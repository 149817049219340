import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  newJournal: null,
  triggerCreateNewRoutine: false,

  questionList: [],
  isNew: false,
  temporyQuestionObject: null,
  temporyEntryObject: {},
};

const createNewRoutineSuccess = (state, action) => {
  return updateObject(state, {
    newJournal: action.newJournal,
    triggerCreateNewRoutine: !state.triggerCreateNewRoutine,
    isNew: true
  });
};

const editRoutineTemplate = (state, action) => {
  return updateObject(state, {
    newJournal: action.journalEntry,
    isNew: false
  });
};

const copyRoutineTemplate = (state, action) => {
  return updateObject(state, {
    newJournal: action.journalEntry,
    isNew: true
  });
};

const createNewRoutineFailed = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true
  });
};
const updateJournalEntryState = (state, action) => {
  return updateObject(state, {
    newJournal: action.newJournal,
    isNew: false
  });
};
const updateQuestionListState = (state, action) => {
  return updateObject(state, {
    questionList: action.questionList
  });
};
const updateTemporyQuestionObject = (state, action) => {
  return updateObject(state, {
    temporyQuestionObject: action.questionObject
  });
};
const updateTemporyEntryObject = (state, action) => {
  return updateObject(state, {
    temporyEntryObject: action.entryObject
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_NEW_ROUTINE_SUCCESS:
      return createNewRoutineSuccess(state, action);

    case actionTypes.CREATE_NEW_ROUTINE_FAIL:
      return createNewRoutineFailed(state, action);

    case actionTypes.EDIT_ROUTINE_TEMPLATE:
      return editRoutineTemplate(state, action);

    case actionTypes.COPY_ROUTINE_TEMPLATE: 
    return copyRoutineTemplate(state, action);

    case actionTypes.UPDATE_JOURNAL_ENTRY_STATE:
      return updateJournalEntryState(state, action);

    case actionTypes.UPDATE_QUESTION_LIST:
      return updateQuestionListState(state, action);

    case actionTypes.UPDATE_TEMPORY_QUESTION_OBJECT:
      return updateTemporyQuestionObject(state, action);

    case actionTypes.UPDATE_TEMPORY_ENTRY_OBJECT:
      return updateTemporyEntryObject(state, action);

    default:
      return state;
  }
};

export default reducer;
