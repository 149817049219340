import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';

const primaryButtonWhite = props => {
  return (
    <View
      style={[
        styles.actionBtnWrapper,
        props.btnPosition === 'left'
          ? styles.flexAiFs
          : props.btnPosition === 'right'
          ? styles.flexAiFe
          : props.btnPosition === 'center'
          ? styles.flexAiCt
          : styles.flexCom,
      ]}>
      <TouchableOpacity
        style={[props.touchableStyle]}
        onPress={props.touchableOnPress}
        disabled={props.disabled}>
        <View
          nativeID={props.nativeId}
          style={[
            styles.btnSingleView,
            styles.primaryButton,
            styles.btnBorderLight,
            styles.bgWhite,
            props.buttonHeigtSm && styles.pdBtnBtNine,
            props.btnShadow ? styles.btnShadowCom : styles.noBtnShadow,
            props.btnLg ? styles.primaryButtonLg : styles.primaryButtonCom,
            props.btnStyle,
          ]}>
          <View style={[styles.btnSingle, styles.btnSingleCent]}>
            {Boolean(props.activityInd) && (
              <View
                style={[
                  styles.indicatorIconView,
                  styles.indicatorLtSm,
                  props.activityIndStyle,
                ]}>
                <ActivityIndicator pdZero={true} size="small" />
              </View>
            )}
            <Text
              style={[
                styles.textPrime,
                styles.textAlignCenter,
                styles.textColorLight,
                styles.bold,
                props.btnTextStyle,
              ]}
              allowFontScaling={false}
              >
              {props.btnText}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (primaryButtonWhite);
