import React, { Component } from "react";
import { View } from "react-native";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import moment from "moment";
import { chartTypes } from '../../Constant/index';
import { getTherapiesColourCode } from "../../util/commonUiLogic";
import * as ColorCode from '../ColorPicker/ColorCodes';
import { getSymptomsData, getMetricsData, getDosagesData, getMetricValue } from '../../util/commonUiLogic';

export default class GraphCommon extends Component {
  constructor(props) {
    super(props);
    const {
      therapies,
      metrics,
      symptoms,
      symptomProps,
      metricProps,
      therapyProps,
    } = props;
    this.state = {
      therapies: therapies,
      metrics: metrics,
      symptoms: symptoms,
      didMount: false,
      symptomProps: symptomProps,
      metricProps: metricProps,
      therapyProps: therapyProps,
      chartData: []
    };
    this.chart = null;
    this.tMax = 1;
    this.tMin = 0;
    this.mMax = 1;
    this.mMin = 0;
    this.sMin = 0;
    this.sMax = 10;
    am4core.useTheme(am4themes_animated);
    if (window.performance) {
      if (performance.navigation.type === 1 && this.chart && this.chart.dispose) {
        this.chart.dispose();
        this.tMax = 1;
        this.tMin = 0;
        this.mMax = 1;
        this.mMin = 0;
        this.sMin = 0;
        this.sMax = 10;
      }
    }
  }

  componentDidMount() {
    const { symptoms, metrics, therapies, startDate, endDate, isFromAddEditGraph } = this.props;

    const chartData = GraphCommon.drawGraph(
      symptoms,
      metrics,
      therapies,
      startDate,
      endDate,
      this.props,
      isFromAddEditGraph
    );
    this.setState({ didMount: true, chartData: chartData });
  }

  static drawGraph = (
    symptoms,
    metrics,
    therapies,
    startDate,
    endDate,
    props,
    isFromAddEditGraph = false
  ) => {
    if (this.chart && this.chart.dispose) {
      this.chart.dispose();
      this.tMax = 1;
      this.tMin = 0;
      this.mMax = 1;
      this.mMin = 0;
      this.sMin = 0;
      this.sMax = 10;
    }
    this.chart = am4core.create(props.graphDivId, am4charts.XYChart);
    this.chart.responsive.enabled = true;
    //this.chart.exporting.menu = new am4core.ExportMenu();
    this.chart.colors.step = 2;
    this.chart.maskBullets = false; //To fixed Bullets outside plot

    const chartData = [];
    const journalEntries = Object.values(props.journalEntriesProcessed);
    const symptomsData = getSymptomsData(
      symptoms,
      startDate,
      endDate,
      props,
      journalEntries,
      isFromAddEditGraph
    );
    Object.values(symptomsData).forEach((item) => {
      item.forEach((data) => {
        const obj = {};
        obj["date"] = new Date(data.entryDate);
        obj["s_" + data.symptom] = data.severity ? data.severity : 0;
        obj["lineDash"] = "4,4";

        chartData.push(obj);
      });
    });

    const metricsData = getMetricsData(
      metrics,
      startDate,
      endDate,
      props,
      journalEntries,
      isFromAddEditGraph
    );
    Object.values(metricsData).forEach((item) => {
      item.forEach((data) => {
        const obj = {};
        obj["date"] = new Date(data.entryDate);
        const val = getMetricValue(data, props);
        obj["m_" + data.metric] = val;
        obj["lineDash"] = "4,4";
        if (this.mMin > val) {
          this.mMin = val;
        }
        if (this.mMax < val) {
          this.mMax = val;
        }

        chartData.push(obj);
      });
    });

    const therapiesData = getDosagesData(
      therapies,
      startDate,
      endDate,
      props,
      journalEntries,
      chartData,
      isFromAddEditGraph
    );
    Object.values(therapiesData).forEach((item) => {
      item.forEach((data) => {
        const obj = {};
        obj["date"] = new Date(data.entryDate);
        const val = data.quantity ? data.quantity : 0;
        obj["t_" + data.treatment] = val;
        obj["lineDash"] = "4,4";
        if (this.tMin > val) {
          this.tMin = val;
        }
        if (this.tMax < val) {
          this.tMax = val;
        }

        chartData.push(obj);
      });
    });

    var obj = {};
    chartData.forEach(function (item) {
      if (item && item.date) {
        obj[item.date] = obj[item.date] || {};
        for (var _k in item) obj[item.date][_k] = item[_k];
      }
    });

    let newChartData = Object.keys(obj).map(function (key) {
      return obj[key];
    });

    newChartData.sort((a, b) => moment(a.date).diff(moment(b.date)));
    this.chart.data = newChartData;

    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 50;
    //dateAxis.renderer.grid.template.disabled = true;
    //dateAxis toolTip config
    dateAxis.renderer.fullWidthTooltip = true;
    dateAxis.tooltipDateFormat = "MMM dd, h:mm a";
    dateAxis.tooltip.background.fill = am4core.color("#3b5998");
    dateAxis.tooltip.background.strokeWidth = 0;
    //make sure to only display days, not minutes or other scales
    dateAxis.dateFormats.setKey("day", "MMM dd");
    dateAxis.dateFormats.setKey("minute", " ");
    dateAxis.dateFormats.setKey("hour", " ");
    dateAxis.periodChangeDateFormats.setKey("day", "MMM dd"); 
    // dateAxis.tooltip.background.cornerRadius = 3;
    // dateAxis.tooltip.background.pointerLength = 0;
    // dateAxis.baseInterval = {
    //   timeUnit: "seconds",
    //   count: 1,
    // };
 
    if (props.enableZoom) {
      //Add zoom
      this.chart.scrollbarX = new am4core.Scrollbar();
      // Zoom events
      dateAxis.events.on("startchanged", dateAxisZoomed);
      dateAxis.events.on("endchanged", dateAxisZoomed);
      function dateAxisZoomed(ev) {
        var axis = ev.target;
        var start = axis.getPositionLabel(axis.start);
        var end = axis.getPositionLabel(axis.end);
        console.log("New range: " + start + " -- " + end);
      }
    }

    let i = 0;
    let dataFound = false;
    Object.keys(symptomsData).forEach((item, index) => {
      const symObj = props.symptomsDigestProcessed.userSymptoms[item];
      const symTempClrs = props.temporyClors && props.temporyClors.symptoms && props.temporyClors.symptoms[item] ? props.temporyClors.symptoms[item] : null;
      const symPropsObj =
        props.symptomProps && props.symptomProps[item]
          ? props.symptomProps[item]
          : {};
      if (isFromAddEditGraph && Number(symPropsObj.min) > Number(symPropsObj.max)) return;
      dataFound = true;
      GraphCommon.createAxisAndSeries(
        this.chart,
        "s_" + item,
        symObj.name,
        null,
        i % 2 === 0 ? false : true,
        symTempClrs || symObj.displayProperties?.color || ColorCode.BGSYMPTOM,
        symPropsObj.style
          ? symPropsObj.style.toUpperCase()
          : symObj.displayProperties.style || (symObj.displayProperties.dashedLine === false ? chartTypes.dash : chartTypes.line),
        isFromAddEditGraph && symPropsObj.max
          ? parseInt(symPropsObj.max)
          : symObj.displayProperties.scaleMax
          ? symObj.displayProperties.scaleMax
          : this.sMax,
        isFromAddEditGraph && symPropsObj.min
          ? parseInt(symPropsObj.min)
          : symObj.displayProperties.scaleMin
          ? symObj.displayProperties.scaleMin
          : this.sMin,
        symObj.displayProperties.scaleMax &&
          symObj.displayProperties.scaleMax === 1
          ? true
          : false
      );
      i++;
    });

    Object.keys(metricsData).forEach((item, index) => {
      const metObj = props.metricsDigestProcessed.userDefinedMetrics[item];
      const metTempClrs = props.temporyClors && props.temporyClors.metrics && props.temporyClors.metrics[item] ? props.temporyClors.metrics[item] : null;
      if (metObj) {
        const metPropsObj =
          props.metricProps && props.metricProps[item]
            ? props.metricProps[item]
            : {};
        let unit;
        if (isFromAddEditGraph && metPropsObj.min > metPropsObj.max) return;
        dataFound = true;
        let display = props.userDefinedMetricUnitOfMeasures.find(function (
          element
        ) {
          return element.metricId === metObj.id;
        });

        if (!display) {
          display = props.systemDefinedMetricUnitOfMeasures.find(function (
            element
          ) {
            return element.metricId === metObj.id;
          });
        }

        // const dislayUnit =
        //   props.unitsOfMeasureDigestProcessed.unitsOfMeasure[display?.uomId];
        let dislayUnit = null;
        if (display && display?.uomId) {
          dislayUnit = props.unitsOfMeasureDigestProcessed.unitsOfMeasure[display?.uomId];
        }

        if (dislayUnit) {
          unit = dislayUnit.symbol;
        } else {
          if (metObj.symbol) {
            unit = metObj.symbol;
          } else {
            unit = "#";
          }
        }

        GraphCommon.createAxisAndSeries(
          this.chart,
          "m_" + item,
          metObj.name,
          unit,
          i % 2 === 0 ? false : true,
          metTempClrs || metObj.displayProperties?.color || ColorCode.BGHEALTHDATA,
          metPropsObj.style
            ? metPropsObj.style.toUpperCase()
            : metObj.displayProperties.style || (metObj.displayProperties.dashedLine === true ? chartTypes.line : chartTypes.dash),
          isFromAddEditGraph && metPropsObj.max ? parseInt(metPropsObj.max) : this.mMax,
          isFromAddEditGraph && metPropsObj.min ? parseInt(metPropsObj.min) : this.mMin
        );
        i++;
      }
    });

    Object.keys(therapiesData).forEach((item, index) => {
      const theObj = props.treatmentsProcessed[item];
      const data = theObj.dosages;
      let unit = data[0] && data[0].units ? data[0].units : " ";
      const thePropsObj =
        props.therapyProps && props.therapyProps[item]
          ? props.therapyProps[item]
          : {};
      const theTempClrs = props.temporyClors && props.temporyClors.therapies && props.temporyClors.therapies[item] ? props.temporyClors.therapies[item] : null;

      if (isFromAddEditGraph && Number(thePropsObj.min) > Number(thePropsObj.max)) return;
      dataFound = true;
      GraphCommon.createAxisAndSeries(
        this.chart,
        "t_" + item,
        theObj.name,
        unit,
        i % 2 === 0 ? false : true,
        theTempClrs || theObj.displayProperties?.color || getTherapiesColourCode(theObj.treatmentType),
        thePropsObj.style ? thePropsObj.style.toUpperCase() : chartTypes.bar,
        isFromAddEditGraph && thePropsObj.max ? parseInt(thePropsObj.max) : this.tMax,
        props.isFromAddEditGraph && thePropsObj.min ? parseInt(thePropsObj.min) : this.tMin
      );
      i++;
    });

    // Add legend
    this.chart.legend = new am4charts.Legend();
    this.chart.legend.labels.template.maxWidth = "50%";
    //this.chart.legend.labels.template.truncate = true;
    this.chart.legend.maxHeight = 300;
    this.chart.legend.scrollable = true;

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.lineX.stroke = am4core.color("#3b5998");
    this.chart.cursor.lineX.strokeWidth = 1;
    this.chart.cursor.lineX.strokeOpacity = 1;
    this.chart.cursor.lineX.strokeDasharray = "";
    this.chart.cursor.lineY.disabled = true;

    if (props.updateDataFound) {
      props.updateDataFound(dataFound);
    }
    
    if (!Boolean(props.isFromDashboardGraph)) {
      let options = this.chart.exporting.getFormatOptions("png");
      options.quality = 1;
      options.scale = 10;
      this.chart.exporting.useWebFonts = false;
      this.chart.exporting.useRetina = false;
      this.chart.exporting.setFormatOptions("png", options);
      if (props.isEdit) {
        let graphid = props.graphImageS3;
        if (!graphid) {
          const now = new Date();
          graphid = now.getTime() + "_" + props.userId + ".png";
          props.setGraphImage(graphid);
        }
        props.updateGrph(graphid, this.chart);
      } else {
        if (props.updateGrph) {
          props.updateGrph(this.chart);
        }
      }
    }
    return chartData;
  };

  static cleanValues = (data = [], min, field) => {
    return data.map(item => {
      if (item[field] < min) {
        delete item[field]
      }
      return item
    }).filter(obj => Object.keys(obj).length > 2)
  }

  // Create series
  static createAxisAndSeries = (
    chart,
    field,
    name,
    unit,
    opposite,
    color,
    chartType,
    max,
    min,
    isSymYesNoScale
  ) => {
    GraphCommon.cleanValues(chart.data, min, field)
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = min;
    valueAxis.max = max;
    valueAxis.cursorTooltipEnabled = false;
    if (chartType === chartTypes.bar) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = 5;
      series.dataFields.valueY = field;
      series.dataFields.dateX = "date";
      series.strokeWidth = 3;
      series.yAxis = valueAxis;
      series.name = name;

      series.tooltipText =
        "{name} = " +
        (isSymYesNoScale ? "Yes" : "{valueY} ") +
        (unit ? unit : "");

      if(isSymYesNoScale) {
        series.adapter.add("tooltipText", function(tooltipText, target) {
          let data = target.tooltipDataItem.dataContext;
          if(data) {
            let key = target.dataFields.valueY;
            let val = data[key];
            if (val == 0) { 
              return tooltipText.replace('Yes', 'No')
            }
            else {
              return tooltipText;
            }
          }
        });
      }
      
      var bullet = series.bullets.push(new am4charts.Bullet());
      var square = bullet.createChild(am4core.Rectangle);
      square.width = 5;
      square.height = 5;
      square.horizontalCenter = "middle";
      square.verticalCenter = "middle";
      square.strokeWidth = 3;

      //series.columns.template.tooltipText = "{name} = {valueY}";
      //series.tooltip.pointerOrientation = "down";
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = am4core.color("#ffffff");
      series.columns.template.fillOpacity = 0.7;
      series.showOnInit = true;

      var barChartState = series.columns.template.states.create("hover");
      barChartState.properties.fillOpacity = 1;

      if (color) {
        series.stroke = am4core.color(color);
        series.fill = am4core.color(color);
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color(color);
      }

      valueAxis.renderer.labels.template.fill = series.stroke;
      valueAxis.renderer.opposite = opposite;
      //valueAxis.renderer.grid.template.strokeDasharray = '4,4';
      valueAxis.renderer.grid.template.disabled = true;
    } else {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = "date";
      series.strokeWidth = 3;
      series.yAxis = valueAxis;
      series.name = name;

      series.tooltipText =
        "{name} = " +
        (isSymYesNoScale ? "Yes" : "{valueY} ") +
        (unit ? unit : ""); 

      if(isSymYesNoScale) {
        series.adapter.add("tooltipText", function(tooltipText, target) {
          let data = target.tooltipDataItem.dataContext;
          if(data) {
            let key = target.dataFields.valueY;
            let val = data[key];
            if (val == 0) { 
              return tooltipText.replace('Yes', 'No')
            }
            else {
              return tooltipText;
            }
          }
        });
      }

      //series.tooltip.pointerOrientation = "down";
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = am4core.color("#ffffff");
      series.showOnInit = true;

      let bulletCircle = series.bullets.push(new am4charts.CircleBullet());
      //bulletCircle.tooltipText = "{name} = {valueY}";
      bulletCircle.circle.strokeWidth = 2;

      if (color) {
        series.stroke = am4core.color(color);
        series.fill = am4core.color(color);
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color(color);
      } else {
        let interfaceColors = new am4core.InterfaceColorSet();
        series.stroke = interfaceColors.getFor("background");
      }

      if (chartType === chartTypes.dash) {
        series.propertyFields.strokeDasharray = "lineDash";
      }
      var lineChartState = bulletCircle.states.create("hover");
      lineChartState.properties.scale = 1.2;

      //hide/show bullets with zoom and maskBullets
      bulletCircle.adapter.add("dy", function (dy, target) {
        hideBullet(target);
        return dy;
      });
      function hideBullet(bullet) {
        if (
          bullet.pixelX < 0 ||
          bullet.pixelX > chart.plotContainer.measuredWidth ||
          bullet.pixelY < 0 ||
          bullet.pixelY > chart.plotContainer.measuredHeight
        ) {
          bullet.visible = false;
        } else {
          bullet.visible = true;
        }
      }
      // valueAxis.renderer.line.strokeOpacity = 1;
      // valueAxis.renderer.line.strokeWidth = 2;
      // valueAxis.renderer.line.stroke = series.stroke;

      valueAxis.renderer.labels.template.fill = series.stroke;
      valueAxis.renderer.opposite = opposite;
      //valueAxis.renderer.grid.template.strokeDasharray = '4,4';
      valueAxis.renderer.grid.template.disabled = true;
    }
  };

  componentWillUnmount() {
    if (this.chart && this.chart.dispose) {
      this.chart.dispose();
      this.tMax = 1;
      this.tMin = 0;
      this.mMax = 1;
      this.mMin = 0;
      this.sMin = 0;
      this.sMax = 10;
    }
  }

  hasSelectedData = () => {
    const { symptoms, therapies, metrics } = this.props;
    let selectData = false;
    let symptomsArray = [];
    let therapyArray = [];
    let metricsArray = [];

    if (symptoms) {
      symptomsArray = Object.values(symptoms);
    }
    if (therapies) {
      therapyArray = Object.values(therapies);
    }
    if (metrics) {
      metricsArray = Object.values(metrics);
    }
    if((symptomsArray && symptomsArray.length > 0) ||
        (therapyArray && therapyArray.length > 0) ||
        (metricsArray && metricsArray.length > 0)
    ) {
      selectData = true;
    }
    return selectData;
  }

  render() {
    return (
      <View />
    );
  }
}
