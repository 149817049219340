import React from "react";
import { View, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";

const itemDeleteButton = (props) => {
  return (
    <View style={[styles.deleteBtnView, styles.flexCom, styles.flexJcCtAiCt]}>
      <TouchableOpacity onPress={props.touchableOnPress}>
        <View style={[styles.deleteIconSection]}>
          {Boolean(props.routinesReminderDelete) ? (
            <Image
            style={styles.scheduleCancelIcon}
            source={require("../../assets/icons/menu-res-active.png")}
          />
          ): (
          <Image
            style={styles.delLgIcon}
            source={require("../../assets/icons/remove.png")}
          />
          )}
          
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (itemDeleteButton);
