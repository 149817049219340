import * as variable from "./base/variable";
export const cssCommunity = {
  communityResourceIcon: {
    width: 60,
    height: 57,
    resizeMode: "contain",
    marginRight: 15,
  },
  communityList: {
    marginTop: 15,
  },
  viewItemDataCmmunity: {
    paddingTop: 5,
  },
  viewItemDataTag: {
    paddingTop: 8,
  },
  communityProfileIcon: {
    width: 40,
    height: 40,
  },
  viewItemSmTitle: {
    paddingBottom: 5,
  },
  communityBgSec: {
    width: 90,
    height: "100%",
    position: "absolute",
    top: 0,
    ...variable.dPFlexAiCtJcCt,
    left: 0,
    bottom: 0,
  },
  communityListBotMg: {
    marginBottom: 15,
  },
  communityProfileSec: {
    ...variable.dPFlexAiCtJcCt,
    width: 40,
    height: 40,
  },
  viewDataFullDesc: {
    paddingLeft: 90,
    width: "100%",
  },
  viewDataFullDescSmallScreen: {
    paddingLeft: 20
  },
  viewDataView: {
    width: "100%",
  },
  comIconArea: {
    width: 75,
    height: 60,
    position: "absolute",
    top: 0,
    left: 0,
  },
  comDescSec: {
    paddingLeft: 75,
    maxWidth: "100%",
  },
  comDescView: {
    ...variable.marginLtRtAuto,
    minHeight: 60,
  },
};
