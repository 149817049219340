import React, { Component } from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";
import moment from "moment";
import styles from "../../../../Css";
import * as FieldDetails from "../../../TextInput/fieldDetails";
import TextDatePickerFrame from "../../../TextInput/textDatePickerFrame";
import TextTimePickerFrame from "../../../TextInput/textTimePickerFrame";
import TimePicker from "../../../DateTimePicker/TimePicker";
import DatePicker from "../../../DateTimePicker/DatePicker";
import ItemDeleteButton from "../../../Button/itemDeleteButton";
import {timeMap} from '../../../../util/formatDate';
import { WEB } from "../../../../util/platformWindowConfig";


class Daily extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    const { reminderTime } = data;
    this.state = {
      ...data,
      reminderDateTime: this.getDateTime(reminderTime),
    };
  }

  customTouchableComponent = (onPress) => {
    return (
      Platform.OS === WEB
        ? <TouchableOpacity onPress={onPress}>
            {this.renderEndReminderContent()}
          </TouchableOpacity>
        : this.renderEndReminderContent()
    );
  };

  renderEndReminderContent = () => {
    const { reminderEnd } = this.state;
    return (
      <TextDatePickerFrame
        title={"End Reminder"}
        fieldBg={FieldDetails.BGWHITE}
        fieldSm={true}
        fieldValue={
          reminderEnd ? moment(reminderEnd).format("D MMM 'YY") : ""
        }
        onDateConfirm={this.onDateConfirm}
        date={reminderEnd}
        dateSm={true}
        windowWidth={this.props.windowWidth}
        reminderSection={this.props.reminderSection}
        minDate={new Date().getTime()}
      />
    )
  }

  customTouchableComponentTimePicker = (onPress) => {
    return (
      Platform.OS === WEB
        ? <TouchableOpacity onPress={onPress}>
            {this.renderEverydayTimeContent()}
          </TouchableOpacity>
        : this.renderEverydayTimeContent()
    );
  };

  renderEverydayTimeContent = () => {
    const { reminderTime, reminderDateTime } = this.state;
    let duration = "";
    if (reminderTime && reminderTime.length > 0) {
      const time = timeMap[reminderTime[0]];
      const times = time.split(" ");
      duration =
        times[0] +
        ":" +
        (reminderTime[1].toString().length === 1
          ? "0" + reminderTime[1]
          : reminderTime[1]) +
        " " +
        times[1];
    }
    return (
      <TextTimePickerFrame
        title={"Everyday @"}
        fieldBg={FieldDetails.BGWHITE}
        fieldSm={true}
        fieldValue={duration}
        onTimeChange={this.onTimeChange}
        reminderDateTime={reminderDateTime}
        windowWidth={this.props.windowWidth}
        reminderSection={this.props.reminderSection}
      />
    )
  }

  onDateConfirm = (date) => {
    const { updateReminders, index } = this.props;
    this.setState({ reminderEnd: date }, () =>
      updateReminders(index, { ...this.state, reminderEnd: date })
    );
  };

  onTimeChange = (selectedTime) => {
    const { updateReminders, index } = this.props;
    const time =
      Platform.OS === WEB
        ? moment(selectedTime).format("HH.mm")
        : moment(selectedTime, "x").format("HH.mm");

    const times = time.split(".");
    const { reminderTime } = this.state;
    reminderTime[0] = parseInt(times[0]);
    reminderTime[1] = parseInt(times[1]);
    this.setState(
      {
        reminderTime: reminderTime,
        reminderDateTime: this.getDateTime(reminderTime),
      },
      () => {
        this.setAccept();
        updateReminders(index, { ...this.state, reminderTime: reminderTime });
      }
    );
  };

  getDateTime = (reminderTime) => {
    if (reminderTime && reminderTime.length > 0) {
      const now = moment()
        .set("hour", reminderTime[0])
        .set("minute", reminderTime[1]);
      return now.valueOf();
    }

    return null;
  };

  setAccept = () => {
    const { reminderTime } = this.state;
    const { updateReminders, index } = this.props;
    if (reminderTime.length === 2) {
      this.setState({ accept: true }, () => {
        updateReminders(index, { ...this.state, accept: true });
      });
    }
  };

  render() {
    const { reminderEnd, reminderDateTime } = this.state;
    const { index, deleteItem, id } = this.props;
    return (
      <View style={styles.DailyAddWrapper}>
        <View style={styles.fieldContainer}>
          <View style={styles.fieldRowWrapper}>
            <View style={styles.fieldColIdCom}>
            <View style={styles.indexSm}>
                <Text style={[styles.textPrimeSmBold, styles.indexTxtSm]}>
                  {id + 1}
                </Text>
              </View>
            </View>
            <View style={styles.fieldColSp}></View>
            <View style={styles.fieldColStretch}>
              <TimePicker
                customTouchableComponent={
                  this.customTouchableComponentTimePicker
                }
                onChange={this.onTimeChange}
                value={reminderDateTime}
                windowWidth={this.props.windowWidth}
                reminderSection={this.props.reminderSection}
              />
            </View>
            <View style={styles.fieldColSp}></View>
            <View style={styles.fieldColStretch}>
              <DatePicker
                date={reminderEnd}
                customTouchableComponent={this.customTouchableComponent}
                onConfirm={this.onDateConfirm}
                windowWidth={this.props.windowWidth}
                reminderSection={this.props.reminderSection}
                minDate={new Date().getTime()}
              />
            </View>
            <View style={styles.fieldColSp}></View>
              <View style={styles.fieldColDelete}>
                <ItemDeleteButton 
                touchableOnPress={() => deleteItem(index)} 
                routinesReminderDelete={true}
                />
              </View>
          </View>
        </View>
      </View>
    );
  }
}

export default (Daily);
