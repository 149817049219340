import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import ToolTip from '../../components/ToolTip';

class NotificationContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.Openid && props.Openid === props.id ? true : false,
      notifClick: props.notifClick
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {Openid, id, notifClick} = props;
    const isOpen = Openid && Openid === id ? true : false;
    if (state.isOpen !== isOpen) {
      return {
        isOpen: isOpen,
      };
    }
    if (state.notifClick !== notifClick) {
      return {
        notifClick: notifClick,
      };
    }
    return null;
  }

  open = () => {
    if (this.state.isOpen) {
      this.props.openMenu(null);
    } else {
      this.props.openMenu(this.props.id);
    }
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.notifyMenuOption, styles.greyBorderThin, this.props.disabled && styles.notificationDisable]}>
        <TouchableOpacity 
          onPress={this.props.onPressNotification}
          disabled={this.state.notifClick || this.props.disabled}>
          <View style={[stylesRes.notifyMaxView]}>
            <View style={[styles.ViewRow]}>
              <View style={styles.viewDetailSec}>
                <View style={styles.viewMainData}>
                  <View
                    style={[styles.viewItemDesc, styles.colPdLtTopBotNotify]}>
                    <View style={[styles.viewImg]}>
                      <View style={[styles.notifyComSec, styles.rightSpSm]}>
                        <View
                          style={[stylesRes.notifyIconRect, {backgroundColor: !this.props.data?.deviceName && this.props.data?.color && this.props.data.color}]}>
                          <Image
                            source={this.props.notificationIcon}
                            style={styles.imgContain}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={[styles.viewDataFull]}>
                      <Text style={this.props.deviceNotif ? styles.textPrime : styles.textPrimeBold} allowFontScaling= {false}>
                        {this.props.notifName}
                      </Text>
                      {Boolean(this.props.notifDetails) &&
                        <>
                        <Text style={[styles.textMicSm, styles.txtGreyHighlightCr]}>
                          <Text style={[styles.statusBarText, styles.minusCircleView]} allowFontScaling= {false}>
                            {this.props.data.title}
                          </Text>
                          <Text style={styles.minusCircleView} allowFontScaling= {false}>
                            {this.props.type === 'PARTNER_DELETE_HEALTH_RESOURCE' ? ' was removed from the health library by ' : ' Shared by ' }
                          </Text>
                          <Text style={[styles.statusBarText, styles.minusCircleView]} allowFontScaling= {false}>
                            {this.props.data.profileName}
                          </Text>
                        </Text>
                       </>
                      }
                      <Text style={styles.textMicSm} allowFontScaling= {false}>{this.props.date}</Text>
                    </View>
                  </View>
                </View>
              </View>
              {!Boolean(this.props.hideOptions) &&
              <View style={[styles.dataIconSecSm, styles.colPdRtTopBotNotify]}>
                <View style={styles.iconListSec}>
                  <View style={[styles.settingSec, styles.iconListMgSmLt]}>
                    <TouchableOpacity onPress={() => this.open()}>
                      <Image
                        style={[styles.settingIcon, styles.settingSp]}
                        source={require('../../assets/icons/settings.png')}
                      />
                    </TouchableOpacity>
                    <ToolTip
                      id={'menu-routine'}
                      menuOptions={true}
                      isOpen={this.state.isOpen}>
                      <View
                        style={[
                          styles.menuListViewWrapper,
                          styles.dropDownList,
                        ]}>
                        {(this.props.type === 'ROUTINE_REMINDERS' || this.props.type === 'CLINICALTRIAL') &&
                            <View style={[styles.liItem]}>
                            <TouchableOpacity 
                                onPress={this.props.onPressTurnOffNotification}>
                                <View style={[styles.menuListItem]}>
                                <Text style={styles.texPrime} allowFontScaling= {false}>
                                    Turn Off Notification
                                </Text>
                                </View>
                            </TouchableOpacity>
                            </View>
                        }
                        <View style={[styles.liItem]}>
                          <TouchableOpacity
                            onPress={this.props.onPressClearNotification}>
                            <View style={[styles.menuListItem]}>
                              <Text style={styles.texPrime} allowFontScaling= {false}>Delete</Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </ToolTip>
                  </View>
                </View>
              </View>
              }
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default (NotificationContent);
