import React, {Component} from 'react';
import * as actions from '../../store/actions/index';
import {
  DASHBOARD,
  OTHER,
  MANAGE_DEVICES,
} from '../../navigation/path';
import * as RNLocalize from 'react-native-localize';
import { 
  handleCreateEntryNavigation,
  handleUpdateRoutineNavigation,
  handleCreateEntryFromEmailNavigation,
  handleSurveysNavigation } from '../../util/commonUiLogic';
import * as updateSelectedProfile from "../../store/actions/updateSelectedProfile";

let tokensHandledInThisSession = {};
class AbstractDashboardLoadingScreen extends Component {
  constructor(props) {
    super(props);
    let token = props.route.params ? props.route.params.token : null;
    let reminderDateTime = props.route.params ? props.route.params?.reminder : null;
  if (token && token !== props.tokenHistory) {
      this.props.setToken(
        token,
        AbstractDashboardLoadingScreen.getTokenType(props.route.params, props),
        Boolean(reminderDateTime) ? parseInt(reminderDateTime) : null
      );

    } else {
      token = props.tokenFromState;
      reminderDateTime = props.reminderDateTimeFromState;
    }
    this.state = {
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      token: token,
      deeplinkNotYetHandled: false,
      forceLoad: props.forceLoad,
      reminderDateTime: Boolean(reminderDateTime) ? parseInt(reminderDateTime) : null
    };
  }

  static getTokenType = (params, props) => {
    const {token} = params;
    let tokenType = null;
    if (props.route.name.includes('accept')) {
      tokenType = 'accept';
    } else if (
      props.route.name.includes('update-routine') &&
      props.token !== token
    ) {
      tokenType = 'update-routine';
    } else if (
      props.route.name.includes('create-journal') &&
      props.token !== token
    ) {
      tokenType = 'create-journal';
    } else if (
      props.route.name.includes('survey-questions') &&
      props.token !== token
    ) {
      tokenType = 'survey-questions';
    } else if (
      props.route.name.includes('journal-from-email') &&
      props.token !== token
    ) {
      tokenType = 'journal-from-email';
    }
    return tokenType;
  };

  componentDidMount = () => {
    const {
      coreDataLoaded,
      journalUserId,
      onFetachAllUserJournals,
      navigation,
      tokenTypeFromState,
      sharedAccessInvitationAccept,
      onfetchAllJournalEntriesForCaregiverAccept,
      setJournalUser,
      setToken,
      onFetachAllJournals,
      updateProfile,
      resetNotification,
      changeJournalUser,
    } = this.props;

    const {token} = this.state;
    console.log("AbstractDashboardLoadingScreen::componentDidMount coreDataLoaded: " + Boolean(coreDataLoaded) + " journalUserId: " + journalUserId + " token: " + token + " tokenTypeFromState: " + tokenTypeFromState + " deeplinkNotYetHandled: " + this.state.deeplinkNotYetHandled);
    if (coreDataLoaded && journalUserId) {
      this.fetchJournalForPersonBeingCaredFor(journalUserId, onFetachAllUserJournals);
    } else if (((this.state.token === undefined || this.state.token === null) && this.state.deeplinkNotYetHandled === false)) {
      console.log("Fetching journal when token and deeplinkNotYetHandled is false");
      // Only execute this block if a deeplink isn't waiting to be handled. This avoids
      // a double loading of journal entries which can cause the state to get in a weird
      // place. When deeplinks are triggered they do a dispatch and then update the path
      // which will cause this component to get mounted twice potentially
      onFetachAllJournals((processedData, data) => {
        console.log("Fetch journal when token and deeplinkNotYetHandled is false");
        if (!data.user.timezone) {
          console.log("Updating profile TZ");
          let profile = {...data.user};

          profile = {...profile, timezone: RNLocalize.getTimeZone()};
          updateProfile(profile, () => {
          });
          console.log("Updated profile TZ");
        }

        if (journalUserId) {
          // We use a timeout to ensure the primary dispatch can complete updating the state, and enabling the spinner to restart for the new load
          setTimeout(() => changeJournalUser(journalUserId, navigation, processedData.sharedAccessDigest, false, 'App Loading > SwitchProfile > Caregiver'), 0);
        }
      });
    } else if (this.props.route.params?.deviceName) {
      console.log("Handling device entry loading");
      onFetachAllJournals((processedData, data) => {
        console.log("Fetched journals for handling device entry loading");
        if (!data.user.timezone) {
          console.log("Updating timezone handling device entry loading");
          let profile = {...data.user};

          profile = {...profile, timezone: RNLocalize.getTimeZone()};
          updateProfile(profile, () => {
          });
          console.log("Updated timezone handling device entry loading");
        }
        console.log("Resetting navigation for device loading");
        this.props.navigation.reset({
          index: 0,
          routes: [{name: MANAGE_DEVICES.path}],
        });
        console.log("Resetted navigation for device loading");
      });
    } else if (this.props.route.params?.orgName) {
      console.log("Resetting navigation for orgName: " + this.props.route.params?.orgName);
      this.props.navigation.reset({
        index: 0,
        routes: [{name: DASHBOARD.path}],
      });
      console.log("Resetted navigation for orgName loading: " + this.props.route.params?.orgName);
    } else {
      if (token && this.isCareGiverAcceptRequest()) {
        console.log("Handling caregiver accept request");
        if (
          !AbstractDashboardLoadingScreen.hasTokenBeenHandledInSession(
            token,
            tokenTypeFromState,
          )
        ) {
          AbstractDashboardLoadingScreen.addTokenHandledInSession(
            token,
            tokenTypeFromState,
          );
          sharedAccessInvitationAccept(token, (success, data) => {
            onfetchAllJournalEntriesForCaregiverAccept(processedData => {
              if (success) {
                const name =
                  processedData.sharedAccessDigest?.sharedAccessUserSummaries?.[
                    data.grantingUser
                    ]?.name;

                onFetachAllUserJournals(data.grantingUser, () => {
                  setJournalUser(data.grantingUser, name);
                  setToken(null, null);
                  navigation.reset({
                    index: 0,
                    routes: [{name: DASHBOARD.path}],
                  });
                });
              } else {
                setToken(null, null);

                if (
                  data.caregiverStatus === 'ACCEPTED' ||
                  data.caregiverStatus === 'REVOKED'
                ) {
                  navigation.push(
                    OTHER.caregiverVerificationPath, {
                      caregiverStatus: data.caregiverStatus
                    },
                  );
                } else {
                  /**
                   * TODO : added console.log to check the functionality when caregiver status is not matched for ACCEPTED or REVOKED
                   */
                  console.log(
                    'Dashboard loading for caregiver status fail : ',
                    JSON.stringify(data),
                  );
                  onFetachAllJournals((processedData, data) => {
                    if (!data.user.timezone) {
                      let profile = {...data.user};

                      profile = {
                        ...profile,
                        timezone: RNLocalize.getTimeZone(),
                      };
                      updateProfile(profile, () => {
                      });
                    }
                    navigation.reset({
                      index: 0,
                      routes: [{name: DASHBOARD.path}],
                    });
                  });
                }
              }
            });
          });
        } else {
          console.log('Skipping duplicate call to accept invitation.');
          navigation.reset({
            index: 0,
            routes: [{name: DASHBOARD.path}],
          });
        }
      } else if (token && this.isUpdateRoutineRequest()) {
        console.log("Handling update routine request");
        onFetachAllJournals(processedData => {
          console.log("Fetched journals for handling update routine request");
          handleUpdateRoutineNavigation(this.state.token, processedData.journalEntryTypes, this.props, processedData, true);
        });
      } else if (token && this.isCreateNewRoutine()) {
        console.log("Handling create new routine");
        onFetachAllJournals(processedData => {
          console.log("Fetched journals for handling create new routine");
          handleCreateEntryNavigation(this.state.token, this.state.reminderDateTime, processedData.journalEntryTypes, this.props);
        });
      } else if (token && this.isEmailJournalEntry()) {
        console.log("Handling email journal entry");
        onFetachAllJournals(processedData => {
          handleCreateEntryFromEmailNavigation(this.state.token, processedData.journalEntries, this.props);
        });
      } else if (token && this.isFillInSurveyRequest()) {
        console.log("Handling fill in survey request");
        onFetachAllJournals(processedData => {
          handleSurveysNavigation(this.state.token, this.props);
        });
      } else if (this.props.forceLoad === true) {
        console.log("AbstractDashboardLoadingScreen - Handling simple deep link.");
        this.props.onFetachAllJournals((processedData, data) => {
          if (!data.user.timezone) {
            let profile = {...data.user};

            profile = {...profile, timezone: RNLocalize.getTimeZone()};
            this.props.updateProfile(profile, () => {
            });
          }
        });
      }
    }

    resetNotification();
  };

  fetchJournalForPersonBeingCaredFor(journalUserId, onFetachAllUserJournals) {
    console.log("Fetching journal for journalUserId: " + journalUserId);
    onFetachAllUserJournals(this.props.journalUserId, () => {
      console.log("Fetch complete journal for journalUserId: " + journalUserId);
      if (this.props.route?.params?.navigateWhenReady)
        this.props.route.params.navigateWhenReady({path: DASHBOARD.path, trys: 0})
    });
  }

  isFillInSurveyRequest() {
    return (
      this.props.route.name.includes('survey-questions') ||
      this.props.tokenTypeFromState === 'survey-questions'
    );
  }

  isCreateNewRoutine() {
    return (
      this.props.route.name.includes('create-journal') ||
      this.props.tokenTypeFromState === 'create-journal'
    );
  }

  isUpdateRoutineRequest() {
    return (
      this.props.route.name.includes('update-routine') ||
      this.props.tokenTypeFromState === 'update-routine'
    );
  }

  isEmailJournalEntry() {
    return (
      this.props.route.name.includes('journal-from-email') ||
      this.props.tokenTypeFromState === 'journal-from-email'
    );
  }

  isCareGiverAcceptRequest() {
    return (
      this.props.route.name.includes('accept') ||
      this.props.tokenTypeFromState === 'accept'
    );
  }

  static addTokenHandledInSession(token, tokenType) {
    if (!tokensHandledInThisSession.hasOwnProperty(tokenType)) {
      tokensHandledInThisSession[tokenType] = {};
    }

    if (!tokensHandledInThisSession[tokenType].hasOwnProperty(token)) {
      tokensHandledInThisSession[tokenType][token] = true;
    }
  }

  static hasTokenBeenHandledInSession(token, tokenType) {
    if (!tokensHandledInThisSession.hasOwnProperty(tokenType)) {
      return false;
    }

    return tokensHandledInThisSession[tokenType].hasOwnProperty(token);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      windowHeight,
      windowWidth,
      deeplinkNotYetHandled,
    } = props;
    let token = props.route.params ? props.route.params.token : null;
    let reminderDateTime = props.route.params ? props.route.params?.reminder : null;
    if (token && token !== props.tokenHistory) {
      props.setToken(
        token,
        AbstractDashboardLoadingScreen.getTokenType(props.route.params, props),
        Boolean(reminderDateTime) ? parseInt(reminderDateTime) : null
      );
    }

    if (
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth ||
      token !== state.token ||
      reminderDateTime !== state.reminderDateTime ||
      deeplinkNotYetHandled !== state.deeplinkNotYetHandled
    ) {
      return {
        windowWidth: windowWidth,
        windowHeight: windowHeight,
        token: token,
        reminderDateTime: parseInt(reminderDateTime),
        deeplinkNotYetHandled: deeplinkNotYetHandled,
      };
    }

    return null;
  }

  render() {
    return <></>;
  }
}

export const AbstractDashbordLoadingScreen_mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    logout: state.auth.logout,
    isSignup: state.auth.isSignup,
    fetchAllJournalsSpinnerStart: state.routines.fetchAllJournalsSpinnerStart,
    coreDataLoaded: state.routines.coreDataLoaded,
    isFromLogin: state.auth.isFromLogin,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
    tokenFromState: state.uistate.token,
    tokenHistory: state.uistate.tokenHistory,
    tokenTypeFromState: state.uistate.tokenType,
    reminderDateTimeFromState: state.uistate.reminderDateTime,
    user: state.routines.user,
    deeplinkNotYetHandled: state.redirect.deeplinkNotYetHandled,
  };
};

export const AbstractDashbordLoadingScreen_mapDispatchToProps = dispatch => {
  return {
    onFetachAllJournals: callBack =>
      dispatch(actions.fetchAllJournalEntries(callBack)),
    onfetchAllJournalEntriesForCaregiverAccept: callBack =>
      dispatch(actions.fetchAllJournalEntriesForCaregiverAccept(callBack)),
    onFetachAllUserJournals: (journalUserId, callBack) =>
      dispatch(actions.fetachAllUserJournals(journalUserId, callBack)),
    sharedAccessInvitationAccept: (token, callBack) =>
      dispatch(actions.sharedAccessInvitationAccept(token, callBack)),
    resetNotification: () => dispatch(actions.resetNotification()),
    setEntryType: journalEntryType =>
      dispatch(actions.setSelectedJournalEntryType(journalEntryType)),
    onCreateNewRoutine: (journalEntry, callBack, journalUserId) =>
      dispatch(actions.createNewRoutine(journalEntry, callBack, journalUserId)),
    getAllUserHealthSurvey: (journalUserId, callBack) =>
      dispatch(actions.getAllUserHealthSurvey(journalUserId, callBack)),
    getUserHealthSurveyById: (id, journalUserId, callBack) =>
      dispatch(actions.getUserHealthSurveyById(id, journalUserId, callBack)),
    selectSurvey: (
      id,
      surveyId,
      name,
      isClosed,
      modifiedOn = null,
      description = null,
      duration = null,
      s3ImageUrl = null,
      surveyStatus = null,
      questionOrder = 0,
    ) =>
      dispatch(
        actions.selectSurvey(
          id,
          surveyId,
          name,
          isClosed,
          modifiedOn,
          description,
          duration,
          s3ImageUrl,
          surveyStatus,
          questionOrder,
        ),
      ),
    resetSurvey: () => dispatch(actions.resetSurvey()),
    setActiveTab: activeTabIndex =>
      dispatch(actions.setActiveTab(activeTabIndex)),
    setJournalUser: (journalUserId, journalUserName) =>
      dispatch(actions.setJournalUser(journalUserId, journalUserName)),
    updateProfile: (editProfile, callBack) =>
      dispatch(actions.updateProfile(editProfile, callBack)),
    setToken: (token, type, reminderDateTime) => dispatch(actions.setToken(token, type, reminderDateTime)),
    onSetSelectedJournalEntry: selectedId =>
      dispatch(actions.setSelectedJournalEntry(selectedId)),
    changeJournalUser: (grantingUser, navigation, sharedAccessDigestProcessed, graphChecked, trackEventTag) =>
      dispatch(updateSelectedProfile.changeJournalUser(grantingUser, navigation, sharedAccessDigestProcessed, graphChecked, trackEventTag)),
  };
};

export default AbstractDashboardLoadingScreen;
