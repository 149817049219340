import React from "react";
import { View, Image } from "react-native";
import styles from "../../../Css";
import { APPLE_APP_STORE_URL } from '../../../util/commonUiConfig';

const appStore = (props) => {
  return (
    <View style={[styles.storeImgView, props.webMobile && styles.storeWrapper]}>
        <a href={APPLE_APP_STORE_URL}>
        <Image
          style={[
            props.windowWidth < 375 && props.webMobile
              ? [styles.storeIconSm]
              : props.webMobile
              ? [styles.storeIconRg]
              : styles.storeIcon,
          ]}
          source={require("../../../assets/store/app-store.png")}
        />
        </a>
    </View>
  );
};

export default appStore;
