import React from 'react';
import {View, Text, Image} from 'react-native';
import styles from '../../../Css';

const textOrCallus = props => {
  const { phoneNumber, isFromTextUs = false } = props;
  return (
    <View style={[styles.detailItemWrapper, !Boolean(isFromTextUs) && styles.detailItemLast]}>
      <View style={[styles.detailItemTitle]}>
        <View style={[styles.itemIconView]}>
          <Image
            source={Boolean(isFromTextUs)
              ? require('../../../assets/contact/text-us.png')
              : require('../../../assets/contact/call-us.png')}
            style={styles.itemContactIcon}
          />
        </View>
        <View style={[styles.itemTextView]}>
          <Text style={[styles.textPrimeBold, styles.textColorLight]} allowFontScaling={false}>
            {Boolean(isFromTextUs) ? 'Text Us' : 'Call Us'}
          </Text>
        </View>
      </View>
      <View style={[styles.detailItemData]}>
        <Text
          style={[
            styles.textPrimeBold,
            styles.textOrange,
            styles.textAlignRight,
          ]}
          allowFontScaling={false}
          >
          {phoneNumber}
        </Text>
      </View>
    </View>
  );
};

export default textOrCallus;
