import React, { Component } from "react";
import { View } from "react-native";
import styles from "../../Css";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { setEmailVerified } from "../../store/actions";
import { toggleWatchOverview } from "../../store/actions/uistate";

class messageOverlay extends Component {
  constructor(props) {
    super(props);

    const { windowHeight, windowWidth } = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    };
    this.emailVerificationCheck = null;
  }

  componentDidMount() {
    const {emailVerified, setEmailVerified} = this.props;
    if(!emailVerified) {
      //starts a timer to check if email is verified
      this.emailVerificationCheck = setInterval(() => {
        Auth.currentUserInfo().then(res => {
          if(res.attributes.email_verified) {
            //email is verified
            setEmailVerified(true);
            this.stopEmailVerificationCheck();
            //brings up the watch an overview modal
            //toggleWatchOverviewVideo(true); //To fixed ZI-1132 & ZI-1137 (ZI-956 minor CR)
          }
        }).catch(() => {})
      }, 5000);
    }
  }

  componentWillUnmount() {
    this.stopEmailVerificationCheck();
  }

  stopEmailVerificationCheck = () => {
    //stops the email verification checker
    if (this.emailVerificationCheck) {
    clearInterval(this.emailVerificationCheck);
    this.emailVerificationCheck = null;
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth } = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  render() {
    const { windowHeight, outlookView } = this.props;
    let headerHeight = 57;
    if(outlookView){
      headerHeight = 0;
    }
    let overLayHeight = windowHeight - headerHeight;
    let topSpace = headerHeight;
    return (
        <View style={[styles.layoutOverlay, {top: topSpace, height: overLayHeight }]}></View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    emailVerified: state.auth.emailVerified,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setEmailVerified: (val) => dispatch(setEmailVerified(val)),
    toggleWatchOverviewVideo: (val) => dispatch(toggleWatchOverview(val))
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(messageOverlay);
