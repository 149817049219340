import React, {useState, useCallback, useEffect} from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
  TextInput,
} from 'react-native';
import {debounce} from 'lodash';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import FieldTitleCommon from '../../components/TextInput/fieldTitleCommon';
import NestedScroll from '../NestedScroll/nestedScroll';
import { WEB } from '../../util/platformWindowConfig';

let pending = Date.now();
const SLEEPING_TIME = Platform.OS === WEB ? 500 : 1000;
const CHUNKS = 20;

const TimeZoneDropDown = props => {
  const [open, setOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [textString, setTextString] = useState('');
  const [fragments, setFragments] = useState([]);
  const searchInList = useCallback(
    debounce(text => setSearchString(text), 500),
    [],
  );

  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  const {
    timeZoneList = {},
    selectedTimeZone,
    processing,
    updateTimeZone,
    journalUserId,
    updateTimeZoneDropDownOpen
  } = props;

  useEffect(() => {
    pending = Date.now();
    renderTimeZones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  const onDropdownSelect = flag => {
    if (flag) {
      pending = Date.now();
      renderTimeZones()
    } else {
      setTextString('');
      setSearchString('');
      pending = Date.now();
      setFragments([]);
    }
    setOpen(flag);
    if (journalUserId) updateTimeZoneDropDownOpen(flag);
  };

  const onClickUpdateTimeZone = item => {
    onDropdownSelect(false);
    updateTimeZone(item?.key);
  };

  const singleTimeZoneFragment = (item, idx) => {
    return (
      <TouchableOpacity
        key={item?.key + '_' + idx}
        onPress={() => onClickUpdateTimeZone(item)}
        disabled={item?.selected}>
        <View
          style={[
            styles.flexRow,
            styles.flexAiCt,
            styles.colPdLtRt,
            styles.messageTopBotPdSm,
            styles.bgBlueAutoSuggest,
            styles.dropDwnSelectFd,
          ]}>
            <View style={[styles.selectedItemRoundIcon]}>
                {item?.selected ? (
                  <Image
                    style={styles.timeZoneRadioBtn}
                    source={require('../../assets/icons/selection-round-tick.png')}
                  />
                ) : (
                  <Image
                    style={styles.timeZoneRadioBtn}
                    source={require('../../assets/icons/selection-round-default.png')}
                  />
                )}
            </View>
            <View style={{paddingLeft: 10}}>
              <Text style={styles.textTitle} allowFontScaling={false}>{item?.value}</Text>
            </View>
        </View>
      </TouchableOpacity>
    );
  };

  const renderTimeZones = async () => {
    const iAmPending = pending;
    let memoizedNumber = 0;
    let frags = [];

    const splitString = (param) => {
      return param.split("UTC")[1].split(")")[0].split(":");
    }

    const checkObject = key => {
      return (
        key.toLowerCase().includes(searchString.toLowerCase()) ||
        timeZoneList[key].toLowerCase().includes(searchString.toLowerCase())
      );
    };

    const sortByZone = (a, b) => {
      let [ahh, amm] = splitString(a);
      let [bhh, bmm] = splitString(b);
      return (+ahh * 60 + amm) - (+bhh * 60 + bmm)
    };

    const sortByName = (a, b) => {
      let [ahh, amm] = splitString(a);
      let [bhh, bmm] = splitString(b);
      if ((+ahh * 60 + amm) === (+bhh * 60 + bmm)) {
        const aKey = timeList.find(i => i.value === a).key;
        const bKey = timeList.find(i => i.value === b).key;
        if (aKey.toUpperCase() < bKey.toUpperCase()) {
          return -1;
        } else if (aKey.toUpperCase() > bKey.toUpperCase()) {
          return 1;
        }
      } else {
        return 0;
      }
    };

    const timeList = [];
    const timeListValues = [];

    for (const [key, value] of Object.entries(timeZoneList)) {
      console.log(`${key}: ${value}`);
      timeList.push({
        key, value
      })
      timeListValues.push(value);
    }
    const sortedZones = timeListValues.sort(sortByZone).sort(sortByName);

    const filteredArray = sortedZones.map(item => {
      return timeList.find(i => i.value === item).key
    }).filter(checkObject);

    // const filteredArray = Object.keys(timeZoneList).sort((a,b) => a.localeCompare(b)).filter(checkObject);
    for (const key of filteredArray) {
      if (iAmPending !== pending) break;
      const value = timeZoneList[key];
      memoizedNumber++;
      frags.push(
        singleTimeZoneFragment(
          {key, value, selected: key === selectedTimeZone},
          memoizedNumber,
        ),
      );
      if (
        memoizedNumber % CHUNKS === 0 ||
        memoizedNumber === filteredArray.length
      ) {
        // eslint-disable-next-line no-loop-func
        setFragments(fragments => memoizedNumber > CHUNKS ? [...fragments, ...frags] : [...frags]); //To avoid this warning need to use old traditional techniques
        if (memoizedNumber !== filteredArray.length - 1)
          await new Promise(r => setTimeout(r, SLEEPING_TIME));
        frags = [];
      }
    }
    if (filteredArray.length === 0) setFragments([]);
    if (iAmPending !== pending) return;
  };

  const search = text => {
    setTextString(text);
    searchInList(text);
  };

  return (
    <View
      style={[
        styles.setupBtnArea,
        styles.mgTopLg,
        styles.mgBtFourty,
      ]}>
      <FieldTitleCommon
        timeZoneFd={true}
        fieldAlign={props.fieldAlign}
        fieldBg={props.fieldBg}
        title={'Time Zone'}
        requirdStar={props.requirdStar}
        fieldLt={props.fieldLt}
        fieldSm={props.fieldSm}
        fieldRg={props.fieldRg}
        errorText={props.errorText}
        warningDuplication={props.warningDuplication}
        errorTextCom={props.errorTextCom}
      />
      <TouchableOpacity 
        onPress={() => onDropdownSelect(!open)} 
        disabled={processing} 
        style={[processing && {opacity: 0.5}]}
      >
      <View pointerEvents='none'>
      <TextInput
        style={[
          styles.timeZoneField,
          styles.bgWhite,
          styles.textFieldRg,
          styles.ltPdLg,
          styles.textTitle,
          styles.textSelectBordeRad,
          open && styles.textDeSelectBordeRad,
          open && styles.timeZoneAutoCompleteShadow,
          open && styles.borderBtmWdZero,
          
          {
            ...Platform.select({
              android: {
                color: '#000000', //To fixed non-editable TextInput color issue in android(ZI-1077)
              },
            }),
          },
        ]}
        allowFontScaling={false}
        selection={{
          start: 0,
          end: 0,
        }}
        editable={false}
        value={timeZoneList[selectedTimeZone]}
      />
      <View style={[styles.timeZoneDropDown]}>
          <Image
            style={styles.timeZoneDropDownIcn}
            source={require('../../assets/icons/down-arrow.png')}
          />
      </View>
      </View>
      </TouchableOpacity>

      {/* TimeZoneDropDown */}
      {Boolean(open) && (
        <View
          style={[
            stylesRes.autoCompleteSuggestionDataCom,
            stylesRes.rightAlignStyle,
            stylesRes.autoCompleteBgShadowCr,
            stylesRes.autoCompleteBgWhite,
            stylesRes.autoCompleteBorder,
            // stylesRes.medicationsDrpDownStyle,
            stylesRes.autoCompleteDataMgLgCr,
            styles.textSelectBordeRad,
            styles.bgBlueAutoSuggest,
            styles.noPdTop,
            styles.pdBtOne,
          ]}>
          {/* TimeZone Input */}
          <View
            style={[
              styles.flexRow,
              styles.flexAiCt,
              styles.heightFourtyFive,
              styles.colPdLtRt,
              styles.bgWhite,
            ]}>
            <Image
              style={styles.contTypeIcon}
              source={require('../../assets/icons/new-search-icon.png')}
            />
            <TextInput
              style={[styles.ltPdLg, styles.hudredPercentWd, styles.textTitle]}
              placeholder={'Search'}
              placeholderTextColor={'#B5B5B6'}
              value={textString}
              onChangeText={search}
            />
          </View>
          <View style={[styles.borderBtLtRadius, styles.borderBtRtRadius]}>
            <View style={styles.mxHeightHudredEightyFive}>
              {/* selection drop down */}
              <NestedScroll>{fragments}</NestedScroll>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

export default TimeZoneDropDown;
