import React, { Component } from 'react';
import { View } from 'react-native';
import moment from 'moment';
import { cancelTokenSource } from '../../axios/axios-config-common.web';
import { IMAGES_DOCUMENTS } from '../../navigation/path';
import { freeStorageByte, premiumStorageByte } from '../../Constant';
import { pendoTrackEvent } from '../../util/pendoConfig';

const MAX_FILE_SIZE = 262144000;
const MAX_FILE_SIZE_ERROR = 'Sorry. File exceeds the maximum upload size of 250MB.';

export default class AddImagesDocumentsCommon extends Component {

  handleAddPressedForUpload = () => {
    this.setState({
      visibleEntryModal: true,
      selected: 3,
    });
  };


  getFileAndImage = (filesArray, callBack) => {
    const files = {};
    const images = {};
    let index = 0;
    let fileSize = 0;
    for (let i = 0; i < filesArray.length; i++) {
      const file = filesArray[i];
      var reader = new FileReader();
      fileSize += file.size;
      // eslint-disable-next-line no-loop-func
      reader.onload = function(event) {
        files[index] = file;
        images[index] = event.target.result;
        index++;
        callBack(files, images, index, fileSize);
      };
      reader.readAsDataURL(file);
    }

    setTimeout(() => {
      this.setState({files, images, index, fileSize});
    }, 1000);
  };

  /**
   *
   * @param {*} croppedImages
   * @param {*} fileNamePrefix
   * @param {*} uploadType : There are two value types > CAMERA, FILE_UPLOAD
   */
  onChangeFileUpload = (croppedImages, fileNamePrefix, uploadType) => {
    this.setState({visibleEntryModal: false});
    let files;
    let fileSize = 0;
    let index = 0;
    let images = {};
    let isFileTooLarge = false;
    if (this.state.files) {
      files = {...this.state.files};
      fileSize = this.state.fileSize;
      index = this.state.index;
      images = {...this.state.images};
    } else {
      files = [];
    }

    pendoTrackEvent('Attachments > UploadFile');
    if (uploadType === 'FILE_UPLOAD') {
      const e = croppedImages;
      this.setState({ isFileReading: true });

      for (let i = 0; i < e.target.files.length; i++) {
        // eslint-disable-next-line no-loop-func
        (() => {
          const file = e.target.files[i];
          if (file.size <= MAX_FILE_SIZE) {
            if (file.size > 0) {
              var reader = new FileReader();
              reader.onload = function(event) {
                files[index] = file;
                images[index] = event.target.result;
                index++;
              };
              reader.readAsDataURL(file);
              fileSize += file.size;
            }
          } else {
            isFileTooLarge = true;
          }
        })();
      }

      this.setState({ isFileReading: false });

      const {applicationSettingsDigest} = this.props;
      const {uploadedFileSize} = applicationSettingsDigest;
      const totalFileSize = uploadedFileSize + fileSize;
      e.target.value = null;
      if (isFileTooLarge) {
        this.setState({
          uploadError: true,
          uploadErrorMesage: MAX_FILE_SIZE_ERROR,
        });
      } else if (
        this.state.accountType === 'regular' &&
        totalFileSize > freeStorageByte
      ) {
        this.props.showHideStorageBanner(true);
      } else if (
        this.state.accountType === 'premium' &&
        totalFileSize > premiumStorageByte
      ) {
        this.props.showHideStorageBanner(true);
      } else {
        setTimeout(() => {
          this.setState({
            files: files,
            fileSize: fileSize,
            index: index,
            images: images,
            uploadError: false,
            uploadErrorMesage: null,
          });
        }, 1000);
      }
    }

    if (uploadType === 'CAMERA' && croppedImages.length) {
      const generatedFileName = `${new Date().getTime()}`;
      croppedImages.forEach((croppedImageItem, croppedImageItemIndex) => {
        const { cropImageUrl, image64 } = croppedImageItem;

        let updatedFileName = fileNamePrefix || generatedFileName;
        if (croppedImages.length > 1) {
          updatedFileName = `${updatedFileName}_Pages${croppedImageItemIndex+1}of${croppedImages.length}`;
        }
        updatedFileName = `${updatedFileName}.png`;
        const file = this.blobToFile(cropImageUrl, updatedFileName);

        var reader = new FileReader();
        reader.onload = function(event) {
          files[index] = file;

          images[index] = image64;

          index++;
        };
        reader.readAsDataURL(file);
        fileSize += file.size;
      });

      const {applicationSettingsDigest} = this.props;
      const {uploadedFileSize} = applicationSettingsDigest;
      const totalFileSize = uploadedFileSize + fileSize;
      if (
        this.state.accountType === 'regular' &&
        totalFileSize > freeStorageByte
      ) {
        this.props.showHideStorageBanner(true);
      } else if (
        this.state.accountType === 'premium' &&
        totalFileSize > premiumStorageByte
      ) {
        this.props.showHideStorageBanner(true);
      } else {
        setTimeout(() => {
          this.setState({
            files: files,
            fileSize: fileSize,
            index: index,
            images: images,
            uploadError: false,
            uploadErrorMesage: null,
            visibleImageTakePhoto: false,
          });
        }, 1000);
      }
    }
  };

  /**
   * This is old method. Renamed with _temp.
   * This is kept for functionality verification.
   * @param {*} e
   * @param {*} image64
   */
  onChangeFileUpload_temp = (e, image64) => {
    this.setState({visibleEntryModal: false});
    let files;
    let fileSize = 0;
    let index = 0;
    let images = {};
    let isFileTooLarge = false;
    if (this.state.files) {
      files = {...this.state.files};
      fileSize = this.state.fileSize;
      index = this.state.index;
      images = {...this.state.images};
    } else {
      files = [];
    }
    if (!image64) {
      for (let i = 0; i < e.target.files.length; i++) {
        // eslint-disable-next-line no-loop-func
        (() => {
          const file = e.target.files[i];
          if (file.size <= MAX_FILE_SIZE) {
            var reader = new FileReader();
            reader.onload = function(event) {
              files[index] = file;
              images[index] = event.target.result;
              index++;
            };
            reader.readAsDataURL(file);
            fileSize += file.size;
          } else if (!isFileTooLarge) {
            isFileTooLarge = true;
          }
        })();
      }
      const {applicationSettingsDigest} = this.props;
      const {uploadedFileSize} = applicationSettingsDigest;
      const totalFileSize = uploadedFileSize + fileSize;
      e.target.value = null;
      if (fileSize > MAX_FILE_SIZE || isFileTooLarge) {
        this.setState({
          uploadError: true,
          uploadErrorMesage: MAX_FILE_SIZE_ERROR,
        });
      } else if (
        this.state.accountType === 'regular' &&
        totalFileSize > freeStorageByte
      ) {
        this.props.showHideStorageBanner(true);
      } else if (
        this.state.accountType === 'premium' &&
        totalFileSize > premiumStorageByte
      ) {
        this.props.showHideStorageBanner(true);
      } else {
        setTimeout(() => {
          this.setState({
            files: files,
            fileSize: fileSize,
            index: index,
            images: images,
            uploadError: false,
            uploadErrorMesage: null,
          });
        }, 1000);
      }
    } else {
      const timestamp = `${new Date().getTime()}.png`;
      const file = this.blobToFile(e, timestamp);

      if (file.size < MAX_FILE_SIZE) {
        var reader = new FileReader();
        reader.onload = function(event) {
          files[index] = file;
          images[index] = image64;
          index++;
        };
        reader.readAsDataURL(file);
        fileSize += file.size;
      } else if (!isFileTooLarge) {
        isFileTooLarge = true;
      }
      setTimeout(() => {
        this.setState({
          files: files,
          fileSize: fileSize,
          index: index,
          images: images,
          uploadError: false,
          uploadErrorMesage: null,
          visibleImageTakePhoto: false,
        });
      }, 1000);
    }
  };

  handleFileRename = (res, newFileName) => {
    const { files } = this.state;
    const updatedFiles = { ...files };

    const renamedFileObject = new File([files[res]], newFileName, { type: files[res].type });
    updatedFiles[res] = renamedFileObject;

    //setTimeout(() => {
      this.setState({
        files: updatedFiles,
      });
    //}, 1000);
  };

  redirectAttachImagesDocumentsToContact = () => {
    this.props.navigation.replace(IMAGES_DOCUMENTS.attachToContactPath);
  };

  redirectAttachImagesDocumentsToTreatment = () => {
    this.props.navigation.replace(IMAGES_DOCUMENTS.attachToTreatmentPath);
  };

  redirectAttachImagesDocumentsToJournal = () => {
    this.props.navigation.replace(IMAGES_DOCUMENTS.attachToJournalPath);
  };

  onSaveFilesToAttach = () => {
    const newObject = Object.assign({}, this.state.files);
    Object.keys(this.state.files).forEach(key => {
      newObject[key].cancelToken = cancelTokenSource.source();
    })
    this.props.onSaveFilesToAttach(newObject);
  };

  handleFileUpload = (artifactId) => {

    this.props.setAttachmentUploadStatus(true);
    this.props.setAttachmentArtifactId(artifactId);
    let fileSize = 0;
    Object.values(this.props.files).forEach((res, index) => {
      fileSize += res.size;
    });
    this.props.setAttachmentFullSize(fileSize);
    
    this.props.showUploadProgress();
  }

  // New Quick Note
  handleCreateNewRoutine = () => {
    this.setState({showSpinner: true});

    let newTemplate = {
      id: -1,
      title: Object.keys(this.state.files).length < 2 ? `${Object.keys(this.state.files).length} File` : `${Object.keys(this.state.files).length} Files` ,
      entryDate: moment.utc().valueOf(),
      entryType: this.props.applicationSettingsDigest.quickNoteEntryTypeId,
    };
    this.props.onSaveJournalEntry(
      {journalEntry: newTemplate},
      (res, data) => {
        if (res) {
          // const currDate = moment.utc().format("x");
          // this._createQuestion(data.artifactId, currDate);
          const artifactId = data.journalEntry.artifactId;
          this.onSaveFilesToAttach();
          this.handleFileUpload(artifactId);
          this.setState({showSpinner: false, confirmBox: false});
          this.props.navigation.push(
            IMAGES_DOCUMENTS.path, {
              from: this.props.route.name,
            },
          );
        } else {
          this.setState({showSpinner: false});
        }
      },
      this.props.journalUserId,
    );
  };

  handleDragAndDropFileSelect = (droppedFiles = []) => {
    const filesToBeProcessed = {
      target: {
        files: droppedFiles,
      }
    }
    this.onChangeFileUpload(filesToBeProcessed, null, 'FILE_UPLOAD');
  };

  render() {
    return (<View />);
  }
}

