import React, {Component} from 'react';
import {View} from 'react-native';
import styles from '../../Css';
import Header from '../../components/Header/header';
import Surveys from './Surveys';
import {SURVEYS, DASHBOARD} from '../../navigation/path';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import {surveyTabs} from '../../Constant/index';
import { pendoTrackEvent } from '../../util/pendoConfig';

class SurveysScreen extends Component {
  constructor(props) {
    super(props);
    const {route, setActiveTab} = props;
    if (
      route &&
      route.params &&
      route.params.getPath &&
      route.params.getPath === 'COMPLETED'
    ) {
      setActiveTab(surveyTabs.completed);
    }else{
      setActiveTab(surveyTabs.open);
    }
  }

  componentDidMount = () => {
    const {route, setSurveyListFromUrl} = this.props;
    const {params} = route;

    if (params?.from) {
      setSurveyListFromUrl(params.from);
    }
    pendoTrackEvent('Surveys@');
  };

  redirectMainSurvey = (status = false) => {
    if (!status) {
      this.props.navigation.push(SURVEYS.mainSurveyPath);
    } else {
      this.props.navigation.push(SURVEYS.mainSurveyPath, {
        status: status ,
      });
    }
  };

  redirectSurveyQuestions = () => {
    this.props.navigation.replace(SURVEYS.surveyQuestionPath, {
      from: SURVEYS.path
    });
  };
  render() {
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => {
            const {fromUrl, navigation, route} = this.props;
            if (!fromUrl) {
              navigation.reset({
                routes: [{ name: DASHBOARD.path }]
              });
            } else {
              navigation.push(fromUrl, {
                from: route.name,
              })
            }
          }}
          index={SURVEYS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <Surveys
          redirectMainSurvey={this.redirectMainSurvey}
          redirectSurveyQuestions={this.redirectSurveyQuestions}
          navigation={this.props.navigation}
          activeTab={this.props.activeTab}
        />
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    fromUrl: state.surveys.fromUrl,
    activeTab: state.surveys.activeTab,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSurveyListFromUrl: fromUrl =>
      dispatch(actions.setSurveyListFromUrl(fromUrl)),
    setActiveTab: activeTabIndex =>
      dispatch(actions.setActiveTab(activeTabIndex)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurveysScreen);
