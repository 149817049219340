import React from "react";
import { Text, View } from "react-native";
import styles from "../../Css";

const successCopyToNewBox = (props) => {
  return (
    <View style={[styles.popMessageContView, styles.comSp]}>
      <View style={[styles.popMessageTitleView, styles.comMgBotExSm]}>
        <Text style={[styles.textTitleBold, styles.textAlignCenter]} allowFontScaling={false}>
          Success!
        </Text>
      </View>

      <View style={[styles.popMessageDescView, styles.comMgBot]}>
        <Text style={[styles.textTitle, styles.textAlignCenter]} allowFontScaling={false}>
          A new entry was created
        </Text>
      </View>

      <View style={[styles.popMessageDescView, styles.comMgBotExSm]}>
        <Text style={[styles.textTitleBold, styles.textAlignCenter]} allowFontScaling={false}>
          Data from your entry was copied:
        </Text>
      </View>

      <View style={[styles.popMessageDescList]}>
        <View style={[styles.popMessageDescListItem, styles.comMgBotExSm]}>
          <Text
            style={[
              styles.textTitle,
              styles.textEm,
              styles.textAlignCenter,
              styles.textColorLight,
            ]}
            allowFontScaling={false}
          >
            Medications/Supplements
          </Text>
        </View>
        <View style={[styles.popMessageDescListItem, styles.comMgBotExSm]}>
          <Text
            style={[
              styles.textTitle,
              styles.textEm,
              styles.textAlignCenter,
              styles.textColorLight,
            ]}
            allowFontScaling={false}
          >
            Activities
          </Text>
        </View>

        <View style={[styles.popMessageDescListItem, styles.comMgBotExSm]}>
          <Text
            style={[
              styles.textTitle,
              styles.textEm,
              styles.textAlignCenter,
              styles.textColorLight,
            ]}
            allowFontScaling={false}
          >
            Symptoms
          </Text>
        </View>

        <View style={[styles.popMessageDescListItem, styles.comMgBotExSm]}>
          <Text
            style={[
              styles.textTitle,
              styles.textEm,
              styles.textAlignCenter,
              styles.textColorLight,
            ]}
            allowFontScaling={false}
          >
            Health Data
          </Text>
        </View>
      </View>
    </View>
  );
};
export default (successCopyToNewBox);
