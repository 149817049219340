import React from "react";
import { Image, View, Text, TouchableOpacity, ScrollView } from "react-native";
import styles from "../../../Css";
import stylesResponsive from '../../../CssResponsive';
import Header from "../../Header/header";
import SpaceBar from "../../SpaceBar/SpaceBar";
import PageContainer from "../../PageLayout/PageContainer";
import CardItem from "../Card/CardItem";
import ActivityIndicator from '../../ActivityIndicator/activityIndicator';

const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
const FindPeersResults = (props) => {
  const {
    peersResults,
    handleOnPressCreateProfile,
    windowHeight,
    journalUserId,
    index,
    navigation,
    handleOnPressBackButton,
    windowWidth,
    loading,
    route,
    hasNotch,
  } = props;
  
  //const { peerProfiles, totalMatchedPeers, conditionName } = peersResults;
  let fullHeight = windowHeight;
  let headerHeight = 57;
  let btnHeight = 0;
  let careGiverAlertHeight = 0;
  if (journalUserId) {
    careGiverAlertHeight = 31;
  }
  let notchHeight = 0;
  if (hasNotch) {
    notchHeight = 50;
  }
  let totHeight = headerHeight + btnHeight + careGiverAlertHeight + notchHeight;
  let scrollHeight = fullHeight - totHeight;
  if (scrollHeight < 50) {
    scrollHeight = 50;
  }
  let cardSectionHeight = 515;
  let displayCount = 5;
  if (scrollHeight < cardSectionHeight) {
    displayCount = 3;
  }

  return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={true}
        hideCaregiverAccess={true}
        handleOnPressBackButton={handleOnPressBackButton}
        index={index}
        navigation={navigation}
        route={route}
      />
      <SpaceBar />
      <View style={{ height: scrollHeight }}>
        <ScrollView style={styles.scrollViewArea}>
          <View style={[styles.resultsHeader, styles.bgpage, styles.flexJcCtAiCt]}>
            <View
              style={[
                styles.commonTopBlock,
                styles.peerTopCom
              ]}>
              <View style={styles.commonTopImg}>
                <View
                  style={[
                    styles.commonTopIconView,
                  ]}>
                  <Image
                    style={[styles.imgCover]}
                    source={require('../../../assets/setup/bg-intro-top-com.png')}
                  />
                </View>
              </View>
            </View>

            <View style={[styles.resultsTitleHeader,
              windowWidth > 767
              ? styles.resultsTitleCom
              : windowWidth > 574
              ? styles.resultsTitleResMd
              : windowWidth > 374
              ? styles.resultsTitleRes
              : styles.resultsTitleResSpec]}>
              <Text
                style={[
                  styles.textAlignCenter,
                  windowWidth < 768
                  ? styles.textPrimeLg
                  : styles.textTitle
                ]}
                allowFontScaling= {false}
              >
                Connect with Zamplo Community members worldwide   
              </Text>
            </View>

            <View style={stylesRes.commonBotImgArea}>
              <View
                style={[
                  styles.commonBotIconView,
                ]}>
                <Image
                  style={styles.imgCover}
                  source={require('../../../assets/setup/bg-intro-bottom-com.png')}
                />
              </View>
            </View>
          </View>
          <PageContainer>
            <View style={[styles.btnArea]}>
              <View style={[styles.comBtnWrapper, styles.comBtnWrapperSm]}>
                <View style={styles.fieldBtn}>
                  <View style={styles.btnBlockFull}>
                    <TouchableOpacity
                      nativeID={'communityLatestConnectionsCreateProfile'}
                      style={[
                        styles.submitSm,
                        styles.submitBgOrange,
                        styles.btnShadowCom,
                        styles.btnBorderNone,
                      ]}
                      underlayColor="rgba(0, 153, 168, 0.8)"
                      onPress={handleOnPressCreateProfile}
                    >
                      <View style={styles.btnSingleView}>
                        <View style={[styles.btnSingle, styles.btnSingleCent]}>
                          <Text style={[styles.textPrimeBold, styles.textWhite]} allowFontScaling= {false}>
                          Create profile
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.gridSection}>
              <View style={styles.gridRow}>
                <View style={[styles.gridColFull]}>
                  <View style={[styles.colPdTopBotLg]}>
                    <View style={[styles.secArea]}>
                      <View style={[styles.resultsTitleSubHeader]}>
                        <Text
                          style={[
                            windowWidth < 768
                            ? styles.textPrimeLg
                            : styles.textTitle
                          ]}
                          allowFontScaling= {false}
                        >
                          Recent Zamplo Community members   
                        </Text>
                      </View>
                    </View>
                    {loading
                      ? <ActivityIndicator pdLarge={true} />
                      : <View style={[styles.secArea]}>
                          <View style={styles.cardContainer}>
                            {peersResults && peersResults.length > 0 && (
                              peersResults.map(
                                (element, index) =>
                                  displayCount > index && (
                                    <CardItem
                                      peerProfile={element}
                                      //conditionName={conditionName} //flow on hold until considarable number of peers join to the community(ZI-1545)
                                      displayInfo={true}
                                      key={index}
                                    />
                                  )
                              )
                            )}
                          </View>
                        </View>
                    }
                  </View>
                </View>
              </View>
            </View>
          </PageContainer>
        </ScrollView>
      </View>
    </View>
  );
};

export default (FindPeersResults);
