import {StyleSheet} from 'react-native';
import theme from '../../../css/base/theme';

export default StyleSheet.create({

  attestationMargin: {
    marginBottom: 15
  },

  btnMaxWidthBorder: {
    width: 150,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: theme.COLOR_ORANGE,
    borderRadius: 5,
  },

  submitBtn: {
    borderColor: '#43ADB9',
  },

  submitBtnText: {
    color: '#43ADB9',
    marginLeft: 5,
  },

  innerScrollWrapper: {
    paddingBottom: 0,
  },

});

