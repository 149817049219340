import React, { Component } from "react";
import { View, ActivityIndicator } from "react-native";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";
import { connect } from "react-redux";
import ContactCamera from "../../../modules/webCamForUploadAttachment/webCam";
import ImageCropper from "../../../modules/imageCropperForAttachment/attachmentImageCropper";
import * as actions from "../../../store/actions/index";
import AddFileNamePrefix from './addFileNamePrefix';
import AttachmentImagePreview from "../../../modules/imageCropperForAttachment/attachmentImagePreview";

class ImageTakephoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      imageUrl: null,
      artifactId: this.props.artifactId,
      croppedImages: [],
      visibleAddFileNameModal: false,
      visibleImagePreview: false,
      croppedImageToPreview: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { windowHeight, windowWidth } = nextProps;

    this.setState({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    });
  }

  onimageSrc = (imageUrl) => {
    this.setState({
      imageUrl: imageUrl,
      retake: false,
      message: "Adjust to crop image",
    });
  };

  blobToFile = (theBlob, fileName) => {
    theBlob.filename = fileName;
    theBlob.name = fileName;
    return theBlob;
  };

  onCropImage = (cropImageUrl, image64) => {
    if (!this.props.artifactId) {
      // this.props.onCropAddImage(cropImageUrl, image64);
      const { croppedImages } = this.state;
      this.setState({
        imageUrl: null,
        croppedImages: [...croppedImages, ...[{ cropImageUrl, image64 }]],
        visibleImagePreview: true,
      });

      const generatedFileName = `${new Date().getTime()}.png`;
      const file = this.blobToFile(cropImageUrl, generatedFileName);
        var reader = new FileReader();
        reader.onload = (event) => {
          this.setState({ croppedImageToPreview: event.target.result });
        }
        reader.readAsDataURL(file);
    } else {
      if (cropImageUrl) {
        this.setCreateSpinner();
        const formData = new FormData();

        let timestamp = `${new Date().getTime()}.png`;

        formData.append("files[0]", cropImageUrl, timestamp);

        this.props.onUpload(
          this.state.artifactId,
          formData,
          (success, attachments) => {
            if (success && attachments) {

            } else {
              this.props.uploadError();
            }
            this.setCreateSpinner();

            this.props.cropSuccess();
          },
          true,
          this.props.journalUserId,
          cropImageUrl.size
        );
      }
    }
  };

  setCreateSpinner = () => {
    this.setState({ createSpinner: !this.state.createSpinner });
  };

  createContact = () => {
    let addNewContact = {};
    this.setCreateSpinner();
    this.props.addContact(
      addNewContact,
      this.props.contacts,
      (res) => {
        if (res) {
          this.props.history.push("/add-contact");
        }
        this.setCreateSpinner();
      },
      this.props.journalUserId
    );
  };

  handleImageNamePrefixSave = (fileNamePrefix) => {
    const { onCropAddImage } = this.props;
    const { croppedImages } = this.state;

    this.setState({ visibleAddFileNameModal: false });
    onCropAddImage(croppedImages, fileNamePrefix);
  };

  handleImageCropperDonePressed = () => {
    this.setState({
      visibleAddFileNameModal: true,
      //croppedImageToPreview: null,
    });
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    let fullHeight = this.state.windowHeight;
    let fullWidth = this.state.windowWidth;
    let scanWidth = "100%";
    let scanHeight = "auto";
    let maxContHeight = fullHeight;
    let headerHeight = 57;
    let btnHeight = 120;
    let careGiverAlertHeight = 0;
    if (this.props.journalUserId) {
      careGiverAlertHeight = 31;
    }
    let totHeight = headerHeight + btnHeight + careGiverAlertHeight;
    maxContHeight = fullHeight - totHeight;
    
    return (
      <View style={[styles.camModalWrapper]}>
        {this.state.createSpinner ? (
          <ActivityIndicator color="#FF8300" size="small" />
        ) : (
          <View style={[styles.popUpBlockFull, styles.modalScan]}>
            <View>
              <View style={[styles.popUpBlock, styles.menuBg, styles.newUploadPopUpBlock]}>
                <View style={styles.popUpContentArea}>
                  <View style={styles.gridSectionPopup}>
                    <View style={[styles.boxShadowModalNone]}>
                      <View
                        style={stylesRes.modalContIn}
                        //removed stylesRes.colPdComScan
                      >
                        <View style={styles.gridRow}>
                          <View style={styles.gridColFull}>
                            <View style={[styles.infoBlock]}>
                              <View style={stylesRes.imgPreviewSecCom}>
                                <View
                                  style={[
                                    stylesRes.scanFullArea,
                                    stylesRes.newScanFullArea,
                                   //{ height: maxContHeight },
                                  ]}
                                >

                                  {!Boolean(this.state.imageUrl) && !this.state.visibleImagePreview &&
                                  <ContactCamera
                                    onimageSrc={this.onimageSrc}
                                    scanHeight={scanHeight}
                                    scanWidth={scanWidth}
                                    maxContHeight={maxContHeight}
                                    windowWidth={this.state.windowWidth}
                                    windowHeight={this.state.windowHeight}
                                    paddingAll={true}
                                    modalView={true}
                                  />}

                                  {Boolean(this.state.imageUrl) && !this.state.visibleImagePreview &&
                                  <ImageCropper
                                    imageUrl={this.state.imageUrl}
                                    onCropImage={this.onCropImage}
                                    contactCamera={() => {
                                      this.setState({ imageUrl: null });

                                      this.createContact();
                                    }}
                                    scanHeight={scanHeight}
                                    scanWidth={scanWidth}
                                    maxContHeight={maxContHeight}
                                    windowWidth={this.state.windowWidth}
                                    windowHeight={this.state.windowHeight}
                                    paddingAll={true}
                                    onDonePressed={this.handleImageCropperDonePressed}
                                  />}

                                  {!Boolean(this.state.imageUrl) && this.state.visibleImagePreview && this.state.croppedImages.length > 0 &&
                                  <AttachmentImagePreview
                                    imageUrl={this.state.croppedImageToPreview}
                                    scanHeight={scanHeight}
                                    scanWidth={scanWidth}
                                    maxContHeight={maxContHeight}
                                    windowWidth={this.state.windowWidth}
                                    windowHeight={this.state.windowHeight}
                                    paddingAll={true}
                                    onDonePressed={this.handleImageCropperDonePressed}
                                    onTakePhotoAgain={() => this.setState({ visibleImagePreview: false, croppedImageToPreview: null })}
                                    imageCount={this.state.croppedImages.length}
                                  />}
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        )}

        <AddFileNamePrefix
          visible={this.state.visibleAddFileNameModal}
          onClose={() => this.setState({ visibleAddFileNameModal: false })}
          onSave={this.handleImageNamePrefixSave}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    contacts: state.routines.contacts,
    journalUserId: state.uistate.journalUserId,
    user: state.routines.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onUpload: (artifactId, formData, callBack, reload, journalUserId, fileSize) =>
      dispatch(
        actions.uploadAttachmen(
          artifactId,
          formData,
          callBack,
          reload,
          journalUserId,
          fileSize
        )
      ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageTakephoto);
