import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import {View, Keyboard} from 'react-native';
import styles from '../../../Css';
import * as FieldDetails from '../../TextInput/fieldDetails';
//import AutoCompleteFrame from '../../AutoComplete/AutoCompleteFrame';
import AutoCompleteSuggestion from '../../AutoComplete/AutoCompleteSuggestion';
import {autoSuggestionListType} from '../../../Constant/index';

class StepTwoAddActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.activeTherapieTab === 1 && props.therapieName ? props.therapieName : '',
      terms: [],
      selectedValues: StepTwoAddActivity.getSelectedActivities(
        props.treatments,
        props,
      ),
    };
  }

  componentDidMount() {
    this.getSystemActivity();
  }

  getSystemActivity = () => {
    this.props.systemDefinedActivities((result, data) => {
      if (result) {
        this.setState({terms: data});
      }
    });
  };

  static getDerivedStateFromProps(props, state) {
    const {treatments} = props;

    return {
      selectedValues: StepTwoAddActivity.getSelectedActivities(treatments, props),
    };
  }

  static getSelectedActivities = (treatments, props) => {
    const selectedValues = {};
    const {treatmentsProcessed, userTreatments} = props;

    if (treatments && treatments.length > 0) {
      treatments.forEach(element => {
        if (element.treatmentType === 5) {
          selectedValues[element.name] = element.name;
        }
      });
    }
    if (userTreatments && userTreatments.length > 0) {
      userTreatments.forEach(item => {
        if (item.treatmentType === 5) {
          const obj = treatmentsProcessed[item.id];
          if (obj) {
            selectedValues[obj.name] = obj.name;
          }
        }
      });
    }
    return selectedValues;
  };

  onChangeTextName = currentInput => {
    this.props.onUpdateTherapieName(currentInput, 1);
    this.setState({
      name: currentInput,
    });
  };

  onPressCustomName = () => {
    Keyboard.dismiss();
    if (this.state.name && this.validateTreatment()) {
      let tempTreatments = [];
      let filteredItems = [];
      if (this.props.treatments) {
        tempTreatments = [...this.props.treatments];
        filteredItems = tempTreatments.filter(
          res => res.name === this.state.name,
        );
      }
      if (filteredItems.length === 0) {
        const name = this.state.name;
        let treatmentObject = {
          id: -1,
          name: name,
          notes: null,
          prescribedBy: null,
          healthProviderName: '',
          identificationNumber: null,
          treatmentType: 5,
          active: true,
          isActive: true,
          dosages: {
            id: -1,
            start: new Date().getTime(),
            end: null,
            quantity: 0,
            frequency: 1,
            frequencyType: 'DAILY',
            instructions: null,
            units: 'minutes',
          },
          defaultMetrics: [],
          isConfirm: false,
          type: 'custom'
        };

        tempTreatments.push(treatmentObject);
        this.props.updateTreatment(tempTreatments, () => {
          this.setState({
            name: '',
          });
          this.props.updateInActive(false);
        });
      }
      this.setState({
        name: '',
      });

      this.props.onUpdateTherapieName('', 0);
    }
  };

  validateTreatment = () => {
    let valid = true;
    if (this.props.treatments && this.props.treatments.length !== 0) {
      valid = this.props.treatments.some(
        res => !(res.name === this.state.name),
      );
    }
    return valid;
  };

  clearInputAndTerms = () => {
    this.setState({
      name: '',
    });
  };

  onItemPressName = (selection, type) => {
    this.setState(
      {
        name: selection.name,
      },
      () => {
        // this.onPressCustomName();
        if (this.state.name && this.validateTreatment()) {
          let tempTreatments = [];
          let filteredItems = [];
          if (this.props.treatments) {
            tempTreatments = [...this.props.treatments];
            filteredItems = tempTreatments.filter(
              res => res.name === this.state.name,
            );
          }
          if (filteredItems.length === 0) {
            const name = this.state.name;
            let treatmentObject = {
              id: -1,
              name: name,
              notes: null,
              prescribedBy: null,
              healthProviderName: '',
              identificationNumber: null,
              treatmentType: 5,
              active: true,
              isActive: true,
              dosages: {
                id: -1,
                start: new Date().getTime(),
                end: null,
                quantity: 0,
                frequency: 1,
                frequencyType: 'DAILY',
                instructions: null,
                units: 'minutes',
              },
              defaultMetrics: [],
              isConfirm: false,
              type: null
            };
    
            tempTreatments.push(treatmentObject);
            this.props.updateTreatment(tempTreatments, () => {
              this.setState({
                name: '',
              });
              this.props.updateInActive(false);
            });
          }
          this.setState({
            name: '',
          });
    
          this.props.onUpdateTherapieName('', 0);
        }
      },
    );
  };

  render() {
    return (
      <View style={[styles.fieldRow, styles.zIndexAutoComplete]}>
        <View style={[styles.fieldRowWrapper]}>
          <View style={styles.fieldColStretch}>
            <AutoCompleteSuggestion
              textFieldWithTitle={true}
              textFieldTitle="Activity"
              triggerLength={1}
              onChangeText={this.onChangeTextName}
              onItemPress={this.onItemPressName}
              currentInput={this.state.name}
              onPressCustom={this.onPressCustomName}
              customSectionTitle={'Add Custom Meds/Activity/Therapy'}
              showCustomSection={true}
              autoFocus={true}
              fieldBg={FieldDetails.BGWHITE}
              fieldLt={true}
              terms={this.state.terms}
              selectedValues={this.state.selectedValues}
              placeholder="ex: Cycling"
              updateTermList={() => this.getSystemActivity()}
              type={autoSuggestionListType.activity}
              zoomIcon={true}
              removeIcon={true}
              updateAutocompleteOpen={this.props.updateAutocompleteOpen}
            />
          </View>
        </View>
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    treatments: state.setup.treatments,
    therapieName: state.setup.therapieName,
    activeTherapieTab: state.setup.activeTherapieTab,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    userTreatments: state.routines.treatments,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateTreatment: (addNewTreatment, callBack) =>
      dispatch(actions.updateTreatment(addNewTreatment, callBack)),
    onUpdateTherapieName: (name, activeTab) => dispatch(actions.updateTherapieName(name, activeTab)),
    updateTherapie: (addNewTherapie, callBack) =>
      dispatch(actions.updateTherapie(addNewTherapie, callBack)),
    systemDefinedActivities: callBack =>
      dispatch(actions.systemDefinedActivities(callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepTwoAddActivity);
