import React from 'react';
import { View, TouchableOpacity, Platform } from 'react-native';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'underscore';
import UIText from '../Text/text';
import ReportDetail from './ReportDetail';
import ReportSymptom from './ReportSymptom';
import ReportIssues from './ReportIssues';
import ReportTreatment from './ReportTreatment';
import ReportImagesAndDocuments from './ReportImagesAndDocuments';
import ReportGraph from './ReportGraph';
import ReportAddEditNote from './ReportAddEditNote';
import PageContainer from '../PageLayout/PageContainer';
import ActivityIndicator from '../ActivityIndicator/activityIndicator';
import SavingConfirmation from '../ConfirmationBox/savingConfirmation';
import Header from '..//Header/header';
import SpaceBar from '../SpaceBar/SpaceBar';
import KeyboardAwareScrollViewHoc from '../KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import MenuOverlayContainer from '../PageLayout/MenuOverlayContainer';
import CancelButton from '../../components/Button/cancelButton';
import ReportCommon from './ReportCommon';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import {hasValue} from '../../util/hashValueGenerator';
import {alertBody, reportTitle} from '../../GlobalFunctions/GlobalString';
import * as actions from '../../store/actions/index';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { WEB } from '../../util/platformWindowConfig';

class Report extends ReportCommon {
  constructor(props) {
    super(props);
    this.state = {
      confirmBox: false,
      isNewReport: props.id ? false : true,
      validNote: true,
      saveWait: false,
    };
    this.props.hideMobileMenu();
    this.scrollViewRef = null;
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
      this.setState({
        isNewReport: this.props.id ? false : true,
      });
    });
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    });
  }

  handleOnPressCancel = () => {
    if (this.getHashValue() === this.props.initHashValue) {
      this.props.resetReport();
      this.props.redirectReportList();
    } else {
      this.setState({confirmBox: true});
    }
  };

  getHashValue = () => {
    const currentHash = hasValue(
      this.props.id +
        ',' +
        this.props.startDate +
        ',' +
        this.props.endDate +
        ',' +
        this.props.name +
        ',' +
        this.props.note +
        ',' +
        JSON.stringify(this.props.filteredRecordedSymptom) +
        ',' +
        JSON.stringify(this.props.filteredRecordedDosing) +
        ',' +
        JSON.stringify(this.props.selectedIssues) +
        ',' +
        JSON.stringify(this.props.selectedImages) +
        ',' +
        JSON.stringify(this.props.savedGraph),
    );
    return currentHash;
  };

  onSave = () => {
    if (this.props.name && this.props.name.trim() !== '') {
      this.setState({saveWait: true}, () => {
        if (this.props.note && this.props.note.length > 100) {
          this.setState({validNote: false});
          return;
        }
        this.setState({validNote: true});
        const data = {
          report: {
            id: this.props.id,
            notes: this.props.note,
            content: JSON.stringify({
              id: this.props.id ? this.props.id : -1,
              name: this.props.name,
              startDate: this.props.startDate,
              endDate: this.props.endDate,
              filteredRecordedSymptom: this.props.filteredRecordedSymptom,
              filteredRecordedDosing: this.props.filteredRecordedDosing,
              selectedIssues: this.props.selectedIssues,
              selectedImages: this.props.selectedImages,
              selectedImagesObj: this.props.selectedImagesObj,
              imagesTitle: this.props.imagesTitle,
              savedGraph: this.props.savedGraph,
              note: this.props.note,
              graphImage: this.props.graphImage,
              graphImageS3: this.props.graphImageS3,
              startDateShow: this.props.startDate
                ? moment(this.props.startDate, 'x').format("DD MMM 'YY")
                : '',
              endDateShow: this.props.endDate
                ? moment(this.props.endDate, 'x').format("DD MMM 'YY")
                : '',
            }),
            name: this.props.name,
          },
        };
        if (!_.isEmpty(this.props.savedGraph) && this.props.graphImageS3) {
          this.uploadGraphImage();
        }
        pendoTrackEvent('NewReport@ > Save');
        this.props.updateUserReport(
          data,
          this.props.journalUserId,
          (success, res) => {
            this.setState({saveWait: false});
            if (success) {
              this.props.selectReport(res.id, JSON.parse(res.content));
              this.props.redirectViewReport();
            }
          },
        );
      });
    } else {
      this.props.invalidReportName();
      this.props.commonErrorHandler('Report name is missing.');
      if (this.scrollViewRef) {
        setTimeout(() => {
          Platform.OS === WEB
            ? this.scrollViewRef?.scrollTo({ x: 0, y: 0, animated: true })
            : this.scrollViewRef.scrollToPosition(0, 0, true);
        }, 500);
      }
    }
  };

  cancelReport = () => {
    this.setState({confirmBox: false});
    this.props.resetReport();
    this.props.redirectReportList();
  };

  componentWillUnmount = () => {
    this.props.showMobileMenu();
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    if (
      !this.props.id &&
      this.props.initHashValue === '' &&
      this.props.startDate &&
      this.props.endDate
    ) {
      this.props.setInitHashValue(this.getHashValue());
    }
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => {
            this.handleOnPressCancel();
          }}
          index={this.props.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        {this.getHashValue() !== this.props.initHashValue &&
          <MenuOverlayContainer onPressOpen={this.handleOnPressCancel} />
        }
        <KeyboardAwareScrollViewHoc
          hasNotch={this.props.hasNotch}
          ref={val => (this.scrollViewRef = val)}>
          <View style={styles.gridSection}>
            <PageContainer smScreenFull={true}>
              <View style={stylesRes.contBotPd} dataSet={{media: ids.contBotPd}}>
                <View style={styles.gridRow}>
                  <View style={[styles.gridColFull, styles.contPdTop]}>
                    <View style={styles.titleArea}>
                      <View style={[styles.colPdLtRt, styles.flexRowJcCtAiCt]}>
                        <UIText
                          title={
                            this.state.isNewReport
                              ? 'Start a new Report'
                              : 'Edit Report'
                          }
                          style={[styles.textTitle, styles.textAlignCenter]}
                        />
                      </View>
                    </View>
                    <View style={styles.secArea}>
                      <ReportDetail />
                      <View style={styles.subSection}>
                        <ReportAddEditNote validNote={this.state.validNote} />
                        <ReportSymptom
                          redirectReportAttachSymptom={
                            this.props.redirectReportAttachSymptom
                          }
                        />
                        <ReportIssues
                          redirectReportAddIssue={
                            this.props.redirectReportAddIssue
                          }
                          redirectPage={this.props.redirectPage}
                        />
                        <ReportTreatment
                          redirectPage={this.props.redirectPage}
                          redirectReportAttachMedications={
                            this.props.redirectReportAttachMedications
                          }
                        />
                        <ReportImagesAndDocuments
                          redirectReportAddImage={
                            this.props.redirectReportAddImage
                          }
                        />
                        <ReportGraph
                          redirectReportAttachGraph={
                            this.props.redirectReportAttachGraph
                          }
                          updateGrph={this.updateGrph}
                        />
                      </View>
                      <View style={styles.subSection}>
                        <View style={styles.btnArea}>
                          <View style={stylesRes.btnViewSecLg} dataSet={{media: ids.btnViewSecLg}}>
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <CancelButton
                                  touchableOnpress={() => {
                                    this.handleOnPressCancel();
                                    pendoTrackEvent('NewReport@ > Cancel');
                                  }}
                                  disabled={this.state.saveWait}
                                  nativeId={'newReportCancel'}
                                />
                              </View>
                            </View>
                            <View style={styles.fieldBtnSp} />
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={styles.submitSm}
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={this.onSave}
                                  disabled={this.state.saveWait}
                                  nativeID={'newReportSave'}>
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent,
                                      ]}>
                                      {this.state.saveWait && (
                                        <View style={styles.actIndCom}>
                                          <ActivityIndicator size="small" />
                                        </View>
                                      )}
                                      <UIText
                                        style={[
                                          styles.submitBtnTextWhite,
                                          styles.bold]}
                                        title="Save"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                    <SavingConfirmation
                      visibleModal={this.state.confirmBox}
                      alertBody={alertBody}
                      alertTitle={reportTitle}
                      handleCancel={this.cancelReport}
                      handleConfirm={() => {
                        this.setState({confirmBox: false});
                        this.onSave();
                      }}
                      confirmWait={this.state.saveWait}
                      disabled={this.state.saveWait}
                      onBackdropPress={() => {
                        this.setState({confirmBox: false});
                      }}
                    />
                  </View>
                </View>
              </View>
            </PageContainer>
          </View>
        </KeyboardAwareScrollViewHoc>
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    id: state.report.id,
    name: state.report.name,
    note: state.report.note,
    startDate: state.report.startDate,
    endDate: state.report.endDate,
    filteredRecordedSymptom: state.report.filteredRecordedSymptom,
    filteredRecordedDosing: state.report.filteredRecordedDosing,
    selectedIssues: state.report.selectedIssues,
    selectedImages: state.report.selectedImages,
    journalUserId: state.uistate.journalUserId,
    savedGraph: state.report.savedGraph,
    imagesTitle: state.report.imagesTitle,
    graphImage: state.report.graphImage,
    graphImageS3: state.report.graphImageS3,
    initHashValue: state.report.initHashValue,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    invalidReportName: () => dispatch(actions.invalidReportName()),
    resetReport: () => dispatch(actions.resetReport()),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    updateUserReport: (data, journalUserId, callBack) =>
      dispatch(actions.updateUserReport(data, journalUserId, callBack)),
    selectReport: (id, data) => dispatch(actions.selectReport(id, data)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    setInitHashValue: initHashVal =>
      dispatch(actions.setInitHashValue(initHashVal)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Report);
