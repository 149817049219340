import React, { Component } from "react";
import styles from "../../../Css";
import { View, Text, TouchableOpacity, Image, Platform } from "react-native";
import * as paths from "../../../navigation/path";
import { handleReferFriend } from '../../../util/shareOption';
import { pendoTrackEvent } from "../../../util/pendoConfig";
import { WEB } from "../../../util/platformWindowConfig";


class ReferFriendMenuItem extends Component {
  constructor(props) {
    super(props);
    const { isSelected } = props;
    this.state = {
      isSelected: isSelected,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { isSelected } = props;
    if (isSelected !== state.isSelected) {
      return {
        isSelected: isSelected,
      };
    }

    return null;
  }
  render() {
    const { navigation, onPressMenuItem, tabMenuIconView, handleTabMenuClick } = this.props;
    const { isSelected } = this.state;
    const { title, path } = paths.REFER_FRIEND;
    return (
      <View style={styles.mainNavItem}>
        <TouchableOpacity
          onPress={() => {
            if (handleTabMenuClick) {
              handleTabMenuClick()
            } else {
            if (onPressMenuItem) {
              onPressMenuItem();
            }
            pendoTrackEvent('Menu > ReferaFriend');
            if (Platform.OS !== WEB) {
              handleReferFriend();
            } else {
              navigation.reset({
                  index: 0,
                  routes: [{ name: path }],
              });
            }
            }
          }}
        >
          <View
            nativeID={'menuReferaFriend'}
            style={[
              styles.navListLiDet,
              styles.navListLiDetPdSm,
              Boolean(isSelected) ? styles.mainNavSelected : styles.mainNavDefault,
            ]}
          >
            <View style={[styles.navListLiImgCom]}>
              <Image
                style={styles.navMainMenuIcon}
                source={Boolean(isSelected)
                  ? require("../../../assets/main-nav/refer-friend-active.png")
                  : require("../../../assets/main-nav/refer-friend.png")
                }
              />
            </View>
            {!Boolean(tabMenuIconView) && (
            <View style={[styles.navListLiContent]}>
              <Text
                style={[
                  Boolean(isSelected)
                    ? [styles.textPrimeBold, styles.textOrange]
                    : styles.textPrime,
                ]}
                allowFontScaling={false}
              >
                {title}
              </Text>
            </View>
            )}
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default (ReferFriendMenuItem);
