import React, {Component} from 'react';
import * as actions from '../../store/actions/index';
import {connect} from 'react-redux';
import ViewContact from '../../components/Contacts/viewContact';
import ConfirmationBox from '../../components/ConfirmationBox/confirmationBox';
import {CONTACTS, TODOS, IMAGES_DOCUMENTS} from '../../navigation/path';
import Cropper from '../../components/ImageCropper/AmazingCropperPage';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { handleCommonBackNavigation } from '../../navigation/RootNavigation';
class ViewContactScreen extends Component {
  constructor(props) {
    super(props);
    if (this.props.selectedContact) {
      if (this.props.activeTab !== 0) {
        this.props.setActiveTab(0);
      }

      const stateObject = this.createStateObject(this.props.selectedContact, this.props.questionDigest) 
      this.state = {
        ...stateObject,
        deleteConfirm: false,
        deleteWait: false,
      };
      this.tapCount = 0;
    } else {
      this.props.navigation.push(CONTACTS.path);
    }
  }

  state = {
    collapsible: {
      notes: false,
      questions: false,
    },
    questions: [],
    questionDigest: [],
  };

  componentDidMount() {
    const { selectedContact } = this.props;
    this.setState({
      collapsible: {
        notes: selectedContact.notes ? true : false,
        questions:
          this.state.questions && this.state.questions.length > 0
            ? true
            : false,
      },
    });
    this.props.navigation.addListener('blur', () => {
      this.tapCount = 0;
    });
    pendoTrackEvent('Contacts/@ViewContact');
  }

  createStateObject(selectedContact = {}, questionDigest = {}) {

    const questionsData = this.getQuestions(
      questionDigest.userArtifactHealthQuestions,
      selectedContact.artifactId,
    );

    const newObj = {
      contactName: selectedContact.givenName,
      organization: selectedContact.organization,
      specialty: selectedContact.specialty,
      phoneNumber: selectedContact.primaryPhoneNumber,
      phoneNumber2: selectedContact.secondaryPhoneNumber,
      faxNumber: selectedContact.faxPhoneNumber,
      email: selectedContact.emailaddress,
      address1: selectedContact.physicalAddressLine1,
      address2: selectedContact.physicalAddressLine2,
      city: selectedContact.municipality,
      zipCode: selectedContact.postalCode,
      stateProvince: selectedContact.stateProvince,
      country: selectedContact.country,
      artifactId: selectedContact.artifactId,
      notes: selectedContact.notes,
      questions: questionsData,
      collapsible: {
        notes: selectedContact.notes ? true : false,
        questions: questionsData && questionsData.length,
      },
      questionDigest: questionDigest,
    }

    return newObj;

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { error, errorDescription, selectedContact, questionDigest = {} } = nextProps;
    let newStateObject = {};
    if (selectedContact && ((selectedContact.artifactId !== this.state.artifactId) || (JSON.stringify(questionDigest) !== JSON.stringify(this.state.questionDigest)))) {
      newStateObject = this.createStateObject(selectedContact, questionDigest);
    }

    this.setState({
      ...this.state,
      ...newStateObject,
      error,
      errorDescription,
      deleteWait: false,
      deleteConfirm: false,
      alertMessageTitle: null,
    });
  }

  handleDeleteContactConfirmation = () => {
    this.setState({
      deleteConfirm: true,
      alertMessageTitle: 'Delete Contact',
    });
  };

  handleDeleteContact = () => {
    this.setState({deleteWait: true});
    this.props.onDeleteContact(
      this.props.selectedContact.id,
      this.props.contacts,
      () => {
        this.props.clearSelectedContact();
        this.setState({
          deleteWait: false,
          deleteConfirm: false,
          alertMessageTitle: null,
        });
        this.props.navigation.push(
          CONTACTS.path, {
            from: this.props.route.name,
          },
        );

      },
      this.props.journalUserId,
    );
  };

  hasAttachments = artifactId => {
    let attachments = [];
    if (this.props.attachmentsCompleteDigest) {
      attachments = this.props.attachmentsCompleteDigest.userArtifactAttachments.filter(
        function(attachment) {
          return attachment.userArtifact === artifactId;
        },
      );
    }
    return attachments.length > 0;
  };

  handleEditContact = () => {
    this.tapCount = this.tapCount + 1;
    if (this.tapCount === 1) {
      this.props.editContact(this.props.selectedContact, () => {
        this.props.navigation.push(CONTACTS.editContactPath);
      });
    }
  };

  getQuestions = (questions, artifactId) => {
    let questionsArray = [];
    if (questions) {
      questionsArray = questions.filter(function(question) {
        return question.artifactId === artifactId;
      });
    }

    const unique = questionsArray
      .map(e => e['questionId'])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => questionsArray[e])
      .map(e => questionsArray[e]);
    return unique;
  };

  handleOnPressBackButton = () => {
    if (
      this.props.route?.params &&
      this.props.route?.params.from &&
      (this.props.route?.params.from === CONTACTS.addContactPath ||
        this.props.route?.params.from === CONTACTS.editContactPath)
    ) {
      this.props.navigation.replace(
        CONTACTS.path, {
          from: this.props.route.name,
        },
      );
    } else if (
      this.props.route?.params &&
      this.props.route?.params.from &&
      (this.props.route?.params.from === CONTACTS.path ||
        this.props.route?.params.from === TODOS.path ||
        this.props.route?.params.from === IMAGES_DOCUMENTS.path)
    ) {
      // this.props.navigation.navigate(
      //   this.props.route?.params.from, {
      //     from: this.props.route.name,
      //   },
      // );
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: this.props.route?.params.from }],  
      });
    } else {
      handleCommonBackNavigation(this.props.navigation);
    }
  };

  handleOnPressCollapsible = (key, val) => {
    let collapsibleTemp = this.state.collapsible;
    collapsibleTemp[key] = !val;
    this.setState({collapsible: collapsibleTemp});
  };

  openCroper = (capturedImage) => {
    this.setState({showCropperPage: true, image: capturedImage});
  }

  closeCroper = (croppedUri, width, height) => {
    if (croppedUri) {
      this.setState({
        showCropperPage: false, 
        image: {...this.state.image, uri: croppedUri, height: height, width: width},
      });
    } else {
      this.setState({
        showCropperPage: false, 
        image: {...this.state.image},
      });
    }
  }

  imageUploadCompleted = () => {
    this.setState({image: null});
  }

  selectedTab = tabid => {
    this.props.setActiveTab(tabid);
    if (tabid === 1) {
      this.props.fetchUserAttachments(this.state.artifactId);
    }
  };

  render() {
    if (this.state.showCropperPage) {
      this.props.hideMobileMenu();
    } else {
      this.props.showMobileMenu();
    }
    return (
      this.state.showCropperPage 
      ? <Cropper image={this.state.image} close={this.closeCroper} />
      : <React.Fragment>
        <ViewContact
          handleOnPressBackButton={this.handleOnPressBackButton}
          contactName={this.state.contactName}
          phoneNumber={this.state.phoneNumber}
          faxNumber={this.state.faxNumber}
          country={this.state.country}
          specialty={this.state.specialty}
          emailaddress={this.state.email}
          address1={this.state.address1}
          stateProvince={this.state.stateProvince}
          city={this.state.city}
          zipCode={this.state.zipCode}
          artifactId={this.state.artifactId}
          notes={this.state.notes}
          organization={this.state.organization}
          contentVisibilityNotes={this.state.collapsible.notes}
          contentVisibilityQuestions={this.state.collapsible.questions}
          handleDeleteContactConfirmation={this.handleDeleteContactConfirmation}
          fetchAttachmentSpinner={this.props.fetchAttachmentSpinner}
          handleOnPressCollapsibleNotes={res =>
            this.handleOnPressCollapsible('notes', res)
          }
          handleOnPressCollapsibleQuestions={res =>
            this.handleOnPressCollapsible('questions', res)
          }
          hasAttachments={this.hasAttachments(
            this.props.selectedContact.artifactId,
          )}
          userAttachmentsSelected={this.props.userAttachmentsSelected}
          handleEditContact={this.handleEditContact}
          questions={this.state.questions}
          questionDigest={this.state.questionDigest}
          questionDigestProcessed={this.props.questionDigestProcessed}
          hasNotch={this.props.hasNotch}
          index={CONTACTS.index}
          navigation={this.props.navigation}
          route={this.props.route}
          openCroper={this.openCroper}
          tempCapturedImage={this.state.image} 
          onUploadCompleted={this.imageUploadCompleted} 
          activeTab={this.props.activeTab}
          selectedTab={this.selectedTab}
        />
        <ConfirmationBox
          visibleModal={this.state.deleteConfirm}
          onBackdropPress={() => {
            this.setState({deleteConfirm: false});
          }}
          onRequestClose={() => {
            this.setState({deleteConfirm: false});
          }}
          alertTitle={this.state.alertMessageTitle}
          handleDelete={this.handleDeleteContact}
          deleteWait={this.state.deleteWait}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    errorDescription: state.routines.errorDescription,
    error: state.routines.error,
    selectedContact: state.contacts.selectedContact,
    contacts: state.routines.contacts,
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
    fetchAttachmentSpinner: state.routines.journalAttachmentSpinner,
    questionDigest: state.routines.questionDigest,
    questionDigestProcessed: state.routines.questionDigestProcessed,
    userAttachmentsSelected: state.routines.userAttachmentsSelected,
    journalUserId: state.uistate.journalUserId,
    hasNotch: state.uistate.hasNotch,
    activeTab: state.surveys.activeTab,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onDeleteContact: (id, contacts, callBack, journalUserId) =>
      dispatch(actions.deleteContact(id, contacts, callBack, journalUserId)),
    editContact: (contact, callBack) =>
      dispatch(actions.editContact(contact, callBack)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    setActiveTab: activeTabIndex =>
      dispatch(actions.setActiveTab(activeTabIndex)),
    fetchUserAttachments: artifactId =>
      dispatch(actions.fetchUserAttachments(artifactId)),
    clearSelectedContact: () => dispatch(actions.selectContactSuccess({}))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewContactScreen);
