import React, { Component } from 'react';
import { TouchableOpacity, Text, Platform, View } from 'react-native';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { EmptyItem } from '../Common';
import MedicationItem from './MedicationItem';
import QuickEntryPopup from './QuickEntryPopup'
import { MedicationsOrTherapiesScreen } from '../../../screens/MedicationsOrTherapies';
import { pendoTrackEvent } from '../../../util/pendoConfig';
import styles from '../../../Css';
import { dashboardCategories, dashboardIssues, getDashboardData, sortAlphabeticalOrder } from '../../../util/commonUiLogic';
import { DASHBOARD, MEDICATIONS } from '../../../navigation/path';
import { ANDROID } from '../../../util/platformWindowConfig';

class MedicationSection extends Component {
  constructor(props) {
    super(props);
    const { listTherapy = [] } = props;
    const filteredTreatments = MedicationSection.filterMedication(listTherapy, props);
    this.state = {
      filteredTreatments: filteredTreatments,
      wait: false,
      visibleModal: false,
      treatment: null,
    };
  }

  static filterMedication = (listTherapy, props) => {
    let filteredTreatments = listTherapy.filter(
      itme =>
        itme.name &&
        itme.isActive &&
        !MedicationSection.isPush(itme.pauseDate, itme.pauseDuration) &&
        itme.isPinned &&
        (props.type === dashboardCategories.MEDICATIONS ? itme.treatmentType !== 5 : itme.treatmentType === 5)
    );
    const now = new Date();
    filteredTreatments = filteredTreatments.filter(
      item =>
        !item.dosages ||
        (item.dosages && item.dosages.length === 0) ||
        (item.dosages && item.dosages.length > 0 && !item.dosages[0].end) ||
        (item.dosages &&
          item.dosages.length > 0 &&
          item.dosages[0].end > now.getTime()),
    );

    return filteredTreatments;
  };

  static getDerivedStateFromProps(props, state) {
    const { listTherapy = [] } = props;

    const filteredTreatments = MedicationSection.filterMedication(listTherapy, props);
    if (filteredTreatments !== state.filteredTreatments) {
      return {
        filteredTreatments: filteredTreatments,
      };
    }
    if (filteredTreatments.length !== state.filteredTreatments.length) {
      return {
        filteredTreatments: filteredTreatments,
      };
    }

    return null;
  }

  static isPush = (pauseDate, pauseDuration) => {
    let millisecond = null;

    if (pauseDate) {
      millisecond = 86400000 * pauseDuration + pauseDate;
    }

    if (millisecond && millisecond > new Date().getTime()) {
      return true;
    } else {
      return false;
    }
  };

  editMedication = id => {
    if (this.props.listTherapy) {
      const selectedTherapy = this.props.listTherapy.filter(function (therapy) {
        return therapy.id === id;
      });

      if (selectedTherapy && selectedTherapy.length >= 1) {
        if (selectedTherapy[0]?.publishedBy === "PARTNER") {
          this.props.onSelectTherapie(selectedTherapy[0], () => {
            this.props.redirectViewTherapie();
          });
        } else {
          this.props.editTherapie(selectedTherapy[0], () => {
            this.props.redirectAddTherapie();
          });
        }
      }
    }
  };

  renderMedicationItems = () => {
    const { filteredTreatments, selected } = this.state;
    const { type, listTherapy, navigation } = this.props;

    sortAlphabeticalOrder(filteredTreatments);
    let issue = '';
    let listItems = [];
    if (filteredTreatments.length === 0) {
      issue = dashboardIssues.NOPINNED;
    }

    if (type === dashboardCategories.MEDICATIONS) {
      listItems = MedicationsOrTherapiesScreen.dataFilterMedication(
        listTherapy,
      );
    } else {
      listItems = MedicationsOrTherapiesScreen.dataFilter(listTherapy, 5);
    }

    if ((listItems ?? []).length === 0) {
      issue = dashboardIssues.NODATA;
    }

    if (issue !== '') {
      return <EmptyItem addTherapie={this.addTherapie} navigation={navigation} type={type} issue={issue} />;
    }

    return (
      <>
        <Text style={[styles.textPinned, Platform.OS !== ANDROID ? styles.bold : null]} allowFontScaling={false}>Pinned:</Text>
        {filteredTreatments.map((filteredTreatment, index) => {
          const { id } = filteredTreatment;
          return filteredTreatment ? (
            <MedicationItem
              key={'med_act_item_' + id}
              treatment={filteredTreatment}
              id={id}
              onPressItem={() => this.editMedication(id)}
              showPopup={this.showPopup}
              selected={selected}
            />
          ) : null;
        })}
        <View style={[styles.flexJcCtAiCt, styles.comSpSm]}>
          <TouchableOpacity
            onPress={() =>
              navigation.reset({
                index: 0,
                routes: [{
                  name: MEDICATIONS.path,
                }],
              })
            }
          >
            <Text style={[styles.textPrimeBold, styles.resourceBlueTxt, styles.textUnderline]} allowFontScaling={false}>
              {getDashboardData(type).viewButtonText}
            </Text>
          </TouchableOpacity>
        </View>
      </>
    )
  };

  addTherapie = callback => {
    let addNewTherapie = {};
    this.props.addTherapie(
      addNewTherapie,
      res => {
        callback(res);
        if (res) {
          this.props.navigation.reset({
            index: 0,
            routes: [{
              name: MEDICATIONS.addTherapiePath,
              params: {
                globalPath: DASHBOARD.path,
                activeTherapyTab: 1
              }
            }],
          });
        }
      },
      this.props.journalUserId,
    );
  };

  showPopup = treatment => {
    this.props.setPopupTreatment(treatment);
    this.setState({
      visibleModal: true,
      treatment: treatment,
      selected: treatment.id
    });
    pendoTrackEvent(treatment.treatmentType === 5
      ? 'Dashboard@ > RecordActivity'
      : 'Dashboard@ > RecordMed');
  };

  hidePopup = () => {
    this.props.setFromPopup(null);
    this.props.setPopupTreatment(null);
    this.setState({
      visibleModal: false
    });
    setTimeout(() => this.setState({ selected: false }), 1000);
  };

  render() {
    const { visibleModal, treatment } = this.state;

    return (
      <>
        {/* Medications or Activities*/}
        {this.renderMedicationItems()}
        <QuickEntryPopup
          treatment={treatment}
          visibleModal={visibleModal}
          hidePopup={this.hidePopup}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
    listTherapy: state.routines.treatments,
    fromPopup: state.uistate.fromPopup,
    popupTreatment: state.therapie.popupTreatment
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editTherapie: (therapie, callBack) =>
      dispatch(actions.editTherapie(therapie, callBack)),
    addTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.createTherapie(addNewTherapie, callBack, journalUserId)),
    setFromPopup: fromPopup => dispatch(actions.setFromPopup(fromPopup)),
    setPopupTreatment: treatment => dispatch(actions.setPopupTreatment(treatment)),
    onSelectTherapie: (SelectedTherapie, callBack) =>
      dispatch(actions.selectTherapie(SelectedTherapie, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicationSection);
