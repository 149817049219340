

export const getEntryCountUsingTreatmentId = (journalEntriesProcessed, treatment) => {

    let count = 0;
    for (let [key, value] of Object.entries(journalEntriesProcessed)) {

        if (value.dosages && value.dosages.length) {
            const isAvailable = value.dosages.find(d => d.treatment === treatment);
            if (isAvailable) {
                count += 1
            }
        }

    }

    return count;


}