import React from "react";
import { View } from "react-native";
import stylesResponsive from "../../CssResponsive";

import MenuItem from "./menuItem";

const addMenuMobilleWeb = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const { menuItems } = props;
  return (
    <View style={[stylesRes.addItemsOpenSm]} dataSet={{media: ids.addItemsOpenSm}}>
      <View style={[stylesRes.addLinksOverlay]} />
      <View style={[stylesRes.addLinksArea]}>
        <View style={[stylesRes.menuScrollSm]} dataSet={{media: ids.menuScrollSm}}>
          {menuItems.map((item, index) => (
            <MenuItem {...item} type={"MW"} key={"add_menu_web_" + index} />
          ))}
        </View>
      </View>
    </View>
  );
};

export default (addMenuMobilleWeb);
