import React, { useState } from 'react';
import { View, ScrollView, Text, Image, TouchableOpacity } from 'react-native';
import _ from 'underscore';
import Header from '../../components/Header/header';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import SelectSaveButton from '../../components/AttachToOption/selectSaveButton';
import PageContainer from "../../components/PageLayout/PageContainer";
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';

const ContactListContent = (props) => {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const { index, navigation, route, saveWait, contactsProcessed, contactsList, handleOnPressBackButton, handleOnSave } = props;
    const [selectedContact, setSelectedContact] = useState(null);

    const handleOnPressSelectContact = id => {
        let val = null;
        if (selectedContact !== id) {
            val = id;
        }
        setSelectedContact(val);
        if (props.setSelectedContact && props.setSelectedContact(val));
    };

    const renderContactContent = (contactList, title) => {
        return (
            <>
            {_.isEmpty(contactList) ? null : (
                <View style={stylesRes.contentSingleBlock}>
                <View style={stylesRes.colFull}>
                    <View>
                    <View style={stylesRes.contentTopicSection}>
                        <View style={styles.medListFullSec}>
                        <View
                            style={[
                            styles.medListSingleBoxLg,
                            styles.colBg,
                            ]}>
                            <View
                            style={[
                                styles.titleBlockCom,
                                styles.gridPdAllTopBotSm,
                            ]}>
                            <View style={styles.titleTextBlock}>
                                <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                                    {title}
                                </Text>
                            </View>
                            </View>
                        </View>
                        </View>
                    </View>

                    <View style={stylesRes.contentDetailSection}>
                        <View style={stylesRes.contList}>
                        {Object.values(
                            contactList
                        ).sort((a,b) =>
                            (a.givenName !== null && b.givenName !== null) && a.givenName.toLowerCase().localeCompare(b.givenName.toLowerCase())
                        ).map(res => (
                            <View style={[styles.borderThin]}>
                            <TouchableOpacity
                                onPress={() =>
                                    handleOnPressSelectContact(res.id)
                                }
                                style={[styles.contListItemMd]}>
                                <View style={stylesRes.contListItemBlock}>
                                <View
                                    style={[
                                    stylesRes.contListItemDesc,
                                    stylesRes.contListItemFull,
                                    ]}>
                                    <View
                                    style={
                                        styles.contListItemIconBlock
                                    }>
                                    <Image
                                        style={[styles.personIcon]}
                                        resizeMode={'contain'}
                                        source={require('../../assets/icons/user.png')}
                                    />
                                    </View>
                                    <View
                                    style={
                                        styles.contListItemTextBlock
                                    }>
                                    <Text
                                        style={[
                                        styles.text,
                                        styles.bold,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        {res.givenName}
                                    </Text>
                                    {Boolean(res.organization) && (
                                        <Text style={styles.text} allowFontScaling={false}>
                                        {res.organization}
                                        </Text>
                                    )}
                                    </View>
                                </View>
                                <View
                                    style={[
                                    stylesRes.selectionBlock,
                                    stylesRes.btnCenterView,
                                    ]}>
                                    <View style={styles.iconListSec}>
                                    <View style={[styles.selectSec]}>
                                        <View
                                        style={[
                                            styles.selectSecIcon,
                                            styles.iconListMgSmLt,
                                        ]}>
                                        <Image
                                            style={styles.radioIcon}
                                            source={res.id === selectedContact
                                            ? require('../../assets/icons/radio-selected.png')
                                            : require('../../assets/icons/radio-default.png')
                                            }
                                        />
                                        </View>
                                    </View>
                                    </View>
                                </View>
                                </View>
                            </TouchableOpacity>
                            </View>
                        ))}
                        </View>
                    </View>
                    </View>
                </View>
                </View>
            )}
            </>
        )
    }

    return (
      <>
        <Header
            showFilers={false}
            showBack={true}
            showClearFilters={false}
            showNotify={false}
            handleOnPressBackButton={handleOnPressBackButton}
            index={index}
            navigation={navigation}
            route={route}
        />
        <SpaceBar />
        <ScrollView style={styles.scrollViewArea}>
          <View style={styles.gridSection}>
            <View
              style={[stylesRes.contentSection, stylesRes.contBotLgCom]}>
                <PageContainer smScreenFull={true}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    {/* Care Team */}
                    {renderContactContent(contactsList?.careTeam, 'Care Team')}

                    {/* Family */}
                    {renderContactContent(contactsList?.family, 'Other')}
                  </View>
                </View>
                </PageContainer>
                <PageContainer>
                {selectedContact ? (
                  <SelectSaveButton
                    isFromSave={true}
                    saveWait={saveWait}
                    disabled={saveWait}
                    onPress={() => handleOnSave(contactsProcessed, selectedContact)}
                  />
                ) : (
                  <SelectSaveButton />
                )}
                </PageContainer>
              </View>
            </View>
        </ScrollView>
      </>
    );
  }

export default ContactListContent;
