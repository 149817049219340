import React, { Component } from "react";
// import { View, ViewPropTypes } from "react-native";
import { View, Text, TouchableOpacity } from "react-native";
import { ViewPropTypes } from 'deprecated-react-native-prop-types'
import PropTypes from "prop-types";

import XDate from "xdate";
import dateutils from "../dateutils";
import { xdateToData, parseDate } from "../interface";
import styleConstructor from "./style";
import Day from "./day/basic";
import UnitDay from "./day/period";
import MultiDotDay from "./day/multi-dot";
import MultiPeriodDay from "./day/multi-period";
import SingleDay from "./day/custom";
import CalendarHeader from "./header";
import shouldComponentUpdate from "./updater";
import { SELECT_DATE_SLOT } from "../testIDs";

//Fallback when RN version is < 0.44
//const viewPropTypes = ViewPropTypes || View.propTypes;
const viewPropTypes = ViewPropTypes;
const EmptyArray = [];

export default class Calendar extends Component {
  constructor(props) {
    super(props);
    this.style = styleConstructor(this.props.theme);
    let currentMonth;
    if (props.current) {
      currentMonth = XDate(props.current);
      //currentMonth = parseDate(props.current);
    } else {
      currentMonth = XDate();
    }
    this.state = {
      currentMonth,
      visible: false,
      view: "days",
      hideDayNames: this.props.hideDayNames,
      yearRange: currentMonth
    };

    this.updateMonth = this.updateMonth.bind(this);
    this.addMonth = this.addMonth.bind(this);
    this.pressDay = this.pressDay.bind(this);
    this.longPressDay = this.longPressDay.bind(this);
    this.shouldComponentUpdate = shouldComponentUpdate;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const current = parseDate(nextProps.current);
    if (
      current &&
      current.toString("yyyy MM") !==
        this.state.currentMonth.toString("yyyy MM")
    ) {
      this.setState({
        currentMonth: current.clone(),
        yearRange: current.clone()
      });
    }

    if (nextProps.hideMonth && nextProps.hideMonth !== this.props.hideMonth) {
      this.setState({
        view: "days"
      });
    }
  }

  updateMonth(day, doNotTriggerListeners) {
    if (
      day.toString("yyyy MM") === this.state.currentMonth.toString("yyyy MM")
    ) {
      return;
    }
    this.setState(
      {
        currentMonth: day.clone()
      },
      () => {
        if (!doNotTriggerListeners) {
          const currMont = this.state.currentMonth.clone();
          if (this.props.onMonthChange) {
            this.props.onMonthChange(xdateToData(currMont));
          }
          if (this.props.onVisibleMonthsChange) {
            this.props.onVisibleMonthsChange([xdateToData(currMont)]);
          }
        }
      }
    );
  }

  _handleDayInteraction(date, interaction) {
    const day = parseDate(date);
    const minDate = parseDate(this.props.minDate);
    const maxDate = parseDate(this.props.maxDate);
    if (
      !(minDate && !dateutils.isGTE(day, minDate)) &&
      !(maxDate && !dateutils.isLTE(day, maxDate))
    ) {
      const shouldUpdateMonth =
        this.props.disableMonthChange === undefined ||
        !this.props.disableMonthChange;
      if (shouldUpdateMonth) {
        this.updateMonth(day);
      }
      if (interaction) {
        interaction(xdateToData(day));
      }
    }
  }

  // New Code
  onPressMonth() {
    this.setState({ view: "month", hideDayNames: true });
    this.forceUpdate();
  }

  onPressYear() {
    this.setState({ view: "year", hideDayNames: true });
    this.forceUpdate();
  }
  // New Code

  pressDay(date) {
    this._handleDayInteraction(date, this.props.onDayPress);
  }

  longPressDay(date) {
    this._handleDayInteraction(date, this.props.onDayLongPress);
  }

  addMonth(count) {
    this.updateMonth(this.state.currentMonth.clone().addMonths(count, true));
  }

  handleYearRange(year) {
    const yearRange = new XDate();
    yearRange.setFullYear(year);
    this.setState({ yearRange });
    this.forceUpdate();
  }

  renderDay(day, id) {
    const minDate = parseDate(this.props.minDate);
    const maxDate = parseDate(this.props.maxDate);
    let state = "";
    if (this.props.disabledByDefault) {
      state = "disabled";
    } else if (
      (minDate && !dateutils.isGTE(day, minDate)) ||
      (maxDate && !dateutils.isLTE(day, maxDate))
    ) {
      state = "disabled";
    } else if (!dateutils.sameMonth(day, this.state.currentMonth)) {
      state = "disabled";
    } else if (dateutils.sameDate(day, XDate())) {
      state = "today";
    }

    if (
      !dateutils.sameMonth(day, this.state.currentMonth) &&
      this.props.hideExtraDays
    ) {
      return <View key={id} style={{ flex: 1 }} />;
    }

    const DayComp = this.getDayComponent();
    const date = day.getDate();
    const dateAsObject = xdateToData(day);

    return (
      <View style={{ flex: 1, alignItems: "center" }} key={id}>
        <DayComp
          testID={`${SELECT_DATE_SLOT}-${dateAsObject.dateString}`}
          state={state}
          theme={this.props.theme}
          onPress={this.pressDay}
          onLongPress={this.longPressDay}
          date={dateAsObject}
          marking={this.getDateMarking(day)}
        >
          {date}
        </DayComp>
      </View>
    );
  }

  getDayComponent() {
    if (this.props.dayComponent) {
      return this.props.dayComponent;
    }

    switch (this.props.markingType) {
      case "period":
        return UnitDay;
      case "multi-dot":
        return MultiDotDay;
      case "multi-period":
        return MultiPeriodDay;
      case "custom":
        return SingleDay;
      default:
        return Day;
    }
  }

  getDateMarking(day) {
    if (!this.props.markedDates) {
      return false;
    }
    const dates =
      this.props.markedDates[day.toString("yyyy-MM-dd")] || EmptyArray;
    if (dates.length || dates) {
      return dates;
    } else {
      return false;
    }
  }

  renderWeekNumber(weekNumber) {
    return (
      <Day
        key={`week-${weekNumber}`}
        theme={this.props.theme}
        marking={{ disableTouchEvent: true }}
        state="disabled"
      >
        {weekNumber}
      </Day>
    );
  }

  renderWeek(days, id) {
    const week = [];
    days.forEach((day, id2) => {
      week.push(this.renderDay(day, id2));
    }, this);

    if (this.props.showWeekNumbers) {
      week.unshift(this.renderWeekNumber(days[days.length - 1].getWeek()));
    }

    return (
      <View style={this.style.week} key={id}>
        {week}
      </View>
    );
  }
  //new code//

  handleMonthChange(month) {
    let tempDate = XDate(this.state.currentMonth);
    tempDate.setMonth(month - 1);
    this.updateMonth(tempDate);
    this.setState({ view: "days", hideDayNames: false });
    this.forceUpdate();
  }

  handleYearChange(year) {
    let tempDate = XDate(this.state.currentMonth);
    tempDate.setFullYear(year);
    this.updateMonth(tempDate);
    this.setState({ view: "days", hideDayNames: false });
    this.forceUpdate();
  }
  //new code//
  renderModalBottomPart() {
    switch (this.state.view) {
      case "days":
        const days = dateutils.page(
          this.state.currentMonth,
          this.props.firstDay
        );
        const weeks = [];
        while (days.length) {
          weeks.push(this.renderWeek(days.splice(0, 7), weeks.length));
        }
        return <View style={this.style.monthView}>{weeks}</View>;

      case "month":
        return (
          <View style={styles.monthView}>
            <View style={[styles.calBoxListView]}>
              <View style={styles.calBoxSingle} key="jan">
                <View style={styles.calBoxSingleItem}>
                  <TouchableOpacity onPress={() => this.handleMonthChange(1)}>
                    <View style={styles.calBoxItem}>
                      <Text style={styles.calText} allowFontScaling={false}>Jan</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.calBoxSingle} key={"feb"}>
                <View style={styles.calBoxSingleItem}>
                  <TouchableOpacity onPress={() => this.handleMonthChange(2)}>
                    <View style={styles.calBoxItem}>
                      <Text style={styles.calText} allowFontScaling={false}>Feb</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.calBoxSingle} key={"mar"}>
                <View style={styles.calBoxSingleItem}>
                  <TouchableOpacity onPress={() => this.handleMonthChange(3)}>
                    <View style={styles.calBoxItem}>
                      <Text style={styles.calText} allowFontScaling={false}>Mar</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.calBoxSingle} key={"apr"}>
                <View style={styles.calBoxSingleItem}>
                  <TouchableOpacity onPress={() => this.handleMonthChange(4)}>
                    <View style={styles.calBoxItem}>
                      <Text style={styles.calText} allowFontScaling={false}>Apr</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.calBoxSingle} key={"may"}>
                <View style={styles.calBoxSingleItem}>
                  <TouchableOpacity onPress={() => this.handleMonthChange(5)}>
                    <View style={styles.calBoxItem}>
                      <Text style={styles.calText} allowFontScaling={false}>May</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.calBoxSingle} key={"jun"}>
                <View style={styles.calBoxSingleItem}>
                  <TouchableOpacity onPress={() => this.handleMonthChange(6)}>
                    <View style={styles.calBoxItem}>
                      <Text style={styles.calText} allowFontScaling={false}>Jun</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.calBoxSingle} key={"jul"}>
                <View style={styles.calBoxSingleItem}>
                  <TouchableOpacity onPress={() => this.handleMonthChange(7)}>
                    <View style={styles.calBoxItem}>
                      <Text style={styles.calText} allowFontScaling={false}>Jul</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.calBoxSingle} key={"aug"}>
                <View style={styles.calBoxSingleItem}>
                  <TouchableOpacity onPress={() => this.handleMonthChange(8)}>
                    <View style={styles.calBoxItem}>
                      <Text style={styles.calText} allowFontScaling={false}>Aug</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.calBoxSingle} key={"sep"}>
                <View style={styles.calBoxSingleItem}>
                  <TouchableOpacity onPress={() => this.handleMonthChange(9)}>
                    <View style={styles.calBoxItem}>
                      <Text style={styles.calText} allowFontScaling={false}>Sep</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.calBoxSingle} key={"oct"}>
                <View style={styles.calBoxSingleItem}>
                  <TouchableOpacity onPress={() => this.handleMonthChange(10)}>
                    <View style={styles.calBoxItem}>
                      <Text style={styles.calText} allowFontScaling={false}>Oct</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.calBoxSingle} key={"nov"}>
                <View style={styles.calBoxSingleItem}>
                  <TouchableOpacity onPress={() => this.handleMonthChange(11)}>
                    <View style={styles.calBoxItem}>
                      <Text style={styles.calText} allowFontScaling={false}>Nov</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.calBoxSingle} key="dec">
                <View style={styles.calBoxSingleItem}>
                  <TouchableOpacity onPress={() => this.handleMonthChange(12)}>
                    <View style={styles.calBoxItem}>
                      <Text style={styles.calText} allowFontScaling={false}>Dec</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        );
      case "year":
        let tempDate = XDate(this.state.yearRange);
        const currentYear = tempDate.getFullYear();
        const min = currentYear - 8;
        const max = currentYear + 7;
        const years = [];
        for (var i = min; i <= max; i++) {
          years.push(i);
        }
        return (
          <View style={[styles.yearView, styles.calFullView]}>
            <View style={styles.calCentView}>
              <View style={[styles.calBoxListView]}>
                {years.map(year => {
                  return (
                    <View style={styles.calBoxSingle} key={year}>
                      <View style={styles.calBoxSingleItem}>
                        <TouchableOpacity
                          onPress={() => this.handleYearChange(year)}
                        >
                          <View style={styles.calBoxItem}>
                            <Text style={styles.calText} allowFontScaling={false}>{year}</Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        );
      default:
        break;
    }
  }

  render() {
    const days = dateutils.page(this.state.currentMonth, this.props.firstDay);
    const weeks = [];
    while (days.length) {
      weeks.push(this.renderWeek(days.splice(0, 7), weeks.length));
    }
    let indicator;
    const current = parseDate(this.props.current);
    if (current) {
      const lastMonthOfDay = current
        .clone()
        .addMonths(1, true)
        .setDate(1)
        .addDays(-1)
        .toString("yyyy-MM-dd");
      if (
        this.props.displayLoadingIndicator &&
        !(this.props.markedDates && this.props.markedDates[lastMonthOfDay])
      ) {
        indicator = true;
      }
    }
    return (
      <View style={[this.style.container, this.props.style]}>
        <CalendarHeader
          mode={this.state.view}
          theme={this.props.theme}
          hideArrows={this.props.hideArrows}
          hideMonth={this.props.hideMonth}
          month={this.state.currentMonth}
          addMonth={this.addMonth}
          showIndicator={indicator}
          firstDay={this.props.firstDay}
          renderArrow={this.props.renderArrow}
          monthFormat={this.props.monthFormat}
          hideDayNames={this.state.hideDayNames}
          weekNumbers={this.props.showWeekNumbers}
          onPressArrowLeft={this.props.onPressArrowLeft}
          onPressArrowRight={this.props.onPressArrowRight}
          onPressYearSelectL={this.handleYearRange.bind(this)}
          onPressYearSelectR={this.handleYearRange.bind(this)}
          onYearSelect={this.onPressYear.bind(this)}
          onMonthSelect={this.onPressMonth.bind(this)}
          yearRange={this.state.yearRange}
        />
        {/* <View style={this.style.monthView}>{weeks}</View> */}
        {this.renderModalBottomPart()}
      </View>
    );
  }
}

const styles = {
  monthItem: {
    marginLeft: "6px",
    height: "50px",
    width: "175px",
    borderWidth: "2px",
    borderRadius: "5px",
    borderColor: "#dfdfdf",
    justifyContent: "center"
  },
  monthText: {
    textAlign: "center"
  },
  yearItem: {
    alignSelf: "center",
    borderWidth: "2px",
    borderRadius: "5px",
    height: "50px",
    width: "250px",
    borderColor: "#dfdfdf",
    justifyContent: "center"
  },
  yearText: {
    textAlign: "center"
  },
  contentContainer: {
    height: "300px",
    paddingVertical: "20px"
  },
  monthSingle: {
    width: "25%"
  },
  calBoxSingle: {
    width: "25%",
    maxWidth: 80
  },
  monthListView: {
    flexWrap: "wrap",
    flexDirection: "row",
    paddingBottom: 10
  },
  monthItemSm: {
    borderRadius: 5,
    justifyContent: "center",
    paddingTop: 10,
    paddingBottom: 10
  },
  monthSingleItem: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 5,
    marginRight: 5
  },
  calBoxItem: {
    borderRadius: 5,
    justifyContent: "center",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  calBoxSingleItem: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 5,
    marginRight: 5
  },
  calBoxListView: {
    flexWrap: "wrap",
    flexDirection: "row",
    paddingBottom: 10,
    alignItems: "center",
    justifyContent: "center"
  },
  calText: {
    fontSize: 14,
    textAlign: "center"
  },
  arrView: {
    flexBasis: 20,
    alignItems: "center"
  },
  calCentView: {
    flex: 1
  },
  calFullView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
};

Calendar.propTypes = {
  // Specify theme properties to override specific styles for calendar parts. Default = {}
  theme: PropTypes.object,
  // Collection of dates that have to be marked. Default = {}
  markedDates: PropTypes.object,

  // Specify style for calendar container element. Default = {}
  style: viewPropTypes.style,
  // Initially visible month. Default = Date()
  current: PropTypes.any,
  // Minimum date that can be selected, dates before minDate will be grayed out. Default = undefined
  minDate: PropTypes.any,
  // Maximum date that can be selected, dates after maxDate will be grayed out. Default = undefined
  maxDate: PropTypes.any,

  // If firstDay=1 week starts from Monday. Note that dayNames and dayNamesShort should still start from Sunday.
  firstDay: PropTypes.number,

  // Date marking style [simple/period/multi-dot/multi-period]. Default = 'simple'
  markingType: PropTypes.string,

  // Hide month navigation arrows. Default = false
  hideArrows: PropTypes.bool,
  // Display loading indicador. Default = false
  displayLoadingIndicator: PropTypes.bool,
  // Do not show days of other months in month page. Default = false
  hideExtraDays: PropTypes.bool,

  // Handler which gets executed on day press. Default = undefined
  onDayPress: PropTypes.func,
  // Handler which gets executed on day long press. Default = undefined
  onDayLongPress: PropTypes.func,
  // Handler which gets executed when visible month changes in calendar. Default = undefined
  onMonthChange: PropTypes.func,
  onVisibleMonthsChange: PropTypes.func,
  // Replace default arrows with custom ones (direction can be 'left' or 'right')
  renderArrow: PropTypes.func,
  // Provide custom day rendering component
  dayComponent: PropTypes.any,
  // Month format in calendar title. Formatting values: http://arshaw.com/xdate/#Formatting
  monthFormat: PropTypes.string,
  // Disables changing month when click on days of other months (when hideExtraDays is false). Default = false
  disableMonthChange: PropTypes.bool,
  //  Hide day names. Default = false
  hideDayNames: PropTypes.bool,
  // Disable days by default. Default = false
  disabledByDefault: PropTypes.bool,
  // Show week numbers. Default = false
  showWeekNumbers: PropTypes.bool,
  // Handler which gets executed when press arrow icon left. It receive a callback can go back month
  onPressArrowLeft: PropTypes.func,
  // Handler which gets executed when press arrow icon left. It receive a callback can go next month
  onPressArrowRight: PropTypes.func
};
