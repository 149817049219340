import React, { Component } from 'react';
import { View } from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import { connect } from 'react-redux';
import _ from 'lodash';
import { peerToPeerStatuses, peerToPeerTabs } from '../../Constant';
import { PEER_TO_PEER } from '../../navigation/path';
import * as actions from '../../store/actions/index';
import { TwilioService } from '../../util/PeerToPeerService/twilio-service';

class TwilioMessaging extends Component {
  constructor(props) {
    super(props);
    const { connectedConnections = [], sentConnections = [] } = props;
    this.state = {
      myConnections: connectedConnections,
      sentConnections: sentConnections
    };
    this.listners = React.createRef().current;
    this.client = React.createRef().current;
    this.conversations = React.createRef().current;
    this.listners = this.client = this.conversations = {};
  }

  componentDidMount() {
    const { onGetToken, peerProfile, onSetChatStatus, setUnSeenConversations } = this.props;
    this.addEventListners();

    setUnSeenConversations({});

    if (peerProfile?.id ? true : false) {
      onSetChatStatus(peerToPeerStatuses.INITIALIZING);
      TwilioService.initialize(onGetToken, peerProfile?.id?.toString());
    }
  }

  componentWillUnmount() {
    if (this.client && this.client?.shutdown) this.client.shutdown();
    EventRegister.removeAllListeners();
  }

  static getDerivedStateFromProps(props, state) {
    const { connectedConnections = [], sentConnections = [] } = props;
    if (!_.isEqual(connectedConnections, state.myConnections) ||
    !_.isEqual(sentConnections, state.sentConnections)) {
      return {
        myConnections: connectedConnections,
        sentConnections: sentConnections
      };
    }
    return null;
  }

  addEventListners = () => {
    this.listners['initFailed'] = EventRegister.addEventListener(
      'initFailed',
      this.onInitFailed,
    );
    this.listners['initialized'] = EventRegister.addEventListener(
      'initialized',
      this.onInitialized,
    );
    this.listners['typingStarted'] = EventRegister.addEventListener(
      'typingStarted',
      this.handleOnTyping,
    );
    this.listners['setAllMessagesToRead'] = EventRegister.addEventListener(
      'setAllMessagesToRead',
      this.handleSetAllMessagesToRead,
    );
    this.listners['addMessage'] = EventRegister.addEventListener(
      'addMessage',
      this.handleAddMessage,
    );
    this.listners['reInitialize'] = EventRegister.addEventListener(
      'reInitialize',
      this.reInitialize,
    );
    this.listners['redirectChat'] = EventRegister.addEventListener(
      'redirectChat',
      this.redirectChat,
    );
    this.listners['clientShutdown'] = EventRegister.addEventListener(
      'clientShutdown',
      this.handleClientshutdown,
    );
  };

  onInitFailed = error => {
    const { commonErrorHandler, onSetChatStatus } = this.props;
    commonErrorHandler("Chat initialization failed!, Please resatart the app!");
    onSetChatStatus(peerToPeerStatuses.STOPED);
  };

  handleClientshutdown = () => {
    if (this.client && this.client.shutdown)
      this.client.shutdown()
  }

  handleAddMessage = data => {
    const { newMessages, attributes, conversationSid } = data;
    if (this.conversations[conversationSid]) {
      this.conversations[conversationSid].sendMessage(
        newMessages[0].text,
        attributes,
      );
    }
  }

  handleSetAllMessagesToRead = async conversationSid => {
    if (this.conversations[conversationSid]) {
      this.conversations[conversationSid].setAllMessagesRead()
    }
  }

  handleOnTyping = sid => {
    if (this.conversations[sid]) this.conversations[sid].typing();
  };

  /**
    @param {Client} newClient
  */
  onInitialized = async newClient => {
    const {
      updateSingleChannel,
      peerProfile,
      setUnSeenConversations,
      removeSingleChannel
    } = this.props;
    this.client = newClient;
    newClient.on('conversationJoined', async conversation => {
      const count = await conversation.getUnreadMessagesCount();
      conversation.on('typingStarted', () => {
        updateSingleChannel(conversation.sid, { typing: true });
      });

      conversation.on('typingEnded', () => {
        updateSingleChannel(conversation.sid, { typing: false });
      });

      // conversation.on("participantJoined", (participant) => {
      // });
      // conversation.on("participantLeft", (participant) => {
      // });

      if (conversation.status === 'joined') {
        this.conversations[conversation.sid] = conversation;
        const parsedConversation = await TwilioService.getParsedConversation(
          conversation,
          peerProfile?.id?.toString(),
          this.state.myConnections.concat(this.state.sentConnections),
        );
        if (parsedConversation) {
          updateSingleChannel(conversation.sid, parsedConversation);
          if (count || count === null) {
            const { unSeenConversations } = this.props;
            unSeenConversations[conversation.sid] = conversation.sid;
            setUnSeenConversations(unSeenConversations);
          }
        }
        if (this.props.chatStatus === peerToPeerStatuses.INITIALIZED)
          this.addUsersToSubscribedList(this.state.myConnections.concat(this.state.sentConnections), this.client)
      }
    });

    newClient.on('conversationRemoved', conversation => {
      const { unSeenConversations } = this.props;
      removeSingleChannel(conversation.sid);
      delete unSeenConversations[conversation.sid];
      setUnSeenConversations(unSeenConversations);
    });

    newClient.on('messageAdded', async message => {
      const { channels, selectedChannel, unSeenConversations, peerProfile } = this.props;
      const participants = await message.conversation.getParticipants();
      const participant = participants?.find(participant => (participant.identity !== "undefined" ? participant.identity : participant?.conversation?.uniqueName?.replace(peerProfile?.id?.toString(), '')?.trim()) === message.author)
      const parsedMessage = TwilioService.getParsedMessage(
        message,
        participant,
      );
      const activeConversation = channels.find(
        channel => channel.id === message.conversation.sid,
      );
      let messages = Object.assign([], activeConversation?.allMessages ?? []);
      if (messages.some(({ _id }) => _id === message.attributes?.giftedId)) {
        messages = messages.map(newMessage =>
          newMessage._id === message.attributes?.giftedId
            ? parsedMessage
            : newMessage,
        );
      } else {
        messages = [...messages, parsedMessage];
      }
      updateSingleChannel(message.conversation.sid, {
        allMessages: messages,
        lastMessageTime:
          message?.dateCreated ??
          message.conversation.dateUpdated ??
          message.conversation.dateCreated,
        lastMessage: message.body,
        author: message.author,
        newText: await message.conversation.getUnreadMessagesCount() > 0 || selectedChannel?.id !== message.conversation.sid,
      });
      if (selectedChannel?.id !== message.conversation.sid) {
        unSeenConversations[message.conversation.sid] =
          message.conversation.sid;
        setUnSeenConversations(unSeenConversations);
      } else {
        // if (message.author !== peerProfile?.id?.toString()) {
        message.conversation.updateLastReadMessageIndex(message.conversation.lastMessage.index)
        // }
      }
    });

    // newClient.on("participantLeft", (participant) => {
    // });

    newClient.on('participantUpdated', async participant => {
      const {selectedChannel, unSeenConversations} = this.props;
      const count = await participant.participant.conversation.getUnreadMessagesCount()
      const parsedConversation = await TwilioService.getParsedConversation(
        participant.participant.conversation,
        peerProfile?.id?.toString(),
        this.state.myConnections.concat(this.state.sentConnections),
      );
      updateSingleChannel(
        participant.participant.conversation.sid,
        {...parsedConversation, newText: (count || count === null) && selectedChannel?.id !== participant.participant.conversation.sid},
      );
      if ((count || count === null) && selectedChannel?.id !== participant.participant.conversation.sid) {
        unSeenConversations[participant.participant.conversation.sid] = participant.participant.conversation.sid;
        setUnSeenConversations(unSeenConversations);
      }
    });

    // newClient.on("participantJoined", async (participant) => {
    //   const parsedConversation = await TwilioService.getParsedConversation(
    //     participant.conversation,
    //     peerProfile?.id?.toString(),
    //     myConnections
    //   );
    //   updateSingleChannel(participant.conversation.sid, parsedConversation);
    // });

    // newClient.on("conversationUpdated", ({ conversation }) => {
    // });

    // newClient.on("messageUpdated", ({ message }) => {
    // });

    // newClient.on("messageRemoved", (message) => {
    // });

    newClient.on('userUpdated', event => {
      const { channels } = this.props;
      const userChannels = channels.filter(
        channel =>
          channel.userId.toString() ===
          (event.user?.state?.identity ?? event.user.identity),
      );
      for (let index = 0; index < userChannels.length; index++) {
        updateSingleChannel(userChannels[index]?.id, {
          status: event.user.isOnline,
        });
      }
    });

    this.addUsersToSubscribedList(this.state.myConnections.concat(this.state.sentConnections), newClient);
    this.addConversationList(newClient);
  };

  reInitialize = async data => {
    const { conversationSid } = data;
    this.addUsersToSubscribedList(this.state.myConnections.concat(this.state.sentConnections), this.client);
    await this.addConversationList(this.client);
    const { channels, onSetSelectedConversation, navigation, onSetPeerToPeerActiveTab } = this.props;
    const foundItem = channels.find(res => conversationSid === res.id);
    if (foundItem) {
      onSetSelectedConversation(foundItem);
      onSetPeerToPeerActiveTab(peerToPeerTabs.chats);
      navigation.reset({
        index: 0,
        routes: [{ name: PEER_TO_PEER.individualChatPath }],
      });
    }
  }

  redirectChat = (data, trys = 0) => {
    const { conversationSid } = data;
    const { channels, onSetSelectedConversation, navigation, onSetPeerToPeerActiveTab, commonErrorHandler, chatStatus } = this.props;
    if (chatStatus !== peerToPeerStatuses.INITIALIZED) return;
    const foundItem = channels.find(res => conversationSid === res.id);
    if (!foundItem && trys < 6) return setTimeout(() => this.redirectChat(data, ++trys), 1000);
    onSetPeerToPeerActiveTab(peerToPeerTabs.chats);
    if (foundItem) {
      onSetSelectedConversation(foundItem);
      // navigation.reset({
      //   index: 0,
      //   routes: [{ name: PEER_TO_PEER.individualChatPath }],
      // });
      navigation.navigate(PEER_TO_PEER.individualChatPath);
    } else {
      commonErrorHandler('Sorry, this conversation is unavailable.');
      navigation.reset({
        index: 0,
        routes: [{ name: PEER_TO_PEER.path }],
      });
    }
  }

  addUsersToSubscribedList = (myConnectionList, client) => {
    // Do not use async await inside forEach loop it may not give the correct result!
    myConnectionList.forEach(connection =>
      client.getUser(connection?.id?.toString()),
    );
  };

  /**
    @param {Client} client
  */
  addConversationList = async client => {
    const { peerProfile, onUpdateConversations, onSetChatStatus, setUnSeenConversations, unSeenConversations } = this.props;
    try {
      const conversationPaginator = await client.getSubscribedConversations();
      const flatternConversations = await TwilioService.flatPaginators(
        conversationPaginator,
        [],
      );
      for (let index = 0; index < flatternConversations.length; index++) {
        this.conversations[flatternConversations[index].sid] = flatternConversations[index];
      }
      const readableConversations = await TwilioService.getReadableConversationObjects(
        flatternConversations,
        peerProfile?.id?.toString(),
        this.state.myConnections.concat(this.state.sentConnections),
      );
      let unSeenChats = { ...unSeenConversations };
      readableConversations.forEach(conversation => {
        if (conversation.newText) {
          unSeenChats = {
            ...unSeenChats,
            [conversation.id]: conversation.id
          }
        }
      })
      onUpdateConversations(readableConversations);
      onSetChatStatus(peerToPeerStatuses.INITIALIZED);
      setUnSeenConversations(unSeenChats);
    } catch (error) {
      console.log(error?.message);
      // commonErrorHandler(error?.message);
    }
  };
  render() {
    return <View />;
  }
}

const mapStateToProps = state => {
  return {
    peerProfile: state.routines.peer2peer,
    newMessageCount: state.peerToPeer.newMessageCount,
    channels: state.peerToPeer.channels,
    unSeenConversations: state.peerToPeer.unSeenConversations,
    connectedConnections: state.routines.myNetwork?.connected,
    sentConnections: state.routines.myNetwork?.sent,
    selectedChannel: state.peerToPeer.selectedChannel,
    chatStatus: state.peerToPeer.chatStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetToken: (callBack, userId) =>
      dispatch(actions.getToken(callBack, userId)),
    setNewMessageCount: count => dispatch(actions.setNewMessageCount(count)),
    onUpdateConversations: conversations =>
      dispatch(actions.updateChannels(conversations)),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    updateSingleChannel: (sid, data) =>
      dispatch(actions.updateSingleChannel(sid, data)),
    removeSingleChannel: id => dispatch(actions.removeSingleChannel(id)),
    setUnSeenConversations: unSeenConvos =>
      dispatch(actions.setUnSeenConversations(unSeenConvos)),
    onSetSelectedConversation: conversation =>
      dispatch(actions.setSelectedChannel(conversation)),
    onSetPeerToPeerActiveTab: activeTabIndex =>
      dispatch(actions.setPeerToPeerActiveTab(activeTabIndex)),
    onSetChatStatus: status =>
      dispatch(actions.setChatStatus(status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TwilioMessaging);
