import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity, Platform} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import VerificationCodeInput from '../../components/VerificationCodeInput';
import Warning from '../../components/Warning/warning';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import WarningCommon from '../../components/Warning/warningCommon';
import PageLayout from '../../components/PageLayout/pageLayout';
import PrimaryButton from '../../components/Button/primaryButton';
import * as FieldDetails from '../../components/TextInput/fieldDetails';
import PrimaryShadowButton from '../../components/Button/primaryShadowButton';
import InputPasswordField from '../../components/TextInput/inputPasswordField';
import ValidationMessage from '../../components/Warning/validationMessage';
import KeychainWrapper from '../../components/Biometric/keyChain';
import { WEB } from '../../util/platformWindowConfig';


class LoginpasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: null,
      confirmPassword: null,
      newPasswordValidate: true,
      confirmPasswordValidate: true,
      passwordVisibleConfirm: true,
      passwordVisible: true,
      verificationCodeValidate: true,
      verificationCode: "",
      wait: false,
      resendCodeWait: false,
      errorCode: false,
      errorDescriptionCode: '',
      validCode: false,
      invalidCode: false,
      shouldChange: false,
    };
    setTimeout(() => {
      this.setState({shouldChange: true});
    }, 600);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {error, errorDescription} = nextProps;

    this.setState({
      ...this.state,
      error,
      errorDescription,
    });
  }

  onPressChangePassword = () => {
    if (this.validInput()) {
      this.setState({wait: true, error: false, errorCode: false}, () => {
        this.props.changePassword(
          this.state.newPassword,
          this.state.verificationCode,
          (success, message) => {
            if (success) {
              this.setState({
                validCode: true,
                invalidCode: false,
              }, async () => {
                  this.props.showSuccessPopup(3);
                  if (Platform.OS !== WEB) {
                    await KeychainWrapper.resetGenericPassword();
                  }
                }
              );
            } else {
              this.setState({
                verificationCodeValidate: false,
                errorCode: true,
                errorDescriptionCode: message,
                wait: false,
                validCode: false,
                invalidCode: true,
              });
            }
          },
        );
      });
    }
  };

  validInput = () => {
    let verificationCodeValidate = true;
    let newPasswordValidate = true;
    let passwordError = '';
    let confirmPasswordValidate = true;
    let confirmPasswordError = '';

    if (!this.state.verificationCode) {
      verificationCodeValidate = false;
    }

    if (!this.state.newPassword) {
      newPasswordValidate = false;
      passwordError = 'Enter password';
    }

    if (this.state.passwordVisible && !this.state.confirmPassword) {
      confirmPasswordValidate = false;
      confirmPasswordError = 'Confirm password';
    }

    if (
      this.state.passwordVisible &&
      this.state.newPassword &&
      this.state.newPassword !== this.state.confirmPassword
    ) {
      confirmPasswordError = 'Please make sure your passwords match';
      confirmPasswordValidate = false;
    }

    if (this.state.newPassword && this.state.newPassword.length < 8) {
      newPasswordValidate = false;
      passwordError = 'Minimum length is 8';
    }

    this.setState({
      verificationCodeValidate,
      newPasswordValidate,
      passwordError,
      confirmPasswordValidate,
      confirmPasswordError,
    });

    return (
      verificationCodeValidate && newPasswordValidate && confirmPasswordValidate
    );
  };

  onChangeTextNew = value => {
    if (value) {
      this.setState({newPasswordValidate: true});
    } else {
      this.setState({newPasswordValidate: false});
    }
    this.setState({newPassword: value});
  };

  onChangeVerificationCode = (value, valid) => {

    if (valid && value && value.length === 6) {
      this.setState({
        verificationCode: value,
        verificationCodeValidate: true,
      });
    } else {
      this.setState({
        verificationCodeValidate: true,
        verificationCode: value,
        invalidCode: false,
        errorCode: false,
        errorDescriptionCode: '',
      });
    }
  };

  onChangeTextConfirm = value => {
    if (value) {
      this.setState({confirmPasswordValidate: true});
    } else {
      this.setState({confirmPasswordValidate: false});
    }
    this.setState({confirmPassword: value});
  };

  handlePasswordVisibility = () => {
    this.setState(prevState => {
      return {
        passwordVisible: !prevState.passwordVisible,
      };
    });
  };

  handlePasswordVisibilityConfirm = () => {
    this.setState(prevState => {
      return {
        passwordVisibleConfirm: !prevState.passwordVisibleConfirm,
      };
    });
  };

  resendCode = () => {
    this.setState({
      resendCodeWait: true,
      verificationCodeValidate: true,
      invalidCode: false,
      errorCode: false,
      errorDescriptionCode: '',
      verificationCode: '',
    });
    this.props.resendCode((success, message) => {
      if (!success) {
        this.setState({
          errorCode: true,
          errorDescriptionCode: message,
          resendCodeWait: false,
        });
      } else {
        this.setState({resendCodeWait: false});
      }
    });
  };

  render() {
    const stylesRes = stylesResponsive.getProcessedStyles().styles;
    const ids = stylesResponsive.getProcessedStyles().ids;

    return (
      <PageLayout
        windowHeightView={this.props.windowHeightView}
        windowHeightSmView={this.props.windowHeightSmView}
        windowWidthView={this.props.windowWidthView}
        bgBlue={this.props.bgBlue}
        contentCenter={false}
        bgStyle={true}>
        <View style={[styles.boxWrapperArea, styles.colMgLtRt]}>
          <View 
            style={[stylesRes.boxWrapperView, stylesRes.wrapperShadow]}
            dataSet={{media: ids.boxWrapperView}}>
            <View style={[styles.secArea]}>
              <View style={[styles.colPd]}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View
                      style={[
                        styles.titleBlock,
                        styles.flexRowJcCtAiCt,
                        styles.noPdTop,
                      ]}>
                      <Text
                        style={[
                          styles.textAlignCenter,
                          styles.textTitleExLg,
                          styles.textThickGrey,
                        ]}
                        allowFontScaling={false}
                        >
                        <Text style={[styles.bold]} allowFontScaling={false}>Forgot </Text>Password?
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.topImgSecCom}>
                      <View style={stylesRes.topImgSpecCom}>
                        <Image
                          style={styles.passIconSmCom}
                          source={require('../../assets/reset-password/forgot-password.png')}
                        />
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.fieldRow, styles.comMgBotSm]}>
                      <View style={[styles.rowTitleSec, styles.rowTitleTopSp]}>
                        <Text
                          style={[styles.textPrime, styles.textAlignCenter]} allowFontScaling={false}>
                          Enter the{' '}
                          <Text style={[styles.bold]} allowFontScaling={false}>verification code </Text>
                        </Text>
                      </View>

                      <View
                        style={[
                          styles.verifyCodeField,
                          this.state.invalidCode ||
                          !this.state.verificationCodeValidate
                            ? styles.inValidColor
                            : this.state.validCode
                            ? styles.validGreenColor
                            : styles.validColor,
                        ]}>
                        <VerificationCodeInput
                          onChange={this.onChangeVerificationCode}
                          shouldChange={this.state.shouldChange}
                          verificationCode={this.state.verificationCode}
                        />

                        <View style={styles.fieldIconView}>
                          <View style={styles.fieldIconTouchView}>
                            {Boolean(this.state.validCode) && (
                              <Image
                                style={styles.statusCorrectIcon}
                                source={require('../../assets/icons/right-green-icon.png')}
                                resizeMode={'contain'}
                              />
                            )}

                            {Boolean(this.state.invalidCode) && (
                              <Image
                                style={styles.statusWarningIcon}
                                source={require('../../assets/icons/warning-exclamation.png')}
                                resizeMode={'contain'}
                              />
                            )}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={[styles.gridColFull, styles.errBoxView]}>
                    {Boolean(this.state.errorCode) && (
                      <WarningCommon
                        textPosition={'left'}
                        errorMessage={this.state.errorDescriptionCode}
                      />
                    )}
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={[styles.gridColFull]}>
                    <View style={[styles.fieldInfoSection, styles.comMgBot]}>
                      <Text style={[styles.textPrime, styles.textTertiary]} allowFontScaling={false}>
                        Enter the 6-digit verification code sent to your email
                        address
                      </Text>
                    </View>
                  </View>
                </View>
                {!Boolean(this.state.validCode) && (
                  <View style={styles.gridRow}>
                    <View style={[styles.gridColFull]}>
                      <View style={[styles.btnArea, styles.comSp]}>
                        <View style={styles.fieldBtn}>
                          <PrimaryShadowButton
                            btnText={'Resend Code'}
                            btnPosition={'full'}
                            activityInd={this.state.resendCodeWait}
                            touchableOnPress={this.resendCode}
                            disabled={this.state.resendCodeWait}
                            resetIcon={true}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                )}

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.fieldContainer}>
                      <View style={[styles.fieldRowWrapper]}>
                        <View style={styles.fieldColStretch}>
                          <InputPasswordField
                            title={'Enter New Password'}
                            fieldBg={FieldDetails.BGWHITE}
                            fieldRg={true}
                            requirdStar={true}
                            secureTextEntry={this.state.passwordVisible}
                            onChangeText={this.onChangeTextNew}
                            value={
                              this.state.newPassword
                                ? this.state.newPassword
                                : undefined
                            }
                            passwordVisible={this.state.passwordVisible}
                            invalid={!this.state.newPasswordValidate}
                            handlePasswordVisibility={
                              this.handlePasswordVisibility
                            }
                            passwordShowHide={true}
                            onFocus={() => {
                              this.setState({
                                newPasswordValidate: true,
                              });
                            }}
                          />
                        </View>
                      </View>
                      {!this.state.newPasswordValidate && (
                        <ValidationMessage
                          errorMessage={this.state.passwordError}
                        />
                      )}
                    </View>
                  </View>
                </View>

                {Boolean(this.state.passwordVisible) && (
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={styles.fieldContainer}>
                        <View style={[styles.fieldRowWrapper]}>
                          <View style={styles.fieldColStretch}>
                            <InputPasswordField
                              title={'Confirm New Password'}
                              fieldBg={FieldDetails.BGWHITE}
                              fieldRg={true}
                              requirdStar={true}
                              secureTextEntry={
                                this.state.passwordVisibleConfirm
                              }
                              onChangeText={this.onChangeTextConfirm}
                              value={this.state.confirmPassword}
                              passwordShowHide={false}
                              invalid={!this.state.confirmPasswordValidate}
                              onFocus={() => {
                                this.setState({
                                  confirmPasswordValidate: true,
                                });
                              }}
                            />
                          </View>
                        </View>
                        {!Boolean(this.state.confirmPasswordValidate) && (
                          <ValidationMessage
                            errorMessage={this.state.confirmPasswordError}
                          />
                        )}
                      </View>
                    </View>
                  </View>
                )}

                <View style={styles.gridRow}>
                  <View style={[styles.gridColFull, styles.errBoxView]}>
                    {Boolean(this.state.error) && (
                      <Warning errorDescription={this.state.errorDescription} />
                    )}
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.btnArea, styles.btnPdTopMini]}>
                      <View style={styles.fieldBtn}>
                        <PrimaryButton
                          btnText={'Save'}
                          btnPosition={'full'}
                          activityInd={this.state.wait}
                          btnShadow={true}
                          btnLg={true}
                          touchableOnPress={this.onPressChangePassword}
                          disabled={this.state.wait}
                          btnStyle={styles.submitBgBlueDark}
                        />
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View
                      style={[
                        styles.contCenterView,
                        styles.secBgPdTop,
                        styles.colPdBot,
                      ]}>
                      <Text style={[styles.textPrime]}>Back to </Text>
                      <TouchableOpacity onPress={this.props.onPressLogin} disabled={this.state.wait}>
                        <Text style={[styles.textPrimeBold, styles.resourceBlueTxt]} allowFontScaling={false}>
                          Login Page
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </PageLayout>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSuccessPopup: id => dispatch(actions.showSuccessPopup(id)),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(LoginpasswordReset);
