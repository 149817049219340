import React, {Component} from "react";
import { Text, TouchableOpacity, View } from "react-native";
import styles from "../../../Css";
import Modal from "../../Modal/modal";
import ModalLayoutSpec from "../../ModalLayout/modalLayoutSpec";
import PrimaryButton from "../../Button/primaryButton";

class reportPopup extends Component{
    constructor(props) {
        super(props);
        this.state = {
            reportType: null
        };
    }
    setReportType = reportType => {
        this.setState({
            reportType: this.state.reportType !== reportType 
                ? reportType 
                : null
        });
    }
    render() {
        const {
            visibleModal, 
            hidePopup, 
            selectedUser, 
            wait, 
            onReport
        } = this.props;
        const {reportType} = this.state;
        return (
            <Modal
              visible={visibleModal}
              onBackdropPress={() => hidePopup("REPORT_POPUP")}
            >
              <ModalLayoutSpec
                contPosition={"center"}
                showClose={true}
                hidePopup={() => hidePopup("REPORT_POPUP")}
              >
                <View style={styles.popupContentWrapper}>
                  <View style={[styles.popMessageContView, styles.comSp]}>
                    <View style={[styles.popMessageTitleView, styles.comSp]}>
                      <Text style={[styles.textTitle, styles.textAlignCenter]} allowFontScaling= {false}>
                        Report <Text style={styles.bold} allowFontScaling= {false}>{selectedUser && selectedUser?.userName}</Text>
                      </Text>
                    </View>
                    <View style={[styles.popMessageTitleView, styles.comSpExSm]}>
                      <Text style={[styles.textPrime, styles.textAlignCenter]} allowFontScaling= {false}>
                        Help us understand what’s happening. How would you describe it?
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.popMessageContBody, styles.comSp]}>
                    <View style={[styles.popMessageContBodyView]}>
                      <View
                        style={[
                          styles.itemListView,
                          styles.flexRowJcCtAiCt,
                          styles.flexWrapView,
                        ]}
                      >
                        <View style={[styles.itemListSingle]}>
                          <TouchableOpacity 
                            onPress={() => this.setReportType(1)}
                            style={[
                                styles.itemListBtn,
                                reportType === 1 
                                  ? styles.itemBtnActive
                                  : styles.itemBtnDefault

                          ]}>
                            <Text style={[
                                styles.textPrime, 
                                reportType === 1
                                  ? styles.itemBtnTextActive
                                  : styles.itemBtnTextDefault
                            ]}
                            allowFontScaling= {false}
                            >
                              Spam
                            </Text>
                          </TouchableOpacity>
                        </View>
        
                        <View style={[styles.itemListSingle]}>
                          <TouchableOpacity
                            onPress={() => this.setReportType(2)}
                            style={[
                                styles.itemListBtn, 
                                reportType === 2 
                                  ? styles.itemBtnActive
                                  : styles.itemBtnDefault
                          ]}>
                            <Text style={[
                                styles.textPrime, 
                                reportType === 2
                                  ? styles.itemBtnTextActive
                                  : styles.itemBtnTextDefault
                            ]}
                            allowFontScaling= {false}
                            >
                              Misleading or Scam
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View style={[styles.itemListSingle]}>
                          <TouchableOpacity
                            onPress={() => this.setReportType(3)}
                            style={[
                                styles.itemListBtn, 
                                reportType === 3 
                                  ? styles.itemBtnActive
                                  : styles.itemBtnDefault
                          ]}>
                            <Text style={[
                                styles.textPrime, 
                                reportType === 3
                                  ? styles.itemBtnTextActive
                                  : styles.itemBtnTextDefault
                            ]}
                            allowFontScaling= {false}
                            >
                              Prohibited Content
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View style={[styles.itemListSingle]}>
                          <TouchableOpacity
                            onPress={() => this.setReportType(4)}
                            style={[
                                styles.itemListBtn, 
                                reportType === 4 
                                  ? styles.itemBtnActive
                                  : styles.itemBtnDefault
                          ]}>
                            <Text style={[
                                styles.textPrime, 
                                reportType === 4
                                  ? styles.itemBtnTextActive
                                  : styles.itemBtnTextDefault
                            ]}
                            allowFontScaling= {false}
                            >
                              Violence
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={[styles.popBtnView, styles.comMgTopSm]}>
                    <View style={[styles.btnArea, styles.flexRow, styles.flexJcCtAiCt]}>
                      <View style={styles.fieldBtn}>
                        <PrimaryButton
                          btnText={"Submit"}
                          btnPosition={"full"}
                          touchableOnPress={() => onReport(reportType)}
                          activityInd={wait}
                          disabled={!reportType || wait}
                          btnStyle={[
                            styles.submitBgOrange,
                            styles.primaryButtonLgPd,
                            !reportType || wait ? styles.btnBlurState : styles.btnActiveState,
                          ]}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </ModalLayoutSpec>
            </Modal>
        );
    }
  
};
export default (reportPopup);