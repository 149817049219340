import React from 'react';
import {View, Image, TouchableOpacity, Text} from 'react-native';
import styles from '../../Css';
import {PROFILE} from '../../navigation/path';
import { pendoTrackEvent } from '../../util/pendoConfig';
const premiumOvalButton = props => {
  return (
    <TouchableOpacity
      onPress={() => {
        pendoTrackEvent(props.eventName);
        props.navigation.navigate(
          PROFILE.profilePremiumAccountPath, {
            fromPopup: props.fromPopup,
          },
        );

        if (props.closeModal) {
          props.closeModal();
        }
      }}
      style={
        props.btnFull
        ? styles.btnTouchFull
        : props.btnSmFull
        ? styles.btnTouchSmFull
        : styles.btnTouchCom
      }
      >
      <View style={[styles.premiumBgWrapper, styles.flexAiCt]}>
        <Image
          style={styles.premiumBgCom}
          source={require('../../assets/icons/premium-bg.png')}
        />
        <View style={styles.premiumBgContent} nativeID={props.nativeId}>
          <View style={[styles.premiumBtnWrapper, styles.premiumBtnOval]}>
            <View style={[styles.premiumIconViewSm]}>
              <Image
                style={[styles.premiumIconSm, styles.premiumBtnImg]}
                source={require('../../assets/icons/premium.png')}
              />
            </View>
            <Text style={[styles.textMicSmBold, styles.textOrange]} allowFontScaling={false}>
              Premium
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default (premiumOvalButton);
