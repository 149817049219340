import React, {Component} from 'react';
import {View, Text, TouchableOpacity, ScrollView, Platform} from 'react-native';
import Header from '../../components/Header/header';
import _ from 'underscore';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import moment from 'moment';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import ReportPopupMenu from '../../components/PopupMenu/reportPopupMenu';
import ConfirmationBox from '../../components/ConfirmationBox/confirmationBox';
import {
  axiosPOSTPdfDownload,
  axiosPOSTSendEmailReport,
  axiosPdfDownload,
} from '../../axios/axios-common';
import {
  GENERATE_USER_REPORT,
  SEND_EMAIL_USER_REPORT,
} from '../../store/actions/urls';
import SendEmailBox from '../../components/ConfirmationBox/sendEmailPopupBox';
import SuccessBox from '../../components/ConfirmationBox/successBox';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import PageContainer from '../../components/PageLayout/PageContainer';
import SectionTitle from '../../components/Text/sectionTitle';
import SearchField from '../../components/Search/searchField';
import PlusButtonDark from '../../components/Button/plusButtonDark';

import PremiumOvalButton from '../../components/Button/premiumOvalButton';
import NoResultFound from '../../components/Search/noResultFound';
import ReportBanner from '../../components/Banner/ReportBanner';
import {freeUserMaximumReportsAllow} from '../../Constant';
import {getAccountType} from '../../util/subscriptionInformation';
import {REPORTS, DASHBOARD} from '../../navigation/path';
import {hasValue} from '../../util/hashValueGenerator';
import { handleCommonBackNavigation } from '../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { WEB } from '../../util/platformWindowConfig';


class ReportsScreen extends Component {
  constructor(props) {
    super(props);
    const accountType = getAccountType(props.userSubscriptions);
  //  let from = null;
    //TO-DO remove previous navigation changes
    // if (this.props.location.state && this.props.location.state.from) {
    //   from = this.props.location.state.from;
    // }
    // if (props.route?.params && props.route?.params?.from) {
    //   from = props.route?.params.from;
    // }
    this.state = {
      reports: [],
      reportsAll: [],
      lodding: true,
      deleteWait: false,
      selectedId: null,
      deleteConform: false,
      visibleModalSendEmail: false,
      sendWait: false,
      successModalVisible: false,
      accountType: accountType,
      searchStr: 
        //TO-DO remove previous navigation changes
        // from && 
        // this.props.history.action === 'PUSH' &&
        // from.includes(REPORTS.viewReportPath) &&
        // this.props.reportFilter ? this.props.reportFilter : '',
        '',
      enableReportCount: 0,
      downloadWait: false,
      reportId: null
    };
    this.tapCount = 0;
  }
  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      let searchString = null;
      let from = null;
      if (this.props.route?.params && this.props.route?.params?.from) {
        from = this.props.route?.params.from;
      }
      if (this.props.reportFilter && from && (
        from.includes(REPORTS.viewReportPath) || 
        from.includes(REPORTS.newReportPath) ||
        from.includes(REPORTS.editReportPath))
      ) {
        searchString = this.props.reportFilter;
      }
      let enableReportCount = this.state.enableReportCount;
      this.setState({lodding: true});
      this.props.getAllUserReport(this.props.journalUserId, (success, data) => {
        if (success) {
          const reports = data.reports;
          reports.sort(function(a, b) {
            return b.createdOn - a.createdOn;
          });
          let enableReportFilter = reports.filter(
            report => report.status === 'ENABLED',
          );
          if (enableReportFilter && enableReportFilter.length > 0) {
            enableReportCount = enableReportFilter.length;
          }
          this.setState({
            reports: reports,
            reportsAll: reports,
            enableReportCount: enableReportCount,
          });
        }
        this.filterReport(searchString);
        this.setState({lodding: false});
      });
      pendoTrackEvent('Reports@');
    });
    this.props.navigation.addListener('blur', () => {
      this.tapCount = 0;
      this.props.navigation.setParams({from: REPORTS.path});
    });
  }

  handleDeleteReport = () => {
    if (this.state.selectedId) {
      pendoTrackEvent('Reports@ > 3Dots > Delete');
      let enableReportCount = this.state.enableReportCount;
      this.setState({deleteWait: true});
      this.props.deleteReport(
        this.state.selectedId,

        res => {
          let reports = [...this.state.reports];
          let deleteConform = true;
          if (res) {
            reports = reports.filter(item => item.id !== this.state.selectedId);
            deleteConform = false;
            let enableReportFilter = reports.filter(
              report => report.status === 'ENABLED',
            );
            if (enableReportFilter && enableReportFilter.length > 0) {
              enableReportCount = enableReportFilter.length;
            }
          }

          this.setState({
            deleteWait: false,
            selectedId: null,
            reports: reports,
            reportsAll: reports,
            deleteConform: deleteConform,
            enableReportCount: enableReportCount,
          });
        },
        this.props.journalUserId,
      );
    }
  };

  onDelete = id => {
    this.setState({
      deleteConform: true,
      selectedId: id,
    });
  };

  OnEdit = id => {
    var found = this.state.reports.find(function(element) {
      return element.id === id;
    });

    if (found) {
      const report = JSON.parse(found.content);
      this.props.selectReport(id, JSON.parse(found.content));
      const initHashValue = hasValue(
        id +
          ',' +
        new Date(report.startDate) +
          ',' +
        new Date(report.endDate) +
          ',' +
        report.name +
          ',' +
        report.note +
          ',' +
        JSON.stringify(report.filteredRecordedSymptom) +
          ',' +
        JSON.stringify(report.filteredRecordedDosing) +
          ',' +
        JSON.stringify(report.selectedIssues) +
          ',' +
        JSON.stringify(report.selectedImages) +
          ',' +
        JSON.stringify(report.savedGraph) 
      );

      if (this.tapCount === 1) {
        pendoTrackEvent('Reports@ > 3Dots > Edit');
        this.props.setInitHashValue(initHashValue);
        this.redirectEditReport();
      }
    }
  };

  onView = id => {
    var found = this.state.reports.find(function(element) {
      return element.id === id;
    });

    if (found) {
      pendoTrackEvent('Reports > @ViewReport');
      this.props.selectReport(id, JSON.parse(found.content));
      this.redirectViewReport();
    }
  };

  onDownloadReport = async id => {
    const found = this.state.reports.find(function(element) {
      return element.id === id;
    });

    if (found) {
      this.setState({downloadWait: true, reportId: id});

      const content = {
        ...JSON.parse(found.content),
      };

      const data = {
        timeStamp:
          moment(new Date().getTime(), 'x').format("MMM D 'YY") +
          ' ' +
          moment(new Date().getTime(), 'x').format('h:mm a'),
        ...content,
        graphImageS3: !_.isEmpty(content.savedGraph) ? content.graphImageS3 : null,
        id: id,
      };

      if (this.tapCount === 1) {
        pendoTrackEvent('Reports@ > 3Dots > Download');
        if (Platform.OS === WEB) {
          let url = GENERATE_USER_REPORT;
          if (this.props.journalUserId) {
            url += '?journalUserId=' + this.props.journalUserId;
          }
          axiosPOSTPdfDownload(url, data, data.name + '.pdf', (res, message) => {
            this.setState({downloadWait: false, reportId: null});
            this.tapCount = 0;
          });
        } else {
          axiosPdfDownload(
            data,
            data.name + '.pdf',
            this.props.journalUserId,
            () => {
              this.setState({downloadWait: false, reportId: null});
              this.tapCount = 0;
            },
          );
        }

      }
    }
  };

  handleSendEmailReport = (emails, callback) => {
    this.setState({sendWait: true});
    if (
      this.state.selectedId &&
      this.state.reports &&
      this.state.reports.length > 0
    ) {
      var found = this.state.reports.find(element => {
        return element.id === this.state.selectedId;
      });

      if (found) {
        pendoTrackEvent('Reports@ > 3Dots > SendasEmail');
        const content = {
          ...JSON.parse(found.content),
        };

        const data = {
          id: this.state.selectedId,
          timeStamp:
            moment(new Date().getTime(), 'x').format("MMM D 'YY") +
            ' ' +
            moment(new Date().getTime(), 'x').format('h:mm a'),
          ...content,
          graphImageS3: !_.isEmpty(content.savedGraph) ? content.graphImageS3 : null,
          startDateShow: content.startDate
            ? moment(new Date(content.startDate), 'x').format("DD MMM 'YY")
            : '',
          endDateShow: content.endDate
            ? moment(
                new Date(content.endDate),

                'x',
              ).format("DD MMM 'YY")
            : '',
        };
        let sendEmail = {
          emailRecipients: emails,
          reportData: data,
        };
        let url = SEND_EMAIL_USER_REPORT;
        axiosPOSTSendEmailReport(url, sendEmail, () => {
          this.setState({
            selectedId: null,
            visibleModalSendEmail: false,
            sendWait: false,
            successModalVisible: true,
          });
          callback(true);
          setTimeout(() => {
            this.setState({successModalVisible: false});
          }, 1500);
        });
      }
    }
  };

  onSendEmail = id => {
    this.setState({visibleModalSendEmail: true, selectedId: id});
  };

  filterReport = searchStr => {
    if (searchStr) {
      const {reportsAll} = this.state;

      let reports = reportsAll;
      // if (
      //   accountType === 'regular' &&
      //   reportsAll &&
      //   reportsAll.length >= freeUserMaximumReportsAllow
      // ) {
      //   reports = reportsAll.slice(0, freeUserMaximumReportsAllow);
      // }
      const filteredreports = reports.filter(
        type =>
          type.name &&
          type.name.toLowerCase().includes(searchStr.toLowerCase()),
      );

      this.setState({
        searchStr: searchStr,
        reports: filteredreports,
      });
    } else {
      this.setState({
        searchStr: searchStr,
        reports: this.state.reportsAll,
      });
    }
    this.props.setReportFilter(searchStr);
  };

  redirectNewReport = () => {
    this.props.clearFilesToAttach();
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: REPORTS.newReportPath }],
    });
  };

  redirectViewReport = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: REPORTS.viewReportPath }],
    });
  };
  redirectEditReport = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: REPORTS.editReportPath }],
    });
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
        <View style={[styles.pageBodyWrapper]}>
          <Header
            showFilers={false}
            showBack={true}
            showClearFilters={false}
            showNotify={true}
            handleOnPressBackButton={() => {
              if (this.state.searchStr !== "" && this.state.searchStr !== null) {
                this.filterReport(null);
              } else if (
                this.props.route?.params &&
                this.props.route?.params.from &&
                (this.props.route?.params.from === REPORTS.newReportPath ||
                  this.props.route?.params.from === REPORTS.editReportPath ||
                  this.props.route?.params.from === REPORTS.viewReportPath)
              ) {
                this.props.navigation.reset({
                  index: 0,
                  routes: [{ name: DASHBOARD.path }],
                });
              } else {
                handleCommonBackNavigation(this.props.navigation);
              }
            }}
            index={REPORTS.index}
            navigation={this.props.navigation}
            route={this.props.route}
          />
          <SpaceBar />
          {this.state.accountType === "regular" &&
            this.state.enableReportCount >= freeUserMaximumReportsAllow && (
              <ReportBanner />
            )}

          <ScrollView 
            style={[styles.scrollViewArea]}
            keyboardShouldPersistTaps={'handled'}
          >
            <View style={styles.gridSection}>
              <PageContainer>
                <View style={[styles.gridRow, styles.colPdBotSm]}>
                  <View style={styles.gridColFull}>
                  <View style={[styles.searchSection]}>
                    {/* button */}
                    <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt]}>
                      <View>
                        <SectionTitle 
                          smallTextTilte={true}
                          title={"Reports"}
                        />
                      </View>
                      <View>
                      {this.state.accountType === "regular" &&
                        this.state.enableReportCount >= freeUserMaximumReportsAllow && (
                          <View style={[styles.premiumTopBtn, {top:3}]}>
                            <PremiumOvalButton
                              navigation={this.props.navigation}
                              btnFull={true}
                            />
                          </View>
                        )}
                      <View style={[stylesRes.newConnectionBtnCr, styles.btnMgMd]} dataSet={{media: ids.newConnectionBtnCr}}>
                        <PlusButtonDark
                          touchableOnPress={() => {
                            const { accountType } = this.state;

                            if (
                              accountType === "regular" &&
                              this.state.enableReportCount >=
                                freeUserMaximumReportsAllow
                            ) {
                              return;
                            }
                            pendoTrackEvent('Reports@ > Add');
                            this.props.resetReport();
                            this.redirectNewReport();
                          }}
                          btnPosition={"full"}
                          btnText={"New Report"}
                          plusIconSm={true}
                          btnShadowNone={true}
                          nativeId={'reportsAdd'}
                          newConnection={true}
                        />
                      </View>
                    </View>
                    </View>
                    {/* search bar */}
                    <View style={styles.fieldColSearch}>
                      <SearchField
                       searchText={
                        this.state.searchStr ? this.state.searchStr : ""
                        }
                        placeholder="Search existing reports"
                        onChangeText={this.filterReport}
                        showClearBtton={
                          this.state.searchStr && this.state.searchStr !== ""
                            ? true
                            : false
                        }
                        clearFilter={() => this.filterReport(null)}
                        nativeId={'reportsSearch'}
                        eventName={'Reports@ > Search'}
                      />
                    </View>
                </View>
                    {_.isEmpty(this.state.reports) &&
                      !this.state.lodding &&
                      this.state.searchStr !== "" &&
                      this.state.searchStr !== null && <NoResultFound />}
                  </View>
                </View>
              </PageContainer>

              {this.state.lodding ? (
                <ActivityIndicator pdLarge={true} />
              ) : (
                <PageContainer>
                  <View style={stylesRes.botPd} dataSet={{media: ids.botPd}}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        {this.state.reports.length > 0 ? (
                          <View style={[styles.secArea]}>
                            <View style={[styles.secSubContentArea, styles.pdTopSm]}>
                              {/* Loop this section  - start*/}

                              {this.state.reports.map((item, index) => (
                                <View
                                  key={item.id}
                                  style={[
                                    styles.viewSingleRow,
                                    styles.comSpSm,
                                    // styles.menuItemView,
                                  ]}
                                >
                                  <View
                                    style={[
                                      styles.menuSingleBar,
                                      styles.menuBgWhite,
                                      styles.menuItemView,
                                    ]}
                                  >
                                    <TouchableOpacity
                                      style={[styles.menuSingleMain]}
                                      disabled={item.status !== "ENABLED"}
                                      onPress={() => this.onView(item.id)}
                                      nativeID={'reportsViewReport'}
                                    >
                                      <View style={styles.menuSingleContBlock}>
                                        <View style={[styles.flexCom, styles.menuCont]}>
                                          <View
                                            style={[
                                              styles.flexCom,
                                              styles.menuSingleTextViewSm,
                                              styles.colPdTopBotRg,
                                            ]}
                                          >
                                            <Text
                                              style={[
                                                styles.textPrimeBold,
                                                item.status !== "ENABLED" // props.disableText
                                                  ? styles.textGreyDarkMd
                                                  : styles.activeText,
                                              ]}
                                              allowFontScaling={false}
                                            >
                                              {item.name}
                                            </Text>
                                          </View>
                                          <View
                                            style={[
                                              Boolean(this.state.downloadWait) && Boolean(item.id === this.state.reportId) && styles.flexRow,
                                              styles.dataIconSecMd,
                                              styles.colPdTopBotRg,
                                            ]}
                                          >
                                            {Boolean(this.state.downloadWait) && Boolean(item.id === this.state.reportId) &&
                                              <ActivityIndicator size />
                                            }
                                            <Text
                                              style={[
                                                Boolean(this.state.downloadWait) && Boolean(item.id === this.state.reportId) && styles.flexAsCt,
                                                styles.textPrimeSm,
                                                styles.textAlignRight,
                                                item.status !== "ENABLED"
                                                  ? styles.textGreyDarkMd
                                                  : styles.activeText,
                                              ]}
                                              allowFontScaling={false}
                                            >
                                              {moment(item.createdOn, "x").format(
                                                "D MMM 'YY"
                                              )}
                                            </Text>
                                          </View>
                                        </View>
                                      </View>

                                      {/* styles.comSpSm,
                                          // styles.menuItemView,
                                        ]}>
                                        <View
                                          style={[
                                            styles.menuSingleBar,
                                            styles.menuBgWhite,
                                            styles.menuItemView,
                                          ]}>
                                          <TouchableOpacity
                                            style={[styles.menuSingleMain]}
                                            onPress={() => this.OnEdit(item.id)}
                                            disabled={item.status !== 'ENABLED'}>
                                            <View style={styles.menuSingleContBlock}>
                                              <View
                                                style={[
                                                  styles.flexCom,
                                                  styles.menuCont,
                                                ]}>
                                                <View
                                                  style={[
                                                    styles.flexCom,
                                                    styles.menuSingleTextViewSm,
                                                    styles.colPdTopBotRg,
                                                  ]}>
                                                  <Text
                                                    style={[
                                                      styles.textPrimeBold,
                                                      item.status !== 'ENABLED' // props.disableText
                                                        ? styles.textGreyDarkMd
                                                        : styles.activeText,
                                                    ]}>
                                                    {item.name}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={[
                                                    styles.dataIconSecMd,
                                                    styles.colPdTopBotRg,
                                                  ]}>
                                                  <Text
                                                    style={[
                                                      styles.textPrimeSm,
                                                      styles.textAlignRight,
                                                      item.status !== 'ENABLED'
                                                        ? styles.textGreyDarkMd
                                                        : styles.activeText,
                                                    ]}>
                                                    {moment(
                                                      item.modifiedOn,
                                                      'x',
                                                    ).format("D MMM 'YY")}
                                                  </Text>*/}

                                      <View
                                        style={[styles.dataIconSecSm, styles.secCenter]}
                                      >
                                        <ReportPopupMenu
                                          onDelete={() => this.onDelete(item.id)}
                                          onEdit={() => {
                                            this.tapCount = this.tapCount + 1;
                                            this.OnEdit(item.id)
                                          }}
                                          onDownloadReport={() => {
                                            this.tapCount = this.tapCount + 1;
                                            this.onDownloadReport(item.id);
                                          }}
                                          onSendEmail={() => {
                                            this.onSendEmail(item.id);
                                          }}
                                          disableText={
                                            item.status !== "ENABLED" ? true : false
                                          }
                                          nativeIdMenuTrigger={'reports3Dots'}
                                          eventNameMenuTrigger={'Reports@ > 3Dots'}
                                          nativeIdEdit={'reports3DotsEdit'}
                                          nativeIdDelete={'reports3DotsDelete'}
                                          nativeIdDownload={'reports3DotsDownload'}
                                          nativeIdSendasEmail={'reports3DotsSendasEmail'}
                                        />
                                      </View>
                                    </TouchableOpacity>
                                  </View>
                                </View>
                              ))}
                              {/* Loop this section  - end*/}
                            </View>
                          </View>
                        ) : null}
                      </View>
                    </View>
                  </View>
                </PageContainer>
              )}

              <ConfirmationBox
                visibleModal={this.state.deleteConform}
                onBackdropPress={() => {
                  this.setState({ deleteConform: false, selectedId: null });
                }}
                onRequestClose={() => {
                  this.setState({ deleteConform: false, selectedId: null });
                }}
                alertTitle="Delete Report"
                handleDelete={this.handleDeleteReport}
                deleteWait={this.state.deleteWait}
              />
              {this.state.visibleModalSendEmail && (
                <SendEmailBox
                  visibleModalSendEmail={this.state.visibleModalSendEmail}
                  onBackdropPress={() => {
                    this.setState({
                      visibleModalSendEmail: false,
                      selectedId: null,
                    });
                  }}
                  onRequestClose={() => {
                    this.setState({
                      visibleModalSendEmail: false,
                      selectedId: null,
                    });
                  }}
                  handleSendEmail={(emails, callback) =>
                    this.handleSendEmailReport(emails, callback)
                  }
                  sendWait={this.state.sendWait}
                />
              )}
              <SuccessBox
                visibleModal={this.state.successModalVisible}
                message="Success! Email has been sent."
              />
            </View>
          </ScrollView>
        </View>      
    );
  }
}

const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
    userSubscriptions: state.routines.userSubscription,
    reportFilter: state.filter.reportFilter
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearFilesToAttach: () => dispatch(actions.clearFilesToAttach()),
    getAllUserReport: (journalUserId, callBack) =>
      dispatch(actions.getAllUserReport(journalUserId, callBack)),
    deleteReport: (id, callBack, journalUserId) =>
      dispatch(actions.deleteReport(id, callBack, journalUserId)),
    selectReport: (id, data) => dispatch(actions.selectReport(id, data)),
    resetReport: () => dispatch(actions.resetReport()),
    setReportFilter: filter => dispatch(actions.setReportFilter(filter)),
    setInitHashValue: initHashVal => dispatch(actions.setInitHashValue(initHashVal)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsScreen);
