import React, {Component} from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import styles from '../../../Css';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import ActivityIndicator from '../../ActivityIndicator/activityIndicator';
import ListRoutine from '../../../screens/Routines/ListRoutine';
import {pendoTrackEvent} from '../../../util/pendoConfig';
class CheckInItem extends Component {
  constructor(props) {
    super(props);
    const {disabledAll} = props;
    this.state = {
      disabledAll: disabledAll,
      loading: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {disabledAll} = props;

    if (state.disabledAll !== disabledAll) {
      return {
        disabledAll: disabledAll,
      };
    }

    return null;
  }

  handleCreateNewRoutine = () => {
    const {
      journalEntryTypeId,
      onCreateNewRoutine,
      journalUserId,
      redirectJournal,
      isQuickNote,
      setDisabledAll,
      isDeviceJournal,
      journalEntriesProcessed,
      journalEntryTypesProcessed,
      deviceName,
      onSetSelectedJournalEntry,
      title,
    } = this.props;
    if (isQuickNote) {
      redirectJournal();
    } else {
      this.setState({loading: true});
      setDisabledAll(true);
      if (isDeviceJournal) {
        const journalEntries = Object.values(journalEntriesProcessed);
        const allJournal = ListRoutine.sortBydate(ListRoutine.getJournal(
          journalEntries,
          journalEntryTypesProcessed,
        ));
        const deviceJournals = allJournal.filter(item => item.deviceName === deviceName);
        if (deviceJournals && deviceJournals.length > 0) {
          onSetSelectedJournalEntry(deviceJournals[0]);
        } else {
          onSetSelectedJournalEntry(allJournal?.[0] || null);
        }
        this.setState({loading: false});
        setDisabledAll(false);
        redirectJournal(
          deviceJournals && deviceJournals.length > 0 
            ? deviceJournals[0]?.title 
            : allJournal?.[0]?.title, true);
      } else {
        onCreateNewRoutine(
          { journalEntryTypeId, draft: true },
          res => {
            this.setState({loading: false});
            setDisabledAll(false);
            if (res) {
              redirectJournal(title);
            }
          },
          journalUserId,
        );
      }
    }
    pendoTrackEvent('Dashboard@ > Routine');
  };

  render() {
    const {title, icon, color, boxBg, hasLogoIcon} = this.props;
    const {disabledAll, loading} = this.state;
    return (
      <View style={[styles.itemsContainerCol]}>
        <TouchableOpacity
          onPress={this.handleCreateNewRoutine}
          disabled={disabledAll}>
          <View 
            style={[styles.itemsContainer, styles.containerBlue, boxBg]} 
            nativeID={'dashboardRoutinItem'}
          >
            <View style={[styles.itemIconBox, {backgroundColor: color}]}>
              <Image style={[hasLogoIcon ? styles.itemIconBox : styles.itemIcon]} source={icon} />
            </View>
            <View style={[styles.itemTextBox, styles.itemTextBoxRtSp]}>
              <Text style={[styles.textPrimeBold]} allowFontScaling={false}>{title}</Text>
              {Boolean(loading) && (
                <View style={styles.actIndIconFixed}>
                  <ActivityIndicator size="small" pdZero={true} />
                </View>
              )}
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalEntryTypes: state.routines.journalEntryTypes,
    journalUserId: state.uistate.journalUserId,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateNewRoutine: (journalEntry, callBack, journalUserId) =>
      dispatch(actions.createNewRoutine(journalEntry, callBack, journalUserId)),
    onSetSelectedJournalEntry: selectedId =>
      dispatch(actions.setSelectedJournalEntry(selectedId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckInItem);
