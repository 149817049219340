import theme from "./base/theme";
import * as variable from "./base/variable";
import * as variableCommon from "./base/variableCommon";
export const cssForm = {
  textField: {
    ...variable.textFieldPrimary,
    ...variable.textFieldPd,
    maxHeight: 61,
    width: "100%",
  },
  textFieldWhite: {
    backgroundColor: theme.COLOR_WHITE,
  },
  textFieldSm: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 10,
    paddingRight: 10,
    maxHeight: 51,
  },
  textFieldLg: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 30,
    maxHeight: 51,
  },
  textFieldExLg: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 48,
    maxHeight: 51,
  },
  textFieldDeleteAccount : {
    ...variable.btnBorderRadius,
    borderColor: theme.COLOR_GREY_MEDIUM,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    maxHeight: 51,
    maxWidth: 80,
  },
  submit: {
    ...variable.btnBorderRadius,
    ...variable.btnBgCom,
    paddingTop: 15,
    paddingRight: 60,
    paddingBottom: 15,
    paddingLeft: 60,
  },
  submitSm: {
    ...variable.btnBorderRadius,
    ...variable.btnBgCom,
    paddingTop: 15,
    paddingRight: 15,
    paddingBottom: 15,
    paddingLeft: 15,
  },
  cancelButtonSm: {
    ...variable.btnBorderRadius,
    ...variable.btnBgCom,
    paddingTop: 13,
    paddingRight: 15,
    paddingBottom: 13,
    paddingLeft: 15,
  },

  cancelBtnSmCr: {
    paddingTop: 13,
    paddingBottom: 13,
    backgroundColor:'white',
    borderWidth: 2, 
    borderColor:theme.COLOR_GREY_DARK_1
  },

  progressBarCancelBtn: {
    alignItems: 'center',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    height: 42,
    justifyContent: 'center',
    width: 140,
  },
  journalBtns: {
    alignItems: 'center',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    height: 42,
    justifyContent: 'center',
    maxWidth: 140,
  },
   submitMiniBtn: {
    minWidth: 170,
  },
  submitExSm: {
    ...variable.btnBorderRadius,
    ...variable.btnBgCom,
    paddingTop: 15,
    paddingRight: 10,
    paddingBottom: 15,
    paddingLeft: 10,
  },
  submitMd: {
    ...variable.btnBorderRadius,
    ...variable.btnBgCom,
    paddingTop: 15,
    paddingRight: 30,
    paddingBottom: 15,
    paddingLeft: 30,
  },
  btnLinkCom: {
    ...variable.btnBorderRadiusSm,
    ...variable.btnBgCom,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    minWidth: 120,
  },
  submitSub: {
    ...variable.btnBorderRadius,
    ...variable.btnBgCom,
    paddingTop: 12,
    paddingRight: 15,
    paddingBottom: 12,
    paddingLeft: 15,
  },
  submitSubSm: {
    ...variable.btnBorderRadius,
    ...variable.btnBgCom,
    paddingTop: 12,
    paddingRight: 10,
    paddingBottom: 12,
    paddingLeft: 10,
  },
  submitSubCom: {
    ...variable.btnBorderRadius,
    ...variable.btnBgCom,
    paddingTop: 12,
    paddingRight: 40,
    paddingBottom: 12,
    paddingLeft: 40,
  },
  submitRg: {
    paddingRight: 50,
    paddingLeft: 50,
  },
  submitRgSm: {
    paddingRight: 40,
    paddingLeft: 40,
  },
  btnFixed: {
    maxWidth: 120,
  },
  validColor: {
    borderColor: theme.COLOR_BORDER,
  },
  
  inValidColor: {
    borderColor: theme.COLOR_RED,
    borderWidth:1,
  },
  textAreaWrapper: {
    ...variable.textAreaPrimary,
    ...variable.textAreaTextPd,
  },
  textAreaField: {
    color: theme.PRIMARY_FONT_COLOR,
    ...variable.textAreaPd,
  },
  pickerAreaWrapper: {
    ...variable.textPickerComArea,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 1,
    paddingRight: 1,
  },
  pickerSelectionWrapper: {
    ...variable.textPickerComArea,
    paddingTop: 10,
    paddingBottom: 1,
    paddingLeft: 1,
    paddingRight: 1,
  },
  pickerAreaField: {
    color: theme.PRIMARY_FONT_COLOR,
    ...variable.textPickPd,
    height: 48,
    borderWidth: 0,
    ...variableCommon.pickerBg,
  },
  textPickerPdSmCom: {
    paddingTop: 0,
    paddingRight: 20,
    paddingBottom: 0,
    paddingLeft: 7,
    height: 47,
  },
  textPickerPdMiniCom: {
    paddingTop: 0,
    paddingRight: 20,
    paddingBottom: 0,
    paddingLeft: 7,
    height: 37,
  },
  textPickerPdMedCom: {
    paddingTop: 0,
    paddingRight: 20,
    paddingBottom: 0,
    paddingLeft: 7,
    height: 57,
  },
  selectionPicker: {
    paddingTop: 0,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 7,
    height: 48,
    borderTopWidth: 18,
    borderTopColor: "transparent",
  },
  emailSendWrapperFull: {
    ...variable.textPickerComArea,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 1,
    paddingRight: 1,
    width: "100%",
  },
  textFieldEmailSm: {
    ...variable.fontPrimary,
    paddingLeft: 10,
    paddingTop: 10,
    paddingRight: 15,
    paddingBottom: 15,
  },
  textFieldEmailText: {
    ...variable.fontPrimary,
    paddingLeft: 10,
    paddingTop: 10,
    paddingRight: 15,
    paddingBottom: 15,
  },
  textFieldMax: {
    maxHeight: 61,
  },
  textAreaMax: {
    maxHeight: "auto",
  },
  params: {
    backgroundColor: theme.COLOR_WHITE,
    borderRadius: 10,
    padding: 10,
    alignItems: "flex-start",
    margin: 5,
  },
  param: {
    fontSize: 12,
    textAlign: "center",
    color: "#333333",
    marginBottom: 5,
  },
  comDetailWrapper: {
    ...variable.textPickerComArea,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 1,
    paddingRight: 1,
    width: "100%",
  },
  tickStyleCom: {
    marginTop: 2,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
  },
  btnActionCom: {
    ...variable.btnBorderRadiusSm,
    ...variable.btnBgCom,
    paddingTop: 8,
    paddingRight: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    minWidth: 80,
  },
  btnActionComLg: {
    ...variable.btnBorderRadiusSm,
    ...variable.btnBgCom,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    minWidth: 80,
  },
  pickerIconView: {
    position: "absolute",
    display: "flex",
    right: 10,
    zIndex: 99999,
  },
  pickerIconCent: {
    top: 0,
    bottom: 0,
    justifyContent: "center",
  },
  pickerArrowIcon: {
    width: 6,
    height: 12,
  },
  pickerDownArrowIcon: {
    width: 10,
    height: 15,
  },
  pickerWrapperWithTitle: {
    ...variable.textPickerComArea,
    paddingTop: 10,
    paddingBottom: 1,
    paddingLeft: 1,
    paddingRight: 1,
  },
  pickerWrapperWithoutTitle: {
    ...variable.textPickerComArea,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 1,
    paddingRight: 1,
  },
  primaryButton: {
    ...variable.btnBorderRadiusSm,
    ...variable.btnBgCom,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    minWidth: 115,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  btnBorderLight: {
    borderColor: theme.COLOR_GREY_LIGHT_RG
  },
  btnBorderTransparent: {
    borderColor: theme.COLOR_TRANSPARENT
  },
  btnBorderNone: {
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  textPickerPdExMiniCom: {
    paddingTop: 0,
    paddingRight: 20,
    paddingBottom: 0,
    paddingLeft: 7,
    height: 30,
  },
  primaryButtonLg: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  textAreaFieldAuto: {
    ...variable.textFieldPrimary,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 10,
    paddingRight: 10,
    width: "100%",
  },
  textEditorHeightSm: {
    minHeight: 100,
  },
  textEditorHeightLg: {
    minHeight: 170,
  },
  primaryButtonLink: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 25,
    paddingRight: 25,
    minWidth: 225,
  },
  primaryButtonFixed: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 30,
    paddingLeft: 30,
  },
  zoomLgIcon: {
    width: 150,
    height: 120,
  },
  zoomPd : {
    paddingTop: 30,
    paddingBottom: 30,
    paddingRight: 10,
    paddingLeft: 10,
  },
  duplicateWarningColor:{
    borderColor: theme.COLOR_ORANGE,
  },
  pickerWidth: {
    width:120
  },
  pickerWidthWeb: {
    width:90
  }
};
