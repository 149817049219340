import React from 'react';
import {View, TextInput, TouchableOpacity, Image} from 'react-native';
import styles from '../../Css';
import {pendoTrackEvent} from '../../util/pendoConfig';

const SearchField = props => {
  return (
    <React.Fragment>
      <View style={[styles.btnActionView, styles.btnActionInfoRtZero]}>
        {!props.showClearBtton && (
          <TouchableOpacity style={styles.touchZoom}>
            <Image
              style={styles.searchZoomIcon}
              source={require('../../assets/icons/search-zoom.png')}
            />
          </TouchableOpacity>
        )}

        {props.showClearBtton && (
          <TouchableOpacity
            style={styles.touchZoom}
            onPress={props.clearFilter}>
            <Image
              style={styles.searchClearIcon}
              source={require('../../assets/icons/search-result-clear.png')}
            />
          </TouchableOpacity>
        )}
      </View>
      <TextInput
        style={[styles.textField, styles.textFieldWhite, 
        props.searchFieldSm
        ? styles.textFieldSearchSm  
        : styles.textFieldExLg
        ]}
        allowFontScaling={false}
        placeholder={props.placeholder}
        onChangeText={props.onChangeText}
        underlineColorAndroid="transparent"
        value={props.searchText}
        nativeID={props.nativeId}
        onFocus={() => {
          pendoTrackEvent(props.eventName);
        }}
      />
    </React.Fragment>
  );
};

export default SearchField;
