// const treatmentTypes = {
//     1: {id: 1, name: 'Other'},
//     2: {id: 2, name: 'Medication'},
//     3: {id: 3, name: 'Natural Health Product'},
//     4: {id: 4, name: 'Surgery'},
//     5: {id: 5, name: 'Activity'},
// };

import {nullableStringCompare} from "../../util/stringManipulations";

export const filterMetrics = (entry, metricsDigestProcessed) => {
    let metrics = [];

    if (entry.defaultMetrics && entry.defaultMetrics.length > 0) {
        entry.defaultMetrics.forEach(element => {
            const item = metricsDigestProcessed.selectableMetrics[element.metric];
            if (item) {
                const sysType =
                    metricsDigestProcessed.systemDefinedMetricGroups[item.metricGroup];
                const userType =
                    metricsDigestProcessed.userDefinedMetricGroups[item.metricGroup];
                const type = sysType ? sysType : userType;

                metrics.push({
                    ...element,
                    name: `${type ? `${type.name} - ` : ''}${item.name}`,
                    id: element.id,
                    metricRef: item,
                });
            }
        });
    }
    metrics.sort((x, y) => nullableStringCompare(x?.name, y?.name));
    return metrics;
};

export const filterSymptoms = (entry, symptomsDigestProcessed) => {
    let symptoms = [];

    if (entry.defaultSymptoms && entry.defaultSymptoms.length > 0) {
        entry.defaultSymptoms.forEach(element => {
            const item = symptomsDigestProcessed.userSymptoms[element.userSymptom];
            if (item) {
                symptoms.push({
                    ...element,
                    name: item.name,
                    id: element.id,
                    symptom: element.userSymptom,
                    symptomObj: item,
                });
            }
        });
    }
    symptoms.sort((x, y) => nullableStringCompare(x?.name, y?.name));
    return symptoms;
};

export const filterTreatments = (entry, treatmentsProcessed) => {
    let treatments = [];

    if (entry.defaultTreatments && entry.defaultTreatments.length > 0) {
        entry.defaultTreatments.forEach(element => {
            const item = treatmentsProcessed[element.treatment];
            if (item) {
                //const type = treatmentTypes[item.treatmentType];
                treatments.push({
                    ...element,
                    //name: `${type ? `${type.name} - ` : ''}${item.name}`,
                    name: item.name,
                    id: element.id,
                    treatmentObj: item,
                });
            }
        });
    }
    treatments.sort((x, y) => nullableStringCompare(x?.name, y?.name));
    return treatments;
};
