import React from 'react';
import {View} from 'react-native';
import styles from '../../Css';
import TextPickerFrame from '../../components/TextInput/textPickerFrame';
import * as FieldDetails from '../../components/TextInput/fieldDetails';

const Country = props => {
  return (
    <View style={[styles.fieldRow]}>
      <View style={[styles.fieldRowWrapper]}>
        <View style={styles.fieldColStretch}>
          <TextPickerFrame
            title={'Country of Residence'}
            fieldBg={FieldDetails.BGWHITE}
            //requirdStar={'*'}
            invalid={props.invalid}
            items={[
              {label: '', value: '', key: 'c_0'},
              {label: 'Canada', value: 'Canada', key: 'c_1'},
              {
                label: 'United States of America (USA)',
                value: 'United States of America (USA)',
                key: 'c_2',
              },
              {
                label: 'Afghanistan',
                value: 'Afghanistan',
                key: 'c_3',
              },
              {
                label: 'Albania',
                value: 'Albania',
                key: 'c_4',
              },
              {
                label: 'Algeria',
                value: 'Algeria',
                key: 'c_5',
              },
              {
                label: 'Andorra',
                value: 'Andorra',
                key: 'c_6',
              },
              {label: 'Angola', value: 'Angola', key: 'c_7'},
              {
                label: 'Antigua and Barbuda',
                value: 'Antigua and Barbuda',
                key: 'c_8',
              },
              {
                label: 'Argentina',
                value: 'Argentina',
                key: 'c_9',
              },
              {
                label: 'Armenia',
                value: 'Armenia',
                key: 'c_10',
              },
              {
                label: 'Australia',
                value: 'Australia',
                key: 'c_11',
              },
              {
                label: 'Austria',
                value: 'Austria',
                key: 'c_12',
              },
              {
                label: 'Azerbaijan',
                value: 'Azerbaijan',
                key: 'c_13',
              },
              {
                label: 'Bahamas',
                value: 'Bahamas',
                key: 'c_15',
              },
              {
                label: 'Bahrain',
                value: 'Bahrain',
                key: 'c_16',
              },
              {
                label: 'Bangladesh',
                value: 'Bangladesh',
                key: 'c_17',
              },
              {
                label: 'Barbados',
                value: 'Barbados',
                key: 'c_18',
              },
              {
                label: 'Belarus',
                value: 'Belarus',
                key: 'c_19',
              },
              {
                label: 'Belgium',
                value: 'Belgium',
                key: 'c_20',
              },
              {label: 'Belize', value: 'Belize', key: 'c_21'},
              {label: 'Benin', value: 'Benin', key: 'c_22'},
              {label: 'Bhutan', value: 'Bhutan', key: 'c_23'},
              {
                label: 'Bolivia',
                value: 'Bolivia',
                key: 'c_24',
              },
              {
                label: 'Bosnia and Herzegovina',
                value: 'Bosnia and Herzegovina',
                key: 'c_25',
              },
              {
                label: 'Botswana',
                value: 'Botswana',
                key: 'c_26',
              },
              {label: 'Brazil', value: 'Brazil', key: 'c_27'},
              {label: 'Brunei', value: 'Brunei', key: 'c_28'},
              {
                label: 'Bulgaria',
                value: 'Bulgaria',
                key: 'c_29',
              },
              {
                label: 'Burkina Faso',
                value: 'Burkina Faso',
                key: 'c_30',
              },
              {
                label: 'Burundi',
                value: 'Burundi',
                key: 'c_31',
              },
              {
                label: 'Cabo Verde',
                value: 'Cabo Verde',
                key: 'c_32',
              },
              {
                label: 'Cambodia',
                value: 'Cambodia',
                key: 'c_33',
              },
              {
                label: 'Cameroon',
                value: 'Cameroon',
                key: 'c_34',
              },
              {
                label: 'Central African Republic (CAR)',
                value: 'Central African Republic (CAR)',
                key: 'c_35',
              },
              {label: 'Chad', value: 'Chad', key: 'c_36'},
              {label: 'Chile', value: 'Chile', key: 'c_37'},
              {label: 'China', value: 'China', key: 'c_199'},
              {
                label: 'Colombia',
                value: 'Colombia',
                key: 'c_38',
              },
              {
                label: 'Comoros',
                value: 'Comoros',
                key: 'c_39',
              },
              {
                label: 'Congo, Democratic Republic of the',
                value: 'Congo, Democratic Republic of the',
                key: 'c_40',
              },
              {
                label: 'Congo, Republic of the',
                value: 'Congo, Republic of the',
                key: 'c_41',
              },
              {
                label: 'Costa Rica',
                value: 'Costa Rica',
                key: 'c_42',
              },
              {
                label: "Cote d'Ivoire",
                value: "Cote d'Ivoire",
                key: 'c_43',
              },
              {
                label: 'Croatia',
                value: 'Croatia',
                key: 'c_44',
              },
              {label: 'Cuba', value: 'Cuba', key: 'c_45'},
              {label: 'Cyprus', value: 'Cyprus', key: 'c_46'},
              {
                label: 'Czechia',
                value: 'Czechia',
                key: 'c_47',
              },
              {
                label: 'Denmark',
                value: 'Denmark',
                key: 'c_48',
              },
              {
                label: 'Djibouti',
                value: 'Djibouti',
                key: 'c_49',
              },
              {
                label: 'Dominica',
                value: 'Dominica',
                key: 'c_50',
              },
              {
                label: 'Dominican Republic',
                value: 'Dominican Republic',
                key: 'c_51',
              },
              {
                label: 'Ecuador',
                value: 'Ecuador',
                key: 'c_52',
              },
              {label: 'Egypt', value: 'Egypt', key: 'c_53'},
              {
                label: 'El Salvador',
                value: 'El Salvador',
                key: 'c_54',
              },
              {
                label: 'Equatorial Guinea',
                value: 'Equatorial Guinea',
                key: 'c_55',
              },
              {
                label: 'Eritrea',
                value: 'Eritrea',
                key: 'c_56',
              },
              {
                label: 'Estonia',
                value: 'Estonia',
                key: 'c_57',
              },
              {
                label: 'Eswatini (formerly Swaziland)',
                value: 'Eswatini (formerly Swaziland)',
                key: 'c_58',
              },
              {
                label: 'Ethiopia',
                value: 'Ethiopia',
                key: 'c_59',
              },
              {label: 'Fiji', value: 'Fiji', key: 'c_60'},
              {
                label: 'Finland',
                value: 'Finland',
                key: 'c_61',
              },
              {label: 'France', value: 'France', key: 'c_62'},
              {label: 'Gabon', value: 'Gabon', key: 'c_63'},
              {label: 'Gambia', value: 'Gambia', key: 'c_64'},
              {
                label: 'Georgia',
                value: 'Georgia',
                key: 'c_65',
              },
              {
                label: 'Germany',
                value: 'Germany',
                key: 'c_66',
              },
              {label: 'Ghana', value: 'Ghana', key: 'c_67'},
              {label: 'Greece', value: 'Greece', key: 'c_68'},
              {
                label: 'Grenada',
                value: 'Grenada',
                key: 'c_69',
              },
              {
                label: 'Guatemala',
                value: 'Guatemala',
                key: 'c_70',
              },
              {label: 'Guinea', value: 'Guinea', key: 'c_71'},
              {
                label: 'Guinea-Bissau',
                value: 'Guinea-Bissau',
                key: 'c_72',
              },
              {label: 'Guyana', value: 'Guyana', key: 'c_73'},
              {label: 'Haiti', value: 'Haiti', key: 'c_74'},
              {
                label: 'Honduras',
                value: 'Honduras',
                key: 'c_75',
              },
              {
                label: 'Hungary',
                value: 'Hungary',
                key: 'c_76',
              },
              {
                label: 'Iceland',
                value: 'Iceland',
                key: 'c_77',
              },
              {label: 'India', value: 'India', key: 'c_78'},
              {
                label: 'Indonesia',
                value: 'Indonesia',
                key: 'c_201',
              },
              {label: 'Iran', value: 'Iran', key: 'c_80'},
              {label: 'Iraq', value: 'Iraq', key: 'c_81'},
              {
                label: 'Ireland',
                value: 'Ireland',
                key: 'c_82',
              },
              {label: 'Israel', value: 'Israel', key: 'c_83'},
              {label: 'Italy', value: 'Italy', key: 'c_84'},
              {
                label: 'Jamaica',
                value: 'Jamaica',
                key: 'c_85',
              },
              {label: 'Japan', value: 'Japan', key: 'c_86'},
              {label: 'Jordan', value: 'Jordan', key: 'c_87'},
              {
                label: 'Kazakhstan',
                value: 'Kazakhstan',
                key: 'c_88',
              },
              {label: 'Kenya', value: 'Kenya', key: 'c_89'},
              {
                label: 'Kiribati',
                value: 'Kiribati',
                key: 'c_90',
              },
              {label: 'Kosovo', value: 'Kosovo', key: 'c_91'},
              {label: 'Kuwait', value: 'Kuwait', key: 'c_92'},
              {
                label: 'Kyrgyzstan',
                value: 'Kyrgyzstan',
                key: 'c_93',
              },
              {label: 'Laos', value: 'Laos', key: 'c_94'},
              {label: 'Latvia', value: 'Latvia', key: 'c_95'},
              {
                label: 'Lebanon',
                value: 'Lebanon',
                key: 'c_96',
              },
              {
                label: 'Lesotho',
                value: 'Lesotho',
                key: 'c_97',
              },
              {
                label: 'Liberia',
                value: 'Liberia',
                key: 'c_98',
              },
              {label: 'Libya', value: 'Libya', key: 'c_99'},
              {
                label: 'Liechtenstein',
                value: 'Liechtenstein',
                key: 'c_100',
              },
              {
                label: 'Lithuania',
                value: 'Lithuania',
                key: 'c_101',
              },
              {
                label: 'Luxembourg',
                value: 'Luxembourg',
                key: 'c_102',
              },
              {
                label: 'Madagascar',
                value: 'Madagascar',
                key: 'c_103',
              },
              {
                label: 'Malawi',
                value: 'Malawi',
                key: 'c_104',
              },
              {
                label: 'Malaysia',
                value: 'Malaysia',
                key: 'c_105',
              },
              {
                label: 'Maldives',
                value: 'Maldives',
                key: 'c_106',
              },
              {label: 'Mali', value: 'Mali', key: 'c_107'},
              {label: 'Malta', value: 'Malta', key: 'c_108'},
              {
                label: 'Marshall Islands',
                value: 'Marshall Islands',
                key: 'c_109',
              },
              {
                label: 'Mauritania',
                value: 'Mauritania',
                key: 'c_110',
              },
              {
                label: 'Mauritius',
                value: 'Mauritius',
                key: 'c_111',
              },
              {
                label: 'Mexico',
                value: 'Mexico',
                key: 'c_112',
              },
              {
                label: 'Micronesia',
                value: 'Micronesia',
                key: 'c_113',
              },
              {
                label: 'Moldova',
                value: 'Moldova',
                key: 'c_114',
              },
              {
                label: 'Monaco',
                value: 'Monaco',
                key: 'c_115',
              },
              {
                label: 'Mongolia',
                value: 'Mongolia',
                key: 'c_116',
              },
              {
                label: 'Montenegro',
                value: 'Montenegro',
                key: 'c_117',
              },
              {
                label: 'Morocco',
                value: 'Morocco',
                key: 'c_118',
              },
              {
                label: 'Mozambique',
                value: 'Mozambique',
                key: 'c_119',
              },
              {
                label: 'Myanmar',
                value: 'Myanmar',
                key: 'c_120',
              },
              {
                label: 'Namibia',
                value: 'Namibia',
                key: 'c_121',
              },
              {label: 'Nauru', value: 'Nauru', key: 'c_122'},
              {label: 'Nepal', value: 'Nepal', key: 'c_123'},
              {
                label: 'Netherlands',
                value: 'Netherlands',
                key: 'c_124',
              },
              {
                label: 'New Zealand',
                value: 'New Zealand',
                key: 'c_125',
              },
              {
                label: 'Nicaragua',
                value: 'Nicaragua',
                key: 'c_126',
              },
              {label: 'Niger', value: 'Niger', key: 'c_127'},
              {
                label: 'Nigeria',
                value: 'Nigeria',
                key: 'c_128',
              },
              {
                label: 'North Korea',
                value: 'North Korea',
                key: 'c_129',
              },
              {
                label: 'North Macedonia',
                value: 'North Macedonia',
                key: 'c_130',
              },
              {
                label: 'Norway',
                value: 'Norway',
                key: 'c_131',
              },
              {label: 'Oman', value: 'Oman', key: 'c_132'},
              {
                label: 'Pakistan',
                value: 'Pakistan',
                key: 'c_133',
              },
              {label: 'Palau', value: 'Palau', key: 'c_134'},
              {
                label: 'Palestine',
                value: 'Palestine',
                key: 'c_135',
              },
              {
                label: 'Panama',
                value: 'Panama',
                key: 'c_136',
              },
              {
                label: 'Papua New Guinea',
                value: 'Papua New Guinea',
                key: 'c_137',
              },
              {
                label: 'Paraguay',
                value: 'Paraguay',
                key: 'c_138',
              },
              {label: 'Peru', value: 'Peru', key: 'c_139'},
              {
                label: 'Philippines',
                value: 'Philippines',
                key: 'c_140',
              },
              {
                label: 'Poland',
                value: 'Poland',
                key: 'c_141',
              },
              {
                label: 'Portugal',
                value: 'Portugal',
                key: 'c_142',
              },
              {label: 'Qatar', value: 'Qatar', key: 'c_143'},
              {
                label: 'Romania',
                value: 'Romania',
                key: 'c_144',
              },
              {
                label: 'Russia',
                value: 'Russia',
                key: 'c_145',
              },
              {
                label: 'Rwanda',
                value: 'Rwanda',
                key: 'c_146',
              },
              {
                label: 'Saint Kitts and Nevis',
                value: 'Saint Kitts and Nevis',
                key: 'c_147',
              },
              {
                label: 'Saint Lucia',
                value: 'Saint Lucia',
                key: 'c_148',
              },
              {
                label: 'Saint Vincent and the Grenadines',
                value: 'Saint Vincent and the Grenadines',
                key: 'c_149',
              },
              {label: 'Samoa', value: 'Samoa', key: 'c_150'},
              {
                label: 'San Marino',
                value: 'San Marino',
                key: 'c_151',
              },
              {
                label: 'Sao Tome and Principe',
                value: 'Sao Tome and Principe',
                key: 'c_152',
              },
              {
                label: 'Saudi Arabia',
                value: 'Saudi Arabia',
                key: 'c_153',
              },
              {
                label: 'Senegal',
                value: 'Senegal',
                key: 'c_154',
              },
              {
                label: 'Serbia',
                value: 'Serbia',
                key: 'c_155',
              },
              {
                label: 'Seychelles',
                value: 'Seychelles',
                key: 'c_156',
              },
              {
                label: 'Sierra Leone',
                value: 'Sierra Leone',
                key: 'c_157',
              },
              {
                label: 'Singapore',
                value: 'Singapore',
                key: 'c_158',
              },
              {
                label: 'Slovakia',
                value: 'Slovakia',
                key: 'c_159',
              },
              {
                label: 'Slovenia',
                value: 'Slovenia',
                key: 'c_160',
              },
              {
                label: 'Solomon Islands',
                value: 'Solomon Islands',
                key: 'c_161',
              },
              {
                label: 'Somalia',
                value: 'Somalia',
                key: 'c_162',
              },
              {
                label: 'South Africa',
                value: 'South Africa',
                key: 'c_163',
              },
              {
                label: 'South Korea',
                value: 'South Korea',
                key: 'c_164',
              },
              {
                label: 'South Sudan',
                value: 'South Sudan',
                key: 'c_165',
              },
              {label: 'Spain', value: 'Spain', key: 'c_166'},
              {
                label: 'Sri Lanka',
                value: 'Sri Lanka',
                key: 'c_167',
              },
              {label: 'Sudan', value: 'Sudan', key: 'c_168'},
              {
                label: 'Suriname',
                value: 'Suriname',
                key: 'c_169',
              },
              {
                label: 'Sweden',
                value: 'Sweden',
                key: 'c_170',
              },
              {
                label: 'Switzerland',
                value: 'Switzerland',
                key: 'c_171',
              },
              {label: 'Syria', value: 'Syria', key: 'c_172'},
              {
                label: 'Taiwan',
                value: 'Taiwan',
                key: 'c_173',
              },
              {
                label: 'Tajikistan',
                value: 'Tajikistan',
                key: 'c_174',
              },
              {
                label: 'Tanzania',
                value: 'Tanzania',
                key: 'c_200',
              },
              {
                label: 'Thailand',
                value: 'Thailand',
                key: 'c_175',
              },
              {
                label: 'Timor-Leste',
                value: 'Timor-Leste',
                key: 'c_176',
              },
              {label: 'Togo', value: 'Togo', key: 'c_177'},
              {label: 'Tonga', value: 'Tonga', key: 'c_178'},
              {
                label: 'Trinidad and Tobago',
                value: 'Trinidad and Tobago',
                key: 'c_179',
              },
              {
                label: 'Tunisia',
                value: 'Tunisia',
                key: 'c_180',
              },
              {
                label: 'Turkey',
                value: 'Turkey',
                key: 'c_181',
              },
              {
                label: 'Turkmenistan',
                value: 'Turkmenistan',
                key: 'c_182',
              },
              {
                label: 'Tuvalu',
                value: 'Tuvalu',
                key: 'c_183',
              },
              {
                label: 'Uganda',
                value: 'Uganda',
                key: 'c_184',
              },
              {
                label: 'Ukraine',
                value: 'Ukraine',
                key: 'c_185',
              },
              {
                label: 'United Arab Emirates (UAE)',
                value: 'United Arab Emirates (UAE)',
                key: 'c_186',
              },
              {
                label: 'United Kingdom (UK)',
                value: 'United Kingdom (UK)',
                key: 'c_187',
              },
              {
                label: 'Uruguay',
                value: 'Uruguay',
                key: 'c_188',
              },
              {
                label: 'Uzbekistan',
                value: 'Uzbekistan',
                key: 'c_189',
              },
              {
                label: 'Vanuatu',
                value: 'Vanuatu',
                key: 'c_190',
              },
              {
                label: 'Vatican City (Holy See)',
                value: 'Vatican City (Holy See)',
                key: 'c_191',
              },
              {
                label: 'Venezuela',
                value: 'Venezuela',
                key: 'c_192',
              },
              {
                label: 'Vietnam',
                value: 'Vietnam',
                key: 'c_193',
              },
              {label: 'Yemen', value: 'Yemen', key: 'c_194'},
              {
                label: 'Zambia',
                value: 'Zambia',
                key: 'c_195',
              },
              {
                label: 'Zimbabwe',
                value: 'Zimbabwe',
                key: 'c_196',
              },
              {
                label: 'Others',
                value: 'Others',
                key: 'c_197',
              },
              {label: 'Europe', value: 'Europe', key: 'c_198'},
            ]}       
            fieldAlign={FieldDetails.ALIGNLEFT}
            fieldLt={true}
            onValueChange={props.onChangeCountry}
            selectedValue={props.country}
          />
        </View>
      </View>
    </View>
  );
};

export default (Country);
