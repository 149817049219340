import React, { Component } from "react";
import { Platform, View } from "react-native";
import { connect } from "react-redux";
import Attachment from "./attachment";
import ConfirmationBox from "../../components/ConfirmationBox/confirmationBox";
import AttachmentImageBox from "./AttachmentImageBox/classImageBox";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import * as actions from "../../store/actions/index";
import * as axiosCommon from "../../axios/axios-common";
import * as urls from "../../store/actions/urls";
import { deleteAttachmentOcr } from "../../store/actions/ocr";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import {getArtifact} from '../../util/commonUiLogic';
import { ANDROID, WEB } from "../../util/platformWindowConfig";

class Attachments extends Component {
  constructor(props) {
    super(props);
    const artifactId = getArtifact(props);
    const attachmentCategory = props.attachmentCategory ? props.attachmentCategory.split("-") : [];
    this.state = {
      viewImage: false,
      deleteConfirm: false,
      attachId: null,
      deleteWait: false,
      fileName: null,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      downloadToken: "",
      attachmentId: null,
      downloadWait: false,
      userAttachmentsSelected: this.props.userAttachmentsSelected
        ? this.props.userAttachmentsSelected
        : [],
      artifactId: artifactId,
      attachmentCategory: attachmentCategory[0],
      fileSize: 0,
    };

    this.props.fetchUserAttachments(artifactId);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { userAttachmentsSelected, windowHeight, windowWidth } = props;

    if (
      userAttachmentsSelected
      // &&
      // this.state.userAttachmentsSelected.length !==
      //   userAttachmentsSelected.length
    ) {
      this.setState({
        userAttachmentsSelected: userAttachmentsSelected,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      });
      if (this.props.setActiveCircle) {
        this.props.setActiveCircle();
      }
    } else {
      this.setState({
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      });
    }

    if (
      props?.journalEntrySelected?.id !== this.props?.journalEntrySelected?.id
    ) {
      const artifactId = getArtifact(props);
      this.setState(
        {
          artifactId: artifactId,
        },
        () => {
          this.props.fetchUserAttachments(this.state.artifactId);
        }
      );
    }
  }

  handlePopupAttachmentConfirmation = (mimetype, id, fileName) => {
    if (mimetype && mimetype.split("/")[0] === "image") {
      axiosCommon.axiosGET(urls.S3_ATTACHMENTS + id, (res) => {
        if (res.type === "SUCCESS") {
          this.setState({
            viewImage: true,
            attachmentId: id,
            downloadUrl: res.responseDto.data.downloadUrl,
          });
        }
      });
    } else if (mimetype && mimetype.split("/")[1] === "pdf") {
      this.viewAttachment(id, fileName);
    }
  };

  handleDeleteAttachmentConfirmation = (attachmentId, fileName, fileSize) => {
    this.setState({
      deleteConfirm: true,
      alertMessageTitle: "Delete attachment",
      attachId: attachmentId,
      fileName: fileName,
      fileSize: fileSize,
    });
  };

  handleDeleteAttachment = () => {
    this.setState({ deleteWait: true });

    this.props.onDeleteAttachment(
      this.state.attachId,
      this.state.artifactId,
      this.props.attachmentsCompleteDigest,
      (res) => {
        const fileName = this.state.fileName;
        const attachId = this.state.attachId;
        if (res) {
          deleteAttachmentOcr({
            fileName: fileName,
            attachmentId: attachId.toString(),
            attachmentCategory: this.props.attachmentCategory,
            userId: this.props.user.id.toString(),
          });
          this.setState({
            deleteConfirm: false,
            alertMessageTitle: "",
            attachId: null,
            fileName: "",
            deleteWait: false,
          });
        }
      },
      this.state.fileSize
    );
  };

  downloadAttachment = (id, fileName) => {
    this.setState({ downloadWait: true, attachmentId: id });
    const callBack = (response) => {
      if (response.type === "SUCCESS") {
        const token = response.responseDto.data.authToken;
        const url = urls.IMG_DOC_BY_ID + id + "&auth=" + token;
        if (Platform.OS === WEB) {
          const header = {
            url,
            method: "GET",
            responseType: "blob", // important
          };
          axiosCommon.axiosDownload(header, fileName, () => {
            this.setState({ downloadWait: false, attachmentId: null });
          });
        } else {
          axiosCommon.actualDownload(fileName, url, () => {
            this.setState({ 
              downloadWait: false, 
              attachmentId: null 
            });
          });
        }
      }
    };
    if (id) {
      axiosCommon.axiosGET(urls.IMG_GET_DOWNLOAD_TOKEN + id, callBack);
    }
  };

  viewAttachment = (id, fileName) => {
    const callBack = (response) => {
      if (response.type === "SUCCESS") {
        const downloadUrl = response.responseDto.data.downloadUrl;
        if (Platform.OS !== ANDROID) {
          axiosCommon.axiosViewFile(downloadUrl);
        } else {
          const token = response.responseDto.data.authToken;
          const url = urls.IMG_DOC_BY_ID + id + "&auth=" + token;
          axiosCommon.actualDownload(fileName, url, () => {}, true);
        }
      }
    };

    if (id) {
      axiosCommon.axiosGET(urls.IMG_GET_DOWNLOAD_TOKEN + id, callBack);
    }
  };
  componentDidMount = () => {
    this.setState({
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
    });
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {attachmentCategory} = this.props;
    const attachmentCategoryFromMain =
      this.props.attachmentCategory === 'Contact' ||
      attachmentCategory === 'Contact-view' ||
      attachmentCategory === 'Therapy-view' ||
      attachmentCategory === 'Therapy' ||
      attachmentCategory === 'Journal' ||
      attachmentCategory === 'Journal-view';
    return (
      <View style={styles.gridRow}>
        <View style={styles.gridColFull}>
          <View style={[stylesRes.tabContentFullArea]}>
            <View
              // style={[
              //   attachmentCategoryFromMain
              //     ? stylesRes.tabContent
              //     : stylesRes.tabContentMaxArea,
              //   stylesRes.botPd,
              // ]}
              // dataSet={attachmentCategoryFromMain ? {media: ids.botPd} : {media: ids.tabContentMaxArea}}
            >
              <View
                style={[
                  this.props.attachmentSpaceZero
                    ? styles.sortingFullCom
                    : styles.sortingFullBlock,
                ]}
              >
                {Boolean(this.props.fetchAttachmentSpinner) ? (
                  <View
                    style={
                      attachmentCategoryFromMain
                        ? styles.attachmentIndicatorCenter
                        : styles.attachmentIndicatorLeft
                    }
                  >
                    <View
                      style={[
                        attachmentCategoryFromMain
                          ? styles.attachmentIndicatorCom
                          : styles.attachmentIndicator,
                      ]}
                    >
                      <ActivityIndicator />
                    </View>
                  </View>
                ) : (
                  <View
                    style={
                      attachmentCategoryFromMain
                        ? styles.detailViewBlockNoPd
                        : styles.detailViewBlock
                    }
                  >
                    {this.props.userAttachmentsSelected ? (
                      this.props.userAttachmentsSelected.length !== 0 ? (
                        <React.Fragment>
                          {this.state.userAttachmentsSelected.map((res) => (
                            <Attachment
                              key={"attachment" + res.id}
                              id={res.id}
                              name={res.filename}
                              image={res.iconUrl}
                              mimetype={res.mimetype}
                              handleDeleteAttachment={() => {
                                this.handleDeleteAttachmentConfirmation(
                                  res.id,
                                  res.filename,
                                  res.filesize
                                );
                              }}
                              attachmentId={this.state.attachmentId}
                              handlePopupAttachment={
                                this.handlePopupAttachmentConfirmation
                              }
                              downloadWait={(this, this.state.downloadWait)}
                              downloadAttachment={this.downloadAttachment}
                              disabled={res.status !== "ENABLED"}
                            />
                          ))}

                            {Boolean(this.state.attachmentId) ? (
                            <AttachmentImageBox
                              visibleModal={this.state.viewImage}
                              close={() => {
                                this.setState({
                                  viewImage: false,
                                  attachmentId: null,
                                });
                              }}
                              onBackdropPress={() => {
                                this.setState({
                                  viewImage: false,
                                  attachmentId: null,
                                });
                              }}
                              attachmentId={this.state.attachmentId}
                              downloadUrl={this.state.downloadUrl}
                              logout={this.props.logout}
                            />
                            ) : null}
                          <ConfirmationBox
                            visibleModal={this.state.deleteConfirm}
                            onBackdropPress={() => {
                              this.setState({ deleteConfirm: false });
                            }}
                            onRequestClose={() => {
                              this.setState({ deleteConfirm: false });
                            }}
                            alertTitle={this.state.alertMessageTitle}
                            handleDelete={this.handleDeleteAttachment}
                            deleteWait={this.state.deleteWait}
                          />
                        </React.Fragment>
                      ) : null
                    ) : null}
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
    user: state.routines.user,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    newTherapie: state.therapie.newTherapie,
    selectedTherapie: state.therapie.selectedTherapie,
    fetchAttachmentSpinner: state.routines.journalAttachmentSpinner,
    userAttachmentsSelected: state.routines.userAttachmentsSelected,
    newJournal: state.routineTemplate.newJournal,
    journalEntrySelected: state.routines.journalEntrySelected,
    selectedContact: state.contacts.selectedContact,
    newContact: state.contacts.newContact
      ? state.contacts.newContact.data
      : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAttachment: (
      attachmentId,
      artifactId,
      attachmentsCompleteDigest,
      callBack,
      fileSize
    ) =>
      dispatch(
        actions.deleteAttachment(
          attachmentId,
          artifactId,
          attachmentsCompleteDigest,
          callBack,
          fileSize
        )
      ),
    logout: () => dispatch(actions.logout()),
    fetchUserAttachments: (artifactId) =>
      dispatch(actions.fetchUserAttachments(artifactId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
