import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import moment from 'moment';
import TherapieRow from './TherapieRow';
import {verifyDuplicateMedication} from '../../../util/commonUiLogic';
import {itemTypes} from '../../../Constant/index';

class StepTwoTreatment extends Component {
  constructor(props) {
    super(props);
    const {treatment} = props;
    const {id, dosages, prescribedBy, type} = treatment;
    let healthProviderName = "";
    if (prescribedBy && props.contactsProcessed[prescribedBy]) {
      const {givenName, organization} = props.contactsProcessed[prescribedBy];
      healthProviderName = givenName ? givenName : organization;
    }
    this.state = {
      ...treatment,
      dosages: {...dosages},
      visibleDetailView: id === -1 ? true : false,
      startDateClick: false,
      endDateClick: false,
      saveWait: false,
      validateName: true,
      contentVisibility: false,
      duplicatePopup: false,
      saveDisable: false,
      healthProviderName,
      type: type
    };
  }

  componentDidMount = () => {
    const {treatment} = this.props;
    const {dosages, name, identificationNumber, id} = treatment;
    if (id === -1) {
    this.verifyDuplicateMedication(
      name,
      identificationNumber,
      dosages && dosages.units,
      dosages && dosages.quantity,
    );
    }
  };

  handleShowDetailView = () => {
    this.setState(prevState => ({
      visibleDetailView: !prevState.visibleDetailView,
    }));
  };

  onUnitValueChange = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.dosages.units = val;
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {
      const {name, identificationNumber, dosages, id} = this.state;
      const {quantity} = dosages;
      if (id === -1) this.verifyDuplicateMedication(name, identificationNumber, val, quantity);
    });
  };

  onNameValueChange = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.name = val;
      this.setState({name : val});
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {
      const {dosages, id} = this.state;
      const {quantity, units} = dosages;
      if (id === -1) this.verifyDuplicateMedication(val, null, units, quantity);
    });
  };

  updateQuantity = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.dosages.quantity = val;
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {
      const {name, identificationNumber, dosages, id} = this.state;
      const {units} = dosages;
      if (id === -1) this.verifyDuplicateMedication(name, identificationNumber, units, val);
    });
  };

  updateFrequency = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.dosages.frequency = val;
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {});
  };

  updateFrequencyType = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.dosages.frequencyType = val;
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {});
  };

  handleOnPressCollapsible = val => {
    this.setState({contentVisibility: !val});
  };

  updateInstructions = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.dosages.instructions = val;
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {});
  };

  updatePrescribedBy = (id, name) => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.prescribedBy = id;
      item.healthProviderName = name;
      this.setState({...item});
    }
    this.props.updateTreatment(tempTreatments, () => {});
  };
  updateStartDate = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.dosages.start = val;
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {});
  };
  updateEndDate = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.dosages.end = val;
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {});
  };

  onPressUpdateTherapie = () => {
    const tempTreatments = [...this.props.treatments];
    let treatment = tempTreatments[this.props.index];
    const {dosages} = treatment;

    const {start, end} = dosages;
    const addNewTherapie = {
      treatment: {
        ...treatment,
        dosages: [
          {
            ...dosages,
            start: start ? moment.utc(start).valueOf() : null,
            end: end ? moment.utc(end).valueOf() : null,
          },
        ],
        displayProperties: {
          id: null,
          createdOn: null,
          modifiedOn: null,
          color: null,
          artifactId: null,
          dashedLine: true,
        },
      },
    };

    this.setState({saveWait: true});
    if (
      !addNewTherapie.treatment.prescribedBy &&
      addNewTherapie.treatment.healthProviderName
    ) {
      this.addContactAndUpdateTherapie(
        addNewTherapie,
        addNewTherapie.treatment.healthProviderName,
      );
    } else {
      this.updateTherapie(addNewTherapie);
    }
  };

  addContactAndUpdateTherapie = (
    addNewTherapie,
    healthProviderName,
  ) => {
    let addNewContact = {
      addNewContact: {
        contact: {
          id: -1,
          givenName: healthProviderName,
          isCareTeam: true,
        },
      },
    };
    this.props.updateContact(
      addNewContact,
      this.props.contacts,
      (success, contactData) => {
        if (success) {
          addNewTherapie.treatment.prescribedBy = contactData.id;
        }

        this.updateTherapie(addNewTherapie);
      },
      this.props.journalUserId,
      false, //stop calling fetch attachment api
    );
  };

  updateTherapie = (addNewTherapie) => {
    this.props.updateTherapie(
      addNewTherapie,
      (success, data) => {
        this.setState({saveWait: false, visibleDetailView: false});
        if (success) {
          const obj = {
            ...data,
            dosages: {
              ...data.dosages[0],
            },
            healthProviderName: this.state.healthProviderName,
            unitsList: this.state.unitsList,
            isConfirm: false,
          };
          const temp = [...this.props.treatments];
          temp[this.props.index] = obj;
          this.props.updateTreatment(temp, () => {});
        } else {
        }
      },
      this.props.journalUserId,
    );
  };

  verifyDuplicateMedication = (name, din_npn, unit, quantity) => {
    const {listTherapie, routineWizardSaveDisable} = this.props;
    verifyDuplicateMedication(name, din_npn, unit, quantity, listTherapie, (exist, msg) => {
      if (exist) {
        this.setState({
          duplicatePopup: true,
          saveDisable: true,
          errorDescription: msg,
        }, () => {
          this.updateDuplicateExistOrNot(true);
          routineWizardSaveDisable(true, itemTypes.treatment)

        });
      } else {
        this.setState({
          duplicatePopup: false,
          saveDisable: name?.trim() === '' ? true : false,
          errorDescription: null,
        }, () => {
          this.updateDuplicateExistOrNot(false);
        });
      }
    });
  };

  updateDuplicateExistOrNot = (val) => {
    const {routineWizardSaveDisable, updateTreatment, treatments, index} = this.props;

    let tempTreatments = [];
    if (treatments) {
      tempTreatments = [...treatments];
      let item = tempTreatments[index];

      item.duplicate = val;
    }
    updateTreatment(tempTreatments, () => {
      let invalidName = treatments.some(treatment => {
        return treatment.id == -1 && (treatment?.name?.trim() === '' || treatment?.duplicate)
      })
      routineWizardSaveDisable(invalidName, itemTypes.treatment);
    });
  }


  render() {
    const {
      name,
      validateName,
      visibleDetailView,
      dosages,
      contentVisibility,
      prescribedBy,
      healthProviderName,
      unitsList,
      identificationNumber,
      saveWait,
      duplicatePopup,
      errorDescription,
      saveDisable,
      type,
    } = this.state;
    const {
      units,
      quantity,
      frequency,
      frequencyType,
      instructions,
      start,
      end,
    } = dosages;
    return (
      <TherapieRow
        visibleDetailView={visibleDetailView}
        name={name}
        identificationNumber={identificationNumber}
        validateName={validateName}
        unit={units}
        onUnitValueChange={this.onUnitValueChange}
        quantity={quantity}
        updateQuantity={this.updateQuantity}
        frequency={frequency}
        frequencyType={frequencyType}
        updateFrequency={this.updateFrequency}
        updateFrequencyType={this.updateFrequencyType}
        contentVisibility={contentVisibility}
        instructions={instructions}
        updateInstructions={this.updateInstructions}
        prescribedBy={prescribedBy}
        healthProviderName={healthProviderName}
        updatePrescribedBy={this.updatePrescribedBy}
        startDate={start}
        endDate={end}
        updateStartDate={this.updateStartDate}
        updateEndDate={this.updateEndDate}
        unitsList={unitsList}
        handleOnPressCollapsible={this.handleOnPressCollapsible}
        index={this.props.index}
        handleOnDeleteTreatment={this.props.handleOnDeleteTreatment}
        key={this.props.key}
        showDetail={this.handleShowDetailView}
        update={this.onPressUpdateTherapie}
        saveWait={saveWait}
        duplicatePopup={duplicatePopup}
        errorDescription={errorDescription}
        saveDisable={saveDisable}
        onNameValueChange={this.onNameValueChange}
        type={type}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    treatments: state.setup.treatments,
    contacts: state.routines.contacts,
    journalUserId: state.uistate.journalUserId,
    listTherapie: state.routines.treatments,
    contactsProcessed: state.routines.contactsProcessed,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateTreatment: (addNewTreatment, callBack) =>
      dispatch(actions.updateTreatment(addNewTreatment, callBack)),
    updateTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.updateTherapie(addNewTherapie, callBack, journalUserId)),
    updateContact: (addNewContact, contacts, callBack, fetchAttachments) =>
      dispatch(actions.updateContact(addNewContact, contacts, callBack, fetchAttachments)),

    displayProperties: (displayPrope, journalUserId) =>
      dispatch(
        actions.updateGraphDisplayProperties(displayPrope, journalUserId),
      ),
    onDeleteTherapie: (id, callBack, journalUserId) =>
      dispatch(actions.deleteTherapie(id, callBack, journalUserId)),
    routineWizardSaveDisable: (flag, isFrom ) => dispatch(actions.updateRoutineWizardSaveDisable(flag, isFrom))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepTwoTreatment);
