import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';

const backCancelSection = props => {
  const {handleOnPressButton, isCancel, nativeId} = props;
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  return (
    <TouchableOpacity
      onPress={handleOnPressButton}
      style={[
        stylesRes.headeBackSec, stylesRes.headerIconArrowPd
        ]}>
      <View 
        nativeID={nativeId}
        style={[stylesRes.backSecWebView]}>
        <Image
          style={styles.headerBackIcon}
          source={require('../../../assets/icons/header-back-button.png')}
        />
        {Boolean(isCancel) &&
          <View style={styles.backTextSec}>
            <Text style={[styles.textPrimeSm, styles.headerTextCom]} allowFontScaling={false}>Cancel</Text>
          </View>
        }
      </View>
    </TouchableOpacity>
  );
};

export default (backCancelSection);
