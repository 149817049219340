import React from "react";
import { Text, View } from "react-native";
import styles from "../../../Css";
import Modal from "../../Modal/modal";
import ModalLayoutSpec from "../../ModalLayout/modalLayoutSpec";
import PrimaryButton from '../../Button/primaryButton';

const disconnectPopup = props => {
    const {
        visibleModal,
        hidePopup,
        selectedUser,
        wait,
        onBlock
    } = props;
    return (
      <Modal
        visible={visibleModal}
        onBackdropPress={() => hidePopup('BLOCK_POPUP')}
      >
        <ModalLayoutSpec
          contPosition={"center"}
          showClose={true}
          hidePopup={() => hidePopup('BLOCK_POPUP')}
        >
          <View style={styles.popupContentWrapper}>
            <View style={[styles.popMessageContView, styles.comSp]}>
                <View style={[styles.popMessageTitleView, styles.comSp]}>
                    <Text style={[styles.textTitle, styles.textAlignCenter]} allowFontScaling= {false}>
                        Block <Text style={styles.bold} allowFontScaling= {false}>{selectedUser && selectedUser?.userName}</Text>
                    </Text>
                </View>
                <View style={[styles.popMessageTitleView, styles.comSpExSm]}>
                    <Text style={[styles.textPrime, styles.textAlignCenter]} allowFontScaling= {false}>
                        You will be disconnected and you will not show up in {selectedUser && selectedUser?.userName}’s search.
                    </Text>
                </View>
            </View>
            <View style={[styles.popBtnView, styles.comMgTopSm]}>
              <View style={[styles.btnArea, styles.flexRow, styles.flexJcCtAiCt]}>
                <View style={styles.fieldBtn}>
                  <PrimaryButton
                    btnText={"Block"}
                    btnPosition={"full"}
                    touchableOnPress={onBlock}
                    activityInd={wait}
                    disabled={wait}
                    btnStyle={[
                      styles.submitBgOrange,
                      styles.primaryButtonLgPd,
                      wait
                        ? styles.btnBlurState
                        : styles.btnActiveState,
                    ]}
                  />
                </View>
              </View>
            </View>
          </View>
        </ModalLayoutSpec>
      </Modal>
    );
};
export default (disconnectPopup);
