import React from "react";
import {
  View,
  ScrollView,
  Text,
  Switch,
  TouchableOpacity,
  Image,
} from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import Header from "../../components/Header/header";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import SpaceBar from "../../components/SpaceBar/SpaceBar";
import PageContainer from "../../components/PageLayout/PageContainer";

const measurements = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={true}
        handleOnPressBackButton={props.handleOnPressBackButton}
        index={props.index}
        navigation={props.navigation}
        route={props.route}
      />
      <SpaceBar />
      <ScrollView style={styles.scrollViewArea}>
        <View style={styles.gridSection}>
          <PageContainer>
            <View style={[stylesRes.contentSection, stylesRes.contBotSmPdFixed]}>
              <View style={[styles.gridRow, styles.colPdTopCom]}>
                <View style={styles.gridColFull}>
                  <View style={styles.secArea}>
                    <View style={[styles.colPdTopCom]}>
                      <View style={stylesRes.maxComSecContent} dataSet={{media: ids.maxComSecContent}}>
                        <View
                          style={[
                            styles.comTextSec,
                            styles.comSpLg,
                            styles.flexRowJcFsAiFs,
                          ]}
                        >
                          <Text
                            style={[styles.textPrimeSm, styles.textAlignCenter]}
                            allowFontScaling= {false}
                          >
                            The International System of Units (SI) is the modern
                            form of the metric system, and is the most widely
                            used system of measurement.
                          </Text>
                          <TouchableOpacity
                            style={[styles.toolTipIconLink, styles.toolTipMgMd]}
                            onPress={() => props.onShowTutorial(5)}
                          >
                            <Image
                              source={require("../../assets/icons/question.png")}
                              style={styles.questionIcon}
                              resizeMode={"contain"}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.secArea}>
                    <View style={[styles.colPdTopCom]}>
                      <View style={stylesRes.maxComSec} dataSet={{media: ids.maxComSec}}>
                        <View style={[styles.comToggleArea, styles.comSpLg]}>
                          <View
                            style={[
                              styles.toggleBtnContent,
                              styles.flexRowJcCtAiCt,
                            ]}
                          >
                            <View
                              style={[
                                styles.toggleBtnSmText,
                                styles.toggleTextPdSm,
                              ]}
                            >
                              <Text
                                style={[
                                  styles.textPrime,
                                  styles.textAlignRight,
                                ]}
                                allowFontScaling= {false}
                              >
                                (Metric)
                              </Text>
                            </View>
                            <View style={[styles.toggleBtnLgText]}>
                              <Text
                                style={[
                                  styles.textSpecBold,
                                  styles.textAlignRight,
                                ]}
                                allowFontScaling= {false}
                              >
                                SI
                              </Text>
                            </View>
                          </View>
                          <View style={[styles.toggleBtnCenter]}>
                            <View style={stylesRes.toggleBtnFull} dataSet={{media: ids.toggleBtnFull}}>
                              <Switch
                                style={{
                                  transform: [{ scaleX: 1.5 }, { scaleY: 1.5 }],
                                }}
                                onValueChange={props.toggleSwitch}
                                value={props.active}
                              />
                              {props.activeWait && (
                                <View style={styles.actIndTop}>
                                  <ActivityIndicator size="small" />
                                </View>
                              )}
                            </View>
                          </View>
                          <View
                            style={[
                              styles.toggleBtnContent,
                              styles.flexRowJcCtAiCt,
                            ]}
                          >
                            <View style={[styles.toggleBtnLgText]}>
                              <Text
                                style={[
                                  styles.textSpecBold,
                                  styles.textAlignLeft,
                                ]}
                                allowFontScaling= {false}
                              >
                                US
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.toggleBtnSmText,
                                styles.toggleTextPdSm,
                              ]}
                            >
                              <Text
                                style={[styles.textPrime, styles.textAlignLeft]}
                                allowFontScaling= {false}
                              >
                                (Imperial)
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.secArea}>
                    <View style={[styles.spaceBarLg, styles.borderThin]} />
                  </View>
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.secArea}>
                    <View style={stylesRes.maxComSec} dataSet={{media: ids.maxComSec}}>
                      <View style={[styles.comTextSec, styles.comSpLg]}>
                        <Text
                          style={[styles.textPrimeSm, styles.textAlignCenter]}
                          allowFontScaling= {false}
                        >
                          The SI system is the standard except USA. Imperial
                          measurements for weight and height are included in
                          your journal for convenience.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </PageContainer>
        </View>
      </ScrollView>
    </View>
  );
};
export default (measurements);
