import { Platform } from "react-native";
import * as variable from "../css/base/variable";
import theme from "./base/theme";
import * as variableShadow from './base/variableShadow';
export const cssPeerToPeer = {
    btnMaxWrapper: {
        width: "100%",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 20,
        maxWidth: 440,
        ...variable.marginLtRtAuto,
      },
      pageBotPd: {
        paddingBottom: 100,
      },
      findIcon: {
          width: 330,
          height: 250,
          resizeMode: "contain",
      },
      itemColImg: {
        flexBasis: 50,
        maxWidth: 50,
      },
      itemColImgSp: {
        flexBasis: 60,
        maxWidth: 60,
      },
      itemColStretch: {
        flex: 1
      },
      itemColSp: {
        width: 10,
      },
      cardItemSingle: {
        marginBottom: 15,
      },
      moreInfoIconWrapper: {
        paddingLeft: 5,
      },
      moreInfoIconArrow: {
        width: 8,
        height: 5,
        resizeMode: "contain",
      },
      cardItemMore: {
        paddingTop: 5,
      },
      itemColBorder: {
        flex: 1,
        marginTop: 15,
      },
      textlinkText: {
        color: "#3F76E1"
      },
      networkIcon: {
        width: 250,
        height: 105,
        resizeMode: "contain",
    },
    peerConnectIcon: {
      width: 123,
      height: 123,
      resizeMode: "contain",
    },
    peerListRow: {
      flexDirection: 'row',
      paddingTop: 6,
      paddingBottom: 6,
    },
    peerListIcon: {
      width: 21,
      height: 21,
      resizeMode: "contain", 
    },
    profileImgWrapper: {
      flexBasis: 110,
      maxWidth: 110,
      paddingRight: 20,
    },
    profileUserWrapper: {
      flex: 1
    },
    peerProfileImg: {
      width: 85,
      height: 85,
      borderRadius: 85,
      overflow: "hidden"
    },
    findIconSmall: {
      width: 280,
      height: 210,
      resizeMode: "contain",
  },
    peerBtnArea: {
      minHeight: 60,
    },
    selectionRadioWrapper: {
      paddingTop: 10
    },
    profilePeerIcon: {
      width: 85,
      height: 85,
    },
    warningCircle: {
      width: 15,
      height: 15,
    },
    editIconWrapper: {
      width: 20,
      height: 20,
    },
    pageBotExPd: {
      paddingBottom: 240,
    },
    comBtnWrapper: {
      width: "100%",
      flexDirection: "row",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      paddingBottom: 20,
      maxWidth: 375,
      ...variable.marginLtRtAuto,
    },
    peerDisclaimerTop: {
      position: "absolute",
      right: 0,
      top: 0
    },
    peerConnectIconWrapper: {
      paddingTop: 10,
    },
    peerDisclaimerContent: {
      width: "100%",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      paddingBottom: 20,
      maxWidth: 400,
      ...variable.marginLtRtAuto,
    },
    peerDisclaimerZindex: {
      zIndex: 999,
    },
    disclaimerPositionTop: {
      justifyContent: 'flex-start',
      paddingTop: 35
    },
    disclaimerPositionCenter: {
      justifyContent: 'center',
    },
    itemColBtn: {
      flexBasis: 80,
      maxWidth: 80,
    },
    itemColBtnSp: {
      width: 10
    },
    connectBtn: {
      backgroundColor: theme.COLOR_BLUE,
    },
    declineBtn: {
      backgroundColor: theme.COLOR_DISABLE_BUTTON,
    },
    btnTexGrey: {
      color: '#7E7E7E'
    },
    btnTextWhite: {
      color: theme.COLOR_WHITE
    },
    cardSettingMenu: {
      minHeight: 50,
      minWidth: 50,
    },
    cardSettingPd: {
      paddingTop: 15,
      paddingRight: 15,
      paddingBottom: 15,
      paddingLeft: 15,
    },
    statusBtnCom: {
      minHeight: 42,
    },
    itemColBtnSm: {
      flexBasis: 70,
      maxWidth: 70,
    },
    fieldColText: {
      flexBasis: 30,
      maxWidth: 30,
    },
    fieldColInput: {
      flexBasis: 110,
      maxWidth: 110,
    },
    btnSubmitloaderCom: {
      left: 10
    },
    darkTickIcon: {
      width: 15,
      height: 13,
    },
    infoTextCom: {
      paddingRight: 8,
    },
    tickCircle: {
      width: 63,
      height: 63,
      tintColor: "#5ec778"
    },
    closeDarkCircle: {
      width: 35,
      height: 35,
    },
    popUpContentExPdAll: {
      paddingTop: 40,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
    primaryButtonLgPd: {
      paddingTop: 12,
      paddingBottom: 12
    },
    tickIconViewWrapper: {
      flexBasis: 50,
      maxWidth: 50,
    },
    tickIconViewWrapperLg: {
      flexBasis: 70,
      maxWidth: 70,
      paddingRight: 10,
    },
    crossCancelIcon: {
      width: 15,
      height: 15,     
    },
    crossIconWrapper: {
      paddingRight: 10
    },
    submitBgAshDark: {
      backgroundColor: "#A1A1A1"
    },
    profileAvatarText: {
      color: '#FF9E38'
    },
    fieldWrapperBg: {
      backgroundColor: "#F6F6F6"
    },
    chatSendMainWrapper: {
      width: 32, 
      height: 32
    },
    chatSendIconWrapper: {
      borderRadius: 32, 
      backgroundColor: "#33ADB9", 
      width: 32, 
      height: 32, 
      position: "absolute", 
      left: 0, 
      right: 0, 
      alignItems: "center", 
      justifyContent: "center"
    },
    chatSendIcon: {
      width: 18, 
      height: 16
    },
    itemBtnTextDefault: {
      color: "#5B5B5B"
    },
    itemBtnTextActive: {
      color: "#FFFFFF"
    },
    itemBtnDefault: {
      backgroundColor: "#F1F1F1"
    },
    itemBtnActive: {
      backgroundColor: "#FF9E38"
    },
    itemListBtn: {
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
      borderRadius: 87,
      marginLeft: 5,
      marginRight: 5,
      marginTop: 5,
      marginBottom: 5,
    },
    btnSpecPd: {
      paddingLeft: 5,
      paddingRight: 5,
    },
    peerProfileCircle: {
      backgroundColor: "rgb(255, 228, 200)",
      width: 85,
      height: 85,
      borderRadius: 85,
    },
    peerProfileTextWrapper: {
      position: "absolute", 
      alignItems: "center", 
      justifyContent: "center",
      width: 85,
      height: 85,
      top: 0
    },
    onlineWrapper: {
      position: "absolute", 
      width: 12,
      height: 12,
      bottom: 2,
      alignSelf: 'flex-end',
      right: 8,
    },
    onlineWrapperIcon: {
      backgroundColor: "#2AD062",
      width: 12,
      height: 12,
      borderRadius: 12,
      borderColor: theme.COLOR_WHITE,
      borderWidth: 2,
    },
    unReadMessageWrapper: {
      position: "absolute", 
      width: 8,
      height: 8,
      justifyContent: 'flex-start',
      left: -15,
      top: 15
    },
    unReadMessageWrapperIcon: {
      backgroundColor: "#FF9E38",
      width: 8,
      height: 8,
      borderRadius: 8,
    },
    answerSinglePd: {
      paddingBottom: 6
    },
    btnMaxWrapperLg: {
      width: "100%",
      flexDirection: "row",
      paddingTop: 20,
      paddingBottom: 20,
      maxWidth: 450,
      ...variable.marginLtRtAuto,
    },
    fieldBtnMiniSpec: {
      flex: 0.7,
    },
    indicatorLtPeers: {
      left: -10,
    },
    submitBtnArrow: {
      width: 25,
      height: 23,
    },
    resultsHeader: {
      minHeight: 100,
      position: 'relative',
    },
    peerTopCom: {
      bottom: 0,
      position: "absolute",
      right: 0,
    },
    commonTopIconView: {
      width: 154,
      height: 100,
    },
    commonBotIconView: {
      width: 137,
      height: 100,
    },
    resultsTitleHeader: {
      paddingTop: 30,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
      zIndex: 999,
    },
    resultsTitleResMd: {
      width: 500,
      ...variable.marginLtRtAuto,
    },
    resultsTitleRes: {
      width: 330,
      ...variable.marginLtRtAuto,
    },
    resultsTitleResSpec: {
      width: 280,
      ...variable.marginLtRtAuto,
    },
    comBtnWrapperSm: {
      maxWidth: 240,
    },
    resultsTitleSubHeader: {
      paddingBottom: 20,
    },
    singleItemWrapper: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: "100%",
      paddingTop: 20,
      paddingBottom: 20,
      maxWidth: 860,
    },
    singleItemWrapperMax: {
      width: "50%",
    },
    singleItemWrapperFull: {
      width: "100%",
    },
    singleItemViewWrapper: {
      width: "100%",
      paddingTop: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 15,
      borderWidth: 1,
      borderColor: theme.COLOR_GREY_LIGHT_RG,
      borderRadius: 8,
      zIndex: 9,
      ...variableShadow.itemShadow,
    },
    singleItemViewMin: {
      minHeight: 340
    },
    singleItemPd: {
      paddingTop: 0,
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 20,
    },
    singleItemPdSm: {
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 20,
    },
    btnResult: {
      minWidth: 200
    },
    loaderLt: {
      right: 0,
    },
    referFriendIcon: {
      width: 155,
      height: 155,
    },
    itemListBlock: {
      maxWidth: 200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    itemRowSingle: {
      paddingBottom: 3,
    },
    titleBlockPdBotSpec: {
      paddingBottom: 60,
      paddingTop: 50,
    },
    itemListBlockLg: {
      minHeight: 165,
    },
    itemColSettingBtnLg: {
      flexBasis: 50,
      maxWidth: 50,
    },
    submitBtnTouch: {
      width: 50,
      height: 50,
    },
    itemColBtnMd: {
      flexBasis: 50,
      maxWidth: 50,
    },
    lastMessageUnRead: {
      color: theme.PRIMARY_FONT_COLOR,
      fontWeight: '500'
    },
    conditionTitleTxt: {
      color:theme.TERTIARY_FONT_COLOR,
      fontSize: 14,
      fontWeight: '600',
      ...Platform.select({
        android: {
          fontWeight: 'bold'
        },
      })
    },
    cardItemContentAlign: {
      marginLeft: 50
    },
    cardItemContentCr: {
      height: 'auto', 
      paddingTop: 12, 
      paddingLeft:10, 
      // paddingBottom:25
    },
    cardItemConditionTag:{
      backgroundColor:theme.COLOR_GREY_LIGHT_RG, 
      borderRadius: 8, 
      paddingLeft: 5, 
      paddingRight: 5, 
      paddingTop:3, 
      paddingBottom: 5,
      marginRight:12,
      marginBottom: 2,
      marginTop: 2,
    },
    cardItemConditionTags: {
      maxWidth: 1500, 
      flexWrap: 'wrap'
    },
    cardItemConditionTagTxt:{
      fontWeight: '500', 
      color:theme.COLOR_BLACK,
      ...Platform.select({
        android: {
          fontWeight: 'bold'
        },
      })
    },
    profileText:{
      ...variable.fontSecondary,
    fontSize: theme.FONT_SIZE_X_LG,
    fontWeight:theme.FONT_WEIGHT_MEDIUM,
    marginRight:126,
    },
    customPaddingProfile:{
      marginTop:24,
    }
};
