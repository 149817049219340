import React, {Component} from 'react';
import {View} from 'react-native';
import styles from '../../../../Css';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import Collapsible from '../../../Collapsible/Collapsible';
import ItemDeleteButton from '../../../Button/itemDeleteButton';
import PlusButtonCom from '../../../Button/plusButtonCom';
import HealthDataAutoComplete from '../../../AutoComplete/CommonAutoComplete/Metric';
import SystemItem from './SystemItem';
import UserItem from './UserItem';
import UserItemView from './UserItemView';
import { chartTypes } from '../../../../Constant/index';
import {getSortedMetricsWithSymptomObject} from "../../../../store/actions/index";

class HealthData extends Component {
  constructor(props) {
    super(props);
    const {metrics} = props;
    const sortedMetricsWithSymptomObject = getSortedMetricsWithSymptomObject(metrics, false, props.metricsDigestProcessed);
    this.state = {
      hasInvalidNames: false,
      collapsible: {
        healthData: sortedMetricsWithSymptomObject && sortedMetricsWithSymptomObject.length > 0,
      },
      autocompleteOpen: false,
      add: false,
      metrics: sortedMetricsWithSymptomObject,
      selectedValues: HealthData.getSelectedValues(sortedMetricsWithSymptomObject),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {newJournal, isCopyPrevious} = props;
    if (newJournal && isCopyPrevious && isCopyPrevious()) {
      const {metrics} = newJournal;
      const sortedMetricsWithSymptomObject = getSortedMetricsWithSymptomObject(metrics, false, props.metricsDigestProcessed);
      return {
        collapsible: {
          healthData: sortedMetricsWithSymptomObject && sortedMetricsWithSymptomObject.length > 0,
        },
        metrics: sortedMetricsWithSymptomObject,
        selectedValues: HealthData.getSelectedValues(sortedMetricsWithSymptomObject),
      };
    }

    return null;
  }

  static getSelectedValues = (metrics, props) => {
    const selectedValues = {};

    if (metrics) {
      metrics.forEach(element => {
        let obj = {};
        if (element.type === 'SYSTEM' || element.type === 'CUSTOM') {
          obj = element;
        } else {
          obj = element.metricRef;
        }
        if (obj && obj.name) {
          selectedValues[obj.name] = obj.name;
        }
      });
    }
    return selectedValues;
  };

  handleOnPressCollapsible = key => {
    const {collapsible} = this.state;
    let val = collapsible[key];
    collapsible[key] = !val;
    let add = false;

    this.setState({collapsible: collapsible, add: add});
  };

  updateAutocompleteOpen = val => {
    this.setState({autocompleteOpen: val});
  };

  onPressItem = (item, type) => {
    const {metrics, selectedValues} = this.state;
    const {
      updateHealthData,
      metricsDigest,
      metricsDigestProcessed,
    } = this.props;
    const {selectableMetrics} = metricsDigestProcessed;
    let existItem = true;
    this.setAdd();

    if (metrics && metrics.length > 0) {
      if (type === 'USER' || type === 'SYSTEM') {
        metrics.forEach(i => {
          if (i.metric === item.id) {
            existItem = false;
          }
        });
      } else {
        metrics
          .filter(m => m.id === -1)
          .forEach(i => {
            if (
              i.type === 'USER'
                ? i.metric && selectableMetrics[i.metric]?.name === item
                : i?.metricRef?.name === item
            ) {
              existItem = false;
            }
          });
      }
    }
    if (existItem) {
      if (type === 'USER') {
        const {
          userDefinedMetricUnitOfMeasures,
          systemDefinedMetricUnitOfMeasures,
        } = metricsDigest;
        let found = userDefinedMetricUnitOfMeasures.find(
          element => element.metricId === item.id,
        );
        if (!found) {
          found = systemDefinedMetricUnitOfMeasures.find(
            element => element.metricId === item.id,
          );
        }
        const obj = selectableMetrics[item.id];
        if (obj.name) selectedValues[obj.name] = obj.name;
        metrics.push({
          id: -1,
          metric: item.id,
          value: null,
          unitOfMeasure: found ? found.uomId : 0,
          type: type,
        });
      } else {
        let name;
        if (type === 'SYSTEM') {
          name = item.name;
        } else {
          name = item;
        }
        selectedValues[name] = name;
        metrics.push({
          name: name,
          description: '',
          typeOfMeasurement: item.typeOfMeasurement,
          metricGroup: item.metricGroup ? item.metricGroup : 1,
          displayProperties: {
            color: 'rgba(113, 168, 220, 1)',
            style: chartTypes.dash,
          },
          metricDisplayUnitOfMeasure: {
            uomId: null,
          },
          userMetricLog: {
            value: null,
            unitOfMeasure: null,
          },
          type: type,
        });
      }
      this.setState({metrics: metrics, selectedValues: selectedValues}, () =>
        updateHealthData(metrics),
      );
    }
  };

  setAdd = () => {
    const {add} = this.state;
    this.setState({add: !add});
  };

  getItem = (item, index) => {
    const {metricsDigestProcessed, type, windowWidth, healthdataErrors, removeHealthdataErrors, deviceJournal} = this.props;
    const {selectableMetrics} = metricsDigestProcessed;
    const {metrics, hasInvalidNames} = this.state;
    if (item.type === 'SYSTEM' || item.type === 'CUSTOM') {
      return (
        <SystemItem
          data={item}
          index={index}
          key={'ha_' + index}
          updateHealthData={this.updateHealthData}
          hasInvalidNames= {hasInvalidNames}
          deleteItem={this.deleteItem}
          category={type}
          error={healthdataErrors?.[index]}
          removeHealthdataErrors={removeHealthdataErrors}
          metricsCount={this.state.metrics.length}
          end={metrics && metrics.length > 0 ? metrics.length : 0}
        />
      );
    } else if (type === 'RT') {
      return (
        <UserItemView
          data={item}
          index={index}
          key={'ha_' + index}
          deleteItem={this.deleteItem}
        />
      );
    } else if (selectableMetrics[item.metric]) {
      return (
        selectableMetrics[item.metric] && (
          <UserItem
            data={item}
            index={index}
            key={'ha_' + index}
            deleteItem={this.deleteItem}
            updateHealthData={this.updateHealthData}
            windowWidth={windowWidth}
            deviceJournal={deviceJournal}
          />
        )
      );
    }

    return null;
  };

  updateHealthData = (index, data) => {
    const {metrics} = this.state;
    const {updateHealthData, isInvalidName} = this.props;
    metrics[index] = data;
    this.setState(
      {
        metrics: metrics,
      },
      () => updateHealthData(metrics),
    );
    let hasInvalidNames = this.checkInvalidName();
    isInvalidName(hasInvalidNames);
  };

  checkInvalidName = () => {
    console.log(this.state.metrics);
    let isInvalidName = this.state.metrics.some(metric => {
      return metric?.name?.trim() === '';
    });
    return isInvalidName;
  }

  deleteItem = index => {
    const {metrics} = this.state;
    const { updateHealthData, isInvalidName, metricsDigestProcessed } = this.props;
    delete metrics[index];
    // metrics.splice(index, 1);
    const sortedMetricsWithSymptomObject = getSortedMetricsWithSymptomObject(metrics, false, metricsDigestProcessed);
    this.setState(
      {
        metrics: metrics,
        selectedValues: HealthData.getSelectedValues(sortedMetricsWithSymptomObject, this.props),
      },
      () => updateHealthData(metrics),
    );
    if (this.props.healthdataErrors) {
      this.props.handleDeleteError(index);
    }

    let hasInvalidNames = this.checkInvalidName();
    isInvalidName(hasInvalidNames);
  };

  render() {
    const {add, metrics, selectedValues, collapsible} = this.state;
    const {plusButton, disabledScroll, type} = this.props;

    return (
      <Collapsible
        title={'Health Data'}
        contentVisibility={collapsible.healthData}
        handleOnPressCollapsible={() =>
          this.handleOnPressCollapsible('healthData')
        }
        style={[styles.collapseTitle]}
        customTitleStyle={true}
        plusButton={plusButton}
        topBorder={true}
        // toolTipButton={true}
        // toolTipId={'tool-tip-health-data'}
        // toolTipContent={''}
        nativeId={type === 'R' ? 'journalHealthData' : 'routineHealthData'}
      >
        <View style={[styles.collapseContent, styles.colPdTopLtRt, styles.zIndexAutoComplete,styles.switchMenu ]}>
          {/* Loop Item for Health Data - Start */}
          {metrics.map((item, index) => this.getItem(item, index))}

          {/* Loop Item for Health Data - End */}
          {!Boolean(add) && (
            <View style={[styles.btnArea, styles.btnAreaPd, styles.zIndexMin]}>
              <PlusButtonCom
                btnPosition={'left'}
                btnText={'Add'}
                touchableOnPress={this.setAdd}
                nativeId={type === 'R' ? 'journalAddHealthData' : 'routineAddHealthData'}
              />
            </View>
          )}
        </View>
        {Boolean(add) && (
          <View
            style={[
              styles.fieldRowCom,
              styles.fieldRowNoMg,
              styles.zIndexSmAutoComplete,
              styles.colPdLtRtBot,
            ]}>
            <View style={[styles.fieldRowComWrapper]}>
              <View style={[styles.fieldColStretch]}>
                <HealthDataAutoComplete
                  updateAutocompleteOpen={this.updateAutocompleteOpen}
                  onPressItem={this.onPressItem}
                  selectedValues={selectedValues}
                  disabledScroll={disabledScroll}
                />
              </View>
              <View style={styles.fieldColSp} />
              <View style={styles.fieldColDelete}>
                <ItemDeleteButton touchableOnPress={this.setAdd} />
              </View>
            </View>
          </View>
        )}
      </Collapsible>
    );
  }
}

const mapStateToProps = state => {
  return {
    newJournal: state.routineTemplate.newJournal,
    metricsDigest: state.routines.metricsDigest,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    isInvalidName: flag => dispatch(actions.hasEntryHealthDataInvalidNames(flag)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthData);
