export const BGMEDICATION = "#6E1C6B";
export const BGSUPPLEMENT = "#D13836";
export const BGSYMPTOM = "#CC8825";
export const BGHEALTHDATA = "#71A8DC";
export const BGACTIVITY = "#33895A";
export const BGCOLOR1 = "#d33c66";
export const BGCOLOR2 = "#6045AE";
export const BGCOLOR3 = "#4355AC";
export const BGCOLOR4 = "#5D7DF0";
export const BGCOLOR5 = "#4FA8EB";
export const BGCOLOR6 = "#57B9CF";
export const BGCOLOR7 = "#449388";
export const BGCOLOR8 = "#4D6D1F";
export const BGCOLOR9 = "#AC62D9";
export const BGCOLOR10 = "#EA6340";
export const BGCOLOR11 = "#FBA51A";
