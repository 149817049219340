import React, {Component} from 'react';
import { Provider } from "react-redux";
import { NavigationContainer } from '@react-navigation/native';
import { navigationRef, isReadyRef } from './navigation/RootNavigation';
import Route from "./navigation/RouteNew";
import configureStore from "./store/store";
import linking from './navigation/linking';
import { ORGANIZATION_NAME } from './util/commonUiConfig';

const store = configureStore();
class App extends Component {
  componentDidMount() {
    isReadyRef.current = true;
  }
  render() {
    return (
        <Provider store={store}>
          <NavigationContainer 
            documentTitle={{formatter: () => ORGANIZATION_NAME}} 
            linking={linking} 
            ref={navigationRef}
          >
            <Route />
          </NavigationContainer>
      </Provider>
    );
  }
}

export default App;