import React from "react";
import { View, Text } from "react-native";
import styles from "../../Css";

const accountPasswordSuccess = (props) => {
  return (
    <View style={[styles.popMessageContView, styles.comSpSm]}>
      <View style={styles.popMessageDescView}>
        <Text style={[styles.textPrimeLg, styles.textAlignCenter]} allowFontScaling={false}>
          <Text allowFontScaling={false}>{props?.title || ''}{'\n'}</Text>
          <Text style={styles.bold} allowFontScaling={false}>{props?.body || ''}</Text>
        </Text>
      </View>
    </View>
  );
};

export default (accountPasswordSuccess);
