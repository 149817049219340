import React, {Component} from 'react';
import {View, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import UIText from '../../components/Text/text';
import UITextInput from '../../components/TextInput/textInput';
import Header from '../../components/Header/header';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import MedicationsTherapiesItem from '../../components/Report/MedicationsTherapiesItem';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import PageContainer from "../../components/PageLayout/PageContainer";
import {REPORTS} from '../../navigation/path';
import { pendoTrackEvent } from '../../util/pendoConfig';

class ReportAddIMedicationsTherapiesScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recordedDosing: props.recordedDosing,
      filterDosings: this.filterDosing(props.recordedDosing, ''),
      addList: [],
      removeList: [],
      searchKeyWord: '',
    };
  }

  componentDidMount () {
    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
      pendoTrackEvent('Reports/@AddMedSupp+Act');
    });
    this.props.navigation.addListener('blur', () => {
      this.props.showMobileMenu();
    });
  }

  filterDosing = (dosing, searchKeyWord) => {
    let filterDosings = [];

    let startDate;
    if (this.props.startDate) {
      startDate = new Date(this.props.startDate.getTime());
      startDate.setHours(0, 0, 0, 0);
    }

    let endDate;

    if (this.props.endDate) {
      endDate = new Date(this.props.endDate.getTime());
      endDate.setHours(23, 59, 59, 59);
    }

    if (searchKeyWord) {
      filterDosings = dosing.filter(item => {
        const treatment = this.props.treatmentsProcessed[item.userTreatment];

        return (
          treatment &&
          treatment.name &&
          treatment.name.toLowerCase().includes(searchKeyWord.toLowerCase())
        );
      });
    } else {
      filterDosings = dosing;
    }

    if (startDate && endDate) {
      filterDosings = filterDosings.filter(item =>
        item.start && item.end
          ? (item.start >= startDate.getTime() &&
              item.start <= endDate.getTime()) ||
            (item.end >= startDate.getTime() && item.end <= endDate.getTime()) ||
            (item.start <= startDate.getTime() && item.end >= endDate.getTime())
        : item
      );
    }
    return filterDosings;
  };

  onSearchByKeyWord = val => {
    this.setState({
      searchKeyWord: val,
      filterDosings: this.filterDosing(this.state.recordedDosing, val),
    });
  };

  onSave = () => {
    this.props.saveReportMedicationsTherapie(
      this.state.addList,
      this.state.removeList,
    );
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: REPORTS.newReportPath }],
    });
  };

  cancelReport = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: REPORTS.newReportPath }],
    });
  };

  addRemoveMedicationsTherapie = (isAdd, value) => {
    if (isAdd) {
      const addList = [...this.state.addList];
      addList.push(value);
      this.setState({addList: addList});
    } else {
      const removeList = [...this.state.removeList];
      removeList.push(value);
      this.setState({removeList: removeList});
    }
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => {
            this.props.navigation.goBack();
          }}
          index={REPORTS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        <KeyboardAwareScrollViewHoc>
          <View style={[styles.gridSection]}>
          <PageContainer>
            <View style={stylesRes.layoutPdFixed}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.secArea}>
                        <View style={[styles.formInnerArea]}>
                          <View style={[styles.fieldRow]}>
                            <UITextInput
                              style={[
                                styles.textField,
                                styles.textFieldWhite,
                                styles.textFieldSm,
                              ]}
                              onChangeText={this.onSearchByKeyWord}
                              placeholder="Search entries"
                              value={this.state.searchKeyWord}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                </View>
              </View>
            </PageContainer>
            <PageContainer smScreenFull={true}>
              <View style={stylesRes.contBotPd} dataSet={{media: ids.contBotPd}}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.secArea]}>
                      {this.state.filterDosings.length > 0 && (
                        <View style={[styles.listItemsAll]}>
                          {this.state.filterDosings.map((res, index) => {
                            const treatment = this.props.treatmentsProcessed[
                              res.userTreatment
                            ];

                            return (
                              treatment &&
                              treatment.name !== null && (
                                <MedicationsTherapiesItem
                                  id={res.id}
                                  key={'ri_' + index}
                                  item={res}
                                  addRemoveMedicationsTherapie={
                                    this.addRemoveMedicationsTherapie
                                  }
                                  treatment={treatment}
                                />
                              )
                            );
                          })}
                        </View>
                      )}
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.btnArea, styles.btnMgSm]}>
                      <View style={stylesRes.btnViewSec}>
                        <View style={styles.fieldBtn}>
                          <View style={styles.btnBlockFull}>
                            <TouchableOpacity
                              style={[styles.submitSm, styles.disableBtn]}
                              underlayColor="rgba(0, 153, 168, 0.8)"
                              onPress={this.cancelReport}>
                              <View style={styles.btnSingleView}>
                                <View
                                  style={[
                                    styles.btnSingle,
                                    styles.btnSingleCent,
                                  ]}>
                                  <UIText
                                    style={styles.submitText}
                                    title="Cancel"
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View style={styles.fieldBtnSp} />
                        <View style={styles.fieldBtn}>
                          <View style={styles.btnBlockFull}>
                            <TouchableOpacity
                              disabled={!this.state.filterDosings.length}
                              onPress={this.onSave}
                              style={[styles.submitSm, this.state.filterDosings.length ? styles.btnActiveState : styles.btnBlurState]}>
                              <View style={styles.btnSingleView}>
                                <View
                                  style={[
                                    styles.btnSingle,
                                    styles.btnSingleCent,
                                  ]}>
                                  <UIText
                                    style={styles.submitText}
                                    title="Save"
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </PageContainer>
          </View>
        </KeyboardAwareScrollViewHoc>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    recordedDosing: state.routines.recordedDosing,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    startDate: state.report.startDate,
    endDate: state.report.endDate,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    saveReportMedicationsTherapie: (addList, removeList) =>
      dispatch(actions.saveReportMedicationsTherapie(addList, removeList)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportAddIMedicationsTherapiesScreen);
