import React from "react";
import {createStackNavigator} from '@react-navigation/stack';
import {
  CAREGIVERS, CLINICAL_TRIALS, COMMUNITY_GROUPS,
  CONTACTS, DASHBOARD, GRAPHS,
  IMAGES_DOCUMENTS,
  MEDICATIONS, METRICS, OTHER, PEER_TO_PEER,
  PROFILE,
  TODOS,
  REPORTS,
  ROUTINES,
  JOURNALS,
  SURVEYS,
  SYMPTOMS,
  HELP,
  MANAGE_DEVICES,
  HEALTH_JOURNEY
} from "./path";
import {DashboardScreen} from "../screens";
import {
  AddQuickNoteScreen,
  AddRoutineScreen,
  AddRoutineTemplateScreen,
  RoutinesScreen,
  RoutinesSettingsScreen,
  ViewRoutineScreen
} from "../screens/Routines";
import {AddContactScreen, ContactsScreen, ContactTakePhotoScreen, ViewContactScreen} from "../screens/Contacts";
import {
  AddMedicationsOrTherapiesScreen,
  MedicationsOrTherapiesScreen, ScanPrescriptionScreen,
  ViewMedicationsOrTherapiesScreen
} from "../screens/MedicationsOrTherapies";
import {
  AttachToContactScreen, AttachToJournalScreen,
  AttachToTreatmentScreen,
  QuestionMainScreen,
  QuestionsScreen
} from "../screens/Questions";
import {
  AddAttachmentScreen,
  AttachmentAttachToContactScreen,
  AttachmentAttachToJournalScreen, AttachmentAttachToTreatmentScreen,
  AttachmentScreen
} from "../screens/ImagesDocuments";
import {
  NewReportsScreen,
  ReportAddImageScreen, ReportAddIMedicationsTherapiesScreen,
  ReportAddIssueScreen, ReportAddSymptomsScreen,
  ReportAttachGraphScreen,
  ReportsScreen, ViewReportScreen
} from "../screens/Reports";
import {
  CancelSubscriptionScreen,
  EditProfileScreen,
  MeasurementsScreen,
  MyDevicesScreen,
  PremiumAccountScreen,
  ProfileScreen, UpgradePremiumScreen
} from "../screens/Profile";
import {MainSurveyScreen, SurveyQuestionScreen, SurveysScreen} from "../screens/Surveys";
import {ManageDevices} from "../screens/Device";
import {
  AddEditCustomSymptomScreen,
  AddEditMetricScreen, AddEditRollingGraphsScreen, ClinicalTrialDisclaimerScreen, ClinicalTrialScreen,
  MetricsScreen, RollingGraphsScreen,
  SymptomsScreen,
  ViewMetricScreen, ViewSymptomScreen
} from "../screens/Settings";
import {CaregiverAccessScreen, CaregiverScreen, InviteCaregiverScreen} from "../screens/Caregiver";
import {CommunityGroupsScreen, ShareResourcesScreen} from "../screens/CommunityGroups";
import ContactusScreen from "../screens/Contactus";
import NoNetworkScreen from "../screens/NoNetwork/NoNetworkScreen";
import {
  PeerToPeerDisclaimerScreen,
  PeerResultsByConditionScreen,
  PeerToPeerMainScreen,
  FindPeersScreen,
  SearchResultsScreen,
  IndividualChatScreen,
  NewChatListScreen
} from '../screens/PeerToPeer';
import {HealthJourneyScreen, AddEventScreen} from "../screens/HealthJourney";
import HealthJourneyAddAttachments from "../screens/HealthJourney/HealthJourneyAttachments/HealthJourneyAddAttachments";
import {
  APP_FEATURE_P2P_ENABLED,
  APP_FEATURE_MANAGE_DEVICE_ENABLED,
  APP_FEATURE_HEALTH_JOURNEY_ENABLED
} from '../util/featureConfig';
import MobileFilter from "../screens/HealthJourney/HealthJourneyFilters/MobileFilter";

const Stack = createStackNavigator();

class RouteCommonLoggedInAndLoadedRoutes {

  static getFragment(additionalRoutes) {
    return <>
      <Stack.Screen name={DASHBOARD.path} component={DashboardScreen}/>
      <Stack.Screen name={DASHBOARD.joinStudyPath} component={DashboardScreen}/>
      {/* routines-start */}
      <Stack.Screen name={JOURNALS.path} component={RoutinesScreen}/>
      <Stack.Screen
        name={JOURNALS.viewJournalPath}
        component={ViewRoutineScreen}
      />
      <Stack.Screen
        name={JOURNALS.addJournalPath}
        component={AddRoutineScreen}
      />
      <Stack.Screen
        name={ROUTINES.addRoutineTemplatePath}
        component={AddRoutineTemplateScreen}
      />
      <Stack.Screen
        name={ROUTINES.editRoutineTemplatePath}
        component={AddRoutineTemplateScreen}
      />

      <Stack.Screen
        name={ROUTINES.path}
        component={RoutinesSettingsScreen}
      />

      <Stack.Screen
        name={JOURNALS.addQuickNotePath}
        component={AddQuickNoteScreen}
      />
      {/* routines-end */}

      {/* contacts-start */}
      <Stack.Screen name={CONTACTS.path} component={ContactsScreen}/>
      <Stack.Screen
        name={CONTACTS.viewContactPath}
        component={ViewContactScreen}
      />
      <Stack.Screen
        name={CONTACTS.addContactPath}
        component={AddContactScreen}
      />
      <Stack.Screen
        name={CONTACTS.editContactPath}
        component={AddContactScreen}
      />
      <Stack.Screen
        name={CONTACTS.contactTakePhotoPath}
        component={ContactTakePhotoScreen}
      />
      {/* contacts-end */}

      {/* medications-start */}
      <Stack.Screen
        name={MEDICATIONS.path}
        component={MedicationsOrTherapiesScreen}
      />
      <Stack.Screen
        name={MEDICATIONS.addTherapiePath}
        component={AddMedicationsOrTherapiesScreen}
      />
      <Stack.Screen
        name={MEDICATIONS.editTherapiePath}
        component={AddMedicationsOrTherapiesScreen}
      />
      <Stack.Screen
        name={MEDICATIONS.viewTherapiesPath}
        component={ViewMedicationsOrTherapiesScreen}
      />
      <Stack.Screen
        name={MEDICATIONS.scanPrescriptionPath}
        component={ScanPrescriptionScreen}
      />
      {/* medications-end */}

      {/* question-start */}

      <Stack.Screen name={TODOS.path} component={QuestionMainScreen}/>
      <Stack.Screen
        name={TODOS.addToDoPath}
        component={QuestionsScreen}
      />

      <Stack.Screen
        name={TODOS.todoAttachToTreatmentPath}
        component={AttachToTreatmentScreen}
      />
      <Stack.Screen
        name={TODOS.todoAttachToContactPath}
        component={AttachToContactScreen}
      />
      <Stack.Screen
        name={TODOS.todoAttachToJournalPath}
        component={AttachToJournalScreen}
      />
      {/* question-end */}

      {/* attachment-start */}
      <Stack.Screen
        name={IMAGES_DOCUMENTS.path}
        component={AttachmentScreen}
      />

      <Stack.Screen
        name={IMAGES_DOCUMENTS.addImagesDocumentsPath}
        component={AddAttachmentScreen}
      />

      <Stack.Screen
        name={IMAGES_DOCUMENTS.attachToContactPath}
        component={AttachmentAttachToContactScreen}
      />

      <Stack.Screen
        name={IMAGES_DOCUMENTS.attachToJournalPath}
        component={AttachmentAttachToJournalScreen}
      />

      <Stack.Screen
        name={IMAGES_DOCUMENTS.attachToTreatmentPath}
        component={AttachmentAttachToTreatmentScreen}
      />

      {/* attachment-end */}

      {/* my-health-journey-start */}
      {APP_FEATURE_HEALTH_JOURNEY_ENABLED &&
        <>
          <Stack.Screen name={HEALTH_JOURNEY.path} component={HealthJourneyScreen}/>
          <Stack.Screen name={HEALTH_JOURNEY.addEventPath} component={AddEventScreen}/>
          <Stack.Screen name={HEALTH_JOURNEY.addAttachments} component={HealthJourneyAddAttachments}/>
          <Stack.Screen name={HEALTH_JOURNEY.filter} component={MobileFilter}/>
        </>
      }
      {/* my-health-journey-end */}

      {/* report-start */}
      <Stack.Screen name={REPORTS.path} component={ReportsScreen}/>
      <Stack.Screen
        name={REPORTS.reportAddIssuePath}
        component={ReportAddIssueScreen}
      />
      <Stack.Screen
        name={REPORTS.reportAddImagePath}
        component={ReportAddImageScreen}
      />
      <Stack.Screen
        name={REPORTS.reportAddGraphPath}
        component={ReportAttachGraphScreen}
      />

      <Stack.Screen
        name={REPORTS.newReportPath}
        component={NewReportsScreen}
      />
      <Stack.Screen
        name={REPORTS.editReportPath}
        component={NewReportsScreen}
      />
      <Stack.Screen
        name={REPORTS.viewReportPath}
        component={ViewReportScreen}
      />
      <Stack.Screen
        name={REPORTS.reportAddSymptomPath}
        component={ReportAddSymptomsScreen}
      />

      <Stack.Screen
        name={REPORTS.reportAddMedicationsPath}
        component={ReportAddIMedicationsTherapiesScreen}
      />
      {/* report-end */}

      {/* profile-start */}
      <Stack.Screen name={PROFILE.path} component={ProfileScreen}/>
      <Stack.Screen
        name={PROFILE.profileEditPath}
        component={EditProfileScreen}
      />

      <Stack.Screen
        name={PROFILE.profileMyDevicesPath}
        component={MyDevicesScreen}
      />
      <Stack.Screen
        name={PROFILE.profileMeasurementsPath}
        component={MeasurementsScreen}
      />

      <Stack.Screen
        name={PROFILE.profilePremiumAccountPath}
        component={PremiumAccountScreen}
      />

      <Stack.Screen
        name={PROFILE.profileUpgradePremiumPath}
        component={UpgradePremiumScreen}
      />

      <Stack.Screen
        name={PROFILE.profileCancelSubscriptionPath}
        component={CancelSubscriptionScreen}
      />
      {/* profile-end */}

      {/* surveys-start */}

      <Stack.Screen name={SURVEYS.path} component={SurveysScreen}/>

      <Stack.Screen
        name={SURVEYS.mainSurveyPath}
        component={MainSurveyScreen}
      />
      <Stack.Screen
        name={SURVEYS.surveyQuestionPath}
        component={SurveyQuestionScreen}
      />
      {/* surveys-end */}

      {/* Manage Device start */}

      {APP_FEATURE_MANAGE_DEVICE_ENABLED &&
          <Stack.Screen
            name={MANAGE_DEVICES.path}
            component={ManageDevices}
          />
      }

      {/* Manage Device End */}

      {/* metric-start */}

      <Stack.Screen
        name={METRICS.addMetricPath}
        component={AddEditMetricScreen}
      />
      <Stack.Screen
        name={METRICS.editMetricPath}
        component={AddEditMetricScreen}
      />
      <Stack.Screen name={METRICS.path} component={MetricsScreen}/>
      <Stack.Screen
        name={METRICS.viewMetricPath}
        component={ViewMetricScreen}
      />
      {/* metric-end */}

      {/* symptoms-start */}

      <Stack.Screen name={SYMPTOMS.path} component={SymptomsScreen}/>

      <Stack.Screen
        name={SYMPTOMS.addSymptomPath}
        component={AddEditCustomSymptomScreen}
      />
      <Stack.Screen
        name={SYMPTOMS.editSymptomPath}
        component={AddEditCustomSymptomScreen}
      />

      <Stack.Screen
        name={SYMPTOMS.viewSymptomPath}
        component={ViewSymptomScreen}
      />
      {/* symptoms-end */}

      {/* rolling-graphs-start */}
      <Stack.Screen name={GRAPHS.path} component={RollingGraphsScreen}/>

      <Stack.Screen
        name={GRAPHS.addGraphPath}
        component={AddEditRollingGraphsScreen}
      />
      <Stack.Screen
        name={GRAPHS.editGraphPath}
        component={AddEditRollingGraphsScreen}
      />
      {/* rolling-graphs-end */}

      {/* clinical-trials-start */}
      <Stack.Screen
        name={CLINICAL_TRIALS.otherPath}
        component={ClinicalTrialDisclaimerScreen}
      />
      <Stack.Screen
        name={CLINICAL_TRIALS.path}
        component={ClinicalTrialScreen}
      />
      {/* clinical-trials-end */}

      {/* caregiver-start */}
      <Stack.Screen
        name={CAREGIVERS.inviteCaregiverPath}
        component={InviteCaregiverScreen}
      />
      <Stack.Screen
        name={CAREGIVERS.path}
        component={CaregiverAccessScreen}
      />

      <Stack.Screen
        name={CAREGIVERS.caregiverPath}
        component={CaregiverScreen}
      />
      {/* caregiver-end */}
      {/* community-groups-start */}
      <Stack.Screen
        name={COMMUNITY_GROUPS.path}
        component={CommunityGroupsScreen}
      />
      <Stack.Screen
        name={COMMUNITY_GROUPS.shareResourcePath}
        component={ShareResourcesScreen}
      />
      {/* community-groups-end */}

      <Stack.Screen name={HELP.path} component={ContactusScreen}/>
      {/* <Route path={OTHER.setupPath} component={SetupScreen} /> */}
      {/* No network connection */}
      <Stack.Screen name={OTHER.noNetwork} component={NoNetworkScreen}/>

      {additionalRoutes.map(routeMap => (
        <Stack.Screen
          key={routeMap.name}
          name={routeMap.name}
          component={routeMap.component}
        />
      ))}

      {APP_FEATURE_P2P_ENABLED &&
        <>
          <Stack.Screen
            name={PEER_TO_PEER.disclaimerPath}
            component={PeerToPeerDisclaimerScreen}
          />
          <Stack.Screen
            name={PEER_TO_PEER.peerResultsByConditionPath}
            component={PeerResultsByConditionScreen}
          />
          <Stack.Screen
            name={PEER_TO_PEER.path}
            component={PeerToPeerMainScreen}
          />
          <Stack.Screen
            name={PEER_TO_PEER.findPeersPath}
            component={FindPeersScreen}
          />
          <Stack.Screen
            name={PEER_TO_PEER.searchResultsPath}
            component={SearchResultsScreen}
          />
          <Stack.Screen
            name={PEER_TO_PEER.individualChatPath}
            component={IndividualChatScreen}
          />
          <Stack.Screen
            name={PEER_TO_PEER.newChatListPath}
            component={NewChatListScreen}
          />
        </>
      }
      {/* Deep link start */}
      <Stack.Screen
        name={CAREGIVERS.caregiverAcceptPath}
        component={DashboardScreen}
      />
      <Stack.Screen
        name={JOURNALS.updateRoutinePath}
        component={AddRoutineTemplateScreen}
      />
      <Stack.Screen
        name={JOURNALS.createJournalPath}
        component={AddRoutineScreen}
      />
      <Stack.Screen
        name={JOURNALS.journalFromEmail}
        component={ViewRoutineScreen}
      />
      <Stack.Screen
        name={SURVEYS.surveyQuestionsPath}
        component={MainSurveyScreen}
      />
      {/* Deep link end */}
    </>;
  }
}

export default RouteCommonLoggedInAndLoadedRoutes;
