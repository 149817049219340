import React, { Component } from 'react'
import { pendoTrackEvent } from '../../util/pendoConfig';
import crypto from 'crypto';

export default class CommonStuff extends Component {

  handleUpload = async (s3Url, callback) => {
    await new Promise(r => this.props.resourceFileUpload(
      s3Url,
      this.state.file,
      r,
    ))
    callback()
  }

  handleFileAdd = async files => {
    pendoTrackEvent('HealthLibrary@ > UploadFile');
    this.dismissTagList();
    this.setState({fileUploadWait: true});
    if (files.length && files[0] && files[0].size < 51200000) {
      const fileChecksum = await this.getFileChecksum(files[0]);
      if (fileChecksum) {
        let fileName = files[0].name;
        this.setState({
          file: files[0],
          title: fileName,
          url: null,
          editableUrl: false,
          validUrl: true,
          fileChecksum: fileChecksum,
          exist: false,
          error: false,
          errorDescription: '',
          numberOfUsers: 0,
          resourceId: null,
          fileUploadWait: false,
          saveDisabled: false
        });
        this.props.onResourceDocumentValidation({
          fileName: fileName,
          fileChecksum: fileChecksum,
        });
      }
    } else {
      this.setState({
        error: true,
        errorDescription: 'File size must be less than 50MB',
        fileUploadWait: false,
        saveDisabled: true
      });
    }
  };

  getFileChecksum = file => {
    return new Promise(r => {
      let reader = new FileReader();
      let fileChecksum = null;
      reader.readAsDataURL(file);
      reader.onload = function() {
        fileChecksum = crypto
          .createHash('md5')
          .update(reader.result.toString())
          .digest('hex');
        r(fileChecksum);
      };
    })
  };

  render() {
    return (
     <></>
    )
  }
}