import React from 'react';
import { View } from 'react-native';
import TopSectionBar from '../../../components/HealthJourney/HealthJourneyHeader/TopSectionBar';
import OptionFilters from '../../../components/HealthJourney/HealthJourneyHeader/OptionFilters';

const HealthJourneyHeader = (props) => {
  const {selectedAllCategoriesItems,isDateSelected,toggleDate, onPressFilter, dob, hasEvents, setOpenFilters, openFilters} = props;
  return (
    <View>
      {/* TOP SECTION */}
      <View  pointerEvents={openFilters ? "none" : "auto"}>
        <TopSectionBar />
      </View>
      <OptionFilters
        selectedAllCategoriesItems={selectedAllCategoriesItems}
        hasEvents = {hasEvents}
        onPressFilter={onPressFilter} 
        setOpenFilters={setOpenFilters}
        openFilters={openFilters}
        dob={dob}
        isDateSelected={isDateSelected}
        toggleDate={toggleDate}/>
    </View>
  );
};

export default HealthJourneyHeader;
