import React from "react";
import { View} from "react-native";
import { connect } from "react-redux";
import NoGraphData from "../../Dashboard/GraphSection/noGraphData";
import GraphCommon from "../../GraphCommon/GraphCommon";
import styles from "../../../Css";
class Graph extends GraphCommon {

  componentDidMount() {
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    });
  }

  static getDerivedStateFromProps(props, state) {
    const {
      symptoms,
      metrics,
      therapies,
      startDate,
      endDate,
      symptomProps,
      metricProps,
      therapyProps,
    } = props;
      const chartData = Graph.drawGraph(
        symptoms,
        metrics,
        therapies,
        startDate,
        endDate,
        props
      );
      return {
        therapies: therapies,
        metrics: metrics,
        symptoms: symptoms,
        symptomProps: symptomProps,
        metricProps: metricProps,
        therapyProps: therapyProps,
        chartData: chartData
      };
  }

  render() {
    return (
      <View style={styles.graphAreaWrapper}>
        <div id={this.props.graphDivId} style={{ width: "100%", height: "353px" }} />
        {(!Boolean(this.state.chartData) || Boolean(this.state.chartData && this.state.chartData.length === 0)) &&
          <View style={[styles.noGraphWrapper, styles.flexJcCtAiCt]}>
            <NoGraphData dashboardGraph={true} graphId={this.props.graphId} />
          </View>
        }
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    symptomsDigestProcessed: state.routines.symptomsDigestProcessed,
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    userDefinedMetricUnitOfMeasures:
      state.routines.metricsDigest.userDefinedMetricUnitOfMeasures,
    systemDefinedMetricUnitOfMeasures:
      state.routines.metricsDigest.systemDefinedMetricUnitOfMeasures,
  };
};

export default connect(mapStateToProps, null)(Graph);
