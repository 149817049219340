import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import Collapsible from "../../components/Collapsible/Collapsible";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import PlusButtonCom from "../../components/Button/plusButtonCom";

class ReportSymptom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleReportSymptoms: props.symptomOpne,
      filteredRecordedSymptom: props.filteredRecordedSymptom
        ? props.filteredRecordedSymptom
        : [],
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { filteredRecordedSymptom } = props;
    this.setState({ filteredRecordedSymptom });
  }
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    let count = 0;
    return (
      <Collapsible
        title="Symptoms"
        contentVisibility={this.state.visibleReportSymptoms}
        handleOnPressCollapsible={() => {
          this.setState({
            visibleReportSymptoms: !this.state.visibleReportSymptoms,
          });
          this.props.setTabOpen(1, !this.state.visibleReportSymptoms);
        }}
      >
        <View style={[styles.collapseContent]}>
          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              {/* If Symptoms available only, Please add styles.addedItemsSymptoms  */}
              <View
                style={[
                  this.state.filteredRecordedSymptom.length > 0
                    ? styles.addedItemsSymptoms
                    : styles.addedItemsNone,
                ]}
              >
                {/* Loop Item Start */}

                {this.state.filteredRecordedSymptom &&
                  this.state.filteredRecordedSymptom.map((item) => {
                    let symptomName;
                    count++;

                    if (this.props.userSymptomsProcessed[item.symptom]) {
                      symptomName = this.props.userSymptomsProcessed[
                        item.symptom
                      ].name;
                    }

                    if (symptomName) {
                      return (
                        <View
                          style={[
                            styles.listItemView,
                            styles.listItemAddedView,
                            styles.colPdLtRt,
                            styles.colBg,
                          ]}
                          key={"rs_" + item.id}
                        >
                          <View style={[styles.listItemCloseCenter]}>
                            <View style={styles.listItemCloseIcon}>
                              <TouchableOpacity
                                onPress={() =>
                                  this.props.removeReportSymptom(item.id)
                                }
                              >
                                <Image
                                  style={styles.closeDarkIcon}
                                  source={require("../../assets/icons/close-dark.png")}
                                />
                              </TouchableOpacity>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.listContentViewFull,
                              styles.colPdTopBot,
                            ]}
                          >
                            <View style={[styles.listContentView]}>
                              <View
                                style={[
                                  styles.addedItemInd,
                                  styles.addedItemRtPd,
                                ]}
                              >
                                <View
                                  style={[
                                    stylesRes.itemTextAreaMg,
                                    stylesRes.itemTextPdRt,
                                  ]}
                                  dataSet={{media: ids.itemTextAreaMg}}
                                >
                                  <Text style={[styles.textPrime]} allowFontScaling={false}>
                                    {symptomName}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                          {count < this.state.filteredRecordedSymptom.length ? (
                            <View style={stylesRes.itemBorderReport} dataSet={{media: ids.itemBorderReport}} />
                          ) : null}
                        </View>
                      );
                    } else {
                      return null;
                    }
                  })}
                {/* Loop Item End */}
              </View>
              <View style={[styles.addItemSecFull, styles.colBg, styles.colPd]}>
                <View style={[styles.btnArea]}>
                  <PlusButtonCom
                    btnPosition={"left"}
                    btnText={"Add"}
                    touchableOnPress={this.props.redirectReportAttachSymptom}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Collapsible>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filteredRecordedSymptom: state.report.filteredRecordedSymptom,
    userSymptomsProcessed: state.routines.symptomsDigestProcessed.userSymptoms,
    symptomOpne: state.report.symptomOpne,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeReportSymptom: (id) => dispatch(actions.removeReportSymptom(id)),
    setTabOpen: (tab, open) => dispatch(actions.setTabOpen(tab, open)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportSymptom);
