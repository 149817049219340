//ROW TYPES
const HEADER = "HEADER";
const QUESTION_ROW = "QUESTION_ROW";
const DESCRIPTIVE_ROW = "DESCRIPTIVE_ROW";
const TOTAL_ROW = "TOTAL_ROW";
const SUB_TOTAL_ROW = "SUB_TOTAL_ROW";

//QUESTION ROW SELECTION TYPES
const SINGLE_SELECTION = "SINGLE_SELECTION";
const LONG_ANSWER = "LONG_ANSWER";
const DEFAULT = "DEFAULT";

//PLACEHOLDERS
const TYPE_ANSWER_HERE = "type answer here";

const SELECT_TYPE_RADIO = 'RADIO';
const SELECT_TYPE_DEFAULT = 'DEFAULT';

const COLUMN_TEMPLATE = {
    columnId: null,
    selected: false,
    value: null,
    id: -1545692,
}

export {
    HEADER,
    QUESTION_ROW,
    DESCRIPTIVE_ROW,
    SINGLE_SELECTION,
    LONG_ANSWER,
    DEFAULT,
    TYPE_ANSWER_HERE,
    SELECT_TYPE_RADIO,
    SELECT_TYPE_DEFAULT,
    TOTAL_ROW,
    SUB_TOTAL_ROW,
    COLUMN_TEMPLATE
}