import React, { Component } from "react";
import { View, Image, Text, TouchableOpacity, TextInput, Platform } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import UIText from "../../components/Text/text";
import TickButton from "../CheckBox/CheckBox";
import QuestionMainAnswers from "./QuestionMainAnswers";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import PlusButtonCom from "../../components/Button/plusButtonCom";

import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { postedUserName } from './Util/PostedUserNameUtils'

// Date
import moment from "moment";
import { pendoTrackEvent } from "../../util/pendoConfig";

class QuestionMainItem extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      tabIndex: this.props.tabIndex ? this.props.tabIndex : 0,
      healthQuestion: this.props.healthQuestion,
      answersSorted: [],
      viewQuestionBox: false,
      commentContent: "",
      hideComments: true,
      wait: false,
      waitAnswer: false,
      validComment: true,
      commentButtonEnable: true
    };
  }

  componentDidMount = () => {
    this.sortedAnswers();
  };

  UNSAFE_componentWillReceiveProps = (props) => {
    const { user, tabIndex, healthQuestion } = props;

    this.setState(
      {
        firstName: user.firstName,
        lastName: user.lastName,
        tabIndex,
        healthQuestion,
      },
      () => {
        this.sortedAnswers();
      }
    );
  };

  sortedAnswers = () => {
    let answersSorted = [];

    if (this.props.healthQuestion && this.props.healthQuestion.answers && this.props.healthQuestion.answers.length !== 0) {
      answersSorted = this.props.healthQuestion.answers;
      this.setState({ answersSorted });
    }
  };

  handleOnPressTick = (closed, healthQuestion) => {
    let healthQuestionObj = {
      artifactId: healthQuestion.mainArtifactId,
      healthQuestion,
    };

    // Set Local Change Attributes to Question Object
    healthQuestionObj.healthQuestion["closed"] = closed;
    healthQuestionObj.healthQuestion["editting"] = false;
    this.setState({ wait: true });
    this.props.onSaveHealthQuestion(
      healthQuestionObj,
      healthQuestion.mainArtifactId,
      () => {
        this.setState({ wait: false });
        let healthQuestionTemp = this.state.healthQuestion;
        healthQuestionTemp.closed = closed;
        this.setState({ healthQuestion: healthQuestionTemp });
        this.props.answerdQuestion(healthQuestion.id, closed);
      },
      this.props.journalUserId
    );
  };

  handleOnPressSave = (answer, questionId) => {
    let answerObj = {};

    if (answer && answer.trim() !== "") {
      answerObj = {
        healthQuestionId: questionId,
        healthQuestionAnswer: {
          id: -1,
          content: answer,
        },
      };
      this.setState({ waitAnswer: true });
      this.props.onUpdateHealthQuestionAnswer(
        answerObj,
        (res, data) => {
          // Add to sorted answers
          this.sortedAnswers();
          this.props.getQuestionDigest();
          // Clear Answer Box
          this.setState({
            viewQuestionBox: false,
            waitAnswer: false,
            commentContent: "",
            commentButtonEnable: true
          });
        },
        this.props.journalUserId
      );
    } else {
      this.setState({ validComment: false });
    }
  };

  redirectPage = (type, mainArtifactId) => {
    switch (type) {
      case 1:
        const journalId = this.props.journalArtifactMap.hasOwnProperty(mainArtifactId) ? this.props.journalArtifactMap[mainArtifactId] : null;
        const selectedJournalEntry = this.props.journalEntriesProcessed.hasOwnProperty(journalId) ? this.props.journalEntriesProcessed[journalId] : null;
        if (selectedJournalEntry) {
          this.props.onSetSelectedJournalEntry(selectedJournalEntry);
          this.props.redirectJournal(selectedJournalEntry?.title);
        }
        break;
      case 2:
        this.medicationsOrTherapiesSelectHandler(mainArtifactId);
        break;
      case 3:
        this.contactsSelectHandler(mainArtifactId);
        break;
      default:
        break;
    }
  };

  medicationsOrTherapiesSelectHandler = (id) => {
    if (this.props.listTherapie) {
      const selectedTherapie = this.props.listTherapie.filter(function (
        therapie
      ) {
        return therapie.artifactId === id;
      });

      if (selectedTherapie && selectedTherapie.length >= 1) {
        this.props.onSelectTherapie(selectedTherapie[0], () => {
          this.props.redirectMedicationsTherapies();
        });
      }
    }
  };

  contactsSelectHandler = (id) => {
    if (this.props.listContacts) {
      const selectedContact = this.props.listContacts.filter(function (
        contacts
      ) {
        return contacts.artifactId === id;
      });

      if (selectedContact && selectedContact.length >= 1) {
        this.props.onSelectContact(selectedContact[0], () => {
          if (!this.props.moreMenu) {
            this.props.onSlectMoreMenu();
          }

          this.props.redirectContact();
        });
      }
    }
  };

  addComment = () => {
    if(this.state.commentButtonEnable){
      this.setState((prevState) => ({
        viewQuestionBox: !prevState.viewQuestionBox,
        validComment: true,
        commentButtonEnable: false
      }), () => {
        if (this.textInput) {
          this.textInput.focus();
        }
      });
      pendoTrackEvent('To-Do@ > AddComment');
    } else {
      if (this.textInput) {
        this.textInput.focus();
      }
    }
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    let wait = null;
    if (this.state.wait) {
      wait = <ActivityIndicator size="small" pdZero={true} />;
    }

    let waitAnswer = null;
    if (this.state.waitAnswer) {
      waitAnswer = (
        <View style={[styles.actIndCom]}>
          <ActivityIndicator size="small" pdZero={true} />
        </View>
      );
    }

    return (
        <View
          style={[
            styles.questionRowSingle,
            styles.comMgTopSm
          ]}
        >
          <View style={[styles.questionDetailSec, styles.commentBoxView]}>
            <View style={[styles.toDoContWrapper]}>
              <View style={[styles.questionItemLtRtPd]}>
                <View style={[styles.questionTitleViewSm]}>
                  <View style={[styles.questionTitleBlockMain]}>
                    <View style={styles.questionTextBlock}>
                      <View style={[styles.questionTitleMain]}>
                        <View style={[styles.toDoSelection]}>
                          {this.props.healthQuestion.closed ? (
                            <TickButton
                              imagePath={"tickButton"}
                              styleType={"circle"}
                              tickBtnStyle={styles.circleSelection}
                              toggleTickCallback={() =>
                                this.handleOnPressTick(
                                  false,

                                  this.props.healthQuestion
                                )
                              }
                            />
                          ) : (
                            <TickButton
                              imagePath={"untickButton"}
                              styleType={"circle"}
                              tickBtnStyle={styles.circleSelection}
                              toggleTickCallback={() =>
                                this.handleOnPressTick(
                                  true,

                                  this.props.healthQuestion
                                )
                              }
                            />
                          )}
                        </View>
                        <View style={[styles.toDoTitle, styles.flexCom]}>
                          <Text style={[styles.textTitleBold, styles.textQuest]} allowFontScaling= {false}>
                            {this.props.healthQuestion.content}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={[styles.questionCommentBlock]}>
                  <View style={[styles.commentTextBlock, styles.flexCom]}>
                    <View style={[styles.btnArea]}>
                      <PlusButtonCom
                        btnPosition={"left"}
                        btnText={"Add Comment"}
                        touchableOnPress={this.addComment}
                        nativeId={'to-doAddComment'}
                      />
                    </View>
                  </View>
                  {wait}
                  <View style={[styles.commentDetailsWrapper, styles.flexRow]}>
                    <View style={[styles.questionAttachWrapper]}>
                      <TouchableOpacity
                        onPress={() => {
                          this.state.commentButtonEnable && this.redirectPage(
                            this.props.healthQuestion.type,
                            this.props.healthQuestion.mainArtifactId
                          );
                        }}
                      >
                        <Image
                          style={styles.attachMainIcon}
                          source={require("../../assets/icons/attach-lg.png")}
                        />
                      </TouchableOpacity>
                    </View>

                    <View style={[styles.questionDateWrapper]}>
                      <Text style={[styles.textPrime, styles.dateTextQuest]} allowFontScaling= {false}>
                        {moment(this.props.healthQuestion.openedDate).format(
                          "DD MMM 'YY"
                        )}
                      </Text>
                    </View>
                  </View>
                </View>

                {this.state.answersSorted.length !== 0 ? (
                  <View style={[styles.commentFullBlock]}>
                    <View style={[styles.commentsListView]}>
                      {this.state.answersSorted.length > 0 ? (
                        <View style={[styles.commentsListItemMoreBtnSec, styles.infoPdBotSm]}>
                          <View style={[styles.commentsListComHr]} />
                          <View style={[styles.moreCircleBubble]}>
                            <View style={styles.countBubbleWrapper}>
                              <Image
                                style={[styles.countBubble]}
                                source={require("../../assets/icons/bubble-count.png")}
                              />

                              <View style={styles.countBubbleText}>
                                <TouchableOpacity
                                  onPress={() => {
                                    this.setState((prevState) => ({
                                      hideComments: !prevState.hideComments,
                                    }));
                                  }}
                                  style={styles.countBubbleTouch}
                                >
                                  {this.state.hideComments ? (
                                    <Text
                                      style={[
                                        styles.textPrimeSm,
                                        styles.textAlignCenter,
                                      ]}
                                      allowFontScaling= {false}
                                    >
                                      {this.state.answersSorted.length}
                                    </Text>
                                  ) : (
                                    <Image
                                      style={[styles.countBubbleArrow]}
                                      source={require("../../assets/icons/close-arrow.png")}
                                    />
                                  )}
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      ) : null}

                      {Boolean(this.state.answersSorted.length) ? (
                        this.state.hideComments ? null : (
                          <View
                            style={[
                              styles.commentsListItemMore,
                              styles.morePdTop,
                            ]}
                          >
                            {/* TO-DO Question Loop Start */}
                            {this.state.answersSorted.map(
                              (answersSortedRes, index) => {
                                return (
                                  <QuestionMainAnswers
                                    key={"comm_" + answersSortedRes.id}
                                    userImage="TO-DO"
                                    userName={postedUserName(answersSortedRes.postedByUser)}
                                    answer={answersSortedRes.content}
                                    postedDate={answersSortedRes.modifiedOn}
                                  />
                                );
                              }
                            )}

                            {/* TO-DO Question Loop End */}
                          </View>
                        )
                      ) : null}
                    </View>
                  </View>
                ) : null}
              </View>
              <View style={[styles.hrLineSp]} />

              {/* Question Block */}
              <View
                style={[styles.questionCommentFull, styles.questionItemLtRtPd]}
              >
                {this.state.viewQuestionBox ? (
                  <View style={[styles.addCommentSection]}>
                    <View style={styles.fieldRowView}>
                      <View
                        style={[
                          styles.textAreaWrapper,
                          this.state.validComment
                            ? styles.validColor
                            : styles.inValidColor,
                        ]}
                      >
                        <Text style={styles.textView}>
                          <Text style={[styles.textLabel, styles.textGreyDark]} allowFontScaling= {false}>
                            Type to enter a comment
                          </Text>
                        </Text>
                        <TextInput
                          ref={input => {
                            this.textInput = input;
                          }}
                          allowFontScaling= {false}
                          value={this.state.commentContent}
                          onChangeText={(val) =>
                            this.setState({
                              commentContent: val,
                              validComment: true,
                            })
                          }
                          autoFocus={true}
                          style={[styles.textAreaField,
                            {
                              ...Platform.select({
                                android: {
                                  textAlignVertical: "top"
                                },
                              })
                            }]}
                          multiline={true}
                          numberOfLines={3}
                          placeholderTextColor={"#98989A"}
                        />
                      </View>
                    </View>
                    <View style={styles.fieldRow}>
                      <View style={styles.btnArea}>
                        <View style={[stylesRes.btnViewSecSmRight, stylesRes.btnViewComment]}>
                          <View style={styles.fieldBtn}>
                            <View style={styles.btnBlockFull}>
                              <TouchableOpacity
                                style={[styles.submitSm, styles.btnPdMini, styles.bgWhite, styles.borderBtn]}
                                underlayColor="rgba(0, 153, 168, 0.8)"
                                onPress={() => {
                                  this.setState({
                                    viewQuestionBox: false,
                                    commentContent: "",
                                    commentButtonEnable: true
                                  });
                                }}
                              >
                                <View style={styles.btnSingleView}>
                                  <View
                                    style={[
                                      styles.btnSingle,
                                      styles.btnSingleCent,
                                    ]}
                                  >
                                    <UIText
                                      style={[styles.submitText, styles.submitTextSm, styles.whiteBtnText]}
                                      title="Cancel"
                                    />
                                  </View>
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                          <View style={styles.fieldBtnSp} />
                          <View style={styles.fieldBtn}>
                            <View style={styles.btnBlockFull}>
                              <TouchableOpacity
                                style={[styles.submitSm, styles.btnPdMini, styles.borderBtnTransparent]}
                                underlayColor="rgba(0, 153, 168, 0.8)"
                                onPress={() =>
                                  this.handleOnPressSave(
                                    this.state.commentContent,
                                    this.props.healthQuestion.id
                                  )
                                }
                              >
                                <View style={styles.btnSingleView}>
                                  <View
                                    style={[
                                      styles.btnSingle,
                                      styles.btnSingleCent,
                                    ]}
                                  >
                                    {waitAnswer}
                                    <UIText
                                      style={[
                                        styles.submitText,
                                        styles.submitTextSm
                                      ]}
                                      title="Save"
                                    />
                                  </View>
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                ) : null}
              </View>
            </View>
          </View>
        </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    journalArtifactMap: state.routines.journalArtifactMap,
    listTherapie: state.routines.treatments,
    listContacts: state.routines.contacts,
    journalUserId: state.uistate.journalUserId,
    moreMenu: state.uistate.moreMenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateHealthQuestionAnswer: (
      healthQuestionAnswer,
      callBack,
      journalUserId
    ) =>
      dispatch(
        actions.updateHealthQuestionAnswerNew(
          healthQuestionAnswer,
          callBack,
          journalUserId
        )
      ),
    onSaveHealthQuestion: (question, artifactId, callBack, journalUserId) =>
      dispatch(
        actions.saveHealthQuestion(
          question,
          artifactId,
          callBack,
          journalUserId
        )
      ),
    onSetSelectedJournalEntry: (selectedId) =>
      dispatch(actions.setSelectedJournalEntry(selectedId)),
    onSelectTherapie: (SelectedTherapie, callBack) =>
      dispatch(actions.selectTherapie(SelectedTherapie, callBack)),
    onSelectContact: (selectedContact, callBack) =>
      dispatch(actions.selectedContact(selectedContact, callBack)),
    onSlectMoreMenu: () => dispatch(actions.slectMoreMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionMainItem);
