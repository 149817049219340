import React from "react";
import { View, Image, TouchableOpacity, Text } from "react-native";
import styles from "../../../../Css";
import { UI_BREAK_FOUR_COUNT , UI_BREAK_ONE_COUNT} from '../../../../util/platformWindowConfig';

const questionBlock = (props) => {
  const {
    title,
    items,
    selectedList,
    onPressItem,
    selectAll,
    onPressTimeFilter,
    windowWidth
  } = props;
  return (
    <View style={[styles.questionAnswerBlock]}>
      <View style={[styles.questionWrapper, styles.flexRow, styles.noBotMg, styles.contColFul]}>
        <Text style={[
          // styles.textPrimeBold,
          windowWidth > UI_BREAK_ONE_COUNT
          ? styles.textTitleBold
          : styles.textPrimeBold,
          windowWidth < UI_BREAK_FOUR_COUNT && styles.contCol
        ]}
        allowFontScaling= {false}
        >
          {title}
        </Text>
        <View style={[styles.answerSingleViewAll, styles.flexRow, windowWidth < UI_BREAK_FOUR_COUNT && styles.contCol]}>
          <View style={[styles.answerSingleViewCom, styles.flexRow]}>
            <TouchableOpacity
              style={[styles.answerViewWrapper]}
              onPress={onPressTimeFilter}
            >
              <View style={[styles.optionWrapper]}>
                {!selectAll ? (
                  <Image
                    style={styles.radioIcon}
                    source={require("../../../../assets/icons/radio-selected.png")}
                  />
                ) : (
                  <Image
                    style={styles.radioIcon}
                    source={require("../../../../assets/icons/radio-default.png")}
                  />
                )}
              </View>

              <View style={[styles.answerTextWrapper]}>
                <Text style={[
                  // styles.textPrime,
                  windowWidth > UI_BREAK_ONE_COUNT
                  ? styles.textTitle
                  : styles.textPrime,
                ]}
                allowFontScaling= {false}
                >Last 6 months</Text>
              </View>
            </TouchableOpacity>
          </View>
          <View style={[styles.answerSingleViewCom, styles.flexRow]}>
            <TouchableOpacity
              style={[styles.answerViewWrapper]}
              onPress={onPressTimeFilter}
            >
              <View style={[styles.optionWrapper]}>
                {selectAll ? (
                    <Image
                      style={styles.radioIcon}
                      source={require("../../../../assets/icons/radio-selected.png")}
                    />
                  ) : (
                    <Image
                      style={styles.radioIcon}
                      source={require("../../../../assets/icons/radio-default.png")}
                    />
                  )}
              </View>

              <View style={[styles.answerTextWrapper]}>
                <Text style={[
                  // styles.textPrime
                  windowWidth > UI_BREAK_ONE_COUNT
                  ? styles.textTitle
                  : styles.textPrime,
                  ]}
                  allowFontScaling= {false}
                  >All</Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>

      <View style={[styles.answerWrapper]}>
        {items && items.length > 0 &&
          items.map(res => {
            return (
              <View style={[styles.answerSingle, styles.answerSinglePd]} key={res.id}>
                <TouchableOpacity
                  style={[styles.answerOptMg, styles.answerViewWrapper]}
                  onPress={() => onPressItem(res)}
                >
                  <View style={[styles.optionWrapper]}>
                    {selectedList.find(element => element.id === res.id) ? (
                      <Image
                        style={styles.radioIcon}
                        source={require("../../../../assets/toggleTick/check-tick.png")}
                      />
                    ) : (
                      <Image
                        style={styles.radioIcon}
                        source={require("../../../../assets/toggleTick/check-untick.png")}
                      />
                    )}
                  </View>
                  <View style={[styles.answerTextWrapper]}>
                    <Text style={[
                      // styles.textPrime
                      windowWidth > 1366
                      ? styles.textTitle
                      : styles.textPrime,
                      ]}
                      allowFontScaling= {false}
                      >{res.name}</Text>
                  </View>
                </TouchableOpacity>
              </View>
            );
        })} 
      </View>
    </View>
  );
};

export default (questionBlock);
