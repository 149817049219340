import React from "react";
import { View, TouchableOpacity, Text, Image } from "react-native";
import styles from "../../Css";

const plusButton = (props) => {
  return (
    <View
      style={[
        styles.actionBtnWrapper,
        props.btnPosition === "left"
          ? styles.flexAiFs
          : props.btnPosition === "right"
          ? styles.flexAiFe
          : styles.flexAiCt,
      ]}
    >
      <View style={[styles.actionBtn, styles.actionBtnPd]}>
        <TouchableOpacity
          onPress={props.touchableOnPress}
          style={[styles.flexRow, styles.flexAiCt]}
          disabled={props.disabled}
        >
          <View style={styles.iconViewSm}>
            <Image
              source={require("../../assets/icons/square-plus-grey.png")}
              style={styles.addPlusIcon}
              resizeMode={"contain"}
            />
          </View>
          <Text style={[styles.textTitleBold, styles.textLightBlack]} allowFontScaling={false}>
            Attachment
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default (plusButton);
