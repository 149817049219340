import React, { Component } from "react";
import { View, ScrollView, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import UIText from "../../components/Text/text";
import Tabs from "../../components/Tab/Tab";
import {surveyTabs} from '../../Constant/index';
import SpaceBar from "../../components/SpaceBar/SpaceBar";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import SurveyItem from "../../components/Surveys/SurveyItem";
import PageContainer from "../../components/PageLayout/PageContainer";
import moment from "moment";
import {SURVEYS} from '../../navigation/path';
import { pendoTrackEvent } from "../../util/pendoConfig";

class Surveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveysOpen: [],
      surveysClosed: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.props.getAllUserHealthSurvey(
      this.props.journalUserId,
      (success, data) => {
        if (success) {
          this.setState({ surveysOpen: data.open, surveysClosed: data.closed });
        }
        this.setState({ loading: false });
      }
    );
  }

  startSurvey = (id, surveyId, name, modifiedOn, description, surveyStatus, duration, questionOrder, s3ImageUrl) => {
    const { getUserHealthSurveyById, journalUserId, resetSurvey, selectSurvey, setActiveTab, redirectMainSurvey, navigation } = this.props;
    this.setState({ loading: true });
    getUserHealthSurveyById(
      id,
      journalUserId,
      (success, data) => {
        this.setState({ loading: false });
        if (success) {
          if (surveyStatus === 'OPEN') {
            selectSurvey(id, surveyId, name, false, modifiedOn, description, duration, s3ImageUrl);
            setActiveTab(surveyTabs.open);
            redirectMainSurvey();
          }

          if (surveyStatus === 'PROCESSING') {
            navigation.push(SURVEYS.surveyQuestionPath, {
              from: SURVEYS.mainSurveyPath,
              surveyId, 
              notificationId: id, 
              surveyStatus,
              questionOrder
            });
          }
        } else {
          resetSurvey();
          setActiveTab(surveyTabs.open);
          redirectMainSurvey(true);
        }
      }
    );

  };

  viewSurvey = (id, surveyId, surveyAnsId, name, description) => {
    const { navigation } = this.props;
    navigation.reset({
      index: 0,
      routes: [{ 
        name: SURVEYS.surveyQuestionPath,
        params: {
          from: SURVEYS.mainSurveyPath, surveyId, 
          notificationId: id, 
          isReadOnly: true
        }
      }],
    });
  };

  selectedTab = (tabid) => {
    this.props.setActiveTab(tabid);
    if (tabid === surveyTabs.open) {
      pendoTrackEvent('Surveys > @Open')
    } else {
      pendoTrackEvent('Surveys > @Completed');
    }
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <React.Fragment>
        <SpaceBar />
        {this.state.loading ? (
          <ActivityIndicator />
        ) : (
          <ScrollView
            style={[stylesRes.scrollView, stylesRes.listBg]}
            keyboardShouldPersistTaps={'handled'}
            dataSet={{media: ids.listBg}}
          >
            <View style={[styles.gridSection, styles.bgWhite]}>
              <Tabs
                activeTab={this.props.activeTab}
                pageContainer={true}
                smScreenFull={true}
                tabIndex={this.selectedTab}
              >
                {/* Open tab */}
                <View
                  title="Open"
                  style={[styles.tabContentSection, styles.bgWhite]}
                  nativeId={'surveysOpen'}
                >
                  <PageContainer smScreenFull={true}>
                    <View style={[styles.gridRow]}>
                      <View style={styles.gridColFull}>
                        <View
                          style={[
                            stylesRes.tabContentFullArea,
                            stylesRes.listBg,
                            stylesRes.contBotPd,
                            stylesRes.surveyCombinedStyle
                          ]}
                          dataSet={{media: ids.surveyCombinedStyle}}
                        >
                          <View style={styles.tabFullBlock}>
                            <View style={styles.tabContentBlock}>
                              <View style={styles.titleArea}>
                                <View
                                  style={[
                                    // styles.colPd,
                                    styles.colPdTopBotLg,
                                    styles.flexRowJcCtAiCt,
                                    styles.bgWhite,
                                  ]}
                                >
                                  <UIText
                                    title="Benefits of Surveys?"
                                    style={[
                                      styles.textPrimeBold,
                                      styles.textAlignCenter,
                                    ]}
                                  />
                                  <TouchableOpacity
                                    style={[
                                      styles.toolTipIconLink,
                                      styles.toolTipMg,
                                    ]}
                                    onPress={() =>
                                      this.props.onShowTutorial(17)
                                    }
                                  >
                                    <Image
                                      source={require("../../assets/icons/question.png")}
                                      style={styles.questionIcon}
                                      resizeMode={"contain"}
                                    />
                                  </TouchableOpacity>
                                </View>
                              </View>

                              {this.state.surveysOpen.length > 0 ? (
                                <View style={[styles.secArea]}>
                                  <View
                                    style={[
                                      styles.secSubContentArea,
                                      //styles.menuBg,
                                      styles.secBgPdTop,
                                    ]}
                                  >
                                    {/* Loop this section  - start*/}
                                    {this.state.surveysOpen &&
                                      this.state.surveysOpen
                                      .sort((a,b) => b.surveyTime - a.surveyTime)
                                      .map((item) => (
                                        <SurveyItem
                                          key={item.id}
                                          title={item.title}
                                          surveyStatus={item.surveyStatus}
                                          date={moment(
                                            item.surveyTime,
                                            "x"
                                          ).format("D MMM 'YY")}
                                          surveyCompletedTime={item.surveyCompletedTime && moment(item.surveyCompletedTime, "x").format("D MMM 'YY")}
                                          surveyDueDate={item.surveyDueDate && moment(item.surveyDueDate, "x").format("D MMM 'YY")}
                                          onPress={() => {
                                            this.startSurvey(
                                              item.id,
                                              item.surveyId,
                                              item.title,
                                              item.modifiedOn,
                                              item.description,
                                              item.surveyStatus,
                                              item.duration,
                                              item.questionOrder,
                                              item.s3ImageUrl
                                            )
                                          }
                                        }
                                        />
                                      ))}
                                    {/* Loop this section  - end*/}
                                  </View>
                                </View>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </PageContainer>
                </View>
                {/* Completed tab */}
                <View
                  title="Completed"
                  style={[styles.tabContentSection, styles.bgWhite]}
                  nativeId={'surveysCompleted'}
                >
                  <PageContainer smScreenFull={true}>
                    <View style={[styles.gridRow]}>
                      <View style={styles.gridColFull}>
                        <View
                          style={[
                            stylesRes.tabContentFullArea,
                            stylesRes.listBg,
                            stylesRes.botPd,
                            stylesRes.surveyCombinedStyle
                          ]}
                         dataSet={{media: ids.surveyCombinedStyle}}
                        >
                          <View style={styles.tabFullBlock}>
                            <View style={styles.tabContentBlock}>
                              {this.state.surveysClosed.length > 0 ? (
                                <View style={[styles.secArea]}>
                                  <View
                                    style={[
                                      styles.secSubContentArea,
                                      styles.menuBg,
                                      styles.secBgPdTop,
                                    ]}
                                  >
                                    {/* Loop this section  - start*/}

                                    {this.state.surveysClosed &&
                                      this.state.surveysClosed
                                      .sort((a,b) => b.surveyCompletedTime - a.surveyCompletedTime)
                                      .map((item) => (
                                        <SurveyItem
                                          key={item.id}
                                          title={item.title}
                                          surveyId={item.surveyId}
                                          notificationId={item.id}
                                          surveyName={item.title}
                                          surveyStatus={item.surveyStatus}
                                          date={moment(
                                            item.surveyCompletedTime,
                                            "x"
                                          ).format("D MMM 'YY")}
                                          onPress={() =>{
                                            this.viewSurvey(
                                              item.id,
                                              item.surveyId,
                                              item.surveyAnsId,
                                              item.title,
                                              item.description
                                            )}
                                          }
                                        />
                                      ))}
                                    {/* Loop this section  - end*/}
                                  </View>
                                </View>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </PageContainer>
                </View>
              </Tabs>
            </View>
          </ScrollView>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUserHealthSurvey: (journalUserId, callBack) =>
      dispatch(actions.getAllUserHealthSurvey(journalUserId, callBack)),
    selectSurvey: (id, surveyId, name, isClosed, modifiedOn, description, duration, s3ImageUrl) =>
      dispatch(actions.selectSurvey(id, surveyId, name, isClosed, modifiedOn, description, duration, s3ImageUrl)),
    onShowTutorial: (tutorialType) =>
      dispatch(actions.showTutorial(tutorialType)),
    getUserHealthSurveyById: (id, journalUserId, callBack) =>
      dispatch(actions.getUserHealthSurveyById(id, journalUserId, callBack)),
    getUserHealthSurveyAnswersById: (answerId, journalUserId, callBack) =>
      dispatch(
        actions.getUserHealthSurveyAnswersById(
          answerId,
          journalUserId,
          callBack
        )
      ),
    resetSurvey: () => dispatch(actions.resetSurvey()),
    setActiveTab: (activeTabIndex) =>
      dispatch(actions.setActiveTab(activeTabIndex)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Surveys);
