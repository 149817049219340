import React, {Component} from 'react';
import {View} from 'react-native';
import stylesResponsive from '../../CssResponsive';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import MobileMenuItems from '../../components/Menu/MobileMenuItems';
import {AddMenuMobilleWeb, AddMenuWeb} from '../../components/AddMenu';
import {MobileWebMenu} from '../../components/Menu';
import {addMenuItems, peerToPeerTabs} from '../../Constant';
import {getUnseenRequestCount} from '../../util/commonUiLogic';
import {JOURNALS, PEER_TO_PEER} from '../../navigation/path';
import {pendoTrackEvent} from '../../util/pendoConfig';
import UploadProgressWeb from '../../components/UploadProgressWeb/uploadProgressWeb';
import styles from '../../Css';
import DraggableComponent from '../../components/UploadProgressWeb/DraggableComponent';
import CancelConfirmation from '../../components/ConfirmationBox/savingConfirmation';

const refForUnload = (e) => {
  e.preventDefault();
  e.returnValue = '';
}
class AddMenu extends Component {
  constructor(props) {
    super(props);
    const {selectedMenu, windowHeight, windowWidth, opened, addMenu, myNetwork, newMessageCount, unSeenConversations, seenNotification} = props;
    const {received} = myNetwork;
    this.state = {
      opened: opened,
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      showTutorial: false,
      selectedMenu: selectedMenu ? selectedMenu : 0,
      moreMenuMobile: false,
      addMenu: addMenu,
      hasCommunityNotifications: getUnseenRequestCount(received) > 0 || Object.keys(unSeenConversations).length > 0,
      hasSurveyNotifications: Object.values(seenNotification).filter(res => res.type === 'SURVEY').length > 0,
      showUploadProgressContent: true,
      showUploadProgress: false,
      filesLength: 0,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      windowHeight,
      windowWidth,
      showTutorial,
      selectedMenu,
      addMenu,
      opened,
      myNetwork,
      newMessageCount,
      unSeenConversations,
      files,
      showUploadProgress,
      seenNotification
    } = props;
    const {received} = myNetwork;
    let hasSurveyNotifications = state.hasSurveyNotifications;
    let hasCommunityNotifications = state.hasCommunityNotifications;
    if (getUnseenRequestCount(received) > 0 || Object.keys(unSeenConversations).length > 0) {
      hasCommunityNotifications = true;
    } else {
      hasCommunityNotifications = false;
    }
    if (Object.values(seenNotification).filter(res => res.type === 'SURVEY').length > 0) {
      hasSurveyNotifications = true;
    } else {
      hasSurveyNotifications = false;
    }
    if (
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth ||
      showTutorial !== state.showTutorial ||
      selectedMenu !== state.selectedMenu ||
      addMenu !== state.addMenu ||
      opened !== state.opened || 
      hasCommunityNotifications !== state.hasCommunityNotifications ||
      Object.keys(files).length !== state.filesLength ||
      hasSurveyNotifications !== state.hasSurveyNotifications ||
      showUploadProgress !== state.showUploadProgress
    ) {
      if (!state.showUploadProgress && showUploadProgress) {
        window.addEventListener('beforeunload', refForUnload);
      }
      if (state.filesLength > 0 && Object.keys(files).length === 0) {
        window.removeEventListener('beforeunload', refForUnload);
      }
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        showTutorial: showTutorial,
        selectedMenu: selectedMenu,
        addMenu: addMenu,
        opened: opened,
        hasCommunityNotifications: hasCommunityNotifications,
        hasSurveyNotifications: hasSurveyNotifications,
        filesLength: Object.keys(files).length,
        showUploadProgress: showUploadProgress,
      };
    }
    return null;
  }

  handelOnMenuItemPress = index => {
    if (this.props.moreMenu && index < 4) {
      this.props.onSlectMoreMenu();
    }
    this.setState({
      selectedMenu: index,
      moreMenuMobile: false,
    });
    if (index === PEER_TO_PEER.index) {
      const {connected} = this.props.myNetwork;
      if (connected && connected.length > 0) {
        this.props.OnSetPeerToPeerActiveTab(peerToPeerTabs.chats);
      } else {
        this.props.OnSetPeerToPeerActiveTab(peerToPeerTabs.myNetwork);
      }
    }
    if (index === JOURNALS.index) {
      this.props.onSetSelectedJournalEntry(null);
      this.props.onResetNewJournal();
    }
  };

  addJournalEntry = () => {
    const {addJournalModelOpen, openedState, addMenuState} = this.props;
    this.setState({opened: false, addMenu: false}, () => {
      addJournalModelOpen();
      openedState(false);
      addMenuState(false);
    });
    pendoTrackEvent('Orange Add > Journals');
  };
  addTaskQuestion = () => {
    const {addTaskQuestionPageOpen, openedState, addMenuState} = this.props;
    this.setState({opened: false, addMenu: false}, () => {
      addTaskQuestionPageOpen();
      openedState(false);
      addMenuState(false);
    });
    pendoTrackEvent('Orange Add > To-Do');
  };
  addAttachment = () => {
    const {addAttachmentPageOpen, openedState, addMenuState} = this.props;
    this.setState({opened: false, addMenu: false}, () => {
      addAttachmentPageOpen('web');
      openedState(false);
      addMenuState(false);
    });
    pendoTrackEvent('Orange Add > Attachment');
  };
  addNewContact = () => {
    const {addNewContactModalOpen, openedState, addMenuState} = this.props;
    this.setState(
      {
        opened: false,
        addMenu: false,
      },
      () => {
        addNewContactModalOpen();
        openedState(false);
        addMenuState(false);
      },
    );
    pendoTrackEvent('Orange Add > Contact');
  };
  addMedicationOrTherapy = () => {
    const {
      addMedicationOrTherapyModalOpen,
      openedState,
      addMenuState,
    } = this.props;
    this.setState(
      {
        opened: false,
        addMenu: false,
      },
      () => {
        addMedicationOrTherapyModalOpen();
        openedState(false);
        addMenuState(false);
      },
    );
    pendoTrackEvent('Orange Add > MedSupp+Act');
  };
  addQuickNote = () => {
    const {addQuickNotePageOpen, openedState, addMenuState} = this.props;
    this.setState({opened: false, addMenu: false}, () => {
      addQuickNotePageOpen();
      openedState(false);
      addMenuState(false);
    });
    pendoTrackEvent('Orange Add > QuickNote');
  };

  onShowHideUploadProgress = () => {
    this.setState({showUploadProgressContent: !this.state.showUploadProgressContent});
  };

  onCancelSingleUpload = (key, cancelToken) => {
    const fileSize = this.props.attachmentFullSize - this.props.files[key].size;
    this.props.setAttachmentSize(fileSize);
    if (key === this.props.currentUploadingAttachmentKey) {
      this.props.cancelUploadAttachmentProcessAxios(cancelToken);
    } else {
      if (Object.keys(this.props.files).length > 0) {
        const newFiles = this.props.files;
        delete newFiles[key];
        this.props.onSaveFilesToAttach(newFiles);
      }
    }
  };

  onCancelAllUpload = () => {
    if (Object.keys(this.props.files).length > 0) {
      if (!this.props.deleteUploadConfirm) {
        this.props.onShowDeleteUploadConfirmation(true, null);
      } else {
        this.props.clearFilesToAttach();
        this.props.clearUploadedAttachments();
        if (Object.keys(this.props.files).length > 0) {
          const token = this.props.files[this.props.currentUploadingAttachmentKey].cancelToken;
          this.props.cancelUploadAttachmentProcessAxios(token);
        }
        this.props.setAttachmentUploadStatus(false);
        this.props.setAttachmentArtifactId(null);
        this.props.onShowDeleteUploadConfirmation(false, null);
        this.props.hideUploadProgress();
        this.props.setAttachmentSize(0);
        this.props.setAttachmentUploadProgress(0);
      }
    } else {
      this.props.clearFilesToAttach();
      this.props.clearUploadedAttachments();
      this.props.hideUploadProgress();
      this.props.setAttachmentUploadProgress(0);
    }
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    // siteMaxWidth: 1440,
    let fullWidth = this.state.windowWidth;
    let rightSpMenu = 0;
    if (fullWidth > 1440) {
      let rightSpFull = (fullWidth - 1440) / 2;
      rightSpMenu = rightSpFull;
    }
    const {
      opened,
      selectedMenu = 0,
      addMenu,
      moreMenuMobile,
      windowHeight,
      showTutorial,
      hasCommunityNotifications,
      hasSurveyNotifications,
    } = this.state;
    const {
      navigation,
      openedState,
      moreMenu,
      onSlectMoreMenu,
      addMenuState,
      showMobileMenu,
      resetReport,
      onClearQuestionFilter,
      onClearJournalEntryFilte,
      journalUserId,
      applicationSettingsDigest,
      showUploadProgress,
      tabMenuIconView,
      peerProfile
    } = this.props;
    return (
      <React.Fragment>
        {!showTutorial && (
          <View style={[stylesRes.addMoreSection]}>
            {/* New Menu for Large Screen- Start */}

            {showMobileMenu && (
              <AddMenuWeb
                rightSpMenu={rightSpMenu}
                open={() => {
                  this.setState({opened: true});
                  openedState(true);
                  pendoTrackEvent('Orange Add >');
                }}
                colse={() => {
                  this.setState({opened: false});
                  openedState(false);
                }}
                opened={opened}
                menuItems={addMenuItems(
                  this.addJournalEntry,
                  this.addTaskQuestion,
                  this.addAttachment,
                  this.addNewContact,
                  this.addMedicationOrTherapy,
                  this.addQuickNote,
                )}
              />
            )}
          
            {showUploadProgress && (
              <DraggableComponent 
                windowHeight={this.props.windowHeight} 
                windowWidth={this.props.windowWidth}
                defaultPosition={{x: this.props.windowWidth - 280, y: -this.props.windowHeight + this.props.windowHeight - 140}}
              >
              <View 
              style={[stylesRes.uploadprogressWebDetails]}
              dataSet={{media: ids.uploadprogressWebDetails}}
              >
              <View style={styles.dashboardPgContainer}>
                <UploadProgressWeb />
              </View>
              </View>
            
              </DraggableComponent>
            )}
            {/* New Menu for Large Screen- End */}

            {/* Menu for Web View on Mobile- Start */}

            {showMobileMenu && (
              <MobileWebMenu
                handelOnMenuItemPress={this.handelOnMenuItemPress}
                selectedMenu={selectedMenu}
                onAdd={() => {
                  if(!addMenu) {
                    pendoTrackEvent('Orange Add >');
                  }
                  this.setState({
                    addMenu: !addMenu,
                    moreMenuMobile: false,
                  });
                  addMenuState(!addMenu);
                  if (moreMenu) {
                    onSlectMoreMenu();
                  }
                }}
                addMenu={addMenu}
                onPressMoreMenu={() => {
                  let selectedMenuNew = selectedMenu;
                  if (selectedMenu < 3) {
                    selectedMenuNew = 0;
                  }

                  onSlectMoreMenu();

                  this.setState({
                    moreMenuMobile: !moreMenuMobile,
                    selectedMenu: selectedMenuNew,
                  });
                }}
                moreMenuMobile={moreMenuMobile}
                navigation={navigation}
                onClearQuestionFilter={onClearQuestionFilter}
                onClearJournalEntryFilte={onClearJournalEntryFilte}
                hasCommunityNotifications={hasCommunityNotifications}
                hasSurveyNotifications={hasSurveyNotifications}
                journalUserId={journalUserId}
                tabMenuIconView={tabMenuIconView}
                route={
                  applicationSettingsDigest &&
                  applicationSettingsDigest
                    .showPeerProfileScreen === null
                    ? PEER_TO_PEER.disclaimerPath
                    : (applicationSettingsDigest &&
                      !Boolean(applicationSettingsDigest?.showPeerProfileScreen) &&
                      (!Boolean(peerProfile) || !Boolean(peerProfile && peerProfile?.username)))
                        ? PEER_TO_PEER.peerResultsByConditionPath
                        : PEER_TO_PEER.path
                  }
              />
            )}
            {/* End */}

            {moreMenuMobile && (
              <MobileMenuItems
                handelOnMenuItemPress={this.handelOnMenuItemPress}
                selectedMenu={selectedMenu}
                resetReport={resetReport}
                screenHeight={windowHeight}
                hideMoreMenuMobile={() => {
                  this.setState({
                    moreMenuMobile: false,
                  });
                }}
                navigation={navigation}
              />
            )}

            {addMenu && (
              <AddMenuMobilleWeb
                menuItems={addMenuItems(
                  this.addJournalEntry,
                  this.addTaskQuestion,
                  this.addAttachment,
                  this.addNewContact,
                  this.addMedicationOrTherapy,
                  this.addQuickNote,
                )}
              />
            )}
            {this.props.deleteUploadConfirm &&
              <CancelConfirmation
                visibleModal={this.props.deleteUploadConfirm}
                onBackdropPress={() => {
                  this.props.onShowDeleteUploadConfirmation(false, null);
                }}
                onRequestClose={() => {
                  this.props.onShowDeleteUploadConfirmation(false, null);
                }}
                alertBody={"Your items are still uploading, are you sure you want to cancel?"}
                alertTitle={"Cancel Upload?"}
                handleCancel={() => this.onCancelAllUpload()}
                handleConfirm={() => {
                  this.props.onShowDeleteUploadConfirmation(false, null)
                }}
                btnTextOne={"Cancel Upload"}
                btnTextTwo={"Continue Upload"}
                dynamic
              />
            }
          </View>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    showTutorial: state.uistate.showTutorial,
    selectedMenu: state.uistate.selectedMenu,
    moreMenu: state.uistate.moreMenu,
    showMobileMenu: state.uistate.showMobileMenu,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    myNetwork: state.routines.myNetwork,
    newMessageCount: state.peerToPeer.newMessageCount,
    unSeenConversations: state.peerToPeer.unSeenConversations,
    journalUserId: state.uistate.journalUserId,
    attachmentFullSize: state.routines.attachmentFullSize,
    attachmentUploadProgress: state.routines.attachmentUploadProgress,
    attachmentUploadProgressTemp: state.routines.attachmentUploadProgressTemp,
    files: state.imagesDocuments.files,
    uploadedFiles: state.imagesDocuments.uploadedFiles,
    currentUploadingAttachmentKey: state.routines.currentUploadingAttachmentKey,
    deleteUploadConfirm: state.uistate.deleteUploadConfirm,
    showUploadProgress: state.uistate.showUploadProgress,
    seenNotification: state.uistate.seenNotification,
    peerProfile: state.routines.peer2peer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSlectMoreMenu: () => dispatch(actions.slectMoreMenu()),
    resetReport: () => dispatch(actions.resetReport()),
    onResetClinicalTrialState: () =>
      dispatch(actions.resetClinicalTrialState()),
    onClearQuestionFilter: () => dispatch(actions.clearQuestionFilter()),
    onClearJournalEntryFilte: () => dispatch(actions.clearJournalEntryFilter()),
    OnSetPeerToPeerActiveTab: activeTabIndex =>
      dispatch(actions.setPeerToPeerActiveTab(activeTabIndex)),
    cancelUploadAttachmentProcessAxios: cancelToken => dispatch(actions.cancelUploadAttachmentProcessAxios(cancelToken)),
    setAttachmentSize: size => dispatch(actions.setAttachmentFullSize(size)),
    onSaveFilesToAttach: files => dispatch(actions.saveFilesToAttach(files)),
    clearFilesToAttach: () => dispatch(actions.clearFilesToAttach()),
    clearUploadedAttachments: () => dispatch(actions.clearUploadedAttachments()),
    setAttachmentUploadStatus: status => dispatch(actions.setAttachmentUploadStatus(status)),
    setAttachmentArtifactId: id => dispatch(actions.setAttachmentArtifactId(id)),
    onShowDeleteUploadConfirmation: (status, data) => dispatch(actions.onShowDeleteUploadConfirmation(status, data)),
    hideUploadProgress: () => dispatch(actions.hideUploadProgress()),
    setAttachmentUploadProgress: progress => dispatch(actions.setAttachmentUploadProgress(progress)),
    onSetSelectedJournalEntry: (selectedJournal) =>
      dispatch(actions.setSelectedJournalEntry(selectedJournal)),
    onResetNewJournal: () => dispatch(actions.resetNewJournal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddMenu);
