import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import styles from "../../../../Css";
import stylesResponsive from "../../../../CssResponsive";
import { getMenuItemDetails } from '../../../../util/commonUiLogic';

const commonBottomMenuItem = (props) => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  const { onPressMenuItem, addMenu, selectedMenu, moreMenuMobile, navigation, route, pathDetails, hasNotifications, onClearFilter } = props;
  const { title, index } = pathDetails;
  const details = getMenuItemDetails(title);
  return (
    <View style={[stylesRes.navLinkSingleItem]}>
      <TouchableOpacity
        onPress={() => {
          if (onPressMenuItem) {
            onPressMenuItem(index);
          } 
          if (onClearFilter) {
            onClearFilter();
          }
          navigation.reset({
            index: 0,
            routes: [{ name: route }],
          });
        }}
        style={[!addMenu ? styles.menuOverlayCom : styles.menuOverlay]}
        disabled={addMenu}
      >
        <View style={[styles.navLinkItemView]} nativeID={details.nativeId}>
          <View style={[styles.navMenuCom]}>
            <Image
              style={[styles.navMainMenuIcon]}
              source={selectedMenu === index && !moreMenuMobile
                ?   details.selectedIcon
                :   details.unSelectedIcon
            }
            />    
            {/* has notification - start*/}
            {Boolean(hasNotifications) && <View style={[styles.notifyAvailableMenu, styles.notifyAvailableMenuSm]} />}
            {/* has notification - end */}
          </View>
          <View style={[styles.navMenuText]}>
            <Text
              style={[
                styles.textExMicSmBold,
                styles.textAlignCenter,
                selectedMenu === index && !moreMenuMobile
                  ? styles.menuTextSelected
                  : styles.menuTextDefault,
              ]}
              allowFontScaling={false}
            >
              {title}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default commonBottomMenuItem;
