import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import styles from "../../Css";
import Metric from "./ReportGraphMetric";
import { chartTypes } from '../../Constant/index';
import {sortAlphabeticalOrder} from '../../util/commonUiLogic';
class ReportMetrics extends Component {
  render() {
    return (
      <View style={[styles.collapseContent]}>
        <View style={[styles.collapseDescription]}>
          <View style={[styles.collapseInContent, styles.colPdLtRt]}>
            <View style={styles.secSubContentArea}>
              {sortAlphabeticalOrder(this.props.metricsDigest.userDefinedMetrics)
                .map(item => (
                  <Metric
                    item={item}
                    key={"me_" + item.id}
                    configChartType={item.displayProperties?.style || (item.displayProperties?.dashedLine === true ? chartTypes.line : chartTypes.dash)}
                  />
              ))}
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    metricsDigest: state.routines.metricsDigest
  };
};

export default connect(
  mapStateToProps,
  null
)(ReportMetrics);
