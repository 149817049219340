import React, {Component} from 'react';
import { View, ScrollView, Text, Image, TouchableOpacity, Linking } from 'react-native';
import { connect } from 'react-redux';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Header from '../../components/Header/header';
import UIText from '../../components/Text/text';
import * as axiosCommon from '../../axios/axios-common';
import {SUBSCRIPTION_PLANS} from '../../store/actions/urls';
import * as actions from '../../store/actions/index';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import PageContainer from '../../components/PageLayout/PageContainer';
import PremiumContent from '../../components/Premium/premiumContent';
import {
  // expiryText,
  // isSubscriptionPremium,
  // isSubscriptionSponsored,
  getCurrentSubscription,
  isSubscriptionCancelled,
} from '../../util/subscriptionInformation';
import {DASHBOARD, PROFILE} from '../../navigation/path';
import {MONTH, YEAR} from '../../Constant';
import {ORGANIZATION_NAME, ORGANIZATION_URL_PRIVACY, ORGANIZATION_URL_TOU} from '../../util/commonUiConfig';
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { TAB_BREAk_POINT_START } from '../../util/platformWindowConfig';

class PremiumAccountScreen extends Component {
  constructor(props) {
    super(props);
    if (props.journalUserId) {
      props.navigation.push(DASHBOARD.path);
    }
    let fromPopup = null;
    if (props.route?.params && props.route?.params.fromPopup) {
      fromPopup = props.route?.params.fromPopup;
    }

    this.state = {
      firstName: (props.user || {}).firstName,
      lastName: (props.user || {}).lastName,
      subscriptionPlans: {},
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      fromPopup: fromPopup,
    };
  }

  componentDidMount() {
    const userSubscription = getCurrentSubscription(
      this.props.userSubscription,
    );

    // This really shouldn't direct to cancel but not changing right now. It should
    // just show "Congrats! You are already a premium user!"
    if (
      userSubscription === null &&
      !isSubscriptionCancelled(userSubscription)
    ) {
      this.props.navigation.push(PROFILE.profileCancelSubscriptionPath);
    } else {
      this.getSubscriptionPlans();
    }
    this.setState({
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
    });
    pendoTrackEvent('Premium@');
  }

  getSubscriptionPlans = () => {
    axiosCommon.axiosGET(SUBSCRIPTION_PLANS, res => {
      if (res.type === 'SUCCESS') {
        const obj = {};
        res.responseDto.data.plans.forEach(item => {
          obj[item.interval] = item;
        });
        this.setState({subscriptionPlans: obj});
      } else if (res.type === 'ERROR' && res.errorDto === 'SESSION_TIME_OUT') {
        this.props.logout();
      }
    });
  };

  redirectPremiumAccount = () => {
    this.props.navigation.push(PROFILE.profileUpgradePremiumPath);
    pendoTrackEvent('Premium@ > Upgrade');
  };

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const {windowHeight, windowWidth} = nextProps;
  //   this.setState({
  //     windowHeight: windowHeight,
  //     windowWidth: windowWidth,
  //     loading: true,
  //   });
  // }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth} = props;

    if (
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    let fullWidth = this.state.windowWidth;
    let btnStyle = styles.btnRowMgRg;
    if (fullWidth > TAB_BREAk_POINT_START) {
      btnStyle = styles.btnRowMgLg;
    }
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          filterClickHandler={this.handleOnPressFilter}
          clearFiltersHandler={this.handleOnPressClearFilters}
          handleOnPressBackButton={() => {
            if (this.state.fromPopup) {
              this.props.setFromPopup(this.state.fromPopup);
            }
            handleCommonBackNavigation(this.props.navigation);
          }}
          index={PROFILE.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        {Object.values(this.state.subscriptionPlans).length > 0 ? (
          <ScrollView style={styles.scrollViewArea}>
            <View style={styles.gridSection}>
              <View
                style={[
                  stylesRes.contentSection,
                  stylesRes.botExPd,
                  stylesRes.layoutTopPdFixed,
                ]}
                dataSet={{media: ids.botExPd}}>
                <PageContainer>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={styles.secArea}>
                        <View style={stylesRes.maxComSec} dataSet={{media: ids.maxComSec}}>
                          <View
                            style={[
                              styles.comSecRow,
                              styles.flexRow,
                              styles.flexAiCt,
                            ]}>
                            <View
                              style={[styles.comColHalf, styles.flexJcFeAiFe]}>
                              <View style={stylesRes.comImgSecPrem}>
                                <Image
                                  style={[styles.imgCover]}
                                  source={require('../../assets/premium/premium_smilingstars.png')}
                                />
                              </View>
                            </View>
                            <View style={styles.comColHalf}>
                              <View
                                style={[
                                  styles.comTextSec,
                                  styles.comPdLt,
                                  styles.spSmTopBot,
                                ]}>
                                <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                                  {this.state.firstName} {this.state.lastName}!
                                </Text>
                                <Text style={[styles.textPrime]} allowFontScaling={false}>
                                  You can do so much more with the premium
                                  version.
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </PageContainer>

                <PageContainer smScreenFull={true}>
                  <View style={[styles.gridRow, styles.postRelative]}>
                    <View style={styles.gridColFull}>
                      <View style={styles.secArea}>
                        <View
                          style={[styles.viewBannerSec, styles.postRelative]}>
                          <View
                            style={[
                              styles.premBannerBg,
                              styles.bgPremHeight,
                              styles.bgPlanHolderHeight,
                            ]}>
                            <Image
                              style={[styles.premBg]}
                              source={require('../../assets/premium/prem-bg.png')}
                            />
                          </View>
                          <View
                            style={[
                              styles.premBannerContent,
                              styles.bgPremHeight,
                              // styles.flexRowJcCtAiCt
                            ]}>
                            <View style={[styles.premTitleBlock]}>
                              <Text
                                style={[
                                  styles.textPrime,
                                  styles.textAlignCenter,
                                ]} allowFontScaling={false}>
                                Choose your
                                <Text style={[styles.textPrime, styles.textAlignCenter, styles.textEm, styles.bold]} allowFontScaling={false}>
                                 {` ${ORGANIZATION_NAME} Premium Plan`}
                                </Text>
                              </Text>
                            </View>

                            <View style={[styles.premTextArea]}>
                              <View style={stylesRes.premBoxFullView}>
                                <View style={stylesRes.premBoxView}>
                                  <View style={stylesRes.premBoxSingle}>
                                    <View
                                      style={[
                                        styles.premBoxData,
                                        styles.premShadow,
                                      ]}>
                                      <View style={[styles.preDataNo]}>
                                        <View style={[styles.preSignView]}>
                                          <View style={[styles.preDataSign]}>
                                            <Text
                                              style={[
                                                styles.textNoExSm,
                                                styles.textDark,
                                              ]} allowFontScaling={false}>
                                              C$
                                            </Text>
                                          </View>
                                        </View>
                                        <View style={[styles.preDataNoLg]}>
                                          <Text
                                            style={[
                                              styles.textNoLg,
                                              styles.textAlignCenter,
                                              styles.textDark,
                                            ]} allowFontScaling={false}>
                                            {this.state.subscriptionPlans[MONTH]
                                              .amount / 100}
                                          </Text>
                                        </View>
                                      </View>
                                      <View
                                        style={[
                                          styles.preDataLine,
                                          styles.borderThin,
                                        ]}
                                      />
                                      <View style={[styles.preDataText]}>
                                        <Text
                                          style={[
                                            styles.textPrimeSm,
                                            styles.textAlignCenter,
                                          ]} allowFontScaling={false}>
                                          Monthly subscription
                                        </Text>
                                        <Text
                                          style={[
                                            styles.textPrimeSm,
                                            styles.textAlignCenter,
                                          ]} allowFontScaling={false}>
                                          (C$
                                          {(this.state.subscriptionPlans[MONTH]
                                            .amount /
                                            100) *
                                            12}{' '}
                                          annually)
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                                <View style={stylesRes.premBoxOrViewWrapper}>
                                  <View style={stylesRes.premBoxOrView}>
                                    <View style={[styles.orIconSec]}>
                                      <Image
                                        style={[styles.orIcon]}
                                        source={require('../../assets/icons/or-bg.png')}
                                      />
                                    </View>

                                    <View style={[styles.premOrText]}>
                                      <Text
                                        style={[
                                          styles.textPrime,
                                          styles.textWhite,
                                          styles.textAlignCenter,
                                          styles.textEm,
                                        ]} allowFontScaling={false}>
                                        or
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                                <View style={stylesRes.premBoxView}>
                                  <View style={stylesRes.premBoxSingle}>
                                    {/* <TouchableOpacity style={[styles.maxView]}> */}
                                    {/* //Add styles.premSelected for selection */}
                                    <View
                                      style={[
                                        styles.premBoxData,
                                        styles.premShadow,
                                      ]}>
                                      <View style={[styles.preDataNo]}>
                                        <View style={[styles.preSignView]}>
                                          <View style={[styles.preDataSign]}>
                                            <Text
                                              style={[
                                                styles.textNoExSm,
                                                styles.textDark,
                                              ]} allowFontScaling={false}>
                                              C$
                                            </Text>
                                          </View>
                                        </View>

                                        <View style={[styles.preDataNoLg]}>
                                          <Text
                                            style={[
                                              styles.textNoLg,
                                              styles.textAlignCenter,
                                              styles.textDark,
                                            ]} allowFontScaling={false}>
                                            {this.state.subscriptionPlans[YEAR]
                                              .amount / 100}
                                          </Text>
                                        </View>
                                      </View>
                                      <View
                                        style={[
                                          styles.preDataLine,
                                          styles.borderThin,
                                        ]}
                                      />
                                      <View style={[styles.preDataText]}>
                                        <Text
                                          style={[
                                            styles.textPrimeSm,
                                            styles.textAlignCenter,
                                          ]} allowFontScaling={false}>
                                          Annual subscription
                                        </Text>
                                      </View>
                                    </View>
                                    {/* </TouchableOpacity> */}
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </PageContainer>

                <PageContainer>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={[styles.btnArea, btnStyle]}>
                        <View style={[styles.btnBlock, styles.noMgTop]}>
                          <TouchableOpacity
                            style={[styles.submit, styles.submitBgOrange]}
                            underlayColor="rgba(0, 153, 168, 0.8)"
                            onPress={this.redirectPremiumAccount}
                            nativeID={'premiumUpgrade'}>
                            <View style={styles.btnSingleView}>
                              <View
                                style={[
                                  styles.btnSingle,
                                  styles.btnSingleCent,
                                ]}>
                                {/* <View style={styles.actIndLg}>
                                  <ActivityIndicator size="small" />
                                </View> */}
                                <UIText
                                  style={styles.submitText}
                                  title="Upgrade to Premium"
                                />
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={styles.gridRow}>
                    <View style={[styles.headerContent, styles.gridColFull]}>
                      <View style={[styles.itemsContainerRow, styles.flexCom, styles.itemRowMg, styles.textAlignCenter]}>
                        <TouchableOpacity
                            onPress={() => Linking.openURL(ORGANIZATION_URL_PRIVACY)}
                            title="Privacy Policy">
                          <Text
                              style={[ styles.textPrimeMd, styles.textUnderline, styles.textSingle]} allowFontScaling={false}>
                            Privacy Policy
                          </Text>
                        </TouchableOpacity>
                        <Text style={[styles.textPrimeMd, styles.textEm, styles.textGrey]} allowFontScaling={false}>
                          and
                        </Text>
                        <TouchableOpacity
                            onPress={() => Linking.openURL(ORGANIZATION_URL_TOU)}
                            title="Terms of Use">
                          <Text style={[styles.textPrimeMd, styles.textUnderline, styles.textSingle]} allowFontScaling={false}>
                            Terms of Use
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </PageContainer>

                <PageContainer>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={styles.secArea}>
                        <View style={stylesRes.premiumDetailSec}>
                          <PremiumContent fullWidth={fullWidth} />
                        </View>
                      </View>
                    </View>
                  </View>
                </PageContainer>
                <PageContainer>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={[styles.btnArea, btnStyle]}>
                        <View style={[styles.btnBlock, styles.noMgTop]}>
                          <TouchableOpacity
                            style={[styles.submit, styles.submitBgOrange]}
                            underlayColor="rgba(0, 153, 168, 0.8)"
                            onPress={this.redirectPremiumAccount}
                            nativeID={'premiumUpgrade'}>
                            <View style={styles.btnSingleView}>
                              <View
                                style={[
                                  styles.btnSingle,
                                  styles.btnSingleCent,
                                ]}>
                                {/* <View style={styles.actIndLg}>
                                  <ActivityIndicator size="small" />
                                </View> */}
                                <UIText
                                  style={styles.submitText}
                                  title="Upgrade to Premium"
                                />
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </PageContainer>
              </View>
            </View>
          </ScrollView>
        ) : (
          <ActivityIndicator />
        )}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.routines.user,
    userSubscription: state.routines.userSubscription,
    journalUserId: state.uistate.journalUserId,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actions.logout()),
    setFromPopup: fromPopup => dispatch(actions.setFromPopup(fromPopup)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PremiumAccountScreen);
