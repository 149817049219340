import React, { useState, useEffect } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
} from 'react-native';
import _ from 'lodash';
import styles from '../../../../Css';
import TickButton from '../../../../components/CheckBox/CheckBox';

const MobileSubCategoriesExpandableView = ({ item, key, setSubItemSelection, selectedSubCategories, isUncheckedAll }) => {
    //Custom Component for the Expandable List
    const [isItemSelected, setItemSelection] = useState(false);

    useEffect(() => {
        if(_.isEqual(isUncheckedAll, true)){
            setItemSelection(false);
        }
        // setClearAllCheckboxes(false);
    }, [isUncheckedAll]);

    useEffect(() => {
        if (selectedSubCategories) {
            const isSubCategorySelected = selectedSubCategories.find(x => x.subCategoryList.includes(item.name));
            setItemSelection(Boolean(isSubCategorySelected));
        }
        //eslint-disable-next-line
    }, [selectedSubCategories]);

    return (
        <View style={[styles.flexRow, styles.flexJcFs, styles.flexAiCt, styles.menuSingleTextViewSm, styles.ctgryListRtLtTpBt, styles.pdBtTwo]}>
                        
            <TouchableOpacity
                style={styles.wdHundredPer}
                onPress={() => {
                    setItemSelection(!isItemSelected);
                    setSubItemSelection(item.name, !isItemSelected);
                }}
                key={key}>
                <View style={[styles.checkboxContainer]}>
                    {isItemSelected ? (
                        <TickButton
                            imagePath={'tickButton'}
                            toggleTickCallback={() =>
                                [setItemSelection(!isItemSelected), setSubItemSelection(item.name, !isItemSelected)]
                            }
                            tickStyle={{ marginRight: 0 }}
                        />
                    ) : (
                        <TickButton
                            imagePath={'untickButton'}
                            toggleTickCallback={() =>
                                [setItemSelection(!isItemSelected), setSubItemSelection(item.name, !isItemSelected)]
                            }
                            tickStyle={{ marginRight: 0 }}
                        />
                    )}
                    <Text style={[styles.textPaddingSubCategory, styles.textPrime]} numberOfLines={1}>
                        {`${item.name}`}
                    </Text>
                </View>
            </TouchableOpacity>
        </View>
    );
};

export default MobileSubCategoriesExpandableView;