import {
    SINGLE_SELECTION,
    LONG_ANSWER,
    DEFAULT,
    DESCRIPTIVE_ROW,
    TYPE_ANSWER_HERE,
    HEADER,
    TOTAL_ROW,
    SUB_TOTAL_ROW,
} from './SurveyTableConstants';

export const CellHook = {
    [DEFAULT]: (data, elementsRef) => {

        const { rowType, cellIndex, columnWidths } = data;

        const width = [DESCRIPTIVE_ROW].includes(rowType) ? columnWidths.fullColumn : cellIndex === 0 ? columnWidths.firstColumn : columnWidths.otherColumns;
        const textAlignCenter = [HEADER, DESCRIPTIVE_ROW].includes(rowType) || ([TOTAL_ROW, SUB_TOTAL_ROW].includes(rowType) && cellIndex !== 0) ? true : false;
        const enableAnswerTrigger = [HEADER, DESCRIPTIVE_ROW].includes(rowType) ? false : true;
        const needToCaptureColumnWidths = HEADER === rowType ? true : false;
        const clearValueWhenHideFirstRow = [DESCRIPTIVE_ROW].includes(rowType) ? false : true;
        const getFirstColumnValueToSecondColumn = [TOTAL_ROW, SUB_TOTAL_ROW].includes(rowType) && cellIndex === 1 ? true : false;

        return { width, textAlignCenter, enableAnswerTrigger, needToCaptureColumnWidths, clearValueWhenHideFirstRow, getFirstColumnValueToSecondColumn };
    },
    [SINGLE_SELECTION]: (data, elementsRef) => {

        const { rowType, cellIndex, columnWidths } = data;

        const width = cellIndex === 0 ? columnWidths.firstColumn : columnWidths.otherColumns;
        const textAlignCenter = cellIndex !== 0 ? true : false;
        const enableAnswerTrigger = cellIndex !== 0 ? true : false;

        return { width, textAlignCenter, enableAnswerTrigger, needToCaptureColumnWidths: true , clearValueWhenHideFirstRow: true};
    },
    [LONG_ANSWER]: (data, elementsRef) => {

        const { rowType, cellIndex, columnWidths } = data;

        const width = cellIndex === 0 ? columnWidths.firstColumn : columnWidths.mergedColumns;
        const placeholder = cellIndex !== 0 ? TYPE_ANSWER_HERE : null;
        const disabled = cellIndex === 0 ? true : false;
        const needToCaptureColumnWidths = cellIndex === 0 ? true : false;

        return { width, placeholder, textAlignCenter: false, enableAnswerTrigger: false, forceToEnter: true, disabled, needToCaptureColumnWidths , clearValueWhenHideFirstRow: true};
    }
};