import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';
import _ from 'underscore';

const initialState = {
  id: null,
  name: null,
  validName: true,
  startDate: null,
  validStartDate: true,
  endDate: null,
  validEndDate: true,
  filteredRecordedSymptom: [],
  filteredRecordedDosing: [],
  selectedIssues: [],
  selectedIssuesObj: {},
  selectedImages: [],
  selectedImagesObj: {},
  imagesTitle: null,
  graph: {
    id: null,
    name: null,
    symptoms: {},
    metrics: {},
    therapies: {},
    symptomProps: {},
    metricProps: {},
    therapyProps: {},
  },
  savedGraph: {},
  note: null,
  viewBy: null,
  symptomOpne: true,
  issueOpen: true,
  dosingOpen: true,
  appoinmentOpen: true,
  imageOpen: true,
  graphOpen: true,
  noteOpen: true,
  graphImage: null,
  graphImageS3: null,
  initHashValue: ''
};

const setTabOpen = (state, action) => {
  switch (action.tab) {
    case 1:
      return updateObject(state, {
        symptomOpne: action.open,
      });
    case 2:
      return updateObject(state, {
        issueOpen: action.open,
      });
    case 3:
      return updateObject(state, {
        dosingOpen: action.open,
      });
    case 4:
      return updateObject(state, {
        appoinmentOpen: action.open,
      });
    case 5:
      return updateObject(state, {
        imageOpen: action.open,
      });
    case 6:
      return updateObject(state, {
        graphOpen: action.open,
      });
    case 7:
      return updateObject(state, {
        noteOpen: action.open
      });
    default:
      return state;
  }
};

const updateReportDetail = (state, action) => {
  return updateObject(state, {
    name: action.name,
    validName: true,
    startDate: action.startDate,
    endDate: action.endDate,
    filteredRecordedSymptom: action.filteredRecordedSymptom,
    filteredRecordedDosing: action.filteredRecordedDosing,
    selectedIssues: action.selectedIssues,
    selectedIssuesObj: action.selectedIssuesObj,
    selectedImages: action.selectedImages,
    selectedImagesObj: action.selectedImagesObj,
    viewBy: action.viewBy,
    graphImageS3: action.graphid,
  });
};

const removeReportSymptom = (state, action) => {
  const filteredRecordedSymptom = state.filteredRecordedSymptom.filter(
    item => item.id !== action.id,
  );
  return updateObject(state, {
    filteredRecordedSymptom: filteredRecordedSymptom,
  });
};

const removeReportDosing = (state, action) => {
  const filteredRecordedDosing = state.filteredRecordedDosing.filter(
    item => item.id !== action.id,
  );
  return updateObject(state, {
    filteredRecordedDosing: filteredRecordedDosing,
  });
};

const addRemoveReportIssue = (state, action) => {
  const selectedIssuesObj = state.selectedIssuesObj;
  if (selectedIssuesObj[action.question.id]) {
    delete selectedIssuesObj[action.question.id];
  } else {
    selectedIssuesObj[action.question.id] = action.question;
  }
  return updateObject(state, {
    selectedIssuesObj: selectedIssuesObj,
  });
};

const addRemoveReportImage = (state, action) => {
  const selectedImagesObj = state.selectedImagesObj;
  if (selectedImagesObj[action.attachment.id]) {
    delete selectedImagesObj[action.attachment.id];
  } else {
    selectedImagesObj[action.attachment.id] = action.attachment;
  }
  return updateObject(state, {
    selectedImagesObj: selectedImagesObj,
  });
};

const saveReportIssue = state => {
  const selectedIssuesObj = state.selectedIssuesObj;
  let selectedIssues = [];
  Object.values(selectedIssuesObj).forEach(element =>
    selectedIssues.push(element),
  );

  return updateObject(state, {
    selectedIssues: selectedIssues,
  });
};

const saveReportImage = state => {
  const selectedImagesObj = state.selectedImagesObj;
  let selectedImages = [];
  Object.values(selectedImagesObj).forEach(element =>
    selectedImages.push(element),
  );

  return updateObject(state, {
    selectedImages: selectedImages,
  });
};

const removeReportIssue = (state, action) => {
  const selectedIssues = state.selectedIssues.filter(
    item => item.id !== action.id,
  );
  const selectedIssuesObj = state.selectedIssuesObj;
  if (selectedIssuesObj[action.id]) {
    delete selectedIssuesObj[action.id];
  }
  return updateObject(state, {
    selectedIssues: selectedIssues,
    selectedIssuesObj: selectedIssuesObj,
  });
};

const removeReportImage = (state, action) => {
  const selectedImages = state.selectedImages.filter(
    item => item.id !== action.id,
  );
  const selectedImagesObj = state.selectedImagesObj;
  if (selectedImagesObj[action.id]) {
    delete selectedImagesObj[action.id];
  }
  return updateObject(state, {
    selectedImages: selectedImages,
    selectedImagesObj: selectedImagesObj,
  });
};
const invalidReportName = state => {
  return updateObject(state, {
    validName: false,
  });
};

const updateImagesTitle = (state, action) => {
  return updateObject(state, {
    imagesTitle: action.title,
  });
};

const selectGraph = (state, action) => {
  return updateObject(state, {
    graph: action.graph,
  });
};

const removeGraph = state => {
  return updateObject(state, {
    savedGraph: {},
    graph: {
      id: null,
      name: null,
      symptoms: {},
      metrics: {},
      therapies: {},
      symptomProps: {},
      metricProps: {},
      therapyProps: {},
    },
    graphImageS3: null
  });
};
const updateReportNote = (state, action) => {
  return updateObject(state, {
    note: action.note,
  });
};

const resetReport = state => {
  return updateObject(state, {
    id: null,
    name: null,
    validName: true,
    startDate: null,
    validStartDate: true,
    endDate: null,
    validEndDate: true,
    filteredRecordedSymptom: [],
    filteredRecordedDosing: [],
    selectedIssues: [],
    selectedIssuesObj: {},
    selectedImages: [],
    selectedImagesObj: {},
    imagesTitle: null,
    note: null,
    savedGraph: {},
    viewBy: null,
    symptomOpne: true,
    issueOpen: true,
    dosingOpen: true,
    appoinmentOpen: true,
    imageOpen: true,
    graphOpen: true,
    noteOpen: true,
    graphImage: null,
    graphImageS3: null,
    graph: {
      id: null,
      name: null,
      symptoms: {},
      metrics: {},
      therapies: {},
      symptomProps: {},
      metricProps: {},
      therapyProps: {},
    },
    initHashValue: ''
  });
};

const selectReport = (state, action) => {
  const startDate = action.data.startDate
    ? new Date(action.data.startDate)
    : null;
  const endDate = action.data.endDate ? new Date(action.data.endDate) : null;
  let diffDays;
  if (startDate && endDate) {
    const diffTime = Math.abs(startDate.getTime() - endDate.getTime());
    diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  return updateObject(state, {
    ...action.data,
    id: action.id,
    startDate: startDate,
    endDate: endDate,
    selectedIssuesObj: convertToObject(action.data.selectedIssues),
    selectedImagesObj: convertToObject(action.data.selectedImages),
    graph: action.data.savedGraph ? {...action.data.savedGraph} : {},
    savedGraph: action.data.savedGraph ? action.data.savedGraph : {},
    symptomOpne: true,
    issueOpen: true,
    dosingOpen: true,
    appoinmentOpen: true,
    imageOpen: true,
    graphOpen: true,
    noteOpen: true,
    viewBy: !(startDate && endDate)
      ? 0
      : diffDays === 31
      ? 1
      : diffDays === 91
      ? 2
      : 3,
    graphImage: null,
  });
};

const convertToObject = array => {
  if (array) {
    const obj = {};
    array.forEach(element => (obj[element.id] = element));
    return obj;
  }
};

const addSymptom = (state, action) => {
  const symptoms = {...state.graph.symptoms};
  symptoms[action.symptom] = action.symptom;
  let graph = {...state.graph};
  if (_.isEmpty(graph)) {
    graph = {
      id: null,
      name: null,
      symptoms: {},
      metrics: {},
      therapies: {},
      symptomProps: {},
      metricProps: {},
      therapyProps: {},
    };
  }
  graph.symptoms = symptoms;
  return updateObject(state, {graph: graph});
};

const removeSymptom = (state, action) => {
  const symptoms = {...state.graph.symptoms};
  const symptomProps = {...state.graph.symptomProps};
  delete symptoms[action.symptom];
  delete symptomProps[action.symptom];
  const graph = {...state.graph};
  graph.symptoms = symptoms;
  graph.symptomProps = symptomProps;
  return updateObject(state, {graph: graph});
};

const addMetric = (state, action) => {
  const metrics = {...state.graph.metrics};
  metrics[action.metric] = action.metric;
  let graph = {...state.graph};
  if (_.isEmpty(graph)) {
    graph = {
      id: null,
      name: null,
      symptoms: {},
      metrics: {},
      therapies: {},
      symptomProps: {},
      metricProps: {},
      therapyProps: {},
    };
  }
  graph.metrics = metrics;
  return updateObject(state, {graph: graph});
};

const removeMetric = (state, action) => {
  const metrics = {...state.graph.metrics};
  const metricProps = {...state.graph.metricProps};
  delete metrics[action.metric];
  delete metricProps[action.metric];
  const graph = {...state.graph};
  graph.metrics = metrics;
  graph.metricProps = metricProps;
  return updateObject(state, {graph: graph, graphImage: null});
};

const addTherapy = (state, action) => {
  const therapies = {...state.graph.therapies};
  therapies[action.therapy] = action.therapy;
  let graph = {...state.graph};
  if (_.isEmpty(graph)) {
    graph = {
      id: null,
      name: null,
      symptoms: {},
      metrics: {},
      therapies: {},
      symptomProps: {},
      metricProps: {},
      therapyProps: {},
    };
  }
  graph.therapies = therapies;
  return updateObject(state, {graph: graph});
};

const removeTherapy = (state, action) => {
  const therapies = {...state.graph.therapies};
  const therapyProps = {...state.graph.therapyProps};
  delete therapies[action.therapy];
  delete therapyProps[action.therapy];
  const graph = {...state.graph};
  graph.therapies = therapies;
  graph.therapyProps = therapyProps;
  return updateObject(state, {graph: graph});
};

const saveReportGraph = state => {
  return updateObject(state, {savedGraph: state.graph});
};

const setGraphImage = (state, action) => {
  return updateObject(state, {graphImageS3: action.graphid});
};

const saveReportSymptom = (state, action) => {
  const addList = action.addList;
  const removeList = action.removeList;

  let filteredRecordedSymptom = [...state.filteredRecordedSymptom];
  if (removeList) {
    removeList.forEach(element => {
      filteredRecordedSymptom = filteredRecordedSymptom.filter(
        res => element.id !== res.id,
      );
    });
  }
  if (addList) {
    addList.forEach(element => {
      filteredRecordedSymptom.push(element);
    });
  }
  return updateObject(state, {
    filteredRecordedSymptom: filteredRecordedSymptom,
  });
};

const saveReportMedicationsTherapie = (state, action) => {
  const addList = action.addList;
  const removeList = action.removeList;
  let filteredRecordedDosing = [...state.filteredRecordedDosing];
  if (removeList) {
    removeList.forEach(element => {
      filteredRecordedDosing = filteredRecordedDosing.filter(
        res => element.id !== res.id,
      );
    });
  }
  if (addList) {
    addList.forEach(element => {
      filteredRecordedDosing.push(element);
    });
  }
  return updateObject(state, {
    filteredRecordedDosing: filteredRecordedDosing,
  });
};

const cancelGraph = state => {
  return updateObject(state, {graph: state.savedGraph});
};

const setInitHashValue = (state, action) => {
  return updateObject(state, { 
    graph: state.savedGraph,
    initHashValue: action.initHashValue 
  });
};

const updateReportMeds = (state, action) => {
  let moddedMeds = state.filteredRecordedDosing.map((med) => {
    if(med.userTreatment === action.selectedTherapie.id) {
      med = {...med, ...action.selectedTherapie.dosages[0]}
      return med;
    }

    return med;
  });

  return updateObject(state, {
    filteredRecordedDosing: moddedMeds
  });
}

const updateSymptomProps = (state, action) => {
  const symptomProps = { ...state.graph.symptomProps };
  symptomProps[action.symptomId] = action.symptomProps;
  let graph = {...state.graph};
  if (_.isEmpty(graph)) {
    graph = {
      id: null,
      name: null,
      symptoms: {},
      metrics: {},
      therapies: {},
      symptomProps: {},
      metricProps: {},
      therapyProps: {},
    };
  }
  graph.symptomProps = symptomProps;
  return updateObject(state, {graph: graph});
};

const updateMetricProps = (state, action) => {
  const metricProps = { ...state.graph.metricProps };
  metricProps[action.metricId] = action.metricProps;
  let graph = {...state.graph};
  if (_.isEmpty(graph)) {
    graph = {
      id: null,
      name: null,
      symptoms: {},
      metrics: {},
      therapies: {},
      symptomProps: {},
      metricProps: {},
      therapyProps: {},
    };
  }
  graph.metricProps = metricProps;
  return updateObject(state, {graph: graph});
};

const updateTherapyProps = (state, action) => {
  const therapyProps = { ...state.graph.therapyProps };
  therapyProps[action.therapyId] = action.therapyProps;
  let graph = {...state.graph};
  if (_.isEmpty(graph)) {
    graph = {
      id: null,
      name: null,
      symptoms: {},
      metrics: {},
      therapies: {},
      symptomProps: {},
      metricProps: {},
      therapyProps: {},
    };
  }
  graph.therapyProps = therapyProps;
  return updateObject(state, {graph: graph});
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_REPORT_DETAIL:
      return updateReportDetail(state, action);
    case actionTypes.REMOVE_REPORT_SYMPTOM:
      return removeReportSymptom(state, action);
    case actionTypes.REMOVE_REPORT_DOSING:
      return removeReportDosing(state, action);
    case actionTypes.ADD_REMOVE_REPORT_ISSUE:
      return addRemoveReportIssue(state, action);
    case actionTypes.ADD_REMOVE_REPORT_IMAGE:
      return addRemoveReportImage(state, action);
    case actionTypes.SAVE_REPORT_ISSUE:
      return saveReportIssue(state);
    case actionTypes.SAVE_REPORT_IMAGE:
      return saveReportImage(state);
    case actionTypes.REMOVE_REPORT_ISSUE:
      return removeReportIssue(state, action);
    case actionTypes.REMOVE_REPORT_IMAGE:
      return removeReportImage(state, action);
    case actionTypes.INVALID_REPORT_NAME:
      return invalidReportName(state);
    case actionTypes.UPDATE_REPORT_IMAGES_TITLE:
      return updateImagesTitle(state, action);
    case actionTypes.SELECT_REPORT_GRAPH:
      return selectGraph(state, action);
    case actionTypes.REMOVR_REPORT_GRAPH:
      return removeGraph(state, action);
    case actionTypes.UPDATE_REPORT_NOTE:
      return updateReportNote(state, action);
    case actionTypes.RESET_REPORT:
      return resetReport(state, action);
    case actionTypes.SELECT_REPORT:
      return selectReport(state, action);
    case actionTypes.REPORT_GRAPH_ADD_SYMPTOM:
      return addSymptom(state, action);
    case actionTypes.REPORT_GRAPH_REMOVE_SYMPTOM:
      return removeSymptom(state, action);
    case actionTypes.REPORT_GRAPH_ADD_METRIC:
      return addMetric(state, action);
    case actionTypes.REPORT_GRAPH_REMOVE_METRIC:
      return removeMetric(state, action);
    case actionTypes.REPORT_GRAPH_ADD_THERAPY:
      return addTherapy(state, action);
    case actionTypes.REPORT_GRAPH_REMOVE_THERAPY:
      return removeTherapy(state, action);
    case actionTypes.SAVE_REPORT_GRAPH:
      return saveReportGraph(state);
    case actionTypes.SET_OPEN_TAB:
      return setTabOpen(state, action);
    case actionTypes.SET_GRAPH_IMAGE:
      return setGraphImage(state, action);
    case actionTypes.SAVE_REPORT_SYMPTOM:
      return saveReportSymptom(state, action);
    case actionTypes.SAVE_REPORT_MEDICATION_THERAPIE:
      return saveReportMedicationsTherapie(state, action);
    case actionTypes.CANCEL_GRAPH:
      return cancelGraph(state);
    case actionTypes.SET_INIT_HASH_VALUE:
      return setInitHashValue(state, action);
    case actionTypes.UPDATE_THERAPIE_SUCCESS:
       return updateReportMeds(state, action);
    case actionTypes.REPORT_GRAPH_UPDATE_SYMPTOM_PROPS:
      return updateSymptomProps(state, action);
    case actionTypes.REPORT_GRAPH_UPDATE_METRIC_PROPS:
      return updateMetricProps(state, action);
    case actionTypes.REPORT_GRAPH_UPDATE_THERAPY_PROPS:
      return updateTherapyProps(state, action);  
    default:
      return state;
  }
};

export default reducer;
