import React from "react";
import { View, Text, TouchableOpacity, ScrollView, Image } from "react-native";
import styles from "../../../../Css";
import Header from "../../../Header/header";
import SpaceBar from "../../../SpaceBar/SpaceBar";
import PageContainer from "../../../PageLayout/PageContainer";
import CardItem from "../../Card/CardItem";
import SearchField from "../../../Search/searchField";
import ConnectionRequestPopup from "../../ConfirmationBox/connectionRequestPopup";
import MakeprofilePublicPopup from "../../ConfirmationBox/makeProfilePublicPopup";
import SuccessMessagePopup from "../../ConfirmationBox/successMessagePopup";
import ReferFriendBlock from "./referFriendBlock";
import { getScrollHeight } from '../../../../util/commonUiLogic';

const SearchResultsComponent = (props) => {
  const {
    index,
    navigation,
    handleOnPressBackButton,
    searchedList,
    totalMatchedResults,
    criteriaText,
    onPressEditSearch,
    onPressCancelSearch,
    onPressReferFriend,
    onSearchInputValueChange,
    searchedKeyword,
    windowHeight,
    windowWidth,
    journalUserId,
    hasNotch,
    selectedConditionList,
    onPressConnect,
    visibleConnectionRequestPopup,
    visibleMakeprofilePublicPopup,
    visibleSuccessMessage,
    hidePopup,
    selectedUser,
    onUpdateConnectionStatus,
    route,
    timeZoneWarning,
    timeZone
  } = props;
  
  let btnHeight = 130;
  if(!searchedKeyword && totalMatchedResults === 0) {
    btnHeight = 0;
  }
  let scrollHeight = getScrollHeight(hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth, btnHeight);

  if (scrollHeight < 50) {
    scrollHeight = 50;
  }
  return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showHome={false}
        showNotify={true}
        hideCaregiverAccess={true}
        handleOnPressBackButton={handleOnPressBackButton}
        index={index}
        navigation={navigation}
        route={route}
      />
      <SpaceBar />
      <View style={{ height: scrollHeight }}>
      <ScrollView style={styles.scrollViewArea}>
        <PageContainer>
           {(searchedKeyword || totalMatchedResults !== 0) ? ( 
            <View style={styles.gridSection}>
              <View style={styles.gridRow}>
                <View style={[styles.gridColFull]}>
                  <View style={[styles.colPdTopCom]}>
                    <View style={[styles.searchSection]}>
                      <View style={styles.fieldRowWrapperSm}>
                        <View style={styles.fieldColSearch}>
                          <SearchField
                            searchText={
                              searchedKeyword ? searchedKeyword : ""
                            }
                            placeholder="Name/ Keyword"
                            onChangeText={onSearchInputValueChange}
                            showClearBtton={
                              searchedKeyword && searchedKeyword !== ""
                                ? true
                                : false
                            }
                            clearFilter={() => onSearchInputValueChange(null)}
                          />
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.secArea,
                        styles.flexJcCtAiCt,
                        styles.colPdBotLg,
                      ]}
                    >
                      <View style={[styles.textFullWrapper, styles.pdTopSm]}>
                        <Text style={[styles.textTitleBold, styles.colPdBotExSm]} allowFontScaling= {false}>
                          {totalMatchedResults} Results found
                        </Text>
                      </View>
                      
                      <TouchableOpacity onPress={onPressEditSearch}>
                        <View style={styles.comSp}>
                          <Text
                            style={[
                              windowWidth > 1366
                              ? styles.textPrimeBold
                              : styles.textPrimeSmBold,
                              styles.textAlignCenter,
                              styles.textUnderline,
                              styles.textlinkText,
                            ]}
                            allowFontScaling= {false}
                          >
                            Criteria: {criteriaText}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View style={[styles.secArea]}>
                      <View style={styles.cardContainer}>
                        {searchedList &&
                          searchedList.length > 0 &&
                          searchedList.map((element, index) => (
                            <CardItem
                              key={index}
                              peerProfile={element}
                              displayInfo={true}
                              displayStatusButton={true}
                              isExpand={element.isExpand ? element.isExpand : false}
                              searchedKeyword={searchedKeyword}
                              selectedConditionList={selectedConditionList}
                              onPressConnect={onPressConnect}
                            />
                          ))}
                      </View>
                    </View>
                  
                  </View>
                </View>
              </View>
            </View>
          ) : (
            <ReferFriendBlock 
              windowWidth={windowWidth}
              criteriaText={criteriaText}
              onPressEditSearch={onPressEditSearch}
              onPressReferFriend={onPressReferFriend}
            />
          )} 
        </PageContainer>
      </ScrollView>
      </View>
      {(searchedKeyword || totalMatchedResults !== 0)  && (
        <PageContainer smScreenFull={true}>
          <View style={[styles.greyBorderThin, styles.colPdTopBotLg, styles.topPdSm]} />
          <View style={[styles.secArea, styles.pdBotLg]}>
            <View style={styles.btnArea}>
              <View style={styles.btnBlock}>
                <TouchableOpacity onPress={onPressCancelSearch}>
                  <View
                    style={[
                      styles.submitMd,
                      styles.btnShadowCom,
                      styles.submitBgAshDark,
                      styles.flexRow,
                      styles.flexJcCtAiCt,
                    ]}
                  >
                    <View style={styles.crossIconWrapper}>
                      <View style={styles.crossIconInner}>
                      <Image
                      style={[styles.crossCancelIcon]}
                      source={require("../../../../assets/peer-to-peer/circle-cancel.png")}
                    />
                      </View>
                    </View>
                    <Text style={[styles.textPrimeBold, styles.textWhite]} allowFontScaling= {false}>
                      Cancel Search
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </PageContainer>
      )}
      {visibleConnectionRequestPopup &&
        <ConnectionRequestPopup 
          visibleModal={visibleConnectionRequestPopup}
          hidePopup={hidePopup}
          selectedUser={selectedUser}
          onUpdateConnectionStatus={onUpdateConnectionStatus}
        />
      }
      {visibleMakeprofilePublicPopup &&
        <MakeprofilePublicPopup 
          visibleModal={visibleMakeprofilePublicPopup}
          hidePopup={hidePopup}
          selectedUser={selectedUser}
          onUpdateConnectionStatus={onUpdateConnectionStatus}
        />
      }
      {visibleSuccessMessage &&
        <SuccessMessagePopup 
          visibleModal={visibleSuccessMessage}
          hidePopup={hidePopup}
          message={'Connect with'}
          messageTitle={'Message Sent'}
          selectedUser={selectedUser}
        />
      }
    </View>
  );
};
export default (SearchResultsComponent);
