import React, {Component} from 'react';
import {View, Text} from 'react-native';
import _ from 'lodash';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import moment from 'moment';
import Tabs from '../../components/Tab/Tab';
import Header from '../../components/Header/header';
import ConfirmationBox from '../../components/ConfirmationBox/confirmationBox';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import RutineItemScrollView from './RutineItemScrollView';
import {JOURNALS, DASHBOARD} from '../../navigation/path';
import PageContainer from '../../components/PageLayout/PageContainer';
import SectionTitle from '../../components/Text/sectionTitle';
import PlusButtonDark from '../../components/Button/plusButtonDark';
import SearchField from '../../components/Search/searchField';
import ImageDocSearch from '../../components/Search/imageDocSearch';
import NoResultFound from '../../components/Search/noResultFound';
import {getAccountType} from '../../util/subscriptionInformation';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import NoJournalFound from '../../components/Search/noJournalFound';
import MessageOverlay from '../../components/MessageRibbon/messageOverlay';
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { TAB_BREAk_MAX_WIDTH } from '../../util/platformWindowConfig';
import { getScrollHeight } from '../../util/commonUiLogic';

let routinesRef = null;
class ListRoutine extends Component {
  constructor(props) {
    super(props);
    const journalEntries = props.journalEntriesProcessed ? Object.values(props.journalEntriesProcessed) : [];

    const allJournal = ListRoutine.getJournal(
      journalEntries,
      props.journalEntryTypesProcessed,
    );
    // const dateHighlight = ListRoutine.getDateHighlight(allJournal);
    let selectedDateCheck = new Date();
    if (this.props.selectedDate !== null) {
      selectedDateCheck = props.selectedDate;
    }

    let filterJournalEntries = [];
    let filterFavouritesJournalEntries = [];
    this.searchTextMap = {};
    const accountType = getAccountType(props.userSubscriptions);
    this.state = {
      journalEntries: allJournal,
      journalEntryTypes: props.journalEntryTypes,
      filterJournalEntries: filterJournalEntries,
      filterFavouritesJournalEntries: filterFavouritesJournalEntries,
      // dateHighlight: dateHighlight,
      filterOn: false,
      deleteConfirm: false,
      selectedDeleteId: null,
      deleteWait: false,
      treatmentsProcessed: props.treatmentsProcessed,
      symptomsDigestProcessed: props.symptomsDigestProcessed,
      metricsDigestProcessed: props.metricsDigestProcessed,

      // Selected Date
      selectedDate: selectedDateCheck,
      filterWait: false,
      obj: null,
      metricFil: null,
      treatmentFil: null,
      symptomFil: null,

      imageSerachFinish: false,
      accountType: accountType,
      searchKeyword: '',
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      activeTab: 0,
    };
    if (accountType === 'premium') {
      this.onTicked(true);
    } else {
      this.onTicked(false);
    }
  }

  compareArrays = (a, b) => {
    if ((a && !b) || (!a && b)) return false;
    if (!a && !b) {
      return true;
    }
    if (a.length !== b.length) {
      return false;
    }
    if (!a.every((element, index) => element === b[index])) {
      return false;
    }
    return true;
  };

  compareJournalEntryArrays = (a, b) => {
    if (!this.compareArrays(a, b)) {
      return false;
    }

    return a.every((element, index) => {
      return element.title === b[index].title && element.entryDate === b[index].entryDate && element.flagged === b[index].flagged;
    });
  };

  shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
    let stateChanged = false;
    if (this.state.activeTab !== nextState.activeTab) {
      stateChanged = true;
    }

    if (this.state.filterOn !== nextState.filterOn) {
      stateChanged = true;
    }

    if (this.state.deleteConfirm !== nextState.deleteConfirm) {
      stateChanged = true;
    }

    if (this.state.deleteWait !== nextState.deleteWait) {
      stateChanged = true;
    }

    if (this.state.selectedDeleteId !== nextState.selectedDeleteId) {
      stateChanged = true;
    }

    // Selected date isn't used anywhere so shouldn't impact the comparison
    // if (this.state.selectedDate !== nextState.selectedDate) {
    //   console.log("changed selectedDate: " + this.state.selectedDate + "; " + nextState.selectedDate);
    //   stateChanged = true;
    // }

    if (this.state.filterWait !== nextState.filterWait) {
      stateChanged = true;
    }

    if (this.state.imageSerachFinish !== nextState.imageSerachFinish) {
      stateChanged = true;
    }

    if (this.state.searchKeyword !== nextState.searchKeyword) {
      stateChanged = true;
    }

    if (this.state.searchAttachmentContent !== nextState.searchAttachmentContent) {
      stateChanged = true;
    }

    if (this.state.windowWidth !== nextState.windowWidth) {
      stateChanged = true;
    }

    if (this.state.windowHeight !== nextState.windowHeight) {
      stateChanged = true;
    }

    if (this.state.activeTab === 0) {
      if (!this.compareArrays(Object.keys(this.props.journalEntriesProcessed), Object.keys(nextProps.journalEntriesProcessed))) {
        stateChanged = true;
      }

      if (!this.compareJournalEntryArrays(this.state.journalEntries, nextState.journalEntries)) {
        stateChanged = true;
      }

      if (!this.compareArrays(this.props.filterJournalEntries, nextProps.filterJournalEntries)) {
        stateChanged = true;
      }
    } else {

      if (!this.compareArrays(Object.keys(this.props.journalEntriesProcessed), Object.keys(nextProps.journalEntriesProcessed))) {
        stateChanged = true;
      }

      if (!this.compareJournalEntryArrays(this.state.journalEntries, nextState.journalEntries)) {
        stateChanged = true;
      }
      if (!this.compareArrays(this.props.filterFavouritesJournalEntries, nextProps.filterFavouritesJournalEntries)) {
        stateChanged = true;
      }
    }


    // We don't need to check journal entry types - it is used for a filter for this view
    // Leaving as a comment so it isn't added later
    // if (!this.compareArrays(this.props.journalEntryTypes, nextProps.JournalEntryTypes)) {
    //   stateChanged = true;
    // }

    // We don't need to check treatmentsProcessed - it is used for a filter for this view
    // Leaving as a comment so it isn't added later
    // if (!this.compareArrays(this.props.treatmentsProcessed, nextProps.treatmentsProcessed)) {
    //   stateChanged = true;
    // }

    // We don't need to check symptomsDigestProcessed - it is used for a filter for this view
    // Leaving as a comment so it isn't added later
    // if (!this.compareArrays(this.props.symptomsDigestProcessed, nextProps.symptomsDigestProcessed)) {
    //   stateChanged = true;
    // }

    // We don't need to check metricsDigestProcessed - it is used for a filter for this view
    // Leaving as a comment so it isn't added later
    // if (!this.compareArrays(this.props.metricsDigestProcessed, nextProps.metricsDigestProcessed)) {
    //   stateChanged = true;
    // }

    // We don't need to compare metricFil, treatmentFil, symptomFil, etc as their result would show up in the
    // filter arrays above

    return stateChanged;
  }

  componentDidMount = () => {
    const {
      journalEntryFilter,
      selectedDate,
      windowWidth,
      windowHeight,
      journalEntrySelected,
      setNewJoural,
    } = this.props;
    let selectedDateCheck = new Date();
    if (selectedDate !== null) {
      selectedDateCheck = selectedDate;
    }

    let journalEntryFilterObj = {};
    let filterJournalEntries = [];
    let filterFavouritesJournalEntries = [];
    let filterWait = false;
    let searchKeyword = null;
    if (
      journalEntryFilter &&
      journalEntryFilter.searchKeyword &&
      journalEntryFilter.searchKeyword !== '' &&
      journalEntryFilter.ticked
    ) {
      searchKeyword = journalEntryFilter.searchKeyword;
      this.setState({
        searchKeyword: searchKeyword,
        searchAttachmentContent: journalEntryFilter.ticked,
      });
    } else if (
      journalEntryFilter &&
      journalEntryFilter.searchKeyword &&
      journalEntryFilter.searchKeyword !== ''
    ) {
      journalEntryFilterObj = ListRoutine.handleFilter(
        journalEntryFilter,
        this.state.journalEntries,
        this.props,
      );

      searchKeyword = journalEntryFilter.searchKeyword;
      this.setState({
        ...journalEntryFilterObj,
        filterWait,
        searchKeyword: searchKeyword,
      });
    } else if (
      !journalEntryFilter ||
      (journalEntryFilter &&
        (!journalEntryFilter.searchKeyword ||
          journalEntryFilter.searchKeyword === '') &&
        this.state.journalEntries &&
        this.state.journalEntries.length > 0)
    ) {
      filterJournalEntries = ListRoutine.sortBydate(this.state.journalEntries);
      if (
        !journalEntrySelected &&
        filterJournalEntries &&
        filterJournalEntries.length > 0
      ) {
        let selectedJournal = filterJournalEntries[0];
        const firstJournal = this.getFirstJournal();
        if (firstJournal) {
          selectedJournal = firstJournal;
        }
        this.props.onSetSelectedJournalEntry(selectedJournal);
        this.props.setViewRoutine(true);
        if (windowWidth > TAB_BREAk_MAX_WIDTH) this.props.navigation.setParams({
          journalPath: setNewJoural ? JOURNALS.addJournalPathSuffix : JOURNALS.viewJournalPathSuffix,
          journalName: selectedJournal?.title
        });
      }

      const favouriteJournals = ListRoutine.filterFavouritesJournalEntries(
        this.state.journalEntries,
      );
      filterFavouritesJournalEntries = ListRoutine.sortBydate(
        favouriteJournals,
      );
    } else if (
      this.state.journalEntries &&
      this.state.journalEntries.length === 0
    ) {
      this.props.onSetSelectedJournalEntry(null);
      this.props.setViewRoutine(true);
      this.props.navigation.setParams({
        journalPath: '',
        journalName: ''
      });
    }

    this.setState({
      filterJournalEntries,
      filterFavouritesJournalEntries,
      selectedDate: selectedDateCheck,
      filterWait,
      searchKeyword: searchKeyword,
      windowWidth: windowWidth,
      windowHeight: windowHeight,
    });

    this.props.navigation.addListener('blur', () => {
      this.props.navigation.setParams({from: JOURNALS.path});
    });
    this.props.navigation.addListener('focus', () => {
      if (
        this.props.journalEntryFilter &&
        this.props.journalEntryFilter.searchKeyword &&
        this.props.journalEntryFilter.searchKeyword !== '' 
      ) {
        this.handleOnPressClearFilters();
      }
    });
  };

  getFirstJournal = () => {
    if (routinesRef) {
      const firstJournal = routinesRef.getFirstJournal();
      if (firstJournal) {
        return firstJournal;
      }
    }
    return null;
  }

  static getJournal = (journalEntries, journalEntryTypesProcessed) => {
    return journalEntries.filter(function(journalEntrie) {
      return (
        journalEntryTypesProcessed[journalEntrie.entryType] &&
        !journalEntryTypesProcessed[journalEntrie.entryType].isAppointment
      );
    });
  };

  static getDerivedStateFromProps(props, state) {
    const {
      journalEntryFilter,
      selectedDate,
      journalEntriesProcessed,
      journalEntryTypesProcessed,
      windowHeight,
      windowWidth,
      deletedJournal,
      setView,
      navigation,
      route,
      setNewJoural
    } = props;

    const journalEntries = Object.values(journalEntriesProcessed);

    if (windowWidth && !setView && !setNewJoural && windowWidth > TAB_BREAk_MAX_WIDTH) {
      navigation.navigate(JOURNALS.path, {
        from: route.name
      })
    }
    const allJournal = ListRoutine.getJournal(
      journalEntries,
      journalEntryTypesProcessed,
    );
    // const dateHighlight = ListRoutine.getDateHighlight(allJournal);
    let selectedDateCheck = new Date();
    if (selectedDate !== null) {
      selectedDateCheck = selectedDate;
    }

    if (deletedJournal) {
      const {
        filterJournalEntries,
        filterFavouritesJournalEntries,
        activeTab,
      } = state;
      if (routinesRef) {
        routinesRef.refresh();
      }
      if (activeTab === 0) {
        if (filterJournalEntries && filterJournalEntries.length > 0) {
          let selectedJournal = filterJournalEntries[0];
          if (routinesRef) {
            const firstJournal = routinesRef.getFirstJournal();
            if (firstJournal) {
              selectedJournal = firstJournal;
            }
          }
          props.onSetSelectedJournalEntry(selectedJournal);
          props.setViewRoutine(true);
          props.navigation.setParams({
            journalPath: JOURNALS.viewJournalPathSuffix,
            journalName: selectedJournal?.title
          });
        } else {
          props.onSetSelectedJournalEntry(null);
          props.setViewRoutine(true);
          props.navigation.setParams({
            journalPath: '',
            journalName: ''
          });
        }
      } else {
        if (
          filterFavouritesJournalEntries &&
          filterFavouritesJournalEntries.length > 0
        ) {
          let selectedFoviuriteJournal = filterFavouritesJournalEntries[0];
          if (routinesRef) {
            const firstFovouriteJournal = routinesRef.getFirstJournal();
            if (firstFovouriteJournal) {
              selectedFoviuriteJournal = firstFovouriteJournal;
            }
          }
          props.onSetSelectedJournalEntry(selectedFoviuriteJournal);
          props.setViewRoutine(true);
          props.navigation.setParams({
            journalPath: JOURNALS.viewJournalPathSuffix,
            journalName: selectedFoviuriteJournal?.title
          });
        } else {
          props.onSetSelectedJournalEntry(null);
          props.setViewRoutine(true);
          props.navigation.setParams({
            journalPath: '',
            journalName: ''
          });
        }
      }
    }

    let journalEntryFilterObj = {};
    let filterJournalEntries = allJournal;
    let filterFavouritesJournalEntries = [];
    let filterWait = false;
    let searchKeyword = null;
    if (state.deleteWait || state.deleteConfirm) {
      return null;
    } else if (state.imageSerachFinish) {
      return {
        imageSerachFinish: false,
        windowWidth: windowWidth,
        windowHeight: windowHeight,
      };
    } else if (
      journalEntryFilter &&
      journalEntryFilter.searchKeyword &&
      journalEntryFilter.searchKeyword !== '' &&
      journalEntryFilter.ticked &&
      !state.imageSerachFinish
    ) {
      searchKeyword = journalEntryFilter.searchKeyword;
      return {
        searchKeyword: searchKeyword,
        searchAttachmentContent: journalEntryFilter.ticked,
        journalEntries: allJournal,
        windowWidth: windowWidth,
        windowHeight: windowHeight,
      };
    } else if (
      journalEntryFilter &&
      journalEntryFilter.searchKeyword &&
      journalEntryFilter.searchKeyword !== ''
    ) {
      let filterList = allJournal;
      if (
        state.filterJournalEntries &&
        state.filterJournalEntries.length > 0 &&
        state.searchKeyword &&
        state.searchKeyword.length < journalEntryFilter.searchKeyword.length
      ) {
        filterList = state.filterJournalEntries;
      }
      journalEntryFilterObj = ListRoutine.handleFilter(
        journalEntryFilter,
        filterList,
        props,
      );

      searchKeyword = journalEntryFilter.searchKeyword;
      return {
        ...journalEntryFilterObj,
        filterWait,
        searchKeyword: searchKeyword,
        journalEntries: allJournal,
        // dateHighlight: dateHighlight,
        windowWidth: windowWidth,
        windowHeight: windowHeight,
      };
    } else if (
      !journalEntryFilter ||
      (journalEntryFilter &&
        (!journalEntryFilter.searchKeyword ||
          journalEntryFilter.searchKeyword === '') &&
        allJournal &&
        allJournal.length > 0)
    ) {
      filterJournalEntries = ListRoutine.sortBydate(allJournal);
      const favouriteJournals = ListRoutine.filterFavouritesJournalEntries(
        allJournal,
      );
      filterFavouritesJournalEntries = ListRoutine.sortBydate(
        favouriteJournals,
      );
    }

    return {
      filterJournalEntries,
      filterFavouritesJournalEntries,
      selectedDate: selectedDateCheck,
      filterWait,
      searchKeyword: searchKeyword,
      journalEntries: allJournal,
      // dateHighlight: dateHighlight,
      windowWidth: windowWidth,
      windowHeight: windowHeight,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      searchKeyword, 
      searchAttachmentContent
    } = this.state;
    if (prevState.searchKeyword !== searchKeyword || prevState.searchAttachmentContent !== searchAttachmentContent) {
      this.imageSerach();
    }
  }

  handleOnPressAttachments = selectedJournalEntry => {
    this.props.onSetSelectedJournalEntry(selectedJournalEntry);
    this.props.navigation.setParams({
      journalPath: JOURNALS.viewJournalPathSuffix,
      journalName: selectedJournalEntry?.title
    });
    // this.props.setViewRoutine(false)
    if (
      this.props.showListRoutineHeader &&
      this.props.route &&
      this.props.route.name
    ) {
      this.props.navigation.push(
        JOURNALS.viewJournalPath, {
          from: this.props.route.name,
          journalName: selectedJournalEntry?.title
        },
      );
    }
  };

  handleOnChangeflagged = (selectedJournalEntry, flagged) => {
    this.props.onSaveJournalEntry(
      {journalEntry: {...selectedJournalEntry, flagged}},
      () => {},
      this.props.journalUserId,
    );
  };

  handleOnPressClearFilters = () => {
    this.props.onClearJournalEntryFilte();
  };

  static filterJournalEntriesBySearchKeyword = (
    journalEntries,
    searchKeyword,
  ) => {
    if (journalEntries) {
      return journalEntries.filter(function(journalEntrie) {
        // return journalEntrie.title.includes(searchKeyword);
        return journalEntrie.title
          .toLowerCase()
          .includes(searchKeyword.toLowerCase());
      });
    }
  };

  static filterJournalEntriesByDate = (journalEntries, searchKeyword) => {
    let dateSearchList = [];
    if (journalEntries) {
      journalEntries.forEach(journalEntrie => {
        let date = new moment(journalEntrie.entryDate).format('DD MMM YY');
        let date2 = new moment(journalEntrie.entryDate).format('DD MMM YYYY');
        if (
          date.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          date2.toLowerCase().includes(searchKeyword.toLowerCase())
        ) {
          dateSearchList.push(journalEntrie);
        }
      });
    }
    return dateSearchList;
  };

  static filterJournalEntriesNotesBySearchKeyword = (
    journalEntries,
    searchKeyword,
  ) => {
    if (journalEntries) {
      return journalEntries.filter(
        res =>
          res.entry &&
          res.entry.toLowerCase().includes(searchKeyword.toLowerCase()),
      );
    }
  };

  static filterJournalEntriesQuestionBySearchKeyword = (
    journalEntries,
    searchKeyword,
    props,
  ) => {
    if (journalEntries) {
      return journalEntries.filter(res => {
        const qList = props.questionDigest.userArtifactHealthQuestions.filter(
          re =>
            res.artifactId === re.artifactId &&
            props.questionDigestProcessed.questions[re.questionId] &&
            (props.questionDigestProcessed.questions[re.questionId].content
              .toLowerCase()
              .includes(searchKeyword.toLowerCase()) ||
              (props.questionDigestProcessed.questions[re.questionId].answers &&
                props.questionDigestProcessed.questions[re.questionId].answers
                  .length > 0 &&
                props.questionDigestProcessed.questions[
                  re.questionId
                ].answers.find(answer =>
                  answer.content
                    .toLowerCase()
                    .includes(searchKeyword.toLowerCase()),
                ))),
        );

        return qList && qList.length > 0;
      });
    }
  };

  static filterJournalEntriesMetricBySearchKeyword = (
    journalEntries,
    searchKeyword,
    metricsDigestProcessed,
  ) => {
    if (journalEntries) {
      return journalEntries.filter(res => {
        let metrics = [];
        if (res.metrics && res.metrics.length > 0) {
          metrics = res.metrics.filter(
            re =>
              metricsDigestProcessed.selectableMetrics[re.metric] &&
              metricsDigestProcessed.selectableMetrics[re.metric].name
                .toLowerCase()
                .includes(searchKeyword.toLowerCase()),
          );
        }
        return metrics && metrics.length > 0;
      });
    }
  };

  static filterJournalEntrieSymptomsBySearchKeyword = (
    journalEntries,
    searchKeyword,
    symptomsDigestProcessed,
  ) => {
    if (journalEntries) {
      return journalEntries.filter(res => {
        let symptoms = [];
        if (res.symptoms && res.symptoms.length > 0) {
          symptoms = res.symptoms.filter(
            re =>
              symptomsDigestProcessed.userSymptoms[re.symptom] &&
              symptomsDigestProcessed.userSymptoms[re.symptom].name
                .toLowerCase()
                .includes(searchKeyword.toLowerCase()),
          );
        }
        return symptoms && symptoms.length > 0;
      });
    }
  };

  static filterJournalEntrieTreatmentBySearchKeyword = (
    journalEntries,
    searchKeyword,
    treatmentsProcessed,
  ) => {
    if (journalEntries) {
      return journalEntries.filter(res => {
        let dosages = [];
        if (res.dosages && res.dosages.length > 0) {
          dosages = res.dosages.filter(
            re =>
              treatmentsProcessed[re.treatment] &&
              treatmentsProcessed[re.treatment].name
                .toLowerCase()
                .includes(searchKeyword.toLowerCase()),
          );
        }
        return dosages && dosages.length > 0;
      });
    }
  };

  static filterFavouritesJournalEntriesBySearchKeyword = (
    journalEntries,
    searchKeyword,
  ) => {
    if (journalEntries) {
      return journalEntries.filter(function(journalEntrie) {
        return (
          journalEntrie.title
            .toLowerCase()
            .includes(searchKeyword.toLowerCase()) && journalEntrie.flagged
        );
      });
    }
  };

  static sortBydate = filterJournalEntries => {
    filterJournalEntries.sort(function(a, b) {
      return b.modifiedOn - a.modifiedOn;
    }).sort(function(a, b) {
      return b.entryDate - a.entryDate;
    });
    return filterJournalEntries;
  };

  static filterFavouritesJournalEntries = journalEntries => {
    if (journalEntries) {
      return journalEntries.filter(function(journalEntrie) {
        return journalEntrie.flagged;
      });
    }
  };

  static handleFilter = (filter, journalEntries, props) => {
    let filterJournalEntries = [];
    let filterFavouritesJournalEntries = [];
    let metricFil = [];
    let symptomFil = [];
    let treatmentFil = [];
    let notesFilter = [];
    let questionFilter = [];
    let dateFilterJournalEntries = [];
    if (
      filter &&
      filter.searchKeyword &&
      filter.searchKeyword !== '' &&
      journalEntries
    ) {
      const data = ListRoutine.keyWorldSearch(
        journalEntries,
        filter.searchKeyword,
        props,
      );

      filterJournalEntries = data.filterJournalEntries;
      filterFavouritesJournalEntries = data.filterFavouritesJournalEntries;
      metricFil = data.metricFil;
      symptomFil = data.symptomFil;
      treatmentFil = data.treatmentFil;
      notesFilter = data.notesFilter;
      questionFilter = data.questionFilter;
      dateFilterJournalEntries = data.dateFilterJournalEntries;
    } else if (journalEntries) {
      filterJournalEntries = journalEntries;
      filterFavouritesJournalEntries = ListRoutine.filterFavouritesJournalEntries(
        journalEntries,
      );
    }

    let attachmentFilter = [];
    const obj = {};

    filterJournalEntries = Array.from(
      new Set([
        ...treatmentFil,
        ...metricFil,
        ...symptomFil,
        ...filterJournalEntries,
        ...notesFilter,
        ...questionFilter,
        ...attachmentFilter,
        ...dateFilterJournalEntries,
      ]),
    );

    filterFavouritesJournalEntries = Array.from(
      new Set([
        ...treatmentFil,
        ...metricFil,
        ...symptomFil,
        ...filterFavouritesJournalEntries,
        ...notesFilter,
        ...questionFilter,
        ...attachmentFilter,
      ]),
    );
    return ListRoutine.setFilterData(
      filterJournalEntries,
      filterFavouritesJournalEntries,
      metricFil,
      treatmentFil,
      symptomFil,
      obj,
      dateFilterJournalEntries,
    );
  };

  static keyWorldSearch = (journalEntries, searchKeyword, props) => {
    let filterJournalEntries = [];
    let filterFavouritesJournalEntries = [];
    let metricFil = [];
    let symptomFil = [];
    let treatmentFil = [];
    let notesFilter = [];
    let questionFilter = [];
    let dateFilterJournalEntries = [];

    filterJournalEntries = ListRoutine.filterJournalEntriesBySearchKeyword(
      journalEntries,
      searchKeyword,
    );

    dateFilterJournalEntries = ListRoutine.filterJournalEntriesByDate(
      journalEntries,
      searchKeyword,
    );

    filterFavouritesJournalEntries = ListRoutine.filterFavouritesJournalEntriesBySearchKeyword(
      journalEntries,
      searchKeyword,
    );
    notesFilter = ListRoutine.filterJournalEntriesNotesBySearchKeyword(
      journalEntries,
      searchKeyword,
    );

    questionFilter = ListRoutine.filterJournalEntriesQuestionBySearchKeyword(
      journalEntries,
      searchKeyword,
      props,
    );

    metricFil = ListRoutine.filterJournalEntriesMetricBySearchKeyword(
      journalEntries,
      searchKeyword,
      props.metricsDigestProcessed,
    );

    symptomFil = ListRoutine.filterJournalEntrieSymptomsBySearchKeyword(
      journalEntries,
      searchKeyword,
      props.symptomsDigestProcessed,
    );

    treatmentFil = ListRoutine.filterJournalEntrieTreatmentBySearchKeyword(
      journalEntries,
      searchKeyword,
      props.treatmentsProcessed,
    );

    return {
      filterJournalEntries,
      filterFavouritesJournalEntries,
      metricFil,
      symptomFil,
      treatmentFil,
      notesFilter,
      questionFilter,
      dateFilterJournalEntries,
    };
  };

  imageSerach = () => {
    const {journalEntryFilter, attachmentsCompleteDigest} = this.props;
    const filter = journalEntryFilter;
    this.setState({filterWait: true})
    let filterJournalEntries = [];
    let filterFavouritesJournalEntries = [];
    let metricFil = [];
    let symptomFil = [];
    let treatmentFil = [];
    let notesFilter = [];
    let questionFilter = [];

    let attachmentFilter = [];
    const obj = {};
    if (
      filter &&
      filter.searchKeyword &&
      filter.searchKeyword !== '' &&
      filter.ticked &&
      this.state.journalEntries
    ) {
      this.searchTextMap[filter.searchKeyword] = filter.searchKeyword;
      const data = ListRoutine.keyWorldSearch(
        this.state.journalEntries,
        filter.searchKeyword,
        this.props,
      );
      filterJournalEntries = data.filterJournalEntries;
      filterFavouritesJournalEntries = data.filterFavouritesJournalEntries;
      metricFil = data.metricFil;
      symptomFil = data.symptomFil;
      treatmentFil = data.treatmentFil;
      notesFilter = data.notesFilter;
      questionFilter = data.questionFilter;
      let userId = this.props.user.id;

      if (this.props.journalUserId) {
        userId = this.props.journalUserId;
      }

      this.props.searchAttachment(
        {
          attachmentCategory: 'Journal',
          searchKey: filter.searchKeyword,
          userId: userId,
        },
        (sucess, data) => {

          delete this.searchTextMap[filter.searchKeyword];
          if (sucess) {
            let art = [];
            let temp = [];
            if (
              filter.searchKeyword &&
              attachmentsCompleteDigest &&
              attachmentsCompleteDigest.attachments &&
              attachmentsCompleteDigest.attachments.length > 0
            ) {
              attachmentsCompleteDigest.attachments.forEach(element => {
                return element.filename
                  .toLowerCase()
                  .includes(filter.searchKeyword.toLowerCase())
                  ? art.push(element)
                  : null;
              });
            }
            if (
              art &&
              art.length > 0 &&
              attachmentsCompleteDigest.userArtifactAttachments &&
              attachmentsCompleteDigest.userArtifactAttachments.length > 0
            ) {
              art.forEach(re => {
                attachmentsCompleteDigest.userArtifactAttachments.forEach(res =>
                  re.id === res.attachment
                    ? temp.push({...re, instanceId: res.userArtifact})
                    : null,
                );
              });
            }

            data.forEach(element => {
              let exatt = element.attachments.concat(temp);
              exatt.forEach(item => {
                if (!obj[item.instanceId]) {
                  obj[item.instanceId] = [item];
                } else {
                  obj[item.instanceId].push(item);
                }
              });
            });
            let attList = [];
            attachmentFilter = this.state.journalEntries.filter(att => {
              if (obj[att.artifactId] && obj[att.artifactId].length > 0) {
                obj[att.artifactId].forEach(rr => {
                  attList.push(rr);
                });
              }
              return obj[att.artifactId];
            });
            filterJournalEntries = Array.from(
              new Set([
                ...treatmentFil,
                ...metricFil,
                ...symptomFil,
                ...filterJournalEntries,
                ...notesFilter,
                ...questionFilter,
                ...attachmentFilter,
              ]),
            );

            filterFavouritesJournalEntries = Array.from(
              new Set([
                ...treatmentFil,
                ...metricFil,
                ...symptomFil,
                ...filterFavouritesJournalEntries,
                ...notesFilter,
                ...questionFilter,
                ...attachmentFilter,
              ]),
            );
            const filterData = ListRoutine.setFilterData(
              filterJournalEntries,
              filterFavouritesJournalEntries,
              metricFil,
              treatmentFil,
              symptomFil,
              obj,
            );

            this.setState({
              filterWait: !_.isEmpty(this.searchTextMap),
              imageSerachFinish: true,
              ...filterData,
            });
          } else {
            this.setState({
              filterWait: !_.isEmpty(this.searchTextMap),
              imageSerachFinish: true,
            });
          }
        },
      );
    }
  };

  static setFilterData = (
    filterJournalEntries,
    filterFavouritesJournalEntries,
    metricFil,
    treatmentFil,
    symptomFil,
    obj,
  ) => {
    if (
      filterFavouritesJournalEntries &&
      filterFavouritesJournalEntries.length > 0
    ) {
      let filterFavouritesJournalEntriesTemp = [];
      filterJournalEntries.forEach(res => {
        if (res.flagged) {
          filterFavouritesJournalEntriesTemp.push(res);
        }
      });
      filterFavouritesJournalEntries = filterFavouritesJournalEntriesTemp;
    }

    filterJournalEntries.sort(function(a, b) {
      return b.modifiedOn - a.modifiedOn;
    });
    filterFavouritesJournalEntries = filterFavouritesJournalEntries.sort(
      function(a, b) {
        return b.modifiedOn - a.modifiedOn;
      },
    );

    return {
      filterJournalEntries: filterJournalEntries,
      filterFavouritesJournalEntries: filterFavouritesJournalEntries,
      metricFil: metricFil,
      treatmentFil: treatmentFil,
      symptomFil: symptomFil,
      filterOn: true,
      obj: obj,
    };
  };

  onDeleteJournal = () => {
    this.setState({deleteWait: true});
    let selectedId = this.state.selectedDeleteId;
    this.props.onDeleteJournal(
      selectedId,
      this.props.journalEntriesProcessed,
      () => {
        this.setState({
          deleteConfirm: false,
          deleteWait: false,
          selectedDeleteId: null,
        });
      },
      this.props.journalUserId,
    );
  };

  journalHasAttachments = artifactId => {
    let attachments = [];
    if (this.props.attachmentsCompleteDigest) {
      attachments = this.props.attachmentsCompleteDigest.userArtifactAttachments.filter(
        function(attachment) {
          return attachment.userArtifact === artifactId;
        },
      );
    }
    return attachments.length > 0;
  };

  searchRoutines = val => {
    const {onFilterJournalEntry, journalEntryFilter} = this.props;
    let filter = {};
    if (journalEntryFilter) {
      filter = journalEntryFilter;
    }
    onFilterJournalEntry({...filter, searchKeyword: val});
  };
  onTicked = val => {
    const {onFilterJournalEntry, journalEntryFilter} = this.props;
    let filter = {};
    if (journalEntryFilter) {
      filter = journalEntryFilter;
    }
    if(val) {
      pendoTrackEvent('Journals@ > SearchImages')
    }
    this.setState({searchAttachmentContent: val});
    let searchKey =
      journalEntryFilter && journalEntryFilter.searchKeyword
        ? journalEntryFilter.searchKeyword
        : '';
    onFilterJournalEntry({...filter, searchKeyword: searchKey, ticked: val});
  };

  handleCreateNewJournalEntry = () => {
    pendoTrackEvent('Journals@ > Add');
    this.props.setFromPopup('JOURNAL');
    // const {
    //   onCreateNewRoutine,
    //   journalUserId,
    //   applicationSettingsDigest,
    // } = this.props;
    // const {newJournalEntryTypeId} = applicationSettingsDigest;

    // this.setState({createNewJournalWait: true});
    // onCreateNewRoutine(
    //   {journalEntryTypeId: newJournalEntryTypeId},
    //   res => {
    //     this.setState({
    //       createNewJournalWait: false,
    //     });
    //     if (res) {
    //       // handleCreateNewRoutine();
    //       this.props.history.push(JOURNALS.addJournalPath);
    //     }
    //   },
    //   journalUserId,
    // );
  };

  handleTab = () => {
    const {
      filterJournalEntries,
      filterFavouritesJournalEntries,
      activeTab,
    } = this.state;

    if (activeTab === 0) {
      this.getActiveTabEntries(filterJournalEntries);
    } else {
      this.getActiveTabEntries(filterFavouritesJournalEntries);
    }
  };

  getActiveTabEntries = (filterJournalEntries) => {
    const {windowWidth} = this.state;
    if (filterJournalEntries && filterJournalEntries.length > 0) {
      let selectedJournal = filterJournalEntries[0];
      const firstJournal = this.getFirstJournal();
      if (firstJournal) {
        selectedJournal = firstJournal;
      }
      this.props.onSetSelectedJournalEntry(selectedJournal);
      this.props.setViewRoutine(true);
      if (windowWidth > TAB_BREAk_MAX_WIDTH) this.props.navigation.setParams({
        journalPath: JOURNALS.viewJournalPathSuffix, 
        journalName: selectedJournal?.title
      });
    } else {
      this.props.onSetSelectedJournalEntry(null);
      this.props.setViewRoutine(true);
      this.props.navigation.setParams({journalPath: '', journalName: ''});
    }
  }

  setActiveTab = res => {
    this.setState({activeTab: res}, () => this.handleTab());
  };

  setViewJournal = selectedJournal => {
    this.props.onSetSelectedJournalEntry(selectedJournal);
    this.props.setViewRoutine(true);
    this.props.navigation.setParams({
      journalPath: JOURNALS.viewJournalPathSuffix,
      journalName: selectedJournal?.title
    });
  }

  renderItemList = (isFavourites = false) => {
    return (
      <RutineItemScrollView
        data={Boolean(isFavourites)
          ? this.state.filterFavouritesJournalEntries
          : this.state.filterJournalEntries
        }
        handleOnPressAttachments={
          this.handleOnPressAttachments
        }
        handleOnChangeflagged={this.handleOnChangeflagged}
        journalHasAttachments={this.journalHasAttachments}
        journalEntryTypesProcessed={
          this.props.journalEntryTypesProcessed
        }
        filterOn={
          this.props.journalEntryFilter ? true : false
        }
        journalEntryFilter={
          this.props.journalEntryFilter
            ? this.props.journalEntryFilter
            : null
        }
        filterWait={this.state.filterWait}
        questionDigest={this.props.questionDigest}
        questionDigestProcessed={
          this.props.questionDigestProcessed
        }
        obj={this.state.obj}
        treatmentFil={this.state.treatmentFil}
        metricFil={this.state.metricFil}
        symptomFil={this.state.symptomFil}
        metricsDigestProcessed={
          this.state.metricsDigestProcessed
        }
        symptomsDigestProcessed={
          this.state.symptomsDigestProcessed
        }
        treatmentsProcessed={this.state.treatmentsProcessed}
        getRef={ref => routinesRef = ref}
        setViewJournal={this.setViewJournal}
      />
    );
  }

  render() {
    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    const {
      journalEntryFilter,
      emailVerified,
      hasNotch,
      journalUserId,
      timeZoneWarning,
      timeZone
    } = this.props;
    const { windowHeight, windowWidth } = this.state;

    let fullHeight = windowHeight;
    let fullWidth = windowWidth;
    let scrollHeight = getScrollHeight(hasNotch, journalUserId, timeZoneWarning, timeZone, fullHeight, fullWidth);   

    if (!this.props.showListRoutineHeader) {
      scrollHeight = fullHeight;
    }
        
    let smScreenMaxWidth = 1023;
    let outlookTabView = false;
    let resultLgScreen = false;
    let outlookTabViewSmall = false;
    if (fullWidth > smScreenMaxWidth) {
      outlookTabView = true;
      resultLgScreen = true;
    } else {
      outlookTabViewSmall = true;
    }
    return (
      <View style={[stylesRes.contentAreaFull]}>
        {!Boolean(emailVerified) && !Boolean(this.props.showListRoutineHeader) && (
          <MessageOverlay outlookView={true} />
        )}
        {Boolean(this.props.showListRoutineHeader) && (
          <React.Fragment>
            <Header
              showFilers={false}
              showBack={true}
              showClearFilters={false}
              showNotify={true}
              // clearFiltersHandler={this.handleOnPressClearFilters}
              // handleFilter={this.handleFilter}
              type="journal"
              handleOnPressBackButton={() => {
                if (
                  journalEntryFilter &&
                  journalEntryFilter.searchKeyword &&
                  journalEntryFilter.searchKeyword !== ''
                ) {
                  this.handleOnPressClearFilters();
                } else {
                  if (
                    this.props.route &&
                    this.props.route?.params &&
                    this.props.route?.params.from
                  ) {
                    this.props.route?.params.from ===
                      JOURNALS.addJournalPath ||
                    this.props.route?.params.from ===
                      JOURNALS.viewJournalPath ||
                    this.props.route?.params.from ===
                      JOURNALS.addQuickNotePath
                      ? this.props.navigation.reset({
                        index: 0,
                        routes: [{ name: DASHBOARD.path }],
                      })
                      : handleCommonBackNavigation(this.props.navigation);
                  } else {
                    handleCommonBackNavigation(this.props.navigation);
                  }
                }
              }}
              index={JOURNALS.index}
              navigation={this.props.navigation}
              route={this.props.route}
            />
            <SpaceBar />
          </React.Fragment>
        )}
        <KeyboardAwareScrollViewHoc
          scrollHeight={scrollHeight}
          scrollViewWebNone={true}
          scrollTransparent={true}>
          <View style={{minHeight: scrollHeight}}>
            <PageContainer outlookScreen={true}>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={[styles.searchSection]}>
                      {/* button */}
                      <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt]}>
                        <View>
                          <SectionTitle 
                            smallTextTilte={true}
                            title={'All Entries'}
                          />
                        </View>
                        <View style={[stylesRes.newConnectionBtnCr,styles.btnMgMd]} dataSet={{media: ids.newConnectionBtnCr}}>
                          <PlusButtonDark
                            touchableOnPress={this.handleCreateNewJournalEntry}
                            btnPosition={'full'}
                            btnText={'New Entry'}
                            plusIconSm={true}
                            btnShadowNone={true}
                            disabled={this.state.createNewJournalWait}
                            activityInd={this.state.createNewJournalWait}
                            nativeID={'journalsAdd'}
                            newConnection={true}
                          />
                        </View>
                      </View>
                      {/* search bar */}
                      <View style={styles.fieldColSearch}>
                        <SearchField
                          searchText={
                            journalEntryFilter &&
                            journalEntryFilter.searchKeyword
                              ? journalEntryFilter.searchKeyword
                              : ''
                          }
                          placeholder="Search entries"
                          onChangeText={this.searchRoutines}
                          showClearBtton={
                            journalEntryFilter &&
                            journalEntryFilter.searchKeyword &&
                            journalEntryFilter.searchKeyword !== ''
                              ? true
                              : false
                          }
                          clearFilter={this.handleOnPressClearFilters}
                          searchFieldSm={true}
                          nativeId={'journalsSearch'}
                          eventName={'Journals@ > Search'}
                        />
                      </View>
                  </View>
                  <View style={[styles.searchDocSection]}>
                    <ImageDocSearch
                      ticked={
                        this.state.accountType === 'premium' &&
                        journalEntryFilter &&
                        journalEntryFilter.ticked
                          ? journalEntryFilter.ticked
                          : false
                      }
                      onTicked={this.onTicked}
                      accountType={this.state.accountType}
                      navigation={this.props.navigation}
                      journalUserId={this.props.journalUserId}
                      outlookView={true}
                      nativeId={'journalsSearchImages'}
                    />
                  </View>
                </View>
              </View>
            </PageContainer>

            {_.isEmpty(this.state.filterFavouritesJournalEntries) &&
            _.isEmpty(this.state.filterJournalEntries) &&
            journalEntryFilter &&
            journalEntryFilter.searchKeyword !== '' &&
            !this.state.filterWait ? (
              <View style={styles.colPdLtRt}>
                <NoResultFound />
              </View>
            ) : (
              _.isEmpty(this.state.filterFavouritesJournalEntries) &&
              _.isEmpty(this.state.filterJournalEntries) &&
              !this.state.filterWait && (
                <Tabs
                  pageContainer={true}
                  smScreenFull={true}
                  outlookScreen={true}
                  screenFull={true}
                  outlookTabView={outlookTabView}
                  outlookTabViewSmall={outlookTabViewSmall}
                  tabIndex={this.setActiveTab}>
                  <View title="All" style={[styles.tabContentSection]}>
                    <View style={styles.searchCategoryWrapper}>
                      <View style={styles.categoryItemTitle}>
                        <Text style={[styles.textPrimeBold]} allowFontScaling={false}>Today</Text>
                      </View>
                    </View>
                    <View style={styles.colPdLtRt}>
                      <NoJournalFound resultLgScreen={resultLgScreen} />
                    </View>
                  </View>
                  <View title="Favourites" style={[styles.tabContentSection]}>
                    <View style={styles.searchCategoryWrapper}>
                      <View style={styles.categoryItemTitle}>
                        <Text style={[styles.textPrimeBold]} allowFontScaling={false}>Today</Text>
                      </View>
                    </View>
                    <View style={styles.colPdLtRt}>
                      <NoJournalFound resultLgScreen={resultLgScreen} />
                    </View>
                  </View>
                </Tabs>
              )
            )}

            {(!_.isEmpty(this.state.filterFavouritesJournalEntries) ||
              !_.isEmpty(this.state.filterJournalEntries) ||
              this.state.filterWait) && (
              <Tabs
                pageContainer={true}
                smScreenFull={true}
                outlookScreen={true}
                screenFull={true}
                outlookTabView={outlookTabView}
                outlookTabViewSmall={outlookTabViewSmall}
                tabIndex={this.setActiveTab}>
                <View title="All" style={[styles.tabContentSection]}>
                  <View style={[styles.gridRow, styles.tabTopPd]}>
                    <View style={[styles.gridColFull]}>
                      {/* Routines List Start Here */}
                      {Boolean(this.state.journalEntries) &&
                        Boolean(this.state.filterJournalEntries) && (
                          this.renderItemList()
                        )}

                      {/* Routines List End Here */}
                    </View>
                  </View>
                </View>
                <View title="Favourites" style={[styles.tabContentSection]}>
                  <View style={[styles.gridRow, styles.tabTopPd]}>
                    <View style={styles.gridColFull}>
                      {/* Routines Favourites List Start Here */}
                      {Boolean(this.state.journalEntries) &&
                        Boolean(this.state.filterFavouritesJournalEntries) && (
                          this.renderItemList(true)
                        )}
                      {/* Routines Favourites List End Here */}
                    </View>
                  </View>
                </View>
              </Tabs>
            )}
          </View>
        </KeyboardAwareScrollViewHoc>

        <ConfirmationBox
          visibleModal={this.state.deleteConfirm}
          onBackdropPress={() => {
            this.setState({deleteConfirm: false});
          }}
          onRequestClose={() => {
            this.setState({deleteConfirm: false});
          }}
          alertTitle={'Delete Entry'}
          handleDelete={this.onDeleteJournal}
          deleteWait={this.state.deleteWait}
        />
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    journalEntryTypes: state.routines.journalEntryTypes,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    journalEntrySelected: state.routines.journalEntrySelected,
    // Spinners
    deleteJournalSpinner: state.routines.deleteJournalSpinner,

    // Get Selected Date
    selectedDate: state.routines.selectedDate,
    journalEntryFilter: state.routines.journalEntryFilter,
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
    journalUserId: state.uistate.journalUserId,
    questionDigest: state.routines.questionDigest,
    questionDigestProcessed: state.routines.questionDigestProcessed,
    user: state.routines.user,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    symptomsDigestProcessed: state.routines.symptomsDigestProcessed,
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    userSubscriptions: state.routines.userSubscription,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    emailVerified: state.auth.emailVerified,
    allAttachments: state.routines.attachmentsCompleteDigest.attachments,
    userArtifactAttachments:
      state.routines.attachmentsCompleteDigest.userArtifactAttachments,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
    hasNotch: state.uistate.hasNotch,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetachAllJournals: () => dispatch(actions.fetchAllJournalEntries()),
    onSetSelectedJournalEntry: selectedJournal => {
      dispatch(actions.setSelectedJournalEntry(selectedJournal))
    },
    onDeleteJournal: (
      selectedJournalId,
      journalEntriesProcessed,
      callBack,
      journalUserId,
    ) =>
      dispatch(
        actions.deleteJournal(
          selectedJournalId,
          journalEntriesProcessed,
          callBack,
          journalUserId,
        ),
      ),
    onSaveJournalEntry: (journalEntry, callBack, journalUserId) =>
      dispatch(actions.saveJournalEntry(journalEntry, callBack, journalUserId)),
    // onSetSelectDate: date => dispatch(actions.setSelectDate(date)),
    onClearJournalEntryFilte: () => dispatch(actions.clearJournalEntryFilter()),
    editRoutineTemplate: journalEntrie =>
      dispatch(actions.editRoutineTemplate(journalEntrie)),
    copyRoutineTemplate: journalEntrie =>
      dispatch(actions.copyRoutineTemplate(journalEntrie)),
    searchAttachment: (formData, callBack) =>
      dispatch(actions.searchAttachment(formData, callBack)),
    onFilterJournalEntry: filter =>
      dispatch(actions.filterJournalEntry(filter)),
    onCreateNewRoutine: (journalEntry, callBack, journalUserId) =>
      dispatch(actions.createNewRoutine(journalEntry, callBack, journalUserId)),
    setFromPopup: fromPopup => dispatch(actions.setFromPopup(fromPopup)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListRoutine);
