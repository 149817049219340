import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity, ScrollView } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import PageContainer from "../../components/PageLayout/PageContainer";
import {DASHBOARD} from '../../navigation/path';
import { TAB_BREAk_POINT_START } from "../../util/platformWindowConfig";
import { getScrollHeight } from "../../util/commonUiLogic";

class MainSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
      lodding: false,
      journalUserName: props.journalUserName,
      IsInValidSurvey: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth, route } = props;
    const params = route?.params;
    const { isInvalidSurvey } = state;

    let stateNew = null;
    if (
      props.windowHeight !== state.windowHeight ||
      props.windowWidth !== state.windowWidth
    ) {
      stateNew = {
        ...stateNew,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }
    if (params?.status && !isInvalidSurvey) {
      stateNew = {
        ...stateNew,
        isInvalidSurvey: true,
      };
    }
    return stateNew;
  }


  startSurveyQuestions = (state) => {
    if (state) {
      this.props.navigation.reset({
        routes: [{ 
          index: 0,
          name: DASHBOARD.path 
        }]
      });

    } else {
      this.props.redirectSurveyQuestions();
    }
  };

  render() {
    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    const { hasNotch, journalUserId, timeZoneWarning, timeZone } = this.props;
    const { windowHeight, windowWidth } = this.state;

    let scrollMaxHeight = getScrollHeight(hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth ,100);
    
    let fullWidth = windowWidth;
    let bannerWidth = 0;
    let bannerHeight = 0;

    if (fullWidth > TAB_BREAk_POINT_START) {
      bannerWidth = fullWidth - 455;
    } else {
      bannerWidth = fullWidth;
    }
    bannerHeight = (260 / 1500) * bannerWidth;

    return (
      <React.Fragment>
        {this.state.lodding ? (
          <View style={styles.loaderCom}>
            <ActivityIndicator size="large" />
          </View>
        ) : (
          <>
          <View style={{height: scrollMaxHeight}}>
          <ScrollView>
            <View style={stylesRes.surveyContent}>
              <View style={[styles.surveyBannerWrapper]}>
                <View style={[styles.surveyBannerFull]}>
                  {fullWidth > 560 ?

                  <Image
                    style={[
                      styles.surveyBannerFull,
                      { height: bannerHeight },
                    ]}
                    source={require("../../assets/background-images/survey-banner-1.png")}
                  />
                  :
                  <Image
                    style={[
                      styles.surveyBannerFull,
                      { height: 175 },
                    ]}
                    source={require("../../assets/background-images/survey-bg-mobile.png")}
                  />
                }
                </View>
              </View>
              <PageContainer>
                <View style={[stylesRes.maxComSec, stylesRes.surveyScreenMainWrapper]} dataSet={{media: ids.surveyScreenMainWrapper}}>
                  {Boolean(this.props.s3ImageUrl) &&
                  <View style={[styles.surveyBannerFull, styles.surveyLogoWidth]}>
                    <Image
                      style={[{width: 236}, {height: 163}]}
                      resizeMode = {'contain'}
                      source={{ uri: this.props.s3ImageUrl }}/>
                  </View>}
                  {Boolean(this.state.isInvalidSurvey) && 
                    <View style={[styles.surveyBannerFull, styles.surveyLogoWidth]}>
                      <Image
                      style={[styles.surveyNotfoundProfileImage]}
                      source={require("../../assets/survey-icons/survey-notfound-profile-icon.png")}
                      />
                    </View>
                  }
                  <View style={[styles.comTextSec, styles.comSpLg]}>
                    <View >
                      <View style={[styles.titleMain]}>
                        <Text
                          style={[
                            styles.textTitleMainSmBold,
                            styles.textAlignCenter,
                          ]}
                          allowFontScaling={false}
                        >
                          {this.props.name}
                        </Text>
                        {Boolean(this.state.isInvalidSurvey) && 
                          <Text
                            style={[
                              styles.textTitleMainMdBold,
                              styles.textAlignCenter,
                            ]}
                            allowFontScaling={false}
                          >
                            Survey not found
                          </Text>
                        }
                         
                      </View>
                      {!Boolean(this.state.isInvalidSurvey) &&                       
                      <View style={[styles.titleSub]}>
                        <Text
                          style={[
                            styles.textTitleExLgBold,
                            styles.textAlignCenter,
                          ]}
                          allowFontScaling={false}
                        >
                          Health Questionnaire
                        </Text>
                      </View>
                      }
                    </View>
                  </View>
                  <View style={[stylesRes.maxComSecSm, stylesRes.surveyDescriptionBlock]} dataSet={{media: ids.surveyDescriptionBlock}}>
                    <View style={[styles.comTextSec, styles.comSpMd]}>
                      <Text style={[styles.textPrime, styles.textAlignCenter]} allowFontScaling={false}>
                        {this.props.description}
                      </Text>
                      {Boolean(this.state.isInvalidSurvey) && 
                      <View>
                      <Text style={[styles.textTitleMd, styles.textAlignCenter]} allowFontScaling={false}>
                        You have already completed
                      </Text>
                      <Text style={[styles.textTitleMd, styles.textAlignCenter]} allowFontScaling={false}>
                        this survey, or
                      </Text>
                      <Text style={[styles.textTitleMd, styles.textAlignCenter]} allowFontScaling={false}>
                        this survey is no longer active.
                      </Text>
                      </View>
                      }
                    </View>
                    <View style={[styles.comTextSec, styles.comSp]}>
                      <Text
                        style={[
                          styles.textPrime,
                          styles.textOrange,
                          styles.textEm,
                          styles.textAlignCenter,
                        ]}
                        allowFontScaling={false}
                      >
                        {this.props.duration && `Approx. ${this.props.duration} mins`}
                      </Text>
                    </View>
                  </View>
                </View>
              </PageContainer>
            </View>
          </ScrollView>
          </View>
          <PageContainer>
            <View style={[stylesRes.maxComSec, stylesRes.surveyScreenMainWrapper]} dataSet={{media: ids.surveyScreenMainWrapper}}>
              <View style={[stylesRes.maxComSecSm, stylesRes.surveyDescriptionBlock]} dataSet={{media: ids.surveyDescriptionBlock}}>
                <View style={stylesRes.orangeButtonWrapper} dataSet={{media: ids.orangeButtonWrapper}}>
                  <TouchableOpacity
                    style={[styles.orangeButton]}
                    onPress={() => this.startSurveyQuestions(Boolean(this.props.route?.params?.status))}
                  >
                    <Text style={[styles.textTitleBold, styles.textWhite]} allowFontScaling={false}>{Boolean(this.state.isInvalidSurvey) ? `Continue to Dashboard`:`Let’s get started`}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
        </PageContainer>
      </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showLearnHow: state.uistate.showLearnHow,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    surveyId: state.surveys.surveyId,
    journalUserId: state.uistate.journalUserId,
    name: state.surveys.name,
    description: state.surveys.description,
    duration: state.surveys.duration || null,
    s3ImageUrl: state.surveys.s3ImageUrl || null,
    hasNotch: state.uistate.hasNotch,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainSurvey);
