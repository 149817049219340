import React from 'react';
import {View, Text, Image} from 'react-native';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import styles from '../../Css';

const {Popover} = renderers;

const commonPopupMenu = props => {
  return (
    <Menu renderer={Popover} rendererProps={{preferredPlacement: 'left'}}>
      <MenuTrigger>
        <View style={[styles.itemIconSingle, styles.itemSettingMenu]}>
          <Image
            style={[
              styles.settingIcon,
              styles.settingIconDark,
              props.disableText
              ? styles.iconInactiveColor
              : styles.iconActiveTintColor,
            ]}
            source={require('../../assets/icons/settings.png')}
          />
        </View>
      </MenuTrigger>
      {!Boolean(props.disableText) &&
        <MenuOptions style={[styles.dropDownList, styles.menuOptionsLg]}>
          <View style={{padding: 1}} nativeID={props.nativeIdEdit}>
          <MenuOption style={styles.liItem} onSelect={props.handleEdit}>
            <Text style={styles.listText} allowFontScaling= {false}>Edit</Text>
          </MenuOption>
          </View>
          <MenuOption style={styles.liItem} onSelect={props.handleDelete}>
            <Text style={styles.listText} allowFontScaling= {false}>Delete</Text>
          </MenuOption>
        </MenuOptions>
      }
    </Menu>
  );
};
export default commonPopupMenu;
