import React, { Component } from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";
import moment from "moment";
import styles from "../../../../Css";
import * as FieldDetails from "../../../TextInput/fieldDetails";
import ItemDeleteButton from "../../../Button/itemDeleteButton";
import TextDatePickerFrame from "../../../TextInput/textDatePickerFrame";
import TextTimePickerFrame from "../../../TextInput/textTimePickerFrame";
import TimePicker from "../../../DateTimePicker/TimePicker";
import DatePicker from "../../../DateTimePicker/DatePicker";
import TextButton from "../../../TextInput/textButton";
import {timeMap, days} from '../../../../util/formatDate';
import { WEB } from "../../../../util/platformWindowConfig";


class Weekly extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    const { reminderTime } = data;
    this.state = { ...data, reminderDateTime: this.getDateTime(reminderTime) };
  }

  onTimeChange = (selectedTime) => {
    const time = Platform.OS === WEB
      ? moment(selectedTime).format("HH.mm")
      : moment(selectedTime, "x").format("HH.mm")

    const times = time.split(".");
    //const { reminderTime } = this.state;
    let reminderTime = this.state.reminderTime;
    if (reminderTime && Array.isArray(reminderTime)) {
      reminderTime[0] = parseInt(times[0]);
      reminderTime[1] = parseInt(times[1]);
    } else {
      reminderTime = times[0] + ':' + times[1];
    }
    this.setState({
      reminderTime: reminderTime,
      reminderDateTime: this.getDateTime(reminderTime),
    });
  };

  getDateTime = (reminderTime) => {
    const reminderTimes = reminderTime && Array.isArray(reminderTime) ? reminderTime : reminderTime.split(":");
    if (reminderTimes && reminderTimes.length > 0) {
      const now = moment()
        .set("hour", reminderTimes[0])
        .set("minute", reminderTimes[1]);
      return now.valueOf();
    }

    return null;
  };

  customTouchableComponentTimePicker = (onPress) => {
    return (
      Platform.OS === WEB
        ? <TouchableOpacity onPress={onPress}>
            {this.renderWeeklyTimeContent()}
          </TouchableOpacity>
        : this.renderWeeklyTimeContent()
    );
  };

  renderWeeklyTimeContent = () => {
    const { reminderTime, reminderDateTime } = this.state;
    let duration = "";
    const reminderTimes = reminderTime && Array.isArray(reminderTime) ? reminderTime : reminderTime.split(":");
    if (reminderTimes && reminderTimes.length > 0) {
      const time = timeMap[reminderTimes[0]];
      const times = time.split(" ");
      duration =
        times[0] +
        ":" +
        (reminderTimes[1].toString().length === 1
          ? "0" + reminderTimes[1]
          : reminderTimes[1]) +
        " " +
        times[1];
    }
    return (
      <TextTimePickerFrame
        title={"Weekly @"}
        fieldBg={FieldDetails.BGWHITE}
        fieldSm={true}
        fieldValue={duration}
        onTimeChange={this.onTimeChange}
        reminderDateTime={reminderDateTime}
        windowWidth={this.props.windowWidth}
        reminderSection={this.props.reminderSection}
      />
    )
  }

  customTouchableComponent = (onPress) => {
    return (
      Platform.OS === WEB
        ? <TouchableOpacity onPress={onPress}>
            {this.renderEndReminderContent()}
          </TouchableOpacity>
        : this.renderEndReminderContent()
    );
  };

  renderEndReminderContent = () => {
    const { reminderEnd } = this.state;

    return (
      <TextDatePickerFrame
        title={"End Reminder"}
        fieldBg={FieldDetails.BGWHITE}
        fieldSm={true}
        fieldValue={
          reminderEnd ? moment(reminderEnd).format("D MMM 'YY") : ""
        }
        onDateConfirm={this.onDateConfirm}
        date={reminderEnd}
        dateSm={true}
        windowWidth={this.props.windowWidth}
        reminderSection={this.props.reminderSection}
        minDate={new Date().getTime()}
      />
    )
  }

  getDayButton = (isActive, btnText, touchableOnPress) => {
    return (
      <>
        <View style={styles.btnTabSingleDynamic}>
          <TextButton
            active={isActive}
            btnText={btnText}
            touchableOnPress={touchableOnPress}
            weeklySelection ={true}
            activeBtnTabStyle={styles.daysButtonActive}
            weeklybtnShadow={true}
          />
        </View>
        <View style={styles.fieldColSpSm} />
      </>
    )
  }
  renderWeekDaysSection = () => {
    const {
      reminderMonday,
      reminderTuesday,
      reminderWednesday,
      reminderThursday,
      reminderFriday,
      reminderSaturday,
      reminderSunday,
    } = this.state;
    return(
      <>
      <View style={[styles.fieldColId, styles.pdLeftZero]}>
        <View style={styles.textSectionCenter}>
          <Text style={styles.primarySmBold} allowFontScaling={false}>on</Text>
        </View>
      </View>
      <View style={styles.fieldColSpSm} />

      {this.getDayButton(reminderMonday, days.monday, this.onReminderMonday)}
        {this.getDayButton(reminderTuesday, days.tuesday, this.onReminderTuesday)}
        {this.getDayButton(reminderWednesday, days.wednessday, this.onReminderWednesday)}
        {this.getDayButton(reminderThursday, days.thursday, this.onReminderThursday)}
        {this.getDayButton(reminderFriday, days.friday, this.onReminderFriday)}
        {this.getDayButton(reminderSaturday, days.saturday, this.onReminderSaturday)}
        {this.getDayButton(reminderSunday, days.sunday, this.onReminderSunday)}
      </>
    )
  }

  onDateConfirm = (date) => {
    const { updateReminders, index } = this.props;
    this.setState({ reminderEnd: date }, () =>
      updateReminders(index, { ...this.state, reminderEnd: date })
    );
  };

  onReminderMonday = () => {
    const { updateReminders, index } = this.props;
    const { reminderMonday } = this.state;
    this.setState({ reminderMonday: !reminderMonday }, () =>
      updateReminders(index, { ...this.state, reminderMonday: !reminderMonday })
    );
  };

  onReminderTuesday = () => {
    const { updateReminders, index } = this.props;
    const { reminderTuesday } = this.state;
    this.setState({ reminderTuesday: !reminderTuesday }, () =>
      updateReminders(index, {
        ...this.state,
        reminderTuesday: !reminderTuesday,
      })
    );
  };

  onReminderWednesday = () => {
    const { updateReminders, index } = this.props;
    const { reminderWednesday } = this.state;
    this.setState({ reminderWednesday: !reminderWednesday }, () =>
      updateReminders(index, {
        ...this.state,
        reminderWednesday: !reminderWednesday,
      })
    );
  };

  onReminderThursday = () => {
    const { updateReminders, index } = this.props;
    const { reminderThursday } = this.state;
    this.setState({ reminderThursday: !reminderThursday }, () =>
      updateReminders(index, {
        ...this.state,
        reminderThursday: !reminderThursday,
      })
    );
  };

  onReminderFriday = () => {
    const { updateReminders, index } = this.props;
    const { reminderFriday } = this.state;
    this.setState({ reminderFriday: !reminderFriday }, () =>
      updateReminders(index, { ...this.state, reminderFriday: !reminderFriday })
    );
  };

  onReminderSaturday = () => {
    const { updateReminders, index } = this.props;
    const { reminderSaturday } = this.state;
    this.setState({ reminderSaturday: !reminderSaturday }, () =>
      updateReminders(index, {
        ...this.state,
        reminderSaturday: !reminderSaturday,
      })
    );
  };

  onReminderSunday = () => {
    const { updateReminders, index } = this.props;
    const { reminderSunday } = this.state;
    this.setState({ reminderSunday: !reminderSunday }, () =>
      updateReminders(index, { ...this.state, reminderSunday: !reminderSunday })
    );
  };

  updateDays = (obj) => {
    const { updateReminders, index } = this.props;
    const { reminderDateTime } = this.state;
    updateReminders(index, { ...obj, reminderDateTime: reminderDateTime })
  };

  render() {
    const {
      reminderEnd,
      reminderDateTime,
      publishedBy,
    } = this.state;

    const { index, deleteItem, windowWidth } = this.props;
    return (
      <React.Fragment>
        <View 
          onLayout={(event) => this.props.setExpiredReminderPosition({...event.nativeEvent.layout, reminderEveryday: false, reminderMonthly: false})}
        >
          <View style={styles.fieldContainer}>
            <View style={styles.fieldRowWrapper}>
              <View style={styles.fieldColIdCom}>
                <View style={styles.indexSm}>
                  <Text style={[styles.textPrimeSmBold, styles.indexTxtSm]}>
                    {index+ 1}
                  </Text>
                </View>
              </View>

              <View style={styles.fieldColSp} />
              <View style={styles.fieldColStretch}>
                <TimePicker
                  customTouchableComponent={
                    this.customTouchableComponentTimePicker
                  }
                  onChange={this.onTimeChange}
                  value={reminderDateTime}
                  windowWidth={this.props.windowWidth}
                  reminderSection={this.props.reminderSection}
                />
              </View>

              {Boolean(Platform.OS === WEB && windowWidth >= 1150)  &&
                <>
                <View style={styles.fieldColSp} />
                <View style={styles.fieldRowWrapperSm}>
                  {this.renderWeekDaysSection()}
                </View>
                </>
              }

              <View style={styles.fieldColSp} />
              <View style={styles.fieldColStretch}>
                <DatePicker
                  date={reminderEnd}
                  customTouchableComponent={this.customTouchableComponent}
                  onConfirm={this.onDateConfirm}
                  windowWidth={this.props.windowWidth}
                  reminderSection={this.props.reminderSection}
                  minDate={new Date().getTime()}
                />
              </View>

              <View style={styles.fieldColSp} />
              <View style={styles.fieldColDelete}>
                {publishedBy !== 'PARTNER' && (
                  <ItemDeleteButton 
                  touchableOnPress={() => deleteItem(index)} 
                  routinesReminderDelete={true}
                  />
                )}  
              </View>
            </View>

            {(Boolean(Platform.OS === WEB && windowWidth < 1150) || Boolean(Platform.OS !== WEB)) &&
              <View style={styles.fieldRowWrapper}>
                {this.renderWeekDaysSection()}
              </View>
            }
          </View>
        </View>
      </React.Fragment>
    );
  }
}

export default (Weekly);
