import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import styles from "../../Css";
import * as FontScaling from '../../components/Text/fontScaling';

const textButton = (props) => {
  return (
    <TouchableOpacity
      disabled={props.disabled}
      style={styles.btnTabTouch}
      onPress={props.touchableOnPress}
    >
      <View
        style={[
          styles.btnTabSmMenu,
          styles.btnTabMenuCurve,
          // styles.btnShadow,
          props.weeklybtnShadow
          ? styles.weeklyBtnShadowRoutine
          : styles.btnShadow,
          props.weeklySelection && styles.weekDaySelection,
          props.active && props.activeBtnTabStyle 
            ? props.activeBtnTabStyle
            : props.active
                ? styles.btnTabMenuActive
                : styles.btnTabMenuDefault,
          props.btnTabStyle
        ]}
      >
        <Text
          style={[
            styles.textPrimeSmBold,
            styles.textAlignCenter,
            props.active ? styles.btnTextHighlight : styles.btnTextdefault,
          ]}
          allowFontScaling = {FontScaling.allowFontScaleFalse}
        >
          {props.btnText}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default textButton;
