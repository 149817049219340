import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from '../../../Css';
import { connect } from 'react-redux';
import * as FieldDetails from '../../TextInput/fieldDetails';
import TextInputPlusMinus from '../../TextInput/TextInputPlusMinus';
import TextFrame from '../../TextInput/textFrame';
import Warning from '../../Warning/warning';
import _ from 'lodash';

class ActivityMyList extends Component {
  constructor(props) {
    super(props);
    const { data, index, updateActivity, treatmentsProcessed, isNew, type } = props;
    const isRandNew = (isNew && type === 'R') || (type === 'R' && data.new);
    const { quantity, treatment, accept, treatmentObj: { publishedBy } = {}, units, updated } = data;

    let treatmentQuantity = this.getGoalAndUnit(treatment, treatmentsProcessed).treatmentQuantity;
    let treatmentUnit = this.getGoalAndUnit(treatment, treatmentsProcessed).treatmentUnit;

    // if (treatment && treatmentsProcessed && treatmentsProcessed[treatment]) {
    //   const dosages = treatmentsProcessed[treatment].dosages;
    //   treatmentQuantity = dosages && dosages.length > 0 && (dosages[0].quantity || dosages[0].quantity === 0)
    //       ? dosages[0].quantity
    //       : null;
    //   treatmentUnit =
    //     dosages && dosages.length > 0 && dosages[0].units
    //       ? dosages[0].units
    //       : null;
    // }
    const itemQuantity = quantity || quantity === 0 ? quantity : treatmentQuantity;

    this.state = {
      value: updated || !isRandNew || (!data.new && isRandNew) ? quantity : null,
      units: units ? units : treatmentUnit ? treatmentUnit : 'minutes',
      publishedBy: publishedBy,
      movingSliderCurrentSelection: undefined,
      duplicatePopup: false,
      goalNumber: treatmentQuantity,
      goalUnit: treatmentUnit,
      hasAutofillDisabled: this.quantity === treatmentQuantity,
    };

    updateActivity(index, {
      ...data,
      quantity: isRandNew && updated ? itemQuantity : isRandNew && quantity === null ? null : quantity,
      accept: (itemQuantity || itemQuantity === 0) && accept ? true : false
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { data } = props;

    if (data && !data.new) {

      return {
        value: data.quantity
      };
    }

    return null;
  }

  componentDidMount = () => {
    this.validateAutoFillOption();
  }



  componentDidUpdate(prevProps, prevState) {



    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      const treatmentUnit = this.getGoalAndUnit(this.props.data.treatment, this.props.treatmentsProcessed).treatmentUnit;
      this.setState({
        value: this.props.data.quantity,
        units: this.props.data.units ? this.props.data.units : treatmentUnit ? treatmentUnit : 'minutes',
        goalNumber: this.getGoalAndUnit(this.props.data.treatment, this.props.treatmentsProcessed).treatmentQuantity,
        goalUnit: this.getGoalAndUnit(this.props.data.treatment, this.props.treatmentsProcessed).treatmentUnit,
        publishedBy: this.props?.data?.treatmentObj?.publishedBy,
      }, () => {
        const hasAutofillDisabled = this.compareGoalAndQuantity(this.state.goalNumber, this.state.value);
        this.setState({ hasAutofillDisabled });
      });
    }

    if (!isNaN(this.state.value) && prevState.value !== this.state.value) {
      const hasAutofillDisabled = this.compareGoalAndQuantity(this.state.goalNumber, this.state.value);
      this.setState({ hasAutofillDisabled });
    }
  }

  getGoalAndUnit = (treatment, treatmentsProcessed) => {
    let treatmentQuantity = null;
    let treatmentUnit = null;

    if (treatment && treatmentsProcessed && treatmentsProcessed[treatment]) {
      const dosages = treatmentsProcessed[treatment].dosages;
      treatmentQuantity = dosages && dosages.length > 0 && (dosages[0].quantity || dosages[0].quantity === 0)
        ? dosages[0].quantity
        : null;
      treatmentUnit =
        dosages && dosages.length > 0 && dosages[0].units
          ? dosages[0].units
          : null;
    }

    return { treatmentQuantity, treatmentUnit }


  }

  compareGoalAndQuantity = (goal, qty) => {

    if (typeof goal === typeof qty && goal === qty) return true;


    const goal_new = goal !== null && typeof goal === 'string' ? parseInt(goal) : goal;
    const val_new = qty !== null && typeof qty === 'string' ? parseInt(qty) : qty;

    if (goal_new === val_new) return true;

    return false;

  }


  onChange = value => {
    const { data, updateActivity, index } = this.props;
    if (data) {
      this.setState({ value }, () => {
        updateActivity(index, { ...data, quantity: value, accept: true, updated: true });
        this.validateAutoFillOption();
      });
    } else {
      updateActivity(value);
      this.validateAutoFillOption();
    }

  };

  onAutoFill = () => {

    this.setState({ value: this.state.goalNumber }, () => {
      this.onChange(this.state.goalNumber);
      this.validateAutoFillOption();
    });


  }

  validateAutoFillOption = () => {
    setTimeout(() => {
      if (this.compareGoalAndQuantity(this.state.goalNumber, this.state.value)) {
        this.setState({ hasAutofillDisabled: true });
      } else {
        this.setState({ hasAutofillDisabled: false });
      }
    }, 200)
  }

  render() {
    const {
      value,
      units,
      duplicatePopup,
      errorDescription,
      goalNumber,
      goalUnit,
      publishedBy,
      hasAutofillDisabled
    } = this.state;
    const { data, deleteItem, index } = this.props;

    let category = this.props.type;
    return (
      <>
        <View style={[styles.ActivitySliderCardView, styles.bgDarkAsh]}>
          {category !== 'RT' ?
            <View style={[styles.fieldRowWrapperSm, styles.borderTpLtRadius, styles.borderTpRtRadius]}>
              {/* {publishedBy !== 'PARTNER' && ( ZI-4049 - issue fixed by this comment */}
              <View style={[styles.fieldColAutoFill, styles.flexJcFe, styles.flexAiCt]}>
                {category !== 'RT' && goalNumber !== null &&
                  <View style={[styles.fillCont]}>
                    <View>
                      <Text style={[styles.textPrimeSm, styles.textColorGreyLight, styles.pdRtFour, styles.pdTpTwelve, styles.autoCompleteTextInput, styles.pdBtTwo]}>{`${goalNumber}`} {`${goalUnit || 'minutes'}`}</Text>
                    </View>
                    <TouchableOpacity disabled={hasAutofillDisabled} onPress={this.onAutoFill}>
                      <View style={[styles.fillContAuto, styles.pdTpTwelve, styles.pdBtTwo, styles.contListItemIconBlock]}>
                        <Image
                          style={[
                            styles.autofillIconMed
                          ]}
                          source={require('../../../assets/icons/autofill.png')}
                        />
                        <Text style={[styles.textPrimeSmBlue, styles.textUnderline, hasAutofillDisabled && { opacity: 0.4 }]}>{`${'Autofill'}`}</Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                }
              </View>
            </View>
            :
            <View style={[styles.fieldContainerCom]}>
              <View style={[styles.fieldRowWrapperSm, styles.bgWhite, styles.borderTpLtRadius, styles.borderTpRtRadius]}>
                {/* {category !== 'RT' && <View style={styles.fieldColSpCom} />} */}
                <View style={[styles.fieldColStretch, styles.iconLtSp]}>
                  <View style={[styles.fieldRowWrapperSm, styles.preDataLine, styles.flexCol]}>
                    <Text style={[styles.textPrimeBold, styles.textBlack, styles.infoSp]}>
                      {`${data.treatmentObj && data.treatmentObj.name ? data.treatmentObj.name : data.name}`} </Text>
                    {category !== 'RT' && goalNumber !== null && goalUnit !== null &&
                      <Text style={[styles.textPrime, styles.textColorGreyLight, styles.pdTopExSm]}>Goal: {`${goalNumber}`} {`${goalUnit}`}</Text>
                    }
                  </View>
                </View>
                {publishedBy !== 'PARTNER' && (
                  <View style={[styles.fieldColDelete, styles.flexJcCt]}>
                    <TouchableOpacity onPress={() => deleteItem(index)}>
                      <Image
                        style={[
                          styles.delLgIcon
                        ]}
                        source={require('../../../assets/icons/close-light-grey.png')}
                      />
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
          }
          <View style={[styles.iconLtSp, styles.barBotSp]}>
            {category !== 'RT' ?
              <Text style={[styles.textPrimeBold, styles.textBlack, styles.infoSp,
                // publishedBy === 'PARTNER' && styles.mgTpTwelve  --- ZI-4049 - issue fixed by this comment
              ]}> {`${data.treatmentObj && data.treatmentObj.name ? data.treatmentObj.name : data.name}`} </Text>
              :
              <Text style={[styles.textPrime, styles.textBlack]}>
                {category !== 'RT' ? 'My progress:' : 'Set goal:'}
              </Text>
            }
            <View style={category !== 'RT' ? [styles.flexRow, styles.mgTopLg, styles.rightSpSm] : [styles.flexRow, styles.mgTopLg]}>
              <View>
                <TextInputPlusMinus
                  title={'Quantity'}
                  fieldBg={FieldDetails.BGWHITE}
                  fieldAlign={FieldDetails.ALIGNCENTER}
                  fieldLt={false}
                  darkTxt={true}
                  value={value}
                  onChange={this.onChange}
                  type={'ACTIVITY'}
                  units={units}
                  // max={units === 'minutes' ? 120 : null}
                  max={null}
                  activitySlider={true}
                />
              </View>
              <View style={styles.wdTen}></View>
              <View style={category !== 'RT' && [styles.fieldColStretch, styles.rightSpSm]}>
                <TextFrame
                  title={'Measure'}
                  fieldBg={FieldDetails.BGGREY}
                  fieldSm={false}
                  fieldValue={units ? units : 'minutes'}
                  editable={false}
                  activitySlider={false}
                />
              </View>
              {category !== 'RT' && publishedBy !== 'PARTNER' ?
                <View style={[styles.fieldColDelete, styles.flexJcCt]}>
                  <TouchableOpacity onPress={() => deleteItem(index)}>
                    <Image
                      style={[
                        styles.delLgIcon,
                        styles.closeMed
                      ]}
                      source={require('../../../assets/icons/close-light-grey.png')}
                    />
                  </TouchableOpacity>
                </View>
                : null}
            </View>
          </View>
          {Boolean(duplicatePopup) && (
            <>
              <Warning
                errorDescription={errorDescription}
                textPosition={'left'}
                warningBlockSp={category !== 'RT' ? true : false}
                warningBlockMg={true}
              />
            </>
          )}
        </View>
        <View style={[styles.secSepBorderBot, styles.comSp, styles.titleTopPd]} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    treatmentsProcessed: state.routines.treatmentsProcessed,
    hasInvalidNamesInActivity: state.therapie.hasActivityInvalidName,
    hasInvalidNamesInMedication: state.therapie.hasMedicationInvalidName,
    hasInvalidNamesInHealthData: state.therapie.hasHealthDataInvalidName,
    hasInvalidNamesInSymtoms: state.therapie.hasSymptomsInvalidName,
  };
};

export default connect(
  mapStateToProps,
  null,
)(ActivityMyList);
