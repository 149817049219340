//import * as actionTypes from './actionTypes';
import * as axiosCommon from '../../axios/axios-common';
import * as url from './urls';
import {
    commonErrorHandler,
  } from "./index";

//sends the token to backend
export const publishPushNotificationToken = (token, platform, deviceId) => {
  let requestUrl = url.REMOTE_PUSH_NOTIFICATION_TOKEN;
  let data = {firebaseToken: token, platform, deviceId};
  return dispatch => {
    axiosCommon.axiosPOST(requestUrl, res => {
        if(res.type !== 'SUCCESS') {
            dispatch(commonErrorHandler('Could not register for notifications.'));
        }
    }, data);
  };

};

export const snoozeReminder = (routineId, type, snoozeInterval, calback) => {
  let data = {notificationId: routineId, snoozeInterval, notificationType: type};
  return dispatch => {
    axiosCommon.axiosPOST(url.REMINDER_SNOOZE, res => {
      if(res.type !== 'SUCCESS') {
          dispatch(commonErrorHandler('Could not snooze reminder.'));
          calback(false);
          return;
      }
          calback(true);
      }, data);
  };
};
