import React, {Component} from 'react';
import {connect} from 'react-redux';
import {View, Text} from 'react-native';
import styles from '../../Css';
import {MenuOption} from 'react-native-popup-menu';
import * as actions from '../../store/actions/index';
class Activity extends Component {
  constructor(props) {
    super(props);
    const { windowHeight, windowWidth } = props;
    this.state = {
      treatmentInfoValues: null,
      ingredients: [],
      wait: true,
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    };
  }

  onPressViewFullTherapy = () => {
    const {treatment, handleRedirectActivity, onSelectTherapie} = this.props;
    if (treatment) {
      onSelectTherapie(treatment, () => {
        handleRedirectActivity();
      });
    }
  };

  Capitalize = str => {
    if (str === 'AS_NEEDED') {
      return 'As Needed';
    } else {
      return str
        ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
        : null;
    }
  };

  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth } = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }


  render() {
    const {treatment, windowWidth} = this.props;
    let name;
    let instruction = null;
    let frequencyType = '';
    let units = '';
    if (treatment) {
      name = treatment.name;
      instruction =
        treatment.dosages[0] && treatment.dosages[0].instructions
          ? treatment.dosages[0].instructions
          : null;
      frequencyType =
        treatment.dosages[0] && treatment.dosages[0].frequencyType
          ? treatment.dosages[0].frequencyType
          : null;
      units =
        treatment.dosages[0] && treatment.dosages[0].units
          ? treatment.dosages[0].units
          : null;
    }
    return (
      <MenuOption style={styles.comPd} onSelect={this.onPressViewFullTherapy}>
        <View
          style={[
            styles.fieldToolTipBlock,
            styles.toolTipInfoBox,
            windowWidth < 375
            ? styles.toolTipInfoBoxSm
            : windowWidth < 575
            ? styles.toolTipInfoBoxMd
            : styles.toolTipInfoBoxRg,
          ]}>
          {/* {treatmentInfoValues && ( */}
          <View style={[styles.comSpSm]}>
            <View style={[styles.comMgBotSm]}>
              <Text style={[styles.textPrimeMdBold, styles.textGreyDark]}  allowFontScaling={false}>
                {name}
              </Text>
            </View>
            <View style={[styles.tableViewCom, styles.grayBox]}>
              <View style={[styles.flexCom]}>
                <Text style={[styles.textPrime, styles.textColorLight]}  allowFontScaling={false}>
                  Instructions
                </Text>
              </View>
              <View style={[styles.flexCom]}>
                <Text style={[styles.textPrime, styles.textColorLight]}  allowFontScaling={false}>
                  {instruction}
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableViewCom,
                styles.comMgTopExSm,
                styles.toolTipInfoBox,
                windowWidth < 375
                ? styles.toolTipInfoBoxSm
                : windowWidth < 575
                ? styles.toolTipInfoBoxMd
                : styles.toolTipInfoBoxRg,
              ]}>
              <View style={[styles.trTitleView]}>
                <View style={[styles.tdColViewCom, styles.tdColLgViewCom]}>
                  <Text style={[styles.textMicSm, styles.grayboxTitle]}  allowFontScaling={false}>
                    Frequency
                  </Text>
                </View>
                <View style={[styles.tdColViewCom, styles.tdColSmViewCom]}>
                  <Text style={[styles.textMicSm, styles.grayboxTitle]}  allowFontScaling={false}>
                    Measure
                  </Text>
                </View>
              </View>
              {/* Loop Section Start */}
              {/* {ingredients.map((item, index) => ( */}
              <View
                style={[styles.trDataViewCom]}
                // key={"med_ingredients_" + index}
              >
                <View style={[styles.tdColViewCom, styles.tdColLgViewCom]}>
                  <Text style={[styles.textPrimeSmBold, styles.textGreyDark]}  allowFontScaling={false}>
                    {this.Capitalize(frequencyType)}
                  </Text>
                </View>
                <View style={[styles.tdColViewCom, styles.tdColSmViewCom]}>
                  <Text style={[styles.textPrimeSmBold, styles.textGreyDark]}  allowFontScaling={false}>
                    {this.Capitalize(units)}
                  </Text>
                </View>
              </View>
              {/* ))} */}

              {/* Loop Section End */}
            </View>
          </View>
          {/* )} */}

          {/* {wait && (
            <View
              style={[
                styles.indicatorFixed,
                styles.flexJcCtAiCt,
                styles.indToolBox,
              ]}
            >
              <ActivityIndicatorCustom pdZero={true} size="small" />
            </View>
          )} */}

          <View style={[styles.moreLink]}>
            {/* <TouchableOpacity onPress={this.onPressViewFullTherapy}> */}
              <Text style={[styles.textPrimeSm, styles.textOrange]}  allowFontScaling={false}>
                View more information
              </Text>
            {/* </TouchableOpacity> */}
          </View>
        </View>
      </MenuOption>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSelectTherapie: (SelectedTherapie, callBack) =>
      dispatch(actions.selectTherapie(SelectedTherapie, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Activity);

