import theme from './base/theme';

export const cssDashboard = {
  dashboardWrapper: {
    backgroundColor: theme.COLOR_WHITE,
    borderWidth: 1,
    borderColor: theme.COLOR_GREY_LIGHT_EX_20,
    //paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  titleBottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: theme.COLOR_GREY_LIGHT_EX_20,
  },
  sheduleText: {
    fontSize: 20,
    fontWeight: '700',
    padding: 0,
    color: theme.COLOR_GREY_DARK,
  },
  headerDashboardContent: {
    width: '100%',
    height: 48,
    backgroundColor: theme.COLOR_GRAY_LIGHT_EX_24,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems:"center",
    justifyContent: 'space-between',
  },
  editIconDash: {
    height: 28,
    width: 28,
  },
  titleDashCont: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  surveyText: {
    color:theme.COLOR_GREY_DARK,
    fontWeight: '700',
    fontSize:16,
    padding:0 ,
    marginLeft:8,
  },
  completeText:{
    fontSize:12,
    fontWeight: '500',
    color:theme.COLOR_GREY_DARK_1,
  },
  surveyContent:{
    width:'100%',
    //minHeight:130,
    maxHeight:"100%",
    backgroundColor:theme.COLOR_WHITE,
    paddingLeft:10,
    paddingRight:10,
    paddingTop: 20
  },
  dashBoardContentMain:{
 //minHeight:203,
 width:"100%",
 marginBottom:10,
 borderColor:theme.COLOR_GREY_LIGHT_RG,
 borderWidth:1,
 borderRadius:3,
  },
  taskContainer:{
    flexDirection:'row',
    justifyContent:'space-between',
    marginBottom:20,

  },
  dueContainer:{
    backgroundColor:theme.COLOR_OCEAN_BLUE_2,
    minWidth:96,
    maxWidth:'100%',
    height:24,
    borderRadius:8,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  overDueBGColor: {
    backgroundColor:theme.COLOR_RED_4,
  },
  dueCompletedBGColor: {
    backgroundColor: theme.COLOR_GREEN_8
  },
  clockDash: {
    height:14,
    width:14,
    marginRight:10,
  },
  studyContainer:{
    flex: 1,
    flexDirection:'row',
    alignItems:'center'
  },
  dueText:{
    fontSize:12,
    fontWeight: '500',
    color:theme.COLOR_BLUE_17,
    padding:0,
  },
  overDueTextColor: {
    color: theme.COLOR_RED_3
  },
  dueCompletedTextColor: {
    color: theme.COLOR_GREEN_7
  },
  studyText:{
    fontSize:14,
    fontWeight: '400',
    color:theme.COLOR_BLACK,
    marginLeft:6,
    padding:0,


  },
  customBtn:{
    width:144,
    height:32,
    backgroundColor:theme.COLOR_GREY_LIGHT_RG,
    borderRadius:22,
    color:theme.COLOR_GREY_DARK,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'row',
    fontSize:12,
    marginBottom:20,
    alignSelf:'center',
  },
  blueborder:{
    height:32,
    // minWidth:187,
    // maxWidth:250,
    borderColor:theme.COLOR_BLUE_DARK_2,
    borderRadius:48,
    borderWidth:2,
    paddingLeft:20,
    paddingRight:20,
    marginTop:10,
  }
};
