import React from "react";
import { View, Text } from "react-native";
import styles from "../../../../Css";
import CollapseDetailDescription from "../../../TabCollapse/collapseDetailDescription";

const routineTabContent = (props) => {
  const { windowWidth, onPressSetupNow } = props;

  return (
    <CollapseDetailDescription
      itemTitle={"Let’s create a routine!"}
      imgSource={require("../../../../assets/setup/routine.png")}
      windowWidth={windowWidth}
      lastChild={true}
      onPressSetupNow={onPressSetupNow}
    >
      <View style={[styles.collapseContDec]}>
        <View style={[styles.collapseContPara]}>
          <Text style={[styles.textPrimeSm, styles.textGrey]} allowFontScaling={false}>
            Creating up a routine allows you to create a template for your
            journal entries. You can also set reminders for reoccuring
            medications, supplements and activties while tracking health data
            and symptoms.
          </Text>
        </View>
      </View>
    </CollapseDetailDescription>
  );
};

export default (routineTabContent);
