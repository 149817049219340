import {Linking, Platform} from 'react-native';
import { WEB } from '../util/platformWindowConfig';


const openLink = url => {
  if (Platform.OS === WEB) {
    window.open(url, '_blank');
  } else {
    Linking.openURL(url);
  }
};

export {openLink};
