import React, { Component } from 'react';
import { View, Text } from 'react-native';
import styles from '../../../../Css';
import { connect } from 'react-redux';
import TextPickerFrame from '../../../TextInput/textPickerFrame';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextInputPlusMinus from '../../../TextInput/TextInputPlusMinus';
import * as actions from '../../../../store/actions/index';
import MedticationTooltipContent from '../../../TooltipContent/Medtication';
import ItemDeleteButton from '../../../Button/itemDeleteButton';
import TextFrame from '../../../TextInput/textFrame';
import Warning from '../../../Warning/warning';
import TextFieldToolTip from "./../../../../components/TextInput/textFieldToolTip";
import { verifyDuplicateMedication, getMedicationUnits } from '../../../../util/commonUiLogic';

class SystemItem extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    const quanity =
      data.quantity ? data.quantity : (data.dosages[0]?.quantity || data.dosages[0]?.quantity === 0) ? data.dosages[0]?.quantity : null;
    this.state = {
      ...data,
      units: [],
      accept: true,
      wait: false,
      quantity: quanity,
      duplicatePopup: false,
      unitValueChanging: false
    };
  }

  componentDidMount() {
    const { data, drugAdministrationsDigits } = this.props;
    const { identificationNumber, treatmentType } = data;

    if (identificationNumber && treatmentType) {
      this.getTreatmentInfoValues(identificationNumber, treatmentType);
    } else {
      const units = getMedicationUnits(null, drugAdministrationsDigits);//this.getUnit(null);
      const unit = data.units ? data.units : data.dosages[0]?.units ? data.dosages[0]?.units : 'Tablets';
      this.updateMedicationValues(units, identificationNumber);
      this.setState({
        units: units,
        unit: unit,
      }, () => {
        this.verifyDuplicateMedication(data.name, null, this.state.unit, this.state.quantity);
      });
    }
  }


  static getDerivedStateFromProps(props, state) {
    const { data, isNew, type } = props;
    //const {identificationNumber, treatmentType} = data;
    const quantity = data.quantity ? data.quantity : (data.dosages[0]?.quantity || data.dosages[0]?.quantity === 0) ? data.dosages[0]?.quantity : null;
    const unit =
      data && data.dosages && data.dosages[0].units
        ? data.dosages[0].units
        : 'Tablets';

    if (!state.unitValueChanging && (quantity !== state.quanity || unit !== state.unit)) {
      return {
        quantity: quantity,
        // units: units,
        //unit: unit,
      };
    }

    return null;
  }

  getTreatmentInfoValues = (din_npn, treatmentType) => {
    const { unit } = this.state;
    const { getTreatmentInfo, drugAdministrationsDigits, data } = this.props;
    const units = getMedicationUnits(null, drugAdministrationsDigits)
    getTreatmentInfo(din_npn, treatmentType, (success, res) => {
      if (success) {
        const { unitValue, unitList } = res;
        this.updateMedicationValues(unitList, din_npn);
        this.setState({
          units: unitList,
          unit: data?.dosages?.[0]?.units ?? data?.unit ?? unitValue,
        }, () => {
          this.verifyDuplicateMedication(this.state.name, din_npn, (data?.dosages?.[0]?.units ?? data?.unit ?? unitValue), this.state.quantity);
        });
      } else {
        const foundTablet = units.find(res => res.value === 'Tablets');
        const unitVal = data?.dosages?.[0]?.units ?? data?.unit ?? foundTablet ? 'Tablets' : units && units[0] && units[0].value ? units[0].value : null;
        this.updateMedicationValues(units);
        this.setState({
          units: units,
          unit: unitVal,
        }, () => {
          this.verifyDuplicateMedication(this.state.name, null, unitVal);
        });
      }
    },
    unit,
    units,
    drugAdministrationsDigits,
    false,
    data?.dosages?.[0]?.units);
  };

  updateMedicationValues = (units, identificationNumber = null) => {
    const {
      data,
      index,
      updateMedication,
    } = this.props;
    const foundTablet = units.find(res => res.value === 'Tablets');
    updateMedication(index, {
      ...data,
      identificationNumber: identificationNumber,
      ...(data.dosages && {
        dosages: [
          {
            ...data.dosages[0],
            identificationNumber: identificationNumber,
            units: data?.dosages?.[0]?.units ? data?.dosages?.[0]?.units : data.unit
              ? data.unit
              : foundTablet
                ? 'Tablets'
                : units && units[0] && units[0].value ? units[0].value : null,
          },
        ],
      }),
      ...(data.userDosingLog && {
        userDosingLog: {
          ...data.userDosingLog,
          units: data?.dosages?.[0]?.units ? data?.dosages?.[0]?.units : data.unit
            ? data.unit
            : foundTablet
              ? 'Tablets'
              : units && units[0] && units[0].value ? units[0].value : null,
        },
      }),
    });
  };

  onValueChange = val => {
    this.setState({ unit: val, unitValueChanging: true }, () => {
      const { data, updateMedication, index } = this.props;
      const { quantity } = this.state;
      updateMedication(index, {
        ...data,
        dosages: [{ ...data.dosages[Object.keys(data.dosages)[0]], units: val }],
        userDosingLog: { ...data.userDosingLog, units: val },
      });
      this.verifyDuplicateMedication(
        data.name,
        data.identificationNumber,
        val,
        quantity,
      );
      this.setState({ unitValueChanging: false });
    });
  };

  onChange = val => {
    this.setState(
      { quantity: val, accept: val || val === '0' || val === 0 ? true : false },
      () => {
        const { data, updateMedication, index } = this.props;
        const { unit } = this.state;
        updateMedication(index, {
          ...data,
          dosages: [
            { ...data.dosages[Object.keys(data.dosages)[0]], quantity: val },
          ],
          userDosingLog: { ...data.userDosingLog, quantity: val },
          accept: val || val === '0' || val === 0 ? true : false,
        });
        this.verifyDuplicateMedication(
          data.name,
          data.identificationNumber,
          unit,
          val,
        );
      },
    );
  };

  onAccept = () => {
    const { updateMedication, index, data } = this.props;
    const { accept, quantity } = this.state;
    if (quantity || quantity === 0) {
      //&& quantity  !== '0' remove for fixed CR-1538
      this.setState({ accept: !accept }, () =>
        updateMedication(index, {
          ...data,
          accept: !accept,
        }),
      );
    }
  };

  verifyDuplicateMedication = (name, din_npn, unit, quantity) => {
    const { listTherapie, saveDisable, hasInvalidNamesInActivity, hasInvalidNamesInMedication,
      hasInvalidNamesInHealthData, hasInvalidNamesInSymtoms } = this.props;
    verifyDuplicateMedication(name, din_npn, unit, quantity, listTherapie, (exist, msg) => {
      if (exist) {
        this.setState({
          duplicatePopup: true,
          errorDescription: msg,
        }, () => {
          saveDisable(true);
        });
      } else {
        this.setState({
          duplicatePopup: false,
          errorDescription: null,
        }, () => {
          if (!hasInvalidNamesInActivity && !hasInvalidNamesInMedication && !hasInvalidNamesInHealthData && !hasInvalidNamesInSymtoms) {


            saveDisable(false);
          } else {

            saveDisable(true);
          }
        });
      }
    });
  };

  changeMedicationName = (val) => {
    const { updateMedication, index, data } = this.props;
    // console.log(data);
    this.setState(
      () => {
        updateMedication(index, {
          ...data,
          name: val,
        });
        this.verifyDuplicateMedication(val, null, this.state.unit, this.state.quantity);
      });
  }

  render() {
    const {
      data,
      handleRedirectMedications,
      windowWidth,
      deleteItem,
      index,
    } = this.props;
    const { name, identificationNumber, treatmentType, type, accept } = data;
    const {
      units,
      quantity,
      unit,
      duplicatePopup,
      errorDescription,
    } = this.state;
    let category = this.props.type;
    return (
      <>
        <View style={category !== 'RT' ? [styles.ActivitySliderCardView, styles.bgDarkAsh] : null}>
          <View>
            {category !== 'RT' && <View style={[styles.basisStyle]} />}
            {category !== 'RT' && type === 'SYSTEM' ?
              <View style={[styles.iconLtSp, styles.pdTopSm]}>
                <View style={[styles.flexRowAiCt]}>
                  <Text style={[styles.textPrimeBold, styles.textBlack, styles.rightSpExLg, { alignItems: 'baseline' },
                    // publishedBy === 'PARTNER' && styles.mgTpTwelve --- ZI-4049 - issue fixed by this comment
                  ]}>
                    {`${name}`}
                  </Text>
                  <View style={[{ width: 2, height: 2 }]}>
                    <TextFieldToolTip isBlueIcon={true} renderComponent={() => (
                      <MedticationTooltipContent
                        identificationNumber={identificationNumber}
                        treatmentType={treatmentType}
                        treatment={null}
                        handleRedirectMedications={handleRedirectMedications}
                        windowWidth={windowWidth}
                        showLink={false}
                      />
                    )}
                    />
                  </View>
                </View>
              </View>
              :
              <View style={category === 'RT' && [styles.fieldContainerCom]}>
                <View style={[styles.fieldRowWrapper, category !== 'RT' && [styles.attachmentCont, styles.mgTopLg]]}>
                  <View style={styles.fieldColStretch}>
                    {category !== 'RT' ?
                      <TextFrame
                        title={'Medication'}
                        fieldBg={data.type === 'CUSTOM' ? (name === '' || name === null || name === undefined) && FieldDetails.BGRED : FieldDetails.BGGREY}
                        fieldInfo={type === 'SYSTEM' ? true : false}
                        fieldValue={name}
                        noOfLines={1}
                        onChangeText={this.changeMedicationName}
                        editable={data.type === 'CUSTOM' ? true : false}
                        darkTxt={true}
                      />
                      :
                      <TextFrame
                        title={'Medication'}
                        fieldBg={type === 'CUSTOM' ? (name === '' || name === null || name === undefined) && FieldDetails.BGRED : FieldDetails.BGGREY}
                        fieldInfo={type === 'SYSTEM' ? true : false}
                        fieldValue={name}
                        onChangeText={this.changeMedicationName}
                        editable={type === 'CUSTOM' ? true : false}
                        renderComponent={() => (
                          <MedticationTooltipContent
                            identificationNumber={identificationNumber}
                            treatmentType={treatmentType}
                            treatment={null}
                            handleRedirectMedications={handleRedirectMedications}
                            windowWidth={windowWidth}
                            showLink={false}
                          />
                        )}
                        noOfLines={1}
                      />
                    }
                  </View>
                  <View style={styles.fieldColSp} />
                  <View style={styles.fieldColDelete}>
                    <ItemDeleteButton touchableOnPress={() => deleteItem(index)} />
                  </View>
                </View>
              </View>
            }
          </View>
          <View style={type === 'CUSTOM ' && { marginRight: 36 }}>
            <View style={[category !== 'RT' && styles.iconLtSp, { marginBottom: 20 }]}>
              <View style={[styles.flexRow, styles.mgTopLg, category !== 'RT' && styles.rightSpSm]}>
                <View>
                  <TextInputPlusMinus
                    title={'Quantity'}
                    fieldBg={FieldDetails.BGWHITE}
                    fieldAlign={FieldDetails.ALIGNCENTER}
                    fieldLt={false}
                    darkTxt={true}
                    value={quantity}
                    onChange={this.onChange}
                    type={'ACTIVITY'}
                    units={units}
                    max={null}
                    activitySlider={true}
                  />
                </View>
                <View style={styles.wdTen}></View>
                <View style={category !== 'RT' && [styles.fieldColStretch, type !== 'CUSTOM ' && styles.rightSpSm]}>
                  <TextPickerFrame
                    title={category !== 'RT' ? 'Measure' : 'Unit'}
                    fieldBg={FieldDetails.BGWHITE}
                    fieldSm={false}
                    darkTxt={true}
                    items={units}
                    onValueChange={this.onValueChange}
                    selectedValue={unit}
                    activitySlider={false}
                  />
                </View>
                {category !== 'RT' && type === 'SYSTEM' ? (
                  <View style={[styles.fieldColDelete, styles.flexJcCt]}>
                    <View style={styles.fieldColDelete}>
                      <ItemDeleteButton touchableOnPress={() => deleteItem(index)} />
                    </View>
                  </View>
                ) : <View style={{ marginRight: 36 }} />}
              </View>
            </View>
          </View>
          {Boolean(duplicatePopup) && (
            <>
              <Warning
                errorDescription={errorDescription}
                textPosition={'left'}
                warningBlockSp={category !== 'RT' ? true : false}
                warningBlockMg={true}
                journalTreatmentWarning={true}
              />
            </>
          )}
        </View>
        <View style={[styles.secSepBorderBot, styles.comSp, styles.titleTopPd]} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    drugAdministrationsDigits: state.routines.drugAdministrationsDigits,
    listTherapie: state.routines.treatments,
    hasInvalidNamesInActivity: state.therapie.hasActivityInvalidName,
    hasInvalidNamesInMedication: state.therapie.hasMedicationInvalidName,
    hasInvalidNamesInHealthData: state.therapie.hasHealthDataInvalidName,
    hasInvalidNamesInSymtoms: state.therapie.hasSymptomsInvalidName,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveDisable: flag => dispatch(actions.updatesaveDisable(flag)),
    getTreatmentInfo: (din_npn, treatmentType, callBack, unit, units, drugAdministrationsDigits, displayFullName, selectedUnit) => 
      dispatch(actions.getTreatmentInfo(din_npn, treatmentType, callBack, unit, units, drugAdministrationsDigits, displayFullName, selectedUnit)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemItem);
