import React, {Component} from 'react';
import {View, Text, TouchableOpacity, Platform } from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import ConfirmationBox from '../../components/ConfirmationBox/deleteMedicationconfirmationBox';
import CannotDeleteBox from '../../components/ConfirmationBox/cannotDeleteBox';
import Header from '../../components/Header/header';
import stylesResponsive from '../../CssResponsive';
import styles from '../../Css';
import Tabs from '../../components/Tab/Tab';
import Collapsible from '../../components/Collapsible/Collapsible';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import TherapieHeader from '../../components/MedicationsOrTherapies/View/header';
import Activity from '../../components/MedicationsOrTherapies/View/activity';
import Medication from '../../components/MedicationsOrTherapies/View/medication';
import Attachments from '../../components/Attachment/Attachments';
import Question from '../../components/Question/View';
import Information from '../../components/MedicationsOrTherapies/Information';
import {DASHBOARD, IMAGES_DOCUMENTS, MEDICATIONS, TODOS, REPORTS, HEALTH_JOURNEY} from '../../navigation/path';
import StorageBanner from '../../components/Banner/StorageBanner';
import PageContainer from '../../components/PageLayout/PageContainer';
import AddAttachment from '../../components/Button/addAttachment';
import Cropper from '../../components/ImageCropper/AmazingCropperPage';
import { pendoTrackEvent } from '../../util/pendoConfig';
import MedicationsOrTherapiesItem from '../../components/MedicationsOrTherapies/MedicationsOrTherapiesItem/MedicationsOrTherapiesItem';
import { ANDROID, TAB_BREAk_POINT_END } from '../../util/platformWindowConfig';

class ViewMedicationsOrTherapiesScreen extends Component {
  constructor(props) {
    super(props);
    if (this.props.selectedTherapie) {
      const questions = this.getQuestions(
        this.props.questionDigest.userArtifactHealthQuestions,
        this.props.selectedTherapie.artifactId,
      );
      if (this.props.activeTab !== 0) {
        this.props.setActiveTab(0);
      }
      if (this.props.route?.params &&
        this.props.route?.params?.activeAttachmentTab) {
          this.props.setActiveTab(1);
      }
      this.state = {
        collapsible: {
          historicalCourses: false,
          notes: false,
          treatmentInfo: false,
          questions: questions && questions.length > 0 ? true : false,
        },
        questions: questions,
        windowHeight: props.windowHeight,
        windowWidth: props.windowWidth,
        treatmentInfoValues: null,
        cannotDeleteBox: false,
        hasRecordings: ViewMedicationsOrTherapiesScreen.getHasRecordings(props.selectedTherapie.id, props.journalEntriesProcessed),
        fromHealthJourney: props.route?.params?.from === HEALTH_JOURNEY.path,
        activeWait: false,
      };
      this.tapCount = 0;
    } else {
      this.props.navigation.push(
        MEDICATIONS.path, {
          from: this.props.route.name,
        },
      );
    }
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      const {selectedTherapie, questionDigest, journalEntriesProcessed} = this.props;
      if (
        selectedTherapie.identificationNumber &&
        selectedTherapie.treatmentType !== 5
      ) {
        this.getTreatmentInfoValues();
      } else {
        const questions = this.getQuestions(
          questionDigest.userArtifactHealthQuestions,
          selectedTherapie.artifactId,
        );
        this.setState({
          questions: questions,
          hasRecordings: ViewMedicationsOrTherapiesScreen.getHasRecordings(selectedTherapie.id, journalEntriesProcessed),
          collapsible: {
            historicalCourses: false,
            notes: selectedTherapie.notes ? true : false,
            treatmentInfo: false,
            questions:
              (this.state.questions && this.state.questions.length > 0) || (questions && questions.length > 0)
                ? true
                : false,
          },
        });
      }
      pendoTrackEvent('MedSupp+Act/@ViewTherapy');
    });
    this.props.navigation.addListener('blur', () => {
      this.tapCount = 0;
    });
  }

  getTreatmentInfoValues = () => {
    const {selectedTherapie, questionDigest, journalEntriesProcessed, getTreatmentInfo} = this.props;
    if (selectedTherapie && selectedTherapie?.identificationNumber && selectedTherapie?.treatmentType) {
      getTreatmentInfo(selectedTherapie?.identificationNumber, selectedTherapie?.treatmentType, (success, res) => {
        if (success) {
          const { value } = res;
          let treatmentInfoValues = null;
          if (value) {
            treatmentInfoValues = value;
          }
          const questions = this.getQuestions(
            questionDigest.userArtifactHealthQuestions,
            selectedTherapie.artifactId,
          );
          this.setState({
            treatmentInfoValues: treatmentInfoValues,
            questions: questions,
            hasRecordings: ViewMedicationsOrTherapiesScreen.getHasRecordings(selectedTherapie.id, journalEntriesProcessed),
            collapsible: {
              historicalCourses: false,
              notes: selectedTherapie.notes ? true : false,
              treatmentInfo: treatmentInfoValues ? true : false,
              questions:
                (this.state.questions && this.state.questions.length > 0) || (questions && questions.length > 0)
                  ? true
                  : false,
            },
          });
        }
      });
    }
  };

  getQuestions = (questions, artifactId) => {
    let questionsArray = [];
    if (questions) {
      questionsArray = questions.filter(function(question) {
        return question.artifactId === artifactId;
      });
    }

    const unique = questionsArray
      .map(e => e['questionId'])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => questionsArray[e])
      .map(e => questionsArray[e]);
    return unique;
  };

  handleOnPressCollapsible = (key, val) => {
    let collapsibleTemp = this.state.collapsible;
    collapsibleTemp[key] = !val;
    this.setState({collapsible: collapsibleTemp});
  };

  handleDeleteTherapieConfirmation = () => {
    this.setState({
      deleteConform: true,
    });
  };

  handleDeleteTherapie = () => {
    this.setState({deleteWait: true});
    if (this.state.hasRecordings) {
      this.setState({
        deleteWait: false,
        deleteConform: false
      });
      setTimeout(() => {
        this.setState({cannotDeleteBox: true});
      }, 500);
    } else {
      this.props.onDeleteTherapie(
        this.props.selectedTherapie.id,
        res => {
          this.setState({
            deleteWait: false,
            deleteConform: false,
          });
          if (res) {
            if (
              this.props.route?.params &&
              (this.props.route?.params.from === MEDICATIONS.path ||
              this.props.route?.params.from === TODOS.path ||
              this.props.route?.params.from === REPORTS.viewReportPath ||
              this.props.route?.params.from === REPORTS.newReportPath ||
              this.props.route?.params.from === REPORTS.editReportPath ||
              this.props.route?.params.from === IMAGES_DOCUMENTS.path)
            ) {
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: this.props.route?.params.from }],
              });
            } else {
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: DASHBOARD.path }],
              });
            }
          } else {
          }
        },
        this.props.journalUserId,
      );
    }
  };

  handleOnPressBackButton = () => {
    if (
      this.props.route?.params &&
      (this.props.route?.params.from === MEDICATIONS.path ||
        this.props.route?.params.from === TODOS.path ||
        this.props.route?.params.from === REPORTS.viewReportPath ||
        this.props.route?.params.from === REPORTS.newReportPath ||
        this.props.route?.params.from === REPORTS.editReportPath ||
        this.props.route?.params.from === IMAGES_DOCUMENTS.path)
    ) {
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: this.props.route?.params.from }],
      });
    } else if(this.props.route?.params && this.props.route?.params.from) {
      if (this.props.route?.params.from === MEDICATIONS.scanPrescriptionPath || this.props.route?.params.from === MEDICATIONS.path) {
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: MEDICATIONS.path }],
        });
      } else if (this.state.fromHealthJourney) {
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: this.props.windowWidth < TAB_BREAk_POINT_END && this.props.eventData && this.props.eventData["type"] ? HEALTH_JOURNEY.addEventPath : HEALTH_JOURNEY.path }],
        });
      } else {
        this.props.navigation.goBack();
      }
    } else {
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: DASHBOARD.path }],
      });
    }
  };

  handleEditTherapie = () => {
    this.tapCount = this.tapCount + 1;
    if (this.tapCount === 1) {
      const therapieTobeUpdated = {
        ...this.props.selectedTherapie,
        forceEdit: true
      }
      this.props.editTherapie(therapieTobeUpdated, () => {
        this.props.navigation.push(MEDICATIONS.editTherapiePath);
      });
    }
  };

  hasAttachments = artifactId => {
    let attachments = [];
    if (this.props.attachmentsCompleteDigest) {
      attachments = this.props.attachmentsCompleteDigest.userArtifactAttachments.filter(
        function(attachment) {
          return attachment.userArtifact === artifactId;
        },
      );
    }
    return attachments.length > 0;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {windowHeight, windowWidth} = nextProps;

    this.setState({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    });
  }

  static getHasRecordings = (id, journalEntriesProcessed) => {
    if (!id || !journalEntriesProcessed) return false;
    const journalEntries = Object.values(journalEntriesProcessed);
    return [
      ...new Map(
        []
          .concat(...journalEntries.map(entry => entry.dosages || []))
          .map(dosage => [dosage['treatment'], dosage]),
      ).values(),
    ].some(dosage => dosage.treatment === id);
  }

  selectedTab = tabid => {
    this.props.setActiveTab(tabid);
    if (tabid === 1) {
      this.props.fetchUserAttachments(this.props.selectedTherapie?.artifactId);
    }
  };

  openCroper = (capturedImage) => {
    this.setState({showCropperPage: true, image: capturedImage});
  }

  closeCroper = (croppedUri, width, height) => {
    if (croppedUri) {
      this.setState({
        showCropperPage: false, 
        image: {...this.state.image, uri: croppedUri, height: height, width: width},
      });
    } else {
      this.setState({
        showCropperPage: false, 
        image: {...this.state.image},
      });
    }
  }

  imageUploadCompleted = () => {
    this.setState({image: null});
  }

  handlePinUnpinFromDashboard = (flag, treatment) => {
    const {onPinToDashboard, journalUserId, onSelectTherapie} = this.props;
    const treatmentToBeUpdated = Object.assign({}, treatment);
    treatmentToBeUpdated.isPinned = flag;
    this.setState({activeWait: true}, () => {
      const updatedTreatment = {
        treatment: treatmentToBeUpdated,
      };
      onPinToDashboard(updatedTreatment, (success, medication) => {
        if (success) {
          onSelectTherapie(medication, () => {
            this.setState({activeWait: false});
          });
        } else {
          this.setState({activeWait: false});
        }
      },
      journalUserId)
    })
  }

  getMenuOptions = () => {
    const {selectedTherapie, showSuccessPopup} = this.props;
    const {activeWait} = this.state;
    const operations = [
      {id: "RESUME", title: "Resume", onSelect: () => showSuccessPopup(21, null, {treatment: selectedTherapie}), disabled: activeWait},
      {id: "PAUSE", title: "Pause", onSelect: () => showSuccessPopup(20, null, {treatment: selectedTherapie}), disabled: activeWait},
      {id: "STOP", title: "Stop", onSelect: () => showSuccessPopup(19, null, {treatment: selectedTherapie}), disabled: activeWait},
      {id: "UNPIN", title: "Unpin from Dashboard", onSelect: () => this.handlePinUnpinFromDashboard(false, selectedTherapie), activity: activeWait, disabled: activeWait},
      {id: "PIN", title: "Pin to Dashboard", onSelect: () => this.handlePinUnpinFromDashboard(true, selectedTherapie), activity: activeWait, disabled: activeWait},
      {id: "EDIT", title: "Edit", onSelect: this.handleEditTherapie, nativeId: 'medSuppActView3DotsEdit', disabled: activeWait},
      {id: "EDIT", title: "Delete", onSelect: () => this.setState({deleteConform: true}), disabled: activeWait} //kept the same id as EDIT
    ];
    return operations.filter(operation => MedicationsOrTherapiesItem.filterOperations(operation, selectedTherapie));
  }

  handleWhenHaveData = () => {
    this.setState({deleteConform: false}, () => {
      setTimeout(() => this.setState({cannotDeleteBox: true}), 500);
    })
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {
      questions,
      collapsible,
      treatmentInfoValues,
      showCropperPage,
      image,
      fromHealthJourney,
      hasRecordings,
      deleteConform,
      cannotDeleteBox
    } = this.state;
    const {
      selectedTherapie,
      questionDigestProcessed,
      questionDigest,
      hasNotch,
      contactsProcessed,
      unitsOfMeasureDigestProcessed,
      metricsDigestProcessed,
      hideMobileMenu,
      showMobileMenu,
      onDeleteTherapie,
      journalUserId,
    } = this.props;
    if (showCropperPage) {
      hideMobileMenu();
    } else {
      showMobileMenu();
    }
    return (
      showCropperPage 
      ? <Cropper image={image} close={this.closeCroper} />
      : <React.Fragment>
        <View style={[styles.pageBodyWrapper]}>
          <Header
            showFilers={false}
            showBack={true}
            showClearFilters={false}
            showNotify={true}
            handleOnPressBackButton={this.handleOnPressBackButton}
            index={fromHealthJourney ? HEALTH_JOURNEY.index : MEDICATIONS.index}
            navigation={this.props.navigation}
            route={this.props.route}
          />
          <SpaceBar />
          <StorageBanner />
          <KeyboardAwareScrollViewHoc hasNotch={hasNotch} extraScrollHeight={Platform.OS === ANDROID ? 200 : 70}>
              {Boolean(selectedTherapie) && (
              <View style={styles.gridSection}>
                <TherapieHeader
                  selectedTherapie={selectedTherapie}
                  handleDeleteTherapieConfirmation={
                    this.handleDeleteTherapieConfirmation
                  }
                  handleEditTherapie={this.handleEditTherapie}
                  windowWidth={this.state.windowWidth}
                  hasRecordings={this.state.hasRecordings}
                  menuOptions={this.getMenuOptions()}
                />
                <Tabs 
                  pageContainer={true} 
                  smScreenFull={true}
                  activeTab={this.props.activeTab}
                  tabIndex={(res) => this.selectedTab(res)}
                >
                  {/* Details tab */}
                  <View title="Details" style={[styles.tabContentSection]}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View
                          style={[stylesRes.tabContentFullArea, stylesRes.botPd]} dataSet={{media: ids.botPd}}>
                          {/* Newly Added Start */}
                          <PageContainer smScreenFull={true}>
                            {selectedTherapie.treatmentType === 5 && (
                              <Activity
                                selectedTherapie={selectedTherapie}
                                contactsProcessed={contactsProcessed}
                                unitsOfMeasureDigestProcessed={
                                  unitsOfMeasureDigestProcessed
                                }
                                metricsDigestProcessed={metricsDigestProcessed}
                              />
                            )}

                            {selectedTherapie.treatmentType !== 5 && (
                              <Medication
                                selectedTherapie={selectedTherapie}
                                contactsProcessed={contactsProcessed}
                              />
                            )}
                            {/* Newly Added End */}
                          </PageContainer>
                          <PageContainer smScreenFull={true}>
                            <View
                              style={[
                                styles.collapseArea,
                                styles.comMgTopLg,
                              ]}>
                              <View style={styles.collapseView}>
                                {/* Notes*/}
                                  {Boolean(selectedTherapie.notes) && (
                                  <Collapsible
                                    title="Notes"
                                    contentVisibility={collapsible.notes}
                                    handleOnPressCollapsible={res =>
                                      this.handleOnPressCollapsible('notes', res)
                                    }
                                    plusButton={true}
                                    topBorder={true}>
                                    <View style={[styles.collapseContent]}>
                                      <View style={styles.gridRow}>
                                        <View style={styles.gridColFull}>
                                          <View style={[styles.fieldRowView]}>
                                            <View
                                              style={[
                                                styles.collapseDescription,
                                                styles.borderThin,
                                                styles.collapseContentBg,
                                              ]}>
                                              <View
                                                style={[
                                                  styles.collapseInContent,
                                                  styles.colPdLtRt,
                                                  styles.listTopBotPd,
                                                ]}>
                                                <View
                                                  style={[
                                                    styles.detailViewFull,
                                                  ]}>
                                                  <Text
                                                    style={styles.smDescText} allowFontScaling={false}>
                                                    {selectedTherapie.notes}
                                                  </Text>
                                                </View>
                                              </View>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  </Collapsible>
                                )}

                                {/* Questions*/}
                                {questions && questions.length > 0 && (
                                  <Collapsible 
                                    title="To-dos"
                                    contentVisibility={collapsible.questions}
                                    handleOnPressCollapsible={res =>
                                      this.handleOnPressCollapsible(
                                        'questions',
                                        res,
                                      )
                                    }
                                    plusButton={true}
                                    topBorder={true}>
                                    <View
                                      style={[
                                        styles.collapseContentBg,
                                        styles.colPdTopLtRt,
                                        styles.questItemViewWrap
                                      ]}>
                                      {questions.map((res, index) => (
                                        <View
                                          style={[ styles.questItemViewWrapIn]}
                                          key={'qu_1' + index}>
                                          <Question
                                            question={
                                              questionDigestProcessed.questions[
                                                res.questionId
                                              ]
                                            }
                                            questionDigest={questionDigest}
                                            parentArtifactId={
                                              selectedTherapie.artifactId
                                            }
                                            key={'qu_' + index}
                                            bgDark={true}
                                          />
                                        </View>
                                      ))}
                                    </View>
                                  </Collapsible>
                                )}

                                  {Boolean(treatmentInfoValues) && (
                                  <Collapsible
                                    title="Medication/Supplement Information"
                                    contentVisibility={
                                      collapsible.treatmentInfo
                                    }
                                    handleOnPressCollapsible={res =>
                                      this.handleOnPressCollapsible(
                                        'treatmentInfo',
                                        res,
                                      )
                                    }
                                    plusButton={true}
                                    toolTipButton={true}
                                    toolTipId={'tool-tip-suppliment-info'}
                                    toolTipContent={
                                      'Find details about your medication/supplement including active ingredients and strength'
                                    }
                                    topBorder={true}
                                    customTitle={true}
                                    windowWidth={this.state.windowWidth}>
                                    <Information
                                      treatmentInfoValues={treatmentInfoValues}
                                    />
                                  </Collapsible>
                                )}
                              </View>
                            </View>
                            <View
                              style={[styles.subSection, styles.comMgTopMd]}>
                              <View style={[styles.btnBlock, styles.noMgTop]}>
                                <TouchableOpacity
                                  style={[
                                    styles.submit,
                                    styles.submitRg,
                                    styles.btnShadowCom,
                                    styles.submitBgAsh,
                                  ]}
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={this.handleOnPressBackButton}>
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent,
                                      ]}>
                                      <Text
                                        style={[
                                          styles.submitBtnTextWhite,
                                          styles.bold,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        Back
                                      </Text>
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </PageContainer>
                        </View>
                      </View>
                    </View>
                  </View>
                  {/* Attachments tab */}
                  <View
                    title="Attachments"
                    activeCircle={this.hasAttachments(
                      selectedTherapie.artifactId,
                    )}
                    style={[styles.tabContentSection, styles.comStyling]}
                    attachmentCategory="Therapy-view">
                    <PageContainer smScreenFull={true}>
                    <View style={[styles.attachWrapper, styles.attachWrapperPd]}>
                      <Attachments attachmentCategory="Therapy-view" attachmentSpaceZero={true} />
                      <AddAttachment 
                        attachmentCategory="Therapy-view" 
                        btnText={"Add"} 
                        btnPosition={"left"} 
                        openCroper={this.openCroper}
                        tempCapturedImage={this.state.image} 
                        onUploadCompleted={this.imageUploadCompleted} 
                      />
                    </View>
                    </PageContainer>
                  </View>
                </Tabs>
              </View>
            )}
          </KeyboardAwareScrollViewHoc>
        </View>
        {deleteConform && (
          <ConfirmationBox
            visibleModal={deleteConform}
            onRequestClose={() => {
              this.setState({deleteConform: false});
            }}
            selectedTherapie={selectedTherapie}
            onDeleteTherapie={onDeleteTherapie}
            journalUserId={journalUserId}
            handleWhenHaveData={this.handleWhenHaveData}
            hasRecordings={hasRecordings}
            onFinish={this.handleOnPressBackButton}
          />
        )}
        
        {/* cannot delete message box */}
        {cannotDeleteBox && (
          <CannotDeleteBox
            visibleModal={cannotDeleteBox}
            onBackdropPress={() => {
              this.setState({cannotDeleteBox: false});
            }}
            onRequestClose={() => {
              this.setState({cannotDeleteBox: false});
            }}
            name={selectedTherapie?.name}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedTherapie: state.therapie.selectedTherapie,
    contactsProcessed: state.routines.contactsProcessed,
    questionDigest: state.routines.questionDigest,
    questionDigestProcessed: state.routines.questionDigestProcessed,
    userAttachmentsSelected: state.routines.userAttachmentsSelected,
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    treatments: state.routines.treatments,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    // Spinners
    fetchAttachmentSpinner: state.routines.journalAttachmentSpinner,
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
    journalUserId: state.uistate.journalUserId,
    hasNotch: state.uistate.hasNotch,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    activeTab: state.surveys.activeTab,
    eventData: state.uistate.eventData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDeleteTherapie: (id, callBack, journalUserId) =>
      dispatch(actions.deleteTherapie(id, callBack, journalUserId)),
    onPauseMedication: (selectedTherapie, timeStamp, days, callBack) =>
      dispatch(
        actions.onPauseMedication(selectedTherapie, timeStamp, days, callBack),
      ),
    onActive: (selectedTherapie, val, callBack) =>
      dispatch(actions.onActive(selectedTherapie, val, callBack)),
    editTherapie: (therapie, callBack) =>
      dispatch(actions.editTherapie(therapie, callBack)),
    fetchUserAttachments: artifactId =>
      dispatch(actions.fetchUserAttachments(artifactId)),
    onResumeMedication: (selectedTherapie, callBack) =>
      dispatch(actions.onResumeMedication(selectedTherapie, callBack)),
    onSelectContact: (selectedContact, callBack) =>
      dispatch(actions.selectedContact(selectedContact, callBack)),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    setActiveTab: activeTabIndex =>
      dispatch(actions.setActiveTab(activeTabIndex)),
    showSuccessPopup: (id, graphId, deviceData) => dispatch(actions.showSuccessPopup(id, graphId, deviceData)),
    onPinToDashboard: (updatedTheropy, callBack, journalUserId) =>
      dispatch(actions.updateTherapie(updatedTheropy, callBack, journalUserId)),
    onSelectTherapie: (SelectedTherapie, callBack) =>
      dispatch(actions.selectTherapie(SelectedTherapie, callBack)),
    getTreatmentInfo: (din_npn, treatmentType, callBack) => 
      dispatch(actions.getTreatmentInfo(din_npn, treatmentType, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewMedicationsOrTherapiesScreen);
