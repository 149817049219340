import React, {Component} from 'react';
import {View} from 'react-native';

import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Collapsible from '../../components/Collapsible/Collapsible';
import TimeZoneDropDown from '../TimeZone/Dropdown';

class TimeZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentVisibility: false,
    };
  }

  handleOnPressCollapsible = () => {
    this.setState(prevState => ({
      contentVisibility: !prevState.contentVisibility,
    }));
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {contentVisibility} = this.state;
    const {timeZoneList, selectedTimeZone, processing, updateTimeZone, journalUserId, updateTimeZoneDropDownOpen} = this.props;
    return (
      // <View style={[styles.zIndexMin]}>
        <Collapsible
          title="Time Zone"
          contentVisibility={contentVisibility}
          style={[styles.collapseTitle, styles.zIndexMin]}
          handleOnPressCollapsible={this.handleOnPressCollapsible}
          titleLt={true}
          titleLg={true}
          topBorder={true}>
          <View style={[styles.collapseContent, styles.comSpMd]}>
            <View style={stylesRes.boxWrapperInWrapper}>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <TimeZoneDropDown
                    timeZoneList={timeZoneList}
                    selectedTimeZone={selectedTimeZone}
                    processing={processing}
                    updateTimeZone={updateTimeZone}
                    journalUserId={journalUserId}
                    updateTimeZoneDropDownOpen={updateTimeZoneDropDownOpen}
                  />
                </View>
              </View>
            </View>
          </View>
        </Collapsible>
      // </View>
    );
  }
}
export default (TimeZone);
