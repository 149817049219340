import React from "react";
import { View, TextInput } from "react-native";
import styles from "../../Css";
import FieldTitle from "./fieldTitle";
import * as FontScaling from "../../components/Text/fontScaling";

const textInputQuantityFrame = (props) => {
  const { value, keyboardType, onChangeText, editable } = props;
  return (
    <View
      style={[
        props.fieldSm ? styles.textInputSmWrapper : styles.textInputWrapper,
        styles.postRelative,
      ]}
    >
      <FieldTitle
        fieldAlign={props.fieldAlign}
        fieldBg={props.fieldBg}
        title={props.title}
        fieldLt={props.fieldLt}
        fieldSm={props.fieldSm}
        fieldRg={props.fieldRg}
      />

      <View
        style={[
          styles.textInputField,
          styles.frameQuanitityPd,
          styles.flexJcCtAiCt,
          styles.postRelative,
          props.fieldBg === "grey"
            ? styles.bgThinGrey
            : props.fieldBg === "blue"
            ? styles.bgBlueExLight
            : props.fieldBg === "transparent"
            ? styles.bgTransparent
            : styles.bgWhite,
        ]}
      >
        <TextInput
          style={[
            styles.textAlignCenter,
            styles.framePlusMinusTextBoxSm,
            styles.frameQuanitityBox,
          ]}
          allowFontScaling={false}
          underlineColorAndroid="transparent"
          value={value}
          keyboardType={keyboardType}
          onChangeText={onChangeText}
          editable={editable}
          maxLength={props.maxLength}
          maxFontSizeMultiplier={FontScaling.fontScaleField}
        />
      </View>
    </View>
  );
};

export default textInputQuantityFrame;
