import React, {Component} from 'react';
import {View, ScrollView, Text, Image, TouchableOpacity, Platform} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Header from '../../components/Header/header';
import Collapsible from '../../components/Collapsible/Collapsible';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import {CAREGIVERS, DASHBOARD} from '../../navigation/path';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import SimpleConfirmBox from '../../components/ConfirmationBox/simpleConfirmBox';
import SimpleOkBox from '../../components/ConfirmationBox/simpleOkBox';
import LearnHowPrivacyPolicy from '../SignUp/LearnHowPrivacyPolicy';
import PageContainer from '../../components/PageLayout/PageContainer';
import PrimaryButton from '../../components/Button/primaryButton';
import {getAccountType} from '../../util/subscriptionInformation';
import DashboardHOC from '../../components/Dashboard/DashboardHOC';
import {pendoTrackEvent} from '../../util/pendoConfig';
import { TAB_BREAk_POINT_START, WEB } from '../../util/platformWindowConfig';

const {Popover} = renderers;
class CaregiverAccessScreen extends Component {
  constructor(props) {
    super(props);
    if (props.journalUserId) {
      props.navigation.push(DASHBOARD.path);
    }
    const accountType = getAccountType(props.userSubscription);
    const invitationSentDiscounnetSharedAccess = this.getInvitationSentDiscounnetSharedAccess(
      props,
    );
    const connected = this.props.sharedAccessDigest.sharedAccessGivenToOthers.filter(
      res =>
        res.permissionType !== 'NONE' &&
        !invitationSentDiscounnetSharedAccess[res.grantedTo],
    );
    const other = this.getOtherList(props);
    const connectedWithOthers = this.props.sharedAccessDigest.sharedAccessGivenToMe.filter(
      res => res.permissionType !== 'NONE',
    );
    const invitationsReceived = this.getInvitationsReceived(props);
    this.state = {
      connected: connected,
      connectedWithOthers: connectedWithOthers,
      other: other,
      invitationsReceived: invitationsReceived,
      grantedTo: null,
      visibleModal: false,
      removeWait: false,
      visibleMycaregiverlist:
        (connected && connected.length > 0) || (other && other.length > 0),
      visiblePeopleIcareList:
        (connectedWithOthers && connectedWithOthers.length > 0) ||
        (invitationsReceived && invitationsReceived.length > 0),
      acceptWait: false,
      grantingUserICare: null,
      grantedToICare: null,
      visibleModalICare: false,
      visibleModalDecline: false,
      tokenDecline: false,
      grantingUserDecline: null,
      visibleModalCancel: false,
      cancelToken: null,
      cancelEmail: null,
      visibleModalResend: false,
      accountType: accountType,
      showLearnHowPrivacyPolicy: false,
      windowWidth: props.windowWidth,
    };
  }

  componentDidMount() {
    pendoTrackEvent('Caregivers@');
  }

  getInvitationSentDiscounnetSharedAccess = props => {
    const sharedAccessUserSummaries =
      props.sharedAccessDigest.sharedAccessUserSummaries;
    const pendingInvitationsSent =
      props.sharedAccessDigest.pendingInvitationsSent;
    const obj = {};
    if (pendingInvitationsSent) {
      pendingInvitationsSent.forEach(res => {
        if (sharedAccessUserSummaries) {
          const found = sharedAccessUserSummaries.find(
            item => item && item.emailAddress === res.emailAddress,
          );
          if (found) {
            obj[found.userId] = found.userId;
          }
        }
      });
    }
    return obj;
  };

  UNSAFE_componentWillReceiveProps(props) {
    const other = this.getOtherList(props);

    this.setState({
      windowWidth: props.windowWidth,
      other: other,
      invitationsReceived: this.getInvitationsReceived(props),
      connected: props.sharedAccessDigest.sharedAccessGivenToOthers.filter(
        res => res.permissionType !== 'NONE',
      ),
      connectedWithOthers: props.sharedAccessDigest.sharedAccessGivenToMe.filter(
        res => res.permissionType !== 'NONE',
      ),
    });
  }

  getOtherList = props => {
    return props.sharedAccessDigest.pendingInvitationsSent.filter(function(
      res,
    ) {
      return res.status !== 'CONNECTED';
    });
  };

  getInvitationsReceived = props => {
    return props.sharedAccessDigest.pendingInvitationsReceived.filter(function(
      res,
    ) {
      return res.status !== 'CONNECTED';
    });
  };

  getStatus = status => {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'DONOTUSE':
        return 'Donotuse';
      case 'DECLINED':
        return 'Declined';
      case 'CONNECTED':
        return 'Connected';
      case 'DISCONNECTED':
        return 'Disconnected';
      default:
        return 'Connected';
    }
  };

  // getStyle = (status) => {
  //   switch (status) {
  //     case "PENDING":
  //       return styles.userPendingText;
  //     case "DONOTUSE":
  //       return styles.userDeclinedText;
  //     case "DECLINED":
  //       return styles.userDeclinedText;
  //     case "CONNECTED":
  //       return styles.userConnectedText;
  //     default:
  //       return styles.userConnectedText;
  //   }
  // };

  getStyle = status => {
    switch (status) {
      case 'PENDING':
        return styles.textGreyDarkMd;
      case 'DONOTUSE':
        return styles.textGreyDarkMd;
      case 'DECLINED':
        return styles.textGreyDarkMd;
      case 'CONNECTED':
        return styles.userConnectedText;
      case 'DISCONNECTED':
        return styles.userPendingText;
      default:
        return styles.userConnectedText;
    }
  };

  redirectInviteCaregiver = () => {
    pendoTrackEvent('Caregivers@ > InviteCaregiver');
    this.props.navigation.push(CAREGIVERS.inviteCaregiverPath);
  };

  deleteSharedAccessInvitation = () => {
    this.setState({removeWait: true});
    this.props.deleteSharedAccessInvitation(this.state.cancelToken, res => {
      const other = this.state.other.filter(
        res => res.token !== this.state.cancelToken,
      );
      this.setState({
        other: other,
        cancelToken: null,
        removeWait: false,
        visibleModalCancel: false,
      });
    });
  };

  deleteDeclinedSharedAccessInvitation = token => {
    this.props.deleteSharedAccessInvitation(token, res => {
      const other = this.state.other.filter(res => res.token !== token);
      this.setState({
        other: other,
      });
    });
  };

  handleDeleteSharedAccessPermissionConfermation = grantedTo => {
    this.setState({
      visibleModal: true,
      grantedTo: grantedTo,
    });
  };

  handleDeleteSharedAccessPermissionGivenToMeConfermation = (
    grantedTo,
    grantingUser,
  ) => {
    this.setState({
      grantingUserICare: grantingUser,
      grantedToICare: grantedTo,
      visibleModalICare: true,
    });
  };
  deleteSharedAccessPermission = () => {
    this.setState({removeWait: true});
    this.props.deleteSharedAccessPermission(this.state.grantedTo, res => {
      const connected = this.state.connected.filter(
        res => res.grantedTo !== this.state.grantedTo,
      );
      this.setState({
        connected: connected,
        removeWait: false,
        visibleModal: false,
        grantedTo: null,
      });
    });
  };

  deleteSharedAccessPermissionGivenToMe = () => {
    this.setState({removeWait: true});
    this.props.deleteSharedAccessPermissionGivenToMe(
      this.state.grantingUserICare,
      res => {
        let connectedWithOthers = this.state.connectedWithOthers;
        if (res) {
          connectedWithOthers = this.state.connectedWithOthers.filter(
            res => res.grantingUser !== this.state.grantingUserICare,
          );
        }

        this.setState({
          connectedWithOthers: connectedWithOthers,
          removeWait: false,
          visibleModalICare: false,
          grantedToICare: null,
          grantingUserICare: null,
        });
      },
    );
  };

  acceptInvitation = token => {
    this.setState({acceptWait: true});
    this.props.sharedAccessInvitationAccept(token, (res, data) => {
      if (res) {
        const invitationsReceived = this.state.invitationsReceived.filter(
          res => res.token !== token,
        );
        let connectedWithOthers = this.state.connectedWithOthers;
        connectedWithOthers = connectedWithOthers.filter(
          res => res.grantingUser !== data.grantingUser,
        );
        connectedWithOthers.push(data);
        this.setState({
          acceptWait: false,
          invitationsReceived: invitationsReceived,
          connectedWithOthers: connectedWithOthers,
          visiblePeopleIcareList: false
        }, () => {
          setTimeout(() => {
            this.setState({
              visiblePeopleIcareList: true
            });
          }, 500);
        });
      } else {
        this.setState({acceptWait: false});
      }
    });
  };

  declineInvitation = () => {
    this.setState({declineWait: true});
    this.props.sharedAccessInvitationDecline(this.state.tokenDecline, res => {
      let invitationsReceived = this.state.invitationsReceived;
      if (res) {
        invitationsReceived = this.state.invitationsReceived.filter(
          res => res.token !== this.state.tokenDecline,
        );
      }

      this.setState({
        declineWait: false,
        invitationsReceived: invitationsReceived,
        tokenDecline: null,
        grantingUserDecline: null,
        visibleModalDecline: false,
      });
    });
  };

  resendInvitation = (resendToken, resendEmail) => {
    let visibleModalResend = false;
    this.props.sharedAccessInvitationResend(resendToken, (res, data) => {
      let other = this.state.other;
      if (res) {
        other = this.state.other.filter(res => res.token !== resendToken);
        other.push(data);
        visibleModalResend = true;
      }
      this.setState({
        other: other,
        resendEmail: resendEmail,
        visibleModalResend: visibleModalResend,
      });
    });
  };
  
  redirectCaregiver = id => {
    this.props.selectCaregiver(id);
    this.props.navigation.push(CAREGIVERS.caregiverPath, {
      from : CAREGIVERS.path
    });
  };

  handleDeleteSharedAccessDisconnectConfermation = grantedTo => {
    this.props.sharedAccessDisconnect(
      {
        grantedTo: grantedTo,
        status: 'DISCONNECTED',
      },
      (res, data) => {
        if (res) {
          const {connected} = this.state;
          const connectedUpdated = [];
          connected.forEach(element => {
            if (element.grantedTo === grantedTo) {
              connectedUpdated.push({
                ...element,
                shareAccessStatus: 'DISCONNECTED',
              });
            } else {
              connectedUpdated.push(element);
            }
          });
          this.setState({connected: connectedUpdated});
        }
      },
    );
  };

  handleSharedAccessDisconnectResendInvite = res => {
    const inviteTo = this.props.sharedAccessDigestProcessed?.sharedAccessUserSummaries?.[res.grantedTo] ?? {};

    const array = [];
    array.push({
      email: inviteTo.emailAddress,
      permission: res.permissionType,
      note:
        'Hi! Would you accept my invitation to be a part of my care network?',
    });
    this.props.sharedAccessInvitation(
      {emailAddresses: array},
      (sucesss, data) => {
        if (sucesss) {
          let {other, connected} = this.state;
          data.forEach(res => other.push(res));
          const connectedFilter = connected.filter(
            item => res.grantedTo !== item.grantedTo,
          );
          this.setState({
            connected: connectedFilter,
            other: other,
          });
        }
      },
      this.props.journalUserId,
    );
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    let windowWidth = this.state.windowWidth;
    let bannerWidth = 0;
    let bannerHeight = 0;

    if (windowWidth > TAB_BREAk_POINT_START) {
      bannerWidth = windowWidth - 455;
    } else {
      bannerWidth = windowWidth - 40;
    }
    bannerHeight = (180 / 4040) * bannerWidth;
    let contentPd = 20;
    let contentWidth = 740;
    if (windowWidth > 2000) {
      contentPd = 60;
      contentWidth = 800;
    }
    //const {accountType} = this.state;
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => {
            Platform.OS === WEB
              ? this.props.navigation.navigate(DASHBOARD.path)
              : this.props.navigation.push(DASHBOARD.path);
          }}
          index={CAREGIVERS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        <DashboardHOC isNotFromDashboard={true}>
        <ScrollView style={styles.scrollViewArea}>
          <View style={styles.gridSection}>
            <PageContainer smScreenFull={true}>
              <View style={stylesRes.botExPd} dataSet={{media: ids.botExPd}}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.secArea}>
                      <View
                        style={[
                          styles.caregiverBannerWrapper,
                          styles.caregiverBannerFull,
                        ]}>
                        <View
                          style={[
                            styles.caregiverBannerFull,
                            styles.caregiverBannerBg,
                          ]}>
                          <View style={[styles.caregiverBannerDetail]}>
                            <View
                              style={[
                                styles.flexRow,
                                styles.caregiverContent,
                                {
                                  maxWidth: contentWidth,
                                  paddingLeft: contentPd,
                                },
                              ]}>
                              <View style={[styles.caregiverIconView]}>
                                <Image
                                  style={[styles.caregiverIcon]}
                                  source={require('../../assets/premium/caregiver.png')}
                                />
                              </View>
                              <View style={[styles.caregiverContentView]}>
                                <View style={[styles.bannerDescription]}>
                                  <View style={[styles.bannerTitleView]}>
                                    <Text style={styles.textTitleBold} allowFontScaling={false}>
                                      Invite a friend or family member to be
                                      your caregiver!
                                    </Text>
                                  </View>

                                  <View style={[styles.bannerTextView]}>
                                    <Text style={styles.textPrime} allowFontScaling={false}>
                                      A Caregiver can ensure your questions are
                                      answered during appointments. About 91% of
                                      Caregivers help with decisions, and 79%
                                      investigate better treatment options.
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                        <View style={[styles.caregiverBannerFull]}>
                          <Image
                            style={[
                              styles.caregiverBannerFull,
                              {height: bannerHeight},
                            ]}
                            source={require('../../assets/background-images/caregiver-border.png')}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.secArea}>
                      <Collapsible
                        title="My caregiver list:"
                        contentVisibility={this.state.visibleMycaregiverlist}
                        handleOnPressCollapsible={() => {
                          this.setState({
                            visibleMycaregiverlist: !this.state
                              .visibleMycaregiverlist,
                          });
                        }}
                        titleMd={true}
                        windowWidth = {windowWidth}
                        regularUser={false}
                        navigation={this.props.navigation}>
                        <View style={[styles.collapseContent]}>
                          <View style={styles.secSubContentArea}>
                            {this.state.connected.map(
                              (res, index) =>
                                Boolean(this.props.sharedAccessDigestProcessed) && Boolean(this.props.sharedAccessDigestProcessed.sharedAccessUserSummaries) &&
                                Boolean(this.props.sharedAccessDigestProcessed.sharedAccessUserSummaries[res.grantedTo]) && (
                                  <View
                                    style={[
                                      styles.ViewRow,
                                      styles.greyBorderThin,
                                    ]}
                                    key={'cl_' + index}>
                                    <View style={styles.viewDetailSec}>
                                      <View style={styles.viewMainData}>
                                        <View
                                          style={[
                                            styles.viewItemDesc,
                                            styles.colPdTopBotRg,
                                            windowWidth > TAB_BREAk_POINT_START
                                            ? styles.colPdLtZero
                                            : styles.ltPdLg
                                          ]}>
                                          <View style={[styles.viewImgCom]}>
                                            <View
                                              style={[
                                                styles.userComIconSec,
                                                styles.rightSpSm,
                                              ]}>
                                              <Image
                                                source={require('../../assets/icons/user-com.png')}
                                                style={[
                                                  styles.userComIcon,
                                                  this.props.disableText
                                                    ? styles.iconInactiveColor
                                                    : styles.iconActiveTintColor,
                                                ]}
                                                resizeMode={'contain'}
                                              />
                                            </View>
                                          </View>

                                          <View style={[styles.viewDataFull]}>
                                            <Text
                                              style={[
                                                styles.textPrimeBold,
                                                res.shareAccessStatus ===
                                                'DISCONNECTED'
                                                  ? styles.textGreyDarkMd
                                                  : styles.activeText,
                                              ]}
                                              allowFontScaling={false}
                                              >
                                              {
                                                this.props
                                                  .sharedAccessDigestProcessed
                                                  ?.sharedAccessUserSummaries[
                                                  res.grantedTo
                                                ]?.name
                                              }
                                            </Text>
                                            <View style={styles.emailViemSm}>
                                              <Text
                                                style={[
                                                  styles.textPrime,
                                                  res.shareAccessStatus ===
                                                  'DISCONNECTED'
                                                    ? styles.textGreyDarkMd
                                                    : styles.activeText,
                                                ]}
                                                allowFontScaling={false}
                                                numberOfLines={1}>
                                                {
                                                  this.props
                                                    .sharedAccessDigestProcessed
                                                    ?.sharedAccessUserSummaries[
                                                    res.grantedTo
                                                  ]?.emailAddress
                                                }
                                              </Text>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                    <View style={[styles.docIconSecWrapper]}>
                                      <View style={[styles.itemIconSingleSm]}>
                                        <Text
                                          style={[
                                            styles.textPrimeSmBold,
                                            this.getStyle(
                                              res.shareAccessStatus,
                                            ),
                                          ]}
                                          allowFontScaling={false}
                                          >
                                          {this.getStatus(
                                            res.shareAccessStatus,
                                          )}
                                        </Text>
                                      </View>

                                      <View style={[styles.settingSec]}>
                                        <Menu
                                          renderer={Popover}
                                          rendererProps={{
                                            preferredPlacement: 'left',
                                          }}>
                                          <MenuTrigger>
                                            <View
                                              style={[
                                                styles.itemIconSingle,
                                                styles.itemSettingMenu,
                                              ]}>
                                              <Image
                                                style={[styles.settingIcon]}
                                                source={require('../../assets/icons/settings.png')}
                                              />
                                            </View>
                                          </MenuTrigger>
                                          <MenuOptions
                                            style={styles.dropDownList}>
                                            <MenuOption
                                              style={styles.liItem}
                                              onSelect={() =>
                                                this.redirectCaregiver(res.id)
                                              }>
                                              <Text style={styles.listText} allowFontScaling={false}>
                                                Settings
                                              </Text>
                                            </MenuOption>
                                            {/* prevent the premium user check for caregiver cr */}
                                            {/* {accountType !== 'regular' && ( */}
                                              <>
                                                {!res.shareAccessStatus ||
                                                res.shareAccessStatus ===
                                                  'CONNECTED' ? (
                                                  <MenuOption
                                                    style={styles.liItem}
                                                    onSelect={() =>
                                                      this.handleDeleteSharedAccessDisconnectConfermation(
                                                        res.grantedTo,
                                                      )
                                                    }>
                                                    <Text
                                                      style={styles.listText} allowFontScaling={false}>
                                                      Disconnect
                                                    </Text>
                                                  </MenuOption>
                                                ) : (
                                                  <MenuOption
                                                    style={styles.liItem}
                                                    onSelect={() =>
                                                      this.handleSharedAccessDisconnectResendInvite(
                                                        res,
                                                      )
                                                    }>
                                                    <Text
                                                      style={styles.listText} allowFontScaling={false}>
                                                      Re-send Invite
                                                    </Text>
                                                  </MenuOption>
                                                )}
                                              </>
                                            {/* )} */}
                                          </MenuOptions>
                                        </Menu>
                                      </View>
                                    </View>
                                  </View>
                                ),
                            )}
                            {this.state.other.map((res, index) => (
                              <View
                                style={[styles.ViewRow, styles.greyBorderThin]}
                                key={'pdi_' + index}>
                                <View style={styles.viewDetailSec}>
                                  <View style={styles.viewMainData}>
                                    <View
                                      style={[
                                        styles.viewItemDesc,
                                        styles.colPdTopBotRg,
                                        windowWidth > TAB_BREAk_POINT_START
                                        ? styles.colPdLtZero
                                        : styles.ltPdLg
                                      ]}>
                                      <View style={[styles.viewImgCom]}>
                                        <View
                                          style={[
                                            styles.userComIconSec,
                                            styles.rightSpSm,
                                          ]}>
                                          <Image
                                            source={require('../../assets/icons/user-com.png')}
                                            style={[
                                              styles.userComIcon,
                                              this.props.disableText
                                                ? styles.iconInactiveColor
                                                : styles.iconActiveTintColor,
                                            ]}
                                            resizeMode={'contain'}
                                          />
                                        </View>
                                      </View>

                                      <View style={[styles.viewDataFull]}>
                                        <Text
                                          style={[
                                            styles.textPrime,
                                            this.props.disableText
                                              ? styles.textGreyDarkMd
                                              : styles.activeText,
                                          ]}
                                          allowFontScaling={false}
                                          numberOfLines={1}>
                                          {res.emailAddress}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                                <View style={[styles.docIconSecWrapper]}>
                                  <View style={[styles.itemIconSingleSm]}>
                                    <Text
                                      style={[
                                        styles.textPrimeSmBold,
                                        this.getStyle(res.status),
                                      ]}
                                      allowFontScaling={false}
                                      >
                                      {this.getStatus(res.status)}
                                    </Text>
                                  </View>

                                  <View style={[styles.settingSec]}>
                                    <Menu
                                      renderer={Popover}
                                      rendererProps={{
                                        preferredPlacement: 'left',
                                      }}>
                                      <MenuTrigger>
                                        <View
                                          style={[
                                            styles.itemIconSingle,
                                            styles.itemSettingMenu,
                                          ]}>
                                          <Image
                                            style={[styles.settingIcon]}
                                            source={require('../../assets/icons/settings.png')}
                                          />
                                        </View>
                                      </MenuTrigger>
                                      {res.status === 'PENDING' ? (
                                        <MenuOptions
                                          style={styles.dropDownList}>
                                          <MenuOption
                                            style={styles.liItem}
                                            onSelect={() => {
                                              this.setState({
                                                visibleModalCancel: true,
                                                cancelToken: res.token,
                                                cancelEmail: res.emailAddress,
                                              });
                                            }}>
                                            <Text style={styles.listText} allowFontScaling={false}>
                                              Cancel Invite
                                            </Text>
                                          </MenuOption>
                                          {/* prevent the premium user check for caregiver cr */}
                                          {/* {accountType !== 'regular' && ( */}
                                            <MenuOption
                                              style={styles.liItem}
                                              onSelect={() =>
                                                this.resendInvitation(
                                                  res.token,
                                                  res.emailAddress,
                                                )
                                              }>
                                              <Text style={styles.listText} allowFontScaling={false}>
                                                Resend Invite
                                              </Text>
                                            </MenuOption>
                                          {/* )} */}
                                        </MenuOptions>
                                      ) : (
                                        <MenuOptions
                                          style={styles.dropDownList}>
                                          <MenuOption
                                            style={styles.liItem}
                                            onSelect={() =>
                                              this.deleteDeclinedSharedAccessInvitation(
                                                res.token,
                                              )
                                            }>
                                            <Text style={styles.listText} allowFontScaling={false}>
                                              Delete
                                            </Text>
                                          </MenuOption>
                                        </MenuOptions>
                                      )}
                                    </Menu>
                                  </View>
                                </View>
                              </View>
                            ))}
                          </View>
                        </View>
                      </Collapsible>

                      <Collapsible
                        title="People I care for:"
                        contentVisibility={this.state.visiblePeopleIcareList}
                        handleOnPressCollapsible={() => {
                          this.setState({
                            visiblePeopleIcareList: !this.state
                              .visiblePeopleIcareList,
                          });
                        }}
                        titleMd={true}
                        windowWidth = {windowWidth}
                        >
                        <View style={[styles.collapseContent]}>
                          <View style={styles.secSubContentArea}>
                            {this.state.connectedWithOthers.map(
                              (res, index) =>
                                this.props.sharedAccessDigestProcessed && this.props.sharedAccessDigestProcessed.sharedAccessUserSummaries &&
                                this.props.sharedAccessDigestProcessed.sharedAccessUserSummaries[res.grantedTo] &&
                                this.props.sharedAccessDigestProcessed.sharedAccessUserSummaries.hasOwnProperty(
                                  res.grantingUser,
                                ) && (
                                  <View
                                    style={[
                                      styles.ViewRow,
                                      styles.greyBorderThin,
                                    ]}
                                    key={'cl_' + index}>
                                    <View style={styles.viewDetailSec}>
                                      <View style={styles.viewMainData}>
                                        <View
                                          style={[
                                            styles.viewItemDesc,
                                            styles.colPdTopBotRg,
                                            windowWidth > TAB_BREAk_POINT_START
                                            ? styles.colPdLtZero
                                            : styles.ltPdLg
                                          ]}>
                                          <View style={[styles.viewImgCom]}>
                                            <View
                                              style={[
                                                styles.userComIconSec,
                                                styles.rightSpSm,
                                              ]}>
                                              <Image
                                                source={require('../../assets/icons/user-com.png')}
                                                style={[
                                                  styles.userComIcon,
                                                  res.shareAccessStatus ===
                                                  'DISCONNECTED'
                                                    ? styles.iconInactiveColor
                                                    : styles.iconActiveTintColor,
                                                ]}
                                                resizeMode={'contain'}
                                              />
                                            </View>
                                          </View>

                                          <View style={[styles.viewDataFull]}>
                                            <Text
                                              style={[
                                                styles.textPrimeBold,
                                                res.shareAccessStatus ===
                                                'DISCONNECTED'
                                                  ? styles.textGreyDarkMd
                                                  : styles.activeText,
                                              ]}
                                              allowFontScaling={false}
                                              >
                                              {
                                                this.props
                                                  .sharedAccessDigestProcessed
                                                  ?.sharedAccessUserSummaries[
                                                  res.grantingUser
                                                ]?.name
                                              }
                                            </Text>
                                            <View style={styles.emailViemSm}>
                                              <Text
                                                style={[
                                                  styles.textPrime,
                                                  res.shareAccessStatus ===
                                                  'DISCONNECTED'
                                                    ? styles.textGreyDarkMd
                                                    : styles.activeText,
                                                ]}
                                                allowFontScaling={false}
                                                numberOfLines={1}>
                                                {
                                                  this.props
                                                    .sharedAccessDigestProcessed
                                                    ?.sharedAccessUserSummaries[
                                                    res.grantingUser
                                                  ]?.emailAddress
                                                }
                                              </Text>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                    <View style={[styles.docIconSecWrapper]}>
                                      <View style={[styles.itemIconSingleSm]}>
                                        <Text
                                          style={[
                                            styles.textPrimeSmBold,
                                            this.getStyle(
                                              res.shareAccessStatus,
                                            ),
                                          ]}
                                          allowFontScaling={false}
                                          >
                                          {this.getStatus(
                                            res.shareAccessStatus,
                                          )}
                                        </Text>
                                      </View>

                                      <View style={[styles.settingSec]}>
                                        <Menu
                                          renderer={Popover}
                                          rendererProps={{
                                            preferredPlacement: 'left',
                                          }}>
                                          <MenuTrigger>
                                            <View
                                              style={[
                                                styles.itemIconSingle,
                                                styles.itemSettingMenu,
                                              ]}>
                                              <Image
                                                style={[styles.settingIcon]}
                                                source={require('../../assets/icons/settings.png')}
                                              />
                                            </View>
                                          </MenuTrigger>
                                          <MenuOptions
                                            style={styles.dropDownList}>
                                            <MenuOption
                                              style={styles.liItem}
                                              onSelect={() =>
                                                this.handleDeleteSharedAccessPermissionGivenToMeConfermation(
                                                  res.grantedTo,
                                                  res.grantingUser,
                                                )
                                              }>
                                              <Text style={styles.listText} allowFontScaling={false}>
                                                Remove
                                              </Text>
                                            </MenuOption>
                                          </MenuOptions>
                                        </Menu>
                                      </View>
                                    </View>
                                  </View>
                                ),
                            )}
                            {this.state.invitationsReceived.map(
                              (res, index) => (
                                <View
                                  style={[
                                    styles.ViewRow,
                                    styles.greyBorderThin,
                                  ]}
                                  key={'pdi_' + index}>
                                  <View style={styles.viewDetailSec}>
                                    <View style={styles.viewMainData}>
                                      <View
                                        style={[
                                          styles.viewItemDesc,
                                          styles.colPdTopBotRg,
                                        ]}>
                                        <View style={[styles.viewImgCom]}>
                                          <View
                                            style={[
                                              styles.userComIconSec,
                                              styles.rightSpSm,
                                              windowWidth > TAB_BREAk_POINT_START
                                              ? styles.colPdLtZero
                                              : styles.ltPdLg
                                            ]}>
                                            <Image
                                              source={require('../../assets/icons/user-com.png')}
                                              style={[
                                                styles.userComIcon,
                                                this.props.disableText
                                                  ? styles.iconInactiveColor
                                                  : styles.iconActiveTintColor,
                                              ]}
                                              resizeMode={'contain'}
                                            />
                                          </View>
                                        </View>

                                        <View style={[styles.viewDataFull]}>
                                          <Text style={styles.textPrime} allowFontScaling={false}>
                                            {res.inviteBy}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                  <View style={[styles.docIconSecWrapper]}>
                                    <View style={[styles.itemIconSingleSm]}>
                                      <Text
                                        style={[
                                          styles.textPrimeSmBold,
                                          this.getStyle(res.status),
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        {this.getStatus(res.status)}
                                      </Text>
                                    </View>

                                    <View style={[styles.settingSec]}>
                                      <Menu
                                        renderer={Popover}
                                        rendererProps={{
                                          preferredPlacement: 'left',
                                        }}>
                                        <MenuTrigger>
                                          <View
                                            style={[
                                              styles.itemIconSingle,
                                              styles.itemSettingMenu,
                                            ]}>
                                            <Image
                                              style={[styles.settingIcon]}
                                              source={require('../../assets/icons/settings.png')}
                                            />
                                          </View>
                                        </MenuTrigger>
                                        <MenuOptions
                                          style={styles.dropDownList}>
                                          <MenuOption
                                            style={styles.liItem}
                                            onSelect={() =>
                                              this.acceptInvitation(res.token)
                                            }>
                                            <Text style={styles.listText} allowFontScaling={false}>
                                              Accept Invitation
                                            </Text>
                                          </MenuOption>

                                          <MenuOption
                                            style={styles.liItem}
                                            onSelect={() => {
                                              this.setState({
                                                visibleModalDecline: true,
                                                tokenDecline: res.token,
                                                grantingUserDecline:
                                                  res.inviteBy,
                                              });
                                            }}>
                                            <Text style={styles.listText} allowFontScaling={false}>
                                              Decline Invitation
                                            </Text>
                                          </MenuOption>
                                        </MenuOptions>
                                      </Menu>
                                    </View>
                                  </View>
                                </View>
                              ),
                            )}
                          </View>
                        </View>
                      </Collapsible>
                    </View>
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={[styles.gridColFull, styles.comMgTopSm]}>
                    <View style={[styles.btnArea, styles.btnRowMgRg]}>
                      <View style={[styles.btnBlock, styles.noMgTop]}>
                        <View style={stylesRes.btnSingleSecFull}>
                            <PrimaryButton
                              btnText={'Invite caregiver'}
                              btnPosition={'full'}
                              btnLg={true}
                              touchableOnPress={this.redirectInviteCaregiver}
                              btnStyle={styles.submitBgBlue}
                              nativeId={'caregiversInviteCaregiver'}
                            />
                            {/* prevent the premium user check for caregiver */}
                          {/* {this.state.accountType !== 'regular' &&
                            <PrimaryButton
                              btnText={'Invite caregiver'}
                              btnPosition={'full'}
                              btnLg={true}
                              touchableOnPress={this.redirectInviteCaregiver}
                              btnStyle={styles.submitBgBlue}
                            />
                          } */}
                          {/* <TouchableOpacity
                            style={[styles.submitMd]}
                            underlayColor="rgba(0, 153, 168, 0.8)"
                            onPress={
                              this.state.accountType === "regular"
                                ? this.redirectPremiumAccount
                                : this.redirectInviteCaregiver
                            }
                          >
                            <View style={styles.btnSingleView}>
                              <View
                                style={[styles.btnSingle, styles.btnSingleCent]}
                              >
                                <UIText
                                  style={styles.submitText}
                                  title="Invite caregiver"
                                />
                              </View>
                            </View>
                          </TouchableOpacity> */}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.secArea}>
                      <View style={[styles.colPdLtRt]}>
                        <View style={stylesRes.maxComSec} dataSet={{media: ids.maxComSec}}>
                          <View style={styles.contColFul}>
                            <View
                              style={[
                                styles.contComText,
                                styles.flexRowJcCtAiCt,
                              ]}>
                              <Text
                                style={[
                                  styles.textPrime,
                                  styles.textAlignCenter,
                                  styles.textGrey,
                                ]}
                                allowFontScaling={false}
                                >
                                Your privacy is our priority.
                              </Text>
                              <TouchableOpacity
                                style={[styles.mgLtExSm]}
                                onPress={() =>
                                  this.setState({
                                    showLearnHowPrivacyPolicy: true,
                                  })
                                }>
                                <Text
                                  style={[
                                    styles.textOrange,
                                    styles.textAlignCenter,
                                    styles.textUnderline,
                                  ]}
                                  allowFontScaling={false}
                                  >
                                  Learn How
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </PageContainer>
          </View>
        </ScrollView>
        </DashboardHOC>
        {this.state.visibleModal &&
        <SimpleConfirmBox
          visibleModal={this.state.visibleModal}
          alertTitle={'Remove Caregiver'}
          alertBody={
            this.state.grantedTo !== null
              ? 'Are you sure you want to remove ' +
                this.props.sharedAccessDigestProcessed
                ?.sharedAccessUserSummaries?.[this.state.grantedTo]?.name ?? '' + '\'s' +
                ' access to your account? You will need to resend the invite to reconnect.'
              : ''
          }
          onRequestClose={() => this.setState({visibleModal: false})}
          onBackdropPress={() => this.setState({visibleModal: false})}
          handleCancel={() => this.setState({visibleModal: false})}
          handleConfirm={this.deleteSharedAccessPermission}
          confirmWait={this.state.removeWait}
          alertBodyBoldText={true}
        />
        }
        {this.state.visibleModalICare &&
        <SimpleConfirmBox
          visibleModal={this.state.visibleModalICare}
          alertTitle={'Warning:'}
          alertBody={
            this.state.grantingUserICare !== null
              ? 'Are you sure you want to remove ' +
                this.props.sharedAccessDigestProcessed
                ?.sharedAccessUserSummaries?.[this.state.grantingUserICare]
                ?.name ?? '' +
                '? ' +
                this.props.sharedAccessDigestProcessed
                ?.sharedAccessUserSummaries?.[this.state.grantingUserICare]
                ?.name ?? '' +
                ' will need to resend the invite to connect.'
              : ''
          }
          onRequestClose={() => this.setState({visibleModalICare: false})}
          onBackdropPress={() => this.setState({visibleModalICare: false})}
          handleCancel={() => this.setState({visibleModalICare: false})}
          handleConfirm={this.deleteSharedAccessPermissionGivenToMe}
          confirmWait={this.state.removeWait}
          alertBodyBoldText={true}
        />
        }
        {this.state.visibleModalDecline &&
        <SimpleConfirmBox
          visibleModal={this.state.visibleModalDecline}
          alertTitle={'Warning:'}
          alertBody={
            this.state.grantingUserDecline !== null
              ? 'Are you sure you want to decline? ' +
                this.state.grantingUserDecline +
                '  will need to resend the invite to connect.'
              : ''
          }
          onRequestClose={() => this.setState({visibleModalDecline: false})}
          onBackdropPress={() => this.setState({visibleModalDecline: false})}
          handleCancel={() => this.setState({visibleModalDecline: false})}
          handleConfirm={this.declineInvitation}
          confirmWait={this.state.declineWait}
          alertBodyBoldText={true}
        />
        }
        {this.state.visibleModalCancel &&
        <SimpleConfirmBox
          visibleModal={this.state.visibleModalCancel}
          alertTitle={'Remove Caregiver'}
          alertBody={
            this.state.cancelEmail !== null
              ? 'Are you sure you want to cancel the request to ' +
                this.state.cancelEmail +
                '? You will need to resend the invite to reconnect.'
              : ''
          }
          onRequestClose={() => this.setState({visibleModalCancel: false})}
          onBackdropPress={() => this.setState({visibleModalCancel: false})}
          handleCancel={() => this.setState({visibleModalCancel: false})}
          handleConfirm={this.deleteSharedAccessInvitation}
          confirmWait={this.state.removeWait}
          alertBodyBoldText={true}
        />
        }
        {this.state.visibleModalResend &&
        <SimpleOkBox
          visibleModal={this.state.visibleModalResend}
          alertTitle={'Resend Invite'}
          alertBody={
            this.state.resendEmail !== null
              ? ' Invitation to ' + this.state.resendEmail + ' was resent.'
              : ''
          }
          onRequestClose={() => this.setState({visibleModalResend: false})}
          onBackdropPress={() => this.setState({visibleModalResend: false})}
          handleCancel={() => this.setState({visibleModalResend: false})}
          handleConfirm={() => this.setState({visibleModalResend: false})}
          alertBodyBoldText={true}
        />
        }
        {this.state.showLearnHowPrivacyPolicy &&
          <LearnHowPrivacyPolicy
            showLearnHowPrivacyPolicy={this.state.showLearnHowPrivacyPolicy}
            toggleLearnHowPrivacyPolicy={() => {
              this.setState({showLearnHowPrivacyPolicy: false});
            }}
          />
        }
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    sharedAccessDigest: state.routines.sharedAccessDigest,
    sharedAccessDigestProcessed: state.routines.sharedAccessDigestProcessed,
    userSubscription: state.routines.userSubscription,
    journalUserId: state.uistate.journalUserId,
    windowWidth: state.uistate.windowWidth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteSharedAccessInvitation: (token, callBack) =>
      dispatch(actions.deleteSharedAccessInvitation(token, callBack)),
    deleteSharedAccessPermission: (grantedTo, callBack) =>
      dispatch(actions.deleteSharedAccessPermission(grantedTo, callBack)),
    toggleLearnHow: () => dispatch(actions.showLearnHow()),
    sharedAccessInvitationAccept: (token, callBack) =>
      dispatch(actions.sharedAccessInvitationAccept(token, callBack)),
    sharedAccessInvitationDecline: (token, callBack) =>
      dispatch(actions.sharedAccessInvitationDecline(token, callBack)),
    selectCaregiver: id => dispatch(actions.selectCaregiver(id)),
    sharedAccessInvitationResend: (token, callBack) =>
      dispatch(actions.sharedAccessInvitationResend(token, callBack)),
    deleteSharedAccessPermissionGivenToMe: (grantingUser, callBack) =>
      dispatch(
        actions.deleteSharedAccessPermissionGivenToMe(grantingUser, callBack),
      ),
    sharedAccessDisconnect: (data, callBack) =>
      dispatch(actions.sharedAccessDisconnect(data, callBack)),
    sharedAccessInvitation: (data, callBack, journalUserId) =>
      dispatch(actions.sharedAccessInvitation(data, callBack, journalUserId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CaregiverAccessScreen);
