import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
  errorDescription: null,
  error: false,
  logout: false,
  isFromLogin: false,
  fromSignUp: true,
  isAuthenticated: false,
  loginWithTouchId: false,
  emailVerified: false,
  emailVerificationPhase: false,
  mfaEnable: false,
  cognitoUser: null
};

const authStart = (state, action) => {
  return updateObject(state, {error: action.error});
};

const authSuccess = (state, action) => {
  let isFromLogin = state.isFromLogin;
  if (action.isFromLogin) {
    isFromLogin = action.isFromLogin;
  }
  return updateObject(state, {
    error: false,
    logout: false,
    isAuthenticated: true,
    isFromLogin: isFromLogin,
    emailVerificationPhase: false
  });
};

const moveToEmailVerificationPhase = (state, action) => {
  let isFromLogin = state.isFromLogin;
  if (action.isFromLogin) {
    isFromLogin = action.isFromLogin;
  }
  return updateObject(state, {
    error: false,
    logout: false,
    isAuthenticated: true,
    isFromLogin: isFromLogin,
    emailVerificationPhase: true,
    cognitoUser: action.user
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
    isFromLogin: false,
    isAuthenticated: false,
    emailVerificationPhase: false
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    logout: true,
    isFromLogin: false,
    isAuthenticated: false,
    emailVerificationPhase: false,
    emailVerified: false
  });
};

const keepAlive = (state, action) => {
  return updateObject(state, {});
};

const loginButtonPress = (state, action) => {
  return updateObject(state, {isFromLogin: true});
};

const isFromLogin = state => {
  return updateObject(state, {isFromLogin: false, fromSignUp: false});
};

const setLoginWithTouchId = (state, action) => {
  return updateObject(state, {loginWithTouchId: action.loginWithTouchId});
};

const setEmailVerified = (state, action) => {
  let emailVerificationPhase = state.emailVerificationPhase;
  if (action.emailVerified) {
    emailVerificationPhase = false;
  }
  return updateObject(state, {emailVerified: action.emailVerified, emailVerificationPhase: emailVerificationPhase});
};

const setMFAEnable = (state, action) => {
  return updateObject(state, {mfaEnable: action.mfaEnable});
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.KEEP_ALIVE:
      return keepAlive(state, action);
    case actionTypes.LOGIN_PRESS:
      return loginButtonPress(state, action);
    case actionTypes.IS_FROM_LOGIN:
      return isFromLogin(state, action);
    case actionTypes.LOGIN_WITH_TOUCH_ID:
      return setLoginWithTouchId(state, action);
    case actionTypes.SET_EMAIL_VERIFIED:
      return setEmailVerified(state, action);
    case actionTypes.SET_MFA_ENABLE:
      return setMFAEnable(state, action);
    case actionTypes.MOVE_TO_EMAIL_VERIFICATION_PHASE:
      return moveToEmailVerificationPhase(state, action);
    default:
      return state;
  }
};

export default reducer;
