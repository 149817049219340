import React, {Component} from 'react';
import {View, Text, TouchableOpacity, Platform} from 'react-native';
import moment from 'moment';
import styles from '../../../../Css';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextDatePickerFrame from '../../../TextInput/textDatePickerFrame';
import TextTimePickerFrame from '../../../TextInput/textTimePickerFrame';
import ItemDeleteButton from '../../../Button/itemDeleteButton';
import TimePicker from '../../../DateTimePicker/TimePicker';
import DatePicker from '../../../DateTimePicker/DatePicker';
import TextInputDayFrameSpec from '../../../TextInput/TextInputDayFrameSpec';
import {timeMap} from '../../../../util/formatDate';
import { TAB_BREAk_POINT, TAB_BREAk_POINT_START, WEB } from '../../../../util/platformWindowConfig';


class Monthly extends Component {
  constructor(props) {
    super(props);
    const {data} = props;
    const {reminderTime} = data;
    this.state = {...data, reminderDateTime: this.getDateTime(reminderTime)};
  }

  getDateTime = reminderTime => {
    const reminderTimes = reminderTime && Array.isArray(reminderTime) ? reminderTime : reminderTime.split(":");
    if (reminderTimes && reminderTimes.length > 0) {
      const now = moment()
        .set('hour', reminderTimes[0])
        .set('minute', reminderTimes[1]);
      return now.valueOf();
    }

    return null;
  };

  customTouchableComponentTimePicker = onPress => {
    return (
      Platform.OS === WEB
        ? <TouchableOpacity onPress={onPress}>
            {this.renderMonthlyTimeContent()}
          </TouchableOpacity>
        : this.renderMonthlyTimeContent()
    );
  };

  renderMonthlyTimeContent = () => {
    const {reminderTime, reminderDateTime} = this.state;
    let duration = '';
    const reminderTimes = reminderTime && Array.isArray(reminderTime) ? reminderTime : reminderTime.split(":");
    if (reminderTimes && reminderTimes.length > 0) {
      const time = timeMap[reminderTimes[0]];
      const times = time.split(' ');
      duration =
        times[0] +
        ':' +
        (reminderTimes[1].toString().length === 1
          ? '0' + reminderTimes[1]
          : reminderTimes[1]) +
        ' ' +
        times[1];
    }
    return (
      <TextTimePickerFrame
        title={'Monthly @'}
        fieldBg={FieldDetails.BGWHITE}
        fieldSm={true}
        fieldValue={duration}
        onTimeChange={this.onTimeChange}
        reminderDateTime={reminderDateTime}
        windowWidth={this.props.windowWidth}
        reminderSection={this.props.reminderSection}
      />
    )
  }

  renderMonthlyEndReminder = () => {
    const {reminderEnd} = this.state;
    const {windowWidth, reminderSection} = this.props;
    return (
      <>
      <View style={styles.fieldColSp} />
        <View style={styles.fieldColStretch}>
          <DatePicker
            date={reminderEnd}
            customTouchableComponent={this.customTouchableComponent}
            onConfirm={this.onDateConfirm}
            windowWidth={windowWidth}
            reminderSection={reminderSection}
            minDate={new Date().getTime()}
          />
      </View>
      </>
    )
  }

  onTimeChange = selectedTime => {
    const time =
      Platform.OS === WEB
        ? moment(selectedTime).format('HH.mm')
        : moment(selectedTime, 'x').format('HH.mm');

    const {updateReminders, index} = this.props;
    const times = time.split('.');
    //const { reminderTime } = this.state;
    let reminderTime = this.state.reminderTime;
    if (reminderTime && Array.isArray(reminderTime)) {
      reminderTime[0] = parseInt(times[0]);
      reminderTime[1] = parseInt(times[1]);
    } else {
      reminderTime = times[0] + ':' + times[1];
    }
    this.setState(
      {
        reminderTime: reminderTime,
        reminderDateTime: this.getDateTime(reminderTime),
      },
      () => updateReminders(index, {...this.state, reminderTime: reminderTime}),
    );
  };

  customTouchableComponent = onPress => {
    return (
      Platform.OS === WEB
        ? <TouchableOpacity onPress={onPress}>
            {this.renderEndReminderContent()}
          </TouchableOpacity>
        : this.renderEndReminderContent()
    )
  };

  renderEndReminderContent = () => {
    const {reminderEnd} = this.state;

    return (
      <TextDatePickerFrame
        title={'End Reminder'}
        fieldBg={FieldDetails.BGWHITE}
        fieldSm={true}
        fieldValue={
          reminderEnd ? moment(reminderEnd).format("D MMM 'YY") : ''
        }
        onDateConfirm={this.onDateConfirm}
        date={reminderEnd}
        dateSm={true}
        windowWidth={this.props.windowWidth}
        reminderSection={this.props.reminderSection}
        minDate={new Date().getTime()}
      />
    )
  }

  onDateConfirm = date => {
    const {updateReminders, index} = this.props;
    this.setState({reminderEnd: date}, () =>
      updateReminders(index, {...this.state, reminderEnd: date}),
    );
  };

  setReminderMonthlyDay = val => {
    const {updateReminders, index} = this.props;
    this.setState({reminderMonthlyDay: val}, () =>
      updateReminders(index, {...this.state, reminderMonthlyDay: val}),
    );
  };

  render() {
    const {reminderDateTime, reminderMonthlyDay, publishedBy} = this.state;
    const {index, deleteItem, windowWidth} = this.props;
    return (
      <React.Fragment>
        <View 
          onLayout={(event) => this.props.setExpiredReminderPosition({...event.nativeEvent.layout, reminderMonthly: true})}
        >
          <View style={styles.fieldContainer}>
            <View style={styles.fieldRowWrapper}>
              <View style={styles.fieldColIdCom}>
                <View style={styles.indexSm}>
                  <Text style={[styles.textPrimeSmBold, styles.indexTxtSm]}>
                    {index+ 1}
                  </Text>
                </View>
              </View>

              <View style={styles.fieldColSp} />
              <View style={styles.fieldColStretch}>
                <TimePicker
                  customTouchableComponent={
                    this.customTouchableComponentTimePicker
                  }
                  onChange={this.onTimeChange}
                  value={reminderDateTime}
                  windowWidth={this.props.windowWidth}
                  reminderSection={this.props.reminderSection}
                />
              </View>

              <View style={styles.fieldColSp} />
              <View style={styles.fieldColStretch}>
                <TextInputDayFrameSpec
                  title={'On'}
                  fieldBg={FieldDetails.BGWHITE}
                  fieldSm={true}
                  fieldValue={reminderMonthlyDay}
                  setReminderMonthlyDay={this.setReminderMonthlyDay}
                  windowWidth={this.props.windowWidth}
                  reminderSection={this.props.reminderSection}
                />
              </View>

              {windowWidth > TAB_BREAk_POINT_START &&
                this.renderMonthlyEndReminder()
              }

              <View style={styles.fieldColSp} />
              <View style={styles.fieldColDelete}>
                {publishedBy !== 'PARTNER' && (
                  <ItemDeleteButton 
                  touchableOnPress={() => deleteItem(index)} 
                  routinesReminderDelete={true}
                  />
                )}
              </View>
            </View>

            {windowWidth < TAB_BREAk_POINT && 
              <View style={styles.fieldRowWrapper}>
                <View style={styles.fieldColIdCom} />
                {this.renderMonthlyEndReminder()}
                <View style={styles.fieldColSp} />
                <View style={styles.fieldColDelete} />
              </View>
            }
          </View>
        </View>
      </React.Fragment>
    );
  }
}

export default (Monthly);
