import React, { Component } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import styles from "../../../Css";
import { pendoTrackEvent } from "../../../util/pendoConfig";
import { getMenuItemDetails } from '../../../util/commonUiLogic';
import { HEALTH_JOURNEY } from "../../../navigation/path";

class CommonIconMenuItem extends Component {
  constructor(props) {
    super(props);
    const { isSelected } = props;
    this.state = {
      isSelected: isSelected,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { isSelected } = props;
    if (isSelected !== state.isSelected) {
      return {
        isSelected: isSelected,
      };
    }

    return null;
  }
  render() {
    const { navigation, onPressMenuItem, tabMenuIconView, handleTabMenuClick, clearFilter, pathDetails, hasNotifications } = this.props;
    const { isSelected } = this.state;
    const { title, path } = pathDetails;
    const details = getMenuItemDetails(title);
    return (
      <View style={styles.mainNavItem} nativeID={details.nativeIdWrapper}>
        <TouchableOpacity
          onPress={() => {
            if (handleTabMenuClick) {
              handleTabMenuClick()
            } else {
              if (onPressMenuItem) {
                onPressMenuItem();
              }
              if (clearFilter) {
                clearFilter();
              }
              pendoTrackEvent(details.eventName);
              navigation.reset({
                index: 0,
                routes: [{ name: path }],
              });
            }
          }}
        >
          <View
            nativeID={details.nativeId}
            style={[
              styles.navListLiDet,
              styles.navListLiDetPdSm,
              Boolean(isSelected) ? styles.mainNavSelected : styles.mainNavDefault,
            ]}
          >
            <View style={[styles.navListLiImgCom]}>
              <Image
                style={[title === HEALTH_JOURNEY.title ? styles.itemContactIcon : styles.navMainMenuIcon]}
                source={Boolean(isSelected)
                  ? details.selectedIcon
                  : details.unSelectedIcon
                }
              />
              {/* for ipad and tab */}
              {Boolean(tabMenuIconView) && Boolean(hasNotifications) && (
                <View style={[styles.notifyAvailableMenu, styles.notifyAvailableMenuSm]}/>
              )}
            </View>
            {!Boolean(tabMenuIconView) && (
            <View style={[styles.navListLiContent]}>
              <Text
                style={[
                  Boolean(isSelected)
                    ? [styles.textPrimeBold, styles.textOrange]
                    : styles.textPrime,
                ]}
                allowFontScaling={false}
              >
                {title}
              </Text>
              {/* for desktop */}
              {!Boolean(tabMenuIconView) && Boolean(hasNotifications) && (
                <View style={[styles.notifyAvailableMenu]}/>
              )}
            </View>
            )}
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default CommonIconMenuItem;
