import React from "react";
import { View } from "react-native";
import styles from "../../../Css";
import ProgressBar from "../../../modules/react-native-animated-progress";
import stylesCss from "./SurveyProgressBarStyle";

const SurveyProgressBar = (props) => {
  return (
    <View style={[stylesCss.progressBarWrapper, styles.flexJcCtAiCt]}>
      <ProgressBar
        progress={props.progress}
        height={7}
        backgroundColor="#FF8300"
        surveyProgress={true}
        windowWidth={props.windowWidth}
      />
    </View>
  );
};

export default (SurveyProgressBar);
