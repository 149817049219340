import {StyleSheet} from 'react-native';

export default StyleSheet.create({

    progressBarWrapper: {
        paddingTop: 5,
        paddingBottom: 15,
      }

});

