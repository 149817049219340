import React, { Component } from "react";
import { Image, TouchableOpacity, View } from "react-native";

const assets = {
  tickButton: require("../../assets/toggleTick/ticked-orange-circle.png"),
  untickButton: require("../../assets/toggleTick/un-ticked-grey-circle.png"),
  tickButtonGrey: require("../../assets/toggleTick/ticked-grey-circle.png")
};

export default class toggleTick extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticked: props.ticked
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { ticked } = props;
    if (this.state.ticked !== ticked) {
      this.setState({ ticked: ticked });
    }
  }

  clickEvent = () => {
    this.setState({ticked: !this.state.ticked}, () =>
      this.props.toggleTickCallback(this.state.ticked),
    );
  };

  render() {
    return (
      <View>
        <TouchableOpacity
          onPressIn={this.clickEvent}
          style={{
            marginRight: 10,
            marginTop: 3,
            marginBottom: 0,
            justifyContent: "center",
            alignItems: "center"
          }}
          disabled={this.props.disabled}
        >
          <Image
            source={
              this.state.ticked && this.props.disabled
                ? assets.tickButtonGrey
                : this.state.ticked
                ? assets.tickButton
                : assets.untickButton
            }
            style={{ height: 22, width: 22 }}
          />
        </TouchableOpacity>
      </View>
    );
  }
}
