import React, { Component } from 'react';
import { View, Switch, Image } from 'react-native';

import styles from "../../Css";
import {DEVICE_TYPES} from "./Constant"
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";

class DeviceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indicator: false,
      connetionStatus: props.status,
      connectingStatus : props.deviceConnecting
    };
  }

  renderImage = (type) => {
    switch (type) {
      case DEVICE_TYPES.HEXOSKIN:
        return (
          <Image
            style={styles.deviceItemImg}
            source={require("../../assets/devices/hexoskin.png")}
          />
        );
      case DEVICE_TYPES.APPLEWATCH:
        return (
          <Image
            style={styles.deviceItemImg}
            source={require("../../assets/devices/apple-watch.png")}
          />
        );
      case DEVICE_TYPES.FITBIT:
        return (
          <Image
            style={styles.deviceItemImg}
            source={require("../../assets/devices/fitbit.png")}
          />
        );
      case DEVICE_TYPES.GARMIN:
        return (
          <Image
            style={styles.deviceItemImg}
            source={require("../../assets/devices/garmin.png")}
          />
        );
      default:
        break;
    }
  }

  render() {
    const {item, status, changeDeviceStatus} = this.props;
    return (
      <View
        style={[styles.deviceListItem, styles.borderBotGrey]}
      >
        <View style={[styles.deviceItemImgSec]}>
          {this.renderImage(item.deviceName)}
        </View>
        <View style={[styles.deviceItemBtnSec]}>
          <View
            style={[styles.toggleButtonCom, styles.flexAiFs]}
          >
            <Switch
              testID={`deviceToggle`}
              style={{
                transform: [{ scaleX: 1.0 }, { scaleY: 1.0 }],
              }}
              onValueChange={val => {
                changeDeviceStatus(val);
                
              }}
              value={status ? status : false}
            />
            {Boolean(this.state.connectingStatus) && 
              <ActivityIndicator size="small" pdZero={true} />
            }
          </View>

        </View>
      </View>
    );
  }
}

export default DeviceList;
