import React from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import ActivityIndicator from "../../ActivityIndicator/activityIndicator";
import {findThumbnail} from '../../../util/commonUiLogic';
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";

const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
const FileUpload = props => {
  const resourceRow = (
    <View
      style={[
        styles.listItemView,
        styles.listItemAddedView,
        styles.colPdLtRt,
        styles.borderBotGrey
      ]}
    >
      <View style={[styles.listItemCloseCenter]}>
        <View style={styles.listItemCloseIcon}>
          <TouchableOpacity
            onPress={() => {
              props.handleDeleteFile();
            }}
          >
            <Image
              style={styles.closeDarkIcon}
              source={require("../../../assets/icons/close-dark.png")}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View style={[styles.listContentViewFull, styles.colPdTopBot]}>
        <View style={[styles.listContentView]}>
          <View style={[styles.addedItemInd, styles.addedItemRtPd]}>
            <View style={[styles.itemFullRow]}>
              <View style={stylesRes.itemResourceAreaMainLg}>
                <View style={stylesRes.itemResourceIcon}>
                {props.file && props.file.name ? (
                    <Image
                      style={styles.imgContain}
                      source={findThumbnail(props.file.name)}
                    />
                  ) : (
                    <Image
                      style={styles.imgContain}
                      source={require("../../../assets/icons/report-img-doc.png")}
                    />
                )}
                </View>
              </View>

              <View
                style={stylesRes.itemTextAreaMain}
              >
                <View style={[styles.itemTextDetails]}>
                  <View style={[styles.itemTextMain]}>
                    <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                      {props.file && props.file.name}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  return (
    <React.Fragment>
      <View style={styles.gridRow}>
        <View style={styles.gridColFull}>
          <View style={[styles.colPdLtRt]}>
            <View style={[styles.btnViewArea]}>
              <View style={stylesRes.btnSingleSecCom}>
                <View style={[styles.uploadViewFull, styles.upBtnHeightSm]}>
                  <TouchableOpacity style={[styles.upImgSec]}>
                    <View style={[styles.fileUploadSection]}>
                      <View style={[
                        styles.upImg, 
                        styles.submitBgOrange,
                        styles.upBtnHeightSm,
                        props.disabled || props.fileUploadWait
                          ? styles.submitBgOrangeLight
                          : styles.submitBgOrange
                      ]}>
                        <View style={styles.btnSingleView}>
                          <View
                            style={[styles.btnSingle, styles.btnSingleCent]}
                          >
                            {props.fileUploadWait && (
                              <View style={styles.rightSpSm}>
                                <ActivityIndicator size="small" />
                              </View>
                            )}
                            <View
                              style={[styles.fileIconSec, styles.rightSpSm]}
                            >
                              <Image
                                style={styles.fileIcon}
                                source={require("../../../assets/icons/file.png")}
                              />
                            </View>
                            <Text style={[styles.textPrimeBold, styles.textWhite]} allowFontScaling={false}>Upload a File</Text>
                          </View>
                        </View>
                      </View>
                      <View style={styles.fileUploadBtn}>
                        <input
                          type="file"
                          name="file"
                          id="btn"
                          onChange={e => props.onFileAdd(e.target.files)}
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            height: 45
                          }}
                          disabled={props.disabled}
                          onClick={(e) => {
                            e.target.value = '';
                          }}
                        />
                        <label
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            height: 51,
                            position: "absolute",
                            top: 0
                          }}
                          htmlFor="btn"
                          nativeID={props.nativeId}
                        >
                          Upload
                        </label>
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.gridRow}>
        <View style={styles.gridColFull}>
          <View style={[styles.colPdLtRt]}>
            <View>
              <View style={[styles.infoBlock, styles.infoPd]}>
                <Text style={[styles.textPrime]} allowFontScaling={false}>
                  <Text style={[styles.textPrimeBold]} allowFontScaling={false}>Disclaimer: </Text>{" "}
                  Resources will be shared with the community. Never upload
                  personal information to community resources.
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.comMgLtRtCom, styles.spSmTopBotCom, styles.borderBotGrey]}></View>
        </View>
      </View>

      {/* If at least one resource added, Please add resourceListWrapper section */}
      <View style={[styles.gridRow, styles.resourceListWrapper]}>
        <View style={styles.gridColFull}>
          <View style={[styles.resourceListView]}>
            {/* Loop Item Start */}
            {props.file !== null && resourceRow}
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

export default (FileUpload);
