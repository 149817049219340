import React from 'react';
import {View, TouchableOpacity, Text} from 'react-native';
import styles from '../../Css';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
const primaryButtonDark = props => {
  return (
    <View
      style={[
        styles.actionBtnWrapper,
        props.btnPosition === 'left'
          ? styles.flexAiFs
          : props.btnPosition === 'right'
          ? styles.flexAiFe
          : props.btnPosition === 'center'
          ? styles.flexAiCt
          : styles.flexCom,
      ]}>
      <View
        style={[
          props.btnPosition === 'full' ? styles.flexCom : styles.actionBtn,
        ]}>
        <TouchableOpacity
          onPressIn={props.touchableOnPress}
          disabled={props.disabled}
          style={[
            props.btnPosition === 'full' ? styles.flexCom : styles.flexBtn,
            styles.flexJcCtAiCt,
            props.btnShadowNone ? styles.btnShadowNone : styles.submitBtnShadow,
            styles.btnActionComLg,
            props.disabled && props.disabledColorChange
            ? [styles.submitBgOrangeLight, props.disableLightBlur ? styles.btnLightBlurState : styles.btnBlurState]
            : styles.submitBgOrange,
            props.btnColor === 'grey' && styles.submitBgAsh,
            props.btnStyleSpec,
            props.newConnection && styles.newConnectionBtn
          ]}
          nativeID={props.nativeId}>
          <View style={[styles.btnSingle, styles.btnSingleCent]}>
            {Boolean(props.activityInd) && (
              <View
                style={[
                  styles.indicatorIconView,
                  styles.indicatorLtMd,
                  props.activityIndStyle,
                ]}>
                <ActivityIndicator
                   pdZero={true}
                  size="small"
                />
              </View>
              )} 
            <Text style={[styles.textPrimeBold, styles.textWhite]} allowFontScaling={false}>
              {props.btnText}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default (primaryButtonDark);
