import React from 'react';
import {View, Image, Text} from 'react-native';
import styles from '../../../Css';

const profileImage = props => {
  const {userName, userImage, displayOnlineOffline} = props;
  const userAvatarName = userName
    ? userName.charAt(0) + (userName.length > 1 ? userName.charAt(1) : '')
    : null;
  return (
    <View style={styles.userSecView}>
      <View style={[styles.userImgView, styles.postRelative]}>
        <View style={[styles.profileAvatar, styles.avatarBg]}>
          {userImage ? (
            <Image
              style={styles.profileAvatarDefault}
              resizeMode={'cover'}
              source={{
                uri: userImage,
              }}
            />
          ) : (
            // <UserAvatar
            //   size={40}
            //   name={userAvatarName.toUpperCase()}
            //   textColor={'#FF9E38'}
            //   bgColor={'rgb(255, 228, 200)'}
            // />
            <View style={styles.profileAvatarDefault}>
              <Text
                style={[
                  styles.textTitleExLg,
                  styles.textAlignCenter,
                  styles.profileAvatarText,
                ]}
                allowFontScaling= {false}
                >
                {userAvatarName?.toUpperCase()}
              </Text>
            </View>
          )}
        </View>
        {/* Profile Online Icon */}
        {Boolean(displayOnlineOffline) && (
          <View style={styles.onlineWrapper}>
            <View style={styles.onlineWrapperIcon} />
          </View>
        )}
      </View>
    </View>
  );
};
export default (profileImage);
