import React from 'react';
import {TextInput} from 'react-native';
import styles from '../../../../Css';

const SearchName = props => {
  return (
    <React.Fragment>
      <TextInput
        style={[styles.textField, styles.textFieldWhite, 
        props.searchFieldSm
        ? styles.textFieldSearchSm  
        : styles.textFieldExLg
        ]}
        allowFontScaling={false}
        placeholder={props.placeholder}
        onChangeText={props.onChangeText}
        underlineColorAndroid="transparent"
        value={props.searchText}
      />
    </React.Fragment>
  );
};

export default SearchName;
