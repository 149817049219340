import React from 'react';
import {View} from 'react-native';
import styles from '../../Css';
import {
  LogoSetup,
  ViewStyle,
  ProceedTextConent,
} from '../AccountVerify/CommonContents';

const content = props => (
  <React.Fragment>
    <View style={[styles.staticPageContent, styles.staticPageMax]}>
      {LogoSetup}
      {ViewStyle}
      <View style={styles.gridRow}>
        <View style={[styles.gridColFull, styles.colPdTop]}>
          <View style={[styles.staticImgWrapper, styles.flexJcCtAiCt]}>
            {props.messageIcon}
          </View>
        </View>
      </View>

      <View style={styles.gridRow}>
        <View style={[styles.gridColFull, styles.staticSecText]}>
          <View style={styles.staticSecTitle}>{props.messageTitle}</View>
          <View style={[styles.staticSecDesc, styles.pdTopSm, styles.pdBotLg]}>
            {props.message}
          </View>
        </View>
      </View>
      {ProceedTextConent(props.navigateToDashbaord)}
    </View>
  </React.Fragment>
);

export default (content);
