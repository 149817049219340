import React from 'react';
import {View, TouchableOpacity, Text, Image, Platform} from 'react-native';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import {Link} from '@react-navigation/native';
import { WEB } from '../../../util/platformWindowConfig';

const breadCrumbSection = props => {
  const {breadCrumb} = props;
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <View 
      nativeID={'headerBreadCrumb'}
      style={[
        Platform.OS === WEB
        ? stylesRes.breadCrumbBlock
        : stylesRes.breadCrumbAppBlock
        ]}
      dataSet={{media: ids.breadCrumbBlock}}  
    >
      {breadCrumb.map((val, index) => {
        if (index === breadCrumb.length - 1) {
          return (
            <React.Fragment key={'bred_crum_' + index}>
              {props.home ? (
                <View
                  style={[
                    styles.breadCrumbTextSec,
                    styles.breadCrumbMg,
                    styles.flexRow,
                    styles.flexAiCt,
                  ]}
                  key={'bread_crumb_' + index}>
                  <Text style={[styles.textPrimeMdBold]} allowFontScaling={false}>{val.name}</Text>
                  <View
                    style={[styles.breadCrumbArrowSec, styles.breadCrumbMgLt]}>
                    <Image
                      style={[styles.nextIcon, styles.breadCrumbTint]}
                      source={require('../../../assets/icons/next.png')}
                    />
                  </View>
                </View>
              ) : (
                <View
                  style={[styles.breadCrumbTextSec, styles.breadCrumbMg]}
                  key={'bread_crumb_' + index}>
                  <Text style={[styles.headerText, styles.smText, styles.bold]} allowFontScaling={false}>
                    {val.name}
                  </Text>
                </View>
              )}
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={'bread_crumb_' + index}>
              <TouchableOpacity style={styles.breadCrumbMg}>
                <View style={[styles.breadCrumbLinkSec]}>
                  <Text style={[styles.headerText, styles.smLink]} allowFontScaling={false}>
                    <Link
                      to={val.link}
                      style={{textDecorationLine: 'none', color: '#404041'}}>
                      {val.name}
                    </Link>
                  </Text>
                </View>
              </TouchableOpacity>
              <View style={[styles.breadCrumbArrowSec, styles.breadCrumbMg]}>
                <Image
                  style={styles.nextIcon}
                  source={require('../../../assets/icons/next.png')}
                />
              </View>
            </React.Fragment>
          );
        }
      })}
    </View>
  );
};

export default (breadCrumbSection);
