import React from 'react';
import {View, TouchableOpacity, Text, Image} from 'react-native';
import styles from '../../../Css';

const addCaregiverSection = props => {
  const { redirectCaregiverAccess } = props;
  return (
    <View style={styles.headerCenterBlock}>
      <View style={[styles.caregiverBlock]}>
        <TouchableOpacity
          onPress={redirectCaregiverAccess}
          style={styles.flexRow}>
          <View style={styles.careGiverBtnView} nativeID={'headerAddCaregiver'}>
            <Image
              style={styles.switchMenuBgCom}
              source={require('../../../assets/icons/oval-button.png')}
            />

            <View style={[styles.ovalBtnTextViewCom]}>
              <View
                style={[styles.caregiverAdd, styles.flexAiCt, styles.flexRow]}>
                <View style={styles.smPlusIconView}>
                  <Image
                    style={styles.smPlusIcon}
                    source={require('../../../assets/icons/plus.png')}
                  />
                </View>
                <Text style={[styles.textPrimeSmBold, styles.textBlueDark]} allowFontScaling={false}>
                  Add Caregiver
                </Text>
                {/* prevent the premium user check for caregiver */}
                {/* {accountType === 'regular' && (
                <View style={styles.premiumCircleView}>                
                  <Image
                  style={styles.premiumIconExLg}
                  source={require('../../../assets/icons/premium.png')}
                />
                </View>
                 )} */}
              </View>
            </View>
          </View>
          {/* {accountType === 'regular' && (
            <View style={styles.premiumBtnSection}>
              <View style={styles.premiumIconView}>
                <Image
                  style={styles.premiumIcon}
                  source={require('../../../assets/icons/premium.png')}
                />
              </View>
              <Text
                style={[
                  props.windowWidth < 375
                    ? styles.textPrimeExSmBold
                    : styles.textPrimeSmBold,
                  styles.textOrange,
                ]}
                allowFontScaling = {FontScaling.allowFontScaleFalse}
                >
                Premium
              </Text>
            </View>
          )} */}
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default (addCaregiverSection);
