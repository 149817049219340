import React from 'react';
import {View, Text} from 'react-native';
import styles from '../../Css';

const validationMessage = props => {
  return (
    <View style={[styles.fieldErrorWrapper]}>
      <View style={[
      props.fieldLg
      ? styles.fieldErrorContentLg
      : styles.fieldErrorContent
      ]}>
        <View style={[styles.fieldErrorView]}>
          <View style={[styles.errorTriangleView]}>
            <View style={[styles.errorTriangle, styles.triangleLeft]} />
          </View>
          <View style={[styles.fieldErrorBox]}>
            <View style={[styles.fieldErrorDetails]}>
              <Text style={[styles.textPrimeSm, styles.textWhite]}  allowFontScaling={false}>
                {props.errorMessage}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default (validationMessage);
