import React from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native";
import stylesResponsive from "../../../../../CssResponsive";
import styles from "../../../../../Css";
import UIText from "../../../../Text/text";
import UITextInput from "../../../../../components/TextInput/textInput";
import ActivityIndicator from "../../../../../components/ActivityIndicator/activityIndicator";
import WarningDuplication from '../../../../../components/Warning/warningDuplication';

const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
const clinicalTrialFreeModalContent = props => {
  return (
    <View style={stylesRes.modalScrollWrapperCom} dataSet={{media: ids.modalScrollWrapperCom}}>
      <ScrollView 
        style={styles.modalScroller}
        keyboardShouldPersistTaps={'always'}
      >
          <View style={[styles.popUpBlock, styles.bgWhite]}>
            <View style={styles.popUpContentArea}>
              <View style={styles.gridSectionPopup}>
                <View style={[styles.popUpContentPd]}>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={styles.fieldRow}>
                        <View style={[styles.fieldCol, styles.topPdLg]}>
                          <UITextInput
                            style={[
                              styles.textField,
                              styles.textFieldSm,
                              !props.validTitle
                                ? styles.inValidColor
                                : props.duplicateTitle
                                  ? styles.duplicateWarningColor
                                  : styles.validColor
                            ]}
                            onChangeText={props.onTextValueChangeTitle}
                            value={props.title}
                            placeholder={"Enter title"}
                          />
                        {Boolean(props.duplicateTitle) &&
                            <WarningDuplication errorMessage={"Duplicate name found. Please consider a different name"}/>
                          }
                          </View>
                      </View>
                    </View>
                  </View>

                  <View>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={stylesRes.infoBlockMax}>
                          <View style={[styles.infoBlock, styles.infoPd]}>
                            <Text
                              style={[styles.textPrime, styles.textAlignCenter]}
                              allowFontScaling={false}
                            >
                              With the premium subscription you will be notified
                              when a new clinical trial matches your search. You
                              can save up to 5 searches.
                            </Text>
                          </View>
                          <View style={[styles.infoBlock]}>
                            <Text
                              style={[styles.textPrime, styles.textAlignCenter]}
                              allowFontScaling={false}
                            >
                              Let us relieve the stress!
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.btnWrapper]}>
                          <View style={[styles.btnRow, styles.btnRowSp]}>
                            <View style={[styles.btnViewArea]}>
                              <View style={stylesRes.btnSingleSecMin}>
                                <TouchableOpacity
                                  style={[
                                    styles.submitSub,
                                    styles.submitBgOrange
                                  ]}
                                  onPress={
                                    props.journalUserId === null
                                      ? props.redirectPremiumAccount
                                      : null
                                  }
                                  disabled={props.journalUserId !== null}
                                >
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.submitText,
                                          styles.submitTextSm,
                                          styles.bold
                                        ]}
                                        allowFontScaling={false}
                                      >
                                        Subscribe to Premium
                                      </Text>
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.btnArea}>
                          <View style={stylesRes.btnViewSecSm} dataSet={{media: ids.btnViewSecSm}}>
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={[styles.submitSm, styles.disableBtn]}
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={props.close}
                                  disabled={props.saveWait}
                                >
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent
                                      ]}
                                    >
                                      <UIText
                                        style={styles.submitText}
                                        title="Cancel"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                            <View style={styles.fieldBtnSp} />
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={[
                                    styles.submitSm,
                                    !props.title ||
                                    (props.saveSearchList.length >= 5 &&
                                      props.savedSearchId === -1)
                                      ? styles.disableBtn
                                      : styles.submitBgBlue
                                  ]}
                                  disabled={
                                    !props.title ||
                                    (props.saveSearchList.length >= 5 &&
                                      props.savedSearchId === -1) ||
                                    props.saveWait
                                  }
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={props.handleOnSaveSearchOk}
                                >
                                  <View style={styles.btnSingleView}>
                                    {props.saveWait && (
                                      <View style={styles.actIndCom}>
                                        <ActivityIndicator size="small" />
                                      </View>
                                    )}
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent
                                      ]}
                                    >
                                      <UIText
                                        style={[
                                          styles.submitText,
                                          styles.submitTextSm
                                        ]}
                                        title="Ok"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default (clinicalTrialFreeModalContent);
