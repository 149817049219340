import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  symptom: {
    id: null,
    name: null,
    isActive: null,
    displayProperties: {
      id: null,
      color: "",
      dashedLine: null
    }
  },
  newSymptom: null,
  selectedSymptom: null
};

const selectSymptom = (state, action) => {
  return updateObject(state, {
    selectedSymptom: action.selectedSymptom
  });
};

const createSymptomSuccess = (state, action) => {
  return updateObject(state, { newSymptom: action.newSymptom });
};

const createSymptomFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_SYMPTOM_SUCCESS:
      return createSymptomSuccess(state, action);
    case actionTypes.UPDATE_USER_SYMPTOM_FAIL:
      return createSymptomFail(state, action);

    case actionTypes.SELECT_SYMPTOM:
      return selectSymptom(state, action);

    default:
      return state;
  }
};
export default reducer;
