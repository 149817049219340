import React from "react";
import { View, Image, Text } from "react-native";
import styles from "../../Css";

const noImagesDocumentsFound = (props) => {
  return (
    <React.Fragment>
        <View style={[
            styles.searchEmptyResultLg, 
            styles.searchEmptyPdTop,
            styles.searchEmptyPd,
            styles.flexJcCtAiCt
            ]}
        >
            <Image
                style={styles.dragNdropIcon}
                source={require('../../assets/ocr-icons/add-document-icon.png')}
            />
            <View>
                <Text style={styles.textTitleExLgBold} allowFontScaling={false}>Upload your documents</Text>
            </View>
        </View>
    </React.Fragment>
  );
};

export default noImagesDocumentsFound;
