import React from 'react';
import { View } from 'react-native';
import SingleItem from './singleItem';
import UploadHeader from './uploadHeader';
import styles from '../../Css';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';

class UploadProgressWeb extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showUploadProgressContent: true,
    }
  }

  componentDidMount() {
    this.handleContinueUpload();
  }

  handleContinueUpload = () => {
    const artifactId = this.props.attachmentArtifactId;
    const currentEventId = this.props.currentEventId;
    const formData = new FormData();
    const ocrData = {};
    let key = Object.keys(this.props.files)[0];
    const cancelToken = this.props.files[key].cancelToken;
    formData.append('files[' + 0 + ']', this.props.files[key], this.props.files[key].name);
    ocrData[this.props.files[key].name] = this.props.files[key];
    let userId = this.props.user.id;
    if (this.props.journalUserId) {
      userId = this.props.journalUserId;
    }
    let uploadConfig = {
      onUploadProgress: (progressEvent) => {
        this.props.setAttachmentUploadProgressTemp(progressEvent.loaded);
      }
    };
    this.props.setAttachmentCurrentKey(key);
    const uniqueId = parseInt(Date.now() * Math.random());
    this.props.onUpload(
      artifactId,
      formData,
      (success, attachments) => {
        this.props.setAttachmentCurrentKey(null);
        if (success && attachments) {
          const upoladData = [];
          attachments.forEach((item, index) => {
            const data = ocrData[item.filename];

            var reader = new FileReader();
            reader.fileName = item.filename;
            reader.instanceId = artifactId;
            reader.attachmentCategory = 'Contact';
            reader.userId = userId;
            reader.index = index;
            reader.arrayLenght = attachments.length - 1;
            reader.upoladData = upoladData;
            reader.attachmentId = item.id;

            reader.onload = function(readerEvt) {
              readerEvt.target.upoladData.push({
                fileName: readerEvt.target.fileName,
                content: window.btoa(readerEvt.target.result),
                attachmentId: readerEvt.target.attachmentId.toString(),
              });
            };

            reader.readAsArrayBuffer(data);
          });
          const newProgress = this.props.attachmentUploadProgress + this.props.attachmentUploadProgressTemp;
          this.props.setAttachmentUploadProgress(newProgress);
          this.props.setAttachmentUploadProgressTemp(0);
          if (Object.keys(this.props.files).length > 0) {
            const newFiles = this.props.files;
            const delItem = this.props.files[key];
            delete newFiles[key];
            let finished = this.props.uploadedFiles;
            let newFinished = {
              ...finished,
              [key]:delItem,
            };
            this.props.saveUploadedAttachments(newFinished);
            if (Object.keys(newFiles).length > 0) {
              //call this again because we're not done yet
              this.props.onSaveFilesToAttach(newFiles);
              this.handleContinueUpload();
            } else {
              this.props.setAttachmentUploadProgressTemp(0);
              this.props.setAttachmentUploadStatus(false);
              this.props.setAttachmentArtifactId(null);
              this.props.onShowDeleteUploadConfirmation(false, null);
              this.props.showSuccessPopup(14, null);
              this.props.setCurrentEventId(null);
              if (currentEventId) {
                this.props.fetchAllHealthJourneyEvents(this.props.journalUserId, () => 8)
              }
            }
          }
        } else if (success === 'UPLOADCANCELLED') {
          this.props.setAttachmentUploadProgressTemp(0);
          this.props.cancelUploadAttachmentProcess(
            artifactId, 
            this.props.journalUserId, 
            () => {},
            uniqueId,
          );
          if (Object.keys(this.props.files).length > 0) {
            const newFiles = this.props.files;
            delete newFiles[key];
            if (Object.keys(newFiles).length > 0) {
              //call this again because we're not done yet (this happens when user cancelled the request)
              this.props.onSaveFilesToAttach(newFiles);
              this.handleContinueUpload();
            } else {
              this.props.setAttachmentUploadProgressTemp(0);
              this.props.setAttachmentUploadStatus(false);
              this.props.setAttachmentArtifactId(null);
              this.props.setCurrentEventId(null);
              if (Object.keys(this.props.uploadedFiles).length > 0) {
                this.props.showSuccessPopup(14, null);
                if (currentEventId) {
                  this.props.fetchAllHealthJourneyEvents(this.props.journalUserId, () => 8)
                }
              } else {
                this.props.hideUploadProgress();
              }
            }
          }
        }
      },
      false,
      this.props.journalUserId,
      this.props.files[key].size,
      uploadConfig,
      cancelToken,
      uniqueId,
      currentEventId,
    );
  }

  onShowHideUploadProgress = () => {
    this.setState({showUploadProgressContent: !this.state.showUploadProgressContent});
  };

  onCancelSingleUpload = (key, cancelToken) => {
    const fileSize = this.props.attachmentFullSize - this.props.files[key].size;
    this.props.setAttachmentSize(fileSize);
    if (key === this.props.currentUploadingAttachmentKey) {
      this.props.cancelUploadAttachmentProcessAxios(cancelToken);
    } else {
      if (Object.keys(this.props.files).length > 0) {
        const newFiles = this.props.files;
        delete newFiles[key];
        this.props.onSaveFilesToAttach(newFiles);
      }
    }
  };

  onCancelAllUpload = () => {
    if (Object.keys(this.props.files).length > 0) {
      if (!this.props.deleteUploadConfirm) {
        this.props.onShowDeleteUploadConfirmation(true, null);
      } else {
        this.props.clearFilesToAttach();
        this.props.clearUploadedAttachments();
        if (Object.keys(this.props.files).length > 0) {
          const token = this.props.files[this.props.currentUploadingAttachmentKey].cancelToken;
          this.props.cancelUploadAttachmentProcessAxios(token);
        }
        this.props.setAttachmentUploadStatus(false);
        this.props.setAttachmentArtifactId(null);
        this.props.onShowDeleteUploadConfirmation(false, null);
        this.props.hideUploadProgress();
        this.props.setAttachmentSize(0);
        this.props.setAttachmentUploadProgress(0);
        this.props.setCurrentEventId(null);
      }
    } else {
      this.props.clearFilesToAttach();
      this.props.clearUploadedAttachments();
      this.props.hideUploadProgress();
      this.props.setAttachmentUploadProgress(0);
    }
  };

  getHeaderText = () => {
    let text = "";
    if (Object.keys(this.props.files).length > 0) {
      text = `Uploading ${Object.keys(this.props.files).length} Item(s)`;
    } else {
      text = `${Object.keys(this.props.uploadedFiles).length} Upload(s) Complete`;
    }
    return text;
  }

  renderUploadingItems = () => {
    let items = null;
    if (Object.keys(this.props.files).length) {
      items = Object.keys(this.props.files).map(item => (
        <SingleItem 
          itemName={this.props.files[item].name}
          item={this.props.files[item]}
          status={true}
          onCancel={this.onCancelSingleUpload}
          id={item}
          isUploading={item === this.props.currentUploadingAttachmentKey}
          progress={Math.round(this.props.attachmentUploadProgressTemp * 100 / this.props.files[item].size)}
        />
      ));
    }
    return items;
  }

  renderUploadedItems = () => {
    let items = null;
    if (Object.keys(this.props.uploadedFiles).length) {
      items = Object.keys(this.props.uploadedFiles).map(item => (
        <SingleItem 
          itemName={this.props.uploadedFiles[item].name}
          status={false}
        />
      ));
    }
    return items;
  }

  render() {
    return (
      <View style={[ styles.dashboardPopupUpload]}>
        <View style={styles.uploadHeaderBorder}>
          <UploadHeader
            title={this.getHeaderText()}
            onShowHide={this.onShowHideUploadProgress}
            showContent={this.state.showUploadProgressContent}
            onCancel={this.onCancelAllUpload}
            progress={Math.round(((this.props.attachmentUploadProgress + this.props.attachmentUploadProgressTemp) * 100) / this.props.attachmentFullSize) || 0}
          />
        </View>
        
        {this.state.showUploadProgressContent &&
          <View>
            {this.props.files && Object.keys(this.props.files).length > 0 && this.renderUploadingItems()}
            {this.props.uploadedFiles && Object.keys(this.props.uploadedFiles).length > 0 && this.renderUploadedItems()}
          </View>
        }
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
    attachmentFullSize: state.routines.attachmentFullSize,
    attachmentUploadProgress: state.routines.attachmentUploadProgress,
    attachmentUploadProgressTemp: state.routines.attachmentUploadProgressTemp,
    files: state.imagesDocuments.files,
    uploadedFiles: state.imagesDocuments.uploadedFiles,
    currentUploadingAttachmentKey: state.routines.currentUploadingAttachmentKey,
    deleteUploadConfirm: state.uistate.deleteUploadConfirm,
    showUploadProgress: state.uistate.showUploadProgress,
    attachmentArtifactId: state.routines.attachmentArtifactId,
    user: state.routines.user,
    currentEventId: state.uistate.currentEventId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    cancelUploadAttachmentProcessAxios: cancelToken => dispatch(actions.cancelUploadAttachmentProcessAxios(cancelToken)),
    setAttachmentSize: size => dispatch(actions.setAttachmentFullSize(size)),
    onSaveFilesToAttach: files => dispatch(actions.saveFilesToAttach(files)),
    clearFilesToAttach: () => dispatch(actions.clearFilesToAttach()),
    clearUploadedAttachments: () => dispatch(actions.clearUploadedAttachments()),
    setAttachmentUploadStatus: status => dispatch(actions.setAttachmentUploadStatus(status)),
    setAttachmentArtifactId: id => dispatch(actions.setAttachmentArtifactId(id)),
    onShowDeleteUploadConfirmation: (status, data) => dispatch(actions.onShowDeleteUploadConfirmation(status, data)),
    hideUploadProgress: () => dispatch(actions.hideUploadProgress()),
    setAttachmentUploadProgress: progress => dispatch(actions.setAttachmentUploadProgress(progress)),
    setAttachmentUploadProgressTemp: progress => dispatch(actions.setAttachmentUploadProgressTemp(progress)),
    setAttachmentCurrentKey: key => dispatch(actions.setAttachmentCurrentKey(key)),
    saveUploadedAttachments: files => dispatch(actions.saveUploadedAttachments(files)),
    onUpload: (
      artifactId,
      formData,
      callBack,
      reload,
      journalUserId,
      fileSize,
      uploadConfig,
      cancelToken,
      uniqueId,
      currentEventId,
    ) =>
      dispatch(
        actions.uploadAttachmen(
          artifactId,
          formData,
          callBack,
          reload,
          journalUserId,
          fileSize,
          uploadConfig,
          cancelToken,
          uniqueId,
          currentEventId,
        ),
      ),
    showSuccessPopup: (id, graphId) =>
      dispatch(actions.showSuccessPopup(id, graphId)),
    cancelUploadAttachmentProcess: (artifactId, journalUserId, callback, uniqueId) => 
      dispatch(actions.cancelUploadAttachmentProcess(artifactId, journalUserId, callback, uniqueId)),
    setCurrentEventId: id => dispatch(actions.setCurrentEventId(id)),
    fetchAllHealthJourneyEvents: (journalUserId, callback) => dispatch(actions.fetchAllHealthJourneyEvents(journalUserId, callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadProgressWeb);