import React, {Component} from 'react';
import RoutineItem from './RoutineItem';
import {sortAlphabeticalOrder} from '../../../util/commonUiLogic';
class RoutineItems extends Component {
  shouldComponentUpdate(nexProps) {
    if (nexProps.list.length === 0) return true;
    if (this.compareStates(nexProps)) return true;

    return false;
  }

  compareStates(nextProps) {
    const {list} = this.props;
    if (JSON.stringify(list) !== JSON.stringify(nextProps.list)) return true;

    return false;
  }

  render() {
    const {isActive, list, handleDeleteRoutine, handleEdit} = this.props;
    const listSort = sortAlphabeticalOrder(list);
    const routineItems = listSort.map(entry => (
      <RoutineItem
        key={entry.id}
        isActive={isActive}
        entry={entry}
        handleDeleteRoutine={handleDeleteRoutine}
        handleEdit={handleEdit}
      />
    ));

    return <React.Fragment>{routineItems}</React.Fragment>;
  }
}

export default RoutineItems;
