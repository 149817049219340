import React, {Component} from 'react';
import {connect} from 'react-redux';
import OutlookPageContainer from '../../components/PageLayout/OutlookPageContainer';
import OutlookListContainer from '../../components/PageLayout/OutlookListContainer';
import OutlookContentContainer from '../../components/PageLayout/OutlookContentContainer';
import ListRoutine from './ListRoutine';
import ViewRoutine from './ViewRoutine';
import AddRoutine from './AddRoutine';
import {JOURNALS} from '../../navigation/path';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { TAB_BREAk_POINT_END } from '../../util/platformWindowConfig';

class RoutinesScreen extends Component {
  constructor(props) {
    super(props);
    const {newJournal, windowHeight, windowWidth, journalEntrySelected} = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      setView: !Boolean(props?.route?.params?.fromWindowResize),
      setNewJoural: newJournal ? true : false,
      openPopup: false,
      fromWindowResize: props?.route?.params?.fromWindowResize,
      unMounted: false,
      selectedEntryId: null,
    };
    //react ref dosent work cuz of uranium dosent forward ref, we have to implement our own ref
    this.addRoutineRef = null;
    props.navigation.setParams({
      journalPath: newJournal ? JOURNALS.addJournalPathSuffix : JOURNALS.viewJournalPathSuffix,
      journalName: newJournal?.title ?? journalEntrySelected?.title
    });
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      const {newJournal, windowHeight, windowWidth} = this.props;
      this.setState({
        windowHeight,
        windowWidth,
        setNewJoural: newJournal ? true : false,
      });
      pendoTrackEvent('Journals@');
    });
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth, navigation, journalEntrySelected, newJournal} = props;
    const {setNewJoural, unMounted} = state;
    if (windowWidth < TAB_BREAk_POINT_END && !unMounted) {
      if (setNewJoural) {
        const {routes = []} = navigation.getState();
        const newRoutes = [...routes.filter(item => item.name !== JOURNALS.addJournalPath && item.name !== JOURNALS.path), {
          name: JOURNALS.addJournalPath,
          params: {
            from: props.route.name,
            journalName: newJournal?.title ?? journalEntrySelected?.title,
            fromWindowResize : true
          }
        }]
        navigation.reset({
          index: newRoutes.length - 1,
          routes: newRoutes,
        });
        return {
          unMounted: true,
        }
        // navigation.push(
        //   JOURNALS.addJournalPath, {
        //     from: props.route.name,
        //     journalName: journalEntrySelected?.title,
        //     fromWindowResize : true
        //   },
        // );
      }
    }
    if (
      (state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth || 
      state.selectedEntryId !== (newJournal?.id ?? journalEntrySelected?.id)) && !unMounted
    ) {
      if (windowWidth < TAB_BREAk_POINT_END) {
        navigation.setParams({journalPath: '', journalName: ''});
      } else {
        navigation.setParams({
          journalPath: setNewJoural ? JOURNALS.addJournalPathSuffix : JOURNALS.viewJournalPathSuffix,
          journalName: newJournal?.title ?? journalEntrySelected?.title
        });
      }
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        selectedEntryId: newJournal?.id ?? journalEntrySelected?.id
      };
    }

    if (setNewJoural) {
      return {setView: false};
    }
    return null;
  }

  render() {
    const {windowWidth, navigation} = this.props;
    let showListRoutineHeader = false;
    if (windowWidth < TAB_BREAk_POINT_END) {
      showListRoutineHeader = true;
    }
    return (
      <OutlookPageContainer>
        <OutlookListContainer
          disabled={
            !this.state.setNewJoural
          }
          onPressOpen={() => {
            this.addRoutineRef && this.addRoutineRef.handleOnSubmitCancel();

          }}>
          <ListRoutine
            showListRoutineHeader={showListRoutineHeader}
            route={this.props.route}
            navigation={navigation}
            deletedJournal={
              this.state.setView
                ? false
                : this.state.setNewJoural
                ? false
                : true
            }
            setView={this.state.setView}
            setNewJoural={this.state.setNewJoural}
            setViewRoutine={res => this.setState({setView: res})}
          />
        </OutlookListContainer>
        {this.state.setView && !showListRoutineHeader ? (
          <OutlookContentContainer>
            <ViewRoutine
              navigation={navigation}
              route={this.props.route}
              showListRoutineHeader={showListRoutineHeader}
              deleteSuccess={() => {
                this.setState({setView: false});
              }}
              editSuccess={(journalName) => {
                this.setState({setNewJoural: true});  
                this.props.navigation.setParams({              
                  journalPath: JOURNALS.addJournalPathSuffix,
                  journalName: journalName
                });
              }}
              fromMain
            />
          </OutlookContentContainer>
        ) : this.state.setNewJoural && !showListRoutineHeader ? (
          <OutlookContentContainer>
            <AddRoutine
              getRef={(ref) => this.addRoutineRef = ref}
              navigation={navigation}
              route={{...this.props.route, params: {...(this.props.route?.params ?? {}), fromWindowResize: this.state.fromWindowResize}}}
              showListRoutineHeader={showListRoutineHeader}
              deleteSuccess={() => {
                this.setState({setNewJoural: false, setView: true});
                this.props.navigation.setParams({              
                  journalPath: JOURNALS.viewJournalPathSuffix,
                });
              }}
              fromMain
            />
          </OutlookContentContainer>
        ) : null}
      </OutlookPageContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    newJournal: state.routineTemplate.newJournal,
    journalEntrySelected: state.routines.journalEntrySelected,
  };
};

export default connect(
  mapStateToProps,
  null,
)(RoutinesScreen);
