import React from 'react';
import {View, TextInput, TouchableOpacity, Image} from 'react-native';
import styles from '../../Css';
import FieldTitleCommon from './fieldTitleCommon';
import * as FontScaling from '../../components/Text/fontScaling';
const inputPasswordField = React.forwardRef((props, ref) => (
  <View style={[styles.textInputWrapper, styles.postRelative]}>
    <FieldTitleCommon
      fieldAlign={props.fieldAlign}
      fieldBg={props.fieldBg}
      title={props.title}
      requirdStar={props.requirdStar}
      fieldLt={props.fieldLt}
      fieldSm={props.fieldSm}
      fieldRg={props.fieldRg}
    />
    <TextInput
      style={[
        styles.textInputField,
        styles.fieldPdRtSp,
        props.fieldBg && props.fieldBg === 'grey'
          ? styles.bgThinGrey
          : props.fieldBg && props.fieldBg === 'blue'
          ? styles.bgBlueExLight
          : props.fieldBg && props.fieldBg === 'transparent'
          ? styles.bgTransparent
          : styles.bgWhite,
        props.fieldAlign && props.fieldAlign === 'center'
          ? styles.textAlignCenter
          : styles.textAlignLeft,
        props.invalid ? styles.inValidColor : styles.validColor,
        props.fieldStyle,
      ]}
      allowFontScaling={false}
      underlineColorAndroid="transparent"
      value={props.value ? props.value : ''}
      onChangeText={props.onChangeText}
      editable={props.editable}
      placeholder={props.placeholder}
      passwordVisible={props.passwordVisible}
      secureTextEntry={props.secureTextEntry}
      onFocus={props.onFocus}
      ref={ref}
      returnKeyType={props.returnKeyType}
      onSubmitEditing={props.onSubmitEditing}
      autoCapitalize={props.autoCapitalize}
      textContentType={props.textContentType}
      maxFontSizeMultiplier = {FontScaling.fontScaleField}
      autoComplete={'off'}
    />
    {Boolean(props.passwordShowHide) && (
      <View style={[styles.passHintIn, styles.rightZero]}>
        <TouchableOpacity
          style={[styles.passHintViewTouch, styles.passHintTouchLg]}
          onPress={props.handlePasswordVisibility}
        >
          <View style={styles.passHintDetails}>
            <Image
              style={props.passwordVisible ? styles.passHiddenLg : styles.passShowLg}
              source={
                props.passwordVisible
                  ? require("../../assets/icons/pw-hide-blue.png")
                  : require("../../assets/icons/pw-show-blue.png")
              }
              resizeMode={"contain"}
            />
          </View>
        </TouchableOpacity>
    </View>
    )}
  </View>
));

export default inputPasswordField;
