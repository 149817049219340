import React from 'react';
import {View, Text} from 'react-native';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';

const caregiverHeader = props => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  const {journalUserName} = props;
  return (
    <View style={stylesRes.caregiverAlertBox}>
      <View 
        nativeID={'caregiverHeader'}
        style={[stylesRes.caregiverAlertCont, stylesRes.caregiverAlertBg]}>
        <Text
          style={[
            styles.textPrimeSm,
            styles.textWhite,
            styles.textAlignCenter,
          ]}
          allowFontScaling={false}
          >
          You are logged in as caregiver to{' '}
          <Text style={[styles.bold]} allowFontScaling={false}>
            {journalUserName ? journalUserName : ''}
          </Text>
        </Text>
      </View>
    </View>
  );
};

export default (caregiverHeader);
