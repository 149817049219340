import React, { Component } from "react";
import { View, TouchableOpacity, Text } from "react-native";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import lightGreen from "@material-ui/core/colors/lightGreen";
import styles from "../../Css";
import moment from "moment";

const theme = createMuiTheme({
  palette: {
    primary: lightGreen
  }
});

class datePicker extends Component {
  constructor(props) {
    super(props);
    const { format, date } = props;
    this.format = format ? format : "D MMM 'YY";
    this.changeClick = false;
    this.state = {
      date: date ? date : null,
      openTo: "date"
    };
  }

  onChange = date => {
    const { onConfirm } = this.props;
    this.changeClick = true;
    this.setState(
      {
        date: moment(date).valueOf()
      },
      () => {
        if (onConfirm) {
          onConfirm(moment(date).valueOf());
        }
      }
    );
  };

  onClose = () => {
    const { onConfirm, reminderSection } = this.props;
    if (!this.changeClick && !reminderSection) {
      this.setState({ date: null }, () => {
        if (onConfirm) {
          onConfirm(null);
        }
      });
    }
    this.changeClick = false;
  };

  textFieldComponent = props => {
    const { customTouchableComponent } = this.props;
    const { onClick } = props;
    if (customTouchableComponent) {
      return customTouchableComponent(onClick);
    }

    const { date } = this.state;

    return (
      <TouchableOpacity onPress={onClick}>
        <View style={[styles.textFieldDatePickerField]}>
          <Text style={styles.textPrime} allowFontScaling={false}>
            {date
              ? moment(date).format(
                  this.format === "dd-MM-yyyy" ? "DD-MM-YYYY" : this.format
                )
              : ""}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  toolbarComponent = props => {
    return (
      <View style={[styles.datePickerHeaderWrapperWeb]}>
        <View style={[styles.datePickerHeaderView, styles.pickBg]}>
          <View style={[styles.datePickerHeaderRow, styles.pickerRowMg]}>
            <View style={[styles.datePickerHeaderCont]}>
              <View style={[styles.datePickerBtnText]}>
                <TouchableOpacity
                  style={[styles.datePickerBtn, styles.datePickerBtnPd]}
                  onPress={() => props.setOpenView("year")}
                >
                  <Text style={[styles.textPrimeLg, styles.textExLightWhite]} allowFontScaling={false}>
                    {moment(props.date).format("YYYY")}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <View style={[styles.datePickerHeaderRow]}>
            <View style={[styles.datePickerHeaderCont]}>
              <View style={[styles.datePickerBtnText]}>
                <TouchableOpacity
                  style={[styles.datePickerBtn, styles.datePickerBtnPd]}
                  onPress={() => props.setOpenView("month")}
                >
                  <Text
                    style={[
                      styles.textNoSm,
                      styles.lineHeightSmNo,
                      styles.textExLightWhite
                    ]}
                    allowFontScaling={false}
                  >
                    {moment(props.date).format("D MMM 'YY")}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  onMonthChange = date => {
    this.setState({
      date: moment(date).valueOf()
    });
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const { date } = nextProps;
    this.setState({ date: date });
  };

  render() {
    const { date } = this.state;
    const { shouldDisableDate, format, minDate, maxDate } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            value={moment(date)}
            onChange={this.onChange}
            format={format}
            InputProps={{
              disableUnderline: true
            }}
            openTo={"date"}
            TextFieldComponent={this.textFieldComponent}
            onClose={this.onClose}
            shouldDisableDate={day => {
              return shouldDisableDate && shouldDisableDate(day);
            }}
            views={["year", "month", "date"]}
            showTodayButton={true}
            onMonthChange={this.onMonthChange}
            ToolbarComponent={this.toolbarComponent}
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate ? new Date(maxDate) : undefined}
            orientation="portrait"
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}
export default (datePicker);
