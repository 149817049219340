import React, {Component} from 'react';
import {View} from 'react-native';

import styles from '../../../../Css';

import {connect} from 'react-redux';
import Collapsible from '../../../Collapsible/Collapsible';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextFrame from '../../../TextInput/textFrame';
import MedticationTooltipContent from '../../../TooltipContent/Medtication';
import {getSortedMedicationDosagesWithTreatmentObject} from "../../../../store/actions";
import { TAB_BREAk_MAX_WIDTH } from '../../../../util/platformWindowConfig';

class Medications extends Component {
  constructor(props) {
    super(props);
    const {dosages} = props;
    const filteredMedications = dosages.filter(dosage => dosage.quantity !== null);
    const sortedMedicationDosagesWithTreatmentObject = getSortedMedicationDosagesWithTreatmentObject(filteredMedications, false, props.treatmentsProcessed);
    this.state = {
      dosages: sortedMedicationDosagesWithTreatmentObject,
      visibleMedications: filteredMedications && filteredMedications.length > 0,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {dosages, windowWidth} = props;
    const filteredMedications = dosages.filter(dosage => dosage.quantity !== null);
    const sortedMedicationDosagesWithTreatmentObject = getSortedMedicationDosagesWithTreatmentObject(filteredMedications, false, props.treatmentsProcessed);
    if (windowWidth > TAB_BREAk_MAX_WIDTH && JSON.stringify(state.dosages) !== JSON.stringify(filteredMedications)) {
      return {
        dosages: sortedMedicationDosagesWithTreatmentObject,
        visibleMedications: filteredMedications && filteredMedications.length > 0,
      }
    }
    return null;
  }

  getItem = (item, index) => {
    const {treatmentsProcessed, handleRedirectMedications} = this.props;
    const {quantity, treatment} = item;
    const {name, identificationNumber, treatmentType} = item.treatmentObj;
    return (
      <View style={styles.fieldContainer} key={'je_med_' + index}>
        <View style={[styles.fieldRowWrapper]}>
          <View style={styles.fieldColPlusMinus}>
            <TextFrame
              title={'Quantity'}
              fieldBg={FieldDetails.BGGREY}
              fieldAlign={FieldDetails.ALIGNCENTER}
              fieldValue={quantity}
              editable={false}
            />
          </View>
          <View style={styles.fieldColSp} />
          <View style={styles.fieldColStretch}>
            <TextFrame
              title={'Medication'}
              fieldBg={FieldDetails.BGGREY}
              fieldInfo={true}
              fieldValue={name}
              editable={false}
              renderComponent={() => (
                <MedticationTooltipContent
                  identificationNumber={identificationNumber}
                  treatmentType={treatmentType}
                  treatment={treatmentsProcessed[treatment]}
                  handleRedirectMedications={handleRedirectMedications}
                  windowWidth={this.props.windowWidth}
                  showLink={true}
                />
              )}
              noOfLines={1}
            />
          </View>
        </View>
      </View>
    );
  };

  render() {
    const {dosages, visibleMedications} = this.state;
    const {treatmentsProcessed} = this.props;

    return (

<>
      {dosages && dosages.length > 0 ? ( 
        <Collapsible
        title="Medications/Supplements"
        contentVisibility={visibleMedications}
        handleOnPressCollapsible={() => {
          this.setState({
            visibleMedications: !visibleMedications,
          });
        }}
        disabled={dosages && dosages.length > 0 ? false : true}
        style={[styles.collapseTitle]}
        customTitleStyle={true}
        borderNone={true}>
        {dosages.length > 0 && (
          <View
            style={[
              styles.collapseContent,
              styles.colPd,
              styles.colPdBotSm,
              styles.bgThinGrey,
            ]}>
            {/* Loop Item for Medication - Start */}
            {dosages.map(
              (item, index) =>
                treatmentsProcessed[item.treatment] &&
                this.getItem(item, index),
            )}
            {/* Loop Item for Medication - End */}
          </View>
        )}
      </Collapsible>
      )
      : null }
</>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    treatmentsProcessed: state.routines.treatmentsProcessed,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(
  mapStateToProps,
  null,
)(Medications);
