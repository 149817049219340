import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import styles from '../../Css';

const cancelButton = (props) => {
    const { touchableOnpress, buttonText, disabled, nativeId } = props
    return (
        <TouchableOpacity
            style={[
                styles.cancelButtonSm,
                styles.commonCancelBtn,
                props.cancelBtnWidthDiff
                ? styles.commonCancelBtnMinWidthSm
                : styles.commonCancelBtnMinWidth,
            ]}
            onPress={touchableOnpress}
            disabled={disabled}
            nativeID={nativeId}
        >
            <View style={styles.btnSingleView}>
            <View style={[
                styles.btnSingle,
                styles.btnSingleCent,
            ]}>
                <Text
                    style={styles.commonCancelBtnTxt}
                    allowFontScaling={false}
                >
                    {buttonText ? buttonText : "Cancel"}
                </Text>
            </View>
            </View>
        </TouchableOpacity>
    );
}

export default (cancelButton)
