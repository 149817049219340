export const cssSurveyResponsive = {

  titleWrapper: {
    flexDirection: "row",
    marginBottom: 8,
    justifyContent: 'flex-start',
  },

  contentMainWrapper: {
    paddingTop: 0,

    "@media (min-width: 992px)": {
      paddingTop: 20,
    }
  },

  surveyScreenMainWrapper: {

    "@media (min-width: 992px)": {
      paddingTop: 30,
    },
    "@media (min-width: 576px)": {
      maxWidth: 460
    }
  },

  orangeButtonWrapper: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,

    "@media (min-width: 992px)": {
      marginTop: 0,
    }
  },

  surveyDescriptionBlock: {
    width: '100%',
    alignItems: 'center',

    "@media (min-width: 576px)": {
      maxWidth: 380,
    }
  },

  surveyStatusWrapper: {
    flexBasis: 90,
    marginLeft: 0,
    marginRight: 20,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',

    "@media (min-width: 992px)": {
      marginLeft: 30,
      marginRight: 60,
    }
  },

  questionsOuterWrapper: {
    marginTop: 20,
  },

  responsiveBackBtnMargin: {
    marginRight: 5,

    "@media (min-width: 992px)": {
      marginRight: 15,
    }
  },

  responsiveNextBtnMargin: {
    marginLeft: 5,

    "@media (min-width: 992px)": {
      marginLeft: 15,
    }
  },

  responsiveSubmitBtnMargin: {
    marginLeft: 5,
  },

  fixedWidthIconWrapper: {
    flexDirection: 'row',
    flexBasis: 120,
    maxWidth: 120,
    justifyContent: 'flex-end',
    alignItems: 'center',

    "@media (min-width: 992px)": {
      marginRight: 30,
    }
  },

  responsiveFixedWidthDateWrapper: {
    marginRight: 0,
    flexBasis: 80,
    maxWidth: 80,

    "@media (min-width: 992px)": {
      marginRight: 30,
      flexBasis: 115,
      maxWidth: 115,

    }
  },

  resViewDataFull: {
    flex: 1,
    paddingLeft: 0,

    "@media (min-width: 992px)": {
      paddingLeft: 30,

    }
  },

  titleWrapperSection: {
    flexDirection: 'row',
  },

  titleBtnWrapper: {
    flexBasis: 85,
    maxWidth: 85,
    paddingLeft: 15,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },

  titleWrapperMain: {
    flex: 1,
  },

  btnPdBot: {
    paddingBottom: 30,
  }
};
