import React, {Component} from 'react';
import {View, Platform} from 'react-native';
import {connect} from 'react-redux';
import styles from '../../Css';
import * as actions from '../../store/actions/index';
import Header from '../../components/Header/header';
import stylesResponsive from '../../CssResponsive';
import Tabs from '../../components/Tab/Tab';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import Activity from '../../components/MedicationsOrTherapies/Activity';
import Medication from '../../components/MedicationsOrTherapies/Medication';
import Attachments from '../../components/Attachment/Attachments';
import SectionTitle from '../../components/Text/sectionTitle';
import TherapyTypes from '../../components/MedicationsOrTherapies/TherapyTypes';
import {MEDICATIONS, DASHBOARD, HEALTH_JOURNEY} from '../../navigation/path';
import StorageBanner from '../../components/Banner/StorageBanner';
import PageContainer from '../../components/PageLayout/PageContainer';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import AddAttachment from '../../components/Button/addAttachment';
import SavingConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import {
  alertBody,
  medicationTitle,
  activityTitle,
} from '../../GlobalFunctions/GlobalString';
import Cropper from '../../components/ImageCropper/AmazingCropperPage';
import { handleCommonBackNavigation } from '../../navigation/RootNavigation';
import MenuOverlayContainer from '../../components/PageLayout/MenuOverlayContainer';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { TAB_BREAk_POINT_END, WEB } from '../../util/platformWindowConfig';

class AddTherapie extends Component {
  constructor(props) {
    super(props);
    if (!this.props.newTherapie) {
      return this.redirectMedicationsTherapies(true);
    }
    if (this.props.activeTab !== 0) {
      this.props.setActiveTab(0);
    }
    const {newTherapie, isNew} = props;
    const {treatmentType, artifactId} = newTherapie;
    let fromPopup = null;
    let fromManualEntry = false;
    const activeTherapyTab = props.route?.params?.activeTherapyTab;
    this.forceEdit = newTherapie.forceEdit;
    if (props.route?.params && props.route?.params.fromPopup) {
      fromPopup = props.route?.params.fromPopup;
      if (props.route?.params.fromManualEntry) {
        fromManualEntry = true;
      }
    }
    this.newActivity = newTherapie;
    this.newTherapie = newTherapie;
    this.mediChangesRef = null;
    this.activityChangesRef = null;
    this.state = {
      artifactId: artifactId,
      activeTherapyTab: activeTherapyTab ? activeTherapyTab : treatmentType === 5 ? 1 : 0,
      activeCircle: this.hasAttachments(artifactId),
      isNew: isNew,
      isScrollDisabled: true,
      fromPopup: fromPopup,
      fromManualEntry: fromManualEntry,
      background: '#fff',
      confirmBox: false,
      deleteWaitTherapy: false,
      saveWait: false,
      isHaveChangesActive: false,
      isHaveChangesMedication: false,
      initMedicationHash: null,
      initActiveHash: null,
      fromHealthJourney: props?.route?.params?.from === HEALTH_JOURNEY.path,
    };
    this.scrollViewRef = null;
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
      pendoTrackEvent(this.props.route.name === MEDICATIONS.addTherapiePath ? 'MedSupp+Act/@AddTherapy' : 'MedSupp+Act/@EditTherapy');
    });
  }
  hasAttachments = artifactId => {
    let attachments = [];
    if (this.props.attachmentsCompleteDigest) {
      attachments = this.props.attachmentsCompleteDigest.userArtifactAttachments.filter(
        function(attachment) {
          return attachment.userArtifact === artifactId;
        },
      );
    }
    return attachments.length > 0;
  };
  disabledScroll = (val, callBack) => {
    this.setState({isScrollDisabled: val}, () => {
      if (callBack) {
        callBack();
      }
    });
  };
  setActiveCircle = () => {
    this.setState({activeCircle: this.hasAttachments(this.state.artifactId)});
  };

  setSelectTherapy = val => {
    this.setState({activeTherapyTab: val});
  };

  componentWillReceiveProps(props) {
    const {windowHeight, windowWidth} = props;
    this.setState({
      windowWidth,
      windowHeight,
    });
    this.props.hideMobileMenu();
  }
  componentWillUnmount() {
    //TO-DO remove previous navigation changes
    // const {
    //   onDeleteTherapie,
    //   journalUserId,
    //   newContact,
    //   newTherapie,
    //   contacts,
    //   onResetNewTherapie,
    // } = this.props;
    // if (newTherapie) {
    //   const {isNew} = this.state;
    //   if (isNew) {
    //     onDeleteTherapie(
    //       newTherapie.id,
    //       res => {
    //         onResetNewTherapie();
    //       },
    //       journalUserId,
    //     );
    //     if (newTherapie.prescribedBy) {
    //       if (
    //         newContact &&
    //         newContact.data &&
    //         newContact.data.id === newTherapie.prescribedBy
    //       ) {
    //         this.props.onDeleteContact(
    //           newTherapie.prescribedBy,
    //           contacts,
    //           res => {},
    //         );
    //       }
    //     }
    //   }
    // }
    this.props.showMobileMenu();
  }

  handleOnPressBackButton = () => {
    this.state.activeTherapyTab === 0
      ? this.mediChangesRef.handleOnSubmitCancel(res => {
          if (this.state.isNew && this.hasAttachments(this.state.artifactId)) {
            this.setState({confirmBox: true});
          } else {
            if (res) {
              this.setState({confirmBox: true});
            } else {
              this.redirectTherapies();
            }
          }
        })
      : this.activityChangesRef.handleOnSubmitCancel(res => {
          if (this.state.isNew && this.hasAttachments(this.state.artifactId)) {
            this.setState({confirmBox: true});
          } else {
            if (res) {
              this.setState({confirmBox: true});
            } else {
              this.redirectTherapies();
            }
          }
        });
  };

  handleOnPressSaveButton = () => {
    const {fromHealthJourney} = this.state;
    const globalPath = this.props.route?.params?.globalPath;
    if (fromHealthJourney) {
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: this.props.windowWidth < TAB_BREAk_POINT_END && this.props.eventData && this.props.eventData["type"] ? HEALTH_JOURNEY.addEventPath : HEALTH_JOURNEY.path }],
      });
    } else if (globalPath === DASHBOARD.path) {
      this.props.setFromPopup(null);
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: DASHBOARD.path }],
      });
    } else {
      this.redirectViewMedicationTherapie();
    }
  };

  redirectViewMedicationTherapie = () => {
    this.props.setFromPopup(null);
    this.props.navigation.reset({
      index: 0,
      routes: [{ 
        name: MEDICATIONS.viewTherapiesPath, 
        params: {from: this.props.route?.params.from ? this.props.route?.params.from : ''}
      }],
    });
  };

  redirectMedicationsTherapies = flag => {
    const activeTherapyTab = this.props.route?.params?.activeTherapyTab;
    if (!flag) {
      if (this.state.fromPopup) {
        this.props.setFromPopup(this.state.fromPopup, Boolean(activeTherapyTab === 0));
      }
    }
    const {
      onDeleteTherapie,
      journalUserId,
      newContact,
      newTherapie,
      contacts,
      onResetNewTherapie,
    } = this.props;
    if (newTherapie) {
      const {isNew} = this.state;
      if (isNew) {
        onDeleteTherapie(
          newTherapie.id,
          res => {
            onResetNewTherapie();
          },
          journalUserId,
        );
        if (newTherapie.prescribedBy) {
          if (
            newContact &&
            newContact.data &&
            newContact.data.id === newTherapie.prescribedBy
          ) {
            this.props.onDeleteContact(
              newTherapie.prescribedBy,
              contacts,
              res => {},
            );
          }
        }
      }
    }
    this.setState({confirmBox: false});
    if (
      this.props.route?.params &&
      this.props.route?.params.from === MEDICATIONS.scanPrescriptionPath) {
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: MEDICATIONS.path }],
        });
      } else if (this.props.route?.params && this.props.route?.params.globalPath) {
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: this.props.route?.params.globalPath }],
        });
      }
      else {
        handleCommonBackNavigation(this.props.navigation);
      }
  };

  handleChangeComplete = color => {
    this.setState({background: color.hex});
  };

  scrollToTop = () => {
    if (this.scrollViewRef) {
      setTimeout(() => {
        Platform.OS === WEB
          ? this.scrollViewRef?.scrollTo({ x: 0, y: 0, animated: true })
          : this.scrollViewRef?.scrollToPosition(0, 0, true);
      }, 500);
    }
  };

  scrollToEnd = () => {
    if (this.scrollViewRef && this.scrollViewRef.scrollToEnd) {
      setTimeout(() => {
        this.scrollViewRef.scrollToEnd({animated: true});
      }, 500);
    }
  }

  updateNewTherapie = therapie => {
    if (therapie.treatmentType === 5) {
      this.newActivity = therapie;
    } else {
      this.newTherapie = therapie;
    }
  };

  handleDeleteTherapie = () => {
    this.setState({deleteWaitTherapy: true});
    const {onDeleteTherapie, journalUserId, newTherapie} = this.props;
    onDeleteTherapie(
      newTherapie.id,
      res => {
        this.setState({
          deleteWaitTherapy: false,
        });
        if (res) {
          this.redirectTherapies();
        }
      },
      journalUserId,
    );
  };

  redirectTherapies = () => {
    if (this.state.isNew) {
      this.setState({isNew: false});
      this.redirectMedicationsTherapies();
    } else if (this.state.fromHealthJourney) {
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: this.props.windowWidth < TAB_BREAk_POINT_END && this.props.eventData && this.props.eventData["type"] ? HEALTH_JOURNEY.addEventPath : HEALTH_JOURNEY.path }],
      });
    } else if (this.props.route?.params?.from === MEDICATIONS.path){
      this.props.navigation.reset({
        index: 0,
        routes: [{ name:  MEDICATIONS.path }],
      });
    } else{
      this.setState({confirmBox: false});
      handleCommonBackNavigation(this.props.navigation);
    }
  };

  selectedTab = tabid => {
    this.props.setActiveTab(tabid);
    if (tabid === 1) {
      this.props.fetchUserAttachments(this.state.artifactId);
    }
  };

  openCroper = (capturedImage) => {
    this.setState({
      showCropperPage: true, 
      image: capturedImage
    });
  }

  closeCroper = (croppedUri, width, height) => {
    if (croppedUri) {
      this.setState({
        showCropperPage: false, 
        image: {...this.state.image, uri: croppedUri, height: height, width: width},
      });
    } else {
      this.setState({
        showCropperPage: false, 
        image: {...this.state.image},
      });
    }
  }

  imageUploadCompleted = () => {
    this.setState({image: null});
  }

  setHaveChanges = (value, type) => {
    if (type === 'MEDICATION') {
      if (this.state.isHaveChangesMedication !== value) {
        this.setState({isHaveChangesMedication: value});
      }
    } else {
      if (this.state.isHaveChangesActive !== value) {
        this.setState({isHaveChangesActive: value});
      }
    }
  }

  setHashChanges = (value, type) => {
    if (type === 'MEDICATION') {
      if (!this.state.initMedicationHash) {
        this.setState({initMedicationHash: value});
      }
    } else {
      if (!this.state.initActiveHash) {
        this.setState({initActiveHash: value});
      }
    }
  }

  isHaveChanges = () => {
    let flag = this.state.isNew && this.hasAttachments(this.state.artifactId) ? true : false;
    if (this.state.activeTherapyTab === 0) {
      if (this.state.isHaveChangesMedication) {
        flag = true;
      }
    } else {
      if (this.state.isHaveChangesActive) {
        flag = true;
      }
    }
    return flag;
  }

  //Save if there any unsaved question before saving the actual data
  handleOnSaveQuestion = callBack => {
    const {temporyQuestionObject} = this.props;
    if (temporyQuestionObject) {
      this.props.onSaveHealthQuestion(
        temporyQuestionObject,
        temporyQuestionObject.artifactId,
        () => {
          this.props.updateTemporyQuestionObject(null);
          callBack();
        },
        this.props.journalUserId,
      );
    } else {
      callBack();
    }
  }

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {hasNotch, route} = this.props;
    const {fromHealthJourney} = this.state;
    return (
      this.props.newTherapie && this.state.showCropperPage 
      ? <Cropper
          image={this.state.image} 
          close={this.closeCroper} 
        />
      : <View style={[styles.pageBodyWrapper]} onTouchStart={() => this.props.onTouchRoutine(true)}>
        {this.props.newTherapie ? (
          this.props.deleteTherapieInProgress ? (
            <ActivityIndicator />
          ) : (
            <React.Fragment>
              <Header
                showFilers={false}
                showBack={true}
                showClearFilters={false}
                showNotify={true}
                handleOnPressBackButton={this.handleOnPressBackButton}
                index={fromHealthJourney ? HEALTH_JOURNEY.index : MEDICATIONS.index}
                navigation={this.props.navigation}
                route={this.props.route}
              />
              <SpaceBar />
              {this.isHaveChanges() && 
                <MenuOverlayContainer onPressOpen={this.handleOnPressBackButton} />
              }
              <StorageBanner />
              <KeyboardAwareScrollViewHoc
                hasNotch={hasNotch}
                extraScrollHeight={130}
                scrollEnabled={this.state.isScrollDisabled}
                ref={val => (this.scrollViewRef = val)}>
                <PageContainer smScreenFull={true}>
                  {Boolean(route?.params?.activeTherapyTab === 0 || route?.params?.activeTherapyTab === 1)
                    ? <View style={[styles.flexJcCtAiCt, styles.viewMedSection]}>
                        <SectionTitle 
                          smallTextTilte={true}
                          title={route?.params?.activeTherapyTab === 0
                            ? 'New Medication/Supplement'
                            : 'New Activity'}
                        />
                      </View>
                    : <TherapyTypes
                        selectTherapy={this.setSelectTherapy}
                        activeTherapyTab={this.state.activeTherapyTab}
                        isNew={this.state.isNew}
                      />
                  }
                </PageContainer>
                <View style={stylesRes.gridSection}>
                  <Tabs
                    pageContainer={true}
                    smScreenFull={true}
                    activeTab={this.props.activeTab}
                    tabIndex={this.selectedTab}>
                    {/* Details tab */}
                    <View title="Details" style={[styles.tabContentSection]}>
                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <PageContainer smScreenFull={true}>
                            <View
                              style={[
                                stylesRes.tabContentFullArea,
                                stylesRes.contBotPdFixed,
                              ]}>
                              {this.state.activeTherapyTab === 0 && (
                                <Medication
                                  handleOnPressSaveButton={() => {
                                    this.setState({isNew: false});
                                    this.handleOnPressSaveButton();
                                  }}
                                  navigation={this.props.navigation}
                                  getRef={ref => (this.mediChangesRef = ref)}
                                  disabledScroll={this.disabledScroll}
                                  fromManualEntry={this.state.fromManualEntry}
                                  scrollToTop={this.scrollToTop}
                                  scrollToEnd={this.scrollToEnd}
                                  updateNewTherapie={this.updateNewTherapie}
                                  newTherapie={this.newTherapie}
                                  handleOnPressBackButton={
                                    this.handleOnPressBackButton
                                  }
                                  setSaveWait={(saveWait) => {
                                    this.setState({
                                      saveWait: saveWait
                                    });
                                  }}
                                  setHaveChanges={this.setHaveChanges}
                                  setHashChanges={this.setHashChanges}
                                  initMedicationHash={this.state.initMedicationHash}
                                  forceEdit={this.forceEdit}
                                  handleOnSaveQuestion={this.handleOnSaveQuestion}
                                />
                              )}
                              {this.state.activeTherapyTab === 1 && (
                                <Activity
                                  handleOnPressSaveButton={() => {
                                    this.setState({isNew: false});
                                    this.handleOnPressSaveButton();
                                  }}
                                  navigation={this.props.navigation}
                                  getRef={ref =>
                                    (this.activityChangesRef = ref)
                                  }
                                  disabledScroll={this.disabledScroll}
                                  scrollToTop={this.scrollToTop}
                                  scrollToEnd={this.scrollToEnd}
                                  updateNewTherapie={this.updateNewTherapie}
                                  newTherapie={this.newActivity}
                                  handleOnPressBackButton={
                                    this.handleOnPressBackButton
                                  }
                                  setSaveWait={(saveWait) => {
                                    this.setState({
                                      saveWait: saveWait
                                    });
                                  }}
                                  setHaveChanges={this.setHaveChanges}
                                  setHashChanges={this.setHashChanges}
                                  initActiveHash={this.state.initActiveHash}
                                  forceEdit={this.forceEdit}
                                  handleOnSaveQuestion={this.handleOnSaveQuestion}
                                />
                              )}
                            </View>
                          </PageContainer>
                        </View>
                      </View>
                    </View>
                    {/* Attachments tab */}
                    <View
                      title="Attachments"
                      activeCircle={
                        this.hasAttachments(this.state.artifactId) ||
                        this.state.activeCircle
                      }
                      fetchAttachmentSpinner={this.props.fetchAttachmentSpinner}
                      attachmentCategory="Therapy">
                      <PageContainer smScreenFull={true}>
                        <View style={styles.attachWrapper}>
                          <Attachments
                            attachmentCategory="Therapy"
                            setActiveCircle={this.setActiveCircle}
                            attachmentSpaceZero={true}
                          />
                          <AddAttachment
                            attachmentCategory="Therapy"
                            btnText={'Add'}
                            btnPosition={'left'}
                            openCroper={this.openCroper}
                            tempCapturedImage={this.state.image} 
                            onUploadCompleted={this.imageUploadCompleted} 
                          />
                        </View>
                      </PageContainer>
                    </View>
                  </Tabs>
                </View>
              </KeyboardAwareScrollViewHoc>
              {this.state.confirmBox &&
              <SavingConfirmation
                visibleModal={this.state.confirmBox}
                onBackdropPress={() => {
                  this.setState({confirmBox: false});
                }}
                onRequestClose={() => {
                  this.setState({confirmBox: false});
                }}
                alertTitle={
                  this.state.activeTherapyTab === 0
                    ? medicationTitle
                    : activityTitle
                }
                alertBody={alertBody}
                handleCancel={() => {
                  this.state.isNew
                    ? this.handleDeleteTherapie()
                    : this.state.fromHealthJourney
                      ? this.props.navigation.reset({
                          index: 0,
                        routes: [{ name: this.props.windowWidth < TAB_BREAk_POINT_END && this.props.eventData && this.props.eventData["type"] ? HEALTH_JOURNEY.addEventPath : HEALTH_JOURNEY.path }],
                        })
                      : this.props.navigation.goBack();
                }}
                handleConfirm={() => {
                  this.setState({confirmBox: false}, () => {
                    if (this.props.activeTab !== 0) {
                      this.props.setActiveTab(0);
                    }
                    setTimeout(
                      () =>
                        this.state.activeTherapyTab === 0
                          ? !this.mediChangesRef.getDisabledStatus()
                            ? this.mediChangesRef.onPressUpdateTherapie()
                            : (this.setState({confirmBox: false}), this.scrollToTop())
                          : !this.activityChangesRef.getDisabledStatus()
                            ? this.activityChangesRef.onPressUpdateTherapie()
                            : (this.setState({confirmBox: false}), this.scrollToTop())
                      ,1000,
                    );
                  });
                }}
                deleteWait={this.state.deleteWaitTherapy}
                confirmWait={this.state.saveWait}
                disabled={this.state.saveWait || this.state.deleteWaitTherapy}
              />
              }
            </React.Fragment>
          )
        ) : (
          this.redirectMedicationsTherapies(true)
        )}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    newTherapie: state.therapie.newTherapie,
    isNew: state.therapie.isNew,
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
    journalUserId: state.uistate.journalUserId,
    newContact: state.contacts.newContact,
    contacts: state.routines.contacts,
    hasNotch: state.uistate.hasNotch,
    fetchAttachmentSpinner: state.routines.journalAttachmentSpinner,
    deleteTherapieInProgress: state.uistate.deleteTherapieInProgress,
    activeTab: state.surveys.activeTab,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    temporyQuestionObject: state.routineTemplate?.temporyQuestionObject,
    eventData: state.uistate.eventData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDeleteTherapie: (id, callBack, journalUserId) =>
      dispatch(actions.deleteTherapie(id, callBack, journalUserId)),
    onDeleteContact: (id, contacts, callBack) =>
      dispatch(actions.deleteContact(id, contacts, callBack)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    onResetNewTherapie: () => dispatch(actions.resetNewTherapie()),
    setFromPopup: (fromPopup, fromDashbordMeds) => dispatch(actions.setFromPopup(fromPopup, fromDashbordMeds)),
    setActiveTab: activeTabIndex =>
      dispatch(actions.setActiveTab(activeTabIndex)),
    fetchUserAttachments: artifactId =>
      dispatch(actions.fetchUserAttachments(artifactId)),
    onSaveHealthQuestion: (
      healthQuestion,
      artifactId,
      callBack,
      journalUserId,
    ) =>
      dispatch(
        actions.saveHealthQuestion(
          healthQuestion,
          artifactId,
          callBack,
          journalUserId,
        ),
      ),
    updateTemporyQuestionObject: questionObject =>
      dispatch(actions.updateTemporyQuestionObject(questionObject)),
    onTouchRoutine: flag =>
      dispatch(actions.onTouchRoutine(flag)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddTherapie);
