import React from "react";
import { View, Text } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";

const currencyFormatter = require("currency-formatter");

const orderSummery = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <View style={styles.gridRow}>
      <View style={styles.gridColFull}>
        <View style={styles.secArea}>
          <View style={[styles.colPdLtRt]}>
            <View style={stylesRes.maxComSec} dataSet={{media: ids.maxComSec}}>
              <View style={[styles.subSection]}>
                <View style={[styles.titleBlockCom]}>
                  <Text
                    style={[styles.textTitleExLgBold, styles.textAlignCenter]}
                    allowFontScaling= {false}
                  >
                    Order Summary
                  </Text>
                </View>
                <View style={[styles.sumDataView, styles.sumDataPdSm]}>
                  <View style={[styles.sumDataRow]}>
                    <View style={[styles.sumDataField]}>
                      <Text style={[styles.textTitleExLg]} allowFontScaling= {false}>
                        {props.type} Plan
                      </Text>
                    </View>
                    <View style={[styles.sumDataValue]}>
                      {/* <View style={[styles.sumDataSign]}>
                        <Text style={[styles.textTitleExLg]}>$</Text>
                      </View> */}
                      <View style={[styles.sumDataAmount]} />
                      <Text style={[styles.textTitleExLg]} allowFontScaling= {false}>
                        {currencyFormatter.format(props.amount, {
                          locale: "en-CA"
                        })}
                      </Text>
                    </View>
                  </View>

                  {/* <View style={[styles.sumDataRow]}>
                    <View style={[styles.sumDataField]}>
                      <Text style={[styles.textTitleExLg]}>Tax</Text>
                    </View>
                    <View style={[styles.sumDataValue]}>
                      <View style={[styles.sumDataSign]}>
                        <Text style={[styles.textTitleExLg]}>$</Text>
                      </View>
                      <View style={[styles.sumDataAmount]} />
                      <Text style={[styles.textTitleExLg]}>2.50</Text>
                    </View>
                  </View> */}

                  <View style={[styles.sumDataRow]}>
                    <View style={[styles.sumDataField]}>
                      <Text style={[styles.textTitleExLg]} allowFontScaling= {false}>Total</Text>
                    </View>
                    <View
                      style={[styles.sumDataValue, styles.borderThinBlackTop]}
                    >
                      {/* <View style={[styles.sumDataSign]}>
                        <Text style={[styles.textTitleExLg]}>$</Text>
                      </View> */}
                      <View style={[styles.sumDataAmount]} />
                      <Text style={[styles.textTitleExLg]} allowFontScaling= {false}>
                        {currencyFormatter.format(props.total, {
                          locale: "en-CA"
                        })}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={[styles.descBlockCom]}>
                  <Text style={[styles.textPrime]} allowFontScaling= {false}>
                    I agree that I will be charged today. My subscription will
                    automatically renew on these same terms until I cancel; I
                    authorize Hanalytics Solutions to charge my card now and
                    upon each renewal; and a cancellation will be effective on
                    the next renewal date of my subscription.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default (orderSummery);
