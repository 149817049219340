import React, { Component } from 'react';
import {View, Platform, ScrollView} from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { unFocus } from 'zamplo-common-js';
import Header from '../../components/Header/header';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import MenuOverlayContainer from '../../components/PageLayout/MenuOverlayContainer';
import PageContainer from '../../components/PageLayout/PageContainer';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import AddEventMenu from '../../components/HealthJourney/AddEventButton/AddEventMenu';
import HealthJourneyAddEvent from './HealthJourneyEvents/HealthJourneyAddEvent';
import HealthJourneyHeader from './HealthJourneyList/HealthJourneyHeader';
import HealthJourneyList from './HealthJourneyList/HealthJourneyList';
import SavingConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import {HEALTH_JOURNEY, MEDICATIONS, SYMPTOMS} from '../../navigation/path';
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import {hasValue} from '../../util/hashValueGenerator';
import * as actions from '../../store/actions/index';
import * as constants from './HealthJourneyConstant'
import { cancelTokenSource } from '../../axios/axios-config-common';
import MobileProgress from './HealthJourneyAttachments/MobileProgress';
import { getFileExtensions } from '../../util/commonUiLogic';
import NoResultFound from './HealthJourneyList/NoResultFound';
import ClearAllFilter from '../../components/HealthJourney/ClearAllFilter/ClearAllFilter';
import { TAB_BREAk_POINT_START, WEB } from '../../util/platformWindowConfig';

let eventObject = {
  type: "",
};
const TRYS = [3000, 2000, 1000, 500];
class HealthJourneyScreen extends Component {

  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      dob: props.user.birthday
        ? moment.utc(props.user.birthday, 'x').format('YYYY-MM-DD')
        : null,
      saveDisabled: false,
      eventObj: {},
      eventInProgress: false,
      events: {},
      fetching: false,
      startDate : null,
      endDate : null,
      isStartDaySelected : false,
      isEndDaySelected: false,
      dispalyConfirmBox: false,
      deleteConfirmBox: false,
      deleteEventId: -1,
      attachUploading: false,
      advancedFilterObj: null,
      allCategoriesList: null,
      dateFilterObj: {},
      sortFilterValue: 1,
      selectedDropdown: [],
      mounted: false,
      filtering: false,
      filterQueue: [],
      openFilters: false,
      isFiltersAvailable: false,
      isDateSelected:true,
      filterDone: false
    };
    this.initHashValue = null;
    this.filterDoneCheck = null;
  }
  scrollViewRef = React.createRef();
  layoutDimentions = {};
  yearLayoutDimentions = {};

  componentDidMount() {
    const {navigation} = this.props;
    navigation.addListener('focus', () => {
      this.mountedProcess();
      this.initialize();
    });
  }

  initialize = () => {
    this.setState({
      sortFilterValue: this.props.sortType,
      dateFilterObj: this.props.setSelectedDateObject,
      advancedFilterObj: this.props.setSelectedAdvancedFilterItems,
      allCategoriesList: this.props.setSelectedCategoryObject,
      selectedDropdown: this.props.selectedDropdownItemNumber,
      filtering: this.props.selectedDropdownItemNumber.length > 0,
    }, this.filterPipeline);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.sortType !== this.props.sortType){
      this.setState({sortFilterValue: this.props.sortType});
    }
    if(prevProps.setSelectedDateObject !== this.props.setSelectedDateObject){
      this.setState({dateFilterObj: this.props.setSelectedDateObject});
    }
    if (prevProps.setSelectedAdvancedFilterItems !== this.props.setSelectedAdvancedFilterItems) {
      this.setState({advancedFilterObj: this.props.setSelectedAdvancedFilterItems});
    }
    if (prevProps.setSelectedCategoryObject !== this.props.setSelectedCategoryObject) {
      this.setState({allCategoriesList: this.props.setSelectedCategoryObject});
    }

    if(prevProps.selectedDropdownItemNumber !== this.props.selectedDropdownItemNumber || 
      (JSON.stringify(prevProps.eventData) !== JSON.stringify(this.props.eventData))){
      this.setState({selectedDropdown: this.props.selectedDropdownItemNumber, filtering: true}, this.filterPipeline);
    }

    if((Boolean(this.state.events && this.state.events?.length > 0) && 
        JSON.stringify(this.sortEvents(prevState.events)) !== JSON.stringify(this.sortEvents(this.state.events))
      ) || (!Boolean(this.props.fetchAllHealthJourneyEvents?.fetching) && JSON.stringify(this.sortEvents(this.props.fetchAllHealthJourneyEvents?.data || [])) !== JSON.stringify(this.sortEvents(prevProps.fetchAllHealthJourneyEvents?.data || [])))) {
        this.filterPipeline();
    }
  }

  mountedProcess = () => {
    const {hideMobileMenu, eventData, openedYearAndId, setOpenedYearAndId, fetchEvents, journalUserId} = this.props;
    hideMobileMenu();
    if (!_.isEmpty(eventData)) {
      this.setState({eventObj: eventData, eventInProgress: true}, () => {
        this.initHashValue = eventData.initHashValue ? eventData.initHashValue : this.getHashValue();
        this.forceUpdate();
      });
      this.doAScroll(undefined, 200);
    }

    if (openedYearAndId) {
      // const {year, id, bubbleData} = openedYearAndId;
      // setTimeout(() => {
      //   this.doAScroll(undefined, this.layoutDimentions[year + "_" + id]?.y + (bubbleData?.y || 0) + this.currentLayout?.y + this.getNotchHeight());
      //   setOpenedYearAndId(null)
      // }, 4000);
      this.handleScrollToAPosition(openedYearAndId, 0);
    } else {
      fetchEvents(journalUserId, () => 9)
    }

    this.setDateRange();
  }

  handleScrollToAPosition = (positionData, trys = 0) => {
    const {setOpenedYearAndId} = this.props;
    const {year, id, bubbleData} = positionData;
    if (trys >= TRYS.length) return; //Waited soo long!
    if (_.isEmpty(this.layoutDimentions))
      return setTimeout(() => this.handleScrollToAPosition(positionData, trys++), TRYS[trys])

    this.doAScroll(undefined, this.yearLayoutDimentions[year]?.y + this.layoutDimentions[year + "_" + id]?.y + (bubbleData?.y || 0));
    setOpenedYearAndId(null);
  }

  setDateRange = () => {
    const {setInnitialDateRange} = this.props;
    if(this.state.events){
      const tempDatesList = Object.values(this.state.events).flatMap(o1=>Object.values(o1).flatMap(o2=>[o2.startDate])).sort();
      const dateObject = {'firstDate': tempDatesList[0], 'lastDate': tempDatesList.pop()};
      setInnitialDateRange(dateObject);
    }
  }

  componentWillUnmount() {
    const {showMobileMenu, setActiveBubble} = this.props;
    showMobileMenu();
    setActiveBubble(null);
    this.stopFilterDoneCheck();
  }
  
  static getDerivedStateFromProps(props, state) {
    const {windowWidth, navigation, eventData, fetchAllHealthJourneyEvents, setEventData} = props;
    const {fetching, eventInProgress, events, eventObj} = state;
    const {fetching: fetchingProps, data} = fetchAllHealthJourneyEvents;
    let objToBeUpdated = {};

    if (
      windowWidth < constants.WINDOW_MOBILE_WIDTH_MARGIN &&
      !_.isEmpty(eventData)
    ) {
      if (!_.isEqual(eventData, eventObj)) {
        setEventData(eventObj);
      }
      navigation.reset({
        index: 0,
        routes: [{ name: HEALTH_JOURNEY.addEventPath }],
      });
    }

    if (!_.isEmpty(eventData) || eventInProgress) {
      objToBeUpdated = {
        ...objToBeUpdated,
        eventInProgress: !_.isEmpty(eventData),
      };
    }

    if (fetchingProps || fetching) {
      objToBeUpdated = {
        ...objToBeUpdated,
        fetching: fetchingProps,
        events: !fetchingProps && fetching ? _.omitBy(data, _.isNil) : null,
        mounted: true,
      };
    }

    // if (_.isEmpty(events) && !_.isEmpty(data)) {
    //   objToBeUpdated = {
    //     ...objToBeUpdated,
    //     events: _.omitBy(data, _.isNil)
    //   };
    // }

    if (!_.isEmpty(objToBeUpdated))
      return _.omitBy(objToBeUpdated, _.isNil);
    
    return null;
  }

  sortEvents = (events) => {
    const copiedEvent = _.cloneDeep(events);
    
    const orderedObject = Object.keys(copiedEvent).sort().reduce(
      (obj, key) => { 
        obj[key] = copiedEvent[key].map(i => {
          const copiedObj = _.cloneDeep(i);
          if(copiedObj.attachments) delete copiedObj.attachments;
          copiedObj.attachmentsCount = i?.attachments?.length;
          return copiedObj
        }).sort(function(a, b) {
          return a.id - b.id;
      }); 
        return obj;
      }, 
      {}
    );
    return orderedObject;
  }

  handleOnPressBackButton = () => {
    const {navigation} = this.props;
    handleCommonBackNavigation(navigation);
  };

  onPressCategoryItem = (type) => {
    const {setEventData, windowWidth, resetNewTherapie} = this.props;
    eventObject = {
      ...eventObject,
      type: type
    };
    resetNewTherapie();
    setEventData(eventObject);
    this.setState({eventObj: eventObject}, () => {
      this.initHashValue = this.getHashValue();
    });
    Platform.OS === WEB &&
      windowWidth >= constants.WINDOW_MOBILE_WIDTH_MARGIN &&
      this.doAScroll(undefined, 200);
  }

  doAScroll = (x, y) => {
    setTimeout(() => {
      if (this.scrollViewRef?.current?.scrollTo) {
        this.scrollViewRef.current.scrollTo({x, y, animated: true})
      }
    }, 500);
  };

  onPressCancel = () => {
    if(this.initHashValue === this.getHashValue()) {
      this.closeAddEventBox();
    } else {
      this.setState({
        dispalyConfirmBox: true
      });
    }
  }

  closeAddEventBox = () => {
    const {setEventData, setActiveBubble} = this.props;
    setEventData({});
    setActiveBubble(null);
    this.setState({eventInProgress: false, dispalyConfirmBox: false});
    this.initHashValue = null;
  }

  updateLayoutDimentions = (category, id, dimentions) => {
    this.layoutDimentions[category + "_" + id] = dimentions;
  }

  updateYearLayoutDimentions = (year, dimentions) => {
    this.yearLayoutDimentions[year] = dimentions;
  }

  onPressViewItem = (bubbleId, recordings, cardId, year, data, type) => {
    const {setOpenedYearAndId, setActiveBubble} = this.props;
    setOpenedYearAndId({
      year,
      id: cardId,
      type,
      bubbleId,
      bubbleData: data
    });
    setActiveBubble(null);
    if (type === constants.eventTypes.SYMPTOMS) {
      this.onSelectSymptom(bubbleId, recordings);
    } else if (type === constants.eventTypes.MEDICATIONS) {
      this.onSelectMedication(bubbleId);
    }
  }

  onSelectSymptom = (id, recordings) => {
    const {userSymptoms, onSelectSymptom, navigation} = this.props;
    let found = userSymptoms.find(symptom => symptom.id === id);

    if (found)
      onSelectSymptom(
        {...found, recordings: recordings ? recordings : undefined},
        () =>
          navigation.push(found.publishedBy !== "PARTNER" ? SYMPTOMS.editSymptomPath : SYMPTOMS.viewSymptomPath, {
            from: HEALTH_JOURNEY.path,
          }),
      );
  };

  onSelectMedication = id => {
    const {treatments, editTherapie, navigation, onSelectTherapie} = this.props;
    const therapieTobeUpdated = {
      ...treatments.filter(item => item.id === id)?.[0],
      forceEdit: true,
    }
    if (therapieTobeUpdated.publishedBy !== 'PARTNER') {
      editTherapie(therapieTobeUpdated, () =>
        navigation.navigate(MEDICATIONS.editTherapiePath, {from: HEALTH_JOURNEY.path})
      );
    } else {
      onSelectTherapie(therapieTobeUpdated, () =>
        navigation.navigate(MEDICATIONS.viewTherapiesPath, {from: HEALTH_JOURNEY.path})
      )
    }
  }

  onSaveAttachWeb = (attachments, artifactId, eventId) => {
    const {onSaveFilesToAttach, setAttachmentUploadStatus, setAttachmentArtifactId, setCurrentEventId, setAttachmentFullSize, showUploadProgress} = this.props;
    const files = {};
    let fileSize = 0;
    attachments.forEach((item, idx) => {
      const file = item.file;
      file.cancelToken = cancelTokenSource.source();
      files[idx] = file;
      fileSize += file.size;
    });
    onSaveFilesToAttach(files);
    setAttachmentUploadStatus(true);
    setAttachmentArtifactId(artifactId);
    setCurrentEventId(eventId);
    setAttachmentFullSize(fileSize);
    showUploadProgress();
  };

  onSaveAttachMobile = (attachments, artifactId, eventId) => {
    this.setState({
      attachUploading: true,
      attachments,
      artifactId,
      eventId,
    });
  };

  onPressAdd = (data, attachments, callback) => {
    const {saveAndUpdateHealthJourneyEvent, journalUserId, setEventData, showSuccessPopup, setOpenedYearAndId, setActiveBubble} = this.props;
    setActiveBubble(null);
    this.setState({filterDone: false});
    saveAndUpdateHealthJourneyEvent(journalUserId, data, (flag, data) => {
      if (flag) {
        const {id, startDate, artifactId} = data;
        const year = startDate?.split("-")[0];
        setEventData({});
        this.setState({
          eventInProgress: false, 
          dispalyConfirmBox: false, 
        });
        this.initHashValue = null;
        showSuccessPopup(18, null, null);
        setOpenedYearAndId({
          year: year,
          id,
        });

        this.filterDoneCheck = setInterval(() => {
          if (this.state.filterDone) {
            setTimeout(() => {
              this.doAScroll(undefined, this.yearLayoutDimentions[year]?.y + this.layoutDimentions[year + "_" + id]?.y);
              setOpenedYearAndId(null);
              if (attachments && attachments.length > 0) {
                if (Platform.OS === WEB) {
                  this.onSaveAttachWeb(attachments, artifactId, id);
                } else {
                  this.onSaveAttachMobile(attachments, artifactId, id);
                }
              }
            }, 500);
            this.stopFilterDoneCheck();
          }
        }, 500);
      }
      //this.sortAllEvents(this.state.sortFilterValue);
      callback();
    });
  }

  stopFilterDoneCheck = () => {
    //stops the filter done checker
    if (this.filterDoneCheck) {
      clearInterval(this.filterDoneCheck);
      this.filterDoneCheck = null;
      this.setState({filterDone: false});
    }
  }

  onPressEditItem = (data, collapse) => {
    const {setEventData, windowWidth} = this.props;
    const {attachments = [], categoryType, medications = [], healthData = [], symptoms = []} = data;
    const dataNew = {
      ...data,
      type: categoryType,
      attachments: attachments.map(item => {
        const {filename = "", id} = item;
        const extension = filename.split(".").pop();
        return {
          tempId: id,
          id,
          fileName: filename.replace(`.${extension}`, ""),
          fileExtension: extension,
        }
      }),
      categoryTypeItems: [...medications, ...healthData, ...symptoms].map(item => ({id: item.id})),
      tagsArray: data.tags ? data.tags.includes(",")  ? data.tags?.split(",")?.map(t => ({tags: t})) : [{tags: data.tags}] : [],
    };
    this.setState({eventObj: dataNew}, () => {
      this.initHashValue = this.getHashValue();
      this.forceUpdate();
    });
    setEventData(dataNew);
    if (Platform.OS === WEB && 
      windowWidth >= constants.WINDOW_MOBILE_WIDTH_MARGIN) {
        this.doAScroll(undefined, 200);
        collapse && collapse();
    }
  }

  onPressDeleteItem = id => {
    this.setState({
      deleteEventId: id,
      deleteConfirmBox: true
    });
  }

  handleOnDeleteEvent = () => {
    const {deleteHealthJourneyEvent, journalUserId} = this.props;
    const {deleteEventId} = this.state;
    this.setState({deleteWait: true});
    deleteHealthJourneyEvent(journalUserId, deleteEventId, (success) => {
      this.setState({deleteWait: false});
      if (success) {
        this.setState({deleteEventId: -1, deleteConfirmBox: false},this.filterPipeline);
      }
    })

  }

  getHashValue = () => {
    const {eventObj, eventInProgress} = this.state;

    if (eventInProgress) {
      const { eventName = null, description = null, startDate = null, endDate = null, tagsArray = [], attachments = [], categoryTypeItems = [] } = eventObj;

      const currentHash = hasValue(
        eventName +
        ',' +
        description +
          ',' +
        startDate +
          ',' +
        endDate +
          ',' +
        JSON.stringify(tagsArray) +
         ',' +
        JSON.stringify(attachments) +
        ',' +
        JSON.stringify(categoryTypeItems)
      );
      return currentHash;
    }
  }

  onPressFilter = () => {
    const { navigation } = this.props;
    navigation.reset({
      index: 0,
      routes: [{ name: HEALTH_JOURNEY.filter }],
    });
  }

  exit = () => {
    const {fetchEvents, journalUserId} = this.props;
    this.setState({
      attachUploading: false,
      attachments: [],
      artifactId: null,
      eventId: null,
    }, () => fetchEvents(journalUserId, () => 9));
  }

  filterTagsFunction = (tags , data) => {
    const tagsArray = data.tags.split(',');
      for(const tag of tagsArray){
        if(!tags.includes(tag)){
          continue;
        }
        return tags.includes(tag);
      }
    }
  

  filterAttachmentsFunction = (attachments , data) => {

    if(!data?.attachments?.length) return false;

    for(const fileExtension of attachments){

        
        for(const attachment of data.attachments){
          if(!fileExtension.includes(attachment.filename.split('.').pop())){
            continue;
          }
          return fileExtension.includes(attachment.filename.split('.').pop());
        }
      }
  }

  filterEventsByTagsOrAttachements = (filterItems) => {
    let advancedFilterFn = {};
    let filterTagArray = [];
    let fileExtensions = [];

    const IMAGE_FILE_TYPES = ['Image', 'Image_optional'];
    const DOC_FILE_TYPES = ['Document', 'Ppt', 'Compress', 'Database', 'Email', 'Spreadsheet'];
    const VIDEO_FILE_TYPES = ['Video', 'Audio', 'Media'];

    for (let advancedItem of filterItems) {
      if (advancedItem.filterType === "Tag" && advancedItem.data.length > 0) {
        for(const item of advancedItem.data){
          if(item.isChecked == true){
            filterTagArray = filterTagArray.concat(item.category_name);
          }
        }
      } else if(advancedItem.filterType=== "Attachments" && advancedItem.data.length > 0) {
        for(const attachementType of advancedItem.data){
          if(attachementType.category_name === 'Images' &&  attachementType.isChecked == true){
            fileExtensions.push(getFileExtensions(IMAGE_FILE_TYPES));
          } else if(attachementType.category_name === 'Documents' && attachementType.isChecked == true){
            fileExtensions.push(getFileExtensions(DOC_FILE_TYPES));
          } else if(attachementType.category_name === 'Videos' && attachementType.isChecked == true){
            fileExtensions.push(getFileExtensions(VIDEO_FILE_TYPES));
          }
        }
      }
    }
    

      advancedFilterFn = (data) => {
        if(fileExtensions.length > 0 && !filterTagArray.length) {
          return this.filterAttachmentsFunction(fileExtensions, data)
        
        } else if(filterTagArray.length > 0 && !fileExtensions.length ) {
          return this.filterTagsFunction(filterTagArray, data)
          
        } else if(fileExtensions.length > 0 && filterTagArray.length){
            return this.filterAttachmentsFunction(fileExtensions, data) && this.filterTagsFunction(filterTagArray, data)
        }
      }
    this.setAdvancedFilterToState(advancedFilterFn);


  }


  setAdvancedFilterToState = (advancedFilterFn) => {

    this.setState(state => {
      const events = {};
      for (const [year, items] of Object.entries(state.events)) {
        events[year] = items.slice().filter(advancedFilterFn);
      }
      return { events, filterQueue: state.filterQueue?.slice(1)};
    }, this.removeEmptyItemsFromEventsList);
  }

  filterEventsByCategoryType = (categoryFilterItems) => {
      let categoryArray = [];

      if(!_.isEqual(categoryFilterItems, {})){
        for(const categoryItem of categoryFilterItems) {
         categoryArray = categoryArray.concat(categoryItem.value);
        }
      }

      const filterCategoryFn = ((data) => {
        if(data.categoryType === 'MEDICATIONS') {
          for(const categoryFilterItem of categoryFilterItems) {
            if(categoryFilterItem.categoryName === 'Meds/Supplements' &&
                categoryFilterItem.subCategoryList.length === 0) {
                  return categoryArray.includes(data.categoryType);
            } else if(categoryFilterItem.categoryName === 'Meds/Supplements' &&
              categoryFilterItem.subCategoryList.length > 0) {
              for(const medication of data.medications){
                if(!categoryFilterItem.subCategoryList.includes(medication.name)) {
                  continue;
                }
                return categoryFilterItem.subCategoryList.includes(medication.name);
              }
            }
          }
        } else if (data.categoryType === 'ATTACHMENTS') {
          return categoryArray.includes(data.categoryType);
        } else if (data.categoryType === 'CUSTOM_NOTES') {
          return categoryArray.includes(data.categoryType);
        }
      });

      this.setCategoryFiltersToState(filterCategoryFn);
  }

  setCategoryFiltersToState = (filterCategoryFn) => {

    this.setState(state => {
      const events = {};
      for (const [year, items] of Object.entries(state.events)) {
        events[year] = items.slice().filter(filterCategoryFn);
      }
      return { events, filterQueue: state.filterQueue?.slice(1) };
    }, this.removeEmptyItemsFromEventsList);
  }

  filterEventsByDates = (filterItems) => {
    const {setInnitialDateRange} = this.props;
    const filterDateFn = (data) => (
      moment(data.startDate).isSameOrAfter(filterItems.fromDate) &&
      moment(data.startDate).isSameOrBefore(filterItems.toDate));
    this.setDateFiltersToState(filterDateFn);
    const dateObject = {'firstDate': filterItems.fromDate, 'lastDate': filterItems.toDate};
    setInnitialDateRange(dateObject);
  }

  setDateFiltersToState = (filterDateFn) => {

    this.setState(state => {
      const events = {};
      for (const [year, items] of Object.entries(state.events)) {
        events[year] = items.slice().filter(filterDateFn);
      }
      return { events, filterQueue: state.filterQueue?.slice(1) };
    }, this.removeEmptyItemsFromEventsList);
  }

  sortAllEvents = (sortType) => {

    if (sortType === 0) {
      const sortFn = (a, b) => a.startDate.localeCompare(b.startDate);
      this.onCreateSortObject(sortFn);
    } else if (sortType === 1) {
      const sortFn = (a, b) => b.startDate.localeCompare(a.startDate);
      this.onCreateSortObject(sortFn);
    }
  }

  onCreateSortObject = (sortFn) => {

    this.setState(state => {
      const events = {};
      for (const [year, items] of Object.entries(state.events)) {
        events[year] = items.slice().sort(sortFn);
      }
      return { events, filterQueue: state.filterQueue?.slice(1) };
    }, () => {
      this.setDateRange();
      this.removeEmptyItemsFromEventsList();
    });
  }

  removeEmptyItemsFromEventsList = () => {
    this.setState(state => {
      const events = {};
      for (const [key, value] of Object.entries(state.events)) {
        if(value.length > 0){
          events[key] = value
        }
      }

      return { events, filtering: state.filterQueue.length > 0, filterDone: true };
     
    });
  }

  handleOpenHealthJourneyList = () => {
    this.setState({isFiltersAvailable: false});
  }

  filterPipeline = () => {

    const {saveHealthJourneyCategoryObject, saveHealthJourneyTagsObject, selectedAdvancedFilterItems} = this.props;
    // the values are getting from redux (mobile application) and if data available those are setting to the local state.
    if(!_.isEmpty(saveHealthJourneyTagsObject.data))this.setState({advancedFilterObj: saveHealthJourneyTagsObject.data});
    if(!_.isEmpty(saveHealthJourneyCategoryObject.data))this.setState({allCategoriesList: saveHealthJourneyCategoryObject.data});

    const { fetchEvents, journalUserId } = this.props
    setTimeout(() => {
      fetchEvents(journalUserId, () => {
        this.pipelineComponents();
      });
    }, 500);
  }

  pipelineComponents = async () => {
    this.handleOpenHealthJourneyList();
    this.sortAllEvents(this.state.sortFilterValue);

    const FUNCTIONS = ['filterEventsByTagsOrAttachements', 'filterEventsByCategoryType', 'filterEventsByDates', 'sortAllEvents'];
    const pipeLineStackArray = [];
    
    this.state.selectedDropdown.forEach((item, index) => {
      pipeLineStackArray.push(FUNCTIONS[item]);
    });
    await new Promise(r => this.setState(prevState => ({filterQueue: prevState.filterQueue.length > 0 ? prevState.filterQueue : pipeLineStackArray, filtering: this.state.selectedDropdown.length > 0}), r));

    for (const pipeLineItem of pipeLineStackArray) {
      this.setState({isFiltersAvailable: true});
      switch(pipeLineItem) {

        case 'filterEventsByTagsOrAttachements' : 
          if(!_.isEmpty(this.state.advancedFilterObj)) this.filterEventsByTagsOrAttachements(this.state.advancedFilterObj);
          continue;

        case 'filterEventsByCategoryType' : 
          if(!_.isEqual(this.state.allCategoriesList, {}) && !_.isNull(this.state.allCategoriesList)) this.filterEventsByCategoryType(this.state.allCategoriesList);
          continue;

        case 'filterEventsByDates': 
          if(!_.isEqual(this.state.dateFilterObj, {})) this.filterEventsByDates(this.state.dateFilterObj);
          continue;

        case 'sortAllEvents': 
          this.sortAllEvents(this.state.sortFilterValue);
          continue;

        default:
          this.sortAllEvents(this.state.sortFilterValue);
      }
    }
    // this.removeEmptyItemsFromEventsList();
  }

  setOpenFilters = flag => {
    const {openFilters} = this.state;
    // EventRegister.emitEvent("unfocusTags", true);
    unFocus()
    if (!openFilters && !flag) return;
    this.setState({openFilters: flag});
  }

  toggleDate = ()=>{
    this.setState({
      isDateSelected:true
    })
  }
  clearAllFilterHandler = ()=>{
    const { 
      resetFilteringData, 
      resetFilteringObjectData, 
      resetHealthJourneyEvents,
      setHealthJourneyCategoryObject,
    } = this.props;
      resetFilteringData();
      resetFilteringObjectData();
      resetHealthJourneyEvents();
      setHealthJourneyCategoryObject({})
  }
  render(){
      const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
      const {eventInProgress, fetching, events, dispalyConfirmBox, deleteConfirmBox, deleteWait, attachUploading, attachments, artifactId, eventId, dob, mounted, filtering, sortFilterValue, openFilters} = this.state;
      const {navigation, route, eventData, windowWidth, journalUserId, activeBubbleId, setActiveBubble, setEventData, commonErrorHandler, onUpload, cancelUploadAttachmentProcess, treatments, userSymptoms, userDefinedMetrics, systemDefinedMetrics, updateSystemDefinedSymptoms, userSubscriptions, newTherapie, isNew, drugAdministrationsDigits, updateTherapie, resetNewTherapie, onDeleteTherapie, editTherapie, createUserSymptom, onDeleteSymptoms, journalEntries, onSelectSymptom, onSelectTherapie, fetchHealthJourneyTagsList, emailVerified} = this.props;
      let isInitHashChanged = this.initHashValue && this.initHashValue !== this.getHashValue();
      return (
      <View style={styles.pageBodyWrapper} onStartShouldSetResponder={() => this.setOpenFilters(false)}>
        <Header
            showFilers={false}
            showBack={true}
            showClearFilters={false}
            showNotify={true}
            hideCaregiverAccess={true}
            handleOnPressBackButton={eventInProgress ? this.onPressCancel : this.handleOnPressBackButton}
            index={HEALTH_JOURNEY.index}
            navigation={navigation}
            route={route}
        />
        <SpaceBar />
          {Boolean(isInitHashChanged) &&
          <MenuOverlayContainer onPressOpen={this.onPressCancel} />
        }
        <ScrollView ref={this.scrollViewRef} contentContainerStyle={{paddingBottom: (activeBubbleId && 300) || undefined, flexGrow: 1, minHeight: 500}}>
          <View style={styles.gridSection}>
            <PageContainer
              smScreenFull={true}
            >
              <View style={[stylesRes.contentSection, stylesRes.layoutPdFixed]}>
                <HealthJourneyHeader
                  dob={dob}
                  hasEvents = {_.isEmpty(events)}
                  selectedAllCategoriesItems={this.state.allCategoriesList}
                  setOpenFilters={this.setOpenFilters}
                  openFilters={openFilters}
                  onPressFilter={() => this.onPressFilter()}
                  isDateSelected={this.state.isDateSelected}
                  toggleDate={this.toggleDate}/>
                  {this.state.isFiltersAvailable && (windowWidth > TAB_BREAk_POINT_START) ?
                  <ClearAllFilter clearAllFilterHandler={this.clearAllFilterHandler} windowWidth={windowWidth}/>
                      : null
                  }
                <View style={{ zIndex: -9, paddingBottom:100}}  pointerEvents={openFilters ? "none" : "auto"}>
                {eventInProgress && (
                  <View style={{zIndex: 9}}>
                    <HealthJourneyAddEvent
                      onPressCancel={this.onPressCancel}
                      onPressAdd={this.onPressAdd}
                      eventData={eventData}
                      windowWidth={windowWidth}
                      journalUserId={journalUserId}
                      dob={dob}
                      setStartDate = {(date)=>this.setState({startDate:date})}
                      setEndDate = {(date)=>this.setState({endDate:date})}
                      updateEventData={data => this.setState(prevState => ({eventObj: {...prevState.eventObj, ...data}}))}
                      setIsStartDaySelected={(value) => this.setState({isStartDaySelected: value})}
                      setIsEndDaySelected={(value) => this.setState({isEndDaySelected: value})}
                      activeBubbleId={activeBubbleId}
                      setActiveBubble={setActiveBubble}
                      dispalyConfirmBox={dispalyConfirmBox}
                      updateDispalyConfirmBox={() => this.setState({dispalyConfirmBox: false})}
                      closeAddEventBox={() => this.closeAddEventBox()}
                      navigation={navigation}
                      route={route}
                      setEventData={setEventData}
                      commonErrorHandler={commonErrorHandler}
                      initHashValue={this.initHashValue}
                      isInitHashChanged={isInitHashChanged}
                      userListItems={eventData?.type === constants.eventTypes.MEDICATIONS
                        ? treatments
                        : eventData?.type === constants.eventTypes.SYMPTOMS
                          ? userSymptoms
                          : eventData?.type === constants.eventTypes.HEATHDATA
                            ? userDefinedMetrics
                            : []
                      }
                      systemDefinedMetrics={eventData?.type === constants.eventTypes.HEATHDATA && systemDefinedMetrics}
                      updateSystemDefinedSymptoms={(callback) => updateSystemDefinedSymptoms((result, data) => callback(result, data))}
                      userSubscriptions={userSubscriptions}
                      newTherapie={newTherapie}
                      isNew={isNew}
                      drugAdministrationsDigits={drugAdministrationsDigits}
                      updateTherapie={updateTherapie}
                      resetNewTherapie={resetNewTherapie}
                      onDeleteTherapie={onDeleteTherapie}
                      editTherapie={editTherapie}
                      createUserSymptom={createUserSymptom}
                      onDeleteSymptoms={onDeleteSymptoms}
                      journalEntries={journalEntries}
                      onSelectSymptom={onSelectSymptom}
                      onSelectTherapie={onSelectTherapie}
                      fetchHealthJourneyTagsList={(key, serviceType, callback) => fetchHealthJourneyTagsList(key, serviceType, callback)}
                    />
                  </View>
                )}
                <View>
                  {(fetching && _.isEmpty(events)) || filtering ? (
                    <ActivityIndicator />
                  ) : (
                    <HealthJourneyList
                      data={events}
                      updateLayoutDimentions={this.updateLayoutDimentions}
                      updateYearLayoutDimentions={this.updateYearLayoutDimentions}
                      onPressViewItem={this.onPressViewItem}
                      editEvent={this.onPressEditItem}
                      deleteEvent={this.onPressDeleteItem}
                      disbleOperations={!_.isEmpty(eventData)}
                      sortFilterValue={sortFilterValue}
                      isFiltersAvailable={this.state.isFiltersAvailable}
                      handleOpenHealthJourneyList={this.handleOpenHealthJourneyList}
                    />
                  )}
                  {(!fetching && _.isEmpty(events) && mounted && !filtering) && 
                        <NoResultFound isFiltersAvailable={this.state.isFiltersAvailable} />
                  }
                </View>
              </View>
            </View>
            </PageContainer>
          </View>
        </ScrollView>

        {/* health Journery select category section */}
        <AddEventMenu
          menuItems={[
            {title: constants.UTILITY_MED_AND_SUPPLIMENT, onPress: () => this.onPressCategoryItem(constants.eventTypes.MEDICATIONS)},
            /* Hide for now as this is not included in MVP */
            // {title: constants.UTILITY_SYMPTOM, onPress: () => {}}, //this.onPressCategoryItem(constants.eventTypes.SYMPTOMS)},
            // {title: constants.UTILITY_HEALTH_DATA, onPress: () => {}}, //this.onPressCategoryItem(constants.eventTypes.HEATHDATA)},
            {title: constants.UTILITY_DOCUMENT_AND_IMAGES, onPress: () => this.onPressCategoryItem(constants.eventTypes.ATTACHMENTS)},
            {title: constants.UTILITY_CUSTOM_NOTE, onPress: () => this.onPressCategoryItem(constants.eventTypes.CUSTOM_NOTES)},
          ]}
          isDisabled={Boolean(Object.keys(eventData).length > 0) || !Boolean(emailVerified)}
        />
        {deleteConfirmBox && (
          <SavingConfirmation
            visibleModal={deleteConfirmBox}
            alertBody={constants.DELETE_CONFIRM_BODY}
            alertTitle={constants.DELETE_CONFIRM_TITLE}
            btnTextOne={constants.DELETE_CONFIRM_BTN_CANCEL}
            btnTextTwo={constants.DELETE_CONFIRM_BTN_DELETE}
            handleCancel={() => this.setState({deleteConfirmBox: false})}
            handleConfirm={() => {
              this.handleOnDeleteEvent();
            }}
            confirmWait={deleteWait}
            onBackdropPress={() => {
              this.setState({deleteConfirmBox: false});
            }}
            disabled={deleteWait}
            idFromHealthJourney
          />
        )}
        {attachUploading && (
          <MobileProgress
            artifactId={artifactId}
            attachments={attachments}
            onUpload={onUpload}
            eventId={eventId}
            exit={this.exit}
            journalUserId={journalUserId}
            cancelUploadAttachmentProcess={cancelUploadAttachmentProcess}
          />
        )}
      </View>
    );
  }
};

const mapStateToProps = state => {
  return {
    windowWidth: state.uistate.windowWidth,
    eventData: state.uistate.eventData,
    openedYearAndId: state.uistate.openedYearAndId,
    journalUserId: state.uistate.journalUserId,
    fetchAllHealthJourneyEvents: state.healthJourney.fetchAllHealthJourneyEvents,
    activeBubbleId: state.uistate.activeBubbleId,
    treatments: state.routines.treatments,
    userSymptoms: state.routines.symptomsDigest
      ? state.routines.symptomsDigest.userSymptoms
      : [],
    userDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.userDefinedMetrics
      : [],
    systemDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.systemDefinedMetrics
      : [],
    userSubscriptions: state.routines.userSubscription,
    newTherapie: state.therapie.newTherapie,
    isNew: state.therapie.isNew,
    drugAdministrationsDigits: state.routines.drugAdministrationsDigits,
    journalEntries: state.routines.journalEntries,
    user: state.routines.user,
    saveHealthJourneyTagsObject: state.healthJourney.saveHealthJourneyTagsObject,
    saveHealthJourneyCategoryObject: state.healthJourney.saveHealthJourneyCategoryObject,
    setSelectedAdvancedFilterItems: state.healthJourneyFiltering.setSelectedAdvancedFilterItems,
    sortType: state.healthJourneyFiltering.sortType,
    setSelectedDateObject: state.healthJourneyFiltering.setSelectedDateObject,
    setSelectedCategoryObject: state.healthJourneyFiltering.setSelectedCategoryObject,
    selectedDropdownItemNumber: state.healthJourneyFiltering.selectedDropdownItemNumber,
    setSelectDateOption: state.healthJourneyFiltering.setSelectDateOption,
    emailVerified: state.auth.emailVerified,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    setEventData: data => dispatch(actions.setEventData(data)),
    setOpenedYearAndId: data => dispatch(actions.setOpenedYearAndId(data)),
    onSelectSymptom: (selectedSymptom, callback) =>
      dispatch(actions.onSelectSymptom(selectedSymptom, callback)),
    fetchEvents: (journalUserId, callback) =>
      dispatch(actions.fetchAllHealthJourneyEvents(journalUserId, callback)),
    saveAndUpdateHealthJourneyEvent: (journalUserId, data, callback) =>
      dispatch(actions.saveAndUpdateHealthJourneyEvent(journalUserId, data, callback)),
    deleteHealthJourneyEvent: (journalUserId, id, callback) =>
      dispatch(actions.deleteHealthJourneyEvent(journalUserId, id, callback)),
    showSuccessPopup: (id, graph, data) => dispatch(actions.showSuccessPopup(id, graph, data)),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    setActiveBubble: id => dispatch(actions.setActiveBubble(id)),
    setCurrentEventId: id => dispatch(actions.setCurrentEventId(id)),
    onSaveFilesToAttach: files => dispatch(actions.saveFilesToAttach(files)),
    setAttachmentUploadStatus: status => dispatch(actions.setAttachmentUploadStatus(status)),
    setAttachmentArtifactId: id => dispatch(actions.setAttachmentArtifactId(id)),
    setAttachmentFullSize: size => dispatch(actions.setAttachmentFullSize(size)),
    showUploadProgress: () => dispatch(actions.showUploadProgress()),
    updateSystemDefinedSymptoms: (callback) => dispatch(actions.updateSystemDefinedSymptoms(callback)),
    onUpload: (
      artifactId,
      formData,
      callBack,
      reload,
      journalUserId,
      fileSize,
      uploadConfig,
      cancelToken,
      uniqueId,
      currentEventId,
    ) =>
      dispatch(
        actions.uploadAttachmen(
          artifactId,
          formData,
          callBack,
          reload,
          journalUserId,
          fileSize,
          uploadConfig,
          cancelToken,
          uniqueId,
          currentEventId,
        ),
      ),
    cancelUploadAttachmentProcess: (artifactId, journalUserId, callback, uniqueId) => 
      dispatch(actions.cancelUploadAttachmentProcess(artifactId, journalUserId, callback, uniqueId)),
    updateTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.updateTherapie(addNewTherapie, callBack, journalUserId)),
    resetNewTherapie: () => dispatch(actions.resetNewTherapie()),
    onDeleteTherapie: (id, callBack, journalUserId) =>
      dispatch(actions.deleteTherapie(id, callBack, journalUserId)),
    editTherapie: (treatment, callback) =>
      dispatch(actions.editTherapie(treatment, callback)),
    createUserSymptom: (userSymptom, callback, journalUserId) =>
      dispatch(actions.createUserSymptom(userSymptom, callback, journalUserId)),
    onDeleteSymptoms: (id, userSymptoms, callback, journalUserId) =>
      dispatch(actions.deleteUserSymptom(id, userSymptoms, callback, journalUserId)),
    onSelectTherapie: (SelectedTherapie, callBack) =>
      dispatch(actions.selectTherapie(SelectedTherapie, callBack)),
    setInnitialDateRange: (dateRage) => 
      dispatch(actions.setInnitialDateRange(dateRage)),
    fetchHealthJourneyTagsList: (filterKey, serviceType, callback) =>
      dispatch(actions.fetchHealthJourneyTagsList(filterKey, serviceType, callback)),
    clearAllAdvancedFilteres: () =>
      dispatch(actions.clearAllAdvancedFilteres()),
    resetFilteringData: () => dispatch(actions.resetFilteringData()),
    resetHealthJourneyEvents: () => dispatch(actions.resetHealthJourneyEvents()),
    resetFilteringObjectData: () => dispatch(actions.resetFilteringObjectData()),
    setHealthJourneyCategoryObject: (categoriesObj) =>
    dispatch(actions.setHealthJourneyCategoryObject(categoriesObj)),
    clearSelectedTags: () =>
      dispatch(actions.clearSelectedTags()),
    resetFilterComponents: (isDisabled) =>
      dispatch(actions.resetFilterComponents(isDisabled)),

    removeDropDownItemNumber: (itemNumber) => 
      dispatch(actions.removeDropDownItemNumber(itemNumber)),
    clearSelectCategories: () => 
      dispatch(actions.clearSelectCategories()),
    clearSelectedDateObject: () => 
      dispatch(actions.clearSelectedDateObject()),
    setSortType: (sortType) =>
      dispatch(actions.setSortType(sortType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthJourneyScreen);