import React from 'react';
import { View, Platform, Text } from 'react-native';
import { connect } from 'react-redux';
import * as RNLocalize from 'react-native-localize';
import Modal from '../Modal/modal';
import { JournalEntryFilter } from '../../components/Filter';
import MessageOverlay from '../../components/MessageRibbon/messageOverlay';
import {
  BreadCrumbSection,
  UserSection,
  BackCancelSection,
  SwitchProfileSection,
  AddCaregiverSection,
  NotifySection,
  FilerClearFilterSection,
  CaregiverHeader,
  CroperSection
} from './Items';
import NotificationInfo from '../TimeZone/NotificationInfo';
import AppUpdateModal from '../ConfirmationBox/appUpdateModal';
import HeaderCommon from './headerCommon';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import {
  PROFILE,
  OTHER,
  CAREGIVERS,
  HELP,
} from '../../navigation/path';
import * as actions from '../../store/actions/index';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { isHaveTimeZoneConflict } from '../../util/commonUiLogic';
import { IOS, TAB_BREAk_POINT_START } from '../../util/platformWindowConfig';
import * as updateSelectedProfile from "../../store/actions/updateSelectedProfile";
import { WEB } from '../../util/platformWindowConfig';

let anchorEl;

class Header extends HeaderCommon {

  componentDidMount() {
    const {navigation, onMenuItemPress, index, timeZone, timeZoneWarning, journalUserId} = this.props;
    navigation.addListener('focus', () => {
      onMenuItemPress(index);
      Platform.OS !== WEB && this.getProfileImageAndBackHandler();
      if (!Boolean(journalUserId) && !Boolean(timeZoneWarning) && isHaveTimeZoneConflict(timeZone)) {
        this.setState({timeZoneWarning: true});
      }
    }); 
  }

  handleBackButtonClick() {
    if (this.props.handleOnPressBackButton) {
      this.props.handleOnPressBackButton();
      return true;
    }
    return false;
  }

  imageLoadingError() {
    this.setState({imageLoading: false});
  }

  filterClickHandler = () => {
    switch (this.props.type) {
      case 'journal':
        this.setState({
          visibleFilter: true,
          filterType: 'journal',
        });
        break;
      default:
        this.setState({
          visibleFilter: true,
          filterType: 'appointment',
        });
        break;
    }
    if (this.props.filterClickHandler) {
      this.props.filterClickHandler();
    }
  };

  handleOnPressJournalFilter = filter => {
    if (this.props.handleFilter) {
      this.props.handleFilter(filter);
    }

    this.setState({
      visibleFilter: false,
      showClearFilters: true,
      imagePath: require('../../assets/icons/header-filter-on-active.png'),
    });
  };

  changeJournalUser = grantingUser => {
    const {navigation, sharedAccessDigestProcessed, graphChecked, changeJournalUser} = this.props;

    changeJournalUser(grantingUser, navigation, sharedAccessDigestProcessed, graphChecked, 'Header > SwitchProfile > Caregiver');

    if (Boolean(Platform.OS !== WEB)) {
      this.clearPushNotifications();
    }
  };

  setUpOwnJournal = () => {
    const {journalUserId, navigation, sharedAccessDigestProcessed, graphChecked, setUpOwnJournal} = this.props;

    setUpOwnJournal(journalUserId, navigation, sharedAccessDigestProcessed, graphChecked, 'Header > SwitchProfile > My');

    if (Boolean(Platform.OS !== WEB))
    this.clearPushNotifications();
  };

  redirectProfile = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: PROFILE.path }],
    });
    pendoTrackEvent('Header > Profile');
  };

  onPressCancel = () => {
    const {setSeenTimeZoneWarning} = this.props;
    this.setState({timeZoneWarning: false}, () => setSeenTimeZoneWarning(true))
  }

  updateTimeZone = () => {
    const {updateTimeZone, updateProfile, user, showSuccessPopup, setSeenTimeZoneWarning, journalUserId} = this.props;
    let timeZone =  RNLocalize.getTimeZone();
    this.setState({timeZoneUpdating: true});
    const data = {
      timeZone
    }
    updateTimeZone(
      timeZone,
      succes => {
        if (succes) {
          updateProfile({
            user: {
              ...user,
              timezone: timeZone
            }
          })
          showSuccessPopup(17, null, data)
          this.setState({timeZoneWarning: false}, () => setSeenTimeZoneWarning(true))
        }
        this.setState({timeZoneUpdating: false});
      },
      journalUserId
    )
  }

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {
      visibleFilter,
      filterType,
      imageLoading,
      uername,
      hasNotification,
      showAddCareiver,
      accountType,
      timeZoneWarning,
      timeZoneUpdating,
      customHeading
    } = this.state;
    const {navigation, windowWidth, emailVerified, route, user, timeZoneWarning: timeZoneWarinigProps} = this.props;
    let tabDevice = false;
    if (Boolean(Platform.OS !== WEB) && Boolean(windowWidth > TAB_BREAk_POINT_START)) {
      tabDevice = true;
    }
    return (
      <>
        <View style={stylesRes.headerSectionWrapper}>
          {!Boolean(this.props.journalUserId) && Boolean(timeZoneWarning) && !Boolean(timeZoneWarinigProps) && (
            <NotificationInfo
              updateTimeZone={this.updateTimeZone}
              onPressCancel={this.onPressCancel}
              timeZoneUpdating={timeZoneUpdating}
            />
          )}
          {Boolean(this.props.journalUserId) && (
            <CaregiverHeader journalUserName={uername} />
          )}
          <View style={[stylesRes.headerSectionTop]}>
            <View style={styles.headerSection}>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View
                    nativeID={'header'}
                    style={[
                      styles.headerContent,
                      styles.headerBg,
                      styles.lightGreyBorder,
                      Boolean(Platform.OS === WEB) && styles.lightGreyBorderLeft
                    ]}>
                    <View style={[styles.headerLeft, styles.headerLeftWrapper]}>
                      <View style={styles.headerLeftDetails}>
                        {Boolean(this.state.isHome) &&
                          Boolean(this.props.breadCrumb) &&
                          (Boolean(tabDevice) || Boolean(Platform.OS === WEB)) && (
                            <BreadCrumbSection
                              home={this.state.isHome}
                              breadCrumb={this.props.breadCrumb}
                            />
                          )}
                        {Boolean(this.state.isHome) && (!Boolean(tabDevice) || Boolean(Platform.OS === WEB)) && (
                          <UserSection
                            redirectProfile={this.redirectProfile}
                            source={this.state.uri}
                            onError={this.imageLoadingError.bind(this)}
                            imageLoading={imageLoading}
                            firstName={user.firstName}
                            lastName={user.lastName}
                          />
                        )}
                        {Boolean(this.state.showBack) && (
                          <BackCancelSection
                            handleOnPressButton={
                              this.props.handleOnPressBackButton
                            }
                            nativeId={'headerCancel'}
                          />
                        )}
                        {Boolean(this.state.showCancel) && (
                          <BackCancelSection
                            handleOnPressButton={
                              this.props.handleOnPressCancelButton
                            }
                            isCancel={true}
                            nativeId={'headerBack'}
                          />
                        )}
                      </View>
                    </View>

                    <View
                      nativeID={'headerCaregivers'}
                      style={[
                        styles.headerCenter,
                        styles.headerCentetBtnWrapper,
                      ]}>
                      {!Boolean(this.state.hideCaregiverAccess) && (
                        <View style={styles.headerCenterDetails}>
                          {this.state.sharedAccessGivenToMe &&
                            this.state.sharedAccessGivenToMe.length > 0 && (
                              <SwitchProfileSection
                                onSwitchProffile={(e) => {
                                  if (Boolean(Platform.OS === WEB) && !Boolean(this.state.showCaregiverList)) {
                                    e.preventDefault();
                                    anchorEl = e.currentTarget;
                                  } else {
                                    anchorEl = null;
                                  }
                                  this.setState({
                                    showCaregiverList: !this.state
                                      .showCaregiverList,
                                  })
                                }}
                                showCaregiverList={this.state.showCaregiverList}
                                handleClose={() => {
                                  anchorEl = null;
                                  this.setState({showCaregiverList: false});
                                }}
                                changeJournalUser={this.changeJournalUser}
                                sharedAccessGivenToMe={
                                  this.state.sharedAccessGivenToMe
                                }
                                sharedAccessUserSummaries={
                                  this.props.sharedAccessDigestProcessed
                                    ?.sharedAccessUserSummaries
                                }
                                sharedAccessDigestProcessed={
                                  this.props.sharedAccessDigestProcessed
                                }
                                setUpOwnJournal={this.setUpOwnJournal}
                                myDetail={this.state.myDetail}
                                anchorEl={anchorEl}
                                accountType={accountType}
                                navigation={this.props.navigation}
                                showAdd={
                                  this.props.journalUserId ? false : true
                                }
                                disableAddCaregiverNavigation={
                                  this.state.disableAddCaregiverNavigation
                                }
                                journalUserId={this.props.journalUserId}
                                showAddCareiver={showAddCareiver}
                              />
                            )}

                          {Boolean(showAddCareiver) &&
                            this.state.sharedAccessGivenToMe &&
                            this.state.sharedAccessGivenToMe.length === 0 && (
                              <AddCaregiverSection
                                redirectCaregiverAccess={() => {
                                  this.props.navigation.reset({
                                    index: 0,
                                    routes: [{ name: CAREGIVERS.inviteCaregiverPath }],
                                  });
                                  pendoTrackEvent('Header > AddCaregiver');
                                }}
                                accountType={accountType}
                              />
                            )}
                        </View>
                      )}
                      {Boolean(customHeading) && (
                        <View style={styles.headerCenterDetails}>
                          <Text style={this.props.boldheading && styles.textPrimeBold}>{customHeading}</Text>
                        </View>
                      )}
                    </View>

                    <View style={[styles.headerRight, styles.headerRightWrapper]}>
                      <View style={styles.headerRightDetails}>
                        {Boolean(this.state.showClearFilters) && (
                          <FilerClearFilterSection
                            filterClickHandler={() => {
                              this.props.clearFiltersHandler();
                              this.setState({
                                showFilers: true,
                                showClearFilters: false,
                                imagePath: require('../../assets/icons/header-filter-on.png'),
                              });
                            }}
                            source={require('../../assets/icons/header-filter-off.png')}
                          />
                        )}
                        {Boolean(this.state.showFilers) && (
                          <FilerClearFilterSection
                            source={this.state.imagePath}
                            filterClickHandler={this.filterClickHandler}
                          />
                        )}

                        {Boolean(this.state.showNotify) && (
                          <NotifySection
                            onPressNotification={() => {
                              this.handleOnPressNotificationButton(true);
                              pendoTrackEvent('Header > Notifications');
                            }}
                            // disableNotification={this.state.disableNotification}
                            hasNotification={hasNotification}
                            notifCount={this.state.notifCount}
                            navigation={navigation}
                          />
                        )}
                        {Boolean(Platform.OS === IOS) && Boolean(this.state.showCropper) && (
                          <CroperSection
                            handleOnPressCropButton={() => {
                              this.props.handleOnPressCropButton()
                            }}
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <AppUpdateModal
            visibility={this.state.appUpdatePopupClose}
            hidePopup={this.closeAppUpdatePopup}
            onBackdropPress={this.closeAppUpdatePopup}
            notNowUpdate={this.notNowUpdate}
            showNotNow={this.state.showNotNow}
            updateNow={this.updateNow}
            showClose={this.state.showClose}
          />

          <Modal
            visible={visibleFilter}
            onRequestClose={() => {
              this.setState({visibleFilter: false, filterType: null});
            }}
            onBackdropPress={() => {
              this.setState({visibleFilter: false, filterType: null});
            }}
            defaultStyle={[styles.customPopUpStyle, styles.topSpForHeader]}>
            {filterType === 'journal' && (
              <JournalEntryFilter
                handleOnPressFilter={this.handleOnPressJournalFilter}
              />
            )}
          </Modal>
        </View>
        {!Boolean(emailVerified) &&
          route.name !== HELP.path &&
          route.name !== OTHER.notificationPath && (
            <MessageOverlay />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    sharedAccessDigest: state.routines.sharedAccessDigest,
    sharedAccessDigestProcessed: state.routines.sharedAccessDigestProcessed,
    journalUserName: state.uistate.journalUserName,
    user: state.routines.user,
    journalUserId: state.uistate.journalUserId,
    notification: state.uistate.notification,
    userSubscription: state.routines.userSubscription,
    emailVerified: state.auth.emailVerified,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    timeZoneWarning: state.uistate.timeZoneWarning,
    timeZone: state.routines?.user?.timezone,
    graphChecked: state.uistate.graphChecked,
    uniqueId: state.uistate.uniqueId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeJournalUser: (grantingUser, navigation, sharedAccessDigestProcessed, graphChecked, trackEventTag) =>
      dispatch(updateSelectedProfile.changeJournalUser(grantingUser, navigation, sharedAccessDigestProcessed, graphChecked, trackEventTag)),
    setUpOwnJournal: (prevJournalUserId, navigation, sharedAccessDigestProcessed, graphChecked, trackEventTag) =>
      dispatch(updateSelectedProfile.setUpOwnJournal(prevJournalUserId, navigation, sharedAccessDigestProcessed, graphChecked, trackEventTag)),
    onMenuItemPress: index => dispatch(actions.selectMenuItem(index)),
    setSeenNotification: () => dispatch(actions.setSeenNotification()),
    getAppUpdate: (data, callBack) =>
      dispatch(actions.getAppUpdate(data, callBack)),
    appNotNowUpdate: (data, callBack) =>
      dispatch(actions.appNotNowUpdate(data, callBack)),
    setSeenTimeZoneWarning: (seenTimeZoneWarning) => dispatch(actions.setSeenTimeZoneWarning(seenTimeZoneWarning)),
    updateTimeZone: (timeZone, callBack) =>
      dispatch(actions.updateTimeZone(timeZone, callBack)),
    updateProfile: profile =>
      dispatch(actions.updatePro(profile)),
    showSuccessPopup: (id, graphId, deviceData) => dispatch(actions.showSuccessPopup(id, graphId, deviceData)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
