import React from "react";
import { ScrollView } from "react-native";
import styles from "../../Css";
const keyboardAwareScrollViewHoc = React.forwardRef((props, ref) => {
  return (
    <React.Fragment>
      {Boolean(props.scrollViewWebNone) ? (
      <div style={{height: props.scrollHeight, overflowY: 'auto'}} id="scrollableDiv" 
      className={
        props.scrollTransparent 
        ? "transparentScroll"
        : "commonScroll"
      }
      >
          {props.children}
      </div>
      ) : (
        <ScrollView
          style={[
            styles.scrollViewArea,
            props.pageLightBg ? styles.secLightBlueBg : styles.pageComBg,
          ]}
          ref={ref}
          onScroll={props.onScroll}
          onContentSizeChange={props.onContentSizeChange}
          scrollEventThrottle={props.scrollEventThrottle}
          contentContainerStyle={props.contentContainerStyle}
          onLayout={props.onLayout}
        >
          {props.children}
        </ScrollView>
      )}
    </React.Fragment>
  );
});
export default keyboardAwareScrollViewHoc;
