export const JOIN_FOR_FREE = 'Join for Free?';
export const SIGN_UP = 'Sign Up';
export const FORGOT_PASSWORD = 'Forgot Password?';
export const PASSWORD = 'Password';
export const LOGIN = 'Login';
export const STAR = '*';
export const EMAIL_ADDRESS = 'Email Address';
export const EMAIL_INCORRECT =
  "Whoops! Looks like the email you entered isn't correct";
export const LETS_GET_STARTED = 'Let’s get started ';
export const SUBMIT = 'Submit';
