import React from "react";
import { Image, View, Text, Platform } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import { WEB } from "../../util/platformWindowConfig";
import StoreTile from "./store/storeTile";


const stylesRes = stylesResponsive.getProcessedStyles().styles;
const management = (props) => {
  return (
    <View style={[styles.boxWrapperArea, styles.colMgLtRt]}>
      <View style={stylesRes.boxWrapperViewManagement}>
        <View style={styles.gridRow}>
          <View style={styles.gridColFull}>
            <View style={styles.collageWrapper}>
              <View style={styles.collageRow}>
                <View style={styles.collageCol}>
                  <View style={styles.collageBox}>
                    <View style={styles.collageContent}>
                      <View
                        style={[styles.collageImgView, styles.flexJcCtAiCt]}
                      >
                        <Image
                          style={styles.communityTileIcon}
                          source={require("../../assets/routine/community.png")}
                          resizeMode={"contain"}
                        />
                      </View>

                      <View style={styles.collageTitle}>
                        <Text
                          style={[styles.textTitleBold, styles.textThickGrey]}
                          allowFontScaling={false}
                        >
                          Join Our Community
                        </Text>
                      </View>
                      <View style={styles.collageDesc}>
                        <Text style={[styles.textPrime, styles.textTertiary]} allowFontScaling={false}>
                          Find people like you, anywhere in the world.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.collageColSp} />
                <View style={styles.collageCol}>
                  <View style={styles.collageBox}>
                    <View style={styles.collageContent}>
                      <View
                        style={[styles.collageImgView, styles.flexJcCtAiCt]}
                      >
                        <Image
                          style={styles.stayIcon}
                          source={require("../../assets/routine/stay.png")}
                          resizeMode={"contain"}
                        />
                      </View>

                      <View style={styles.collageTitle}>
                        <Text
                          style={[styles.textTitleBold, styles.textThickGrey]}
                          allowFontScaling={false}
                        >
                          Stay connected
                        </Text>
                      </View>
                      <View style={styles.collageDesc}>
                        <Text style={[styles.textPrime, styles.textTertiary]} allowFontScaling={false}>
                          Keep your careteam and caregivers up to date.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.collageRowSp} />

              <View style={styles.collageRow}>
                <View style={styles.collageCol}>
                  <View style={styles.collageBox}>
                    <View style={styles.collageContent}>
                      <View
                        style={[styles.collageImgView, styles.flexJcCtAiCt]}
                      >
                        <Image
                          style={styles.healthDataIcon}
                          source={require("../../assets/routine/routine_1_stayontrack.png")}
                          resizeMode={"contain"}
                        />
                      </View>

                      <View style={styles.collageTitle}>
                        <Text
                          style={[styles.textTitleBold, styles.textThickGrey]}
                          allowFontScaling={false}
                        >
                          Track your Health Data
                        </Text>
                      </View>
                      <View style={styles.collageDesc}>
                        <Text style={[styles.textPrime, styles.textTertiary]} allowFontScaling={false}>
                          Keep track of your lab results, symptoms, medications
                          or supplements and activities.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.collageColSp} />

                <View style={styles.collageCol}>
                  <View style={styles.collageBox}>
                    <View style={styles.collageContent}>
                      <View
                        style={[styles.collageImgView, styles.flexJcCtAiCt]}
                      >
                        <Image
                          style={styles.customGraphIcon}
                          source={require("../../assets/routine/routine_2_trackstats.png")}
                          resizeMode={"contain"}
                        />
                      </View>

                      <View style={styles.collageTitle}>
                        <Text
                          style={[styles.textTitleBold, styles.textThickGrey]}
                          allowFontScaling={false}
                        >
                          Create custom Graphs
                        </Text>
                      </View>
                      <View style={styles.collageDesc}>
                        <Text style={[styles.textPrime, styles.textTertiary]} allowFontScaling={false}>
                          See trends in your symptoms with different therapies.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.collageRowSp} />
              {Platform.OS === WEB && <StoreTile />}

              {/* Privacy Policy Section Commented for CR ZI-1700*/}
              {/* <View style={styles.collageRowSp} />
              <View style={styles.collageRow}>
                <View style={styles.collageCol}>
                  <View style={styles.collageBox}>
                    <View style={[styles.collageContent, styles.flexRow]}>
                      <View style={styles.collageImgSmView}>
                        <Image
                          style={styles.privacyMatterIcon}
                          source={require("../../assets/routine/privacy.png")}
                          resizeMode={"contain"}
                        />
                      </View>

                      <View style={styles.collageDescWrapper}>
                        <View style={styles.collageTitle}>
                          <Text
                            style={[styles.textTitleBold, styles.textThickGrey]}
                          >
                            Privacy Matters
                          </Text>
                        </View>
                        <View style={[styles.collageDesc, styles.flexRow]}>
                          <Text style={[styles.textPrime, styles.textTertiary]}>
                            Your privacy is our priority.{" "}
                            <TouchableOpacity
                              onPress={props.ttoggleLearnHowPrivacyPolicy}
                            >
                              <Text
                                style={[
                                  styles.textPrime,
                                  styles.textOrange,
                                  styles.textUnderline,
                                ]}
                              >
                                Learn How
                              </Text>
                            </TouchableOpacity>
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View> */}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default (management);
