import * as actionTypes from './actionTypes';
import * as axiosCommon from '../../axios/axios-common';
import * as url from './urls';
import {logout, fetchUserAttachments, commonErrorHandler} from './index';

export const selectContactSuccess = selectedContact => {
  return {
    type: actionTypes.SELECT_CONTACT,
    selectedContact: selectedContact,
  };
};

export const selectedContact = (selectedContact, callBack) => {
  return dispatch => {
    dispatch(selectContactSuccess(selectedContact));
    dispatch(fetchUserAttachments(selectedContact.artifactId));
    callBack();
  };
};

export const deleteContact = (contactId, contacts, callBack, journalUserId) => {
  let requestUrl = url.DELETE_CONTACT + '?contactId=' + contactId;
  if (journalUserId) {
    requestUrl += '&journalUserId=' + journalUserId;
  }
  return dispatch => {
    dispatch(setDeleteContactInProgress(true));
    axiosCommon.axiosDELETE(requestUrl, res => {
      if (res.type === 'SUCCESS') {
        dispatch(deleteContactSuccess(contactId, contacts));
        dispatch(setDeleteContactInProgress(false));
        callBack();
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; deleteContact");
          dispatch(logout());
        } else {
          //dispatch(deleteContactFailed(res.errorDto.data.error));
          dispatch(commonErrorHandler(res.errorDto.data.error));
          dispatch(setDeleteContactInProgress(false));
        }
      }
    });
  };
};

export const deleteContactSuccess = contactId => {
  return {
    type: actionTypes.DELETE_CONTACT_SUCCESS,
    contactId: contactId,
  };
};

export const deleteContactFailed = errorMsg => {
  return {
    type: actionTypes.DELETE_CONTACT_FAIL,
    errorDescription: errorMsg,
  };
};

export const setDeleteContactInProgress = inProgress => {
  return {
    type: actionTypes.DELETE_CONTACT_IN_PROGRESS,
    deleteContactInProgress: inProgress,
  }
}
// update contact

export const updateContact = (
  updateContact,
  contacts,
  callBack,
  journalUserId,
  fetchAtttachments = true,
) => {
  let requestUrl = url.UPDATE_CONTACT;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          if (updateContact.addNewContact.contact.id === -1) {
            dispatch(
              addContactSuccess(
                res.responseDto.data.contact,
                true,
                updateContact.imagePath,
              ),
            );
            dispatch(addContact(res.responseDto.data, contacts));
            if (fetchAtttachments) {
              dispatch(
                fetchUserAttachments(res.responseDto.data.contact.artifactId),
              );
            }
            callBack(true, res.responseDto.data.contact);
          } else {
            dispatch(updateContactSuccess(res.responseDto.data));
            if (fetchAtttachments) {
              dispatch(
                fetchUserAttachments(res.responseDto.data.contact.artifactId),
              );
            }
            dispatch(updateContacts(res.responseDto.data, contacts));
            callBack(true, res.responseDto.data.contact);
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateContact");
            dispatch(logout());
          } else if (res.errorDto !== 'undefined') {
            //   dispatch(updateContactFail(res.errorDto.data.error));
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      updateContact.addNewContact,
    );
  };
};

export const updateContactSuccess = data => {
  return {
    type: actionTypes.UPDATE_CONTACT_SUCCESS,
    selectedContact: data.contact,
  };
};

export const updateContacts = data => {
  return {
    type: actionTypes.UPDATE_CONTACT,
    contact: data.contact,
  };
};

export const updateContactFail = errorMsg => {
  return {
    type: actionTypes.UPDATE_CONTACT_FAIL,
    errorDescription: errorMsg,
  };
};

// Create new contact

export const createContact = (
  addNewContact,
  contacts,
  callBack,
  journalUserId,
) => {
  let requestUrl = url.ADD_CONTACT;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(addContactSuccess(res.responseDto.data.contact, true));
          //   dispatch(addContact(res.responseDto.data, contacts));
          dispatch(
            fetchUserAttachments(res.responseDto.data.contact.artifactId),
          );
          callBack(true);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; createContact");
            dispatch(logout());
          } else if (res.errorDto !== 'undefined') {
            // dispatch(addContactFailed(res.errorDto.data.error));
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false);
          }
        }
      },
      addNewContact,
    );
  };
};

export const addContactSuccess = (data, isNew, imagePath) => {
  return {
    type: actionTypes.ADD_CONTACT_SUCCESS,
    newContact: imagePath ? {data, imagePath} : {data},
    isNew: isNew,
  };
};

export const addContact = data => {
  return {
    type: actionTypes.ADD_CONTACT,
    contact: data.contact,
  };
};

export const addContactFailed = errorMsg => {
  return {
    type: actionTypes.ADD_CONTACT_FAIL,
    errorDescription: errorMsg,
  };
};

export const editContact = (contact, callBack) => {
  return dispatch => {
    dispatch(addContactSuccess(contact, false));
    callBack();
  };
};

export const resetNewContact = () => {
  return dispatch => {
    dispatch(addContactSuccess(null, false));
  };
};