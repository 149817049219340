import React from "react";
import { View, TouchableOpacity, Text, Image, Platform } from "react-native";
import styles from "../../Css";
import * as FontScaling from '../../components/Text/fontScaling';
import { WEB } from "../../util/platformWindowConfig";


const plusButtonCom = (props) => {
  return (
    <View
      style={[
        styles.actionBtnWrapper,
        props.btnPosition === "left"
          ? styles.flexAiFs
          : props.btnPosition === "right"
            ? styles.flexAiFe
            : styles.flexAiCt,
      ]}
    >
      <View style={[styles.actionBtn]}>
        <TouchableOpacity
          nativeID={props.nativeId}
          onPress={props.touchableOnPress}
          style={[styles.btnActionCom, styles.bgWhite, 
            Platform.OS === WEB ? styles.submitBtnShadow : styles.simpleBtnShadow
          ]}
        >
          <View style={[styles.btnSingle, styles.btnSingleCent]}>
            <View style={styles.iconViewExSm}>
              <Image
                style={styles.plusExSm}
                source={require("../../assets/icons/plus-sm.png")}
                resizeMode={"contain"}
              />
            </View>
            <Text style={[styles.textPrimeBold, styles.textOrange]} allowFontScaling = {FontScaling.allowFontScaleFalse}>
              {props.btnText}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default (plusButtonCom);
