import React, { Component } from "react";
import { connect } from "react-redux";
import ProfileSection from "../ProfileSection";
import { View } from "react-native";
import stylesResponsive from "../../../CssResponsive";
import styles from "../../../Css";
import MenuItems from "../MenuItems";
import TabMoreMenuItem from "../TabMoreMenuItem";
class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const { navigation, tabMenuIconView, tabDevice } = this.props;
    return (
      <View style={[stylesRes.sideBarViewFull, stylesRes.menuStatus]} dataSet={{media: ids.menuStatus}}>
        <View
          style={[
            tabMenuIconView
            ? stylesRes.sideBarIconContainer
            : stylesRes.sideBarContainer,
            stylesRes.sidebarArea,
            { height: "100%" },
          ]}
        >
          <View style={[stylesRes.sideBarView]}>
            <View style={[styles.sideBarContentView]}>
              {Boolean(tabDevice) && <TabMoreMenuItem />}
              <ProfileSection navigation={navigation} tabMenuIconView={tabMenuIconView} />
              <MenuItems navigation={navigation} tabMenuIconView={tabMenuIconView} />
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
