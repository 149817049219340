import React from 'react';
import { View, Text, Platform } from 'react-native';
import styles from "../../Css";
import { WEB } from '../../util/platformWindowConfig';


const TakenImagesCount = ({ count }) => (
  <View style={styles.gridRow}>
    {Platform.OS === WEB && 
      <Text style={styles.textTitleBold} allowFontScaling={false}>Count</Text>
    }
    <Text style={styles.textPrime} allowFontScaling={false}>
      {Platform.OS === WEB 
        ? `${count} of ${count}` 
        : `${count} / ${count}`
      }
    </Text>
  </View>
);

export default TakenImagesCount;
