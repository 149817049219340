import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import {View, Keyboard} from 'react-native';
import styles from '../../../Css';
import * as FieldDetails from '../../TextInput/fieldDetails';
import AutoCompleteFrame from '../../AutoComplete/AutoCompleteFrame';
import { chartTypes } from '../../../Constant/index';
import {sortAlphabeticalOrder} from '../../../util/commonUiLogic';
class StepOneAddCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.conditionName ? props.conditionName : '',
      selectedConditions: this.getSelectedConditions(props.conditions),
      placeholderCondition: props.conditionCount === 0 ? true : false,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      selectedConditions: this.getSelectedConditions(props.conditions),
      placeholderCondition: props.conditionCount === 0 ? true : false,
    });
  }

  getSelectedConditions = conditions => {
    const obj = {};
    if (conditions && conditions.length > 0) {
      conditions.forEach(item => (obj[item.name?.toLowerCase()] = item.name?.toLowerCase()));
    }

    if (this.props.userConditions && this.props.userConditions.length > 0) {
      this.props.userConditions.forEach(item => (obj[item.name?.toLowerCase()] = item.name?.toLowerCase()));
    }

    return obj;
  };

  onChangeTextName = currentInput => {
    this.props.onUpdateConditionName(currentInput);
    this.setState({name: currentInput});
  };

  onItemPress = selection => {
    Keyboard.dismiss();
    let selectedValue = selection.data || selection;

    let conditions = [];
    let filteredItems = [];
    if (this.props.conditions) {
      conditions = [...this.props.conditions];
      filteredItems = conditions.filter(
        res => res.condition.name?.toLowerCase() === selectedValue.name?.toLowerCase(),
      );
    }
    if (filteredItems.length === 0) {
      const name = selection.name;
      const array = [];
      const conditionsStages = selectedValue?.conditionsStages || selectedValue?.conditionStages;
      if (conditionsStages && conditionsStages.length >0) {
        conditionsStages.forEach((res, index) => {
          array.push({label: res.stage, value: index});
        });
      }

      conditions.push({
        condition: selectedValue,
        stages: array,
        stage:
        conditionsStages && conditionsStages.length > 0
            ? conditionsStages.stage
            : null,
        status: 'NONE',
        id: -1,
        name: name,
        conditionId: selectedValue.id,
        year: null,
      });
      this.props.updateCondition(conditions, () => {
        this.setState({
          name: '',
        });
        this.props.updateInActive(false);

        if (conditions.length) {
          //auto add symptoms that are related to conditions
          this.props.getConditionSymptomsList(
            {
              conditionIdList: conditions
                .filter(c => c.conditionId)
                .map(cond => cond.conditionId),
            },
            (success, data) => {
              try {
                if (success) {
                  if (this.props.systemDefinedSymptoms) {
                    if (data.length) {
                      let mappedSymptoms = [];
                      data.forEach(item => {
                        let mappedSymptom = this.props.systemDefinedSymptoms.find(
                          value => value.id === item.symptom,
                        );
                        if(mappedSymptom) {
                          mappedSymptoms.push(mappedSymptom);
                        }
                      });
                      //appending existing symptoms
                      let symptoms = [...(this.props.symptoms || [])];
                      mappedSymptoms.forEach(sym => {
                        //check whether symptoms already exisits
                        let existingSymptom = symptoms.find(
                          res => res.symptom.name === sym.name,
                        );
                        let existingUserSymptom = this.props.userSymptoms.find(val => 
                          val.name === sym.name
                        )
                        if (!existingSymptom) {
                          let displayProperties = sym?.displayProperties;
                          symptoms.push({
                            symptom: {...sym, addedFromRW: !existingUserSymptom ? true : false},
                            isActive: true,
                            id: -1,
                            name: sym.name,
                            createdOn: null,
                            modifiedOn: null,
                            artifactId: null,
                            displayProperties: {
                              color: displayProperties?.color ? displayProperties?.color : 'rgba(204, 136, 37, 1)',
                              //dashedLine: displayProperties?.dashedLine ? displayProperties?.dashedLine : true,
                              style: displayProperties?.style,
                              scaleMin: displayProperties?.scaleMin,
                              scaleMax: displayProperties?.scaleMax,
                            },
                          });
                        }
                      });
                      sortAlphabeticalOrder(symptoms);
                      this.props.updateSymptom(symptoms, () => {
                        if (symptoms && symptoms.length > 0) {
                          this.props.addedMappedData();
                        }
                      });
                    }
                  }
                }
              } catch (ex) {
                console.log(ex);
              }
            },
          );

          //auto add health data that are related to conditions
          this.props.getConditionMetricsList(
            {
              conditionIdList: conditions
                .filter(c => c.conditionId)
                .map(cond => cond.conditionId),
            },
            (success, data) => {
              try {
                if (success) {
                  if (this.props.systemDefinedMetrics) {
                    if (data.length) {
                      let mappedMetrics = [];
                      data.forEach(item => {
                        let mappedMetric = this.props.systemDefinedMetrics.find(
                          value => value.id === item.metric,
                        );
                        if (mappedMetric) {
                          mappedMetrics.push(mappedMetric);
                        }
                      });
                      //appending existing health data
                      let metrics = [...(this.props.metrics || [])];
                      mappedMetrics.forEach(met => {
                        //check whether health data already exisits
                        let existingMetric = metrics.find(
                          res => res.metric && res.metric.name === met.name,
                        );
                        let existingUserMetric = this.props.userDefinedMetrics.find(val => 
                          val.name === met.name
                        )
                        if (!existingMetric) {
                          metrics.push({
                            metric: {...met, addedFromRW: !existingUserMetric ? true : false},
                            isActive: true,
                            id: -1,
                            name: met.name,
                            createdOn: null,
                            modifiedOn: null,
                            artifactId: null,
                            description: met.description,
                            typeOfMeasurement: met.typeOfMeasurement,
                            metricGroup: met.metricGroup,
                            displayProperties: {
                              color: 'rgba(113, 168, 220, 1)',
                              style: chartTypes.dash,
                              scaleMin: null,
                              scaleMax: null,
                            },
                          });
                        }
                      });
                      sortAlphabeticalOrder(metrics);
                      this.props.updateMetric(metrics, () => {
                        if (metrics && metrics.length > 0) {
                          this.props.addedMappedData();
                        }
                      });
                    }
                  }
                }
              } catch (ex) {
                console.log(ex);
              }
            },
          );
        }
      });
    }
    this.setState({
      name: '',
    });
    this.props.onUpdateConditionName('');
  };

  // onKeyPress = e => {
  //   if (e.nativeEvent.key === "Enter") {
  //     if (this.state.name && this.validateCondition()) {
  //       let conditions = [];
  //       if (this.props.conditions) {
  //         conditions = [...this.props.conditions];
  //       }
  //       conditions.push({
  //         condition: { name: this.state.name },
  //         stage: [],
  //         status: "NONE",
  //         stage: null,
  //         id: -1,
  //         name: this.state.name,
  //         conditionId: null,
  //         year: null
  //       });
  //       this.props.updateCondition(conditions, () => {
  //         this.setState({
  //           name: ""
  //         });
  //         termsArray = [];
  //       });
  //       this.props.onUpdateConditionName("");
  //     }
  //   }
  // };

  onAddNewPress = () => {
    Keyboard.dismiss();
    if (this.state.name && this.validateCondition()) {
      let conditions = [];
      let filteredItems = [];
      if (this.props.conditions) {
        conditions = [...this.props.conditions];
        filteredItems = conditions.filter(
          res => res.condition.name?.toLowerCase() === this.state.name?.toLowerCase(),
        );
      }
      if (filteredItems.length === 0) {
        conditions.push({
          condition: {name: this.state.name},
          //  stage: [],
          status: 'NONE',
          stage: null,
          id: -1,
          name: this.state.name,
          conditionId: null,
          year: null,
        });
        this.props.updateCondition(conditions, () => {
          this.setState({
            name: '',
          });
          this.props.updateInActive(false);
        });
      }
      this.setState({
        name: '',
      });
      this.props.onUpdateConditionName('');
    } else {
      this.setState({
        name: '',
      });
      this.props.onUpdateConditionName('');
    }
  };
  validateCondition = () => {
    let valid = true;
    if (this.props.conditions && this.props.conditions.length !== 0) {
      valid = this.props.conditions.some(
        res => !(res.condition.name === this.state.name),
      );
    }
    return valid;
  };
  clearInputAndTerms = () => {
    this.setState({
      name: '',
    });
  };
  processData = res => {
    let termsArray = [];
    if (res) {
      const suggestionResults = [...res.summaries];
      termsArray = suggestionResults.map(value => {
        return {name: value.name, data: value};
      });
    }

    return termsArray;
  };

  getUserTerms = (conditions, userConditions) => {

    if(conditions?.length && userConditions?.length){
      return  [...new Set(conditions.concat(userConditions))];
    }else if(conditions?.length){
      return conditions;
    } else if(userConditions?.length){
      return userConditions;
    } else {
      return [];
    }


  }

  render() {
    const {updateAutocompleteOpen, conditions, userConditions} = this.props;
    
    return (
      <View style={[styles.fieldRow, styles.zIndexAutoComplete]}>
        <View style={[styles.fieldRowWrapper]}>
          <View style={styles.fieldColStretch}>
            <AutoCompleteFrame
              textFieldWithTitle={true}
              textFieldTitle="Enter Condition"
              remortSearch={true}
              url="/api/conditions/byname?substring="
              processData={this.processData}
              triggerLength={2}
              onChangeText={this.onChangeTextName}
              onItemPress={this.onItemPress}
              currentInput={this.state.name}
              onPressCustom={selection => {
                this.onAddNewPress(selection);
              }}
              customSectionTitle={'Create Custom'}
              showCustomSection={true}
              autoFocus={this.props.autoFocus === false ? false : (!this.state.name || this.state.name === '')}
              selectedValues={this.state.selectedConditions}
              // editable={this.props.data.id === -1 ? true : false}
              fieldBg={FieldDetails.BGWHITE}
              fieldLt={true}
              zoomIcon={true}
              removeIcon={true}
              //requirdStar={'*'}
              placeholder={
                this.state.placeholderCondition
                  ? 'Search medical conditions'
                  : 'Add another medical condition'
              }
              // "ex: Diabetes, Chronic kidney disease etc.,"
              updateAutocompleteOpen={updateAutocompleteOpen}
              userTerms={this.getUserTerms(conditions, userConditions)}
            />
          </View>
        </View>
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    conditions: state.setup.conditions,
    conditionName: state.setup.conditionName,
    userConditions: state.routines.userConditions,
    systemDefinedSymptoms: state.routines.symptomsDigest
      ? state.routines.symptomsDigest.systemDefinedSymptom
      : [],
    symptoms: state.setup.symptoms,
    metrics: state.setup.metrics,
    systemDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.systemDefinedMetrics
      : [],
    userDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.userDefinedMetrics
      : [],
    userSymptoms: state.routines.symptomsDigest
      ? state.routines.symptomsDigest.userSymptoms
      : [],
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateCondition: (addNewCondition, callBack) =>
      dispatch(actions.updateCondition(addNewCondition, callBack)),
    onUpdateConditionName: name => dispatch(actions.updateConditionName(name)),
    getConditionSymptomsList: (conditionIdList, callBack, journalUserId) =>
      dispatch(
        actions.getConditionSymptomsList(
          conditionIdList,
          callBack,
          journalUserId,
        ),
      ),
    updateSymptom: (addNewSymptom, callBack) =>
      dispatch(actions.updateSymptom(addNewSymptom, callBack)),
    getConditionMetricsList: (conditionIdList, callBack, journalUserId) =>
      dispatch(
        actions.getConditionMetricsList(
          conditionIdList,
          callBack,
          journalUserId,
        ),
      ),
    updateMetric: (addNewMetric, callBack) =>
      dispatch(actions.updateMetric(addNewMetric, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepOneAddCondition);
