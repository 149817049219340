import React from 'react';
import {Text, View, Image, TouchableOpacity, ScrollView} from 'react-native';
import styles from '../../Css';
import stylesResponsive from "../../CssResponsive";
import Header from '../../components/Header/header';
import UIText from '../../components/Text/text';
import SpaceBar from '../../components/SpaceBar/SpaceBar';

const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
const NoNetworkScreen = props => {

  const goBack = () => props.navigation.goBack({key:null});

  return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showHome={true}
        showNotify={true}
        handleOnPressHomeButton={() => {}}
        handleOnPressBackButton={() => goBack()}
        navigation={props.navigation}
        route={props.route}
      />
      <SpaceBar />
      <ScrollView
          contentContainerStyle={[
            styles.scrollViewMax,
            styles.flexJcCtAiCt,
            styles.secLightBlueBg,
          ]}
        >
          <View style={[styles.flexJcCtAiCt]}>
            <Image
              source={require("../../assets/no-network/no-wifi.png")}
              style={{ width: 150, height: 150 }}
            />
            <View style={[{ width: "70%" }, styles.mgTopLg]}>
              <Text
                style={[
                  styles.textPrimeBold,
                  styles.textAlignCenter,
                ]}
                allowFontScaling={false}
              >
                No Internet Connection
              </Text>

              <Text
                style={[
                  styles.textPrimeSm,
                  styles.textGrey,
                  styles.textAlignCenter,
                ]}
                allowFontScaling={false}
              >
                Make sure your wifi or mobile data is turned on, then try again
              </Text>
            </View>
            <View style={stylesRes.botExPdSm} dataSet={{media: ids.botExPdSm}}>
              <View style={[styles.btnBlockFull, styles.mgTopLg]}>
                <TouchableOpacity
                  style={[styles.submit, styles.submitBgOrange]}
                  underlayColor="rgba(0, 153, 168, 0.8)"
                  onPress={() => goBack()}
                  disabled={false}
                >
                  <View style={styles.btnSingleView}>
                    <View style={[styles.btnSingle, styles.btnSingleCent]}>
                      <UIText style={styles.submitText} title="Retry" />
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ScrollView>
    </View>
  );
};

export default NoNetworkScreen;
