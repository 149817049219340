import React, { Component } from 'react';
import { connect } from 'react-redux';
import { View, Image, TouchableOpacity } from 'react-native';
import * as actions from '../../store/actions/index';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import ProfileImageUploadPopup from './profileImageUploadPopup';
import UserAvatar from 'react-native-user-avatar';
import { getUrl } from '../../GlobalFunctions/GetImage';
import { getUserAvatarName } from './../../util/commonUiLogic';

class EditProfileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleEditProfilePhotoModal: false,
      profileImage: null,
      tempProfileImage: null,
      imageLoading: true,
      url: null,
      profilePicRemoved: false,
      fileUploadWait: false,
      uploadError: false,
      uploadErrorMesage: "",
      formData: null,
    };
  }

  componentDidMount() {
    const { journalUserId } = this.props;
    let url = '/api/profile/image/byId?';
    getUrl(url, journalUserId, url => {
      this.setState({
        url: url,
      });
    });
  }

  imageLoadingError() {
    this.setState({ imageLoading: false });
  }

  onProfileImageUploadSucess = profileImage => {
    this.setState({ profileImage, tempProfileImage: null, profilePicRemoved: false }, () => {
      this.props.uploadProfileImageSucess();
    });
  };

  handleOnUploadProfileImage = (formData, image, size) => {


    if(size && size > 5000000){
      this.setState({
        uploadError: true,
        uploadErrorMesage: 'Sorry. File exceeds the maximum upload size of 5MB.',
      });

      return;
    }

    // const {formData} = this.state;
    // const image = this.state.tempProfileImage;
    this.setState({ fileUploadWait: true });
    if (this.state.profilePicRemoved) {
      this.props.deleteProfileImage(
        'PROFILE',
        (success, message) => {
          if (success) {
            this.setState(
              {
                visibleEditProfilePhotoModal: false,
                // profilePicRemoved: false,
                imageLoading: false,
                // tempProfileImage: null,
              },
              () =>
                setTimeout(() => {
                  this.onProfileImageUploadSucess(null);
                }, 1000),
            );
          } else {
            this.setState({
              uploadError: true,
              uploadErrorMesage: message,
            });
          }
          this.setState({ fileUploadWait: false });
        },
        this.props.journalUserId,
      );
    } else {
      this.props.onUpload(
        formData,
        (success, message) => {
          if (success) {
            this.setState({
              visibleEditProfilePhotoModal: false,
              // profilePicRemoved: false,
              // tempProfileImage: null,
            }, () => {
              setTimeout(() => {
                this.onProfileImageUploadSucess(image);
              }, 1000);
            });
          } else {
            this.setState({ uploadError: true, uploadErrorMesage: message });
          }
          this.setState({ fileUploadWait: false });
        },
        this.props.journalUserId,
      );
    }
  }

  render() {
    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    const { firstName, lastName } = this.props;
    let nameArray = [];
    const userAvatarName = getUserAvatarName(firstName, lastName);
    nameArray = userAvatarName?.split(' ');

    const userAvatarNameForPopup = nameArray && nameArray.length > 0
      ? nameArray[0].charAt(0) + (nameArray.length > 1 ? nameArray[nameArray.length - 1].charAt(0) : '')
      : null;
    return (
      <View style={styles.gridRow}>
        <View style={styles.gridColFull}>
          <View style={styles.secArea}>
            <View style={[styles.profileBannerSec]}>
              <View style={[styles.profileBannerBg, styles.bgHeight]}>
                <Image
                  style={[styles.profileBannerBgImg, styles.bgHeightProfile]}
                  source={require('../../assets/icons/profile-bg.png')}
                />
              </View>
              <View
                style={[
                  styles.profileBannerContent,
                  styles.bgHeight,
                  styles.flexRowJcCtAiCt,
                ]}>
                <View style={styles.profileBannerTextArea}>
                  <View style={stylesRes.profileCenter} dataSet={{ media: ids.profileCenter }}>
                    <TouchableOpacity
                      onPress={() => {
                        this.setState({ visibleEditProfilePhotoModal: true });
                      }}>
                      <View style={stylesRes.profileCenterImgSec}>
                        {this.state.profileImage ? (
                          <Image
                            style={styles.profileLoginIconLg}
                            source={{ uri: this.state.profileImage }}
                          />
                        ) : this.state.imageLoading && this.state.url ? (
                          <Image
                            style={styles.profileLoginIconLg}
                            //  source={require("../../assets/profile/profile-default.png")}
                            source={this.state.url}
                            onError={this.imageLoadingError.bind(this)}
                          />
                        ) : userAvatarName ? (
                          <UserAvatar
                            size={115}
                            name={userAvatarName.toUpperCase()}
                            textColor={'#FF9E38'}
                            bgColor={'rgb(255, 255, 255)'}
                          />
                        ) : null}
                      </View>
                      <View style={styles.plusSecView}>
                        <Image
                          style={styles.plusExSm}
                          source={require('../../assets/icons/more-white.png')}
                        />
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <ProfileImageUploadPopup
          visibleEditProfilePhotoModal={this.state.visibleEditProfilePhotoModal}
          onRequestClose={() => {
            this.setState({
              visibleEditProfilePhotoModal: false,
              profilePicRemoved: false,
              tempProfileImage: null,
              uploadError: false,
              uploadErrorMesage: "",
            });
          }}
          profileImage={this.state.profileImage}
          onUploadSucess={this.onProfileImageUploadSucess}
          imageLoading={this.state.imageLoading}
          uri={this.state.url}
          userAvatarName={userAvatarNameForPopup}
          onChangeProfileImage={image => this.setState({ tempProfileImage: image, uploadError: false })}
          tempProfileImage={this.state.tempProfileImage}
          removeProfilePic={status => {
            if (status) {
              this.setState({
                tempProfileImage: null,
                profilePicRemoved: status,
                uploadError: false,
                uploadErrorMesage: "",
              })
            } else {
              this.setState({ profilePicRemoved: status })
            }
          }}
          profilePicRemoved={this.state.profilePicRemoved}
          handleOnUploadProfileImage={this.handleOnUploadProfileImage}
          fileUploadWait={this.state.fileUploadWait}
          uploadError={this.state.uploadError}
          uploadErrorMesage={this.state.uploadErrorMesage}
          imageLoadingError={this.imageLoadingError.bind(this)}
          getImageUrl={value => {
            this.setState({ formData: value });
          }}
          cancelCropping={() => {
            this.setState({
              tempProfileImage: null,
              uploadError: false,
              uploadErrorMesage: "",
            })
          }}
        />
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpload: (formData, callBack, journalUserId) =>
      dispatch(actions.uploadProfileImage(formData, callBack, journalUserId)),
    getProfileImage: (userId, callBack) =>
      dispatch(actions.getProfileImage(userId, callBack)),
    uploadProfileImageSucess: () =>
      dispatch(actions.uploadProfileImageSucess()),
    deleteProfileImage: (type, callBack, journalUserId) =>
      dispatch(actions.deleteProfileImage(type, callBack, journalUserId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditProfileHeader);
