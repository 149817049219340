import * as actionTypes from './actionTypes';
import * as axiosCommon from '../../axios/axios-common';
import * as axiosCommonNotif from '../../axios/axios-common-notif';
import * as url from './urls';
import _ from "lodash";
import moment from 'moment';
import {logout, commonErrorHandler} from './index';
import {addNotifcation} from './uistate';
import { actionStatus } from '../../util/commonUiLogic';
import { 
  listTypes,
  visibleType,
  COMMUNITY_CONDITION_KEY,
  COMMUNITY_SYMPTOMS_KEY,
  PERSONAL_DETAILS_KEY,
  LOCATION_DETAILS_KEY,
  COMMUNITY_TREATMENT_KEY
} from '../../screens/PeerToPeer/MainSection/Profile/Constraints';

export const updateShowPeerProfileScreen = (
  showPeerProfileScreen,
  journalUserId,
  callBack,
) => {
  let requestUrl = url.PEER_TO_PEER_SCREEN_STATUS;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(true);
          dispatch(updateShowPeerProfileScreenSuccess(showPeerProfileScreen));
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateShowPeerProfileScreen");
            dispatch(logout());
          } else if (res.errorDto !== 'undefined') {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      {showPeerProfileScreen: showPeerProfileScreen},
    );
  };
};

const updateShowPeerProfileScreenSuccess = showPeerProfileScreen => {
  return {
    type: actionTypes.UPDATE_SHOW_PEER_PROFILE_SCREEN_SUCCESS,
    showPeerProfileScreen: showPeerProfileScreen,
  };
};

export const setPeerToPeerActiveTab = activeTab => {
  return {
    type: actionTypes.SET_PEER_TO_PEER_ACTIVE_TAB,
    activeTab: activeTab,
  };
};

export const getLatestPeers = callBack => {
  return dispatch => {
    axiosCommon.axiosGET(url.GET_LATEST_PEERS, res => {
      if (res.type === 'SUCCESS') {
        callBack(true, res.responseDto.data);
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; getLatestPeers");
          dispatch(logout());
        } else if (res.errorDto !== 'undefined') {
          dispatch(commonErrorHandler(res.errorDto.data.error));
          callBack(false, res.errorDto.data.error);
        }
      }
    });
  };
};

export const updatePeer2PeerProfile = (
  updateProfile,
  callBack,
  journalUserId,
) => {
  let requestUrl = url.UPDATE_PEER_2_PEER_PROFILE;

  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(updatep2pProfileSuccess(res.responseDto.data));
          dispatch(updatep2pPro(res.responseDto.data, null, false));
          callBack(true);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updatePeer2PeerProfile");
            dispatch(logout());
          } else if (res.errorDto !== 'undefined') {
            dispatch(updateProfileFail(res.errorDto.data.error));
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      updateProfile,
    );
  };
};


export const updatePeer2PeerProfileListItems = (
  type,
  updateProfile,
  callBack,
  journalUserId,
) => {

  let requestUrl;
  let key;

  switch(type){
    case listTypes.MEDICAL_CONDITIONS:
      requestUrl = url.UPDATE_PEER_2_PEER_PROFILE_LIST_ITEMS_MEDICAL_CONDITIONS;
      key = COMMUNITY_CONDITION_KEY;
      break;
    case listTypes.TREATMENTS:
      requestUrl = url.UPDATE_PEER_2_PEER_PROFILE_LIST_ITEMS_TREATMENTS;
      key = COMMUNITY_TREATMENT_KEY;
      break;
    case listTypes.SYMPTOMS:
      requestUrl = url.UPDATE_PEER_2_PEER_PROFILE_LIST_ITEMS_SYMPTOMS;
      key = COMMUNITY_SYMPTOMS_KEY;
      break
    case listTypes.PERSONAL_DETAILS:
      requestUrl = url.UPDATE_PEER_2_PEER_PROFILE_LIST_ITEMS_PERSONAL_DETAILS;
      key = PERSONAL_DETAILS_KEY;
      break
    case listTypes.LOCATION_DETAILS:
      requestUrl = url.UPDATE_PEER_2_PEER_PROFILE_LIST_ITEMS_LOCATION_DEATILS;
      key = LOCATION_DETAILS_KEY;
      break
    default:
      break
  }

  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(updatep2pProfileSuccess(res.responseDto.data, key));
          dispatch(updatep2pPro( res.responseDto.data, key));
          callBack(true);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updatePeer2PeerProfileListItems");
            dispatch(logout());
          } else if (res.errorDto !== 'undefined') {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      updateProfile,
    );
  };
};


export const updatep2pProfileSuccess = (data, key) => {
  const p2pData = data;
  
  return (dispatch, state) =>{
    const p2p = state().routines.peer2peer;
    dispatch({
      type: actionTypes.UPDATE_PEER_2_PEER_PROFILE_SUCCESS,
      userUpdated: key ? {...p2p , [key]: p2pData} : {...p2p, ...data},
    });
  };
};

export const updatep2pProfileListItemSuccess = data => {
  return {
    type: actionTypes.UPDATE_PEER_2_PEER_PROFILE_DATA_LIST_SUCCESS,
    userUpdated: data,
  };
};

export const updatep2pPro = (data, key, updateCommunityData = true) => {
  const p2pData = data;
  return (dispatch, state) =>{
    const p2p = state().routines.peer2peer;
    const commiunityDetails = state().peerToPeer.commiunityDetails;

    const profile = key ? {...p2p , [key]: p2pData} : {...p2p, ...data};
    const updatedCommunityDetais = {
      ...commiunityDetails,
      selectedMedicalCondition: profile?.communityProfileCondition
          ? profile?.communityProfileCondition.filter(item => item.visibility === visibleType.PUBLIC)
          : [],
        selectedTreatments:  profile?.communityProfileTreatments
          ? profile?.communityProfileTreatments.filter(item => item.visibility === visibleType.PUBLIC)
          : [],
        selectedSymptoms: profile?.communityProfileSymptoms
          ? profile?.communityProfileSymptoms.filter(item => item.visibility === visibleType.PUBLIC)
          : [],
        selectedPersonalDetails: profile?.personalDetails
          ? profile?.personalDetails.filter(item => item.visibility === visibleType.PUBLIC)
          : [],
        selectedLocationDetails: profile?.locationDetails
          ? profile?.locationDetails.filter(item => item.visibility === visibleType.PUBLIC)
          : [],
        medicalConditionList: profile?.communityProfileCondition || [],
        treatmentList: profile?.communityProfileTreatments || [],
        symptomList: profile?.communityProfileSymptoms || [],
        personalDetailList: profile?.personalDetails || [],
        locationDetails: profile?.locationDetails || [],
    };
    dispatch({
      type: actionTypes.UPDATE_PEER_2_PEER_PROFILE,
      profile,
    });
   if(updateCommunityData) dispatch(setCommiunityProfileDetails(updatedCommunityDetais));
  };
};


export const updateProfileFail = errorMsg => {
  return {
    type: actionTypes.UPDATE_PEER_2_PEER_PROFILE_FAIL,
    errorDescription: errorMsg,
  };
};

export const uploadp2pProfileImage = (formData, callBack) => {
  return dispatch => {
    axiosCommon.axiosImageUploadPUT(
      url.UPLOAD_P2P_PROFILE_IMAGE,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(true);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; uploadp2pProfileImage");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      formData,
    );
  };
};

export const OnSaveChangesP2PProfile = val => {
  return {
    type: actionTypes.UPDATE_USER_CHANGE_P2P_PROFILE,
    val: val,
  };
};

export const setSearchTearms = searchTearms => {
  return {
    type: actionTypes.SET_SEARCH_TEARMS,
    searchTearms: searchTearms,
  };
};

export const handleSearchPeers = (searchObject, callBack) => {
  let requestUrl = url.SEARCH_PEERS;
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(searchPeersSuccess(res.responseDto.data));
          callBack(true, res.responseDto.data);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; handleSearchPeers");
            dispatch(logout());
          } else if (res.errorDto !== 'undefined') {
            dispatch(commonErrorHandler(res.errorDto?.data?.error));
            callBack(false, res.errorDto?.data?.error);
          }
        }
      },
      searchObject,
    );
  };
};
export const searchPeersSuccess = data => {
  return {
    type: actionTypes.SEARCH_PEERS_SUCCESS,
    peersResults: data,
  };
};

export const updatePeerResultsSearchedList = searchedList => {
  return {
    type: actionTypes.UPDATE_PEER_RESULTS_SEARCHED_LIST,
    searchedList: searchedList,
  };
};

export const resetPeerResults = () => {
  return {
    type: actionTypes.RESET_PEER_RESULTS,
  };
};

export const updateSearchedKeyword = searchedKeyword => {
  return {
    type: actionTypes.UPDATE_SEARCHED_KEYWORD,
    searchedKeyword: searchedKeyword,
  };
};

export const sendConnectionRequest = (connectionRequest, callBack) => {
  let requestUrl = url.SEND_CONNECTION_REQUEST;
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(true, res.responseDto.data);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; sendConnectionRequest");
            dispatch(logout());
          } else if (res.errorDto !== 'undefined') {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      connectionRequest,
    );
  };
};

export const updatePeerProfileToPublic = callBack => {
  return dispatch => {
    axiosCommon.axiosGET(url.UPDATE_PEER_PROFILE_TO_PUBLIC, res => {
      if (res.type === 'SUCCESS') {
        dispatch(updatep2pPro(res.responseDto.data, null, false));
        callBack(true);
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; updatePeerProfileToPublic");
          dispatch(logout());
        } else if (res.errorDto !== 'undefined') {
          dispatch(commonErrorHandler(res.errorDto.data.error));
          callBack(false);
        }
      }
    });
  };
};

export const setConnectionRequestMessage = message => {
  return {
    type: actionTypes.SET_CONNECTION_REQUEST_MESSAGE,
    connectionRequestMessage: message,
  };
};

export const updateMyNetwork = myNetwork => {
  return {
    type: actionTypes.UPDATE_PEER_2_PEER_MYNETWORK,
    myNetwork: myNetwork,
  };
};

// export const getMyNetworkList = callBack => {
//   return dispatch => {
//     axiosCommon.axiosGET(url.GET_MY_NETWORK_LIST, res => {
//       if (res.type === 'SUCCESS') {
//         dispatch(updateMyNetwork(res.responseDto.data));
//         callBack(true, res.responseDto.data);
//       } else {
//         if (res.errorDto === 'SESSION_TIME_OUT') {
//           dispatch(logout());
//         } else if (res.errorDto !== 'undefined') {
//           dispatch(commonErrorHandler(res.errorDto.data.error));
//           callBack(false, res.errorDto.data.error);
//         }
//       }
//     });
//   };
// };

export const handleMyNetworkAction = (actionDetailObj, callBack) => {
  let requestUrl = url.PROFILE_ACTION;
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(true, res.responseDto.data);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; handleMyNetworkAction");
            dispatch(logout());
          } else if (res.errorDto !== 'undefined') {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      actionDetailObj,
    );
  };
};

export const updateConnectionRequestCount = count => {
  return {
    type: actionTypes.UPDATE_CONNECTION_REQUEST_COUNT,
    connectionRequestCount: count,
  };
};

export const updateSeenRequests = connectionIdObj => {
  let requestUrl = url.UPDATE_SEEN_REQUESTS;
  return (dispatch, state) => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateSeenRequests");
            dispatch(logout());
          }
        }
      },
      connectionIdObj,
    );

    let myNetwork = state().routines?.myNetwork || {};
    const { received } = myNetwork;
    if (received?.length) {
      const processedConnectionIds = [ ...connectionIdObj?.connectionId || [] ];
      const updatedReceived = received.map((item) => {
        const { connectionId } = item;
        const foundConnection = processedConnectionIds.find((currentConnectionId) => `${connectionId}` ===  `${currentConnectionId}`);
        if (foundConnection) {
          return { ...item, isNotified: true };
        }
        return item;
      });

      myNetwork = { ...myNetwork, received: updatedReceived };
      dispatch(updateMyNetwork(myNetwork));
    }
  };
};

export const getLatestRequestList = (timeStamp, callBack) => {
  return dispatch => {
    axiosCommonNotif.axiosGET(
      url.GET_LATEST_REQUESTS_LIST,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(true, res.responseDto.data);
        }
      },
      {timeStamp: timeStamp},
    );
  };
};
let interval = null;
export const startTaskGetLatestRequestList = timeStamp => {
  let timeStamptemp = timeStamp;
  return (dispatch, state) => {
    interval = setInterval(() => {
      const notifications = [];

      dispatch(
        getLatestRequestList(timeStamptemp, (success, res) => {
          if (success) {
            timeStamptemp = res.timeStamp;
            let bulkDataMyNetwork = state().routines.myNetwork || {};
            let searchedResults = state().peerToPeer?.peersResults?.searchedList || [];
            let haveUpdate = false;
            const findAndReplace = item => {
              if (searchedResults.length === 0) return;
              const foundIdx = searchedResults.findIndex((bulkItem) => bulkItem.connectionId === item.connectionId);
              if (foundIdx === -1) return;
              searchedResults[foundIdx] = {...item};
              haveUpdate = true;
            }
            // Update from the response data connected. Advanced operation for connected data happens below.
            if (res?.connected?.length) {
              const bulkDataMyNetworkConnected = [...bulkDataMyNetwork?.connected || []];
              res.connected.forEach(item => {
                  const foundIndex = bulkDataMyNetworkConnected.findIndex((bulkItem) => bulkItem.connectionId === item.connectionId);
                if (foundIndex > -1) {
                  bulkDataMyNetworkConnected[foundIndex] = { ...item };
                } else {
                  bulkDataMyNetworkConnected.push({ ...item });
                }
                //Add connection request accept notification
                if (item.enableNotification) {
                  notifications.push({
                    type: 'PEER_NOTIFICATION',
                    data: {
                      type: 'PEER_CONNECTION_ACCEPTED',
                      channelId: item.channelId,
                      userName: item.userName
                    },
                    date: new Date(item.connectionModifiedOn),
                    id:
                      'PEER_CONNECTION_ACCEPTED' +
                      item.id +
                      '_' +
                      moment(item.connectionModifiedOn).format('YYYY-MM-DD'),
                  });
                }
              });

              bulkDataMyNetwork = { ...bulkDataMyNetwork, connected: bulkDataMyNetworkConnected };
            }

            // Update from the response data received
            if (res?.received?.length) {
              const bulkDataMyNetworkReceived = [...bulkDataMyNetwork?.received || []];
              res.received.forEach(item => {
                const foundIndex = bulkDataMyNetworkReceived.findIndex((bulkItem) => bulkItem.connectionId === item.connectionId);
                if (foundIndex > -1) {
                  bulkDataMyNetworkReceived[foundIndex] = { ...item };
                } else {
                  bulkDataMyNetworkReceived.push({ ...item });
                }
                //Add connection request notification
                //if (!item?.isNotified) {
                  notifications.push({
                    type: 'PEER_NOTIFICATION',
                    data: {
                      type: 'PEER_CONNECTION_REQUEST',
                      notificationTypeId: item.connectionId,
                      userName: item.userName
                    },
                    date: new Date(item.connectionModifiedOn),
                    id:
                      'PEER_CONNECTION_REQUEST' +
                      item.id +
                      '_' +
                      moment(item.connectionModifiedOn).format('YYYY-MM-DD'),
                  });
                //}
              });

              bulkDataMyNetwork = { ...bulkDataMyNetwork, received: bulkDataMyNetworkReceived };
            }

            // Update from the response data sent
            if (res?.sent?.length) {
              const bulkDataMyNetworkSent = [...bulkDataMyNetwork?.sent || []];
              res.sent.forEach(item => {
                findAndReplace(item)
                const foundIndex = bulkDataMyNetworkSent.findIndex((bulkItem) => bulkItem.connectionId === item.connectionId);
                if (foundIndex > -1) {
                  bulkDataMyNetworkSent[foundIndex] = { ...item };
                } else {
                  bulkDataMyNetworkSent.push({ ...item });
                }
              });

              bulkDataMyNetwork = { ...bulkDataMyNetwork, sent: bulkDataMyNetworkSent };
            }

            // Update from the response data declined
            if (res?.declined?.length) {
              let bulkDataMyNetworkReceived = [...bulkDataMyNetwork?.received || []];
              let bulkDataMyNetworkSent = [...bulkDataMyNetwork?.sent || []];

              res.declined.forEach(item => {
                findAndReplace(item)
                let foundIndexReceived = bulkDataMyNetworkReceived.findIndex((bulkItem) => bulkItem.connectionId === item.connectionId);
                if (foundIndexReceived > -1) {
                  bulkDataMyNetworkReceived = [
                    ...bulkDataMyNetworkReceived.slice(0, foundIndexReceived),
                    ...bulkDataMyNetworkReceived.slice(foundIndexReceived + 1, bulkDataMyNetworkReceived.length)
                  ];
                }

                let foundIndexSent = bulkDataMyNetworkSent.findIndex((bulkItem) => bulkItem.connectionId === item.connectionId);
                if (foundIndexSent > -1) {
                  bulkDataMyNetworkSent = [
                    ...bulkDataMyNetworkSent.slice(0, foundIndexSent),
                    ...bulkDataMyNetworkSent.slice(foundIndexSent + 1, bulkDataMyNetworkSent.length),
                  ];
                }
              });

              bulkDataMyNetwork = {
                ...bulkDataMyNetwork,
                received: bulkDataMyNetworkReceived,
                sent: bulkDataMyNetworkSent,
              };
            }

            // Update from the response data disconnected
            if (res?.disconnected?.length) {
              const bulkDataMyNetworkConnected = res?.disconnected.reduce((accBulkArr, item) => {
                const foundIndex = accBulkArr.findIndex((bulkItem) => bulkItem.connectionId === item.connectionId);
                findAndReplace(item)
                if (foundIndex > -1) {
                  return [
                    ...accBulkArr.slice(0, foundIndex),
                    ...accBulkArr.slice(foundIndex + 1, accBulkArr.length),
                  ];
                }
                return accBulkArr;
              }, [...bulkDataMyNetwork?.connected || []]);

              bulkDataMyNetwork = { ...bulkDataMyNetwork, connected: bulkDataMyNetworkConnected };
            }

            // Update from the response data withdraw
            if (res?.withdraw?.length) {
              let bulkDataMyNetworkSent = [...bulkDataMyNetwork?.sent || []];
              let bulkDataMyNetworkReceived = [...bulkDataMyNetwork?.received || []];

              res.withdraw.forEach(item => {
                findAndReplace(item)
                const foundIndexSent = bulkDataMyNetworkSent.findIndex((bulkItem) => bulkItem.connectionId === item.connectionId);
                if (foundIndexSent > -1) {
                  bulkDataMyNetworkSent = [
                    ...bulkDataMyNetworkSent.slice(0, foundIndexSent),
                    ...bulkDataMyNetworkSent.slice(foundIndexSent + 1, bulkDataMyNetworkSent.length),
                  ];
                }

                const foundIndexReceived =  bulkDataMyNetworkReceived.findIndex((bulkItem) => bulkItem.connectionId === item.connectionId);
                if (foundIndexReceived > -1) {
                  bulkDataMyNetworkReceived = [
                    ...bulkDataMyNetworkReceived.slice(0, foundIndexReceived),
                    ...bulkDataMyNetworkReceived.slice(foundIndexReceived + 1, bulkDataMyNetworkReceived.length),
                  ];
                }
              });

              bulkDataMyNetwork = {
                ...bulkDataMyNetwork,
                sent: bulkDataMyNetworkSent,
                received: bulkDataMyNetworkReceived,
              };
            }

            // Update from the response data connected (some of the operation happend in the upper content)
            if (res?.connected?.length) {
              let bulkDataMyNetworkReceived = [...bulkDataMyNetwork?.received || []];
              let bulkDataMyNetworkSent = [...bulkDataMyNetwork?.sent || []];
              let bulkDataMyNetworkConnected = [...bulkDataMyNetwork?.connected || []];

              res.connected.forEach(item => {
                findAndReplace(item)
                const foundIndexReceived = bulkDataMyNetworkReceived.findIndex((bulkItem) => bulkItem.connectionId === item.connectionId);
                if (foundIndexReceived > -1) {
                  bulkDataMyNetworkReceived = [
                    ...bulkDataMyNetworkReceived.slice(0, foundIndexReceived),
                    ...bulkDataMyNetworkReceived.slice(foundIndexReceived + 1, bulkDataMyNetworkReceived.length),
                  ];
                }

                const foundIndexSent = bulkDataMyNetworkSent.findIndex((bulkItem) => bulkItem.connectionId === item.connectionId);
                if (foundIndexSent > -1) {
                  bulkDataMyNetworkSent = [
                    ...bulkDataMyNetworkSent.slice(0, foundIndexSent),
                    ...bulkDataMyNetworkSent.slice(foundIndexSent + 1, bulkDataMyNetworkSent.length),
                  ];
                }

                const foundIndexConnected = bulkDataMyNetworkConnected.findIndex((bulkItem) => bulkItem.connectionId === item.connectionId);
                if (foundIndexConnected === -1) {
                  bulkDataMyNetworkConnected.push({ ...item });
                }
              });

              bulkDataMyNetwork = {
                ...bulkDataMyNetwork,
                received: bulkDataMyNetworkReceived,
                sent: bulkDataMyNetworkSent,
                connected: bulkDataMyNetworkConnected,
              };
            }

            if (Object.keys(bulkDataMyNetwork).length) {
              dispatch(updateMyNetwork({ ...bulkDataMyNetwork }));
            }
            if (notifications.length > 0) {
              dispatch(addNotifcation(null, notifications));
            }
            if (haveUpdate) {
              dispatch(updateResults(Date.now()));
            }
          }
        }),
      );
    }, 5000);
  };
};

export const clearTaskGetLatestRequestList = () => {
  return () => {
    if (interval) {
      clearInterval(interval);
    }
  };
};

export const getToken = (callBack, userId) => {
  return dispatch => {
    axiosCommon.axiosGET(url.GET_TWILIO_TOKEN + `/${userId}`, res => {
      if (res.type === 'SUCCESS') {
        callBack(true, res.responseDto.data);
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; getToken");
          dispatch(logout());
        } else if (res.errorDto !== 'undefined') {
          dispatch(commonErrorHandler(res.errorDto.data.error));
          callBack(false, res.errorDto.data.error);
        }
      }
    });
  };
};

export const updateChannels = channels => {
  return {
    type: actionTypes.UPDATE_CHANNELS,
    channels: channels,
  };
};

export const updateResults = timeStamp => {
  return {
    type: actionTypes.UPDATE_RESULTS,
    timeStamp: timeStamp,
  };
};

export const updateSingleChannel = (channelSid, data) => {
  return {
    type: actionTypes.UPDATE_SINGLE_CHANNEL,
    channelSid: channelSid,
    data,
  };
};

export const removeSingleChannel = channelSid => {
  return {
    type: actionTypes.REMOVE_SINGLE_CHANNEL,
    channelSid: channelSid,
  };
};

export const setUnSeenConversations = unSeenConvos => {
  return {
    type: actionTypes.SET_UNSEEN_CONVERSATIONS,
    unSeenConvos: unSeenConvos,
  };
};

export const setNewMessageCount = count => {
  return {
    type: actionTypes.UPDATE_NEW_MESSAGE_COUNT,
    newMessageCount: count,
  };
};

export const setSelectedChannel = channel => {
  return {
    type: actionTypes.SELECTED_CHANNEL,
    selectedChannel: channel,
  };
};

export const resetPeerToPeer = () => {
  return {
    type: actionTypes.RESET_PEER_TO_PEER,
  };
};

export const updateBulkMyNetworkDataByStatusAndConnectionId = (status, connectionId, channelId, callBack = () => {}) => {
  return (dispatch, state) => {
    let myNetwork = state().routines.myNetwork || {};
    let isBulkMyNetworkDataUpdated = false;

    // remove this item from bulk received and
    //add this item to bulk connected
    if (status === actionStatus.CONNECTED) {
      const { received, connected } = myNetwork;

      // Remove from received
      let updatedArr = [...received || []];
      const foundIndx = updatedArr.findIndex((item) => item.connectionId === connectionId);
      if (foundIndx > -1) {
        updatedArr = [
          ...updatedArr.slice(0, foundIndx),
          ...updatedArr.slice(foundIndx + 1, updatedArr.length),
        ];
        myNetwork = { ...myNetwork, received: updatedArr };
        isBulkMyNetworkDataUpdated = true;
      }

      // Add to connected
      let updatedArrConnected = [...connected || []];
      const foundIndexConnected = updatedArrConnected.findIndex((item) => item.connectionId === connectionId);
      if (foundIndexConnected === -1 && foundIndx > -1 && received?.[foundIndx]) {
        updatedArrConnected.push({ ...received[foundIndx], channelId });
        myNetwork = { ...myNetwork, connected: updatedArrConnected };
        isBulkMyNetworkDataUpdated = true;
      }
    }

    // remove this item from bulk received
    if (status === actionStatus.DECLINED) {
      const { received } = myNetwork;
      let updatedArr = [...received || []];
      const foundIndx = updatedArr.findIndex((item) => item.connectionId === connectionId);
      if (foundIndx > -1) {
        updatedArr = [
          ...updatedArr.slice(0, foundIndx),
          ...updatedArr.slice(foundIndx + 1, updatedArr.length),
        ];
        myNetwork = { ...myNetwork, received: updatedArr };
        isBulkMyNetworkDataUpdated = true;
      }
    }

    // remove this item from bulk sent
    if (status === actionStatus.WITHDRAW) {
      const { sent } = myNetwork;
      let updatedArr = [...sent || []];
      const foundIndx = updatedArr.findIndex((item) => item.connectionId === connectionId);
      if (foundIndx > -1) {
        updatedArr = [
          ...updatedArr.slice(0, foundIndx),
          ...updatedArr.slice(foundIndx + 1, updatedArr.length),
        ];
        myNetwork = { ...myNetwork, sent: updatedArr };
        isBulkMyNetworkDataUpdated = true;
      }
    }

    // remove this item from bulk connected
    if (status === actionStatus.BLOCK || status === actionStatus.REPORT || status === actionStatus.DISCONNECT) {
      const { connected } = myNetwork;
      let updatedArr = [...connected || []];
      const foundIndx = updatedArr.findIndex((item) => item.connectionId === connectionId);
      if (foundIndx > -1) {
        updatedArr = [
          ...updatedArr.slice(0, foundIndx),
          ...updatedArr.slice(foundIndx + 1, updatedArr.length),
        ];
        myNetwork = { ...myNetwork, connected: updatedArr };
        isBulkMyNetworkDataUpdated = true;
      }
    }

    if (isBulkMyNetworkDataUpdated) {
      dispatch(updateMyNetwork({ ...myNetwork }));
      callBack();
    }
  };
};

export const setChatStatus = status => {
  return {
    type: actionTypes.SET_CHAT_STATUS,
    chatStatus: status,
  };
};

export const setViewMoreShowHideDetails = item => {
  return {
    type: actionTypes.UPDATE_VIEW_MORE_SHOW_HIDE_DETAILS,
    showHideStatusDetails : item,
  }
};
  export const setCommiunityProfileDetails = item => {
    return {
      type: actionTypes.UPDATE_COMMIUNITY_PROFILE_DETAILS,
      commiunityDetails : item,
    }
  }

  export const getPeer2PeerProfileData = (
    callBack,
    journalUserId,
  ) => {
    let requestUrl = url.UPDATE_PEER_2_PEER_PROFILE;
  
    if (journalUserId) {
      requestUrl += '?journalUserId=' + journalUserId;
    }
    return dispatch => {
      axiosCommon.axiosGET(
        requestUrl,
        res => {
          if (res.type === 'SUCCESS') {
            dispatch(updatep2pProfileSuccess(res.responseDto.data));
            dispatch(updatep2pPro(res.responseDto.data));
            if(callBack)callBack(true, res.responseDto.data);
          } else {
            if (res.errorDto === 'SESSION_TIME_OUT') {
              dispatch(logout());
            } else if (res.errorDto !== 'undefined') {
              dispatch(updateProfileFail(res.errorDto.data.error));
              dispatch(commonErrorHandler(res.errorDto.data.error));
              if(callBack)callBack(false, res.errorDto.data.error);
            }
          }
        }
      );
    };
  };