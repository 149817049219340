import React, { Component } from "react";
import { View, Image, Platform, Linking } from "react-native";
import styles from "../../Css";
import * as actions from "../../store/actions/index";
import * as urls from "../../store/actions/urls"
import { connect } from "react-redux";
import Modal from "../../components/Modal/modal";
import PhoneNumbeSuccess from "./phoneNumbeSuccess";
import AccountPasswordSuccess from "./accountPasswordSuccess";
import PasswordResetSuccess from "./ passwordResetSuccess";
import VerificationCodeSuccess from "./verificationCodeSuccess";
import EmailAccountVerifiedSuccess from "./emailAccountVerifiedSuccess";
import ModalLayout from "../ModalLayout/modalLayout";
import CopyToNewRoutineSuccess from "./successCopyToNewBox";
import MedicalConditionSuccess from "./medicalConditionSuccess";
import RoutineModalCommonContent from "./routineModalCommonContent";
import {getAccountType} from "../../util/subscriptionInformation";
import {freeUserMaximumGraphsAllow} from "../../Constant/index";
import {PROFILE, ROUTINES, DASHBOARD} from "../../navigation/path";
import DeviceStatusPopup from "./deviceStatusPopup";
import SuccessBox from "../ConfirmationBox/successBox";
import JoinStudyPopup from "./joinStudyPopup";
import HealthResourcePopup from "./healthResourcePopup";
import TimeZoneUpdateSuccess from "./timeZoneSuccess";
import StopMedication from "../MedicationsOrTherapies/stopMedication";
import PauseMedication from "../MedicationsOrTherapies/pauseMedication";
import ResumeMedication from "../MedicationsOrTherapies/resumeMedication";
import { WEB } from "../../util/platformWindowConfig";


class SucessMessage extends Component {
  constructor(props) {
    const {
      messageId,
      showSuccessMessage,
    } = props;
    super(props);
    
    this.state = {
      messageId: messageId,
      visibleModal: showSuccessMessage,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { showSuccessMessage, messageId, graphId, userSubscriptions, dashboard } = props;

    if (showSuccessMessage && messageId) {
      let displayCrown = false;
      if(messageId === 6 || messageId === 12 || messageId === 10) {
        const accountType = getAccountType(userSubscriptions);
        if (dashboard && accountType === 'regular') {
          let enableGraphCount = 0;
          let enableGraphs = [];
          //enabled graph can edited from save routin or journal
          enableGraphs = dashboard.filter(graph => graph.status === 'ENABLED' && graphId && graph.id !== graphId);
          if (enableGraphs && enableGraphs.length > 0) {
            enableGraphCount = enableGraphs.length; 
            if(enableGraphCount >= freeUserMaximumGraphsAllow) {
              displayCrown = true;
            }
          }
        }
      }

      if(!displayCrown && props.deviceData?.device !== 'HEXOSKIN' && messageId !== 15 && messageId !== 16 && messageId !== 19 && messageId !== 20 && messageId !== 21) {
        setTimeout(() => {
          props.hideSuccessPopup();
        }, 3000);
      }
      return {
        visibleModal: showSuccessMessage,
        messageId: messageId,
        displayCrown: displayCrown,
      };
    } else {
      return {
        visibleModal: false,
        messageId: null,
      };
    }
  }

  redirectUpgradeToPremium = () => {
    this.props.navigation.navigate(PROFILE.profilePremiumAccountPath);
    this.hidePopup();
  };

  hidePopup = (navigation) => {
    this.props.hideSuccessPopup();
    this.setState({
      visibleModal: false,
      messageId: null,
    });
    if (navigation) {
      if (navigation === 'ROUTINES') {
        this.props.navigation.reset({
          routes: [{ name: ROUTINES.path }]
        });
      }
    }
  }

  redirect = () => {
    this.hidePopup();
    Platform.OS === WEB
      ? window.location.href = urls.HEXOSKIN_URL + urls.HEXOSKIN_CLIENT_ID + "/?redirect_uri="+ urls.DEEP_LINK_URL+ urls.REDIRECT_URL + "/HEXOSKIN"
      : this.props.navigation.reset({
        index: 0,
        routes: [{ name: DASHBOARD.path }],
      });
      Linking.openURL(urls.HEXOSKIN_URL + urls.HEXOSKIN_CLIENT_ID + "/?redirect_uri="+ urls.DEEP_LINK_URL+ urls.REDIRECT_URL + "/HEXOSKIN")
  }

  getMessage = () => {
    switch (this.state.messageId) {
      case 1:
        return <PhoneNumbeSuccess />;
      case 2:
        return (
          <AccountPasswordSuccess 
            body={this.props?.deviceData?.body} 
            title={this.props?.deviceData?.title}
          />
        );
      case 3:
        return <PasswordResetSuccess />;
      case 4:
        return <VerificationCodeSuccess />;
      case 5:
        return <EmailAccountVerifiedSuccess />;
      case 6:
        return (
          <RoutineModalCommonContent
            messageTitle={"Your routine setup is complete"}
            displayCrown={this.state.displayCrown}
            touchableOnPress={this.redirectUpgradeToPremium}
          />
        );
      case 7:
        return (
          <RoutineModalCommonContent
            messageTitle={"Entry Saved"}
          />
        );
      case 8:
        return <CopyToNewRoutineSuccess />;
      case 9:
        return ( 
          <RoutineModalCommonContent
            messageTitle={"Entry Saved \n & Routine Updated"}
          />
        );
      case 10:
        return (
          <RoutineModalCommonContent
            messageTitle={"Entry Saved \n & Routine Created"}
            displayCrown={this.state.displayCrown}
            touchableOnPress={this.redirectUpgradeToPremium}
          />
        );
      case 11:
        return <MedicalConditionSuccess />;
      case 12:
        return (
          <RoutineModalCommonContent
            messageTitle={"Your setup is successful"}
            displayCrown={this.state.displayCrown}
            touchableOnPress={this.redirectUpgradeToPremium}
          />
        );
      case 13:
        return (
          <DeviceStatusPopup
            alertTitle={this.props.deviceData.title}
            tickCircle={this.props.deviceData.success}
            exclamationCircle={this.props.deviceData.fail}
            alertBodyTitle={this.props.deviceData.bodyTitle}
            alertBody={this.props.deviceData.body}
            btnText={this.props.deviceData.btnText}
            handleConfirm={() => 
              this.props.deviceData.action === 'REDIRECT' 
                ? this.redirect() 
                : this.props.deviceData.action === 'GOTIT'
                  ? this.hidePopup('ROUTINES')
                  : this.hidePopup()
            }
            closeModal={this.hidePopup}
          />
        );
      case 14:
        return (
          <SuccessBox
            visibleModal={true}
            messageTitle="Success!"
            message="Attachments uploaded"
          />
        );
      case 15:
        return (
          <JoinStudyPopup
            hidePopup={this.hidePopup}
            navigation={this.props.navigation}
          />
        );
      case 16:
        return (
          <HealthResourcePopup
            hidePopup={this.hidePopup}
            navigation={this.props.navigation}
          />
        );
      case 17:
        return (
          <TimeZoneUpdateSuccess
            timeZone={this.props?.deviceData?.timeZone} 
          />
        );
      case 18:
        return (
          <RoutineModalCommonContent
            messageTitle={"Event Saved"}
          />
        );
      case 19:
        return (
          <StopMedication
            visibleModal={this.state.visibleModal}
            onRequestClose={this.hidePopup}
            onStopMedication={this.props.onResumeMedication}
            treatment={this.props?.deviceData?.treatment}
          />
        );
      case 20:
        return (
          <PauseMedication
            visibleModal={this.state.visibleModal}
            onRequestClose={this.hidePopup}
            treatment={this.props?.deviceData?.treatment}
            onPauseMedication={this.props.onPauseMedication}
          />
        );
      case 21:
        return (
          <ResumeMedication
            visibleModal={this.state.visibleModal}
            onRequestClose={this.hidePopup}
            onResumeMedication={this.props.onResumeMedication}
            treatment={this.props?.deviceData?.treatment}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const routineMessageIds = [6, 7, 9, 10, 12, 18];
    let isRotineMessageId = false;
    if(routineMessageIds.includes(this.state.messageId)) {
      isRotineMessageId = true;
    }
    return (
      <Modal visible={this.state.visibleModal}>
        {this.state.messageId && this.state.messageId !== 15 && this.state.messageId !== 16 && this.state.messageId !== 17 && this.state.messageId !== 19 && this.state.messageId !== 20 && this.state.messageId !== 21 ? (
          <ModalLayout
            contPosition={"center"}
            showClose={
              isRotineMessageId && this.state.messageId !== 18 ? true : false
            }
            boxStyle={isRotineMessageId ? true : false}
            hidePopup={this.hidePopup}
          >
            {this.state.messageId !== 13 ?
              <View style={styles.popupContentWrapper}>
                <View style={[styles.popIconView, styles.comMgBot]}>
                  <View style={styles.popMessageIconView}>
                    <Image
                      style={[
                        styles.popMessageIcon,
                        isRotineMessageId
                          ? styles.bgCircleGreen
                          : styles.bgCircleOrange,
                      ]}
                      source={require("../../assets/icons/right.png")}
                    />
                  </View>
                </View>
                {this.state.messageId && this.getMessage()}
              </View>
            :
              this.getMessage()
            }
          </ModalLayout>
        ) : (
          this.state.messageId === 15 || 
          this.state.messageId === 16 ||
          this.state.messageId === 17 ||
          this.state.messageId === 19 ||
          this.state.messageId === 20 ||
          this.state.messageId === 21
            ? this.getMessage() 
            : <View />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messageId: state.uistate.messageId,
    showSuccessMessage: state.uistate.showSuccessMessage,
    graphId: state.uistate.graphId,
    userSubscriptions: state.routines.userSubscription,
    dashboard: state.routines.dashboard,
    deviceData: state.uistate.deviceData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideSuccessPopup: () => dispatch(actions.hideSuccessPopup()),
    onResumeMedication: (selectedTherapie, active, callBack) =>
      dispatch(actions.onResumeMedication(selectedTherapie, active, callBack)),
    onPauseMedication: (selectedTherapie, timeStamp, days, callBack) =>
      dispatch(actions.onPauseMedication(selectedTherapie, timeStamp, days, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SucessMessage);
