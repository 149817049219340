import React from "react";
import { Text, View, Image, ScrollView, TouchableOpacity } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import Modal from "../Modal/modal";

const successBox = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <Modal
      visible={props.visibleModal}
      onRequestClose={props.onRequestClose}
      onBackdropPress={props.onBackdropPress}
      backdropStyle={props.backdropStyle}
      animationNone={props.animationNone}
    >
      <View style={[stylesRes.modalScrollWrapperCom]} dataSet={{media: ids.modalScrollWrapperCom}}>
        <ScrollView style={styles.modalScroller}>
          <View 
            style={[
            styles.popUpBlock, 
            styles.bgWhite, 
            props.roundedCorners ? styles.successboxRoundCorners : styles.noRoundCorners
            ]}>
            {Boolean(props.showClose) && (
              <View style={styles.closeBtnResDel}>
                <TouchableOpacity onPress={props.onRequestClose}>
                  <Image
                    style={styles.premiumIconSm}
                    source={require("../../assets/icons/close-icon-small.png")}
                  />
                </TouchableOpacity>
              </View>
            )}
            <View style={styles.popUpContentArea}>
              <View style={styles.gridSectionPopup}>
                <View style={[styles.popUpContentPd]}>
                  <View style={stylesRes.modalContIn}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.modalMessage]}>
                          <Image
                            style={styles.popMessageIconSm}
                            source={
                              props.isWarning
                                ? require("../../assets/icons/warning.png")
                                : require("../../assets/icons/right.png")
                            }
                          />

                          <View style={[styles.infoBlock, styles.modelContPd]}>
                            {Boolean(props.messageTitle) ? (
                              <View style={styles.infoTitle}>
                                <Text
                                  style={[
                                    styles.textTitleExLg,
                                    styles.textAlignCenter,
                                    props.messageTitleStyle
                                  ]}
                                  allowFontScaling={false}
                                >
                                  {props.messageTitle}
                                </Text>
                              </View>
                             ) : null}

                            <View style={styles.infoTextArea}>
                              <Text
                                style={[
                                  styles.textPrime,
                                  styles.textAlignCenter,
                                  props.messageStyle
                                ]}
                                allowFontScaling={false}
                              >
                                {Boolean(props.number) ? (
                                  <Text style={styles.bold} allowFontScaling={false}>
                                    {props.number}
                                  </Text>
                                ) : null}
                                {props.message}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};
export default (successBox);
