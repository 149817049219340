import React, {Fragment, useState, useEffect} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import MedicationsOrTherapiesItem from '../../components/MedicationsOrTherapies/MedicationsOrTherapiesItem/MedicationsOrTherapiesItem';
import styles from '../../Css';
import { TAB_BREAk_POINT_START } from '../../util/platformWindowConfig';
import {title} from './MedicationActivityCategoryItem';

const MedicationActivityItemCollapse = props => {
  const {
    data = [],
    windowWidth,
    medicationsOrTherapiesSelectHandler,
    renderSearchContents,
    collapseTitle,
    handleEditMedicationActivity,
    searchKey
  } = props;
  const [open, setOpen] = useState(data && data.length > 0 && collapseTitle !== title[2]);
  
  useEffect(() => {
    setOpen(data && data.length > 0 && (collapseTitle !== title[2] || (searchKey !== null && searchKey !== '')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const getIcon = titleText => {
    switch (titleText) {
      case title[0]:
        return require('../../assets/icons/play-btn-app.png');
      case title[1]:
        return require('../../assets/icons/pause-btn-app.png');
      case title[2]:
        return require('../../assets/icons/stop-btn-app.png');
      default:
        return require('../../assets/icons/play-btn-app.png');
    }
  };

  const getIconBackgroundColor = titleText => {
    switch (titleText) {
      case title[0]:
        return styles.darkGreen;
      case title[1]:
        return styles.darkPurple; 
      case title[2]:
        return styles.darkGreyBg; 
      default:
        return styles.darkGreen;
    }
  };


  const handleOpen = () => {
    setOpen(open => !open);
  };

  return (
    <>
      <TouchableOpacity onPress={handleOpen} disabled={!data.length}>
        <View
          style={[
            styles.flexRow,
            styles.flexAiCt,
            styles.flexJcSb,
            styles.itemDividerFull,
            windowWidth > TAB_BREAk_POINT_START
              ? styles.itemDividerPdLtZero
              : styles.itemDividerPd,
          ]}>
          <View  style={[styles.flexRow, styles.flexAiCt]}>
            <View style={[styles.wdBgCircle,styles.imgSp, getIconBackgroundColor(collapseTitle)]}>
              <Image 
                style={styles.getIconImg} 
                source={getIcon(collapseTitle)} />
            </View>

            <Text
              style={[styles.textPrimeBold]}
              allowFontScaling={false}>
              {collapseTitle}
            </Text>
            <Text
              style={[styles.textPrimeMd, styles.resourceBlueTxt, {marginLeft:5}]}
              allowFontScaling={false}>
              {`(${data.length})`}
            </Text>
          </View>
          <View>
            {open ? (
              <Image
                style={[styles.arrowOpen]}
                source={require('../../assets/icons/arrow-down-orange.png')}
              />
            ) : (
              <Image
                style={[styles.arrowClose]}
                source={require('../../assets/icons/arrow-next.png')}
              />
            )}
          </View>
        </View>
      </TouchableOpacity>
      {open && (
        <>
          {data.map(item => {
            return (
              <Fragment key={'MM' + item.id}>
                <MedicationsOrTherapiesItem
                  treatment={item}
                  medicationsOrTherapiesSelectHandler={
                    medicationsOrTherapiesSelectHandler
                  }
                  windowWidth={windowWidth}
                  handleEditMedicationActivity={handleEditMedicationActivity}
                />
                {Boolean(item.containKeyword) && renderSearchContents(item)}
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );
};

export default MedicationActivityItemCollapse;
