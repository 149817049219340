import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import {View, Keyboard} from 'react-native';
import styles from '../../../../Css';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import AutoCompleteSuggestion from '../../AutoCompleteSuggestion';
import {autoSuggestionListType} from '../../../../Constant/index';
import { getUserTerms, getSuggestedTeams } from '../../../../util/commonUiLogic';

class Symptom extends Component {
  constructor(props) {
    super(props);
    const {symptomName, userSymptoms} = props;
    this.state = {
      name: symptomName ? symptomName : '',
      terms: [],
      userTerms: getUserTerms(userSymptoms),
    };
  }

  componentDidMount() {
    this.getSystemDefinedSymptoms();
  }

  getSystemDefinedSymptoms = () => {
    this.props.updateSystemDefinedSymptoms((result, data) => {
      if (result) {
        this.updateTermList(data);
      }
    });
  };

  updateTermList = data => {
    // let termsArray = [];
    // const {userSymptoms} = this.props;
    // const userDefinedSymptomMap = {};
    // userSymptoms.forEach(res => {
    //   userDefinedSymptomMap[res.name] = res.name;
    // });
    // if (data) {
    //   const suggestionResults = [];
    //   data.forEach(res => {
    //     if (!userDefinedSymptomMap[res.name]) {
    //       suggestionResults.push(res);
    //     }
    //   });
    //   termsArray = suggestionResults.map(value => {
    //     return {name: value.name, data: value};
    //   });
    // }
    const {userSymptoms} = this.props;
    let termsArray = getSuggestedTeams(data, userSymptoms);
    this.setState({terms: [...termsArray]});
  };

  // getUserTerms = () => {
  //   let termsArray = [];
  //   const {userSymptoms} = this.props;
  //   if (userSymptoms) {
  //     const suggestionResults = [...userSymptoms];
  //     termsArray = suggestionResults.map(value => {
  //       return {name: value.name, data: value};
  //     });
  //   }
  //   return termsArray;
  // };

  onChangeTextName = currentInput => {
    this.setState({
      name: currentInput,
    });
  };

  onItemPress = (selection, type) => {
    let selectedValue = selection.data;
    const {onPressItem} = this.props;

    this.setState({
      name: '',
    });
    onPressItem(selectedValue, type);
    Keyboard.dismiss();
  };

  onAddNewPress = (selection, type) => {
    if (selection && selection.trim() !== '') {
      const name = selection;

      this.setState({
        name: '',
      });
      const {onPressItem} = this.props;
      onPressItem(name, type);
      Keyboard.dismiss();
    }
  };

  render() {
    const {userTerms, terms} = this.state;
    const {updateAutocompleteOpen, selectedValues} = this.props;
    return (
      <View
        style={[styles.fieldRow, styles.zIndexAutoComplete, styles.noMgBot]}>
        <AutoCompleteSuggestion
          textFieldWithTitle={true}
          textFieldTitle="Enter Symptom"
          // toolTipButton={true}
          // toolTipId={'tool-tip-med'}
          // toolTipContent={
          //   'Track any feeling of illness or physical or mental change'
          // }
          fieldBg={FieldDetails.BGWHITE}
          remortSearch={false}
          //triggerLength={1}
          onChangeText={this.onChangeTextName}
          onItemPress={this.onItemPress}
          currentInput={this.state.name}
          onPressCustom={this.onAddNewPress}
          customSectionTitle={'Create custom'}
          showCustomSection={true}
          autoFocus={true}
          terms={terms}
          userTerms={userTerms}
          updateAutocompleteOpen={updateAutocompleteOpen}
          selectedValues={selectedValues}
          userListTitle={'Select from existing list of symptoms'}
          systemListTitel={'Select from suggested symptoms'}
          disabledScroll={this.props.disabledScroll}
          clearTextOnBlur={true}
          updateTermList={() => this.getSystemDefinedSymptoms()}
          type={autoSuggestionListType.symptom}
          allowFontScaling= {false}
          zoomIcon={true}
          removeIcon={true}
          autoDisplayUserList={true}
          maxLength={250}
        />
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    userSymptoms: state.routines.symptomsDigest
      ? state.routines.symptomsDigest.userSymptoms
      : [],
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateSystemDefinedSymptoms: callback =>
    dispatch(actions.updateSystemDefinedSymptoms(callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Symptom);
