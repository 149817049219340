import React, {Component} from 'react';
import {connect} from 'react-redux';
import Metrics from '../../../components/Settings/metrics/metrics';
import * as actions from '../../../store/actions/index';
import {METRICS, DASHBOARD} from '../../../navigation/path';
import {handleCommonBackNavigation} from '../../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../../util/pendoConfig';
import {sortAlphabeticalOrder} from '../../../util/commonUiLogic';
class MetricsScreen extends Component {
  constructor(props) {
    super(props);
    //let from = null;
    //TO-DO remove previous navigation changes
    // if (this.props.location.state && this.props.location.state.from) {
    //   from = this.props.location.state.from;
    // }
    // if (props.route?.params && props.route?.params.from) {
    //   from = props.route?.params.from;
    // }
    this.state = {
      userDefinedMetrics: this.props.userDefinedMetrics,
      userDefinedMetricUnitOfMeasures: this.props
        .userDefinedMetricUnitOfMeasures,
      loadingWait: true,
      searchKeyword:
        //TO-DO remove previous navigation changes
        // from &&
        // this.props.history.action === 'PUSH' &&
        // from.includes(METRICS.viewMetricPath) &&
        // this.props.metricFilter
        //   ? this.props.metricFilter
        //   : '',
        '',
    };
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      let searchString = null;
      let from = null;
      if (this.props.route?.params && this.props.route?.params.from) {
        from = this.props.route?.params.from;
      }
      if (this.props.metricFilter && from && (
        from.includes(METRICS.viewMetricPath) || 
        from.includes(METRICS.addMetricPath) ||
        from.includes(METRICS.editMetricPath))
      ) {
        searchString = this.props.metricFilter;
      }
      this.searchUserDefinedMetric(searchString);
      setTimeout(() => this.setState({loadingWait: false}), 1500);
      pendoTrackEvent('HealthData@');
    });
    this.props.navigation.addListener('blur', () => {
      this.props.navigation.setParams({from: METRICS.path});
    });
  }

  // UNSAFE_componentWillReceiveProps(props) {
  //   const {userDefinedMetrics, userDefinedMetricUnitOfMeasures} = props;
  //   this.setState({
  //     userDefinedMetrics: userDefinedMetrics,
  //     userDefinedMetricUnitOfMeasures: userDefinedMetricUnitOfMeasures,
  //   });
  // }

  onPressCreateNewMetric = () => {
    pendoTrackEvent('HealthData@ > Add');
    this.props.onSelectMetric(null, () => {
      this.props.navigation.replace(METRICS.addMetricPath);

    });
  };

  onSelectMetric = id => {
    let selectMetric = this.props.userDefinedMetrics.filter(function(metrics) {
      return metrics.id === id;
    });

    if (selectMetric && selectMetric.length > 0) {
      let selectUOM = this.props.userDefinedMetricUnitOfMeasures.filter(
        function(userDefinedMetricUnitOfMeasures) {
          return (
            userDefinedMetricUnitOfMeasures.metricId === selectMetric[0].id
          );
        },
      );
      if (selectUOM && selectUOM.length > 0) {
        let uom = this.props.unitsOfMeasure.filter(function(unit) {
          return unit.id === selectUOM[0].uomId;
        });

        let selectUOMArray = uom[0];
        let selectMetricArray = selectMetric[0];
        let selectedAllMetric = {
          selectMetricArray,
          selectUOMArray,
        };
        this.props.onSelectMetric(selectedAllMetric, () => {
          this.props.navigation.push(METRICS.viewMetricPath);
        });
      } else {
        let selectMetricArray = selectMetric[0];
        let selectedAllMetric = {
          selectMetricArray,
        };
        this.props.onSelectMetric(selectedAllMetric, () => {
          this.props.navigation.push(METRICS.viewMetricPath);
        });
      }
    }
  };

  searchUserDefinedMetric(searchKeyword) {
    if (searchKeyword) {
      const filterMetric = this.props.userDefinedMetrics.filter(
        res =>
          res.name &&
          res.name.toLowerCase().includes(searchKeyword.toLowerCase()),
      );

      this.setState({
        userDefinedMetrics: filterMetric,
        searchKeyword: searchKeyword,
        userDefinedMetricUnitOfMeasures: this.props.userDefinedMetricUnitOfMeasures,
      });
    } else {
      this.setState({
        searchKeyword: '',
        userDefinedMetrics: this.props.userDefinedMetrics,
        userDefinedMetricUnitOfMeasures: this.props.userDefinedMetricUnitOfMeasures,
      });
    }
    this.props.setMetricFilter(searchKeyword);
  }

  render() {
    const {userDefinedMetrics} = this.state;
    const userDefinedMetricsSort = sortAlphabeticalOrder(userDefinedMetrics);

    return (
      <Metrics
        handleOnPressBackButton={() => {
          let from = null;
          if (this.props.route?.params && this.props.route?.params.from) {
            from = this.props.route?.params.from;
          }
          if (
            this.state.searchKeyword !== '' &&
            this.state.searchKeyword !== null
          ) {
            this.searchUserDefinedMetric(null);
          } else if (
            from &&
            (from.includes(METRICS.viewMetricPath) ||
              from.includes(METRICS.addMetricPath) ||
              from.includes(METRICS.editMetricPath))
          ) {
            this.props.navigation.reset({
              index: 0,
              routes: [{ name: DASHBOARD.path }],
            });
          } else {
            handleCommonBackNavigation(this.props.navigation);
          }
        }}
        userDefinedMetrics={userDefinedMetricsSort}
        searchUserDefinedMetric={this.searchUserDefinedMetric.bind(this)}
        userDefinedMetricUnitOfMeasures={
          this.state.userDefinedMetricUnitOfMeasures
        }
        onPressCreateNewMetric={this.onPressCreateNewMetric}
        onSelectMetricList={this.onSelectMetric}
        unitsOfMeasure={
          this.props.unitsOfMeasure ? this.props.unitsOfMeasure : null
        }
        loadingWait={this.state.loadingWait}
        index={METRICS.index}
        navigation={this.props.navigation}
        route={this.props.route}
        searchKeyword={this.state.searchKeyword}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    userDefinedMetrics: state.routines.metricsDigest.userDefinedMetrics,
    userDefinedMetricUnitOfMeasures:
      state.routines.metricsDigest.userDefinedMetricUnitOfMeasures,
    unitsOfMeasure: state.routines.unitsOfMeasureDigest.unitsOfMeasure,
    metricFilter: state.filter.metricFilter,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSelectMetric: (selectedContact, callBack) =>
      dispatch(actions.onSelectMetric(selectedContact, callBack)),
    setMetricFilter: filter => dispatch(actions.setMetricFilter(filter)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MetricsScreen);
