import React, {Component} from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Switch,
  Platform,
} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import UIText from '../Text/text';
import Collapsible from '../../components/Collapsible/Collapsible';
import VerificationCodeInput from '../../components/VerificationCodeInput';
import PhoneInput from '../../components/PhoneInput';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import {Auth} from 'aws-amplify';
import ToolTip from '../../components/ToolTip';
import KeychainWrapper from '../Biometric/keyChain';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { getBiometricSecurityType } from '../../util/commonUiLogic';
import { IOS, WEB } from '../../util/platformWindowConfig';


const {Popover} = renderers;

class Security extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentVisibility: props.profileSecurityShow,
      phoneNumber: '',
      phoneNumberVerified: false,
      phoneNumberEdit: true,
      wait: false,
      verificationOn: false,
      validCode: false,
      invalidCode: false,
      verificationWait: false,
      error: false,
      errorDescription: '',
      mfaEnabled: false,
      mfaWait: false,
      openCountyPopup: false,
      bioWait: false,
      bioEnabled: false,
      showBio: false,
      biometricToggle: this.props.biometricToggle,
      showBioEnableMessage: false,
    };

    this.props.setProfileSecurityShow(false);
    if (this.props.scrollTo && props.profileSecurityShow) {
      setTimeout(() => this.props.scrollTo(true), 500);
    }
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then(user => {
        Auth.getPreferredMFA(user, {
          bypassCache: true,
        }).then(data => {
          let mfaEnabled = false;
          if (data === 'SMS_MFA') {
            mfaEnabled = true;
          }

          this.setState({
            mfaEnabled: mfaEnabled,
          });
        });
      })
      .catch(err => {});

    Auth.currentUserInfo()
      .then(res => {
        this.setState({
          phoneNumber: res.attributes.phone_number,
          phoneNumberVerified: res.attributes.phone_number_verified,
          phoneNumberEdit: !res.attributes.phone_number_verified,
        });
      })
      .catch(err => {
        this.setState({
          error: true,
          errorDescription: err.message,
        });
      });
    if (Platform.OS !== WEB) {
      this.checkBioMetricStatus();
    }
    if (Platform.OS === IOS) this.getBioMetricsTypeSecurity();
  }

  checkBioMetricStatus = async () => {
    if (Platform.OS !== WEB) {
      let hasCredentials = await KeychainWrapper.hasCredentialsInKeychain();
      this.setState({hasCredentials: hasCredentials});

      if (hasCredentials && !this.state.biometricToggle) {
        this.setState({bioEnabled: true, showBio: true});
      } else {
        this.setState({bioEnabled: false, showBio: true});
      }
    }
  };

  getBioMetricsTypeSecurity = async () => {
    let biometryTypeSecurity = null;
    const data = await getBiometricSecurityType();
    if (data && data?.sensorName) {
      biometryTypeSecurity = data?.sensorName;
    }
    
    this.setState({
      biometryTypeSecurity: biometryTypeSecurity,
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    const {profileSecurityShow, biometricToggle} = props;
    if (profileSecurityShow) {
      this.setState({contentVisibility: profileSecurityShow});
      this.props.setProfileSecurityShow(false);
    }
    if (
      Platform.OS !== WEB &&
      biometricToggle !== this.state.biometricToggle
    ) {
      this.setState({biometricToggle: biometricToggle});

      this.checkBioMetricStatus();
    }
  }

  handleOnPressCollapsible = () => {
    if (this.props.scrollTo) {
      this.props.scrollTo(!this.state.contentVisibility);
    }
    this.setState({contentVisibility: !this.state.contentVisibility});
  };

  editPhoneNumber = () => {
    if (this.state.mfaEnabled) {
      this.setState({
        validCode: false,
        verificationOn: false,
        invalidCode: false,
        error: true,
        errorDescription:
          'Can not edit phone number,2-Step Verification is enabled.',
      });
    } else {
      this.setState({
        phoneNumberEdit: true,
        validCode: false,
        verificationOn: false,
        error: false,
        errorDescription: '',
        phoneNumberVerified: false,
      });
    }
  };

  updatePhoneNumber = () => {
    if (!this.state.phoneNumber) {
    } else {
      let phoneNumber = this.state.phoneNumber.replace(/[- )(]/g, '');
      if (phoneNumber.charAt(0) !== '+') {
        phoneNumber = '+' + phoneNumber;
      }
      if (phoneNumber.trim().length === 12) {
        this.setState({wait: true, verificationOn: false});
        Auth.currentAuthenticatedUser({
          bypassCache: true,
        })
          .then(user => {
            Auth.updateUserAttributes(user, {
              phone_number: phoneNumber.trim(),
            })
              .then(result => {
                Auth.verifyCurrentUserAttribute('phone_number')
                  .then(() => {
                    this.setState({
                      wait: false,
                      verificationOn: true,
                      error: false,
                    });
                  })
                  .catch(err => {
                    this.setState({
                      wait: false,
                      error: true,
                      errorDescription: err.message,
                    });
                  });
              })
              .catch(err => {
                this.setState({
                  wait: false,
                  error: true,
                  errorDescription: err.message,
                });
              });
          })
          .catch(err => {
            this.setState({
              wait: false,
              error: true,
              errorDescription: err.message,
            });
          });
      } else {
        this.setState({
          error: true,
          errorDescription: 'Please enter valid phone number',
        });
      }
    }
  };

  onChangeverificationCode = (value, valid) => {
    if (valid && value.length === 6) {
      this.setState({verificationWait: true}, () => {
        Auth.verifyCurrentUserAttributeSubmit('phone_number', value)
          .then(() => {
            this.setState(
              {
                verificationWait: false,
                validCode: true,
                invalidCode: false,
                error: false,
                phoneNumberVerified: true,
                phoneNumberEdit: false,
              },
              () => {
                this.toggleMfaSwitch(true);
                this.props.showSuccessPopup(1);
                setTimeout(() => this.setState({verificationOn: false}), 500);
              },
            );
          })
          .catch(e => {
            this.setState({
              verificationWait: false,
              invalidCode: true,
              validCode: false,
              error: true,
              errorDescription:
                e.code === 'CodeMismatchException' ||
                e.code === 'ExpiredCodeException'
                  ? 'Error! Looks like you entered the wrong code'
                  : e.message,
            });
          });
      });
    } else {
      this.setState({
        verificationWait: false,
        invalidCode: false,
        validCode: false,
        error: false,
        errorDescription: '',
      });
    }
  };
  toggleMfaSwitch = val => {
    if (!this.state.phoneNumber) {
      this.setState({
        mfaWait: false,
        error: true,
        errorDescription:
          '  User does not have a phone number set to turn on SMS 2-Step Verification',
      });
    } else if (!this.state.phoneNumberVerified) {
      this.setState({
        mfaWait: false,
        error: true,
        errorDescription: 'Phone number is not verified.',
      });
    } else {
      let mfaAttr = 'NOMFA';
      if (val) {
        mfaAttr = 'SMS';
      }
      this.setState({mfaWait: true, error: false});
      Auth.currentAuthenticatedUser({
        bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then(user => {
          Auth.setPreferredMFA(user, mfaAttr)
            .then(result => {
              pendoTrackEvent(val ? 'Profile@ > Security > 2StepOn' : 'Profile@ > Security > 2StepOff')
              this.setState({mfaEnabled: val, mfaWait: false});
              if (mfaAttr === 'SMS') {
                this.props.clearNotificationById('MFA_ENABLE_REMINDER');
              } else {
                this.props.cognitoReminder(true, false, null);
              }
            })
            .catch(err => {
              this.setState({
                mfaWait: false,
                error: true,
                errorDescription: err.message,
              });
            });
        })
        .catch(err => {
          this.setState({
            mfaWait: false,
            error: true,
            errorDescription: err.message,
          });
        });
    }
  };

  toggleBiometricSwitch = val => {
    const {showBioEnableMessage} = this.state;
    this.setState({bioWait: !val}, async () => {
      if (!val) {
        await KeychainWrapper.resetGenericPassword();

        this.setState({bioEnabled: val, bioWait: false});
      } else if (!showBioEnableMessage) {
        this.setState({showBioEnableMessage: true});
      }
    });
  };

  deletePhoneNumber = () => {
    if (this.state.mfaEnabled) {
      this.setState({
        validCode: false,
        verificationOn: false,
        invalidCode: false,
        error: true,
        errorDescription:
          'Can not delete phone number,2-Step Verification is enabled.',
      });
    } else {
      this.setState({
        wait: true,
        error: false,
        validCode: false,
        verificationOn: false,
        invalidCode: false,
      });
      Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
        .then(user => {
          Auth.updateUserAttributes(user, {
            phone_number: '',
          })
            .then(result => {
              this.setState({
                phoneNumber: '',
                phoneNumberVerified: false,
                phoneNumberEdit: true,
                wait: false,
              });
            })
            .catch(err => {
              this.setState({
                wait: false,
                error: true,
                errorDescription: err.message,
              });
            });
        })
        .catch(err => {
          this.setState({
            wait: false,
            error: true,
            errorDescription: err.message,
          });
        });
    }
  };

  setOpenCountyPopup = val => {
    this.setState({openCountyPopup: val});
  };
  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {showBio, bioEnabled, bioWait} = this.state;
    return (
      <React.Fragment>
        <View style={styles.zIndexHigh}>
          <Collapsible
            title="Security"
            contentVisibility={this.state.contentVisibility}
            style={[styles.collapseTitle, styles.zIndexMin]}
            handleOnPressCollapsible={this.handleOnPressCollapsible}
            titleLight={true}
            topBorder={true}
            titleLt={true}
            titleLg={true}
            nativeId={'profileSecurity'}>
            <View style={[styles.collapseContent]}>
              <View style={stylesRes.boxWrapperInWrapper}>
                <View style={stylesRes.boxWrapperIn}>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={styles.fieldRow}>
                        <View style={[styles.textDescViewWrapper]}>
                          <View style={[styles.textDescView, styles.pdTopSm]}>
                            <View style={styles.textDetails}>
                              <Text
                                style={[
                                  styles.textPrime,
                                  styles.textAlignLeft,
                                ]}
                                allowFontScaling= {false}
                                >
                                2-Step Verification
                              </Text>
                            </View>
                            <View style={styles.helpCircleComView}>
                              <ToolTip
                                id={'2-step-verification'}
                                text={
                                  '2-step verification adds an extra layer of security to your account. Once enabled, each time you log into the application, will be required to enter a unique pass code sent to your mobile number in addition to yor user name and password.'
                                }
                                disabledScroll={this.props.disabledScroll}
                              />
                            </View>
                          </View>

                          <View
                            style={[
                              styles.switchWrapper,
                              styles.viewMoreDataTopMg,
                            ]}>
                            <View style={[styles.switchView]}>
                              <Switch
                                onValueChange={this.toggleMfaSwitch}
                                value={this.state.mfaEnabled}
                                style={styles.toggleBtnIcon}
                              />
                              {this.state.mfaWait && (
                                <View
                                  style={[
                                    styles.indicatorIconView,
                                    styles.indicatorRtSwtich,
                                  ]}>
                                  <ActivityIndicator
                                    pdZero={true}
                                    size="small"
                                  />
                                </View>
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={styles.gridRow}>
                    <View style={[styles.gridColFull]}>
                      <View
                        style={[
                          styles.textSubCom,
                          styles.infoPdLg,
                          styles.flexRowJcCtAiCt,
                        ]}>
                        <View
                          style={[
                            styles.flexRowJcCtAiCt,
                            styles.textSubDetail,
                          ]}>
                          <View style={styles.textIconCenter}>
                            <Image
                              style={styles.telCircleIcon}
                              source={require('../../assets/icons/tel-circle.png')}
                            />
                          </View>

                          <View style={styles.textDescWrapper}>
                            <Text style={[styles.textPrime]} allowFontScaling= {false}>
                              Send a code to verify your cell phone number
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={[styles.gridRow, styles.zIndexMax]}>
                    <View style={styles.gridColFull}>
                      <View style={styles.fieldRow}>
                        <View
                          style={[styles.telCodeField, styles.fieldRtPdSpec]}>
                          {!this.state.wait && (
                            <PhoneInput
                              value={this.state.phoneNumber}
                              disabled={
                                !this.state.phoneNumberEdit ||
                                this.state.verificationOn
                              }
                              onChange={phone => {
                                this.setState({
                                  phoneNumber: phone,
                                  error: false,
                                  errorDescription: '',
                                  verificationOn: false,
                                });
                              }}
                              setOpenCountyPopup={this.setOpenCountyPopup}
                              allowFontScaling= {false}
                            />
                          )}

                          <View style={[styles.fieldIconView, styles.flexRow]}>
                            {Boolean(this.state.phoneNumberEdit) &&
                              !Boolean(this.state.verificationOn) &&
                              !this.state.wait && (
                                <TouchableOpacity
                                  style={styles.fieldIconTouchSmView}
                                  onPress={this.updatePhoneNumber}
                                  disabled={!this.state.phoneNumberEdit}>
                                  <Image
                                    style={styles.enterIcon}
                                    source={require('../../assets/icons/enter.png')}
                                    resizeMode={'contain'}
                                  />
                                </TouchableOpacity>
                              )}
                            {Boolean(this.state.verificationOn) && !this.state.wait && (
                              <TouchableOpacity
                                style={styles.fieldIconTouchSmView}
                                onPress={this.updatePhoneNumber}
                                disabled={!this.state.phoneNumberEdit}>
                                <Image
                                  style={styles.resetIcon}
                                  source={require('../../assets/icons/reset.png')}
                                  resizeMode={'contain'}
                                />
                              </TouchableOpacity>
                            )}

                            {this.state.wait && (
                              <View
                                style={[
                                  styles.indicatorIconView,
                                  styles.indicatorLtSm,
                                ]}>
                                <ActivityIndicator pdZero={true} size="small" />
                              </View>
                            )}

                            {!Boolean(this.state.phoneNumberEdit) && (
                              <TouchableOpacity
                                style={styles.fieldIconTouchSmView}
                                onPress={this.updatePhoneNumber}
                                disabled={!this.state.phoneNumberEdit}>
                                <Image
                                  style={styles.rightSmIcon}
                                  source={require('../../assets/icons/right-grey.png')}
                                  resizeMode={'contain'}
                                />
                              </TouchableOpacity>
                            )}

                            <View style={[styles.settingSmSec]}>
                              <Menu
                                renderer={Popover}
                                rendererprops={{
                                  preferredPlacement: 'left',
                                }}>
                                <MenuTrigger>
                                  <Image
                                    style={[
                                      styles.settingIcon,
                                      styles.settingsGrey,
                                    ]}
                                    source={require('../../assets/icons/settings.png')}
                                  />
                                </MenuTrigger>
                                <MenuOptions style={styles.dropDownList}>
                                  <MenuOption
                                    style={styles.liItem}
                                    onSelect={() => this.editPhoneNumber()}>
                                    <Text style={styles.listText} allowFontScaling= {false}>Edit</Text>
                                  </MenuOption>
                                  <MenuOption
                                    style={styles.liItem}
                                    onSelect={() => this.deletePhoneNumber()}>
                                    <Text style={styles.listText} allowFontScaling= {false}>Delete</Text>
                                  </MenuOption>
                                </MenuOptions>
                              </Menu>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  {Boolean(this.state.verificationOn) && (
                    <View style={[styles.gridRow, styles.zIndexMin]}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.fieldRow]}>
                          <View
                            style={[styles.rowTitleSec, styles.rowTitleTopSp]}>
                            <Text
                              style={[
                                styles.textPrimeSm,
                                styles.textAlignCenter,
                              ]}
                              allowFontScaling= {false}
                              >
                              Enter the{' '}
                              <Text style={[styles.bold]} allowFontScaling= {false}>
                                verification code{' '}
                              </Text>
                            </Text>
                          </View>

                          <View
                            style={[
                              styles.verifyCodeField,
                              this.state.invalidCode
                                ? styles.inValidColor
                                : this.state.validCode
                                ? styles.validGreenColor
                                : styles.validColor,
                            ]}>
                            <VerificationCodeInput
                              onChange={this.onChangeverificationCode}
                            />
                            <View style={styles.fieldIconView}>
                              <View style={styles.fieldIconTouchView}>
                                {this.state.verificationWait && (
                                  <View style={styles.indicatorFixed}>
                                    <ActivityIndicator size="small" />
                                  </View>
                                )}

                                {Boolean(this.state.validCode) && (
                                  <Image
                                    style={styles.statusCorrectIcon}
                                    source={require('../../assets/icons/right-green-icon.png')}
                                    resizeMode={'contain'}
                                  />
                                )}

                                {Boolean(this.state.invalidCode) && (
                                  <Image
                                    style={styles.statusWrongIcon}
                                    source={require('../../assets/icons/warning-exclamation.png')}
                                    resizeMode={'contain'}
                                  />
                                )}
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  )}

                  <View style={[styles.gridRow, styles.zIndexMin]}>
                    <View style={[styles.gridColFull, styles.errBoxView]}>
                      {Boolean(this.state.error) && (
                        <View style={styles.notifyBlock}>
                          <View
                            style={[
                              styles.notifyDetails,
                              styles.notifyNoPd,
                              styles.notifyPdBot,
                            ]}>
                            <UIText
                              style={[styles.warningComTextInd]}
                              title={this.state.errorDescription}
                            />
                          </View>
                        </View>
                      )}
                    </View>
                  </View>

                  {Boolean(showBio) && (
                    <View style={[styles.gridRow, styles.zIndexMin]}>
                      <View style={styles.gridColFull}>
                        <View style={styles.fieldRow}>
                          <View style={[styles.textDescViewWrapper]}>
                            <View
                              style={[styles.textDescView, styles.secBgPdTop]}>
                              <View style={styles.textDetails}>
                                <Text
                                  style={[
                                    styles.textPrime,
                                    styles.textAlignLeft,
                                  ]}
                                  allowFontScaling= {false}
                                  >
                                  {Platform.OS === IOS && this.state.biometryTypeSecurity
                                    ? this.state.biometryTypeSecurity
                                    : 'Biometric Security'
                                  }
                                </Text>
                              </View>
                            </View>

                            <View
                              style={[
                                styles.switchWrapper,
                                styles.viewMoreDataTopMg,
                              ]}>
                              <View style={[styles.switchView]}>
                                <Switch
                                  onValueChange={this.toggleBiometricSwitch}
                                  value={bioEnabled}
                                  style={styles.toggleBtnIcon}
                                  // disabled={!bioEnabled}
                                />
                                {Boolean(bioWait) && (
                                  <View
                                    style={[
                                      styles.indicatorIconView,
                                      styles.indicatorRtSwtich,
                                    ]}>
                                    <ActivityIndicator
                                      pdZero={true}
                                      size="small"
                                    />
                                  </View>
                                )}
                              </View>
                            </View>
                          </View>
                          {Boolean(this.state.showBioEnableMessage) && (
                            <View style={[styles.flexRow, styles.flexJcFsAiFs, styles.notifyPdBot]}>
                              <View>
                                <View
                                  style={[styles.medSecImgBgView, styles.oneTp]}
                                >
                                  <Image
                                    style={styles.statusWrongIcon}
                                    source={require('../../assets/icons/warning-exclamation.png')}
                                    resizeMode={'contain'}
                                  />
                                </View>
                                <View
                                  style={[
                                    styles.notifyDetails,
                                    styles.notifyNoPd,
                                    styles.paddingLeft25,
                                  ]}>
                                  <UIText
                                    style={[styles.warningComText]}
                                    title={"You need to re-login with your current password in order to enable biometrics on this device."}
                                  />
                                </View>
                              </View>
                            </View>
                          )}
                        </View>
                      </View>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </Collapsible>
        </View>
        {Platform.OS === WEB && this.state.contentVisibility ? (
          <View style={styles.pdBotPage} />
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    profileSecurityShow: state.uistate.profileSecurityShow,
    user: state.routines.user,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    clearNotificationById: id => dispatch(actions.clearNotificationById(id)),
    setProfileSecurityShow: show =>
      dispatch(actions.setProfileSecurityShow(show)),
    cognitoReminder: (emailVerified, mfaEnable, notif) =>
      dispatch(actions.cognitoReminder(emailVerified, mfaEnable, notif)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Security);
