import theme from "./base/theme";
export const cssBackground = {
  startUpLeftBlock: {
    backgroundColor: theme.COLOR_WHITE,
  },
  menuBgWhite: {
    backgroundColor: theme.COLOR_WHITE,
    
  },
  bgWhite: {
    backgroundColor: theme.COLOR_WHITE,
  },
  tabTitleBg: {
    backgroundColor: theme.COLOR_WHITE,
  },
  disableBtn: {
    backgroundColor: theme.COLOR_DISABLE_BUTTON,
  },
  submitBgBlue: {
    backgroundColor: theme.COLOR_BLUE,
  },
  bgYellowDark: {
    backgroundColor: theme.COLOR_YELLOW,
  },
  bgOrangeDark: {
    backgroundColor: theme.COLOR_ORANGE_DARK,
  },
  submitBgOrange: {
    backgroundColor: theme.COLOR_ORANGE_DARK,
  },
  submitBgBlueDark: {
    backgroundColor: theme.COLOR_BLUE_DARK_2,
  },
  submitBgGrey: {
    backgroundColor: theme.COLOR_GREY_LIGHT_RG,
  },
  bgPink: {
    backgroundColor: theme.COLOR_ERROR_BG,
  },
  bgBlue: {
    backgroundColor: theme.COLOR_BLUE_5,
  },
  bgLightGrey: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_6,
  },
  topicBgDefault: {
    backgroundColor: theme.COLOR_PURPLE_1,
  },
  bgGreenDark: {
    backgroundColor: theme.COLOR_GREEN_3,
  },
  bgPurpleDark: {
    backgroundColor: theme.COLOR_PURPLE_DARK,
  },
  bgOrange: {
    backgroundColor: theme.COLOR_ORANGE_2,
  },
  bgGreen: {
    backgroundColor: theme.COLOR_GREEN_2,
  },
  addItemBg: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_3,
  },
  colBg: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_3,
  },
  colBgLight: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_4,
  },
  navListLiActive: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_4,
  },
  menuBg: {
    backgroundColor: theme.COLOR_GREY_LIGHT_MD,
  },
  modalContentBg: {
    backgroundColor: theme.COLOR_LIGHT_WHITE_2,
  },
  headerBg: {
    backgroundColor: theme.COLOR_HEADER,
  },
  pgActiveBg: {
    backgroundColor: theme.COLOR_ORANGE,
  },
  secBgCom: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_1,
  },
  openBg: {
    backgroundColor: theme.COLOR_GREY_LIGHT_RG,
  },
  pgBgOrange: {
    backgroundColor: theme.COLOR_ORANGE,
  },
  mapBg: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_2,
  },
  secBlueBg: {
    backgroundColor: theme.COLOR_BLUE_BG,
  },
  inActiveBg: {
    backgroundColor: theme.COLOR_BORDER,
  },
  submitBgInkBlue: {
    backgroundColor: theme.COLOR_BLUE_3,
  },
  bgPurple: {
    backgroundColor: theme.COLOR_PURPLE_LIGHT,
  },
  statusBgOrange: {
    backgroundColor: theme.COLOR_ORANGE,
  },
  bgpage: {
    backgroundColor: theme.COLOR_HEADER,
  },
  navListLiDefault: {
    backgroundColor: theme.COLOR_WHITE,
  },
  bgLearnHow: {
    backgroundColor: theme.COLOR_LIGHT_PINK,
  },
  bgThinGrey: {
    backgroundColor: theme.LIGHT_BG,
  },
  bgThinDark: {
    backgroundColor: theme.LIGHT_MD_BG,
  },
  bgThinExLight: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_10,
  },
  bgBlueExLight: {
    backgroundColor: theme.COLOR_BLUE_2,
  },
  bgBlueAutoSuggest: {
    backgroundColor: theme.COLOR_BLUE_12,
  },
  darkIcon: {
    tintColor: theme.COLOR_GREY_DARK_2,
  },
  lightIcon: {
    tintColor: theme.COLOR_GREY_DARK_1,
  },
  notifyTitleBg: {
    backgroundColor: theme.LIGHT_BG
  },
  bgBlueLight: {
    backgroundColor: theme.COLOR_BLUE_4,
  },
  contBgTransparent: {
    backgroundColor: theme.COLOR_TRANSPARENT,
  },
  toolTipBg: {
    backgroundColor: theme.COLOR_BLUE_6,
  },
  validGreenColor: {
    borderColor: theme.COLOR_EMERALD,
  },
  submitBgAshLight: {
    backgroundColor: theme.COLOR_ASH,
  },
  bgThinGreyBorder: {
    borderColor: theme.LIGHT_BG,
  },
  bgBlueExLightBorder: {
    borderColor: theme.COLOR_BLUE_2,
  },
  bgWhiteBorder: {
    borderColor: theme.COLOR_WHITE,
  },
  bgTransparentBorder: {
    borderColor: theme.COLOR_TRANSPARENT,
  },
  secLightBlueBg: {
    backgroundColor: theme.COLOR_BLUE_BG_LIGHT,
  },
  submitBgOrangeLight: {
    backgroundColor: theme.COLOR_ORANGE_LIGHT,
  },
  imgViewBg: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_19,
  },
  attachBg: {
    backgroundColor: theme.COLOR_BLACK,
  },
  bgCircleGreen: {
    tintColor: theme.COLOR_EMERALD,
  },
  bgCircleOrange: {
    tintColor: theme.COLOR_ORANGE_DARK,
  },
  bgHealthJourneyDeleteBtn: {
    backgroundColor: theme.COLOR_BLUE_16,
  },
  bgwhitegrey: {
    backgroundColor:theme.COLOR_DARK_LIGHT_GREY
  },
  bgDarkAsh: {
    backgroundColor: theme.COLOR_DARK_ASH
  }
};
