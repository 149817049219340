import theme from './base/theme';
import {Platform} from 'react-native';
import * as variable from '../css/base/variable';
import * as variableShadow from './base/variableShadow';
export const cssMedication = {
  collapsiblePlusIcon: {
    width: 38,
    height: 38,
  },
  collapsiblePlusIconView: {
    width: 39,
    height: 39,
  },
  collapsibleIconView: {
    marginRight: 10,
    marginLeft: -5,
  },
  btnTabMenuWrapper: {
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnTabMenu: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_10,
    minHeight: 40,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  btnTabSmMenu: {
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5,
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_10,
    minHeight: 40,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  btnTabMenuDefault: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_10,
  },
  btnTabMenuActive: {
    backgroundColor: theme.COLOR_ORANGE_DARK,
  },
  btnTabMenuActiveGray: {
    backgroundColor: theme.TERTIARY_FONT_COLOR,
  },
  btnTabMenuCom: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 4,
  },
  btnTabMenuCurve: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
  },
  btnTextdefault: {
    color: theme.SECONDARY_FONT_COLOR,
  },
  btnTextHighlight: {
    color: theme.COLOR_WHITE,
  },
  btnTabSingleLg: {
    flex: 1,
  },
  btnTabSingleSm: {
    flex: 0.7,
  },
  btnTabTouch: {
    flex: 1,
  },
  btnTabSingleSp: {
    maxWidth: 3,
    flexBasis: 3,
  },
  btnShadow: {
    shadowColor: '#9DC2FF',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    ...Platform.select({
      web: {
        boxShadow: '0px 4px 4px #C4CAFF',
      },
    }),
  },
  secDivider: {
    borderTopColor: 'rgba(152, 152, 154, 0.4)',
    borderTopWidth: 1,
    marginBottom: 15,
  },
  minusCircleView: {
    paddingRight: 2,
  },
  fieldColFixed: {
    maxWidth: 90,
    flexBasis: 90,
  },
  fieldColDynamic: {
    flex: 1,
  },
  mgRtExSm: {
    marginRight: 1,
  },
  mgLtExSm: {
    marginLeft: 1,
  },
  btnTabSingleDynamic: {
    flex: 1,
  },
  rowTitleView: {
    paddingBottom: 5,
  },
  submitBgAsh: {
    backgroundColor: theme.COLOR_ASH,
  },
  textInkBlue: {
    color: theme.COLOR_INK,
  },
  tableView: {
    borderWidth: 1,
    borderColor: theme.COLOR_GREY_LIGHT_EX_14,
  },
  trTitleView: {
    flexDirection: 'row',
  },
  trDataView: {
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: theme.COLOR_GREY_LIGHT_EX_14,
  },
  trDataViewCom: {
    flexDirection: 'row',
  },
  tdColView: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
  tdColViewCom: {
    paddingTop: 3,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 3,
  },
  tableViewCom: {
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5,
  },
  tdColRtBorder: {
    borderRightWidth: 1,
    borderRightColor: theme.COLOR_GREY_LIGHT_EX_14,
  },
  tdColLgView: {
    width: '60%',
  },
  tdColSmView: {
    width: '40%',
  },
  tdColLgViewCom: {
    width: '70%',
  },
  tdColSmViewCom: {
    width: '30%',
  },
  arrowClose: {
    width: 10,
    height: 18,
  },
  arrowOpen: {
    width: 18,
    height: 10,
  },
  horzFieldValueRemove: {
    maxWidth: 28,
    flexBasis: 28,
    paddingLeft: 6,
  },
  delLgIcon: {
    width: 22,
    height: 22,
  },
  collapseTopBorder: {
    borderTopColor: theme.COLOR_BORDER_MEDIUM,
    borderTopWidth: 1,
    borderBottomWidth: 0,
  },
  collapseTopBorderNone: {
    borderBottomColor: 'transparent',
    borderTopWidth: 0,
  },
  collapseBotBorderNone: {
    borderBottomColor: 'transparent',
    borderBottomWidth: 0,
  },
  collapseBorderNone: {
    borderColor: 'transparent',
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  collapseBotBorder: {
    borderBottomColor: theme.COLOR_BORDER_MEDIUM,
    borderBottomWidth: 1,
  },
  vBarIcon: {
    width: 1,
    height: 22,
  },
  vertDivider: {
    maxWidth: 1,
    flexBasis: 1,
    marginLeft: 5,
    marginRight: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  attachDownloadIcon: {
    width: 20,
    height: 22,
  },
  attachmentIconSec: {
    flexBasis: 42,
    maxWidth: 42,
    justifyContent: 'center',
    alignItems: 'center',
  },
  attachIconTouch: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  attachSp: {
    paddingRight: 10,
  },
  attachRowPd: {
    paddingBottom: 20,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  attachDownloadLoader: {
    width: 20,
    height: 22,
  },
  attachImgAvailable: {
    borderColor: theme.COLOR_GREY_DARK_1,
    borderWidth: 1,
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  questComCircleView: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  questComCircleMg: {
    marginTop: -10,
    marginRight: -10,
  },
  durationContentView: {
    flexBasis: 60,
    maxWidth: 60,
  },
  durationSliderView: {
    flex: 1,
  },
  durationSliderWrapper: {
    flexDirection: 'row',
  },
  sliderDataIcon: {
    width: 42,
    height: 42,
  },
  sliderDataIconView: {
    width: 42,
    height: 42,
  },
  sliderDataValue: {
    position: 'absolute',
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    top: -3,
  },
  durationSliderTitle: {
    paddingLeft: 60,
    paddingTop: 5,
  },
  sliderText: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    height: 17,
  },
  sliderTextLeft: {
    left: 10,
  },
  sliderTextRight: {
    right: 10,
  },
  popupTitleMg: {
    marginBottom: 5,
  },
  deleteBasket: {
    width: 26,
    height: 34,
  },
  popUpBoxHeightMin: {
    minHeight: 280,
  },
  submitBgRed: {
    backgroundColor: theme.COLOR_RED_1,
  },
  indicatorLtMini: {
    left: -5,
  },
  resumeBtn: {
    width: 33,
    height: 33,
  },
  tdColEqView: {
    width: '32%',
  },
  tdColEqLgView: {
    width: '36%',
  },
  pauseBtn: {
    width: 23,
    height: 26,
  },
  pauseField: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
    paddingBottom: 20,
  },
  texFieldSingle: {
    maxWidth: 50,
  },
  pauseFieldWrapper: {
    paddingBottom: 10,
  },
  noRad: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
  comTopBorder: {
    borderTopColor: theme.COLOR_BORDER_MEDIUM,
    borderTopWidth: 1,
  },
  comBotBorder: {
    borderBottomColor: theme.COLOR_BORDER_MEDIUM,
    borderBottomWidth: 1,
  },
  trItemView: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: theme.COLOR_BORDER_MEDIUM,
    paddingBottom: 10,
    paddingTop: 10,
  },
  tdItemLgView: {
    width: '60%',
  },
  tdItemSmView: {
    width: '40%',
  },
  contRowSp: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
  },
  modalPickerAllWrapper: {
    flex: 1,
    backgroundColor: theme.COLOR_BLACK_2,
  },
  modalPickerArea: {
    ...variable.marginAllAuto,
    maxHeight: '100%',
  },
  collapseContentBg: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_4,
  },
  questItemView: {
    marginBottom: 20,
    borderTopColor: theme.COLOR_GREY_LIGHT_MD,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderRightColor: theme.COLOR_GREY_LIGHT_MD,
    borderLeftWidth: 1,
    borderLeftColor: theme.COLOR_GREY_LIGHT_MD,
  },
  indicatorLtCom: {
    left: 10,
  },
  sliderSmaller: {
    width: 100,
  },
  sliderSmallerView: {
    flex: 1,
  },
  sliderIndicator: {
    marginRight: 15,
    marginTop: -4,
  },
  indicatorLtMiniLg: {
    left: -10,
  },
  horzLgTitle: {
    flexBasis: 170,
    maxWidth: 170,
  },
  sliderBtn: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
  },
  thumbBtn: {
    width: 30,
    height: 30,
    borderRadius: 30,
    shadowColor: theme.COLOR_BLUE_10,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  trackArea: {
    height: 14,
    borderRadius: 14,
  },
  deleteFullView: {
    flex: 1,
  },
  sliderThumbCom: {
    width: 35,
    height: 35,
    resizeMode: 'contain',
  },
  sliderCom: {
    height: 20,
  },
  linearGradientApp: {
    borderRadius: 50,
  },
  thumbBtnSm: {
    width: 20,
    height: 20,
    backgroundColor: theme.COLOR_PURPLE_2,
    borderColor: theme.COLOR_PURPLE_3,
    borderWidth: 5,
    borderRadius: 10,
    shadowColor: theme.COLOR_BLACK,
    shadowOffset: {width: 0, height: 2},
    shadowRadius: 2,
    shadowOpacity: 0.35,
  },
  sliderDataTitle: {
    maxWidth: 42,
  },
  sliderThumbTouch: {
    position: 'absolute',
    top: 5,
    borderRadius: 30,
    width: 30,
    height: 30,
  },
  thumbRight: {
    right: 0,
  },
  thumbLeft: {
    left: 0,
  },
  thumbCenter: {
    left: '35%',
  },
  stopBtn: {
    width: 36,
    height: 36,
  },
  removeIcon: {
    width: 20,
    height: 20,
  },
  thumbBtnOrange: {
    width: 20,
    height: 20,
    backgroundColor: theme.COLOR_WHITE,
    borderColor: theme.COLOR_ORANGE_DARK,
    borderWidth: 2,
    borderRadius: 10,
    shadowColor: theme.COLOR_BLACK,
    shadowOffset: {width: 0, height: 2},
    shadowRadius: 2,
    shadowOpacity: 0.35,
  },
  exclamationIcon: {
    width: 55,
    height: 51,
  },
  specDetWrapper: {
    paddingTop: 3,
  },
  itemDividerWrap: {
    paddingLeft: 20,
  },
  itemDividerFull: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  itemDividerPd: {
    paddingLeft: 10,
    paddingRight: 25,
  },
  listPremiumBtnSec: {
    flexBasis: 105,
    maxWidth: 105,
  },
  premiumBtnView: {
    borderRadius: 35,
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 5,
    paddingLeft: 10,
    backgroundColor: theme.COLOR_HEADER,
    ...variableShadow.btnShadowSpec,
  },
  bgDevice: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_12,
  },
  secDeviceArea: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  deviceListWrapper: {
    borderBottomWidth: 0,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  deviceMainSec: {
    minHeight: 140,
  },
  deviceListItem: {
    paddingTop: 20,
    paddingRight: 35,
    paddingBottom: 20,
    paddingLeft: 35,
    flexDirection: 'row',
  },
  deviceItemBtnSec: {
    flex: 1,
    paddingLeft: 10,
    justifyContent: 'center',
  },
  deviceItemImgSec: {
    flexBasis: 180,
    maxWidth: 180,
  },
  deviceItemImg: {
    width: 160,
    height: 34,
    resizeMode: 'contain',
  },
  itemMoreWrapper: {
    paddingTop: 20,
    paddingRight: 10,
    paddingBottom: 20,
    paddingLeft: 10,
  },
  deviceSecPd: {
    paddingBottom: 100,
  },
  actIndSwitch: {
    position: 'absolute',
    left: 60,
    marginTop: 3,
  },
  attachIconTouchCom: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  fieldColIdCom: {
    flexBasis: 33,
    maxWidth: 33,
    paddingLeft: 3,
  },
  menuSingleIconSpecBlock: {
    flexBasis: 130,
    maxWidth: 130,
    alignSelf: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  menuSpecIcon: {
    width: 100,
    height: 20,
    resizeMode: 'contain',
  },
  fillCont: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  fillContAuto: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 5,
  },
  autofillIconMed: {
    height:12,
    width:12,
    resizeMode:'contain',
    marginRight:8,
  },
  closeMed:{
    marginLeft:10,
  }
};
