import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity, Platform} from 'react-native';

import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import UIText from '../Text/text';
import UITextInput from '../../components/TextInput/textInput';
import Collapsible from '../../components/Collapsible/Collapsible';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import {Auth} from 'aws-amplify';
import Warning from '../../components/Warning/warning';
import KeychainWrapper from '../../components/Biometric/keyChain';
import NextTimeBioMetrics from '../Biometric/biometricLoginModal';
import CancelButton from '../Button/cancelButton';
import { WEB } from '../../util/platformWindowConfig';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentVisibility: false,
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      currentPasswordValidate: true,
      newPasswordValidate: true,
      confirmPasswordValidate: true,
      confirmBox: false,
      currentPasswordVisible: true,
      newPasswordVisible: true,
      confirmPasswordVisible: true,
      saveWait: false,
      visibleSetBiometricModel: false,
      hasCredentials: false,
    };
  }

  componentDidMount() {
    this.hasCredentialsInKeychain();
  }

  hasCredentialsInKeychain = async () => {
    if (Platform.OS !== WEB) {
      let hasCredentials = await KeychainWrapper.hasCredentialsInKeychain()
      this.setState({hasCredentials: hasCredentials});
    }
  };

  handleOnPressCollapsible = () => {
    if (this.props.scrollTo) {
      this.props.scrollTo(!this.state.contentVisibility);
    }
    this.setState({
      contentVisibility: !this.state.contentVisibility,
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      currentPasswordValidate: true,
      newPasswordValidate: true,
      confirmPasswordValidate: true,
      confirmBox: false,
      currentPasswordVisible: true,
      newPasswordVisible: true,
      confirmPasswordVisible: true,
      error: false,
    });
  };

  handleConfirm = () => {
    this.setState({confirmBox: false});
    this.props.history.goBack();
  };

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const {error, errorDescription} = nextProps;
  //   this.setState({
  //     ...this.state,
  //     error,
  //     errorDescription,
  //   });
  // }

  handleCurrentPasswordVisibility = () => {
    this.setState(prevState => ({
      currentPasswordVisible: !prevState.currentPasswordVisible,
    }));
  };
  handleNewPasswordVisibility = () => {
    this.setState(prevState => ({
      newPasswordVisible: !prevState.newPasswordVisible,
    }), () => {
      if (this.state.newPassword &&
        !this.state.newPasswordVisible &&
        !this.state.confirmPassword
      ) {
        this.setState({error: false, errorDescription: ''})
      }
    });
  };
  handleConfirmPasswordVisibility = () => {
    this.setState(prevState => ({
      confirmPasswordVisible: !prevState.confirmPasswordVisible,
    }));
  };

  handleSuccessSetPassword = (callback, data) => {
    this.props.scrollToTop(false, callback, data);
    this.setState({contentVisibility: false});
  };

  onPressChangePassword = () => {
    if (
      !this.state.currentPassword ||
      !this.state.newPassword ||
      (this.state.newPasswordVisible && !this.state.confirmPassword)
    ) {
      let er = 'This field is required';
      this.setState({
        ...this.state,
        error: true,
        errorDescription: er,
        currentPasswordValidate: this.state.currentPassword,
        newPasswordValidate: this.state.newPassword,
        confirmPasswordValidate: this.state.confirmPassword,
      });
      if (
        !this.state.currentPassword &&
        !this.state.newPassword &&
        !this.state.confirmPassword
      ) {
        this.setState({
          currentPasswordValidate: false,
          newPasswordValidate: false,
          confirmPasswordValidate: false,
        });
      } else if (!this.state.newPassword && !this.state.confirmPassword) {
        this.setState({
          newPasswordValidate: false,
          confirmPasswordValidate: false,
        });
      }
    } else if (this.state.currentPassword === this.state.newPassword) {
      let er = 'Your New Password must be different from your Current Password';
      this.setState({
        ...this.state,
        error: true,
        errorDescription: er,
        newPasswordValidate: false,
        newPassword: '',
        confirmPasswordValidate: false,
        confirmPassword: '',
      });
    } else if (
      this.state.newPasswordVisible &&
      this.state.newPassword !== this.state.confirmPassword
    ) {
      let er = 'Error! Looks like the passwords don’t match up';
      this.setState({
        ...this.state,
        error: true,
        errorDescription: er,
        confirmPasswordValidate: false,
        confirmPassword: '',
      });
    } else if (this.state.newPassword.length < 8) {
      let er = 'Minimum length is 8';
      this.setState({
        ...this.state,
        error: true,
        errorDescription: er,
        newPasswordValidate: false,
        confirmPasswordValidate: false,
      });
    } else {
      const oldPassword = this.state.currentPassword;
      const newPassword = this.state.newPassword;

      this.setState({
        error: false,
        errorDescription: '',
        saveWait: true,
      });

      Auth.currentAuthenticatedUser()
        .then(user => {
          this.setState({user});
          return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then(async data => {
          // if (Platform.OS !== 'web') {
          //   await KeychainWrapper.resetGenericPassword();
          // }
          const {hasCredentials} = this.state;
          const isWeb = Platform.OS === WEB;
          this.setState(
            {
              currentPasswordValidate: true,
              currentPassword: '',
              newPasswordValidate: true,
              newPassword: isWeb ? '' : newPassword,
              confirmPasswordValidate: true,
              confirmPassword: '',
              saveWait: false,
              visibleSetBiometricModel: !isWeb && hasCredentials,
            },
            () => {
              if (isWeb || !hasCredentials) {
                const data = {
                  title: 'Success',
                  body: 'Your password is updated'
                }
                this.handleSuccessSetPassword(this.props.showSuccessPopup, data)
              }
            },
          );
        })
        .catch(err => {
          if (
            err.code === 'NotAuthorizedException' ||
            err.code === 'InvalidParameterException'
          ) {
            this.setState({
              error: true,
              errorDescription: 'Incorrect current password',
              saveWait: false,
              currentPasswordValidate: false,
              currentPassword: '',
            });
          } else {
            this.setState({
              error: true,
              errorDescription: err.message,
              saveWait: false,
            });
          }
        });
    }
  };

  handleOnSubmitCancel = () => {
    this.handleOnPressCollapsible();
    this.setState({
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      currentPasswordValidate: true,
      newPasswordValidate: true,
      confirmPasswordValidate: true,
      error: false,
      errorDescription: '',
      currentPasswordVisible: true,
      newPasswordVisible: true,
      confirmPasswordVisible: true,
    });
  };

  onChangeCurrentPassword = value => {
    if (value) {
      this.setState({currentPasswordValidate: true, error: false});
    } else {
      this.setState({currentPasswordValidate: false});
    }
    this.setState({currentPassword: value});
  };

  onChangeNewPassword = value => {
    if (value) {
      this.setState({newPasswordValidate: true, error: false});
    } else {
      this.setState({newPasswordValidate: false});
    }
    this.setState({newPassword: value});
  };

  onChangeConfirmNewPassword = value => {
    if (value) {
      this.setState({confirmPasswordValidate: true, error: false});
    } else {
      this.setState({confirmPasswordValidate: false});
    }
    this.setState({confirmPassword: value});
  };

  onPressNotNow = () => {
    const {showSuccessPopup, biometricToggle} = this.props;
    const data = {
      title: 'Success',
      body: 'Your password is updated'
    }
    this.setState({visibleSetBiometricModel: false}, async () => {
      await KeychainWrapper.resetGenericPassword();
      this.handleSuccessSetPassword(showSuccessPopup, data);
      biometricToggle();
    })
  }

  handleOnPressSubmit = async () => {
    const {showSuccessPopup} = this.props;
    const {newPassword, user} = this.state;
    const {username} = user;
    const data = {
      title: 'Success',
      body: 'Your password and biometrics are updated'
    }
    try {
      await KeychainWrapper.resetGenericPassword();
      await KeychainWrapper.setGenericPassword(username, newPassword);
      this.setState({visibleSetBiometricModel: false}, () => {
        this.handleSuccessSetPassword(showSuccessPopup, data);
      })
    } catch (error) {
      data.body = 'Your password is updated';
      this.setState({visibleSetBiometricModel: false}, () => {
        this.handleSuccessSetPassword(showSuccessPopup, data);
      })
    }
  }

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {visibleSetBiometricModel} = this.state;
    return (
      <View style={styles.zIndexZero}>
        <Collapsible
          title="Change Password"
          contentVisibility={this.state.contentVisibility}
          style={[styles.collapseTitle, styles.zIndexMin]}
          handleOnPressCollapsible={this.handleOnPressCollapsible}
          titleLt={true}
          titleLg={true}
          topBorder={true}>
          <View style={[styles.collapseContent, styles.comSpMd]}>
            <View style={stylesRes.boxWrapperIn}>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.fieldRow}>
                    <Text style={styles.textView} allowFontScaling= {false}>
                      <UIText
                        style={[
                          styles.textLabel,
                          styles.bold,
                          styles.textColorLight,
                        ]}
                        title="Current Password"
                      />
                      <UIText 
                      style={styles.textReq} 
                      title="*" 
                      />
                    </Text>

                    <UITextInput
                      style={[
                        styles.textField,
                        styles.fieldPdRtExLg,
                        this.state.currentPasswordValidate
                          ? styles.validColor
                          : styles.inValidColor,
                      ]}
                      secureTextEntry={this.state.currentPasswordVisible}
                      onChangeText={this.onChangeCurrentPassword}
                      value={this.state.currentPassword}
                      autoCapitalize="none"
                    />

                    <View style={styles.passHintIn}>
                      <View style={styles.passHintDetails}>
                        <TouchableOpacity
                          style={styles.passHintView}
                          onPress={this.handleCurrentPasswordVisibility}>
                          <Image
                            style={
                              this.state.currentPasswordVisible
                                ? styles.passHiddenLg
                                : styles.passShowLg
                            }
                            source={
                              this.state.currentPasswordVisible
                                ? require('../../assets/icons/pw-hide-blue.png')
                                : require('../../assets/icons/pw-show-blue.png')
                            }
                            resizeMode={'contain'}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.fieldRow}>
                    <Text style={styles.textView} allowFontScaling= {false}>
                      <UIText
                        style={[
                          styles.textLabel,
                          styles.bold,
                          styles.textColorLight,
                        ]}
                        title="New Password"
                      />
                      <UIText 
                      style={styles.textReq} 
                      title="*" 
                      />
                    </Text>

                    <UITextInput
                      style={[
                        styles.textField,
                        styles.fieldPdRtExLg,
                        this.state.newPasswordValidate
                          ? styles.validColor
                          : styles.inValidColor,
                      ]}
                      secureTextEntry={this.state.newPasswordVisible}
                      onChangeText={this.onChangeNewPassword}
                      value={this.state.newPassword}
                      autoCapitalize="none"
                    />

                    <View style={styles.passHintIn}>
                      <View style={styles.passHintDetails}>
                        <TouchableOpacity
                          style={styles.passHintView}
                          onPress={this.handleNewPasswordVisibility}>
                          <Image
                            style={
                              this.state.newPasswordVisible
                                ? styles.passHiddenLg
                                : styles.passShowLg
                            }
                            source={
                              this.state.newPasswordVisible
                                ? require('../../assets/icons/pw-hide-blue.png')
                                : require('../../assets/icons/pw-show-blue.png')
                            }
                            resizeMode={'contain'}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              {Boolean(this.state.newPasswordVisible) && (
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.fieldRow}>
                      <Text style={styles.textView} allowFontScaling= {false}>
                        <UIText
                          style={[
                            styles.textLabel,
                            styles.bold,
                            styles.textColorLight,
                          ]}
                          title="Confirm New Password"
                        />
                        <UIText 
                        style={styles.textReq} 
                        title="*" 
                        />
                      </Text>

                      <UITextInput
                        style={[
                          styles.textField,
                          styles.fieldPdRtExLg,
                          this.state.confirmPasswordValidate
                            ? styles.validColor
                            : styles.inValidColor,
                        ]}
                        secureTextEntry={true}
                        onChangeText={this.onChangeConfirmNewPassword}
                        value={this.state.confirmPassword}
                        autoCapitalize="none"
                      />
                    </View>
                  </View>
                </View>
              )}

              <View style={styles.gridRow}>
                <View style={[styles.gridColFull, styles.errBoxView]}>
                  {Boolean(this.state.error) && (
                    <Warning errorDescription={this.state.errorDescription} />
                  )}
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={[styles.btnArea, styles.btnPdTopMini]}>
                    <View
                      style={[stylesRes.btnViewSec, stylesRes.btnViewSecComNoPd]}>
                      <View style={styles.fieldBtnMini}>
                        <View style={styles.btnBlockFull}>
                          <CancelButton
                            touchableOnpress={() => this.handleOnSubmitCancel()} 
                            cancelBtnWidthDiff={true}             
                          />
                        </View>
                      </View>
                      <View style={styles.fieldBtnSpSm} />
                      <View style={styles.fieldBtn}>
                        <View style={styles.btnBlockFull}>
                          <TouchableOpacity
                            style={[
                              styles.submitSm,
                              styles.submitBgOrange,
                              styles.btnShadowCom,
                            ]}
                            underlayColor="rgba(0, 153, 168, 0.8)"
                            onPress={() => {
                              this.onPressChangePassword();
                            }}
                            disabled={this.state.saveWait}>
                            <View style={styles.btnSingleView}>
                              <View
                                style={[
                                  styles.btnSingle,
                                  styles.btnSingleCent,
                                ]}>
                                {this.state.saveWait && (
                                  <View
                                    style={[
                                      styles.indicatorIconView,
                                      styles.indicatorLtExSm,
                                    ]}>
                                    <ActivityIndicator
                                      size="small"
                                    />
                                  </View>
                                )}
                                <Text
                                  style={[
                                    styles.submitBtnTextWhite,
                                    styles.bold,
                                  ]}
                                  allowFontScaling= {false}
                                  >
                                  Change Password
                                </Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Collapsible>
        {visibleSetBiometricModel && (
          <NextTimeBioMetrics
            biometricType={'Update'}
            nextTimeBioMetric={visibleSetBiometricModel}
            onPressNotNow={this.onPressNotNow}
            handleOnSubmitLoginTuchId={this.handleOnPressSubmit}
            headerText={"Do you wish to update your biometrics with the new password?"}
            bodyText={"Your biometrics will be turned off  if you wish to not update it for now"}
          />
        )}
      </View>
    );
  }
}
export default (ChangePassword);
