import React, {Component} from 'react';
import {View, Text} from 'react-native';
import {connect} from 'react-redux';
import styles from '../../../../Css';
import Collapsible from '../../../Collapsible/Collapsible';
import SymptomSlider from '../../../../components/HASlider/symptomSlider';
import YesNoSwitch from '../../../../components/YesNoSwitch/yesNoSwitch';
import {getSortedSymptomsWithSymptomObject} from "../../../../store/actions";
import { TAB_BREAk_MAX_WIDTH } from '../../../../util/platformWindowConfig';

class Symptoms extends Component {
  constructor(props) {
    super(props);
    const {symptoms, symptomsDigestProcessed} = props;
    const filteredSymptoms = symptoms.filter(symptom => symptom.severity !== null);
    const sortedSymptomsWithSymptomObject = getSortedSymptomsWithSymptomObject(filteredSymptoms, false, symptomsDigestProcessed);
    this.state = {
      symptoms: sortedSymptomsWithSymptomObject,
      visibleSymptoms: filteredSymptoms && filteredSymptoms.length > 0,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {symptoms, windowWidth, symptomsDigestProcessed} = props;
    const filteredSymptoms = symptoms.filter(symptom => symptom.severity !== null);
    const sortedSymptomsWithSymptomObject = getSortedSymptomsWithSymptomObject(filteredSymptoms, false, symptomsDigestProcessed);
    if (windowWidth > TAB_BREAk_MAX_WIDTH && JSON.stringify(state.symptoms) !== JSON.stringify(sortedSymptomsWithSymptomObject)) {
      return {
        symptoms: sortedSymptomsWithSymptomObject,
        visibleSymptoms: filteredSymptoms && filteredSymptoms.length > 0,
      }
    }

    return null;
  }

  getItem = (item) => {
    const {severity} = item;
    const {name, displayProperties} = item.symptomObj;
    const {scaleMin, scaleMax, description} = displayProperties;

    const sliderSegments = [...Array(11)].map(x => '');
    if (scaleMin !== null
      && scaleMax !== null
      && description) {
      try {
        const tempDescriptionObjectArray = JSON.parse(description);
        tempDescriptionObjectArray.forEach((element) => {
          if (description) {
            sliderSegments[element.value] = element.description;
          }
        });
      } catch (error) {
      }
    }

    const getYesNoSwitchSeverity = (yesNoSeverityLevel) => {
      switch (yesNoSeverityLevel) {
        case 0:
          return 'NO';
        case 1:
          return 'YES';
        default:
          return null;
      }
    };

    return (
      <View style={[styles.sliderViewWrapper, styles.secSepBorderBot]}>
        <View style={[styles.comSpSm]}><Text style={[styles.textPrimeBold]}
                                             allowFontScaling={false}>{name}</Text></View>
        {(scaleMin === 0 && scaleMax === 1) &&
          <YesNoSwitch enabled={false} value={getYesNoSwitchSeverity(severity)}/>}
        {((scaleMin === null && scaleMax === null) || (scaleMin === 0 && scaleMax === 10))
          &&
          <SymptomSlider
            value={severity}
            enabled={false}
            valueDescriptions={sliderSegments}
            colorMode={'GRADIENT'}
            disabledScroll={this.props.disabledScroll}
          />
        }
      </View>
    );
  };

  render() {
    const {symptoms, visibleSymptoms} = this.state;
    const {symptomsDigestProcessed} = this.props;
    const {userSymptoms} = symptomsDigestProcessed;
    return (
      <>
      {
        symptoms && symptoms.length > 0 ? (

          <Collapsible
          title="Symptoms"
          contentVisibility={visibleSymptoms}
          handleOnPressCollapsible={() => {
            this.setState({
              visibleSymptoms: !visibleSymptoms,
            });
          }}
          disabled={symptoms && symptoms.length > 0 ? false : true}
          style={[styles.collapseTitle]}
          customTitleStyle={true}
          topBorder={true}
        >
          {symptoms.length > 0 && (
            <View
              style={[
                styles.collapseContent,
                styles.colPd,
                styles.colPdBotExSm,
                styles.bgThinGrey,
              ]}>
              <View style={(styles.fieldContainer, styles.fieldRowNoMg)}>
                <View style={[styles.fieldRowSliderWrapper]}>
                  {symptoms.map(
                    (item, index) =>
                    Boolean(userSymptoms[item.symptom]) && this.getItem(item, index),
                  )}
                </View>
              </View>
            </View>
          )}
        </Collapsible>
        ) : null
      }
        

      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    symptomsDigestProcessed: state.routines.symptomsDigestProcessed,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(
  mapStateToProps,
  null,
)(Symptoms);
