import React, { Fragment, useState, useEffect } from "react";
import {View, Text, Image, TouchableOpacity} from 'react-native';
import FileUpload from "../../../components/ImagesDocumentsForAttachment/fileUpload";
import styles from '../../../Css';
import AttachmentRenameBox from "../HealthJourneyAttachments/AttachmentRenameBox";
import * as constants from '../HealthJourneyConstant';

const AddEventAttachments = (props) => {
  const [visibleEntryModal, setVisibleEntryModal] = useState(false);
  const {windowWidth, attachments, addAttachmentsTakePhoto, updateAttachments, commonErrorHandler, addAttachmentsFromZamplo, launch, isFromAddAttachmentCategory, activeBubbleId, setActiveBubble} = props;
  const [focus, setFocus] = useState(false);
  const notEmpty = attachments && attachments.length > 0;

  useEffect(() => {
    if (launch) {
      setVisibleEntryModal(true);
    }
  }, [launch])

  const handleModalState = () => {
    setVisibleEntryModal(e => !e);
  }

  const onChangeFileUpload = eventObj => {
    const newAttachments = Object.assign([], attachments);

    const attachmentsPresent = eventObj?.target?.files?.length ? true : false;

    if (!attachmentsPresent) return
    for (let i = 0; i < eventObj.target.files.length; i++) {
      const file = eventObj.target.files[i];
      if (file.size <= constants.MAX_FILE_SIZE_W) {
        if (file.size > 0) {
          const extension = file?.name?.split(".").pop();
          const newFile = {
            tempId: Date.now() + i,
            file,
            fileName: file.name.replace(`.${extension}`, ""),
            fileExtension: extension,
          };
          newAttachments.push(newFile);
        }
      } else {
        commonErrorHandler("Maximum file size exceeded!")
      }
    }
    
    updateAttachments(newAttachments);
    handleModalState();
  }

  const selectFromZamplo = () => {
    handleModalState();
    addAttachmentsFromZamplo();
  }

  const onPressTakePhoto = () => {
    handleModalState();
    addAttachmentsTakePhoto();
  }

  const onChangeFileName = (newName, id) => {
    const found = attachments.findIndex(item => item.tempId === id);
    const attachToUpdate = Object.assign([], attachments);
    if (found !== -1) {
      const element = attachments[found];
      element.fileName = newName;
      if (!element?.id) {
        const newFile = new File([element.file], newName + "." + element.fileExtension, { type: element.file.type });
        element.file = newFile;
      } 
      attachToUpdate[found] = element;
    }
    updateAttachments(attachToUpdate);
  }

  const onFileRemove = id => {
    const attachToUpdate = Object.assign([], attachments);
    updateAttachments(attachToUpdate.filter(item => item.tempId !== id));
  }


  return (
    <View
      style={[
        styles.flexRow,
        // styles.flexJcSb,
        // styles.flexAiCt,
        styles.mgTopLg,
        styles.flexJcFs
      ]}>
      {(windowWidth >= constants.WINDOW_MOBILE_WIDTH_MARGIN || Boolean(notEmpty) || Boolean(isFromAddAttachmentCategory)) && (
        <TouchableOpacity onPress={() => {
          activeBubbleId && setActiveBubble(null);
          handleModalState();
        }}
        disabled={notEmpty} 
        style={styles.flexRow}>
          <View style={styles.flexRow}>
              <View style={[styles.flexJcCt, styles.flexAiCt, notEmpty && styles.tpFifteenJsFS]}>
                <Image
                  style={styles.attachmentIcnNwEvent}
                  source={notEmpty ? constants.setAttachmentIconToDisplay(focus, isFromAddAttachmentCategory) : constants.setAttachmentIconToDisplay(true, isFromAddAttachmentCategory)}
                  resizeMode={constants.IMAGE_RESIZE_MODE}
                />
              </View>
              {!notEmpty && (windowWidth >= constants.WINDOW_MOBILE_WIDTH_MARGIN ||
                isFromAddAttachmentCategory) && (
                  <View
                    style={[styles.mgLtEight, styles.flexJcCt, styles.flexAiCt]}>
                    <Text style={[
                      styles.textTitle,
                      styles.attachmentTxtNwEvnt,
                      isFromAddAttachmentCategory && styles.textUnderline
                    ]}>
                      {isFromAddAttachmentCategory ? '+ Add Documents or Images' : 'Attachments'}
                    </Text>
                  </View>
                )}
          </View>
        </TouchableOpacity>
      )}
      {notEmpty && (
        <View
          style={[
            styles.flexRow,
            // styles.flexAiCt,
            styles.mgLtFive,
            {
              maxWidth: '90%',
              flexWrap: 'wrap',
              flex: 1,
            },
          ]}>
          {attachments.map(item => (
            <Fragment key={item.tempId}>
              <AttachmentRenameBox
                focus={focus}
                name={item.fileName}
                index={item.tempId}
                onChangeFileName={onChangeFileName}
                extension={item.fileExtension}
                onRemoveFile={onFileRemove}
                onSetFocusValue={(value) => setFocus(value)}
                activeBubbleId={activeBubbleId}
                setActiveBubble={setActiveBubble}
              />
            </Fragment>
          ))}

          <View
            style={[styles.pdLtTwentyFive, styles.flexJcCt, styles.flexAiCt]}>
            <TouchableOpacity onPress={() => {
              activeBubbleId && setActiveBubble(null);
              handleModalState();
            }}>
              <Text style={[styles.textPrimeBold, styles.seaBluTxtColor, styles.textUnderline]}>
                + Add more
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
      <FileUpload
        onChangeFileUpload={onChangeFileUpload}
        visibleEntryModal={visibleEntryModal}
        onPressClose={handleModalState}
        selected={3}
        onPressTakePhoto={onPressTakePhoto}
        showSelectFromZamplo
        onSelectFromZamplo={selectFromZamplo}
      />
    </View>
  );
};

export default AddEventAttachments;
