import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import styles from '../../Css';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';

const primaryShadowButton = props => {
  return (
    <View
      style={[
        styles.actionBtnWrapper,
        props.btnPosition === 'left'
          ? styles.flexAiFs
          : props.btnPosition === 'right'
          ? styles.flexAiFe
          : props.btnPosition === 'center'
          ? styles.flexAiCt
          : styles.flexCom,
      ]}>
      <TouchableOpacity
        style={[props.touchableStyle]}
        onPress={props.touchableOnPress}
        disabled={props.disabled}>
        <View
          style={[
            styles.btnSingleView,
            styles.primaryButton,
            styles.primaryButtonLg,
            styles.btnBorderLight,
            styles.bgWhite,
            styles.btnShadowCom,
            props.btnStyle,
          ]}>
          <View style={[styles.btnSingle, styles.btnSingleCent]}>
            {Boolean(props.activityInd) && (
              <View
                style={[
                  styles.indicatorIconView,
                  props.btnPosition === 'full'
                    ? styles.indicatorLtDefault
                    : styles.indicatorLtSm,
                  props.activityIndStyle,
                ]}>
                <ActivityIndicator pdZero={true} size="small" />
              </View>
            )}
            <Text
              style={[styles.submitBtnText, styles.resourceBlueTxt, styles.bold]} allowFontScaling={false}>
              {props.btnText}
            </Text>

            {Boolean(props.resetIcon) && (
              <View style={styles.btnIconView}>
                <Image
                  style={styles.resetLgIcon}
                  source={require('../../assets/icons/reset-blue.png')}
                />
              </View>
            )}
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (primaryShadowButton);
