import React, { Component } from "react";
import { Image, View, Text, TouchableOpacity, TextInput, ScrollView } from "react-native";
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
class YearlyCustomPicker extends Component {
    render(){
        const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
        return (
        <React.Fragment>  
        <View style={[styles.wdSeventy, styles.selectItemCustomDatePicker]}>
            <ScrollView>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> 2013 </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> 2014 </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> 2015 </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> 2016 </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> 2017 </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> 2018</Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> 2019 </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> 2020 </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> 2021 </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> 2022 </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> 2024 </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> 2025 </Text>
            </ScrollView>
        </View>

        </React.Fragment>
        );
    }
};

export default YearlyCustomPicker;
