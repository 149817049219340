import React, {useState, useEffect} from 'react';
import ReactCodeInput from 'react-verification-code-input';
import {TextInput} from 'react-native';

let codeRef = null
const VerificationCodeInput = ({shouldChange = true, ...props}) => {
  const {verificationCode} = props;
  const [code, setVerificationCode] = useState('');
  
  useEffect(() => {
    if (code !== verificationCode) {
      setVerificationCode(verificationCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationCode])

  const checkValues = (arr, index) => {
    if (arr && arr[index] !== "" && arr.length - 1 === index) return true;
    if (arr && arr[index] !== "")
      return checkValues(arr, index + 1);
    else
      return false;
  }

  const onFocus = (ref, index) => {
    if (index === 0) return;
    if (ref.iRefs[index].current.value) return;
    if (ref?.state?.values?.some(e => e)) return;
    let focusMe = index;
    for (let i = index; i >= 0; i--) {
      if (!ref.iRefs[i].current.value)
        focusMe = i;
    }
    if (index === focusMe) return; //do this otherwise this will call itself
    if (ref?.iRefs?.[index]?.current) ref.iRefs[index].current.blur();
    if (ref?.iRefs?.[focusMe]?.current) ref.iRefs[focusMe].current.focus();
  }

  const addFocusEventsToEachBox = ref => {
    ref.iRefs.forEach((element, idx) => {
      element.current.onfocus = () => onFocus(ref, idx)
    });;
  }

  const handleFocus = ref => {
    if (ref) {
      addFocusEventsToEachBox(ref);
    }
    
    if (
      ref?.state?.values &&
      checkValues(ref?.state?.values, 0) && ref?.iRefs?.[5]?.current
    ) {
      ref.iRefs[5].current.blur();
    }
  }
  
  if (Boolean(shouldChange)) {
    if (codeRef && code === '') {
      codeRef.state.values = Array(6).fill('');
    }
    return (
      <ReactCodeInput
        onChange={code => props.onChange(code, false)}
        onComplete={code => props.onChange(code, true)}
        fieldWidth={32}
        fieldHeight={38}
        className="input-set"
        autoFocus={true}
        values={code}
        loading={!Boolean(shouldChange)}
        ref={ref => {
          handleFocus(ref);
          codeRef = ref
        }}
      />
    );
  } else {
    return (
      <TextInput value="" style={{width: 0, height: 0}}  allowFontScaling={false} />
    );
  }
};

export default VerificationCodeInput;
