import React from 'react';
import {Image, View, Text, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import * as comTitles from './titles';
import TickButton from '../../components/CheckBox/CheckBox';
import LogoSetUp from '../../components/LogoSetUp/LogoSetUp';
import ToolTip from '../../components/ToolTip';
import Warning from '../../components/Warning/warning';
import Recaptcha from './Recaptcha';
import * as FieldDetails from '../../components/TextInput/fieldDetails';
import PageLayout from '../../components/PageLayout/pageLayout';
import InputField from '../../components/TextInput/inputField';
import InputPasswordField from '../../components/TextInput/inputPasswordField';
import PrimaryButton from '../../components/Button/primaryButton';
import ValidationMessage from '../../components/Warning/validationMessage';
import BioMetricModal from '../Biometric/biometricEnableModal';
import NextTimeBioMetrics from '../Biometric/biometricLoginModal';

let secondTextInput = null;
const stylesRes = stylesResponsive.getProcessedStyles().styles;
const ids = stylesResponsive.getProcessedStyles().ids;
const login = props => {
  return (
    <>
    <PageLayout
      windowHeightView={props.windowHeightView}
      windowHeightSmView={props.windowHeightSmView}
      windowWidthView={props.windowWidthView}
      bgBlue={props.bgBlue}
      contentCenter={false}
      bgStyle={true}
      scrollEnabled={props.scrollEnabled}>
      <View style={[styles.boxWrapperArea, styles.colMgLtRt]}>
        <View style={[stylesRes.boxWrapperView, stylesRes.wrapperShadow]} dataSet={{media: ids.boxWrapperView}}>
          <View style={[styles.colPd]}>
            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <LogoSetUp />
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={[styles.gridColFull]}>
                <View
                  style={[
                    styles.titleBlock,
                    styles.flexRowJcCtAiCt,
                    styles.pdTopBotZero,
                    styles.comSpTopBot,
                  ]}>
                  <View style={styles.textIconView}>
                    <Image
                      style={styles.humanIconCom}
                      source={require('../../assets/icons/human-circle.png')}
                      resizeMode={'contain'}
                    />
                  </View>

                  <Text
                    style={[
                      styles.textAlignCenter,
                      styles.textTitleBold,
                      styles.textThickGrey,
                    ]}
                    allowFontScaling={false}
                    >
                    Login
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <View
                  style={[
                    styles.titleBlock,
                    styles.flexRowJcCtAiCt,
                    styles.noPdTop,
                  ]}>
                  <Text
                    style={[
                      styles.textAlignCenter,
                      styles.textPrime,
                      styles.textTertiary,
                    ]}
                    allowFontScaling={false}
                    >
                    First time here?{' '}
                  </Text>
                  <TouchableOpacity onPress={props.handleOnSignup} style={styles.touchSmPd} disabled={props.authSuccessSpinner}>
                    <Text
                      style={[
                        styles.resourceBlueTxt,
                        styles.bold,
                        styles.textUnderline,
                      ]}
                      allowFontScaling={false}
                      >
                      Sign Up
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <View style={styles.fieldContainer}>
                  <View style={[styles.fieldRowWrapper]}>
                    <View style={styles.fieldColStretch}>
                      <InputField
                        title={comTitles.EMAIL_ADDRESS}
                        fieldBg={FieldDetails.BGWHITE}
                        fieldRg={true}
                        requirdStar={true}
                        invalid={!props.emailValidate}
                        onChangeText={props.handleOnChangeEmail}
                        value={props.email}
                        autoFocus={true}
                        underlineColorAndroid="transparent"
                        returnKeyType={'next'}
                        onSubmitEditing={e => {
                          secondTextInput.focus();
                          if (props.email && props.password) {
                            props.onSubmitEditing();
                          }
                        }}
                        editable={!props.authSuccessSpinner}
                        textContentType={'username'}
                      />
                    </View>
                  </View>

                    {!Boolean(props.emailValidate) && Boolean(props.errorEmail) && (
                    <ValidationMessage errorMessage={props.emailDescription} />
                  )}
                </View>
              </View>
            </View>
            <View style={[styles.gridRow, styles.tooltipZindexLow]}>
              <View style={styles.gridColFull}>
                <View style={styles.fieldContainer}>
                  <View style={[styles.fieldRowWrapper]}>
                    <View style={styles.fieldColStretch}>
                      <InputPasswordField
                        title={comTitles.PASSWORD}
                        fieldBg={FieldDetails.BGWHITE}
                        fieldRg={true}
                        requirdStar={true}
                        invalid={!props.passwordValidate}
                        secureTextEntry={props.passwordVisible}
                        passwordVisible={props.passwordVisible}
                        onChangeText={props.handleOnChangePassword}
                        value={props.password}
                        onSubmitEditing={props.onSubmitEditing}
                        underlineColorAndroid="transparent"
                        autoCapitalize="none"
                        ref={input => {
                          secondTextInput = input;
                        }}
                        handlePasswordVisibility={
                          props.handlePasswordVisibility
                        }
                        passwordShowHide={true}
                        editable={!props.authSuccessSpinner}
                        textContentType={'password'}
                      />
                    </View>
                  </View>
                    {!Boolean(props.passwordValidate) && Boolean(props.errorPassword) && (
                    <ValidationMessage
                      errorMessage={props.passwordDescription}
                    />
                  )}
                </View>
              </View>
            </View>
            <View nativeID="inputLoginFields" />

            <View style={[styles.gridRow, styles.tooltipZindexHigh]}>
              <View style={styles.gridColFull}>
                <View style={styles.fieldRow}>
                  <View style={[styles.linkSecView]}>
                    <View style={[styles.checkBoxView, styles.flexCom]}>
                      <View>
                          {Boolean(props.staySigned) ? (
                          <TickButton
                            imagePath={'tickButton'}
                            toggleTickCallback={() => {
                              props.toggleTickCallback(false);
                            }}
                            tickStyle={[styles.tickStyleCom, {marginRight: 2}]}
                            styleType={'curve'}
                          />
                        ) : (
                          <TickButton
                            imagePath={'untickButton'}
                            toggleTickCallback={() => {
                              props.toggleTickCallback(true);
                            }}
                            tickStyle={[styles.tickStyleCom, {marginRight: 2}]}
                            styleType={'curve'}
                          />
                        )}
                      </View>
                      <View style={styles.checkBoxItemText}>
                        <Text
                          style={[
                            styles.textPrimeSm,
                            styles.textAlignLeft,
                            styles.textTertiary,
                          ]}
                          allowFontScaling={false}
                          >
                          Stay Signed in
                        </Text>
                      </View>
                      <View style={styles.helpCircleComView}>
                        <ToolTip
                          id={'tool-tip-1'}
                          text={
                            'Select this to reduce the number of times you are asked to sign-in'
                          }
                          placement={'top'}
                          smScreen={true}
                          disabledScroll={props.disabledScroll}
                        />
                      </View>
                    </View>

                    <View style={[styles.forgotPassView, styles.flexCom]}>
                      <TouchableOpacity onPress={props.onClickForgotPassword} style={styles.touchSmPd} disabled={props.authSuccessSpinner}>
                        <View style={styles.contBlock}>
                          <Text
                            style={[
                              styles.textPrimeMd,
                              styles.resourceBlueTxt,
                              styles.textAlignRight,
                            ]}
                            allowFontScaling={false}
                            >
                            Forgot{' '}
                            <Text style={[styles.resourceBlueTxt]} allowFontScaling={false}>Password?</Text>
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={[styles.gridColFull, styles.errBoxView]}>
                  {Boolean(props.error) && (
                  <Warning
                    errorDescription={props.errorDescription}
                    textPosition={'left'}
                  />
                )}
              </View>
            </View>

              {Boolean(props.invalidLogingTryCountExceed) && (
              <View style={styles.gridRow}>
                <View style={[styles.gridColFull]}>
                  <View style={styles.fieldRow}>
                    <View style={styles.captchaBlock}>
                      <Recaptcha setRecaptchaValue={props.setRecaptchaValue} />
                    </View>
                  </View>
                </View>
              </View>
            )}

            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <View
                  style={[
                    styles.btnArea,
                    styles.btnPdTopMini,
                    styles.comMgBotSm,
                  ]}>
                  <View style={styles.fieldBtn}>
                    <PrimaryButton
                      btnText={'Login'}
                      btnPosition={'full'}
                      activityInd={props.authSuccessSpinner}
                      btnShadow={true}
                      btnLg={true}
                      touchableOnPress={props.handleOnSubmitLogin}
                      disabled={props.authSuccessSpinner}
                      btnStyle={styles.submitBgBlueDark}
                    />
                  </View>
                </View>
              </View>
            </View>

              {(Boolean(props.touchIDIsSupported) ||
              (!props.touchIDIsSupported && props.openSetting)) &&
                Boolean(props.hasCredentials) && (
                <View style={styles.gridRow}>
                  <View style={[styles.gridColFull]}>
                    <View
                      style={[
                        styles.textDetails,
                      ]}>
                      <TouchableOpacity
                        onPress={props.handleOnSubmitLoginTuchId}
                        disabled={props.bioPressed}
                        style={styles.comPd}>
                        <Text
                          style={[
                            styles.textPrimeBold,
                            styles.resourceBlueTxt,
                            styles.textUnderline,
                          ]}
                          allowFontScaling={false}
                          >
                          Login using {props.biometricType}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              )}
          </View>
        </View>
      </View>
      </PageLayout>
      <>
      {props.showSetting && (
        <BioMetricModal
          showSetting={props.showSetting}
          onPressSkip={props.onPressSkip}
          onPressGoToSetting={props.onPressGoToSetting}
        />
      )}
        {Boolean(props.nextTimeBioMetric) && (
        <NextTimeBioMetrics
          biometricType={'Use ' + props.biometricType}
          nextTimeBioMetric={props.nextTimeBioMetric}
          onPressNotNow={props.onPressNotNow}
          handleOnSubmitLoginTuchId={props.handleOnSubmitLoginTuchId}
          headerText={"Next time, would you like to login faster with biometric login?"}
          bodyText={"Zamplo uses biometric security to keep your data safe. You can cancel biometric login at anytime in your profile."}
        />
      )}
        {Boolean(props.updateBiometric) && (
        <NextTimeBioMetrics
          biometricType={'Update'}
          nextTimeBioMetric={props.updateBiometric}
          onPressNotNow={props.onPressNotNowUpdate}
          handleOnSubmitLoginTuchId={props.onPressUpdateCredentials}
          headerText={"Do you wish to update your email address and password?"}
          bodyText={"It looks like you logged in manually. Do you want to update the credentials we have saved for you?"}
        />
      )}
      </>
      </>
  );
};

export default (login);
