import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import { Keyboard } from 'react-native';
import AutoCompleteSuggestion from '../../AutoCompleteSuggestion';
import {autoSuggestionListType} from '../../../../Constant/index';

class AddRoutineDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      userTerms: this.getUserTerms(),
    };
  }

  getUserTerms = () => {
    let termsArray = [];
    const { treatments } = this.props;
    if (treatments) {
      const suggestionResults = [...treatments];
      termsArray = suggestionResults.map(value => {
        return (
          value &&
          value.treatmentType !== 5 && {
            name: value.name,
            data: value,
          }
        );
      });
    }

    return termsArray;
  };

  onItemPressName = (selection, type) => {
    const { onPressItem } = this.props;

    this.setState(
      {
        name: '',
      },
      () => {
        onPressItem(selection, type);
        Keyboard.dismiss();
      },
    );
  };

  onChangeTextName = currentInput => {
    this.setState({
      name: currentInput,
    });
  };

  onPressCustomName = (selection, type) => {
    if (selection && selection.trim() !== '') {
      const { onPressItem } = this.props;
      this.setState(
        {
          name: '',
        },
        () => {
          onPressItem(selection, type);
          Keyboard.dismiss();
        },
      );
    }
  };


  processData = res => {
    let termsArray = [];
    if (res) {
      const suggestionResults = [...res.summaries];
      if (res?.substring === this.state.name || !res?.substring) {
      termsArray = suggestionResults.map(value => {
        return {
          currentInput: this.state.currentInput ,
          name: value.summary.match('.+?(?=\\(DIN:)') ? value.summary.match('.+?(?=\\(DIN:)') : value.summary.match('.+?(?=\\(NHP:)'),
          // name: value.summary,
          Ingredient: value.ingredient,
          din: value.summary.includes("(DIN:") ? "DIN: "+value.treatmentId : "NHP: "+value.treatmentId ,
          data: value
        };
      });
      this.setState({termsArray: termsArray});
      } else {
        termsArray = this.state.termsArray
      }
    }
    return termsArray;
  };

  render() {
    const url = '/api/treatments/treatment/summarycontains?substring=';
    const { updateAutocompleteOpen, selectedValues } = this.props;
    const { name, userTerms } = this.state;
    return (
      <AutoCompleteSuggestion
        textFieldWithTitle={true}
        textFieldTitle="Enter Medication/Supplement"
        // toolTipButton={true}
        // toolTipId={'tool-tip-med'}
        // toolTipContent={
        //   'Monitor your medications/supplements and find them as listed in Health Canada’s drug product database'
        // }
        fieldBg={FieldDetails.BGWHITE}
        remortSearch={true}
        url={url}
        processData={this.processData}
        triggerLength={3}
        //  validateName={validateName}
        onChangeText={this.onChangeTextName}
        onItemPress={this.onItemPressName}
        currentInput={name}
        onPressCustom={this.onPressCustomName}
        customSectionTitle={'Create custom'}
        showCustomSection={true}
        autoFocus={true}
        userTerms={userTerms}
        updateAutocompleteOpen={updateAutocompleteOpen}
        selectedValues={selectedValues}
        userListTitle={'Select from list of existing Medications/Supplements'}
        systemListTitel={'Select from suggested Meds/Supplements'}
        disabledScroll={this.props.disabledScroll}
        // fieldBg={FieldDetails.BGWHITE}
        // fieldLt={true}
        // toolTipButton={true}
        // toolTipId={"tool-tip-medication"}
        // toolTipContent={""}
        clearTextOnBlur={true}
        medication={"med"}
        type={autoSuggestionListType.medication}
        zoomIcon={true}
        removeIcon={true}
        autoDisplayUserList={true}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    treatments: state.routines.treatments,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddRoutineDetails);
