import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity, TextInput } from "react-native";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive"
import PageContainer from "../../../components/PageLayout/PageContainer";
import { pendoTrackEvent } from "../../../util/pendoConfig";

class MyResourcesHeader extends Component {
  
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (   
      <React.Fragment>
        <PageContainer smScreenFull={true}>
          <View>
              {/* {this.props.resourcesCount || this.props.resourcesCount === 0 ? (
                <View style={[styles.colPdTopLtRt]}>
                  <View style={[styles.titleArea]}>
                    <View style={[styles.flexRowJcCtAiCt, styles.comDescView]}>
                      <View style={[styles.comIconArea]}>
                        <Image
                          source={require("../../../assets/community/community-group.png")}
                          style={styles.communityResourceIcon}
                        />
                      </View>
                      <View style={[styles.comDescSec]}>
                        <Text
                          style={[styles.textTitleBold, styles.textAlignCenter]}
                          allowFontScaling={false}
                        >
                          {this.props.newResourcesCount > 0  ? `You have ${this.props.newResourcesCount} new resources!` : `You have ${this.props.resourcesCount} resources in your Library`}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              ) : null} */}
              <View style={[
                styles.flexRow,
                styles.flexJcSb,
                styles.flexAiCt,
                styles.mgTopLg,
                styles.setupBtnArea
              ]}>
                <View>
                  <Text style={[styles.textTitleMdBold, styles.txtGreyHighlightCr]}> 
                    Resources Library
                  </Text>
                </View>
                <View>
                  <TouchableOpacity onPress={this.props.handleResourceModalShow}>
                  <View style={styles.newResourceHealthLbBtn}>
                      <Text style={[styles.textPrimeBold, styles.textWhite]}>
                      + New Resource
                      </Text>
                  </View>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.secArea}>
                <View style={[styles.colPdTopLtRt]}>
                  <View style={[styles.formInnerArea, styles.comMgBotSm]}>
                    <View style={[styles.fieldRow, styles.fieldRowNoMg]}>
                      <TextInput
                        style={[
                          styles.textField,
                          styles.textFieldWhite,
                          styles.textFieldSm,
                          styles.fieldRtPdSpec
                        ]}
                        allowFontScaling={false}
                        placeholder="Search my resources"
                        onChangeText={
                          this.props.handleSearchMyResourceInputValueChange
                        }
                        //autoFocus={this.props.emailVerified ? true : false}
                        value={this.props.keywords}
                        underlineColorAndroid="transparent"
                        nativeID={'healthLibraryMyResourcesSearch'}
                        onfocus={() => pendoTrackEvent('HealthLibrary > @MyResources > Search')}
                      />
                      <View style={styles.healthLibrarySearchIcnPlc}>
                        <Image
                          style={styles.searchZoomIconRound}
                          source={require('../../../assets/icons/search-icon-round.png')}
                        />
                      </View>
                    </View>
                    {/* <View style={styles.btnArea}>
                      <View style={styles.btnBlock}>
                        <TouchableOpacity
                          onPress={this.props.handleResourceModalShow}
                        >
                          <View
                            style={[
                              styles.submitSub,
                              styles.submitBgOrange,
                              styles.flexRow,
                              styles.flexJcCtAiCt,
                            ]}
                            nativeID={'healthLibraryMyResourcesAddaResource'}
                          >
                            <View
                              style={[styles.moewIconSec, styles.rightSpSm]}
                            >
                              <Image
                                style={styles.moreIcon}
                                source={require("../../../assets/icons/more-white.png")}
                              />
                            </View>

                            <Text
                              style={[
                                styles.submitText,
                                styles.submitTextSm,
                                styles.bold,
                              ]}
                              allowFontScaling={false}
                            >
                              Add a resource
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View> */}
                  </View>
                </View>

                {/* resource info */}
                <View>
                {this.props.resourcesCount === 0 ? (

                <View style={[styles.flexAiCt, styles.flexJcCt, styles.centrTxtAlgn, styles.comMgTopExLg]}>
                  <View>
                    <Text style={[styles.textTitle, styles.textColorGreyLight, styles.textEm, styles.textWeightFiveHundred]}>
                      Welcome to the Health Library!
                    </Text>
                  </View>
                  <View style={[styles.mgTopLg, styles.comSpMd]}>
                      <Image
                        source={require("../../../assets/community/community-group.png")}
                        style={styles.communityResourceIcon}
                      />
                  </View>
                  <View style={stylesRes.resourceTextAlignNull} dataSet={{media: ids.resourceTextAlignNull}}>
                    <Text style={[styles.textTitle, styles.textColorGreyLight, styles.textEm, styles.textWeightFiveHundred, styles.textAlignCenter]}>
                    Click the blue button above to add and save resources.  
                    </Text>
                  </View>
                </View>
                  
                ) :
              
                <View style={[styles.mgLtTwenty, styles.lblSp, styles.comSpMd]}>
                    <Text
                      style={[styles.textTitle, styles.textWeightFiveHundred, styles.textEm, styles.resourceBlueTxt]}
                      allowFontScaling={false}
                    >
                    {this.props.newResourcesCount > 0  ? `You have ${this.props.newResourcesCount} new resources!` : `You have ${this.props.resourcesCount} resources in your Library`}

                    </Text>
                </View>
                }
                </View>
                
              </View>
            </View>
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default (MyResourcesHeader);
