import React from 'react';
import {View, Image, TouchableOpacity, Text} from 'react-native';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import {CAREGIVERS} from '../../../navigation/path';
import { pendoTrackEvent } from '../../../util/pendoConfig';
import CaregiverDetail from './CaregiverDetail';
import NestedScroll from "../../NestedScroll/nestedScroll";

const caregiverList = props => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  const {
    sharedAccessGivenToMe,
    sharedAccessUserSummaries,
    sharedAccessDigestProcessed,
    changeJournalUser,
    setUpOwnJournal,
    myDetail,
    navigation,
    showAdd,
    journalUserId,
    showAddCareiver,
    handleClose
  } = props;
  const {userId = '', name = ''} = myDetail ? myDetail : {};
  return (
    <View 
      style={[stylesRes.switchProfileWrapper, stylesRes.switchProfileFixed]}>
      <View 
        nativeID={'headerSwitchProfileCareGivers'}
        style={[styles.switchMenuDetails, styles.switchBgLight, styles.popoverBgBorder]}>
        <NestedScroll>
        {!showAdd &&
          <CaregiverDetail
            name={'My Profile'}
            uId={userId}
            changeJournalUser={setUpOwnJournal}
            key={userId}
            myName={name}
          />
        }
        {sharedAccessGivenToMe &&
          sharedAccessGivenToMe.length > 0 &&
          sharedAccessGivenToMe.map(
            item =>
              sharedAccessUserSummaries &&
              sharedAccessUserSummaries.hasOwnProperty(item.grantingUser) &&
              item.grantingUser !== journalUserId &&
              (item.shareAccessStatus === null ||
                item.shareAccessStatus === 'CONNECTED') && (
                <CaregiverDetail
                  name={
                    sharedAccessDigestProcessed?.sharedAccessUserSummaries?.[
                      item.grantingUser
                    ]?.name
                  }
                  uId={item.grantingUser}
                  changeJournalUser={() => changeJournalUser(item.grantingUser)}
                  key={item.grantingUser}
                />
              ),
          )}

          {(Boolean(showAdd) && Boolean(showAddCareiver)) && (
          <View style={[styles.switchMenuRow, styles.secSepBorderBot]}>
            <TouchableOpacity
              onPress={() => {
                handleClose();
                navigation.reset({
                  index: 0,
                  routes: [{ name: CAREGIVERS.inviteCaregiverPath }],
                });
                pendoTrackEvent('Header > SwitchProfile > AddCaregiver');
              }}>
              <View 
                nativeID={'headerSwitchProfileAddCareGiver'}
                style={[styles.headerViewComCont, styles.switchMenuPd]}>
                <View style={[styles.flexRow, styles.menuListLast]}>
                  <View style={[styles.headerViewIcon, styles.flexJcCtAiCt]} >
                    <View style={styles.smPlusIconViewLg}>
                      <Image
                        style={styles.smPlusIcon}
                        source={require('../../../assets/icons/plus.png')}
                      />
                    </View>
                  </View>
                  <View style={styles.headerViewTextCom}>
                    <Text style={[styles.textPrimeMdBold, styles.textBlueDark]} allowFontScaling={false}>
                      Add Caregiver
                    </Text>
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          </View>
        )}
        </NestedScroll>
      </View>
    </View>
  );
};

export default (caregiverList);
