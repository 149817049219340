import React from "react";
import { View, TouchableOpacity, Text, Image } from "react-native";
import styles from "../../../Css";
import {getConnectionStatus} from '../../../util/commonUiLogic';

const statusButton = (props) => {
  const {
    btnStatus,
    onPressConnect,
    windowWidth,
    displaySendIcon,
    onPressSend,
    foundChatOnList,
  } = props;
  
  let btnText = getConnectionStatus(btnStatus);

  return (
    <>
      <View style={styles.itemColBtnSp} />
      {(!btnStatus || btnText === 'Connect') ? (
        <View style={styles.itemColBtn}>
          <TouchableOpacity onPress={onPressConnect}>
            <View
              style={[
                styles.submitSubSm,
                styles.flexRow,
                styles.flexJcCtAiCt,
                styles.connectBtn,
              ]}
            >
              <Text
                style={[
                  // styles.textPrime,
                  windowWidth > 1366
                  ? styles.textPrime
                  : styles.textPrimeMd,
                  styles.btnTextWhite,
                ]}
                allowFontScaling= {false}
              >
                {btnText}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      ) : (
        <>
        {displaySendIcon ? (
          <View style={[styles.itemColBtnMd]}>
            <View style={[styles.statusBtnCom, styles.flexJcCt, styles.flexAiFe]}>
              <TouchableOpacity onPress={onPressSend} disabled={!Boolean(foundChatOnList)}>
                <View style={[styles.flexJcCtAiCt, styles.submitBtnTouch, !Boolean(foundChatOnList) && {opacity: 0.5}]}>
                  <Image
                    style={[styles.submitBtnArrow]}
                    source={require("../../../assets/peer-to-peer/button-next.png")}
                  />
                </View>
              </TouchableOpacity>
            </View>
          </View>
        ) : (
          <View style={[styles.itemColBtnSm]}>
            <View style={[styles.statusBtnCom, styles.flexJcCt, styles.flexAiFe]}>
              <Text
                style={[
                  windowWidth > 1366 ? styles.textTitle : styles.textPrimeMd,
                  styles.textAlignRight,
                  btnText === "Connected"
                    ? styles.userConnectedText
                    : styles.userPendingText,
                ]}
                allowFontScaling= {false}
              >
                {btnText}
              </Text>
            </View>
          </View>
        )}
      </>
      )}
    </>
  );
};

export default (statusButton);
