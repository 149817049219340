import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Login from './Login';
import {OTHER, DASHBOARD} from '../../navigation/path';
import { pendoTrackEvent } from '../../util/pendoConfig';

class LoginScreen extends Component {

  constructor(props) {
    super(props);
    let params = props.route.params;
    this.state = {
      userEmail: params?.email
    }
  }

  componentDidMount = () => {
    let params = this.props.route.params;
    const token = params?.token;
    const reminderDateTime = params?.reminder;
    let tokenType = null;
    if (this.props.route.name.includes('accept')) {
      tokenType = 'accept';
    } else if (
      this.props.route.name.includes('update-routine') &&
      this.props.token !== token
    ) {
      tokenType = 'update-routine';
    } else if (
      this.props.route.name.includes('create-journal') &&
      this.props.token !== token
    ) {
      tokenType = 'create-journal';
    } else if (
      this.props.route.name.includes('survey-questions') &&
      this.props.token !== token
    ) {
      tokenType = 'survey-questions';
    } else if (this.props.route.name.includes('joinstudy')) {
      this.props.setJoinStudyData(params);
    }

    if (tokenType && this.props.tokenHistory !== tokenType) {
      this.props.setToken(token, tokenType, Boolean(reminderDateTime) ? parseInt(reminderDateTime) : null);  
    }
    pendoTrackEvent('Login@');
  };

  onClickForgotPassword = () => {
    this.props.navigation.push(OTHER.forgotPasswordPath);
  };

  onSignup = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{name: OTHER.signupPath}],
    });
  };

  onLoginSuccess = () => {
    const query = new URLSearchParams(this.props.route.params);
    //if code is avaliable that means this is for the email verification, we should
    //not reidirect to dashboard if its email verification
    if(!query.get('code')) {
      this.props.navigation.push(DASHBOARD.path);
    }
  };

  render() {
    return (
      <Login
        handleOnSignup={this.onSignup}
        onLoginSuccess={this.onLoginSuccess}
        onClickForgotPassword={this.onClickForgotPassword}
        email={this.state.userEmail}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    errorDescription: state.auth.errorDescription,
    error: state.auth.error,
    tokenHistory: state.uistate.tokenHistory,
    tokenTypeHistory: state.uistate.tokenTypeHistory,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (emailAddress, password, platform, callBack) =>
      dispatch(actions.auth(emailAddress, password, platform, callBack)),
    onFetachAllJournals: callBack =>
      dispatch(actions.fetchAllJournalEntries(callBack)),
    onSaveQuestionToAttach: question =>
      dispatch(actions.saveQuestionToAttach(question)),
    onLoginButtonPress: () => dispatch(actions.loginButtonPress()),
    onWindowResize: (windowHeight, windowWidth) =>
      dispatch(actions.windowResize(windowHeight, windowWidth)),
    setToken: (token, type, reminderDateTime) => dispatch(actions.setToken(token, type, reminderDateTime)),
    setJoinStudyData: (data) => dispatch(actions.setJoinStudyData(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginScreen);
