import React, { Component } from 'react';
import { View, Text } from 'react-native';
import styles from '../../../../Css';
import { connect } from 'react-redux';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextInputPlusMinus from '../../../TextInput/TextInputPlusMinus';
import TextPickerFrame from '../../../TextInput/textPickerFrame';
import * as actions from '../../../../store/actions/index';
import ItemDeleteButton from '../../../Button/itemDeleteButton';
import TextFrame from '../../../TextInput/textFrame';
import Warning from '../../../Warning/warning';
import { verifyDuplicateMedication } from '../../../../util/commonUiLogic';
class SystemItem extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    const quanity =
      data.quantity ? data.quantity : (data.dosages[0]?.quantity || data.dosages[0]?.quantity === 0) ? data.dosages[0]?.quantity : null;
    const unitValue = data.units ? data.units : data.dosages[0]?.units ? data.dosages[0]?.units : 'minutes';
    this.state = {
      ...data,
      accept: true,
      wait: false,
      units: unitValue,
      slider: true,
      quantity: quanity,
      duplicatePopup: false,
      unitValueChanging: false,
    };
    setTimeout(() => {
      this.verifyDuplicateMedication(data.name, unitValue, quanity);
    }, 0)
  }


  static getDerivedStateFromProps(props, state) {
    const { data, isNew, type } = props;

    const quantity = data.quantity ? data.quantity : (data.dosages[0]?.quantity || data.dosages[0]?.quantity === 0) ? data.dosages[0]?.quantity : null;
    const unit =
      data && data.dosages && data.dosages[0].units
        ? data.dosages[0].units
        : 'minutes';

    if ((!state.unitValueChanging && quantity !== state.quanity) || unit !== state.units) {
      return {
        quantity: quantity,
        // units: unit,
        slider: unit === 'minutes' ? true : false,
      };
    }

    return null;
  }

  onChange = val => {
    const { units } = this.state;
    const { updateActivity, data, index } = this.props;

      this.setState(
        { quantity: val, accept: val || val === '0' || val === 0 ? true : false },
        () => {
          updateActivity(index, {
            ...data,
            dosages: [
              { ...data.dosages[Object.keys(data.dosages)[0]], quantity: val },
            ],
            userDosingLog: { ...data.userDosingLog, quantity: val },
            accept: val || val === '0' || val === 0 ? true : false,
          });
          this.verifyDuplicateMedication(data.name, units, val);
        },
      );
  };

  onValueChangeUnit = val => {
    let { quantity } = this.state;
    const { data, index, updateActivity } = this.props;

    this.setState(
      {
        units: val,
        quantity: quantity,
        slider: val === 'minutes' ? true : false,
        unitValueChanging: true,
      },
      () => {
        updateActivity(index, {
          ...data,
          dosages: [
            { ...data.dosages[Object.keys(data.dosages)[0]], units: val },
          ],
          userDosingLog: {
            ...data.userDosingLog,
            units: val,
            quantity: quantity,
          },
        });
        this.verifyDuplicateMedication(data.name, val, quantity);
        this.setState({ unitValueChanging: false });
      },
    );
  };

  onAccept = () => {
    const { accept, quantity } = this.state;
    if (quantity || quantity === 0) {
      //&& quantity  !== '0' remove for fixed CR-1538
      const { updateActivity, index, data } = this.props;
      this.setState({ accept: !accept }, () =>
        updateActivity(index, {
          ...data,
          accept: !accept,
        }),
      );
    }
  };

  verifyDuplicateMedication = (name, unit, quantity) => {
    const { listTherapie, hasInvalidNamesInActivity, hasInvalidNamesInMedication, hasInvalidNamesInHealthData,
      hasInvalidNamesInSymtoms } = this.props;
    verifyDuplicateMedication(name, null, unit, quantity, listTherapie, (exist, msg) => {
      if (exist) {
        this.setState(
          {
            duplicatePopup: true,
            errorDescription: msg,
          },
          () => {
            this.props.saveDisable(true);
          },
        );
      } else {
        this.setState(
          {
            duplicatePopup: false,
            errorDescription: null,
          },
          () => {
            if (!hasInvalidNamesInActivity && !hasInvalidNamesInMedication && !hasInvalidNamesInHealthData && !hasInvalidNamesInSymtoms) {
              this.props.saveDisable(false);

            } else {
              this.props.saveDisable(true);

            }
          },
        );
      }
    });
  };

  changeActivityName = val => {
    const { updateActivity, index, data } = this.props;
    const { units, quantity } = this.state;
    this.setState(
      () => {
        updateActivity(index, {
          ...data,
          name: val,
        });
        this.verifyDuplicateMedication(val, units, quantity);
      }
    );
  }

  render() {
    const {
      quantity,
      units,
      duplicatePopup,
      errorDescription,
    } = this.state;
    const { data, deleteItem, index } = this.props;
    const { name, type } = data;
    let category = this.props.type;

    return (
      <>
        <View style={category !== 'RT' ? [styles.ActivitySliderCardView, styles.bgDarkAsh] :
          [styles.fieldContainer, styles.borderTpLtRadius, styles.borderTpRtRadius, styles.borderBtLtRadius, styles.borderBtRtRadius, styles.bgDarkAsh, styles.brdWdZero]}>
          <View>
            {category !== 'RT' && <View style={[styles.basisStyle]} />}
            {category !== 'RT' && type === 'SYSTEM' ?
              <View style={[styles.iconLtSp, styles.pdTopSm]}>
                <Text style={[styles.textPrimeBold, styles.textBlack, styles.rightSpExLg, { alignItems: 'baseline' },
                  // publishedBy === 'PARTNER' && styles.mgTpTwelve --- ZI-4049 - issue fixed by this comment
                ]}>
                  {`${name}`}
                </Text>
              </View>
              :
              <View style={[styles.fieldRowWrapper, styles.attachmentCont, styles.mgTopLg]}>
                <View style={styles.fieldColStretch}>
                  <TextFrame
                    title={'Activity'}
                    fieldBg={data.type === 'CUSTOM' ? (name === '' || name === null || name === undefined) && FieldDetails.BGRED : FieldDetails.BGGREY}
                    fieldValue={name}
                    noOfLines={1}
                    onChangeText={this.changeActivityName}
                    editable={data.type === 'CUSTOM' ? true : false}
                    darkTxt={true}
                  />
                </View>
                <View style={styles.fieldColSp} />
                <View style={styles.fieldColDelete}>
                  <ItemDeleteButton touchableOnPress={() => deleteItem(index)} />
                </View>
              </View>
            }
          </View>
          <View style={type === 'CUSTOM ' && { marginRight: 36 }}>
            <View style={[styles.iconLtSp, { marginBottom: 20 }, category === 'RT' && [styles.barBotSp, { marginTop: 10 }]]}>
              {
                category === 'RT' ?
                  <Text style={[styles.textPrime, styles.textBlack, styles.tpFourteen]}>
                    {category !== 'RT' ? 'My progress:' : 'Set goal:'}
                  </Text> : null
              }
              <View style={[styles.flexRow, styles.mgTopLg, category !== 'RT' && styles.rightSpSm]}>
                <View>
                  <TextInputPlusMinus
                    title={'Quantity'}
                    fieldBg={FieldDetails.BGWHITE}
                    fieldAlign={FieldDetails.ALIGNCENTER}
                    fieldLt={false}
                    darkTxt={true}
                    value={quantity}
                    onChange={this.onChange}
                    type={'ACTIVITY'}
                    units={units}
                    // max={units === 'minutes' ? 120 : null}
                    max={null}
                    activitySlider={true}
                  />
                </View>
                <View style={styles.wdTen}></View>
                <View style={category !== 'RT' && [styles.fieldColStretch, type !== 'CUSTOM ' && styles.rightSpSm]}>
                  <TextPickerFrame
                    title={'Measure'}
                    fieldBg={FieldDetails.BGWHITE}
                    fieldLt={false}
                    darkTxt={true}
                    items={[
                      { key: 'hours', value: 'hours', label: 'hours' },
                      { key: 'minutes', value: 'minutes', label: 'minutes' },
                      {
                        key: 'repetitions',
                        value: 'repetitions',
                        label: 'repetitions',
                      },
                      { key: 'sessions', value: 'sessions', label: 'sessions' },
                    ]}
                    selectedValue={units}
                    onValueChange={this.onValueChangeUnit}
                    activitySlider={false}
                  />
                </View>
                {category !== 'RT' && type === 'SYSTEM' ? (
                  <View style={[styles.fieldColDelete, styles.flexJcCt]}>
                    <View style={styles.fieldColDelete}>
                      <ItemDeleteButton touchableOnPress={() => deleteItem(index)} />
                    </View>
                  </View>
                ) : <View style={{ marginRight: 36 }} />}
              </View>
            </View>
            {/* <View style={styles.fieldColSp} /> */}
          </View>
          {Boolean(duplicatePopup) && (
            <>
              <Warning
                errorDescription={errorDescription}
                textPosition={'left'}
                warningBlockSp={category !== 'RT' ? true : false}
                warningBlockMg={true}
                journalTreatmentWarning={true}
              />
            </>
          )}
        </View>
        <View style={[styles.secSepBorderBot, styles.comSp, styles.titleTopPd]} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    listTherapie: state.routines.treatments,
    hasInvalidNamesInActivity: state.therapie.hasActivityInvalidName,
    hasInvalidNamesInMedication: state.therapie.hasMedicationInvalidName,
    hasInvalidNamesInHealthData: state.therapie.hasHealthDataInvalidName,
    hasInvalidNamesInSymtoms: state.therapie.hasSymptomsInvalidName,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.updateTherapie(addNewTherapie, callBack, journalUserId)),
    displayProperties: (displayPrope, journalUserId, callBack) =>
      dispatch(
        actions.updateGraphDisplayProperties(
          displayPrope,
          journalUserId,
          callBack,
        ),
      ),
    saveDisable: flag => dispatch(actions.updatesaveDisable(flag)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemItem);
