import React from "react";
import { Text, View, TouchableOpacity, ScrollView } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import Modal from "../Modal/modal";
import UIText from "../Text/text";

const orderCompleteSubscription = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <Modal
      visible={props.visibleModal}
      onRequestClose={props.onRequestClose}
      onBackdropPress={props.onBackdropPress}
    >
        <View style={stylesRes.modalScrollWrapperCom} dataSet={{media: ids.modalScrollWrapperCom}}>
          <ScrollView style={styles.modalScroller}>
          <View style={[styles.popUpBlock, styles.bgWhite]}>
            <View style={styles.popUpContentArea}>
              <View style={styles.gridSectionPopup}>
                <View style={[styles.popUpContentPd]}>
                  <View style={stylesRes.modalContIn}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View
                          style={[styles.infoTitleBlock, styles.infoPdTopBot]}
                        >
                          <Text
                            style={[
                              styles.infoText,
                              styles.text,
                              styles.centerText,
                              styles.bold
                            ]}
                            allowFontScaling={false}
                          >
                            {/* {props.alertTitle} */}
                            Thank you for ordering the {props.type} premium
                            subscription.
                          </Text>
                        </View>
                        <View style={[styles.infoBlock, styles.infoPdBotSm]}>
                          <Text
                            style={[
                              styles.infoText,
                              styles.text,
                              styles.centerText
                            ]}
                            allowFontScaling={false}
                          >
                            Your order number is: {props.orderNumber}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.btnArea}>
                          <View style={stylesRes.btnViewSecSm} dataSet={{media: ids.btnViewSecSm}}>
                            <View style={styles.fieldBtn}>
                              <View style={[styles.btnBlock, styles.noMgTop]}>
                                <TouchableOpacity
                                  style={styles.submit}
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={() => {
                                    props.ok();
                                  }}
                                >
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent
                                      ]}
                                    >
                                      {/* {props.deleteWait && (
                                        <View style={styles.actIndSm}>
                                          <ActivityIndicator size="small" />
                                        </View>
                                      )} */}
                                      <UIText
                                        style={[
                                          styles.submitText,
                                          styles.submitTextSm
                                        ]}
                                        title="OK"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};
export default (orderCompleteSubscription);
