import React from 'react';
import {View, Text, Platform} from 'react-native';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import ColorItemField from '../../../components/ColorPicker/ColorItemField';
import {chartTypes} from '../../../Constant/index';
import PickerTemplate from '../../../components/Picker/pickerTemplate';
import Picker from '../../../components/Picker/picker';
import { WEB } from '../../../util/platformWindowConfig';


const GraphPropertySelector = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <View style={[styles.gridRow, styles.zIndexMin]}>
      <View style={styles.gridColFull}>
        <View style={styles.secArea}>
          <View style={[styles.secDetailTitleBlock, styles.colPdLtRt]}>
            <View style={styles.spComTopBot}>
              <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                Graph Properties:
              </Text>
            </View>
          </View>
          <View style={[styles.secBgCom, styles.secDetailBlock]}>
            <View style={stylesRes.secConPdLtRt} dataSet={{media: ids.secConPdLtRt}}>
              <View style={[styles.secBgCom, styles.comMgTop]}>
                <View style={[styles.fieldRow]}>
                  <View style={[styles.horzFieldFull]}>
                    <View style={[styles.horzFieldTitle]}>
                      <Text style={[styles.textPrime]} allowFontScaling={false}>
                        {props.isEdit ? 'Choose style & colour:' : 'Style & colour:'}
                      </Text>
                    </View>

                    {/* New Cr */}
                    <View style={[styles.horzFieldValue, styles.answerView]}>
                      <View style={[styles.grapdhStyleLtPd]}>
                        <View style={[styles.fieldInRow]}>
                          <View style={stylesRes.toggleBtnView} dataSet={{media: ids.toggleBtnView}}>
                            <PickerTemplate>
                              <Picker
                                items={[
                                  {
                                    label: '▐ ▖Bar',
                                    value: chartTypes.bar,
                                    key: '1',
                                  },
                                  {
                                    label: '╱ Line',
                                    value: chartTypes.line,
                                    key: '2',
                                  },
                                  {
                                    label: '╌ Dash',
                                    value: chartTypes.dash,
                                    key: '3',
                                  },
                                ]}
                                style={[
                                  styles.pickerAreaField,
                                  styles.textPickerGraph,
                                  Platform.OS === WEB
                                    ? styles.pickerWidthWeb
                                    : styles.pickerWidth,
                                ]}
                                pickerFrame={'PickerFrameGraphStyles'}
                                selectedValue={props.graphStyle}
                                onValueChange={props.onChangeChartType}
                                disabled={props.disabledGraphStyle}
                              />
                            </PickerTemplate>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.colorBoxListView,
                          styles.colorBoxLeft,
                          styles.grapdhStyleLtPd,
                        ]}>
                        <ColorItemField
                          handleChangeComplete={props.onChangeColor}
                          color={props.color}
                          disabled={props.disabledColorPicker}
                          changedPickerColor={props.changedPickerColor}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default (GraphPropertySelector);