import React, {Component} from 'react';
import {connect} from 'react-redux';
import ProfileImage from '../Card/profileImage';
import {View, TouchableOpacity, Text} from 'react-native';
import styles from '../../../Css';
import DateItem from './dateItem';
import {getDateText} from '../../../util/commonUiLogic';
import Highlighter from '../../Highlighter/highlighter';

class CardItem extends Component {
  constructor(props) {
    super(props);
    const {windowHeight, windowWidth} = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    };
  }

  render() {
    const {
      windowWidth,
      onPressItem,
      displayUserNameOnly,
      item,
      sid,
      searchedKeyword,
    } = this.props;
    const {
      userName,
      profileImageS3SignedUrl,
      name,
      lastMessageTime,
      lastMessage,
      author,
      newText,
      status
    } = item;

    return (
      <View style={[styles.cardItemSingle]}>
        <TouchableOpacity onPress={() => onPressItem(item)}>
          <View
            style={[
              styles.flexRow,
              styles.cardItemDetails,
            ]}>
            {!Boolean(displayUserNameOnly) && Boolean(newText) && (
              <View style={styles.unReadMessageWrapper}>
                <View style={styles.unReadMessageWrapperIcon} />
              </View>
            )}
            <View style={styles.itemColImg}>
              <ProfileImage
                userName={displayUserNameOnly ? userName : name}
                userImage={profileImageS3SignedUrl}
                displayOnlineOffline={displayUserNameOnly ? false : status}
                sid={sid}
              />
            </View>
            <View style={styles.itemColSp} />

            {/* Only for UserName */}
            {displayUserNameOnly ? (
              <View style={styles.itemColStretch}>
                <View
                  style={[
                    styles.cardItemContent,
                    styles.flexJcCt,
                    styles.flexCom,
                  ]}>
                  <View style={[styles.cardItemTitle]}>
                    <Text
                      style={
                        windowWidth > 1366
                          ? styles.textTitleBold
                          : styles.textPrimeBold
                      }
                      allowFontScaling= {false}
                      >
                      {userName}
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <>
                <View style={styles.itemColStretch}>
                  <View style={styles.cardItemContent}>
                    <View style={[styles.cardItemTitle]}>
                      <Text
                        style={[
                          windowWidth > 1366
                            ? newText ? styles.textTitleBold : styles.textTitle
                            : newText ? styles.textPrimeBold : styles.textPrime
                        ]}
                        allowFontScaling= {false}
                        >
                        {name}
                      </Text>
                        {Boolean(searchedKeyword) ? (
                        <Highlighter
                          style={[
                            windowWidth > 1366
                              ? styles.textPrime
                              : styles.textPrimeSm,
                            newText
                              ? styles.lastMessageUnRead
                              : styles.defaultText,
                          ]}
                          searchWords={searchedKeyword}
                          textToHighlight={lastMessage}
                          numberOfLines={2}
                          nativeID={'textTruncate'}
                        />
                      ) : (
                        <Text
                          style={[
                            windowWidth > 1366
                              ? styles.textPrime
                              : styles.textPrimeSm,
                            newText
                              ? styles.lastMessageUnRead
                              : styles.defaultText,
                          ]}
                          allowFontScaling={false}
                          numberOfLines={2}
                          nativeID={'textTruncate'}>
                          {lastMessage}
                        </Text>
                      )}
                    </View>
                  </View>
                </View>

                <DateItem
                  isNewText={newText}
                  date={getDateText(lastMessageTime)}
                />
              </>
            )}
          </View>
        </TouchableOpacity>
        <View style={[styles.flexRow]}>
          <View style={styles.itemColImgSp} />
          <View style={[styles.itemColBorder, styles.greyBorderThin]} />
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(
  mapStateToProps,
  null,
)(CardItem);
