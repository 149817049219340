// Auth URLs
export const AUTH_START = 'AUTH_START';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const KEEP_ALIVE = 'KEEP_ALIVE';
export const LOGIN_PRESS = 'LOGIN_PRESS';
export const IS_FROM_LOGIN = 'IS_FROM_LOGIN';
export const LOGIN_WITH_TOUCH_ID = 'LOGIN_WITH_TOUCH_ID';
export const SET_EMAIL_VERIFIED = 'SET_EMAIL_VERIFIED';
export const SET_MFA_ENABLE = 'SET_MFA_ENABLE';
export const MOVE_TO_EMAIL_VERIFICATION_PHASE =
  'MOVE_TO_EMAIL_VERIFICATION_PHASE';

//Sign Up
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

//setup
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_CONDITION = 'UPDATE_CONDITION';
export const UPDATE_TREATMENT = 'CREATE_TREATMENT';
export const UPDATE_SYMPTOM = 'CREATE_SYMPTOM';
export const UPDATE_METRIC = 'UPDATE_METRIC';
export const UPDATE_ROUTINE = 'UPDATE_ROUTINE';
export const UPDATE_CONDITION_NAME = 'UPDATE_CONDITION_NAME';
export const UPDATE_THERAPIE_NAME = 'UPDATE_THERAPIE_NAME';
export const UPDATE_SYMPTOM_NAME = 'UPDATE_SYMPTOM_NAME';
export const UPDATE_METRICS_NAME = 'UPDATE_METRICS_NAME';
export const RESET_SETUP_STATE = 'RESET_SETUP_STATE';
export const UPDATE_ADD_NEW_CUSTOM_SYMPTOM = 'UPDATE_ADD_NEW_CUSTOM_SYMPTOM';
export const SET_SETUP_ACTIVE_TAB = 'SET_SETUP_ACTIVE_TAB';
export const UPDATE_DATA_ADDED_FROM_RW = 'UPDATE_DATA_ADDED_FROM_RW';
export const UPDATE_ROUTINE_WIZARD_SAVE_DISABLE = 'UPDATE_ROUTINE_WIZARD_SAVE_DISABLE';

// Journals
export const CREATE_JOURNAL_ENTRY_BY_TYPE_SUCCESS =
  'CREATE_JOURNAL_ENTRY_BY_TYPE_SUCCESS';
export const CREATE_JOURNAL_ENTRY_BY_TYPE_FAIL =
  'CREATE_JOURNAL_ENTRY_BY_TYPE_FAIL';

export const DELETE_JOURNAL_ENTRY_SUCCESS = 'DELETE_JOURNAL_ENTRY_SUCCESS';
export const DELETE_JOURNAL_ENTRY_FAIL = 'DELETE_JOURNAL_ENTRY_FAIL';
export const DELETE_JOURNAL_ENTRY_START = 'DELETE_JOURNAL_ENTRY_START';

export const UPDATE_JOURNAL_ENTRY_SUCCESS = 'UPDATE_JOURNAL_ENTRY_SUCCESS';
export const UPDATE_JOURNAL_ENTRY_FAIL = 'UPDATE_JOURNAL_ENTRY_FAIL';

export const CLONE_JOURNAL_ENTRY_SUCCESS = 'CLONE_JOURNAL_ENTRY_SUCCESS';
export const CLONE_JOURNAL_ENTRY_FAIL = 'CLONE_JOURNAL_ENTRY_FAIL';

export const FETCH_JOURNAL_ENTRY_BY_TIME_RANGE_SUCCESS =
  'FETCH_JOURNAL_ENTRY_BY_TIME_RANGE_SUCCESS';
export const FETCH_JOURNAL_ENTRY_BY_TIME_RANGE_FAIL =
  'FETCH_JOURNAL_ENTRY_BY_TIME_RANGE_FAIL';

export const FETCH_ALL_JOURNAL_ENTRIES_SUCCESS =
  'FETCH_ALL_JOURNAL_ENTRIES_SUCCESS';
export const FETCH_ALL_JOURNAL_ENTRIES_FAIL = 'FETCH_ALL_JOURNAL_ENTRIES_FAIL';
export const FETCH_ALL_JOURNAL_ENTRIES_START =
  'FETCH_ALL_JOURNAL_ENTRIES_START';

export const DELETE_JOURNAL_ENTRY_TYPE_SUCCESS =
  'DELETE_JOURNAL_ENTRY_TYPE_SUCCESS';

export const UPDATING_JOURNAL_ENTRY_TYPE = 'updating_journal_entry_type';
export const UPDATE_JOURNAL_ENTRY_TYPE_SUCCESS =
  'UPDATE_JOURNAL_ENTRY_TYPE_SUCCESS';
export const UPDATE_JOURNAL_ENTRY_TYPE_FAIL = 'UPDATE_JOURNAL_ENTRY_TYPE_FAIL';

export const SET_JOURNAL_ENTRY_SELECTED = 'SET_JOURNAL_ENTRY_SELECTED';
export const SET_JOURNAL_ENTRY_SELECTED_SUCCESS =
  'SET_JOURNAL_ENTRY_SELECTED_SUCCESS';
export const SET_JOURNAL_ENTRY_TYPE_SELECTED =
  'SET_JOURNAL_ENTRY_TYPE_SELECTED';
export const CLEAR_JOURNAL_ENTRY_TYPE_SELECTED =
  'CLEAR_JOURNAL_ENTRY_TYPE_SELECTED';
export const SET_JOURNAL_ENTRY_SELECTED_START =
  'SET_JOURNAL_ENTRY_SELECTED_START';

export const SELECT_DATE = 'SELECT_DATE';

export const JOURNAL_ENTRY_FILTER = 'JOURNAL_ENTRY_FILTER';

export const JOURNAL_ENTRY_CLEAR_FILTER = 'JOURNAL_ENTRY_CLEAR_FILTER';
export const SETUP_ON_SUCCESS = 'SETUP_ON_SUCCESS';
export const SET_TOTAL_ATTACHMENT_SIZE = 'SET_TOTAL_ATTACHMENT_SIZE';

export const FETCH_IOS_SUBSCRIPTION_DETAILS_SUCCESS =
  'FETCH_IOS_SUBSCRIPTION_DETAILS_SUCCESS';
export const FETCH_IOS_SUBSCRIPTION_DETAILS_FAILURE =
  'FETCH_IOS_SUBSCRIPTION_DETAILS_FAILURE';

export const SEND_IOS_SUBSCRIPTION_DETAILS_SUCCESS =
  'SEND_IOS_SUBSCRIPTION_DETAILS_SUCCESS';
export const SEND_IOS_SUBSCRIPTION_DETAILS_FAILURE =
  'SEND_IOS_SUBSCRIPTION_DETAILS_FAILURE';
  
export const ON_TOUCH_ROUTINE = 'ON_TOUCH_ROUTINE';

// User Health Question
export const UPDATE_HEALTH_QUESTION_ANSWER_SUCCESS =
  'UPDATE_HEALTH_QUESTION_ANSWER_SUCCESS';
export const UPDATE_HEALTH_QUESTION_ANSWER_SUCCESS_NEW =
  ' UPDATE_HEALTH_QUESTION_ANSWER_SUCCESS_NEW';

export const UPDATE_HEALTH_QUESTION_ANSWER_FAIL =
  'UPDATE_HEALTH_QUESTION_ANSWER_FAIL';

export const UPDATE_HEALTH_QUESTION_SUCCESS = 'UPDATE_HEALTH_QUESTION_SUCCESS';
export const UPDATE_HEALTH_QUESTION_SUCCESS_NEW =
  'UPDATE_HEALTH_QUESTION_SUCCESS_NEW';
export const UPDATE_HEALTH_QUESTION_FAIL = 'UPDATE_HEALTH_QUESTION_FAIL';

export const FETCH_USER_ATTACHMENTS_SUCCESS = 'FETCH_USER_ATTACHMENTS_SUCCESS';
export const FETCH_USER_ATTACHMENTS_FAIL = 'FETCH_USER_ATTACHMENTS_FAIL';
export const FETCH_USER_ATTACHMENTS_START = 'FETCH_USER_ATTACHMENTS_START';

export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_FAIL = 'DELETE_ATTACHMENT_FAIL';
export const ADD_QUESTION = 'ADD_QUESTION';

export const FETCH_ALL_USER_JOURNAL_ENTRIES_SUCCESS =
  'FETCH_ALL_USER_JOURNAL_ENTRIES_SUCCESS';

//UI State

export const SELECT_MENU_ITEM = 'SELECT_MENU_ITEM';
export const DESELECT_MENU_ITEM = 'DESELECT_MENU_ITEM';
export const SIDE_DAWER_TOGGLE = 'SIDE_DAWER_TOGGLE';
export const MORE_MENU = 'MORE_MENU';
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS';
export const WINDOW_RESIZE = 'WINDOW_RESIZE';
export const SHOW_TUTORIAL = 'SHOW_TUTORIAL';
export const SET_JOURNAL_USER = 'SET_JOURNAL_USER';
export const SHOW_LEARN_HOW = 'SHOW_LEARN_HOW';
export const SET_TOKEN = 'SET_TOKEN';
export const UI_STATE_RESET = 'UI_STATE_RESET';
export const ROUTINE_REMINDERS = 'ROUTINE_REMINDERS';
export const SEEN_NOTIFICATION = 'SEEN_NOTIFICATION';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
export const CLEAR_ALL_NOTIFICATION = 'CLEAR_ALL_NOTIFICATION';
export const CLEAR_NOTIFICATION_BY_ID = 'CLEAR_NOTIFICATION_BY_ID';
export const CLEAR_MENU_SECTION_NOTIFICATIONS =
  'CLEAR_MENU_SECTION_NOTIFICATIONS';
export const SHOW_USER_LICENSE_AGREEMENT = 'SHOW_USER_LICENSE_AGREEMENT';
export const SHOW_PRIVACY_STATMENT = 'SHOW_PRIVACY_STATMENT';
export const SHOW_MOBILE_MENU = 'SHOW_MOBILE_MENU';
export const HIDE_MOBILE_MENU = 'HIDE_MOBILE_MENU';
export const ADD_NOTIFCATION = 'ADD_NOTIFCATION';
export const SET_DEVICE_MAIN_INFO = 'SET_DEVICE_MAIN_INFO';
export const SHOW_SUCCESS_POPUP = 'SHOW_SUCCESS_POPUP';
export const HIDE_SUCCESS_POPUP = 'HIDE_SUCCESS_POPUP';
export const SET_PROFILE_SECURITY_SHOW = 'SET_PROFILE_SECURITY_SHOW';
export const SHOW_HIDE_STORAGE_BANNER = 'SHOW_HIDE_STORAGE_BANNER';
export const SET_FROM_POPUP = 'SET_FROM_POPUP';
export const SET_FROM_NOTIF_ID = 'SET_FROM_NOTIF_ID';
export const SELECT_GRAPH_ITEM = 'SELECT_GRAPH_ITEM';
export const SET_PREVIOUS_GRAPH_ID = 'SET_PREVIOUS_GRAPH_ID';
export const SET_GRAPH_CHECKED = 'SET_GRAPH_CHECKED';
export const SET_DEVICE_FONT_SCALE = 'SET_DEVICE_FONT_SCALE';
export const TOGGLE_WATCH_OVERVIEW = 'TOGGLE_WATCH_OVERVIEW';
export const SET_TAB_MENU_ICON_VIEW = 'SET_TAB_MENU_ICON_VIEW';
export const SET_IS_TAB_DEVICE = 'SET_IS_TAB_DEVICE';
export const TOGGLE_SETUP_MODAL = 'TOGGLE_SETUP_MODAL';
export const TOGGLE_VERIFICATION_MAIL_SENT = 'TOGGLE_VERIFICATION_MAIL_SENT';
export const SIGNUP_EMAIL = 'SIGNUP_EMAIL';
export const SET_SHOW_MAPPED_DATA_POPUP_NEXT_TIME = 'SET_SHOW_MAPPED_DATA_POPUP_NEXT_TIME';
export const SET_JOIN_STUDY_DATA = 'SET_JOIN_STUDY_DATA';
export const SET_TAB_MENU_DISABLE = 'SET_TAB_MENU_DISABLE';
export const SET_ALERT_MODAL_VISIBLE = 'SET_ALERT_MODAL_VISIBLE';
export const UPDATE_DASHBOARD_ITEM_SHOW_HIDE = 'UPDATE_DASHBOARD_ITEM_SHOW_HIDE';

// Routine Template
export const CREATE_NEW_ROUTINE_SUCCESS = 'CREATE_NEW_ROUTINE_SUCCESS';
export const CREATE_NEW_ROUTINE_FAIL = 'CREATE_NEW_ROUTINE_FAIL';
export const CREATE_NEW_ROUTINE_TEMPLATE_SUCCESS =
  'CREATE_NEW_ROUTINE_TEMPLATE_SUCCESS';
export const CREATE_NEW_ROUTINE_TEMPLATE_FAIL =
  'CREATE_NEW_ROUTINE_TEMPLATE_FAIL';
export const SAVE_JOURNAL_ENTRY_SUCCESS = 'SAVE_JOURNAL_ENTRY_SUCCESS';
export const SAVE_JOURNAL_ENTRY__FAIL = 'SAVE_JOURNAL_ENTRY__FAIL';
export const DELETE_HEALTH_QUESTION_SUCCESS = 'DELETE_HEALTH_QUESTION_SUCCESS';
export const DELETE_HEALTH_QUESTION_FAIL = 'DELETE_HEALTH_QUESTION_FAIL';
export const EDIT_ROUTINE_TEMPLATE = 'EDIT_ROUTINE_TEMPLATE';
export const COPY_ROUTINE_TEMPLATE = 'COPY_ROUTINE_TEMPLATE';
export const SAVE_HEALTH_QUESTION_SUCCESS = 'SAVE_HEALTH_QUESTION_SUCCESS';
export const SAVE_HEALTH_QUESTION_FAIL = 'SAVE_HEALTH_QUESTION_FAIL';
export const UPDATE_JOURNAL_ENTRY_STATE = 'UPDATE_JOURNAL_ENTRY_STATE';
export const UPDATE_QUESTION_LIST = 'UPDATE_QUESTION_LIST';
export const UPDATE_TEMPORY_QUESTION_OBJECT = 'UPDATE_TEMPORY_QUESTION_OBJECT';
export const UPDATE_TEMPORY_ENTRY_OBJECT = 'UPDATE_TEMPORY_ENTRY_OBJECT';

// Device
export const CONNECT_DEVICE = 'CONNECT_DEVICE';
export const CONNECT_DEVICE_SUCCESS = 'CONNECT_DEVICE_SUCCESS';
export const CONNECT_DEVICE_FAIL = 'CONNECT_DEVICE_FAIL';

export const SET_DEVICE_MAP = 'SET_DEVICE_MAP';
export const UPDATE_DEVICE_MAP = 'UPDATE_DEVICE_MAP';

//UI therapie
export const ADD_THERAPIE_SUCCESS = 'ADD_THERAPIE_SUCCESS';
export const ADD_THERAPIE = 'ADD_THERAPIE';
export const UPDATE_THERAPIE = 'UPDATE_THERAPIE';
export const ADD_THERAPIE_FAIL = 'ADD_THERAPIE_FAIL';
export const DELETE_THERAPIE_SUCCESS = 'DELETE_THERAPIE_SUCCESS';
export const DELETE_THERAPIE_FAIL = 'DELETE_THERAPIE_FAIL';
export const UPDATE_THERAPIE_SUCCESS = 'UPDATE_THERAPIE_SUCCESS';
export const UPDATE_THERAPIE_FAIL = 'UPDATE_THERAPIE_FAIL';
export const UPDATE_GRAPH_DISPLAY_SUCCESS = 'UPDATE_GRAPH_DISPLAY_SUCCESS';
export const UPDATE_GRAPH_DISPLAY_FAIL = 'UPDATE_GRAPH_DISPLAY_FAIL';
export const SELECT_THERAPIE = 'SELECT_THERAPIE';
export const DELETE_THERAPIE_IN_PROGRESS = 'DELETE_THERAPIE_IN_PROGRESS';
export const SET_POPUP_TREATMENT = 'SET_POPUP_TREATMENT';
export const UPDATE_PREMIUM_BANNER_HIDE_SUCCESS =
  'UPDATE_PREMIUM_BANNER_HIDE_SUCCESS';
export const UPDATE_SAVE_DISABLE = 'UPDATE_SAVE_DISABLE';
export const HAS_ENTRY_CUSTOM_ACTIVITY_INVALID_NAMES = 'HAS_ENTRY_CUSTOM_ACTIVITY_INVALID_NAMES';
export const HAS_ENTRY_CUSTOM_MEDICATION_INVALID_NAMES = 'HAS_ENTRY_CUSTOM_MEDICATION_INVALID_NAMES';
export const HAS_ENTRY_CUSTOM_HEALTH_DATA_INVALID_NAMES = 'HAS_ENTRY_CUSTOM_HEALTH_DATA_INVALID_NAMES';
export const HAS_ENTRY_CUSTOM_SYMPTOMS_INVALID_NAMES = 'HAS_ENTRY_CUSTOM_SYMPTOMS_INVALID_NAMES';

export const HAS_ENTRY_CUSTOM_ACTIVITY_DUPLICATE = 'HAS_ENTRY_CUSTOM_ACTIVITY_DUPLICATE';
export const HAS_ENTRY_CUSTOM_MEDICATION_DUPLICATE = 'HAS_ENTRY_CUSTOM_MEDICATION_DUPLICATE';
export const RESET_DUPLICATES = 'RESET_DUPLICATES';
export const UPDATE_TEMP_CLRS = "UPDATE_TEMP_CLRS";
export const CLEAR_TEMP_CLRS = "CLEAR_TEMP_CLRS";
// export const EDIT_THERAPIE = "EDIT_THERAPIE";

//Contacts
export const SELECT_CONTACT = 'SELECT_CONTACT';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAIL = 'DELETE_CONTACT_FAIL';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_FAIL = 'UPDATE_CONTACT_FAIL';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT = 'ADD_CONTACT';
export const ADD_CONTACT_FAIL = 'ADD_CONTACT_FAIL';
export const DELETE_CONTACT_IN_PROGRESS = 'DELETE_CONTACT_IN_PROGRESS';

export const RESET_DATA_LOGOUT = 'RESET_DATA_LOGOUT';

export const UPLOAD_ATTACHMENTS_SUCCESS = 'UPLOAD_ATTACHMENTS_SUCCESS';

// Question
export const SAVE_QUESTION_TO_ATTACH = 'SAVE_QUESTION_TO_ATTACH';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const QUESTION_FILTER = 'QUESTION_FILTER';
export const QUESTION_CLEAR_FILTER = 'QUESTION_CLEAR_FILTER';

export const ADD_REMOVE_JOURNAL = 'ADD_REMOVE_JOURNAL';
export const REMOVE_ALL_JOURNAL = 'REMOVE_ALL_JOURNAL';

//ImageDoument
export const SAVE_FILES_TO_ATTACH = 'SAVE_FILES_TO_ATTACH';
export const CLEAR_FILES_TO_ATTACH = 'CLEAR_FILES_TO_ATTACH';
export const SAVE_UPLOADED_ATTACHMENTS = 'SAVE_UPLOADED_ATTACHMENTS';
export const CLEAR_UPLOADED_ATTACHMENTS = 'CLEAR_UPLOADED_ATTACHMENTS';
export const SET_ATTACHEMENT_UPLOAD_CURRENT_KEY = 'SET_ATTACHEMENT_UPLOAD_CURRENT_KEY';

//Profile
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';
export const UPDATE_PROFILE_JOURNAL_ENTRY = 'UPDATE_PROFILE_JOURNAL_ENTRY';
export const PROFILE_INIT_CONDITIONS = 'PROFILE_INIT_CONDITIONS';
export const PROFILE_UPDATE_CONDITION = 'PROFILE_UPDATE_CONDITION';
export const GET_TIME_ZONE_LIST = 'GET_TIME_ZONE_LIST';
export const GET_TIME_ZONE_LIST_SUCCESS = 'GET_TIME_ZONE_LIST_SUCCESS';
export const GET_TIME_ZONE_LIST_FAIL = 'GET_TIME_ZONE_LIST_FAIL';
export const UPDATE_TIME_ZONE = 'UPDATE_TIME_ZONE'
export const UPDATE_TIME_ZONE_SUCCESS = 'UPDATE_TIME_ZONE_SUCCESS'
export const UPDATE_TIME_ZONE_FAIL = 'UPDATE_TIME_ZONE_FAIL'
export const SET_SEEN_TIME_ZONE_WARNING = 'SET_SEEN_TIME_ZONE_WARNING';


//User Allergy
export const UPDATE_USER_ALLERGY = 'UPDATE_USER_ALLERGY';
export const DELETE_USER_ALLERGY = 'DELETE_USER_ALLERGY';

//User Conditions
export const UPDATE_USER_CONDITION = 'UPDATE_USER_CONDITIONS';
export const DELETE_USER_CONDITION = 'DELETE_USER_CONDITION';

//User Symptom
export const UPDATE_USER_SYMPTOM = 'UPDATE_USER_SYMPTOM';

//User Metric
export const UPDATE_USER_METRIC = 'UPDATE_USER_METRIC';

//Selectable Metric
export const UPDATE_SELECTABLE_METRIC = 'UPDATE_SELECTABLE_METRIC';

//Care giver access
export const SHARED_ACCESS_INVITATION_SUCCESS =
  'SHARED_ACCESS_INVITATION_SUCCESS';
export const DELETE_SHARED_ACCESS_INVITATION_SUCCESS =
  'DELETE_SHARED_ACCESS_INVITATION_SUCCESS';
export const UPDATE_SHARED_ACCESS_PERMISSION_SUCCESS =
  'UPDATE_SHARED_ACCESS_PERMISSION_SUCCESS';
export const DELETE_SHARED_ACCESS_PERMISSION_SUCCESS =
  'DELETE_SHARED_ACCESS_PERMISSION_SUCCESS';
export const SHARED_ACCESS_INVITATION_ACCEPT_SUCCESS =
  'SHARED_ACCESS_INVITATION_ACCEPT_SUCCESS';
export const SHARED_ACCESS_INVITATION_DECLINE_SUCCESS =
  'SHARED_ACCESS_INVITATION_DECLINE_SUCCESS';
export const SHARED_ACCESS_INVITATION_RESEND_SUCCESS =
  'SHARED_ACCESS_INVITATION_RESEND_SUCCESS';
export const DELETE_SHARED_ACCESS_PERMISSION_GIVEN_TO_ME_SUCCESS =
  'DELETE_SHARED_ACCESS_PERMISSION_GIVEN_TO_ME_SUCCESS';
export const SHARED_ACCESS_DISCONNECT_SUCCESS =
  'SHARED_ACCESS_DISCONNECT_SUCCESS';

//Settings - metrics
export const ADD_METRICS_SUCCESS = 'ADD_METRICS_SUCCESS';
export const ADD_METRICS_FAIL = 'ADD_METRICS_FAIL';
export const ADD_METRICS_UOM_SUCCESS = 'ADD_METRICS_UOM_SUCCESS';
export const SELECT_METRIC = 'SELECT_METRIC';
export const UPDATE_USER_METRICS = 'UPDATE_USER_METRICS';
export const UPDATE_USER_METRICS_UOM = 'UPDATE_USER_METRICS_UOM';
export const DELETE_METRIC_FAIL = 'DELETE_METRIC_FAIL';
export const DELETE_METRIC_SUCCESS = 'DELETE_METRIC_SUCCESS';

//Settings - Symptoms
export const UPDATE_USER_SYMPTOM_FAIL = 'UPDATE_USER_SYMPTOM_FAIL';
export const UPDATE_USER_SYMPTOM_SUCCESS = 'UPDATE_USER_SYMPTOM_SUCCESS';
export const ADD_USER_SYMPTOM = 'ADD_USER_SYMPTOM';
export const SELECT_SYMPTOM = 'SELECT_SYMPTOM';
export const DELETE_SYMPTOM_SUCCESS = 'DELETE_SYMPTOM_SUCCESS';
export const DELETE_SYMPTOM_FAIL = 'DELETE_SYMPTOM_FAIL';

export const COMMON_ERROR_HANDLER = 'COMMON_ERROR_HANDLER';
export const USER_DIGEST_ERROR = 'USER_DIGEST_ERROR';

// rolling graphs setting
export const UPDATE_GRAPH_NAME = 'UPDATE_GRAPH_NAME';
export const UPDATE_GRAPH_START_DATE = 'UPDATE_GRAPH_START_DATE';
export const UPDATE_GRAPH_END_DATE = 'UPDATE_GRAPH_END_DATE';
export const ROLLING_GRAPH_ADD_SYMPTOM = 'ROLLING_GRAPH_ADD_SYMPTOM';
export const ROLLING_GRAPH_REMOVE_SYMPTOM = 'ROLLING_GRAPH_REMOVE_SYMPTOM';
export const ROLLING_GRAPH_ADD_METRIC = 'ROLLING_GRAPH_ADD_METRIC';
export const ROLLING_GRAPH_REMOVE_METRIC = 'ROLLING_GRAPH_REMOVE_METRIC';
export const ROLLING_GRAPH_ADD_THERAPY = 'ROLLING_GRAPH_ADD_THERAPY';
export const ROLLING_GRAPH_REMOVE_THERAPY = 'ROLLING_GRAPH_REMOVE_THERAPY';
export const UPDATE_DASHBOARD_GRAPH_SUCCESS = 'UPDATE_DASHBOARD_GRAPH';
export const DELETE_DASHBOARD_GRAPH_SUCCESS = 'DELETE_DASHBOARD_GRAPH';
export const SELECT_ROLLING_GRAPH = 'SELECT_ROLLING_GRAPH';
export const RESET_ROLLING_GRAPH = 'RESET_ROLLING_GRAPH';
export const HIDESHOWGRAPH = 'HIDESHOWGRAPH';
export const PREVIEW_GRAPH = 'PREVIEW_GRAPH';
export const UPDATE_SYMPTOM_PROPS = 'UPDATE_SYMPTOM_PROPS';
export const UPDATE_METRIC_PROPS = 'UPDATE_METRIC_PROPS';
export const UPDATE_THERAPY_PROPS = 'UPDATE_THERAPY_PROPS';
export const SET_GRAPH_INIT_HASH_VALUE = 'SET_GRAPH_INIT_HASH_VALUE';
export const REFRESH_GRAPH = 'REFRESH_GRAPH';

// Account
export const DELETE_ACCOUNT_START = 'DELETE_ACCOUNT_START';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAIL = 'DELETE_ACCOUNT_FAIL';

//caregiver
export const SELECT_CAREGIVER = 'SELECT_CAREGIVER';

//push Notifications
export const OVERDUE_ROUTINE_REMINDER_AND_MATCHED_CLINICAL_TRAIL =
  'OVERDUE_ROUTINE_REMINDER_AND_MATCHED_CLINICAL_TRAIL';
export const OVERDUE_ROUTINE_REMINDER_AND_MATCHED_CLINICAL_TRAIL_FAIL =
  'OVERDUE_ROUTINE_REMINDER_AND_MATCHED_CLINICAL_TRAIL_FAIL';

//Filter
export const ADD_IMAGE_FILTER = 'ADD_IMAGE_FILTER';
export const CLEAR_IMAGE_FILTER = 'CLEAR_IMAGE_FILTER';
export const ADD_CONTACT_FILTER = 'ADD_CONTACT_FILTER';
export const CLEAR_CONTACT_FILTER = 'CLEAR_CONTACT_FILTER';
export const CLEAR_THERAPY_FILTER = 'CLEAR_THERAPY_FILTER';
export const SET_THERAPY_POPUP = 'SET_THERAPY_POPUP';
export const ADD_SYMPTOM_FILTER = 'ADD_SYMPTOM_FILTER';
export const CLEAR_SYMPTOM_FILTER = 'CLEAR_SYMPTOM_FILTER';
export const ADD_METRIC_FILTER = 'ADD_METRIC_FILTER';
export const CLEAR_METRIC_FILTER = 'CLEAR_METRIC_FILTER';
export const ADD_GRAPH_FILTER = 'ADD_GRAPH_FILTER';
export const CLEAR_GRAPH_FILTER = 'CLEAR_GRAPH_FILTER';
export const ADD_REPORT_FILTER = 'ADD_REPORT_FILTER';
export const CLEAR_REPORT_FILTER = 'CLEAR_REPORT_FILTER';
export const ADD_ROUTINE_FILTER = 'ADD_ROUTINE_FILTER';
export const CLEAR_ROUTINE_FILTER = 'CLEAR_ROUTINE_FILTER';

//OCR
export const OCR_SCAN_PRESCRIPTION_START = 'OCR_SCAN_PRESCRIPTION_START';
export const OCR_SCAN_PRESCRIPTION_OK = 'OCR_SCAN_PRESCRIPTION_OK';
export const OCR_SCAN_PRESCRIPTION_FAIL = 'OCR_SCAN_PRESCRIPTION_FAIL';
export const OCR_UPLOAD_BUSINESS_CARD_FAIL = 'OCR_UPLOAD_BUSINESS_CARD_FAIL';
export const OCR__BUSINESS_JOB_RESULT_FAIL = 'OCR__BUSINESS_JOB_RESULT_FAIL';

//Clinical Trial
export const SEARCH_CLINICAL_TRIAL_SUCCESS = 'SEARCH_CLINICAL_TRIAL_SUCCESS';
export const SEARCH_TEARMS = 'SEARCH_TEARMS';
export const ADD_CLINICAL_TRIAL = 'ADD_CLINICAL_TRIAL';
export const ADD_CLINICAL_TRIAL_LIST = 'ADD_CLINICAL_TRIAL_LIST';
export const DELETE_CLINICAL_TRIAL_SUCCESS = 'DELETE_CLINICAL_TRIAL_SUCCESS';
export const DELETE_ALL_CLINICAL_TRIALS_SUCCESS =
  'DELETE_ALL_CLINICAL_TRIAL_SUCCESS';
export const DELETE_CLINICAL_TRIAL_LIST_SUCCESS =
  'DELETE_CLINICAL_TRIAL_LIST_SUCCESS';
export const ADD_CLINICAL_TRIAL_TO_DELETE = 'ADD_CLINICAL_TRIAL_TO_DELETE';
export const REMOVE_CLINICAL_TRIAL_FROM_DELETE =
  'REMOVE_CLINICAL_TRIAL_FROM_DELETE';
export const RESET_CLINICAL_TRIALS = 'RESET_CLINICAL_TRIALS';
export const ADD_CLINICAL_TRIAL_TO_MY_LIST = 'ADD_CLINICAL_TRIAL_TO_MY_LIST';
export const REMOVE_CLINICAL_TRIAL_FROM_MY_LIST =
  'REMOVE_CLINICAL_TRIAL_FROM_MY_LIST';
export const RESET_NEW_CLINICAL_TRIALS = 'RESET_NEW_CLINICAL_TRIALS';
export const ADD_SAVED_SEARCH_CLINICAL_TRIAL =
  'ADD_SAVED_SEARCH_CLINICAL_TRIAL';
export const UPDATE_SAVED_SEARCH_CLINICAL_TRIAL =
  'UPDATE_SAVED_SEARCH_CLINICAL_TRIAL';
export const RESET_CLINICAL_TRIAL_STATE = 'RESET_CLINICAL_TRIAL_STATE';
export const RESET_CLINICAL_TRIAL_SEARCH_RESULTS = 'RESET_CLINICAL_TRIAL_SEARCH_RESULTS';
export const UPDATE_CLINICALTRAIL_DISCLAIMER_SUCCESS =
  'UPDATE_CLINICALTRAIL_DISCLAIMER_SUCCESS';
export const DELETE_FROM_NEW_CLINICAL_TRIAL_LIST =
  'DELETE_FROM_NEW_CLINICAL_TRIAL_LIST';

//report
export const UPDATE_REPORT_DETAIL = 'UPDATE_REPORT_DETAIL';
export const REMOVE_REPORT_SYMPTOM = 'REMOVE_REPORT_SYMPTOM';
export const REMOVE_REPORT_DOSING = 'REMOVE_REPORT_DOSING';
export const ADD_REMOVE_REPORT_ISSUE = 'ADD_REMOVE_REPORT_ISSUE';
export const SAVE_REPORT_ISSUE = 'SAVE_REPORT_ISSUE';
export const REMOVE_REPORT_ISSUE = 'REMOVE_REPORT_ISSUE';
export const ADD_REMOVE_REPORT_IMAGE = 'ADD_REMOVE_REPORT_IMAGE';
export const SAVE_REPORT_IMAGE = 'SAVE_REPORT_IMAGE';
export const REMOVE_REPORT_IMAGE = 'REMOVE_REPORT_IMAGE';
export const INVALID_REPORT_NAME = 'INVALID_REPORT_NAME';
export const UPDATE_REPORT_IMAGES_TITLE = 'UPDATE_IMAGES_TITLE';
export const SELECT_REPORT_GRAPH = 'SELECT_REPORT_GRAPH';
export const REMOVR_REPORT_GRAPH = 'REMOVR_REPORT_GRAPH';
export const UPDATE_REPORT_NOTE = 'UPDATE_REPORT_NOTE';
export const RESET_REPORT = 'RESET_REPORT';
export const SELECT_REPORT = 'SELECT_REPORT';
export const REPORT_GRAPH_ADD_SYMPTOM = 'REPORT_GRAPH_ADD_SYMPTOM';
export const REPORT_GRAPH_REMOVE_SYMPTOM = 'REPORT_GRAPH_REMOVE_SYMPTOM';
export const REPORT_GRAPH_ADD_METRIC = 'REPORT_GRAPH_ADD_METRIC';
export const REPORT_GRAPH_REMOVE_METRIC = 'REPORT_GRAPH_REMOVE_METRIC';
export const REPORT_GRAPH_ADD_THERAPY = 'REPORT_GRAPH_ADD_THERAPY';
export const REPORT_GRAPH_REMOVE_THERAPY = 'REPORT_GRAPH_REMOVE_THERAPY';
export const SAVE_REPORT_GRAPH = 'SAVE_REPORT_GRAPH';
export const SET_OPEN_TAB = 'SET_OPEN_TAB';
export const SET_GRAPH_IMAGE = 'SET_GRAPH_IMAGE';
export const SAVE_REPORT_SYMPTOM = 'SAVE_REPORT_SYMPTOM';
export const SAVE_REPORT_MEDICATION_THERAPIE =
  'SAVE_REPORT_MEDICATION_THERAPIE;';
export const CANCEL_GRAPH = 'CANCEL_GRAPH';
export const SET_INIT_HASH_VALUE = 'SET_INIT_HASH_VALUE';
export const REPORT_GRAPH_UPDATE_SYMPTOM_PROPS =
  'REPORT_GRAPH_UPDATE_SYMPTOM_PROPS';
export const REPORT_GRAPH_UPDATE_METRIC_PROPS =
  'REPORT_GRAPH_UPDATE_METRIC_PROPS';
export const REPORT_GRAPH_UPDATE_THERAPY_PROPS =
  'REPORT_GRAPH_UPDATE_THERAPY_PROPS';

//survry
export const SELECT_SURVEY = 'SELECT_SURVEY';
export const SET_SURVEY_QUESTIONS = 'SET_SURVEY_QUESTIONS';
export const ADD_REMOVE_SURVEY_CHOICE_QUESTIONS_ANSWER =
  'ADD_REMOVE_SURVEY_CHOICE_QUESTIONS_ANSWER';
export const ADD_REMOVE_SURVEY_SINGALE_QUESTIONS_ANSWER =
  'ADD_REMOVE_SURVEY_SINGALE_QUESTIONS_ANSWER';
export const SET_SURVEY_ANSWERS = 'SET_SURVEY_ANSWERS';
export const RESET_SURVEY = 'RESET_SURVEY';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const FETCH_SURVEY_QUESTION = 'FETCH_SURVEY_QUESTION';
export const FETCH_SURVEY_QUESTION_SUCCESS = 'FETCH_SURVEY_QUESTION_SUCCESS';
export const FETCH_SURVEY_QUESTION_FAIL = 'FETCH_SURVEY_QUESTION_FAIL';
export const SAVE_HEALTH_SURVEY_ANSWER = 'SAVE_HEALTH_SURVEY_ANSWER';
export const SAVE_HEALTH_SURVEY_ANSWER_SUCCESS =
  'SAVE_HEALTH_SURVEY_ANSWER_SUCCESS';
export const SAVE_HEALTH_SURVEY_ANSWER_FAIL = 'SAVE_HEALTH_SURVEY_ANSWER_FAIL';
export const SAVE_HEALTH_SURVEY_ANSWER_RESET =
  'SAVE_HEALTH_SURVEY_ANSWER_RESET';
export const SET_SURVEY_LIST_FROM_URL = 'SET_SURVEY_LIST_FROM_URL';

// Community groups
export const FETCH_COMMUNITY_GROUPS_RESOURCES =
  'FETCH_COMMUNITY_GROUPS_RESOURCES';
export const FETCH_COMMUNITY_GROUPS_RESOURCES_SUCCESS =
  'FETCH_COMMUNITY_GROUPS_RESOURCES_SUCCESS';
export const FETCH_COMMUNITY_GROUPS_RESOURCES_FAIL =
  'FETCH_COMMUNITY_GROUPS_RESOURCES_FAIL';
export const ADD_COMMUNITY_GROUPS_RESOURCE_SUCCESS =
  'ADD_COMMUNITY_GROUPS_RESOURCE_SUCCESS';
export const ADD_COMMUNITY_GROUPS_RESOURCE_FAIL =
  'ADD_COMMUNITY_GROUPS_RESOURCE_FAIL';
export const DELETE_COMMUNITY_GROUPS_RESOURCE_SUCCESS =
  'ADD_COMMUNITY_GROUPS_RESOURCE_SUCCESS';
export const DELETE_COMMUNITY_GROUPS_RESOURCE_FAIL =
  'ADD_COMMUNITY_GROUPS_RESOURCE_FAIL';
export const VALIDATE_RESOURCE_SUCCESS = 'VALIDATE_RESOURCE_SUCCESS';
export const VALIDATE_RESOURCE_FAIL = 'VALIDATE_RESOURCE_FAIL';
export const RESET_ADD_RESOURCE_FETCHED_DATA =
  'RESET_ADD_RESOURCE_FETCHED_DATA';
export const FETCH_ALL_RESOURCE_SUCCESS = 'FETCH_ALL_RESOURCE_SUCCESS';
export const FETCH_ALL_RESOURCE_FAIL = 'FETCH_ALL_RESOURCE_FAIL';
export const FETCH_ALL_SOCIAL_MEDIA_DATA_SUCCESS =
  'FETCH_ALL_SOCIAL_MEDIA_DATA_SUCCESS';
export const FETCH_ALL_SOCIAL_MEDIA_DATA_FAIL =
  'FETCH_ALL_SOCIAL_MEDIA_DATA_FAIL';
export const ADD_COMMUNITY_GROUPS_RESOURCES_SUCCESS =
  'ADD_COMMUNITY_GROUPS_RESOURCES_SUCCESS';
export const ADD_COMMUNITY_GROUPS_RESOURCES_FAIL =
  'ADD_COMMUNITY_GROUPS_RESOURCES_FAIL';
export const VALIDATE_DOCUMENT_SUCCESS = 'VALIDATE_DOCUMENT_SUCCESS';
export const VALIDATE_DOCUMENT_FAIL = 'VALIDATE_DOCUMENT_FAIL';
export const SELECTED_RESOURCE = 'SELECTED_RESOURCE';
export const HANDLE_SEARCH_SUCCESS = 'HANDLE_SEARCH_SUCCESS';
export const HANDLE_SEARCH_FAIL = 'HANDLE_SEARCH_FAIL';
export const TAG_SEARCH_SUCCESS = 'TAG_SEARCH_SUCCESS';
export const HANDLE_RESET_COMMUNITY_SEARCH = 'HANDLE_RESET_COMMUNITY_SEARCH';
export const POP_PREVIOUS_TAG_SEARCH_RESULT = 'POP_PREVIOUS_TAG_SEARCH_RESULT';
export const TAG_SEARCH_FAIL = 'TAG_SEARCH_FAIL';
export const UPDATE_SEARCHED_KEYWORDS = 'UPDATE_SEARCHED_KEYWORDS';

//peer To Peer
export const UPDATE_SHOW_PEER_PROFILE_SCREEN_SUCCESS =
  'UPDATE_SHOW_PEER_PROFILE_SCREEN_SUCCESS';
export const SET_PEER_TO_PEER_ACTIVE_TAB = 'SET_PEER_TO_PEER_ACTIVE_TAB';
export const UPDATE_PEER_2_PEER_PROFILE_SUCCESS =
  'UPDATE_PEER_2_PEER_PROFILE_SUCCESS';
export const UPDATE_COMMIUNITY_PROFILE_DETAILS= 'UPDATE_COMMIUNITY_PROFILE_DETAILS';

export const UPDATE_PEER_2_PEER_PROFILE = 'UPDATE_PEER_2_PEER_PROFILE';
export const UPDATE_PEER_2_PEER_PROFILE_FAIL =
  'UPDATE_PEER_2_PEER_PROFILE_FAIL';
export const UPDATE_USER_CHANGE_P2P_PROFILE = 'UPDATE_USER_CHANGE_P2P_PROFILE';
export const SET_SEARCH_TEARMS = 'SET_SEARCH_TEARMS';
export const SEARCH_PEERS_SUCCESS = 'SEARCH_PEERS_SUCCESS';
export const UPDATE_PEER_RESULTS_SEARCHED_LIST =
  'UPDATE_PEER_RESULTS_SEARCHED_LIST';
export const RESET_PEER_RESULTS = 'RESET_PEER_RESULTS';
export const UPDATE_SEARCHED_KEYWORD = 'UPDATE_SEARCHED_KEYWORD';
export const SET_CONNECTION_REQUEST_MESSAGE = 'SET_CONNECTION_REQUEST_MESSAGE';
export const UPDATE_CONNECTION_REQUEST_COUNT =
  'UPDATE_CONNECTION_REQUEST_COUNT';
export const UPDATE_CHANNELS = 'UPDATE_CHANNELS';
export const UPDATE_RESULTS = 'UPDATE_RESULTS';
export const UPDATE_SINGLE_CHANNEL = 'UPDATE_SINGLE_CHANNEL';
export const REMOVE_SINGLE_CHANNEL = 'REMOVE_SINGLE_CHANNEL';
export const SET_UNSEEN_CONVERSATIONS = 'SET_UNSEEN_CONVERSATIONS';
export const SET_CHAT_STATUS = 'SET_CHAT_STATUS';
export const SELECTED_CHANNEL = 'SELECTED_CHANNEL';
export const RESET_PEER_TO_PEER = 'RESET_PEER_TO_PEER';
export const UPDATE_NEW_MESSAGE_COUNT = 'UPDATE_NEW_MESSAGE_COUNT';
export const UPDATE_PEER_2_PEER_MYNETWORK = 'UPDATE_PEER_2_PEER_MYNETWORK';
export const UPDATE_PEER_2_PEER_PROFILE_INFO= 'UPDATE_PEER_2_PEER_PROFILE_INFO';


export const UPDATE_PEER_2_PEER_PROFILE_DATA_LIST = 'UPDATE_PEER_2_PEER_PROFILE_DATA_LIST';
export const UPDATE_PEER_2_PEER_PROFILE_DATA_LIST_SUCCESS = 'UPDATE_PEER_2_PEER_PROFILE_DATA_LIST_SUCCESS'
export const UPDATE_PEER_2_PEER_PROFILE_DATA_LIST_FAIL = 'UPDATE_PEER_2_PEER_PROFILE_DATA_LIST_FAIL'

export const UPDATE_VIEW_MORE_SHOW_HIDE_DETAILS = 'UPDATE_VIEW_MORE_SHOW_HIDE_DETAILS';

export let UPDATE_PATH_BY_INTENT = 'UPDATE_PATH_BY_INTENT';
export let UPDATE_DEEPLINK_HANDLED = 'UPDATE_DEEPLINK_HANDLED';
export let CLEAR_REDIRECT_PATH = 'CLEAR_REDIRECT_PATH';
export let RESET_REDIRECT_TO_INITIAL_STATE = 'RESET_REDIRECT_TO_INITIAL_STATE';

export const SET_ANSWERD_RATIO = 'SET_ANSWERD_RATIO';

export const SET_ATTACHEMENT_UPLOAD_PROGRESS = 'SET_ATTACHEMENT_UPLOAD_PROGRESS';
export const SET_ATTACHEMENT_UPLOAD_PROGRESS_TEMP = 'SET_ATTACHEMENT_UPLOAD_PROGRESS_TEMP';
export const SET_ATTACHEMENT_UPLOAD_STATUS = 'SET_ATTACHEMENT_UPLOAD_STATUS';
export const SET_ATTACHEMENT_ARTIFACT_ID = 'SET_ATTACHEMENT_ARTIFACT_ID';
export const SET_ATTACHEMENT_FULL_SIZE = 'SET_ATTACHEMENT_FULL_SIZE';
export const SHOW_UPLOAD_PROGRESS = 'SHOW_UPLOAD_PROGRESS';
export const HIDE_UPLOAD_PROGRESS = 'HIDE_UPLOAD_PROGRESS';
export const DELETE_UPLOAD_CONFIRMATION = 'DELETE_UPLOAD_CONFIRMATION';

//Health Resource
export const SET_HEALTH_RESOURCE_POPUP_DATA = 'SET_HEALTH_RESOURCE_POPUP_DATA';

//events 
export const SET_VISIBLE_EVENT_MENU = 'SET_VISIBLE_EVENT_MENU';
export const SET_EVENT_DATA = 'SET_EVENT_DATA';
export const SET_EVENT_YEAR_AND_ID = 'SET_EVENT_YEAR_AND_ID';
export const SET_ACTIVE_BUBBLE = 'SET_ACTIVE_BUBBLE';
export const SET_CURRENT_EVENT_ID = 'SET_CURRENT_EVENT_ID';


//Health Journey
export const FETCH_ALL_HEALTH_JOURNEY_EVENT = 'FETCH_ALL_HEALTH_JOURNEY_EVENT';
export const FETCH_ALL_HEALTH_JOURNEY_EVENT_SUCCESS = 'FETCH_ALL_HEALTH_JOURNEY_EVENT_SUCCESS';
export const FETCH_ALL_HEALTH_JOURNEY_EVENT_FAILS = 'FETCH_ALL_HEALTH_JOURNEY_EVENT_FAILS';
export const FETCH_HEALTH_JOURNEY_EVENT = 'FETCH_HEALTH_JOURNEY_EVENT';
export const FETCH_HEALTH_JOURNEY_EVENT_SUCCESS = 'FETCH_HEALTH_JOURNEY_EVENT_SUCCESS';
export const FETCH_HEALTH_JOURNEY_EVENT_FAILS = 'FETCH_HEALTH_JOURNEY_EVENT_FAILS';
export const SAVE_AND_UPDATE_HEALTH_JOURNEY_EVENT ='SAVE_AND_UPDATE_HEALTH_JOURNEY_EVENT';
export const SAVE_AND_UPDATE_HEALTH_JOURNEY_EVENT_SUCCESS ='SAVE_AND_UPDATE_HEALTH_JOURNEY_EVENT_SUCCESS';
export const SAVE_AND_UPDATE_HEALTH_JOURNEY_EVENT_FAILS ='SAVE_AND_UPDATE_HEALTH_JOURNEY_EVENT_FAILS';
export const DELETE_HEALTH_JOURNEY_EVENT ='DELETE_HEALTH_JOURNEY_EVENT';
export const DELETE_HEALTH_JOURNEY_EVENT_SUCCESS ='DELETE_HEALTH_JOURNEY_EVENT_SUCCESS';
export const DELETE_HEALTH_JOURNEY_EVENT_FAILS ='DELETE_HEALTH_JOURNEY_EVENT_FAILS';
export const UPLOAD_HEALTH_JOURNEY_ATTACHMENT ='UPLOAD_HEALTH_JOURNEY_ATTACHMENT';
export const UPLOAD_HEALTH_JOURNEY_ATTACHMENT_SUCCESS ='UPLOAD_HEALTH_JOURNEY_ATTACHMENT_SUCCESS';
export const UPLOAD_HEALTH_JOURNEY_ATTACHMENT_FAILS ='UPLOAD_HEALTH_JOURNEY_ATTACHMENT_FAILS';
export const DELETE_HEALTH_JOURNEY_ATTACHMENT ='DELETE_HEALTH_JOURNEY_ATTACHMENT';
export const DELETE_HEALTH_JOURNEY_ATTACHMENT_SUCCESS ='DELETE_HEALTH_JOURNEY_ATTACHMENT_SUCCESS';
export const DELETE_HEALTH_JOURNEY_ATTACHMENT_FAILS ='DELETE_HEALTH_JOURNEY_ATTACHMENT_FAILS';
export const FETCH_HEALTH_JOURNEY_TAGS_LIST_SUCCESS ='FETCH_HEALTH_JOURNEY_TAGS_LIST_SUCCESS';
export const FETCH_HEALTH_JOURNEY_TAGS_LIST_FAILS ='FETCH_HEALTH_JOURNEY_TAGS_LIST_FAILS';
export const RESET_HEALTH_JOURNEY_EVENTS = "RESET_HEALTH_JOURNEY_EVENTS";

//Health Journey Filtering innital objects and arrays.
export const HEALTH_JOURNEY_ADVANCED_FILTERING_ATTACHMENTS = 'HEALTH_JOURNEY_ADVANCED_FILTERING_ATTACHMENTS';
export const HEALTH_JOURNEY_CATEGORIES_FILTERING_DATA = 'HEALTH_JOURNEY_CATEGORIES_FILTERING_DATA';
export const FETCH_HEALTH_JOURNEY_FILTER_TAGS_OBJECT ='FETCH_HEALTH_JOURNEY_FILTER_TAGS_OBJECT';
export const FETCH_HEALTH_JOURNEY_FILTER_CATEGORY_OBJECT ='FETCH_HEALTH_JOURNEY_FILTER_CATEGORY_OBJECT';


export const SET_SORT_TYPE = "SET_SORT_TYPE";
export const SET_INNITIAL_DATE_RANGE = "SET_INNITIAL_DATE_RANGE";
export const SET_SELECTED_BY_DATE_OPTION = "SET_SELECTED_BY_DATE_OPTION";
export const SET_SELECTED_BY_DATE_OBJECT = "SET_SELECTED_BY_DATE_OBJECT";
export const CLEAR_SELECTED_BY_DATE_OBJECT = "CLEAR_SELECTED_BY_DATE_OBJECT";

export const SET_SELECTED_BY_CATEGORY_OBJECT = "SET_SELECTED_BY_CATEGORY_OBJECT";
export const CLEAR_SELECTED_BY_CATEGORY_OBJECT = "CLEAR_SELECTED_BY_CATEGORY_OBJECT";

export const SET_ADVANCED_FILTER_ITEMS = "SET_ADVANCED_FILTER_ITEMS";
export const CLEAR_ADVANCED_FILTER_ITEMS = "CLEAR_ADVANCED_FILTER_ITEMS";
export const SET_SELECTED_TAGS = "SET_SELECTED_TAGS";
export const ADD_SET_SELECTED_TAGS_COUNT = "ADD_SET_SELECTED_TAGS_COUNT";
export const REDUCE_SET_SELECTED_TAGS_COUNT = "REDUCE_SET_SELECTED_TAGS_COUNT";
export const CLEAR_SELECTED_TAGS_COUNT = "CLEAR_SELECTED_TAGS_COUNT";
export const CLEAR_SELECTED_TAGS = "CLEAR_SELECTED_TAGS";

export const DISABLE_FILTER_COMPONENTS = "DISABLE_FILTER_COMPONENTS";
export const ENABLE_APPLY_BUTTON = "ENABLE_APPLY_BUTTON";
export const ENABLE_REMOVE_BUTTON = "ENABLE_REMOVE_BUTTON";
export const DISABLE_APPLY_BUTTON = "DISABLE_APPLY_BUTTON";
export const DISABLE_REMOVE_BUTTON = "DISABLE_REMOVE_BUTTON";

export const ENABLE_CATEGORY_APPLY_BUTTON = "ENABLE_CATEGORY_APPLY_BUTTON";
export const ENABLE_CATEGORY_REMOVE_BUTTON = "ENABLE_CATEGORY_REMOVE_BUTTON";
export const DISABLE_CATEGORY_APPLY_BUTTON = "DISABLE_CATEGORY_APPLY_BUTTON";
export const DISABLE_CATEGORY_REMOVE_BUTTON = "DISABLE_CATEGORY_REMOVE_BUTTON";

export const SET_SELECTED_DROPDOWN_NUMBER = "SET_SELECTED_DROP_DOWN_NUMBER";
export const REMOVE_SELECTED_DROPDOWN_NUMBER = "REMOVE_SELECTED_DROPDOWN_NUMBER";

export const HEALTH_JOURNEY_SELECTED_CATEGORY_LIST = "HEALTH_JOURNEY_SELECTED_CATEGORY_LIST";

export const RESET_FILTERING_DATA =  "RESET_FILTERING_DATA";
export const RESET_FILTERING_OBJECT_DATA = "RESET_FILTERING_OBJECT_DATA";

export const SYNC_DASHBOARD_SCHEDULE_DATA = "SYNC_DASHBOARD_SCHEDULE_DATA";
export const SET_FINISH_SCHEDULES = "SET_FINISH_SCHEDULES";

export const UPDATE_DROPDOWN_UNIQUE_ID = "UPDATE_DROPDOWN_UNIQUE_ID";

export class HANDLE_RESET_SEARCH {
}
