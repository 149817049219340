import React, {Component} from 'react';
import {View} from 'react-native';
import styles from '../../../../Css';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import Collapsible from '../../../Collapsible/Collapsible';
import ItemDeleteButton from '../../../Button/itemDeleteButton';
import PlusButtonCom from '../../../Button/plusButtonCom';
import SymptomAutoComplete from '../../../AutoComplete/CommonAutoComplete/Symptom';
import SystemItem from './SystemItem';
import UserItemView from './UserItemView';
import SymptomLevelSelector from './SymptomLevelSelector';
import { chartTypes } from '../../../../Constant';
import {getSortedSymptomsWithSymptomObject} from "../../../../store/actions/index";

class Symptoms extends Component {
  constructor(props) {
    super(props);
    const {symptoms} = props;
    const sortedSymptomsWithSymptomObject = getSortedSymptomsWithSymptomObject(symptoms, false, props.symptomsDigestProcessed);
    this.state = {
      hasInvalidNames: false,
      collapsible: {
        symptomAll: sortedSymptomsWithSymptomObject && sortedSymptomsWithSymptomObject.length > 0,
      },
      autocompleteOpen: false,
      add: false,
      symptoms: sortedSymptomsWithSymptomObject,
      selectedValues: Symptoms.getSelectedValues(sortedSymptomsWithSymptomObject),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {newJournal, isCopyPrevious} = props;
    if (newJournal && isCopyPrevious && isCopyPrevious()) {
      const {symptoms} = newJournal;
      const sortedSymptomsWithSymptomObject = getSortedSymptomsWithSymptomObject(symptoms, false, props.symptomsDigestProcessed);
      return {
        collapsible: {
          symptomAll: sortedSymptomsWithSymptomObject && symptoms.length > 0,
        },
        symptoms: sortedSymptomsWithSymptomObject,
        selectedValues: Symptoms.getSelectedValues(sortedSymptomsWithSymptomObject),
      };
    }

    return null;
  }

  static getSelectedValues = (symptoms) => {
    const selectedValues = {};

    if (symptoms) {
      symptoms.forEach(element => {
        let obj = {};
        if (element.type === 'SYSTEM' || element.type === 'CUSTOM') {
          obj = element;
        } else {
          obj = element.symptomObj;
        }
        if (obj) {
          selectedValues[obj.name] = obj.name;
        }
      });
    }
    return selectedValues;
  };

  handleOnPressCollapsible = key => {
    const {collapsible} = this.state;
    let val = collapsible[key];
    collapsible[key] = !val;
    let add = false;

    this.setState({collapsible: collapsible, add: add});
  };

  updateAutocompleteOpen = val => {
    this.setState({autocompleteOpen: val});
  };

  setAdd = () => {
    const {add} = this.state;
    this.setState({add: !add});
  };

  onPressItem = (item, type) => {
    const {symptoms, selectedValues} = this.state;
    const {updateSymptoms, symptomsDigestProcessed} = this.props;
    const {userSymptoms} = symptomsDigestProcessed;
    let existItem = true;
    this.setAdd();

    if (symptoms && symptoms.length > 0) {
      if (type === 'USER' || type === 'SYSTEM') {
        symptoms.forEach(i => {
          if (i.symptom === item.id) {
            existItem = false;
          }
        });
      } else {
        symptoms
          .filter(s => s.id === -1)
          .forEach(i => {
            if (
              i.type === 'USER'
                ? userSymptoms[i.symptom].name === item
                : i?.symptomObj?.name === item
            ) {
              existItem = false;
            }
          });
      }
    }
    if (existItem) {
      if (type === 'USER') {
        symptoms.push({
          id: -1,
          symptom: item.id,
          severity: null,
          type: type,
        });
        const obj = userSymptoms[item.id];
        if (obj) {
          selectedValues[obj.name] = obj.name;
        }
      } else {
        let name;
        let displayProperties;
        if (type === 'SYSTEM') {
          name = item.name;
          displayProperties = item.displayProperties;
        } else {
          name = item;
        }
        selectedValues[name] = name;
        symptoms.push({
          name: name,
          displayProperties: {
            color: type === 'SYSTEM' ? displayProperties?.color : 'rgba(204, 136, 37, 1)',
            //dashedLine: type === 'SYSTEM' ? displayProperties?.dashedLine : true,
            style: type === 'SYSTEM' ? displayProperties?.style : chartTypes.line,
            scaleMin: type === 'SYSTEM' ? displayProperties?.scaleMin : null,
            scaleMax: type === 'SYSTEM' ? displayProperties?.scaleMax : null,
            description: type === 'SYSTEM' ? displayProperties?.description : null,
          },
          userSymptomLog: {
            severity: null,
          },
          type: type,
        });
      }
      this.setState({symptoms: symptoms, selectedValues: selectedValues}, () =>
        updateSymptoms(symptoms),
      );
    }
  };

  getItem = (item, index) => {
    const {symptomsDigestProcessed, type} = this.props;
    const {userSymptoms} = symptomsDigestProcessed;

    if (item.type === 'SYSTEM' || item.type === 'CUSTOM') {
      return (
        <SystemItem
          data={item}
          index={index}
          key={'sy_' + index}
          deleteItem={this.deleteItem}
          updateSymptoms={this.updateSymptoms}
          category={type}
          disabledScroll={this.props.disabledScroll}
        />
      );
    } else if (type === 'RT') {
      return (
        <UserItemView
          data={item}
          index={index}
          key={'sy_' + index}
          userSymptomsProcessed={userSymptoms}
          deleteItem={this.deleteItem}
          updateSymptoms={this.updateSymptoms}
        />
      );
    } else if (userSymptoms[item.symptom]) {
      const currentUserSymptom = userSymptoms[item.symptom];
      return (
        <SymptomLevelSelector
          data={item}
          symptomName={currentUserSymptom.name}
          scaleMin={currentUserSymptom.displayProperties.scaleMin}
          scaleMax={currentUserSymptom.displayProperties.scaleMax}
          severity={item.severity}
          publishedBy={currentUserSymptom.publishedBy}
          sliderDescriptions={currentUserSymptom.displayProperties.description}
          onUpdateSymptom={severityUpdated =>
            this.updateSymptoms(index, {...item, severity: severityUpdated})
          }
          onDeleteSymptom={() => this.deleteItem(index)}
          disabledScroll={this.props.disabledScroll}
        />
      );
    }
    return null;
  };

  updateSymptoms = (index, data) => {
    const {symptoms} = this.state;
    const {updateSymptoms, isInvalidName} = this.props;
    symptoms[index] = data;
    this.setState(
      {
        symptoms: symptoms,
      },
      () => updateSymptoms(symptoms),
    );
    let hasInvalidNames = this.checkInvalidName();
    isInvalidName(hasInvalidNames);
  };

  checkInvalidName = () => {
    let isInvalidName = this.state.symptoms.some(symptom => {
      return symptom?.name?.trim() === '';
    });
    return isInvalidName;
  }

  deleteItem = index => {
    const {symptoms} = this.state;
    const { updateSymptoms, isInvalidName, symptomsDigestProcessed } = this.props;

    const deletedSymptomId = symptoms[index].id;
    symptoms.splice(index, 1);
    const sortedSymptomsWithSymptomObject = getSortedSymptomsWithSymptomObject(symptoms, false, symptomsDigestProcessed);
    this.setState(
      {
        symptoms: symptoms,
        selectedValues: Symptoms.getSelectedValues(sortedSymptomsWithSymptomObject),
      },
      () => updateSymptoms(symptoms, deletedSymptomId),
    );

    let hasInvalidNames = this.checkInvalidName();
    isInvalidName(hasInvalidNames);
  };

  render() {
    const {add, symptoms, selectedValues, collapsible} = this.state;
    const {plusButton, disabledScroll, type} = this.props;

    return (
      <Collapsible
        title={'Symptoms'}
        contentVisibility={collapsible.symptomAll}
        handleOnPressCollapsible={() =>
          this.handleOnPressCollapsible('symptomAll')
        }
        style={[styles.collapseTitle]}
        customTitleStyle={true}
        plusButton={plusButton}
        topBorder={true}
        // toolTipButton={true}
        // toolTipId={'tool-tip-Symptom'}
        // toolTipContent={''}
        nativeId={type === 'R' ? 'journalSymptom' : 'routineSymptom'}
      >
        <View style={[styles.collapseContent, styles.colPdTopLtRt]}>
          <View style={[styles.fieldContainer, styles.fieldRowNoMg]}>
            <View style={[styles.fieldRowSliderWrapper]}>
              {symptoms.map((item, index) => this.getItem(item, index))}
            </View>
          </View>

          {/* Loop Item for Symptom - End */}

          {!Boolean(add) && (
            <View style={[styles.btnArea, styles.btnAreaPd, styles.zIndexMin]}>
              <PlusButtonCom
                btnPosition={'left'}
                btnText={'Add'}
                touchableOnPress={this.setAdd}
                nativeId={type === 'R' ? 'journalAddSymptom' : 'routineAddSymptom'}
              />
            </View>
          )}
        </View>
        {Boolean(add) && (
          <View
            style={[
              styles.fieldRowCom,
              styles.fieldRowNoMg,
              styles.zIndexAutoComplete,
              styles.colPdLtRtBot,
            ]}>
            <View style={[styles.fieldRowComWrapper]}>
              <View style={styles.fieldColStretch}>
                <SymptomAutoComplete
                  updateAutocompleteOpen={this.updateAutocompleteOpen}
                  onPressItem={this.onPressItem}
                  selectedValues={selectedValues}
                  disabledScroll={disabledScroll}
                />
              </View>
              <View style={styles.fieldColSp} />
              <View style={styles.fieldColDelete}>
                <ItemDeleteButton touchableOnPress={this.setAdd} />
              </View>
            </View>
          </View>
        )}
      </Collapsible>
    );
  }
}

const mapStateToProps = state => {
  return {
    newJournal: state.routineTemplate.newJournal,
    symptomsDigestProcessed: state.routines.symptomsDigestProcessed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    isInvalidName: flag => dispatch(actions.hasEntrySymptomInvalidNames(flag)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Symptoms);
