import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import InfiniteScroll from "react-infinite-scroll-component";
import ClinicalTrialResult from "../../../components/Settings/ClinicalTrials/ClinicalTrialSearch/ClinicalTrialResult";
import PageContainer from "../../../components/PageLayout/PageContainer";
import { getScrollHeight } from "../../../util/commonUiLogic";
import { TAB_BREAk_POINT } from "../../../util/platformWindowConfig";

class ClinicalTrialResultsScrollView extends Component {
  state = {
    items: Array.isArray(this.props.data.study)
      ? this.props.data.study.slice(0, 19)
      : this.props.data.study, //Array.from(this.props.data.study.slice(0, 19)),
    hasMore: true,
    windowHeight: undefined,
    windowWidth: undefined
  };

  constructor(props) {
    super(props);
    const items = Array.isArray(this.props.data.study)
      ? this.props.data.study.slice(0, 19)
      : this.props.data.study; //props.data.study.slice(0, 19);//Array.from(props.data.study.slice(0, 19));
    this.state = {
      items: items,
      hasMore: this.props.data.study && Array.isArray(this.props.data.study) && this.props.data.study.length > 19,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { windowHeight, windowWidth } = props;
    const items = Array.isArray(this.props.data.study)
      ? this.props.data.study.slice(0, 19)
      : this.props.data.study; //props.data.study.slice(0, 19);//Array.from(props.data.study.slice(0, 19));
    this.setState({
      items: items,
      hasMore: this.props.data.study && Array.isArray(this.props.data.study) && this.props.data.study.length > 19,
      windowWidth,
      windowHeight
    });
  }

  fetchMoreData = () => {
    if (this.state.items.length >= this.props.data.study.length) {
      if (this.props.data.count > this.props.searchResults.study.length) {
        this.props.onSearchClinicalTrials(
          this.props.searchTearmQuary +
            "&down_chunk=" +
            Math.ceil(this.props.searchResults.study.length / 1000 + 1),
          (success1, res1) => {
            if (success1) {
            }
          },
          this.props.searchTearms
        );
      }
      this.setState({ hasMore: false });
      return;
    } else {
      const items = this.state.items.concat(
        this.props.data.study.slice(
          this.state.items.length,
          this.state.items.length + 19
        )
      );

      if (items.length > this.state.items.length) {
        setTimeout(() => {
          this.setState({
            items: items
          });
        }, 1500);
      }
    }
  };

  render() {
    const { journalUserId, timeZoneWarning, timeZone } = this.props;
    const { windowHeight, windowWidth } = this.state;
    let tabHeight = 44;
    let searchHeadingHeight = 115;
    let btnHeight = 65;

    let scrollHeight = getScrollHeight(false, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth, tabHeight + btnHeight + searchHeadingHeight);
    if (scrollHeight < 50) {
      scrollHeight = 50;
    }
    return (
      <div
        id="scrollableDiv"
        style={{ height: scrollHeight, overflowY: "auto" }}
      >
        <PageContainer smScreenFull={true}>
          <InfiniteScroll
            dataLength={this.state.items.length}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {Array.isArray(this.state.items) ? (
              this.state.items.map((item, i) =>
                item.locations !== "" ? (
                  Array.isArray(item.locations.location) ? (

                    item.locations.location.map((location, index) => (
                      <ClinicalTrialResult
                        key={item.rank + "loc" + index}
                        result={item}
                        location={location}
                      />
                    ))
                  ) : (
                    <ClinicalTrialResult
                      key={item.rank}
                      result={item}
                      location={item.locations.location}
                    />
                  )
                ) : (
                  <ClinicalTrialResult
                    key={item.rank}
                    result={item}
                    location=""
                  />
                )
              )
            ) : null}
          </InfiniteScroll>
          <View style={{ height: 100 }} />
          </PageContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
    searchResults: state.clinicalTrials.searchResults,
    searchTearms: state.clinicalTrials.searchTearms,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSearchClinicalTrials: (searchTearm, callBack, searchTearms) =>
      dispatch(actions.searchClinicalTrials(searchTearm, callBack, searchTearms))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClinicalTrialResultsScrollView);
