import React, {Component, Fragment} from 'react';
import styles from '../../../Css';
import {View, Text, Image} from 'react-native';
import {Slider, Rail, Handles, Tracks} from 'react-compound-slider';
import LinearGradient from 'react-native-web-linear-gradient';
import PropTypes from 'prop-types';

const sliderStyle = {
  position: 'relative',
  width: '100%',
  height: 15,
  touchAction: 'none',
};

class Duration extends Component {
  constructor(props) {
    super(props);
    let max = props.maximumPoints ? props.maximumPoints : 120;
    let min = props.minimumPoints ? props.minimumPoints : 0;
    let {duration} = props;
    let value = duration ? duration : 0;
    this.state = {
      values: [value],
      value: value,
      domain: [min, max],
    };
  }

  onChange = values => {
    const {updateDuration} = this.props;
    this.setState({ values, value: values?.[0] }, () => {
      updateDuration(values?.[0]);
    });
  };

  render() {
    const {
      state: {values},
    } = this;
    //let percentBox = (value / 120) * 100;
    //   let mgLeft = 0;
    //  mgLeft = -(value * (25 / 120));
    return (
      <View style={[styles.durationWrapper, styles.comMgBot]}>
        <View style={[styles.durationSliderTitleFull, styles.comMgBotSm]}>
          <Text style={[styles.textPrimeExSmBold, styles.textGrey]} allowFontScaling={false}>
            Duration
          </Text>
        </View>

        {/* <View style={[styles.sliderValueWrapper, styles.comMgBot]}>
          <View
            style={[
              styles.sliderThumbInfoView,
              styles.sliderThumbMg,
              {left: `${percent}%`, marginLeft: mgLeft},
            ]}>
            <Text style={[styles.textPrimeSmBold]}>{value}</Text>
          </View>
        </View> */}
        <View
          style={[
            styles.durationSliderWrapper,
            styles.flexAiCt,
            styles.durationSliderPd,
          ]}>
          <View style={styles.durationSliderView}>
            <LinearGradient
              start={{x: 0, y: 0}}
              end={{x: 1, y: 0}}
              colors={['#FF8301', '#FF8300']}
              style={[styles.linearGradient, styles.noMgBot]}>
              <Slider
                mode={1}
                step={5}
                domain={this.state.domain}
                rootStyle={sliderStyle}
                onChange={this.onChange}
                values={values}
                disabled={this.props.isClosed}>
                <Rail>
                  {({getRailProps}) => (
                    <SliderRail getRailProps={getRailProps} />
                  )}
                </Rail>
                <Handles>
                  {({handles, getHandleProps}) => (
                    <div className="slider-handles">
                      {handles.map(handle => (
                        <Handle
                          key={handle.id}
                          handle={handle}
                          domain={this.state.domain}
                          getHandleProps={getHandleProps}
                        />
                      ))}
                    </div>
                  )}
                </Handles>
                <Tracks right={false}>
                  {({tracks, getTrackProps}) => (
                    <div className="slider-tracks">
                      {tracks.map(({id, source, target}) => (
                        <Track
                          key={id}
                          source={source}
                          target={target}
                          getTrackProps={getTrackProps}
                        />
                      ))}
                    </div>
                  )}
                </Tracks>
              </Slider>
            </LinearGradient>
            <View style={[styles.sliderRangeArea, styles.flexRow]}>
              <View style={[styles.sliderTextFixed, styles.flexAiFs]}>
                <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>0</Text>
              </View>
              <View style={[styles.sliderTextFixed, styles.flexAiFe]}>
                <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>120</Text>
              </View>
            </View>
          </View>
        </View>
        {/* <View style={[styles.sliderDataTitle]}>
          <Text style={[styles.textPrimeExSm, styles.textBlack]}>Minutes</Text>
        </View> */}
      </View>
    );
  }
}

const railOuterStyle = {
  position: 'absolute',
  width: '100%',
  height: 42,
  transform: 'translate(0%, -50%)',
  borderRadius: 7,
  cursor: 'pointer',
  // border: '1px solid white',
  marginTop: 8,
};

const railInnerStyle = {
  position: 'absolute',
  width: '100%',
  height: 14,
  transform: 'translate(0%, -50%)',
  borderRadius: 7,
  pointerEvents: 'none',
  //backgroundColor: 'rgb(155,155,155)',
  marginTop: 8,
};

export function SliderRail({getRailProps}) {
  return (
    <Fragment>
      <div style={railOuterStyle} {...getRailProps()} />
      <div style={railInnerStyle} />
    </Fragment>
  );
}

SliderRail.propTypes = {
  getRailProps: PropTypes.func.isRequired,
};

export function Handle({
  domain: [min, max],
  handle: {id, value, percent, mgLeft, percentBox},
  getHandleProps,
}) {
  return (
    <Fragment>
      <div
        style={{
          left: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
          zIndex: 5,
          width: 28,
          height: 42,
          cursor: 'pointer',
          // border: '1px solid white',
          backgroundColor: 'none',
          marginTop: 7,
        }}
        {...getHandleProps(id)}>
        <View style={[styles.sliderValueWrapper, styles.comMgBot]}>
          <View
            style={[
              styles.sliderThumbInfoView,
              styles.sliderThumbMgLg,
              // {left: `${percent}%`},
              {left: `${percentBox}%`, marginLeft: mgLeft},
            ]}>
            <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>{value}</Text>
          </View>
        </View>
      </div>
      <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          zIndex: 2,
          width: 35,
          height: 35,
          backgroundColor: 'transparent',
          marginTop: 7,
        }}>
        <Image
          style={styles.sliderThumbCom}
          source={require('../../../assets/icons/slider-thumb-orange.png')}
          pointerEvents={'none'}
        />
      </div>
    </Fragment>
  );
}

Handle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Handle.defaultProps = {
  disabled: false,
};

export function KeyboardHandle({
  domain: [min, max],
  handle: {id, value, percent},
  disabled,
  getHandleProps,
}) {
  return (
    <button
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        left: `${percent}%`,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        zIndex: 2,
        width: 24,
        height: 24,
        borderRadius: '50%',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
        backgroundColor: disabled ? '#666' : '#ffc400',
      }}
      {...getHandleProps(id)}
    />
  );
}

KeyboardHandle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

KeyboardHandle.defaultProps = {
  disabled: false,
};

export function Track({source, target, getTrackProps}) {
  return (
    <div
      style={{
        position: 'absolute',
        transform: 'translate(0%, -50%)',
        height: 14,
        zIndex: 1,
        //backgroundColor: disabled ? '#999' : '#b28900',
        borderRadius: 7,
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
        marginTop: 8,
      }}
      {...getTrackProps()}
    />
  );
}

Track.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getTrackProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Track.defaultProps = {
  disabled: false,
};

export function Tick({tick, count, format}) {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          //marginTop: 14,
          width: 1,
          height: 5,
          backgroundColor: 'rgb(200,200,200)',
          left: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          //marginTop: 22,
          fontSize: 10,
          textAlign: 'center',
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}>
        {format(tick.value)}
      </div>
    </div>
  );
}

Tick.propTypes = {
  tick: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  count: PropTypes.number.isRequired,
  format: PropTypes.func.isRequired,
};

Tick.defaultProps = {
  format: d => d,
};

export default (Duration);
