import React, { Component } from 'react';
import { View} from 'react-native';
import { freeStorageByte, premiumStorageByte } from '../../Constant';


export default class AddQuickNoteCommon extends Component {
  
  getFileAndImage = (filesArray, callBack) => {
    const files = {};
    const images = {};
    let index = 0;
    let fileSize = 0;
    for (let i = 0; i < filesArray.length; i++) {
      const file = filesArray[i];
      var reader = new FileReader();
      fileSize += file.size;
      // eslint-disable-next-line no-loop-func
      reader.onload = function(event) {
        files[index] = file;
        images[index] = event.target.result;
        index++;
        callBack(files, images, index, fileSize);
      };
      reader.readAsDataURL(file);
    }

    setTimeout(() => {
      this.setState({files, images, index, fileSize});
    }, 1000);
  };

  blobToFile = (theBlob, fileName) => {
    theBlob.filename = fileName;
    theBlob.name = fileName;
    return theBlob;
  };

  onChangeFileUpload = (e, image64) => {
    this.setState({visibleEntryModal: false});

    let files;
    let fileSize = 0;
    let index = 0;
    let images = {};
    let isFileTooLarge = false;

    if (this.state.files) {
      files = {...this.state.files};
      fileSize = this.state.fileSize;
      index = this.state.index;
      images = {...this.state.images};
    } else {
      files = [];
    }
    if (!image64) {
      for (let i = 0; i < e.target.files.length; i++) {
        // eslint-disable-next-line no-loop-func
        (() => {
          const file = e.target.files[i];
          if (file.size <= 26214400) {
            var reader = new FileReader();
            reader.onload = function(event) {
              files[index] = file;
              images[index] = event.target.result;
              index++;
            };
            reader.readAsDataURL(file);
            fileSize += file.size;
          } else if (!isFileTooLarge) {
            isFileTooLarge = true;
          }
        })();
      }

      e.target.value = null;
      const {applicationSettingsDigest} = this.props;
      const {uploadedFileSize} = applicationSettingsDigest;
      const totalFileSize = uploadedFileSize + fileSize;
      if (fileSize > 26214400 || isFileTooLarge) {
        this.props.commonErrorHandler(
          'Sorry. File exceeds the maximum upload size of 25MB.',
        );
      } else if (
        this.state.accountType === 'regular' &&
        totalFileSize > freeStorageByte
      ) {
        this.props.showHideStorageBanner(true);
      } else if (
        this.state.accountType === 'premium' &&
        totalFileSize > premiumStorageByte
      ) {
        this.props.showHideStorageBanner(true);
      } else {
        setTimeout(() => {
          this.setState({
            files: files,
            fileSize: fileSize,
            index: index,
            images: images,
            uploadError: false,
            uploadErrorMesage: null,
          });
        }, 1000);
      }
    } else {
      const timestamp = `${new Date().getTime()}.png`;
      const file = this.blobToFile(e, timestamp);

      if (file.size < 26214400) {
        var reader = new FileReader();
        reader.onload = function(event) {
          files[index] = file;
          images[index] = image64;
          index++;
        };
        reader.readAsDataURL(file);
        fileSize += file.size;
      } else if (!isFileTooLarge) {
        isFileTooLarge = true;
      }
      setTimeout(() => {
        this.setState({
          files: files,
          fileSize: fileSize,
          index: index,
          images: images,
          uploadError: false,
          uploadErrorMesage: null,
          visibleImageTakePhoto: false,
        });
      }, 1000);
    }
  };

  getFileContentData = (attachments, ocrData, artifactId) => {
    const upoladData = [];
    let userId = this.props.user.id;
    if (this.props.journalUserId) {
        userId = this.props.journalUserId;
    }
    attachments.forEach((item, index) => {
        const data = ocrData[item.filename];

        var reader = new FileReader();
        reader.fileName = item.filename;
        reader.instanceId = artifactId;
        reader.attachmentCategory = 'Contact';
        reader.userId = userId;
        reader.index = index;
        reader.arrayLenght = attachments.length - 1;
        reader.upoladData = upoladData;
        reader.attachmentId = item.id;

        reader.onload = function(readerEvt) {
            readerEvt.target.upoladData.push({
            fileName: readerEvt.target.fileName,
            content: window.btoa(readerEvt.target.result),
            attachmentId: readerEvt.target.attachmentId.toString(),
            });
        };

        reader.readAsBinaryString(data);
    });
  }

  render() {
    return <View />
  }
}

