import * as actionTypes from "../actions/actionTypes";
import * as actionTypesUserConsentForms from "../actions/actionTypesUserConsentForms";
import { updateObject } from "../../shared/utility";

const initialState = {
  consentForms: [],
  consentFormIndex: 0,
  saving: false,
  error: null,
};

const setConsentForms = (state, action) => {
  return updateObject(state, {
    consentForms: action.data.consentForms,
    consentFormIndex: 0,
    saving: false,
    error: null,
  });
};

const resetConsentForms = (state, action) => {
  return updateObject(state, {
    consentForms: [],
    consentFormIndex: 0,
    saving: false,
    error: null,
  });
};

const submitConsentFormInitialDataStart = (state, action) => {
  return updateObject(state, {
    saving: true,
    error: null,
  });
};

const submitConsentFormInitialDataFail = (state, action) => {
  return updateObject(state, {
    saving: false,
    error: action.error,
  });
};

const submitConsentFormInitialDataSuccess = (state, action) => {
  const consentFormToUpdate = state.consentForms[action.consentFormIndex];
  const newConsentFormValues = action.data.userConsent;
  const updatedConsentFormArray = [...state.consentForms];
  updatedConsentFormArray[action.consentFormIndex] = {
    ...consentFormToUpdate,
    ...newConsentFormValues,
  };

  return updateObject(state, {
    consentForms: updatedConsentFormArray,
    saving: false,
    error: null,
  });
};

const submitConsentFormConfirmationStart = (state, action) => {
  return updateObject(state, {
    saving: true,
    error: null,
  });
};

const submitConsentFormConfirmationFail = (state, action) => {
  return updateObject(state, {
    saving: false,
    error: action.error,
  });
};

const submitConsentFormConfirmationSuccess = (state, action) => {
  return submitConsentFormInitialDataSuccess(state, action);
};

const incrementConsentFormIndex = (state, action) => {
  return updateObject(state, {
    consentFormIndex: state.consentFormIndex + 1,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SURVEY_QUESTIONS:
      return setConsentForms(state, action);
    case actionTypes.RESET_SURVEY:
      return resetConsentForms(state, action);
    case actionTypesUserConsentForms.SUBMIT_CONSENT_FORM_INITIAL_DATA_START:
      return submitConsentFormInitialDataStart(state, action);
    case actionTypesUserConsentForms.SUBMIT_CONSENT_FORM_INITIAL_DATA_FAIL:
      return submitConsentFormInitialDataFail(state, action);
    case actionTypesUserConsentForms.SUBMIT_CONSENT_FORM_INITIAL_DATA_SUCCESS:
      return submitConsentFormInitialDataSuccess(state, action);
    case actionTypesUserConsentForms.SUBMIT_CONSENT_FORM_CONFIRMATION_START:
      return submitConsentFormConfirmationStart(state, action);
    case actionTypesUserConsentForms.SUBMIT_CONSENT_FORM_CONFIRMATION_FAIL:
      return submitConsentFormConfirmationFail(state, action);
    case actionTypesUserConsentForms.SUBMIT_CONSENT_FORM_CONFIRMATION_SUCCESS:
      return submitConsentFormConfirmationSuccess(state, action);
    case actionTypesUserConsentForms.INCREMENT_CONSENT_FORM_INDEX:
      return incrementConsentFormIndex(state, action);
    default:
      return state;
  }
};
export default reducer;
