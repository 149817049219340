import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import DateFnsUtils from "@date-io/date-fns";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import lightGreen from "@material-ui/core/colors/lightGreen";
import styles from "../../Css";
import moment from "moment";

const theme = createMuiTheme({
  palette: {
    primary: lightGreen,
  },
});

class TP extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = {
      value: value ? value : null,
    };
  }
  textFieldComponent = (props) => {
    const { onClick } = props;
    const { customTouchableComponent, value } = this.props;

    if (customTouchableComponent) {
      return customTouchableComponent(onClick);
    }
    return (
      <TouchableOpacity onPress={onClick}>
        <View style={[styles.textFieldDatePickerField]}>
          <Text style={styles.textPrime} allowFontScaling={false}>
            {value ? moment(value).format("h:mm A") : ""}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { value } = nextProps;
    this.setState({ value: value });
  };

  render() {
    const { value } = this.state;
    const { onChange } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker
            value={value ? moment(value) : null}
            onChange={onChange}
            InputProps={{
              disableUnderline: true,
            }}
            TextFieldComponent={this.textFieldComponent}
            orientation="portrait"
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}
export default (TP);
