import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import styles from "../../Css";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";

const primaryLinkButton = (props) => {
  return (
    <View
      style={[
        styles.actionBtnWrapper,
          {width: "100%"},
        props.btnPosition === "left"
          ? styles.flexAiFs
          : props.btnPosition === "right"
          ? styles.flexAiFe
          : props.btnPosition === "center"
          ? styles.flexAiCt
          : styles.flexCom,
      ]}
    >
      <TouchableOpacity
        style={[props.touchableStyle]}
        onPress={props.touchableOnPress}
        disabled={props.disabled}
      >
        <View
          nativeID={props.nativeId}
          style={[
            styles.btnSingleView,
            styles.primaryButton,
            styles.primaryButtonLink,
            styles.btnBorderLight,
            styles.bgWhite,
            styles.btnShadowCom,
            props.btnStyle,
          ]}
        >
          <View style={[styles.btnSingle, styles.btnSingleCent]}>
            {Boolean(props.activityInd) && (
              <View
                style={[
                  styles.indicatorIconView,
                  props.btnPosition === "full"
                    ? styles.indicatorLtDefault
                    : styles.indicatorLtSm,
                  props.activityIndStyle,
                ]}
              >
                <ActivityIndicator pdZero={true} size="small" />
              </View>
            )}
            <Text style={[styles.submitBtnText, styles.textOrange]} allowFontScaling={false}>
            <Text style={styles.textColorLight} allowFontScaling={false}>{props.btnTextMain}</Text>
            {" "}{props.btnTextSub}
            </Text>

            <View style={styles.btnIconView}>
              <Image
                style={styles.linkIconSm}
                source={require("../../assets/contact/link.png")}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (primaryLinkButton);
