import React, {Component} from 'react';
import {connect} from 'react-redux';
import AddHealthData from './AddHealthData';
import Item from './Item';
import * as actions from "../../../store/actions/index";
import { getUnits, getTypeOfMeasurement } from '../../../util/commonUiLogic';

class HealthData extends Component {
  constructor(props) {
    super(props);
    const {helethData} = props;
    this.state = {
      list: helethData ? helethData : [],
      unitsArray: []
    };
  }

  // static getDerivedStateFromProps(props, state) {
  //   const {helethData} = props;
  //   const {list} = state;

  //   if (JSON.stringify(helethData) !== JSON.stringify(list)) {
  //     return {
  //       list: helethData ? helethData : [],
  //     }
  //   }
  //   return null;
  // }

  componentDidMount() {
    this.props.getSystemUnitOfMeasure((res, data) => {
      if (res) {
        this.setState({
          unitsArray: data
        });
      }
    });
  }

  addHealthDataToList = (data, type, uomId) => {
    const {list} = this.state;
    list.push({
      metric: null,
      unit: uomId,
      data: data,
      type: type,
    });
    this.setState({list: list});
  };

  deleteItem = index => {
    const {updateHelethData} = this.props;
    let {list} = this.state;
    //delete list[index];
    list.splice(index, 1);
    this.setState({list: list});
    updateHelethData(list);
  };
  updateMetricValues = (index, unit) => {
    const {list} = this.state;
    const {updateHelethData} = this.props;
    const data = {...list[index], unit};
    list[index] = data;
    // console.log(list[index]);
    // updateHelethData(list);
  };

  updateHelthDataName = (index, name) => {
    const {list} = this.state;
    const {updateHelethData} = this.props;
    const data = {...list[index], data:{...list[index].data, name}};
    list[index] = data;
    // console.log(list[index].data);
    updateHelethData(list);
  };

  deleteMetricErrors = (index) => {
    const {list} = this.state;
    const {updateHelethData} = this.props;
    list[index].error = false;
    updateHelethData(list);
  };
  render() {
    const {list, unitsArray} = this.state;
    const {updateAutocompleteOpen, userDefinedMetricsProcessed} = this.props;
    return (
      <React.Fragment>
        <AddHealthData
          addHealthDataToList={this.addHealthDataToList}
          metrics={list}
          updateAutocompleteOpen={updateAutocompleteOpen}
        />
        {list.map((res, i) =>
          <Item
            key={'helethData_item' + i}
            metric={res}
            index={i}
            units={getUnits(unitsArray, getTypeOfMeasurement(res, userDefinedMetricsProcessed))}
            deleteItem={this.deleteItem}
            updateMetricValues={this.updateMetricValues}
            updateHelthDataName={this.updateHelthDataName}
            error={this.props.error}
            deleteMetricErrors={this.deleteMetricErrors}
            metricsCount={list.length}
        />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    unitsOfMeasureDigest: state.routines.unitsOfMeasureDigest
      ? state.routines.unitsOfMeasureDigest
      : [],
    userDefinedMetricsProcessed: state.routines.metricsDigestProcessed
      ? state.routines.metricsDigestProcessed.userDefinedMetrics
      : [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSystemUnitOfMeasure: callBack =>
      dispatch(actions.getSystemUnitOfMeasure(callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthData);
