import React, {Component} from 'react';
import {View} from 'react-native';
import styles from '../../Css';
import {connect} from 'react-redux';
import Header from '../../components/Header/header';
import * as actions from '../../store/actions/index';
import ViewReport from '../../components/Report/ViewReport';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import {
  REPORTS,
  JOURNALS,
  CONTACTS,
  MEDICATIONS,
} from '../../navigation/path';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { TAB_BREAk_POINT_END } from '../../util/platformWindowConfig';

class ViewReportScreen extends Component {
  redirectPage = userArtifact => {
    let item;
    const {journalEntriesProcessed, contactsList, therapyList} = this.props;
    const journalList = Object.values(journalEntriesProcessed);
    if ((item = journalList.find(entry => entry.artifactId === userArtifact))) {
      if (!this.props.journalEntryTypesProcessed[item.entryType].isAppointment) {
        this.props.setJournalEntry(item);
        if (this.props.windowWidth < TAB_BREAk_POINT_END) {
          this.props.navigation.push(
            JOURNALS.viewJournalPath, {
              from: REPORTS.viewReportPath,
              params: {journalName: item?.title}
            },
          );
        } else {
          this.props.navigation.push(JOURNALS.path, {
            from: this.props.route.name,
            params: {
              journalPath: JOURNALS.viewJournalPathSuffix,
              journalName: item?.title
            }
          });
        }
      }
    } else if (
      (item = contactsList.find(contact => contact.artifactId === userArtifact))
    ) {
      this.props.setContact(item, () =>
        this.props.navigation.push(
          CONTACTS.viewContactPath, {
            from: REPORTS.viewReportPath
          },
        ),
      );
    } else if (
      (item = therapyList.find(therapy => therapy.artifactId === userArtifact))
    ) {
      this.props.setTherapy(item, () =>
        this.props.navigation.push(
          MEDICATIONS.viewTherapiesPath, {
            from: REPORTS.viewReportPath
          },
        ),
      );
    }
  };

  componentDidMount= () => {
    pendoTrackEvent("Reports/@ViewReport");
  }

  redirectEditReport = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ 
        name: REPORTS.editReportPath,
      }],
    });
  };

  redirectReport = () => {
    this.props.resetReport();
    this.props.navigation.reset({
      index: 0,
      routes: [{ 
        name: REPORTS.path,
        params: {from: this.props.route.name}
      }],
    });
  };

  render() {
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => {
            this.redirectReport();
          }}
          index={REPORTS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        <KeyboardAwareScrollViewHoc>
          <View style={[styles.gridSection]}>
            <ViewReport
              redirectEditReport={this.redirectEditReport}
              redirectPage={this.redirectPage}
              redirectReport={this.redirectReport}
            />
          </View>
        </KeyboardAwareScrollViewHoc>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    therapyList: state.routines.treatments,
    contactsList: state.routines.contacts,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    windowWidth: state.uistate.windowWidth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setJournalEntry: selectedId =>
      dispatch(actions.setSelectedJournalEntry(selectedId)),
    setTherapy: (SelectedTherapie, callBack) =>
      dispatch(actions.selectTherapie(SelectedTherapie, callBack)),
    setContact: (selectedContact, callBack) =>
      dispatch(actions.selectedContact(selectedContact, callBack)),
    resetReport: () => dispatch(actions.resetReport()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewReportScreen);
