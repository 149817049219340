import React, {Component} from 'react';
import { View } from 'react-native';
import Cookies from 'js-cookie';
import {BASE_URL} from '../../../store/actions/urls';

export default class CaregiverDetailCommon extends Component {
  constructor(props) {
    super(props);
    let uri;
    if (this.props.uId) {
      uri =
        BASE_URL +
        '/api/profile/image/byId?journalUserId=' +
        this.props.uId +
        '&auth=' +
        Cookies.get('authToken');
    } else {
      uri = BASE_URL + '/api/profile/image/byId?auth=' + Cookies.get('authToken');
    }
    this.state = {
      imageLoading: true,
      name: props.name,
      uri: {
        uri: uri,
        cache: 'reload',
      },
    };
  }

  render() {
    return <View />;
  }
}

