import React, { Component } from "react";
import {View, Text, TouchableOpacity, Keyboard, Platform} from 'react-native';
import moment from "moment";
import _ from "lodash";
import AddEventNameDatePicker from "./AddEventNameDatePicker";
import AddEventCategoryType from "./AddEventCategoryType";
import AddEventDescription from "./AddEventDescription";
import AddEventAttachments from "./AddEventAttachments";
import AddEventTags from "../../../components/Tags/EventTags";
import SavingConfirmation from '../../../components/ConfirmationBox/savingConfirmation';
import ActivityIndicator from '../../../components/ActivityIndicator/activityIndicator';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import {alertBody, healthJourneyTitle} from '../../../GlobalFunctions/GlobalString';
import * as constants from '../HealthJourneyConstant';
import { HEALTH_JOURNEY, IMAGES_DOCUMENTS } from "../../../navigation/path";
import * as ColorCode from '../../../components/ColorPicker/ColorCodes';
import {checkCategoryItemsSavedOrNot} from '../../../util/commonUiLogic';
import { IOS, WEB } from "../../../util/platformWindowConfig";

class HealthJourneyAddEvent extends Component{

    constructor(props) {
        super(props);
        this.state = {
          type: null,
          eventName: null,
          startDate : null,
          endDate: null,
          isStartDaySelected: false,
          isEndDaySelected: false,
          description: null,
          tagsArray: [],
          attachments: [],
          categoryTypeItems: [],
          saveWait: false, 
          newCategoryTypeItems: [],
          isStartDateSelected:false,
          isEndDateSelected:false,
          deleting: false,
          mounted: false,
          showList : true,
        };
      }

    componentDidMount() {
      const {eventData} = this.props;
      const {type = null, eventName = null, startDate = null, endDate = null, isStartDaySelected = true, isEndDaySelected = true, description = null, tags, tagsArray, attachments = [], categoryTypeItems = [], newCategoryTypeItems = [], isEndDateSelected = false , isStartDateSelected =false} = eventData;
      if (!_.isEmpty(eventData)) {
        this.setState({
          type,
          eventName,
          startDate,
          endDate,
          isStartDaySelected,
          isEndDaySelected,
          description,
          tagsArray: tagsArray ? tagsArray : tags ? tags.includes(",") ? tags.split(",").map(t => ({tags: t})) : [{tags}] : [],
          attachments,
          categoryTypeItems,
          newCategoryTypeItems,
          isStartDateSelected,
          isEndDateSelected,
          mounted: true,
        });
      }
    }

    enableSaveButton = () => {
      const {type, eventName, startDate, endDate, attachments, categoryTypeItems} = this.state;
      const {isInitHashChanged} = this.props;
      let commonRequired = Boolean(eventName && startDate && (startDate && endDate ? !moment(startDate).isAfter(endDate) : true) && isInitHashChanged);
      switch (type) {
        case constants.eventTypes.MEDICATIONS:
        case constants.eventTypes.SYMPTOMS:
        case constants.eventTypes.HEATHDATA:
          return Boolean(commonRequired && checkCategoryItemsSavedOrNot(categoryTypeItems));
        case constants.eventTypes.ATTACHMENTS:
          return Boolean(commonRequired && attachments && attachments.length > 0);
        case constants.eventTypes.CUSTOM_NOTES:
          return commonRequired;
        default:
          return false;  
      }  
    }
    
    removeCategoryItems = async () => {
      const {newCategoryTypeItems, type} = this.state;
      const {onDeleteTherapie, journalUserId, commonErrorHandler, closeAddEventBox, onDeleteSymptoms, userListItems} = this.props;
      if (newCategoryTypeItems.length === 0) {
        closeAddEventBox();
        return;
      }
      this.setState({deleting: true});
      let promises = [];
      if (type === constants.eventTypes.MEDICATIONS) {
        promises = newCategoryTypeItems.map(
          ({id}) =>
            new Promise((resolve, reject) =>
              onDeleteTherapie(
                id,
                res => {
                  if (res) {
                    resolve("Passed");
                  } else {
                    reject(id);
                  }
                },
                journalUserId,
              ),
            ),
        );
      } else if (type === constants.eventTypes.SYMPTOMS) {
        promises = newCategoryTypeItems.map(
          ({id}) =>
            new Promise((resolve, reject) =>
              onDeleteSymptoms(
                id,
                userListItems,
                res => {
                  if (res) {
                    resolve("Passed");
                  } else {
                    reject(id);
                  }
                },
                journalUserId
              ),
            ),
        );
      }

      const results = await Promise.allSettled(promises);
      const failed = results.filter(item => item.status === "rejected").map(item => item.reason);
      if (failed.length > 0)
        commonErrorHandler(`Failed to delete ${failed.join(",")}`);
      this.setState({deleting: false});
      closeAddEventBox();
    }

    /**
     * @param {{}} item 
     * @param {"MEDICATIONS" | "SYMPTOMS" | "HEALTHDATA"} type 
     * @param {{} | undefined} drugAdministrationsDigits 
     * @returns {Promise<{}>} Returns a promise which can be used to save the category data
     */
    static getCategoryItem = (item, type, drugAdministrationsDigits) => {
      return new Promise(async (reso, reje) => {
        try {
          let processedItem = {};
          if (type === constants.eventTypes.MEDICATIONS) {
            const {
              dosages,
              treatmentType = '',
              displayProperties = {},
              //identificationNumber = '',
            } = item;
            const startDate = dosages && dosages.length > 0 ? dosages[0].start : Date.now();
            const endDate = dosages && dosages.length > 0 ? dosages[0].end : null;
            const quantity = dosages && dosages.length > 0 ? dosages[0].quantity : null;
            const frequency = dosages && dosages.length > 0 ? dosages[0].frequency : null;
            const frequencyType = dosages && dosages.length > 0 ? dosages[0].frequencyType : 'DAILY';
            const instructions = dosages && dosages.length > 0 ? dosages[0].instructions : '';
            let {color} = displayProperties ? displayProperties : {};
            color = color ? color : treatmentType === 3 ? ColorCode.BGSUPPLEMENT : ColorCode.BGMEDICATION;
            let unit = dosages && dosages.length > 0 ? dosages[0].units : '';
            processedItem = {
              treatment: {
                ...item,
                dosages: [
                  {
                    id: -1,
                    start: moment.utc(startDate).valueOf(),
                    end: moment.utc(endDate).valueOf(),
                    quantity,
                    frequency,
                    frequencyType,
                    instructions,
                    units: unit,
                  },
                ],
                displayProperties: {
                  id: null,
                  createdOn: null,
                  modifiedOn: null,
                  color,
                  artifactId: null,
                  dashedLine: true,
                },
              },
            };
          }
          return reso(processedItem);
        } catch (error) {
          return reje("Aborting");
        }
      })
    }

    handleOnSaveEvent = () => {
      this.setState({saveWait: true});
      Keyboard.dismiss();
      setTimeout(() => {
      const {onPressAdd, eventData, journalUserId, updateDispalyConfirmBox} = this.props;
      const {eventName, description, tagsArray, startDate, endDate, attachments, categoryTypeItems} = this.state;
      updateDispalyConfirmBox()
      const newAttachments = attachments.filter(item => !item.id);
      const data = {
        id: eventData && eventData.id ? eventData.id : "-1",
        eventName,
        description,
        tags: tagsArray.filter(t => t.tags).map(t => t.tags).join(","),
        startDate:moment(startDate).format("YYYY-MM-DD"),
        categoryType: eventData.type,
        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
        medications: eventData.type === constants.eventTypes.MEDICATIONS ? categoryTypeItems.map(item => item.id) : [],
        symptoms: eventData.type === constants.eventTypes.SYMPTOMS ? categoryTypeItems.map(item => item.id) : [],
        healthData: eventData.type === constants.eventTypes.HEATHDATA ? categoryTypeItems.map(item => item.id) : [],
        publishedBy: eventData && eventData.publishedBy ? eventData.publishedBy : journalUserId ? constants.eventPublishedBy.CAREGIVER : constants.eventPublishedBy.SELF,
        userAttachments: attachments.filter(item => item.id).map(item => ({attachmentId: item.id, attachmentName: `${item.fileName}.${item.fileExtension}`})),
        isStartDateSelected: startDate && startDate.length == 10 && this.state.isStartDaySelected?true : false,
        isEndDateSelected: endDate && endDate.length == 10 && this.state.isEndDaySelected? true :false
      };
      onPressAdd(data, newAttachments, () => this.setState({saveWait: false}));
    }, 500);
    }

    updateProperties = obj => {
      const {updateEventData} = this.props;
      this.setState({...obj});
      updateEventData(obj);
    }

    syncEventData = () => {
      const {setEventData, eventData, initHashValue} = this.props;
      const {type, eventName, description, tagsArray, startDate, endDate, attachments, categoryTypeItems, newCategoryTypeItems} = this.state;
      setEventData({
        ...eventData,
        type,
        eventName,
        description,
        tagsArray,
        startDate,
        endDate,
        attachments,
        categoryTypeItems,
        newCategoryTypeItems,
        initHashValue: initHashValue
      });
    }
    setIsStartDaySelectedHandler = (props)=>{
      this.setState({isStartDaySelected: props})
    }
    setIsEndDaySelectedHandler = (props)=>{
      this.setState({isEndDaySelected: props})
    }

    redirectSomeware = (path, params) => {
      const {navigation} = this.props;
      this.syncEventData();
      navigation.navigate(path, params && {
        from: HEALTH_JOURNEY.path
      });
    }

    dismissTagList = () => {
      this.setState({showList : false, isTagFocused : true})
      setTimeout(()=>{
        this.setState({showList: true, isTagFocused : false})
      },100)
    }

    render () {
     const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
     const { onPressCancel, windowWidth, activeBubbleId, setActiveBubble, dispalyConfirmBox, updateDispalyConfirmBox, closeAddEventBox, eventData, commonErrorHandler, userListItems, systemDefinedMetrics, updateSystemDefinedSymptoms, route, navigation, journalUserId, userSubscriptions, onDeleteTherapie, newTherapie, isNew, drugAdministrationsDigits, updateTherapie, resetNewTherapie, editTherapie, createUserSymptom, onDeleteSymptoms, journalEntries, onSelectSymptom, onSelectTherapie, dob, fetchHealthJourneyTagsList} = this.props;
     const { type, eventName, startDate, endDate, isStartDaySelected, isEndDaySelected, description, tagsArray, attachments, categoryTypeItems, saveWait, newCategoryTypeItems, isStartDateSelected , isEndDateSelected, deleting, mounted } = this.state;
     let saveDisabled = !this.enableSaveButton();
     if (Platform.OS === IOS && Boolean(route?.params?.attachments) && Boolean(route?.params?.attachments.length !== attachments.length) && Boolean(route?.params?.attachments.length > attachments.length)) {
      this.setState({
        attachments: route?.params?.attachments
      }, () => {
        this.props.navigation.setParams({attachments: null});
      });
    }
     return (
        <React.Fragment>
            {/* Desktop View */}
            <View style={[stylesRes.largerScrnVisibiliyOp]} dataSet={{media: ids.largerScrnVisibiliyOp}}>
                {/* ADD NEW EVENT SECTION */}
                <View style={[styles.flexRow, styles.flexAiFs, styles.flexJcFs, styles.mgTpSeventy, styles.zIndexNine]}>
                    <View style={[styles.mgRtTwelve, styles.pdTopSm]}>
                        <View style={styles.circleHealthJourneyCrd} />
                    </View>
                    {/* ADD EVENT BOX */}
                    <View style={[styles.flexCom, styles.rightSpExLg]}>
                        <View style={[
                            styles.addEvntBox, 
                            styles.pdLtFifty, 
                            styles.contPdTop, 
                            styles.infoPdBotImg, 
                            styles.pdRtThirtyFive,
                            // styles.overflowHid
                        ]}>
                          <View>
                            <View style={styles.zIndexTwo}>
                            {/* Name & Date Picker */}
                            <AddEventNameDatePicker
                                eventName={eventName}
                                dob={dob}
                                onChangeEventName={(value) => this.updateProperties({eventName: value})}
                                startDate={startDate}
                                setStartDate={(date) => this.updateProperties({startDate: date})}
                                endDate={endDate}
                                setEndDate={(date) => this.updateProperties({endDate: date})}
                                isStartDaySelected={isStartDaySelected}
                                isEndDaySelected={isEndDaySelected}
                                setIsStartDaySelected={(value) => this.setIsStartDaySelectedHandler(value)}
                                setIsEndDaySelected={(value) => this.setIsEndDaySelectedHandler(value) }
                                activeBubbleId={activeBubbleId}
                                setActiveBubble={setActiveBubble}
                                isStartDateSelected = {this.state.isStartDateSelected}
                                isEndDateSelected = {this.state.isEndDateSelected}
                                autoFocus={Boolean(
                                  route?.params?.from === HEALTH_JOURNEY.addAttachments ||
                                  route?.params?.from === IMAGES_DOCUMENTS.path
                                )}
                                dismissTagList={this.dismissTagList}
                            />
                            </View>
                            <View style={styles.zIndexOne}>
                              {/* Meds or Symptoms or Health data */}
                              {!_.isEmpty(eventData) && 
                                  type !== constants.eventTypes.CUSTOM_NOTES && 
                                  type !== constants.eventTypes.ATTACHMENTS &&
                                    <AddEventCategoryType 
                                      categoryType={type}
                                      items={categoryTypeItems}
                                      newItems={newCategoryTypeItems}
                                      userListItems={userListItems}
                                      systemDefinedMetrics={systemDefinedMetrics}
                                      updateCagoryList={(list, newList) => this.updateProperties({categoryTypeItems: list, newCategoryTypeItems: newList})}
                                      activeBubbleId={activeBubbleId}
                                      setActiveBubble={setActiveBubble}
                                      navigation={navigation}
                                      journalUserId={journalUserId}
                                      userSubscriptions={userSubscriptions}
                                      onDeleteTherapie={onDeleteTherapie}
                                      editTherapie={editTherapie}
                                      newTherapie={newTherapie}
                                      isNew={isNew}
                                      drugAdministrationsDigits={drugAdministrationsDigits}
                                      updateTherapie={updateTherapie}
                                      resetNewTherapie={resetNewTherapie}
                                      commonErrorHandler={commonErrorHandler}
                                      syncEventData={this.syncEventData}
                                      createUserSymptom={createUserSymptom}
                                      onDeleteSymptoms={onDeleteSymptoms}
                                      journalEntries={journalEntries}
                                      onSelectSymptom={onSelectSymptom}
                                      onSelectTherapie={onSelectTherapie}
                                      parentMounted={mounted}
                                      updateSystemDefinedSymptoms={updateSystemDefinedSymptoms}
                                      dismissTagList={this.dismissTagList} />
                              }
                              {type === constants.eventTypes.ATTACHMENTS &&
                                <AddEventAttachments 
                                  attachments={attachments} 
                                  windowWidth={windowWidth} 
                                  updateAttachments={attach => this.updateProperties({attachments: attach})}
                                  addAttachmentsTakePhoto={() => this.redirectSomeware(HEALTH_JOURNEY.addAttachments, false)}
                                  commonErrorHandler={commonErrorHandler}
                                  addAttachmentsFromZamplo={() => this.redirectSomeware(IMAGES_DOCUMENTS.path, true)}
                                  isFromAddAttachmentCategory
                                  activeBubbleId={activeBubbleId}
                                  setActiveBubble={setActiveBubble}
                                />
                              }
                            </View>
                            <View style={styles.zIndexZero}>
                            {/* Description */}
                            <AddEventDescription 
                                handleOnDescriptionDone={(value) => this.updateProperties({description: value})}
                                windowWidth={windowWidth}
                                description={description}
                                activeBubbleId={activeBubbleId}
                                setActiveBubble={setActiveBubble}
                                dismissTagList={this.dismissTagList}
                            />   
                            </View>

                            {/* Attachments */} 
                            {type !== constants.eventTypes.ATTACHMENTS &&
                              <AddEventAttachments 
                                attachments={attachments} 
                                windowWidth={windowWidth} 
                                updateAttachments={attach => this.updateProperties({attachments: attach})}
                                addAttachmentsTakePhoto={() => this.redirectSomeware(HEALTH_JOURNEY.addAttachments, false)}
                                commonErrorHandler={commonErrorHandler}
                                addAttachmentsFromZamplo={() => this.redirectSomeware(IMAGES_DOCUMENTS.path, true)}
                                activeBubbleId={activeBubbleId}
                                setActiveBubble={setActiveBubble}
                              />
                            }

                            {/* Tags */}
                              <AddEventTags 
                                  windowWidth={windowWidth}
                                  updateTagArray={(tagsArray) => this.updateProperties({tagsArray: tagsArray})}
                                  tagsArray={tagsArray}
                                  activeBubbleId={activeBubbleId}
                                  setActiveBubble={setActiveBubble}
                                  fetchHealthJourneyTagsList={fetchHealthJourneyTagsList} /> 
                            </View>
                        </View>
                    </View>
                </View>

                {/* Buttons */}
                <View style={[styles.flexRow, styles.flexJcFe, styles.flexAiCt, styles.comMgTopLg, styles.mgBtFifty, styles.rightSpExLg, styles.zIndexZero]}>
                    <TouchableOpacity onPress={onPressCancel}>
                        <View style={[styles.newEventBtn,styles.newEventCancelBtn, styles.flexAiCt, styles.flexJcCt, styles.mgRtSix]}>
                            <Text style={[styles.textTitleBold, styles.textColorGreyLight, styles.btOne]}>Cancel</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={this.handleOnSaveEvent}
                        disabled={saveWait || saveDisabled}>
                        <View style={styles.btnSingleCent}>
                            {saveWait && (
                                <View style={[
                                    styles.indicatorIconView,
                                    styles.btnSubmitloaderCom,
                                ]} >
                                <ActivityIndicator
                                    pdSm={true}
                                    size="small"
                                    color={"#4C89DC"}
                                />
                                </View>
                            )}
                            <View 
                                style={[
                                    styles.newEventBtn,
                                    saveWait || saveDisabled ? styles.newEventSveBtnDisbaled : styles.newEventSveBtn, 
                                    styles.flexAiCt, 
                                    styles.flexJcCt, 
                                    styles.mgLtSix]}>
                                <Text style={[styles.textTitleBold, styles.textWhite, styles.btOne]}>Save</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>  
            {dispalyConfirmBox && (
                <SavingConfirmation
                    visibleModal={dispalyConfirmBox}
                    alertBody={alertBody}
                    alertTitle={healthJourneyTitle}
                    handleCancel={this.removeCategoryItems}
                    handleConfirm={() => {
                        this.handleOnSaveEvent();
                    }}
                    confirmWait={saveWait}
                    deleteWait={deleting}
                    saveDisabled={saveWait || saveDisabled || deleting}
                    cancelDisabled={saveWait}
                    onBackdropPress={() => {
                        updateDispalyConfirmBox()
                    }}
                />
            )}   
        </React.Fragment>
    );
}
};

export default HealthJourneyAddEvent;
