import {StyleSheet} from 'react-native';

export default StyleSheet.create({

    iconMgTop: {
        marginTop: 2,
      },

    titleBtnTouchWrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    
});
