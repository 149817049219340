import React from 'react';
import {View, Text} from 'react-native';
import styles from '../../Css';
import * as FontScaling from '../../components/Text/fontScaling';

const sectionTitle = props => {
  return (
    <View style={[styles.pageTitleSection, props.titleStyle]} nativeID={props.nativeId}>
      <Text style={[styles.textTitleMdBold, props.smallTextTilte && styles.textTitleBold]}  maxFontSizeMultiplier = {FontScaling.fontScaleLg}>
        {props.title}
      </Text>
    </View>
  );
};

export default sectionTitle;
