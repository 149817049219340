import React, { useState } from "react";
import { Image, View, Text, TouchableOpacity } from "react-native";
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';

const SortByDropDown = (props) => {
  const FILTER_TAG = 3; 
  const { selectedSortFilterItem, setOnOpenFilter, sortType,
    setDropDownItemNumber } = props;
  const { setSortType } = props;
  const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
  const [sortOption, setSortOption] = useState(sortType);

  return (
    <React.Fragment>
      <View style={[styles.postAb, styles.mgLtFive, stylesRes.sortNewestDropDown, {marginTop: 32}]} dataSet={{ media: ids.sortNewestDropDown }} onStartShouldSetResponder={(e) => e.stopPropagation()}>
        <View style={[stylesRes.sortNewestDropDownTp, styles.bgWhite, styles.boxBorder, styles.borderBtRadiusEight]} dataSet={{ media: ids.sortNewestDropDownTp }}>
          <View style={[styles.borderBotGrey, styles.pdTpEleven, styles.pdBtEight, stylesRes.sortNwPdOpFr]} dataSet={{ media: ids.sortNwPdOpFr }}>
            <Text style={styles.textPrimeSm}>
              Sort by:
            </Text>
          </View>

          <TouchableOpacity onPress={() => [setDropDownItemNumber(FILTER_TAG), setOnOpenFilter(false), setSortType(1)]}>
            <View style={[styles.borderBotGrey, styles.flexRow, styles.flexJcSb, styles.flexAiCt, styles.pdTpEight, styles.pdBtEight, stylesRes.sortNwPdOp]} dataSet={{ media: ids.sortNwPdOp }}>
              <Text style={styles.textPrime}>
                Newest to Oldest
              </Text>
              {sortType === 1 ?
                <Image
                  style={styles.healthJrnTickIcn}
                  source={require('../../../assets/health-journey-icons/tick-icon.png')}
                /> : null
              }
            </View>
          </TouchableOpacity>
          
          <TouchableOpacity onPress={() => [setDropDownItemNumber(FILTER_TAG), setOnOpenFilter(false), setSortType(0)]}>
            <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt, styles.pdTpEight, styles.pdBtEight, stylesRes.sortNwPdOp]} dataSet={{ media: ids.sortNwPdOp }}>
              <Text style={styles.textPrime}>
                Oldest to Newest
              </Text>
              {sortType === 0 ?
                <Image
                  style={styles.healthJrnTickIcn}
                  source={require('../../../assets/health-journey-icons/tick-icon.png')}
                /> : null
              }
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
      fetchHealthJourneyTagsList: state.healthJourney.fetchHealthJourneyTagsList,
      advancedFilterAttachments: state.healthJourneyFilterObjects.advancedFilterAttachments,
      shouldComponentsDisabled: state.healthJourneyFiltering.shouldComponentsDisabled,
      sortType: state.healthJourneyFiltering.sortType,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSortType: (sortType) =>
          dispatch(actions.setSortType(sortType)),
    setDropDownItemNumber: (itemNumber) => 
      dispatch(actions.setDropDownItemNumber(itemNumber)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (SortByDropDown);
