import React from "react";
import { Image, View } from "react-native";
import styles from "../../../Css";
const qrCode = (props) => {
  return (
    <>
      <View style={[styles.qrWrapper]}>
        <Image
          style={styles.qrImg}
          source={require("../../../assets/store/qr.png")}
        />
      </View>
    </>
  );
};

export default qrCode;
