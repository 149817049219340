import React, { useState } from "react";
import {View} from "react-native";
import {Document, Page, pdfjs} from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {getNavigationPane, nextPage, previousPage} from "./PDFViewerCommon";
if (process.env.NODE_ENV === 'development') {
  // do nothing
} else {
  pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';
}

const PDF_VIEWER_WIDTH = 800;

export function PDFViewer({ url, onPageChanged, onLoadSuccess }) {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [consentPdfUrlLoaded, setConsentPdfUrlLoaded] = useState(false);

  function onPdfLoadSuccess({numPages}) {
    setNumPages(numPages);
    setPageNumber(1);
    setConsentPdfUrlLoaded(true);
    if (onLoadSuccess) {
      onLoadSuccess(numPages);
    }
  }

  return (
    <View>
      {Boolean(consentPdfUrlLoaded) && getNavigationPane(pageNumber, numPages,
        () => previousPage(pageNumber, numPages, setPageNumber, onPageChanged),
        () => nextPage(pageNumber, numPages, setPageNumber, onPageChanged))}
      <Document file={url} onLoadSuccess={onPdfLoadSuccess}>
        <Page
          pageNumber={pageNumber}
          width={PDF_VIEWER_WIDTH}
        />
      </Document>
      {Boolean(consentPdfUrlLoaded) && getNavigationPane(pageNumber, numPages,
        () => previousPage(pageNumber, numPages, setPageNumber, onPageChanged),
        () => nextPage(pageNumber, numPages, setPageNumber, onPageChanged))}
    </View>
  );
}