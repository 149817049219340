import React, { useState } from 'react';
import {View, TouchableOpacity, Text, Image} from 'react-native';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { DASHBOARD, MEDICATIONS, ROUTINES } from '../../../navigation/path';
import styles from '../../../Css';
import { dashboardCategories, dashboardIssues, getDashboardData } from '../../../util/commonUiLogic';

const CheckInEmptyItem = props => {
  const {type, issue, navigation, triggerAddMeds, addTherapie} = props;
  const [wait, setwait] = useState(false);
  const {
    createButtonText,
    emptyMessage,
    noPinnedMessage,
    viewButtonText,
  } = getDashboardData(type);

  const handleNavigation = () => {
    if (issue === dashboardIssues.NODATA) {
      handleCreations()
    } else {
      handleViews()
    }
  }

  const handleCreations = () => {
    switch (type) {
      case dashboardCategories.ROUTINES:
        return navigateSomewhere(ROUTINES.addRoutineTemplatePath)
      case dashboardCategories.MEDICATIONS:
        return triggerAddMeds()
      case dashboardCategories.ACTIVITIES:
        return handleAddTherapie();
      default:
        break;
    }
  }

  const handleAddTherapie = () => {
    setwait(true)
    addTherapie(() => setwait(false));
  }

  const handleViews = () => {
    switch (type) {
      case dashboardCategories.ROUTINES:
        return navigateSomewhere(ROUTINES.path)
      case dashboardCategories.MEDICATIONS:
        case dashboardCategories.ACTIVITIES:
        return navigateSomewhere(MEDICATIONS.path)
      default:
        break;
    }
  }

  const navigateSomewhere = path => {
    navigation.reset({
      index: 0,
      routes: [{ 
        name: path,
        params: {
          from: DASHBOARD.path,
        } 
      }],
    });
  }

  return (
    <View style={styles.flexJcCtAiCt}>
      {type === dashboardCategories.SCHEDULES ? (
        <>
          <Image
            style={[styles.comMgTopExLg, styles.editIconDash]}
            source={require('../../../assets/dashbord/complete-tick.png')}
          />
          <Text style={[styles.detailTextBold, styles.comSpLg]} testID={'EMPTY_MESSAGE_' + type} allowFontScaling={false}>{emptyMessage}</Text>
        </>
      ) : (
        <>
          <Text style={[styles.detailTextBold, styles.comMgTopExLg]} allowFontScaling={false}>{issue === "NODATA" ? emptyMessage : noPinnedMessage}</Text>
          <TouchableOpacity testID={'CREATE_BUTTON_' + type} onPress={handleNavigation} disabled={wait} style={[styles.flexJcCtAiCt, styles.comSpLg, styles.blueborder]}>
            <Text style={[styles.textPrimeBold, styles.resourceBlueTxt]} allowFontScaling={false}>{issue === "NODATA" ? createButtonText : viewButtonText}</Text>
          </TouchableOpacity>
        </>
      )}
    </View>
  );
};


const mapStateToProps = (state) => {
  return {
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    triggerAddMeds: () => dispatch(actions.setFromPopup('MEDICATION', true)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckInEmptyItem);
