import React from "react";
import { View, Image } from "react-native";
import styles from "../../Css";
import {
  Menu,
  MenuOptions,
  MenuTrigger,
  renderers,
} from "react-native-popup-menu";
const { Popover } = renderers;
const textFieldToolTip = (props) => {
  return (
    <View style={[styles.btnActionView, styles.btnActionInfo]}>
      <Menu
        renderer={Popover}
        rendererprops={{
          preferredPlacement: "top",
        }}
        // rendererProps={{
        //   placement : 'top',
        // }}
      >
        <MenuTrigger>
          { props.isBlueIcon ? 
            <Image
              style={[styles.editDarkIcon]}
              source={require("../../assets/icons/info-blue.png")} /> :
            <Image
              style={[styles.infoBtnSm]}
              source={require("../../assets/icons/info.png")} /> 
          }
        </MenuTrigger>
        <MenuOptions style={styles.dropDownList}>
          {props.renderComponent && props.renderComponent()}
        </MenuOptions>
      </Menu>
    </View>
  );
};

export default textFieldToolTip;
