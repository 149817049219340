import {StyleSheet} from 'react-native';
import theme from '../../css/base/theme';

export default StyleSheet.create({

  sliderTitle: {
    fontWeight: '700',
    marginBottom: 12,
  },

  sliderBlockWrapper: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 10,
    //marginRight: 10,
  },

  sliderIndexWrapper: {
    alignItems: 'center',
    //height: 'inherit',
    justifyContent: 'center',
    zIndex: 999,
  },

  sliderBlockButtonWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottomColor: 'transparent',
    borderTopColor:  'transparent',
    borderLeftColor: '#fff',
    borderRightColor: '#fff',
    borderWidth: 0.25,
    height: 17,

  },

  sliderBlockButtonWrapperOrange: {
    backgroundColor: '#F5BF44',
  },


  arrowHead: {
    bottom: -22,
    height: 19,
    left: 0,
    width: 21,
  },

  indexText: {
    fontWeight: '700',
    fontSize: theme.FONT_SIZE_X_SM
  },


  tooltipWrapper: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 35,
    zIndex: 9,


  },

  tooltipTextWrapper: {
    backgroundColor: '#fff',
    bottom: -22,
    width: 120,
    minWidth: 80,
    padding: 5,
    shadowColor: "#A3C5FE",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    textAlign: 'center',
    elevation: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },

  sliderOurtWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 5,
    paddingTop: 5,


  },

  textBox: {
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
    flexBasis: 42,
    height: 42,
    textAlign: 'center',
  },

  textBoxtext: {
    fontSize: theme.FONT_SIZE_X_LG,
    fontWeight: '700',
  },

});
