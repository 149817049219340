import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import styles from "../../../Css";
import Metric from "./Metric";
import { hasValue } from "../../../util/hashValueGenerator";
import {chartTypes} from '../../../Constant/index';
import {sortAlphabeticalOrder} from '../../../util/commonUiLogic';

let mMax = 1;
let mMin = 0;
class Metrics extends Component {
  constructor(props) {
    super(props);
    const {startDate, endDate} = props;
    const hash = hasValue(
        startDate +
          "," +
          endDate
    );
    this.state = {
      startDate: startDate,
      endDate: endDate,
      hash: hash
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {startDate, endDate} = props;
    const changeHasValue = hasValue(
        startDate +
          "," +
          endDate
    );
    if (state.hash !== changeHasValue) {
      return {
        startDate: startDate,
        endDate: endDate,
        hash: changeHasValue
      };
    }
    return null;
  }

  getMetricsData = (startDate, endDate) => {
    const metricsData = {};

    const journalEntries = Object.values(this.props.journalEntriesProcessed);
    if (journalEntries.length > 0) {
      if (startDate) {
        startDate.setHours(0, 0, 0, 0);
      }

      if (endDate) {
        endDate.setHours(23, 59, 59, 59);
      }

      let firstDate = new Date();
      firstDate.setDate(firstDate.getDate() - 14);
      firstDate.setHours(0, 0, 0, 0);

      let journalEntriesFilterd = [];
      if (startDate && endDate) {
        journalEntriesFilterd = journalEntries.filter(
          item =>
            item.metrics &&
            item.metrics.length > 0 &&
            item.entryDate > startDate.getTime() &&
            item.entryDate < endDate.getTime(),
        );
      } else {
        journalEntriesFilterd = journalEntries.filter(
          item =>
            item.metrics &&
            item.metrics.length > 0 &&
            item.entryDate > firstDate.getTime(),
        );
      }

      journalEntriesFilterd.forEach(item => {
        let filterMetrics = item.metrics;
        filterMetrics = filterMetrics.filter(item => item.value);

        filterMetrics.forEach(metricItem => {
          if (metricItem.value) {
            let data = [];
            if (metricsData[metricItem.metric]) {
              data = [...metricsData[metricItem.metric]];
            }
            data.push({...metricItem, value: this.getMetricValue(metricItem)}); 
            metricsData[metricItem.metric] = data;
          }
        });
      });
    }

    return metricsData;
  };

  getMetricValue = (metric) => {
    const unitsOfMeasure =
      this.props.unitsOfMeasureDigestProcessed.unitsOfMeasure[metric.unitOfMeasure];
    if (unitsOfMeasure) {
      let display = this.props.userDefinedMetricUnitOfMeasures.find(function (
        element
      ) {
        return element.metricId === metric.metric;
      });

      if (!display) {
        display = this.props.systemDefinedMetricUnitOfMeasures.find(function (
          element
        ) {
          return element.metricId === metric.metric;
        });
      }

      if (display && display?.uomId) {
      const dislayUnit =
        this.props.unitsOfMeasureDigestProcessed.unitsOfMeasure[display.uomId];

      const baseValue =
        (metric.value - unitsOfMeasure.baseOffset) * unitsOfMeasure.baseScale;

      const dislayvalue =
        baseValue / dislayUnit.baseScale + dislayUnit.baseOffset;

      return dislayvalue;
      }
    }

    return metric.value;
  };

  getMinMax = (metricsData, metric) => {
    mMax = 1;
    mMin = 0;
    Object.keys(metricsData).forEach((item, index) => { 
      if(item == metric) {
        let metricSeriesData = metricsData[item];
        metricSeriesData.forEach(data => {
          const val = Math.ceil(data.value);
          if (mMin > val) {
            mMin = val;
          }
          if (mMax < val) {
            mMax = val;
          }
        });
        return;
      }
    });
  }
  
  render() {
    const {startDate, endDate} = this.state;
    const metricsData = this.getMetricsData(
      startDate ? new Date(startDate) : null,
      endDate ? new Date(endDate) : null)
    return (
      <View style={styles.collapseContent}>
        <View style={styles.collapseDescription}>
          <View style={styles.collapseInContent}>
            <View style={styles.secSubContentArea}>
              {sortAlphabeticalOrder(this.props.metricsDigest.userDefinedMetrics)
                .map(item => (
                  this.getMinMax(metricsData, item.id),
                  <Metric
                    item={item}
                    key={"me_" + item.id}
                    configChartType={item.displayProperties?.style || (item.displayProperties?.dashedLine === true ? chartTypes.line : chartTypes.dash)}
                    defaultMin={mMin}
                    defaultMax={mMax}
                    fullWidth={this.props.fullWidth}
                    publishedByPartner={this.props.publishedByPartner}
                    routineMetrics={this.props.routineMetrics}
                    colorPicked={this.props.colorPicked}
                    addNewUserMetric={this.props.addNewUserMetric}
                  />
              ))}
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    metricsDigest: state.routines.metricsDigest,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    startDate: state.rollinggraphs.startDate,
    endDate: state.rollinggraphs.endDate,
    userDefinedMetricUnitOfMeasures:
      state.routines.metricsDigest.userDefinedMetricUnitOfMeasures,
    systemDefinedMetricUnitOfMeasures:
      state.routines.metricsDigest.systemDefinedMetricUnitOfMeasures,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
  };
};

export default connect(
  mapStateToProps,
  null
)(Metrics);
