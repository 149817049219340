import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import styles from '../../../../../Css';

const setUpReminder = props => {
  const {yesNo, onPressYesNo} = props;
  return (
    <View style={styles.sectionView}>
      <View style={[styles.questionWrapper]}>
        <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
          Would you like to setup a Reminder?
        </Text>
      </View>

      <View style={[styles.answerWrapper]}>
        <View style={[styles.answerCustomOpt]}>
          <View style={[styles.answerOptionRowSpec]}>
            <View style={[styles.answerOptionInd, styles.rightSpExLg]}>
              <TouchableOpacity
                onPress={() => onPressYesNo(true)}
                style={[styles.answerOptMg, styles.answerViewWrapper]}>
                <View style={[styles.optionWrapper]}>
                  {yesNo ? (
                    <Image
                      style={styles.radioIcon}
                      source={require('../../../../../assets/icons/radio-selected.png')}
                    />
                  ) : (
                    <Image
                      style={styles.radioIcon}
                      source={require('../../../../../assets/icons/radio-default.png')}
                    />
                  )}
                </View>
                <View style={[styles.answerTextWrapper, styles.flexRow]}>
                  <Text style={[styles.textPrime]} allowFontScaling={false}>Yes</Text>
                </View>
              </TouchableOpacity>
            </View>
            <View style={[styles.answerOptionInd]}>
              <TouchableOpacity
                onPress={() => onPressYesNo(false)}
                style={[styles.answerOptMg, styles.answerViewWrapper]}>
                <View style={[styles.optionWrapper]}>
                  {!yesNo ? (
                    <Image
                      style={styles.radioIcon}
                      source={require('../../../../../assets/icons/radio-selected.png')}
                    />
                  ) : (
                    <Image
                      style={styles.radioIcon}
                      source={require('../../../../../assets/icons/radio-default.png')}
                    />
                  )}
                </View>
                <View style={[styles.answerTextWrapper]}>
                  <Text style={[styles.textPrime]} allowFontScaling={false}>No</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default (setUpReminder);
