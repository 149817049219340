import React, {Component} from 'react';
import {View, Text, TextInput} from 'react-native';
import {connect} from 'react-redux';
import styles from '../../Css';
import UIText from '../../components/Text/text';
import RadioForm from '../../modules/radioButton/RadioButton';
import * as actions from '../../store/actions/index';
import Dates from '../DateTimePicker/Dates';

const radioProps = [
  {label: 'All', value: 0},
  {label: '1 month', value: 1},
  {label: '3 months', value: 2},
  {label: 'Custom', value: 3},
];

class ReportDetail extends Component {
  constructor(props) {
    super(props);
    const date1 = new Date(props.startDate);
    const date2 = new Date(props.endDate);
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    this.textInput = null;
    this.startDate = props.startDate;
    this.endDate = props.endDate;
    if (!(props.startDate && props.endDate)) {
      const journalEntries = Object.values(this.props.journalEntriesProcessed);
      if (journalEntries && journalEntries.length > 0) {
        journalEntries.sort(function(a, b) {
          return parseInt(a.entryDate) - parseInt(b.entryDate);
        });
      }
      this.startDate = new Date();
      this.endDate = new Date();
      if (journalEntries && journalEntries.length > 0) {
        this.startDate = new Date(journalEntries[0].entryDate);
        // endDate = new Date(
        //   this.props.journalEntries[
        //     this.props.journalEntries.length - 1
        //   ].entryDate
        // );
      }
    }
    this.state = {
      name: props.name ? props.name : '',
      validName: true,
      // startDate: startDate,
      validStartDate: props.validStartDate,
      // endDate: endDate,
      validEndDate: props.validEndDate,
      // showStartDate: this.startDate
      //   ? moment(this.startDate).format("DD MMM 'YY")
      //   : "",
      // showEndDate: this.endDate ? moment(this.endDate).format("DD MMM 'YY") : "",
      viewBy:
        props.viewBy !== null
          ? props.viewBy
          : !(props.startDate && props.endDate)
          ? 0
          : diffDays === 31
          ? 1
          : diffDays === 91
          ? 2
          : 3,
      endDateClick: false,
      startDateClick: false,
    };
    setTimeout(() => {
      this.updateGlobaleSate(props.viewBy === null ? true : false);
    }, 100);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.state.name || this.state.name === '') {
        if(this.textInput) {
          this.textInput.focus();
        }
      }
    }, 200);
  }

  handleOnChangeName = val => {
    this.setState({
      name: val, 
      validName: val ? true : false
    }, 
    () => this.updateGlobaleSate(false)
    );
  };
  
  updateGlobaleSate = initial => {
    let filteredRecordedSymptom = initial
      ? this.props.recordedSymptom
      : this.props.filteredRecordedSymptom &&
        this.props.filteredRecordedSymptom.length > 0
      ? this.props.filteredRecordedSymptom
      : [];
    let filteredRecordedDosing = initial
      ? this.props.recordedDosing
      : this.props.filteredRecordedDosing &&
        this.props.filteredRecordedDosing.length > 0
      ? this.props.filteredRecordedDosing
      : [];
    let selectedIssues = this.props.selectedIssues;
    let selectedIssuesObj = this.props.selectedIssuesObj;
    let selectedImages = this.props.selectedImages;
    let selectedImagesObj = this.props.selectedImagesObj;
    if (this.startDate && this.endDate && initial) {
      const startDate = new Date(this.startDate.getTime());
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(this.endDate.getTime());
      endDate.setHours(23, 59, 59, 59);

      filteredRecordedSymptom = this.props.recordedSymptom.filter(function(
        recordedSymptom,
      ) {
        return (
          recordedSymptom.createdOn > startDate.getTime() &&
          recordedSymptom.createdOn < endDate.getTime()
        );
      });

      filteredRecordedDosing = this.props.recordedDosing.filter(function(
        recordedDosing,
      ) {
        return (
          (recordedDosing.start >= startDate.getTime() &&
            recordedDosing.start <= endDate.getTime()) ||
          (recordedDosing.end >= startDate.getTime() &&
            recordedDosing.end <= endDate.getTime()) ||
          (recordedDosing.start <= startDate.getTime() &&
            recordedDosing.end >= endDate.getTime()) ||
          !recordedDosing.end
        );
      });

      if (this.props.selectedIssues) {
        selectedIssues = this.props.selectedIssues.filter(
          item =>
            item.modifiedOn > startDate.getTime() &&
            item.modifiedOn < endDate.getTime(),
        );

        selectedIssuesObj = {};
        selectedIssues.forEach(element => {
          selectedIssuesObj[element.id] = element;
        });
      }

      if (this.props.selectedImages) {
        selectedImages = this.props.selectedImages.filter(
          item =>
            item.modifiedOn > startDate.getTime() &&
            item.modifiedOn < endDate.getTime(),
        );

        selectedImagesObj = {};
        selectedImages.forEach(element => {
          selectedImagesObj[element.id] = element;
        });
      }
    }

    const userSymptomsProcessed = this.props.userSymptomsProcessed;
    filteredRecordedSymptom.sort(function(a, b) {
      var textA = userSymptomsProcessed[a.symptom]
        ? userSymptomsProcessed[a.symptom].name
        : '';
      var textB = userSymptomsProcessed[b.symptom]
        ? userSymptomsProcessed[b.symptom].name
        : '';
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    let graphid = this.props.graphImageS3;
    // if (initial || !graphid) {
    //   const now = new Date();
    //   graphid = now.getTime() + '_' + this.props.userId + '.png';
    // }

    this.props.updateReportDetail({
      name: this.state.name,
      //  validName: initial ? true : this.state.name ? true : false,
      startDate: this.startDate,
      endDate: this.endDate,
      filteredRecordedSymptom: this.removeDuplicateSymptom(
        filteredRecordedSymptom,
      ),
      filteredRecordedDosing: filteredRecordedDosing,
      selectedIssues: selectedIssues,
      selectedIssuesObj: selectedIssuesObj,
      selectedImages: selectedImages,
      selectedImagesObj: selectedImagesObj,
      viewBy: this.state.viewBy,
      graphid: graphid,
    });
  };

  handleOnPressRadioForm = value => {
    let startDate = new Date();
    this.setState({viewBy: value});
    if (value === 0) {
      const journalEntries = Object.values(this.props.journalEntriesProcessed);

      if (journalEntries && journalEntries.length > 0) {
        journalEntries.sort(function(a, b) {
          return parseInt(a.entryDate) - parseInt(b.entryDate);
        });
      }

      let endDate = new Date();
      if (journalEntries && journalEntries.length && journalEntries[0] && journalEntries[0].entryDate) {
        startDate = new Date(journalEntries[0].entryDate);
        // endDate = new Date(
        //   this.props.journalEntries[
        //     this.props.journalEntries.length - 1
        //   ].entryDate
        // );
      }
      this.startDate = new Date(
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
        ),
      );
      this.endDate = new Date(
        Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
      );

      this.setState(
        {
          viewBy: value,
        },
        () => this.updateGlobaleSate(true),
      );
    } else if (value === 3) {
      this.startDate = null;
      this.endDate = null;
      this.setState(
        {
          viewBy: value,
        },
        () => this.updateGlobaleSate(true),
      );
    } else if (value === 1) {
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 30);

      this.startDate = new Date(
        Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
      );
      this.endDate = new Date(
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
        ),
      );
      this.setState(
        {
          viewBy: value,
        },
        () => this.updateGlobaleSate(true),
      );
    } else if (value === 2) {
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 90);

      this.startDate = new Date(
        Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
      );

      this.endDate = new Date(
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
        ),
      );
      this.setState(
        {
          viewBy: value,
        },
        () => this.updateGlobaleSate(true),
      );
    }
  };

  removeDuplicateSymptom = filteredRecordedSymptom => {
    const unique = filteredRecordedSymptom
      .map(e => e['symptom'])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => filteredRecordedSymptom[e])
      .map(e => filteredRecordedSymptom[e]);

    return unique;
  };

  UNSAFE_componentWillReceiveProps(props) {
    const {validName} = props;
    if (this.state.validName !== validName) {
      this.setState({validName});
    }
  }

  updateStartDate = val => {
    this.startDate = val !== null ? new Date(val) : null;
    this.setState({viewBy: 3}, () => this.updateGlobaleSate(true));
  };

  updateEndDate = val => {
    this.endDate = val !== null ? new Date(val) : null;
    this.setState({viewBy: 3}, () => this.updateGlobaleSate(true));
  };

  render() {
    return (
      <View style={[styles.subSection, styles.colPdTopLtRt]}>
        <View style={[styles.fieldRow]}>
          <Text style={[styles.textView]} allowFontScaling={false}>
            <UIText
              style={[styles.textLabel, styles.bold, styles.textColorLight]}
              title="Title"
            />
          </Text>

          {/* <UITextInput
            style={
              this.state.validName
                ? styles.textField
                : [styles.textField, styles.invalidTextField]
            }
            onChangeText={this.handleOnChangeName}
            value={this.state.name}
            autoFocus={true}
            onBlur={() => this.updateGlobaleSate(false)}
            ref={input => {
              this.textInput["two"] = input;
            }}
          /> */}
          <TextInput
            style={[
              styles.textField,
              this.state.validName ? styles.validColor : styles.inValidColor,
            ]}
            allowFontScaling={false}
            onChangeText={this.handleOnChangeName}
            value={this.state.name}
            autoFocus={!this.state.name || this.state.name === ''}
            onBlur={() => this.updateGlobaleSate(false)}
            ref={input => {
              this.textInput = input;
            }}
            maxLength={50}
            underlineColorAndroid="transparent"
          />
        </View>
        <View style={styles.fieldRow}>
          <View style={styles.fieldCol}>
            <View style={[styles.fieldTopic, styles.pdBotXs]}>
              <Text style={styles.textPrime} allowFontScaling={false}>View by:</Text>
            </View>
            <View style={[styles.radioSecFul, styles.pdBotExSm]}>
              <RadioForm
                style={styles.radioSet}
                radio_props={radioProps}
                initial={this.state.viewBy}
                onPress={this.handleOnPressRadioForm}
                formHorizontal={true}
                buttonColor={'rgba(152, 152, 154, 0.6)'}
                selectedButtonColor={'#FF8300'}
              />
            </View>
          </View>
        </View>
        <Dates
          startDate={this.startDate}
          endDate={this.endDate}
          updateStartDate={this.updateStartDate}
          updateEndDate={this.updateEndDate}
        />
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    id: state.report.id,
    name: state.report.name,
    validName: state.report.validName,
    startDate: state.report.startDate,
    validStartDate: state.report.validStartDate,
    endDate: state.report.endDate,
    validEndDate: state.report.validEndDate,
    journalUserId: state.uistate.journalUserId,
    recordedSymptom: state.routines.recordedSymptom,
    recordedDosing: state.routines.recordedDosing,
    selectedIssues: state.report.selectedIssues,
    selectedIssuesObj: state.report.selectedIssuesObj,
    selectedImages: state.report.selectedImages,
    selectedImagesObj: state.report.selectedImagesObj,
    filteredRecordedSymptom: state.report.filteredRecordedSymptom,
    filteredRecordedDosing: state.report.filteredRecordedDosing,
    userSymptomsProcessed: state.routines.symptomsDigestProcessed.userSymptoms,
    viewBy: state.report.viewBy,
    graphImageS3: state.report.graphImageS3,
    userId: state.routines.user.id,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateReportDetail: data => dispatch(actions.updateReportDetail(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportDetail);
