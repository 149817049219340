import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import {Keyboard} from 'react-native';
import * as actions from '../../../../store/actions/index';
import AutoCompleteSuggestion from '../../AutoCompleteSuggestion';
import {autoSuggestionListType} from '../../../../Constant/index';

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      userTerms: this.getUserTerms(),
      terms: [],
    };
  }

  componentDidMount() {
    this.getSystemActivity();
  }

  getSystemActivity = () => {
    this.props.systemDefinedActivities((result, data) => {
      if (result) {
        this.setState({terms: data});
      }
    });
  };

  getUserTerms = () => {
    let termsArray = [];
    const {treatments} = this.props;
    if (treatments) {
      const suggestionResults = [...treatments];
      termsArray = suggestionResults.map(value => {
        return (
          value && value.treatmentType === 5 && {name: value.name, data: value}
        );
      });
    }

    return termsArray;
  };

  onItemPressName = (selection, type) => {
    const {onPressItem} = this.props;

    this.setState(
      {
        name: selection.name,
      },
      () => {
        onPressItem(selection, type);
        Keyboard.dismiss();
      },
    );
  };

  onChangeTextName = currentInput => {
    this.setState({
      name: currentInput,
    });
  };

  onPressCustomName = (selection, type) => {
    if (selection && selection.trim() !== '') {
      const {onPressItem} = this.props;
      this.setState(
        {
          name: '',
        },
        () => {
          onPressItem(selection, type);
          Keyboard.dismiss();
        },
      );
    }
  };

  render() {
    const {updateAutocompleteOpen, selectedValues} = this.props;
    const {name, userTerms, terms} = this.state;
    return (
      <AutoCompleteSuggestion
        textFieldWithTitle={true}
        textFieldTitle="Enter Activity"
        // toolTipButton={false}
        // toolTipId={'tool-tip-med'}
        // toolTipContent={
        //   'Record any activity including custom physical and social activities'
        // }
        fieldBg={FieldDetails.BGWHITE}
        remortSearch={false}
        //triggerLength={1}
        onChangeText={this.onChangeTextName}
        onItemPress={this.onItemPressName}
        currentInput={name}
        onPressCustom={this.onPressCustomName}
        customSectionTitle={'Create custom'}
        showCustomSection={true}
        autoFocus={true}
        userTerms={userTerms}
        terms={terms}
        updateAutocompleteOpen={updateAutocompleteOpen}
        selectedValues={selectedValues}
        userListTitle={'Select from existing activities'}
        systemListTitel={'Select from suggested activities'}
        disabledScroll={this.props.disabledScroll}
        clearTextOnBlur={true}
        updateTermList={() => this.getSystemActivity()}
        type={autoSuggestionListType.activity}
        zoomIcon={true}
        removeIcon={true}
        autoDisplayUserList={true}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    treatments: state.routines.treatments,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    systemDefinedActivities: callBack =>
      dispatch(actions.systemDefinedActivities(callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Activity);
