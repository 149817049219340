import React, {Component} from 'react';

import {
  View,
  TouchableWithoutFeedback,
  Platform,
  Animated,
} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import * as paths from '../../navigation/path';
import {
  CommonIconMenuItem,
  ReferFriendMenuItem,
  SubMenuTitel,
  CommonMenuItem,
  SetupWizardMenuItem,
  LogoutMenuItem
} from './MenuItem';
import {getUnseenRequestCount, getScrollHeight} from '../../util/commonUiLogic';
import {APP_FEATURE_MANAGE_DEVICE_ENABLED, APP_FEATURE_HEALTH_JOURNEY_ENABLED} from '../../util/featureConfig';
import {tabs} from '../../Constant/index';
import {toggleSetupModal} from '../../store/actions/uistate';
import {getCurrentSubscription, isSubscriptionSponsored} from "../../util/subscriptionInformation";
import ScrollViewWithScrollBarIndicator from "../ScrollViewWithScrollBarIndicator/ScrollViewWithScrollBarIndicator"
import {ANDROID, IOS, WEB} from '../../util/platformWindowConfig';


class MobileMoreMenu extends Component {
  constructor(props) {
    super(props);
    const {
      windowHeight,
      windowWidth,
      applicationSettingsDigest,
      seenNotification,
      myNetwork,
      unSeenConversations
    } = props;
    const {received} = myNetwork;
    const {isSetupOn} = applicationSettingsDigest;
    const scrollIndicator = new Animated.Value(0);
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      isSetupOn: isSetupOn,
      hasCommunityNotifications: getUnseenRequestCount(received) > 0 || Object.keys(unSeenConversations).length > 0,
      hasSurveyNotifications: Object.values(seenNotification).filter(res => res.type === 'SURVEY').length > 0,
      hasNotification: props.notification && Object.values(props.notification).length > 0,
      scrollIndicator: scrollIndicator
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      windowHeight,
      windowWidth,
      applicationSettingsDigest,
      seenNotification,
      myNetwork,
      unSeenConversations,
      notification
    } = props;
    const {received} = myNetwork;
    const {isSetupOn} = applicationSettingsDigest;
    let hasCommunityNotifications = state.hasCommunityNotifications;
    let hasSurveyNotifications = state.hasSurveyNotifications;
    let hasNotification = state.hasNotification;
    if (getUnseenRequestCount(received) > 0 || Object.keys(unSeenConversations).length > 0) {
      hasCommunityNotifications = true;
    } else {
      hasCommunityNotifications = false;
    }
    if (Object.values(seenNotification).filter(res => res.type === 'SURVEY').length > 0) {
      hasSurveyNotifications = true;
    } else {
      hasSurveyNotifications = false;
    }
    if (Object.values(notification).length > 0) {
      hasNotification = true;
    } else {
      hasNotification = false;
    }
    if (
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth ||
      isSetupOn !== state.isSetupOn ||
      hasCommunityNotifications !== state.hasCommunityNotifications ||
      hasSurveyNotifications !== state.hasSurveyNotifications
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        isSetupOn: isSetupOn,
        hasCommunityNotifications: hasCommunityNotifications,
        hasSurveyNotifications: hasSurveyNotifications,
        hasNotification: hasNotification,
      };
    }
    return null;
  }

  isSetupComplete = () => {
    const {
      conditions = [],
      userConditions = [],
      treatments = [],
      userTreatments = [],
      symptoms = [],
      userSymptoms = [],
      metrics = [],
      userDefinedMetrics = [],
      journalEntryTypes = [],
    } = this.props;

    if (
      ((conditions && conditions.length > 0) ||
        (userConditions && userConditions.length > 0)) &&
      ((treatments && treatments.length > 0) ||
        (userTreatments && userTreatments.length > 0)) &&
      ((symptoms && symptoms.length > 0) ||
        (userSymptoms && userSymptoms.length > 0)) &&
      ((metrics && metrics.length > 0) ||
        (userDefinedMetrics && userDefinedMetrics.length > 0)) &&
      (journalEntryTypes && journalEntryTypes.length > 0)
    ) {
      //all steps complete
      return true;
    }
    //at least one step is pending
    return false;
  };

  handleOnPressMenuItem = (notifType) => {
    const {
      seenNotification,
      onClearMenuSectionNotifications,
    } = this.props;
    if (notifType === 'PEER_NOTIFICATION') {

    }
    onClearMenuSectionNotifications(
      Object.values(seenNotification).filter(res => res.type === notifType)
    );
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {
      isSetupOn,
      windowHeight,
      windowWidth,
      hasSurveyNotifications,
    } = this.state;

    const {
      selectedMenu,
      navigation,
      handelOnMenuItemPress,
      showSetup,
      journalUserId,
      clearTherapyFilter,
      clearImageFilter,
      clearContactFilter,
      clearSymptomFilter,
      clearMetricFilter,
      clearGraphFilter,
      clearReportFilter,
      clearRoutineFilter,
      clearHealthLibraryFilter,
      clearQuestionFilter,
      userSubscription,
      onSetEventData,
      setActiveBubble,
      hasNotch,
      timeZone,
      timeZoneWarning,
    } = this.props;

    let fullHeight = windowHeight;
    let menuSpace = 100;

    // We don't explicitly hide the status bar on web or android
    // so whether there is a notch or not doesn't matter. Either way
    // using hasNotch on Android is totally incorrect - we should be
    // looking at the height of the status bar instead.
    const doesOSHaveHiddenStatusBar = Platform.OS !== WEB && Platform.OS !== ANDROID;
    let menuHeight = getScrollHeight(doesOSHaveHiddenStatusBar && hasNotch, journalUserId, timeZoneWarning, timeZone, fullHeight, windowWidth);

    let notchOffset = 0;
    if (doesOSHaveHiddenStatusBar && hasNotch) {
      notchOffset = 50;
    }

    let totHeight = fullHeight - menuHeight - notchOffset; // This is to fix menus being pushed down by the notch height and leaving a gap under the status
    console.log("totheight", totHeight);
    let scrollHeight = menuHeight - menuSpace;

    const currentSubscription = getCurrentSubscription(userSubscription);
    const isSponsoredSubscription = isSubscriptionSponsored(currentSubscription);

    return (
      <View
        style={[
          stylesRes.moreMenuOpenSm,
          // stylesRes.backDropBg,
          stylesRes.moreMenuOpenFixed,
          {height: fullHeight},
        ]}
        dataSet={{media: ids.moreMenuOpenSm}}>
        <TouchableWithoutFeedback onPress={this.props.hideMoreMenuMobile}>
          <View style={[{height: totHeight}]}/>
        </TouchableWithoutFeedback>
        <View style={[styles.menuBgWhite, styles.menuFull, {height: menuHeight}]}>
          <View
            style={[
              styles.menuBgWhite,
              styles.menuFull,
              {height: scrollHeight},
            ]}>
            <ScrollViewWithScrollBarIndicator>
              <View style={[stylesRes.moreMenuAreaMobileView]}>
                <View style={[stylesRes.moreMenuContent]}>
                  <View style={[stylesRes.menuResFull]}>
                    <View
                      style={[
                        stylesRes.moreMenuInner,
                        stylesRes.moreMenuFirstSet,
                      ]}>
                      <View style={[styles.navList]} nativeID={'menuItemList'}>
                        {APP_FEATURE_HEALTH_JOURNEY_ENABLED &&
                          <CommonIconMenuItem
                            isSelected={selectedMenu === paths.HEALTH_JOURNEY.index}
                            navigation={navigation}
                            pathDetails={paths.HEALTH_JOURNEY}
                            onPressMenuItem={() => {
                              handelOnMenuItemPress(paths.HEALTH_JOURNEY.index);
                              onSetEventData({});
                              setActiveBubble(null);
                            }}
                          />
                        }
                        {Boolean(isSponsoredSubscription) &&
                          <CommonIconMenuItem
                            isSelected={selectedMenu === paths.SURVEYS.index}
                            navigation={navigation}
                            pathDetails={paths.SURVEYS}
                            hasNotifications={hasSurveyNotifications}
                            onPressMenuItem={() => {
                              this.handleOnPressMenuItem('SURVEY');
                              handelOnMenuItemPress(paths.SURVEYS.index);
                            }}
                          />
                        }
                        {!Boolean(journalUserId) && (
                          <CommonIconMenuItem
                            isSelected={selectedMenu === paths.TODOS.index}
                            navigation={navigation}
                            pathDetails={paths.TODOS}
                            onPressMenuItem={() =>
                              handelOnMenuItemPress(paths.TODOS.index)
                            }
                            clearFilter={clearQuestionFilter}
                          />
                        )}
                        <CommonIconMenuItem
                          isSelected={selectedMenu === paths.GRAPHS.index}
                          navigation={navigation}
                          pathDetails={paths.GRAPHS}
                          onPressMenuItem={() =>
                            handelOnMenuItemPress(paths.GRAPHS.index)
                          }
                          clearFilter={clearGraphFilter}
                        />
                        <CommonIconMenuItem
                          isSelected={selectedMenu === paths.COMMUNITY_GROUPS.index}
                          navigation={navigation}
                          pathDetails={paths.COMMUNITY_GROUPS}
                          onPressMenuItem={() =>
                            handelOnMenuItemPress(
                              paths.COMMUNITY_GROUPS.index,
                            )
                          }
                          clearFilter={clearHealthLibraryFilter}
                        />
                        <CommonIconMenuItem
                          isSelected={selectedMenu === paths.CONTACTS.index}
                          navigation={navigation}
                          pathDetails={paths.CONTACTS}
                          onPressMenuItem={() =>
                            handelOnMenuItemPress(paths.CONTACTS.index)
                          }
                          clearFilter={clearContactFilter}
                        />
                        {!Boolean(journalUserId) && (
                          <CommonIconMenuItem
                            isSelected={selectedMenu === paths.CAREGIVERS.index}
                            navigation={navigation}
                            pathDetails={paths.CAREGIVERS}
                            onPressMenuItem={() =>
                              handelOnMenuItemPress(paths.CAREGIVERS.index)
                            }
                          />
                        )}

                        <SubMenuTitel titel={'MY HEALTH RESEARCH'}/>
                        <View style={styles.subMenuWrapper}>
                          <CommonIconMenuItem
                            isSelected={selectedMenu === paths.CLINICAL_TRIALS.index}
                            navigation={navigation}
                            title={paths.CLINICAL_TRIALS.title}
                            pathDetails={{
                              ...paths.CLINICAL_TRIALS,
                              path: this.props.applicationSettingsDigest && !this.props.applicationSettingsDigest.clinicalTrialDisclaimer
                                ? paths.CLINICAL_TRIALS.otherPath
                                : paths.CLINICAL_TRIALS.path
                            }}
                            onPressMenuItem={() => {
                              handelOnMenuItemPress(paths.CLINICAL_TRIALS.index);
                              this.props.onResetSearchResults();
                            }}
                          />
                        </View>

                        <SubMenuTitel titel={'MY DOCUMENTS'}/>
                        <View style={styles.subMenuWrapper}>
                          <CommonMenuItem
                            isSelected={
                              selectedMenu === paths.IMAGES_DOCUMENTS.index
                            }
                            navigation={navigation}
                            title={paths.IMAGES_DOCUMENTS.title}
                            route={paths.IMAGES_DOCUMENTS.path}
                            onPressMenuItem={() =>
                              handelOnMenuItemPress(
                                paths.IMAGES_DOCUMENTS.index,
                              )
                            }
                            clearFilter={clearImageFilter}
                          />
                          <CommonMenuItem
                            isSelected={selectedMenu === paths.REPORTS.index}
                            navigation={navigation}
                            title={paths.REPORTS.title}
                            route={paths.REPORTS.path}
                            onPressMenuItem={() =>
                              handelOnMenuItemPress(paths.REPORTS.index)
                            }
                            clearFilter={clearReportFilter}
                          />
                        </View>

                        <SubMenuTitel titel={'MY HEALTH TRACKING'}/>
                        <View style={styles.subMenuWrapper}>
                          <CommonMenuItem
                            isSelected={
                              selectedMenu === paths.MEDICATIONS.index
                            }
                            navigation={navigation}
                            title={paths.MEDICATIONS.title}
                            route={paths.MEDICATIONS.path}
                            onPressMenuItem={() =>
                              handelOnMenuItemPress(paths.MEDICATIONS.index)
                            }
                            clearFilter={clearTherapyFilter}
                          />
                          <CommonMenuItem
                            isSelected={
                              selectedMenu === paths.ROUTINES.index
                            }
                            navigation={navigation}
                            title={paths.ROUTINES.title}
                            route={paths.ROUTINES.path}
                            onPressMenuItem={() =>
                              handelOnMenuItemPress(
                                paths.ROUTINES.index,
                              )
                            }
                            clearFilter={clearRoutineFilter}
                          />
                          <CommonMenuItem
                            isSelected={selectedMenu === paths.SYMPTOMS.index}
                            navigation={navigation}
                            title={paths.SYMPTOMS.title}
                            route={paths.SYMPTOMS.path}
                            onPressMenuItem={() =>
                              handelOnMenuItemPress(paths.SYMPTOMS.index)
                            }
                            clearFilter={clearSymptomFilter}
                          />
                          <CommonMenuItem
                            isSelected={selectedMenu === paths.METRICS.index}
                            navigation={navigation}
                            title={paths.METRICS.title}
                            route={paths.METRICS.path}
                            onPressMenuItem={() =>
                              handelOnMenuItemPress(paths.METRICS.index)
                            }
                            clearFilter={clearMetricFilter}
                          />
                          {/* Manage Devices */}
                          {APP_FEATURE_MANAGE_DEVICE_ENABLED &&
                            <CommonMenuItem
                              isSelected={selectedMenu === paths.MANAGE_DEVICES.index}
                              navigation={navigation}
                              title={paths.MANAGE_DEVICES.title}
                              route={paths.MANAGE_DEVICES.path}
                              onPressMenuItem={() =>
                                handelOnMenuItemPress(paths.MANAGE_DEVICES.index)
                              }
                            />
                          }
                        </View>


                        <SubMenuTitel titel={'HELP AND SUPPORT'}/>
                        <View style={styles.subMenuWrapper}>
                          <CommonMenuItem
                            isSelected={selectedMenu === paths.HELP.index}
                            navigation={navigation}
                            title={paths.HELP.title}
                            route={paths.HELP.path}
                            onPressMenuItem={() =>
                              handelOnMenuItemPress(paths.HELP.index)
                            }
                          />
                        </View>
                      </View>

                      <View>
                        {!Boolean(isSetupOn) && (
                          <View>
                            <SetupWizardMenuItem
                              onPress={() => {
                                showSetup(this.props.journalUserId, !this.isSetupComplete(), () => {
                                  this.props.setActiveTab(tabs.profile);
                                  if (Platform.OS !== WEB) {
                                    navigation.reset({
                                      index: 0,
                                      routes: [{name: paths.OTHER.setupPath}],
                                    });
                                  } else {
                                    navigation.navigate(paths.DASHBOARD.path);
                                    this.props.toggleSetupModal(true);
                                  }
                                  handelOnMenuItemPress(paths.DASHBOARD.index);
                                });
                              }}
                            />
                          </View>
                        )}
                        <View>
                          <LogoutMenuItem
                            onPress={this.props.onSignOutPress}
                            hasNotification={this.state.hasNotification}
                          />
                          {hasNotch && <View style={{height: 100}}/>}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </ScrollViewWithScrollBarIndicator>
          </View>
        </View>
      </View>
      // </TouchableWithoutFeedback>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    hasNotch: state.uistate.hasNotch,
    userConditions: state.routines.userConditions,
    treatments: state.setup.treatments,
    userTreatments: state.routines.treatments,
    symptoms: state.setup.symptoms,
    userSymptoms: state.routines.symptomsDigest
      ? state.routines.symptomsDigest.userSymptoms
      : [],
    metrics: state.setup.metrics,
    userDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.userDefinedMetrics
      : [],
    journalEntryTypes: state.routines.journalEntryTypes,
    notification: state.uistate.notification,
    seenNotification: state.uistate.seenNotification,
    myNetwork: state.routines.myNetwork,
    newMessageCount: state.peerToPeer.newMessageCount,
    unSeenConversations: state.peerToPeer.unSeenConversations,
    userSubscription: state.routines.userSubscription,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    togglePrivacyStatment: () => dispatch(actions.showPrivacyStatment()),
    showSetup: (journalUserId, updateGlobally, callBack) =>
      dispatch(actions.showSetup(journalUserId, callBack, updateGlobally)),
    clearQuestionFilter: () => dispatch(actions.clearQuestionFilter()),
    clearTherapyFilter: () => dispatch(actions.clearTherapyFilter()),
    clearImageFilter: () => dispatch(actions.clearImageFilter()),
    clearContactFilter: () => dispatch(actions.clearContactFilter()),
    clearSymptomFilter: () => dispatch(actions.clearSymptomFilter()),
    clearMetricFilter: () => dispatch(actions.clearMetricFilter()),
    clearDeviceFilter: () => dispatch(actions.clearDeviceFilter()),
    clearGraphFilter: () => dispatch(actions.clearGraphFilter()),
    clearReportFilter: () => dispatch(actions.clearReportFilter()),
    clearRoutineFilter: () => dispatch(actions.clearRoutineFilter()),
    clearHealthLibraryFilter: (keywords) => {
      dispatch(actions.updateSearchedKeywords(keywords));
    },
    onClearMenuSectionNotifications: (notificationArray) =>
      dispatch(actions.clearMenuSectionNotifications(notificationArray)),
    onSignOutPress: () => dispatch(actions.logout()),
    onResetSearchResults: () => dispatch(actions.resetSearchResults()),
    setActiveTab: value => dispatch(actions.setSetupActiveTab(value)),
    toggleSetupModal: (val) => dispatch(toggleSetupModal(val)),
    onSetEventData: (data) => dispatch(actions.setEventData(data)),
    setActiveBubble: (id) => dispatch(actions.setActiveBubble(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MobileMoreMenu);
