import React from "react";
import Modal from "../../components/Modal/modal";

const modalVideo = (props) => {
  return (
    <Modal
      visible={props.visibleWatchOverview}
      onBackdropPress={props.onBackdropPress}
      backdropStyle={props.backdropStyle}
    >
      {props.children}
    </Modal>
  );
};

export default (modalVideo);
