import React, { Component } from 'react';
import { View, ScrollView, Text } from 'react-native';
import { connect } from 'react-redux';
import _ from 'underscore';
import Header from '../../components/Header/header';
import RoutineItems from './RoutinesSettings/RoutineItems';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import PlusButtonDark from '../../components/Button/plusButtonDark';
import SimpleOkBox from '../../components/ConfirmationBox/simpleOkBox';
import PageContainer from '../../components/PageLayout/PageContainer';
import SearchField from '../../components/Search/searchField';
import NoResultFound from '../../components/Search/noResultFound';
import SectionTitle from '../../components/Text/sectionTitle';
import DeletingConfirmation from '../../components/ConfirmationBox/deletingConfirmation';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import * as actions from '../../store/actions/index';
import { ROUTINES, DASHBOARD } from '../../navigation/path';
import { handleCommonBackNavigation } from '../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../util/pendoConfig';

class RoutinesSettings extends Component {
  constructor(props) {
    super(props);
    const routine = this;
    this.state = {
      activeList: routine.filterActiveInactive(),
      inactiveList: routine.filterActiveInactive(false),
      visibleModal: false,
      selectedId: null,
      deleteWait: false,
      loadingWait: true,
      visibleWarrningModal: false,
      searchStr: '',
    };

    this.filterActiveInactive = this.filterActiveInactive.bind(this);
    this.handleDeleteRoutine = this.handleDeleteRoutine.bind(this);
    this.filterRoutine = this.filterRoutine.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.hideWarrningModal = this.hideWarrningModal.bind(this);
    this.filterActiveInactiveBySearchKey = this.filterActiveInactiveBySearchKey.bind(this);
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      this.setState({loadingWait: true});
      let searchString = null;
      let from = null;
      if (this.props.route?.params && this.props.route?.params.from) {
        from = this.props.route?.params.from;
      }
      if (this.props.routineFilter && from && (from.includes(ROUTINES.addRoutineTemplatePath) || from.includes(ROUTINES.editRoutineTemplatePath))) {
        searchString = this.props.routineFilter;
      }
      this.filterRoutine(searchString);
      setTimeout(() => this.setState({loadingWait: false}), 1500);
      pendoTrackEvent('Routines@');
    });
    this.props.navigation.addListener('blur', () => {
      this.props.navigation.setParams({from: ROUTINES.path});
    });
  }

  hideModal() {
    this.setState({visibleModal: false});
  }

  showModal(selectedId) {
    this.setState({visibleModal: true, selectedId});
  }

  hideWarrningModal() {
    this.setState({visibleWarrningModal: false});
  }

  filterActiveInactive(isActive = true) {
    const filteredList = this.props.journalEntryTypes.filter(
      type => type.isActive === isActive && type.userCanEdit && type.isHidden === false
    );
    return filteredList;
  }

  filterActiveInactiveBySearchKey = (searchStr, isActive = true) => {
    return this.props.journalEntryTypes.filter(
      type =>
        type.name &&
        type.name.toLowerCase().includes(searchStr.toLowerCase()) &&
        type.isActive === isActive &&
        type.isHidden === false &&
        type.userCanEdit,
    );
  }

  filterRoutine(searchStr) {
    if (searchStr) {
      const filteredActiveList = this.filterActiveInactiveBySearchKey(searchStr);
      const filteredInactiveList = this.filterActiveInactiveBySearchKey(searchStr, false);
      this.setState({
        searchStr: searchStr,
        activeList: filteredActiveList,
        inactiveList: filteredInactiveList,
      });
    } else {
      this.setState({
        searchStr: searchStr,
        activeList: this.filterActiveInactive(),
        inactiveList: this.filterActiveInactive(false),
      });
    }
    this.props.setRoutineFilter(searchStr);
  }

  handleDeleteRoutine() {
    this.setState({deleteWait: true});
    const callBack = error => {
      this.setState({deleteWait: false, visibleModal: false}, () => {
        if (error) {
          setTimeout(() => {
            this.setState({visibleWarrningModal: true});
          }, 500);
        }
      });
    };

    if (this.state.selectedId) {
      pendoTrackEvent('Routines@ > 3Dots > Delete');
      this.props.deleteRoutine(
        this.state.selectedId,
        callBack,
        this.props.journalUserId,
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.journalEntryTypes !== prevProps.journalEntryTypes) {
      this.setState({
        activeList: this.filterActiveInactive(),
        inactiveList: this.filterActiveInactive(false),
      });
    }
  }

  handleRedirect() {
    pendoTrackEvent('Routines@ > Add');
    this.props.navigation.push(ROUTINES.addRoutineTemplatePath);
  }

  handleEdit(journalEntryType) {
    this.props.setEntryType(journalEntryType);
    this.props.navigation.push(ROUTINES.editRoutineTemplatePath, {
      routineName: journalEntryType.name
    });
  }

  compareStates(nextState) {
    const {activeList, inactiveList} = this.state;

    for (let index = 0; index < inactiveList.length; index++) {
      if (inactiveList[index].id !== nextState.inactiveList[index].id)
        return true;
    }
    for (let index = 0; index < activeList.length; index++) {
      if (activeList[index].id !== nextState.activeList[index].id) return true;
    }

    return false;
  }

  handleOnPressBackButton = () => {
    let from = null;
    if (this.props.routine?.params && this.props.routine?.params.from) {
      from = this.props.routine?.params.from;
    }
    if (this.state.searchStr !== '' && this.state.searchStr !== null) {
      this.filterRoutine(null);
    }
    else if (from && (from.includes(ROUTINES.addRoutineTemplatePath) || from.includes(ROUTINES.editRoutineTemplatePath)))
    {
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: DASHBOARD.path }],
      });
    } else {
      handleCommonBackNavigation(this.props.navigation);
    }
  };

  renderListItems = (list, isActive = true) => {
    return (
      <View style={styles.listSubView}>
        <View style={[styles.listTopicSec]}>
          <View style={[styles.listTopicSecLg]}>
            <View
              style={[
                styles.titleBlockCom,
                isActive
                  ? styles.colPdTopBotCom
                  : styles.colPdBotLg
              ]}
            >
              <View style={styles.titleTextBlock}>
                <Text style={[styles.textTitleBold]} allowFontScaling={false}>{isActive ? 'Active' : 'Inactive'}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.listSubViewArea]}>
          <View
            style={[styles.listSubViewCont, styles.infoPdBotSm]}
          >
            <RoutineItems
              isActive={isActive ? true : false}
              list={list}
              handleDeleteRoutine={this.showModal}
              handleEdit={this.handleEdit}
            />
          </View>
        </View>
      </View>
    );
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={true}
        filterClickHandler={this.handleOnPressFilter}
        clearFiltersHandler={this.handleOnPressClearFilters}
        handleOnPressBackButton={this.handleOnPressBackButton}
        index={ROUTINES.index}
        navigation={this.props.navigation}
        route={this.props.route}
      />
      <SpaceBar />

      <ScrollView style={styles.scrollViewArea}>
        <View style={styles.gridSection}>
          <PageContainer>
            <View style={[styles.gridRow, styles.colPdBotSm]}>
              <View style={styles.gridColFull}>
                <View style={[styles.searchSection]}>
                    {/* button */}
                    <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt]}>
                      <View>
                        <SectionTitle 
                          smallTextTilte={true}
                          title={"Routines"}
                        />
                      </View>
                      <View style={[stylesRes.newConnectionBtnCr,styles.btnMgMd]} dataSet={{media: ids.newConnectionBtnCr}}>
                        <PlusButtonDark
                          touchableOnPress={this.handleRedirect}
                          btnPosition={"full"}
                          btnText={"New Routine"}
                          plusIconSm={true}
                          btnShadowNone={true}
                          nativeId={'routinesAdd'}
                          newConnection={true}

                        />
                      </View>
                    </View>
                    {/* search bar */}
                    <View style={styles.fieldColSearch}>
                      <SearchField
                          searchText={
                            this.state.searchStr ? this.state.searchStr : ""
                          }
                          placeholder="Search existing routines"
                          onChangeText={this.filterRoutine}
                          showClearBtton={
                            this.state.searchStr && this.state.searchStr !== ""
                              ? true
                              : false
                          }
                          clearFilter={() => this.filterRoutine(null)}
                          nativeId={'routinesSearch'}
                          eventName={'Routines@ > Search'}
                        />
                    </View>
                </View>
                {_.isEmpty(this.state.activeList) &&
                  _.isEmpty(this.state.inactiveList) &&
                  this.state.searchStr !== "" &&
                  this.state.searchStr !== null &&
                  !this.state.filterWait && <NoResultFound />}
              </View>
            </View>
          </PageContainer>

          {this.state.loadingWait ? (
            <ActivityIndicator pdLarge={true} />
          ) : (
            <PageContainer>
              <View style={[stylesRes.contentSection, stylesRes.botPd]} dataSet={{media: ids.botPd}}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.secArea}>
                      {this.state.activeList.length > 0 ? (
                        this.renderListItems(this.state.activeList)
                      ) : null}
                      {this.state.activeList.length > 0 &&
                      this.state.inactiveList.length > 0 ? (
                        <View style={styles.secBgPdBot} />
                      ) : null}
                      {this.state.inactiveList.length > 0 ? (
                        this.renderListItems(this.state.inactiveList, false)
                      ) : null}
                    </View>
                  </View>
                </View>
              </View>
            </PageContainer>
          )}
        </View>
      </ScrollView>
      <DeletingConfirmation
        visibleModal={this.state.visibleModal}
        deleteWait={this.state.deleteWait}
        onBackdropPress={this.hideModal}
        onRequestClose={this.hideModal}
        handleConfirm={this.handleDeleteRoutine}
        handleCancel={this.hideModal}
        alertTitle="Are you sure that you’d like to delete this Routine?"
      />
      <SimpleOkBox
        visibleModal={this.state.visibleWarrningModal}
        alertTitle="This Routine cannot be deleted."
          alertBody="Routines with saved entries cannot be deleted because of the saved data."
        handleConfirm={this.hideWarrningModal}
        onBackdropPress={this.hideWarrningModal}
        onRequestClose={this.hideWarrningModal}
      />
    </View>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteRoutine: (journalEntryTypeId, callBack, journalUserId) =>
      dispatch(
        actions.deleteJournalEntryType(
          journalEntryTypeId,
          callBack,
          journalUserId,
        ),
      ),
    setEntryType: journalEntryType =>
      dispatch(actions.setSelectedJournalEntryType(journalEntryType)),
    setRoutineFilter: filter => dispatch(actions.setRoutineFilter(filter)),
  };
};

const mapStateToProps = state => {
  return {
    journalEntryTypes: state.routines.journalEntryTypes,
    journalUserId: state.uistate.journalUserId,
    routineFilter: state.filter.routineFilter
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoutinesSettings);
