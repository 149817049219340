import React, { Component } from "react";
import { View, Image } from "react-native";
import styles from "../../Css";

class LogoSetUp extends Component {
  onFinishCheckingCode = (val) => {
    this.props.onChange(val);
  };
  render() {
    return (
      <React.Fragment>
        <View style={[styles.topImgViewBlock, styles.comSpSm]}>
          <View style={[styles.topImgView, styles.flexJcCtAiCt]}>
            <Image
              style={styles.logoApplication}
              source={require("../../assets/logo/logo.png")}
              resizeMode={"contain"}
            />
          </View>
        </View>
      </React.Fragment>
    );
  }
}

export default LogoSetUp;
