import React from "react";
import {View} from "react-native";
import {connect} from "react-redux";
import styles from "../../../Css";
import * as actions from "../../../store/actions/index";

import ResultsListItem from "./ResultsListItem";
import InfiniteScroll from "react-infinite-scroll-component";
import DirectoryHeader from "./DirectoryHeader";
import PageContainer from "../../../components/PageLayout/PageContainer";
import AbstractResultsList, {ITEM_LIST_WINDOW_SIZE} from "./AbstractResultsList";

class ResultsList extends AbstractResultsList {
  fetchMoreData = () => {
    if (this.state.items.length >= this.state.searchResult.length) {
      this.setState({hasMore: false});
    } else {
      const itemsEndIndex = Math.min(this.state.items.length + ITEM_LIST_WINDOW_SIZE, this.state.searchResult.length);
      if (this.state.items.length < itemsEndIndex) {
        this.setState({
          itemEndIndex: itemsEndIndex,
          hasMore: this.state.searchResult.length >= itemsEndIndex,
        });
      }
    }
  };

  render() {
    return (
      <div
        id="scrollableDiv"
        style={{height: "100%", overflowY: "auto"}}
      >
        <DirectoryHeader
          searchCount={this.state.searchCount}
          searchString={this.state.searchString}
          tagSearch={this.state.tagSearch}
          resourceType={this.state.resourceType}
          onPressResetSearch={this.props.onPressResetSearch}
          onPressPreviousSearch={this.props.onPressPreviousSearch}
          onPressSaveCriteriaVisible={this.props.onPressSaveCriteriaVisible}
        />
        <PageContainer smScreenFull={true}>
          {this.state.items.length > 0 && (
            <View
              style={[
                styles.communityList,
                styles.greyBorderThinTop,
                styles.communityListBotMg
              ]}
            >
              <InfiniteScroll
                dataLength={this.state.items.length}
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
              >
                {this.state.items.map((result, index) => (
                  <ResultsListItem
                    result={result}
                    key={index}
                  />
                ))}
              </InfiniteScroll>
              <View style={{height: 80}}></View>
            </View>
          )}
        </PageContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showLearnHow: state.uistate.showLearnHow,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    socialMediaDataList: state.communityGroups.fetchAllSocialMediaData,
    searchResultStack: state.communityGroups.searchResultStack,
    user: state.routines.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSocialMediaData: resourceId =>
      dispatch(actions.fetchSocialMediaData(resourceId)),
    clearAddedResource: () => dispatch(actions.resetAddResourceFetchedData())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultsList);
