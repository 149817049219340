import React from "react";
import { TouchableOpacity, Text } from "react-native";

const button = props => {
  return (
    <TouchableOpacity
      style={props.touchableStyle}
      onPress={props.touchableOnPress}
    >
      <Text style={props.textStyle} allowFontScaling={false}>{props.textTitle}</Text>
    </TouchableOpacity>
  );
};

export default button;
