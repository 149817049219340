import React, { Component } from "react";
import { View, Platform } from "react-native";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";
import { connect } from "react-redux";
import ResourcesList from "./ResourcesList";
import * as actions from "../../../store/actions/index";
import Modal from "../../../components/Modal/modal";
import ResoureModal from "../../../components/ResourceModal/ResourceModal";
import ActivityIndicator from "../../../components/ActivityIndicator/activityIndicator";
import SuccessBox from "../../../components/ConfirmationBox/successBox";
import { pendoTrackEvent } from "../../../util/pendoConfig";
import { ANDROID, IOS, WEB } from "../../../util/platformWindowConfig";
import { unFocus } from "zamplo-common-js";
import { getScrollHeight } from '../../../util/commonUiLogic';

class MyResources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceFilter: [],
      visibleResourceModal: false,
      resources: [],
      resourcesLoading: true,
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
      resourcesCount: this.props.resourcesList && this.props.resourcesList.length
        ? this.props.resourcesList.length
        : 0,
      successModalVisible: false,
      successMessage: "",
      keywords: this.props.keywords ? this.props.keywords : "",
      searchFilterResults:
        this.props.searchFilterResults &&
        this.props.searchFilterResults.length > 0
          ? this.props.searchFilterResults
          : [],
      messageNum: null,
    };
  }

  componentDidMount = () => {
    this.setState({ resourcesLoading: true });
    this.props.fetchAllResouces(
      this.props.journalUserId,
        // ? this.props.journalUserId
        // : this.props.user.id.toString(),
      (data) => {
        if (this.props.keywords) {
          let filterArray = [];
          let tempFilter = [];

          if (data && data.length > 0) {
            data.filter((res) => {
              const {title, tags} = res?.communityResponse;
              if (title) {
                if (
                  title
                    .toLowerCase()
                    .includes(this.props.keywords.toLowerCase())
                ) {
                  filterArray.push(res);
                }

                if (tags && tags.length > 0) {
                  return tags.filter((re) => {
                    if (
                      re
                        .toLowerCase()
                        .includes(this.props.keywords.toLowerCase()) &&
                      !title
                        .toLowerCase()
                        .includes(this.props.keywords.toLowerCase())
                    ) {
                      filterArray.push(res);
                    }
                    return re;
                  });
                }

                return filterArray;
              }
              return filterArray;
            });
          }
          if (filterArray && filterArray.length) {
            tempFilter = [...new Set(filterArray)];
          }

          this.setState({
            searchFilterResults: tempFilter,
            resourcesCount: tempFilter.length,
            resourcesLoading: false,
          });
        } else {
          this.setState({
            resourcesLoading: false,
            searchFilterResults: data ? data : [],
            resourcesCount: data.length,
          });
        }

        //Update seen resources when navigate from Notification (bell notification & push notification)
        if (this.props.route?.params && this.props.route?.params?.isFromNotif && this.props.route?.params?.resourceId) {
          this.handleOnTapNewResource(this.props.route?.params?.resourceId);
        }
      }
    );
  };

  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth } = props;

    if (
      props.windowHeight !== state.windowHeight ||
      props.windowWidth !== state.windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }
    return null;
  }

  handleSearchMyResourceInputValueChange = (keywords) => {
    if (keywords) {
      let filterArray = [];
      let tempFilter = [];

      if (this.props.resourcesList && this.props.resourcesList.length > 0) {
        this.props.resourcesList.filter((res) => {
          const {title, tags} = res?.communityResponse;
          if (title) {
            if (title.toLowerCase().includes(keywords.toLowerCase())) {
              filterArray.push(res);
            }

            if (tags && tags.length > 0) {
              return tags.filter((re) => {
                if (
                  re.toLowerCase().includes(keywords.toLowerCase()) &&
                  !title.toLowerCase().includes(keywords.toLowerCase())
                ) {
                  filterArray.push(res);
                }
                return re;
              });
            }

            return filterArray;
          }
          return filterArray;
        });
      }
      if (filterArray && filterArray.length) {
        tempFilter = [...new Set(filterArray)];
      }

      this.setState(
        {
          searchFilterResults: tempFilter,
          resourcesCount: tempFilter.length,
          keywords: keywords,
        },
        () => this.props.updateSearchedKeywords(keywords)
      );
    } else {
      this.setState(
        {
          searchFilterResults: this.props.resourcesList
            ? this.props.resourcesList
            : [],
          resourcesCount: this.props.resourcesList
            ? this.props.resourcesList.length
            : 0,
          keywords: keywords,
        },
        () => this.props.updateSearchedKeywords("")
      );
    }
  };

  handleResourceModalShow = () => {
    pendoTrackEvent('HealthLibrary > @MyResources > AddaResource');
    this.setState({ visibleResourceModal: true });
  };

  handleResourceModalCancel = (successModalVisible, successMessage) => {
    let messageNum = "";
    messageNum = successMessage ? successMessage.match(/\d+/) : "";
    let successMsg = "";
    successMsg = successMessage.replace(/\d+/g, "");

    this.setState(
      {
        visibleResourceModal: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            successModalVisible: successModalVisible,
            successMessage: successMsg,
            messageNum: messageNum && messageNum[0] ? messageNum[0] : "",
          });
        }, 500);
      }
    );

    if (successModalVisible) {
      setTimeout(() => {
        this.setState({
          successModalVisible: false,
          successMessage: "",
          messageNum: null,
        });
      }, 2000);
    }

    const { clearAddedResource } = this.props;
    this.setState({ resourcesLoading: true });
    this.props.fetchAllResouces(
      this.props.journalUserId,
        // ? this.props.journalUserId
        // : this.props.user.id.toString(),
      (data) => {
        if (this.props.keywords) {
          let filterArray = [];
          let tempFilter = [];

          if (data && data.length > 0) {
            data.filter((res) => {
              const {title, tags} = res?.communityResponse;
              if (title) {
                if (
                  title
                    .toLowerCase()
                    .includes(this.props.keywords.toLowerCase())
                ) {
                  filterArray.push(res);
                }

                if (tags && tags.length > 0) {
                  return tags.filter((re) => {
                    if (
                      re
                        .toLowerCase()
                        .includes(this.props.keywords.toLowerCase()) &&
                      !title
                        .toLowerCase()
                        .includes(this.props.keywords.toLowerCase())
                    ) {
                      filterArray.push(res);
                    }
                    return re;
                  });
                }

                return filterArray;
              }
              return filterArray;
            });
          }
          if (filterArray && filterArray.length) {
            tempFilter = [...new Set(filterArray)];
          }

          this.setState({
            searchFilterResults: tempFilter,
            resourcesCount: tempFilter.length,
            resourcesLoading: false,
          });
        } else {
          this.setState({
            resourcesLoading: false,
            searchFilterResults: data ? data : [],
            resourcesCount: data.length,
          });
        }
      }
    );
    clearAddedResource();
  };

  fetchFilteredResourceList = () =>{
    this.handleSearchMyResourceInputValueChange(this.state.keywords)
  }

  handleOnPressShowHide = (showHideObj) => {
    const {setHealthLibraryShowHideStatus, journalUserId} = this.props;
    const {searchFilterResults} = this.state;
    const resources = Object.assign([], searchFilterResults);
    const index = resources.findIndex(item => item.mapId === showHideObj.mapId);
    setHealthLibraryShowHideStatus(showHideObj, journalUserId, (sucess) => {
      if (sucess) {
        resources[index].hidden = showHideObj.isHidden;
        this.setState({
          searchFilterResults: resources
        });
      }
    });
  }

  handleOnTapNewResource = (mapId) => {
    const resources = Object.assign([], this.state.searchFilterResults);
    const index = resources.findIndex(item => item.mapId == mapId);
    if (index !== -1) {
      this.props.setResourceSeenStatus(this.props.journalUserId, mapId, status => {
        if (status) {
          resources[index].seen = true;
          this.setState({searchFilterResults: resources});
        }
      });
    }
  }

  render() {
    const { styles: stylesRes } = stylesResponsive.getProcessedStyles();
    const { hasNotch, journalUserId, timeZoneWarning, timeZone } = this.props;
    const { windowHeight, windowWidth } = this.state;

    let calHeight = 80 + 127;
    let tabHeight = 44;
    let scrollHeight = getScrollHeight(hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth, calHeight + tabHeight);
    if (Platform.OS === IOS || Platform.OS === ANDROID) {
      scrollHeight = scrollHeight - 20;
    }
    return (
      <React.Fragment>
        <View style={styles.gridRow}>
          <View style={styles.gridColFull}>
            <View style={stylesRes.tabContentFullArea}>
              <View style={styles.tabFullBlock}>
                <View style={[styles.secSubArea]}>
                  <View style={styles.secSubContentArea}>
                    {this.state.resourcesLoading ? (
                      <View
                        style={[
                          { minHeight: scrollHeight },
                          styles.flexJcCtAiCt,
                        ]}
                      >
                        <ActivityIndicator color="#FF8300" />
                      </View>
                    ) : (
                      <ResourcesList
                        resourcesList={this.state.searchFilterResults}
                        navigation={this.props.navigation}
                        activeTab={(tagSearchResultsArr) => {
                          this.props.activeTab(tagSearchResultsArr);
                        }}
                        resourcesCount={this.state.resourcesCount}
                        handleSearchMyResourceInputValueChange={
                          this.handleSearchMyResourceInputValueChange
                        }
                        handleResourceModalShow={this.handleResourceModalShow}
                        keywords={this.state.keywords}
                        fetchFilteredResourceList={this.fetchFilteredResourceList}
                        handleOnPressShowHide={this.handleOnPressShowHide}
                        handleOnTapNewResource={this.handleOnTapNewResource}
                        newResourcesCount={this.state.searchFilterResults.filter(item => !item.seen).length}
                      />
                    )}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Modal 
          visible={this.state.visibleResourceModal}
          onBackdropPress={unFocus}
          defaultStyle={[styles.flexJcCtAiCt, Platform.OS !== WEB && {margin: 0}]}
        >
          <ResoureModal
            isVisible={this.state.visibleResourceModal}
            close={this.handleResourceModalCancel}
          />
        </Modal>
        <SuccessBox
          visibleModal={this.state.successModalVisible}
          message={this.state.successMessage}
          number={this.state.messageNum}
          messageTitle={"Success!"}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //user: state.routines.user,
    resourcesList: state.communityGroups.fetchAllResources,
    keywords: state.communityGroups.searchedKeyword,
    searchFilterResults: state.communityGroups.searchFilterResults,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
    hasNotch: state.uistate.hasNotch,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearAddedResource: () => dispatch(actions.resetAddResourceFetchedData()),
    fetchAllResouces: (userId, callBack) =>
      dispatch(actions.fetchAllResouces(userId, callBack)),
    // filterResults: filterItems => {
    //   dispatch(actions.filterResults(filterItems));
    // },
    updateSearchedKeywords: (keywords) => {
      dispatch(actions.updateSearchedKeywords(keywords));
    },
    setHealthLibraryShowHideStatus: (showHideObj, journalUserId, callBack) =>
      dispatch(actions.setHealthLibraryShowHideStatus(showHideObj, journalUserId, callBack)),
    setResourceSeenStatus: (journalUserId, resourceId, callBack) => {
      dispatch(actions.setResourceSeenStatus(journalUserId, resourceId, callBack));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyResources);
