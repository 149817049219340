import React from "react";
import { View } from "react-native";
import styles from "../../../Css";
import AppStore from "./appStore";
import PlayStore from "./playStore";
import QRCode from "./qrCode";
import { isAndroid, isIOS } from "react-device-detect";
const storeTile = (props) => {
  return (
    <View style={styles.collageRow}>
      <View style={styles.collageCol}>
        <View style={styles.collageBox}>
          <View style={[styles.collageContent, styles.flexRow]}>
            <View style={[styles.storeImgWrapper, styles.flexJcCtAiCt]}>
              {isIOS ? (
                <AppStore />
              ) : isAndroid ? (
                <PlayStore />
              ) : (
                <>
                  <AppStore />
                  <PlayStore />
                </>
              )}
            </View>
            <View style={[styles.flexCom, styles.flexJcCtAiCt]}>
              <View style={[styles.qrContWrapper]}>
                <QRCode />
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default storeTile;
