import React, {Component} from 'react';
import {View, ScrollView, Image, TouchableOpacity, Keyboard} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import Header from '../../Header/header';
import SpaceBar from '../../SpaceBar/SpaceBar';
import ActivityIndicator from '../../ActivityIndicator/activityIndicator';
import UIText from '../../Text/text';
import AutoCompleteSuggestion from '../../../components/AutoComplete/AutoCompleteSuggestion';
import {HEALTH_JOURNEY, SYMPTOMS} from '../../../navigation/path';
import PageContainer from '../../../components/PageLayout/PageContainer';
import * as ColorCode from '../../../components/ColorPicker/ColorCodes';
import SavingConfirmation from '../../../components/ConfirmationBox/savingConfirmation';
import {alertBody, symptomTitle} from '../../../GlobalFunctions/GlobalString';
import MenuOverlayContainer from '../../../components/PageLayout/MenuOverlayContainer';
import { pendoTrackEvent } from '../../../util/pendoConfig';
import {autoSuggestionListType, chartTypes} from '../../../Constant/index';
import GroupFrame from '../../../components/TextInput/groupFrame';
import * as FieldDetails from '../../../components/TextInput/fieldDetails';
import TextButton from '../../../components/TextInput/textButton';
import GraphPropertySelector from '../GraphPropertySelector/GraphPropertySelector';
import { getSuggestedTeams } from '../../../util/commonUiLogic';
import {hasValue} from '../../../util/hashValueGenerator';
import { TAB_BREAk_POINT_END } from '../../../util/platformWindowConfig';

class AddEditCustomSymptom extends Component {
  constructor(props) {
    super(props);
    const {selectedSymptom = {}} = props;
    const {displayProperties} = selectedSymptom ? selectedSymptom : {};
    const {color, scaleMin, scaleMax} = displayProperties ? displayProperties : {};
    this.state = {
      id: this.props.selectedSymptom ? this.props.selectedSymptom.id : -1,
      symptomName: this.props.selectedSymptom
        ? this.props.selectedSymptom.name
        : '',
      color: color ? color : ColorCode.BGSYMPTOM,
      graphStyle:
        this.props.selectedSymptom &&
        this.props.selectedSymptom.displayProperties
          ? this.props.selectedSymptom.displayProperties.style
              ? this.props.selectedSymptom.displayProperties.style
              : this.props.selectedSymptom.displayProperties.dashedLine === false
                  ? chartTypes.dash
                  : chartTypes.line
          : chartTypes.line,
      saveWait: false,
      validateName: true,
      recordings: (this.props.selectedSymptom || {}).recordings,
      error:
        this.props.selectedSymptom &&
        this.props.selectedSymptom.recordings &&
        this.props.selectedSymptom.recordings !== 0
          ? true
          : false,
      errorDescription:
        this.props.selectedSymptom &&
        this.props.selectedSymptom.recordings &&
        this.props.selectedSymptom.recordings !== 0
          ? 'Only graph properties can be edited for Symptom labels with recordings or that are predefined.'
          : '',
      editable:
        props.selectedSymptom &&
        props.selectedSymptom.recordings &&
        props.selectedSymptom.recordings !== 0
          ? false
          : true,
      cancelConfirm: false,
      cancelWait: false,
      terms: [],
      userTerms: [],
      yesNoBtn: scaleMax === 1 && scaleMin === 0 ? true : false,
      zeroToTenBtn: (scaleMax === null && scaleMin === null) || (scaleMin === 0 && scaleMax === 10) ? true : false,
      editableMeasure: props.selectedSymptom ? true : false,
      changedPickerColor: false,
      fromHealthJourney: props.route?.params?.from === HEALTH_JOURNEY.path,
    };
    this.initSymptomHash = this.getSymptomHashValue();
    this.loadingFinished = false;
  }

  componentDidMount() {
    this.getSystemDefinedSypmtoms();
    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
    });
    this.props.navigation.addListener('blur', () => {
      this.props.showMobileMenu();
    });
  }

  getSystemDefinedSypmtoms = () => {
    this.props.updateSystemDefinedSymptoms((result, data) => {
      if (result) {
        this.updateTermList(data);
      }
      this.loadingFinished = true;
      this.forceUpdate();
    });
  };

  updateTermList = (data) => {
    // let termsArray = [];
    // termsArray = data.map(value => {
    //   return {name: value.name, data: value};
    // });

    // this.setState({terms: [...termsArray]}, () => {
    //   initialValue = this.state;
    // });
    const {userSymptoms} = this.props;
    let termsArray = getSuggestedTeams(data, userSymptoms);
    this.setState({
      terms: [...termsArray], 
      userTerms: getSuggestedTeams(userSymptoms, data, true)
    });
  };

  onChangeTextSymptomName = currentInput => {
    if (currentInput) {
      this.setState({validateName: true});
    } else {
      this.setState({validateName: false});
    }

    this.setState({
      symptomName: currentInput,
      yesNoBtn: false,
      zeroToTenBtn: false, 
      editableMeasure: false,
      changedPickerColor: false
    });
  };

  handleOnSubmitSymptomSave = () => {
    this.setState({saveWait: true}, () => {
      if (!this.state.symptomName) {
        this.setState({validateName: false, saveWait: false});
        return;
      } else if (!this.state.editableMeasure) {
        this.setState({saveWait: false});
        return;
      } else {
        this.setState({validateName: true});
      }

      let userSymptom = {
        userSymptom: {
          id: this.state.id,
          name: this.state.symptomName,
          displayProperties: {
            ...this.state.displayProperties,
            color: this.state.color,
            style: this.state.graphStyle,
          },
        },
      };

      pendoTrackEvent('AddSymptom@ > Save');
      this.props.createUserSymptom(
        userSymptom,
        (success, addedSyptom) => {
          this.setState({saveWait: false});
          if (success) {
            if (this.state.fromHealthJourney) {
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: this.props.windowWidth < TAB_BREAk_POINT_END && this.props.eventData && this.props.eventData["type"] ? HEALTH_JOURNEY.addEventPath : HEALTH_JOURNEY.path }],
              });
            } else {
              this.props.onSelectSymptom(addedSyptom, res => {
                this.props.redirectViewSymptom();
              });
            }
          }
        },
        this.props.journalUserId,
      );
    });
  };

  onChangeChartType = value => {
    this.setState({graphStyle: value});
  };

  handleOnSubmitCancel = () => {
    if (this.initSymptomHash === this.getSymptomHashValue()) {
      this.handlecancelSymptom();
    } else {
      this.setState({
        cancelConfirm: true,
      });
    }
  };

  handlecancelSymptom = () => {
    const {fromHealthJourney} = this.state;
    const {eventData} = this.props;
    if (fromHealthJourney) {
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: this.props.windowWidth < TAB_BREAk_POINT_END && eventData && eventData["type"] ? HEALTH_JOURNEY.addEventPath : HEALTH_JOURNEY.path }],
      });
    } else if (this.props.selectedSymptom) {
      this.props.redirectBack();
    } else {
      this.setState({
        cancelConfirm: false
      }, () => {
        this.props.redirectSymptoms();
      });  
    }
  };

  onItemPress = selection => {
    Keyboard.dismiss();
    let selectedValue = selection.data;
    let displayProperties = selection?.data?.displayProperties;

    let color = displayProperties?.color
      ? displayProperties?.color
      : ColorCode.BGSYMPTOM;
    let graphStyle = displayProperties?.style || (displayProperties?.dashedLine === false ? chartTypes.dash : chartTypes.line);

    this.setState({
      symptomName: selectedValue.name,
      validateName: true,
      displayProperties: {
        color: color,
        style: graphStyle,
        description: displayProperties?.description,
        scaleMax: displayProperties?.scaleMax,
        scaleMin: displayProperties?.scaleMin,
      },
      color: color,
      style: graphStyle,
      editableMeasure: true,
      yesNoBtn: displayProperties?.scaleMax === 1 && displayProperties?.scaleMin === 0 ? true : false,
      zeroToTenBtn: (displayProperties?.scaleMax === null && displayProperties?.scaleMin === null) || (displayProperties?.scaleMin === 0 && displayProperties?.scaleMax === 10) ? true : false,
      changedPickerColor: true
    });
  };

  onAddNewPress = selection => {
    Keyboard.dismiss();
    this.setState({
      symptomName: selection,
      validateName: true,
      displayProperties: {
        color: ColorCode.BGSYMPTOM,
        style: chartTypes.line,
        scaleMax: null,
        scaleMin: null,
      },
      color: ColorCode.BGSYMPTOM,
      style: chartTypes.line,
      editableMeasure: true,
      yesNoBtn: false,
      zeroToTenBtn: true,
      changedPickerColor: true
    });
  };

  getSymptomChanges = () => {
    let flag = null;

    if (this.initSymptomHash === this.getSymptomHashValue()) {
      flag = false;
    } else {
      flag = true;
    }
    return flag;
  };

  setSelectMeasure = () => {
    this.setState(
      prevState => ({
        yesNoBtn: !prevState.yesNoBtn,
        zeroToTenBtn: !prevState.zeroToTenBtn,
        displayProperties: {
          ...prevState.displayProperties,
          scaleMax: !prevState.yesNoBtn ? 1 : 10,
          scaleMin: 0,
        }
      })
    );
  };

  getSymptomHashValue = () => {
    const {id, symptomName, color, graphStyle, yesNoBtn, zeroToTenBtn} = this.state;
    return hasValue(
      id +
        ',' +
        symptomName +
        ',' +
        color +
        ',' +
        graphStyle +
        ',' +
        yesNoBtn +
        ',' +
        zeroToTenBtn
    );
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {color, graphStyle, changedPickerColor} = this.state;
    return (
      <React.Fragment>
        <View style={[styles.pageBodyWrapper]}>
          <Header
            showFilers={false}
            showBack={true}
            showClearFilters={false}
            showNotify={true}
            handleOnPressBackButton={this.handleOnSubmitCancel}
            index={SYMPTOMS.index}
            navigation={this.props.navigation}
            route={this.props.route}
          />
          <SpaceBar />
          {Boolean(this.getSymptomChanges()) && (
            <MenuOverlayContainer onPressOpen={this.handleOnSubmitCancel} />
          )}
          <ScrollView
            style={styles.scrollViewArea}
            keyboardShouldPersistTaps={'always'}>
            <View style={styles.gridSection}>
              <PageContainer smScreenFull={true}>
                <View style={stylesRes.contBotPd} dataSet={{media: ids.contBotPd}}>
                  <View style={[styles.gridRow, styles.zIndexAutoComplete]}>
                    <View style={styles.gridColFull}>
                      <View style={[styles.secArea]}>
                        <View style={[styles.colPdTopLtRt]}>
                          <View style={[styles.formInnerArea]}>
                            <View style={[styles.fieldRow]}>
                              <View style={[styles.fieldColDouble]}>
                                <View style={[styles.fieldColMain, styles.symptomLtPd]}>
                                  <View style={[styles.fieldColMax]}>
                                    <AutoCompleteSuggestion
                                      textFieldWithTitle={true}
                                      textFieldTitle="Symptom"
                                      remortSearch={false}
                                      triggerLength={1}
                                      onChangeText={
                                        this.onChangeTextSymptomName
                                      }
                                      onItemPress={this.onItemPress}
                                      currentInput={this.state.symptomName}
                                      onPressCustom={this.onAddNewPress}
                                      customSectionTitle={
                                        'Add Custom Symptom'
                                      }
                                      showCustomSection={true}
                                      autoFocus={this.state.editable && this.loadingFinished ? true : false}
                                      terms={this.state.terms}
                                      userTerms={this.state.userTerms}
                                      validateName={!this.state.validateName}
                                      maxLength={250}
                                      updateTermList={() =>
                                        this.getSystemDefinedSypmtoms()
                                      }
                                      zoomIcon={true}
                                      removeIcon={true}
                                      type={autoSuggestionListType.symptom}
                                      placeholder="Search..."
                                      editable={this.state.editable && this.loadingFinished}
                                    />
                                  </View>
                                </View>

                                <View style={[styles.symptomFieldBtn, (!this.state.editable || !this.state.editableMeasure) && {opacity:0.5}]}>
                                  <GroupFrame
                                    title={'Select Measure'}
                                    fieldBg={FieldDetails.BGWHITE}
                                    groupStyle={styles.textInputMdWrapperCr}>
                                    <View style={styles.btnTabSingleDynamic}>
                                      <TextButton
                                        btnTabStyle={styles.btnTabSmWrapper}
                                        active={this.state.yesNoBtn}
                                        activeBtnTabStyle={styles.btnTabMenuActiveGray}
                                        btnText={'Yes/No'}
                                        touchableOnPress={() => this.setSelectMeasure()}
                                        disabled={!this.state.editable || !this.state.editableMeasure}
                                        weeklybtnShadow={false}
                                      />
                                    </View>
                                    <View style={styles.btnTabSingleSp} />
                                    <View style={styles.btnTabSingleDynamic}>
                                      <TextButton
                                        btnTabStyle={styles.btnTabSmWrapper}
                                        active={this.state.zeroToTenBtn}
                                        activeBtnTabStyle={styles.btnTabMenuActiveGray}
                                        btnText={'0 to 10'}
                                        touchableOnPress={() => this.setSelectMeasure()}
                                        disabled={!this.state.editable || !this.state.editableMeasure}
                                        weeklybtnShadow={false}
                                      />
                                    </View>
                                  </GroupFrame>
                                </View>

                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={[styles.gridRow, styles.zIndexMin]}>
                    <View style={styles.gridColFull}>
                      {Boolean(this.state.error) && (
                        <View style={styles.notifyArea}>
                          <View
                            style={[
                              styles.colPdLtRt,
                              styles.subViewPd,
                              styles.borderBotGrey,
                            ]}>
                            <View style={styles.notifyBlock}>
                              <View
                                style={[
                                  styles.notifyDetails,
                                  styles.notifyNoPd,
                                ]}>
                                <View style={styles.notifyWarning}>
                                  <Image
                                    style={styles.iconWarning}
                                    source={require('../../../assets/icons/warning.png')}
                                  />
                                </View>

                                <UIText
                                  style={[styles.notifyText, styles.bold]}
                                  title={this.state.errorDescription}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      )}
                    </View>
                  </View>
                  
                  <GraphPropertySelector
                    onChangeChartType={this.onChangeChartType}
                    graphStyle={graphStyle}
                    onChangeColor={color => {
                      this.setState({color: color});
                    }}
                    color={color}
                    isEdit={true}
                    changedPickerColor={changedPickerColor}
                  />

                  <View style={[styles.gridRow, styles.zIndexMin]}>
                    <View style={styles.gridColFull}>
                      <View style={[styles.colPdLtRt]}>
                        <View style={styles.btnArea}>
                          <View style={stylesRes.btnViewSec}>
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={[styles.cancelButtonSm, styles.cancelBtnSmCr]}
                                  underlayColor="rgba(0, 153, 168, 0.AS8)"
                                  onPress={this.handleOnSubmitCancel}
                                  disabled={this.state.saveWait}>
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent,
                                      ]}>
                                      <UIText
                                        style={[styles.submitText, styles.cancelTxtCr]}
                                        title="Cancel"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                            <View style={styles.fieldBtnSp} />
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={styles.submitSm}
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={this.handleOnSubmitSymptomSave}
                                  disabled={
                                    this.state.saveWait ||
                                    !this.getSymptomChanges
                                  }
                                  nativeID={'addSymptomSave'}>
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent,
                                      ]}>
                                      {this.state.saveWait && (
                                        <View style={styles.actIndCom}>
                                          <ActivityIndicator size="small" />
                                        </View>
                                      )}
                                      <UIText
                                        style={styles.submitText}
                                        title="Save"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </PageContainer>
            </View>
          </ScrollView>
        </View>
        {this.state.cancelConfirm &&
        <SavingConfirmation
          visibleModal={this.state.cancelConfirm}
          alertTitle={symptomTitle}
          alertBody={alertBody}
          handleCancel={() => {
            this.setState({cancelConfirm: false});
            this.handlecancelSymptom();
          }}
          handleConfirm={() => {
            this.setState({cancelConfirm: false}, () => {
              this.handleOnSubmitSymptomSave();
            });
          }}
          confirmWait={this.state.saveWait}
          disabled={this.state.saveWait}
          onBackdropPress={() => this.setState({cancelConfirm: false})}
        />
        }
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    selectedSymptom: state.symptom.selectedSymptom,
    errorDescription: state.symptom.errorDescription,
    error: state.symptom.error,
    journalUserId: state.uistate.journalUserId,
    userSymptoms: state.routines.symptomsDigest
      ? state.routines.symptomsDigest.userSymptoms
      : [],
    windowWidth: state.uistate.windowWidth,
    eventData: state.uistate.eventData,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    createUserSymptom: (userSymptom, callback, journalUserId) =>
      dispatch(actions.createUserSymptom(userSymptom, callback, journalUserId)),
    onSelectSymptom: (selectedSymptom, callback) =>
      dispatch(actions.onSelectSymptom(selectedSymptom, callback)),
    updateSystemDefinedSymptoms: callback =>
      dispatch(actions.updateSystemDefinedSymptoms(callback)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEditCustomSymptom);
