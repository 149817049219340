
import {StyleSheet} from 'react-native';

export default StyleSheet.create({

  questionWrapper: {
    borderRadius: 4,

  },

  questionInput: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    paddingRight: 10,
    height: 75,
  },

  questionImageWrapper: {
    marginTop: 30,
    marginBottom: 30,
    height: 164
  },

  QuestionLongAnswerWrapper: {
    marginTop: 20
  },

  longAnswerReadOnlyText: {
    color: '#98989A',
    paddingTop:10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,

  }

});
