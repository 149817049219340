import React from "react";
import { View } from "react-native";
import styles from "../../../Css";
import AppStore from "./appStore";
import PlayStore from "./playStore";
import { isAndroid, isIOS } from "react-device-detect";
const storeCommon = (props) => {
  return (
    <View style={[styles.storeMainWrapper]}>
      <View style={[styles.flexCom, styles.flexRow, styles.flexJcCtAiCt]}>
        {isIOS ? (
          <AppStore windowWidth={props.windowWidth} webMobile={true} />
        ) : isAndroid ? (
          <PlayStore windowWidth={props.windowWidth} webMobile={true} />
        ) : (
          <>
            <AppStore windowWidth={props.windowWidth} webMobile={true} />
            <PlayStore windowWidth={props.windowWidth} webMobile={true} />
          </>
        )}
      </View>
    </View>
  );
};

export default storeCommon;
