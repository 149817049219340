import React, {Component} from 'react';
import {View, TextInput} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import styles from '../../../Css';

class questionText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newJournal: props.newJournal ? props.newJournal : null,
      newTherapie: props.newTherapie ? props.newTherapie : null,
      newContact: props.newContact ? props.newContact : null,
      questionList: props.questionList,
      questionValue: props.questionValue ? props.questionValue : '',
      id: props.id ? props.id : -1,
      journalEntrySelected: props.journalEntrySelected
        ? props.journalEntrySelected
        : null,
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      newJournal: props.newJournal ? props.newJournal : null,
      newTherapie: props.newTherapie ? props.newTherapie : null,
      newContact: props.newContact ? props.newContact : null,
      questionList: props.questionList,
      journalEntrySelected: props.journalEntrySelected
        ? props.journalEntrySelected
        : null,
    });

  }

  componentDidUpdate(prevProps){

    const { remoteSubmit , changeRemoteSubmit} = this.props;

    if(prevProps.remoteSubmit !== remoteSubmit && remoteSubmit && changeRemoteSubmit){
      this.handleAddQuestion(changeRemoteSubmit);
    }else if(remoteSubmit){
      changeRemoteSubmit(false);
    }
  }

  componentWillUnmount() {
    this.props.updateTemporyQuestionObject(null);
  }

  onChangeTextQuestion = questionValue => {

    this.setState({questionValue});
    if (questionValue) {
      const healthQuestion = {
        artifactId: this.getArtifactId(),
        healthQuestion: {
          id: this.state.id,
          content: questionValue,
          openedDate: Date.now(),
        },
      };
      this.props.updateTemporyQuestionObject(healthQuestion);
    } else {
      this.props.updateTemporyQuestionObject(null);
    }
  };

  getArtifactId = () => {
    let artifactId = null;
    if (this.state.newJournal && this.props.type === 'journal') {
      artifactId = this.state.newJournal.artifactId;
    } else if (this.state.newTherapie && this.props.type === 'therapy') {
      artifactId = this.state.newTherapie.artifactId;
    } else if (this.state.newContact && this.props.type === 'contact') {
      artifactId = this.state.newContact.artifactId;
    } else if (this.state.journalEntrySelected && this.props.type === 'selectedJournal') {
      artifactId = this.state.journalEntrySelected.artifactId;
    }
    return artifactId;
  }

  handleAddQuestion = (callBack) => {
    if (this.props.scrollToEnd) {
      this.props.scrollToEnd();
    }

    const artifactId = this.getArtifactId();
    
    if (this.state.questionValue) {
      const healthQuestion = {
        artifactId: artifactId,
        healthQuestion: {
          id: this.state.id,
          content: this.state.questionValue,
          openedDate: Date.now(),
        },
      };

      this.props.onSaveHealthQuestion(
        healthQuestion,
        artifactId,
        (sucess, res) => {
          const questionList = [...this.state.questionList];
          questionList.push(res.healthQuestion);
          this.setState(
            {
              questionList: questionList,
            },
            () => {
              this.props.onUpdateQestionList(questionList);
              this.setState({questionValue: ''});
            },
          );
          this.props.handleHideQuestionTextBox();
          this.props.updateTemporyQuestionObject(null);
          if(callBack)callBack(false,true)
        },
        this.props.journalUserId,
      );
    }
  };
  render() {
    return (
      <React.Fragment>
        <View style={[styles.fieldRow]}>
          <TextInput
            style={[
              styles.textField,
              styles.textFieldSm,
              styles.textFieldWhite,
              styles.textAreaMax,
            ]}
            allowFontScaling={false}
            placeholder="Add a new to-do"
            value={this.state.questionValue}
            keyboardType="default"
            returnKeyType="done"
            onSubmitEditing={this.handleAddQuestion}
            onChangeText={this.onChangeTextQuestion}
            autoFocus={true}
            //onBlur={this.handleAddQuestion}
            // multiline={true}
            // numberOfLines={2}
            underlineColorAndroid="transparent"
          />
        </View>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    newJournal: state.routineTemplate.newJournal,
    journalUserId: state.uistate.journalUserId,
    newTherapie: state.therapie.newTherapie,
    newContact: state.contacts.newContact
      ? state.contacts.newContact.data
      : null,
    journalEntrySelected: state.routines.journalEntrySelected,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSaveHealthQuestion: (
      healthQuestion,
      artifactId,
      callBack,
      journalUserId,
    ) =>
      dispatch(
        actions.saveHealthQuestion(
          healthQuestion,
          artifactId,
          callBack,
          journalUserId,
        ),
      ),
    onUpdateQestionList: questionList =>
      dispatch(actions.updateQestionList(questionList)),
    updateTemporyQuestionObject: questionObject =>
      dispatch(actions.updateTemporyQuestionObject(questionObject)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(questionText);
