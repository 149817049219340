import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL_COMMUNITY_GROUPS } from "../store/actions/urls";

const axiosConfigCommonCommunityGroups = axios.create({
  baseURL: BASE_URL_COMMUNITY_GROUPS
});

axiosConfigCommonCommunityGroups.interceptors.request.use(
  async resp => {
    const token = Cookies.get("authToken");
    if (token) {
      resp.headers["jwt_token"] = token;
      return resp;
    }
  },
  error => Promise.reject(error)
);

export default axiosConfigCommonCommunityGroups;
