import React, { Component } from 'react';
import {View} from 'react-native';
import styles from '../../../../Css';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import Collapsible from '../../../Collapsible/Collapsible';
import ActivityMyList from '../../JournalSlider/ActivityMyList';
import ItemDeleteButton from '../../../Button/itemDeleteButton';
import PlusButtonCom from '../../../Button/plusButtonCom';
import ActivityAutoComplete from '../../../AutoComplete/CommonAutoComplete/Activity';
import SystemItem from './SystemItem';
import UserItemView from './UserItemView';
import UserItemEdit from './UserItemEdit';
import { verifyDuplicateMedication } from '../../../../util/commonUiLogic';
import {getSortedActivityDosagesWithTreatmentObject} from "../../../../store/actions/index";
class Activity extends Component {
  constructor(props) {
    super(props);
    const {dosages} = props;
    const sortedActivityDosagesWithTreatmentObject = getSortedActivityDosagesWithTreatmentObject(dosages, false, props.treatmentsProcessed);
    this.state = {
      collapsible: {
        activity: sortedActivityDosagesWithTreatmentObject && sortedActivityDosagesWithTreatmentObject.length > 0,
      },
      autocompleteOpen: false,
      dosages: sortedActivityDosagesWithTreatmentObject,
      add: false,
      selectedValues: Activity.getSelectedValues(sortedActivityDosagesWithTreatmentObject, props),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {newJournal, isCopyPrevious} = props;
    if (newJournal && isCopyPrevious && isCopyPrevious()) {
      const {dosages} = newJournal;
      const sortedActivityDosagesWithTreatmentObject = getSortedActivityDosagesWithTreatmentObject(dosages, false, props.treatmentsProcessed);

      return {
        collapsible: {
          activity: sortedActivityDosagesWithTreatmentObject && sortedActivityDosagesWithTreatmentObject.length > 0,
        },
        dosages: sortedActivityDosagesWithTreatmentObject,
        selectedValues: Activity.getSelectedValues(sortedActivityDosagesWithTreatmentObject),
      };
    }

    return null;
  }

  static getSelectedValues = (dosages) => {
    const selectedValues = {};

    if (dosages) {
      dosages.forEach(element => {
        let obj = {};
        if (element.type === 'SYSTEM' || element.type === 'CUSTOM') {
          obj = element;
        } else {
          obj = element.treatmentObj;
        }
        if (obj) {
          selectedValues[obj.name] = obj.name;
        }
      });
    }
    return selectedValues;
  };

  handleOnPressCollapsible = key => {
    const {collapsible} = this.state;
    let val = collapsible[key];
    collapsible[key] = !val;
    let add = false;

    this.setState({collapsible: collapsible, add: add});
  };

  updateAutocompleteOpen = val => {
    this.setState({autocompleteOpen: val});
  };

  onPressItem = (item, type) => {
    const {dosages, selectedValues} = this.state;
    const { treatmentsProcessed, updateActivity, type: componentType } = this.props;
    let existItem = true;
    this.setAdd();

    if (dosages && dosages.length > 0) {
      if (type === 'USER') {
        dosages.forEach(i => {
          if (i.treatment === item.data.id) {
            existItem = false;
          }
        });
      } else {
        dosages
          .filter(d => d.id === -1)
          .forEach(i => {
            if (
              i.type === 'USER'
                ? treatmentsProcessed[i.treatment] &&
                  treatmentsProcessed[i.treatment].name === item
                : i?.treatmentObj?.name === item
            ) {
              existItem = false;
            }
          });
      }
    }
    if (existItem) {
      if (type === 'USER') {
        dosages.push({
          id: -1,
          new: true,
          quantity: componentType === 'R'
            ? null
            : item.data.dosages && item.data.dosages[0]
              ? item.data.dosages[0].quantity
              : null,
          units:
            item.data.dosages && item.data.dosages[0]
              ? item.data.dosages[0].units
              : null,
          treatment: item.data.id,
          type: type,
          name: item.name,
          accept: true,
        });
        const obj = treatmentsProcessed[item.data.id];
        if (obj) {
          selectedValues[obj.id] = obj.id;
        }
      } else if (type === 'SYSTEM') {
        selectedValues[item.name] = item;
        dosages.push({
          name: item.name,
          notes: null,
          new: true,
          prescribedBy: null,
          identificationNumber: null,
          treatmentType: 5,
          active: true,
          isActive: true,
          dosages: [
            {
              quantity: componentType === 'R' ? null : 0,
              frequency: 1,
              frequencyType: 'DAILY',
              units: 'minutes',
            },
          ],
          displayProperties: {
            color: null,
            dashedLine: true,
          },
          type: type,
          accept: true,
          userDosingLog: {
            quantity: 0,
            units: 'minutes',
          },
        });
      } else {
        selectedValues[item] = item;
        dosages.push({
          name: item,
          notes: null,
          new: true,
          prescribedBy: null,
          identificationNumber: null,
          treatmentType: 5,
          active: true,
          isActive: true,
          dosages: [
            {
              quantity: componentType === 'R' ? null : 0,
              frequency: 1,
              frequencyType: 'DAILY',
              units: 'minutes',
            },
          ],
          displayProperties: {
            color: null,
            dashedLine: true,
          },
          type: type,
          accept: true,
          userDosingLog: {
            quantity: 0,
            units: 'minutes',
          },
        });
      }

      this.setState({dosages: dosages, selectedValues: selectedValues}, () =>
        updateActivity(dosages),
      );
    }
  };

  setAdd = () => {
    const {add} = this.state;
    this.setState({add: !add});
  };

  getItem = (item, index) => {
    const {
      treatmentsProcessed,
      type,
      handleRedirectActivity,
      windowWidth,
      isNew
    } = this.props;
    let units = null;
    if (
      treatmentsProcessed[item.treatment] &&
      treatmentsProcessed[item.treatment].dosages &&
      treatmentsProcessed[item.treatment].dosages.length > 0
    ) {
      units = treatmentsProcessed[item.treatment].dosages[0].units;
    }

    if (item.type === 'SYSTEM' || item.type === 'CUSTOM') {
      return (
        <SystemItem
          data={item}
          index={index}
          key={'med_' + index}
          updateActivity={this.updateActivity}
          type={type}
          deleteItem={this.deleteItem}
          windowWidth={windowWidth}
          isNew={isNew}
        />
      );
    } 
    // else if ((!units || units === 'minutes') && type === 'R') {
    //   return (
    //     <ActivitySlider
    //       data={item}
    //       treatmentsProcessed={treatmentsProcessed}
    //       index={index}
    //       key={'med_' + index}
    //       updateActivity={this.updateActivity}
    //       deleteItem={this.deleteItem}
    //       handleRedirectActivity={handleRedirectActivity}
    //     />
    //   );
    // } 
    else if (
      treatmentsProcessed[item.treatment] &&
      treatmentsProcessed[item.treatment].dosages &&
      treatmentsProcessed[item.treatment].dosages.length > 0 &&
      (treatmentsProcessed[item.treatment].dosages[0].quantity === null ||
        treatmentsProcessed[item.treatment].dosages[0].quantity === undefined)
    ) {
      return (
        <UserItemEdit
          data={item}
          index={index}
          key={'med_' + index}
          treatmentsProcessed={treatmentsProcessed}
          updateActivity={this.updateActivity}
          handleRedirectActivity={handleRedirectActivity}
          windowWidth={windowWidth}
          deleteItem={this.deleteItem}
          type={type}
          isNew={isNew}
        />
      );
    } else if (type === 'RT') {
      return (
        <UserItemView
          data={item}
          index={index}
          key={'med_' + index}
          deleteItem={this.deleteItem}
          updateActivity={this.updateActivity}
          treatmentsProcessed={treatmentsProcessed}
          handleRedirectActivity={handleRedirectActivity}
        />
      );
    } else if (treatmentsProcessed[item.treatment]) {
      return (
        // <OtherItem
        //   data={item}
        //   index={index}
        //   key={'med_' + index}
        //   treatmentsProcessed={treatmentsProcessed}
        //   updateActivity={this.updateActivity}
        //   deleteItem={this.deleteItem}
        //   handleRedirectActivity={handleRedirectActivity}
        // />
        <ActivityMyList
          data={item}
          treatmentsProcessed={treatmentsProcessed}
          index={index}
          key={'med_' + index}
          updateActivity={this.updateActivity}
          type={type}
          deleteItem={this.deleteItem}
          windowWidth={windowWidth}
          isNew={isNew}
        />
      );
    }

    return null;
  };

  updateActivity = (index, data) => {
    const {dosages} = this.state;
    const { updateActivity, isInvalidName, isActivityDuplicate } = this.props;
    dosages[index] = data;
    this.setState(
      {
        dosages: dosages,
      },
      () => updateActivity(dosages),
    );
    let hasInvalidNames = this.checkInvalidName();
    let hasDuplicates = this.checkDuplicates();
    isInvalidName(hasInvalidNames);
    isActivityDuplicate(hasDuplicates);
  };

  checkInvalidName = () => {
    let isInvalidName = this.state.dosages.some(dosage => {
      return dosage?.name?.trim() === '';
    });
    return isInvalidName;
  }

  executeDuplicateChecker = (name, unit, quantity) => {
    const { listTherapie } = this.props;
    let res = false;

    verifyDuplicateMedication(name, null, unit, quantity, listTherapie, (exist, msg) => {
      if (exist) {
        res = true;
      }
    });

    return res;
  }

  checkDuplicates = () => {

    const { dosages = [] } = this.state;
    let res = false;

    try {
      for (const property of dosages) {

        if (property.type && property.type !== "USER") {
          const checkResult = this.executeDuplicateChecker(property.name, property.dosages[0].units, property.dosages[0].quantity);
          if (checkResult) {
            res = true;
            break;
          }
        }
      }
    } catch (error) {
      console.log('checkDuplicates error', error);
    }

    return res;
  }

  deleteItem = index => {
    const {dosages} = this.state;
    const { updateActivity, isInvalidName, isActivityDuplicate, treatmentsProcessed } = this.props;

    dosages.splice(index, 1);
    const sortedActivityDosagesWithTreatmentObject = getSortedActivityDosagesWithTreatmentObject(dosages, false, treatmentsProcessed);
    this.setState(
      {
        dosages: dosages,
        selectedValues: Activity.getSelectedValues(sortedActivityDosagesWithTreatmentObject, this.props),
      },
      () => updateActivity(dosages),
    );
    let hasInvalidNames = this.checkInvalidName();
    let hasDuplicates = this.checkDuplicates();
    isInvalidName(hasInvalidNames);
    isActivityDuplicate(hasDuplicates);
  };

  render() {
    const {dosages, add, selectedValues, collapsible} = this.state;

    const {plusButton, disabledScroll, type} = this.props;
    return (
      <Collapsible
        title={'Activity'}
        contentVisibility={collapsible.activity}
        handleOnPressCollapsible={() =>
          this.handleOnPressCollapsible('activity')
        }
        style={[styles.collapseTitle]}
        customTitleStyle={true}
        plusButton={plusButton}
        topBorder={true}
        nativeId={type === 'R' ? 'journalActivity' : 'routineActivity'}
      >
        <View style={[styles.collapseContent, styles.colPdTopLtRt]}>
          <View style={[styles.fieldContainer, styles.fieldRowNoMg]}>
            <View style={[styles.fieldRowSliderWrapper]}>
              {/* Loop Item for Activity Slider - Start */}

              {dosages.map((item, index) => this.getItem(item, index))}

              {/* Loop Item for Activity Slider - End */}
            </View>
          </View>

          {!Boolean(add) && (
            <View style={[styles.btnArea, styles.btnAreaPd, styles.zIndexMin]}>
              <PlusButtonCom
                btnPosition={'left'}
                btnText={'Add'}
                touchableOnPress={this.setAdd}
                nativeId={type === 'R' ? 'journalAddActivity' : 'routineAddActivity'}
              />
            </View>
          )}
        </View>

        {Boolean(add) && (
          <View
            style={[
              styles.fieldRowCom,
              styles.fieldRowNoMg,
              styles.zIndexAutoComplete,
              styles.colPdLtRtBot,
            ]}>
            <View style={[styles.fieldRowComWrapper]}>
              <View style={styles.fieldColStretch}>
                <ActivityAutoComplete
                  updateAutocompleteOpen={this.updateAutocompleteOpen}
                  onPressItem={this.onPressItem}
                  selectedValues={selectedValues}
                  disabledScroll={disabledScroll}
                />
              </View>
              <View style={styles.fieldColSp} />
              <View style={styles.fieldColDelete}>
                <ItemDeleteButton touchableOnPress={this.setAdd} />
              </View>
            </View>
          </View>
        )}
      </Collapsible>
    );
  }
}

const mapStateToProps = state => {
  return {
    newJournal: state.routineTemplate.newJournal,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    listTherapie: state.routines.treatments,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    isInvalidName: flag => dispatch(actions.hasEntryActivityInvalidNames(flag)),
    isActivityDuplicate: flag => dispatch(actions.hasEntryActivityDuplicates(flag)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Activity);
