import React from "react";
import {ScrollView, View} from 'react-native';
import styles from '../../../Css';
import stylesResponsive from "../../../CssResponsive";

const modalTemplate = props => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  return (
    <>
      <ScrollView style={styles.modalScroller}>
        <View style={[styles.popUpBlock, styles.bgWhite]}>
          <View style={styles.popUpContentArea}>
            <View style={styles.gridSectionPopUp}>
              <View style={[styles.popUpContentPd, styles.modalContentBg]}>
                <View style={stylesRes.modalContIn}>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={styles.menuBarLgList}>
                      {props.children}  
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default (modalTemplate);