import React from "react";
import { View, TouchableOpacity, Text, Image, Platform } from "react-native";
import { connect } from "react-redux";
import moment from "moment";
import Header from "../../../components/Header/header";
import SpaceBar from "../../../components/SpaceBar/SpaceBar";
import ActivityIndicator from "../../../components/ActivityIndicator/activityIndicator";
import Collapsible from "../../../components/Collapsible/Collapsible";
import GraphSection from "../../../components/Settings/RollingGraphs/GraphSection";
import Symptoms from "../../../components/Settings/RollingGraphs/Symptoms";
import Metrics from "../../../components/Settings/RollingGraphs/Metrics";
import Therapies from "../../../components/Settings/RollingGraphs/Therapies";
import GraphName from "../../../components/Settings/RollingGraphs/GraphName";
import SuccessBox from "../../../components/ConfirmationBox/successBox";
import SavingConfirmation from "../../../components/ConfirmationBox/savingConfirmation";
import CancelButton from '../../../components/Button/cancelButton';
import PageContainer from "../../../components/PageLayout/PageContainer";
import KeyboardAwareScrollViewHoc from '../../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import MenuOverlayContainer from "../../../components/PageLayout/MenuOverlayContainer";
import ScrollViewCustom from "./scrollViewCustom";
import AddEditRollingGraphCommon from "./AddEditRollingGraphsCommon";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";
import * as actions from "../../../store/actions/index";
import * as axiosCommon from "../../../axios/axios-common";
import * as url from "../../../store/actions/urls";
import { GRAPHS, DASHBOARD } from "../../../navigation/path";
import { hasValue } from "../../../util/hashValueGenerator";
import { graphBody, graphBodyCommon, graphAfterTitle, graphBeforeTile } from "../../../GlobalFunctions/GlobalString"
import { pendoTrackEvent } from "../../../util/pendoConfig";
import { getScrollHeight } from '../../../util/commonUiLogic';
import { WEB } from "../../../util/platformWindowConfig";

class AddEditRollingGraphsScreen extends AddEditRollingGraphCommon {
  constructor(props) {
    super(props);
    let fromPopup = null;
    let entryTypeId = null;
    if (props.route?.params && props.route?.params.fromPopup) {
      fromPopup = props.route?.params.fromPopup;
      entryTypeId = props.route?.params.entryTypeId;
    }

    let publishedByPartner = false;
    let routineSymptoms = [];
    let routineMetrics = [];
    let routineTreatments = [];
    if (props.entryTypeId && 
          props.journalEntryTypesProcessed.hasOwnProperty(props.entryTypeId) && 
            props.journalEntryTypesProcessed[props.entryTypeId].journalPublishedBy === 'PARTNER'
    ) {
      publishedByPartner = true;
      let symptoms = props.journalEntryTypesProcessed[props.entryTypeId].defaultSymptoms;
      if (symptoms && symptoms.length > 0) {
        routineSymptoms = symptoms;
      }
      let metrics = props.journalEntryTypesProcessed[props.entryTypeId].defaultMetrics;
      if (metrics && metrics.length > 0) {
        routineMetrics = metrics;
      }
      let treatments = props.journalEntryTypesProcessed[props.entryTypeId].defaultTreatments;
      if (treatments && treatments.length > 0) {
        routineTreatments = treatments;
      }
    }
    this.state = {
      visibleTherapy: props.dashboard && props.dashboard.length > 0 ? false : true,
      visibleSymptom: props.dashboard && props.dashboard.length > 0 ? false : true,
      visibleMetric: props.dashboard && props.dashboard.length > 0 ? false : true,
      visibleActivity: props.dashboard && props.dashboard.length > 0 ? false : true,
      saveWait: false,
      validGraphName: true,
      visibleWarning: false,
      visibleMinMaxWarning: false,
      confirmBox: false,
      //uri: null,
      downloadwait: false,
      dataFound: false,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      //scrollHeight: 0,
      fromPopup: fromPopup,
      entryTypeId: entryTypeId,
      publishedByPartner: publishedByPartner,
      routineSymptoms: routineSymptoms,
      routineMetrics: routineMetrics,
      routineTreatments: routineTreatments,
      isFromCancel: false,
      validExistingGraphName: true,
      isColorPicked: false,
      addedTherapiesSet:[],
      addedSymptomSet:[],
      addedMetricSet:[]
    };
    this.props.hideMobileMenu();
    this.scrollViewRef = null;
  }

  componentDidMount() {
    pendoTrackEvent(this.props.route.name === GRAPHS.addGraphPath ? 'Graphs/@AddGraph' : 'Graphs/@Viewgraph');
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    });
    this.props.clearTempClr();
  }

  handleOnSubmitSave = () => {
    this.setState({ saveWait: true }, () => {
      if (!this.props.graphName) {
        this.setState({ validGraphName: false, saveWait: false });
        this.props.commonErrorHandler('Graph name is missing.');
        this.scrollToTop();
        return;
      }
      this.props.updateGraphName(this.props.graphName.trim());
      let from = null;
      if (this.props.route?.params && this.props.route?.params.from) {
        from = this.props.route?.params.from;
      }
      let metrics = null;
      let symptoms = null;
      let therapies = null;

      Object.keys(this.props.metrics).forEach((id, index) => {
        if (index === 0) {
          metrics = id;
        } else {
          metrics += "," + id;
        }
      });

      Object.keys(this.props.symptoms).forEach((id, index) => {
        if (index === 0) {
          symptoms = id;
        } else {
          symptoms += "," + id;
        }
      });

      Object.keys(this.props.therapies).forEach((id, index) => {
        if (index === 0) {
          therapies = id;
        } else {
          therapies += "," + id;
        }
      });

      if (!metrics && !symptoms && !therapies) {
        this.setState({ visibleWarning: true, saveWait: false });
        setTimeout(() => {
          this.setState({ visibleWarning: false });
        }, 3000);
        return;
      }

      let treatmentsProps = [];
      let symptomsProps = [];
      let metricsProps = [];
      let activityMinMaxError = false;
      let therapyMinMaxError = false;
      let symptomMinMaxError = false;
      let metricMinMaxError = false;
      Object.values(this.props.therapyProps).forEach(item => {
        if(parseInt(item.max) <= parseInt(item.min)) {
          if(this.props.treatmentsProcessed[item.id].treatmentType === 5) {
            activityMinMaxError = true;
          } else {
            therapyMinMaxError = true;
          }
          return;
        }
        treatmentsProps.push(item);
      });
      Object.values(this.props.symptomProps).forEach(item => {
        if(parseInt(item.max) <= parseInt(item.min)) {
          symptomMinMaxError = true;
          return;
        }
        symptomsProps.push(item);
      });
      Object.values(this.props.metricProps).forEach(item => {
        if(parseInt(item.max) <= parseInt(item.min)) {
          metricMinMaxError = true;
          return;
        }
        metricsProps.push(item);
      });

      if (activityMinMaxError || therapyMinMaxError || symptomMinMaxError || metricMinMaxError) {
        this.setState({ visibleMinMaxWarning: true, saveWait: false });
        if(activityMinMaxError) {
          this.setState({visibleActivity: true});
        }
        if(therapyMinMaxError) {
          this.setState({visibleTherapy: true});
        }
        if(symptomMinMaxError) {
          this.setState({visibleSymptom: true});
        }
        if(metricMinMaxError) {
          this.setState({visibleMetric: true});
        }
        setTimeout(() => {
          this.setState({ visibleMinMaxWarning: false });
        }, 3000);
        return;
      }


      
        let updatedUserTreatments = this.state.addedTherapiesSet ? this.state.addedTherapiesSet : null;
        let updatedUserSymptoms = this.state.addedSymptomSet ? this.state.addedSymptomSet : null;
        let updatedUserMetrics = this.state.addedMetricSet ? this.state.addedMetricSet : null;
        this.getGraphImage(res => {
          pendoTrackEvent('NewGraph@ > Save');
          this.props.updateDashboardGraph(
            {
              id: this.props.id ? this.props.id : -1,
              treatments: therapies,
              metrics: metrics,
              symptoms: symptoms,
              name: this.props.graphName,
              startDate: this.props.startDate
                ? moment.utc(this.props.startDate).valueOf()
                : null,
              endDate: this.props.endDate
                ? moment.utc(this.props.endDate).valueOf()
                : null,
              treatmentsProperties: JSON.stringify(treatmentsProps),
              symptomsProperties: JSON.stringify(symptomsProps),
              metricsProperties: JSON.stringify(metricsProps),
              graph: res,
              entryTypeId: this.props.entryTypeId ? this.props.entryTypeId : this.state.entryTypeId,
              updatedUserTreatments,
              updatedUserSymptoms,
              updatedUserMetrics,
            },
            (sucess, data) => {
              this.setState({ saveWait: false, confirmBox: false });
              if (sucess) {
                if (from && from.includes(DASHBOARD.path)) {
                  this.props.onSelectGraphItem(data.id);
                  this.props.navigation.navigate(DASHBOARD.path);
                } else {
                  this.props.navigation.navigate(
                    GRAPHS.path, {
                      from: this.props.route.name,
                    },
                  );
                }
                data.updatedUserTreatments.forEach((item)=>{
                  this.props.updateTreatments(item);
                })
  
                data.updatedUserSymptoms.forEach((item)=>{
                  this.props.updateNewSymptom(item)
                })
  
                data.updatedUserMetrics.forEach((item)=>{
                  this.props.updateUserMetrics(item)
                })
              }
              this.props.refreshGraph()
            },
            this.props.journalUserId
          );
        });
    });
  };

  cancelClickHandler = () => {
    if (this.state.fromPopup) {
      this.props.setFromPopup(this.state.fromPopup);
    }
    if (this.props.route?.params && this.props.route?.params?.from && this.props.route?.params?.from === GRAPHS.path) {
      this.props.navigation.reset({
        index: 0,
        routes: [{ 
          name: GRAPHS.path,
          params: {from: this.props.route.name}
        }],
      });
    } else {
      this.props.navigation.goBack();
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { windowHeight, windowWidth } = nextProps;
    this.setState({
      windowWidth,
      windowHeight,
    });
  };

  componentWillUnmount = () => {
    if (this.props.previewGraph) {
      this.props.onPreviewGraph();
    }
    this.props.showMobileMenu();
    this.props.clearTempClr();
  };

  download = () => {
      this.getGraphImage(res => {
        this.setState({ downloadwait: true });
        let downloadUrl = url.GRAPH_PDF;

        if (this.props.journalUserId) {
          downloadUrl += "?journalUserId=" + this.props.journalUserId;
        }
        axiosCommon.axiosPOST(
          downloadUrl,

          (res) => {
            if (res.type === "SUCCESS") {
              axiosCommon.axiosGraphPdfDownload(
                url.GRAPH_PDF + "/" + res.responseDto.data.graphId,
                this.props.graphName + ".pdf",
                (res) => {
                  this.setState({ downloadwait: false });
                }
              );
            } else {
              this.setState({ downloadwait: false });
            }
          },
          {
            timeStamp:
              moment(new Date().getTime(), "x").format("DD MMM 'YY") +
              " " +
              moment(new Date().getTime(), "x").format("h:mm a"),
            startDate: this.props.startDate
              ? moment(this.props.startDate, "x").format("DD MMM 'YY")
              : "",
            endDate: this.props.endDate
              ? moment(this.props.endDate, "x").format("DD MMM 'YY")
              : "",
            reportFor: this.props.graphName,
            graph: res,
          }
        );
      })
  };

  handleOnCancel = () => {
    if (this.state.isColorPicked || this.getHashValue() !== this.props.initHashValue) {
      this.setState({
        confirmBox: true, isFromCancel: true
      });
    } else if (this.getHashValue() === this.props.initHashValue) {
      this.cancelClickHandler();
    } else {
      this.setState({
        confirmBox: true, isFromCancel: true
      });
    }
  };

  scrollToTop = () => {
    if (this.scrollViewRef) {
      setTimeout(() => {
        Platform.OS === WEB
        ? this.scrollViewRef?.scrollTo({ x: 0, y: 0, animated: true })
        : this.scrollViewRef.scrollToPosition(0, 0, true)
      }, 500);
    }
  };

  getHashValue = () => {
    let metrics = null;
    let symptoms = null;
    let therapies = null;

    Object.keys(this.props.metrics).forEach((id, index) => {
      if (index === 0) {
        metrics = id;
      } else {
        metrics += "," + id;
      }
    });
    Object.keys(this.props.symptoms).forEach((id, index) => {
      if (index === 0) {
        symptoms = id;
      } else {
        symptoms += "," + id;
      }
    });
    Object.keys(this.props.therapies).forEach((id, index) => {
      if (index === 0) {
        therapies = id;
      } else {
        therapies += "," + id;
      }
    });

    let treatmentsProps = [];
    let symptomsProps = [];
    let metricsProps = [];
    Object.values(this.props.therapyProps).forEach(item => {
      treatmentsProps.push(item);
    });
    Object.values(this.props.symptomProps).forEach(item => {
      symptomsProps.push(item);
    });
    Object.values(this.props.metricProps).forEach(item => {
      metricsProps.push(item);
    });

    const currentHash = hasValue(
      this.props.id +
      ',' +
      this.props.startDate +
        ',' +
      this.props.endDate +
        ',' +
      this.props.graphName +
        ',' +
      metrics +
        ',' +
      symptoms +
        ',' +
      therapies +
        ',' +
      JSON.stringify(treatmentsProps) +
        ',' +
      JSON.stringify(symptomsProps) +
        ',' +
      JSON.stringify(metricsProps)
    );
    return currentHash;
  }

  enableSaveButton = (colorPicked) => {
    if (colorPicked) {
      this.setState({
        isColorPicked: true
      })
    } else {
      this.setState({
        isColorPicked: false
      })
    }
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const { journalUserId, timeZoneWarning, timeZone } = this.props;
    const { windowWidth, windowHeight} = this.state;

    if ((!this.props.id && this.props.initHashValue === "" && this.props.startDate && this.props.endDate) ||
          (this.props.id && this.props.initHashValue === "")) {
      this.props.setGraphInitHashValue(this.getHashValue());
    }
    const currentHash = this.getHashValue();
    let fullWidth = windowWidth;
    let screen = "";
    if (fullWidth > 1279) {
      screen = "lgScreen";
    } else {
      screen = "smScreen";
    }    
    let sectionHeight = 250;
    let scrollHeight = getScrollHeight(false, journalUserId, timeZoneWarning, timeZone, windowHeight, fullWidth, sectionHeight);

    return (
      <View style={styles.pageBodyWrapper}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          filterClickHandler={this.handleOnPressFilter}
          download
          clearFiltersHandler={this.handleOnPressClearFilters}
          handleOnPressBackButton={() => {
            if (this.props.previewGraph) {
              this.props.onPreviewGraph();
            } else {
              this.handleOnCancel();
            }
          }}
          index={GRAPHS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        {currentHash !== this.props.initHashValue &&
          <MenuOverlayContainer onPressOpen={this.handleOnCancel} />
        }
        <KeyboardAwareScrollViewHoc
          ref={val => (this.scrollViewRef = val)}
          hasNotch={this.props.hasNotch}
          onScroll={event => {
            this.setState({
              scrollHeight: event.nativeEvent.contentOffset.y
            });
          }}>
          <View style={styles.gridSection}>
            <PageContainer smScreenFull={true}>
              <View style={stylesRes.botExPd} dataSet={{media: ids.botExPd}}>
                <View style={[fullWidth > 1279 ? styles.flexRow : styles.flexCol, styles.contPdTop]}>
                  <View style={[fullWidth > 1279 ? styles.flexCom : styles.flexAuto]}>
                    <View style={[fullWidth > 1279
                          ? styles.colPdLtRtZero : styles.colPdLtRt]}>
                      {!Boolean(this.props.previewGraph) && (
                        <GraphName
                          validGraphName={this.state.validGraphName}
                          setGraphNameValidate={(isValid) =>
                            this.setState({ validGraphName: isValid })
                          }
                          validExistingGraphName={this.state.validExistingGraphName}
                          setValidExistingGraphName={(isValid) =>
                            this.setState({ validExistingGraphName: isValid,})
                          }
                        />
                      )}
                    </View>
                    {screen === "smScreen" && (
                      <GraphSection
                        updateGrph={this.updateGrph}
                        downloadwait={this.state.downloadwait}
                        download={this.download}
                        updateDataFound={(val) =>
                          this.setState({ dataFound: val })
                        }
                        dataFound={this.state.dataFound}
                        fullWidth={fullWidth}
                      />
                    )}

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <ScrollViewCustom
                          scrollHeight={scrollHeight}
                          fullWidth={fullWidth}
                        >
                        <View style={styles.secArea}>
                          <View
                            style={[
                              styles.SecCollapsible,
                              styles.borderThickRgTop,
                            ]}
                          >
                            <Collapsible
                                title="Activities"
                                contentVisibility={this.state.visibleActivity}
                                handleOnPressCollapsible={() =>
                                  this.setState({
                                    visibleActivity: !this.state.visibleActivity,
                                  })
                                }
                              >
                                <Therapies 
                                  fullWidth={fullWidth} 
                                  type="activity"
                                  publishedByPartner={this.state.publishedByPartner}
                                  routineTreatments={this.state.routineTreatments} 
                                  colorPicked={(colorPicked)=>this.enableSaveButton(colorPicked)}
                                  addNewTherapies={(addNewTherapies)=>{
                                    this.setState({
                                    addedTherapiesSet:[...this.state.addedTherapiesSet, addNewTherapies.treatment]
                                    })
                                    this.props.updateTempClr({ ...addNewTherapies.treatment, type: 'therapies' });
                                  }}
                                  />
                              </Collapsible>

                              <Collapsible
                                title="Meds/Supplements"
                                contentVisibility={this.state.visibleTherapy}
                                handleOnPressCollapsible={() =>
                                  this.setState({
                                    visibleTherapy: !this.state.visibleTherapy,
                                  })
                                }
                              >
                                <Therapies 
                                  fullWidth={fullWidth} 
                                  type="meds"
                                  publishedByPartner={this.state.publishedByPartner}
                                  routineTreatments={this.state.routineTreatments} 
                                  colorPicked={(colorPicked)=>this.enableSaveButton(colorPicked)}
                                  addNewTherapies={(addNewTherapies)=>{
                                    this.setState({
                                      addedTherapiesSet:[...this.state.addedTherapiesSet, addNewTherapies.treatment]
                                    })

                                    this.props.updateTempClr({ ...addNewTherapies.treatment, type: 'therapies' });
                                  }}
                                  />
                              </Collapsible>


                            <Collapsible
                              title="Symptoms"
                              contentVisibility={this.state.visibleSymptom}
                              handleOnPressCollapsible={() =>
                                this.setState({
                                  visibleSymptom: !this.state.visibleSymptom,
                                })
                              }
                            >
                              <Symptoms 
                                fullWidth={fullWidth}
                                publishedByPartner={this.state.publishedByPartner}
                                routineSymptoms={this.state.routineSymptoms} 
                                colorPicked={(colorPicked)=>this.enableSaveButton(colorPicked)}
                                  addNewUserSymptom={(addNewUserSymptom)=>{
                                    this.setState({
                                      addedSymptomSet:[...this.state.addedSymptomSet, addNewUserSymptom.userSymptom]
                                    })
                                    this.props.updateTempClr({ ...addNewUserSymptom.userSymptom, type: 'symptoms' });
                                  }}
                                />
                            </Collapsible>

                            <Collapsible
                              title="Health Data"
                              contentVisibility={this.state.visibleMetric}
                              handleOnPressCollapsible={() =>
                                this.setState({
                                  visibleMetric: !this.state.visibleMetric,
                                })
                              }
                            >
                              <Metrics 
                                fullWidth={fullWidth}
                                publishedByPartner={this.state.publishedByPartner}
                                routineMetrics={this.state.routineMetrics} 
                                colorPicked={(colorPicked)=>this.enableSaveButton(colorPicked)}
                                  addNewUserMetric={(addNewUserMetric)=>{
                                    this.setState({
                                      addedMetricSet:[...this.state.addedMetricSet, addNewUserMetric.metric],
                                    })

                                    this.props.updateTempClr({ ...addNewUserMetric.metric, type: 'metric' });
                                  }}
                                />
                            </Collapsible>
                          </View>
                        </View>
                        </ScrollViewCustom>
                      </View>
                    </View>
                    </View>

                    {screen === "lgScreen" && (
                      <View style={[styles.singleSecSpace, styles.flexJcCtAiCt]}>
                        <View style={styles.greyBorderSep}></View>
                      </View>
                    )}

                    <View
                      style={[
                        fullWidth > 1279 ? styles.flexCom : styles.flexAuto,
                      ]}
                    >
                    {screen === "lgScreen" && (
                      <GraphSection
                        updateGrph={this.updateGrph}
                        downloadwait={this.state.downloadwait}
                        download={this.download}
                        updateDataFound={(val) =>
                          this.setState({ dataFound: val })
                        }
                        dataFound={this.state.dataFound}
                        fullWidth={fullWidth}
                      />
                    )}
                    <View style={[styles.gridRow, fullWidth > 1279
                          ? styles.colPdLtRtZero : styles.colPdLtRt]}>
                      <View style={styles.gridColFull}>
                        {/* <View style={styles.colPdLtRt}> */}
                          <View style={styles.btnArea}>
                            <View style={stylesRes.btnViewSec}>
                              <View style={styles.fieldBtn}>
                                <View style={styles.btnBlockFull}>
                                  <CancelButton
                                    touchableOnpress={this.handleOnCancel}
                                    disabled={this.state.saveWait}
                                    cancelBtnWidthDiff={true}
                                  />
                                </View>
                              </View>
                              <View style={styles.fieldBtnSpSm} />
                              <View style={styles.fieldBtn}>
                                <View style={styles.btnBlockFull}>
                                  <TouchableOpacity
                                    style={[
                                      styles.submitSm,
                                      styles.btnShadowCom,
                                      (!this.state.isColorPicked) && (this.state.saveWait ||
                                        (currentHash === this.props.initHashValue && (!this.state.entryTypeId || this.props.graphId)))
                                        ? styles.submitBgOrangeLight
                                        : styles.submitBgOrange
                                    ]}
                                    underlayColor="rgba(0, 153, 168, 0.8)"
                                    onPress={() => {
                                      this.props.entryTypeId
                                        ? this.setState({confirmBox: true, isFromCancel: false})
                                        : this.handleOnSubmitSave()
                                    }}
                                    disabled={(!this.state.isColorPicked) && (this.state.saveWait ||
                                      (currentHash === this.props.initHashValue && (!this.state.entryTypeId || this.props.graphId)))
                                    }
                                    nativeID={'newGraphSave'}
                                  >
                                    <View style={styles.btnSingleView}>
                                      <View
                                        style={[
                                          styles.btnSingle,
                                          styles.btnSingleCent,
                                        ]}
                                      >
                                        {this.state.saveWait && (
                                          <View
                                            style={[
                                              styles.indicatorIconView,
                                              styles.indicatorLtCom,
                                            ]}
                                          >
                                            <ActivityIndicator
                                              pdZero={true}
                                              size="small"
                                            />
                                          </View>
                                        )}
                                        <Text
                                          style={[
                                            styles.submitBtnTextWhite,
                                            styles.bold,
                                          ]}
                                          allowFontScaling={false}
                                        >
                                          Save
                                        </Text>
                                      </View>
                                    </View>
                                  </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </PageContainer>
          </View>
        </KeyboardAwareScrollViewHoc>
        {this.state.visibleWarning &&
          <SuccessBox
            visibleModal={this.state.visibleWarning}
            onRequestClose={() => this.setState({ visibleWarning: false })}
            onBackdropPress={() => this.setState({ visibleWarning: false })}
            isWarning={true}
            message={"Please Select \n Meds/Supplements & Activities or Symptoms or Health Data"}
          />
        }
        {this.state.visibleMinMaxWarning &&
          <SuccessBox
            visibleModal={this.state.visibleMinMaxWarning}
            onRequestClose={() => this.setState({ visibleMinMaxWarning: false })}
            onBackdropPress={() => this.setState({ visibleMinMaxWarning: false })}
            isWarning={true}
            message={"Please review entered Min and Max"}
          />
        }
        {this.state.confirmBox &&
          <SavingConfirmation
            visibleModal={this.state.confirmBox}
            alertBody={
              this.props.entryTypeId
                ? graphBody
                : graphBodyCommon
            }
            alertTitle={
              !this.state.isFromCancel
                ? graphBeforeTile
                : graphAfterTitle
            }
            btnTextOne={!this.state.isFromCancel ? "Cancel" : "Don’t Save"}
            handleCancel={() => {
              this.setState({ confirmBox: false }, () => {
                this.cancelClickHandler();
              });
            }}
            handleConfirm={() =>{
              this.setState({ confirmBox: false });
              this.handleOnSubmitSave()
            }}
            confirmWait={this.state.saveWait}
            disabled={this.state.saveWait}
            onBackdropPress={() => {
              this.setState({ confirmBox: false });
            }}
          />
        }
        {fullWidth <= 1279 && this.state.scrollHeight > 0 &&
          <View style={styles.scrollTop}>
          <TouchableOpacity
            onPress={this.scrollToTop}>
            <Image
              style={styles.scrollTopIcon}
              source={require("../../../assets/icons/scroll-top-square.png")}
            />
          </TouchableOpacity>
        </View>
        }
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: state.rollinggraphs.id,
    graphName: state.rollinggraphs.graphName,
    startDate: state.rollinggraphs.startDate,
    endDate: state.rollinggraphs.endDate,
    metrics: state.rollinggraphs.metrics,
    symptoms: state.rollinggraphs.symptoms,
    therapies: state.rollinggraphs.therapies,
    journalUserId: state.uistate.journalUserId,
    previewGraph: state.rollinggraphs.previewGraph,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    initHashValue: state.rollinggraphs.initHashValue,
    symptomProps: state.rollinggraphs.symptomProps,
    metricProps: state.rollinggraphs.metricProps,
    therapyProps: state.rollinggraphs.therapyProps,
    hasNotch: state.uistate.hasNotch,
    dashboard: state.routines.dashboard,
    entryTypeId: state.rollinggraphs.entryTypeId,
    visibleGraph: state.rollinggraphs.dashboardGraph,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDashboardGraph: (data, callBack, journalUserId) =>
      dispatch(actions.updateDashboardGraph(data, callBack, journalUserId)),
    selectGraph: (graph, callBack) =>
      dispatch(actions.selectGraph(graph, callBack)),
    onPreviewGraph: () => dispatch(actions.previewGraph()),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    setGraphInitHashValue: (initHashVal) =>
      dispatch(actions.setGraphInitHashValue(initHashVal)),
    setFromPopup: (fromPopup) => dispatch(actions.setFromPopup(fromPopup)),
    onSelectGraphItem: (graphId) => dispatch(actions.selectGraphItem(graphId)),
    onHideShowGraph: (value) => dispatch(actions.onHideShowGraph(value)),
    updateGraphName: (name) => dispatch(actions.updateGraphName(name)),
    refreshGraph: () => dispatch(actions.refreshGraph()),
    updateTreatments: (data) => dispatch(actions.updateTreatments(data)),
    updateNewSymptom: (data) => dispatch(actions.updateNewSymptom(data)),
    updateUserMetrics: (data) => dispatch(actions.updateUserMetrics(data)),
    updateTempClr: (data) => dispatch(actions.updateTempClr(data)),
    clearTempClr: (data) => dispatch(actions.clearTempClr(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditRollingGraphsScreen);
