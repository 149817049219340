import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import styles from "../../Css";
import { TAB_BREAk_POINT_START } from "../../util/platformWindowConfig";

const collapseWrapper = (props) => {
  return (
    <View style={[styles.collapseBoxWrapper, styles.bgWhite]}>
      <View style={[styles.collapseBoxTitleWrapper]}>
        <View style={[styles.collapseBoxTitle]}>
          <View style={[styles.collapseAllTitle]}>
            <Text
              style={[
                props.windowWidth > TAB_BREAk_POINT_START
                  ? styles.textTitleExLgBold
                  : styles.textTitleMdBold,
              ]}
              allowFontScaling={false}
            >
              {props.title}
            </Text>
          </View>
        </View>
        <View style={[styles.collapseBoxClose]}>
          <TouchableOpacity onPress={props.touchableOnPress}>
            <View style={[styles.collapseAllClose]}>
              <Text style={[styles.textPrime, styles.textOrange]}  allowFontScaling={false}>Hide</Text>
            </View>
          </TouchableOpacity>
          {/* <TouchableOpacity onPress={props.touchableOnPress}>
            <View style={[styles.collapseAllClose]}>
              <Text style={[styles.textPrime, styles.textOrange]}>Hide</Text>
            </View>
          </TouchableOpacity> */}
        </View>
      </View>
      {props.children}
    </View>
  );
};

export default (collapseWrapper);
