import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import StepTwoTreatment from './StepTwoTreatment';
import StepTwoActivity from './StepTwoActivity';
import {itemTypes} from '../../../Constant/index';

class StepTwoTreatmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treatmentArray: props.treatments ? props.treatments : [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {treatments = [], updateTreatments} = props;
    if (treatments && JSON.stringify(treatments) !== JSON.stringify(state.treatmentArray)) {
      updateTreatments(treatments);
      return {
        treatmentArray: treatments,
      };
    }

    return null;
  }

  handleOnDeleteTreatment = id => {
    //Delete treatment from server
    const {updateTreatments, routineWizardSaveDisable} = this.props;
    const tempData = this.props.treatments;
    if (tempData[id].id !== -1) {
      this.props.onDeleteTherapie(tempData[id].id, () => {});
    }

    tempData.splice(id, 1);
    this.setState({
      treatmentArray: tempData,
    });
    this.props.updateTreatment(tempData, () => {
      if (updateTreatments) {
        updateTreatments(tempData);
      }
      let invalidName = this.props.treatments.some(treatment => {
        return treatment.id == -1 && (treatment?.name?.trim() === '' || treatment?.duplicate)
      })
      routineWizardSaveDisable(invalidName, itemTypes.treatment);
    });
  };

  render() {
    return (
      this.state.treatmentArray &&
      this.state.treatmentArray.map((res, index) => {
        if (res.treatmentType === 5) {
          return (
            <StepTwoActivity
              key={index}
              treatment={res}
              index={index}
              handleOnDeleteTreatment={this.handleOnDeleteTreatment}
              clearErrors={this.props.clearErrors}
              error={this.state.error}
            />
          );
        } else {
          return (
            <StepTwoTreatment
              key={index}
              treatment={res}
              index={index}
              handleOnDeleteTreatment={this.handleOnDeleteTreatment}
            />
          );
        }
      })
    );
  }
}
const mapStateToProps = state => {
  return {
    treatments: state.setup.treatments,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateTreatment: (addNewTreatment, callBack) =>
      dispatch(actions.updateTreatment(addNewTreatment, callBack)),
    onDeleteTherapie: (id, callBack) =>
      dispatch(actions.deleteTherapie(id, callBack)),
    routineWizardSaveDisable: (flag, isFrom ) => 
      dispatch(actions.updateRoutineWizardSaveDisable(flag, isFrom))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepTwoTreatmentList);
