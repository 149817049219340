import { StyleSheet, Platform } from "react-native";
import * as defaultStyle from "../../style";

const STYLESHEET_ID = "stylesheet.calendar.header";

export default function(theme = {}) {
  const appStyle = { ...defaultStyle, ...theme };
  return StyleSheet.create({
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingLeft: 10,
      paddingRight: 10,
      alignItems: "center"
    },
    monthText: {
      fontSize: appStyle.textMonthFontSize,
      //   fontFamily: appStyle.textMonthFontFamily,
      fontWeight: appStyle.textMonthFontWeight,
      color: appStyle.monthTextColor,
      margin: 10,
      fontFamily: appStyle.fontFamily
    },
    arrow: {
      padding: 10
    },
    arrowImage: {
      ...Platform.select({
        ios: {
          tintColor: appStyle.arrowColor
        },
        android: {
          tintColor: appStyle.arrowColor
        }
      })
    },
    arrowImageIcon: {
      width: 5,
      height: 10
    },
    week: {
      marginTop: 7,
      flexDirection: "row",
      justifyContent: "space-around"
    },
    dayHeader: {
      marginTop: 2,
      marginBottom: 7,
      width: 32,
      textAlign: "center",
      fontWeight: "bold",
      fontSize: appStyle.textDayHeaderFontSize,
      //  fontFamily: appStyle.textDayHeaderFontFamily,
      color: appStyle.textLinkColor,
      fontFamily: appStyle.fontFamily
    },
    ...(theme[STYLESHEET_ID] || {})
  });
}
