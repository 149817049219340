import React, {useState, useEffect} from "react";

import ManageDeviceCommon from "./ManageDeviceCommon";
import {AUTH_CONFIGS, AUTH_CONFIGS_WEB} from '../../util/auth_config';
import {Linking, Platform} from "react-native";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import { WEB } from "../../util/platformWindowConfig";


const ManageDevices = (props) => {
  const {route, navigation} = props;
  const webQueryParams = {type: 1}; // type is used to identify the platform in the backend if type = 1 that is web and type is 2 means it is mobile.

  const [accessCode, setAccessCode] = useState(null);
  const [isHexoskinAuthorized, setIsHexoskinAuthorized] = useState(false);
  const [visibleDisconnectModal, setVisibleDisconnectModal] = useState(false);
  const [disconnectedDeviceName, setDisconnectedDeviceName] = useState(null);

  useEffect(() => {
    console.log("ManageDevices useEffect: params: ", route.params);
    let params = route.params;
    let accessCodeParam = params?.code;

    if (Boolean(accessCodeParam)) {
      setAccessCode(accessCodeParam);
      setIsHexoskinAuthorized(true);
    }
    console.log('accessCodeParam', accessCodeParam);
  });

  const onLoginNew = async () => {
    try {
      const auth0LoginUrl = `https://${AUTH_CONFIGS.domain}/authorize?audience=${AUTH_CONFIGS.audience}&scope=openid%20email%20profile%20offline_access&response_type=code&client_id=${AUTH_CONFIGS_WEB.clientId}&redirect_uri=${AUTH_CONFIGS.appOrigin}`;
      if (Platform.OS === WEB) {
        window.open(auth0LoginUrl, "_self");
      } else {
        await Linking.openURL(auth0LoginUrl);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onLogout = async () => {
    try {
      // Note - this will only work if they logout on the device that they created
      // the session on. If they logout on a different device. We will also need to
      // disconnect the device from the backend.
      const logoutUrl = `https://${AUTH_CONFIGS.domain}/v2/logout?client_id=${AUTH_CONFIGS_WEB.clientId}&returnTo=${AUTH_CONFIGS.appOrigin}`;
      if (Platform.OS === WEB) {
        window.open(logoutUrl, "_self");
      } else {
        await Linking.openURL(logoutUrl);
      }
      setAccessCode(null);
    } catch (e) {
      console.log('Log out cancelled');
    }
  };

  const changeDeviceWithAuth0Web = async (status, deviceName) => {
    if (status) {
      if (isHexoskinAuthorized) {
        onLogout();
        setDisconnectedDeviceName(deviceName);
        setVisibleDisconnectModal(true);
      } else {
        onLoginNew();
      }
    } else {
      setDisconnectedDeviceName(deviceName);
      setVisibleDisconnectModal(true);
    }
  }

  return (
    <>
      <ManageDeviceCommon
        changeDeviceWithAuth0={changeDeviceWithAuth0Web}
        route={route}
        navigation={navigation}
        accessCode={accessCode}
        disconnectedDeviceName={disconnectedDeviceName}
        setVisibleDisconnectModal={setVisibleDisconnectModal}
        visibleDisconnectModal={visibleDisconnectModal}
        doLogout={onLogout}
        isAuthenticated={isHexoskinAuthorized}
        clientId={AUTH_CONFIGS_WEB.clientId}
        type={webQueryParams.type}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    deviceMap: state.device.userDeviceMap || []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    connectHexoskinDeviceWithAccessCode: (deviceObj, callBack) => dispatch(actions.connectHexoskinDeviceWithAccessCode(deviceObj, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageDevices);
