import React from "react";
import { View, Text } from "react-native";
import styles from "../../../../Css";

const titleBlock = (props) => {
  return (
    <View style={[styles.menuSingleContBlock, styles.menuContMin]}>
      <View
        style={[
          styles.menuSingleTextViewSm,
          styles.colPdTopBotRg,
          styles.flexCom,
          styles.graphSmTitle,
          styles.graphSmTitleCr
        ]}
      >
        <Text
          style={[
            styles.textPrimeBold,
          ]}
          allowFontScaling={false}
          numberOfLines={2}
          nativeID={"textTruncate"}
        >
          {props.name ? props.name : "Defult Graph"}
        </Text>
      </View>
    </View>
  );
};

export default titleBlock;
