import React, { Component } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import Picker from "../../components/Picker/picker";

class Frequency extends Component {
  constructor(props) {
    super(props);
    this.frequency = [
      {
        label: "1 times",
        value: "1",
        key: "1times",
      },
      {
        label: "2 times",
        value: "2",
        key: "2times",
      },
      {
        label: "3 times",
        value: "3",
        key: "3times",
      },
      {
        label: "4 times",
        value: "4",
        key: "4times",
      },
      {
        label: "5 times",
        value: "5",
        key: "5times",
      },
      {
        label: "6 times",
        value: "6",
        key: "6times",
      },
      {
        label: "7 times",
        value: "7",
        key: "7times",
      },
      {
        label: "8 times",
        value: "8",
        key: "8times",
      },
      {
        label: "9 times",
        value: "9",
        key: "9times",
      },
      {
        label: "10 times",
        value: "10",
        key: "10times",
      },
      {
        label: "11 times",
        value: "11",
        key: "11times",
      },
      {
        label: "12 times",
        value: "12",
        key: "12times",
      },
    ];
    this.state = {
      times: props.times ? props.times : 1,
      periodic: props.periodic ? props.periodic : "DAILY",
    };
  }

  onChangeTime = (value) => {
    this.setState({ times: value }, () => {
      this.props.updateTime(value);
    });
  };
  onChangePeriodic = (value) => {
    this.setState({ periodic: value }, () => {
      this.props.updatePeriodic(value);
    });
  };
  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const { periodic } = this.state;
    return (
      <View style={[styles.fieldRow, styles.zIndexMin]}>
        <View style={styles.rowTitleView}>
          <Text style={[styles.textPrimeExSmBold, styles.textColorLight]} allowFontScaling={false}>
            Frequency
          </Text>
        </View>
        <View style={styles.fieldRowFlexRow}>
          <View style={[styles.fieldColFixed]}>
          <View style={[styles.fieldColCont, styles.mgRtExSm]}>
              <View style={[styles.pickerAreaWrapper]}>
                <View
                  style={[stylesRes.dropArrowView, stylesRes.dropArrowCent]}
                  pointerEvents={"none"}
                >
                  <View style={stylesRes.dropArrowIcon}>
                    <Image
                      style={[styles.imgContain]}
                      source={require("../../assets/icons/dropdown-arrow.png")}
                    />
                  </View>
                </View>
                <Picker
                  items={this.frequency}
                  style={[styles.pickerAreaField, styles.textPickerPdSmCom]}
                  selectedValue={
                    this.state.times ? this.state.times.toString() : ""
                  }
                  onValueChange={this.onChangeTime}
                  allowFontScaling={false}
                />

                {/* <Picker
                  items={this.units}
                  style={[styles.pickerAreaField, styles.selectionPicker]}
                  selectedValue={this.state.treatmentType.toString()}
                  onValueChange={(itemValue) => {
                    this.setState({
                      treatmentType: itemValue,
                    });
                  }}
                /> */}
              </View>
            </View>
          </View>
          <View style={styles.fieldColDynamic}>
          <View
              style={[styles.fieldColCont, styles.mgLtExSm, styles.flexCom]}
            >
              <View style={stylesRes.btnTabViewSmWrap}>
                <View style={styles.btnTabSingleDynamic}>
                  <TouchableOpacity
                    style={styles.btnTabTouch}
                    onPress={() => this.onChangePeriodic("DAILY")}
                  >
                    <View
                      style={[
                        styles.btnTabSmMenu,
                        styles.btnTabMenuCom,
                        styles.btnShadow,
                        periodic === "DAILY"
                          ? styles.btnTabMenuActive
                          : styles.btnTabMenuDefault,
                      ]}
                    >
                      <Text
                        style={[
                          styles.textPrimeSmBold,
                          styles.textAlignCenter,
                          periodic === "DAILY"
                            ? styles.btnTextHighlight
                            : styles.btnTextdefault,
                        ]}
                        allowFontScaling={false}
                      >
                        Daily
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
                <View style={styles.btnTabSingleSp}></View>
                <View style={styles.btnTabSingleDynamic}>
                  <TouchableOpacity
                    style={styles.btnTabTouch}
                    onPress={() => this.onChangePeriodic("WEEKLY")}
                  >
                    <View
                      style={[
                        styles.btnTabSmMenu,
                        styles.btnTabMenuCom,
                        styles.btnShadow,
                        periodic === "WEEKLY"
                          ? styles.btnTabMenuActive
                          : styles.btnTabMenuDefault,
                      ]}
                    >
                      <Text
                        style={[
                          styles.textPrimeSmBold,
                          styles.textAlignCenter,
                          periodic === "WEEKLY"
                            ? styles.btnTextHighlight
                            : styles.btnTextdefault,
                        ]}
                        allowFontScaling={false}
                      >
                        Weekly
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>

                <View style={styles.btnTabSingleSp}></View>

                <View style={styles.btnTabSingleDynamic}>
                  <TouchableOpacity
                    style={styles.btnTabTouch}
                    onPress={() => this.onChangePeriodic("AS_NEEDED")}
                  >
                    <View
                      style={[
                        styles.btnTabSmMenu,
                        styles.btnTabMenuCom,
                        styles.btnShadow,
                        periodic === "AS_NEEDED"
                          ? styles.btnTabMenuActive
                          : styles.btnTabMenuDefault,
                      ]}
                    >
                      <Text
                        style={[
                          styles.textPrimeSmBold,
                          styles.textAlignCenter,
                          periodic === "AS_NEEDED"
                            ? styles.btnTextHighlight
                            : styles.btnTextdefault,
                        ]}
                        allowFontScaling={false}
                      >
                        As Needed
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default (Frequency);
