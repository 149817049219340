import React from 'react';
import {View} from 'react-native';
import styles from '../../../../Css';
import ArrowPrevButton from '../../../Button/arrowPrevButton';
import PrimaryButton from '../../../Button/primaryButton';
const profileTabFooter = props => {
  const {
    back,
    onSaveAndClose,
    saveWait,
    windowWidth,
    inActive,
    isEmptyRoutine,
  } = props;

  return (
    <View style={[styles.modalFixedSection, styles.zIndexMin]}>
      <View style={[styles.btnViewBorder]} />
      <View style={[styles.btnFixedArea]}>
        <View style={styles.btnFooterRow}>
          <View style={styles.btnSubSection}>
            <ArrowPrevButton
              btnText={windowWidth > 374 ? 'Save & Back' : 'Save \n& Back'}
              touchableOnPress={back}
              prevWait={saveWait}
            />
          </View>

          <View style={styles.btnSubSectionFixed}>
            <View style={styles.fieldBtn}>
              <PrimaryButton
                btnText={'Create Routine'}
                btnPosition={'full'}
                indicatorLtDefaultSm={styles.indicatorLtDefaultSm}
                disabled={saveWait || isEmptyRoutine}
                activityInd={saveWait}
                touchableOnPress={onSaveAndClose}
                activityIndStyle={styles.indLoaderSm}
                inActive={inActive}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default (profileTabFooter);
