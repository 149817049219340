import React, { Component } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  TouchableWithoutFeedback,
  TextInput,
} from 'react-native';
import {findThumbnail} from '../../util/commonUiLogic';

import styles from '../../Css';

class FileRename extends Component {
  state = {
    fileNameInputValue: '',
    focusedIndex: -1,
  };

  handleListItemPress = (res, index) => {
    const { files, disabled } = this.props;

    if (!disabled) {
      let fileName = files[res].name || '';
      fileName = fileName.substr(0, fileName.lastIndexOf('.'));
      this.setState({ focusedIndex: index, fileNameInputValue: fileName });
    }
  }

  handleTextInputBlur = (res, resIndex) => {
    const { files, onFileRename } = this.props;
    const { fileNameInputValue } = this.state;

    const fileName = files[res].name;
    const fileExtension = fileName.substr(fileName.lastIndexOf('.')+1, fileName.length);
    let newFileName = `${fileNameInputValue}.${fileExtension}`;

    this.setState({ focusedIndex: -1, fileNameInputValue: '' });
    onFileRename(res, newFileName);
  };

  render() {
    const { files, handleDeleteAttachment, disabled } = this.props;
    const { focusedIndex, fileNameInputValue } = this.state;

    return (
      <View style={[styles.detailViewBlock, styles.secSpPdTopSm]}>
      {Object.keys(files) &&
        Object.keys(files).map((res, resIndex) => {
          // const fileExtension = files[res].name ? files[res].name.split('.').pop() : null;
          return (
        <View
          style={[styles.attachmentRow, styles.greyBorderThin]}
          key={'image_id_' + res}
        >
          <View
            style={[
              styles.attachmentDetSec,
              styles.sortingPdSm,
              styles.smPdLtRt,
            ]}>
            <View
              style={[
                styles.attachmentDet,
                styles.attachmentDetMax,
                styles.attachmentThumbTextWrapper,

              ]}>
              <View
                style={[
                  styles.attachmentImgDet,
                  styles.attachmentImgDetMax,
                  styles.uploadAttachmentImgDetMax,
                ]}>
                <Image 
                  style={styles.attachmentPreviewIcon}
                  source={findThumbnail(files[res].name)}/>
              </View>
              <View
                style={[
                  styles.attachmentCont,
                  styles.attachmentContMax,
                ]}>
                {focusedIndex !== resIndex &&
                <TouchableWithoutFeedback onPress={() => this.handleListItemPress(res, resIndex)}>
                  <Text style={styles.attachmentCont} allowFontScaling={false}>
                    {files[res].name}
                  </Text>
                </TouchableWithoutFeedback>}

                {focusedIndex === resIndex &&
                  <TextInput
                    disabled={disabled}
                    style={[styles.editableBoder]}
                    value={fileNameInputValue}
                    onBlur={() => this.handleTextInputBlur(res, resIndex)}
                    allowFontScaling={false}
                    onChangeText={(text) => this.setState({ fileNameInputValue: text })} />}
              </View>
            </View>
            <View
              style={[
                styles.attachmentBtn,
                styles.attachmentBtnMax,
                styles.uploadAttachmentBtnMax
              ]}>
              <TouchableOpacity
                disabled={disabled}
                onPress={() =>
                  handleDeleteAttachment(res)
                }>
                <View style={styles.attachmentIcon}>
                  <Image
                    style={styles.uploadAttachmentcrossIcon}
                    source={require('../../assets/icons/close-icon-small.png')}
                  />
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )})}
      </View>
    )
  }
};

export default FileRename;
