import React, { useState, useEffect } from 'react';
import {
    View,
    Image,
    TouchableOpacity,
} from 'react-native';
import styles from '../../Css';
import * as constants from './constants';
import theme from '../../css/base/theme';
import { nullableStringCompare } from '../../util/stringManipulations';
import { MainBubble } from 'zamplo-common-js';

const AddEventTags = props => {
    const {
        windowWidth,
        updateTagArray,
        tagsArray: tagsArrayProps,
        activeBubbleId,
        setActiveBubble,
        fetchHealthJourneyTagsList,
        setIsFocused,
        hideIcon = false,
        customStyle,
        customWidth,
        healthLibrary = false,
    } = props;
    const [showTagSection, setShowTagSection] = useState(hideIcon || tagsArrayProps.length > 0);
    const [tagList, setTagList] = useState([]);

    useEffect(() => {
      if (!showTagSection && tagsArrayProps.length > 0)
        setShowTagSection(true);
        //eslint-disable-next-line
    }, [tagsArrayProps])

    useEffect(() => {
        fetchHealthJourneyTagsList(
            'tags',
            'HLHJ',
            (isFetched, data) => {
                if (isFetched) {
                    if (data?.healthJourney || data?.healthLibrary) {
                        const healthJourneyTags = data?.healthJourney ? data?.healthJourney : [];
                        const healthLibraryTags = data?.healthLibrary ? data?.healthLibrary : [];
                        const res = healthJourneyTags.concat(healthLibraryTags).sort((x, y) => nullableStringCompare(x, y));;
                        setTagList(res);
                    } else {
                        setTagList([]);
                    }
                } else {
                    setTagList([]);
                }
            },
        );
        //eslint-disable-next-line
    }, []);

    return !showTagSection &&
        windowWidth >= constants.WINDOW_MOBILE_WIDTH_MARGIN ? (
        <View
            style={[
                styles.flexCom,
                styles.flexJcFe,
                styles.flexAiFe,
                styles.postAb,
                styles.rgZero,
                styles.btZero,
            ]}>
            <TouchableOpacity
                onPress={() => {
                    activeBubbleId && setActiveBubble(null);
                    setShowTagSection(true);
                }}>
                <View>
                    <Image
                        style={styles.tagIcnNwEvnt}
                            source={require('../../assets/health-journey-icons/add-tag-icon.png')}
                        resizeMode={constants.IMAGE_RESIZE_MODE}
                    />
                </View>
            </TouchableOpacity>
        </View>
    ) : (
      <View style={{marginTop: !healthLibrary ? 30 : 0}}> 
        <MainBubble 
          suggestions={tagList} 
          onChangeBubbles={e => updateTagArray?.(e.map(t => ({tags: t})))} 
          mainTitle={!healthLibrary ? "Tags:" : undefined} 
          wrapperStyles={[{width: customWidth || 300}, styles.tagWrapperStyles, styles.addTagAutoSugesstionBorderGrey, customStyle]} 
          preSelectedValues={tagsArrayProps?.map(t => t.tags) || []}
          dropdownHeader="Hit enter or comma to create a new tag."
          showIcon={!healthLibrary}
          wrapperFocusBorderColor={!healthLibrary ? theme.COLOR_BLUE_16 : undefined}
          separator={constants.COMMA}
          bubbleLimit={!healthLibrary ? 10 : undefined}
          onTextFocus={setIsFocused}
          inputPlaceholder={tagsArrayProps.length === 0 ? constants.SEARCH_OR_CREATE_NEW_TAGS : ''}
          bubbleStyles={styles.tagBubbleStyles}
          placeholderTextColor={!healthLibrary ? theme.COLOR_GREY_4 : undefined}
          inputStyles={styles.tagInputStyles}
        />
      </View>
    );
};

export default AddEventTags;
