import {StyleSheet} from 'react-native';

export default StyleSheet.create({

  questionImageWrapper: {
    marginTop: 30,
    marginBottom: 30,
    height: 164
  },

  questionSliderWrapper: {
    marginTop: 20
  }

});