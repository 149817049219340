import React from 'react';
import { View, Text, Image, TouchableOpacity } from "react-native";
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import stylesCss from './QuestionTitleStyle';


const QuestionSkip = ({onSkip}) => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    return (
    <View style={stylesRes.titleBtnWrapper}>
      <TouchableOpacity style={[stylesCss.titleBtnTouchWrapper]} onPress={() => onSkip()}>
        <Text style={[styles.textPrimeBold, styles.textOrange]} allowFontScaling={false}>SKIP</Text>
        <View style={[styles.iconViewExSmLt, stylesCss.iconMgTop]}>
          <Image
            style={styles.nextArrowThick}
            source={require("../../assets/icons/next-arrow-thick.png")}
          />
        </View>
      </TouchableOpacity>
    </View>
  );
};
export default (QuestionSkip);



