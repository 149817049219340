import React, { Component } from "react";
import { View } from "react-native";
import styles from "../../Css";
import ModalLayout from "../../components/ModalLayout/modalLayout";
import ModalVideo from "../../components/ModalLayout/modalVideo";
import PrimaryButton from "../../components/Button/primaryButton";
import YoutubeData from "./youtubeData";
import { connect } from "react-redux";
import { toggleWatchOverview } from "../../store/actions/uistate";
import { pendoTrackEvent } from "../../util/pendoConfig";

class OverviewModal extends Component {

  hidePopup = () => {
    this.props.toggleWatchOverviewVideo(false);
    pendoTrackEvent('WatchOverview > Exit');
  };

  render() {
    const { visibleWatchOverview, letsGetStartedOnPress, windowWidth} = this.props;
    // let youtubeW_H = 315/560;
    let videoBoxHeight = 152;
    let videoBoxWidth = 270;
    if(windowWidth > 767) {
      videoBoxHeight = 270;
      videoBoxWidth = 480;
    }
    return (
      <ModalVideo
        visibleWatchOverview={visibleWatchOverview}
        onBackdropPress={this.hidePopup}
        backdropStyle={true}
      >
        <ModalLayout
          contPosition={"center"}
          showClose={true}
          hidePopup={this.hidePopup}
          modalVideo={true}
          nativeId={'watchOverviewExit'}
        >
          <View style={styles.popupContentWrapper}>
            <View style={[styles.popMessageContView, styles.secBgPdTop]}>
              <YoutubeData windowWidth={windowWidth} videoBoxHeight={videoBoxHeight} videoBoxWidth={videoBoxWidth}/>
            </View>
            <View style={[styles.popBtnView, styles.comMgTopRg]}>
              <View style={[styles.btnArea]}>
                <View style={styles.fieldBtn}>
                  <PrimaryButton
                    btnText={"Lets get started"}
                    btnPosition={"center"}
                    btnStyle={styles.primaryButtonMax}
                    touchableOnPress={() => {
                      letsGetStartedOnPress();
                      pendoTrackEvent('WatchOverview > GetStarted');
                    }}
                    nativeId={'watchOverviewGetStarted'}
                  />
                </View>
              </View>
            </View>
          </View>
        </ModalLayout>
      </ModalVideo>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    toggleWatchOverviewVideo: (val) => dispatch(toggleWatchOverview(val))
  }
};

export default connect(null, mapDispatchToProps)(OverviewModal);
