import React, {Component} from 'react';
import {Text, View} from 'react-native';
import {connect} from 'react-redux';
import * as FieldDetails from '../../TextInput/fieldDetails';
import ItemDeleteButton from '../../Button/itemDeleteButton';
import InputField from '../../TextInput/inputField';
import TextPickerFrame from '../../TextInput/textPickerFrame';
import styles from '../../../Css';
import {decimalRegex} from '../../../Constant';
class HeathDataItem extends Component {
  constructor(props) {
    super(props);
    const {
      helathData,
      userDefinedMetricsProcessed,
      unitsOfMeasuresProcessed,
      index,
      changeHelathDataValue
    } = props;
    const {metric, unit} = helathData;
    const {name} = userDefinedMetricsProcessed[metric];
    const {symbol, options} = unitsOfMeasuresProcessed[unit];
    let value = options && options.length > 0 ? options[0]?.id : null;
    this.state = {
      ...helathData,
      name: name,
      symbol: symbol,
      value: value,
      options: options,
    };
    changeHelathDataValue({...helathData,  value: value, uomOption: options ? value : null}, index);
  }

  //   static getDerivedStateFromProps(props, state) {
  //     return null;
  //   }


  onSetValue = (val) =>{
    const {value} = this.state;
    const {changeHelathDataValue, helathData, index} = this.props;

    if (!isNaN(val) && val.match(decimalRegex)) {
      this.setState({value: val}, () =>
        changeHelathDataValue({...helathData, value: val ? val : null}, index),
      );
    } else {
      this.setState({value: value}, () =>
        changeHelathDataValue({...helathData, value: parseFloat(value)}, index),
      );
    }
  }

  onValueChange = val => {
    const {symbol} = this.state;

    if(symbol === '10pt'){
      if(val < 11){
        this.onSetValue(val)
      }else{
        return
      }
    }else if (symbol === '5pt'){
      if(val < 6){
        this.onSetValue(val)
      }else{
        return
      }
    } else {
      this.onSetValue(val)
    }
  };

  onChangeHealthDataValues = (val) =>{
    const {changeHelathDataValue, helathData, index} = this.props;
    this.setState({value: val}, () =>
      changeHelathDataValue({...helathData,  value: val, uomOption: val}, index),
    );
  }

  getItems = () => {
    const { options } = this.state;
    const items = [];
    options.forEach(element => {
      items.push({
        label: element.name,
        value: element.id,
        key: element.id,
      })
    });

    return items;
  }

  render() {
    const { name, symbol, id, value, options } = this.state;
    const { deleteHelathData } = this.props;

    return (
      <View style={[styles.fieldRowWrapper]}>
        <View style={styles.fieldColDetails}>
          <View style={[styles.contSm]}>
            <Text style={[styles.textPrime]} allowFontScaling={false}>{name}</Text>
          </View>
        </View>
        <View style={styles.fieldColSp} />
        <View style={styles.fieldColStretch}>
          {options && options.length > 0 ?
          (
            <TextPickerFrame
              title={'Units: ' + symbol}
              fieldBg={FieldDetails.BGWHITE}
              items={this.getItems()}
              selectedValue={value}
              onValueChange={this.onChangeHealthDataValues}
            />
          ) :
          (
            <InputField
              title={'Units: ' + symbol}
              fieldBg={FieldDetails.BGWHITE}
              fieldLt={true}
              onChangeText={this.onValueChange}
              value={value}
              keyboardType={"numeric"}
            />
          )
        }
        </View>
        <View style={styles.fieldColSp} />
        <View style={styles.fieldColDelete}>
          <ItemDeleteButton touchableOnPress={() => deleteHelathData(id)} />
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    unitsOfMeasuresProcessed: state.routines.unitsOfMeasureDigestProcessed
      ? state.routines.unitsOfMeasureDigestProcessed.unitsOfMeasure
      : [],
    userDefinedMetricsProcessed: state.routines.metricsDigestProcessed
      ? state.routines.metricsDigestProcessed.userDefinedMetrics
      : [],
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeathDataItem);
