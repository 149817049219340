import React, { Component } from "react";
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  Keyboard,
} from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import UIText from "../../components/Text/text";
import * as validator from "../../validator/validator";
import * as actions from "../../store/actions/index";
import Warning from "../../components/Warning/warning";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import { connect } from "react-redux";
import EmailFieldMultiple from "../../components/StaticCommon/emailFieldMultiple";
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import Header from '../../components/Header/header';
import {PEER_TO_PEER, REFER_FRIEND} from '../../navigation/path'
import PageContainer from '../../components/PageLayout/PageContainer';
import { ORGANIZATION_NAME } from '../../util/commonUiConfig';
import SimpleOkBox from "../../components/ConfirmationBox/simpleOkBox";
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import MenuOverlayContainer from "../../components/PageLayout/MenuOverlayContainer";
import SavingConfirmation from "../../components/ConfirmationBox/savingConfirmation";
import { pendoTrackEvent } from "../../util/pendoConfig";

const EMAIL_CONTENT = `Manage your health journey with ${ORGANIZATION_NAME}. You can journal your health data, track your medications, upload your health documents and much more, all in a single app. Join the ${ORGANIZATION_NAME} movement. Signup for free.`;

class ReferFriendScreen extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      emailAddresses: [],
      email: "",
      note: '',
      confirmBox: false,
      wait: false,
      error: false,
      errorDescription: "",
      showLearnHowPrivacyPolicy: false,
      loggedUserEmail: this.props.loggedUser.email,
      message: "",
      confirmBoxUnsaved: false,
    };
    // this.props.hideMobileMenu();
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
      pendoTrackEvent('ReferFriend@');
    })
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    })
  }

  componentWillUnmount = () => {
    this.props.showMobileMenu();
  };

  onChangeEmailText = (value) => {
    if (value.trim().indexOf(" ") >= 0) {
      this.setState({
        email: value,
        error: true,
        errorDescription: "Please enter valid email address.",
      });
    } else {
      this.setState({
        email: value,
        error: false,
        errorDescription: "",
      });
    }
  };

  onBlur = (e) => {
    this.addEmailToList();
  };

  addEmailToList = () => {
    if (this.state.email.trim() === "") {
      setTimeout(() => {
        this.setState({
          error: false,
          errorDescription: "",
        });
      }, 500);
    } else if (validator.validateEmail(this.state.email.trim())) {
      if (this.checkEmailAreadyInList(this.state.email.trim())) {
        setTimeout(() => {
          this.setState(
            {
              email: "",
              error: true,
              errorDescription: "Email address already exist.",
            },
            () => {
              if (this.textInput) {
                this.textInput.focus();
              }
            }
          );
        }, 500);
      } else {
        const emailAddresses = [...this.state.emailAddresses];
        emailAddresses.push({ email: this.state.email.trim() });
        setTimeout(() => {
          this.setState(
            {
              email: "",
              emailAddresses: emailAddresses,
              error: false,
              errorDescription: "",
            },
            () => {
              if (this.textInput) {
                this.textInput.focus();
              }
              Keyboard.dismiss();
            }
          );
        }, 100);
      }
    } else {
      setTimeout(() => {
        this.setState({
          error: true,
          errorDescription: "Please enter valid email address.",
        });
      }, 500);
    }
  };

  checkEmailAreadyInList = (email) => {
    const { emailAddresses } = this.state;
    const found = emailAddresses.find(
      (res) => res.email.toLowerCase() === email.toLowerCase()
    );
    if (found) {
      return true;
    }
    return false;
  };

  onChangeNoteText = (value) => {
    this.setState({ note: value });
  };

  remove = (email) => {
    const emailAddresses = [...this.state.emailAddresses];
    this.setState(
      {
        emailAddresses: emailAddresses.filter((res) => res.email !== email),
      },
      () => {
        if (this.textInput) {
          this.textInput.focus();
        }
      }
    );
  };

  referFriendInvitation = () => {
    if (this.state.email && this.state.email.trim() !== "") {
      this.addEmailToList();
    } else if (this.state.emailAddresses.length > 0) {
      this.setState({ wait: true });
      this.props.referFriendInvitation(
        this.state,
        (success, res) => {
          this.setState({ wait: false });
          if ( success ) {
            this.setState({
              message: res.message,
              emailAddresses: [],
              note: ''
            })
          } else {
            this.setState({
              message: res.message,
              note: ''
            })
          }
        },
        this.props.journalUserId
      );
    } else {
      setTimeout(() => {
        this.setState(
          {
            email: "",
            error: true,
            errorDescription: "Please enter Email address.",
          },
          () => {
            if (this.textInput) {
              this.textInput.focus();
            }
          }
        );
      }, 500);
    }
  };

  cancel = () => {
    if (this.isHaveChanges()) {
      this.setState({confirmBoxUnsaved: true});
    } else {
      handleCommonBackNavigation(this.props.navigation);
    }
  };

  onKeyPress = e => {
    if (e.nativeEvent.key === 'Enter' || e.nativeEvent.key === ' ') {
      this.addEmailToList();
    }
    if (e.nativeEvent.key === 'Delete' || e.nativeEvent.key === 'Backspace') {
      if (this.state.email === '' && this.state.emailAddresses.length > 0) {
        this.state.emailAddresses.forEach(item =>{
          this.remove(item.email)
        })
      }
    }
  }

  isHaveChanges = () => {
    const {email, note, emailAddresses} = this.state;
    if (email || note || emailAddresses.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  handleBackNavigation = () => {
    const {navigation, route} = this.props;
    if (this.isHaveChanges()) {
      this.setState({confirmBoxUnsaved: true});
    } else {
      if (
        route?.params &&
        route?.params.from &&
        route?.params.from === PEER_TO_PEER.searchResultsPath
      ) {
        navigation.goBack();
      } else {
        handleCommonBackNavigation(navigation);
      }
    }
  }

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {navigation, route} = this.props;
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          hideCaregiverAccess={true}
          showNotify={true}
          handleOnPressBackButton={() => this.handleBackNavigation()}
          index={REFER_FRIEND.index}
          navigation={navigation}
          route={route}
        />
        <SpaceBar />
        {this.isHaveChanges() ? (
          <MenuOverlayContainer onPressOpen={() => this.setState({confirmBoxUnsaved: true})} />
        ) : null}
        <ScrollView
          style={styles.scrollViewArea}
          keyboardShouldPersistTaps={"handled"}
        >
          <View style={styles.gridSection}>
            <PageContainer>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.secArea}>
                    <View style={[styles.colPdTopLtRt]}>
                      <View style={[styles.comTextSec, styles.comSpLg, styles.contPdTop]}>
                        <View style={[styles.titlePdBotXSm]}>
                          <Text
                            style={[styles.textTitleMdBold, styles.textThickGrey]}
                            allowFontScaling={false}
                          >
                            Refer A Friend
                          </Text>
                        </View>
                        <Text style={[styles.textPrime, styles.textColorLight]} allowFontScaling={false}>
                          Refer a friend to join {ORGANIZATION_NAME}! Send a personalized
                          message and they will receive a link to sign up.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.secArea}>
                    <View style={[styles.colPdLtRt]}>
                      <View style={[styles.secSubArea]}>
                        <EmailFieldMultiple
                          textInput={this.textInput}
                          titleEmail="Email:"
                          emailAddresses={this.state.emailAddresses}
                          removeEmail={this.remove}
                          onChangeTextEmail={this.onChangeEmailText}
                          email={this.state.email}
                          onBlur={this.onBlur}
                          onChangeNoteText={this.onChangeNoteText}
                          noteValue={this.state.note}
                          palceholderNote={EMAIL_CONTENT}
                          onKeyPress={this.onKeyPress}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={[styles.gridColFull, styles.errBoxView]}>
                  {Boolean(this.state.error) && (
                    <Warning errorDescription={this.state.errorDescription} />
                  )}
                </View>
              </View>


              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.btnArea}>
                    <View style={stylesRes.btnViewSec}>
                      <View style={styles.fieldBtn}>
                        <View style={styles.btnBlockFull}>
                          <TouchableOpacity
                            style={[styles.submitExSm, styles.bgWhite, styles.borderBtn]}
                            underlayColor="rgba(0, 153, 168, 0.8)"
                            onPress={this.cancel}
                          >
                            <View style={styles.btnSingleView}>
                              <View style={[styles.btnSingle, styles.btnSingleCent]}>
                                <UIText style={[styles.textPrimeLg, styles.whiteBtnText]} title="Cancel"  />
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View style={styles.fieldBtnSp} />
                      <View style={styles.fieldBtn}>
                        <View style={styles.btnBlockFull}>
                          <TouchableOpacity
                            style={[styles.submitExSm, styles.borderBtnTransparent]}
                            underlayColor="rgba(0, 153, 168, 0.8)"
                            onPress={this.referFriendInvitation}
                            disabled={this.state.wait}
                          >
                            <View style={styles.btnSingleView}>
                              <View style={[styles.btnSingle, styles.btnSingleCent]}>
                               {this.state.wait && (
                                  <View
                                    style={[styles.actIndCom, styles.indLeftSm]}
                                  >
                                    <ActivityIndicator size="small" pdZero={true} />
                                  </View>
                                )}
                                <UIText style={[styles.textPrimeLg, styles.textWhite]} title="Refer a friend"  />
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </PageContainer>
          </View>
        </ScrollView>

        <SimpleOkBox
          visibleModal={this.state.confirmBox}
          alertTitle={'Refer friend'}
          alertBody={this.state.message}
          onRequestClose={() => this.setState({confirmBox: false})}
          onBackdropPress={() => this.setState({confirmBox: false})}
          handleCancel={() => this.setState({confirmBox: false})}
          handleConfirm={() => this.setState({confirmBox: false})}
          alertBodyBoldText={true}
        />
        <SavingConfirmation
          visibleModal={this.state.confirmBoxUnsaved}
          alertTitle={"Continue Refer a friend before closing?"}
          alertBody={"Any unsaved changes will be lost."}
          handleCancel={() => {
            this.setState({confirmBoxUnsaved: false}, () => {
              handleCommonBackNavigation(navigation);
            });
          }}
          handleConfirm={() => {
            this.setState({confirmBoxUnsaved: false}, () => {
              this.referFriendInvitation();
            });
          }}
          confirmWait={this.state.wait}
          disabled={this.state.wait}
          onBackdropPress={() => this.setState({confirmBoxUnsaved: false})}
          btnTextOne={"Cancel"}
          btnTextTwo={"Refer a friend"}
        />

      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    journalUserId: state.uistate.journalUserId,
    loggedUser: state.routines.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    referFriendInvitation: (data, callBack, journalUserId) =>
      dispatch(actions.referFriendInvitation(data, callBack, journalUserId)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferFriendScreen);
