import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from '../../../../Css';
import { connect } from 'react-redux';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextInputPlusMinus from '../../../TextInput/TextInputPlusMinus';
import TextFrame from '../../../TextInput/textFrame';
import TextFieldToolTip from "./../../../../components/TextInput/textFieldToolTip";
import MedticationTooltipContent from '../../../TooltipContent/Medtication';
import AcceptButton from '../../../Button/acceptButton';
import ItemDeleteButton from '../../../Button/itemDeleteButton';

class UserItem extends Component {
  constructor(props) {
    super(props);
    const { updateMedication, index, data, treatmentsProcessed, isNew, type, } = this.props;
    const {
      quantity,
      treatment,
      accept,
      treatmentObj: { publishedBy } = {},
      units,
    } = data;
    const isRandNew = (isNew && type === 'R') || (type === 'R' && data.new);
    const treatmentQuantity = this.getGoalAndUnit(treatment, treatmentsProcessed).treatmentQuantity;
    const treatmentUnit = this.getGoalAndUnit(treatment, treatmentsProcessed).treatmentUnit;
    const itemQuantity = quantity;
    this.state = {
      quantity: data.updated || !isRandNew || (!data.new && isRandNew) ? itemQuantity : null,
      units: units ? units : treatmentUnit ? treatmentUnit : 'Tablets',
      publishedBy: publishedBy,
      goalNumber: treatmentQuantity,
      goalUnit: treatmentUnit,
      hasAutofillDisabled: this.quantity === treatmentQuantity,
    };

    updateMedication(index, {
      ...data,
      quantity: isRandNew && data.updated ? itemQuantity : isRandNew && itemQuantity === null ? null : itemQuantity,
    });
  }

  componentDidMount = () => {
    this.validateAutoFillOption();
  }

  componentDidUpdate(prevProps, prevState) {

    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      const treatmentUnit = this.getGoalAndUnit(this.props.data.treatment, this.props.treatmentsProcessed).treatmentUnit;
    
      this.setState({
        quantity: this.props.data.quantity,
        units: this.props.data.units ? this.props.data.units : treatmentUnit ? treatmentUnit : 'Tablets',
        goalNumber: this.getGoalAndUnit(this.props.data.treatment, this.props.treatmentsProcessed).treatmentQuantity,
        goalUnit: treatmentUnit,
        publishedBy: this.props?.data?.treatmentObj?.publishedBy,
      }, () => {
        const hasAutofillDisabled = this.compareGoalAndQuantity(this.state.goalNumber, this.state.quantity);
        this.setState({ hasAutofillDisabled });
      });
    }

    if (!isNaN(this.state.quantity) && prevState.quantity !== this.state.quantity) {
      const hasAutofillDisabled = this.compareGoalAndQuantity(this.state.goalNumber, this.state.quantity);
      this.setState({ hasAutofillDisabled });
    }

  }

  getGoalAndUnit = (treatment, treatmentsProcessed) => {
    let treatmentQuantity = null;
    let treatmentUnit = null;

    if (treatment && treatmentsProcessed && treatmentsProcessed[treatment]) {
      const dosages = treatmentsProcessed[treatment].dosages;
      treatmentQuantity = dosages && dosages.length > 0 && (dosages[0].quantity || dosages[0].quantity === 0)
        ? dosages[0].quantity
        : null;
      treatmentUnit =
        dosages && dosages.length > 0 && dosages[0].units
          ? dosages[0].units
          : null;
    }

    return { treatmentQuantity, treatmentUnit }

  }

  static getDerivedStateFromProps(props, state) {
    const { data } = props;

    if (data) {

      return {
        quantity: data.quantity,
        accept:
          (data.quantity || data.quantity === 0 || data.type === 'USER') &&
            data.accept
            ? true
            : false,
      };
    }

    return null;
  }

  compareGoalAndQuantity = (goal, qty) => {

    if (typeof goal === typeof qty && goal === qty) return true;

    const goal_new = goal !== null && typeof goal === 'string' ? parseInt(goal) : goal;
    const val_new = qty !== null && typeof qty === 'string' ? parseInt(qty) : qty;

    if (goal_new === val_new) return true;

    return false;

  }

  onChange = val => {
    const { updateMedication, index, data } = this.props;
    this.setState(
      { quantity: val }, //&& val !== '0' remove for fixed CR-1538
      () => {
        updateMedication(index, {
          ...data,
          updated: true,
          quantity: val,
        });
        this.validateAutoFillOption();
      }
    );

  };

  onAutoFill = () => {
    const { updateMedication, index, data } = this.props;
    this.setState(
      { quantity: this.state.goalNumber },
      () => {
        updateMedication(index, {
          ...data,
          updated: true,
          quantity: this.state.goalNumber,
        })
        this.validateAutoFillOption()
      }
    );

  }

  validateAutoFillOption = () => {
    setTimeout(() => {
      if (this.compareGoalAndQuantity(this.state.goalNumber, this.state.quantity)) {
        this.setState({ hasAutofillDisabled: true });
      } else {
        this.setState({ hasAutofillDisabled: false });
      }
    }, 200)
  }

  onAccept = () => {
    const { accept, quantity } = this.state;
    if (quantity || quantity === 0) {
      //&& quantity  !== '0' remove for fixed CR-1538
      const { updateMedication, index, data } = this.props;
      this.setState({ accept: !accept }, () =>
        updateMedication(index, {
          ...data,
          accept: !accept,
        }),
      );
    }
  };

  render() {
    const {
      data,
      treatmentsProcessed,
      deleteItem,
      index,
      handleRedirectMedications,
      windowWidth
    } = this.props;
    const { treatment } = data;
    const { name, identificationNumber, treatmentType } = treatmentsProcessed[
      treatment
    ];
    const {
      quantity,
      units,
      publishedBy,
      goalNumber,
      goalUnit,
      hasAutofillDisabled,
      accept,
    } = this.state;
    let category = this.props.type;

    return (
      <>
        <View style={category !== 'RT' ? [styles.ActivitySliderCardView, styles.bgDarkAsh] : [styles.fieldContainer]}>
          {category !== 'RT' ?
            <View>
              <View style={[styles.fieldRowWrapperSm, styles.borderTpLtRadius, styles.borderTpRtRadius]}>
                {/* {publishedBy !== 'PARTNER' && (  ZI-4049 - issue fixed by this comment */}
                <View style={[styles.fieldColAutoFill, styles.flexJcFe, styles.flexAiCt]}>
                  {category !== 'RT' && goalNumber !== null &&
                    <View style={[styles.fillCont]}>
                    <Text style={[styles.textPrimeSm, styles.textColorGreyLight, styles.pdRtFour, styles.pdTpTwelve, styles.autoCompleteTextInput, styles.pdBtTwo]}>
                        {`${goalNumber}`} {`${goalUnit || 'Tablets'}`}
                      </Text>
                        <TouchableOpacity disabled={hasAutofillDisabled} onPress={this.onAutoFill}>
                        <View style={[styles.fillContAuto, styles.pdTpTwelve, styles.pdBtTwo, styles.contListItemIconBlock]}>
                          <Image
                            style={[
                              styles.autofillIconMed
                            ]}
                            source={require('../../../../assets/icons/autofill.png')}
                          />
                          <Text style={[styles.textPrimeSmBlue, styles.textUnderline, hasAutofillDisabled && { opacity: 0.4 }]}>{`${'Autofill'}`}</Text>
                        </View>
                        </TouchableOpacity>
                    </View>
                  }
                </View>
                {/* )} */}
              </View>
            </View>
            :
            null}

          <View style={category !== 'RT' ? [styles.iconLtSp, styles.barBotSp] : [styles.fieldRowWrapper]}>
            {category !== 'RT' ? <View style={[styles.flexRowAiCt]}>
              <Text style={[styles.textPrimeBold, styles.textBlack, styles.rightSpExLg, { alignItems: 'baseline' },
                // publishedBy === 'PARTNER' && styles.mgTpTwelve --- ZI-4049 - issue fixed by this comment
              ]}>
                {`${name}`}
              </Text>
              <View style={[{ width: 2, height: 2 }]}>
                <TextFieldToolTip isBlueIcon={true} renderComponent={() => (
                  <MedticationTooltipContent
                    identificationNumber={identificationNumber}
                    treatmentType={treatmentType}
                    treatment={treatmentsProcessed[treatment]}
                    handleRedirectMedications={handleRedirectMedications}
                    windowWidth={this.props.windowWidth}
                    showLink={true}
                  />
                )}
                />
              </View>
            </View>
              :
              <View style={styles.fieldColIcon}>
                <AcceptButton
                  btnDark={accept}
                  touchableOnPress={this.onAccept}
                  btnCircle={true}
                />
              </View>
            }
            {category === 'RT' && <View style={styles.fieldColSp} />}
            <View style={category !== 'RT' ? [styles.flexRow, styles.mgTopLg, styles.rightSpSm] : styles.fieldColPlusMinus}>
              <View>
                <TextInputPlusMinus
                  title={'Quantity'}
                  fieldBg={FieldDetails.BGWHITE}
                  fieldAlign={FieldDetails.ALIGNCENTER}
                  fieldLt={false}
                  darkTxt={true}
                  value={quantity}
                  onChange={this.onChange}
                  type={'ACTIVITY'}
                  units={units}
                  max={null}
                  activitySlider={true}
                />
              </View>
              {category !== 'RT' ? <View style={styles.wdTen} /> : <View style={styles.fieldColSp} />}
              <View style={category !== 'RT' ? [styles.fieldColStretch, styles.rightSpSm] : [styles.fieldColStretch]}>
                {category !== 'RT' ?
                  <TextFrame
                    title={'Measure'}
                    fieldBg={FieldDetails.BGGREY}
                    fieldValue={units ? units : 'minutes'}
                    editable={false}
                    activitySlider={false}
                  />
                  :
                  <TextFrame
                    title={'Medication'}
                    fieldBg={FieldDetails.BGGREY}
                    fieldInfo={true}
                    fieldValue={name}
                    renderComponent={() => (
                      <MedticationTooltipContent
                        identificationNumber={identificationNumber}
                        treatmentType={treatmentType}
                        treatment={treatmentsProcessed[treatment]}
                        handleRedirectMedications={handleRedirectMedications}
                        windowWidth={windowWidth}
                        showLink={true}
                      />
                    )}
                    noOfLines={1}
                  />
                }
              </View>
              {category !== 'RT' && publishedBy !== 'PARTNER' &&
                <View style={[styles.fieldColDelete, styles.flexJcCt]}>
                  <TouchableOpacity onPress={() => deleteItem(index)}>
                    <Image
                      style={[
                        styles.delLgIcon,
                        styles.closeMed

                      ]}
                      source={require('../../../../assets/icons/close-light-grey.png')}
                    />
                  </TouchableOpacity>
                </View>
              } 
              {category === 'RT' &&
                <View>
                  <View style={styles.fieldColSp} />
                  <View style={styles.fieldColDelete}>
                    {publishedBy !== 'PARTNER' && (
                      <ItemDeleteButton touchableOnPress={() => deleteItem(index)} />
                    )}
                  </View>
                </View>
              }
            </View>
          </View>
        </View>
        <View style={[styles.secSepBorderBot, styles.comSp, styles.titleTopPd]} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    treatmentsProcessed: state.routines.treatmentsProcessed,
    hasInvalidNamesInActivity: state.therapie.hasActivityInvalidName,
    hasInvalidNamesInMedication: state.therapie.hasMedicationInvalidName,
    hasInvalidNamesInHealthData: state.therapie.hasHealthDataInvalidName,
    hasInvalidNamesInSymtoms: state.therapie.hasSymptomsInvalidName,
  };
};

export default connect(
  mapStateToProps,
  null,
)(UserItem);
