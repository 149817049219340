import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import styles from "../../Css";
import Modal from "../Modal/modal";
import ActivityIndicator from "../ActivityIndicator/activityIndicator";
import ModalLayout from "../ModalLayout/modalLayout";

const deleteResourceConfirmationBox = (props) => {
  return (
    <Modal 
      visible={props.visibleModal} 
      onBackdropPress={props.onBackdropPress}>
      <ModalLayout
        contPosition={"center"}
        popupAuto={true}
        hidePopup={props.onBackdropPress}
        bordeRadSm={true}
        pdCom={true}
      >
        <View style={[styles.flexAiCt, styles.centrTxtAlgn]}>
          
          <Text 
            style={[styles.textTitleExLgBold, styles.darkLinktext]}
            allowFontScaling={false}>
            Delete Resource?
          </Text>

          <View style={[styles.flexJcCt, styles.flexAiCt, styles.secSpPdTopMd, styles.deleteResourceConWidth]}>
          <Text   
            style={[styles.textTitle, styles.textBlack, styles.centrTxtAlgn]}
            allowFontScaling={false}>
            Are you sure you want to permanently delete this resource?
          </Text>
          </View>

          <Text 
            style={[styles.textTitle, styles.textBlack, styles.modelContPd]}
            allowFontScaling={false}>
            This action cannot be undone.
          </Text>
          
          <View style={[styles.flexRow, styles.titleBlockPdTop]}>
          <TouchableOpacity
              onPress={props.onBackdropPress}
              disabled={props.deleteWait}
            >
              <View style={[styles.commonBtnStyle, styles.deleteResourceCancelBtn]}>
                <Text 
                  style={[styles.textTitleBold, styles.textWhite]}
                  allowFontScaling={false}>
                  Cancel
                </Text>
              </View>
            </TouchableOpacity>
            
            <TouchableOpacity
              onPress={() => {
                props.handleDelete();
              }}
              disabled={props.deleteWait}>
              <View style={[styles.commonBtnStyle,styles.deleteResourceDeleteBtn,]}>
                <View style={styles.btnSingleCent}>
                {props.deleteWait && (
                  <View style={styles.loaderWait}>
                    <ActivityIndicator size="small" />
                  </View>
                )}
                <Text 
                  style={[styles.textTitleBold, styles.textColorGreyLight]}
                  allowFontScaling={false}>
                  Yes, Delete
                </Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>

        </View>
        
      </ModalLayout>
    </Modal>
  );
};
export default (deleteResourceConfirmationBox);
