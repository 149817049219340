import React from 'react';
import {View, Text, Image} from 'react-native';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import {getCoverIcon, getCoverImage} from '../../../util/commonUiLogic';
import PageContainer from '../../../components/PageLayout/PageContainer';
import MenuOptionPopover from '../../../screens/HealthJourney/HealthJourneyList/MenuOptionPopover';
import { TAB_BREAk_POINT_START } from '../../../util/platformWindowConfig';

const header = props => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  const {
    treatmentType,
    name,
    identificationNumber,
    dosages,
  } = props.selectedTherapie;
  const {quantity, units} = dosages && dosages.length > 0 ? dosages[0] : {};

  let windowWidth = props.windowWidth;
  let conTitle = styles.textTitleExLgBold;
  // if (props.fontSizeView === "fontExSm") {
  //   conTitle = styles.textPrimeMdBold;
  // } else if (props.fontSizeView === "fontSm") {
  //   conTitle = styles.textTitleBold;
  // } else {
  //   conTitle = styles.textTitleExLgBold;
  // }
  if (windowWidth > 1280) {
    conTitle = styles.textTitleExLgBold;
  } else if (windowWidth > TAB_BREAk_POINT_START) {
    conTitle = styles.textTitleBold;
  } else {
    conTitle = styles.textPrimeLgBold;
  }
  let validIdentificationNumber = false;
  if (identificationNumber && identificationNumber.trim() !== '') {
    validIdentificationNumber = true;
  }

  return (
    <View style={[styles.containerCardTitleView]}>
      <View style={[styles.cardTitleArea]}>
        <View style={stylesRes.contentTopicSection}>
          <View style={styles.medListFullSec}>
            <View
              style={[
                styles.medListSingleBoxLg,
                styles.bgOrange,
                styles.boxHeightMd,
              ]}>
              <View style={styles.medSecImgFull}>
                <Image
                  style={[styles.medSecImgBgView, styles.boxHeightMd]}
                  resizeMode={'cover'}
                  source={getCoverImage(treatmentType)}
                />
              </View>

              <PageContainer>
                <View style={[stylesRes.contentSection]}>
                  <View style={[stylesRes.medContArea]}>
                    <View style={[styles.medContentTop, styles.colPdLtRt1]}>
                      <View style={styles.medTitleTop}>
                        <View style={styles.medTopicIconBlock}>
                          <Image
                            style={[styles.medIcon, {width: 38, height: 40}]}
                            resizeMode={'contain'}
                            source={getCoverIcon(treatmentType)}
                          />
                        </View>
                        <View style={styles.medTitleBlockLg}>
                          <Text style={conTitle} allowFontScaling={false} numberOfLines={3}>{name}</Text>

                          {treatmentType !== 5 && Boolean(validIdentificationNumber) && (
                            <View
                              style={[styles.medContentBot, styles.topPdExSm]}>
                              <View>
                                <Text style={[styles.medDesc]} allowFontScaling={false}>
                                  <Text style={[styles.bold]} allowFontScaling={false}>
                                    DIN/NPN: {identificationNumber}
                                  </Text>
                                </Text>
                              </View>
                            </View>
                          )}

                          {treatmentType === 5 && quantity !== 0 && Boolean(quantity) && (
                            <View
                              style={[styles.medContentBot, styles.topPdExSm]}>
                              <View>
                                <Text style={[styles.medDesc]} allowFontScaling={false}>
                                  <Text style={[styles.bold]} allowFontScaling={false}>
                                    Duration:{' '}
                                    {quantity +
                                      ' ' +
                                      (units ? units : 'minutes')}
                                  </Text>
                                </Text>
                              </View>
                            </View>
                          )}
                        </View>
                      </View>

                      <MenuOptionPopover
                        placement="left"
                        triggerIcon={require('../../../assets/icons/settings.png')}
                        menuOptions={props.menuOptions}
                        nativeIdMenuTrigger={'medSuppActView3Dots'}
                        menuTrigger={'MedSupp+Act > View@ > 3Dots'}
                        itemMenuTrigger={true}
                      />
                    </View>
                  </View>
                </View>
              </PageContainer>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default (header);
