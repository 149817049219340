import * as variable from "../css/base/variable";

export const cssUploadAttachmentResponsive = {

  uploadIconOrange: {
    width: 29,
    height: 38,
  },


  uploadBtnCamImgOrange: {
    width: 47,
    height: 39,
  },

  uploadBtnImgFullAlign: {
    minHeight: 0,
    marginBottom: 2,
  },

  newUploadBtnSingle: {
    height: 140,
    width: 100,
    "@media (min-width: 992px)": {
      height: 140,
    width: 200,
    }

  },

  newUploadBtnSingleLeftBtn: {
  marginRight: 20,

  },

  newUploadBtnSingleRightBtn: {
  marginLeft: 20,

  },

  newUploadBtnLink: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  newModalScrollBlock: {
    height: 201,
    width: 358,

    "@media (min-width: 992px)": {
      height: 283,
      width: 600,
    }
  },

  tabContentFixedHeight: {
    maxHeight: 250,
    overflowY: 'auto',
    paddingBottom: 0,

    "@media (min-width: 992px)": {
      paddingBottom: 0,
    }
  },

  dragNdropSection: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 40,
    marginBottom: 40,
    paddingLeft: 20,
    paddingRight: 20,
    width: '80%',

    "@media (min-width: 992px)": {
      //maxWidth: 808,
      paddingRight: 0,
      paddingLeft: 0,
    }

  },

  newScanFullArea: {
    width: '100%',
  },

  uploadAttachmentCamIconWrapper: {
    border: 'none',
    boxShaddow: 'unset',
    width: 56,
    height: 56,

  },

  uploadAttachmentframeAreaContView: {
    position: 'relative',
    paddingBottom: 20
  },

  namePrefixPopup: {
    ...variable.marginAllAuto,
    height: 248,
    width: 323,
    alignItems: 'center',
    backgroundColor: '#ffffff',
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 15,
    paddingRight: 15,
  },
  
  progressBarPopUp: {
    ...variable.marginAllAuto,
    alignItems: 'center',
    backgroundColor: '#ffffff',
    height: 277,
    paddingTop: 35,
    paddingBottom: 35,
    paddingLeft: 15,
    paddingRight: 15,
    width: 323,
  },

  saveCancelButtonBlockWrapper: {
    position: 'relative',
    marginTop: 20,


    "@media (min-width: 992px)": {
      //position: 'absolute',
      //bottom: 0,
      paddingBottom: 200,
     // maxWidth: 808,
      width: '100%',

    }
  },

  btnViewSecNew: {
    paddingTop: 0,
  },

  cropperTextBlock: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 20,

    "@media (min-width: 992px)": {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
    }
  },

  buttonAndDragSection: {
    flexDirection: 'column',
    marginTop: 10,
    marginBottom: 20,

    "@media (min-width: 991px)": {
      flexDirection: 'row',
    }

  },

  dropZoneInnerWrapper: {
    justifyContent: 'center',
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,

    "@media (min-width: 991px)": {
      marginRight: 0,
      marginTop: 0,
      width: '90%',
    }
  },

  uploadErrorMessage: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",

    "@media (min-width: 991px)": {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 15,
    }
  },
};
