import React, { useState } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  Keyboard
} from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import TextInput from "../TextInput/textInput";
import moment from "moment";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import CancelButton from "../Button/cancelButton";
import { pendoTrackEvent } from "../../util/pendoConfig";
import { WEB } from "../../util/platformWindowConfig";


let scrollViewRef = null;
const PauseMedication = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {
    onRequestClose,
    treatment,
    onPauseMedication
  } = props;
  const [error, setError] = useState(false);
  const [pauseDays, setPauseDays] = useState('');
  const [pauseUntil, setPauseUntil] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);
  const [pauseWait, setPauseWait] = useState(false);
 
  const onChangePauseDays = val => {
    const days = val;
    var valid = days.match(/^-?\d*(\.\d+)?$/);
    if (isNaN(days) || !valid || days === 0 || days === '0') {
      if (!pauseDays) {
        setPauseDays('');
      }
    } else if (days > 99) {
      setError(true);
      setPauseUntil(null);
      setPauseDays(val);
    } else {
      let today = null;
      let newDay = null;
      if (val) {
        today = moment();
        newDay = moment(today).add(val, 'days');
      }
      setError(false);
      setPauseUntil(newDay);
      setPauseDays(val);
      setTimeStamp(new Date().getTime())
    }
    scrollToBottom();
  };

  const onPressPause = () => {
    Keyboard.dismiss();
    if (pauseDays && pauseDays <= 99) {
      setPauseWait(true);
      pendoTrackEvent(treatment?.treatmentType !== 5 
        ? 'MedSupp+Act@ > MedSuppPause'
        : 'MedSupp+Act@ > ActivityPause'
      );
      onPauseMedication(treatment, timeStamp, pauseDays, (success, message) => {
        onRequestClose();
      });
    } else {
      setError(true);
    }
  };

  const scrollToBottom = () => {
    if (scrollViewRef && scrollViewRef.scrollToEnd) {
      setTimeout(() => {
        scrollViewRef.scrollToEnd()
      }, 0);
    }
  }
  return (
      <View style={stylesRes.modalScrollMessageView} dataSet={{media: ids.modalScrollMessageView}}>
        <ScrollView 
          style={[styles.modalScroller, {height: 450}]} 
          keyboardShouldPersistTaps={'always'}
          ref={ref => (scrollViewRef = ref)} 
        >
          <View style={[styles.popUpBlock, styles.bgWhite]}>
            <View style={styles.popUpContentArea}>
              <View style={styles.gridSectionPopup}>
                <View
                  style={[
                    styles.popUpContentPd,
                    styles.popUpBoxHeightMin,
                    styles.flexJcFe
                  ]}
                >
                  <View style={stylesRes.modalContIn}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.popMessageIconView, styles.comSp]}>
                          <Image
                            style={styles.pauseBtn}
                            source={require("../../assets/icons/pause-button.png")}
                          />
                        </View>

                        <View
                          style={[styles.popMessageContView, styles.comSpSm]}
                        >
                          <View
                            style={[
                              styles.popMessageTitleView,
                              styles.popupTitleMg
                            ]}
                          >
                            <Text
                              style={[styles.textTitle, styles.textAlignCenter]}
                              allowFontScaling={false}
                            >
                              <Text style={styles.bold} allowFontScaling={false}>Pause</Text>{" "}
                              {treatment?.treatmentType !== 5
                                ? "Medications/Supplements"
                                : "Activities"}
                            </Text>
                          </View>

                          <View style={styles.popMessageDescView}>
                            <Text
                              style={[styles.textPrime, styles.textAlignCenter]}
                              allowFontScaling={false}
                            >
                              Are you sure?
                            </Text>
                          </View>
                        </View>

                        <View
                          style={[
                            styles.popMessageDetailsView,
                            styles.comMgTopSm
                          ]}
                        >
                          <View style={stylesRes.pauseDetailWrapper}>
                            <View style={styles.pauseDetailView}>
                            <Text
                                style={[
                                  styles.textPrime,
                                  styles.textAlignCenter
                                ]}
                                allowFontScaling={false}
                              >
                                For how many days would you like to pause?
                              </Text>
                              <Text
                                style={[
                                  styles.textPrime,
                                  styles.textEm,
                                  styles.textAlignCenter
                                ]}
                                allowFontScaling={false}
                              >
                                After this pause, you will be notified to restart
                                this {treatment?.treatmentType !== 5
                                  ? 'medication/supplement'
                                  : 'activity.'
                                }
                              </Text>
                            </View>
                          </View>

                          <View style={styles.pauseFieldWrapper}>
                            <View style={styles.pauseField}>
                              <TextInput
                                style={[
                                  styles.textField,
                                  styles.textFieldSm,
                                  styles.texFieldSingle,
                                  styles.textAlignCenter,
                                  styles.noRad
                                ]}
                                allowFontScaling={false}
                                onChangeText={onChangePauseDays}
                                value={pauseDays}
                                autoFocus={true}
                                keyboardType={
                                  Platform.OS === WEB ? "default" : "numeric"
                                }
                                onFocus={() => scrollToBottom()}
                              />
                            </View>

                          {Boolean(error) && (
                              <View style={styles.errorMessage}>
                                <Image
                                  style={[styles.iconWarning, styles.imgSp]}
                                  resizeMode={"cover"}
                                  source={require("../../assets/icons/warning.png")}
                                />
                                <Text style={styles.warningText} allowFontScaling={false}>
                                  Maximum number of days you can pause is 99
                                </Text>
                              </View>
                            )}
                          {Boolean(pauseUntil) && (
                              <React.Fragment>
                                <View style={styles.pauseTitle}>
                                  <Text
                                    style={[
                                      styles.textPrimeLgBold,
                                      styles.textAlignCenter
                                    ]}
                                    allowFontScaling={false}
                                  >
                                    Pause Until:
                                  </Text>
                                </View>
                                <View style={styles.pauseDetail}>
                                  <Text
                                    style={[
                                      styles.textPrimeLg,
                                      styles.textAlignCenter
                                    ]}
                                    allowFontScaling={false}
                                  >
                                    {moment(pauseUntil).format(
                                      "D MMM 'YY"
                                    )}{", "}
                                    {moment(pauseUntil).format("h:mm a")}
                                  </Text>
                                </View>
                              </React.Fragment>
                            )}
                          </View>
                        </View>

                        <View
                          style={[
                            styles.popBtnView,
                            styles.comMgTopSm,
                            styles.comSp
                          ]}
                        >
                          <View style={styles.btnArea}>
                            <View
                              style={[
                                stylesRes.btnViewSec,
                                stylesRes.btnViewSecComNoPd
                              ]}
                            >
                              <View style={styles.fieldBtn}>
                                <View style={styles.btnBlockFull}>
                                  <CancelButton 
                                    touchableOnpress={onRequestClose}
                                    cancelBtnWidthDiff={true}
                                  />
                                </View>
                              </View>
                              <View style={styles.fieldBtnSpSm} />
                              <View style={styles.fieldBtn}>
                                <View style={styles.btnBlockFull}>
                                  <TouchableOpacity
                                    style={[
                                      styles.submitSm,
                                      styles.btnShadowCom,
                                      styles.submitBgRed
                                    ]}
                                    underlayColor="rgba(0, 153, 168, 0.8)"
                                    onPress={onPressPause}
                                    disabled={error || pauseWait}
                                    nativeID={treatment?.treatmentType !== 5 ? 'medSuppPause' : 'ActivityPause'}
                                  >
                                    <View style={styles.btnSingleView}>
                                      <View
                                        style={[
                                          styles.btnSingle,
                                          styles.btnSingleCent
                                        ]}
                                      >
                                      {Boolean(pauseWait) && (
                                          <View
                                            style={[
                                              styles.indicatorIconView,
                                              styles.indicatorLtMiniLg
                                            ]}
                                          >
                                            <ActivityIndicator
                                              pdZero={true}
                                              size="small"
                                            />
                                          </View>
                                         )} 
                                        <Text
                                          style={[
                                            styles.submitBtnTextWhite,
                                            styles.bold
                                          ]}
                                          allowFontScaling={false}
                                        >
                                          Yes, Pause
                                        </Text>
                                      </View>
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
  );
};
export default PauseMedication;
