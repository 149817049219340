import React, {Component} from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import {connect} from 'react-redux';
import Cookies from 'js-cookie';
import {Linking} from 'react-native';
import {
  CAREGIVERS,
  CLINICAL_TRIALS,
  COMMUNITY_GROUPS,
  CONTACTS,
  DASHBOARD,
  GRAPHS,
  IMAGES_DOCUMENTS,
  MEDICATIONS,
  METRICS,
  OTHER,
  PEER_TO_PEER,
  PROFILE,
  TODOS,
  REPORTS,
  ROUTINES,
  JOURNALS,
  SURVEYS,
  MANAGE_DEVICES,
  SYMPTOMS,
  HELP,
  REFER_FRIEND,
  HEALTH_JOURNEY
} from './path';
import {redirect} from './redirect';
import * as actions from '../store/actions/index';

import {
  Layout,
  WelcomeScreen,
  LoginScreen,
  SignUpScreen,
  DashboardScreen,
  SetUpHOC,
  EmailVerificationScreen,
  DashbordLoadingScreen,
  CaregiverVerificationScreen,
} from '../screens';
import NavService from './navigationService';
import EmailRecovery from '../screens/EmailRecovery';
import ReferFriendScreen from "../screens/ReferFriendScreen";
import RouteCommonLoggedInAndLoadedRoutes from './RouteCommonLoggedInAndLoadedRoutes';
import * as RootNavigation from './RootNavigation';
import {DEEP_LINK_URL} from '../store/actions/urls';

const Stack = createStackNavigator();
let redirectPath = DASHBOARD.path;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: props.isAuthenticated,
      logout: props.logout,
      fetchAllJournalsSpinnerStart: props.fetchAllJournalsSpinnerStart,
      fetchAllJournalsSpinner: props.fetchAllJournalsSpinner,
      deeplinkNotYetHandled: props.deeplinkNotYetHandled,
    };
  }
  componentDidMount() {
    //TO-DO remove previous navigation changes
    //NavService.setNavService(this.props.history);
    // const pathname = this.props.location.pathname.split('/');

    // if (
    //   this.props.location.pathname.includes('accept') ||
    //   this.props.location.pathname.includes('update-routine') ||
    //   this.props.location.pathname.includes('create-journal') ||
    //   this.props.location.pathname.includes('survey-questions') ||
    //   this.props.location.pathname.includes('/journal-from-email')
    // ) {
    //   redirectPath = this.props.location.pathname;
    // } else if (redirect[pathname[1]]) {
    //   redirectPath = redirect[pathname[1]];
    // }
    NavService.setNavService(RootNavigation.getNavigationProps());
    Linking.getInitialURL().then(url => {
      const pathContent = url && url.replace(DEEP_LINK_URL, "");
      const pathname = pathContent.split('/');
  
      if (
        pathContent.includes('accept') ||
        pathContent.includes('update-routine') ||
        pathContent.includes('create-journal') ||
        pathContent.includes('survey-questions') ||
        pathContent.includes('journal-from-email') ||
        pathContent.includes('manage-devices') ||
        pathContent.includes('joinstudy') 
      ) {
        redirectPath = pathContent;
      } else if (redirect[pathname[1]]) {
        redirectPath = redirect[pathname[1]];
        RootNavigation.getNavigationProps().navigate(redirectPath);
      }
    });

    this.handleResize();
    window.addEventListener('resize', this.handleResize, true);
    this.props.showMobileMenu();
    this.props.setFromPopup(null);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      isAuthenticated,
      logout,
      fetchAllJournalsSpinnerStart,
      fetchAllJournalsSpinner,
      deeplinkNotYetHandled
    } = props;
    //TO-DO remove previous navigation changes
    // const pathname = location.pathname.split('/');

    // if (redirect[pathname[1]]) {
    //   redirectPath = redirect[pathname[1]];
    // }
    NavService.setNavService(RootNavigation.getNavigationProps());

    Linking.getInitialURL().then(url => {
      const pathContent = url && url.replace(DEEP_LINK_URL, "");
      const pathname = pathContent.split('/');
      if (redirect[pathname[1]]) {
        redirectPath = redirect[pathname[1]];
      }
    });

    if (
      isAuthenticated !== state.isAuthenticated ||
      logout !== state.logout ||
      fetchAllJournalsSpinnerStart !== state.fetchAllJournalsSpinnerStart ||
      fetchAllJournalsSpinner !== state.fetchAllJournalsSpinner ||
      deeplinkNotYetHandled !== state.deeplinkNotYetHandled
    ) {
      return {
        isAuthenticated: isAuthenticated,
        logout: logout,
        fetchAllJournalsSpinnerStart: fetchAllJournalsSpinnerStart,
        fetchAllJournalsSpinner: fetchAllJournalsSpinner,
        deeplinkNotYetHandled: deeplinkNotYetHandled,
      };
    }

    return null;
  }

  navigateWhenReady = data => {
    const {path, trys = 0} = data
    if (trys === 5) return; // somethings not right!
    if (this.isLoggedInAndJournalHasBeenFetched()) {
      RootNavigation.getNavigationProps().reset({
        index: 0,
        routes: [{name: path}],
      });
      return;
    }
    return setTimeout(() => this.navigateWhenReady({...data, trys: trys + 1}), 1000);
  }

  handleResize = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
    this.props.onWindowResize(window.innerHeight, window.innerWidth);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    let routes;
    //pages that will always be in route regardless of logged in or not
    const globalPages = [
      <Stack.Screen
        name={OTHER.emailReset}
        component={EmailRecovery}
      />,
    ];

    if (this.isLoggedInAndJournalHasBeenFetched()) {
      routes = (
        <Layout>
          <Stack.Navigator headerMode={'none'} initialRouteName={redirectPath}>
          {RouteCommonLoggedInAndLoadedRoutes.getFragment([
            {
              name: OTHER.loginPath,
              component: DashboardScreen,
            },
            // {
            //   name: DASHBOARD.path,
            //   component: DashboardScreen,
            // },
            // {
            //   path: DASHBOARD.root,
            //   component: DashboardScreen,
            // },
            {
              name: REFER_FRIEND.path,
              component: ReferFriendScreen,
            },
          ])}
          </Stack.Navigator>
        </Layout>
      );
    } else if (this.isLoggedInButJournalNeedsToBeFetchedOrDeepLinkTriggered()) {
      routes = (
        <Stack.Navigator headerMode={'none'} initialRouteName={redirectPath}>
          {globalPages}
          {[
            DASHBOARD.path,
            DASHBOARD.root,
            JOURNALS.path,
            TODOS.path,
            MEDICATIONS.path,
            CONTACTS.path,
            IMAGES_DOCUMENTS.path,
            TODOS.addToDoPath,
            IMAGES_DOCUMENTS.addImagesDocumentsPath,
            REPORTS.path,
            SURVEYS.path,
            ROUTINES.path,
            SYMPTOMS.path,
            METRICS.path,
            MANAGE_DEVICES.path,
            HEALTH_JOURNEY.path,
            GRAPHS.path,
            CAREGIVERS.path,
            PROFILE.path,
            PROFILE.profileMyDevicesPath,
            CAREGIVERS.title,
            CAREGIVERS.inviteCaregiverPath,
            PROFILE.profileMeasurementsPath,
            HELP.path,
            //REPORTS.newReportPath,
            // REPORTS.viewReportPath,
            //GRAPHS.addGraphPath,
            CLINICAL_TRIALS.otherPath,
            CLINICAL_TRIALS.path,
            COMMUNITY_GROUPS.path,
            PROFILE.profileCancelSubscriptionPath,
            PROFILE.profilePremiumAccountPath,
            OTHER.noNetwork,
            REFER_FRIEND.path,
            PEER_TO_PEER.disclaimerPath,
            PEER_TO_PEER.peerResultsByConditionPath,
            PEER_TO_PEER.path,
            OTHER.loginPath
          ].map(path => (
            <Stack.Screen
              key={path}
              name={path}
              component={DashbordLoadingScreen}
              initialParams={{navigateWhenReady: this.navigateWhenReady}}
            />
          ))}
          <Stack.Screen
            name={CAREGIVERS.caregiverAcceptPath}
            component={DashbordLoadingScreen}
            initialParams={{navigateWhenReady: this.navigateWhenReady}}
          />
          <Stack.Screen
            name={JOURNALS.updateRoutinePath}
            component={DashbordLoadingScreen}
            initialParams={{navigateWhenReady: this.navigateWhenReady}}
          />
          <Stack.Screen
            name={JOURNALS.createJournalPath}
            component={DashbordLoadingScreen}
            initialParams={{navigateWhenReady: this.navigateWhenReady}}
          />
          <Stack.Screen
            name={JOURNALS.journalFromEmail}
            component={DashbordLoadingScreen}
            initialParams={{navigateWhenReady: this.navigateWhenReady}}
          />
          <Stack.Screen
            name={SURVEYS.surveyQuestionsPath}
            component={DashbordLoadingScreen}
            initialParams={{navigateWhenReady: this.navigateWhenReady}}
          />
          <Stack.Screen
            name={SURVEYS.surveyConsentPath}
            component={DashbordLoadingScreen}
            initialParams={{navigateWhenReady: this.navigateWhenReady}}
          />
          <Stack.Screen
            name={OTHER.emailVerificationPath}
            component={EmailVerificationScreen}
          />

          <Stack.Screen
            name={OTHER.caregiverVerificationPath}
            component={CaregiverVerificationScreen}
          />
          <Stack.Screen
            name={DASHBOARD.joinStudyPath}
            component={DashbordLoadingScreen}
            initialParams={{navigateWhenReady: this.navigateWhenReady}}
          />
          {/* <Stack.Screen to={redirectPath} /> */}
        </Stack.Navigator>
      );
    } else if (this.notLoggedIn()) {
      routes = (
        <SetUpHOC>
          <Stack.Navigator headerMode={'none'} initialRouteName={OTHER.loginPath}>
            {globalPages}
            <Stack.Screen name={OTHER.loginPath} component={LoginScreen} />
            <Stack.Screen
              name={CAREGIVERS.caregiverAcceptPath}
              component={LoginScreen}
            />
            <Stack.Screen
              name={JOURNALS.updateRoutinePath}
              component={LoginScreen}
            />
            <Stack.Screen
              name={JOURNALS.createJournalPath}
              component={LoginScreen}
            />
            <Stack.Screen
              name={JOURNALS.journalFromEmail}
              component={LoginScreen}
            />
            <Stack.Screen
              name={SURVEYS.surveyQuestionsPath}
              component={LoginScreen}
            />
            <Stack.Screen
              name={SURVEYS.surveyConsentPath}
              component={LoginScreen}
            />
            <Stack.Screen
              name={DASHBOARD.joinStudyPath}
              component={LoginScreen}
            />
            <Stack.Screen name={OTHER.signupPath} component={SignUpScreen} />

            {/* <Stack.Screen to={OTHER.loginPath} /> */}
          </Stack.Navigator>
        </SetUpHOC>
      );
    } else {
      routes = (
        <SetUpHOC>
          <Stack.Navigator headerMode={'none'} initialRouteName={OTHER.loginPath}>
            {globalPages}
            <Stack.Screen name={OTHER.loginPath} component={LoginScreen} />
            <Stack.Screen
              name={CAREGIVERS.caregiverAcceptPath}
              component={LoginScreen}
            />
            <Stack.Screen
              name={JOURNALS.updateRoutinePath}
              component={LoginScreen}
            />
            <Stack.Screen
              name={JOURNALS.createJournalPath}
              component={LoginScreen}
            />
            <Stack.Screen
              name={JOURNALS.journalFromEmail}
              component={LoginScreen}
            />
            <Stack.Screen
              name={SURVEYS.surveyQuestionsPath}
              component={LoginScreen}
            />
            <Stack.Screen
              name={SURVEYS.surveyConsentPath}
              component={LoginScreen}
            />
            <Stack.Screen
              name={MANAGE_DEVICES.path}
              component={LoginScreen}
            />
            <Stack.Screen name={OTHER.welcomePath} component={WelcomeScreen} />
            <Stack.Screen name={OTHER.signupPath} component={SignUpScreen} />
            <Stack.Screen name="/" component={WelcomeScreen} />
            {/* if not logged in then should rediret to login */}
            <Stack.Screen
              name={OTHER.emailVerificationPath}
              component={LoginScreen}
            />
            <Stack.Screen
              name={DASHBOARD.joinStudyPath}
              component={LoginScreen}
            />
            {/* <Stack.Screen to="/" /> */}
          </Stack.Navigator>
        </SetUpHOC>
      );
    }

    return <React.Fragment>{routes}</React.Fragment>;
  }

  notLoggedIn() {
    return this.state.logout || !this.state.isAuthenticated;
  }

  isLoggedInButJournalNeedsToBeFetchedOrDeepLinkTriggered() {
    return this.state.isAuthenticated &&
        (!this.state.fetchAllJournalsSpinnerStart || this.state.fetchAllJournalsSpinner || this.state.deeplinkNotYetHandled) &&
        !this.state.logout;
  }

  isLoggedInAndJournalHasBeenFetched() {
    return this.state.isAuthenticated &&
        this.state.fetchAllJournalsSpinnerStart &&
        !this.state.fetchAllJournalsSpinner &&
        !this.state.deeplinkNotYetHandled &&
        !this.state.logout;
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: Cookies.get('authToken') !== undefined ? true : false,
    logout: state.auth.logout,
    isSignup: state.auth.isSignup,
    fetchAllJournalsSpinnerStart: state.routines.fetchAllJournalsSpinnerStart,
    fetchAllJournalsSpinner: state.routines.fetchAllJournalsSpinner,
    deeplinkNotYetHandled: state.routines.deeplinkNotYetHandled,
    isFromLogin: state.auth.isFromLogin,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetachAllJournals: () => dispatch(actions.fetchAllJournalEntries()),
    loginButtonPress: () => dispatch(actions.loginButtonPress()),
    onWindowResize: (windowHeight, windowWidth) =>
      dispatch(actions.windowResize(windowHeight, windowWidth)),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    setFromPopup: fromPopup => dispatch(actions.setFromPopup(fromPopup)),
  };
};

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App);
