import React, { Component } from "react";
import { connect } from "react-redux";
import { View, Image, TouchableOpacity, Text } from "react-native";
import styles from "../../Css";
import moment from "moment";
import {
  getCurrentSubscription,
  getAccountType,
} from "../../util/subscriptionInformation";
import WatchOverview from "../../components/MessageRibbon/watchOverview";
import * as actions from "../../store/actions/index";
import {ORGANIZATION_NAME} from '../../util/commonUiConfig';
import { pendoTrackEvent } from "../../util/pendoConfig";
class UpgradePremiumContent extends Component {
  constructor(props) {
    super(props);
    const currentSubscription = getCurrentSubscription(props.userSubscription);
    const accountType = getAccountType(props.userSubscription);
    const { windowHeight, windowWidth, applicationSettingsDigest } = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      currentSubscription: currentSubscription,
      freeDays: this.getFreeDays(currentSubscription),
      accountType: accountType,
      showBanner:
        (currentSubscription &&
          currentSubscription.subscriptionStatus === "TRIAL") ||
        accountType === "regular" ||
        (accountType === "premium" && 
          applicationSettingsDigest && 
          applicationSettingsDigest.showPremiumBanner),
      showNextIcon: 
        (currentSubscription &&
          currentSubscription.subscriptionStatus === "TRIAL") ||
        accountType === "regular"
    };
  }

  getFreeDays = (currentSubscription) => {
    if (
      currentSubscription &&
      currentSubscription.subscriptionStatus === "TRIAL"
    ) {
      var a = moment();
      var b = moment(currentSubscription.subscriptionExpiryDate);

      return b.diff(a, "days");
    }

    return null;
  };

  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth } = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  closePremiumBanner = () => {
    this.props.updatePremiumBannerHide(
      false,
      this.props.journalUserId,
      res => {
        if(res) {
          this.setState({
            showBanner: false
          })
        }
      },
    );
  }
  render() {
    const { freeDays, showBanner, windowWidth, accountType, showNextIcon } = this.state;
    return (
      <>
          <View style={styles.secSpPdTop}>
          {Boolean(showBanner) && (
          <View
            nativeID={'dashboardPremiumBanner'}
            style={[styles.premiumUpgradeConteArea, styles.premiumBoxWrapper, styles.ribbonWrapper]}
          >
            <TouchableOpacity
              style={[styles.ribbonSpec, styles.flexRowJcCtAiCt]}
              onPress={() => {
                this.props.redirectPremiumPage();
                pendoTrackEvent('Dashboard@ > Premium');
              }}
              disabled={!showNextIcon || this.props.journalUserId}
            >
              <WatchOverview windowWidth={windowWidth} />
              <View 
                style={[styles.flexCom, styles.flexRowJcCtAiCt, styles.ribbonPdRtTopBot]} 
                nativeID={'dashboardPremium'}>
                <View style={[styles.premiumIconAreaSm, styles.flexJcCtAiCt]}>
                  <Image
                    style={[styles.premiumIconMd]}
                    source={require("../../assets/icons/premium-main.png")}
                  />
                </View>
                <View style={[styles.premiumTextAreaSm, styles.flexCom]}>
                  <View style={styles.premiumTextTitle}>
                    <Text style={styles.textPrimeBold} allowFontScaling={false}>
                      {freeDays
                        ? freeDays + " days left - FREE premium trial"
                        : accountType === 'regular' 
                          ? `Upgrade to ${ORGANIZATION_NAME} Premium`
                          : "Congratulations on becoming a premium user!"
                      }
                    </Text>
                  </View>
                </View>
                <View style={[styles.premiumArrowArea, styles.flexJcCtAiCt]}>
                  {showNextIcon
                    ? <Image
                        style={[styles.arrowActionIcon]}
                        source={require("../../assets/icons/arrow-next-dark.png")}
                      />
                    : <TouchableOpacity
                        style={[styles.premiumBoxContWrapper, styles.flexRowJcCtAiCt]}
                        onPress={this.closePremiumBanner}
                        disabled={this.props.journalUserId}
                      >
                        <Image
                          style={[styles.ribbonClose]}
                          source={require("../../assets/icons/ribbon-close.png")}
                        />
                      </TouchableOpacity>
                  }
                </View>
              </View>
            </TouchableOpacity>
          </View>
          )}
          </View>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userSubscription: state.routines.userSubscription,
    windowWidth: state.uistate.windowWidth,
    windowHeight: state.uistate.windowHeight,
    journalUserId: state.uistate.journalUserId,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePremiumBannerHide: (
      showPremiumBanner,
      journalUserId,
      callBack,
    ) =>
      dispatch(
        actions.updatePremiumBannerHide(
          showPremiumBanner,
          journalUserId,
          callBack,
        ),
      ),
  };
};

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(UpgradePremiumContent);
