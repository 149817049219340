import React from "react";
import { View, TextInput} from "react-native";
import styles from "../../Css";
import FieldTitleCommonSpec from "./fieldTitleCommonSpec";
import * as FontScaling from '../../components/Text/fontScaling';
const textAreaField = (props) => {
  return (
    <View style={[styles.textAreaInputWrapper, styles.postRelative,
      props.fieldBg === "grey"
      ? styles.bgThinGrey
      : props.fieldBg === "blue"
      ? styles.bgBlueExLight
      : props.fieldBg === "transparent"
      ? styles.bgTransparent
      : styles.bgWhite
      ]}>
      <FieldTitleCommonSpec
        fieldAlign={props.fieldAlign}
        title={props.title}
        requirdStar={props.requirdStar}
      />
      <TextInput
        style={[
          styles.textAreaInput,
          props.fieldInfo ? styles.frameInfoPd : styles.framePd,
          props.fieldAlign === "center"
            ? styles.textAlignCenter
            : styles.textAlignLeft,
          props.invalid ? styles.inValidColor : styles.validColor,
          props.fieldStyle
        ]}
        allowFontScaling={false}
        underlineColorAndroid="transparent"
        multiline={true}
        value={props.value ? props.value : ''}
        onChangeText={props.onChangeText}
        numberOfLines={props.numberOfLines}
        maxFontSizeMultiplier = {FontScaling.fontScaleField}
        placeholder={props.placeholder}
        autoFocus={props.autoFocus}
      />
    </View>
  );
};

export default textAreaField;
