import React from "react";
import styles from "../../../../Css";
import { View, TextInput } from "react-native";
import * as FontScaling from '../../../../components/Text/fontScaling';

const notes = (props) => {
  return (
    <View
      style={[
        styles.collapseContent,
        styles.colPd,
        props.fieldBg ? styles.bgThinGrey : styles.colBg,
      ]}
    >
      <View
        style={[
          styles.fieldRow,
          props.mgZero ? styles.fieldRowNoMg : styles.fieldRowMg,
        ]}
      >
        <TextInput
          style={[
            styles.textField,
            styles.textFieldSm,
            styles.textFieldWhite,
            styles.textAreaMax,
          ]}
          allowFontScaling={false}
          placeholder="Enter a new note"
          onChangeText={props.onChangeTextNote}
          value={props.noteValue}
          multiline={true}
          numberOfLines={2}
          underlineColorAndroid="transparent"
          maxFontSizeMultiplier = {FontScaling.fontScaleField}
        />
      </View>
    </View>
  );
};

export default (notes);
