import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Platform } from 'react-native';
import moment from "moment";
import styles from '../../../../Css';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextTimePickerFrame from '../../../TextInput/textTimePickerFrame';
import TextInputDayFrameSpec from '../../../TextInput/TextInputDayFrameSpec';
import TimePicker from '../../../DateTimePicker/TimePicker';
import DatePicker from '../../../DateTimePicker/DatePicker';
import TextDatePickerFrame from '../../../TextInput/textDatePickerFrame';
import ItemDeleteButton from '../../../Button/itemDeleteButton';
import {timeMap} from '../../../../util/formatDate';
import { TAB_BREAk_POINT, TAB_BREAk_POINT_START, WEB } from '../../../../util/platformWindowConfig';

class Monthly extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    const { reminderTime } = data;
    this.state = {
      ...data,
      reminderDateTime: this.getDateTime(reminderTime),
    };
  }

  getDateTime = reminderTime => {
    if (reminderTime && reminderTime.length > 0) {
      const now = moment()
        .set('hour', reminderTime[0])
        .set('minute', reminderTime[1]);
      return now.valueOf();
    }

    return null;
  };

  customTouchableComponentTimePicker = onPress => {
    return (
      Platform.OS === WEB
        ? <TouchableOpacity onPress={onPress}>
            {this.renderMonthlyTimeContent()}
          </TouchableOpacity>
        : this.renderMonthlyTimeContent()
    );
  };

  renderMonthlyTimeContent = () => {
    const { reminderTime, reminderDateTime } = this.state;
    let duration = '';
    if (reminderTime && reminderTime.length > 0) {
      const time = timeMap[reminderTime[0]];
      const times = time.split(' ');
      duration =
        times[0] +
        ':' +
        (reminderTime[1].toString().length === 1
          ? '0' + reminderTime[1]
          : reminderTime[1]) +
        ' ' +
        times[1];
    }
    return (
      <TextTimePickerFrame
        title={'Monthly @'}
        fieldBg={FieldDetails.BGWHITE}
        fieldSm={true}
        fieldValue={duration}
        onTimeChange={this.onTimeChange}
        reminderDateTime={reminderDateTime}
        windowWidth={this.props.windowWidth}
        reminderSection={this.props.reminderSection}
      />
    )
  }

  onTimeChange = selectedTime => {
    const time =
      Platform.OS === WEB
        ? moment(selectedTime).format('HH.mm')
        : moment(selectedTime, 'x').format('HH.mm');

    const { updateReminders, index } = this.props;
    const times = time.split('.');
    const { reminderTime } = this.state;
    reminderTime[0] = parseInt(times[0]);
    reminderTime[1] = parseInt(times[1]);
    this.setState(
      {
        reminderTime: reminderTime,
        reminderDateTime: this.getDateTime(reminderTime),
      },
      () => {
        this.setAccept();
        updateReminders(index, { ...this.state, reminderTime: reminderTime });
      },
    );
  };

  customTouchableComponent = onPress => {
    return (
      Platform.OS === WEB
        ? <TouchableOpacity onPress={onPress}>
            {this.renderEndReminderContent()}
          </TouchableOpacity>
        : this.renderEndReminderContent()
    );
  };

  renderEndReminderContent = () => {
    const { reminderEnd } = this.state;

    return (
      <TextDatePickerFrame
        title={'End Reminder'}
        fieldBg={FieldDetails.BGWHITE}
        fieldSm={true}
        fieldValue={
          reminderEnd ? moment(reminderEnd).format("D MMM 'YY") : ''
        }
        onDateConfirm={this.onDateConfirm}
        date={reminderEnd}
        dateSm={true}
        windowWidth={this.props.windowWidth}
        reminderSection={this.props.reminderSection}
        minDate={
          new Date().getDate() > this.state.reminderMonthlyDay 
            ? new Date(new Date().getFullYear(), new Date().getMonth() + 1, this.state.reminderMonthlyDay) 
            : new Date(new Date().getFullYear(), new Date().getMonth(), this.state.reminderMonthlyDay)
        }
      />
    )
  }

  renderMonthlyEndReminder = () => {
    const {reminderMonthlyDay, reminderEnd} = this.state;
    const {windowWidth, reminderSection} = this.props;
    return(
      <>
        <View style={styles.fieldColSp} />
        <View style={styles.fieldColStretch}>
          {reminderMonthlyDay !== null ? (
              <DatePicker
                date={reminderEnd}
                customTouchableComponent={this.customTouchableComponent}
                onConfirm={this.onDateConfirm}
                windowWidth={windowWidth}
                reminderSection={reminderSection}
                minDate={
                  new Date().getDate() > reminderMonthlyDay
                    ? new Date(new Date().getFullYear(), new Date().getMonth() + 1, reminderMonthlyDay)
                    : new Date(new Date().getFullYear(), new Date().getMonth(), reminderMonthlyDay)
                }
              />
            ) : null}
        </View>
      </>
    );
  }

  onDateConfirm = date => {
    const { updateReminders, index } = this.props;
    this.setState({ reminderEnd: date }, () =>
      updateReminders(index, { ...this.state, reminderEnd: date }),
    );
  };

  setAccept = () => {
    const { updateReminders, index } = this.props;
    const { reminderTime, reminderMonthlyDay } = this.state;
    if (reminderTime.length === 2 && reminderMonthlyDay) {
      this.setState({ accept: true }, () => {
        updateReminders(index, { ...this.state, accept: true });
      });
    }
  };

  setReminderMonthlyDay = val => {
    const { updateReminders, index } = this.props;
    this.setState({ reminderMonthlyDay: val }, () => {
      this.setAccept();
      updateReminders(index, { ...this.state, reminderMonthlyDay: val });
    });
    
    if(this.state.reminderEnd !== null){
      if (new Date().getDate() < val) {
        this.setState({ reminderEnd: new Date(new Date().getFullYear(), new Date().getMonth(), val) }, () =>
          updateReminders(index, { ...this.state, reminderEnd: new Date(new Date().getFullYear(), new Date().getMonth(), val) }),
        );
      }else{
        this.setState({ reminderEnd: new Date(new Date().getFullYear(), new Date().getMonth()+1, val) }, () =>
          updateReminders(index, { ...this.state, reminderEnd: new Date(new Date().getFullYear(), new Date().getMonth()+1, val) }),
        );
      }
    }
  };

  render() {
    const {
      reminderDateTime,
      reminderMonthlyDay,
    } = this.state;
    const { index, deleteItem, id, windowWidth, reminderSection, routineWizardReminderCont } = this.props;
    return (
      <React.Fragment>
        <View style={styles.monthlyAddWrapper}>
          <View style={styles.fieldContainer}>
            <View style={styles.fieldRowWrapper}>
              <View style={styles.fieldColIdCom}>
                <View style={styles.indexSm}>
                  <Text style={[styles.textPrimeSmBold, styles.indexTxtSm]}>
                    {id + 1}
                  </Text>
                </View>
              </View>
              <View style={styles.fieldColSp} />
              <View style={styles.fieldColStretch}>
                <TimePicker
                  customTouchableComponent={
                    this.customTouchableComponentTimePicker
                  }
                  onChange={this.onTimeChange}
                  value={reminderDateTime}
                  windowWidth={windowWidth}
                  reminderSection={reminderSection}
                />
              </View>
              
              <View style={styles.fieldColSp} />
              <View style={styles.fieldColStretch}>
                <TextInputDayFrameSpec
                  title={'On'}
                  fieldBg={FieldDetails.BGWHITE}
                  fieldSm={true}
                  fieldValue={reminderMonthlyDay}
                  setReminderMonthlyDay={this.setReminderMonthlyDay}
                  windowWidth={windowWidth}
                  reminderSection={reminderSection}
                />
              </View>

              {windowWidth > TAB_BREAk_POINT_START && !Boolean(routineWizardReminderCont) &&
                this.renderMonthlyEndReminder()
              }

              <View style={styles.fieldColSp} />
              <View style={styles.fieldColDelete}>
                <ItemDeleteButton
                  touchableOnPress={() => deleteItem(index)}
                  routinesReminderDelete={true}
                />
              </View>
            </View>

            {(windowWidth < TAB_BREAk_POINT || Boolean(routineWizardReminderCont)) &&
              <View style={styles.fieldRowWrapper}>
                <View style={styles.fieldColIdCom} />
                {this.renderMonthlyEndReminder()}
                <View style={styles.fieldColSp} />
                <View style={styles.fieldColDelete} />
              </View>
            }

          </View>
        </View>
      </React.Fragment>
    );
  }
}

export default (Monthly);
