import React, {Component} from 'react';
import {View, Text, Image} from 'react-native';
import styles from '../../../../Css';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import {AddSymptom, SymptomList} from '../../../Setup/Symptom';
import {SymptomFooter} from '../SetupFooter';
import MessageBox from '../../../Text/messageBox';
import SetupCancelConfirmationBox from '../setupCancelConfirmationBox';

class SymptomContent extends Component {
  constructor(props) {
    super(props);
    const {windowWidth, windowHeight, symptoms = [], saveDisable} = props;
    this.state = {
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      saveWait: false,
      nextWait: false,
      symptoms: symptoms ? symptoms : [],
      visibleCloseConfirmationModal: false,
      inActive: true,
      saveDisable: saveDisable
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth, symptoms = [], saveDisable} = props;
    if (
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth ||
      (symptoms && symptoms.length !== state.symptoms.length) ||
      saveDisable !== state.saveDisable
    ) {
      return {
        windowWidth: windowWidth,
        windowHeight: windowHeight,
        symptoms: symptoms,
        saveDisable: saveDisable
      };
    }

    return null;
  }

  update = (callBack, from) => {
    const {symptoms, updateUserSymptom, updateSymptom} = this.props;
    if (symptoms && symptoms.length > 0) {
      const updateSymptomList = [];
      if (from === 'SAC') {
        this.setState({saveWait: true});
      } else {
        this.setState({nextWait: true});
      }
      symptoms.forEach((res, index) => {
        updateUserSymptom(
          res,
          (success, data) => {
            if (success) {
              data.symptom = res.symptom;
              updateSymptomList.push(data);
            } else {
              updateSymptomList.push(res);
            }
            if (index === symptoms.length - 1) {
              updateSymptom(updateSymptomList, () => {
                callBack();
                if (from === 'SAC') {
                  this.setState({saveWait: false});
                } else {
                  this.setState({nextWait: false});
                }
              });
            }
          },
          this.props.journalUserId,
        );
      });
    } else if (from === 'NEXT') {
      callBack();
    }
  };
  updateSymptoms = symptoms => {
    this.setState({
      symptoms: symptoms,
      inActive: !symptoms || (symptoms && symptoms.length === 0),
    });
  };

  cancel = () => {
    const {updateSymptom, closeModal, symptoms = []} = this.props;
    const symptomsfiltered = symptoms && symptoms.filter(res => res.id !== -1);
    updateSymptom(symptomsfiltered, () => {
      closeModal();
      this.hideCloseConfirmationModal();
    });
  };

  hideCloseConfirmationModal = () => {
    this.setState({visibleCloseConfirmationModal: false});
  };
  render() {
    const {
      saveWait,
      nextWait,
      symptoms,
      visibleCloseConfirmationModal,
      saveDisable
    } = this.state;
    const {tabs, setActiveTab} = this.props;
    return (
      <>
        <View style={[styles.sectionContainer, styles.sectionContainerzIndex]}>
          <View style={[styles.secMainTitle, styles.comMgBot]}>
            <Text style={[styles.textTitleExLgBold, styles.textThickGrey]} allowFontScaling={false}>
              Which symptom(s) do you experience alongside your condition?
            </Text>
          </View>
          <MessageBox
            message={
              <Text allowFontScaling={false}>
                You may track your symptoms on a scale from 0 to 10 or a simple
                Yes/No. Choose the way to measure your symptoms after selecting
                them from the dropdown search box.
              </Text>
            }
          />
          <AddSymptom updateInActive={val => this.setState({inActive: val})} />
          {(!Boolean(symptoms) || (symptoms && symptoms.length === 0)) && (
            <View
              style={[
                styles.addDefaultBox,
                styles.flexJcCtAiCt,
                styles.zIndexMin,
              ]}>
              <View style={[styles.addDefaultIcon]}>
                <Image
                  style={styles.addIcon}
                  source={require('../../../../assets/icons/add-symptom.png')}
                />
              </View>
              <View style={styles.addDefaultData}>
                <Text style={[styles.textPrimeSm, styles.textColorLight]}>
                  Add Symptom(s)
                </Text>
              </View>
            </View>
          )}

          <SymptomList updateSymptoms={this.updateSymptoms} />
        </View>

        <SymptomFooter
          back={() => {
            this.state.symptoms && this.state.symptoms.length > 0
              ? this.update(() => setActiveTab(tabs.medications), 'SAC')
              : setActiveTab(tabs.medications);
          }}
          next={() => {
            this.update(() => setActiveTab(tabs.healthData), 'NEXT');
          }}
          saveWait={saveWait}
          nextWait={nextWait}
          saveDisable={saveDisable}
        />
        <SetupCancelConfirmationBox
          visibleModal={visibleCloseConfirmationModal}
          cancel={this.hideCloseConfirmationModal}
          confirm={this.cancel}
          message="Cancel setting up your symptom?"
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    symptoms: state.setup.symptoms,
    windowWidth: state.uistate.windowWidth,
    windowHeight: state.uistate.windowHeight,
    journalUserId: state.uistate.journalUserId,
    saveDisable: state.setup.routineWizardSaveDisable?.symptom,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSymptom: (addNewSymptom, callBack) =>
      dispatch(actions.updateSymptom(addNewSymptom, callBack)),
    updateUserSymptom: (symptom, callBack, journalUserId) =>
      dispatch(actions.updateUserSymptom(symptom, callBack, journalUserId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SymptomContent);
