import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";
import UIText from "../../Text/text";
import { View, Image, TouchableOpacity, Keyboard } from "react-native";
import Question from "./Question";
import QuestionText from "./questionText";
import ConfirmationBox from "../../ConfirmationBox/confirmationBox";
import PlusButtonCom from "../../Button/plusButtonCom";

class QuestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newJournal: props.newJournal ? props.newJournal : null,
      newTherapie: props.newTherapie ? props.newTherapie : null,
      newContact: props.newContact ? props.newContact : null,
      questionList: [],
      // Modal
      showQuestionTextBox: false,
      type: props.type,

      questionId: 0,
      deleteConfirm: false,
      deleteWait: false,
      alertMessageTitle: null,
      editOption: null,
      remoteSubmit: false
    };
  }

  componentDidMount() {
    if (this.props.newJournal && this.props.type === "journal") {
      this.setState({
        questionList: this.getQuestions(
          this.props.questionDigest.userArtifactHealthQuestions,
          this.props.newJournal.artifactId
        ),
      });
    } else if (this.props.newTherapie && this.props.type === "therapy") {
      this.setState({
        questionList: this.getQuestions(
          this.props.questionDigest.userArtifactHealthQuestions,
          this.props.newTherapie.artifactId
        ),
      });
    } else if (this.props.newContact && this.props.type === "contact") {
      this.setState({
        questionList: this.getQuestions(
          this.props.questionDigest.userArtifactHealthQuestions,
          this.props.newContact.artifactId
        ),
      });
    }
  }
  getQuestions = (questions, artifactId) => {
    let questionsArray = [];
    if (questions) {
      questionsArray = questions.filter(function(question) {
        return question.artifactId === artifactId;
      });
    }

    const unique = questionsArray
      .map((e) => e["questionId"])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => questionsArray[e])
      .map((e) => questionsArray[e]);

    const questionsFilter = [];
    unique.forEach((element) => {
      questionsFilter.push(
        this.props.questionDigestProcessed.questions[element.questionId]
      );
    });

    return questionsFilter;
  };

  UNSAFE_componentWillReceiveProps(props) {
    const {
      questionDigest,
      questionDigestProcessed,
      newJournal,
      newTherapie,
      newContact,
    } = props;
    this.setState(
      {
        newJournal,
        newTherapie,
        newContact,
        questionDigest,
        questionDigestProcessed,
      },
      () => {
        let artifactId = null;
        if (newJournal && this.props.type === "journal") {
          artifactId = newJournal.artifactId;
        } else if (newTherapie && this.props.type === "therapy") {
          artifactId = newTherapie.artifactId;
        } else if (newContact && this.props.type === "contact") {
          artifactId = newContact.artifactId;
        }
        this.setState({
          questionList: this.getQuestions(
            questionDigest.userArtifactHealthQuestions,
            artifactId
          ),
        });
      }
    );
  }

  handleShowQuestionTextBox = () => {
    this.setState({ remoteSubmit: true });
    Keyboard.dismiss();
    setTimeout(() => {
      this.setState({ showQuestionTextBox: true });
    }, 300);
  };

  handleHideQuestionTextBox = () => {
    if (this.state.editOption) {
      this.setState({ editOption: null });
    } else {
      this.setState({ showQuestionTextBox: false });
    }
  };

  handleDeleteQuestion = () => {
    this.setState({ deleteWait: true });
    let artifactId = null;
    if (this.state.newJournal && this.props.type === "journal") {
      artifactId = this.state.newJournal.artifactId;
    } else if (this.state.newTherapie && this.props.type === "therapy") {
      artifactId = this.state.newTherapie.artifactId;
    } else if (this.state.newContact && this.props.type === "contact") {
      artifactId = this.state.newContact.artifactId;
    }

    // Remove from Database
    this.props.onDeleteQuestion(
      this.state.questionId,
      artifactId,
      this.props.questionDigest,
      this.props.questionDigestProcessed,
      () => {
        this.setState({
          deleteWait: false,
          deleteConfirm: false,
          alertMessageTitle: null,
        });
        // Remove from local state
        const questionList = this.state.questionList.filter(
          (res) => res.id !== this.state.questionId
        );
        this.setState({
          questionList: questionList
        }, () => {
          this.props.onUpdateQestionList(questionList);
        });
      },
      this.props.journalUserId
    );
  };

  handleDeleteQuestionConfirmation = (id) => {
    this.setState({
      questionId: id,
      deleteConfirm: true,
      alertMessageTitle: "Delete To-do?",
    });
  };

  handleOnEditQuestion = (id) => {
    this.setState({ editOption: id });
  };

  changeRemoteSubmit = (remoteSubmit, showQuestionTextBox) => {
    this.setState({ remoteSubmit , ...(showQuestionTextBox ? { showQuestionTextBox } : {})});
  };

  renderQuestions = () => {
    return this.state.questionList.map((res, i) => (
      <Question
        question={res}
        index={i}
        key={i}
        handleOnDeleteQuestion={this.handleDeleteQuestionConfirmation}
        handleOnEditQuestion={this.handleOnEditQuestion}
        editOption={this.state.editOption}
        questionList={this.state.questionList}
        handleHideQuestionTextBox={this.handleHideQuestionTextBox}
        type={this.props.type}
        bgLight={this.props.bgLight}
        scrollToEnd={this.props.scrollToEnd}
        remoteSubmit={this.state.remoteSubmit}
        changeRemoteSubmit={this.changeRemoteSubmit}
      />
    ));
  };
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    
    return (
      <React.Fragment>
        <View style={[styles.collapseContent]}>
          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              <View style={[styles.innerQuestionBlock]}>
                <View style={[styles.listFullView, styles.questionList]}>
                  {/* Question List  */}
                  {this.renderQuestions()}
                </View>
              </View>
              <View
                style={[
                  styles.addSection,
                  styles.colPd,
                  this.props.bgLight ? styles.bgThinGrey : styles.colBg,
                ]}
              >
                {Boolean(this.state.showQuestionTextBox) && (
                  <QuestionText
                    questionList={this.state.questionList}
                    showQuestionTextBox={this.state.showQuestionTextBox}
                    handleHideQuestionTextBox={this.handleHideQuestionTextBox}
                    type={this.props.type}
                    scrollToEnd={this.props.scrollToEnd}
                    remoteSubmit={this.state.remoteSubmit}
                    changeRemoteSubmit={this.changeRemoteSubmit}
                  />
                )}

                <View style={[stylesRes.addItemSecFull]}>
                  {this.props.type === "journal" ? (
                    <View
                      style={[
                        styles.btnArea,
                        styles.zIndexMin,
                        this.props.bgLight ? styles.bgThinGrey : styles.colBg,
                      ]}
                    >
                      <PlusButtonCom
                        btnPosition={"left"}
                        btnText={"Add"}
                        touchableOnPress={this.handleShowQuestionTextBox}
                      />
                    </View>
                  ) : (
                    <View
                      style={[
                        styles.addItemSecBtnSecFull,
                        this.props.bgLight ? styles.bgThinGrey : styles.colBg,
                      ]}
                    >
                      <TouchableOpacity
                        onPress={this.handleShowQuestionTextBox}
                        style={[
                          stylesRes.addItemSecBtnSec,
                          stylesRes.boxShadowBtn,
                        ]}
                        dataSet={{media: ids.addItemSecBtnSec}}
                      >
                        <UIText
                          title={"Add a to-do"}
                          style={styles.btnText}
                        />
                        <Image
                          style={styles.plusSm}
                          source={require("../../../assets/icons/plus-sm.png")}
                        />
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>
        </View>
        <ConfirmationBox
          visibleModal={this.state.deleteConfirm}
          onBackdropPress={() => {
            this.setState({ deleteConfirm: false });
          }}
          onRequestClose={() => {
            this.setState({ deleteConfirm: false });
          }}
          alertTitle={this.state.alertMessageTitle}
          handleDelete={this.handleDeleteQuestion}
          deleteWait={this.state.deleteWait}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    newJournal: state.routineTemplate.newJournal,
    questionDigest: state.routines.questionDigest,
    questionDigestProcessed: state.routines.questionDigestProcessed,
    questionList: state.routineTemplate.questionList,
    journalUserId: state.uistate.journalUserId,
    newTherapie: state.therapie.newTherapie,
    newContact: state.contacts.newContact
      ? state.contacts.newContact.data
      : null,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteQuestion: (
      healthQuestionId,
      artifactId,
      questionDigest,
      questionDigestProcessed,
      callBack,
      journalUserId
    ) =>
      dispatch(
        actions.deleteHealthQuestion(
          healthQuestionId,
          artifactId,
          questionDigest,
          questionDigestProcessed,
          callBack,
          journalUserId
        )
      ),
    onUpdateQestionList: questionList =>
      dispatch(actions.updateQestionList(questionList))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionList);
