import React, {Component} from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import * as actions from "../../store/actions/index";
import styles from '../../Css';
import Header from '../../components/Header/header';
import Tabs from '../../components/Tab/Tab';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import MyResources from './MyResources/MyResources';
import Directory from './Directory/Directory';
import {DASHBOARD, COMMUNITY_GROUPS} from '../../navigation/path';
import SavingConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import {
  alertBodySpec,
  clinicaltrialTitle,
} from '../../GlobalFunctions/GlobalString';
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import MenuOverlayContainer from '../../components/PageLayout/MenuOverlayContainer';
import {communityGroupTabPath} from '../../Constant/index';
import {pendoTrackEvent} from '../../util/pendoConfig';

const SEARCH_TAB = 1;
const MY_RESOURCES_TAB = 0;

class CommunityGroupsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
      activeTab: MY_RESOURCES_TAB,
      tagSearch: '',
      searchWait: false,
      haveChanges: false,
    };
    this.healthlibChangesRef = null;
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      const activeTab = this.props.route?.params?.healthLibraryTab;
      this.props.navigation.setParams({healthLibraryTab: activeTab ? activeTab : communityGroupTabPath.myResources});
      let pendoTrackEventName = 'HealthLibrary/@MyResources';
      let tabIndex = MY_RESOURCES_TAB;
      switch (activeTab) {
        case communityGroupTabPath.directory:
          pendoTrackEventName = 'HealthLibrary/@Directory';
          tabIndex = SEARCH_TAB;
          break;
        case communityGroupTabPath.searchResults:
          pendoTrackEventName = 'HealthLibrary/@SearchResults';
          tabIndex = SEARCH_TAB;
          break;
        default:
          break;
      }
      this.setState({activeTab: tabIndex});
      pendoTrackEvent(pendoTrackEventName);
    });
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth} = props;

    if (
      props.windowHeight !== state.windowHeight ||
      props.windowWidth !== state.windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }
    return null;
  }

  goBackfunction = () => {
    if (this.state.activeTab === SEARCH_TAB && this.healthlibChangesRef?.isSearchOn?.()) {
      if (this.healthlibChangesRef?.isSearchFromMyResources?.()) {
        this.setState({activeTab: MY_RESOURCES_TAB}, this.props.resetSearch());
      } else {
        this.props.popPreviousSearchResult();
      }
    } else {
      if (
        this.props.route?.params &&
        this.props.route?.params.from
      ) {
        this.props.route?.params.from ===
        COMMUNITY_GROUPS.shareResourcePath
          ? this.props.navigation.reset({
            index: MY_RESOURCES_TAB,
            routes: [{name: DASHBOARD.path}],
          })
          : handleCommonBackNavigation(this.props.navigation);
      } else {
        handleCommonBackNavigation(this.props.navigation);
      }
    }
  };

  handleChanges = (value) => {
    if (this.state.haveChanges !== value) {
      this.setState({haveChanges: value});
    }
  }

  render() {
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => {
              this.goBackfunction();
          }}
          index={COMMUNITY_GROUPS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar/>
        <View style={[styles.gridSectionView]}>
          <Tabs
            activeTab={this.state.activeTab}
            tabIndex={index => {
              this.setState({
                activeTab: index,
              });
              if (index === MY_RESOURCES_TAB) {
                pendoTrackEvent('HealthLibrary > @MyResources')
                this.props.navigation.setParams({healthLibraryTab: communityGroupTabPath.myResources});
                pendoTrackEvent('HealthLibrary/@MyResources');
              } else if (index === SEARCH_TAB && this.state.tagSearch) {
                pendoTrackEvent('HealthLibrary > @Directory');
                this.props.navigation.setParams({healthLibraryTab: communityGroupTabPath.searchResults});
                pendoTrackEvent('HealthLibrary/@SearchResults');
              } else {
                pendoTrackEvent('HealthLibrary > @Directory');
                this.props.navigation.setParams({healthLibraryTab: communityGroupTabPath.directory});
                pendoTrackEvent('HealthLibrary/@Directory');
              }
            }}
            pageContainer={true}
            smScreenFull={true}>
            {/* My Resources tab */}
            <View
              title="My Resources"
              style={[styles.tabContentSection]}
              nativeId={'healthLibraryMyResources'}
            >
              <MyResources
                navigation={this.props.navigation}
                activeTab={tagSearchResultsArr => {
                  if (tagSearchResultsArr && tagSearchResultsArr[MY_RESOURCES_TAB]) {
                    this.setState({
                      activeTab: SEARCH_TAB,
                      tagSearch: tagSearchResultsArr[MY_RESOURCES_TAB].tag,
                    });
                  } else {
                    this.setState({
                      activeTab: SEARCH_TAB,
                      tagSearch: '',
                    });
                  }
                }}
                route={this.props.route}
              />
            </View>
            {/* Directory tab */}
            <View
              title="Community Search"
              style={[styles.tabContentSection]}
              nativeId={'healthLibraryDirectory'}
            >
              <Directory
                activeTab={this.state.activeTab}
                getRef={ref => (this.healthlibChangesRef = ref)}
                setSearchWait={(searchWait) => {
                  this.setState({
                    searchWait: searchWait
                  });
                }}
                handleChanges={this.handleChanges}
                navigation={this.props.navigation}
              />
            </View>
          </Tabs>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    showLearnHow: state.uistate.showLearnHow,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    popPreviousSearchResult: () => {
      dispatch(actions.popPreviousSearchResult());
    },
    resetSearch: () => {
      dispatch(actions.resetCommunitySearch());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityGroupsScreen);
