import React from "react";
import { Image, View, Text, TouchableOpacity } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import * as comTitles from "./titles";
import Warning from "../Warning/warning";
import PageLayout from "../PageLayout/pageLayout";
import PrimaryButton from "../Button/primaryButton";
import InputField from "../TextInput/inputField";
import * as FieldDetails from "../TextInput/fieldDetails";

const stylesRes = stylesResponsive.getProcessedStyles().styles;
const ids = stylesResponsive.getProcessedStyles().ids;
const resetpasswordview = (props) => {
  return (
    <PageLayout
      windowHeightView={props.windowHeightView}
      windowHeightSmView={props.windowHeightSmView}
      windowWidthView={props.windowWidthView}
      bgBlue={props.bgBlue}
      contentCenter={false}
      bgStyle={true}
    >
      <View style={[styles.boxWrapperArea, styles.colMgLtRt]}>
        <View 
          style={[stylesRes.boxWrapperView, stylesRes.wrapperShadow]}
          dataSet={{media: ids.boxWrapperView}}>
          <View style={[styles.secArea]}>
            <View style={stylesRes.secPdSm}>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View
                    style={[
                      styles.titleBlock,
                      styles.flexRowJcCtAiCt,
                      styles.noPdTop,
                    ]}
                  >
                    <Text
                      style={[
                        styles.textAlignCenter,
                        styles.textTitleExLg,
                        styles.textThickGrey,
                      ]}
                      allowFontScaling={false}
                    >
                      <Text style={[styles.bold]} allowFontScaling={false}>Forgot </Text>Password?
                    </Text>
                  </View>
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.topImgSecCom}>
                    <View style={stylesRes.topImgSpecCom}>
                      <Image
                        style={styles.passIconSmCom}
                        source={require("../../assets/reset-password/forgot-password.png")}
                      />
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={[styles.gridColFull]}>
                  <View style={stylesRes.descTextComSm}>
                    <View
                      style={[
                        styles.textSubCom,
                        styles.infoPdLg,
                        styles.secBgPdTop,
                      ]}
                    >
                      <Text
                        style={[
                          styles.textPrime,
                          styles.textAlignCenter,
                          styles.textTertiary,
                        ]}
                        allowFontScaling={false}
                      >
                        We will email you{" "}
                        <Text style={[styles.bold]}>
                          a password reset verification code to your email
                        </Text>
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={[styles.gridColFull]}>
                  <View>
                    <View
                      style={[
                        styles.textSubCom,
                        styles.infoPdBotMd,
                        styles.flexRowJcCtAiCt,
                      ]}
                    >
                      <View
                        style={[styles.flexRowJcCtAiCt, styles.textSubDetail]}
                      >
                        <View style={styles.textIconSmWrapper}>
                          <Image
                            style={styles.envelopeIcon}
                            source={require("../../assets/icons/envelope.png")}
                          />
                        </View>

                        <View style={styles.textDescWrapper}>
                          <Text style={[styles.textPrime]} allowFontScaling={false}>
                            Please enter the{" "}
                            <Text style={[styles.bold]} allowFontScaling={false}>email address </Text>
                            associated with your account.
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.fieldContainer}>
                    <View style={[styles.fieldRowWrapper]}>
                      <View style={styles.fieldColStretch}>
                        <InputField
                          title={comTitles.EMAIL_ADDRESS}
                          fieldBg={FieldDetails.BGWHITE}
                          fieldRg={true}
                          requirdStar={true}
                          invalid={!props.emailValidate}
                          onChangeText={props.onChangeEmail}
                          value={props.email}
                          autoFocus={true}
                          underlineColorAndroid="transparent"
                        />
                      </View>
                    </View>
                    {/* {!props.emailValidate && (
                    <ValidationMessage
                      errorMessage={'Please enter a valid email address'}
                    />
                  )} */}
                  </View>
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={[styles.gridColFull, styles.errBoxView]}>
                  {Boolean(props.error) && (
                    <Warning
                      errorDescription={props.errorDescription}
                      textPosition={"left"}
                    />
                  )}
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={[styles.btnArea, styles.btnPdTopMini]}>
                    <View
                      style={styles.fieldBtn}
                      ref={(component) => (props._root = component)}
                    >
                      <PrimaryButton
                        btnText={"Send"}
                        btnPosition={"full"}
                        activityInd={props.authSuccessSpinner}
                        btnShadow={true}
                        btnLg={true}
                        touchableOnPress={props.onPressResetEmail}
                        disabled={props.authSuccessSpinner}
                        btnStyle={styles.submitBgBlueDark}
                      />
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View
                    style={[
                      styles.contCenterView,
                      styles.secBgPdTop,
                      styles.colPdBot,
                    ]}
                  >
                    <Text style={[styles.textPrime]} allowFontScaling={false}>Back to </Text>
                    <TouchableOpacity onPress={props.onPressLogin} disabled={props.authSuccessSpinner}>
                      <Text style={[styles.textPrimeBold, styles.resourceBlueTxt]} allowFontScaling={false}>
                        Login Page
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </PageLayout>
  );
};

export default (resetpasswordview);
