import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import styles from '../../../Css';
import { pendoTrackEvent } from '../../../util/pendoConfig';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

const logoutMenuItem = props => {
  const {onPress, tabMenuIconView, resetFilteringData, resetFilteringObjectData, resetCommunitySearch} = props;
  return (
    <View style={[
      tabMenuIconView 
      ? styles.logoutMenuSection
      : styles.logOutMenuSectionLg
      ]}>
      <View 
        nativeID={'menuLogOut'}
        style={[
          styles.logoutMenuWrapper, 
          styles.secSepBorderTop,
          styles.secSepBorderBot
        ]}
      >
        <TouchableOpacity 
          style={[
            styles.logoutMenuDetails,
            !tabMenuIconView && styles.contColFul
          ]} 
          onPress={() => {
            onPress();
            pendoTrackEvent('Menu > LogOut');
            resetFilteringData();
            resetFilteringObjectData();
            resetCommunitySearch();
          }}
        >
          {Boolean(tabMenuIconView) && (
          <View style={[styles.logoutMenuView]}>
            <Image
              style={styles.logoutMenuIcon}
              source={require('../../../assets/main-nav/logout.png')}
            />
          </View>
          )}
          {!Boolean(tabMenuIconView) && (
          // <View style={[styles.logoutMenuTitle]}>
          //   <Text style={[styles.textPrimeBold, styles.textAlignCenter, styles.textOrange]} allowFontScaling={false}>
          //     LOG OUT
          //   </Text>
          // </View>
          <View style={[styles.logOutBtnText, styles.flexAiCt, styles.flexJcCt]}>
             <Text style={[styles.textTitleBold, styles.textAlignCenter]} allowFontScaling={false}>
              LOG OUT
             </Text>
          </View>
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};

const mapStateToProps = state => {
  return { };
};

const mapDispatchToProps = dispatch => {
  return {
      resetFilteringData: () => dispatch(actions.resetFilteringData()),
      resetFilteringObjectData: () => dispatch(actions.resetFilteringObjectData()),
      resetCommunitySearch: () => dispatch(actions.resetCommunitySearch()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(logoutMenuItem);
