import {StyleSheet} from 'react-native';

export default StyleSheet.create({

  surveyQuestionBanner: {
    height: 164,
    width: '100%',
  },

  surveyQuestionBannerWrapper: {
    flex: 1,
  },


});