import React, {Component} from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import styles from '../../Css';
import Therapy from './ReportGraphTherapy';
import { chartTypes } from '../../Constant/index';
import {sortAlphabeticalOrder} from '../../util/commonUiLogic';

class Therapies extends Component {
  renderTreatments = () => {
    let treatments = [];
    if (this.props.type && this.props.type === "activity") {
      treatments = this.props.treatments.filter(
        item => item.name && item.displayProperties && item.treatmentType === 5,
      );
      sortAlphabeticalOrder(treatments);
    } else {
      treatments = this.props.treatments.filter(
        item => item.name && item.displayProperties && item.treatmentType !== 5,
      );
      sortAlphabeticalOrder(treatments);
    }
    
    return treatments.map(item => (
      <Therapy item={item} key={'tr_' + item.id} configChartType={chartTypes.bar}/>
    ));
  };
  render() {
    return (
      <View style={[styles.collapseContent]}>
        <View style={[styles.collapseDescription]}>
          <View style={[styles.collapseInContent, styles.colPdLtRt]}>
            <View style={styles.secSubContentArea}>
              {this.renderTreatments()}
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    treatments: state.routines.treatments,
  };
};
export default connect(
  mapStateToProps,
  null,
)(Therapies);
