import * as axiosCommon from "../../axios/axios-common";
import * as actionTypesUserConsentForms from "./actionTypesUserConsentForms";
import { logout } from "./index";

export const SUBMIT_CONSENT_FORM_INITIAL_DATA = '/api/userscheduledsurvey/:surveyId/:notificationId/consentForm';
export const CONFIRM_CONSENT_FORM_DATA = '/api/userscheduledsurvey/:surveyId/:notificationId/consentForm/:consentId/confirm';

const getSubmitConsentFormForSurveysUrl = (surveyId, notificationId) => {
  return SUBMIT_CONSENT_FORM_INITIAL_DATA.replace(":surveyId", surveyId).replace(":notificationId", notificationId);
};

const getConfirmConsentFormUrl = (surveyId, notificationId, consentId) => {
  return CONFIRM_CONSENT_FORM_DATA.replace(":surveyId", surveyId).replace(":notificationId", notificationId).replace(":consentId", consentId);
};


export const submitConsentFormInitialDataForSurvey = (surveyId, notificationId, consentFormIndex, consentFormData, callBack) => {
  let requestUrl = getSubmitConsentFormForSurveysUrl(surveyId, notificationId);
  return (dispatch, getState) => {
    const _journalUserId = getState().uistate?.journalUserId || null;
    if (_journalUserId) {
      requestUrl = `${requestUrl}?journalUserId=${_journalUserId}`
    }

    dispatch(submitConsentFormInitialDataStart(consentFormIndex));

    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          dispatch(submitConsentFormInitialDataSuccess(consentFormIndex, res.responseDto.data));
          callBack(true, res.responseDto.data);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; submitConsentFormInitialDataForSurvey");
            dispatch(logout());
          } else {
            dispatch(submitConsentFormInitialDataFail(res.errorDto.data.message));
            callBack(false, res.errorDto.data.message);
          }
        }
      },
      {
        userConsent: consentFormData
      }
    );
  };
};

const submitConsentFormInitialDataStart = () => {
  return {
    type: actionTypesUserConsentForms.SUBMIT_CONSENT_FORM_INITIAL_DATA_START,
  };
};

const submitConsentFormInitialDataSuccess = (consentFormIndex, data) => {
  return {
    type: actionTypesUserConsentForms.SUBMIT_CONSENT_FORM_INITIAL_DATA_SUCCESS,
    consentFormIndex: consentFormIndex,
    data: data,
  };
};

const submitConsentFormInitialDataFail = (errorMessage) => {
  return {
    type: actionTypesUserConsentForms.SUBMIT_CONSENT_FORM_INITIAL_DATA_FAIL,
    error: errorMessage
  };
};

export const submitConsentFormConfirmation = (surveyId, notificationId, consentFormIndex, consentFormData, callBack) => {
  return (dispatch, getState) => {
    const _journalUserId = getState().uistate?.journalUserId || null;
    let requestUrl = getConfirmConsentFormUrl(surveyId, notificationId, consentFormData.id);
    if (_journalUserId) {
      requestUrl = `${requestUrl}?journalUserId=${_journalUserId}`
    }

    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          dispatch(submitConsentFormConfirmationSuccess(consentFormIndex, res.responseDto.data));
          callBack(true, res.responseDto.data);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; submitConsentFormConfirmation");
            dispatch(logout());
          } else {
            dispatch(submitConsentFormConfirmationFail(res.errorDto.data.message));
            callBack(false, res.errorDto.data.message);
          }
        }
      },
      {}
    );
  };
};

const submitConsentFormConfirmationSuccess = (consentFormIndex, data) => {
  return {
    type: actionTypesUserConsentForms.SUBMIT_CONSENT_FORM_CONFIRMATION_SUCCESS,
    consentFormIndex: consentFormIndex,
    data: data,
  };
};

const submitConsentFormConfirmationFail = (errorMessage) => {
  return {
    type: actionTypesUserConsentForms.SUBMIT_CONSENT_FORM_CONFIRMATION_FAIL,
    error: errorMessage
  };
};

export const incrementNextConsentFormIndex = () => {
  return {
    type: actionTypesUserConsentForms.INCREMENT_CONSENT_FORM_INDEX
  };
};