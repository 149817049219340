export const DEVICE_TYPES = {
    HEXOSKIN: 'HEXOSKIN',
    APPLEWATCH: 'APPLEWATCH',
    GARMIN: 'GARMIN',
    FITBIT: 'FITBIT',
  };

export const CONNECTION_STATUS = {
    CONNECTED: 'CONNECTED',
    NOT_CONNECTED: 'NOT_CONNECTED',
    DISCONNECTED: 'DISCONNECTED',
  };