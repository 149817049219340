import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Keyboard, Platform} from 'react-native';
import ViewProfile from '../../components/Profile/viewProfile';
import moment from 'moment';
import * as actions from '../../store/actions/index';
import {
  getCurrentSubscription,
  getAccountType,
  getSubscriptionMadeBy,
  getSubscriptionPlanDescription,
  getSubscriptionType,
  getNextBillingDate,
} from '../../util/subscriptionInformation';
import {DASHBOARD, PROFILE, CAREGIVERS, OTHER} from '../../navigation/path';
import LearnHowPrivacyPolicy from '../SignUp/LearnHowPrivacyPolicy';
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../util/pendoConfig';
import AccountDeleteConfirm from '../../components/ConfirmationBox/accountDeleteConfirm';
import DeleteAccountSuccess from '../../components/ConfirmationBox/deleteAccountSuccess';
import { IOS } from '../../util/platformWindowConfig';

class ProfileScreen extends Component {
  constructor(props) {
    super(props);
    const {applicationSettingsDigest} = props;
    const {uploadedFileSize} = applicationSettingsDigest;

    this.state = {
      firstName: (props.user || {}).firstName,
      lastName: (props.user || {}).lastName,
      gender: props.user.sex
        ? this.lowerCaseAllWordsExceptFirstLetters(props.user.sex)
        : null,
      dob:
        props.profile && props.profile.onlyYear
          ? moment.utc(props.user.birthday, 'x').format('YYYY')
          : props.user.birthday && props.user.birthday !== 0
          ? moment.utc(props.user.birthday, 'x').format('DD MMM, YY')
          : null,
      height: null,
      weight: null,
      weightUomSymbol: null,
      heightUomSymbol: null,
      ethnicity: (props.user || {}).ethnicity,
      city: (props.user || {}).city,
      state: (props.user || {}).state,
      country: (props.user || {}).country,
      accountType: getAccountType(props.userSubscription),
      currentSubscription: getCurrentSubscription(props.userSubscription),
      loading: true,
      showLearnHowPrivacyPolicy: false,
      isScrollDisabled: true,
      totalUsageByte: uploadedFileSize,
      biometricToggle: false,
      showDeleteConfirm: false,
      showDeleteSuccess: false,
      didUserConfirm: false,
      confText: '',
      timeZoneList: [],
      timeZoneUpdating: false,
    };
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      this.props.getAllTimeZoneList(
        (success, res) => {
          if (success) {
            this.setState({
              timeZoneList: res,
            })
          }
        }
      );
      console.log('TIME_ZONE', this.props.timeZone)


      this.setState({loading: true});

      this.getLetastJournal();
      setTimeout(() => this.setState({loading: false}), 1500);
      if (Platform.OS === IOS) {
        this.props.fetchIosSubscriptionDetails(success => {},
        this.props.journalUserId);
      }
      pendoTrackEvent('Profile@');
    });

  }

  updateTimeZone = timeZone => {
    const {updateTimeZone, updateProfile, user, showSuccessPopup, setSeenTimeZoneWarning, journalUserId} = this.props;
    this.setState({timeZoneUpdating: true});
    const data = {
      timeZone
    }
    updateTimeZone(
      timeZone,
      (succes)=>{
        if(succes){
          updateProfile({
            user: {
              ...user,
              timezone: timeZone
            }
          })
          showSuccessPopup(17, null, data)
          setSeenTimeZoneWarning(false);
        }
        this.setState({timeZoneUpdating: false});
      },
      journalUserId
    );
  }

  disabledScroll = (val, callBack) => {
    this.setState({isScrollDisabled: val}, () => {
      if (callBack) {
        callBack();
      }
    });
  };
  lowerCaseAllWordsExceptFirstLetters = string => {
    return string.replace(/\w\S*/g, function(word) {
      return (
        word.charAt(0) +
        word
          .slice(1)
          .toLowerCase()
          .replace(/_/g, ' ')
      );
    });
  };

  getHightMetrixUser = () =>
    this.props.metricsDigest.userDefinedMetrics.filter(function(res) {
      return res.name === 'Height';
    });

  getHightMetrixSystem = () =>
    this.props.metricsDigest.systemDefinedMetrics.filter(function(res) {
      return res.name === 'Height';
    });

  getWeightMetrixUser = () =>
    this.props.metricsDigest.userDefinedMetrics.filter(function(res) {
      return res.name === 'Weight';
    });

  getWeightMetrixSystem = () =>
    this.props.metricsDigest.systemDefinedMetrics.filter(function(res) {
      return res.name === 'Weight';
    });

  getLetastJournal = () => {
    const hightMetrixUser = this.getHightMetrixUser();

    const hightMetrixSystem = this.getHightMetrixSystem();

    const weightMetrixUser = this.getWeightMetrixUser();

    const weightMetrixSystem = this.getWeightMetrixSystem();

    let journalEntriesHeight = [];
    let journalEntriesWeight = [];
    Object.values(this.props.journalEntriesProcessed).filter(function(res) {
      if (res && res.metrics) {
        res.metrics.filter(function(re) {
          if (
            hightMetrixUser &&
            hightMetrixUser.length > 0 &&
            re.metric === hightMetrixUser[0].id
          ) {
            journalEntriesHeight.push(res);
          } else if (
            hightMetrixSystem &&
            hightMetrixSystem.length > 0 &&
            re.metric === hightMetrixSystem[0].id
          ) {
            journalEntriesHeight.push(res);
          }
          if (
            weightMetrixUser &&
            weightMetrixUser.length > 0 &&
            re.metric === weightMetrixUser[0].id
          ) {
            journalEntriesWeight.push(res);
          } else if (
            weightMetrixSystem &&
            weightMetrixSystem.length > 0 &&
            re.metric === weightMetrixSystem[0].id
          ) {
            journalEntriesWeight.push(res);
          }
          return journalEntriesWeight && journalEntriesHeight;
        });
      }
      return journalEntriesHeight && journalEntriesWeight;
    });
    if (journalEntriesHeight && journalEntriesHeight.length > 0) {
      journalEntriesHeight.sort(function(a, b) {
        return b.modifiedOn - a.modifiedOn;
      });
      this.getHight(journalEntriesHeight[0]);
    }

    if (journalEntriesWeight && journalEntriesWeight.length > 0) {
      journalEntriesWeight.sort(function(a, b) {
        return b.modifiedOn - a.modifiedOn;
      });
      this.getWeight(journalEntriesWeight[0]);
    }
  };

  getHight = journal => {
    const hightMetrixUser = this.getHightMetrixUser();

    const hightMetrixSystem = this.getHightMetrixSystem();

    if (journal && journal.metrics) {
      let metrics = [];
      journal.metrics.filter(function(res) {
        if (
          hightMetrixUser &&
          hightMetrixUser.length > 0 &&
          res.metric === hightMetrixUser[0].id
        ) {
          metrics.push(res);
        } else if (
          hightMetrixSystem &&
          hightMetrixSystem.length > 0 &&
          res.metric === hightMetrixSystem[0].id
        ) {
          metrics.push(res);
        }
        return metrics;
      });

      if (metrics && metrics[0]) {
        this.setState({
          height: metrics[0].value ? metrics[0].value : null,
          heightUomSymbol:
            metrics[0].unitOfMeasure &&
            this.props.unitsOfMeasureDigestProcessed.unitsOfMeasure[
              metrics[0].unitOfMeasure
            ].symbol
              ? this.props.unitsOfMeasureDigestProcessed.unitsOfMeasure[
                  metrics[0].unitOfMeasure
                ].symbol
              : null,
        });
      }
    }
  };

  getWeight = journal => {
    const weightMetrixUser = this.getWeightMetrixUser();
    const weightMetrixSystem = this.getWeightMetrixSystem();
    if (journal && journal.metrics) {
      let metrics = [];
      journal.metrics.filter(function(res) {
        if (
          weightMetrixUser &&
          weightMetrixUser.length > 0 &&
          res.metric === weightMetrixUser[0].id
        ) {
          metrics.push(res);
        } else if (
          weightMetrixSystem &&
          weightMetrixSystem.length > 0 &&
          res.metric === weightMetrixSystem[0].id
        ) {
          metrics.push(res);
        }
        return metrics;
      });

      if (metrics && metrics[0]) {
        this.setState({
          weight: metrics[0].value ? metrics[0].value : null,
          weightUomSymbol:
            metrics[0].unitOfMeasure &&
            this.props.unitsOfMeasureDigestProcessed.unitsOfMeasure[
              metrics[0].unitOfMeasure
            ].symbol
              ? this.props.unitsOfMeasureDigestProcessed.unitsOfMeasure[
                  metrics[0].unitOfMeasure
                ].symbol
              : null,
        });
      }
    }
  };

  redirectEditProfile = () => {
    pendoTrackEvent('Profile@ > Edit');
    this.props.navigation.push(PROFILE.profileEditPath);
  };

  redirectCaregiverAccess = () => {
    this.props.navigation.push(CAREGIVERS.path);
  };

  redirectMyDevices = () => {
    this.props.navigation.push(PROFILE.profileMyDevicesPath);
  };

  redirectMeasurements = () => {
    pendoTrackEvent('Profile@ > Measurements');
    this.props.navigation.push(PROFILE.profileMeasurementsPath);
  };

  redirectPremiumAccount = () => {
    this.props.navigation.push(PROFILE.profilePremiumAccountPath);
  };

  redirectCancelSubscription = () => {
    this.props.navigation.push(PROFILE.profileCancelSubscriptionPath);
  };

  handleOnPressBackButton = () => {
    if(this.props.route?.params && 
        this.props.route?.params.from &&
          this.props.route?.params.from === PROFILE.profileEditPath) 
    {
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: DASHBOARD.path }],
      });
    } else {
      handleCommonBackNavigation(this.props.navigation);
    }
  };

  onSignOutPress = () => {
    this.props.onSignOutButtonPress();
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: OTHER.loginPath }],
    });
  };

  onPressDelete = () => this.showDeleteConfirm();

  onPressDeleteConfirm = () => {
    Keyboard.dismiss();
    this.props.deleteAccount(() => {
      this.setState(
        {
          showDeleteConfirm: false,
          didUserConfirm: false,
          confText: '',
        },
        () => {
          this.setState({
            showDeleteSuccess: true,
          });
        },
      );
    });
  };

  showDeleteConfirm = () =>
    this.setState({
      showDeleteConfirm: true,
      didUserConfirm: false,
      confText: '',
    });

  hideDeleteConfirm = () => this.setState({showDeleteConfirm: false});

  validateUserConf = input => {
    if (input === 'YES') this.setState({didUserConfirm: true, confText: input});
    else this.setState({didUserConfirm: false, confText: input});
  };

  successDelete = () => {
    this.props.deleteAccountSuccess();
    this.setState({
      showDeleteSuccess: false,
    });
  };


  render() {
    return (
      <>
        <ViewProfile
          gender={this.state.gender}
          dob={this.state.dob}
          redirectEditProfile={this.redirectEditProfile}
          onSignOutPress={this.onSignOutPress}
          redirectMyDevices={this.redirectMyDevices}
          redirectCaregiverAccess={this.redirectCaregiverAccess}
          redirectMeasurements={this.redirectMeasurements}
          ethnicity={this.state.ethnicity}
          height={this.state.height}
          weight={this.state.weight}
          weightUomSymbol={this.state.weightUomSymbol}
          heightUomSymbol={this.state.heightUomSymbol}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          userId={this.props.user.id}
          city={this.state.city}
          state={this.state.state}
          country={this.state.country}
          handleOnPressBackButton={this.handleOnPressBackButton}
          redirectPremiumAccount={this.redirectPremiumAccount}
          accountType={this.state.accountType}
          currentSubscription={this.state.currentSubscription}
          redirectCancelSubscription={this.redirectCancelSubscription}
          toggleLearnHow={() =>
            this.setState({showLearnHowPrivacyPolicy: true})
          }
          journalUserId={this.props.journalUserId}
          loading={this.state.loading}
          showSuccessPopup={this.props.showSuccessPopup}
          hasNotch={this.props.hasNotch}
          index={PROFILE.index}
          navigation={this.props.navigation}
          route={this.props.route}
          scrollEnabled={this.state.isScrollDisabled}
          disabledScroll={this.disabledScroll}
          subscriptionMadeByDes={getSubscriptionMadeBy(
            this.state.currentSubscription,
          )}
          subscriptionType={getSubscriptionType(this.state.currentSubscription)}
          nextBillingDate={getNextBillingDate(this.state.currentSubscription)}
          subscriptionPlanDescription={getSubscriptionPlanDescription(
            this.state.currentSubscription,
          )}
          subscriptionMadeBy={
            this.state.currentSubscription
              ? this.state.currentSubscription.subscriptionMadeBy
              : null
          }
          totalUsageByte={this.state.totalUsageByte}
          subscriptionIsCancel={
            this.state.currentSubscription
              ? this.state.currentSubscription.cancel
              : null
          }
          changeBiometricToggle={() => {
            this.setState({biometricToggle: true});
          }}
          biometricToggle={this.state.biometricToggle}
          onPressDelete={this.onPressDelete}
          timeZoneList={this.state.timeZoneList}
          selectedTimeZone={this.props.timeZone}
          processing={this.state.timeZoneUpdating}
          updateTimeZone={this.updateTimeZone}
        />
        {this.state.showDeleteConfirm && (
          <AccountDeleteConfirm
            visibleModal={this.state.showDeleteConfirm}
            onBackdropPress={this.hideDeleteConfirm}
            onRequestClose={this.hideDeleteConfirm}
            handleInputConf={this.validateUserConf}
            didUserConfirm={this.state.didUserConfirm}
            onPressDelete={this.onPressDeleteConfirm}
            pushWait={this.props.deleteWait}
            confText={this.state.confText}
          />
        )}
        {this.state.showDeleteSuccess && (
          <DeleteAccountSuccess
            visibleModal={this.state.showDeleteSuccess}
            onBackdropPress={this.successDelete}
            onRequestClose={this.successDelete}
            onPressOkay={this.successDelete}
          />
        )}
        {Boolean(this.state.showLearnHowPrivacyPolicy) &&
          <LearnHowPrivacyPolicy
            showLearnHowPrivacyPolicy={this.state.showLearnHowPrivacyPolicy}
            toggleLearnHowPrivacyPolicy={() => {
              this.setState({showLearnHowPrivacyPolicy: false});
            }}
          />
        }
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.routines.user,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    metricsDigest: state.routines.metricsDigest,
    userSubscription: state.routines.userSubscription,
    journalUserId: state.uistate.journalUserId,
    hasNotch: state.uistate.hasNotch,
    profile: state.routines.peer2peer,
    deleteWait: state.account.deleteWait,
    timeZone: state.routines.user.timezone,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSignOutButtonPress: () => dispatch(actions.logout()),
    toggleLearnHow: () => dispatch(actions.showLearnHow()),
    showSuccessPopup: (id, graphId, deviceData) => dispatch(actions.showSuccessPopup(id, graphId, deviceData)),
    fetchIosSubscriptionDetails: (successCallback, journalUserId) =>
      dispatch(
        actions.fetchIosSubscriptionDetails(successCallback, journalUserId),
      ),
    deleteAccount: callBack => dispatch(actions.deleteAccount(callBack)),
    deleteAccountSuccess: () => {
      dispatch(actions.deleteAccountSuccess());
    },
    getAllTimeZoneList: (callBack) =>
      dispatch(actions.getTimeZoneList(callBack)),
    updateTimeZone: (timeZone, callBack, journalUserId) =>
      dispatch(actions.updateTimeZone(timeZone, callBack, journalUserId)),
    updateProfile: profile =>
      dispatch(actions.updatePro(profile)),
    setSeenTimeZoneWarning: () => dispatch(actions.setSeenTimeZoneWarning()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileScreen);
