import React from "react";
import { Text } from "react-native";
import styles from "../../Css";

const helpSurvey = () => {
  return (
    <Text style={styles.textPrime}  allowFontScaling={false}>
        Surveys provide researchers with anonymous info. Your experience can lead to better therapies for people like you. 
    </Text>
  );
};

export default helpSurvey;
