

import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
} from "react-native";
import { connect } from "react-redux";

import { MANAGE_DEVICES } from "../../navigation/path";
import Header from "../../components/Header/header";
import SpaceBar from "../../components/SpaceBar/SpaceBar";
import PageContainer from "../../components/PageLayout/PageContainer";
import DeviceList from "./DeviceList";
import CommonModal from "../../components/CustomModal/CommonModal";
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../util/pendoConfig';
import DeviceDisconnectedModal from "./deviceDisconnectedModal";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";

import { CONNECTION_STATUS } from "./Constant";
import * as actions from '../../store/actions/index';
import {AUTH_CONFIGS} from "../../util/auth_config";
import { Fragment } from "react";

const ManageDevicesCommon = ( props ) =>{
  const {deviceMap, connectHexoskinDeviceWithAccessCode, showSuccessPopup, updateJournalEntryTypeSuccess, hideMobileMenu, onDisconnect, updateDeviceMap, showMobileMenu, changeDeviceWithAuth0,
    route, navigation, disconnectedDeviceName, visibleDisconnectModal, setVisibleDisconnectModal, doLogout, accessCode, isAuthenticated, type } = props;
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const [deviceName, setDeviceName] = useState(null);
  const [deviceConnected, setDeviceConnected] = useState(false);
  const [deviceConnecting, setDeviceConnecting] = useState(false);

  const connectToAuth = async () => {
    let params = route.params;
    let deviceName = params?.deviceName || 'HEXOSKIN';
    let deviceStatus = deviceMap.filter(item => item.deviceName === deviceName)?.[0]?.connectionStatus;
    let deviceObj = null;   

    if (deviceName && !getConnectionStatus(deviceStatus) && isAuthenticated) {
      setDeviceName(deviceName);
      setDeviceConnecting(true);
      deviceObj = {
        deviceName: deviceName,
        code: accessCode,
        type: type,
        redirectUri: AUTH_CONFIGS.appOrigin
      }
      
      let deviceData = {
        title: 'Hexoskin is now connected!',
        success: true,
        fail: false,
        bodyTitle: false,
        body: 'Zamplo will recieve a daily average of your health data and create an automatic entry.',
        btnText: 'Got it!',
        action: 'GOTIT', 
        device: 'HEXOSKIN',
      }

      connectHexoskinDeviceWithAccessCode(
        deviceObj,
        (success, data) => {
        if (success) {
          showSuccessPopup(13, null, deviceData);
          updateDeviceMapStatus(deviceName, true);
          data.journalEntryTypes && data.journalEntryTypes.forEach(item => {
              updateJournalEntryTypeSuccess(item);
          })
        } else {
          console.log("something went wrong");
        }
        setDeviceName(null);
        setDeviceConnecting(false)
        },
      );
    }
    hideMobileMenu();
    pendoTrackEvent('ManageDevices@');
  }

  useEffect(() => {
    if(accessCode) {
      connectToAuth();
    }
  }, [accessCode, isAuthenticated])

  const onDisconnectDevice = (deviceName) => {
    setDeviceName(deviceName);
    setDeviceConnecting(true)
    let deviceData = {
      title: '',
      success: true,
      fail: false,
      bodyTitle: 'Device Disconnected',
      body: 'The Hexoskin routine has been removed from your Zamplo account.',
      btnText: null,
      action: null,
    }

    onDisconnect(
      deviceName,
      (success, data) => {
        if (success) {
          updateDeviceMapStatus(deviceName, false);
          data.journalEntryTypes && data.journalEntryTypes.forEach(item => {
            updateJournalEntryTypeSuccess(item);
          })
          setVisibleDisconnectModal(false)
          setTimeout(() => {
            showSuccessPopup(13, null, deviceData);
          }, 500);
          doLogout();
        } else {
          console.log("something went wrong")
          }
        setDeviceName(deviceName)
        setDeviceConnecting(false)
      },
    );
  }
  
  const updateDeviceMapStatus = (deviceName, status) => {
    updateDeviceMap(deviceName, status);
  }

  const getConnectionStatus = status => {
    switch (status) {
      case CONNECTION_STATUS.CONNECTED:
        return true;
      case CONNECTION_STATUS.NOT_CONNECTED:
        return false;
      case CONNECTION_STATUS.DISCONNECTED:
        return false;
      default:
        return true;
    }
  }

  useEffect(() => {
    showMobileMenu();
  },[])

  return (
    <>
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          navigation={navigation}
          route={route}
          index={MANAGE_DEVICES.index}
          handleOnPressBackButton={() => {
            handleCommonBackNavigation(navigation);
          }}
        />
        <SpaceBar />
        <ScrollView contentContainerStyle={[styles.contentAreaViewApp]}>
          <View style={[styles.bgDevice, styles.deviceMainSec]}>
            <PageContainer outlookScreen={true}>
              <View style={stylesRes.manageDeviceTxtAlignPd} dataSet={{media: ids.manageDeviceTxtAlignPd}}>
                <View style={styles.gridColFull}>
                  <View style={styles.secArea}>
                    <View style={[styles.secDeviceArea]}>
                      <View
                        style={[
                          styles.secDeviceTitleArea,
                          styles.infoPdBotSm,
                        ]}
                      >
                      <View 
                        style={stylesRes.manageDeviceMainHeadingAlignPd} 
                        dataSet={{media: ids.manageDeviceMainHeadingAlignPd}}>
                        <Text style={[styles.textTitleMainSmBold]} allowFontScaling={false}>
                          Manage your Devices
                        </Text>
                      </View>
                    </View>
                      
                    <View 
                      style={stylesRes.manageDeviceTxtAlign}
                      dataSet={{media: ids.manageDeviceTxtAlign}}>
                      <View style={[styles.secDeviceDescArea,styles.flexRow]}>    
                          <Text style={styles.itemsContainerRow}>
                          <Text style={[styles.textPrime]} allowFontScaling={false}>
                            Zamplo connects to your Wearable Technology so you
                            can track all of your health data in one place.
                          </Text>
                          <Text style={styles.textPrimeBold}>
                            To connect or disconnect a device, tap the toggle button.
                          </Text>
                          </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </PageContainer>
        </View>

        <View style={[styles.deviceSubSec, styles.flexCom, styles.bgDevice]}>
          <View
            style={[
              styles.boxBorder,
              styles.bgWhite,
              styles.deviceListWrapper,
              styles.flexCom,
              styles.deviceSecPd,
            ]}
          >
              <PageContainer deviceListScreen={true}>
                <View style={[styles.secArea]}>
                  <View style={[styles.deviceListAll]}>
                    {/* Device Loop Start */}
                    {deviceMap.map((item, index) => (
                      <Fragment key={index}>
                        <DeviceList
                          testID={`deviceList_${item.deviceName}`}
                          item={item}
                          index={index}
                          changeDeviceStatus={(status) => changeDeviceWithAuth0(status, item.deviceName)}
                          status={getConnectionStatus(item.connectionStatus)}
                          connectingDeviceName={deviceName}
                          connectingStatus={deviceConnecting}
                          setVisibleDisconnectModal={() =>
                            setVisibleDisconnectModal(true)
                          }
                        />
                    </Fragment>
                    ))}
                    {/* Device Loop End */}
                  </View>
                </View>
                <View style={[styles.secArea, styles.itemMoreWrapper]}>
                  <Text
                    style={[
                      styles.textPrime,
                      styles.textColorLight,
                      styles.textAlignCenter,
                    ]}
                    allowFontScaling={false}
                  >
                    More devices coming soon...
                  </Text>
                </View>
              </PageContainer>
            </View>
          </View>
          <CommonModal
            visibleModal={deviceConnected}
            onBackdropPress={() => {
              setDeviceConnected(false)
            }}
            onRequestClose={() => {
              setDeviceConnected(false)
            }}
            alertTitle={"Hexoskin is now connected!"}
            alertBodyTitle={false}
            alertBody={
              "Zamplo will receive a daily average of your health data and create an automatic entry."
            }
            btnText={"Got it!"}
            handleConfirm={() => {
              setDeviceConnected(false)
            }}
            tickCircle={true}
            exclamationCircle={false}
          />
          {visibleDisconnectModal && 
            <DeviceDisconnectedModal
              visibleModal={visibleDisconnectModal}
              onBackdropPress={() => {
                setVisibleDisconnectModal(false)
              }}
              onRequestClose={() => {
                setVisibleDisconnectModal(false)
              }}
              onDisconnect={()=>onDisconnectDevice(disconnectedDeviceName)}
              connectingDeviceName={deviceName}
              connectingStatus={deviceConnecting}
            />
          }
        </ScrollView>
      </View>
    </>
  );  
}

const mapStateToProps = (state) => {
  return {
    deviceMap: state.device.userDeviceMap || []
  };
};
  
const mapDispatchToProps = (dispatch) => {
  return {
    connectHexoskinDeviceWithAccessCode: (deviceObj, callBack) => dispatch(actions.connectHexoskinDeviceWithAccessCode(deviceObj, callBack)),
    showSuccessPopup: (id, graph, data) => dispatch(actions.showSuccessPopup(id, graph, data)),
    onDisconnect: (deviceName, callBack) => dispatch(actions.disconnectDevice(deviceName, callBack)),
    setDeviceMap: deviceMap => dispatch(actions.setDeviceMap(deviceMap)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    updateDeviceMap: (device, status) => dispatch(actions.updateDeviceMap(device, status)),
    updateJournalEntryTypeSuccess: (journal) => dispatch(actions.updateJournalEntryTypeSuccess(journal)),
    hideSuccessPopup: () => dispatch(actions.hideSuccessPopup()),
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageDevicesCommon);