import React, { Component, Fragment } from "react";
import styles from "../../../Css";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import QuestionText from "./questionText";

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newJournal: props.newJournal ? props.newJournal : null,
      newTherapie: props.newTherapie ? props.newTherapie : null,
      newContact: props.newContact ? props.newContact : null,
      question: props.question,
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      question: props.question,
      newJournal: props.newJournal ? props.newJournal : null,
      newTherapie: props.newTherapie ? props.newTherapie : null,
      newContact: props.newContact ? props.newContact : null,
    });

    
  }

  render() {
    
    return (
      <Fragment>
        <View
          key={this.props.key}
          style={[
            styles.listItemView,
            styles.listItemAddedView,
            styles.borderBotWhite,
            styles.colPdInSec,
            this.props.bgLight ? styles.bgThinGrey : styles.colBg,
          ]}
        >
          <View style={[styles.addedListSection]}>
            {this.props.editOption &&
            this.props.editOption === this.state.question.id ? (
              <QuestionText
                id={this.props.editOption}
                questionValue={this.state.question.content}
                questionList={this.props.questionList}
                showQuestionTextBox={this.props.editOption}
                handleHideQuestionTextBox={this.props.handleHideQuestionTextBox}
                type={this.props.type}
                scrollToEnd={this.props.scrollToEnd}
                remoteSubmit={this.props.remoteSubmit}
                changeRemoteSubmit={this.props.changeRemoteSubmit}
              />
            ) : (
              <View style={[styles.listContentSection]}>
                <View style={styles.listItemTextView}>
                  <View style={[styles.addedItemInd]}>
                    <Text style={[
                      styles.text, 
                      styles.sectionMaxWidth
                      ]} 
                      allowFontScaling={false}
                      nativeID={"textTruncate"}
                      numberOfLines={1}
                      ellipsizeMode='tail'
                    >
                      {this.state.question.content}
                    </Text>
                  </View>
                </View>
                <View style={styles.listItemEditCloseView}>
                  <View
                    style={[styles.listItemEditView, styles.listItemIconViewSm]}
                  >
                    <TouchableOpacity
                      onPress={() =>
                        this.props.handleOnEditQuestion(this.state.question.id)
                      }
                      style={styles.touchPd}
                    >
                      <Image
                        style={styles.closeDarkIcon}
                        source={require("../../../assets/icons/writing.png")}
                      />
                    </TouchableOpacity>
                  </View>

                  <View
                    style={[
                      styles.listItemCloseView,
                      styles.listItemIconViewSm,
                    ]}
                  >
                    <TouchableOpacity
                      onPress={() =>
                        this.props.handleOnDeleteQuestion(
                          this.state.question.id
                        )
                      }
                      style={styles.touchPd}
                    >
                      <Image
                        style={styles.editDarkIcon}
                        source={require("../../../assets/icons/close-dark.png")}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newJournal: state.routineTemplate.newJournal,
    newTherapie: state.therapie.newTherapie,
    newContact: state.contacts.newContact
      ? state.contacts.newContact.data
      : null,
  };
};

export default connect(mapStateToProps, null)(Question);
