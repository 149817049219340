import React, { Component } from 'react';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import Modal from '../../Modal/modal';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';

class AddFileNamePrefix extends Component {
  state = {
    inputText: '',
  };

  render() {
    const { styles: stylesRes } = stylesResponsive.getProcessedStyles();
    const { visible, onClose, onSave } = this.props;
    const { inputText } = this.state;

    return (
      <Modal 
        visible={visible} 
        onRequestClose={onClose} 
        onBackdropPress={onClose}
      >
        <View style={stylesRes.namePrefixPopup}>
          <Text style={[styles.textTitleBold]} allowFontScaling={false}>Add file name prefix</Text>
          <View style={[styles.prefixPopupInputWrapper]}>
            <TextInput
              style={[styles.prefixPopupInput]}
              allowFontScaling={false}
              value={inputText}
              placeholder={'Enter name'}
              placeholderTextColor={'#98989A'}
              onChangeText={(value) => this.setState({ inputText: value })}
            />
          </View>
          <View style={[styles.prefixPopupBtnWrapper]}>
            <TouchableOpacity
              style={[styles.prefixPopupBtn, styles.attachmentCancelBtn,styles.attachmentBtnBorder,styles.bgWhite]}
              onPress={onClose}
            >
              <Text style={[
                styles.textPrime,
                   styles.textAlignCenter,
                   styles.textColorGreyLight,
                   styles.bold,
                ]}
                allowFontScaling={false}
                >
                Cancel</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.prefixPopupBtn, styles.prefixPopupBtnRight]}
              onPress={() => onSave(inputText)}
              disabled={!Boolean(inputText.length)}
            >
              <Text style={[styles.textPrime, styles.textWhite]} allowFontScaling={false}>Confirm</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    );
  }
};

export default (AddFileNamePrefix);
