import React, { Component } from "react";
import {
  View,
  ScrollView,
  Text,
  Image
} from "react-native";
import styles from "../../Css";
import { connect } from "react-redux";
import LearnHowPrivacyPolicy from "../SignUp/LearnHowPrivacyPolicy";
import Content from "./content";
import { DASHBOARD} from '../../navigation/path';
import Footer from "../../components/StaticCommon/footer";

const ACCEPT_STATUS = 'ACCEPTED';
const REVOKED_STATUS = 'REVOKED';

class CaregiverVerificationScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLearnHowPrivacyPolicy: false,
      caregiverStatus:
        props.route?.params && props.route?.params?.caregiverStatus
    };
  }

  toggleLearnHowPrivacyPolicy = () => {
    this.setState({ showLearnHowPrivacyPolicy: true });
  };

  navigateToDashbaord = () => {
    this.props.navigation.push(DASHBOARD.path);
  }

  render() {
    const { showLearnHowPrivacyPolicy } = this.state;
    return (
      <View style={[styles.pageBodyWrapper]}>
        <ScrollView
          style={[styles.scrollViewArea]}
          contentContainerStyle={[styles.scrollViewMax, styles.flexJcSb]}
        >
          <React.Fragment>
            <Content
              messageTitle={
                <Text style={[styles.textTitleMainLgBold, styles.textAlignCenter]} allowFontScaling={false}>
                  {this.state.caregiverStatus === REVOKED_STATUS && "Access has been revoked"}
                  {this.state.caregiverStatus === ACCEPT_STATUS && "This invitation link has expired"}
                </Text>
              }
              message={
                <Text
                  style={[
                    styles.textPrime,
                    styles.textTertiary,
                    styles.textAlignCenter,
                  ]}
                  allowFontScaling={false}
                >
                  {this.state.caregiverStatus === ACCEPT_STATUS && `Your caregiver invitation link has expired.\n Request the sender to Re-send Invitation.`}
                  {this.state.caregiverStatus === REVOKED_STATUS && "Your caregiver access has been revoked."}
                </Text>
              }
              messageIcon={
                <Image
                  style={[styles.caregiverMessageIcon]}
                  source={ 
                    this.state.caregiverStatus === ACCEPT_STATUS ?  require("../../assets/icons/expire.png") : 
                    this.state.caregiverStatus === REVOKED_STATUS ?  require("../../assets/icons/revoke.png") : null
                  }
                /> 
              }
              navigateToDashbaord={this.navigateToDashbaord}
            />
            
            { ( this.state.caregiverStatus === REVOKED_STATUS || this.state.caregiverStatus === ACCEPT_STATUS ) && (
              <Footer toggleLearnHowPrivacyPolicy={this.toggleLearnHowPrivacyPolicy}/>
            )}
          </React.Fragment>
        </ScrollView>
        {Boolean(showLearnHowPrivacyPolicy) &&
          <LearnHowPrivacyPolicy
            showLearnHowPrivacyPolicy={showLearnHowPrivacyPolicy}
            toggleLearnHowPrivacyPolicy={() => {
              this.setState({ showLearnHowPrivacyPolicy: false });
            }}
          />
        }
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowWidth: state.uistate.windowWidth,
    windowHeight: state.uistate.windowHeight,
    journalUserId: state.uistate.journalUserId,
    hasNotch: state.uistate.hasNotch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaregiverVerificationScreen);
