import theme from "./base/theme";

export const cssBorder = {
  borderCol: {
    borderColor: theme.COLOR_GREY_LIGHT,
  },
  lightGreyBorder: {
    borderBottomColor: theme.COLOR_GREY_LIGHT,
    borderBottomWidth: 2,
  },
  borderThin: {
    borderBottomColor: theme.COLOR_GREY_LIGHT,
    borderBottomWidth: 1,
  },
  greyBorderThick: {
    borderBottomColor: theme.COLOR_GREY_LIGHT_MD,
    borderBottomWidth: 4,
  },
  greyBorder: {
    borderBottomColor: theme.COLOR_GREY_LIGHT_RG,
    borderBottomWidth: 2,
  },
  greyBorderTop: {
    borderTopColor: theme.COLOR_GREY_LIGHT_RG,
    borderTopWidth: 2,
  },
  borderThinTop: {
    borderTopColor: theme.COLOR_GREY_LIGHT,
    borderTopWidth: 1,
  },
  borderThickRgTop: {
    borderTopColor: theme.COLOR_GREY_LIGHT,
    borderTopWidth: 2,
  },
  borderThinBot: {
    borderBottomColor: theme.COLOR_GREY_LIGHT,
    borderBottomWidth: 1,
  },
  borderBotWhite: {
    borderBottomColor: theme.COLOR_WHITE,
    borderBottomWidth: 7,
  },
  liItemBorder: {
    borderBottomColor: theme.COLOR_GREY_LIGHT,
    borderBottomWidth: 1,
  },
  borderBotGrey: {
    borderBottomColor: theme.COLOR_BORDER,
    borderBottomWidth: 1,
  },
  liItem: {
    borderBottomColor: theme.COLOR_GREY_LIGHT,
    borderBottomWidth: 1,
    zIndex: 99,
  },
  greyBorderThin: {
    borderBottomColor: theme.COLOR_GREY_LIGHT_RG,
    borderBottomWidth: 1,
  },
  boxBorder: {
    borderWidth: 1,
    borderColor: theme.COLOR_BORDER,
  },
  borderThinCom: {
    borderBottomColor: theme.COLOR_GREY_LIGHT,
    borderBottomWidth: 2,
  },
  borderThinBlackTop: {
    borderTopColor: theme.COLOR_BLACK,
    borderTopWidth: 1,
  },
  hrLineLg: {
    width: "100%",
    borderBottomColor: theme.COLOR_GREY_LIGHT_MD,
    borderBottomWidth: 1,
    paddingBottom: 15,
    marginBottom: 15,
  },
  sectionSep: {
    borderTopColor: theme.COLOR_GREY_LIGHT_MD,
    borderTopWidth: 4,
    paddingBottom: 15,
  },
  noBotPd: {
    paddingBottom: 0,
  },
  greyBorderThinTop: {
    borderTopColor: theme.COLOR_GREY_LIGHT_RG,
    borderTopWidth: 1,
  },
  lightGreyBorderLeft: {
    borderLeftColor: theme.COLOR_GREY_LIGHT,
    borderLeftWidth: 2,
  },
  borderTopGrey: {
    borderColor: theme.COLOR_BORDER,
    borderTopWidth: 1,
  },
  secSepBorderTop: {
    borderTopColor: theme.COLOR_BORDER_MEDIUM,
    borderTopWidth: 1,
  },
  secSepBorderBot: {
    borderBottomColor: theme.COLOR_BORDER_MEDIUM,
    borderBottomWidth: 1,
  },
  borderTopMenu: {
    borderTopColor: theme.COLOR_BORDER_MEDIUM,
    borderTopWidth: 1
  },
  greyBoxBorder: {
    borderColor: theme.COLOR_GREY_LIGHT_RG,
    borderWidth: 1,
  },
  borderComBot: {
    borderBottomColor: theme.COLOR_BORDER_COM,
    borderBottomWidth: 1,
  },
  greyBorderThickTop: {
    borderTopColor: theme.COLOR_GREY_LIGHT_MD,
    borderTopWidth: 4,
  },
  uploadHeaderBorder: {
    borderTopColor: theme.COLOR_BORDER,
    borderRightColor: theme.COLOR_BORDER,
    borderLeftColor: theme.COLOR_BORDER,
    borderBottomColor: 'transparent',
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },

  commonCancelBtn:{
    alignItems: 'center',
    borderWidth:2,
    borderTopColor: theme.COLOR_GREY_DARK_1,
    borderRightColor: theme.COLOR_GREY_DARK_1,
    borderLeftColor: theme.COLOR_GREY_DARK_1,
    borderBottomColor: theme.COLOR_GREY_DARK_1,
    backgroundColor:theme.COLOR_WHITE,
    justifyContent: 'center',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
},

 commonCancelBtnMinWidth:{
    minWidth: 140,
 },

 commonCancelBtnMinWidthSm:{
    minWidth: 100,
 },

 noBorderBtWd: {
  borderBottomWidth:0
 },

 borderBtRadiusEight:{
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8
 },
 borderTpLtRadius: {
    borderTopLeftRadius: 8
 },

 borderTpRtRadius: {
    borderTopRightRadius: 8
 },

 borderBtLtRadius: {
    borderBottomRightRadius: 8
 },

 borderBtRtRadius: {
    borderBottomLeftRadius: 8
 },

 brdTpWdOne: {
  borderTopWidth: 1
 },

 brdLtWdOne: {
  borderLeftWidth: 1
 },

 brdRtWdOne: {
  borderRightWidth: 1
 },

 brdBtWdOne: {
  borderBottomWidth: 1
 },
 
 brdGreyDark: {
  borderColor: theme.COLOR_BORDER
 },

 borderTpLtRadiusZero:{
  borderTopLeftRadius: 0
},

borderTpRtRadiusZero: {
 borderTopRightRadius:0
},

brdWdZero: {
  borderWidth:0
}

};
