import React from "react";
import { View, Text } from "react-native";
import styles from "../../../../Css";
import CollapseDetailDescription from "../../../TabCollapse/collapseDetailDescription";

const profileTabContent = (props) => {
  const { windowWidth, onPressSetupNow } = props;

  return (
    <CollapseDetailDescription
      itemTitle={
        // windowWidth > 991
        //   ? "Setup your profile and add your medical condition(s)"
        //   : "Add Medical condition(s)"
        "Add medical condition(s) to customize your routines"
      }
      imgSource={require("../../../../assets/setup/treatment.png")}
      windowWidth={windowWidth}
      onPressSetupNow={onPressSetupNow}
    >
      <View style={styles.collapseContDec}>
        <View style={[styles.collapseContPara, styles.comMgBotSm]}>
          <Text style={[styles.textPrimeSm, styles.textGrey]} allowFontScaling={false}>
            Adding your medical conditions will help you customize the journal
            to track health information that is important for the condition.
          </Text>
        </View>
        <View style={[styles.collapseContPara]}>
          <Text style={[styles.textPrimeSm, styles.textGrey]} allowFontScaling={false}>
            When you add a medical condition, you will see a list of suggested
            symptoms and health data to add to your routine.
          </Text>
        </View>
      </View>
    </CollapseDetailDescription>
  );
};

export default (profileTabContent);
