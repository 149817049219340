export const getHeightWidth = (windowHeight, diff, fullWidth) => {
  let fullHeight = windowHeight;
  let topIconWidth = 285;
  let topIconHeight = 185;
  let botIconWidth = 281;
  let botIconHeight = 244;
  let minHeight = null;
  let headerHeight = 57;

  if (diff) {
    minHeight = fullHeight;
  } else if (fullWidth && fullWidth < 768) {
    minHeight = fullHeight;
  } else {
    minHeight = 700;
    minHeight = fullHeight - headerHeight;
  }

  topIconHeight = (20 / 100) * minHeight;
  topIconWidth = (285 / 185) * topIconHeight;
  if (topIconHeight > 185) {
    topIconWidth = 285;
    topIconHeight = 185;
  }
  botIconHeight = (28 / 100) * minHeight;
  botIconWidth = (281 / 244) * botIconHeight;
  if (botIconHeight > 244) {
    botIconWidth = 281;
    botIconHeight = 244;
  }
  return {
    windowHeightSmView: minHeight,
    topIconWidthImg: topIconWidth,
    topIconHeightImg: topIconHeight,
    botIconWidthImg: botIconWidth,
    botIconHeightImg: botIconHeight,
  };
};
