import React, {Component} from 'react';
import {View, Text, Image} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import ActivityIndicator from '../ActivityIndicator/activityIndicator';
import LogoSetUp from '../../components/LogoSetUp/LogoSetUp';
import VerificationCodeInput from '../../components/VerificationCodeInput';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import WarningCommon from '../../components/Warning/warningCommon';
import PrimaryShadowButton from '../../components/Button/primaryShadowButton';
import PageLayout from '../../components/PageLayout/pageLayout';

class LoginMFAVerification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wait: false,
      resendWait: false,
      validCode: false,
      invalidCode: false,
      verificationCode: ''
    };
  }

  onCodeChange = () => {
    this.setState({
      wait: false,
      invalidCode: false,
      validCode: false,
      error: false,
      errorDescription: '',
    });
  };

  onChangeverificationCode = (value, valid) => {
    if (value && valid && value.length === 6) {
      this.setState(
        {
          wait: true,
          error: false,
          errorDescription: '',
          validCode: false,
          invalidCode: false,
          verificationCode: value
        },
        () => {
          this.props.mfaVerification(value, (sucess, message) => {
            if (sucess) {
              this.props.showSuccessPopup(4);
              this.setState({
                wait: false,
                validCode: true,
                invalidCode: false,
                error: false,
              });
            } else {
              this.setState({
                wait: false,
                invalidCode: true,
                validCode: false,
                error: true,
                errorDescription: message,
              });
            }
          });
        },
      );
    } else {
      this.setState({
        // wait: false,
        invalidCode: false,
        validCode: false,
        error: false,
        errorDescription: '',
        verificationCode: value
      });
    }
  };

  render() {
    const stylesRes = stylesResponsive.getProcessedStyles().styles;
    const ids = stylesResponsive.getProcessedStyles().ids;
    return (
      <PageLayout
        windowHeightView={this.props.windowHeightView}
        windowHeightSmView={this.props.windowHeightSmView}
        windowWidthView={this.props.windowWidthView}
        bgBlue={this.props.bgBlue}
        contentCenter={false}>
        <View style={[styles.boxWrapperArea, styles.colMgLtRt]}>
          <View 
            style={[stylesRes.boxWrapperView, stylesRes.wrapperShadow]}
            dataSet={{media: ids.boxWrapperView}}>
            <View style={[styles.secArea]}>
              <View style={[styles.colPd]}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <LogoSetUp />
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={[styles.gridColFull]}>
                    <View style={stylesRes.descTextComLg}>
                      <View style={[styles.textSubCom, styles.infoPdBotMd]}>
                        <Text
                          style={[
                            styles.textPrime,
                            styles.textTertiary,
                            styles.textAlignCenter,
                          ]}
                          allowFontScaling={false}
                          >
                          We have sent your two-step verification code to your
                          mobile number:
                        </Text>
                        <Text
                          style={[
                            styles.textPrimeBold,
                            styles.textTertiary,
                            styles.textAlignCenter,
                          ]}
                          allowFontScaling={false}
                          >
                          {this.props.CODEDELIVERYDESTINATION}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.fieldRow, styles.comMgBotSm]}>
                      <View style={[styles.rowTitleSec, styles.rowTitleTopSp]}>
                        <Text
                          style={[styles.textPrime, styles.textAlignCenter]}
                          allowFontScaling={false}
                          >
                          Enter the{' '}
                          <Text style={[styles.bold]} allowFontScaling={false}>verification code </Text>
                        </Text>
                      </View>

                      <View
                        style={[
                          styles.verifyCodeField,
                          this.state.invalidCode
                            ? styles.inValidColor
                            : this.state.validCode
                            ? styles.validGreenColor
                            : styles.validColor,
                        ]}>
                        <VerificationCodeInput
                          onChange={this.onChangeverificationCode}
                          onCodeChange={this.onCodeChange}
                          verificationCode={this.state.verificationCode}
                        />

                        <View style={styles.fieldIconView}>
                          <View style={styles.fieldIconTouchView}>
                            {Boolean(this.state.wait) && (
                              <View style={styles.indicatorFixed}>
                                <ActivityIndicator pdZero={true} size="small" />
                              </View>
                            )}

                            {Boolean(this.state.validCode) && (
                              <Image
                                style={styles.statusCorrectIcon}
                                source={require('../../assets/icons/right-green-icon.png')}
                                resizeMode={'contain'}
                              />
                            )}

                            {Boolean(this.state.invalidCode) && (
                              <Image
                                style={styles.statusWrongIcon}
                                source={require('../../assets/icons/warning-exclamation.png')}
                                resizeMode={'contain'}
                              />
                            )}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={[styles.gridColFull, styles.errBoxView]}>
                    {Boolean(this.state.error) && (
                      <WarningCommon
                        textPosition={'left'}
                        errorMessage={this.state.errorDescription}
                      />
                    )}
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={[styles.gridColFull]}>
                    <View style={[styles.fieldInfoSection, styles.comMgBot]}>
                      <Text style={[styles.textPrime, styles.textTertiary]} allowFontScaling={false}>
                        Enter the 6-digit verification code sent to your phone
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={[styles.gridColFull]}>
                    <View
                      style={[
                        styles.btnArea,
                        styles.btnPdTopMini,
                        styles.comMgBotSm,
                      ]}>
                      <View style={styles.fieldBtn}>
                        <PrimaryShadowButton
                          btnText={'Resend Code'}
                          btnPosition={'full'}
                          activityInd={this.state.resendWait}
                          touchableOnPress={() => {
                            this.setState({
                              resendWait: true,
                              validCode: false,
                              invalidCode: false,
                              error: false,
                              errorDescription: '',
                              verificationCode: ''
                            });
                            this.props.resendCode((success, message) => {
                              if (success) {
                                this.setState({resendWait: false});
                              } else {
                                this.setState({
                                  error: true,
                                  errorDescription: message,
                                  resendWait: false,
                                });
                              }
                            });
                          }}
                          disabled={
                            this.state.resendWait ||
                            this.state.validCode ||
                            this.state.wait
                          }
                          resetIcon={true}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </PageLayout>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSuccessPopup: id => dispatch(actions.showSuccessPopup(id)),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(LoginMFAVerification);
