import React, {Component} from 'react';
import {View, Text, TextInput, Platform, Keyboard} from 'react-native';
import {connect} from 'react-redux';
import AddContact from '../../components/Contacts/addContact';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import UIText from '../../components/Text/text';
import styles from '../../Css';
import * as comTitles from '../../components/Contacts/titles';
import * as actions from '../../store/actions/index';
import * as validator from '../../validator/validator';
import {CONTACTS} from '../../navigation/path';
import SavingConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import {alertBody, contactTitle} from '../../GlobalFunctions/GlobalString';
import Cropper from '../../components/ImageCropper/AmazingCropperPage';
import { pendoTrackEvent } from '../../util/pendoConfig';
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import {hasValue} from '../../util/hashValueGenerator';
import { WEB } from '../../util/platformWindowConfig';


let initialQues = [];

class AddContactScreen extends Component {
  constructor(props) {
    super(props);
    if(!this.props.newContact ||
      this.props.newContact === null) {
        return this.props.navigation.replace(CONTACTS.path);
    }
    let fromPopup = null;
    if (props.route?.params && props.route?.params.fromPopup) {
      fromPopup = props.route?.params.fromPopup;
    }
    if (this.props.activeTab !== 0) {
      this.props.setActiveTab(0);
    }
    this.state = {
      id: (props.newContact || {}).id,
      artifactId: (props.newContact || {}).artifactId,
      fullName: (props.newContact || {}).givenName,
      collapsible: {
        notes: false,
        questions: false,
        image: true,
      },
      imagePath: props.imageURL ? props.imageURL : null,
      rowsQuestions: [],
      categoryType: null,
      organization: (props.newContact || {}).organization,
      specialty: (props.newContact || {}).specialty,
      phone: (props.newContact || {}).primaryPhoneNumber,
      fax: (props.newContact || {}).faxPhoneNumber,
      email: (props.newContact || {}).emailaddress,
      address: (props.newContact || {}).physicalAddressLine1,
      city: (props.newContact || {}).municipality,
      province: (props.newContact || {}).stateProvince,
      zipCode: (props.newContact || {}).postalCode,
      country: (props.newContact || {}).country,
      notes: (props.newContact || {}).notes,
      isCareTeam: (props.newContact || {}).isCareTeam || false,
      questionArray: this.getQuestionArray(
        props.questionDigest.userArtifactHealthQuestions,
        (props.newContact || {}).artifactId,
      ),
      saveWait: false,
      deleteWait: false,
      validateName: true,
      emailValidate: true,
      phoneValidate: true,
      faxValidate: true,
      isNew: props.isNew,
      saveOrDeleteConfirm: false,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      addQuestion: false,
      questions: null,
      fromPopup: fromPopup,
    };
    if (
      this.props.route?.params &&
      this.props.route?.params.from &&
      this.props.route?.params.from === CONTACTS.contactTakePhotoPath
    ) {
      this.initContactHash = null;;
    } else {
      this.initContactHash = this.getContactHashValue();
    }

    initialQues = this.getQuestionArray(
      props.questionDigest.userArtifactHealthQuestions,
      (props.newContact || {}).artifactId,
    );
    this.scrollViewRef = null;
    // this.props.hideMobileMenu();
  }

  componentDidMount() {
    const {newContact, isNew,} = this.props;
    if (newContact) {
      this.setState({
        collapsible: {
          notes:
            this.state.notes === null || this.state.notes === '' ? false : true,
          questions:
            this.state.questionArray && this.state.questionArray.length > 0
              ? true
              : false,
          image: true,
        },
      });
    }
    this.setState({isNew: isNew});
    this.props.navigation.addListener('blur', () => {
      this.props.showMobileMenu();
    });
    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
      pendoTrackEvent(this.props.route.name === CONTACTS.addContactPath ? 'Contacts/@ManualAdd' : 'Contacts/@editContact');
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      error,
      errorDescription,
      isNew,
      windowHeight,
      windowWidth,
    } = nextProps;
    if (this.state.isNew !== isNew) {
      this.setState({isNew: isNew});
    }
    this.setState({
      ...this.state,
      error,
      errorDescription,
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    });
    this.props.hideMobileMenu();
  }

  handleOnPressBackButton = () => {
    this.handleOnSubmitCancel();
  };

  handleOnPressCollapsible = (key, val) => {
    let collapsibleTemp = this.state.collapsible;
    collapsibleTemp[key] = !val;
    this.setState({collapsible: collapsibleTemp});
  };

  onChangeTextQuestion = val => {
    let ques = this.state.questions;
    ques = val;
    this.setState({questions: ques});
  };

  _addRowQuestions = () => {
    this.setState({addQuestion: true});
  };

  handleModalConfirm = () => {
    if (this.state.fromPopup) {
      this.props.setFromPopup(this.state.fromPopup);
    }
    if (this.props.newContact && this.props.newContact !== null) {
      if (this.state.isNew) {
        this.props.onDeleteContact(
          this.state.id,
          this.props.contacts,
          () => {
            //this.props.onResetNewContact();
          },
          this.props.journalUserId,
        );
      }
    }
    if (
      this.props.route?.params &&
      this.props.route?.params.from
    ) {
      this.props.route?.params.from === CONTACTS.contactTakePhotoPath ||
      this.props.route?.params.from === CONTACTS.path
        // ? this.props.navigation.navigate(
        //   CONTACTS.path, {
        //     from: this.props.route.name,
        //   }
        // )
        ? this.handleNavigation(CONTACTS.path)
        : handleCommonBackNavigation(this.props.navigation);
    } else {
      if (this.props.route?.params && this.props.route?.params.fromPopup && this.props.route?.params.fromPopup === 'CONTACT') {
        if (this.props.route?.params?.globalPath) {
          this.handleNavigation(this.props.route?.params?.globalPath);
        } else {
          // this.props.navigation.push(
          //   CONTACTS.path, {
          //     from: this.props.route.name,
          //   },
          // );
          this.handleNavigation(CONTACTS.path);
        }
      } else {
        handleCommonBackNavigation(this.props.navigation);
      }
    }
  };

  handleNavigation = (path) => {
    this.props.navigation.reset({
      index: 0,
      routes: [{
        name: path,
        params: {
          from: this.props.route.name,
        }
      }],
    })
  }

  handleOnSubmitCancel = () => {
    if (this.getContactHasDataToSave()) {
      this.setState({saveOrDeleteConfirm: true});
    } else {
      this.handleModalConfirm();
    }
  };

  hasAttachments = artifactId => {
    let attachments = [];
    if (this.props.attachmentsCompleteDigest) {
      attachments = this.props.attachmentsCompleteDigest.userArtifactAttachments.filter(
        function(attachment) {
          return attachment.userArtifact === artifactId;
        },
      );
    }
    return attachments.length > 0;
  };

  toggleTickCallback = value => {
    this.setState({isCareTeam: value});
  };

  onPressAddContact = () => {
    this.setState({saveWait: true});
    if (!this.state.fullName) {
      this.setState({
        validateName: false,
        saveWait: false,
        saveOrDeleteConfirm: false,
      });
      // this.props.commonErrorHandler('Name can not be empty.'); now this error message won't be used
      this.scrollUpScreen(0, 0, 0, 0);
      return;
    } else {
      this.setState({validateName: true});
    }
    if (
      !this.state.emailValidate ||
      !this.state.phoneValidate ||
      !this.state.faxValidate
    ) {
      this.setState({saveWait: false, saveOrDeleteConfirm: false});

      if (!this.state.phoneValidate) {
        this.scrollUpScreen(0, 50, 0, 200);
      } else if (!this.state.faxValidate) {
        this.scrollUpScreen(0, 55, 0, 220);
      } else if (!this.state.emailValidate) {
        this.scrollUpScreen(0, 150, 0, 240);
      }

      return;
    }
    let addNewContact = {
      addNewContact: {
        contact: {
          id: this.state.id,
          createdOn: null,
          modifiedOn: null,
          artifactId: this.state.artifactId,
          prefix: null,
          suffix: null,
          physicalAddressLine1: this.state.address,
          physicalAddressLine2: null,
          municipality: this.state.city,
          stateProvince: this.state.province,
          postalCode: this.state.zipCode,
          country: this.state.country,
          organization: this.state.organization,
          specialty: this.state.specialty,
          primaryPhoneNumber: this.state.phone
            ? '+' + this.state.phone.replace(/\+/g, '')
            : this.state.phone,
          secondaryPhoneNumber: null,
          faxPhoneNumber: this.state.fax,
          emailaddress: this.state.email,
          notes: this.state.notes,
          givenName: this.state.fullName,
          familyName: null,
          middleName: null,
          isCareTeam: this.state.isCareTeam,
        },
      },
    };
    const {temporyQuestionObject} = this.props;
    if (temporyQuestionObject) {
      this.saveTemporyHealthQuestion(temporyQuestionObject, addNewContact);
    } else {
      this.updateNewContact(addNewContact);
    }
  };

  updateNewContact = (newContact) => {
    this.props.updateContact(
      newContact,
      this.props.contacts,
      (success, message) => {
        if (success) {
          this.setState({isNew: false, saveWait: false});
          //setTimeout(() => {
          //this.setState({ saveWait: false });
          this.redirectcontact();
          //}, 1500);
        } else {
          this.setState({saveWait: false});
        }
      },
      this.props.journalUserId,
    );
  }

  saveTemporyHealthQuestion = (healthQuestion, newContact) => {
    this.props.onSaveHealthQuestion(
      healthQuestion,
      healthQuestion.artifactId,
      () => {
        this.props.updateTemporyQuestionObject(null);
        this.updateNewContact(newContact);
      },
      this.props.journalUserId,
    );
  }
  redirectcontact = () => {
    // this.props.navigation.push(
    //   CONTACTS.viewContactPath, {
    //     from: this.props.route.name,
    //   },
    // );
    // const routes = this.props.navigation.dangerouslyGetState().routes || [];
    // routes.pop();
    // if (routes && routes.length > 0) {
    //   this.props.navigation.reset({
    //     index: routes.length - 1,
    //     routes,
    //   });
    //   return;
    // }
    this.handleNavigation(CONTACTS.viewContactPath);
  };

  selectedTab = tabid => {
    this.props.setActiveTab(tabid);
    if (tabid === 1) {
      this.props.fetchUserAttachments(this.state.artifactId);
    }
  };

  getQuestionArray = (questionDigest, artifactId) => {
    let questionsArray = [];
    if (questionDigest) {
      questionsArray = questionDigest.filter(function(question) {
        return question.artifactId === artifactId;
      });
    }

    const unique = questionsArray
      .map(e => e['questionId'])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => questionsArray[e])
      .map(e => questionsArray[e]);

    let questionsDetailArray = [];

    if (unique) {
      unique.map(res => {
        questionsDetailArray.push(
          this.props.questionDigestProcessed.questions[res.questionId].content,
        );
        return null;
      });
    }

    return questionsDetailArray;
  };

  onSubmitQuestions = () => {
    let addQuestions = {};
    if (this.state.questions) {
      addQuestions = {
        healthQuestion: {
          id: -1,
          createdOn: null,
          modifiedOn: null,
          artifactId: null,
          content: this.state.questions,
          importance: null,
          openedDate: new Date().getTime(),
          closedDate: null,
          closed: false,
          answers: [
            {
              id: null,
              createdOn: null,
              modifiedOn: null,
              artifactId: null,
              content: null,
              answerDate: null,
            },
          ],
        },
        artifactId: this.state.artifactId,
      };

      this.props.addHealthQuestion(
        addQuestions,
        this.props.questionDigest,
        this.state.artifactId,
        this.props.questionDigestProcessed,
        res => {
          let quesArray = [...this.state.questionArray];
          let items = [...quesArray, res.healthQuestion.content];

          this.setState({
            questionArray: items,
            addQuestion: false,
            questions: '',
          });
        },
        this.props.journalUserId,
      );
    }
  };

  handleDeleteContact = () => {
    const {isNew} = this.state;

    if (isNew) {
      this.setState({saveOrDeleteConfirm: false}, () => {
        this.props.onDeleteContact(
          this.state.id,
          this.props.contacts,
          () => {
            this.setState({
              isNew: false,
            });
            this.handleModalConfirm();
          },
          this.props.journalUserId,
        );
      });
    } else {
      this.setState({saveOrDeleteConfirm: false});
      this.handleModalConfirm();
    }
  };

  componentWillUnmount() {
    //TO-DO remove previous navigation changes
    // if (this.props.newContact && this.props.newContact !== null) {
      // if (this.state.isNew) {
      //   this.props.onDeleteContact(
      //     this.state.id,
      //     this.props.contacts,
      //     () => {
      //       this.props.onResetNewContact();
      //     },
      //     this.props.journalUserId,
      //   );
      // }
    // }
    this.props.showMobileMenu();
  }

  scrollUpScreen = (webX, webY, mobileX, mobileY) => {
    setTimeout(() => {
      Platform.OS === WEB
        ? this.scrollViewRef?.scrollTo({ x: webX, y: webY, animated: true })
        : this.scrollViewRef?.scrollToPosition(mobileX, mobileY, true);
    }, 500);
  };

  getContactHasDataToSave = () => {
    let flag = null;
    let questionArray = this.getQuestionArray(
      this.props.questionDigest.userArtifactHealthQuestions,
      (this.props.newContact || {}).artifactId,
    );
    if (
      this.initContactHash === this.getContactHashValue() &&
      JSON.stringify(questionArray) === JSON.stringify(initialQues)
    ) {
      if (this.state.isNew && this.hasAttachments(this.state.artifactId)) {
        flag = true;
      } else {
        flag = false;
      }
    } else {
      flag = true;
    }
    return flag;
  };

  openCroper = (capturedImage) => {
    this.setState({
      showCropperPage: true, 
      image: capturedImage,
    });
  }

  closeCroper = (croppedUri, width, height) => {
    if (croppedUri) {
      this.setState({
        showCropperPage: false, 
        image: {...this.state.image, uri: croppedUri, height: height, width: width},
      });
    } else {
      this.setState({
        showCropperPage: false, 
        image: {...this.state.image},
      });
    }
  }

  imageUploadCompleted = () => {
    this.setState({image: null});
  }

  getContactHashValue = () => {
    const {
      id,
      artifactId, 
      fullName, 
      organization, 
      specialty, 
      phone, 
      fax, 
      email,
      address, 
      city, 
      province, 
      zip,
      country, 
      notes, 
      isCareTeam
    } = this.state;
    return hasValue(
      id +
        ',' +
        artifactId +
        ',' +
        fullName +
        ',' +
        organization +
        ',' +
        specialty +
        ',' +
        phone  +
        ',' +
        fax +
        ',' +
        email +
        ',' +
        address +
        ',' +
        city +
        ',' +
        province +
        ',' +
        zip +
        ',' +
        country +
        ',' +
        notes +
        ',' +
        isCareTeam
    );
  }

  render() {
    let questionView = null;
    if (this.props.newContact && this.props.newContact !== null) {
      if (this.state.addQuestion) {
        questionView = (
          <View style={[styles.fieldRow]}>
            <View style={styles.textAreaWrapper}>
              <Text style={[styles.textView]} allowFontScaling={false}>
                <UIText
                  style={[styles.textLabel, styles.bold, styles.textColorLight]}
                  title={comTitles.QUESTIONS}
                />
              </Text>
              <TextInput
                style={[
                  styles.textAreaField,
                  styles.textFieldWhite,
                  styles.textAreaMax,
                ]}
                allowFontScaling={false}
                onChangeText={this.onChangeTextQuestion}
                multiline={true}
                numberOfLines={3}
                onBlur={this.onSubmitQuestions}
              />
            </View>
          </View>
        );
      }
    }
    return (
      this.props.newContact && this.props.newContact !== null && this.state.showCropperPage 
      ? <Cropper 
            image={this.state.image}
            close={this.closeCroper}
        />
      : <View style={[styles.pageBodyWrapper]}>
        {this.props.newContact && this.props.newContact !== null ? (
          this.props.deleteContactInProgress ? (
            <ActivityIndicator />
          ) : (
            <React.Fragment>
              <AddContact
                onShowTutorial={this.props.onShowTutorial}
                handleOnPressBackButton={this.handleOnPressBackButton}
                contentVisibilityNotes={this.state.collapsible.notes}
                contentVisibilityImage={this.state.collapsible.image}
                contentVisibilityQuestion={this.state.collapsible.questions}
                imagePath={this.state.imagePath}
                p2pProfileHasDataToSave={this.getContactHasDataToSave()}
                imageTitle={
                  this.state.collapsible.image ? 'Hide image' : 'Show image'
                }
                selectedTab={this.selectedTab}
                activeTab={this.props.activeTab}
                handleOnPressCollapsibleImage={res =>
                  this.handleOnPressCollapsible('image', res)
                }
                handleOnPressCollapsibleNotes={res =>
                  this.handleOnPressCollapsible('notes', res)
                }
                handleOnPressCollapsibleQuestion={res =>
                  this.handleOnPressCollapsible('questions', res)
                }
                onChangeTextFullName={fullName => {
                  this.setState({fullName: fullName ? fullName : null, validateName: true});
                }}
                fullName={this.state.fullName}
                onValueChange={itemValue => {
                  this.setState({categoryType: itemValue});
                }}
                categoryType={this.state.categoryType}
                onChangeTextOrganization={organization => {
                  this.setState({organization: organization ? organization : null});
                }}
                organization={this.state.organization}
                onChangeTextSpecialty={specialty => {
                  this.setState({specialty: specialty ? specialty : null});
                }}
                specialty={this.state.specialty}
                onChangeTextPhone={phone => {
                  if (phone) {
                    this.setState({
                      phoneValidate: validator.validateNumber(phone),
                      phone: phone,
                    });
                  } else {
                    this.setState({
                      phoneValidate: true,
                      phone: null,
                    });
                  }
                }}
                phone={this.state.phone}
                phoneValidate={this.state.phoneValidate}
                onChangeTextFax={fax => {
                  if (fax) {
                    this.setState({
                      faxValidate: validator.validateNumber(fax),
                      fax: fax,
                    });
                  } else {
                    this.setState({
                      faxValidate: true,
                      fax: null,
                    });
                  }
                }}
                faxValidate={this.state.faxValidate}
                fax={this.state.fax}
                onChangeTextEmail={email => {
                  if (email) {
                    this.setState({
                      emailValidate: validator.validateEmail(email),
                      email: email,
                    });
                  } else {
                    this.setState({
                      emailValidate: true,
                      email: null,
                    });
                  }
                }}
                emailValidate={this.state.emailValidate}
                email={this.state.email}
                onChangeTextAddress={address => {
                  this.setState({address: address ? address : null});
                }}
                address={this.state.address}
                onChangeTextCity={city => {
                  this.setState({city: city ? city : null});
                }}
                city={this.state.city}
                onChangeTextProvince={province => {
                  this.setState({province: province ? province : null});
                }}
                province={this.state.province}
                onChangeTextZipCode={zipCode => {
                  this.setState({zipCode: zipCode ? zipCode : null});
                }}
                zipCode={this.state.zipCode}
                onChangeTextCountry={country => {
                  this.setState({country: country ? country : null});
                }}
                country={this.state.country}
                onChangeTextNotes={notes => {
                  this.setState({notes: notes ? notes : null});
                }}
                notes={this.state.notes}
                questions={this.state.questionArray}
                rowsQuestions={questionView}
                _addRowQuestions={this._addRowQuestions}
                handleOnSubmitCancel={this.handleOnSubmitCancel}
                handleOnSubmitSave={() => {
                  Keyboard.dismiss();
                  setTimeout(() => {
                  const isPhoneValid = () =>
                    !this.state.phone ||
                    (this.state.phone.length > 10 ||
                      this.state.phone.length < 14)
                      ? true
                      : false;
                  const isNameValid = () =>
                    this.state.fullName ? true : false;

                  this.setState(
                    {
                      phoneValidate: isPhoneValid(),
                      validateName: isNameValid(),
                    },
                    () => {
                      this.onPressAddContact();
                    },
                  );
                  }, 300);
                }}
                hasAttachments={this.hasAttachments(this.state.artifactId)}
                artifactId={this.state.artifactId}
                fetchAttachmentSpinner={this.props.fetchAttachmentSpinner}
                userAttachmentsSelected={this.props.userAttachmentsSelected}
                ticked={this.state.isCareTeam}
                toggleTickCallback={this.toggleTickCallback}
                saveWait={this.state.saveWait}
                validateName={this.state.validateName}
                journalUserId={this.props.journalUserId}
                windowHeight={this.state.windowHeight}
                hasNotch={this.props.hasNotch}
                index={CONTACTS.index}
                navigation={this.props.navigation}
                route={this.props.route}
                emailVerified={this.props.emailVerified}
                scrollViewRef={val => {
                  this.scrollViewRef = val;
                }}
                openCroper={this.openCroper}
                tempCapturedImage={this.state.image} 
                onUploadCompleted={this.imageUploadCompleted} 
              />
              {this.state.saveOrDeleteConfirm &&
              <SavingConfirmation
                visibleModal={this.state.saveOrDeleteConfirm}
                alertTitle={contactTitle}
                alertBody={alertBody}
                handleCancel={this.handleDeleteContact}
                handleConfirm={() => {
                  this.setState({saveOrDeleteConfirm: false}, () => {
                    if (this.props.activeTab !== 0) {
                      this.props.setActiveTab(0);
                    }
                    setTimeout(() => {
                      this.onPressAddContact();
                    }, 1000);
                  });
                }}
                confirmWait={this.state.saveWait}
                disabled={this.state.saveWait}
                onBackdropPress={() =>
                  this.setState({saveOrDeleteConfirm: false})
                }
              />
              }
            </React.Fragment>
          )
        ) : (
          this.props.navigation.replace(CONTACTS.path)
        )}
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    errorDescription: state.contacts.errorDescription,
    error: state.contacts.error,
    contacts: state.routines.contacts,
    newContact: state.contacts.newContact
      ? state.contacts.newContact.data
      : null,
    imageURL:
      state.contacts.newContact && state.contacts.newContact.imagePath
        ? state.contacts.newContact.imagePath
        : null,
    questionDigest: state.routines.questionDigest,
    questionDigestProcessed: state.routines.questionDigestProcessed,
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
    fetchAttachmentSpinner: state.routines.journalAttachmentSpinner,
    userAttachmentsSelected: state.routines.userAttachmentsSelected,
    journalUserId: state.uistate.journalUserId,
    isNew: state.contacts.isNew,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    hasNotch: state.uistate.hasNotch,
    deleteContactInProgress: state.uistate.deleteContactInProgress,
    emailVerified: state.auth.emailVerified,
    activeTab: state.surveys.activeTab,
    temporyQuestionObject: state.routineTemplate.temporyQuestionObject,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateContact: (addNewContact, contacts, callBack, journalUserId) =>
      dispatch(
        actions.updateContact(
          addNewContact,
          contacts,
          callBack,
          journalUserId,
        ),
      ),
    addHealthQuestion: (
      addQuestions,
      questionDigest,
      artifactId,
      questionDigestProcessed,
      callback,
      journalUserId,
    ) =>
      dispatch(
        actions.createHealthQuestion(
          addQuestions,
          questionDigest,
          artifactId,
          questionDigestProcessed,
          callback,
          journalUserId,
        ),
      ),
    onShowTutorial: tutorialType =>
      dispatch(actions.showTutorial(tutorialType)),
    onDeleteContact: (id, contacts, callBack, journalUserId) =>
      dispatch(actions.deleteContact(id, contacts, callBack, journalUserId)),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    fetchUserAttachments: artifactId =>
      dispatch(actions.fetchUserAttachments(artifactId)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    setFromPopup: fromPopup => dispatch(actions.setFromPopup(fromPopup)),
    onResetNewContact: () => dispatch(actions.resetNewContact()),
    setActiveTab: activeTabIndex =>
      dispatch(actions.setActiveTab(activeTabIndex)),
    onSaveHealthQuestion: (
      healthQuestion,
      artifactId,
      callBack,
      journalUserId,
    ) =>
      dispatch(
        actions.saveHealthQuestion(
          healthQuestion,
          artifactId,
          callBack,
          journalUserId,
        ),
      ),
    updateTemporyQuestionObject: questionObject =>
      dispatch(actions.updateTemporyQuestionObject(questionObject)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddContactScreen);
