import * as actionTypes from "./actionTypes";

export const setImageFilter = filetr => {
  return {
    type: actionTypes.ADD_IMAGE_FILTER,
    imageFilter: filetr
  };
};

export const clearImageFilter = () => {
  return {
    type: actionTypes.CLEAR_IMAGE_FILTER
  };
};

export const setContactFilter = (filter, ticked) => {
  return {
    type: actionTypes.ADD_CONTACT_FILTER,
    contactFilter: { searchKeyword: filter, ticked: ticked }
  };
};

export const clearContactFilter = () => {
  return {
    type: actionTypes.CLEAR_CONTACT_FILTER
  };
};

export const clearTherapyFilter = () => {
  return {
    type: actionTypes.CLEAR_THERAPY_FILTER
  };
};

export const setTherapyPopup = popupData => {
  return {
    type: actionTypes.SET_THERAPY_POPUP,
    popupData
  };
};

export const setSymptomFilter = filter => {
  return {
    type: actionTypes.ADD_SYMPTOM_FILTER,
    symptomFilter: filter
  };
};

export const clearSymptomFilter = () => {
  return {
    type: actionTypes.CLEAR_SYMPTOM_FILTER
  };
};

export const setMetricFilter = filter => {
  return {
    type: actionTypes.ADD_METRIC_FILTER,
    metricFilter: filter
  };
};

export const clearMetricFilter = () => {
  return {
    type: actionTypes.CLEAR_METRIC_FILTER
  };
};

export const setGraphFilter = filter => {
  return {
    type: actionTypes.ADD_GRAPH_FILTER,
    graphFilter: filter
  };
};

export const clearGraphFilter = () => {
  return {
    type: actionTypes.CLEAR_GRAPH_FILTER
  };
};

export const setReportFilter = filter => {
  return {
    type: actionTypes.ADD_REPORT_FILTER,
    reportFilter: filter
  };
};

export const clearReportFilter = () => {
  return {
    type: actionTypes.CLEAR_REPORT_FILTER
  };
};

export const setRoutineFilter = filter => {
  return {
    type: actionTypes.ADD_ROUTINE_FILTER,
    routineFilter: filter
  };
};

export const clearRoutineFilter = () => {
  return {
    type: actionTypes.CLEAR_ROUTINE_FILTER
  };
};