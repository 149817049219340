import React, {Component} from 'react';
import stylesResponsive from '../../CssResponsive';
import styles from '../../Css';
import {View, Platform} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import * as paths from '../../navigation/path';
import {
  SubMenuTitel,
  CommonMenuItem,
  CommonIconMenuItem,
  SetupWizardMenuItem,
  CommunityMenuItem,
  ReferFriendMenuItem,
  LogoutMenuItem,
} from './MenuItem';
import {
  APP_FEATURE_P2P_ENABLED,
  APP_FEATURE_MANAGE_DEVICE_ENABLED,
  APP_FEATURE_HEALTH_JOURNEY_ENABLED
} from '../../util/featureConfig';
import {getUnseenRequestCount} from '../../util/commonUiLogic';
import {tabs} from '../../Constant/index';
import {toggleSetupModal} from '../../store/actions/uistate';
import {getCurrentSubscription, isSubscriptionSponsored} from "../../util/subscriptionInformation";
import ScrollViewWithScrollBarIndicator from "../ScrollViewWithScrollBarIndicator/ScrollViewWithScrollBarIndicator";
import {WEB} from '../../util/platformWindowConfig';


class MenuItems extends Component {
  constructor(props) {
    super(props);
    const {
      selectedMenu,
      applicationSettingsDigest,
      windowHeight,
      windowWidth,
      seenNotification,
      myNetwork,
      unSeenConversations
    } = props;
    const {received} = myNetwork;
    const {isSetupOn} = applicationSettingsDigest;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      selectedMenu: selectedMenu ? selectedMenu : 0,
      showMyHealthTracking: false,
      showMyDocuments: false,
      isSetupOn: isSetupOn,
      hasCommunityNotifications: getUnseenRequestCount(received) > 0 || Object.keys(unSeenConversations).length > 0,
      hasSurveyNotifications: Object.values(seenNotification).filter(res => res.type === 'SURVEY').length > 0,
      hasNotification: props.notification && Object.values(props.notification).length > 0,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      selectedMenu,
      applicationSettingsDigest,
      windowHeight,
      windowWidth,
      seenNotification,
      myNetwork,
      unSeenConversations,
      notification
    } = props;
    const {received} = myNetwork;
    const {isSetupOn} = applicationSettingsDigest;
    let hasCommunityNotifications = state.hasCommunityNotifications;
    let hasSurveyNotifications = state.hasSurveyNotifications;
    let hasNotification = state.hasNotification;
    if (getUnseenRequestCount(received) > 0 || Object.keys(unSeenConversations).length > 0) {
      hasCommunityNotifications = true;
    } else {
      hasCommunityNotifications = false;
    }
    if (Object.values(seenNotification).filter(res => res.type === 'SURVEY').length > 0) {
      hasSurveyNotifications = true;
    } else {
      hasSurveyNotifications = false;
    }
    if (Object.values(notification).length > 0) {
      hasNotification = true;
    } else {
      hasNotification = false;
    }
    if (
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth ||
      selectedMenu !== state.selectedMenu ||
      isSetupOn !== state.isSetupOn ||
      hasCommunityNotifications !== state.hasCommunityNotifications ||
      hasSurveyNotifications !== state.hasSurveyNotifications) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        selectedMenu: selectedMenu,
        isSetupOn: isSetupOn,
        hasCommunityNotifications: hasCommunityNotifications,
        hasSurveyNotifications: hasSurveyNotifications,
        hasNotification: hasNotification,
      };
    }

    return null;
  }

  isSetupComplete = () => {
    const {
      conditions = [],
      userConditions = [],
      treatments = [],
      userTreatments = [],
      symptoms = [],
      userSymptoms = [],
      metrics = [],
      userDefinedMetrics = [],
      journalEntryTypes = [],
    } = this.props;

    if (
      ((conditions && conditions.length > 0) ||
        (userConditions && userConditions.length > 0)) &&
      ((treatments && treatments.length > 0) ||
        (userTreatments && userTreatments.length > 0)) &&
      ((symptoms && symptoms.length > 0) ||
        (userSymptoms && userSymptoms.length > 0)) &&
      ((metrics && metrics.length > 0) ||
        (userDefinedMetrics && userDefinedMetrics.length > 0)) &&
      (journalEntryTypes && journalEntryTypes.length > 0)
    ) {
      //all steps complete
      return true;
    }
    //at least one step is pending
    return false;
  };

  handleOnPressMenuItem = (notifType) => {
    const {onClearMenuSectionNotifications, seenNotification} = this.props;
    onClearMenuSectionNotifications(
      Object.values(seenNotification).filter(res => res.type === notifType)
    );
  }

  checkConnectedUserCount = () => {
    const {connected} = this.props.myNetwork;
    let count = 0;
    if (connected && connected.length > 0) {
      count = connected.length;
    }
    return count;
  }

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {selectedMenu = 0, isSetupOn, hasCommunityNotifications, hasSurveyNotifications} = this.state;
    const {
      navigation,
      showSetup,
      journalUserId,
      clearTherapyFilter,
      clearImageFilter,
      clearContactFilter,
      clearSymptomFilter,
      clearMetricFilter,
      clearGraphFilter,
      clearQuestionFilter,
      clearJournalFilter,
      clearReportFilter,
      clearRoutineFilter,
      clearHealthLibraryFilter,
      tabMenuIconView,
      OnSetPeerToPeerActiveTab,
      onSetSelectedJournalEntry,
      onResetNewJournal,
      onSetEventData,
      setActiveBubble,
      userSubscription,
      peerProfile,
      handleTabMenuClick
    } = this.props;

    const currentSubscription = getCurrentSubscription(userSubscription);
    const isSponsoredSubscription = isSubscriptionSponsored(currentSubscription);

    return (
      <React.Fragment>
        <View style={[stylesRes.menuAreaWrapper]}>
          <ScrollViewWithScrollBarIndicator
            scrollID={'menuScroll'}
          >
            <View style={[stylesRes.menuArea]}>
              <View style={stylesRes.menuContent}>
                <View style={[styles.menuContentFull, styles.highZindex]}>
                  <CommonIconMenuItem
                    handleTabMenuClick={handleTabMenuClick}
                    isSelected={selectedMenu === paths.DASHBOARD.index}
                    navigation={navigation}
                    tabMenuIconView={tabMenuIconView}
                    pathDetails={paths.DASHBOARD}
                  />
                  {APP_FEATURE_HEALTH_JOURNEY_ENABLED &&
                    <CommonIconMenuItem
                      handleTabMenuClick={handleTabMenuClick}
                      isSelected={selectedMenu === paths.HEALTH_JOURNEY.index}
                      navigation={navigation}
                      tabMenuIconView={tabMenuIconView}
                      pathDetails={paths.HEALTH_JOURNEY}
                      onPressMenuItem={() => {
                        onSetEventData({});
                        setActiveBubble(null);
                      }}
                    />
                  }

                  <CommonIconMenuItem
                    handleTabMenuClick={handleTabMenuClick}
                    isSelected={selectedMenu === paths.JOURNALS.index}
                    navigation={navigation}
                    clearFilter={clearJournalFilter}
                    tabMenuIconView={tabMenuIconView}
                    pathDetails={paths.JOURNALS}
                    onPressMenuItem={() => {
                      onSetSelectedJournalEntry(null);
                      onResetNewJournal();
                    }}
                  />
                  {Boolean(isSponsoredSubscription) &&
                    <CommonIconMenuItem
                      handleTabMenuClick={handleTabMenuClick}
                      isSelected={selectedMenu === paths.SURVEYS.index}
                      navigation={navigation}
                      clearFilter={clearJournalFilter}
                      tabMenuIconView={tabMenuIconView}
                      pathDetails={paths.SURVEYS}
                      onPressMenuItem={() => this.handleOnPressMenuItem('SURVEY')}
                      hasNotifications={hasSurveyNotifications}
                    />
                  }
                  <CommonIconMenuItem
                    handleTabMenuClick={handleTabMenuClick}
                    isSelected={selectedMenu === paths.TODOS.index}
                    navigation={navigation}
                    clearFilter={clearQuestionFilter}
                    tabMenuIconView={tabMenuIconView}
                    pathDetails={paths.TODOS}
                  />
                  <CommonIconMenuItem
                    handleTabMenuClick={handleTabMenuClick}
                    isSelected={selectedMenu === paths.GRAPHS.index}
                    navigation={navigation}
                    clearFilter={clearGraphFilter}
                    tabMenuIconView={tabMenuIconView}
                    pathDetails={paths.GRAPHS}
                  />
                  {APP_FEATURE_P2P_ENABLED && !Boolean(journalUserId) && (
                    <CommunityMenuItem
                      handleTabMenuClick={handleTabMenuClick}
                      isSelected={selectedMenu === paths.PEER_TO_PEER.index}
                      navigation={navigation}
                      tabMenuIconView={tabMenuIconView}
                      hasNotifications={hasCommunityNotifications}
                      OnSetPeerToPeerActiveTab={OnSetPeerToPeerActiveTab}
                      route={
                        this.props.applicationSettingsDigest &&
                        this.props.applicationSettingsDigest
                          .showPeerProfileScreen === null
                          ? paths.PEER_TO_PEER.disclaimerPath
                          : (this.props.applicationSettingsDigest &&
                            !Boolean(this.props.applicationSettingsDigest?.showPeerProfileScreen) &&
                            (!Boolean(peerProfile) || !Boolean(peerProfile && peerProfile?.username)))
                            ? paths.PEER_TO_PEER.peerResultsByConditionPath
                            : paths.PEER_TO_PEER.path
                      }
                      connectedUserCount={this.checkConnectedUserCount()}
                    />
                  )}
                  <CommonIconMenuItem
                    handleTabMenuClick={handleTabMenuClick}
                    isSelected={selectedMenu === paths.COMMUNITY_GROUPS.index}
                    navigation={navigation}
                    clearFilter={() => clearHealthLibraryFilter(null)}
                    tabMenuIconView={tabMenuIconView}
                    pathDetails={paths.COMMUNITY_GROUPS}
                  />
                  <CommonIconMenuItem
                    handleTabMenuClick={handleTabMenuClick}
                    isSelected={selectedMenu === paths.CONTACTS.index}
                    navigation={navigation}
                    clearFilter={clearContactFilter}
                    tabMenuIconView={tabMenuIconView}
                    pathDetails={paths.CONTACTS}
                  />
                  {!Boolean(journalUserId) && (
                    <CommonIconMenuItem
                      handleTabMenuClick={handleTabMenuClick}
                      isSelected={selectedMenu === paths.CAREGIVERS.index}
                      navigation={navigation}
                      tabMenuIconView={tabMenuIconView}
                      pathDetails={paths.CAREGIVERS}
                    />
                  )}

                  {!Boolean(tabMenuIconView) && (
                    <>
                      <SubMenuTitel titel={'MY HEALTH RESEARCH'} handleTabMenuClick={handleTabMenuClick}/>
                      <View style={styles.subMenuWrapper}>
                        <CommonIconMenuItem
                          isSelected={selectedMenu === paths.CLINICAL_TRIALS.index}
                          navigation={navigation}
                          title={paths.CLINICAL_TRIALS.title}
                          pathDetails={{
                            ...paths.CLINICAL_TRIALS, path: this.props.applicationSettingsDigest && !this.props.applicationSettingsDigest.clinicalTrialDisclaimer
                              ? paths.CLINICAL_TRIALS.otherPath
                              : paths.CLINICAL_TRIALS.path
                          }}
                          onPressMenuItem={() => {this.props.onResetSearchResults();}}
                        />
                      </View>

                      <SubMenuTitel titel={'MY DOCUMENTS'} handleTabMenuClick={handleTabMenuClick}/>
                      <View style={styles.subMenuWrapper}>
                        <CommonMenuItem
                          handleTabMenuClick={handleTabMenuClick}
                          isSelected={selectedMenu === paths.IMAGES_DOCUMENTS.index}
                          navigation={navigation}
                          title={paths.IMAGES_DOCUMENTS.title}
                          route={paths.IMAGES_DOCUMENTS.path}
                          clearFilter={clearImageFilter}
                        />
                        <CommonMenuItem
                          handleTabMenuClick={handleTabMenuClick}
                          isSelected={selectedMenu === paths.REPORTS.index}
                          navigation={navigation}
                          title={paths.REPORTS.title}
                          route={paths.REPORTS.path}
                          clearFilter={clearReportFilter}
                        />
                      </View>

                      <SubMenuTitel titel={'MY HEALTH TRACKING'} handleTabMenuClick={handleTabMenuClick}/>
                      <View style={styles.subMenuWrapper}>
                        <CommonMenuItem
                          handleTabMenuClick={handleTabMenuClick}
                          isSelected={selectedMenu === paths.MEDICATIONS.index}
                          navigation={navigation}
                          title={paths.MEDICATIONS.title}
                          route={paths.MEDICATIONS.path}
                          clearFilter={clearTherapyFilter}
                        />
                        <CommonMenuItem
                          handleTabMenuClick={handleTabMenuClick}
                          isSelected={selectedMenu === paths.ROUTINES.index}
                          navigation={navigation}
                          title={paths.ROUTINES.title}
                          route={paths.ROUTINES.path}
                          clearFilter={clearRoutineFilter}
                        />
                        <CommonMenuItem
                          handleTabMenuClick={handleTabMenuClick}
                          isSelected={selectedMenu === paths.SYMPTOMS.index}
                          navigation={navigation}
                          title={paths.SYMPTOMS.title}
                          route={paths.SYMPTOMS.path}
                          clearFilter={clearSymptomFilter}
                        />
                        <CommonMenuItem
                          handleTabMenuClick={handleTabMenuClick}
                          isSelected={selectedMenu === paths.METRICS.index}
                          navigation={navigation}
                          title={paths.METRICS.title}
                          route={paths.METRICS.path}
                          clearFilter={clearMetricFilter}
                        />
                        {/* Manage Devices */}
                        {Boolean(isSponsoredSubscription) && APP_FEATURE_MANAGE_DEVICE_ENABLED &&
                          <CommonMenuItem
                            handleTabMenuClick={handleTabMenuClick}
                            isSelected={selectedMenu === paths.MANAGE_DEVICES.index}
                            navigation={navigation}
                            title={paths.MANAGE_DEVICES.title}
                            route={paths.MANAGE_DEVICES.path}
                          />
                        }
                      </View>

                      <SubMenuTitel titel={'HELP AND SUPPORT'} handleTabMenuClick={handleTabMenuClick}/>
                      <View style={styles.subMenuWrapper}>
                        <CommonMenuItem
                          handleTabMenuClick={handleTabMenuClick}
                          isSelected={selectedMenu === paths.HELP.index}
                          navigation={navigation}
                          title={paths.HELP.title}
                          route={paths.HELP.path}
                        />
                      </View>
                    </>
                  )}
                </View>
              </View>
            </View>
            {!Boolean(isSetupOn)
              &&
              <SetupWizardMenuItem
                onPress={() => {
                  showSetup(this.props.journalUserId, !this.isSetupComplete(), () => {
                    this.props.setActiveTab(tabs.profile);
                    if (Platform.OS !== WEB) {
                      navigation.reset({
                        index: 0,
                        routes: [{name: paths.OTHER.setupPath}],
                      });
                    } else {
                      navigation.navigate(paths.DASHBOARD.path);
                      this.props.toggleSetupModal(true);
                    }
                  });
                }}
                tabMenuIconView={tabMenuIconView}
                handleTabMenuClick={handleTabMenuClick}
              />
            }
            <LogoutMenuItem
              onPress={this.props.onSignOutPress}
              hasNotification={this.state.hasNotification}
              tabMenuIconView={tabMenuIconView}
              handleTabMenuClick={handleTabMenuClick}
            />
            <View style={[styles.mainMenuSpace]}/>
          </ScrollViewWithScrollBarIndicator>
        </View>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    selectedMenu: state.uistate.selectedMenu,
    journalUserId: state.uistate.journalUserId,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    userConditions: state.routines.userConditions,
    treatments: state.setup.treatments,
    userTreatments: state.routines.treatments,
    symptoms: state.setup.symptoms,
    userSymptoms: state.routines.symptomsDigest
      ? state.routines.symptomsDigest.userSymptoms
      : [],
    metrics: state.setup.metrics,
    userDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.userDefinedMetrics
      : [],
    journalEntryTypes: state.routines.journalEntryTypes,
    notification: state.uistate.notification,
    seenNotification: state.uistate.seenNotification,
    myNetwork: state.routines.myNetwork,
    newMessageCount: state.peerToPeer.newMessageCount,
    unSeenConversations: state.peerToPeer.unSeenConversations,
    userSubscription: state.routines.userSubscription,
    peerProfile: state.routines.peer2peer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onResetClinicalTrialState: () =>
      dispatch(actions.resetClinicalTrialState()),
    togglePrivacyStatment: () => dispatch(actions.showPrivacyStatment()),
    showSetup: (journalUserId, updateGlobally, callBack) =>
      dispatch(actions.showSetup(journalUserId, callBack, updateGlobally)),
    clearQuestionFilter: () => dispatch(actions.clearQuestionFilter()),
    clearJournalFilter: () => dispatch(actions.clearJournalEntryFilter()),
    clearTherapyFilter: () => dispatch(actions.clearTherapyFilter()),
    clearImageFilter: () => dispatch(actions.clearImageFilter()),
    clearContactFilter: () => dispatch(actions.clearContactFilter()),
    clearSymptomFilter: () => dispatch(actions.clearSymptomFilter()),
    clearMetricFilter: () => dispatch(actions.clearMetricFilter()),
    clearDeviceFilter: () => dispatch(actions.clearDeviceFilter()),
    clearGraphFilter: () => dispatch(actions.clearGraphFilter()),
    clearReportFilter: () => dispatch(actions.clearReportFilter()),
    clearRoutineFilter: () => dispatch(actions.clearRoutineFilter()),
    clearHealthLibraryFilter: (keywords) => {
      dispatch(actions.updateSearchedKeywords(keywords));
    },
    onClearMenuSectionNotifications: (notificationArray) =>
      dispatch(actions.clearMenuSectionNotifications(notificationArray)),
    OnSetPeerToPeerActiveTab: (activeTabIndex) =>
      dispatch(actions.setPeerToPeerActiveTab(activeTabIndex)),
    onSignOutPress: () => dispatch(actions.logout()),
    onResetSearchResults: () => dispatch(actions.resetSearchResults()),
    onSetSelectedJournalEntry: (selectedJournal) =>
      dispatch(actions.setSelectedJournalEntry(selectedJournal)),
    setActiveTab: value => dispatch(actions.setSetupActiveTab(value)),
    toggleSetupModal: (val) => dispatch(toggleSetupModal(val)),
    onResetNewJournal: () => dispatch(actions.resetNewJournal()),
    onSetEventData: (data) => dispatch(actions.setEventData(data)),
    setActiveBubble: (id) => dispatch(actions.setActiveBubble(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MenuItems);
