import React from "react";
import { View, Text, Image, TouchableOpacity, Platform, Dimensions } from "react-native";
import styles from "../../Css";
import ToolTip from "../../components/ToolTip";
import PremiumButton from "../../components/Button/premiumButton";
import * as FontScaling from "../../components/Text/fontScaling";
import { ANDROID } from "../../util/platformWindowConfig";

const CollapsibleTitle = (props) => {
  let collapseTitle = props.title;
  let twoRowTitle = false;

  const screenWidth = Math.round(Dimensions.get('window').width);
  if (
    screenWidth < 575 &&
    props.title === "Medication/Supplement Information"
  ) {
    collapseTitle = "Medication/Supplement \nInformation";
    twoRowTitle = true;
  }

  return (
    <React.Fragment>
      {Boolean(props.plusButton) && (
        <View
          style={[
            styles.collapsibleIconView,
            props.titleMd ? styles.noMgImg : styles.mgImg,
          ]}
        >
          <TouchableOpacity
            nativeID={props.nativeId && props.nativeId + (props.contentVisibility ? 'Collaps' : 'Expand')}
            onPress={props.handleOnPressCollapsible}
            style={styles.collapsiblePlusIconView}
          >
            {!Boolean(props.contentVisibility)
              ? <Image
                source={require("../../assets/icons/square-plus-orange.png")}
                style={styles.collapsiblePlusIcon}
                resizeMode={"contain"}
              />
              : <Image
                source={require("../../assets/icons/square-plus-grey.png")}
                style={styles.collapsiblePlusIcon}
                resizeMode={"contain"}
              />
            }
          </TouchableOpacity>
        </View>
      )}

      <Text
        style={[
          props.titleLg ? styles.textTitle : props.customTitleStyle ? styles.textCollapsibleTitle : styles.textPrime,
          props.titleLt ? styles.textThin : (!props.customTitleStyle || Platform.OS !== ANDROID) ? styles.bold : null,
          twoRowTitle ? { width: '70%' } : null,
        ]}
        allowFontScaling={false}
        maxFontSizeMultiplier={FontScaling.fontScaleRg}
      >
        {collapseTitle}
      </Text>

      {Boolean(props.regularUser) && (
        <View style={styles.premiumBtnSp}>
          <PremiumButton btnBot={true} navigation={props.navigation} />
        </View>
      )}

      {Boolean(props.toolTipButton) && (
        <View style={twoRowTitle ? [styles.helpCircleComViewMg, { marginRight: 10 }] : styles.helpCircleComView}>
          <ToolTip
            id={props.toolTipId}
            text={props.toolTipContent}
            disabledScroll={props.disabledScroll}
          />
        </View>
      )}
    </React.Fragment>
  );
};

export default (CollapsibleTitle);

