import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  TextInput
} from "react-native";
import stylesResponsive from "../../CssResponsive";
import styles from "../../Css";
import UIText from "../Text/text";
import UITextInput from "../TextInput/textInput";
import ActivityIndicator from "../ActivityIndicator/activityIndicator";
import Collapsible from "../Collapsible/Collapsible";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import SuccessBox from "../../components/ConfirmationBox/successBox";
import Picker from "../Picker/picker";

class SearchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentVisibility: false,
      name: this.props.name ? this.props.name : "",
      validName: true,
      successModalVisible: false,
      successMessage: "",
      ...this.props.state,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth
    };
  }

  onPressSaveCriteria = () => {
    if (!this.state.name) {
      this.setState({ validName: false });
      return;
    } else {
      this.setState({ validName: true });
    }
    this.setState({ saveSearchWait: true });
    let treatment = [];
    let symptom = [];
    let condition = [];

    if (
      this.props.selectedTreatment &&
      this.props.selectedTreatment.length > 0
    ) {
      this.props.selectedTreatment.filter(res => {
        treatment.push(res.id);
        return treatment;
      });
    }
    if (this.props.selectedSymptom && this.props.selectedSymptom.length > 0) {
      this.props.selectedSymptom.filter(res => {
        symptom.push(res.id);
        return symptom;
      });
    }

    if (
      this.props.selectedUserCondition &&
      this.props.selectedUserCondition.length > 0
    ) {
      this.props.selectedUserCondition.filter(res => {
        condition.push(res.id);
        return condition;
      });
    }
    let searchObject = {
      similarAge: this.props.similarAge,
      treatment: treatment,
      mySex: this.props.mySex,
      treatmentAll: this.props.treatmentAll,
      dateRange: this.props.dateRange,
      diagnosis: condition,
      symptomAll: this.props.symptomAll,
      userId: this.props.journalUserId
        ? this.props.journalUserId.toString()
        : this.props.user.toString(),
      compareCaregiver: this.props.selectedId.toString(),
      searchString: this.props.searchString,
      symptom: symptom,
      treatment6Months: this.props.treatmentLastSix,
      name: this.state.name,
      symptom6Months: this.props.symptomLastSix,
      compareUser: this.props.peopleLike,
      resourceType: this.props.resourceType
    };
    this.props.onhandleSearchCriteria(searchObject, res => {
      if (res && res === "Caregiver has permission type: READ_ONLY") {
        this.setState({
          saveSearchWait: false
        });
        this.props.close();
      } else if (res) {
        this.setState(
          {
            saveSearchWait: false,
            successModalVisible: true,
            successMessage: res,
            contentVisibility: false,
            name: "",
            validName: true
          },
          () => {
            setTimeout(() => {
              this.setState({ successModalVisible: false, successMessage: "" });
              this.props.close(true);
            }, 800);
          }
        );
      } else {
        this.setState({
          saveSearchWait: false
        });
        this.props.close(true);
      }
    });
  };

  getStatus = value => {
    switch (value) {
      case "NONE":
        return "Diagnosed";
      case "DIAGNOSIS":
        return "Diagnosed";
      case "REMISSION":
        return "Remission";
      case "REOCCURENCE":
        return "Reoccurence";
      default:
        return 0;
    }
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const { windowHeight, windowWidth } = nextProps;
    this.setState({
      windowWidth,
      windowHeight,
    });
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    let fullWidth = this.state.windowWidth;
    let modalMaxWidth = 400;
    let answerPickerPd = 0;
    if (fullWidth > 574) {
      modalMaxWidth = 375;
    } else if (fullWidth > 374) {
      modalMaxWidth = 340;
    } else {
      modalMaxWidth = 300;
      answerPickerPd = 10;
    }
    return (
      <View 
        style={[stylesRes.modalScrollWrapperCom, { width: modalMaxWidth }]}
        dataSet={{media: ids.modalScrollWrapperCom}}>
        {this.state.contentVisibility && (
          <TouchableOpacity
            style={styles.closeBar}
            onPress={this.props.onPressClose}
          ></TouchableOpacity>
        )}
        <ScrollView 
          style={styles.modalScroller}
          keyboardShouldPersistTaps={'always'}
        >
          <View style={[styles.popUpBlock, styles.bgWhite]}>
            <View style={styles.popUpContentArea}>
              <View style={styles.gridSectionPopup}>
                <View style={[styles.colPdTopBotLg]}>
                  <View>
                    <View style={[styles.gridRow, styles.borderBotGrey]}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.colPdLtRt]}>
                          <View style={styles.fieldRow}>
                            <View style={[styles.fieldCol]}>
                              <UITextInput
                                style={[
                                  styles.textField,
                                  styles.textFieldSm,
                                  this.state.validName
                                    ? styles.validColor
                                    : styles.inValidColor
                                ]}
                                value={this.state.name}
                                placeholder={"Enter Search title"}
                                onChangeText={value => {
                                  this.setState({
                                    name: value,
                                    validName: true
                                  });
                                }}
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    <Collapsible
                      title="Search Criteria"
                      contentVisibility={this.state.contentVisibility}
                      handleOnPressCollapsible={() => {
                        this.setState(prevState => ({
                          contentVisibility: !prevState.contentVisibility
                        }));
                      }}
                    >
                      <View style={[styles.gridRow, styles.borderBotGrey]}>
                        <View style={styles.gridColFull}>
                          <View style={[styles.colPdTopLtRt]}>
                            <View style={styles.secArea}>
                              <View style={[styles.subSection]}>
                                <View style={[styles.fieldRow]}>
                                  <TextInput
                                    style={[
                                      styles.textField,
                                      styles.textFieldWhite,
                                      styles.textFieldSm
                                    ]}
                                    allowFontScaling={false}
                                    value={this.props.searchString}
                                    underlineColorAndroid="transparent"
                                    placeholder="Enter text or tag, ex. support"
                                    editable={false}
                                  />
                                </View>

                                <View style={[styles.fieldRow]}>
                                  <View style={[styles.fieldDescCom]}>
                                    <Text style={[styles.textPrime]} allowFontScaling={false}>
                                      Added or updated since:
                                    </Text>
                                  </View>
                                  <View style={[styles.pickerSelectionWrapper]}>
                                    <Text
                                      style={[
                                        styles.textView,
                                        styles.textViewPicker
                                      ]}
                                      allowFontScaling={false}
                                    >
                                      <UIText
                                        style={[
                                          styles.textLabel,
                                          styles.bold,
                                          styles.textColorLight
                                        ]}
                                        title="Select Date Range:"
                                      />
                                    </Text>
                                    <View
                                      style={[
                                        stylesRes.dropArrowView,
                                        stylesRes.dropArrowTopLg
                                      ]}
                                      pointerEvents={"none"}
                                    >
                                      <View style={stylesRes.dropArrowIcon}>
                                        <Image
                                          style={[styles.imgContain]}
                                          source={require("../../assets/icons/dropdown-arrow.png")}
                                        />
                                      </View>
                                    </View>
                                    <Picker
                                      items={[{ label: this.props.dateRange }]}
                                      style={[
                                        styles.pickerAreaField,
                                        styles.selectionPicker,
                                      ]}
                                      selectedValue={this.props.dateRange}
                                      onValueChange={value => {}}
                                      enabled={false}
                                    />
                                  </View>
                                </View>

                                <View style={[styles.fieldRow]}>
                                  <View style={[styles.pickerSelectionWrapper]}>
                                    <Text
                                      style={[
                                        styles.textView,
                                        styles.textViewPicker
                                      ]}
                                      allowFontScaling={false}
                                    >
                                      <UIText
                                        style={[
                                          styles.textLabel,
                                          styles.bold,
                                          styles.textColorLight
                                        ]}
                                        title="Resource type"
                                      />
                                    </Text>
                                    <View
                                      style={[
                                        stylesRes.dropArrowView,
                                        stylesRes.dropArrowTopLg
                                      ]}
                                      pointerEvents={"none"}
                                    >
                                      <View style={stylesRes.dropArrowIcon}>
                                        <Image
                                          style={[styles.imgContain]}
                                          source={require("../../assets/icons/dropdown-arrow.png")}
                                        />
                                      </View>
                                    </View>
                                    <Picker
                                      items={[
                                        { label: this.props.resourceType }
                                      ]}
                                      style={[
                                        styles.pickerAreaField,
                                        styles.textPickerPdSmCom,
                                      ]}
                                      selectedValue={this.props.resourceType}
                                      onValueChange={value => {}}
                                      enabled={false}
                                    />
                                  </View>
                                </View>

                                <View style={[styles.questSectionWrapper]}>
                                  <View style={[styles.questionAnswerBlock]}>
                                    <View style={[styles.questionWrapper]}>
                                      <Text
                                        style={[
                                          styles.textPrime
                                          // props.valid
                                          // ? styles.textColor
                                          // : styles.textRed
                                        ]}
                                        allowFontScaling={false}
                                      >
                                        Show me resources others are using
                                      </Text>
                                    </View>

                                    <View style={[styles.answerWrapper]}>
                                      <View
                                        style={[
                                          styles.answerSingle,
                                          styles.flexAiCt,
                                          styles.flexJcSb,
                                          styles.flexWrapView
                                        ]}
                                      >
                                        <View style={[styles.answerSingleText]}>
                                          <View style={[styles.answerOpt]}>
                                            {this.props.peopleLike ? (
                                              <Image
                                                style={styles.radioIcon}
                                                source={require("../../assets/icons/radio-selected.png")}
                                              />
                                            ) : (
                                              <Image
                                                style={styles.radioIcon}
                                                source={require("../../assets/icons/radio-default.png")}
                                              />
                                            )}
                                          </View>
                                          <View style={[styles.answerText]}>
                                            <Text style={[styles.textPrime]} allowFontScaling={false}>
                                              People like:
                                            </Text>
                                          </View>
                                        </View>

                                        {this.props.peopleLike ? (
                                          <View
                                            style={[
                                              styles.answerPicker,
                                              {
                                                paddingTop: answerPickerPd,
                                                paddingBottom: answerPickerPd
                                              }
                                            ]}
                                          >
                                            <View
                                              style={[styles.pickerAreaWrapper]}
                                            >
                                              <View
                                                style={[
                                                  stylesRes.dropArrowView,
                                                  stylesRes.dropArrowCent
                                                ]}
                                                pointerEvents={"none"}
                                              >
                                                <View style={stylesRes.dropArrowIcon}>
                                                  <Image
                                                    style={[styles.imgContain]}
                                                    source={require("../../assets/icons/dropdown-arrow.png")}
                                                  />
                                                </View>
                                              </View>
                                              <Picker
                                                items={[
                                                  {
                                                    label: this.props
                                                      .selectedUser
                                                  }
                                                ]}
                                                style={[
                                                  styles.pickerAreaField,
                                                  styles.textPickerPdMiniCom,
                                                ]}
                                                selectedValue={
                                                  this.props.selectedUser
                                                }
                                                onValueChange={value => {}}
                                                enabled={false}
                                              />
                                            </View>
                                          </View>
                                        ) : null}
                                      </View>

                                      <View
                                        style={[
                                          styles.answerSingle,
                                          styles.flexAiCt,
                                          styles.flexJcSb,
                                          styles.flexWrapView
                                        ]}
                                      >
                                        <View style={[styles.answerSingleText]}>
                                          <View style={[styles.answerOpt]}>
                                            {this.props.caringPeopleLike ? (
                                              <Image
                                                style={styles.radioIcon}
                                                source={require("../../assets/icons/radio-selected.png")}
                                              />
                                            ) : (
                                              <Image
                                                style={styles.radioIcon}
                                                source={require("../../assets/icons/radio-default.png")}
                                              />
                                            )}
                                          </View>
                                          <View style={[styles.answerText]}>
                                            <Text style={[styles.textPrime]} allowFontScaling={false}>
                                              Caring for people like:
                                            </Text>
                                          </View>
                                        </View>

                                        {this.props.caringPeopleLike ? (
                                          <View
                                            style={[
                                              styles.answerPicker,
                                              {
                                                paddingTop: answerPickerPd,
                                                paddingBottom: answerPickerPd
                                              }
                                            ]}
                                          >
                                            <View
                                              style={[styles.pickerAreaWrapper]}
                                            >
                                              <View
                                                style={[
                                                  stylesRes.dropArrowView,
                                                  stylesRes.dropArrowCent
                                                ]}
                                                pointerEvents={"none"}
                                              >
                                                <View style={stylesRes.dropArrowIcon}>
                                                  <Image
                                                    style={[styles.imgContain]}
                                                    source={require("../../assets/icons/dropdown-arrow.png")}
                                                  />
                                                </View>
                                              </View>
                                              <Picker
                                                items={[
                                                  {
                                                    label: this.props
                                                      .selectedUser
                                                  }
                                                ]}
                                                style={[
                                                  styles.pickerAreaField,
                                                  styles.textPickerPdMiniCom,
                                                ]}
                                                selectedValue={
                                                  this.props.selectedUser
                                                }
                                                onValueChange={value => {}}
                                                enabled={false}
                                              />
                                            </View>
                                          </View>
                                        ) : null}
                                      </View>
                                    </View>
                                  </View>

                                  <View style={[styles.questionAnswerBlock]}>
                                    <View style={[styles.questionWrapper]}>
                                      <Text
                                        style={[
                                          styles.textPrime
                                          // props.valid
                                          // ? styles.textColor
                                          // : styles.textRed
                                        ]}
                                        allowFontScaling={false}
                                      >
                                        Demographics
                                      </Text>
                                    </View>

                                    <View style={[styles.answerWrapper]}>
                                      <View style={[styles.answerSingle]}>
                                        <View style={[styles.answerOpt]}>
                                          {this.props.similarAge ? (
                                            <Image
                                              style={styles.radioIcon}
                                              source={require("../../assets/icons/radio-selected.png")}
                                            />
                                          ) : (
                                            <Image
                                              style={styles.radioIcon}
                                              source={require("../../assets/icons/radio-default.png")}
                                            />
                                          )}
                                        </View>
                                        <View style={[styles.answerText]}>
                                          <Text style={[styles.textPrime]} allowFontScaling={false}>
                                            Similar age
                                          </Text>
                                        </View>
                                      </View>
                                      <View style={[styles.answerSingle]}>
                                        <View style={[styles.answerOpt]}>
                                          {this.props.mySex ? (
                                            <Image
                                              style={styles.radioIcon}
                                              source={require("../../assets/icons/radio-selected.png")}
                                            />
                                          ) : (
                                            <Image
                                              style={styles.radioIcon}
                                              source={require("../../assets/icons/radio-default.png")}
                                            />
                                          )}
                                        </View>
                                        <View style={[styles.answerText]}>
                                          <Text style={[styles.textPrime]} allowFontScaling={false}>
                                            My Sex
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={[styles.questionAnswerBlock]}>
                                    <View
                                      style={[
                                        styles.questionWrapper,
                                        styles.flexRow,
                                        styles.noBotMg
                                      ]}
                                    >
                                      <Text style={[styles.textPrime]} allowFontScaling={false}>
                                        Diagnosis
                                      </Text>
                                    </View>
                                    {!(this.props.selectedUserCondition && this.props.selectedUserCondition.length > 0) &&
                                      <Text style={[styles.textPrimeMd, styles.textEm]} allowFontScaling={false}>
                                        No diagnosis selected
                                      </Text>
                                    }
                                    {this.props.selectedUserCondition && this.props.selectedUserCondition.length > 0
                                      ? this.props.selectedUserCondition.map(
                                          res => {
                                            return (
                                              <View
                                                style={[styles.answerWrapper]}
                                                key={res.id}
                                              >
                                                <View
                                                  style={[styles.answerSingle]}
                                                >
                                                  <View
                                                    style={[styles.answerOpt]}
                                                  >
                                                    <Image
                                                      style={styles.radioIcon}
                                                      source={require("../../assets/icons/radio-selected.png")}
                                                    />
                                                  </View>
                                                  <View
                                                    style={[styles.answerText]}
                                                  >
                                                    <Text
                                                      style={[styles.textPrime]}
                                                      allowFontScaling={false}
                                                    >
                                                      {res.name}
                                                    </Text>
                                                    <Text
                                                      style={[
                                                        styles.textPrimeSm,
                                                        styles.textEm,
                                                        styles.textColorLight
                                                      ]}
                                                      allowFontScaling={false}
                                                    >
                                                      {(res.year
                                                        ? this.getStatus(
                                                            res.status
                                                          ) +
                                                          " in " +
                                                          res.year +
                                                          (res.stage
                                                            ? ", "
                                                            : "")
                                                        : "") +
                                                        (res.stage
                                                          ? !res.stage.includes(
                                                              "Stage"
                                                            )
                                                            ? "Stage " +
                                                              res.stage
                                                            : res.stage
                                                          : "")}
                                                    </Text>
                                                  </View>
                                                </View>
                                              </View>
                                            );
                                          }
                                        )
                                      : null}
                                  </View>

                                  <View style={[styles.questionAnswerBlock]}>
                                    <View
                                      style={[
                                        styles.questionWrapper,
                                        styles.flexRow,
                                        styles.noBotMg
                                      ]}
                                    >
                                      <Text style={[styles.textPrime]} allowFontScaling={false}>
                                        Treatment
                                      </Text>
                                    </View>
                                    {!(this.props.selectedTreatment && this.props.selectedTreatment.length > 0) &&
                                      <Text style={[styles.textPrimeMd, styles.textEm]} allowFontScaling={false}>
                                        No treatment selected
                                      </Text>
                                    }
                                    {this.props.selectedTreatment && this.props.selectedTreatment.length > 0
                                      ? this.props.selectedTreatment.map(
                                          res => {
                                            return (
                                              <View
                                                style={[styles.answerWrapper]}
                                                key={res.id}
                                              >
                                                <View
                                                  style={[styles.answerSingle]}
                                                >
                                                  <View
                                                    style={[styles.answerOpt]}
                                                  >
                                                    <Image
                                                      style={styles.radioIcon}
                                                      source={require("../../assets/icons/radio-selected.png")}
                                                    />
                                                  </View>
                                                  <View
                                                    style={[styles.answerText]}
                                                  >
                                                    <Text
                                                      style={[styles.textPrime]}
                                                      allowFontScaling={false}
                                                    >
                                                      {res.name}
                                                    </Text>
                                                  </View>
                                                </View>
                                              </View>
                                            );
                                          }
                                        )
                                      : null}
                                  </View>

                                  <View style={[styles.questionAnswerBlock]}>
                                    <View
                                      style={[
                                        styles.questionWrapper,
                                        styles.flexRow,
                                        styles.noBotMg
                                      ]}
                                    >
                                      <Text style={[styles.textPrime]} allowFontScaling={false}>
                                        Symptoms
                                      </Text>
                                    </View>
                                    {!(this.props.selectedSymptom && this.props.selectedSymptom.length > 0) &&
                                      <Text style={[styles.textPrimeMd, styles.textEm]} allowFontScaling={false}>
                                        No symptom selected
                                      </Text>
                                    }
                                    {this.props.selectedSymptom && this.props.selectedSymptom.length > 0
                                      ? this.props.selectedSymptom.map(res => {
                                          return (
                                            <View
                                              style={[styles.answerWrapper]}
                                              key={res.id}
                                            >
                                              <View
                                                style={[styles.answerSingle]}
                                              >
                                                <View
                                                  style={[styles.answerOpt]}
                                                >
                                                  <Image
                                                    style={styles.radioIcon}
                                                    source={require("../../assets/icons/radio-selected.png")}
                                                  />
                                                </View>
                                                <View
                                                  style={[styles.answerText]}
                                                >
                                                  <Text
                                                    style={[styles.textPrime]}
                                                    allowFontScaling={false}
                                                  >
                                                    {res.name}
                                                  </Text>
                                                </View>
                                              </View>
                                            </View>
                                          );
                                        })
                                      : null}
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </Collapsible>
                    <View style={[styles.gridRow]}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.colPdLtRt]}>
                          <View style={styles.btnArea}>
                            <View
                              style={[stylesRes.btnViewSecSm, stylesRes.noPdBot]}
                              dataSet={{media: ids.btnViewSecSm}}
                            >
                              <View style={styles.fieldBtn}>
                                <View style={styles.btnBlockFull}>
                                  <TouchableOpacity
                                    style={[styles.submitSm, styles.disableBtn]}
                                    underlayColor="rgba(0, 153, 168, 0.8)"
                                    onPress={() => this.props.close()}
                                  >
                                    <View style={styles.btnSingleView}>
                                      <View
                                        style={[
                                          styles.btnSingle,
                                          styles.btnSingleCent
                                        ]}
                                      >
                                        <UIText
                                          style={styles.submitText}
                                          title="Cancel"
                                        />
                                      </View>
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              </View>
                              <View style={styles.fieldBtnSp} />
                              <View style={styles.fieldBtn}>
                                <View style={styles.btnBlockFull}>
                                  <TouchableOpacity
                                    style={[
                                      styles.submitSm,
                                      // (if button active  styles.submitBgBlue)
                                      styles.submitBgBlue
                                      // (if button disbled  styles.disableBtn)
                                      // styles.submitBgBlue
                                    ]}
                                    underlayColor="rgba(0, 153, 168, 0.8)"
                                    onPress={this.onPressSaveCriteria}
                                  >
                                    <View style={styles.btnSingleView}>
                                      {this.state.saveSearchWait && (
                                        <View style={styles.actIndCenterLg}>
                                          <ActivityIndicator size="small" />
                                        </View>
                                      )}
                                      <View
                                        style={[
                                          styles.btnSingle,
                                          styles.btnSingleCent
                                        ]}
                                      >
                                        <UIText
                                          style={[
                                            styles.submitText,
                                            styles.submitTextSm
                                          ]}
                                          title="Save"
                                        />
                                      </View>
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        <SuccessBox
          visibleModal={this.state.successModalVisible}
          message={this.state.successMessage}
        />
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onhandleSearchCriteria: (searchObject, callBack) => {
      dispatch(actions.onhandleSearchCriteria(searchObject, callBack));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchModal);
