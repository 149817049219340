import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  TextInput,
  Platform
} from "react-native";
import UIText from "../Text/text";
import Header from "../Header/header";
import Tabs from "../Tab/Tab";
import Collapsible from "../Collapsible/Collapsible";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import Attachments from "../Attachment/Attachments";
import TickButton from "../../components/CheckBox/CheckBox";
import QuestionList from "../../components/Question/Add";
import SpaceBar from "../../components/SpaceBar/SpaceBar";
import KeyboardAwareScrollViewHoc from "../KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc";
import StorageBanner from "../../components/Banner/StorageBanner";
import PageContainer from "../../components/PageLayout/PageContainer";
import PhoneInput from "../../components/PhoneInput";
import ValidationMessage from "../../components/Warning/validationMessage";
import GooglePlacesAutoComplete from './googlePlacesAutoComplete';
import AddAttachment from "../../components/Button/addAttachment";
import MenuOverlayContainer from "../PageLayout/MenuOverlayContainer";
import CancelButton from "../Button/cancelButton";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import { WEB } from "../../util/platformWindowConfig";

let textInput2 = null;
let textInput3 = null;
let textInput4 = null;
let textInput5 = null;
let textInput6 = null;
let textInput7 = null;
let textInput8 = null;
let textInput9 = null;
let textInput10 = null;
let textInput11 = null;

const addContact = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const handleAdressSelection = (addressObject) => {
    let adressComponents = addressObject.address_components;
    // let streetNumber = adressComponents.find(
    //   (item) => item.types[0] === "street_number"
    // );
    // let route = adressComponents.find((item) => item.types[0] === "route");
    let city = adressComponents.find((item) => item?.types?.[0] === "locality");
    let province = adressComponents.find(
      (item) => item?.types?.[0] === "administrative_area_level_1"
    );
    let country = adressComponents.find((item) => item?.types?.[0] === "country");
    let postalCode = adressComponents.find(
      (item) => item?.types?.[0] === "postal_code"
    );

    props.onChangeTextAddress(addressObject.formatted_address);
    props.onChangeTextCity(city?.long_name);
    props.onChangeTextProvince(province?.long_name);
    props.onChangeTextCountry(country?.long_name);
    props.onChangeTextZipCode(postalCode?.long_name);
  };
  props.scrollViewRef && props.scrollViewRef(this);

  const renderTitleTextView = (title) => {
    return (
      <Text style={styles.textView} allowFontScaling={false}>
        <Text
          style={[
            styles.textLabel,
            styles.bold,
            styles.textColorLight,
          ]}
          allowFontScaling={false}
        >
          {title}
        </Text>
      </Text>
    );
  }

  return (
    <View style={styles.pageBodyWrapper}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={true}
        handleOnPressBackButton={props.handleOnPressBackButton}
        index={props.index}
        navigation={props.navigation}
        route={props.route}
      />
      <SpaceBar />
      {Boolean(props.p2pProfileHasDataToSave) && (
        <MenuOverlayContainer onPressOpen={props.handleOnPressBackButton} />
      )}
      <StorageBanner />
      <KeyboardAwareScrollViewHoc hasNotch={props.hasNotch} ref={props.scrollViewRef}>
        <View style={styles.gridSectionView}>
          <Tabs
            activeTab={props.activeTab}
            tabIndex={(res) => props.selectedTab(res)}
            pageContainer={true}
            smScreenFull={true}
          >
            {/* Open tab */}
            <View title="Contact Info" style={styles.tabContentSection}>
              <View style={[stylesRes.tabContentFullArea, stylesRes.contBotPd]} dataSet={{media: ids.contBotPd}}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <PageContainer smScreenFull={true}>
                      {Boolean(props.imagePath) ? (
                        <Collapsible
                          title={props.imageTitle}
                          contentVisibility={props.contentVisibilityImage}
                          handleOnPressCollapsible={
                            props.handleOnPressCollapsibleImage
                          }
                        >
                          <View
                            style={[
                              styles.collapseContent,
                              // styles.colPdSm,
                              styles.colPdTopCom,
                              styles.comSpExSm,
                              styles.uploadedSection,
                              styles.flexJcCtAiCt,
                            ]}
                          >
                            <View style={[stylesRes.uploadedImgView]}>
                              <Image
                                source={{ uri: props.imagePath }}
                                style={styles.imgContain}
                                resizeMode={"contain"}
                              />
                            </View>
                          </View>
                        </Collapsible>
                      ) : null}
                    </PageContainer>
                    <PageContainer>
                      <View style={[styles.secArea, styles.pdTopSm]}>
                        <View
                          style={[styles.subSection, styles.colPdTopBotCom]}
                        >
                          <View style={styles.fieldRow}>
                            <View
                              style={[
                                styles.fieldRowContainer,
                                styles.postRelative,
                              ]}
                            >
                              {renderTitleTextView("Full Name")}
                              <TextInput
                                style={[
                                  styles.textField,
                                  props.validateName
                                    ? styles.validColor
                                    : styles.inValidColor,
                                ]}
                                allowFontScaling={false}
                                onChangeText={props.onChangeTextFullName}
                                value={props.fullName}
                                //autoFocus={props.emailVerified && true} //!props.validateName}
                                underlineColorAndroid="transparent"
                                onSubmitEditing={() => {
                                  textInput2.focus();
                                }}
                                returnKeyType={"next"}
                              />
                              {!Boolean(props.validateName) && (
                                <ValidationMessage
                                  fieldLg={true}
                                  errorMessage={"Name can not be empty."}
                                />
                              )}
                            </View>
                          </View>

                          <View style={styles.fieldRow}>
                            {renderTitleTextView("Organization")}
                            <TextInput
                              style={styles.textField}
                              allowFontScaling={false}
                              onChangeText={props.onChangeTextOrganization}
                              value={props.organization}
                              underlineColorAndroid="transparent"
                              ref={(input) => {
                                textInput2 = input;
                              }}
                              onSubmitEditing={() => {
                                textInput3.focus();
                              }}
                              returnKeyType={"next"}
                            />
                          </View>

                          <View style={styles.fieldRow}>
                            {renderTitleTextView("Specialty")}
                            <TextInput
                              style={styles.textField}
                              allowFontScaling={false}
                              onChangeText={props.onChangeTextSpecialty}
                              value={props.specialty}
                              underlineColorAndroid="transparent"
                              ref={(input) => {
                                textInput3 = input;
                              }}
                              onSubmitEditing={() => {
                                textInput4.phone.focus();
                              }}
                              returnKeyType={"next"}
                            />
                          </View>

                          <View style={[styles.fieldRow, styles.zIndexMax]}>
                            <View
                              style={[
                                styles.fieldRowContainer,
                                styles.postRelative,
                              ]}
                            >
                              {renderTitleTextView("Phone #")}
                              <View
                                style={[
                                  styles.telCodeFieldLg,
                                  props.phoneValidate
                                    ? styles.validColor
                                    : styles.inValidColor,
                                ]}
                              >
                                <PhoneInput
                                  value={props.phone ? props.phone : ""}
                                  ref={(ref) => (textInput4 = ref)}
                                  onChange={Platform.OS === WEB ? props.onChangeTextPhone : (val) =>
                                    props.onChangeTextPhone(
                                      val.replace("+", "")
                                    )
                                  }
                                  setOpenCountyPopup={() => {}}
                                  inputProps={
                                    Platform.OS === WEB
                                      ? {onKeyDown: e => {
                                        if (e) {
                                          e.key === 'Enter' &&
                                            setTimeout(
                                              () => textInput5.focus(),
                                              200,
                                            );
                                          //workaround for https://github.com/bl00mber/react-phone-input-2/issues/231
                                          //someone already commited a fix to the repo,
                                          //will release with the fix on the next release of the library
                                          //remove below line when fix is released with the library
                                          e.keyCode === 8 &&
                                            props.phone?.length == 1 &&
                                            props.onChangeTextPhone('');
                                        }
                                      },}
                                      : {onSubmitEditing: () => textInput5 && textInput5.focus()}
                                  }
                                  emailVerified={props.emailVerified}
                                />
                              </View>

                              {!Boolean(props.phoneValidate) && (
                                <ValidationMessage
                                  fieldLg={true}
                                  errorMessage={"Invalid phone number"}
                                />
                              )}
                            </View>
                          </View>
                          <View style={[styles.fieldRow, styles.zIndexMin]}>
                            <View
                              style={[
                                styles.fieldRowContainer,
                                styles.postRelative,
                              ]}
                            >
                              {renderTitleTextView("Fax #")}
                              <TextInput
                                style={[
                                  styles.textField,
                                  props.faxValidate
                                    ? styles.validColor
                                    : styles.inValidColor,
                                ]}
                                allowFontScaling={false}
                                onChangeText={props.onChangeTextFax}
                                value={props.fax}
                                underlineColorAndroid="transparent"
                                ref={(input) => {
                                  textInput5 = input;
                                }}
                                onSubmitEditing={() => {
                                  textInput6.focus();
                                }}
                                returnKeyType={"next"}
                              />
                              {!Boolean(props.faxValidate) && (
                                <ValidationMessage
                                  fieldLg={true}
                                  errorMessage={"Invalid fax number"}
                                />
                              )}
                            </View>
                          </View>

                          <View style={[styles.fieldRow, styles.zIndexMin]}>
                            <View
                              style={[
                                styles.fieldRowContainer,
                                styles.postRelative,
                              ]}
                            >
                              {renderTitleTextView("Email")}
                              <TextInput
                                style={[
                                  styles.textField,
                                  props.emailValidate
                                    ? styles.validColor
                                    : styles.inValidColor,
                                ]}
                                allowFontScaling={false}
                                onChangeText={props.onChangeTextEmail}
                                value={props.email}
                                underlineColorAndroid="transparent"
                                ref={(input) => {
                                  textInput6 = input;
                                }}
                                onSubmitEditing={() => {
                                  textInput7.refs.textInput.focus();
                                }}
                                returnKeyType={"next"}
                              />
                              {!Boolean(props.emailValidate) && (
                                <ValidationMessage
                                  fieldLg={true}
                                  errorMessage={"Invalid email address"}
                                />
                              )}
                            </View>
                          </View>

                          <View style={[styles.fieldRow, styles.zIndexMin]}>
                            {renderTitleTextView("Address")}
                            {Platform.OS === WEB
                            ? <View style={[styles.textField]}>
                                <GooglePlacesAutoComplete
                                  handleAdressSelection={handleAdressSelection}
                                  defaultValue={props.address}
                                  ref={ref => (textInput7 = ref)}
                                  inputProps={{
                                    onKeyDown: e =>
                                      e.key === 'Enter' &&
                                      setTimeout(
                                        () => textInput8 && textInput8.focus(),
                                        200,
                                      ),
                                  }}
                                />
                              </View>
                            : <GooglePlacesAutoComplete
                                ref={(ref) => (textInput7 = ref)}
                                styles={{
                                  textInputContainer: {
                                    backgroundColor: "transparent",
                                    height: "auto",
                                    borderTopWidth: 0,
                                    borderBottomWidth: 0,
                                  },
                                  textInput: {
                                    marginTop: 0,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    ...styles.textField,
                                    height: 61,
                                  },
                                }}
                                placeholder=""
                                getDefaultValue={() => props.address || ""}
                                onPress={(data, details = null) => {
                                  handleAdressSelection(details);
                                  // props.onChangeTextAddress(details.formatted_address);
                                }}
                                textInputProps={{
                                  onSubmitEditing: () =>
                                    textInput8 && textInput8.focus(),
                                }}
                              />
                            }
                          </View>

                          {(Boolean(props.city) ||
                            Boolean(props.provice) ||
                            Boolean(props.country) ||
                            Boolean(props.zipCode)) && (
                            <React.Fragment>
                              <View style={[styles.fieldRow, styles.zIndexMin]}>
                                {renderTitleTextView("City")}
                                <TextInput
                                  style={[styles.textField]}
                                  allowFontScaling={false}
                                  onChangeText={props.onChangeTextCity}
                                  value={props.city}
                                  underlineColorAndroid="transparent"
                                  ref={(input) => {
                                    textInput8 = input;
                                  }}
                                  onSubmitEditing={() => {
                                    textInput9.focus();
                                  }}
                                  returnKeyType={"next"}
                                />
                              </View>

                              <View style={[styles.fieldRow, styles.zIndexMin]}>
                                {renderTitleTextView("Province/State")}
                                <TextInput
                                  style={[styles.textField]}
                                  allowFontScaling={false}
                                  onChangeText={props.onChangeTextProvince}
                                  value={props.province}
                                  underlineColorAndroid="transparent"
                                  ref={(input) => {
                                    textInput9 = input;
                                  }}
                                  onSubmitEditing={() => {
                                    textInput10.focus();
                                  }}
                                  returnKeyType={"next"}
                                />
                              </View>

                              <View style={[styles.fieldRow, styles.zIndexMin]}>
                                {renderTitleTextView("Postal Code/Zip Code")}
                                <TextInput
                                  style={styles.textField}
                                  allowFontScaling={false}
                                  onChangeText={props.onChangeTextZipCode}
                                  value={props.zipCode}
                                  underlineColorAndroid="transparent"
                                  ref={(input) => {
                                    textInput10 = input;
                                  }}
                                  onSubmitEditing={() => {
                                    textInput11.focus();
                                  }}
                                  returnKeyType={"next"}
                                />
                              </View>

                              <View style={[styles.fieldRow, styles.zIndexMin]}>
                               {renderTitleTextView("Country")}
                               <TextInput
                                  style={styles.textField}
                                  allowFontScaling={false}
                                  onChangeText={props.onChangeTextCountry}
                                  value={props.country}
                                  underlineColorAndroid="transparent"
                                  ref={(input) => {
                                    textInput11 = input;
                                  }}
                                />
                              </View>
                            </React.Fragment>
                          )}
                        </View>
                      </View>
                    </PageContainer>

                    <PageContainer smScreenFull={true}>
                      <View style={[styles.secArea, styles.borderThinTop]}>
                        {/* Notes */}

                        <Collapsible
                          title="Notes"
                          contentVisibility={props.contentVisibilityNotes}
                          handleOnPressCollapsible={
                            props.handleOnPressCollapsibleNotes
                          }
                        >
                          <View
                            style={[
                              styles.collapseContent,
                              styles.colBg,
                              styles.colPdSm,
                            ]}
                          >
                            <View style={styles.gridRow}>
                              <View style={styles.gridColFull}>
                                <View style={styles.fieldRow}>
                                  <TextInput
                                    style={[
                                      styles.textField,
                                      styles.textFieldSm,
                                      styles.textFieldWhite,
                                      styles.textAreaMax,
                                    ]}
                                    allowFontScaling={false}
                                    placeholder="Enter a new note"
                                    onChangeText={props.onChangeTextNotes}
                                    value={props.notes}
                                    multiline={true}
                                    numberOfLines={2}
                                    underlineColorAndroid="transparent"
                                  />
                                </View>
                              </View>
                            </View>
                          </View>
                        </Collapsible>

                        <Collapsible
                          title="To-dos"
                          contentVisibility={props.contentVisibilityQuestion}
                          handleOnPressCollapsible={
                            props.handleOnPressCollapsibleQuestion
                          }
                        >
                          <QuestionList type="contact" />
                        </Collapsible>
                      </View>
                    </PageContainer>

                    <PageContainer>
                      <View style={styles.secArea}>
                        <View style={[styles.subSection, styles.colPdTopCom]}>
                          <View style={stylesRes.tickButtonPd} dataSet={{media: ids.tickButtonPd}}>
                            <View style={styles.tickButtonRow}>
                              <View
                                style={[styles.tickButtonText, styles.flexRow]}
                              >
                                <Text style={styles.textPrime} allowFontScaling={false}>
                                  Is this contact part of your Careteam?
                                </Text>
                                <TouchableOpacity
                                  style={[
                                    styles.toolTipIconLink,
                                    styles.toolTipMg,
                                  ]}
                                  onPress={() => props.onShowTutorial(7)}
                                >
                                  <Image
                                    source={require("../../assets/icons/question.png")}
                                    style={styles.questionIcon}
                                    resizeMode={"contain"}
                                  />
                                </TouchableOpacity>
                              </View>
                              <View style={styles.tickButton}>
                                {Boolean(props.ticked) ? (
                                  <TickButton
                                    imagePath={"tickButton"}
                                    toggleTickCallback={() =>
                                      props.toggleTickCallback(false)
                                    }
                                    tickStyle={{ marginRight: 0 }}
                                  />
                                ) : (
                                  <TickButton
                                    imagePath={"untickButton"}
                                    toggleTickCallback={() =>
                                      props.toggleTickCallback(true)
                                    }
                                    tickStyle={{ marginRight: 0 }}
                                  />
                                )}
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>

                      <View style={styles.btnArea}>
                        <View style={stylesRes.btnViewSec}>
                          <View style={styles.fieldBtn}>
                            <View style={styles.btnBlockFull}>
                              <CancelButton
                               touchableOnpress={props.handleOnSubmitCancel}
                               disabled={props.saveWait}
                              />
                            </View>
                          </View>
                          <View style={styles.fieldBtnSp} />
                          <View style={styles.fieldBtn}>
                            <View style={styles.btnBlockFull}>
                              <TouchableOpacity
                                style={styles.submitSm}
                                underlayColor="rgba(0, 153, 168, 0.8)"
                                onPress={props.handleOnSubmitSave}
                                disabled={props.saveWait}
                              >
                                <View
                                  ref={(component) => (props._root = component)}
                                >
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent,
                                      ]}
                                    >
                                      {props.saveWait && (
                                        <View style={styles.actIndCom}>
                                          <ActivityIndicator size="small" />
                                        </View>
                                      )}
                                      <UIText
                                        style={[
                                          styles.submitBtnTextWhite,
                                          styles.bold]}
                                        title="Save"
                                      />
                                    </View>
                                  </View>
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </PageContainer>
                  </View>
                </View>
              </View>
            </View>
            {/* All tab */}
            <View
              title="Attachments"
              activeCircle={props.hasAttachments}
              style={styles.tabContentSection}
              attachmentCategory="Contact"
            >
              <PageContainer smScreenFull={true}>
                <View style={styles.attachWrapper}>
                {props.userAttachmentsSelected &&
                  props.userAttachmentsSelected.length !== 0 && (
                    <Attachments
                      attachmentCategory="Contact"
                      attachmentSpaceZero={true}
                    />
                  )}
                <AddAttachment
                  attachmentCategory="Contact"
                  btnText={"Add"}
                  btnPosition={"left"}
                  openCroper={props.openCroper}
                  tempCapturedImage={props.tempCapturedImage} 
                  onUploadCompleted={props.onUploadCompleted} 
                />
                </View>
              </PageContainer>
            </View>
          </Tabs>
        </View>
      </KeyboardAwareScrollViewHoc>
    </View>
  );
};
export default (addContact);
