import React, {Component} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import DOMPurify from 'dompurify';

let domPurifyConfig = {
  ALLOWED_ATTR: ['class', 'style', 'href', 'target']
};

const HTML_WRAPPER = `<!DOCTYPE html>
<html>
  <head>
    <style>
      .ql-editor a { text-decoration: none }
      .ql-editor { padding: 0!important }
    </style>
    <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
  </head>
  <body class="ql-editor" style="font-family: Asap, sans-serif; display: table; word-break: break-word; white-space: normal">$description</body>
</html>`

export default class Webview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seeMore: false,
      isOverflowDataExists: false,
      allContentVisible: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if(JSON.stringify(this.props.htmlContent) !== JSON.stringify(nextProps.htmlContent)) {
      this.setState({seeMore: false, isOverflowDataExists: false}, () => {
        this.updateIframe(nextProps.htmlContent);
        this.calculateSeeMoreVisibilty();
      });
    }
  }

  componentDidMount() {
    this.updateIframe(this.props.htmlContent);
    this.calculateSeeMoreVisibilty();
  }

  iframeLoadComplete = () => {
    this.iframeRef.style.height = (this.iframeRef.contentWindow.document.body.scrollHeight + 10) + 'px';
    this.calculateSeeMoreVisibilty();
  }

  updateIframe = (htmlContent) => {
    let sanitizedHtml = DOMPurify.sanitize(htmlContent, domPurifyConfig);
    sanitizedHtml = HTML_WRAPPER.replace('$description',sanitizedHtml)
    this.iframeRef.contentWindow.document.open();
    this.iframeRef.contentWindow.document.write(sanitizedHtml);
    this.iframeRef.contentWindow.document.close();
    this.iframeRef.style.height = (this.iframeRef.contentWindow.document.body.scrollHeight + 10) + 'px';
  }

  calculateSeeMoreVisibilty = () => {
    const {onContentScrollCompleteChange, alwaysFullContent} = this.props;
    const {seeMore} = this.state;
    const currentAllContentVisible = this.state.allContentVisible;

    let height = (this.iframeRef.contentWindow.document.body.scrollHeight + 10);
    const overflowDataExists = height > 80;
    const isAllContentVisible = Boolean(alwaysFullContent) || seeMore || !overflowDataExists;
    this.setState({isOverflowDataExists: overflowDataExists, allContentVisible: isAllContentVisible}, () => {
      if (onContentScrollCompleteChange && isAllContentVisible !== currentAllContentVisible) {
        console.log('isAllContentVisible', isAllContentVisible);
        onContentScrollCompleteChange(isAllContentVisible)
      }
    });
  };

  seeMoreClicked = () => {
    this.setState({seeMore: true}, () => {
      this.calculateSeeMoreVisibilty();
    });
  };

  seeLessClicked = () => {
    this.setState({seeMore: false}, () => {
      this.calculateSeeMoreVisibilty();
    });
  };

  linkClick = event => {
    event.preventDefault();
    let url = event.target.getAttribute('href');
    if (url) window.open(url, '_blank');
  };

  render() {
    const {seeMore, isOverflowDataExists} = this.state;
    const {alwaysFullContent} = this.props;
    return (
      <>
        <View
          style={[!Boolean(alwaysFullContent) && !seeMore && {height: 80}, {overflow: 'hidden'}]}>
          {/* Easiest way to bypass all application specific default styles */}
          <iframe id="iframe" ref={ref => this.iframeRef = ref} onLoad={this.iframeLoadComplete} scrolling="no" frameBorder="0"/>
          {/* {reactElements} */}
        </View>
        {!Boolean(alwaysFullContent) && !seeMore && isOverflowDataExists && (
          <View style={styles.flexAsFe}>
            <TouchableOpacity onPress={this.seeMoreClicked}>
              <Text
                style={[
                  styles.textPrime,
                  styles.resourceBlueTxt,
                  styles.textUnderline,
                ]}
                allowFontScaling={false}
                >
                See more
              </Text>
            </TouchableOpacity>
          </View>
        )}
        {!Boolean(alwaysFullContent) && seeMore && isOverflowDataExists && (
          <View style={styles.flexAsFe}>
            <TouchableOpacity onPress={this.seeLessClicked}>
              <Text
                style={[
                  styles.textPrime,
                  styles.resourceBlueTxt,
                  styles.textUnderline,
                ]}
                allowFontScaling={false}
                >
                See less
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </>
    );
  }
}
