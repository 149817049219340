import React from "react";
import { View, Text, Image } from "react-native";
import styles from "../../Css";
import UIText from "../../components/Text/text";
const pickerTemplate = (props) => {
  return (
    <View
      style={[
        props.pickerWithTitle
          ? styles.pickerWrapperWithTitle
          : styles.pickerWrapperWithoutTitle,
        props.invalid ? styles.inValidColor : styles.validColor,
      ]}
    >
      {Boolean(props.pickerWithTitle) && (
        <Text style={[styles.textView, styles.textViewPicker]} allowFontScaling= {false}>
          <UIText
            style={[styles.textLabel, styles.bold, styles.textColorLight]}
            title={props.pickerTitle}
          />
          {Boolean(props.requirdStar) && <UIText style={styles.textReq} title={"*"} />}
        </Text>
      )}

      <View
        style={[styles.pickerIconView, styles.pickerIconCent]}
        pointerEvents={"none"}
      >
        <View style={[styles.pickerArrowIcon]}>
          <Image
            style={[styles.imgContain]}
            source={require("../../assets/icons/dropdown-arrow.png")}
          />
        </View>
      </View>
      {props.children}
    </View>
  );
};

export default pickerTemplate;
