import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Platform } from 'react-native';
import { connect } from 'react-redux';
import * as RNLocalize from 'react-native-localize';

import Header from '../../components/Header/header';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import SavingConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import JournalEntryTypeHeader from '../../components/RoutineTemplate/JournalEntryTypeHeader';
import Medications from '../../components/RoutineTemplate/Medications/Edit';
import Activity from '../../components/RoutineTemplate/Activity/Edit';
import Symptoms from '../../components/RoutineTemplate/Symptoms/Edit';
import HealthData from '../../components/RoutineTemplate/HealthData/Edit';
import Reminder from '../../components/RoutineTemplate/Reminder';
import SpaceBarBottom from '../../components/SpaceBar/spaceBarBottom';
import * as FieldDetails from '../../components/TextInput/fieldDetails';
import PageContainer from '../../components/PageLayout/PageContainer';
import CommonConfirmBox from '../../components/ConfirmationBox/commonConfirmBox';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import MenuOverlayContainer from '../../components/PageLayout/MenuOverlayContainer';
import CancelButton from '../../components/Button/cancelButton';

import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';

import * as comTitles from './titles';
import * as actions from '../../store/actions/index';
import { hasValue } from '../../util/hashValueGenerator';
import { DASHBOARD, ROUTINES, MEDICATIONS } from '../../navigation/path';
import { scrollEvent } from './ScrollEventRegister';
import {
  alertBody,
  routineTitle,
  routinereminderTitle,
} from '../../GlobalFunctions/GlobalString';
import { getScrollHeight } from '../../util/commonUiLogic';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { WEB } from '../../util/platformWindowConfig';

class AddNewRoutine extends Component {
  constructor(props) {
    super(props);
    const {selectedEntry, windowHeight, windowWidth} = this.props;
    const {
      id = -1,
      color = 'rgba(248, 121, 60, 1)',
      name = '',
      description = '',
      defaultMetrics = [],
      defaultTreatments = [],
      defaultSymptoms = [],
      reminders = [],
      journalPublishedBy,
    } = selectedEntry || this.props.route?.params?.selectedEntry || {};
    this.id = id;
    this.color = color;
    this.name = this.props.route?.params?.selectedEntry ? this.handleName() : name;
    this.description = description;
    this.defaultMetrics = defaultMetrics ? defaultMetrics : [];
    this.defaultMdication = this.getMedication(
      defaultTreatments ? defaultTreatments : [],
    );
    this.defaultActivity = this.getActivity(
      defaultTreatments ? defaultTreatments : [],
    );
    this.defaultSymptoms = defaultSymptoms ? defaultSymptoms : [];
    this.reminders = reminders ? reminders.filter(item => item.reminder) : [];
    this.newReminders = [];

    let pushNotification =
      this.reminders && this.reminders.length > 0
        ? this.reminders[0].pushNotification
        : true;
    let emailNotification =
      this.reminders && this.reminders.length > 0
        ? this.reminders[0].emailNotification
        : false;
    this.hasValue = this.getRoutineHashValue(pushNotification, emailNotification);
    let fromPopup = null;
    if (props.route?.params && props.route?.params.fromPopup) {
      fromPopup = props.route?.params.fromPopup;
    }
    let reminderId = null;
    if (props.route?.params && props.route?.params.reminderId) {
      reminderId = props.route?.params.reminderId;
    }
    this.state = {
      saveWait: false,
      validation: true,
      mediView: false,
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      isScrollDisabled: true,
      isEmptyRoutine: true,
      fromPopup: fromPopup,
      reminderId: reminderId,
      publishedBy: journalPublishedBy,
      pushNotification: pushNotification,
      emailNotification: emailNotification,
      healthdataErrors: null,
      haveDuplicatedName: !this.eligibleToSave(journalPublishedBy),
    };
    this.scrollViewRef = null;
    this.yOffset = null;
    this.props.hideMobileMenu();
    this.reminderRef = null;
    this.pageScrollHeight = null;
    this.reminderComponentHeight = null;
    this.healthdataComponentHeight = null;
  }

  componentDidMount() {
    this.updateIsEmptyRoutine();
    pendoTrackEvent(this.props.route.name === ROUTINES.addRoutineTemplatePath ? 'Routines/@AddRoutine' : 'Routine/@Edit');
    this.props.navigation.addListener('blur', () => {
      this.props.showMobileMenu();
    });
    if (this.state.reminderId) {
      const { hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth } = this.props;
      setTimeout(() => {
        let position = this.pageScrollHeight - getScrollHeight(hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth) - this.reminderComponentHeight;
        //Reminder component top content height
        let remPos = Platform.OS === WEB ? 150 : 170;
        this.reminderRef.getExpiredReminderPosition() && this.reminderRef.getExpiredReminderPosition().forEach((item, index) => {
          remPos += item.height;
        });
        Platform.OS === WEB
          ? this.scrollViewRef && this.scrollViewRef?.scrollTo({ x: 0, y: position + remPos, animated: true })
          : this.scrollViewRef && this.scrollViewRef?.scrollToPosition(0, position + remPos, true);
        }, 1000);
    }
  }

  scrollToInvalidHealthdata = () => {
    const { hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth } = this.props;

    setTimeout(() => {
      let top = this.pageScrollHeight - getScrollHeight(hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth) - this.healthdataComponentHeight;
      //Reminder component top content height
      let position = Platform.OS === WEB ? 150 : 170;
      for (let item of this.defaultMetrics) {
        if (!item?.metric && !item?.metricDisplayUnitOfMeasure?.uomId) {
          break;
        }
        position += 90;
      }
      Platform.OS === WEB
        ? this.scrollViewRef && this.scrollViewRef?.scrollTo({ x: 0, y: position + top, animated: true })
        : this.scrollViewRef && this.scrollViewRef?.scrollToPosition(0, position + top, true);
      }, 500);
  }

  componentWillUnmount() {
    if (!this.state.mediView) {
      this.props.resetSelectedEntry();
    }
    this.props.showMobileMenu();
    this.props.resetDuplicates();
  }

  static getDerivedStateFromProps(props, state) {
    const {saveDisable} = props;
    if (saveDisable !== state.isEmptyRoutine) {
      return {
        isEmptyRoutine: saveDisable,
      };
    }
    const {windowHeight, windowWidth} = props;
    if (
      state.windowHeight !== windowHeight||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }
    return null;
  }

  handleName = () => {
    const { name } = this.props.route?.params?.selectedEntry;
    const { journalEntryTypes = [] } = this.props;
    let nameList = [];

    nameList = journalEntryTypes.filter(element => {
      return element && element.name && element.name.includes(name)
    });

    if (!nameList.length) {
      return name + ' copy';
    } else {
      let numberArray = [];
      let isHaveCopy = false;
      nameList.map(element => {
        let splitted = element && element.name ? element.name.split(' ') : '';
        if (splitted[splitted.length - 1] === 'copy') {
          isHaveCopy = true;
        }
        if (/^\d+$/g.test(splitted[splitted.length - 1])) {
          if (splitted.length >= 2) {
            if (splitted[splitted.length - 2] === 'copy') {
              numberArray.push(splitted[splitted.length - 1]);
            }
          }
        }
      });
      const splittedName = name.split(' ');
      if (!numberArray.length) {
        if (isHaveCopy) {
          if (splittedName[splittedName.length - 1] === 'copy') {
            return name + ' 2';
          }
          return name + ' copy 2';
        } else {
          return name + ' copy';
        }
      } else {
        numberArray.sort((a, b) => a - b);
        if (splittedName[splittedName.length - 1] === 'copy') {
          return (
            name + ' ' + (parseInt(numberArray[numberArray.length - 1]) + 1)
          );
        }
        if (/^\d+$/g.test(splittedName[splittedName.length - 1])) {
          splittedName.pop();
          if (
            splittedName.length &&
            splittedName[splittedName.length - 1] === 'copy'
          ) {
            return (
              splittedName.join(' ') +
              ' ' +
              (parseInt(numberArray[numberArray.length - 1]) + 1)
            );
          }
        }
        return (
          name + ' copy ' + (parseInt(numberArray[numberArray.length - 1]) + 1)
        );
      }
    }
  };

  getMedication = defaultTreatments => {
    const {treatmentsProcessed} = this.props;
    return defaultTreatments.filter(res => {
      if (res.treatmentType) {
        return res.treatmentType !== 5;
      } else {
        return treatmentsProcessed[res.treatment]?.treatmentType !== 5;
      }
    });
  };

  getActivity = defaultTreatments => {
    const {treatmentsProcessed} = this.props;
    return defaultTreatments.filter(res => {
      if (res.treatmentType) {
        return res.treatmentType === 5;
      } else {
        return treatmentsProcessed[res.treatment]?.treatmentType === 5;
      }
    });
  };

  updateColor = color => {
    this.color = color;
    this.forceUpdate();
  };

  updateName = name => {
    this.name = name;
    this.forceUpdate();
  };

  updateDescription = description => {
    this.description = description;
    this.forceUpdate();
  };

  updateIsEmptyRoutine = () => {
    const metricsFilter = this.defaultMetrics.filter(
      res => res.metric !== null,
    );

    const symptomsFilter = this.defaultSymptoms.filter(
      res => res.symptom !== null,
    );

    const dosagesMedicationFilter = this.defaultMdication.filter(
      res => res.treatment !== null,
    );

    const dosagesActivityFilter = this.defaultActivity.filter(
      res => res.treatment !== null,
    );

    const metricsEmpty = !(metricsFilter && metricsFilter.length > 0);
    const symptomsEmpty = !(symptomsFilter && symptomsFilter.length > 0);
    const dosagesMedicationEmpty = !(
      dosagesMedicationFilter && dosagesMedicationFilter.length > 0
    );
    const dosagesActivityEmpty = !(
      dosagesActivityFilter && dosagesActivityFilter.length > 0
    );

    this.setState({
      isEmptyRoutine:
        metricsEmpty &&
        symptomsEmpty &&
        dosagesMedicationEmpty &&
        dosagesActivityEmpty,
    });
  };

  updateMedication = defaultMdication => {
    this.defaultMdication = defaultMdication;
    this.updateIsEmptyRoutine();
  };

  updateActivity = defaultActivity => {
    this.defaultActivity = defaultActivity;
    this.updateIsEmptyRoutine();
  };

  updateHealthData = defaultMetrics => {
    this.defaultMetrics = defaultMetrics;
    this.updateIsEmptyRoutine();
  };

  updateSymptoms = defaultSymptoms => {
    this.defaultSymptoms = defaultSymptoms;
    this.updateIsEmptyRoutine();
  };

  updateReminders = reminders => {
    this.reminders = reminders;
    this.forceUpdate();
  };

  updateNewReminders = newReminders => {
    this.newReminders = newReminders;
    this.forceUpdate();
  };

  handleRedirectMedications = () => {
    const {navigation, setEntryType} = this.props;
    this.setState({mediView: true}, () => {
      const defaultMetrics = this.defaultMetrics;
      const defaultMdication = this.defaultMdication;
      const defaultActivity = this.defaultActivity;
      const defaultTreatments = defaultMdication.concat(defaultActivity);
      const defaultSymptoms = this.defaultSymptoms;
      const reminders = [...this.reminders];
      let count = reminders.length;
      this.newReminders.forEach(item => {
        if (item.accept) {
          reminders.push({...item, id: -count++});
        }
      });
      const color = this.color;
      const name = this.name;
      const description = this.description;
      const {selectedEntry = {}} = this.props;
      const {id = -1, copied} = selectedEntry ? selectedEntry : {};
      let selectedRoutine = {
        ...selectedEntry,
        id: copied ? -1 : id,
        defaultMetrics,
        defaultTreatments,
        defaultSymptoms,
        reminders,
        color,
        name,
        description,
      };
      setEntryType(selectedRoutine);
      navigation.push(
        MEDICATIONS.viewTherapiesPath, {
          from: this.props.route.name
        }
      );
    });
  };

  handleNotificationStatus = (pn, en) => {
    this.setState({
      pushNotification: pn,
      emailNotification: en,
    });
  };

  handleOnSubmitSave = (isAllow = false) => {
    this.setState({reminderBox: false, saveWait: true}, () => {
      const {treatmentsProcessed} = this.props;
      const defaultMetrics = this.defaultMetrics
        .filter(item => item.metric)
        .map(metric => metric.metric);
      const newMetrics = this.defaultMetrics.filter(item => !item.metric);
      const defaultMdication = this.defaultMdication
        .filter(item => item.treatment && !item.edited)
        .map(dosage => dosage.treatment);
      const newMedications = this.defaultMdication.filter(
        item => !item.treatment,
      );

      this.defaultMdication.filter(item => {
        if (item.edited && item.treatment) {
          let treatmentObj = treatmentsProcessed[item.treatment];
          treatmentObj = {
            ...treatmentObj,
            dosages: [
              {
                ...treatmentObj.dosages[Object.keys(treatmentObj.dosages)[0]],
                units: item.unit ? item.unit : item.units,
                quantity: item.quantity,
              },
            ],
          };
          newMedications.push(treatmentObj);
        }
      });
      const defaultActivity = this.defaultActivity
        .filter(item => item.treatment && !item.edited)
        .map(dosage => dosage.treatment);
      const newActivities = this.defaultActivity.filter(
        item => !item.treatment,
      );

      this.defaultActivity.filter(item => {

        if (item.edited && item.treatment) {
          let treatmentObj = treatmentsProcessed[item.treatment];
          treatmentObj = {
            ...treatmentObj,
            dosages: [
              {
                ...treatmentObj.dosages[Object.keys(treatmentObj.dosages)[0]],
                units: item.unit ? item.unit : item.units,
                quantity: item.quantity,
              },
            ],
          };
          newActivities.push(treatmentObj);
        }
      });

      const newTreatments = newMedications.concat(newActivities);
      const defaultTreatments = defaultMdication.concat(defaultActivity);
      const defaultSymptoms = this.defaultSymptoms
        .filter(item => item.symptom)
        .map(symptom => symptom.symptom);
      const newSymptoms = this.defaultSymptoms.filter(item => !item.symptom);
      const reminders = [...this.reminders];
      let count = reminders.length;
      let allow = true;
      this.newReminders.forEach(item => {
        if (item.accept) {
          reminders.push({...item, id: -count++, reminderEnd: item.reminderEnd ? new Date(item.reminderEnd).getTime() : null,
            reminderType: this.props.selectedEntry && this.props.selectedEntry?.type && this.props.selectedEntry.type === "DEVICE" ? "DEVICE" : "DEFAULT"});

        } else {
          allow = false;
        }
      });
      // Hack: Reset the reminder timezone to device timezone until we
      // update the UI
      reminders.forEach(reminder => {
        reminder.reminderTimezone = RNLocalize.getTimeZone();
        reminder.pushNotification = this.state.pushNotification;
        reminder.emailNotification = this.state.emailNotification;
      });
      const color = this.color;
      const name = this.name.trim();
      const description = this.description;
      const {selectedEntry = {}, createRoutine, journalUserId} = this.props;
      const {id = -1, copied, graphId = null} = selectedEntry
        ? selectedEntry
        : {};

      const payload = {
        newTreatments: newTreatments,
        newSymptoms: newSymptoms,
        newMetrics: newMetrics,
        journalEntryType: {
          ...selectedEntry,
          id: this.id !== -1 ? this.id : copied ? -1 : id,
          graphId: copied ? null : graphId,
          defaultMetrics,
          defaultTreatments,
          defaultSymptoms,
          reminders,
          color,
          name,
          description,
          isActive: true,
          journalPublishedBy: this.state.publishedBy
        },
      };
      const errors = this.defaultMetrics.map(item => !item?.error || item?.metric ? false : true);
      const uniqueName = this.eligibleToSave(this.state.publishedBy);
      if ((name && name.trim() === '') || !name || !uniqueName) {
        const {validation} = this.state;
        this.setState({validation: !validation, saveWait: false});

        setTimeout(() => {
          Platform.OS === WEB
            ? this.scrollViewRef?.scrollTo({ x: 0, y: 0, animated: true })
            : this.scrollViewRef?.scrollToPosition(0, 0, true);
        }, 500);
      } else if (errors.filter(item => item).length > 0) {
        this.setState({healthdataErrors: errors, saveWait: false});
        this.scrollToInvalidHealthdata();
      } else if (!allow && !isAllow) {
        this.setState({reminderBox: true, saveWait: false});
      } else {
        pendoTrackEvent('CreateRoutine > Save');
        createRoutine(payload, journalUserId, (success, data) => {
          if (success) {
            if (data.userDashboard) {
              this.props.updateDashboardGraphSuccess(data.userDashboard);
              if (
                data.userDashboard.status === 'ENABLED' &&
                data.journalEntryType.showOnDashboard
              ) {
                this.props.setPreviousGraphId(this.props.selectedGraphId)
                this.props.onSelectGraphItem(data.userDashboard.id);
              }
            }
            if (data.newTreatments && data.newTreatments.length > 0) {
              data.newTreatments.forEach(treat =>
                this.props.updateTreatments(treat),
              );
            }
            if (data.newSymptoms && data.newSymptoms.length > 0) {
              data.newSymptoms.forEach(sympt =>
                this.props.updateUserSymptom(sympt),
              );
            }
            if (data.newMetrics && data.newMetrics.length > 0) {
              data.newMetrics.forEach(metr => {
                this.props.updateUserMetrics(metr);
                this.props.updateUserMetricUOM(metr.metricDisplayUnitOfMeasure);
              });
            }
            const {showSuccessPopup} = this.props;
            this.props.navigation.reset({
              index: 0,
              routes: [{ name: DASHBOARD.path }],
            });
            setTimeout(() => {
              showSuccessPopup(6, data?.userDashboard?.id);
            }, 500);
          }
          this.setState({saveWait: false, confirmBox: false});
        });
      }
    });
  };

  eligibleToSave = (journalPublishedBy) => {
    const {journalEntryTypes = []} = this.props;
    const found = journalEntryTypes.filter(element => element && element.name?.trim().toLowerCase() === this.name?.trim().toLowerCase());

    let flag = true;
    if (found && found.length === 0) {
      return flag;
    }
    if (journalPublishedBy === "PARTNER") {
      return flag;
    }
    
    if (found && found.length > 1) {
      flag = false;
    }
    const selectedEntryId = this.props?.route?.params?.token || this.id;
    if (found && found.length === 1 && found?.[0]?.id !== parseInt(selectedEntryId)) {
      flag = false;
    }
    return flag;
  }

  onPressCancel = () => {
    const changeHash = this.getRoutineHashValue(this.state.pushNotification, this.state.emailNotification);
    if (changeHash === this.hasValue) {
      if (this.state.fromPopup) {
        this.props.setFromPopup(this.state.fromPopup);
      }
      if (this.props.route?.params?.from === DASHBOARD.path) {
        this.props.navigation.reset({
          index: 0,
          routes: [{ 
            name: DASHBOARD.path,
          }],
        });
      } else {
        this.props.navigation.reset({
          index: 0,
          routes: [{ 
            name: ROUTINES.path,
            params: {from: this.props.route.name}
          }],
        });
      }
    } else {
      this.setState({confirmBox: true});
    }
  };

  disabledScroll = (val, callBack) => {
    this.setState({isScrollDisabled: val}, () => {
      if (callBack) {
        callBack();
      }
    });
  };

  getRoutineChanges = () => {
    let flag = null;
    const changeHash = this.getRoutineHashValue(this.state.pushNotification, this.state.emailNotification);
    if (changeHash === this.hasValue) {
      flag = false;
    } else {
      flag = true;
    }
    return flag;
  };

  getRoutineHashValue = (pushNotification, emailNotification) => {
    return hasValue(
      this.color +
        ',' +
        this.name +
        ',' +
        this.description +
        ',' +
        JSON.stringify(this.defaultMetrics) +
        ',' +
        JSON.stringify(this.defaultMdication) +
        ',' +
        JSON.stringify(this.defaultActivity) +
        ',' +
        JSON.stringify(this.defaultSymptoms) +
        ',' +
        JSON.stringify(this.reminders) +
        ',' +
        JSON.stringify(this.newReminders) +
        ',' +
        pushNotification +
        ',' +
        emailNotification,
    );
  }

  scroll = position => {
    setTimeout(() => {
      Platform.OS === WEB
        ? this.scrollViewRef?.scrollTo({ x: 0, y: this.yOffset - position, animated: true })
        : this.scrollViewRef?.scrollToPosition(0, this.yOffset - position, true);
    }, 500);
  }

  removeHealthdataErrors = (index) => {
    this.setState(prevState => ({healthdataErrors: [...prevState.healthdataErrors.map((item, i) => i === index ? false : item)]}))
  }

  handleDeleteError = (index) => {
    const {healthdataErrors} = this.state;
    delete healthdataErrors[index];
    this.setState({healthdataErrors});
  }



  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {
      saveWait,
      validation,
      isEmptyRoutine,
      publishedBy,
      haveDuplicatedName,
    } = this.state;
    this.props.hideMobileMenu();

    return (
      <View style={styles.pageBodyWrapper} onTouchStart={() => this.props.onTouchRoutine(true)}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={this.onPressCancel}
          index={ROUTINES.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        {this.getRoutineChanges() ? (
          <MenuOverlayContainer onPressOpen={this.onPressCancel}
          />
        ) : null}
        <KeyboardAwareScrollViewHoc
          ref={value => (this.scrollViewRef = value)}
          hasNotch={this.props.hasNotch}
          extraScrollHeight={170}
          onScroll={(e) => {
            this.yOffset = e.nativeEvent.contentOffset.y
          }}
          scrollEnabled={this.state.isScrollDisabled}
          scrollEventThrottle={0}
          onContentSizeChange={(width, height) => {
            scrollEvent();
            this.pageScrollHeight = height;
          }}>
          <View style={styles.gridSection}>
            <PageContainer>
              <View style={styles.routineContentWrapper}>
                <View style={[styles.routineDescriptions, styles.colPdTopCom]}>
                  <View style={[styles.titleMainSection, styles.comMgBotSm]}>
                    <Text style={[styles.textPrimeBold]} allowFontScaling={false}>Create a routine</Text>
                  </View>
                </View>
              </View>
              <JournalEntryTypeHeader
                color={this.color}
                name={this.name}
                description={this.description}
                //description={this.description}
                updateColor={this.updateColor}
                updateName={this.updateName}
                updateDescription={this.updateDescription}
                validation={validation}
                publishedBy={publishedBy}
                isFromCopyToNew={this.props.selectedEntry?.isCopyToNew}
                haveDuplicatedName={haveDuplicatedName}
              />
            </PageContainer>
            <View style={[styles.comTopBorder, styles.viewMgTop]} />
            <PageContainer smScreenFull={true}>
              <View style={[styles.secArea, styles.bgThinGrey]}>
                <Medications
                  dosages={this.defaultMdication}
                  updateMedication={this.updateMedication}
                  handleRedirectMedications={this.handleRedirectMedications}
                  plusButton={false}
                  type={'RT'}
                  windowWidth={this.state.windowWidth}
                  disabledScroll={this.disabledScroll}
                />

                <View style={styles.subSection}>
                  <Activity
                    dosages={this.defaultActivity}
                    updateActivity={this.updateActivity}
                    plusButton={false}
                    type={'RT'}
                    handleRedirectActivity={this.handleRedirectMedications}
                    disabledScroll={this.disabledScroll}
                  />

                  <View style={styles.subSection}>
                    <Symptoms
                      symptoms={this.defaultSymptoms}
                      updateSymptoms={this.updateSymptoms}
                      plusButton={false}
                      type={'RT'}
                      disabledScroll={this.disabledScroll}
                    />

                    <View style={styles.subSection} onLayout={(event) => {this.healthdataComponentHeight = event.nativeEvent.layout.height}}>
                      <HealthData
                        metrics={this.defaultMetrics}
                        updateHealthData={this.updateHealthData}
                        plusButton={false}
                        type={'RT'}
                        windowWidth={this.state.windowWidth}
                        disabledScroll={this.disabledScroll}
                        healthdataErrors={this.state.healthdataErrors}
                        removeHealthdataErrors={this.removeHealthdataErrors}
                        handleDeleteError={this.handleDeleteError}
                      />

                      <View style={styles.subSection} onLayout={(event) => {this.reminderComponentHeight = event.nativeEvent.layout.height}}>
                        <Reminder
                          reminders={this.reminders}
                          newReminders={this.newReminders}
                          updateNewReminders={this.updateNewReminders}
                          updateReminders={this.updateReminders}
                          plusButton={false}
                          type={'RT'}
                          windowWidth={this.state.windowWidth}
                          reminderSection={true}
                          handleNotificationStatus={
                            this.handleNotificationStatus
                          }
                          scroll={this.scroll}
                          reminderId={this.state.reminderId}
                          getRef={value => (this.reminderRef = value)}
                        />
                        <View
                          style={[styles.secSepBorderBot, styles.zIndexMin]}
                        />
                        <View
                          style={[
                            styles.btnArea,
                            styles.bgWhite,
                            styles.btnPdSmFixed,
                          ]}>
                          <View style={stylesRes.btnViewSec} dataSet={{media: ids.btnViewSec}}>
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <CancelButton 
                                  touchableOnpress={this.onPressCancel}
                                  disabled={saveWait}
                                />
                              </View>
                            </View>
                            <View style={styles.fieldBtnSpSm} />
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={[
                                    styles.submitSm,
                                    styles.btnShadowCom,
                                    saveWait
                                      ? styles.submitBgOrangeLight
                                      : isEmptyRoutine
                                      ? [
                                          styles.submitBgOrange,
                                          styles.btnBlurState,
                                        ]
                                      : styles.submitBgOrange,
                                  ]}
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={() => this.handleOnSubmitSave()}
                                  disabled={
                                    saveWait ||
                                    isEmptyRoutine ||
                                    !this.getRoutineChanges
                                  }
                                  nativeID={'createRoutineSave'}>
                                  <View
                                    ref={component => (this._root = component)}>
                                    <View style={styles.btnSingleView}>
                                      <View
                                        style={[
                                          styles.btnSingle,
                                          styles.btnSingleCent,
                                        ]}>
                                        {saveWait && (
                                          <View
                                            style={[
                                              styles.indicatorIconView,
                                              styles.indicatorLtCom,
                                            ]}>
                                            <ActivityIndicator
                                              pdZero={true}
                                              size="small"
                                            />
                                          </View>
                                        )}
                                        <Text
                                          style={[
                                            styles.submitBtnTextWhite,
                                            styles.bold,
                                          ]} allowFontScaling={false}>
                                          {comTitles.SAVE}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                        {/* Autosugest Open */}
                        <SpaceBarBottom
                          windowWidth={this.state.windowWidth}
                          viewBg={FieldDetails.BGWHITE}
                          spaceBarLg={true}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </PageContainer>
          </View>
        </KeyboardAwareScrollViewHoc>
        {/* Calendar Modal */}
        {this.state.confirmBox &&
        <SavingConfirmation
          visibleModal={this.state.confirmBox}
          onBackdropPress={() => {
            this.setState({confirmBox: false});
          }}
          onRequestClose={() => {
            this.setState({confirmBox: false});
          }}
          alertBody={alertBody}
          alertTitle={routineTitle}
          handleCancel={() => {
            this.setState({confirmBox: false});
            if (this.props.route?.params?.from === DASHBOARD.path) {
              this.props.navigation.reset({
                index: 0,
                routes: [{ 
                  name: DASHBOARD.path,
                }],
              });
            } else {
              this.props.navigation.reset({
                index: 0,
                routes: [{ 
                  name: ROUTINES.path,
                  params: {from: this.props.route.name}
                }],
              });
            }
          }}
          handleConfirm={() => {
            this.setState({confirmBox: false});
            this.handleOnSubmitSave();
          }}
          confirmWait={saveWait}
          disabled={saveWait || isEmptyRoutine}
        />
        }

        {/* reminder saving confirmation box */}
        {this.state.reminderBox &&
        <CommonConfirmBox
          visibleModal={this.state.reminderBox}
          onBackdropPress={() => {
            this.setState({saveWait: false, reminderBox: false});
          }}
          onRequestClose={() => {
            this.setState({saveWait: false, reminderBox: false});
          }}
          alertTitle={routinereminderTitle}
          handleCancel={() => {
            this.setState({saveWait: false, reminderBox: false});
          }}
          handleConfirm={() => {
            this.handleOnSubmitSave(true);
          }}
        />
        }
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    createdRoutineEntry: state.routines.createdRoutineEntry,
    creatingRoutineEntry: state.routines.creatingRoutineEntry,
    selectedEntry: state.routines.journalEntryTypeSelected,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    journalUserId: state.uistate.journalUserId,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    saveDisable: state.therapie.saveDisable,
    journalEntryTypes: state.routines.journalEntryTypes,
    hasNotch: state.uistate.hasNotch,
    selectedGraphId: state.uistate.selectedGraphId,
    timeZoneWarning: state.uistate.timeZoneWarning,
    timeZone: state.routines?.user?.timezone,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    createRoutine: (payload, journalUserId, callBack) =>
      dispatch(
        actions.updateJournalEntryType(payload, journalUserId, callBack),
      ),
    resetSelectedEntry: () => dispatch(actions.clearSelectedEntryType()),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    showSuccessPopup: (id, graphId) =>
      dispatch(actions.showSuccessPopup(id, graphId)),
    setEntryType: journalEntryType =>
      dispatch(actions.setSelectedJournalEntryType(journalEntryType)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    setFromPopup: fromPopup => dispatch(actions.setFromPopup(fromPopup)),
    updateTreatments: treatment =>
      dispatch(actions.updateTreatments(treatment)),
    updateUserSymptom: symptom => dispatch(actions.updateNewSymptom(symptom)),
    updateUserMetrics: metric => dispatch(actions.updateUserMetrics(metric)),
    updateUserMetricUOM: uom => dispatch(actions.updateUserMetricUOM(uom)),
    updateDashboardGraphSuccess: data =>
      dispatch(actions.updateDashboardGraphSuccess(data)),
    onSelectGraphItem: graphId => dispatch(actions.selectGraphItem(graphId)),
    setPreviousGraphId: graphId => dispatch(actions.setPreviousGraphId(graphId)),
    onTouchRoutine: flag =>
      dispatch(actions.onTouchRoutine(flag)),
    resetDuplicates: () => dispatch(actions.resetDuplicates()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddNewRoutine);
