import React, {Component} from 'react';
import {View, Platform} from 'react-native';

import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Tabs from '../../components/Tab/Tab';
import Header from '../../components/Header/header';
import _ from 'underscore';
import * as actions from '../../store/actions/index';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
// Redux
import {connect} from 'react-redux';
import {
  getTherapiesColourCode,
  getAppointmentsColourCode,
} from '../../util/commonUiLogic';

// Filter Modal Content
import QuestionScrollView from './QuestionScrollView';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import {
  TODOS,
  DASHBOARD,
  JOURNALS,
  CONTACTS,
  MEDICATIONS,
} from '../../navigation/path';
import PageContainer from '../../components/PageLayout/PageContainer';
import SearchField from '../../components/Search/searchField';
import NoResultFound from '../../components/Search/noResultFound';
import SectionTitle from '../../components/Text/sectionTitle';
import PlusButtonDark from '../../components/Button/plusButtonDark';
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { ANDROID, TAB_BREAk_POINT_END } from '../../util/platformWindowConfig';
import { getScrollHeight } from '../../util/commonUiLogic';

class QuestionMainScreen extends Component {
  constructor(props) {
    super(props);
    const questions = this.getDisplayName();
    let questionsFilterObj = {};
    if (props.QuestionsFilter) {
      questionsFilterObj = QuestionMainScreen.handleModalOnPressFilterButton(
        props.QuestionsFilter,
        questions,
      );
    }
    this.state = {
      user: props.user,
      tabIndex: 0,
      allQuestions: questions,
      questions: questions,
      questionsOpen: QuestionMainScreen.getQuestionsOpen(questions),
      ...questionsFilterObj,
      searchKeyword: '',
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {QuestionsFilter, windowHeight, windowWidth} = props;

    let questionsFilterObj = {};
    let filterList = state.allQuestions;
    if (
      QuestionsFilter &&
      QuestionsFilter.currentInput &&
      QuestionsFilter.currentInput !== '' &&
      state.questions &&
      state.questions.length > 0 &&
      state.searchKeyword.length < QuestionsFilter.currentInput.length
    ) {
      filterList = state.questions;
    }
    questionsFilterObj = QuestionMainScreen.handleModalOnPressFilterButton(
      QuestionsFilter,
      filterList,
    );

    return {
      searchKeyword:
        QuestionsFilter && QuestionsFilter.currentInput
          ? QuestionsFilter.currentInput
          : '',
      ...questionsFilterObj,
      windowWidth: windowWidth,
      windowHeight: windowHeight,
    };
  }

  // componentWillUnmount = () => {
  //   if (this.props.QuestionsFilter) {
  //     this.handleOnPressClearFilters();
  //   }
  // };

  static getQuestionsOpen = questions => {
    const questionsOpen = {};
    if (questions) {
      Object.values(questions).forEach(res => {
        if (!res.closed) {
          questionsOpen[res.id] = res;
        }
      });
    }

    return questionsOpen;
  };

  getDisplayName = () => {
    let questionsTemp = this.props.questionDigest.questions;
    let questions = [];

    if (!_.isEmpty(questionsTemp)) {
      const userArtifactHealthQuestionsMap = this.getUserArtifactHealthQuestionsMap();
      const journalEntriesMap = this.getJournalEntriesMap();
      const treatmentsMap = this.getTreatmentsMap();
      const contactsMap = this.getContactsMap();

      Object.values(questionsTemp).forEach(res => {
        const userArtifactHealthQuestion =
          userArtifactHealthQuestionsMap[res.id];

        if (userArtifactHealthQuestion) {
          const journalEntrie =
            journalEntriesMap[userArtifactHealthQuestion.artifactId];
          const treatment =
            treatmentsMap[userArtifactHealthQuestion.artifactId];

          const contact = contactsMap[userArtifactHealthQuestion.artifactId];

          if (journalEntrie) {
            const entry = this.props.journalEntryTypesProcessed[
              journalEntrie.entryType
            ];
            if (entry) {
              res.displayName = journalEntrie.title;
              res.entryType = journalEntrie.entryType;
              res.isJournal = true;
              res.type = entry.isAppointment ? 4 : 1;
              res.color = entry.isAppointment
                ? getAppointmentsColourCode(journalEntrie.appointmentType)
                : entry.color;
              res.mainArtifactId = journalEntrie.artifactId;
              questions.push(res);
            }
          } else if (treatment) {
            res.displayName = treatment.name;
            res.isJournal = false;
            res.type = 2;
            res.color = getTherapiesColourCode(treatment.treatmentType);
            res.mainArtifactId = treatment.artifactId;
            questions.push(res);
          } else if (contact) {
            res.displayName = contact.givenName;
            res.isJournal = false;
            res.type = 3;
            res.color = '#FFFFFF';
            res.mainArtifactId = contact.artifactId;
            questions.push(res);
          }
        }
      });
    }
    return questions;
  };

  getUserArtifactHealthQuestionsMap = () => {
    const userArtifactHealthQuestions = {};
    this.props.questionDigest.userArtifactHealthQuestions.forEach(res => {
      userArtifactHealthQuestions[res.questionId] = res;
    });

    return userArtifactHealthQuestions;
  };

  getJournalEntriesMap = () => {
    const journalEntries = Object.values(this.props.journalEntriesProcessed);
    const journalEntriesMap = {};
    journalEntries.forEach(res => {
      journalEntriesMap[res.artifactId] = res;
    });

    return journalEntriesMap;
  };

  getTreatmentsMap = () => {
    const treatmentsMap = {};
    this.props.treatments.forEach(res => {
      treatmentsMap[res.artifactId] = res;
    });

    return treatmentsMap;
  };

  getContactsMap = () => {
    const contactsMap = {};
    this.props.contacts.forEach(res => {
      contactsMap[res.artifactId] = res;
    });

    return contactsMap;
  };

  // Filter Clear Button On Click
  handleOnPressClearFilters = () => {
    this.props.onClearQuestionFilter();
    // this.setState({
    //   questions: this.state.allQuestions,
    //   questionsOpen: QuestionMainScreen.getQuestionsOpen(
    //     this.state.allQuestions,
    //   ),
    // });
  };

  // Filter Button On Modal Pressed
  static handleModalOnPressFilterButton = (filter, allQuestions) => {
    let newFilter = [];
    let newFilterOpen = [];
    if (filter && filter.currentInput && filter.currentInput !== '') {
      Object.values(allQuestions).forEach(res => {
        if (
          (res.content &&
            res.content
              .toLowerCase()
              .includes(filter.currentInput.toLowerCase())) ||
          (res.answers &&
            res.answers.length > 0 &&
            QuestionMainScreen.hasAnswers(res.answers, filter.currentInput)) ||
          (res.displayName &&
            res.displayName
              .toLowerCase()
              .includes(filter.currentInput.toLowerCase()))
        ) {
          newFilter[res.id] = res;
          if (!res.closed) {
            newFilterOpen[res.id] = res;
          }
        }
      });
    } else {
      newFilter = allQuestions;
      newFilterOpen = QuestionMainScreen.getQuestionsOpen(allQuestions);
    }
    return {
      questions: newFilter,
      questionsOpen: newFilterOpen,
    };
  };

  static hasAnswers = (answers, currentInput) => {
    let filterAnswers = answers.filter(re =>
      re.content.toLowerCase().includes(currentInput.toLowerCase()),
    );
    if (filterAnswers && filterAnswers.length > 0) {
      return true;
    }

    return false;
  };

  handleOnPressBackButton = () => {
    if (this.props.QuestionsFilter) {
      this.handleOnPressClearFilters();
    } else {
      if (
        this.props.route?.params &&
        this.props.route?.params.from
      ) {
        this.props.route?.params.from ===
          TODOS.todoAttachToContactPath ||
        this.props.route?.params.from ===
          TODOS.todoAttachToJournalPath ||
        this.props.route?.params.from ===
          TODOS.todoAttachToTreatmentPath ||
        this.props.route?.params.from === TODOS.addToDoPath
          ? this.props.navigation.reset({
            index: 0,
            routes: [{ name: DASHBOARD.path }],
          })
          : this.handleBackNavigation();
      } else {
        this.handleBackNavigation();
      }
    }
  };

  handleBackNavigation = () => {
    handleCommonBackNavigation(this.props.navigation);
  }
  redirectJournal = (journalName) => {
    if (this.props.windowWidth < TAB_BREAk_POINT_END) {
      this.props.navigation.push(
        JOURNALS.viewJournalPath, {
          from: TODOS.path,
          journalName: journalName
        }
      );
    } else {
      this.props.navigation.push(
        JOURNALS.path, {
          from: TODOS.path,
          journalPath: JOURNALS.viewJournalPathSuffix,
          journalName: journalName
        }
      );
    }
  };

  redirectMedicationsTherapies = () => {
    this.props.navigation.push(MEDICATIONS.viewTherapiesPath, {
      from: TODOS.path,
    });
  };

  redirectContact = () => {
    this.props.navigation.push(CONTACTS.viewContactPath, {
      from: TODOS.path,
    });
  };

  answerdQuestion = (id, closed) => {
    let questions = [...this.state.questions];
    let questionsOpen = {...this.state.questionsOpen};
    if (closed && questionsOpen[id]) {
      delete questionsOpen[id];
    } else {
      const found = questions.find(res => res.id === id);
      if (found) {
        questionsOpen[id] = found;
      }
    }

    this.setState({
      questions: questions,
      questionsOpen: questionsOpen,
    });
  };

  getQuestionDigest = () => {
    const questions = this.getDisplayName();
    this.setState(
      {
        user: this.props.user,

        allQuestions: questions,
        questions: questions,
        questionsOpen: QuestionMainScreen.getQuestionsOpen(questions),
      },
      () => {
        if (this.props.QuestionsFilter) {
          QuestionMainScreen.handleModalOnPressFilterButton(
            this.props.QuestionsFilter,
            this.state.allQuestions,
          );
        }
      },
    );
  };

  searchTaskAndQuestions = val => {
    const {onFilterQuestions} = this.props;
    onFilterQuestions({currentInput: val});
  };

  handleRedirectAddQuestion = () => {
    this.props.onSaveQuestionToAttach('');
    this.props.navigation.push(
      TODOS.addToDoPath, {
        from: this.props.route.name,
      },
    );
    pendoTrackEvent('To-Dos@ > Add');
  };

  componentDidMount = () => {
    const {windowWidth, windowHeight} = this.props;
    this.setState({
      width: windowWidth,
      height: windowHeight,
    });
    this.props.navigation.addListener('focus', () => {
      let from = null;
      if (this.props.route.params && this.props.route.params.from) {
        from = this.props.route.params.from;
      }
      if (!from || (from && from.includes(this.props.route.name))) {
        this.handleOnPressClearFilters();
      }
      pendoTrackEvent('To-Dos@');
    });
    this.props.navigation.addListener('blur', () => {
      // this.componentWillUnmount();
      this.props.navigation.setParams({from: this.props.route.name});
    });
  };

  sortFunction = (a, b) => new Intl.Collator().compare(b?.openedDate, a?.openedDate)

  renderListItems = (questions) => {
    return (
      <QuestionScrollView
        data={Object.values(questions).sort(this.sortFunction)}
        user={this.state.user}
        tabIndex={this.state.tabIndex}
        redirectJournal={this.redirectJournal}
        redirectMedicationsTherapies={
          this.redirectMedicationsTherapies
        }
        redirectContact={this.redirectContact}
        answerdQuestion={this.answerdQuestion}
        getQuestionDigest={this.getQuestionDigest}
      />
    )
  }
  render() {
    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    const { QuestionsFilter, hasNotch, journalUserId, timeZoneWarning, timeZone } = this.props;
    const { windowHeight, windowWidth } = this.state;
    let scrollHeight = getScrollHeight(hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth);

    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          //showClearFilters={false}
          //    showClearFilters={this.props.QuestionsFilter ? true : false}
          showNotify={true}
          //  clearFiltersHandler={this.handleOnPressClearFilters}
          handleOnPressBackButton={this.handleOnPressBackButton}
          type="question"
          //  handleFilter={this.handleModalOnPressFilterButton}
          index={TODOS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        <KeyboardAwareScrollViewHoc
          hasNotch={this.props.hasNotch}
          extraScrollHeight={Platform.OS === ANDROID ? 200 : 70}
          scrollViewWebNone={true}
          scrollHeight={scrollHeight}>
          <View style={{minHeight: scrollHeight}}>
            <PageContainer>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                <View style={[styles.searchSection]}>
                    {/* button */}
                    <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt]}>
                      <View>
                        <SectionTitle 
                          smallTextTilte={true}
                          title={'To-dos'} 
                        />
                      </View>
                      <View style={[stylesRes.newConnectionBtnCr,styles.btnMgMd]} dataSet={{media: ids.newConnectionBtnCr}}>
                        <PlusButtonDark
                          touchableOnPress={this.handleRedirectAddQuestion}
                          btnPosition={'full'}
                          btnText={'New To-do'}
                          plusIconSm={true}
                          btnShadowNone={true}
                          nativeId={'to-dosAdd'}
                          newConnection={true}
                        />
                      </View>
                    </View>
                    {/* search bar */}
                    <View style={styles.fieldColSearch}>
                      <SearchField
                        searchText={
                          QuestionsFilter && QuestionsFilter.currentInput
                            ? QuestionsFilter.currentInput
                            : ''
                        }
                        placeholder="Search to-dos"
                        onChangeText={this.searchTaskAndQuestions}
                        showClearBtton={
                          QuestionsFilter &&
                          QuestionsFilter.currentInput &&
                          QuestionsFilter.currentInput !== ''
                            ? true
                            : false
                        }
                        clearFilter={this.handleOnPressClearFilters}
                        nativeId={'to-dosSearch'}
                        evantName={'To-Dos@ > Search'}
                      />
                    </View>
                </View>
                  {_.isEmpty(this.state.questionsOpen) &&
                    _.isEmpty(this.state.questions) &&
                    Boolean(QuestionsFilter) && <NoResultFound />}
                </View>
              </View>
            </PageContainer>
            {(!_.isEmpty(this.state.questionsOpen) ||
              !_.isEmpty(this.state.questions)) && (
              <Tabs pageContainer={true} smScreenFull={true}>
                {/* Open tab */}
                <View title="Open" style={[styles.tabContentSection]}>
                  <View style={styles.gridRow}>
                    <View style={[styles.gridColFull, styles.pdTopSm]}>
                      {/* TO-DO Question Loop Start */}

                      {this.state.questionsOpen ? (
                        _.isEmpty(this.state.questionsOpen) ? null : (
                         this.renderListItems(this.state.questionsOpen)
                        )
                      ) : null}

                      {/* TO-DO Question Loop End */}
                    </View>
                  </View>
                </View>
                {/* All tab */}
                <View title="All" style={[styles.tabContentSection]}>
                  <View style={styles.gridRow}>
                    <View style={[styles.gridColFull, styles.pdTopSm]}>
                      {/* TO-DO Question All Loop Start */}
                      {this.state.questions ? (
                        _.isEmpty(this.state.questions) ? null : (
                          this.renderListItems(this.state.questions)
                        )
                      ) : null}
                      {/* TO-DO Question All Loop End */}
                    </View>
                  </View>
                </View>
              </Tabs>
            )}
          </View>
        </KeyboardAwareScrollViewHoc>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    contactsProcessed: state.routines.contactsProcessed,
    questionDigestProcessed: state.routines.questionDigestProcessed,
    user: state.routines.user,
    questions: state.question.questions,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    QuestionsFilter: state.routines.QuestionsFilter,
    treatments: state.routines.treatments,
    contacts: state.routines.contacts,
    questionDigest: state.routines.questionDigest,
    hasNotch: state.uistate.hasNotch,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onClearQuestionFilter: () => dispatch(actions.clearQuestionFilter()),
    onFilterQuestions: filter => dispatch(actions.filterQuestions(filter)),
    onSaveQuestionToAttach: question =>
      dispatch(actions.saveQuestionToAttach(question)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionMainScreen);
