import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";

const ContactsListItem = props => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  return (
    <View
      key={props.key}
      style={[styles.contListItemWrapper, styles.greyBorderThin]}
    >
      <TouchableOpacity
        onPress={() => {
          props.onPressContactList(props?.contacts?.id);
        }}
      >
        <View style={[styles.gridItemPd]}>
          <View style={stylesRes.contListItemBlock}>
            <View
              style={[stylesRes.contListItemDesc, stylesRes.contListItemFull]}
            >
              <View style={styles.contListItemIconBlock}>
                <Image
                  style={[styles.personIcon]}
                  resizeMode={"contain"}
                  source={require("../../assets/icons/user.png")}
                />
              </View>
              <View style={[styles.contListItemTextBlock, styles.flexJcCt]}>
                {!!props.contacts.givenName && (
                  <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                    {props.contacts.givenName}
                  </Text>
                )}
                {!!props.contacts.organization && (
                  <Text style={[styles.textPrime, styles.textColorLight]} allowFontScaling={false}>{props.contacts.organization}</Text>
                )}
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};
export default (ContactsListItem);
