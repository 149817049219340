import React from 'react';
import {View, TextInput, TouchableOpacity, Image} from 'react-native';
import styles from '../../Css';
import FieldTitleCommon from './fieldTitleCommon';
import * as FontScaling from '../../components/Text/fontScaling';

const inputFieldSpec = React.forwardRef((props, ref) => (
  <View style={[styles.textInputWrapper, styles.postRelative]}>
    <FieldTitleCommon
      fieldAlign={props.fieldAlign}
      fieldBg={props.fieldBg}
      title={props.title}
      requirdStar={props.requirdStar}
      fieldLt={props.fieldLt}
      fieldSm={props.fieldSm}
      fieldRg={props.fieldRg}
      errorText={props.errorText}
    />
    <TextInput
      style={[
        styles.textInputField,
        props.fieldInfo ? styles.frameInfoPd : styles.framePd,
        props.fieldBg && props.fieldBg === 'grey'
          ? styles.bgThinGrey
          : props.fieldBg && props.fieldBg === 'blue'
          ? styles.bgBlueExLight
          : props.fieldBg && props.fieldBg === 'transparent'
          ? styles.bgTransparent
          : styles.bgWhite,
        props.fieldAlign && props.fieldAlign === 'center'
          ? styles.textAlignCenter
          : styles.textAlignLeft,
        props.invalid ? styles.inValidColor : styles.validColor,
        props.fieldStyle,
      ]}
      allowFontScaling={false}
      onBlur={props.onBlur}
      underlineColorAndroid="transparent"
      value={props.value ? props.value : ''}
      onChangeText={props.onChangeText}
      editable={props.editable}
      placeholder={props.placeholder}
      returnKeyType={props.returnKeyType}
      onSubmitEditing={props.onSubmitEditing}
      onFocus={props.onFocus}
      ref={ref}
      maxLength={props.maxLength}
      textContentType={props.textContentType}
      keyboardType={props.keyboardType}
      maxFontSizeMultiplier={FontScaling.fontScaleField}
    />
    {Boolean(props.removeIcon) && (
    <View style={[styles.passHintIn, styles.rightZero]}>
        <TouchableOpacity
            style={styles.passHintViewTouch}
            onPress={props.touchablePress}
        >
            <View style={styles.passHintDetails}>
            <Image
                style={styles.AutoCompleteRemoveIcon}
                source={require("../../assets/icons/remove-icon.png")}
                resizeMode={"contain"}
            />
            </View>
        </TouchableOpacity>
    </View>
     )}
  </View>
));

export default inputFieldSpec;
