export const validateEmail = email => {
  //var re = /\S+@\S+\.\S+/;
  // eslint-disable-next-line no-useless-escape
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
  return re.test(email);
};

export const validateNumber = number => {
  var re = /^-{0,1}\d*[\s.-]{0,1}\d*[\s.-]{0,1}\d+$/;
  return re.test(number);
};
