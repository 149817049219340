import React from "react";
import styles from "../../../../Css";
import stylesResponsive from "../../../../CssResponsive";
import { View, TouchableOpacity, Image } from "react-native";

const addButton = (props) => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  const { addMenu, openAddMenu } = props;
  return (
    <TouchableOpacity onPress={openAddMenu}>
      <View style={[stylesRes.addButtonSm]}>
        {!Boolean(addMenu) ? (
          <Image
            style={[styles.imgContain]}
            source={require("../../../../assets/main-nav/add-menu.png")}
          />
        ) : (
          <Image
            style={[styles.imgContain]}
            source={require("../../../../assets/main-nav/add-menu-close.png")}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

export default (addButton);
