import React from 'react'
import {View,Text,Image,TouchableOpacity} from 'react-native';
import styles from '../../../Css'
import { TAB_BREAk_POINT_START } from '../../../util/platformWindowConfig';

const ClearAllFilter = (props)=>{
  if (props.windowWidth > TAB_BREAk_POINT_START) {
    return(
       <View style={styles.clearAllWrapper}>
        <TouchableOpacity style={[styles.clearAllTouchable]} onPress={props.clearAllFilterHandler}>
          <Image style={styles.clearCloseIcn} source={require('../../../assets/health-journey-icons/close.png')}/>
              <Text style={styles.clearAllTouchableText}>Clear All Filters</Text>
         </TouchableOpacity>
       </View> 
  )
  } else{
     return(
       <TouchableOpacity 
         disabled={props.newItemCount() === 0} 
         onPress={() => props.clearAllFilterHandler()}
        >
          <View style={[styles.clearAllView,props.newItemCount() === 0 && styles.clearFilterDisabled]}>
            <Image
            style={[styles.filterIcn]}
            source={require('../../../assets/health-journey-icons/filter-icon.png')}
            />
            {props.newItemCount() > 99 ? (
                 <View style={styles.filterCount}>
                    <Text style={[styles.textExMicSm, styles.fiveHudrredFtWgt, styles.textWhite]}>{`99+`}</Text>
                 </View>
                 ):(
                 <View style={styles.filterCount}>
                    <Text style={[styles.textExMicSm, styles.fiveHudrredFtWgt, styles.textWhite]}>{props.newItemCount()}</Text>
                 </View>
                )
            }
            <Text style={[styles.clearAllText,styles.clearAllTitleBold]}>Clear All Filters</Text>
           </View>
       </TouchableOpacity>
    )
  }
}
export default ClearAllFilter;

