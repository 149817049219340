export const QUESTION_TYPES = {
  multipleAnswerQuestion: 'MULTIPLEANSWERQUESTION',
  choiceQuestion: 'CHOICEQUESTION',
  descriptiveQuestion: 'DESCRIPTIVESECTION',
  longAnswerQuestion: 'LONGQUESTION',
  slider: 'RANKQUESTION',
  symptomSliderQuestion: 'SYMPTOMSLIDERQUESTION',
  surveyTable: 'TABLE',
};

export const QUESTION_DIRECTION = {
  prev: 'PREV',
  next: 'NEXT',
  submit: 'SUBMIT',
};