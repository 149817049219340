import React, { Component } from "react";
import { View, Text } from "react-native";
import { connect } from "react-redux";
import styles from "../../../Css";
import RadioForm from "../../../modules/radioButton/RadioButton";
import * as actions from "../../../store/actions/index";
import Dates from "../../DateTimePicker/Dates";
import InputField from "../../../components/TextInput/inputField";
import * as FieldDetails from "../../../components/TextInput/fieldDetails";
import WarningDuplication from '../../../components/Warning/warningDuplication';
const radioProps = [
  { label: "1 month", value: 1 },
  { label: "3 months", value: 2 },
  { label: "Custom", value: 3 },
];

class GraphName extends Component {
  constructor(props) {
    super(props);
    const date1 = new Date(props.startDate);
    const date2 = new Date(props.endDate);
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    this.startDate = props.startDate;
    this.endDate = props.endDate;
    this.state = {
      name: props.graphName || "",
      visibleCalModal: false,
      viewBy: !(props.startDate && props.endDate)
        ? 0
        : diffDays === 30
          ? 0
        : diffDays === 90
            ? 1
            : 2,
      endDateClick: false,
      startDateClick: false,
      validExistingGraphName: true,
    };
  }

  componentDidMount() {
    if (this.state.viewBy === 0 && !this.props.graphId) {
      let startDate = new Date();
      let endDate = new Date();
      endDate.setDate(endDate.getDate() - 30);
      this.startDate = endDate;
      this.endDate = startDate;
      this.setState(
        {
          viewBy: 0,
        },
        () => {
          this.props.updateStartDate(endDate.getTime());
          this.props.updateEndDate(startDate.getTime());
        }
      );
    }
  }

  changeGraphName = (name) => {
    let trimmedName = name;
    if (name) {
      trimmedName = name.trim();
    }
    const filterdGraph = this.props.dashboard.filter((graph)=>
          graph.name === trimmedName && 
          graph.id !== this.props.graphId
        );

    if (name) {
      this.props.setGraphNameValidate(true);
    } else {
      this.props.setGraphNameValidate(false);
    }
    this.setState({ name: name });
    this.props.updateGraphName(name);

    if(filterdGraph.length > 0){
      this.props.setValidExistingGraphName(false);
    }else{
      this.props.setValidExistingGraphName(true);
    }
  };

  handleOnPressRadioForm = (value) => {
    let startDate = new Date();
    this.setState({ viewBy: value - 1 });


    if (value === 3) {
      this.startDate = null;
      this.endDate = null;
      this.setState(
        {
          viewBy: 2,
        },
        () => {
          this.props.updateStartDate(null);
          this.props.updateEndDate(null);
        }
      );
    } else if (value === 1) {
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 30);
      this.startDate = endDate;
      this.endDate = startDate;
      this.setState(
        {
          viewBy: 0,
        },
        () => {
          this.props.updateStartDate(endDate.getTime());
          this.props.updateEndDate(startDate.getTime());
        }
      );
    } else if (value === 2) {
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 90);
      this.startDate = endDate;
      this.endDate = startDate;
      this.setState(
        {
          viewBy: 1,
        },
        () => {
          this.props.updateStartDate(endDate.getTime());
          this.props.updateEndDate(startDate.getTime());
        }
      );
    }
  };

  updateStartDate = (val) => {
    this.startDate = val !== null ? new Date(val) : null;

    this.setState({ viewBy: 2 }, () =>
      this.props.updateStartDate(val !== null ? this.startDate.getTime() : null)
    );
  };

  updateEndDate = (val) => {
    this.endDate = val !== null ? new Date(val) : null;
    this.setState({ viewBy: 2 }, () =>
      this.props.updateEndDate(val !== null ? this.endDate.getTime() : null)
    );
  };



  render() {
    return (
      <View style={styles.gridRow}>
        <View style={styles.gridColFull}>
          <View style={[styles.secArea]}>
            <View style={[styles.itemSection, styles.comMgBotMd]}>
              <View style={[styles.secSubContentArea]}>
                <View style={[styles.fieldRow, styles.fieldRowNoMg]}>
                  <View style={styles.fieldRowWrapperFull}>
                    {/* TextInput */}
                    <InputField
                      title={"Graph Title"}
                      fieldLt={true}
                      fieldBg={FieldDetails.BGWHITE}
                      onChangeText={this.changeGraphName}
                      value={this.state.name}
                      autoFocus={true}
                      invalid={!this.props.validGraphName ? true : false}
                      warningDuplication={!this.props.validExistingGraphName ? true : false}
                      requirdStar={true}
                    />
                  </View>
                  {!Boolean(this.props.validExistingGraphName) &&
                  <WarningDuplication
                    errorMessage={"Duplicate graph name found. please try another graph name"}
                  />
                  }
                </View>
              </View>
            </View>

            <View style={styles.fieldRow}>
              <View style={styles.fieldCol}>
                <View style={[styles.fieldTopic, styles.pdBotXs]}>
                  <Text style={styles.textPrime} allowFontScaling={false}>View by:</Text>
                </View>
                <View style={[styles.radioSecFul, styles.pdBotExSm]}>
                  <RadioForm
                    style={styles.radioSet}
                    radio_props={radioProps}
                    initial={this.state.viewBy}
                    onPress={this.handleOnPressRadioForm}
                    formHorizontal={true}
                    buttonColor={"rgba(152, 152, 154, 0.6)"}
                    selectedButtonColor={"#FF8300"}
                  />
                </View>
              </View>
            </View>

            <Dates
              startDate={this.startDate}
              endDate={this.endDate}
              updateStartDate={this.updateStartDate}
              updateEndDate={this.updateEndDate}
            />
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    graphId: state.rollinggraphs.id,
    graphName: state.rollinggraphs.graphName,
    startDate: state.rollinggraphs.startDate,
    endDate: state.rollinggraphs.endDate,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    dashboard: state.routines.dashboard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGraphName: (name) => dispatch(actions.updateGraphName(name)),
    updateStartDate: (startDate) =>
      dispatch(actions.updateStartDate(startDate)),
    updateEndDate: (endDate) => dispatch(actions.updateEndDate(endDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphName);
