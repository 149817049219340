import React, { useEffect, useState, useRef, Fragment } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    TextInput,
    Image,
    Animated,
    Easing,
    ScrollView
} from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash'; 
import styles from '../../../../Css';
import CategoriesFilterSubExpandableComponent from './CategoriesFilterSubExpandableComponent';
import TickButton from '../../../../components/CheckBox/CheckBox';

const CategoriesFilterExpandableComponent =
    (props) => {
        const { item, onClickFunction, isUncheckedAll, setClearAllCheckboxes, getSelectedCategoriesObj, selectedCategories, setSelectedCategoryObject } = props;
        const [layoutHeight, setLayoutHeight] = useState(0);
        const [state, setState] = useState(false);
        const [isItemSelected, setItemSelection] = useState(false);
        const [subCategoriesItems, setSubCategoriesItems] = useState(item.subcategory);
        const [selectedSubCategoriesCount, setSelectedSubCategoriesCount] = useState(0);
        const [selectedCategoryName, setSelectedCategoryName] = useState(null);
        const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);
        const [selectedSubCategoryNames, setSelectedSubCategoryNames] = useState([]);
        const [searchString, setSearchString] = useState("");
        const spinValue = useRef(undefined);
        const mounted = useRef(null);

        const spin = spinValue?.current?.interpolate({
            inputRange: [0, 1],
            outputRange: ['0deg', '-90deg'],
        });

        useEffect(() => {
            if (selectedCategories) {
                const isCategorySelected = selectedCategories.find(x => x.categoryName === item.category_name);
                if ((isCategorySelected && selectedCategoryName) || (!mounted.current && isCategorySelected)) {
                    setItemSelection(true);
                    setSelectedCategoryName(isCategorySelected.categoryName);
                    setSelectedSubCategoriesCount(isCategorySelected?.subCategoryList.length)
                    setSelectedSubCategoryNames(isCategorySelected?.subCategoryList || [])
                }
            }
            //eslint-disable-next-line
        }, [selectedCategories]);

        useEffect(() => {
            if (spinValue.current) {
                Animated.timing(spinValue.current, {
                    toValue: item.isExpanded ? 0 : 1,
                    duration: 160,
                    easing: Easing.linear,
                    useNativeDriver: true,
                }).start();
            } else {
                spinValue.current = new Animated.Value(1);;
            }
        }, [item.isExpanded])

        useEffect(() => {
            if (item.isExpanded) {
                setLayoutHeight(null);
            } else {
                setLayoutHeight(0);
            }
        }, [item.isExpanded]);

        useEffect(() => {
            if(_.isEqual(isUncheckedAll, true)){
                setItemSelection(false);
                setSelectedSubCategoryNames([]);
                setSelectedCategoryName(null);
                setSelectedCategoryValue(null);
            }
        }, [isUncheckedAll]);

        const categorySelectHandler = (categoryName, categoryValue, itemSelected) => {
            if (itemSelected) {
                setSelectedCategoryName(categoryName);
                setSelectedCategoryValue(categoryValue);
            } else {
                setSelectedCategoryName(null);
                setSelectedCategoryValue(null);
            }
            setState(!state);
        }

        useEffect(() => {
            if(!_.isEqual(setSelectedCategoryObject, {})){
                for(const categoryObject of setSelectedCategoryObject) {
                    if(_.isEqual(categoryObject.value, "MEDICATIONS")) {
                        setSelectedSubCategoryNames(categoryObject.subCategoryList)
                    }
                }
            }
        }, [setSelectedCategoryObject]);

        const subCategotySelectionHandler = (subCategoryName, isItemChecked) => {


            if (isItemChecked && !selectedSubCategoryNames?.includes(subCategoryName)) {

                setSelectedSubCategoryNames([...selectedSubCategoryNames, subCategoryName]);
                setSelectedSubCategoriesCount(selectedSubCategoryNames.length + 1);
                
                setItemSelection(true);
                categorySelectHandler(item.category_name, item.value, true)
            }
            if (!isItemChecked && selectedSubCategoryNames.includes(subCategoryName)) {


                setSelectedSubCategoryNames(selectedSubCategoryNames.filter(item => item !== subCategoryName));
                setSelectedSubCategoriesCount(selectedSubCategoryNames.length - 1);

                if (selectedSubCategoryNames.length - 1 === 0) {
                    setItemSelection(false);
                    categorySelectHandler(null, null, false)
                }
            }
            setState(!state);
        }

        const selectAll = (val, flag) => {
          if (flag) {
            setSelectedSubCategoryNames(item.subcategory.map(item => item.name));
            setSelectedSubCategoriesCount(item.subcategory.length);
            setItemSelection(true);
            categorySelectHandler(item.category_name, item.value, true)
          } else {
            setSelectedSubCategoryNames([]);
            setSelectedSubCategoriesCount(0);
            setItemSelection(false);
            categorySelectHandler(null, null, false)
          }
          setState(!state);
        }

        const onSearchSubCategory = (pSubcategoryItems, searchKey) => {
            let searchedItem = pSubcategoryItems.filter(function (item) {
                if (item.name && searchKey && item.name.toLowerCase().includes(searchKey.toLowerCase())) {
                    return item;
                }
            });
            setSubCategoriesItems(searchedItem);
            setSearchString(searchKey);
        }

        useEffect(() => {
            if (mounted.current) {
              let obj = {
                  categoryName: selectedCategoryName,
                  value: selectedCategoryValue,
                  subCategoryList: selectedSubCategoryNames
              };
              getSelectedCategoriesObj(obj);
            } else {
              mounted.current = true;
            }
            //eslint-disable-next-line
        }, [state]);

        const checkValidity = () => {
          return selectedCategories.find(x => x.categoryName === item.category_name)?.subCategoryList?.length > 0 && subCategoriesItems.length > 0
        }
        
        return (
                <View>
                    <View style={[styles.flexRow, styles.flexJcFs, styles.flexAiCt]}>
                        {/*Header of the Expandable List Item*/}
                        <View>
                            <View style={[styles.flexRow]}>
                                <TouchableOpacity
                                    activeOpacity={0.8}
                                    onPress={() => item.subcategory.length > 0
                                        ?   onClickFunction()
                                        :   [setItemSelection(!isItemSelected), categorySelectHandler(item.category_name, item.value, !isItemSelected)]}>
                                    <View style={[styles.checkboxContainer, styles.ctgryListRtLtTpBt]}>
                                    {Boolean(isItemSelected) ? (
                                            <TickButton
                                                disabled={checkValidity()}
                                                imagePath={'tickButton'}
                                                toggleTickCallback={() =>
                                                item.subcategory.length > 0
                                                    ?   onClickFunction()
                                                    :   [setItemSelection(!isItemSelected),
                                                        categorySelectHandler(item.category_name, item.value, !isItemSelected)]
                                                }
                                                tickStyle={{ marginRight: 0 }}
                                            />
                                        ) : (
                                            <TickButton
                                                imagePath={'untickButton'}
                                                toggleTickCallback={() =>
                                                    item.subcategory.length > 0
                                                    ?   onClickFunction()
                                                    :   [setItemSelection(!isItemSelected),
                                                    categorySelectHandler(item.category_name, item.value, !isItemSelected)]
                                            }
                                            tickStyle={{ marginRight: 0 }}
                                            />
                                        )}
                                        <Text style={[item.subcategory.length > 0 ? styles.textPadding : styles.textPaddingLg, styles.textPrimeBold, !isItemSelected && {opacity: 0.5}]}>
                                            {`${item.category_name}`}
                                        </Text>

                                        {`${item.subcategory.length}` > 0 ?
                                            <View style={[styles.flexRow, styles.flexJcFs, styles.flexAiCt, styles.mgLtFive]}>
                                                <Text style={[styles.textCompStyling, styles.textPrime, styles.lightSelectedAmntTxtClr]}>
                                                    {selectedSubCategoryNames.length > 0 ? item.subcategory.length !== selectedSubCategoryNames.length ? `${selectedSubCategoryNames.length}` : `ALL` : ``}
                                                </Text>
                                                <View style={styles.iconLtSp}>
                                                    <Animated.View style={[styles.customArrowWdHg, { transform: [{ rotate: spin ? spin : '-90deg' }] },
                                                    item.isExpanded && { bottom: 0 }]}>
                                                        <Image
                                                            style={[styles.customArrowWdHg]}
                                                            source={require('../../../../assets/health-journey-icons/dropdown-arrow-black.png')}
                                                        />
                                                    </Animated.View>
                                                </View>
                                            </View>
                                        : null}
                                    </View>
                                </TouchableOpacity>
                            </View>

                            <View
                                style={{
                                    height: layoutHeight,
                                    overflow: 'hidden',
                                    paddingRight: 12
                                }}>
                                {`${item.subcategory.length}` > 0 ?
                                    <View>
                                        <View style={styles.dateFilterLine} />  
                                        <View style={[styles.flexRow, styles.flexJcFs, styles.flexAiCt, styles.infoPdTopBotExSm, styles.cardRowPdLtRt, styles.pdLtEight]}>
                                            <View style={[styles.tpOne, styles.pdLtNineteen]}>
                                                <Image
                                                    style={{ width: 15, height: 15 }}
                                                    source={require('../../../../assets/health-journey-icons/search-icon.png')}
                                                />
                                            </View>
                                            <TextInput
                                                style={[styles.textPrimeMd, styles.wdFullPer, styles.mgLtFive]}
                                                placeholder={"Search My " + `${item.category_name}`}
                                                placeholderTextColor={"#C4C4C4"}
                                                underlineColorAndroid="transparent"
                                                onChangeText={(value) => onSearchSubCategory(item.subcategory, value)}
                                            />
                                        </View>
                                    </View> : null}

                                {/*Content under the header of the Expandable List Item*/}
                                <ScrollView showsVerticalScrollIndicator={true} style={[styles.colapseViewMaxHeight, {maxWidth:250}]}>
                                  {!Boolean(searchString) && subCategoriesItems?.length > 0 && (
                                    <CategoriesFilterSubExpandableComponent
                                      item={{name: "Select All"}}
                                      setSubItemSelection={(val, isChecked) => selectAll(val, isChecked)}
                                      selectedSubCategories={selectedSubCategoryNames.length === item.subcategory.length && !isUncheckedAll ? [{subCategoryList: ["Select All"]}] : [{subCategoryList: ["Test"]}]}
                                      isUncheckedAll={isUncheckedAll}
                                    />
                                  )}
                                {Boolean(subCategoriesItems) && subCategoriesItems?.map((item, key) => (
                                  <Fragment key={key + item.name}>
                                    <CategoriesFilterSubExpandableComponent
                                      item={item}
                                      key={key}
                                      setSubItemSelection={(val, isChecked) => subCategotySelectionHandler(val, isChecked)}
                                      selectedSubCategories={selectedCategories}
                                      isUncheckedAll={isUncheckedAll}
                                    />
                                  </Fragment>
                                ))}
                                </ScrollView>
                            </View>
                        </View>
                    </View>
                    <View style={styles.dateFilterLine} />
                </View>
        );
    };

    const mapStateToProps = state => {
        return {
            setSelectedCategoryObject: state.healthJourneyFiltering.setSelectedCategoryObject,
        };
    };
    
    const mapDispatchToProps = dispatch => {
        return {};
    };
    
    export default connect(mapStateToProps, mapDispatchToProps) (CategoriesFilterExpandableComponent);