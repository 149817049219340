import React from 'react';
import {View, TouchableOpacity, Text, Image} from 'react-native';
import styles from '../../../Css';
import UserAvatar from 'react-native-user-avatar';
const userImage = props => {
  const {imageLoading, name, uri, onError, changeJournalUser, myName} = props;
  const nameArray = myName ? myName.split(' ') : name.split(' ');
  const userAvatarName =
    nameArray[0] +
    ' ' +
    (nameArray.length > 1 ? nameArray[nameArray.length - 1] : '');
  return (
    <View style={[styles.switchMenuRow, styles.secSepBorderBot]}>
      <TouchableOpacity onPress={changeJournalUser}>
        <View 
          nativeID={myName ? 'headerSwitchProfileMy' : 'headerSwitchProfileCaregiver'}
          style={[styles.headerViewComCont, styles.switchMenuPd]}>
          <View style={[styles.headerViewIcon, styles.rightSpSm]}>
            <View style={[styles.profileAvatarSm]}>
              {Boolean(imageLoading) && (
                <Image
                  style={styles.profileAvatarDefaultSm}
                  resizeMode={'cover'}
                  source={uri}
                  onError={onError}
                />
              )}

              {!Boolean(imageLoading) && (
                <UserAvatar
                  size={40}
                  name={userAvatarName.toUpperCase()}
                  textColor={'#FF9E38'}
                  bgColor={'rgb(255, 228, 200)'}
                />
              )}
            </View>
          </View>
          <View style={styles.headerViewText}>
            <Text style={styles.textPrimeMdBold} allowFontScaling={false}>{name}</Text>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (userImage);
