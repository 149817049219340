import React from "react";
import { View } from "react-native";
import styles from "../../Css";
import {ORGANIZATION_NAME} from '../../util/commonUiConfig';

const youtubeData = (props) => {
  return (
    <View style={styles.videoPlayerWrapper}>
      <iframe
        title={ORGANIZATION_NAME}
        width={props.videoBoxWidth}
        height={props.videoBoxHeight}
        src="https://www.youtube.com/embed/HlfvUm0Ezwg?autoplay=1"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </View>
  );
};

export default (youtubeData);