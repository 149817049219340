import React, { Component } from "react";
import {
  View,
  ScrollView,
  Platform,
  Text,
  TouchableOpacity,
} from "react-native";
import styles from "../../Css";
import _ from "underscore";
import Header from "../../components/Header/header";
import MenuOverlayContainer from '../../components/PageLayout/MenuOverlayContainer';
import stylesResponsive from "../../CssResponsive";
import Tabs from "../../components/Tab/Tab";
import { connect } from "react-redux";
import ImagePreview from "./ImagesDocsSorter";
import * as actions from "../../store/actions/index";
import * as axiosCommon from "../../axios/axios-common";
import * as urls from "../../store/actions/urls";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import SpaceBar from "../../components/SpaceBar/SpaceBar";
import DropZone from './Dropzone/DropZone';
import {isMobile} from 'react-device-detect';
import {
  DASHBOARD,
  IMAGES_DOCUMENTS,
  JOURNALS,
  CONTACTS,
  MEDICATIONS,
  HEALTH_JOURNEY,
} from "../../navigation/path";
import PageContainer from "../../components/PageLayout/PageContainer";
import SectionTitle from "../../components/Text/sectionTitle";
import PrimaryButtonDark from "../../components/Button/primaryButtonDark";
import SearchField from "../../components/Search/searchField";
import PremiumOvalButton from "../../components/Button/premiumOvalButton";
import { getAccountType } from "../../util/subscriptionInformation";
import StorageBanner from "../../components/Banner/StorageBannerAttachment";
import {
  freeStorage,
  premiumStorage,
  freeStorageByte,
  premiumStorageByte,
} from "../../Constant";
import NoResultFound from "../../components/Search/noResultFound";
import NoImagesDocumentsFound from "../../components/Search/noImagesDocumentsFound";
import { pendoTrackEvent } from "../../util/pendoConfig";
import { ANDROID, TAB_BREAk_POINT_END, WEB } from "../../util/platformWindowConfig";

class ImagesDocumentsScreen extends Component {
  constructor(props) {
    super(props);
    const { applicationSettingsDigest, userSubscriptions, attachmentUploadStatus } = props;
    const { uploadedFileSize } = applicationSettingsDigest;
    const accountType = getAccountType(userSubscriptions);
    this.searchTextMap = {};
    this.state = {
      distinctUserArtifacts: null,
      distinctFlaggedList: null,
      data: "",
      filterOn: false,
      filterWait: false,
      accountType: accountType,
      totalUsageByte: uploadedFileSize,
      imageSerachFinish: false,
      fromHealthJourney: props.route?.params?.from === HEALTH_JOURNEY.path,
      preSelectedItems: props.eventData?.attachments?.filter(i => i.id)?.map(i => i.id) || [],
      selectedItems: [],
      attachmentUploadStatus: attachmentUploadStatus
    };
    this.tapCount = 0;
  }

  static getDerivedStateFromProps(props, state) {
    const { imageFilter, artifactList, journalEntriesProcessed, attachmentUploadStatus } = props;

    const journalList = Object.values(journalEntriesProcessed);
    if (state.imageSerachFinish) {
      return {
        imageSerachFinish: false,
      };
    } else if (imageFilter && imageFilter.trim() !== "" && !state.filterWait) {
      return {
        searchKeyword: imageFilter,
      };
    } else if (
      !state.filterWait &&
      (!imageFilter || (imageFilter && imageFilter.trim() === "") || (Platform.OS === WEB && state.attachmentUploadStatus !== attachmentUploadStatus))
    ) {
      const shortArtifactList = artifactList.sort(function (a, b) {
        return b.createdOn - a.createdOn;
      });
      const distinctUserArtifacts = [
        ...new Set(shortArtifactList.map((item) => item.userArtifact)),
      ];

      const attachmentsUserArtifacts = {};
      shortArtifactList.forEach((item) => {
        attachmentsUserArtifacts[item.attachment] = item.userArtifact;
      });

      const flaggedList = [...journalList.filter((item) => item.flagged)];

      const distinctFlaggedList = [
        ...new Set(flaggedList.map((item) => item.artifactId)),
      ];

      const distinctFlaggedListObj = {};
      distinctFlaggedList.forEach((item) => {
        distinctFlaggedListObj[item] = item;
      });

      return {
        filterOn: false,
        filterWait: false,
        searchKeyword: "",
        distinctUserArtifacts,
        distinctFlaggedList,
        attachmentsUserArtifacts: attachmentsUserArtifacts,
        distinctFlaggedListObj: distinctFlaggedListObj,
        attachmentUploadStatus: attachmentUploadStatus
      };
    }

    return {
      filterWait: state.filterWait,
      searchKeyword: imageFilter ? imageFilter : "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.imageFilter && prevState.searchKeyword !== this.state.searchKeyword) {
      this.handleFilter(this.props.imageFilter);
    }
  }

  updateSelectedItems = data => {
    const {selectedItems} = this.state;
    const clonedItems = Object.assign([], selectedItems);
    const {id, name} = data;
    const found = selectedItems.find(item => item?.id === id);
    const extension = name.split(".").pop();
    if (!found) {
      const file = {
        tempId: Date.now(),
        id,
        fileName: name.replace(`.${extension}`, ""),
        fileExtension: extension
      };
      clonedItems.push(file);
      this.setState({selectedItems: clonedItems});
    } else {
      this.setState({selectedItems: clonedItems.filter(item => item?.id !== id)});
    }
  }

  renderAttachments = (filterData) => {
    const uniqueList = filterData.reduce((accUniqueList, currentItem) => {
      if (!accUniqueList.includes(currentItem)) {
        accUniqueList.push(currentItem);
      }
      return accUniqueList;
    }, []);
    let userArtifactList = [];
    return uniqueList.map((artifact, index) => {
      userArtifactList.push(artifact);
      // const flagged = this.state.distinctFlaggedList.find(
      //   (item) => item === artifact
      // )
      //   ? true
      //   : false;
      // const notJournal = this.props.journalList.find(
      //   (journal) => journal.artifactId === artifact
      // )
      //   ? false
      //   : true;
      if ((uniqueList.length - 1) === index) {
        return (
          <ImagePreview
            //artifact={artifact}
            redirectPage={this.redirectPage}
            // key={artifact + "_" + index}
            // flagged={flagged}
            // notJournal={notJournal}
            downloadAttachment={this.downloadAttachment}
            ocrData={this.state.filterData}
            filterOn={this.state.filterOn}
            filter={this.state.filter}
            userId={
              this.props.journalUserId
                ? this.props.journalUserId
                : this.props.user.id
            }
            getAttachmentCategory={this.getAttachmentCategory}
            viewAttachment={this.viewAttachment}
            onSetTotalAttachmentSize={() => {
              this.setState({
                totalUsageByte:
                  this.props.applicationSettingsDigest.uploadedFileSize
              });
            }}
            userArtifactList={userArtifactList}
            fromHealthJourney={this.state.fromHealthJourney}
            preSelectedItems={this.state.preSelectedItems}
            selectedItems={this.state.selectedItems.map(e => e.id)}
            updateSelectedItems={this.updateSelectedItems}
            attachmentUploadStatus = {this.state.attachmentUploadStatus}
          />
        );
      }
    });
  };

  renderFavorites = (filterData) => {
    let userArtifactList = [];
    return filterData.map((artifact, index) => {
      userArtifactList.push(artifact);
      if ((filterData.length - 1) === index) {
        return (
          <ImagePreview
            //artifact={artifact}
            redirectPage={this.redirectPage}
            // key={artifact}
            // flagged
            downloadAttachment={this.downloadAttachment}
            ocrData={this.state.filterData}
            filterOn={this.state.filterOn}
            filter={this.state.filter}
            userId={
              this.props.journalUserId
                ? this.props.journalUserId
                : this.props.user.id
            }
            getAttachmentCategory={this.getAttachmentCategory}
            viewAttachment={this.viewAttachment}
            onSetTotalAttachmentSize={() => {
              this.setState({
                totalUsageByte:
                  this.props.applicationSettingsDigest.uploadedFileSize
              });
            }}
            userArtifactList={userArtifactList}
            fromHealthJourney={this.state.fromHealthJourney}
            preSelectedItems={this.state.preSelectedItems}
            selectedItems={this.state.selectedItems.map(e => e.id)}
            updateSelectedItems={this.updateSelectedItems}
          />
        );
      }
    });
  };

  redirectPage = (userArtifact) => {
    let item;
    const { journalEntriesProcessed, contactsList, therapyList } = this.props;
    const journalList = Object.values(journalEntriesProcessed);
    if (
      (item = journalList.find((entry) => entry.artifactId === userArtifact))
    ) {
      if (!this.props.journalEntryTypesProcessed[item.entryType].isAppointment) {
        this.props.setJournalEntry(item);
        if (this.props.windowWidth < TAB_BREAk_POINT_END) {
          this.props.navigation.push(
            JOURNALS.viewJournalPath, {
              from: IMAGES_DOCUMENTS.path,
              journalName: item?.title
            }
          );
        } else {
          this.props.navigation.push(
            JOURNALS.path, {
              from: this.props.route.name,
              journalPath: JOURNALS.viewJournalPathSuffix,
              journalName: item?.title
            }
          );
        }
      }
    } else if (
      (item = contactsList.find(
        (contact) => contact.artifactId === userArtifact
      ))
    ) {
      this.props.setContact(item, () =>
        this.props.navigation.push(
          CONTACTS.viewContactPath, {
            from: IMAGES_DOCUMENTS.path
          }
        )
      );
    } else if (
      (item = therapyList.find(
        (therapy) => therapy.artifactId === userArtifact
      ))
    ) {
      this.props.setTherapy(item, () =>
        this.props.navigation.push(
          MEDICATIONS.viewTherapiesPath, {
            from: IMAGES_DOCUMENTS.path
          }
        )
      );
    } else {
    }
  };

  getAttachmentCategory = (userArtifact) => {
    let item;
    const { journalEntriesProcessed, contactsList, therapyList } = this.props;
    const journalList = Object.values(journalEntriesProcessed);
    if (
      (item = journalList.find((entry) => entry.artifactId === userArtifact))
    ) {
      if (this.props.journalEntryTypesProcessed[item.entryType].isAppointment) {
        return "Appointment";
      } else {
        return "Journal";
      }
    } else if (
      (item = contactsList.find(
        (contact) => contact.artifactId === userArtifact
      ))
    ) {
      return "Contact";
    } else if (
      (item = therapyList.find(
        (therapy) => therapy.artifactId === userArtifact
      ))
    ) {
      return "Therapy";
    } else {
    }
  };

  downloadAttachment = (id, fileName) => {
    this.tapCount = this.tapCount + 1;
    const callBack = (response) => {
      if (response.type === "SUCCESS") {
        const token = response.responseDto.data.authToken;
        const url = urls.IMG_DOC_BY_ID + id + "&auth=" + token;
        if (Platform.OS === WEB) {
          const header = {
            url,
            method: "GET",
            responseType: "blob", // important
          };
          axiosCommon.axiosDownload(header, fileName);
        } else {
          axiosCommon.actualDownload(fileName, url, () => {});
        }
      }
      this.tapCount = 0;
    };

    if (id) {
      if (this.tapCount === 1) {
        axiosCommon.axiosGET(urls.IMG_GET_DOWNLOAD_TOKEN + id, callBack);
      }
    }
  };

  viewAttachment = (id, fileName) => {
    const callBack = (response) => {
      if (response.type === "SUCCESS") {
        const downloadUrl = response.responseDto.data.downloadUrl;
        if (Platform.OS !== ANDROID) {
          axiosCommon.axiosViewFile(downloadUrl);
        } else {
          const token = response.responseDto.data.authToken;
          const url = urls.IMG_DOC_BY_ID + id + "&auth=" + token;
          axiosCommon.actualDownload(fileName, url, () => {}, true);
        }
      }
    };

    if (id) {
      axiosCommon.axiosGET(urls.IMG_GET_DOWNLOAD_TOKEN + id, callBack);
    }
  };

  componentDidMount() {
    const {hideMobileMenu} = this.props;
    const {fromHealthJourney} = this.state;
    this.props.navigation.addListener('focus', () => {
      if (fromHealthJourney) hideMobileMenu();
      const shortArtifactList = this.props.artifactList.sort(function (a, b) {
        return b.createdOn - a.createdOn;
      });
      const distinctUserArtifacts = [
        ...new Set(shortArtifactList.map((item) => item.userArtifact)),
      ];

      const attachmentsUserArtifacts = {};
      shortArtifactList.forEach((item) => {
        attachmentsUserArtifacts[item.attachment] = item.userArtifact;
      });

      const journalList = Object.values(this.props.journalEntriesProcessed);
      const flaggedList = [
        ...journalList.filter((item) => item.flagged),
      ];

      const distinctFlaggedList = [
        ...new Set(flaggedList.map((item) => item.artifactId)),
      ];

      const distinctFlaggedListObj = {};
      distinctFlaggedList.forEach((item) => {
        distinctFlaggedListObj[item] = item;
      });

      this.setState({
        distinctUserArtifacts,
        distinctFlaggedList,
        attachmentsUserArtifacts: attachmentsUserArtifacts,
        distinctFlaggedListObj: distinctFlaggedListObj,
      });
      let from = this.props.route.params?.from;
      if (from && (from.includes(JOURNALS.viewJournalPath) ||
        from.includes(JOURNALS.path) ||
        from.includes(CONTACTS.viewContactPath) ||
        from.includes(MEDICATIONS.viewTherapiesPath))
      ) {
        if (this.props.imageFilter) {
          this.handleFilter(this.props.imageFilter);
        } else {
          this.componentWillUnmount();
        }
      } else {
        this.componentWillUnmount();
      }
      pendoTrackEvent('Attachments@');
    });
    this.props.navigation.addListener('blur', () => {
      this.tapCount = 0;
      this.props.navigation.setParams({from: this.props.route.name});
    });
  }

  componentWillUnmount = () => {
    if (this.state.filterOn) {
      this.handleOnPressClearFilters();
    }
  };

  handleFilter = (filter) => {
    this.setState({filterWait: true})
    const {allAttachments, user, journalUserId} = this.props;
    let userId = user.id;

    if (journalUserId) {
      userId = journalUserId;
    }
    this.searchTextMap[filter] = filter;
    this.props.searchAttachment(
      {
        attachmentCategory: "All",
        searchKey: filter,
        userId: userId,
      },
      (sucess, data) => {
        delete this.searchTextMap[filter];
        if (sucess && _.isEmpty(this.searchTextMap)) {
          if (this.state.searchKeyword) {
            const obj = {};
            if (allAttachments && allAttachments.length > 0) {
              allAttachments.forEach(element => {
                return element.filename
                  .toLowerCase()
                  .includes(this.state.searchKeyword.toLowerCase())
                  ? (obj[element.id] = element)
                  : null;
              });
            }
            data.forEach((element) => {
              element.attachments.forEach((item) => {
                obj[item.attachmentId] = item;
              });
            });
            this.setState({
              filterOn: true,
              filterData: obj,
              filter: filter,
              filterWait: !_.isEmpty(this.searchTextMap),
              imageSerachFinish: true,
            });
          } else {
            this.setState({
              filterOn: false,
              filterData: {},
              filter: filter,
              filterWait: false,
              imageSerachFinish: false,
            });
          }
        } else if (_.isEmpty(this.searchTextMap)) {
          this.setState({
            filterOn: true,
            filterData: {},
            filter: filter,
            filterWait: !_.isEmpty(this.searchTextMap),
            imageSerachFinish: true,
          });
        }
      }
    );
    //  this.props.setImageFilter(filter);
  };

  handleOnPressClearFilters = () => {
    this.setState({ filterOn: false });
    this.props.clearImageFilter();
  };

  searchAttachment = (val) => {
    const { setImageFilter } = this.props;
    setImageFilter(val);
  };

  handleUploadAttachment = (droppedFiles = []) => {
    pendoTrackEvent('Attachments@ > Upload');
    const { navigation, clearFilesToAttach } = this.props;
    if (droppedFiles.length) {
      navigation.push(
        IMAGES_DOCUMENTS.addImagesDocumentsPath, {
          state: droppedFiles || undefined,
        }
      );
    } else {
      clearFilesToAttach();
      navigation.reset({
        index: 0,
        routes: [{ 
          name: IMAGES_DOCUMENTS.addImagesDocumentsPath, 
        }],
      });
    }
    if (Platform.OS === WEB) {
      this.props.clearUploadedAttachments();
      this.props.setAttachmentUploadProgress(0);
      this.props.hideUploadProgress();
    }
  };

  handleRedirectHealthJourney = type => {
    const {navigation, setEventData, eventData, route, windowWidth} = this.props;
    const {selectedItems} = this.state;
    const attachments = eventData.attachments || [];
    if (type === "SAVE") {
      setEventData({
        ...eventData,
        attachments: [...attachments, ...selectedItems]
      });
    }
    navigation.reset({
      index: 0,
      routes: [{ 
        name: windowWidth < TAB_BREAk_POINT_END ? HEALTH_JOURNEY.addEventPath : HEALTH_JOURNEY.path,
        params: {from: route.name}
      }],
    }); 
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const { accountType, totalUsageByte, fromHealthJourney } = this.state;
    const { imageFilter } = this.props;
    let filterData = [];
    let filterDataFavorites = [];
    if (this.state.distinctUserArtifacts) {
      if (this.state.filterOn) {
        Object.keys(this.state.filterData).forEach((id) => {
          filterData.push(this.state.attachmentsUserArtifacts[id]);
        });
      } else {
        filterData = this.state.distinctUserArtifacts;
      }
    }

    if (this.state.distinctFlaggedList) {
      if (this.state.filterOn) {
        Object.keys(this.state.filterData).forEach((id) => {
          if (
            this.state.distinctFlaggedListObj[
              this.state.attachmentsUserArtifacts[id]
            ]
          ) {
            filterDataFavorites.push(this.state.attachmentsUserArtifacts[id]);
          }
        });
      } else {
        filterDataFavorites = this.state.distinctFlaggedList;
      }
    }
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          //   handleFilter={this.handleFilter}
          //  clearFiltersHandler={this.handleOnPressClearFilters}
          handleOnPressBackButton={() => {
            let from = null;
            if (this.props.route?.params && this.props.route?.params.from) {
              from = this.props.route?.params.from;
            }
            if (this.state.filterOn) {
              this.handleOnPressClearFilters();
            } else if (fromHealthJourney) {
              this.handleRedirectHealthJourney("CANCEL")
            } else if (from === MEDICATIONS.path) {
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: MEDICATIONS.path }],
              });
            } else {
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: DASHBOARD.path }],
              }); 
            }
          }}
          type="image"
          index={IMAGES_DOCUMENTS.index}
          navigation={this.props.navigation}
          route={this.props.route}
          hideCaregiverAccess={fromHealthJourney}
        />
        <SpaceBar />
        {fromHealthJourney &&
          <MenuOverlayContainer 
            onPressOpen={() => this.handleRedirectHealthJourney("CANCEL")} />
        }
        {this.props.journalUserId === null &&
          accountType === "regular" &&
          totalUsageByte >= freeStorageByte && <StorageBanner />
        }

        <ScrollView style={styles.scrollViewArea}>
          <View style={styles.gridSection}>
            <PageContainer>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>

                <View style={[styles.searchSection]}>
                    {/* button */}
                    <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt]}>
                      <View>
                        <SectionTitle 
                          smallTextTilte={true}
                          title={"Attachments"}
                          titleStyle={styles.titlePdZero} 
                        />
                      </View>
                      <View style={[stylesRes.newConnectionBtnCr, styles.btnMgMd]} dataSet={{media: ids.newConnectionBtnCr}}>
                        <PrimaryButtonDark
                          touchableOnPress={this.handleUploadAttachment}
                          btnPosition={"full"}
                          btnText={"+ New Attachment"}
                          btnShadowNone={true}
                          disabled={fromHealthJourney}
                          disabledColorChange={true}
                          disableLightBlur={fromHealthJourney}
                          // activityInd={this.state.createNewJournalWait}
                          nativeId={'attachmentsUpload'}
                          newConnection={true}
                        />
                      </View>
                    </View>
                    {/* search bar */}
                    <View style={styles.fieldColSearch}>
                      <SearchField
                        searchText={imageFilter ? imageFilter : ""}
                        placeholder="Search existing attachments"
                        onChangeText={this.searchAttachment}
                        showClearBtton={
                          imageFilter && imageFilter !== "" ? true : false
                        }
                        clearFilter={this.handleOnPressClearFilters}
                        nativeId={'attachmentsSearch'}
                        eventName={'Attachments@ > Search'}
                      />
                    </View>
                </View>
                  
                  <View style={[styles.attachmentDataSection, styles.flexJcFs]}>
                    <View style={[styles.attachmentDataFull, styles.flexAiFs]}>
                      <View style={styles.attachmentDataComView}>
                        <View style={styles.attachmentDataTitle}>
                          <Text style={[styles.textPrimeSm]} allowFontScaling={false}>
                            Data usage:{" "}
                          </Text>
                        </View>
                        <View style={styles.attachmentDataSub}>
                          <Text
                            style={[
                              styles.textPrimeSm,
                              styles.textColorLight,
                            ]}
                            allowFontScaling={false}
                          >
                            {this.props.journalUserId === null &&
                            accountType === "regular"
                              ? "Used " +
                                (
                                  Number.parseInt(
                                    (totalUsageByte / freeStorageByte) * 1000
                                  ) / 10
                                ).toFixed(1) +
                                "% of " +
                                freeStorage
                              : "Used " +
                                (
                                  Number.parseInt(
                                    (totalUsageByte / premiumStorageByte) *
                                      1000
                                  ) / 10
                                ).toFixed(1) +
                                "% of " +
                                premiumStorage}
                          </Text>
                        </View>
                      </View>
                    </View>

                    {accountType === "regular" && (
                        <View style={[styles.premiumLinkView]}>
                          <PremiumOvalButton 
                            navigation={this.props.navigation} 
                            nativeId={'attachmentsPremium'}
                            eventName={'Attachments@ > Premium'}
                          />
                        </View>
                      )}
                  </View>
                  {_.isEmpty(filterData) &&
                    _.isEmpty(filterDataFavorites) &&
                    this.state.filterOn &&
                    !this.state.filterWait ? (
                      <NoResultFound />
                    ) : (
                      Platform.OS !== WEB &&
                      _.isEmpty(filterData) &&
                     // _.isEmpty(filterDataFavorites) &&
                      !this.state.filterWait &&
                      <Tabs
                        pageContainer={true}
                        smScreenFull={true}
                      >
                        <View
                          title="All"
                          style={[styles.tabContentSection, styles.comStyling]}
                        >
                          <NoImagesDocumentsFound />
                        </View>
                        <View
                          title="Favourites"
                          style={[styles.tabContentSection]}
                        >
                          <NoImagesDocumentsFound />
                        </View>
                      </Tabs>
                    )
                  }
                </View>
              </View>
            </PageContainer>

            {this.state.filterWait ? (
              <ActivityIndicator pdLarge={true} />
            ) : (
              <React.Fragment>
                {/* {(!_.isEmpty(filterData) ||
                  !_.isEmpty(filterDataFavorites)) && ( */}
                {Platform.OS !== WEB && !_.isEmpty(filterData) && (
                  <Tabs
                    pageContainer={true}
                    smScreenFull={true}
                    // tabIndex={res => props.selectedTab(res)}
                  >
                    {/* All tab */}
                    <View
                      title="All"
                      style={[styles.tabContentSection, styles.comStyling]}
                      isFromHealthJourney={fromHealthJourney}
                    >
                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <PageContainer smScreenFull={true}>
                            <View
                              style={[
                                stylesRes.tabContentFullArea,
                                stylesRes.botPd,
                              ]}
                              dataSet={{media: ids.botPd}}
                            >
                              {this.renderAttachments(filterData)}
                            </View>
                          </PageContainer>
                        </View>
                      </View>
                    </View>
                    {/* Attachments tab */}
                    <View
                      title="Favourites"
                      //activeCircle={props.hasAttachments}
                      style={[styles.tabContentSection]}
                      isFromHealthJourney={fromHealthJourney}
                    >
                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <PageContainer smScreenFull={true}>
                            <View
                              style={[
                                stylesRes.tabContentFullArea,
                                stylesRes.botPd,
                              ]}
                              dataSet={{media: ids.botPd}}
                            >
                              {this.renderFavorites(filterDataFavorites)}
                            </View>
                          </PageContainer>
                        </View>
                      </View>
                    </View>
                  </Tabs>
                )}

                {Platform.OS === WEB && (!_.isEmpty(filterData) ||
                  !_.isEmpty(filterDataFavorites)) && (
                  <Tabs
                    // tabIndex={res => props.selectedTab(res)}
                    pageContainer={true}
                    smScreenFull={true}
                  >
                    {/* All tab */}
                    <View title="All" style={[styles.tabContentSection]} isFromHealthJourney={fromHealthJourney}>
                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <PageContainer smScreenFull={true}>
                            <View
                            nativeID="tabDiv"
                              style={[
                                stylesRes.tabContentFullArea,
                                stylesRes.botPd,
                                !isMobile &&
                                stylesRes.tabContentFixedHeight
                              ]}
                              dataSet={{media: isMobile ? ids.botPd : ids.tabContentFixedHeight}}
                            >
                              {this.renderAttachments(filterData)}
                            </View>

                          </PageContainer>
                        </View>
                      </View>
                    </View>
                    {/* Attachments tab */}
                    <View
                      title="Favourites"
                      //activeCircle={props.hasAttachments}
                      style={[styles.tabContentSection, styles.comStyling]}
                      isFromHealthJourney={fromHealthJourney}
                    >
                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <PageContainer smScreenFull={true}>
                            <View
                              style={[
                                stylesRes.tabContentFullArea,
                                stylesRes.botPd,
                                !isMobile &&
                                stylesRes.tabContentFixedHeight
                              ]}
                              dataSet={{media: isMobile ? ids.botPd : ids.tabContentFixedHeight}}
                            >
                              {this.renderFavorites(filterDataFavorites)}
                            </View>
                          </PageContainer>
                        </View>
                      </View>
                    </View>
                  </Tabs>
                )}
              </React.Fragment>
            )}
            {Platform.OS === WEB && !isMobile && !Boolean(fromHealthJourney) && (
              <PageContainer>
                <View style={[styles.dragNdropSectionOuterWrapper]}>
                  <View style={[stylesRes.dragNdropSection, {height: 'auto'
                    //this.props.windowHeight - 509
                    }]}
                    dataSet={{media: ids.dragNdropSection}}>
                      <DropZone disabled={this.props.isUploading} onGetFiles={this.handleUploadAttachment} />
                  </View>
                </View>
              </PageContainer>
            )}
          </View>
        </ScrollView>

        {/* cancel and confirm button */}
        <View style={[ styles.mgBtFifty]}>
          {Boolean(fromHealthJourney) && (
            <PageContainer>
              <View style={[styles.borderBotGrey, styles.wdHundredPercent, styles.btnMgLg]}></View>

              <View style={[styles.dragNdropSectionOuterWrapper, styles.flexRow]}>
                  <TouchableOpacity onPress={() => this.handleRedirectHealthJourney("CANCEL")}>
                    <View style={[
                      styles.wdHundredFifty, 
                      styles.fourtyHg, 
                      styles.bgWhite, 
                      styles.brdGreyDarkWdOne,
                      styles.borderTpLtRadius,
                      styles.borderTpRtRadius,
                      styles.borderBtLtRadius,
                      styles.borderBtRtRadius,
                      styles.flexAiCt,
                      styles.flexJcCt,
                      styles.mgRtSix
                      ]}>
                      <Text style={styles.textPrimeLgBold}> Cancel </Text>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => this.handleRedirectHealthJourney("SAVE")}>
                    <View style={[
                        styles.wdHundredFifty, 
                        styles.fourtyHg, 
                        styles.bgHealthJourneyDeleteBtn, 
                        styles.borderTpLtRadius,
                        styles.borderTpRtRadius,
                        styles.borderBtLtRadius,
                        styles.borderBtRtRadius,
                        styles.flexAiCt,
                        styles.flexJcCt,
                        styles.mgRtSix
                        ]}>
                      <Text style={[styles.textPrimeLgBold, styles.textWhite]}> Confirm </Text>
                    </View>
                  </TouchableOpacity>
              </View>
            </PageContainer>
          )}
        </View>

      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setJournalEntry: (selectedId) =>
      dispatch(actions.setSelectedJournalEntry(selectedId)),
    setTherapy: (SelectedTherapie, callBack) =>
      dispatch(actions.selectTherapie(SelectedTherapie, callBack)),
    setContact: (selectedContact, callBack) =>
      dispatch(actions.selectedContact(selectedContact, callBack)),
    clearImageFilter: () => dispatch(actions.clearImageFilter()),
    setImageFilter: (filter) => dispatch(actions.setImageFilter(filter)),
    searchAttachment: (formData, callBack) =>
      dispatch(actions.searchAttachment(formData, callBack)),
    clearFilesToAttach: () => dispatch(actions.clearFilesToAttach()),
    clearUploadedAttachments: () => dispatch(actions.clearUploadedAttachments()),
    setAttachmentUploadProgress: progress => dispatch(actions.setAttachmentUploadProgress(progress)),
    hideUploadProgress: () => dispatch(actions.hideUploadProgress()),
    setEventData: data => dispatch(actions.setEventData(data)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
  };
};

const mapStateToProps = (state) => {
  return {
    allAttachments: state.routines.attachmentsCompleteDigest.attachments,
    artifactList:
      state.routines.attachmentsCompleteDigest.userArtifactAttachments,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    therapyList: state.routines.treatments,
    contactsList: state.routines.contacts,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    user: state.routines.user,
    journalUserId: state.uistate.journalUserId,
    imageFilter: state.filter.imageFilter,
    userSubscriptions: state.routines.userSubscription,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    windowWidth: state.uistate.windowWidth,
    eventData: state.uistate.eventData,
    attachmentUploadStatus: state.routines.attachmentUploadStatus
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImagesDocumentsScreen);
