import React, { Component } from "react";
import { Image, TouchableOpacity, View } from "react-native";

const assetsTickButtonImage = require("../../assets/toggleTick/ticked.png");
const assetsUntickButtonImage = require("../../assets/toggleTick/un-ticked.png");
const assetsCurveTickButtonImage = require("../../assets/toggleTick/check-tick.png");
const assetsCurveUntickButtonImage = require("../../assets/toggleTick/check-untick.png");
const assetsCircleTickButtonImage = require("../../assets/toggleTick/tick-circle-active.png");
const assetsCircleUntickButtonImage = require("../../assets/toggleTick/tick-circle-default.png");



export default class toggleTick extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticked: props.isTicked || false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.hasOwnProperty('isTicked') && (nextProps.isTicked !== prevState.ticked)) {
      return {
        ticked: nextProps.isTicked
      }
    }

    // Return null to indicate no change to state.
    return null;
  }

  clickEvent = () => {
    // this.setState({
    //   ticked: !this.state.ticked 
    // }, () => this.props.toggleTickCallback(this.state.ticked));
    this.props.toggleTickCallback(!this.state.ticked);
    this.setState({
      ticked: !this.state.ticked 
    });
  };

  renderImage = (url) => {
    return (
      <Image
        source={url}
        style={[{ height: 15, width: 15 }, this.props.tickBtnStyle]}
      />
    )
  }

  renderImageConditions = (type, section = "untickButton") => {

    if (type === 'curve') {

      const url = section === "untickButton" ? assetsCurveUntickButtonImage : assetsCurveTickButtonImage;

      return this.renderImage(url);

    } else if (type === 'circle') {
      const url = section === "untickButton" ? assetsCircleUntickButtonImage : assetsCircleTickButtonImage;

      return this.renderImage(url);

    } else {
      const url = section === "untickButton" ? assetsUntickButtonImage : assetsTickButtonImage;

      return this.renderImage(url);
    }

  }

  render() {

    return (
      <View>
        <TouchableOpacity
          onPress={this.clickEvent}
          style={[{
            marginRight: 10,
            marginBottom: 0,
            justifyContent: "center",
            alignItems: "center",
          }, this.props.tickStyle]}
          disabled={this.props.disabled}
          nativeID={this.props.nativeId} 
        >
          {
            this.renderImageConditions(this.props.styleType, this.props.imagePath)
          }

        </TouchableOpacity>
      </View>
    );
  }
}
