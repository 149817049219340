import React, {useEffect, useRef} from 'react';
import {FlatList, Text, View} from 'react-native';
import QuestionTitle from '../../../../components/QuestionTitle/QuestionTitle';
import QuestionImage from '../QuestionImage';
import YesNoSwitch from '../../../../components/YesNoSwitch/yesNoSwitch';
import stylesCss from './QuestionSymptomSliderStyles';
import QuestionWarning from '../../../../components/QuestionTitle/QuestionWarning';
import ScaleSlider from "../../../../components/HASlider/scaleSlider";
import styles from "../../../../Css";
import {isArray} from "lodash/lang";

const SYMPTOM_SLIDER_START = '#F5BF44';
const SYMPTOM_SLIDER_END = '#D24935';

const SYMPTOM_MIN_VALUE = 0;
const SYMPTOM_MAX_VALUE = 10;
const QuestionSymptomSlider = ({
                                 title,
                                 measureType,
                                 questionIndex,
                                 isReadOnly,
                                 sliderDescriptionArray,
                                 answer,
                                 imageUrl,
                                 windowWidth,
                                 onChange,
                                 onDisableScroll,
                                 required,
                                 requiredWarning,
                                 onSkip,
                               }) => {
  const generateSegmentArray = () => {
    let validatedArray = sliderDescriptionArray && Array.isArray(sliderDescriptionArray) ? sliderDescriptionArray : [];

    const tempArray = [...Array(11)].map((value, index) => {
      return validatedArray[index] || null;
    });
    return tempArray;
  };

  const generateYesNoSwitchValue = () => {
    if (answer === true || answer === 'true') {
      return 'YES'
    }
    if (answer === false || answer === 'false') {
      return 'NO'
    }
    return null;
  };

  const sliderReference = useRef(null);

  useEffect(() => {
    if (sliderReference.current && sliderReference.current.focusInput) {
      setTimeout(() => {
        if (sliderReference.current && sliderReference.current.focusInput) {
          sliderReference.current.focusInput();
        }
      }, 200);
    }
  }, []);

  const symptomValueDescriptionsArray = generateSegmentArray();
  return (
    <>
      {Boolean(imageUrl) && <View style={[stylesCss.questionImageWrapper]}>
        <QuestionImage imageUrl={imageUrl}/>
      </View>}
      <View style={[stylesCss.symptomSlidereWrapper]}>

      </View>
      <QuestionTitle questionIndex={questionIndex} title={title} required={required} onSkip={onSkip}
                     isReadOnly={isReadOnly}/>
      <View style={[stylesCss.symptomSlidereWrapper]}>
        {
          measureType === 'SLIDER' &&
          <>
            <ScaleSlider
              ref={sliderReference}
              minValue={SYMPTOM_MIN_VALUE}
              maxValue={SYMPTOM_MAX_VALUE}
              minScaleDescription={Boolean(symptomValueDescriptionsArray[SYMPTOM_MIN_VALUE]) ? symptomValueDescriptionsArray[SYMPTOM_MIN_VALUE] : ''}
              maxScaleDescription={Boolean(symptomValueDescriptionsArray[SYMPTOM_MAX_VALUE]) ? symptomValueDescriptionsArray[SYMPTOM_MAX_VALUE] : ''}
              enabled={!isReadOnly}
              onUpdate={onChange}
              value={answer !== null ? isArray(answer) ? answer[0] : answer : null}
              disabledScroll={onDisableScroll}
              scaleStartColor={SYMPTOM_SLIDER_START}
              scaleEndColor={SYMPTOM_SLIDER_END}
              valueDescriptions={symptomValueDescriptionsArray}
            />
            {Boolean(symptomValueDescriptionsArray.filter((item) => item !== null).length) &&
              <View>
                <View style={[stylesCss.symptomValueDescriptionRow]}>
                  <Text
                    style={[stylesCss.symptomValueDescriptionTableHeader, styles.textPrimeBold, styles.textColor]}>Symptom Rating Scale</Text>
                </View>
                <View style={[stylesCss.symptomValueDescriptionRow]}>
                  <Text
                    style={[stylesCss.symptomValueDescriptionIndex, styles.textPrimeBold, styles.textColor]}>Rating</Text>
                  <Text
                    style={[stylesCss.symptomValueDescriptionText, styles.textPrimeBold, styles.textColor]}>Description</Text>
                </View>
                {symptomValueDescriptionsArray.map((item, index) => {
                  return Boolean(item) ? <View key={'question-' + questionIndex + 'symptom-desc-' + index}
                                               style={[stylesCss.symptomValueDescriptionRow]}>
                    <Text
                      style={[stylesCss.symptomValueDescriptionIndex, styles.textPrimeBold, styles.textColor]}>{index}</Text>
                    <Text
                      style={[stylesCss.symptomValueDescriptionText, styles.textPrime, styles.textColor]}>{item}</Text>
                  </View> : null
                })}
              </View>
            }
          </>
        }
        {
          measureType === 'YESORNO' &&
          <YesNoSwitch value={generateYesNoSwitchValue()} enabled={!isReadOnly}
                       onChange={(value) => onChange((value === 'YES'))} windowWidth={windowWidth}/>
        }
      </View>
      {/* Requird Field Warning Start */}
      {Boolean(requiredWarning) &&
        <QuestionWarning/>
      }
      {/* Requird Field Warning End */}
    </>
  );
};

export default QuestionSymptomSlider;
