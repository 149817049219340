import React from "react";
import { View } from "react-native";
import styles from "../../../Css";
const scrollViewCustom = (props) => {
  let scrollHeight = props.scrollHeight;
  if (scrollHeight < 100) {
    scrollHeight = 100;
  }
  return (
    <React.Fragment>
      <View
        style={[
          props.fullWidth > 1278
            ? [styles.graphSecScrollView, { maxHeight: scrollHeight }]
            : styles.graphDefaultView,
        ]}
      >
        {props.children}
      </View>
    </React.Fragment>
  );
};
export default scrollViewCustom;
