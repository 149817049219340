import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "../../../../Css";
import * as actions from "../../../../store/actions/index";
import Collapsible from "../../../Collapsible/Collapsible";
import QuestionList from "../../../Question/Add";

class Questions extends Component {
  constructor(props) {
    super(props);
    const { artifactId, questionDigest } = this.props;
    const { userArtifactHealthQuestions } = questionDigest;
    const questions = this.getQuestions(
      userArtifactHealthQuestions,
      artifactId
    );
    this.state = {
      collapsible: {
        question: questions && questions.length > 0,
      },
    };
    props.onUpdateQestionList(questions);
  }

  handleOnPressCollapsible = (key) => {
    let collapsibleTemp = this.state.collapsible;
    let val = collapsibleTemp[key];
    collapsibleTemp[key] = !val;
    this.setState({ collapsible: collapsibleTemp });
  };

  getQuestions = (questions, artifactId) => {
    let questionsArray = [];
    if (questions) {
      questionsArray = questions.filter(function(question) {
        return question.artifactId === artifactId;
      });
    }

    const unique = questionsArray
      .map((e) => e["questionId"])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => questionsArray[e])
      .map((e) => questionsArray[e]);
    return unique;
  };

  render() {
    return (
      <Collapsible
        title="To-dos"
        contentVisibility={this.state.collapsible.question}
        handleOnPressCollapsible={() =>
          this.handleOnPressCollapsible("question")
        }
        style={[styles.collapseTitle]}
        customTitleStyle={true}
        plusButton={true}
        topBorder={true}
        nativeId={'journalToDos'}
      >
        <QuestionList type="journal" bgLight={true} />
      </Collapsible>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    questionDigestProcessed: state.routines.questionDigestProcessed,
    questionDigest: state.routines.questionDigest,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateQestionList: questionList =>
      dispatch(actions.updateQestionList(questionList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
