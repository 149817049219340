import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import NewChatListComponent from '../../../../components/PeerToPeer/MainSection/Chats/NewChatListComponent';
import {DASHBOARD, PEER_TO_PEER} from '../../../../navigation/path';
import { pendoTrackEvent } from '../../../../util/pendoConfig';
import _ from 'lodash';

class NewChatListScreen extends Component {
  constructor(props) {
    super(props);
    const {conversations, myNetwork} = props;
    this.state = {
      myConnections: myNetwork?.connected ? myNetwork.connected : [],
      searchedKeyword: null,
      loading: false,
      conversations: conversations ? conversations : [],
      conversationsAll: conversations ? conversations : [],
    };
    this.conversationPaginator = React.createRef();
  }

  componentDidMount() {
    const {
      peerProfile,
      onGetToken,
      commonErrorHandler,
      navigation,
      journalUserId,
    } = this.props;
    if (journalUserId) {
      navigation.push(DASHBOARD.path);
    }
    pendoTrackEvent('Chats/@NewMessage');
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    });
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth, myNetwork, conversations} = props;
    const {connected} = myNetwork;

    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth ||
      !_.isEqual(connected, state.myConnections) ||
      !_.isEqual(conversations, state.conversations)
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        myConnections: connected ? connected : [],
        conversationsAll: conversations,
      };
    }

    return null;
  }

  componentWillUnmount = () => {
    if (this.state.searchedKeyword) {
      this.props.onUpdateSearchedKeyword('');
    }
  };

  filterMyConnections = keyword => {
    const {conversationsAll} = this.state;
    if (keyword) {
      let myConnections = conversationsAll;
      let connectedFilter = [];
      if (myConnections && myConnections.length > 0) {
        connectedFilter = myConnections.filter(
          res =>
            res?.userName &&
            res.userName.toLowerCase().includes(keyword.toLowerCase()),
        );
      }
      this.setState({
        searchedKeyword: keyword,
        conversations: connectedFilter,
      });
    } else {
      this.setState({
        searchedKeyword: keyword,
        conversations: conversationsAll,
      });
    }
    this.props.onUpdateSearchedKeyword(keyword);
  };

  handleOnPressBackButton = () => {
    if (this.state.searchedKeyword) {
      this.filterMyConnections(null);
    } else {
      this.props.navigation.goBack();
    }
  };

  handleOnJoin = item => {
    const {onSetSelectedConversation} = this.props;

    onSetSelectedConversation(item);
    this.props.navigation.replace(PEER_TO_PEER.individualChatPath);
  };

  render() {
    const {navigation, route} = this.props;
    const {conversations, searchedKeyword, loading} = this.state;
    return (
      <NewChatListComponent
        navigation={navigation}
        route={route}
        index={PEER_TO_PEER.index}
        handleOnPressBackButton={this.handleOnPressBackButton}
        myConversationList={conversations}
        searchedKeyword={searchedKeyword}
        onSearchInputValueChange={this.filterMyConnections}
        loading={loading}
        onPressUserNameItem={this.handleOnJoin}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    peerProfile: state.routines.peer2peer,
    searchedKeyword: state.peerToPeer.searchedKeyword,
    conversations: state.peerToPeer.channels,
    myNetwork: state.routines.myNetwork,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateSearchedKeyword: keyword =>
      dispatch(actions.updateSearchedKeyword(keyword)),
    onSetSelectedConversation: channel =>
      dispatch(actions.setSelectedChannel(channel)),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    onGetToken: (callBack, userId) =>
      dispatch(actions.getToken(callBack, userId)),
    onUpdateConversations: conversations =>
      dispatch(actions.updateChannels(conversations)),
    setNewMessageCount: (count) => dispatch(actions.setNewMessageCount(count)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewChatListScreen);
