import React, {Component} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';

class TherapieTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTherapyTab: props.activeTherapyTab ? props.activeTherapyTab : 0,
    };
  }
  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {isNew, windowWidth} = this.props;
    return (
      <View style={[styles.subSection, styles.colPdBotExSm]}>
        <View style={[styles.fieldRow, styles.fieldRowNoMg]}>
          <View style={[styles.titleTab]}>
            <Text style={[styles.textPrime]} allowFontScaling={false}>Select Type:</Text>
          </View>

          <View style={styles.btnTabMenuWrapper}>
            <View style={[stylesRes.btnTabViewWrapCom,
             windowWidth > 767
             ? stylesRes.btnTabViewLg
             : stylesRes.btnTabViewSm
            ]}>
              <View style={styles.btnTabSingleBoxLg}>
                <TouchableOpacity
                  style={styles.btnTabTouch}
                  onPress={() => {
                    this.setState({
                      activeTherapyTab: 0,
                    });
                    this.props.selectTherapy(0);
                  }}
                  disabled={!isNew}>
                  <View
                    style={[
                      styles.btnTabMenu,
                      styles.btnTabMenuCom,
                      styles.btnShadow,
                      this.state.activeTherapyTab === 0
                        ? styles.btnTabMenuActive
                        : styles.btnTabMenuDefault,
                    ]}>
                    <Text
                      style={[
                        styles.textPrimeBold,
                        styles.textAlignCenter,
                        this.state.activeTherapyTab === 0
                          ? styles.btnTextHighlight
                          : styles.btnTextdefault,
                      ]}
                      allowFontScaling={false}
                      >
                      Medication / Supplement
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
              <View style={styles.btnTabSingleSp} />
              <View style={styles.btnTabSingleBoxSm}>
                <TouchableOpacity
                  style={styles.btnTabTouch}
                  onPress={() => {
                    this.setState({
                      activeTherapyTab: 1,
                    });
                    this.props.selectTherapy(1);
                  }}
                  disabled={!isNew}>
                  <View
                    style={[
                      styles.btnTabMenu,
                      styles.btnTabMenuCom,
                      styles.btnShadow,
                      this.state.activeTherapyTab === 1
                        ? styles.btnTabMenuActive
                        : styles.btnTabMenuDefault,
                    ]}>
                    <Text
                      style={[
                        styles.textPrimeBold,
                        styles.textAlignCenter,
                        this.state.activeTherapyTab === 1
                          ? styles.btnTextHighlight
                          : styles.btnTextdefault,
                      ]}
                      allowFontScaling={false}
                      >
                      Activity
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default (TherapieTypes);
