import React, { Component } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  TextInput,
  ScrollView,
  Platform
} from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import Modal from "../Modal/modal";
import UIText from "../Text/text";
import ActivityIndicator from "../ActivityIndicator/activityIndicator";
import * as validator from "../../validator/validator";
import Warning from '../Warning/warning';
import CancelButton from "../Button/cancelButton";
import { WEB } from "../../util/platformWindowConfig";

class sendEmailBox extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      emailAddresses: [],
      email: "",
      editable: true,
      error: false,
      errorDescription: '',
    };
  this.emailDelete = false;
  }
  componentDidMount() {
    if (this.textInput) {
      this.textInput.focus();
    }
  }
  onChangeEmailText = value => {
    if (value.trim().indexOf(' ') >= 0) {
      this.setState({
        email: value,
        error: true,
        errorDescription: 'Please enter valid email address.',
      });
    } else {
      this.setState({
        email: value,
        error: false,
        errorDescription: '',
      });
    }
    if(value !== '' ) this.emailDelete = false;
  };

  onBlur = e => {
    // if (validator.validateEmail(this.state.email.trim())) {
    //   const emailAddresses = [...this.state.emailAddresses];
    //   emailAddresses.push({ email: this.state.email.trim(), permission: "READ_ONLY" });
    //   if (emailAddresses.length === 5) {
    //     this.setState({ editable: false });
    //   }
    //   this.setState({ email: "", emailAddresses: emailAddresses }, () => {
    //     if (this.textInput) {
    //       this.textInput.focus();
    //     }
    //   });
    // }
    this.addEmailToList();
  };

  onKeyPress = e => {
    if (e.nativeEvent.key === "Enter" || e.nativeEvent.key === " ") {
      // if (validator.validateEmail(this.state.email.trim())) {
      //   const emailAddresses = [...this.state.emailAddresses];
      //   emailAddresses.push({
      //     email: this.state.email.trim(),
      //     permission: "READ_ONLY"
      //   });
      //   setTimeout(() => {
      //     this.setState({ email: "", emailAddresses: emailAddresses }, () => {
      //       if (this.textInput) {
      //         this.textInput.focus();
      //       }
      //     });
      //   }, 500);
      // }
      this.addEmailToList();
    }
      if (e.nativeEvent.key === 'Delete' || e.nativeEvent.key === 'Backspace') {
        if ( Platform.OS !== WEB){
          if (this.emailDelete && this.state.emailAddresses.length > 0) {
            for (let i = 0; i < this.state.emailAddresses.length; i++) {
              this.remove(this.state.emailAddresses[i].email);
            }
          }
          if(this.state.email === ''){
            this.emailDelete = true;
          }
        } else {
          if (e.nativeEvent.key === 'Delete' || e.nativeEvent.key === 'Backspace') {
            if (this.state.email === '' && this.state.emailAddresses.length > 0) {
              for (let i = 0; i < this.state.emailAddresses.length; i++) {
                this.remove(this.state.emailAddresses[i].email);
              }
            }
          }
        }
      }
  };
  addEmailToList = () => {
    if (this.state.email.trim() === '') {
      setTimeout(() => {
        this.setState({
          error: false,
          errorDescription: '',
        });
      }, 500);
    } else if (validator.validateEmail(this.state.email.trim())) {
      if (this.checkEmailAreadyInList(this.state.email.trim())) {
        setTimeout(() => {
          this.setState(
            {
              email: '',
              error: true,
              errorDescription: 'Email address already exist.',
            },
            () => {
              if (this.textInput) {
                this.textInput.focus();
              }
            },
          );
        }, 500);
      } else {
        const emailAddresses = [...this.state.emailAddresses];
        emailAddresses.push({
          email: this.state.email.trim(),
          permission: 'READ_ONLY',
        });
        if (emailAddresses.length === 5) {
          this.setState({ editable: false });
        }
        setTimeout(() => {
          this.setState(
            {
              email: '',
              emailAddresses: emailAddresses,
              error: false,
              errorDescription: '',
            },
            () => {
              if (this.textInput) {
                this.textInput.focus();
              }
              //Keyboard.dismiss();
            },
          );
        }, 100);
      }
    } else {
      setTimeout(() => {
        this.setState({
          error: true,
          errorDescription: 'Please enter valid email address.',
        });
      }, 500);
    }
  };
  checkEmailAreadyInList = email => {
    const {emailAddresses} = this.state;
    const found = emailAddresses.find(
      res => res.email && email && res.email.toLowerCase() === email.toLowerCase(),
    );
    if (found) {
      return true;
    }
    return false;
  };

  remove = email => {
    const emailAddresses = [...this.state.emailAddresses];
    this.setState(
      {
        emailAddresses: emailAddresses.filter(res => res.email !== email),
        editable: true
      },
      () => {
        if (this.textInput) {
          this.textInput.focus();
        }
      }
    );
  };
  onSendEmail = () => {
    if (this.state.email && this.state.email.trim() !== '') {
      this.addEmailToList();
    } else if (this.state.emailAddresses && this.state.emailAddresses.length > 0) {
      let emailBox = [];
      this.state.emailAddresses.forEach(
        res => {
          emailBox.push(res.email);
        }
      );

      this.props.handleSendEmail(
        emailBox,
        () => {
          this.setState({
            emailAddresses: [],
            email: "",
            editable: true
          });
        }
      );
    } else {
      setTimeout(() => {
        this.setState(
          {
            email: '',
            error: true,
            errorDescription: 'Please enter Email address.',
          },
          () => {
            if (this.textInput) {
              this.textInput.focus();
            }
          },
        );
      }, 500);
    }
  };
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <Modal
        visible={this.props.visibleModalSendEmail}
        onRequestClose={() => {
          this.setState({ emailAddresses: [], email: "", editable: true });
          this.props.onRequestClose();
        }}
        onBackdropPress={() => {
          this.setState({ emailAddresses: [], email: "", editable: true });
          this.props.onBackdropPress();
        }}
      >
        <View style={stylesRes.modalScrollWrapperCom} dataSet={{media: ids.modalScrollWrapperCom}}>
          <ScrollView
            style={styles.modalScroller}
            keyboardShouldPersistTaps={'always'}
          >
            <View style={[styles.popUpBlock, styles.bgWhite]}>
              <View style={styles.popUpContentArea}>
                <View style={styles.gridSectionPopup}>
                  <View style={styles.popUpContentPd}>
                    <View>
                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <View style={styles.secArea}>
                            <View style={[styles.secSubArea, styles.contPdTop]}>
                              <View style={styles.fieldRow}>
                                <View
                                  style={[
                                    styles.fullEmailWrap,
                                    styles.emailSendWrapperFull
                                  ]}
                                  onPress={() => {
                                    if (this.textInput) {
                                      this.textInput.focus();
                                    }
                                  }}
                                >
                                  <View
                                    style={[
                                      styles.listEmailAll,
                                      styles.listEmailAllHeight,
                                      styles.flexRow
                                    ]}
                                  >
                                    <View style={styles.listEmailTextSec}>
                                      <Text style={styles.textView} allowFontScaling={false}>
                                        <UIText
                                          style={[
                                            styles.textLabel,
                                            styles.bold,
                                            styles.textColorLight
                                          ]}
                                          title="Email:"
                                        />
                                      </Text>
                                    </View>
                                    <View style={styles.listEmailScrollWrap}>
                                      {Boolean(this.state.emailAddresses) && this.state.emailAddresses.length > 0 && this.state.emailAddresses.map(
                                        (res, index) => (
                                          <View style={styles.listEmailSingle}>
                                            <View style={styles.listEmailName}>
                                            <Text style={styles.textPrimeSm} allowFontScaling={false}>
                                              {res.email}
                                            </Text>
                                            </View>
                                            <View
                                              style={[
                                                styles.smCloseIconView,
                                                styles.iconLtSp
                                              ]}
                                            >
                                              <TouchableOpacity
                                                onPress={() =>
                                                  this.remove(res.email)
                                                }
                                              >
                                                <Image
                                                  source={require("../../assets/icons/color-select.png")}
                                                  style={styles.colorSelectIcon}
                                                  resizeMode={"contain"}
                                                />
                                              </TouchableOpacity>
                                            </View>
                                          </View>
                                        )
                                      )}
                                      <View style={styles.textFieldEmailView}>
                                        {!this.state.editable ? (
                                          <Text
                                            style={[
                                              styles.textFieldEmailText,
                                              styles.textPrimeBold
                                            ]}
                                            allowFontScaling={false}
                                          >
                                            Maximum 5 Recipient Only
                                          </Text>
                                        ) : (
                                          <TextInput
                                            style={[
                                              styles.textFieldEmailSm,
                                              { zIndex: 999 }
                                            ]}
                                            onChangeText={
                                              this.onChangeEmailText
                                            }
                                            allowFontScaling={false}
                                            value={this.state.email}
                                            onKeyPress={this.onKeyPress}
                                            onBlur={this.onBlur}
                                            editable={this.state.editable}
                                            underlineColorAndroid="transparent"
                                            ref={input => {
                                              this.textInput = input;
                                            }}
                                          />
                                        )}
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={styles.gridRow}>
                        <View style={[styles.gridColFull, styles.errBoxView]}>
                          {this.state.error && (
                            <Warning errorDescription={this.state.errorDescription} />
                          )}
                        </View>
                      </View>
                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <View style={styles.btnArea}>
                            <View style={stylesRes.btnViewSecSm} dataSet={{media: ids.btnViewSecSm}}>
                              <View style={styles.fieldBtn}>
                                <View style={styles.btnBlockFull}>
                                  <CancelButton 
                                    touchableOnpress={() => {
                                      this.setState({
                                        emailAddresses: [],
                                        email: "",
                                        editable: true
                                      });
                                      this.props.onBackdropPress();
                                    }}
                                    cancelBtnWidthDiff={true}
                                  />
                                </View>
                              </View>
                              <View style={styles.fieldBtnSp} />
                              <View style={styles.fieldBtn}>
                                <View style={styles.btnBlockFull}>
                                  <TouchableOpacity
                                    style={[styles.submitSm]}
                                    underlayColor="rgba(0, 153, 168, 0.8)"
                                    onPress={this.onSendEmail}
                                  >
                                    <View style={styles.btnSingleView}>
                                      <View
                                        style={[
                                          styles.btnSingle,
                                          styles.btnSingleCent
                                        ]}
                                      >
                                        {Boolean(this.props.sendWait) && (
                                          <View style={styles.loaderWait}>
                                            <ActivityIndicator size="small" />
                                          </View>
                                        )}
                                        <UIText
                                          style={[
                                            styles.submitText,
                                            styles.submitTextSm
                                          ]}
                                          title="Send Email"
                                        />
                                      </View>
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </Modal>
    );
  }
}
export default (sendEmailBox);
