import React from "react";
import Tooltip from "react-tooltip-lite";

const mouseHover = (props) => {
  return (
    <Tooltip
      content={props.text}
      background={
        props.light
        ? "#F7F7FA"
        : "#888888"
      }
      color={
      props.light
      ? "#19191D"
      : "#ffffff"
    }
      tipContentClassName={"tooltip-content-mousehover"}
      arrow={true}
      distance={10}
    >
      {props.children}
    </Tooltip>
  );
};

export default mouseHover;
