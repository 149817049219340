import React, {Component} from 'react';
import {MenuProvider} from 'react-native-popup-menu';
import {View} from 'react-native';
import stylesResponsive from '../../CssResponsive';
import {WebMenu} from '../../components/Menu';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import GlobalMessageHandling from './GlobalMessageHandling';
import Tutorial from './Tutorial';
import CommonAddMenu from './CommonAddMenu';
import LearnHow from './LearnHow';
import PrivacyStatment from './PrivacyStatment';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import GraphPreview from './GraphPreview';
import SucessMessage from '../../components/SucessMessage';
import {Auth} from 'aws-amplify';
import {toggleWatchOverview} from '../../store/actions/uistate';
import PendoProfile from '../../components/Profile/PendoProfile';
import GraphRoutineTypeModalContent from '../../components/Settings/RollingGraphs/ModalContent/index';
import * as RootNavigation from '../../navigation/RootNavigation';
import TwilioMessaging from './TwilioMessaging';
import { APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED } from '../../util/featureConfig';

class Layout extends Component {
  constructor(props) {
    super(props);
    const {windowHeight, windowWidth} = props;
    this.state = {
      createSpinner: true,
      createSpinnerToken: props.token ? true : false,
      opened: false,
      addMenu: false,
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      hasPeerProfile: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const getRestWithoutTimeStamp = (para) => {
      const { timeStamp, ...rest } = para;
      return rest;
    }

    const restNew = getRestWithoutTimeStamp(nextProps.myNetwork);
    const restPre = getRestWithoutTimeStamp(this.props.myNetwork);

    if (JSON.stringify(restNew) !== JSON.stringify(restPre)) {
      return true;
    }

    if (nextState.createSpinner !== this.state.createSpinner) {
      return true;
    }

    if (nextState.opened !== this.state.opened) {
      return true;
    }

    if (nextState.addMenu !== this.state.addMenu) {
      return true;
    }

    if (nextState.hasPeerProfile !== this.state.hasPeerProfile) {
      return true;
    }

    if (nextState.createSpinnerToken !== this.state.createSpinnerToken) {
      return true;
    }
    if (nextState.windowHeight !== this.state.windowHeight) {
      return true;
    }
    if (nextState.windowWidth !== this.state.windowWidth) {
      return true;
    }

    if ((nextProps.moreMenu && JSON.stringify(nextProps.moreMenu)) !== (this.props.moreMenu && JSON.stringify(this.props.moreMenu))) {
      return true;
    }

    if ((nextProps.showSideDrawer && JSON.stringify(nextProps.showSideDrawer)) !== (this.props.showSideDrawer && JSON.stringify(this.props.showSideDrawer))) {
      return true;
    }

    if ((nextProps.journalUserId && JSON.stringify(nextProps.journalUserId)) !== (this.props.journalUserId && JSON.stringify(this.props.journalUserId))) {
      return true;
    }

    if ((nextProps.previewGraph && JSON.stringify(nextProps.previewGraph)) !== (this.props.previewGraph && JSON.stringify(this.props.previewGraph))) {
      return true;
    }

    if ((nextProps.token && JSON.stringify(nextProps.token)) !== (this.props.token && JSON.stringify(this.props.token))) {
      return true;
    }
    if ((nextProps.windowHeight && JSON.stringify(nextProps.windowHeight)) !== (this.props.windowHeight && JSON.stringify(this.props.windowHeight))) {
      return true;
    }

    if ((nextProps.windowWidth && JSON.stringify(nextProps.windowWidth)) !== (this.props.windowWidth && JSON.stringify(this.props.windowWidth))) {
      return true;
    }

    if ((nextProps.emailVerified && JSON.stringify(nextProps.emailVerified)) !== (this.props.emailVerified && JSON.stringify(this.props.emailVerified))) {
      return true;
    }

    if ((nextProps.dashboard && JSON.stringify(nextProps.dashboard)) !== (this.props.dashboard && JSON.stringify(this.props.dashboard))) {
      return true;
    }

    if ((nextProps.peerProfile && JSON.stringify(nextProps.peerProfile)) !== (this.props.peerProfile && JSON.stringify(this.props.peerProfile))) {
      return true;
    }

    if ((nextProps.userDefinedMetrics && JSON.stringify(nextProps.userDefinedMetrics)) !== (this.props.userDefinedMetrics && JSON.stringify(this.props.userDefinedMetrics))) {
      return true;
    }

    if ((nextProps.userSymptoms && JSON.stringify(nextProps.userSymptoms)) !== (this.props.userSymptoms && JSON.stringify(this.props.userSymptoms))) {
      return true;
    }

    if ((nextProps.userTreatments && JSON.stringify(nextProps.userTreatments)) !== (this.props.userTreatments && JSON.stringify(this.props.userTreatments))) {
      return true;
    }

    if ((nextProps.tabMenuIconView && JSON.stringify(nextProps.tabMenuIconView)) !== (this.props.tabMenuIconView && JSON.stringify(this.props.tabMenuIconView))) {
      return true;
    }

    return false

  }

  static getDerivedStateFromProps(props, state) {
    const {token, windowHeight, windowWidth, peerProfile, myNetwork, journalUserId} = props;

    if (state.createSpinnerToken && token === null) {
      return {
        createSpinnerToken: false,
      };
    }
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }
    if (!journalUserId && peerProfile && peerProfile.id && !state.hasPeerProfile) {
      props.onStartTaskGetLatestRequestList(myNetwork?.timeStamp);
      return {
        hasPeerProfile: true,
      };
    }

    return null;
  }

  componentDidMount = async () => {
    const { journalUserId, pullSchedulars } = this.props;
    if (Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED)) {
      pullSchedulars(journalUserId);
    }
    this.props.reminder(this.props.journalUserId);
    this.setState({journalUserIdInState: this.props.journalUserId});
    this.props.keepAlive();

    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      if (user) {
        const data = await Auth.getPreferredMFA(user, { bypassCache: true, });

        if (data && !this.props.journalUserId) {
          let mfaEnabled = false;
          if (data === 'SMS_MFA') {
            mfaEnabled = true;
          }
          this.props.cognitoReminder(true, mfaEnabled, this.notif);
          this.props.setMFAEnable(mfaEnabled);
        }
      }
    } catch (error) {
      console.log('error in authenticator');

    }

    try {
      const res = await Auth.currentUserInfo();
      if (res && !this.props.journalUserId) {
        const emailVerified = res.attributes.email_verified;
        this.props.cognitoReminder(emailVerified, true, this.notif);
        this.props.setEmailVerified(emailVerified);
      }
    } catch (error) {
      console.log('error in get user info');
    }

    this.setState({ createSpinnerToken: false, createSpinner: false });
    this.props.resetHealthJourneyEvents();

  };

  setCreateSpinner = () => {
    this.setState({createSpinner: !this.state.createSpinner});
  };

  getCointainer = (navigationProps, stylesRes, ids) => {
    const {windowWidth, tabMenuIconView} = this.props;
    let tabMenuBreakPointStart = 991;
    let tabMenuBreakPointEnd = 1024;
    let tabDevice = false;
    this.props.setIsTabDevice(false);
    if (
      windowWidth > tabMenuBreakPointStart &&
      windowWidth <= tabMenuBreakPointEnd
    ) {
      tabDevice = true;
      this.props.setIsTabDevice(true);
    }
    let moreMenu = stylesRes.sidebarMargin;
    let moreMenuId = ids.sidebarMargin;
    if (this.props.moreMenu) {
      moreMenu = stylesRes.sidebarMarginFull;
      moreMenuId = ids.sidebarMarginFull;
    }
    if (tabDevice && tabMenuIconView) {
      moreMenu = stylesRes.sidebarMarginSm;
      moreMenuId = ids.sidebarMarginSm;
    }
    let backDropStatus = stylesRes.backDropClose;
    if (this.props.showSideDrawer) {
      backDropStatus = stylesRes.backDropOpen;
    }
    return (
      <View style={stylesRes.layoutWrapper}>
        <View
          style={stylesRes.layoutWrapper}
          pointerEvents={
            this.state.opened === true || this.state.addMenu === true
              ? 'none'
              : 'auto'
          }>
          <WebMenu
            navigation={navigationProps}
            tabDevice={tabDevice}
            tabMenuIconView={tabDevice ? tabMenuIconView : false}
          />
          <View style={[stylesRes.mainContainer, moreMenu, backDropStatus]} dataSet={{media: moreMenuId}}>
            {this.state.createSpinner || this.state.createSpinnerToken ? (
              <>
                <ActivityIndicator />
                <View style={{backgroundColor: 'white'}}>
                  {this.props.children}
                </View>
              </>
            ) : (
              this.props.children
            )}
            <Tutorial moreMenu={this.props.moreMenu} />
            {this.props.previewGraph && <GraphPreview />}
          </View>
        </View>
      </View>
    );
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    let navigationProps = RootNavigation.getNavigationProps();
    const tabMenuIconView = this.props.tabMenuIconView;
    const { journalUserId, peerProfile } = this.props;
    return (
      <MenuProvider style={[stylesRes.bgWrapper]}>
        <View style={[stylesRes.pageLayoutWrapper]}>
          <GlobalMessageHandling />
          {!journalUserId && peerProfile && peerProfile.id &&
            <TwilioMessaging navigation={navigationProps} />
          }
          {this.getCointainer(navigationProps, stylesRes, ids)}
          <LearnHow />
          <PrivacyStatment />
          <PendoProfile />
          <SucessMessage navigation={navigationProps} />
          <CommonAddMenu
            setCreateSpinner={this.setCreateSpinner}
            addMenu={this.state.addMenu}
            addMenuState={data => {
              this.setState({addMenu: data});
            }}
            navigation={navigationProps}
            opened={this.state.opened}
            openedState={data => {
              this.setState({opened: data});
            }}
            tabMenuIconView={tabMenuIconView}
          />
          <GraphRoutineTypeModalContent
            navigation={navigationProps}
          />
        </View>
      </MenuProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    moreMenu: state.uistate.moreMenu,
    showSideDrawer: state.uistate.showSideDrawer,
    journalUserId: state.uistate.journalUserId,
    previewGraph: state.rollinggraphs.previewGraph,
    token: state.uistate.token,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    tabMenuIconView: state.uistate.tabMenuIconView,
    emailVerified: state.auth.emailVerified,
    userConditions: state.routines.userConditions,
    userTreatments: state.routines.treatments,
    userSymptoms: state.routines.symptomsDigest
      ? state.routines.symptomsDigest.userSymptoms
      : [],
    userDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.userDefinedMetrics
      : [],
    dashboard: state.routines.dashboard,
    peerProfile: state.routines.peer2peer,
    myNetwork: state.routines.myNetwork,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    keepAlive: () => dispatch(actions.keepAlive()),
    reminder: journalUserId => dispatch(actions.reminder(journalUserId)),
    setEmailVerified: emailVerified =>
      dispatch(actions.setEmailVerified(emailVerified)),
    setMFAEnable: mfaEnable => dispatch(actions.setMFAEnable(mfaEnable)),
    cognitoReminder: (emailVerified, mfaEnable, notif) =>
      dispatch(actions.cognitoReminder(emailVerified, mfaEnable, notif)),
    setIsTabDevice: isTabDevice =>
      dispatch(actions.setIsTabDevice(isTabDevice)),
    toggleWatchOverviewVideo: val => dispatch(toggleWatchOverview(val)),
    onStartTaskGetLatestRequestList: timeStamp =>
      dispatch(actions.startTaskGetLatestRequestList(timeStamp)),
    resetHealthJourneyEvents: () => dispatch(actions.resetHealthJourneyEvents()),
    pullSchedulars: journalUserId => dispatch(actions.pullSchedulars(journalUserId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);
