export const NAME = "Name";
export const PRESCRIBER = "Prescriber";
export const DIN_NPN = "DIN / NPN";
export const INSTRUCTION = "Instruction";
export const QUANTITY = "Quantity";
export const UNITS = "Units";
export const NOTES = "Notes";
export const QUESTIONS = "Questions";
export const SAVE = "Save";
export const CANCEL = "Cancel";
export const TITLE = "New medicine or therapy";
export const FREQUENCY = "Frequency";
