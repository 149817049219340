import React from "react";
import { Text } from "react-native";
import styles from "../../Css";

const helpContact = () => {
  return (
    <Text style={styles.textPrime}  allowFontScaling={false}>
        Indicate which Contacts are members of your Care Team, so they are automatically listed on your Reports. 
    </Text>
  );
};

export default helpContact;
