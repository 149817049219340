import React, {Component} from 'react';
import {View, Image, Keyboard} from 'react-native';
import QuestionMultipleSelection from './QuestionMultipleSelection/QuestionMultipleSelection';
import QuestionSingleSelection from './QuestionSingleSelection/QuestionSingleSelection';
import QuestionLongAnswer from './QuestionLongAnswer/QuestionLongAnswer';
import QuestionSlider from './QuestionSlider/QuestionSlider';
import QuestionSymptomSlider from './QuestionSymptomSlider/QuestionSymptomSlider';
import PageContainer from "../../../components/PageLayout/PageContainer";
import styles from '../../../Css';
import stylesCss from './SurveyQuestionStyle';
import stylesResponsive from '../../../CssResponsive';
import ArrowPrevButton from '../../../components/Button/arrowPrevButton';
import ArrowNextButton from '../../../components/Button/arrowNextButton';
import SubmitButton from '../../../components/Button/submitButton';
import Description from './Description/Description';
import {QUESTION_TYPES} from './utils/questionUtilConstants';
import surveyAnswerValidation, {getSurveyTableErrorRows} from './utils/surveyAnswerValidation';
import KeyboardAwareScrollViewHoc from '../../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import {connect} from "react-redux";
import * as actions from "../../../store/actions/index";
import SurveyProgressBar from '../../../components/Surveys/SurveyProgressBar/SurveyProgressBar';
import MenuOverlayContainer from '../../../components/PageLayout/MenuOverlayContainer';
import SavingConfirmation from '../../../components/ConfirmationBox/savingConfirmation';
import {SURVEYS} from '../../../navigation/path';
import {TAB_BREAk_POINT_START} from '../../../util/platformWindowConfig';
import SurveyTable from "../SurveyQuestions/SurveyTable";
import SurveyTableGraphWrapper from "../SurveyQuestions/SurveyTableGraph/SurveyTableGraphWrapper";
import { getScrollHeight } from '../../../util/commonUiLogic';
import { cloneDeep } from 'lodash';

let initAnswer = null;
const tableTypes = {
  TOTAL: 'TOTAL',
  SUB_TOTAL: 'SUB_TOTAL'
}

class SurveyQuestion extends Component {

  state = {
    answer: null,
    isScrollEnabled: true,
    requiredWarning: false,
    numberOfQuestions: 0,
    confirmBox: false,
    showSpinner: false,
    tableDataArray: [],
    requiredSurveyTableRows: [],
    questionTable: this.props.question?.healthSurveyQuestion?.questionTable || [],
  };

  componentDidMount() {
    const {question} = this.props;
    if (question?.questionAnswer && question.questionAnswer.length) {
      const {type, text, range, choiceId, tableValues} = question.questionAnswer[0];
      let updatedAnswer = null;
      if (type === 'CHOICE') {
        updatedAnswer = {
          otherText: text,
          selectedItem: choiceId && choiceId.length ? choiceId[0] : null,
        };
      }

      if (type === 'MULTIPLE') {
        updatedAnswer = {
          otherText: text,
          selectedItems: choiceId ? [...choiceId] : [],
        };
      }

      if (type === 'LONG') {
        updatedAnswer = text;
      }

      if (type === 'RANK') {
        updatedAnswer = range;
      }

      if (type === 'SYMPTOM') {
        updatedAnswer = range;
      }


      if (type === 'TABLE') {
        updatedAnswer = tableValues;
      }

      this.setState({answer: updatedAnswer});
      initAnswer = updatedAnswer;
    } else {
      this.setState({answer: null});
      initAnswer = null;
    }
  };

  handleAnswerUpdated = (updatedAnswer, extraParams = {}) => {
    const {requiredSurveyTableRows = []} = this.state;

    this.setState({answer: updatedAnswer, requiredWarning: false, ...extraParams}, () => {
      let rowErrors = requiredSurveyTableRows;
      if (extraParams?.questionTable?.length && requiredSurveyTableRows.length) {
        rowErrors = getSurveyTableErrorRows(extraParams.questionTable)
      }
      this.setState({requiredSurveyTableRows: rowErrors});
    });
  };


  handleSaveAndBackClick = () => {
    Keyboard.dismiss();
    setTimeout(() => {
      const {onShowPrevQuestion, question, isReadOnly, updateAnsweredRatio, surveyQuestions, onSkip} = this.props;
      const {answer, questionTable = []} = this.state;

      if (isReadOnly) {
        onShowPrevQuestion(null);
      } else {
        if (surveyAnswerValidation.isValid(answer, question?.healthSurveyQuestion?.questionType, questionTable)) {
          onShowPrevQuestion(answer);
          updateAnsweredRatio(false, (1 / surveyQuestions.length));
        } else {
          if (question?.healthSurveyQuestion?.required || question?.healthSurveyQuestion?.branchingMode === "SKIP_BASED_ON_OPTION") {
            this.setState({requiredWarning: true});
          } else if (question?.healthSurveyQuestion?.questionType === QUESTION_TYPES.surveyTable) {
            const requiredRows = getSurveyTableErrorRows(questionTable);
            this.setState({requiredSurveyTableRows: requiredRows});
          } else {
            onSkip("PREV");
          }
        }
      }
    }, 100);
  }

  handleSaveAndNextClick = () => {
    Keyboard.dismiss();
    setTimeout(() => {
      const {onShowNextQuestion, question, isReadOnly, updateAnsweredRatio, surveyQuestions, onSkip} = this.props;
      const {answer, questionTable = []} = this.state;

      if (isReadOnly) {
        onShowNextQuestion(null);
      } else {
        if (surveyAnswerValidation.isValid(answer, question?.healthSurveyQuestion?.questionType, questionTable)) {
          onShowNextQuestion(answer);
          updateAnsweredRatio(false, (1 / surveyQuestions.length));
        } else {
          if (question?.healthSurveyQuestion?.required || question?.healthSurveyQuestion?.branchingMode === "SKIP_BASED_ON_OPTION") {
            this.setState({requiredWarning: true});
          } else if (question?.healthSurveyQuestion?.questionType === QUESTION_TYPES.surveyTable) {
            const requiredRows = getSurveyTableErrorRows(questionTable);
            this.setState({requiredSurveyTableRows: requiredRows});
          } else {
            onSkip("NEXT");
          }
        }
      }
    }, 100);
  };

  handleProgressBar = () => {
    const {question, surveyQuestions} = this.props;
    const progressLevel = question.healthSurveyQuestion.questionOrder / surveyQuestions.length * 100;
    return progressLevel;
  }

  handleSubmit = () => {
    Keyboard.dismiss();
    setTimeout(() => {

      const {onSubmit, question, isReadOnly, onSkip} = this.props;
      const {answer, questionTable = []} = this.state;

      if (isReadOnly) {
        onSubmit(null);
      } else {
        if (surveyAnswerValidation.isValid(answer, question?.healthSurveyQuestion?.questionType, questionTable)) {
          onSubmit(answer);
        } else {
          if (question?.healthSurveyQuestion?.required) {
            this.setState({ requiredWarning: true });
          } else if (surveyAnswerValidation.isValid(answer, question?.healthSurveyQuestion?.questionType)) {
            onSubmit(answer);
          } else if (question?.healthSurveyQuestion?.questionType === QUESTION_TYPES.surveyTable) {
            const requiredRows = getSurveyTableErrorRows(questionTable);
            this.setState({requiredSurveyTableRows: requiredRows});
          } else {
            onSkip("SUBMIT");
          }
        }
      }
    }, 100);

  };

  onSkipQuestion = (question) => {
    Keyboard.dismiss();
    const {updateAnsweredRatio, surveyQuestions, onSkip} = this.props;
    updateAnsweredRatio(false, (1 / surveyQuestions.length));

    if (question?.nextQuestionId !== null) {
      onSkip("NEXT");
    } else {
      onSkip("SUBMIT");
    }

  }

  handleOnPressCancelButton = () => {
    this.props.navigation.replace(SURVEYS.path);
  }

  handleOnPressSaveButton = () => {
    setTimeout(() => {
      const {handleOnSaveAnswer, question, isReadOnly} = this.props;
      if (isReadOnly) {
        this.props.navigation.replace(SURVEYS.path);
      } else {
        if (surveyAnswerValidation.isValid(this.state.answer, question?.healthSurveyQuestion?.questionType)) {
          handleOnSaveAnswer(this.state.answer);
          this.setState({showSpinner: true});
          setTimeout(() => {
            this.props.navigation.replace(SURVEYS.path);
            this.setState({showSpinner: false, confirmBox: false});
          }, 3000);
        } else {
          if (question?.healthSurveyQuestion?.required || question?.healthSurveyQuestion?.branchingMode === "SKIP_BASED_ON_OPTION") {
            this.setState({requiredWarning: true, confirmBox: false});
          } else {
            handleOnSaveAnswer(null, true);
            this.setState({showSpinner: true});
            setTimeout(() => {
              this.props.navigation.replace(SURVEYS.path);
              this.setState({showSpinner: false, confirmBox: false});
            }, 3000);
          }
        }
      }
    }, 100);
  }

  isAnswerHaveChanges = () => {
    return JSON.stringify(initAnswer) !== JSON.stringify(this.state.answer);
  }

  sortGraphDataByTableType = (healthSurveyTableSummaries) => {

    const concatedArray = healthSurveyTableSummaries.filter(res => res?.tableType === tableTypes.TOTAL).concat(healthSurveyTableSummaries.filter(res => res?.tableType !== tableTypes.TOTAL).sort((a,b) => a?.rowId - b?.rowId));
    
    const formattedArray = concatedArray.map(item => {
      const copiedItem= cloneDeep(item);
      const copiedTableScores= cloneDeep(copiedItem.tableScores);
      copiedTableScores.push({ answerId: copiedItem.selectedAnswerId, score: copiedItem.score, completedDate: copiedItem.completedDate });
      
      copiedItem.tableScores = copiedTableScores;
      return copiedItem;
    })

    return formattedArray;
  }

  renderSurveyContent = () => {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {answer, requiredWarning} = this.state;
    const {
      windowWidth,
      question,
      isReadOnly,
      tabDevice,
      tabMenuIconView
    } = this.props;

    let fullWidth = windowWidth;
    let bannerWidth = 0;
    let bannerHeight = 0;
    if (fullWidth > TAB_BREAk_POINT_START) {
      bannerWidth = fullWidth - 455;
    } else {
      bannerWidth = fullWidth;
    }
    bannerHeight = (260 / 1500) * bannerWidth;
    let longAnswerQuestion = question?.healthSurveyQuestion?.questionType === QUESTION_TYPES.longAnswerQuestion;
    let surveyTableQuestion = question?.healthSurveyQuestion?.questionType === QUESTION_TYPES.surveyTable;
    return (
      <>
                  <View
                    style={[question?.healthSurveyQuestion?.questionType !== QUESTION_TYPES.descriptiveQuestion && stylesRes.contentMainWrapper]} // wedon't need this style for description screen
                    dataSet={{media: ids.contentMainWrapper}}
                  />
                  {question?.healthSurveyQuestion?.questionType === QUESTION_TYPES.descriptiveQuestion &&
                    <View style={[styles.surveyBannerWrapper]}>
                      <View style={[styles.surveyBannerFull]}>
                        {fullWidth > 560 ?

                          <Image
                            style={[
                              styles.surveyBannerFull,
                              {height: bannerHeight},
                            ]}
                            source={require("../../../assets/background-images/survey-banner-1.png")}
                          />
                          :
                          <Image
                            style={[
                              styles.surveyBannerFull,
                              {height: 175},
                            ]}
                            source={require("../../../assets/background-images/survey-bg-mobile.png")}
                          />}
                      </View>
          </View>
        }
        <PageContainer>
          <View style={stylesRes.questionsOuterWrapper}>
            {question?.healthSurveyQuestion?.questionType === QUESTION_TYPES.choiceQuestion &&
              <QuestionSingleSelection
                imageUrl={question?.healthSurveyQuestion?.s3ImageUrl}
                title={question?.healthSurveyQuestion?.questionText}
                isReadOnly={(isReadOnly)}
                answer={answer}
                questionIndex={question?.healthSurveyQuestion?.questionOrder}
                allowOtherText={question?.healthSurveyQuestion?.ownAnswer && question?.healthSurveyQuestion?.branchingMode !== "SKIP_BASED_ON_OPTION"}
                onSelect={(tempAnswer) => {
                  this.handleAnswerUpdated(tempAnswer)
                }}
                onChangeText={(tempAnswer) => {
                  this.handleAnswerUpdated(tempAnswer)
                }}
                choices={question?.healthSurveyQuestion?.questionChoices || []}
                required={question?.healthSurveyQuestion?.required || question?.healthSurveyQuestion?.branchingMode === "SKIP_BASED_ON_OPTION"}
                requiredWarning={requiredWarning}
                onSkip={() => this.onSkipQuestion(question)}
              />}

            {question?.healthSurveyQuestion?.questionType === QUESTION_TYPES.multipleAnswerQuestion &&
              <QuestionMultipleSelection
                answer={answer}
                imageUrl={question?.healthSurveyQuestion?.s3ImageUrl}
                title={question?.healthSurveyQuestion?.questionText}
                isReadOnly={(isReadOnly)}
                questionIndex={question?.healthSurveyQuestion?.questionOrder}
                allowOtherText={question?.healthSurveyQuestion?.ownAnswer}
                onChangeText={(tempAnswer) => {
                  this.handleAnswerUpdated(tempAnswer)
                }}
                choices={question?.healthSurveyQuestion?.questionChoices || []}
                selectedItems={answer || []}
                onChange={(selectedItems) => this.handleAnswerUpdated(selectedItems)}
                required={question?.healthSurveyQuestion?.required}
                requiredWarning={requiredWarning}
                onSkip={() => this.onSkipQuestion(question)}
              />}

            {Boolean(longAnswerQuestion) &&
              <QuestionLongAnswer
                imageUrl={question?.healthSurveyQuestion?.s3ImageUrl}
                title={question?.healthSurveyQuestion?.questionText}
                isReadOnly={isReadOnly}
                questionIndex={question?.healthSurveyQuestion?.questionOrder}
                answer={answer}
                onChangeText={(text) => this.handleAnswerUpdated(text)}
                required={question?.healthSurveyQuestion?.required}
                requiredWarning={requiredWarning}
                onSkip={() => this.onSkipQuestion(question)}
              />}

            {question?.healthSurveyQuestion?.questionType === QUESTION_TYPES.slider &&
              <QuestionSlider
                imageUrl={question?.healthSurveyQuestion?.s3ImageUrl}
                title={question?.healthSurveyQuestion?.questionText}
                isReadOnly={isReadOnly}
                questionIndex={question?.healthSurveyQuestion?.questionOrder}
                answer={answer}
                minValue={question?.healthSurveyQuestion?.minimumPoints}
                maxValue={question?.healthSurveyQuestion?.maximumPoints}
                minScaleDescription={question?.healthSurveyQuestion?.minDesc || null}
                maxScaleDescription={question?.healthSurveyQuestion?.maxDesc || null}
                onChange={(value) => this.handleAnswerUpdated(value)}
                // onDisableScroll={(isDisabled) => this.setState({ isScrollEnabled: isDisabled })}
                required={question?.healthSurveyQuestion?.required}
                requiredWarning={requiredWarning}
                onSkip={() => this.onSkipQuestion(question)}
              />}

            {question?.healthSurveyQuestion?.questionType === QUESTION_TYPES.symptomSliderQuestion &&
              <QuestionSymptomSlider
                imageUrl={question?.healthSurveyQuestion?.s3ImageUrl}
                title={question?.healthSurveyQuestion?.questionText}
                isReadOnly={isReadOnly}
                questionIndex={question?.healthSurveyQuestion?.questionOrder}
                measureType={question?.healthSurveyQuestion?.measureType}
                sliderDescriptionArray={
                  question?.healthSurveyQuestion?.measureType === 'SLIDER' && question?.healthSurveyQuestion?.instruction
                    ? JSON.parse(question?.healthSurveyQuestion?.instruction)
                    : null
                }
                answer={answer}
                windowWidth={windowWidth}
                onChange={(value) => this.handleAnswerUpdated(value)}
                // onDisableScroll={(isDisabled) => this.setState({ isScrollEnabled: isDisabled })}
                required={question?.healthSurveyQuestion?.required}
                requiredWarning={requiredWarning}
                onSkip={() => this.onSkipQuestion(question)}
              />}

            {question?.healthSurveyQuestion?.questionType === QUESTION_TYPES.descriptiveQuestion &&
              <Description
                imageUrl={question?.healthSurveyQuestion?.s3ImageUrl}
                title={question?.healthSurveyQuestion?.questionText}
                content={question?.healthSurveyQuestion?.instruction}
              />}
            {Boolean(surveyTableQuestion) &&
              <>
                {Boolean(isReadOnly) && Boolean(question?.healthSurveyTableSummaries && question?.healthSurveyTableSummaries.length > 0) &&
                  <SurveyTableGraphWrapper
                    windowWidth={windowWidth}
                    surveyTableGraphData={this.sortGraphDataByTableType(question?.healthSurveyTableSummaries)}
                    tabDevice={tabDevice}
                    tabMenuIconView={tabMenuIconView}
                  />
                }
                <Description
                  imageUrl={question?.healthSurveyQuestion?.s3ImageUrl}
                  title={question?.healthSurveyQuestion?.questionText}
                  content={question?.healthSurveyQuestion?.instruction}
                  showTitleSection={false}
                  onSkip={() => this.onSkipQuestion(question)}
                  required={question?.healthSurveyQuestion?.required}
                  questionIndex={question?.healthSurveyQuestion?.questionOrder}
                  customDescriptionStyle={true}
                />
                <SurveyTable
                  questionItem={question?.healthSurveyQuestion}
                  question={question}
                  errors={this.state.requiredSurveyTableRows}
                            onValueChange={({
                                              questionTable,
                                              answers
                                            }) => this.handleAnswerUpdated(answers, {questionTable})}
                  answer={answer}
                  isReadOnly={isReadOnly}
                  required={question?.healthSurveyQuestion?.required}
                  questionIndex={question?.healthSurveyQuestion?.questionOrder}
                  navigation={this.props.navigation}
                />
              </>
            }
          </View>
        </PageContainer>
      </>
    );
  }

  renderButtonContent = () => {
    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    const {
      windowWidth,
      question,
      answerSaved,
      isReadOnly,
      questionDirection,
    } = this.props;

    let fullWidth = windowWidth;

    return (
      <PageContainer>
        <View style={stylesRes.prevNextBtnSurveyWrapper}>
          {question?.previousQuestionId !== null &&
                    <View style={[stylesRes.responsiveBackBtnMargin, stylesRes.btnPdBot]}
                          dataSet={{media: ids.responsiveBackBtnMargin}}>
              <ArrowPrevButton
                touchableStyle={[stylesCss.btnMaxWidthBorder]}
                btnText={isReadOnly ? 'Back' : 'Save & Back'}
                touchableOnPress={this.handleSaveAndBackClick}
                prevWait={answerSaved.fetching && questionDirection === 'PREV'}
              />
            </View>}
          {question?.nextQuestionId !== null &&
                    <View style={[stylesRes.responsiveNextBtnMargin, stylesRes.btnPdBot]}
                          dataSet={{media: ids.responsiveNextBtnMargin}}>
              <ArrowNextButton
                touchableStyle={[stylesCss.btnMaxWidthBorder]}
                btnText={isReadOnly ? 'Next' : 'Save & Next'}
                touchableOnPress={this.handleSaveAndNextClick}
                disabled={answerSaved.fetching}
                nextWait={answerSaved.fetching && questionDirection === 'NEXT'}
              />
            </View>}
          {question?.nextQuestionId === null &&
            <View style={[stylesRes.responsiveSubmitBtnMargin, stylesRes.btnPdBot]}>
              <SubmitButton
                touchableStyle={[stylesCss.btnMaxWidthBorder, stylesCss.submitBtn]}
                btnText={isReadOnly ? 'Done' : 'Submit'}
                touchableOnPress={this.handleSubmit}
                disabled={answerSaved.fetching}
                nextWait={answerSaved.fetching && questionDirection === 'SUBMIT'}
              />
            </View>}
        </View>
        <SurveyProgressBar
          windowWidth={fullWidth}
          progress={this.props.answeredRatio}
        />
      </PageContainer>
    );
  }

  render() {
    const {
      windowHeight,
      windowWidth,
      question,
      hasNotch,
      journalUserId,
      timeZoneWarning,
      timeZone,
    } = this.props;

    let scrollMaxHeight = getScrollHeight(hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth, 160);

    let longAnswerQuestion = question?.healthSurveyQuestion?.questionType === QUESTION_TYPES.longAnswerQuestion;
    let surveyTableQuestion = question?.healthSurveyQuestion?.questionType === QUESTION_TYPES.surveyTable;

    return (
      <>
        {this.isAnswerHaveChanges() ? (
          <MenuOverlayContainer onPressOpen={() => this.setState({ confirmBox: true })} />
        ) : null}
        {Boolean(longAnswerQuestion || surveyTableQuestion) ? (
          <KeyboardAwareScrollViewHoc extraScrollHeight={50} enableResetScrollToCoords>
            <View style={[styles.pageBodyWrapper]}>
              <View style={styles.gridSection}>
                {this.renderSurveyContent()}
                {this.renderButtonContent()}
              </View>
            </View>
          </KeyboardAwareScrollViewHoc>
        ) : (
          <View style={[styles.pageBodyWrapper]}>
            <View style={styles.gridSection}>
              <View style={{ height: scrollMaxHeight }}>
                <KeyboardAwareScrollViewHoc extraScrollHeight={-70} enableResetScrollToCoords>
                  {this.renderSurveyContent()}
                </KeyboardAwareScrollViewHoc>
              </View>
              {this.renderButtonContent()}
            </View>
          </View>
        )}
        <SavingConfirmation
          visibleModal={this.state.confirmBox}
          alertTitle={"Save changes to your Question before closing?"}
          alertBody={"Any unsaved changes will be lost."}
          handleCancel={() => {
            this.setState({confirmBox: false}, () => {
              this.handleOnPressCancelButton();
            });
          }}
          handleConfirm={() => this.handleOnPressSaveButton()}
          confirmWait={this.state.showSpinner}
          disabled={this.state.showSpinner}
          onBackdropPress={() => {
            if (!this.state.showSpinner) {
              this.setState({confirmBox: false});
            }
          }}
        />
      </>
    );
  }
};

const mapStateToProps = state => {
  return {
    surveyQuestions: state.surveys.questions,
    answeredRatio: state.surveys.answeredRatio,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateAnsweredRatio: (isNew, ratio) =>
      dispatch(actions.setAnsweredRatio(isNew, ratio)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyQuestion);
