import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import MyNetworkComponent from '../../../../components/PeerToPeer/MainSection/MyNetwork/MyNetworkComponent';
import {PEER_TO_PEER} from '../../../../navigation/path';
import {actionStatus} from '../../../../util/commonUiLogic';
import { pendoTrackEvent } from '../../../../util/pendoConfig';
import { EventRegister } from 'react-native-event-listeners';

class MyNetworkScreen extends Component {
  constructor(props) {
    super(props);
    const {myNetwork} = props;
    this.state = {
      visibleReceived:
        myNetwork.received && myNetwork.received.length > 0 ? true : false,
      visibleSent: myNetwork.sent && myNetwork.sent.length > 0 ? true : false,
      visibleConnected:
        myNetwork.connected && myNetwork.connected.length > 0 ? true : false,
      myNetwork: props.myNetwork,
      myNetworkAll: props.myNetwork,
      loading: true,
      searchedKeyword: null,
      connecting: false,
      connectionID: null,
      wait: false,
      visibleDisconnectPopup: false,
      visibleBlockPopup: false,
      visibleReportPopup: false,
      visibleSuccessMessage: false,
      selectedUser: null,
      connectionId: null,
      status: null,
      reportType: null,
      disconnectWait: false,
      blockWait: false,
      reportWait: false,
      successMessage: null,
      successMessageTitle: null,
      profileLocked: false
    };
  }

  componentDidMount() {
    const {
      getRef,
      commonErrorHandler,
      fromNotifId,
      myNetwork,
      onClearMenuSectionNotifications,
      seenNotification,
    } = this.props;
    getRef && getRef(this);

    this.getPeer2PeerProfileData()
    //To manage My Connections Tab unseen request count
    MyNetworkScreen.upateNewlySeenRequests(myNetwork, this.props);
    this.filterMyNetwork(this.state.searchedKeyword);

    if (fromNotifId) {
      if (myNetwork.received && myNetwork.received.length > 0) {
        let index = myNetwork.received.findIndex(
          res => res.connectionId === fromNotifId,
        );
        if (index < 0) {
          commonErrorHandler('Sorry, this invitation have been withdrawn.');
        }
      }
    }
    const filteredNotifications = Object.values(seenNotification).filter(res => res.type === 'PEER_NOTIFICATION');
    if (filteredNotifications && filteredNotifications.length > 0) {
      onClearMenuSectionNotifications(filteredNotifications);
    }
    this.setState({loading: false});
    
    pendoTrackEvent('Community/@MyConnections');
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    });
  }

  getPeer2PeerProfileData = () => {
    this.props.getPeer2PeerProfileData((success, res) => {
      if (success) {
        this.setState({
          profileLocked: res?.isEnabledPopup,
        });
      }
    });   
  }
  static upateNewlySeenRequests = (myNetwork, props) => {
    const {onUpdateSeenRequests} = props;
    if (myNetwork.received && myNetwork.received.length > 0) {
      const newlySeenRequests = myNetwork.received.filter(
        item => !item.isNotified,
      );
      if (newlySeenRequests && newlySeenRequests.length > 0) {
        const newlySeenRequestsIdList = [
          ...new Set(newlySeenRequests.map(item => item.connectionId)),
        ];
        const connectionIdObj = {
          connectionId: newlySeenRequestsIdList,
        };
        onUpdateSeenRequests(connectionIdObj);
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth, myNetwork} = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth ||
      state.myNetworkAll.received.length !== myNetwork.received.length ||
      state.myNetworkAll.sent.length !== myNetwork.sent.length ||
      state.myNetworkAll.connected.length !== myNetwork.connected.length
    ) {
      //To manage My Connections Tab unseen request count(when user is in same Tab)
      MyNetworkScreen.upateNewlySeenRequests(myNetwork, props);
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        myNetwork: myNetwork,
        myNetworkAll: myNetwork,
        visibleReceived:
          myNetwork.received && myNetwork.received.length > 0 ? true : false,
        visibleSent:
          myNetwork.sent && myNetwork.sent.length > 0 ? true : false,
        visibleConnected:
          myNetwork.connected && myNetwork.connected.length > 0 ? true : false,
      };
    }

    return null;
  }

  componentWillUnmount = () => {
    const {onUpdateSearchedKeyword, onSetFromNotifId, fromNotifId} = this.props;
    if (this.state.searchedKeyword) {
      onUpdateSearchedKeyword('');
    }
    if (fromNotifId) {
      onSetFromNotifId(null);
    }
  };

  handleOnPressCollapsibleRecieved = () => {
    this.setState({
      visibleReceived: !this.state.visibleReceived,
    });
  };
  handleOnPressCollapsibleSent = () => {
    this.setState({
      visibleSent: !this.state.visibleSent,
    });
  };
  handleOnPressCollapsibleConnected = () => {
    this.setState({
      visibleConnected: !this.state.visibleConnected,
    });
  };

  handleRedirect = () => {
    pendoTrackEvent('Community > @MyConnections > New Connections');
    this.props.onSetSearchTearms(null);
    this.props.navigation.push(PEER_TO_PEER.findPeersPath);
  };

  onSelectActionStatus = (connectionId, status, selectedUser) => {
    this.setState(
      {
        connectionId: connectionId,
        status: status,
        selectedUser: selectedUser,
      },
      () => {
        switch (status) {
          case actionStatus.CONNECTED:
            if (!this.state.wait) {
            this.setState(
              {
                connecting: true,
                connectionID: connectionId,
              },
              () => this.handleMyNetworkAction(),
            );
            }
            break;
          case actionStatus.DECLINED:
            if (!this.state.connecting) {
            this.setState(
              {
                wait: true,
                connectionID: connectionId,
              },
              () => this.handleMyNetworkAction(),
            );
            }
            break;
          case actionStatus.DISCONNECT:
            this.setState({visibleDisconnectPopup: true});
            break;
          case actionStatus.BLOCK:
            this.setState({visibleBlockPopup: true});
            break;
          case actionStatus.REPORT:
            this.setState({visibleReportPopup: true});
            break;
          default:
            this.handleMyNetworkAction();
            break;
        }
      },
    );
  };

  handleMyNetworkAction = callBack => {
    const {onHandleMyNetworkAction, commonErrorHandler} = this.props;
    const {connectionId, status, reportType} = this.state;
    const myNetworkActionObj = {
      connectionId: connectionId,
      status: status,
      reportType: reportType,
    };

    onHandleMyNetworkAction(myNetworkActionObj, (success, res) => {
      const { updateBulkMyNetworkDataByStatusAndConnectionId } = this.props;
      const { myNetwork } = this.state;

      switch (status) {
        case actionStatus.CONNECTED:
          this.setState({connecting: false, connectionID: null});
          break;
        case actionStatus.DECLINED:
          this.setState({wait: false, connectionID: null});
          break;
        default:
          // nothing;
          break;
      }
      if (success) {
        updateBulkMyNetworkDataByStatusAndConnectionId(res.connectionStatus, res.id, res?.channelId, () => {
          if (res.connectionStatus === actionStatus.CONNECTED) {
            this.redirectToIndividualChat(res.channelId, []);
          }
        });
      } else {
        commonErrorHandler(res);
      }
      if (callBack) {
        callBack(success);
      }
    });
  };

  filterMyNetwork = keyword => {
    const {myNetwork} = this.props;
    this.setState({
      searchedKeyword: keyword,
      visibleReceived: myNetwork.received && myNetwork.received.length > 0 ? true : false,
      visibleSent: myNetwork.sent && myNetwork.sent.length > 0 ? true : false,
      visibleConnected: myNetwork.connected && myNetwork.connected.length > 0 ? true : false,
    });
    this.props.onUpdateSearchedKeyword(keyword);
    return keyword;
  };

  hidePopup = (popupName, showNextPopup) => {
    switch (popupName) {
      case 'DISCONNECT_POPUP':
        this.setState({
          visibleDisconnectPopup: false,
          visibleSuccessMessage: showNextPopup ? true : false,
        });
        break;
      case 'REPORT_POPUP':
        this.setState({
          visibleReportPopup: false,
          visibleSuccessMessage: showNextPopup ? true : false,
          selectedUser: showNextPopup && null,
        });
        break;
      case 'BLOCK_POPUP':
        this.setState({
          visibleBlockPopup: false,
          visibleSuccessMessage: showNextPopup ? true : false,
          selectedUser: showNextPopup && null,
        });
        break;
      default:
        this.setState({
          visibleSuccessMessage: false,
        });
        break;
    }
  };

  handleOnDisconnectPeer = () => {
    this.setState({disconnectWait: true});
    this.handleMyNetworkAction(success => {
      if (success) {
        this.setState(
          {
            successMessage: 'You will be disconnected with',
            successMessageTitle: 'Disconnected',
          },
          () => {
            this.hidePopup('DISCONNECT_POPUP', true);
            setTimeout(() => {
              this.hidePopup();
            }, 3000);
          },
        );
      } else {
        this.hidePopup('DISCONNECT_POPUP');
      }
      this.setState({disconnectWait: false});
    });
  };

  handleOnBlockPeer = () => {
    const {selectedUser} = this.state;
    this.setState({blockWait: true});
    this.handleMyNetworkAction(success => {
      if (success) {
        this.setState(
          {
            successMessage:
              'You will be disconnected and you will not show up in ' +
              (selectedUser && selectedUser?.userName) +
              '’s search.',
            successMessageTitle: 'Blocked',
          },
          () => {
            this.hidePopup('BLOCK_POPUP', true);
            setTimeout(() => {
              this.hidePopup();
            }, 3000);
          },
        );
      } else {
        this.hidePopup('BLOCK_POPUP');
      }
      this.setState({blockWait: false});
    });
  };

  handleOnReportPeer = reportType => {
    const {selectedUser} = this.state;
    this.setState(
      {
        reportWait: true,
        reportType: reportType,
      },
      () => {
        this.handleMyNetworkAction(success => {
          if (success) {
            this.setState(
              {
                successMessage:
                  'You will be disconnected and you will not show up in ' +
                  (selectedUser && selectedUser?.userName) +
                  '’s search.',
                successMessageTitle: 'Report Sent',
              },
              () => {
                this.hidePopup('REPORT_POPUP', true);
                setTimeout(() => {
                  this.hidePopup();
                }, 3000);
              },
            );
          } else {
            this.hidePopup('REPORT_POPUP');
          }
          this.setState({
            reportWait: false,
            reportType: null,
          });
        });
      },
    );
  };

  redirectToIndividualChat = (conversationId, myNetworkUpdatedConnectedList) => {
    this.setState({loading: true}, () => {
      EventRegister.emitEvent("reInitialize", {conversationSid: conversationId})
    });
  };

  redirectToIndividualChatwithoutInitialize = (conversationId, myNetworkUpdatedConnectedList) => {
    this.setState({loading: true}, () => {
      EventRegister.emitEvent("redirectChat", {conversationSid: conversationId})
    });
  };

  render() {
    const {peerProfile, getRef, windowWidth, conversations} = this.props;
    const {
      visibleReceived,
      visibleSent,
      visibleConnected,
      myNetwork,
      loading,
      searchedKeyword,
      connecting,
      connectionID,
      wait,
      visibleDisconnectPopup,
      visibleBlockPopup,
      visibleReportPopup,
      visibleSuccessMessage,
      selectedUser,
      disconnectWait,
      blockWait,
      reportWait,
      successMessage,
      successMessageTitle,
      profileLocked
    } = this.state;
    getRef && getRef(this);
    return (
      <MyNetworkComponent
        hasPeerProfile={peerProfile && peerProfile?.username ? true : false}
        visibleReceived={visibleReceived}
        visibleSent={visibleSent}
        visibleConnected={visibleConnected}
        onPressCollapsibleRecieved={this.handleOnPressCollapsibleRecieved}
        onPressCollapsibleSent={this.handleOnPressCollapsibleSent}
        onPressCollapsibleConnected={this.handleOnPressCollapsibleConnected}
        handleRedirect={this.handleRedirect}
        myNetworkList={myNetwork}
        loading={loading}
        onSelectActionStatus={this.onSelectActionStatus}
        onSearchInputValueChange={this.filterMyNetwork}
        searchedKeyword={searchedKeyword}
        connecting={connecting}
        connectionID={connectionID}
        wait={wait}
        visibleDisconnectPopup={visibleDisconnectPopup}
        visibleBlockPopup={visibleBlockPopup}
        visibleReportPopup={visibleReportPopup}
        visibleSuccessMessage={visibleSuccessMessage}
        hidePopup={this.hidePopup}
        selectedUser={selectedUser}
        onDisconnectPeer={this.handleOnDisconnectPeer}
        disconnectWait={disconnectWait}
        onBlockPeer={this.handleOnBlockPeer}
        blockWait={blockWait}
        onReportPeer={this.handleOnReportPeer}
        reportWait={reportWait}
        successMessage={successMessage}
        successMessageTitle={successMessageTitle}
        windowWidth={windowWidth}
        onPressSend={selectedPeer =>
          this.redirectToIndividualChatwithoutInitialize(selectedPeer?.channelId, [...myNetwork?.connected || []])
        }
        conversations={conversations}
        profileLocked={profileLocked}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    hasNotch: state.uistate.hasNotch,
    peerProfile: state.routines.peer2peer,
    searchedKeyword: state.peerToPeer.searchedKeyword,
    myNetwork: state.routines.myNetwork,
    fromNotifId: state.uistate.fromNotifId,
    seenNotification: state.uistate.seenNotification,
    conversations: state.peerToPeer.channels?.filter(channel => channel.id) ?? [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetSearchTearms: searchTearms =>
      dispatch(actions.setSearchTearms(searchTearms)),
    onHandleMyNetworkAction: (myNetworkActionObj, callBack) =>
      dispatch(actions.handleMyNetworkAction(myNetworkActionObj, callBack)),
    onUpdateSearchedKeyword: keyword =>
      dispatch(actions.updateSearchedKeyword(keyword)),
    OnSetPeerToPeerActiveTab: activeTabIndex =>
      dispatch(actions.setPeerToPeerActiveTab(activeTabIndex)),
    onUpdateSeenRequests: connectionIdObj =>
      dispatch(actions.updateSeenRequests(connectionIdObj)),
    onSetSelectedConversation: conversation =>
      dispatch(actions.setSelectedChannel(conversation)),
    onGetToken: (callBack, userId) =>
      dispatch(actions.getToken(callBack, userId)),
    onSetFromNotifId: id => dispatch(actions.setFromNotifId(id)),
    updateBulkMyNetworkDataByStatusAndConnectionId: (status, connectionId, channelId, callBack) =>
      dispatch(actions.updateBulkMyNetworkDataByStatusAndConnectionId(status, connectionId, channelId, callBack)),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    onClearMenuSectionNotifications: (notificationArray) => 
      dispatch(actions.clearMenuSectionNotifications(notificationArray)),
    getPeer2PeerProfileData : (callBack, journalUserId) =>
      dispatch(actions.getPeer2PeerProfileData(callBack, journalUserId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyNetworkScreen);
