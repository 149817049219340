import {Platform} from 'react-native';
import theme from './base/theme';
import * as variableShadow from './base/variableShadow';

export const csshealthjourney = {
  // Adding Events
  exportPdfBtn: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.COLOR_BLUE_16,
    borderRadius: 20,
    flexDirection: 'row',
    height: 33,
    width: 150,
  },

  exportPdfIcn: {
    height: 14,
    width: 14,
  },

  searchEventsIcnPst: {
    position: 'absolute',
    top: 14,
    left: 35,
  },

  searchEventIcn: {
    width: 20,
    height: 20,
  },

  searchEvenBr: {
    borderColor: theme.COLOR_GREY_LIGHT_RG,
    borderWidth: 2,
    paddingLeft: 50,
  },

  filterIcn: {
    width: 17,
    height: 19,
  },

  filterCount: {
    backgroundColor: theme.COLOR_BLUE_16,
    width: 18,
    height: 18,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 9,
    bottom: 12,
  },

  clearAllText:{
    marginLeft:10,
    textDecorationLine:"underline",
    fontWeight:'600'
  },

  fiveHudrredFtWgt: {
    fontWeight: '500',
  },

  sectionContainerHealthJourney: {
    borderWidth: 1,
    borderColor: '#C4C4C4',
    borderRadius: 4,
    height: 32,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    ...Platform.select({
      web: {
        marginLeft: 5,
      },
      ios: {
        marginLeft: 18,
      },
      android: {
        marginLeft: 18,
      },
    }),
  },

  secGap: {
    paddingRight: 2,
  },

  categoryIcn: {
    width: 15,
    height: 16,
  },

  pdRtSix: {
    paddingRight: 6,
  },

  pdLtSix: {
    paddingLeft: 6,
  },

  pdLtTwelve: {
    paddingLeft: 12,
  },

  pdRtTwelve: {
    paddingRight: 12,
  },

  pdRtFive: {
    paddingRight: 5,
  },

  pdLtFive: {
    paddingLeft: 5,
  },

  btOne: {
    bottom: 1,
  },

  btTwo: {
    bottom: 2,
  },

  btThree: {
    bottom: 3,
  },

  healthJornCalendar: {
    width: 14,
    height: 14,
  },

  plusBtnBlue: {
    width: 60,
    height: 60,
    borderRadius: 50,
    backgroundColor: theme.COLOR_BLUE_16,
    alignItems: 'center',
    justifyContent: 'center',
  },

  plusBtnDisabledBlue: {
    width: 60,
    height: 60,
    borderRadius: 50,
    backgroundColor: theme.COLOR_BLUE_10,
    alignItems: 'center',
    justifyContent: 'center',
  },

  plusBtnBlueIcn: {
    width: 23,
    height: 23,
  },

  sixteenRgt: {
    right: 16,
  },

  twentyRgt: {
    right: 20,
  },

  elipseIcnNwEvnt: {
    width: 10,
    height: 10,
  },

  addEvntBox: {
    borderWidth: 1,
    borderColor: theme.COLOR_GREY_3,
    backgroundColor: theme.COLOR_WHITE,
    borderRadius: 8,
  },

  addEvntTxtInput: {
    //lineHeight: 30,
    borderColor: theme.COLOR_WHITE,
    // maxHeight: 61,
  },

  NwEvtCalendarBox: {
    backgroundColor: theme.COLOR_WHITE,
    borderWidth: 1,
    borderColor: theme.COLOR_GREY,
    borderRadius: 8,
  },

  NwEvtCalendarBoxWd: {
    height: 31,
    width: 110,
  },
  customWidthCalender: {
    height: 31,
    minWidth: 110,
    maxWidth: '100%',
  },

  calendarIcnNwEvnt: {
    width: 16,
    height: 16,
  },

  calendarTxtNwEvnt: {
    color: theme.COLOR_GREY,
    fontWeight: '500',
  },

  wdTwelve: {
    width: 12,
  },

  hamburgerMenuNwEvnt: {
    width: 15,
    height: 11,
  },

  readMoreTxt: {
    color: theme.COLOR_BLUE_16,
    textDecorationLine: 'underline',
    textDecorationColor: theme.COLOR_BLUE_16,
  },

  attachmentIcnNwEvent: {
    width: 18,
    height: 19,
  },

  attachmentTxtNwEvnt: {
    fontWeight: '500',
    color: theme.COLOR_BLUE_16,
  },

  tagIcnNwEvnt: {
    width: 32,
    height: 32,
  },

  overflowHid: {
    overflow: 'hidden',
  },

  addEventSidePopUpWd: {
    ...variableShadow.selcetCatSidePopUpShadow,
    width: 198,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopWidth: 0,
    borderColor: theme.COLOR_GREY_MEDIUM,
    backgroundColor: theme.COLOR_WHITE,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,

    //height:'100%',
  },

  sidePopUpSltCtg: {
    height: 42,
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: theme.COLOR_BLUE_16,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 0,
    borderColor: theme.COLOR_GREY_MEDIUM,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    left: 0,
    paddingLeft: 15,
  },

  sidePopUpCtg: {
    height: 42,
    alignItems: 'flex-start',
    justifyContent: 'center',

    // borderWidth:1,
    // borderColor:theme.COLOR_GREY_MEDIUM,
    borderTopWidth: 1,
    borderTopColor: theme.COLOR_GREY_MEDIUM,
    paddingLeft: 15,
  },

  sidePopBrdBtmRad: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  blueDropDownArrowWd: {
    height: 15,
    width: 15,
  },

  blueDropDownArrowPs: {
    // bottom:4,
    left: 5,
  },

  postAb: {
    position: 'absolute',
  },

  ltTen: {
    left: 10,
  },

  tpFourteen: {
    top: 14,
  },

  tpThirty: {
    top: 30,
  },

  rgZero: {
    right: 0,
  },

  ltFive: {
    left: 5,
  },

  circleHealthJourneyCrd: {
    width: 12,
    height: 12,
    borderRadius: 50,
    backgroundColor: theme.COLOR_ASH_2,
  },

  lineHealthJourneyCrd: {
    borderLeftWidth: 2,
    borderLeftColor: theme.COLOR_ASH_2,
    minHeight: '5%',
    flex: 1,
  },

  tpSixteen: {
    top: 16,
  },

  healthJourneyCardWrapper: {
    backgroundColor: theme.COLOR_WHITE,
    borderWidth: 1,
    borderColor: theme.COLOR_GREY_3,
    borderRadius: 8,
   
  },

  healthJourneyTpTb: {
    borderBottomColor: theme.COLOR_GREY_3,
    borderBottomWidth: 1,
  },

  addFilterLine: {
    borderBottomColor: theme.COLOR_BLUE_16,
    borderBottomWidth: 1,
  },

  addTagAutoSugesstionBorderBlue: {
    borderColor: theme.COLOR_BLUE_16,
  },

  addTagAutoSugesstionBorderGrey: {
    borderColor: theme.COLOR_GREY_DARK_1,
  },

  menuTriggerIcnHlthJourney: {
    height: 4,
    width: 16,
  },

  menuTriggerIcnHlthJourneyLg: {
    height: 16,
    width: 16,
  },

  careGiverCircle: {
    width: 24,
    height: 24,
    borderRadius: 50,
    backgroundColor: theme.COLOR_BLUE_11,
  },

  hundredMaxWd: {
    maxWidth: '100%',
  },

  ninetySevenMxWd: {
    maxWidth: '97%',
  },

  startAndEndDateWrapper: {
    ...variableShadow.startDateEndDateShadow,
    width: 288,
    ...Platform.select({
      ios: {
        width: 270,
      },
    }),
    backgroundColor: theme.COLOR_WHITE,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.COLOR_GREY_3,
  },

  dateRequireClr: {
    color: theme.COLOR_RED,
  },

  tagGreyTxtColor: {
    color: theme.COLOR_GREY_4,
  },

  monthWdHgConainer: {
    width: 68,
    height: 22,
    ...Platform.select({
      android: {
        width: 115,
      },
    }),
  },

  monthWdHgConainerWeb: {
    ...Platform.select({
      web: {
        width: 95,
      },
    }),
  },

  dayWdHgConainer: {
    width: 52,
    height: 22,
    ...Platform.select({
      android: {
        width: 100,
      },
    }),
  },

  yearWdHgConainer: {
    width: 60,
    height: 22,
    ...Platform.select({
      android: {
        width: 110,
      },
    }),
  },

  mgLtAndWeb: {
    marginLeft: 25,
    ...Platform.select({
      android: {
        marginLeft: 20,
      },
    }),
  },

  //  dateSelectionContainer: {
  //   borderWidth:1,
  //   borderColor:theme.COLOR_GREY_3,
  //   borderRadius:4,
  //   backgroundColor:theme.COLOR_WHITE,
  //   paddingLeft:8,
  //   paddingRight:8,
  //   marginRight:5
  //  },

  mxWdFourty: {
    maxWidth: 40,
  },

  drpDwnArwBlck: {
    width: 6,
    height: 7,
    bottom: 1,
  },

  dateSelectionUnderline: {
    textDecorationLine: 'underline',
    textDecorationColor: theme.COLOR_BLUE_16,
  },

  applyBtn: {
    width: 50,
    height: 22,
    backgroundColor: theme.COLOR_BLUE_11,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },

  mxTwoEighty: {
    maxWidth: 280,
  },

  mxHudredPrec: {
    maxWidth: '100%',
  },
  postTwo: {
    position: 'relative',
    bottom: 1,
  },

  startDateEndPopZIndex: {
    maxHeight: 30,
    ...Platform.select({
      web: {
        zIndex: 9,
      },
      ios: {
        zIndex: 9,
      },
    }),
  },

  btThirtyOne: {
    bottom: 31,
  },

  newEventBtn: {
    width: 132,
    height: 42,
    borderRadius: 50,
  },

  newEventCancelBtn: {
    borderWidth: 2,
    borderColor: theme.COLOR_GREY_DARK_1,
    backgroundColor: theme.COLOR_WHITE,
  },

  newEventSveBtn: {
    backgroundColor: theme.COLOR_UNDERLINE_BLUE,
  },

  newEventSveBtnDisbaled: {
    backgroundColor: theme.COLOR_UNDERLINE_BLUE_DISABLED,
  },
  addEventButton: {
    position: 'absolute',
    width: 80,
    height: 95,
    bottom: 0,
    right: 15,
  },

  hgThirtyFive: {
    height: 35,
  },

  wdHundred: {
    width: 100,
  },

  // descriptonTextInputBox: {
  //   borderWidth: 1,
  //   borderColor: '#D9D9D9',
  //   backgroundColor: theme.COLOR_WHITE,
  //   borderRadius: 8,
  // },
  // heightTwoTwenty: {
  //   height: 220,
  // },
  mxHeightHundredPer: {
    maxHeight: '100%',
  },

  hgHundredPer: {
    height: '100%',
  },

  closeIcnGreyEvent: {
    width: 11,
    height: 11,
  },

  mxWdThreeHund: {
    maxWidth: 300,
  },

  btTwenty: {
    bottom: 20,
  },

  btTen: {
    bottom: 10,
  },

  btZero: {
    bottom: 0,
  },

  attachmentIcnGreyEvent: {
    width: 24,
    height: 28,
  },

  tagIcnDarkEvent: {
    width: 30,
    height: 30,
  },
  addEventTags: {
    flexBasis: 250,
    maxWidth: 250,
  },

  addAttachmentWrapper: {
    height: 38,
    borderWidth: 1,
    borderColor: theme.COLOR_BORDER,
    borderRadius: 5,
  },

  brdEditColorLuminous: {
    borderColor: theme.COLOR_BLUE_LUMINOUS,
  },

  oneSixtyWd: {
    width: 160,
  },

  tenGap: {
    width: 10,
  },

  btSix: {
    bottom: 6,
  },
  lefTen: {
    left: 10,
  },

  wdThirty: {
    width: 30,
  },

  bdRadEight: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  hgFifty: {
    height: 50,
  },

  tagsTxtEvent: {
    position: 'absolute',
    top: -6,
    left: 10,
    zIndex: 9,
  },

  logOutBtnText: {
    flex: 1,
    height: 40,
    backgroundColor: theme.COLOR_ASH_3,
    borderRadius: 8,
  },
  enterTags: {
    width: '93%',
    marginLeft: 20,
    marginRight: 20,
    padding: 0,
  },
  nomgLtZero: {
    marginLeft: 0,
  },
  tagScrollWrap: {
    flexDirection: 'row',
    flex: 1,
    position: 'relative',
  },
  editandAddModalSidePd: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  enterTagsPd: {
    paddingLeft: 0,
    justifyContent: 'center',
    margin: 0,
  },
  borderWidth: {
    borderWidth: 0,
  },

  attachmentWrapperHg: {
    height: 45,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  infoDarkIcon: {
    width: 20,
    height: 20,
  },

  wdSix: {
    width: 6,
  },

  circleDotEvent: {
    width: 9,
    height: 9,
    borderRadius: 50,
    backgroundColor: '#FF8300',
  },

  medsEventWrapper: {
    minWidth: 20,
    maxWidth: 300,
    height: 32,
    borderWidth: 2,
    borderColor: theme.COLOR_GREY_LIGHT_EX_16,
    backgroundColor: theme.COLOR_WHITE, //theme.COLOR_WHITE_BLUE_LIGHT,
    borderRadius: 13,
    ...Platform.select({
      web: {
        maxWidth: 240,
      },
    }),
  },

  medEventWdFull: {
    width: '93%',
  },

  medsMxWd: {
    maxWidth: 155,
  },

  medsPopupWrapper: {
    minHeight: 50,
    backgroundColor: theme.COLOR_WHITE,
    borderWidth: 1,
    borderColor: theme.COLOR_BORDER,
    borderRadius: 8,
    top: 32,
    position: 'absolute',
  },

  wdSeventy: {
    width: 70,
  },

  wdEightyFive: {
    width: 85,
  },

  wdNinetyThree: {
    width: 93,
  },

  medsDivideSec: {
    borderTopWidth: 1,
    borderTopColor: theme.COLOR_BORDER,
    width: '100%',
  },

  wdHalfPerc: {
    width: '50%',
  },

  wdFullPer: {
    width: '100%',
  },

  medsRemoveClr: {
    color: theme.COLOR_BLOOD_RED_LIGHT,
  },

  bodsButtonDivVerticle: {
    borderLeftWidth: 1,
    borderLeftColor: theme.COLOR_BORDER,
  },

  fourtyHg: {
    height: 40,
  },

  fourtyFourHg: {
    height: 44,
  },

  wdNinetyFivePer: {
    width: '95%',
  },

  yearCollapsHg: {
    height: 30,
    backgroundColor: theme.COLOR_WHITE,
  },
  pdRtEight: {
    paddingRight: 8,
  },
  evntDescriptionTxt: {
    position: 'absolute',
    opacity: 0,
    zIndex: -99,
  },

  txtAlignJustify: {
    textAlign: 'justify',
  },

  evntDescriptionReadMoreTxt: {
    position: 'absolute',
    right: 0,
    backgroundColor: 'white',
    paddingRight: 20,
  },

  dateFilterWrapper: {
    minHeight: 10,
    backgroundColor: theme.COLOR_WHITE,
    borderWidth: 1,
    borderColor: theme.COLOR_GREY_3,
    paddingBottom: 20,
  },

  dateFilterLine: {
    borderBottomWidth: 1,
    borderBottomColor: '#E3E5E5',
    width: '100%',
  },

  dateFilterLineWithMargin: {
    borderBottomWidth: 1,
    borderBottomColor: '#E3E5E5',
    width: '100%',
    marginTop: 10,
  },

  btnWdRemandApply: {
    width: 92,
    height: 32,
    borderRadius: 8,
  },

  removeButton: {
    borderWidth: 2,
    borderColor: theme.COLOR_GREY_DARK,
    justifyContent: 'center',
    marginRight: 4,
    alignItems: 'center',
  },

  removeButtonDisabled: {
    borderWidth: 2,
    borderColor: theme.COLOR_GREY_LIGHT,
    justifyContent: 'center',
    marginRight: 4,
    alignItems: 'center',
  },

  applyButton: {
    backgroundColor: theme.COLOR_BLUE_16,
    alignItems: 'center',
    marginLeft: 4,
    justifyContent: 'center',
  },

  applyButtonDisabled: {
    backgroundColor: theme.COLOR_UNDERLINE_BLUE_DISABLED,
    alignItems: 'center',
    marginLeft: 4,
    justifyContent: 'center',
  },

  wdNintyFive: {
    width: 95,
  },

  wdOneHundSix: {
    width: 106,
  },

  wdOneHundTwelve: {
    width: 112,
  },

  monthWdCustom: {
    height: 30,
    borderWidth: 1,
    borderColor: theme.COLOR_LIGHT_GREY_WHITE,
    borderRadius: 4,
  },

  monthWdCustomMobile: {
    height: 35,
    borderWidth: 1,
    borderColor: theme.COLOR_LIGHT_GREY_WHITE,
    borderRadius: 4,
  },

  psArrowCustom: {
    position: 'absolute',
    right: 5,
    top: 12,
  },

  customArrowWdHg: {
    width: 10,
    height: 6,
  },

  toCustomWd: {
    width: 40,
    alignItems: 'flex-end',
  },

  selectItemCustomDatePicker: {
    height: 140,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderColor: theme.COLOR_LIGHT_GREY_WHITE,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    position: 'absolute',
    top: 25,
    backgroundColor: theme.COLOR_WHITE,
  },

  advanceFilterPos: {
    left: 5,
  },

  whOneThirtyTwo: {
    width: 132,
  },

  wdTwoHundred: {
    width: 200,
  },

  tgGreySmIcn: {
    height: 12,
    width: 12,
  },

  fullHgHundredPerc: {
    height: '100%',
  },

  minHgThirty: {
    minHeight: 30,
  },

  filterBrdBtm: {
    borderBottomWidth: 1,
    borderBottomColor: theme.COLOR_LIGHT_GREY_WHITE,
  },

  wdHundredSixteen: {
    width: 116,
  },

  wdTwoFiftySeven: {
    width: 257,
  },

  wdTwoFifty: {
    width: 250,
  },

  minHgFifty: {
    minHeight: 50,
  },

  mxHgTwoFifty: {
    maxHeight: 250,
  },

  tpOne: {
    top: 1,
  },

  tpTwo: {
    top: 2,
  },

  tpThree: {
    top: 3,
  },

  searchIcnSmallGrey: {
    width: 12,
    height: 12,
  },

  brdLgWdThree: {
    borderBottomWidth: 3,
    borderBottomColor: theme.COLOR_GREY_LIGHT_EX_4,
  },

  mxHgHudredSixtyThree: {
    maxHeight: 163,
  },

  mxHgOneFiftyFive: {
    maxHeight: 155,
  },

  pstAbsLtFive: {
    position: 'absolute',
    left: 5,
  },

  pstAbTpThirtyRgtZero: {
    position: 'absolute',
    top: 30,
    // right:0,
  },

  pstAbTpZeroRgtZero: {
    position: 'absolute',
  },

  lightSelectedAmntTxtClr: {
    color: theme.COLOR_GREY_3,
  },

  rghtArrowSm: {
    width: 5,
    height: 8,
  },

  selectCategoriesPopUpWrapper: {
    ...variableShadow.selcetCatSidePopUpShadow,
    width: 250,
    minHeight: 200,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingBottom: 30,
  },

  pickerTpBtBorder: {
    borderColor: theme.COLOR_ASH_3,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    ...Platform.select({
      android: {
        borderTopWidth: 0,
      },
    }),
  },

  touchableAndWd: {
    ...Platform.select({
      android: {
        width: 80,
      },
    }),
  },

  touchableAndWdMax: {
    ...Platform.select({
      android: {
        width: 180,
      },
    }),
  },


  seperateLineEachCatSec: {
    borderColor: theme.COLOR_ASH_3,
    borderBottomWidth: 1,
  },

  pstAb: {
    position: 'absolute',
  },

  lightHighlightedBleBg: {
    backgroundColor: theme.COLOR_WHITE_BLUE_LIGHT,
  },

  ltSeventeen: {
    left: 17,
  },

  tpZero: {
    top: 0,
  },

  rtThirtyNine: {
    right: 39,
  },

  hgFive: {
    height: 5,
  },

  selectedBorderClr: {
    borderColor: theme.COLOR_BLUE_15,
  },

  zIndexTopPos: {
    zIndex: 999999,
  },

  noDis: {
    display: 'none',
  },
  removemgPd: {
    margin: 0,
    padding: 0,
  },

  smCloseIconViewHealthJourney: {
    position: 'absolute',
    // right: 10,
    top: 10,
  },
  tagScrollWrapHealthJourney: {
    flexDirection: 'row',
    flex: 1,
    // position: "relative",
  },
  minHeightFour: {
    minHeight: 4,
  },

  zIndexNine: {
    zIndex: 9,
  },

  zIndexOne: {
    zIndex: 1,
  },

  zIndexTwo: {
    zIndex: 2,
  },

  wdFourtyTwo: {
    width: 42,
  },

  wdHundredPercent: {
    width: '100%',
  },

  wdHundredFifty: {
    width: 150,
  },

  brdGreyDarkWdOne: {
    borderColor: theme.COLOR_GREY_DARK,
    borderWidth: 1,
  },

  brdLgGreyDarkWdOne: {
    borderColor: theme.COLOR_GREY_LIGHT,
    borderWidth: 1,
  },

  mxWdThreeFiftySix: {
    maxWidth: 356,
  },

  hgFiftyFive: {
    height: 55,
  },

  minHgFourty: {
    minHeight: 40,
  },
  // minHgFifty:{
  //   minHeight: 50
  // },

  wdTwoHudredSixty: {
    width: 260,
  },

  mnHeightThirty: {
    minHeight: 30,
  },

  tagLtAlign: {
    alignItems: 'flex-end',
    paddingRight: 20,
  },

  flxStartAlng: {
    alignItems: 'flex-start',
  },

  tgsPstRtBtZero: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    // zIndex: 9
  },

  tpFifteenJsFS: {
    top: 15,
    justifyContent: 'flex-start',
  },

  wdHudred: {
    width: 100,
  },

  wdOneEighty: {
    width: 180,
  },
  wdTwenty: {
    width: 20,
  },

  wdEditAttachContentRes: {
    width: 250,
    ...Platform.select({
      android: {
        width: 260,
      },
    }),
  },

  brdBtnWdThree: {
    borderBottomWidth: 3,
    borderBottomColor: theme.COLOR_GREY_LIGHT_EX_22,
  },

  mxWdOneSixty: {
    maxWidth: 160,
  },

  wdThirtyFive: {
    width: 35,
  },

  tagsBrdRadSpecStyle: {
    marginBottom: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    // flexDirection:'row',
    // flexWrap:'wrap'
  },

  toAlignLeft: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  checkboxContainer: {
    flex: 2,
    flexDirection: 'row',
    alignItems: 'center',
    //height: 20,
    //paddingRight: 10,
  },

  checkboxContainerMobile: {
    flex: 2,
    flexDirection: 'row',
    height: 40,
    paddingRight: 10,
    alignItems: 'center',
  },

  checkbox: {
    height: 20,
    width: 20,
    borderRadius: 10,
    borderColor: '#C4C4C4',
  },

  checkboxMobile: {
    height: 20,
    width: 20,
    alignSelf: 'center',
    borderRadius: 5,
    borderColor: '#C4C4C4',
  },

  textPadding: {
    paddingLeft: 10,
    ...Platform.select({
      web: {
        width: 140,
      },
      ios: {
        //width: '85%',
        width: 140,
      },
      android: {
        //width: '85%',
        width: 140,
      },
    }),
  },

  textPaddingLg: {
    paddingLeft: 10,
    ...Platform.select({
      web: {
        width: 200,
      },
      ios: {
        width: '95%',
      },
      android: {
        width: '95%',
      },
    }),
  },

  textPaddingSubCategory: {
    paddingLeft: 10,
    width: '100%',
  },

  textCompStyling: {
    width: 30,
    textAlign: 'right',
    fontWeight: 'bold',
  },

  closeIcnGrey: {
    width: 16,
    height: 16,
  },

  colapseViewMaxHeight: {
    maxHeight: 220,
  },

  colapseViewMobileMaxHeight: {
    maxHeight: 130,
  },

  scrollPadding: {
    paddingRight: 8,
  },

  bottomMargin: {
    marginBottom: -5,
  },

  ltOneThirty: {
    left: 130,
  },

  wdOneSixty: {
    width: 160,
  },

  itemsWdMax: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  fntDark: {
    color: '#404041',
    top: 1,
  },

  pdTpHundred: {
    paddingTop: 100,
  },

  noResultFoundIng: {
    width: 170,
    height: 145,
  },

  noResultFoundIngNoFilters: {
    width: 180,
    height: 165,
  },

  wdSixty: {
    width: 60,
  },

  hdgThirtySix: {
    height: 36,
  },

  wdEightyPerc: {
    width: '80%',
  },

  wdTenPerc: {
    width: '10%',
  },

  wdNinetyPerc: {
    width: '90%',
  },

  wdHundredPer: {
    width: '100%',
  },

  pdLtZerpfRtEight: {
    paddingRight: 0,
    paddingLeft: 8,
  },

  pdLtEight: {
    paddingLeft: 8,
  },
  selectCategoryText: {
    fontSize: 16,
    color: '#404041',
    fontFamily: 'Asap',
  },
  moreText: {
    color: theme.COLOR_GREY_DARK_1,
    fontWeight: theme.FONT_WEIGHT_REGULAR,
    fontSize: theme.FONT_SIZE_RG,
  },
  tagbox: {
    borderColor: theme.COLOR_BLUE_16,
    borderRadius: 22,
    paddingBottom: 5,
    paddingTop: 5,
    marginBottom: 5,
    marginTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 20,
    maxWidth: '100%',
  },
  tagsContainer: {
    borderRadius: 8,
    paddingRight: 10,
  },
  dropDownAuto: {
    borderColor: theme.COLOR_GRAY_LIGHT_EX_23,
    borderWidth: 1,
    backgroundColor: theme.COLOR_BLUE_12,
    padding: 10,
  },
  scrollContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  scrollViewContainer: {
    width: '100%',
  },
  customScrollBar: {
    backgroundColor: '#ccc',
    borderRadius: 3,
    width: 6,
  },
  customScrollBarBackground: {
    backgroundColor: '#232323',
    borderRadius: 3,
    height: '100%',
    width: 6,
  },
  closeIconNew: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  tickIcon: {
    marginRight: 8,
  },
  hitView: {
    borderColor: theme.COLOR_GRAY_LIGHT_EX_23,
    borderWidth: 1,
  },
  clearAllView:{
    flex:1,
    flexDirection:"row",
    justifyContent:"flex-end"
  },
  clearCloseIcn: {
    width: 15,
    height: 16,
    marginRight:5
  },
  clearFilterDisabled:{
    opacity:0.4
  },
  tagWrapperStyles: {
    borderWidth: 1, 
    paddingLeft: 5, 
    paddingBottom: 10
  },
  tagBubbleStyles: {
    borderColor: theme.COLOR_BLUE_16, 
    borderWidth: 1, 
    marginLeft: 5, 
    marginRight: 10, 
    marginTop: 10, 
    paddingTop: 5, 
    paddingBottom: 5, 
    minHeight: 30
  },
  tagInputStyles: {
    marginTop: 5, 
    minHeight: 40, 
    height: 40
  }
};
