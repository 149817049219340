import React, {Component} from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import UIText from '../../components/Text/text';
import UITextInput from '../../components/TextInput/textInput';
import Header from '../../components/Header/header';
import RadioForm from '../../modules/radioButton/RadioButton';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import ReportIssue from '../../components/Report/ReportIssue';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import moment from 'moment';
import {
  getAppointmentsColourCode,
  getTherapiesColourCode,
} from '../../util/commonUiLogic';
import Picker from '../../components/Picker/picker';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import {REPORTS} from '../../navigation/path';
import PageContainer from "../../components/PageLayout/PageContainer";
import { pendoTrackEvent } from '../../util/pendoConfig';
const radioProps = [{label: 'All', value: 0}, {label: 'UnAnswered', value: 1}];

class ReportAddIssueScreen extends Component {
  constructor(props) {
    super(props);
    const questions = this.getQuestions();
    this.state = {
      questions: questions,
      issueDestination: '1',
      filter: 0,
      searchKeyWord: '',
      filterQuestions: this.filterQuestions(questions, '1', 0, ''),
      selectedQuestions: [],
      isIssueSelected:
        Object.values(props.selectedIssuesObj).length > 0 ? true : false,
    };
  }

  componentDidMount () {
    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
      pendoTrackEvent('Reports/@AddTo-Do');
    });
    this.props.navigation.addListener('blur', () => {
      this.props.showMobileMenu();
    });
  }
  getQuestions = () => {
    const journalQuestions = [];
    const contactQuestions = [];
    const treatmentQuestions = [];

    if (
      this.props.questionDigest &&
      this.props.questionDigest.userArtifactHealthQuestions
    ) {
      this.props.questionDigest.userArtifactHealthQuestions.forEach(element => {
        const journal = this.props.journalArtifactMap[element.artifactId] ? this.props.journalEntriesProcessed[this.props.journalArtifactMap[element.artifactId]] : null;
        if (
          journal &&
          this.props.journalEntryTypesProcessed[journal.entryType]
        ) {
          element.title = journal.title;
          if (
            this.props.journalEntryTypesProcessed[journal.entryType]
              .isAppointment
          ) {
            element.color = getAppointmentsColourCode(journal.appointmentType);
          } else {
            element.color = this.props.journalEntryTypesProcessed[
              journal.entryType
            ].color;
          }
          journalQuestions.push(element);
        }

        if (this.props.contactsArtifactMap[element.artifactId]) {
          element.title = this.props.contactsArtifactMap[element.artifactId]
            .givenName
            ? this.props.contactsArtifactMap[element.artifactId].givenName
            : this.props.contactsArtifactMap[element.artifactId].organization;

          contactQuestions.push(element);
        }

        if (this.props.treatmentsArtifactMap[element.artifactId]) {
          const treatment = this.props.treatmentsArtifactMap[
            element.artifactId
          ];
          element.title = treatment.name;

          element.color = getTherapiesColourCode(treatment.treatmentType);
          treatmentQuestions.push(element);
        }
      });
    }

    return {
      journalQuestions: journalQuestions,
      contactQuestions: contactQuestions,
      treatmentQuestions: treatmentQuestions,
    };
  };

  filterQuestions = (allQuestions, issueDestination, filter, searchKeyWord) => {
    let questions;
    let filterQuestions = [];
    switch (issueDestination) {
      case '1':
        questions = allQuestions.journalQuestions;
        break;
      case '2':
        questions = allQuestions.contactQuestions;
        break;
      default:
        questions = allQuestions.treatmentQuestions;
        break;
    }

    let startDate;
    if (this.props.startDate) {
      startDate = new Date(this.props.startDate);
      startDate.setHours(0, 0, 0, 0);
    }

    let endDate;

    if (this.props.endDate) {
      endDate = new Date(this.props.endDate);
      endDate.setHours(23, 59, 59, 59);
    }

    if (questions) {
      questions.forEach(element => {
        const question = this.props.questionDigestProcessed.questions[
          element.questionId
        ];
        if (question) {
          question.title = element.title;
          question.color = element.color;
          question.parentArtifactId = element.artifactId;
          filterQuestions.push(question);
        }
      });
    }

    if (filter === 1) {
      filterQuestions = filterQuestions.filter(res => !res.closed);
    }

    if (searchKeyWord) {
      filterQuestions = filterQuestions.filter(
        res =>
          res.content.toLowerCase().includes(searchKeyWord.toLowerCase()) ||
          (res.title &&
            res.title.toLowerCase().includes(searchKeyWord.toLowerCase())),
      );
    }

    if (startDate && endDate) {
      filterQuestions = filterQuestions.filter(
        item =>
          item.modifiedOn > startDate.getTime() &&
          item.modifiedOn < endDate.getTime(),
      );
    }

    return filterQuestions;
  };

  changeIssueDestination = val => {
    this.setState({
      issueDestination: val,
      filterQuestions: this.filterQuestions(
        this.state.questions,
        val,
        this.state.filter,
        this.state.searchKeyWord,
      ),
    });
  };

  handleOnPressRadioForm = val => {
    this.setState({
      filter: val,
      filterQuestions: this.filterQuestions(
        this.state.questions,
        this.state.issueDestination,
        val,
        this.state.searchKeyWord,
      ),
    });
  };

  onSearchByKeyWord = val => {
    this.setState({
      searchKeyWord: val,
      filterQuestions: this.filterQuestions(
        this.state.questions,
        this.state.issueDestination,
        this.state.filter,
        val,
      ),
    });
  };

  onSave = () => {
    this.props.saveReportIssue();
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: REPORTS.newReportPath }],
    });
  };

  cancelReport = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: REPORTS.newReportPath }],
    });
  };

  setIssueSelected = val => {
    this.setState({isIssueSelected: val});
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => {
            this.props.navigation.goBack();
          }}
          index={REPORTS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        <KeyboardAwareScrollViewHoc>
          <View style={[styles.gridSection]}>
           <PageContainer>
            <View style={stylesRes.layoutPdFixed}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.secArea}>
                        <View style={[styles.formInnerArea]}>
                          <View style={[styles.fieldRow]}>
                            <View style={[styles.pickerSelectionWrapper]}>
                              <Text
                                style={[
                                  styles.textView,
                                  styles.textViewPicker,
                                ]} allowFontScaling={false}>
                                <UIText
                                  style={[
                                    styles.textLabel,
                                    styles.bold,
                                    styles.textColorLight,
                                  ]}
                                  title="Issue Location"
                                />
                              </Text>
                              <View
                                style={[
                                  stylesRes.dropArrowView,
                                  stylesRes.dropArrowTopLg,
                                ]}
                                pointerEvents={'none'}>
                                <View style={stylesRes.dropArrowIcon}>
                                  <Image
                                    style={[styles.imgContain]}
                                    source={require('../../assets/icons/dropdown-arrow.png')}
                                  />
                                </View>
                              </View>
                              {/* Select styles.inValidColor for invalid option*/}
                              <Picker
                                items={[
                                  {
                                    label: 'Entries',
                                    value: '1',
                                    key: 1,
                                  },
                                  {label: 'Contacts', value: '2', key: 2},
                                  {label: 'Treatment', value: '3', key: 3},
                                ]}
                                style={[
                                  styles.pickerAreaField,
                                  styles.selectionPicker,
                                ]}
                                selectedValue={this.state.issueDestination}
                                onValueChange={this.changeIssueDestination}
                              />
                            </View>
                          </View>
                          <View style={[styles.fieldRow]}>
                            <UITextInput
                              style={[
                                styles.textField,
                                styles.textFieldWhite,
                                styles.textFieldSm,
                              ]}
                              onChangeText={this.onSearchByKeyWord}
                              placeholder="Search entries"
                              value={this.state.searchKeyWord}
                            />
                          </View>
                          <View style={[styles.fieldRow, styles.fieldRowNoMg]}>
                            <View
                              style={[styles.radioSecFul, styles.comMgTopSm]}>
                              <View style={stylesRes.radioSetArea}>
                                <View style={stylesRes.radioSetText}>
                                  <Text style={styles.textPrimeBold} allowFontScaling={false}>
                                    Filter:
                                  </Text>
                                </View>
                                <View>
                                  <RadioForm
                                    style={[
                                      styles.radioSetCom,
                                      styles.radioSetDouble,
                                    ]}
                                    radio_props={radioProps}
                                    formHorizontal={true}
                                    buttonColor={'rgba(152, 152, 154, 0.6)'}
                                    selectedButtonColor={'#FF8300'}
                                    initial={this.state.filter}
                                    onPress={this.handleOnPressRadioForm}
                                  />
                                </View>
                              </View>
                            </View>
                          </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </PageContainer>
            <PageContainer smScreenFull={true}>
              <View style={stylesRes.contBotPd} dataSet={{media: ids.contBotPd}}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.secArea]}>
                      <View
                        style={[
                          this.state.filterQuestions.length > 0
                            ? styles.listItemsReportAllAdded
                            : styles.listItemsNone,
                        ]}>
                        {this.state.filterQuestions.map((res, index) => (
                          <ReportIssue
                            id={res.id}
                            key={this.state.issueDestination + 'ri_' + index}
                            title={res.title}
                            content={res.content}
                            question={res}
                            parentArtifactId={res.parentArtifactId}
                            date={moment(res.modifiedOn, 'x').format(
                              "D MMM 'YY",
                            )}
                            color={res.color}
                            setIssueSelected={this.setIssueSelected}
                          />
                        ))}
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.btnArea, styles.btnMgSm]}>
                      <View style={stylesRes.btnViewSec}>
                        <View style={styles.fieldBtn}>
                          <View style={styles.btnBlockFull}>
                            <TouchableOpacity
                              style={[styles.submitSm, styles.disableBtn]}
                              underlayColor="rgba(0, 153, 168, 0.8)"
                              onPress={this.cancelReport}>
                              <View style={styles.btnSingleView}>
                                <View
                                  style={[
                                    styles.btnSingle,
                                    styles.btnSingleCent,
                                  ]}>
                                  <UIText
                                    style={styles.submitText}
                                    title="Cancel"
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View style={styles.fieldBtnSp} />
                        <View style={styles.fieldBtn}>
                          <View style={styles.btnBlockFull}>
                            {this.state.isIssueSelected ? (
                              <TouchableOpacity
                                onPress={this.onSave}
                                style={[styles.submitSm]}>
                                <View style={styles.btnSingleView}>
                                  <View
                                    style={[
                                      styles.btnSingle,
                                      styles.btnSingleCent,
                                    ]}>
                                    <UIText
                                      style={styles.submitText}
                                      title="Save"
                                    />
                                  </View>
                                </View>
                              </TouchableOpacity>
                            ) : (
                              <TouchableOpacity
                                style={[styles.submitSm, styles.disableBtn]}
                                underlayColor="rgba(0, 153, 168, 0.8)">
                                <View style={styles.btnSingleView}>
                                  <View
                                    style={[
                                      styles.btnSingle,
                                      styles.btnSingleCent,
                                    ]}>
                                    <UIText
                                      style={styles.submitText}
                                      title="Select"
                                    />
                                  </View>
                                </View>
                              </TouchableOpacity>
                            )}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </PageContainer>
          </View>
        </KeyboardAwareScrollViewHoc>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    questionDigest: state.routines.questionDigest,
    questionDigestProcessed: state.routines.questionDigestProcessed,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    journalArtifactMap: state.routines.journalArtifactMap,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    treatmentsArtifactMap: state.routines.treatmentsArtifactMap,
    contactsArtifactMap: state.routines.contactsArtifactMap,
    startDate: state.report.startDate,
    endDate: state.report.endDate,
    selectedIssuesObj: state.report.selectedIssuesObj,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    saveReportIssue: () => dispatch(actions.saveReportIssue()),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportAddIssueScreen);
