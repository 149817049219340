import React, { Component } from "react";
import {
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
} from "react-native";
import {Picker} from '@react-native-picker/picker';
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import UIText from "../Text/text";
import UITextInput from "../TextInput/textInput";
import ActivityIndicator from "../ActivityIndicator/activityIndicator";
import { injectStripe, CardElement } from "react-stripe-elements";
import OrderSummery from "./orderSummery";
import * as axiosCommon from "../../axios/axios-common";
import { SUBSCRIPTION } from "../../store/actions/urls";
import OrderCompleteSubscription from "../ConfirmationBox/orderCompleteSubscription";
import { MONTH, YEAR } from "../../Constant";
import PageContainer from "../../components/PageLayout/PageContainer";
import {pendoTrackEvent} from '../../util/pendoConfig';
class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlane: MONTH,
      fullName: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      addressLine1: "",
      addressLine2: "",
      amount: this.getAmount(MONTH),
      total: this.getTotal(MONTH),
      wait: false,
      visibleModal: false,

      validateFullName: true,
      validateCity: true,
      validateState: true,
      validateCountry: true,
      validatePostalCode: true,
      validateAddressLine1: true,
      //validateAddressLine2: true
    };
  }

  getAmount = (paln) => {
    return this.props.subscriptionPlans[paln].amount / 100;
  };

  getTotal = (paln) => {
    return this.props.subscriptionPlans[paln].amount / 100;
  };

  selectPlan = (paln) => {
    this.setState({
      selectedPlane: paln,
      amount: this.getAmount(paln),
      total: this.getTotal(paln),
    });
  };

  copyFromProfile = () => {
    this.setState({
      fullName: this.props.user?.firstName + " " + this.props.user?.lastName,
      city: this.props.user?.city ? this.props.user.city : "",
      state: this.props.user?.state ? this.props.user.state : "",
      country: this.props.user?.country ? this.props.user.country : "",
    });
  };
  validateProfileInfo = () => {
    let valid = true;
    if (
      (this.state.fullName && this.state.fullName.trim() === "") ||
      !this.state.fullName
    ) {
      this.setState({ validateFullName: false });
      valid = false;
      if (this.props.handleScrolling) {
        this.props.handleScrolling(true);
      }
    } else {
      this.setState({ validateFullName: true });
    }
    if (
      (this.state.city && this.state.city.trim() === "") ||
      !this.state.city
    ) {
      this.setState({ validateCity: false });
      valid = false;
      if (this.props.handleScrolling) {
        this.props.handleScrolling();
      }
    } else {
      this.setState({ validateCity: true });
    }
    if (
      (this.state.country === "Canada" ||
        this.state.country === "United States of America (USA)") &&
      !this.state.state
    ) {
      this.setState({ validateState: false });
      valid = false;
    } else {
      this.setState({ validateState: true });
    }
    if (!this.state.country) {
      this.setState({ validateCountry: false });
      valid = false;
      if (this.props.handleScrolling) {
        this.props.handleScrolling();
      }
    } else {
      this.setState({ validateCountry: true });
    }
    if (
      (this.state.postalCode && this.state.postalCode.trim() === "") ||
      !this.state.postalCode
    ) {
      this.setState({ validatePostalCode: false });
      valid = false;
      if (this.props.handleScrolling) {
        this.props.handleScrolling();
      }
    } else {
      this.setState({ validatePostalCode: true });
    }
    if (
      (this.state.addressLine1 && this.state.addressLine1.trim() === "") ||
      !this.state.addressLine1
    ) {
      this.setState({ validateAddressLine1: false });
      valid = false;
      if (this.props.handleScrolling) {
        this.props.handleScrolling(true);
      }
    } else {
      this.setState({ validateAddressLine1: true });
    }
    // if (!this.state.addressLine2) {
    //   this.setState({ validateAddressLine2: false });
    //   valid = false;
    // } else {
    //   this.setState({ validateAddressLine2: true });
    // }
    return valid;
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    if (this.props.stripe) {
      if (this.validateProfileInfo()) {
        this.setState({ wait: true });
        this.props.stripe.createToken().then((res) => {
          if (res.token) {
            axiosCommon.axiosPOST(
              SUBSCRIPTION,
              (data) => {
                this.setState({ wait: false });
                if (data.type === "SUCCESS") {
                  this.setState({
                    visibleModal: true,
                    orderNumber: data.responseDto.data.orderNumber,
                  });
                } else if (
                  data.type === "ERROR" &&
                  data.errorDto === "SESSION_TIME_OUT"
                ) {
                  this.props.logout();
                } else {
                  this.props.commonErrorHandler(data.errorDto.data.error);
                  this.setState({ wait: false });
                }
              },
              {
                tocken: res.token.id,
                fullName: this.state.fullName,
                city: this.state.city,
                state: this.state.city,
                country: this.state.country,
                addressLine1: this.state.addressLine1,
                addressLine2: this.state.addressLine2,
                type: this.state.selectedPlane === YEAR ? "YEAR" : "MONTH",
                planId: this.props.subscriptionPlans[this.state.selectedPlane]
                  .id,
                paymentType: "PERSONAL",
              }
            );
          } else if (res.error) {
            this.props.commonErrorHandler(res.error.message);
            this.setState({ wait: false });
          }
        });
      }
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
    pendoTrackEvent('Billing@ > Subscribe');
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <React.Fragment>
        <PageContainer smScreenFull={true}>
          <View style={[styles.gridRow, styles.postRelative]}>
            <View style={styles.gridColFull}>
              <View style={styles.secArea}>
                <View style={[styles.viewBannerSec, styles.postRelative]}>
                  <View
                    style={[
                      styles.premBannerBg,
                      styles.bgPremHeight,
                      styles.bgPlanHolderHeight,
                    ]}
                  >
                    <Image
                      style={[styles.premBg]}
                      source={require("../../assets/premium/prem-bg.png")}
                    />
                  </View>
                  <View
                    style={[
                      styles.premBannerContent,
                      styles.bgPremHeight,
                      // styles.flexRowJcCtAiCt
                    ]}
                  >
                    <View style={[styles.premTitleBlock]}>
                      <Text style={[styles.textPrime, styles.textAlignCenter]} allowFontScaling= {false}>
                        Choose a plan
                      </Text>
                    </View>

                    <View style={[styles.premTextArea]}>
                      <View style={[stylesRes.premBoxFullView]}>
                        <View style={[stylesRes.premBoxView]}>
                          <View style={[stylesRes.premBoxSingle]}>
                            <TouchableOpacity
                              style={[styles.maxView]}
                              onPress={() => this.selectPlan(MONTH)}
                            >
                              {/* //Add styles.premSelected for selection */}
                              <View
                                style={
                                  this.state.selectedPlane === MONTH
                                    ? [
                                        styles.premBoxData,
                                        styles.premShadow,
                                        styles.premSelected,
                                      ]
                                    : [styles.premBoxData, styles.premShadow]
                                }
                              >
                                <View style={[styles.preDataNo]}>
                                  <View style={[styles.preSignView]}>
                                    <View style={[styles.preDataSign]}>
                                      {/* if selected add textDark else add textLight*/}
                                      <Text
                                        style={
                                          this.state.selectedPlane === MONTH
                                            ? [
                                                styles.textNoExSm,
                                                styles.textDark,
                                              ]
                                            : [
                                                styles.textNoExSm,
                                                styles.textLight,
                                              ]
                                        }
                                        allowFontScaling= {false}
                                      >
                                        $
                                      </Text>
                                    </View>
                                  </View>
                                  <View style={[styles.preDataNoLg]}>
                                    {/* if selected add textDark else add textLight*/}
                                    <Text
                                      style={
                                        this.state.selectedPlane === MONTH
                                          ? [
                                              styles.textNoLg,
                                              styles.textAlignCenter,
                                            ]
                                          : [
                                              styles.textNoLg,
                                              styles.textAlignCenter,
                                              styles.textLight,
                                            ]
                                      }
                                      allowFontScaling= {false}
                                    >
                                      {this.props.subscriptionPlans[MONTH]
                                        .amount / 100}
                                    </Text>
                                  </View>
                                </View>
                                <View
                                  style={[
                                    styles.preDataLine,
                                    styles.borderThin,
                                  ]}
                                />
                                <View style={[styles.preDataText]}>
                                  <Text
                                    style={[
                                      styles.textPrimeSm,
                                      styles.textAlignCenter,
                                    ]}
                                  >
                                    Monthly subscription
                                  </Text>
                                  <Text
                                    style={[
                                      styles.textPrimeSm,
                                      styles.textAlignCenter,
                                    ]}
                                    allowFontScaling= {false}
                                  >
                                    ($
                                    {(this.props.subscriptionPlans[MONTH]
                                      .amount /
                                      100) *
                                      12}{" "}
                                    annually)
                                  </Text>
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View style={stylesRes.premBoxOrViewWrapper}>
                          <View style={stylesRes.premBoxOrView}>
                            <View style={[styles.orIconSec]}>
                              <Image
                                style={[styles.orIcon]}
                                source={require("../../assets/icons/or-bg.png")}
                              />
                            </View>

                            <View style={[styles.premOrText]}>
                              <Text
                                style={[
                                  styles.textPrime,
                                  styles.textWhite,
                                  styles.textAlignCenter,
                                  styles.textEm,
                                ]}
                                allowFontScaling= {false}
                              >
                                or
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View style={stylesRes.premBoxView}>
                          <View style={stylesRes.premBoxSingle}>
                            <TouchableOpacity
                              style={[styles.maxView]}
                              onPress={() => this.selectPlan(YEAR)}
                            >
                              {/* //Add styles.premSelected for selection */}
                              <View
                                style={
                                  this.state.selectedPlane === YEAR
                                    ? [
                                        styles.premBoxData,
                                        styles.premShadow,
                                        styles.premSelected,
                                      ]
                                    : [styles.premBoxData, styles.premShadow]
                                }
                              >
                                <View style={[styles.preDataNo]}>
                                  <View style={[styles.preSignView]}>
                                    <View style={[styles.preDataSign]}>
                                      <Text
                                        style={
                                          this.state.selectedPlane === YEAR
                                            ? [
                                                styles.textNoExSm,
                                                styles.textDark,
                                              ]
                                            : [
                                                styles.textNoExSm,
                                                styles.textLight,
                                              ]
                                        }
                                        allowFontScaling= {false}
                                      >
                                        $
                                      </Text>
                                    </View>
                                  </View>

                                  <View style={[styles.preDataNoLg]}>
                                    <Text
                                      style={
                                        this.state.selectedPlane === YEAR
                                          ? [
                                              styles.textNoLg,
                                              styles.textAlignCenter,
                                              styles.textDark,
                                            ]
                                          : [
                                              styles.textNoLg,
                                              styles.textAlignCenter,
                                              styles.textLight,
                                            ]
                                      }
                                      allowFontScaling= {false}
                                    >
                                      {this.props.subscriptionPlans[YEAR]
                                        .amount / 100}
                                    </Text>
                                  </View>
                                </View>
                                <View
                                  style={[
                                    styles.preDataLine,
                                    styles.borderThin,
                                  ]}
                                />
                                <View style={[styles.preDataText]}>
                                  <Text
                                    style={[
                                      styles.textPrimeSm,
                                      styles.textAlignCenter,
                                    ]}
                                    allowFontScaling= {false}
                                  >
                                    Annual subscription
                                  </Text>
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </PageContainer>
        <PageContainer smScreenFull={true}>
          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              <View style={styles.secArea}>
                {/* <View style={[styles.colPdLtRt]}> */}
                <View
                  style={[
                    styles.titleBlockCom,
                    styles.greyBorderThick,
                    styles.borderSpMd,
                    styles.comMgTopLg,
                  ]}
                >
                  <Text
                    style={[styles.textTitleExLgBold, styles.textAlignCenter]}
                    allowFontScaling= {false}
                  >
                    Billing and Payment Information
                  </Text>
                </View>
                {/* </View> */}
              </View>
            </View>
          </View>
        </PageContainer>
        <PageContainer smScreenFull={true}>
          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              <View style={[styles.btnArea, styles.comMgBot]}>
                <View style={[styles.btnBlock, styles.noMgTop]}>
                  <TouchableOpacity
                    style={[styles.submitSubCom, styles.submitBgOrange]}
                    underlayColor="rgba(0, 153, 168, 0.8)"
                    onPress={this.copyFromProfile}
                  >
                    <View style={styles.btnSingleView}>
                      <View style={[styles.btnSingle, styles.btnSingleCent]}>
                        {/* <View style={styles.actIndLg}>
                                              <ActivityIndicator size="small" />
                                            </View> */}
                        <UIText
                          style={[
                            styles.submitText,
                            styles.submitTextSm,
                            styles.bold,
                          ]}
                          title="Copy from Profile"
                        />
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              <View style={styles.secArea}>
                <View
                  style={[
                    styles.subSection,
                    styles.greyBorderThick,
                    styles.borderSpMd,
                  ]}
                >
                  <View style={[styles.colPdLtRt]}>
                    <View style={[styles.formInnerArea, styles.viewMgTopLg]}>
                      <View style={[styles.fieldRow]}>
                        <Text style={[styles.textView]}>
                          <Text
                            style={[
                              styles.textLabel,
                              styles.bold,
                              styles.textColorLight,
                            ]}
                            allowFontScaling= {false}
                          >
                            Full Name
                          </Text>
                          <UIText style={styles.textReq} title={"*"} />
                        </Text>

                        <UITextInput
                          style={[
                            styles.textField,
                            styles.textFieldWhite,
                            this.state.validateFullName
                              ? styles.validColor
                              : styles.inValidColor,
                          ]}
                          value={this.state.fullName}
                          onChangeText={(val) => {
                            let specials = /^[a-zA-Z\s]*$/;
                            if ((val && val.trim() === "") || !val) {
                              this.setState({
                                validateFullName: false,
                                fullName: "",
                              });
                            } else {
                              if (specials.test(val)) {
                                this.setState({
                                  validateFullName: true,
                                  fullName: val,
                                });
                              } else {
                                this.setState({
                                  validateFullName: false,
                                  fullName: val,
                                });
                              }
                            }
                          }}
                        />
                      </View>

                      <View style={[styles.fieldRow]}>
                        <Text style={[styles.textView]}>
                          <Text
                            style={[
                              styles.textLabel,
                              styles.bold,
                              styles.textColorLight,
                            ]}
                            allowFontScaling= {false}
                          >
                            Address Line 1
                          </Text>
                          <UIText style={styles.textReq} title={"*"} />
                        </Text>

                        <UITextInput
                          style={[
                            styles.textField,
                            styles.textFieldWhite,
                            this.state.validateAddressLine1
                              ? styles.validColor
                              : styles.inValidColor,
                          ]}
                          value={this.state.addressLine1}
                          onChangeText={(val) => {
                            if ((val && val.trim() === "") || !val) {
                              this.setState({
                                validateAddressLine1: false,
                                addressLine1: "",
                              });
                            } else {
                              this.setState({
                                validateAddressLine1: true,
                                addressLine1: val,
                              });
                            }
                          }}
                        />
                      </View>

                      <View style={[styles.fieldRow]}>
                        <Text style={[styles.textView]}>
                          <Text
                            style={[
                              styles.textLabel,
                              styles.bold,
                              styles.textColorLight,
                            ]}
                            allowFontScaling= {false}
                          >
                            Address Line 2
                          </Text>
                          {/* <UIText style={styles.textReq} title={"*"} /> */}
                        </Text>

                        <UITextInput
                          style={[
                            styles.textField,
                            styles.textFieldWhite,
                            // this.state.validateAddressLine2
                            //   ? styles.validColor
                            //   : styles.inValidColor
                          ]}
                          value={this.state.addressLine2}
                          onChangeText={(val) => {
                            // if (val) {
                            //   this.setState({ validateAddressLine2: true });
                            // } else {
                            //   this.setState({ validateAddressLine2: false });
                            // }
                            this.setState({ addressLine2: val });
                          }}
                        />
                      </View>

                      <View style={[styles.fieldRow, styles.fieldRowFlexRow]}>
                        <View
                          style={[
                            this.state.country === "Canada" ||
                            this.state.country ===
                              "United States of America (USA)"
                              ? styles.fieldColLgView
                              : styles.fieldColLgViewFull,
                          ]}
                        >
                          <View
                            style={[
                              styles.fieldColCont,
                              this.state.country === "Canada" ||
                              this.state.country ===
                                "United States of America (USA)"
                                ? styles.mgRtSm
                                : styles.mgRtZero,
                            ]}
                          >
                            <Text style={styles.textView}>
                              <Text
                                style={[
                                  styles.textLabel,
                                  styles.bold,
                                  styles.textColorLight,
                                ]}
                                allowFontScaling= {false}
                              >
                                City
                              </Text>
                              <UIText style={styles.textReq} title={"*"} />
                            </Text>
                            <TextInput
                              style={[
                                styles.textField,
                                styles.textFieldWhite,
                                this.state.validateCity
                                  ? styles.validColor
                                  : styles.inValidColor,
                              ]}
                              allowFontScaling={false}
                              onChangeText={(val) => {
                                if ((val && val.trim() === "") || !val) {
                                  this.setState({
                                    validateCity: false,
                                    city: "",
                                  });
                                } else {
                                  this.setState({
                                    validateCity: true,
                                    city: val,
                                  });
                                }
                              }}
                              value={this.state.city}
                            />
                          </View>
                        </View>

                        {(this.state.country === "Canada" ||
                          this.state.country ===
                            "United States of America (USA)") && (
                          <View style={styles.fieldColSmView}>
                            <View style={[styles.fieldColCont, styles.mgLtSm]}>
                              <View
                                style={[
                                  styles.pickerSelectionWrapper,
                                  this.state.validateState
                                    ? styles.validColor
                                    : styles.inValidColor,
                                ]}
                              >
                                <Text
                                  style={[
                                    styles.textView,
                                    styles.textViewPicker,
                                  ]}
                                  allowFontScaling= {false}
                                >
                                  <Text
                                    style={[
                                      styles.textLabel,
                                      styles.bold,
                                      styles.textColorLight,
                                    ]}
                                    allowFontScaling= {false}
                                  >
                                    {this.state.country === "Canada"
                                      ? "Province"
                                      : this.state.country ===
                                        "United States of America (USA)"
                                      ? "State"
                                      : "Province/State"}
                                  </Text>
                                  <UIText style={styles.textReq} title={"*"} />
                                </Text>
                                <View
                                  style={[
                                    stylesRes.dropArrowView,
                                    stylesRes.dropArrowTopLg,
                                  ]}
                                  pointerEvents={"none"}
                                >
                                  <View style={stylesRes.dropArrowIcon}>
                                    <Image
                                      style={[styles.imgContain]}
                                      source={require("../../assets/icons/dropdown-arrow.png")}
                                    />
                                  </View>
                                </View>
                                {this.state.country === "Canada" ? (
                                  <Picker
                                    style={[
                                      styles.pickerAreaField,
                                      styles.selectionPicker,
                                    ]}
                                    selectedValue={this.state.state}
                                    onValueChange={(val) => {
                                      if (val && val !== "") {
                                        this.setState({ validateState: true });
                                      } else {
                                        this.setState({ validateState: false });
                                      }
                                      this.setState({ state: val });
                                    }}
                                  >
                                    <Picker.Item label="" value="" />
                                    <Picker.Item label="AB" value="AB" />
                                    <Picker.Item label="BC" value="BC" />
                                    <Picker.Item label="MB" value="MB" />
                                    <Picker.Item label="NB" value="NB" />
                                    <Picker.Item label="NL" value="NL" />
                                    <Picker.Item label="NS" value="NS" />
                                    <Picker.Item label="NT" value="NT" />
                                    <Picker.Item label="NU" value="NU" />
                                    <Picker.Item label="ON" value="ON" />
                                    <Picker.Item label="PE" value="PE" />
                                    <Picker.Item label="QC" value="QC" />
                                    <Picker.Item label="SK" value="SK" />
                                    <Picker.Item label="YT" value="YT" />
                                    <Picker.Item label="None" value="None" />
                                  </Picker>
                                ) : this.state.country ===
                                  "United States of America (USA)" ? (
                                  <Picker
                                    style={[
                                      styles.pickerAreaField,
                                      styles.selectionPicker,
                                    ]}
                                    selectedValue={this.state.state}
                                    onValueChange={(val) => {
                                      if (val && val !== "") {
                                        this.setState({ validateState: true });
                                      } else {
                                        this.setState({ validateState: false });
                                      }
                                      this.setState({ state: val });
                                    }}
                                  >
                                    <Picker.Item label="" value="" />
                                    <Picker.Item label="AL" value="AL" />
                                    <Picker.Item label="AK" value="AK" />
                                    <Picker.Item label="AZ" value="AZ" />
                                    <Picker.Item label="AR" value="AR" />
                                    <Picker.Item label="CA" value="CA" />
                                    <Picker.Item label="CO" value="CO" />
                                    <Picker.Item label="CT" value="CT" />
                                    <Picker.Item label="DE" value="DE" />
                                    <Picker.Item label="DC" value="DC" />
                                    <Picker.Item label="FL" value="FL" />
                                    <Picker.Item label="GA" value="GA" />
                                    <Picker.Item label="HI" value="HI" />
                                    <Picker.Item label="ID" value="ID" />
                                    <Picker.Item label="IL" value="IL" />
                                    <Picker.Item label="IN" value="IN" />
                                    <Picker.Item label="IA" value="IA" />
                                    <Picker.Item label="KS" value="KS" />
                                    <Picker.Item label="KY" value="KY" />
                                    <Picker.Item label="LA" value="LA" />
                                    <Picker.Item label="ME" value="ME" />
                                    <Picker.Item label="MD" value="MD" />
                                    <Picker.Item label="MA" value="MA" />
                                    <Picker.Item label="MI" value="MI" />
                                    <Picker.Item label="MN" value="MN" />
                                    <Picker.Item label="MS" value="MS" />
                                    <Picker.Item label="MO" value="MO" />
                                    <Picker.Item label="MT" value="MT" />
                                    <Picker.Item label="NE" value="NE" />
                                    <Picker.Item label="NV" value="NV" />
                                    <Picker.Item label="NH" value="NH" />
                                    <Picker.Item label="NJ" value="NJ" />
                                    <Picker.Item label="NM" value="NM" />
                                    <Picker.Item label="NY" value="NY" />
                                    <Picker.Item label="NC" value="NC" />
                                    <Picker.Item label="ND" value="ND" />
                                    <Picker.Item label="OH" value="OH" />
                                    <Picker.Item label="OK" value="OK" />
                                    <Picker.Item label="OR" value="OR" />
                                    <Picker.Item label="PA" value="PA" />
                                    <Picker.Item label="RI" value="RI" />
                                    <Picker.Item label="SC" value="SC" />
                                    <Picker.Item label="SD" value="SD" />
                                    <Picker.Item label="TN" value="TN" />
                                    <Picker.Item label="TX" value="TX" />
                                    <Picker.Item label="UT" value="UT" />
                                    <Picker.Item label="VT" value="VT" />
                                    <Picker.Item label="VA" value="VA" />
                                    <Picker.Item label="WA" value="WA" />
                                    <Picker.Item label="WV" value="WV" />
                                    <Picker.Item label="WI" value="WI" />
                                    <Picker.Item label="WY" value="WY" />
                                    <Picker.Item label="None" value="None" />
                                  </Picker>
                                ) : (
                                  <Picker
                                    style={[
                                      styles.pickerAreaField,
                                      styles.selectionPicker,
                                    ]}
                                    onValueChange={(val) => {
                                      this.setState({ state: val });
                                    }}
                                    selectedValue={this.state.state}
                                  >
                                    <Picker.Item label="" value="" />
                                  </Picker>
                                )}
                              </View>
                            </View>
                          </View>
                        )}
                      </View>

                      <View style={[styles.fieldRow]}>
                        <Text style={[styles.textView]}>
                          <Text
                            style={[
                              styles.textLabel,
                              styles.bold,
                              styles.textColorLight,
                            ]}
                            allowFontScaling= {false}
                          >
                            Postal/ZIP code
                          </Text>
                          <UIText style={styles.textReq} title={"*"} />
                        </Text>

                        <UITextInput
                          style={[
                            styles.textField,
                            styles.textFieldWhite,
                            this.state.validatePostalCode
                              ? styles.validColor
                              : styles.inValidColor,
                          ]}
                          value={this.state.postalCode}
                          onChangeText={(val) => {
                            if ((val && val.trim() === "") || !val) {
                              this.setState({
                                validatePostalCode: false,
                                postalCode: "",
                              });
                            } else {
                              this.setState({
                                validatePostalCode: true,
                                postalCode: val,
                              });
                            }
                          }}
                        />
                      </View>

                      <View style={[styles.fieldRow, styles.fieldRowNoMg]}>
                        <View
                          style={[
                            styles.pickerSelectionWrapper,
                            this.state.validateCountry
                              ? styles.validColor
                              : styles.inValidColor,
                          ]}
                        >
                          <Text
                            style={[styles.textView, styles.textViewPicker]}
                          >
                            <Text
                              style={[
                                styles.textLabel,
                                styles.bold,
                                styles.textColorLight,
                              ]}
                              allowFontScaling= {false}
                            >
                              Country
                            </Text>
                            <UIText style={styles.textReq} title={"*"} />
                          </Text>
                          <View
                            style={[
                              stylesRes.dropArrowView,
                              stylesRes.dropArrowTopLg,
                            ]}
                            pointerEvents={"none"}
                          >
                            <View style={[stylesRes.dropArrowIcon]}>
                              <Image
                                style={[styles.imgContain]}
                                source={require("../../assets/icons/dropdown-arrow.png")}
                              />
                            </View>
                          </View>
                          <Picker
                            style={[
                              styles.pickerAreaField,
                              styles.selectionPicker,
                            ]}
                            selectedValue={this.state.country}
                            onValueChange={(val) => {
                              if (val) {
                                this.setState({ validateCountry: true });
                              } else {
                                this.setState({ validateCountry: false });
                              }
                              this.setState({ country: val });
                            }}
                          >
                            <Picker.Item label="" value="" />
                            <Picker.Item label="Canada" value="Canada" />
                            <Picker.Item
                              label="United States of America (USA)"
                              value="United States of America (USA)"
                            />
                            <Picker.Item
                              label="Afghanistan"
                              value="Afghanistan"
                            />
                            <Picker.Item label="Albania" value="Albania" />
                            <Picker.Item label="Algeria" value="Algeria" />
                            <Picker.Item label="Andorra" value="Andorra" />
                            <Picker.Item label="Angola" value="Angola" />
                            <Picker.Item
                              label="Antigua and Barbuda"
                              value="Antigua and Barbuda"
                            />
                            <Picker.Item label="Argentina" value="Argentina" />
                            <Picker.Item label="Armenia" value="Armenia" />
                            <Picker.Item label="Australia" value="Australia" />
                            <Picker.Item label="Austria" value="Austria" />
                            <Picker.Item
                              label="Azerbaijan"
                              value="Azerbaijan"
                            />
                            <Picker.Item label="Bahamas" value="Bahamas" />
                            <Picker.Item label="Bahrain" value="Bahrain" />
                            <Picker.Item
                              label="Bangladesh"
                              value="Bangladesh"
                            />
                            <Picker.Item label="Barbados" value="Barbados" />
                            <Picker.Item label="Belarus" value="Belarus" />
                            <Picker.Item label="Belgium" value="Belgium" />
                            <Picker.Item label="Belize" value="Belize" />
                            <Picker.Item label="Benin" value="Benin" />
                            <Picker.Item label="Bhutan" value="Bhutan" />
                            <Picker.Item label="Bolivia" value="Bolivia" />
                            <Picker.Item
                              label="Bosnia and Herzegovina"
                              value="Bosnia and Herzegovina"
                            />
                            <Picker.Item label="Botswana" value="Botswana" />
                            <Picker.Item label="Brazil" value="Brazil" />
                            <Picker.Item label="Brunei" value="Brunei" />
                            <Picker.Item label="Bulgaria" value="Bulgaria" />
                            <Picker.Item
                              label="Burkina Faso"
                              value="Burkina Faso"
                            />
                            <Picker.Item label="Burundi" value="Burundi" />
                            <Picker.Item
                              label="Cabo Verde"
                              value="Cabo Verde"
                            />
                            <Picker.Item label="Cambodia" value="Cambodia" />
                            <Picker.Item label="Cameroon" value="Cameroon" />
                            <Picker.Item
                              label="Central African Republic (CAR)"
                              value="Central African Republic (CAR)"
                            />
                            <Picker.Item label="Chad" value="Chad" />
                            <Picker.Item label="Chile" value="Chile" />
                            <Picker.Item label="China" value="China" />
                            <Picker.Item label="Colombia" value="Colombia" />
                            <Picker.Item label="Comoros" value="Comoros" />
                            <Picker.Item
                              label="Congo, Democratic Republic of the"
                              value="Congo, Democratic Republic of the"
                            />
                            <Picker.Item
                              label="Congo, Republic of the"
                              value="Congo, Republic of the"
                            />
                            <Picker.Item
                              label="Costa Rica"
                              value="Costa Rica"
                            />
                            <Picker.Item
                              label="Cote d'Ivoire"
                              value="Cote d'Ivoire"
                            />
                            <Picker.Item label="Croatia" value="Croatia" />
                            <Picker.Item label="Cuba" value="Cuba" />
                            <Picker.Item label="Cyprus" value="Cyprus" />
                            <Picker.Item label="Czechia" value="Czechia" />
                            <Picker.Item label="Denmark" value="Denmark" />
                            <Picker.Item label="Djibouti" value="Djibouti" />
                            <Picker.Item label="Dominica" value="Dominica" />
                            <Picker.Item
                              label="Dominican Republic"
                              value="Dominican Republic"
                            />
                            <Picker.Item label="Ecuador" value="Ecuador" />
                            <Picker.Item label="Egypt" value="Egypt" />
                            <Picker.Item
                              label="El Salvador"
                              value="El Salvador"
                            />
                            <Picker.Item
                              label="Equatorial Guinea"
                              value="Equatorial Guinea"
                            />
                            <Picker.Item label="Eritrea" value="Eritrea" />
                            <Picker.Item label="Estonia" value="Estonia" />
                            <Picker.Item
                              label="Eswatini (formerly Swaziland)"
                              value="Eswatini (formerly Swaziland)"
                            />
                            <Picker.Item label="Ethiopia" value="Ethiopia" />
                            <Picker.Item label="Fiji" value="Fiji" />
                            <Picker.Item label="Finland" value="Finland" />
                            <Picker.Item label="France" value="France" />
                            <Picker.Item label="Gabon" value="Gabon" />
                            <Picker.Item label="Gambia" value="Gambia" />
                            <Picker.Item label="Georgia" value="Georgia" />
                            <Picker.Item label="Germany" value="Germany" />
                            <Picker.Item label="Ghana" value="Ghana" />
                            <Picker.Item label="Greece" value="Greece" />
                            <Picker.Item label="Grenada" value="Grenada" />
                            <Picker.Item label="Guatemala" value="Guatemala" />
                            <Picker.Item label="Guinea" value="Guinea" />
                            <Picker.Item
                              label="Guinea-Bissau"
                              value="Guinea-Bissau"
                            />
                            <Picker.Item label="Guyana" value="Guyana" />
                            <Picker.Item label="Haiti" value="Haiti" />
                            <Picker.Item label="Honduras" value="Honduras" />
                            <Picker.Item label="Hungary" value="Hungary" />
                            <Picker.Item label="Iceland" value="Iceland" />
                            <Picker.Item label="India" value="India" />
                            <Picker.Item label="Indonesia" value="Indonesia" />
                            <Picker.Item label="Iran" value="Iran" />
                            <Picker.Item label="Iraq" value="Iraq" />
                            <Picker.Item label="Ireland" value="Ireland" />
                            <Picker.Item label="Israel" value="Israel" />
                            <Picker.Item label="Italy" value="Italy" />
                            <Picker.Item label="Jamaica" value="Jamaica" />
                            <Picker.Item label="Japan" value="Japan" />
                            <Picker.Item label="Jordan" value="Jordan" />
                            <Picker.Item
                              label="Kazakhstan"
                              value="Kazakhstan"
                            />
                            <Picker.Item label="Kenya" value="Kenya" />
                            <Picker.Item label="Kiribati" value="Kiribati" />
                            <Picker.Item label="Kosovo" value="Kosovo" />
                            <Picker.Item label="Kuwait" value="Kuwait" />
                            <Picker.Item
                              label="Kyrgyzstan"
                              value="Kyrgyzstan"
                            />
                            <Picker.Item label="Laos" value="Laos" />
                            <Picker.Item label="Latvia" value="Latvia" />
                            <Picker.Item label="Lebanon" value="Lebanon" />
                            <Picker.Item label="Lesotho" value="Lesotho" />
                            <Picker.Item label="Liberia" value="Liberia" />
                            <Picker.Item label="Libya" value="Libya" />
                            <Picker.Item
                              label="Liechtenstein"
                              value="Liechtenstein"
                            />
                            <Picker.Item label="Lithuania" value="Lithuania" />
                            <Picker.Item
                              label="Luxembourg"
                              value="Luxembourg"
                            />
                            <Picker.Item
                              label="Madagascar"
                              value="Madagascar"
                            />
                            <Picker.Item label="Malawi" value="Malawi" />
                            <Picker.Item label="Malaysia" value="Malaysia" />
                            <Picker.Item label="Maldives" value="Maldives" />
                            <Picker.Item label="Mali" value="Mali" />
                            <Picker.Item label="Malta" value="Malta" />
                            <Picker.Item
                              label="Marshall Islands"
                              value="Marshall Islands"
                            />
                            <Picker.Item
                              label="Mauritania"
                              value="Mauritania"
                            />
                            <Picker.Item label="Mauritius" value="Mauritius" />
                            <Picker.Item label="Mexico" value="Mexico" />
                            <Picker.Item
                              label="Micronesia"
                              value="Micronesia"
                            />
                            <Picker.Item label="Moldova" value="Moldova" />
                            <Picker.Item label="Monaco" value="Monaco" />
                            <Picker.Item label="Mongolia" value="Mongolia" />
                            <Picker.Item
                              label="Montenegro"
                              value="Montenegro"
                            />
                            <Picker.Item label="Morocco" value="Morocco" />
                            <Picker.Item
                              label="Mozambique"
                              value="Mozambique"
                            />
                            <Picker.Item label="Myanmar" value="Myanmar" />
                            <Picker.Item label="Namibia" value="Namibia" />
                            <Picker.Item label="Nauru" value="Nauru" />
                            <Picker.Item label="Nepal" value="Nepal" />
                            <Picker.Item
                              label="Netherlands"
                              value="Netherlands"
                            />
                            <Picker.Item
                              label="New Zealand"
                              value="New Zealand"
                            />
                            <Picker.Item label="Nicaragua" value="Nicaragua" />
                            <Picker.Item label="Niger" value="Niger" />
                            <Picker.Item label="Nigeria" value="Nigeria" />
                            <Picker.Item
                              label="North Korea"
                              value="North Korea"
                            />
                            <Picker.Item
                              label="North Macedonia"
                              value="North Macedonia"
                            />
                            <Picker.Item label="Norway" value="Norway" />
                            <Picker.Item label="Oman" value="Oman" />
                            <Picker.Item label="Pakistan" value="Pakistan" />
                            <Picker.Item label="Palau" value="Palau" />
                            <Picker.Item label="Palestine" value="Palestine" />
                            <Picker.Item label="Panama" value="Panama" />
                            <Picker.Item
                              label="Papua New Guinea"
                              value="Papua New Guinea"
                            />
                            <Picker.Item label="Paraguay" value="Paraguay" />
                            <Picker.Item label="Peru" value="Peru" />
                            <Picker.Item
                              label="Philippines"
                              value="Philippines"
                            />
                            <Picker.Item label="Poland" value="Poland" />
                            <Picker.Item label="Portugal" value="Portugal" />
                            <Picker.Item label="Qatar" value="Qatar" />
                            <Picker.Item label="Romania" value="Romania" />
                            <Picker.Item label="Russia" value="Russia" />
                            <Picker.Item label="Rwanda" value="Rwanda" />
                            <Picker.Item
                              label="Saint Kitts and Nevis"
                              value="Saint Kitts and Nevis"
                            />
                            <Picker.Item
                              label="Saint Lucia"
                              value="Saint Lucia"
                            />
                            <Picker.Item
                              label="Saint Vincent and the Grenadines"
                              value="Saint Vincent and the Grenadines"
                            />
                            <Picker.Item label="Samoa" value="Samoa" />
                            <Picker.Item
                              label="San Marino"
                              value="San Marino"
                            />
                            <Picker.Item
                              label="Sao Tome and Principe"
                              value="Sao Tome and Principe"
                            />
                            <Picker.Item
                              label="Saudi Arabia"
                              value="Saudi Arabia"
                            />
                            <Picker.Item label="Senegal" value="Senegal" />
                            <Picker.Item label="Serbia" value="Serbia" />
                            <Picker.Item
                              label="Seychelles"
                              value="Seychelles"
                            />
                            <Picker.Item
                              label="Sierra Leone"
                              value="Sierra Leone"
                            />
                            <Picker.Item label="Singapore" value="Singapore" />
                            <Picker.Item label="Slovakia" value="Slovakia" />
                            <Picker.Item label="Slovenia" value="Slovenia" />
                            <Picker.Item
                              label="Solomon Islands"
                              value="Solomon Islands"
                            />
                            <Picker.Item label="Somalia" value="Somalia" />
                            <Picker.Item
                              label="South Africa"
                              value="South Africa"
                            />
                            <Picker.Item
                              label="South Korea"
                              value="South Korea"
                            />
                            <Picker.Item
                              label="South Sudan"
                              value="South Sudan"
                            />
                            <Picker.Item label="Spain" value="Spain" />
                            <Picker.Item label="Sri Lanka" value="Sri Lanka" />
                            <Picker.Item label="Sudan" value="Sudan" />
                            <Picker.Item label="Suriname" value="Suriname" />
                            <Picker.Item label="Sweden" value="Sweden" />
                            <Picker.Item
                              label="Switzerland"
                              value="Switzerland"
                            />
                            <Picker.Item label="Syria" value="Syria" />
                            <Picker.Item label="Taiwan" value="Taiwan" />
                            <Picker.Item
                              label="Tajikistan"
                              value="Tajikistan"
                            />
                            <Picker.Item label="Tanzania" value="Tanzania" />
                            <Picker.Item label="Thailand" value="Thailand" />
                            <Picker.Item
                              label="Timor-Leste"
                              value="Timor-Leste"
                            />
                            <Picker.Item label="Togo" value="Togo" />
                            <Picker.Item label="Tonga" value="Tonga" />
                            <Picker.Item
                              label="Trinidad and Tobago"
                              value="Trinidad and Tobago"
                            />
                            <Picker.Item label="Tunisia" value="Tunisia" />
                            <Picker.Item label="Turkey" value="Turkey" />
                            <Picker.Item
                              label="Turkmenistan"
                              value="Turkmenistan"
                            />
                            <Picker.Item label="Tuvalu" value="Tuvalu" />
                            <Picker.Item label="Uganda" value="Uganda" />
                            <Picker.Item label="Ukraine" value="Ukraine" />
                            <Picker.Item
                              label="United Arab Emirates (UAE)"
                              value="United Arab Emirates (UAE)"
                            />
                            <Picker.Item
                              label="United Kingdom (UK)"
                              value="United Kingdom (UK)"
                            />
                            <Picker.Item label="Uruguay" value="Uruguay" />
                            <Picker.Item
                              label="Uzbekistan"
                              value="Uzbekistan"
                            />
                            <Picker.Item label="Vanuatu" value="Vanuatu" />
                            <Picker.Item
                              label="Vatican City (Holy See)"
                              value="Vatican City (Holy See)"
                            />
                            <Picker.Item label="Venezuela" value="Venezuela" />
                            <Picker.Item label="Vietnam" value="Vietnam" />
                            <Picker.Item label="Yemen" value="Yemen" />
                            <Picker.Item label="Zambia" value="Zambia" />
                            <Picker.Item label="Zimbabwe" value="Zimbabwe" />
                            <Picker.Item label="Others" value="Others" />
                            <Picker.Item label="Europe" value="Europe" />
                          </Picker>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              <View style={styles.secArea}>
                <View
                  style={[
                    styles.subSection,
                    styles.greyBorderThick,
                    styles.borderSpMd,
                  ]}
                >
                  <View style={[styles.colPdLtRt]}>
                    <View style={[styles.formInnerArea]}>
                      <CardElement />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <OrderSummery
            type={this.state.selectedPlane === MONTH ? "Monthly" : "Annual"}
            total={this.state.total}
            amount={this.state.amount}
          />
          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              <View style={[styles.greyBorderThickTop, styles.comMgTopLg]} />
              <View style={[styles.btnArea]}>
                <View style={[styles.btnBlock, styles.noMgTop]}>
                  <View style={stylesRes.btnViewSecMax} dataSet={{media: ids.btnViewSecMax}}>
                    <View style={styles.fieldBtn}>
                      <TouchableOpacity
                        style={[styles.submit]}
                        underlayColor="rgba(0, 153, 168, 0.8)"
                        onPress={this.handleSubmit}
                        disabled={this.state.wait}
                        nativeID={'billingSubscribe'}>
                        <View style={styles.btnSingleView}>
                          <View
                            style={[styles.btnSingle, styles.btnSingleCent]}
                          >
                            {this.state.wait && (
                              <View style={styles.actIndLg}>
                                <ActivityIndicator size="small" />
                              </View>
                            )}
                            <UIText
                              style={styles.submitText}
                              title="Subscribe Now"
                            />
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </PageContainer>
        <OrderCompleteSubscription
          visibleModal={this.state.visibleModal}
          // onRequestClose={()=>this.setState({visibleModal:false})}
          // onBackdropPress={()=>this.setState({visibleModal:false})}
          type={this.state.selectedPlane === MONTH ? "Monthly" : "Annual"}
          orderNumber={this.state.orderNumber}
          ok={() => {
            this.props.subscriptionSuccess();
            this.setState({ visibleModal: false });
          }}
        />
      </React.Fragment>
    );
  }
}

export default injectStripe(CheckoutForm);
