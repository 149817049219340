import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import styles from '../../../../../Css';

const setUpReminder = props => {
  const {isSlected, onSelect, data} = props;
  const {id, name} = data;
  return (
    <View
      style={[styles.answerSingle, styles.answerMg]}
      // key={res.id}
    >
      <TouchableOpacity
        style={[styles.answerOptMg, styles.answerViewWrapper]}
        onPress={() => onSelect(id)}>
        <View style={[styles.optionWrapper]}>
          {isSlected ? (
            <Image
              style={styles.radioIcon}
              source={require('../../../../../assets/icons/radio-selected.png')}
            />
          ) : (
            <Image
              style={styles.radioIcon}
              source={require('../../../../../assets/icons/radio-default.png')}
            />
          )}
        </View>
        <View style={[styles.answerTextWrapper]}>
          <Text style={[styles.textPrime]} allowFontScaling={false}>{name}</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (setUpReminder);
