import React from 'react';
import {Text, View, TouchableOpacity} from 'react-native';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import Modal from '../../Modal/modal';
//import ActivityIndicator from '../../ActivityIndicator/activityIndicator';
import ModalLayout from '../../ModalLayout/modalLayout';
const confirmationBox = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {visibleModal, cancel, confirm, message} = props;
  return (
    <Modal
      visible={visibleModal}
      // onRequestClose={    const { visibleModal, cancel} = props;}
      onBackdropPress={cancel}>
      <ModalLayout contPosition={'end'} popupAuto={'true'}>
        <View style={styles.popupContentWrapper}>
          <View style={[styles.popMessageContView]}>
            <View style={[styles.popMessageTitleView, styles.comMgBotSm]}>
              <Text style={[styles.textTitleBold, styles.textAlignCenter]} allowFontScaling={false}>
                {message}
              </Text>
            </View>

            <View style={[styles.popMessageDescView, styles.comMgBotSm]}>
              <Text style={[styles.testPrime, styles.textAlignCenter]} allowFontScaling={false}>
                Are you sure?
              </Text>
            </View>

            <View style={[styles.popBtnView, styles.comMgTopMd]}>
              <View style={styles.btnArea}>
                <View style={[stylesRes.btnViewSec, stylesRes.btnViewSecComNoPd]} dataSet={{media: ids.btnViewSec}}>
                  <View style={styles.fieldBtn}>
                    <View style={styles.btnBlockFull}>
                      <TouchableOpacity
                        style={[styles.submitSm, styles.submitBgBlue]}
                        underlayColor="rgba(0, 153, 168, 0.8)"
                        onPress={cancel}>
                        <View style={styles.btnSingleView}>
                          <View
                            style={[styles.btnSingle, styles.btnSingleCent]}>
                            <Text style={[styles.submitBtnTextWhite]} allowFontScaling={false}>
                              Cancel
                            </Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View style={styles.fieldBtnSpSm} />
                  <View style={styles.fieldBtn}>
                    <View style={styles.btnBlockFull}>
                      <TouchableOpacity
                        style={[styles.submitSm, styles.disableBtn]}
                        underlayColor="rgba(0, 153, 168, 0.8)"
                        onPress={confirm}>
                        <View style={styles.btnSingleView}>
                          <View
                            style={[styles.btnSingle, styles.btnSingleCent]}>
                            {/* {resumWait && ( */}
                            {/* <View
                              style={[
                                styles.indicatorIconView,
                                styles.indicatorLtMini,
                              ]}>
                              <ActivityIndicator pdZero={true} size="small" />
                            </View> */}
                            {/* )} */}
                            {/* )} */}
                            <Text style={[styles.submitBtnTextWhite]} allowFontScaling={false}>Yes</Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ModalLayout>
    </Modal>
  );
};
export default (confirmationBox);
