import React from "react";
import { View, Image, TouchableOpacity } from "react-native";
import ActivityIndicator from "../../../ActivityIndicator/activityIndicator";
import styles from "../../../../Css";

const downloadBlock = (props) => {
  const {
    item,
    graphId,
    downloadwait,
    onPressDownload
  } = props;
  return (
    <View
      style={[
        styles.listDownloadSec,
        styles.colPdTopBotRg,
        styles.flexJcCtAiCt,
        styles.downloadSecMg,
      ]}
    >
      {Boolean(downloadwait) && item.id === graphId ? (
        <View style={styles.pdfWaitSm}>
          <View style={styles.attachDownloadLoader}>
            <ActivityIndicator size="small" />
          </View>
        </View>
      ) : (
        <TouchableOpacity
          style={styles.downloadIconTouch}
          onPress={() => onPressDownload(
            item.id,
            item.name,
            item.startDate,
            item.endDate
          )}
        >
          <Image
            style={styles.pdfSmIcon}
            source={require("../../../../assets/icons/pdf-download.png")}
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default downloadBlock;
