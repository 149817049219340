import React from 'react';
import {View, Text, Image} from 'react-native';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import styles from '../../../Css';
import {getConnectionStatus, actionStatus} from '../../../util/commonUiLogic';
const {Popover} = renderers;

const settingsButton = props => {
  const {
    btnStatus,
    onSelectOption
  } = props;
  return (
    <Menu renderer={Popover} rendererProps={{preferredPlacement: 'left'}}>
      <MenuTrigger>
        <View style={[styles.itemIconSingle, styles.cardSettingMenu]}>
          <Image
            style={[styles.settingIconThin, styles.settingIconLight]}
            source={require('../../../assets/icons/setting-lg.png')}
          />
        </View>
      </MenuTrigger>

      <MenuOptions style={[styles.dropDownList, styles.menuOptionsLg]}>
        {getConnectionStatus(btnStatus) === 'Pending' &&
          <MenuOption 
            style={[styles.liItem, styles.cardSettingPd]}
            onSelect={()=> onSelectOption(actionStatus.WITHDRAW)}
          >
            <Text style={styles.textPrime} allowFontScaling= {false}>Withdraw</Text>
          </MenuOption>
        }
        {getConnectionStatus(btnStatus) === 'Connected' &&
          <>
            <MenuOption 
              style={[styles.liItem, styles.cardSettingPd]}
              onSelect={()=> onSelectOption(actionStatus.DISCONNECT)}
            >
              <Text style={styles.textPrime} allowFontScaling= {false}>Disconnect</Text>
            </MenuOption>
            <MenuOption 
              style={[styles.liItem, styles.cardSettingPd]}
              onSelect={()=> onSelectOption(actionStatus.BLOCK)}
            >
              <Text style={styles.textPrime} allowFontScaling= {false}>Block</Text>
            </MenuOption>
            <MenuOption 
              style={[styles.liItem, styles.cardSettingPd]}
              onSelect={()=> onSelectOption(actionStatus.REPORT)}
            >
              <Text style={styles.textPrime} allowFontScaling= {false}>Report</Text>
            </MenuOption>
          </>
        }
      </MenuOptions>
    </Menu>
  );
};
export default settingsButton;
