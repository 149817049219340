import {
    HEALTH_JOURNEY_ADVANCED_FILTERING_ATTACHMENTS,
    HEALTH_JOURNEY_CATEGORIES_FILTERING_DATA,
    RESET_FILTERING_OBJECT_DATA
} from "../actions/actionTypes";

const ATTACHMENTS = [
    {
        category_name: 'Images',
    },
    {
        category_name: 'Documents',
    },
    {
        category_name: 'Videos',
    }
];

const CATEGORYIES = [
    {
        category_name: 'All Categories',
        subcategory: [],
    },
    {
        category_name: 'Documents/Images',
        value: 'ATTACHMENTS',
        subcategory: [],
    },
    {
        category_name: 'Meds/Supplements',
        value: 'MEDICATIONS',
        subcategory: [],
    },
    {
        category_name: 'Symptoms',
        value: 'SYMPTOMS',
        subcategory: [
            { id: 7, val: 'Shoulder Pain' },
            { id: 8, val: 'Headache' },
            { id: 9, val: 'Neck Ache' },
            { id: 10, val: 'Dizziness' },
        ],
    },
    {
        category_name: 'Health Data',
        value: 'HEALTH_DATA',
        subcategory: [],
    },
    {
        category_name: 'Custom Notes',
        value: 'CUSTOM_NOTES',
        subcategory: [],
    },
];

const initialState = {
    advancedFilterAttachments: [],
    categoriesFilterData: [],
};

export default function healthJourneyFilterObjectsReducer(state = initialState, action) {
    switch (action.type) {
        case HEALTH_JOURNEY_ADVANCED_FILTERING_ATTACHMENTS:
            return {
                ...state,
                advancedFilterAttachments: ATTACHMENTS,
            }

        case HEALTH_JOURNEY_CATEGORIES_FILTERING_DATA:
            return {
                ...state,
                categoriesFilterData: CATEGORYIES,
            }
        case RESET_FILTERING_OBJECT_DATA:
            return {
                advancedFilterAttachments: [],
                categoriesFilterData: [],
            }
        default:
            return state;
    }
};