import React, {Component} from 'react';
import {Platform, View} from 'react-native';
import DeviceInfo from 'react-native-device-info';
import {connect} from "react-redux";
import moment from "moment";
import {
    isSubscriptionSponsored,
    isSubscriptionPremium,
    isPremiumSelfPayAccount,
    getSponsorOrganization,
    getSponsorProfile,
    getCurrentSubscription
} from "../../util/subscriptionInformation";
import {InternalUserEmailRegex, currentAppVersion} from "../../Constant";
import {hasValue} from '../../util/hashValueGenerator';
import {BASE_URL} from '../../store/actions/urls';
import {ACCOUNT_ID} from '../../util/pendoConfig';
/* global pendo */
import {PendoSDK} from 'rn-pendo-sdk';
import { WEB } from '../../util/platformWindowConfig';


class PendoProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialChecksum: null,
        }
        setTimeout(() => this.setPendo(this.getVisitorData()), 2000);
    }

    componentDidUpdate() {
        setTimeout(() => this.setPendo(this.getVisitorData()), 2000);
    }

    get_cuid = (userId) => {
        const regex = /https?:\/\/([^\/]+)\/?/;
        let m = BASE_URL.match(regex);
        let zi_api_host = (m) ? String(m[1]) : "unknown";
        let cuid = [userId,zi_api_host].join("@")
        cuid = hasValue(cuid);
        return cuid;
    }

    setPendo = (visitorData) => {
        if(!visitorData) return;
        console.log("PendoProfile:start");
        if (Platform.OS === WEB && pendo.isReady()) {
            pendo.updateOptions({
                visitor: visitorData,
                account: {
                    id: ACCOUNT_ID,
                    name: ACCOUNT_ID
                }
            })
        } else {
            Platform.OS === WEB
                ?   pendo.initialize({
                        visitor: visitorData,
                        account: {
                            id: ACCOUNT_ID,
                            name: ACCOUNT_ID
                        }
                    })
                :   
                    PendoSDK.startSession(
                        this.get_cuid(this.props.userId), 
                        ACCOUNT_ID, 
                        visitorData,
                        {name: ACCOUNT_ID} 
                    )
                    PendoSDK.setVisitorData(visitorData)
        }
        console.log("PendoProfile:end");
    }

    getVisitorData = () => {
        const { userId, userEmail, subscriptionStatus, userTreatments, journalEntriesProcessed,
            userSubscription, userSubscriptionDigest, userConditions, journalEntryTypes,
            dashboard, userCreatedOn, emailVerified, userSymptoms, sharedAccessDigest, peerProfile, journalUserId} = this.props;

        const { sharedAccessGivenToOthers, sharedAccessGivenToMe } =  sharedAccessDigest;
        const journalEntries = Object.values(journalEntriesProcessed);
        const currentSubscription = getCurrentSubscription(userSubscription);
        const sponsor_org = getSponsorOrganization(userSubscriptionDigest);
        const sponsor_profile = getSponsorProfile(userSubscriptionDigest);

        let date_first_journal = -1;
        let date_last_journal = -1;
        if (journalEntries.length) {
            let firstJournalEntry = journalEntries.reduce(function(prev, current) {
                return (prev.createdOn < current.createdOn) ? prev : current
            });
            let lastJournalEntry = journalEntries.reduce(function(prev, current) {
                return (prev.createdOn > current.createdOn) ? prev : current
            });
            date_first_journal = moment(firstJournalEntry.createdOn).toISOString();
            date_last_journal = moment(lastJournalEntry.createdOn).toISOString();
        }

        const num_symptoms = userSymptoms.filter(ut => ut.publishedBy === 'SELF').length;
        const num_user_treatments =  userTreatments.filter(ut => ut.publishedBy === 'SELF').length;

        const num_caregivers = (sharedAccessGivenToOthers) 
            ? sharedAccessGivenToOthers.filter(cg => cg.shareAccessStatus === "CONNECTED").length 
            : 0;
        const num_caregiver_for = (sharedAccessGivenToMe) 
            ? sharedAccessGivenToMe.filter(cg => cg.shareAccessStatus === "CONNECTED").length 
            : 0;

        const visitorData = {
            id: this.get_cuid(userId),
            is_internal_user: (String(userEmail).match(InternalUserEmailRegex)) ? true : false,
            //email: userEmail, //hide email meta data
            //account created
            date_signup: moment(userCreatedOn).toISOString(),
            subscription_status: subscriptionStatus,
            is_sponsored: isSubscriptionSponsored(currentSubscription),
            is_premium: isSubscriptionPremium(currentSubscription),
            is_self_pay: isPremiumSelfPayAccount(currentSubscription),
            sponsor_org: sponsor_org,
            sponsor_profile: sponsor_profile,
            zr_profile_value: sponsor_org + sponsor_profile,
            num_user_treatments: num_user_treatments,
            num_user_conditions: userConditions.length,
            num_journals: journalEntries.length,
            num_routine_zr: journalEntryTypes.filter(jt => jt.journalPublishedBy === 'PARTNER').length,
            num_routine_self: journalEntryTypes.filter(jt => jt.journalPublishedBy !== 'PARTNER').length,
            num_symptoms: num_symptoms,
            num_graphs: dashboard.length,
            is_empty_profile: ((num_symptoms + userConditions.length + num_user_treatments + journalEntries.length) <= 0),
            is_email_verified: emailVerified,
            
            //caregiver info
            num_caregivers: num_caregivers,
            num_caregiver_for: num_caregiver_for,
            has_caregiver: (num_caregivers > 0),
            is_caregiver: (num_caregiver_for > 0),
            is_caretaker_account: journalUserId ? true : false,
            
            //first journal entry
            date_first_journal: date_first_journal,
             //last journal entry
            date_last_journal: date_last_journal, 

            //Community info
            has_community_profile: peerProfile && peerProfile.id ? true : false,
            current_app_version: Platform.OS !== WEB ? DeviceInfo.getVersion() : currentAppVersion,
        };
        let checksum = hasValue(JSON.stringify(visitorData));
        if (this.state.initialChecksum === checksum) return null;
        this.setState({initialChecksum: checksum});
        return visitorData;
    }

    render() {
        return (<View/>)
    }

}

const mapStateToProps = state => {
    return {
        userEmail: state.routines.user.email,
        userId: state.routines.user.id,
        userCreatedOn: state.routines.user.createdOn,
        userFirstName: state.routines.user.firstName,
        userSubscription: state.routines.userSubscription,
        userSubscriptionDigest: state.routines.userSubscriptionDigest,
        userLastName: state.routines.user.lastName,
        subscriptionStatus: state.routines.applicationSettingsDigest.subscriptionStatus,
        userTreatments: state.routines.treatments,
        journalEntriesProcessed: state.routines.journalEntriesProcessed,
        userConditions: state.routines.userConditions,
        userSymptoms: state.routines.symptomsDigest ? state.routines.symptomsDigest.userSymptoms : [],
        journalEntryTypes: (state.routines.journalEntryTypes) ? state.routines.journalEntryTypes : [],
        dashboard: state.routines.dashboard,
        emailVerified: state.auth.emailVerified,
        sharedAccessDigest: (state.routines.sharedAccessDigest) ? state.routines.sharedAccessDigest : [],
        peerProfile: state.routines.peer2peer,
        journalUserId: state.uistate.journalUserId,
    };
};

export default connect(
    mapStateToProps,
    null,) (PendoProfile);
