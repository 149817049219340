import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import * as validator from '../../validator/validator';
import Login from '../../components/Login/Login';
import LoginpasswordReset from '../../components/Login/LoginpasswordReset';
import LoginMFAVerification from '../../components/Login/LoginMFAVerification';
import LoginUserConfirmation from '../../components/Login/LoginUserConfirmation';
import ResetPassword from '../../components/Login/ResetPassword';
import {Platform} from 'react-native';
import {Amplify, Auth} from 'aws-amplify';
import axios from 'axios';
import UserJournalDigestRequestErrorMessage from './UserJournalDigestRequestErrorMessage';

import {
  IDENTITY_POOL_ID,
  REGION,
  IDENTITY_POOL_REGION,
  USER_POOL_ID,
  USER_POOL_WEB_CLIENT_ID,
} from '../../util/cognitoConfig';
import {BASE_URL_COGNITO} from '../../store/actions/urls';
import { ANDROID, IOS, WEB } from '../../util/platformWindowConfig';

Amplify.configure({
  Auth: {
    identityPoolId: IDENTITY_POOL_ID,
    region: REGION,
    identityPoolRegion: IDENTITY_POOL_REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    clientMetadata: {myCustomKey: 'myCustomValue'},
  },
});

class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      email: props.email ? props.email : '',
      password: '',
      passwordVisible: true,
      emailValidate: true,
      passwordValidate: true,
      authSuccessSpinner: false,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      staySigned: Platform.OS === ANDROID || Platform.OS === IOS,
      recaptchaValue: null,
      invalidLogingTryCountExceed: false,
      attemptCount: 0,
      disabledLogin: false,
      // passwordResetRequired: true
    };
  }
  componentDidMount = () => {
    setTimeout(() => {
      if (this.textInput) {
        this.textInput.focus();
      }
    }, 200);
    this.setState({
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {error, errorDescription, windowHeight, windowWidth} = nextProps;

    this.setState({
      ...this.state,
      error,
      errorDescription,
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    });
  }

  handleOnSubmitLogin = () => {
    const username = this.state.email;
    const password = this.state.password;
    const recaptchaValue = this.state.recaptchaValue;
    this.setState({
      errorPassword: false,
      passwordValidate: true,
      errorEmail: false,
      emailValidate: true,
      errorDescription: '',
      error: false,
    });
    if (!username && !password) {
      this.setState({
        emailValidate: false,
        passwordValidate: false,
        errorEmail: true,
        errorPassword: true,
        error: false,
        emailDescription: 'Username is required',
        passwordDescription: 'Password is required',
      });
    } else if (!username) {
      this.setState({
        emailValidate: false,
        errorEmail: true,
        error: false,
        emailDescription: 'Username is required',
      });
    } else if (!password) {
      this.setState({
        passwordValidate: false,
        errorPassword: true,
        error: false,
        passwordDescription: 'Password is required',
      });
    } else {
      if (
        this.state.invalidLogingTryCountExceed &&
        recaptchaValue === null &&
        Platform.OS === WEB
      ) {
        this.setState({
          errorDescription: 'Please verify you are human',
          error: true,
        });
      } else {
        this.setState(
          prevState => ({
            attemptCount: prevState.attemptCount + 1,
          }),
          () => {
            const validationData = {
              gcaptchaResponse: recaptchaValue,
              gcaptchaVisible: this.state.invalidLogingTryCountExceed
                ? 'true'
                : 'false',
            };
            this.onLogin(username, password, validationData);
          },
        );
      }
    }
  };

  onLogin = (username, password, validationData) => {
    this.setState({authSuccessSpinner: true, disabledLogin: true});
    Auth.signIn({
      username, // Required, the username
      password, // Optional, the password,
      validationData,
    })
      .then(user => {
        if (
          user.challengeName === 'SMS_MFA' ||
          user.challengeName === 'SOFTWARE_TOKEN_MFA'
        ) {
          this.setState({
            MFAVerification: true,
            user: user,
            forgotPassword: false,
            userNotConfirmed: false,
            passwordResetRequired: false,
          });
        } else {
          this.onAuth(user);
        }
      })
      .catch(err => {
        console.log(err);
        if (err.code === 'UserNotConfirmedException') {
          Auth.resendSignUp(username)
            .then(() => {
              this.setState({
                userNotConfirmed: true,
              });
            })
            .catch(e => {
              this.setState({
                errorDescription: e.message,
                error: true,
                authSuccessSpinner: false,
                disabledLogin: false,
              });
            });
        } else if (err.code === 'PasswordResetRequiredException') {
          Auth.forgotPassword(username)
            .then(data => {
              this.setState({
                passwordResetRequired: true,
              });
            })
            .catch(er => {
              this.setState({
                errorDescription: er.message,
                error: true,
                authSuccessSpinner: false,
                disabledLogin: false,
              });
            });
        } else if (
          err.code === 'UserNotFoundException' ||
          err.code === 'NotAuthorizedException' ||
          err.code === 'UserLambdaValidationException'
        ) {
          if (
            err.message.includes('INALID_USERNAME_PASSWORD') ||
            err.message.includes('Incorrect username or password')
          ) {
            if (this.state.attemptCount === 6) {
              this.setState({
                errorDescription:
                  'Error! You have tried to log in more than 5 times. Please verify you are human',
                error: true,
                invalidLogingTryCountExceed: true,
                authSuccessSpinner: false,
                disabledLogin: false,
              });
            } else if (this.state.attemptCount > 6) {
              this.setState(
                {invalidLogingTryCountExceed: false, recaptchaValue: null},
                () => {
                  this.setState({
                    invalidLogingTryCountExceed: true,
                    authSuccessSpinner: false,
                    disabledLogin: false,
                    errorDescription: 'Incorrect username or password.',
                    error: true,
                  });
                },
              );
            } else {
              this.setState({
                errorDescription: 'Incorrect username or password.',
                error: true,
                authSuccessSpinner: false,
                disabledLogin: false,
              });
            }
          } else if (err.message.includes('Password attempts exceeded')) {
            this.setState({
              errorDescription:
                'Error! You have tried to log in more than 5 times. Please verify you are human',
              error: true,
              invalidLogingTryCountExceed: true,
              authSuccessSpinner: false,
              disabledLogin: false,
            });
          } else if (err.message.includes('GOOGLE_CAPTCHA_FAILED')) {
            this.setState({
              errorDescription: 'Google Captcha failed',
              error: true,
              invalidLogingTryCountExceed: true,
              authSuccessSpinner: false,
              disabledLogin: false,
            });
          } else {
            this.setState({
              errorDescription: err.message,
              error: true,
              authSuccessSpinner: false,
              disabledLogin: false,
            });
          }
          if (err.code === 'UserNotFoundException') {
            this.setState({
              emailValidate: false,
            });
          } else if (err.code === 'NotAuthorizedException') {
            this.setState({
              passwordValidate: false,
            });
          }
        } else if (err.code === 'InvalidParameterException') {
          this.setState({
            errorDescription: 'Invalid username, please resubmit.',
            error: true,
            authSuccessSpinner: false,
            disabledLogin: false,
          });
        } else if (err.code === 'NetworkError') {
          this.setState({
            errorDescription: 'Network error.',
            error: true,
            authSuccessSpinner: false,
            disabledLogin: false,
          });
        }
      });
  };

  onAuth = user => {
    this.props.onAuth(
      user,
      success => {
        if (success) {
          this.props.onSaveQuestionToAttach('');
          //this.props.onLoginButtonPress();
          //this.props.onLoginSuccess();
        } else {
          this.setState({authSuccessSpinner: false, disabledLogin: false});
        }
      },
      this.state.staySigned,
      true,
    );
  };

  handleOnChangeEmail = email => {
    this.setState({
      //emailValidate: validator.validateEmail(email),
      email: email.trim(),
      errorEmail: false,
    });
    if (validator.validateEmail(email.trim())) {
      this.setState({
        emailValidate: true,
        errorEmail: false,
      });
    }
  };

  handleOnChangePassword = password => {
    // let passwordValidate = false;
    // if (password) {
    //   passwordValidate = true;
    // }

    this.setState({
      password,
      errorPassword: false,
      passwordValidate: true,
    });
  };

  toggleTickCallback = toggleVal => {
    this.setState({staySigned: toggleVal});
  };

  handlePasswordVisibility = () => {
    this.setState(prevState => {
      return {
        passwordVisible: !prevState.passwordVisible,
      };
    });
  };

  changePassword = (newPassword, verificationCode, callBack) => {
    const username = this.state.email;
    const validationData = {
      gcaptchaResponse: null,
    };
    Auth.forgotPasswordSubmit(username, verificationCode, newPassword)
      .then(data => {
        callBack(true);
        setTimeout(() => {
          this.onLogin(username, newPassword, validationData);
        }, 1000);
      })
      .catch(err => {
        console.log(err);
        if (
          err.code === 'CodeMismatchException' ||
          err.code === 'ExpiredCodeException'
        ) {
          callBack(false, 'Error! Looks like you entered the wrong code');
        } else {
          callBack(false, err.message);
        }
      });
  };

  resendCode = callBack => {
    const username = this.state.email;
    Auth.forgotPassword(username)
      .then(data => {
        console.log(data);
        callBack(true);
      })
      .catch(error => {
        callBack(false, error.message);
      });
  };

  mfaVerification = (verificationCode, callBack) => {
    const user = this.state.user;
    Auth.confirmSignIn(
      user, // Return object from Auth.signIn()
      verificationCode, // Confirmation code
      user.challengeName, // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    )
      .then(user => {
        callBack(true);
        setTimeout(() => {
          this.onAuth(user);
        }, 2500);
      })
      .catch(err => {
        console.log(err);
        if (
          err.code === 'CodeMismatchException' ||
          err.code === 'ExpiredCodeException'
        ) {
          callBack(false, 'Error! Looks like you entered the wrong code');
        } else {
          callBack(false, err.message);
        }
      });
  };
  conformationVerification = (verificationCode, callBack) => {
    const username = this.state.email;
    const password = this.state.password;
    const validationData = {
      gcaptchaResponse: null,
    };
    Auth.confirmSignUp(username, verificationCode, {
      forceAliasCreation: true,
    })
      .then(data => {
        this.onLogin(username, password, validationData);
      })
      .catch(err => {
        console.log(err);
        if (
          err.code === 'CodeMismatchException' ||
          err.code === 'ExpiredCodeException'
        ) {
          callBack('Error! Looks like you entered the wrong code');
        } else {
          callBack(err.message);
        }
      });
  };
  resendMFAVerificationCode = callBack => {
    const username = this.state.email;
    const password = this.state.password;
    const validationData = {
      gcaptchaResponse: null,
    };
    Auth.signIn({
      username, // Required, the username
      password, // Optional, the password,
      validationData,
    })
      .then(user => {
        this.setState({user: user});
        callBack(true);
      })
      .catch(err => {
        callBack(false, err.message);
      });
  };

  resendConformationVerificationCode = callBack => {
    const username = this.state.email;
    Auth.resendSignUp(username).catch(e => {
      callBack(e.message);
    });
  };

  onClickForgotPassword = () => {
    this.setState({forgotPassword: true});
  };
  sendResetPasswordCode = (email, callBack) => {
    axios
      .create()
      .get(
        BASE_URL_COGNITO +
          'get_user_attributes?email=' +
          encodeURIComponent(email),
        {},
      )
      .then(response => {
        if (response.data.email_verified === 'true') {
          Auth.forgotPassword(email)
            .then(data => {
              console.log(data);
              this.setState({passwordResetRequired: true, email: email});
            })
            .catch(error => {
              console.log(error);
              callBack(false, error.message);
            });
        } else {
          callBack(false, 'Email is not verified');
        }
      })
      .catch(error => {
        callBack(false, 'Error! Invalid Email');
      });
  };

  setRecaptchaValue = val => {
    this.setState({recaptchaValue: val});
  };

  handleBackToLoginPage = () => {
    const {userJournalDigestError} = this.props;
    userJournalDigestError(false);
  };

  render() {
    let fullHeight = this.state.windowHeight;
    let fullWidth = this.state.windowWidth;
    let minHeight = fullHeight;
    if (this.state.passwordResetRequired) {
      return (
        <LoginpasswordReset
          changePassword={this.changePassword}
          resendCode={this.resendCode}
          windowHeightView={fullHeight}
          windowHeightSmView={minHeight}
          windowWidthView={fullWidth}
          bgBlue={true}
          contentCenter={true}
          onPressLogin={() => {
            this.setState({
              passwordResetRequired: false,
              forgotPassword: false,
            });
          }}
        />
      );
    } else if (this.state.MFAVerification) {
      return (
        <LoginMFAVerification
          mfaVerification={this.mfaVerification}
          resendCode={this.resendMFAVerificationCode}
          windowHeightView={fullHeight}
          windowHeightSmView={minHeight}
          windowWidthView={fullWidth}
          bgBlue={true}
          contentCenter={true}
          CODEDELIVERYDESTINATION={
            this.state.user.challengeParam.CODE_DELIVERY_DESTINATION
          }
        />
      );
    } else if (this.state.userNotConfirmed) {
      return (
        <LoginUserConfirmation
          conformationVerification={this.conformationVerification}
          resendCode={this.resendConformationVerificationCode}
        />
      );
    } else if (this.state.forgotPassword) {
      return (
        <ResetPassword
          onPressLogin={() => {
            this.setState({forgotPassword: false});
          }}
          sendCode={this.sendResetPasswordCode}
          windowHeightView={fullHeight}
          windowHeightSmView={minHeight}
          windowWidthView={fullWidth}
          bgBlue={true}
          contentCenter={true}
        />
      );
    }

    return (
      <>
        {!this.props.userJournalDigestErrorFlag ? (
          <Login
            handlePasswordVisibility={this.handlePasswordVisibility}
            handleOnChangePassword={this.handleOnChangePassword}
            handleOnChangeEmail={this.handleOnChangeEmail}
            handleOnSubmitLogin={this.handleOnSubmitLogin}
            handleOnSignup={() =>
              this.props.resetSetupState(this.props.handleOnSignup)
            }
            authSuccessSpinner={this.state.authSuccessSpinner}
            disabledLogin={this.state.disabledLogin}
            onClickForgotPassword={this.onClickForgotPassword}
            onSubmitEditing={this.handleOnSubmitLogin}
            textInput={input => (this.textInput = input)}
            {...this.state}
            refScrollView={ScrollView => {
              this.ListView_Reference = ScrollView;
            }}
            toggleTickCallback={this.toggleTickCallback}
            staySigned={this.state.staySigned}
            touchIDIsSupported={false}
            hasCredentials={false}
            windowHeightView={fullHeight}
            windowHeightSmView={minHeight}
            windowWidthView={fullWidth}
            bgBlue={true}
            contentCenter={true}
            setRecaptchaValue={this.setRecaptchaValue}
            invalidLogingTryCountExceed={this.state.invalidLogingTryCountExceed}
            emailValidate={this.state.emailValidate}
            passwordValidate={this.state.passwordValidate}
          />
        ) : (
          <UserJournalDigestRequestErrorMessage
            bgBlue={true}
            backToLoginPage={this.handleBackToLoginPage}
            windowHeightView={fullHeight}
            windowHeightSmView={minHeight}
            windowWidthView={fullWidth}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorDescription: state.auth.errorDescription,
    error: state.auth.error,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    userJournalDigestErrorFlag: state.uistate.userJournalDigestErrorFlag,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (user, callBack, staySigned, isFromLogin) =>
      dispatch(actions.auth(user, callBack, staySigned, isFromLogin)),
    onFetachAllJournals: callBack =>
      dispatch(actions.fetchAllJournalEntries(callBack)),
    onSaveQuestionToAttach: question =>
      dispatch(actions.saveQuestionToAttach(question)),
    onLoginButtonPress: () => dispatch(actions.loginButtonPress()),
    resetSetupState: callBack => dispatch(actions.resetSetupState(callBack)),
    userJournalDigestError: status =>
      dispatch(actions.userJournalDigestError(status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginScreen);
