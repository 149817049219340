import React from "react";
import Highlighter from "../../modules/textHighlighter";

const highlighter = (props) => {
  const {searchWords, textToHighlight, highlightStyle, style, numberOfLines, nativeID} = props;
  return (
    <Highlighter
        highlightStyle={highlightStyle  
            ? highlightStyle
            : {
                backgroundColor: "#e5e5e5",
                fontWeight: "bold"
            }
        }
        autoEscape={true}
        searchWords={[searchWords]}
        textToHighlight={textToHighlight}
        style={style}
        allowFontScaling={false}
        numberOfLines={numberOfLines}
        nativeID={nativeID}
    />
  );
};

export default (highlighter);
