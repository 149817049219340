import React from 'react';
import {View, TouchableOpacity, Image} from 'react-native';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import UserAvatar from 'react-native-user-avatar';
import {getUserAvatarName} from '../../../util/commonUiLogic';

const userSection = props => {
  const {source, onError, redirectProfile, imageLoading, firstName, lastName} = props;
  const userAvatarName = getUserAvatarName(firstName, lastName);
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <View style={stylesRes.userSecView} dataSet={{media: ids.userSecView}}>
      <View style={[stylesRes.userImgView]}>
        <TouchableOpacity onPress={redirectProfile}>
          <View 
            nativeID={'headerProfile'}
            style={styles.profileAvatar}>
            {Boolean(imageLoading) && (
              <Image
                style={styles.profileAvatarDefault}
                resizeMode={'cover'}
                source={source}
                onError={onError}
              />
            )}

            {!Boolean(imageLoading) && (
              <UserAvatar
                size={40}
                name={userAvatarName.toUpperCase()}
                textColor={'#FF9E38'}
                bgColor={'rgb(255, 228, 200)'}
              />
            )}
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default (userSection);
