export const alertBody = 'Any unsaved changes will be lost.';
export const alertTitle =
  'Save changes to your Community profile before closing?';
export const caregiverTitle = 'Invite caregiver before closing?';
export const attachementTitle =
  'Save changes to your Attachment(s) before closing?';
export const clinicaltrialTitle = 'Complete your search before closing?';
export const contactTitle = 'Save changes to your Contact before closing?';
export const graphBody =
  'Any updates to your Graph will not be reflected in your Routine';
export const graphBodyCommon = 'Any unsaved changes will be lost.';
export const graphBeforeTile =
  'Confirm you want to save changes to your Graph?';
export const graphAfterTitle = 'Save changes to your Graph before closing?';
export const healthdataTitle =
  'Save changes to your Health Data before closing?';
export const healthlibraryBody = 'Any unsaved work will be lost.';
export const journalTitle =
  'Save changes to this entry before closing?';
export const medicationTitle =
  'Save changes to your Medication/Supplement before closing?';
export const activityTitle = 'Save changes to your Activity before closing?';
export const reportTitle = 'Save changes to your Report before closing?';
export const routineTitle = 'Save changes to your Routine before closing?';
export const routinereminderTitle =
  'Oops, your reminder is incomplete. Do you want to save and discard the reminder';
export const todoTitle = 'Save changes to your To-do before closing?';
export const alertBodySpec = 'Any unsaved work will be lost.';
export const profileTitle = 'Save changes to your profile before closing?';
export const qnoteTitle = 'Save changes to your Quick note before closing?';
export const symptomTitle = 'Save changes to your Symptoms before closing?';
export const healthJourneyTitle = 'Save changes to your Health Journey event before closing?';
export const profileCancelTitle = 'Save Changes before closing?';
