import React, { Component } from 'react';
import {View, Platform, ScrollView} from 'react-native';
import { connect } from 'react-redux';
import Header from '../../../components/Header/header';
import SpaceBar from '../../../components/SpaceBar/SpaceBar';
import PageContainer from '../../../components/PageLayout/PageContainer';
import ImageTakePhotoPopup from "../../../components/Attachment/AttachmentImageBoxUpdated/imageTakePhotoPopup";
import Cropper from '../../../components/ImageCropper/AmazingCropperPage';
import styles from '../../../Css';
import {HEALTH_JOURNEY} from '../../../navigation/path';
import * as actions from '../../../store/actions/index';
import * as constants from '../HealthJourneyConstant'
import {deleteFileByUri} from '../../../axios/common';
import { WEB, IOS } from '../../../util/platformWindowConfig';

class HealthJourneyAddAttchments extends Component {

  constructor(props) {
    super(props);
    this.state = {
      croppedUri: null,
      tempCapturedImages: [],
      cancelCropping: false,
    }
  }
  
  static getDerivedStateFromProps(props, state) {
    return null;
  }

  handleOnPressBackButton = (isFromSave = false, attachments) => {
    const {navigation, windowWidth, route} = this.props;
    let path = ""
    if (windowWidth < constants.WINDOW_MOBILE_WIDTH_MARGIN) {
      path = HEALTH_JOURNEY.addEventPath
    } else {
      path = HEALTH_JOURNEY.path
    }
    if (Platform.OS === IOS) {
      navigation.navigate(path, {
        from : route.name,
        attachments: Boolean(isFromSave) && attachments
      });
    } else {
      navigation.reset({
        index: 0,
        routes: [{ 
          name: path,
          params: {from : route.name}
        }],
      });
    }
  };

  onFinishCrop = (croppedImages, fileNamePrefix) => {
    const {eventData, setEventData} = this.props;
    const {attachments = []} = eventData;
    croppedImages.forEach((item, index) => {
      let fileName = fileNamePrefix;
      if (croppedImages.length > 1) {
        fileName = `${fileName}_Pages${index + 1}of${croppedImages.length}`;
      }
      if (Platform.OS === WEB) {
        const {cropImageUrl} = item;
        fileName = `${fileName}.png`;
        const file = new File([cropImageUrl], fileName, { type: cropImageUrl.type });
        const newFile = {
          tempId: Date.now() + index,
          file,
          fileName: fileName.replace(".png", ""),
          fileExtension: "png",
        };
        attachments.push(newFile);
      } else {
        const extension = item?.uri?.split(".").pop();
        fileName = `${fileName}.${extension}`;
        const file = {
          size: item.fileSize,
          name: fileName,
          uri: item.uri,
          type: item.type,
        };
        const newFile = {
          tempId: Date.now() + index,
          file,
          fileName: fileName.replace(`.${extension}`, ""),
          fileExtension: extension,
        }
        attachments.push(newFile);
      }
    });
    setEventData({
      ...eventData,
      attachments
    });
    this.handleOnPressBackButton(true, attachments); 
  }

  setImageToCrop = (image, tempCapturedImages) => {
    this.setState({
      tempCapturedImages: tempCapturedImages,
      image: image,
      croppedUri: image.uri
    });
  }

  openCroppingPage = () => {
    const {tempCapturedImages, image} = this.state;
    let tempImages = tempCapturedImages.filter(res => res.uri !== image.uri);
    this.setState({
      showCropper: true,
      cancelCropping: false, 
      tempCapturedImages: tempImages,
    });
  }

  closeCroper = (croppedUri, newWidth, newHeight) => {
    const {tempCapturedImages, image} = this.state;
    if (croppedUri) {
      //Remove temp image stored in cache
      if(this.state.croppedUri && this.state.croppedUri !== croppedUri) {
        deleteFileByUri(this.state.croppedUri);
      }
      this.setState({
        showCropper: false, 
        croppedUri: croppedUri,
        tempCapturedImages: [...tempCapturedImages, {...image, uri: croppedUri, height: newHeight, width: newWidth}],
        image: {...image, uri: croppedUri, height: newHeight, width: newWidth},
        cancelCropping: false,
      });
    } else {
      this.setState({
        showCropper: false,  
        croppedUri: this.state.image.uri,
        tempCapturedImages: [...tempCapturedImages, {...image}],
        cancelCropping: true,
      });
    }
  }

  render(){
    const {navigation, route} = this.props;
    const {croppedUri, tempCapturedImages, cancelCropping} = this.state;
    return (
      this.state.showCropper 
      ? <Cropper 
          image={this.state.image} 
          close={this.closeCroper} 
        />
      : <View style={styles.pageBodyWrapper}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={false}
          hideCaregiverAccess={true}
          handleOnPressBackButton={this.handleOnPressBackButton}
          index={HEALTH_JOURNEY.index}
          navigation={navigation}
          route={route}
          // showCropper={true}
          // handleOnPressCropButton={this.openCroppingPage}
        />
        <SpaceBar />
        <ScrollView keyboardShouldPersistTaps={'always'}>
          <View style={styles.gridSection}>
            <PageContainer smScreenFull={true}>
              <View
                nativeID="noborder"
                style={[styles.sortingListBlock, styles.borderThin, styles.uploadAttachmentThinBorder]}>
                <View style={[styles.uploadSection, styles.sortingPd, styles.uploadAttachmentPadding]}>
                  <ImageTakePhotoPopup
                    onCropAddImage={this.onFinishCrop}
                    artifactId={false}
                    onTakePhotoCancel={this.handleOnPressBackButton}
                    setImageToCrop={this.setImageToCrop}
                    croppedUri={croppedUri}
                    tempCapturedImages={tempCapturedImages}
                    cancelCropping={cancelCropping}
                  />
                </View>
              </View>
            </PageContainer>
          </View>
        </ScrollView>
      </View>
    );
  }
};

const mapStateToProps = state => {
  return {
    windowWidth: state.uistate.windowWidth,
    eventData: state.uistate.eventData,
    userDefinedSymptoms: state.routines.symptomsDigest.userSymptoms,
    openedYearAndId: state.uistate.openedYearAndId,
    journalUserId: state.uistate.journalUserId,
    fetchAllHealthJourneyEvents: state.healthJourney.fetchAllHealthJourneyEvents,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    setEventData: data => dispatch(actions.setEventData(data)),
    setOpenedYearAndId: data => dispatch(actions.setOpenedYearAndId(data)),
    onSelectSymptom: (selectedSymptom, callback) =>
      dispatch(actions.onSelectSymptom(selectedSymptom, callback)),
    fetchEvents: (journalUserId, callback) =>
      dispatch(actions.fetchAllHealthJourneyEvents(journalUserId, callback)),
    saveAndUpdateHealthJourneyEvent: (journalUserId, data, callback) =>
      dispatch(actions.saveAndUpdateHealthJourneyEvent(journalUserId, data, callback)),
    deleteHealthJourneyEvent: (journalUserId, id, callback) =>
      dispatch(actions.deleteHealthJourneyEvent(journalUserId, id, callback)),
    showSuccessPopup: (id, graph, data) => dispatch(actions.showSuccessPopup(id, graph, data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthJourneyAddAttchments);