import { Client, Conversation, Message, Participant } from '@twilio/conversations';
import _ from 'lodash';
import moment from 'moment';
import { EventRegister } from 'react-native-event-listeners';

const MAX_NUMBER_OF_CONNECTION_RETRIES = 4;
const DISCONNECTED_ERROR = "Twilsock has disconnected.";
const INITIALIZED = "initialized";
const INITIALIZED_FAILED = "initFailed";
const TOKEN_EXPIRED = "tokenExpired";
const TOKEN_ABOUT_TO_EXPIRE = "tokenAboutToExpire";
const TOKEN_ERROR_MESSAGE = 'Twilio token is null or undefined';
const TOKEN_CLIENT_ERROR_MESSAGE = 'Twilio client is null or undefined';

const WAIT_BETWEEN_RETRIES = 10000;

export class TwilioService {
  static serviceInstance;
  static chatClient;
  static getTokenFromAPI = () => void 0;
  static peerProfileId;

  static getInstance() {
    if (!TwilioService.serviceInstance) {
      TwilioService.serviceInstance = new TwilioService();
    }
    return TwilioService.serviceInstance;
  }

  static getToken() {
    return new Promise((resolve, reject) => TwilioService.getTokenFromAPI((flag, response) => {
      if (flag) {
        resolve(response.token)
      } else {
        reject(null)
      }
    }, TwilioService.peerProfileId))
  }

  static async sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  static async retryInitialize(getToken, peerProId, currentRetryCount) {
    await TwilioService.sleep(WAIT_BETWEEN_RETRIES);
    console.log("Retrying chat connection...")
    return TwilioService.initialize(getToken, peerProId, currentRetryCount + 1);
  }

  static async initialize(getToken, peerProId, currentRetryCount = 0) {

    TwilioService.getTokenFromAPI = getToken;
    TwilioService.peerProfileId = peerProId;

    try {
      const fpaToken = await TwilioService.getToken();
      TwilioService.chatClient = new Client(fpaToken);
      TwilioService.chatClient.on(INITIALIZED, () => {
        EventRegister.emitEvent(INITIALIZED, TwilioService.chatClient)
        TwilioService.addTokenListners(TwilioService.chatClient)
      });
      TwilioService.chatClient.on(INITIALIZED_FAILED, ({ error }) => {
        console.log("Twilio connection failure: " + JSON.stringify(error));
        if (currentRetryCount < MAX_NUMBER_OF_CONNECTION_RETRIES) {
          return TwilioService.retryInitialize(getToken, peerProId, currentRetryCount + 1);
        } else if (error && error.message !== DISCONNECTED_ERROR && currentRetryCount === MAX_NUMBER_OF_CONNECTION_RETRIES) {
          return EventRegister.emitEvent(INITIALIZED_FAILED, error.message)
        }
      });
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
    @param {Client} client
  */
  static addTokenListners(client) {
    client.on(TOKEN_ABOUT_TO_EXPIRE, async () => {
      const fpaToken = await TwilioService.getToken();
      client.updateToken(fpaToken);
    });

    client.on(TOKEN_EXPIRED, async () => {
      const fpaToken = await TwilioService.getToken();
      client.updateToken(fpaToken);
    });
  }

  async getChatClient(twilioToken) {
    if (!TwilioService.chatClient && !twilioToken) {
      throw new Error(TOKEN_ERROR_MESSAGE);
    }
    if (!TwilioService.chatClient && twilioToken) {
      TwilioService.chatClient = new Client(twilioToken)
      return TwilioService.chatClient;
    }
    return TwilioService.chatClient;
  }

  clientShutdown() {
    TwilioService.chatClient && TwilioService.chatClient.shutdown();
    TwilioService.chatClient = null;
  }

  addTokenListener(getToken) {
    if (!TwilioService.chatClient) {
      throw new Error(TOKEN_CLIENT_ERROR_MESSAGE);
    }
    TwilioService.chatClient.on(TOKEN_ABOUT_TO_EXPIRE, () => {
      getToken(() => TwilioService.chatClient.updateToken);
    });

    TwilioService.chatClient.on(TOKEN_EXPIRED, () => {
      getToken(() => TwilioService.chatClient.updateToken);
    });
    return TwilioService.chatClient;
  }

  parseConversations(conversations, profileId, connectionList, callBack) {
    let tempConversation = [];
    if (conversations && conversations.length > 0) {
      conversations.forEach(conversation =>
        this.parseConversation(
          conversation,
          profileId,
          connectionList,
          tempConversation,
          callBack,
        ),
      );
    } else {
      callBack(tempConversation);
    }
  }

  getRecentMessages = messages => {
    let totalMessages = {};
    if (messages[messages.length - 1]) {
      totalMessages = messages[messages.length - 1].state;
    }
    return totalMessages;
  };

  getAllMessages = messages => {
    let totalMessages = [];
    if (messages && messages.length > 0) {
      messages.forEach(res =>
        totalMessages.push({
          content: res.state.body,
          dateUpdated: res.state.dateUpdated,
        }),
      );
    }
    return totalMessages;
  };

  parseConversation = async (
    conversation,
    profileId,
    connectionList,
    conversationTempObj1,
    callBack,
  ) => {
    let lastMessageObj = {};
    let conversationTempObj = conversationTempObj1;
    let messageCount = 0;
    const conversationObj = this.getConversationDetails(
      conversation.uniqueName,
      profileId,
      connectionList,
    );
    await conversation.getMessagesCount().then(count => {
      messageCount = count;
    });
    conversation.getMessages().then(messages => {
      lastMessageObj = this.getRecentMessages(messages.items);
      const allMessages = this.getAllMessages(messages.items);

      if (conversationObj) {
        conversationTempObj.push({
          id: conversation.sid,
          name: conversationObj && conversationObj['userName'], //channel.friendlyName,
          createdAt: conversation.dateCreated,
          updatedAt: conversation.dateUpdated,
          lastMessageTime:
            conversation.lastMessage?.dateCreated ??
            conversation.dateUpdated ??
            conversation.dateCreated, //lastMessageObj.dateUpdated,
          isPrivate: conversation.isPrivate,
          profileImageS3SignedUrl: conversationObj && conversationObj['profileImageS3SignedUrl'],
          lastMessage: lastMessageObj.body,
          lastConsumedMessageIndex:
            conversation?.lastReadMessageIndex,
          author: lastMessageObj.attributes?.isSystemMessage
            ? 'system'
            : lastMessageObj.author,
          messageCount: messageCount, //messageCount,
          userName: conversationObj && conversationObj['userName'],
          userId: conversationObj && conversationObj['id'],
          sex: conversationObj && conversationObj['sex'],
          birthday: conversationObj && conversationObj['birthday'],
          medicalConditionsList:
            conversationObj && conversationObj['medicalConditionsList'],
          story: conversationObj && conversationObj['story'],
          connectionStatus:
            conversationObj && conversationObj['connectionStatus'],
          connectionId: conversationObj && conversationObj['connectionId'],
          allMessages: allMessages,
        });
        callBack(conversationTempObj);
      }
    });
  };

  getConversationDetails(uniqueName, profileId, connectionList) {
    let conversation = {};
    let ids = uniqueName.split(' ');
    if (profileId == ids[0]) {
      conversation = connectionList.find(connecion => connecion.id == ids[1]);
    } else {
      conversation = connectionList.find(connecion => connecion.id == ids[0]);
    }
    return conversation;
  }

  async parseMessages(messages) {
    // return messages.map(this.parseMessage).reverse();
    const updatedMessages = [];
    for (let i = 0; i < messages.length; i++) {
      const parsedMessage = await this.parseMessage(messages[i]);
      updatedMessages.push(parsedMessage)
    }
    return updatedMessages;
  }

  async parseMessage(message) {
    let lastConsumedMessageIndex = null;
    const participants = await message.conversation.getParticipants();
    participants.forEach(participant => {
      if (participant.sid !== message.sid) {
        lastConsumedMessageIndex = participant.lastReadMessageIndex;
      }
    })
    return {
      _id: message.sid,
      text: message.body,
      createdAt: message.dateCreated,
      user: {
        _id: message.author,
        name: message.author,
      },
      received: true, //To update msg delivered state
      sent: message.index < lastConsumedMessageIndex ? true : false, //To update msg read state
      index: message.index,
      memberSid: message.participantSid,
      system: message.attributes?.isSystemMessage,
    };
  }

  /**
    @param {Paginator<Conversation | Message>} paginator
    @param {Conversation[] | Message[]} flatternArray
    @returns {Promise<Conversation[] | Message[]>} Flatterned Conversations
  */
  static flatPaginators = async (paginator, flatternArray = []) => {
    try {
      flatternArray = [...flatternArray, ...paginator.items];
      if (!paginator.hasNextPage) return flatternArray;
      return TwilioService.flatPaginators(await paginator.nextPage(), flatternArray)
    } catch (error) {
      throw new Error(error)
    }
  }

  /**
    @param {Message} message
    @param {Participant} participant
    @returns {{}}
  */
  static getParsedMessage(message, participant) {
    return {
      _id: message.sid,
      text: message.body,
      createdAt: message.dateCreated,
      user: {
        _id: message.author,
        name: message.author,
      },
      received: true,
      sent: message.index <= participant?.lastReadMessageIndex ?? 0 ? true : false,
      index: message.index,
      memberSid: message.participantSid,
      system: message.attributes?.isSystemMessage,
    }
  }

  // Some users don't have identity
  static findOpponentDetails(currentPeerConnections, peerProfileId, participants, uniqueName) {
    const opponentDetails = currentPeerConnections.find(currentCon => currentCon.id?.toString() === participants.find(par => par.identity !== peerProfileId).identity);
    if (opponentDetails) return opponentDetails;
    if (!uniqueName) return null;
    return currentPeerConnections.find(currentCon => currentCon.id?.toString() === uniqueName.replace(peerProfileId, '').trim());
  }

  /**
    @param {Conversation} conversation
    @param {string} peerProfileId
    @param {{}[]} currentPeerConnections
    @returns {Promise<{}>}
  */
  static async getParsedConversation(conversation, peerProfileId, currentPeerConnections) {
    try {
      const messageCount = conversation.getMessagesCount();
      const unreadMessagesCount = await conversation.getUnreadMessagesCount();
      const participants = await conversation.getParticipants();
      const opponentDetails = TwilioService.findOpponentDetails(currentPeerConnections, peerProfileId, participants, conversation.uniqueName)
      if (!opponentDetails) return null;
      const messages = await TwilioService.flatPaginators(await conversation.getMessages(60, 0), []);
      const lastMessage = messages?.find(singleMessage => singleMessage.index === conversation.lastMessage.index);
      const participant = participants?.find(participant => (participant.identity !== "undefined" ? participant.identity : participant?.conversation?.uniqueName?.replace(peerProfileId, '')?.trim()) === opponentDetails['id']?.toString())
      const parsedMessages = messages.map(message => TwilioService.getParsedMessage(message, participant));
      const user = participant?.getUser()
      return {
        id: conversation.sid,
        name: opponentDetails?.userName, //channel.friendlyName,
        createdAt: conversation.dateCreated,
        updatedAt: conversation.dateUpdated,
        lastMessageTime:
          conversation.lastMessage?.dateCreated ??
          conversation.dateUpdated ??
          conversation.dateCreated, //lastMessageObj.dateUpdated,
        isPrivate: conversation.isPrivate,
        profileImageS3SignedUrl: opponentDetails?.profileImageS3SignedUrl,
        lastMessage: lastMessage.body,
        lastConsumedMessageIndex:
          conversation?.lastReadMessageIndex,
        author: lastMessage.attributes?.isSystemMessage
          ? 'system'
          : lastMessage.author,
        messageCount: await messageCount, //messageCount,
        userName: opponentDetails['userName'],
        userId: opponentDetails['id'],
        sex: opponentDetails['sex'],
        birthday: opponentDetails['birthday'],
        medicalConditionsList:
          opponentDetails['medicalConditionsList'],
        story: opponentDetails['story'],
        connectionStatus:
          opponentDetails['connectionStatus'],
        connectionId: opponentDetails['connectionId'],
        allMessages: parsedMessages,
        newText: unreadMessagesCount > 0 || unreadMessagesCount === null,
        status: (await user).isOnline,
        typing: false,
      };
    } catch (error) {
      throw new Error(error)
    }
  }


  /**
    @param {Conversation[]} conversations
    @param {string} peerProfileId
    @param {{}[]} currentPeerConnections
    @returns {Promise<{}[]>}
  */
  static async getReadableConversationObjects(conversations, peerProfileId, currentPeerConnections) {
    try {
      const parsedConversations = [];
      for (let index = 0; index < conversations.length; index++) {
        const parsedConversation = await TwilioService.getParsedConversation(conversations[index], peerProfileId, currentPeerConnections)
        parsedConversations.push(parsedConversation);
      }
      return _.compact(parsedConversations).sort((a, b) =>
        moment(b.lastMessageTime).diff(moment(a.lastMessageTime)),
      );
    } catch (error) {
      throw new Error(error)
    }
  }
}

