import React from 'react';
import {View, Text, Image} from 'react-native';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import styles from '../../Css';
import {journalSaveOptions} from '../../Constant/index';
import { pendoTrackEvent } from '../../util/pendoConfig';

const {Popover} = renderers;

const buttonPopupMenu = props => {
  return (
    <>
      {props.disabled ? (
        <View 
          style={[styles.itemIconSingle, styles.itemIconBtn]}>
          <Image
            style={[styles.playSmIcon]}
            source={require('../../assets/icons/play.png')}
          />
        </View>
      ) : (
        <Menu
          renderer={Popover}
          rendererProps={{
            placement: 'bottom',
          }}>
          <MenuTrigger onPress={() => pendoTrackEvent('Journal@ > SaveAccordion')}>
            <View 
              nativeID={'journalSaveAccordion'}
              style={[styles.itemIconSingle, styles.itemIconBtn]}>
              <Image
                style={[styles.playSmIcon]}
                source={require('../../assets/icons/play.png')}
              />
            </View>
          </MenuTrigger>
          <MenuOptions style={[styles.dropDownList, styles.menuOptionsLg]}>
              {Boolean(props.showCreateRoutine) && (
              <View style={{padding: 1}} nativeID={'journalSaveUpdateRoutine'}>
                <MenuOption
                  style={[styles.liItem, styles.viewItemPd]}
                  onSelect={() => props.onPressCreateRoutine(journalSaveOptions.saveAndUpdateRoutine)}>
                  <Text 
                    style={styles.listTextCom}
                    allowFontScaling= {false}
                  >
                    + Update this Routine
                  </Text>
                </MenuOption>
              </View>
            )}
            <View style={{padding: 1}} nativeID={!props.showCreateRoutine ? 'NewJournalSaveAddRoutine' : 'journalSaveCreateNewRoutine'}>
              <MenuOption
                style={[styles.liItem, styles.viewItemPd]}
                onSelect={() => props.onPressCreateRoutine(journalSaveOptions.saveAndCreateRoutine)}>
                <Text 
                  style={styles.listTextCom}
                  allowFontScaling= {false}
                >
                  + Create a New Routine
                </Text>
              </MenuOption>
            </View>
          </MenuOptions>
        </Menu>
      )}
    </>
  );
};
export default buttonPopupMenu;
