import React from "react";
import { View, Text, Image } from "react-native";
import styles from "../../../../Css";
import PrimaryButton from "../../../Button/primaryButton";

const getCriteriaTextArray = (criteriaText) => {
  let criteriaTextArray = ["Criteria:"];
  return criteriaTextArray.concat(criteriaText.split("| "));
};

const referFriendBlock = (props) => {
  const { windowWidth, onPressEditSearch, onPressReferFriend, criteriaText } =
    props;

  let criteriaTextArray = getCriteriaTextArray(criteriaText);
  return (
    <View style={[styles.gridSectionCom, styles.pdBotLg]}>
      <View style={[styles.secArea, styles.flexJcCtAiCt]}>
        <View
          style={[
            styles.textFullWrapper,
            styles.contPdTop,
            windowWidth > 767
              ? styles.titleBlockPdBotSpec
              : styles.titleBlockPdBotZero,
          ]}
        >
          <Text style={[styles.textTitleMdBold]} allowFontScaling= {false}>No Results Found</Text>
        </View>
      </View>

      <View
        style={[
          windowWidth > 767 ? styles.flexRow : styles.flexCol,
          styles.singleItemWrapper,
        ]}
      >
        <View
          style={[
            windowWidth > 767
              ? styles.singleItemWrapperMax
              : styles.singleItemWrapperFull,
          ]}
        >
          <View
            style={[
              styles.flexJcCtAiCt,
              windowWidth > 767 ? styles.singleItemPd : styles.singleItemPdSm,
            ]}
          >
            <View
              style={[
                styles.flexJcCtAiCt,
                styles.singleItemViewWrapper,
                windowWidth > 767
                  ? [styles.singleItemViewMin]
                  : styles.singleItemViewAuto,
              ]}
            >
              <View style={[styles.resultContentView]}>
                <View style={[styles.titleTopBotPd]}>
                  <Text style={[styles.textTitleMd, styles.textAlignCenter]} allowFontScaling= {false}>
                    Try changing your search criteria:
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.itemListBlock,
                  windowWidth > 767
                    ? [styles.itemListBlockLg, styles.flexJcCt]
                    : styles.itemListBlockSm,
                ]}
              >
                {criteriaTextArray.map((res) => {
                  return (
                    <View style={[styles.itemRowSingle]}>
                      <Text
                        style={[styles.textPrimeMdBold, styles.textlinkText]}
                        numberOfLines={1}
                        allowFontScaling= {false}
                      >
                        {res}
                      </Text>
                    </View>
                  );
                })}
              </View>
              <View style={[styles.contPdTop]}></View>
              <PrimaryButton
                btnText={"Edit Search"}
                touchableOnPress={onPressEditSearch}
                btnStyle={[styles.submitBgOrange, styles.btnResult]}
                btnShadow={false}
              />
            </View>
          </View>
        </View>

        <View
          style={[
            windowWidth > 767
              ? styles.singleItemWrapperMax
              : styles.singleItemWrapperFull,
          ]}
        >
          <View
            style={[
              styles.flexJcCtAiCt,
              windowWidth > 767 ? styles.singleItemPd : styles.singleItemPdSm,
            ]}
          >
            <View
              style={[
                styles.flexJcCtAiCt,
                styles.singleItemViewWrapper,
                windowWidth > 767
                  ? styles.singleItemViewMin
                  : styles.singleItemViewAuto,
              ]}
            >
              <View style={[styles.resultContentView]}>
                <View style={[styles.titleTopBotPd]}>
                  <Text style={[styles.textTitleMd, styles.textAlignCenter]} allowFontScaling= {false}>
                    Build your own Community
                  </Text>
                </View>
              </View>

              <View style={[styles.infoPdBotSm]}>
                <Image
                  style={styles.referFriendIcon}
                  source={require("../../../../assets/peer-to-peer/refer-friend.png")}
                />
              </View>
              <View style={[styles.contPdTop]}></View>

              <PrimaryButton
                btnText={"Refer a Friend"}
                touchableOnPress={onPressReferFriend}
                btnStyle={[styles.submitBgBlue, styles.btnResult]}
                btnShadow={false}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
export default (referFriendBlock);
