import React from "react";
import { View, Image, Platform, Text } from "react-native";
import PageContainer from "../../../PageLayout/PageContainer";
import styles from "../../../../Css";
import Header from "../../../Header/header";
import SpaceBar from "../../../SpaceBar/SpaceBar";
import CardItemHeader from "../../Card/CardItem";
import { GiftedChat } from '../../../../modules/react-native-gifted-chat';
// import { GiftedChat } from 'react-native-gifted-chat';
import DisconnectPopup from '../../ConfirmationBox/disconnectPopup';
import BlockPopup from '../../ConfirmationBox/blockPopup';
import ReportPopup from '../../ConfirmationBox/reportPopup';
import SuccessMessagePopup from '../../ConfirmationBox/successMessagePopup';
import ActivityIndicator from '../../../ActivityIndicator/activityIndicator';

const IndividualChatComponent = (props) => {
  const { 
    index, 
    navigation, 
    handleOnPressBackButton ,
    userId,
    messages, 
    onSend,
    peerProfile,
    windowWidth,
    onSelectActionStatus,
    visibleDisconnectPopup,
    visibleBlockPopup,
    visibleReportPopup,
    visibleSuccessMessage,
    hidePopup,
    selectedUser,
    onDisconnectConversation,
    disconnectWait,
    onBlockConversation,
    blockWait,
    onReportConversation,
    reportWait,
    successMessage,
    successMessageTitle,
    onScroll,
    scrollHeight,
    onInputTextChanged,
    messageText,
    onKeyboardWillShow,
    isKeyboardShow,
    route,
    loading,
    disableHeader,
    isFromSearch,
    messageIndex
  } = props;

  const typingIndicator = () => {
    return (
      <View>
        {Boolean(peerProfile.typing) && (
          <Text>Typing</Text>
        )}
      </View>
    )
  }

  return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showHome={false}
        showNotify={true}
        hideCaregiverAccess={true}
        handleOnPressBackButton={handleOnPressBackButton}
        index={index}
        navigation={navigation}
        route={route}
      />
      <SpaceBar />
      <PageContainer smScreenFull={true}>
        <View style={[styles.secArea, styles.colPdTopCom, styles.colPdBotLg]}>
          <View style={[styles.gridRow, styles.colPdLtRt]}>
          {loading ? (
            <ActivityIndicator pdLarge={true} />
          ) : (
            <View style={styles.gridColFull}>
              <View style={[styles.chatItemSection]} pointerEvents={disableHeader ? "none" : "auto"}>
                <CardItemHeader
                  peerProfile={peerProfile}
                  displayInfo={true}
                  displaySettingsButton={true}
                  onSelectActionStatus={onSelectActionStatus}
                  scrollHeight={scrollHeight}
                  messageText={messageText}
                  isKeyboardShow={isKeyboardShow}
                  chatScreen={true}
                  displayOnlineOffline={true}
                />
              </View>
            </View>
          )}
          </View>
        </View>
      </PageContainer>
      <GiftedChat
        messages={messages.sort((a, b) => a.index - b.index)}
        // isTyping={peerProfile.typing && false}
        // renderAvatarOnTop
        onSend={(messages) => onSend(messages)}
        user={{ _id: userId.toString() }}
        placeholder={'Write a message'}
        placeholderTextColor={'#98989a'}
        renderAvatar={null}
        windowWidth = {windowWidth}
        children={
          <View style={styles.chatSendMainWrapper}>
            <View
              style={styles.chatSendIconWrapper}
            >
              <Image style={styles.chatSendIcon} source={require("../../../../assets/icons/chat-send.png")}/>
            </View>
          </View>
        }
        onScroll={(scrollHeight) => onScroll(scrollHeight)}
        onInputTextChanged={(text) => onInputTextChanged(text)}
        onKeyboardWillShow={(isShow) => onKeyboardWillShow(isShow)}
        wrapInSafeArea={false}
        // inverted={false}
        // renderChatFooter={typingIndicator}
        isFromSearch={isFromSearch}
        messageIndex={messageIndex}
      />
      {visibleDisconnectPopup &&
        <DisconnectPopup 
          visibleModal={visibleDisconnectPopup}
          hidePopup={hidePopup}
          selectedUser={selectedUser}
          onDisconnect={onDisconnectConversation}
          wait={disconnectWait}
        />
      }
      {visibleBlockPopup &&
        <BlockPopup 
          visibleModal={visibleBlockPopup}
          hidePopup={hidePopup}
          selectedUser={selectedUser}
          onBlock={onBlockConversation}
          wait={blockWait}
        />
      }
      {visibleReportPopup &&
        <ReportPopup 
          visibleModal={visibleReportPopup}
          hidePopup={hidePopup}
          selectedUser={selectedUser}
          onReport={onReportConversation}
          wait={reportWait}
        />
      }
      {visibleSuccessMessage &&
        <SuccessMessagePopup 
          visibleModal={visibleSuccessMessage}
          hidePopup={hidePopup}
          message={successMessage}
          messageTitle={successMessageTitle}
          selectedUser={selectedUser}
        />
      }
     </View>
  );
};

export default (IndividualChatComponent);
