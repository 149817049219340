import React from 'react';
import {View, Image, TouchableOpacity, Text} from 'react-native';
import styles from '../../../Css';
import {getRoutineImage} from '../../../util/commonUiLogic';

const contentBlock = props => {
  const {
    entryType,
    onPressRoutine,
    nativeId,
  } = props;
  return (
    <TouchableOpacity
        key={entryType.id}
        onPress={() => onPressRoutine()}
        style={[styles.barBotSpSm]}>
        <View
            style={[
                styles.menuSingleBar,
                styles.menuBgWhite,
                styles.barShadow,
                styles.smRad,
            ]}
            nativeID={nativeId}
        >
            <View
                style={[
                styles.menuSingleIconBlock,
                styles.barPd,
                ]}>
                <View style={[
                    styles.journalEntryNwBx,
                    styles.flexJcCt,
                    styles.flexAiCt,
                    {backgroundColor: entryType.color}
                ]}>
                    <Image
                        style={entryType?.icon_c ? styles.journalEntryNwBx : styles.routineIcon}
                        source={getRoutineImage(
                            entryType.userCanEdit,
                            entryType.name,
                            entryType.icon_c
                        )}
                    />
                </View>
            </View>
            <View style={styles.menuSingleContBlock}>
                <View style={styles.menuSingleTextView}>
                <Text style={styles.menuSingleText} allowFontScaling={false}>
                    {entryType.name === 'New Journal Entry' ? 'New Entry' : entryType.name}
                </Text>
                </View>
            </View>
        </View>
    </TouchableOpacity>
  );
};

export default (contentBlock);
