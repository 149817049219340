import React from 'react';
import {ScrollView, View} from 'react-native';
import styles from '../../Css';

const nestedScroll = props => {
  return (
    <React.Fragment>
      {Boolean(props.customScrollWeb) ? (
        <View
          style={[styles.customScrollNested, styles.itemsContainerList]}
          nativeID={props.scrollID}>
          {props.children}
        </View>
      ) : (
        <ScrollView>{props.children}</ScrollView>
      )}
    </React.Fragment>
  );
};

export default (nestedScroll);
