import React, {useEffect, useRef} from "react";
import { Image, View, Text, TouchableOpacity, Animated, Easing } from "react-native";
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';

const HealthJourneyYearCollapse = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const { year, onPress, open, maxTouchableWidth = false } = props;
  const spinValue = useRef(undefined);
  const spin = spinValue?.current?.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-90deg'],
  });

  useEffect(() => {
    if (spinValue.current) {
      Animated.timing(spinValue.current, {
        toValue: open ? 0 : 1,
        duration: 160,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start();
    } else {
      spinValue.current = new Animated.Value(open ? 0 : 1);;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  return (
    <View style={[stylesRes.pdMbTwentyDecZero]} dataSet={{media: ids.pdMbTwentyDecZero}}>
      <TouchableOpacity onPress={() => onPress(year)} style={[maxTouchableWidth ? styles.touchableAndWdMax : styles.touchableAndWd]}>
        <View>
          <View style={[styles.flexJcFs, styles.flexRow, styles.flexAiCt, styles.yearCollapsHg, styles.barTopSp]}>
            <View>
              <Text style={[styles.textTitleExLgBold, styles.seaBluTxtColor]}> 
                {year}
              </Text>
            </View>
            <Animated.View key={year} style={[styles.blueDropDownArrowPs, {transform: [{ rotate: spin ? spin : open ? '0deg' : '270deg'}]}, open && {bottom: 4}]}>
              <Image
                style={styles.blueDropDownArrowWd}
                source={require('../../../assets/health-journey-icons/dropdown-arrow-blue.png')}
              />
            </Animated.View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default HealthJourneyYearCollapse;
