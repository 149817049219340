import * as actionTypes from './actionTypes';
import * as axiosCommon from '../../axios/axios-common';
import * as urls from './urls';
import {
  commonErrorHandler,
  logout,
} from './index';


export const getTimeZoneList = callBack => {
  return dispatch => {
    dispatch({
        type: actionTypes.GET_TIME_ZONE_LIST,
    });
    const url = urls.GET_TIME_ZONE_LIST;

    axiosCommon.axiosGET(
        url, 
        (res) => {
      if (res.type === 'SUCCESS') {
        callBack(true, res.responseDto.data)
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; getTimeZoneList");
          dispatch(logout());
        } else if (res.errorDto !== 'undefined') {
            callBack(false)
        }
      }
    });
  };
};

export const updateTimeZone = (timeZone, callback, journalUserId) => {
    return (dispatch) => {
      dispatch({
        type: actionTypes.UPDATE_TIME_ZONE,
      });
      let url = urls.UPDATE_TIME_ZONE_LIST;
      if (journalUserId) {
        url += '?journalUserId=' + journalUserId;
      }
      axiosCommon.axiosPUT(
        url,
        (res) => {
          if (res.type === "SUCCESS") {
            // dispatch(getTimeZoneList(callback));
            dispatch({
              type: actionTypes.UPDATE_TIME_ZONE_SUCCESS,
              payload: res.responseDto.data,
            });
            callback(true, res.responseDto.data);
          } else if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; updateTimeZone");
            dispatch(logout());
          } else {
            dispatch({
              type: actionTypes.UPDATE_TIME_ZONE_FAIL,
              payload: res.errorDto.data.error || 'Failed to update timezone',
            });
            dispatch(commonErrorHandler(res.errorDto.data.error || 'Failed to update timezone'));
            callback(false)
          }
        },
        {timezone: timeZone}
      );
    };
  };
