import React, {Component} from 'react';
import {View, Platform} from 'react-native';
import styles from '../../../Css';
import {connect} from 'react-redux';
import {tabs} from '../../../Constant';
import * as actions from '../../../store/actions/index';
import {CollapseWrapper, CollapseItemTitle} from '../../TabCollapse/';

import {
  HealthDataTabContent,
  MedicationsTabContent,
  ProfileTabContent,
  RoutineTabContent,
  SymptomTabContent,
} from './TabContent';

import SetupModal from './SetupModal';
import { toggleSetupModal } from '../../../store/actions/uistate';
import {ORGANIZATION_NAME} from '../../../util/commonUiConfig';
import { TAB_BREAk_POINT, TAB_BREAk_POINT_START, WEB } from '../../../util/platformWindowConfig';

class Setup extends Component {
  constructor(props) {
    super(props);
    const {windowWidth, activeTab, tabMenuIconView, tabDevice} = props;
    this.state = {
      windowWidth: tabDevice && !tabMenuIconView
                    ? windowWidth - 195
                    : windowWidth,
      activeTab: activeTab ? activeTab : tabs.profile,
      tabMenuIconView: tabMenuIconView,
      tabDevice: tabDevice
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {windowWidth, tabMenuIconView, tabDevice, activeTab} = props;
    if (windowWidth !== state.windowWidth ||
      tabMenuIconView !== state.tabMenuIconView ||
      tabDevice !== state.tabDevice ||
      activeTab !== state.activeTab
    ) {
      return {
        windowWidth: tabDevice && !tabMenuIconView
                      ? windowWidth - 195
                      : windowWidth,
        tabMenuIconView: tabMenuIconView,
        tabDevice: tabDevice,
        activeTab: activeTab,
      };
    }
    return null;
  }

  setActiveTab = tabId => {
    this.setState({activeTab: tabId}, () => {
      const {setActiveTab} = this.props;
      setActiveTab(tabId);
    });
  };

  openModal = () => {
    const {redirectSetup} = this.props;

    if (Platform.OS === WEB) {
      //this.setState({visibleModal: true});
      this.props.toggleSetupModal(true);
      // redirectSetup();
    } else {
      redirectSetup();
    }
  };

  closeModal = () => {
    const {redirectDashboard} = this.props;

    if (Platform.OS === WEB) {
      //this.setState({visibleModal: false});
      this.props.toggleSetupModal(false);
      // redirectDashboard();
    } else {
      redirectDashboard();
    }
  };

  render() {
    const {windowWidth, activeTab} = this.state;
    const {
      conditions = [],
      userConditions = [],
      treatments = [],
      userTreatments = [],
      symptoms = [],
      userSymptoms = [],
      metrics = [],
      userDefinedMetrics = [],
      journalEntryTypes = [],
      hideSetup,
      visibleModal
    } = this.props;

    const userJournalEntryTypes = journalEntryTypes.filter(
      res => res.userCanEdit && res.active,
    );

    return (
      <View style={[styles.setupSectionView, styles.infoPdBotLg]}>
        <CollapseWrapper
          title={`${ORGANIZATION_NAME} Routine Wizard`}
          touchableOnPress={() =>
            hideSetup(this.props.journalUserId, () => {})
          }
          windowWidth={windowWidth}>
          <View
            style={[
              windowWidth > TAB_BREAk_POINT_START
                ? styles.tabVerticalWrapper
                : styles.tabHorizontalWrapper,
            ]}>
            {windowWidth > TAB_BREAk_POINT_START && (
              <View style={[styles.tabVerticalTitleWrapper]}>
                <CollapseItemTitle
                  itemTitle={'Medical Condition(s)'}
                  activeTab={activeTab === tabs.profile}
                  windowWidth={windowWidth}
                  onTabPress={() => this.setActiveTab(tabs.profile)}
                  isDone={
                    (conditions && conditions.length > 0) ||
                    (userConditions && userConditions.length > 0)
                  }
                />
                <CollapseItemTitle
                  itemTitle={'Medications, Supplements & Activites'}
                  activeTab={activeTab === tabs.medications}
                  windowWidth={windowWidth}
                  onTabPress={() => this.setActiveTab(tabs.medications)}
                  isDone={
                    (treatments && treatments.length > 0) ||
                    (userTreatments && userTreatments.length > 0)
                  }
                />
                <CollapseItemTitle
                  itemTitle={'Symptom(s)'}
                  activeTab={activeTab === tabs.symptom}
                  windowWidth={windowWidth}
                  onTabPress={() => this.setActiveTab(tabs.symptom)}
                  isDone={
                    (symptoms && symptoms.length > 0) ||
                    (userSymptoms && userSymptoms.length > 0)
                  }
                />
                <CollapseItemTitle
                  itemTitle={'Track your Health Data'}
                  activeTab={activeTab === tabs.healthData}
                  windowWidth={windowWidth}
                  onTabPress={() => this.setActiveTab(tabs.healthData)}
                  isDone={
                    (metrics && metrics.length > 0) ||
                    (userDefinedMetrics && userDefinedMetrics.length > 0)
                  }
                />
                <CollapseItemTitle
                  itemTitle={'Create a new Routine'}
                  lastChild={true}
                  activeTab={activeTab === tabs.routine}
                  windowWidth={windowWidth}
                  onTabPress={() => this.setActiveTab(tabs.routine)}
                  isDone={
                    userJournalEntryTypes && userJournalEntryTypes.length > 0
                  }
                />
              </View>
            )}

            <View style={[styles.tabCollapseContentBlock]}>
              {windowWidth < TAB_BREAk_POINT && (
                <CollapseItemTitle
                  itemTitle={'Medical Condition(s)'}
                  activeTab={activeTab === tabs.profile}
                  windowWidth={windowWidth}
                  onTabPress={() => this.setActiveTab(tabs.profile)}
                  isDone={
                    (conditions && conditions.length > 0) ||
                    (userConditions && userConditions.length > 0)
                  }
                />
              )}

              {activeTab === tabs.profile && (
                <ProfileTabContent
                  windowWidth={windowWidth}
                  onPressSetupNow={this.openModal}
                />
              )}

              {windowWidth < TAB_BREAk_POINT && (
                <CollapseItemTitle
                  itemTitle={'Medications, Supplements & Activites'}
                  activeTab={activeTab === tabs.medications}
                  windowWidth={windowWidth}
                  onTabPress={() => this.setActiveTab(tabs.medications)}
                  isDone={
                    (treatments && treatments.length > 0) ||
                    (userTreatments && userTreatments.length > 0)
                  }
                />
              )}

              {activeTab === tabs.medications && (
                <MedicationsTabContent
                  windowWidth={windowWidth}
                  onPressSetupNow={this.openModal}
                />
              )}

              {windowWidth < TAB_BREAk_POINT && (
                <CollapseItemTitle
                  itemTitle={'Symptom(s)'}
                  activeTab={activeTab === tabs.symptom}
                  windowWidth={windowWidth}
                  onTabPress={() => this.setActiveTab(tabs.symptom)}
                  isDone={
                    (symptoms && symptoms.length > 0) ||
                    (userSymptoms && userSymptoms.length > 0)
                  }
                />
              )}
              {activeTab === tabs.symptom && (
                <SymptomTabContent
                  windowWidth={windowWidth}
                  onPressSetupNow={this.openModal}
                />
              )}

              {windowWidth < TAB_BREAk_POINT && (
                <CollapseItemTitle
                  itemTitle={'Track your Health Data'}
                  activeTab={activeTab === tabs.healthData}
                  windowWidth={windowWidth}
                  onTabPress={() => this.setActiveTab(tabs.healthData)}
                  isDone={
                    (metrics && metrics.length > 0) ||
                    (userDefinedMetrics && userDefinedMetrics.length > 0)
                  }
                />
              )}
              {activeTab === tabs.healthData && (
                <HealthDataTabContent
                  windowWidth={windowWidth}
                  onPressSetupNow={this.openModal}
                />
              )}

              {windowWidth < TAB_BREAk_POINT && (
                <CollapseItemTitle
                  itemTitle={'Create a new Routine'}
                  lastChild={true}
                  activeTab={activeTab === tabs.routine}
                  windowWidth={windowWidth}
                  onTabPress={() => this.setActiveTab(tabs.routine)}
                  isDone={
                    userJournalEntryTypes && userJournalEntryTypes.length > 0
                  }
                />
              )}
              {activeTab === tabs.routine && (
                <RoutineTabContent
                  windowWidth={windowWidth}
                  onPressSetupNow={this.openModal}
                />
              )}
            </View>
          </View>
        </CollapseWrapper>
        {Platform.OS === WEB &&
          <SetupModal
            visibleModal={visibleModal}
            closeModal={this.closeModal}
            activeTab={activeTab}
            tabs={tabs}
            setActiveTab={this.setActiveTab}
          />
        }
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
    windowWidth: state.uistate.windowWidth,
    conditions: state.setup.conditions,
    userConditions: state.routines.userConditions,
    treatments: state.setup.treatments,
    userTreatments: state.routines.treatments,
    symptoms: state.setup.symptoms,
    userSymptoms: state.routines.symptomsDigest
      ? state.routines.symptomsDigest.userSymptoms
      : [],
    metrics: state.setup.metrics,
    userDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.userDefinedMetrics
      : [],
    journalEntryTypes: state.routines.journalEntryTypes,
    activeTab: state.setup.activeTab,
    tabMenuIconView: state.uistate.tabMenuIconView,
    tabDevice: state.uistate.tabDevice,
    visibleModal: state.uistate.setupModalOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setActiveTab: value => dispatch(actions.setSetupActiveTab(value)),
    hideSetup: (journalUserId, callBack) =>
      dispatch(actions.hideSetup(journalUserId, callBack)),
    toggleSetupModal: (val) => dispatch(toggleSetupModal(val))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Setup);
