import React, { Component } from "react";
import { View } from "react-native";
import stylesResponsive from "../../CssResponsive";
import { connect } from "react-redux";
import { isHaveTimeZoneConflict } from "../../util/commonUiLogic";
import { TIME_ZONE_BANNER_HEIGHT_BREAK_POINT } from "../../util/platformWindowConfig";
class SpaceBar extends Component {
  render() {
    const {timeZone, timeZoneWarning, journalUserId, windowWidth} = this.props;
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const headingHeights = {
      HEADER: 57,
      JOURNAL_USER: 31,
      TIMEZONE_WARNING: windowWidth < TIME_ZONE_BANNER_HEIGHT_BREAK_POINT ? 80 : 57
    };
    let spaceBarHeight = headingHeights.HEADER;
    if (journalUserId) {
      spaceBarHeight += headingHeights.JOURNAL_USER;
    }
    if (!Boolean(journalUserId) && !Boolean(timeZoneWarning) && isHaveTimeZoneConflict(timeZone)) {
      spaceBarHeight += headingHeights.TIMEZONE_WARNING;
    }
    return (
        <View style={stylesRes.spaceBarFull}>
            <View style={{paddingTop: spaceBarHeight}}></View>
        </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    timeZoneWarning: state.uistate.timeZoneWarning,
    timeZone: state.routines?.user?.timezone,
    journalUserId: state.uistate.journalUserId,
    windowWidth: state.uistate.windowWidth
  };
};

export default connect(
  mapStateToProps,
  null
)(SpaceBar);
