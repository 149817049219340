import React, {Component} from 'react';
import ImagePreview from '../../components/ImagesDocuments/ImagePreview';
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import * as axiosCommon from '../../axios/axios-common';
import * as url from '../../store/actions/urls';
import {View, Platform} from 'react-native';
import {deleteAttachmentOcr} from '../../store/actions/ocr';
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import {  WEB } from "../../util/platformWindowConfig";

class ImagesDocsSorter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachmentList: [],
      loading: false,
    };
  }

  renderImagePreview = () => {
    if (this.state.attachmentList) {
      return this.state.attachmentList.map(attachment => {
        return (
          <ImagePreview
            key={attachment.id}
            attachmentId={attachment.id}
            fileName={attachment.filename}
            redirectPage={this.props.redirectPage}
            //artifactId={this.props.artifact}
            artifactId={attachment.userArtifact}
            mimetype={attachment.mimetype}
            uploadDate={attachment.uploadDate}
            iconUrl={attachment.iconUrl}
            //flagged={this.props.flagged}
            flagged={attachment.flagged}
            //notJournal={this.props.notJournal}
            deleteAttachment={this.deleteAttachment}
            downloadAttachment={this.props.downloadAttachment}
            viewAttachment={this.props.viewAttachment}
            disableText={attachment.status !== 'ENABLED'}
            fileSize={attachment.filesize}
            fromHealthJourney={this.props.fromHealthJourney}
            selectedItems={this.props.selectedItems}
            preSelectedItems={this.props.preSelectedItems}
            updateSelectedItems={this.props.updateSelectedItems}
          />
        );
      });
    }
  };

  renderFilterImagePreview = () => {
    if (this.state.attachmentList) {
      return this.state.attachmentList.map(attachment => {
        return (
          Boolean(this.props.ocrData[attachment.id]) && (
            <ImagePreview
              key={attachment.id}
              attachmentId={attachment.id}
              fileName={attachment.filename}
              redirectPage={this.props.redirectPage}
              //artifactId={this.props.artifact}
              artifactId={attachment.userArtifact}
              mimetype={attachment.mimetype}
              uploadDate={attachment.uploadDate}
              iconUrl={attachment.iconUrl}
              //flagged={this.props.flagged}
              flagged={attachment.flagged}
              //notJournal={this.props.notJournal}
              deleteAttachment={this.deleteAttachment}
              downloadAttachment={this.props.downloadAttachment}
              searchWordContainingSentences={
                this.props.ocrData[attachment.id].searchWordContainingSentences
              }
              filter={this.props.filter}
              viewAttachment={this.props.viewAttachment}
              disableText={attachment.status !== 'ENABLED'}
              fileSize={attachment.filesize}
              fromHealthJourney={this.props.fromHealthJourney}
              selectedItems={this.props.selectedItems}
              preSelectedItems={this.props.preSelectedItems}
              updateSelectedItems={this.props.updateSelectedItems}
            />
          )
        );
      });
    }
  };

  deleteAttachment = (id, fileName, fileSize, userArtifact) => {
    const callBack = success => {
      let newAttachmentList;
      if (success) {
        newAttachmentList = this.state.attachmentList.filter(
          item => item.id !== id,
        );
        this.props.onSetTotalAttachmentSize();
        const attachmentCategory = this.props.getAttachmentCategory(
          userArtifact//this.props.artifact,
        );
        deleteAttachmentOcr({
          fileName: fileName,
          attachmentId: id.toString(),
          attachmentCategory: attachmentCategory,
          userId: this.props.userId.toString(),
        });
        this.setState({attachmentList: newAttachmentList});
      } else {
      }
    };
    this.props.onDeleteAttachment(
      id,
      userArtifact,//this.props.artifact,
      this.props.attachmentsCompleteDigest,
      callBack,
      fileSize
    );
  };

  getInitAttachments(){
    const callBack = response => {
      let attachmentList;
      if (response.type === 'SUCCESS') {
        attachmentList = [
          ...response.responseDto.data.attachments.sort(function(a, b) {
            return b.uploadDate - a.uploadDate;
          }),
        ];
      } else {
      }

      this.setState({attachmentList, loading: false});
    };
    this.setState({loading: true});
    axiosCommon.axiosPOST(
      url.FETCH_USER_ATTACHMENTS_LIST,
      callBack,
      {artifactIds: this.props.userArtifactList}
    );   
  }

  componentDidMount() {
     this.getInitAttachments();
  }

  componentDidUpdate(prevProps){
    const callBack = response => {
      let attachmentList;
      if (response.type === 'SUCCESS') {
        attachmentList = [
          ...response.responseDto.data.attachments.sort(function(a, b) {
            return b.createdOn - a.createdOn;
          }),
        ];
      } else {
      }
      if (this.state.attachmentList.length !== attachmentList.length) {
        this.setState({attachmentList});
      }
    };

    if (this.props.artifact) {
      axiosCommon.axiosGET(
        url.FETCH_USER_ATTACHMENTS + this.props.artifact,
        callBack,
      );
    }

    if(prevProps.attachmentUploadStatus !== this.props.attachmentUploadStatus && Platform.OS === WEB ){
      this.getInitAttachments();
    }
  }

  render() {
    return (
      <View>
        {this.state.loading 
        ? <ActivityIndicator pdLarge={true} />
        : this.props.filterOn
          ? this.renderFilterImagePreview()
          : this.renderImagePreview()
        }
      </View>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAttachment: (
      attachmentId,
      artifactId,
      attachmentsCompleteDigest,
      callBack,
      fileSize
    ) =>
      dispatch(
        actions.deleteAttachment(
          attachmentId,
          artifactId,
          attachmentsCompleteDigest,
          callBack,
          fileSize
        )
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ImagesDocsSorter);
