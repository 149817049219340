import React from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Platform
} from 'react-native';
import { connect } from 'react-redux';
import styles from '../../../../Css';
import TickButton from '../../../../components/CheckBox/CheckBox';

const AdvanceFilterExpandableComponent = (props) => {
    const { item, checkedItem, fromMobileFilter } = props;

    //Custom Component for the Expandable List
    return (
        <React.Fragment>
            <View style={[styles.flexRow, styles.flexJcFs, styles.flexAiCt]}>
                {/*Header of the Expandable List Item*/}
                <View>
                    <View style={[styles.flexRow]}>
                        <TouchableOpacity
                            activeOpacity={0.8}
                            onPress={()=> checkedItem(!item.isChecked, item.id, item.category_name)}>
                            <View style={[styles.flexRow, styles.ctgryListRtLtTpBt, fromMobileFilter && Platform.OS === "web" && styles.wdEightyPerc]}>
                              <View style={styles.wdTenPerc}>
                                {Boolean(item.isChecked) ? (
                                    <TickButton
                                        imagePath={'tickButton'}
                                        toggleTickCallback={() => checkedItem(!item.isChecked, item.id, item.category_name)}
                                        tickStyle={{ marginRight: 0, top: '10%' }}
                                    />
                                ) : (
                                    <TickButton
                                        imagePath={'untickButton'}
                                        toggleTickCallback={() => checkedItem(!item.isChecked, item.id, item.category_name)}
                                        tickStyle={{ marginRight: 0, top: '10%' }}
                                    />
                                )}
                              </View>
                              <View style={styles.wdNinetyPerc}>
                                <Text style={[styles.textPrime, styles.flexAiFs, Platform.OS === "web" ? styles.textPadding : styles.collapseContLgPd]} numberOfLines={1}>
                                    {item.category_name}
                                </Text>
                              </View>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <View style={[styles.dateFilterLine]}></View>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        resetComponents: state.healthJourneyFiltering.resetComponents,
    };
};

const mapDispatchToProps = dispatch => {
    return { };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceFilterExpandableComponent);