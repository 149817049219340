import React from 'react';
import { TextInput, View, Platform, Text } from 'react-native'
import QuestionTitle from '../../../../components/QuestionTitle/QuestionTitle';
import QuestionImage from '../QuestionImage';
import stylesCss from './QuestionLongAnswerStyle';
import styles from '../../../../Css';
import QuestionWarning from '../../../../components/QuestionTitle/QuestionWarning';

const QuestionLongAnswer = ({ title, imageUrl, questionIndex, answer = '', isReadOnly, onChangeText, required, requiredWarning, onSkip }) => (
  <>
    {Boolean(imageUrl) && <View style={[stylesCss.questionImageWrapper]}>
      <QuestionImage imageUrl={imageUrl} />
    </View>}
    <View style={[stylesCss.QuestionLongAnswerWrapper]}>
      <QuestionTitle questionIndex={questionIndex} title={title} required={required} onSkip={onSkip}  isReadOnly={isReadOnly}/>
      <View style={[stylesCss.questionWrapper, styles.greyBoxBorder,
          // {if required}
          requiredWarning && styles.inValidColor
      ]}>
        {!isReadOnly && <TextInput
          style={[
            stylesCss.questionInput,
            {
              ...Platform.select({
                android: {
                  textAlignVertical: "top",
                  height: 95,
                },
                ios: {
                  height: 95,
                },
              }),
            },
          ]}
          allowFontScaling={false}
          placeholderTextColor="#C4C4C4"
          multiline
          placeholder="Enter your response here"
          value={answer}
          onChangeText={(text) => !isReadOnly && onChangeText(text)}
        />}
        {isReadOnly && <Text style={[stylesCss.longAnswerReadOnlyText]} allowFontScaling={false}>
          {answer}
        </Text>}
      </View>
      {/* Requird Field Warning Start */}
      {requiredWarning &&
        <QuestionWarning/>
      }
      {/* Requird Field Warning End */}
    </View>

  </>
);

export default QuestionLongAnswer;
