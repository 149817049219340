import {Platform} from "react-native";
import * as actions from "./actionTypes";
import * as axiosCommon from "../../axios/axios-common-community-groups";
import {axiosGET, axiosPOST, axiosPUT} from "../../axios/axios-common";
import * as urls from "./urls";
import {logout, commonErrorHandler, setHealthResourcePopupData, showSuccessPopup} from "./index";
import { WEB } from "../../util/platformWindowConfig";


export const addReplaceMyList = (replaceResource, callBack) => {
  const requestUrl = urls.BASE_URL_COMMUNITY_GROUPS + "mylist";
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        const {type, responseDto, errorDto} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          callBack(data);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; addReplaceMyList");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            callBack(data);
            dispatch(commonErrorHandler(data));
            dispatch(addCommunityGroupsResourcesFailed(data));
          }
        }
      },
      replaceResource
    );
  };
};

//Checked

//fetch all resources
export const fetchAllResouces = (journalUserId, callBack) => {
  let requestUrl = urls.GET_ALL_HEALTH_LIBRARY_RESOURCES;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosGET(
      requestUrl,
      res => {
        const {type, responseDto, errorDto} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          dispatch(fetchAllResourceSuccess(data));
          callBack(data);
        } else if (type === "NOT FOUND") {
          dispatch(fetchAllResourceSuccess());
          callBack([]);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; fetchAllResouces");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            dispatch(commonErrorHandler(data.error));
            dispatch(fetchAllResourceFail(data));
            callBack([]);
          }
        }
      },
      // {
      //   userId: userId
      // }
    );
  };
};

export const fetchAllResourceSuccess = data => {
  return {
    type: actions.FETCH_ALL_RESOURCE_SUCCESS,
    allResources: data
  };
};

export const updateSearchedKeywords = (searchedKeyword, searchResults) => {
  let searchObject = {
    searchedKeyword: searchedKeyword,
    searchResults: searchResults
  };
  return {
    type: actions.UPDATE_SEARCHED_KEYWORDS,
    searchObject: searchObject
  };
};

export const fetchAllResourceFail = error => {
  return {
    type: actions.FETCH_ALL_RESOURCE_FAIL,
    errorDescription: error
  };
};

export const fetchSocialMediaData = (resourceId, callBack) => {
  const requestUrl = urls.BASE_URL_COMMUNITY_GROUPS + "getsocialmediadata";
  return dispatch => {
    axiosCommon.axiosGET(
      requestUrl,
      res => {
        const {type, responseDto} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          //   dispatch(fetchSocialMediaDataSuccess(data));
          callBack(true, data);
        } else {
          if (res.errorDto && res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; fetchSocialMediaData");
            dispatch(logout());
          } else if (res.errorDto && res.errorDto !== "undefined") {
            const {data} = res.errorDto;
            // dispatch(commonErrorHandler(data));
            // dispatch(fetchSocialMediaDataFail(data));
            callBack(false, data);
          }
        }
      },
      {
        resourceId: resourceId
      }
    );
  };
};

export const fetchSocialMediaDataSuccess = data => {
  return {
    type: actions.FETCH_ALL_SOCIAL_MEDIA_DATA_SUCCESS,
    getSocialMedia: data
  };
};

export const fetchSocialMediaDataFail = error => {
  return {
    type: actions.FETCH_ALL_SOCIAL_MEDIA_DATA_FAIL,
    errorDescription: error
  };
};

/* Add community groups resources */
export const addCommunityGroupsResources = (addCommunity, callBack) => {
  const requestUrl = urls.BASE_URL_COMMUNITY_GROUPS + "resource";
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        const {type, responseDto, errorDto} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          // dispatch(addCommunityGroupsResourcesSuccess(data));
          callBack(data);
        } else if (type === "CONFLICT") {
          let response = JSON.parse(res.conflictDto.data);
          callBack(response);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; addCommunityGroupsResources");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            callBack(data);
            //dispatch(commonErrorHandler(data));
            dispatch(addCommunityGroupsResourcesFailed(data));
          }
        }
      },
      addCommunity
    );
  };
};

export const addCommunityGroupsResourcesFailed = error => {
  return {
    type: actions.ADD_COMMUNITY_GROUPS_RESOURCES_FAIL,
    errorDescription: error
  };
};

/* Validate url fields */
export const validateResource = (url, callBack) => {
  const requestUrl = urls.BASE_URL_COMMUNITY_GROUPS + "urlfieldvalidate";
  return dispatch => {
    axiosCommon.axiosGET(
      requestUrl,
      res => {
        const {type, responseDto, errorDto} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          if (callBack) {
            callBack(true, data);
          }
          dispatch(validateResourceSuccess(data));
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; validateResource");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            //dispatch(commonErrorHandler(data));
            if (callBack) {
              callBack(false, data);
            }
            dispatch(validateResourceFail(data));
          }
        }
      },
      {
        url: url
      }
    );
  };
};

export const validateResourceSuccess = data => {
  return {
    type: actions.VALIDATE_RESOURCE_SUCCESS,
    validResource: data
  };
};

export const validateResourceFail = error => {
  return {
    type: actions.VALIDATE_RESOURCE_FAIL,
    errorDescription: error
  };
};

export const resetAddResourceFetchedData = () => {
  return {
    type: actions.RESET_ADD_RESOURCE_FETCHED_DATA
  };
};

export const onResourceDocumentValidation = fileObject => {
  const requestUrl = urls.BASE_URL_COMMUNITY_GROUPS + "documentvalidate";
  return dispatch => {
    axiosCommon.axiosGET(
      requestUrl,
      res => {
        const {type, responseDto, errorDto} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          dispatch(validateDocumentSuccess(data));
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; onResourceDocumentValidation");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            dispatch(commonErrorHandler(data));
            dispatch(validateDocumentFail(data));
          }
        }
      },
      fileObject
    );
  };
};

export const validateDocumentSuccess = data => {
  return {
    type: actions.VALIDATE_DOCUMENT_SUCCESS,
    validDocument: data
  };
};

export const validateDocumentFail = error => {
  return {
    type: actions.VALIDATE_DOCUMENT_FAIL,
    errorDescription: error
  };
};

export const resourceFileUpload = (url, file, callBack) => {
  return dispatch => {
    axiosCommon.axiosPUT(
      url,
      res => {
        const {type, errorDto} = res;
        if (type === "SUCCESS") {
          callBack();
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; resourceFileUpload");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            dispatch(commonErrorHandler(data));
          }
        }
      },
      file
    );
  };
};

export const deleteResource = (id, callBack) => {
  const requestUrl = urls.BASE_URL_COMMUNITY_GROUPS + "resources";
  return dispatch => {
    axiosCommon.axiosDELETE(
      requestUrl,
      res => {
        const {type, responseDto, errorDto} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          callBack(data);
          // dispatch(validateDocumentSuccess(data));
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; deleteResource");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            callBack(data);
            dispatch(commonErrorHandler(data));
            // dispatch(validateDocumentFail(data));
          }
        }
      },
      id
    );
  };
};

export const onSelectResources = (resource, callBack) => {
  return dispatch => {
    dispatch(selectResourceSuccess(resource));
    callBack();
  };
};

export const selectResourceSuccess = resource => {
  return {
    type: actions.SELECTED_RESOURCE,
    selectedResource: resource
  };
};

export const handleSendEmail = (emailBox, callBack) => {
  const requestUrl = urls.BASE_URL_COMMUNITY_GROUPS + "sendemails";
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        const {type, responseDto, errorDto} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          // dispatch(addCommunityGroupsResourcesSuccess(data));
          callBack(data);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; handleSendEmail");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            dispatch(commonErrorHandler(data));
            // dispatch(addCommunityGroupsResourcesFailed(data));
            callBack(data);
          }
        }
      },
      emailBox
    );
  };
};

export const onDownloadResource = (resourceId, callBack) => {
  const requestUrl = urls.BASE_URL_COMMUNITY_GROUPS + "download";
  return dispatch => {
    axiosCommon.axiosGET(
      requestUrl,
      res => {
        const {type, responseDto, errorDto, notFound} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          if (data) {
            if (Platform.OS === WEB) {
              axiosCommon.axiosDownload(data.downloadURL, () => {
                callBack();
              });
            } else {
              axiosCommon.actualDownload(
                data.downloadURL
                  .split("ResourceFiles/")
                  .pop()
                  .split("?X-Amz-Algorithm=")[0],
                data.downloadURL,
                () => {
                  callBack();
                }
              );
            }
          } else {
            callBack();
          }
        } else if (type === "NOT FOUND") {
          const {data} = notFound;
          dispatch(commonErrorHandler(data));
          callBack();
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; onDownloadResource");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            dispatch(commonErrorHandler(data));
            callBack();
            // dispatch(validateDocumentFail(data));
          }
        }
      },
      resourceId
    );
  };
};

export const onhandleSearch = (searchObject, callBack, searchObjectExtraValues) => {
  const requestUrl = urls.BASE_URL + urls.HEALTH_LIBRARY_SEARCH;
  return dispatch => {
    axiosGET(
      requestUrl,
      res => {
        const {type, responseDto, errorDto} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          dispatch(onhandleSearchSuccess(data, {...searchObject, ...searchObjectExtraValues}));
          if (callBack) {
            callBack(true, data);
          }
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; onhandleSearch");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            dispatch(commonErrorHandler(data));
            dispatch(onhandleSearchFail(data));
            callBack(false, data);
          }
        }
      },
      searchObject
    );
  };
};

export const onhandleSearchSuccess = (data, searchObject) => {
  return {
    type: actions.HANDLE_SEARCH_SUCCESS,
    searchResult: data,
    searchObject: searchObject
  };
};

export const resetCommunitySearch = () => {
  return {
    type: actions.HANDLE_RESET_COMMUNITY_SEARCH
  };
};

export const onhandleSearchFail = error => {
  return {
    type: actions.HANDLE_SEARCH_FAIL,
    errorDescription: error
  };
};

export const onhandleSearchCriteria = (searchObject, callBack) => {
  const requestUrl = urls.BASE_URL_COMMUNITY_GROUPS + "criteria";
  // const requestUrl = urls.BASE_URL + urls.SEARCH_CRITERIA;
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        const {type, responseDto, errorDto} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          callBack(data);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; onhandleSearchCriteria");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            callBack(data);
            dispatch(commonErrorHandler(data));
          }
        }
      },
      searchObject
    );
  };
};

export const handleSearchByTag = (tagSearch, searchSource, callBack) => {
  const requestUrl = urls.BASE_URL_COMMUNITY_GROUPS + "searchbytag";
  return dispatch => {
    axiosCommon.axiosGET(
      requestUrl,
      res => {
        const {type, responseDto, errorDto} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          if (data) {
            let tagSearchResultsObject = {
              tag: tagSearch,
              searchSource: searchSource,
              tagResults: data ? data : [],
            };
            dispatch(handleSearchByTagSuccess(tagSearchResultsObject));
            if (callBack) {
              callBack(data);
            }
          }
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; handleSearchByTag");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            dispatch(commonErrorHandler(data));
            dispatch(handleSearchByTagFail(data));
          }
        }
      },
      tagSearch
    );
  };
};

export const popPreviousSearchResult = () => {
  return {
    type: actions.POP_PREVIOUS_TAG_SEARCH_RESULT,
  };
};


export const handleSearchByTagSuccess = data => {
  return {
    type: actions.TAG_SEARCH_SUCCESS,
    tagSearchResults: data
  };
};

export const handleSearchByTagFail = error => {
  return {
    type: actions.TAG_SEARCH_FAIL,
    errorDescription: error
  };
};

// //Get resource details published by Partner  
let pending = false;
export const getResourceDetailsById = (communityResourceId, callBack) => {
  if (!pending)
    pending = true;
  else
    return () => {
    };
  const requestUrl = urls.GET_HEALTH_LIBRARY_RESOURCE_DETAILS + communityResourceId;
  return dispatch => {
    // dispatch(setHealthResourcePopupData({})) //clear previously stored data
    axiosGET(
      requestUrl,
      res => {
        const {type, responseDto, errorDto, notFound} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          if (data) {
            let dataObj = {
              ...data,
              communityResourceId
            }
            dispatch(setHealthResourcePopupData(dataObj));
            setTimeout(() => {
              dispatch(showSuccessPopup(16, null, null));
            }, 500);
            if (callBack) {
              callBack(true);
            }
          }
        } else if (type === "NOT FOUND") {
          const {data} = notFound;
          dispatch(commonErrorHandler(data));
          if (callBack) {
            callBack(true);
          }
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; getResourceDetailsById");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            dispatch(commonErrorHandler(data.error));
            if (callBack) {
              callBack(false);
            }
          }
        }
        pending = false;
      }
    );
  };
}

export const setHealthLibraryShowHideStatus = (showHideObj, journalUserId, callBack) => {
  let requestUrl = urls.SET_HEALTH_LIBRARY_SHOW_HIDE;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosPOST(
      requestUrl,
      res => {
        const {type, responseDto, errorDto} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          callBack(true, data);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; setHealthLibraryShowHideStatus");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            callBack(false, data);
            dispatch(commonErrorHandler(data.error));
          }
        }
      },
      showHideObj
    );
  };
};

//set resource seen status
export const setResourceSeenStatus = (journalUserId, mapId, callBack) => {
  let requestUrl = urls.CHANGE_HEALTH_LIBRARY_RESOURCE_SEEN_STATUS;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosGET(
      requestUrl,
      res => {
        const {type, responseDto, errorDto} = res;
        if (type === "SUCCESS") {
          const {data} = responseDto;
          callBack(true, data);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; setResourceSeenStatus");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            const {data} = errorDto;
            callBack(false, data);
            dispatch(commonErrorHandler(data.error));
          }
        }
      },
      {
        mapid: mapId
      }
    );
  };
};
export const handleResourceMassageCheckBox = (status, resourceId, callBack) => {
  let requestUrl = urls.MASSAGE_STATUS_CHANGES.replace('{resourceId}', resourceId);
  return dispatch => {
    axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(true, res.responseDto.data);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; handleResourceMassageCheckBox");
            dispatch(logout());
          } else if (res.errorDto !== 'undefined') {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      status,
    );
  };
};

