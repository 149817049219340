import React, { Fragment, useState, useEffect } from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";
import EventAttachmentItem from "./EventAttachmentItem";
import EventBubbleItem from "./EventBubbleItem";
import EventBubbleTagItem from "./EventBubbleTagItem";
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import * as constants from '../HealthJourneyConstant';

const EventContentBox = props =>  {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();

  const [open, setOpen] = useState(false);
  const {
    colapsibleTitle,
    expandTitle,
    type,
    data,
    chunkSize,
    userData,
    onPressViewItem,
    year,
    cardId,
    openedYearAndId,
    journalEntries,
    setActiveBubble,
    activeBubbleId,
    updateOpenCategoryType,
    updateOpenAttachment,
    windowWidth
  } = props;

  useEffect(() => {
    if (
      !open &&
      openedYearAndId &&
      openedYearAndId.year === year &&
      //openedYearAndId.type === type &&
      openedYearAndId.id === cardId
    ) {
      setOpen(true);
      updateOpenCategoryType && type !== constants.eventTypes.ATTACHMENTS && type !== 'TAGS' && updateOpenCategoryType(true);
      updateOpenAttachment && type === constants.eventTypes.ATTACHMENTS && updateOpenAttachment(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedYearAndId]);
  
  const handleOpenClose = () => {
    setOpen(open => !open);
    setActiveBubble && setActiveBubble(null);
    updateOpenCategoryType && type !== constants.eventTypes.ATTACHMENTS && type !== 'TAGS' && updateOpenCategoryType(!open);
    updateOpenAttachment && type === constants.eventTypes.ATTACHMENTS && updateOpenAttachment(!open);
  }
  
  // const sliceArrayIntoChunks = (chunkSize, array) => {
  //   const res = [];
  //   for (let i = 0; i < array.length; i += chunkSize) {
  //       const chunk = array.slice(i, i + chunkSize);
  //       res.push(chunk);
  //   }
  //   return res;
  // }

  const renderGrid = () => {
    // const chunks = sliceArrayIntoChunks(Math.ceil(data.length / chunkSize), data);
    let counter = 1;
    return (
      <View style={[
        stylesRes.rowColumn,
        styles.secLightBlueBg,
        styles.infoPdTopBotExSm,
        styles.flexWrapView,
        {paddingLeft: 30, paddinRight: 30},
        open && type === 'TAGS' && styles.tagsBrdRadSpecStyle
        ]} 
        dataSet={{media: ids.rowColumn}}
        >
        {/* {chunks.map((chunk, idx) => (
          // <View key={idx} style={{width: `${Math.floor(100 / chunkSize)}%`}}>
               <View key={idx} style={[stylesRes.pdLefrRemLg, {zIndex: 9999 - idx}, type !== constants.eventTypes.ATTACHMENTS &&  {flexDirection:'row', flexWrap:'wrap', width:'100%'}]} dataSet={{media: ids.pdLefrRemLg}}> */}
            <View style={[{width:'100%'}, type !== constants.eventTypes.ATTACHMENTS && type !== 'TAGS' && {width:'80%'}]}>
            <View style={[styles.itemsWdMax, type !== constants.eventTypes.ATTACHMENTS && type !== 'TAGS' && stylesRes.medsMbClmWebRow]} dataSet={{media: ids.medsMbClmWebRow}}>
            {data.map((item, index) =>
              type === constants.eventTypes.ATTACHMENTS ? (
                <Fragment key={counter}>
                  <EventAttachmentItem
                    index={counter++}
                    item={item}
                  />
                </Fragment>
              ) : type === 'TAGS' ? (
                <Fragment key={counter}>
                  <EventBubbleTagItem
                    index={counter++}
                    item={item}
                  />
                </Fragment>
              ) : (
                <Fragment key={counter}>
                  <EventBubbleItem
                    type={type}
                    index={counter++}
                    item={userData[item.id]}
                    onPressViewItem={onPressViewItem}
                    year={year}
                    cardId={cardId}
                    openedYearAndId={openedYearAndId}
                    journalEntries={journalEntries}
                    setActiveBubble={setActiveBubble}
                    activeBubbleId={activeBubbleId}
                  />
                </Fragment>
              ),
            )}
            </View>
            </View>
          {/* </View> */}
         {/* ))} */}
      </View>
    );
  }

  return (
    <Fragment>
          <TouchableOpacity onPress={handleOpenClose}>
            <View style={[ 
            styles.comSpSm,
            styles.lblSp,
          styles.tagLtAlign,
            ]}>
              <View style={stylesRes.pdLefrRemLg} dataSet={{media: ids.pdLefrRemLg}}>
                <Text
                  style={[
                    styles.textPrimeMdBold,
                    styles.healthJourneySeaBlue,
                    styles.textUnderline,
                  ]}>
                  {!open ? colapsibleTitle : expandTitle}
                </Text>
              </View>
            </View>
          </TouchableOpacity>                             
      {open && (
        <View>
          {renderGrid()}
        </View>
      )}
    </Fragment>
  );
};

export default EventContentBox;