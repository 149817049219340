import React from 'react';
import {Text, View, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import Modal from '../Modal/modal';
import ModalLayout from '../ModalLayout/modalLayout';
import {ORGANIZATION_NAME} from '../../util/commonUiConfig';

const deleteAccountSuccess = props => {
  return (
    <Modal
      visible={props.visibleModal}
      onRequestClose={props.onRequestClose}
      onBackdropPress={props.onBackdropPress}>
      <ModalLayout contPosition={'center'} popupAuto={true} pdCom={true}>
        <View style={styles.popupContentWrapper}>
          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              <View style={[styles.infoTitleBlock, styles.colPdBotLg]}>
                <Text style={[styles.textPrimeBold, styles.textAlignCenter]} allowFontScaling={false}>
                  Thank you for using {ORGANIZATION_NAME}!
                </Text>
              </View>
              <View style={[styles.infoBlock, styles.colPdBotLg]}>
                <Text style={[styles.textPrime, styles.textAlignCenter]} allowFontScaling={false}>
                  You have successfully deleted your {ORGANIZATION_NAME} account and all
                  your information.
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              <View style={[styles.btnBlock, styles.noMgTop]}>
                <TouchableOpacity onPress={props.onPressOkay}>
                  <View style={[styles.submit, styles.btnSingleCent]}>
                    <Text style={styles.submitText} allowFontScaling={false}>Okay</Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </ModalLayout>
    </Modal>
  );
};

export default (deleteAccountSuccess);
