import React, { Component } from "react";
import { connect } from "react-redux";
import { View, Image, TouchableOpacity, Text } from "react-native";
import ActivityIndicator from "../ActivityIndicator/activityIndicator";
import * as actions from "../../store/actions/index";
import styles from "../../Css";
import Modal from "../Modal/modal";
import AddMethodforEntry from "../ConfirmationBox/addMethodforEntry";
import ImageTakePhotoPopup from "../Attachment/AttachmentImageBox/imageTakePhotoPopup";
import { getAccountType } from "../../util/subscriptionInformation";
import { freeStorageByte, premiumStorageByte } from "../../Constant";
import {getArtifact} from '../../util/commonUiLogic';

class FileUploadJournalAttachment extends Component {
  constructor(props) {
    super(props);
    const attachmentCategory = props.attachmentCategory ? props.attachmentCategory.split("-") : [];
    let treatmentType = null;

    if (attachmentCategory[0] === "Therapy") {
      if (attachmentCategory.length === 2) {
        treatmentType = (props.selectedTherapie || {}).treatmentType;
      } else {
        treatmentType = (props.newTherapie || {}).treatmentType;
      }
    }
    const accountType = getAccountType(props.userSubscriptions);
    this.state = {
      artifactId: getArtifact(props),
      treatmentTypeId: treatmentType,
      attachmentCategory: attachmentCategory[0],
      fileUploadWait: false,
      visibleEntryModal: false,
      selected: null,
      visibleImageTakePhoto: false,
      visibleUploadTypeModal: false,
      accountType: accountType,
      journalEntrySelected: props.journalEntrySelected,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.journalEntrySelected !== props.journalEntrySelected) {
      return {
        artifactId: getArtifact(props),
        journalEntrySelected: props.journalEntrySelected,
      }
    }
    return null;
  }
  onChange = (e) => {
    this.setState({
      visibleEntryModal: false,
    });
    const files = e.target.files;
    const formData = new FormData();
    const ocrData = {};

    let fileSize = 0;

    let userId = this.props.user.id;
    if (this.props.journalUserId) {
      userId = this.props.journalUserId;
    }

    for (let i = 0; i < files.length; i++) {
      formData.append("files[" + i + "]", files[i]);
      fileSize += files[i].size;
      ocrData[files[i].name] = files[i];
    }

    e.target.value = null;
    const { applicationSettingsDigest } = this.props;
    const { uploadedFileSize } = applicationSettingsDigest;
    const totalFileSize = uploadedFileSize + fileSize;
    if (fileSize > 26214400) {
      this.props.commonErrorHandler(
        "Sorry. File exceeds the maximum upload size of 25MB."
      );
    } else if (
      this.props.journalUserId === null &&
      this.state.accountType === "regular" &&
      totalFileSize > freeStorageByte
    ) {
      this.props.showHideStorageBanner(true);
    } else if (
      this.state.accountType === "premium" &&
      totalFileSize > premiumStorageByte
    ) {
      this.props.showHideStorageBanner(true);
    } else {
      this.setState({ fileUploadWait: true });
      this.props.onUpload(
        this.state.artifactId,
        formData,
        (success, attachments) => {
          if (success && attachments) {
            const upoladData = [];
            attachments.forEach((item, index) => {
              const data = ocrData[item.filename];

              var reader = new FileReader();
              reader.fileName = item.filename;
              reader.instanceId = this.state.artifactId;
              reader.attachmentId = item.id;
              reader.attachmentCategory = this.state.attachmentCategory;
              reader.userId = userId;
              reader.index = index;
              reader.arrayLenght = attachments.length - 1;
              reader.upoladData = upoladData;
              reader.uploadComplte = this.uploadComplte;

              reader.onload = function (readerEvt) {
                readerEvt.target.upoladData.push({
                  fileName: readerEvt.target.fileName,
                  content: readerEvt.target.result,
                  attachmentId: readerEvt.target.attachmentId.toString(),
                });
                if (readerEvt.target.index === readerEvt.target.arrayLenght) {
                  readerEvt.target.uploadComplte();
                }
              };

              reader.readAsDataURL(data);
            });
          } else {
          }
        },
        false,
        this.props.journalUserId,
        fileSize
      );
    }
  };

  uploadComplte = () => {
    this.props.fetchUserAttachments(this.state.artifactId);
    this.setState({
      fileUploadWait: false,
      selected: null,
    });
  };

  onPressClose = () => {
    this.setState({ visibleEntryModal: false });
  };

  render() {
    return (
    <React.Fragment>
        <View style={[styles.btnSingle, styles.flexAiFs, styles.colPdLtRt]}>
          <TouchableOpacity
            onPress={() => {
              this.setState({
                visibleEntryModal: true,
                selected: 3,
              });
            }}
            disabled={this.state.fileUploadWait}
            style={[
              styles.btnActionCom,
              styles.bgWhite,
              styles.btnBorderThin,
              styles.btnBorderLight,
            ]}
          >
            <View style={[
              styles.flexRow, 
              this.state.fileUploadWait
                ? styles.flexAiFs
                : styles.flexJcCtAiCt
            ]}>
              <View style={styles.fileUploadBtnFixed}>
                <View style={styles.fileUploadBtnIcon}>
                  <Image
                    style={styles.plusExSm}
                    source={require("../../assets/icons/plus-sm.png")}
                    resizeMode={"contain"}
                  />
                </View>
                <Text style={[styles.textPrimeBold, styles.textOrange]} allowFontScaling={false}>
                  {this.props.btnText}
                </Text>
              </View>

              {this.state.fileUploadWait && (
                <View style={styles.loaderWaitCom}>
                  <ActivityIndicator size="small" />
                </View>
              )}
            </View>
          </TouchableOpacity>
        </View>
        {this.state.visibleEntryModal && (
        <Modal
          visible={this.state.visibleEntryModal}
          onRequestClose={() => {
            this.setState({ visibleEntryModal: false });
          }}
          onBackdropPress={() => this.setState({ visibleEntryModal: false })}
        >
          <AddMethodforEntry
            onPressClose={this.onPressClose}
            onPressManuallyEntry={this.onPressManuallyEntry}
            onPressUploadEntry={this.onPressUploadEntry}
            onPressTakePhoto={() => {
              this.setState({
                visibleImageTakePhoto: true,
                visibleEntryModal: false,
              });
            }}
            onChange={(e) => this.onChange(e)}
            selected={this.state.selected}
            onClick={() => {
              this.setState({ uploadError: false });
            }}
            artifactId={this.state.artifactId}
          />
        </Modal>
        )}
        {this.state.visibleImageTakePhoto && (
        <Modal
          visible={this.state.visibleImageTakePhoto}
          onRequestClose={() =>
            this.setState({
              visibleImageTakePhoto: false,
              selected: null,
            })
          }
          onBackdropPress={() =>
            this.setState({
              visibleImageTakePhoto: false,
              selected: null,
            })
          }
        >
          <ImageTakePhotoPopup
            cropSuccess={() => {
              this.setState({ visibleImageTakePhoto: false });
            }}
            artifactId={this.state.artifactId}
            close={() => {
              this.setState({ visibleImageTakePhoto: false, selected: null });
            }}
            attachmentCategory={this.state.attachmentCategory}
            treatmentTypeId={this.state.treatmentTypeId}
            uploadError={() => {
              this.setState({ uploadError: true });
            }}
          />
        </Modal>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.routines.user,
    journalUserId: state.uistate.journalUserId,
    newTherapie: state.therapie.newTherapie,
    selectedTherapie: state.therapie.selectedTherapie,
    newJournal: state.routineTemplate.newJournal,
    journalEntrySelected: state.routines.journalEntrySelected,
    selectedContact: state.contacts.selectedContact,
    newContact: state.contacts.newContact
      ? state.contacts.newContact.data
      : null,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    userSubscriptions: state.routines.userSubscription,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onUpload: (
      artifactId,
      formData,
      callBack,
      reload,
      journalUserId,
      fileSize
    ) =>
      dispatch(
        actions.uploadAttachmen(
          artifactId,
          formData,
          callBack,
          reload,
          journalUserId,
          fileSize
        )
      ),
    fetchUserAttachments: (artifactId) =>
      dispatch(actions.fetchUserAttachments(artifactId)),
    commonErrorHandler: (error) => dispatch(actions.commonErrorHandler(error)),
    showHideStorageBanner: (val) =>
      dispatch(actions.showHideStorageBanner(val)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadJournalAttachment);
