import React, {Component} from 'react';
import {Image, View, Text, ScrollView} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Modal from '../../components/Modal/modal';
import NestedScroll from '../../components/NestedScroll/nestedScroll';
import PrimaryButton from '../../components/Button/primaryButton';
import PrimaryButtonWhite from '../../components/Button/primaryButtonWhite';

class UserLicenseAgreement extends Component {
  render() {
    const stylesRes = stylesResponsive.getProcessedStyles().styles;
    const ids = stylesResponsive.getProcessedStyles().ids;
    return (
      <Modal
        visible={this.props.showUserLicenseAgreement}
        onRequestClose={this.props.toggleUserLicenseAgreement}
        onBackdropPress={this.props.toggleUserLicenseAgreement}
        defaultStyle={styles.customPopUpStyleLg}>
        <View style={stylesRes.modalScrollStatic} dataSet={{media: ids.modalScrollStatic}}>
          <ScrollView style={styles.modalScroller}>
            <View style={[styles.popUpBlock, styles.bgWhite, styles.popUpRad]}>
              <View style={styles.popUpContentArea}>
                <View style={styles.gridSectionPopup}>
                  <View style={[styles.popUpContentPd]}>
                    <View style={stylesRes.modalContIn}>
                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <View style={[styles.topImgSecCom]}>
                            <View style={styles.topImgSignUp}>
                              <Image
                                style={styles.privacyIcon}
                                source={require('../../assets/signup/privacy.png')}
                              />
                            </View>
                          </View>
                        </View>
                      </View>

                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <View style={styles.titleBlock}>
                            <Text style={styles.titleText} allowFontScaling={false}>
                              User License Agreement
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <View
                            style={[
                              stylesRes.userInfoBlockCom,
                              stylesRes.modalRdSm,
                            ]}>
                            <View
                              style={[
                                stylesRes.userInfoTextCom,
                                stylesRes.userInfoTextLg,
                              ]}>
                              <NestedScroll>
                                <View style={stylesRes.userInfoInnerBlock}>
                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        1. Non-Exclusive Licence Agreement
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          This licence agreement (the
                                          "Agreement") between HAnalytics
                                          Solutions Inc. ("We" and “us”) and you
                                          or the entity you represent ("You")
                                          sets out the terms and conditions
                                          gov-erning your use of the services We
                                          provide through our website,
                                          accessible at
                                          www.hanalyticssolutions.com, or via
                                          applications for mobile operating
                                          systems (collec-tively, the “Site”).
                                          By clicking on the "I Agree" icon at
                                          the end of this Agreement, You
                                          in-dicate your acceptance of the terms
                                          of this Agreement. This Agreement
                                          takes effect on the date you accept
                                          the terms of the Agreement in
                                          accordance with this section (the
                                          "Effective Date"). You represent that
                                          You are legally able to enter into
                                          contracts.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        2. Disclaimer
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          The Site does not provide medical
                                          advice. All material provided by the
                                          Site is for infor-mational purposes
                                          and does not constitute medical advice
                                          or treatment. Always seek the advice
                                          of a qualified health professional for
                                          any questions You may have. If You
                                          think You have a medical emergency,
                                          call your doctor or an emergency
                                          service. The Site is provided "as is",
                                          without warranty of any kind, either
                                          express or implied, including but not
                                          limited to the implied warranties of
                                          merchantability or fitness for a
                                          particular purpose. We give no
                                          warranty that the Site will meet your
                                          particular requirements or that the
                                          operation of the Site will be
                                          uninterrupted or error free.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        3. Change or Discontinuation of the Site
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We reserve the right to change or
                                          discontinue any aspect of the Site.
                                          You will be notified of any material
                                          changes or discontinuation of the
                                          Site.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        4. Grant of Licence and Limits to Use
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          In this section,
                                        </Text>
                                      </View>

                                      <View style={stylesRes.paraBotPd}>
                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            (a) “Caregiver” means a family
                                            member or a paid or unpaid helper
                                            who regularly looks after a Patient;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            (b) “Documentation” means all
                                            documentation and supporting
                                            resources provided with the Site.
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            (c) “Health Care Provider” means a
                                            person or business entity providing
                                            treatment, advice, or other services
                                            with the intent of providing
                                            healthcare, and does not include a
                                            Caregiver;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            (d) “Health Data” means data
                                            collected by the Site that is
                                            “health information” as defined in
                                            the{' '}
                                            <Text style={styles.textEm} allowFontScaling={false}>
                                              Health Information Act, RSA 2000,
                                              c H-5.
                                            </Text>
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            (e) “Health Record System” means an
                                            Electronic Medical Record, an
                                            Electronic Health Record, or any
                                            other system for recording or
                                            storing health data;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            (f) “Patient” means a person whose
                                            Health Data is collected by use of
                                            the Site;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            (g) “Personal Data” means Patient
                                            information collected by the Site
                                            that is not “Health Data”; and
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            (h) “Restricted Access Data” means
                                            Health Data that the Patient has
                                            marked as Restricted Access;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            (i) “Restricted Access Header” means
                                            the document header displayed on
                                            Restricted Access Data; and
                                          </Text>
                                        </View>
                                      </View>

                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We grant you a limited, revocable,
                                          non-exclusive, non-sublicensable,
                                          non-transferable li-cence to access
                                          and/or use the Site for your personal
                                          or commercial use. You agree that You
                                          may not use the Site in any manner or
                                          for any purpose other than as
                                          expressly permitted by this Agreement
                                          and in particular You may not:
                                        </Text>
                                      </View>

                                      <View style={stylesRes.paraBotPd}>
                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.1 modify, alter, tamper with or
                                            create a derivative work of the
                                            Site;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.2 derive or determine or attempt
                                            to derive or determine the source
                                            code or the un-derlying ideas,
                                            algorithms, structure or
                                            organization of the Site;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.3 resell, loan, rent, distribute,
                                            or sublicense the Site or the
                                            Documentation; or
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.4 allow a person who is not a
                                            party to this Agreement to access or
                                            use the Site.
                                          </Text>
                                        </View>
                                      </View>

                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          In addition, Caregivers and Health
                                          Care Providers may not:
                                        </Text>
                                      </View>

                                      <View style={stylesRes.paraBotPd}>
                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.5 disclose Personal Data to any
                                            third party without the express
                                            consent of the Patient;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.6 use the Site to collect or store
                                            Personal Data or Health Data without
                                            the express consent of the Patient;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.3 resell, loan, rent, distribute,
                                            or sublicense the Site or the
                                            Documentation; or
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.7 use Health Data for educational
                                            purposes without the express consent
                                            of the Patient;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd}>
                                            4.8 use the Site as a sole or
                                            primary Health Record System;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.9 reproduce Restricted Access Data
                                            without the Restricted Access
                                            Header;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.10 share Restricted Access Data
                                            without the express consent of the
                                            Patient; or
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.11 share Health Data through means
                                            other than those provided by the
                                            Site or an equally or more secure
                                            means.
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        5. Modification of Licence
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We may modify the terms of this
                                          Agreement at any time without notice.
                                          Any additional or modified terms will
                                          be effective upon being posted on our
                                          website. It is your responsibility to
                                          review the Agreement periodically on
                                          the website. Your continued use of the
                                          Site con-stitutes your acceptance of
                                          the modified or additional terms.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        6. Proprietary and Intellectual Property
                                        Rights
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We own all right, title and interest
                                          in and to the Site. You acknowledge
                                          and agree that the Site is licensed to
                                          You only for the term of this
                                          Agreement and in accordance with the
                                          terms and conditions of this
                                          Agreement. Except for the limited
                                          rights granted in this Agreement, We
                                          reserve all rights.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        7. Term
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          The term of this Agreement commences
                                          on the Effective Date and remains in
                                          effect until terminated in accordance
                                          with para. 8 of this Agreement.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        8. Termination
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          This Agreement may be terminated in
                                          the following ways:
                                        </Text>
                                      </View>

                                      <View>
                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            8.1 You may terminate this agreement
                                            by changing your account settings on
                                            the Site;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            8.2 We may terminate this Agreement
                                            at any time with 30 days’ notice; or
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            8.3 This Agreement may terminate by
                                            reason of non-payment as set out in
                                            the payment terms section of our
                                            website (the “Payment Terms”). ;
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        9. Fees and Payment
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          The fees payable by You for the Site
                                          and the terms and conditions of
                                          payment of such fees are set out in
                                          the Payment Terms.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        10. Responsibility for Password and
                                        Login Information
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          You are fully responsible for all
                                          activities that occur under your
                                          account. You must im-mediately inform
                                          us of any unauthorized use of your
                                          account or any other breach of
                                          se-curity.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        11. Limitation of Liability
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          In no event shall We, our directors,
                                          officers, agents, employees or
                                          affiliates be liable to You for any
                                          direct, indirect, special,
                                          consequential or exemplary damages
                                          arising out of or re-lated to the use
                                          or inability to use the Site, even if
                                          We have been advised of the
                                          possibility of such damages. This
                                          limitation of liability shall apply to
                                          any claim or cause of action
                                          whatsoever whether such claim or cause
                                          of action is based on contract, tort,
                                          strict liability or other theory.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        12. Indemnity
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          You agree to indemnify us and hold us
                                          harmless together with our directors,
                                          officers, agents, employees and
                                          affiliates, from and against all
                                          liability, causes of action, claims,
                                          costs and expenses arising out of or
                                          resulting from use of the Site.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        13. Disruption of Service
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          You agree that We shall not be held
                                          responsible for any harm caused by a
                                          disruption of service of the Site.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        14. Privacy
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We are committed to respecting your
                                          privacy rights. All information You
                                          provide to us in connection with Your
                                          use of the Site will be subject to our
                                          Privacy Notice which is set out on our
                                          website.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        15. Notices
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          All notices given under this Agreement
                                          shall be delivered electronically.
                                        </Text>
                                      </View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                          a. No Waiver
                                        </Text>
                                      </View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We shall not be deemed to have waived
                                          any provision in this Agreement unless
                                          such waiver is in writing and signed
                                          by us. No failure by us to exercise
                                          and no delay by us in exercising any
                                          right or remedy under this Agreement
                                          will be deemed to be a waiver by us of
                                          that right or remedy. No waiver by us
                                          of any breach by You of this Agreement
                                          shall be deemed to be a waiver of any
                                          subsequent breach by You of that
                                          provision.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        16. Severability
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          If any court of competent jurisdiction
                                          holds that any provision in this
                                          Agreement is invalid, illegal or
                                          unenforceable, the validity, legality
                                          and enforceability of the remaining
                                          provisions of this Agreement will
                                          remain in full force and effect. Any
                                          invalid or unenforceable por-tions
                                          will be interpreted to effect and
                                          intent of the original portion. If
                                          such construction is not possible, the
                                          invalid or unenforceable portion will
                                          be severed from this Agreement but the
                                          rest of the Agreement will remain in
                                          full force and effect.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        17. Governing Law
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          This Agreement shall be governed by
                                          and construed in accordance with the
                                          laws of the Province of Alberta and
                                          the laws of Canada applicable therein.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        18. Entire Agreement
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          This Agreement and the Payment Terms
                                          together form the entire agreement
                                          between the parties with respect to
                                          the subject matter hereof and
                                          supersede and cancel any and all prior
                                          or contemporaneous discussions,
                                          negotiations, arrangements and
                                          agreements between them, express or
                                          implied, oral or written, with respect
                                          to such subject matter hereof.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        19. Assignment
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          You will not assign this Agreement or
                                          sublicense any of your rights under
                                          this Agreement without our prior
                                          consent. Subject to the foregoing,
                                          this Agreement will be binding upon,
                                          and inure to the benefit of, the
                                          parties and their respective
                                          successors and assigns.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        20. Acknowledgement
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          You acknowledge that You have read
                                          this Agreement, understand it and
                                          agree to be bound by its terms and
                                          conditions.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View
                                    style={[
                                      stylesRes.paraBlock,
                                      stylesRes.zeroBotPd,
                                    ]}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        21. Licence Agreement History
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          April 3, 2017: Original version
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </NestedScroll>
                            </View>
                          </View>
                        </View>
                      </View>

                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <View
                            style={[stylesRes.btnViewSecSm, stylesRes.zeroBotPd]}
                            dataSet={{media: ids.btnViewSecSm}}>
                            <View
                              style={[
                                styles.btnArea,
                                styles.flexRow,
                                styles.flexCom,
                              ]}>
                              <View style={styles.fieldBtn}>
                                <PrimaryButtonWhite
                                  btnText={'Decline'}
                                  btnPosition={'full'}
                                  touchableOnPress={() => {
                                    this.props.onAcceptDecline(false);
                                    this.props.toggleUserLicenseAgreement();
                                  }}
                                />
                              </View>
                              <View style={styles.fieldBtnSpSm} />
                              <View style={styles.fieldBtn}>
                                <PrimaryButton
                                  btnText={'Accept'}
                                  btnShadow={true}
                                  btnPosition={'full'}
                                  touchableOnPress={() => {
                                    this.props.onAcceptDecline(true);
                                    this.props.toggleUserLicenseAgreement();
                                  }}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    showUserLicenseAgreement: state.uistate.showUserLicenseAgreement,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleUserLicenseAgreement: () =>
      dispatch(actions.showUserLicenseAgreement()),
    togglePrivacyStatment: () => dispatch(actions.showPrivacyStatment()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserLicenseAgreement);
