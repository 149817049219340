import React, {Component} from 'react';
import {connect} from 'react-redux';
import ProfileImage from './profileImage';
import {View, TouchableOpacity, Text, Image, Platform} from 'react-native';
import styles from '../../../Css';
import FieldTitle from '../../../components/TextInput/fieldTitle';
import * as FieldDetails from '../../../components/TextInput/fieldDetails';
import ViewMoreText from '../../../components/ViewMoreText';
import CardButton from './cardButton';
import ContentBlock from './contentBlock';
import StatusButton from './statusButton';
import SettingsButton from './settingsButton';
import {actionStatus, sortAlphabeticalOrder} from '../../../util/commonUiLogic';
import * as actions from '../../../store/actions/index';
import stylesResponsive from '../../../CssResponsive';
import { TAB_BREAk_POINT_START, WEB } from '../../../util/platformWindowConfig';
const FIX_FREEZING_POINT = 10;
class CardItem extends Component {
  constructor(props) {
    super(props);
    const {
      windowHeight,
      windowWidth,
      isExpand,
      searchedKeyword,
      scrollHeight,
      messageText,
      isKeyboardShow,
    } = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      showInfo: isExpand
        ? isExpand
        : scrollHeight < -300 || messageText === null
        ? true
        : false, //scrollHeight < -300 because of iOS and safari browser scroll issue
      searchedKeyword: searchedKeyword,
      scrollHeight: scrollHeight,
      messageText: messageText,
      isKeyboardShow: isKeyboardShow,
      viewMore: false,
      
    };
    this.medicalConditionTagsWidth = {}
  }

  static getDerivedStateFromProps(props, state) {
    const {
      isExpand,
      searchedKeyword,
      scrollHeight,
      messageText,
      isKeyboardShow,
    } = props;
    if (state.searchedKeyword !== searchedKeyword) {
      return {
        showInfo: isExpand,
        searchedKeyword: searchedKeyword,
        viewMore: false,
      };
    }
    if (
      state.scrollHeight !== scrollHeight ||
      state.messageText !== messageText ||
      state.isKeyboardShow !== isKeyboardShow
    ) {
      return {
        showInfo: state.showInfo && ((state.scrollHeight + 160 === scrollHeight || state.scrollHeight < scrollHeight) && messageText === state.messageText) ? true : false,
        scrollHeight: state.showInfo && (state.scrollHeight + 160 === scrollHeight || state.scrollHeight < scrollHeight) ? scrollHeight - 1 : scrollHeight,
        messageText: messageText,
        isKeyboardShow: isKeyboardShow,
      };
    }

    return null;
  }
  //conditions in more info
  getConditionListText = conditions => {
    let conditionListText = '';
    if (conditions && conditions.length > 0) {
      conditions
        .sort((a, b) => {
          return (a !== null && b !== null) && a.toLowerCase().localeCompare(b.toLowerCase());
        })
        .forEach((condition, index) => {
          if (index === 0) {
            conditionListText = condition;
          } else {
            conditionListText += ', ' + condition;
          }
        });
    }
    return conditionListText;
  };

  //max 3 conditions in Find Peers - Search Results
  getConditionsNameText = () => {
    let conditionText = '';
    let conditionListText = this.getConditionListText(
      this.props.peerProfile.medicalConditionsList,
    );
    if (
      this.props.selectedConditionList &&
      this.props.selectedConditionList.length > 0
    ) {
      sortAlphabeticalOrder(this.props.selectedConditionList)
        .forEach(condition => {
          if (
            conditionListText.includes(condition.name) &&
            !conditionText.includes(condition.name)
          ) {
            if (conditionText.split(',').length >= 4) return;
            conditionText += condition.name + ', ';
          }
        });
    } else {
      if (conditionListText.split(',').length >= 4) {
        conditionListText.split(',').forEach((condition, index) => {
          if (index < 3) {
            conditionText += condition + ', ';
          }
        });
      } else {
        conditionText = conditionListText + ', ';
      }
    }

    return conditionText !== ''
      ? conditionText.substring(0, conditionText.length - 2)
      : conditionText;
  };
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {showInfo} = this.state;
    const {viewMore} = this.state;
    const {
      peerProfile,
      conditionName,
      displayConnectDeclineButtons,
      displayStatusButton,
      displaySettingsButton,
      displayInfo,
      displayReceivedMessage,
      onPressConnect,
      onSelectActionStatus,
      connecting,
      connectionID,
      wait,
      windowWidth,
      chatScreen,
      displaySendIcon,
      onPressSend,
      displayOnlineOffline,
      foundChatOnList, 
      setViewMoreShowHideDetails,
      showHideStatusDetails
    } = this.props;
    const {
      userName,
      sex,
      birthday,
      medicalConditionsList,
      story,
      profileImageS3SignedUrl,
      message,
      connectionStatus,
      connectionId,
      status,
    } = peerProfile;
    let age = '';
    if (birthday && birthday > 0) {
      let ageDifMs = Date.now() - birthday;
      let ageDate = new Date(ageDifMs);
      age = Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    const DisplayInfo = (info, medicalCondition) => {
      let conditionItems = [];
      if (medicalCondition) {
        conditionItems = getConditionItems();
      }
      return (
        <View style={[styles.flexRow, styles.colPdTopCom,styles.selectionRadioWrapper]}>
          <View style={styles.flexCom}>
            <View style={[styles.postRelative, styles.cardItemContentAlign]}>
              <FieldTitle
                fieldBg={FieldDetails.BGWHITE}
                title={info}
                fieldLt={false}
                conditionTitle={true}
              />
              <View
                style={[
                  styles.cardItemContentCr
                ]}>
                {Boolean(medicalCondition)
                  ? <View style={[styles.flexRow, viewMore && styles.cardItemConditionTags]}>
                      {!viewMore 
                        ? <View 
                        style={stylesRes.conditionWidth} 
                        dataSet={{media: ids.conditionWidth}}
                        onLayout={e => {
                          const width = e.nativeEvent.layout.width;
                          setTimeout(() => {
                            const sumOfTagsWidth = Object.values(this.medicalConditionTagsWidth).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                            if (width - FIX_FREEZING_POINT < sumOfTagsWidth){
                              setViewMoreShowHideDetails({[userName] : sumOfTagsWidth})
                            }
                          }, 300);
                        }}>
                          {conditionItems}
                        </View>
                        : conditionItems
                      }  
                      <View style={[styles.topAlgnSix, !viewMore && styles.flexEnd]}>
                      {Boolean(showHideStatusDetails[userName]) &&
                        <TouchableOpacity 
                          onPress={() => {
                            this.setState({viewMore: !viewMore});
                          }}>
                          <Text 
                            style={[
                              styles.textPrimeBold, 
                              styles.menuTextActive, 
                              //styles.iconLtSp, 
                              styles.mgRtZero,
                              styles.textUnderline,
                            ]} 
                            allowFontScaling={false}>
                            {!viewMore ? 'Read more' : 'Hide'}
                          </Text>
                        </TouchableOpacity>
                      } 
                    </View>
                  </View>
                  : <ViewMoreText
                      longText={story}
                      moreText={'Read more'}
                      lessText={'Hide'}
                      numberOfLines={Platform.OS === WEB ? 1 : 2}
                    />
                }
              </View>
            </View>
          </View>
        </View>
      );
    };

    const getConditionItems = () => {
      return medicalConditionsList && medicalConditionsList.length > 0 &&
        medicalConditionsList.sort((a, b) => {
          return (a !== null && b !== null) && a.toLowerCase().localeCompare(b.toLowerCase());
        }).map((condition, idx) => { 
          return (
            <View 
              style={[styles.cardItemConditionTag]} 
                onLayout={e => this.medicalConditionTagsWidth[idx] = e.nativeEvent.layout.width }> 
              <Text style={[styles.textPrime, styles.cardItemConditionTagTxt]} allowFontScaling={false}>
                {condition}
              </Text>
            </View>
          );
        });
    };

    return (
      <View style={[styles.cardItemSingle]}>
        <View
          style={[
            styles.flexRow,
            styles.cardItemDetails
          ]}>
          <TouchableOpacity
            onPress={() => {
              this.setState({
                showInfo: !showInfo,
              });
            }} 
            style={[styles.flexRow, styles.flexCom]}>
          <View style={styles.itemColImg}>
            <ProfileImage 
              userName={userName} 
              userImage={profileImageS3SignedUrl} 
              displayOnlineOffline={displayOnlineOffline && status ? true : false}
            />
          </View>
          <View style={styles.itemColSp} />
          <View style={styles.itemColStretch}>
            <View style={styles.cardItemContent}>
              <View style={styles.cardItemTitle}>
                <Text
                  style={
                    windowWidth > 1366
                      ? styles.textTitleBold
                      : styles.textPrimeBold
                  }
                  allowFontScaling= {false}
                  >
                  {userName}
                  {sex || age !== ''
                    ? '(' +
                      (sex ? sex.charAt(0) : '') +
                      (sex && age !== '' ? ', ' : '') +
                      age +
                      ')'
                    : ''}
                  {/* {`${moment(birthday).year()}`.substring(2)}) */}
                </Text>
              </View>
                {!Boolean(showInfo) && (
                <View style={[styles.cardItemDetail, styles.cardItemMore]}>
                  <Text
                    style={
                      windowWidth > 1366 ? styles.textPrime : styles.textPrimeSm
                    }
                    numberOfLines={1}
                    allowFontScaling= {false}
                    >
                    {conditionName
                      ? conditionName
                      : this.getConditionsNameText()}
                  </Text>
                </View>
              )}
                {Boolean(displayInfo) && (
                <View style={[styles.cardItemMore, styles.flexAsfs]}>
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({
                        showInfo: !showInfo,
                      });
                    }}>
                      {!Boolean(showInfo) ? (
                      <View style={styles.flexRow}>
                        <Text
                          style={[
                            windowWidth > 1366
                              ? styles.textPrimeBold
                              : styles.textPrimeSmBold
                          ]}
                          allowFontScaling= {false}
                          >
                          more info
                        </Text>
                        <View
                          style={[
                            styles.moreInfoIconWrapper,
                            styles.flexJcCtAiCt,
                          ]}>
                          <Image
                            style={[styles.moreInfoIconArrow]}
                            source={require('../../../assets/peer-to-peer/more-info.png')}
                          />
                        </View>
                      </View>
                    ) : (
                      <View style={styles.flexRow}>
                        <Text
                          style={[
                            windowWidth > 1366
                              ? styles.textPrimeBold
                              : styles.textPrimeSmBold
                          ]}
                          allowFontScaling= {false}
                          >
                          hide info
                        </Text>
                        <View
                          style={[
                            styles.moreInfoIconWrapper,
                            styles.flexJcCtAiCt,
                          ]}>
                          <Image
                            style={[styles.moreInfoIconArrow]}
                            source={require('../../../assets/peer-to-peer/more-info-hide.png')}
                          />
                        </View>
                      </View>
                    )}
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </View>
          </TouchableOpacity>

          {/* Decline Connect Button - start*/}
          {Boolean(displayConnectDeclineButtons) && (
            <>
              <View style={styles.itemColBtnSp} />
              <View style={styles.itemColBtn}>
                <CardButton
                  decline={true}
                  onPressButton={() =>
                    onSelectActionStatus(connectionId, actionStatus.DECLINED)
                  }
                  wait={wait && connectionId === connectionID ? true : false}
                  windowWidth={windowWidth}
                />
              </View>

              <View style={styles.itemColBtnSp} />
              <View style={styles.itemColBtn}>
                <CardButton
                  onPressButton={() =>
                    onSelectActionStatus(connectionId, actionStatus.CONNECTED, peerProfile)
                  }
                  connecting={
                    connecting && connectionId === connectionID ? true : false
                  }
                  windowWidth={windowWidth}
                />
              </View>
            </>
          )}
          {/* Decline Connect Button - end*/}

          {/* status Button - start*/}
          {Boolean(displayStatusButton) && (
            <>
              <StatusButton
                windowWidth={windowWidth}
                btnStatus={connectionStatus}
                onPressConnect={() => onPressConnect(peerProfile)} //send connection request(message)
                displaySendIcon={displaySendIcon}
                onPressSend={() => onPressSend(peerProfile)}
                foundChatOnList={foundChatOnList}
              />
            </>
          )}
          {/* status Button - end*/}

          {/* setting Button - start*/}
          {Boolean(displaySettingsButton) && (
            <>
              <View style={styles.itemColBtnSp} />
              <View style={styles.itemColSettingBtnLg}>
                <SettingsButton
                  windowWidth={windowWidth}
                  btnStatus={connectionStatus}
                  onSelectOption={actionStatus =>
                    onSelectActionStatus(
                      connectionId,
                      actionStatus,
                      peerProfile,
                    )
                  }
                />
              </View>
            </>
          )}
          {/* setting Button - end*/}
        </View>



        {Boolean(displayInfo) && Boolean(showInfo) && (
          <>
            {DisplayInfo('Conditions', true)}
            {story !== null && story.trim() !== '' && (DisplayInfo('Story'))}
          </>
        )}

        {Boolean(displayReceivedMessage) && (
          <ContentBlock title={'Message'} message={message} windowWidth={windowWidth} showInfo={showInfo}/>
        )}

        <View style={[styles.flexRow]}>
          {windowWidth > TAB_BREAk_POINT_START && !chatScreen && (
            <View style={styles.itemColImgSp} />
          )}
          <View style={[styles.itemColBorder, styles.greyBorderThin]} />
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    hasNotch: state.uistate.hasNotch,
    showHideStatusDetails :  state.peerToPeer.showHideStatusDetails || {}
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setViewMoreShowHideDetails: item =>
      dispatch(actions.setViewMoreShowHideDetails(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardItem);
