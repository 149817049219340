import React from "react";
import { View } from "react-native";
import styles from "../../Css";
import LogoSetUp from "../../components/LogoSetUp/LogoSetUp";

const sectionHeader = (props) => (
  <React.Fragment>
    <View style={styles.gridRow}>
      <View style={[styles.gridColFull, styles.infoPdBotSm]}>
        <LogoSetUp />
      </View>
    </View>
    <View style={styles.gridRow}>
      <View style={[styles.gridColFull, styles.pdBotLg]}>
        <View style={styles.greyBorderThinTop} />
      </View>
    </View>
  </React.Fragment>
);

export default (sectionHeader);
