import React, { useState, useEffect } from "react";
import { View, Text, Platform } from "react-native";
import SurveyTableGraph from "./SurveyTableGraph";
import styles from '../../../../Css';
import { WEB, TAB_BREAk_MAX_WIDTH } from "../../../../util/platformWindowConfig";

const MENU_ICON_VIEW = 60;
const MENU_ITEM_VIEW = 255;
const MINIMUM_WIDTH_FOR_DASHBOARD_FOR_DUAL_COLUMN = 600;
const MINIMUM_WIDTH_FOR_DASHBOARD_FOR_TRIPLE_COLUMN = 900;

const SurveyTableGraphWrapper = props =>  {
  const {
    windowWidth,
    surveyTableGraphData,
    tabDevice,
    tabMenuIconView
  } = props;

  const [contentWidth, setContentWidth] = useState(windowWidth - 0);
  
  useEffect(() => {
    let menuWidth = 0;
    if (tabDevice) {
        menuWidth = tabMenuIconView ? MENU_ICON_VIEW : MENU_ITEM_VIEW;
    } else if (Platform.OS === WEB && windowWidth > TAB_BREAk_MAX_WIDTH) {
        menuWidth = MENU_ITEM_VIEW;
    } else {
        menuWidth = 0;
    }
    setContentWidth(windowWidth - menuWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, tabDevice, tabMenuIconView]);

  const isDualColumn = contentWidth >= MINIMUM_WIDTH_FOR_DASHBOARD_FOR_DUAL_COLUMN;
  const isTripleColumn = contentWidth >= MINIMUM_WIDTH_FOR_DASHBOARD_FOR_TRIPLE_COLUMN;

  const sliceArrayIntoChunks = (chunkSize, array) => {
    const res = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
  }
  const chunks = sliceArrayIntoChunks(Math.ceil(surveyTableGraphData.length / (isTripleColumn ? 3 : isDualColumn ? 2 : 1)), surveyTableGraphData);

  return (
    <>
    <Text style={[styles.textTitleExLgBold, styles.infoPd]}>Summary</Text>
    <View style={[styles.flexJcCtAiCt, styles.secSpPdBot, isDualColumn || isTripleColumn ? styles.flexRow : styles.flexCol]}>
        <View style={isTripleColumn ? styles.fieldColOneThird : isDualColumn ? styles.fieldColHalf : styles.contColFul}>
            {chunks[0]?.map((data, index) =>
                <SurveyTableGraph
                    index={index}
                    total={chunks[0]?.length}
                    dataSet={data}
                />  
            )}
        </View>
        {isDualColumn && surveyTableGraphData.length >= 2 &&
            <View style={isTripleColumn ? styles.fieldColOneThird : isDualColumn ? styles.fieldColHalf : styles.contColFul}>
                {chunks[1]?.map((data, index) =>
                    <SurveyTableGraph
                        index={index}
                        total={chunks[1]?.length}
                        dataSet={data}
                    />  
                )}
                {chunks[0]?.length !== chunks[1]?.length &&
                <View style={{height: Platform.OS === WEB ? 362 : 295 * (chunks[0]?.length - chunks[1]?.length)}}/>}
            </View>
        }
        {isTripleColumn && surveyTableGraphData.length >= 3 &&
            <View style={isTripleColumn ? styles.fieldColOneThird : isDualColumn ? styles.fieldColHalf : styles.contColFul}>
                {chunks[2]?.map((data, index) =>
                    <SurveyTableGraph
                        index={index}
                        total={chunks[2]?.length}
                        dataSet={data}
                    />  
                )}
                {chunks[0]?.length !== chunks[2]?.length &&
                <View style={{height: Platform.OS === WEB ? 362 : 295 * (chunks[0]?.length - chunks[2]?.length)}}/>}
            </View>
        }
    </View>
    <Text style={styles.textTitleExLgBold}>Appendix</Text>
    </>
  );
};

export default SurveyTableGraphWrapper;