import React from "react";
import { Image, View, Text } from "react-native";
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import * as constants from '../HealthJourneyConstant'


const PublishedBy = props =>  {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {publishedBy, name, badge} = props;
  return (
    <View
      style={[
        styles.ltPdLg,
        styles.infoPdTopBotExSm,
        styles.flexWrapView,
        styles.ninetySevenMxWd,
        {zIndex:-9}
      ]}>
      <View
        style={[
          styles.flexRow,
          styles.flexAiCt,
          styles.flexJcFe,
          styles.topPdSm,
          stylesRes.careGiveradLtPd,
        ]}
        dataSet={{media: ids.careGiveradLtPd}}>
        <View>
          {publishedBy === constants.eventPublishedBy.PARTNER ? (
            <Image
              style={{width: 12, height: 12}}
              source={require('../../../assets/health-journey-icons/star-tick-icon.png')}
            />
          ) : (
            <View
              style={[
                styles.flexAiCt,
                styles.flexJcCt,
                styles.careGiverCircle,
              ]}>
              <Text
                style={[
                  styles.textPrimeSm,
                  styles.textWeightFiveHundred,
                  styles.textWhite,
                ]}>
                {badge}
              </Text>
            </View>
          )}
        </View>
        <View style={styles.navListLiContent}>
          <Text
            style={[
              styles.textPrimeBold,
              styles.textEm,
              styles.seaBluTxtColor,
            ]}>
            {name}
          </Text>
        </View>
        <View style={styles.pdLeftFour}>
          <Text
            style={[
              styles.textPrime,
              styles.textWeightFiveHundred,
              styles.textGreyDark,
              styles.textEm,
            ]}>
            Added this event
          </Text>
        </View>
      </View>
    </View>
  );
};

export default PublishedBy;