import React from "react";
import { View, ScrollView, Text, TouchableOpacity } from "react-native";
import _ from "underscore";
import styles from "../../../Css";
import Header from "../../Header/header";
import SpaceBar from "../../SpaceBar/SpaceBar";
import stylesResponsive from "../../../CssResponsive";
import ActivityIndicator from "../../ActivityIndicator/activityIndicator";
import PageContainer from "../../../components/PageLayout/PageContainer";
import SearchField from "../../../components/Search/searchField";
import NoResultFound from "../../../components/Search/noResultFound";
import SectionTitle from "../../../components/Text/sectionTitle";
import PlusButtonDark from "../../../components/Button/plusButtonDark";

const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();

const itemList = (res, props) => (
  <View style={[styles.singleItem, styles.greyBorder]} key={res.id}>
    <TouchableOpacity
      onPress={() => {
        props.onPressSelectSymptom(res.id);
      }}
      nativeID={'symptomsViewSymptom'}
    >
      <View style={[styles.messageRow]}>
        <View style={[styles.messageDetailSec, styles.fullHeight]}>
          <View style={[styles.messageDetailCont, styles.fullHeight]}>
            <View
              style={[
                stylesRes.customBorderCom,
                {
                  backgroundColor: res.displayProperties
                    ? res.displayProperties.color
                      ? res.displayProperties.color
                      : "white"
                    : "white",
                },
              ]}
            />
            <View
              style={[
                stylesRes.messageDetailCom,
                stylesRes.messageTopBotPdWeb,
                stylesRes.textMinHeight,
              ]}
            >
              <Text style={[styles.textTitle, styles.breakWord]} allowFontScaling={false}>
                {res.name}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  </View>
);
const symptoms = (props) => {
  return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={true}
        filterClickHandler={this.handleOnPressFilter}
        clearFiltersHandler={this.handleOnPressClearFilters}
        handleOnPressBackButton={props.handleOnPressBackButton}
        index={props.index}
        navigation={props.navigation}
        route={props.route}
      />
      <SpaceBar />
      <ScrollView style={styles.scrollViewArea}>
        <View style={styles.gridSection}>
          <PageContainer>
            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <View style={[styles.searchSection]}>
                    {/* button */}
                    <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt]}>
                      <View>
                        <SectionTitle 
                          smallTextTilte={true}
                          title={"Symptoms"}
                        />
                      </View>
                      <View style={[stylesRes.newConnectionBtnCr, styles.btnMgMd]} dataSet={{media: ids.newConnectionBtnCr}}>
                        <PlusButtonDark
                          touchableOnPress={props.onPressAddSymptom}
                          btnPosition={"full"}
                          btnText={"New Symptom"}
                          plusIconSm={true}
                          btnShadowNone={true}
                          nativeId={'symptomsAdd'}
                          newConnection={true}

                        />
                      </View>
                    </View>
                    {/* search bar */}
                    <View style={styles.fieldColSearch}>
                      <SearchField
                          searchText={props.keywords ? props.keywords : ""}
                          placeholder="Search existing symptoms"
                          onChangeText={props.searchUserDefinedSymptoms}
                          showClearBtton={
                            props.keywords && props.keywords !== "" ? true : false
                          }
                          clearFilter={() =>
                            props.searchUserDefinedSymptoms(null)
                          }
                          nativeId={'symptomsSearch'}
                          eventName={'Symptoms@ > Search'}
                        />
                    </View>
                </View>

                {_.isEmpty(props.userSymptoms) &&
                  !props.loadingWait &&
                  props.keywords !== "" &&
                  props.keywords !== null && <NoResultFound />}
              </View>
            </View>
          </PageContainer>

          {props.loadingWait ? (
            <ActivityIndicator pdLarge={true} />
          ) : (
            <PageContainer smScreenFull={true}>
              <View style={stylesRes.botExPd} dataSet={{media: ids.botExPd}}>
                <View style={[styles.gridRow, styles.contPdTop]}>
                  <View style={styles.gridColFull}>
                    <View style={styles.secArea}>
                      {/* Single Item Start */}

                        {Boolean(props.userSymptoms) && props.userSymptoms.length > 0 && (
                        <View
                          style={[styles.ItemWrapper, styles.greyBorderTop]}
                        >
                          {props.userSymptoms.map((res) => {
                            return itemList(res, props);
                          })}
                        </View>
                      )}

                      {/* Single Item End */}
                    </View>
                  </View>
                </View>
              </View>
            </PageContainer>
          )}
        </View>
      </ScrollView>
    </View>
  );
};

export default (symptoms);
