import theme from "./theme";
export const cursorPointer = {
  cursor: "pointer"
};
export const positionFixed = {
  position: "fixed"
};
export const pickerBg = {
  backgroundColor: theme.COLOR_WHITE
};
