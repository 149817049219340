import React from 'react';
import {Picker} from '@react-native-picker/picker';

const picker = props => {
  let key;
  let itemLength = props?.items?.length || null;
  if (itemLength) {
    const lastItem = props.items[itemLength - 1];
    key = lastItem.label + lastItem.value + lastItem.key;
  }

  return (
    <Picker
      key={key}
      itemStyle={[
        {fontSize: 15, textAlign: 'center'},
      ]}
      onValueChange={props.onValueChange}
      selectedValue={props.selectedValue}
      enabled={!props.disabled && Boolean(props?.items?.length)}
      style={props.style}
      >
      {props.items &&
        props.items.length > 0 &&
        props.items.map(item => (
          <Picker.Item
            label={item.label}
            value={item.value}
          />
        ))} 
    </Picker>
  );
};
export default picker;
