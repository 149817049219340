export const NAME = "Name";
export const PRESCRIBER = "Prescriber";
export const DIN_NPN = "DIN / NPN";
export const INSTRUCTION = "Instruction";
export const QUANTITY = "Quantity";
export const UNITS = "Units";
export const NOTES = "Notes";
export const QUESTIONS = "Questions";
export const SAVE = "Save";
export const CANCEL = "Cancel";
export const TITLE = "New medicine or therapy";

// Therapy Types
export const Other = "Other"; //Other
export const MEDICATION = "Medication";
export const NATURAL_HEALTH_PRODUCT = "Natural Health Product";
export const SURGERY = "Surgery";
export const ACTIVITY = "Activity";
