import React, {PureComponent} from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  ScrollView,
  Linking,
} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Modal from '../../components/Modal/modal';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import {getHeightWidth} from '../../GlobalFunctions/PageSize';

class LearnHow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
    };
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    const {windowHeight, windowWidth} = nextProps;
    this.setState({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    });
  };

  privacypolicy = () => {
    setTimeout(() => {
      this.props.togglePrivacyStatment();
    }, 500);
    this.props.toggleLearnHow();
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {windowHeight, windowWidth} = this.state;
    const HeightWidth = getHeightWidth(windowHeight, false, windowWidth);
    const {
      topIconWidthImg,
      topIconHeightImg,
      botIconWidthImg,
      botIconHeightImg,
    } = HeightWidth;

    return (
      <Modal
        visible={this.props.showLearnHow}
        onRequestClose={this.props.toggleLearnHow}
        onBackdropPress={this.props.toggleLearnHow}
        // defaultStyle={[styles.customPopUpStyle, styles.mgTopAddJournal]}
      >
        <View style={[stylesRes.modalScrollBlock]} dataSet={{media: ids.modalScrollBlock}}>
          <TouchableOpacity
            style={[styles.touchSpaceHeader]}
            onPress={this.props.toggleLearnHow}
          />
          <TouchableOpacity
            onPress={this.props.toggleLearnHow}
            style={[styles.closeViewTouchWrap, styles.closeViewTopLg]}>
            <Image
              style={[styles.closeIcon, styles.closeViewTouchBtnSm]}
              source={require('../../assets/icons/close.png')}
            />
          </TouchableOpacity>
          <ScrollView style={[styles.modalScroller]}>
            <View style={[styles.popUpBlock, styles.bgLearnHow]}>
              <View style={styles.popUpContentArea}>
                <View style={styles.gridSectionPopup}>
                  <View style={stylesRes.modalContIn}>
                    <View style={styles.commonFullBlock}>
                      <View style={styles.commonTopBlock}>
                        <View style={styles.commonTopImg}>
                          <View
                            style={[
                              stylesRes.commonTopImgView,
                              {
                                width: topIconWidthImg,
                                height: topIconHeightImg,
                              },
                            ]}
                            dataSet={{media: ids.commonTopImgView}}>
                            <Image
                              style={[styles.imgCover]}
                              source={require('../../assets/setup/bg-intro-top.png')}
                            />
                          </View>
                        </View>
                      </View>

                      <View style={[styles.commonContentBlock1]}>
                        <View style={styles.gridRow}>
                          <View style={styles.gridColFull}>
                            <View style={[styles.textAreaFull]}>
                              <View style={[stylesRes.maxSectionView]} dataSet={{media: ids.maxSectionView}}>
                                <View
                                  style={[
                                    styles.textAreaView,
                                    styles.greyBorderThin,
                                    styles.colPdLtRtBot,
                                  ]}>
                                  <View
                                    style={[
                                      styles.titleBlockCom,
                                      styles.textSecPd,
                                    ]}>
                                    <Text
                                      style={[
                                        styles.textTitleMdBold,
                                        styles.textAlignCenter,
                                      ]}
                                      allowFontScaling={false}
                                      >
                                      Your data is encrypted and therefore
                                      rendered unreadable to unauthorized users
                                      and hackers.
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.titleBlockSub,
                                      styles.comSpSm,
                                    ]}>
                                    <Text
                                      style={[
                                        styles.textTitle,
                                        styles.textAlignCenter,
                                      ]}
                                      allowFontScaling={false}
                                      >
                                      We do not sell or give your personal
                                      information to third parties. This
                                      includes:
                                    </Text>
                                  </View>
                                  <View style={[styles.textListView]}>
                                    <View
                                      style={[
                                        styles.textListBlock,
                                        styles.pdSmList,
                                      ]}>
                                      <View
                                        style={[
                                          styles.textListIcon,
                                          styles.textListIconCom,
                                        ]}
                                      />
                                      <Text style={[styles.textPrime]} allowFontScaling={false}>
                                        Identity, contact and your payment
                                        information
                                      </Text>
                                    </View>

                                    <View
                                      style={[
                                        styles.textListBlock,
                                        styles.pdSmList,
                                      ]}>
                                      <View
                                        style={[
                                          styles.textListIcon,
                                          styles.textListIconCom,
                                        ]}
                                      />
                                      <Text style={[styles.textPrime]} allowFontScaling={false}>
                                        Journals, notes and questions
                                      </Text>
                                    </View>

                                    <View
                                      style={[
                                        styles.textListBlock,
                                        styles.pdSmList,
                                      ]}>
                                      <View
                                        style={[
                                          styles.textListIcon,
                                          styles.textListIconCom,
                                        ]}
                                      />
                                      <Text style={[styles.textPrime]} allowFontScaling={false}>
                                        Attachments
                                      </Text>
                                    </View>

                                    <View
                                      style={[
                                        styles.textListBlock,
                                        styles.pdSmList,
                                      ]}>
                                      <View
                                        style={[
                                          styles.textListIcon,
                                          styles.textListIconCom,
                                        ]}
                                      />
                                      <Text style={[styles.textPrime]} allowFontScaling={false}>
                                        Contacts and Health Reports
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </View>
                              <View style={[stylesRes.maxSectionView]} dataSet={{media: ids.maxSectionView}}>
                                <View
                                  style={[
                                    styles.textAreaView,
                                    styles.colPdTopLtRt,
                                  ]}>
                                  <View style={[styles.textBlockSub]}>
                                    <View
                                      style={[
                                        styles.textItemDet,
                                        styles.flexRowWrap,
                                      ]}>
                                      <Text
                                        style={[
                                          styles.textPrime,
                                          styles.textAlignCenter,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        If you have a privacy question or
                                        concern,
                                      </Text>
                                      <Text
                                        style={[
                                          styles.textPrime,
                                          styles.textAlignCenter,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        please read our{' '}
                                      </Text>
                                      <TouchableOpacity
                                        onPress={this.privacypolicy}>
                                        <Text
                                          style={[
                                            styles.textUnderline,
                                            styles.textPrime,
                                            styles.textAlignCenter,
                                          ]}
                                          allowFontScaling={false}
                                          >
                                          Privacy Policy
                                        </Text>
                                      </TouchableOpacity>
                                      <Text
                                        style={[
                                          styles.textPrime,
                                          styles.textAlignCenter,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        or contact our Privacy Officer at
                                      </Text>
                                      <TouchableOpacity
                                        onPress={() =>
                                          Linking.openURL(
                                            'mailto:info@hanalyticssolutions.com',
                                          )
                                        }
                                        title="info@hanalyticssolutions.com">
                                        <Text
                                          style={[
                                            styles.textUnderline,
                                            styles.textPrime,
                                            styles.textAlignCenter,
                                          ]}
                                          allowFontScaling={false}
                                          >
                                          info@hanalyticssolutions.com
                                        </Text>
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>

                      <View>
                        <View style={styles.commonBotImg}>
                          <View
                            style={[
                              stylesRes.commonBotImgView,
                              {
                                width: botIconWidthImg,
                                height: botIconHeightImg,
                              },
                            ]}
                            dataSet={{media: ids.commonBotImgView}}>
                            <Image
                              style={styles.imgCover}
                              source={require('../../assets/setup/bg-intro-bottom.png')}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    showLearnHow: state.uistate.showLearnHow,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleLearnHow: () => dispatch(actions.showLearnHow()),
    togglePrivacyStatment: () => dispatch(actions.showPrivacyStatment()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LearnHow);
