import React, { Component } from 'react';
import { connect } from 'react-redux';
import ViewMetric from '../../../components/Settings/metrics/viewMetric';
import * as actions from '../../../store/actions/index';
import ConfirmationBox from '../../../components/ConfirmationBox/simpleConfirmBox';
import { METRICS } from '../../../navigation/path';
import CannotDeleteBox from '../../../components/ConfirmationBox/cannotDeleteBox';
import { pendoTrackEvent } from '../../../util/pendoConfig';
import { chartTypes } from '../../../Constant/index';
class ViewMetricScreen extends Component {
  constructor(props) {
    super(props);
    if (props.getAllMetric) {
      this.state = {
        id: props.getAllMetric.id,
        metricName: props.getAllMetric.name,
        graphStyle: props.getAllMetric.displayProperties.style
          ? props.getAllMetric.displayProperties.style
          : props.getAllMetric.displayProperties.dashedLine === true 
            ? chartTypes.line
            : chartTypes.dash,
        color: props.getAllMetric?.displayProperties?.color,
        publishedBy: props.getAllMetric.publishedBy,
        uom: props.selectUOMArray ? props.selectUOMArray.symbol : '',
        recordings: this.getRecordings(props.getAllMetric.id),
        deleteConfirm: false,
        deleteWait: false,
        selectedId: null,
        cannotDeleteBox: false
      };
      this.tapCount = 0;
    }
  }

  // UNSAFE_componentWillReceiveProps(props) {
  //   const { error, errorDescription, getAllMetric } = props;
  //   this.setState({
  //     error,
  //     errorDescription,
  //     deleteWait: false,
  //     deleteConfirm: false,
  //     alertMessageTitle: null,
  //     recordings: this.getRecordings(getAllMetric.id)
  //   });
  // }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      if (this.props.getAllMetric) {
        this.setState({
          id: this.props.getAllMetric.id,
          metricName: this.props.getAllMetric.name,
          graphStyle: this.props.getAllMetric.displayProperties.style
            ? this.props.getAllMetric.displayProperties.style
            : this.props.getAllMetric.displayProperties.dashedLine === true 
              ? chartTypes.line
              : chartTypes.dash,
          color: this.props.getAllMetric?.displayProperties?.color,
          publishedBy: this.props.getAllMetric.publishedBy,
          uom: this.props.selectUOMArray ? this.props.selectUOMArray.symbol : '',
          recordings: this.getRecordings(this.props.getAllMetric.id),
          deleteConfirm: false,
          deleteWait: false,
          selectedId: null,
          cannotDeleteBox: false
        });
      }
      pendoTrackEvent('HealthData/@ViewHealthData');
    });
    this.props.navigation.addListener('blur', () => {
      this.tapCount = 0;
    });
  }

  onPressEditMetric = () => {
    this.tapCount = this.tapCount + 1;
    if (this.tapCount === 1) {
      pendoTrackEvent('ViewHealthData@ > 3Dots > Edit');
      if (this.state.recordings) {
        if (this.props.getAllMetric && this.props.selectedAllMetric) {
          let getAllMetric = this.props.getAllMetric;
          let selectedAllMetric = this.props.selectedAllMetric;

          getAllMetric.recordings =
            this.state.recordings !== null ? this.state.recordings : undefined;

          selectedAllMetric.selectMetricArray = getAllMetric;

          this.props.onSelectMetric(selectedAllMetric, () => {
            this.props.navigation.push(METRICS.editMetricPath);
          });
        }
      } else {
        if (this.props.selectedAllMetric) {
          this.props.onSelectMetric(this.props.selectedAllMetric, () => {
            this.props.navigation.push(METRICS.editMetricPath);
          });
        }
      }
    }
  };

  onPressDeleteMetric = id => {
    if (this.state.recordings) {
      this.setState({
        cannotDeleteBox: true
      });
    } else {
      this.setState({
        deleteConfirm: true,
        alertMessageTitle: 'Are you sure you want to delete this health data?',
        selectedId: id,
      });
    }
  };

  onPressDelete = () => {
    this.setState({ deleteWait: true });
    if (this.props.userDefinedMetrics && this.state.selectedId) {
      let selectedId = this.state.selectedId;
      const selectedMetric = this.props.userDefinedMetrics.filter(function (
        metric,
      ) {
        return metric.id === selectedId;
      });
      if (selectedMetric && selectedMetric.length >= 1) {
        pendoTrackEvent('ViewHealthData@ > 3Dots > Delete');
        this.props.onDeleteMetric(
          selectedMetric[0].id,
          this.props.userDefinedMetrics,
          res => {
            this.setState({
              deleteWait: false,
              deleteConfirm: false,
              alertMessageTitle: null,
              // cannotDeleteBox: true
            });
            if (res) {
              this.props.navigation.navigate(
                METRICS.path, {
                  from: this.props.route.name,
                }
              );
            }
          },
          this.props.journalUserId
        );
      }
    }
  };

  getRecordings = id => {
    let metrics = [];
    let records = [];
    const journalEntries = Object.values(this.props.journalEntriesProcessed)
    if (journalEntries && journalEntries.length > 0) {
      journalEntries.forEach(record => {
        if (record.metrics && record.metrics.length > 0) {
          record.metrics.forEach(re => {
            metrics.push(re);
          });
        }
      });
    }

    if (metrics && metrics.length > 0) {
      records = metrics.filter(res => {
        return res.metric === id;
      });
    }
    return records.length;
  };

  render() {
    return (
      <React.Fragment>
        <ViewMetric
          handleOnPressBackButton={() => {
            this.props.navigation.navigate(
              METRICS.path, {
                from: this.props.route.name,
              },
            );
          }}
          metricName={this.state.metricName}
          setColor={this.state.color}
          graphStyle={this.state.graphStyle}
          onPressEditMetric={this.onPressEditMetric}
          onPressDeleteMetric={this.onPressDeleteMetric}
          recordings={this.state.recordings}
          id={this.state.id}
          uom={this.state.uom}
          index={METRICS.index}
          navigation={this.props.navigation}
          route={this.props.route}
          publishedBy={this.state.publishedBy}
        />
        <ConfirmationBox
          visibleModal={this.state.deleteConfirm}
          handleCancel={() => {
            this.setState({ deleteConfirm: false });
          }}
          onRequestClose={() => {
            this.setState({ deleteConfirm: false });
          }}
          alertTitle={this.state.alertMessageTitle}
          handleConfirm={this.onPressDelete}
          confirmWait={this.state.deleteWait}
        />
        <CannotDeleteBox
          visibleModal={this.state.cannotDeleteBox}
          onBackdropPress={() => {
            this.setState({ cannotDeleteBox: false });
          }}
          onRequestClose={() => {
            this.setState({ cannotDeleteBox: false });
          }}
          name={"Health Data"}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    getAllMetric: state.metrics.selectedMetric?.selectMetricArray,
    selectUOMArray: state.metrics.selectedMetric?.selectUOMArray,
    selectedAllMetric: state.metrics.selectedMetric,
    userDefinedMetrics: state.routines.metricsDigest.userDefinedMetrics,
    journalUserId: state.uistate.journalUserId,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onDeleteMetric: (id, userDefinedMetrics, callBack, journalUserId) =>
      dispatch(actions.deleteMetric(id, userDefinedMetrics, callBack, journalUserId)),
    onSelectMetric: (selectedContact, callBack) =>
      dispatch(actions.onSelectMetric(selectedContact, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewMetricScreen);
