import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import * as RNLocalize from 'react-native-localize';
import styles from '../../Css';
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";

const NotificationInfo = (props) => {
  const { updateTimeZone, onPressCancel, timeZoneUpdating } = props;
  return (
    <View style={[styles.bgWhite, styles.flexJcCt, styles.colPdLtRt, styles.messageTopBotPdSm, styles.minHeightFiftySeven]}>
      <View style={[styles.flexRow, styles.flexAiCt, styles.flexJcSb]}>
        <View style={[styles.sixtyPerWd,styles.flexAiFs]}>
          <Text style={[styles.textPrimeBold, styles.lineHgTwenty]}>
            Have you travelled?
          </Text>
          <Text style={[styles.textPrimeBold, styles.timeZoneNotifiTxt, styles.textWeightFiveHundred, styles.lineHgTwenty]}>
            Would you like to update your time zone to {RNLocalize.getTimeZone()}?
          </Text>
        </View>
        <View style={[styles.flexRow, styles.flexAiCt]}>
          <TouchableOpacity onPress={updateTimeZone} disabled={timeZoneUpdating}>
            <View style={[styles.timeZoneUpdateBtn, styles.flexJcCt, styles.flexAiCt]}>
              {Boolean(timeZoneUpdating) &&
              <View style={[styles.timeZoneZctivityInd]}>
              <ActivityIndicator size="small"/> 
              </View>
              }
              <Text style={[styles.textPrimeLgBold, styles.textWhite]}>
                Update
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={onPressCancel}>
            <Image
              style={styles.crossNotifyIcn}
              source={require('../../assets/icons/menu-res-active.png')}
            />
          </TouchableOpacity>
        </View>

      </View>
    </View>


  );
};

export default (NotificationInfo);
