import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import styles from '../../Css';
import ActivityIndicator from '../ActivityIndicator/activityIndicator';


const arrowNextButton = props => {
  return (
    <View style={[styles.btnSectionLink, styles.flexJcCtAiFe, styles.arrowSecPdRt]}>
      <TouchableOpacity
        style={[props.touchableStyle]}
        onPress={props.touchableOnPress}
        disabled={props.disabled}>
        <View style={[styles.flexRow, styles.flexAiCt]}>
          {Boolean(props.nextWait) && (
            <View
              style={[
                styles.indicatorIconView,
                styles.indLoaderLg
              ]}>
              <ActivityIndicator pdZero={true} size="small" color={'#43ADB9'}/>
            </View>
          )}
          <Text style={[styles.textPrimeBold, styles.blueText, styles.textAlignRight, ]} allowFontScaling={false}>
            {props.btnText || 'Submit'}
          </Text>
          <View style={styles.iconViewExSmLt}>
            <Image
              style={styles.nextArrowThick}
              source={require('../../assets/icons/next-arrow-blue.png')}
            />
          </View>
        </View>
      </TouchableOpacity>
    </View>

  );
};

export default (arrowNextButton);
