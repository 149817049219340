import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  files: [],
  journalEntriesAdd: null,
  uploadedFiles: {},
};

const saveFilesToAttach = (state, action) => {
  return updateObject(state, {
    files: action.files
  });
};

const saveUploadedAttachments = (state, action) => {
  return updateObject(state, {
    uploadedFiles: action.files
  });
};

const clearFilesToAttach = state => {
  return updateObject(state, {
    files: {}
  });
};

const clearUploadedAttachments = state => {
  return updateObject(state, {
    uploadedFiles: {}
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_FILES_TO_ATTACH:
      return saveFilesToAttach(state, action);
    case actionTypes.CLEAR_FILES_TO_ATTACH:
      return clearFilesToAttach(state, action);
    case actionTypes.SAVE_UPLOADED_ATTACHMENTS:
      return saveUploadedAttachments(state, action);
    case actionTypes.CLEAR_UPLOADED_ATTACHMENTS:
      return clearUploadedAttachments(state, action);
    default:
      return state;
  }
};

export default reducer;
