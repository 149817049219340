import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from '../../../../Css';
import { connect } from 'react-redux';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextInputPlusMinus from '../../../TextInput/TextInputPlusMinus';
import TextPickerFrame from '../../../TextInput/textPickerFrame';
import TextFrame from '../../../TextInput/textFrame';
import * as actions from '../../../../store/actions/index';
//import ActivitySlider from '../../../RoutineTemplate/JournalSlider/ActivitySlider';
import Warning from '../../../Warning/warning';
import { verifyDuplicateMedication } from '../../../../util/commonUiLogic';

class UserItemEdit extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    this.state = {
      ...data,
      accept: true,
      wait: false,
      duplicatePopup: false,
      data
    };
  }
  componentDidMount() {
    const { data, treatmentsProcessed } = this.props;
    const { treatment } = data;
    const { dosages, publishedBy } = treatmentsProcessed[treatment];

    const units =
      dosages && dosages.length > 0 && dosages[0].units
        ? dosages[0].units
        : 'minutes';
    this.setState({
      units: units,
      publishedBy: publishedBy
    });
  }

  componentDidUpdate(prevPros, prevStates) {
    const { data = {} } = this.props;

    if (!isNaN(data.quantity) && data.quantity !== this.state?.data?.quantity) {
      this.setState({
        quantity: data.quantity,
        data
      })
    }

  }


  onChange = val => {
    const { units, publishedBy } = this.state;
    const { updateActivity, data, index } = this.props;

      this.setState({ quantity: val }, () => {
        updateActivity(index, {
          ...data,
          units: units,
          quantity: val,
          edited: publishedBy === 'PARTNER' ? false : true,
        });
        this.verifyDuplicateMedication(data.name, units, val);
      });
  };

  onValueChangeUnit = val => {
    let { quantity, publishedBy } = this.state;
    const { data, index, updateActivity } = this.props;

    this.setState(
      {
        units: val,
        quantity: quantity,
      },
      () => {


        updateActivity(index, {
          ...data,
          units: val,
          quantity: quantity,
          edited: publishedBy === 'PARTNER' ? false : true,
        });
        this.verifyDuplicateMedication(data.name, val, quantity);
      },
    );
  };

  verifyDuplicateMedication = (name, unit, quantity) => {
    const { listTherapie, hasInvalidNamesInActivity, hasInvalidNamesInMedication, hasInvalidNamesInHealthData,
      hasInvalidNamesInSymtoms } = this.props;
    verifyDuplicateMedication(name, null, unit, quantity, listTherapie, (exist, msg) => {
      if (exist) {
        this.setState(
          {
            duplicatePopup: true,
            errorDescription: msg,
          },
          () => {
            this.props.saveDisable(this.state.duplicatePopup);
          },
        );
      } else {
        this.setState(
          {
            duplicatePopup: false,
            errorDescription: null,
          },
          () => {
            if (!hasInvalidNamesInActivity && !hasInvalidNamesInMedication && !hasInvalidNamesInHealthData && !hasInvalidNamesInSymtoms) {

              this.props.saveDisable(false);
            } else {
              this.props.saveDisable(this.state.duplicatePopup);

            }
          },
        );
      }
    });
  };

  render() {
    const { treatmentsProcessed, deleteItem, index, data } = this.props;
    const {
      quantity,
      treatment,
      duplicatePopup,
      errorDescription,
    } = this.state;
    const { name, dosages } = treatmentsProcessed[treatment];
    const units = dosages && dosages[0] && dosages[0]?.units ? dosages[0]?.units : null;
    let category = this.props.type;
    return (
      <>
        <View style={[styles.ActivitySliderCardView, styles.bgDarkAsh]}>
          {category === 'RT' ?
            <View style={styles.fieldContainerCom}>
              <View style={[styles.fieldRowWrapperSm, styles.bgWhite, styles.borderTpLtRadius, styles.borderTpRtRadius]}>
                <View style={[styles.fieldColStretch, styles.iconLtSp]}>
                  <View style={styles.preDataLine}>
                    <Text style={[styles.textPrimeBold, styles.textBlack, styles.infoSp]}>
                      {name}
                    </Text>
                  </View>
                </View>
                <View style={[styles.fieldColDelete, styles.flexJcCt]}>
                  <TouchableOpacity onPress={() => deleteItem(index)}>
                    <Image
                      style={[
                        styles.delLgIcon
                      ]}
                      source={require('../../../../assets/icons/close-light-grey.png')}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            :
            null
          }
          <View style={[styles.iconLtSp, styles.barBotSp]}>
            {category !== 'RT' ?
              <Text style={[styles.textPrimeBold, styles.textBlack, styles.infoSp, styles.barTopSp]}>
                {name}
              </Text>
              :
              <Text style={[styles.textPrime, styles.textBlack]}>
                {category !== 'RT' ? 'My progress:' : 'Set goal:'}
              </Text>
            }
            <View style={category !== 'RT' ? [styles.flexRow, styles.mgTopLg, styles.rightSpSm] : [styles.flexRow, styles.mgTopLg]}>
              <View>
                <TextInputPlusMinus
                  title={'Quantity'}
                  fieldBg={FieldDetails.BGWHITE}
                  fieldAlign={FieldDetails.ALIGNCENTER}
                  fieldLt={false}
                  darkTxt={true}
                  value={quantity}
                  onChange={this.onChange}
                  type={'ACTIVITY'}
                  units={this.state.units}
                  max={null}
                  activitySlider={true}
                />
              </View>
              <View style={styles.wdTen}></View>
              <View style={category !== 'RT' ? [styles.fieldColStretch, styles.rightSpSm] : [styles.fieldColPickerCom]}>
              {data?.units || units ?
                <TextFrame
                  title={'Measure'}
                  fieldBg={FieldDetails.BGGREY}
                  fieldValue={data?.units || units}
                  editable={false}
                  activitySlider={false}
                />
                :
                <TextPickerFrame
                  title={'Measure'}
                  fieldBg={FieldDetails.BGWHITE}
                  // fieldAlign={FieldDetails.ALIGNCENTER}
                  fieldLt={false}
                  darkTxt={true}
                  items={[
                    { key: 'hours', value: 'hours', label: 'hours' },
                    { key: 'minutes', value: 'minutes', label: 'minutes' },
                    {
                      key: 'repetitions',
                      value: 'repetitions',
                      label: 'repetitions',
                    },
                    { key: 'sessions', value: 'sessions', label: 'sessions' },
                  ]}
                  selectedValue={this.state.units}
                  onValueChange={this.onValueChangeUnit}
                  activitySlider={false}
                />
              }
              </View>
              {category !== 'RT' ? <View style={[styles.fieldColDelete, styles.flexJcCt]}>
                <TouchableOpacity onPress={() => deleteItem(index)}>
                  <Image
                    style={[
                      styles.delLgIcon
                    ]}
                    source={require('../../../../assets/icons/close-light-grey.png')}
                  />
                </TouchableOpacity>
              </View> : null}
            </View>
          </View>
          {Boolean(duplicatePopup) && (
            <Warning
              errorDescription={errorDescription}
              textPosition={'left'}
              warningBlockSp={category !== 'RT' ? true : false}
              warningBlockMg={true}
              journalTreatmentWarning={true}
            />
          )}
        </View>
        <View style={[styles.secSepBorderBot, styles.comSp, styles.titleTopPd]} />
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    treatmentsProcessed: state.routines.treatmentsProcessed,
    listTherapie: state.routines.treatments,
    hasInvalidNamesInActivity: state.therapie.hasActivityInvalidName,
    hasInvalidNamesInMedication: state.therapie.hasMedicationInvalidName,
    hasInvalidNamesInHealthData: state.therapie.hasHealthDataInvalidName,
    hasInvalidNamesInSymtoms: state.therapie.hasSymptomsInvalidName,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.updateTherapie(addNewTherapie, callBack, journalUserId)),
    displayProperties: (displayPrope, journalUserId) =>
      dispatch(
        actions.updateGraphDisplayProperties(displayPrope, journalUserId),
      ),
    saveDisable: flag => dispatch(actions.updatesaveDisable(flag)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserItemEdit);
