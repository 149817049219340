import React from "react";
import { View, Image } from "react-native";
import styles from "../../../../Css";

const lockBlock = (props) => {
  return (
    <View
      style={[
        styles.listLockSec,
        styles.colPdTopBotRg,
        styles.flexJcCtAiCt,
      ]}
    >
      <Image
        style={[
          styles.lockLg,
        ]}
        source={require("../../../../assets/icons/lock-lg.png")}
      />
    </View>
  );
};

export default lockBlock;
