import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import {View, Keyboard} from 'react-native';
import styles from '../../../Css';
import * as FieldDetails from '../../TextInput/fieldDetails';
//import AutoCompleteFrame from '../../AutoComplete/AutoCompleteFrame';
import AutoCompleteSuggestion from '../../AutoComplete/AutoCompleteSuggestion';
import {autoSuggestionListType, chartTypes} from '../../../Constant/index';
import { getSelectedItems, getSuggestedTeams } from '../../../util/commonUiLogic';
class StepThreeAddSymptom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.symptomName ? props.symptomName : '',
      results: [],
      terms: [],
      userTerms: [],
      selectedSymptoms: getSelectedItems(props.symptoms),
    };
  }

  componentDidMount() {
    this.getSystemDefinedSymptoms();
  }

  getSystemDefinedSymptoms = () => {
    //this.setState({loadingSymptoms: true});
    this.props.updateSystemDefinedSymptoms((result, data) => {
      if (result) {
        this.updateTermList(data);
      }
      //this.setState({loadingSymptoms: false})
    });
  };

  updateTermList = data => {
    let termsArray = getSuggestedTeams(data, this.props.userSymptoms);
    this.setState({
      terms: [...termsArray],
      userTerms: getSuggestedTeams(this.props.userSymptoms, data, true)
    });
  };

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({selectedSymptoms: getSelectedItems(props.symptoms)});
  }

  onChangeTextName = currentInput => {
    this.setState({
      name: currentInput,
    });
    this.props.onUpdateSymptomName(currentInput);
  };

  onItemPress = (selection, type) => {
    Keyboard.dismiss();
    let selectedValue = selection.data;
    let displayProperties = selection?.data?.displayProperties;
    let symptoms = [];
    let filteredItems = [];
    if (this.props.symptoms) {
      symptoms = [...this.props.symptoms];
      filteredItems = symptoms.filter(
        res => res.symptom.name === selectedValue.name,
      );
    }

    if (filteredItems.length === 0) {
      symptoms.push({
        symptom: {...selectedValue, addedFromRW: type === 'SYSTEM' ? true : false},
        isActive: true,
        id: -1,
        name: selectedValue.name,
        createdOn: null,
        modifiedOn: null,
        artifactId: null,
        displayProperties: {
          color: displayProperties?.color,
          style: displayProperties?.style,
          scaleMin: displayProperties?.scaleMin,
          scaleMax: displayProperties?.scaleMax,
          description: displayProperties?.description
        },
        type: null
      });

      this.props.updateSymptom(symptoms, () => {
        this.setState({
          name: '',
          results: [],
        });
        this.props.updateInActive(false);
      });
    }
    this.setState({
      name: '',
      results: [],
    });
    this.props.onUpdateSymptomName('');
  };

  onAddNewPress = () => {
    Keyboard.dismiss();
    if (this.state.name && this.validateSymptom()) {
      let symptoms = [];
      let filteredItems = [];
      if (this.props.symptoms) {
        symptoms = [...this.props.symptoms];
        filteredItems = symptoms.filter(
          res => res.symptom.name === this.state.name,
        );
      }

      if (filteredItems.length === 0) {
        symptoms.push({
          symptom: {name: this.state.name, addedFromRW: true},
          isActive: true,
          id: -1,
          name: this.state.name,
          displayProperties: {
            color: 'rgba(204, 136, 37, 1)',
            style: chartTypes.line,
            scaleMin: null,
            scaleMax: null,
          },
          type: 'custom'
        });
        this.props.updateSymptom(symptoms, () => {
          this.setState({
            name: '',
            results: [],
          });
          this.props.updateInActive(false);
        });
      }
      this.setState({
        name: '',
        results: [],
      });
      this.props.onUpdateSymptomName('');
    }
  };

  validateSymptom = () => {
    let valid = true;
    if (this.props.symptoms && this.props.symptoms.length !== 0) {
      valid = this.props.symptoms.some(
        res => !(res.symptom.name === this.state.name),
      );
    }
    return valid;
  };
  clearInputAndTerms = () => {
    this.setState({
      name: '',
      results: [],
    });
  };
  render() {
    return (
      <View style={[styles.fieldRow, styles.zIndexAutoComplete]}>
        <View style={[styles.fieldRowWrapper]}>
          <View style={styles.fieldColStretch}>
            <AutoCompleteSuggestion
              textFieldWithTitle={true}
              textFieldTitle="Enter Symptom"
              remortSearch={false}
              triggerLength={1}
              onChangeText={this.onChangeTextName}
              onItemPress={this.onItemPress}
              currentInput={this.state.name}
              onPressCustom={this.onAddNewPress}
              showCustomSection={true}
              //autoFocus={!this.state.loadingSymptoms ? true : false}
              autoFocus={true}
              terms={this.state.terms}
              userTerms={this.state.userTerms}
              selectedValues={this.state.selectedSymptoms}
              fieldBg={FieldDetails.BGWHITE}
              fieldLt={true}
              zoomIcon={true}
              removeIcon={true}
              placeholder="Search symptoms e.g. pain, stress, fever"
              updateTermList={() => this.getSystemDefinedSymptoms()}
              type={autoSuggestionListType.symptom}
              maxLength={250}
              //editable={!this.state.loadingSymptoms}
            />
          </View>
        </View>
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    symptoms: state.setup.symptoms,
    userSymptoms: state.routines.symptomsDigest
      ? state.routines.symptomsDigest.userSymptoms
      : [],
    symptomName: state.setup.symptomName,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateSymptom: (addNewSymptom, callBack) =>
      dispatch(actions.updateSymptom(addNewSymptom, callBack)),
    onUpdateSymptomName: name => dispatch(actions.updateSymptomName(name)),
    updateSystemDefinedSymptoms: callback =>
    dispatch(actions.updateSystemDefinedSymptoms(callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepThreeAddSymptom);
