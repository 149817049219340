import React, { Component } from 'react';
import { View, Image, Platform } from 'react-native';
import { connect } from 'react-redux';
import moment from 'moment';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Tabs from '../../components/Tab/Tab';
import Attachments from '../../components/Attachment/Attachments';
import JournalEntryHeader from '../../components/RoutineTemplate/JournalEntryHeader/View';
import Header from '../../components/Header/header';
import * as actions from '../../store/actions/index';
import ConfirmationBox from '../../components/ConfirmationBox/confirmationBox';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import Medications from '../../components/RoutineTemplate/Medications/View';
import Activity from '../../components/RoutineTemplate/Activity/View';
import Symptoms from '../../components/RoutineTemplate/Symptoms/View';
import HealthData from '../../components/RoutineTemplate/HealthData/View';
import Questions from '../../components/RoutineTemplate/Questions/View';
import Notes from '../../components/RoutineTemplate/Notes/View';
import AddAttachment from '../../components/Button/addAttachment';

import {
  DASHBOARD,
  JOURNALS,
  MEDICATIONS,
  ROUTINES,
  IMAGES_DOCUMENTS,
  TODOS,
  REPORTS
} from '../../navigation/path';
import StorageBanner from '../../components/Banner/StorageBanner';
import PageContainer from '../../components/PageLayout/PageContainer';
import NoJournalFound from '../../components/Search/noJournalFound';
import Cropper from '../../components/ImageCropper/AmazingCropperPage';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { ANDROID, TAB_BREAk_MAX_WIDTH, TAB_BREAk_POINT_END } from '../../util/platformWindowConfig';

class ViewRoutine extends Component {
  constructor(props) {
    super(props);
    if (props.journalEntrySelected) {
      const {
        journalEntrySelected,
        questionDigest,
        applicationSettingsDigest,
        treatmentsProcessed,
        journalEntryTypesProcessed,
      } = props;
      const {userArtifactHealthQuestions} = questionDigest;

      const {
        dosages,
        symptoms,
        metrics,
        entryType,
        entry,
        artifactId,
        flagged,
        deviceName,
      } = journalEntrySelected;

      const {quickNoteEntryTypeId} = applicationSettingsDigest;

      const questions = ViewRoutine.getQuestions(
        userArtifactHealthQuestions,
        artifactId,
      );
      const dosagesMedication = ViewRoutine.getMedicationDosages(
        dosages,
        treatmentsProcessed,
      );
      const dosagesActivity = ViewRoutine.getActivityDosages(
        dosages,
        treatmentsProcessed,
      );
      const journalEntryTypes = journalEntryTypesProcessed[entryType];

      this.state = {
        deleteConfirm: false,
        alertMessageTitle: null,
        deleteWait: false,
        artifactId: artifactId,
        entryType: entryType,
        quickNoteEntryTypeId: quickNoteEntryTypeId
          ? quickNoteEntryTypeId
          : null,
        entry: entry,
        flagged: flagged,
        questions: questions ? questions : [],
        symptoms: symptoms ? symptoms : [],
        metrics: metrics ? metrics : [],
        dosagesActivity: dosagesActivity ? dosagesActivity : [],
        dosagesMedication: dosagesMedication ? dosagesMedication : [],
        journalEntryTypes: journalEntryTypes ? journalEntryTypes : null,
        deviceJournal: deviceName ? true : false,
      };
    } else {
      this.state = {
        deleteConfirm: false,
        alertMessageTitle: null,
        deleteWait: false,
        artifactId: null,
        entryType: null,
        quickNoteEntryTypeId: null,
        entry: null,
        flagged: null,
        questions: [],
        symptoms: [],
        metrics: [],
        dosagesActivity: [],
        dosagesMedication: [],
        journalEntryTypes: null,
        isScrollDisabled: true,
        deviceJournal: false,
      };
    }
    if (this.props.activeTab !== 0) {
      this.props.setActiveTab(0);
    }
    this.tapCount = 0;
  }

  static getQuestions = (questions, artifactId) => {
    let questionsArray = [];
    if (questions) {
      questionsArray = questions.filter(function (question) {
        return question.artifactId === artifactId;
      });
    }

    const unique = questionsArray
      .map(e => e['questionId'])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => questionsArray[e])
      .map(e => questionsArray[e]);
    return unique;
  };

  static getMedicationDosages = (dosages, treatmentsProcessed) => {
    return dosages.filter(res => {
      if (treatmentsProcessed[res.treatment]) {
        return treatmentsProcessed[res.treatment].treatmentType !== 5;
      }
    });
  };

  static getActivityDosages = (dosages, treatmentsProcessed) => {
    return dosages.filter(res => {
      if (treatmentsProcessed[res.treatment]) {
        return treatmentsProcessed[res.treatment].treatmentType === 5;
      }
    });
  };

  journalHasAttachments = artifactId => {
    let attachments = [];
    if (this.props.attachmentsCompleteDigest) {
      attachments = this.props.attachmentsCompleteDigest.userArtifactAttachments.filter(
        function (attachment) {
          return attachment.userArtifact === artifactId;
        },
      );
    }
    return attachments.length > 0;
  };

  handleDeleteJournalConfirmation = () => {
    this.setState({
      deleteConfirm: true,
      alertMessageTitle: 'Delete Entry',
    });
  };

  handleDeleteJournal = () => {
    this.setState({ deleteWait: true });

    const {
      journalEntrySelected,
      onDeleteJournal,
      journalUserId,
      journalEntriesProcessed,
    } = this.props;
    const { id } = journalEntrySelected;

    onDeleteJournal(
      id,
      journalEntriesProcessed,
      res => {
        this.setState({
          deleteWait: false,
          deleteConfirm: false,
          alertMessageTitle: null,
        });
        if (res) {
          this.handleOnPressBackButton();
        }
      },
      journalUserId,
    );
  };

  handleOnPressBackButton = () => {
    if (this.props.windowWidth < TAB_BREAk_POINT_END) {
      this.handleRedirectWeb(JOURNALS.path);
    } else {
      this.props.deleteSuccess();
    }
  };

  handleEditJournal = () => {
    this.tapCount = this.tapCount + 1;
    const {
      journalEntrySelected,
      editRoutineTemplate,
      navigation,
      editSuccess,
      windowWidth,
      editJournalEntry,
      journalUserId,
      route,
    } = this.props;
    if (this.tapCount === 1) {
      editJournalEntry(journalEntrySelected?.id, journalUserId, (success, res) => {
        if (success) {
          editRoutineTemplate(res);
          if (windowWidth < TAB_BREAk_POINT_END) {
            const { routes = [] } = navigation.getState();
            // navigation.navigate(
            //   JOURNALS.addJournalPath,{
            //     from: this.props.route.name,
            //     journalName: journalEntrySelected?.title
            //   }
            // );
            navigation.reset({
              index: routes.length,
              routes: [...routes.filter(item => item.name !== JOURNALS.addJournalPath), {
                name: JOURNALS.addJournalPath,
                params: {
                  from: route.name,
                  journalName: journalEntrySelected?.title
                }
              }],
            });
          } else {
            editSuccess(journalEntrySelected?.title);
          }
        } else {
          this.tapCount = 0;
        }
      });
    }
  };

  handleCopytoNew = () => {
    this.tapCount = this.tapCount + 1;
    const {
      journalEntrySelected,
      onSaveJournalEntry,
      journalUserId,
      copyRoutineTemplate,
      navigation,
      windowWidth,
      route,
      editSuccess,
      showSuccessPopup,
      editJournalEntry
    } = this.props;
    if (this.tapCount === 1) {
      pendoTrackEvent('Journal@ > copytonew');
      editJournalEntry(journalEntrySelected?.id, journalUserId, (success, res) => {
        if (success) {
          const journalEntry = {
            ...res,
            id: -1,
            entryDate: parseInt(moment().format('x')),
          };
          onSaveJournalEntry(
            { journalEntry: journalEntry },
            (res, journal) => {
              if (res) {
                copyRoutineTemplate(journal.journalEntry);
                if (windowWidth < TAB_BREAk_POINT_END) {
                  navigation.navigate(
                    JOURNALS.addJournalPath, {
                      from: route.name,
                      journalName: journalEntrySelected?.title
                    }
                  );
                } else {
                  editSuccess(journalEntrySelected?.title);
                }
                showSuccessPopup(8);
              } else {
                this.tapCount = 0;
              }
            },
            journalUserId,
            [],
            true
          );
        } else {
          this.tapCount = 0;
        }
      }, true);
    }
  };

  handleRedirectRoutine = () => {
    const { navigation } = this.props;
    const selectEntry = {
      name: this.props.journalEntrySelected.title,
      description : this.props.journalEntrySelected.description,
      defaultMetrics : this.props.journalEntrySelected.metrics,
      defaultSymptoms : this.props.journalEntrySelected.symptoms,
      defaultTreatments: this.props.journalEntrySelected.dosages
    }
    pendoTrackEvent('Journal@ > convert');
    navigation.navigate(
      ROUTINES.addRoutineTemplatePath, {
        selectedEntry: selectEntry,
      }
    );
  }

  handleRedirectWeb = path => {
    const {navigation, route} = this.props;
    navigation.navigate(path, {
      from: route.name,
    });
  };

  handleRedirectMedications = () => {
    this.handleRedirectWeb(MEDICATIONS.viewTherapiesPath);
  };

  componentDidMount = () => {
    this.setState({
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
    });
    pendoTrackEvent('Journals/@ViewJournal');
    this.props.navigation.addListener('blur', () => {
      this.tapCount = 0;
    });
  };

  static getDerivedStateFromProps(props, state) {
    const {journalEntrySelected} = props;
    if (journalEntrySelected) {
      const {
        applicationSettingsDigest,
        route,
        navigation,
        questionDigest,
        treatmentsProcessed,
        windowWidth,
        windowHeight,
        journalEntryTypesProcessed,
        fromMain
      } = props;

      const {quickNoteEntryTypeId} = applicationSettingsDigest;
      const {userArtifactHealthQuestions} = questionDigest;

      const {
        dosages,
        symptoms,
        metrics,
        entryType,
        entry,
        artifactId,
        flagged,
        title,
        deviceName
      } = journalEntrySelected;

      const dosagesMedication = ViewRoutine.getMedicationDosages(
        dosages,
        treatmentsProcessed,
      );

      const dosagesActivity = ViewRoutine.getActivityDosages(
        dosages,
        treatmentsProcessed,
      );

      const questions = ViewRoutine.getQuestions(
        userArtifactHealthQuestions,
        artifactId,
      );
      const journalEntryTypes = journalEntryTypesProcessed[entryType];
      //TO-DO remove previous navigation changes
      // if (
      //   location.state &&
      //   location.state.from &&
      //   (location.state.from === JOURNALS.path ||
      //     location.state.from === JOURNALS.addJournalPath) &&
      //   windowWidth &&
      //   windowWidth > 1023
      // ) {
      //   history.push({
      //     pathname: JOURNALS.path,
      //     state: {
      //       from: location.pathname,
      //     },
      //   });
      // }
      if (
        // route?.params &&
        // route?.params.from &&
        // (route?.params.from === JOURNALS.path ||
        //   route?.params.from === JOURNALS.addJournalPath) &&
        windowWidth &&
        windowWidth > TAB_BREAk_MAX_WIDTH &&
        !fromMain
      ) {
        navigation.navigate(
          JOURNALS.path, {
            from: route.name,
            journalPath: JOURNALS.viewJournalPathSuffix,
            journalName: title
          }
        );
      }
      return {
        entry: entry,
        flagged: flagged,
        entryType: entryType,
        artifactId: artifactId,
        questions: questions ? questions : [],
        symptoms: symptoms ? symptoms : [],
        metrics: metrics ? metrics : [],
        dosagesActivity: dosagesActivity ? dosagesActivity : [],
        dosagesMedication: dosagesMedication ? dosagesMedication : [],
        quickNoteEntryTypeId: quickNoteEntryTypeId
          ? quickNoteEntryTypeId
          : null,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        journalEntryTypes: journalEntryTypes ? journalEntryTypes : null,
        deviceJournal: deviceName ? true : false,
      };
    }
    return null;
  }

  handleOnChangeflagged = () => {
    const {
      onSaveJournalEntry,
      journalEntrySelected,
      onSetSelectedJournalEntry,
      journalUserId,
    } = this.props;
    const flagged = this.state.flagged === true ? false : true;
    const selectedJournalEntry = {
      ...journalEntrySelected,
      flagged,
    };
    pendoTrackEvent('Journal@ > Favourite');
    onSaveJournalEntry(
      {journalEntry: {...journalEntrySelected, flagged}},
      () => {
        this.setState({flagged: flagged});
        onSetSelectedJournalEntry(selectedJournalEntry);
      },
      journalUserId,
    );
  };

  onPressBackButton = showListRoutineHeader => {
    const {navigation, route} = this.props;
    if (!showListRoutineHeader) {
      if(route?.params &&
        route?.params.from && (
        route?.params.from === IMAGES_DOCUMENTS.path ||
        route?.params.from === TODOS.path ||
        route?.params.from === REPORTS.viewReportPath ||
        route?.params.from === REPORTS.newReportPath ||
        route?.params.from === REPORTS.editReportPath)
      ) {
        navigation.reset({
          index: 0,
          routes: [{
            name: route?.params.from,
            params: {from: route.name}
          }],
        });
      } else {
        this.handleOnPressHomeButton();
      }
    } else {
      if (
        route?.params &&
        route?.params.from &&
        (route?.params.from === JOURNALS.path ||
          route?.params.from === TODOS.path ||
          route?.params.from === IMAGES_DOCUMENTS.path ||
          route?.params.from === REPORTS.viewReportPath ||
          route?.params.from === REPORTS.newReportPath ||
          route?.params.from === REPORTS.editReportPath
        )
      ) {
        navigation.reset({
          index: 0,
          routes: [{
            name: route?.params.from,
            params: {from: route.name}
          }],
        });
      } else {
        this.handleOnPressHomeButton();
      }
    }
  };

  handleOnPressHomeButton = () => {
    const {navigation} = this.props;
    navigation.reset({
      index: 0,
      routes: [{name: DASHBOARD.path}]
    });
  };

  disabledScroll = (val, callBack) => {
    this.setState({isScrollDisabled: val}, () => {
      if (callBack) {
        callBack();
      }
    });
  };

  selectedTab = tabid => {
    this.props.setActiveTab(tabid);
    if (tabid === 1) {
      this.props.fetchUserAttachments(this.state.artifactId);
    }
  };

  openCroper = (image) => {
    this.setState({
      showCropper: true,
      image: image,
    });
  }

  closeCropper = (croppedUri) => {
    if (croppedUri) {
      Image.getSize(croppedUri,(newHeight, newWidth) => {
        this.setState({
          showCropper: false,
          image: {...this.state.image, uri: croppedUri, height: newHeight, width: newWidth},
        });
      });
    } else {
      this.setState({
        showCropper: false,
        image: {...this.state.image},
      });
    }
  }

  imageUploadCompleted = () => {
    this.setState({image: null});
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {
      navigation,
      windowWidth,
      windowHeight,
      journalEntrySelected,
      journalUserId,
      hasNotch,
      fetchAttachmentSpinner,
      route,
    } = this.props;
    let showListRoutineHeader = false;
    if (windowWidth < TAB_BREAk_POINT_END) {
      showListRoutineHeader = true;
    }
    let headerHeight = 57;
    let careGiverAlertHeight = 0;
    if (journalUserId) {
      careGiverAlertHeight = 31;
    }
    let totHeight = headerHeight + careGiverAlertHeight;
    let totHeightResult = windowHeight - totHeight;

    if (journalEntrySelected) {
      const {
        flagged,
        dosagesMedication,
        dosagesActivity,
        artifactId,
        deleteConfirm,
        alertMessageTitle,
        deleteWait,
        symptoms,
        metrics,
        entry,
        entryType,
        quickNoteEntryTypeId,
        journalEntryTypes,
        deviceJournal,
      } = this.state;

      let smScreenMaxWidth = 1023;
      let outlookTabView = false;
      let outlookTabViewSmall = false;
      if (windowWidth >= smScreenMaxWidth) {
        outlookTabView = true;
      } else {
        outlookTabViewSmall = true;
      }

      if (this.state.showCropper) {
        this.props.hideMobileMenu();
      } else {
        this.props.showMobileMenu();
      }
      return (
        this.state.showCropper
        ? <Cropper
            image={this.state.image}
            close={this.closeCropper}
          />
        : <View style={[styles.pageBodyWrapper]}>
          <Header
            showFilers={false}
            showBack={true}
            showClearFilters={false}
            showNotify={true}
            handleOnPressBackButton={() =>
              this.onPressBackButton(showListRoutineHeader)
            }
            index={JOURNALS.index}
            navigation={navigation}
            route={route}
          />
          <SpaceBar />
          <StorageBanner />
          <KeyboardAwareScrollViewHoc
            hasNotch={hasNotch}
            scrollEnabled={this.state.isScrollDisabled}
            extraScrollHeight={Platform.OS === ANDROID ? 200 : 70}
          >
            <View style={styles.gridSection}>
              <JournalEntryHeader
                handleDeleteJournal={this.handleDeleteJournalConfirmation}
                handleOnPressBackButton={() => {
                  this.handleRedirectWeb(DASHBOARD.path);
                }}
                journalEntry={journalEntrySelected}
                journalEntryTypes={journalEntryTypes}
                handleEditJournal={this.handleEditJournal}
                handleCopytoNew={this.handleCopytoNew}
                handleConvertToRoutine={this.handleRedirectRoutine}
                iconStatus={showListRoutineHeader}
                flagged={flagged}
                changeFlagged={this.handleOnChangeflagged}
                deviceJournal={deviceJournal}
                testID={"journalHeader"}
              />

              {/* JournalEntryHeader End */}

              <Tabs
                pageContainer={true}
                smScreenFull={true}
                outlookScreen={true}
                screenFull={true}
                activeTab={this.props.activeTab}
                tabIndex={(res) => this.selectedTab(res)}
                outlookTabView={outlookTabView}
                outlookTabViewSmall={outlookTabViewSmall}>
                {/* First tab */}
                <View title="Entry" style={[styles.tabContentSection]}>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <PageContainer smScreenFull={true} outlookScreen={true} screenDynamic={true}>
                        <View style={[stylesRes.tabContent, stylesRes.contBotPd]} dataSet={{media: ids.contBotPd}}>
                          <View style={[styles.secArea]}>
                              {Boolean(dosagesMedication) &&
                              dosagesMedication.length > 0 && (
                                <Medications
                                  dosages={dosagesMedication}
                                  handleRedirectMedications={
                                    this.handleRedirectMedications
                                  }
                                  windowWidth={windowWidth}
                                />
                              )}
                              {Boolean(dosagesActivity) && dosagesActivity.length > 0 && (
                              <Activity
                                dosages={dosagesActivity}
                                windowWidth={windowWidth}
                                handleRedirectActivity={
                                  this.handleRedirectMedications
                                }
                              />
                            )}
                              {Boolean(symptoms) && symptoms.length > 0 && (
                              <Symptoms
                                symptoms={symptoms}
                                windowWidth={windowWidth}
                                disabledScroll={this.disabledScroll}
                              />
                            )}
                              {Boolean(metrics) && metrics.length > 0 && (
                              <HealthData metrics={metrics} />
                            )}

                            {entry !== null &&
                              entry !== undefined &&
                              entry.trim() !== '' && (
                                <Notes
                                  entry={entry}
                                  isQuickNote={
                                    quickNoteEntryTypeId === entryType
                                  }
                                />
                              )}
                            {/* {questions && questions.length > 0 && ( */}
                              <Questions type={"selectedJournal"} bgLight={true} journalEntry={journalEntrySelected} />
                            {/* )} */}
                          </View>
                        </View>
                      </PageContainer>
                    </View>
                    {/* </View> */}
                  </View>
                </View>
                {/* Second tab */}
                <View
                  title="Attachments"
                  activeCircle={this.journalHasAttachments(artifactId)}
                  fetchAttachmentSpinner={fetchAttachmentSpinner}
                  style={[styles.tabContentSection, styles.comStyling]}
                  attachmentCategory="Journal-view">
                  <PageContainer
                  // smScreenFull={true} outlookScreen={true}
                  smScreenFull={true}
                  outlookScreen={true}
                  screenDynamic={true}
                  >
                    <View style={[styles.attachWrapper, styles.attachWrapperPd]}>
                      <Attachments attachmentCategory="Journal-view"  attachmentSpaceZero={true} />
                      <AddAttachment
                        attachmentCategory="Journal-view"
                        btnText={"Add"}
                        btnPosition={"left"}
                        openCroper={this.openCroper}
                        tempCapturedImage={this.state.image}
                        onUploadCompleted={this.imageUploadCompleted}
                      />
                    </View>
                  </PageContainer>
                </View>
              </Tabs>
            </View>
            {/* </ScrollView> */}
          </KeyboardAwareScrollViewHoc>
          {/* </View> */}
          <ConfirmationBox
            visibleModal={deleteConfirm}
            onBackdropPress={() => {
              this.setState({deleteConfirm: false});
            }}
            onRequestClose={() => {
              this.setState({deleteConfirm: false});
            }}
            alertTitle={alertMessageTitle}
            handleDelete={this.handleDeleteJournal}
            deleteWait={deleteWait}
          />
        </View>
      );
    } else {
      return (
        <View style={[styles.pageBodyWrapper]}>
          <Header
            showFilers={false}
            showBack={true}
            showClearFilters={false}
            showNotify={true}
            handleOnPressBackButton={() =>
              this.onPressBackButton(showListRoutineHeader)
            }
            index={JOURNALS.index}
            navigation={navigation}
            route={route}
          />
          <SpaceBar />
          <StorageBanner />
          <NoJournalFound screenLg={true} resultHeight={totHeightResult} />
        </View>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    journalEntrySelected: state.routines.journalEntrySelected,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
    journalUserId: state.uistate.journalUserId,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    hasNotch: state.uistate.hasNotch,
    questionDigest: state.routines.questionDigest,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    fetchAttachmentSpinner: state.routines.journalAttachmentSpinner,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    activeTab: state.surveys.activeTab,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDeleteJournal: (journalId, journalEntriesProcessed, callBack, journalUserId) =>
      dispatch(
        actions.deleteJournal(
          journalId,
          journalEntriesProcessed,
          callBack,
          journalUserId,
        ),
      ),
    onSaveJournalEntry: (journalEntry, callBack, journalUserId, deletedSymptomIds, isFromCopyToNew) =>
      dispatch(actions.saveJournalEntry(journalEntry, callBack, journalUserId, deletedSymptomIds, isFromCopyToNew)),
    onSelectTherapie: (SelectedTherapie, callBack) =>
      dispatch(actions.selectTherapie(SelectedTherapie, callBack)),
    fetchUserAttachments: artifactId =>
      dispatch(actions.fetchUserAttachments(artifactId)),
    editRoutineTemplate: journalEntrie =>
      dispatch(actions.editRoutineTemplate(journalEntrie)),
    onSetSelectedJournalEntry: selectedId =>
      dispatch(actions.setSelectedJournalEntry(selectedId)),
    showSuccessPopup: id => dispatch(actions.showSuccessPopup(id)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    setActiveTab: activeTabIndex =>
      dispatch(actions.setActiveTab(activeTabIndex)),
    copyRoutineTemplate: (journalEntrie) =>
      dispatch(actions.copyRoutineTemplate(journalEntrie)),
    editJournalEntry: (id, journalUserId, callBack, isFromCopyToNew) =>
      dispatch(actions.editJournalEntry(id, journalUserId, callBack, isFromCopyToNew)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewRoutine);
