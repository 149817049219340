import React from 'react';
import { View, Text } from 'react-native';
import styles from '../../../../Css';
import ItemDeleteButton from '../../../Button/itemDeleteButton';
import SymptomSlider from '../../../../components/HASlider/symptomSlider';
import YesNoSwitch from '../../../../components/YesNoSwitch/yesNoSwitch';

const SymptomLevelSelector = ({
  symptomName,
  scaleMin,
  scaleMax,
  severity,
  // publishedBy,
  sliderDescriptions,
  onUpdateSymptom,
  onDeleteSymptom,
  data,
  disabledScroll
}) => {
  const {symptomObj: {publishedBy} = {}} = data
  const generateSliderSegments = () => {
    const sliderSegments = [...Array(11)].map(x => '');
    if (scaleMin !== null
        && scaleMax !== null
        && sliderDescriptions) {
      try {
        const tempDescriptionObjectArray = JSON.parse(sliderDescriptions);
        tempDescriptionObjectArray.forEach(({ value, description }) => {
          if (description) {
            sliderSegments[value] = description;
          }
        });
      } catch(error) {
      }
    }
    return sliderSegments;
  };

  return (
    <View style={[styles.editSymptomSliderOuterWrapper, styles.secSepBorderBot]}>
      <View>
        <View style={[styles.comSpSm]}>
          <Text style={[styles.textPrimeBold]} allowFontScaling={false}>{symptomName}</Text>
        </View>
        <View style={[
        styles.flexRow,
        styles.flexAiCt,
      ]}>
          <View style={styles.fieldColStretch}>
            {(scaleMin === 0 && scaleMax === 1) &&
            <YesNoSwitch
              value={(severity === null) ? null : (severity === 1 ? 'YES' : 'NO')}
              onChange={(status) => onUpdateSymptom(status === 'YES' ? 1 : 0)}
            />}
            {( (scaleMin === null && scaleMax === null)
              || (scaleMin === 0 && scaleMax === 10) ) &&
            <SymptomSlider
              value={severity}
              onUpdate={(severityLevel) => onUpdateSymptom(severityLevel)}
              valueDescriptions={generateSliderSegments()}
              colorMode={'GRADIENT'}
              disabledScroll={disabledScroll}
            />}
          </View>
          <View style={styles.fieldColDelete}>
          {publishedBy !== 'PARTNER' &&
            <ItemDeleteButton touchableOnPress={onDeleteSymptom} />
          }
          </View>
        </View>
      </View>
    </View>
  )
};

export default SymptomLevelSelector;