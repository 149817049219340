import React, {Component} from 'react';
import Modal from 'modal-enhanced-react-native-web';

class modal extends Component {
  escFunction = event => {
    if (event.keyCode === 27 && this.props.visible) {
      this.props.onBackdropPress();
    }
  };
  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }
  render() {
    let defaultStyle;
    if (this.props.defaultStyle) {
      defaultStyle = this.props.defaultStyle;
    } else {
      defaultStyle = {
        marginTop: 0,
        marginRight: 0,
        marginLeft: 0,
        marginBottom: 0,
        justifyContent: 'flex-start',
        alignItems: 'center',
      };
    }
    return (
      <>
      { this.props.visible &&
      <Modal
        onRequestClose={this.props.onRequestClose}
        isVisible={this.props.visible}
        animationType={
          this.props.animationNone
          ? 'none'
          : 'slide'
        }
        onBackdropPress={this.props.onBackdropPress}
        backdropColor={
          this.props.backdropStyle
          ? 'rgba(0, 0, 0, 0.8)'
          : 'rgba(64, 64, 65, 0.3)'
        }
        backdropOpacity={
          this.props.backdropStyle
          ? 0.9
          : 0.3
        }
        style={defaultStyle}>
        {this.props.children}
      </Modal>
      }
      </>
    );
  }
}

export default modal;
