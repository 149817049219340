import React from "react";
import { View, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";

const acceptButton = (props) => {
  return (
    <View style={[styles.acceptBtnView, styles.flexCom, styles.flexJcCtAiCt]}>
      <TouchableOpacity
        onPress={props.touchableOnPress}
        disabled={props.disabled}
      >
        <View style={[styles.acceptIconSection]}>
          {Boolean(props.btnDark) ? (
            Boolean(props.btnCircle) ? (
              <Image
                style={styles.acceptBtn}
                source={require("../../assets/icons/accept-circle-dark.png")}
              />
            ) : (
              <Image
                style={styles.acceptBtn}
                source={require("../../assets/icons/accept-dark.png")}
              />
            )
          ) : Boolean(props.btnCircle) ? (
            <Image
              style={styles.acceptBtn}
              source={require("../../assets/icons/accept-circle-light.png")}
            />
          ) : (
            <Image
              style={styles.acceptBtn}
              source={require("../../assets/icons/accept-light.png")}
            />
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (acceptButton);
