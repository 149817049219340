import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import Disclaimer from '../../../components/Settings/ClinicalTrials/Disclaimer';
import TickButton from '../../../components/CheckBox/CheckBox';
import {CLINICAL_TRIALS} from '../../../navigation/path';
import {getHeightWidth} from '../../../GlobalFunctions/PageSize';
import {handleCommonBackNavigation} from '../../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../../util/pendoConfig';
class DisclaimerScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
      ticked: false,
      wait: false,
    };
  }

  componentDidMount = () => {
    pendoTrackEvent('ClinicalTrials/@Disclaimer');
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const {windowHeight, windowWidth} = nextProps;
    this.setState({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    });
  };

  iAgreeButton = () => {
    if (this.state.ticked) {
      return (
        <TickButton
          imagePath={'tickButton'}
          toggleTickCallback={toggleVal => {
            this.ticked(toggleVal);
          }}
        />
      );
    } else {
      return (
        <TickButton
          imagePath={'untickButton'}
          toggleTickCallback={toggleVal => {
            this.ticked(toggleVal);
          }}
        />
      );
    }
  };
  ticked(toggleVal) {
    this.setState({ticked: toggleVal});
  }

  handleNextOnPress = () => {
    if (this.state.ticked) {
      this.setState({wait: true});
      this.props.updateClinicaltrailDisclaimer(
        true,
        this.props.journalUserId,
        res => {
          if (res) {
            this.props.onResetClinicalTrialState();
            this.props.navigation.replace(CLINICAL_TRIALS.path);
          } else {
            this.setState({wait: false});
          }
        },
      );
    }
  };
  handleOnPressBackButton = () => {
    handleCommonBackNavigation(this.props.navigation);
  };

  render() {
    const HeightWidth = getHeightWidth(this.state.windowHeight);
    const {
      windowHeightSmView,
      topIconWidthImg,
      topIconHeightImg,
      botIconWidthImg,
      botIconHeightImg,
    } = HeightWidth;

    return (
      <Disclaimer
        windowHeightSmView={windowHeightSmView}
        topIconWidthImg={topIconWidthImg}
        topIconHeightImg={topIconHeightImg}
        botIconWidthImg={botIconWidthImg}
        botIconHeightImg={botIconHeightImg}
        iAgreeButton={this.iAgreeButton()}
        isAgree={this.state.ticked}
        handleOnNext={this.handleNextOnPress}
        handleOnPressBackButton={this.handleOnPressBackButton}
        wait={this.state.wait}
        index={CLINICAL_TRIALS.index}
        navigation={this.props.navigation}
        route={this.props.route}
        onPressAggree={(val) => this.ticked(val)}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onResetClinicalTrialState: () =>
      dispatch(actions.resetClinicalTrialState()),
    updateClinicaltrailDisclaimer: (
      clinicalTrialDisclaimer,
      journalUserId,
      callBack,
    ) =>
      dispatch(
        actions.updateClinicaltrailDisclaimer(
          clinicalTrialDisclaimer,
          journalUserId,
          callBack,
        ),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisclaimerScreen);
