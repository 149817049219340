import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from '../../../Css';
import { pendoTrackEvent } from '../../../util/pendoConfig';

class CommonMenuItem extends Component {
  constructor(props) {
    super(props);
    const {isSelected} = props;
    this.state = {
      isSelected: isSelected,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {isSelected} = props;
    if (isSelected !== state.isSelected) {
      return {
        isSelected: isSelected,
      };
    }

    return null;
  }
  render() {
    const {
      title,
      route,
      navigation,
      onPressMenuItem,
      clearFilter,
      hasNotifications,
      handleTabMenuClick,
    } = this.props;
    const {isSelected} = this.state;

    return (
      <View style={styles.mainNavSubItem} nativeID={`menuItem${title.replace(/[&/ ]/g, '')}`}>
        <TouchableOpacity
          onPress={() => {
            if (handleTabMenuClick) {
              handleTabMenuClick()
              return;
            }
            if (onPressMenuItem) {
              onPressMenuItem();
            }
            if (clearFilter) {
              clearFilter();
            }
            pendoTrackEvent(`Menu > ${title.replace(/[&/ ]/g, '')}`);
            navigation.reset({
              index: 0,
              routes: [{ name: route }],
            });
          }}>
          <View
            nativeID={`menu${title.replace(/[&/ ]/g, '')}`}
            style={[
              styles.navListLiDet,
              styles.navListLiDetPdSm,
              styles.navListsubItemPd,
              Boolean(isSelected) ? styles.mainNavSelected : styles.mainNavDefault,
            ]}>
            <View style={[styles.navListLiContent]}>
              <Text
                style={[
                  Boolean(isSelected)
                    ? [styles.textPrimeBold, styles.textOrange]
                    : styles.textPrime,
                ]}
                allowFontScaling={false}
                >
                {title}
              </Text>
              {Boolean(hasNotifications) && (
              <View style={[styles.notifyAvailableMenu]} />
              )}
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default (CommonMenuItem);
