import * as actionTypes from './actionTypes';
import * as axiosCommon from '../../axios/axios-common';
import * as url from './urls';
import _ from "lodash";
import {
  logout,
  commonErrorHandler,
  cognitoReminder,
  setEmailVerified,
  updatep2pProfileSuccess,
  updatep2pPro,
} from './index';

// update Profile

export const updateProfile = (updateProfile, callBack, journalUserId) => {
  let requestUrl = url.UPDATE_PROFILE;

  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          if (res.responseDto.data.emailUpdated) {
            dispatch(cognitoReminder(false, true, null));
            dispatch(setEmailVerified(false));
          }
          if (res.responseDto.data && res.responseDto.data.peerProfile) {

            dispatch(updatep2pProfileSuccess(res.responseDto.data.peerProfile));
            dispatch(updatep2pPro(res.responseDto.data.peerProfile));
          }
          dispatch(updateProfileSuccess(res.responseDto.data));
          dispatch(updatePro(res.responseDto.data));
          if (res.responseDto.data.metricRecording) {
            dispatch(
              updateProfileJournalEntry(res.responseDto.data.metricRecording),
            );
          }

          callBack(true);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateProfile");
            dispatch(logout());
          } else if (res.errorDto !== 'undefined') {
            dispatch(updateProfileFail(res.errorDto.data.error));
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      updateProfile,
    );
  };
};

export const updateProfileSuccess = data => {
  return {
    type: actionTypes.UPDATE_PROFILE_SUCCESS,
    userUpdated: data.user,
  };
};

export const updatePro = data => {
  return {
    type: actionTypes.UPDATE_PROFILE,
    profile: data.user,
  };
};

export const updateProfileJournalEntry = data => {
  return {
    type: actionTypes.UPDATE_PROFILE_JOURNAL_ENTRY,
    metricRecording: data,
  };
};

export const updateProfileFail = errorMsg => {
  return {
    type: actionTypes.UPDATE_PROFILE_FAIL,
    errorDescription: errorMsg,
  };
};

export const profileInitConditions = (conditions, conditionValidation) => {
  return {
    type: actionTypes.PROFILE_INIT_CONDITIONS,
    conditions: conditions,
    conditionValidation: conditionValidation,
  };
};

export const profileUpdateCondition = (condition, index) => {
  return {
    type: actionTypes.PROFILE_UPDATE_CONDITION,
    condition: condition,
    index: index,
  };
};
