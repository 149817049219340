
import {ORGANIZATION_NAME} from './commonUiConfig';
import { Platform } from 'react-native';
/* global pendo */
import {PendoSDK} from 'rn-pendo-sdk';
import { WEB } from './platformWindowConfig';

export const PENDO_KEY = "eyJhbGciOiJSUzI1NiIsImtpZCI6IiIsInR5cCI6IkpXVCJ9.eyJkYXRhY2VudGVyIjoidXMiLCJrZXkiOiJmMmM3Nzc4ZTQ5NmQ0ODQ0N2Q5OGY1OWJkZDVjNGRlMzQ2MDE0MzE2MDEwYmQ2Y2QyYTZjY2UyMmJkNjkzOTY0NzU2ZThkMTkxYzBmOGU1MDc4MzUzNjYzM2JiOTcyZGEwYWQ2MzU0MzY5ZTFlMTc1Mjc3MWI0ZTM0MzQ0NmUzNmFjZGI2MjE0OTViMmQ2Nzg2NGJhMGI5NGNkNjZiYTMwMWVjYTJlNmQ2OWRlMjdkY2I0MGE0MWY1ODJlYmQ2OGY5ZGM0MGQzZGE2MjlhNDYxM2EzMTc2YmMxYjRmYzBmMi4yYzc2MmNlYWJiNWEyMmU4ZDA4YmQ3MzIxY2E4ODQ5My5iNDQ0YjlhY2VhYzAwNGYwYjNlN2Q1NjI5YjY5MDRmNzllZjUxM2IwODU2MGQ5ODRhYjQwMzQ1Zjg0MzMyOTM5In0.QeiFKEVoGDt1ZsSWPd9PfNAIUiX1EkzVshGjoqERt1mhDLsUpGm-onTYgUH7_Gexn8HiFHJ9g_VZnNR6wnkJ_2GfYrQ_YiaivJBK63sSeUFfeNGzgq9GmCieBbMZ-frFDNzaY_uqV8Uki4mzXCfQ71TJ2qAoONJh4E8SJIldTtk";
export const ACCOUNT_ID = ORGANIZATION_NAME + '_QA';


export const pendoTrackEvent = (event) => {
    Platform.OS === WEB
        ? pendo.track(event)
        : PendoSDK.track(event)
}

export const pendoClearVisitor = () => {
    Platform.OS !== WEB && PendoSDK.endSession();
}
