import React, {Component} from 'react';
import {View, Text} from 'react-native';
import {connect} from 'react-redux';
import styles from '../../../Css';
import TextPickerFrame from '../../../components/TextInput/textPickerFrame';
import * as FieldDetails from '../../../components/TextInput/fieldDetails';
import Prescriber from '../../AutoComplete/CommonAutoComplete/Prescriber';
import { TAB_BREAk_POINT_START } from '../../../util/platformWindowConfig';

class LabReportDetails extends Component {
  constructor(props) {
    super(props);
    this.types = [
      {value: 'LAB_REPORT', label: 'Lab Report', key: 'LAB_REPORT'},
      {value: 'CT_SCAN', label: 'CT Scan', key: 'CT_SCAN'},
      {value: 'MRI_REPORT', label: 'MRI Report', key: 'MRI_REPORT'},
      {
        value: 'DISCHARGE_SUMMARY',
        label: 'Discharge Summary',
        key: 'DISCHARGE_SUMMARY',
      },
      {value: 'CONSULT_NOTE', label: 'Consult Note', key: 'CONSULT_NOTE'},
      {value: 'PATHOLOGY', label: 'Pathology', key: 'PATHOLOGY'},
    ];
    const {healthProvider, healthProvidername, labReportType, contactsProcessed} = props;
    let healthProviderName = '';
    if (healthProvider && contactsProcessed[healthProvider]) {
      const {givenName, organization} = contactsProcessed[healthProvider];

      healthProviderName = givenName ? givenName : organization;
    } else if (healthProvidername) {
      healthProviderName = healthProvidername;
    }
    this.state = {
      healthProvider: healthProvider,
      labReportType: labReportType,
      healthProviderName: healthProviderName,
    };
  }

  onSelecthealthProvider = (id, name) => {
    const {updateHealthProvider} = this.props;
    this.setState(
      {
        prescribedBy: id,
        healthProviderName: name,
      },
      () => {
        updateHealthProvider(id, name);
      },
    );
  };

  onChangeLabReportType = id => {
    const {updateLabReportType} = this.props;
    this.setState({labReportType: id}, () => {
      updateLabReportType(id);
    });
  };

  render() {
    const {healthProvider, healthProviderName, labReportType} = this.state;
    return (
      <React.Fragment>
        <View style={[styles.secArea, styles.zIndexAutoComplete]}>
          <View style={[styles.formSection, 
            this.props.windowWidth > TAB_BREAk_POINT_START
            ? styles.colPdTopCom
            : styles.colPdTopLtRt
            ]}>
            <View style={[styles.titleSection, styles.comMgBot]}>
              <Text style={[styles.textPrimeBold]} allowFontScaling={false}>Lab and Report Details</Text>
            </View>
            <View style={styles.fieldRow}>
              <View style={[styles.fieldRowWrapper]}>
                <View style={styles.fieldColStretch}>
                  {/* <PickerTemplate invalid={false} pickerWithTitle={true} pickerTitle={'Type'}>
              <Picker
                items={this.types}
                style={[styles.pickerAreaField, styles.textPickerPdSmCom]}
                selectedValue={labReportType}
                onValueChange={this.onChangeLabReportType}
              />
            </PickerTemplate invalid={false} pickerWithTitle={false}> */}
                  <TextPickerFrame
                    title={'Type'}
                    fieldBg={FieldDetails.BGWHITE}
                    items={this.types}
                    fieldAlign={FieldDetails.ALIGNLEFT}
                    fieldLt={true}
                    selectedValue={labReportType}
                    onValueChange={this.onChangeLabReportType}
                  />
                </View>
              </View>
            </View>

            <View style={[styles.fieldRow, styles.noMgBot]}>
              <Prescriber
                prescribedBy={healthProvider}
                healthProviderName={healthProviderName}
                updatePrescribedBy={this.onSelecthealthProvider}
                textFieldTitle="Contact/Provider"
                customSectionTitle="Create new contact/provider"
                systemListTitel={'Select from list'}
                zoomIcon={true}
                placeholder={'Select a contact/provider'}
              />
            </View>
          </View>
        </View>
        <View style={[styles.secSepBorderBot, styles.zindexMin]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    contactsProcessed: state.routines.contactsProcessed,
  };
};

export default connect(
  mapStateToProps,
  null,
)(LabReportDetails);
