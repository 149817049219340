import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import moment from "moment";
import {
  getCoverIcon,
  lowerCaseAllWordsExceptFirstLetters
} from "../../util/commonUiLogic";

class MedicationTherapiesItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelect: this.isSelect(props.item.id)
    };
  }

  isSelect = id => {
    const found = this.props.filteredRecordedDosing.find(
      item => item.id === id
    );
    if (found) {
      return true;
    } else {
      return false;
    }
  };

  onSelect = () => {
    this.props.addRemoveMedicationsTherapie(
      !this.state.isSelect,
      this.props.item
    );
    this.setState({ isSelect: !this.state.isSelect });
  };
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.messageSecRow, styles.greyBorder]}>
        <TouchableOpacity style={[styles.messageRow]} onPress={this.onSelect}>
          <View style={[stylesRes.itemTextAreaMain, stylesRes.itemTextMgCom]}>
            <View style={[stylesRes.itemImgAreaMain, stylesRes.itemMgLtCom]}>
              <View style={stylesRes.itemMediIcon}>
                <Image
                  style={styles.imgContain}
                  source={getCoverIcon(this.props.treatment.treatmentType)}
                />
              </View>
            </View>

            <View style={stylesRes.itemTextAreaMain}>
              <View style={[styles.itemTextDetails]}>
                <View style={[styles.itemTextMainCom]}>
                  <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                    {lowerCaseAllWordsExceptFirstLetters(
                      this.props.treatment && this.props.treatment.name ? this.props.treatment.name : ''
                    )}
                  </Text>
                </View>

                <View style={[styles.itemTextSub]}>
                  <Text style={[styles.textPrimeSm]} allowFontScaling={false}>
                    {this.props.item.quantity} {this.props.item.units}
                  </Text>
                  <Text
                    style={[
                      styles.textPrimeSm,
                      styles.textOrange,
                      styles.textEm
                    ]}
                    allowFontScaling={false}
                  >
                    {" "}
                    {this.props.item.frequency}
                  </Text>
                </View>
              </View>

              <View style={stylesRes.itemTextDate} dataSet={{media: ids.itemTextDate}}>
                {this.props.item.start || this.props.item.end ? (
                  <Text style={[styles.textPrimeSm, styles.textAlignRight]} allowFontScaling={false}>
                    {this.props.item.start
                      ? moment(this.props.item.start, "x").format("D MMM 'YY")
                      : null}{" "}
                    {this.props.item.end
                      ? "- " +
                        moment(this.props.item.end, "x").format("D MMM 'YY")
                      : null}
                  </Text>
                ) : null}
              </View>
            </View>
          </View>
          <View
            style={[
              styles.selectionBlockSm,
              styles.messageTopBotPdSm,
              styles.selectionPdRt
            ]}
          >
            <View style={styles.iconListSec}>
              <View
                style={[
                  styles.selectSecIcon,
                  styles.iconSettingSp,
                  styles.settingPd
                ]}
              >
                <Image
                  style={styles.radioIcon}
                  source={
                    this.state.isSelect
                      ? require("../../assets/icons/radio-selected.png")
                      : require("../../assets/icons/radio-default.png")
                  }
                />
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    filteredRecordedDosing: state.report.filteredRecordedDosing
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addRemoveReportIssue: question =>
      dispatch(actions.addRemoveReportIssue(question))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicationTherapiesItem);
