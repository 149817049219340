import React from "react";
import styles from "../../../Css";
import { View, Image, Text } from "react-native";
const noGraphData = (props) => {
  return (
    <View style={[styles.flexJcCtAiCt, styles.graphDefaultWrapper]}>
      <View style={[styles.infoPdBotImg]}>
        <Image
          style={styles.graphDefaultIcon}
          source={require("../../../assets/icons/graph-default.png")}
        />
      </View>
      <View style={[styles.resultContentView]}>
        <View style={[styles.resultContentTitle]}>
          {Boolean(props.initGraph) ? (
            <Text style={[styles.textTitleBold, styles.textAlignCenter]} allowFontScaling={false}>
              Select a variable to graph
            </Text>
          ) : props.dashboardGraph && !props.graphId ? (
            <Text style={[styles.textTitleBold, styles.textAlignCenter]} allowFontScaling={false}>
              Graph something life changing
            </Text>
          ) : (
            <View style={[styles.noInfoSection, styles.flexJcCtAiCt]}>
              <View style={styles.noInfoImgSection}>
                <Image
                  style={styles.noInfoIcon}
                  source={require("../../../assets/icons/info-dark.png")}
                />
              </View>
              <View style={styles.noInfoTextSection}>
                <Text style={[styles.textTitleBold, styles.textAlignCenter]} allowFontScaling={false}>
                  {props.dashboardGraph 
                    ? 'Oops! There’s no data for last two weeks.' 
                    : 'Oops! There’s no data for your selection.'
                  }
                </Text>
              </View>
            </View>
          )}
          {/* <Text style={[styles.textTitleMdBold, styles.textAlignCenter]}>
            {props.initGraph
              ? 'Select a variable to graph'
              : props.dashboardGraph && !props.graphId
                ? 'Graph something life changing'
                : 'Oops! There’s no data for your selection.' 
            }
          </Text> */}
        </View>
        {/* <View style={[styles.resultContentDesc]}>
          <Text
            style={[
              styles.textPrime,
              styles.textAlignCenter,
            ]}
          >
            Complete a journal entry to begin graphing
          </Text>
        </View> */}
      </View>
    </View>
  );
};

export default (noGraphData);
