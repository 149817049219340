import React from "react";
import { View, ScrollView } from "react-native";
const ColorPickerScroll = (props) => {
  let scrollHeight = 470;
  if (props.fullHeight < 510) {
    scrollHeight = props.fullHeight - 40;
  }
  return (
    <>
      {props.fullHeight < 510 ? (
        <View style={[{ height: scrollHeight }]}>
          <ScrollView>{props.children}</ScrollView>
        </View>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
};

export default (ColorPickerScroll);