import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Platform,
} from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import moment from "moment";
import QuestionMainAnswers from "../Question/QuestionMainAnswers";
import TickButton from "../CheckBox/CheckBox";
import PlusButtonCom from "../../components/Button/plusButtonCom";
import UIText from "../../components/Text/text";


class ViewIssue extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      answers: props.question.answers ? [...props.question.answers] : [],
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      ticked: this.props.question ? this.props.question.closed : null,
      hideComments: true,
      wait: false,
      answer: "",
      commentButtonEnable: true
    };
  }

  onPressAddComment = () => {
    if (this.state.commentButtonEnable) {
      this.setState({ 
        addAnswer: !this.state.addAnswer,  
        validComment: true, 
        commentButtonEnable: false 
      });
    } else {
      if (this.textInput) {
        this.textInput.focus();
      }
    }
  };

  onChangeTextComment = (val) => {
    this.setState({ answer: val, validComment: true });
  };

  saveAnswer = () => {
    let answer = {};
    if ( this.state.answer && this.state.answer.trim() ) {
      this.setState({ wait: true });
      answer = {
        healthQuestionId: this.props.question.id,
        healthQuestionAnswer: {
          id: -1,
          content: this.state.answer,
        },
      };

      this.props.onUpdateHealthQuestionAnswer(
        answer,
        (success, data) => {
          if (success) {
            let answers = [...this.state.answers];

            answers.push(data);
            this.setState({
              addAnswer: false,
              answer: "",
              wait: false,
              answers: answers,
              commentButtonEnable: true
            });
          }

          this.setState({
            addAnswer: false,
            wait: false,
          });
        },
        this.props.journalUserId
      );
    } else {
      this.setState({ validComment: false });
    }
  };

  handleCheckboxQuestion = (value) => {
    this.setState({ wait: true });
    const healthQuestion = this.props.question;
    healthQuestion.closed = value;
    healthQuestion.editting = false;
    let req = {
      artifactId: this.props.parentArtifactId,
      healthQuestion: healthQuestion,
    };

    this.props.onUpdateHealthQuestion(
      req,
      this.props.questionDigest,
      (success, data) => {
        if (success) {
          this.setState({ ticked: value });
        }
        this.setState({ wait: false });
      },
      this.props.journalUserId
    );
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    let waitAnswer = null;
    if (this.state.wait) {
      waitAnswer = (
        <View style={[styles.actIndCom]}>
          <ActivityIndicator size="small" pdZero={true} />
        </View>
      );
    }
    return (
      <View style={[styles.questionRowComSpec, styles.comMgTopSm]}>
        <View style={[styles.questionDetailSec, styles.commentBoxView]}>
          <View style={[styles.toDoContWrapper]}>
            <View style={[styles.questionItemLtRtPd]}>
              <View style={[styles.questionTitleViewSm]}>
                <View style={[styles.questionTitleBlockMain]}>
                  <View style={styles.questionTextBlock}>
                    <View style={[styles.questionTitleMain]}>
                      <View style={[styles.toDoSelection]}>
                        {this.state.ticked ? (
                          <TickButton
                            imagePath={"tickButton"}
                            styleType={"circle"}
                            tickBtnStyle={styles.circleSelection}
                            toggleTickCallback={() =>
                              this.handleCheckboxQuestion(false)
                            }
                          />
                        ) : (
                          <TickButton
                            imagePath={"untickButton"}
                            styleType={"circle"}
                            tickBtnStyle={styles.circleSelection}
                            toggleTickCallback={() =>
                              this.handleCheckboxQuestion(true)
                            }
                          />
                        )}
                      </View>
                      <View style={[styles.toDoTitle, styles.flexCom]}>
                        <Text style={[styles.textTitleBold, styles.textQuest]} allowFontScaling={false}>
                          {this.props.question.content}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={[styles.questionCommentBlock]}>
                <View style={[styles.commentTextBlock, styles.flexCom]}>
                  <View style={[styles.btnArea]}>
                    <PlusButtonCom
                      btnPosition={"left"}
                      btnText={"Add Comment"}
                      touchableOnPress={this.onPressAddComment}
                    />
                  </View>
                </View>
                <View style={[styles.commentDetailsWrapper, styles.flexRow]}>
                  <View style={[styles.questionAttachWrapper]}>
                    <TouchableOpacity
                      onPress={() =>
                        this.state.commentButtonEnable && this.props.redirectPage(this.props.parentArtifactId)
                      }
                    >
                      <Image
                        style={styles.attachMainIcon}
                        source={require("../../assets/icons/attach-lg.png")}
                      />
                    </TouchableOpacity>
                  </View>
                  <View style={[styles.questionDateWrapper]}>
                    <Text style={[styles.textPrime, styles.dateTextQuest]} allowFontScaling={false}>
                      {moment(this.props.question.modifiedOn, "x").format(
                        "D MMM 'YY"
                      )}
                    </Text>
                  </View>
                </View>
              </View>

              {Boolean(this.state.answers.length) ? (
                <View style={[styles.commentFullBlock]}>
                  <View style={[styles.commentsListView]}>
                    {Boolean(this.state.answers.length) ? (
                      <View
                        style={[
                          styles.commentsListItemMoreBtnSec,
                          styles.infoPdBotSm,
                        ]}
                      >
                        <View style={[styles.commentsListComHr]} />
                        <View style={[styles.moreCircleBubble]}>
                          <View style={styles.countBubbleWrapper}>
                            <Image
                              style={[styles.countBubble]}
                              source={require("../../assets/icons/bubble-count.png")}
                            />

                            <View style={styles.countBubbleText}>
                              <TouchableOpacity
                                onPress={() => {
                                  this.setState((prevState) => ({
                                    hideComments: !prevState.hideComments,
                                  }));
                                }}
                                style={styles.countBubbleTouch}
                              >
                                {this.state.hideComments ? (
                                  <Text
                                    style={[
                                      styles.textPrimeSm,
                                      styles.textAlignCenter,
                                    ]}
                                    allowFontScaling={false}
                                  >
                                    {this.state.answers.length}
                                  </Text>
                                ) : (
                                  <Image
                                    style={[styles.countBubbleArrow]}
                                    source={require("../../assets/icons/close-arrow.png")}
                                  />
                                )}
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    ) : null}

                    {Boolean(this.state.answers.length) ? (
                      this.state.hideComments ? null : (
                        <View
                          style={[
                            styles.commentsListItemMore,
                            styles.morePdTop,
                          ]}
                        >
                          {this.state.answers.map((answersSortedRes, index) => {
                            return (
                              <QuestionMainAnswers
                                userImage="TO-DO"
                                userName={`${this.state.firstName} ${this.state.lastName.charAt(0)}.`}
                                answer={answersSortedRes.content}
                                postedDate={answersSortedRes.modifiedOn}
                              />
                            );
                          })}
                        </View>
                      )
                    ) : null}
                  </View>
                </View>
              ) : null}
            </View>
            <View style={[styles.hrLineSp]} />

            {/* Question Block */}
            <View
              style={[styles.questionCommentFull, styles.questionItemLtRtPd]}
            >
              {this.state.addAnswer ? (
                <View style={[styles.addCommentSection]}>
                  <View style={styles.fieldRowView}>
                    <View
                      style={[
                        styles.textAreaWrapper,
                        this.state.validComment
                          ? styles.validColor
                          : styles.inValidColor,
                      ]}
                    >
                      <Text style={styles.textView}>
                        <Text style={[styles.textLabel, styles.textGreyDark]} allowFontScaling={false}>
                          Type to enter a comment
                        </Text>
                      </Text>
                      <TextInput
                        ref={input => {
                          this.textInput = input;
                        }}
                        allowFontScaling={false}
                        onChangeText={this.onChangeTextComment}
                        style={[
                          styles.textAreaField,
                          {
                            ...Platform.select({
                              android: {
                                textAlignVertical: "top",
                              },
                            }),
                          },
                        ]}
                        multiline={true}
                        numberOfLines={3}
                        underlineColorAndroid="transparent"
                        placeholderTextColor={"#98989A"}
                        autoFocus={true}
                      />
                    </View>
                  </View>
                  <View style={styles.fieldRow}>
                    <View style={styles.btnArea}>
                      <View
                        style={[
                          stylesRes.btnViewSecSmRight,
                          stylesRes.btnViewComment,
                        ]}
                      >
                        <View style={styles.fieldBtn}>
                          <View style={styles.btnBlockFull}>
                            <TouchableOpacity
                              style={[
                                styles.submitSm,
                                styles.btnPdMini,
                                styles.bgWhite,
                                styles.borderBtn,
                              ]}
                              underlayColor="rgba(0, 153, 168, 0.8)"
                              onPress={() => {
                                this.setState({ addAnswer: !this.state.addAnswer, commentButtonEnable: true })
                              } }
                            >
                              <View style={styles.btnSingleView}>
                                <View
                                  style={[
                                    styles.btnSingle,
                                    styles.btnSingleCent,
                                  ]}
                                >
                                  <UIText
                                    style={[
                                      styles.submitText,
                                      styles.submitTextSm,
                                      styles.whiteBtnText,
                                    ]}
                                    title="Cancel"
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View style={styles.fieldBtnSp} />
                        <View style={styles.fieldBtn}>
                          <View style={styles.btnBlockFull}>
                            <TouchableOpacity
                              style={[
                                styles.submitSm,
                                styles.btnPdMini,
                                styles.borderBtnTransparent,
                              ]}
                              underlayColor="rgba(0, 153, 168, 0.8)"
                              onPress={this.saveAnswer}
                            >
                              <View style={styles.btnSingleView}>
                                <View
                                  style={[
                                    styles.btnSingle,
                                    styles.btnSingleCent,
                                  ]}
                                >
                                  {waitAnswer}
                                  <UIText
                                    style={[
                                      styles.submitText,
                                      styles.submitTextSm,
                                    ]}
                                    title="Save"
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              ) : null}
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.routines.user,
    journalUserId: state.uistate.journalUserId,
    questionDigest: state.routines.questionDigest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateHealthQuestionAnswer: (
      healthQuestionAnswer,
      callBack,
      journalUserId
    ) =>
      dispatch(
        actions.updateHealthQuestionAnswerNew(
          healthQuestionAnswer,
          callBack,
          journalUserId
        )
      ),

    onUpdateHealthQuestion: (
      healthQuestion,
      questionDigest,
      callBack,
      journalUserId
    ) =>
      dispatch(
        actions.updateHealthQuestionNew(
          healthQuestion,
          questionDigest,
          callBack,
          journalUserId
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewIssue);
