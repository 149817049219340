import React, { Component } from 'react';
import { Text, View, TouchableOpacity, Platform } from 'react-native';
import styles from '../../../Css';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import PrimaryButton from '../../Button/primaryButton';
import * as FieldDetails from '../../TextInput/fieldDetails';
import TextFrame from '../../TextInput/textFrame';
import TextPickerFrame from '../../TextInput/textPickerFrame';
import TextInputPlusMinus from '../../TextInput/TextInputPlusMinus';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import TextDatePickerFrame from '../../TextInput/textDatePickerFrame';
import Warning from '../../Warning/warning';
import moment from 'moment';
import { generateName } from './Util/MedicationAndActivityNameUtils'
import { pendoTrackEvent } from '../../../util/pendoConfig';
import { WEB } from '../../../util/platformWindowConfig';
import { verifyDuplicateMedication, getMedicationUnits } from '../../../util/commonUiLogic';

class MedicationQuickEntry extends Component {
  constructor(props) {
    super(props);
    const { treatment, drugAdministrationsDigits } = props;
    const { dosages = [], publishedBy } = treatment;
    const dosage = dosages.length > 0 ? dosages[0] : {};
    const unit = dosage?.units && dosage?.units !== '' ? dosage?.units : null;
    const initQuantity = dosage?.quantity || dosage?.quantity === 0 || dosage?.quantity === '0' ? dosage?.quantity : null;

    this.state = {
      ...dosage,
      entryDate: moment().valueOf(),
      error: false,
      errorDescription: '',
      duplicateErrorDescription: null,
      unitList: unit ? [] : getMedicationUnits(null, drugAdministrationsDigits),
      isSaveDisabled: (initQuantity || initQuantity === 0 || initQuantity === '0') && unit ? false : true,
      unit: unit,
      initQuantity: initQuantity,
      publishedBy: publishedBy
    }
  }


  componentDidMount() {
    const { unitList, unit, initQuantity } = this.state;
    const { treatment } = this.props;
    const { identificationNumber, treatmentType } = treatment;

    if (!unit) {
      if (identificationNumber && treatmentType) {
        this.getTreatmentInfoValues(identificationNumber, treatmentType);
      } else {
        const foundTablet = unitList.find(res => res.value === 'Tablets');
        const unit = foundTablet ? 'Tablets' : unitList && unitList[0] && unitList[0].value ? unitList[0].value : null;

        this.setState({
          isSaveDisabled: (initQuantity || initQuantity === 0 || initQuantity === '0') && unit ? false : true,
          unit: unit,
        });
      }
    }
  };

  getTreatmentInfoValues = (din_npn, treatmentType) => {
    const { unit } = this.state;
    const { drugAdministrationsDigits, getTreatmentInfo } = this.props;
    getTreatmentInfo(din_npn, treatmentType, (success, res) => {
      if (success) {
        const { unitValue, unitList } = res;

        this.setState({
          unitList: unitList,
          unit: unitValue,
          isSaveDisabled: (this.state.initQuantity || this.state.initQuantity === 0 || this.state.initQuantity === '0') && unitValue ? false : true,
        });
      }
    }, unit, this.state.unitList, drugAdministrationsDigits);      
  }

  updateQuantity = val => {
    const { units, unit, initQuantity } = this.state;
    const { treatment = {} } = this.props;
    const { name, identificationNumber } = treatment;

    if (!units || (!initQuantity && initQuantity !== 0 && initQuantity !== '0')) this.verifyDuplicateMedication(name, identificationNumber, unit, val);
    this.setState({ quantity: val, error: false, errorDescription: '', isSaveDisabled: (val || val === 0 || val === '0') && (units || unit) ? false : true });
  };

  customTouchableComponent = onPress => {
    return (
      Platform.OS === WEB
        ? <TouchableOpacity onPress={onPress}>
          {this.renderDateTimeContent()}
        </TouchableOpacity>
        : this.renderDateTimeContent()
    );
  };

  renderDateTimeContent = () => {
    const { entryDate } = this.state;
    return (
      <TextDatePickerFrame
        title={'Date and Time'}
        fieldBg={FieldDetails.BGWHITE}
        fieldSm={false}
        fieldLt={true}
        fieldValue={
          entryDate ? moment(entryDate).format("D MMM 'YY | h:mm A") : ''
        }
        date={entryDate}
        onDateTimeConfirm={this.onConfirmEntryDate}
        windowWidth={this.props.windowWidth}
      />
    )
  }

  onConfirmEntryDate = date => {
    if (date) {
      this.setState({ entryDate: date, isSaveDisabled: false });
    }
  };

  onValueChange = val => {
    const { quantity, units, initQuantity } = this.state;
    const { treatment = {} } = this.props;
    const { name, identificationNumber } = treatment;

    if (!units || (!initQuantity && initQuantity !== 0 && initQuantity !== '0')) this.verifyDuplicateMedication(name, identificationNumber, val, quantity);
    this.setState({ unit: val, isSaveDisabled: quantity && (val || val === 0 || val === '0') ? false : true })
  };

  addDosage = () => {
    const { quantity, entryDate, unit, units, initQuantity, publishedBy } = this.state;
    this.setState({ saveWait: true });

    if (quantity || quantity === 0 || quantity === '0' || (quantity && quantity.toString().trim() !== '')) {
      const {
        treatment = {},
        applicationSettingsDigest,
        onSaveJournalEntry,
        journalUserId,
        showSuccess,
      } = this.props;
      const { quickNoteEntryTypeId } = applicationSettingsDigest;
      const { name, id } = treatment;

      let newTemplate = {
        id: -1,
        title: `${generateName(name.trim())}, ${quantity} ${unit}`,
        entryDate: entryDate
          ? moment.utc(entryDate).valueOf()
          : moment.utc().valueOf(),
        entryType: quickNoteEntryTypeId,
        dosages: [
          {
            id: -1,
            quantity: quantity,
            units: unit,
            treatment: id,
          },
        ],
      };

      onSaveJournalEntry(
        { journalEntry: newTemplate },
        (success, data) => {
          if (success) {
            this.setState(
              {
                saveWait: false,
                isSaveDisabled: false,
              },
              () => showSuccess(),
            );
          } else {
            this.setState({ error: true, errorDescription: data, saveWait: false, isSaveDisabled: false }, () => {
              setTimeout(() => {
                this.props.hidePopup();
                this.setState({ error: false, errorDescription: '' });
              }, 1500);
            });
          }
        },
        journalUserId,
      );

      if (publishedBy !== "PARTNER" && (!units || (!initQuantity && initQuantity !== 0 && initQuantity !== '0'))) this.doUpdateTherapy();

    } else {
      this.setState({ error: true, errorDescription: 'Quantity is required', saveWait: false, isSaveDisabled: false });
    }
    pendoTrackEvent('Dashboard@ > RecordMed > Yes');
  };

  doUpdateTherapy = () => {
    const { unit, units, quantity, initQuantity } = this.state;
    const { journalUserId, treatment = {}, updateTherapie } = this.props;
    const { dosages = [] } = treatment;

    let updatedTherapieData = {
      treatment: {
        ...treatment,
        dosages: [
          {
            ...dosages,
            units: units ? units : unit,
            quantity: (initQuantity || initQuantity === 0 || initQuantity === '0') ? initQuantity : quantity
          },
        ],
      },
    };

    updateTherapie(
      updatedTherapieData,
      (success, data) => {
        if (success) {
          this.setState(
            {
              saveWait: false,
              isSaveDisabled: false,
            },
          );
        }
      },
      journalUserId,
    );
  }

  verifyDuplicateMedication = (name, din_npn, unit, quantity) => {
    const { listTherapie } = this.props;

    verifyDuplicateMedication(name, din_npn, unit, quantity, listTherapie, (exist, msg) => {
      if (exist) {
        this.onChangeDuplicteMedicationState(true, true, msg);
      } else {
        this.onChangeDuplicteMedicationState(false, false, null);
      }
    });
  };

  onChangeDuplicteMedicationState = (isItemExist, isSaveDisabled, msg) => {
    this.setState({
      isItemExist: isItemExist,
      isSaveDisabled: isSaveDisabled,
      duplicateErrorDescription: msg,
    });
  }

  render() {
    const { treatment = {} } = this.props;
    const { name } = treatment;
    const {
      quantity,
      units,
      unitList,
      unit,
      entryDate,
      saveWait,
      isSaveDisabled,
      error,
      errorDescription,
      isItemExist,
      duplicateErrorDescription,
    } = this.state;

    return (
      <View style={styles.popupContentWrapper}>
        <View style={[styles.popMessageContView, styles.comSp]}>
          <View style={[styles.popMessageTitleView]}>
            <Text style={[styles.textTitle, styles.textAlignCenter]} allowFontScaling={false}>
              Record one dose of <Text style={styles.bold}>{name}?</Text>
            </Text>
          </View>
        </View>
        <View style={styles.modalSectionView}>
          <View style={[styles.fieldRow]}>
            <View style={[styles.fieldRowWrapper]}>
              <View style={styles.fieldColStretch}>
                <TextInputPlusMinus
                  title={'Quantity'}
                  fieldBg={FieldDetails.BGWHITE}
                  fieldAlign={FieldDetails.ALIGNCENTER}
                  fieldLt={true}
                  value={quantity}
                  onChange={this.updateQuantity}
                  invalid={true}
                />
              </View>
              <View style={styles.fieldColSpMd} />

              <View style={styles.fieldColStretch}>
                {units ?
                  <TextFrame
                    title={'Unit'}
                    fieldBg={FieldDetails.BGWHITE}
                    fieldValue={units}
                    fieldAlign={FieldDetails.ALIGNLEFT}
                    fieldLt={true}
                    editable={false}
                  />
                  :
                  <TextPickerFrame
                    title={'Unit'}
                    fieldBg={FieldDetails.BGWHITE}
                    fieldSm={false}
                    darkTxt={true}
                    items={unitList}
                    onValueChange={this.onValueChange}
                    selectedValue={unit}
                    activitySlider={false}
                  />
                }
              </View>
            </View>
          </View>

          <View style={[styles.fieldRow]}>
            <View style={[styles.fieldRowWrapper]}>
              <View style={styles.fieldColStretch}>
                <DateTimePicker
                  date={entryDate}
                  customTouchableComponent={this.customTouchableComponent}
                  onConfirm={this.onConfirmEntryDate}
                  windowWidth={this.props.windowWidth}
                />
              </View>
            </View>
          </View>
        </View>
        {(Boolean(error) || isItemExist) && <Warning errorDescription={isItemExist ? duplicateErrorDescription : errorDescription} />}
        <View style={[styles.popBtnView, styles.comMgTopSm]}>
          <View style={[styles.btnArea, styles.flexRow, styles.flexJcCtAiCt]}>
            {/* <View style={styles.fieldBtn}>
              <PrimaryButtonWhite
                btnText={'Cancel'}
                btnPosition={'full'}
                touchableOnPress={hidePopup}
              />
            </View>
            <View style={styles.fieldBtnSpSm} /> */}
            <View style={styles.fieldBtn}>
              <PrimaryButton
                btnText={'Yes'}
                btnPosition={'full'}
                touchableOnPress={this.addDosage}
                activityInd={saveWait}
                nativeId={'dashboardRecordMedYes'}
                disabled={isItemExist || isSaveDisabled || saveWait}
              />
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    drugAdministrationsDigits: state.routines.drugAdministrationsDigits,
    listTherapie: state.routines.treatments,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.updateTherapie(addNewTherapie, callBack, journalUserId)),
    onSaveJournalEntry: (journalEntry, callBack, journalUserId) =>
      dispatch(actions.saveJournalEntry(journalEntry, callBack, journalUserId)),
    getTreatmentInfo: (din_npn, treatmentType, callBack, unit, units, drugAdministrationsDigits) => 
      dispatch(actions.getTreatmentInfo(din_npn, treatmentType, callBack, unit, units, drugAdministrationsDigits)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicationQuickEntry);
