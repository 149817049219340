import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";
import moment from "moment";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

class RoutineItem extends Component {
  state = {
    attach: false
  };

  constructor(props) {
    super(props);
    this.state = {
      attach: false
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { journalEntriesAdd } = props;
    if (this.state.attach && journalEntriesAdd !== props.id) {
      this.setState({ attach: false });
    }
  }

  handleOnPressSelectQuestion = id => {
    this.props.addRemoveJournal(id);
    this.setState({ ...this.state, attach: !this.state.attach });
  };

  journalHasAttachments = artifactId => {
    let attachments = [];
    if (this.props.attachmentsCompleteDigest) {
      attachments = this.props.attachmentsCompleteDigest.userArtifactAttachments.filter(
        function(attachment) {
          return attachment.userArtifact === artifactId;
        }
      );
    }
    return attachments.length > 0;
  };

  render() {
    return (
      <View>
        {this.state.attach ? (
          <View style={[styles.messageSecRow, styles.greyBorder]}>
            <TouchableOpacity
              onPress={() => this.handleOnPressSelectQuestion(this.props.id)}
            >
              <View key={this.props.id} style={[styles.messageRow]}>
                <View style={styles.messageDetailSec}>
                  <View style={styles.messageDetailCont}>
                    <View
                      style={[
                        styles.customBorderSec,
                        { backgroundColor: this.props.bgColor }
                      ]}
                    />
                    <View
                      style={[styles.messageDetail, styles.messageTopBotPdSm]}
                    >
                      <Text style={styles.messageTitle} allowFontScaling={false}>
                        {this.props.title}
                      </Text>
                      <Text style={styles.messageDateText} allowFontScaling={false}>
                        {moment(this.props.entryDate).format("MM.DD.YYYY")}{" "}
                        {moment(this.props.createdOn).format("h:mm a")}
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={[
                    styles.selectionBlock,
                    styles.messageTopBotPdSm,
                    styles.selectionPdRtLg
                  ]}
                >
                  <View style={styles.iconListSec}>
                    {Boolean(this.journalHasAttachments(this.props.artifactId)) && (
                      <View style={[styles.attachSec, styles.iconListMgSmLt]}>
                        <Image
                          style={styles.attachIcon}
                          source={require("../../assets/icons/attach.png")}
                        />
                      </View>
                    )}

                    {this.props.flagged ? (
                      <View style={[styles.starSec, styles.iconListMgSmLt]}>
                        <Image
                          style={styles.starIcon}
                          source={require("../../assets/icons/star-yellow.png")}
                        />
                      </View>
                    ) : (
                      <View style={[styles.starSec, styles.iconListMgSmLt]}>
                        <Image
                          style={styles.starIcon}
                          source={require("../../assets/icons/star-grey.png")}
                        />
                      </View>
                    )}
                    <View style={[styles.selectSecIcon, styles.iconListMgSmLt]}>
                      <Image
                        style={styles.radioIcon}
                        source={require("../../assets/icons/radio-selected.png")}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          </View>
        ) : (
          <View style={[styles.messageSecRow, styles.greyBorder]}>
            <TouchableOpacity
              onPress={() => this.handleOnPressSelectQuestion(this.props.id)}
            >
              <View key={this.props.id} style={[styles.messageRow]}>
                <View style={styles.messageDetailSec}>
                  <View style={styles.messageDetailCont}>
                    <View
                      style={[
                        styles.customBorderSec,
                        { backgroundColor: this.props.bgColor }
                      ]}
                    />
                    <View
                      style={[styles.messageDetail, styles.messageTopBotPdSm]}
                    >
                      <Text style={styles.messageTitle} allowFontScaling={false}>
                        {this.props.title}
                      </Text>
                      <Text style={styles.messageDateText} allowFontScaling={false}>
                        {moment(this.props.entryDate).format("MM.DD.YYYY")}{" "}
                        {moment(this.props.createdOn).format("h:mm a")}
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={[
                    styles.selectionBlock,
                    styles.messageTopBotPdSm,
                    styles.selectionPdRtLg
                  ]}
                >
                  <View style={styles.iconListSec}>
                      {Boolean(this.journalHasAttachments(this.props.artifactId)) && (
                      <View style={[styles.attachSec, styles.iconListMgSmLt]}>
                        <Image
                          style={styles.attachIcon}
                          source={require("../../assets/icons/attach.png")}
                        />
                      </View>
                    )}

                    {this.props.flagged ? (
                      <View style={[styles.starSec, styles.iconListMgSmLt]}>
                        <Image
                          style={styles.starIcon}
                          source={require("../../assets/icons/star-yellow.png")}
                        />
                      </View>
                    ) : (
                      <View style={[styles.starSec, styles.iconListMgSmLt]}>
                        <Image
                          style={styles.starIcon}
                          source={require("../../assets/icons/star-grey.png")}
                        />
                      </View>
                    )}
                    <View style={[styles.selectSecIcon, styles.iconListMgSmLt]}>
                      <Image
                        style={styles.radioIcon}
                        source={require("../../assets/icons/radio-default.png")}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
    journalEntriesAdd: state.question.journalEntriesAdd
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addRemoveJournal: id => dispatch(actions.addRemoveJournal(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutineItem);
