import React from "react";
import { View, TouchableOpacity, Text, Image } from "react-native";
import styles from "../../Css";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import * as FontScaling from '../../components/Text/fontScaling';

const plusButtonDark = (props) => {
  return (
    <View
      style={[
        styles.actionBtnWrapper,
        props.btnPosition === "left"
          ? styles.flexAiFs
          : props.btnPosition === "right"
          ? styles.flexAiFe
          : props.btnPosition === "center"
          ? styles.flexAiCt
          : styles.flexCom,
      ]}
    >
      <View
        style={[
          props.btnPosition === "full" ? styles.flexCom : styles.actionBtn,
        ]}
      >
        <TouchableOpacity
          onPressIn={props.touchableOnPress}
          disabled={props.disabled}
          style={[
            props.btnPosition === "full" ? styles.flexCom : styles.flexBtn,
            styles.flexJcCtAiCt,
            props.btnShadowNone ? styles.btnShadowNone : styles.submitBtnShadow,
            styles.btnActionComLg,
            props.btnColor === "grey"
              ? styles.submitBgAsh
              : styles.submitBgOrange,
            props.newConnection && styles.newConnectionBtn

          ]}
          nativeID={props.nativeId}
        >
          <View style={[styles.btnSingle, styles.btnSingleCent]}>
            {props.activityInd && (
              <View
                style={[
                  styles.indicatorIconView,
                  styles.indicatorLtMd,
                  props.activityIndStyle,
                ]}
              >
                <ActivityIndicator pdZero={true} size="small" />
              </View>
            )}
            <View style={styles.iconViewExSm}>
              <Image
                style={props.plusIconSm ? styles.plusExMinSm : styles.plusExSm}
                source={require("../../assets/icons/more-white.png")}
                resizeMode={"contain"}
              />
            </View>
            <Text style={[styles.textPrimeBold, styles.textWhite]} 
            allowFontScaling = {FontScaling.allowFontScaleFalse}
            >
              {props.btnText}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default (plusButtonDark);
