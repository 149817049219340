import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import "./cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";

class AttachmentImageCropper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      cropResult: null
    };
  }
  base64ImageToBlob = str => {
    // extract content type and base64 payload from original string
    let pos = str.indexOf(";base64,");
    let type = str.substring(5, pos);
    let b64 = str.substr(pos + 8);

    // decode base64
    let imageContent = atob(b64);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    let buffer = new ArrayBuffer(imageContent.length);
    let view = new Uint8Array(buffer);

    // fill the view, using the decoded base64
    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }

    // convert ArrayBuffer to Blob
    let blob = new Blob([buffer], { type: type });
    return blob;
  };

  cropImage = () => {
    if (typeof this.cropper.getCroppedCanvas() === "undefined") {
      return;
    }

    let cropResult = this.cropper.getCroppedCanvas().toDataURL();
    let base64ImageToBlob = this.base64ImageToBlob(cropResult);

    if (cropResult) {
      this.props.onCropImage(base64ImageToBlob, cropResult);
    } else {
    }
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    let positionStatus = "absolute";
    return (
      <React.Fragment>
        <View style={[stylesRes.frameArea, stylesRes.frameAreaImg]}>
          <View
            style={[
              this.props.paddingAll
                ? stylesRes.colPdComScanNone
                : stylesRes.colPdTopLtRtCom
            ]}
            dataSet={{media: ids.colPdTopLtRtCom}}
          >
            <View style={[styles.frameAreaTop]}>
              <View
                style={{
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
              >
                <Cropper
                  style={{
                    width: this.props.scanWidth,
                    height: this.props.scanHeight
                  }}
                  guides={false}
                  src={this.props.imageUrl}
                  ref={cropper => {
                    this.cropper = cropper;
                  }}
                />
              </View>
            </View>
          </View>
        </View>

        <View
          style={[
            stylesRes.frameArea,
            stylesRes.frameAreaContView,
            { position: positionStatus }
          ]}
        >
          <View style={[styles.colPdLtRt]}>
            <View style={[styles.frameAreaBot]}>
              <View style={[stylesRes.frameViewBot]}>
                <React.Fragment>
                  <View style={[stylesRes.frameViewBtn]}>
                    <TouchableOpacity>
                      <button
                        onClick={this.cropImage}
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          cursor: "poniter"
                        }}
                      >
                        <View style={[stylesRes.frameBtnFull]}>
                          <View style={[stylesRes.frameBtn]}>
                            <Text
                              style={[
                                styles.textPrimeExSmBold,
                                styles.textAlignCenter,
                                styles.textBlack
                              ]}
                              allowFontScaling={false}
                            >
                              Save
                            </Text>
                          </View>
                        </View>
                      </button>
                    </TouchableOpacity>
                  </View>
                  <View
                    style={[
                      stylesRes.frameViewText,
                      stylesRes.frameTextMinHeight
                    ]}
                  ></View>
                </React.Fragment>
              </View>
            </View>
          </View>
        </View>
      </React.Fragment>
    );
  }
}
export default (AttachmentImageCropper);
