import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import ConfirmationBox from "../ConfirmationBox/confirmationBox";
import { getMedicalConditionStatus } from "../../util/commonUiLogic";

class ConditionsList extends Component {
  state = {
    userConditions: [],
    confirmBox: false,
    deleteWait: false,
    selectedId: null,
  };
  constructor(props) {
    super(props);
    const { editMode } = props;
    this.state = {
      userConditions: props.userConditions,
      editMode,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { userConditions, editMode } = props;
    this.setState({ userConditions: userConditions, editMode });
  }

  onDeleteUseCondition = () => {
    if (this.state.selectedId) {
      this.setState({ deleteWait: true });
      this.props.onDeleteUseCondition(this.state.selectedId, (res) => {
        this.setState({
          confirmBox: false,
          selectedId: null,
          deleteWait: false,
        });
      });
    }
  };

  render() {
    return (
      <View style={[styles.addedSecFull]}>
        {this.state.userConditions && this.state.userConditions.length > 0 && this.state.userConditions.map((res, index) => (
          <View
            style={[
              styles.addedItemRow,
              // styles.itemViewSpLg,
              this.state.editMode && styles.itemViewSpLg,
              // !this.state.editMode && styles.greyBorderThin
            ]}
            key={"condition_" + index}
          >
            <View
              style={[
                styles.addedItemView,
                !this.state.editMode && styles.cardRowInSp,
                this.state.editMode && styles.itemMgCom,
              ]}
            >
              {Boolean(this.state.editMode) ? (
                <View style={[styles.listItemCloseSecSm]}>
                  <View style={[styles.listItemCloseIcon, styles.closeIconSp]}>
                    <TouchableOpacity
                      onPress={() => {
                        this.setState({ confirmBox: true, selectedId: res.id });
                      }}
                    >
                      <Image
                        style={styles.closeDarkIcon}
                        source={require("../../assets/icons/close-dark.png")}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              ) : null}

              <View style={[styles.addedItemCont, styles.smPdItem]}>
                <Text style={[styles.textPrimeBold, styles.textGrey]} allowFontScaling={false}>
                  {res.name}
                </Text>
                <Text style={[styles.textPrimeExSm, styles.textGrey]} allowFontScaling={false}>
                  {(res.year
                    ? getMedicalConditionStatus(res.status) +
                      " in " +
                      res.year +
                      (res.stage ? ", " : "")
                    : "") +
                    (res.stage
                      ? !res.stage.includes("Stage")
                        ? "Stage " + res.stage
                        : res.stage
                      : "")}
                </Text>
              </View>
            </View>
          </View>
        ))}
        {Boolean(this.state.confirmBox) && (
        <ConfirmationBox
          visibleModal={this.state.confirmBox}
          onRequestClose={() => {
            this.setState({ confirmBox: false });
          }}
          onBackdropPress={() => {
            this.setState({ confirmBox: false });
          }}
          alertTitle="Delete Condition"
          handleDelete={this.onDeleteUseCondition}
          deleteWait={this.state.deleteWait}
        />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userConditions: state.routines.userConditions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteUseCondition: (id, callBack) =>
      dispatch(actions.deleteUseCondition(id, callBack)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConditionsList);
