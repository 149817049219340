import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";
import { toggleWatchOverview } from "../../store/actions/uistate";
import { connect } from "react-redux";
import * as FontScaling from '../../components/Text/fontScaling';
import {pendoTrackEvent} from '../../util/pendoConfig';
class WatchOverview extends Component {
  showPopup = () => {
    this.props.toggleWatchOverviewVideo(true);
    pendoTrackEvent('Dashboard@ > WatchOverview');
  };

  render() {
    const { windowWidth } = this.props;
    return (
      <>
        <View
          style={[
            windowWidth > 767 ? styles.playAreaView : styles.playAreaViewSm, styles.touchFullWrapper]}
        >
          <TouchableOpacity 
            onPress={this.showPopup} 
            style={[styles.ribbonPdLtTopBot, styles.touchFullWrapper, styles.flexCom]} 
            nativeID={'dashboardWatchOverview'}>
            <View style={[styles.playAreaCont, styles.flexRow]}>
              <Image
                style={[styles.mediaPlay]}
                source={require("../../assets/icons/video-play.png")}
              />
              <Text style={[styles.textPrimeBold, styles.textOrange]} maxFontSizeMultiplier = {FontScaling.fontScaleSm}>
                {windowWidth > 767
                  ? "Watch an Overview"
                  : "Watch an \nOverview"}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleWatchOverviewVideo: (val) => dispatch(toggleWatchOverview(val)),
  };
};

export default connect(null, mapDispatchToProps)(WatchOverview);
