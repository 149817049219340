import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Collapsible from '../Collapsible/Collapsible';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import moment from 'moment';
import PlusButtonCom from "../../components/Button/plusButtonCom";

class ReportIssues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleReportIssues: props.issueOpen,
      selectedIssues: props.selectedIssues ? props.selectedIssues : [],
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const {selectedIssues} = props;
    this.setState({selectedIssues});
  }
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <Collapsible
        title="To-dos"
        contentVisibility={this.state.visibleReportIssues}
        handleOnPressCollapsible={() => {
          this.setState({
            visibleReportIssues: !this.state.visibleReportIssues,
          });
          this.props.setTabOpen(2, !this.state.visibleReportIssues);
        }}>
        <View style={[styles.collapseContent]}>
          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              {/* If Issues added only, Please add styles.addedItemsIssues  */}
              <View style={[styles.addedItemsIssues]}>
                {/* Loop Item Start */}

                {this.state.selectedIssues &&
                  this.state.selectedIssues.map(item => {
                    return (
                      Boolean(this.props.questionProcessed[item.id]) && (
                        <View
                          style={[styles.listItemWrapper]}
                          key={'ri_' + item.id}>
                          <View
                            style={[
                              styles.listItemView,
                              styles.listItemAddedView,
                              styles.colPdLtRt,
                              styles.colBg,
                            ]}>
                            <View
                              style={[
                                styles.listContentViewFull,
                                styles.colPdTop,
                              ]}>
                              <View style={stylesRes.itemTextAreaMain}>
                                <View style={[styles.itemTextDetails]}>
                                  <View style={[styles.itemTextMainCom]}>
                                    <Text style={[styles.textPrimeLgBold]} allowFontScaling={false}>
                                      {item.title}
                                    </Text>
                                    <View style={stylesRes.itemAttachSec}>
                                      <TouchableOpacity
                                        onPress={() =>
                                          this.props.redirectPage(
                                            item.parentArtifactId,
                                          )
                                        }>
                                        <View style={stylesRes.itemAttachIcon}>
                                          <Image
                                            style={styles.imgContain}
                                            source={require('../../assets/icons/attach-lg.png')}
                                          />
                                        </View>
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                </View>

                                <View
                                  style={[
                                    stylesRes.itemTextDate,
                                    stylesRes.noRtPd,
                                  ]}
                                  dataSet={{media: ids.itemTextDate}}>
                                  <Text
                                    style={[
                                      styles.textPrimeSm,
                                      styles.textAlignRight,
                                    ]}
                                    allowFontScaling={false}
                                    >
                                    {moment(item.modifiedOn, 'x').format(
                                      "D MMM 'YY",
                                    )}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>

                          <View
                            style={[
                              styles.listItemView,
                              styles.listItemAddedView,
                              styles.colPdLtRt,
                              styles.colBg,
                            ]}>
                            <View style={[styles.listItemCloseCenter]}>
                              <View style={styles.listItemCloseIcon}>
                                <TouchableOpacity
                                  onPress={() =>
                                    this.props.removeReportIssue(item.id)
                                  }>
                                  <Image
                                    style={styles.closeDarkIcon}
                                    source={require('../../assets/icons/close-dark.png')}
                                  />
                                </TouchableOpacity>
                              </View>
                            </View>
                            <View
                              style={[
                                styles.listContentViewFull,
                                styles.colPdTopBot,
                              ]}>
                              <View style={[styles.listContentView]}>
                                <View
                                  style={[
                                    styles.addedItemInd,
                                    styles.addedItemRtPd,
                                  ]}>
                                  <View style={[styles.itemFullRow]}>
                                    <View
                                      style={[
                                        stylesRes.itemTextAreaMain,
                                        stylesRes.itemTextAreaMg,
                                      ]}
                                      dataSet={{media: ids.itemTextAreaMg}}>
                                      <Text style={[styles.textPrime]} allowFontScaling={false}>
                                        {
                                          this.props.questionProcessed[item.id]
                                            .content
                                        }
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>

                            <View style={stylesRes.itemBorderReport} dataSet={{media: ids.itemBorderReport}} />
                          </View>
                        </View>
                      )
                    );
                  })}
                {/* Loop Item End */}
              </View>

              <View style={[styles.addItemSecFull, styles.colBg, styles.colPd]}>
              <View style={[styles.btnArea]}>
                  <PlusButtonCom
                    btnPosition={"left"}
                    btnText={"Add"}
                    touchableOnPress={this.props.redirectReportAddIssue}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Collapsible>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedIssues: state.report.selectedIssues,
    questionProcessed: state.routines.questionDigestProcessed.questions,
    issueOpen: state.report.issueOpen,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    removeReportIssue: id => dispatch(actions.removeReportIssue(id)),
    setTabOpen: (tab, open) => dispatch(actions.setTabOpen(tab, open)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportIssues);
