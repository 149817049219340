import React from 'react';
import {Image, View, Text, ScrollView} from 'react-native';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import Header from '../../Header/header';
import SpaceBar from '../../SpaceBar/SpaceBar';
import PrimaryButton from '../../Button/primaryButton';
import { TAB_BREAk_POINT_START } from '../../../util/platformWindowConfig';

const DisplayText = (props, windowWidth, imagePath) => {
  return (
    <View style={[styles.peerListRow]}>
      <View style={[styles.itemIconView]}>
        <Image
          source={imagePath}
          style={styles.peerListIcon}
        />
      </View>
      <View style={[styles.detailItemData]}>
        <Text
          style={[
            windowWidth > 1366 ? styles.textTitleSm : styles.textTitle,
            styles.textColorLight,
          ]}
          allowFontScaling= {false}
          >
          {props}
        </Text>
      </View>
    </View>
  );
};

const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
const DisclaimerComponent = props => {
  const {
    handleOnPressBackButton,
    index,
    navigation,
    windowHeightSmView,
    topIconWidthImg,
    topIconHeightImg,
    handleOnNext,
    wait,
    botIconWidthImg,
    botIconHeightImg,
    windowWidth,
    route
  } = props;

  return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={false}
        hideCaregiverAccess={true}
        handleOnPressBackButton={handleOnPressBackButton}
        index={index}
        navigation={navigation}
        route={route}
      />
      <SpaceBar />
      <ScrollView style={styles.scrollViewArea}>
        <View style={styles.gridSection}>
          <View style={stylesRes.contentSectionFull}>
            <View>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.secArea}>
                    <View
                      style={{minHeight: windowHeightSmView}}>
                      <View style={[styles.commonFullBlock, styles.bgpage]}>
                        <View
                          style={[
                            styles.commonTopBlock,
                            styles.peerDisclaimerTop,
                          ]}>
                          <View style={styles.commonTopImg}>
                            <View
                              style={[
                                stylesRes.commonTopImgView,
                                {
                                  width: topIconWidthImg,
                                  height: topIconHeightImg,
                                },
                              ]}
                              dataSet={{media: ids.commonTopImgView}}>
                              <Image
                                style={[styles.imgCover]}
                                source={require('../../../assets/setup/bg-intro-top.png')}
                              />
                            </View>
                          </View>
                        </View>

                        <View
                          style={[
                            styles.flexCom,
                            styles.flexJcCtAiCt,
                            styles.peerDisclaimerZindex,
                            windowWidth > TAB_BREAk_POINT_START
                              ? styles.disclaimerPositionCenter
                              : styles.disclaimerPositionTop,
                          ]}>
                          <View style={[styles.peerDisclaimerContent]}>
                            <View style={styles.gridRow}>
                              <View style={styles.gridColFull}>
                                <View
                                  style={[
                                    styles.peerConnectIconWrapper,
                                    styles.flexJcCtAiCt,
                                  ]}>
                                  <Image
                                    style={[styles.peerConnectIcon]}
                                    source={require('../../../assets/peer-to-peer/connect.png')}
                                  />
                                </View>
                                <View
                                  style={[
                                    styles.peerTitleWrapper,
                                    styles.flexJcCtAiCt,
                                    styles.infoPdBotMd,
                                    styles.topPdLg,
                                  ]}>
                                  <View
                                    style={[
                                      styles.textFullWrapper,
                                      styles.pdTopSm,
                                    ]}>
                                    <Text
                                      style={[styles.textTitleMainExLgBold]} allowFontScaling= {false}>
                                      Build your Community
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.textFullWrapper,
                                      styles.pdTopExSm,
                                    ]}>
                                    <Text
                                      style={[
                                        windowWidth > 1366
                                          ? styles.textTitleLg
                                          : styles.textTitleMd,
                                      ]}
                                      allowFontScaling= {false}
                                      >
                                      Together we are stronger
                                    </Text>
                                  </View>
                                </View>

                                <View
                                  style={[
                                    styles.textAreaView,
                                    styles.colPdLtRtBot,
                                  ]}>
                                  <View style={[styles.peerListRow]}>
                                    <View style={[styles.itemIconView]}>
                                      <Image
                                        source={require('../../../assets/peer-to-peer/people.png')}
                                        style={styles.peerListIcon}
                                      />
                                    </View>
                                    <View style={[styles.detailItemData]}>
                                      <Text
                                        style={[
                                          windowWidth > 1366
                                            ? styles.textTitleSm
                                            : styles.textTitle,
                                          styles.textColorLight,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        Make connections with people like you
                                      </Text>
                                    </View>
                                  </View>

                                  {DisplayText(
                                    'Get answers from your community',
                                    windowWidth,
                                    require("../../../assets/peer-to-peer/world.png")
                                  )}
                                  {DisplayText(
                                    'Your chats are private',
                                    windowWidth,
                                    require("../../../assets/peer-to-peer/lock.png")
                                  )}
                                </View>

                                <View
                                  style={[
                                    styles.textAreaFull,
                                    styles.btnBlockFull,
                                    styles.pdTopSm,
                                  ]}>
                                  <View style={styles.fieldBtn}>
                                    <PrimaryButton
                                      btnText={'Let’s get started'}
                                      touchableOnPress={handleOnNext}
                                      btnShadow={true}
                                      btnStyle={[styles.primaryButtonLg]}
                                      disabled={wait}
                                      nativeId={'communityDisclaimerStart'}
                                    />
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>

                        <View style={stylesRes.commonBotImgArea}>
                          <View
                            style={[
                              stylesRes.commonBotImgView,
                              {
                                width: botIconWidthImg,
                                height: botIconHeightImg,
                              },
                            ]}
                            dataSet={{media: ids.commonBotImgView}}>
                            <Image
                              style={styles.imgCover}
                              source={require('../../../assets/setup/bg-intro-bottom-left.png')}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default (DisclaimerComponent);
