export const DASHBOARD = {
  path: 'dashboard',
  index: 0,
  title: 'Dashboard',
  root: '/',
  joinStudyPath: 'joinstudy',
};
export const JOURNALS = {
  path: 'journals-list',
  index: 1,
  title: 'Entries',
  addJournalPathSuffix: 'edit',
  viewJournalPathSuffix: 'view',
  addJournalPath: 'journal-entry/edit', //'add-new-journal',
  viewJournalPath: 'journal-entry/view',
  addQuickNotePath: 'add-quick-note',
  updateRoutinePath: 'update-routine',
  createJournalPath: 'create-journal',
  journalFromEmail: 'journal-from-email'
};
export const TODOS = {
  path: 'todos',
  index: 2,
  title: 'To-dos',
  addToDoPath: 'add-todo',
  todoAttachToJournalPath: 'todo-attach-to-journal',
  todoAttachToContactPath: 'todo-attach-to-contact',
  todoAttachToTreatmentPath: 'todo-attach-to-treatment',
};

export const MEDICATIONS = {
  path: 'medications-therapies',
  index: 4,
  title: 'Meds/Supplements & Activities',
  viewTherapiesPath: 'view-medications-therapies',
  addTherapiePath: 'add-therapy',
  scanPrescriptionPath: 'scan-prescription',
  editTherapiePath: 'edit-therapy',
};

export const CONTACTS = {
  path: 'contacts',
  index: 5,
  title: 'Contacts',
  viewContactPath: 'view-contact',
  addContactPath: 'add-contact',
  contactTakePhotoPath: 'contact-takephoto',
  editContactPath: 'edit-contact',
};

export const REPORTS = {
  path: 'reports',
  index: 6,
  title: 'Reports',
  newReportPath: 'report-new',
  editReportPath: 'report-edit',
  viewReportPath: 'report-view',
  reportAddSymptomPath: 'report-add-symptom',
  reportAddIssuePath: 'report-add-issue',
  reportAddMedicationsPath: 'report-add-medications',
  reportAddImagePath: 'report-add-image',
  reportAddGraphPath: 'report-add-graph',
};

export const COMMUNITY_GROUPS = {
  path: 'health-library',
  index: 7,
  title: 'Health Library',
  shareResourcePath: 'share-resource',
};

export const IMAGES_DOCUMENTS = {
  path: 'images-documents',
  index: 8,
  title: 'Attachments',
  addImagesDocumentsPath: 'add-images-documents',
  attachToJournalPath: 'attach-images-documents-to-journal',
  attachToContactPath: 'attach-images-documents-to-contact',
  attachToTreatmentPath: 'attach-images-documents-to-treatment',
};

export const SURVEYS = {
  path: 'surveys',
  index: 9,
  title: 'Surveys',
  mainSurveyPath: 'main-survey',
  surveyQuestionsPath: 'survey-questions',
  surveyQuestionPath: 'survey-question',
  surveyConsentPath: 'survey-consent',
};
export const ROUTINES = {
  path: 'routines',
  index: 10,
  title: 'Routines',
  addRoutineTemplatePath: 'add-new-routine',
  editRoutineTemplatePath: 'edit-routine',
};

export const SYMPTOMS = {
  path: 'symptoms',
  index: 11,
  title: 'Symptoms',
  viewSymptomPath: 'view-symptom',
  addSymptomPath: 'add-symptom',
  editSymptomPath: 'edit-symptom',
};

export const METRICS = {
  path: 'health-data',
  index: 12,
  title: 'Health Data',
  viewMetricPath: 'view-health-data',
  addMetricPath: 'add-health-data',
  editMetricPath: 'edit-health-data',
};

export const GRAPHS = {
  path: 'graphs',
  index: 13,
  title: 'Graphs',
  addGraphPath: 'add-graph',
  editGraphPath: 'edit-graph',
};

export const CLINICAL_TRIALS = {
  path: 'clinical-trials',
  index: 14,
  title: 'Clinical Trials',
  otherPath: 'clinical-trial-disclaimer',
};

export const CAREGIVERS = {
  path: 'profile-caregiver-access',
  index: 15,
  title: 'Caregivers',
  inviteCaregiverPath: 'profile-invite-caregiver',
  caregiverPath: 'caregiver-settings',
  caregiverAcceptPath: 'accept',
};

export const PUSH_NOTIFICATION = {
  path: 'push-notifications',
  index: 16,
  title: 'Dashboard',
};

export const ABOUT_HANALAYTICS = {
  path: 'about-hanalaytics',
  index: 17,
  title: 'Dashboard',
};

export const HELP = {
  path: 'help',
  index: 18,
  title: 'Tutorials & Help',
};

export const PEER_TO_PEER = {
  path: 'community',
  index: 19,
  title: 'Community',
  disclaimerPath: 'community-disclaimer',
  peerResultsByConditionPath: 'latest-connections',
  findPeersPath: 'find-connections',
  searchResultsPath: 'search-connections',
  individualChatPath: 'conversation',
  newChatListPath: 'start-new-chat',
};

export const REFER_FRIEND = {
  path: 'refer-a-friend',
  index: 20,
  title: 'Refer a friend',
};

export const MANAGE_DEVICES = {
  path: 'manage-devices',
  index: 21,
  title: 'Manage Devices',
}

export const HEALTH_JOURNEY = {
  path: 'health_journey',
  addEventPath: 'add_event',
  addAttachments: 'add_attachments',
  index: 22,
  title: 'Health Journey',
  filter: "filter",
}

export const PROFILE = {
  path: 'profile',
  index: -1,
  title: 'Profile',
  profileEditPath: 'profile-edit',
  profileMyDevicesPath: 'profile-my-devices',
  profileMeasurementsPath: 'profile-measurements',
  profileCancelSubscriptionPath: 'profile-cancel-subscription',
  profilePremiumAccountPath: 'profile-premium-account',
  profileUpgradePremiumPath: 'profile-upgrade-premium',
};

export const OTHER = {
  signupPath: 'signup',
  loginPath: 'login',
  forgotPasswordPath: 'reset-password',
  welcomePath: 'welcome',
  emailVerificationPath: 'email-verification',
  emailReset: 'recover',
  notificationPath: 'notification',
  setupPath: 'setup',
  noNetwork: 'no-network',
  caregiverVerificationPath: 'caregiver-verfication',
};
