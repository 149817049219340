import React from "react";
import { Text, View, Image,TouchableOpacity } from "react-native";

import Modal from "../../components/Modal/modal";
import ModalLayout from "../../components/ModalLayout/modalLayout";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";

import styles from "../../Css";

const DeviceDisconnectedModal = (props) => {
  return (
    <Modal
      visible={props.visibleModal}
      onRequestClose={props.onRequestClose}
      onBackdropPress={props.onBackdropPress}
    >
      <ModalLayout
        contPosition={"start"}
        showClose={false}
        pdCom={true}
        hidePopup={props.onBackdropPress}
        // closeIcnType={"SPECIAL"}
      >
        <View style={[styles.popupContentWrapper, styles.flexCom]}>
          {/* CLOSE ICON */}
          <View style={styles.crossIcnBtnSm}>
            <TouchableOpacity onPress={props.onRequestClose}>
              <View>
                <Image
                  style={styles.closeDarkIconSm}
                  source={require('../../assets/icons/menu-res-active.png')}
                />
              </View>
            </TouchableOpacity>
          </View>
        
           {/* MODAL CONTENT */}
          <View style={[styles.flexJcCt, styles.flexAiCt]}>
              <View style={[styles.flexRow, styles.pdTopExSm ]}>
                <Image
                    style={styles.statusWarningIcon}
                    source={require("../../assets/icons/warning-exclamation.png")}
                  />
                  <Text style={[styles.navListsubPd, styles.textPrimeLgBold]}> 
                    Disconnect This Device? 
                   </Text>
              </View>

              <View style={[styles.flexJcCt, styles.flexAiCt, styles.contPdTop,styles.twoThirtyNineWidth]}>
                  <Text style={styles.textPrimeMd}>
                    Disconnecting will remove the associated routine, 
                    Zamplo will no longer retrieve data from the device.
                  </Text>
              </View>

              <View style={styles.titleTopPd}>
                  <Text style={styles.textPrimeMd}>
                    Do you wish to proceed?
                  </Text>
              </View>

              <View style={[styles.flexRow, styles.secSpPdTop]}>
                <TouchableOpacity 
                onPress={props.onDisconnect}
                >
                  <View style={[
                    styles.attachmentBtnBorder,
                    styles.eigthBrdRad, 
                    styles.flexJcCt, 
                    styles.flexAiCt,
                    styles.bgWhite,
                    styles.sixMgRght,
                    styles.mdBtnHexo
                  ]}>
                  {Boolean(props.connectingStatus) &&
                      <View style={[styles.actIndSwitch,styles.twoDigit,styles.oneTp]}>
                        <ActivityIndicator size="small" pdZero={true} />
                      </View>
                  }
                      <View style={styles.mdBtnHexoTxt}>
                      <Text style={[styles.textTitleBold,styles.textColorGreyLight]}>  
                        Disconnect 
                      </Text>
                      </View>
                  </View>
                </TouchableOpacity>

                <TouchableOpacity onPress={props.onBackdropPress}>
                  <View style={[
                    styles.mdBtnHexo,
                    styles.eigthBrdRad, 
                    styles.flexJcCt, 
                    styles.flexAiCt,
                    styles.sixMgLt,
                    styles.cancelBtnHexo
                    ]}>
                    <View style={styles.mdBtnHexoTxt}>
                      <Text style={[styles.textTitleBold,styles.textWhite]}>  
                        Cancel 
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
          </View>
          
        </View>
      </ModalLayout>
    </Modal>
  );
};
export default (DeviceDisconnectedModal);
