import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Collapsible from '../../components/Collapsible/Collapsible';
import moment from 'moment';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import {
  getCoverIcon,
  lowerCaseAllWordsExceptFirstLetters,
} from '../../util/commonUiLogic';
import PlusButtonCom from "../../components/Button/plusButtonCom";

class ReportTreatment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleReportTherapy: props.dosingOpen,
      filteredRecordedDosing: props.filteredRecordedDosing
        ? props.filteredRecordedDosing
        : [],
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    const {filteredRecordedDosing} = props;
    this.setState({filteredRecordedDosing});
  }
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    let count = 0;
    return (
      <Collapsible
        title="Medications/Therapies"
        contentVisibility={this.state.visibleReportTherapy}
        handleOnPressCollapsible={() => {
          this.setState({
            visibleReportTherapy: !this.state.visibleReportTherapy,
          });
          this.props.setTabOpen(3, !this.state.visibleReportTherapy);
        }}>
        <View style={[styles.collapseContent]}>
          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              {/* If Medications added only, Please add styles.addedItemsIssues  */}
              {/* <View style={[styles.addedItemsThrapies]}> */}
              <View
                style={[
                  this.state.filteredRecordedDosing.length > 0
                    ? styles.addedItemsThrapies
                    : styles.addedItemsNone,
                ]}>
                {/* Loop Item Start */}

                {this.state.filteredRecordedDosing &&
                  this.state.filteredRecordedDosing.map(item => {
                    const treatment = this.props.treatmentsProcessed[
                      item.userTreatment
                    ];
                    count++;
                    if (treatment && treatment.name) {
                      return (
                        <View
                          style={[
                            styles.listItemView,
                            styles.listItemAddedView,
                            styles.colPdLtRt,
                            styles.colBg,
                          ]}
                          key={"rt_" + item.id}
                        >
                          <View style={[styles.listItemCloseCenter]}>
                            <View style={styles.listItemCloseIcon}>
                              <TouchableOpacity
                                onPress={() =>
                                  this.props.removeReportDosing(item.id)
                                }>
                                <Image
                                  style={styles.closeDarkIcon}
                                  source={require('../../assets/icons/close-dark.png')}
                                />
                              </TouchableOpacity>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.listContentViewFull,
                              styles.colPdTopBot,
                            ]}>
                            <View style={[styles.listContentView]}>
                              <View
                                style={[
                                  styles.addedItemInd,
                                  styles.addedItemRtPd,
                                ]}>
                                <View style={[styles.itemFullRow]}>
                                  <View style={stylesRes.itemImgAreaMain}>
                                    <View style={stylesRes.itemMediIcon}>
                                      <Image
                                        style={styles.imgContain}
                                        source={getCoverIcon(
                                          treatment.treatmentType,
                                        )}
                                      />
                                    </View>
                                  </View>

                                  <View style={stylesRes.itemTextAreaMain}>
                                    <View style={[styles.itemTextDetails]}>
                                      <View style={[styles.itemTextMainCom]}>
                                        <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                                          {lowerCaseAllWordsExceptFirstLetters(
                                            treatment.name,
                                          )}
                                        </Text>
                                        <View style={stylesRes.itemAttachSec}>
                                          <TouchableOpacity
                                            onPress={() =>
                                              this.props.redirectPage(
                                                treatment.artifactId,
                                              )
                                            }>
                                            <View
                                              style={stylesRes.itemAttachIcon}>
                                              <Image
                                                style={styles.imgContain}
                                                source={require('../../assets/icons/attach-lg.png')}
                                              />
                                            </View>
                                          </TouchableOpacity>
                                        </View>
                                      </View>

                                      <View style={[styles.itemTextSub]}>
                                        <Text style={[styles.textPrimeSm]} allowFontScaling={false}>
                                          {item.quantity} {item.units}
                                        </Text>
                                        <Text
                                          style={[
                                            styles.textPrimeSm,
                                            styles.textOrange,
                                            styles.textEm,
                                          ]}
                                          allowFontScaling={false}
                                          >
                                          {' '}
                                          {item.frequency} {item.frequencyType ? 'times ' + item.frequencyType.toLowerCase().replace('_', ' ') : ''}
                                        </Text>
                                      </View>
                                    </View>

                                    <View style={stylesRes.itemTextDate} dataSet={{media: ids.itemTextDate}}>
                                      {item.start || item.end ? (
                                        <Text
                                          style={[
                                            styles.textPrimeSm,

                                            styles.textAlignRight,
                                          ]}
                                          allowFontScaling={false}
                                          >
                                          {item.start
                                            ? moment(item.start, 'x').format(
                                                "D MMM 'YY",
                                              )
                                            : null}{' '}
                                          {item.end
                                            ? '- ' +
                                              moment(item.end, 'x').format(
                                                "D MMM 'YY",
                                              )
                                            : null}
                                        </Text>
                                      ) : null}
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                          {count < this.state.filteredRecordedDosing.length ? (
                            <View style={stylesRes.itemBorderReport} dataSet={{media: ids.itemBorderReport}} />
                          ) : null}
                        </View>
                      );
                    } else {
                      return null;
                    }
                  })}
                {/* Loop Item End */}
              </View>

              <View style={[styles.addItemSecFull, styles.colBg, styles.colPd]}>
              <View style={[styles.btnArea]}>
                  <PlusButtonCom
                    btnPosition={"left"}
                    btnText={"Add"}
                    touchableOnPress={this.props.redirectReportAttachMedications}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Collapsible>
    );
  }
}

const mapStateToProps = state => {
  return {
    filteredRecordedDosing: state.report.filteredRecordedDosing,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    dosingOpen: state.report.dosingOpen,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeReportDosing: id => dispatch(actions.removeReportDosing(id)),
    setTabOpen: (tab, open) => dispatch(actions.setTabOpen(tab, open)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportTreatment);
