import * as actionTypes from './actionTypes';

export const updateGraphName = graphName => {
  return {
    type: actionTypes.UPDATE_GRAPH_NAME,
    graphName: graphName,
  };
};

export const updateStartDate = startDate => {
  return {
    type: actionTypes.UPDATE_GRAPH_START_DATE,
    startDate: startDate,
  };
};

export const updateEndDate = endDate => {
  return {
    type: actionTypes.UPDATE_GRAPH_END_DATE,
    endDate: endDate,
  };
};

export const addSymptom = symptom => {
  return {
    type: actionTypes.ROLLING_GRAPH_ADD_SYMPTOM,
    symptom: symptom,
  };
};

export const removeSymptom = symptom => {
  return {
    type: actionTypes.ROLLING_GRAPH_REMOVE_SYMPTOM,
    symptom: symptom,
  };
};

export const addMetric = metric => {
  return {
    type: actionTypes.ROLLING_GRAPH_ADD_METRIC,
    metric: metric,
  };
};

export const removeMetric = metric => {
  return {
    type: actionTypes.ROLLING_GRAPH_REMOVE_METRIC,
    metric: metric,
  };
};

export const addTherapy = therapy => {
  return {
    type: actionTypes.ROLLING_GRAPH_ADD_THERAPY,
    therapy: therapy,
  };
};

export const removeTherapy = therapy => {
  return {
    type: actionTypes.ROLLING_GRAPH_REMOVE_THERAPY,
    therapy: therapy,
  };
};

export const resetRollingGraph = () => {
  return {
    type: actionTypes.RESET_ROLLING_GRAPH,
  };
};

export const onHideShowGraph = value => {
  return {type: actionTypes.HIDESHOWGRAPH, dashboardGraph: value};
};

export const selectGraph = (graph, callBack) => {
  const treatments = graph.treatments ? graph.treatments.split(',') : [];
  const metrics = graph.metrics ? graph.metrics.split(',') : [];
  const symptoms = graph.symptoms ? graph.symptoms.split(',') : [];
  const treatmentsObj = {};
  const metricsObj = {};
  const symptomsObj = {};
  treatments.forEach(element => {
    treatmentsObj[element] = element;
  });
  metrics.forEach(element => {
    metricsObj[element] = element;
  });
  symptoms.forEach(element => {
    symptomsObj[element] = element;
  });
  let startDate = null;
  if (graph.startDate) {
    startDate = new Date(graph.startDate).getTime();
  }
  let endDate = null;
  if (graph.endDate) {
    endDate = new Date(graph.endDate).getTime();
  }
  const treatmentsProperties = graph.treatmentsProperties ? JSON.parse(graph.treatmentsProperties) : [];
  const symptomsProperties = graph.symptomsProperties ? JSON.parse(graph.symptomsProperties) : [];
  const metricsProperties = graph.metricsProperties ? JSON.parse(graph.metricsProperties) : [];
 
  const therapyPropsObj = {};
  const symptomPropsObj = {};
  const metricPropsObj = {};
  Object.values(treatmentsProperties).forEach(element => {
    therapyPropsObj[element.id] = element
  });
  Object.values(symptomsProperties).forEach(element => {
    symptomPropsObj[element.id] = element
  });
  Object.values(metricsProperties).forEach(element => {
    metricPropsObj[element.id] = element
  });
  callBack();
  return {
    type: actionTypes.SELECT_ROLLING_GRAPH,
    id: graph.id,
    graphName: graph.name ? graph.name : 'Defult Graph',
    symptoms: symptomsObj,
    metrics: metricsObj,
    therapies: treatmentsObj,
    startDate: startDate,
    endDate: endDate,
    symptomProps: symptomPropsObj,
    metricProps: metricPropsObj,
    therapyProps: therapyPropsObj,
    entryTypeId: graph.entryTypeId,
  };
};

export const previewGraph = () => {
  return {
    type: actionTypes.PREVIEW_GRAPH,
  };
};

export const updateSymptomProps = (symptomId, chartType, minVal, maxVal) => {
  return {
    type: actionTypes.UPDATE_SYMPTOM_PROPS,
    symptomId: symptomId,
    symptomProps: {
      id: symptomId, 
      style: chartType, 
      min: minVal, 
      max: maxVal
    },
  };
};

export const updateMetricProps = (metricId, chartType, minVal, maxVal) => {
  return {
    type: actionTypes.UPDATE_METRIC_PROPS,
    metricId: metricId,
    metricProps: {
      id: metricId, 
      style: chartType, 
      min: minVal, 
      max: maxVal
    },
  };
};

export const updateTherapyProps = (therapyId, chartType, minVal, maxVal) => {
  return {
    type: actionTypes.UPDATE_THERAPY_PROPS,
    therapyId: therapyId,
    therapyProps: {
      id: therapyId, 
      style: chartType, 
      min: minVal, 
      max: maxVal
    },
  };
};

export const setGraphInitHashValue = (initHashVal) => {
  return {
    type: actionTypes.SET_GRAPH_INIT_HASH_VALUE,
    initHashValue: initHashVal
  };
}

export const updateTempClr = (values) => {
  return {
    type: actionTypes.UPDATE_TEMP_CLRS,
    values
  };
}

export const clearTempClr = () => {
  return {
    type: actionTypes.CLEAR_TEMP_CLRS,
    values: null
  };
}