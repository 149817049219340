import React from 'react';
import {View, Text, Image} from 'react-native';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import styles from '../../Css';

const {Popover} = renderers;

const journalEntryPopupMenu = props => {
  return (
    <Menu renderer={Popover} rendererProps={{preferredPlacement: 'left'}}>
      <MenuTrigger>
        <View style={[styles.itemIconSingle, styles.itemSettingMenu]}>
          <Image
            style={[styles.settingIconThin, styles.settingIconLight]}
            source={require('../../assets/icons/setting-lg.png')}
          />
        </View>
      </MenuTrigger>

      <MenuOptions style={[styles.dropDownList, styles.menuOptionsLg]}>
      {props.iconStatus && !props.deviceJournal ? (
          <React.Fragment>
            <MenuOption style={styles.liItem} onSelect={props.handleCopytoNew}>
              <Text style={styles.listText} allowFontScaling= {false}>Copy to New</Text>
            </MenuOption>
            <MenuOption style={styles.liItem} onSelect={props.handleConvertToRoutine}>
              <Text style={styles.listText} allowFontScaling= {false}>Convert to Routine</Text>
            </MenuOption>
          </React.Fragment>
        ) : null}
        <MenuOption style={styles.liItem} onSelect={props.handleEditJournal}>
          <Text style={styles.listText} allowFontScaling= {false}>Edit</Text>
        </MenuOption>
        {!Boolean(props.deviceJournal) &&
          <MenuOption style={styles.liItem} onSelect={props.handleDeleteJournal}>
              <Text style={styles.listText} allowFontScaling= {false}>Delete</Text>
          </MenuOption>
        }
      </MenuOptions>
    </Menu>
  );
};
export default journalEntryPopupMenu;
