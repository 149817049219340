import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import ActivityIndicator from '../ActivityIndicator/activityIndicator';
import styles from '../../Css';

const selectSaveButton = props => {
  const { isFromSave = false, saveWait = false, disabled = true, onPress } = props;
  return (
    <View style={styles.gridRow}>
        <View style={styles.gridColFull}>
            <View style={[styles.btnBlock, styles.btnMgLg]}>
                <TouchableOpacity
                    onPress={onPress}
                    disabled={disabled}>
                    <View
                        style={[styles.submit, styles.submitMiniBtn, !isFromSave && styles.disableBtn]}
                        underlayColor="rgba(0, 153, 168, 0.8)">
                        {saveWait && (
                            <View style={styles.actIndCenter}>
                                <ActivityIndicator size="small" />
                            </View>
                        )}
                        <Text style={styles.submitText} allowFontScaling={false}>{isFromSave ? 'Save' : 'Select'}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    </View>
  );
};

export default selectSaveButton;
