import React from 'react';
import styles from '../../../Css';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import { pendoTrackEvent } from '../../../util/pendoConfig';

const setupWizardMenuItem = props => {
  const { onPress, tabMenuIconView, handleTabMenuClick } = props;
  return (
    <View nativeID={'menuItemRoutineWizard'}>
      <View 
        nativeID={'menuRoutineWizard'}
        style={[
          styles.setupMenuWrapper, 
          styles.noMgTop
        ]}
      >
        <TouchableOpacity 
          style={[styles.setupMenuDetails]} 
          onPress={() => {
            if (handleTabMenuClick) {
              handleTabMenuClick()
            } else {
            onPress();
            pendoTrackEvent('Menu > RoutineWizard');
            }
          }}
        >
          <View style={[styles.setupMenuView]}>
            <Image
              style={styles.setupMenuIcon}
              source={require('../../../assets/main-nav/setup.png')}
            />
          </View>
          {!Boolean(tabMenuIconView) && (
          <View style={[styles.setupMenuTitle]}>
            <Text style={[styles.textPrime, styles.textOrange]} allowFontScaling={false}>
              Open Routine Wizard
            </Text>
          </View>
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default (setupWizardMenuItem);
