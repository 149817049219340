import React, { Component } from 'react';
import { connect } from 'react-redux';
import ViewCustomSymptom from '../../../components/Settings/symptoms/viewSymptom';
import * as actions from '../../../store/actions/index';
import ConfirmationBox from '../../../components/ConfirmationBox/simpleConfirmBox';
import { HEALTH_JOURNEY, SYMPTOMS } from '../../../navigation/path';
import CannotDeleteBox from '../../../components/ConfirmationBox/cannotDeleteBox';
import { pendoTrackEvent } from '../../../util/pendoConfig';
import { chartTypes } from '../../../Constant/index';
import { TAB_BREAk_POINT_END } from '../../../util/platformWindowConfig';

class ViewCustomSymptomScreen extends Component {
  constructor(props) {
    super(props);
    if (this.props.selectedSymptom) {
      this.state = {
        id: (this.props.selectedSymptom || {}).id,
        symptomName: (this.props.selectedSymptom || {}).name,
        publishedBy: (this.props.selectedSymptom || {}).publishedBy,
        color: ((this.props.selectedSymptom || {}).displayProperties || {})
          .color,
        graphStyle: ((this.props.selectedSymptom || {}).displayProperties || {}).style || 
          (((this.props.selectedSymptom || {}).displayProperties || {}).dashedLine === false
            ? chartTypes.dash
            : chartTypes.line),
        validateName: true,
        deleteConfirm: false,
        deleteWait: false,
        selectedId: null,
        recordings: this.getRecordings(this.props.selectedSymptom.id),
        cannotDeleteBox: false,
        fromHealthJourney: props.route?.params?.from === HEALTH_JOURNEY.path
      };
      this.tapCount = 0;
    }
  }

  // UNSAFE_componentWillReceiveProps(props) {
  //   const { error, errorDescription, selectedSymptom } = props;
  //   this.setState({
  //     error,
  //     errorDescription,
  //     deleteWait: false,
  //     deleteConfirm: false,
  //     alertMessageTitle: null,
  //     recordings: this.getRecordings(selectedSymptom.id)
  //   });
  // }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      if (this.props.selectedSymptom) {
        this.setState({
          id: (this.props.selectedSymptom || {}).id,
          symptomName: (this.props.selectedSymptom || {}).name,
          publishedBy: (this.props.selectedSymptom || {}).publishedBy,
          color: ((this.props.selectedSymptom || {}).displayProperties || {})
            .color,
          graphStyle: ((this.props.selectedSymptom || {}).displayProperties || {}).style ||
            (((this.props.selectedSymptom || {}).displayProperties || {}).dashedLine === false
              ? chartTypes.dash
              : chartTypes.line),
          validateName: true,
          deleteConfirm: false,
          deleteWait: false,
          selectedId: null,
          recordings: this.getRecordings(this.props.selectedSymptom.id),
          cannotDeleteBox: false,
        });
      }
      pendoTrackEvent('Symptoms/@ViewSymptom');
    });
    this.props.navigation.addListener('blur', () => {
      this.tapCount = 0;
    });
  }

  onPressEditUserSymptom = () => {
    this.tapCount = this.tapCount + 1;
    if (this.tapCount === 1) {
      pendoTrackEvent('ViewSymptom@ > 3Dots > Edit');
      if (this.state.recordings) {
        if (this.props.selectedSymptom) {
          let selectedSymptom = this.props.selectedSymptom;
          selectedSymptom.recordings =
            this.state.recordings !== null ? this.state.recordings : undefined;
          this.props.onSelectSymptom(selectedSymptom, () => {
            this.props.navigation.push(SYMPTOMS.editSymptomPath);
          });
        }
      } else {
        if (this.props.selectedSymptom) {
          this.props.onSelectSymptom(this.props.selectedSymptom, () => {
            this.props.navigation.push(SYMPTOMS.editSymptomPath);
          });
        }
      }
    }
  };

  onPressDeleteUserSymptom = id => {
    if (this.state.recordings) {
      this.setState({
        cannotDeleteBox: true
      });
    } else {
      this.setState({
        deleteConfirm: true,
        alertMessageTitle: 'Are you sure you want to delete this Symptom?',
        selectedId: id,
      });
    }
  };

  onPressDelete = () => {
    this.setState({ deleteWait: true });
    if (this.state.selectedId) {
      pendoTrackEvent('ViewSymptom@ > 3Dots > Delete');
      this.props.onDeleteSymptoms(
        this.state.selectedId,
        this.props.userDefinedSymptoms,
        res => {
          this.setState({
            deleteWait: false,
            deleteConfirm: false,
            alertMessageTitle: null,
            // cannotDeleteBox: true
          });
          if (res) {
            this.props.navigation.navigate(
              SYMPTOMS.path, {
                from: this.props.route.name,
              },
            );
          }
        },
        this.props.journalUserId,
      );
    }
  };

  getRecordings = id => {
    let symptoms = [];
    let records = [];
    const journalEntries = Object.values(this.props.journalEntriesProcessed)
    if (journalEntries && journalEntries.length > 0) {
      journalEntries.forEach(record => {
        if (record.symptoms && record.symptoms.length > 0) {
          record.symptoms.forEach(re => {
            symptoms.push(re);
          });
        }
      });
    }

    if (symptoms && symptoms.length > 0) {
      records = symptoms.filter(res => {
        return res.symptom === id;
      });
    }
    return records.length;
  };

  render() {
    return (
      <React.Fragment>
        <ViewCustomSymptom
          handleOnPressBackButton={() => {
            if (this.state.fromHealthJourney) {
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: this.props.windowWidth < TAB_BREAk_POINT_END && this.props.eventData && this.props.eventData["type"] ? HEALTH_JOURNEY.addEventPath : HEALTH_JOURNEY.path }],
              });
            } else {
              this.props.navigation.navigate(
                SYMPTOMS.path, {
                  from: this.props.route.name,
                },
              );
            }
          }}
          symptomName={this.state.symptomName}
          onChangeTextSymptomName={symptomName => {
            if (symptomName) {
              this.setState({ validateName: true });
            } else {
              this.setState({ validateName: false });
            }

            this.setState({ symptomName: symptomName });
          }}
          setColor={this.state.color}
          onPressSetColor={color => {
            this.setState({ color: color });
          }}
          graphStyle={this.state.graphStyle}
          id={this.state.id}
          handleOnPressRadioForm={this.handleOnPressRadioForm}
          validateName={this.state.validateName}
          onPressEditUserSymptom={this.onPressEditUserSymptom}
          onPressDeleteUserSymptom={this.onPressDeleteUserSymptom}
          recordings={this.state.recordings}
          index={SYMPTOMS.index}
          navigation={this.props.navigation}
          route={this.props.route}
          publishedBy={this.state.publishedBy}
          fromHealthJourney={this.state.fromHealthJourney}
        />
        <ConfirmationBox
          visibleModal={this.state.deleteConfirm}
          handleCancel={() => {
            this.setState({ deleteConfirm: false });
          }}
          onRequestClose={() => {
            this.setState({ deleteConfirm: false });
          }}
          alertTitle={this.state.alertMessageTitle}
          handleConfirm={this.onPressDelete}
          confirmWait={this.state.deleteWait}
        />
        {/* cannot delete message box */}
        <CannotDeleteBox
          visibleModal={this.state.cannotDeleteBox}
          onBackdropPress={() => {
            this.setState({ cannotDeleteBox: false });
          }}
          onRequestClose={() => {
            this.setState({ cannotDeleteBox: false });
          }}
          name={"Symptoms"}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    userDefinedSymptoms: state.routines.symptomsDigest.userSymptoms,
    selectedSymptom: state.symptom.selectedSymptom,
    journalUserId: state.uistate.journalUserId,
    windowWidth: state.uistate.windowWidth,
    eventData: state.uistate.eventData,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSelectSymptom: (selectedSymptom, callback) =>
      dispatch(actions.onSelectSymptom(selectedSymptom, callback)),
    onDeleteSymptoms: (id, userSymptoms, callback, journalUserId) =>
      dispatch(
        actions.deleteUserSymptom(id, userSymptoms, callback, journalUserId),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewCustomSymptomScreen);
