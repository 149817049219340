import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import styles from "../../../../Css";
import stylesResponsive from "../../../../CssResponsive";
import ActivityIndicator from "../../../../components/ActivityIndicator/activityIndicator";
import { openLink } from '../../../../services/LinkingService'

class ClinicalTrialResult extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      result: props.result,
      location: props.location,
      isSelected:
        props.newClinicalTrials &&
          props.newClinicalTrials[props.result.title + props.location]
          ? true
          : false,
      saveWait: false,
      deleteWait: false,
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      result: props.result,
      location: props.location,
      newClinicalTrials: props.newClinicalTrials,
      errorDescription: props.errorDescription,
      error: props.error,
    });
  }

  onAdd = () => {
    let clinicalTrialId = this.state.result.title + this.state.location;
    let addNewClinicalTrial = {
      clinicalTrial: {
        id: -1,
        createdOn: null,
        modifiedOn: null,
        artifactId: null,
        nctId: null,
        conditionList: this.getConditions(),
        briefTitle: this.state.result.title,
        briefSummary: null,
        detailedDescription: null,
        overallStatus: this.state.result.status.content,
        locationList: this.getLocations(),
        distance: null,
        url: this.state.result.url
      },
    };
    
    this.props.onAddClinicalTrial(
      addNewClinicalTrial,
      (success, data) => {
        if (success) {
          this.props.onAddClinicalTrialToMyList(clinicalTrialId, data.id);
          this.setState((prevState) => ({
            isSelected: !prevState.isSelected,
          }));
        }
          this.setState({ saveWait: false });
        
      },
      this.props.journalUserId
    );
  };
  onRemove = () => {
    let clinicalTrialId = this.state.result.title + this.state.location;
    ;
    const clinicalTrials = { ...this.props.newClinicalTrials };
    let selectedId = clinicalTrials[clinicalTrialId];
    this.props.onDeleteClinicalTrial(
      selectedId,
      (success) => {

       
        if (success) {
          this.props.onRemoveClinicalTrialFromMyList(clinicalTrialId);
          this.setState((prevState) => ({
            isSelected: !prevState.isSelected
          }));
        } 
        this.setState({ deleteWait: false });
      },
      this.props.journalUserId
    );
  };

  getConditions = () => {
    let conditions = [];
    Array.isArray(this.state.result.conditions.condition)
      ? this.state.result.conditions.condition.forEach((condition) =>
        conditions.push(condition)
      )
      : conditions.push(this.state.result.conditions.condition);

    return conditions;
  };

  getLocations = () => {
    // let locations = [];
    // this.state.result.locations !== "" ? (
    //   Array.isArray(this.state.result.locations.locations) ?
    //     this.state.result.locations.location.forEach(location => {
    //       locations.push(location);
    //     })
    //   :  locations.push(this.state.result.locations.location)
    // ) : locations = []

    // return locations;
    let locations = [];
    this.state.location !== ""
      ? locations.push(this.state.location)
      : (locations = []);

    return locations;
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    
    return (
      <View
        key={this.state.result.title + this.state.location}
        style={[styles.viewRowWrapper, styles.greyBorderThin]}
      >
        <View style={styles.viewRowTitleBlock}>
          <View style={stylesRes.viewRowTitle}>
            <Text
              style={[styles.textMicSm, styles.textEm, styles.textColorGreen]}
              allowFontScaling={false}
            >
              {this.state.result.status.content}
            </Text>
          </View>
        </View>
        <View style={[styles.ViewRow]}>
          <View style={styles.viewDetailSec}>
            <View style={styles.viewMainData}>
              <View
                style={[
                  styles.viewItemDesc,
                  styles.flexJcFsAiFs,
                  styles.colPdLtBotRgTopSm,
                ]}
              >
                <View style={[styles.viewImgSec, styles.flexAiCt]}>
                  <View style={[styles.mapIconSec]}>
                    <Image
                      source={require("../../../../assets/icons/map-mark.png")}
                      style={styles.mapIconSm}
                      resizeMode={"contain"}
                    />
                  </View>
                </View>

                <View style={[styles.viewDataFull]}>
                  <View style={[styles.viewItemData]}>
                    <TouchableOpacity
                      onPress={() => openLink(this.state.result.url)}>
                      <Text
                        style={[styles.textPrimeSmBold, styles.textUnderline]}
                        allowFontScaling={false}
                      >
                        {this.state.result.title}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View style={[styles.viewItemDataSub, styles.pdTopExSm]}>
                    <Text style={styles.textPrimeSm} allowFontScaling={false}>
                      {Array.isArray(this.state.result.conditions.condition)
                        ? this.state.result.conditions.condition.map(
                          (condition) => condition + "\n"
                        )
                        : this.state.result.conditions.condition}
                    </Text>
                  </View>
                  <View style={[styles.locDetails, styles.pdTopExSm]}>
                    <Text style={[styles.textExMicSm]} allowFontScaling={false}>
                      {this.state.location}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.dataIconSecExLg, styles.colPdRtBotRgTopSm]}>
            <View style={styles.iconListCol}>
              {/* <View
                style={[
                  styles.settingSec,
                  styles.iconSettingMgLt
                ]}
              >
                <TouchableOpacity>
                  <Image
                    style={[
                      styles.settingIcon,
                      styles.settingSp
                    ]}
                    source={require("../../assets/icons/settings.png")}
                  />
                </TouchableOpacity>
              </View> */}

              <View style={styles.btnArea}>
                <TouchableOpacity 
                  onPress={() => { 
                    this.setState({
                      ...(this.state.isSelected ? { deleteWait : true} : { saveWait: true })
                    }, () => {
                      
                      if (this.state.isSelected) {
                        this.onRemove();
                      } else {
                        this.onAdd();
                      }
                    })

                  }
                    } 
                  disabled={(this.state.saveWait || this.state.deleteWait)}
                >
                  <View
                    style={[
                      styles.submitSubSm,
                      styles.submitBgOrange,
                      styles.flexRow,
                      styles.flexJcCtAiCt,
                      this.state.isSelected
                        ? styles.disableBtn
                        : styles.submitBgOrange,
                    ]}
                  >
                    {(this.state.saveWait || this.state.deleteWait) && (
                      <View style={styles.actIndCom}>
                        <ActivityIndicator size="small" />
                      </View>
                    )}
                    <View style={[styles.moreIconSec, styles.rightSpExSm]}>
                      {!this.state.isSelected ? (
                        <Image
                          style={styles.moreIcon}
                          source={require("../../../../assets/icons/more-white.png")}
                        />
                      ) : (
                          <Image
                            style={styles.minusIcon}
                            source={require("../../../../assets/icons/minus-white.png")}
                          />
                        )}
                    </View>

                    <Text style={[styles.btnSmTextBold]} allowFontScaling={false}>My List</Text>
                  </View>
                </TouchableOpacity>
              </View>

              {/* <View style={styles.btnArea}>
                <TouchableOpacity>
                  <View style={[styles.flexJcCtAiCt]}>
                    <View style={[styles.upIconSec]}>
                      <Image
                        style={styles.upIcon}
                        source={require("../../../../assets/icons/upload.png")}
                      />
                    </View>
                  </View>
                </TouchableOpacity>
              </View> */}
            </View>
          </View>
        </View>
      </View>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    journalUserId: state.uistate.journalUserId,
    newClinicalTrials: state.clinicalTrials.newClinicalTrials,
    errorDescription: state.routines.errorDescription,
    error: state.routines.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAddClinicalTrialToMyList: (id, newClinicalTrial) =>
      dispatch(actions.addClinicalTrialToMyList(id, newClinicalTrial)),
    onRemoveClinicalTrialFromMyList: (id) =>
      dispatch(actions.removeClinicalTrialFromMyList(id)),
    onAddClinicalTrial: (newClinicalTrial, callBack, journalUserId) =>
      dispatch(
        actions.createClinicalTrial(newClinicalTrial, callBack, journalUserId)
      ),
    onDeleteClinicalTrial: (id, callBack, journalUserId) =>
      dispatch(actions.deleteClinicalTrial(id, callBack, journalUserId)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClinicalTrialResult);
