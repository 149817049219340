import React from "react";
import { View, ScrollView, Text, Image, TouchableOpacity } from "react-native";
import stylesResponsive from "../../CssResponsive";
import styles from '../../Css';
import Modal from "../Modal/modal";
import ProgressBar from "../../modules/react-native-animated-progress";

const uploadProgress = props => {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {visibleModal, progress, onPressCancel} = props;
    return (   
      <Modal
        visible={visibleModal}
        backdropStyle={true}
      >
        <View style={stylesRes.progressBarPopUp}>
            <ScrollView style={styles.modalScroller}>
                <View style={[styles.popUpBlock, styles.bgWhite]}>
                    <View style={styles.popUpContentArea}>
                    <View style={styles.gridSectionPopup}>
                        <View style={stylesRes.modalContIn}>
                            <View style={styles.gridColFull}>
                                
                                <View style={styles.flexJcCtAiCt}>

                                    <View>
                                    <Image
                                        style={[styles.uploadLgIcon]}
                                        resizeMode={"contain"}
                                        source={require("../../assets/icons/upload-button.png")}
                                        />
                                    </View>

                                    <View style={styles.progressBarTopSm}>
                                    <Text style={[styles.textTitleBold]} allowFontScaling={false}>
                                        Uploading...
                                    </Text>
                                    </View>
                                    
                                    
                                    <View style={[styles.progressBarTopLg, styles.progressSm, styles.flexJcCtAiCt ]}>
                                    <ProgressBar
                                        progress={progress}
                                        height={8}
                                        backgroundColor="#FF8300"
                                    />
                                    </View>

                                    <View style={[styles.progressBarTopLg, styles.progressTextSm]}>
                                        <Text style={[styles.progressBarText ]} allowFontScaling={false}>
                                        Please do not exit the application until upload is complete.
                                        </Text>
                                    </View>
                                    
                                    <View style={[styles.fieldBtn, styles.progressBarTopLg]}>
                                        <TouchableOpacity
                                            onPress={onPressCancel}
                                            style={[
                                                styles.progressBarCancelBtn,
                                                styles.attachmentBtnBorder,
                                                styles.bgWhite
                                            ]}
                                        >
                                        <View style={[styles.btnSingleCent]}>
                                            <Text style={[
                                            styles.textTitle,
                                            styles.textAlignCenter,
                                            styles.textColorGreyLight,
                                            styles.bold,
                                             ]}
                                             allowFontScaling={false}>
                                            Cancel
                                            </Text>
                                        </View>
                                        </TouchableOpacity>
                                    </View>

                                </View>

                            </View>
                        </View>
                    </View>
                    </View>
                </View>
            </ScrollView>
        </View>
      </Modal>
    );
};

export default (uploadProgress);
