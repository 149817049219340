import React, {Component} from 'react';
import {View, ScrollView, Text, Platform, TouchableOpacity} from 'react-native';
import ContactsListItem from '../../components/Contacts/contactsListItem';
import _ from 'underscore';
import {connect} from 'react-redux';
import stylesResponsive from '../../CssResponsive';
import Header from '../../components/Header/header';
import styles from '../../Css';
import * as actions from '../../store/actions/index';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import moment from 'moment';
import {DASHBOARD, CONTACTS, PROFILE} from '../../navigation/path';
import PageContainer from '../../components/PageLayout/PageContainer';
import SectionTitle from '../../components/Text/sectionTitle';
import SearchField from '../../components/Search/searchField';
import PlusButtonDark from '../../components/Button/plusButtonDark';
import ImageDocSearch from '../../components/Search/imageDocSearch';
import {getAccountType} from '../../util/subscriptionInformation';
import NoResultFound from '../../components/Search/noResultFound';
import Modal from '../../components/Modal/modal';
import AddMethodforEntry from '../../components/ConfirmationBox/addMethodforEntry';
import Highlighter from '../../components/Highlighter/highlighter';
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { IOS, WEB } from '../../util/platformWindowConfig';


class ContactsScreen extends Component {
  constructor(props) {
    super(props);
    const accountType = getAccountType(props.userSubscriptions);
    this.searchTextMap = {};
    //let from = null;
    //TO-DO remove previous navigation changes
    // if (this.props.location.state && this.props.location.state.from) {
    //   from = this.props.location.state.from;
    // }
    // if (this.props.route?.params && this.props.route?.params.from) {
    //   from = this.props.route?.params.from;
    // }
    this.state = {
      contactsList: [],
      careteamContactList: [],
      otherContactList: [],
      filterOn: false,
      filterWait: false,
      obj: null,
      accountType: accountType,
      visibleEntryModal: false,
      createWait: false,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      filterKey: '',
      buttonStatus: false,
    };
    if (accountType === 'premium' || this.props.journalUserId !== null) {
      this.onTicked(true);
    } else {
      this.onTicked(false);
    }
    //TO-DO remove previous navigation changes
    // if (
    //   props.filterContacts &&
    //   (!from ||
    //     (from && !from.includes(CONTACTS.viewContactPath)) ||
    //     (from &&
    //       from.includes(CONTACTS.viewContactPath) //&&
    //       //this.props.history.action !== 'PUSH'
    //     ))
    // ) {
    //   this.handleOnPressClearFilters();
    // }
  }

  componentDidMount() {
    const {filterContacts, listContacts} = this.props;
    if (this.state.imageSerachFinish) {
      return {
        imageSerachFinish: false,
      };
    } else if (
      filterContacts &&
      filterContacts.searchKeyword &&
      filterContacts.searchKeyword.trim() !== '' &&
      filterContacts.ticked &&
      !this.state.filterWait
    ) {
      this.handleImageFilter(
        filterContacts.searchKeyword,
        filterContacts.ticked,
      );
      this.setState({
        filterWait: true,
        filter: filterContacts.searchKeyword,
        searchAttachmentContent: filterContacts.ticked,
      });
    } else if (
      filterContacts &&
      filterContacts.searchKeyword &&
      filterContacts.searchKeyword.trim() !== '' &&
      !this.state.filterWait
    ) {
      let filterObj = {};
      filterObj = ContactsScreen.handleFilter(
        filterContacts.searchKeyword,
        this.props,
      );
      this.setState({
        ...filterObj,
        filterWait: false,
        filter: filterContacts.searchKeyword,
      });
    } else if (
      !this.state.filterWait &&
      (!filterContacts ||
        (filterContacts &&
          (!filterContacts.searchKeyword ||
            filterContacts.searchKeyword === '')))
    ) {
      const careteamContactList = ContactsScreen.dataFilter(listContacts, true);
      const otherContactList = ContactsScreen.dataFilter(
        listContacts,
        false,
        null,
      );
      this.setState({
        filterWait: false,
        filterOn: false,
        contactsList: listContacts,
        careteamContactList: careteamContactList,
        otherContactList: otherContactList,
        filter: '',
        obj: null,
      });
    } else {
      this.setState({
        filterWait: this.state.filterWait,
        contactsList: listContacts,
        careteamContactList: ContactsScreen.dataFilter(listContacts, true),
        otherContactList: ContactsScreen.dataFilter(listContacts, false, null),
        obj: null,
        filter:
          filterContacts && filterContacts.searchKeyword
            ? filterContacts.searchKeyword
            : '',
      });
    }

    this.props.navigation.addListener('blur', () => {
      this.props.navigation.setParams({from: CONTACTS.path});
    });
    this.props.navigation.addListener('focus', () => {
      let from = null;
      if (this.props.route?.params && this.props.route?.params.from) {
        from = this.props.route?.params.from;
      }
      if (
        this.props.filterContacts &&
        (!from ||
          (from && 
            !(from.includes(CONTACTS.viewContactPath) ||
              from.includes(CONTACTS.addContactPath) ||
              from.includes(CONTACTS.editContactPath))
          )
        )
      ) {
        this.handleOnPressClearFilters();
      }
      pendoTrackEvent('Contacts@');
    });
  }

  static getDerivedStateFromProps(props, state) {
    const {filterContacts, windowHeight, windowWidth} = props;

    if (state.imageSerachFinish) {
      return {
        imageSerachFinish: false,
      };
    } else if (
      filterContacts &&
      filterContacts.searchKeyword &&
      filterContacts.searchKeyword.trim() !== '' &&
      filterContacts.ticked &&
      !state.filterWait
    ) {
      return {
        filter: filterContacts.searchKeyword,
        searchAttachmentContent: filterContacts.ticked,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    } else if (
      filterContacts &&
      filterContacts.searchKeyword &&
      filterContacts.searchKeyword.trim() !== '' &&
      !state.filterWait
    ) {
      let filterObj = {};
      filterObj = ContactsScreen.handleFilter(
        filterContacts.searchKeyword,
        props,
      );
      return {
        ...filterObj,
        filterWait: false,
        filter: filterContacts.searchKeyword,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    } else if (
      !state.filterWait &&
      (!filterContacts ||
        (filterContacts &&
          (!filterContacts.searchKeyword ||
            filterContacts.searchKeyword === '')))
    ) {
      const careteamContactList = ContactsScreen.dataFilter(
        props.listContacts,
        true,
      );
      const otherContactList = ContactsScreen.dataFilter(
        props.listContacts,
        false,
        null,
      );
      return {
        filterWait: false,
        filterOn: false,
        contactsList: props.listContacts,
        careteamContactList: careteamContactList,
        otherContactList: otherContactList,
        filter: '',
        obj: null,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return {
      filterWait: state.filterWait,
      contactsList: props.listContacts,
      careteamContactList: state.careteamContactList
        ? state.careteamContactList
        : null,
      otherContactList: state.otherContactList ? state.otherContactList : null,
      obj: null,
      filter:
        filterContacts && filterContacts.searchKeyword
          ? filterContacts.searchKeyword
          : '',
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {filterContacts} = this.props;
    const {filter, searchAttachmentContent} = this.state;
    if (prevState.filter !== filter ||
      prevState.searchAttachmentContent !== searchAttachmentContent) {
      this.handleImageFilter(
        filterContacts.searchKeyword,
        filterContacts.ticked,
      );
    }
  }

  // componentWillUnmount = () => {
  //   if (this.state.filterOn) {
  //     this.handleOnPressClearFilters();
  //   }
  // };

  static dataFilter = (listContacts, type1, type2) => {
    if (listContacts && listContacts.length > 0) {
      const contactList = listContacts.filter(function(contact) {
        return (
          contact.givenName &&
          (contact.isCareTeam === type1 || contact.isCareTeam === type2)
        );
      });

      return contactList.sort(function(a, b) {
        return (a.givenName !== null && b.givenName !== null) && a.givenName.toLowerCase().localeCompare(b.givenName.toLowerCase());
      });
    }
  };

  contactsSelectHandler = id => {
    if (this.props.listContacts) {
      const selectedContact = this.props.listContacts.filter(function(
        contacts,
      ) {
        return contacts.id === id;
      });

      if (selectedContact && selectedContact.length >= 1) {
        this.props.onSelectContact(selectedContact[0], () => {
          this.props.navigation.navigate(
            CONTACTS.viewContactPath, {
              from: this.props.route.name,
            }
          );
        });
      }
    }
  };

  static handleFilter = (filter, props) => {
    const contactUniqueArray = ContactsScreen.filter(filter, props);
    if (contactUniqueArray && contactUniqueArray.length > 0) {
      return {
        filter: filter,
        filterOn: true,
        contactsList: contactUniqueArray,
        careteamContactList: ContactsScreen.dataFilter(
          contactUniqueArray,
          true,
        ),
        otherContactList: ContactsScreen.dataFilter(
          contactUniqueArray,
          false,
          null,
        ),
        obj: null,
        filterWait: false,
      };
    } else {
      return {
        filter: filter,
        filterOn: true,
        contactsList: null,
        careteamContactList: null,
        otherContactList: null,
        obj: null,
        filterWait: false,
      };
    }
  };

  static filter = (filter, props) => {
    const givenNameNotesFilter = props.listContacts.filter(res => {
      return (
        (res.givenName
          ? res.givenName.toLowerCase().includes(filter.toLowerCase())
          : null) ||
        (res.notes
          ? res.notes.toLowerCase().includes(filter.toLowerCase())
          : null) ||
        (res.organization
          ? res.organization.toLowerCase().includes(filter.toLowerCase())
          : null) ||
        (res.specialty
          ? res.specialty.toLowerCase().includes(filter.toLowerCase())
          : null) ||
        (res.primaryPhoneNumber
          ? res.primaryPhoneNumber.toLowerCase().includes(filter.toLowerCase())
          : null) ||
        (res.faxPhoneNumber
          ? res.faxPhoneNumber.toLowerCase().includes(filter.toLowerCase())
          : null) ||
        (res.emailaddress
          ? res.emailaddress.toLowerCase().includes(filter.toLowerCase())
          : null) ||
        (res.physicalAddressLine1
          ? res.physicalAddressLine1
              .toLowerCase()
              .includes(filter.toLowerCase())
          : null) ||
        (res.municipality
          ? res.municipality.toLowerCase().includes(filter.toLowerCase())
          : null) ||
        (res.stateProvince
          ? res.stateProvince.toLowerCase().includes(filter.toLowerCase())
          : null) ||
        (res.postalCode
          ? res.postalCode.toLowerCase().includes(filter.toLowerCase())
          : null) ||
        (res.country
          ? res.country.toLowerCase().includes(filter.toLowerCase())
          : null)
      );
    });
    const questionFilter = props.listContacts.filter(res => {
      const qList = props.questionDigest.userArtifactHealthQuestions.filter(
        re =>
          res.artifactId === re.artifactId &&
          props.questionDigestProcessed.questions[re.questionId] &&
          (props.questionDigestProcessed.questions[re.questionId].content
            .toLowerCase()
            .includes(filter.toLowerCase()) ||
            (props.questionDigestProcessed.questions[re.questionId].answers &&
              props.questionDigestProcessed.questions[re.questionId].answers
                .length > 0 &&
              props.questionDigestProcessed.questions[
                re.questionId
              ].answers.find(answer =>
                answer.content.toLowerCase().includes(filter.toLowerCase()),
              ))),
      );
      return qList && qList.length > 0 ? qList : null;
    });

    const contactUniqueArray = Array.from(
      new Set([...givenNameNotesFilter, ...questionFilter]),
    );

    return contactUniqueArray;
  };

  handleImageFilter = (filter, ticked) => {
    this.setState({filterWait: true, filterKey: filter}, () => {
      const {attachmentsCompleteDigest} = this.props;
      if (ticked && filter) {
        let userId = this.props.user.id;

        if (this.props.journalUserId) {
          userId = this.props.journalUserId;
        }
        this.searchTextMap[filter] = filter;
        this.props.searchAttachment(
          {
            attachmentCategory: 'Contact',
            searchKey: filter,
            userId: userId,
          },
          (sucess, data) => {
            delete this.searchTextMap[filter];
            if (sucess && _.isEmpty(this.searchTextMap)) {
              let art = [];
              let temp = [];
              if (
                filter &&
                attachmentsCompleteDigest &&
                attachmentsCompleteDigest.attachments &&
                attachmentsCompleteDigest.attachments.length > 0
              ) {
                attachmentsCompleteDigest.attachments.forEach(element => {
                  return element.filename
                    .toLowerCase()
                    .includes(filter.toLowerCase())
                    ? art.push(element)
                    : null;
                });
              }

              if (
                art &&
                art.length > 0 &&
                attachmentsCompleteDigest.userArtifactAttachments &&
                attachmentsCompleteDigest.userArtifactAttachments.length > 0
              ) {
                art.forEach(re => {
                  attachmentsCompleteDigest.userArtifactAttachments.forEach(
                    res =>
                      re.id === res.attachment
                        ? temp.push({...re, instanceId: res.userArtifact})
                        : null,
                  );
                });
              }

              const obj = {};
              data.forEach(element => {
                let exatt = element.attachments.concat(temp);
                exatt.forEach(item => {
                  if (!obj[item.instanceId]) {
                    obj[item.instanceId] = [item];
                  } else {
                    obj[item.instanceId].push(item);
                  }
                });
              });
              let attList = [];
              let attachmentFilter = this.props.listContacts.filter(att => {
                if (obj[att.artifactId] && obj[att.artifactId].length > 0) {
                  obj[att.artifactId].forEach(rr => {
                    attList.push(rr);
                  });
                }
                return obj[att.artifactId];
              });
              const contactArray = ContactsScreen.filter(filter, this.props);
              const contactUniqueArray = Array.from(
                new Set([...contactArray, ...attachmentFilter]),
              );

              if (contactUniqueArray && contactUniqueArray.length > 0) {
                let contactsList = null;
                let careteamContactList = null;
                let otherContactList = null;
                let objAttachment = null;
                if (this.state.filter) {
                  contactsList = contactUniqueArray;
                  careteamContactList = ContactsScreen.dataFilter(
                    contactUniqueArray,
                    true,
                  );
                  otherContactList = ContactsScreen.dataFilter(
                    contactUniqueArray,
                    false,
                    null,
                  );
                  objAttachment = obj;

                  this.setState({
                    filter: filter,
                    filterOn: true,
                    contactsList: contactsList,
                    careteamContactList: careteamContactList,
                    otherContactList: otherContactList,
                    obj: objAttachment,
                    filterWait: !_.isEmpty(this.searchTextMap),
                    imageSerachFinish: true,
                  });
                }
              } else {
                this.setState({
                  filter: filter,
                  contactsList: null,
                  careteamContactList: null,
                  otherContactList: null,
                  obj: null,
                  filterWait: !_.isEmpty(this.searchTextMap),
                  imageSerachFinish: true,
                  filterOn: true,
                });
              }
            } else if (_.isEmpty(this.searchTextMap)) {
              this.setState({
                filterWait: !_.isEmpty(this.searchTextMap),
                filter: filter,
                contactsList: null,
                careteamContactList: null,
                otherContactList: null,
                obj: null,
                imageSerachFinish: true,
                filterOn: true,
              });
            }
          },
        );
      } else {
        this.setState({
          filterWait: false,
          filter: filter,
          contactsList: this.props.listContacts,
          careteamContactList: ContactsScreen.dataFilter(
            this.props.listContacts,
            true,
          ),
          otherContactList: ContactsScreen.dataFilter(
            this.props.listContacts,
            false,
            null,
          ),
          obj: null,
          imageSerachFinish: false,
          filterOn: false,
        });
      }
    });
  };

  searchContact = val => {
    this.setState({filterKey: val});
    const {setContactFilter, filterContacts} = this.props;

    setContactFilter(
      val,
      filterContacts && filterContacts.ticked ? filterContacts.ticked : false,
    );
  };
  onTicked = val => {
    const {setContactFilter, filterContacts} = this.props;

    if (val) {
      pendoTrackEvent('Contacts@ > SearchImages');
    }
    this.setState({
      searchAttachmentContent: val
    });
    setContactFilter(
      filterContacts && filterContacts.searchKeyword
        ? filterContacts.searchKeyword
        : '',
      val,
    );
  };

  handleOnPressClearFilters = () => {
    this.props.clearContactFilter();
  };

  onPressClose = () => {
    this.setState({visibleEntryModal: false, selected: null});
  };

  onPressManuallyEntry = () => {
    this.setState({
      visibleEntryModal: false,
      buttonStatus: true,
    });
    this.createContact();
  };

  onPressUploadEntry = () => {
    this.setState(
      {
        visibleEntryModal: false,
      },
      () => {
        setTimeout(() => {
          this.props.navigation.push(
            CONTACTS.contactTakePhotoPath, {
              type: 'upolad',
              fromPopup: 'CONTACT',
            },
          );
        }, 1000);
      },
    );
  };

  onPressTakePhoto = () => {
    this.props.setFromPopup(null);
    this.setState({buttonStatus: true});
    if (Platform.OS === IOS) {
      setTimeout(() => {
        this.setState(
          {
            visibleEntryModal: false,
          },
          () => {
            setTimeout(() => {
              this.props.navigation.push(
                CONTACTS.contactTakePhotoPath, {
                  type: 'takePhoto',
                  fromPopup: 'CONTACT',
                },
              );
              this.setState({buttonStatus: false});
            }, 1000);
          },
        );
      }, 300);
    } else {
      this.setState(
        {
          visibleEntryModal: false,
        },
        () => {
          setTimeout(() => {
            this.props.navigation.push(
              CONTACTS.contactTakePhotoPath, {
                type: 'takePhoto',
                fromPopup: 'CONTACT',
              },
            );
            this.setState({buttonStatus: false});
          }, 1000);
        },
      );
    }
  };

  onChange = e => {
    if (e && e.target.files[0]) {
      let name = e.target.files[0].name
        .split('.')
        .pop()
        .toLowerCase();
      if (
        name === 'png' ||
        name === 'jpg' ||
        name === 'jpeg' ||
        name === 'svg' ||
        name === 'jfif' ||
        name === 'gif'
      ) {
        let imagePath = window.URL.createObjectURL(e.target.files[0]);
        this.setState({visibleEntryModal: false});
        this.props.setFromPopup(null);
        this.props.navigation.push(
          CONTACTS.contactTakePhotoPath, {
            inputResult: imagePath,
            fromPopup: 'CONTACT',
          },
        );
      } else {
        this.setState({visibleWarning: true});
        setTimeout(() => {
          this.setState({visibleWarning: false});
        }, 5000);
      }
    }
  };

  onPressUploadEntry = () => {
    this.props.setFromPopup(null);
    this.setState(
      {
        visibleEntryModal: false,
      },
      () => {
        setTimeout(() => {
          this.props.navigation.push(
            CONTACTS.contactTakePhotoPath, {
              type: 'upolad',
              fromPopup: 'CONTACT',
            },
          );
        }, 1000);
      },
    );
  };

  createContact = () => {
    let addNewContact = {};
    this.setState({createWait: true});
    this.props.addContact(
      addNewContact,
      this.props.contacts,
      res => {
        if (res) {
          this.props.setFromPopup(null);
          this.props.navigation.push(
            CONTACTS.addContactPath, {
              fromPopup: 'CONTACT',
            },
          );
        }
        this.setState({createWait: false, buttonStatus: false});
      },
      this.props.journalUserId,
    );
  };
  redirectPremiumAccount = () => {
    this.setState({
      visibleEntryModal: false,
      buttonStatus: true,
    });
    this.props.setFromPopup(null);
    this.props.navigation.push(
      PROFILE.profilePremiumAccountPath, {
        fromPopup: 'CONTACT',
      },
    );
    setTimeout(() => {
      this.setState({buttonStatus: false});
    }, 100);
  };

  renderContactHighlightTextContent = (textToHighlight, searchWords, style={}) => {
    return (
      (textToHighlight !== null && textToHighlight !== undefined) &&
      <Highlighter
        searchWords={searchWords}
        textToHighlight={textToHighlight}
        style={!_.isEmpty(style) && style}
      />
    );
  }

  notesFilterResults = (contactArray, index) => {
    return contactArray.notes && this.state.filter ? (
      contactArray.notes
        .toLowerCase()
        .includes(this.state.filter.toLowerCase()) ? (
        <View style={[styles.viewMoreDataSingleSub]} key={index}>
          <View style={[styles.resultTitle]}>
            <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>Notes</Text>
          </View>
          <View style={styles.viewMorePdLg}>
            <View style={[styles.viewMoreDataItemSub]}>
              {this.renderContactHighlightTextContent(contactArray.notes, this.state.filter)}
            </View>
          </View>
        </View>
      ) : null
    ) : null;
  };

  questionsFilterResults = (contactArray, index) => {
    let questionList = [];
    let contList = [];
    const questionArtifact = this.props.questionDigest.userArtifactHealthQuestions.filter(
      re => {
        return re.artifactId === contactArray.artifactId;
      },
    );
    if (questionArtifact && questionArtifact.length > 0) {
      questionArtifact.forEach(qid => {
        questionList.push(
          this.props.questionDigestProcessed.questions[qid.questionId],
        );
      });
    }

    if (questionList && this.state.filter) {
      contList = questionList.filter(qcon => {
        return qcon.content
          .toLowerCase()
          .includes(this.state.filter.toLowerCase());
      });
      if (contList && contList.length > 0) {
        return (
          <View style={[styles.viewMoreDataSingleSub]}>
            <View style={[styles.resultTitle]}>
              <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>Questions</Text>
            </View>
            {contList.map((res, x) => {
              return (
                <View key={x} style={styles.viewMorePdLg}>
                  <View style={[styles.viewMoreDataItemSub]}>
                    {this.renderContactHighlightTextContent(res.content, this.state.filter)}
                  </View>
                </View>
              );
            })}
          </View>
        );
      }
    }
  };

  attachemntFilterResults = (contactArray, index) => {
    let attList = [];
    if (
      this.state.obj &&
      this.state.obj[contactArray.artifactId] &&
      this.state.obj[contactArray.artifactId].length > 0
    ) {
      this.state.obj[contactArray.artifactId].forEach(rr => {
        attList.push(rr);
      });
    }
    attList = [...new Set(attList)];
    if (attList && attList.length > 0) {
      return (
        <View style={[styles.viewMoreDataSingleSub]}>
          <View style={[styles.resultTitle]}>
            <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>Attachments</Text>
          </View>

          {attList.map((att, y) => {
            return (
              <View
                key={y}
                style={styles.viewMorePdLg}>
                <View style={[styles.resultSubTitle]}>
                  <View style={[styles.resultTitleWrapper]}>
                    {attList.length !== 2 && (
                      this.renderContactHighlightTextContent(att.filename, this.state.filterKey, styles.textPrimeSm)    
                    )}

                    {att.searchWordContainingSentences &&
                      att.instanceId === contactArray.artifactId && (
                        <View style={[styles.resultSubTitle]}>
                          <View style={[styles.resultTitleWrapper]}>
                            {this.renderContactHighlightTextContent(att.fileName, this.state.filterKey, styles.textPrimeSm)}
                          </View>
                        </View>
                    )}
                    {(attList.length !== 2 || 
                      (att.searchWordContainingSentences &&
                        att.instanceId === contactArray.artifactId
                      )) &&
                      <View
                        style={[
                          styles.resultDateWrapper,
                        ]}>
                        <Text
                          style={[styles.textPrimeSm, styles.textAlignLeft]} allowFontScaling={false}>
                          {att.uploadDate !== null &&
                            moment(att.uploadDate).format("DD MMM 'YY")}
                        </Text>
                      </View>
                    }
                  </View>
                </View>

                {att.searchWordContainingSentences &&
                att.instanceId === contactArray.artifactId
                  ? att.searchWordContainingSentences.map((re, x) => {
                      return (
                        <View key={x} style={[styles.viewMoreDataItemSub, styles.viewMoreDataTopMg]}>
                          {this.renderContactHighlightTextContent(re, this.state.filter)}
                        </View>
                      );
                    })
                  : null}
              </View>
            );
          })}
        </View>
      );
    }
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {filterContacts, windowWidth} = this.props;
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          // handleFilter={this.handleFilter}
          // clearFiltersHandler={this.handleOnPressClearFilters}
          type="contact"
          handleOnPressBackButton={() => {
            if (this.state.filterOn) {
              this.handleOnPressClearFilters();
            } else if (
              this.props.route?.params &&
              this.props.route?.params.from
            ) {
              this.props.route?.params.from ===
                CONTACTS.viewContactPath ||
              this.props.route?.params.from ===
                CONTACTS.addContactPath ||
              this.props.route?.params.from ===
                CONTACTS.editContactPath
                ? this.props.navigation.navigate(DASHBOARD.path)
                : handleCommonBackNavigation(this.props.navigation);
            } else {
              handleCommonBackNavigation(this.props.navigation);
            }
          }}
          index={CONTACTS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />

        <ScrollView style={styles.scrollViewArea}>
          <View style={styles.gridSection}>
            <PageContainer>
              <View style={[styles.gridRow, styles.colPdBotSm]}>
                <View style={styles.gridColFull}>
                  <View style={[styles.searchSection]}>
                      {/* button */}
                      <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt]}>
                        <View>
                          <SectionTitle 
                            smallTextTilte={true}
                            title={'Contacts & Careteam'}
                          />
                        </View>
                        <View style={[stylesRes.newConnectionBtnCr, styles.btnMgMd]} dataSet={{media: ids.newConnectionBtnCr}}>
                          <PlusButtonDark
                            touchableOnPress={() => {
                              this.setState({visibleEntryModal: true});
                              pendoTrackEvent('Contacts@ > Add');
                            }}
                            btnPosition={'full'}
                            btnText={'New Contact'}
                            plusIconSm={true}
                            btnShadowNone={true}
                            nativeId={'contactsAdd'}
                            newConnection={true}
                          />
                        </View>
                      </View>
                      {/* search bar */}
                      <View style={styles.fieldColSearch}>
                        <SearchField
                            searchText={
                              filterContacts && filterContacts.searchKeyword
                                ? filterContacts.searchKeyword
                                : ''
                            }
                            placeholder="Search existing contact & careteam"
                            onChangeText={this.searchContact}
                            showClearBtton={
                              filterContacts &&
                              filterContacts.searchKeyword &&
                              filterContacts.searchKeyword !== ''
                                ? true
                                : false
                            }
                            clearFilter={this.handleOnPressClearFilters}
                            nativeId={'contactsSearch'}
                            eventName={'Contacts@ > Search'}
                          />
                      </View>
                  </View>
                  <View style={[styles.searchDocSection]}>
                    <ImageDocSearch
                      ticked={
                        this.state.accountType === 'premium' &&
                        filterContacts &&
                        filterContacts.ticked
                          ? filterContacts.ticked
                          : false
                      }
                      onTicked={this.onTicked}
                      accountType={this.state.accountType}
                      navigation={this.props.navigation}
                      journalUserId={this.props.journalUserId}
                      windowWidth={windowWidth}
                      nativeId={'contactsSearchImages'}
                    />
                  </View>

                  {_.isEmpty(this.state.careteamContactList) &&
                    _.isEmpty(this.state.otherContactList) &&
                    !this.state.filterWait &&
                    this.state.filterOn &&
                    !this.state.createWait && <NoResultFound />}
                </View>
              </View>
            </PageContainer>

            {this.state.filterWait || this.state.createWait ? (
              <ActivityIndicator pdLarge={true} />
            ) : (
              <PageContainer smScreenFull={true}>
                <View style={[stylesRes.contentSection, stylesRes.contBotPd]} dataSet={{media: ids.contBotPd}}>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      {this.state.careteamContactList &&
                        this.state.careteamContactList.length > 0 && (
                          <View style={stylesRes.contentSingleBlock}>
                            <View style={stylesRes.colFull}>
                              <View>
                                <View style={stylesRes.contentTopicSection}>
                                  <View style={styles.medListFullSec}>
                                    <View
                                      style={[
                                        styles.medListSingleBoxLg,
                                        styles.colBg,
                                      ]}>
                                      <View
                                        style={[
                                          styles.titleBlockCom,
                                          styles.gridPdAllTopBotSm,
                                        ]}>
                                        <View style={styles.titleTextBlock}>
                                          <Text
                                            style={[
                                              styles.textPrimeBold,
                                              styles.textColorLight,
                                            ]}
                                            allowFontScaling={false}
                                            >
                                            Care Team
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                                <View style={stylesRes.contentDetailSection}>
                                  <View style={stylesRes.contList}>
                                    {this.state.careteamContactList.map(
                                      (res, index) => (
                                        <React.Fragment key={index}>
                                          <ContactsListItem
                                            key={'care_team_' + index}
                                            contacts={res}
                                            onPressContactList={
                                              this.contactsSelectHandler
                                            }
                                          />
                                          {this.notesFilterResults(
                                            res,
                                            index,
                                          ) ||
                                          this.questionsFilterResults(
                                            res,
                                            index,
                                          ) ||
                                          this.attachemntFilterResults(
                                            res,
                                            index,
                                          ) ? (
                                            <TouchableOpacity
                                              onPress={() => {
                                                this.contactsSelectHandler(
                                                  res.id,
                                                );
                                              }}>
                                              <View
                                                style={[
                                                  styles.viewMoreSectionCom,
                                                ]}>
                                                {this.notesFilterResults(
                                                  res,
                                                  index,
                                                )}
                                                {this.questionsFilterResults(
                                                  res,
                                                  index,
                                                )}
                                                {this.attachemntFilterResults(
                                                  res,
                                                  index,
                                                )}
                                              </View>
                                            </TouchableOpacity>
                                          ) : null}
                                        </React.Fragment>
                                      ),
                                    )}
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        )}

                        {Boolean(this.state.otherContactList) &&
                        this.state.otherContactList.length > 0 && (
                          <View style={stylesRes.contentSingleBlock}>
                            <View style={stylesRes.colFull}>
                              <View>
                                <View style={stylesRes.contentTopicSection}>
                                  <View style={styles.medListFullSec}>
                                    <View
                                      style={[
                                        styles.medListSingleBoxLg,
                                        styles.colBg,
                                      ]}>
                                      <View
                                        style={[
                                          styles.titleBlockCom,
                                          styles.gridPdAllTopBotSm,
                                        ]}>
                                        <View style={styles.titleTextBlock}>
                                          <Text
                                            style={[
                                              styles.textPrimeBold,
                                              styles.textColorLight,
                                            ]}
                                            allowFontScaling={false}
                                            >
                                            Other
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                                <View style={stylesRes.contentDetailSection}>
                                  <View style={stylesRes.contList}>
                                    {this.state.otherContactList.map(
                                      (res, index) => (
                                        <React.Fragment key={index}>
                                          <ContactsListItem
                                            key={'other_' + index}
                                            contacts={res}
                                            onPressContactList={
                                              this.contactsSelectHandler
                                            }
                                          />
                                          {this.notesFilterResults(
                                            res,
                                            index,
                                          ) ||
                                          this.questionsFilterResults(
                                            res,
                                            index,
                                          ) ||
                                          this.attachemntFilterResults(
                                            res,
                                            index,
                                          ) ? (
                                            <TouchableOpacity
                                              onPress={() => {
                                                this.contactsSelectHandler(
                                                  res.id,
                                                );
                                              }}>
                                              <View
                                                style={[
                                                  styles.viewMoreSectionCom,
                                                ]}>
                                                {this.notesFilterResults(
                                                  res,
                                                  index,
                                                )}
                                                {this.questionsFilterResults(
                                                  res,
                                                  index,
                                                )}
                                                {this.attachemntFilterResults(
                                                  res,
                                                  index,
                                                )}
                                              </View>
                                            </TouchableOpacity>
                                          ) : null}
                                        </React.Fragment>
                                      ),
                                    )}
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        )}
                    </View>
                  </View>
                </View>
              </PageContainer>
            )}
          </View>
        </ScrollView>

        <Modal
          visible={this.state.visibleEntryModal}
          onRequestClose={() => {
            this.setState({visibleEntryModal: false, selected: null});
          }}
          onBackdropPress={() =>
            this.setState({visibleEntryModal: false, selected: null})
          }>
          {Platform.OS === WEB ? (
            <AddMethodforEntry
              onPressClose={this.onPressClose}
              onPressManuallyEntry={this.onPressManuallyEntry}
              onPressUploadEntry={this.onPressUploadEntry}
              onPressTakePhoto={this.onPressTakePhoto}
              onChange={e => this.onChange(e)}
              accountType={this.state.accountType}
              redirectPremiumAccount={this.redirectPremiumAccount}
              navigation={this.props.navigation}
              fromPopup="CONTACT"
              journalUserId={this.props.journalUserId}
            />
          ) : (
            <AddMethodforEntry
              onPressClose={this.onPressClose}
              onPressManuallyEntry={this.onPressManuallyEntry}
              onChange={this.onPressUploadEntry}
              onPressTakePhoto={this.onPressTakePhoto}
              accountType={this.state.accountType}
              redirectPremiumAccount={this.redirectPremiumAccount}
              navigation={this.props.navigation}
              fromPopup="CONTACT"
              journalUserId={this.props.journalUserId}
              buttonStatus={this.state.buttonStatus}
            />
          )}
        </Modal>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    listContacts: state.routines.contacts,
    user: state.routines.user,
    questionDigest: state.routines.questionDigest,
    questionDigestProcessed: state.routines.questionDigestProcessed,
    filterContacts: state.filter.contactFilter,
    userSubscriptions: state.routines.userSubscription,
    journalUserId: state.uistate.journalUserId,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSelectContact: (selectedContact, callBack) =>
      dispatch(actions.selectedContact(selectedContact, callBack)),
    setContactFilter: (filter, ticked) =>
      dispatch(actions.setContactFilter(filter, ticked)),
    clearContactFilter: () => dispatch(actions.clearContactFilter()),
    searchAttachment: (formData, callBack) =>
      dispatch(actions.searchAttachment(formData, callBack)),
    addContact: (addNewContact, contacts, callBack, journalUserId) =>
      dispatch(
        actions.createContact(addNewContact, contacts, callBack, journalUserId),
      ),
    setFromPopup: fromPopup => dispatch(actions.setFromPopup(fromPopup)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactsScreen);
