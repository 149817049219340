import React from 'react';
import {Text} from 'react-native';

const parsedText = props => {
    return (
        <Text style={props.style} allowFontScaling={false}>
          {props.text}
        </Text>
    );
}
export default parsedText;