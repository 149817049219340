import theme from "./base/theme";
import { Platform } from "react-native";
import * as variableShadow from "../css/base/variableShadow";
export const cssMobApp = {
  mobAppWrapper: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: theme.COLOR_WHITE
  },
  mobAppMenu: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    left: 0,
    right: 0,
    zIndex: 99
  },
  mobAppContainer: {
    flex: 1
  },
  mobileAddMenuItems: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "100%",
    left: 0,
    right: 0,
    zIndex: 9
  },
  mobAppMenuAdd: {
    position: "absolute",
    bottom: 26,
    zIndex: 999999,
    alignItems: "center",
    justifyContent: "center",
    width: 70,
    height: 70
  },
  mobAppMenuWrapper: {
    alignItems: "center",
    justifyContent: "center",
    ...Platform.select({
      ios: {
        zIndex: 999
      }
    })
  },
  addButtonApp: {
    width: 70,
    height: 70,
    zIndex: 9999999
  },
  mobAppMenuView: {
    width: "100%",
    minHeight: 70,
    zIndex: 999,
    backgroundColor: theme.COLOR_WHITE,
  },
  menuIndShadow: {
    ...variableShadow.menuShadow,
    borderTopWidth: 1,
    borderColor: theme.COLOR_BLACK_1
  },
  notchView: {
    position: "absolute",
    left: 0,
    right: 0,
    backgroundColor: theme.COLOR_WHITE,
    width: "100%",
    height: 50,
    bottom: -50,
    zIndex: 999
  }
};
