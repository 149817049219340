import { Platform } from "react-native";
import theme from "./base/theme";
import * as variableShadow from "./base/variableShadow";
export const cssRoutine = {
  tabVerticalTitleWrapper: {
    maxWidth: 320,
    flexBasis: 320,
    zIndex: 99,
  },
  tabCollpaseTitle: {
    paddingLeft: 15,
    paddingRight: 5,
    paddingTop: 15,
    paddingBottom: 15,
    flexDirection: "row",
  },
  tabCollpaseBorder: {
    borderBottomWidth: 1,
    borderBottomColor: theme.COLOR_GREY_LIGHT_RG,
  },
  tabCollpaseBorderNone: {
    borderBottomWidth: 1,
    borderBottomColor: theme.COLOR_TRANSPARENT,
  },
  tabVerticalWrapper: {
    flexDirection: "row",
  },
  collapseBoxWrapper: {
    borderWidth: 1,
    borderColor: theme.COLOR_GREY_LIGHT_RG,
    borderRadius: 8,
    ...variableShadow.itemShadow,
  },
  collapseAllClose: {
    paddingTop: 15,
    paddingRight: 15,
    paddingBottom: 15,
  },
  collapseAllTitle: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    paddingRight: 5,
  },
  collapseBoxTitleWrapper: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: theme.COLOR_GREY_LIGHT_RG,
  },
  collapseBoxTitle: {
    flex: 1,
  },
  collapseBoxClose: {
    flexBasis: 70,
    maxWidth: 70,
    alignItems: "flex-end",
  },
  collapseContArea: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 15,
    flex: 1,
  },
  collapseContVerical: {
    borderLeftWidth: 1,
    borderLeftColor: theme.COLOR_GREY_LIGHT_RG,
  },
  collapseContHorizontal: {
    borderBottomWidth: 1,
    borderBottomColor: theme.COLOR_GREY_LIGHT_RG,
  },
  collapseContRow: {
    flexDirection: "row",
    flex: 1,
  },
  collapseContLg: {
    flex: 1,
  },
  collapseContLgPd: {
    paddingLeft: 10,
  },
  collapseContNoPd: {
    paddingLeft: 0,
  },
  collapseContSm: {
    paddingLeft: 10,
    paddingRight: 10,
    maxWidth: 140,
    flexBasis: 140,
  },
  collapseContTitle: {
    paddingBottom: 10,
  },
  collapseImgPd: {
    paddingTop: 10,
  },
  collapseImg: {
    alignItems: "center",
  },
  collapseArrow: {
    position: "absolute",
    right: -13,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  tabHorizontalWrapper: {
    flexDirection: "column",
  },
  tabCollapseContentBlock: {
    flex: 1,
    zIndex: 9,
  },
  collapseContView: {
    minHeight: 70,
  },
  titleSecBlock: {
      flex: 1,
      flexDirection: 'row'
  },
  titleSecMain: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center'
  },
  titleSecSub: {
      maxWidth: 60,
      flexBasis: 60,
  },
  graphViewWrapper: {
    width: "100%",
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 15,
    borderWidth: 1,
    borderColor: theme.COLOR_GREY_LIGHT_RG,
    borderRadius: 8,
    zIndex: 9,
    ...variableShadow.itemShadow,
  },
  titleSecFull: {
    flex: 1,
  },
  btnSubSection: {
    flex: 1,
  },
  modalFixedSection: {
    width: '100%',
  },
  btnFixedArea: {
    width: '100%',
  },
  contentModalWrapper: {
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5,
    zIndex: 999,
  },
  modalMainSection: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 15,
  },
  prevArrowThick: {
    width: 18,
    height: 14,
  },
  nextArrowThick: {
    width: 18,
    height: 14,
  },
  btnSectionLink: {
    flex: 1,
  },
  itemsContainerCol: {
    flex: 1,
  },
  containerBlue: {
    backgroundColor: theme.COLOR_THIN_BLUE,
  },
  containerWhite: {
    backgroundColor: theme.COLOR_WHITE,
  },
  containerPinkMd: {
    backgroundColor: theme.COLOR_THIN_PINK_2,
  },
  itemsContainer: {
    borderRadius: 8,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    minHeight: 54,
    alignItems: 'center',
    flexDirection: 'row',
    ...variableShadow.itemShadow,
  },
  itemsContainerSm: {
    borderRadius: 8,
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 2,
    minHeight: 54,
    alignItems: 'center',
    flexDirection: 'row',
    ...variableShadow.itemShadow,
  },
  itemsContainerRow: {
    flexDirection: 'row'
  },
  itemIcon: {
    width: 15,
    height: 15,
  },
  itemIconBox: {
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
  },
  itemRecordBox: {
    width: 30,
    height: 30,
  },
  itemTextBox: {
    flex: 1,
    paddingLeft: 10,
  },
  itemRowMg: {
    marginBottom: 15,
  },
  itemButton: {
    flexBasis: 45,
    maxWidth: 45,
  },
  itemRecordIcon: {
    width: 30,
    height: 30,
  },
  titleBorderBox: {
    flex: 1,
    paddingLeft: 10,
  },
  titleBorder: {
    borderTopWidth: 1,
    borderTopColor: theme.COLOR_GREY_LIGHT_RG,
  },
  titleSecSm: {
    maxWidth: '100%'
  },
  itemIconDefault: {
    width: 30,
    height: 30,
    backgroundColor: theme.LIGHT_BG,
  },
  itemBoxBar: {
    width: 170,
    height: 12,
    backgroundColor: theme.LIGHT_BG,
  },
  spaceBox: {
    width: '100%',
    height: 100,
    ...Platform.select({
      ios: {
        height: 70,
      }
    })
  },
  graphSelectionHeight: {
    maxHeight: 205,
    borderWidth: 1,
    borderColor: theme.COLOR_GREY_LIGHT_RG,
    borderTopWidth: 0,
  },
  dropDownOptionPdLt: {
    paddingLeft: 15,
  },
  itemsContainerList: {
    maxHeight: 187,
  },
  modalLargeWrapper: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 600,
    width: '100%',
  },
  scrollViewModal: {
    width: '100%', 
    height: '100%', 
    // backgroundColor: theme.COLOR_GREY_DARK_4
  },
  scrollViewMax: {
    flexGrow: 1
  },
  radioOptSpSm: {
    marginRight: 5,
    marginTop: 2
  },
  messageBoxWrapper: {
    borderColor: theme.COLOR_YELLOW_1,
    borderWidth: 1,
    borderRadius: 4,
    paddingTop: 3,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 3,
    marginBottom: 15,
  },
  iconWarningSm: {
    width: 12,
    height: 12,
  },
  iconMessage: {
    maxWidth: 18,
    flexBasis: 18,
    paddingTop: 2,
  },
  messageTitleSm: {
    paddingBottom: 2,
  },
  addedItemBox: {
    borderColor: theme.COLOR_GREY_LIGHT_RG,
    borderWidth: 1,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    borderRadius: 5,
  },
  listItemRemove: {
    maxWidth: 30,
    flexBasis: 30,
    alignItems: 'flex-end'
  },
  addIcon: {
    width: 50,
    height: 50
  },
  addDefaultIcon: {
    paddingBottom:10,
  },
  addDefaultBox: {
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20
  },
  stepfourmetricSz: {
    maxWidth: 110,
    flexBasis: 110
  },
  fieldColDynamicMax: {
    flex: 1,
    maxWidth: 240,
  },
  popUpBoxSm: {
    minHeight: 200,
  },
  closeBtnView: {
    position: "absolute",
    right: 5,
    top: 5,
    zIndex: 999,
  },
  closeBtnTouch: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  indicatorLtDefault: {
    left: 0
  },
  indicatorLtDefaultSm: {
    left: -5
  },
  contSm: {
    flex: 1,
    justifyContent: 'center'
  },
  navListLiTitle: {
    paddingLeft: 10,
  },
  switchMenuPd: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
  },
  btnFooterRow: {
    flexDirection: 'row',
  },
  btnViewBorder: {
    borderTopWidth: 1,
    borderTopColor: theme.COLOR_GREY_LIGHT_RG,
    width: '100%',
    marginBottom: 10,
    marginTop: 15,
  },
  indLoaderSm: {
    left: -7,
  },
  btnTabSingleBoxLg: {
    flex: 1,
  },
  btnTabSingleBoxSm: {
    flex: 0.6,
  },
  itemBoxTitle: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    borderWidth: 1,
    borderColor: theme.COLOR_BORDER,
    borderBottomWidth: 0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  listIconView: {
    width: 30,
    height: 30,
    borderRadius: 30,
    backgroundColor: theme.COLOR_GREY_LIGHT_RG,
    marginRight: 10,
  },
  listIconMed: {
    position: 'absolute',
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  secBtnWrapper: {
    width: '100%',
    marginLeft: 'auto',
    maxWidth: 250,
    flexDirection: 'row',
  },
  boxBorderLtRt: {
    borderColor: theme.COLOR_BORDER,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    paddingLeft: 10,
    paddingRight: 10,
  },
  boxBorderBot: {
    borderColor: theme.COLOR_BORDER,
    borderTopWidth: 0,
    borderBottomWidth:1,
    borderLeftWidth: 1,
    borderRightWidth:1,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  collapseBorderLtRt: {
    borderColor: theme.COLOR_BORDER,
    borderTopWidth: 0,
    borderBottomWidth:0,
    borderLeftWidth: 1,
    borderRightWidth:1,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  secDividerBox: {
    borderColor: theme.COLOR_BORDER,
    borderTopWidth: 0,
    borderBottomWidth:1,
    borderLeftWidth: 0,
    borderRightWidth:0,
    paddingBottom: 15,
  },

  fieldColSpBorderRt: {
    width: 9,
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: theme.COLOR_BORDER,
  },
  fieldColSpBorderLt: {
    width: 9,
    borderWidth: 0,
    borderLeftWidth: 1,
    borderColor: theme.COLOR_BORDER,
  },
  noBorderLt: {
    borderWidth: 0,
    borderLeftWidth: 0,
  },
  noBorderRt: {
    borderWidth: 0,
    borderRightWidth: 0,
  },
  fieldRowPdCom: {
    paddingTop: 5,
    paddingBottom: 15,
  },
  headerLeftWrapper: {
    flex: 1,
    alignItems: 'flex-start'
  },
  headerRightWrapper: {
    flex: 1,
    alignItems: 'flex-end'
  },
  arrowSecPdRt: {
    paddingLeft: 5,
  },
  arrowSecPdLt: {
    paddingRight: 5,
  },
  statusIconSelectCom: {
    flexBasis: 20,
    maxWidth: 20,
  },
  itemTextBoxRtSp: {
    paddingRight: 20,
    marginRight: 2,
  },
  actIndIconFixed: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    flex: 1,
  },
  breadCrumbMgLt: {
    marginLeft: 10,
  },
  breadCrumbTint: {
    tintColor: theme.PRIMARY_FONT_COLOR
  },
  favStarBtn: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  favSectionSm: {
    flexBasis: 32,
    maxWidth: 32,
  },
  favSectionLg: {
    flexBasis: 40,
    maxWidth: 40,
  },
  noteDisplayArea: {
    backgroundColor: theme.COLOR_WHITE,
    paddingBottom: 15,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    ...variableShadow.noteBoxShadow,
    minHeight: 200,
  },
  validationResultBox: {
    paddingTop: 3,
  },
  textPdRt: {
    paddingRight: 2,
  },
  autoSuggestSp: {
    minHeight: 200,
  },
  addLinkMinHeight: {
    minHeight: 18,
  },
  acceptBtnTouchView: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  headerCentetBtnWrapper: {
    maxWidth: 205,
    flexBasis: 205,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuListLast: {
    minHeight: 30,
    paddingLeft: 8,
    alignItems: 'center',
  },
  indLoaderLg: {
    left: -25,
  },
  answerSingleSpace: {
    width: 20,
  },
  closeDarkCircleCom: {
    width: 35,
    height: 35,
  },
  popUpContentComPdAll: {
    paddingTop: 50,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  attachWrapper: {
    minHeight: 180,
  },
  attachmentIndicatorCom: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    minHeight: 100,
    width: "100%"
  },
  attachmentIndicatorCenter: {
    alignItems: 'center',
  },
  btnBorderThin: {
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  attachWrapperPd: {
    paddingBottom: 80,
  },
  menuSingleNoteBlock: {
    paddingLeft: 20
  },
  noResultContSec: {
    paddingLeft: 5,
    paddingRight: 5
  },
  indLoaderLgLtSpec: {
    left: -19,
  },
  indLoaderLgRtSpec: {
    right: -19,
  },
  autoSuggestSpace: {
    minHeight: 100,
  },
  routineBorderLt:{
    borderLeftWidth:1, 
    borderColor:theme.COLOR_GREY_LIGHT_RG, 
    marginTop:5, 
    height:'110%', 
  },
  routineBorderTp: {
    borderTopWidth:1, 
    borderColor:theme.COLOR_GREY_LIGHT_RG,
  },
  routineBorderBtm: {
    borderTopWidth:1, 
    borderColor:theme.COLOR_GREY_LIGHT_RG,
    marginTop:5,
  },
  routineBorderRt: {
    borderRightWidth:1, 
    borderColor:theme.COLOR_GREY_LIGHT_RG, 
    marginTop:5, 
    height:'110%', 
  },
  routingmgCloseIcn: {
    marginTop:20, 
    marginRight:10
  },
  routineListPickerMg: {
    paddingTop:5,
    marginTop:5, 
    marginRight:10,
  },
  routingMetricNmMg: {
    marginTop:25, 
    marginLeft:10
  }, 
  stepFourMetricWrapper: {
    flexDirection:'row', 
    position:'relative'
  },
  graphWrapperRt: {
    marginRight:'auto'
  },
  
};
