import React from "react";
import { Text, View, Image } from "react-native";
import styles from "../../../Css";
import Modal from "../../Modal/modal";
import ModalLayout from '../../ModalLayout/modalLayout';


const successMessagePopup = props => {
    const {
        visibleModal,
        hidePopup,
        messageTitle,
        message,
        selectedUser
    } = props;
    return (
        <Modal
            visible={visibleModal}
            onBackdropPress={() => hidePopup()}
        >
            <ModalLayout
                contPosition={"center"}
                showClose={false}
                hidePopup={() => hidePopup()}
            >
                <View style={styles.popupContentWrapper}>
                    <View style={[styles.popMessageContView, styles.comSp]}>
                        <View style={[styles.flexJcCtAiCt, styles.comSpLg]}>
                            <Image
                                style={styles.tickCircle}
                                source={require("../../../assets/icons/right-green-icon.png")}
                            />
                        </View>

                        <View style={[styles.popMessageTitleView, styles.comSpExSm]}>
                            <Text style={[styles.textTitle, styles.textAlignCenter]} allowFontScaling= {false}>
                                {message} <Text style={styles.bold} allowFontScaling= {false}>{selectedUser && selectedUser?.userName}</Text>
                            </Text>
                        </View>
                    </View>
                    <View style={styles.modalSectionView}>
                        <View style={[styles.flexRowJcCtAiCt]}>
                            <View style={styles.infoTextCom}>
                                <Image
                                    style={styles.darkTickIcon}
                                    source={require("../../../assets/icons/dark-right.png")}
                                />
                            </View>
                            <Text style={[styles.textPrimeBold]} allowFontScaling= {false}>{messageTitle}</Text>
                        </View>
                    </View>
                </View>
            </ModalLayout>
        </Modal>
    );
};
export default (successMessagePopup);
