
import {StyleSheet} from 'react-native';

export default StyleSheet.create({

  questionImageWrapper: {
    marginTop: 0,
    marginBottom: 20,
    height: 164
  },

  contentWrapper: {
    alignItems: 'center',
  },

  descriptionWrappertWrapper: {
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
    //maxWidth: 380,
    width: '100%'
  },

  centeredText: {
    textAlign: 'center',
  },

  leftAlignText: {
    textAlign: 'left',
  },

  leftAlignContent: {
    alignItems: 'flex-start'
  },

  contentMaxWidth: {
    maxWidth: 380,
  }

});
