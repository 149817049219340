import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity, Platform } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import ActivityIndicator from "../ActivityIndicator/activityIndicator";
import ReportViewPopupMenu from "../PopupMenu/reportViewPopupMenu";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import moment from "moment";
import ImageFile from "./ImageFile";
import {
  getCoverIcon,
  lowerCaseAllWordsExceptFirstLetters,
} from "../../util/commonUiLogic";
import Graph from "./Graph";
import ViewIssue from "./ViewIssue";
import {
  axiosPOSTPdfDownload,
  axiosPdfDownload,
  axiosPOSTSendEmailReport,
} from "../../axios/axios-common";
import { SEND_EMAIL_USER_REPORT } from "../../store/actions/urls";
import { GENERATE_USER_REPORT } from "../../store/actions/urls";
import ConfirmationBox from "../ConfirmationBox/confirmationBox";
import PageContainer from "../PageLayout/PageContainer";
import SendEmailBox from "../../components/ConfirmationBox/sendEmailPopupBox";
import SuccessBox from "../../components/ConfirmationBox/successBox";
import { hasValue } from "../../util/hashValueGenerator";
import _ from "underscore";
import { pendoTrackEvent } from "../../util/pendoConfig";
import { WEB } from "../../util/platformWindowConfig";

class ViewReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      therapies: {},
      metrics: {},
      symptoms: {},
      downloadWait: false,
      deleteWait: false,
      selectedId: null,
      deleteConfirm: false,
      visibleModalSendEmail: false,
      sendWait: false,
    };
  }

  getCoverColor = (type) => {
    switch (type) {
      case 1:
        return "#f4ecd7";
      //orange
      case 2:
        return "#e4f5f4";
      //blue
      case 3:
        return "#eef7ee";
      //green
      case 4:
        return "#fcf4f2";
      //pink
      case 5:
        return "#f7f0fa";
      //purple
      default:
        return "#f4ecd7";
    }
  };

  OnEdit = (id) => {
    pendoTrackEvent('ViewReport@ > 3Dots > Edit');
    this.props.selectReport(id, this.props.report);
    const initHashValue = hasValue(
      `${this.props.id},${this.props.startDate},${this.props.endDate},${this.props.name},${this.props.note},${JSON.stringify(this.props.filteredRecordedSymptom)},${JSON.stringify(this.props.filteredRecordedDosing)},${JSON.stringify(this.props.selectedIssues)},${JSON.stringify(this.props.selectedImages)},${JSON.stringify(this.props.savedGraph)}`
    );
    this.props.setInitHashValue(initHashValue);
    this.props.redirectEditReport();
  };

  onDelete = (id) => {
    this.setState({
      deleteConfirm: true,
      selectedId: id,
    });
  };

  handleDeleteReport = () => {
    if (this.state.selectedId) {
      pendoTrackEvent('ViewReport@ > 3Dots > Delete');
      this.setState({ deleteWait: true });
      this.props.deleteReport(
        this.state.selectedId,
        () => {
          this.setState(
            {
              deleteWait: false,
              selectedId: null,
              deleteConfirm: false,
            },
            () => {
              this.props.redirectReport();
            }
          );
        },
        this.props.journalUserId
      );
    }
  };

  onDownloadReport = () => {
    this.setState({ downloadWait: true });
    pendoTrackEvent('ViewReport@ > Download');
    const data = {
      id: this.props.id ? this.props.id : -1,
      name: this.props.name,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      timeStamp:
        moment(new Date().getTime(), "x").format("MMM D 'YY") +
        " " +
        moment(new Date().getTime(), "x").format("h:mm a"),
      filteredRecordedSymptom: this.props.filteredRecordedSymptom,
      filteredRecordedDosing: this.props.filteredRecordedDosing,
      selectedIssues: this.props.selectedIssues,
      selectedIssuesObj: this.props.selectedIssuesObj,
      selectedImages: this.props.selectedImages,
      selectedImagesObj: this.props.selectedImagesObj,
      imagesTitle: this.props.imagesTitle,
      note: this.props.note,
      graphImage: this.props.graphImage,
      graphImageS3: !_.isEmpty(this.props.savedGraph)
        ? this.props.graphImageS3
        : null,
      startDateShow: this.props.startDate
        ? moment(this.props.startDate, "x").format("DD MMM 'YY")
        : "",
      endDateShow: this.props.endDate
        ? moment(this.props.endDate, "x").format("DD MMM 'YY")
        : "",
    };

    let url = GENERATE_USER_REPORT;
    if (this.props.journalUserId) {
      url += "?journalUserId=" + this.props.journalUserId;
    }

    if (Platform.OS === WEB) {
      axiosPOSTPdfDownload(
        url,
        data,
        this.props.name + ".pdf",
        (res, message) => {
          this.setState({ downloadWait: false });
        }
      );
    } else {
      const dataSave = {
        report: {
          id: this.props.id,
          notes: this.props.note,
          content: JSON.stringify({
            id: this.props.id ? this.props.id : -1,
            name: this.props.name,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            filteredRecordedSymptom: this.props.filteredRecordedSymptom,
            filteredRecordedDosing: this.props.filteredRecordedDosing,
            selectedIssues: this.props.selectedIssues,
            selectedIssuesObj: this.props.selectedIssuesObj,
            selectedImages: this.props.selectedImages,
            selectedImagesObj: this.props.selectedImagesObj,
            imagesTitle: this.props.imagesTitle,
            savedGraph: this.props.savedGraph,
            note: this.props.note,
            graphImage: this.props.graphImage,
            graphImageS3: !_.isEmpty(this.props.savedGraph)
              ? this.props.graphImageS3
              : null,
            startDateShow: this.props.startDate
              ? moment(this.props.startDate, "x").format("DD MMM 'YY")
              : "",
            endDateShow: this.props.endDate
              ? moment(this.props.endDate, "x").format("DD MMM 'YY")
              : "",
          }),
          name: this.props.name,
        },
      };
      this.props.updateUserReport(
        dataSave,
        this.props.journalUserId,
        (res, reportData) => {
          if (res) {
            this.props.selectReport(
              reportData.id,
              JSON.parse(reportData.content)
            );
            data.id = reportData.id;
            axiosPdfDownload(
              data,
              this.props.name + ".pdf",
              this.props.journalUserId,
              () => {
                this.setState({ downloadWait: false });
              }
            );
          }
        }
      );
    }
  };

  handleSendEmailReport = (emails, callback) => {
    this.setState({ sendWait: true });
    pendoTrackEvent('ViewReport@ > SendasEmail');
    const data = {
      id: this.props.id ? this.props.id : -1,
      name: this.props.name,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      timeStamp:
        moment(new Date().getTime(), "x").format("MMM D 'YY") +
        " " +
        moment(new Date().getTime(), "x").format("h:mm a"),
      filteredRecordedSymptom: this.props.filteredRecordedSymptom,
      filteredRecordedDosing: this.props.filteredRecordedDosing,
      selectedIssues: this.props.selectedIssues,
      selectedIssuesObj: this.props.selectedIssuesObj,
      selectedImages: this.props.selectedImages,
      selectedImagesObj: this.props.selectedImagesObj,
      imagesTitle: this.props.imagesTitle,
      note: this.props.note,
      graphImage: this.props.graphImage,
      graphImageS3: !_.isEmpty(this.props.savedGraph)
        ? this.props.graphImageS3
        : null,
      startDateShow: this.props.startDate
        ? moment(new Date(this.props.startDate), "x").format("DD MMM 'YY")
        : "",
      endDateShow: this.props.endDate
        ? moment(
            new Date(this.props.endDate),

            "x"
          ).format("DD MMM 'YY")
        : "",
    };
    let sendEmail = {
      emailRecipients: emails,
      reportData: data,
    };
    let url = SEND_EMAIL_USER_REPORT;
    axiosPOSTSendEmailReport(url, sendEmail, () => {
      this.setState({
        selectedId: null,
        visibleModalSendEmail: false,
        sendWait: false,
        successModalVisible: true,
      });
      callback(true);
      setTimeout(() => {
        this.setState({ successModalVisible: false });
      }, 1500);
    });
  };

  onSendEmail = (id) => {
    this.setState({
      visibleModalSendEmail: true,
      selectedId: id,
    });
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    let countSymptom = 0;
    let countTherapy = 0;
    // let countApp = 0;
    let countImages = 0;
    let countQuestion = 0;

    const { id, name, note } = this.props;
    return (
      <PageContainer smScreenFull={true}>
        <View style={styles.gridRow}>
          <View style={styles.gridColFull}>
            <View 
              style={[stylesRes.tabContentFullArea, stylesRes.contBotPd]}
              dataSet={{media: ids.contBotPd}}>
              <View style={styles.tabFullBlock}>
                <View style={styles.tabContentBlock}>
                  <View style={styles.titleArea}>
                    <View style={[styles.subSection, styles.colPdTopLtRt]}>
                      <View style={[styles.titleFullView]}>
                        <View style={[styles.titleViewMain]}>
                          <View style={[styles.titleDesc]}>
                            <Text style={[styles.textTitleMdBold]} allowFontScaling={false}>{name}</Text>
                          </View>
                          <View style={[styles.dateDesc, styles.topPdExSm]}>
                            <Text style={[styles.textPrime]} allowFontScaling={false}>
                              {this.props.startDate && this.props.endDate
                                ? moment(this.props.startDate, "x").format(
                                    "D MMM 'YY"
                                  ) +
                                  " - " +
                                  moment(this.props.endDate, "x").format(
                                    "D MMM 'YY"
                                  )
                                : ""}
                            </Text>
                          </View>
                        </View>

                        <View style={stylesRes.btnViewMain}>
                          <View style={[styles.btnRow]}>
                            <View style={[styles.btnViewCom]}>
                              <View style={[styles.btnBlockRight]}>
                                <ReportViewPopupMenu
                                  onDelete={() => this.onDelete(id)}
                                  onEdit={() => this.OnEdit(id)}
                                  nativeIdMenuTrigger={'viewReport3Dots'}
                                  eventNameMenuTrigger={'ViewReport@ > 3Dots'}
                                  nativeIdEdit={'viewReport3DotsEdit'}
                                  nativeIdDelete={'viewReport3DotsDelete'}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={styles.secArea}>
                    <View style={[styles.subSection, styles.colPdTopLtRt]}>
                      <View style={[styles.formSection, styles.pdBotLg]}>
                        <Text style={styles.textPrimeMd} allowFontScaling={false}>{note}</Text>
                      </View>
                    </View>
                    <View style={[styles.subSection]}>
                      {this.props.filteredRecordedSymptom &&
                        this.props.filteredRecordedSymptom.length > 0 && (
                          <View style={[styles.viewContent, styles.sectionSep]}>
                            <View style={styles.gridRow}>
                              <View style={styles.gridColFull}>
                                <View
                                  style={[
                                    styles.borderBotGrey,
                                    styles.titlePd,
                                  ]}
                                >
                                  <Text style={styles.smTitleText} allowFontScaling={false}>
                                    Symptoms
                                  </Text>
                                </View>

                                {/* If Symptoms available only, Please add styles.itemViewWrapper  */}
                                <View style={[styles.itemViewWrapper]}>
                                  {/* Loop Item Start */}

                                  {this.props.filteredRecordedSymptom.map(
                                    (item) => {
                                      let symptomName;
                                      countSymptom++;
                                      if (
                                        this.props.userSymptomsProcessed[
                                          item.symptom
                                        ]
                                      ) {
                                        symptomName = this.props
                                          .userSymptomsProcessed[item.symptom]
                                          .name;
                                      }

                                      return (
                                        Boolean(symptomName) && (
                                          <View
                                            style={[
                                              styles.listItemView,
                                              styles.listItemAddedView,
                                              styles.colPdLtRt,
                                            ]}
                                            key={symptomName}
                                          >
                                            <View
                                              style={[
                                                styles.listContentViewFull,
                                                styles.colPdTopBot,
                                              ]}
                                            >
                                              <View
                                                style={[styles.listContentView]}
                                              >
                                                <View
                                                  style={[styles.addedItemInd]}
                                                >
                                                  <View
                                                    style={[
                                                      styles.itemTextFull,
                                                    ]}
                                                  >
                                                    <View
                                                      style={[
                                                        stylesRes.itemTextLg,
                                                        stylesRes.itemTextAreaMg,
                                                      ]}
                                                      dataSet={{media: ids.itemTextAreaMg}}
                                                    >
                                                      <Text
                                                        style={[
                                                          styles.textPrime,
                                                        ]}
                                                        allowFontScaling={false}
                                                      >
                                                        {symptomName}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </View>
                                              </View>
                                            </View>

                                            {countSymptom <
                                            this.props.filteredRecordedSymptom
                                              .length ? (
                                              <View 
                                                style={stylesRes.itemBorderReport}
                                                dataSet={{media: ids.itemBorderReport}} />
                                            ) : null}
                                          </View>
                                        )
                                      );
                                    }
                                  )}
                                  {/* Loop Item End */}
                                </View>
                              </View>
                            </View>
                          </View>
                        )}

                      {this.props.selectedIssues &&
                        this.props.selectedIssues.length > 0 && (
                          <View style={[styles.viewContent, styles.sectionSep]}>
                            <View style={styles.gridRow}>
                              <View style={styles.gridColFull}>
                                <View
                                  style={[
                                    styles.borderBotGrey,
                                    styles.titlePd,
                                    styles.comSpSm
                                  ]}
                                >
                                  <Text style={styles.smTitleText} allowFontScaling={false}>
                                    To-dos
                                  </Text>
                                </View>

                                <View style={[styles.itemViewWrapper]}>
                                  {/* Loop Item Start */}

                                  {this.props.selectedIssues.map((item) => {
                                    countQuestion++;
                                    return (
                                      Boolean(this.props.questionProcessed[item.id]) && (
                                        <ViewIssue
                                          questionsCurrentCount={countQuestion}
                                          questionsLength={
                                            this.props.selectedIssues.length
                                          }
                                          question={
                                            this.props.questionProcessed[
                                              item.id
                                            ]
                                          }
                                          title={item.title}
                                          parentArtifactId={
                                            item.parentArtifactId
                                          }
                                          redirectPage={this.props.redirectPage}
                                          key={"VI_" + item.id}
                                        />
                                      )
                                    );
                                  })}

                                  {/* Loop Item End */}
                                </View>
                              </View>
                            </View>
                          </View>
                        )}

                      {this.props.filteredRecordedDosing &&
                        this.props.filteredRecordedDosing.length > 0 && (
                          <View
                            style={[
                              styles.viewContent,
                              styles.sectionSep,
                              styles.noBotPd,
                            ]}
                          >
                            <View style={styles.gridRow}>
                              <View style={styles.gridColFull}>
                                <View
                                  style={[
                                    styles.borderBotGrey,
                                    styles.titlePd,
                                  ]}
                                >
                                  <Text style={styles.smTitleText} allowFontScaling={false}>
                                    Medications/Therapies
                                  </Text>
                                </View>

                                {/* If Medications Issues added only, Please add styles.itemViewWrapper  */}
                                <View style={[styles.itemViewWrapper]}>
                                  {/* Loop Item Start */}

                                  {this.props.filteredRecordedDosing.map(
                                    (item, index) => {
                                      countTherapy++;
                                      const treatment = this.props
                                        .treatmentsProcessed[
                                        item.userTreatment
                                      ];

                                      return (
                                        Boolean(treatment) &&
                                        Boolean(treatment.name) && (
                                          <View
                                            style={[
                                              styles.listItemView,
                                              styles.listItemAddedView,
                                            ]}
                                            key={treatment.name + "_" + index}
                                          >
                                            <View
                                              style={[
                                                styles.listContentViewFull,
                                                styles.colPdTopBot,
                                                styles.colPdLtRt,
                                                {
                                                  backgroundColor: this.getCoverColor(
                                                    treatment.treatmentType
                                                  ),
                                                },
                                              ]}
                                            >
                                              <View
                                                style={[styles.listContentView]}
                                              >
                                                <View
                                                  style={[styles.addedItemInd]}
                                                >
                                                  <View
                                                    style={[styles.itemFullRow]}
                                                  >
                                                    <View style={stylesRes.itemImgAreaMain}>
                                                      <View style={stylesRes.itemMediIcon}>
                                                        <Image
                                                          style={
                                                            styles.imgContain
                                                          }
                                                          source={getCoverIcon(
                                                            treatment.treatmentType
                                                          )}
                                                        />
                                                      </View>
                                                    </View>

                                                    <View style={stylesRes.itemTextAreaMain}>
                                                      <View
                                                        style={[
                                                          styles.itemTextDetails,
                                                        ]}
                                                      >
                                                        <View
                                                          style={[
                                                            styles.itemTextMainCom,
                                                          ]}
                                                        >
                                                          <Text
                                                            style={[
                                                              styles.textPrimeBold,
                                                            ]}
                                                            allowFontScaling={false}
                                                          >
                                                            {lowerCaseAllWordsExceptFirstLetters(
                                                              treatment.name
                                                            )}
                                                          </Text>

                                                          <View style={stylesRes.itemAttachSec}>
                                                            <TouchableOpacity
                                                              onPress={() =>
                                                                this.props.redirectPage(
                                                                  treatment.artifactId
                                                                )
                                                              }
                                                            >
                                                              <View style={stylesRes.itemAttachIcon}>
                                                                <Image
                                                                  style={
                                                                    styles.imgContain
                                                                  }
                                                                  source={require("../../assets/icons/attach-lg.png")}
                                                                />
                                                              </View>
                                                            </TouchableOpacity>
                                                          </View>
                                                        </View>

                                                        <View
                                                          style={[
                                                            styles.itemTextSub,
                                                          ]}
                                                        >
                                                          <Text
                                                            style={[
                                                              styles.textPrimeSm,
                                                            ]}
                                                            allowFontScaling={false}
                                                          >
                                                            {item.quantity}{" "}
                                                            {item.units}
                                                          </Text>

                                                          <Text
                                                            style={[
                                                              styles.textPrimeSm,
                                                              styles.textOrange,
                                                              styles.textEm,
                                                            ]}
                                                            allowFontScaling={false}
                                                          >
                                                            {" "}
                                                            {
                                                              item.frequency
                                                            }{" "}
                                                            {item.frequencyType
                                                              ? "times " +
                                                                item.frequencyType
                                                                  .toLowerCase()
                                                                  .replace(
                                                                    "_",
                                                                    " "
                                                                  )
                                                              : ""}
                                                          </Text>
                                                        </View>
                                                      </View>

                                                      <View
                                                        style={[
                                                          stylesRes.itemTextDate,
                                                          stylesRes.noRtPd,
                                                        ]}
                                                        dataSet={{media: ids.itemTextDate}}
                                                      >
                                                        {item.start ||
                                                        item.end ? (
                                                          <Text
                                                            style={[
                                                              styles.textPrimeSm,
                                                              styles.textAlignRight,
                                                            ]}
                                                            allowFontScaling={false}
                                                          >
                                                            {item.start
                                                              ? moment(
                                                                  item.start,
                                                                  "x"
                                                                ).format(
                                                                  "D MMM 'YY"
                                                                )
                                                              : null}{" "}
                                                            {item.end
                                                              ? "- " +
                                                                moment(
                                                                  item.end,
                                                                  "x"
                                                                ).format(
                                                                  "D MMM 'YY"
                                                                )
                                                              : null}
                                                          </Text>
                                                        ) : null}
                                                      </View>
                                                    </View>
                                                  </View>
                                                </View>
                                              </View>
                                            </View>

                                            {countTherapy <
                                            this.props.filteredRecordedDosing
                                              .length ? (
                                              <View
                                                style={[
                                                  stylesRes.itemBorderReport,
                                                  stylesRes.itemWhiteBorderReport,
                                                ]}
                                                dataSet={{media: ids.itemBorderReport}}
                                              />
                                            ) : null}
                                          </View>
                                        )
                                      );
                                    }
                                  )}
                                  {/* Loop Item End */}
                                </View>
                              </View>
                            </View>
                          </View>
                        )}

                      {this.props.selectedImages.length > 0 && (
                        <View style={[styles.viewContent, styles.sectionSep]}>
                          <View style={styles.gridRow}>
                            <View style={styles.gridColFull}>
                              <View
                                style={[
                                  styles.borderBotGrey,
                                  styles.titlePd,
                                ]}
                              >
                                <View style={[styles.imgMainTitleText]}>
                                  <Text style={styles.smTitleText} allowFontScaling={false}>
                                    Images/Documents
                                  </Text>
                                </View>
                                {/* Title Section for images - Start */}
                                <View style={[this.props.imagesTitle && styles.addedImgTitleText]}>
                                  <Text style={styles.textPrime} allowFontScaling={false}>
                                    {this.props.imagesTitle}
                                  </Text>
                                </View>
                                {/* Title Section for images - End */}
                              </View>

                              {/* If Images/Documents added only, Please add styles.itemViewWrapper  */}
                              <View style={[styles.itemViewWrapper]}>
                                {/* Loop Item Start */}

                                {this.props.selectedImages.map((item) => {
                                  const attachment = this.props
                                    .attachmentsProcessed[item.id];
                                  countImages++;
                                  return (
                                    Boolean(attachment) && (
                                      <View
                                        style={[
                                          styles.listItemView,
                                          styles.listItemAddedView,
                                          styles.colPdLtRt,
                                        ]}
                                      >
                                        <View
                                          style={[
                                            styles.listContentViewFull,
                                            styles.colPdTopBot,
                                          ]}
                                        >
                                          <View
                                            style={[styles.listContentView]}
                                          >
                                            <View style={[styles.addedItemInd]}>
                                              <View
                                                style={[styles.itemFullRow]}
                                              >
                                                <View style={stylesRes.itemImgAreaMainLg} dataSet={{media: ids.itemImgAreaMainLg}}>
                                                  <View style={stylesRes.itemDocIcon} dataSet={{media: ids.itemDocIcon}}>
                                                    <ImageFile id={item.id} />
                                                  </View>
                                                </View>

                                                <View style={stylesRes.itemTextAreaMain}>
                                                  <View
                                                    style={[
                                                      styles.itemTextDetails,
                                                    ]}
                                                  >
                                                    <View
                                                      style={[
                                                        styles.itemTextMain,
                                                      ]}
                                                    >
                                                      <Text
                                                        style={[
                                                          styles.textPrimeBold,
                                                        ]}
                                                        allowFontScaling={false}
                                                      >
                                                        {attachment.filename}
                                                      </Text>
                                                    </View>
                                                  </View>

                                                  <View
                                                    style={[
                                                      stylesRes.itemTextDate,
                                                      stylesRes.noRtPd,
                                                    ]}
                                                    dataSet={{media: ids.itemTextDate}}
                                                  >
                                                    <Text
                                                      style={[
                                                        styles.textPrimeSm,
                                                        styles.textAlignRight,
                                                      ]}
                                                      allowFontScaling={false}
                                                    >
                                                      {moment(
                                                        attachment.modifiedOn,
                                                        "x"
                                                      ).format("D MMM 'YY")}
                                                    </Text>
                                                  </View>
                                                </View>
                                              </View>
                                            </View>
                                          </View>
                                        </View>

                                        {countImages <
                                        this.props.selectedImages.length ? (
                                          <View style={stylesRes.itemBorderReportFull} />
                                        ) : null}
                                      </View>
                                    )
                                  );
                                })}
                                {/* Loop Item End */}
                              </View>
                            </View>
                          </View>
                        </View>
                      )}

                      {this.props.savedGraph &&
                        Object.values(this.props.savedGraph).length > 0 && (
                          <View style={[styles.viewContent, styles.sectionSep]}>
                            <View style={styles.gridRow}>
                              <View style={styles.gridColFull}>
                                <View
                                  style={[
                                    styles.borderBotGrey,
                                    styles.titlePd,
                                  ]}
                                >
                                  <Text style={styles.smTitleText} allowFontScaling={false}>Graphs</Text>
                                </View>

                                <View style={[styles.itemViewWrapper]}>
                                  <View
                                    style={[
                                      styles.listItemView,
                                      styles.listItemAddedView,
                                      styles.colPdLtRt,
                                    ]}
                                  >
                                    <View
                                      style={[
                                        styles.listContentViewFull,
                                        styles.colPdTopBot,
                                      ]}
                                    >
                                      <View style={stylesRes.detailGraph}>
                                        <Graph isEdit={false} graphDivId={"chartdiv_view_report"} />
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        )}
                    </View>
                    <View
                      style={[
                        styles.subSection,
                        styles.sectionSep,
                        styles.noBotPd,
                      ]}
                    >
                      <View style={[styles.btnArea, styles.colPd]}>
                        <View
                          style={[
                            stylesRes.btnViewSec,
                            stylesRes.btnViewSecComNoPd,
                          ]}
                        >
                          <View style={styles.fieldBtn}>
                            <View style={styles.btnBlockFull}>
                              <TouchableOpacity
                                onPress={() => this.onSendEmail(id)}
                                disabled={this.state.visibleModalSendEmail}
                                underlayColor="rgba(0, 153, 168, 0.8)"
                              >
                                <View
                                  style={[
                                    styles.submitSm,
                                    styles.submitBgOrange,
                                    styles.btnSingleCent,
                                  ]}
                                  nativeID={'viewReportSendasEmail'}
                                >
                                  <Text style={styles.submitText} allowFontScaling={false}>
                                    Send as email
                                  </Text>
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                          <View style={styles.fieldBtnSp} />

                          <View style={styles.fieldBtn}>
                            <View style={styles.btnBlockFull}>
                              <TouchableOpacity
                                onPress={this.onDownloadReport}
                                disabled={this.state.downloadWait}
                                underlayColor="rgba(0, 153, 168, 0.8)"
                              >
                                <View
                                  style={[
                                    styles.submitSm,
                                    styles.submitBgOrange,
                                    styles.btnSingleCent,
                                  ]}
                                  nativeID={'viewReportDownload'}
                                >
                                  {this.state.downloadWait && (
                                    <View style={styles.actIndCenterView}>
                                      <ActivityIndicator size="small" />
                                    </View>
                                  )}
                                  <Text style={styles.submitText} allowFontScaling={false}>
                                    Download
                                  </Text>
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                    <ConfirmationBox
                      visibleModal={this.state.deleteConfirm}
                      onBackdropPress={() => {
                        this.setState({
                          deleteConfirm: false,
                          selectedId: null,
                        });
                      }}
                      onRequestClose={() => {
                        this.setState({
                          deleteConfirm: false,
                          selectedId: null,
                        });
                      }}
                      alertTitle="Delete Report"
                      handleDelete={this.handleDeleteReport}
                      deleteWait={this.state.deleteWait}
                    />
                    {this.state.visibleModalSendEmail && (
                      <SendEmailBox
                        visibleModalSendEmail={this.state.visibleModalSendEmail}
                        onBackdropPress={() => {
                          this.setState({
                            visibleModalSendEmail: false,
                            selectedId: null,
                          });
                        }}
                        onRequestClose={() => {
                          this.setState({
                            visibleModalSendEmail: false,
                            selectedId: null,
                          });
                        }}
                        handleSendEmail={(emails, callback) =>
                          this.handleSendEmailReport(emails, callback)
                        }
                        sendWait={this.state.sendWait}
                      />
                    )}
                    <SuccessBox
                      visibleModal={this.state.successModalVisible}
                      message="Success! Email has been sent."
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: state.report.id,
    name: state.report.name,
    note: state.report.note,
    report: state.report,
    startDate: state.report.startDate,
    endDate: state.report.endDate,
    filteredRecordedSymptom: state.report.filteredRecordedSymptom,
    filteredRecordedDosing: state.report.filteredRecordedDosing,
    selectedIssues: state.report.selectedIssues,
    selectedImages: state.report.selectedImages,
    attachmentsProcessed:
      state.routines.attachmentsCompleteDigestProcessed.attachments,
    contactsProcessed: state.routines.contactsProcessed,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    userSymptomsProcessed: state.routines.symptomsDigestProcessed.userSymptoms,
    questionProcessed: state.routines.questionDigestProcessed.questions,
    selectedGraph: state.report.selectedGraph,
    dashboardProcessed: state.routines.dashboardProcessed,
    journalUserId: state.uistate.journalUserId,
    savedGraph: state.report.savedGraph,
    imagesTitle: state.report.imagesTitle,
    graphImage: state.report.graphImage,
    graphImageS3: state.report.graphImageS3,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserReport: (data, journalUserId, callBack) =>
      dispatch(actions.updateUserReport(data, journalUserId, callBack)),
    selectReport: (id, data) => dispatch(actions.selectReport(id, data)),
    deleteReport: (id, callBack, journalUserId) =>
      dispatch(actions.deleteReport(id, callBack, journalUserId)),
    setInitHashValue: (initHashVal) =>
      dispatch(actions.setInitHashValue(initHashVal)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewReport);
