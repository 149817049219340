import React from 'react';
import {View, Text, Image, TouchableOpacity, Linking, Platform} from 'react-native';
import styles from '../../Css';
import {pendoTrackEvent} from '../../util/pendoConfig';
import { WEB } from '../../util/platformWindowConfig';


const emailus = (props) => {
    return (
        <View style={[styles.borderComBot, {width: "100%"}]}>
            <TouchableOpacity
                onPress={() => {
                    if (Platform.OS === WEB) {
                        window.open(props.link);
                    } else {
                        Linking.openURL(`${props.link}`);
                    }
                    pendoTrackEvent(props.eventName);
                }}>
                <View style={[styles.detailItemWrapper, styles.detailItemFirst]} nativeID={props.nativeId}>
                    <View style={[styles.detailItemWideTitle]}>
                        <View style={[styles.itemIconView]}>
                            <Image
                                source={require("../../assets/icons/video-play.png")}
                                style={styles.itemVideoIcon}
                            />
                        </View>
                        <View style={[styles.itemTextView]}>
                            <Text style={[styles.textPrimeBold, styles.textColorLight]} allowFontScaling={false}>
                                {props.videoDescription}
                            </Text>
                        </View>
                    </View>
                    <View style={[styles.detailItemData]}>
                        <Text
                            style={[
                                styles.textPrimeBold,
                                styles.textOrange,
                                styles.textAlignRight,
                            ]}
                            allowFontScaling={false}
                        >
                            Watch now
                        </Text>
                    </View>
                    <View style={[styles.btnIconView]}>
                        <Image
                            style={styles.linkIconSm}
                            source={require("../../assets/contact/link.png")}
                        />
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    );
};

export default (emailus);
