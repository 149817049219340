import React from 'react';
import {View, Image, Text} from 'react-native';
import styles from '../../Css';

const noResultFound = props => {
  return (
    <View style={[styles.searchEmptyResult, styles.flexJcCtAiCt]}>
      <View style={[styles.resultIconView]}>
        <Image
          style={styles.searchResultIcon}
          source={require('../../assets/icons/search-result-empty.png')}
        />
      </View>
      <View style={[styles.resultContentView]}>
        <View style={[styles.resultContentTitle]}>
          <Text style={[styles.textPrimeBold, styles.textAlignCenter]} allowFontScaling={false}>
            No results matching search criteria
          </Text>
        </View>
        <View style={[styles.resultContentDesc]}>
          <Text
            style={[
              styles.textPrime,
              styles.textGreyLight,
              styles.textAlignCenter,
            ]}
            allowFontScaling={false}
            >
            Clear your search or search using {'\n'}a different keyword
          </Text>
        </View>
      </View>
    </View>
  );
};

export default noResultFound;
