import React from 'react';
import {View, Text} from 'react-native';
import styles from '../../Css';
import {LogoSetup, ViewStyle, ProceedTextConent} from './CommonContents';

const content = props => (
  <React.Fragment>
    <View style={[styles.staticPageContent, styles.staticPageMax]}>
      {LogoSetup}
      {ViewStyle}
      <View style={styles.gridRow}>
        <View style={styles.gridColFull}>
          <View style={[styles.staticImgWrapper, styles.flexJcCtAiCt]}>
            {props.messageIcon}
          </View>
        </View>
      </View>

      <View style={styles.gridRow}>
        <View style={[styles.gridColFull, styles.staticSecText]}>
          <View style={styles.staticSecTitle}>
            <Text style={[styles.textTitleMainLgBold, styles.textAlignCenter]}>
              {props.messageTitle}
            </Text>
          </View>
          {props.verifySuccess && (
            <View style={[styles.staticSecTitle, styles.titleTopPd]}>
              <Text style={[styles.textTitleMainLgBold]}>
                {props.messageSubTitle}
              </Text>
            </View>
          )}
          <View style={[styles.staticSecDesc, styles.pdTopSm, styles.pdBotLg]}>
            <Text style={[styles.textPrime, styles.textTertiary]}>
              {props.message}
            </Text>
          </View>
        </View>
      </View>
      {ProceedTextConent(props.navigateToDashbaord)}
    </View>
  </React.Fragment>
);

export default (content);
