import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

class SymptomItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelect: this.isSelect(props.id)
    };
  }

  isSelect = id => {
    const found = this.props.filteredRecordedSymptom.find(
      item => item.id === id
    );
    if (found) {
      return true;
    } else {
      return false;
    }
  };

  onSelect = () => {
    this.props.addRemoveSymptom(!this.state.isSelect, this.props.data);
    this.setState({ isSelect: !this.state.isSelect });

    // this.props.addRemoveReportIssue(this.props.question);
    // this.props.setIssueSelected(
    //   Object.values(this.props.selectedIssuesObj).length > 0
    // );
  };
  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();

    return (
      <View style={[styles.messageSecRow, styles.greyBorder]}>
        <TouchableOpacity style={[styles.messageRow]} onPress={this.onSelect}>
        <View style={styles.messageDetailSec}>
          <View style={styles.messageDetailCont}>
            <View style={[styles.messageDetailSm, styles.messageTopBotPdSm]}>
              <View style={[styles.messageDataFull, styles.flexAiFs]}>
                <View style={stylesRes.messageDataSm}>
                  <Text style={styles.messageTitle} allowFontScaling={false}>
                    {this.props.symptom.name}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={[
            styles.selectionBlockSm,
            styles.messageTopBotPdSm,
            styles.selectionPdRt
          ]}
        >
          <View style={styles.iconListSec}>
            <View
              style={[styles.selectSecIcon, styles.iconSettingSp, styles.settingPd]}
            >
              <Image
                style={styles.radioIcon}
                source={
                  this.state.isSelect
                    ? require("../../assets/icons/radio-selected.png")
                    : require("../../assets/icons/radio-default.png")
                }
              />
            </View>
          </View>
        </View>
        </TouchableOpacity>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedIssuesObj: state.report.selectedIssuesObj,
    userSymptomsProcessed: state.routines.symptomsDigestProcessed.userSymptoms,
    filteredRecordedSymptom: state.report.filteredRecordedSymptom
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addRemoveReportIssue: question =>
      dispatch(actions.addRemoveReportIssue(question))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SymptomItem);
