import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import UserAvatar from 'react-native-user-avatar';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Cookies from 'js-cookie';
import {BASE_URL} from '../../store/actions/urls';
import {getUserAvatarName} from './../../util/commonUiLogic';

class ProfileHeader extends Component {
  constructor(props) {
    super(props);
    let url = BASE_URL + '/api/profile/image/byId?';
    if (this.props.journalUserId) {
      url +=
        'journalUserId=' +
        this.props.journalUserId +
        '&auth=' +
        Cookies.get('authToken');
    } else {
      url += 'auth=' + Cookies.get('authToken');
    }
    this.state = {
      imageLoading: true,
      uri: {
        uri: url + '&random_number=' + new Date().getTime(),
        cache: 'reload',
      },
    };
  }

  imageLoadingError() {
    this.setState({imageLoading: false});
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {firstName, lastName} = this.props;
    const userAvatarName = getUserAvatarName(firstName, lastName);
    return (
      <View style={styles.gridRow}>
        <View style={styles.gridColFull}>
          <View style={styles.secArea}>
            <View style={[styles.profileBannerSec]}>
              <View style={[styles.profileBannerBg, styles.bgHeight]}>
                <Image
                  style={[styles.profileBannerBgImg]}
                  source={require('../../assets/icons/profile-bg.png')}
                />
              </View>

              <View
                style={[
                  styles.profileBannerContent,
                  styles.bgHeight,
                  styles.flexRowJcCtAiCt,
                ]}>
                <View style={styles.profileBannerTextArea}>
                  <View style={stylesRes.profileTextView} dataSet={{media: ids.profileTextView}}>
                    <View style={stylesRes.profileTextCom}>
                      <Text style={[styles.textPrimeSm, styles.textWhite]} allowFontScaling= {false}>
                        This health journey belongs to {'\n'}
                        <Text style={[styles.bold]} allowFontScaling= {false}>
                          {this.props.firstName} {this.props.lastName}
                        </Text>
                      </Text>
                    </View>
                  </View>
                  <View style={stylesRes.profileCenter} dataSet={{media: ids.profileCenter}}>
                    <View style={stylesRes.profileCenterImgSec}>
                      {this.state.imageLoading && this.state.uri ? (
                        <Image
                          style={styles.profileLoginIconLg}
                          source={this.state.uri}
                          onError={this.imageLoadingError.bind(this)}
                        />
                      ) : userAvatarName ? (
                        <UserAvatar
                          size={115}
                          name={userAvatarName.toUpperCase()}
                          textColor={'#FF9E38'}
                          bgColor={'rgb(255, 255, 255)'}
                        />
                      ) : null}

                    </View>
                  </View>
                  <View style={stylesRes.profileLinkView} data-madia={ids.profileLinkView}>
                    <View style={stylesRes.profileTextEdit}>
                      <TouchableOpacity
                        onPress={this.props.redirectEditProfile}>
                        <View style={stylesRes.profileEditLink} nativeID={'profileEdit'}>
                          <View style={[styles.editProfile, styles.mgRtSm]}>
                            <Image
                              style={styles.noteIcon}
                              source={require('../../assets/icons/note.png')}
                            />
                          </View>
                          <Text style={[styles.textPrimeSm, styles.textWhite]} allowFontScaling= {false}>
                            Edit
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default (ProfileHeader);
