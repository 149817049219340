import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity, Platform } from "react-native";
import { connect } from "react-redux";
import styles from "../../../../Css";
import stylesResponsive from "../../../../CssResponsive";
import DateTimePicker from "../../../DateTimePicker/DateTimePicker";
import InputField from "../../../TextInput/inputField";
import moment from "moment";
import PageContainer from "../../../../components/PageLayout/PageContainer";
import Webview from "../../../Webview/webview";
import FieldTitle from "../../../TextInput/fieldTitle";
import * as FieldDetails from "../../../TextInput/fieldDetails";
import MouseHover from "../../../MouseHover";
import CopyFromPrev from "./CopyFromPrev";
import ReminderHeader from "../../Reminder/ReminderHeader";
import { APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED } from '../../../../util/featureConfig';
import { WEB } from "../../../../util/platformWindowConfig";
class JournalTemplateHeader extends Component {
  constructor(props) {
    super(props);
    const { date, title, description, flagged } = this.props;
    this.state = {
      date: date,
      title: title,
      invalidTitle: false,
      description: description,
      validation: true,
      flagged: flagged,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { validation } = props;
    if (validation !== state.validation) {
      let invalidTitle = false;
      if ((state.title && state.title.trim() === "") || !state.title) {
        invalidTitle = true;
      }
      return {
        invalidTitle: invalidTitle,
        validation: validation,
      };
    }
    return null;
  }

  customTouchableComponent = (onPress) => {
    return (
      Platform.OS === WEB
        ? <TouchableOpacity onPress={onPress}>
          {this.renderDateTimeContent()}
        </TouchableOpacity>
        : this.renderDateTimeContent()
    );
  };

  renderDateTimeContent = () => {
    const { date } = this.state;
    return (
      <View style={styles.bgWhite}>
        <View
          style={[
            styles.textField,
            styles.textFieldDateSm,
            styles.noRad,
            this.props.windowWidth < 575
              ? styles.dateSmPd
              : styles.dateLgPd,
            styles.customPaddding
          ]}
        >
          <View style={styles.dateTimeValue}>
            <Text
              style={[
                this.props.windowWidth < 575
                  ? [styles.textPrimeSmBlue, styles.textUnderline]
                  : [styles.textPrimeMdBlue, styles.textUnderline],
              ]}
              allowFontScaling={false}
            >
              {date ? moment(date).format("D MMM 'YY h:mm A") : ""}
            </Text>
          </View>
        </View>
        <View
          style={[
            styles.imgViewSm,
            styles.imgViewcalSm,
            this.props.windowWidth < 575
              ? styles.imgViewSmRt
              : styles.imgViewLgRt,
          ]}
          pointerEvents={"none"}
        >
          <Image
            style={[styles.calIconExSm]}
            source={require("../../../../assets/icons/cal-dark.png")}
          />
        </View>
      </View>
    )
  }

  onConfirm = (date) => {
    if (date) {
      this.setState({ date: date });
      const { updateEntryDate } = this.props;
      updateEntryDate(date);
    }
  };

  onChangeTitel = (val) => {
    const { updateTitel } = this.props;
    this.setState({ title: val, invalidTitle: false }, () => updateTitel(val));
  };

  onChangeDescription = (val) => {
    const { updateDescription } = this.props;
    this.setState({ description: val }, () => updateDescription(val));
  };
  changeFlagged = () => {
    const { updateFlagged } = this.props;
    const { flagged } = this.state;
    this.setState({ flagged: !flagged }, () => updateFlagged(!flagged));
  };

  getPrefferedColor = color => {
    color = color.replace(/#/g, '')
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);

    const bright = r * 2 + g * 3 + b;
    if (bright >= 1300)
      return "DARK";
    return "WHITE";
  }

  render() {
    const { styles: stylesRes } = stylesResponsive.getProcessedStyles();
    const {
      applicationSettingsDigest,
      journalEntryTypes,
      copyPreviousJournalEntry,
      deviceJournal,
      reminderDateTime
    } = this.props;
    const {
      quickNoteEntryTypeId,
      labsEntryTypeId,
      newJournalEntryTypeId,
    } = applicationSettingsDigest;
    const { id, color, reminders, journalPublishedBy, icon_c } = journalEntryTypes;
    const { date, title, description, invalidTitle, flagged } = this.state;
    const isDark = Boolean(color && color.includes("#") && this.getPrefferedColor(color) === "DARK")
    return (
      <React.Fragment>
        <View style={[styles.routineTopicSection, styles.topicBlock]}>
          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              <View
                style={[
                  styles.topicFullBlock,
                  {
                    borderBottomWidth: 1,
                    borderBottomColor: '#C1C1C2'
                  }
                  // styles.topicBgDefault,
                  // { backgroundColor: color },
                ]}
              >
                <PageContainer outlookScreen={true}>
                  <View style={[styles.barPd, styles.flexRow, styles.flexJcSb]}>
                    {/* {((quickNoteEntryTypeId !== id &&
                      newJournalEntryTypeId !== id) ||
                      labsEntryTypeId === id) && (
                     <View style={[styles.journalHeaderSection, styles.flexCom]}>
                      <View
                        style={[
                          styles.journalHeaderArea,
                          styles.flexRow,
                          styles.flexAiCt,
                        ]}
                      >
                     <View
                            style={[
                              this.props.windowWidth < 375
                                ? styles.copyBtnBoxSm
                                : styles.copyBtnBox,
                              styles.simpleBtnShadow,
                            ]}
                          >
                            <TouchableOpacity
                              onPress={copyPreviousJournalEntry}
                            >
                              <View
                                style={[
                                  styles.simpleBtnBox,
                                  this.props.windowWidth < 575
                                    ? styles.simpleBtnBoxSm
                                    : styles.simpleBtnBoxCom,
                                  styles.simpleBtnSm,
                                  styles.flexJcCtAiCt,
                                ]}
                              >
                                <Text
                                  style={[
                                    this.props.windowWidth < 375
                                      ? styles.textPrimeExSm
                                      : this.props.windowWidth < 575
                                      ? styles.textPrimeSm
                                      : styles.textPrime,
                                  ]}
                                  allowFontScaling={
                                    FontScaling.allowFontScaleFalse
                                  }
                                >
                                  Copy from previous entry
                                </Text>
                              </View>
                            </TouchableOpacity>
                          </View>
                     <View style={[styles.copyBtnIcon]}>
                            <View style={styles.textIconViewRt}>
                              <TouchableOpacity
                                onPress={copyPreviousJournalEntry}
                              >
                                <Image
                                  style={styles.copyIcon}
                                  source={require("../../../../assets/icons/copy.png")}
                                  resizeMode={"contain"}
                                />
                              </TouchableOpacity>
                            </View>
                          </View>
                     </View>
                    </View>
                     )} */}
                    {/* {newJournalEntryTypeId === id && ( */}
                    {/* <View
                      style={[styles.flexJcCt, styles.flexCom, styles.textPdRt]}
                    >
                      <Text
                        style={[
                          this.props.windowWidth < 375
                            ? styles.textPrimeBold
                            : styles.textPrimeLgBold,
                          (icon_c !== null || isDark)
                            ? styles.textPrimeCom
                            : styles.textWhite,
                        ]}
                        allowFontScaling={false}
                        maxFontSizeMultiplier={FontScaling.fontScaleMd}
                      >
                        Create an Entry
                      </Text>
                    </View> */}
                    {/* )} */}

                    <View
                      style={[
                        styles.flexAiFe,
                        styles.flexJcCt,

                        this.props.windowWidth < 575
                          ? styles.journalDateSectionSm
                          : styles.journalDateSectionLg,
                      ]}
                    >
                      <View style={styles.textInputAreaView} nativeID={'journalDateTimePicker'}>
                        <DateTimePicker
                          date={date}
                          customTouchableComponent={
                            this.customTouchableComponent
                          }
                          onConfirm={this.onConfirm}
                          clearCancelOff={true}
                          disabled={deviceJournal}
                        />
                        <View>
                          {Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED) && Boolean(reminderDateTime) && reminderDateTime !== date &&
                            <Text style={[styles.textPrime, styles.customTextDate]} allowFontScaling={false}>
                              Entry for reminder on {`${moment(reminderDateTime).format("DD MMM 'YY h:mm A")}`}
                            </Text>
                          }
                        </View>
                        {/* {((quickNoteEntryTypeId !== id &&
                      newJournalEntryTypeId !== id) ||
                      labsEntryTypeId === id) && (
                      <View
                        style={[
                          this.props.windowWidth < 575
                            ? styles.copyToNewSectionSm
                            : styles.copyToNewSectionLg,
                          styles.flexAiFe,
                          styles.flexJcCt,
                        ]}
                      > */}
                        {/* <MouseHover id={"copy"} text={"Copy from previous"}>
                          <TouchableOpacity 
                            nativeID={'journalCopyFromPrevious'}
                            onPress={copyPreviousJournalEntry}
                            disabled={deviceJournal}>
                            <Image
                              style={styles.copyNewIcon}
                              source={(icon_c !== null || isDark)
                                ? require("../../../../assets/icons/copy-from-previous-dark.png")
                                : require("../../../../assets/icons/copy-from-previous-white.png")
                              }
                              resizeMode={"contain"}
                            />
                          </TouchableOpacity>
                        </MouseHover> */}
                        {/* </View>
                    )} */}
                      </View>
                    </View>

                    <View
                      style={[
                        this.props.windowWidth < 575
                          ? styles.favSectionSm
                          : styles.favSectionLg,
                        styles.flexAiFe,
                        styles.flexJcCt,
                      ]}
                    >
                      <View style={styles.favStarBtn}>
                        <TouchableOpacity
                          onPress={this.changeFlagged}
                          nativeID={'journalFavourite'}>
                          {flagged ? (
                            <Image
                              style={styles.starIconSm}
                              source={require("../../../../assets/icons/star-yellow-lg.png")}
                            />
                          ) : (
                            <Image
                              style={styles.starIconSm}
                              source={require("../../../../assets/icons/star-grey-lg.png")}
                            />
                          )}
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </PageContainer>
              </View>
            </View>
          </View>
        </View>

        <View style={[stylesRes.contentViewWrapperFull, stylesRes.tabBorder]}>
          <PageContainer screenFull={true} outlookScreen={true}>
            {quickNoteEntryTypeId !== id &&
              labsEntryTypeId !== id &&
              newJournalEntryTypeId !== id && (
                <View style={[styles.journalContentWrapper, styles.bgwhitegrey, styles.colPdLtRt, styles.pdTopSm]}>
                  <View style={styles.fieldRow}>
                    <View style={styles.fieldRowWrapperFull} nativeID={'journalJournalName'}>
                      {/* TextInput */}
                      <InputField
                        title={"Entry Name"}
                        value={title}
                        onChangeText={this.onChangeTitel}
                        fieldLt={true}
                        invalid={invalidTitle}
                        editable={journalPublishedBy !== "PARTNER"}
                        requirdStar={true}
                      />
                    </View>
                  </View>
                  {description !== "" && description != null && (
                    <View
                      style={[
                        stylesRes.contentViewWrapperFull,
                      ]}
                    >
                      <View style={[styles.journalContentWrapper]}>
                        <View
                          style={[
                            styles.pdBotLg,
                            styles.fieldRowWrapperFull,
                          ]}
                        >
                          {/* <ViewMoreText longText={description} /> */}
                          <View style={[styles.postRelative]}>
                            <FieldTitle
                              fieldAlign={this.props.fieldAlign}
                              fieldBg={FieldDetails.BGGREY}
                              title={"Entry Description"}
                              fieldLt={true}
                            />
                            <View
                              style={[
                                styles.textInputField,
                                styles.bgTransparent,
                                styles.bgThinGrey,
                                { height: "auto" },
                              ]}
                            >
                              <View
                                style={[
                                  //!seeMore && { height: 50 },
                                  //{ overflow: "hidden" },
                                  styles.flexJcCt,
                                  styles.bgThinGrey,
                                  //styles.bgTransparent
                                ]}
                              //nativeID={"richTextView"}
                              />
                              <Webview htmlContent={description} />
                            </View>
                          </View>
                          <View style={[styles.moreDescView]} />
                        </View>
                      </View>
                    </View>
                  )}

                  {/* Copy From Previous Button */}
                  <CopyFromPrev
                    nativeID={'journalCopyFromPrevious'}
                    onPress={copyPreviousJournalEntry}
                    disabled={deviceJournal}
                  />
                  <ReminderHeader reminders={reminders} isFromJournal={true} />
                </View>
              )}
          </PageContainer>
          <PageContainer outlookScreen={true}>
            {(quickNoteEntryTypeId === id ||
              labsEntryTypeId === id ||
              newJournalEntryTypeId === id) && (
                <View style={[styles.journalFormWrapper]}>
                  <View style={[styles.journalForm, styles.contSmPdTopBot]}>
                    <View style={styles.fieldRow}>
                      <View style={styles.fieldRowWrapperFull} nativeID={'journalJournalName'}>
                        {/* TextInput */}
                        <InputField
                          title={"Entry Name"}
                          value={title}
                          onChangeText={this.onChangeTitel}
                          fieldLt={true}
                          invalid={invalidTitle}
                          editable={journalPublishedBy !== "PARTNER"}
                          requirdStar={true}
                        />
                      </View>
                    </View>
                    <View style={[styles.fieldRowFrame, styles.noMgBot]}>
                      <View style={styles.fieldRowWrapperFull}>
                        {/* TextInput */}
                        {/* ZI-619- journal description should not be editable from patient portal at anywhere */}
                        {/* {typeName !== "New Journal Entry" && (
                        <InputField
                          title={"Journal Description"}
                          value={description}
                          onChangeText={this.onChangeDescription}
                          fieldLt={true}
                          maxLength={255}
                        />
                      )} */}
                      </View>
                    </View>
                  </View>
                </View>
              )}
          </PageContainer>
        </View>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
  };
};

export default connect(mapStateToProps, null)(JournalTemplateHeader);
