import React from 'react';
import { Image, View } from 'react-native';
import stylesCss from './QuestionImageStyles';

const QuestionImage = ({ imageUrl }) => (
  <>
    <View style={[stylesCss.surveyQuestionBannerWrapper]}>
      {Boolean(imageUrl) && <Image
        style={[stylesCss.surveyQuestionBanner]}
        resizeMode = {'contain'}
        source={{ uri: imageUrl }}
      />}

    </View>
  </>
);

export default QuestionImage;
