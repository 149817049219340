import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import TickButton from "../../components/CheckBox/CheckBoxNew";
import { getTherapiesColourCode } from "../../util/commonUiLogic";
import * as actions from "../../store/actions/index";

class Therapy extends Component {
  constructor(props) {
    super(props);
    const {therapies, therapyProps, configChartType, savedTherapyProps, dashboardProcessed, graphId} = this.props;
    let defaultChartType = configChartType;
    if (dashboardProcessed.hasOwnProperty(graphId) && dashboardProcessed[graphId].treatmentsProperties) {
      const propsArray = JSON.parse(dashboardProcessed[graphId].treatmentsProperties);
      Object.values(propsArray).forEach(element => {
        if (element.id == props.item.id) {
          defaultChartType = element.style;
        }
      });
    }

    this.state = {
      ...props.item,
      displayProperties: { ...props.item.displayProperties },
      tick: therapies && therapies[props.item.id] ? true : false,
      chartType: therapyProps && therapyProps[props.item.id] && therapyProps[props.item.id].style 
                  ? therapyProps[props.item.id].style
                  : savedTherapyProps && savedTherapyProps[props.item.id] && savedTherapyProps[props.item.id].style 
                      ? savedTherapyProps[props.item.id].style
                          : dashboardProcessed.hasOwnProperty(graphId)
                              ? defaultChartType
                              : configChartType,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.therapies && props.therapies[this.state.id]) {
      this.setState({ tick: true });
    } else {
      this.setState({ tick: false });
    }
  }

  handleOnPressTick = (tick, id) => {
    if (tick) {
      this.props.addTherapy(id);
      this.props.updateTherapyProps(id, this.state.chartType);
    } else {
      this.props.removeTherapy(id);
    }
  };
  shouldComponentUpdate(nextProps, nextState) {
    return nextState.tick !== this.state.tick;
  }
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const colorItem = (
      <View
        key={this.props.key}
        style={stylesRes.colorBox}
        dataSet={{media: ids.colorBox}}
      >
        <View
          style={[
            stylesRes.colorItem,
            {
              backgroundColor: this.state.treatmentType
                ? getTherapiesColourCode(this.state.treatmentType)
                : "white"
            }
          ]}
          dataSet={{media: ids.colorItem}}
        >
          <View style={[styles.colorSection]} />
        </View>
      </View>
    );
    return (
      <TouchableOpacity 
        onPress={() => 
          this.handleOnPressTick(!this.state.tick, this.state.id)
        }
      >
        <View style={[styles.ViewRow, styles.greyBorderThin]}>
          <View style={styles.viewDetailSec}>
            <View style={styles.viewMainData}>
              <View style={[styles.viewItemDesc, styles.colPdLtTopBotRg]}>
                <View style={[styles.viewColor, styles.infoSp]}>{colorItem}</View>

                <View style={[styles.viewDataFull]}>
                  <Text style={styles.textPrime} allowFontScaling={false}>{this.state.name}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.dataIconSecRg, styles.colPdRtTopBotRg]}>
            <View style={styles.iconListSec}>
              <View style={[styles.checkboxSec]}>
                <TickButton
                  ticked={this.state.tick}
                  toggleTickCallback={tick =>
                    this.handleOnPressTick(tick, this.state.id)
                  }
                />
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = state => {
  return {
    therapies: state.report.graph.therapies,
    therapyProps: state.report.graph.therapyProps,
    savedTherapyProps: state.report.savedGraph.therapyProps,
    dashboardProcessed: state.routines.dashboardProcessed,
    graphId: state.report.graph.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addTherapy: therapy => dispatch(actions.addReportGrapTherapy(therapy)),
    removeTherapy: therapy => dispatch(actions.removeReportGrapTherapy(therapy)),
    updateTherapyProps: (therapyId, chartType) => 
      dispatch(actions.updateReportGraphTherapyProps(therapyId, chartType))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Therapy);
