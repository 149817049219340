export const CONTACT_PHONE_NUMBER = '+1 587 907 5357';
export const CONTACT_EMAIL = 'support@zamplo.org';
export const ORGANIZATION_NAME = 'Zamplo';
export const ORGANIZATION_DOMAIN = 'zamplo.org';
export const CURRENT_YEAR = '2021';
export const ORGANIZATION_URL = 'https://www.zamplo.org';
export const ORGANIZATION_URL_TOU = 'https://www.zamplo.org/terms-of-use';
export const ORGANIZATION_URL_SUPPORT = 'https://www.zamplo.org/support';
export const ORGANIZATION_URL_PRIVACY = 'https://www.zamplo.org/privacy';

export const APPLE_APP_STORE_URL = 'https://apps.apple.com/lk/app/zamplo/id1503568766';
export const ANDROID_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.hanalytics';