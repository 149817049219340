import React, {useState, useEffect} from 'react';
import {Text, View, TouchableOpacity, TextInput} from 'react-native';
import styles from '../../Css';
import Modal from '../Modal/modal';
import ModalLayout from '../ModalLayout/modalLayout';

const EditHealthDataPopup = props => {
  const {
    visibleModal,
    healthDatainputValue,
    handleEditHealthData,
    onBackdropPress,
    onRequestClose,
  } = props;
  const [healthDataName, setHealthDataName] = useState(
    props.healthDatainputValue || '',
  );

  const onChangeText = currentInput => {
    setHealthDataName(currentInput);
  };

  const onCancelHandler = () => {
    setHealthDataName(healthDatainputValue);
    onRequestClose();
  };

  const onSaveHandler = () => {
    handleEditHealthData(healthDataName);
  };

  return (
    <Modal visible={visibleModal} onBackdropPress={onBackdropPress}>
      <ModalLayout
        contPosition={'center'}
        popupAuto={true}
        hidePopup={onBackdropPress}
        bordeRadSm={true}
        pdCom={true}>
        <View style={[styles.flexAiCt, styles.centrTxtAlgn]}>
          <Text style={styles.textTitleBold} allowFontScaling={false}>
            Edit Health Data Name
          </Text>
          <View
            style={[
              styles.flexJcCt,
              styles.flexAiCt,
              styles.secSpPdTopMd,
              styles.deleteResourceConWidth,
            ]}>
            <TextInput
              maxLength={50}
              style={[
                styles.prefixPopupInput,
                healthDataName.trim() === '' && styles.inValidColor,
              ]}
              allowFontScaling={false}
              value={healthDataName}
              placeholder={'Enter name'}
              placeholderTextColor={'#98989A'}
              onChangeText={onChangeText}
            />
          </View>

          <View
            style={[styles.flexRow, styles.titleBlockPdTop, styles.flexJcSb]}>
            <TouchableOpacity onPress={onCancelHandler}>
              <View
                style={[
                  styles.commonBtnStyle,
                  styles.deleteResourceDeleteBtn,
                  styles.rightSpSm,
                ]}>
                <Text
                  style={[styles.textTitleBold, styles.textColorGreyLight]}
                  allowFontScaling={false}>
                  Cancel
                </Text>
              </View>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={onSaveHandler}
              disabled={!Boolean(healthDataName.length)}>
              <View
                style={[
                  styles.commonBtnStyle,
                  styles.deleteResourceCancelBtn,
                  styles.submitBgBlueDark,
                  styles.ItemRowSp,
                  !Boolean(healthDataName.length) && styles.btnBlurState,
                ]}>
                <View style={styles.btnSingleCent}>
                  <Text
                    style={[styles.textTitleBold, styles.textWhite]}
                    allowFontScaling={false}>
                    Save
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </ModalLayout>
    </Modal>
  );
};
export default EditHealthDataPopup;
