import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import ClinicalTrialMyListElement from "../../../components/Settings/ClinicalTrials/ClinicalTrialMyList/ClinicalTrialMyListElement";
import PageContainer from "../../../components/PageLayout/PageContainer";
import { getScrollHeight } from '../../../util/commonUiLogic';
class ClinicalTrialMyListScrollView extends Component {
  state = {
    items: this.props.data.slice(0, 19),
    hasMore: true,

    windowHeight: undefined,
    windowWidth: undefined
  };

  constructor(props) {
    super(props);
    const items = props.data.slice(0, 19);
    this.state = {
      items: items,
      hasMore: items.length > 0,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { windowHeight, windowWidth } = props;
    const items = props.data.slice(0, 19);
    this.setState({
      items: items,
      hasMore: items.length > 0,
      windowWidth,
      windowHeight
    });
  }

  fetchMoreData = () => {
    if (this.state.items.length >= this.props.data.length) {
      this.setState({ hasMore: false });
      return;
    } else {
      const items = this.state.items.concat(
        this.props.data.slice(
          this.state.items.length,
          this.state.items.length + 19
        )
      );

      if (items.length > this.state.items.length) {
        setTimeout(() => {
          this.setState({
            items: items
          });
        }, 1500);
      }
    }
  };

  render() {
    const { journalUserId, timeZoneWarning, timeZone } = this.props;
    const {  windowHeight, windowWidth } = this.state;
    let tabHeight = 44;
    let deleteSecHeight = 58;
    let scrollHeight = getScrollHeight(false, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth, tabHeight + deleteSecHeight);

    return (
      <div
        id="scrollableDiv"
        style={{ height: scrollHeight, overflowY: "auto" }}
      >
        <PageContainer smScreenFull={true}>
          <InfiniteScroll
            dataLength={this.state.items.length}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {this.state.items.map((item, index) => (
              <ClinicalTrialMyListElement
                key={item.id}
                myClinicalTrial={item}
              />
            ))}
          </InfiniteScroll>
          <View style={{ height: 100 }} />
          </PageContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};

export default connect(
  mapStateToProps,
  null
)(ClinicalTrialMyListScrollView);
