import React, { Component } from 'react';
import { View, Text } from 'react-native';
import styles from '../../../../Css';
import Daily from './Daily';
import Monthly from './Monthly';
import Weekly from './Weekly';
import { getSortedReminderList } from '../../../../util/commonUiLogic';
import RadioForm from '../../../../modules/radioButton/RadioButton';
import ItemDeleteButton from '../../../Button/itemDeleteButton';

const REMINDER_PROPS = [
  { label: "Daily", value: "DAILY"},
  { label: "Weekly", value: "WEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
];
class Reminder extends Component {
  constructor(props) {
    super(props);
    const { newReminders } = props;
    this.state = {
      type: null,
      reminders: newReminders ? getSortedReminderList(newReminders) : [],
      remWait: false,
      initial: null,
    };
  }

  addType = () => {
    this.setState({ add: false });
  };

  addReminder = (value, index) => {
    this.setState({ type: value, initial: index }, () => {
      setTimeout(() => {
        const { reminders } = this.state;
        const { updateNewReminders,pushNotification,emailNotification } = this.props;
        const obj = {
          id: -1,
          reminder: true,
          pushNotification: pushNotification,
          emailNotification: emailNotification,
          reminderEveryday: false,
          reminderMonday: false,
          reminderTuesday: false,
          reminderWednesday: false,
          reminderThursday: false,
          reminderFriday: false,
          reminderSaturday: false,
          reminderSunday: false,
          reminderTime: [],
          reminderMonthly: false,
          reminderMonthlyDay: null,
          reminderEnd: null,
          userArtifactType: 'Reminder',
          accept: false,
        };

        if (value === 'DAILY') {
          reminders.push({
            ...obj,
            reminderEveryday: true,
          });
        } else if (value === 'MONTHLY') {
          reminders.push({
            ...obj,
            reminderMonthly: true,
          });
        } else {
          reminders.push({
            ...obj,
          });
        }
        let newReminders = getSortedReminderList(reminders);
        this.props.setShowRadioForm(false)
        this.setState({ reminders: newReminders , initial: null}, () =>
          updateNewReminders(newReminders),
        );
        this.props.scroll && this.props.scroll(this.calculateY(value, newReminders));
      }, 500);
    });
  };

  calculateY = (value, reminders) => {
    let weekly = 0;
    let monthly = 0;
    let position = -100;
    reminders.forEach(({ reminderEveryday, reminderMonthly }) => {
      if (reminderMonthly) {
        monthly += 1;
      } else {
        weekly += 1;
      }
    });
    if (value === 'MONTHLY') {
      position += 0;
    } else if (value === 'DAILY') {
      position += ((monthly * 105) + (weekly * 110));
    } else {
      position += (monthly * 105);
    }

    return position;
  }

  updateReminders = (index, reminder) => {
    const { updateNewReminders } = this.props;
    const { reminders } = this.state;
    reminders[index] = reminder;
    this.setState({ reminders: reminders }, () => updateNewReminders(reminders));
  };

  renderReminders = (item, index, id) => {
    if (item.reminderEveryday) {
      return (
        <Daily
          data={item}
          index={index}
          key={'rem_' + index}
          updateReminders={this.updateReminders}
          windowWidth={this.props.windowWidth}
          reminderSection={this.props.reminderSection}
          deleteItem={this.deleteItem}
          id={id}
        />
      );
    } else if (item.reminderMonthly) {
      return (
        <Monthly
          data={item}
          index={index}
          key={'rem_' + index}
          updateReminders={this.updateReminders}
          windowWidth={this.props.windowWidth}
          reminderSection={this.props.reminderSection}
          deleteItem={this.deleteItem}
          id={id}
          reminderToolTip={this.props.reminderToolTip}
          routineWizardReminderCont={this.props.routineWizard}
        />
      );
    } else {
      return (
        <Weekly
          data={item}
          index={index}
          key={'rem_' + index}
          updateReminders={this.updateReminders}
          windowWidth={this.props.windowWidth}
          reminderSection={this.props.reminderSection}
          deleteItem={this.deleteItem}
          id={id}
          routineWizardReminderCont={this.props.routineWizard}
        />
      );
    }
  };

  deleteItem = index => {
    this.setState({ remWait: true }, () => {
      const { updateNewReminders } = this.props;
      let { reminders } = this.state;
      //delete reminders[index];
      reminders.splice(index, 1);
      //reminders = reminders.filter(item => item);
      this.setState({ reminders: reminders, remWait: false }, () =>
        updateNewReminders(reminders),
      );
    });
  };
  closeRadioForm = () => {
    this.props.setShowRadioForm(false)
  }
  render() {
    const { reminders, remWait } = this.state;
    const { remindersCount, hasActiveReminders,showRadioForm } = this.props;
    return (
      <React.Fragment>
        {!remWait
          ? reminders.map((item, index) =>
            this.renderReminders(item, index, remindersCount + index),
          )
          : null}

        {Boolean(showRadioForm) && (
          <View>
            {hasActiveReminders ? (
              <View style={[styles.indexSm, styles.reminderNum, styles.tpNine]}>
                <Text style={[styles.textPrime, styles.indexTxtSm]}>
                  {reminders.length + remindersCount +1}
                </Text>
              </View>
            ): reminders.length > 0 ?(
                <View style={[styles.indexSm, styles.reminderNum]}>
                  <Text style={[styles.textPrime, styles.indexTxtSm]}> 
                    {reminders.length + 1}
                  </Text>
                </View>
            ): null}
          <View style={[styles.pdTopSm, reminders.length > 0 || hasActiveReminders ? styles.messageDetail : styles.iconListNoPd]}>
          <Text style={styles.choosingTxt}>Choose one:</Text>

          <View style={styles.radioFormWrap}>
            <RadioForm
              style={[
                {
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  flexWrap: "wrap",
                },
              ]}
              radio_props={REMINDER_PROPS}
              formHorizontal={true}
              buttonColor={"rgba(152, 152, 154, 0.6)"}
              selectedButtonColor={"#FF8300"}
              onPress={this.addReminder}
              initial={this.state.initial}
              selectedLabelColor={"black"}
              labelColor={"grey"}
            />
          </View>
        </View>
            {reminders.length > 0 || hasActiveReminders ? (
              <View>
                <View style={styles.fieldColSp}></View>
                <View style={[styles.fieldColDelete, styles.reminderChooseOne]}>
                  <ItemDeleteButton
                    touchableOnPress={() => this.closeRadioForm()}
                    routinesReminderDelete={true}
                  />
                </View>
              </View>

            ) : null}
          </View>
        )}
      </React.Fragment>
    );
  }
}

export default (Reminder);
