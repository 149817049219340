export const listTypes = {
    MEDICAL_CONDITIONS: "MEDICAL_CONDITIONS",
    SYMPTOMS: "SYMPTOMS",
    TREATMENTS: "TREATMENTS",
    PERSONAL_DETAILS: "PERSONAL_DETAILS",
    LOCATION_DETAILS: "LOCATION_DETAILS"
  };

  export const INCOGNITO_MODE = 'INCOGNITO_MODE';
  export const STORY_MODE = 'STORY_MODE';
  export const USERNAME_SAVE = 'USERNAME_SAVE';
  export const EDIT_LIST = 'EDIT_LIST';
  export const CANCEL_EDIT_LIST = 'CANCEL_EDIT_LIST';
  export const SAVE_HASH = 'SAVE_HASH';

  export const COMMUNITY_CONDITION_KEY = 'communityProfileCondition';
  export const COMMUNITY_SYMPTOMS_KEY = 'communityProfileSymptoms';
  export const PERSONAL_DETAILS_KEY = 'personalDetails';
  export const LOCATION_DETAILS_KEY = 'locationDetails';
  export const COMMUNITY_TREATMENT_KEY = 'communityProfileTreatments';

  export const visibleType = {
    PUBLIC: "PUBLIC",
    PRIVATE: "PRIVATE",
  };