import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  notificationId: null,
  name: "",
  questions: [],
  surveyId: null,
  choiceQuestionsAnswers: {},
  singleQuestionsAnswers: {},
  isClosed: false,
  answers: [],
  activeTab: 0,
  answeredRatio: 0,
  modifiedOn: null,
  description: null,
  duration: null,
  s3ImageUrl: null, // This is survey image URL
  surveyQuestion: {
    fetching: false,
    data: null,
    error: null,
  },
  answerSaved: {
    fetching: false,
    data: null,
    error: null,
  },
  fromUrl: null,
};

const selectSurvey = (state, action) => {
  return updateObject(state, {
    notificationId: action.id,
    surveyId: action.surveyId,
    name: action.name,
    isClosed: action.isClosed,
    modifiedOn: action.modifiedOn,
    description: action.description,
    duration: action.duration,
    s3ImageUrl: action.s3ImageUrl,
    surveyStatus: action?.surveyStatus,
    questionOrder: action?.questionOrder,
  });
};
const setSurveyQuestions = (state, action) => {
  return updateObject(state, {
    questions: action.data.healthSurvey.questions,
  });
};

const setSurveyAnswers = (state, action) => {
  return updateObject(state, {
    answers: action.data.answerResponse
  });
};
const addRemoveSurveyChoiceQuestionsAnswer = (state, action) => {
  let answersForChoiseQuestion = {};
  if (action.add) {
    answersForChoiseQuestion[action.answersId] = action.answersId;
  } else {
    delete answersForChoiseQuestion[action.answersId];
  }

  const choiceQuestionsAnswers = state.choiceQuestionsAnswers;
  choiceQuestionsAnswers[action.questionId] = answersForChoiseQuestion;

  if (
    Object.values(answersForChoiseQuestion).length === 0 &&
    choiceQuestionsAnswers[action.questionId]
  ) {
    delete choiceQuestionsAnswers[action.questionId];
  }
  return updateObject(state, {
    choiceQuestionsAnswers: choiceQuestionsAnswers
  });
};

const addRemoveSurveySingleQuestionsAnswer = (state, action) => {
  let singleQuestionsAnswers = state.singleQuestionsAnswers;
  singleQuestionsAnswers[action.questionId] = action.value;

  return updateObject(state, {
    singleQuestionsAnswers: singleQuestionsAnswers
  });
};

const resetSurvey = (state, action) => {
  return updateObject(state, {
    notificationId: null,
    name: "",
    questions: [],
    surveyId: null,
    choiceQuestionsAnswers: {},
    singleQuestionsAnswers: {},
    isClosed: false,
    answers: [],
    description: null,
    duration: null,
    s3ImageUrl: null, // This is survey image URL
  });
};
const setActiveTab = (state, action) => {
  return updateObject(state, {
    activeTab: action.activeTab
  });
};
const fetchSurveyQuestion = (state, action) => {
  return updateObject(state, {
    surveyQuestion: {
      fetching: true,
      data: null,
      error: null,
    }
  });
};
const fetchSurveyQuestionSuccess = (state, action) => {
  return updateObject(state, {
    surveyQuestion: {
      fetching: false,
      data: action.payload,
      error: null,
    }
  });
};
const fetchSurveyQuestionFail = (state, action) => {
  return updateObject(state, {
    surveyQuestion: {
      fetching: false,
      data: null,
      error: action.payload,
    }
  });
};
const saveHealthSurveyAnswer = (state, action) => {
  return updateObject(state, {
    answerSaved: {
      fetching: true,
      data: null,
      error: null,
    }
  });
};
const saveHealthSurveyAnswerSuccess = (state, action) => {
  return updateObject(state, {
    answerSaved: {
      fetching: false,
      data: action.payload,
      error: null,
    }
  });
};
const saveHealthSurveyAnswerFail = (state, action) => {
  return updateObject(state, {
    answerSaved: {
      fetching: false,
      data: null,
      error: action.payload,
    }
  });
};
const saveHealthSurveyAnswerReset = (state) => {
  return updateObject(state, {
    answerSaved: {
      fetching: false,
      data: null,
      error: null,
    }
  });
};

const setAnsweredRatio = (state, action) => {
  return updateObject(state, {
    answeredRatio: action.isNew ? action.data * 100 : state.answeredRatio + (action.data * 100)
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_SURVEY:
      return selectSurvey(state, action);
    case actionTypes.SET_SURVEY_QUESTIONS:
      return setSurveyQuestions(state, action);
    case actionTypes.ADD_REMOVE_SURVEY_CHOICE_QUESTIONS_ANSWER:
      return addRemoveSurveyChoiceQuestionsAnswer(state, action);
    case actionTypes.ADD_REMOVE_SURVEY_SINGALE_QUESTIONS_ANSWER:
      return addRemoveSurveySingleQuestionsAnswer(state, action);
    case actionTypes.SET_SURVEY_ANSWERS:
      return setSurveyAnswers(state, action);
    case actionTypes.RESET_SURVEY:
      return resetSurvey(state, action);
    case actionTypes.SET_ACTIVE_TAB:
      return setActiveTab(state, action);
    case actionTypes.FETCH_SURVEY_QUESTION:
      return fetchSurveyQuestion(state, action);
    case actionTypes.FETCH_SURVEY_QUESTION_SUCCESS:
      return fetchSurveyQuestionSuccess(state, action);
    case actionTypes.FETCH_SURVEY_QUESTION_FAIL:
      return fetchSurveyQuestionFail(state, action);
    case actionTypes.SAVE_HEALTH_SURVEY_ANSWER:
      return saveHealthSurveyAnswer(state, action);
    case actionTypes.SAVE_HEALTH_SURVEY_ANSWER_SUCCESS:
      return saveHealthSurveyAnswerSuccess(state, action);
    case actionTypes.SAVE_HEALTH_SURVEY_ANSWER_FAIL:
      return saveHealthSurveyAnswerFail(state, action);
    case actionTypes.SAVE_HEALTH_SURVEY_ANSWER_RESET:
      return saveHealthSurveyAnswerReset(state);
    case actionTypes.SET_SURVEY_LIST_FROM_URL:
      return { ...state, fromUrl: action.payload };
    case actionTypes.SET_ANSWERD_RATIO:
      return setAnsweredRatio(state, action);
    default:
      return state;
  }
};
export default reducer;
