import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';

import StepOneCondition from './StepOneCondition';

class StepOneConditionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conditionArray: props.conditions ? props.conditions : [],
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(
      {conditionArray: props.conditions ? props.conditions : []},
      () => this.props.updateConditions(this.state.conditionArray),
    );
  }

  handleOnDeleteCondition = id => {
    //Delete condition from server
    const tempData = this.state.conditionArray;
    if (tempData[id].id !== -1) {
      this.props.onDeleteUseCondition(tempData[id].id, () => {});
    }

    tempData.splice(id, 1);
    this.setState({
      conditionArray: tempData,
    });

    this.props.updateCondition(tempData, () => {
      this.props.updateConditions(tempData);
    });
  };

  render() {
    let rows = this.state.conditionArray.map((res, i) => {
      return (
        <StepOneCondition
          condition={res}
          index={i}
          key={i}
          handleOnDeleteCondition={this.handleOnDeleteCondition}
        />
      );
    });
    return <React.Fragment>{rows}</React.Fragment>;
  }
}
const mapStateToProps = state => {
  return {
    conditions: state.setup.conditions,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateCondition: (addNewCondition, callBack) =>
      dispatch(actions.updateCondition(addNewCondition, callBack)),
    onDeleteUseCondition: (id, callBack) =>
      dispatch(actions.deleteUseCondition(id, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepOneConditionList);
