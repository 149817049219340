/**
 * In this function return below scnarios
 * if usesr add journal entry using quick note then check the entry length greater than 34 char. 
 * then titile was reduced to 31 chars. and add the ... for end of string 
 * if above requirement is not meet then check the title to 34 char. and do the above thing same
 * 
 * withour using Qucik entry to user add the journal entry then title to set the same title requirement as above
 * 
 * @param {Object} journalEntry 
 * @param {Object} journalEntryType 
 * @returns String
 */
export const showJournalTitle = (journalEntry, journalEntryType) => {

    let entryIsQuickNote = journalEntryType?.name === "Quick Note" ? true : false;
    let entry = journalEntry.entry;
    let title = journalEntry.title;

    if (entryIsQuickNote && entry && title) {
        if (entry.length > 34) {
            if (entry.substring(0, 34) === title) {
                return `${title.slice(0, -3)}...`;
            } else {
                return title.length > 34 ? `${(title.substring(0, 34)).slice(0, -3)}...` : `${title}`;
            }
        }
        // return entry.length > 34 ? title.length > 34 ? `${(title.substring(0, 34)).slice(0, -3)}...` : `${title}`;
    }
    return title.length > 34 ? `${(title.substring(0, 34)).slice(0, -3)}...` : `${title}`;
}