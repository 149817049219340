import React, { Component } from "react";
import { View, Image, Text } from "react-native";
import styles from "../../Css";
import { connect } from "react-redux";

class NoGraphFound extends Component {
  constructor(props) {
    super(props);

    const { windowHeight, windowWidth } = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth } = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  render() {
    const { windowWidth } = this.props;
    return (
      <React.Fragment>
        <View style={[styles.searchEmptyPdTop, styles.flexJcCtAiCt]}>
          <View
            style={[
              styles.graphNoItemWrapper,
              windowWidth > 767
                ? styles.graphNoItemWrapperMax
                : styles.graphNoItemWrapperFull,
            ]}
          >
            <View
              style={[
                styles.flexJcCtAiCt,
                styles.graphViewWrapper,
                styles.graphNoItemContent,
                windowWidth > 767
                  ? styles.graphNoItemContentLg
                  : styles.graphNoItemContentSm,
              ]}
            >
              <View style={[styles.infoPdBotImg]}>
                <Image
                  style={styles.graphDefaultIcon}
                  source={require("../../assets/icons/graph-default.png")}
                />
              </View>
              <View style={[styles.resultContentView]}>
                <View style={[styles.resultContentTitle]}>
                  <Text
                    style={[styles.textTitleMdBold, styles.textAlignCenter]}
                    allowFontScaling={false}
                  >
                    Graph something life changing
                  </Text>
                </View>
                {/* <View style={[styles.resultContentDesc]}>
                  <Text style={[styles.textPrime, styles.textAlignCenter]}>
                    Complete a journal entry to begin graphing
                  </Text>
                </View> */}
              </View>
            </View>
          </View>
        </View>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(mapStateToProps, null)(NoGraphFound);
