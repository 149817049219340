import * as actionTypes from './actionTypes';
import * as axiosCommon from '../../axios/axios-common';
import * as url from './urls';
import {logout, commonErrorHandler} from './index';

const DEVICES = {
    HEXOSKIN: 'HEXOSKIN',
    APPLEWATCH: 'APPLEWATCH'
  }

export const connectDevice = (deviceObj, callBack) => {
    let requestUrl = url.CONNECT_DEVICE;
    switch (deviceObj.deviceName) {
        case DEVICES.HEXOSKIN:
          requestUrl = url.CONNECT_HEXOSKIN;
          break;
        case DEVICES.APPLEWATCH:
          requestUrl = url.CONNECT_APPLEWATCH;
          break;
        default:
          break;
      }
    return dispatch => {
        axiosCommon.axiosPOST(
            requestUrl,
            res => {
                if (res.type === 'SUCCESS') {
                    callBack(true, res.responseDto.data);
                } else {
                    if (res.errorDto === 'SESSION_TIME_OUT') {
                      console.log("session time out; connectDevice");
                      dispatch(logout());
                    } else if (res.errorDto !== 'undefined') {
                      dispatch(commonErrorHandler(res.errorDto.data.error));
                      callBack(false, res.errorDto.data.error);
                    }
                  }
            },
            deviceObj,
        );
    };
};

export const connectHexoskinDeviceWithAccessCode = (deviceObj, callBack) => {
  const requestUrl = url.CONNECT_HEXOSKIN_WITH_ACCESS_CODE;
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(true, res.responseDto.data);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; connectHexoskinDeviceWithAccessCode");
            dispatch(logout());
          } else if (res.errorDto !== 'undefined') {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      deviceObj,
    );
  };
};

export const disconnectDevice = (deviceName ,callBack) => {

    let requestUrl = url.DISCONNECT_DEVICE
        .replace('{deviceName}', deviceName)

    return dispatch => {
        axiosCommon.axiosGET(
            requestUrl,
            res => {
                if (res.type === 'SUCCESS') {
                    callBack(true, res.responseDto.data);
                } else {
                    if (res.errorDto === 'SESSION_TIME_OUT') {
                      console.log("session time out; disconnectDevice");
                      dispatch(logout());
                    } else if (res.errorDto !== 'undefined') {
                      dispatch(commonErrorHandler(res.errorDto.data.error));
                      callBack(false, res.errorDto.data.error);
                    }
                  }
            }
        )
    }
};

export const setDeviceMap = deviceMap => {
    return {
      type: actionTypes.SET_DEVICE_MAP,
      userDeviceMap: deviceMap,
    };
  };

  export const updateDeviceMap = (device, status) => {
    return {
      type: actionTypes.UPDATE_DEVICE_MAP,
      device,
      status,
    };
  };