import React, { useState, useEffect, useRef } from "react";
import { View, Text, Animated, Easing, Platform } from "react-native";
import moment from 'moment';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import PickerTemplate from '../../Picker/pickerTemplateWithArrow';
import Picker from '../../Picker/picker';
import MonthlyCustomPicker from '../Picker/MonthlyCustomPicker';
import YearlyCustomPicker from '../Picker/YearlyCustomPicker';

const initMonths = [
    { label: 'Month', value: '-1', key: 'monthInit' },
    { label: 'January', value: '01', key: 'jan' },
    { label: 'February', value: '02', key: 'feb' },
    { label: 'March', value: '03', key: 'mar' },
    { label: 'April', value: '04', key: 'apr' },
    { label: 'May', value: '05', key: 'may' },
    { label: 'June', value: '06', key: 'jun' },
    { label: 'July', value: '07', key: 'jul' },
    { label: 'August', value: '08', key: 'aug' },
    { label: 'September', value: '09', key: 'sep' },
    { label: 'October', value: '10', key: 'oct' },
    { label: 'November', value: '11', key: 'nov' },
    { label: 'December', value: '12', key: 'dec' },
];

const CustomDatePicker = (props) => {
    const {selectedFromMonth, handleFromMonthSelected, selectedFromYear, handleFromYearSelected, selectedToMonth,
        handleToMonthSelected, selectedToYear, handleToYearSelect, dob, oldEventYear } = props;

    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    const [pickerMonthItemList, setPickerMonthItemList] = useState([]);
    const [pickerYearItemList, setPickerYearItemList] = useState([]);
    const spinValue = useRef(undefined);
    const mounted = useRef();

    const spin = spinValue?.current?.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '-180deg'],
    });

    useEffect(() => {
        let birthYear = dob?.split('-')[0] || oldEventYear;
        if (!mounted.current) {
            let pickerYearItems = [{ label: 'Year', value: '-1', key: 'yearInit' }];
            let currentYear = moment().year();
            for (let year = currentYear; year >= birthYear; year--) {
                pickerYearItems.push({
                    label: `${year}`,
                    value: `${year}`,
                    key: `year${year}`,
                });
            }
            setPickerMonthItemList(initMonths);
            setPickerYearItemList(pickerYearItems);
            mounted.current = true;
            }
            if (
            (selectedFromMonth !== '-1' &&
            selectedFromYear !== "-1" &&
            selectedToYear !== "-1" &&
            selectedToMonth !== "-1")
            ) {
            playWithDates();
            }
        //eslint-disable-next-line
    }, [selectedFromMonth, selectedFromYear, selectedToYear, selectedToMonth]);

    const playWithDates = () => {
      const from = (selectedFromYear && selectedFromMonth) ? `${selectedFromYear}-${selectedFromMonth}` : null;
      const to = (selectedToYear && selectedToMonth) ? `${selectedToYear}-${selectedToMonth}` : null;
      if (!from || !to) return;
      const random = Math.round(Math.random());
      if (to.localeCompare(from) < 0) {
        // if (random === 0) {
          handleFromMonthSelected("-1");
          handleFromYearSelected("-1");
        // } else {
          handleToMonthSelected("-1");
          handleToYearSelect("-1");
        // }
      }
    }
    const disableToSection = () => {
      return !Boolean(
        selectedFromMonth && 
        selectedFromMonth !== "-1" &&
        selectedFromYear &&
        selectedFromYear !== "-1"
      )
    }


    return (
        <React.Fragment>
            {/* Custom Options */}
            <View>
                <View style={[stylesRes.dividingClmsCustom, styles.flexJcCt, styles.flexAiCt, styles.contPdTop, styles.infoPd]} dataSet={{ media: ids.dividingClmsCustom }}>
                    <View style={[styles.flexRow, stylesRes.customSelecPicker]} dataSet={{ media: ids.customSelecPicker }}>
                        <View style={styles.toCustomWd}>
                            <Text style={[styles.textGreyDark, styles.textPrimeSm]}> From: </Text>
                        </View>
                        <View style={styles.flexRow}>
                            <View style={[styles.wdOneHundTwelve, styles.colPdRtNotify, styles.flexJcCt]}>
                                    <PickerTemplate>
                                        <Picker
                                            items={pickerMonthItemList}
                                            style={[
                                                styles.pickerAreaField,
                                                styles.textPrime,
                                                styles.defaultText,
                                                styles.textPickerGraph,
                                                styles.monthWdHgConainer,
                                                styles.monthWdHgConainerWeb
                                            ]}
                                            pickerFrame={'PickerFrameHealthJourneyFilter'}
                                            selectedValue={selectedFromMonth}
                                            onValueChange={(month) => handleFromMonthSelected(month)}
                                        />
                                    </PickerTemplate>
                                </View>
                            <View style={[styles.wdEightyFive, styles.colPdRtNotify, styles.flexJcCt]}>
                                <PickerTemplate>
                                    <Picker
                                        items={pickerYearItemList}
                                        style={[
                                            styles.pickerAreaField,
                                            styles.textPrime,
                                            styles.defaultText,
                                            styles.textPickerGraph,
                                            styles.monthWdHgConainer,
                                        ]}
                                        pickerFrame={'PickerFrameHealthJourney'}
                                        selectedValue={selectedFromYear !== null ? selectedFromYear : 'Year'}
                                        onValueChange={(year) => handleFromYearSelected(year)}
                                    />
                                </PickerTemplate>
                                {/* {isYearSelected ? <YearlyCustomPicker /> : null} */}
                            </View>
                        </View>
                    </View>

                    <View style={[styles.flexRow, stylesRes.customSelecPicker, { zIndex: -9 }]} dataSet={{ media: ids.customSelecPicker }}>
                        <View style={styles.toCustomWd}>
                            <Text style={[styles.textGreyDark, styles.textPrimeSm]}> To: </Text>
                        </View>
                        <View style={styles.flexRow} pointerEvents={disableToSection() ? "none" : "auto"}>
                            <View style={[styles.wdOneHundTwelve, styles.colPdRtNotify, styles.flexJcCt]}>
                                <PickerTemplate>
                                    <Picker
                                        items={pickerMonthItemList}
                                        style={[
                                            styles.pickerAreaField,
                                            styles.textPrime,
                                            styles.defaultText,
                                            styles.textPickerGraph,
                                            styles.monthWdHgConainer,
                                            styles.monthWdHgConainerWeb
                                        ]}
                                        pickerFrame={'PickerFrameHealthJourneyFilter'}
                                        selectedValue={selectedToMonth !== null ? selectedToMonth : 'Month'}
                                        onValueChange={(month) => handleToMonthSelected(month)}
                                    />
                                </PickerTemplate>
                            </View>

                            <View style={[styles.wdEightyFive, styles.colPdRtNotify, styles.flexJcCt]}>
                                <PickerTemplate>
                                    <Picker
                                        items={pickerYearItemList}
                                        style={[
                                            styles.pickerAreaField,
                                            styles.textPrime,
                                            styles.defaultText,
                                            styles.textPickerGraph,
                                            styles.monthWdHgConainer,
                                        ]}
                                        pickerFrame={'PickerFrameHealthJourney'}
                                        selectedValue={selectedToYear !== null ? selectedToYear : 'Year'}
                                        onValueChange={(year) => handleToYearSelect(year)}
                                    />
                                </PickerTemplate>
                            </View>

                        </View>
                    </View>
                </View>
                <View style={[stylesRes.brdGreMbOnly, { zIndex: -9 }]} dataSet={{ media: ids.brdGreMbOnly }} />
            </View>
        </React.Fragment>
    );
};

export default CustomDatePicker;
