import React from "react";
import Swiper from "react-native-web-swiper";
import { View, Text, Image, ScrollView } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import * as comTitle from "./titles";
import UIButton from "../Button/button";
import { TAB_BREAk_POINT_START } from "../../util/platformWindowConfig";

const startUp = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  let fullWidth = props.windowWidth;
  let fullHeight = props.windowHeight;
  let sliderHeight = 0;
  let sliderImgWidth = 0;
  let sliderImgHeight = 0;
  let sliderTotHeight = 0;
  sliderTotHeight = fullHeight - 200;
  if (sliderTotHeight > 479 && fullWidth > TAB_BREAk_POINT_START) {
    sliderHeight = 420;
    sliderImgWidth = 320;
    sliderImgHeight = 320;
  } else if (sliderTotHeight > 574) {
    sliderHeight = 520;
    sliderImgWidth = 420;
    sliderImgHeight = 420;
  } else if (sliderTotHeight > 479) {
    sliderHeight = 380;
    sliderImgWidth = 280;
    sliderImgHeight = 280;
  } else if (sliderTotHeight > 374) {
    sliderHeight = 360;
    sliderImgWidth = 260;
    sliderImgHeight = 260;
  } else {
    sliderHeight = 300;
    sliderImgWidth = 200;
    sliderImgHeight = 200;
  }

  return (
    <ScrollView style={styles.containerFull}>
      <View style={[styles.gridSection]}>
        <View
          style={[
            stylesRes.startUpSmWeb,
            { minHeight: fullHeight }
          ]}
          dataSet={{media: ids.startUpSmWeb}}
        >
          <View style={stylesRes.startUpLogoSecSm} dataSet={{media: ids.startUpLogoSecSm}}>
            <View style={stylesRes.startUpLogoView} dataSet={{media: ids.startUpLogoView}}>
              <Image
                source={require("../../assets/logo/logo.png")}
                style={styles.logoStartup}
                resizeMode={"contain"}
              />
            </View>
          </View>

          <View 
            style={[stylesRes.startUpLeftBlockWeb, stylesRes.zIndexHigh]}
            dataSet={{media: ids.startUpLeftBlockWeb}}>
            <View
              style={stylesRes.startUpLeftBlockContWeb}
              dataSet={{media: ids.startUpLeftBlockContWeb}}
            >
              <View style={stylesRes.startUpLogoSecWeb} dataSet={{media: ids.startUpLogoSecWeb}}>
                <Image
                  source={require("../../assets/logo/logo.png")}
                  style={styles.logoStartup}
                  resizeMode={"contain"}
                />
              </View>
              <View
                style={[
                  stylesRes.startUpPgSliderContWeb,
                  { height: sliderHeight }
                ]}
                dataSet={{media: ids.startUpPgSliderContWeb}}
              >
                <View style={stylesRes.startUpPgSliderWeb} dataSet={{media: ids.startUpPgSliderWeb}}>
                  <Swiper
                    index={0}
                    autoplayTimeout={5}
                    loop={true}
                    dotStyle={{
                      backgroundColor: "#F4ECD7",
                      width: 10,
                      height: 10,
                      borderRadius: 7,
                      marginLeft: 7,
                      marginRight: 7
                    }}
                    activeDotStyle={{
                      backgroundColor: "#FF8300",
                      width: 10,
                      height: 10,
                      borderRadius: 7,
                      marginLeft: 7,
                      marginRight: 7
                    }}
                    prevButtonText={""}
                    nextButtonText={""}
                  >
                    <View
                      style={[styles.slideContainerLgScreen, styles.slide1]}
                    >
                      <View style={{ width: sliderImgWidth, height: sliderImgHeight }}>
                        <Image
                          source={require("../../assets/home-sliders/startup-slider-1.png")}
                          style={[styles.imgContain]}
                        />
                      </View>
                      <View style={stylesRes.sliderContMax} dataSet={{media: ids.sliderContMax}}>
                        <View style={stylesRes.sliderInfoPd} dataSet={{media: ids.sliderInfoPd}}>
                          <Text
                            style={[
                              styles.textPrime,
                              styles.textAlignCenter,
                              styles.textGrey
                            ]}
                            allowFontScaling={false}
                          >
                            Reduce information overload. {"\n"}Our service keeps
                            you organized. {"\n"} Be empowered!
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={[styles.slideContainer, styles.slide2]}>
                      <View style={{ width: sliderImgWidth, height: sliderImgHeight }}>
                        <Image
                          source={require("../../assets/home-sliders/startup-slider-2.png")}
                          style={[styles.imgContain]}
                        />
                      </View>
                      <View style={stylesRes.sliderContMax} dataSet={{media: ids.sliderContMax}}>
                        <View style={stylesRes.sliderInfoPd} dataSet={{media: ids.sliderInfoPd}}>
                          <Text
                            style={[
                              styles.textPrime,
                              styles.textAlignCenter,
                              styles.textGrey
                            ]}
                            allowFontScaling={false}
                          >
                            Analyze your therapies and symptoms. Advocate to
                            improve your quality of life.
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={[styles.slideContainer, styles.slide3]}>
                      <View style={{ width: sliderImgWidth, height: sliderImgHeight }}>
                        <Image
                          source={require("../../assets/home-sliders/startup-slider-3.png")}
                          style={[styles.imgContain]}
                        />
                      </View>
                      <View style={stylesRes.sliderContMax} dataSet={{media: ids.sliderContMax}}>
                        <View style={stylesRes.sliderInfoPd} dataSet={{media: ids.sliderInfoPd}}>
                          <Text
                            style={[
                              styles.textPrime,
                              styles.textAlignCenter,
                              styles.textGrey
                            ]}
                            allowFontScaling={false}
                          >
                            Generate reports and share health information
                            securely.
                          </Text>
                        </View>
                      </View>
                    </View>
                  </Swiper>
                </View>
              </View>
            </View>
          </View>
          <View 
            style={[stylesRes.startUpMidBarBlockWeb, stylesRes.zIndexHigh]}
            dataSet={{media: ids.startUpMidBarBlockWeb}}>
            <Image
              source={require("../../assets/home-sliders/startup-bg.png")}
              style={{
                width: "100%",
                height: "100%"
              }}
              resizeMode={"stretch"}
            />
          </View>
          <View style={stylesRes.startUpRightBlockWeb} dataSet={{media: ids.startUpRightBlockWeb}}>
            <View style={stylesRes.startUpRightBlockContWeb} dataSet={{media: ids.startUpRightBlockContWeb}}>
              <View style={stylesRes.startUpPgContWeb} dataSet={{media: ids.startUpPgContWeb}}>
                <View style={stylesRes.startUpMessage} dataSet={{media: ids.startUpMessage}}>
                  <Text
                    style={[styles.textTitleMainLgBold, styles.textAlignCenter]}
                    allowFontScaling={false}
                  >
                    Understand trends in your symptoms and treatment regimes
                    with a time-tracked electronic journal.
                  </Text>
                </View>
                <View style={styles.startUpPgBtnSec}>
                  <View style={stylesRes.startUpBtnWeb} dataSet={{media: ids.startUpBtnWeb}}>
                    <View>
                      <UIButton
                        touchableStyle={[styles.submit, styles.submitRgSm]}
                        touchableOnPress={props.handleOnSignup}
                        textStyle={styles.submitText}
                        textTitle={comTitle.LETS_GET_STARTED}
                      />
                    </View>
                  </View>
                </View>
                <View style={stylesRes.startUpSmDesc} dataSet={{media: ids.startUpSmDesc}}>
                  <Text style={styles.textTitleSm} allowFontScaling={false}>
                    Already have an account?{" "}
                  </Text>
                  <UIButton
                    touchableOnPress={props.handleTouchableOnPress}
                    textStyle={[styles.textTitleMdBold, styles.textOrangeLight]}
                    textTitle={comTitle.LOGIN}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default (startUp);
