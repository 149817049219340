import { countryNameMap } from "./commonUiLogic";

const findCountry = (strings, country) => {
    const countryName = countryNameMap[country];
    const lowercaseCountry = countryName.toLowerCase();
    if (strings.toLowerCase().includes(lowercaseCountry)) {
        return true;
    } else {
        return false;
    }
}

const findCity = (strings, city) => {

    if (strings.toLowerCase().includes(city.toLowerCase())) {
        return true;
    } else {
        return false;
    }
}

const countryAndCityFind = (city, country, strings) => {
    if (city && country && findCountry(strings, country) && findCity(strings, city)) {
        return true;
    } else if (!city && country && findCountry(strings, country)) {
        return true;
    } else if (!country && city && findCity(strings, city)) {
        return true;
    } else if (!country && !city) {
        return true;
    } else {
        return false;
    }
}

export const filterLocaions = (city, country, item) => {

    if (item.locations && item.locations.location && Array.isArray(item.locations.location)) {

        const locationsPresent = item.locations.location.filter(a => {

            const found = countryAndCityFind(city, country, a);

            return found;

        });

        if (locationsPresent && locationsPresent.length) {
            item.locations.location = locationsPresent;
            return item;
        } else {
            return null;
        }

    } else if (item.locations && item.locations.location && typeof item.locations.location === "string") {
        const found = countryAndCityFind(city, country, item.locations.location);

        if (found) {
            item.locations.location = [item.locations.location];
            return item;
        } else {
            return null;
        }

    } else if (item.locations && item.locations && typeof item.locations === "string") {
        const found = countryAndCityFind(city, country, item.locations);

        if (found) {
            item.locations = { location: [item.locations] };
            return item;
        } else {
            return null;
        }
    }



}

const getActualCount = (findings) => {

    let count = 0;

    for (const element of findings) {
        if (element && element.locations && element.locations.location) {
            count = count + element.locations.location.length;
        } else {
            count = count + 1;
        }

    }

    return count;

}

export const clinicalTrailFormatter = (searchTearm, search_results) => {

    try {
        const { city = null, country = null } = searchTearm;

        const responce = {
            count: 0,
            query: search_results.query,
            study: []
        }

        // console.log('search_results', search_results);

        // if (!city && !country) return search_results;

        if (search_results.study && Array.isArray(search_results.study) && !search_results.study.length) {
            return responce;
        } else if (search_results.study && Array.isArray(search_results.study) && search_results.study.length) {

            const final = search_results.study.map((item) => {
                const res = filterLocaions(city, country, item);
                return res;
            }).filter(a => a);

            // console.log('final', final);
            // console.log('getActualCount', getActualCount(final));

            responce.count = getActualCount(final);
            responce.study = final;

            return responce;

        } else if (search_results.study && !Array.isArray(search_results.study) && search_results.study.locations) {
            const res = filterLocaions(city, country, search_results.study);

            if (res) {
                responce.count = 1;
                responce.study = [res];
            } else {
                return responce;
            }

        } else {
            return responce;
        }

    } catch (error) {
        return search_results;
    }

}