import React, { Component } from "react";
import { View, TextInput } from "react-native";
import styles from "../../Css";

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: props.notes
    };
  }

  onChangeNote = notes => {
    this.setState(
      {
        notes: notes
      },
      () => {
        this.props.updateNotes(notes);
      }
    );
  };
  render() {
    return (
      <View style={[styles.collapseContent, styles.colBg, styles.colPdSm]}>
        <View style={styles.gridRow}>
          <View style={styles.gridColFull}>
            <View style={[styles.fieldRow]}>
              <TextInput
                style={[
                  styles.textField,
                  styles.textFieldSm,
                  styles.textFieldWhite,
                  styles.textAreaMax
                ]}
                allowFontScaling={false}
                placeholder="Enter a new note"
                onChangeText={this.onChangeNote}
                value={this.state.notes}
                autoFocus={this.state.notes ? false : true}
                multiline={true}
                numberOfLines={2}
                underlineColorAndroid="transparent"
              />
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default (Notes);
