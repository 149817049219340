import React, {Component} from 'react';
import {
    View,
    ScrollView,
    Text,
    Image,
    TouchableOpacity,
    Linking,
    Platform,
} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Header from '../../components/Header/header';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import PrimaryLinkButton from '../../components/Button/primaryLinkButton';
import LearnHowPrivacyPolicy from '../SignUp/LearnHowPrivacyPolicy';
import {HELP} from '../../navigation/path';
import {Swipe, Emailus, Textus, Callus} from '../../components/Contactus';
import {
    CONTACT_PHONE_NUMBER,
    ORGANIZATION_URL_SUPPORT,
    ORGANIZATION_DOMAIN,
    ORGANIZATION_NAME
} from '../../util/commonUiConfig';
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import {pendoTrackEvent} from '../../util/pendoConfig';
import Videolink from "../../components/Contactus/videolink";
import { WEB } from '../../util/platformWindowConfig';


class Contactus extends Component {
    constructor(props) {
        super(props);
        const {windowWidth, windowHeight} = props;

        this.state = {
            windowWidth: windowWidth,
            windowHeight: windowHeight,
            showLearnHowPrivacyPolicy: false,
        };
    }

    componentDidMount() {
        pendoTrackEvent('Tutorials+Help@');
    }

    static getDerivedStateFromProps(props, state) {
        const {windowWidth, windowHeight} = props;

        if (
            windowWidth !== state.windowWidth ||
            windowHeight !== state.windowHeight
        ) {
            return {
                windowWidth: windowWidth,
                windowHeight: windowHeight,
            };
        }

        return null;
    }

    handleOnPressBackButton = () => {
        handleCommonBackNavigation(this.props.navigation);
    };

    toggleLearnHowPrivacyPolicy = () => {
        pendoTrackEvent('Tutorials+Help@ > LearnHow');
        this.setState({showLearnHowPrivacyPolicy: true});
    };

    render() {
        const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
        const {windowWidth} = this.props;
        return (
            <View style={[styles.pageBodyWrapper]}>
                <Header
                    showFilers={false}
                    showBack={true}
                    showClearFilters={false}
                    showNotify={true}
                    handleOnPressBackButton={this.handleOnPressBackButton}
                    index={HELP.index}
                    navigation={this.props.navigation}
                    route={this.props.route}
                />
                <SpaceBar/>
                <ScrollView style={styles.scrollViewArea}>
                    <View style={styles.gridSection}>
                        <View style={stylesRes.contentSectionMax} dataSet={{media: ids.contentSectionMax}}>
                            <View style={stylesRes.contBotPd} dataSet={{media: ids.contBotPd}}>
                                <View style={styles.gridRow}>
                                    <View style={styles.gridColFull}>
                                        <View style={styles.colPd}>
                                            <View
                                                style={[
                                                    stylesRes.boxMaxWrapper,
                                                    windowWidth > 767
                                                        ? stylesRes.boxMaxWrapperSm
                                                        : stylesRes.boxMaxWrapperLg,
                                                ]}>
                                                <View
                                                    style={[
                                                        styles.tutorialBlock,
                                                        styles.detailBoxWrapper,
                                                        styles.greyBoxBorder,
                                                    ]}>
                                                    <View style={[styles.detailTitleBlock]}>
                                                        <Text
                                                            style={[
                                                                styles.textTitleBold,
                                                                styles.textAlignCenter,
                                                            ]}
                                                            allowFontScaling={false}
                                                        >
                                                            {ORGANIZATION_NAME} Online Tutorials
                                                        </Text>
                                                    </View>
                                                    <View style={[styles.detailInfoBlock]}>
                                                        <Text
                                                            style={[
                                                                styles.textPrime,
                                                                styles.textColorLight,
                                                                styles.textAlignCenter,
                                                            ]}
                                                            allowFontScaling={false}
                                                        >
                                                            Let us help you learn the best ways to use{" "}
                                                            {ORGANIZATION_NAME} with our step-by-step videos.
                                                        </Text>
                                                    </View>

                                                    <View
                                                        style={[
                                                            styles.detailImgBlock,
                                                            styles.flexJcCtAiCt,
                                                        ]}>
                                                        <Image
                                                            source={require('../../assets/contact/help.png')}
                                                            style={styles.helpComImg}
                                                        />
                                                    </View>

                                                    <View style={[styles.detailInfoBlock]}>
                                                        <Text
                                                            style={[
                                                                styles.textPrime,
                                                                styles.textColorLight,
                                                                styles.textAlignCenter,
                                                            ]}
                                                            allowFontScaling={false}
                                                        >
                                                            Watch the following videos to learn more about using {ORGANIZATION_NAME}{" "}
                                                            or click the link at the bottom to see all of our support resources!
                                                        </Text>
                                                    </View>

                                                    <View style={[styles.flexJcCtAiCt]}>
                                                        <Videolink
                                                            nativeId={'HowToCreateAnEntryLink'}
                                                            eventName={'Tutorials+Help@ > HowToCreateAnEntry'}
                                                            videoDescription={'How to create an entry'}
                                                            link={'https://www.youtube.com/watch?v=UkA2xO8TT68'}
                                                        />
                                                    </View>

                                                    <View style={[styles.flexJcCtAiCt]}>
                                                        <Videolink
                                                            nativeId={'HowToAddAttachmentsLink'}
                                                            eventName={'Tutorials+Help@ > HowToAddAttachments'}
                                                            videoDescription={'How to add attachments'}
                                                            link={'https://www.youtube.com/watch?v=gSyjCk9PFf0'}
                                                        />
                                                    </View>

                                                    <View style={[styles.flexJcCtAiCt]}>
                                                        <Videolink
                                                            nativeId={'HowToAddMedsAndSupplementsLink'}
                                                            eventName={'Tutorials+Help@ > HowToAddMedsAndSupplements'}
                                                            videoDescription={'How to add meds and supplements'}
                                                            link={'https://www.youtube.com/watch?v=qUdbuhuU-Us'}
                                                        />
                                                    </View>

                                                    <View style={[styles.flexJcCtAiCt]}>
                                                        <Videolink
                                                            nativeId={'HowToCreateARoutineLink'}
                                                            eventName={'Tutorials+Help@ > HowToCreateARoutine'}
                                                            videoDescription={'How to create a routine'}
                                                            link={'https://www.youtube.com/watch?v=KU8xfI7dmgE'}
                                                        />
                                                    </View>

                                                    <View style={[styles.flexJcCtAiCt]}>
                                                        <Videolink
                                                            nativeId={'HowToUseTheHealthLibraryLink'}
                                                            eventName={'Tutorials+Help@ > HowToUseTheHealthLibrary'}
                                                            videoDescription={'How to use the health library'}
                                                            link={'https://www.youtube.com/watch?v=6lDapWysiIU'}
                                                        />
                                                    </View>

                                                    <View style={[styles.flexJcCtAiCt]}>
                                                        <Videolink
                                                            nativeId={'HowToAddAResourceIntoTheHealthLibraryLink'}
                                                            eventName={'Tutorials+Help@ > HowToAddAResourceIntoTheHealthLibrary'}
                                                            videoDescription={'How to add a resource to your health library'}
                                                            link={'https://www.youtube.com/watch?v=AzvqGO3-XPw'}
                                                        />
                                                    </View>

                                                    <View style={[styles.btnArea, styles.flexJcCtAiCt]}>
                                                        <PrimaryLinkButton
                                                            btnTextMain={'For more videos, go to'}
                                                            btnTextSub={` ${ORGANIZATION_DOMAIN}`}
                                                            touchableOnPress={() => {
                                                                pendoTrackEvent('Tutorials+Help@ > WebsiteLink');
                                                                const url = ORGANIZATION_URL_SUPPORT;
                                                                if (Platform.OS === WEB) {
                                                                    window.open(url);
                                                                } else {
                                                                    Linking.openURL(url);
                                                                }
                                                            }}
                                                            nativeId={'tutorialsHelpWebsiteLink'}
                                                        />
                                                    </View>
                                                </View>

                                                <View
                                                    style={[
                                                        styles.contactBlock,
                                                        styles.detailBoxWrapper,
                                                        styles.greyBoxBorder,
                                                    ]}>
                                                    <View style={[styles.detailTitleBlock]}>
                                                        <Text style={[styles.textTitleBold]} allowFontScaling={false}>
                                                            Contact Us
                                                        </Text>
                                                    </View>
                                                    <View style={[styles.detailInfoBlockSm]}>
                                                        <Text
                                                            style={[styles.textPrime, styles.textColorLight]}
                                                            allowFontScaling={false}>
                                                            We will get back to you within 48 hours
                                                        </Text>
                                                    </View>

                                                    <View style={[styles.detailItemBlock]}>
                                                        <Emailus
                                                            nativeId={'tutorialsHelpEmailLink'}
                                                            eventName={'Tutorials+Help@ > EmailLink'}
                                                        />

                                                        <Textus phoneNumber={` ${CONTACT_PHONE_NUMBER}`}/>
                                                        <Callus phoneNumber={` ${CONTACT_PHONE_NUMBER}`}/>
                                                    </View>
                                                </View>
                                                
                                                {Platform.OS !== WEB && <Swipe/>}

                                                <View
                                                    style={[styles.linkWrapper, styles.secSepBorderTop]}>
                                                    <View style={[styles.contColFul]}>
                                                        <View
                                                            style={[
                                                                styles.contComText,
                                                                styles.flexRowJcCtAiCt,
                                                            ]}>
                                                            <Text
                                                                style={[
                                                                    styles.textPrimeSm,
                                                                    styles.textAlignCenter,
                                                                    styles.textGreyDark,
                                                                ]}
                                                                allowFontScaling={false}
                                                            >
                                                                Your privacy is our priority.{" "}
                                                            </Text>
                                                            <TouchableOpacity
                                                                style={[styles.mgLtExSm]}
                                                                onPress={this.toggleLearnHowPrivacyPolicy}>
                                                                <Text
                                                                    nativeID={'tutorialsHelpLearnHow'}
                                                                    style={[
                                                                        styles.textPrimeSm,
                                                                        styles.textOrange,
                                                                        styles.textAlignCenter,
                                                                    ]}
                                                                    allowFontScaling={false}
                                                                >
                                                                    Learn How
                                                                </Text>
                                                            </TouchableOpacity>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </ScrollView>
                <LearnHowPrivacyPolicy
                    showLearnHowPrivacyPolicy={this.state.showLearnHowPrivacyPolicy}
                    toggleLearnHowPrivacyPolicy={() => {
                        this.setState({showLearnHowPrivacyPolicy: false});
                    }}
                    windowHeight={this.state.windowHeight}
                />
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        windowWidth: state.uistate.windowWidth,
        windowHeight: state.uistate.windowHeight,
        visibleGraph: state.rollinggraphs.dashboardGraph,
        dashboardProcessed: state.routines.dashboardProcessed,
        journalUserId: state.uistate.journalUserId,
        hasNotch: state.uistate.hasNotch,
        applicationSettingsDigest: state.routines.applicationSettingsDigest,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        isFromLogin: () => dispatch(actions.isFromLogin()),
        resetRollingGraph: () => dispatch(dispatch(actions.resetRollingGraph())),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Contactus);
