import React, { useState, useEffect, useRef, Fragment } from 'react';
import {
  Image, View, Text, ScrollView, TouchableOpacity, Animated, Easing,
  UIManager, SafeAreaView, LayoutAnimation, Platform, TextInput
} from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash';
import Header from '../../../components/Header/header';
import SpaceBar from '../../../components/SpaceBar/SpaceBar';
import PageContainer from '../../../components/PageLayout/PageContainer';
import styles from '../../../Css';
import stylesResponsive from "../../../CssResponsive";
import { HEALTH_JOURNEY } from '../../../navigation/path';
import * as actions from '../../../store/actions/index';
import SelectCategoryTypeFilter from '../../../components/HealthJourney/Picker/SelectCategoryTypeFilter';
import SelectedOptionPicker from '../../../components/HealthJourney/Picker/SelectedOptionPicker';
import MobileCategoriesFilterComponent from './ExpandableViews/MobileCategoriesFilterComponent'
import { sortAlphabeticalOrder } from '../../../util/commonUiLogic';
import AdvancedFilterSingleItem from '../../../components/HealthJourney/DropDown/AdvancedFilterSingleItem'
import ClearAllFilter from '../../../components/HealthJourney/ClearAllFilter/ClearAllFilter';
import { ANDROID } from '../../../util/platformWindowConfig';

const AVAILABLE_OPTIONS = 2;
const CATEGORY = 1;
const CUSTOM = 0;

const MobileFilter = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const { selectedAvancedFilterItems, onSetAdvancedFilterList, advancedFilterAttachments, setOnOpenFilter,
    fetchHealthJourneyTagsList, setAdvancedFilteringAttachmentsData, setHealthJourneyTagsAndAttachmentsObject, setDropDownItemNumber, removeDropDownItemNumber, selectedDropdownItemNumber,
    setHealthJourneyCategoryObject, saveHealthJourneyTagsObject, saveHealthJourneyCategoryObject, listTherapie, setSelectedAdvancedFilterItems, setAdvancedFilterCheckedItems, clearAllAdvancedFilteres, fetchAllHealthJourneyEvents} = props;

  // ~~ custom filter releated useStates and refs. ~~
  const [hasTagsDropDownOpen, setTagsDropDownOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [isUncheckAll, setIsUncheckAll] = useState(false);

  // set this values to redux
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [tagsOrAttachmentsListData, setTagsOrAttachmentsListData] = useState(null);
  const [advancedFilterDropDownText, setAdvancedFilterDropDownText] = useState('Select Tags');
  const [filterType, setFilterType] = useState(null);

  // ~~ select Categories releated useStates and refs. ~~
  const { selectedAllCategoriesItems, onCategoryFilterItems } = props;
  const [categoryList, setCaregoryList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // set this values to redux
  const [loadSelectedCategoriesToView, setLoadSelectedCategoriesToView] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);
  const [selectedSubCategoryNames, setSelectedSubCategoryNames] = useState([]);
  const [openedId, setOpenedId] = useState("");
  const [advancedFilterList, setAdvancedFilterList] = useState([{}]);
  const selectedQueue = useRef([])
  //  ---------------- Common functions ------------------------------
  const spinValue = useRef(undefined);
  const categoryRef = useRef(undefined);
  const customRef = useRef(undefined);

  const [itemCount, setItemCount] = useState(0);
  const {fetching: fetchingProps, data} = fetchAllHealthJourneyEvents;

  if (Platform.OS === ANDROID) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }

  const updateLayout = (index) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    const array = [...categoryList];
    array.map((value, placeindex) =>
      placeindex === index
        ? (array[placeindex]['isExpanded'] =
          !array[placeindex]['isExpanded'])
        : (array[placeindex]['isExpanded'] = false),
    );
    setCaregoryList(array);
  };

  useEffect(() => {
    const { navigation, hideMobileMenu } = props;
    navigation.addListener('focus', () => {
      hideMobileMenu()
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleButtonClickability();
    //eslint-disable-next-line
  }, [selectedCategories, selectedTags, selectedAttachments]);

  const handleOnPressBackButton = () => {
    const { navigation } = props;
    navigation.reset({
      index: 0,
      routes: [{ name: HEALTH_JOURNEY.path }],
    });
  }

  const spin = spinValue?.current?.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-180deg'],
  });

  useEffect(() => {
    if (spinValue.current) {
      Animated.timing(spinValue.current, {
        toValue: hasTagsDropDownOpen ? 0 : 1,
        duration: 160,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start();
    } else {
      spinValue.current = new Animated.Value(1);;
    }
  }, [hasTagsDropDownOpen]);

  const onApplyHandler = () => {
    const list = Object.assign([], advancedFilterList);
    const filter = [];
    list.forEach(item => {
      const {type, data} = item;
      if (!type) return
      let obj = {
        filterType: type === "TAGS" ? "Tag" : 'Attachments',
        data: data.filter(e => e.isChecked)
      };
      filter.push(obj);
    })
    if (filter.length > 0)
      setAdvancedFilterCheckedItems(filter);
    setHealthJourneyCategoryObject(selectedCategories);
    const [first, last] = selectedQueue.current;
    first !== undefined && setDropDownItemNumber(first);
    last !== undefined && setDropDownItemNumber(last);
    if (!selectedQueue.current.includes(CUSTOM)) {
      removeDropDownItemNumber(CUSTOM);
      setHealthJourneyTagsAndAttachmentsObject({});
      clearAllAdvancedFilteres();
    } 
    if (!selectedQueue.current.includes(CATEGORY)) {
      removeDropDownItemNumber(CATEGORY);
      setHealthJourneyCategoryObject({});
    } 
    // selectedQueue.current.forEach(
    //   async num =>
    //     await new Promise(r => {
    //       setDropDownItemNumber(num);
    //       setTimeout(r, 500);
    //     }),
    // );
    // [CATEGORY, CUSTOM]
    //   .filter(e => !selectedQueue.current.includes(e))
    //   .forEach(
    //     async num =>
    //       await new Promise(r => {
    //         removeDropDownItemNumber(num);
    //         setTimeout(r, 500);
    //       }),
    //   );
      handleOnPressBackButton();
  }

  const clearAllCheckboxes = () => {
    setIsButtonDisabled(true);
    setIsUncheckAll(true);
    setLoadSelectedCategoriesToView([]);
    setSelectedTags([]);
    setSelectedAttachments([]);
    setSelectedCategories([]);
    setHealthJourneyTagsAndAttachmentsObject({});
    setHealthJourneyCategoryObject({});
    setAdvancedFilterList([{}]);
    clearAllAdvancedFilteres();
    removeDropDownItemNumber(CATEGORY);
    removeDropDownItemNumber(CUSTOM);
    // [CATEGORY, CUSTOM]
    //   .filter(e => !selectedQueue.current.includes(e))
    //   .forEach(
    //     async num =>
    //       await new Promise(r => {
    //         removeDropDownItemNumber(num);
    //         setTimeout(r, 500);
    //       }),
    //   );
  }

  const handleButtonClickability = () => {
    if (isButtonDisabled) {
      if (!_.isEmpty(selectedCategories) || !_.isEmpty(loadSelectedCategoriesToView) ||
        !_.isEmpty(selectedTags) || !_.isEmpty(selectedAttachments)) {
        setIsButtonDisabled(false);
      }
    } else {
      setIsButtonDisabled(false);
    }
  }

  // ------------------------------------- end -----------------------------


  // ---------------------- category filter --------------------------------

  useEffect(() => {
    createDropDownCategoryList();
    //eslint-disable-next-line
  }, []);

  const createDropDownCategoryList = () => {
    // remove nulls from the array
    const therapieList = listTherapie.filter(function (item) { return item.name !== null && item.treatmentType !== 5 && item.isActive});

    const categoryObj = [
      // {
      //     category_name: 'All Categories',
      //     subcategory: [],
      // },
      {
        category_name: 'Documents/Images',
        value: 'ATTACHMENTS',
        subcategory: [],
      },
      {
        category_name: 'Meds/Supplements',
        value: 'MEDICATIONS',
        subcategory: sortAlphabeticalOrder(therapieList),
      },
      // {
      //     category_name: 'Symptoms',
      //     value: 'SYMPTOMS',
      //     subcategory: [],
      // },
      // {
      //     category_name: 'Health Data',
      //     value: 'HEALTH_DATA',
      //     subcategory: [],
      // },
      {
        category_name: 'Custom Notes',
        value: 'CUSTOM_NOTES',
        subcategory: [],
      },
    ];

    setCaregoryList(categoryObj);
  }

  useEffect(() => {
    const tempCategories = [...loadSelectedCategoriesToView]
    if (saveHealthJourneyCategoryObject.data.length > 0) {
      for (const selectedCategory of saveHealthJourneyCategoryObject.data) {
        tempCategories.push(selectedCategory)
      }
    }

    // setLoadSelectedCategoriesToView(tempCategories);
    setSelectedCategories(tempCategories);
    categoryRef.current = tempCategories;
    if (selectedDropdownItemNumber && selectedDropdownItemNumber.length > 0)
      selectedQueue.current = selectedDropdownItemNumber.filter(num => num === 1 || num === 0);

//eslint-disable-next-line
  }, []);

  const updateSelectedCategoriesObj = (obj, category) => {
    let newList = [...selectedCategories];
    if (obj.categoryName !== undefined && obj.categoryName !== null) {
      const existItem = newList.findIndex(item => item.categoryName === obj.categoryName);
      if (existItem !== -1) {
        newList[existItem].subCategoryList = obj.subCategoryList;
        // obj.subCategoryList.filter((item, i) => existItem.subCategoryList[i] = item);
      } else {
        newList.push(obj);
      }
    } else {
      newList = [...newList.filter(item => item.value !== category)]
    }
    setSelectedCategories(newList);
    setIsUncheckAll(false);
  }

 const newItemCount = () => {
    let subCatCount = 0;
    let count = 0;
    let added = false;
    let countAd = 0;
    let arr1 = []; 
    let arr2 = [];
    let countNew = 0;
 //calculating count of subs categories
 if(selectedCategories.length > 0){
  for(const item of selectedCategories){
    if(item.subCategoryList.length > 0) subCatCount += item.subCategoryList.length
    else subCatCount += 1
  }
 }
 //filtering the isChecked===true values in the advancedFilterList
    arr1 = advancedFilterList.map((el)=>{
    arr2 = el.data?.filter((item)=>{
    return item.isChecked === true
    })
    .map((item)=>item.isChecked)
    countNew = countNew + arr2?.length
    })
  if(selectedCategories.length >= 0 && countNew > 0) {
        count = countNew + subCatCount;
        setItemCount(count);
        added = true;
  }
    setItemCount(count + countAd + (added ? 0 : subCatCount));
    return itemCount;
};
  // ----------------------------------end -----------------------------------------


  // ----------------------------- Advanced Filter ---------------------------------

  useEffect(() => {
    setAdvancedFilteringAttachmentsData();
    if (setSelectedAdvancedFilterItems && setSelectedAdvancedFilterItems.length > 0) {
      const filterListArray = [];
      const extra = [];
      setSelectedAdvancedFilterItems.forEach(item => {
        const {filterType, data} = item;
        if (filterType === 'Attachments')
          advancedFilterAttachments.forEach(item => {
            if (!data.some(e => e.category_name === item.category_name))
              extra.push({...item, isChecked: false})
          })
        let obj = {
          type: filterType === "Tag" ? "TAGS" : "ATTACHMENTS",
          data: [...data, ...extra],
          placeholder: filterType === "Tag" ? "Select Tags" : "Select Types",
        }
        filterListArray.push(obj);
      });
      setAdvancedFilterList(filterListArray)
      customRef.current = filterListArray;
      fetchTags(filterListArray);
    } else {
      fetchTags([]);
    }

    let tagArray = [];
    async function fetchTags(preData) {
        const fetchData = await new Promise((resolve, reject) =>
            fetchHealthJourneyTagsList('tags', 'HJ', (isFetched, data) => {
                if (isFetched) {
                    const healthJourneyTags = data?.healthJourney ? data?.healthJourney : [];
                    resolve(healthJourneyTags)
                } else {
                    reject(data)
                }
            }));

        fetchData.sort();
        if (fetchData !== null && fetchData !== undefined) {
          let count = 0;
          for (let item of fetchData) {
            ++count;
            const id = Date.now() + count;
            tagArray.push({ category_name: item, isChecked: false, id });
          }
        }
        setTags(tagArray);
        const tagIndex = preData.findIndex(item => item.type === "TAGS");
        if (tagIndex !== -1) {
          const data = Object.assign([], preData[tagIndex].data)
          let tagsItem = Object.assign({}, preData[tagIndex])
          let fullList = Object.assign([], preData)
          const rowTags = data.map(item => item.category_name);
          fetchData.forEach((item, i) => {
            if (!rowTags.includes(item)) {
              const id = Date.now() + i;
              data.push({ category_name: item, isChecked: false, id });
            }
          }) 
          tagsItem = {
            ...tagsItem,
            data: data.sort((a, b) => a?.category_name.localeCompare(b?.category_name))
          }
          fullList[tagIndex] = tagsItem
          setAdvancedFilterList([...fullList])
          customRef.current = fullList;
        }
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!_.isEqual(selectedCategories, categoryRef.current)) {
      if (selectedCategories.length === 0) {
        selectedQueue.current = [...selectedQueue.current.filter(e => e !== CATEGORY)]
      } else {
        if (!selectedQueue.current.includes(CATEGORY)) {
          selectedQueue.current.push(CATEGORY)
        }
      }
      categoryRef.current = selectedCategories;
    }
    if (!_.isEqual(advancedFilterList, customRef.current)) {
      if (advancedFilterList.some(item => item?.data?.some(e => e.isChecked))) {
        if (!selectedQueue.current.includes(CUSTOM)) {
          selectedQueue.current.push(CUSTOM)
        }
      } else {
        selectedQueue.current = [...selectedQueue.current.filter(e => e !== CUSTOM)]
      }
      customRef.current = advancedFilterList;
    }
  }, [selectedCategories, advancedFilterList])


  

  useEffect(() => {
    if (!_.isEmpty(saveHealthJourneyTagsObject.data)) {
      if (saveHealthJourneyTagsObject.data[0].filterType !== undefined
        && saveHealthJourneyTagsObject.data[0].filterType === 'Tag') {
        setSelectedTags(saveHealthJourneyTagsObject.data[0].data);
      }
      if (saveHealthJourneyTagsObject.data[1].filterType !== undefined
        && saveHealthJourneyTagsObject.data[1].filterType === 'Attachments') {
        setSelectedAttachments(saveHealthJourneyTagsObject.data[1].data);
      }
    }
    //eslint-disable-next-line
  }, []);
  // ----------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    const tagsObject = saveHealthJourneyTagsObject.data;
    const categoryObject = saveHealthJourneyCategoryObject.data;
    let subCatCount = 0;
    let count = 0;
    let added = false;
    let countAd = 0;
    if(saveHealthJourneyCategoryObject.data.length > 0){
      for(const item of saveHealthJourneyCategoryObject.data){
        if(item.subCategoryList.length > 0) subCatCount += item.subCategoryList.length
        else subCatCount += 1
      }
    }
    if(saveHealthJourneyCategoryObject.data.length > 0 && saveHealthJourneyTagsObject.data.length > 0) {
      count = tagsObject[0].data.length + tagsObject[1].data.length +
                categoryObject.length + subCatCount;
                setItemCount(count);
      added = true;
    }
    if (setSelectedAdvancedFilterItems && setSelectedAdvancedFilterItems.length > 0) {
      setSelectedAdvancedFilterItems.forEach(item => {
        countAd += item.data.length;
      })
    }
    setItemCount(count + countAd + (added ? 0 : subCatCount));
  },[saveHealthJourneyTagsObject, saveHealthJourneyCategoryObject, setSelectedAdvancedFilterItems]);

  // ------------------------------------------------------------------------------------------------------------------------------------------
  const filterTypesSelectionHandler = (selectedFilterType, index) => {
    // setFilterType(selectedFilterType);
    setSelectedTags(selectedFilterType);
    const clone = Object.assign([], advancedFilterList);
    let selectedOne = {
      type: selectedFilterType,
      ...getDataList(selectedFilterType),
    };
    clone[index] = selectedOne;
    setAdvancedFilterList(clone);
  }

  const getDataList = type => {
    switch (type) {
      case "TAGS":
        return {data: tags, placeholder: "Select Tags"};
      case "ATTACHMENTS":
        return {data: advancedFilterAttachments, placeholder: "Select Types"};
      default:
        return {data: [], placeholder: "Unknown"};
    }
  }

  const toggleTagOrAttachmentCheckBox = (isSelected, itemName) => {
    if (filterType === 'TAG') {
      if (isSelected && !selectedTags.includes(itemName))
        setSelectedTags([...selectedTags, itemName]);
      if (!isSelected && selectedTags.includes(itemName))
        setSelectedTags(selectedTags.filter(item => item !== itemName));
    } else if (filterType === 'ATTACHMENTS') {
      if (isSelected && !selectedAttachments.includes(itemName))
        setSelectedAttachments([...selectedAttachments, itemName]);
      if (!isSelected && selectedAttachments.includes(itemName))
        setSelectedAttachments(selectedAttachments.filter(item => item !== itemName));
    } else {
      // yet to be implemented -- 
    }
  }

  const maximumFilterCountChecker = () => {
    if (advancedFilterList.length === AVAILABLE_OPTIONS)
      return true;
    return Boolean(enableApplyButton());
  }

  const enableApplyButton = () => {
    return advancedFilterList.find(item => _.isEmpty(item) || !item.data.find(e => e.isChecked))
  }

  const onSearchSubCategory = (searchKey) => {
    let searchedItem = tags.filter(function (item) {
        if (item.category_name.toLowerCase().includes(searchKey.toLowerCase())) {
            return item;
        }
    });
    setTagsOrAttachmentsListData(searchedItem);
  }

  const handleAddFilter = () => {
    const array = Object.assign([], advancedFilterList);
    array.push({});
    setAdvancedFilterList(array);
    setOpenedId(9999);
  }

  const removeFilter = idx => {
    const array = Object.assign([], advancedFilterList);
    array.splice(idx, 1);
    if (array.length === 0)
      array.push({});
    setAdvancedFilterList(array);
  }

  const checkAll = (index, flag) => {
    const clone = Object.assign([], advancedFilterList);
    const data = Object.assign([], clone[index].data);
    let selectedOne = {
      ...clone[index],
      data: data.map(e => ({...e, isChecked: flag})),
    };
    clone[index] = selectedOne;
    setAdvancedFilterList(clone);
  };

  const checkOne = (flag, subIdx, mainIdx, name) => {
    const clone = Object.assign([], advancedFilterList);
    const data = Object.assign([], clone[mainIdx].data);
    const foundHit = data.findIndex(d => d.category_name === name && d.id === subIdx);
    if (foundHit !== -1) 
      data[foundHit] = {...data[foundHit], isChecked: flag};
    let selectedOne = {
      ...clone[mainIdx],
      data,
    };
    clone[mainIdx] = selectedOne;
    setAdvancedFilterList(clone);
  };

  const enableButtons = () => {
    return !Boolean(
      selectedCategories.length > 0 || 
      !advancedFilterList.some(item => _.isEmpty(item) || !item.data.find(e => e.isChecked))
    )
  }

  const setOpenFilters = () => {
    if (openedId === 9999) return;
    setOpenedId(9999);
  }

  const { navigation, route } = props;
  return (
    <View style={styles.pageBodyWrapper} onStartShouldSetResponder={() => setOpenFilters()}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={false}
        hideCaregiverAccess={true}
        customHeading="Filter Events"
        handleOnPressBackButton={() => handleOnPressBackButton()}
        index={HEALTH_JOURNEY.index}
        navigation={navigation}
        route={route}
      />
      <SpaceBar />
      <ScrollView contentContainerStyle={{flexGrow: 1}}>
        <View style={styles.gridSection}>
          <PageContainer>

            <View>
              <View  >
                {/* Select Category Topic */}
                <View >
                <View style={[styles.flexRow, styles.progressBarTopLg, styles.pdBtTwelve, styles.pdLtZero,{justifyContent:"space-between"}]}>
                  <Text style={styles.textTitleBold}> Select Categories </Text>
                  {/* Clear all filter component */}
                  <ClearAllFilter clearAllFilterHandler={clearAllCheckboxes} windowWidth={props.windowWidth} newItemCount={newItemCount}/>
                </View>
                <View>
              
                </View>
                {/* All Categories Select */}
                <View style={styles.colBgLight}>
                  {/* need a fix -----  */}
                  {/* <View style={[styles.flexRow, styles.flexJcFs, styles.flexAiCt, styles.menuSingleTextViewSm, styles.comSpExSm, styles.seperateLineEachCatSec]}>
                    <View>
                      <TickButton
                        imagePath={'untickButton'}
                        tickStyle={[styles.tickStyleCom, { marginRight: 2 }]}
                        styleType={'curve'}
                      />
                    </View>
                    <View style={styles.textPrime}>
                      <Text> All Categories </Text>
                    </View>
                  </View> */}
                </View>
                {/* <View style={[stylesRes.filtervisible,{flex:1,flexDirection:"row"}, hasEvents ? {opacity: 0.4}: null]} dataSet={{ media: ids.filtervisible }} pointerEvents={openFilters ? "none" : "auto"}>
                  <TouchableOpacity disabled={hasEvents} onPress={() => clearAllCheckboxes()}>
                    <Image
                      style={[styles.filterIcn]}
                      source={require('../../../assets/health-journey-icons/filter-icon.png')}
                    />
                    {itemCount > 99 ? (
                    <View style={styles.filterCount}>
                      <Text style={[styles.textExMicSm, styles.fiveHudrredFtWgt, styles.textWhite]}>{`99+`}</Text>
                    </View>
                    ):(
                      <View style={styles.filterCount}>
                      <Text style={[styles.textExMicSm, styles.fiveHudrredFtWgt, styles.textWhite]}>{`${ itemCount}`}</Text>
                    </View>
                    )
                    }
                  </TouchableOpacity>
                </View> */}
        </View>
                {/* Individual Category Types */}

                <View pointerEvents={openedId < advancedFilterList.length ? "none" : "auto"}>
                  <ScrollView nestedScrollEnabled={true}>
                    {categoryList.map((item, key) => (
                      <MobileCategoriesFilterComponent
                        key={item.category_name}
                        onClickFunction={() => {
                          updateLayout(key);
                        }}
                        item={{
                          ...item,
                          subcategory: [...new Map(item.subcategory.map(item => [item["name"], item])).values()]
                        }}
                        isUncheckedAll={isUncheckAll}
                        setClearAllCheckboxes={(val) => clearAllCheckboxes(val)}
                        getSelectedCategoriesObj={(obj) => updateSelectedCategoriesObj(obj, item.value)}
                        selectedCategories={selectedCategories.filter(res => res.value === item.value)}
                      />
                    ))}
                  </ScrollView>

                </View>
              </View>
            </View>


            {/* to reduce the unneccessay render cycles --> custom filter view added */}
            <View>
              {/* DROPDOWN */}
              <View>
                {/* include text and select input boxes */}
                <View>
                  <View style={[styles.comMgTopSm, styles.btnMgMd, styles.noMgImg]}>
                    <Text style={styles.textTitleBold}> Custom Filters </Text>
                  </View>
                  {advancedFilterList.map((item, index) => (
                    <Fragment key={index}>
                      <AdvancedFilterSingleItem
                        onSeleceFilter={filterTypesSelectionHandler}
                        index={index}
                        selectedMain={advancedFilterList.map(e => e.type)}
                        item={item}
                        removeFilter={removeFilter}
                        checkAll={checkAll}
                        checkOne={checkOne}
                        openedId={openedId}
                        setOpenedId={setOpenedId}
                        fromMobileFilter
                      />
                    </Fragment>
                  ))}
                </View>
                {/* dropdowns */}
                <View>
                  {Boolean(hasTagsDropDownOpen) && Boolean(tagsOrAttachmentsListData) ?
                    <View>
                      <SelectedOptionPicker
                        data={tagsOrAttachmentsListData}
                        tickedList={filterType === 'TAG' ?
                          selectedTags : selectedAttachments}
                        filterType = {filterType}
                        toggleTickCallback={(isSelected, itemName) => toggleTagOrAttachmentCheckBox(isSelected, itemName)}
                        onSearchSubCategory = {(searchKey) => onSearchSubCategory(searchKey)}
                      />
                    </View>
                    : null}
                </View>
              </View>

              {/* BOTTOM CTA OPTIONS */}
              <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt, styles.contPdTop, styles.mgLtEighteen, styles.mgRtFifteen, { zIndex: -9 }]}>
                <TouchableOpacity onPress={handleAddFilter} disabled={maximumFilterCountChecker()}>
                  <View style={[maximumFilterCountChecker() && { opacity: 0.5 }]}>
                    <Text style={[styles.textPrime, styles.seaBluTxtColor, styles.textUnderline]}>
                      + Add Filter
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>

          </PageContainer>
        </View>
        <View style={{height:300, zIndex: -9}}/>
      </ScrollView >

      <View style={[styles.btZero, styles.infoPdBotImg, styles.bgWhite, styles.wdFullPer]}>
        <View style={[styles.borderBotGrey, styles.wdHundredPercent, styles.btnMgLg, styles.noMgTop]}></View>

        <View style={[styles.flexRow, styles.flexJcCt]}>
          <View style={[styles.dragNdropSectionOuterWrapper, styles.flexRow]}>
            <TouchableOpacity  onPress={() => handleOnPressBackButton()}>
              <View style={[
                styles.wdHundredFifty,
                styles.fourtyHg,
                styles.bgWhite,
                styles.brdGreyDarkWdOne,
                styles.borderTpLtRadius,
                styles.borderTpRtRadius,
                styles.borderBtLtRadius,
                styles.borderBtRtRadius,
                styles.flexAiCt,
                styles.flexJcCt,
                styles.mgRtSix
              ]}>
                <Text style={styles.textPrimeLgBold}> Cancel</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity disabled={enableButtons()} onPress={() => onApplyHandler()}>
              <View style={[
                styles.wdHundredFifty,
                styles.fourtyHg,
                enableButtons() ? styles.newEventSveBtnDisbaled : styles.bgHealthJourneyDeleteBtn,
                styles.borderTpLtRadius,
                styles.borderTpRtRadius,
                styles.borderBtLtRadius,
                styles.borderBtRtRadius,
                styles.flexAiCt,
                styles.flexJcCt,
                styles.mgRtSix
              ]}>
                <Text style={[styles.textPrimeLgBold, styles.textWhite]}> Apply </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View >
  );
};

const mapStateToProps = state => {
  return {
    fetchHealthJourneyTagsList: state.healthJourney.fetchHealthJourneyTagsList,
    advancedFilterAttachments: state.healthJourneyFilterObjects.advancedFilterAttachments,
    saveHealthJourneyTagsObject: state.healthJourney.saveHealthJourneyTagsObject,
    saveHealthJourneyCategoryObject: state.healthJourney.saveHealthJourneyCategoryObject,
    listTherapie: state.routines.treatments,
    setSelectedAdvancedFilterItems: state.healthJourneyFiltering.setSelectedAdvancedFilterItems,
    selectedDropdownItemNumber: state.healthJourneyFiltering.selectedDropdownItemNumber,
    fetchAllHealthJourneyEvents: state.healthJourney.fetchAllHealthJourneyEvents,
    windowWidth: state.uistate.windowWidth,
    setSelectDateOption: state.healthJourneyFiltering.setSelectDateOption,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    fetchHealthJourneyTagsList: (filterKey, serviceType, callback) =>
      dispatch(actions.fetchHealthJourneyTagsList(filterKey, serviceType, callback)),
    setAdvancedFilteringAttachmentsData: () =>
      dispatch(actions.setAdvancedFilteringAttachmentsData()),
    setHealthJourneyTagsAndAttachmentsObject: (tagsAndAttachmentsObj) =>
      dispatch(actions.setHealthJourneyTagsAndAttachmentsObject(tagsAndAttachmentsObj)),
    setHealthJourneyCategoryObject: (categoriesObj) =>
      dispatch(actions.setHealthJourneyCategoryObject(categoriesObj)),
    setAdvancedFilterCheckedItems: (advancedFilterList) =>
      dispatch(actions.setAdvancedFilterCheckedItems(advancedFilterList)),
    setDropDownItemNumber: (itemNumber) => 
      dispatch(actions.setDropDownItemNumber(itemNumber)),
    removeDropDownItemNumber: (itemNumber) => 
      dispatch(actions.removeDropDownItemNumber(itemNumber)),
    clearAllAdvancedFilteres: () =>
      dispatch(actions.clearAllAdvancedFilteres()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MobileFilter);