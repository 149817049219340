import React from "react";
import { Text, Image, View } from "react-native";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from "react-native-popup-menu";
import styles from "../../Css";
import { pendoTrackEvent } from "../../util/pendoConfig";

const { Popover } = renderers;

const reportPopupMenu = (props) => {
  return (
    <Menu renderer={Popover} rendererProps={{ preferredPlacement: "left" }}>
      <MenuTrigger onPress={() => pendoTrackEvent(props.eventNameMenuTrigger)}>
        <View 
          style={[styles.itemIconSingle, styles.itemSettingMenu]}
          nativeID={props.nativeIdMenuTrigger}>
          <Image
            style={[styles.settingIcon, styles.settingIconDark,
            props.disableText
              ? styles.iconInactiveColor
              : styles.iconActiveTintColor,
            ]}
            source={require("../../assets/icons/settings.png")}
          />
        </View>
      </MenuTrigger>
      {!Boolean(props.disableText) &&
        <MenuOptions style={styles.dropDownList}>
          <View style={{padding: 1}} nativeID={props.nativeIdDownload}>
          <MenuOption style={styles.liItem} onSelect={props.onDownloadReport}>
            <Text style={styles.listText} allowFontScaling= {false}>Download</Text>
          </MenuOption>
          </View>
          <View style={{padding: 1}} nativeID={props.nativeIdSendasEmail}>
          <MenuOption style={styles.liItem} onSelect={props.onSendEmail}>
            <Text style={styles.listText} allowFontScaling= {false}>Send as email</Text>
          </MenuOption>
          </View>
          <View style={{padding: 1}} nativeID={props.nativeIdEdit}>
          <MenuOption style={styles.liItem} onSelect={props.onEdit}>
            <Text style={styles.listText} allowFontScaling= {false}>Edit</Text>
          </MenuOption>
          </View>
          <View style={{padding: 1}} nativeID={props.nativeIdDelete}>
          <MenuOption style={styles.liItem} onSelect={props.onDelete}>
            <Text style={styles.listText} allowFontScaling= {false}>Delete</Text>
          </MenuOption>
          </View>
        </MenuOptions>
      }
    </Menu>
  );
};
export default reportPopupMenu;
