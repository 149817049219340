import React, { Fragment, useState, useEffect } from "react";
import { View } from "react-native";

import HealthJourneyListItem from "./HealthJourneyListItem";
import HealthJourneyYearCollapse from "./HealthJourneyYearCollapse"

const HealthJourneyList = props => {
  const { data = {}, editEvent, deleteEvent, setIdForExport, exportedList, exportOnGoing, updateLayoutDimentions, updateYearLayoutDimentions, onPressViewItem,
    disbleOperations, sortFilterValue, isFiltersAvailable, handleOpenHealthJourneyList } = props;
  
  const sort = (a, b) => {
    if (sortFilterValue)
      return parseInt(b) - parseInt(a)
    return parseInt(a) - parseInt(b)
  }

  const [openYearArr, setOpenYearArr] = useState([]);
  const [dataState, setDataState] = useState({});
  const [isAvailbleFilter, setIsAvailbleFilter] = useState(false);

  useEffect(() => {

    if (isFiltersAvailable && !isAvailbleFilter && data && Object.keys(data).length) {

      handleAllYears(Object.keys(data));
      setIsAvailbleFilter(true);
      setDataState(data);
    }
  }, [0])

  useEffect(() => {

    if (isFiltersAvailable && !isAvailbleFilter && data && Object.keys(data).length && JSON.stringify(openYearArr) !== JSON.stringify(Object.keys(data))) {
      handleAllYears(Object.keys(data));
      setIsAvailbleFilter(true);
      setDataState(data);
    } else if (!isFiltersAvailable && isAvailbleFilter) {
      setIsAvailbleFilter(false);
      reverseCheckOpenArray(dataState, data);
    } else if (JSON.stringify(data) !== JSON.stringify(dataState)) {
      reverseCheckOpenArray(dataState, data);
    }
  }, [isFiltersAvailable, JSON.stringify(data), JSON.stringify(openYearArr), isAvailbleFilter])

  const handleAllYears = (data) => {
    setOpenYearArr(data);
  }

  const reverseCheckOpenArray = (preState, newData) => {
    if (JSON.stringify(preState) !== JSON.stringify(newData) && openYearArr.length) {

      const newYearArry = openYearArr.filter(y => y && Object.keys(newData).includes(y));
      setOpenYearArr(newYearArry);
      setDataState(newData);
    } else if (JSON.stringify(preState) !== JSON.stringify(newData)) {
      setDataState(newData);
    }
  }


  const onYearHanlder = (sYear, previoslyOpen) => {

    if (previoslyOpen && sYear) {
      let arr = [...openYearArr];
      arr = arr.filter(y => y !== sYear);
      setOpenYearArr(arr);
    } else if (!previoslyOpen && sYear) {
      let arr = [...openYearArr];
      arr.push(sYear);
      setOpenYearArr(arr);
    }
  }

  return (
    <Fragment>

      {
        data && Object.keys(data) && Object.keys(data).length ?
          (
            <HealthJourneyYearCollapse
              year={!openYearArr.length ? "Expand All" : "Collapse All"}
              open={openYearArr.length}
              onPress={() => { handleAllYears(openYearArr.length ? [] : Object.keys(data)) }}
              maxTouchableWidth={true}
            />
          ) : null
      }



      {data &&
        Object.keys(data).length > 0 &&
        Object.keys(data).sort(sort).map((key, index) => (
          <View key={key} style={{zIndex: 99999 - index}} onLayout={e => updateYearLayoutDimentions(key, e.nativeEvent.layout)}>
            <HealthJourneyListItem
              year={key}
              data={data[key]}
              editEvent={editEvent}
              deleteEvent={deleteEvent}
              updateLayoutDimentions={updateLayoutDimentions}
              setIdForExport={setIdForExport}
              exportedList={exportedList}
              exportOnGoing={exportOnGoing}
              onPressViewItem={onPressViewItem}
              disbleOperations={disbleOperations}
              isFiltersAvailable={isFiltersAvailable}
              handleOpenHealthJourneyList={handleOpenHealthJourneyList}
              AllData={Object.keys(data)}
              onYearHanlder={onYearHanlder}
              forceOpen={openYearArr.length && openYearArr.includes(key) ? true : false}
              cardNumber = {index}
            />
          </View>
        ))}
    </Fragment>
  );
};

export default HealthJourneyList;
