import { Share } from 'react-native';
import { ORGANIZATION_NAME } from './commonUiConfig';
import { SHARE_URL } from '../store/actions/urls';

export const handleReferFriend = () => {
  shareOption(
    {
      title: `Manage your health journey with ${ORGANIZATION_NAME}.`,
      message: `Manage your health journey with ${ORGANIZATION_NAME}. You can journal your health data, track your medications, upload your health documents and much more, all in a single app. Join the ${ORGANIZATION_NAME} movement for free ${SHARE_URL}`
    }, (sus) => {
      console.log("success : ", sus)
    }, (err) => {
      console.log("error : ", err)
  }); 
}

export const shareOption = (data, onShare, onError) => {
  try {
    Share.share(
      {
        title: data.title,
        message: data.message,
      }, {
        subject: data.title,
      }
    ).then(result => {
      if (onShare) onShare(result);
    }).catch(err => {
      if (onError) onError(err)
    });
  } catch (err) {
    if (onError) onError(err)
  }
};
