import React from 'react';
import { Platform } from 'react-native';
import { connect } from 'react-redux';
import AddImagesDocuments from '../../components/ImagesDocumentsForAttachment/addImagesDocuments';
import Cropper from '../../components/ImageCropper/AmazingCropperPage';
import SavingConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import SuccessBox from '../../components/ConfirmationBox/successBox';
import UploadProgress from '../../components/ImagesDocumentsForAttachment/uploadProgress';
import CancelUploadConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import AddImagesDocumentsCommon from './AddImagesDocumentsCommon';
import * as actions from '../../store/actions/index';
import { IMAGES_DOCUMENTS } from '../../navigation/path';
import { getAccountType } from '../../util/subscriptionInformation';
import { attachementTitle } from "../../GlobalFunctions/GlobalString";
import { pendoTrackEvent } from '../../util/pendoConfig';
import { WEB } from '../../util/platformWindowConfig';

class AddImagesDocumentsScreen extends AddImagesDocumentsCommon {
  constructor(props) {
    super(props);
    const accountType = getAccountType(props.userSubscriptions);
    this.state = {
      uploadError: false,
      files: {},
      images: {},
      uploadErrorMesage: null,
      fileSize: 0,
      index: 0,
      confirmBox: false,
      visibleEntryModal: false,
      visibleImageTakePhoto: false,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      accountType: accountType,
      isFileReading: false,
    };
    this.addImagesDocumentsRef = null;
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      const { route, hideMobileMenu, emailVerified } = this.props;
      this.addImagesDocumentsRef = this;

      this.getFileAndImage(
        Object.values(this.props.files),
        (files, images, index, fileSize) =>
          this.setState({files, images, index, fileSize}),
      );
      hideMobileMenu();
      if (route?.params?.fromSubPath) {
        return;
      } else if (!route?.params?.state?.length && emailVerified) {
        this.handleAddPressedForUpload();
      } else if (Platform.OS === WEB){
        this.handleDragAndDropFileSelect(route?.params?.state);
      }
      pendoTrackEvent('Attachments/@UploadFile');
    });
    this.props.navigation.addListener('blur', () => {
      if (Platform.OS !== WEB) {
        this.setState({ showSpinner: false, confirmBox: false, cancelConfirmBox: false });
      }
      this.componentWillUnmount();
    });
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth} = props;
    if (
      state.windowHeight !== windowHeight||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }
    return null;
  }

  blobToFile = (theBlob, fileName) => {
    theBlob.filename = fileName;
    theBlob.name = fileName;
    return theBlob;
  };

  handleDeleteAttachment = id => {
    const filterData = this.state.files;
    const filterimages = this.state.images;
    delete filterData[id];
    delete filterimages[id];
    this.setState({
      files: filterData,
      images: filterimages,
    });
  };

  handleModalOnClickCancel = () => {
    this.setState({
      confirmBox: false
    }, () => {
      this.props.clearFilesToAttach();
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: IMAGES_DOCUMENTS.path }],
      });
    });
  };

  handleCancelAddImageDocument = () => {
    if (Object.values(this.state.files).length > 0) {
      this.setState({confirmBox: true});
    } else {
      this.handleModalOnClickCancel();
    }
  };

  componentWillUnmount() {
    this.props.showMobileMenu();
  }

  render() {
    return (
      Platform.OS !== WEB && this.state.showCropper 
      ? <Cropper 
          image={this.state.image} 
          close={this.closeCroper} 
        />
      : <React.Fragment>
          <AddImagesDocuments
            onChangeFileUpload={this.onChangeFileUpload}
            uploadError={this.state.uploadError}
            uploadErrorMesage={this.state.uploadErrorMesage}
            files={this.state.files}
            images={this.state.images}
            handleDeleteAttachment={this.handleDeleteAttachment}
            redirectAttachImagesDocumentsToContact={
              this.redirectAttachImagesDocumentsToContact
            }
            redirectAttachImagesDocumentsToTreatment={
              this.redirectAttachImagesDocumentsToTreatment
            }
            redirectAttachImagesDocumentsToJournal={
              this.redirectAttachImagesDocumentsToJournal
            }
            onSaveFilesToAttach={this.onSaveFilesToAttach}
            handleOnPressBackButton={this.handleCancelAddImageDocument}
            visibleEntryModal={this.state.visibleEntryModal}
            onRequestClose={() => {
              this.setState({visibleEntryModal: false});
            }}
            onBackdropPress={() => this.setState({visibleEntryModal: false})}
            onPressClose={() => {
              this.setState({visibleEntryModal: false});
              if (!Boolean(Object.keys(this.state.files).length)) {
                this.props.navigation.reset({
                  index: 0,
                  routes: [{ name: IMAGES_DOCUMENTS.path }],
                });
              }
            }}  
            onPressTakePhoto={() => {
              if (Platform.OS === WEB) {
                pendoTrackEvent('Attachments > Takephoto');
                this.setState({
                  visibleImageTakePhoto: true,
                  visibleEntryModal: false,
                });
              } else {
                this.takePhoto()
              }
            }}
            selected={this.state.selected}
            visibleImageTakePhoto={this.state.visibleImageTakePhoto}
            onRequestCloseImg={() =>
              this.setState({
                visibleImageTakePhoto: false,
                selected: null,
              })
            }
            onBackdropPressImg={() =>
              this.setState({
                visibleImageTakePhoto: false,
                selected: null,
              })
            }
            close={() => {
              this.setState({visibleImageTakePhoto: false, selected: null});
            }}
            cropSuccess={() => {
              this.setState({visibleImageTakePhoto: false});
            }}
            handleCreateNewRoutine={this.handleCreateNewRoutine}
            showSpinner={this.state.showSpinner}
            visibleUploadTypeModal={this.state.visibleUploadTypeModal}
            onRequestCloseUploadTypeModal={() => {
              this.setState({visibleUploadTypeModal: false});
            }}
            onBackdropPressUploadTypeModal={() =>
              this.setState({visibleUploadTypeModal: false})
            }
            onPressCancel={() => {
              this.setState({visibleUploadTypeModal: false});
            }}
            selectDocumentType={this.selectDocumentType}
            index={IMAGES_DOCUMENTS.index}
            navigation={this.props.navigation}
            route={this.props.route}
            onAddPressedForUpload={this.handleAddPressedForUpload}
            onAddImageDocumentCancelPress={this.handleCancelAddImageDocument}
            onFileRename={this.handleFileRename}
            windowHeight={this.props.windowHeight}
            windowWidth={this.props.windowWidth}
            addImagesDocumentsRef={this.addImagesDocumentsRef}
            onTakePhotoCancel={() => {
              setTimeout(() => {
                this.setState({visibleImageTakePhoto: false});
              }, 1000);
            }}
            onDragAndDropFileSelect={this.handleDragAndDropFileSelect}
            isFileReading={this.state.isFileReading}
            handleOnPressCropButton={this.openCroppingPage}
            setImageToCrop={this.setImageToCrop}
            croppedUri={this.state.croppedUri}
            tempCapturedImages={this.state.tempCapturedImages}
            cancelCropping={this.state.cancelCropping}
          />
          {this.state.confirmBox &&
            <SavingConfirmation
              visibleModal={this.state.confirmBox}
              onBackdropPress={() => {
                this.setState({confirmBox: false});
              }}
              onRequestClose={() => {
                this.setState({confirmBox: false});
              }}
              alertTitle={attachementTitle}
              handleCancel={()=>this.handleModalOnClickCancel()}
              handleConfirm={() => {
                this.setState({confirmBox: false});
                this.handleCreateNewRoutine();
              }}
              confirmWait={this.state.showSpinner}
              disabled={this.state.showSpinner}
            />
          }
          {this.state.successModalVisible &&
            <SuccessBox
              visibleModal={this.state.successModalVisible}
              messageTitle="Success!"
              message="Attachments uploaded"
            />
          }
          {Platform.OS !== WEB && this.state.showSpinner &&
            <UploadProgress
              visibleModal={this.state.showSpinner}
              progress={this.state.progress}
              onPressCancel={() => {
                this.setState({showSpinner: false}, () => {
                  setTimeout(() => {
                    this.setState({cancelConfirmBox: true});
                  }, 500);
                });
              }}
            />
          }
          {this.state.cancelConfirmBox &&
            <CancelUploadConfirmation
              visibleModal={this.state.cancelConfirmBox}
              onBackdropPress={() => this.closeCanceConfirmation()}
              onRequestClose={() => this.closeCanceConfirmation()}
              alertTitle={'Cancel Upload?'}
              alertBody={'Your items are still uploading, are you sure you want to cancel?'}
              btnTextOne={'Cancel Upload'}
              btnTextTwo={'Continue Upload'}
              handleCancel={()=> {
                const artifactId = this.state.artifactId;
                  this.setState({
                    cancelConfirmBox: false,
                    showSpinner: false,
                    progress: 0,
                    artifactId: null
                  }, () => {
                    this.props.cancelUploadAttachmentProcess(
                      artifactId,
                      this.props.journalUserId,
                      res => {
                        this.handleModalOnClickCancel();
                      }
                    );
                  });
              }}
              handleConfirm={() => this.closeCanceConfirmation()}
            />
          }
        </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSaveFilesToAttach: files => dispatch(actions.saveFilesToAttach(files)),
    onUpload: (
      artifactId,
      formData,
      callBack,
      reload,
      journalUserId,
      fileSize,
      uploadConfig,
      cancelToken,
      uniqueId,
    ) =>
      dispatch(
        actions.uploadAttachmen(
          artifactId,
          formData,
          callBack,
          reload,
          journalUserId,
          fileSize,
          uploadConfig,
          cancelToken,
          uniqueId,
        ),
      ),
    onSaveJournalEntry: (journalEntry, callBack, journalUserId) =>
      dispatch(actions.saveJournalEntry(journalEntry, callBack, journalUserId)),
    clearFilesToAttach: () => dispatch(actions.clearFilesToAttach()),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),

    showHideStorageBanner: val => dispatch(actions.showHideStorageBanner(val)),
    setAttachmentUploadStatus: status => dispatch(actions.setAttachmentUploadStatus(status)),
    setAttachmentArtifactId: id => dispatch(actions.setAttachmentArtifactId(id)),
    setAttachmentFullSize: size => dispatch(actions.setAttachmentFullSize(size)),
    showUploadProgress: () => dispatch(actions.showUploadProgress()),
    cancelUploadAttachmentProcess: (artifactId, journalUserId, callback) => 
      dispatch(actions.cancelUploadAttachmentProcess(artifactId, journalUserId, callback)),
  };
};
const mapStateToProps = state => {
  return {
    files: state.imagesDocuments.files,
    journalUserId: state.uistate.journalUserId,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    user: state.routines.user,
    userSubscriptions: state.routines.userSubscription,
    emailVerified: state.auth.emailVerified,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddImagesDocumentsScreen);
