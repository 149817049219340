import React, { Fragment, useState, useEffect, useRef } from "react";
import { View, Text, Image, TextInput, TouchableOpacity, Platform } from 'react-native';
import styles from '../../../Css';
import ViewMoreText from '../../../components/ViewMoreText/index';
import * as constants from '../HealthJourneyConstant';

const ViewEventDescription = (props) => {
    const { description: descProps } = props;

    const [description, setDescription] = useState(null);
    const [showDescriptionBox, setShowDescriptionBox] = useState(false);
    const [height, setHeight] = useState(40);

    useEffect(() => {
        if (description !== descProps) {
            setDescription(descProps)
        }
    }, [descProps])

    const handleOnPressDescription = () => {
        setShowDescriptionBox(!showDescriptionBox);
    }



    return (
        <Fragment>
            {!showDescriptionBox ? (
                <View style={[styles.flexRow, styles.comMgTopSm, styles.flexAiCt]}>
                    <View
                        style={[styles.pdRtSeven, styles.mgLtFive, styles.hudredPercentWd]}
                    >
                        <ViewMoreText
                            onPressMore={handleOnPressDescription}
                            longText={description}
                            moreText={'Read more'}
                            lessText={'Read less'}
                            numberOfLines={2}
                            customStyle={[styles.seaBluTxtColor, styles.statusBarText]}
                        />
                    </View>
                </View>
            ) :
                (
                    <View style={styles.mgTopLg}>
                        <TextInput
                            style={[styles.textPrime,
                            styles.addEvntTxtInput,
                            { height: height },
                            {
                                ...Platform.select({
                                    android: {
                                        textAlignVertical: "top"
                                    },
                                })
                            }]}
                            multiline={true}
                            value={description || ""}
                            maxLength={constants.DESCRIPTION_MAX_LENGTH}
                            onContentSizeChange={(e) => {
                                setHeight(e.nativeEvent.contentSize.height);
                            }}

                            disabled={true}
                        />
                        <TouchableOpacity onPress={handleOnPressDescription}>
                            <View style={[
                                styles.flexRow,
                                styles.closeIconView,
                                { marginBottom: 25 }
                            ]}>
                                <Text style={[styles.textPrimeBold, styles.seaBluTxtColor, styles.textUnderline]}>
                                    Read less
                                </Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                )}
        </Fragment>
    );
};

export default ViewEventDescription;