import React, { Component } from "react";
import { Image, View, Text, TouchableOpacity, TextInput, ScrollView } from "react-native";
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
class MonthlyCustomPicker extends Component {
    render(){
        const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
        return (
        <React.Fragment>  
        <View style={[styles.wdNintyFive, styles.selectItemCustomDatePicker]}>
            <ScrollView>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> January </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> February </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> March </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> April </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> May </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> June </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> July </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> August </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> September </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> October </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> November </Text>
                <Text style={[styles.btnPdTopMini, styles.titlePdBotXSm, styles.textPrimeSm ]}> December </Text>
            </ScrollView>
        </View>

        </React.Fragment>
        );
    }
};

export default MonthlyCustomPicker;
