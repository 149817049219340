import theme from "./base/theme";

export const cssImage = {
  iconWarning: {
    width: 16,
    height: 16,
  },
  attachIcon: {
    width: 21,
    height: 19,
    resizeMode: "contain",
  },
  starIcon: {
    width: 25,
    height: 24,
  },
  settingIcon: {
    width: 5,
    height: 22,
    resizeMode: "contain",
  },
  medIcon: {
    width: 35,
    height: 43,
  },
  closeIcon: {
    width: 45,
    height: 45,
  },
  searchIcon: {
    width: 21,
    height: 21,
  },
  calIcon: {
    width: 19,
    height: 19,
  },
  nextIcon: {
    width: 7,
    height: 12,
  },
  journalIcon: {
    width: 47,
    height: 47,
  },
  routineIcon: {
    width: 27,
    height: 27,
  },
  closeDarkIcon: {
    width: 14,
    height: 14,
  },
  editDarkIcon: {
    width: 15,
    height: 15,
  },
  uploadIcon: {
    width: 13,
    height: 13,
  },
  uploadIconSm: {
    width: 22,
    height: 16
  },
  uploadLgIcon: {
    width: 42,
    height: 30
  },
  calNavIcon: {
    width: 48,
    height: 48,
  },
  questionIcon: {
    width: 36,
    height: 28,
  },
  questionIconSm: {
    width: 26,
    height: 20,
  },
  goIcon: {
    width: 6,
    height: 11,
  },
  personIcon: {
    width: 17,
    height: 19,
  },
  contTypeIcon: {
    width: 20,
    height: 20,
  },
  messageIcon: {
    width: 19,
    height: 13,
  },
  popMessageIcon: {
    width: 70,
    height: 70,
  },
  popMessageIconSm: {
    width: 64,
    height: 64,
  },
  plusIcon: {
    width: 13,
    height: 13,
    marginRight: 5,
  },
  navMenuIcon: {
    width: 46,
    height: 36,
  },
  passHidden: {
    width: 15,
    height: 5,
  },
  passShow: {
    width: 15,
    height: 14,
  },
  toolTipSmIcon: {
    width: 33,
    height: 37,
  },
  radioIcon: {
    width: 15,
    height: 15,
  },
  plusSm: {
    width: 22,
    height: 22,
  },
  colorSelectIcon: {
    width: 13,
    height: 13,  
  },
  passIconLgCom: {
    width: 205,
    height: 176,
  },
  nextCircleIon: {
    width: 95,
    height: 95,
  },
  plusExSm: {
    width: 11,
    height: 11,
  },
  plusExMinSm: {
    width: 7,
    height: 7,
  },
  moreIcon: {
    width: 9,
    height: 9,
  },
  profileBannerBgImg: {
    width: "100%",
    resizeMode: "cover",
    flex: 1,
  },
  careBannerBgImg: {
    width: "100%",
    resizeMode: "cover",
    flex: 1,
  },
  noteIcon: {
    width: 22,
    height: 17,
  },
  userComIcon: {
    width: 26,
    height: 26,
  },
  fileIcon: {
    width: 24,
    height: 21,
  },
  premBg: {
    width: "100%",
    resizeMode: "cover",
    flex: 1,
  },
  orIcon: {
    width: 64,
    height: 64,
  },
  spinLoader: {
    width: 48,
    height: 48,
  },
  quoteIconWhite: {
    width: 11,
    height: 9,
  },
  mapIconSm: {
    width: 15,
    height: 21,
  },
  upIcon: {
    width: 13,
    height: 16,
  },
  minusIcon: {
    width: 9,
    height: 1,
  },
  userProfileImg: {
    width: 26,
    height: 26,
    borderRadius: 26,
  },
  closeModalIcon: {
    width: 45,
    height: 45,
  },
  privacyIcon: {
    width: 253,
    height: 129,
  },
  profileLoginIconLg: {
    width: 115,
    height: 115,
  },
  menuCurve: {
    width: 80,
    height: 60,
  },
  notifyDeleteIcon: {
    width: 23,
    height: 28,
    resizeMode: "contain",
  },
  notifyAlarmIcon: {
    width: 28,
    height: 28,
    resizeMode: "contain",
  },
  deviceIconView: {
    width: 70,
    position: "absolute",
    left: 0,
    top: 0,
  },
  deviceIconSm: {
    width: 70,
  },
  phoneIcon: {
    width: 18,
    height: 28,
    resizeMode: "contain",
  },
  tabIcon: {
    width: 22,
    height: 29,
    resizeMode: "contain",
  },
  laptopIcon: {
    width: 30,
    height: 21,
    resizeMode: "contain",
  },
  addAttachIcon: {
    width: 30,
    height: 30,
    resizeMode: "contain",
  },
  plusBtnIcon: {
    width: 20,
    height: 20,
  },
  acceptBtn: {
    width: 32,
    height: 32,
  },
  infoBtnSm: {
    width: 20,
    height: 20,
  },
  sliderTouchIcon: {
    width: 36,
    height: 47,
  },
  clockIcon: {
    width: 20,
    height: 20,
  },
  copyIcon: {
    width: 19,
    height: 21,
  },
  calIconExSm: {
    width: 14,
    height: 14,
  },
  arrowUpIcon: {
    width: 12,
    height: 12,
    resizeMode: "contain",
  },
  searchZoomIcon: {
    width: 18,
    height: 18,
    resizeMode: "contain",
  },
  searchZoomIconRound: {
    width: 24,
    height: 24,
    resizeMode: "contain",
  },
  dateTimeIcon: {
    width: 17,
    height: 19,
    resizeMode: "contain",
  },
  passHiddenLg: {
    width: 22,
    height: 7,
  },
  passShowLg: {
    width: 22,
    height: 18,
  },
  enterIcon: {
    width: 21,
    height: 18,
  },
  resetIcon: {
    width: 16,
    height: 20,
  },
  securityIcon: {
    width: 21,
    height: 26,
  },
  helpCircleComIcon: {
    width: 20,
    height: 20,
  },
  statusWrongIcon: {
    width: 22,
    height: 22,
  },
  statusCorrectIcon: {
    width: 22,
    height: 22,
  },
  statusWarningIcon: {
    width: 22,
    height: 20,
  },
  resetLgIcon: {
    width: 16,
    height: 20,
  },
  envelopeIcon: {
    width: 20,
    height: 16,
  },
  passIconSmCom: {
    width: 130,
    height: 112,
  },
  rightSmIcon: {
    width: 22,
    height: 22,
  },
  telCircleIcon: {
    width: 24,
    height: 24,
  },
  arrowCloseLg: {
    width: 10,
    height: 18,
  },
  arrowOpenLg: {
    width: 18,
    height: 10,
  },
  warningTraingleCom: {
    width: 20,
    height: 18,
  },
  statusTick: {
    width: 20,
    height: 20,
    resizeMode: "contain",
  },
  setupIcon: {
    width: 96,
    height: 96,
    resizeMode: "contain",
  },
  tabCollpaseTitleView: {
    paddingLeft: 10,
  },
  collapseViewIcon: {
    width: 13,
    height: 23
  },
  recordBtn: {
    width: 36,
    height: 36,
    // marginLeft: -10,
    marginBottom: -2,
  },
  closeDarkIconSm: {
    width: 12,
    height: 12,
  },
  closeLightIconExSm: {
    width: 9,
    height: 9
  },
  navMainMenuIcon: {
    width: 24,
    height: 24,
    resizeMode: "contain",
  },
  topArrow: {
    width: 24,
    height: 12,
    resizeMode: "contain",
  },
  headerNotifyIcon: {
    width: 22,
    height: 22,
    resizeMode: "contain",
  },
  headerFilterIcon: {
    width: 22,
    height: 22,
    resizeMode: "contain",
  },
  headerBackIcon: {
    width: 20,
    height: 14,
  },
  addButtonIconSm: {
    width: 70,
    height: 70,
  },
  closeButtonIconSm: {
    width: 70,
    height: 70,
  },
  starIconSm: {
    width: 24,
    height: 24,
  },
  healthDataIcon: {
    width: 121,
    height: 111,
    resizeMode: "contain",
  },
  customGraphIcon: {
    width: 138,
    height: 104,
    resizeMode: "contain",
  },
  stayIcon: {
    width: 157,
    height: 91,
    resizeMode: "contain",  
  },
  privacyMatterIcon: {
    width: 77,
    height: 66,
    resizeMode: "contain",
  },
  humanIconCom: {
    width: 20,
    height: 20,
  },
  privacyLockIcon: {
    width: 60,
    height: 60,
  },
  helpComImg: {
    width: 136,
    height: 146,
    resizeMode: "contain",  
  },
  linkIconSm: {
    width: 9,
    height: 9,
    resizeMode: "contain", 
    marginTop: 2,
  },
  itemContactIcon: {
    width: 18,
    height: 18,
    resizeMode: "contain", 
  },
  itemVideoIcon: {
    width: 12,
    height: 12,
    resizeMode: "contain",
  },
  swipeIcon: {
    width: 24,
    height: 30,
    resizeMode: "contain", 
  },
  plusBtnSmIcon: {
    width: 17,
    height: 17,
  },
  switchMenuBgCom: {
    width: 140,
    height: 42,
    resizeMode: "contain",
  },
  searchResultIcon: {
    width: 45,
    height: 49,
    resizeMode: "contain",
  },
  searchClearIcon: {
    width: 18,
    height: 18,
    resizeMode: "contain",
  },
  warningMessageIcon: {
    width: 16,
    height: 13
  },
  medIconSm: {
    width: 25,
    height: 28,
  },
  premiumBgCom: {
    width: 85,
    height: 30,
    resizeMode: "contain",
  },
  attachmentImgLg: {
    width: 40,
    height: 30,
  },
  attachLinkIcon: {
    width: 20,
    height: 20,
  },
  caregiverIcon: {
    width: 117,
    height: 80,
    resizeMode: "contain",
  },
  logoApplication: {
    width: 213,
    height: 60,
  },
  logoStartup: {
    width: 142,
    height: 40,
  },
  AutoCompleteRemoveIcon: {
    width: 16,
    height: 16
  },
  popUpCloseLgIcon: {
    width: 15,
    height: 15
  },
  graphPolygonDrpDnIcn: {
    width:9, 
    height: 18
  },
  resourceIcon: {
    width: 40,
    height: 40
  },
  resourceLinkIcn: {
    width: 11, 
    height: 11
  },
  startCheckIcon: {
    width: 12, 
    height:12
  },
  cameraRotateIcn: {
    width:20, 
    height:20
  },
  takePhotoIcn: {
    width:66, 
    height:66
  },
  flashIcn: {
    width:20,
    height:34
  },
  healthJrnTickIcn: {
    width:18, 
    height:12
  },
  getIconImg:{
    width:'100%', 
    height: 21
  }
};
