import React from 'react';
import {View} from 'react-native';
import Notification from '../../Notification/Notification';
import stylesResponsive from '../../../CssResponsive';
const notifySection = props => {
  const {navigation} = props;
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <View style={stylesRes.leftMgSmCom} dataSet={{media: ids.leftMgSmCom}}>
      <Notification navigation={navigation} />
    </View>
  );
};

export default (notifySection);
