import React from 'react';
import {View, TouchableOpacity, Image} from 'react-native';
import styles from '../../Css';

const closeButtonSpec = props => {
  return (
    <View style={styles.closeBtnView}>
      <TouchableOpacity onPress={props.touchableOnPress}>
        <View style={styles.closeBtnTouchSpec}>
          <Image
            style={styles.closeDarkCircle}
            source={require('../../assets/icons/close.png')}
          />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (closeButtonSpec);
