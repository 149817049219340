import moment from 'moment';
import {Platform} from 'react-native';
import ReactNativeBiometrics, { BiometryTypes } from 'react-native-biometrics';
import momenttimezone from 'moment-timezone';
import * as RNLocalize from 'react-native-localize';
import { TIME_ZONE_BANNER_HEIGHT_BREAK_POINT, WEB, TAB_BREAk_POINT_END } from './platformWindowConfig';
import { APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED } from './featureConfig';
import { hasValue } from './hashValueGenerator';
import * as paths from "../navigation/path";
import {
  filterMetrics,
  filterSymptoms,
  filterTreatments,
} from '../screens/LoadingScreen/DashboardLoadingScreenUtil';
import { surveyTabs } from '../Constant/index';

export const getCoverImage = type => {
  switch (type) {
    // case 1:
    //   return require("../assets/background-images/bg-purple.png");
    // case 2:
    //   return require("../assets/background-images/bg-orange.png");
    // case 3:
    //   return require("../assets/background-images/bg-green.png");
    // case 4:
    //   return require("../assets/background-images/bg-pink.png");
    case 5:
      //return require('../assets/background-images/bg-blue.png');
      return require('../assets/background-images/bg-blue.png');
    default:
      // return require('../assets/background-images/bg-orange.png');
      return require('../assets/background-images/bg-med-activity.png');
  }
};

export const getCoverIcon = type => {
  switch (type) {
    // case 1:
    //   return require("../assets/therapy-icons/other.png");
    // case 2:
    //   return require("../assets/therapy-icons/medication.png");
    // case 3:
    //   return require("../assets/therapy-icons/natural.png");
    // case 4:
    //   return require("../assets/therapy-icons/surgery.png");
    case 5:
      return require('../assets/therapy-icons/activity.png');
    default:
      return require('../assets/therapy-icons/medication.png');
  }
};

export const getCoverColor = type => {
  switch (type) {
    // case 1:
    //   return require("../assets/therapy-icons/other.png");
    // case 2:
    //   return require("../assets/therapy-icons/medication.png");
    // case 3:
    //   return require("../assets/therapy-icons/natural.png");
    // case 4:
    //   return require("../assets/therapy-icons/surgery.png");
    case 5:
      return '#E4F5F4';
    default:
      return '#faf6ec';
  }
};

export const treatmentTypes = {
  OTHER: 1,
  MEDICATION: 2,
  NATURAL_HEALTH_PRODUCT: 3,
  SURGERY: 4,
  ACTIVITY: 5,
}

export const getRoutineImage = (userCanEdit, typeName, icon_c) => {
  if (
    !userCanEdit &&
    (typeName === 'Quick Note' || typeName === 'New Journal Entry' || typeName === 'Lab & Reports')
  ) {
    return require('../assets/journal-icons/entry.png');
  } else {
    return icon_c ? {uri: icon_c} : require('../assets/journal-icons/report.png');
  }
};

export const getTherapiesColourCode = type => {
  switch (type) {
    case 1:
      return '#E6CFEF';
    case 2:
      return '#6E1C6B'; //Medication
    case 3:
      return '#D13836'; //Supplement
    case 4:
      return '#F2D9D2';
    case 5:
      return '#33895A'; //Activity
    default:
      return '#F2D9D2';
  }
};

export const getAppointmentsColourCode = type => {
  switch (type) {
    case 'FOLLOW_UP':
      return '#20C1C3';
    case 'OTHER':
      return '#81AB72';
    case 'ER_ADMISSION':
      return '#FF6348';
    case 'HOSPITAL_ADMISSION':
      return '#64A6F4';
    case 'CONSULT':
      return '#73FDFF';
    case 'TREATMENT_SURGERY':
      return '#F33F93';
    default:
      return '#81AB72';
  }
};

export const lowerCaseAllWordsExceptFirstLetters = string => {
  return string.replace(/\w\S*/g, function(word) {
    return word.charAt(0) + word.slice(1).toLowerCase();
  });
};

export const lightOrDark = color => {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp < 127.5) {
    return true; //dark
  } else {
    return false; //light
  }
};

export const removeEndOfStringWhitespace = string => {
  return string.trim();
};

export const getFrequencyType = type => {
  switch (type) {
    case 'DAILY':
      return 'daily';
    case 'WEEKLY':
      return 'weekly';
    default:
      return 'as needed';
  }
};

export const getConnectionStatus = status => {
  switch (status) {
    case 1:
      return 'Pending'
    case 2:
      return 'Connected'
    case 3:
      return 'Declined'
    default:
      return 'Connect'
  }
}

export const actionStatus = {
  PENDING: 1,
  CONNECTED: 2,
  DECLINED: 3,
  WITHDRAW: 4,
  BLOCK: 5,
  DISCONNECT: 6,
  REPORT: 7
};

export const getDateText = date => {
  let result = moment(date).format("MMM D 'YY");
  if (moment(date).isSame(moment(), 'day')) {
    result = 'Today';
  } else if (moment(date).isSame(moment().subtract(1, 'day'), 'day')) {
    result = 'Yesterday';
  }
  return result;
};

export const getSortedReminderList = (reminders) => {
  const dailyReminders = reminders.filter(res => res.reminderEveryday);
  const monthlyReminders = reminders.filter(res => res.reminderMonthly);
  const weeklyReminders = reminders.filter(res => !res.reminderEveryday && !res.reminderMonthly);

  return dailyReminders.concat(weeklyReminders).concat(monthlyReminders);
}

export const getUnseenRequestCount =(received) => {
  let unSeenRequestCount = received.filter(
    item => !item.isNotified,
  );
  return unSeenRequestCount.length;
}

export const zIndexWorkaround = (index, totalCount) => {
  return Platform.select({
    ios: {
      zIndex: 9999999 + totalCount - index,
    },
    web: {
      zIndex: 9999999 + totalCount - index,
    },
  });
}

export const checkKeys = (obj, keys) => {
  let flag = true;
  if(!obj) return false;
  for (const key of keys) {
    if (!(key in obj)) {
      flag = false;
    }
  }
  return flag;
}

const FILE_TYPES = {
  video: 'Video',
  image: 'Image',
  image_optional: 'Image_optional',
  document:'Document',
  ppt:'Ppt',
  audio:'Audio',
  compress:'Compress',
  media:'Media',
  database:'Database',
  email:'Email',
  spreadsheet:'Spreadsheet',
};

const FILE_TYPE_EXTENSIONS = [
  {
    type: FILE_TYPES.video,
    extensions: ['mp4', 'mov','mpg', 'wmv'],
  },
  {
    type: FILE_TYPES.image,
    extensions: ['gif','jpeg', 'png', 'jpg', 'tif', 'svg', 'tiff'],
  },
  {
    type: FILE_TYPES.image_optional,
    extensions: ['ai', 'ps', 'psd'],
  },
  {
    type: FILE_TYPES.document,
    extensions: ['doc','docx','odt','pdf','rtf','tex','txt', 'wpd'],
  },
  {
    type: FILE_TYPES.ppt,
    extensions: ['key','odp','pps','ppt', 'pptx'],
  },
  {
    type: FILE_TYPES.audio,
    extensions: ['mp3','wav','wpl','wma','mpa','mid'],
  },
  {
    type: FILE_TYPES.compress,
    extensions: ['7z','arj','deb','pkg','rar','rpm','z','zip'],
  },
  {
    type: FILE_TYPES.media,
    extensions: ['bin','dmg','iso','toast','vcd'],
  },
  {
    type: FILE_TYPES.database,
    extensions: ['csv','dat','db','log','mdb','sav','sql','tar', 'accdb'],
  },
  {
    type: FILE_TYPES.email,
    extensions: ['email','eml','emlx','msg','oft','ost','pst'],
  },
  {
    type: FILE_TYPES.spreadsheet,
    extensions: ['ods','xls','xlsm','xlsx'],
  },

];

export const getFileExtensions = (fileTypes) => {
  let extensionList = [];
  for(const fileType of fileTypes){
    FILE_TYPE_EXTENSIONS.find((tempFileType) => {
      if(tempFileType.type === fileType){
        for(const extensions of tempFileType.extensions){
          extensionList.push(extensions);
        };
      };
    });
  };
  return extensionList;
}

  export const findThumbnail = (fileName = null) => {
    const fileExtension = fileName.split('.').pop();
    const fileType = FILE_TYPE_EXTENSIONS.find((tempFileType) => {
      const { extensions } = tempFileType;
      return extensions.includes(fileExtension.toLowerCase());
    });

    switch (fileType?.type) {
      case FILE_TYPES.video:
        return require('../assets/icons/video.png')
      case FILE_TYPES.image:
        return require('../assets/icons/image.png')
      case FILE_TYPES.image_optional:
        return require('../assets/icons/image-optional.png')
      case FILE_TYPES.document:
        return require('../assets/icons/document.png')
      case FILE_TYPES.ppt:
        return require('../assets/icons/ppt.png')
      case FILE_TYPES.audio:
        return require('../assets/icons/audio.png')
      case FILE_TYPES.compress:
        return require('../assets/icons/compress.png')
      case FILE_TYPES.media:
        return require('../assets/icons/media.png')
      case FILE_TYPES.database:
        return require('../assets/icons/database.png')
      case FILE_TYPES.email:
        return require('../assets/icons/email.png')
        case FILE_TYPES.spreadsheet:
          return require('../assets/icons/spreadsheet.png')
      default:
        return require('../assets/icons/default.png')
    }
  };

  //get userDefined list items (symptoms/ health data)
  export const getUserTerms = (userDefinedData) => {
    let userTermsArray = [];
    if (userDefinedData) {
      const suggestionResults = [...userDefinedData];
      userTermsArray = sortAlphabeticalOrder(suggestionResults).map(value => {
        return {name: value.name, data: value};
      });
    }
    return userTermsArray;
  };

  //get auto suggestion list items - user list/system list (symptoms/ health data)
  export const getSuggestedTeams = (systemDefinedData, userDefinedData, isInverse) => {
    let termsArray = [];
    const userDefinedDataMap = {};
    userDefinedData.forEach(res => {
      userDefinedDataMap[res.name] = res.name;
    });
    if (systemDefinedData) {
      const suggestionResults = [];
      systemDefinedData.forEach(res => {
        if (!isInverse ? !userDefinedDataMap[res.name] : userDefinedDataMap[res.name]) {
          suggestionResults.push(res);
        }
      });
      termsArray = suggestionResults.map(value => {
        return {name: value.name, data: value};
      });
    }
    return termsArray;
  }

  //Display orange dot in symptom/health data auto suggestion list
  export const getSelectedItems = (alreadySelectedData, userDefinedData) => {
    const obj = {};
    if (alreadySelectedData && alreadySelectedData.length > 0) {
      alreadySelectedData.forEach(item => (obj[item.name] = item.name));
    }

    if (userDefinedData && userDefinedData.length > 0) {
      userDefinedData.forEach(item => (obj[item.name] = item.name));
    }

    return obj;
  };

  //Display orange dot in health data auto suggestion list in Activiy & get already selected health data
  export const getAlreadySelectedHealthData = (userDefinedMetrics, rwMetrics, selectedValue, needFilteredItems, hasType) => {
    let metrics = [];
    let mappedMetrics = [];
    let filteredItems = [];

    if (rwMetrics) {
      metrics = [...rwMetrics];
      metrics.forEach(metric => {
        if(metric.metric) {
          if(userDefinedMetrics) {
            let mappedMetric = userDefinedMetrics.filter(value => 
              value.id === metric.metric
            );
            if(mappedMetric) {
              mappedMetrics.push(mappedMetric[0]);
            }
          } 
        } else {
          mappedMetrics.push(metric);
        }
      });

      if (needFilteredItems) {
        if (hasType) {
          filteredItems = mappedMetrics.filter(metric =>
            metric?.name === selectedValue.name ||
            metric?.data?.name === selectedValue.name
          );
        } else {
          filteredItems = mappedMetrics.filter(metric =>
            metric?.name === selectedValue ||
            metric?.data?.name === selectedValue
          );
        }
      }
    }
    return needFilteredItems ? filteredItems : mappedMetrics;
  }

  export const getUnits = (untisArray, typeOfMeasurement) => {
    if (untisArray && typeOfMeasurement) {
      return untisArray.filter(
        res => res.typeOfMeasurement === typeOfMeasurement && res.active,
      );
    }

    return untisArray.filter(res => res.active);
  }

  export const getMeasurementUnits = (unitsArray, selectedUnit = null) => {
    let units = [];
    let tempUnitArray = [];
    if (unitsArray && unitsArray.length > 0) {
      if (selectedUnit !== null && selectedUnit !== "") {
        tempUnitArray = unitsArray.filter(unit => 
          unit && unit.name !== null && unit.id === selectedUnit
        );
        unitsArray.filter(unit => {
          if (unit && unit.name !== null && unit.id !== selectedUnit) {
            tempUnitArray.push(unit);
          }
        });
      } else {
        tempUnitArray = unitsArray;
      }
      tempUnitArray.map((res) =>
        units.push({
          name: `${res.name} (${res.symbol})`, 
          data: res
        })
      );
    }
    return units;
  }

  //get health data type of measurement by uomId
  export const getTypeOfMeasurementByUomId = (unitsOfMeasure, uomId) => {
    if (unitsOfMeasure && unitsOfMeasure[uomId]) {
      return unitsOfMeasure[uomId].typeOfMeasurement;
    }

    return null;
  };

  //get Activity health data type of measurement
  export const getTypeOfMeasurement = (res, userDefinedMetricsProcessed) => {
    let typeOfMeasurement;
    if (res.data) {
      typeOfMeasurement = res.data.typeOfMeasurement;
    } else if (res.metric) {
      typeOfMeasurement = userDefinedMetricsProcessed[res.metric]
        ? userDefinedMetricsProcessed[res.metric].typeOfMeasurement
        : null;
    }

    return typeOfMeasurement;
  }

  export const getUserAvatarName = (firstName, lastName) => {
    const emptySpaceRegex = /\s/g;
    let nameArray = [];
    if (firstName) {
      nameArray.push(firstName.replace(emptySpaceRegex, ''));
    }
    if (lastName) {
      nameArray.push(lastName.replace(emptySpaceRegex, ''));
    }
    const userAvatarName =
      nameArray && nameArray.length > 0
        ? nameArray[0] +
          ' ' +
          (nameArray.length > 1 ? nameArray[nameArray.length - 1] : '')
        : null;
    return userAvatarName;
  }

  export const sortAlphabeticalOrder = (list) => {
    if(list){
    return list.sort(
      (a, b) => (a.name !== null && b.name !== null) && a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    }
  }

  /**
   * @returns {Promise<{available: boolean,biometryType?: string,error?: string,sensorName?: string}>} Returns the promise object which contains the sensor data
   */
  export const getBiometricSecurityType = async () => {
    try {
      const supported = await new ReactNativeBiometrics().isSensorAvailable();
      if (!(supported && supported?.available) || supported?.error || !supported?.biometryType) return supported;
      const {biometryType} = supported;
      switch (biometryType) {
        case BiometryTypes.TouchID:
          return {...supported, sensorName: 'Touch ID'};
        case BiometryTypes.FaceID:
          return {...supported, sensorName: 'Face ID'};
        case BiometryTypes.Biometrics:
          return {...supported, sensorName: 'Biometric'};
        default:
          return supported;
      }
    } catch (error) {
      throw(error);
    }
  }

  export const getArtifact = (props) => {
    const attachmentCategory = props.attachmentCategory.split("-");
    switch (attachmentCategory[0]) {
      case "Therapy":
        if (attachmentCategory.length === 2) {
          return (props.selectedTherapie || {}).artifactId;
        }
        return (props.newTherapie || {}).artifactId;
      case "Journal":
        if (attachmentCategory.length === 2) {
          return (props.journalEntrySelected || {}).artifactId;
        }
        return (props.newJournal || {}).artifactId;
      case "Contact":
        if (attachmentCategory.length === 2) {
          return (props.selectedContact || {}).artifactId;
        }
        return (props.newContact || {}).artifactId;
      default:
        return null;
    }
  };

  export const countryNameMap = {
    "AF" : "Afghanistan",
    "AL" : "Albania",
    "DZ" : "Algeria",
    "AS" : "American Samoa",
    "AD" :  "Andorra",
    "AO" : "Angola",  
    "AR" : "Argentina", 
    "AM" : "Armenia",
    "AW" : "Aruba", 
    "AU" : "Australia", 
    "AT" : "Austria", 
    "AZ" : "Azerbaijan", 
    "BS" : "Bahamas", 
    "BH" : "Bahrain", 
    "BD" : "Bangladesh", 
    "BB" : "Barbados", 
    "BY" : "Belarus", 
    "BE" : "Belgium", 
    "BZ" : "Belize", 
    "BJ" : "Benin",  
    "BM" : "Bermuda", 
    "BT" : "Bhutan", 
    "BO" : "Bolivia", 
    "BA" : "Bosnia and Herzegovina", 
    "BW" : "Botswana", 
    "BR" : "Brazil", 
    "BN" : "Brunei Darussalam", 
    "BG" : "Bulgaria", 
    "BF" : "Burkina Faso", 
    "BI" : "Burundi", 
    "KH" : "Cambodia", 
    "CM" : "Cameroon", 
    "CA" : "Canada", 
    "KY" : "Cayman Islands", 
    "CF" : "Central African Republic",
    "TD" : "Chad", 
    "CL" : "Chile", 
    "CN" : "China", 
    "CO" : "Colombia", 
    "KM" :"Comoros",  
    "CG" : "Congo", 
    "CD" : "Congo, The Democratic Republic of the",
    "CR" : "Costa Rica", 
    "HR" : "Croatia", 
    "CU" : "Cuba", 
    "CY" : "Cyprus", 
    "CZ" : "Czech Republic", 
    "CI" : "C&#244;te D'Ivoire", 
    "DK" : "Denmark", 
    "DJ" : "Djibouti", 
    "DO" : "Dominican Republic", 
    "EC" : "Ecuador", 
    "EG" : "Egypt", 
    "SV" : "El Salvador", 
    "GQ" : "Equatorial Guinea", 
    "EE" : "Estonia", 
    "ET" : "Ethiopia", 
    "FO" : "Faroe Islands", 
    "FJ" : "Fiji", 
    "FI" : "Finland", 
    "CS" : "Former Serbia and Montenegro",
    "YU" : "Former Yugoslavia", 
    "FR" : "France",
    "GF" : "French Guiana", 
    "PF" : "French Polynesia", 
    "GA" : "Gabon", 
    "GM" : "Gambia", 
    "GE" : "Georgia", 
    "DE" : "Germany", 
    "GH" : "Ghana", 
    "GI" : "Gibraltar", 
    "GR" : "Greece", 
    "GL" : "Greenland", 
    "GD" : "Grenada", 
    "GP" : "Guadeloupe", 
    "GU" : "Guam", 
    "GT" : "Guatemala", 
    "GN" : "Guinea", 
    "GW" : "Guinea-Bissau", 
    "GY" : "Guyana", 
    "HT" : "Haiti", 
    "VA" : "Holy See (Vatican City State)",
    "HN" : "Honduras",
    "HK" : "Hong Kong", 
    "HU" : "Hungary", 
    "IS" : "Iceland", 
    "IN" : "India", 
    "ID" : "Indonesia", 
    "IR" : "Iran, Islamic Republic of",
    "IQ" : "Iraq",  
    "IE" : "Ireland", 
    "IL" : "Israel", 
    "IT" : "Italy", 
    "JM" : "Jamaica", 
    "JP" : "Japan", 
    "JE" : "Jersey", 
    "JO" : "Jordan", 
    "KZ" : "Kazakhstan", 
    "KE" : "Kenya", 
    "KP" : "Korea, Democratic People's Republic of",
    "KR" : "Korea, Republic of", 
    "KW" : "Kuwait", 
    "KG" : "Kyrgyzstan", 
    "LA" : "Lao People's Democratic Republic",  
    "LV" : "Latvia", 
    "LB" : "Lebanon", 
    "LS" : "Lesotho", 
    "LR" : "Liberia", 
    "LY" : "Libyan Arab Jamahiriya",
    "LI" : "Liechtenstein", 
    "LT" : "Lithuania", 
    "LU" : "Luxembourg", 
    "MK" : "Macedonia, The Former Yugoslav Republic of",
    "MG" : "Madagascar", 
    "MW" : "Malawi", 
    "MY" : "Malaysia", 
    "MV" : "Maldives", 
    "ML" : "Mali", 
    "MT" : "Malta", 
    "MQ" : "Martinique", 
    "MR" : "Mauritania", 
    "MU" : "Mauritius", 
    "YT" : "Mayotte", 
    "MX" : "Mexico", 
    "MD" : "Moldova, Republic of",
    "MC" : "Monaco", 
    "MN" : "Mongolia", 
    "ME" : "Montenegro",  
    "MS" : "Montserrat", 
    "MA" : "Morocco", 
    "MZ" : "Mozambique", 
    "MM" : "Myanmar", 
    "NA" : "Namibia", 
    "NP" : "Nepal", 
    "NL" : "Netherlands", 
    "AN" : "Netherlands Antilles",
    "NC" : "New Caledonia", 
    "NZ" : "New Zealand", 
    "NI" : "Nicaragua", 
    "NE" : "Niger", 
    "NG" : "Nigeria", 
    "MP" : "Northern Mariana Islands",
    "NO" : "Norway", 
    "OM" : "Oman", 
    "PK" : "Pakistan", 
    "PA" : "Panama", 
    "PG" : "Papua New Guinea", 
    "PY" : "Paraguay", 
    "PE" : "Peru", 
    "PH" : "Philippines", 
    "PL" : "Poland", 
    "PT" : "Portugal", 
    "PR" : "Puerto Rico", 
    "QA" : "Qatar", 
    "RO" : "Romania", 
    "RU" : "Russian Federation", 
    "RW" :  "Rwanda", 
    "RE" : "R&#233;union", 
    "KN" : "Saint Kitts and Nevis",
    "LC" : "Saint Lucia", 
    "WS" : "Samoa", 
    "SA" : "Saudi Arabia", 
    "SN" : "Senegal", 
    "RS" : "Serbia", 
    "SC" : "Seychelles", 
    "SL" : "Sierra Leone", 
    "SG" : "Singapore", 
    "SK" : "Slovakia", 
    "SI" : "Slovenia", 
    "SB" : "Solomon Islands", 
    "SO" : "Somalia", 
    "ZA" : "South Africa", 
    "ES" : "Spain", 
    "LK" : "Sri Lanka", 
    "SD" : "Sudan", 
    "SR" : "Suriname", 
    "SZ" : "Swaziland", 
    "SE" : "Sweden", 
    "CH" : "Switzerland", 
    "SY" : "Syrian Arab Republic",
    "TW" : "Taiwan", 
    "TJ" : "Tajikistan", 
    "TZ" : "Tanzania", 
    "TH" : "Thailand", 
    "TG" : "Togo", 
    "TT" : "Trinidad and Tobago", 
    "TN" : "Tunisia", 
    "TR" : "Turkey", 
    "UG" : "Uganda", 
    "UA" : "Ukraine", 
    "AE" : "United Arab Emirates",
    "GB" : "United Kingdom", 
    "US" : "United States",
    "UY" : "Uruguay", 
    "UZ" : "Uzbekistan", 
    "VU" : "Vanuatu", 
    "VE" : "Venezuela", 
    "VN" : "Vietnam", 
    "VI" : "Virgin Islands (U.S.)",
    "YE" : "Yemen", 
    "ZM" : "Zambia", 
    "ZW" : "Zimbabwe", 
  };

  export const isViewableAttachment = (filename) => {
    const fileExtension = filename.split('.').pop();
    const fileType = FILE_TYPE_EXTENSIONS.find((tempFileType) => {
      const { extensions } = tempFileType;
      return extensions.includes(fileExtension.toLowerCase());
    });
    if (fileType?.type === FILE_TYPES.document ||
      fileType?.type === FILE_TYPES.image ||
      fileType?.type === FILE_TYPES.video ||
      fileType?.type === FILE_TYPES.audio
      ) {
        return true;
    }
    return false;
  }

  export const checkCategoryItemsSavedOrNot = (categoryTypeItems) => {
    let itemSaved = false;
    if (categoryTypeItems && categoryTypeItems.length > 0) {
      itemSaved = true;
      categoryTypeItems.forEach(item => {
        if (item?.initializing) {
          itemSaved = false;
        }
      });
    }
    return itemSaved;
  }

  export const verifyDuplicateMedicationExistOrNot = (name, din_npn, userListItems) => {
    if (
      name &&
      din_npn &&
      userListItems &&
      userListItems.length > 0
    ) {
      return userListItems.find(res => (
        res.treatmentType !== 5 &&
        res.name &&
        res.name.trim().toLowerCase() === name.trim().toLowerCase() &&
        res.identificationNumber &&
        res.identificationNumber.trim() === din_npn.trim()
      ));
    } else {
      return false;
    }
  }

  export const verifyDuplicateMedication = (name, din_npn, unit, quantity, listTherapie, callBack, id = null) => {
    if (
      name &&
      unit &&
      (quantity || quantity === 0) &&
      listTherapie &&
      listTherapie.length > 0
    ) {
      let duplicateMedication = [];
      listTherapie.forEach(res => {
        if (
          res.name &&
          res.name.trim().toLowerCase() === name.trim().toLowerCase() &&
          (din_npn === null ||
            (res.identificationNumber &&
              res.identificationNumber.trim() === din_npn.trim())) &&
          res.dosages[0] &&
          (res.dosages[0].quantity == quantity &&
            res.dosages[0].units === unit)
        ) {
          if (id) {
            res.id !== id && duplicateMedication.push(res);
          } else {
            duplicateMedication.push(res);
          }
        }
      });

      if (duplicateMedication && duplicateMedication.length > 0) {
        let templist =
          duplicateMedication[0].name +
          ', ' +
          quantity +
          ' ' +
          duplicateMedication[0].dosages[0].units +
          ' is already in your list';
        callBack(true, templist);
      } else {
        callBack(false);
      }
    } else {
      callBack(false);
    }
  };

  export const dashboardCategories = {
    SCHEDULES: "SCHEDULES",
    ROUTINES: "ROUTINES",
    MEDICATIONS: "MEDICATIONS",
    ACTIVITIES: "ACTIVITIES",
    INSIGHTS: "INSIGHTS"
  };

  export const dashboardIssues = {
    NODATA: "NODATA",
    NOPINNED: "NOPINNED",
  };

  const dashboardDataType = {
    title: '',
    emptyMessage: '',
    noPinnedMessage: '',
    viewButtonText: '',
    createButtonText: ''
  }

  /**
    @param {"SCHEDULES" | "ROUTINES" | "MEDS" | "ACTIVITIES" | "INSIGHTS"} type
    @returns {dashboardDataType} data object for dashboard tile
  */
  export const getDashboardData = type  => {
    const data = {...dashboardDataType};
    switch (type) {
      case dashboardCategories.SCHEDULES:
        data.title = "Today's Schedule: ";
        data.emptyMessage = "Nothing is scheduled for today"
        break;
      case dashboardCategories.ROUTINES:
        data.title = "Routines";
        data.emptyMessage = "Create a Routine to get started";
        data.noPinnedMessage = "No routines are pinned to Dashboard.";
        data.viewButtonText = "View all routines";
        data.createButtonText = "Create a Routine";
        break;
      case dashboardCategories.MEDICATIONS:
        data.title = "Medications/Supplements";
        data.emptyMessage = "Add Meds/Supplements to get started";
        data.noPinnedMessage = "No meds/supplements are pinned to Dashboard.";
        data.viewButtonText = "View all meds/supplements";
        data.createButtonText = "Add Meds/Supplements";
        break;
      case dashboardCategories.ACTIVITIES:
        data.title = "Activities";
        data.emptyMessage = "Add Activities to get started";
        data.noPinnedMessage = "No activities are pinned to Dashboard.";
        data.viewButtonText = "View all activities";
        data.createButtonText = "Add Activities";
        break;
      case dashboardCategories.INSIGHTS:
        data.title = "Insights";
        data.emptyMessage = "";
        data.noPinnedMessage = "";
        data.viewButtonText = "";
        data.createButtonText = "";
        break;
      default:
        break;
    }
    return data;
  }

const sort = (a, b) => Boolean(a?.reminderTime && b?.reminderTime) &&  a?.reminderTime.replaceAll(':', '') - b?.reminderTime.replaceAll(':', '');

const sortSurveysByReminderTime = (a, b) => Boolean(a?.reminderTime && b?.reminderTime) && new Date(a?.reminderTime).getTime() - new Date(b?.reminderTime).getTime();

const sortSurveysByOverDueDays = (a, b) => Boolean(a?.overDueDays && b?.overDueDays) && b?.overDueDays - a?.overDueDays;

export const scheduleTypes = {
  SURVEY: 'SURVEY',
  ROUTINE: 'ROUTINE'
} 
export const scheduleStatus = {
  DUE : 'DUE',
  OVER_DUE: 'OVER_DUE',
  UP_COMING: 'UP_COMING'
}

export const preProcessScheduleData = (data, finishedSchedules = []) => {
  if (!data) return {routine: [], survey: [], extra: {}};
  const {routine = [], survey = []} = data;
  
  const processedRoutines = [
    ...[...(routine?.threeDay ?? []), ...finishedSchedules.filter(e => e.type === scheduleTypes.ROUTINE && e.scheduleTime === -3).map(e => ({...e, completed: true}))]?.map(data => ({...data, scheduleTimeStatus: scheduleStatus.OVER_DUE, scheduleTime: -3, type: scheduleTypes.ROUTINE, reminderDateTime: getEntryDateTime(data?.reminderTime, true, -3), uniqueId: data?.id + '_3_' + data?.reminderTime})).sort(sort), 
    ...[...(routine?.twoDay ?? []), ...finishedSchedules.filter(e => e.type === scheduleTypes.ROUTINE && e.scheduleTime === -2).map(e => ({...e, completed: true}))]?.map(data => ({...data, scheduleTimeStatus: scheduleStatus.OVER_DUE, scheduleTime: -2, type: scheduleTypes.ROUTINE, reminderDateTime: getEntryDateTime(data?.reminderTime, true, -2), uniqueId: data?.id + '_2_' + data?.reminderTime})).sort(sort),
    ...[...(routine?.oneDay ?? []), ...finishedSchedules.filter(e => e.type === scheduleTypes.ROUTINE && e.scheduleTime === -1).map(e => ({...e, completed: true}))]?.map(data => ({...data, scheduleTimeStatus: scheduleStatus.OVER_DUE, scheduleTime: -1, type: scheduleTypes.ROUTINE, reminderDateTime: getEntryDateTime(data?.reminderTime, true, -1), uniqueId: data?.id + '_1_' + data?.reminderTime})).sort(sort),
    ...routine?.zeroDay?.map(data => ({...data, scheduleTimeStatus: scheduleStatus.UP_COMING, scheduleTime: data?.reminderTime, type: scheduleTypes.ROUTINE, reminderDateTime: getEntryDateTime(data?.reminderTime), uniqueId: data?.id + '_0_' + data?.reminderTime})).sort(sort),
  ];

  const processedSurveys = [
    ...[...(survey?.overDue ?? []), ...finishedSchedules.filter(e => e.type === scheduleTypes.SURVEY).map(e => ({...e, completed: true}))]?.map(data => ({...data, scheduleTimeStatus: scheduleStatus.OVER_DUE, scheduleTime: -(data?.overDueDays), type: scheduleTypes.SURVEY, uniqueId: data?.id + '_' + data?.overDueDays + '_' + data?.reminderTime})).sort(sortSurveysByReminderTime).sort(sortSurveysByOverDueDays),
    ...survey?.currentDay?.map(data => ({...data, scheduleTimeStatus: scheduleStatus.DUE, scheduleTime: data?.reminderTime, type: scheduleTypes.SURVEY, uniqueId: data?.id + '_' + data?.overDueDays + '_' + data?.reminderTime})).sort(sortSurveysByReminderTime) 
  ]
  return {
    routines: processedRoutines, 
    surveys: processedSurveys,
    extra: {
      completedRoutines: processedRoutines.reduce((acc, curr) => curr.completed ? ++acc : acc,  0),
      completedSurveys: processedSurveys.reduce((acc, curr) => curr.completed ? ++acc : acc,  0),
    }
  }
}

export const getEntryDateTime = (reminderTime, isOverDue = false, scheduleTime) => {
  const [h = 0, m = 0, s = 0, ms = 0] = reminderTime && Array.isArray(reminderTime) ? reminderTime : reminderTime.split(":");
  return new Date(moment().subtract(isOverDue ? Math.abs(scheduleTime) : 0, "days").valueOf()).setHours(h, m, s, ms);
}

export const getDayDifference = (dateInMilliseconds) => {
  return moment().startOf('day').diff(moment(dateInMilliseconds).startOf('day'), 'days');
}

export const isHaveTimeZoneConflict = timeZone => {
  const timeZoneOffset = momenttimezone.tz(timeZone).format('Z');
  const currentTimeZone = RNLocalize.getTimeZone();
  const currentTimeZoneOffset = momenttimezone.tz(currentTimeZone).format('Z');
  return currentTimeZoneOffset !== timeZoneOffset
}

export const getMedicationUnits = (drugAdministrations, drugAdministrationsDigits, displayFullName = false) => {
  const units = [];
  if (drugAdministrations) {
    drugAdministrations
      .sort((a, b) => (a.state !== null && b.state !== null) && a.state.toLowerCase().localeCompare(b.state.toLowerCase()))
      .forEach(element => {
        units.push({
          key: element.state,
          value: element.state,
          label: getUnitName(element.state, displayFullName)
        });
      });
  } else if (drugAdministrationsDigits) {
    drugAdministrationsDigits
      .sort((a, b) => (a.administrationUnit !== null && b.administrationUnit !== null) &&
        a.administrationUnit.toLowerCase().localeCompare(b.administrationUnit.toLowerCase()),
      )
      .forEach(element => {
        units.push({
          key: element.administrationUnit,
          value: element.administrationUnit,
          label: getUnitName(element.administrationUnit, displayFullName),
        });
      });
  }

  return units;
};

export const getUnitName = (unitName, displayFullName) => {
  return displayFullName 
    ? unitName
    : unitName === 'Teaspoon'
      ? 'Tsp'
      : unitName === 'Tablespoon'
        ? 'Tbsp'
        : unitName
}

export const getScrollHeight = (hasNotch, journalUserId, timeZoneWarning, timeZone, fullHeight, fullWidth, buttonHeight = 0, needTotalHeight = false) => {
  let headerHeight = 57;
  let careHeaderHeight = 0;
  let notchHeight = 0;
  let timeZoneBannerHeight = 0;
  if (hasNotch) {
    notchHeight = 50;
  }
  if (journalUserId) {
    careHeaderHeight = 31;
  }
  if (!Boolean(journalUserId) && !Boolean(timeZoneWarning) && isHaveTimeZoneConflict(timeZone)) {
    timeZoneBannerHeight = fullWidth <= TIME_ZONE_BANNER_HEIGHT_BREAK_POINT ? 80 : 57;
  }
  let totHeight = headerHeight  + careHeaderHeight + timeZoneBannerHeight + notchHeight + buttonHeight;
  if (needTotalHeight) {
    return totHeight;
  } else {
    return fullHeight - totHeight;
  }
}

const filterEntiesByTime = (journalEntriesFilterd, startDate, endDate, journalEntries) => {
  if (startDate) {
    startDate.setHours(0, 0, 0, 0);
  }

  if (endDate) {
    endDate.setHours(23, 59, 59, 59);
  }

  let firstDate = new Date();
  firstDate.setDate(firstDate.getDate() - 14);
  firstDate.setHours(0, 0, 0, 0);
  if (startDate && endDate) {
    journalEntriesFilterd = journalEntriesFilterd.filter(
      item =>
        item.entryDate > startDate.getTime() &&
        item.entryDate < endDate.getTime(),
    );
  } else {
    journalEntriesFilterd = journalEntries.filter(
      item => item.entryDate > firstDate.getTime()
    );
  }
  return journalEntriesFilterd;
}

export const getSymptomsData = (symptoms, startDate, endDate, props, journalEntries, isFromAddEditGraph = false) => {
  let symptomsArray = [];
  if (symptoms) {
    symptomsArray = Object.values(symptoms);
  }
  const symptomsData = {};

  if (
    symptomsArray &&
    symptomsArray.length > 0 &&
    journalEntries.length > 0
  ) {
    let journalEntriesFilterd = journalEntries.filter(
      item => item.symptoms && item.symptoms.length > 0,
    );
    journalEntriesFilterd = filterEntiesByTime(journalEntriesFilterd, startDate, endDate, journalEntries);

    journalEntriesFilterd.forEach(item => {
      let filterSymptoms = item.symptoms;

      filterSymptoms = filterSymptoms.filter(item => item.severity || item.severity === 0);

      filterSymptoms.sort(function(a, b) {
        return parseInt(a.createdOn) - parseInt(b.createdOn);
      });
      filterSymptoms.forEach(symptomItem => {
        if (symptoms[symptomItem.symptom]) {
          if (Platform.OS !== WEB && isFromAddEditGraph) {
            //if not in the range of min and max ignore
            let sumpPropsObj = props.symptomProps[symptomItem.symptom];
            if (
              (sumpPropsObj && ((Number(sumpPropsObj.min) > symptomItem.severity) ||
              (Number(sumpPropsObj.max) < symptomItem.severity)))
            ) {
              return;
            }
          }

          let data = [];
          if (symptomsData[symptomItem.symptom]) {
            data = [...symptomsData[symptomItem.symptom]];
          }
          if(Platform.OS === WEB) {
            symptomItem.entryDate = item.entryDate;
            data.push(symptomItem);
            symptomsData[symptomItem.symptom] = data.sort(
              (a, b) => a.entryDate - b.entryDate
            );
          } else {
            const symObj = props.symptomsDigestProcessed.userSymptoms[symptomItem.symptom];
            let sname = symObj.name;
            if (sname.length > 20) {
              sname = sname.substring(0, 15);
              sname += '...';
            }
            let isSymYesNoScale = symObj.displayProperties.scaleMax && 
                                    symObj.displayProperties.scaleMax === 1 
                                      ? true 
                                      : false
            data.push({
              x: (((new Date(item.entryDate).getTime()/ 1000) / 60) /60) / 24,
              y: symptomItem.severity ? symptomItem.severity : 0,
              marker: sname + ' = ' + 
                        (isSymYesNoScale
                          ? symptomItem.severity === 1
                            ? 'Yes' 
                            : 'No'
                          : (symptomItem.severity ? symptomItem.severity : 0)
                        ) + '\n' + moment(new Date(item.entryDate)).format("MMM DD, h:mm A")
            });
            symptomsData[symptomItem.symptom] = data.sort((a, b) => a.x - b.x);
          }
        }
      });
    });
  }

  return symptomsData;
};

export const getMetricsData = (metrics, startDate, endDate, props, journalEntries, isFromAddEditGraph = false) => {
  let metricsArray = [];
  if (metrics) {
    metricsArray = Object.values(metrics);
  }
  const metricsData = {};

  if (
    metricsArray &&
    metricsArray.length > 0 &&
    journalEntries.length > 0
  ) {
    let journalEntriesFilterd = journalEntries.filter(
      item => item.metrics && item.metrics.length > 0,
    );
    journalEntriesFilterd = filterEntiesByTime(journalEntriesFilterd, startDate, endDate, journalEntries);

    journalEntriesFilterd.forEach(item => {
      let filterMetrics = item.metrics;

      filterMetrics = filterMetrics.filter(item => item.value || item.value === 0);

      filterMetrics.sort(function(a, b) {
        return parseInt(a.createdOn) - parseInt(b.createdOn);
      });
      filterMetrics.forEach(metricItem => {
        if (metrics[metricItem.metric]) {
          if (Platform.OS !== WEB && isFromAddEditGraph) {
            //if not in the range of min and max ignore
            let metricPropsObj = props.metricProps[metricItem.metric];
            if (
              (metricPropsObj && ((Number(metricPropsObj.min) > metricItem.value) ||
              (Number(metricPropsObj.max) < metricPropsObj.value)))
            ) {
              return;
            }
          }
          let data = [];
          if (metricsData[metricItem.metric]) {
            data = [...metricsData[metricItem.metric]];
          }

          if (Platform.OS === WEB) {
            metricItem.entryDate = item.entryDate;
            data.push(metricItem);
            metricsData[metricItem.metric] = data.sort(
              (a, b) => a.entryDate - b.entryDate
            );
          } else {
            const metObj = props.metricsDigestProcessed.userDefinedMetrics[metricItem.metric];
            if (metObj) {
            let unit;

            let display = props.userDefinedMetricUnitOfMeasures.find(function(
              element,
            ) {
              return element.metricId === metObj.id;
            });

            if (!display) {
              display = props.systemDefinedMetricUnitOfMeasures.find(function(
                element,
              ) {
                return element.metricId === metObj.id;
              });
            }

            // const dislayUnit = props.unitsOfMeasureDigestProcessed
            //   .unitsOfMeasure[display.uomId];
            let dislayUnit = null;
            if (display && display?.uomId) {
              dislayUnit = props.unitsOfMeasureDigestProcessed.unitsOfMeasure[display.uomId];
            }

            if (dislayUnit) {
              unit = dislayUnit.symbol;
            } else {
              if (metObj.symbol) {
                unit = metObj.symbol;
              } else {
                unit = '#';
              }
            }
            let mname = metObj.name;
            if (mname.length > 20) {
              mname = mname.substring(0, 15);
              mname += '...';
            }
            data.push({
              x: (((new Date(item.entryDate).getTime()/ 1000) / 60) /60) / 24,
              y: getMetricValue(metricItem, props),
              marker: mname + ' = ' + getMetricValue(metricItem, props) + ' ' + (unit ? unit : '')
                        + '\n' + moment(new Date(item.entryDate)).format("MMM DD, h:mm A")
            });
            metricsData[metricItem.metric] = data.sort((a, b) => a.x - b.x);
            }
          }
        }
      });
    });
  }

  return metricsData;
};

export const getMetricValue = (metric, props) => {
  const unitsOfMeasure = props.unitsOfMeasureDigestProcessed
    .unitsOfMeasure[metric.unitOfMeasure];
  if (unitsOfMeasure) {
    let display = props.userDefinedMetricUnitOfMeasures.find(function(
      element,
    ) {
      return element.metricId === metric.metric;
    });

    if (!display) {
      display = props.systemDefinedMetricUnitOfMeasures.find(function(
        element,
      ) {
        return element.metricId === metric.metric;
      });
    }

    if (display && display?.uomId) {
    const dislayUnit = props.unitsOfMeasureDigestProcessed
      .unitsOfMeasure[display.uomId];

    const baseValue =
      (metric.value - unitsOfMeasure.baseOffset) * unitsOfMeasure.baseScale;

    const dislayvalue =
      baseValue / dislayUnit.baseScale + dislayUnit.baseOffset;

    return dislayvalue;
    }
  }

  return metric.value;
};

export const getDosagesData = (therapies, startDate, endDate, props, journalEntries, isFromAddEditGraph = false) => {
  let therapiesArray = [];
  if (therapies) {
    therapiesArray = Object.values(therapies);
  }
  const therapiesData = {};

  if (
    therapiesArray &&
    therapiesArray.length > 0 &&
    journalEntries.length > 0
  ) {
    let journalEntriesFilterd = journalEntries.filter(
      item => item.dosages && item.dosages.length > 0,
    );
    journalEntriesFilterd = filterEntiesByTime(journalEntriesFilterd, startDate, endDate, journalEntries);

    journalEntriesFilterd.forEach(item => {
      let dosages = item.dosages;

      dosages = dosages.filter(item => item.quantity || item.quality === 0);

      dosages.sort(function(a, b) {
        return parseInt(a.createdOn) - parseInt(b.createdOn);
      });
      dosages.forEach(dosagesItem => {
        if (therapies[dosagesItem.treatment]) {
          if (Platform.OS !== WEB && isFromAddEditGraph) {
            //if not in the range of min and max ignore
            let dosagePropsObj = props.therapyProps[dosagesItem.treatment];
            if (
              (dosagePropsObj && ((Number(dosagePropsObj.min) > dosagesItem.quantity) ||
              (Number(dosagePropsObj.max) < dosagesItem.quantity)))
            ) {
              return;
            }
          } 
          let data = [];
          if (therapiesData[dosagesItem.treatment]) {
            data = [...therapiesData[dosagesItem.treatment]];
          }
          if (Platform.OS === WEB) {
            dosagesItem.entryDate = item.entryDate;
            data.push(dosagesItem);
            therapiesData[dosagesItem.treatment] = data.sort(
              (a, b) => a.entryDate - b.entryDate
            );
          } else {
            const theObj = props.treatmentsProcessed[dosagesItem.treatment];
            const dosages = theObj.dosages;
            let unit = dosages[0] && dosages[0].units ? dosages[0].units : ' ';
            let tname = theObj.name;
            if (tname.length > 20) {
              tname = tname.substring(0, 15);
              tname += '...';
            }
            data.push({
              x: (((new Date(item.entryDate).getTime()/ 1000) / 60) /60) / 24,
              y: dosagesItem.quantity ? dosagesItem.quantity : 0,
              marker: tname + ' = ' + (dosagesItem.quantity ? dosagesItem.quantity : 0) + ' ' + (unit ? unit : '')
                        + '\n' + moment(new Date(item.entryDate)).format("MMM DD, h:mm A")
            });
            therapiesData[dosagesItem.treatment] = data.sort(
              (a, b) => a.x - b.x,
            );
          }
        }
      });
    });
  }

  return therapiesData;
};

export const getGraphHashValue = (therapies, metrics, symptoms, startDate, endDate, therapyProps, metricProps, symptomProps) => {
  return hasValue(
    JSON.stringify(therapies) +
    ',' +
    JSON.stringify(metrics) +
    ',' +
    JSON.stringify(symptoms) +
    ',' +
    startDate +
    ',' +
    endDate +
    ',' +
    JSON.stringify(therapyProps) +
    ',' +
    JSON.stringify(metricProps) +
    ',' +
    JSON.stringify(symptomProps)  
  );
}
export const getMenuItemDetails = (title) => {
  const data = {};
    switch (title) {
      case paths.DASHBOARD.title:
        data.eventName = 'Menu > Dashboard';
        data.nativeIdWrapper = 'menuItemDashboard';
        data.nativeId = 'menuDashboard';
        data.selectedIcon = require("../assets/main-nav/home-active.png");
        data.unSelectedIcon = require("../assets/main-nav/home.png");
        break;
      case paths.HEALTH_JOURNEY.title:
        data.eventName = 'Menu > HealthJourney';
        data.nativeIdWrapper = 'menuItemHealthJourney';
        data.nativeId = 'menuHealthJourney';
        data.selectedIcon = require("../assets/main-nav/health-journey-active.png");
        data.unSelectedIcon = require("../assets/main-nav/health-journey.png");
        break;
      case paths.JOURNALS.title:
        data.eventName = 'Menu > Journals';
        data.nativeIdWrapper = 'menuItemJournals';
        data.nativeId = 'menuJournals';
        data.selectedIcon = require("../assets/main-nav/journal-active.png");
        data.unSelectedIcon = require("../assets/main-nav/journal.png");
        break;
      case paths.SURVEYS.title:
        data.eventName = 'Menu > Surveys';
        data.nativeIdWrapper = 'menuItemSurveys';
        data.nativeId = 'menuSurveys';
        data.selectedIcon = require("../assets/main-nav/surveys-active.png");
        data.unSelectedIcon = require("../assets/main-nav/surveys.png");
        break;
      case paths.TODOS.title:
        data.eventName = 'Menu > To-dos';
        data.nativeIdWrapper = 'menuItemToDos';
        data.nativeId = 'menuTo-Dos';
        data.selectedIcon = require("../assets/main-nav/task-question-active.png");
        data.unSelectedIcon = require("../assets/main-nav/task-question.png");
        break;
      case paths.GRAPHS.title:
        data.eventName = 'Menu > Graphs';
        data.nativeIdWrapper = 'menuItemGraphs';
        data.nativeId = 'menuGraphs';
        data.selectedIcon = require("../assets/main-nav/graph-active.png");
        data.unSelectedIcon = require("../assets/main-nav/graph.png");
        break;
      case paths.PEER_TO_PEER.title:
        data.eventName = 'Menu > Community';
        data.nativeIdWrapper = 'menuItemCommunity';
        data.nativeId = 'menuCommunity';
        data.selectedIcon = require("../assets/main-nav/community-active.png");
        data.unSelectedIcon = require("../assets/main-nav/community.png");
        break;
      case paths.COMMUNITY_GROUPS.title:
        data.eventName = 'Menu > health-library';
        data.nativeIdWrapper = 'menuItemHealthLibrary';
        data.nativeId = 'menuHealthLibrary';
        data.selectedIcon = require("../assets/main-nav/health-library-active.png");
        data.unSelectedIcon = require("../assets/main-nav/health-library.png");
        break;
      case paths.CLINICAL_TRIALS.title:
        data.eventName = 'Menu > clinical-trials';
        data.nativeIdWrapper = 'menuItemClinicalTrials';
        data.nativeId = 'menuClinicalTrials';
        data.selectedIcon = require("../assets/main-nav/clinical-trials-active.png");
        data.unSelectedIcon = require("../assets/main-nav/clinical-trials.png");
        break;
      case paths.CONTACTS.title:
        data.eventName = 'Menu > Contacts';
        data.nativeIdWrapper = 'menuItemContacts';
        data.nativeId = 'menuContacts';
        data.selectedIcon = require("../assets/main-nav/contact-active.png");
        data.unSelectedIcon = require("../assets/main-nav/contact.png");
        break;
      case paths.CAREGIVERS.title:
        data.eventName = 'Menu > Caregivers';
        data.nativeIdWrapper = 'menuItemCaregiver';
        data.nativeId = 'menuCaregivers';
        data.selectedIcon = require("../assets/main-nav/caregiver-active.png");
        data.unSelectedIcon = require("../assets/main-nav/caregiver.png");
        break;
      default:
        break;
    }
    return data;
}

// Filter Therapy based on treatment type (OTHER/MEDICATION/NATURAL HEALTH PRODUCT/SURGERY/ACTIVITY)
export const filterTherapy = (treatmentList) => {
  let treatmentsList = {
    activity: {},
    surgery: {},
    naturalHealthProduct: {},
    medication: {},
    other: {},
  };

  if (treatmentList && treatmentList.length > 0) {
    let treatments = treatmentList.filter(function(treatment) {
      return treatment.name;
    });

    treatments.forEach(trt => {
      // Add Additonal Question Status
      trt = {...trt, addQuestion: false};

      // Remove empty dosages
      if (trt.isActive) {
        switch (trt.treatmentType) {
          case treatmentTypes.OTHER: // OTHER
            treatmentsList.other[trt.id] = trt;
            break;
          case treatmentTypes.MEDICATION: // MEDICATION
            treatmentsList.medication[trt.id] = trt;
            break;
          case treatmentTypes.NATURAL_HEALTH_PRODUCT: // NATURAL HEALTH PRODUCT
            treatmentsList.naturalHealthProduct[trt.id] = trt;
            break;
          case treatmentTypes.SURGERY: // SURGERY
            treatmentsList.surgery[trt.id] = trt;
            break;
          case treatmentTypes.ACTIVITY: // ACTIVITY
            treatmentsList.activity[trt.id] = trt;
            break;
          default:
            treatmentsList.other[trt.id] = trt; // OTHER
        }
      }
    });
    return treatmentsList;
  }
};

// Filter Contacts
export const filterContacts = (contactList) => {
  // Before Filter
  let contactsList = {
    careTeam: {},
    family: {},
  };

  if (contactList && contactList.length > 0) {
    let contacts = contactList.filter(function(contact) {
      return contact.givenName;
    });

    contacts.forEach(res => {
      // Add Additonal Question Status
      res = {...res, addQuestion: false};

      // REMOVE THIS
      res = {...res, contactType: 1};

      if (res.careTeam) {
        // CARETEAM
        contactsList.careTeam[res.id] = res;
      } else {
        // FAMILY
        contactsList.family[res.id] = res;
      }
    });

    return contactsList;
  }
};

export const getMedicalConditionStatus = value => {
  switch (value) {
    case 'NONE':
    case 'None':
    case 'DIAGNOSIS':
    case 'Diagnosis':
      return 'Diagnosed';
    case 'REMISSION':
    case 'Remission':
      return 'Remission';
    case 'REOCCURENCE':
    case 'Reoccurence':
      return 'Reoccurence';
    default:
      return 'Diagnosed';
  }
};

//Email Link Navigations
//Create Entry
export const handleCreateEntryNavigation = (token, reminderDateTime, journalEntryTypesProcessed, props) => {
  const { windowWidth, navigation, journalUserId, setToken, onCreateNewRoutine } = props;
  if (journalEntryTypesProcessed) {
    const journalEntryType = journalEntryTypesProcessed[token];
    if (journalEntryType) {
      let journalEntry = {journalEntryTypeId: token, draft: true};
      if (Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED)) {
        const dayDiff = Boolean(reminderDateTime) ? getDayDifference(reminderDateTime) : 0;
        let entryDate = Boolean(dayDiff > 0) ? reminderDateTime : getEntryDateTime(new Date().getHours() + ':' + new Date().getMinutes());
        journalEntry = {journalEntryTypeId: token, draft: true, entryDate: entryDate};
      }
      onCreateNewRoutine(
        journalEntry,
        res => {
          // Redirect to New Journal
          if (res) {
            setToken(null, null);
            handleNavigationCommon(
              navigation,
              windowWidth < TAB_BREAk_POINT_END
                ? paths.JOURNALS.addJournalPath
                : paths.JOURNALS.path,
              {
                journalPath: paths.JOURNALS.addJournalPathSuffix,
                journalName: journalEntryType.name,
                reminderDateTime: reminderDateTime
              }
            );
          } else {
            setToken(null, null);
            handleNavigationCommon(navigation, paths.DASHBOARD.path);
          }
        },
        journalUserId,
      );
    } else {
      setToken(null, null);
      handleNavigationCommon(navigation, paths.DASHBOARD.path);
    }
  } else {
    setToken(null, null);
    handleNavigationCommon(navigation, paths.DASHBOARD.path);
  }
}

//Update Routine
export const handleUpdateRoutineNavigation = (token, journalEntryTypesProcessed, props, processedData = {}, isFromDashboardLoading = false) => {
  const { navigation, setToken } = props;
  const journalEntryType = journalEntryTypesProcessed[token];
  if (journalEntryType) {
    console.log("Setting navigation for handling update routine request");
    Platform.OS !== WEB
      ? setTimeout(() => {
        handleNavigationCommon(navigation, paths.DASHBOARD.path);
        handleRoutineNavigation(journalEntryType, processedData, props, isFromDashboardLoading);
      }, 2000)
      : handleRoutineNavigation(journalEntryType, processedData, props, isFromDashboardLoading);
  } else {
    setToken(null, null);
    handleNavigationCommon(navigation, paths.DASHBOARD.path);
  }
}
export const handleRoutineNavigation = (journalEntryType, processedData, props, isFromDashboardLoading = false, isFromNotif = false, reminderId = null) => {
  const { setEntryType, setToken, navigation } = props;
  if (journalEntryType?.deviceName !== null) {
    !Boolean(isFromNotif) && setToken(null, null);
    handleNavigationCommon(navigation, paths.ROUTINES.path);
  } else {
    setEntryType({
      ...journalEntryType,
      defaultMetrics: filterMetrics(
        journalEntryType,
        isFromDashboardLoading ? processedData.metricsDigest : props.metricsDigestProcessed,
      ),
      defaultSymptoms: filterSymptoms(
        journalEntryType,
        isFromDashboardLoading ? processedData.symptomsDigest : props.symptomsDigestProcessed,
      ),
      defaultTreatments: filterTreatments(
        journalEntryType,
        isFromDashboardLoading ? processedData.treatments : props.treatmentsProcessed,
      ),
    });
    !Boolean(isFromNotif) && setToken(null, null);
    handleNavigationCommon(
      navigation,
      paths.ROUTINES.editRoutineTemplatePath,
      {
        reminderId: reminderId ? reminderId : null,
        routineName: journalEntryType.name
      }
    );        
  }
}

//Create Entry from email
export const handleCreateEntryFromEmailNavigation = (token, journalEntriesProcessed, props) => {
  const { navigation, windowWidth, setToken, onSetSelectedJournalEntry} = props;
  if (journalEntriesProcessed) {
    let item = journalEntriesProcessed[token];
    if (item) {
      onSetSelectedJournalEntry(item);
      setTimeout(() => {
        setToken(null, null);
        handleNavigationCommon(
          navigation,
          windowWidth < TAB_BREAk_POINT_END
            ? paths.JOURNALS.viewJournalPath
            : paths.JOURNALS.path,
          {
            journalPath: paths.JOURNALS.viewJournalPathSuffix,
            journalName: item.name
          }
        );
      }, 500);
    } else {
      setToken(null, null);
      handleNavigationCommon(
        navigation,
        paths.DASHBOARD.path,
        {errorDes: 'Entry not Found'}
      );
    }
  } else {
    setToken(null, null);
    handleNavigationCommon(
      navigation,
      paths.DASHBOARD.path,
      {errorDes: 'Entry not Found'}
    );
  }
}

//Survey Navigation
export const handleSurveysNavigation = (token, props, isFromNotif = false, notifId = null) => {
  const { journalUserId, navigation, getAllUserHealthSurvey, setToken, resetSurvey, getUserHealthSurveyById, setActiveTab, clearNotificationById } = props;
  const surveyId = token;
  getAllUserHealthSurvey(journalUserId, (success, data) => {
    if (success) {
      let found = data.open.find(function (element) {
        return element.id.toString() === surveyId.toString();
      });
      if (!Boolean(found?.id)) {
        found = data.closed.find(function (element) {
          return element.id.toString() === surveyId.toString();
        });
      }

      resetSurvey();
      getUserHealthSurveyById(found ? found?.id : surveyId, journalUserId, (success, res) => {
        if (success) {
          handleSurveyNavigation(found ? found : res.newSchedule, props, isFromNotif, notifId);
        } else {
          setActiveTab(surveyTabs.open);
          !Boolean(isFromNotif) && setToken(null, null);
          handleNavigationCommon(navigation, paths.SURVEYS.mainSurveyPath, {status: true});
          Boolean(isFromNotif) && Boolean(clearNotificationById) && clearNotificationById(notifId);
        }
      });
    } else {
      !Boolean(isFromNotif) && setToken(null, null);
      handleNavigationCommon(navigation, paths.DASHBOARD.path);
    }
  });
}

export const handleSurveyNavigation = (survey, props, isFromNotif = false, notifId = null, isFromDashboard = false) => {
  const { navigation, selectSurvey, setActiveTab, clearNotificationById, setToken } = props;
  selectSurvey(
    survey.id,
    survey.surveyId,
    survey.title,
    (survey?.surveyStatus === 'COMPLETED'),
    null,
    survey?.description,
    survey?.duration,
    survey?.s3ImageUrl,
    survey?.surveyStatus,
    survey?.questionOrder
  );
  setActiveTab(survey?.surveyStatus === 'COMPLETED' ? surveyTabs.completed : surveyTabs.open);
  if (survey?.surveyStatus === 'OPEN') {
    !Boolean(isFromNotif) && setToken(null, null);
    handleNavigationCommon(navigation, paths.SURVEYS.mainSurveyPath, isFromDashboard ? {from: paths.DASHBOARD.path} : {});
  } else if (survey?.surveyStatus === 'PROCESSING') {
    !Boolean(isFromNotif) && setToken(null, null);
    handleNavigationCommon(
      navigation,
      paths.SURVEYS.surveyQuestionPath, {
        from: isFromDashboard ? paths.DASHBOARD.path : paths.SURVEYS.mainSurveyPath,
        surveyId : survey?.surveyId, 
        notificationId: survey?.id, 
        surveyStatus: survey?.surveyStatus,
        questionOrder: survey?.questionOrder
    });
  } else {
    !Boolean(isFromNotif) && setToken(null, null);
    handleNavigationCommon(navigation, paths.SURVEYS.path, isFromDashboard ? {from: paths.DASHBOARD.path} : {});
  }
  Boolean(isFromNotif) && Boolean(clearNotificationById) && clearNotificationById(notifId);
}

export const handleNavigationCommon = (navigation, path, params) => {
  navigation.reset({
    index: 0,
    routes: [{
      name: path,
      params: params ? params : {}
    }],
  });
}