import React, {Component} from 'react';
import {Image, View, Text, ScrollView, TouchableOpacity, Linking} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Modal from '../../components/Modal/modal';
import NestedScroll from '../../components/NestedScroll/nestedScroll';
import PrimaryButton from '../../components/Button/primaryButton';
import {CONTACT_EMAIL} from '../../util/commonUiConfig';
class LearnHowPrivacyPolicy extends Component {
  render() {
    const stylesRes = stylesResponsive.getProcessedStyles().styles;
    const ids = stylesResponsive.getProcessedStyles().ids;
    return (
      <Modal
        visible={this.props.showLearnHowPrivacyPolicy}
        onRequestClose={this.props.toggleLearnHowPrivacyPolicy}
        onBackdropPress={this.props.toggleLearnHowPrivacyPolicy}
        defaultStyle={[styles.customPopUpStyleLg]}>
        <View style={stylesRes.modalScrollStatic} dataSet={{media: ids.modalScrollStatic}}>
          <ScrollView style={styles.modalScroller}>
            <View
              style={[styles.popUpBlock, styles.bgWhite, styles.popUpRad]}>
              <View style={styles.popUpContentArea}>
                <View style={styles.gridSectionPopup}>
                  <View style={[styles.popUpContentPd]}>
                    <View style={stylesRes.modalContIn}>
                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <View style={styles.privacyDescView}>
                            <View
                              style={[
                                styles.privacyTitleView,
                                styles.flexRow,
                                styles.flexAiCt,
                              ]}>
                              <View style={styles.privacyIconView}>
                                <Image
                                  style={styles.privacyLockIcon}
                                  source={require('../../assets/privacy-icons/privacy-lock.png')}
                                />
                              </View>
                              <View
                                style={[
                                  styles.privacyTitleDesc,
                                  styles.flexCom,
                                ]}>
                                <Text
                                  style={[
                                    styles.textPrimeBold,
                                    styles.textThickGrey,
                                  ]}
                                  allowFontScaling={false}
                                  >
                                  Your data is encrypted and therefore
                                  rendered unreadable to unauthorized users
                                  and hackers.
                                </Text>
                              </View>
                            </View>

                            <View
                              style={[
                                styles.privacyContentView,
                                styles.comMgBotSm,
                              ]}>
                              <Text style={styles.textPrimeSm} allowFontScaling={false}>
                                We do not sell or give your personal
                                information to third parties. This includes:
                              </Text>
                            </View>

                            <View
                              style={[
                                styles.privacyListView,
                                styles.comMgBot,
                              ]}>
                              <View style={styles.privacyListRow}>
                                <View style={styles.listViewIconWrap}>
                                  <Image
                                    style={styles.listViewIcon}
                                    source={require('../../assets/privacy-icons/identity.png')}
                                  />
                                </View>
                                <View style={styles.listViewData}>
                                  <Text
                                    style={[
                                      styles.textPrimeSm,
                                      styles.textTertiary,
                                    ]}
                                    allowFontScaling={false}
                                    >
                                      Identity, contact and your payment information
                                  </Text>
                                </View>
                              </View>

                              <View style={styles.privacyListRow}>
                                <View style={styles.listViewIconWrap}>
                                  <Image
                                    style={styles.listViewIcon}
                                    source={require('../../assets/privacy-icons/note.png')}
                                  />
                                </View>
                                <View style={styles.listViewData}>
                                  <Text
                                    style={[
                                      styles.textPrimeSm,
                                      styles.textTertiary,
                                    ]}
                                    allowFontScaling={false}
                                    >
                                    Journals, notes and questions
                                  </Text>
                                </View>
                              </View>

                              <View style={styles.privacyListRow}>
                                <View style={styles.listViewIconWrap}>
                                  <Image
                                    style={styles.listViewIcon}
                                    source={require('../../assets/privacy-icons/attachment.png')}
                                  />
                                </View>
                                <View style={styles.listViewData}>
                                  <Text
                                    style={[
                                      styles.textPrimeSm,
                                      styles.textTertiary,
                                    ]}
                                    allowFontScaling={false}
                                    >
                                    Attachments
                                  </Text>
                                </View>
                              </View>

                              <View
                                style={[
                                  styles.privacyListRow,
                                  styles.noBotPd,
                                ]}>
                                <View style={styles.listViewIconWrap}>
                                  <Image
                                    style={styles.listViewIcon}
                                    source={require('../../assets/privacy-icons/contact.png')}
                                  />
                                </View>
                                <View style={styles.listViewData}>
                                  <Text
                                    style={[
                                      styles.textPrimeSm,
                                      styles.textTertiary,
                                    ]}
                                    allowFontScaling={false}
                                    >
                                    Contacts and Health Reports
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>

                          <View
                            style={[
                              stylesRes.userInfoBlockCom,
                              stylesRes.modalRdSm,
                            ]}>
                            <View style={stylesRes.userInfoTextCom}>
                              <NestedScroll>
                                <View style={stylesRes.userInfoInnerBlock}>
                                  <View style={stylesRes.paraBotPd}>
                                    <View>
                                      <Text style={styles.textPrimeBold} allowFontScaling={false}>
                                        Privacy Policy
                                      </Text>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          This Privacy Notice applies to
                                          customer information of HAnalytics
                                          Solutions Inc. (“We”, “Our”, “Us”)
                                          collects through our website
                                          (www.hanalyticssolutions.com) or
                                          via our applications for mobile
                                          operating systems (collectively
                                          the “Site”). Below are highlights
                                          of our personal information
                                          handling practices.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        1. What Information We Collect
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We collect the following types of
                                          information:
                                        </Text>
                                      </View>

                                      <View>
                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            1.1 “personal information”, that
                                            is information that we need in
                                            order for you to access the
                                            Site, such as information
                                            relating to your identity, your
                                            contact details, and your
                                            payment information;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            1.2 “health information”, that
                                            is information that is inputted
                                            into the Site relating to the
                                            care, diagnosis, and treatment
                                            of your health.
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        2. How We Collect Information
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We collect your personal
                                          information from the following
                                          sources:
                                        </Text>
                                      </View>

                                      <View>
                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            2.1 information you give us when
                                            you fill out forms, such as
                                            during online purchases,
                                            customer service inquiries, and
                                            surveys;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            2.2 information from other
                                            sources, such as companies that
                                            help us to update our records;
                                            and
                                          </Text>
                                        </View>

                                        <View style={stylesRes.paraBotPd}>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            2.3 information that we collect
                                            automatically when you visit our
                                            website, such as information
                                            about your browser settings and
                                            other information collected
                                            through cookies.
                                          </Text>
                                        </View>

                                        <View style={stylesRes.paraBotPd}>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            We collect your health
                                            information from the following
                                            sources:
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            2.4 information that is inputted
                                            into the Site by you or by a
                                            third party that you have
                                            authorized to access your
                                            account and input information on
                                            your behalf;
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        3. How We Use and Disclose Your
                                        Personal Information
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            3.1 We do not sell or rent your
                                            personal information to third
                                            parties.
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            3.2 We use your personal
                                            information to provide products
                                            and services and to manage our
                                            business operations. For
                                            example, we use your information
                                            in order to fill your orders,
                                            answer your inquiries,
                                            authenticate you when you sign
                                            into online services, prevent
                                            loss and fraud, and analyze our
                                            business operations and website
                                            performance.
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            3.3 We combine all the personal
                                            information we collect other
                                            than information relating to
                                            your browsing history.
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            3.4 We may share personal
                                            information with third parties
                                            who assist us in our operations.
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            3.5 You may close your account
                                            if you want to withdraw your
                                            consent from our further use of
                                            your information for the above
                                            purposes. We may, however,
                                            retain information about your
                                            previous transactions for audit
                                            purposes, to ensure the
                                            integrity of our data and to
                                            fulfill legal requirements.
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        4. How We Use and Disclose Your
                                        Health Information
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.1 We will not share your
                                            health information with any
                                            third party without your prior
                                            consent. You can change the
                                            status of your consent to health
                                            information disclosure at any
                                            time in your account settings.
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        5. How to Contact Us
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          If you have a privacy question or
                                          concern, please contact us at:
                                          help@hanalyticssolutions.com
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBotPd}>
                                    <View>
                                      <Text style={styles.textPrimeBold} allowFontScaling={false}>
                                        Detailed Privacy Notice
                                      </Text>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        1. About Us
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          The Site is operated by HAnalytics
                                          Solutions Inc. We are located in
                                          Calgary, AB, Canada
                                        </Text>
                                      </View>

                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We have appointed a Chief Privacy
                                          Officer who is accountable for our
                                          personal information handling
                                          practices. Our Chief Privacy
                                          Officer is Shaneel Pathak. If you
                                          have a question or complaint about
                                          our personal information handling
                                          practices, please contact us using
                                          our web form using the following
                                          link: www.hanalyticssolutions.com.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View syle={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        2. Effective Date and Scope
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          This Privacy Notice is effective
                                          as of April 3, 2017.
                                        </Text>
                                      </View>

                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          This Privacy Notice governs our
                                          practices with respect to personal
                                          information we collect through the
                                          following websites and online
                                          applications:{' '}
                                          <Text style={styles.textEm} allowFontScaling={false}>
                                            www.hanalyticssolutions.com,
                                            HAnalytics Personal Health
                                            Journal and Analysis
                                          </Text>
                                          .
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        3. What Personal Information Do We
                                        Collect?
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          Personal information is
                                          information that identifies you or
                                          could be combined by us or our
                                          service providers and affiliates
                                          with other information to identify
                                          you. This information includes
                                          your personal e-mail address, your
                                          home address, your home telephone
                                          number, your personal cellphone
                                          number, your image, and may
                                          include your age, and other
                                          similar information when
                                          associated with you. Personal
                                          information may also be
                                          information containing details as
                                          to whether you have opened our
                                          promotional e-mails or how you
                                          have used our website, if we can
                                          associate that personal
                                          information with you.
                                        </Text>
                                      </View>

                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We collect personal information
                                          from you when you create an
                                          account, make a purchase,
                                          communicate with us using our web
                                          forms to make a request or ask a
                                          question, submit a product review,
                                          take a survey or otherwise submit
                                          personal information to us with
                                          your communication.
                                        </Text>
                                      </View>

                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          For example, we will request
                                          credit card and other identifying
                                          information to process a credit
                                          card transaction when you purchase
                                          a product through our website. We
                                          will also ask you for contact
                                          information in order to ship your
                                          product to you or the recipient
                                          that you designate.
                                        </Text>
                                      </View>

                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We receive personal information
                                          about you from other sources. For
                                          example, we will receive
                                          information from credit card
                                          processors regarding whether the
                                          credit card details you gave us
                                          have been accepted or declined. We
                                          will receive information from
                                          shipping companies regarding
                                          whether the product we shipped was
                                          delivered or was undeliverable. We
                                          may also acquire information about
                                          you from other promotional or
                                          marketing companies with whom you
                                          shared your information and whom
                                          you have allowed to provide
                                          information to us.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        4. How Do We Use Your Personal
                                        Information?
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We use your information to provide
                                          products and services and to
                                          manage our business operations.
                                          Some examples include:
                                        </Text>
                                      </View>

                                      <View>
                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.1 to register your account;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.2 to authenticate you when you
                                            log into your account;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.3 to fulfill your requests for
                                            products and services;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.4 to communicate with you
                                            about your requests;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.5 to recommend or to offer you
                                            other products and services
                                            based on your inferred
                                            interests;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.6 to post your product
                                            reviews;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.7 to communicate with you
                                            regarding customer service
                                            issues and any product recalls
                                            or warranty service; and
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            4.8 to protect the security or
                                            integrity of our websites and
                                            our business.
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        5. What Health Information Do We
                                        Collect?
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          Health information is information
                                          relating to health diagnosis,
                                          treatment, or care. This includes
                                          information inputted into the Site
                                          relating to your physical and
                                          mental health, to health services
                                          that have been provided to you, to
                                          any drugs or medication that have
                                          been provided to you, or to any
                                          health care aid or other item that
                                          has been prescribed to you.
                                        </Text>
                                      </View>

                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We collect health information only
                                          when it is inputted into the Site
                                          by you or by a third party who is
                                          authorized to do so on your
                                          behalf.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        6. How Do We Use Your Health
                                        Information?
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          You have full control over the use
                                          of the health information
                                          collected by the Site. Through
                                          your account, you may choose which
                                          third parties or health care
                                          providers can see your
                                          information, and you can designate
                                          information as confidential. We
                                          will not disclose health
                                          information without your prior
                                          consent.
                                        </Text>
                                      </View>

                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          The health information you input
                                          into the Site can be invaluable to
                                          scientific and medical research.
                                          As such, we ask you to consider
                                          consenting to the disclosure of
                                          your health information to third
                                          parties for the purpose of
                                          scientific or medical research.
                                          Such disclosures will be made
                                          without divulging your personal
                                          identity or other personal
                                          information that could lead to a
                                          breach of your privacy. You can
                                          change the status of your consent
                                          at any time through your account
                                          settings on the Site.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        7. Personalized Offers and Web
                                        Analytics
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We also automatically collect
                                          information when you visit our
                                          website in order to customize your
                                          experience. For example, we
                                          collect information about what
                                          site referred you to our website,
                                          your browser type, operating
                                          system type and version, language,
                                          and your Internet Protocol (“IP”)
                                          address. We also collect
                                          information about how you browse
                                          through our website, including
                                          logging the pages you visit and
                                          the amount of time you spend on
                                          certain pages. We use this
                                          information for a number of
                                          technical purposes to ensure that
                                          our pages load correctly in your
                                          browser, as well as to personalize
                                          our offers to you. We also use
                                          this information to improve our
                                          website. For example:
                                        </Text>
                                      </View>

                                      <View>
                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            7.1 we use IP addresses to
                                            correct pages for your
                                            geographical location;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            7.2 we log the frequency of
                                            visits to certain pages on our
                                            website and how visitors
                                            navigate through our website in
                                            order to detect problems,
                                            improve our website and learn
                                            about what products and services
                                            are of interest to our
                                            customers;
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            7.3 we use information about
                                            products that you have clicked
                                            on to make suggestions about
                                            other products or services that
                                            you might be interested in
                                            viewing; and
                                          </Text>
                                        </View>

                                        <View>
                                          <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                            7.4 we track whether certain
                                            types of promotional e-mails
                                            were opened by you and whether
                                            you sought information about a
                                            particular product or service in
                                            order to make inferences about
                                            other products and services you
                                            might be interested in.
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        8. Your Ad Choices: Interest-Based
                                        Advertising
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          Our website uses small pixel files
                                          (small images) and cookies (small
                                          text files) to keep track of your
                                          browser so that you will be
                                          recognized when you visit other
                                          websites where our third-party ad
                                          management providers may show you
                                          advertisements from us based on
                                          your visit to our website. Some of
                                          these advertisements may be
                                          customized to particular products
                                          and services that you viewed or
                                          they may be based on inferences
                                          about products and services that
                                          you might be interested in. This
                                          is called “interest-based
                                          advertising”.
                                        </Text>
                                      </View>

                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          These pixel files and cookies are
                                          generally valid for a limited
                                          number of days (usually no more
                                          than 90 days) and are provided by
                                          our ad management providers. We
                                          require our ad management
                                          providers to participate in the
                                          Digital Advertising Alliance of
                                          Canada’s Ad Choices Program. Click{' '}
                                          <Text
                                            onPress={() =>
                                              // Linking.openURL(
                                              //   "https://youradchoices.ca/"
                                              // )
                                              window.open(
                                                'https://youradchoices.ca/',
                                                '_blank',
                                              )
                                            }>
                                            here
                                          </Text>{' '}
                                          to visit the Digital Advertising
                                          Alliance of Canada’s Your Ad
                                          Choices website where you can find
                                          out more information about digital
                                          advertising and how you can
                                          opt-out of interest-based
                                          advertising.
                                        </Text>
                                      </View>

                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          Please note that if you opt-out of
                                          interest-based advertising you may
                                          still see advertisements from us
                                          on other websites. However, they
                                          will not be customized based on
                                          your browsing history on our
                                          website. In addition, we will
                                          continue to customize our offers
                                          of products and services to you
                                          when you are on our website unless
                                          you block cookies from our
                                          website. If you block cookies on
                                          our website, certain functions,
                                          such as the “shopping cart” will
                                          not function properly because our
                                          website must recognize you as you
                                          move through the checkout process.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        9. Regarding Children and Minors
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We do not allow minors (persons
                                          who are under the age of majority
                                          in their place of residence) to
                                          create accounts or to subscribe to
                                          the Site’s services.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        10. Do We Disclose Your Personal
                                        Information to Others?
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We may share your personal
                                          information with our service
                                          providers that help us with our
                                          business operations. For example,
                                          we share information with service
                                          providers who provide us with
                                          marketing, promotional, and other
                                          support services. We require our
                                          service providers to keep your
                                          personal information secure. In
                                          addition, our service providers
                                          may not use or disclose personal
                                          information for any purpose other
                                          than providing the services on our
                                          behalf unless we or they have
                                          obtained your consent.
                                        </Text>
                                      </View>

                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We may transfer any information we
                                          have about you in connection with
                                          a merger or sale (including
                                          transfers made as part of
                                          insolvency or bankruptcy
                                          proceedings) involving all or part
                                          of our business or as part of a
                                          corporate reorganization or share
                                          sale or other change in corporate
                                          control.
                                        </Text>
                                      </View>

                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We may also disclose your personal
                                          information if it is necessary for
                                          us to collect a debt from you or
                                          where we have reason to believe
                                          that it is relevant to the
                                          investigation or decision to
                                          investigate a breach of the laws
                                          of Canada, a province or territory
                                          of Canada, or a foreign
                                          jurisdiction, and we are legally
                                          permitted to do so.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        11. Law Enforcement Requests
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We will disclose personal
                                          information without your knowledge
                                          or consent if we receive an order,
                                          subpoena, warrant or other legal
                                          requirement issued by a court,
                                          tribunal, regulator or other
                                          person with jurisdiction to compel
                                          disclosure of your personal
                                          information. If we receive a
                                          written request from a police
                                          officer or other law enforcement
                                          agency with authority to request
                                          access to your personal
                                          information in the course of an
                                          actual or potential investigation
                                          into a breach of a law, our policy
                                          is to provide the requested
                                          information.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        12. Preference Centre
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          If you register for an account,
                                          you will have the option to choose
                                          what marketing and promotional
                                          e-mails and communications you
                                          receive from us. You can also
                                          store information such as your
                                          home and shipping address and
                                          credit card information in order
                                          to make future purchases faster.
                                          You will also be able to review
                                          your order history, past product
                                          reviews and track shipments.
                                        </Text>
                                      </View>

                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          You can modify your information by
                                          logging into your account and
                                          making the requested changes. You
                                          cannot modify a product review
                                          once it has been submitted.
                                          However, you can delete your
                                          product review.
                                        </Text>
                                      </View>

                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          You may also choose “close my
                                          account” and we will remove your
                                          account information from active
                                          use. Any product reviews will be
                                          listed as from a “former user”.
                                        </Text>
                                      </View>

                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          If you do not re-activate your
                                          account within 3 months, we will
                                          permanently delete your account.
                                          Please note that we still maintain
                                          information that you provided to
                                          us for audit purposes or to comply
                                          with our legal obligations.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        13. How Do You Access and Modify
                                        Your Personal Information or Make a
                                        Complaint?
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          If you have an account, log in to
                                          access and to modify the personal
                                          information stored with your
                                          account. If you have questions or
                                          concerns about other personal
                                          information collected by us and
                                          would like assistance accessing
                                          that information, please contact
                                          our Chief Privacy Officer at
                                          +1-403-890-5452
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        14. How Do We Protect Your Personal
                                        Information?
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          We take administrative, technical
                                          and physical measures to safeguard
                                          your personal information against
                                          unauthorized access, unauthorized
                                          disclosure, theft and misuse. This
                                          includes limiting access of
                                          employees to, and the use of, your
                                          personal information through the
                                          use of passwords and graduated
                                          levels of clearance. We do not
                                          publish all of our security
                                          measures online because this may
                                          reduce their effectiveness. We
                                          take physical precautions to
                                          ensure that the computer servers
                                          on which your personal information
                                          is stored and archived are secure
                                          and that access to such servers is
                                          protected. We educate our
                                          employees with respect to their
                                          obligations to protect your
                                          personal information and we
                                          require any third-party service
                                          providers to take comparable steps
                                          to ensure the protection of any of
                                          your personal information that is
                                          shared with them.
                                        </Text>
                                      </View>

                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          Although we take precautions
                                          against possible breaches of our
                                          security systems, no company can
                                          fully eliminate the risks of
                                          unauthorized access to your
                                          personal information and no
                                          website is completely secure. We
                                          cannot guarantee that unauthorized
                                          access, hacking, data loss or
                                          breaches of our security systems
                                          will never occur. Accordingly, you
                                          should not transmit personal
                                          information to us using our
                                          website if you consider that
                                          information to be sensitive.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        15. Links to Third-Party Websites
                                        and Social Network Plug-Ins
                                      </Text>
                                    </View>
                                    <View>
                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          You may be able to access
                                          third-party websites through links
                                          available on the Site. These links
                                          are provided for your convenience.
                                          We do not have any control over
                                          those third-party websites and we
                                          do not provide any guarantee that
                                          the privacy practices of the hosts
                                          of those websites meet our
                                          standards. Your use of such
                                          third-party websites is at your
                                          own risk and will be governed by
                                          the privacy policies of those
                                          websites and not by this Privacy
                                          Notice. Do not transmit personal
                                          information using those websites
                                          without reading the privacy
                                          policies governing those websites.
                                        </Text>
                                      </View>

                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          You may create links between our
                                          website and social networking
                                          sites. If you do so, we may be
                                          able to publish updates about our
                                          products and services to your
                                          social network account through the
                                          links that you establish. You may
                                          terminate those links at any time
                                          by modifying your social network
                                          account.
                                        </Text>
                                      </View>

                                      <View style={stylesRes.paraBotPd}>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          If you are logged into a social
                                          networking site when visiting our
                                          page, your visit to our website
                                          will be personalized based on your
                                          social networking profile.
                                        </Text>
                                      </View>

                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          You may change the way social
                                          networking sites and our Sites
                                          interact by modifying your privacy
                                          settings in your social networking
                                          account. You can also log-out of
                                          your social networking account
                                          before visiting our website. For
                                          more information, please refer to
                                          your social networking site's
                                          privacy policy, which governs your
                                          use of these social plug-in
                                          features.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={stylesRes.paraBlock}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        16. Notice to Persons Outside of
                                        Canada
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          This Privacy Notice is governed by
                                          the laws of Canada and applicable
                                          provincial law. By submitting
                                          personal information to us, you
                                          understand that your personal
                                          information will be subject to the
                                          laws of Canada and applicable
                                          provincial laws.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>

                                  <View
                                    style={[
                                      stylesRes.paraBlock,
                                      stylesRes.zeroBotPd,
                                    ]}>
                                    <View style={stylesRes.paraTitleBlock}>
                                      <Text style={styles.textPrimeMdBold} allowFontScaling={false}>
                                        17. Licence Agreement History
                                      </Text>
                                    </View>
                                    <View>
                                      <View>
                                        <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                          April 3, 2017: Original version.
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </NestedScroll>
                            </View>
                          </View>
                        </View>
                      </View>

                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <View
                            style={[styles.detailContact, styles.flexAiCt]}>
                            <Text style={[styles.textPrime]}>
                              To find out more contact us at
                            </Text>
                            <TouchableOpacity
                              onPress={() =>
                                Linking.openURL(`mailto:${CONTACT_EMAIL}`)
                              }
                              title={CONTACT_EMAIL}
                            >
                              <Text
                                style={[
                                  styles.textPrime,
                                  styles.textUnderline,
                                ]}
                                allowFontScaling={false}
                                >
                                {CONTACT_EMAIL}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>

                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <View
                            style={[
                              stylesRes.btnViewSecFull,
                              stylesRes.zeroBotPd,
                              stylesRes.btnViewPdTop
                            ]}>
                            <View
                              style={[
                                styles.btnArea,
                                styles.flexRow,
                                styles.flexCom,
                              ]}>
                              <View style={styles.fieldBtn}>
                                <PrimaryButton
                                  btnText={'Okay'}
                                  btnPosition={'full'}
                                  btnShadow={true}
                                  touchableOnPress={
                                    this.props.toggleLearnHowPrivacyPolicy
                                  }
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </Modal>
    );
  }
}

export default (LearnHowPrivacyPolicy);
