import React, {Component} from 'react';
import {View, Text, Image, Platform} from 'react-native';
import styles from '../../../../Css';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import {hasValue} from '../../../../util/hashValueGenerator';
import {
  AddActivity,
  AddMedication,
  MedicationList,
  TherapieTypes,
} from '../../../Setup/Medications';
import {MedicationsFooter} from '../SetupFooter';
import MessageBox from '../../../Text/messageBox';
import SetupCancelConfirmationBox from '../setupCancelConfirmationBox';
import { chartTypes } from '../../../../Constant/index';
import { getTypeOfMeasurementByUomId } from '../../../../util/commonUiLogic';
import { WEB } from '../../../../util/platformWindowConfig';


class MedicationsContent extends Component {
  constructor(props) {
    super(props);
    const {windowWidth, windowHeight, treatments = [], saveDisable} = props;

    const treatmentsfiltered =
      treatments && treatments.filter(res => res.id !== -1);

    this.state = {
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      activeTherapyTab: 0,
      treatments: treatments ? treatments : [],
      visibleCloseConfirmationModal: false,
      inActive: true,
      hasValue: hasValue(JSON.stringify(treatmentsfiltered)),
      saveWait: false,
      nextWait: false,
      autocompleteOpen: false,
      saveDisable: saveDisable
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth, treatments = [], saveDisable} = props;

    if (
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth ||
      (treatments &&
        JSON.stringify(treatments) !== JSON.stringify(state.treatments) &&
        state.treatments.length) ||
      saveDisable !== state.saveDisable
    ) {
      return {
        windowWidth: windowWidth,
        windowHeight: windowHeight,
        treatments: treatments,
        saveDisable: saveDisable
      };
    }

    return null;
  }
  selectTherapy = val => {
    this.setState({activeTherapyTab: val});
  };

  updateTreatments = treatments => {
    this.setState({
      treatments: treatments,
      inActive: !treatments || (treatments && treatments.length === 0),
    });
  };

  cancel = () => {
    const {updateTreatment, closeModal, treatments = []} = this.props;
    const treatmentsfiltered =
      treatments && treatments.filter(res => res.id !== -1);
    updateTreatment(treatmentsfiltered, () => {
      closeModal();
      this.hideCloseConfirmationModal();
    });
  };

  hideCloseConfirmationModal = () => {
    this.setState({visibleCloseConfirmationModal: false});
  };

  handleMetricsErrors = () => {
    let hasError = false;
    const treatments = Object.assign([], this.props.treatments);
    if (treatments && treatments.length > 0) {
      this.props.treatments.forEach((treat, index) => {
        const metrics = Object.assign([], treat.defaultMetrics);
        if (metrics && metrics.length) {
          treat.defaultMetrics.forEach((item, i) => {
            if (!item.unit) {
              hasError = true;
              metrics[i].error = true;
            }
          });
          treatments[index].defaultMetrics = metrics;
        }
      });
      if (hasError) {
        this.props.updateTreatment(treatments, () => 5);
      }
    }
    return hasError;
  }

  clearErrors = (index, mainIndex) => {
    const treatments = Object.assign([], this.props.treatments);
    const metrics = Object.assign([], this.props.treatments[mainIndex].defaultMetrics);
    metrics[index].error = false;
    treatments[mainIndex].defaultMetrics = metrics;
    this.props.updateTreatment(treatments, () => 5);
  }

  update = (callBack, from) => {
    const {treatments = []} = this.props;
    if (this.handleMetricsErrors()) {
      return;
    }
    let allTreatments = [];
    let routineWizards = [];
    if (treatments && treatments.length > 0) {
      treatments.forEach((treatment, index) => {
        const {dosages} = treatment;
        if (from === 'SAC') {
          this.setState({saveWait: true});
        } else {
          this.setState({nextWait: true});
        }
        let contactObj = null;
        if (!treatment.prescribedBy && treatment.healthProviderName) {
          contactObj = {
            id: -1,
            givenName: treatment.healthProviderName,
            isCareTeam: true,
          }
        }
        let metricArray = [];
        const defaultMetrics = [];
        if (treatment.defaultMetrics && treatment.defaultMetrics.length > 0) {
          treatment.defaultMetrics.forEach((metric, index) => {
            if (metric.metric) {
              defaultMetrics.push(metric);
            } else if (metric.data.type === 'USER') {
              defaultMetrics.push({
                unit: metric.unit,
                metric: metric.data.id,
                id: -(index + 1),
              });
            } else {
              let uomId = null;
              if (metric.unit) {
                uomId = metric.unit;
              }
              const {unitsOfMeasureDigestProcessed} = this.props;
              const {unitsOfMeasure} = unitsOfMeasureDigestProcessed;
              let typeOfMeasurement = getTypeOfMeasurementByUomId(unitsOfMeasure, uomId)

              metricArray.push({
                metric: metric.data,
                isActive: true,
                id: -(index + 1),
                name: metric.data.name,
                createdOn: null,
                modifiedOn: null,
                artifactId: null,
                description: metric.data.description ? metric.data.description : null,
                typeOfMeasurement: typeOfMeasurement,
                metricGroup: metric.data.metricGroup ? metric.data.metricGroup : 1,
                displayProperties: {
                  color: 'rgba(113, 168, 220, 1)',
                  style: chartTypes.dash,
                  scaleMin: null,
                  scaleMax: null,
                },
                uomId: uomId,
              });
            }
          });
        }
        const addNewTherapie = {
          metrics: metricArray.length === 0 ? null : metricArray,
          contact: contactObj,
          treatment: {
            ...treatment,
            dosages: [
              {
                ...dosages,
              },
            ],
            displayProperties: {
              id: null,
              createdOn: null,
              modifiedOn: null,
              color: null,
              artifactId: null,
              dashedLine: true,
            },
            defaultMetrics,
          },
        };
        routineWizards.push(addNewTherapie);
        // this.updateTherapie(
        //   addNewTherapie,
        //   treatment.unitsList,
        //   index,
        //   treatments.length,
        //   allTreatments,
        //   callBack,
        //   from,
        // );
      });
      this.updateTherapie(
        routineWizards,
        treatments.length,
        allTreatments,
        callBack,
        from,
      );
    } else if (from === 'NEXT') {
      callBack();
    }
  };

  updateTherapie = (
    addNewTherapie,
    total,
    allTreatments,
    callBack,
    from,
  ) => {
    this.props.updateRoutineWizardTreatments(
      addNewTherapie,
      (success, data) => {

        if (success) {
          if (data && data.length > 0) {
            data.forEach(res => {
              let newMets = [];
              if (res.metricDefaultUnitOfMeasures && res.metricDefaultUnitOfMeasures.length > 0) {
                res.metricDefaultUnitOfMeasures.forEach(element => {
                  newMets.push({
                    ...element,
                    metric: element.metricId,
                    unit: element.uomId,
                  })
                })
              }
              const obj = {
                ...res.treatment,
                dosages: {
                  ...res.treatment?.dosages?.[0],
                },
  
                unitsList: addNewTherapie[0]?.treatment?.unitsList,
                isConfirm: true,
                defaultMetrics: [...res.treatment.defaultMetrics, ...newMets],
              };
              allTreatments.push(obj);
            })
            
          }

          // const obj = {
          //   ...data,
          //   dosages: {
          //     ...data.dosages[0],
          //   },

          //   unitsList: unitsList,
          //   isConfirm: true,
          // };
          // allTreatments.push(obj);
          //if (data.length === total) {
            this.props.updateTreatment(allTreatments, () => {
              callBack();
              const changeHasValue = hasValue(JSON.stringify(allTreatments));
              if (from === 'SAC') {
                this.setState({saveWait: false, hasValue: changeHasValue});
              } else {
                this.setState({nextWait: false, hasValue: changeHasValue});
              }
            });
          //}
        } else {
          if (from === 'SAC') {
            this.setState({saveWait: false});
          } else {
            this.setState({nextWait: false});
          }
        }
      },
      this.props.journalUserId,
    );
  };
  updateAutocompleteOpen = val => {
    this.setState({autocompleteOpen: val});
  };

  render() {
    const {
      activeTherapyTab,
      treatments = [],
      visibleCloseConfirmationModal,
      saveWait,
      nextWait,
      autocompleteOpen,
      saveDisable
    } = this.state;
    const {tabs, setActiveTab, windowWidth} = this.props;
    return (
      <>
        <View style={[styles.sectionContainer, styles.sectionContainerzIndex]}>
          <View style={[styles.secMainTitle, styles.comMgBot, styles.secRtPd]}>
            <Text style={[styles.textTitleExLgBold, styles.textThickGrey]} allowFontScaling={false}>
              Which medication/supplements or activities do you take?
            </Text>
          </View>

          {activeTherapyTab === 0 && (
            <MessageBox
              messageTitle={'What’s a DIN/NPN?'}
              message={
                <Text allowFontScaling={false}>
                  Licensed natural health products can be identified by the
                  8-digit natural product number ( NPN , or DIN-HM for
                  homeopathic medicines)
                </Text>
              }
            />
          )}
          {activeTherapyTab === 1 && (
            <MessageBox
              messageTitle={'What are Activities?'}
              message={
                <Text allowFontScaling={false}>
                  Activites may include running, cycling, yoga or social
                  prescribing such as laughing, music or art classes.
                </Text>
              }
            />
          )}
          <TherapieTypes
            activeTherapyTab={activeTherapyTab}
            selectTherapy={this.selectTherapy}
            isNew={true}
            windowWidth={windowWidth}
          />

          <View style={styles.therapySetup}>
            {activeTherapyTab === 1 && (
              <AddActivity
                updateInActive={val => this.setState({inActive: val})}
                updateAutocompleteOpen={this.updateAutocompleteOpen}
              />
            )}
            {activeTherapyTab === 0 && (
              <AddMedication
                updateInActive={val => this.setState({inActive: val})}
                updateAutocompleteOpen={this.updateAutocompleteOpen}
              />
            )}
            {(!Boolean(treatments) || (treatments && treatments.length === 0)) && (
              <View
                style={[
                  styles.addDefaultBox,
                  styles.flexJcCtAiCt,
                  styles.zIndexMin,
                ]}>
                <View style={[styles.addDefaultIcon]}>
                  <Image
                    style={styles.addIcon}
                    source={require('../../../../assets/icons/add-medication.png')}
                  />
                </View>
                <View style={styles.addDefaultData}>
                  <Text style={[styles.textPrimeSm, styles.textColorLight]}>
                    Add medication or supplements
                  </Text>
                </View>
              </View>
            )}

            <MedicationList updateTreatments={this.updateTreatments} clearErrors={this.clearErrors} />
            {Platform.OS !== WEB && Boolean(autocompleteOpen) && (
              <View style={[styles.zIndexMin, styles.autoSuggestSp]} />
            )}
          </View>
        </View>

        <MedicationsFooter
          back={() => {
            this.state.treatments && this.state.treatments.length > 0
              ? this.update(() => setActiveTab(tabs.profile), 'SAC')
              : setActiveTab(tabs.profile);
          }}
          next={() => {
            this.update(() => setActiveTab(tabs.symptom), 'NEXT');
          }}
          saveWait={saveWait}
          nextWait={nextWait}
          saveDisable={saveDisable}
        />
        <SetupCancelConfirmationBox
          visibleModal={visibleCloseConfirmationModal}
          cancel={this.hideCloseConfirmationModal}
          confirm={this.cancel}
          message="Cancel setting up your medications, supplements & activities?"
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    treatments: state.setup.treatments,
    journalUserId: state.uistate.journalUserId,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    saveDisable: state.setup.routineWizardSaveDisable?.treatment,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTreatment: (addNewTreatment, callBack) =>
      dispatch(actions.updateTreatment(addNewTreatment, callBack)),
    updateRoutineWizardTreatments: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.updateRoutineWizardTreatments(addNewTherapie, callBack, journalUserId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicationsContent);
