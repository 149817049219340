import React from 'react';
import {View, Text} from 'react-native';
import styles from '../../Css';

const validationMessage = props => {
  return (
    <View style={styles.validationResultBox}>
      <Text style={[styles.textPrimeExSm, styles.textRed]}  allowFontScaling={false}>
        Enter required field
      </Text>
    </View>
  );
};

export default validationMessage;
