import React, {Component} from 'react';
import {connect} from 'react-redux';
import {View, Text} from 'react-native';
import styles from '../../Css';
import {MenuOption} from 'react-native-popup-menu';
import * as actions from '../../store/actions/index';
import ActivityIndicatorCustom from '../ActivityIndicator/activityIndicator';
import {extractInformation} from "../../util/medications_and_supplements/healthDataParsingUtil";
class Medtication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treatmentInfoValues: null,
      ingredients: [],
      wait: true,
    };
  }

  componentDidMount() {
    const {identificationNumber, treatmentType} = this.props;
    if (identificationNumber && treatmentType) {
      this.getTreatmentInfoValues(identificationNumber, treatmentType);
    } else {
      this.setState({wait: false});
    }
  }

  getTreatmentInfoValues = (identificationNumber, treatmentType) => {
    const { getTreatmentInfo } = this.props;
    getTreatmentInfo(identificationNumber, treatmentType, (success, res) => {
      if (success) {
        const {value} = res;
        this.extractInformation(value);
      } else {
        this.setState({
          wait: false,
        });
      }
    })
  };
  extractInformation = treatmentInfoValues => {
    const extractedInformation = extractInformation(treatmentInfoValues);
    console.log("extracted information: " + JSON.stringify(extractedInformation));
    if (extractedInformation.route || extractedInformation.ingredients) {
      this.setState({
        ingredients: extractedInformation.ingredients,
        treatmentInfoValues: treatmentInfoValues,
        wait: false,
      });
    }
  };

  onPressViewFullTherapy = () => {
    const {treatment, handleRedirectMedications, onSelectTherapie} = this.props;
    if (treatment) {
      onSelectTherapie(treatment, () => {
        handleRedirectMedications();
      });
    }
  };
  render() {
    const {treatmentInfoValues, ingredients, wait} = this.state;
    const {
      brandName,
      drugIdentificationNumber,
      productName,
      licenceNumber,
    } = treatmentInfoValues ? treatmentInfoValues : {};
    const {treatmentType, showLink} = this.props;
    return (
      <MenuOption style={styles.comPd} onSelect={this.onPressViewFullTherapy}>
        <View
          style={[
            styles.fieldToolTipBlock,
            !treatmentInfoValues
              ? styles.toolTipInfoBox
              : this.props.windowWidth < 375
              ? styles.toolTipInfoBoxSm
              : this.props.windowWidth < 575
              ? styles.toolTipInfoBoxMd
              : styles.toolTipInfoBoxRg,
          ]}>
          {Boolean(treatmentInfoValues) && (
            <View style={[styles.comMgBot]}>
              <View style={[styles.comMgBotSm]}>
                <Text style={[styles.textPrimeMd, styles.textGreyDark]}  allowFontScaling={false}>
                  Product name:
                </Text>
                <Text style={[styles.textPrimeMdBold, styles.textGreyDark]}  allowFontScaling={false}>
                  {treatmentType === 3 ? productName : brandName}
                </Text>
              </View>
              <View style={[styles.flexRow]}>
                <Text style={[styles.textPrime, styles.textGreyDark]}  allowFontScaling={false}>
                  NPN:
                </Text>
                <Text style={[styles.textPrimeBold, styles.textGreyDark]}  allowFontScaling={false}>
                  {treatmentType === 3
                    ? licenceNumber
                    : drugIdentificationNumber}
                </Text>
              </View>

              <View
                style={[
                  styles.tableViewCom,
                  styles.grayBox,
                  styles.comMgTopSm,
                  !treatmentInfoValues
                    ? styles.toolTipInfoBox
                    : this.props.windowWidth < 375
                    ? styles.toolTipInfoBoxSm
                    : this.props.windowWidth < 575
                    ? styles.toolTipInfoBoxMd
                    : styles.toolTipInfoBoxRg,
                ]}>
                <View style={[styles.trTitleView]}>
                  <View style={[styles.tdColViewCom, styles.tdColLgViewCom]}>
                    <Text style={[styles.textMicSm, styles.grayboxTitle]}  allowFontScaling={false}>
                      Active Ingredients
                    </Text>
                  </View>
                  <View style={[styles.tdColViewCom, styles.tdColSmViewCom]}>
                    <Text style={[styles.textMicSm, styles.grayboxTitle]}  allowFontScaling={false}>
                      Strength
                    </Text>
                  </View>
                </View>
                {/* Loop Section Start */}
                {ingredients.map((item, index) => (
                  <View
                    style={[styles.trDataViewCom]}
                    key={'med_ingredients_' + index}>
                    <View style={[styles.tdColViewCom, styles.tdColLgViewCom]}>
                      <Text
                        style={[styles.textPrimeSmBold, styles.textGreyDark]}  allowFontScaling={false}>
                        {item.ingredient}
                      </Text>
                    </View>
                    <View style={[styles.tdColViewCom, styles.tdColSmViewCom]}>
                      <Text
                        style={[styles.textPrimeSmBold, styles.textGreyDark]}  allowFontScaling={false}>
                        {item.strength + ' '} {item.strengthUnit}
                      </Text>
                    </View>
                  </View>
                ))}

                {/* Loop Section End */}
              </View>
            </View>
          )}

          {Boolean(wait) && (
            <View
              style={[
                styles.indicatorFixed,
                styles.flexJcCtAiCt,
                styles.indToolBox,
              ]}>
              <ActivityIndicatorCustom pdZero={true} size="small" />
            </View>
          )}

          {Boolean(showLink) && (
            <View style={[styles.moreLink]}>
              {/* <TouchableOpacity onPress={this.onPressViewFullTherapy}> */}
                <Text style={[styles.textPrimeSm, styles.textOrange]}  allowFontScaling={false}>
                  View more information
                </Text>
              {/* </TouchableOpacity> */}
            </View>
          )}
        </View>
      </MenuOption>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSelectTherapie: (SelectedTherapie, callBack) =>
      dispatch(actions.selectTherapie(SelectedTherapie, callBack)),
    getTreatmentInfo: (din_npn, treatmentType, callBack) => 
      dispatch(actions.getTreatmentInfo(din_npn, treatmentType, callBack)),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(Medtication);
