import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import routinesReducer from "./reducers/journal";
import authReducer from "./reducers/auth";
import routineTemplateReducer from "./reducers/routineTemplate";
import thunk from "redux-thunk";
import axios from "../modules/redux-interceptor";
import axiosMiddleware from "redux-axios-middleware";
import therapieReducer from "./reducers/therapie";
import uistateReducer from "./reducers/uistate";
import contactsReducer from "./reducers/contacts";
import questionReducer from "./reducers/question";
import imagesDocumentsReducer from "./reducers/imagesDocuments";
import profileReducer from "./reducers/profile";
import setupReducer from "./reducers/setup";
import metricsReducer from "./reducers/metric";
import symptomsReducer from "./reducers/symptom";
import rollinggraphsReducer from "./reducers/rollinggraphs";
import accountReducer from "./reducers/account";
import caregiverReducer from "./reducers/caregiver";
import filterReducer from "./reducers/filter";
import ocrFilter from "./reducers/ocr";
import reportReducer from "./reducers/report";
import clinicalTrialsReducer from "./reducers/clinicalTrials";
import surveysReducer from "./reducers/surveys";
import communityGroupsReducer from "./reducers/communityGroups";
import peerToPeerReducer from './reducers/peerToPeer';
import redirect from "./reducers/redirect";
import device from "./reducers/device";
import healthJourneyReducer from "./reducers/healthJourney";
import healthJourneyFilterObjectsReducer from './reducers/healthJourneyFilterObjects';
import healthJourneyFilteringReducer from "./reducers/healthJourneyFiltering"
import userConsentForms from "./reducers/userConsentForms";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const rootReducer = combineReducers({
  auth: authReducer,
  routines: routinesReducer,
  therapie: therapieReducer,
  routineTemplate: routineTemplateReducer,
  uistate: uistateReducer,
  contacts: contactsReducer,
  question: questionReducer,
  imagesDocuments: imagesDocumentsReducer,
  profile: profileReducer,
  setup: setupReducer,
  metrics: metricsReducer,
  symptom: symptomsReducer,
  rollinggraphs: rollinggraphsReducer,
  account: accountReducer,
  caregiver: caregiverReducer,
  filter: filterReducer,
  ocr: ocrFilter,
  report: reportReducer,
  clinicalTrials: clinicalTrialsReducer,
  surveys: surveysReducer,
  communityGroups: communityGroupsReducer,
  peerToPeer: peerToPeerReducer,
  redirect: redirect,
  device: device,
  healthJourney: healthJourneyReducer,
  healthJourneyFilterObjects: healthJourneyFilterObjectsReducer,
  healthJourneyFiltering: healthJourneyFilteringReducer,
  userConsentForms: userConsentForms
});

const configureStore = () => {
  return createStore(
    rootReducer,
    process.env.NODE_ENV === 'production'
      ? applyMiddleware(axiosMiddleware(axios), thunk)
      : composeEnhancers(applyMiddleware(axiosMiddleware(axios), thunk))
  );
};

export default configureStore;
