// IMPORTANT
// All URLs MUST be kept on one line in order for build scripts to
// work properly

// Notes on URLs
// BASE_URL: do not leave a trailing /, some urls will break on the api
// BASE_URL_OCR: do not leave a trailing /
// BASE_URL_COMMUNITY_GROUPS: leave trailing / until we have time to test without
// BASE_URL_COGNITO: leaving trailing / until we have time to test without

export const BASE_URL = "https://play2.hanalyticssolutions.com";

//Please update for production
export const SHARE_URL = "https://play2.hanalyticssolutions.com/api/refer-friend";

export const BASE_URL_OCR = "https://k37dkrkdnf.execute-api.eu-west-1.amazonaws.com/qa";

// Auth URLs
export const BASE_URL_COMMUNITY_GROUPS = "https://woomxhpss7.execute-api.us-east-1.amazonaws.com/dev/";

export const BASE_URL_COGNITO = "https://zldpaesj14.execute-api.us-east-1.amazonaws.com/dev/";

export const DEEP_LINK_URL = "https://hanalytics-app.com";

export const LOGIN = '/api/login';
export const LOGIN_COGNITO = '/api/cognito-login';
export const LOG_OUT = '/api/logout';
export const LOG_OUT_COGNITO = '/api/cognito-logout';
export const KEEP_ALIVE = '/api/keepalive';
export const SIGNUP = '/api/register';
export const LOG_OUT_EVERYWHERE = '/api/logoutEverywhere';

// Journals URLs
export const CREATE_JOURNAL_ENTRY_BY_TYPE = '/api/journalentry/createnewbytype'; // Create a journal entry based on the given journal entry type.
export const DELETE_JOURNAL_ENTRY = '/api/journalentry/byid'; // Delete a journal entry
export const UPDATE_JOURNAL_ENTRY = '/api/journalentry'; // Save a journal entry
export const CLONE_JOURNAL_ENTRY = '/api/journalentry/clone/byid'; // Clone a journal entry. Returns a journal entry with the metric, symptoms, and treatment recordings copied.
export const FETCH_JOURNAL_ENTRY_BY_TIME_RANGE = '/api/journalentry/timerange'; // Fetch a list of journal entries whose entry date falls within a given time range
export const FETCH_ALL_JOURNAL_ENTRIES = '/api/userJournalDigest'; // Download entire user journal. Intended to be downloaded immediately after login.
export const DELETE_JOURNAL_ENTRY_TYPE = '/api/journalentrytype/byid'; // Delete an existing journal entry type (routine)
export const UPDATE_JOURNAL_ENTRY_TYPE = '/api/journalentrytype-new'; // Save an existing or new journal entry type (routine)
export const FETCH_JOURNAL_ENTRY_TYPE_BY_ID = '/api/journalentrytype/'; //Returns specific journal enty type (routine)
export const EDIT_JOURNAL_ENTRY = '/api/journalentry/edit/';
export const COPY_TO_NEW_JOURNAL_ENTRY = '/api/journalentry/copy-to-new/';
export const DELETE_DRAFT_JOURNAL_ENTRY = '/api/journalentry/delete-drafts';

// User Health Question
export const UPDATE_HEALTH_QUESTION_ANSWER = '/api/healthquestionanswer'; // Save a new or existing user Health Question answer

export const UPDATE_HEALTH_QUESTION = '/api/healthquestion'; // Save a new or existing user health question.

export const FETCH_USER_ATTACHMENTS = '/api/attachments/userartifact?artifactId='; //Get a list of user artifacts associated with a user artifact.

export const FETCH_USER_ATTACHMENTS_LIST = '/api/attachments/userartifact'; //Get a list of user attachments

export const FETCH_USER_ATTACHMENT_BY_ID = '/api/attachments/byid'; // Returns attachment file contents for download

export const DELETE_ATTACHMENT = '/api/attachment';

export const DELETE_HEALTH_QUESTION = '/api/healthquestion/byid'; //Delete an existing user health question.

// Routine Template
export const CREATE_NEW_ROUTINE = '/api/journalentry/createnewbytype';
// export const SAVE_JOURNAL_ENTRY = '/api/journalentry'; // Save a journal entry.
export const SAVE_JOURNAL_ENTRY = '/api/journalentry-new'; //temprory save journal

//Device
export const CONNECT_DEVICE = '/api/device/connect-device';
export const DISCONNECT_DEVICE = '/api/device/disconnect-device?deviceName={deviceName}';
export const CONNECT_HEXOSKIN = '/api/device/connect-hexoskin';
export const CONNECT_HEXOSKIN_WITH_ACCESS_CODE = '/api/device/connect-hexoskin-using-code';
export const CONNECT_APPLEWATCH = '/api/device/connect-applewatch';
export const HEXOSKIN_URL = 'https://shms.hexoskin.com/api/connect/';
export const HEXOSKIN_CLIENT_ID = '9VpTLgsu4C0LIjrEMBQkF7pD9BEBu6';
export const REDIRECT_URL = '/manage-devices';

//Therapie URLs

export const ADD_THERAPIE = '/api/treatment/createnew';
export const DELETE_THERAPIE = '/api/treatment/byid';
export const UPDATE_THERAPIE = '/api/treatment';
export const UPDATE_GRAPH_DISPLAY = '/api/treatment/displayproperties';
export const UPLOAD_USER_ATTACHMENT = '/api/attachment/upload';
export const CANCEL_UPLOAD_USER_ATTACHMENT = '/api/attachment/cancel';
export const GET_SYSTEM_DEFINED_ACTIVITIES = '/api/treatment/system-activity';
export const GET_TREATMENT_INFO = '/api/treatments/treatment?treatmentId={din_npn}&treatmentType={treatmentType}';

// Forgot Password
export const FORGOT_PASSWORD = '/api/requestpasswordreset';
export const RESET_PASSWORD = '/api/resetpassword';

//Contact URLs
export const DELETE_CONTACT = '/api/contact/byid';
export const UPDATE_CONTACT = '/api/contact';
export const ADD_CONTACT = '/api/contact/createnew';

// User Artifact Attachments
export const IMG_GET_DOWNLOAD_TOKEN = '/api/attachments/downloadtoken/byid?attachmentId=';
export const S3_ATTACHMENTS = '/api/s3attachments/byid?attachmentId=';
export const IMG_DOC_BY_ID = '/api/attachments/byid?attachmentId=';

//Profile URLs
export const UPDATE_PROFILE = '/api/profile';
export const UPLOAD_PROFILE_IMAGE = '/api/profile/image/upload';
export const GET_PROFILE_IMAGE = '/api/profile/image/byId';
export const CHANGE_PASSWORD = '/api/changePassword';
export const SIGNED_DEVICES = '/api/signeddevices';
export const DELETE_PROFILE_IMAGE = '/api/profile/image';
export const DELETE_PROFILE_IMAGE_PEER = '/api/peer/profileimage';
export const GET_TIME_ZONE_LIST = '/api/list-timezone';
export const UPDATE_TIME_ZONE_LIST = '/api/timezone';

export const SUBSCRIPTION_PLANS = '/api/subscription/plans';
export const SUBSCRIPTION = '/api/subscription/';
export const SUBSCRIPTION_CANCEL = '/api/subscription/cancel';

export const IOS_SUBSCRIPTION_STATUS = '/api/subscription/ios/status';
export const IOS_SUBSCRIPTION = '/api/subscription/ios';

//User Allergies
export const UPDATE_USER_ALLERGY = '/api/userallergy';
export const DELETE_USER_ALLERGY = '/api/userallergy/byid';
export const FETCH_ALL_SYSTEM_DEFINED_ALLERGIES = '/api/userallergy/system-allergy'; //Get system defined allergies

//User Conditions
export const UPDATE_USER_CONDITION = '/api/userconditions';
export const DELETE_USER_CONDITION = '/api/userconditions/byid';
export const METRICS_CONDITION = '/api/metrics/conditionId';
export const SYMPTOM_CONDITION = '/api/symptoms/conditionId';

//User Symptom
export const UPDATE_USER_SYMPTOM = '/api/usersymptom';
export const DELETE_SYMPTOM = '/api/usersymptom/byid';
export const FETCH_ALL_SYSTEM_DEFINED_SYMPTOMS = '/api/usersymptom/system-symptoms'; //Get system defined symptoms

//User Metric
export const UPDATE_USER_METRIC = '/api/usermetric';
export const UPDATE_USER_METRIC_DISPLAY_UOM = '/api/usermetricdisplayuom';

//Care giver access
export const SHARED_ACCESS_INVITATION = '/api/sharedaccessinvitation';
export const SHARED_ACCESS_PERMISSION = '/api/sharedaccesspermission';
export const SHARED_ACCESS_INVITATION_ACCEPT = '/api/sharedaccessinvitation/accept';
export const SHARED_ACCESS_INVITATION_DECLINE = '/api/sharedaccessinvitation/decline';

export const SHARED_ACCESS_INVITATION_RESEND = '/api/sharedaccessinvitation/resend';
export const SHARED_ACCESS_PERMISSION_CAREGIVER = '/api/sharedaccesspermission/caregiver';
export const SHARED_ACCESS_DISCONNECT = '/api/sharedaccessdisconnect';

//Settings - Metrics
export const CREATE_METRICS = '/api/usermetric';
export const CREATE_UOM = '/api/usermetricdisplayuom';
export const DELETE_METRIC = '/api/usermetric/byid';
export const GET_SYSTEM_UNIT_OF_MEASURE = '/api/usermetric/system-unit-of-measure';

//dashboard graph
export const UPDATE_DASHBOARD_GRAPH = '/api/dashboard';
export const DELETE_DASHBOARD_GRAPH = '/api/dashboard/byid';
export const DOWNLOAD_DASHBOARD_GRAPH = '/api/dashboard/';

//Account
export const DELETE_ACCOUNT = '/api/user';
export const RESET_EMAIL = '/api/recoveremailupdate';

//Push Notification
export const PUSH_NOTIFICATIONS = '/api/pushnotification';
export const REMOTE_PUSH_NOTIFICATION_TOKEN = '/api/pushNotificationToken';
export const REMINDER_SNOOZE = '/api/snoozePushNotification';
export const CLEAR_ALL_NOTIFICATION = '/api/notification/clear-all';

// OCR urls
export const OCR_DELETE_ATTACHMENT = '/deleteattachment';
export const OCR_SCAN_ATTACHMENT = '/startattachmentsscan';
export const OCR_SCAN_BUSINESSCARD = '/startbusinesscardscan';
export const OCR_BUSINESSCARD_JOB_RESULT = '/getbusinesscardjobresult';
export const OCR_SCAN_PRESCRIPTION = '/startprescriptionscan';
export const OCR_SEARCH_ATTACHMENTS = '/api/searchAttachments';
export const OCR_PRESCRIPTION_JOB_ID = '/getprescriptionjobresult';

//Clinical Trial
export const DELETE_CLINICAL_TRIAL = '/api/clinicaltrial/byId';
export const DELETE_ALL_CLINICAL_TRIALS = '/api/clinicaltrial/all';
export const DELETE_CLINICAL_TRIAL_LIST = '/api/clinicaltrial/list';
export const ADD_CLINICAL_TRIAL = '/api/clinicaltrial';
export const ADD_CLINICAL_TRIAL_LIST = '/api/clinicaltrial/list';
export const SEARCH_CLINICAL_TRIAL = '/clinicaltrial';
export const SAVE_SEARCH_CLINICAL_TRIAL = '/api/clinicaltrial/savedsearch';
export const CLINICALTRAIL_DISCLAIMER = '/api/clinicaltraildisclaimer';

//Report
export const UPDATE_USER_REPORT = '/api/userreport';
export const GET_ALL_USER_REPORT = '/api/userreports';
export const DELETE_USER_REPORT = '/api/userreport/byid';
export const GENERATE_USER_REPORT = '/api/userreport/generate';
export const UPLOAD_GRAPH_IMAGE_REPORT = '/api/graph/image';
export const SEND_EMAIL_USER_REPORT = '/api/userreport/email';
export const GRAPH_PDF = '/api/graphpdf';
//Health Survey
export const USER_HEALTH_SURVEY = '/api/userscheduledsurvey';
export const HEALTH_SURVEY = '/api/healthsurvey-notification';
export const HEALTH_SURVEY_ANSWER = '/api/surveyanswer';
export const GET_HEALTH_SURVEY_ANSWERS = '/api/healthsurveyanswer';
export const GET_HEALTH_SURVEY_QUESTION = '/api/userscheduledsurvey/{surveyID}/{notificationID}/question';
export const SEND_EMAIL_SURVEY_REPORT = '/api/health-survey-report/email';
export const GENERATE_SURVEY_REPORT = '/api/health-survey-report/{surveyID}/{notificationID}';

//user notificatio
export const USER_NOTIFICATION = '/api/notification';

//Dashboard setup
export const SETUP_STATUS = '/api/setupstatus';
export const ROUTINE_WIZARD_TREATMENTS = '/api/routine-wizard';
//Dashboard PremiumBanner close
export const DASHBOARD_PREMIUM_BANNER_CLOSE = '/api/hidepremiumbanner';

//Peer To Peer
export const PEER_TO_PEER_SCREEN_STATUS = '/api/peer/profilescreenstatus';
export const GET_LATEST_PEERS = '/api/peer/latest';
export const PEER_2_PEER_USERNAME_VALIDATION = '/api/peer/validatepeerusername';
export const UPDATE_PEER_2_PEER_PROFILE = '/api/peer/userprofile';
export const UPDATE_PEER_2_PEER_PROFILE_LIST_ITEMS_MEDICAL_CONDITIONS = '/api/peer/userprofile/conditions';
export const UPDATE_PEER_2_PEER_PROFILE_LIST_ITEMS_TREATMENTS = '/api/peer/userprofile/treatments';
export const UPDATE_PEER_2_PEER_PROFILE_LIST_ITEMS_SYMPTOMS = '/api/peer/userprofile/symptoms';
export const UPDATE_PEER_2_PEER_PROFILE_LIST_ITEMS_PERSONAL_DETAILS = '/api/peer/userprofile/personal-details';
export const UPDATE_PEER_2_PEER_PROFILE_LIST_ITEMS_LOCATION_DEATILS = '/api/peer/userprofile/location-details';
export const UPLOAD_P2P_PROFILE_IMAGE = '/api/peer/profileimage';
export const SEARCH_PEERS = '/api/peer/search';
export const SEND_CONNECTION_REQUEST = '/api/peer/connect';
export const UPDATE_PEER_PROFILE_TO_PUBLIC = '/api/peer/change-status';
//export const GET_MY_NETWORK_LIST = '/api/peer/my-network';
export const PROFILE_ACTION = '/api/peer/profile-action';
export const GET_PROFILE_CHANGES_STATUS = '/api/peer/profile/status';
export const CHANGE_PROFILE_EDIT_STATUS = '/api/peer/profile/status-seen';
export const GET_LATEST_REQUESTS_LIST = 'api/peer/profile-request-latest';
export const UPDATE_SEEN_REQUESTS = '/api/peer/profile-request-seen';
export const GET_TWILIO_TOKEN = '/api/twilio/token';
export const CHAT_NOTIFICATION_SEEN = 'api/peer/chat-notification-seen';

//Refer Friend
export const REFER_FRIEND_INVITATION = '/api/refer-friend/invitation';

//App version
export const APP_VERSION = '/api/app-version';
export const IGNORE_APP_UPDATE = '/api/ignore/app-update'

//Join study
export const JOIN_STUDY = '/api/participant/';
export const JOIN_STUDY_STATUS = '/api/participant/join-study/status/';

//Health library
export const GET_HEALTH_LIBRARY_RESOURCE_DETAILS = '/api/health-library/id/';
export const GET_ALL_HEALTH_LIBRARY_RESOURCES = '/api/health-library/all-resources';
export const SET_HEALTH_LIBRARY_SHOW_HIDE = '/api/health-library/change-status';
export const CHANGE_HEALTH_LIBRARY_RESOURCE_SEEN_STATUS = '/api/health-library/change-seen-status';
export const HEALTH_LIBRARY_SEARCH = '/api/health-library/directory-search';
export const MASSAGE_STATUS_CHANGES = '/api/health-library/id/{resourceId}/message-status';
export const SEARCH_CRITERIA = '/api/health-library/search-criteria';

//Health Journey
export const GET_ALL_HEALTH_JOURNEY_EVENT_LIST = 'api/healthjourney/events';
export const GET_HEALTH_JOURNEY_EVENT_BY_ID = '/api/healthjourney/event/{eventId}';
export const SAVE_AND_UPDATE_HEALTH_JOURNEY = '/api/healthjourney/event';
export const DELETE_HEALTH_JOURNEY ='/api/healthjourney/event/{eventId}';
export const HEALTH_JOURNEY_UPLOAD_ATTACHMENT = 'api/attachment/upload?{artifactId}&{uniqueId}&{eventId}';
export const HEALTH_JOURNEY_DELETE_ATTACHMENT = 'api/attachment?{eventId}';
export const GET_HEALTH_JOURNEY_TAGS_LIST = '/api/healthjourney/events/filter?filterKey={filterKey}&serviceType={serviceType}';

//schedulars
export const GET_PENDING_SCHEDULE_LIST = 'api/dashboard/schedule';
export const GET_OVERDUE_ADHOC_ENTRY = 'api/dashboard/schedule/getadhoc-entry/{entryId}/{entryTypeId}/{dayNumber}';
