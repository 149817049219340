import React from 'react';
import {View, TextInput} from 'react-native';
import styles from '../../Css';
import FieldTitleCommon from './fieldTitleCommon';
import * as FontScaling from '../../components/Text/fontScaling';
const inputCodeField = props => {
  return (
    <View style={[styles.textInputWrapper, styles.postRelative]}>
      <FieldTitleCommon
        fieldAlign={props.fieldAlign}
        fieldBg={props.fieldBg}
        title={props.title}
        requirdStar={props.requirdStar}
        fieldLt={props.fieldLt}
        fieldSm={props.fieldSm}
        fieldRg={props.fieldRg}
      />
      <TextInput
        style={[
          styles.textInputField,
          styles.fieldPdRtExLg,
          props.fieldBg && props.fieldBg === 'grey'
            ? styles.bgThinGrey
            : props.fieldBg && props.fieldBg === 'blue'
            ? styles.bgBlueExLight
            : props.fieldBg && props.fieldBg === 'transparent'
            ? styles.bgTransparent
            : styles.bgWhite,
          props.fieldAlign && props.fieldAlign === 'center'
            ? styles.textAlignCenter
            : styles.textAlignLeft,
          props.invalidCode
            ? styles.inValidColor
            : props.validCode
            ? styles.validGreenColor
            : styles.validColor,
          props.fieldStyle,
        ]}
        allowFontScaling={false}
        underlineColorAndroid="transparent"
        value={props.value ? props.value : ''}
        onChangeText={props.onChangeText}
        editable={props.editable}
        placeholder={props.placeholder}
        onFocus={props.onFocus}
        maxFontSizeMultiplier = {FontScaling.fontScaleField}
      />
      {/* <View style={[styles.btnActionView, styles.btnActionInfo]}>
        <Image
          style={styles.statusCorrectIcon}
          source={require('../../assets/icons/right-green-icon.png')}
          resizeMode={'contain'}
        />
      </View> */}
    </View>
  );
};

export default inputCodeField;
