import React, { Component } from 'react';
import { View, Platform } from 'react-native';
import { connect } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styles from '../../Css';
import Header from '../../components/Header/header';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import PageContainer from '../../components/PageLayout/PageContainer';
import DashboardWrapper from './DashboardWrapper';
import UpgradePremiumContent from '../../components/Premium/UpgradePremiumContent';
import OverviewModal from '../../components/MessageRibbon/overviewModal';
import * as actions from '../../store/actions/index';
import * as axiosCommon from '../../axios/axios-common';
import { IOS_SUBSCRIPTION } from '../../store/actions/urls';
import {
  DASHBOARD,
  JOURNALS,
  MEDICATIONS,
  PROFILE,
  OTHER,
  HELP,
} from '../../navigation/path';
import {
  Setup,
  TodaysScheduleSection,
  CheckInSection,
  MedicationSection,
  GraphSection,
  DashboardHOC,
} from '../../components/Dashboard';
import {
  toggleWatchOverview,
  toggleSetupModal,
  toggleVerificationEmailSent,
} from '../../store/actions/uistate';

import {
  IOS_IAP_USER_PURCHASE_INFO_STATUS,
  IOS_IAP_USER_PURCHASE_INFO_KEY,
} from '../../Constant/appleIosIap';
import ConfirmEmailMessageRibbon from '../../components/MessageRibbon/confirmEmailRibbon';
import EmailSentRibbon from '../../components/MessageRibbon/emailSentRibbon';
import { Auth } from 'aws-amplify';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { 
  checkKeys,
  dashboardCategories,
  handleCreateEntryNavigation,
  handleUpdateRoutineNavigation,
  handleCreateEntryFromEmailNavigation,
  handleSurveysNavigation } from '../../util/commonUiLogic';
import _ from 'underscore';
import { APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED } from '../../util/featureConfig';
import { IOS, TAB_BREAk_POINT, TAB_BREAk_POINT_END, WEB } from '../../util/platformWindowConfig';

const WIDTH_OF_COLLAPSED_SIDE_MENU = 58;
const MINIMUM_WIDTH_FOR_DASHBOARD_FOR_DUAL_COLUMN = 800;

const breadCrumb = [
  {
    link: DASHBOARD.path,
    name: DASHBOARD.title,
  },
];

let interval = null;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const { windowWidth, windowHeight, applicationSettingsDigest, visibleWatchOverview } = props;
    const { isSetupOn } = applicationSettingsDigest;
    this.state = {
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      dashboardContainerWidth: windowWidth - WIDTH_OF_COLLAPSED_SIDE_MENU, // Starting with a default value until we measure the actual width
      isSetupOn: isSetupOn,
      visibleWatchOverview: visibleWatchOverview
    };
  }

  componentDidMount() {
    const {
      commonErrorHandler,
      route,
      toggleVerificationEmailSent,
      showSuccessPopup,
      setJoinStudyData,
      emailVerified,
      pullSchedulars,
      journalUserId,
      token,
      tokenType
    } = this.props;
    if (Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED)) {
      if (!interval) pullSchedulars(journalUserId);
      else clearInterval(interval);
      interval = setInterval(() => {
        pullSchedulars(journalUserId);
      }, 5000);
    }
    if (Platform.OS === IOS) {
      this.getPersistedIosIapUserPurchaseInfo();
    }
    if (
      route?.params &&
      route?.params.errorDes
    ) {
      commonErrorHandler(route?.params.errorDes);
    }
    //reset
    toggleVerificationEmailSent(false);
    this.props.navigation.addListener('focus', () => {
      this.props.showMobileMenu();
      pendoTrackEvent('Dashboard@');
      if (emailVerified && route?.name === "joinstudy" && checkKeys(route?.params, ["accessCode", "email", "orgName", "profile"])) {
        setJoinStudyData(route?.params);
        showSuccessPopup(15, null, null);
      } else if (emailVerified && !_.isEmpty(this.props.joinStudyData)) {
        showSuccessPopup(15, null, null);
      } else if (!emailVerified && !_.isEmpty(this.props.joinStudyData)) {
        this.props.setJoinStudyData({});
      }
      if (token && tokenType) {
        this.handleEmailLinkNavigations(token, tokenType);
      }
    });
  }

  handleEmailLinkNavigations = (token, tokenType) => {
    const { reminderDateTime, journalEntryTypesProcessed, journalEntriesProcessed } = this.props;
    switch (tokenType) {
      case 'create-journal':
        handleCreateEntryNavigation(token, reminderDateTime, journalEntryTypesProcessed, this.props);
        break;
      case 'update-routine':
        handleUpdateRoutineNavigation(token, journalEntryTypesProcessed, this.props, {});
        break;
      case 'journal-from-email':
        handleCreateEntryFromEmailNavigation(token, journalEntriesProcessed, this.props);
        break;
      case 'survey-questions':
        handleSurveysNavigation(token, this.props);
        break;
      default:
        break;
    }
  }

  componentWillUnmount() {
    if (interval) clearInterval(interval);
    interval = null;
  }

  getPersistedIosIapUserPurchaseInfo = async () => {
    try {
      const jsonValue = await AsyncStorage.getItem(
        IOS_IAP_USER_PURCHASE_INFO_KEY,
      );
      const iosIapUserPurchaseInfo =
        jsonValue != null ? JSON.parse(jsonValue) : null;
      console.log(
        'Dashboard.IOS getPersistedIosIapUserPurchaseInfo | iosIapUserPurchaseInfo: ',
        iosIapUserPurchaseInfo,
      );

      if (
        iosIapUserPurchaseInfo &&
        iosIapUserPurchaseInfo.status &&
        iosIapUserPurchaseInfo.status ===
        IOS_IAP_USER_PURCHASE_INFO_STATUS.PENDING &&
        iosIapUserPurchaseInfo.transactionData &&
        iosIapUserPurchaseInfo.user &&
        iosIapUserPurchaseInfo.user.id &&
        iosIapUserPurchaseInfo.user.id === this.props.user.id
      ) {
        console.log(
          'Dashboard.IOS getPersistedIosIapUserPurchaseInfo | Sending Updated Infor',
        );
        this.sendPersistedIosIapUserPurchaseInfo(
          iosIapUserPurchaseInfo.transactionData,
        );
      } else if (
        iosIapUserPurchaseInfo &&
        iosIapUserPurchaseInfo.status &&
        iosIapUserPurchaseInfo.status ===
        IOS_IAP_USER_PURCHASE_INFO_STATUS.UPDATED
      ) {
        console.log(
          'Dashboard.IOS getPersistedIosIapUserPurchaseInfo | Removing Already Updated Info',
        );
        this.removePersistedIosIapUserPurchaseInfoOnSuccess();
      } else {
        console.log(
          'Dashboard.IOS getPersistedIosIapUserPurchaseInfo | No data to send.',
        );
      }
    } catch (error) {
      console.log(
        'Dashboard.IOS getPersistedIosIapUserPurchaseInfo | error: ',
        error,
      );
    }
  };

  removePersistedIosIapUserPurchaseInfoOnSuccess = async () => {
    try {
      await AsyncStorage.removeItem(IOS_IAP_USER_PURCHASE_INFO_KEY);
    } catch (error) {
      console.log(
        'Dashboard.IOS removePersistedIosIapUserPurchaseInfoOnSuccess | error: ',
        error,
      );
    }
  };

  sendPersistedIosIapUserPurchaseInfo = transactionData => {
    axiosCommon.axiosPOST(
      IOS_SUBSCRIPTION,
      response => {
        console.log(
          'Dashboard.IOS sendPersistedIosIapUserPurchaseInfo | response: ',
          response,
        );
        if (response.type === 'SUCCESS') {
          this.removePersistedIosIapUserPurchaseInfoOnSuccess();
          this.props.resetDataLogout();
          this.props.navigation.push(DASHBOARD.path);
        } else if (
          response.type === 'ERROR' &&
          response.errorDto === 'SESSION_TIME_OUT'
        ) {
          console.log("SESSION_TIME_OUT, logging out; sendPersistedIosIapUserPurchaseInfo ");
          this.props.logout();
        } else {
          this.props.commonErrorHandler(
            'Some error occured. Relaunching the app is recommended.',
          );
        }
      },
      transactionData,
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { windowWidth, windowHeight, applicationSettingsDigest, dashboardScheduleData = {}, visibleWatchOverview } = this.props;
    const { isSetupOn } = applicationSettingsDigest;

    if (windowWidth !== nextState.windowWidth || windowHeight !== nextState.windowHeight || isSetupOn !== nextState.isSetupOn || visibleWatchOverview !== nextState.visibleWatchOverview) {
      return true;


    }

    if (JSON.stringify(dashboardScheduleData) !== JSON.stringify(nextProps.dashboardScheduleData || {})) {
      return true;
    }

    return false;
  }

  static getDerivedStateFromProps(props, state) {
    const { windowWidth, windowHeight, applicationSettingsDigest, visibleWatchOverview } = props;
    const { isSetupOn } = applicationSettingsDigest;
    if (
      windowWidth !== state.windowWidth ||
      windowHeight !== state.windowHeight ||
      isSetupOn !== state.isSetupOn ||
      visibleWatchOverview !== state.visibleWatchOverview
    ) {
      if (Platform.OS === WEB && isSetupOn && !state.isSetupOn) {
        props.navigation.reset({
          index: 0,
          routes: [{ name: DASHBOARD.path }],
        })
      }
      return {
        windowWidth: windowWidth,
        windowHeight: windowHeight,
        isSetupOn: isSetupOn,
        dashboardContainerWidth: windowWidth - WIDTH_OF_COLLAPSED_SIDE_MENU,
        visibleWatchOverview: visibleWatchOverview
      };
    }

    return null;
  }

  handleCreateNewRoutine = (journalName, view = false) => {
    if (this.props.windowWidth < TAB_BREAk_POINT_END) {
      this.props.navigation.reset({
        index: 0,
        routes: [{
          name: view ? JOURNALS.viewJournalPath : JOURNALS.addJournalPath,
          params: { journalName: journalName }
        }],
      });

    } else {
      this.props.navigation.reset({
        index: 0,
        routes: [{
          name: JOURNALS.path,
          params: {
            journalPath: view ? JOURNALS.viewJournalPathSuffix : JOURNALS.addJournalPathSuffix,
            journalName: journalName
          }
        }],
      });
    }
  };

  redirectViewTherapie = () => {
    this.props.navigation.push(MEDICATIONS.viewTherapiesPath);
  };

  redirectProfile = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: PROFILE.path }],
    });
  };

  redirectAddTherapie = () => {
    this.props.navigation.push(
      MEDICATIONS.editTherapiePath, {
      globalPath: DASHBOARD.path,
    },
    );
  };

  redirectDashboard = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: DASHBOARD.path }],
    });
  };
  redirectSetup = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: OTHER.setupPath }],
    });
  };

  redirectPremiumPage = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: PROFILE.profilePremiumAccountPath }],
    });
  };

  resendVerificationMail = () => {
    Auth.verifyCurrentUserAttribute('email')
      .then(() => {
        this.props.toggleVerificationEmailSent(true);
      })
      .catch(err => {
        console.log('err');
      });
  };

  contactSupport = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: HELP.path }],
    });
  };

  letsGetStartedOnPress = () => {
    this.props.toggleWatchOverviewVideo(false);
    if (this.props.emailVerified) {
      if (!this.state.isSetupOn) {
        this.props.showSetup(this.props.journalUserId, false, () => {
          this.props.toggleSetupModal(true);
        });
      } else {
        this.props.toggleSetupModal(true);
      }
    } else {
      this.resendVerificationMail();
    }
  };

  handleDashboardContainerLayout(event) {
    if (event?.nativeEvent?.layout?.width) {
      const { width } = event.nativeEvent.layout;
      this.setState({ dashboardContainerWidth: width });
    }
  }

  getInsightsComponent(isDualColumn, visibleGraph, onHideShowGraph) {
    return <DashboardWrapper
      type={dashboardCategories.INSIGHTS}
      style={styles.comMgTopMd}
      visibleGraph={visibleGraph}
      onHideShowGraph={onHideShowGraph}
      nativeId={'dashboardGraph'}
      nativeIdShowHide={'dashboardGraphShowHide'}
    >
      <GraphSection
        redirectPremiumPage={this.redirectPremiumPage}
        navigation={this.props.navigation}
      />
    </DashboardWrapper>;
  }

  render() {
    const {
      emailVerified,
      userEmail,
      visibleWatchOverview,
      sentVerificationMail,
      navigation,
      visibleGraph,
      onHideShowGraph,
      dashboardScheduleData
    } = this.props;

    const { windowWidth, isSetupOn, dashboardContainerWidth } = this.state;

    const isDualColumn = dashboardContainerWidth >= MINIMUM_WIDTH_FOR_DASHBOARD_FOR_DUAL_COLUMN;

    return (
      <>
        <View style={[styles.pageBodyWrapper]}>
          {Platform.OS === WEB && (
            <OverviewModal
              windowWidth={windowWidth}
              visibleWatchOverview={visibleWatchOverview}
              letsGetStartedOnPress={this.letsGetStartedOnPress}
            />
          )}
          <Header
            breadCrumb={breadCrumb}
            showFilers={false}
            showBack={false}
            showClearFilters={false}
            isHome={true}
            showNotify={true}
            redirectProfile={this.redirectProfile}
            index={DASHBOARD.index}
            navigation={this.props.navigation}
            route={this.props.route}
          />
          <SpaceBar />
          {!emailVerified && !sentVerificationMail && (
            <ConfirmEmailMessageRibbon
              resendEmail={this.resendVerificationMail}
            />
          )}
          {!emailVerified && sentVerificationMail && (
            <EmailSentRibbon
              resendEmail={this.resendVerificationMail}
              emailSentTo={userEmail}
              contactSupport={this.contactSupport}
            />
          )}
          <DashboardHOC layoutGreyVisible={!emailVerified}>
            <PageContainer dashboardScreen={windowWidth >= TAB_BREAk_POINT} smScreenFull={windowWidth < TAB_BREAk_POINT}>
              {emailVerified && (
                <UpgradePremiumContent
                  redirectPremiumPage={this.redirectPremiumPage}
                />
              )}
              {isSetupOn && (
                <Setup
                  redirectSetup={this.redirectSetup}
                  redirectDashboard={this.redirectDashboard}
                />
              )}

              <View
                style={[
                  styles.secArea,
                  styles.secSpPdBot,
                  styles.contColFul,
                  !isDualColumn ? styles.flexCol : styles.flexRow]}
                onLayout={event => this.handleDashboardContainerLayout(event)}
              >
                <View style={!isDualColumn ? styles.contColFul : [styles.fieldColHalf, styles.rtPdMd]}>
                  {
                    Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED) &&
                      Boolean(dashboardScheduleData) &&
                      Boolean(dashboardScheduleData.routines) &&
                      Boolean(dashboardScheduleData.surveys)
                      ?
                      (
                        <DashboardWrapper
                          type={dashboardCategories.SCHEDULES}
                          navigation={navigation}
                          windowWidth={windowWidth}
                          scheduleCount={(dashboardScheduleData?.routines.length ?? 0) + (dashboardScheduleData?.surveys.length ?? 0)}
                          nativeIdShowHide={'dashboardSchedulesShowHide'}
                        >
                          <TodaysScheduleSection
                            navigation={navigation}
                          />
                        </DashboardWrapper>
                      ) : null
                  }


                  <DashboardWrapper
                    type={dashboardCategories.ROUTINES}
                    nativeId={'dashboardRoutines'}
                    nativeIdInner={'dashboardRoutineItems'}
                    style={Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED) ? styles.comMgTopMd : styles.noMgTop}
                    nativeIdShowHide={'dashboardRoutinesShowHide'}
                  >
                    <CheckInSection
                      handleCreateNewRoutine={this.handleCreateNewRoutine}
                      navigation={navigation}
                    />
                  </DashboardWrapper>

                  {isDualColumn &&
                    this.getInsightsComponent(windowWidth, visibleGraph, onHideShowGraph)
                  }
                </View>
                <View style={!isDualColumn ? styles.contColFul : [styles.fieldColHalf, styles.ltPdMd]}>
                  <DashboardWrapper
                    type={dashboardCategories.MEDICATIONS}
                    style={!isDualColumn ? styles.comMgTopMd : styles.noMgTop}
                    nativeId={'dashboardMedications'}
                    nativeIdInner={'dashboardMedicationItems'}
                    nativeIdShowHide={'dashboardMedicationsShowHide'}
                  >
                    <MedicationSection
                      redirectAddTherapie={this.redirectAddTherapie}
                      type={dashboardCategories.MEDICATIONS}
                      navigation={navigation}
                      redirectViewTherapie={this.redirectViewTherapie}
                    />
                  </DashboardWrapper>
                  <DashboardWrapper
                    type={dashboardCategories.ACTIVITIES}
                    style={styles.comMgTopMd}
                    nativeId={'dashboardActivities'}
                    nativeIdInner={'dashboardActivityItems'}
                    nativeIdShowHide={'dashboardActivitiesShowHide'}
                  >
                    <MedicationSection
                      redirectAddTherapie={this.redirectAddTherapie}
                      type={dashboardCategories.ACTIVITIES}
                      navigation={navigation}
                      redirectViewTherapie={this.redirectViewTherapie}
                    />
                  </DashboardWrapper>
                  {!isDualColumn &&
                    this.getInsightsComponent(windowWidth, visibleGraph, onHideShowGraph)
                  }
                </View>
              </View>
              {/* <TouchableOpacity style={styles.customBtn}>
                <Text style={[styles.titleText, styles.submitTextExSm]}>
                  Customize Dashboard
                </Text>
              </TouchableOpacity> */}
              {windowWidth < TAB_BREAk_POINT && <View style={styles.spaceBox} />}
            </PageContainer>
          </DashboardHOC>
        </View>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowWidth: state.uistate.windowWidth,
    windowHeight: state.uistate.windowHeight,
    visibleGraph: state.rollinggraphs.dashboardGraph,
    dashboardProcessed: state.routines.dashboardProcessed,
    journalUserId: state.uistate.journalUserId,
    hasNotch: state.uistate.hasNotch,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    emailVerified: state.auth.emailVerified,
    userEmail: state.routines.user.email,
    visibleWatchOverview: state.uistate.visibleWatchOverview,
    user: state.routines.user,
    conditions: state.setup.conditions,
    userConditions: state.routines.userConditions,
    treatments: state.setup.treatments,
    userTreatments: state.routines.treatments,
    symptoms: state.setup.symptoms,
    userSymptoms: state.routines.symptomsDigest
      ? state.routines.symptomsDigest.userSymptoms
      : [],
    metrics: state.setup.metrics,
    userDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.userDefinedMetrics
      : [],
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    sentVerificationMail: state.uistate.sentVerificationMail,
    joinStudyData: state.uistate.joinStudyData,
    dashboardScheduleData: state.uistate.dashboardScheduleData,
    token: state.uistate.token,
    tokenType: state.uistate.tokenType,
    reminderDateTime: state.uistate.reminderDateTime,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    symptomsDigestProcessed: state.routines.symptomsDigestProcessed,
    treatmentsProcessed: state.routines.treatmentsProcessed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetDataLogout: () => dispatch(actions.resetDataLogout()),
    logout: () => dispatch(actions.logout()),
    isFromLogin: () => dispatch(actions.isFromLogin()),
    toggleWatchOverviewVideo: (val) => dispatch(toggleWatchOverview(val)),
    toggleSetupModal: (val) => dispatch(toggleSetupModal(val)),
    showSetup: (journalUserId, updateGlobally, callBack) =>
      dispatch(actions.showSetup(journalUserId, callBack, updateGlobally)),

    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    toggleVerificationEmailSent: val =>
      dispatch(toggleVerificationEmailSent(val)),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    setJoinStudyData: (data) => dispatch(actions.setJoinStudyData(data)),
    onHideShowGraph: value => dispatch(actions.onHideShowGraph(value)),
    showSuccessPopup: (id, graph, data) => dispatch(actions.showSuccessPopup(id, graph, data)),
    pullSchedulars: journalUserId => dispatch(actions.pullSchedulars(journalUserId)),
    onCreateNewRoutine: (journalEntry, callBack, journalUserId) =>
      dispatch(actions.createNewRoutine(journalEntry, callBack, journalUserId)),
    setToken: (token, type, reminderDateTime) => dispatch(actions.setToken(token, type, reminderDateTime)),
    setEntryType: journalEntryType =>
      dispatch(actions.setSelectedJournalEntryType(journalEntryType)),
    onSetSelectedJournalEntry: selectedId =>
      dispatch(actions.setSelectedJournalEntry(selectedId)),
    getAllUserHealthSurvey: (journalUserId, callBack) =>
      dispatch(actions.getAllUserHealthSurvey(journalUserId, callBack)),
    getUserHealthSurveyById: (id, journalUserId, callBack) =>
      dispatch(actions.getUserHealthSurveyById(id, journalUserId, callBack)),
    selectSurvey: (
      id,
      surveyId,
      name,
      isClosed,
      modifiedOn = null,
      description = null,
      duration = null,
      s3ImageUrl = null,
      surveyStatus = null,
      questionOrder = 0,
    ) =>
      dispatch(
        actions.selectSurvey(
          id,
          surveyId,
          name,
          isClosed,
          modifiedOn,
          description,
          duration,
          s3ImageUrl,
          surveyStatus,
          questionOrder,
        ),
      ),
    resetSurvey: () => dispatch(actions.resetSurvey()),
    setActiveTab: activeTabIndex =>
      dispatch(actions.setActiveTab(activeTabIndex)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
