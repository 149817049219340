import React from 'react';
import {View} from 'react-native';
import styles from '../../../../Css';
import ArrowPrevButton from '../../../Button/arrowPrevButton';
import ArrowNextButton from '../../../Button/arrowNextButton';

const healthDataFooter = props => {
  const {back, next, saveWait, nextWait, saveDisable} = props;

  return (
    <View style={[styles.modalFixedSection, styles.zIndexMin]}>
      <View style={[styles.btnViewBorder]} />
      <View style={[styles.btnFixedArea]}>
        <View style={styles.btnFooterRow}>
          <View style={styles.btnSubSection}>
            <ArrowPrevButton
              btnText={'Save & Back'}
              touchableOnPress={back}
              prevWait={saveWait}
              disabled={saveDisable}
            />
          </View>

          <View style={styles.btnSubSection}>
            <ArrowNextButton
              btnText={'Save & Next'}
              touchableOnPress={next}
              disabled={nextWait || saveWait || saveDisable}
              nextWait={nextWait}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default (healthDataFooter);
