import React, {Component} from 'react';
import {View, Text, TouchableOpacity,Image} from 'react-native';
import styles from '../../../../Css';
import stylesResponsive from '../../../../CssResponsive';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import ReminderAdd from '../../../RoutineTemplate/Reminder/Add';
import {RoutineFooter} from '../SetupFooter';
import InputField from '../../../../components/TextInput/inputField';
import {
  SetUpReminderSection,
  RoutineItemSelectComponent,
} from './RoutineComponent';
import SetupCancelConfirmationBox from '../setupCancelConfirmationBox';
import _ from 'underscore';
import WarningDuplication from '../../../Warning/warningDuplication';

class RoutineContent extends Component {
  constructor(props) {
    super(props);
    const {
      windowWidth,
      windowHeight,
      newRoutine,
      treatments,
      symptoms,
      metrics,
      itemList,
      reminders
    } = props;

    const journalEntries = Object.values(this.props.journalEntriesProcessed);

    this.state = {
      newRoutine: newRoutine ? newRoutine : {},
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      yesNo:
        newRoutine && newRoutine.reminders && newRoutine.reminders.length > 0
          ? true
          : false,
      name: newRoutine && newRoutine.name ? newRoutine.name : '',
      reminders: newRoutine && newRoutine.reminders ? newRoutine.reminders : [],
      validateName: true,
      visibleCloseConfirmationModal: false,
      inActive: true,
      isEmptyRoutine: true,
      defaultTreatments: !_.isEmpty(itemList) && JSON.stringify(itemList?.treatments) !== JSON.stringify(this.getId(treatments))  
        ? this.getSelectedTreatment(treatments)
        : newRoutine && newRoutine?.defaultTreatments 
            ? newRoutine?.defaultTreatments 
            : this.getSelectedTreatment(treatments),
      defaultSymptoms: !_.isEmpty(itemList) && JSON.stringify(itemList?.symptoms) !== JSON.stringify(this.getId(symptoms))  
        ? this.getSelectedSymptoms(symptoms)
        : newRoutine && newRoutine?.defaultSymptoms 
            ? newRoutine?.defaultSymptoms 
            : this.getSelectedSymptoms(symptoms),
      defaultMetrics: !_.isEmpty(itemList) && JSON.stringify(itemList?.metrics) !== JSON.stringify(this.getId(metrics))  
        ? this.getSelectedMetrics(metrics)
        : newRoutine && newRoutine?.defaultMetrics 
            ? newRoutine?.defaultMetrics 
            : this.getSelectedMetrics(metrics),
      symptoms: this.getSymptoms(symptoms, journalEntries),
      metrics: this.getMetrics(metrics, journalEntries),
      duplicatedName: false,
      pushNotification: reminders && reminders.length > 0 ? reminders[0].pushNotification : true,
      emailNotification: reminders && reminders.length > 0 ? reminders[0].emailNotification : false,
      showRadioForm: false,
      showNotificationCheckBox:false
    };
  }

  componentDidMount = () => {
    const {
      defaultMetrics,
      defaultSymptoms,
      defaultTreatments,
      newRoutine,
      name,
      reminders,
    } = this.state;

    let tempRoutine = newRoutine;
    tempRoutine = {
      //Comment out previous logic to fixed ZI-2336 reopen issue
      // ...(defaultTreatments &&
      //   !_.isEmpty(defaultTreatments) && {defaultTreatments}),
      // ...(defaultSymptoms && !_.isEmpty(defaultSymptoms) && {defaultSymptoms}),
      // ...(defaultMetrics && !_.isEmpty(defaultMetrics) && {defaultMetrics}),
      ...{defaultTreatments},
      ...{defaultSymptoms},
      ...{defaultMetrics},
      name: name,
      reminders: reminders,
    };
    this.props.updateRoutine(tempRoutine, () => {
      this.updateIsEmptyRoutine();
    });
    
    if(name) {
      if (name && name.trim() !== '' && this.checkDuplicatedNames(name.trim().toLowerCase())) {
        this.setState({ duplicatedName: true });
      }
    }
  };

  componentWillUnmount = () => {
    const {updateDataAddedFromRW, treatments, symptoms, metrics,} = this.props;
    let itemList = {
      treatments: this.getId(treatments),
      symptoms: this.getId(symptoms),
      metrics: this.getId(metrics),
    }
    updateDataAddedFromRW(itemList);
  }

  checkDuplicatedNames = name => {
    const { journalEntryTypes = [] } = this.props;
    if (journalEntryTypes.some(element => element && element.name && element.name.trim().toLowerCase() === name)) {
      return true;
    }
    return false;
  }

  getMetrics = (metr, journalEntries) => {
    const {userDefinedMetrics} = this.props;
    let metrics = [];
    let temptMetrics = [];
    if (!metr) {
      metr = [];
    }
    if (journalEntries && journalEntries.length > 0) {
      journalEntries.forEach(record => {
        if (record.metrics && record.metrics.length > 0) {
          record.metrics.forEach(re => {
            metrics.push(re.metric);
          });
        }
      });
    }
    if (
      userDefinedMetrics &&
      userDefinedMetrics.length > 0 &&
      metrics &&
      metrics.length > 0
    ) {
      userDefinedMetrics.forEach(res =>
        metrics.forEach(re => res.id === re && temptMetrics.push(res)),
      );
    }

    if (userDefinedMetrics && userDefinedMetrics.length > 0) {
      userDefinedMetrics
        .filter(met => met.publishedBy === 'DEFAULT')
        .forEach(res => temptMetrics.push(res));
    }
    return [...new Set(metr.concat(temptMetrics))];
  };

  getSymptoms = (sympt, journalEntries) => {
    const {userDefinedSymptoms} = this.props;
    let symptoms = [];
    let tempSymptoms = [];
    if (!sympt) {
      sympt = [];
    }
    if (journalEntries && journalEntries.length > 0) {
      journalEntries.forEach(record => {
        if (record.symptoms && record.symptoms.length > 0) {
          record.symptoms.forEach(re => {
            symptoms.push(re.symptom);
          });
        }
      });
    }

    if (
      userDefinedSymptoms &&
      userDefinedSymptoms.length > 0 &&
      symptoms &&
      symptoms.length > 0
    ) {
      userDefinedSymptoms.forEach(res =>
        symptoms.forEach(re => res.id === re && tempSymptoms.push(res)),
      );
    }

    if (userDefinedSymptoms && userDefinedSymptoms.length > 0) {
      userDefinedSymptoms
        .filter(sym => sym.publishedBy === 'DEFAULT')
        .forEach(res => tempSymptoms.push(res));
    }
    return [...new Set(sympt.concat(tempSymptoms))];
  };

  getSelectedTreatment = treatments => {
    const {newRoutine} = this.props;
    let tempTreat = {};
    let itemList = this.getId(treatments) ? this.getId(treatments) : {};
    if (
      newRoutine &&
      newRoutine.defaultTreatments &&
      Object.keys(newRoutine.defaultTreatments).length > 0
    ) {
      tempTreat = {...itemList, ...newRoutine.defaultTreatments};
    } else {
      tempTreat = itemList;
    }
    return tempTreat;
  };

  getSelectedSymptoms = symptoms => {
    const {newRoutine} = this.props;
    let tempTreat = {};
    let itemList = this.getId(symptoms) ? this.getId(symptoms) : {};
    if (
      newRoutine &&
      newRoutine.defaultSymptoms &&
      Object.keys(newRoutine.defaultSymptoms).length > 0
    ) {
      tempTreat = {...itemList, ...newRoutine.defaultSymptoms};
    } else {
      tempTreat = itemList;
    }
    return tempTreat;
  };

  getSelectedMetrics = metrics => {
    const {newRoutine} = this.props;
    let tempTreat = {};
    let itemList = this.getId(metrics) ? this.getId(metrics) : {};
    if (
      newRoutine &&
      newRoutine.defaultMetrics &&
      Object.keys(newRoutine.defaultMetrics).length > 0
    ) {
      tempTreat = {...itemList, ...newRoutine.defaultMetrics};
    } else {
      tempTreat = itemList;
    }
    return tempTreat;
  };

  getId = itemList => {
    let obj = {};
    if (itemList && itemList.length > 0) {
      itemList.forEach(res => res.id !== -1 && (obj[res.id] = res.id));
    }
    return obj;
  };

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth, newRoutine} = props;
    if (
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth
    ) {
      return {
        windowWidth: windowWidth,
        windowHeight: windowHeight,
      };
    }

    return {newRoutine: newRoutine ? newRoutine : {}};
  }

  updateIsEmptyRoutine = () => {
    let {newRoutine} = this.state;

    const metricsEmpty = _.isEmpty(newRoutine.defaultMetrics);
    const symptomsEmpty = _.isEmpty(newRoutine.defaultSymptoms);
    const TreatmentsEmpty = _.isEmpty(newRoutine.defaultTreatments);
    const name =
      !newRoutine.name || (newRoutine.name && newRoutine.name.trim() === '')
        ? true
        : false;

    this.setState({
      isEmptyRoutine:
        name || (metricsEmpty && symptomsEmpty && TreatmentsEmpty),
      inActive: name || (metricsEmpty && symptomsEmpty && TreatmentsEmpty),
    });
  };

  onPressYesNo = val => {
    if (val) {
      this.setState({
        yesNo: val,
      });
    } else {
      this.setState({
        yesNo: val,
        reminders: []
      }, () => {
        this.updateNewReminders([]);
      });
    }
  };

  updateName = name => {
    const {newRoutine} = this.state;

    let validateName = true;
    if (!name || (name && name.trim() === '')) {
      validateName = false;
    }

    let haveDuplicatedName = false;
    if (name && name.trim() !== '' && this.checkDuplicatedNames(name.trim().toLowerCase())) {
      haveDuplicatedName = true;
    }
    this.setState({
      name: name,
      validateName: validateName,
      inActive: !validateName,
      duplicatedName: haveDuplicatedName,
    });
    newRoutine.name = name;
    const {updateRoutine} = this.props;
    updateRoutine(newRoutine, () => {
      this.updateIsEmptyRoutine();
    });
  };
  updateDefaultTreatments = defaultTreatments => {
    let {newRoutine} = this.state;

    newRoutine.defaultTreatments = defaultTreatments;
    const {updateRoutine} = this.props;
    updateRoutine(newRoutine, () => {
      this.updateIsEmptyRoutine();
    });
  };
  updateDefaultMetrics = defaultMetrics => {
    let {newRoutine} = this.state;

    newRoutine.defaultMetrics = defaultMetrics;
    const {updateRoutine} = this.props;
    updateRoutine(newRoutine, () => {
      this.updateIsEmptyRoutine();
    });
  };
  updateDefaultSymptoms = defaultSymptoms => {
    let {newRoutine} = this.state;

    newRoutine.defaultSymptoms = defaultSymptoms;
    const {updateRoutine} = this.props;
    updateRoutine(newRoutine, () => {
      this.updateIsEmptyRoutine();
    });
  };

  updateNewReminders = reminders => {
    let {newRoutine} = this.state;

    newRoutine.reminders = reminders;
    const {updateRoutine} = this.props;
    updateRoutine(newRoutine, () => {
      this.updateIsEmptyRoutine();
    });
  };

  update = () => {
    let {newRoutine = {}, createRoutine, journalUserId} = this.props;
    if (
      !newRoutine ||
      !newRoutine.name ||
      (newRoutine.name && newRoutine.name.trim() === '') 
    ) {
      this.setState({validateName: false, inActive: true});
    }else if( this.checkDuplicatedNames(newRoutine.name.trim().toLowerCase())) {
       this.setState({validateName: false});
    }else {
      this.setState({saveWait: true});
      const {
        defaultTreatments = {},
        defaultSymptoms = {},
        reminders = [],
        defaultMetrics = {},
        name,
      } = newRoutine;

      const treatments = this.getDefaultTreatments(defaultTreatments);
      const metrics = this.getDefaultMetrics(defaultMetrics);
      const symptoms = this.getDefaultSymptoms(defaultSymptoms);
      const validReminders = this.getReminders(reminders);

      const payload = {
        journalEntryType: {
          id: -1,
          defaultMetrics: metrics,
          defaultTreatments: treatments,
          defaultSymptoms: symptoms,
          reminders: validReminders,
          color: 'rgba(248, 121, 60, 1)',
          name: name,
          description: '',
          isActive: true,
        },
      };
      createRoutine(payload, journalUserId, (success, data) => {
        this.setState({
          saveWait: false,
          newRoutine: {},
          name: '',
          yesNo: false,
        });
        const {
          updateRoutine,
          updateTreatment,
          updateCondition,
          updateSymptom,
          updateMetric,
          tabs,
          updateDashboardGraphSuccess,
          onSelectGraphItem,
          updateDataAddedFromRW,
          setPreviousGraphId,
          selectedGraphId,
        } = this.props;
        let graphId = null;
        if (success) {
          if(data.userDashboard) {
            graphId = data.userDashboard.id;
            updateDashboardGraphSuccess(data.userDashboard);
            if(data.userDashboard.status === 'ENABLED') {
              setPreviousGraphId(selectedGraphId);
              onSelectGraphItem(data.userDashboard.id);  
            }  
          }
        }
        updateRoutine(null, () => {
          updateTreatment(null, () => {});
          updateCondition(null, () => {});
          updateSymptom(null, () => {});
          updateMetric(null, () => {});
          updateDataAddedFromRW({})
        });
        this.props.onSaveTriggered(graphId, tabs.profile);
      });
    }
  };

  getReminders = reminders => {
    const newReminders = [];
    reminders.forEach((item, index) => {
      if (item.accept) {
        newReminders.push({...item, id: -(index + 1), reminderType: 'DEFAULT'});
      }
    });
    return newReminders;
  };
  getDefaultTreatments = defaultTreatments => {
    const treatments = [];
    Object.values(defaultTreatments).forEach((treatment, index) => {
      treatments.push(treatment);
    });

    return treatments;
  };
  getDefaultMetrics = defaultMetrics => {
    const metrics = [];
    Object.values(defaultMetrics).forEach((metric, index) => {
      metrics.push(metric);
    });

    return metrics;
  };
  getDefaultSymptoms = defaultSymptoms => {
    const symptoms = [];
    Object.values(defaultSymptoms).forEach((symptom, index) => {
      symptoms.push(symptom);
    });

    return symptoms;
  };
  cancel = () => {
    const {updateRoutine, closeModal} = this.props;
    this.setState({name: '', validateName: true, yesNo: false});
    updateRoutine({}, () => {
      closeModal();
      this.hideCloseConfirmationModal();
    });
  };

  hideCloseConfirmationModal = () => {
    this.setState({visibleCloseConfirmationModal: false});
  };

  pushNotificationSelection = () => {
    this.setState({
      pushNotification: !this.state.pushNotification
    });
  }

  emailNotificationSelection = () => {
    this.setState({
      emailNotification: !this.state.emailNotification
    });
  }
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {
      yesNo,
      validateName,
      name,
      saveWait,
      visibleCloseConfirmationModal,
      inActive,
      isEmptyRoutine,
      defaultTreatments,
      defaultSymptoms,
      defaultMetrics,
      symptoms,
      metrics,
      reminders,
      windowWidth,
      duplicatedName,
      showRadioForm,
      showNotificationCheckBox,
      pushNotification,
      emailNotification
    } = this.state;
    const {treatments, tabs, setActiveTab} = this.props;
    return (
      <>
        <View style={[styles.sectionContainer, styles.sectionContainerzIndex]}>
          <View style={[styles.secMainTitle, styles.comMgBot, styles.secRtPd]}>
            <Text style={[styles.textTitleExLgBold, styles.textThickGrey]} allowFontScaling={false}>
              Great! Let’s setup your routine
            </Text>
          </View>

          <View style={styles.sectionView}>
            <View style={styles.fieldRow}>
              <View style={styles.fieldRowWrapperFull}>
                <View style={styles.fieldColStretch}>
                  <InputField
                    title={'Give it a name'}
                    value={name}
                    invalid={!validateName || duplicatedName}
                    onChangeText={this.updateName}
                    fieldLt={true}
                    requirdStar={'*'}
                    // warningDuplication={duplicatedName}
                  />
                </View>
                {duplicatedName && 
                  <WarningDuplication 
                    errorMessage={"A routine with this name already exists. Please choose a new name or suffix."}
                    error
                  />
                }
              </View>
            </View>
          </View>

          {treatments && treatments.length > 0 && (
            <RoutineItemSelectComponent
              title={'Choose your medications/supplements and activites'}
              itemList={treatments ? treatments : []}
              selectedItem={defaultTreatments ? defaultTreatments : {}}
              updateDefaultValue={this.updateDefaultTreatments}
            />
          )}
          {symptoms && symptoms.length > 0 && (
            <RoutineItemSelectComponent
              title={'What symptoms do you want to track?'}
              itemList={symptoms ? symptoms : []}
              selectedItem={defaultSymptoms ? defaultSymptoms : {}}
              updateDefaultValue={this.updateDefaultSymptoms}
            />
          )}

          {metrics && metrics.length > 0 && (
            <RoutineItemSelectComponent
              title={'Do you want to record any health data?'}
              itemList={metrics ? metrics : []}
              selectedItem={defaultMetrics ? defaultMetrics : {}}
              updateDefaultValue={this.updateDefaultMetrics}
            />
          )}

          <SetUpReminderSection
            yesNo={yesNo}
            onPressYesNo={this.onPressYesNo}
          />
          {Boolean(yesNo) && (
            <>

              <View style={[styles.secSubTitle, styles.comMgTop]}>
                <Text style={[styles.textPrimeBold]} allowFontScaling={false}>Set Reminder</Text>
              </View>
              {Boolean(showNotificationCheckBox) && (
                <View style={[styles.colPdLtRtBot, styles.bgWhite, styles.fieldErrorContent, styles.pdLeftZero]}>
                  <View style={styles.flexRow}>
                    <View style={styles.answerSingleSm}>
                      <TouchableOpacity
                        style={[
                          styles.answerOptMgBot,
                          styles.answerViewWrapper,
                        ]}
                        onPress={this.pushNotificationSelection}
                      >
                        <View style={styles.answerView}>
                          <Image
                            style={[
                              styles.radioIcon,
                              styles.radioOptSp,
                            ]}
                            source={
                              this.state.pushNotification ? require('../../../../assets/toggleTick/check-tick.png') : require('../../../../assets/toggleTick/check-untick.png')
                            }
                          />
                          <Text style={styles.textPrime} allowFontScaling={false}>
                            Push notification
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View style={styles.answerSingleSpace}></View>
                    <View style={styles.answerSingleSm}>
                      <TouchableOpacity
                        style={[
                          styles.answerOptMgBot,
                          styles.answerViewWrapper,
                        ]}
                        onPress={this.emailNotificationSelection}
                      >
                        <View style={styles.answerView}>
                          <Image
                            style={[
                              styles.radioIcon,
                              styles.radioOptSp,
                            ]}
                            source={
                              this.state.emailNotification ? require('../../../../assets/toggleTick/check-tick.png') : require('../../../../assets/toggleTick/check-untick.png')
                            }
                          />
                          <Text style={styles.textPrime} allowFontScaling={false}>
                            Email notification
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              )}
              {(Boolean(pushNotification) || Boolean(emailNotification)) ?
              <ReminderAdd
                reminders={[]}
                newReminders={reminders ? reminders : []}
                updateNewReminders={this.updateNewReminders}
                updateReminders={() => {}}
                plusButton={false}
                type={'RT'}
                windowWidth={windowWidth}
                reminderSection={true}
                reminderToolTip={true}
                //remindersCount={reminders ? reminders.length : 0}
                remindersCount={0}
                routineWizard={true}
                setShowRadioForm={(showRadioForm) => {
                  this.setState({
                    showRadioForm: showRadioForm
                  })
                }}
                showRadioForm={showRadioForm}
                pushNotification={pushNotification}
                emailNotification={emailNotification}
              />
                : null}

              {!Boolean(showRadioForm) && (
                <View style={[styles.barTopSp, styles.comSpLg]}>
                <View style={[stylesRes.routineReminderContent]} dataSet={{media: ids.routineReminderContent}}>
                  <TouchableOpacity
                    style={styles.addReminderButton}
                    onPress={() => this.setState({ showRadioForm: true, showNotificationCheckBox: true })}
                  >
                    <Image
                      style={styles.plusIcon}
                      source={require("../../../../assets/icons/plus-icon-gray.png")}
                    />
                    <Text style={[styles.primarySmBold, styles.addAReminderTxtColor]}>
                      Add a Reminder
                    </Text>
                  </TouchableOpacity>
                </View>
                </View>
              )}
            </>


          )}
        </View>

        <RoutineFooter
          back={() => {
            setActiveTab(tabs.healthData);
          }}
          onSaveAndClose={() => {
            this.update();
          }}
          saveWait={saveWait}
          isEmptyRoutine={isEmptyRoutine}
          windowWidth={windowWidth}
          inActive={inActive}
        />
        <SetupCancelConfirmationBox
          visibleModal={visibleCloseConfirmationModal}
          cancel={this.hideCloseConfirmationModal}
          confirm={this.cancel}
          message="Cancel setting up your routine?"
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowWidth: state.uistate.windowWidth,
    windowHeight: state.uistate.windowHeight,
    journalUserId: state.uistate.journalUserId,
    treatments: state.setup.treatments,
    symptoms: state.setup.symptoms,
    metrics: state.setup.metrics,
    newRoutine: state.setup.newRoutine,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    userDefinedSymptoms: state.routines.symptomsDigest.userSymptoms,
    userDefinedMetrics: state.routines.metricsDigest.userDefinedMetrics,
    journalEntryTypes: state.routines.journalEntryTypes,
    itemList: state.setup.itemList,
    selectedGraphId: state.uistate.selectedGraphId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoutine: (addNewRoutine, callBack) =>
      dispatch(actions.updateRoutine(addNewRoutine, callBack)),
    createRoutine: (payload, journalUserId, callBack) =>
      dispatch(
        actions.updateJournalEntryType(payload, journalUserId, callBack),
      ),
    updateTreatment: (addNewTreatment, callBack) =>
      dispatch(actions.updateTreatment(addNewTreatment, callBack)),
    updateCondition: (addNewCondition, callBack) =>
      dispatch(actions.updateCondition(addNewCondition, callBack)),
    updateSymptom: (addNewSymptom, callBack) =>
      dispatch(actions.updateSymptom(addNewSymptom, callBack)),
    updateMetric: (addNewMetric, callBack) =>
      dispatch(actions.updateMetric(addNewMetric, callBack)),
    setActiveTab: value => dispatch(actions.setSetupActiveTab(value)),
    updateDashboardGraphSuccess: (data) =>
      dispatch(actions.updateDashboardGraphSuccess(data)),
    onSelectGraphItem: (graphId) => dispatch(actions.selectGraphItem(graphId)),
    updateDataAddedFromRW: (itemList) => dispatch(actions.updateDataAddedFromRW(itemList)),
    setPreviousGraphId: graphId => dispatch(actions.setPreviousGraphId(graphId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoutineContent);
