import axios from "./axios-config-common-ocr";

export const axiosGET = (url, calbackFunc, data) => {
  let body = {};
  if (data) {
    body = data;
  }
  axios
    .get(url, { params: body })
    .then(response => {
      calbackFunc({ type: "SUCCESS", responseDto: response });
    })
    .catch(error => {
      if (error.response === undefined) {
        calbackFunc({ type: "ERROR", errorDto: "SESSION_TIME_OUT" });
      } else {
        calbackFunc({ type: "ERROR", errorDto: error.response });
      }
    });
};

export const axiosPOST = (url, calbackFunc, data) => {
  axios
    .post(url, data)
    .then(response => {
      calbackFunc({ type: "SUCCESS", responseDto: response });
    })
    .catch(error => {
      if (error.response === undefined) {
        calbackFunc({ type: "ERROR", errorDto: "SESSION_TIME_OUT" });
      } else {
        calbackFunc({ type: "ERROR", errorDto: error.response });
      }
    });
};

export const axiosFileUploadPOST = (url, calbackFunc, data) => {
  axios
    .post(url, data, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      calbackFunc({ type: "SUCCESS", responseDto: response });
    })
    .catch(error => {
      if (error.response === undefined) {
        calbackFunc({ type: "ERROR", errorDto: "SESSION_TIME_OUT" });
      } else {
        calbackFunc({ type: "ERROR", errorDto: error.response });
      }
    });
};

export const axiosImageUploadPUT = (url, calbackFunc, data) => {
  axios
    .put(url, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      calbackFunc({ type: "SUCCESS", responseDto: response });
    })
    .catch(error => {
      if (error.response === undefined) {
        calbackFunc({ type: "ERROR", errorDto: "SESSION_TIME_OUT" });
      } else {
        calbackFunc({ type: "ERROR", errorDto: error.response });
      }
    });
};

export const axiosPUT = (url, calbackFunc, data) => {
  axios
    .put(url, data)
    .then(response => {
      calbackFunc({ type: "SUCCESS", responseDto: response });
    })
    .catch(error => {
      if (error.response === undefined) {
        calbackFunc({ type: "ERROR", errorDto: "SESSION_TIME_OUT" });
      } else {
        calbackFunc({ type: "ERROR", errorDto: error.response });
      }
    });
};

export const axiosDELETE = (url, calbackFunc, payload) => {
  axios
    .delete(url, { data: payload })
    .then(response => {
      calbackFunc({ type: "SUCCESS", responseDto: response });
    })
    .catch(error => {
      if (error.response === undefined) {
        calbackFunc({ type: "ERROR", errorDto: "SESSION_TIME_OUT" });
      } else {
        calbackFunc({ type: "ERROR", errorDto: error.response });
      }
    });
};

export const axiosDownload = (header, fileName) => {
  axios(header).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
};

export const axiosGETImage = (url, calbackFunc) => {
  axios
    .get(url, {
      headers: {
        responseType: "arraybuffer"
      }
    })
    .then(response => {
      calbackFunc({ type: "SUCCESS", responseDto: response });
    })
    .catch(error => {
      if (error.response === undefined) {
        calbackFunc({ type: "ERROR", errorDto: "SESSION_TIME_OUT" });
      } else {
        calbackFunc({ type: "ERROR", errorDto: error.response });
      }
    });
};

export const axiosGETPrescription = (url, calbackFunc, data) => {
  let body = {};
  if (data) {
    body = data;
  }
  axios
    .get(url, { params: body })
    .then(response => {
      if (response.data === "IN_PROGRESS") {
        calbackFunc({ type: "ERROR", errorDto: "IN_PROGRESS" });
      } else {
        calbackFunc({ type: "SUCCESS", responseDto: response });
      }
    })
    .catch(error => {
      if (error.response === undefined) {
        calbackFunc({ type: "ERROR", errorDto: "SESSION_TIME_OUT" });
      } else {
        calbackFunc({ type: "ERROR", errorDto: error.response });
      }
    });
};
