import React from 'react';
import {View, ScrollView, SafeAreaView, Image} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Management from '../../components/Signup/management';
import StoreCommon from '../../components/Signup/store/storeCommon';
import Contact from '../Signup/contact';
import { TAB_BREAk_POINT } from '../../util/platformWindowConfig';

const stylesRes = stylesResponsive.getProcessedStyles().styles;
const ids = stylesResponsive.getProcessedStyles().ids;
const pageLayout = props => {
  let fullWidth = props.windowWidthView;
  let fullHeight = props.windowHeightView;
  let dynamicWHBot = 0;
  let dynamicWHTop = 0;
  let dynamicWHRight = 0;
  let dynamicTotWBot = 0;
  let botSpace = 50;
  dynamicWHTop = (28 / 100) * fullHeight;
  dynamicWHBot = (50 / 100) * fullHeight;
  dynamicWHRight = (16 / 100) * fullHeight;
  dynamicTotWBot = dynamicWHBot + dynamicWHRight + botSpace;
  if (dynamicWHBot > fullWidth || dynamicWHTop > fullWidth) {
    dynamicWHBot = fullWidth;
    dynamicWHRight = (10 / 100) * fullHeight;
    dynamicWHTop = fullWidth;
  } else if (fullWidth > 767 && dynamicTotWBot < fullWidth) {
    dynamicWHTop = (40 / 100) * fullHeight;
    dynamicWHBot = (62 / 100) * fullHeight;
    dynamicWHRight = (28 / 100) * fullHeight;
  }
  return (
    <React.Fragment>
      {props.bgBlue && fullWidth < TAB_BREAk_POINT ? (
        <SafeAreaView style={[styles.bgBlueLight, styles.flexCom]}>
          <React.Fragment>
            <View
              style={[
                stylesRes.loadBgTopViewOne,
                {width: dynamicWHTop, height: dynamicWHTop},
              ]}>
              <Image
                style={styles.imgCover}
                source={require('../../assets/background-images/load-blue-bg-top.png')}
              />
            </View>

            <View
              style={[
                stylesRes.loadBgBotViewOne,
                {width: dynamicWHBot, height: dynamicWHBot},
              ]}>
              <Image
                style={styles.imgCover}
                source={require('../../assets/background-images/load-blue-bg-bottom.png')}
              />
            </View>
          </React.Fragment>
          <ScrollView
            styles={[stylesRes.contentAreaView, stylesRes.contBgTransparent]}>
            <View style={[styles.gridSection]}>
              <View
                style={[
                  stylesRes.containerSingleFull,
                  stylesRes.contBgTransparent,
                  {minHeight: props.windowHeightView},
                ]}>
                <View
                  style={[
                    stylesRes.containerSingleMax,
                    {minHeight: props.windowHeightSmView},
                    stylesRes.contBgTransparent,
                  ]}
                  dataSet={{media: ids.containerSingleMax}}>
                  <View
                    style={[
                      styles.gridWrapperFull,
                      props.contentCenter ? styles.flexJcCt : styles.flexJcSb,
                    ]}>
                    <StoreCommon windowWidth={fullWidth}/>
                    {props.children}
                    <Contact css={[stylesRes.boxWrapperViewMg]}/>
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        </SafeAreaView>
      ) : (
        <SafeAreaView
          style={[
            props.bgBlue ? styles.bgBlueLight : styles.contentAreaViewBg,
            styles.flexCom,
          ]}>
            {Boolean(props.bgBlue) && (
            <React.Fragment>
              <View
                style={[
                  stylesRes.loadBgTopViewOne,
                  {width: dynamicWHTop, height: dynamicWHTop},
                ]}>
                <Image
                  style={styles.imgCover}
                  source={require('../../assets/background-images/load-blue-bg-top.png')}
                />
              </View>

              <View
                style={[
                  stylesRes.loadBgBotViewOne,
                  {width: dynamicWHBot, height: dynamicWHBot},
                ]}>
                <Image
                  style={styles.imgCover}
                  source={require('../../assets/background-images/load-blue-bg-bottom.png')}
                />
              </View>
            </React.Fragment>
          )}

          <ScrollView
            styles={[
              stylesRes.contentAreaView,
              props.bgBlue
                ? styles.contBgTransparent
                : styles.contentAreaViewBg,
            ]}
            >
            <View style={styles.gridSection}>
              <View
                style={[
                  stylesRes.containerSingleFull,
                  props.bgBlue ? stylesRes.contBgTransparent : stylesRes.pageBg,
                  {minHeight: props.windowHeightView},
                ]}>
                <View style={stylesRes.containerFullRow}>
                  <View
                    style={[
                      stylesRes.containerSingleCol,
                      stylesRes.singleColSignUpLeft,
                    ]}>
                    <View
                      style={[
                        stylesRes.containerSingleMax,
                        props.bgBlue
                          ? stylesRes.containerShadowNone
                          : stylesRes.containerShadow,
                        {minHeight: props.windowHeightSmView},
                        props.bgBlue
                          ? stylesRes.contBgTransparent
                          : stylesRes.containerBgWhite,
                      ]}
                      dataSet={{media: [ids.containerSingleMax, ids.containerShadow]}}>
                      <View
                        style={[
                          styles.gridWrapperFull,
                          props.contentCenter
                            ? styles.flexJcCt
                            : styles.flexJcSb,
                        ]}>
                        {props.children}
                        <Contact css={[stylesRes.boxWrapperViewMg]}/>
                      </View>
                    </View>
                  </View>

                  <View
                    style={[
                      stylesRes.containerSingleCol,
                      stylesRes.singleColSignUpRight,
                    ]}
                    dataSet={{media: ids.singleColSignUpRight}}>
                    <View
                      style={[
                        stylesRes.containerSingleMax,
                        props.bgBlue
                          ? stylesRes.containerShadowNone
                          : stylesRes.containerShadow,
                        {minHeight: props.windowHeightSmView},
                        props.bgBlue
                          ? stylesRes.contBgTransparent
                          : stylesRes.containerBgWhite,
                      ]}
                      dataSet={{media: [ids.containerSingleMax, ids.containerShadow]}}>
                      <View
                        style={[
                          styles.gridWrapperFull,
                          props.contentCenter
                            ? styles.flexJcCt
                            : styles.flexJcSb,
                        ]}>
                        <Management
                          ttoggleLearnHowPrivacyPolicy={
                            props.ttoggleLearnHowPrivacyPolicy
                          }
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        </SafeAreaView>
      )}
    </React.Fragment>
  );
};

export default (pageLayout);
