import React from 'react';
import {View, Text} from 'react-native';
import styles from '../../../Css';
import moment from 'moment';
import {getFrequencyType} from '../../../util/commonUiLogic';
import * as FieldDetails from '../../TextInput/fieldDetails';
import TextFrame from '../../TextInput/textFrame';
import ColorItemPreview from '../../../components/ColorPicker/ColorItemPreview';
import * as ColorCode from '../../../components/ColorPicker/ColorCodes';

const viewMedication = props => {
  const {selectedTherapie, contactsProcessed} = props;

  const {dosages, prescribedBy, displayProperties, treatmentType} = selectedTherapie;
  const {start, end, quantity, frequency, frequencyType, instructions, units} =
    dosages && dosages.length > 0 ? dosages[0] : {};

  const color =
    displayProperties && displayProperties.color
      ? displayProperties.color
      : treatmentType === 3
      ? ColorCode.BGSUPPLEMENT
      : ColorCode.BGMEDICATION;
  return (
    <View style={styles.cardArea}>
      <View style={styles.cardDetailSec}>
        <View style={[styles.therapyDetails, styles.contFull]}>
          <View style={styles.contRow}>
            <View style={[styles.cardContentFull, styles.cardRowPdLtRtTop]}>
              <View style={[styles.fieldRowWrapper]}>
                <View style={styles.fieldColStretch}>
                  <TextFrame
                    title={'Quantity'}
                    fieldBg={FieldDetails.BGWHITE}
                    fieldLt={true}
                    fieldValue={quantity}
                    editable={false}
                  />
                </View>
                <View style={styles.fieldColSpMd} />
                <View style={styles.fieldColStretch}>
                  <TextFrame
                    title={'Measure'}
                    fieldBg={FieldDetails.BGWHITE}
                    fieldValue={units ? units : ''}
                    fieldLt={true}
                    editable={false}
                  />
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.contRowSp]}>
            <View style={[styles.comBotBorder]} />
          </View>

          {/* <View style={[styles.contRow]}>
            <View style={[styles.cardContent, styles.cardRowPdLtRtTop]}>
              <View style={styles.contCol}>
                <View style={[styles.contTitle, styles.fiedBotPd]}>
                  <Text
                    style={[styles.textSm, styles.bold, styles.textColorLight]}
                  >
                    Quantity
                  </Text>
                </View>
                <View style={styles.contDesc}>
                  <Text style={[styles.textTitleBold]}>
                    {quantity ? quantity : ""}
                  </Text>
                </View>
              </View>
              <View style={styles.contCol}>
                <View style={[styles.contTitle, styles.fiedBotPd]}>
                  <Text
                    style={[styles.textSm, styles.bold, styles.textColorLight]}
                  >
                    Units
                  </Text>
                </View>
                <View style={styles.contDesc}>
                  <Text style={[styles.textTitleBold]}>
                    {units ? units : ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.contRowSp]}>
            <View style={[styles.comBotBorder]}></View>
          </View> */}

          <View style={[styles.contRow]}>
            <View style={[styles.cardContent, styles.cardRowPdLtRtTop]}>
              <View style={styles.contCol}>
                <View style={[styles.contTitle, styles.fiedBotPd]}>
                  <Text
                    style={[styles.textSm, styles.bold, styles.textColorLight]}
                    allowFontScaling={false}
                    >
                    Frequency
                  </Text>
                </View>
                <View style={styles.contDesc}>
                  <Text style={[styles.textTitleBold]} allowFontScaling={false}>
                    {frequency
                      ? frequency + ' times ' + getFrequencyType(frequencyType)
                      : ''}
                  </Text>
                </View>
              </View>
              <View style={styles.contCol}>
                <View style={[styles.contTitle, styles.fiedBotPd]}>
                  <Text
                    style={[styles.textSm, styles.bold, styles.textColorLight]}
                    allowFontScaling={false}
                    >
                    Graph Colour
                  </Text>
                </View>
                <View style={styles.contDesc}>
                  <ColorItemPreview setColor={color} />
                </View>
              </View>
            </View>
          </View>

          <View style={[styles.contRowSp]}>
            <View style={[styles.comBotBorder]} />
          </View>

          <View style={[styles.contRow]}>
            <View style={[styles.cardContent, styles.cardRowPdLtRtTop]}>
              <View style={styles.contCol}>
                <View style={[styles.contTitle, styles.fiedBotPd]}>
                  <Text
                    style={[styles.textSm, styles.bold, styles.textColorLight]}
                    allowFontScaling={false}
                    >
                    Instructions
                  </Text>
                </View>
                <View style={styles.contDesc}>
                  <Text style={[styles.textTitleBold]} allowFontScaling={false}>{instructions}</Text>
                </View>
              </View>
              <View style={styles.contCol}>
                <View style={[styles.contTitle, styles.fiedBotPd]}>
                  <Text
                    style={[styles.textSm, styles.bold, styles.textColorLight]}
                    allowFontScaling={false}
                    >
                    Prescriber
                  </Text>
                </View>
                <View style={styles.contDesc}>
                  <Text style={[styles.textTitleBold]} allowFontScaling={false}>
                    {prescribedBy && contactsProcessed[prescribedBy]
                      ? contactsProcessed[prescribedBy].givenName
                        ? contactsProcessed[prescribedBy].givenName
                        : contactsProcessed[prescribedBy].organization
                      : ''}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={[styles.contRowSp]}>
            <View style={[styles.comBotBorder]} />
          </View>

          <View style={[styles.contRow]}>
            <View style={[styles.cardContent, styles.cardRowPdLtRtTop]}>
              <View style={styles.contCol}>
                <View style={[styles.contTitle, styles.fiedBotPd]}>
                  <Text
                    style={[styles.textSm, styles.bold, styles.textColorLight]}
                    allowFontScaling={false}
                    >
                    Start date:
                  </Text>
                </View>
                <View style={styles.contDesc}>
                  <Text style={[styles.textTitleBold]} allowFontScaling={false}>
                    {start ? moment(start).format("DD MMM 'YY") : ''}
                  </Text>
                </View>
              </View>
              <View style={styles.contCol}>
                <View style={[styles.contTitle, styles.fiedBotPd]}>
                  <Text
                    style={[styles.textSm, styles.bold, styles.textColorLight]}
                    allowFontScaling={false}
                    >
                    End date:
                  </Text>
                </View>
                <View style={styles.contDesc}>
                  <Text style={[styles.textTitleBold]} allowFontScaling={false}>
                    {end ? moment(end).format("DD MMM 'YY") : ''}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default (viewMedication);
