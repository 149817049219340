import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from '../../Css';

const swipe = () => {
  return (
    <View style={[styles.feedbackBlock, styles.comSpMd]}>
      <TouchableOpacity>
        <View style={[styles.swipeBoxWrapper, styles.greyBoxBorder]}>
          <View style={[styles.detailTitleBlock]}>
            <Text style={[styles.textPrimeBold, styles.textColorLight]} allowFontScaling={false}>
              Swipe to Give Your valuable Feedback{' '}
            </Text>
          </View>

          <View style={[styles.swipeDetailBlock]}>
            <View style={[styles.swipeIconView]}>
              <Image
                source={require('../../assets/contact/swipe.png')}
                style={styles.swipeIcon}
              />
            </View>
            <View style={[styles.swipeTextView]}>
              <Text style={[styles.textPrime]} allowFontScaling={false}>
                Swipe left with 2 fingers to report bugs or provide suggestions
                for improvement.
              </Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (swipe);
