import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from '../../../Css';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import CircleButtonCom from '../../Button/circleButtonCom';
import stylesResponsive from "../../../CssResponsive";

const imageSorter = type => {
  switch (type) {
    case 5: //activity
      return therapyImages.activity;
    default:
      return therapyImages.medication;
  }
};

const therapyImages = {
  medication: {
    image: require('../../../assets/notify-icons/notify-medication.png'),
  },
  activity: {
    image: require('../../../assets/notify-icons/notify-activity.png'),
  },
};
class MedicationItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {treatment, onPressItem, showPopup, selected, id} = this.props;
    const {
      treatmentType,
      name,
      drugIngredients = null,
      medicinalIngredients = null,
    } = treatment;
    const therapyImages = imageSorter(treatmentType);

    return (
      <>
        <View style={[styles.itemsContainerRow, styles.itemRowMg]}>
          <View style={styles.itemsContainerCol} nativeID={treatmentType === 5 
              ? 'dashboardActivityItem' 
              : 'dashboardMedicationItem'
            }>
            <TouchableOpacity onPress={onPressItem}>
              <View style={[styles.itemsContainerSm, styles.containerPinkMd]}>
                <View style={ stylesRes.leftMgSmCom } dataSet={{media: ids.leftMgSmCom}} />
                <View style={[styles.flexCom, styles.flexRow]}>
                  <View style={[styles.itemRecordBox]}>
                    <Image
                      style={styles.itemRecordIcon}
                      source={therapyImages.image}
                    />
                  </View>

                  <View style={[styles.itemTextBox, styles.flexJcCt]}>
                    <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                      <Text style={[styles.textPrimeBold]} allowFontScaling={false}>{name.trim()}</Text>
                      {Boolean(drugIngredients) && Boolean(drugIngredients[0]) && (
                        <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                          {' '}{drugIngredients[0].strength}
                          {drugIngredients[0].strengthUnit}
                        </Text>
                      )}
                      {Boolean(medicinalIngredients) && Boolean(medicinalIngredients[0]) && (
                        <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                          {' '}{medicinalIngredients[0].quantityUnitOfMeasureFrench}
                        </Text>
                      )}
                      </Text>
                      {(treatment?.dosages[0]?.quantity !== null || treatment?.dosages[0]?.units !== null) && (
                      <View style={styles.specDetWrapperCom}>
                       <Text style={[styles.textPrimeMd, styles.textGreyDark]} allowFontScaling={false}>
                      {treatment?.dosages[0]?.quantity !== null && (
                        <Text style={[styles.textPrimeMd, styles.textGreyDark]} allowFontScaling={false}>{treatment?.dosages[0]?.quantity}{' '}</Text>
                      )}
                      {treatment?.dosages[0]?.units !== null && (
                        <Text style={[styles.textPrimeMd, styles.textGreyDark]} allowFontScaling={false}>{treatment?.dosages[0]?.units}</Text>
                      )}
                      </Text> 
                      </View>
                      )}
                    
                  </View>
                </View>
                <View style={ stylesRes.leftMgSmCom } dataSet={{media: ids.leftMgSmCom}}>
                  <CircleButtonCom
                    btnCreate={ selected && selected === id ? true : false }
                    touchableOnPress={ () => showPopup(treatment) }
                    nativeId={ treatmentType === 5 ? 'dashboardRecordActivity' : 'dashboardRecordMed' }
                    />
                </View>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveJournalEntry: (journalEntry, callBack, journalUserId) =>
      dispatch(actions.saveJournalEntry(journalEntry, callBack, journalUserId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicationItem);
