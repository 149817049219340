import React, {Component} from 'react';
import {View} from 'react-native';
import styles from '../../../../Css';
import {connect} from 'react-redux';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import GroupFrame from '../../../TextInput/groupFrame';
import TextButton from '../../../TextInput/textButton';
import TextFrame from '../../../TextInput/textFrame';
import * as actions from '../../../../store/actions/index';
import ItemDeleteButton from '../../../Button/itemDeleteButton';
import SymptomSlider from '../../../../components/HASlider/symptomSlider';
import YesNoSwitch from '../../../../components/YesNoSwitch/yesNoSwitch';

class Symptoms extends Component {
  constructor(props) {
    super(props);

    let {data} = props;
    const {displayProperties} = data;
    const {scaleMin, scaleMax} = displayProperties;
    let yesNo = scaleMin && scaleMax && scaleMin === 0 && scaleMax === 1;
    this.state = {
      yesNo: yesNo,
      accept: false,
      wait: false,
      slider: false,
    };
  }

  componentDidMount() {
    //for future reference
    //if scaleMin is 0 and max is 1, then binary options, so its a yes or no, but if
    //scaleMix and scaleMax is not defined then we should default to 0-10 scale
    let {data} = this.props;
    const {displayProperties} = data;
    const {scaleMin, scaleMax} = displayProperties;
    if (
      (scaleMin === null && scaleMax === null) ||
      (scaleMin === 0 && scaleMax === 10)
    )
      this.setYesNo(false, true);
    if (scaleMin === 0 && scaleMax === 1) this.setYesNo(true, true);
  }


  setYesNo = (val, fromDidMount) => {
    const {updateSymptoms, data, index} = this.props;
    this.setState(
      {
        yesNo: val,
        slider: true,
      },
      () => {
        updateSymptoms(index, {
          ...data,
          displayProperties: {
            ...data.displayProperties,
            scaleMin: 0,
            scaleMax: val ? 1 : 10,
          },
          userSymptomLog: {
            ...data.userSymptomLog,
            severity: fromDidMount && (data.userSymptomLog?.severity || data.userSymptomLog?.severity === 0) ? data.userSymptomLog?.severity : null,
          },
        });
      },
    );
  };

  static getDerivedStateFromProps(props, state) {
    let {data, updateSymptoms, index} = props;
    const {displayProperties} = data;
    const {scaleMin, scaleMax} = displayProperties;
    let value = null;
    if (
      (scaleMin === null && scaleMax === null) ||
      (scaleMin === 0 && scaleMax === 10)
    ) {
      value = false;
      return {yesNo: false};
    }

    if (scaleMin === 0 && scaleMax === 1) {
      value = true;
      return {yesNo: true};
    }
    updateSymptoms(index, {
      ...data,
      displayProperties: {
        ...data.displayProperties,
        scaleMin: 0,
        scaleMax: value ? 1 : 10,
      },
      userSymptomLog: {
        ...data.userSymptomLog,
        severity: data.userSymptomLog?.severity ? data.userSymptomLog?.severity : null,
      },
    });

    return null;
  }

  updateSymptoms = (ind, res) => {
    const {updateSymptoms} = this.props;
    updateSymptoms(ind, res);
  };

  changeSymptomName = (val) => {
    const {updateSymptoms, index, data} = this.props;
    this.setState(
      () =>
        updateSymptoms(index, {
          ...data,
          name: val,
        }),
    );
  }

  render() {
    let {data, deleteItem, index, category} = this.props;
    const {name, displayProperties, type} = data;
    const {scaleMin, scaleMax} = displayProperties;
    const {yesNo, slider} = this.state;
    const sliderSegments = [...Array(11)].map(x => '');

    const fieldBg = type === 'CUSTOM' ? (name === '' || name === null || name === undefined) && FieldDetails.BGRED : FieldDetails.BGGREY;

    return (
      <>
        <View style={[fieldBg === FieldDetails.BGRED ? styles.fieldContainerLrg : styles.fieldContainer]}>
          <View style={[styles.fieldRowWrapper]}>
            <View style={styles.fieldColStretch}>
              <TextFrame
                title={'Symptom'}
                fieldBg={fieldBg}
                fieldLg={true}
                fieldValue={name}
                onChangeText={this.changeSymptomName}
                editable={type === 'CUSTOM' ? true : false}
                fleldUnique={true}
                noOfLines={1}
                textPosition={"left"}
              />
            </View>
            <View style={styles.fieldColSp} />

            <View style={styles.fieldColBtn}>
              <GroupFrame
                title={'Select Measure'}
                fieldBg={FieldDetails.BGGREY}
                fieldValue={'Laughing'}>
                <View style={styles.btnTabSingleDynamic}>
                  <TextButton
                    active={yesNo === true}
                    btnText={'Yes/No'}
                    touchableOnPress={() => this.setYesNo(true)}
                    weeklybtnShadow={false}
                  />
                </View>
                <View style={styles.btnTabSingleSp} />
                <View style={styles.btnTabSingleDynamic}>
                  <TextButton
                    active={yesNo === false}
                    btnText={'0 to 10'}
                    touchableOnPress={() => this.setYesNo(false)}
                    weeklybtnShadow={false}
                  />
                </View>
              </GroupFrame>
            </View>
            <View style={styles.fieldColSp} />
            <View style={[styles.fieldColDelete]}>
              <ItemDeleteButton touchableOnPress={() => deleteItem(index)} />
            </View>
          </View>
        </View>
        {slider && category !== 'RT' ? (
          <View
            style={[
              styles.editSymptomSliderOuterWrapper,
              styles.secSepBorderBot,
            ]}>
            <View style={[styles.flexRow, styles.flexAiCt]}>
              <View style={styles.fieldColStretch}>
                {scaleMin === 0 && scaleMax === 1 && (
                  <YesNoSwitch
                    value={
                      data.userSymptomLog.severity === null
                        ? null
                        : data.userSymptomLog.severity === 1
                        ? 'YES'
                        : 'NO'
                    }
                    onChange={status =>
                      this.updateSymptoms(index, {
                        ...data,
                        userSymptomLog: {
                          ...data.userSymptomLog,
                          severity: status === 'YES' ? 1 : 0,
                        },
                      })
                    }
                  />
                )}
                {((scaleMin === null && scaleMax === null) ||
                  (scaleMin === 0 && scaleMax === 10)) && (
                  <SymptomSlider
                    value={data.userSymptomLog.severity}
                    onUpdate={severityLevel =>
                      this.updateSymptoms(index, {
                        ...data,
                        userSymptomLog: {
                          ...data.userSymptomLog,
                          severity: severityLevel,
                        },
                      })
                    }
                    disabledScroll={this.props.disabledScroll}
                    valueDescriptions={sliderSegments}
                    colorMode={'GRADIENT'}
                  />
                )}
              </View>
            </View>
          </View>
        ) : (
          <View style={[styles.secSepBorderBot, styles.comSp]} />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    hasInvalidNamesInActivity: state.therapie.hasActivityInvalidName,
    hasInvalidNamesInMedication: state.therapie.hasMedicationInvalidName,
    hasInvalidNamesInHealthData: state.therapie.hasHealthDataInvalidName,
    hasInvalidNamesInSymtoms: state.therapie.hasSymptomsInvalidName,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createUserSymptom: (userSymptom, callback, journalUserId) =>
      dispatch(actions.createUserSymptom(userSymptom, callback, journalUserId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Symptoms);
