import React from 'react';
import {View, Platform} from 'react-native';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import {
  MoreMenuItem,
  AddButton,
  CommonBottomMenuItem,
} from './MenuItem';
import {APP_FEATURE_P2P_ENABLED} from '../../../util/featureConfig';
import * as paths from "../../../navigation/path";
import { IOS } from '../../../util/platformWindowConfig';


const mobileMenu = props => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  const {
    handelOnMenuItemPress,
    addMenu,
    selectedMenu,
    onPressMoreMenu,
    moreMenuMobile,
    openAddMenu,
    navigation,
    hasNotch,
    onClearQuestionFilter,
    onClearJournalEntryFilte,
    hasCommunityNotifications,
    hasSurveyNotifications,
    journalUserId,
    route,
    tabMenuIconView,
  } = props;
  return (
    <View style={[styles.mobAppMenuWrapper]}>
      <View style={[styles.mobAppMenu]}>
        <View
          style={[styles.mobAppMenuView, styles.menuIndShadow]}
          nativeID="menuMobileshowMenu">
          <View style={[stylesRes.mobAppMenuViewRow]}>
            <View style={[styles.navResponsiveLeft]}>
              <View style={[styles.navLeftItemRow]}>
                <CommonBottomMenuItem
                  navigation={navigation}
                  onPressMenuItem={handelOnMenuItemPress}
                  addMenu={addMenu}
                  selectedMenu={selectedMenu}
                  route={paths.DASHBOARD.path}
                  pathDetails={paths.DASHBOARD}
                  moreMenuMobile={moreMenuMobile}
                />
                {APP_FEATURE_P2P_ENABLED && !Boolean(journalUserId) ? (
                  <CommonBottomMenuItem
                    navigation={navigation}
                    onPressMenuItem={handelOnMenuItemPress}
                    addMenu={addMenu}
                    selectedMenu={selectedMenu}
                    hasNotifications={hasCommunityNotifications}
                    route={route}
                    pathDetails={paths.PEER_TO_PEER}
                    moreMenuMobile={moreMenuMobile}
                  />) : (
                  <CommonBottomMenuItem
                    navigation={navigation}
                    onPressMenuItem={handelOnMenuItemPress}
                    addMenu={addMenu}
                    selectedMenu={selectedMenu}
                    route={paths.TODOS.path}
                    pathDetails={paths.TODOS}
                    onClearFilter={onClearQuestionFilter}
                    moreMenuMobile={moreMenuMobile}
                  />
                )}
              </View>
            </View>
            <View style={[styles.navResponsiveCenter]} />
            <View style={[styles.navResponsiveRight]}>
              <View style={[styles.navRightItemRow]}>
                <CommonBottomMenuItem
                  navigation={navigation}
                  onPressMenuItem={handelOnMenuItemPress}
                  addMenu={addMenu}
                  selectedMenu={selectedMenu}
                  route={paths.JOURNALS.path}
                  pathDetails={paths.JOURNALS}
                  onClearFilter={onClearJournalEntryFilte}
                  moreMenuMobile={moreMenuMobile}
                />
                <MoreMenuItem
                  moreMenuMobile={moreMenuMobile}
                  onPressMoreMenu={onPressMoreMenu}
                  addMenu={addMenu}
                  selectedMenu={selectedMenu}
                  tabMenuIconView={tabMenuIconView}
                  hasNotifications={hasSurveyNotifications}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.mobAppMenuAdd]}>
        <View style={styles.addButtonApp} nativeID={'orangeAdd'}>
          <AddButton openAddMenu={openAddMenu} addMenu={addMenu} />
        </View>
      </View>
      {Platform.OS === IOS && Boolean(hasNotch) && <View style={styles.notchView} />}
    </View>
  );
};

export default (mobileMenu);
