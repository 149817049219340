import React, {Component} from 'react';
import {View, ScrollView, Text, TouchableOpacity} from 'react-native';
import _ from 'underscore';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import {connect} from 'react-redux';
import Header from '../../components/Header/header';
import * as actions from '../../store/actions/index';
import MedicationsOrTherapiesHeader from '../../components/MedicationsOrTherapies/MedicationsOrTherapiesHeader/medicationsOrTherapiesHeader';
import MedicationActivityCategoryItem from './MedicationActivityCategoryItem';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import moment from 'moment';
import Highlighter from '../../components/Highlighter/highlighter';
import {DASHBOARD, MEDICATIONS, PROFILE} from '../../navigation/path';
import PageContainer from '../../components/PageLayout/PageContainer';
import SectionTitle from '../../components/Text/sectionTitle';
import SearchField from '../../components/Search/searchField';
import PlusButtonDark from '../../components/Button/plusButtonDark';
import ImageDocSearch from '../../components/Search/imageDocSearch';
import {getAccountType} from '../../util/subscriptionInformation';
import NoResultFound from '../../components/Search/noResultFound';
import Modal from '../../components/Modal/modal';
import AddNewTherapyModalContent from '../../components/ConfirmationBox/addPrescription';
import { pendoTrackEvent } from '../../util/pendoConfig';
import {sortAlphabeticalOrder} from '../../util/commonUiLogic';

class MedicationsOrTherapiesScreen extends Component {
  constructor(props) {
    super(props);

    const accountType = getAccountType(props.userSubscriptions);
    //let from = null;
    //TO-DO remove previous navigation changes
    // if (this.props.location.state && this.props.location.state.from) {
    //   from = this.props.location.state.from;
    // }
    // if (this.props.route?.params && this.props.route?.params.from) {
    //   from = this.props.route?.params.from;
    // }
    this.searchTextMap = {};
    this.state = {
      medication: [],
      activity: [],
      filterWait: false,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,

      imageSerachFinish: false,
      accountType: accountType,
      searchKeyword: '',
      visiblePrescription: false,
      createWait: false,
      buttonStatus: false,
      //loadingWait: false,
    };
    if (accountType === 'premium' || this.props.journalUserId !== null) {
      this.onTicked(true);
    } else {
      this.onTicked(false);
    }
    //TO-DO remove previous navigation changes
    // if (
    //   props.filterTherapy.popupData &&
    //   (!from ||
    //     (from && !from.includes(MEDICATIONS.viewTherapiesPath)) ||
    //     (from &&
    //       from.includes(MEDICATIONS.viewTherapiesPath) //&&
    //       //this.props.history.action !== 'PUSH'
    //     ))
    // ) {
    //   this.handleOnPressClearFilters();
    // }
  }

  componentDidMount() {
    const {filterTherapy, listTherapie} = this.props;
    const {popupData} = filterTherapy;
    if (this.state.imageSerachFinish) {
      return {
        imageSerachFinish: false,
      };
    } else if (
      popupData &&
      popupData.keyword &&
      popupData.keyword.trim() !== '' &&
      popupData.isChecked &&
      !this.state.filterWait
    ) {
      this.setState({
        searchKeyword: popupData.keyword,
        searchAttachmentContent: popupData.isChecked
      });
      this.handleImageFilter(popupData, this.props);

    } else if (
      popupData &&
      popupData.keyword &&
      popupData.keyword.trim() !== '' &&
      !this.state.filterWait
    ) {
      let filterObj = {};
      filterObj = MedicationsOrTherapiesScreen.handleFilter(
        popupData,
        this.props,
      );

      this.setState({
        ...filterObj,
        filterWait: false,
        searchKeyword: popupData.keyword,
      });
    } else if (
      !this.state.filterWait &&
      (!popupData ||
        (popupData && (!popupData.keyword || popupData.keyword === '')))
    ) {
      let medication = MedicationsOrTherapiesScreen.dataFilterMedication(
        listTherapie,
      );
      let activity = MedicationsOrTherapiesScreen.dataFilter(listTherapie, 5);
      this.setState({
        filterWait: false,
        medication,
        activity,
        searchKeyword: '',
      });
    }

    this.setState({
      filterWait: false,
      medication: [],
      activity: [],
      searchKeyword: popupData && popupData.keyword ? popupData.keyword : '',
    });
    //setTimeout(() => this.setState({loadingWait: false}), 1500);

    this.props.navigation.addListener('blur', () => {
      this.props.navigation.setParams({from: MEDICATIONS.path});
    });
    this.props.navigation.addListener('focus', () => {
      let from = null;
      if (this.props.route?.params && this.props.route?.params.from) {
        from = this.props.route?.params.from;
      }
      if (
        this.props.filterTherapy.popupData &&
        (!from ||
          (from && 
            !(from.includes(MEDICATIONS.viewTherapiesPath) || 
              from.includes(MEDICATIONS.addTherapiePath) || 
              from.includes(MEDICATIONS.editTherapiePath))
          )
        )
      ) {
        this.handleOnPressClearFilters();
      }
      this.props.showMobileMenu();
      pendoTrackEvent('MedSupp+Act@');
    });
  }

  static getDerivedStateFromProps(props, state) {
    const {filterTherapy, listTherapie, windowHeight, windowWidth} = props;
    const {popupData} = filterTherapy;
    if (state.imageSerachFinish) {
      return {
        imageSerachFinish: false,
      };
    } else if (
      popupData &&
      popupData.keyword &&
      popupData.keyword.trim() !== '' &&
      popupData.isChecked &&
      !state.filterWait
    ) {
      return {
        searchKeyword: popupData.keyword,
        searchAttachmentContent: popupData.isChecked,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        attachmentContentChanged: true
      };
    } else if (
      popupData &&
      popupData.keyword &&
      popupData.keyword.trim() !== '' &&
      !state.filterWait
    ) {
      let filterObj = {};
      filterObj = MedicationsOrTherapiesScreen.handleFilter(popupData, props);
      return {
        ...filterObj,
        filterWait: false,
        searchKeyword: popupData.keyword,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    } else if (
      !state.filterWait &&
      (!popupData ||
        (popupData && (!popupData.keyword || popupData.keyword === '')))
    ) {
      let medication = MedicationsOrTherapiesScreen.dataFilterMedication(
        listTherapie,
      );
      let activity = MedicationsOrTherapiesScreen.dataFilter(listTherapie, 5);
      return {
        filterWait: false,
        medication,
        activity,
        searchKeyword: '',
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return {
      filterWait: state.filterWait,
      medication: state.medication ? state.medication : [],
      activity: state.activity ? state.activity : [],
      searchKeyword: popupData && popupData.keyword ? popupData.keyword : '',
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchKeyword !== this.state.searchKeyword || prevState.searchAttachmentContent !== this.state.searchAttachmentContent || this.state.attachmentContentChanged) { 
      const {filterTherapy} = this.props;
      const {popupData} = filterTherapy;
      this.handleImageFilter(popupData, this.props);
      this.setState({attachmentContentChanged: false});
    }
  }

  static dataFilter = (listTherapie, type) => {
    if (listTherapie && listTherapie.length > 0) {
      const activity = listTherapie.filter(function(therapie) {
        return therapie.treatmentType === type && therapie.name;
      });

      return sortAlphabeticalOrder(activity);
    }
  };

  static dataFilterMedication = listTherapie => {
    if (listTherapie && listTherapie.length > 0) {
      const medication = listTherapie.filter(function(therapie) {
        return therapie.treatmentType !== 5 && therapie.name;
      });

      return sortAlphabeticalOrder(medication);
    }
  };

  static getFilteredItems = (keyword, attachmentList = [], props) => {
    const {listTherapie, questionDigest, questionDigestProcessed} = props;
    let therapyList = [];

    let attachmentObj = {};
    if (attachmentList && attachmentList.length > 0 && keyword === attachmentList[0].searchKey) {
      attachmentList[0].attachments.forEach(attachment => {
        if (attachmentObj[attachment.instanceId]) {
          attachmentObj[attachment.instanceId].push(attachment);
        } else attachmentObj[attachment.instanceId] = [attachment];
      });
    }
    therapyList = listTherapie.map(therapy => {
      const therapyObj = {...therapy};
      let artifactList,
        questionsStrings = [];
      let containKeyword = false;

      let fullTherapyName = '';
      if (therapy.name && therapy.drugIngredients && therapy.drugIngredients[0]) {
        fullTherapyName = therapy.name.trim().concat(' ').concat(therapy.drugIngredients[0]?.strength.concat(therapy.drugIngredients[0]?.strengthUnit));
      } else if (therapy.name) {
        fullTherapyName = therapy.name.trim();
      }
      if (
        (fullTherapyName &&
          fullTherapyName.toLowerCase().includes(keyword.toLowerCase())) ||
        (therapy.dosages[0] &&
          therapy.dosages[0].instructions &&
          therapy.dosages[0].instructions
            .toLowerCase()
            .includes(keyword.toLowerCase()))
      ) {
        containKeyword = true;
      }

      if (
        therapy.notes &&
        therapy.notes.toLowerCase().includes(keyword.toLowerCase())
      ) {
        containKeyword = true;
        therapyObj['disNotes'] = therapy.notes;
      }

      if (attachmentObj.hasOwnProperty(`${therapy.artifactId}`)) {
        containKeyword = true;
        therapyObj['disAttachments'] = attachmentObj[therapy.artifactId];
      }

      if (therapy.artifactId)
        artifactList = questionDigest.userArtifactHealthQuestions.filter(
          artifact => artifact.artifactId === therapy.artifactId,
        );

      if (artifactList.length) {
        artifactList.forEach(artifact => {
          const question =
            questionDigestProcessed.questions[artifact.questionId];
          if (
            (question.content &&
              question.content.toLowerCase().includes(keyword.toLowerCase())) ||
            (question.answers &&
              question.answers.length > 0 &&
              question.answers.find(answer =>
                answer.content.toLowerCase().includes(keyword.toLowerCase()),
              ))
          ) {
            containKeyword = true;
            questionsStrings.push(question.content);
          }
        });
        therapyObj['disQuestionsList'] = questionsStrings;
      }
      therapyObj['containKeyword'] = containKeyword;
      return therapyObj;
    });
    return therapyList;
  };

  medicationsOrTherapiesSelectHandler = (id, isFromAttchIcon) => {
    const {listTherapie, onSelectTherapie} = this.props;
    if (listTherapie) {
      const selectedTherapie = listTherapie.filter(function(therapie) {
        return therapie.id === id;
      });

      if (selectedTherapie && selectedTherapie.length >= 1) {
        onSelectTherapie(selectedTherapie[0], () => {
          this.props.navigation.push(
            MEDICATIONS.viewTherapiesPath, {
              from: this.props.route.name,
              activeAttachmentTab: isFromAttchIcon
            },
          );
        });
      }
    }
  };
  handleOnPressBackButton = () => {
    // let from = null;
    // if (this.props.route?.params && this.props.route?.params.from) {
    //   from = this.props.route?.params.from;
    // }
    if (
      this.props.filterTherapy.popupData &&
      this.props.filterTherapy.popupData.keyword &&
      this.props.filterTherapy.popupData.keyword !== ''
    ) {
      this.handleOnPressClearFilters();
    } else {
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: DASHBOARD.path }],
      });
    }
  };

  static handleFilter = (filter, props) => {
    const {keyword} = filter;
    if (keyword) {
      const listTherapie = MedicationsOrTherapiesScreen.getFilteredItems(
        keyword,
        null,
        props,
      );
      const result = listTherapie.filter(therapy => {
        return therapy.containKeyword;
      });

      const activity = MedicationsOrTherapiesScreen.dataFilter(
        result,
        parseInt(5),
      );
      const medication = MedicationsOrTherapiesScreen.dataFilterMedication(
        result,
      );
      return {
        activity: activity,
        medication: medication,
      };
    }
    return {
      activity: [],
      medication: [],
    };
  };

  handleImageFilter = (filter = {}, props) => {
    const {keyword, isChecked} = filter ? filter : {};
    const {user, searchAttachment, attachmentsCompleteDigest} = props;

    this.setState({filterWait: true});
    let listTherapie = [];
    if (isChecked && keyword) {
      let userId = user.id;
      this.searchTextMap[keyword] = keyword;
      searchAttachment(
        {
          attachmentCategory: 'Therapy',
          searchKey: keyword,
          userId: userId,
        },
        (success, data) => {
          delete this.searchTextMap[keyword];
          if (success && _.isEmpty(this.searchTextMap)) {
            let medication = [];
            let activity = [];

            let art = [];
            let temp = [];

            if (
              filter.keyword &&
              attachmentsCompleteDigest &&
              attachmentsCompleteDigest.attachments &&
              attachmentsCompleteDigest.attachments.length > 0
            ) {
              attachmentsCompleteDigest.attachments.forEach(element => {
                return element.filename
                  .toLowerCase()
                  .includes(filter.keyword.toLowerCase())
                  ? art.push(element)
                  : null;
              });
            }

            if (
              art &&
              art.length > 0 &&
              attachmentsCompleteDigest.userArtifactAttachments &&
              attachmentsCompleteDigest.userArtifactAttachments.length > 0
            ) {
              art.forEach(re => {
                attachmentsCompleteDigest.userArtifactAttachments.forEach(res =>
                  re.id === res.attachment
                    ? temp.push({...re, instanceId: res.userArtifact})
                    : null,
                );
              });
            }

            const obj = {};

            data.forEach(element => {
              let exatt = element.attachments.concat(temp);
              exatt.forEach(item => {
                if (!obj[item.instanceId]) {
                  obj[item.instanceId] = [item];
                } else {
                  obj[item.instanceId].push(item);
                }
              });
            });

            // let attList = [];
            // let attachmentFilter = this.props.listTherapie.filter(att => {
            //   if (obj[att.artifactId] && obj[att.artifactId].length > 0) {
            //     obj[att.artifactId].forEach(rr => {
            //       attList.push(rr);
            //     });
            //   }
            //   return obj[att.artifactId];
            // });

            // attList.map(item => {
            //   data[0].attachments[data[0].attachments.length] = item;
            // });

            try {
              if (this.state.searchKeyword) {
                listTherapie = MedicationsOrTherapiesScreen.getFilteredItems(
                  keyword,
                  data,
                  props,
                );
                const result = listTherapie.filter(therapy => {
                  return therapy.containKeyword;
                });

                medication =
                  result && result.length
                    ? MedicationsOrTherapiesScreen.dataFilterMedication(result)
                    : [];
                activity =
                  result && result.length
                    ? MedicationsOrTherapiesScreen.dataFilter(result, 5)
                    : [];

                this.setState({
                  medication: medication,
                  activity: activity,
                  filterWait: !_.isEmpty(this.searchTextMap),
                  imageSerachFinish: true,
                });
              }
            } catch (error) {
              console.log(error);
            }
          } else if (_.isEmpty(this.searchTextMap)) {
            this.setState({
              filterWait: !_.isEmpty(this.searchTextMap),
              medication: [],
              activity: [],
              imageSerachFinish: true,
            });
          }
        },
      );
    } else {
      let medication = MedicationsOrTherapiesScreen.dataFilterMedication(
        listTherapie,
      );
      let activity = MedicationsOrTherapiesScreen.dataFilter(listTherapie, 5);
      this.setState({
        filterWait: false,
        medication: medication,
        activity: activity,
        imageSerachFinish: false,
      });
    }
  };

  handleOnPressClearFilters = () => {
    const {clearTherapyFilter} = this.props;
    clearTherapyFilter();
  };

  renderTherapyHighlightTextContent = (textToHighlight, searchWords, style={}) => {
    return (
      (textToHighlight !== null && textToHighlight !== undefined) &&
        <Highlighter
          searchWords={searchWords}
          textToHighlight={textToHighlight}
          style={!_.isEmpty(style) && style}
        />
    );
  }

  renderSearchContents = treatment => {
    let disNotes = treatment.disNotes;
    let disQuestionsList = treatment.disQuestionsList || [];
    let disAttachments = [...new Set(treatment.disAttachments)] || [];

    return (
      (disNotes ||
        disQuestionsList.length > 0 ||
        disAttachments.length > 0) && (
        <TouchableOpacity
          onPress={() => {
            this.medicationsOrTherapiesSelectHandler(treatment.id);
          }}>
          <View style={[styles.viewMoreSectionCom]}>
            {Boolean(disNotes) && (
              <View style={[styles.viewMoreDataSingleSub]}>
                <View style={[styles.resultTitle]}>
                  <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>Notes</Text>
                </View>
                <View style={styles.viewMorePdLg}>
                  <View style={[styles.viewMoreDataItemSub]}>
                    {this.renderTherapyHighlightTextContent(disNotes, this.props.filterTherapy.popupData.keyword)}
                  </View>
                </View>
              </View>
            )}

            {disQuestionsList.length > 0 && (
              <View style={[styles.viewMoreDataSingleSub]}>
                <View style={[styles.resultTitle]}>
                  <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>Questions</Text>
                </View>
                {disQuestionsList.map((question, index) => (
                  <View
                    key={'disQ' + index}
                    style={styles.viewMorePdLg}>
                    <View style={[styles.viewMoreDataItemSub]}>
                      {this.renderTherapyHighlightTextContent(question, this.props.filterTherapy.popupData.keyword)}
                    </View>
                  </View>
                ))}
              </View>
            )}

            {disAttachments.length > 0 && (
              <View style={[styles.viewMoreDataSingleSub]}>
                <View style={[styles.resultTitle]}>
                  <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>Attachments</Text>
                </View>
                {disAttachments.map((attachment, index) => (
                  <View
                    key={'disA1' + index}
                    style={styles.viewMorePdLg}>
                    <View style={[styles.resultSubTitle]}>
                      <View style={[styles.resultTitleWrapper]}>
                        {disAttachments.length !== 2 && (
                          this.renderTherapyHighlightTextContent(attachment.filename, this.state.searchKeyword, styles.textPrimeSm)
                        )}

                        {attachment.searchWordContainingSentences && (
                          this.renderTherapyHighlightTextContent(attachment.fileName, this.state.searchKeyword, styles.textPrimeSm)
                        )}
                        {(disAttachments.length !== 2 || attachment.searchWordContainingSentences) &&
                        <View style={styles.resultDateWrapper}>
                          <Text style={[styles.textPrimeSm, styles.textAlignLeft]} allowFontScaling={false}>
                            {attachment.uploadDate !== null &&
                              moment(attachment.uploadDate).format(
                                "DD MMM 'YY",
                              )}
                          </Text>
                        </View>
                        }
                      </View>
                    </View>

                    {attachment.searchWordContainingSentences &&
                      attachment.searchWordContainingSentences.map(
                        (sentence, index) => (
                          <View
                            style={[
                              styles.viewMoreDataItemSub,
                              styles.viewMoreDataTopMg,
                            ]}
                            key={'disA2' + index}>
                              {this.renderTherapyHighlightTextContent(sentence, this.props.filterTherapy.popupData.keyword)}
                          </View>
                        ),
                      )}
                  </View>
                ))}
              </View>
            )}
          </View>
        </TouchableOpacity>
      )
    );
  };

  searchMedication = val => {
    // this.setState({searchKeywordText: val})
    const {setTherapyPopup, filterTherapy} = this.props;
    let filter = {};
    if (filterTherapy.popupData) {
      filter = filterTherapy.popupData;
    }
    setTherapyPopup({...filter, keyword: val});
  };
  onTicked = val => {
    if(val) {
      pendoTrackEvent('MedSupp+Act@ > SearchImages');
    }
    this.setState({searchAttachmentContent: val});
    const {setTherapyPopup, filterTherapy} = this.props;
    let filter = {};
    if (filterTherapy.popupData) {
      filter = filterTherapy.popupData;
    }
    setTherapyPopup({...filter, isChecked: val});
  };

  scanPrescription = () => {
    this.setState({visiblePrescription: false, buttonStatus: true});
    this.props.setFromPopup(null);
    this.props.navigation.push(
      MEDICATIONS.scanPrescriptionPath, {
        fromPopup: 'MEDICATION',
        from: this.props.route.name,
      },
    );
    setTimeout(() => {
      this.setState({buttonStatus: false});
    }, 100);
    //this.props.history.push(MEDICATIONS.scanPrescriptionPath);
  };

  redirectPremiumAccount = () => {
    this.setState({
      visiblePrescription: false,
      buttonStatus: true,
    });
    this.props.setFromPopup(null);
    this.props.navigation.push(
      PROFILE.profilePremiumAccountPath, {
        fromPopup: 'MEDICATION',
      },
    );
    setTimeout(() => {
      this.setState({buttonStatus: false});
    }, 100);
  };

  manualPrescription = () => {
    this.setState({visiblePrescription: false, buttonStatus: true});
    this.createTherapie();
  };

  createTherapie = () => {
    let addNewTherapie = {};
    //  this.props.setCreateSpinner();
    this.setState({createWait: true});
    this.props.addTherapie(
      addNewTherapie,
      res => {
        if (res) {
          this.props.setFromPopup(null);
          this.props.navigation.push(
            MEDICATIONS.addTherapiePath, {
              fromPopup: 'MEDICATION',
              from: this.props.route.name,
              fromManualEntry: true,
            },
          );
        }
        this.setState({createWait: false, buttonStatus: false});
        ///  this.props.setCreateSpinner();
      },
      this.props.journalUserId,
    );
  };

  handleEditMedicationActivity = treatment => {
    const {navigation, editTherapie, route} = this.props;
    editTherapie({...treatment, forceEdit: true }, 
      () => navigation.push(
        MEDICATIONS.editTherapiePath, {
          from: route.name,
        },
    ));
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {filterTherapy, windowWidth} = this.props;
    const {popupData} = filterTherapy;

    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          type="therapy"
          showFilers={false}
          showBack={true}
          showNotify={true}
          showClearFilters={false}
          //   handleFilter={this.handleFilter}
          // filterClickHandler={this.handleOnPressFilter}
          // clearFiltersHandler={this.handleOnPressClearFilters}
          handleOnPressBackButton={this.handleOnPressBackButton}
          index={MEDICATIONS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        <React.Fragment>
          <ScrollView
            style={styles.scrollViewArea}
            keyboardShouldPersistTaps={'handled'}>
            <View style={styles.gridSection}>
              <PageContainer>
                <View style={[styles.gridRow, styles.colPdBotSm]}>
                  <View style={styles.gridColFull}>
                  <View style={[styles.searchSection]}>
                    {/* button */}
                    <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt]}>
                      <View>
                        <SectionTitle 
                          smallTextTilte={true}
                          title={'Meds/Supplements & Activities'} 
                        />
                      </View>
                      <View style={[stylesRes.newConnectionBtnCr,styles.btnMgMd]} dataSet={{media: ids.newConnectionBtnCr}}>
                      <PlusButtonDark
                            touchableOnPress={() => {
                              this.setState({visiblePrescription: true});
                              pendoTrackEvent('MedSupp+Act@ > Add');
                            }}
                            btnPosition={'full'}
                            btnText={'New'}
                            plusIconSm={true}
                            btnShadowNone={true}
                            nativeId={'medSuppActAdd'}
                            newConnection={true}
                          />
                      </View>
                    </View>
                    {/* search bar */}
                    <View style={styles.fieldColSearch}>
                    <SearchField
                            searchText={
                              popupData && popupData.keyword
                                ? popupData.keyword
                                : ''
                            }
                            placeholder="Search existing meds/supplements & activities"
                            onChangeText={this.searchMedication}
                            showClearBtton={
                              popupData &&
                              popupData.keyword &&
                              popupData.keyword !== ''
                                ? true
                                : false
                            }
                            clearFilter={this.handleOnPressClearFilters}
                            nativeId={'medSuppActSearch'}
                            eventName={'MedSupp+Act@ > Search'}
                          />
                    </View>
                  </View>

                    <View style={[styles.searchDocSection]}>
                      <ImageDocSearch
                        ticked={
                          this.state.accountType === 'premium' &&
                          popupData &&
                          popupData.isChecked
                            ? popupData.isChecked
                            : false
                        }
                        onTicked={this.onTicked}
                        accountType={this.state.accountType}
                        navigation={this.props.navigation}
                        journalUserId={this.props.journalUserId}
                        windowWidth={windowWidth}
                        nativeId={'medSuppActSearchImages'}
                      />
                    </View>
                    {_.isEmpty(this.state.medication) &&
                      _.isEmpty(this.state.activity) &&
                      popupData && 
                      popupData.keyword &&
                      popupData.keyword !== '' &&
                      !this.state.filterWait ? <NoResultFound /> : null}
                  </View>
                </View>
              </PageContainer>

              {this.state.filterWait ||
              //this.state.loadingWait ||
              this.state.createWait ? (
                <ActivityIndicator pdLarge={true} />
              ) : (
                <PageContainer smScreenFull={true}>
                  <View style={[stylesRes.contentSection, stylesRes.botExPd]} dataSet={{media: ids.botExPd}}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        {this.state.medication &&
                          this.state.medication.length > 0 && (
                            <View style={stylesRes.contentSingleBlock}>
                              <View style={stylesRes.colFull}>
                                <View>
                                  <MedicationsOrTherapiesHeader
                                    treatmentType={2}
                                    title="Meds/Supplements"
                                    windowWidth={this.state.windowWidth}
                                  />
                                  <View style={stylesRes.contentDetailSection}>
                                    <View style={stylesRes.contList}>
                                      <MedicationActivityCategoryItem
                                        medication={this.state.medication}
                                        medicationsOrTherapiesSelectHandler={
                                          this.medicationsOrTherapiesSelectHandler
                                        }
                                        renderSearchContents={
                                          this.renderSearchContents
                                        }
                                        handleEditMedicationActivity={this.handleEditMedicationActivity}
                                        searchKey={this.state.searchKeyword}
                                      />
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          )}

                        {this.state.activity && this.state.activity.length > 0 && (
                          <View style={stylesRes.contentSingleBlock}>
                            <View style={stylesRes.colFull}>
                              <View>
                                <MedicationsOrTherapiesHeader
                                  treatmentType={5}
                                  title="Activities"
                                  windowWidth={this.state.windowWidth}
                                />
                                <View style={stylesRes.contentDetailSection}>
                                  <View style={stylesRes.contList}>
                                    <MedicationActivityCategoryItem
                                      medication={this.state.activity}
                                      medicationsOrTherapiesSelectHandler={
                                        this.medicationsOrTherapiesSelectHandler
                                      }
                                      renderSearchContents={
                                        this.renderSearchContents
                                      }
                                      handleEditMedicationActivity={this.handleEditMedicationActivity}
                                      searchKey={this.state.searchKeyword}
                                    />
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        )}
                      </View>
                    </View>
                  </View>
                </PageContainer>
              )}
            </View>
          </ScrollView>
        </React.Fragment>
        {this.state.visiblePrescription &&
        <Modal
          visible={this.state.visiblePrescription}
          onRequestClose={() => {
            this.setState({visiblePrescription: false});
          }}
          onBackdropPress={() => this.setState({visiblePrescription: false})}>
          <AddNewTherapyModalContent
            closeModal={() => this.setState({visiblePrescription: false})}
            scanLabel={this.scanPrescription}
            redirectPremiumAccount={this.redirectPremiumAccount}
            manualEntry={this.manualPrescription}
            accountType={this.state.accountType}
            navigation={this.props.navigation}
            fromPopup="MEDICATION"
            journalUserId={this.props.journalUserId}
            buttonStatus={this.state.buttonStatus}
          />
        </Modal>
        }
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    listTherapie: state.routines.treatments,
    journalUserId: state.uistate.journalUserId,
    user: state.routines.user,
    questionDigest: state.routines.questionDigest,
    questionDigestProcessed: state.routines.questionDigestProcessed,
    filterTherapy: state.filter.therapyFilter,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    userSubscriptions: state.routines.userSubscription,
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSelectTherapie: (SelectedTherapie, callBack) =>
      dispatch(actions.selectTherapie(SelectedTherapie, callBack)),
    clearTherapyFilter: () => dispatch(actions.clearTherapyFilter()),
    searchAttachment: (formData, callBack) =>
      dispatch(actions.searchAttachment(formData, callBack)),
    setTherapyPopup: filter => dispatch(actions.setTherapyPopup(filter)),
    addTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.createTherapie(addNewTherapie, callBack, journalUserId)),
    setFromPopup: fromPopup => dispatch(actions.setFromPopup(fromPopup)),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    editTherapie: (therapie, callBack) =>
      dispatch(actions.editTherapie(therapie, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicationsOrTherapiesScreen);
