import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import DisclaimerComponent from '../../../components/PeerToPeer/BeforeMainCreation/DisclaimerComponent';
import {PEER_TO_PEER} from '../../../navigation/path';
import {getHeightWidth} from '../../../GlobalFunctions/PageSize';
import {handleCommonBackNavigation} from '../../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../../util/pendoConfig';

class DisclaimerScreen extends Component {
  constructor(props) {
    super(props);
    const {windowHeight, windowWidth} = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      wait: false,
    };
  }

  componentDidMount() {
    const {hideMobileMenu} = this.props;
    hideMobileMenu();
    pendoTrackEvent('Community/@Disclaimer');
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth} = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  componentWillUnmount = () => {
    const {showMobileMenu} = this.props;
    showMobileMenu();
  };

  handleNextOnPress = () => {
    const {onUpdateShowPeerProfileScreen, journalUserId, navigation} = this.props;
    this.setState({wait: true});
    onUpdateShowPeerProfileScreen(false, journalUserId, res => {
      if (res) {
        pendoTrackEvent('Community > @Disclaimer > Start');
        navigation.replace(PEER_TO_PEER.peerResultsByConditionPath);
      }
      this.setState({wait: false});
    });
  };

  handleOnPressBackButton = () => {
    const {navigation} = this.props;
    handleCommonBackNavigation(navigation);
  };

  render() {
    const {navigation, route} = this.props;
    const {windowHeight, wait, windowWidth} = this.state;
    const HeightWidth = getHeightWidth(windowHeight);
    const {
      windowHeightSmView,
      topIconWidthImg,
      topIconHeightImg,
      botIconWidthImg,
      botIconHeightImg,
    } = HeightWidth;

    return (
      <DisclaimerComponent
        windowHeightSmView={windowHeightSmView}
        topIconWidthImg={topIconWidthImg}
        topIconHeightImg={topIconHeightImg}
        botIconWidthImg={botIconWidthImg}
        botIconHeightImg={botIconHeightImg}
        handleOnNext={this.handleNextOnPress}
        handleOnPressBackButton={this.handleOnPressBackButton}
        wait={wait}
        index={PEER_TO_PEER.index}
        navigation={navigation}
        route={route}
        windowWidth={windowWidth}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateShowPeerProfileScreen: (
      showPeerProfileScreen,
      journalUserId,
      callBack,
    ) =>
      dispatch(
        actions.updateShowPeerProfileScreen(
          showPeerProfileScreen,
          journalUserId,
          callBack,
        ),
      ),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisclaimerScreen);
