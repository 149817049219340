import React from "react";
import { Image, View, Text, TouchableOpacity } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import LogoSetUp from "../LogoSetUp/LogoSetUp";
import { openDefaultMailClient } from "./mail";
import ActivityIndicator from "../ActivityIndicator/activityIndicator";
import PageLayoutSignup from "../PageLayout/pageLayoutSignup";
import { TAB_BREAk_POINT_START } from "../../util/platformWindowConfig";

class CheckYourEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skipInProgress: false,
      sendingEmail: false,
    };
  }

  componentDidMount() {
    this.setState({ sendingEmail: true });
    this.props.resendEmailVerification(() => {
      this.setState({ sendingEmail: false });
      this.skipAfter3Seconds();
    });
  }

  openEmail = () => {
    openDefaultMailClient();
  };

  skipVerification = () => {
    this.setState({ skipInProgress: true });
    this.props.skipEmailVerification();
  };

  skipAfter3Seconds = () => {
      setTimeout(this.props.skipEmailVerification, 3000);
  }

  render() {
    const stylesRes = stylesResponsive.getProcessedStyles().styles;
    const ids = stylesResponsive.getProcessedStyles().ids;
    const { email } = this.props;
    return (
      <PageLayoutSignup
        windowHeightView={this.props.windowHeightView}
        windowHeightSmView={this.props.windowHeightSmView}
        windowWidthView={this.props.windowWidthView}
        bgBlue={this.props.bgBlue}
        privacyLink={true}
        ttoggleLearnHowPrivacyPolicy={this.props.toggleLearnHowPrivacyPolicy}
      >
        {this.state.sendingEmail && (
          <ActivityIndicator pdZero={true} size="small" />
        )}
        {!this.state.sendingEmail && (
          <View style={[styles.boxWrapperArea, styles.colMgLtRt]}>
            <View
              style={[
                stylesRes.boxWrapperView,
                stylesRes.wrapperShadow,
                stylesRes.boxWrapperViewMg,
              ]}
              dataSet={{media: ids.boxWrapperViewMediaQuery}}
            >
              <View style={[styles.secArea]}>
                <View style={[stylesRes.secPdSm]}>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <LogoSetUp />
                    </View>
                  </View>

                  <View
                    style={[
                      this.props.windowWidthView > TAB_BREAk_POINT_START && styles.verifyboxBlock
                    ]}
                  >
                    <View style={styles.gridRow}>
                      <View style={[styles.gridColFull, styles.secBgPdTop]}>
                        <View style={stylesRes.topImgSpecCom}>
                          <View
                            style={[styles.topImgSecCom, styles.infoPdBotMd]}
                          >
                            <Image
                              style={styles.envelopeLgIcon}
                              source={require("../../assets/icons/envelope.png")}
                            />
                            <View style={styles.emailAvailableView}>
                              <View style={styles.emailAvailableIcon}></View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View
                          style={[
                            styles.titleBlock,
                            styles.flexRowJcCtAiCt,
                            styles.noPdTop,
                          ]}
                        >
                          <Text
                            style={[
                              styles.textAlignCenter,
                              styles.textTitleExLg,
                              styles.textThickGrey,
                            ]}
                            allowFontScaling={false}
                          >
                            <Text style={[styles.bold]} allowFontScaling={false}>Verify your email</Text>
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={[styles.gridColFull]}>
                        <View style={stylesRes.descTextCom} dataSet={{media: ids.descTextCom}}>
                          <View
                            style={[
                              styles.textSubCom,
                              styles.infoPdLg,
                              styles.pdTopExSm,
                            ]}
                          >
                            <Text
                              style={[
                                styles.textPrime,
                                styles.textAlignCenter,
                                styles.textTertiary,
                              ]}
                              allowFontScaling={false}
                            >
                              We have sent an email to{" "}
                              <Text style={[styles.textOrange]} allowFontScaling={false}>{email}</Text>
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View
                          style={[
                            styles.flexJcCtAiCt,
                            styles.colPdBot
                          ]}
                        >
                          <View style={styles.lineSp}>
                            <Text
                              style={[styles.textPrime, styles.textTertiary]}
                              allowFontScaling={false}
                            >
                              Didn’t receive the email?{" "}
                            </Text>
                          </View>
                          <View>
                            <Text
                              style={[styles.textPrime, styles.textTertiary]}
                              allowFontScaling={false}
                            >
                              Check your spam folder or Contact Support
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={[
                stylesRes.boxWrapperBottomView,
                stylesRes.boxWrapperViewMg,
                stylesRes.botLinkView,
              ]}
              dataSet={{media: ids.boxWrapperBottomViewMediaQuery}}
            >
              <View style={[styles.flexRow, styles.flexJcCt]}>
                <Text style={[styles.textPrimeSm, styles.textWhite]} allowFontScaling={false}>
                  Your privacy is our priority.{" "}
                </Text>
                <TouchableOpacity
                  onPress={this.props.toggleLearnHowPrivacyPolicy}
                >
                  <Text
                    style={[
                      styles.textPrimeSm,
                      styles.textOrange,
                      styles.textUnderline,
                    ]}
                    allowFontScaling={false}
                  >
                    Learn How
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )}
      </PageLayoutSignup>
    );
  }
}

export default (CheckYourEmail);