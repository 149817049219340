import { Platform } from "react-native";
import theme from "./theme";
export const dPFlexRow = {
  flexDirection: "row"
};
export const dPFlexCol = {
  flexDirection: "column"
};
export const dPFlexAiFsJcFs = {
  alignItems: "flex-start",
  justifyContent: "flex-start"
};
export const dPFlexAiFsJcCt = {
  alignItems: "flex-start",
  justifyContent: "center"
};
export const dPFlexAiFsJcFe = {
  alignItems: "flex-start",
  justifyContent: "flex-end"
};
export const dPFlexAiFsJcSb = {
  alignItems: "flex-start",
  justifyContent: "space-between"
};
export const dPFlexAiFsJcSa = {
  alignItems: "flex-start",
  justifyContent: "space-around"
};
export const dPFlexAiCtJcCt = {
  alignItems: "center",
  justifyContent: "center"
};
export const dPFlexAiCtJcFs = {
  alignItems: "center",
  justifyContent: "flex-start"
};
export const dPFlexAiCtJcSb = {
  alignItems: "center",
  justifyContent: "space-between"
};
export const dPFlexAiCtJcSa = {
  alignItems: "center",
  justifyContent: "space-around"
};
export const dPFlexAiCtJcFe = {
  alignItems: "center",
  justifyContent: "flex-end"
};
export const dPFlexAiFeJcFe = {
  alignItems: "flex-end",
  justifyContent: "flex-end"
};
export const dPFlexAiFeJcFs = {
  alignItems: "flex-end",
  justifyContent: "flex-start"
};
export const dPFlexAiFeJcCt = {
  alignItems: "flex-end",
  justifyContent: "center"
};
export const dPFlexAiFeJcSa = {
  alignItems: "flex-end",
  justifyContent: "space-around"
};
export const dPFlexAiFeJcSb = {
  alignItems: "flex-end",
  justifyContent: "space-between"
};
export const dPFlexAiStJcFs = {
  alignItems: "stretch",
  justifyContent: "flex-start"
};
export const dPFlexAiStJcFe = {
  alignItems: "stretch",
  justifyContent: "flex-end"
};
export const dPFlexAiStJcCt = {
  alignItems: "stretch",
  justifyContent: "center"
};
export const dPFlexAiStJcSb = {
  alignItems: "stretch",
  justifyContent: "space-between"
};
export const dPFlexAiStJcSa = {
  alignItems: "stretch",
  justifyContent: "space-around"
};
export const fontPrimary = {
  color: theme.PRIMARY_FONT_COLOR,
  fontFamily: theme.FONT_FAMILY
};
export const fontSecondary ={
  fontFamily:theme.SECONDARY_FONT_FAMILY,
  color:theme.COLOR_GREY_DARK_3,
}
export const fontSecondaryPrimaryFontColor ={
  fontFamily:theme.SECONDARY_FONT_FAMILY,
  color:theme.PRIMARY_FONT_COLOR,
}

export const fontFamilyPrimary = {
  fontFamily: theme.FONT_FAMILY
};

export const fontCollapsibleTitle = {
  color: theme.COLLAPSIBLE_TITLE_FONT_COLOR,
  fontFamily: theme.SECONDARY_FONT_FAMILY
};

export const fontPrimaryText = {
  color: theme.PRIMARY_FONT_COLOR,
  fontFamily: theme.FONT_FAMILY,
  fontSize: theme.PRIMARY_TEXT_SIZE
};
export const fontPrimaryTextBold = {
  color: theme.PRIMARY_FONT_COLOR,
  fontFamily: theme.FONT_FAMILY,
  fontSize: theme.PRIMARY_TEXT_SIZE,
  fontWeight: "bold"
};
export const fontSecondaryText = {
  color: theme.SECONDARY_FONT_COLOR,
  fontFamily: theme.FONT_FAMILY,
  fontSize: theme.PRIMARY_TEXT_SIZE
};
export const fontSecondaryTextBold = {
  color: theme.SECONDARY_FONT_COLOR,
  fontFamily: theme.FONT_FAMILY,
  fontSize: theme.PRIMARY_TEXT_SIZE,
  fontWeight: "bold"
};
export const btnBorderRadius = {
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  borderBottomLeftRadius: 8,
  borderTopLeftRadius: 8
};
export const fontDarkBlue = {
  color: theme.COLOR_BLUE_DARK_2,
  fontFamily: theme.FONT_FAMILY,
}
export const textFieldPd = {
  paddingTop: 30,
  paddingBottom: 10,
  paddingRight: 10,
  paddingLeft: 10,
  ...Platform.select({
    android: {
      paddingTop: 25,
      paddingBottom: 15
    },
    ios: {
      paddingTop: 25,
      paddingBottom: 15
    }
  })
};
export const textPickerCom = {
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
  borderTopLeftRadius: 5,
  borderRightWidth: 1,
  borderLeftWidth: 1,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  color: theme.PRIMARY_FONT_COLOR,
  backgroundColor: theme.COLOR_WHITE,
  borderColor: theme.COLOR_BORDER
};
export const textPickPd = {
  paddingTop: 10,
  paddingRight: 15,
  paddingBottom: 10,
  paddingLeft: 15
};
export const textFieldPrimary = {
  // color: theme.PRIMARY_FONT_COLOR,
  borderRightWidth: 1,
  borderLeftWidth: 1,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
  borderTopLeftRadius: 5,
  borderColor: theme.COLOR_BORDER
};
export const btnBgCom = {
  backgroundColor: theme.COLOR_BUTTON_BG
};
export const marginLtRtAuto = {
  marginLeft: "auto",
  marginRight: "auto"
};
export const marginAllAuto = {
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "auto",
  marginBottom: "auto"
};
export const textFieldPrimarySuggest = {
  color: theme.PRIMARY_FONT_COLOR,
  borderRightWidth: 1,
  borderLeftWidth: 1,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
  borderTopLeftRadius: 5,
  borderColor: theme.COLOR_BORDER,
  paddingTop: 15,
  paddingBottom: 15,
  paddingRight: 30
};
export const zeroPadding = {
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0
};
export const zeroPaddingTopBot = {
  paddingTop: 0,
  paddingBottom: 0
};
export const zeroPaddingLtRt = {
  paddingRight: 0,
  paddingLeft: 0
};
export const textAreaPrimary = {
  borderRightWidth: 1,
  borderLeftWidth: 1,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
  borderTopLeftRadius: 5,
  borderColor: theme.COLOR_BORDER
};
export const textAreaPd = {
  paddingRight: 15,
  paddingBottom: 10,
  paddingLeft: 15
};
export const textAreaTextPd = {
  paddingTop: 30
};
export const textPickerComArea = {
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
  borderTopLeftRadius: 5,
  borderRightWidth: 1,
  borderLeftWidth: 1,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  backgroundColor: theme.COLOR_WHITE,
  borderColor: theme.COLOR_BORDER
};
export const btnBorderRadiusSm = {
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
  borderTopLeftRadius: 5
};
export const textInputFramePd = {
  paddingTop: 8,
  paddingBottom: 5,
  paddingRight: 5,
  paddingLeft: 5,
};
export const textInputPd = {
  paddingTop: 10,
  paddingBottom: 5,
  paddingRight: 10,
  paddingLeft: 10,
};
export const textInputPrimary = {
  // color: theme.PRIMARY_FONT_COLOR,
  borderRightWidth: 1,
  borderLeftWidth: 1,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
  borderTopLeftRadius: 5,
  borderColor: theme.COLOR_BORDER
};
export const textInputFramePrimary = {
  color: theme.PRIMARY_FONT_COLOR,
  borderRightWidth: 1,
  borderLeftWidth: 1,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
  borderTopLeftRadius: 5,
  borderColor: theme.COLOR_BORDER
};
export const textPickerFrame = {
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
  borderTopLeftRadius: 5,
  borderRightWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  borderBottomWidth: 0,
  backgroundColor: theme.COLOR_WHITE,
  borderColor: theme.COLOR_BORDER
};
export const textPickerFrameFull = {
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
  borderTopLeftRadius: 5,
  borderRightWidth: 1,
  borderLeftWidth: 1,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  backgroundColor: theme.COLOR_WHITE,
  borderColor: theme.COLOR_BORDER
};
export const textAreaInputPd = {
  paddingTop: 15,
  paddingBottom: 5,
  paddingRight: 10,
  paddingLeft: 10,
};
export const textAreaInputPrimary = {
  color: theme.PRIMARY_FONT_COLOR,
  borderWidth: 0
};
export const textAreaInputFrame = {
  borderRightWidth: 1,
  borderLeftWidth: 1,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
  borderTopLeftRadius: 5,
  paddingTop: 1,
  paddingBottom: 1,
  paddingRight: 1,
  paddingLeft: 1,
  borderColor: theme.COLOR_BORDER
};
export const textInputPdPicker = {
  paddingTop: 10,
  paddingBottom: 5,
  paddingRight: 5,
  paddingLeft: 5,
};