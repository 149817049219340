import React from 'react';
import {View} from 'react-native';
import styles from '../../Css';
import UIText from '../../components/Text/text';

const warningCommon = props => {
  return (
    <View style={styles.notifyBlock}>
      <View
        style={[
          styles.notifyDetailsCom,
          styles.notifyNoPd,
          styles.notifyPdBot,
          props.textPosition === 'left' ? styles.flexJcFs : styles.flexJcCt,
        ]}>
        <UIText
          style={[styles.textPrimeExSmBold, styles.textRed]}
          title={props.errorMessage}
        />
      </View>
    </View>
  );
};

export default (warningCommon);
