import React from "react";
import { View, TouchableOpacity } from "react-native";
import styles from "../../../../Css";
import TitleBlock from "./titleBlock";
import DateRangeBlock from "./dateRangeBlock";
import DownloadBlock from "./downloadBlock";
import MenuBlock from "./menuBlock";
import LockBlock from "./lockBlock";
import PremiumBtnBlock from "./premiumBtnBlock";
import { pendoTrackEvent } from "../../../../util/pendoConfig";

const graphItem = (props) => {
  const {
    item,
    graphId,
    downloadwait,
    onPressDownload,
    onPressDelete,
    redirectToEdit,
    onPressPermium,
    windowWidth,
  } = props;
  return (
    <View
      key={item.id}
      style={[styles.viewSingleRow, styles.comSpSm]}
    >
      <View
        style={[styles.menuSingleBar, styles.menuItemView, 
          item.status === 'ENABLED'
          ? styles.menuBgWhite
          : styles.disableItemBg
        ]}
      >
        <TouchableOpacity
          style={[styles.menuSingleMain]}
          onPress={redirectToEdit}
          disabled={item.status !== 'ENABLED'}
        >
          <TitleBlock 
          name={item.name}
          allowFontScaling={false} 
          />
          
          <DateRangeBlock 
            startDate={item.startDate}
            endDate={item.endDate}
            createdOn={item.createdOn}
            status={item.status}
            windowWidth={windowWidth}
          />
          {item.status === 'ENABLED'
           ?  <>
                <DownloadBlock 
                  item={item}
                  graphId={graphId}
                  downloadwait={downloadwait}
                  onPressDownload={onPressDownload}
                />
                <MenuBlock 
                  onPressEdit={() => {
                    redirectToEdit();
                    pendoTrackEvent('Graphs@ > 3Dots > Edit');
                  }}
                  onPressDelete={onPressDelete}
                  nativeIdEdit={'graphs3DotsEdit'}
                />
              </>
            :
              <>
                <PremiumBtnBlock onPressPermium={onPressPermium} />
                <LockBlock />
              </>
          }
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default graphItem;
