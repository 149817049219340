import React from 'react';
import {Text, View} from 'react-native';
import styles from '../../Css';
import PrimaryButton from '../Button/primaryButton';
import PrimaryButtonWhite from '../Button/primaryButtonWhite';
import Modal from '../Modal/modal';
import ModalLayout from '../ModalLayout/modalLayout';

const biometricLoginModal = props => {
  return (
    <Modal
      visible={props.nextTimeBioMetric}
      onBackdropPress={props.onPressNotNow}
      backdropStyle={true}>
      <ModalLayout
        contPosition={'center'}
        showClose={false}
        pdCom={true}
        boxStyle={false}
        // hidePopup={props.hidePopup}
      >
        <View style={styles.popupContentWrapper}>
          <View style={[styles.popMessageContView]}>
            <View style={[styles.popMessageTitleView]}>
              <Text
                style={[
                  styles.textTitleBold,
                  styles.textThickGrey,
                  styles.textAlignCenter,
                ]}
                allowFontScaling={false}
                >
                {props.headerText || ''}
              </Text>
            </View>
            <View style={[styles.popMessageDecView]}>
              <Text
                style={[
                  styles.textPrimeLg,
                  styles.textThickGrey,
                  styles.textAlignCenter,
                ]}
                allowFontScaling={false}
                >
                {props.bodyText || ''}
              </Text>
            </View>
          </View>

          <View style={[styles.popBtnView, styles.comMgTopSm, styles.colPdBot]}>
            <View style={[styles.btnArea]}>
              <View style={styles.fieldBtn}>
                <PrimaryButton
                  btnText={props.biometricType}
                  btnPosition={'full'}
                  touchableOnPress={props.handleOnSubmitLoginTuchId}
                  // activityInd={saveWait}
                  btnShadow={true}
                  btnLg={true}
                  btnStyle={styles.submitBgBlueDark}
                />
              </View>
              <View style={styles.comSp} />
              <View style={styles.fieldBtn}>
                <PrimaryButtonWhite
                  btnText={'Not Now'}
                  btnPosition={'full'}
                  btnStyle={[styles.darkBtnBorder]}
                  touchableOnPress={props.onPressNotNow}
                  btnLg={true}
                />
              </View>
            </View>
          </View>
        </View>
      </ModalLayout>
    </Modal>
  );
};

export default (biometricLoginModal);
