import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Popover from '../Popover';
import {pendoTrackEvent} from '../../util/pendoConfig';

const NotificationWrapper = (props) => {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    let fullWidth = props.windowWidth;
    let notiyfyWidth = 0;
    if (fullWidth > 575) {
      notiyfyWidth = 340;
    } else if (fullWidth > 375) {
      notiyfyWidth = 300;
    } else {
      notiyfyWidth = 260;
    }
    return (
      <View style={styles.notifyArea}>
        <View style={styles.notifyLinkArea}>
          <TouchableOpacity
            onPress={(e) => {
              props.onPressBellIconWeb(e);
              pendoTrackEvent('Header > Notifications');
            }}>
            <View 
              nativeID={'headerNotifications'}
              style={[stylesRes.notifyIconSec, stylesRes.headerIconPdSmCom]}>
              <Image
                style={styles.headerNotifyIcon}
                source={require('../../assets/icons/bell-header.png')}
              />
            </View>
            {/* {props.notifCount && props.notifCount > 0 ? (
              {
                ...props.notifCount <= 9 ? (
                  <View style={ [styles.notifyAvailable, styles.notifyAvailableSmall] }>
                    <Text style={ [styles.textNotificationCount, styles.textWhite] } allowFontScaling={false}>{ props.notifCount }</Text>
                  </View>) : (props.notifCount >= 10 && props.notifCount <= 99) ? (
                  <View style={ [styles.notifyAvailable, styles.notifyAvailableMedium] }>
                    <Text style={ [styles.textNotificationCount, styles.textWhite] } allowFontScaling={false}>{ props.notifCount }</Text>
                  </View>) : props.notifCount > 99 ? (
                  <View style={ [styles.notifyAvailable, styles.notifyAvailableLarge] }>
                    <Text style={ [styles.textNotificationCount, styles.textWhite] } allowFontScaling={false}>99+</Text>
                  </View>
                  ) : null
              }
              ) : null } */}
            {Boolean(props.hasNotification && props.notifCount > 0) && (
              <View style={[
                styles.notifyAvailable, 
                props.notifCount <= 9  
                  ? styles.notifyAvailableSmall
                  : props.notifCount > 99
                    ? styles.notifyAvailableLarge
                    : styles.notifyAvailableMedium
              ]}>
                <Text style={[styles.textNotificationCount, styles.textWhite]} allowFontScaling={false}>{Boolean(props.notifCount > 99) ? "99+" : props.notifCount}</Text>
              </View>
            )}
          </TouchableOpacity>
        </View>
        <Popover
          open={props.showNotificationList}
          handleClose={props.handleClose}
          anchorEl={props.anchorEl}>
          <View style={{width: notiyfyWidth}}>
            <View
              style={[
                styles.notifyMenuOptionListCom,
                styles.notifyMax,
                styles.notifyMaxHeight,
              ]}>
              <View style={styles.notifyMenuOption}>
                <View style={[stylesRes.notifyMaxView]}>
                  <View style={[styles.secSubTitleArea, styles.greyBorder]}>
                    <View style={[styles.colPdLtRtTopBotLg]} nativeID={'notificationsClearAll'}>
                      <View style={[styles.secSubTitleView]}>
                        <View style={[styles.clearBox]}>
                          <TouchableOpacity
                            onPress={() => {
                              props.clearAllNotification();
                              pendoTrackEvent('Notifications > ClearAll');
                            }}>
                            <Text style={styles.textPrime} allowFontScaling={false}>Clear All</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              {props.getNotificationView()}
            </View>
          </View>
        </Popover>
      </View>
    );
}

export default (NotificationWrapper);
