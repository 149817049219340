import React, { Component } from "react";
import { Text, View, Image, TouchableOpacity, Platform, Linking } from "react-native";
import { connect } from "react-redux";
import moment from 'moment';
import ModalLayout from "../ModalLayout/modalLayout";
import ActivityIndicator from "../ActivityIndicator/activityIndicator";
import NestedScroll from "../NestedScroll/nestedScroll";
import TickButton from '../../components/CheckBox/CheckBox';
import * as actions from "../../store/actions/index";
import styles from "../../Css";
import { WEB } from "../../util/platformWindowConfig";


class HealthResourcePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadWait: false,
      tick: this.props.healthResourcePopupData?.showMassage,
      resourceMapedId : this.props.healthResourcePopupData?.communityResourceId,
    } 
  }

  onPressViewResource = (url) => {
    if (url && url !== "None") {
      if (Platform.OS === WEB) {
        window.open(url);
      } else {
        Linking.openURL(url);
      }
    }
    this.props.hidePopup();
    this.props.setHealthResourcePopupData({}); //clear previously stored data
  };

  onDownloadResource = (resourceId) => {
    this.setState({downloadWait: true});
    this.props.onDownloadResource(
      {resourceId: resourceId.toString()},
      () => {
        this.setState({downloadWait: false}); 
      }
    );
  };

  handleOnPressTick = () => {
    this.setState(prevState =>({
      tick : !prevState.tick 
    }))
  };

  componentWillUnmount = () => {
    let statusObj = {
      showMessage : this.state.tick
    }
    this.props.onHandleResourceMassageCheckBox(statusObj, this.state.resourceMapedId, ()=>{});
  };

  render() {
    const {healthResourcePopupData, hidePopup, timeZone} = this.props
    const {communityResponse, profileName, message} = healthResourcePopupData;
    const {favIcon, title, createdDate, tags, type, url, resourceId} = communityResponse;
    const {downloadWait, tick} = this.state;
    return (
      <ModalLayout
        contPosition={'start'}
        showClose={true}
        popupAuto={false}
        boxStyle={false}
        bordeRadSm={true}
        closeIcnType="SPECIAL"
        hidePopup={() => {
          hidePopup();
          this.props.setHealthResourcePopupData({}); //clear previously stored data
        }}
        >
        <React.Fragment>
          <View style={[styles.healthResourceModalWrapper]}>
            <View style={styles.flexAiCt}>
              <Image
                style={
                  styles.resourceIcon
                }
                source={{uri: favIcon}}
              />

              <Text 
                style={[styles.textPrimeBold,styles.darkLinktext, styles.comMgTopSm, styles.statusTextWebText, styles.secCenterItems, styles.centrTxtAlgn]}
                allowFontScaling={false}>
                {title}
              </Text>

              <Text 
                style={[ styles.textPrimeSm, styles.textGrey, styles.lblSp]}
                allowFontScaling={false}>
                Uploaded on {moment(createdDate).tz(timeZone).format("D MMM 'YY")}
              </Text>
            </View>

            <View style={[styles.flexRow,styles.resourcePopTagTp, styles.flexWrapView]}>
              <Text 
                style={[styles.textPrimeSm, styles.textGrey,]}
                allowFontScaling={false}>
                {'Tags: '}
              </Text>
              <View style={[styles.flexWrapView, styles.twoThirtyWidth, styles.flexRow]}>
              {tags && tags.length > 0 
                ? tags.map((tag, tagIndex) => (
                  <Text 
                    style={[styles.textPrimeSm, styles.textGrey, styles.twoThirtyWidth]}
                    allowFontScaling={false}>
                      {tag}
                      {tagIndex !== tags.length - 1
                        ? ", "
                        : ""}                  
                  </Text>
                ))
                : null
              }
              </View>
            </View>

            <View style={styles.appListMg}>
              <View style={[styles.flexRow]}>
                <Text 
                  style={[styles.textPrimeSm, styles.textGrey]}
                  allowFontScaling={false}>
                  Shared By: 
                </Text>

                <Text 
                  style={[styles.textPrimeSm, styles.textGrey,styles.privacyTextBlock]}
                  allowFontScaling={false}>
                {profileName}
                </Text>
              </View>
              {!Boolean(tick) && (
                <View style={[styles.healthResourceMsgContainer, styles.scrollingOp]}>
                  <View style={styles.scrollInsideContent}>
                    <NestedScroll>
                      {/* <Text style={[styles.textPrimeSm,styles.darkLinktext, styles.scrollSecContent, styles.noPdBot]}>
                        Message from: {profileName}
                      </Text> */}

                      <Text 
                        style={[styles.textPrimeSm,styles.darkLinktext,styles.upIconSec, styles.scrollSecContent]}
                        allowFontScaling={false}>
                        {message}
                      </Text>
                    </NestedScroll>
                  </View> 
                </View>
                )}      
            </View>
          
            <View style={[styles.checkBoxView, styles.flexCom, styles.appListMg]}>
              <View>
                <TickButton
                  ticked={tick}
                  toggleTickCallback={()=>this.handleOnPressTick()}
                  imagePath={tick ? 'tickButton' : 'untickButton'}
                  tickStyle={[styles.tickStyleCom, {marginRight: 2}]}
                />
              </View>
              <View style={styles.checkBoxItemText}>
                <Text
                  style={[
                    styles.textPrimeMd,
                    styles.textAlignLeft,
                    styles.textColorLight,
                  ]}
                  allowFontScaling={false}
                  >
                  {tick ? 'Show this message' : 'Dont show this message again'}
                </Text>
              </View>
            </View>

            <TouchableOpacity 
              style={[
                styles.flexAiCt, 
                styles.btnRowMgTop, 
                styles.viewResourceBtn,
                downloadWait
                ? styles.btnBlurState
                : styles.btnActiveState
              ]}
              underlayColor="rgba(0, 153, 168, 0.8)"
              onPress={() => {
                type  && type !== 'Document'
                  ? this.onPressViewResource(url)
                  : this.onDownloadResource(resourceId)
              }}
              disabled={downloadWait}>
              {downloadWait &&
                <View style={styles.actIndLeftView}>
                  <ActivityIndicator size="small" />
                </View>
              }
              <View>
                <Text 
                  style={[styles.textPrimeBold, styles.textWhite]}
                  allowFontScaling={false}>
                  {type  && type !== 'Document' ? 'View Resource' : 'Download Resource'}
                </Text>
              </View>
            </TouchableOpacity>

            {type  && type !== 'Document' &&
              <View style={styles.healthResourceContent}>
                <Text 
                  style={[styles.textPostBy, styles.centrTxtAlgn]}
                  allowFontScaling={false}>
                  This is an external link and will open outside of the application
                </Text>
              </View>
            }
          </View>
        </React.Fragment>
      </ModalLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    healthResourcePopupData: state.uistate.healthResourcePopupData,
    timeZone: state.routines.user.timezone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDownloadResource: (resourceId, callBack) => {
      dispatch(actions.onDownloadResource(resourceId, callBack));
    },
    onHandleResourceMassageCheckBox: (status, resourceId, callBack) => {
      dispatch(actions.handleResourceMassageCheckBox(status, resourceId, callBack));
    },
    setHealthResourcePopupData: (data) => {
      dispatch(actions.setHealthResourcePopupData(data));
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthResourcePopup);
