import * as actionTypes from './actionTypes';
import * as axiosCommon from '../../axios/axios-common';
import * as url from './urls';
import {logout, commonErrorHandler} from './index';
import {resetRedirectToInitialState} from "./redirect";

export const createUserMetric = (userMetrics, metricUOM, callBack) => {
  return dispatch => {
    if (userMetrics && metricUOM) {
      dispatch(createUserMetricsSuccess(userMetrics));
      dispatch(createMetricUOMSuccess(metricUOM));
      dispatch(updateUserMetrics(userMetrics));
      dispatch(updateUserMetricUOM(metricUOM));
      callBack(true);
    }
  };
};

export const createNewUserMetric = (metric, journalUserId, callBack) => {
  let CREATE_METRICS = url.CREATE_METRICS;
  if (journalUserId) {
    CREATE_METRICS += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      CREATE_METRICS,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(true, res.responseDto.data.metric);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; createNewUserMetric");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false);
          }
        }
      },
      metric,
    );
  };
};

export const createNewUserMetricUOM = (
  metricDefaultUnitOfMeasure,
  journalUserId,
  callBack,
) => {
  let CREATE_UOM = url.CREATE_UOM;
  if (journalUserId) {
    CREATE_UOM += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      CREATE_UOM,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(true, res.responseDto.data.metricDefaultUnitOfMeasure);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; createNewUserMetricUOM");
            logout();
          } else if (res.errorDto !== 'undefined') {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false);
          }
        }
      },
      metricDefaultUnitOfMeasure,
    );
  };
};

export const createUserMetricsSuccess = data => {
  return {
    type: actionTypes.ADD_METRICS_SUCCESS,
    newMetrics: data,
  };
};

export const createMetricUOMSuccess = data => {
  return {
    type: actionTypes.ADD_METRICS_UOM_SUCCESS,
    metricsUOM: data,
  };
};

export const updateUserMetrics = userMetrics => {
  return {
    type: actionTypes.UPDATE_USER_METRICS,
    metric: userMetrics,
  };
};

export const updateUserMetricUOM = metricUOM => {
  return {
    type: actionTypes.UPDATE_USER_METRICS_UOM,
    metricUom: metricUOM,
  };
};

export const onSelectMetric = (selectedMetric, callBack) => {
  return dispatch => {
    dispatch(selectMetricSuccess(selectedMetric));
    callBack();
  };
};

export const selectMetricSuccess = selectedMetric => {
  return {
    type: actionTypes.SELECT_METRIC,
    selectedMetric: selectedMetric,
  };
};

export const deleteMetric = (
  metricId,
  userDefinedMetric,
  callBack,
  journalUserId,
  hideErrorMsg = false
) => {
  let requestUrl = url.DELETE_METRIC + '?metricId=' + metricId;
  if (journalUserId) {
    requestUrl += '&journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosDELETE(requestUrl, res => {
      if (res.type === 'SUCCESS') {
        dispatch(deleteMetricSuccess(metricId, userDefinedMetric));
        callBack(true);
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; deleteMetric");
          dispatch(logout());
        } else {
          // dispatch(deleteMetricFailed(res.errorDto.data.error));
          if (!hideErrorMsg) {
            dispatch(commonErrorHandler(res.errorDto.data.error));
          }
          callBack(false);
        }
      }
    });
  };
};

export const deleteMetricSuccess = (metricId, userDefinedMetric) => {
  const filteredItems = userDefinedMetric.filter(res => res.id !== metricId);

  return {
    type: actionTypes.DELETE_METRIC_SUCCESS,
    userDefinedMetric: filteredItems,
  };
};

export const deleteMetricFailed = errorMsg => {
  return {
    type: actionTypes.DELETE_METRIC_FAIL,
    errorDescription: errorMsg,
  };
};

//Settings - Symptoms

export const createUserSymptom = (userSymptom, callBack, journalUserId) => {
  let requestUrl = url.UPDATE_USER_SYMPTOM;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(updateSymptomSuccess(res.responseDto.data.userSymptom));
          dispatch(updateNewSymptom(res.responseDto.data.userSymptom));
          callBack(true, res.responseDto.data.userSymptom);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; createUserSymptom");
            dispatch(logout());
          } else {
            // dispatch(updateSymptomFail(res.errorDto.data.error));
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false);
          }
        }
      },
      userSymptom,
    );
  };
};

export const updateSymptomSuccess = data => {
  return {
    type: actionTypes.UPDATE_USER_SYMPTOM_SUCCESS,
    selectedSymptom: data,
  };
};

export const updateNewSymptom = data => {
  return {
    type: actionTypes.ADD_USER_SYMPTOM,
    symptom: data,
  };
};

export const updateSymptomFail = errorMsg => {
  return {
    type: actionTypes.UPDATE_USER_SYMPTOM_FAIL,
    errorDescription: errorMsg,
  };
};

export const onSelectSymptom = (selectedSymptom, callBack) => {
  return dispatch => {
    dispatch(selectSymptomSuccess(selectedSymptom));
    callBack();
  };
};

export const selectSymptomSuccess = selectedSymptom => {
  return {
    type: actionTypes.SELECT_SYMPTOM,
    selectedSymptom: selectedSymptom,
  };
};

export const deleteUserSymptom = (
  id,
  userSymptoms,
  callBack,
  journalUserId,
  hideErrorMsg = false
) => {
  let requestUrl = url.DELETE_SYMPTOM + '?userSymptomId=' + id;
  if (journalUserId) {
    requestUrl += '&journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosDELETE(requestUrl, res => {
      if (res.type === 'SUCCESS') {
        dispatch(deleteSymptomSuccess(id, userSymptoms));
        callBack(true);
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; deleteUserSymptom");
          dispatch(logout());
        } else {
          // dispatch(deleteSymptomFailed(res.errorDto.data.error));
          if (!hideErrorMsg) {
            dispatch(commonErrorHandler(res.errorDto.data.error));
          }
          callBack(false);
        }
      }
    });
  };
};

export const deleteSymptomSuccess = (id, userSymptoms) => {
  const filteredItems = userSymptoms.filter(res => res.id !== id);

  return {
    type: actionTypes.DELETE_SYMPTOM_SUCCESS,
    userSymptoms: filteredItems,
  };
};

export const deleteSymptomFailed = errorMsg => {
  return {
    type: actionTypes.DELETE_SYMPTOM_FAIL,
    errorDescription: errorMsg,
  };
};

export const getSystemUnitOfMeasure = callBack => {
  return dispatch => {
    axiosCommon.axiosGET(url.GET_SYSTEM_UNIT_OF_MEASURE, res => {
      if (res.type === 'SUCCESS') {
        callBack(true, res.responseDto.data);
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; getSystemUnitOfMeasure");
          dispatch(logout());
        } else if (res.errorDto !== 'undefined') {
          dispatch(commonErrorHandler(res.errorDto.data.error));
          callBack(false, res.errorDto.data.error);
        }
      }
    });
  };
};

export const updateSystemDefinedAllergies = callBack => {
  return dispatch => {
    axiosCommon.axiosGET(url.FETCH_ALL_SYSTEM_DEFINED_ALLERGIES, res => {
      if (res.type === 'SUCCESS') {
        callBack(true, res.responseDto.data);
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; updateSystemDefinedAllergies");
          dispatch(logout());
        } else {
          dispatch(commonErrorHandler(res.errorDto.data.error));
          callBack(false);
        }
      }
    });
  };
};

export const updateSystemDefinedSymptoms = callBack => {
  return dispatch => {
    axiosCommon.axiosGET(url.FETCH_ALL_SYSTEM_DEFINED_SYMPTOMS, res => {
      if (res.type === 'SUCCESS') {
        callBack(true, res.responseDto.data);
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; updateSystemDefinedSymptoms");
          dispatch(logout());
        } else {
          dispatch(commonErrorHandler(res.errorDto.data.error));
          callBack(false);
        }
      }
    });
  };
};
