import React from "react";
import { View, Text } from "react-native";
import styles from '../../../Css';

const EventBubbleTagItem = props =>  {
  const {item, index} = props;
  return (
    <View key={index} style={{zIndex: 9999 - index}}>
        <View style={[styles.flexRow, styles.flexAiCt, styles.comSpSm]}>
            {/* <View style={[styles.infoSp, styles.wdTwenty]}>
                <Text>{index}.</Text>
            </View> */}
            <View
              style={[
                styles.flexRow, 
                styles.flexJcSb, 
                styles.flexAiCt, 
                styles.medsEventWrapper,
                styles.navListLiContent,
                styles.colPdRtNotify,
                styles.mgRtFifteen
              ]}>
                <View style={[styles.flexRow, styles.flexAiCt]}>
                  <View style={styles.iconLtSp}>
                    <Text 
                      style={[styles.textPrimeBold, styles.medsMxWd]}
                      numberOfLines={1}
                    >
                      {item || ''}
                    </Text>
                  </View>
                </View>
            </View>
        </View>
    </View>
  );
};

export default EventBubbleTagItem;