import React from 'react';
import {Image, View, Text, ScrollView, Platform} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import {connect} from 'react-redux';
import LoadinIndicator from '../../components/Loading/loadinIndicator';
import AbstractDashboardLoadingScreen, {
    AbstractDashbordLoadingScreen_mapDispatchToProps,
    AbstractDashbordLoadingScreen_mapStateToProps
} from "./AbstractDashbordLoadingScreen";
import { WEB } from '../../util/platformWindowConfig';


class DashbordLoadingScreen extends AbstractDashboardLoadingScreen {
    constructor(props) {
        super(props);
        if (Platform.OS !== WEB) {
        this.state = {
            windowHeight: 0,
            windowWidth: 0,
            token: '',
        };
        }
    }

    render() {
        const stylesRes = stylesResponsive.getProcessedStyles().styles;
        const ids = stylesResponsive.getProcessedStyles().ids;
        let fullWidth = this.state.windowWidth;
        let fullHeight = this.state.windowHeight;
        let dynamicWHBot = 0;
        let dynamicWHTop = 0;
        let dynamicWHRight = 0;
        let dynamicTotWBot = 0;
        let botSpace = 50;
        dynamicWHTop = (28 / 100) * fullHeight;
        dynamicWHBot = (50 / 100) * fullHeight;
        dynamicWHRight = (16 / 100) * fullHeight;
        dynamicTotWBot = dynamicWHBot + dynamicWHRight + botSpace;
        if (dynamicWHBot > fullWidth || dynamicWHTop > fullWidth) {
            dynamicWHBot = fullWidth;
            dynamicWHRight = (10 / 100) * fullHeight;
            dynamicWHTop = fullWidth;
        } else if (fullWidth > 767 && dynamicTotWBot < fullWidth) {
            dynamicWHTop = (40 / 100) * fullHeight;
            dynamicWHBot = (62 / 100) * fullHeight;
            dynamicWHRight = (28 / 100) * fullHeight;
        }
        return (
      <ScrollView
        style={[
          stylesRes.containerFullView,
          stylesRes.contBgBlue,
          {minHeight: fullHeight},
        ]}
        dataSet={{media: ids.containerFullView}}>
        <View
          style={[
            stylesRes.containerCommonView,
            stylesRes.contBgBlue,
            {minHeight: fullHeight},
          ]}
          dataSet={{media: ids.containerCommonView}}>
          <View
            style={[
              stylesRes.containerCommonMaxView,
              stylesRes.contBgBlue,
              {minHeight: fullHeight},
            ]}
            dataSet={{media: ids.containerCommonMaxView}}>
            <View
              style={[
                stylesRes.gridContentView,
                {minHeight: fullHeight},
              ]}
              dataSet={{media: ids.gridContentView}}>
                        <View
                            style={[
                                stylesRes.loadBgTopViewOne,
                                {width: dynamicWHTop, height: dynamicWHTop},
                            ]}>
                            <Image
                                style={styles.imgCover}
                                source={require('../../assets/background-images/load-blue-bg-top.png')}
                            />
                        </View>
                        <View
                            style={[
                                stylesRes.loadBgRightViewOne,
                                {width: dynamicWHRight, height: dynamicWHRight},
                            ]}>
                            <Image
                                style={styles.imgCover}
                                source={require('../../assets/background-images/blob.png')}
                            />
                        </View>

                        <View
                            style={[
                                stylesRes.loadBgBotViewOne,
                                {width: dynamicWHBot, height: dynamicWHBot},
                            ]}>
                            <Image
                                style={styles.imgCover}
                                source={require('../../assets/background-images/load-blue-bg-bottom.png')}
                            />
                        </View>

                        <View style={stylesRes.wrapperCenterView}>
                            <LoadinIndicator/>
                            <View style={stylesRes.loaderPd}>
                                <Text
                                    style={[
                                        styles.textPrime,
                                        styles.textAlignCenter,
                                        styles.textWhite,
                                    ]}
                                    allowFontScaling={false}
                                    >
                                    Loading...Hold tight!
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
                </View>
                </ScrollView>
        );
    }
}

export default connect(
    AbstractDashbordLoadingScreen_mapStateToProps,
    AbstractDashbordLoadingScreen_mapDispatchToProps,
)(DashbordLoadingScreen);
