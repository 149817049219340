import React, {useEffect, useState, useRef, Fragment} from "react";
import {View, Text, Image, Animated} from "react-native";
import {GestureHandlerRootView, TouchableOpacity} from "react-native-gesture-handler";
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import * as constants from '../HealthJourneyConstant'
import * as ColorCode from '../../../components/ColorPicker/ColorCodes';

let layoutData = null;
const EventBubbleItem = props => {
  const size = useRef(new Animated.Value(0)).current;
  const x = useRef(new Animated.Value(0)).current;
  const interpolate = x.interpolate({
    inputRange: [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
    outputRange: [0, -20, 20, 0, -20, -0, 20, 0, -20, 0]
  })
  const {
    item,
    type,
    index,
    openedYearAndId,
    year,
    onPressViewItem,
    cardId,
    journalEntries,
    operation = "VIEW",
    activeBubbleId,
    setActiveBubble,
    deleting,
    onDeleteTherapie,
    isNew
  } = props;
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {id, displayProperties, name, dosages, identificationNumber, drugIngredients} = item;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      (!open &&
        openedYearAndId &&
        openedYearAndId.year === year &&
        openedYearAndId.type === type &&
        openedYearAndId.bubbleId === id &&
        openedYearAndId.id === cardId) ||
      (activeBubbleId)
    ) {
      if (activeBubbleId === id) return;
      const flag = activeBubbleId ? activeBubbleId === id : true
      flag && setOpen(flag);
      Animated.timing(size, {
        toValue: flag ? 1 : 0,
        duration: 200,
        useNativeDriver: true,
      }).start(!flag && (() => setOpen(flag)));
    }
    if (!activeBubbleId) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedYearAndId, activeBubbleId]);

  const handleOpenClose = () => {
    setActiveBubble(open ? null : id);
    setTimeout(() => {
      !open && setOpen(!open);
      Animated.timing(size, {
        toValue: !open ? 1 : 0,
        duration: 200,
        useNativeDriver: true,
      }).start(open && (() => setOpen(!open)));
    }, 0);
  }

  const getRecordings = (id, type) => {
    let count = 0;
    if (!journalEntries) return count;
    if (type === constants.eventTypes.SYMPTOMS) {
      journalEntries.forEach(({symptoms}) => {
        if (symptoms && symptoms.length > 0) {
          const records = symptoms.filter(res => res.symptom === id);
          count += records.length
        }
      });
    } else if (type === constants.eventTypes.HEALTHDATA) {
      journalEntries.forEach(({metrics}) => {
        if (metrics && metrics.length > 0) {
          const records = metrics.filter(res => res.metric === id);
          count += records.length
        }
      });
    }
    return count;
  };

  // const wobble = () => {
  //   x.setValue(0);
  //   Animated.timing(x, {
  //     toValue: 5,
  //     duration: 500,
  //     useNativeDriver: true,
  //     easing: Easing.bounce
  //   }).start();
  // }

  const itemContent = (keyText, value, style) => {
    return (
      <View style={[
        styles.flexCol,
        styles.overflowHid,
        styles.flexAiCt,
      ]}>
        <Text style={styles.textGreyDark}>{keyText}</Text>
        <View style={{height: 8}}/>
        <Text style={value === 'add' ? styles.textColorLight : style}>{value}</Text>
      </View>
    );
  };

  const getMedicationView = () => {
    return (
      <Fragment>
        <View>
          <View
            style={[
              styles.flexRow,
              styles.flexJcSb,
              styles.titlePdBotXSm,
              styles.headerBg,
              stylesRes.pdMedsValRes,
              styles.ItemRowSp,
              styles.flexAiCt,
            ]}
            dataSet={{media: ids.pdMedsValRes}}>
            {itemContent(
              'Quantity:',
              dosages && dosages[0]?.quantity
                ? dosages[0]?.quantity
                : 'add'
            )}

            {itemContent(
              'Measure:',
              dosages && dosages[0]?.units
                ? dosages[0]?.units
                : 'add'
            )}
            {itemContent(
              'Frequency:',
              dosages && dosages[0]?.frequency
                ? dosages[0]?.frequency
                : 'add'
            )}
          </View>

          <View
            style={[
              styles.flexRow,
              styles.flexJcSb,
              styles.flexAiCt,
              styles.contSmPdTopBot,
              stylesRes.pdMedsValRes,
            ]}
            dataSet={{media: ids.pdMedsValRes}}>
            {itemContent(
              'DIN/NPN:',
              identificationNumber || 'add',
              styles.bold
            )}
            {itemContent(
              'Strength:',
              drugIngredients && drugIngredients[0]
                ? drugIngredients[0]?.strength + drugIngredients[0]?.strengthUnit
                : 'add'
            )}
            {itemContent(
              'Graph Colour:',
              <View
                style={[
                  styles.circleDotEvent,
                  {
                    backgroundColor: displayProperties?.color
                      ? displayProperties?.color
                      : ColorCode.BGMEDICATION
                  },
                ]}
              />
            )}
          </View>
        </View>
      </Fragment>
    );
  };

  const getDefaultView = () => {
    return (
      <Fragment>
        <View
          style={[
            styles.flexRow,
            styles.flexJcSb,
            styles.contSmPdTopBot,
            stylesRes.pdMedsValRes,
          ]}
          dataSet={{media: ids.pdMedsValRes}}>
          {itemContent(
            'Measure:',
            displayProperties?.scaleMax === 1 &&
            displayProperties?.scaleMin === 0
              ? constants.YES_OR_NO
              : constants.ONE_TO_TEN
          )}
          {itemContent(
            'Graph Colour:',
            <View
              style={[
                styles.circleDotEvent,
                {
                  backgroundColor: displayProperties?.color
                    ? displayProperties?.color
                    : type === constants.eventTypes.SYMPTOMS
                      ? ColorCode.BGSYMPTOM
                      : ColorCode.BGHEALTHDATA
                },
              ]}
            />
          )}
          {itemContent(
            'Recordings:',
            getRecordings(id, type)
          )}
        </View>
      </Fragment>
    )
  }

  return (
    <GestureHandlerRootView style={{zIndex: 999999 - index}}>
      <View
        onLayout={e => (layoutData = e.nativeEvent.layout)}
        key={index}
        style={{zIndex: 999999 - index}}>
        {/* symptom badge */}
        <View style={[styles.flexRow, styles.flexAiCt, styles.comSpSm]}>
          {/* {operation === 'VIEW' && (
          <View style={[styles.infoSp, styles.wdTwenty]}>
            <Text>{index}.</Text>
          </View>
        )} */}

          <TouchableOpacity onPress={handleOpenClose}>
            <View
              style={[
                styles.flexRow,
                styles.flexJcSb,
                styles.flexAiCt,
                styles.medsEventWrapper,
                styles.navListLiContent,
                styles.colPdRtNotify,
                type === constants.eventTypes.MEDICATIONS && styles.medEventWdFull,
                stylesRes.mgMbZeroDescThirty,
                open && styles.lightHighlightedBleBg
              ]} dataSet={{media: ids.mgMbZeroDescThirty}}>
              <View style={[styles.flexRow, styles.flexAiCt]}>
                <View
                  style={[
                    styles.circleDotEvent,
                    {
                      backgroundColor: displayProperties?.color
                        ? displayProperties?.color
                        : type === constants.eventTypes.SYMPTOMS
                          ? ColorCode.BGSYMPTOM
                          : type === constants.eventTypes.HEALTHDATA
                            ? ColorCode.BGHEALTHDATA
                            : ColorCode.BGMEDICATION
                    },
                  ]}
                />
                <View style={styles.iconLtSp}>
                  <Text
                    style={[styles.textPrimeBold, styles.mxWdOneSixty]}
                    numberOfLines={1}>
                    {name || ''}
                  </Text>
                </View>
              </View>
              <View style={styles.iconLtSp}>
                <View style={styles.tenGap}/>
                <Image
                  style={styles.infoDarkIcon}
                  source={require('../../../assets/health-journey-icons/info-icon-dark.png')}
                  resizeMode={constants.IMAGE_RESIZE_MODE}
                />
              </View>
            </View>
          </TouchableOpacity>
        </View>

        {/* popup */}
        {Boolean(open) && (
          <Animated.View
            style={[
              styles.medsPopupWrapper,
              operation !== 'VIEW'
                ? stylesRes.medsPopsNtViewWrapperRes
                : stylesRes.medsPopUpWrapperRes,
              {transform: [{scale: size}, {translateX: interpolate}], opacity: size, elevation: 1},
            ]}
            dataSet={{media: operation !== 'VIEW' ? ids.medsPopsNtViewWrapperRes : ids.medsPopUpWrapperRes}}>
            {/* Product Name */}
            <View
              style={[
                styles.flexRow,
                styles.flexAiFs,
                styles.flexJcSb,
                styles.contSmPdTopBot,
                styles.navListsubPd,
                styles.rtPdLg,
              ]}>
              <View style={styles.flexRow}>
                <Image
                  style={{width: 15, height: 15}}
                  source={require('../../../assets/health-journey-icons/info-icon-dark.png')}
                  resizeMode={constants.IMAGE_RESIZE_MODE}
                />
                <View style={styles.tenGap}/>
                <View style={[styles.flexCol, stylesRes.mxProductName]} dataSet={{media: ids.mxProductName}}>
                  <Text style={styles.textGreyDark}>
                    {type === constants.eventTypes.SYMPTOMS
                      ? 'Symptom:'
                      : type === constants.eventTypes.HEALTHDATA
                        ? 'Health Data:'
                        : 'Product Name:'}
                  </Text>
                  <View style={{height: 10}}/>
                  <Text style={styles.bold}>{name}</Text>
                </View>
              </View>
              <View>
                <TouchableOpacity onPress={handleOpenClose}>
                  <View style={styles.pdAllFive}>
                    <Image
                      style={{width: 15, height: 15}}
                      source={require('../../../assets/health-journey-icons/health-journey-close.png')}
                    />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            {type === constants.eventTypes.MEDICATIONS ? getMedicationView() : getDefaultView()}
            <View style={styles.medsDivideSec}/>
            {/* Button */}
            <View style={[styles.flexJcCtAiCt, operation !== 'VIEW' && styles.flexRow]}>
              {operation !== 'VIEW' &&
                <View style={styles.wdHalfPerc}>
                  <TouchableOpacity
                    onPress={() => onDeleteTherapie(item.id, isNew, type)}
                    disabled={deleting}>
                    <View style={[styles.flexJcCtAiCt, styles.fourtyFourHg, styles.wdFullPer]}>
                      <Text style={[styles.textPrimeBold, styles.medsRemoveClr]}>
                        X Remove
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              }
              {operation !== 'VIEW' && <View style={[styles.bodsButtonDivVerticle, styles.fourtyFourHg]}/>}
              <View style={operation !== 'VIEW' ? styles.wdHalfPerc : styles.wdFullPer}>
                <TouchableOpacity
                  disabled={deleting}
                  onPress={() =>
                    onPressViewItem(id, getRecordings(id, type), cardId, year, layoutData, type)
                  }>
                  <View style={[styles.flexJcCtAiCt, styles.wdFullPer, styles.fourtyFourHg]}>
                    <Text
                      style={[
                        styles.textPrimeBold,
                        styles.medsRemoveClr,
                        styles.seaBluTxtColor,
                      ]}>
                      View / Edit Details
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </Animated.View>
        )}
      </View>
    </GestureHandlerRootView>
  );
};

export default EventBubbleItem;