import React from "react";
import { Text, View, Image } from "react-native";
import styles from "../../Css";
import Modal from "../Modal/modal";
import ModalLayout from "../../components/ModalLayout/modalLayout";
import PrimaryButton from "../../components/Button/primaryButton";

const CommonModal = (props) => {
  return (
    <Modal
      visible={props.visibleModal}
      onRequestClose={props.onRequestClose}
      onBackdropPress={props.onBackdropPress}
    >
      <ModalLayout
        contPosition={"start"}
        showClose={true}
        pdCom={true}
        hidePopup={props.onBackdropPress}
        modalSpecStyle={styles.comfirmModalMin}
      >
        <View style={[styles.popupContentWrapper, styles.flexCom]}>
          <View
            style={[styles.popMessageMainView, styles.flexCom, styles.flexJcCt]}
          >
            {Boolean(props.alertTitle) && (
              <View style={[styles.popMessageComView, styles.comMgBotRg]}>
                <View style={[styles.popMessageTitleView]}>
                  <Text
                    style={[
                      styles.textTitleBold,
                      styles.textThickGrey,
                      styles.textAlignCenter,
                    ]}
                    allowFontScaling={false}
                  >
                    {props.alertTitle}
                  </Text>
                </View>
              </View>
            )}

            {Boolean(props.tickCircle) && (
              <View style={[styles.popIconView]}>
                <View style={styles.popMessageIconView}>
                  <Image
                    style={styles.popMessageIcon}
                    source={require("../../assets/icons/right.png")}
                  />
                </View>
              </View>
            )}

            {Boolean(props.exclamationCircle) && (
              <View style={[styles.popIconView]}>
                <View style={styles.popMessageIconView}>
                  <Image
                    style={styles.popMessageIcon}
                    source={require("../../assets/icons/circle-exclamation.png")}
                  />
                </View>
              </View>
            )}

            {Boolean(props.alertBodyTitle) && (
              <View style={[styles.popMessageSmComView, styles.secBgPdTop]}>
                <Text
                  style={[
                    styles.textPrimeMdBold,
                    styles.textThickGrey,
                    styles.textAlignCenter,
                  ]}
                  allowFontScaling={false}
                >
                  {props.alertBodyTitle}
                </Text>
              </View>
            )}
            {Boolean(props.alertBody) && (
              <View style={[styles.popMessageSmView, styles.secBgPdTop]}>
                <Text
                  style={[
                    styles.textPrimeMd,
                    styles.textThickGrey,
                    styles.textAlignCenter,
                  ]}
                  allowFontScaling={false}
                >
                  {props.alertBody}
                </Text>
              </View>
            )}
          </View>
          {Boolean(props.btnText) && (
          <View style={[styles.popBtnView, styles.comMgTopSm]}>
            <View style={[styles.btnArea, styles.flexRow]}>
              <View style={styles.fieldBtn}>
                <PrimaryButton
                  btnText={props.btnText}
                  btnPosition={"full"}
                  touchableOnPress={props.handleConfirm}
                  activityInd={props.confirmWait}
                  btnShadow={true}
                  disabled={props.disabled}
                />
              </View>
            </View>
          </View>
          )}
        </View>
      </ModalLayout>
    </Modal>
  );
};
export default (CommonModal);
