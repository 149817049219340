import React from "react";
import { Image, View, Text, ActivityIndicator } from "react-native";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import styles from '../../../Css';
import { pendoTrackEvent } from "../../../util/pendoConfig";

const {Popover} = renderers;

const MenuOptionPopover = props =>  {
  const {placement = 'bottom', triggerIcon, menuOptions, disabled, menuTrigger, nativeIdMenuTrigger} = props;
  return (
    <Menu
      renderer={Popover}
      rendererProps={{
        preferredPlacement: placement,
      }}>
      <MenuTrigger disabled={disabled} onPress={menuTrigger ? () => pendoTrackEvent(menuTrigger) : undefined}>
        <View style={[styles.itemIconSingle, {minHeight: 20}]} nativeID={nativeIdMenuTrigger}>
          <Image
            style={[styles.menuTriggerIcnHlthJourney, props.itemMenuTrigger && styles.menuTriggerIcnHlthJourneyLg]}
            source={triggerIcon}
            resizeMode={'contain'}
          />
        </View>
      </MenuTrigger>
      <MenuOptions style={styles.dropDownList}>
        {menuOptions && menuOptions.length > 0 && menuOptions.map((option, idx) => (
          <View nativeID={option.nativeId}  key={idx}>
            <MenuOption disabled={option.disabled} style={[styles.liItem, styles.flexRow]} onSelect={option.onSelect}>
              {option.activity && <ActivityIndicator />}
              <Text style={styles.listText} allowFontScaling={false}>
                {option.title}
              </Text>
            </MenuOption>
          </View>
        ))} 
      </MenuOptions>
    </Menu>
  );
};

export default MenuOptionPopover;