import React, { Component } from "react";
import { View, Image, Text } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import Collapsible from "../Collapsible/Collapsible";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import Graph from "./Graph";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from "react-native-popup-menu";
import _ from "underscore";
import PlusButtonCom from "../../components/Button/plusButtonCom";

const { Popover } = renderers;

class ReportGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleReportGraph: props.graphOpen,
      savedGraph: this.props.savedGraph,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      startDate: props.startDate,
      endDate: props.endDate,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      startDate: props.startDate,
      endDate: props.endDate,
    });
    const { savedGraph } = props;
    this.setState({ savedGraph: savedGraph });
  }
  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    return (
      <Collapsible
        title="Graph"
        contentVisibility={this.state.visibleReportGraph}
        handleOnPressCollapsible={() => {
          this.setState({
            visibleReportGraph: !this.state.visibleReportGraph,
          });
          this.props.setTabOpen(6, !this.state.visibleReportGraph);
        }}
      >
        <View style={[styles.collapseContent]}>
          {!_.isEmpty(this.props.savedGraph) && (
            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <View style={[styles.addedItemGraphs]}>
                  <View style={[styles.addedGraphSingle]}>
                    <View
                      style={[
                        styles.listItemView,
                        styles.listItemAddedView,
                        styles.colPdLtRt,
                        styles.colBgLight,
                      ]}
                    >
                      <View style={[styles.settingSecGraph]}>
                        <Menu
                          renderer={Popover}
                          rendererProps={{
                            preferredPlacement: "left",
                          }}
                        >
                          <MenuTrigger>
                            <Image
                              style={[styles.settingIcon, styles.settingSp]}
                              source={require("../../assets/icons/settings.png")}
                            />
                          </MenuTrigger>
                          <MenuOptions style={styles.dropDownList}>
                            <MenuOption
                              style={styles.liItem}
                              onSelect={() =>
                                this.props.redirectReportAttachGraph()
                              }
                            >
                              <Text style={styles.listText} allowFontScaling={false}>Edit graph</Text>
                            </MenuOption>
                            <MenuOption
                              style={styles.liItem}
                              onSelect={this.props.removeReportGraph}
                            >
                              <Text style={styles.listText} allowFontScaling={false}>Remove graph</Text>
                            </MenuOption>
                          </MenuOptions>
                        </Menu>
                      </View>

                      <View
                        style={[styles.listContentViewFull, styles.colPdTopBot]}
                      >
                        <View style={[styles.listContentView]}>
                          <View
                            style={[styles.addedItemInd, styles.addedItemRtPd]}
                          >
                            <View style={[styles.itemFullRow]}>
                              <View style={stylesRes.itemTextAreaMain}>
                                <View style={[styles.itemTextDetails]}>
                                  <View style={[styles.itemTextMain]}>
                                    <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                                      {this.state.savedGraph.name}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={[styles.listItemView, styles.colPdInSec]}>
                      <View style={[styles.graphItemPreview]}>
                        <View style={stylesRes.detailGraph}>
                          <Graph
                            isEdit={false}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            updateGrph={this.props.updateGrph}
                            graphDivId={"chartdiv_report"}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}

          {_.isEmpty(this.props.savedGraph) && (
            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <View
                  style={[styles.addItemSecFull, styles.colBg, styles.colPd]}
                >
                  <View style={[styles.btnArea]}>
                    <PlusButtonCom
                      btnPosition={"left"}
                      btnText={"Add"}
                      touchableOnPress={this.props.redirectReportAttachGraph}
                    />
                  </View>
                </View>
              </View>
            </View>
          )}
        </View>
      </Collapsible>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    savedGraph: state.report.savedGraph,
    dashboardProcessed: state.routines.dashboardProcessed,
    graphOpen: state.report.graphOpen,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    startDate: state.report.startDate,
    endDate: state.report.endDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeReportGraph: () => dispatch(actions.removeReportGraph()),
    setTabOpen: (tab, open) => dispatch(actions.setTabOpen(tab, open)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportGraph);
