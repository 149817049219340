import { Platform } from "react-native";
import * as variableShadow from "./base/variableShadow";
import theme from "./base/theme";
export const cssJournal = {
    fieldColActity: {
        flex: 1,
    },
    fieldColActitySlider: {
        flex: 1,
    },
    fieldColActitySliderPreview: {
        flex: 0.9,
    },
    symptomSliderLg: {
        width: '100%'
    },
    symptomSliderSm: {
        width: 100
    },
    symptomSliderLgPreview: {
        width: '80%'
    },
    symptomSliderSmPreview: {
        width: 100
    },
    gradientSliderView: {
        ...Platform.select({
            web: {
                paddingLeft: 15,
                paddingRight: 15,
            },
        }),
    },
    gradientSliderDisplay: {
        ...Platform.select({
            web: {
                marginLeft: 15,
                marginRight: 15,
            },
        }),
    },
    fieldColSliderData: {
        maxWidth: 42,
        flexBasis: 42,
    },
    fieldColSliderDataLg: {
        maxWidth: 52,
        flexBasis: 52,
    },
    activitySliderWrapper: {
        paddingBottom: 15,
        marginBottom: 15,
    },
    symptomSliderWrapper: {
        paddingBottom: 15,
    },
    sliderTouchIconView: {
        marginTop: 5,
    },
    textInfoView: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 10
    },
    textIconViewRt: {
        paddingLeft: 5,
    },
    simpleBtnShadow: {
        ...variableShadow.simpleBtnShadow,
    },
    simpleBtnBox: {
        paddingTop: 7,
        paddingLeft: 10,
        paddingBottom: 7,
        paddingRight: 10,
        backgroundColor: theme.COLOR_WHITE,
        minHeight: 35,
    },
    simpleBtnBoxCom: {
        paddingLeft: 10,
        paddingRight: 10
    },
    simpleBtnBoxSm: {
        paddingLeft: 5,
        paddingRight: 5
    },
    simpleBtnSm: {
        maxHeight: 31
    },
    imgViewSm: {
        position: "absolute",
        display: "flex",
        zIndex: 99,
    },
    imgViewSmRt: {
        right: 5
    },
    imgViewSmFt: {
        left: 0
    },
    imgViewLgRt: {
        right: 10
    },
    imgViewLgFt: {
        left: 0
    },
    textFieldDateSm: {
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 5,
        paddingRight: 25,
        height: 35,
        borderWidth: 0,
        borderColor: 'transparent',
    },
    dateLgPd: {
        paddingLeft: 10,
        paddingRight: 10
    },
    dateSmPd: {
        paddingLeft: 5,
        paddingRight: 5
    },
    bgContentPd: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
    },
    arrowSelect: {
        maxWidth: 28,
        flexBasis: 28,
        paddingLeft: 10,
        height: 18,
    },
    copyBtnBox: {
        maxWidth: '85%'
    },
    copyBtnBoxSm: {
        maxWidth: '75%'
    },
    sectionMaxWidth: {
        maxWidth: '100%',
    },
    textInputAreaView: {
        width: '100%'
    },
    calSectionHeader: {
        marginLeft: 'auto'
    },
    dateTimeValue: {
        minHeight: 29,
        justifyContent: 'center'
    },
    customPaddding: {
        paddingLeft: 0
    },
    dateTimeValuePaddingLt: {
        minHeight: 29,
        justifyContent: 'center',
        paddingLeft: '10%'
    },
    flexComDateSmCom: {
        maxWidth: 170,
        flexBasis: 170
    },
    settingIconDark: {
        tintColor: theme.PRIMARY_FONT_COLOR,
    },
    dateItemWrapper: {
        paddingTop: 3,
    },
    journalDateSectionSm: {
        maxWidth: 140,
        flexBasis: 140
    },
    journalDateSectionLg: {
        maxWidth: 160,
        flexBasis: 160
    },
    customTextDate: {
        width: 300,
    },
    copyToNewSectionLg: {
        maxWidth: 40,
        flexBasis: 40
    },
    copyToNewSectionSm: {
        maxWidth: 35,
        flexBasis: 35
    },
    copyNewIcon: {
        width: 24,
        height: 24,
    },
    journalHeaderArea: {
        maxWidth: '100%',
    },
    ltLgPd: {
        paddingLeft: 46,
    },
    topMgWarning: {
        marginTop: -7,
    },
    lockLg: {
        width: 15,
        height: 20
    },
    listLockSec: {
        flexBasis: 35,
        maxWidth: 35,
        paddingRight: 10,
        paddingLeft: 10,
    },
    menuContMin: {
        minWidth: 50,
    },
    disableItemBg: {
        backgroundColor: theme.COLOR_GREY_LIGHT_EX_17
    }
};
