import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  metric: {
    id: null,
    name: null,
    description: null,
    typeOfMeasurement: null,
    metricGroup: null,
    displayProperties: {
      color: "",
      dashedLine: false,
      scaleMin: null,
      scaleMax: null
    }
  },
  newMetrics: null,
  metricsUOM: null,
  selectedMetric: null
};
const createMetricsSuccess = (state, action) => {
  return updateObject(state, { newMetrics: action.newMetrics });
};

const createMetricUOMSuccess = (state, action) => {
  return updateObject(state, { metricsUOM: action.metricsUOM });
};

const selectMetric = (state, action) => {
  return updateObject(state, {
    selectedMetric: action.selectedMetric
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_METRICS_SUCCESS:
      return createMetricsSuccess(state, action);
    case actionTypes.ADD_METRICS_UOM_SUCCESS:
      return createMetricUOMSuccess(state, action);

    case actionTypes.SELECT_METRIC:
      return selectMetric(state, action);

    default:
      return state;
  }
};
export default reducer;
