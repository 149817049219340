import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

class ReportIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelect: this.props.selectedIssuesObj[this.props.id] ? true : false
    };
  }

  onSelect = () => {
    this.setState({ isSelect: !this.state.isSelect });
    this.props.addRemoveReportIssue(this.props.question);
    this.props.setIssueSelected(
      Object.values(this.props.selectedIssuesObj).length > 0
    );
  };
  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.messageSecRow, styles.greyBorder]}>
        <TouchableOpacity style={[styles.messageRow]} onPress={this.onSelect}>
          <View style={styles.messageDetailSec}>
            <View style={styles.messageDetailCont}>
              <View
                style={[
                  styles.customBorderSec,
                  { backgroundColor: this.props.color }
                ]}
              />
              <View style={[styles.messageDetail, styles.messageTopBotPdSm]}>
                <View style={[styles.messageDataFull, styles.flexAiFs]}>
                  <View style={[styles.messageData]}>
                    <Text style={styles.messageTitle} allowFontScaling={false}>{this.props.title}</Text>
                  </View>
                  <View style={stylesRes.messageDate}>
                    <Text
                      style={[styles.textPrimeMdBold, styles.textAlignRight]}
                      allowFontScaling={false}
                    >
                      {this.props.date}
                    </Text>
                  </View>
                </View>
                <View style={[styles.messageDataFull]}>
                  <View style={[styles.messageDataDetailSm]}>
                    <Text style={styles.textPrimeSm} allowFontScaling={false}>{this.props.content}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View
            style={[
              styles.selectionBlockSm,
              styles.messageTopBotPdSm,
              styles.selectionPdRt
            ]}
          >
            <View style={styles.iconListSec}>
              <View
                style={[
                  styles.selectSecIcon,
                  styles.iconSettingSp,
                  styles.settingPd
                ]}
              >
                <Image
                  style={styles.radioIcon}
                  source={
                    this.state.isSelect
                      ? require("../../assets/icons/radio-selected.png")
                      : require("../../assets/icons/radio-default.png")
                  }
                />
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedIssuesObj: state.report.selectedIssuesObj
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addRemoveReportIssue: question =>
      dispatch(actions.addRemoveReportIssue(question))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportIssue);
