import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  imageFilter: null,
  contactFilter: null,
  therapyFilter: {
    filteredData: null,
    popupData: undefined
  },

  symptomFilter: null,
  metricFilter: null,
  graphFilter: null,
  reportFilter: null,
  routineFilter: null,
};
const setImageFilter = (state, action) => {
  return updateObject(state, { imageFilter: action.imageFilter });
};

const clearImageFilter = state => {
  return updateObject(state, { imageFilter: null });
};

const setContactFilter = (state, action) => {
  return updateObject(state, { contactFilter: action.contactFilter });
};

const clearContactFilter = state => {
  return updateObject(state, { 
    contactFilter: {
      searchKeyword: '',
      ticked: state.contactFilter?.ticked,
    },
  });
};


const clearTherapyFilter = state => {
  return updateObject(state, {
    therapyFilter: {
      filteredData: null,
      popupData: {
        keyword: '',
        isChecked: state.therapyFilter.popupData?.isChecked,
      },
    }
  });
};

const setTherapyPopup = (state, action) => {
  return updateObject(state, {
    therapyFilter: {
      ...state.therapyFilter,
      popupData: action.popupData
    }
  });
};

const setSymptomFilter = (state, action) => {
  return updateObject(state, { symptomFilter: action.symptomFilter });
};

const clearSymptomFilter = state => {
  return updateObject(state, { symptomFilter: null });
};

const setMetricFilter = (state, action) => {
  return updateObject(state, { metricFilter: action.metricFilter });
};

const clearMetricFilter = state => {
  return updateObject(state, { metricFilter: null });
};

const setGraphFilter = (state, action) => {
  return updateObject(state, { graphFilter: action.graphFilter });
};

const clearGraphFilter = state => {
  return updateObject(state, { graphFilter: null });
};

const setReportFilter = (state, action) => {
  return updateObject(state, { reportFilter: action.reportFilter });
};

const clearReportFilter = state => {
  return updateObject(state, { reportFilter: null });
};

const setRoutineFilter = (state, action) => {
  return updateObject(state, { routineFilter: action.routineFilter });
};

const clearRoutineFilter = state => {
  return updateObject(state, { routineFilter: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_IMAGE_FILTER:
      return setImageFilter(state, action);
    case actionTypes.CLEAR_IMAGE_FILTER:
      return clearImageFilter(state);
    case actionTypes.ADD_CONTACT_FILTER:
      return setContactFilter(state, action);
    case actionTypes.CLEAR_CONTACT_FILTER:
      return clearContactFilter(state);
    case actionTypes.CLEAR_THERAPY_FILTER:
      return clearTherapyFilter(state);
    case actionTypes.SET_THERAPY_POPUP:
      return setTherapyPopup(state, action);
    case actionTypes.AUTH_LOGOUT:
      return clearTherapyFilter(state);
    case actionTypes.ADD_SYMPTOM_FILTER:
      return setSymptomFilter(state, action);
    case actionTypes.CLEAR_SYMPTOM_FILTER:
      return clearSymptomFilter(state);
    case actionTypes.ADD_METRIC_FILTER:
      return setMetricFilter(state, action);
    case actionTypes.CLEAR_METRIC_FILTER:
      return clearMetricFilter(state);
    case actionTypes.ADD_GRAPH_FILTER:
      return setGraphFilter(state, action);
    case actionTypes.CLEAR_GRAPH_FILTER:
      return clearGraphFilter(state);
    case actionTypes.ADD_REPORT_FILTER:
      return setReportFilter(state, action);
    case actionTypes.CLEAR_REPORT_FILTER:
      return clearReportFilter(state);
    case actionTypes.ADD_ROUTINE_FILTER:
      return setRoutineFilter(state, action);
    case actionTypes.CLEAR_ROUTINE_FILTER:
      return clearRoutineFilter(state);
    default:
      return state;
  }
};

export default reducer;
