import React from 'react';
import {View, Image, Text} from 'react-native';
import styles from '../../Css';
import SingleButton from '../../components/Button/singleButton';
import { TAB_BREAk_POINT, TAB_BREAk_POINT_START } from '../../util/platformWindowConfig';

const collapseDetailDescription = props => {
  return (
    <View
      style={[
        styles.collapseContArea,
        props.windowWidth > TAB_BREAk_POINT_START
          ? styles.collapseContVerical
          : props.windowWidth < TAB_BREAk_POINT && props.lastChild
          ? styles.collapseContHorizontalLast
          : styles.collapseContHorizontal,
      ]}>
      <View style={[styles.collapseContRow]}>
        <View
          style={[
            styles.collapseContLg,
            props.windowWidth > TAB_BREAk_POINT_START
              ? styles.collapseContLgPd
              : styles.collapseContNoPd,
            styles.flexJcSb,
          ]}>
          <View>
            <View style={styles.collapseContTitle}>
              <Text style={styles.textPrimeBold}  allowFontScaling={false}>{props.itemTitle}</Text>
            </View>
            <View style={styles.collapseContView}>{props.children}</View>
          </View>
          {/* {props.windowWidth < 992 && (
            <View style={[styles.collapseContBtn]}>
              <View
                style={[styles.btnBlockLeft, styles.comMgTop, styles.comMgBot]}>
                <SingleButton
                  btnStyle={[styles.btnLinkCom]}
                  noBtnShadow={true}
                  btnText={'Add'}
                  touchableOnPress={props.onPressSetupNow}
                />
              </View>
            </View>
          )} */}
        </View>
        <View style={[styles.collapseContSm]}>
        {/* {props.windowWidth > 991 && ( */}
            <View style={[styles.collapseContBtn, styles.flexAiCt]}>
              <View style={[styles.btnStretch]}>
                <SingleButton
                  btnStyle={[styles.btnLinkCom, styles.smAddBtn]}
                  noBtnShadow={true}
                  btnText={'Add'}
                  touchableOnPress={props.onPressSetupNow}
                />
              </View>
            </View>
          {/* )} */}
          <View style={[styles.collapseImg, styles.collapseImgPd]}>
            <Image style={styles.setupIcon} source={props.imgSource} />
          </View>
        </View>
      </View>
    </View>
  );
};

export default (collapseDetailDescription);
