import React from "react";
import { Text } from "react-native";
import styles from "../../Css";

const helpMeasurement = () => {
  return (
    <Text style={styles.textPrime}  allowFontScaling={false}>
        The SI system is the standard except in USA. For convienence, Imperial measurements for weight and height are included with the SI option.
    </Text>
  );
};

export default helpMeasurement;
