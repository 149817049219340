import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import MainSectionComponent from '../../../components/PeerToPeer/MainSection/MainSectionComponent';
import {PEER_TO_PEER, DASHBOARD} from '../../../navigation/path';
import * as axiosCommon from '../../../axios/axios-common';
import {
  GET_PROFILE_CHANGES_STATUS,
  CHANGE_PROFILE_EDIT_STATUS,
} from '../../../store/actions/urls';
import {getUnseenRequestCount} from '../../../util/commonUiLogic';
import {handleCommonBackNavigation} from '../../../navigation/RootNavigation';
import { peerToPeerTabs, peerToPeerTabPath } from '../../../Constant';
class MainSectionScreen extends Component {
  constructor(props) {
    super(props);
    const {activeTab, windowHeight, windowWidth, myNetwork} = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      activeTab: activeTab,
      editedProfile: false,
      unSeenRequestCount: getUnseenRequestCount(myNetwork?.received),
    };
    this.profileChangesRef = null;
    this.myNetworkRef = null;
    this.chatsRef = null;
    this.conversationPaginator = React.createRef();
  }

  componentDidMount() {
    const {
      journalUserId,
      navigation,
      peerProfile,
      getRef,
      myNetwork,
    } = this.props;
    if (journalUserId) {
      navigation.push(DASHBOARD.path);
    }
    if (
      this.props.route?.params &&
      this.props.route?.params.from &&
      this.props.route?.params.from === PEER_TO_PEER.peerResultsByConditionPath
    ) {
      this.unchangeProfile();
    }      
    axiosCommon.axiosGET(GET_PROFILE_CHANGES_STATUS, res => {
      if (res && res.responseDto && res.responseDto.data.masterProfileUpdated) {
        this.setState({
          editedProfile: res.responseDto.data.masterProfileUpdated,
        });
      }
    });
    getRef && getRef(this);
    if (peerProfile && peerProfile.id) {
      this.setState({
        myConnections:
          myNetwork.connected && myNetwork.connected.length > 0
            ? myNetwork.connected
            : [],
      });
    }
    this.props.navigation.addListener('focus', () => {
      if (this.props.activeTab === peerToPeerTabs.profile) {
        this.props.hideMobileMenu();
      } else {
        this.props.showMobileMenu();
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth, activeTab, myNetwork, showMobileMenu} = props;
    const unSeenRequestCount = getUnseenRequestCount(myNetwork?.received);
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth ||
      state.activeTab !== activeTab ||
      state.unSeenRequestCount !== unSeenRequestCount ||
      myNetwork
    ) {
      if (state.activeTab === peerToPeerTabs.profile && activeTab === peerToPeerTabs.myNetwork) {
        showMobileMenu();
      }
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        activeTab: activeTab,
        unSeenRequestCount: unSeenRequestCount,
        myConnections:
          myNetwork.connected && myNetwork.connected.length > 0
            ? myNetwork.connected
            : [],
      };
    }

    return null;
  }

  handleOnPressBackButton = () => {
    if (this.props.route?.params &&
      this.props.route?.params.from &&
      this.props.route?.params.from === PEER_TO_PEER.findPeersPath
    ) {
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: DASHBOARD.path }],
      });
    } else {
      handleCommonBackNavigation(this.props.navigation); 
    }
  };

  unchangeProfile = () => {
    axiosCommon.axiosGET(CHANGE_PROFILE_EDIT_STATUS, res => {
      if (res && res.responseDto && res.responseDto.data) {
        this.setState({
          editedProfile: res.responseDto.data.masterProfileUpdated,
        });
      }
    });
  };

  handleTabNavigate = index => {
    if (index === 2 && this.state.editedProfile) {
      this.setState({editedProfile: false});
    }
    this.props.OnSetPeerToPeerActiveTab(index);
    switch(index) {
      case peerToPeerTabs.chats:
        this.props.navigation.setParams({communityTab : peerToPeerTabPath.chats});
        break;
      case peerToPeerTabs.myNetwork:
        this.props.navigation.setParams({communityTab : peerToPeerTabPath.myNetwork});
        break;
      case peerToPeerTabs.profile:
        this.props.navigation.setParams({communityTab : peerToPeerTabPath.profile});
        break;
      default:
        this.props.navigation.setParams({communityTab : peerToPeerTabPath.myNetwork});
        break;
    }
  };

  render() {
    const {
      navigation,
      hasNotch,
      windowHeight,
      journalUserId,
      searchedKeyword,
      newMessageCount,
      unSeenConversations,
      windowWidth,
      p2pProfileHasDataToSave,
      route,
      timeZone,
      timeZoneWarning
    } = this.props;
    const {activeTab, editedProfile, unSeenRequestCount} = this.state;
    return (
      <MainSectionComponent
        handleOnPressBackButton={this.handleOnPressBackButton}
        index={PEER_TO_PEER.index}
        navigation={navigation}
        route={route}
        activeTab={activeTab}
        profileChangesRef={this.profileChangesRef}
        tabIndex={this.handleTabNavigate}
        hasNotch={hasNotch}
        windowHeight={windowHeight}
        journalUserId={journalUserId}
        myNetworkRef={this.myNetworkRef}
        searchedKeyword={searchedKeyword}
        editedProfile={editedProfile}
        unSeenRequestCount={unSeenRequestCount}
        newMessageCount={newMessageCount}
        unSeenConversations={unSeenConversations}
        chatsRef={this.chatsRef}
        windowWidth={windowWidth}
        p2pProfileHasDataToSave={p2pProfileHasDataToSave}
        timeZone={timeZone}
        timeZoneWarning={timeZoneWarning}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
    peerProfile: state.routines.peer2peer,
    activeTab: state.peerToPeer.activeTab,
    hasNotch: state.uistate.hasNotch,
    searchedKeyword: state.peerToPeer.searchedKeyword,
    newMessageCount: state.peerToPeer.newMessageCount,
    unSeenConversations: state.peerToPeer.unSeenConversations,
    myNetwork: state.routines.myNetwork,
    p2pProfileHasDataToSave: state.peerToPeer.p2pProfileHasDataToSave,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    OnSetPeerToPeerActiveTab: activeTabIndex =>
      dispatch(actions.setPeerToPeerActiveTab(activeTabIndex)),
    onGetToken: (callBack, userId) =>
      dispatch(actions.getToken(callBack, userId)),
    setNewMessageCount: count => dispatch(actions.setNewMessageCount(count)),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainSectionScreen);
