import React from "react";
import { View, Image, Text } from "react-native";
import styles from "../../Css";

const noJournalFound = (props) => {
  return (
    <React.Fragment>
      {props.resultLgScreen ? (
        <View style={[styles.searchEmptyResult, styles.flexJcCtAiCt]}>
          <View style={[styles.resultIconView]}>
            <Image
              style={styles.noJournalSm}
              source={require("../../assets/icons/no-journal.png")}
            />
          </View>
          <View style={[styles.resultContentView]}>
            <View style={[styles.resultContentDesc]}>
              <Text
                style={[
                  styles.textPrimeSm,
                  styles.textGreyLight,
                  styles.textAlignCenter,
                ]}
                allowFontScaling={false}
              >
                No journals to display
              </Text>
            </View>
          </View>
        </View>
      ) : (
        <View style={[
          props.screenLg
          ? [{minHeight: props.resultHeight}, styles.colBgLight]
          : [styles.searchEmptyResultLg, styles.searchEmptyPdTop],
          styles.searchEmptyPd,
          styles.flexJcCtAiCt]}>
          <View style={[styles.resultIconView]}>
            <Image
              style={styles.noJournalLg}
              source={require("../../assets/icons/no-journal-default.png")}
            />
          </View>
          <View style={[styles.resultContentView]}>
            <View style={[styles.resultContentTitle]}>
              <Text style={[styles.textPrimeBold, styles.textAlignCenter]} allowFontScaling={false}>
                Create and manage your Journals
              </Text>
            </View>
            <View style={[styles.resultContentDesc]}>
              <Text
                style={[
                  styles.textPrimeSm,
                  styles.textGreyLight,
                  styles.textAlignCenter,
                ]}
                allowFontScaling={false}
              >
                Track and manage journals {"\n"}and share them with your family
              </Text>
            </View>
          </View>
        </View>
      )}
    </React.Fragment>
  );
};

export default noJournalFound;
