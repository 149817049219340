import React from "react";
import { View, TextInput, Text } from "react-native";
import styles from "../../Css";
import FieldTitle from "./fieldTitle";
import TextFieldToolTip from "./textFieldToolTip";
import * as FontScaling from '../../components/Text/fontScaling';
import WarningInline from '../../components/Warning/warningInline';
const textFrame = (props) => {
  return (
    <View style={[styles.textInputWrapper, styles.postRelative, props.activitySlider && styles.pickerWidth]}>
      <FieldTitle
        fieldAlign={props.fieldAlign}
        fieldBg={props.fieldBg}
        title={props.title}
        fieldLt={props.fieldLt}
        darkTxt={props.darkTxt}
      />
      <View
        style={[
          props.fieldLg ? styles.textInputFieldLg : styles.textInputField,
          props.fieldInfo ? styles.frameInfoPd : styles.framePd,
          props.fieldBg === "grey"
            ? styles.bgThinGrey
            : props.fieldBg === "blue"
              ? styles.bgBlueExLight
              : props.fieldBg === "transparent"
                ? styles.bgTransparent
                : props.fieldBg === "red"
                 ? [styles.inValidColor, styles.bgWhite]
                  : styles.bgWhite,
        ]}
      >
        <View style={[props.fleldUnique ? styles.dataWrapperLg : styles.dataWrapper, styles.flexJcCt]}>
        {props.editable ?
          <TextInput
            style={[
              styles.textPrimeMd,
              props.fieldAlign === "center"
                ? styles.textAlignCenter
                : styles.textAlignLeft,
            ]}
            maxFontSizeMultiplier = {FontScaling.fontScaleField}
            numberOfLines={props.noOfLines}
            onChangeText={(e) => props.onChangeText(e)}
            value={props.fieldValue}
            editable={props.editable}
          />
          : <Text 
              style={[
                styles.textPrimeMd,
                props.fieldAlign === "center"
                  ? styles.textAlignCenter
                  : styles.textAlignLeft,
              ]}
              allowFontScaling={false}
              numberOfLines={props.noOfLines}>
              {props.fieldValue}
            </Text>
        }
        </View>
      </View>

      {Boolean(props.fieldInfo) && (
        <TextFieldToolTip renderComponent={props.renderComponent} />
      )}

      {props.fieldBg === "red" && (
        <WarningInline
          errorDescription={'Name can not be empty.'}
          textPosition={props.textPosition || 'right'}
        />
      )}
    </View>
  );
};

export default textFrame;
