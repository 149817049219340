import React, { Component } from 'react';
import { View } from 'react-native';
import styles from '../../../Css';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import Graph from './Graph';
import GraphNameDropDown from './graphNameDropDown';
import { getAccountType } from '../../../util/subscriptionInformation';
import { freeUserMaximumGraphsAllow } from '../../../Constant';
import { pendoTrackEvent } from '../../../util/pendoConfig';

class GraphSection extends Component {
  constructor(props) {
    super(props);
    const accountType = getAccountType(props.userSubscriptions);
    const {
      windowHeight,
      windowWidth,
      visibleGraph,
      applicationSettingsDigest,
    } = props;
    const { isSetupOn } = applicationSettingsDigest;

    this.state = {
      graphId: null,
      graphName: '',
      therapies: [],
      metrics: [],
      symptoms: [],
      startDate: null,
      endDate: null,
      showGraphList: false,
      visibleGraph: visibleGraph ? visibleGraph : null,
      anchorEl: null,
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      accountType: accountType,
      therapyProps: {},
      symptomProps: {},
      metricProps: {},
      isSetupOn: isSetupOn,
    };
  }

  componentDidMount() {
    // this.props.navigation.addListener('focus', () => {
    const { selectedGraphId } = this.props;
    let activeGraphs = this.getFilteredGraphs();

    if (activeGraphs.length) {
      //display selected graph if graph selected
      if (selectedGraphId && activeGraphs.find(g => g.id === selectedGraphId)) {
        let selectedGrapObj = activeGraphs.find(g => g.id === selectedGraphId);
        this.viewGraph(selectedGrapObj.id, selectedGrapObj.name);
      }
      else {
        //auto select graph if only 1 graph is avaliable or graph not selected
        let graph = activeGraphs[0];
        this.viewGraph(graph.id, graph.name);
      }
    }
    // });
  }

  getFilteredGraphs = () => {
    const { dashboard, journalEntryTypesProcessed } = this.props;
    let activeGraphs = [];
    activeGraphs = dashboard
      .filter(graph => graph.status === 'ENABLED' &&
        (!graph.entryTypeId ||
          (journalEntryTypesProcessed[graph.entryTypeId] &&
            journalEntryTypesProcessed[graph.entryTypeId].showOnDashboard))
      )
      .sort((a, b) => b.createdOn - a.createdOn);

    return activeGraphs;
  }

  static getDerivedStateFromProps(props, state) {
    const { visibleGraph = false, windowHeight, windowWidth, selectedGraphId } = props;

    if (
      visibleGraph !== state.visibleGraph ||
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth ||
      selectedGraphId !== state.graphId
    ) {
      return {
        visibleGraph: visibleGraph,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        graphId: selectedGraphId,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.graphId !== this.state.graphId && prevState.graphId !== null) {
      const filteredGraphs = this.getFilteredGraphs();
      const selectedGrapObj = filteredGraphs &&
        filteredGraphs.length > 0 &&
        filteredGraphs.find(g => g.id === this.state.graphId);
      if (selectedGrapObj && !this.state.isFromDropDown) {
        this.viewGraph(selectedGrapObj.id, selectedGrapObj.name);
      }
    }
  }
  viewGraph = (id, name, isFromDropDown) => {
    this.setState(
      {
        graphId: id,
        graphName: name,
        showGraphList: false,
        isFromDropDown: isFromDropDown
      },
      () => {
        const graph = this.props.dashboardProcessed[id];
        const treatments = graph.treatments ? graph.treatments.split(',') : [];
        const metrics = graph.metrics ? graph.metrics.split(',') : [];
        const symptoms = graph.symptoms ? graph.symptoms.split(',') : [];
        const treatmentsObj = {};
        const metricsObj = {};
        const symptomsObj = {};
        treatments.forEach(element => {
          treatmentsObj[element] = element;
        });
        metrics.forEach(element => {
          metricsObj[element] = element;
        });
        symptoms.forEach(element => {
          symptomsObj[element] = element;
        });

        const treatmentsProperties = graph.treatmentsProperties ? JSON.parse(graph.treatmentsProperties) : [];
        const symptomsProperties = graph.symptomsProperties ? JSON.parse(graph.symptomsProperties) : [];
        const metricsProperties = graph.metricsProperties ? JSON.parse(graph.metricsProperties) : [];
        const therapyPropsObj = {};
        const symptomPropsObj = {};
        const metricPropsObj = {};
        Object.values(treatmentsProperties).forEach(element => {
          therapyPropsObj[element.id] = element
        });
        Object.values(symptomsProperties).forEach(element => {
          symptomPropsObj[element.id] = element
        });
        Object.values(metricsProperties).forEach(element => {
          metricPropsObj[element.id] = element
        });

        const found = this.getFilteredGraphs().find(g => g.id === this.props.previousGraphId);
        if (
          !this.checkGraphData(symptomsObj, metricsObj, treatmentsObj) &&
          this.props.previousGraphId &&
          found &&
          !isFromDropDown
        ) {
          this.props.setPreviousGraphId(null);
          return this.viewGraph(found.id, found.name);
        }
        this.props.setPreviousGraphId(null);
        this.setState({
          therapies: treatmentsObj,
          metrics: metricsObj,
          symptoms: symptomsObj,
          startDate: graph.startDate,
          endDate: graph.endDate,
          therapyProps: therapyPropsObj,
          symptomProps: symptomPropsObj,
          metricProps: metricPropsObj
        });
        this.props.onSelectGraphItem(id);
        // Not sticky
        // if(isFromDropDown) {
        //   this.props.onHideShowGraph(true);
        // } else {
        //   this.props.onHideShowGraph(this.checkGraphData(symptomsObj, metricsObj, treatmentsObj));
        // }
        if (isFromDropDown) {
          this.props.onHideShowGraph(true);
        } else if (!this.props.graphChecked) {
          this.props.onHideShowGraph(this.checkGraphData(symptomsObj, metricsObj, treatmentsObj));
          this.props.setGraphChecked(true);
        }
      },
    );
  };

  checkGraphData = (symptoms, metrics, therapies) => {
    let foundData = false;

    let firstDate = new Date();
    firstDate.setDate(firstDate.getDate() - 14);
    firstDate.setHours(0, 0, 0, 0);

    const journalEntries = Object.values(this.props.journalEntriesProcessed);

    //symptoms
    let symptomsArray = [];
    if (symptoms) {
      symptomsArray = Object.values(symptoms);
    }
    if (
      symptomsArray &&
      symptomsArray.length > 0 &&
      journalEntries.length > 0
    ) {
      const journalEntriesFilterd = journalEntries.filter(
        (item) =>
          item.symptoms &&
          item.symptoms.length > 0 &&
          item.entryDate > firstDate.getTime()
      );
      journalEntriesFilterd.forEach((item) => {
        let filterSymptoms = item.symptoms;

        filterSymptoms = filterSymptoms.filter((item) => item.severity || item.severity === 0);

        filterSymptoms.forEach((symptomItem) => {
          if (symptoms[symptomItem.symptom]) {
            foundData = true;
          }
        });
      });
    }

    //metrics
    let metricsArray = [];
    if (metrics) {
      metricsArray = Object.values(metrics);
    }
    if (
      metricsArray &&
      metricsArray.length > 0 &&
      journalEntries.length > 0
    ) {
      const journalEntriesFilterd = journalEntries.filter(
        (item) =>
          item.metrics &&
          item.metrics.length > 0 &&
          item.entryDate > firstDate.getTime()
      );
      journalEntriesFilterd.forEach((item) => {
        let filterMetrics = item.metrics;

        filterMetrics = filterMetrics.filter((item) => item.value || item.value === 0);

        filterMetrics.forEach((metricItem) => {
          if (metrics[metricItem.metric]) {
            foundData = true;
          }
        });
      });
    }

    //doseges
    let therapiesArray = [];
    if (therapies) {
      therapiesArray = Object.values(therapies);
    }
    if (
      therapiesArray &&
      therapiesArray.length > 0 &&
      journalEntries.length > 0
    ) {
      const journalEntriesFilterd = journalEntries.filter(
        (item) =>
          item.dosages &&
          item.dosages.length > 0 &&
          item.entryDate > firstDate.getTime()
      );
      journalEntriesFilterd.forEach((item) => {
        let dosages = item.dosages;

        dosages = dosages.filter((item) => item.quantity || item.quantity === 0);

        dosages.forEach((dosagesItem) => {
          if (therapies[dosagesItem.treatment]) {
            foundData = true;
          }
        });
      });
    }
    return foundData;
  }

  render() {
    const {
      visibleGraph,
      graphName,
      showGraphList,
      graphId,
      anchorEl,
      accountType,
    } = this.state;

    const {
      redirectPremiumPage,
      dashboard,
      windowWidth,
    } = this.props;

    let enableGraphFilter = [];
    let enableGraphCount = 0;
    if (dashboard) {
      enableGraphFilter = dashboard.filter(graph => graph.status === 'ENABLED');
      if (enableGraphFilter && enableGraphFilter.length > 0) {
        enableGraphCount = enableGraphFilter.length;
      }

      enableGraphFilter = this.getFilteredGraphs();
    }
    return (
      <>
        {enableGraphCount > 0 &&
          <View
            style={[
              styles.graphSectionView,
              styles.infoPdBotSm,
              styles.highZIndex,
            ]}>
            <View style={[styles.graphViewWrapper, styles.bgWhite]} nativeID={'dashboardGraphView'}>
              <View style={[styles.titleSecMain]}>
                <GraphNameDropDown
                  name={graphName}
                  onPressDropDown={e => {
                    let anchorEl = null;
                    if (!showGraphList) {
                      e.preventDefault();
                      anchorEl = e.currentTarget;
                      this.setState({
                        anchorEl: anchorEl,
                        showGraphList: true
                      });
                      pendoTrackEvent('Dashboard@ > GraphDrop');
                    }
                  }}
                  handleClose={() => {
                    this.setState({ anchorEl: null, showGraphList: false });
                  }}
                  showGraphList={showGraphList}
                  dashboard={enableGraphFilter}
                  graphId={graphId}
                  viewGraph={this.viewGraph}
                  anchorEl={anchorEl}
                  windowWidth={windowWidth}
                  // ref={ref => (this.touchable = ref)}
                  // touchable={this.touchable}
                  onPressCreateNewGraph={() => {
                    if (
                      accountType === 'regular' &&
                      enableGraphCount >= freeUserMaximumGraphsAllow
                    ) {
                      this.setState({
                        showGraphList: false,
                      }, () => {
                        redirectPremiumPage();
                      });
                      pendoTrackEvent('Dashboard@ > GraphDrop > Premium');
                    } else {
                      this.setState({
                        showGraphList: false,
                      }, () => {
                        setTimeout(() => {
                          this.props.setFromPopup('GRAPH_ROUTINE');
                        }, 500)
                      });
                      pendoTrackEvent('Dashboard@ > GraphDrop > CreateNew');
                    }
                  }}
                  displayPremiumCrown={
                    accountType === 'regular' &&
                    enableGraphCount >= freeUserMaximumGraphsAllow
                  }
                />
              </View>
              {Boolean(visibleGraph) && (
                <Graph
                  metrics={this.state.metrics}
                  therapies={this.state.therapies}
                  symptoms={this.state.symptoms}
                  startDate={null}
                  endDate={null}
                  symptomProps={this.state.symptomProps}
                  metricProps={this.state.metricProps}
                  therapyProps={this.state.therapyProps}
                  graphId={graphId}
                  navigation={this.props.navigation}
                  graphDivId={"chartdiv_dashboard"}
                  enableZoom={true}
                  isFromDashboardGraph={true}
                />
              )}
            </View>
          </View>
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    dashboard: state.routines.dashboard,
    dashboardProcessed: state.routines.dashboardProcessed,
    visibleGraph: state.rollinggraphs.dashboardGraph,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    selectedGraphId: state.uistate.selectedGraphId,
    userSubscriptions: state.routines.userSubscription,
    journalUserId: state.uistate.journalUserId,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    previousGraphId: state.uistate.previousGraphId,
    graphChecked: state.uistate.graphChecked,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onHideShowGraph: value => dispatch(actions.onHideShowGraph(value)),
    onSelectGraphItem: graphId => dispatch(actions.selectGraphItem(graphId)),
    setFromPopup: (fromPopup) => dispatch(actions.setFromPopup(fromPopup)),
    setPreviousGraphId: graphId => dispatch(actions.setPreviousGraphId(graphId)),
    setGraphChecked: (graphChecked) => dispatch(actions.setGraphChecked(graphChecked)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GraphSection);
