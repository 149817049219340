import React from 'react';
import { View, Platform } from 'react-native';
import { connect } from 'react-redux';
import moment from 'moment';
import Header from '../../components/Header/header';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import SelectSaveButton from '../../components/AttachToOption/selectSaveButton';
import RutineItemScrollView from '../Questions/RutineItemScrollView';
import PageContainer from "../../components/PageLayout/PageContainer";
import SuccessBox from '../../components/ConfirmationBox/successBox';
import UploadProgress from '../../components/ImagesDocumentsForAttachment/uploadProgress';
import CancelUploadConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import AttachToOptionCommon from './AttachToOptionCommon';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import * as actions from '../../store/actions/index';
import { IMAGES_DOCUMENTS } from '../../navigation/path';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { WEB } from '../../util/platformWindowConfig';

class AttachToJournal extends AttachToOptionCommon {
  state = {
    journalEntries: null,
    journalEntryTypes: null,
    filterJournalEntries: null,
    filterOn: false,
    saveWait: false,
    journalEntriesAdd: null,
    successModalVisible: false,
  };

  componentDidMount = () => {
    this.props.navigation.addListener('focus', () => {
      this.handleOnPressClearFilters();
      const allJournal = this.getJournal(
        this.props.journalEntriesProcessed,
        this.props.journalEntryTypesProcessed,
      );
      allJournal.sort((a, b) => {
        return b.entryDate - a.entryDate;
      });
      this.setState({
        journalEntries: allJournal,
        journalEntryTypes: this.props.journalEntryTypes,

        filterJournalEntries: allJournal,

        journalEntriesAdd: this.props.journalEntriesAdd,
      });

      this.props.hideMobileMenu();
      pendoTrackEvent('Attachments/@AttachToJournal');
    });
    this.props.navigation.addListener('blur', () => {
      if (Platform.OS !== WEB) {
        this.setState({ confirmBox: false, saveWait: false });
      }
      this.componentWillUnmount();
    });
  };

  componentWillUnmount = () => {
    // Clear Filter
    this.props.removeAllJournal();
    this.props.clearJournalEntryFilter();
    this.handleOnPressClearFilters();
    this.props.showMobileMenu();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      journalEntriesProcessed,
      journalEntryTypes,

      journalEntryFilter,
      journalEntriesAdd,
    } = nextProps;

    const allJournal = this.getJournal(
      journalEntriesProcessed,
      this.props.journalEntryTypesProcessed,
    );
    allJournal.sort((a, b) => {
      return b.entryDate - a.entryDate;
    });
    if (journalEntryFilter &&
      Object.keys(journalEntryFilter).length > 2
    ) {
      this.setState({
        journalEntries: allJournal,
        journalEntryTypes,
        filterOn: this.props.journalEntryFilter ? true : this.state.filterOn,
        journalEntriesAdd,
      },
      () => {
        this.handleFilter(journalEntryFilter);
      },);
    } else {
      this.setState({
        journalEntries: allJournal,
        journalEntryTypes,
        filterJournalEntries: allJournal,
        journalEntriesAdd,
      });
    }
  }

  getJournal = (journalEntriesProcessed, journalEntryTypesProcessed) => {
    const journalEntries = Object.values(journalEntriesProcessed);
    return journalEntries.filter(function(journalEntrie) {
      return (
        journalEntryTypesProcessed[journalEntrie.entryType] &&
        !journalEntryTypesProcessed[journalEntrie.entryType].isAppointment
      );
    });
  };

  filterJournalEntriesByDate = (date, journalEntries) => {
    if (journalEntries) {
      return journalEntries.filter(function(journalEntries) {
        return (
          new moment(journalEntries.entryDate).format('YYYY-MM-DD') ===
          moment(date).format('YYYY-MM-DD')
        );
      });
    }
  };

  handleOnPressClearFilters = () => {
    this.setState({
      filterOn: false,
      filterJournalEntries: this.state.journalEntries,
    });
    this.props.clearJournalEntryFilter();
  };

  filterJournalEntriesBySearchKeyword = (journalEntries, searchKeyword) => {
    if (journalEntries) {
      return journalEntries.filter(function(journalEntrie) {
        return journalEntrie.title.toLowerCase().includes(searchKeyword.toLowerCase());
      });
    }
  };

  filterJournalEntriesByRoutineType = (journalEntries, routineType) => {
    if (journalEntries) {
      return journalEntries.filter(function(journalEntrie) {
        return journalEntrie.entryType === parseInt(routineType);
      });
    }
  };

  filterJournalEntriesByViewBy = (journalEntries, startDate, endDate) => {
    if (journalEntries) {
      return journalEntries.filter(function(journalEntrie) {
        return (
          journalEntrie.entryDate < startDate.getTime() &&
          journalEntrie.entryDate > endDate.getTime()
        );
      });
    }
  };

  filterJournalEntriesByStartDate = (journalEntries, startDate) => {
    const toDay = new Date();
    toDay.setHours(23, 59, 59, 999);
    if (journalEntries) {
      if (startDate.getTime() < toDay.getTime()) {
        return journalEntries.filter(function(journalEntrie) {
          return (
            journalEntrie.entryDate >= startDate.getTime() &&
            journalEntrie.entryDate <= toDay.getTime()
          );
        });
      } else {
        return journalEntries.filter(function(journalEntrie) {
          return journalEntrie.entryDate >= startDate.getTime();
        });
      }
    }
  };

  filterJournalEntriesByEndDate = (journalEntries, endDate) => {
    const toDay = new Date();
    toDay.setHours(0, 0, 0, 0);
    if (journalEntries) {
      if (endDate.getTime() < toDay.getTime()) {
        return journalEntries.filter(function(journalEntrie) {
          return journalEntrie.entryDate <= endDate.getTime();
        });
      } else {
        return journalEntries.filter(function(journalEntrie) {
          return (
            journalEntrie.entryDate <= endDate.getTime() &&
            journalEntrie.entryDate >= toDay.getTime()
          );
        });
      }
    }
  };

  handleFilter = filter => {
    let filterJournalEntries = [];

    if (
      filter.searchKeyword &&
      filter.searchKeyword !== '' &&
      this.state.journalEntries
    ) {
      filterJournalEntries = this.filterJournalEntriesBySearchKeyword(
        this.state.journalEntries,
        filter.searchKeyword,
      );
    } else if (this.state.journalEntries) {
      filterJournalEntries = this.state.journalEntries;
    }

    if (filter.routineType !== 0) {
      filterJournalEntries = this.filterJournalEntriesByRoutineType(
        filterJournalEntries,
        filter.routineType,
      );
    }
    if (filter.viewBy === 1) {
      const startDate = new Date();
      const endDate = new Date();
      startDate.setHours(23, 59, 59, 59);
      endDate.setDate(endDate.getDate() - 30);
      endDate.setHours(0, 0, 0, 0);
      filterJournalEntries = this.filterJournalEntriesByViewBy(
        filterJournalEntries,
        startDate,
        endDate,
      );
    } else if (filter.viewBy === 2) {
      const startDate = new Date();
      const endDate = new Date();
      startDate.setHours(23, 59, 59, 59);
      endDate.setDate(endDate.getDate() - 90);
      endDate.setHours(0, 0, 0, 0);
      filterJournalEntries = this.filterJournalEntriesByViewBy(
        filterJournalEntries,
        startDate,
        endDate,
      );
    } else if (filter.viewBy === 3 && filter.startDate && filter.endDate) {
      const startDate = new Date(filter.startDate.getTime());
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(filter.endDate.getTime());
      endDate.setHours(23, 59, 59, 59);

      filterJournalEntries = this.filterJournalEntriesByViewBy(
        filterJournalEntries,
        endDate,
        startDate,
      );
    } else if (filter.viewBy === 3 && filter.startDate) {
      const startDate = new Date(filter.startDate.getTime());
      startDate.setHours(0, 0, 0, 0);

      filterJournalEntries = this.filterJournalEntriesByStartDate(
        filterJournalEntries,
        startDate,
      );
    } else if (filter.viewBy === 3 && filter.endDate) {
      const endDate = new Date(filter.endDate.getTime());
      endDate.setHours(23, 59, 59, 59);
      filterJournalEntries = this.filterJournalEntriesByEndDate(
        filterJournalEntries,
        endDate,
      );
    }
    if (filter.sortBy && filter.sortBy === 1) {
      filterJournalEntries.sort(function(a, b) {
        const aval = a.flagged ? 1 : 0;
        const bval = b.flagged ? 1 : 0;
        return bval - aval;
      });
    } else if (filter.sortBy && filter.sortBy === 2) {
      filterJournalEntries.sort(function(a, b) {
        return b.entryDate - a.entryDate;
      });
    } else if (filter.sortBy && filter.sortBy === 3) {
      filterJournalEntries.sort(function(a, b) {
        return a.entryDate - b.entryDate;
      });
    }

    this.setState({
      filterJournalEntries: filterJournalEntries,
    });
  };

  journalHasAttachments = artifactId => {
    let attachments = [];
    if (this.props.attachmentsCompleteDigest) {
      attachments = this.props.attachmentsCompleteDigest.userArtifactAttachments.filter(
        function(attachment) {
          return attachment.userArtifact === artifactId;
        },
      );
    }
    return attachments.length > 0;
  };

  handleOnPressBackButton = () => {
    this.props.navigation.push(IMAGES_DOCUMENTS.addImagesDocumentsPath, {
      fromSubPath: true
    });
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={true}
          showBack={true}
          backDetailed=""
          showClearFilters={this.state.filterOn}
          showNotify={false}
          filterClickHandler={this.handleOnPressFilter}
          clearFiltersHandler={this.handleOnPressClearFilters}
          handleOnPressBackButton={this.handleOnPressBackButton}
          handleFilter={this.handleFilter}
          type="journal"
          index={IMAGES_DOCUMENTS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        <View style={[styles.gridSection]}>
          <View style={stylesRes.contentSectionFull}>
            <View style={[styles.contentSection]}>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  {/* Routines List Start Here */}

                  {Boolean(this.state.filterJournalEntries) &&
                    this.state.filterJournalEntries.length !== 0 && (
                      <RutineItemScrollView
                        data={this.state.filterJournalEntries}
                        journalEntryTypesProcessed={
                          this.props.journalEntryTypesProcessed
                        }
                        filterOn={true}
                      />
                    )}
                  {/* Routines List End Here */}
                </View>
              </View>
              <PageContainer>
              {this.state.journalEntriesAdd ? (
                <SelectSaveButton
                  isFromSave={true}
                  saveWait={this.state.saveWait}
                  disabled={this.state.saveWait}
                  onPress={() => this.handleOnSave(this.props.journalEntriesProcessed, this.state.journalEntriesAdd)}
                />
              ) : (
                <SelectSaveButton />
              )}
              </PageContainer>
            </View>
          </View>
        </View>

        {this.state.successModalVisible &&
          <SuccessBox
            visibleModal={this.state.successModalVisible}
            messageTitle="Success!"
            message="Attachments uploaded"
          />
        }
        {Platform.OS !== 'web' && this.state.saveWait &&
          <UploadProgress
            visibleModal={this.state.saveWait}
            progress={this.state.progress}
            onPressCancel={() => {
              this.setState({saveWait: false}, () => {
                setTimeout(() => {
                  this.setState({confirmBox: true});
                }, 500);
              });
            }}
          />
        }
        {Platform.OS !== 'web' && this.state.confirmBox &&
          <CancelUploadConfirmation
            visibleModal={this.state.confirmBox}
            onBackdropPress={() => this.closeCanceConfirmation()}
            onRequestClose={() => this.closeCanceConfirmation()}
            alertTitle={'Cancel Upload?'}
            alertBody={'Your items are still uploading, are you sure you want to cancel?'}
            btnTextOne={'Cancel Upload'}
            btnTextTwo={'Continue Upload'}
            handleCancel={()=> {
              const journalArtifactId = this.state.artifactId;
              this.setState({
                confirmBox: false,
                saveWait: false,
                progress: 0,
                artifactId: null,
              }, () => {
                this.props.cancelUploadAttachmentProcess(
                  journalArtifactId,
                  this.props.journalUserId,
                  res => {
                    this.props.clearFilesToAttach();
                    this.props.navigation.push(IMAGES_DOCUMENTS.path);
                  }
                );
              });
            }}
            handleConfirm={() => this.closeCanceConfirmation()}
          />
        }
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    journalEntryTypes: state.routines.journalEntryTypes,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    // Get Selected Date
    selectedDate: state.routines.selectedDate,
    journalEntryFilter: state.routines.journalEntryFilter,
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
    journalEntriesAdd: state.question.journalEntriesAdd,
    files: state.imagesDocuments.files,
    user: state.routines.user,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpload: (
      artifactId,
      formData,
      callBack,
      reload,
      journalUserId,
      fileSize,
      uploadConfig,
      cancelToken,
      uniqueId,
    ) =>
      dispatch(
        actions.uploadAttachmen(
          artifactId,
          formData,
          callBack, 
          reload,
          journalUserId,
          fileSize,
          uploadConfig,
          cancelToken,
          uniqueId,
        ),
      ),
    removeAllJournal: () => dispatch(actions.removeAllJournal()),
    clearJournalEntryFilter: () => dispatch(actions.clearJournalEntryFilter()),
    clearFilesToAttach: () => dispatch(actions.clearFilesToAttach()),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    setAttachmentUploadStatus: status => dispatch(actions.setAttachmentUploadStatus(status)),
    setAttachmentArtifactId: id => dispatch(actions.setAttachmentArtifactId(id)),
    setAttachmentFullSize: size => dispatch(actions.setAttachmentFullSize(size)),
    showUploadProgress: () => dispatch(actions.showUploadProgress()),
    cancelUploadAttachmentProcess: (artifactId, journalUserId, callback) => 
      dispatch(actions.cancelUploadAttachmentProcess(artifactId, journalUserId, callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttachToJournal);
