import React from 'react';
import {View} from 'react-native';
import styles from '../../Css';
const ColorItemPreview = props => {
  const {setColor} = props;
  return (
    <View
      style={[
        styles.colorItemPreview,
        setColor ? {backgroundColor: setColor} : {backgroundColor: '#fff'},
      ]}
    />
  );
};

export default (ColorItemPreview);
