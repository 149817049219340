import React, {Component} from 'react';
import {Text, View} from 'react-native';
import * as actions from '../../../store/actions/index';
import {connect} from 'react-redux';
import styles from '../../../Css';
import Modal from '../../Modal/modal';
import ModalLayoutSpec from '../../ModalLayout/modalLayoutSpec';
import PrimaryButton from '../../Button/primaryButton';
import * as FieldDetails from '../../TextInput/fieldDetails';
import TextAreaFieldSpec from '../../TextInput/textAreaFieldSpec';

class connectionRequestPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      sendRequestWait: false,
    };
  }

  onChangeMessageText = value => {
    this.setState({
      message: value,
    });
  };

  sendConectionRequest = () => {
    const {
      profile,
      selectedUser,
      onSendConnectionRequest,
      onSetConnectionRequestMessage,
      onUpdateConnectionStatus,
    } = this.props;
    const {message} = this.state;

    this.setState({sendRequestWait: true});
    if (message && message.length > 160) {
      this.setState({sendRequestWait: false});
      return;
    }

    if (profile && profile.profileVisibility === 'PUBLIC') {
      const connectionRequest = {
        peerId: selectedUser.id,
        userName: selectedUser.userName,
        message:
          message && message.trim() !== ''
            ? message
            : 'Would you like to be part of my connections?',
      };
      onSendConnectionRequest(connectionRequest, (success, res) => {
        if (success) {
          this.setState({sendRequestWait: false});
          this.hidePopupMessage(true);
          setTimeout(() => {
            this.props.hidePopup();
          }, 3000);
          onUpdateConnectionStatus(res.requestTo, res.id, res.connectionStatus);
        } else {
          this.setState({sendRequestWait: false});
        }
      });
    } else {
      onSetConnectionRequestMessage(
        message && message.trim() !== ''
          ? message
          : 'Would you like to be part of my connections?',
      );
      this.setState({sendRequestWait: false});
      this.hidePopupMessage(true);
    }
  };

  hidePopupMessage = showNextPopup => {
    this.setState({
      message: '',
      sendRequestWait: false,
    });
    this.props.hidePopup('CONNECTION_REQUEST', showNextPopup);
  };
  render() {
    const {visibleModal, selectedUser} = this.props;
    const {message, sendRequestWait} = this.state;
    return (
      <Modal
        visible={visibleModal}
        onBackdropPress={() => this.hidePopupMessage(false)}>
        <ModalLayoutSpec
          contPosition={'center'}
          showClose={true}
          hidePopup={() => this.hidePopupMessage(false)}>
          <View style={styles.popupContentWrapper}>
            <View style={[styles.popMessageContView, styles.comSp]}>
              <View style={[styles.popMessageTitleView]}>
                <Text style={[styles.textPrime, styles.textAlignCenter]} allowFontScaling= {false}>
                  Connect with{' '}
                  <Text style={styles.bold} allowFontScaling= {false}>
                    {selectedUser && selectedUser.userName}
                  </Text>
                </Text>
              </View>
            </View>
            <View style={styles.modalSectionView}>
              <View style={styles.gridRow}>
                <View style={[styles.fieldRow]}>
                  <View style={styles.fieldRowWrapperFull}>
                    <TextAreaFieldSpec
                      title={'Write a personal message'}
                      fieldLt={true}
                      fieldBg={FieldDetails.BGWHITE}
                      placeholder={'Would you like to be part of my connections?'}
                      //numberOfLines={5}
                      value={message}
                      onChangeText={this.onChangeMessageText}
                      autoFocus={true}
                      fieldStyle={{height: 100}}
                    />
                  </View>
                  <View style={[styles.fieldInfo, styles.topPdExSm]}>
                    <Text
                      style={[
                        styles.textMicSm,
                        message && message.length > 160
                          ? styles.textErr
                          : styles.textGrey,
                      ]}
                      allowFontScaling= {false}
                      >
                      Max 160 characters
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={[styles.popBtnView, styles.comMgTopSm]}>
              <View
                style={[styles.btnArea, styles.flexRow, styles.flexJcCtAiCt]}>
                <View style={styles.fieldBtn}>
                  <PrimaryButton
                    btnText={'Send'}
                    btnPosition={'full'}
                    touchableOnPress={this.sendConectionRequest}
                    activityInd={sendRequestWait}
                    disabled={sendRequestWait}
                    btnStyle={[
                      styles.submitBgBlue,
                      styles.primaryButtonLgPd,
                      sendRequestWait
                        ? styles.btnBlurState
                        : styles.btnActiveState,
                    ]}
                  />
                </View>
              </View>
            </View>
          </View>
        </ModalLayoutSpec>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.routines.peer2peer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSendConnectionRequest: (connectionRequest, callBack) =>
      dispatch(actions.sendConnectionRequest(connectionRequest, callBack)),
    onSetConnectionRequestMessage: message =>
      dispatch(actions.setConnectionRequestMessage(message)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(connectionRequestPopup);
