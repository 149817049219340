import React from 'react';
import {View, Image, TouchableOpacity} from 'react-native';
import styles from '../../../Css';
import stylesResponsive from "../../../CssResponsive";

const filerClearFilterSection = props => {
  const {filterClickHandler, source} = props;
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <View style={stylesRes.leftMgSmCom} dataSet={{media: ids.leftMgSmCom}}>
    <TouchableOpacity onPress={filterClickHandler}>
      <View style={[stylesRes.filterSec, stylesRes.headerIconPdSmCom]}>
        <Image style={styles.headerFilterIcon} source={source} />
      </View>
    </TouchableOpacity>
    </View>
  );
};

export default (filerClearFilterSection);
