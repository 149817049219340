import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import TickButton from "../../components/CheckBox/CheckBoxNew";
import * as actions from "../../store/actions/index";

class Symptom extends Component {
  constructor(props) {
    super(props);
    const {symptoms, symptomProps, configChartType, savedSymptomProps, dashboardProcessed, graphId} = this.props;
    let defaultChartType = configChartType;
    if (dashboardProcessed.hasOwnProperty(graphId) && dashboardProcessed[graphId].symptomsProperties) {
      const propsArray = JSON.parse(dashboardProcessed[graphId].symptomsProperties);
      Object.values(propsArray).forEach(element => {
        if (element.id == props.item.id) {
          defaultChartType = element.style;
        }
      });
    }

    this.state = {
      ...props.item,
      displayProperties: { ...props.item.displayProperties },
      tick: symptoms && symptoms[props.item.id] ? true : false,
      chartType: symptomProps && symptomProps[props.item.id] && symptomProps[props.item.id].style 
                  ? symptomProps[props.item.id].style
                  : savedSymptomProps && savedSymptomProps[props.item.id] && savedSymptomProps[props.item.id].style 
                      ? savedSymptomProps[props.item.id].style
                          : dashboardProcessed.hasOwnProperty(graphId)
                              ? defaultChartType
                              : configChartType,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.symptoms && props.symptoms[this.state.id]) {
      this.setState({ tick: true });
    } else {
      this.setState({ tick: false });
    }
  }

  handleOnPressTick = (tick, id) => {
    if (tick) {
      this.props.addSymptom(id);
      this.props.updateSymptomProps(id, this.state.chartType);
    } else {
      this.props.removeSymptom(id);
    }
  };
  shouldComponentUpdate(nextProps, nextState) {
    return nextState.tick !== this.state.tick;
  }
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const colorItem = (
      <View
        key={this.props.key}
        style={stylesRes.colorBox}
        dataSet={{media: ids.colorBox}}
      >
        <View
          style={[
            stylesRes.colorItem,
            {
              backgroundColor: this.state.displayProperties?.color
                ? this.state.displayProperties?.color
                : "white"
            }
          ]}
          dataSet={{media: ids.colorItem}}
        >
          <View style={[styles.colorSection]} />
        </View>
      </View>
    );
    return (
      <TouchableOpacity
        onPress={() => 
          this.handleOnPressTick(!this.state.tick, this.state.id)
        }
      >
        <View style={[styles.ViewRow, styles.greyBorderThin]}>
          <View style={styles.viewDetailSec}>
            <View style={styles.viewMainData}>
              <View style={[styles.viewItemDesc, styles.colPdLtTopBotRg]}>
                <View style={[styles.viewColor, styles.infoSp]}>{colorItem}</View>

                <View style={[styles.viewDataFull]}>
                  <Text style={styles.textPrime} allowFontScaling={false}>{this.state.name}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.dataIconSecRg, styles.colPdRtTopBotRg]}>
            <View style={styles.iconListSec}>
              <View style={[styles.checkboxSec]}>
                <TickButton
                  ticked={this.state.tick}
                  toggleTickCallback={tick =>
                    this.handleOnPressTick(tick, this.state.id)
                  }
                />
                {/* {this.state.tick ? (
                  <TouchableOpacity
                    onPress={() => this.handleOnPressTick(false, this.state.id)}
                    style={[styles.selectSecIcon, styles.iconListMgSmLt]}
                    disabled={this.props.showOnly}
                  >
                    <Image
                      style={styles.radioIcon}
                      source={require("../../../assets/icons/radio-selected.png")}
                    />
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    onPress={() => this.handleOnPressTick(true, this.state.id)}
                    style={[styles.selectSecIcon, styles.iconListMgSmLt]}
                    disabled={this.props.showOnly}
                  >
                    <Image
                      style={styles.radioIcon}
                      source={require("../../../assets/icons/radio-default.png")}
                    />
                  </TouchableOpacity>
                )} */}
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = state => {
  return {
    symptoms: state.report.graph.symptoms,
    symptomProps: state.report.graph.symptomProps,
    savedSymptomProps: state.report.savedGraph.symptomProps,
    dashboardProcessed: state.routines.dashboardProcessed,
    graphId: state.report.graph.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addSymptom: symptom => dispatch(actions.addReportGrapSymptom(symptom)),
    removeSymptom: symptom => dispatch(actions.removeReportGrapSymptom(symptom)),
    updateSymptomProps: (symptomId, chartType) => 
      dispatch(actions.updateReportGraphSymptomProps(symptomId, chartType))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Symptom);
