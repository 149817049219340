import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
  user: null,
  applicationSettingsDigest: null,
  dashboard: null,
  unitsOfMeasureDigest: null,
  metricsDigest: null,
  symptomsDigest: null,
  treatments: null,
  activities: null,
  contacts: null,
  questionDigest: null,
  journalEntryTypes: null,
  attachmentsCompleteDigest: null,
  userAllergies: null,
  userConditions: null,
  sharedAccessDigest: null,
  userSubscription: null,
  userSubscriptionDigest: null,
  clinicalTrials: null,
  clinicalTrialSavedSearch: null,
  drugAdministrationsDigits: null,

  // Processed Data
  journalEntriesProcessed: null,
  unitsOfMeasureDigestProcessed: null,
  metricsDigestProcessed: null,
  symptomsDigestProcessed: null,
  treatmentsProcessed: null,
  contactsProcessed: null,
  questionDigestProcessed: null,
  journalEntryTypesProcessed: null,
  attachmentsCompleteDigestProcessed: null,
  userAllergiesProcessed: null,
  userConditionsProcessed: null,
  drugAdministrationsDigitsProcessed: null,

  journalEntrySelected: null,
  journalEntryTypeSelected: null,

  journalEntrySelectedStatus: false,
  questionDigestStatus: false,

  // Get Selected Date
  selectedDate: null,
  journalEntryFilter: null,

  // Attachments
  userAttachmentsSelected: null,
  attachmentUploadProgress: 0,
  attachmentUploadProgressTemp: 0,
  attachmentUploadStatus: false,
  attachmentArtifactId: null,
  attachmentFullSize: 0,
  currentUploadingAttachmentKey: null,

  //Spinners
  journalAttachmentSpinner: true,
  journalEntrySelectedSpinner: true,
  fetchAllJournalsSpinner: true,
  deleteJournalSpinner: false,
  fetchAllJournalsSpinnerStart: false,
  createdRoutineEntry: false,
  creatingRoutineEntry: false,
  errorDescription: null,
  error: false,

  //pushNotifications
  pushNotification: null,

  //peer2peer
  peer2peer: null,
  myNetwork: null,

  //Device
  userDeviceMap: null,
  onTouchRoutineFlag: false,

  // Core data has been loaded
  coreDataLoaded: false,
};

const createJournalEntryByTypeSuccess = (state, action) => {
  return updateObject(state, {});
};

const createJournalEntryByTypeFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const deleteJournalSuccess = (state, action) => {
  let questionsToBeDeleted = [];
  const questionDigest = {...state.questionDigest};
  const questionDigestProcessed = {...state.questionDigestProcessed};
  const userArtifactHealthQuestionsProcessed =
    questionDigestProcessed.userArtifactHealthQuestions;
  let questions = questionDigest.questions;

  const questionsProcessed = questionDigestProcessed.questions;

  const userArtifactHealthQuestionsTemp = questionDigest.userArtifactHealthQuestions.filter(
    res => res.artifactId === action.artifactId,
  );

  const userArtifactHealthQuestions = questionDigest.userArtifactHealthQuestions.filter(
    res => res.artifactId !== action.artifactId,
  );

  if (
    userArtifactHealthQuestionsTemp &&
    userArtifactHealthQuestionsTemp.length > 0
  ) {
    userArtifactHealthQuestionsTemp.forEach(item => {
      delete userArtifactHealthQuestionsProcessed[item.id];
      delete questionsProcessed[item.questionId];
      questionsToBeDeleted.push(item.questionId);
    });

    questions = questionDigest.questions.filter(
      question => !questionsToBeDeleted.includes(question.id),
    );
  }
  const journalArtifactMap = state.journalArtifactMap;
  if (journalArtifactMap && journalArtifactMap[action.artifactId]) {
    delete journalArtifactMap[action.artifactId];
  }

  let recordedSymptom = [];
  if (state.recordedSymptom) {
    recordedSymptom = state.recordedSymptom;
  }
  
  if (action.symptoms && action.symptoms.length > 0) {
    action.symptoms.forEach(item => {
      let index = recordedSymptom.findIndex(
        symptom => item.id === symptom.id
      );
      if (index !== -1) recordedSymptom.splice(index, 1);
    });
  }

  const journalEntriesProcessed = state.journalEntriesProcessed;
  if (journalEntriesProcessed && journalEntriesProcessed[action.journalEntryId]) {
    delete journalEntriesProcessed[action.journalEntryId];
  }

  return updateObject(state, {
    deleteJournalSpinner: false,
    journalEntriesProcessed: journalEntriesProcessed,
    journalArtifactMap: journalArtifactMap,
    questionDigestProcessed: {
      questions: questionsProcessed,
      userArtifactHealthQuestions: userArtifactHealthQuestionsProcessed,
    },
    questionDigest: {
      questions: questions,
      userArtifactHealthQuestions: userArtifactHealthQuestions,
    },
    recordedSymptom: recordedSymptom
  });
};

const deleteJournalFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
    deleteJournalSpinner: false,
  });
};

// const deleteJournalStart = (state, action) => {
//   return updateObject(state, {
//     deleteJournalSpinner: true
//   });
//};

const updateJournalEntrySuccess = (state, action) => {
  return updateObject(state, {});
};

const updateJournalEntryFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const cloneJournalEntrySuccess = (state, action) => {
  return updateObject(state, {});
};

const cloneJournalEntryFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const fetchJournalEntryByTimeRangeSuccess = (state, action) => {
  return updateObject(state, {});
};

const fetchJournalEntryByTimeRangeFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const getUpdatedJournalState = (action) => {
  return {
    user: action.journalData.user,
    applicationSettingsDigest: action.journalData.applicationSettingsDigest,
    dashboard: action.journalData.dashboard,
    unitsOfMeasureDigest: action.journalData.unitsOfMeasureDigest,
    metricsDigest: action.journalData.metricsDigest,
    symptomsDigest: action.journalData.symptomsDigest,
    journalEntryTypes: action.journalData.journalEntryTypes,
    treatments: action.journalData.treatments,
    activities: action.journalData.systemDefinedActivities,
    contacts: action.journalData.contacts,
    questionDigest: action.journalData.questionDigest,
    attachmentsCompleteDigest: action.journalData.attachmentsCompleteDigest,
    userAllergies: action.journalData.allergiesDigest.userAllergies,
    userConditions: action.journalData.userConditions,
    userSubscription: action.journalData.userSubscription,
    userSubscriptionDigest: action.journalData.userSubscriptionDigest,
    pushNotification: action.journalData.pushNotification,
    clinicalTrials: action.journalData.clinicalTrial,
    clinicalTrialSavedSearch: action.journalData.clinicalTrialSavedSearch,
    drugAdministrationsDigits: action.journalData.drugAdministrationsDigits,

    journalEntriesProcessed: action.processedData.journalEntries,
    unitsOfMeasureDigestProcessed: action.processedData.unitsOfMeasureDigest,
    metricsDigestProcessed: action.processedData.metricsDigest,
    symptomsDigestProcessed: action.processedData.symptomsDigest,
    journalEntryTypesProcessed: action.processedData.journalEntryTypes,
    treatmentsProcessed: action.processedData.treatments,
    contactsProcessed: action.processedData.contacts,
    questionDigestProcessed: action.processedData.questionDigest,
    attachmentsCompleteDigestProcessed: action.processedData.attachmentsCompleteDigest,
    userAllergiesProcessed: action.processedData.userAllergies,
    userConditionsProcessed: action.processedData.userConditions,
    dashboardProcessed: action.processedData.dashboard,
    drugAdministrationsDigitsProcessed: action.processedData.drugAdministrationsDigits,

    recordedSymptom: action.journalRecordedData.recordedSymptom,
    recordedDosing: action.journalRecordedData.recordedDosing,
    journalArtifactMap: action.journalRecordedData.journalArtifactMap,
    treatmentsArtifactMap: action.journalRecordedData.treatmentsArtifactMap,
    contactsArtifactMap: action.journalRecordedData.contactsArtifactMap,
    fetchAllJournalsSpinner: false, //Spinner
    fetchAllJournalsSpinnerStart: action.fetchAllJournalsSpinnerStart,
    systemDefinedAllergy: action.journalData.allergiesDigest.systemDefinedAllergy,
  }
}

const fetchAllJournalEntriesSuccess = (state, action) => {
  const updatedState = getUpdatedJournalState(action);

  return updateObject(state, {
    ...updatedState,

    //peer2peer
    peer2peer: action.journalData.userPeerProfile,
    myNetwork: action.journalData.myNetwork,

    sharedAccessDigest: action.journalData.sharedAccessDigest,
    sharedAccessDigestProcessed: action.processedData.sharedAccessDigest,

    coreDataLoaded: true,
  });
};

const fetchAllUserJournalEntriesSuccess = (state, action) => {
  const updatedState = getUpdatedJournalState(action);

  return updateObject(state, {
    ...updatedState,
  });
};

const fetchAllJournalEntriesFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
    fetchAllJournalsSpinner: false,
  });
};

const fetchAllJournalEntriesStart = (state, action) => {
  return updateObject(state, {
    fetchAllJournalsSpinner: true,
    fetchAllJournalsSpinnerStart: true
  });
};

const deleteJournalEntryTypeSuccess = (state, action) => {
  // return updateObject(state, {}); journalEntryTypeId
  const entryTypes = state.journalEntryTypes.filter(
    journal => journal.id !== action.journalEntryTypeId,
  );
  let entryTypesProcessed = {...state.journalEntryTypesProcessed};
  delete entryTypesProcessed[action.journalEntryTypeId];
  return {
    ...state,
    journalEntryTypes: [...entryTypes],
    journalEntryTypesProcessed: {...entryTypesProcessed},
  };
};

const updatingJournalEntryTypeSuccess = state => {
  return {
    ...state,
    creatingRoutineEntry: true,
    createdRoutineEntry: false,
  };
};

const updateJournalEntryTypeSuccess = (state, action) => {
  const filteredEntryTypes = state.journalEntryTypes.filter(
    entry => entry.id !== action.journalEntryType.id,
  );

  return {
    ...state,
    creatingRoutineEntry: false,
    createdRoutineEntry: true,
    journalEntryTypes: [...filteredEntryTypes, action.journalEntryType],
    journalEntryTypesProcessed: {
      ...state.journalEntryTypesProcessed,
      [action.journalEntryType.id]: action.journalEntryType,
    },
  };
};

const updateJournalEntryTypeFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
    creatingRoutineEntry: false,
    createdRoutineEntry: false,
  });
};

const setSelectedJournalEntrySuccess = (state, action) => {
  return updateObject(state, {
    journalEntrySelected: action.journalEntrySelected,
    journalEntrySelectedSpinner: false,
  });
};

const setSelectedJournalEntryStart = (state, action) => {
  return updateObject(state, {
    journalEntrySelectedSpinner: true,
  });
};

const setSelectedJournalEntryType = (state, action) => {
  const {journalEntryTypeSelected} = action;
  return updateObject(state, {
    journalEntryTypeSelected,
  });
};

const resetSelectedJournalEntryType = state => {
  return updateObject(state, {
    journalEntryTypeSelected: null,
  });
};

const setSelectedDate = (state, action) => {
  return updateObject(state, {
    selectedDate: action.selectedDate,
  });
};

// User Health Question

const updateHealthQuestionAnswerSuccess = (state, action) => {
  return updateObject(state, {
    questionDigest: action.questionDigest,
    journalEntrySelected: action.journalEntrySelected,
    journalEntrySelectedStatus: action.journalEntrySelectedStatus,
    questionDigestStatus: action.questionDigestStatus,
  });
};

const updateHealthQuestionAnswerSuccessNew = (state, action) => {
  // Add To Question Digest
  let questionDigestTemp = state.questionDigest;
  // questionDigestTemp.questions.map(res => {
  //   if (res.id === action.healthQuestionId) {
  //     res.answers.push(action.healthQuestionAnswer);
  //   }
  //});

  for (let i = 0; i < questionDigestTemp.questions.length; i++) {
    if (questionDigestTemp.questions[i].id === action.healthQuestionId) {
      questionDigestTemp.questions[i].answers.push(action.healthQuestionAnswer);
      break;
    }
  }

  // Add To Question Digest Proccessed
  return updateObject(state, {
    questionDigest: questionDigestTemp,
  });
};

const updateHealthQuestionAnswerFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const updateHealthQuestionSuccess = (state, action) => {
  return updateObject(state, {
    questionDigest: action.questionDigest,
    journalEntrySelectedStatus: action.journalEntrySelectedStatus,
  });
};

const updateHealthQuestionSuccessNew = (state, action) => {
  return updateObject(state, {
    questionDigest: action.questionDigest,
  });
};

const updateHealthQuestionFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const fetchUserAttachmentsSuccess = (state, action) => {
  return updateObject(state, {
    userAttachmentsSelected: action.userAttachmentsSelected,
    journalAttachmentSpinner: false,
  });
};

const fetchUserAttachmentsFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
    journalAttachmentSpinner: false,
  });
};

const fetchUserAttachmentsStart = (state, action) => {
  return updateObject(state, {
    journalAttachmentSpinner: true,
  });
};

const deleteAttachmentSuccess = (state, action) => {
  let applicationSettingsDigest = state.applicationSettingsDigest;
  let {uploadedFileSize} = applicationSettingsDigest;
  uploadedFileSize = uploadedFileSize - action.fileSize;
  applicationSettingsDigest.uploadedFileSize = uploadedFileSize;
  return updateObject(state, {
    attachmentsCompleteDigest: action.attachmentsCompleteDigest,
    applicationSettingsDigest: applicationSettingsDigest,
  });
};

const deleteAttachmentFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

// Routine Template
const saveHealthQuestionSuccess = (state, action) => {
  let questionDigestTemp = state.questionDigest;
  let questionDigestProcessedTemp = state.questionDigestProcessed;

  const found = questionDigestTemp.userArtifactHealthQuestions.find(function(
    item,
  ) {
    return item.questionId === action.response.healthQuestion.id;
  });

  if (!found) {
    // Custom ID
    const id = action.response.healthQuestion.id.toString() + action.artifactId;

    // Custom userArtifactHealthQuestions Obj

    let val = {
      id: parseInt(id),
      questionId: action.response.healthQuestion.id,
      artifactId: action.artifactId,
      createdOn: Date.now(),
      modifiedOn: Date.now(),
    };

    questionDigestTemp.userArtifactHealthQuestions.push(val);
    questionDigestProcessedTemp.userArtifactHealthQuestions[id] = val;
  }

  const questions = questionDigestTemp.questions.filter(
    item => item.id !== action.response.healthQuestion.id,
  );
  questions.push(action.response.healthQuestion);
  questionDigestTemp.questions = questions;
  questionDigestProcessedTemp.questions[action.response.healthQuestion.id] =
    action.response.healthQuestion;

  return updateObject(state, {
    questionDigest: questionDigestTemp,
    questionDigestProcessed: questionDigestProcessedTemp,
  });
};

const saveHealthQuestionFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const deleteHealthQuestionSuccess = (state, action) => {
  return updateObject(state, {
    questionDigest: action.questionDigest,
    questionDigestProcessed: action.questionDigestProcessed,
  });
};

const deleteHealthQuestionFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const filterQuestions = (state, action) => {
  return updateObject(state, {
    QuestionsFilter: action.filter,
  });
};

const clearQuestionFilter = (state, action) => {
  return updateObject(state, {
    QuestionsFilter: action.filter,
  });
};

const saveJournalEntrySuccess = (state, action) => {
  let journalEntriesProcessed = state.journalEntriesProcessed;
  const journalArtifactMap = state.journalArtifactMap;

  journalEntriesProcessed[action.newJournal.id] = action.newJournal;
  journalArtifactMap[action.newJournal.artifactId] = action.newJournal.id;

  let recordedSymptom = [];
  if (state.recordedSymptom) {
    recordedSymptom = state.recordedSymptom;
  }
  if (action.newJournal.symptoms && action.newJournal.symptoms.length > 0) {
    action.newJournal.symptoms.forEach(item => {
      recordedSymptom = recordedSymptom.filter(
        symptom => item.id !== symptom.id,
      );
      recordedSymptom.push(item);
    });
  }
  if (action.deletedSymptomIds && action.deletedSymptomIds.length > 0) {
    action.deletedSymptomIds.forEach(id => {
      let index = recordedSymptom.findIndex(
        symptom => id === symptom.id
      );
      if (index !== -1) recordedSymptom.splice(index, 1);
    });
  }

  return updateObject(state, {
    journalEntriesProcessed: journalEntriesProcessed,
    journalEntrySelected: action.isFromCopyToNew ? state.journalEntrySelected : action.newJournal,
    journalEntrySelectedSpinner: false,
    recordedSymptom: recordedSymptom,
  });
};

const saveJournalEntryFailed = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const filterJournalEntry = (state, action) => {
  return updateObject(state, {
    journalEntryFilter: action.filter,
  });
};

const clearJournalEntryFilter = (state, action) => {
  return updateObject(state, {
    journalEntryFilter: {
      searchKeyword: '',
      ticked: state.journalEntryFilter?.ticked,
    },
  });
};

// Therapie

const updateTherapieSuccess = (state, action) => {
  if (state.treatments) {
    const updatetreatments = state.treatments.filter(
      res => res.id !== action.treatment.id,
    );

    updatetreatments.push(action.treatment);

    let treatmentsProcessed = state.treatmentsProcessed;

    treatmentsProcessed[action.treatment.id] = action.treatment;
    let treatmentsArtifactMap = {};
    if (state.treatmentsArtifactMap) {
      treatmentsArtifactMap = state.treatmentsArtifactMap;
    }
    treatmentsArtifactMap[action.treatment.artifactId] = action.treatment;
    let recordedDosing = [];
    if (state.recordedDosing) {
      recordedDosing = state.recordedDosing;
    }

    if (action.treatment.dosages) {
      action.treatment.dosages.forEach(element => {
        recordedDosing = recordedDosing.filter(item => item.id !== element.id);
        recordedDosing.push({...element, userTreatment: action.treatment.id});
      });
    }

    return updateObject(state, {
      treatments: updatetreatments,
      treatmentsProcessed: treatmentsProcessed,
      treatmentsArtifactMap: treatmentsArtifactMap,
      recordedDosing: recordedDosing,
    });
  }

  return updateObject(state, {});
};

const deleteTherapieFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const scanTherapyFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const uploadBusinessCardFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const businessJobResultFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const addQuestion = (state, action) => {
  return updateObject(state, {
    questionDigest: action.questionDigest,
    questionDigestProcessed: action.questionDigestProcessed,
  });
};

const resetDataLogout = (state, action) => {
  return updateObject(state, {
    fetchAllJournalsSpinnerStart: false,
    selectedDate: null,
  });
};

const updatePathByIntentResetJournalState = (state, action) => {
  return updateObject(state, {
    fetchAllJournalsSpinnerStart: false,
  });
};

const deleteTherapieSuccess = (state, action) => {
  const deletedTherapie = state.treatments.find(
    res => res.id === action.therapieId,
  );
  const filteredItems = state.treatments.filter(
    res => res.id !== action.therapieId,
  );

  let questionsToBeDeleted = [];
  let artifactId = deletedTherapie ? deletedTherapie.artifactId : null;

  const questionDigest = {...state.questionDigest};
  const questionDigestProcessed = {...state.questionDigestProcessed};
  const userArtifactHealthQuestionsProcessed =
    questionDigestProcessed.userArtifactHealthQuestions;
  let questions = questionDigest.questions;

  const questionsProcessed = questionDigestProcessed.questions;

  const userArtifactHealthQuestionsTemp = questionDigest.userArtifactHealthQuestions.filter(
    res => res.artifactId === artifactId,
  );

  const userArtifactHealthQuestions = questionDigest.userArtifactHealthQuestions.filter(
    res => res.artifactId !== artifactId,
  );

  if (
    userArtifactHealthQuestionsTemp &&
    userArtifactHealthQuestionsTemp.length > 0
  ) {
    userArtifactHealthQuestionsTemp.forEach(item => {
      delete userArtifactHealthQuestionsProcessed[item.id];
      delete questionsProcessed[item.questionId];
      questionsToBeDeleted.push(item.questionId);
    });

    questions = questionDigest.questions.filter(
      question => !questionsToBeDeleted.includes(question.id),
    );
  }
  const treatmentsArtifactMap = state.treatmentsArtifactMap;
  if (treatmentsArtifactMap && treatmentsArtifactMap[artifactId]) {
    delete treatmentsArtifactMap[artifactId];
  }
  let recordedDosing = state.recordedDosing;
  if (recordedDosing) {
    recordedDosing = recordedDosing.filter(
      item => item.userTreatment !== action.therapieId,
    );
  }
  return updateObject(state, {
    deleteJournalSpinner: false,
    treatments: filteredItems,
    treatmentsArtifactMap: treatmentsArtifactMap,
    questionDigestProcessed: {
      questions: questionsProcessed,
      userArtifactHealthQuestions: userArtifactHealthQuestionsProcessed,
    },
    questionDigest: {
      questions: questions,
      userArtifactHealthQuestions: userArtifactHealthQuestions,
    },
    recordedDosing: recordedDosing,
  });
};

// const deleteQuestion = (
//   userArtifactHealthQuestionsTemp,
//   questionDigest,
//   questionDigestProcessed
// ) => {
//   let questions = questionDigest.questions.filter(res => {
//     return res.id !== userArtifactHealthQuestionsTemp;
//   });
//   questionDigest.questions = questions ? questions : null;

//   questionDigestProcessed.questions = questions;

//   let questionObject = {
//     questionDigest,
//     questionDigestProcessed
//   };

//   return questionObject;
// };

//Contacts

const deleteContactSuccess = (state, action) => {
  const deletedContact = state.contacts.find(
    res => res.id === action.contactId,
  );

  const filteredItems = state.contacts.filter(
    res => res.id !== action.contactId,
  );

  let artifactId = deletedContact ? deletedContact.artifactId : null;
  let questionsToBeDeleted = [];

  const questionDigest = {...state.questionDigest};
  const questionDigestProcessed = {...state.questionDigestProcessed};

  const userArtifactHealthQuestionsProcessed =
    questionDigestProcessed.userArtifactHealthQuestions;

  let questions = questionDigest.questions;
  const questionsProcessed = questionDigestProcessed.questions;

  const userArtifactHealthQuestionsTemp = questionDigest.userArtifactHealthQuestions.filter(
    res => res.artifactId === artifactId,
  );
  const userArtifactHealthQuestions = questionDigest.userArtifactHealthQuestions.filter(
    res => res.artifactId !== artifactId,
  );

  if (
    userArtifactHealthQuestionsTemp &&
    userArtifactHealthQuestionsTemp.length > 0
  ) {
    userArtifactHealthQuestionsTemp.forEach(item => {
      delete userArtifactHealthQuestionsProcessed[item.id];
      delete questionsProcessed[item.questionId];
      questionsToBeDeleted.push(item.questionId);
    });

    questions = questionDigest.questions.filter(
      question => !questionsToBeDeleted.includes(question.id),
    );
  }

  const contactsArtifactMap = state.contactsArtifactMap;
  if (contactsArtifactMap && contactsArtifactMap[artifactId]) {
    delete contactsArtifactMap[artifactId];
  }

  return updateObject(state, {
    contacts: filteredItems,
    questionDigestProcessed: {
      questions: questionsProcessed,
      userArtifactHealthQuestions: userArtifactHealthQuestionsProcessed,
    },
    contactsArtifactMap: contactsArtifactMap,
    questionDigest: {
      questions: questions,
      userArtifactHealthQuestions: userArtifactHealthQuestions,
    },
  });
};

const deleteContactFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const updateContactSuccess = (state, action) => {
  const updateContacts = state.contacts.filter(
    res => res.id !== action.contact.id,
  );

  updateContacts.push(action.contact);

  let contactsProcessed = state.contactsProcessed;
  const contactsArtifactMap = state.contactsArtifactMap;
  contactsArtifactMap[action.contact.artifactId] = action.contact;
  contactsProcessed[action.contact.id] = action.contact;
  return updateObject(state, {
    contacts: updateContacts,
    contactsProcessed: contactsProcessed,
    contactsArtifactMap: contactsArtifactMap,
  });
};



const updateProfileJournalSuccess = (state, action) => {
  if (state.journalEntriesProcessed) {
    let journalEntriesProcessed = state.journalEntriesProcessed;

    journalEntriesProcessed[action.metricRecording.id] = action.metricRecording;

    return updateObject(state, {
      journalEntriesProcessed: journalEntriesProcessed,
      journalEntrySelected: action.metricRecording,
      journalEntrySelectedSpinner: false,
    });
  }

  return updateObject(state, {});
};

const uploadAttachmentsSuccess = (state, action) => {
  let userArtifactAttachments =
    state.attachmentsCompleteDigest.userArtifactAttachments;
  let attachments = state.attachmentsCompleteDigest.attachments;
  let attachmentsProcessed =
    state.attachmentsCompleteDigestProcessed.attachments;
  action.attachments.forEach(res => {
    userArtifactAttachments.push({
      userArtifact: action.artifactId,
      attachment: res.id,
      createdOn: res.createdOn,
    });
    attachments.push(res);
    attachmentsProcessed[res.id] = res;
  });

  let attachmentsCompleteDigest = state.attachmentsCompleteDigest;
  let attachmentsCompleteDigestProcessed =
    state.attachmentsCompleteDigestProcessed;
  attachmentsCompleteDigest.userArtifactAttachments = userArtifactAttachments;
  attachmentsCompleteDigest.attachments = attachments;
  attachmentsCompleteDigestProcessed.attachments = attachmentsProcessed;
  let applicationSettingsDigest = state.applicationSettingsDigest;
  let {uploadedFileSize} = applicationSettingsDigest;
  uploadedFileSize += action.fileSize;
  applicationSettingsDigest.uploadedFileSize = uploadedFileSize;
  return updateObject(state, {
    attachmentsCompleteDigest: attachmentsCompleteDigest,
    attachmentsCompleteDigestProcessed: attachmentsCompleteDigestProcessed,
    applicationSettingsDigest: applicationSettingsDigest,
  });
};

const setTotalAttachmentSize = (state, action) => {
  let applicationSettingsDigest = state.applicationSettingsDigest;
  let {uploadedFileSize} = applicationSettingsDigest;
  uploadedFileSize = uploadedFileSize - action.fileSize;
  applicationSettingsDigest.uploadedFileSize = uploadedFileSize;
  return updateObject(state, {
    applicationSettingsDigest: applicationSettingsDigest,
  });
};

const updateProfileSuccess = (state, action) => {
  return updateObject(state, {user: action.profile});
};

const clearError = (state, action) => {
  return updateObject(state, {
    errorDescription: null,
    error: false,
  });
};

const updateSelectableMetrics = (state, action) => {
  const metricsDigest = {...state.metricsDigest};
  const selectableMetrics = state.metricsDigest.selectableMetrics.filter(
    res => res.id !== action.metric.id,
  );
  selectableMetrics.push(action.metric);

  metricsDigest.selectableMetrics = selectableMetrics;
  return updateObject(state, {
    metricsDigest: metricsDigest,
  });
};
// Settings - Metric

const updateMetricsSuccess = (state, action) => {
  const metricsDigest = {...state.metricsDigest};
  const userDefinedMetrics = state.metricsDigest.userDefinedMetrics.filter(
    res => res.id !== action.metric.id,
  );
  userDefinedMetrics.push(action.metric);

  metricsDigest.userDefinedMetrics = userDefinedMetrics;

  const metricsDigestProcessed = state.metricsDigestProcessed;
  const userDefinedMetricsProcessed = {
    ...metricsDigestProcessed.userDefinedMetrics,
  };
  const selectableMetricsProcessed = {
    ...metricsDigestProcessed.selectableMetrics,
  };

  userDefinedMetricsProcessed[action.metric.id] = action.metric;
  selectableMetricsProcessed[action.metric.id] = action.metric;

  metricsDigestProcessed.userDefinedMetrics = userDefinedMetricsProcessed;
  metricsDigestProcessed.selectableMetrics = selectableMetricsProcessed;
  return updateObject(state, {
    metricsDigest: metricsDigest,
    metricsDigestProcessed: metricsDigestProcessed,
  });
};

const updateMetricsUomSuccess = (state, action) => {
  let lengthUdmUom = state.metricsDigest.userDefinedMetricUnitOfMeasures.length;

  const userDefinedMetricUnitOfMeasures = state.metricsDigest.userDefinedMetricUnitOfMeasures.filter(
    res => res.id !== action.metricUom.id,
  );
  userDefinedMetricUnitOfMeasures.push(action.metricUom);

  const metricsDigest = {...state.metricsDigest};
  const metricsDigestProcessed = state.metricsDigestProcessed;
  if (lengthUdmUom > userDefinedMetricUnitOfMeasures.length) {
    const userDefinedMetricUnitOfMeasuresTemp =
      state.metricsDigest.userDefinedMetricUnitOfMeasures;

    metricsDigest.userDefinedMetricUnitOfMeasures = userDefinedMetricUnitOfMeasuresTemp;

    metricsDigestProcessed.userDefinedMetricUnitOfMeasures[
      action.metricUom.id
    ] = action.metricUom;
  } else {
    metricsDigest.userDefinedMetricUnitOfMeasures = userDefinedMetricUnitOfMeasures;

    metricsDigestProcessed.userDefinedMetricUnitOfMeasures[
      action.metricUom.id
    ] = action.metricUom;
  }

  return updateObject(state, {
    metricsDigest: metricsDigest,
    metricsDigestProcessed: metricsDigestProcessed,
  });
};

const deleteMetricSuccess = (state, action) => {
  const metricsDigest = {...state.metricsDigest};
  metricsDigest.userDefinedMetrics = action.userDefinedMetric;
  return updateObject(state, {
    metricsDigest: metricsDigest,
  });
};

//Setting - Symptoms

const addUserSymptom = (state, action) => {
  const symptomsDigest = {...state.symptomsDigest};
  const updateSymptoms = state.symptomsDigest.userSymptoms.filter(
    res => res.id !== action.symptom.id,
  );
  updateSymptoms.push(action.symptom);

  symptomsDigest.userSymptoms = updateSymptoms;

  const symptomsDigestProcessed = state.symptomsDigestProcessed;
  symptomsDigestProcessed.userSymptoms[action.symptom.id] = action.symptom;

  return updateObject(state, {
    symptomsDigest: symptomsDigest,
    symptomsDigestProcessed: symptomsDigestProcessed,
  });
};

const deleteSymptomSuccess = (state, action) => {
  const symptomsDigest = {...state.symptomsDigest};

  symptomsDigest.userSymptoms = action.userSymptoms;
  return updateObject(state, {
    symptomsDigest: symptomsDigest,
  });
};

const deleteSymptomFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

// Start User Allergy

const updateUserAllergy = (state, action) => {
  const userAllergies = state.userAllergies.filter(
    res => res.id !== action.userAllergy.id,
  );
  userAllergies.push(action.userAllergy);

  const userAllergiesProcessed = state.userAllergiesProcessed;
  userAllergiesProcessed[action.userAllergy.id] = action.userAllergy;
  return updateObject(state, {
    userAllergies: userAllergies,
    userAllergiesProcessed: userAllergiesProcessed,
  });
};

const deleteUserAllergy = (state, action) => {
  const userAllergies = state.userAllergies.filter(res => res.id !== action.id);
  const userAllergiesProcessed = state.userAllergiesProcessed;
  delete userAllergiesProcessed[action.id];

  return updateObject(state, {
    userAllergies: userAllergies,
    userAllergiesProcessed: userAllergiesProcessed,
  });
};

// end User Allergy

// Start User Condition

const updateUserCondition = (state, action) => {
  const userConditions = state.userConditions.filter(
    res => res.id !== action.userCondition.id,
  );
  userConditions.push(action.userCondition);

  const userConditionsProcessed = state.userConditionsProcessed;
  userConditionsProcessed[action.userCondition.id] = action.userCondition;
  return updateObject(state, {
    userConditions: userConditions,
    userConditionsProcessed: userConditionsProcessed,
  });
};

const deleteUserCondition = (state, action) => {
  const userConditions = state.userConditions.filter(
    res => res.id !== action.id,
  );
  const userConditionsProcessed = state.userConditionsProcessed;
  delete userConditionsProcessed[action.id];

  return updateObject(state, {
    userConditions: userConditions,
    userConditionsProcessed: userConditionsProcessed,
  });
};

// end User Condition

// Start User Symptom
const updateUserSymptom = (state, action) => {
  const symptomsDigest = {...state.symptomsDigest};
  const updateSymptoms = state.symptomsDigest.userSymptoms.filter(
    res => res.id !== action.userSymptom.id,
  );
  updateSymptoms.push(action.userSymptom);

  symptomsDigest.userSymptoms = updateSymptoms;

  const symptomsDigestProcessed = state.symptomsDigestProcessed;
  symptomsDigestProcessed.userSymptoms[action.userSymptom.id] =
    action.userSymptom;

  return updateObject(state, {
    symptomsDigest: symptomsDigest,
    symptomsDigestProcessed: symptomsDigestProcessed,
  });
};

// Start User Metric
const updateUserMetric = (state, action) => {
  const userMetrics = state.metricsDigest.filter(
    res => res.id !== action.metric.id,
  );
  userMetrics.push(action.userMetric);

  const userMetricsProcessed = state.metricsDigestProcessed;
  userMetricsProcessed[action.userMetric.id] = action.metric;
  return updateObject(state, {
    metricsDigest: userMetrics,
    metricsDigestProcessed: userMetricsProcessed,
  });
};

// start Care giver access

const sharedAccessInvitation = (state, action) => {
  let sharedAccessDigest = {};
  if (state.sharedAccessDigest) {
    const pendingInvitationsSent =
      state.sharedAccessDigest.pendingInvitationsSent;
    action.shareAccessInvitations.map(
      res => !res.existingEmail && pendingInvitationsSent.push(res),
    );
    sharedAccessDigest = state.sharedAccessDigest;
    sharedAccessDigest.pendingInvitationsSent = pendingInvitationsSent;
  }

  return updateObject(state, {
    sharedAccessDigest: sharedAccessDigest,
  });
};

const deleteSharedAccessInvitation = (state, action) => {
  const pendingInvitationsSent = state.sharedAccessDigest.pendingInvitationsSent.filter(
    res => res.token !== action.token,
  );

  const sharedAccessDigest = state.sharedAccessDigest;
  sharedAccessDigest.pendingInvitationsSent = pendingInvitationsSent;

  return updateObject(state, {
    sharedAccessDigest: sharedAccessDigest,
  });
};

const updateSharedAccessPermission = (state, action) => {
  var found = state.sharedAccessDigest.sharedAccessGivenToOthers.find(
    element => {
      return element.grantedTo === action.grantedTo;
    },
  );
  if (found) {
    found.permissionType = action.permission;
  }
  const sharedAccessGivenToOthers = state.sharedAccessDigest.sharedAccessGivenToOthers.filter(
    res => res.grantedTo !== action.grantedTo,
  );

  sharedAccessGivenToOthers.push(found);

  const sharedAccessDigest = state.sharedAccessDigest;
  sharedAccessDigest.sharedAccessGivenToOthers = sharedAccessGivenToOthers;

  return updateObject(state, {
    sharedAccessDigest: sharedAccessDigest,
  });
};

const deleteSharedAccessPermission = (state, action) => {
  const sharedAccessGivenToOthers = state.sharedAccessDigest.sharedAccessGivenToOthers.filter(
    res => res.grantedTo !== action.grantedTo,
  );

  const sharedAccessDigest = state.sharedAccessDigest;
  sharedAccessDigest.sharedAccessGivenToOthers = sharedAccessGivenToOthers;

  return updateObject(state, {
    sharedAccessDigest: sharedAccessDigest,
  });
};

const deleteSharedAccessPermissionGivenTomeSuccess = (state, action) => {
  let sharedAccessDigest = {};

  if (state.sharedAccessDigest) {
    const sharedAccessGivenToMe = state.sharedAccessDigest.sharedAccessGivenToMe.filter(
      res => res.grantingUser !== action.grantingUser,
    );

    sharedAccessDigest = state.sharedAccessDigest;
    sharedAccessDigest.sharedAccessGivenToMe = sharedAccessGivenToMe;
  }

  return updateObject(state, {
    sharedAccessDigest: sharedAccessDigest,
  });
};

const sharedAccessInvitationAcceptSuccess = (state, action) => {
  if (state.sharedAccessDigest) {
    const found = state.sharedAccessDigest.pendingInvitationsReceived.find(
      res => res.token === action.token,
    );
    const pendingInvitationsReceived = state.sharedAccessDigest.pendingInvitationsReceived.filter(
      res => res.token !== action.token,
    );

    let sharedAccessGivenToMe = state.sharedAccessDigest.sharedAccessGivenToMe;
    sharedAccessGivenToMe = sharedAccessGivenToMe.filter(
      res => res.grantingUser !== action.data.grantingUser,
    );
    sharedAccessGivenToMe.push(action.data);

    const sharedAccessUserSummaries =
      state.sharedAccessDigest?.sharedAccessUserSummaries || [];
    sharedAccessUserSummaries.push({
      userId: action.data.grantingUser,
      name: found.inviteBy,
      emailAddress: action.data.grantingUserEmail,
      ownerOfJournal: false,
    });

    const sharedAccessUserSummariesProcessed =
      state.sharedAccessDigestProcessed?.sharedAccessUserSummaries || {};
    sharedAccessUserSummariesProcessed[action.data.grantingUser] = {
      userId: action.data.grantingUser,
      name: found.inviteBy,
      emailAddress: action.data.grantingUserEmail,
      ownerOfJournal: false,
    };

    const sharedAccessDigest = state.sharedAccessDigest;
    const sharedAccessDigestProcessed = state.sharedAccessDigestProcessed;
    sharedAccessDigest.pendingInvitationsReceived = pendingInvitationsReceived;
    sharedAccessDigest.sharedAccessGivenToMe = sharedAccessGivenToMe;
    sharedAccessDigest.sharedAccessUserSummaries = sharedAccessUserSummaries;
    sharedAccessDigestProcessed.sharedAccessUserSummaries = sharedAccessUserSummariesProcessed;

    return updateObject(state, {
      sharedAccessDigest: sharedAccessDigest,
      sharedAccessDigestProcessed: sharedAccessDigestProcessed,
    });
  }

  return updateObject(state, {});
};

const sharedAccessDisconnectSuccess = (state, action) => {
  if (state.sharedAccessDigest) {
    const sharedAccessGivenToOthers = state.sharedAccessDigest.sharedAccessGivenToOthers.filter(
      res => res.grantedTo.toString() !== action.data.grantedTo.toString(),
    );

    sharedAccessGivenToOthers.push(action.data);

    const sharedAccessDigest = state.sharedAccessDigest;

    sharedAccessDigest.sharedAccessGivenToOthers = sharedAccessGivenToOthers;

    return updateObject(state, {
      sharedAccessDigest: sharedAccessDigest,
    });
  }

  return updateObject(state, {});
};

const sharedAccessInvitationDeclineSuccess = (state, action) => {
  const pendingInvitationsReceived = state.sharedAccessDigest.pendingInvitationsReceived.filter(
    res => res.token !== action.token,
  );
  const sharedAccessDigest = state.sharedAccessDigest;

  sharedAccessDigest.pendingInvitationsReceived = pendingInvitationsReceived;

  return updateObject(state, {
    sharedAccessDigest: sharedAccessDigest,
  });
};

const sharedAccessInvitationResendSuccess = (state, action) => {
  const pendingInvitationsSent = state.sharedAccessDigest.pendingInvitationsSent.filter(
    res => res.token !== action.token,
  );
  pendingInvitationsSent.push(action.data);
  const sharedAccessDigest = state.sharedAccessDigest;
  sharedAccessDigest.pendingInvitationsSent = pendingInvitationsSent;

  return updateObject(state, {
    sharedAccessDigest: sharedAccessDigest,
  });
};

// end Care giver access

//start dashboard graph

const updateDashboardGraph = (state, action) => {
  const dashboard = state.dashboard.filter(
    res => res.id !== action.dashboard.id,
  );
  dashboard.push(action.dashboard);
  const dashboardProcessed = state.dashboardProcessed;
  dashboardProcessed[action.dashboard.id] = action.dashboard;

  if(action.dashboard.entryTypeId && action.isFromGraphSave) {
    const journalEntryTypes = state.journalEntryTypes;
    const journalEntryTypesProcessed = state.journalEntryTypesProcessed;
    const tempJournalEntryType = journalEntryTypes.find(res => res.id === action.dashboard.entryTypeId);
    const tempJournalEntryTypeProcessed = journalEntryTypesProcessed[action.dashboard.entryTypeId];
    tempJournalEntryType.graphId = action.dashboard.id;
    tempJournalEntryTypeProcessed.graphId = action.dashboard.id;

    return updateObject(state, {
      dashboard: dashboard,
      dashboardProcessed: dashboardProcessed,
      journalEntryTypes: journalEntryTypes,
      journalEntryTypesProcessed: journalEntryTypesProcessed
    });
  }
  return updateObject(state, {
    dashboard: dashboard,
    dashboardProcessed: dashboardProcessed,
  });
};

const deleteDashboardGraph = (state, action) => {
  const dashboard = state.dashboard.filter(
    res => res.id !== action.dashboardId,
  );
  const dashboardProcessed = state.dashboardProcessed;
  delete dashboardProcessed[action.dashboardId];

  return updateObject(state, {
    dashboard: dashboard,
    dashboardProcessed: dashboardProcessed
  });
};

//end dashboard graph

const commentErrorHandler = (state, action) => {

  return updateObject(state, {
    errorDescription: action.errorDescription?.message || action.errorDescription,
    error: true,
  });
};

const overdueRoutineReminder = (state, action) => {
  const pushNotification = state.pushNotification ? state.pushNotification : {};
  if (
    action.overdueRoutineReminder !== pushNotification.overdueRoutineReminder
  ) {
    pushNotification.overdueRoutineReminder = action.overdueRoutineReminder;
  }
  if (action.matchedClinicalTrial !== pushNotification.matchedClinicalTrial) {
    pushNotification.matchedClinicalTrial = action.matchedClinicalTrial;
  }
  return updateObject(state, {
    pushNotification: pushNotification,
  });
};

const createClinicalTrialSuccess = (state, action) => {
  let clinicalTrials = [];
  if (state.clinicalTrials) {
    clinicalTrials = state.clinicalTrials;
  }
  const updateClinicalTrial = clinicalTrials.filter(
    res => res.id !== action.clinicalTrial.id,
  );

  updateClinicalTrial.push(action.clinicalTrial);

  return updateObject(state, {
    clinicalTrials: updateClinicalTrial,
  });
};

const createClinicalTrialListSuccess = (state, action) => {
  let clinicalTrialsTemp = state.clinicalTrials;

  action.clinicalTrialList.map(clinicalTrial =>
    clinicalTrialsTemp.push(clinicalTrial),
  );

  return updateObject(state, {
    clinicalTrials: clinicalTrialsTemp,
  });
};

const deleteClinicalTrialSuccess = (state, action) => {
  const filteredItems = state.clinicalTrials.filter(
    res => res.id !== action.clinicalTrialId,
  );
  return updateObject(state, {
    clinicalTrials: filteredItems,
  });
};

const deleteAllClinicalTrialsSuccess = (state, action) => {
  return updateObject(state, {
    clinicalTrials: null,
  });
};

const deleteClinicalTrialListSuccess = (state, action) => {
  const filteredItems = state.clinicalTrials.filter(
    res => action.clinicalTrialIdList.indexOf(res.id) === -1,
  );
  return updateObject(state, {
    clinicalTrials: filteredItems,
  });
};

const updateSavedSearchClinicalTrialsSuccess = (state, action) => {
  const updateSavedSearch = state.clinicalTrialSavedSearch.filter(
    res => res.id !== action.savedSearchClinicalTrial.id,
  );

  updateSavedSearch.push(action.savedSearchClinicalTrial);

  return updateObject(state, {
    clinicalTrialSavedSearch: updateSavedSearch,
  });
};

const updateClinicaltrailDisclaimerSuccess = (state, action) => {
  const applicationSettingsDigest = {...state.applicationSettingsDigest};
  applicationSettingsDigest.clinicalTrialDisclaimer =
    action.clinicalTrialDisclaimer;

  return updateObject(state, {
    applicationSettingsDigest: applicationSettingsDigest,
  });
};

const updateIsSetupOnSuccess = (state, action) => {
  const applicationSettingsDigest = {...state.applicationSettingsDigest};
  applicationSettingsDigest.isSetupOn = action.isSetupOn;

  return updateObject(state, {
    applicationSettingsDigest: applicationSettingsDigest,
  });
};

const fetchIosSubscriptionDetailsUpdateSuccess = (state, action) => {
  return updateObject(state, {
    userSubscription: action.userSubscription
      ? [action.userSubscription]
      : null,
    userSubscriptionDigest: action.userSubscriptionDigest
      ? action.userSubscriptionDigest
      : null,
  });
};

const updatePremiumBannerHideSuccess = (state, action) => {
  const applicationSettingsDigest = {...state.applicationSettingsDigest};
  applicationSettingsDigest.showPremiumBanner = action.showPremiumBanner;

  return updateObject(state, {
    applicationSettingsDigest: applicationSettingsDigest,
  });
};

const updateShowPeerProfileScreenSuccess = (state, action) => {
  const applicationSettingsDigest = {...state.applicationSettingsDigest};
  applicationSettingsDigest.showPeerProfileScreen =
    action.showPeerProfileScreen;

  return updateObject(state, {
    applicationSettingsDigest: applicationSettingsDigest,
  });
};

const updatePeer2PeerProfile = (state, action) => {
  const peer2peer = action.profile;
  return updateObject(state, {
    peer2peer: peer2peer,
  });
};

const updatePeer2PeerMyNetwork = (state, action) => {
  const myNetwork = action.myNetwork;
  return updateObject(state, {
    myNetwork: myNetwork,
  });
};

const setAttachmentUploadProgress = (state, action) => {
  return updateObject(state, {
    attachmentUploadProgress: action.progress,
  });
};

const setAttachmentUploadProgressTemp = (state, action) => {
  return updateObject(state, {
    attachmentUploadProgressTemp: action.progress,
  });
};

const setAttachmentUploadStatus = (state, action) => {
  return updateObject(state, {
    attachmentUploadStatus: action.status,
  });
};

const setArtifactIdForAttachmentUploading = (state, action) => {
  return updateObject(state, {
    attachmentArtifactId: action.id,
  });
};

const setAttachmentFullSize = (state, action) => {
  return updateObject(state, {
    attachmentFullSize: action.size,
  });
};

const setUploadingKey = (state, action) => {
  return updateObject(state, {
    currentUploadingAttachmentKey: action.key,
  });
};

const onTouchRoutine = (state, action) => {
  return updateObject(state, {
    onTouchRoutineFlag: action.flag,
  });
};

const reducer = (state = initialState, action) => {
  //Network error is handled by CR-438(new screen), so we ignore network error here
  //other legacy code related to network error is untouched
  //if need to enable legacy network error msg just remove the condition here
  const networkErrors = ['NETWORK_ERROR', 'Network error'];
  if (networkErrors.includes(action.errorDescription?.message || action.errorDescription )) return state;

  switch (action.type) {
    case actionTypes.CREATE_JOURNAL_ENTRY_BY_TYPE_SUCCESS:
      return createJournalEntryByTypeSuccess(state, action);
    case actionTypes.CREATE_JOURNAL_ENTRY_BY_TYPE_FAIL:
      return createJournalEntryByTypeFail(state, action);

    case actionTypes.DELETE_JOURNAL_ENTRY_SUCCESS:
      return deleteJournalSuccess(state, action);
    case actionTypes.DELETE_JOURNAL_ENTRY_FAIL:
      return deleteJournalFail(state, action);

    case actionTypes.UPDATE_JOURNAL_ENTRY_SUCCESS:
      return updateJournalEntrySuccess(state, action);
    case actionTypes.UPDATE_JOURNAL_ENTRY_FAIL:
      return updateJournalEntryFail(state, action);

    case actionTypes.CLONE_JOURNAL_ENTRY_SUCCESS:
      return cloneJournalEntrySuccess(state, action);
    case actionTypes.CLONE_JOURNAL_ENTRY_FAIL:
      return cloneJournalEntryFail(state, action);

    case actionTypes.FETCH_JOURNAL_ENTRY_BY_TIME_RANGE_SUCCESS:
      return fetchJournalEntryByTimeRangeSuccess(state, action);
    case actionTypes.FETCH_JOURNAL_ENTRY_BY_TIME_RANGE_FAIL:
      return fetchJournalEntryByTimeRangeFail(state, action);

    case actionTypes.FETCH_ALL_JOURNAL_ENTRIES_SUCCESS:
      return fetchAllJournalEntriesSuccess(state, action);
    case actionTypes.FETCH_ALL_JOURNAL_ENTRIES_FAIL:
      return fetchAllJournalEntriesFail(state, action);
    case actionTypes.FETCH_ALL_JOURNAL_ENTRIES_START:
      return fetchAllJournalEntriesStart(state, action);
    case actionTypes.FETCH_ALL_USER_JOURNAL_ENTRIES_SUCCESS:
      return fetchAllUserJournalEntriesSuccess(state, action);

    case actionTypes.DELETE_JOURNAL_ENTRY_TYPE_SUCCESS:
      return deleteJournalEntryTypeSuccess(state, action);

    case actionTypes.UPDATING_JOURNAL_ENTRY_TYPE:
      return updatingJournalEntryTypeSuccess(state);
    case actionTypes.UPDATE_JOURNAL_ENTRY_TYPE_SUCCESS:
      return updateJournalEntryTypeSuccess(state, action);
    case actionTypes.UPDATE_JOURNAL_ENTRY_TYPE_FAIL:
      return updateJournalEntryTypeFail(state, action);

    case actionTypes.SET_JOURNAL_ENTRY_SELECTED_SUCCESS:
      return setSelectedJournalEntrySuccess(state, action);
    case actionTypes.SET_JOURNAL_ENTRY_SELECTED_START:
      return setSelectedJournalEntryStart(state, action);
    case actionTypes.SET_JOURNAL_ENTRY_TYPE_SELECTED:
      return setSelectedJournalEntryType(state, action);
    case actionTypes.CLEAR_JOURNAL_ENTRY_TYPE_SELECTED:
      return resetSelectedJournalEntryType(state);
    case actionTypes.SELECT_DATE:
      return setSelectedDate(state, action);

    // User Health Question
    case actionTypes.UPDATE_HEALTH_QUESTION_ANSWER_SUCCESS:
      return updateHealthQuestionAnswerSuccess(state, action);
    case actionTypes.UPDATE_HEALTH_QUESTION_ANSWER_SUCCESS_NEW:
      return updateHealthQuestionAnswerSuccessNew(state, action);
    case actionTypes.UPDATE_HEALTH_QUESTION_ANSWER_FAIL:
      return updateHealthQuestionAnswerFail(state, action);

    case actionTypes.UPDATE_HEALTH_QUESTION_SUCCESS:
      return updateHealthQuestionSuccess(state, action);
    case actionTypes.UPDATE_HEALTH_QUESTION_SUCCESS_NEW:
      return updateHealthQuestionSuccessNew(state, action);
    case actionTypes.UPDATE_HEALTH_QUESTION_FAIL:
      return updateHealthQuestionFail(state, action);

    case actionTypes.ADD_QUESTION:
      return addQuestion(state, action);

    case actionTypes.QUESTION_FILTER:
      return filterQuestions(state, action);
    case actionTypes.QUESTION_CLEAR_FILTER:
      return clearQuestionFilter(state, action);

    case actionTypes.FETCH_USER_ATTACHMENTS_SUCCESS:
      return fetchUserAttachmentsSuccess(state, action);
    case actionTypes.FETCH_USER_ATTACHMENTS_FAIL:
      return fetchUserAttachmentsFail(state, action);
    case actionTypes.FETCH_USER_ATTACHMENTS_START:
      return fetchUserAttachmentsStart(state, action);

    case actionTypes.DELETE_ATTACHMENT_SUCCESS:
      return deleteAttachmentSuccess(state, action);
    case actionTypes.DELETE_ATTACHMENT_FAIL:
      return deleteAttachmentFail(state, action);
    case actionTypes.JOURNAL_ENTRY_FILTER:
      return filterJournalEntry(state, action);
    case actionTypes.JOURNAL_ENTRY_CLEAR_FILTER:
      return clearJournalEntryFilter(state, action);

    // Routine Template
    case actionTypes.SAVE_HEALTH_QUESTION_SUCCESS:
      return saveHealthQuestionSuccess(state, action);
    case actionTypes.SAVE_HEALTH_QUESTION_FAIL:
      return saveHealthQuestionFail(state, action);

    case actionTypes.DELETE_HEALTH_QUESTION_SUCCESS:
      return deleteHealthQuestionSuccess(state, action);
    case actionTypes.DELETE_HEALTH_QUESTION_FAIL:
      return deleteHealthQuestionFail(state, action);

    case actionTypes.SAVE_JOURNAL_ENTRY_SUCCESS:
      return saveJournalEntrySuccess(state, action);
    case actionTypes.SAVE_JOURNAL_ENTRY__FAIL:
      return saveJournalEntryFailed(state, action);

    //Therapies

    case actionTypes.ADD_THERAPIE:
      return updateTherapieSuccess(state, action);
    case actionTypes.UPDATE_THERAPIE:
      return updateTherapieSuccess(state, action);
    case actionTypes.DELETE_THERAPIE_SUCCESS:
      return deleteTherapieSuccess(state, action);
    case actionTypes.DELETE_THERAPIE_FAIL:
      return deleteTherapieFail(state, action);
    case actionTypes.OCR_SCAN_PRESCRIPTION_FAIL:
      return scanTherapyFail(state, action);
    //Contacts
    case actionTypes.ADD_CONTACT:
      return updateContactSuccess(state, action);
    case actionTypes.UPDATE_CONTACT:
      return updateContactSuccess(state, action);
    case actionTypes.DELETE_CONTACT_SUCCESS:
      return deleteContactSuccess(state, action);
    case actionTypes.DELETE_CONTACT_FAIL:
      return deleteContactFail(state, action);
    case actionTypes.OCR_UPLOAD_BUSINESS_CARD_FAIL:
      return uploadBusinessCardFail(state, action);
    case actionTypes.OCR__BUSINESS_JOB_RESULT_FAIL:
      return businessJobResultFail(state, action);

    //Profile
    case actionTypes.UPDATE_PROFILE:
      return updateProfileSuccess(state, action);

    case actionTypes.UPDATE_PROFILE_JOURNAL_ENTRY:
      return updateProfileJournalSuccess(state, action);

    case actionTypes.RESET_DATA_LOGOUT:
      return resetDataLogout(state, action);

    case actionTypes.UPDATE_PATH_BY_INTENT:
      return updatePathByIntentResetJournalState(state, action);

    case actionTypes.UPLOAD_ATTACHMENTS_SUCCESS:
      return uploadAttachmentsSuccess(state, action);

    case actionTypes.CLEAR_ERROR:
      return clearError(state, action);

    // User Allergy
    case actionTypes.UPDATE_USER_ALLERGY:
      return updateUserAllergy(state, action);

    case actionTypes.DELETE_USER_ALLERGY:
      return deleteUserAllergy(state, action);

    // User Condition
    case actionTypes.UPDATE_USER_CONDITION:
      return updateUserCondition(state, action);

    case actionTypes.DELETE_USER_CONDITION:
      return deleteUserCondition(state, action);

    // User Symptom
    case actionTypes.UPDATE_USER_SYMPTOM:
      return updateUserSymptom(state, action);

    // User Metric
    case actionTypes.UPDATE_USER_METRIC:
      return updateUserMetric(state, action);

    //Selectable Metric
    case actionTypes.UPDATE_SELECTABLE_METRIC:
      return updateSelectableMetrics(state, action);

    //Care giver access
    case actionTypes.SHARED_ACCESS_INVITATION_SUCCESS:
      return sharedAccessInvitation(state, action);
    case actionTypes.DELETE_SHARED_ACCESS_INVITATION_SUCCESS:
      return deleteSharedAccessInvitation(state, action);
    case actionTypes.DELETE_SHARED_ACCESS_PERMISSION_SUCCESS:
      return deleteSharedAccessPermission(state, action);
    case actionTypes.DELETE_SHARED_ACCESS_PERMISSION_GIVEN_TO_ME_SUCCESS:
      return deleteSharedAccessPermissionGivenTomeSuccess(state, action);
    case actionTypes.UPDATE_SHARED_ACCESS_PERMISSION_SUCCESS:
      return updateSharedAccessPermission(state, action);
    case actionTypes.SHARED_ACCESS_INVITATION_ACCEPT_SUCCESS:
      return sharedAccessInvitationAcceptSuccess(state, action);
    case actionTypes.SHARED_ACCESS_INVITATION_DECLINE_SUCCESS:
      return sharedAccessInvitationDeclineSuccess(state, action);
    case actionTypes.SHARED_ACCESS_INVITATION_RESEND_SUCCESS:
      return sharedAccessInvitationResendSuccess(state, action);
    case actionTypes.SHARED_ACCESS_DISCONNECT_SUCCESS:
      return sharedAccessDisconnectSuccess(state, action);

    // Settings - metrics;
    case actionTypes.UPDATE_USER_METRICS:
      return updateMetricsSuccess(state, action);
    case actionTypes.UPDATE_USER_METRICS_UOM:
      return updateMetricsUomSuccess(state, action);
    case actionTypes.DELETE_METRIC_SUCCESS:
      return deleteMetricSuccess(state, action);

    //Settings - symptoms
    case actionTypes.ADD_USER_SYMPTOM:
      return addUserSymptom(state, action);
    case actionTypes.DELETE_SYMPTOM_SUCCESS:
      return deleteSymptomSuccess(state, action);
    case actionTypes.DELETE_SYMPTOM_FAIL:
      return deleteSymptomFail(state, action);
    case actionTypes.COMMON_ERROR_HANDLER:
      return commentErrorHandler(state, action);

    //dashboard graph
    case actionTypes.UPDATE_DASHBOARD_GRAPH_SUCCESS:
      return updateDashboardGraph(state, action);
    case actionTypes.DELETE_DASHBOARD_GRAPH_SUCCESS:
      return deleteDashboardGraph(state, action);

    //push notification
    case actionTypes.OVERDUE_ROUTINE_REMINDER_AND_MATCHED_CLINICAL_TRAIL:
      return overdueRoutineReminder(state, action);

    //clinical trial
    case actionTypes.ADD_CLINICAL_TRIAL:
      return createClinicalTrialSuccess(state, action);
    case actionTypes.ADD_CLINICAL_TRIAL_LIST:
      return createClinicalTrialListSuccess(state, action);

    case actionTypes.DELETE_CLINICAL_TRIAL_SUCCESS:
      return deleteClinicalTrialSuccess(state, action);
    case actionTypes.DELETE_ALL_CLINICAL_TRIALS_SUCCESS:
      return deleteAllClinicalTrialsSuccess(state, action);
    case actionTypes.DELETE_CLINICAL_TRIAL_LIST_SUCCESS:
      return deleteClinicalTrialListSuccess(state, action);

    case actionTypes.ADD_SAVED_SEARCH_CLINICAL_TRIAL:
      return updateSavedSearchClinicalTrialsSuccess(state, action);
    case actionTypes.UPDATE_SAVED_SEARCH_CLINICAL_TRIAL:
      return updateSavedSearchClinicalTrialsSuccess(state, action);
    case actionTypes.UPDATE_CLINICALTRAIL_DISCLAIMER_SUCCESS:
      return updateClinicaltrailDisclaimerSuccess(state, action);
    case actionTypes.SETUP_ON_SUCCESS:
      return updateIsSetupOnSuccess(state, action);
    case actionTypes.SET_TOTAL_ATTACHMENT_SIZE:
      return setTotalAttachmentSize(state, action);

    // iOS Subscription
    case actionTypes.FETCH_IOS_SUBSCRIPTION_DETAILS_SUCCESS:
      return fetchIosSubscriptionDetailsUpdateSuccess(state, action);

    //Dashboard Premium Banner hide
    case actionTypes.UPDATE_PREMIUM_BANNER_HIDE_SUCCESS:
      return updatePremiumBannerHideSuccess(state, action);

    //peer To peer
    case actionTypes.UPDATE_SHOW_PEER_PROFILE_SCREEN_SUCCESS:
      return updateShowPeerProfileScreenSuccess(state, action);
    case actionTypes.UPDATE_PEER_2_PEER_PROFILE:
      return updatePeer2PeerProfile(state, action);
    case actionTypes.UPDATE_PEER_2_PEER_MYNETWORK:
      return updatePeer2PeerMyNetwork(state, action);

    //upload progress
    case actionTypes.SET_ATTACHEMENT_UPLOAD_PROGRESS:
      return setAttachmentUploadProgress(state, action);
    case actionTypes.SET_ATTACHEMENT_UPLOAD_STATUS:
      return setAttachmentUploadStatus(state, action);
    case actionTypes.SET_ATTACHEMENT_ARTIFACT_ID:
      return setArtifactIdForAttachmentUploading(state, action);
    case actionTypes.SET_ATTACHEMENT_FULL_SIZE:
      return setAttachmentFullSize(state, action);
    case actionTypes.SET_ATTACHEMENT_UPLOAD_PROGRESS_TEMP:
      return setAttachmentUploadProgressTemp(state, action);
    case actionTypes.SET_ATTACHEMENT_UPLOAD_CURRENT_KEY:
      return setUploadingKey(state, action);
    case actionTypes.ON_TOUCH_ROUTINE:
      return onTouchRoutine(state, action);
    default:
      return state;
  }
};

export default reducer;
