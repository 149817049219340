import React from 'react';
import {View, Text, Image} from 'react-native';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import styles from '../../Css';
import { pendoTrackEvent } from '../../util/pendoConfig';

const {Popover} = renderers;

const routinePopupMenu = props => {
  return (
    <Menu renderer={Popover} rendererProps={{preferredPlacement: 'left'}}>
      <MenuTrigger onPress={() => pendoTrackEvent(props.eventNameMenuTrigger)}>
        <View 
          style={[styles.itemIconSingle, styles.itemSettingMenu]} 
          nativeID={props.nativeIdMenuTrigger}>
          <Image
            style={[styles.settingIcon, styles.settingIconDark]}
            source={require('../../assets/icons/settings.png')}
          />
        </View>
      </MenuTrigger>
      <MenuOptions style={[styles.dropDownList, styles.menuOptionsLg]}>
        <View style={{padding: 1}} nativeID={props.nativeIdCopytoNew}>
        <MenuOption style={styles.liItem} onSelect={props.handleCopyToNew}>
          <Text style={styles.listText} allowFontScaling= {false}>Copy to New</Text>
        </MenuOption>
        </View>
        {Boolean(props.canEdit) && (
          <View style={{padding: 1}} nativeID={props.nativeIdEdit}>
          <MenuOption style={styles.liItem} onSelect={props.handleEditJournal}>
            <Text style={styles.listText} allowFontScaling= {false}>Edit</Text>
          </MenuOption>
          </View>
        )}
        {Boolean(props.canDelete) && (
          <View style={{padding: 1}} nativeID={props.nativeIdDelete}>
          <MenuOption
            style={styles.liItem}
            onSelect={props.handleDeleteJournal}>
            <Text style={styles.listText} allowFontScaling= {false}>Delete</Text>
          </MenuOption>
          </View>
        )}

        {Boolean(props.canShowHide) && (
          <View style={{padding: 1}} nativeID={props.nativeIdPinToUnpinFromDashboard}>
          <MenuOption
            style={styles.liItem}
            onSelect={props.handleHideShowonDashboard}>
            {props.hideShow ? (
              <Text style={styles.listText} allowFontScaling={false}>Unpin from Dashboard</Text>
            ) : (
              <Text style={styles.listText} allowFontScaling={false}>Pin to Dashboard</Text>
            )}
          </MenuOption>
          </View>
        )}
      </MenuOptions>
    </Menu>
  );
};
export default routinePopupMenu;
