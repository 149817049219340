import React from 'react';
import {Image, View, Text, TouchableOpacity, ScrollView} from 'react-native';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import Header from '../../Header/header';
import SpaceBar from '../../SpaceBar/SpaceBar';

const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
const Disclaimer = props => {
  return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={false}
        handleOnPressBackButton={props.handleOnPressBackButton}
        index={props.index}
        navigation={props.navigation}
        route={props.route}
      />
      {/* <View style={[stylesRes.sectionWrapper, stylesRes.fixedScreen]}> */}
      <SpaceBar />
      <ScrollView style={styles.scrollViewArea}>
        <View style={styles.gridSection}>
          <View style={stylesRes.contentSectionFull}>
            <View>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.secArea}>
                    <View
                      style={[
                        {minHeight: props.windowHeightSmView},
                      ]}>
                      <View style={[styles.commonFullBlock, styles.bgpage]}>
                        <View style={styles.commonTopBlock}>
                          <View style={styles.commonTopImg}>
                            <View
                              style={[
                                stylesRes.commonTopImgView,
                                {
                                  width: props.topIconWidthImg,
                                  height: props.topIconHeightImg,
                                },
                              ]}
                              dataSet={{media: ids.commonTopImgView}}>
                              <Image
                                style={[styles.imgCover]}
                                source={require('../../../assets/setup/bg-intro-top.png')}
                              />
                            </View>
                          </View>
                        </View>

                        <View style={[styles.commonContentBlock]}>
                          <View style={styles.gridRow}>
                            <View style={styles.gridColFull}>
                              <View style={[styles.textAreaFull]}>
                                <View style={stylesRes.maxTextArea} dataSet={{media: ids.maxTextArea}}>
                                  <View
                                    style={[
                                      styles.textAreaView,
                                      styles.greyBorderThin,
                                      styles.colPdLtRtBot,
                                    ]}>
                                    <View
                                      style={[
                                        styles.titleBlockCom,
                                        styles.textSecPd,
                                      ]}>
                                      <Text
                                        style={[
                                          styles.textTitleMdBold,
                                          styles.textAlignCenter,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        Clinical Trials Search
                                      </Text>
                                    </View>
                                    <View
                                      style={[
                                        styles.titleBlockSub,
                                        styles.comSpSm,
                                      ]}>
                                      <Text
                                        style={[
                                          styles.textPrime,
                                          styles.textAlignCenter,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        Our service searchs ClinicalTrial.gov, a
                                        resource provided by the U.S National
                                        Library of Medicine.
                                      </Text>
                                    </View>
                                    <View style={[styles.titleBlockSub]}>
                                      <Text
                                        style={[
                                          styles.textPrime,
                                          styles.textAlignCenter,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        <Text style={[styles.bold]} allowFontScaling={false}>
                                          Liability Disclaimer:
                                        </Text>{' '}
                                        Under no circumstances whatsoever shall
                                        Zamplo be responsible for
                                        the information. The search results may
                                        be incomplete or not applicable. Consult
                                        your physician for clinical trial
                                        information.
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                                <View style={stylesRes.maxTextAreaCom} dataSet={{media: ids.maxTextAreaCom}}>
                                  <View
                                    style={[
                                      styles.textAreaView,
                                      styles.colPdTopLtRt,
                                    ]}>
                                    <View style={[styles.textBlockSub]}>
                                      <View style={styles.gridRow}>
                                        <View style={styles.gridColFull}>
                                          <View style={styles.fieldDetails}>
                                          <TouchableOpacity onPress={() => props.onPressAggree(!props.isAgree)}>
                                            <View
                                              style={[styles.checkBoxSection]}>
                                              <View style={styles.checkBoxView}>
                                                {props.iAgreeButton}
                                                {/* <TickButton
                                                      imagePath={"untickButton"}
                                                      // toggleTickCallback={() =>
                                                      //   props.toggleTickCallback(false)
                                                      // }
                                                    /> */}
                                              </View>
                                              <View
                                                style={styles.flexRow}>
                                                <Text
                                                  style={[
                                                    styles.descText,
                                                    styles.textAlignLeft,
                                                  ]}
                                                  allowFontScaling={false}
                                                  >
                                                  I acknowledge the
                                                  <Text style={[styles.bold]} allowFontScaling={false}>
                                                    {' '}
                                                    Liability Disclaimer.
                                                  </Text>
                                                </Text>
                                              </View>
                                            </View>
                                          </TouchableOpacity>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>

                        <View>
                          <View style={stylesRes.commonBotImgArea}>
                            <View
                              style={[
                                stylesRes.commonBotImgView,
                                {
                                  width: props.botIconWidthImg,
                                  height: props.botIconHeightImg,
                                },
                              ]}
                              dataSet={{media: ids.commonBotImgView}}>
                              <Image
                                style={styles.imgCover}
                                source={require('../../../assets/setup/bg-intro-bottom.png')}
                              />
                            </View>
                          </View>
                          <View style={stylesRes.btnNextResBlock}>
                            <View style={stylesRes.btnNextCom}>
                              <TouchableOpacity
                                onPressIn={props.handleOnNext}
                                disabled={props.wait}>
                                <View style={styles.btnNextIconSec}>
                                  {props.isAgree ? (
                                    <Image
                                      style={styles.nextCircleIon}
                                      source={require('../../../assets/icons/next-circle.png')}
                                    />
                                  ) : (
                                    <Image
                                      style={styles.nextCircleIon}
                                      source={require('../../../assets/icons/next-circle-disabled.png')}
                                    />
                                  )}
                                </View>
                              </TouchableOpacity>
                            </View>

                            <View style={stylesRes.botTextBlockLg} dataSet={{media: ids.botTextBlockLg}}>
                              <Text
                                style={[
                                  styles.textPrimeBold,
                                  styles.textAlignCenter,
                                ]}
                                allowFontScaling={false}
                                >
                                The service does not distribute your personal
                                information outside the system.
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
    // </View>
  );
};

export default (Disclaimer);
