import React, {Component} from 'react';
import {
  Text,
  View,
  Image,
  TextInput,
  TouchableOpacity,
  Platform,
} from 'react-native';
import UIText from '../../components/Text/text';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import AutoCompleteList from './AutoCompleteList';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import * as axiosCommon from '../../axios/axios-common';
import debounce from './vendor/throttle-debounce/debounce';
import ToolTip from '../../components/ToolTip';
import { WEB } from '../../util/platformWindowConfig';


class AutoComplete extends Component {
  constructor(props) {
    super(props);
    const {currentInput, remortSearch} = props;
    this.state = {
      textFieldWithTitle: props.textFieldWithTitle,
      textFieldTitle: props.textFieldTitle,
      textFieldAddCustomBtn: false,
      dataLoading: false,
      showList:
        props.medication &&
        props.medication === 'med' &&
        this.autoFocusMeds().length > 0
          ? true
          : false,
      inputText: currentInput ? currentInput : null,
      dataListFilter: [],
      dataUserListFilter:
        props.medication && props.medication === 'med'
          ? this.autoFocusMeds()
          : [],
      remortSearch: remortSearch ? remortSearch : false,
      focused: props.autoFocus,
    };
  }

  autoFocusMeds = () => {
    let listFilter = [];

    this.props.userTerms.filter(eachTerm => {
      if (
        eachTerm &&
        eachTerm.data.treatmentType !== 5 &&
        eachTerm.data.name !== null
      ) {
        listFilter.push(eachTerm);
      }
    });
    return listFilter;
  };

  static getDerivedStateFromProps(props, state) {
    const {currentInput} = props;

    if (currentInput !== state.inputText) {
      return {
        inputText: currentInput,
      };
    }
    return null;
  }

  onChangeText = currentInput => {
    const {updateAutocompleteOpen, medication} = this.props;
    if (updateAutocompleteOpen) {
      updateAutocompleteOpen(currentInput !== '' ? true : false);
    }
    if (currentInput !== '') {
      this.setState({
        inputText: currentInput,
        showList: true,
        dataLoading: true,
      });
    } else {
      this.setState({
        inputText: null,
        showList:
          medication && medication === 'med' && this.autoFocusMeds().length > 0
            ? true
            : false,
        dataLoading: false,
      });
    }

    this.props.onChangeText(currentInput);
    const findMatch = (term1, term2) =>
      term1.name && term1.name.toLowerCase().indexOf(term2.toLowerCase()) > -1;
    const inputIsEmpty = !!(currentInput.length <= 0);
    if (this.state.remortSearch) {
      //debounce(500, () => {
      let trigger = true;
      if (
        this.props.triggerLength &&
        currentInput.length < this.props.triggerLength
      ) {
        trigger = false;
      }

      const suggestionUserResults =
        this.props.userTerms && this.props.userTerms !== null
          ? this.props.userTerms.filter(eachTerm => {
              return findMatch(eachTerm, currentInput);
            })
          : [];

      medication &&
        medication === 'med' &&
        suggestionUserResults &&
        suggestionUserResults.length > 0 &&
        suggestionUserResults.forEach(item => {
          item.currentInput = currentInput;
        });

      if (this.props.remortSearch && trigger) {
        axiosCommon.axiosGET(this.props.url + currentInput, res => {
          let suggestionResults = [];
          if (res.type === 'SUCCESS') {
            suggestionResults = this.props.processData(res.responseDto.data);
          } else {
            suggestionResults = this.props.processData(null);
          }
          let showSuggestionResults = true;
          suggestionResults.forEach(item => {
            if (
              this.props.triggerLength &&
              item.currentInput &&
              item.currentInput.length < this.props.triggerLength
            ) {
              showSuggestionResults = false;
            }
          });
          this.setState({
            dataListFilter:
              inputIsEmpty || !showSuggestionResults ? [] : suggestionResults,
            dataUserListFilter: inputIsEmpty ? [] : suggestionUserResults,
            dataLoading: false,
          });
        });
      } else if (this.props.remortSearch && !trigger) {
        this.setState({
          dataListFilter: [],
          dataUserListFilter: suggestionUserResults
            ? suggestionUserResults
            : [],
          dataLoading: false,
        });
      } else {
        this.setState({
          dataListFilter: [],
          dataUserListFilter: [],
          dataLoading: false,
        });
      }
      // })();
    } else {
      debounce(500, () => {
        let trigger = true;
        if (
          this.props.triggerLength &&
          currentInput.length < this.props.triggerLength
        ) {
          trigger = false;
        }
        if (trigger) {
          const suggestionResults =
            this.props.terms && this.props.terms !== null
              ? this.props.terms.filter(eachTerm => {
                  return findMatch(eachTerm, currentInput);
                })
              : '';

          const suggestionUserResults =
            this.props.userTerms && this.props.userTerms !== null
              ? this.props.userTerms.filter(eachTerm => {
                  return findMatch(eachTerm, currentInput);
                })
              : '';

          this.setState({
            dataListFilter: inputIsEmpty ? [] : suggestionResults,
            dataUserListFilter: inputIsEmpty ? [] : suggestionUserResults,
            dataLoading: false,
          });
        } else {
          this.setState({
            dataListFilter: [],
            dataUserListFilter: [],
            dataLoading: false,
          });
        }
      })();
    }
  };

  onItemPress = (selectedItemData, type) => {
    this.props.onItemPress(selectedItemData, type);
    this.clear();
  };

  clear = () => {
    const {updateAutocompleteOpen} = this.props;
    if (updateAutocompleteOpen) {
      updateAutocompleteOpen(false);
    }
    this.setState({
      showList: false,
      dataListFilter: [],
      dataUserListFilter: [],
    });
  };

  onPressCustom = () => {
    this.props.onPressCustom(this.state.inputText, 'CUSTOM');
    this.clear();
  };

  hideList = () => {
    if (this.props.clearTextOnBlur) {
      this.props.onChangeText('');
    }
    this.setState({showList: false});
  };

  onFocus = () => {
    const {medication} = this.props;
    this.setState({
      showList:
        medication && medication === 'med' && this.autoFocusMeds().length > 0
          ? true
          : false,
      dataListFilter: [],
      dataUserListFilter:
        medication && medication === 'med' ? this.autoFocusMeds() : [],
      focused: true
    });
  };
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {
      systemListTitel,
      userListTitle,
      medication,
      updateTermList,
    } = this.props;
    return (
      <View style={[styles.autoCompleteWrapper]}>
        <View style={[styles.autoCompleteField]}>
          {Boolean(this.state.textFieldWithTitle) && (
            <View style={[styles.textView]}>
              <View style={[styles.flexRow]}>
                <UIText
                  style={[styles.textLabel, styles.bold, styles.textColorLight]}
                  title={this.state.textFieldTitle}
                  allowFontScaling={false}
                />
                {Boolean(this.props.toolTipButton) && (
                  <View style={[styles.helpCircleComView, styles.helpCircleMg]}>
                    <ToolTip
                      id={this.props.toolTipId}
                      text={this.props.toolTipContent}
                      disabledScroll={this.props.disabledScroll}
                    />
                  </View>
                )}
              </View>
            </View>
          )}

          <View style={[styles.autoCompleteBtnView]}>
            {Boolean(this.props.zoomIcon) && !Boolean(this.state.showList) && (
              <View style={[styles.btnActionTouch]}>
                <View style={[styles.autoCompleteIconView]}>
                  <Image
                    style={styles.searchZoomIcon}
                    source={require('../../assets/icons/search-zoom.png')}
                  />
                </View>
              </View>
            )}

            {/* Clear button section Start */}
            {Boolean(this.state.showList) && (
              <TouchableOpacity
                style={[styles.btnActionTouch]}
                onPress={() => {
                  if (updateTermList) {
                    updateTermList();
                  }
                  this.clear();
                  if (this.props.processData) {
                    this.props.processData(null);
                  }

                  this.props.onChangeText('');
                }}>
                <View style={[styles.autoCompleteIconView]}>
                  <Image
                    style={styles.autoCompleteClearIcon}
                    source={require('../../assets/icons/clear.png')}
                  />
                </View>
              </TouchableOpacity>
            )}

            {/* Add custon button section Start */}
            {/* {this.state.textFieldAddCustomBtn && ( */}
            {/* <View style={[styles.autoCompleteAdd]}>
              <TouchableOpacity>
                <View style={[styles.addCustomBtn]}>
                  <Text style={[styles.textMicSmBold, styles.addCustomBtnText]}>
                    Confirm
                  </Text>
                </View>
              </TouchableOpacity>
            </View> */}
            {/* )} */}
            {/* Add custon button section End */}
          </View>

          <TextInput
            style={[
              styles.textField,
              this.props.fieldBg === 'white'
                ? styles.bgWhite
                : this.props.fieldBg === 'grey'
                ? styles.bgThinGrey
                : styles.bgTransparent,
              this.state.textFieldWithTitle
                ? styles.textFieldRg
                : styles.textFieldSm,
              this.state.textFieldAddCustomBtn
                ? styles.textFieldAutoCompleteLg
                : styles.textFieldAutoCompleteSm,
              !this.props.validateName
                ? styles.validColor
                : styles.inValidColor,
              {
                ...Platform.select({
                  android: {
                    color: '#000000', //To fixed non-editable TextInput color issue in android(ZI-1077)
                  },
                }),
              },
            ]}
            allowFontScaling={false}
            underlineColorAndroid="transparent"
            value={this.state.inputText ? this.state.inputText : ''}
            onChangeText={this.onChangeText}
            autoFocus={this.props.autoFocus}
            editable={this.props.editable}
            maxLength={this.props.maxLength}
            onFocus={() => medication && medication === 'med' ? this.onFocus() : this.setState({focused: true})}
            onBlur={() => this.setState({focused: false})}
            selection={Platform.OS === WEB || this.state.focused ? undefined : {start: 0, end: 0}}
          />
        </View>

        {/* Suggestion Starting View Start */}
        {Boolean(this.state.showList) && (
          <View
            style={[
              stylesRes.autoCompleteDataCom,
              stylesRes.autoCompleteBgWhite,
              stylesRes.autoCompleteBorder,
                this.state.textFieldWithTitle
                  ? stylesRes.autoCompleteDataMgLg
                  : stylesRes.autoCompleteDataMgSm,
            ]}
            dataSet={{media: ids.autoCompleteDataCom}}>

            {/* autoCompleteSection Start */}
            {Boolean(this.props.showCustomSection) && (
              <View style={[styles.autoCompleteSection]}>
                <View
                  style={[
                    styles.autoCompleteTitleBlockCom,
                    styles.bgThinExLight,
                  ]}>
                  <Text style={styles.textPrimeExSmBold} allowFontScaling={false}>
                    {this.props.customSectionTitle}
                  </Text>
                </View>
                <View style={[styles.autoCompleteContList]}>
                  <View style={[styles.autoCompleteItemCom]}>
                    <TouchableOpacity onPress={this.onPressCustom}>
                      <View style={[styles.autoCompleteItemRow]}>
                        <View style={[styles.selectedItemRoundIcon]} />
                        <View style={[styles.selectedItemData]}>
                          <Text style={styles.textPrime} allowFontScaling={false}>
                            {this.state.inputText}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>
                {/* <View style={[styles.autoCompleteSecBorder]} /> */}
              </View>
            )}
            {/* autoCompleteSection End */}

            {/* autoCompleteSection Start */}
            {Boolean(this.props.userTerms) && this.props.userTerms !== null && (
              <React.Fragment>
                {this.state.dataLoading ? (
                  <View style={[styles.autoCompleteSection]}>
                    <ActivityIndicator size="small" />
                  </View>
                ) : (
                  <React.Fragment>
                    {this.state.dataUserListFilter &&
                      this.state.dataUserListFilter.length > 0 && (
                        <View style={[styles.autoCompleteSection]}>
                          <View
                            style={[
                              styles.autoCompleteTitleBlockCom,
                              styles.bgThinExLight,
                            ]}>
                            <Text style={styles.textPrimeExSmBold} allowFontScaling={false}>
                              {userListTitle
                                ? userListTitle
                                : 'Select from User List'}
                            </Text>
                          </View>
                          <View style={[styles.autoCompleteContList]}>
                            <View
                              style={[
                                styles.autoCompleteListHeight,
                                styles.autoCompleteScrollWrap,
                              ]}>
                              <AutoCompleteList
                                dataListFilter={this.state.dataUserListFilter}
                                onItemPress={val =>
                                  this.onItemPress(val, 'USER')
                                }
                                selectedValues={this.props.selectedValues}
                              />
                            </View>
                          </View>
                        </View>
                      )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {this.state.dataLoading ? (
              <View style={[styles.autoCompleteSection]}>
                <ActivityIndicator size="small" />
              </View>
            ) : (
              <React.Fragment>
                {this.state.dataListFilter &&
                this.state.dataListFilter.length > 0 ? (
                  <View style={[styles.autoCompleteSection]}>
                    <View
                      style={[
                        styles.autoCompleteTitleBlockCom,
                        styles.bgThinExLight,
                      ]}>
                      <Text style={styles.textPrimeExSmBold} allowFontScaling={false}>
                        {systemListTitel
                          ? systemListTitel
                          : 'Select from System List'}
                      </Text>
                    </View>
                    <View style={[styles.autoCompleteContList]}>
                      <View
                        style={[
                          styles.autoCompleteListHeight,
                          styles.autoCompleteScrollWrap,
                        ]}>
                        <AutoCompleteList
                          dataListFilter={this.state.dataListFilter}
                          onItemPress={val => this.onItemPress(val, 'SYSTEM')}
                          selectedValues={this.props.selectedValues}
                        />
                      </View>
                    </View>
                  </View>
                ) : null}
              </React.Fragment>
            )}

            {/* autoCompleteSection End */}
          </View>
        )}
        {/* Suggestion Starting View Start */}
        </View>
    );
  }
}

export default (AutoComplete);
