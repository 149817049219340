import React from 'react';
import {View, Text, Image} from 'react-native';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import styles from '../../Css';
import { pendoTrackEvent } from "../../util/pendoConfig";

const {Popover} = renderers;

const imagesDocsPopup = props => {
  return (
    // <View style={[styles.settingSec, styles.iconSettingMgLt]}>
    <Menu renderer={Popover} rendererProps={{preferredPlacement: 'left'}}>
      <MenuTrigger onPress={() => pendoTrackEvent(props.eventNameMenuTrigger)}>
        <View 
          style={[styles.itemIconSingle, styles.itemSettingMenu]}
          nativeID={props.nativeIdMenuTrigger}>
          <Image
            style={[
              styles.settingIcon,
              styles.settingIconDark,
              props.disableText
                ? styles.iconInactiveColor
                : styles.iconActiveTintColor,
            ]}
            source={require('../../assets/icons/settings.png')}
          />
        </View>
      </MenuTrigger>
      <MenuOptions style={styles.dropDownList}>
        <MenuOption style={styles.liItem} onSelect={props.showDeleteConfirm}>
          <Text style={styles.listText} allowFontScaling= {false}>Delete</Text>
        </MenuOption>
        {!Boolean(props.disableText) && (
          <MenuOption
            style={styles.liItem}
            onSelect={() =>
              props.downloadAttachment(props.attachmentId, props.fileName)
            }>
            <Text style={styles.listText} allowFontScaling= {false}>Download</Text>
          </MenuOption>
        )}
      </MenuOptions>
    </Menu>
    // {/* </View> */}
  );
};

export default imagesDocsPopup;
