import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import styles from "../../Css";
import Symptom from "./ReportGraphSymptom";
import { chartTypes } from '../../Constant/index';
import {sortAlphabeticalOrder} from '../../util/commonUiLogic';

class Symptoms extends Component {
  render() {
    return (
      <View style={[styles.collapseContent]}>
        <View style={[styles.collapseDescription]}>
          <View style={[styles.collapseInContent, styles.colPdLtRt]}>
            <View style={styles.secSubContentArea}>
              {sortAlphabeticalOrder(this.props.symptomsDigest.userSymptoms)
                .map(item => (
                  <Symptom
                    item={item}
                    key={"sy_" + item.id}
                    configChartType={item.displayProperties?.style || (item.displayProperties?.dashedLine === false ? chartTypes.dash : chartTypes.line)}
                  />
                ))}
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    symptomsDigestProcessed: state.routines.symptomsDigestProcessed,
    symptomsDigest: state.routines.symptomsDigest
  };
};

export default connect(
  mapStateToProps,
  null
)(Symptoms);
