import React, { useEffect, useState } from 'react';
import {
    SafeAreaView,
    LayoutAnimation,
    View,
    Text,
    ScrollView,
    UIManager,
    TouchableOpacity,
    Platform,
    Image,
} from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash'; 
import styles from '../../../Css';
import * as actions from '../../../store/actions';
import CategoriesFilterExpandableComponent from './ExpandableComponent/CategoriesFilterExpandableComponent';
import {sortAlphabeticalOrder} from '../../../util/commonUiLogic';
import { object } from 'underscore';
import { ANDROID } from '../../../util/platformWindowConfig';

const NewSelectCategoriesDropDown = (props) => {
    const { selectedAllCategoriesItems, onCategoryFilterItems, setOnOpenFilter, listTherapie,saveHealthJourneyCategoryObject,setHealthJourneyCategoryObject } = props;
    const { 
        setSelectCategories, 
        clearSelectCategories, 
        setSelectedCategoryObject,
        onCategoryApplyButtonEnabled,
        onCategoryApplyButtonDisable,
        onCategoryRemoveButtonEnabled,
        onCategoryRemoveButtonDisable,
        isCategoryApplyButtonDisabled,
        isCategoryRemoveButtonDisabled,
        setDropDownItemNumber,
        removeDropDownItemNumber,
     } = props;

    const [categoryList, setCaregoryList] = useState([]);
    const [isUncheckAll, setIsUncheckAll] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [loadSelectedCategoriesToView, setLoadSelectedCategoriesToView] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const FILTER_TAG = 1; 

    if (Platform.OS === ANDROID) {
        UIManager.setLayoutAnimationEnabledExperimental(true);
    }

    useEffect(() => {
        if(selectedCategories.length > 0){
            onCategoryApplyButtonEnabled();
            onCategoryRemoveButtonEnabled();
        } else {
            onCategoryApplyButtonDisable();
            onCategoryRemoveButtonDisable();
        }
        //eslint-disable-next-line
    }, [selectedCategories]);

    useEffect(() => {
        if(!_.isEqual(saveHealthJourneyCategoryObject.data, {}) && 
            (!_.isEmpty(selectedCategories))){
                onCategoryRemoveButtonEnabled();
                onCategoryApplyButtonDisable();
            }
            //eslint-disable-next-line
    }, [selectedCategories]);


    const createDropDownCategoryList = () => {
        // remove nulls from the array
        const therapieList = listTherapie.filter(function (item) { return item.name !== null && item.treatmentType !== 5 && item.isActive});
        const categoryObj = [
            {
                category_name: 'Documents/Images',
                value: 'ATTACHMENTS',
                subcategory: [],
            },
            {
                category_name: 'Meds/Supplements',
                value: 'MEDICATIONS',
                subcategory: sortAlphabeticalOrder(therapieList),
            },
            // {
            //     category_name: 'Symptoms',
            //     value: 'SYMPTOMS',
            //     subcategory: [],
            // },
            // {
            //     category_name: 'Health Data',
            //     value: 'HEALTH_DATA',
            //     subcategory: [],
            // },
            {
                category_name: 'Custom Notes',
                value: 'CUSTOM_NOTES',
                subcategory: [],
            },
        ];
        // setDropDownItemNumber(FILTER_TAG);
        setCaregoryList(categoryObj);
    }

    useEffect(() => {
        createDropDownCategoryList();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const tempCategories = [...loadSelectedCategoriesToView]
        if(!_.isEqual(saveHealthJourneyCategoryObject.data, {})){
            for(const selectedCategory of saveHealthJourneyCategoryObject.data){
                tempCategories.push(selectedCategory)
            }
        }
        setLoadSelectedCategoriesToView(tempCategories);
        setSelectedCategories(tempCategories);
        //eslint-disable-next-line
    }, []);

    const updateLayout = (index) => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        const array = [...categoryList];
        array.map((value, placeindex) =>
            placeindex === index
                ? (array[placeindex]['isExpanded'] =
                    !array[placeindex]['isExpanded'])
                : (array[placeindex]['isExpanded'] = false),
        );
        setCaregoryList(array);
    };

    const updateSelectedCategoriesObj = (obj, category) => {
      let newList = [...selectedCategories];
        if (obj.categoryName !== undefined && obj.categoryName !== null) {
            const existItem = newList.findIndex(item => item.categoryName === obj.categoryName);
            if (existItem !== -1) {
                // obj.subCategoryList.filter((item, i) =>
                //     existItem.subCategoryList[i] = item);
              newList[existItem].subCategoryList = obj.subCategoryList;
            } else {
                newList.push(obj);
            }
        } else {
          newList = [...newList.filter(item => item.value !== category)]
        }
        setSelectedCategories(newList);
        setIsUncheckAll(false);
    }

    const handleApplyOption = () => {
        setHealthJourneyCategoryObject(selectedCategories)
        setDropDownItemNumber(FILTER_TAG);
        setOnOpenFilter(false);
    }

    const clearAllCheckboxes = (param) => {
        clearSelectCategories();

        onCategoryApplyButtonDisable();
        onCategoryRemoveButtonDisable();
        
        setIsUncheckAll(true);
        setLoadSelectedCategoriesToView([]);
        setSelectedCategories([]);
        setIsButtonDisabled(true);

        removeDropDownItemNumber(FILTER_TAG);

        setHealthJourneyCategoryObject({});

        setOnOpenFilter(false);
    }

    const makeButtonsActive = () => {
      return !Boolean(selectedCategories.length > 0)
    }

    return (
        <SafeAreaView style={[styles.rgZero, styles.pstAb]}>
            <View style={[styles.addEvntBox, styles.selectCategoriesPopUpWrapper]}>
                    <View>
                        {/* Select Category Topic */}
                        <View style={[styles.menuSingleTextViewSm, styles.flexRow, styles.flexAiCt, styles.flexJcFs, styles.progressBarTopLg, styles.pdBtTwelve, styles.pdLtTwentyOne]}>
                            <Image
                                style={[{ width: 16, height: 16 }]}
                                source={require('../../../assets/health-journey-icons/category-icon.png')}
                            />
                            <Text style={[{ fontSize: 11, paddingLeft: 10 }]}> Select Categories: </Text>
                        </View>
                        <View>
                            <ScrollView>
                                {categoryList.map((item, key) => (
                                    <CategoriesFilterExpandableComponent
                                        key={item.category_name}
                                        onClickFunction={() => {
                                            updateLayout(key);
                                        }}
                                        item={{
                                          ...item,
                                          subcategory: [...new Map(item.subcategory.map(item => [item["name"], item])).values()]
                                        }}
                                        isUncheckedAll={isUncheckAll}
                                        setClearAllCheckboxes={(val) => clearAllCheckboxes(val)}
                                        getSelectedCategoriesObj={(obj) => updateSelectedCategoriesObj(obj, item.value)}
                                        selectedCategories = {selectedCategories.filter(res => res.value === item.value)}
                                    />
                                ))}
                            </ScrollView>
                        </View>
                        <View>
                            <View style={[styles.dateFilterLine]}></View>
                            {/* button */}
                            <View style={[styles.flexRow, styles.flexJcCt, styles.titleTopPd]}>
                                <TouchableOpacity  disabled = {makeButtonsActive()} onPress={() => clearAllCheckboxes()}>
                                    <View style={[styles.btnWdRemandApply, makeButtonsActive() ? styles.removeButtonDisabled : styles.removeButton]}>
                                        <Text style={[styles.textPrimeBold, makeButtonsActive() ? styles.headerTextCom : styles.textBlack]}> X Clear All</Text>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity  disabled = {makeButtonsActive()} onPress={() => handleApplyOption()}>
                                    <View style={[styles.btnWdRemandApply, makeButtonsActive() ? styles.applyButtonDisabled : styles.applyButton]}>
                                        <Text style={[styles.textPrimeBold, styles.textWhite, styles.postTwo]}> Apply </Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
            </View>
        </SafeAreaView>
    );
};

const mapStateToProps = state => {
    return {
        listTherapie: state.routines.treatments,
        setSelectedCategoryObject: state.healthJourneyFiltering.setSelectedCategoryObject,
        isCategoryApplyButtonDisabled: state.healthJourneyFiltering.isCategoryApplyButtonDisabled,
        isCategoryRemoveButtonDisabled: state.healthJourneyFiltering.isCategoryRemoveButtonDisabled,
        saveHealthJourneyTagsObject: state.healthJourney.saveHealthJourneyTagsObject,
        saveHealthJourneyCategoryObject: state.healthJourney.saveHealthJourneyCategoryObject,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSelectCategories: (categoryObject) => 
            dispatch(actions.setSelectCategories(categoryObject)),
        clearSelectCategories: () => 
            dispatch(actions.clearSelectCategories()),
        onCategoryApplyButtonEnabled: () => 
            dispatch(actions.onCategoryApplyButtonEnabled()),
        onCategoryApplyButtonDisable: () => 
            dispatch(actions.onCategoryApplyButtonDisable()),
        onCategoryRemoveButtonEnabled: () => 
            dispatch(actions.onCategoryRemoveButtonEnabled()),
        onCategoryRemoveButtonDisable: () => 
            dispatch(actions.onCategoryRemoveButtonDisable()),
        setDropDownItemNumber: (itemNumber) => 
            dispatch(actions.setDropDownItemNumber(itemNumber)),
        removeDropDownItemNumber: (itemNumber) => 
            dispatch(actions.removeDropDownItemNumber(itemNumber)),
        setHealthJourneyCategoryObject: (categoriesObj) =>
            dispatch(actions.setHealthJourneyCategoryObject(categoriesObj)),
            
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSelectCategoriesDropDown);
