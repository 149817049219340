import { Platform } from "react-native";
import theme from "../css/base/theme";
import * as variable from "../css/base/variable";
import * as variableCommon from "../css/base/variableCommon";

export const csshealthJourneyResponsive ={
    // Adding Events
    healthJourneyTxt: {
        ...variable.fontPrimary,
        fontSize: theme.FONT_SIZE_XXX_LG,
        fontWeight: "bold",
        color: theme.COLOR_GREY_DARK,

        "@media (min-width: 768px)": {
            fontSize: theme.FONT_SIZE_XXXXX_LG,
          }
    },

    // Options
    filtervisible: {
        marginRight:10,
        display:'flex',
        position:'absolute',
        left:20,

        "@media (min-width: 992px)": {
            display:'none'
          }
    },

    advanceFilterCont:{
        width: 145,
        display:'none',
        "@media (min-width: 992px)": {
          display:'flex',
          ...Platform.select({
            web: {
              display:'block', 
            },
          })
        }
    },

    allCategoriesCont: {
        width: 140, 
        display:'none',
        "@media (min-width: 992px)": {
          display:'flex',
          ...Platform.select({
              web: {
                display:'block', 
              }
            })
          }
    },

    dateSelectionCont: {
        ...Platform.select({
          web: {
            width: '40%',
          },
          ios: {
            width: '40%',
          },
          android: {
            width: '40%',
          }
        }),
        "@media (min-width: 600px)": {
          width:'46%'
        },
        "@media (min-width: 992px)": {
            width: 180,
          }
    },

    sortNewestCont:{
        ...Platform.select({
          web: {
            width: '45%',
          },
          ios: {
            width: '45%',
          },
          android: {
            width: '48%',
          }
        }),
        "@media (min-width: 600px)": {
          width: '49%'
        },
        "@media (min-width: 992px)": {
          width: 195,
          }
    },

    sortNewDropDownContAdj: {
      width:'97%',
      "@media (min-width: 600px)": {
        width:'98%'
      },
      "@media (min-width: 992px)": {
        width:'97%',
      }
    },

    sortToNewestLgAlign: {
      marginRight:18,
      "@media (min-width: 1990px)": {
        marginRight:0,
      }
    },
    sortNewestDropDown: {
        ...Platform.select({
          web: {
            width: '86%',
          },
          ios: {
            width: '79%',
            left:12,
          },
          android: {
            width: '80%',
            left:14,
          }
        }),
        "@media (min-width: 600px)": {
          width:'98%',
          ...Platform.select({
            web: {
              width: '95%',
            },
            android: {
              paddingRight: 20
            },
            ios: {
              paddingRight: 20
            },
          }),
        },
        "@media (min-width: 992px)": {
              width: 163,
            },
            
      "@media (orientation: landscape)": {
        ...Platform.select({
          android: {
            paddingRight: 20
          },
          ios: {
            paddingRight: 20
          },
        }),
      },
    },

    sortNewestDropDownTp: {
      ...Platform.select({
         web: {
            width: '100%',
         },
        ios: {
            width: '100%',
        },
      android: {
            width: '100%',
      }
      }),
      "@media (min-width: 600px)": {
            width:'97%'
      },
     "@media (min-width: 992px)": {
            width: 163,
      }
      },

    sortNwPdOp: {
        paddingRight: 5,
        paddingLeft: 5,
        "@media (min-width: 992px)": {
          paddingRight: 12,
          paddingLeft: 12,
          }
    },
    sortNwPdOpFr: {
      paddingRight: 5,
      paddingLeft: 5,
      "@media (min-width: 992px)": {
        paddingRight: 15,
        paddingLeft: 15,
        }
    },
    largerScrnVisibiliyOp:{
        display:'flex',
        "@media (max-width: 990px)": {
            display:'none'
          }
    },

    smlerScrnVisibilityOp: {
      display:'flex',
        "@media (min-width: 990px)": {
            display:'none'
          }
         

    },

    largeTosmallTextRes:{
      fontSize:11,
      "@media (min-width: 360px)": {
       fontSize:14,
      }
    },

   

    pdMbTwentyDecZero: {
      paddingLeft: 20,
      paddingRight: 20,
      "@media (min-width: 992px)": {
        paddingLeft: 20,
        paddingRight: 20,
        },
        "@media (min-width: 1990px)": {
          paddingRight:0
        }
  },

  pdMbTwentyLgDecZero: {
    paddingLeft: 20,
    paddingRight: 11,
    marginRight:5,
    "@media (min-width: 1950px)": {
      paddingLeft: 20,
      paddingRight:0,
      }
},

    sectionContainer: {
        borderWidth: 1, 
        borderColor:'#C4C4C4', 
        borderRadius:4, 
        height: 32, 
        flexDirection:'row', 
        alignItems:'center', 
        justifyContent:'center',
        marginRight: 5,
        marginLeft:5
    },

    //description text
    descriptionTruncate: {
        maxWidth: 530,
        "@media (min-width: 1440px)": {
            maxWidth: 730,
          }
    },

    addEventSidePopup: {
    ...variableCommon.positionFixed,
    zIndex: 100,
    bottom: 90,
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
    },

    yearCollapseCat:{
        position:'relative',
        bottom:0,
        marginTop:20,
        "@media (min-width: 1440px)": {
            position:'absolute',
            bottom: 4
        }
    },

    healthJourneyLtMg:{
        marginLeft: 20,
    },

    leftAlignRes:{
        left:0,
        "@media (min-width: 992px)": {
            left: 10
          }
    },

    pdLeftRem:{
        paddingLeft: 0,
        "@media (min-width: 992px)": {
            paddingLeft: 15,
          }
    },

    pdLefrRemLg:{
      paddingLeft: 20,
        "@media (min-width: 992px)": {
            paddingLeft: 30
          }
    },

    wdWrapperHelathJrn:{  
      width:'90%',
      ...Platform.select({
        web : {
          width:'89%'
        },
        ios: {
            width: '89%',
        },
      }),
      "@media (min-width: 768px)": {
        width:'95%',
      },
      "@media (min-width: 999px)": {
        width:'96%',
      },
      "@media (min-width: 1990px)": {
        width:'98%',
      },
    },

    txtTruncateWrapper: {
      maxWidth:'89%', 
      "@media (min-width: 768px)": {
        maxWidth:'97%',
      }
    },

    careGiveradLtPd: {
        paddingRight:0, 
        flexWrap:'wrap',
      "@media (min-width: 992px)": {
        paddingRight:20,
      }
    },

    rowColumn: {
      flexDirection:'column', 
      "@media (min-width: 768px)": {
        flexDirection:'row',
      }
    },

    wdAttachment: {
      width:180,
      "@media (min-width: 350px)": {
        width:220,
      },
      "@media (min-width: 992px)": {
        width:280
      }
    },

    mxWidthAttachmentNm: {
      maxWidth:80,
      "@media (min-width: 350px)": {
        maxWidth:120,
      },
      "@media (min-width: 992px)": {
        maxWidth:180,
      }
    },

    pdRtLgBlue: {
      paddingRight:0,
      "@media (min-width: 992px)": {
        paddingRight:30,
      }
    },

    medsPopUpWrapperRes: {
      width:290,
      left:-49,
      "@media (min-width: 766px)": {
        width:310,
        left:-20
      },
      "@media (min-width: 992px)": {
        width: 400,
        left:-20
        // left:20
      }
    },

    medsPopsNtViewWrapperRes: {
      width:310,
      left:-55,
      "@media (min-width: 766px)": {
        width:310,
        left:-19
      },
      "@media (min-width: 992px)": {
        width: 400,
        left:-20
        // left:20
      }
     
    },

    mxProductName: {
      maxWidth:220,
      "@media (min-width: 992px)": {
        maxWidth:310,
        // left:20
      }
    },

    pdMedsValRes: {
      paddingLeft:20,
      paddingRight: 10,
      "@media (min-width: 992px)": {
        paddingLeft:35,
        paddingRight: 25,
      }
    },

    wdTagContentRes: {
      width:200,
      "@media (min-width: 350px)": {
        width:250, 
      },
      "@media (min-width: 768px)": {
        width: 320, 
      }
    },

    dateFilterPos: {
      right:-155,
      ...Platform.select({
        web: {
          left: 0,
        },
        ios: {
          left: 12,
        },
        android: {
          left: 20,
        }
      }),
      "@media (min-width: 600px)": {
        right:28,
        },
      "@media (min-width: 992px)": {
        right:0,
        left:-285
        }
    },
    medsMbClmWebRow:{
      flexDirection:'column',
      "@media (min-width: 600px)": {
        flexDirection:'row', 
        flexWrap:'wrap',     
        },
    },  

    dateFilterWd: {
      ...Platform.select({
        web: {
          width: '99%',
        },
        ios: {
          width: '99%',
        },
        android: {
          width: '102%',
        }
      }),
      borderTopLeftRadius:0,
      borderTopRightRadius:0,
      borderBottomLeftRadius:8,
      borderBottomRightRadius:8,
      "@media (min-width: 399px)": {
        width:'104%',
        ...Platform.select({
          web: {
            width: '103%',
          },
        }),
        },
      "@media (min-width: 600px)": {
        width:'108%'
        },
      "@media (min-width: 992px)": {
      width:465,
      borderTopLeftRadius:0,
      borderTopRightRadius:0,
      }
    },

    advanceFilterOpWd: {
      display:'none',
      "@media (min-width: 992px)": {
        display:'flex', 
        ...Platform.select({
          web: {
            display:'block', 
          }
        }),
        width: 400,
        borderTopLeftRadius:0,
        borderTopRightRadius:0,
        borderBottomLeftRadius:8,
        borderBottomRightRadius:8,
      }
    },

    mgLtmbTenDescZero: {
      ...Platform.select({
        web: {
          marginLeft: 5,
        },
        ios: {
          marginLeft: 5,
        },
        android: {
          marginLeft: 0,
        }
      }),
      "@media (min-width: 992px)": {
        marginLeft:0
      }
    },

    mgLtmbThreeDescZero: {
      marginLeft: 3,
      "@media (min-width: 992px)": {
        marginLeft:0
      }
    },


    dividingClms: {
      flexDirection:'column',
      zIndex:-9,
      "@media (min-width: 992px)": {
        flexDirection:'row',
        }
    },

    dividingClmsCustom: {
      flexDirection:'column',
      justifyContent:'center',
      marginLeft:0,
      "@media (min-width: 992px)": {
        flexDirection:'row',
        justifyContent:'space-around',
        marginLeft:20,
        marginRight:25
        }
    },

    datePickerMbResFir: {
      width:'100%',
      justifyContent:'space-between',
      paddingLeft:20,
      paddingRight:30,
      "@media (min-width: 992px)": {
        width:'40%',
        justifyContent:'center',
        paddingLeft:0,
        paddingRight:0,
        }
    },

   customSelecPicker: {
      alignItems:'center',
      width:'100%',
      justifyContent:'space-between',
      paddingLeft:30,
      paddingRight:50,
      marginBottom:20,
      // zIndex:-999999,
      // backgroundColor:'white',
      "@media (min-width: 992px)": {
        width: '50%',
        paddingLeft: 30,
        paddingRight:0,
        justifyContent:'center',
        marginBottom: 0,


      }
   },

    datePickerMbResSec: {
      width:'100%',
      justifyContent:'space-between',
      paddingLeft:20,
      paddingRight:30,
      paddingTop:20,
      "@media (min-width: 992px)": {
        width:'50%',
        justifyContent:'center',
        paddingLeft:0,
        paddingRight:0,
        paddingTop:0
        }
    },

    dateFilterCnterPos: {
      justifyContent:'center',
      "@media (min-width: 992px)": {
        justifyContent:'flex-end',
        paddingRight: 25
        }
    },

    brdGreMbOnly: {
      borderBottomColor: theme.COLOR_BORDER,
      borderBottomWidth: 1,
      width:'100%',
      marginTop:5,
      marginBottom:20,
      "@media (min-width: 992px)": {
        display:'none'
      }
    },

    startDateEndDatePosMb: {
      position:'absolute', 
      bottom:-127, 
    },

    rgMinusFiftyOne: {
      right:122,
      top:0,
      "@media (min-width: 992px)": {
        right:0,
      }
    },

    medsDropdownWd: {
      width: 260,
      height: 50,
      "@media (min-width: 992px)": {
        width: 300,
      }
    },

    tgsPstRtBtZero: {
      
      "@media (min-width: 992px)": {
        position:'absolute', 
        right:0, 
        bottom: 0
      }
    },

    mgMbZeroDescThirty: {
      marginRight: 15,
      "@media (min-width: 992px)": {
        marginRight: 30
      }
    },

    mbZeroDescEightyFivePersRes: {
      maxWidth: '55%',
      "@media (min-width: 768px)": {
        maxWidth: '74%'
      },
      "@media (min-width: 992px)": {
        maxWidth: '85%'
      },


    },

    whOneThirtyTwoTwentyFiveRes:{
      width:125,

      "@media (min-width: 992px)": {
        width:132,
      }
    },

    ltSeventeenZeroRes: {
      left: 0,
      "@media (min-width: 992px)": {
        left: 15,
      }
    },

    descTwomgHundredSixty: {
      width:160,
      "@media (min-width: 992px)": {
        width: 200,
      }
    },

    textPadding: {
      paddingLeft: 10,
      ...Platform.select({
        web: {
          width: '90%',
        },
        ios: {
          width: '70%'
        },
        android: {
          width: '70%'
      }}),

      "@media (min-width: 992px)": {
        ...Platform.select({
          web: {
            width: 140,
          },
          ios: {
            width: '70%'
          },
          android: {
            width: '70%'
        }}),
      },
    },

    // textPaddingSubCategory: {
    //   paddingLeft: 10,
    //   width: '100%',

    //   "@media (min-width: 992px)": {
    //     width: '120%'
    //   }
    // },
    singleFilter:{
     
      "@media (min-width: 360px)": {
        marginLeft:0,
      },
      "@media (min-width: 992px)": {
        marginLeft:12,
      }
    },

    listTab:{
      "@media (min-width: 360px)": {
        marginLeft:0,
        justifyContent:'flex-end'
      },
      "@media (min-width: 1024px)": {
        marginLeft:15,
        justifyContent:'flex-start',
        
      
        
      },
      "@media (min-width: 992px)": {
        marginLeft:15,
        justifyContent:'flex-start',
        
      }
    }
};