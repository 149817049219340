import React, {Component} from 'react';
import { Keyboard } from 'react-native';
import * as validator from '../../validator/validator';
import ResetPasswordView from './ResetPasswordView';
import {connect} from 'react-redux';

class ResetPassword extends Component {
  state = {
    email: '',
    emailValidate: true,
    authSuccessSpinner: false,
    windowHeight: undefined,
    windowWidth: undefined,
    error: false,
    errorDescription: '',
  };

  onChangeEmail = email => {
    if (email && email.trim() !== '') {
      this.setState({
        error: false,
        errorDescription: null,
        emailValidate: true,
      });
    } else {
      this.setState({
        emailValidate: false,
      });
    }
    this.setState({
      //emailValidate: validator.validateEmail(email),
      email: email,
    });
  };

  onPressResetEmail = () => {
    Keyboard.dismiss();
    if (!this.state.email) {
      let er = 'This field is required';
      this.setState({
        ...this.state,
        error: true,
        errorDescription: er,
        emailValidate: false,
      });
    } else if (!validator.validateEmail(this.state.email.trim())) {
      let er = 'Error! Invalid email';
      this.setState({
        ...this.state,
        error: true,
        errorDescription: er,
        emailValidate: false,
        email: ''
      });
    } else if (validator.validateEmail(this.state.email.trim())) {
      this.setState({authSuccessSpinner: true});
      let email = this.state.email.trim();
      this.props.sendCode(email, (succcess, message) => {
        if (!succcess) {
          this.setState({
            error: true,
            errorDescription: message,
            authSuccessSpinner: false,
            emailValidate: false,
            email: '',
          });
        }
      });
    }
  };

  componentDidMount = () => {
    this.setState({
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {error, errorDescription, windowHeight, windowWidth} = nextProps;

    this.setState({
      ...this.state,
      error,
      errorDescription,
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    });
  }

  render() {
    return (
      <ResetPasswordView
        onChangeEmail={this.onChangeEmail}
        onPressResetEmail={this.onPressResetEmail}
        onPressLogin={this.props.onPressLogin}
        authSuccessSpinner={this.state.authSuccessSpinner}
        {...this.state}
        windowHeightView={this.props.windowHeightView}
        windowHeightSmView={this.props.windowHeightSmView}
        windowWidthView={this.props.windowWidthView}
        bgBlue={this.props.bgBlue}
        contentCenter={this.props.contentCenter}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(mapStateToProps)(ResetPassword);
