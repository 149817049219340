import { cssMainResponsive } from "./cssResponsive/mainResponsive";
import { cssScrollResponsive } from "./cssResponsive/scrollResponsive";
import { cssProfileResponsive } from "./cssResponsive/profileResponsive";
import { cssMedicationResponsive } from "./cssResponsive/medicationResponsive";
import { cssSubResponsive } from "./cssResponsive/subResponsive";
import { cssMenuResponsive } from "./cssResponsive/menuResponsive";
import { cssSliderResponsive } from "./cssResponsive/sliderResponsive";
import { cssUploadAttachmentResponsive } from "./cssResponsive/uploadAttachmentResponsive";
import { cssSurveyResponsive } from "./cssResponsive/surveyResponsive";
import { csshealthJourneyResponsive } from "./cssResponsive/healthJourneyResponsive";
import StyleSheet from 'react-native-media-query';

class Responsive {
  #stylesResMq = {
    ...cssMainResponsive,
    ...cssScrollResponsive,
    ...cssProfileResponsive,
    ...cssMedicationResponsive,
    ...cssSubResponsive,
    ...cssMenuResponsive,
    ...cssSliderResponsive,
    ...cssUploadAttachmentResponsive,
    ...cssSurveyResponsive,
    ...csshealthJourneyResponsive,
  };
  #processedData;
  constructor() {
    this.#processedData = StyleSheet.create(this.#stylesResMq);
  }

  getProcessedStyles = () => {
    return this.#processedData;
  }

  updateStyles = () => {
    this.#processedData = StyleSheet.create(this.#stylesResMq);
  }
}
const responsive = new Responsive();
export default responsive;
