import React from "react";
import { View, Image } from "react-native";
import styles from "../../Css";
import Picker from "../../components/Picker/picker";
import FieldTitle from "./fieldTitle";
import ValidationMessage from "./validationMessage";

const textPickerFrame = (props) => {
  let pickerFrame = 'PickerFrameLg';
  if(props.fieldSm){
     pickerFrame = 'PickerFrameSm';
  } else if (props.pickerSmall) {
    pickerFrame = 'PickerFrameWithBg';
  }
  return (
    <React.Fragment>
    <View
      style={[
        props.fieldSm ? styles.textInputSmWrapper : styles.textInputWrapper,
        styles.postRelative,
      ]}
    >
      <FieldTitle
        fieldAlign={props.fieldAlign}
        fieldBg={props.fieldBg}
        title={props.title}
        fieldSm={props.fieldSm}
        fieldLt={props.fieldLt}
        requirdStar={props.requirdStar}
        darkTxt={props.darkTxt}
      />

      <View
        style={[
          props.fieldSm ? styles.textInputFieldPickerSm : styles.textInputFieldPicker,
          props.activitySlider && styles.pickerWidth,
          props.fieldBg === "grey"
            ? styles.bgThinGrey
            : props.fieldBg === "blue"
            ? styles.bgBlueExLight
            : props.fieldBg === "transparent"
            ? styles.bgTransparent
            : styles.bgWhite,
           props.invalid ? styles.inValidColor : styles.validColor
        ]}
      >
        <View
          style={[
            props.disabled && {opacity: 0.5},
            styles.pickerFrameCom,
            props.pickerSmall
              ? styles.pickerFrameWrapper
              : styles.textPickerFrameFull,
            // props.invalid ? styles.inValidColor : styles.validColor,
          ]}
        >
          <View
            style={[styles.dropArrowFrameView, styles.dropArrowFrameCent]}
            pointerEvents={"none"}
          >
            <View style={[styles.dropArrowFrameIcon]}>
              <Image
                style={[styles.imgContain]}
                source={require("../../assets/icons/dropdown-arrow.png")}
              />
            </View>
          </View>
          <Picker
            style={[
              styles.textPickerSelection,
              props.fieldSm
                ? styles.textPickerSelectionSm
                : props.pickerSmall
                ? [
                    styles.textPickerInFrame,
                    styles.submitBtnShadow,
                    styles.borderRadSm,
                  ]
                : styles.textPickerSelectionRg,
            ]}
            allowFontScaling= {false}
            pickerFrame={pickerFrame}
            {...props}
          />
        </View>
      </View>
    </View>
    {props.invalid && (
    <ValidationMessage/>
    )}
    </React.Fragment>
  );
};

export default textPickerFrame;
