import React, {Component} from 'react';
import {View, Platform} from 'react-native';
import * as actions from '../../../../store/actions/index';
import styles from '../../../../Css';
import {connect} from 'react-redux';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextFrame from '../../../TextInput/textFrame';
import TextPickerFrame from '../../../TextInput/textPickerFrame';
import ItemDeleteButton from '../../../Button/itemDeleteButton';
import TextInputQuantityFrame from '../../../TextInput/textInputQuantityFrame';
import {decimalRegex, autoSuggestionListType} from '../../../../Constant';
import AutoCompleteSuggestion from '../../../AutoComplete/AutoCompleteSuggestion';
import {zIndexWorkaround, getUnits, getMeasurementUnits} from '../../../../util/commonUiLogic';
import { WEB } from '../../../../util/platformWindowConfig';


class SystemItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.data.name,
      unit: this.props.data.metricDisplayUnitOfMeasure.uomId,
      selectedUnitName: this.props.data.userMetricLog.unitName,
      typeOfMeasurement: this.props.data.typeOfMeasurement,
      accept: false,
      wait: false,
      value: this.props.data.userMetricLog.value,
      unitsOfArray: [],
      unitName: this.props.data.userMetricLog.unitName,
      unitValueChanging: false,
    };
  }

  componentDidMount() {
    const {unitName, unit, typeOfMeasurement} = this.state;
    this.props.getSystemUnitOfMeasure((res, data) => {
      if (res) {
        // this.setState(prevState =>({
        //   unitsOfArray: this.getUnitsOfList(data),
        //   unitName : prevState.unit ? data.filter(item => item.id === prevState.unit)[0].symbol : ''
        // }));
        // TODO: ZI-883 remove old codes
        // this.props.updateHealthData(this.props.index, {
        //   ...this.props.data,
        //   typeOfMeasurement: data[0].typeOfMeasurement,
        //   metricDisplayUnitOfMeasure: {
        //     ...this.props.data.metricDisplayUnitOfMeasure,
        //     uomId: data && data[0] ? data[0].id : null,
        //   },
        //   userMetricLog: {
        //     ...this.props.data.userMetricLog,
        //     unitOfMeasure: data && data[0] ? data[0].id : null,
        //   },
        // });

        let units = this.getUnitsOfList(data);
        let unitNameNew = unitName ? unitName : units && units.length > 0 ? units[0]?.symbol : '';
        this.setState({
          unitsOfArray: units,
          unitName : unitNameNew,
          unit: unit ? unit : units && units.length > 0 ? units[0]?.id : '',
          selectedUnitName: unitName ? unitName : units && units.length > 0 ? units[0]?.symbol : '',
        });

        this.props.updateHealthData(this.props.index, {
          ...this.props.data,
          typeOfMeasurement: typeOfMeasurement ? typeOfMeasurement : units && units.length > 0 ? units[0]?.typeOfMeasurement : null,
          metricDisplayUnitOfMeasure: {
            ...this.props.data.metricDisplayUnitOfMeasure,
            uomId: unit ? unit : units && units.length > 0 ? units[0]?.id : null,
          },
          userMetricLog: {
            ...this.props.data.userMetricLog,
            unitOfMeasure: unit ? unit : units && units.length > 0 ? units[0]?.id : null,
            unitName : unitNameNew,
          },
        });
      }
    });
  }


  static getDerivedStateFromProps(props, state) {
    const {
      onTouchRoutineFlag,
      index,
      error,
      updateHealthData,
      data,
      removeHealthdataErrors,
      onTouchRoutine,
      end,
    } = props;
    const {unitName, selectedUnitName, unit} = state;
    if (end && end === 0) {
      onTouchRoutine(false);
    } else if(end && end > 0) {
      if (index === end - 1) {
        onTouchRoutine(false);
      }
    } else {
      return null;
    }
    if (onTouchRoutineFlag && !unitName && selectedUnitName) {
      updateHealthData(index, {
        ...data,
        error: false,
      });
      if (error) {
        removeHealthdataErrors(index);
      }
      return {
        unitName: selectedUnitName,
        unit,
      }
    }

    return null;
  }

  getUnitsOfList = unitsArray => {
    let unitList = [];
    if (this.props.data.typeOfMeasurement) {
      unitList = getUnits(unitsArray, this.props.data.typeOfMeasurement);
    } else {
      unitList = getUnits(unitsArray, null);
    }
    return unitList;
  };

  onChangeTextName = currentInput => {
    this.setState({
      unitName: currentInput,
    });
    const {
      index,
      data,
      updateHealthData
    } = this.props;
    if (!currentInput) {
      this.setState({
        //value: null,
        optionValue: null,
        //unit: null,
      });
      updateHealthData(index, {
        //id: -1,
        ...data,
        error: true,
        // typeOfMeasurement: null,
        // metricDisplayUnitOfMeasure: {
        //   ...data.metricDisplayUnitOfMeasure,
        //   uomId: null,
        // },
        // userMetricLog: {
        //   ...data.userMetricLog,
        //   unitOfMeasure: null,
        //   value: null,
        // },
      });
    }
    if (this.props.error) {
      this.props.removeHealthdataErrors(index);
    }
  };

  onValueChange = val => {
    const {data: {id, options, symbol}} = val;
    const {
      unitsOfMeasureDigestProcessed,
      index,
      data,
      updateHealthData,
    } = this.props;
    
    let {value} = this.state;
    if ((parseInt(id) === 4 && value > 10) || (parseInt(id) === 20 && value > 5)) {
      value = null;
    }
    const {unitsOfMeasure} = unitsOfMeasureDigestProcessed;
    const initValue = options && options.length > 0 ? options[0].id : null;
    this.setState(
      {
        unitName: symbol,
        typeOfMeasurement: unitsOfMeasure[id].typeOfMeasurement,
        value: value,
        optionValue: initValue,
        uomOption: initValue,
        unit: id,
        selectedUnitName: symbol,
      },
      () => {
        updateHealthData(index, {
          //id: -1,
          ...data,
          error: false,
          typeOfMeasurement: unitsOfMeasure[id].typeOfMeasurement,
          metricDisplayUnitOfMeasure: {
            ...data.metricDisplayUnitOfMeasure,
            uomId: id,
          },
          userMetricLog: {
            ...data.userMetricLog,
            unitOfMeasure: id,
            value: initValue ? initValue : value,
            uomOption: initValue,
            valueType: initValue ? "OPTION" : "DEFAULT",
            unitName: symbol,
          },
        });
        if (this.props.error) {
          this.props.removeHealthdataErrors(index);
        }
      },
    );
  };

  onChangeTextQuantity = val => {
    const {value, unit} = this.state;
    const {updateHealthData, index, data} = this.props;
    let pass = true;
    if (!isNaN(val) && val.match(decimalRegex) && ((parseInt(unit) === 4 && val > 10) || (parseInt(unit) === 20 && val > 5))) {
      pass = false;
    } else if (isNaN(val) || !val.match(decimalRegex)) {
      pass = false;
    }
    if (pass) {
      this.setState({value: val ? val : null}, () =>
        updateHealthData(index, {
          ...data,
          userMetricLog: {
            ...data.userMetricLog,
            value: val ? val : null,
          },
        }),
      );
    } else {
      this.setState({value: value}, () =>
        updateHealthData(index, {
          ...data,
          userMetricLog: {
            ...data.userMetricLog,
            value: parseFloat(value),
          },
        }),
      );
    }
  };

  onSetQuantity = val => {
    const {updateHealthData, index, data} = this.props;
    this.setState({optionValue: val, unitValueChanging: true}, () => {
      updateHealthData(index, {
        ...data,
        userMetricLog: {
          ...data.userMetricLog,
          value: val,
          uomOption: val,
        },
      });
      this.setState({unitValueChanging: false});
    });
  };

  changeHealthDataName = (val) => {
    const {updateHealthData, index, data} = this.props;
    this.setState(
      () =>
      updateHealthData(index, {
          ...data,
          name: val,
        }),
    );
  }

  renderInputfield = () => {
    const uomId = this.props?.data?.metricDisplayUnitOfMeasure?.uomId;
    const measurement = this.props.measurementUnits.filter(item => item.id === uomId)?.[0]
    const {value} = this.state;
    let component = null;
    
    component = (
      <TextInputQuantityFrame
        title={'Quantity'}
        fieldBg={FieldDetails.BGWHITE}
        fieldAlign={FieldDetails.ALIGNCENTER}
        value={value || value === 0 ? value.toString() : ''}
        // value={this.state.value}
        keyboardType={Platform.OS === WEB ? 'default' : 'numeric'}
        onChangeText={this.onChangeTextQuantity}
        maxLength={9}
      />
    );

    if (measurement && measurement.options && measurement?.options.length > 0) {
      const items = [];
      measurement.options.forEach(element => {
        items.push({
          label: element.name, 
          value: element.id, 
          key: element.id,
        })
      });
      component = (
        <TextPickerFrame
          title={'Measure'}
          fieldBg={FieldDetails.BGWHITE}
          items={items}
          selectedValue={this.state.optionValue}
          onValueChange={this.onSetQuantity}
        />
      );
    }
    return component;
  }

  updateTermList = () => {
    this.setState({unit: null, selectedUnitName: null})
  }

  render() {
    const {unitsOfArray, unit} = this.state;
    const {deleteItem, index, category, error, metricsCount, updateHealthData, data, removeHealthdataErrors} = this.props;
    const {name} = data;

    const fieldBg = data.type === 'CUSTOM' ? (name === '' || name === null || name === undefined) && FieldDetails.BGRED : FieldDetails.BGGREY;

    return (
      <>
        <View
          style={[
            fieldBg === FieldDetails.BGRED ? styles.fieldContainerLrg : category === 'RT' ? styles.fieldContainer : styles.fieldContainerCom,
            zIndexWorkaround(index, metricsCount)
          ]}>
          <View style={[styles.fieldRowWrapperSm, zIndexWorkaround(index, metricsCount)]}>
            <View style={styles.fieldColStretch}>
              <TextFrame
                title={'Health Data'}
                fieldBg={data.type === 'CUSTOM' ? (name === '' || name === null || name === undefined) && FieldDetails.BGRED : FieldDetails.BGGREY}
                fieldValue={name}
                noOfLines={1}
                onChangeText={this.changeHealthDataName}
                editable={data.type === 'CUSTOM' ? true : false}
                textPosition={"left"}
              />
            </View>
            {category === 'RT' && (
              <>
                <View style={styles.fieldColSp} />
                <View style={[styles.fieldColPickerCom, zIndexWorkaround(index, metricsCount)]}>
                  {/* TODO: ZI-883 remove old codes
                  <TextPickerFrame
                    title={'Select Measure'}
                    fieldBg={FieldDetails.BGGREY}
                    pickerSmall={true}
                    items={unitsOfArray}
                    selectedValue={unit}
                    onValueChange={this.onValueChange}
                  /> */}
                  <AutoCompleteSuggestion
                    textFieldWithTitle={true}
                    textFieldTitle="Unit of Measure"
                    fieldBg={FieldDetails.BGWHITE}
                    remortSearch={false}
                    //triggerLength={1}
                    onChangeText={this.onChangeTextName}
                    onItemPress={this.onValueChange}
                    currentInput={this.state.unitName}
                    autoFocus={false}
                    userTerms={getMeasurementUnits(unitsOfArray, unit)}
                    zoomIcon={true}
                    removeIcon={true}
                    autoDisplayUserList={true}
                    showCustomSection={false}
                    showOtherResults
                    type={autoSuggestionListType.health_data_uom}
                    //showCloseAfterPressItem={Boolean(this.state.unitName)}
                    removeSelectedIcon
                    validateName={error}
                    placeholder="Search..."
                    typeAhead={this.props.measurementTypes}
                    selectedValue={this.state.unit}
                    onBlur={() => {
                      this.setState(prevState => ({
                        unitName: prevState.selectedUnitName,
                        unit: prevState.unit,
                      }));
                      updateHealthData(index, {
                        ...data,
                        error: false,
                      });
                      if (error) {
                        removeHealthdataErrors(index);
                      }
                    }}
                    updateTermList = {this.updateTermList}
                  />
                </View>
              </>
            )}
            <View style={styles.fieldColSp} />
            <View style={[styles.fieldColDelete]}>
              <ItemDeleteButton touchableOnPress={() => deleteItem(index)} />
            </View>
          </View>
        </View>
        
        {category !== 'RT' && (
          <View style={[styles.fieldContainer, zIndexWorkaround(index, metricsCount)]}>
            <View style={[styles.fieldRowWrapper, zIndexWorkaround(index, metricsCount)]}>
              <View style={styles.fieldColPlusMinus}>
                {this.renderInputfield()}
                {/* 
                TODO: ZI-883 remove old codes
                <TextInputQuantityFrame
                  title={'Quantity'}
                  fieldBg={FieldDetails.BGWHITE}
                  fieldAlign={FieldDetails.ALIGNCENTER}
                  value={value || value === 0 ? value.toString() : ''}
                  keyboardType={Platform.OS === 'web' ? 'default' : 'numeric'}
                  onChangeText={this.onChangeTextQuantity}
                /> */}
              </View>
              <View style={styles.fieldColSp} />
              <View style={[styles.fieldColPickerCom, zIndexWorkaround(index, metricsCount)]}>
                {/* 
                // TODO: ZI-883 remove old codes
                <TextPickerFrame
                  title={'Select Measure'}
                  fieldBg={FieldDetails.BGGREY}
                  pickerSmall={true}
                  items={unitsOfArray}
                  selectedValue={unit}
                  onValueChange={this.onValueChange}
                /> */}
                <AutoCompleteSuggestion
                  textFieldWithTitle={true}
                  textFieldTitle="Unit of Measure"
                  fieldBg={FieldDetails.BGWHITE}
                  remortSearch={false}
                  //triggerLength={1}
                  onChangeText={this.onChangeTextName}
                  onItemPress={this.onValueChange}
                  currentInput={this.state.unitName}
                  autoFocus={false}
                  userTerms={getMeasurementUnits(unitsOfArray, unit)}
                  zoomIcon={true}
                  removeIcon={true}
                  autoDisplayUserList={true}
                  showCustomSection={false}
                  showOtherResults
                  type={autoSuggestionListType.health_data_uom}
                  //showCloseAfterPressItem={Boolean(this.state.unitName)}
                  removeSelectedIcon
                  validateName={error}
                  placeholder="Search..."
                  typeAhead={this.props.measurementTypes}
                  selectedValue={this.state.unit}
                  onBlur={() => {
                    this.setState(prevState => ({
                      unitName: prevState.selectedUnitName,
                      unit: prevState.unit,
                    }));
                    updateHealthData(index, {
                      ...data,
                      error: false,
                    });
                    if (error) {
                      removeHealthdataErrors(index);
                    }
                  }}
                  updateTermList = {this.updateTermList}
                />
              </View>
            </View>
          </View>
        )}
        <View style={[styles.secSepBorderBot, styles.comSp]} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    unitsOfMeasureDigest: state.routines.unitsOfMeasureDigest,
    journalUserId: state.uistate.journalUserId,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    measurementUnits: state.routines.unitsOfMeasureDigest.unitsOfMeasure,
    measurementTypes: state.routines.unitsOfMeasureDigest.measurementTypes,
    onTouchRoutineFlag: state.routines.onTouchRoutineFlag,
    hasInvalidNamesInActivity: state.therapie.hasActivityInvalidName,
    hasInvalidNamesInMedication: state.therapie.hasMedicationInvalidName,
    hasInvalidNamesInHealthData: state.therapie.hasHealthDataInvalidName,
    hasInvalidNamesInSymtoms: state.therapie.hasSymptomsInvalidName,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createNewUserMetric: (metric, journalUserId, callBack) =>
      dispatch(actions.createNewUserMetric(metric, journalUserId, callBack)),
    createNewUserMetricUOM: (
      metricDefaultUnitOfMeasure,
      journalUserId,
      callBack,
    ) =>
      dispatch(
        actions.createNewUserMetricUOM(
          metricDefaultUnitOfMeasure,
          journalUserId,
          callBack,
        ),
      ),
    createUserMetric: (userMetrics, metricUOM, callback) =>
      dispatch(actions.createUserMetric(userMetrics, metricUOM, callback)),
    getSystemUnitOfMeasure: callBack =>
      dispatch(actions.getSystemUnitOfMeasure(callBack)),
    onTouchRoutine: flag =>
      dispatch(actions.onTouchRoutine(flag)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemItem);
