import React, { Component } from 'react';
import { View } from 'react-native';
import styles from '../../Css';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import SurveyQuestion from './SurveyQuestions/SurveyQuestion';
import { DASHBOARD, SURVEYS } from '../../navigation/path';
import Header from '../../components/Header/header';
import answerGenerator from './SurveyQuestions/utils/answerGenerator';
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { QUESTION_DIRECTION } from './SurveyQuestions/utils/questionUtilConstants.js';




class SurveyQuestionScreen extends Component {
  constructor(props) {
    super(props);
    const from = this.props.route.params?.from;
    if (!from) {
      setTimeout(() => {
        this.props.navigation.navigate(SURVEYS.path)
      }, 1000);
    }
    this.state = {
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
      isFrom: from,
      surveyId: null,
      notificationId: null,
      isReadOnly: false,
      /**
       * Values should matched with
       * Representation of QUESTION_DIRECTION. Values are 'PREV', 'NEXT' and 'SUBMIT'
       */
      questionDirection: null,
      tabDevice: this.props.tabDevice,
      tabMenuIconView: this.props.tabMenuIconView
    };
  }

  componentDidMount = () => {
    const { fetchSurveyQuestion, route, hideMobileMenu } = this.props;
    const { params } = route;
    this.props.navigation.addListener('focus', () => {
      if (params?.surveyId) {
        this.setState({
          surveyId: params.surveyId,
          notificationId: params?.notificationId || null,
          isReadOnly: (params?.isReadOnly)
        });
        fetchSurveyQuestion(params?.surveyId, null, params?.notificationId, params?.surveyStatus, params?.questionOrder);
      }

      hideMobileMenu();
      pendoTrackEvent('Surveys/@ViewSurvey');
    });
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    });
  }

  componentWillUnmount = () => {
    const { showMobileMenu } = this.props;
    showMobileMenu();
  };

  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth, tabDevice, tabMenuIconView, surveyQuestion, answerSaved, saveHealthSurveyAnswerReset, fetchSurveyQuestion, navigation } = props;
    const { questionDirection, surveyId, isReadOnly, notificationId, isFrom } = state;

    let updatedState = {}

    if (
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth ||
      tabDevice !== state.tabDevice ||
      tabMenuIconView !== state.tabMenuIconView
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        tabDevice: tabDevice,
        tabMenuIconView: tabMenuIconView
      };
    }

    if ((!isReadOnly && answerSaved.data) || (isReadOnly && !answerSaved.data)) {
      if (questionDirection === QUESTION_DIRECTION.prev || questionDirection === QUESTION_DIRECTION.next) {
        fetchSurveyQuestion(surveyId, null, notificationId, null, surveyQuestion.data?.healthSurveyQuestion?.questionOrder, questionDirection);
      }
      if (questionDirection === QUESTION_DIRECTION.submit) {
        saveHealthSurveyAnswerReset();
        if (state.isReadOnly) {
          navigation.push(SURVEYS.path, {
            getPath: "COMPLETED",
          });
        } else {
          if (isFrom && isFrom === DASHBOARD.path) {
            navigation.reset({
              index: 0,
              routes: [{ name: DASHBOARD.path }],
            });
          } else {
            navigation.push(SURVEYS.path);
          }
        }
      }
      updatedState = {
        ...updatedState,
        questionDirection: null,
        answer: null
      };
    }

    if (Object.keys(updatedState).length) {
      return updatedState;
    }
    return null;
  }

  redirectSurveys = isSubmit => {
    if (
      (this.state.isFrom && this.state.isFrom === SURVEYS.path) ||
      (this.state.isFrom &&
        this.state.isFrom === SURVEYS.mainSurveyPath &&
        isSubmit)
    ) {
      this.props.setActiveTab(1);
    } else if (
      this.state.isFrom &&
      this.state.isFrom === SURVEYS.mainSurveyPath
    ) {
      this.props.setActiveTab(0);
    }
    this.props.navigation.push(SURVEYS.path, {
      from: this.props.route.name,
    });
  };

  /**
   * This saves the answer and set the direction to be navigated.
   * After saved success, navigation will occur.
   * @param {*} navigateType should be 'PREV', 'NEXT', 'SUBMIT'
   */
  handleSaveAnswer = (questionDirection, answer, skipped = false) => {
    const { surveyQuestion, saveHealthSurveyAnswer } = this.props;
    const { notificationId, surveyId, isReadOnly } = this.state;

    this.setState({ questionDirection });

    if (!isReadOnly) {
      let isLastQuestion = (questionDirection === QUESTION_DIRECTION.submit);
      let tempAnswerToSave = answerGenerator.generateSavingAnswer(
        answer,
        surveyQuestion.data?.healthSurveyQuestion?.questionType,
        surveyId,
        surveyQuestion.data?.healthSurveyQuestion?.id,
        notificationId,
        isLastQuestion,
        surveyQuestion.data?.healthSurveyQuestion?.questionOrder,
        surveyQuestion.data?.healthSurveyQuestion?.measureType,
        skipped);

      saveHealthSurveyAnswer(tempAnswerToSave);
    }
  };

  handleSkipQuestion = (questionDirection) => {
    this.handleSaveAnswer(questionDirection, null, true);
  }

  render() {
    const { surveyQuestion, answerSaved, navigation, surveyQuestionFetching, hasNotch, journalUserId, route } = this.props;
    const { isReadOnly, questionDirection, tabDevice, tabMenuIconView } = this.state;



    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          navigation={navigation}
          route={route}
          index={SURVEYS.index}
          handleOnPressBackButton={() => {
            if (this.state.isReadOnly) {
              navigation.push(SURVEYS.path, {
                getPath: "COMPLETED",
              });
            } else {
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: SURVEYS.path }],
              });
            }
          }}
        />
        <SpaceBar />
        {/* <SurveyQuestion redirectSurveys={this.redirectSurveys} /> */}
        {Boolean(surveyQuestionFetching) && <View style={styles.loaderCom}>
          <ActivityIndicator size="large" />
        </View>}
        {Boolean(surveyQuestion.data) &&
          <SurveyQuestion
            questionDirection={questionDirection}
            windowHeight={this.props.windowHeight}
            windowWidth={this.props.windowWidth}
            tabDevice={tabDevice}
            tabMenuIconView={tabMenuIconView}
            question={surveyQuestion.data}
            answerSaved={answerSaved}
            onShowPrevQuestion={(ans) => this.handleSaveAnswer(QUESTION_DIRECTION.prev, ans)}
            onShowNextQuestion={(ans) => this.handleSaveAnswer(QUESTION_DIRECTION.next, ans)}
            onSubmit={(ans) => this.handleSaveAnswer(QUESTION_DIRECTION.submit, ans)}
            onSkip={(direction) => this.handleSkipQuestion(direction)}
            isReadOnly={isReadOnly}
            hasNotch={hasNotch}
            journalUserId={journalUserId}
            navigation={this.props.navigation}
            handleOnSaveAnswer={(ans, skipped=false) => this.handleSaveAnswer(null, ans, skipped)}
          />
        }
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    showLearnHow: state.uistate.showLearnHow,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    name: state.surveys.name,
    journalUserId: state.uistate.journalUserId,
    journalUserName: state.uistate.journalUserName,
    surveyQuestionFetching: state.surveys.surveyQuestion.fetching,
    surveyQuestionData: state.surveys.surveyQuestion.data,
    surveyQuestion: state.surveys.surveyQuestion,
    answerSaved: state.surveys.answerSaved,
    hasNotch: state.uistate.hasNotch,
    tabDevice: state.uistate.tabDevice,
    tabMenuIconView: state.uistate.tabMenuIconView,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setActiveTab: activeTabIndex =>
      dispatch(actions.setActiveTab(activeTabIndex)),
    fetchSurveyQuestion: (surveyId, surveyQuestionId, currentQuestionId, notificationId, surveyStatus, questionOrder, questionDirection) =>
      dispatch(actions.fetchSurveyQuestion(surveyId, surveyQuestionId, currentQuestionId, notificationId, surveyStatus, questionOrder, questionDirection)),
    saveHealthSurveyAnswer: (answer, journalUserId) => dispatch(actions.saveHealthSurveyAnswer(answer, journalUserId)),
    saveHealthSurveyAnswerReset: () => dispatch(actions.saveHealthSurveyAnswerReset()),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurveyQuestionScreen);
