import axios from './axios-config-common-notif';

export const axiosGET = (url, calbackFunc, data) => {
  let body = {};
  if (data) {
    body = data;
  }

  axios
    .get(url, {
      headers: {
        'Content-Typ': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
      },
      mode: 'no-cors',
      params: body,
    })
    .then(response => {
      calbackFunc({type: 'SUCCESS', responseDto: response});
    })
    .catch(error => {
      if (error === undefined || error.response === undefined) {
        calbackFunc({
          type: 'ERROR',
          errorDto: {data: {error: 'NETWORK_ERROR'}},
        });
      } else if (error.response) {
        if (error.response.status === 403) {
          calbackFunc({type: 'ERROR', errorDto: 'SESSION_TIME_OUT'});
        } else {
          calbackFunc({type: 'ERROR', errorDto: error.response});
        }
      }
    });
};
