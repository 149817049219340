import React from "react";
import { View, TouchableOpacity, Text } from "react-native";
import styles from "../../../Css";
import ActivityIndicator from '../../ActivityIndicator/activityIndicator';

const cardButton = (props) => {
  const {
    decline,
    onPressButton, 
    connecting,
    wait,
    windowWidth
  } = props;
  let btnText = "Connect";
  if(props.decline){
      btnText="Decline";
  }
  return (
    <TouchableOpacity
      nativeID={'communityMyConnections' + btnText} 
      onPress={onPressButton}
      disabled={(!decline && connecting) || (decline && wait)}  
    >
      <View
        style={[
          styles.submitSubSm,
          styles.flexRow,
          styles.flexJcCtAiCt,
          decline ? styles.declineBtn : styles.connectBtn,
        ]}
      >
        <Text
          style={[
            windowWidth > 1366
            ? styles.textPrime
            : styles.textPrimeMd,
            decline ? styles.btnTexGrey : styles.btnTextWhite,
          ]}
          allowFontScaling= {false}
        >
          {btnText}
        </Text>
        {((!Boolean(decline) && connecting) || (Boolean(decline) && wait)) &&
          <View style={[styles.indicatorIconView, styles.loaderRt]}>
            <ActivityIndicator pdZero={true} size="small" />
          </View>
          } 
      </View>
    </TouchableOpacity>
  );
};

export default (cardButton);
