import React, { Fragment, useState, useEffect } from "react";
import {
  Image,
  View,
  Text,
  TouchableOpacity,
} from 'react-native';
import styles from '../../../Css';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

const NoResultFound = props => {
  const {
    resetFilteringData, 
    resetFilteringObjectData, 
    resetHealthJourneyEvents,
    saveHealthJourneyTagsObject, 
    saveHealthJourneyCategoryObject, 
    setSelectedAdvancedFilterItems,
    fetchAllHealthJourneyEvents,
    setSelectDateOption,
    sortType,
    setHealthJourneyCategoryObject,
    isFiltersAvailable
  } = props;
  const {fetching: fetchingProps, data} = fetchAllHealthJourneyEvents;

  const [itemCount, setItemCount] = useState(0);
  const [eventCount, setEventCount] = useState(0);

  const clearFilters = () => {
    setEventCount(0);
    resetFilteringData();
    resetFilteringObjectData();
    resetHealthJourneyEvents();
    setHealthJourneyCategoryObject({})
  }

  useEffect(() => {
    const tagsObject = saveHealthJourneyTagsObject.data;
    const categoryObject = saveHealthJourneyCategoryObject.data;
    let subCatCount = 0;
    let count = 0;
    let added = false;
    let countAd = 0;
    let eventCount = 0;

    if(saveHealthJourneyCategoryObject.data.length > 0){
      for(const item of saveHealthJourneyCategoryObject.data){
        if(item.subCategoryList.length > 0) subCatCount += item.subCategoryList.length
        else subCatCount += 1
      }
    }

    if(saveHealthJourneyCategoryObject.data.length > 0 && saveHealthJourneyTagsObject.data.length > 0) {
      count = tagsObject[0].data.length + tagsObject[1].data.length +
                categoryObject.length + subCatCount;
                setItemCount(count);
      added = true;
    }
    if (setSelectedAdvancedFilterItems && setSelectedAdvancedFilterItems.length > 0) {
      setSelectedAdvancedFilterItems.forEach(item => {
        countAd += item.data.length;
      })
    }

    if (setSelectDateOption !== null) {
      count = count + 1;
    }

    if (isFiltersAvailable && (sortType === 0 || sortType === 1)) {
      count = count + 1;
    }


    setItemCount(count + countAd + (added ? 0 : subCatCount));

    for (const [year, items] of Object.entries(data)) {
      console.log(items.length);
      eventCount += items.length;
    }

    setEventCount(eventCount);
    //eslint-disable-next-line
  },[saveHealthJourneyTagsObject, saveHealthJourneyCategoryObject, setSelectedAdvancedFilterItems]);

  return (
    <Fragment>

      {
        isFiltersAvailable ? (
          <Fragment>
            <View style={[styles.flexCol, styles.flexJcCt, styles.flexAiCt, styles.pdTpHundred]}>
              <View>
                <Image
                  style={styles.noResultFoundIng}
                  source={require('../../../assets/health-journey-icons/no-result-found.png')}
                />
              </View>
              <View style={styles.comMgTopExLg}>
                <Text style={[styles.textTitleMainLgBold]}> Oops! No Results Found </Text>
              </View>
              <View style={styles.appListMg}>
                <Text style={[styles.textTitleExLg]}> {`${itemCount}`} active filters are hiding {` ${eventCount} `} events. </Text>
              </View>
              <TouchableOpacity onPress={clearFilters}>
                <View style={[styles.readMoreTxt, styles.barTopSp]}>
                  <Text style={[styles.textTitleExLg, styles.readMoreTxt,]}> Clear All Filters </Text>
                </View>
              </TouchableOpacity>
            </View>
          </Fragment>) : (
          <Fragment>
            <View style={[styles.flexCol, styles.flexJcCt, styles.flexAiCt, styles.pdTpHundred]}>
              <View>
                <Image style={styles.noResultFoundIngNoFilters} source={require('../../../assets/health-journey-icons/onboarding_2_phonesafe.png')} />
              </View>
              <View style={styles.comMgTopExLg}>
                <Text style={styles.selectCategoryText}>Select a category from the blue plus</Text>
                  <Text style={styles.selectCategoryText}>button to add your first health event!</Text>
              </View>
            </View>
          </Fragment>)
      }


    </Fragment>
  );
};

const mapStateToProps = state => {
  return { 
    saveHealthJourneyTagsObject: state.healthJourney.saveHealthJourneyTagsObject,
    saveHealthJourneyCategoryObject: state.healthJourney.saveHealthJourneyCategoryObject,
    setSelectedAdvancedFilterItems: state.healthJourneyFiltering.setSelectedAdvancedFilterItems,
    fetchAllHealthJourneyEvents: state.healthJourney.fetchAllHealthJourneyEvents,
    setSelectDateOption: state.healthJourneyFiltering.setSelectDateOption,
    sortType: state.healthJourneyFiltering.sortType,
  };
};

const mapDispatchToProps = dispatch => {
  return {
      resetFilteringData: () => dispatch(actions.resetFilteringData()),
      resetFilteringObjectData: () => dispatch(actions.resetFilteringObjectData()),
      resetHealthJourneyEvents: () => dispatch(actions.resetHealthJourneyEvents()),
      setHealthJourneyCategoryObject: (categoriesObj) =>
        dispatch(actions.setHealthJourneyCategoryObject(categoriesObj)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NoResultFound);
