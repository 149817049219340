import React, {Component} from 'react';
import {Platform} from 'react-native';
import Modal from '../../components/Modal/modal';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import JournalEntryTypeModalContent from '../../components/RoutineTemplate/ModalContent/journalEntryTypeModalContent';
import AddNewTherapyModalContent from '../../components/ConfirmationBox/addPrescription';
import AddMethodforEntry from '../../components/ConfirmationBox/addMethodforEntry';
import SuccessBox from '../../components/ConfirmationBox/successBox';
import AddMenu from './AddMenu';
import {
  JOURNALS,
  ROUTINES,
  TODOS,
  IMAGES_DOCUMENTS,
  CONTACTS,
  MEDICATIONS,
  PROFILE,
} from '../../navigation/path';
import {getAccountType} from '../../util/subscriptionInformation';
import * as RootNavigation from '../../navigation/RootNavigation';
import ListRoutine from '../../screens/Routines/ListRoutine'
import { pendoTrackEvent } from '../../util/pendoConfig';
import { IOS, TAB_BREAk_POINT_END, WEB } from '../../util/platformWindowConfig';

class CommonAddMenu extends Component {
  constructor(props) {
    super(props);
    const accountType = getAccountType(props.userSubscription);
    const {addMenu, opened, fromPopup} = props;
    this.state = {
      visibleModal: fromPopup === 'JOURNAL' ? true : false,
      visibleEntryModal: fromPopup === 'CONTACT' ? true : false,
      selected: 4,
      visiblePrescription: fromPopup === 'MEDICATION' ? true : false,
      visibleWarning: false,
      addMenu: addMenu,
      opened: opened,
      accountType: accountType,
      buttonStatus: false,
    };
    this.addMenuRef = null;
  }

  componentDidMount() {
    this.refUpdate();
  }
  
  static getDerivedStateFromProps(props, state) {
    const {addMenu, opened, fromPopup, userSubscription} = props;
    const accountType = getAccountType(userSubscription);
    let visibleModal = false;
    let visibleEntryModal = false;
    let visiblePrescription = false;
    let {selected} = state;
    if (fromPopup === 'JOURNAL') {
      visibleModal = true;
    } else if (fromPopup === 'CONTACT') {
      visibleEntryModal = true;
      selected = 4;
    } else if (fromPopup === 'MEDICATION') {
      visiblePrescription = true;
    }

    if (addMenu !== state.addMenu || opened !== state.opened) {
      return {
        addMenu: addMenu,
        opened: opened,
      };
    } else if (
      fromPopup &&
      (visibleModal !== state.visibleModal ||
        visibleEntryModal !== state.visibleEntryModal ||
        visiblePrescription !== state.visiblePrescription)
    ) {
      return {
        visibleModal: visibleModal,
        visibleEntryModal: visibleEntryModal,
        visiblePrescription: visiblePrescription,
        selected,
      };
    }

    return {
      accountType: accountType
    };
  }

  refUpdate = () => {
    const {getRef} = this.props;
    getRef && getRef(this);
  };


  getAddMenuRef = () => {
    return this.addMenuRef;
  };

  addJournalModelOpen = () => {
    this.setState({visibleModal: true});
  };

  handleCreateRoutineNavigation = (view = false, journalName) => {
    if (this.props.windowWidth < TAB_BREAk_POINT_END) {
      this.props.navigation.reset({
        index: 0,
        routes: [{ 
          name: view ? JOURNALS.viewJournalPath : JOURNALS.addJournalPath,
          params: {
            fromPopup: 'JOURNAL',
            from: RootNavigation.getLocationProps().name,
            journalName: journalName
          } 
        }],
      });
    } else {
      this.props.navigation.reset({
        index: 0,
        routes: [{ 
          name: JOURNALS.path,
          params: {
            fromPopup: 'JOURNAL',
            from: RootNavigation.getLocationProps().name,
            journalPath: view ? JOURNALS.viewJournalPathSuffix : JOURNALS.addJournalPathSuffix,
            journalName: journalName
          } 
        }],
      });
    }
  }

  handleCreateNewRoutine = routineTypeId => {
    this.setState({visibleModal: false});
    this.props.setFromPopup(null);
    const selectedJournal = this.props.journalEntryTypes.filter(({id}) => id === routineTypeId);
    if (selectedJournal[0].type === "DEVICE") {
      const allJournal = ListRoutine.sortBydate(ListRoutine.getJournal(
        Object.values(this.props.journalEntriesProcessed),
        this.props.journalEntryTypesProcessed,
      ));
      const deviceJournals = allJournal.filter(item => item.deviceName === selectedJournal[0].deviceName);
      if (deviceJournals && deviceJournals.length > 0) {
        this.props.onSetSelectedJournalEntry(deviceJournals[0]);
      } else {
        this.props.onSetSelectedJournalEntry(allJournal?.[0] || null);
      }
      this.handleCreateRoutineNavigation(true);
    } else {
      const journalEntry = { journalEntryTypeId: routineTypeId, draft: true };
      this.props.setCreateSpinner();
      this.props.onCreateNewRoutine(
        journalEntry,
        res => {
          // Redirect to New Journal
          if (res) {
            this.handleCreateRoutineNavigation(false, selectedJournal[0].name);
          }
          this.props.setCreateSpinner();
        },
        this.props.journalUserId,
        true, //New unsaved journal ZI-2293
      );
    }
  };

  copyHandleCreateNewRoutine = () => {
    this.setState({visibleModal: false});
    this.props.setFromPopup(null);
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: ROUTINES.addRoutineTemplatePath }],
    });
  };

  addTaskQuestionPageOpen = () => {
    this.props.onSaveQuestionToAttach('');
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: TODOS.addToDoPath }],
    });
  };

  addAttachmentPageOpen = (deviceType) => {
    this.props.clearFilesToAttach();
    this.props.navigation.reset({
      index: 0,
      routes: [{ 
        name: deviceType === WEB ? IMAGES_DOCUMENTS.path : IMAGES_DOCUMENTS.addImagesDocumentsPath, 
      }],
    });
  };

  addNewContactModalOpen = () => {
    this.setState({
      visibleEntryModal: true,
      selected: 4,
    });
  };

  onPressClose = () => {
    this.props.setFromPopup(null);
    this.setState({visibleEntryModal: false, selected: null});
  };

  onPressManuallyEntry = () => {
    this.props.setFromPopup(null);
    this.setState({
      visibleEntryModal: false,
      buttonStatus: true,
    });
    if (this.state.selected === 4) {
      this.createContact();
      pendoTrackEvent('Contact > EnterManually');
    } else if (this.state.selected === 6) {
      this.createTherapie();
    }
    // this.setState({
    //   selected: null,
    // });
  };

  onPressUploadEntry = () => {
    this.props.setFromPopup(null);
    this.setState(
      {
        visibleEntryModal: false,
      },
      () => {
        setTimeout(() => {
          this.props.navigation.reset({
            index: 0,
            routes: [{ 
              name: CONTACTS.contactTakePhotoPath,
              params: {
                type: 'upolad',
                fromPopup: 'CONTACT',
              } 
            }],
          });
        }, 1000);
      },
    );
  };

  onPressTakePhoto = () => {
    this.props.setFromPopup(null);
    this.setState({buttonStatus: true});
    if (Platform.OS === IOS) {
      setTimeout(() => {
        this.setState(
          {
            visibleEntryModal: false,
          },
          () => {
            setTimeout(() => {
              this.props.navigation.reset({
                index: this.props.navigation.getRootState().routes.length,
                routes: [...this.props.navigation.getRootState().routes, { 
                  name: CONTACTS.contactTakePhotoPath,
                  params: {
                    type: 'takePhoto',
                    fromPopup: 'CONTACT',
                  } 
                }],
              });
              this.setState({buttonStatus: false});
            }, 1000);
          },
        );
      }, 300);
    } else {
      this.setState(
        {
          visibleEntryModal: false,
        },
        () => {
          setTimeout(() => {
            this.props.navigation.reset({
              index: this.props.navigation.getRootState().routes.length,
              routes: [...this.props.navigation.getRootState().routes, { 
                name: CONTACTS.contactTakePhotoPath,
                params: {
                  type: 'takePhoto',
                  fromPopup: 'CONTACT',
                } 
              }],
            });
            this.setState({buttonStatus: false});
          }, 1000);
        },
      );
    }
    pendoTrackEvent('Contact > ScanCard');
  };

  onChange = e => {
    if (e && e.target.files[0]) {
      let name = e.target.files[0].name
        .split('.')
        .pop()
        .toLowerCase();
      if (
        name === 'png' ||
        name === 'jpg' ||
        name === 'jpeg' ||
        name === 'svg' ||
        name === 'jfif' ||
        name === 'gif'
      ) {
        let imagePath = window.URL.createObjectURL(e.target.files[0]);
        this.setState({visibleEntryModal: false});
        this.props.setFromPopup(null);
        this.props.navigation.reset({
          index: 0,
          routes: [{ 
            name: CONTACTS.contactTakePhotoPath,
            params: {
              inputResult: imagePath,
              fromPopup: 'CONTACT',
            } 
          }],
        });
      } else {
        this.setState({visibleWarning: true});
        setTimeout(() => {
          this.setState({visibleWarning: false});
        }, 5000);
      }
    }
  };

  redirectPremiumAccount = () => {
    const visiblePrescription = this.state.visiblePrescription;
    this.setState({
      visiblePrescription: false,
      visibleEntryModal: false,
      buttonStatus: true,
    });
    this.props.setFromPopup(null, visiblePrescription ? this.props.fromDashbordMeds : false);
    this.props.navigation.reset({
      index: this.props.navigation.getRootState().routes.length,
      routes: [...this.props.navigation.getRootState().routes, {
        name: PROFILE.profilePremiumAccountPath,
        params: {
          fromPopup: visiblePrescription ? 'MEDICATION' : 'CONTACT',
        } 
      }],
    });
    setTimeout(() => {
      this.setState({buttonStatus: false});
    }, 100);
    visiblePrescription ? pendoTrackEvent('MedSupp+Act > Premium') : pendoTrackEvent('Contact > Premium');
  };

  createContact = () => {
    let addNewContact = {};
    this.props.setCreateSpinner();
    this.props.addContact(
      addNewContact,
      this.props.contacts,
      res => {
        if (res) {
          //this.props.setFromPopup(null);
          this.props.navigation.reset({
            index: 0,
            routes: [{ 
              name: CONTACTS.addContactPath, 
              params: {
                fromPopup: 'CONTACT',
              } 
            }],
          });
        }
        this.props.setCreateSpinner();
        this.setState({buttonStatus: false});
      },
      this.props.journalUserId,
    );
  };

  addMedicationOrTherapyModalOpen = () => {
    this.setState({
      visiblePrescription: true,
      selected: 6,
    });
  };

  scanPrescription = () => {
    this.props.setFromPopup(null, this.state.fromDashboardMeds);
    this.setState({visiblePrescription: false, buttonStatus: true}, () => {
      setTimeout(() => {
        this.props.navigation.reset({
          index: 0,
          routes: [{ 
            name: MEDICATIONS.scanPrescriptionPath,
            params: {
              fromPopup: 'MEDICATION',
              globalPath: this.props.navigation.getCurrentRoute().name,
              fromDashbordMeds: this.props.fromDashbordMeds ? true : false
            } 
          }],
        });
        this.setState({buttonStatus: false});
      }, 1000);
    });
    pendoTrackEvent('MedSupp+Act > ScanLabel');
  };

  manualPrescription = () => {
    this.props.setFromPopup(null, this.props.fromDashbordMeds);
    this.setState({visiblePrescription: false, buttonStatus: true});
    this.createTherapie();
    pendoTrackEvent('MedSupp+Act > EnterManually');
  };

  createTherapie = () => {
    let addNewTherapie = {};
    this.props.setCreateSpinner();
    this.props.addTherapie(
      addNewTherapie,
      res => {
        if (res) {
          //this.props.setFromPopup(null);
          this.props.navigation.reset({
            index: 0,
            routes: [{ 
              name: MEDICATIONS.addTherapiePath, 
              params: {
                fromPopup: 'MEDICATION',
                fromManualEntry: true,
                globalPath: this.props.navigation.getCurrentRoute().name,
                activeTherapyTab: this.props.fromDashbordMeds ? 0 : null
              } 
            }],
          });
        }
        this.props.setCreateSpinner();
        this.setState({buttonStatus: false});
      },
      this.props.journalUserId,
    );
  };

  addQuickNotePageOpen = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: JOURNALS.addQuickNotePath }],
    });

  };

  render() {
    const {
      setCreateSpinner,
      addMenuState,
      navigation,
      applicationSettingsDigest,
      openedState,
      journalEntryTypesProcessed,
      tabDevice,
      tabMenuIconView
    } = this.props;
    const {newJournalEntryTypeId, labsEntryTypeId} = applicationSettingsDigest;
    const {addMenu, opened} = this.state;
    return (
      <React.Fragment>
        <AddMenu
          setCreateSpinner={setCreateSpinner}
          addMenu={addMenu}
          addMenuState={addMenuState}
          navigation={navigation}
          opened={opened}
          openedState={openedState}
          addJournalModelOpen={this.addJournalModelOpen}
          addTaskQuestionPageOpen={this.addTaskQuestionPageOpen}
          addAttachmentPageOpen={this.addAttachmentPageOpen}
          addNewContactModalOpen={this.addNewContactModalOpen}
          addMedicationOrTherapyModalOpen={this.addMedicationOrTherapyModalOpen}
          addQuickNotePageOpen={this.addQuickNotePageOpen}
          tabDevice={tabDevice}
          tabMenuIconView={tabMenuIconView}
          getRef={ref => (this.addMenuRef = ref)}
        />
        {this.state.visibleModal && (
          <Modal
            visible={this.state.visibleModal}
            onRequestClose={() => {
              this.props.setFromPopup(null);
              this.setState({visibleModal: false});
            }}
            onBackdropPress={() => {
              this.props.setFromPopup(null);
              this.setState({visibleModal: false});
            }}>
            <JournalEntryTypeModalContent
              closeModal={() => {
                this.props.setFromPopup(null);
                this.setState({visibleModal: false});
              }}
              journalEntryTypes={this.props.journalEntryTypes}
              handleCreateNewRoutine={this.handleCreateNewRoutine}
              addNewRoutine={this.copyHandleCreateNewRoutine}
              newJournalEntryType={
                journalEntryTypesProcessed[newJournalEntryTypeId]
              }
              labsEntryType={journalEntryTypesProcessed[labsEntryTypeId]}
              fromPopup="JOURNAL"
            />
          </Modal>
        )}
        {this.state.visibleEntryModal && (
        <Modal
          visible={this.state.visibleEntryModal}
          onRequestClose={() => {
            this.props.setFromPopup(null);
            this.setState({visibleEntryModal: false, selected: null});
          }}
          onBackdropPress={() => {
            this.props.setFromPopup(null);
            this.setState({visibleEntryModal: false, selected: null});
          }}>
          {Platform.OS === WEB ? (
            <AddMethodforEntry
              onPressClose={this.onPressClose}
              onPressManuallyEntry={this.onPressManuallyEntry}
              onPressUploadEntry={this.onPressUploadEntry}
              onPressTakePhoto={this.onPressTakePhoto}
              onChange={e => this.onChange(e)}
              accountType={this.state.accountType}
              redirectPremiumAccount={this.redirectPremiumAccount}
              navigation={navigation}
              fromPopup="CONTACT"
              journalUserId={this.props.journalUserId}
            />
          ) : (
            <AddMethodforEntry
              onPressClose={this.onPressClose}
              onPressManuallyEntry={this.onPressManuallyEntry}
              onChange={this.onPressUploadEntry}
              onPressTakePhoto={this.onPressTakePhoto}
              accountType={this.state.accountType}
              redirectPremiumAccount={this.redirectPremiumAccount}
              navigation={navigation}
              fromPopup="CONTACT"
              journalUserId={this.props.journalUserId}
              buttonStatus={this.state.buttonStatus}
            />
          )}
        </Modal>
        )}
        {this.state.visiblePrescription && (
        <Modal
          visible={this.state.visiblePrescription}
          onRequestClose={() => {
            this.props.setFromPopup(null);
            this.setState({visiblePrescription: false});
          }}
          onBackdropPress={() => {
            this.props.setFromPopup(null);
            this.setState({visiblePrescription: false});
          }}>
          <AddNewTherapyModalContent
            closeModal={() => {
              this.props.setFromPopup(null);
              this.setState({visiblePrescription: false});
            }}
            scanLabel={this.scanPrescription}
            redirectPremiumAccount={this.redirectPremiumAccount}
            manualEntry={this.manualPrescription}
            accountType={this.state.accountType}
            navigation={navigation}
            fromPopup="MEDICATION"
            journalUserId={this.props.journalUserId}
            buttonStatus={this.state.buttonStatus}
          />
        </Modal>
        )}

        <SuccessBox
          visibleModal={this.state.visibleWarning}
          onRequestClose={() => this.setState({visibleWarning: false})}
          onBackdropPress={() => this.setState({visibleWarning: false})}
          isWarning={true}
          message={'Unsupported file type'}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.routines.contacts,
    journalEntryTypes: state.routines.journalEntryTypes,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    journalUserId: state.uistate.journalUserId,
    userSubscription: state.routines.userSubscription,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    fromPopup: state.uistate.fromPopup,
    fromDashbordMeds: state.uistate.fromDashbordMeds,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.createTherapie(addNewTherapie, callBack, journalUserId)),
    onCreateNewRoutine: (journalEntry, callBack, journalUserId, isNew) =>
      dispatch(actions.createNewRoutine(journalEntry, callBack, journalUserId, isNew)),
    addContact: (addNewContact, contacts, callBack, journalUserId) =>
      dispatch(
        actions.createContact(addNewContact, contacts, callBack, journalUserId),
      ),
    onSaveQuestionToAttach: question =>
      dispatch(actions.saveQuestionToAttach(question)),
    scanPrescription: (base64, callback) =>
      dispatch(actions.scanPrescription(base64, callback)),
    clearFilesToAttach: () => dispatch(actions.clearFilesToAttach()),
    setFromPopup: (fromPopup, fromDashboardMeds) => dispatch(actions.setFromPopup(fromPopup, fromDashboardMeds)),
    onSetSelectedJournalEntry: selectedId =>
      dispatch(actions.setSelectedJournalEntry(selectedId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommonAddMenu);
