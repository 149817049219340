import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { View } from "react-native";
import styles from "../../../Css";
import * as FieldDetails from "../../../components/TextInput/fieldDetails";
//import AutoCompleteFrame from "../../../components/AutoComplete/AutoCompleteFrame";
import AutoCompleteSuggestion from "../../../components/AutoComplete/AutoCompleteSuggestion";
import {autoSuggestionListType, chartTypes} from '../../../Constant/index';
import { getSelectedItems, getUserTerms, getSuggestedTeams, getUnits, getAlreadySelectedHealthData } from '../../../util/commonUiLogic';
class AddHealthData extends Component {
  constructor(props) {
    super(props);
    const { metricsName, systemDefinedMetrics, userDefinedMetrics, metrics } = props;
    this.state = {
      name: metricsName ? metricsName : "",
      terms: getSuggestedTeams(systemDefinedMetrics, userDefinedMetrics),
      userTerms: getUserTerms(userDefinedMetrics),
      systemDefinedUnitOfMeasure: [],
      selectedMetrics: getSelectedItems(getAlreadySelectedHealthData(userDefinedMetrics, metrics))
    };
  }

  componentDidMount() {
    this.props.getSystemUnitOfMeasure((res, data) => {
      if (res) {
        this.setState({
          systemDefinedUnitOfMeasure: data
        });
      }
    });
  }

  // UNSAFE_componentWillReceiveProps(props) {
  //   this.setState({ selectedMetrics: getSelectedItems(this.getAlreadySelectedHealthData()) });
  // }

  // getAlreadySelectedHealthData = (selectedValue, needFilteredItems, hasType) => {
  //   const {userDefinedMetrics} = this.props;
  //   let metrics = [];
  //   let mappedMetrics = [];
  //   let filteredItems = [];

  //   if (this.props.metrics) {
  //     metrics = [...this.props.metrics];
  //     metrics.forEach(metric => {
  //       if(metric.metric) {
  //         if(userDefinedMetrics) {
  //           let mappedMetric = userDefinedMetrics.filter(value => 
  //             value.id === metric.metric
  //           );
  //           if(mappedMetric) {
  //             mappedMetrics.push(mappedMetric[0]);
  //           }
  //         } 
  //       } else {
  //         mappedMetrics.push(metric);
  //       }
  //     });

  //     if (needFilteredItems) {
  //       if (hasType) {
  //         filteredItems = mappedMetrics.filter(metric =>
  //           metric?.name === selectedValue.name ||
  //           metric?.data?.name === selectedValue.name
  //         );
  //       } else {
  //         filteredItems = mappedMetrics.filter(metric =>
  //           metric?.name === selectedValue ||
  //           metric?.data?.name === selectedValue
  //         );
  //       }
  //     }
  //   }
  //   return needFilteredItems ? filteredItems : mappedMetrics;
  // }
  
  onChangeTextName = (currentInput) => {
    this.setState({
      name: currentInput,
    });
  };

  onItemPress = (selection, type) => {
    let selectedValue = selection.data;
    const { addHealthDataToList, userDefinedMetricUnitOfMeasures, userDefinedMetrics, metrics } = this.props;

    let filteredItems = getAlreadySelectedHealthData(userDefinedMetrics, metrics, selectedValue, true, true);
    if (filteredItems.length === 0) {
      const units = getUnits(this.state.systemDefinedUnitOfMeasure, selectedValue.typeOfMeasurement);
      let unit = units?.[0];
      if (type === 'USER') {
        let uomId = userDefinedMetricUnitOfMeasures.filter(res => res.metricId === selectedValue.id)?.[0]?.uomId;
        if (units && uomId) {
          unit = units.filter(res => res.id === uomId)?.[0];
        }
      }
  
      this.setState({
        name: "",
      });
      addHealthDataToList(
        selectedValue,
        type,
        unit?.id
      );
    }
    this.setState({
      name: "",
    });
  };

  validateMetric = () => {
    let valid = true;
    if (this.props.metrics && this.props.metrics.length !== 0) {
      valid = this.props.metrics.some(
        (res) => !(res.metric.name === this.state.name)
      );
    }
    return valid;
  };
  clearInputAndTerms = () => {
    this.setState({
      name: "",
    });
  };

  onAddNewPress = (selection) => {
    const { addHealthDataToList, metrics, userDefinedMetrics } = this.props;
    
    let filteredItems = getAlreadySelectedHealthData(userDefinedMetrics, metrics, selection, true);
    if (filteredItems.length === 0) {
      const name = selection;
      const units = getUnits(this.state.systemDefinedUnitOfMeasure, null);
      this.setState({
        name: "",
      });
      addHealthDataToList(
        {
          isActive: true,
          id: -1,
          name: name,
          createdOn: null,
          modifiedOn: null,
          artifactId: null,
          description: '',
          typeOfMeasurement: null,
          metricGroup: 1,
          displayProperties: {
            color: 'rgba(113, 168, 220, 1)',
            style: chartTypes.dash,
            scaleMin: null,
            scaleMax: null,
          },
        },
        "CUSTOM",
        units[0]?.id
      );
    }
    this.setState({
      name: "",
    });
    this.props.onUpdateMetricsName("");
  };

  render() {
    const { userTerms, terms } = this.state;
    const { updateAutocompleteOpen } = this.props;
    return (
      <View style={[styles.fieldRow, styles.zIndexAutoCompleteSpec]}>
        <View style={[styles.fieldRowWrapper, styles.zIndexAutoCompleteSpec]}>
          <View style={[styles.fieldColStretch, styles.zIndexAutoCompleteSpec]}>
            <AutoCompleteSuggestion
              textFieldWithTitle={true}
              textFieldTitle="Track Health Data"
              remortSearch={false}
              triggerLength={1}
              onChangeText={this.onChangeTextName}
              onItemPress={this.onItemPress}
              currentInput={this.state.name}
              onPressCustom={this.onAddNewPress}
              showCustomSection={true}
              //autoFocus={true}
              terms={terms}
              userTerms={userTerms}
              updateAutocompleteOpen={updateAutocompleteOpen}
              selectedValues={this.state.selectedMetrics}
              fieldBg={FieldDetails.BGWHITE}
              fieldLt={true}
              zoomIcon={true}
              removeIcon={true}
              type={autoSuggestionListType.health_data}
              maxLength={50}
            />
          </View>
        </View>
      </View>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    systemDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.systemDefinedMetrics
      : [],
    userDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.userDefinedMetrics
      : [],
    metricsName: state.setup.metricsName,
    userDefinedMetricUnitOfMeasures:
      state.routines.metricsDigest.userDefinedMetricUnitOfMeasures,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateMetric: (addNewMetric, callBack) =>
      dispatch(actions.updateMetric(addNewMetric, callBack)),
    onUpdateMetricsName: (name) => dispatch(actions.updateMetricsName(name)),
    getSystemUnitOfMeasure: callBack =>
      dispatch(actions.getSystemUnitOfMeasure(callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddHealthData);
