import React, { Component } from 'react';
import { View, Text, TouchableOpacity, PanResponder, Image } from 'react-native';
import PropTypes from 'prop-types';
import styles from './yesNoSwitchStyles';
import stylesCss from '../../Css';
import { TAB_BREAk_POINT_START } from '../../util/platformWindowConfig';

const touchableExpandedArea = 15;

const DRAGGABLE_AREA = 100;
const HORIZONTAL_EDGE_LIMIT_OF_SLIDER = 25;

const segmentsArrInSate = ['No', 'Yes'];
Object.freeze(segmentsArrInSate);

const VALUE_TYPES = {
  no: segmentsArrInSate[0].toUpperCase(),
  yes: segmentsArrInSate[1].toUpperCase(),
};
Object.freeze(VALUE_TYPES);

class YesNoSwitch extends Component {
  state = {
    currentSelectedIndex: -1,
    isPressed: false,
    previousPropsSelectedIndex: -1,
    /**
     * User must be able to view tooltip when user clicked on moved on particular segment in view mode.
     * Hence, this keeps the segment index while user moved on any segment. It is updated only when slider is disabled.
     */
    disabledSelectedIndex: -1,
  };

  constructor() {
    super();

    this.sliderWrapper = null;
    this.sliderSectionRefs = [];
    this.toolTip = null;

    this.wrapperLayoutMeasure_var = {};
    this.sliderSectionsLayoutMeasures_var = [...Array(2)].map(x => ({}));
  }

  static getDerivedStateFromProps(props, state) {
    const { previousPropsSelectedIndex } = state;
    const { value } = props;

    const selectedIndexTemp = (value === null) ? -1 : segmentsArrInSate.findIndex((item) => item.toUpperCase() === value);

    let updatedState = {};

    if (previousPropsSelectedIndex !== selectedIndexTemp) {
      updatedState =  {
        previousPropsSelectedIndex: selectedIndexTemp,
        currentSelectedIndex: selectedIndexTemp,
      };
    }

    if (Object.keys(updatedState).length) {
      return updatedState;
    }

    return null;
  }

  panResponder = PanResponder.create({
    // onStartShouldSetPanResponder: (evt, gestureState) => true,
    onMoveShouldSetPanResponder: (evt, gestureState) => true,
    onStartShouldSetResponderCapture: () => true,
    onPanResponderMove: (event, gestureState) => {
      const { moveX, moveY } = gestureState;
      const { currentSelectedIndex, isPressed, disabledSelectedIndex } = this.state;
      const { enabled } = this.props;

      const foundMatch = this.sliderSectionsLayoutMeasures_var.findIndex((sectionMeasure, sectionIndex) => {
        let { x, width } = sectionMeasure;

        const nextSectionMeasure = (sectionIndex + 1) < this.sliderSectionsLayoutMeasures_var.length
          ? this.sliderSectionsLayoutMeasures_var[sectionIndex + 1] : null;
        let nextSectionMeasure_X = nextSectionMeasure ? nextSectionMeasure.x : (x + width);
        if ((sectionIndex + 1) >= this.sliderSectionsLayoutMeasures_var.length) {
          nextSectionMeasure_X += HORIZONTAL_EDGE_LIMIT_OF_SLIDER;
        }
        if (sectionIndex === 0) {
          x -= HORIZONTAL_EDGE_LIMIT_OF_SLIDER;
        }

        return ((moveX > x && moveX <= nextSectionMeasure_X)
              && (moveY > (this.wrapperLayoutMeasure_var.y - DRAGGABLE_AREA) && moveY <= (this.wrapperLayoutMeasure_var.y + this.wrapperLayoutMeasure_var.height + DRAGGABLE_AREA)));
      });

      /**
       * If enabled, currentSelectedIndex is updated.
       */
      if (enabled && isPressed && foundMatch > -1 && currentSelectedIndex !== foundMatch) {
        this.setState({ currentSelectedIndex: foundMatch });
      }

      /**
       * If not enabled, disabledSelectedIndex is updated and currentSelectedIndex should not be changed.
       */
      if (!enabled && isPressed && foundMatch > -1 && disabledSelectedIndex !== foundMatch) {
        this.setState({ disabledSelectedIndex: foundMatch });
      }
      if (foundMatch === -1) {
        this.setState({ isPressed: false });
      }
    },
    onShouldBlockNativeResponder: (evt, gestureState) => true,
    onPanResponderTerminationRequest: (evt, gestureState) => true,
    onPanResponderRelease: (evt, gestureState) => {
      this.handleSectionPress();
    },
    onPanResponderTerminate: (evt, gestureState) => { },
  });

  handleSliderWrapperLayoutChange = () => {
    this.sliderWrapper.measureInWindow((x,y, width, height) => {
      this.wrapperLayoutMeasure_var = { x, y, width, height };
    });
  };

  handleSliderSectionLayoutChange = (sectionIndex) => {
    this.sliderSectionRefs[sectionIndex].measureInWindow((x, y, width, height) => {
      let sliderSectionsLayoutMeasuresUpdated = [ ...this.sliderSectionsLayoutMeasures_var ];
      sliderSectionsLayoutMeasuresUpdated[sectionIndex] = { x, y, width, height };

      this.sliderSectionsLayoutMeasures_var = sliderSectionsLayoutMeasuresUpdated
    });
  };

  handleSectionPressIn = (sectionIndex) => {
    const { enabled } = this.props;

    let stateUpdated = { isPressed: true };
    if (enabled) {
      stateUpdated = { ...stateUpdated, currentSelectedIndex: sectionIndex };
    } else {
      stateUpdated = { ...stateUpdated, disabledSelectedIndex: sectionIndex  };
    }
    this.setState(stateUpdated, this.handleSectionPress);

    this.sliderWrapper.measureInWindow((x,y, width, height) => {
      this.wrapperLayoutMeasure_var = { x, y, width, height };
    });
  };

  handleSectionPress = () => {
    const { onChange } = this.props;
    const { currentSelectedIndex } = this.state;
    const { enabled } = this.props;

    this.setState({ isPressed: false, disabledSelectedIndex: -1 });
    if (enabled) {
      onChange(segmentsArrInSate[currentSelectedIndex] && segmentsArrInSate[currentSelectedIndex].toUpperCase());
    }
  };

  render() {
    const { currentSelectedIndex } = this.state;
    const { enabled, windowWidth } = this.props;

    return (
      <View >
        <View style={[styles.sliderOurtWrapper]}>

          {Boolean(enabled) && currentSelectedIndex !== -1 &&
          <View style={stylesCss.fieldColSliderData}>
            <View style={stylesCss.sliderDataIconView}>
                <View style={[stylesCss.sliderDataResult, stylesCss.flexAiCt]}>
                  <Image
                    style={stylesCss.sliderDataIcon}
                    source={require("../../assets/icons/tool-tip-data.png")}
                  />
                  <View style={stylesCss.sliderDataValue}>
                    <Text
                      style={[stylesCss.textAlignCenter, stylesCss.textPrimeLgBold]}
                      allowFontScaling={false}
                    >
                      {currentSelectedIndex === -1 ? '' : segmentsArrInSate[currentSelectedIndex]}
                    </Text>
                  </View>
                </View>
            </View>
          </View>
          }

          <View
            hitSlop={{ top: touchableExpandedArea, bottom: touchableExpandedArea }}
            style={[
              ...[styles.sliderBlockWrapper],
              ...[Boolean(enabled) ? { width: '94%'} : { width: '100%'}] // for this special ocation we have to set the width as 94%
            ]}
            {...this.panResponder.panHandlers}
            ref={sliderWrapper => {
              this.sliderWrapper = sliderWrapper;
            }}
            onLayout={this.handleSliderWrapperLayoutChange}
          >
            {segmentsArrInSate.map((optionItem, index) => (
              <TouchableOpacity
                activeOpacity={1}
                style={[
                  ...[styles.sliderBlockButtonWrapper],
                  ...[{ backgroundColor: '#E2E2E2' }],
                  ...[(index === currentSelectedIndex) && styles.sliderBlockButtonWrapperOrange], // use this style for orange color background
                  ...[(windowWidth < TAB_BREAk_POINT_START) ? { width: 50 } : { width: 80, height: 22 }], // normal size
                  ...[index === 0  ? { borderTopLeftRadius: 11, borderBottomLeftRadius: 11, borderLeftColor: 'transparent' } : { }],
                  ...[index === (segmentsArrInSate.length - 1)  ? { borderTopRightRadius: 11, borderBottomRightRadius: 11, borderRightColor: 'transparent'} : { }],
                ]}
                key={`slider_${index}`}
                onPressIn={() => this.handleSectionPressIn(index) }
                // onPress={() => this.handleSectionPress() }
                ref={sliderSection => {
                  this.sliderSectionRefs[index] = sliderSection;
                }}
                onLayout={() => this.handleSliderSectionLayoutChange(index)}
                hitSlop={{
                  top: 20,
                  bottom: 20,
                  left: (index === 0) ? 10 : 0,
                  right: 20,
                }}
              >
                <View
                  style={[
                    ...[styles.sliderIndexWrapper],
                    ...[Boolean(enabled) && currentSelectedIndex === index && windowWidth <= TAB_BREAk_POINT_START
                        ? { width: 16, height: 16, borderRadius: 8, backgroundColor: 'rgba(128, 103, 215, 0.4)' }
                        : { }], // normal size
                    ...[Boolean(enabled) && currentSelectedIndex === index && windowWidth > TAB_BREAk_POINT_START
                        ? { width: 22, height: 22, borderRadius: 11, backgroundColor: 'rgba(128, 103, 215, 0.4)' }
                        : { }], // survey symtomp slider outer wheel size in wide screens window.width> 991
                  ]}
                >
                  {Boolean(enabled) &&
                    <View style={[
                      ...[Boolean(enabled) && currentSelectedIndex === index && windowWidth <= TAB_BREAk_POINT_START
                          ? { width: 8, height: 8, borderRadius: 4, backgroundColor: 'rgba(128, 103, 215, 1)' } : { }], // normal size
                      ...[Boolean(enabled) && currentSelectedIndex === index && windowWidth > TAB_BREAk_POINT_START
                          ? { width: 14, height: 14, borderRadius: 7, backgroundColor: 'rgba(128, 103, 215, 1)' } : { }], // survey symtomp slider inner wheel size in wide screens window.width> 991
                      ]}>

                    </View>}
                </View>
                <View
                  style={[
                    ...[ index === 0 ? { position: 'absolute' } : { }],
                    ...[ index === (segmentsArrInSate.length - 1) ? { position: 'absolute' } : { }],
                  ]}
                >
                  <Text style={[]}  allowFontScaling={false}>{optionItem}</Text>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </View>
    );
  };
};

YesNoSwitch.defaultProps = {
  enabled: true,

  value: null,
  onChange: () => {},
};

YesNoSwitch.propTypes = {
  enabled: PropTypes.bool,
  value: PropTypes.oneOf([null, VALUE_TYPES.yes, VALUE_TYPES.no]),
  onChange: PropTypes.func,
};

export default YesNoSwitch;
