import React, { useState } from "react";
import { Text, View, TouchableOpacity, ScrollView, Image } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import CancelButton from "../Button/cancelButton";
import { pendoTrackEvent } from "../../util/pendoConfig";

const ResumeMedication = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {
    onRequestClose,
    treatment,
    onResumeMedication
  } = props;

  const [resumeWait, setResumeWait] = useState(false);

  const onPressResume = () => {
    setResumeWait(true);
    pendoTrackEvent(treatment?.treatmentType !== 5 
      ? 'MedSupp+Act@ > MedSuppResume'
      : 'MedSupp+Act@ > ActivityResume'
    );
    onResumeMedication(treatment, true, () => {
      onRequestClose();
    });
  };

  return (
      <View style={stylesRes.modalScrollMessageView} dataSet={{media: ids.modalScrollMessageView}}>
        <ScrollView style={styles.modalScroller}>
          <View style={[styles.popUpBlock, styles.bgWhite]}>
            <View style={styles.popUpContentArea}>
              <View style={styles.gridSectionPopup}>
                <View
                  style={[
                    styles.popUpContentPd,
                    styles.popUpBoxHeightMin,
                    //content end
                    styles.flexJcFe,
                    //content center
                    // styles.flexJcCt
                  ]}
                >
                  <View style={stylesRes.modalContIn}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.popMessageIconView, styles.comSp]}>
                          <Image
                            style={styles.resumeBtn}
                            source={require("../../assets/icons/resume-button.png")}
                          />
                        </View>

                        <View
                          style={[styles.popMessageContView, styles.comSpSm]}
                        >
                          <View
                            style={[
                              styles.popMessageTitleView,
                              styles.popupTitleMg,
                            ]}
                          >
                            {treatment?.treatmentType !== 5 ? (
                              <Text
                                style={[
                                  styles.textTitle,
                                  styles.textAlignCenter,
                                ]}
                                allowFontScaling={false}
                              >
                                <Text style={styles.bold}>Resume</Text>
                              {"\n\n"}Medications/Supplements
                              </Text>
                            ) : (
                              <Text
                                style={[
                                  styles.textTitle,
                                  styles.textAlignCenter,
                                ]}
                                allowFontScaling={false}
                              >
                                <Text style={styles.bold} allowFontScaling={false}>Resume</Text>{" "}
                                Activities
                              </Text>
                            )}
                          </View>

                          <View style={styles.popMessageDescView}>
                            <Text
                              style={[
                                styles.textPrimeLg,
                                styles.textAlignCenter,
                              ]}
                              allowFontScaling={false}
                            >
                              Are you sure?
                            </Text>
                          </View>
                        </View>

                        <View
                          style={[
                            styles.popBtnView,
                            styles.comMgTopMd,
                            styles.comSp,
                          ]}
                        >
                          <View style={styles.btnArea}>
                            <View
                              style={[
                                stylesRes.btnViewSec,
                                stylesRes.btnViewSecComNoPd,
                              ]}
                            >
                              <View style={styles.fieldBtn}>
                                <View style={styles.btnBlockFull}>
                                  <CancelButton 
                                    touchableOnpress={onRequestClose}
                                    cancelBtnWidthDiff={true}
                                  />
                                </View>
                              </View>
                              <View style={styles.fieldBtnSpSm} />
                              <View style={styles.fieldBtn}>
                                <View style={styles.btnBlockFull}>
                                  <TouchableOpacity
                                    style={[
                                      styles.submitSm,
                                      styles.btnShadowCom,
                                      styles.submitBgOrange,
                                    ]}
                                    underlayColor="rgba(0, 153, 168, 0.8)"
                                    onPress={onPressResume}
                                    nativeID={treatment?.treatmentType !== 5 ? 'medSuppResume' : 'ActivityResume'}
                                  >
                                    <View style={styles.btnSingleView}>
                                      <View
                                        style={[
                                          styles.btnSingle,
                                          styles.btnSingleCent,
                                        ]}
                                      >
                                      {Boolean(resumeWait) && (
                                          <View
                                            style={[
                                              styles.indicatorIconView,
                                              styles.indicatorLtMini,
                                            ]}
                                          >
                                            <ActivityIndicator
                                              pdZero={true}
                                              size="small"
                                            />
                                          </View>
                                        )}
                                        {/* )} */}
                                        <Text
                                          style={[
                                            styles.submitBtnTextWhite,
                                            styles.bold,
                                          ]}
                                          allowFontScaling={false}
                                        >
                                          Yes
                                        </Text>
                                      </View>
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
  );
};
export default ResumeMedication;
