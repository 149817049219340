import React from "react";
import { View, Image, Text } from "react-native";
import styles from "../../../Css";

const noResultBlock = (props) => {
  return (
    <View style={[styles.colPd, styles.bgWhite, styles.flexJcCtAiCt, styles.smRad]}>
      <View style={[styles.noResultTitleSec, styles.infoPdBotMd]}>
        <Text style={[styles.textPrimeBold, styles.textLightBlack]} allowFontScaling={false}>No routines found!</Text>
      </View>
      <View style={[styles.noResultImgSec, styles.infoPdBotMd]}>
        <Image
          style={styles.journalIcon}
          source={require("../../../assets/journal-icons/j-note.png")}
        />
      </View>
      <View style={[styles.noResultContSec]}>
      <Text style={[styles.textPrime, styles.textLightBlack, styles.textAlignCenter]} allowFontScaling={false}>
        You can create your first Routine with the{" "}
        <Text
          style={[styles.textPrime, styles.textOrange, styles.textUnderline]}
          allowFontScaling={false}
          onPress={props.onPressRoutineWizard}
        >Zamplo Routine Wizard</Text>
      </Text>
      </View>
    </View>
  );
};

export default (noResultBlock);
