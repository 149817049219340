import React from "react";
import { Text, Image, View } from "react-native";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers
} from "react-native-popup-menu";
import styles from "../../Css";
import { pendoTrackEvent } from "../../util/pendoConfig";

const { Popover } = renderers;

const reportViewPopupMenu = props => {
  return (
    <Menu renderer={Popover} rendererProps={{ preferredPlacement: "left" }}>
      <MenuTrigger onPress={() => pendoTrackEvent(props.eventNameMenuTrigger)}>
      <View 
        style={[styles.itemIconSingle, styles.itemSettingMenuSm]}
        nativeID={props.nativeIdMenuTrigger}> 
          <Image
            style={[styles.settingIcon, styles.settingIconDark]}
            source={require("../../assets/icons/settings.png")}
          />
      </View> 
      </MenuTrigger>
      <MenuOptions style={styles.dropDownList}>
        <View style={{padding: 1}} nativeID={props.nativeIdEdit}>
        <MenuOption style={styles.liItem} onSelect={props.onEdit}>
          <Text style={styles.listText} allowFontScaling= {false}>Edit</Text>
        </MenuOption>
        </View>
        <View style={{padding: 1}} nativeID={props.nativeIdDelete}>
        <MenuOption style={styles.liItem} onSelect={props.onDelete}>
          <Text style={styles.listText} allowFontScaling= {false}>Delete</Text>
        </MenuOption>
        </View>
      </MenuOptions>
    </Menu>
  );
};
export default reportViewPopupMenu;
