import React from "react";
import { Text, View } from "react-native";
import styles from "../../Css";
import Modal from "../Modal/modal";
import ModalLayout from "../ModalLayout/modalLayout";
import PrimaryButton from "../Button/primaryButton";
import PrimaryButtonWhite from "../Button/primaryButtonWhite";

const deletingConfirmation = (props) => {
  return (
    <Modal
      visible={props.visibleModal}
      onRequestClose={props.onRequestClose}
      onBackdropPress={props.onBackdropPress}
    >
      <ModalLayout
        contPosition={"start"}
        showClose={true}
        pdCom={true}
        hidePopup={props.onBackdropPress}
        modalSpecStyle={styles.comfirmModalMin}
      >
        <View style={[styles.popupContentWrapper, styles.flexCom]}>
          <View
            style={[styles.popMessageMainView, styles.flexCom, styles.flexJcCt]}
          >
            <View style={[styles.popMessageComView]}>
              <View style={[styles.popMessageTitleView]}>
                <Text
                  style={[
                    styles.textTitleBold,
                    styles.textThickGrey,
                    styles.textAlignCenter,
                  ]}
                  allowFontScaling={false}
                >
                  {props.alertTitle}
                </Text>
              </View>
            </View>
            {Boolean(props.alertBody) && (
              <View style={[styles.popMessageSmView, styles.secBgPdTop]}>
                <Text
                  style={[
                    styles.textPrime,
                    styles.textThickGrey,
                    styles.textAlignCenter,
                  ]}
                  allowFontScaling={false}
                >
                  {props.alertBody}
                </Text>
              </View>
            )}
          </View>
          <View style={[styles.popBtnView, styles.comMgTopSm]}>
            <View style={[styles.btnArea, styles.flexRow]}>
              <View style={styles.fieldBtn}>
                <PrimaryButtonWhite
                  btnText={props.btnTextOne ? props.btnTextOne : "Delete"}
                  btnPosition={"full"}
                  btnStyle={[styles.darkBtnBorder]}
                  touchableOnPress={props.handleConfirm}
                  btnTextStyle={styles.textColorGreyLight}
                  activityIndStyle={styles.indicatorLtDefaultSm}
                  activityInd={props.deleteWait}
                  disabled={props.deleteWait}
                />
              </View>
              <View style={styles.fieldBtnSpSm} />
              <View style={styles.fieldBtn}>
                <PrimaryButton
                  btnText={props.btnTextTwo ? props.btnTextTwo : "Cancel"}
                  btnPosition={"full"}
                  touchableOnPress={props.handleCancel}
                  activityInd={props.confirmWait}
                  btnShadow={true}
                  disabled={props.disabled || props.deleteWait}
                />
              </View>
            </View>
          </View>
        </View>
      </ModalLayout>
    </Modal>
  );
};
export default (deletingConfirmation);
