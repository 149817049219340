import * as actionTypes from "./actionTypes";
import * as axiosCommon from "../../axios/axios-common";
import * as url from "./urls";
import { logout, commonErrorHandler } from "./index";
import { clinicalTrailFormatter } from "../../util/clinicalTrailFormatter";

export const searchClinicalTrials = (searchTearm, callBack, searchTearms) => {
  let requestUrl = url.SEARCH_CLINICAL_TRIAL + searchTearm;
  return dispatch => {
    axiosCommon.axiosGET(requestUrl, res => {
      if (res.type === "SUCCESS") {

        const data = clinicalTrailFormatter(searchTearms, res.responseDto.data.search_results);

        dispatch(
          searchClinicalTrialSuccess(data)
        );
        callBack(true, data);
      } else {
        if (res.errorDto === "SESSION_TIME_OUT") {
          console.log("session time out; searchClinicalTrials");
          dispatch(logout());
        } else {
          if (res.errorDto.status === 404) {

            dispatch(searchClinicalTrialSuccess(res.errorDto.data));
            callBack(true, res.errorDto.data);
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
          }
        }
      }
    });
  };
};
export const searchClinicalTrialSuccess = data => {
  return {
    type: actionTypes.SEARCH_CLINICAL_TRIAL_SUCCESS,
    searchResults: data
  };
};

export const searchTearms = searchTearms => {
  return {
    type: actionTypes.SEARCH_TEARMS,
    searchTearms: searchTearms
  };
};
//Delete one clinical trial
export const deleteClinicalTrial = (
  clinicalTrialId,
  callBack,
  journalUserId
) => {
  let requestUrl = url.DELETE_CLINICAL_TRIAL;
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  let del = {
    clinicalTrialId: clinicalTrialId
  };
  return dispatch => {
    axiosCommon.axiosDELETE(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          dispatch(deleteClinicalTrialSuccess(clinicalTrialId));
          callBack(true);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; deleteClinicalTrial");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false);
          }
        }
      },
      del
    );
  };
};

export const deleteClinicalTrialSuccess = clinicalTrialId => {
  return {
    type: actionTypes.DELETE_CLINICAL_TRIAL_SUCCESS,
    clinicalTrialId: clinicalTrialId
  };
};

//delete all clinical trials
export const deleteAllClinicalTrials = (callBack, journalUserId) => {
  let requestUrl = url.DELETE_ALL_CLINICAL_TRIALS;
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosDELETE(requestUrl, res => {
      if (res.type === "SUCCESS") {
        dispatch(deleteAllClinicalTrialsSuccess());
        callBack(true);
      } else {
        if (res.errorDto === "SESSION_TIME_OUT") {
          console.log("session time out; deleteAllClinicalTrials");
          dispatch(logout());
        } else {
          dispatch(commonErrorHandler(res.errorDto.data.error));
          callBack(false);
        }
      }
    });
  };
};

export const deleteAllClinicalTrialsSuccess = () => {
  return {
    type: actionTypes.DELETE_ALL_CLINICAL_TRIALS_SUCCESS
  };
};

//delete multiple clinical trials
export const deleteClinicalTrialList = (
  clinicalTrialIds,
  callBack,
  journalUserId
) => {
  let requestUrl = url.DELETE_CLINICAL_TRIAL_LIST;
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  let del = {
    clinicalTrialIdList: clinicalTrialIds
  };
  return dispatch => {
    axiosCommon.axiosDELETE(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          dispatch(deleteFromNewClinicalTrialList(clinicalTrialIds));
          dispatch(deleteClinicalTrialListSuccess(clinicalTrialIds));
          callBack(true);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; deleteClinicalTrialList");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false);
          }
        }
      },
      del
    );
  };
};

export const deleteClinicalTrialListSuccess = clinicalTrialIdList => {
  return {
    type: actionTypes.DELETE_CLINICAL_TRIAL_LIST_SUCCESS,
    clinicalTrialIdList: clinicalTrialIdList
  };
};

export const deleteFromNewClinicalTrialList = clinicalTrialIdList => {
  return {
    type: actionTypes.DELETE_FROM_NEW_CLINICAL_TRIAL_LIST,
    clinicalTrialIdList: clinicalTrialIdList
  };
};

export const addClinicalTrialToDelete = id => {
  return {
    type: actionTypes.ADD_CLINICAL_TRIAL_TO_DELETE,
    clinicalTrialId: id
  };
};

export const removeClinicalTrialFromDelete = id => {
  return {
    type: actionTypes.REMOVE_CLINICAL_TRIAL_FROM_DELETE,
    clinicalTrialId: id
  };
};

export const resetClinicalTrials = () => {
  return {
    type: actionTypes.RESET_CLINICAL_TRIALS
  };
};

//add clinical trial
export const createClinicalTrial = (
  newClinicalTrial,
  callBack,
  journalUserId
) => {
  let requestUrl = url.ADD_CLINICAL_TRIAL;
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          //dispatch(addClinicalTrialSuccess(res.responseDto.data.clinicalTrial));
          dispatch(addClinicalTrial(res.responseDto.data));
          callBack(true, res.responseDto.data.clinicalTrial);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; createClinicalTrial");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      newClinicalTrial
    );
  };
};

export const addClinicalTrial = data => {
  return {
    type: actionTypes.ADD_CLINICAL_TRIAL,
    clinicalTrial: data.clinicalTrial
  };
};

//add multiple clinical trials
export const createClinicalTrialList = (
  newclinicalTrials,
  callBack,
  journalUserId
) => {
  let requestUrl = url.ADD_CLINICAL_TRIAL_LIST;
  let newclinicalTrialList = {
    clinicalTrialList: newclinicalTrials
  };
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          //dispatch(addClinicalTrialListSuccess(res.responseDto.data.clinicalTrialList));
          dispatch(addClinicalTrialList(res.responseDto.data));
          callBack(true, res.responseDto.data.clinicalTrialList);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; createClinicalTrialList");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      newclinicalTrialList
    );
  };
};

export const addClinicalTrialList = data => {
  return {
    type: actionTypes.ADD_CLINICAL_TRIAL_LIST,
    clinicalTrialList: data.clinicalTrialList
  };
};

export const addClinicalTrialToMyList = (id, newClinicalTrial) => {
  return {
    type: actionTypes.ADD_CLINICAL_TRIAL_TO_MY_LIST,
    newClinicalTrialId: id,
    newClinicalTrial: newClinicalTrial
  };
};

export const removeClinicalTrialFromMyList = id => {
  return {
    type: actionTypes.REMOVE_CLINICAL_TRIAL_FROM_MY_LIST,
    newClinicalTrialId: id
  };
};

export const resetNewClinicalTrials = () => {
  return {
    type: actionTypes.RESET_NEW_CLINICAL_TRIALS
  };
};

//save search clinical trial
export const saveSearchClinicalTrial = (
  savedSearchClinicalTrial,
  callBack,
  journalUserId
) => {
  let requestUrl = url.SAVE_SEARCH_CLINICAL_TRIAL;
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          if (savedSearchClinicalTrial.savedSearch.id === -1) {
            dispatch(addSavedSearchClinicalTrial(res.responseDto.data));
            callBack(true, res.responseDto.data.savedSearch);
          } else {
            dispatch(updateSavedSearchClinicalTrial(res.responseDto.data));
            callBack(true, res.responseDto.data.savedSearch);
          }
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; saveSearchClinicalTrial");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      savedSearchClinicalTrial
    );
  };
};
export const addSavedSearchClinicalTrial = data => {
  return {
    type: actionTypes.ADD_SAVED_SEARCH_CLINICAL_TRIAL,
    savedSearchClinicalTrial: data.savedSearch
  };
};

export const updateSavedSearchClinicalTrial = data => {
  return {
    type: actionTypes.UPDATE_SAVED_SEARCH_CLINICAL_TRIAL,
    savedSearchClinicalTrial: data.savedSearch
  };
};

export const resetClinicalTrialState = callBack => {
  return {
    type: actionTypes.RESET_CLINICAL_TRIAL_STATE
  };
};

export const resetSearchResults = () => {
  return {
    type: actionTypes.RESET_CLINICAL_TRIAL_SEARCH_RESULTS
  };
};

export const updateClinicaltrailDisclaimer = (
  clinicalTrialDisclaimer,
  journalUserId,
  callBack
) => {
  let requestUrl = url.CLINICALTRAIL_DISCLAIMER;
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          callBack(true);
          dispatch(
            updateClinicaltrailDisclaimerSuccess(clinicalTrialDisclaimer)
          );
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; updateClinicaltrailDisclaimer");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      { clinicalTrialDisclaimer: clinicalTrialDisclaimer }
    );
  };
};

const updateClinicaltrailDisclaimerSuccess = clinicalTrialDisclaimer => {
  return {
    type: actionTypes.UPDATE_CLINICALTRAIL_DISCLAIMER_SUCCESS,
    clinicalTrialDisclaimer: clinicalTrialDisclaimer
  };
};
