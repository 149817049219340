import React from "react";
import { Text } from "react-native";
import styles from "../../Css";

const helpProfileCondition = () => {
  return (
    <Text style={styles.textPrime}  allowFontScaling={false}>
        Conditions are listed on your reports. It is valuable information for your careteam.
    </Text>
  );
};

export default helpProfileCondition;
