import React, { useEffect, useState, useCallback } from "react";
import { Animated, StyleSheet, View } from "react-native";
import { TAB_BREAk_POINT_START } from "../../../../util/platformWindowConfig";

const ProgressBar = props => {
  const {
    height,
    progress,
    animated,
    indeterminate,
    progressDuration,
    indeterminateDuration,
    onCompletion,
    backgroundColor,
    surveyProgress,
    windowWidth
  } = props;

  const [timer] = useState(new Animated.Value(0));
  const [width] = useState(new Animated.Value(0));
  
  const indeterminateAnimation = Animated.timing(timer, {
    duration: indeterminateDuration,
    toValue: 1,
    useNativeDriver: false,
    isInteraction: false,
  });
  

  const startAnimation = useCallback(() => {
    if (indeterminate) {
      timer.setValue(0);
      Animated.loop(indeterminateAnimation).start();
    } else {
      Animated.timing(width, {
        duration: animated ? progressDuration : 0,
        toValue: progress,
        useNativeDriver: true,
      }).start(() => {
        onCompletion();
      });
    }
  }, [
    animated,
    indeterminate,
    indeterminateAnimation,
    onCompletion,
    progress,
    progressDuration,
    timer,
    width
  ]);

  const stopAnimation = useCallback(() => {
    if (indeterminateAnimation) indeterminateAnimation.stop();

    Animated.timing(width, {
      duration: 200,
      toValue: 0,
      useNativeDriver: false,
      isInteraction: false
    }).start();
  }, [indeterminateAnimation, width]);

  useEffect(() => {
    if (indeterminate || typeof progress === "number") {
      startAnimation();
    } else {
      stopAnimation();
    }
  }, [indeterminate, progress, startAnimation, stopAnimation]);

  const styleAnimation = () => {
    let scaler = surveyProgress && windowWidth > TAB_BREAk_POINT_START ? 5 : 3
    return indeterminate
      ? {
          transform: [
            {
              translateX: timer.interpolate({
                inputRange: [0, 0.5, 1],
                outputRange: [-0.6 * 320, -0.5 * 0.8 * 320, 0.7 * 320]
              })
            },
            {
              scaleX: timer.interpolate({
                inputRange: [0, 0.5, 1],
                outputRange: [0.0001, 0.8, 0.0001]
              })
            }
          ]
        }
      : {
          // width: width.interpolate({
          //   inputRange: [0, 100],
          //   outputRange: ["0%", "100%"]
          // })
          transform: [
            {
              translateX: width.interpolate({
                inputRange: [0, 50, 100],
                outputRange: [0, 50 * scaler, 100 * scaler]
              })
            },
          ]
        };
  };

  const styles = StyleSheet.create({
    container: {
      width: surveyProgress && windowWidth > TAB_BREAk_POINT_START ? 500 : 280,
      height,
      overflow: "hidden",
      borderRadius: 4,
    },
    progressBar: {
      flex: 1,
      borderRadius: -(height / 2)
    },
    containerWrapper: {
      borderWidth: 1, 
      borderColor: "#404041", 
      padding: 2, 
      backgroundColor: "#ffffff",
      borderRadius: (height + 6) / 2
    },
    bgCommon: {
      backgroundColor: "#E5E5E5"
    },
    bgSurvey: {
      backgroundColor: "#FFFFFF"
    },
  });

  return (
    <View style={[surveyProgress && styles.containerWrapper]}>
      <Animated.View style={[styles.container, {backgroundColor}]}>
        <Animated.View
          style={[
            styles.progressBar,
            surveyProgress
              ? styles.bgSurvey
              : styles.bgCommon,
            {
              ...styleAnimation()
            }
          ]}
        />
      </Animated.View>
    </View>
  );
};

ProgressBar.defaultProps = {
  state: "black",
  height: 2,
  progress: 0,
  animated: true,
  indeterminate: false,
  indeterminateDuration: 1100,
  progressDuration: 1100,
  onCompletion: () => {}
};

export default ProgressBar;
