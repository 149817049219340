import * as actionType from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
  
  const initialState = {
    fetchAllHealthJourneyEvents: {
        fetching: false,
        error: { status: false, message: "" },
        data: {},
      },
    fetchHealthJourneyEvent: {
        fetching: false,
        error: { status: false, message: "" },
        data: [],
      },

    deleteHealthJourneyEvent: {
      isLoading: false,
      response: null,
      error: null
    },
    saveAndUpdateHealthJourneyEvent: {
        fetching: false,
        error: { status: false, message: "" },
        data: null,
    },
    fetchHealthJourneyTagsList: {
      fetching: false,
      error: { status: false, message: "" },
      data: [],
    },
    saveHealthJourneyTagsObject: {
      fetching: false,
      error: { status: false, message: "" },
      data: {},
    },
    saveHealthJourneyCategoryObject: {
      fetching: false,
      error: { status: false, message: "" },
      data: {},
    },
  };

  const healthJourneyReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionType.FETCH_ALL_HEALTH_JOURNEY_EVENT:
          return {
            ...state,
            fetchAllHealthJourneyEvents: {
              ...state.fetchAllHealthJourneyEvents,
              fetching: true,
              error: { status: false, message: "" },
            }
          };
      case actionType.FETCH_ALL_HEALTH_JOURNEY_EVENT_SUCCESS:
          return {
            ...state,
            fetchAllHealthJourneyEvents: {
              ...state.fetchAllHealthJourneyEvents,
              fetching: false,
              data: action.payload,
              error: { status: false, message: "" },
            }
          };
      case actionType.FETCH_ALL_HEALTH_JOURNEY_EVENT_FAILS:
          return {
            ...state,
            fetchAllHealthJourneyEvents: {
              ...state.fetchAllHealthJourneyEvents,
              fetching: false,
              error: { status: true, message: action.payload },
              data: {}
            }
          };
    
      case actionType.FETCH_HEALTH_JOURNEY_EVENT:
          return {
            ...state,
            fetchHealthJourneyEvent: {
              ...state.fetchHealthJourneyEvent,
              fetching: false,
              error: { status: false, message: "" },
            }
          };
      case actionType.FETCH_HEALTH_JOURNEY_EVENT_SUCCESS:
          return {
            ...state,
            fetchHealthJourneyEvent: {
              ...state.fetchHealthJourneyEvent,
              fetching: false,
              data: action.payload,
              error: { status: false, message: "" },
            }
          };
      case actionType.FETCH_HEALTH_JOURNEY_EVENT_FAILS:
          return {
            ...state,
            fetchHealthJourneyEvent: {
              ...state.fetchHealthJourneyEvent,
              fetching: false,
              error: { status: true, message: action.payload },
              data:[]
            }
          };
      case actionType.SAVE_AND_UPDATE_HEALTH_JOURNEY_EVENT:
        return {
            ...state,
            saveAndUpdateHealthJourneyEvent: {
            fetching: true,
            error: { status: false, message: "" },
            data: null,
            },
        };
      case actionType.SAVE_AND_UPDATE_HEALTH_JOURNEY_EVENT_SUCCESS:
        return {
            ...state,
            saveAndUpdateHealthJourneyEvent: {
            fetching: false,
            error: { status: false, message: "" },
            data: action.payload,
            },
        };
      case actionType.SAVE_AND_UPDATE_HEALTH_JOURNEY_EVENT_FAILS:
        return {
            ...state,
            saveAndUpdateHealthJourneyEvent: {
            fetching: false,
            error: { status: true, message: action.payload },
            data: null,
            },
        };

      case actionType.DELETE_HEALTH_JOURNEY_EVENT:
          return {
            ...state,
            deleteHealthJourneyEvent: {
              fetching: true,
              error: { status: false, message: "" },
              data: null,
            }
          };
      case actionType.DELETE_HEALTH_JOURNEY_EVENT_SUCCESS:
        return {
          ...state,
          deleteHealthJourneyEvent: {
            fetching: false,
            error: { status: false, message: "" },
            data: action.payload,
          },
        };
      case actionType.DELETE_HEALTH_JOURNEY_EVENT_FAILS:
        return {
          ...state,
          deleteHealthJourneyEvent: {
            fetching: false,
            error: { status: true, message: action.payload },
            data: null,
          },
        };
        case actionType.FETCH_HEALTH_JOURNEY_TAGS_LIST_SUCCESS:
          return {
            ...state,
            fetchHealthJourneyTagsList: {
              ...state.fetchHealthJourneyTagsList,
              fetching: false,
              data: action.payload,
              error: { status: false, message: "" },
            }
          };
      case actionType.FETCH_HEALTH_JOURNEY_TAGS_LIST_FAILS:
          return {
            ...state,
            fetchHealthJourneyTagsList: {
              ...state.fetchHealthJourneyTagsList,
              fetching: false,
              error: { status: true, message: action.payload },
              data:[]
            }
          };
      case actionType.FETCH_HEALTH_JOURNEY_FILTER_TAGS_OBJECT:
          return {
            ...state,
            saveHealthJourneyTagsObject: {
              fetching: false,
              error: { status: true, message: "" },
              data:action.payload
            }
          };
      case actionType.FETCH_HEALTH_JOURNEY_FILTER_CATEGORY_OBJECT:
          return {
            ...state,
            saveHealthJourneyCategoryObject: {
              fetching: false,
              error: { status: true, message: "" },
              data:action.payload
            }
          };
      case actionType.RESET_HEALTH_JOURNEY_EVENTS:
        return {
          fetchAllHealthJourneyEvents: {
            fetching: false,
            error: { status: false, message: "" },
            data: {},
          },
          fetchHealthJourneyEvent: {
              fetching: false,
              error: { status: false, message: "" },
              data: [],
            },
      
          deleteHealthJourneyEvent: {
            isLoading: false,
            response: null,
            error: null
          },
          saveAndUpdateHealthJourneyEvent: {
              fetching: false,
              error: { status: false, message: "" },
              data: null,
          },
          fetchHealthJourneyTagsList: {
            fetching: false,
            error: { status: false, message: "" },
            data: [],
          },
          saveHealthJourneyTagsObject: {
            fetching: false,
            error: { status: false, message: "" },
            data: {},
          },
          saveHealthJourneyCategoryObject: {
            fetching: false,
            error: { status: false, message: "" },
            data: {},
          },
        }
      default:
        return state;
    }
  };
  
  export default healthJourneyReducer;
  