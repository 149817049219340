import React from "react";
import { View, Image, Text, TouchableOpacity } from "react-native";
import styles from "../../Css";
import { TAB_BREAk_POINT_START } from "../../util/platformWindowConfig";

const collapseItemTitle = (props) => {
  const { onTabPress } = props;
  let addRoutineTitle="Create a new Routine";
  return (
    <View
      style={[
        styles.tabCollpaseTitleRow,
        props.lastChild
          ? styles.tabCollpaseBorderNone
          : styles.tabCollpaseBorder,
        props.activeTab && props.itemTitle !== addRoutineTitle ? styles.activeTabOrangeColor : styles.defaultTabColor,
      ]}
    >
      <TouchableOpacity onPress={onTabPress}>
        <View style={[styles.tabCollpaseTitle]}>
          <View style={[styles.statusTickImage]}>
            {/* {!isDone && (
              <Image
                style={styles.statusTick}
                source={require('../../assets/icons/empty-tick.png')}
              />
            )}

            {isDone && (
              <Image
                style={styles.statusTick}
                source={require('../../assets/icons/filled-tick.png')}
              />
            )} */}
            {props.itemTitle === addRoutineTitle && (
              <Image
                style={styles.statusTick}
                source={require("../../assets/icons/item-add.png")}
              />
            )}
          </View>
          <View
            style={[
              props.itemTitle === addRoutineTitle
                ? styles.tabCollpaseTitleView
                : styles.tabCollpaseTitleViewCom,
            ]}
          >
            <Text
              style={[
                styles.textPrime,
                props.activeTab ? styles.textOrange : styles.defaultText,
                props.itemTitle === addRoutineTitle
                  ? styles.textOrange
                  : null,
              ]}
              allowFontScaling={false}
            >
              {props.itemTitle}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
      {/* Add when collapse active */}
      {props.windowWidth > TAB_BREAk_POINT_START && Boolean(props.activeTab) && (
        <View style={styles.collapseArrow}>
          <Image
            style={styles.collapseViewIcon}
            source={require("../../assets/icons/collapse-view.png")}
          />
        </View>
      )}
    </View>
  );
};

export default (collapseItemTitle);
