import React, { Fragment, useState } from "react";
import { View, Text, Platform } from "react-native";
import moment from "moment";
import EventContentBox from "./EventContentBox";
import ViewMoreText from '../../../components/ViewMoreText/index';
import MenuOptionPopover from "./MenuOptionPopover";
import PublishedBy from "./PublishedBy";
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import ViewEventDescription from "../HealthJourneyEvents/ViewDescription";
import * as constants from '../HealthJourneyConstant';
import { ANDROID, WEB } from "../../../util/platformWindowConfig";

const HealthJourneyEventCard = props =>  {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {data, editEvent, deleteEvent, onPressViewItem, openedYearAndId, journalEntries, disbleOperations, onPressCollapse, userData, setActiveBubble, activeBubbleId, windowWidth, eventCountPerYear} = props;
  const {id, categoryType, startDate, endDate, eventName, description, publishedBy, publisherName, tags} = data;
  const careName = publisherName ? publisherName.split(" ").length > 1 ? (publisherName.split(" ")[0][0] + publisherName.split(" ")[1][0]).toUpperCase() : (publisherName[0] + publisherName[1]).toUpperCase() : "";
  let tagsArray = (tags ? tags.includes(",") ? tags.split(",") : [tags] : []).filter(t => t);
  const [openCategoryType, setOpenCategoryType] = useState(false);
  const [openAttachment, setOpenAttachment] = useState(false);

  const isHaveCategoryData = () => {
    return Boolean(
      Boolean(
        categoryType === constants.eventTypes.MEDICATIONS ||
          categoryType === constants.eventTypes.SYMPTOMS ||
          categoryType === constants.eventTypes.HEATHDATA,
      ) && Boolean(
        (data.symptoms && data.symptoms.length > 0) ||
        (data.medications && data.medications.length > 0) ||
        (data.healthData && data.healthData.length > 0)
      )
    );
  }

  const getRelevantCategoryData = () => {
    switch (categoryType) {
      case constants.eventTypes.MEDICATIONS:
        return {
          data: data.medications,
          view: `View ${data.medications.length} ${data.medications.length !== 1 ? 'Meds/Supplements' : 'Med/Supplement'}`,
          hide: `Hide ${data.medications.length} ${data.medications.length !== 1 ? 'Meds/Supplements' : 'Med/Supplement'}`
        };
      case constants.eventTypes.HEATHDATA:
        return {
          data: data.healthData,
          view: `View ${data.healthData.length} ${'Health Data'}`,
          hide: `Hide ${data.healthData.length} ${'Health Data'}`
        };
      case constants.eventTypes.SYMPTOMS:
        return {
          data: data.symptoms,
          view: `View ${data.symptoms.length} ${data.symptoms.length !== 1 ? 'Symptoms' : 'Symptom'}`,
          hide: `Hide ${data.symptoms.length} ${data.symptoms.length !== 1 ? 'Symptoms' : 'Symptom'}`
        };
      default:
        return {};
    }
  }

  return (
    <Fragment>
      <View style={[styles.mgTopLg, styles.flexRow,styles.flexJcFe, stylesRes.pdMbTwentyLgDecZero]} dataSet={{media: ids.pdMbTwentyLgDecZero}}>
        <View style={[styles.tpFourteen]}>
          <View>
            <View style={styles.circleHealthJourneyCrd} />
          </View>
          {eventCountPerYear > 1 &&
            <View
              style={[
                styles.ltFive,
                styles.tpSixteen,
                styles.lineHealthJourneyCrd,
                styles.barBotSp
                
              ]}
            />
          }
        </View>
        <View
          style={[
            styles.bgWhite,
            styles.healthJourneyCardWrapper,
            stylesRes.healthJourneyLtMg,
            stylesRes.wdWrapperHelathJrn,
            
          ]}
          dataSet={{media: ids.wdWrapperHelathJrn}}>
          <View
            style={[
              styles.flexAiCt,
              styles.flexJcSb,
              styles.flexRow,
              styles.healthJourneyTpTb,
              styles.messageTopBotPdSm,
              styles.flexWrapView,
              styles.hundredMaxWd,
              styles.ltPdLg

            ]}>
            <View style={styles.flexRow}>
            <Text style={[styles.textPrimeLg, styles.textWeightFiveHundred]}>
              {startDate && startDate.length== 10? 
              moment(startDate).format("DD-MMM-YYYY"):moment(startDate).format("MMM-YYYY")}
            </Text>
            {endDate !== null &&
              <Text style={[styles.textGrey, styles.textWeightFiveHundred]}>
                {' to '}
                <Text style={styles.textPrimeLg}>
                  {endDate && endDate.length ==10?
                  moment(endDate).format("DD-MMM-YYYY"):moment(endDate).format("MMM-YYYY")}
                </Text>
              </Text>
            }
            </View>
            <MenuOptionPopover
              placement="bottom"
              triggerIcon={require('../../../assets/health-journey-icons/menu-trigger-icon-black.png')}
              menuOptions={[
                {title: constants.EDIT, onSelect: () => editEvent(data, onPressCollapse)},
                {title: constants.DELETE, onSelect: () => deleteEvent(id)},
              ]}
              disabled={disbleOperations}
            />
          </View>
          <View
            style={[
              styles.ltPdLg,
              styles.sumDataPdSm,
              styles.ninetySevenMxWd,

            ]}>
            <Text
              style={[
                styles.textTitleBold,
                styles.txtGreyHighlightCr,
                stylesRes.txtTruncateWrapper
              ]}
              dataSet={{media: ids.txtTruncateWrapper}}
              allowFontScaling={false}
              numberOfLines={1}
              nativeID={"textTruncate"} 
              ellipsizeMode='tail'
              >
              {eventName}
            </Text>
            {description !== null && description.trim() !== '' &&
            <View
              style={[
                stylesRes.pdLeftRem,
                //styles.flexWrapView,
                styles.ninetySevenMxWd,
                Platform.OS === ANDROID && styles.viewMgTopMd
              ]}
              dataSet={{media: ids.pdLeftRem}}>

              <ViewEventDescription description={description} numberOfLines={Platform.OS === WEB ? 2 : 1} />
              {/* <ViewMoreText
                longText={description.replace(/\n/g, " ")}
                moreText={'Read more'}
                lessText={'Read less'}
                numberOfLines={Platform.OS === "web" ? 2 : 1}
                customStyle={styles.seaBluTxtColor}
              /> */}
            </View>
            }
          </View>
          <View >
            <View style={[styles.flexCol, styles.secBgPdTop,{minHeight:70},openCategoryType ? { zIndex: 999, paddingTop: 15 } : { zIndex: 997 }, ]}>
              {data.attachments && data.attachments.length > 0 && (
                <EventContentBox 
                  type={constants.eventTypes.ATTACHMENTS}
                  data={data.attachments}
                  colapsibleTitle={`View ${data.attachments.length} ${data.attachments.length !== 1 ? 'Attachments' : 'Attachment'}`}
                  expandTitle={`Hide ${data.attachments.length} ${data.attachments.length !== 1 ? 'Attachments' : 'Attachment'}`}
                  chunkSize={2}
                  cardId={data.id}
                  year={data.startDate.split("-")[0]}
                  openedYearAndId={openedYearAndId}
                  updateOpenAttachment={setOpenAttachment}
                  windowWidth={windowWidth}
                />
              )}

              {isHaveCategoryData() && (
                <EventContentBox 
                  type={categoryType}
                  data={getRelevantCategoryData().data}
                  colapsibleTitle={getRelevantCategoryData().view}
                  expandTitle={getRelevantCategoryData().hide}
                  chunkSize={3}
                  userData={userData}
                  year={data.startDate.split("-")[0]}
                  cardId={data.id}
                  onPressViewItem={onPressViewItem}
                  openedYearAndId={openedYearAndId}
                  journalEntries={journalEntries}
                  setActiveBubble={setActiveBubble}
                  activeBubbleId={activeBubbleId}
                  updateOpenCategoryType={setOpenCategoryType}
                  windowWidth={windowWidth}
                />
              )}
            </View> 
            <View style={[(openAttachment || openCategoryType) && {marginTop:0},{zIndex: 998}]}>
              {tagsArray && tagsArray.length > 0 && (
                <EventContentBox 
                  type={'TAGS'}
                  data={tagsArray}
                  colapsibleTitle={`View ${tagsArray.length} ${tagsArray.length !== 1 ? 'Tags' : 'Tag'}`}
                  expandTitle={`Hide ${tagsArray.length} ${tagsArray.length !== 1 ? 'Tags' : 'Tag'}`}
                  chunkSize={3}
                  cardId={data.id}
                  year={data.startDate.split("-")[0]}
                  openedYearAndId={openedYearAndId}
                  windowWidth={windowWidth}
                />
              )}
            </View>
          </View>
          {publishedBy !== constants.eventPublishedBy.SELF &&
            <PublishedBy 
              publishedBy={publishedBy}
              name={publisherName}
              badge={careName}
            />
          }
        </View>
      </View>
    </Fragment>
  );
};

export default HealthJourneyEventCard;