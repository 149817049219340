import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import TickButton from '../../components/CheckBox/CheckBox';
import * as validator from '../../validator/validator';
import Signup from '../../components/Signup/Signup';
import UserLicenseAgreement from './UserLicenseAgreement';
import LearnHowPrivacyPolicy from './LearnHowPrivacyPolicy';
import * as RNLocalize from 'react-native-localize';
import {Keyboard, Platform} from 'react-native';

import {Amplify, Auth} from 'aws-amplify';
import {
  IDENTITY_POOL_ID,
  REGION,
  IDENTITY_POOL_REGION,
  USER_POOL_ID,
  USER_POOL_WEB_CLIENT_ID,
} from '../../util/cognitoConfig';
import CheckYourEmail from '../../components/Signup/CheckYourEmail';
import { ANDROID, IOS, WEB } from '../../util/platformWindowConfig';

Amplify.configure({
  Auth: {
    identityPoolId: IDENTITY_POOL_ID,
    region: REGION,
    identityPoolRegion: IDENTITY_POOL_REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    clientMetadata: {myCustomKey: 'myCustomValue'},
  },
});

class SignUpScreen extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    const {selectedUser, windowHeight, windowWidth, accessCode, email, firstName, lastName, emailVerificationPhase, user} = props;

    const {
      //firstName,
      //lastName,
      //   email,
      password,
      confirmPassword,
      ticked,
    } = selectedUser ? selectedUser : {};
    this.state = {
      firstName: firstName ? firstName : '',
      lastName: lastName ? lastName : '',
      email: email ? email : '',
      password: password,
      confirmPassword: confirmPassword,
      passwordVisible: true,
      validateFN: true,
      validateLN: true,
      emailValidate: true,
      passwordValidate: true,
      cpasswordValidate: true,
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      ticked: ticked ? ticked : this.props.isAgreed,
      authSuccessSpinner: false,
      timezone: RNLocalize.getTimeZone(),
      staySigned: Platform.OS === ANDROID || Platform.OS === IOS,
      emailVerificationPhase: emailVerificationPhase,
      showLearnHowPrivacyPolicy: false,
      iHaveAccessCode: accessCode ? true : false,
      accessCodeValidate: true,
      accessCode: accessCode ? accessCode : '',
      user: user
    };
    this.tickedValue= false;
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth} = props;
    if (
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  componentDidMount = () => {
    setTimeout(() => {
      if (this.textInput) {
        this.textInput.focus();
      }
    }, 200);
  };

  handleOnSubmitSignup = () => {
    Keyboard.dismiss();
    if (this.signInUpValidation()) {
      this.setState({
        authSuccessSpinner: true,
        error: false,
        errorDescription: '',
      });
      this.props.updateSignupEmail(this.state.email);
      const username = this.state.email.toLowerCase();
      const firstName = this.state.firstName;
      const lastName = this.state.lastName;
      const password = this.state.password;
      const timezone = this.state.timezone;
      const staySigned = this.state.staySigned;
      const accessCode = this.state.accessCode;
      const validationData = {
        gcaptchaResponse: null,
      };
      Auth.signUp({
        username,
        password,
        attributes: {
          email: username,
        },
        clientMetadata: {
          firstName: firstName,
          lastName: lastName,
          staySignIn: staySigned ? 'true' : 'false',
          timezone: timezone,
          accessCode: accessCode,
        },
        validationData: [], //optional
      })
        .then(data => {
          Auth.signIn({
            username, // Required, the username
            password, // Optional, the password,
            validationData,
          }).then(user => {
            this.setState({
              user: user,
            });
            Platform.OS !== WEB
            ? this.shallowLogin(() => {
                this.setState({
                  user: user,
                  emailVerificationPhase: true,
                });
              })
            : this.setState({
                emailVerificationPhase: true,
              });
          });
        })
        .catch(err => {
          console.log(err);
          if(err.code === 'UserNotConfirmedException') {

          }
          else if (err.code === 'InvalidParameterException') {
            this.setState({
              error: true,
              errorDescription: 'Please enter a valid email address.',
              authSuccessSpinner: false,
            });
          } else if (
            err.code === 'UserLambdaValidationException' &&
            err.message ===
              'PostConfirmation failed with error Post confirmation phiJournal core registration failed.'
          ) {
            this.setState({
              error: true,
              errorDescription:
                'Invalid or wrong access token.',
              authSuccessSpinner: false,
            });
          } else {
            this.setState({
              error: true,
              errorDescription: err.message,
              authSuccessSpinner: false,
            });
          }
        });
    }
  };

  signInUpValidation = () => {
    const {
      password,
      confirmPassword,
      firstName,
      lastName,
      email,
      ticked,
      passwordVisible,
      iHaveAccessCode,

      accessCode,
    } = this.state;

    let validateFN = true;
    let validateLN = true;
    let emailValidate = true;
    let cpasswordValidate = true;
    let errorDescription = '';
    let error = false;
    let passwordValidate = true;
    let passwordError = '';
    let cpasswordError = '';
    let accessCodeValidate = true;
    if (!firstName || !firstName.trim()) {
      validateFN = false;
    }
    if (!lastName || !lastName.trim()) {
      validateLN = false;
    }
    if (!email || !email.trim()) {
      emailValidate = false;
    }
    if (email && !validator.validateEmail(email)) {
      emailValidate = false;
    }
    if (!password || !password.trim()) {
      passwordValidate = false;
      passwordError = 'Enter password';
    }
    if (passwordVisible && !confirmPassword) {
      cpasswordValidate = false;
      cpasswordError = 'Confirm Password';
    }
    if (!ticked && !this.tickedValue) {
      error = true;
      errorDescription = 'Please accept User License Agreement';
    }

    if (password && password.length < 8 && passwordValidate) {
      passwordValidate = false;
      passwordError = 'Minimum length is 8';
    } else if (
      passwordVisible &&
      confirmPassword &&
      password &&
      password !== confirmPassword
    ) {
      cpasswordValidate = false;
      cpasswordError = 'Please make sure your passwords match';
    }

    if (iHaveAccessCode && (!accessCode || !accessCode.trim())) {
      accessCodeValidate = false;
    }

    this.setState({
      validateFN,
      validateLN,
      emailValidate,
      cpasswordValidate,
      passwordValidate,
      errorDescription,
      error,
      passwordError,
      cpasswordError,
      accessCodeValidate,
    });

    return (
      validateFN &&
      validateLN &&
      emailValidate &&
      cpasswordValidate &&
      passwordValidate &&
      !error &&
      accessCodeValidate
    );
  };

  handleOnChangeEmail = email => {
    this.setState({
      email: email,
      error: false,
      errorDescription: '',
    });
  };

  handleOnChangePassword = password => {
    let passwordValidate = false;
    let passwordError = 'Enter password';
    if (password) {
      passwordValidate = true;
      passwordError = '';
    }

    this.setState({
      password: password,
      passwordValidate: passwordValidate,
      error: false,
      errorDescription: '',
      passwordError: passwordError,
    });
  };

  handleOnChangeConfirmPassword = confirmPassword => {
    let cpasswordValidate = false;
    let cpasswordError = 'Confirm password';
    if (confirmPassword) {
      cpasswordValidate = true;
      cpasswordError = '';
    }
    this.setState({
      confirmPassword: confirmPassword,
      cpasswordValidate: cpasswordValidate,
      error: false,
      errorDescription: '',
      cpasswordError: cpasswordError,
    });
  };

  handleOnChangeAccessCode = accessCode => {
    this.setState({
      accessCode: accessCode,
      accessCodeValidate: true,
      error: false,
      errorDescription: '',
    });
  };

  handlePasswordVisibility = () => {
    this.setState(prevState => {
      return {
        passwordVisible: !prevState.passwordVisible,
        cpasswordValidate: true,
      };
    });
  };

  handleOnChangeFN = firstName => {
    let validateFN = false;
    if (firstName) {
      validateFN = true;
    }
    this.setState({
      firstName: firstName,
      validateFN: validateFN,
      error: false,
      errorDescription: '',
    });
  };

  handleOnChangeLN = lastName => {
    let validateLN = false;
    if (lastName) {
      validateLN = true;
    }
    this.setState({
      lastName: lastName,
      validateLN: validateLN,
      error: false,
      errorDescription: '',
    });
  };

  iAgreeButton = () => {
    if (this.state.ticked) {
      return (
        <TickButton
          imagePath={'tickButton'}
          toggleTickCallback={toggleVal => {
            this.ticked(false);
          }}
          tickStyle={{
            marginTop: 0,
            marginRight: 2,
            paddingBottom: 10,
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 7,
          }}
          styleType={'curve'}
        />
      );
    } else {
      return (
        <TickButton
          imagePath={'untickButton'}
          toggleTickCallback={toggleVal => {
            this.ticked(true);
          }}
          tickStyle={{
            marginTop: 0,
            marginRight: 2,
            paddingBottom: 10,
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 7,
          }}
          styleType={'curve'}
        />
      );
    }
  };
  ticked(toggleVal) {
    this.tickedValue= toggleVal;
    if(toggleVal){
      const {
        confirmPassword,
        passwordVisible,
        iHaveAccessCode,
        accessCode,
        email
      } = this.state;
      let cpasswordValidate = true;
      let accessCodeValidate = true;
      let cpasswordError = '';
      if (iHaveAccessCode && !accessCode) {
        accessCodeValidate = false;
      } else if (passwordVisible && !confirmPassword) {
        cpasswordValidate = false;
        cpasswordError = 'Confirm Password';
      }

      this.setState({
        ticked: toggleVal,
        error: false,
        errorDescription: '',
        cpasswordValidate: cpasswordValidate,
        cpasswordError: cpasswordError,
        accessCodeValidate: accessCodeValidate,
        emailValidate: validator.validateEmail(email)
      });
      this.signInUpValidation()
    }else{
      this.setState({
        ticked: toggleVal,
        error: false,
        errorDescription: '',
        cpasswordValidate: true,
        accessCodeValidate: true,
        validateFN: true,
        validateLN: true,
        emailValidate: true,
        passwordValidate: true
      });
    }

  }

  confirmUserSignUpWithCode = (verificationCode, callBack) => {
    const username = this.state.email.toLowerCase();
    const password = this.state.password;
    const validationData = {
      gcaptchaResponse: null,
    };
    Auth.verifyCurrentUserAttributeSubmit('email', verificationCode)
      .then(data => {
        Auth.signIn({
          username,
          password,
          validationData,
        }).then(user => {
          this.setState({user: user}, () => {
            callBack(true);
            setTimeout(() => {
              this.login();
            }, 2500);
          });
        });
      })
      .catch(err => {
        if (
          err.code === 'CodeMismatchException' ||
          err.code === 'ExpiredCodeException'
        ) {
          callBack(false, 'Error! Looks like you entered the wrong code');
        } else {
          callBack(false, err.message);
        }
      });
  };

  shallowLogin = (callback) => {
    this.props.signup(this.state.user, this.state.staySigned, true, success => {
      if (success) {
        callback();
      }
    });
  };

  login = () => {
    this.props.signup(this.state.user, this.state.staySigned, false, success => {
      if (success) {
        this.props.onFetachAllJournals(() => {
          const addNewUser = {
            user: {
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              timezone: this.state.timezone,
            },
          };

          this.props.updateUser(addNewUser, () => {
            this.props.redrictDashboard();
          });
        });
      }
      this.setState({authSuccessSpinner: false});
    });
  };

  resendEmailVerification = callBack => {
    Auth.verifyCurrentUserAttribute('email')
      .then(() => {
        callBack(true);
      })
      .catch(err => {
        console.log(err);
        callBack(false, err.message);
      });
  };

  skipEmailVerification = () => {
    this.login();
  };

  onChangeText = (type, val) => {
    switch (type) {
      case 'FIRST_NAME':
        this.handleOnChangeFN(val);
        break;
      case 'LAST_NAME':
        this.handleOnChangeLN(val);
        break;
      case 'EMAIL':
        this.handleOnChangeEmail(val);
        break;
      case 'PASSWORD':
        this.handleOnChangePassword(val);
        break;
      case 'CONFIRM_PASSWORD':
        this.handleOnChangeConfirmPassword(val);
        break;
      case 'ACCESSCODE':
        this.handleOnChangeAccessCode(val);
        break;

      default:
        break;
    }
  };

  onFocus = type => {
    const {password, firstName, lastName, confirmPassword} = this.state;
    switch (type) {
      case 'FIRST_NAME':
        this.setState({validateFN: true});

        break;
      case 'LAST_NAME':
        let validateFN = true;
        if (!firstName || !firstName.trim()) {
          validateFN = false;
        }
        this.setState({validateLN: true, validateFN: validateFN});
        break;
      case 'EMAIL':
        let validateLN = true;
        if (!lastName || !lastName.trim()) {
          validateLN = false;
        }
        this.setState({emailValidate: true, validateLN: validateLN});
        break;
      case 'PASSWORD':
        this.setState({
          passwordValidate: true,
          emailValidate: validator.validateEmail(this.state.email),
        });
        break;
      case 'CONFIRM_PASSWORD':
        let passwordValidate = true;
        let passwordError = '';
        if (!password || !password.trim()) {
          passwordValidate = false;
          passwordError = 'Enter password';
        }
        this.setState({
          cpasswordValidate: true,
          passwordValidate: passwordValidate,
          passwordError: passwordError,
        });
        break;

      case 'ACCESSCODE':
        let cpasswordValidate = true;
        let cpasswordError = '';
        if (!confirmPassword || !confirmPassword.trim()) {
          cpasswordValidate = false;
          cpasswordError = 'Confirm password';
        }
        this.setState({
          accessCodeValidate: true,
          cpasswordValidate: cpasswordValidate,
          cpasswordError: cpasswordError,
        });
        break;
      default:
        break;
    }
  };

  render() {
    let fullHeight = this.state.windowHeight;
    let minHeight = fullHeight;
    let fullWidth = this.state.windowWidth;
    if (this.state.emailVerificationPhase) {
      return (
        <CheckYourEmail
          windowHeightView={fullHeight}
          windowHeightSmView={minHeight}
          windowWidthView={fullWidth}
          bgBlue={true}
          contentCenter={false}
          privacyLink={true}
          email={this.props.signupEmail}
          skipEmailVerification={this.skipEmailVerification}
          resendEmailVerification={this.resendEmailVerification}
          toggleLearnHowPrivacyPolicy={() => {
            this.setState({showLearnHowPrivacyPolicy: true});
          }}
         />
      );
    }
    return (
      <React.Fragment>
        <Signup
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          email={this.state.email}
          handlePasswordVisibility={this.handlePasswordVisibility}
          handleOnSubmitSignup={this.handleOnSubmitSignup}
          validateFN={this.state.validateFN}
          validateLN={this.state.validateLN}
          iAgreeButton={this.iAgreeButton()}
          agreed={!this.state.ticked}
          emailChecker={() => {
            if (this.state.email)
              this.setState({
                emailValidate: validator.validateEmail(this.state.email),
              });
          }}
          {...this.state}
          textInput={input => (this.textInput = input)}
          toggleUserLicenseAgreement={() => {
            Keyboard.dismiss();
            this.props.toggleUserLicenseAgreement();
          }}
          windowHeightView={fullHeight}
          windowHeightSmView={minHeight}
          windowWidthView={fullWidth}
          bgBlue={true}
          contentCenter={true}
          passwordError={this.state.passwordError}
          onChangeText={this.onChangeText}
          onFocus={this.onFocus}
          redrictLogin={this.props.redrictLogin}
          cpasswordValidate={this.state.cpasswordValidate}
          toggleLearnHowPrivacyPolicy={() => {
            this.setState({showLearnHowPrivacyPolicy: true});
          }}
          error={this.state.error}
          errorDescription={this.state.errorDescription}
          cpasswordError={this.state.cpasswordError}
          iHaveAccessCode={this.state.iHaveAccessCode}
          setIHaveAccessCode={() =>
            this.setState({
              iHaveAccessCode: !this.state.iHaveAccessCode,
              accessCode: '',
              error: false,
              errorDescription: ''
            })
          }
          accessCode={this.state.accessCode}
          accessCodeValidate={this.state.accessCodeValidate}
        />
        <UserLicenseAgreement onAcceptDecline={value => this.ticked(value)} />
        {Boolean(this.state.showLearnHowPrivacyPolicy) &&
          <LearnHowPrivacyPolicy
            showLearnHowPrivacyPolicy={this.state.showLearnHowPrivacyPolicy}
            toggleLearnHowPrivacyPolicy={() => {
              this.setState({showLearnHowPrivacyPolicy: false});
            }}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorDescription: (
      (((state.auth || {}).errorDescription || {}).response || {}).data || {}
    ).error,
    error: state.auth.error,
    emailVerificationPhase: state.auth.emailVerificationPhase,
    user: state.auth.cognitoUser,
    selectedUser: state.setup.selectedUser,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    signupEmail: state.uistate.email,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signup: (user, staySigned, shallow, callBack) =>
      dispatch(actions.signup(user, staySigned, shallow, callBack)),
    onFetachAllJournals: callBack =>
      dispatch(actions.fetchAllJournalEntries(callBack)),
    updateUser: (addNewUser, callBack) =>
      dispatch(actions.updateUser(addNewUser, callBack)),
    toggleUserLicenseAgreement: () =>
      dispatch(actions.showUserLicenseAgreement()),
    updateSignupEmail: (email) =>
    dispatch(actions.updateSignupEmail(email)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpScreen);
