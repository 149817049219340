import * as variableShadow from "././base/variableShadow";
import theme from "./base/theme";
import * as variable from "../css/base/variable";
import { Platform } from 'react-native';
export const cssPeerProfile = {
    profileText: {
        ...variable.fontSecondary,
        fontSize: theme.FONT_SIZE_X_LG,
        fontWeight: theme.FONT_WEIGHT_MEDIUM,
        marginRight: 89,
        width: 140,
    },
    profileHead: {
        ...variable.fontSecondary,
        fontSize: theme.FONT_SIZE_XXXXX_LG,
        fontWeight: theme.FONT_WEIGHT_EX_BOLD,


    },
    customPaddingProfile: {
        marginTop: 24,
        marginBottom: 24,

    },
    profileUsernameInput: {
        borderColor: theme.COLOR_GREY_LIGHT_RG,
        borderWidth: 2,
        borderRadius: 4,
        height: 48,
        paddingRight: 30,
        paddingLeft: 12,
        marginBottom: 8,
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',


    },
    validBorderProfile: {
        borderColor: theme.COLOR_GREY_LIGHT_RG,
    },
    textAreaInput: {
        height: 128,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        paddingTop: 5,
        paddingRight:5,
        overflow:'hidden'
    },

    availableIconProfile: {
        height: 11,
        width: 11,
        marginRight: 5,
    },

    editpen: {
        height: 16,
        width: 16,
        marginLeft: 10,
        position: 'absolute',
        right: 5,
        top: -8,

    },

    iconBox: {
        paddingLeft: 15,
        paddingRight: 20,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'red'
    },

    availbleText: {
        fontWeight: theme.FONT_WEIGHT_LIGHT
    },
    inputfeildWidth: {
        flex: 1,
    },
    userNameContainer: {
        // marginLeft: 120,
    },
    redTextStar: {
        color: theme.COLOR_RED
    },
    marginZero: {
        marginRight: 0
    },
    maxText: {
        ...variable.fontSecondaryPrimaryFontColor,
        fontSize: theme.FONT_SIZE_LG,
        fontWeight: theme.FONT_WEIGHT_LIGHT,
    },
    inputUnderText: {
        fontSize: theme.FONT_SIZE_XX_SM,
        fontWeight: theme.FONT_WEIGHT_LIGHT,
        ...variable.fontPrimaryText,

    },
    inputUnderTextTwo: {
        ...variable.fontPrimaryText,
        color: theme.COLOR_GREY_MEDIUM,
        fontSize: theme.FONT_SIZE_SM,
        fontWeight: theme.FONT_WEIGHT_LIGHT,
    },
    commonCont: {
        width: 115,
    },
    cancelProfile: {
        minWidth: 85,
        minHeight: 32,
        borderWidth: 2,
        borderRadius: 8,
        backgroundColor: theme.COLOR_WHITE,
        borderColor: theme.COLOR_GREY_DARK_1,
        marginRight: 9,
    },
    cancelText: {
        ...variable.fontFamilyPrimary,
        color: theme.COLOR_GREY_DARK_1,
        fontSize: theme.FONT_SIZE_RG,
        fontWeight: theme.FONT_WEIGHT_EX_BOLD,
    },
    saveProfile: {
        minWidth: 85,
        height: 32,
        borderRadius: 8,
        position: 'relative',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: "center",
    },

    loaderBox: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: "relative"
    },

    saveTextProfile: {
        ...variable.fontFamilyPrimary,
        color: theme.COLOR_WHITE,
        fontSize: theme.FONT_SIZE_RG,
        fontWeight: theme.FONT_WEIGHT_EX_BOLD,
    },
    borderProfileWidthOne: {
        borderTopColor: theme.COLOR_GREY_LIGHT_RG,
        borderTopWidth: 1,
    },
    borderProfileWidthTwo: {
        borderTopColor: theme.COLOR_GREY_LIGHT_RG,
        borderTopWidth: 2,
    },
    publicBtnSec: {
        width: 600,

    },
    editMainBtn: {
        paddingHorizontal: 10,
        marginRight: 0,
    },
    visiblityText: {
        ...variable.fontFamilyPrimary,
        color: theme.COLOR_BLUE_DARK_2,
        fontSize: theme.FONT_SIZE_RG,
        fontWeight: theme.FONT_WEIGHT_MEDIUM,
        textDecorationLine: 'underline',
    },
    publicBtn: {
        padding: 10,
        borderColor: theme.COLOR_GREY_LIGHT_RG,
        borderRadius: 4,
        borderWidth: 2,
        marginBottom: 10,
        marginRight: 10,
        alignSelf: 'flex-start',
    },
    publicTextProfile:{
    marginBottom:15,
    },
    privateBtn: {
        opacity: 0.5,
    },
    publicText: {
        ...variable.fontPrimaryText,
        fontWeight: theme.FONT_WEIGHT_MEDIUM,
        marginLeft: 0,
    },
    marginLeftText: {
        ...variable.fontPrimaryText,
        fontWeight: theme.FONT_WEIGHT_MEDIUM,
        marginLeft: 6,
    },
    showIcon: {
        height: 19,
        width: 23,
        marginRight: 8
    },
    hideIcon: {
        width: 20,
        height: 5,
        marginRight: 8
    },
    serchText: {
        width: 170,
    },
    userNameContainerCustom: {
        marginLeft: 90,
    },
    marginTopText: {
        marginTop: 10,
    },
    bottomSection: {
        marginTop: 24,
        marginBottom: 100,
    },
    italicText: {
        marginTop: 3,
        fontStyle: "italic",
    },
    //Modal styles
    modalContainer: {
        backgroundColor: theme.COLOR_WHITE,
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: 8,
    },
    ModalHeadProfile: {
        ...variable.fontPrimaryText,
        fontWeight: theme.FONT_WEIGHT_EX_BOLD,
        marginTop: 14,
        marginBottom: 12,
    },
    conditionText: {
        ...variable.fontPrimaryText,
        fontWeight: '400',
        width: 274,
        textAlign: 'center',
        marginBottom: 35,
    },
    okayBtn: {
        width: 152,
        height: 38,
        marginBottom: 20,
    },

    loader: {
        padding: 0,
        margin: 0,
    },
    incognitoLoader:{
      top:20,
    },
    blueBorderProfile:{
        borderColor:theme.COLOR_BLUE_DARK_2,
        borderWidth:1,
    },

    btnBackgroundColor : { 
        backgroundColor: theme.BTN_BACKGROUND_COLOR
    }


};
