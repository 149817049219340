import React from "react";
import { View, Text, TouchableOpacity, Image, TextInput } from "react-native";
import styles from "../../Css";
import UIText from "../../components/Text/text";

const emailFieldMultiple = (props) => (
  <>
    <View style={[styles.fieldRow]}>
      <View style={[styles.fullEmailWrap, styles.emailSendWrapperFull]}>
        <TouchableOpacity
          onPress={() => {
            if (props.textInput) {
              props.textInput.focus();
            }
          }}
        >
          <View
            style={[
              styles.listEmailAll,
              styles.listEmailAllHeight,
              styles.flexRow,
            ]}
          >
            <View style={styles.listEmailTextSec}>
              <Text style={[styles.textView]} allowFontScaling={false}>
                <UIText
                  style={[styles.textLabel, styles.bold, styles.textColorLight]}
                  title={props.titleEmail}
                />
              </Text>
            </View>
            <View style={styles.listEmailScrollWrap}>
              {props.emailAddresses.map((res, index) => (
                <View
                  style={styles.listEmailSingle}
                  key={"emai_invite+" + index}
                >
                  <View style={styles.listEmailName}>
                    <Text style={styles.textPrimeSm} allowFontScaling={false}>{res.email}</Text>
                  </View>
                  <View style={[styles.smCloseIconView, styles.iconLtSp]}>
                    <TouchableOpacity
                      onPress={() => props.removeEmail(res.email)}
                    >
                      <Image
                        source={require("../../assets/icons/color-select.png")}
                        style={styles.colorSelectIcon}
                        resizeMode={"contain"}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              ))}

              <View style={styles.textFieldEmailView}>
                <TextInput
                  style={[styles.textFieldEmailSm, { zIndex: 999 }]}
                  allowFontScaling={false}
                  onChangeText={props.onChangeTextEmail}
                  value={props.email}
                  onKeyPress={props.onKeyPress}
                  onBlur={props.onBlur}
                  underlineColorAndroid="transparent"
                  ref={(input) => {
                    this.textInput = input;
                  }}
                  keyboardType="email-address"
                  textContentType="emailAddress"
                />
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </View>
    <View style={[styles.fieldRow]}>
      <TextInput
        style={[styles.textField, styles.textFieldSm, styles.textAreaMax]}
        allowFontScaling={false}
        editable={true}
        multiline={true}
        numberOfLines={3}
        onChangeText={props.onChangeNoteText}
        value={props.noteValue}
        underlineColorAndroid="transparent"
        placeholderTextColor={'#787885'}
        placeholder={props.palceholderNote}
      />
    </View>
  </>
);

export default (emailFieldMultiple);
