import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { View } from "react-native";
import styles from "../../../Css";

import { GOOGLE_CAPTCHA_SITE_KEY } from "../../../util/cognitoConfig";

const recaptchaRef = React.createRef();

class Recaptcha extends Component {
  componentDidMount() {
    const recaptchaValue = recaptchaRef.current.getValue();
  }
  onChange = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    this.props.setRecaptchaValue(recaptchaValue);
  };

  render() {
    return (
      <View style={styles.captchaField}>
        <ReCAPTCHA
          sitekey={GOOGLE_CAPTCHA_SITE_KEY}
          ref={recaptchaRef}
          size={"normal"}
          id={"captcha-login-page"}
          onChange={this.onChange}
        />
      </View>
    );
  }
}

export default Recaptcha;
