import theme from "../css/base/theme";
import * as variable from "../css/base/variable";
import * as variableShadow from "../css/base/variableShadow";
import * as variableHeight from "../css/base/variableHeight";
import * as variableCommon from "../css/base/variableCommon";

export const cssMainResponsive = {
  containerFull: {
    backgroundColor: theme.COLOR_WHITE,
    flex: 1,
    flexDirection: "column",
    "@media (min-width: 768px)": {
      backgroundColor: theme.COLOR_GREY_LIGHT_EX_5
    }
  },

  gridContent: {
    flex: 1,
    flexDirection: "column",
    width: "100%",
    "@media (min-width: 768px)": {
      backgroundColor: theme.COLOR_WHITE,
      paddingBottom: 30,
      flex: "initial",
      ...variableShadow.shadowStyleEight
    }
  },
  startUpSmWeb: {
    width: "100%",
    ...variable.marginLtRtAuto,
    position: "relative",
    flexDirection: "row",
    "@media (min-width: 992px)": {
      ...variableHeight.vhHeight
    },
    "@media (max-width: 991px)": {
      flexDirection: "column",
      justifyContent: "center"
    }
  },
  startUpRightBlockWeb: {
    width: "45%",
    alignItems: "flex-start",
    "@media (max-width: 991px)": {
      width: "100%",
      height: "auto",
      alignItems: "center",
      flexBasis: 140,
      maxHeight: 140,
      justifyContent: "flex-end"
    },
    "@media (min-width: 992px)": {
      backgroundColor: theme.COLOR_GREY_LIGHT_EX_3,
      ...variableHeight.vhHeight
    }
  },
  startUpMidBarBlockWeb: {
    display: "flex",
    width: "10%",
    "@media (max-width: 991px)": {
      display: "none"
    }
  },
  zIndexHigh: {
    zIndex: 99
  },
  startUpLeftBlockContWeb: {
    "@media (min-width: 992px)": {
      justifyContent: "center",
      minHeight: "100%"
    },
    "@media (max-width: 991px)": {
      paddingBottom: 0,
      marginBottom: 0,
      minHeight: "100%",
      justifyContent: "center"
    }
  },
  startUpLogoSecWeb: {
    left: 38,
    top: 38,
    position: "absolute",
    width: 160,
    height: 52,
    "@media (max-width: 991px)": {
      left: 0,
      right: 0,
      top: 30,
      marginLeft: "auto",
      marginRight: "auto",
      display: "none"
    }
  },
  startUpPgSliderContWeb: {
    alignItems: "flex-end",
    justifyContent: "center",
    "@media (max-width: 991px)": {
      justifyContent: "flex-start",
      alignItems: "center"
    }
  },
  startUpLeftBlockWeb: {
    width: "45%",
    backgroundColor: theme.COLOR_WHITE,
    "@media (min-width: 992px)": {
      ...variableHeight.vhHeight
    },
    "@media (max-width: 991px)": {
      width: "100%",
      height: "auto",
      justifyContent: "center"
    }
  },
  startUpPgSliderWeb: {
    height: "100%",
    width: "100%",
    "@media (min-width: 992px)": {
      maxWidth: 400
    }
  },
  startUpRightBlockContWeb: {
    ...variableHeight.vhHeight,
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 991px)": {
      height: "auto",
      paddingTop: 0,
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  startUpPgContWeb: {
    maxWidth: 640,
    paddingTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 20,
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 991px)": {
      // justifyContent: "center",
      // alignItems: "center",
      ...variable.marginLtRtAuto,
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  startUpBtnWeb: {
    marginTop: 40,
    alignItems: "flex-start",
    "@media (max-width: 991px)": {
      marginTop: 15
    }
  },
  startUpSmDesc: {
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingTop: 40,
    "@media (max-width: 991px)": {
      paddingTop: 20
    },
    "@media (max-width: 319px)": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }
  },
  startUpMessage: {
    display: "flex",
    "@media (max-width: 991px)": {
      display: "none"
    }
  },
  calMax: {
    marginLeft: "auto",
    marginRight: "auto",
    "@media (max-width: 375px)": {
      maxWidth: 345
    },
    "@media (max-width: 320px)": {
      maxWidth: 280
    }
  },
  contentAreaFull: {
    maxWidth: "100%",
    position: "relative",
    backgroundColor: theme.COLOR_WHITE
  },
  layoutWrapper: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    backgroundColor: theme.COLOR_WHITE
  },
  sideBarContainer: {
    flexBasis: 255,
    // position: "fixed",
    ...variableCommon.positionFixed,
    top: 0,
    width: 255,
    backgroundColor: theme.COLOR_WHITE
  },
  sideBarView: {
    justifyContent: "space-between",
    flex: 1
  },
  mainContainer: {
    flex: 1
  },
  customBorderCom: {
    width: 20,
    height: "100%",
    position: "absolute",
    top: 0,
    borderTopRightRadius: 10,
    justifyContent: "center",
    alignItems: "center"
  },
  messageDetailCom: {
    paddingLeft: 40,
    flex: 1,
    justifyContent: "center"
  },
  messageTopBotPdWeb: {
    paddingTop: 15,
    paddingBottom: 15
  },
  messageLinkSecCom: {
    flexBasis: 150,
    paddingRight: 20,
    alignItems: "flex-end",
    "@media (min-width: 992px)": {
      flexBasis: 250,
      paddingRight: 50
    }
  },
  messageLinkSecSm: {
    flexBasis: 80,
    paddingRight: 20,
    maxWidth: 80
  },
  breadCrumbBlock: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    marginRight: 20,
    "@media (max-width: 991px)": {
      display: "none"
    }
  },
  backSecWebView: {
    alignItems: "center",
    flexDirection: "row"
  },
  userImgView: {
    marginRight: 20
  },
  sidebarMargin: {
    marginLeft: 0,
    "@media (min-width: 992px)": {
      marginLeft: 255
    }
  },
  btnMaxZindex: {
    zIndex: 999999
  },
  tabContentFullArea: {
    width: "100%"
  },
  tabBlockSingle: {
    backgroundColor: theme.COLOR_WHITE,
    marginTop: 20,
    borderRadius: 5
  },
  tabContentMaxArea: {
    width: "100%",
    "@media (min-width: 992px)": {
      maxWidth: 850,
      ...variable.marginLtRtAuto,
      paddingBottom: 80,
    }
  },
  fixedScreen: {
    ...variableHeight.vhHeight
  },
  colFull: {
    width: "100%"
  },
  contentSectionMax: {
    width: "100%",
    "@media (min-width: 992px)": {
      maxWidth: 945,
      ...variable.marginLtRtAuto
    }
  },
  contentSectionFull: {
    width: "100%"
  },
  contListItemBlock: {
    flexDirection: "row"
  },
  contListItemDesc: {
    flex: 1
  },
  boxShadowBtn: {
    ...variableShadow.shadowStyleSeven
  },
  addItemSecBtnSec: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 5,
    backgroundColor: theme.COLOR_WHITE,
    "@media (min-width: 992px)": {
      maxWidth: 375
    }
  },

  addItemWidth: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    "@media (min-width: 992px)": {
      maxWidth: 375
    }
  },

  addItemRoutingEx: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 5,
    backgroundColor: theme.COLOR_WHITE,
  },

  addItemNewConn: {
    backgroundColor: theme.COLOR_BLUE_DARK_2,
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 50,
    height: 32
  },

  btnViewSec: {
    width: "100%",
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 20,
    maxWidth: 375,
    ...variable.marginLtRtAuto,
  },
  btnViewSecLg: {
    width: "100%",
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 20,
    maxWidth: 375,
    ...variable.marginLtRtAuto,
    "@media (min-width: 768px)": {
      maxWidth: 500,
      ...variable.marginLtRtAuto
    }
  },
  btnViewSecSm: {
    width: "100%",
    flexDirection: "row",
    paddingTop: 20,
    paddingBottom: 20,
    "@media (min-width: 768px)": {
      maxWidth: 375,
      ...variable.marginLtRtAuto
    }
  },
  btnViewSecFull: {
    width: "100%",
    flexDirection: "row",
    paddingTop: 20,
    paddingBottom: 20,
  },
  btnViewSecComNoPd: {
    ...variable.zeroPadding
  },
  btnTopBotPdZero: {
    ...variable.zeroPaddingLtRt
  },
  calTopMg: {
    marginTop: -10
  },
  layoutPdFixed: {
    paddingTop: 20,
    paddingBottom: 20
  },
  layoutTopPdFixed: {
    paddingTop: 20
  },
  layoutBotPdFixed: {
    paddingBottom: 20
  },
  contListItemFull: {
    flexDirection: "row",
    alignItems: "center"
  },
  medContArea: {
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-start"
  },
  sliderBlock: {
    width: "100%",
    ...variable.marginLtRtAuto,
    paddingTop: 10,
    paddingBottom: 10
  },
  severityValue: {
    flexBasis: 30
  },
  sliderView: {
    flex: 1
  },
  sliderRow: {
    flexDirection: "row"
  },
  severityValueFull: {
    position: "absolute",
    bottom: 10,
    ...variable.marginLtRtAuto
  },
  severityValueFullRight: {
    right: 0
  },
  calNavSec: {
    "@media (max-width: 413px)": {
      display: "none"
    }
  },
  calSecInd: {
    paddingHorizontal: 15,
    "@media (max-width: 479px)": {
      paddingHorizontal: 5
    }
  },
  uploadViewRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "@media (max-width: 575px)": {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start"
    }
  },
  uploadSingleRow: {
    alignItems: "center",
    width: "100%"
  },
  rowRev: {
    "@media (max-height: 479px)": {
      direction: "ltr"
    }
  },
  navListLiDet: {
    flexDirection: "row",
    alignItems: "center"
  },
  navPd: {
    paddingTop: 10,
    paddingBottom: 10
  },
  navPdPgTop: {
    paddingTop: 15
  },
  navListLiContentLg: {
    paddingLeft: 12,
    flex: 1,
    maxWidth: 200,
    "@media (max-width: 479px)": {
      maxWidth: 120
    }
  },
  navListDescLg: {
    paddingLeft: 12,
    maxWidth: "100%"
  },
  sideBarViewFull: {
    flexDirection: "row"
  },
  sidebarMarginFull: {
    marginLeft: 0,
    "@media (min-width: 992px)": {
      // marginLeft: 305,
      marginLeft: 255
    }
  },
  menuStatus: {
    "@media (max-width: 991px)": {
      display: "none"
    }
  },
  imgPreviewSecCom: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  colorBox: {
    borderColor: theme.COLOR_BORDER,
    borderWidth: 1,
    width: 20,
    height: 20,
    borderRadius: 5,
    marginLeft: 0,
    position: "relative",
    marginTop: 5,
    marginBottom: 5,
    marginRight: 7,
    "@media (min-width: 992px)": {
      width: 25,
      height: 25,
      marginLeft: 7
    },
    "@media (max-width: 374px)": {
      marginRight: 3,
      marginLeft: 0
    }
  },

  colorItem: {
    width: 18,
    height: 18,
    borderRadius: 5,
    "@media (min-width: 992px)": {
      width: 23,
      height: 23
    }
  },
  headerSectionWrapper: {
    left: 0,
    right: 0,
    zIndex: 9999,
    position: "absolute",
    width: "100%"
  },
  topImgLgCom: {
    ...variable.marginLtRtAuto,
    marginTop: 20
  },
  containerCommon: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: theme.COLOR_WHITE,
    "@media (min-width: 768px)": {
      ...variable.marginLtRtAuto,
      marginTop: 0,
      marginBottom: 0,
      maxWidth: 600,
      width: "100%"
    },
    "@media (min-width: 1025px)": {
      ...variableHeight.vhMinHeight
    }
  },
  containerCommonMax: {
    flex: 1,
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme.COLOR_WHITE,
    "@media (min-width: 768px)": {
      backgroundColor: theme.COLOR_GREY_LIGHT_EX_5
    },
    "@media (min-width: 1025px)": {
      alignItems: "center",
      justifyContent: "center",
      ...variableHeight.vhMinHeight
    }
  },
  bgWrapper: {
    backgroundColor: "theme.COLOR_WHITE"
  },
  containerSingleFull: {
    width: "100%",
    // ...variableHeight.vhMinHeight,
    alignItems: "center",
    justifyContent: "center"
  },
  pageBg: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_5
  },
  containerBgWhite: {
    backgroundColor: theme.COLOR_WHITE
  },
  containerSingleMax: {
    width: "100%",
    "@media (min-width: 768px)": {
      maxWidth: 600
    }
  },
  containerShadow: {
    "@media (min-width: 768px)": {
      ...variableShadow.shadowStyleEight
    }
  },
  maxTextArea: {
    maxWidth: 280,
    ...variable.marginLtRtAuto,
    "@media (min-width: 375px)": {
      maxWidth: 340
    }
  },
  maxTextAreaCom: {
    maxWidth: 280,
    ...variable.marginLtRtAuto,
    width: "100%",
    "@media (min-width: 375px)": {
      maxWidth: 300
    }
  },
  titleMaxComSm: {
    maxWidth: 270,
    ...variable.marginLtRtAuto,
    width: "100%"
  },
  descMaxComLg: {
    ...variable.marginLtRtAuto,
    width: "100%"
  },
  secPdSm: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20
  },
  maxComSec: {
    ...variable.marginLtRtAuto,
    width: "100%",
    "@media (min-width: 576px)": {
      maxWidth: 460
    }
  },
  maxComSecSm: {
    ...variable.marginLtRtAuto,
    width: "100%",
    "@media (min-width: 576px)": {
      maxWidth: 280
    }
  },
  maxComSecMini: {
    ...variable.marginLtRtAuto,
    width: "100%",
    maxWidth: 260
    // "@media (min-width: 576px)": {
    //   maxWidth: 260
    // }
  },
  maxComSecText: {
    ...variable.marginLtRtAuto,
    width: "100%",
    maxWidth: 320
  },
  sliderContMax: {
    ...variable.marginLtRtAuto,
    width: "100%",
    maxWidth: 320,
    "@media (max-width: 374px)": {
      maxWidth: 280
    }
  },
  profileCenterImgSec: {
    width: 115,
    minHeight: 115,
    borderRadius: 115,
    overflow: "hidden"
  },
  btnSubCom: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 374px)": {
      marginLeft: 2,
      marginRight: 2
    }
  },
  btnTextView: {
    flexDirection: "row",
    "@media (max-width: 374px)": {
      paddingTop: 15
    }
  },
  profileTextView: {
    width: "35%",
    "@media (min-width: 992px)": {
      width: "40%"
    }
  },
  profileLinkView: {
    width: "35%",
    "@media (min-width: 992px)": {
      width: "40%"
    }
  },
  profileCenter: {
    width: "30%",
    justifyContent: "center",
    alignItems: "center",
    ...variable.marginLtRtAuto,
    "@media (min-width: 992px)": {
      width: "20%"
    }
  },
  profileTextCom: {
    paddingLeft: 15,
    paddingRight: 15,
    alignItems: "flex-start",
    flexDirection: "row"
  },
  profileTextEdit: {
    paddingRight: 15,
    paddingLeft: 15,
    alignItems: "flex-end"
  },
  profileEditLink: {
    flexDirection: "row",
    alignItems: "center"
  },
  btnSingleSecFull: {
    alignItems: "stretch",
    width: "100%",
    maxWidth: 240
  },
  btnSingleSecMin: {
    alignItems: "stretch",
    width: "100%",
    maxWidth: 200
  },
  btnSingleSecCom: {
    alignItems: "stretch",
    width: "100%",
  },
  premBoxFullView: {
    width: "100%",
    maxWidth: 340,
    ...variable.marginLtRtAuto,
    flexDirection: "row"
  },
  premBoxView: {
    width: "50%"
  },
  premBoxSingle: {
    paddingLeft: 10,
    paddingRight: 10,
    width: "100%",
    flex: 1
  },
  premBoxOrView: {
    ...variable.marginLtRtAuto,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    marginTop: "auto",
    marginBottom: "auto",
    width: 64,
    height: 64,
    zIndex: 999
  },
  toggleBtnFull: {
    alignItems: "center",
    justifyContent: "center",
    minWidth: 100,
    "@media (max-width: 374px)": {
      minWidth: 70
    }
  },
  detailGraph: {
    width: "100%"
  },
  comContentSection: {
    paddingRight: 30,
    paddingLeft: 30,
    height: "auto",
    "@media (min-width: 576px)": {
      height: "auto"
    }
  },
  closeIconView: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    zIndex: 999
  },
  toggleBtnView: {
    alignItems: "center",
    justifyContent: "flex-start",
    maxWidth: 120,
    flexDirection: "row",
    "@media (max-width: 374px)": {}
  },
  secConPdLtRt: {
    paddingLeft: 20,
    paddingRight: 20,
    "@media (min-width: 992px)": {}
  },
  maxContArea: {
    width: "100%",
    "@media (min-width: 992px)": {
      maxWidth: 850,
      ...variable.marginLtRtAuto
    }
  },
  contAreaAll: {
    width: "100%"
  },
  topImgNoMg: {
    marginTop: 0
  },
  containerFullView: {
    flex: 1,
    flexDirection: "column",
    ...variableHeight.vhMinHeight,
    "@media (min-width: 992px)": {
      backgroundColor: theme.COLOR_GREY_LIGHT_EX_5
    }
  },
  containerCommonView: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: theme.COLOR_WHITE,
    ...variableHeight.vhMinHeight,
    "@media (min-width: 992px)": {
      ...variable.marginLtRtAuto,
      marginTop: 0,
      marginBottom: 0,
      width: "100%"
    }
  },
  containerCommonMaxView: {
    flex: 1,
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme.COLOR_WHITE,
    ...variableHeight.vhMinHeight,
    "@media (min-width: 992px)": {
      backgroundColor: theme.COLOR_GREY_LIGHT_EX_5
    },
    "@media (min-width: 1025px)": {
      alignItems: "center",
      justifyContent: "center"
    }
  },
  gridContentView: {
    flex: 1,
    flexDirection: "column",
    width: "100%",
    ...variableHeight.vhMinHeight,
    "@media (min-width: 992px)": {
      backgroundColor: theme.COLOR_BLUE_4,
      ...variableShadow.shadowStyleEight
    }
  },
  contBgBlue: {
    backgroundColor: theme.COLOR_BLUE_4
  },
  loadBgBotViewOne: {
    position: "absolute",
    bottom: 0,
    left: 0
  },
  loadBgTopViewOne: {
    position: "absolute",
    top: 0,
    right: 0
  },
  loadBgRightViewOne: {
    position: "absolute",
    bottom: "7%",
    right: 0
  },
  wrapperCenterView: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  },
  loaderPd: {
    paddingTop: 5
  },
  tutorialToolTip: {
    position: "absolute",
    left: 0,
    right: 0,
    width: "100%",
    bottom: 0,
    "@media (max-width: 991px)": {
      // position: "fixed"
      ...variableCommon.positionFixed
    }
  },
  commonTopImgView: {
    width: 285,
    height: 185,
    "@media (max-width: 374px)": {
      width: 245,
      height: 160
    }
  },
  commonBotImgView: {
    width: 281,
    height: 244,
    "@media (max-width: 374px)": {
      width: 200,
      height: 174
    }
  },
  tutorialToolBgCom: {
    backgroundColor: theme.COLOR_ORANGE_3
  },
  tutorialContentFull: {
    position: "absolute",
    left: 0,
    right: 0,
    width: "100%",
    bottom: 0,
    "@media (min-width: 992px)": {}
  },
  tutorialContent: {
    paddingLeft: 20,
    paddingRight: 20,
    ...variable.marginLtRtAuto,
    maxWidth: 700,
    width: "100%",
    "@media (min-width: 992px)": {
      paddingLeft: 40,
      paddingRight: 40
    }
  },
  tutorialClose: {
    alignItems: "flex-end"
  },
  toolTipData: {
    marginBottom: 20
  },
  toolTipClosePd: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  textMinHeight: {
    minHeight: 52
  },
  uploadBtnCamImg: {
    width: 47,
    height: 39,
    alignSelf: "flex-end"
  },
  uploadBtnJournalImg: {
    width: 48,
    height: 39,
    alignSelf: "flex-end"
  },
  uploadPlusImg: {
    width: 35,
    height: 35,
    position: "absolute",
    bottom: 0
  },
  uploadCamSec: {
    right: 38
  },
  uploadJournalSec: {
    right: 39
  },
  uploadBtnSingle: {
    paddingTop: 10,
    paddingLeft: 5,
    paddingBottom: 10,
    paddingRight: 5,
    backgroundColor: theme.COLOR_WHITE,
    width: 90,
    borderRadius: 5,
    // flex: 1,
    ...variableShadow.shadowStyleTwelve,
    "@media (min-width: 992px)": {
      width: 95
    }
  },
  uploadBtnLink: {
    flex: 1
  },
  uploadBtnImgFull: {
    minHeight: 65
  },
  contBotPdLg: {
    paddingBottom: 140,
    "@media (min-width: 992px)": {
      paddingBottom: 80
    }
  },
  contBotPd: {
    paddingBottom: 100,
    "@media (min-width: 992px)": {
      paddingBottom: 80
    }
  },
  contBotSmPdFixed: {
    paddingBottom: 80
  },
  contBotPdFixed: {
    paddingBottom: 40
  },
  botPd: {
    paddingBottom: 140,
    "@media (min-width: 992px)": {
      paddingBottom: 80
    }
  },
  botExPdSm: {
    paddingBottom: 80,
    "@media (min-width: 992px)": {
      paddingBottom: 15
    }
  },
  botExPd: {
    paddingBottom: 140,
    "@media (min-width: 992px)": {
      paddingBottom: 80
    }
  },
  btnNextResBlock: {
    ...variable.marginAllAuto,
    zIndex: 9999,
    alignItems: "center",
    justifyContent: "flex-end"
  },
  commonBotImgArea: {
    position: "absolute",
    left: 0,
    bottom: 0
  },
  btnNextCom: {
    width: 95,
    height: 95
  },
  botTextBlockLg: {
    maxWidth: 320,
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 180,
    "@media (max-width: 991px)": {
      paddingBottom: 140
    },
    "@media (max-width: 767px)": {
      paddingBottom: 180
    },
    "@media (min-width: 992px)": {
      paddingBottom: 15
    }
  },
  infoBlockMax: {
    ...variable.marginLtRtAuto,
    width: "100%",
    maxWidth: 250
  },
  radioBtnList: {
    flex: 1,
    flexDirection: "column"
  },
  radioBtnListSingle: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap"
  },
  radioBtnContent: {
    maxWidth: 100,
    flexBasis: 100,
    "@media (min-width: 481px)": {
      maxWidth: 200,
      flexBasis: 200
    }
  },
  viewRowTitle: {
    paddingLeft: 80,
    paddingBottom: 5
  },
  itemMediIcon: {
    width: 40,
    height: 40
  },
  itemDocIcon: {
    width: 40,
    height: 23,
    "@media (min-width: 992px)": {
      width: 70,
      height: 40
    }
  },
  itemBorderReport: {
    left: 20,
    bottom: 0,
    position: "absolute",
    right: 20,
    borderBottomColor: theme.COLOR_BORDER,
    borderBottomWidth: 1,
    "@media (min-width: 992px)": {
      left: 70
    }
  },
  itemWhiteBorderReport: {
    borderBottomColor: theme.COLOR_WHITE
  },
  itemBorderReportFull: {
    left: 20,
    bottom: 0,
    position: "absolute",
    right: 20,
    borderBottomColor: theme.COLOR_BORDER,
    borderBottomWidth: 1
  },
  itemImgAreaMain: {
    flexBasis: 50,
    maxWidth: 50
  },
  itemImgAreaMainLg: {
    flexBasis: 50,
    maxWidth: 50,
    "@media (min-width: 992px)": {
      flexBasis: 80,
      maxWidth: 80
    }
  },
  itemTextDate: {
    flexBasis: 100,
    maxWidth: 100,
    paddingLeft: 10,
    paddingRight: 10,
    alignSelf: "center",
    "@media (min-width: 575px)": {
      flexBasis: 180,
      maxWidth: 180
    }
  },
  noRtPd: {
    paddingRight: 0
  },
  itemTextAreaMg: {
    "@media (min-width: 992px)": {
      marginLeft: 40
    }
  },
  itemTextPdRt: {
    paddingRight: 10
  },
  itemTextAreaMain: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  btnViewMain: {
    flexBasis: 120,
    maxWidth: 120
  },
  itemTextLg: {
    flex: 1
  },
  messageImgIcon: {
    width: 60,
    height: 35
  },
  messageImg: {
    maxWidth: 80,
    flexBasis: 80
  },
  messageDate: {
    maxWidth: 150,
    flexBasis: 150,
    paddingLeft: 20
  },
  radioSetArea: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%"
  },
  radioSetText: {
    paddingRight: 10
  },
  descView: {
    marginLeft: 7,
    marginRight: 7,
    "@media (max-width: 374px)": {
      marginLeft: 0,
      marginRight: 0
    }
  },
  uploadedImgView: {
    width: "100%",
    alignSelf: "stretch",
    height: 200
  },
  uploadedImgLg: {
    height: 200
  },
  uploadedImgSm: {
    height: 150
  },
  pageFullCover: {
    flex: 1,
    justifyContent: "space-between"
  },
  sliderQuestionMax: {
    maxWidth: "80%",
    width: "100%",
    ...variable.marginLtRtAuto,
    marginBottom: 10,
    paddingBottom: 10,
    "@media (max-width: 375px)": {
      maxWidth: 320
    },
    "@media (max-width: 320px)": {
      maxWidth: 280
    }
  },
  quoteArrow: {
    flexBasis: 20,
    maxWidth: 20
  },
  quoteArrowTop: {
    alignSelf: "flex-start",
    alignItems: "flex-end"
  },
  quoteArrowBot: {
    alignSelf: "flex-end",
    alignItems: "flex-start"
  },
  quoteDesc: {
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10
  },
  addItemsOpenSm: {
    ...variableCommon.positionFixed,
    bottom: 0,
    width: "100%",
    height: "100%",
    left: 0,
    right: 0,
    zIndex: 9,
    "@media (min-width: 992px)": {
      display: "none"
    }
  },
  addLinksArea: {
    backgroundColor: theme.COLOR_WHITE,
    paddingBottom: 100,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    marginTop: "auto",
    zIndex: 9999
  },
  addLinksOverlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    zIndex: 99,
    backgroundColor: "rgba(64, 64, 65, 0.3)"
  },
  moreMenuOpenSm: {
    width: "100%",
    height: "100%",
    left: 0,
    right: 0,
    zIndex: 9,
    "@media (min-width: 992px)": {
      display: "none"
    }
  },
  moreMenuAreaMobileView: {
    backgroundColor: theme.COLOR_WHITE,
    marginBottom: "auto"
  },
  itemAttachIcon: {
    width: 15,
    height: 15
  },
  itemAttachSec: {
    flexBasis: 30,
    maxWidth: 30,
    alignItems: "center",
    justifyContent: "center"
  },
  btnExSp: {
    paddingBottom: 80,
    "@media (min-width: 992px)": {
      paddingBottom: 0
    }
  },
  btnListPdBot: {
    paddingBottom: 80,
    "@media (min-width: 992px)": {
      paddingBottom: 0
    }
  },
  btnListPdTop: {
    paddingTop: 0
  },
  userSecView: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    "@media (min-width: 992px)": {
      display: "none"
    }
  },
  addMenuLgScreen: {
    borderTopLeftRadius: 30,
    paddingTop: 30,
    paddingLeft: 30,
    paddingBottom: 40,
    paddingRight: 100,
    marginTop: 20,
    ...variableCommon.positionFixed,
    maxWidth: 360,
    marginLeft: "auto",
    zIndex: 9999,
    backgroundColor: theme.COLOR_WHITE,
    right: 0,
    bottom: 0,
    ...variableShadow.shadowStyleNineteen,
    width: "100%"
  },
  caregiverAlertCont: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: theme.COLOR_WARNING
  },
  dropDownOptionIcon: {
    width: 20,
    height: 20
  },
  dropDownOptionBtn: {
    flexBasis: 30,
    maxWidth: 30,
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  dropDownOptionText: {
    flex: 1
  },
  notifyIconRect: {
    width: 45,
    height: 45,
    borderRadius: 5,
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5
  },
  startUpLogoSecSm: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexBasis: 90,
    maxHeight: 90,
    "@media (min-width: 992px)": {
      display: "none"
    }
  },
  startUpLogoView: {
    paddingTop: 25,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    "@media (min-width: 992px)": {
      display: "none"
    }
  },
  sliderInfoPd: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    "@media (max-width: 991px)": {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20
    }
  },
  userInfoBlock: {
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_1
  },
  userInfoInnerBlock: {
    paddingLeft: 20,
    paddingRight: 20
  },
  scanFullArea: {
    justifyContent: "space-between"
  },
  frameBtnFull: {
    width: 66,
    height: 66,
    borderRadius: 66,
    backgroundColor: theme.COLOR_WHITE,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    ...variableShadow.shadowStyleTwenty
  },
  frameBtn: {
    width: 56,
    height: 56,
    borderRadius: 56,
    backgroundColor: theme.COLOR_RED_DARK,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    ...variableCommon.cursorPointer
  },
  frameTextBtn: {
    width: "100%",
    maxWidth: 360,
    ...variable.marginLtRtAuto,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 8,
    paddingTop: 8,
    borderRadius: 18,
    backgroundColor: theme.COLOR_ORANGE_DARK
  },

  frameViewBtn: {
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    marginTop: 10
  },
  frameLine: {
    position: "absolute",
    borderWidth: 3,
    borderColor: theme.COLOR_ORANGE_DARK,
    borderRadius: 16,
    ...variable.marginLtRtAuto,
    left: 0,
    right: 0
  },
  zeroBotPd: {
    paddingBottom: 0
  },
  paraTitleBlock: {
    paddingBottom: 10
  },
  paraBlock: {
    paddingBottom: 20
  },
  paraBotPd: {
    paddingBottom: 10
  },
  frameBtnMinHeight: {
    minHeight: 78
  },
  frameTextMinHeight: {
    minHeight: 32
  },
  frameAreaContView: {
    position: "absolute",
    bottom: 0,
    width: "100%"
  },
  sectionWrapper: {
    backgroundColor: theme.COLOR_WHITE
  },
  dropArrowIcon: {
    width: 6,
    height: 12
  },
  dropArrowView: {
    position: "absolute",
    display: "flex",
    right: 10,
    zIndex: 99999
  },
  dropArrowCent: {
    top: 0,
    bottom: 0,
    justifyContent: "center"
  },
  dropArrowTopLg: {
    top: 0,
    bottom: 0,
    justifyContent: "center"
  },
  colPdTopLtRtCom: {
    paddingTop: 20,
    paddingRight: 20,
    paddingLeft: 20,
    "@media (max-width: 991px)": {
      paddingTop: 0,
      paddingRight: 0,
      paddingLeft: 0
    }
  },
  colPdComScan: {
    paddingTop: 20,
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    "@media (max-width: 991px)": {
      paddingTop: 0,
      paddingRight: 0,
      paddingLeft: 0,
      paddingBottom: 0
    }
  },
  colPdComScanNone: {
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 0
  },
  modalCloseScan: {
    width: 45,
    height: 45
  },
  modelCloseInner: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 99,
    width: 45,
    "@media (min-width: 992px)": {
      top: -25,
      right: -25
    }
  },
  modalScrollWrapperCom: {
    ...variable.marginAllAuto,
    maxHeight: "100%",
    width: 280,
    "@media (min-width: 992px)": {
      width: 375
    }
  },
  resourceModalScrollWrapperCom: {
    ...variable.marginAllAuto,
    width: 280,
    "@media (min-width: 992px)": {
      width: 375
    }
  },
  modalScrollBlock: {
    ...variable.marginAllAuto,
    maxHeight: "100%",
    width: 300,
    "@media (min-width: 992px)": {
      width: 320
    }
  },
  modalScrollBlockSm: {
    ...variable.marginAllAuto,
    maxHeight: "100%",
    width: 280,
    "@media (min-width: 992px)": {
      width: 320
    }
  },

  uploadImageScrollModal: {
    ...variable.marginAllAuto,
    maxHeight: "100%",
    width: 300,
    "@media (min-width: 992px)": {
      width: 320
    }
  },

  modalScrollBlockLg: {
    ...variable.marginAllAuto,
    maxHeight: "100%",
    width: 320,
    overflow: 'hidden',
    "@media (min-width: 992px)": {
      width: 440
    }
  },

  btnViewSecSmRight: {
    width: "100%",
    flexDirection: "row",
    paddingTop: 20,
    marginLeft: "auto",
    maxWidth: 280
  },
  modalScrollCal: {
    ...variable.marginLtRtAuto,
    maxHeight: "100%",
    width: 320,
    "@media (min-width: 992px)": {
      width: 375
    }
  },
  calViewModalCom: {
    borderTopWidth: 1,
    paddingTop: 0,
    borderBottomWidth: 0,
    borderColor: "transparent",
    width: 320,
    "@media (min-width: 992px)": {
      width: 375
    }
  },
  modalCloseImgPreview: {
    ...variable.marginLtRtAuto,
    zIndex: 99,
    alignItems: "flex-end",
    "@media (min-width: 992px)": {}
  },
  noPdBot: {
    paddingBottom: 0
  },
  itemResourceIcon: {
    width: 39,
    height: 22
  },
  itemResourceAreaMainLg: {
    flexBasis: 60,
    maxWidth: 60
  },
  fieldBtnSpMini: {
    width: 10,
    "@media (min-width: 576px)": {
      width: 20
    }
  },
  menuAreaWrapper: {
    flex: 1
  },
  backDropBg: {
    backgroundColor: "rgba(0, 0, 0, 0.05)"
  },
  sidebarArea: {
    borderRightWidth: 2,
    borderColor: "rgba(0, 0, 0, 0.15)"
  },
  listBg: {
    backgroundColor: theme.COLOR_GREY_LIGHT_MD,
    "@media (min-width: 992px)": {
      backgroundColor: theme.COLOR_WHITE
    }
  },
  addBtnTextCom: {
    position: "absolute",
    bottom: 3,
    width: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  tickButtonPd: {
    paddingBottom: 10,
    "@media (min-width: 992px)": {
      paddingBottom: 20
    }
  },
  premBoxOrViewWrapper: {
    width: 64,
    marginLeft: -32,
    marginRight: -32,
    height: 64,
    marginTop: 55,
    zIndex: 9
  },
  maxComSecContent: {
    ...variable.marginLtRtAuto,
    width: "100%",
    paddingLeft: 40,
    paddingRight: 40,
    "@media (min-width: 576px)": {
      maxWidth: 460
    }
  },
  autoCompleteDataCom: {
    position: "absolute",
    left: 0,
    width: "100%",
    paddingBottom: 0,
    paddingTop: 3,
    zIndex: 999999,
    minWidth: 240,
    borderRadius: 5,
    "@media (min-width: 992px)": {
      minWidth: 280
    }
  },
  autoCompleteSuggestionDataCom: {
    position: "absolute",
    width: "100%",
    paddingBottom: 0,
    paddingTop: 3,
    zIndex: 999999,
    minWidth: 240,
    borderRadius: 5,
    "@media (min-width: 992px)": {
      minWidth: 280
    }
  },
  rightAlignStyle: {
    right: 0
  },
  leftAlignStyle: {
    left: 0,
  },

  autoCompleteBgShadowCr: {
    ...variableShadow.autoSuggestShadow,
  },
  autoCompleteDataMgLg: {
    top: 61
  },
  autoCompleteDataMgLgCr: {
    top: 51
  },
  autoCompleteDataMgSm: {
    top: 51
  },
  autoCompleteDataMgSmCr: {
    top: 41
  },
  autoCompleteBorder: {
    borderColor: theme.COLOR_GREY_LIGHT_EX_7,
    borderWidth: 1
  },

  maxSectionView: {
    maxWidth: 320,
    ...variable.marginLtRtAuto,
    "@media (min-width: 991px)": {
      maxWidth: 400
    }
  },
  startUpLogoApp: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexBasis: 90,
    maxHeight: 90
  },
  startUpLogoViewApp: {
    paddingTop: 25,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10
  },
  startUpPgContApp: {
    maxWidth: 640,
    paddingTop: 0,
    justifyContent: "center",
    alignItems: "center",
    ...variable.marginLtRtAuto,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20
  },
  startUpBtnApp: {
    marginTop: 15,
    alignItems: "flex-start"
  },
  startUpPgSliderApp: {
    justifyContent: "flex-start",
    alignItems: "center"
  },
  startUpDescApp: {
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingTop: 20
  },
  contListCenter: {
    justifyContent: "center"
  },
  botHeightSm: {
    minHeight: 40,
    "@media (min-width: 992px)": {
      minHeight: 0
    }
  },
  messageDataSm: {
    flex: 1
  },
  itemTextMgCom: {
    paddingTop: 10,
    paddingBottom: 10
  },
  answerPickerRes: {
    maxWidth: 150,
    flexBasis: 150,
    "@media (min-width: 992px)": {
      maxWidth: 150,
      flexBasis: 150
    }
  },
  graphContentZoom: {
    paddingTop: 10,
    paddingBootom: 100
  },
  modalScrollContentCom: {
    ...variable.marginAllAuto,
    maxHeight: "100%",
    width: '90%',
    "@media (min-width: 1025px)": {
      width: 920
    }
  },
  btnCenterView: {
    justifyContent: 'center',
  },
  modalScrollSpecCom: {
    ...variable.marginLtRtAuto,
    maxHeight: "100%",
    width: 280,
    marginBottom: 30,
    marginTop: 'auto'
  },
  sliderQuestPdBot: {
    paddingBottom: 0,
  },
  contentViewWrapperFull: {
    width: "100%",
  },
  textInfoWrapper: {
    backgroundColor: theme.LIGHT_BG,
    paddingLeft: 20,
    paddingRight: 20,
  },
  tabBorder: {
    borderBottomColor: 'rgb(244, 236, 215)',
    borderBottomWidth: 2,
  },
  autoCompleteBgWhite: {
    backgroundColor: theme.COLOR_WHITE,
  },
  autoCompleteTopLg: {
    top: 51
  },
  autoCompleteTopSm: {
    top: 41
  },
  responsiveMenuWrapper: {
    ...variableCommon.positionFixed,
    left: 0,
    right: 0,
    width: "100%",
    minHeight: 70,
    bottom: 0,
    zIndex: 999,
    backgroundColor: theme.COLOR_WHITE,
    ...variableShadow.menuShadow,
    "@media (min-width: 992px)": {
      display: "none"
    }
  },
  navLinkSingleItem: {
    width: '50%',
    maxWidth: 75,
  },
  mobAppMenuViewRow: {
    position: "relative",
    width: "100%",
    minHeight: 70,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  caregiverAlertBg: {
    backgroundColor: theme.COLOR_ORANGE_DARK,
  },
  switchProfileWrapper: {
    width: 235,
  },
  addMainMenu: {
    position: "absolute",
    width: 80,
    height: 95,
    bottom: 0,
    right: 15,
    "@media (max-width: 991px)": {
      display: "none"
    }
  },
  addMainMenuIn: {
    width: 80,
    height: 95,
    ...variableCommon.positionFixed,
    zIndex: 100,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center'
  },

  addButtonSm: {
    width: 70,
    height: 70,
    zIndex: 999999
  },

  userInfoBlockCom: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_1
  },
  modalScrollStatic: {
    ...variable.marginAllAuto,
    maxHeight: "100%",
    width: '100%',
    maxWidth: 310,
    "@media (min-width: 992px)": {
      maxWidth: 520,
    }
  },
  btnViewPdTop: {
    paddingTop: 15,
  },
  contentBoxFullView: {
    width: '100%',
  },
  camMg: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  boxMaxWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  boxMaxWrapperLg: {
    maxWidth: '100%'
  },
  boxMaxWrapperSm: {
    maxWidth: 360,
  },
  modalScrollFilterView: {
    width: 310,
    "@media (min-width: 991px)": {
      width: 375,
    }
  },
  headerIconArrowPd: {
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 5,
  },
  leftMgSmCom: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 20,
    marginLeft: 5,
    "@media (max-width: 374px)": {
      marginLeft: 0,
    }
  },
  headerIconPdSmCom: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 15,
    paddingBottom: 15,
  },
  btnViewSecMax: {
    width: "100%",
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 20,
    maxWidth: 500,
    ...variable.marginLtRtAuto,
    "@media (min-width: 992px)": {
      maxWidth: 375,
    }
  },
  pageLayoutWrapper: {
    width: "100%",
    backgroundColor: theme.COLOR_WHTE,
    flex: 1,
  },
  addMenuLgMg: {
    marginRight: 0,
  },
  fieldColAddBtn: {
    flexBasis: 100,
    maxWidth: 100,
    "@media (min-width: 992px)": {
      flexBasis: 120,
      maxWidth: 120,
    }
  },
  attachmentItemDetailView: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 15,
    paddingBottom: 15,
    flex: 1,
    "@media (min-width: 992px)": {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  postComRelative: {
    position: 'relative'
  },
  premiumDetailSec: {
    ...variable.marginLtRtAuto,
    width: "100%",
    maxWidth: 700,
  },
  itemMgLtCom: {
    marginLeft: 20,
  },
  contBotLgCom: {
    paddingBottom: 100
  },
  btnViewComment: {
    maxWidth: 240
  },
  fieldColAddBtnSpec: {
    flexBasis: 130,
    maxWidth: 130,
    "@media (min-width: 992px)": {
      flexBasis: 120,
      maxWidth: 120,
    }
  },
  flexBtnStart: {
    justifyContent: "flex-start"
  },
  flexBtnAlignCenter: {
    alignItems: "center"
  },
  uploadprogressWebDetails: {
    alignItems: 'flex-end',
    right: 0,
    bottom: 118,
    "@media (max-width: 992px)": {
      bottom: 100,
    }
  },

  heathUnitMeasure: {
    flexBasis: 110,
    maxWidth: 110,

    "@media (min-width: 768px)": {
      flexBasis: 180,
      maxWidth: 180,
    }
  },

  heathUnitMeasureSm: {
    minWidth: 100,

    "@media (min-width: 768px)": {
      minWidth: 110,
    }
  },

  medicationsDrpDownStyle: {
    minWidth: 240,

    "@media (min-width: 768px)": {
      minWidth: 110,
    }
  },

  updatedProfilePopUpStyle: {
    backgroundColor: theme.COLOR_WHITE,
    borderRadius: 8,
    justifyContent: 'space-between',
  },

  updatedPopUpUpload: {
    width: 296,
    height: 244,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 15,
    paddingBottom: 25,

    "@media (min-width: 768px)": {
      paddingLeft: 40,
      paddingRight: 40,
    }

  },

  updatedPopUpCrop: {
    width: 320,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 25,
    "@media (min-width: 768px)": {
      //width: 440,
      //height: 484,
      paddingLeft: 32,
      paddingRight: 32,
      paddingTop: 15,
      paddingBottom: 32,
    }
  },

  updateScale: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',

    "@media (min-width: 768px)": {
      width: '70%',
    }
  },


  updatedProfileClsIcnPost: {
    position: 'absolute',
    top: 70,
    zIndex: 9,
    right: 15,

    "@media (min-width: 992px)": {
      right: 40,
    }
  },

  graphDrownParentCr: {
    width: 184,
    height: 32,
    paddingRight: 8,
    borderWidth: 1,
    borderColor: theme.COLOR_GREY_LIGHT_EX_20,
    borderRadius: 4,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    paddingLeft: 15,

    "@media (min-width: 768px)": {
      maxWidth: '100%',
      width: '100%',
      marginLeft: 0,
      height: 40,
      paddingRight: 20
    },
  },

  graphDropDownPolygonIcon: {
    paddingLeft: 7,
    paddingTop: 6,
    paddingBottom: 7,

    "@media (min-width: 768px)": {
      paddingLeft: 10,
      paddingTop: 11,
      paddingBottom: 11
    }
  },

  graphSelectionAreaCr: {
    width: 184,

    "@media (min-width: 768px)": {
      width: 340,
    }
  },

  resourceBoxMgCr: {
    marginLeft: 20,
    marginRight: 20,
  },

  resourceListItemTag: {
    maxWidth: 120,
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingRight: 5,
    "@media (min-width: 325px)": {
      maxWidth: 200,
    },

    "@media (min-width: 768px)": {
      maxWidth: 500,
    },
    "@media (min-width: 992px)": {
      maxWidth: 550
    }
  },

  journalWarningSpacing: {
    paddingLeft: 0,
    paddingRight: 0,
    "@media (min-width: 992px)": {
      paddingLeft: 20,
      paddingRight: 20,
    }
  },

  newConnectionBtnPost: {
    // aligmItems:'center',

    // "@media (min-width: 768px)": {
    //   alignItems:'flex-end', 
    // }
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  newConnectionBtnCr: {
    alignItems: 'center',
    height: 40,
    marginBottom: 30,
    marginTop: 10,
    "@media (min-width: 768px)": {
      alignItems: 'flex-end',
    }
  },

  newConnectionSearchBar: {
    flex: 1,
    marginLeft: 0,
    marginRight: 0,

    // "@media (min-width: 768px)": {
    //   marginLeft: 0,
    //   marginRight: 0,
    // }
  },

  conditionWidth: {
    width: '70%',
    overflow: 'hidden',
    flexDirection: 'row',
    maxHeight: 30,
    "@media (min-width: 768px)": {
      width: '90%',
    },
  },

  manageDeviceTxtAlign: {
    textAlign: 'center',
    alignItems: 'center',
    "@media (min-width: 768px)": {
      textAlign: 'left',
      alignItems: 'flex-start'
    },
  },

  manageDeviceTxtAlignPd: {
    flexDirection: 'column',
    paddingLeft: 0,
    "@media (min-width: 768px)": {
      paddingLeft: 30,
    },
  },

  manageDeviceMainHeadingAlignPd: {
    textAlign: 'center',
    "@media (min-width: 768px)": {
      textAlign: 'left',
    },
  },

  routineReminderContent: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingTop: 24,
    paddingLeft: 24,

    "@media (min-width: 376px)": {
      paddingLeft: 0,
      paddingTop: 0
    },
  },

  surveyCombinedStyle: {
    "@media (min-width: 992px)": {
      backgroundColor: theme.COLOR_WHITE,
      paddingBottom: 80
    }
  },

  resourceTextAlignNull: {
    textAlign: 'center',
    maxWidth: 230,
    marginRight: 'auto',
    marginLeft: 'auto',

    "@media (min-width: 768px)": {
      maxWidth: '100%',
    }
  },

  healthJourneyContentTruncWd: {
    width: '80%',
    "@media (min-width: 768px)": {
      width: '90%',
    },
    "@media (min-width: 991px)": {
      width: '95%',
    }
  },
};
