import React, {Component, createRef} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import FieldTitle from './fieldTitle';
import Popover from '../../components/Popover';

let anchorEl;
class TextInputDayFrameSpec extends Component {
  constructor(props) {
    super(props);
    const {fieldValue} = this.props;
    this.state = {
      fieldValue: fieldValue,
      open: false,
    };
    this.touchable = createRef();

  }
  setReminderMonthlyDay = val => {
    const {setReminderMonthlyDay} = this.props;
    this.setState({fieldValue: val, open: false}, () =>
      setReminderMonthlyDay(val),
    );

    anchorEl = null;
  };
  close = () => {
    this.setState({open: false});
    anchorEl = null;
  };
  render() {
    const {fieldValue, open} = this.state;
    const {
      fieldSm,
      fieldBg,
      title,
      fieldAlign,
      windowWidth,
      reminderSection,
    } = this.props;
    return (
      <View
        style={[
          fieldSm ? styles.textInputSmWrapper : styles.textInputWrapper,
          styles.postRelative,
        ]}>
        <TouchableOpacity
          onPress={e => {
            this.setState({open: true});
            anchorEl = e.currentTarget;
          }}
          ref={this.touchable}>
          <FieldTitle
            fieldAlign={fieldAlign}
            fieldBg={fieldBg}
            title={title}
            fieldSm={fieldSm}
            windowWidth={windowWidth}
            reminderSection={reminderSection}
          />
          <View
            style={[
              fieldSm ? styles.textInputFieldSm : styles.textInputField,
              styles.frameInfoPdSm,
              fieldBg === 'grey'
                ? styles.bgThinGrey
                : fieldBg === 'blue'
                ? styles.bgBlueExLight
                : fieldBg === 'transparent'
                ? styles.bgTransparent
                : styles.bgWhite,
            ]}>
            <View style={[styles.dataWrapperSm, styles.flexJcCt]}>
              <Text
                style={[
                  reminderSection ? styles.textPrimeSm : styles.textPrimeMd,
                ]}
                allowFontScaling={false}
                >
                {fieldValue ? 'Day ' + fieldValue : ''}
              </Text>
            </View>
          </View>

          <View style={[styles.btnActionView, styles.btnActionInfo]}>
            <View style={styles.MenuTriggerTouch}>
              <Image
                style={[styles.dropArrowFrameIcon]}
                source={require('../../assets/icons/dropdown-arrow.png')}
              />
            </View>
          </View>
        </TouchableOpacity>

        <Popover
          open={open}
          handleClose={this.close}
          anchorEl={anchorEl}
          touchable={this.touchable}>
          <View style={styles.daySelectionBox}>
            <View style={[styles.dayRow, styles.flexRowWrap]}>
              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 1
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(1)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 1
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 1
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      01
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 2
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(2)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 2
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 2
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      02
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 3
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(3)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 3
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 3
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      03
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 4
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(4)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 4
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 4
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      04
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 5
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(5)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 5
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 5
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      05
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 6
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(6)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 6
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 6
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      06
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 7
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(7)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 7
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 7
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      07
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 8
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(8)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 8
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 8
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      08
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 9
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(9)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 9
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 9
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      09
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 10
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(10)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 10
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 10
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      10
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 11
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(11)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 11
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 11
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      11
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 12
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(12)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 12
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 12
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      12
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 13
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(13)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 13
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 13
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      13
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 14
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(14)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 14
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 14
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      14
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 15
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(15)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 15
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 15
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      15
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 16
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(16)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 16
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 16
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      16
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 17
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(17)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 17
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 17
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      17
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 18
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(18)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 18
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 18
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      18
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 19
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(19)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 19
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 19
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      19
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 20
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(20)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 20
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 20
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      20
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 21
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(21)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 21
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 21
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      21
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 22
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(22)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 22
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 22
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      22
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 23
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(23)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 23
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 23
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      23
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 24
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(24)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 24
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 24
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      24
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 25
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(25)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 25
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 25
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      25
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 26
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(26)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 26
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 26
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      26
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 27
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(27)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 27
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 27
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      27
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.dayColWrap}>
                <TouchableOpacity
                  style={[
                    styles.dayColTouch,
                    parseInt(fieldValue) === 28
                      ? styles.dayActive
                      : styles.dayDefault,
                  ]}
                  onPress={() => this.setReminderMonthlyDay(28)}>
                  <View style={styles.dayCol}>
                    <Text
                      style={[
                        parseInt(fieldValue) === 28
                          ? styles.textPrimeBold
                          : styles.textPrime,
                        parseInt(fieldValue) === 28
                          ? styles.textWhite
                          : styles.textBlack,
                      ]}
                      allowFontScaling={false}
                      >
                      28
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Popover>
      </View>
    );
  }
}

export default TextInputDayFrameSpec;
