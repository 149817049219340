import React from 'react';
import { View, Platform } from 'react-native';
import { connect } from 'react-redux';
import SuccessBox from '../../components/ConfirmationBox/successBox';
import UploadProgress from '../../components/ImagesDocumentsForAttachment/uploadProgress';
import CancelUploadConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import TreatmentListContent from '../../components/AttachToOption/treatmentListContent';
import AttachToOptionCommon from './AttachToOptionCommon';
import styles from '../../Css';
import * as actions from '../../store/actions/index';
import { IMAGES_DOCUMENTS } from '../../navigation/path';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { filterTherapy } from '../../util/commonUiLogic';
import { WEB } from '../../util/platformWindowConfig';

class AttachToTreatment extends AttachToOptionCommon {
  state = {
    treatments: [],

    // Filter Therapy
    treatmentsList: {
      activity: {},
      surgery: {},
      naturalHealthProduct: {},
      medication: {},
      other: {},
    },
    saveWait: false,
    successModalVisible: false,
  };

  componentDidMount = () => {
    this.props.navigation.addListener('focus', () => {
      this.setState({treatments: this.props.treatments}, () => {
        this.setState({treatmentsList: filterTherapy(this.state.treatments)});
      });
      this.props.hideMobileMenu();
      pendoTrackEvent('Attachments/@AttachToMedSupp+Act');
    });
    this.props.navigation.addListener('blur', () => {
      if (Platform.OS !== WEB) {
        this.setState({ confirmBox: false, saveWait: false });
      }
      this.props.showMobileMenu();
    });
  };

  handleOnPressBackButton = () => {
    this.props.navigation.push(IMAGES_DOCUMENTS.addImagesDocumentsPath, {
      fromSubPath: true
    });
  };

  render() {
    const { navigation, route, treatmentsProcessed} = this.props;
    const { treatmentsList, saveWait, successModalVisible, progress, confirmBox, artifactId } = this.state;
    return (
      <View style={[styles.pageBodyWrapper]}>
        <TreatmentListContent
          index={IMAGES_DOCUMENTS.index}
          navigation={navigation}
          route={route}
          handleOnPressBackButton={this.handleOnPressBackButton}
          treatmentsList={treatmentsList}
          treatmentsProcessed={treatmentsProcessed}
          saveWait={saveWait}
          handleOnSave={this.handleOnSave}
        />
        {successModalVisible &&
          <SuccessBox
            visibleModal={successModalVisible}
            messageTitle="Success!"
            message="Attachments uploaded"
          />
        }
        {saveWait &&
          <UploadProgress
            visibleModal={saveWait}
            progress={progress}
            onPressCancel={() => {
              this.setState({saveWait: false}, () => {
                setTimeout(() => {
                  this.setState({confirmBox: true});
                }, 500);
              });
            }}
          />
        }
        {confirmBox &&
          <CancelUploadConfirmation
            visibleModal={confirmBox}
            onBackdropPress={() => this.closeCanceConfirmation()}
            onRequestClose={() => this.closeCanceConfirmation()}
            alertTitle={'Cancel Upload?'}
            alertBody={'Your items are still uploading, are you sure you want to cancel?'}
            btnTextOne={'Cancel Upload'}
            btnTextTwo={'Continue Upload'}
            handleCancel={()=> {
              const treatmentArtifactId = artifactId;
              this.setState({
                confirmBox: false,
                saveWait: false,
                progress: 0,
                artifactId: null,
              }, () => {
                this.props.cancelUploadAttachmentProcess(
                  treatmentArtifactId,
                  this.props.journalUserId,
                  res => {
                    this.props.clearFilesToAttach();
                    this.props.navigation.push(IMAGES_DOCUMENTS.path);
                  }
                );
              });
            }}
            handleConfirm={() => this.closeCanceConfirmation()}
          />
        }
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    files: state.imagesDocuments.files,
    treatments: state.routines.treatments,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    user: state.routines.user,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpload: (
      artifactId,
      formData,
      callBack,
      reload,
      journalUserId,
      fileSize,
      uploadConfig,
      cancelToken,
      uniqueId,
    ) =>
      dispatch(
        actions.uploadAttachmen(
          artifactId,
          formData,
          callBack,
          reload,
          journalUserId,
          fileSize,
          uploadConfig,
          cancelToken,
          uniqueId,
        ),
      ),
    clearFilesToAttach: () => dispatch(actions.clearFilesToAttach()),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    setAttachmentUploadStatus: status => dispatch(actions.setAttachmentUploadStatus(status)),
    setAttachmentArtifactId: id => dispatch(actions.setAttachmentArtifactId(id)),
    setAttachmentFullSize: size => dispatch(actions.setAttachmentFullSize(size)),
    showUploadProgress: () => dispatch(actions.showUploadProgress()),
    cancelUploadAttachmentProcess: (artifactId, journalUserId, callback) => 
      dispatch(actions.cancelUploadAttachmentProcess(artifactId, journalUserId, callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttachToTreatment);
