import React, { Component } from "react";
import { View } from "react-native";
import InfiniteScroll from "react-infinite-scroll-component";
import RoutineItem from "../../components/Question/RoutineItem";
import { connect } from "react-redux";
import PageContainer from "../../components/PageLayout/PageContainer";
import { TAB_BREAk_POINT } from "../../util/platformWindowConfig";
import { getScrollHeight } from '../../util/commonUiLogic';
class RutineItemScrollView extends Component {
  state = {
    items: Array.from(this.props.data.slice(0, 19)),
    hasMore: true,
    windowHeight: undefined,
    windowWidth: undefined
  };

  constructor(props) {
    super(props);
    const items = Array.from(props.data.slice(0, 19));
    this.state = {
      items: items,
      hasMore: items.length > 0,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { windowHeight, windowWidth } = props;
    const items = Array.from(props.data.slice(0, 19));
    this.setState({
      items: items,
      hasMore: items.length > 0,
      windowWidth,
      windowHeight
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.journalEntriesAdd === this.props.journalEntriesAdd;
  }

  fetchMoreData = () => {
    if (this.state.items.length >= this.props.data.length) {
      this.setState({ hasMore: false });
      return;
    } else {
      const items = this.state.items.concat(
        this.props.data.slice(
          this.state.items.length,
          this.state.items.length + 19
        )
      );

      if (items.length > this.state.items.length) {
        setTimeout(() => {
          this.setState({
            items: items
          });
        }, 1500);
      }
    }
  };

  render() {
    const { journalUserId, timeZoneWarning, timeZone } = this.props;
    const { windowHeight, windowWidth } = this.state;
    let fullWidth = windowWidth;
    let btnHeight = 108;
    if (fullWidth < TAB_BREAk_POINT) {
      btnHeight = 188;
    }
    let scrollHeight = getScrollHeight(false, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth, btnHeight);
    return (
      //  <div id="scrollableDiv">
      <div
        id="scrollableDiv"
        style={{ height: scrollHeight, overflowY: "auto" }}
      >
        <PageContainer smScreenFull={true}>
        <InfiniteScroll
          dataLength={this.state.items.length}
          next={this.fetchMoreData}
          hasMore={this.state.hasMore}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {this.state.items.map(
            (item, index) =>
              Boolean(this.props.journalEntryTypesProcessed[item.entryType]) && (
                <RoutineItem
                  // journalEntry={item}
                  // handleOnPressAttachments={this.props.handleOnPressAttachments}
                  // key={item.id}
                  // routineTitle={item.title}
                  // routineDate={moment(item.entryDate).format("MM.DD.YYYY")}
                  // routineTime={moment(item.createdOn).format("h:mm a")}
                  // journalEntryType={
                  //   this.props.journalEntryTypesProcessed[item.entryType]
                  // }
                  // flagged={item.flagged}
                  // onDeleteJournal={() => this.props.onDeleteJournal(item.id)}
                  // journalHasAttachments={this.props.journalHasAttachments(
                  //   item.artifactId
                  // )}
                  // index={index}
                  // handleEditJournal={() => this.props.handleEditJournal(item)}

                  id={item.id}
                  entryDate={item.entryDate}
                  createdOn={item.createdOn}
                  title={item.title}
                  flagged={item.flagged}
                  bgColor={
                    this.props.journalEntryTypesProcessed[item.entryType].color
                  }
                  artifactId={item.artifactId}
                />
              )
          )}
        </InfiniteScroll>
        <View style={{ height: 100 }} />
        </PageContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalEntriesAdd: state.question.journalEntriesAdd,
    journalUserId: state.uistate.journalUserId,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};

export default connect(mapStateToProps, null)(RutineItemScrollView);
