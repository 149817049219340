import theme from "./base/theme";
export const cssOutlook = {
    outlookListContainer: {
        flexBasis: 300,
        maxWidth: 300,
    },
    outlookContentContainer: {
        flex: 1,
    },
    outlookListContainerLg: {
        width: '100%',
    },
    outlookContentContainerLg: {
        width: '100%',        
    },
    categoryItemTitle: {
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        backgroundColor: theme.COLOR_GREY_LIGHT_EX_4
    },
    outlookBorder: {
        borderRightWidth: 2,
        borderColor: "rgba(0, 0, 0, 0.15)"
    }
    ,
    singleItemBorder: {
        borderColor: theme.COLOR_GREY_LIGHT_RG,
        borderWidth: 0,
        borderTopWidth: 1,
      },
    itemsContainerBox: {
        height: "100%",
        position: "absolute",
        top: 0,
        justifyContent: "center",
        alignItems: "center",
    },
    itemsContainerBoxLg: {
        width: 50,
    },
    itemsContainerBoxSm: {
        width: 20,
        borderTopRightRadius: 10,
    },
    itemDetailWrapperPd: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 10,
    },
    itemDetailLtPdSm: {
        paddingLeft: 35,
    },
    itemDetailLtPdLg: {
        paddingLeft: 50,
    },
    itemIconSetColSm: {
        flexBasis: 100,
        alignItems: "center",
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    itemIconBoxSm: {
        borderRadius: 5,
    },
    activeAttachIcon: {
        backgroundColor: theme.COLOR_ORANGE,
        width: 8,
        height: 8,
        borderRadius: 8,
    },
    activeAttachBorder: {
        backgroundColor: theme.COLOR_WHITE,
        width: 10,
        height: 10,
        borderRadius: 10,
        alignItems: "center",
    },
    attachementAvailability: {
        position: 'absolute',
        right: -2,
        top: -4
    },
    starIconExSm: {
        width: 16,
        height: 15,
    },
    starInactive: {
        tintColor: theme.COLOR_GREY_LIGHT_EX_14
    },
    textFieldSearchSm: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 48,
        maxHeight: 41,
    },
    fieldColAddBtnSm: {
        flexBasis: 80,
        maxWidth: 80,
      },
    premiumSecBtnAreaCom: {
        maxWidth: 80,
        marginTop: 2,
        marginLeft: -5
    },
    tabTopPd: {
        paddingTop: 5,
    },
    selectedItemBg: {
        backgroundColor: '#fff6f0'
    },
    topicBarWrapper: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: 15,
        //paddingBottom: 15,
        zIndex: 99,
      },
      starIconMd: {
          width: 22,
          height: 21
      },
      duplicateIcon: {
          width: 20,
          height: 20,
      },
      convertIcon: {
        width: 22,
        height: 18,          
      },
      settingIconLight: {
        tintColor: theme.COLOR_GREY,
    },
    settingIconThin: {
        width: 5,
        height: 20,
        resizeMode: "contain",
      },
      iconSectionWrapper: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end"
      },
      iconSectionLg: {
        flexBasis: 190,
      },
      iconSectionSm: {
        flexBasis: 100,
      },
      outlookOverlayView: {
        zIndex: 999,
        top: 0,
        width: 555,
        left: -255,
        position: 'absolute',
      },
      noJournalSm: {
          width: 65,
          height: 60
      },
      noJournalLg: {
        width: 100,
        height: 92
    },
    searchEmptyResultLg: {
        minHeight: 250,
    },
    searchEmptyPd: {
        paddingBottom: 100,
    },
    searchEmptyPdTop: {
        paddingTop: 30,
    },
    toggleButtonView: {
        flexBasis: 70,
        maxWidth: 70,
    },
    toggleButtonText: {
        flex: 1
    },
    resultDateWrapper: {
        position: "absolute",
        width: 90,
        right: 0,
        paddingLeft: 15,
    },
    resultTitleWrapper: {
        paddingRight: 90,
        position: "relative",
        maxWidth: '100%'
    },
};
