import React from "react";
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from "../../Css";
import ProgressBar from "../../modules/react-native-animated-progress";

const progressBar = (props) => {
  return (
    <View style={[styles.progressBarWrapper, styles.flexJcCtAiCt]}>
      <View style={[styles.progressBarImg, styles.infoPdBotImg]}>
        <Image
          source={require("../../assets/icons/pay-card.png")}
          style={styles.payCardIcon}
        />
      </View>

      <View style={[styles.progressBarFull]}>
        <ProgressBar indeterminate height={6} backgroundColor="#FF9E38" />
      </View>

      <View
        style={[
          styles.progressBarContent,
          styles.flexJcCtAiCt,
          styles.titleTopPd,
        ]}
      >
        <Text style={styles.textTitleBold} allowFontScaling={false}>
          {"Processing business card..."}
        </Text>
        <TouchableOpacity 
          style={[styles.comMgTopExSm, styles.btnPdMini]}
          onPress={props.onCancel}
        >
          <Text style={[styles.textPrime, styles.textColorLight]} allowFontScaling={false}>
            {"Cancel"}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default (progressBar);
