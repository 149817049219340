import React, {Component} from 'react';
import {View} from 'react-native';
import styles from '../../../../Css';
import {connect} from 'react-redux';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextFrame from '../../../TextInput/textFrame';
import ItemDeleteButton from '../../../Button/itemDeleteButton';

class UserItemView extends Component {
  constructor(props) {
    super(props);
    const {data} = props;
    this.state = {
      ...data,
    };
  }



  render() {
    const {
      data,
      metricsDigestProcessed,
      unitsOfMeasureDigestProcessed,
      metricsDigest,
      deleteItem,
      index,
    } = this.props;
    const {metric, metricRef: {publishedBy} = {}} = data;
    const {selectableMetrics} = metricsDigestProcessed;

    const {name} = selectableMetrics[metric];
    let unitName;

    const {
      userDefinedMetricUnitOfMeasures,
      systemDefinedMetricUnitOfMeasures,
    } = metricsDigest;
    let found = userDefinedMetricUnitOfMeasures.find(
      element => element.metricId === metric,
    );
    if (!found) {
      found = systemDefinedMetricUnitOfMeasures.find(
        element => element.metricId === metric,
      );
    }
    const {unitsOfMeasure} = unitsOfMeasureDigestProcessed;

    if (found && unitsOfMeasure[found.uomId]) {
      unitName = unitsOfMeasure[found.uomId].symbol;
    }
    return (
      <>
      <View style={[styles.fieldContainer]}>
        <View style={[styles.fieldRowWrapper]}>
          <View style={styles.fieldColStretch}>
            <TextFrame
              title={'Health Data'}
              fieldBg={FieldDetails.BGGREY}
              fieldValue={name}
              noOfLines={1}
              editable={false}
              UserItem
            />
          </View>
          <View style={styles.fieldColSp} />
          <View style={styles.fieldColMeasure}>
            <TextFrame
              title={'Measure'}
              fieldBg={FieldDetails.BGGREY}
              fieldValue={unitName}
              editable={false}
            />
          </View>
          <View style={styles.fieldColSp} />
            <View style={styles.fieldColDelete}>
            {publishedBy !== 'PARTNER' && (
              <ItemDeleteButton touchableOnPress={() => deleteItem(index)} />
            )}
            </View>
        </View>
      </View>
      <View style={[styles.secSepBorderBot, styles.comSp]}></View>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    metricsDigest: state.routines.metricsDigest,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    hasInvalidNamesInActivity: state.therapie.hasActivityInvalidName,
    hasInvalidNamesInMedication: state.therapie.hasMedicationInvalidName,
    hasInvalidNamesInHealthData: state.therapie.hasHealthDataInvalidName,
    hasInvalidNamesInSymtoms: state.therapie.hasSymptomsInvalidName,
  };
};

export default connect(
  mapStateToProps,
  null,
)(UserItemView);
