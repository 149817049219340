import {StyleSheet} from 'react-native';

export default StyleSheet.create({

  surveyMultipleQuestionWrapper: {
    marginTop: 30,
    marginBottom: 30,
  },

  questionImageWrapper: {
    marginTop: 30,
    marginBottom: 30,
    height: 164
  },

  singleQuestionTextboxWrapper: {
    borderRadius: 4,
  },

  singleQuestionInput: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    paddingRight: 10,
    height: 75,
  },

  singleSelectionReadOnlyText: {
    color: '#98989A',
    paddingTop:10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,

  }
});
