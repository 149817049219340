import React, {Fragment, useState} from "react";
import { TouchableWithoutFeedback, View} from "react-native";
import AddButton from "./AddButton";
import SelectCategoryPopUp from "./SelectCategoryPopUp";
import styles from '../../../Css';

const AddEventMenu = (props) => {
  const {menuItems, isDisabled} = props;
  const [eventMenuOpen, setEventMenuOpen] = useState(false);

  return (
    <Fragment>
      <AddButton 
        openCloseEventMenu={() =>
          setEventMenuOpen(!eventMenuOpen)
        } 
        isDisabled = {isDisabled}
        open={eventMenuOpen} />      
      {Boolean(eventMenuOpen) && (
          <Fragment>
            <SelectCategoryPopUp 
              menuItems={menuItems} 
              menuItemPress={() => 
                setEventMenuOpen(false)
              } 
            />
            <TouchableWithoutFeedback 
              onPress={() => setEventMenuOpen(!eventMenuOpen)}
            >
              <View style={[styles.addMenuOverlayView, styles.indicatorIconView]} />
            </TouchableWithoutFeedback>
          </Fragment>
        )}
       
    </Fragment>
  );
};

export default AddEventMenu;
