import React from 'react';
import {View, ScrollView, Text, Image} from 'react-native';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import Header from '../../Header/header';
import SpaceBar from '../../SpaceBar/SpaceBar';
import PageContainer from '../../../components/PageLayout/PageContainer';
import * as ColorCode from '../../../components/ColorPicker/ColorCodes';
import { pendoTrackEvent } from '../../../util/pendoConfig';
import GraphPropertySelector from '../GraphPropertySelector/GraphPropertySelector';

const {Popover} = renderers;
const {styles: stylesRes} = stylesResponsive.getProcessedStyles();

const viewMetric = props => {
  return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={true}
        filterClickHandler={this.handleOnPressFilter}
        clearFiltersHandler={this.handleOnPressClearFilters}
        handleOnPressBackButton={props.handleOnPressBackButton}
        index={props.index}
        navigation={props.navigation}
        route={props.route}
      />
      <SpaceBar />
      <ScrollView style={styles.scrollViewArea}>
        <View style={styles.gridSection}>
          <PageContainer smScreenFull={true}>
            <View>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.secArea}>
                    <View style={[styles.singleItem, styles.greyBorder]}>
                      <View style={[styles.messageRow]}>
                        <View
                          style={[styles.messageDetailSec, styles.fullHeight]}>
                          <View
                            style={[
                              styles.messageDetailCont,
                              styles.fullHeight,
                            ]}>
                            <View
                              style={[
                                stylesRes.customBorderCom,
                                {
                                  backgroundColor: props.setColor
                                    ? props.setColor
                                    : ColorCode.BGHEALTHDATA
                                },
                              ]}
                            />
                            <View
                              style={[
                                stylesRes.messageDetailCom,
                                stylesRes.messageTopBotPdWeb,
                              ]}>
                              <View style={[styles.viewItemDesc]}>
                                <View style={[styles.viewDataFull]}>
                                  <Text style={styles.textPrimeBold} allowFontScaling={false}>
                                    {props.metricName}
                                  </Text>
                                </View>
                                <View style={[styles.viewDate]}>
                                  <Text style={styles.textPrimeMd} allowFontScaling={false}>
                                    {props.uom}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>

                        <View style={stylesRes.messageLinkSecSm}>
                          <View style={styles.iconListSec}>
                            <View
                              style={[
                                styles.settingSec,
                                styles.iconSettingMgLt,
                              ]}>
                              {props.publishedBy !== 'PARTNER' && (
                                <Menu
                                  renderer={Popover}
                                  rendererProps={{
                                    preferredPlacement: 'left',
                                  }}>
                                  <MenuTrigger onPress={() => pendoTrackEvent('ViewHealthData@ > 3Dots')}>
                                    <View
                                      style={[
                                        styles.itemIconSingle,
                                        styles.itemSettingMenuSm,
                                      ]}
                                      nativeID={'viewHealthData3Dots'}>
                                      <Image
                                        style={[
                                          styles.settingIcon,
                                          styles.settingIconDark,
                                        ]}
                                        source={require('../../../assets/icons/settings.png')}
                                      />
                                    </View>
                                  </MenuTrigger>
                                  <MenuOptions style={styles.dropDownList}>
                                    {/* <View style={{padding: 1}} nativeID={'viewHealthData3DotsEdit'}> */}
                                    <MenuOption
                                      style={styles.liItem}
                                      onSelect={() =>
                                        props.onPressEditMetric(props.id)
                                      }>
                                      <Text style={styles.listText} nativeID={'viewHealthData3DotsEdit'} allowFontScaling={false}>Edit</Text>
                                    </MenuOption>
                                    {/* </View> */}
                                    {/* <View style={{padding: 1}} nativeID={'viewHealthData3DotsDelete'}> */}
                                    <MenuOption
                                      style={[
                                        styles.liItem,
                                        // props.recordings > 0 &&
                                        // styles.submitBgGrey
                                      ]}
                                      onSelect={() =>
                                        props.onPressDeleteMetric(props.id)
                                      }>
                                      <Text nativeID={'viewHealthData3DotsDelete'} style={styles.listText} allowFontScaling={false}>
                                        Delete
                                      </Text>
                                    </MenuOption>
                                    {/* </View> */}
                                  </MenuOptions>
                                </Menu>
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={stylesRes.layoutBotPdFixed}>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.secArea}>
                    <View style={[styles.subSection, styles.borderBotGrey]}>
                      <View style={[styles.titleArea, styles.colPdLtRt]}>
                        <View style={styles.subViewPd}>
                          {/* {props.recordings && ( */}
                            <Text
                              style={[
                                styles.textPrime,
                                styles.textAlignCenter,
                              ]}
                              allowFontScaling={false}
                              >
                              This health data has{' '}
                              <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                                {props.recordings}
                              </Text>{' '}
                              recordings.
                            </Text>
                          {/* )} */}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <GraphPropertySelector
                graphStyle={props.graphStyle}
                disabledGraphStyle
                disabledColorPicker
                color={props.setColor}
              />
            </View>
          </PageContainer>
        </View>
      </ScrollView>
    </View>
  );
};
export default (viewMetric);
