import React, {Component} from 'react';
import styles from '../../Css';
import {View, Image, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';

class TabMoreMenuItem extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { setTabMenuIconView, tabMenuIconView, hasNotifications, handleTabMenuClick } = this.props;
        return (
            <View style={[styles.moreTabMenuIconView]} nativeID={'tabMenuMore'}>
                <View style={[styles.flexAiFs]}>
                    <TouchableOpacity
                        style={styles.moreTabMenuIcon}
                        onPress={() => { handleTabMenuClick ? handleTabMenuClick() : setTabMenuIconView() }}
                    >
                        <Image
                            style={[styles.navMainMenuIcon]}
                            source={require("../../assets/main-nav/more.png")}
                        />
                        {/* for ipad and tab */}
                        {Boolean(tabMenuIconView) && Boolean(hasNotifications) && (
                            <View style={[styles.notifyAvailableMenu, styles.notifyAvailableMenuSm]}/>
                        )}
                    </TouchableOpacity>
                </View>
            </View>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setTabMenuIconView: () =>
            dispatch(actions.setTabMenuIconView())
    };
};

export default connect(
    null,
    mapDispatchToProps,
)(TabMoreMenuItem);
