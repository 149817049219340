import { 
    DISABLE_FILTER_COMPONENTS,
    SET_ADVANCED_FILTER_ITEMS, 
    CLEAR_ADVANCED_FILTER_ITEMS,
    SET_SORT_TYPE,
    SET_INNITIAL_DATE_RANGE,
    SET_SELECTED_BY_DATE_OPTION,
    SET_SELECTED_BY_DATE_OBJECT,
    CLEAR_SELECTED_BY_DATE_OBJECT,
    SET_SELECTED_BY_CATEGORY_OBJECT,
    CLEAR_SELECTED_BY_CATEGORY_OBJECT,
    SET_SELECTED_TAGS,
    CLEAR_SELECTED_TAGS,
    ADD_SET_SELECTED_TAGS_COUNT,
    REDUCE_SET_SELECTED_TAGS_COUNT,
    CLEAR_SELECTED_TAGS_COUNT,
    ENABLE_APPLY_BUTTON,
    ENABLE_REMOVE_BUTTON,
    DISABLE_APPLY_BUTTON,
    DISABLE_REMOVE_BUTTON,
    HEALTH_JOURNEY_SELECTED_CATEGORY_LIST,
    ENABLE_CATEGORY_APPLY_BUTTON,
    ENABLE_CATEGORY_REMOVE_BUTTON,
    DISABLE_CATEGORY_APPLY_BUTTON,
    DISABLE_CATEGORY_REMOVE_BUTTON,
    SET_SELECTED_DROPDOWN_NUMBER,
    REMOVE_SELECTED_DROPDOWN_NUMBER,
    RESET_FILTERING_DATA
} from "./actionTypes";


const setDropDownItemNumber = (itemNumber) => {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTED_DROPDOWN_NUMBER,
            payload: itemNumber,
        });
    }
}

const removeDropDownItemNumber = (itemNumber) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_SELECTED_DROPDOWN_NUMBER,
            payload: itemNumber,
        });
    }
}

// select sort actions
const setSortType = (type) => {
    return (dispatch) => {
        dispatch({
            type: SET_SORT_TYPE,
            payload: type,
        });
    }
}

// select date actions
const setInnitialDateRange = (dateRange) => {
    return (dispatch) => {
        dispatch({
            type: SET_INNITIAL_DATE_RANGE,
            payload: dateRange,
        });
    }
}

const setSortByDateOptions = (selectedOption) => {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTED_BY_DATE_OPTION,
            payload: selectedOption,
        });
    }
}

const setSelectedDateObject = (dateObject) => {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTED_BY_DATE_OBJECT,
            payload: dateObject,
        });
    }
}

const clearSelectedDateObject = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SELECTED_BY_DATE_OBJECT,
        });
    }
}

const resetFilterComponents = (isDisabled) => {
    return (dispatch) => {
        dispatch({
            type: DISABLE_FILTER_COMPONENTS,
            payload: isDisabled,
        });
    }
}

// select categories actions
const setSelectCategories = (selectedObject) => {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTED_BY_CATEGORY_OBJECT,
            payload: selectedObject,
        });
    }
}

const clearSelectCategories = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SELECTED_BY_CATEGORY_OBJECT,
        });
    }
}

// advanced filter actions 
const setSelectedTags = (tagName) => {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTED_TAGS,
            payload: tagName,
        });
    };
}

const increaseSelectedTagCount = () => {
    return (dispatch) => {
        dispatch({
            type: ADD_SET_SELECTED_TAGS_COUNT,
        });
    };
}

const decreaseSelectedTagCount = () => {
    return (dispatch) => {
        dispatch({
            type: REDUCE_SET_SELECTED_TAGS_COUNT,
        });
    };
}

const clearSelectedTagCount = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SELECTED_TAGS_COUNT,
        });
    };
}

const clearSelectedTags = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SELECTED_TAGS,
        });
    };
}

const setAdvancedFilterCheckedItems = (advancedFilterList) => {
    return (dispatch) => {
        dispatch({
            type: SET_ADVANCED_FILTER_ITEMS,
            payload: advancedFilterList,
        });
    };
};

const clearAllAdvancedFilteres = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ADVANCED_FILTER_ITEMS,
        });
    }
}

const onApplyButtonEnabled = () => {
    return (dispatch) => {
        dispatch({
            type: ENABLE_APPLY_BUTTON,
        });
    }
}

const onCategoryApplyButtonEnabled = () => {
    return (dispatch) => {
        dispatch({
            type: ENABLE_CATEGORY_APPLY_BUTTON,
        });
    }
}

const onApplyButtonDisable = () => {
    return (dispatch) => {
        dispatch({
            type: DISABLE_APPLY_BUTTON,
        });
    }
}

const onCategoryApplyButtonDisable = () => {
    return (dispatch) => {
        dispatch({
            type: DISABLE_CATEGORY_APPLY_BUTTON,
        });
    }
}

const onRemoveButtonEnabled = () => {
    return (dispatch) => {
        dispatch({
            type: ENABLE_REMOVE_BUTTON,
        });
    }
}

const onCategoryRemoveButtonEnabled = () => {
    return (dispatch) => {
        dispatch({
            type: ENABLE_CATEGORY_REMOVE_BUTTON,
        });
    }
}

const onRemoveButtonDisable = () => {
    return (dispatch) => {
        dispatch({
            type: DISABLE_REMOVE_BUTTON,
        });
    }
}

const onCategoryRemoveButtonDisable = () => {
    return (dispatch) => {
        dispatch({
            type: DISABLE_CATEGORY_REMOVE_BUTTON,
        });
    }
}

const setHealthJourneySelectedCategories = (selectedCategoryItem) => {
    return (dispatch) => {
        dispatch({
            type: HEALTH_JOURNEY_SELECTED_CATEGORY_LIST,
            payload: selectedCategoryItem,
        });
    }
}

const resetFilteringData = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_FILTERING_DATA,
        });
    }
}

export { 
    setSortType,
    setAdvancedFilterCheckedItems, 
    clearAllAdvancedFilteres,
    resetFilterComponents,
    setInnitialDateRange,
    setSortByDateOptions,
    setSelectedDateObject,
    clearSelectedDateObject,
    setSelectCategories,
    clearSelectCategories,
    setSelectedTags,
    clearSelectedTags,
    increaseSelectedTagCount,
    decreaseSelectedTagCount,
    clearSelectedTagCount,
    onApplyButtonEnabled,
    onApplyButtonDisable,
    onRemoveButtonEnabled,
    onRemoveButtonDisable,
    setHealthJourneySelectedCategories,
    onCategoryApplyButtonEnabled,
    onCategoryApplyButtonDisable,
    onCategoryRemoveButtonEnabled,
    onCategoryRemoveButtonDisable,
    setDropDownItemNumber,
    removeDropDownItemNumber,
    resetFilteringData,
}