import axios from '../../axios/axios-config-auth';
import qs from 'qs';
import * as url from './urls';
import * as actionTypes from './actionTypes';
import Cookies from 'js-cookie';
import {
  reset,
  clearJournalEntryFilter,
  resetDataLogout,
  commonErrorHandler,
  resetSetupState,
  setSelectedJournalEntry,
  clearNotifInterval,
  resetPeerToPeer,
  clearTaskGetLatestRequestList,
} from './index';
import {Auth} from 'aws-amplify';
import {
  isMobile,
  isTablet,
  osName,
  osVersion,
  deviceType,
  mobileVendor,
  browserName,
  browserVersion,
} from 'react-device-detect';
import { v1 as uuid } from 'uuid';
import { EventRegister } from 'react-native-event-listeners';

let timeOut = null;
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
    error: false,
  };
};

export const authSuccess = isFromLogin => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    isFromLogin: isFromLogin,
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    errorDescription: error,
  };
};
export const singOut = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const moveToEmailVerificationPhase = (isFromLogin, cognitoUser) => {
  return {
    type: actionTypes.MOVE_TO_EMAIL_VERIFICATION_PHASE,
    isFromLogin: isFromLogin,
    user: cognitoUser
  };
};

export const logout = () => {
  return dispatch => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    dispatch(reset());
    dispatch(resetDataLogout());
    dispatch(singOut());
    dispatch(resetSetupState(() => {}));
    dispatch(setSelectedJournalEntry(null));
    dispatch(clearNotifInterval());
    dispatch(resetPeerToPeer());
    dispatch(clearTaskGetLatestRequestList());
    EventRegister.emitEvent("clientShutdown", true);

    const token = Cookies.get('authToken');
    const jwtToken = Cookies.get('jwtToken');
    Cookies.remove('authToken');
    Cookies.remove('staySignedToken');
    Cookies.remove('jwtToken');
    if (token) {
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
          Authorization: 'Bearer ' + token,
        },
      };
      axios
        .post(
          url.LOG_OUT_COGNITO,
          qs.stringify({
            authToken: jwtToken,
          }),
          axiosConfig,
        )
        .then(response => {
          if (timeOut) {
            clearTimeout(timeOut);
          }
          dispatch(reset());
          dispatch(resetDataLogout());
          dispatch(singOut());
          dispatch(resetSetupState(() => {}));
          dispatch(setSelectedJournalEntry(null));
          dispatch(clearNotifInterval());
          dispatch(resetPeerToPeer());
          dispatch(clearTaskGetLatestRequestList());
        })
        .catch(error => {
          dispatch(reset());
          dispatch(resetDataLogout());
          dispatch(singOut());
          dispatch(resetSetupState(() => {}));
          dispatch(setSelectedJournalEntry(null));
          dispatch(clearNotifInterval());
          dispatch(resetPeerToPeer());
          dispatch(clearTaskGetLatestRequestList());
        });
    }
  };
};

export const keepAlive = () => {
  return dispatch => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    dispatch(refreshToken());
  };
};

export const loginButtonPress = () => {
  return {
    type: actionTypes.LOGIN_PRESS,
  };
};

export const isFromLogin = () => {
  return {
    type: actionTypes.IS_FROM_LOGIN,
  };
};
export const auth = (user, callBack, staySigned, isFromLogin) => {
  return dispatch => {
    dispatch(authStart());

    let devId = localStorage.getItem('x-uuid-id-ssn');
    if (!devId) {
      devId = uuid();
      localStorage.setItem('x-uuid-id-ssn', devId);
    }

    let deviceInfo = {
      deviceId: devId,
      isBrowser: true,
      isMobile: isMobile,
      isTablet: isTablet,
      osName: osName,
      browserName: browserName,
      browserVersion: browserVersion,
      brand: mobileVendor,
      device: osVersion,
      deviceType: deviceType,
    };

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Auth-Info': JSON.stringify(deviceInfo),
      },
    };
    axios
      .post(
        url.LOGIN_COGNITO,
        qs.stringify({
          token: user.signInUserSession.idToken.jwtToken,
          stay: staySigned,
        }),
        axiosConfig,
      )
      .then(response => {
        if (staySigned) {
          Cookies.set('authToken', response.data.authToken, {expires: 90});
          Cookies.set('staySignedToken', 1, {expires: 90});
        } else {
          Cookies.set('authToken', response.data.authToken);
          Cookies.set('staySignedToken', 0, {expires: 90});
        }
        Cookies.set('jwtToken', user.signInUserSession.idToken.jwtToken);

        dispatch(authSuccess(isFromLogin));
        // dispatch(keepAlive());
        dispatch(clearJournalEntryFilter());

        callBack(true);
      })
      .catch(error => {
        if (error.response === undefined) {
          console.log("response undefined; auth");
          dispatch(logout());
        } else {
          dispatch(authFail(error.response.data.error));
          callBack(false);
        }
      });
  };
};

export const refreshToken = () => {
  console.log('KEEP ALIVE');
  return dispatch => {
    dispatch(authStart());

    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then(user => {
        let axiosConfig = {
          headers: {
            Authorization: 'Bearer ' + Cookies.get('authToken'),
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true,
          },
        };

        axios
          .get(url.KEEP_ALIVE, axiosConfig)
          .then(response => {
            const token = Cookies.get('authToken');
            if (token) {
              const staySigned = Cookies.get('staySignedToken');
              if (staySigned === '1') {
                Cookies.set('authToken', response.data.authToken, {expires: 90});
                Cookies.set('staySignedToken', 1, {expires: 90});
              } else {
                Cookies.set('authToken', response.data.authToken);
                Cookies.set('staySignedToken', 0, {expires: 90});
              }

              dispatch(authSuccess());
              timeOut = setTimeout(() => {
                dispatch(refreshToken());
              }, 60000);
            }
          })
          .catch(error => {
            if (error.response === undefined) {
              dispatch(commonErrorHandler('Network error'));
            } else if (error.response.status === 403) {
              console.log("403 error; refreshToken");
              dispatch(logout());
            } else {
              dispatch(authFail(error.response.data.error));
            }
          });
      })
      .catch(err => {
        console.log("cognito error; overall catch");
        dispatch(logout());
      });
  };
};

export const logoutEveryWhere = () => {
  return dispatch => {
    if (timeOut) {
      clearTimeout(timeOut);
    }

    const token = Cookies.get('authToken');
    Cookies.remove('authToken');
    Cookies.remove('staySignedToken');
    if (token) {
      let axiosConfig = {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      };

      axios
        .get(url.LOG_OUT_EVERYWHERE, axiosConfig)
        .then(response => {
          if (timeOut) {
            clearTimeout(timeOut);
          }
          dispatch(reset());
          dispatch(resetDataLogout());
          dispatch(singOut());
          dispatch(resetSetupState(() => {}));
          dispatch(setSelectedJournalEntry(null));
          dispatch(clearNotifInterval());
          dispatch(resetPeerToPeer());
          dispatch(clearTaskGetLatestRequestList());
          EventRegister.emitEvent("clientShutdown", true);
        })
        .catch(error => {
          if (timeOut) {
            clearTimeout(timeOut);
          }
          dispatch(reset());
          dispatch(resetDataLogout());
          dispatch(singOut());
          dispatch(resetSetupState(() => {}));
          dispatch(setSelectedJournalEntry(null));
          dispatch(clearNotifInterval());
          dispatch(resetPeerToPeer());
          dispatch(clearTaskGetLatestRequestList());
        });
    }
  };
};

export const setEmailVerified = emailVerified => {
  return {
    type: actionTypes.SET_EMAIL_VERIFIED,
    emailVerified: emailVerified,
  };
};

export const setMFAEnable = mfaEnable => {
  return {
    type: actionTypes.SET_MFA_ENABLE,
    mfaEnable: mfaEnable,
  };
};

export const resetEmail = (token, callback) => {
  return dispatch => {
    axios
    .post(
      url.RESET_EMAIL,
      qs.stringify({
        token
      })
    ).then(response => {
      callback(true, response);
    }).catch(error => {
      dispatch(commonErrorHandler('Error occured'));
      callback(false);
    });
  }
}
