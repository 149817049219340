import React, { Component } from 'react';
import { Text, View, TouchableOpacity, Platform } from 'react-native';
import styles from '../../../Css';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import PrimaryButton from '../../Button/primaryButton';
import TextDatePickerFrame from '../../TextInput/textDatePickerFrame';
import * as FieldDetails from '../../TextInput/fieldDetails';
import TextInputPlusMinus from '../../TextInput/TextInputPlusMinus';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import TextFrame from '../../TextInput/textFrame';
import TextPickerFrame from '../../TextInput/textPickerFrame';
import moment from 'moment';
import Warning from '../../Warning/warning';
import Duration from './Duration';
import HeathDataItem from './HeathDataItem';
import { generateName } from './Util/MedicationAndActivityNameUtils'
import { pendoTrackEvent } from '../../../util/pendoConfig';
import { verifyDuplicateMedication as verifyDuplicateActivities } from '../../../util/commonUiLogic';
import { WEB } from '../../../util/platformWindowConfig';


class ActivityQuickEntry extends Component {
  constructor(props) {
    super(props);
    const { treatment } = props;
    const { dosages = [], defaultMetrics = [], publishedBy } = treatment;

    const dosage = dosages.length > 0 ? dosages[0] : {};
    const { units } = dosage;
    const unit = dosage?.units && dosage?.units !== '' ? dosage?.units : null;
    const initQuantity = dosage?.quantity || dosage?.quantity === 0 || dosage?.quantity === '0' ? dosage?.quantity : null;

    this.state = {
      ...dosage,
      defaultMetrics: defaultMetrics,
      units: units ? units : 'minutes',
      entryDate: moment().valueOf(),
      error: false,
      errorDescription: '',
      saveWait: false,
      isSaveDisabled: dosage.quantity || dosage.quantity === 0 || dosage.quantity === '0' ? false : true,
      initQuantity: initQuantity,
      unit: unit,
      publishedBy: publishedBy
    };
  }

  static getDerivedStateFromProps(props, state) {
    return null;
  }

  customTouchableComponent = onPress => {
    return (
      Platform.OS === WEB
        ? <TouchableOpacity onPress={onPress}>
          {this.renderDateTimeContent()}
        </TouchableOpacity>
        : this.renderDateTimeContent()
    );
  };

  renderDateTimeContent = () => {
    const { entryDate } = this.state;
    return (
      <TextDatePickerFrame
        title={'Date and Time'}
        fieldBg={FieldDetails.BGWHITE}
        fieldSm={false}
        fieldLt={true}
        fieldValue={
          entryDate ? moment(entryDate).format("D MMM 'YY | h:mm A") : ''
        }
        date={entryDate}
        onDateTimeConfirm={this.onConfirmEntryDate}
        windowWidth={this.props.windowWidth}
      />
    )
  }

  onConfirmEntryDate = date => {
    if (date) {
      this.setState({ entryDate: date });
    }
  };

  updateQuantity = val => {
    const { units, initQuantity } = this.state;
    const { treatment = {} } = this.props;
    const { name } = treatment;

    if (!units || (!initQuantity && initQuantity !== 0 && initQuantity !== '0')) this.verifyDuplicateActivities(name, units, val);
    this.setState({ quantity: val, error: false, errorDescription: '', isSaveDisabled: (val || val === 0 || val === '0') && (units) ? false : true });
  };

  onValueChange = val => {
    const { quantity, units, initQuantity } = this.state;
    const { treatment = {} } = this.props;
    const { name } = treatment;

    if (!units || (!initQuantity && initQuantity !== 0 && initQuantity !== '0')) this.verifyDuplicateActivities(name, val, quantity);
    this.setState({ units: val, isSaveDisabled: quantity && (val || val === 0 || val === '0') ? false : true })
  };

  deleteHelathData = id => {
    let { defaultMetrics } = this.state;
    defaultMetrics = defaultMetrics.filter(res => res.id !== id);
    this.setState({ defaultMetrics: defaultMetrics });
  };

  changeHelathDataValue = (obj, index) => {
    let { defaultMetrics } = this.state;
    defaultMetrics[index] = obj;
    this.setState({
      defaultMetrics: defaultMetrics,
      error: false,
      errorDescription: '',
    });
  };

  addDosage = () => {
    const { quantity, entryDate, units, defaultMetrics, initQuantity, publishedBy } = this.state;
    this.setState({ saveWait: true });

    if (
      defaultMetrics &&
      defaultMetrics.length > 0 &&
      !this.validateHealthData(defaultMetrics)
    ) {
      this.setState({
        error: true,
        saveWait: false,
        isSaveDisabled: false,
        errorDescription: 'Fill all the health data',
      });
    } else if (quantity || quantity === 0 || quantity === '0' || (quantity && quantity.toString().trim() !== '')) {
      const {
        treatment = {},
        applicationSettingsDigest,
        onSaveJournalEntry,
        journalUserId,
        showSuccess,
      } = this.props;
      const { quickNoteEntryTypeId } = applicationSettingsDigest;
      const { name, id } = treatment;
      const metrics = [];
      if (defaultMetrics && defaultMetrics.length > 0) {
        defaultMetrics.forEach((res, index) => {
          metrics.push({
            id: -(index + 1),
            metric: res.metric,
            value: res.value,
            unitOfMeasure: res.unit,
            uomOption: res.uomOption,
          });
        });
      }
      let newTemplate = {
        id: -1,
        title: `${generateName(name.trim())}, ${quantity} ${units}`,
        entryDate: entryDate
          ? moment.utc(entryDate).valueOf()
          : moment.utc().valueOf(),
        entryType: quickNoteEntryTypeId,
        metrics: metrics,
        dosages: [
          {
            id: -1,
            quantity: quantity,
            units: units,
            treatment: id,
          },
        ],
      };

      onSaveJournalEntry(
        { journalEntry: newTemplate },
        (success, data) => {
          if (success) {
            this.setState(
              {
                saveWait: false,
              },
              () => showSuccess(),
            );
          } else {
            this.setState({ error: true, errorDescription: data, saveWait: false }, () => {
              setTimeout(() => {
                this.props.hidePopup();
                this.setState({ error: false, errorDescription: '' });
              }, 1500);
            });
          }
        },
        journalUserId,
      );
      if (publishedBy !== "PARTNER" && (!units || (!initQuantity && initQuantity !== 0 && initQuantity !== '0'))) this.doUpdateTherapy();
    } else {
      this.setState({ error: true, errorDescription: 'Quantity is required', saveWait: false, isSaveDisabled: false });
    }
    pendoTrackEvent('Dashboard@ > RecordActivity > Yes');
  };

  doUpdateTherapy = () => {
    const { units, quantity, initQuantity } = this.state;
    const { journalUserId, treatment = {}, updateTherapie } = this.props;
    const { dosages = [] } = treatment;

    let updatedTherapieData = {
      treatment: {
        ...treatment,
        dosages: [
          {
            ...dosages,
            units: units,
            quantity: (initQuantity || initQuantity === 0 || initQuantity === '0') ? initQuantity : quantity
          },
        ],
      },
    };

    updateTherapie(
      updatedTherapieData,
      (success, data) => {
        if (success) {
          this.setState(
            {
              saveWait: false,
              isSaveDisabled: false,
            },
          );
        }
      },
      journalUserId,
    );
  }

  verifyDuplicateActivities = (name, unit, quantity) => {
    const { listTherapie } = this.props;

    verifyDuplicateActivities(name, null, unit, quantity, listTherapie, (exist, msg) => {
      if (exist) {
        this.onChangeDuplicteActivityState(true, true, msg);
      } else {
        this.onChangeDuplicteActivityState(false, false, null);
      }
    });
  };

  onChangeDuplicteActivityState = (isItemExist, isSaveDisabled, msg) => {
    this.setState({
      isItemExist: isItemExist,
      isSaveDisabled: isSaveDisabled,
      duplicateErrorDescription: msg,
    });
  }

  validateHealthData = defaultMetrics => {
    let valid = true;
    const { userDefinedMetricsProcessed } = this.props;
    defaultMetrics.forEach(res => {
      if (
        userDefinedMetricsProcessed[res.metric] &&
        res.unit &&
        (!res.value)
      ) {
        valid = false;
      }
    });

    return valid;
  };

  render() {
    const {
      entryDate,
      error,
      errorDescription,
      quantity,
      units,
      defaultMetrics,
      saveWait,
      isSaveDisabled,
      initQuantity,
      isItemExist,
      duplicateErrorDescription,
    } = this.state;
    const { treatment, userDefinedMetricsProcessed } = this.props;
    const { name } = treatment;
    return (
      <View style={styles.popupContentWrapper}>
        <View style={[styles.popMessageContView, styles.comSp]}>
          <View style={[styles.popMessageTitleView]}>
            <Text style={[styles.textTitle, styles.textAlignCenter]} allowFontScaling={false}>
              Record one session of <Text style={styles.bold}>{name}?</Text>
            </Text>
          </View>
        </View>
        <View style={styles.modalSectionView}>
          {/* {units === 'minutes' && (
            <Duration
              duration={quantity}
              updateDuration={this.updateQuantity}
            />
          )}
          {units !== 'minutes' && ( */}
          <View style={[styles.fieldRow]}>
            <View style={[styles.fieldRowWrapper]}>
              <View style={styles.fieldColStretch}>
                <TextInputPlusMinus
                  title={'Quantity'}
                  fieldBg={FieldDetails.BGWHITE}
                  fieldAlign={FieldDetails.ALIGNCENTER}
                  fieldLt={true}
                  value={quantity}
                  onChange={this.updateQuantity}
                />
              </View>
              <View style={styles.fieldColSpMd} />

              <View style={styles.fieldColStretch}>
                {units ?
                  <TextFrame
                    title={'Measure'}
                    fieldBg={FieldDetails.BGWHITE}
                    fieldValue={units}
                    fieldAlign={FieldDetails.ALIGNLEFT}
                    fieldLt={true}
                    editable={false}
                  />
                  :
                  <TextPickerFrame
                    title={'Measure'}
                    fieldBg={FieldDetails.BGWHITE}
                    fieldSm={false}
                    darkTxt={true}
                    items={[
                      { key: 'hours', value: 'hours', label: 'hours' },
                      { key: 'minutes', value: 'minutes', label: 'minutes' },
                      {
                        key: 'repetitions',
                        value: 'repetitions',
                        label: 'repetitions',
                      },
                      { key: 'sessions', value: 'sessions', label: 'sessions' },
                    ]}
                    onValueChange={this.onValueChange}
                    selectedValue={units}
                    activitySlider={false}
                  />
                }
              </View>
            </View>
          </View>
          {/* )} */}

          <View style={styles.healthItemsWrapper}>
            {defaultMetrics && defaultMetrics.length > 0 ? (
              <View style={styles.rowTitleView}>
                <Text style={[styles.textPrimeExSmBold, styles.textColorLight]} allowFontScaling={false}>
                  Heath Data Tracking
                </Text>
              </View>
            ) : null}

            {defaultMetrics && defaultMetrics.length > 0 ?

              defaultMetrics.map(
                (res, index) =>
                  Boolean(userDefinedMetricsProcessed[res.metric]) &&
                  Boolean(res.unit) && (
                    <View style={[styles.fieldRow]} key={'hd_' + res.id}>
                      <HeathDataItem
                        helathData={res}
                        index={index}
                        deleteHelathData={this.deleteHelathData}
                        changeHelathDataValue={this.changeHelathDataValue}
                      />
                    </View>
                  ),
              )
              : null
            }
          </View>

          <View style={[styles.fieldRow]}>
            <View style={[styles.fieldRowWrapper]}>
              <View style={styles.fieldColStretch}>
                <DateTimePicker
                  date={entryDate}
                  customTouchableComponent={this.customTouchableComponent}
                  onConfirm={this.onConfirmEntryDate}
                  windowWidth={this.props.windowWidth}
                />
              </View>
            </View>
          </View>
        </View>
        {(Boolean(error) || isItemExist) && <Warning errorDescription={isItemExist ? duplicateErrorDescription : errorDescription} />}
        <View style={[styles.popBtnView, styles.comMgTopSm]}>
          <View style={[styles.btnArea, styles.flexRow, styles.flexJcCtAiCt]}>
            {/* <View style={styles.fieldBtn}>
              <PrimaryButtonWhite
                btnText={'Cancel'}
                btnPosition={'full'}
                touchableOnPress={hidePopup}
              />
            </View>
            <View style={styles.fieldBtnSpSm} /> */}
            <View style={styles.fieldBtn}>
              <PrimaryButton
                btnText={'Yes'}
                btnPosition={'full'}
                touchableOnPress={this.addDosage}
                activityInd={saveWait}
                nativeId={'dashboardRecordActivityYes'}
                disabled={isItemExist || isSaveDisabled || saveWait}
              />
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    userDefinedMetricsProcessed: state.routines.metricsDigestProcessed
      ? state.routines.metricsDigestProcessed.userDefinedMetrics
      : [],
    listTherapie: state.routines.treatments,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveJournalEntry: (journalEntry, callBack, journalUserId) =>
      dispatch(actions.saveJournalEntry(journalEntry, callBack, journalUserId)),
    updateTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.updateTherapie(addNewTherapie, callBack, journalUserId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivityQuickEntry);
