import React, { useEffect, useLayoutEffect, useState } from "react";
import { Image, View, Text, TouchableOpacity, Platform } from "react-native";
import moment from 'moment';
import PickerTemplate from '../../Picker/pickerTemplate';
import Picker from '../../Picker/picker';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import {isLeapYear, pickerMonthAccordingToBirth} from '../../../util/formatDate';
import * as constants from '../../../screens/HealthJourney/HealthJourneyConstant';
import { ANDROID, IOS } from "../../../util/platformWindowConfig";

const StartAndEndDate = (props) => {
   
  const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
  const { handleOpenCloseModal, type, setStartDate, setEndDate, startDate, endDate,  setIsStartDaySelected, setIsEndDaySelected ,isStartDateSelected, isEndDateSelected, dob} = props;

  const [isValueSelected, setIsValueSelected] = useState(false)
  const [isMonthSelected, setIsMonthSelected] = useState(false)
  const [isYearSelected, setIsYearSelected] = useState(false)
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateSelected, setIsDateSelected] = useState(false);
  const [pickerMonthItemList, setPickerMonthItemList] = useState([]);
  const [pickerDateItemList, setPickerDateItemList] = useState([]);
  const [pickerYearItemList, setPickerYearItemList] = useState([]);

  useLayoutEffect(() => {
    if (type === 'start') {
      if (startDate !== null) {
        if (isStartDateSelected ||startDate && startDate.length==10) {
          _handleMonthSelect((parseInt(moment(startDate, 'YYYY-MM-DD').format('M')) - 1).toString());
          Platform.OS === ANDROID || true
          ? _handleDateSelect(startDate.length == 10  ? moment(startDate, 'YYYY-MM-DD').format('D') : 'N/A') 
          : setTimeout(() => {
            _handleDateSelect(startDate.length == 10  ? moment(startDate, 'YYYY-MM-DD').format('D') : 'N/A') 
          }, 10);
          _handleYearSelect(moment(startDate, 'YYYY-MM-DD').format('YYYY'));
          setIsValueSelected(true);
        } else {
          _handleMonthSelect((parseInt(moment(startDate, 'YYYY-MM-DD').format('M')) - 1).toString());
          _handleYearSelect(moment(startDate, 'YYYY-MM-DD').format('YYYY'));
          setIsValueSelected(true);
        }

      }
    } else {
      if (endDate !== null) {
        if (isEndDateSelected || endDate && endDate.length==10) {
          _handleMonthSelect((parseInt(moment(endDate, 'YYYY-MM-DD').format('M')) - 1).toString());
          Platform.OS === ANDROID
          ? _handleDateSelect(endDate.length == 10 ? moment(endDate, 'YYYY-MM-DD').format('D') : 'N/A')
          : setTimeout(() => {
            _handleDateSelect(endDate.length == 10 ? moment(endDate, 'YYYY-MM-DD').format('D') : 'N/A');
          }, 10);
          _handleYearSelect(moment(endDate, 'YYYY-MM-DD').format('YYYY'));
          setIsValueSelected(true);
        } else {
          _handleMonthSelect((parseInt(moment(endDate, 'YYYY-MM-DD').format('M')) - 1).toString());
          _handleYearSelect(moment(endDate, 'YYYY-MM-DD').format('YYYY'));
          setIsValueSelected(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [0]);

     const clearDate =()=>{
      setTimeout(() => {
        setSelectedDate(null);
      }, 0);
       setIsMonthSelected(false);
       setSelectedMonth("-1");
       setSelectedYear(null)
       setPickerYearItemList([{ label: ``, value: ``, key: ``,}])
       setIsValueSelected(!isValueSelected);
       setIsDateSelected(false)
       setIsYearSelected(false)
       setSelectedYear('Year')
     }

  const handleSelectedDate = () => {
    let month = parseInt(selectedMonth) + 1;
    if (type === 'start') {
      if (dateSelected && selectedDate !== 'N/A') {
        setStartDate(moment(`${selectedYear}-${month}-${selectedDate}`, 'YYYY-MM-DD').format('YYYY-MM-DD'));
        setIsStartDaySelected(true);
      }
      else {
        if (selectedDate === 'N/A' || selectedDate === null) {
          setStartDate(moment(`${selectedYear}-${month}`, 'YYYY-MM').format('YYYY-MM'));
          setIsStartDaySelected(false);
        } else {
          setStartDate(moment(`${selectedYear}-${month}`, 'YYYY-MM-DD').format('YYYY-MM-DD'));
          setIsStartDaySelected(true);
        }
      }
    }
    else {
      if (!isMonthSelected && !isYearSelected) {
        setEndDate(null)
      }
      if (dateSelected && selectedDate !== 'N/A') {
        setEndDate(moment(`${selectedYear}-${month}-${selectedDate}`, 'YYYY-MM-DD').format('YYYY-MM-DD'));
        setIsEndDaySelected(true);
        
      } else {
        if (isMonthSelected && isYearSelected) {
          if (selectedDate === 'N/A' || selectedDate === null) {
            setEndDate(moment(`${selectedYear}-${month}`, 'YYYY-MM').format('YYYY-MM'));
            setIsEndDaySelected(false);
          } else {
            setEndDate(moment(`${selectedYear}-${month}`, 'YYYY-MM-DD').format('YYYY-MM-DD'));
            setIsEndDaySelected(true);
          }
        }
      }

    }
  }

  useEffect(() => {
    let maxDate = 31;
    let formattedBodYear = dob?.split('-')[0];
    let formattedBodMonth = dob?.split('-')[1];
    let formattedBodDate = dob?.split('-')[2];
    let currentYear = moment().year();

    let birthYear = formattedBodYear ? formattedBodYear : 1920;
    let pickerDateItems = [{ label: 'Day', value: '-1', key: 'dayInit' }];

    let monthPickerItems = [...constants.months];

    if (currentYear === `${moment().year()}`) {
      monthPickerItems.length = moment().month() + 1;
    }
    setPickerMonthItemList(selectedYear === formattedBodYear ? pickerMonthAccordingToBirth(formattedBodMonth) : monthPickerItems);

    if (!isMonthSelected) {
      pickerDateItems.push({
        label: `N/A`,
        value: `N/A`,
        key: `N/A`,
      });
      setPickerDateItemList(pickerDateItems);
    } else {
      pickerDateItems[0] = {
        label: `N/A`,
        value: `N/A`,
        key: `N/A`,
      }
      switch (selectedMonth) {
        //Feb
        case '1':
          if (isLeapYear(selectedYear)) maxDate = 29;
          else maxDate = 28;
          break;
        //Apr June Sep Nov
        case '3':
        case '5':
        case '8':
        case '10':
          maxDate = 30;
          break;

        default:
          break;
      }

      let onMonth = false;
      if(parseInt(selectedMonth) === (formattedBodMonth -1) && formattedBodYear === selectedYear) {
        onMonth = true;
        // pickerDateItems.push({
        //   label: `N/A`,
        //   value: `N/A`,
        //   key: `N/A`,
        // });
        // setSelectedDate(selectedDate)
      }
      for (let date = onMonth ? parseInt(formattedBodDate) : 1; date <=  maxDate; date++) {
        pickerDateItems.push({
          label: `${date}`,
          value: `${date}`,
          key: `day${date}`,
        });
      }

      _handleDateSelect(!selectedDate || (selectedDate && parseInt(selectedDate) < parseInt(formattedBodDate) && selectedMonth && parseInt(selectedMonth) === (formattedBodMonth -1) && formattedBodYear === selectedYear) ? pickerDateItems[0].value : selectedDate?.toString())
      setPickerDateItemList(pickerDateItems);
    }

    let pickerYearItems = [{label: 'Year', value: '-1', key: 'yearInit'}];
    let startYear = moment().year();
    if (type !== 'start') {
      startYear = moment().year() + 10;
    }
    for (let year = startYear; year >= birthYear; year--) {
        pickerYearItems.push({
        label: `${year}`,
        value: `${year}`,
        key: `year${year}`,
      });
    }
    setPickerYearItemList(pickerYearItems);
    if(selectedYear === formattedBodYear){
      setPickerMonthItemList(pickerMonthAccordingToBirth(formattedBodMonth));
      const selectedM = selectedMonth && parseInt(selectedMonth) < (parseInt(formattedBodMonth) - 1) && formattedBodYear === selectedYear ? '-1' : selectedMonth?.toString();
      _handleMonthSelect(selectedM)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    selectedMonth, 
    selectedYear,   
  ]);

  const _handleSelectToday = () => {
    if (isValueSelected) {
      clearDate()
    }
    else {
      setIsDateSelected(true);
      setIsMonthSelected(true);
      setIsYearSelected(true);
      setSelectedDate(moment().date().toString());
      setSelectedMonth(moment().month().toString());
      setSelectedYear(moment().year().toString());
      setIsValueSelected(true)
    }
  }

  const _handleApply = () => {
    if (!isValueSelected) {
      setStartDate && setStartDate(null);
      setEndDate && setEndDate(null);
      handleOpenCloseModal();
      return;
    }
    
   if((startDate && startDate.length ==10) && (endDate && endDate.length ==10)){
    setIsStartDaySelected(true);
    setIsEndDaySelected(true)
   }
   if((startDate && startDate.length ==10) && (endDate && endDate.length !=10)){
    setIsStartDaySelected(true);
    setIsEndDaySelected(false)
  }
  if((startDate && startDate.length !=10) && (endDate && endDate.length ==10)){
    setIsStartDaySelected(false);
    setIsEndDaySelected(true)
  }
  if((startDate && startDate.length !=10) && (endDate && endDate.length !=10)){
    setIsStartDaySelected(false);
    setIsEndDaySelected(false)
  }
   
    setIsValueSelected(true)
    handleSelectedDate()
    handleOpenCloseModal()
  }

  const _handleMonthSelect = (month) => {
    setSelectedMonth(month)
    if (Platform.OS !== IOS && selectedDate === '-1') {
      setSelectedDate('N/A');
    }
    if (month !== null && month !== '-1') {
      setIsMonthSelected(true)
    } else {
      setIsMonthSelected(false)
    }
    if (isYearSelected) {
      setIsValueSelected(true)
    }
  }

  const _handleYearSelect = (year) => {
    setSelectedYear(year)
    if (year && year !== '' && year !== null && year !== '-1') {
      setIsYearSelected(true)
    } else {
      setIsYearSelected(false)
    }
    if (isMonthSelected) {
      setIsValueSelected(true)
    }
  }

  const _handleDateSelect = (date) => {
    setSelectedDate(date)
    if (date !== null && date !== '-1') {
      if (date !== 'N/A' && type === 'start') {
        setIsStartDaySelected(true);
      } else if (date === 'N/A' && type === 'start') {
        setIsStartDaySelected(false);
      } else if (date !== 'N/A' && type === 'end') {
        setIsEndDaySelected(true);
      } else {
        setIsEndDaySelected(false);
      }
      setIsDateSelected(true)
    } else {
      setIsDateSelected(false)
    }
  }
  
  const disabledApply = () => {
    return ((!isMonthSelected || !isYearSelected) && !(!isValueSelected && ((type === "start" && startDate) || (type === "end" && endDate))))
  }

    return (
      <React.Fragment>
        <View style={[stylesRes.startDateEndDatePosMb, {top: 32}]}>
          <View style={[type !== 'start' && stylesRes.rgMinusFiftyOne, styles.btnMaxAppZindex]} dataSet={{media: ids.rgMinusFiftyOne}}>
            <View style={[styles.startAndEndDateWrapper, styles.ItemRowSp, styles.smPdLtRt]}>
              {/* top */}
              <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt]}>
                <View style={[styles.flexRow, styles.flexAiCt]}>
                  <Image
                      style={styles.calendarIcnNwEvnt}
                      source={require("../../../assets/health-journey-icons/calendar-icon.png")}
                      resizeMode={"contain"}
                  />

                <Text style={[styles.textPrimeSm, styles.calendarTxtNwEvnt, styles.btnIconView,]}>
                  <Text style={styles.pdRtTwo}>
                    {type === 'start' ? constants.START_DATE : constants.END_DATE}
                  </Text>
                  {type === 'start' && (
                    <Text style={styles.dateRequireClr}>
                      *
                    </Text>
                  )}
                  <Text style={styles.textPrimeSmBold}>
                    :
                  </Text>
                </Text>
              </View>
              <View style={[styles.flexRow, styles.flexAiCt]}>
                {type !== 'start' &&
                  <Text style={[styles.textPrime, styles.textGrey, styles.textEm, styles.pdRtEight]}>
                    optional field
                  </Text>
                }
                <TouchableOpacity onPress={handleOpenCloseModal}>
                  <View style={{padding: 10}}>
                    <Image
                      style={{ width: 11, height: 11 }}
                      source={require("../../../assets/health-journey-icons/health-journey-close.png")}
                    />
                  </View>
                </TouchableOpacity>
              </View>
            </View>

              {/* calendat Section */}
              <View style={[styles.comMgTopSm, styles.mgRtTwentyFive, styles.mgLtAndWeb, styles.flexRow, styles.flexAiCt]}>
                {/* month */}
                <View style={[styles.pdRtEight, styles.fieldInRow]}>                      
                  <PickerTemplate>
                    <Picker
                      items={pickerMonthItemList}
                      style={[
                      styles.pickerAreaField,
                      styles.defaultText,
                      styles.textPickerGraph,
                      styles.monthWdHgConainer
                      ]}
                      pickerFrame={'PickerFrameHealthJourneyFilter'}
                      selectedValue={selectedMonth ? selectedMonth : null}
                      onValueChange={_handleMonthSelect}
                    />
                  </PickerTemplate>
                </View>  
                {/* day */}
                <View style={[styles.pdRtEight, styles.fieldInRow]}>
                  <PickerTemplate>
                    <Picker
                      items={pickerDateItemList}
                      style={[
                      styles.pickerAreaField,
                      styles.defaultText,
                      styles.textPickerGraph,
                      styles.dayWdHgConainer
                      ]}
                      pickerFrame={'PickerFrameHealthJourney'}
                      selectedValue={selectedDate ? selectedDate : null}
                      onValueChange={_handleDateSelect}
                    />
                  </PickerTemplate>
                </View>
                {/* year */}
                <View style={[styles.pdRtEight, styles.fieldInRow]}>
                  <PickerTemplate>
                    <Picker
                      items={pickerYearItemList}
                      style={[
                      styles.pickerAreaField,
                      styles.defaultText,
                      styles.textPickerGraph,
                      styles.yearWdHgConainer
                    ]}
                    pickerFrame={'PickerFrameHealthJourney'}
                    selectedValue={selectedYear ? selectedYear : null}
                    onValueChange={_handleYearSelect}
                  />
                </PickerTemplate>
              </View>
            </View>

            {/* action buttons */}
            <View style={[styles.mgLtTwenyFive, styles.mgTopLg, styles.flexRow, styles.flexJcSb, styles.flexAiCt]}>
              <TouchableOpacity onPress={_handleSelectToday}>
                <Text style={[styles.textPrimeExSmBold, styles.seaBluTxtColor, styles.textUnderline]}>
                  {isValueSelected ? 'X Clear Date' : `+ Select todays date`}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={_handleApply} disabled={disabledApply()}>
                <View style={[styles.applyBtn, disabledApply() && styles.btnBlurState]}>
                  <Text style={[styles.textPrimeSmBold, styles.textWhite, styles.postTwo, styles.textWeightFiveHundred]}>
                    Apply
                  </Text>
                </View>
              </TouchableOpacity>
            </View>

          </View>
        </View>
      </View>
    </React.Fragment>
  );
}

export default StartAndEndDate;
