import React from 'react';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import MenuItem from './menuItem';

const addMenuWeb = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {open, colse, opened, menuItems} = props;

  return (
    <View style={[stylesRes.addMainMenu]} dataSet={{media: ids.addMainMenu}}>
      <View 
        style={[stylesRes.addMainMenuIn, stylesRes.btnMaxZindex]}
        nativeID={'orangeAdd'}>
        {!opened ? (
          <TouchableOpacity onPress={open}>
            <Image
              style={[styles.addButtonIconSm]}
              resizeMode={'contain'}
              source={require('../../assets/main-nav/add-menu.png')}
            />
          </TouchableOpacity>
        ) : (
          <React.Fragment>
            <TouchableOpacity onPress={colse}>
              <Image
                style={[styles.closeButtonIconSm]}
                resizeMode={'contain'}
                source={require('../../assets/main-nav/add-menu-close.png')}
              />
            </TouchableOpacity>
            <View style={[stylesRes.addBtnTextCom]}>
              <Text style={[styles.textMicSmBold, styles.textAlignCenter]} allowFontScaling={false}>
                Add New
              </Text>
            </View>
          </React.Fragment>
        )}
      </View>
      {Boolean(opened) && (
        <React.Fragment>
          <View style={[stylesRes.addMenuLgScreen, stylesRes.addMenuLgMg]}>
            <View 
              style={[stylesRes.menuScroll]} dataSet={{media: ids.menuScroll}}>
              {menuItems.map((item, index) => (
                <MenuItem {...item} type={'W'} key={'add_menu_web_' + index} />
              ))}
            </View>
          </View>
          <TouchableWithoutFeedback onPress={colse}>
            <View style={[styles.addMenuOverlayView]} />
          </TouchableWithoutFeedback>
        </React.Fragment>
      )}
    </View>
  );
};

export default (addMenuWeb);
