import React, {Component} from "react";
import {
    View,
    Text,
    Image,
    TextInput,
    TouchableOpacity,
    ScrollView,
} from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import RadioForm from "../../modules/radioButton/RadioButton";
import {connect} from "react-redux";
import * as actions from "../../store/actions/index";
import TickButton from "../../components/CheckBox/CheckBox";
import Picker from "../Picker/picker";
import Dates from "../DateTimePicker/Dates";

const radioProps = [
    {label: "All", value: 0},
    {label: "1 month", value: 1},
    {label: "3 months", value: 2},
    {label: "Custom", value: 3},
];

class JournalEntryFilter extends Component {
    state = {
        viewBy: 0,
        searchKeyword: "",
        visibleCalModal: false,
        startDateClick: false,
        endDateClick: false,
        sortBy: null,
        routineType: 0,
        ticked: false,
        validSearchKeyword: true,
    };

    componentDidMount = () => {
        if (this.props.journalEntryFilter &&
            Object.keys(this.props.journalEntryFilter).length > 2
        ) {
            this.setState({...this.props.journalEntryFilter});
            this.startDate = this.props.journalEntryFilter.startDate
                ? this.props.journalEntryFilter.startDate
                : null;
            this.endDate = this.props.journalEntryFilter.endDate
                ? this.props.journalEntryFilter.endDate
                : null;
        } else {
            let journalEntriesProcessed = this.props.journalEntriesProcessed;
            if (journalEntriesProcessed && journalEntriesProcessed.length > 0) {
                this.setStartAndEndDateUsingJournalEntries(journalEntriesProcessed);
            } else {
                this.startDate = null;
                this.endDate = null;
            }

            this.setState({
                viewBy: 0,
                searchKeyword: "",
                visibleCalModal: false,
                startDateClick: false,
                endDateClick: false,
                sortBy: null,
                routineType: 0,
                ticked: false,
            });
        }
    };

    setStartAndEndDateUsingJournalEntries(journalEntriesProcessed) {
        const sortedJournalEntries = Object.values(journalEntriesProcessed);
        sortedJournalEntries.sort(function (a, b) {
            return parseInt(a.entryDate) - parseInt(b.entryDate);
        });
        this.startDate = new Date(
            sortedJournalEntries[0].entryDate ? sortedJournalEntries[0].entryDate : Date.now()
        );
        this.endDate = new Date(
            sortedJournalEntries[sortedJournalEntries.length - 1].entryDate
                ? sortedJournalEntries[sortedJournalEntries.length - 1].entryDate
                : Date.now()
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.journalEntryFilter &&
            Object.keys(nextProps.journalEntryFilter).length > 2
        ) {
            this.setState({...nextProps.journalEntryFilter});
            this.startDate = nextProps.journalEntryFilter.startDate
                ? nextProps.journalEntryFilter.startDate
                : null;
            this.endDate = nextProps.journalEntryFilter.endDate
                ? nextProps.journalEntryFilter.endDate
                : null;
        } else {
            let journalEntriesProcessed = nextProps.journalEntriesProcessed;
            if (journalEntriesProcessed && journalEntriesProcessed.length > 0) {
                this.setStartAndEndDateUsingJournalEntries(journalEntriesProcessed);
            } else {
                this.startDate = null;
                this.endDate = null;
            }
            this.setState({
                viewBy: 0,
                searchKeyword: "",
                visibleCalModal: false,
                startDateClick: false,
                endDateClick: false,
                sortBy: null,
                routineType: 0,
                ticked: false,
            });
        }
    }

    handleOnChangeSearchKeyword = (value) => {
        this.setState({
            searchKeyword: value,
            validSearchKeyword: true,
        });
    };

    handleOnPressFavourites = () => {
        if (this.state.sortBy === 1) {
            this.setState({sortBy: null});
        } else {
            this.setState({sortBy: 1});
        }
    };

    handleOnPressNewestToOldest = () => {
        if (this.state.sortBy === 2) {
            this.setState({sortBy: null});
        } else {
            this.setState({sortBy: 2});
        }
    };

    handleOnPressOldestToNeweset = () => {
        if (this.state.sortBy === 3) {
            this.setState({sortBy: null});
        } else {
            this.setState({sortBy: 3});
        }
    };

    handleOnPressFilter = () => {
        if (this.state.ticked && !this.state.searchKeyword) {
            this.setState({validSearchKeyword: false});
            return;
        }
        let allState = {
            ...this.state,
            startDate: this.startDate,
            endDate: this.endDate,
        };
        this.props.handleOnPressFilter(allState);
        this.props.onFilterJournalEntry(allState);
        this.startDate = null;
        this.endDate = null;
        this.setState({
            viewBy: 0,
            searchKeyword: "",
            visibleCalModal: false,
            startDateClick: false,
            endDateClick: false,
            sortBy: null,
            routineType: 0,
            ticked: false,
        });
    };

    handleOnPressRadioForm = (value) => {
        const {journalEntriesProcessed} = this.props;
        if (value === 0 && journalEntriesProcessed && journalEntriesProcessed.length > 0) {
            this.setStartAndEndDateUsingJournalEntries(journalEntriesProcessed);
            this.setState({
                viewBy: value,
            });
        } else {
            const startDate = new Date();
            this.setState({viewBy: value});
            if (value === 0 || value === 3) {
                this.startDate = null;
                this.endDate = null;
                this.setState({
                    viewBy: value,
                });
            } else if (value === 1) {
                const endDate = new Date();
                endDate.setDate(endDate.getDate() - 30);
                this.startDate = endDate;
                this.endDate = startDate;
                this.setState({
                    viewBy: value,
                });
            } else if (value === 2) {
                const endDate = new Date();
                endDate.setDate(endDate.getDate() - 90);
                this.startDate = endDate;
                this.endDate = startDate;
                this.setState({
                    viewBy: value,
                });
            }
        }
    };

    ticked(toggleVal) {
        this.setState({
            ticked: toggleVal,
            validSearchKeyword: toggleVal && this.state.searchKeyword ? false : true,
        });
    }

    getRoutineTypes = () => {
        let routineTypes = [{label: "Select Routine type", value: "0", key: 0}];
        this.props.journalEntryTypes
            ? this.props.journalEntryTypes.map(
                (res) =>
                    res.name &&
                    routineTypes.push({
                        label: res.name,
                        value: res.id,
                        key: res.id,
                    })
            )
            : routineTypes.push({});
        return routineTypes;
    };

    updateStartDate = (val) => {
        if (val) {
            this.startDate = new Date(val);
        } else {
            this.startDate = null;
        }
        this.setState({viewBy: 3});
    };

    updateEndDate = (val) => {
        if (val) {
            this.endDate = new Date(val);
        } else {
            this.endDate = null;
        }
        this.setState({viewBy: 3});
    };

    render() {
        const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
        return (
            <View style={[stylesRes.modalScrollFilterView]} dataSet={{media: ids.modalScrollFilterView}}>
                <ScrollView
                    style={styles.modalScroller}
                    keyboardShouldPersistTaps={'always'}>
                    <View style={[styles.popContainer]}>
                        <View style={styles.gridSectionPopup}>
                            <View
                                style={[
                                    styles.filterBlock,
                                    styles.contSmPdTopBot,
                                    styles.contPdLtRtFilter,
                                    styles.bgWhite,
                                ]}
                            >
                                <View style={stylesRes.filterBlockCom}>
                                    <View style={styles.gridRow}>
                                        <View style={styles.gridColFull}>
                                            <View style={styles.fieldRow}>
                                                <View style={styles.fieldCol}>
                                                    <View style={[styles.imgView, styles.imgViewSearch]}>
                                                        <Image
                                                            style={[styles.searchIcon]}
                                                            source={require("../../assets/icons/search.png")}
                                                        />
                                                    </View>
                                                    <TextInput
                                                        style={[
                                                            styles.textField,
                                                            styles.textFieldLg,
                                                            this.state.validSearchKeyword
                                                                ? styles.validColor
                                                                : styles.inValidColor,
                                                        ]}
                                                        allowFontScaling={false}
                                                        onChangeText={this.handleOnChangeSearchKeyword}
                                                        value={this.state.searchKeyword}
                                                        placeholder="Search Keyword"
                                                        underlineColorAndroid="transparent"
                                                        onSubmitEditing={(e) => {
                                                            this.handleOnPressFilter();
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                    <View style={styles.gridRow}>
                                        <View style={styles.gridColFull}>
                                            <View style={styles.fieldRow}>
                                                <View style={styles.fieldCol}>
                                                    <View
                                                        style={[styles.tickButtonRow]}
                                                    >
                                                        <View style={[styles.tickButtonSm]}>
                                                            {!this.state.ticked ? (
                                                                <TickButton
                                                                    imagePath={"untickButton"}
                                                                    toggleTickCallback={(toggleVal) => {
                                                                        this.ticked(toggleVal);
                                                                    }}
                                                                />
                                                            ) : (
                                                                <TickButton
                                                                    imagePath={"tickButton"}
                                                                    toggleTickCallback={(toggleVal) => {
                                                                        this.ticked(toggleVal);
                                                                    }}
                                                                />
                                                            )}
                                                        </View>
                                                        <View
                                                            style={[styles.tickButtonTextRt, styles.flexRow]}
                                                        >
                                                            <Text style={[styles.textPrime]} allowFontScaling={false}>
                                                                Include images and documents in the search
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                    {/* <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.fieldRow}>
                    <View style={styles.fieldCol}>
                    <View style={[styles.tickButtonRow]}>
                    <View style={[styles.tickButtonSm]}>
  
                                    <TickButton
                                          imagePath={"untickButton"}
                                          // toggleTickCallback={() =>
                                          //   props.toggleTickCallback(false)
                                          // }
                                        />
                                        <TickButton
                                          imagePath={"tickButton"}
                                          //toggleTickCallback={() =>
                                            //props.toggleTickCallback(true)
                                         // }
                                        />

                                  </View>
                                  <View
                                    style={[
                                      styles.tickButtonTextRt,
                                      styles.flexRow
                                    ]}
                                  >
                                    <Text style={[styles.textPrime]}>
                                    Include images and documents in the search
                                    </Text>
                                  </View>

                                </View>
                    </View>
                  </View>
                </View>
              </View> */}
                                    <View style={styles.gridRow}>
                                        <View style={styles.gridColFull}>
                                            <View style={styles.fieldRow}>
                                                <View style={styles.fieldCol}>
                                                    <View style={[styles.fieldTopic, styles.pdBotXs]}>
                                                        <Text style={styles.textPrime} allowFontScaling={false}>View
                                                            By:</Text>
                                                    </View>
                                                    <View style={styles.radioSecFul}>
                                                        <RadioForm
                                                            style={styles.radioSet}
                                                            radio_props={radioProps}
                                                            initial={this.state.viewBy}
                                                            onPress={this.handleOnPressRadioForm}
                                                            formHorizontal={true}
                                                            buttonColor={"rgba(152, 152, 154, 0.6)"}
                                                            selectedButtonColor={"#FF8300"}
                                                            labelStyle={styles.textPrimeSm}
                                                        />
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                    <Dates
                                        startDate={this.startDate}
                                        endDate={this.endDate}
                                        updateStartDate={this.updateStartDate}
                                        updateEndDate={this.updateEndDate}
                                    />

                                    <View style={styles.gridRow}>
                                        <View style={styles.gridColFull}>
                                            <View style={styles.fieldRow}>
                                                <View style={styles.fieldCol}>
                                                    <View style={[styles.fieldTopic, styles.pdBotXs]}>
                                                        <Text style={styles.textPrime} allowFontScaling={false}>Sort
                                                            By:</Text>
                                                    </View>
                                                    <View style={styles.sortSecFul}>
                                                        <View style={styles.sortSecBlock}>
                                                            <TouchableOpacity
                                                                onPress={this.handleOnPressFavourites}
                                                            >
                                                                <View
                                                                    style={[
                                                                        styles.sortItemSingle,
                                                                        this.state.sortBy
                                                                            ? this.state.sortBy === 1
                                                                                ? {
                                                                                    backgroundColor:
                                                                                        "rgba(152, 152, 154, 0.6)",
                                                                                }
                                                                                : {}
                                                                            : {},
                                                                    ]}
                                                                >
                                                                    <Text style={styles.sortItem}
                                                                          allowFontScaling={false}>
                                                                        Favourites
                                                                    </Text>
                                                                </View>
                                                            </TouchableOpacity>
                                                            <TouchableOpacity
                                                                onPress={this.handleOnPressNewestToOldest}
                                                            >
                                                                <View
                                                                    style={[
                                                                        styles.sortItemSingle,
                                                                        this.state.sortBy
                                                                            ? this.state.sortBy === 2
                                                                                ? {
                                                                                    backgroundColor:
                                                                                        "rgba(152, 152, 154, 0.6)",
                                                                                }
                                                                                : {}
                                                                            : {},
                                                                    ]}
                                                                >
                                                                    <Text style={styles.sortItem}
                                                                          allowFontScaling={false}>
                                                                        Newest to Oldest
                                                                    </Text>
                                                                </View>
                                                            </TouchableOpacity>
                                                            <TouchableOpacity
                                                                onPress={this.handleOnPressOldestToNeweset}
                                                            >
                                                                <View
                                                                    style={[
                                                                        styles.sortItemSingle,
                                                                        this.state.sortBy
                                                                            ? this.state.sortBy === 3
                                                                                ? {
                                                                                    backgroundColor:
                                                                                        "rgba(152, 152, 154, 0.6)",
                                                                                }
                                                                                : {}
                                                                            : {},
                                                                    ]}
                                                                >
                                                                    <Text style={styles.sortItem}
                                                                          allowFontScaling={false}>
                                                                        Oldest to Newest
                                                                    </Text>
                                                                </View>
                                                            </TouchableOpacity>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.gridRow}>
                                        <View style={styles.gridColFull}>
                                            <View style={styles.fieldRow}>
                                                <View style={styles.fieldCol}>
                                                    <View style={[styles.fieldTopic, styles.pdBotXs]}>
                                                        <Text style={styles.textPrime} allowFontScaling={false}>Routine
                                                            type:</Text>
                                                    </View>

                                                    <View>
                                                        <View style={[styles.pickerAreaWrapper]}>
                                                            <View
                                                                style={[
                                                                    stylesRes.dropArrowView,
                                                                    stylesRes.dropArrowCent,
                                                                ]}
                                                                pointerEvents={"none"}
                                                            >
                                                                <View style={[stylesRes.dropArrowIcon]}>
                                                                    <Image
                                                                        style={[styles.imgContain]}
                                                                        source={require("../../assets/icons/dropdown-arrow.png")}
                                                                    />
                                                                </View>
                                                            </View>
                                                            <Picker
                                                                items={this.getRoutineTypes()}
                                                                selectedValue={this.state.routineType}
                                                                style={[
                                                                    styles.pickerAreaField,
                                                                    styles.textPickerPdSmCom,
                                                                ]}
                                                                onValueChange={(itemValue) => {
                                                                    this.setState({routineType: itemValue});
                                                                }}
                                                            />
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.gridRow}>
                                        <View style={styles.gridColFull}>
                                            <View style={styles.btnBlock}>
                                                <TouchableOpacity onPress={this.handleOnPressFilter}>
                                                    <View style={styles.submit} underlayColor="#fff">
                                                        <Text style={styles.submitText}
                                                              allowFontScaling={false}>Filter</Text>
                                                    </View>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </ScrollView>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        journalEntryFilter: state.routines.journalEntryFilter,
        journalEntryTypes: state.routines.journalEntryTypes,
        journalEntriesProcessed: state.routines.journalEntriesProcessed,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFilterJournalEntry: (filter) =>
            dispatch(actions.filterJournalEntry(filter)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JournalEntryFilter);
