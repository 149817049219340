import React from 'react';
import {View, Image, Text} from 'react-native';
import styles from '../../Css';
import ModalLayout from '../ModalLayout/modalLayout';

const timeZoneSuccess = props => {
  const {timeZone} = props;
  return (
      <ModalLayout
        contPosition={'center'}
        showClose={false}
      >
        <View style={styles.popupContentWrapper}>
          <View style={[styles.popIconView, styles.comMgBot]}>
            <View style={styles.popMessageIconView}>
            <Image
                style={styles.tickCircle}
                source={require("../../assets/icons/right-green-icon.png")}
            />
            </View>
          </View>
          <View style={[styles.popMessageContView, styles.comSp, styles.successPopUpAlgn]}>
            <View style={[styles.popMessageTitleView, styles.comMgBotSm]}>
              <Text
                style={[
                  styles.textTitleBold,
                  styles.textAlignCenter,
                ]}
                allowFontScaling={false}
                >
                Success!
              </Text>
            </View>

              <View style={[styles.popMessageDescView]}>
                <Text style={[styles.textTitle, styles.textAlignCenter]} allowFontScaling={false}>
                    Your time zone was updated to: 
                    {` ${timeZone}`}
                </Text>
              </View>
          </View>
        </View>
      </ModalLayout>
  );
};

export default (timeZoneSuccess);
