import { QUESTION_TYPES } from './questionUtilConstants';

const surveyAnswerValidation = (function () {
  function isValid(answer, questionType, extraParams) {
    if (questionType === QUESTION_TYPES.multipleAnswerQuestion) {
      if (!answer) {
        return false;
      }

      let { selectedItems = [] } = answer;
      let { otherText = "" } = answer;
      if ((!selectedItems || !selectedItems.length) && !otherText?.trim()) {
        return false;
      }
    } else if (questionType === QUESTION_TYPES.choiceQuestion) {
      if (!answer) {
        return false;
      }

      const { selectedItem, otherText = '' } = answer;
      if (!selectedItem && !otherText?.trim()) {
        return false;
      }
    } else if (questionType === QUESTION_TYPES.descriptiveQuestion) {
      // Nothing to validate.
    } else if (questionType === QUESTION_TYPES.longAnswerQuestion) {
      if (!answer || !answer.trim()) {
        return false;
      }
    } else if (questionType === QUESTION_TYPES.slider) {
      if (answer === null || answer === -1) {
        return false;
      }
    } else if (questionType === QUESTION_TYPES.symptomSliderQuestion) {
      if (answer === null || answer === -1) {
        return false;
      }
    } else if (questionType === QUESTION_TYPES.surveyTable) {
      if (!answer || !extraParams || !extraParams.length || !answer.length) {
        return false;
      } else if (extraParams && extraParams.length) {
        const hasRequiredRows = extraParams.some(questionItem => questionItem && questionItem.required);
        if (!hasRequiredRows) {
          return true;
        } else if (hasRequiredRows && (!answer || !answer.length)) {
          return false;
        } else if (hasRequiredRows && extraParams.some(questionItem => questionItem && questionItem.required && !questionItem.hide && !(questionItem.answers && questionItem.answers.length && questionItem.answers[0].value && questionItem.answers[0].value !== ''))) {
          return false;
        }
      }
    };

    return true;
  };

  return {
    isValid,
  };
})();

export const getSurveyTableErrorRows = (questionTable) => {
  const requiredRows = questionTable.length ? questionTable.filter(rowItem => rowItem.required && !rowItem.hide && !(rowItem.answers && rowItem.answers.length && rowItem.answers[0].value && rowItem.answers[0].value !== "")).map((rowData) => rowData.rowId) : [];

  return requiredRows;
}
export default surveyAnswerValidation;