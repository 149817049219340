import React from 'react';
import {View, Text} from 'react-native';
import styles from '../../../../Css';
import CollapseDetailDescription from '../../../TabCollapse/collapseDetailDescription';

const medicationsTabContent = props => {
  const {windowWidth, onPressSetupNow} = props;

  return (
    <CollapseDetailDescription
      itemTitle={'Add Medications, Supplements & Activities to track regularly'}
      imgSource={require('../../../../assets/setup/medical.png')}
      windowWidth={windowWidth}
      onPressSetupNow={onPressSetupNow}>
      <View style={[styles.collapseContDec]}>
        <View style={[styles.collapseContPara, styles.comMgBotSm]}>
          <Text style={[styles.textPrimeSm, styles.textGrey]} allowFontScaling={false}>
          You can add your medications or supplements by searching the name or DIN/NPN. 
          </Text>
        </View>

        <View style={[styles.collapseContPara]}>
          <Text style={[styles.textPrimeSm, styles.textGrey]} allowFontScaling={false}>
          You can also add custom activities or select from a suggested list. 
          </Text>
        </View>
      </View>
    </CollapseDetailDescription>
  );
};

export default (medicationsTabContent);
