import React, {Component} from 'react';
import {View} from 'react-native';
import styles from '../../../../Css';
import {connect} from 'react-redux';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextFrame from '../../../TextInput/textFrame';
import ItemDeleteButton from '../../../Button/itemDeleteButton';
import MedticationTooltipContent from '../../../TooltipContent/Medtication';

class UserItemView extends Component {
  constructor(props) {
    super(props);
    const {data} = this.props;
    const {quantity} = data;
    this.state = {
      quantity: quantity,
    };
  }


  render() {
    const {
      data,
      treatmentsProcessed,
      deleteItem,
      index,
      handleRedirectMedications,
      windowWidth,
    } = this.props;
    const {treatment, treatmentObj: {publishedBy} = {}} = data;
    const {
      name,
      identificationNumber,
      treatmentType,
      dosages,
    } = treatmentsProcessed[treatment];
    const {quantity} = dosages && dosages[0] ? dosages[0] : {};

    return (
      <>
        <View style={[styles.fieldContainer]}>
          <View style={[styles.fieldRowWrapper]}>
            <View style={styles.fieldColStretch}>
              <TextFrame
                title={'Medication'}
                fieldBg={FieldDetails.BGGREY}
                fieldInfo={true}
                fieldValue={name}
                editable={false}
                renderComponent={() => (
                  <MedticationTooltipContent
                    identificationNumber={identificationNumber}
                    treatmentType={treatmentType}
                    treatment={treatmentsProcessed[treatment]}
                    handleRedirectMedications={handleRedirectMedications}
                    windowWidth={windowWidth}
                    showLink={true}
                  />
                )}
                noOfLines={1}
              />
            </View>
            <View style={styles.fieldColSp} />
            <View style={styles.fieldColPlusMinusText}>
              <TextFrame
                title={'Quantity'}
                fieldBg={FieldDetails.BGGREY}
                fieldAlign={FieldDetails.ALIGNCENTER}
                fieldValue={quantity}
                editable={false}
              />
            </View>
            <View style={styles.fieldColSp} />
            <View style={styles.fieldColDelete}>
              {publishedBy !== 'PARTNER' && (
                <ItemDeleteButton touchableOnPress={() => deleteItem(index)} />
              )}
            </View>
          </View>
        </View>
        <View style={[styles.secSepBorderBot, styles.comSp]} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    treatmentsProcessed: state.routines.treatmentsProcessed,
    hasInvalidNamesInActivity: state.therapie.hasActivityInvalidName,
    hasInvalidNamesInMedication: state.therapie.hasMedicationInvalidName,
    hasInvalidNamesInHealthData: state.therapie.hasHealthDataInvalidName,
    hasInvalidNamesInSymtoms: state.therapie.hasSymptomsInvalidName,
  };
};

export default connect(
  mapStateToProps,
  null,
)(UserItemView);
