export const redirect = {
  login: 'dashboard',
  'journal-entry/view': 'journals-list',
  'journal-entry/edit': 'journals-list',
  'journals-list': 'journals-list',
  'journals-list/view': 'journals-list/view',
  'journals-list/edit': 'journals-list/edit',
  'medications-therapies': 'medications-therapies',
  'view-medications-therapies': 'medications-therapies',
  'add-therapy': 'medications-therapies',
  'edit-therapy': 'medications-therapies',
  contacts: 'contacts',
  'view-contact': 'contacts',
  'add-contact': 'contacts',
  'edit-contact': 'contacts',
  todos: 'todos',
  'add-todo': 'add-todo',
  'todo-attach-to-journal': 'todos',
  'todo-attach-to-contact': 'todos',
  'todo-attach-to-treatment': 'todos',
  'images-documents': 'images-documents',
  'add-images-documents': 'add-images-documents',
  'attach-images-documents-to-journal': 'add-images-documents',
  'attach-images-documents-to-contact': 'add-images-documents',
  'attach-images-documents-to-treatment': 'add-images-documents',
  reports: 'reports',
  'report-new': 'reports',
  'report-edit': 'reports',
  'report-view': 'reports',
  'report-add-symptom': 'reports',
  'report-add-issue': 'reports',
  'report-add-medications': 'reports',
  'report-add-image': 'reports',
  'report-add-graph': 'reports',
  surveys: 'surveys',
  'main-survey': 'surveys',
  'survey-question': 'surveys',
  routines: 'routines',
  'add-new-routine': 'routines',
  'edit-routine': 'routines',
  symptoms: 'symptoms',
  'view-symptom': 'symptoms',
  'add-symptom': 'symptoms',
  'edit-symptom': 'symptoms',
  'health-data': 'health-data',
  'view-health-data': 'health-data',
  'add-health-data': 'health-data',
  'edit-health-data': 'health-data',
  'manage-devices':'manage-devices',
  'login-device':'login-device',
  'graphs': 'graphs',
  'add-graph': 'graphs',
  'edit-graph': 'graphs',
  caregivers: 'profile-caregiver-access',
  'caregiver-settings': 'profile-caregiver-access',
  'push-notifications': 'push-notifications',
  'about-hanalaytics': 'about-hanalaytics',
  profile: 'profile',
  'profile-my-devices': 'profile-my-devices',
  'profile-caregiver-access': 'profile-caregiver-access',
  'profile-invite-caregiver': 'profile-invite-caregiver',
  'profile-measurements': 'profile-measurements',
  help: 'help',
  'clinical-trial-disclaimer': 'clinical-trial-disclaimer',
  'clinical-trials': 'clinical-trials',
  'health-library': 'health-library',
  'share-resource': 'health-library',
  'profile-cancel-subscription': 'profile-cancel-subscription',
  'profile-premium-account': 'profile-premium-account',
  'profile-upgrade-premium': 'profile-premium-account',
  'email- verification': 'email-verification',
  'community': 'community',
  'find-connections': 'community',
  'search-connections': 'community',
  'conversation': 'community',
  'start-new-chat': 'community',
  joinstudy: 'dashboard',
  'my_health_journey': 'my_health_journey',
  'add_event': 'my_health_journey',
  'add_attachments': 'my_health_journey',
};
