export const shadowStyleOne = {
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)"
};
export const shadowStyleTwo = {
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)"
};
export const shadowStyleThree = {
  boxShadow: "0px 4px 4px rgba('0, 0, 0', 0.15)"
};
export const shadowStyleFour = {
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)"
};
export const shadowStyleFive = {
  boxShadow: "4px 0px 7px rgba(0, 0, 0, 0.15)"
};
export const shadowStyleSix = {
  boxShadow: "3px 0px 3px rgba(0, 0, 0, 0.10)"
};
export const shadowStyleSeven = {
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
};
export const shadowStyleEight = {
  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)"
};
export const shadowStyleNine = {
  boxShadow: "inset 0px 2px 2px rgba(0, 0, 0, 0.15)"
};
export const shadowStyleTen = {
  boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.15)"
};
export const shadowStyleEleven = {
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
};
export const shadowStyleTwelve = {
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)"
};
export const shadowStyleThirteen = {
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
};
export const shadowStyleFourteen = {
  boxShadow: "0px -5px 17px rgba(152, 152, 154, 0.2)"
};
export const shadowStyleFifteen = {
  boxShadow: "0 -5px 5px -5px rgba(152, 152, 154, 0.3)"
};
export const shadowStyleSixteen = {
  boxShadow: "0 -5px 5px -5px rgba(152, 152, 154, 0.2)"
};
export const shadowStyleSeventeen = {
  boxShadow: "0px 11px 8px -10px rgba(152, 152, 154, 0.2)"
};
export const shadowStyleEighteen = {
  boxShadow: "0px 80px 0px 80px #ffffff"
};
export const shadowStyleNineteen = {
  boxShadow: "3px 3px 4px rgba(0, 0, 0, 0.3)"
};
export const shadowStyleTwenty = {
  boxShadow: "0px -5px 17px rgba(152, 152, 154, 0.2)"
};
export const shadowStyleTwentyOne = {
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
};
export const fieldShadow = {
  boxShadow: "0px 4px 4px #9DC2FF"
};
export const contentBoxShaow = {
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)"
};
export const itemShadow = {
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)"
};
export const menuShadow = {
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)"
};
export const autoSuggestShadow = {
  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.18)"
}
export const noteBoxShadow = {
  boxShadow: "0px 4px 4px #9DC2FF"
};
export const errShadow = {
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.34)"
};
export const submitBtnShadow = {
  boxShadow: "0px 2px 4px #C4DAFF, 0px 1px 2px #9DC2FF",
};
export const btnShadowCom = {
  boxShadow: "0px 4px 4px #9DC2FF",
};
export const simpleBtnShadow = {
  boxShadow: "0px 4px 4px rgba(64, 64, 65, 0.21)"
};
export const menuBoxShadow = {
  boxShadow: "rgba(0, 0, 0, 0.3) 3px 3px 4px"
};
export const itemBoxShadow = {
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)"
};
export const previewBoxShadow = {
  boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)"
};
export const commentBoxShadow = {
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)"
};
export const fieldShadowSpec = {
  boxShadow: "0px 4px 4px #9DC2FF"
};
export const btnShadowSpec = {
  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)"
};
export const startDateEndDateShadow = {
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
};
export const selcetCatSidePopUpShadow = {
  boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.1)"
};
