import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  ScrollView,
  Platform
} from "react-native";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";
import Modal from "../../Modal/modal";
import { connect } from "react-redux";
import { WEB } from "../../../util/platformWindowConfig";


class attachmentImageBox extends Component {
  constructor(props) {
    super(props);
    const { windowHeight, windowWidth } = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      loading: true,
      width: Platform.OS !== WEB ? windowWidth : 200,
      height: Platform.OS !== WEB ? windowHeight : 200,
    };
  }
  componentDidMount = () => {
    const { downloadUrl } = this.props;

    Image.getSize(
      downloadUrl,
      (width, height) => {
        this.setState({
          width: width,
          height: height,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { windowHeight, windowWidth, downloadUrl } = nextProps;

    this.setState({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      loading: true,
    });

    Image.getSize(
      downloadUrl,
      (width, height) => {
        this.setState({
          width: width,
          height: height,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }

  closePopup = () => {

    this.setState({ loading: false }, () => {
      if (this.props.onRequestClose) this.props.onRequestClose();
      if (this.props.close) this.props.close();
    })

  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const { loading, windowWidth, windowHeight, width, height } = this.state;
    let fullWidth = windowWidth;
    let fullHeight = windowHeight;
    let fullImageWidth = width;
    let fullImageHeight = height;
    let fullImageDynamicWidth = fullImageWidth;
    let fullImageDynamicHeight = fullImageHeight;
    let modalHeight = "100%";
    if (fullImageWidth > 1440 && fullWidth > 1440) {
      fullImageDynamicWidth = 1440;
    } else if (fullImageWidth > fullWidth) {
      fullImageDynamicWidth = fullWidth;
    }
    fullImageDynamicWidth = (fullImageDynamicWidth * 80) / 100;
    fullImageDynamicHeight =
      (fullImageHeight / fullImageWidth) * fullImageDynamicWidth;
    let modalSectionWidth = fullImageDynamicWidth + 10;
    let modalSectionHeight = fullImageDynamicHeight + 10;
    let modalSectionFullHeight = modalSectionHeight + 10;
    if (modalSectionFullHeight > fullHeight) {
      fullImageDynamicHeight = fullHeight - 140;
      fullImageDynamicWidth =
        (fullImageWidth / fullImageHeight) * fullImageDynamicHeight;
      modalSectionWidth = fullImageDynamicWidth + 10;
      modalSectionHeight = fullImageDynamicHeight + 10;
    }
    let modalWidth = fullImageDynamicWidth + 10;
    let modalCloseAreaWidth = modalWidth + 20;
    const {
      downloadUrl,
      visibleModal,
      onBackdropPress,
    } = this.props;

    return (
      <Modal
        visible={visibleModal}
        onRequestClose={this.closePopup}
        onBackdropPress={onBackdropPress}
        backdropStyle={true}
      >
        <View
          style={[
            styles.modalMiddleView,
            {
              width: modalSectionWidth,
              height: modalSectionHeight ? modalSectionHeight : 100,
            },
          ]}
        >
          <View
            style={[
              stylesRes.modalCloseImgPreview,
              { width: modalCloseAreaWidth },
            ]}
            dataSet={{media: ids.modalCloseImgPreview}}
          >
            <TouchableOpacity
              onPress={this.closePopup}
              style={styles.modalClose}
            >
              <View style={styles.closeIconView}>
                <Image
                  style={styles.closeIcon}
                  source={require("../../../assets/icons/close.png")}
                />
              </View>
            </TouchableOpacity>
          </View>
          <View
            style={[
              styles.popUpBlockFull,
              styles.mgTopPopUpForClose,
              { maxHeight: modalHeight ? modalHeight : 100 },
            ]}
          >
            <ScrollView style={styles.modalScroller}>
              <View style={{ width: modalWidth }}>
                <View style={[styles.popUpBlock, styles.attachBg]}>
                  <View style={styles.popUpContentArea}>
                    <View style={styles.gridSectionPopup}>
                      <View style={[styles.popUpContentPdRgSm]}>
                        <View style={stylesRes.modalContIn}>
                          <View style={styles.gridRow}>
                            <View style={styles.gridColFull}>
                              <View style={[styles.infoBlock]}>
                                <View style={stylesRes.imgPreviewSecCom}>
                                  <View
                                    style={[
                                      stylesRes.imgPreviewBoxCom,
                                      {
                                        width: fullImageDynamicWidth,
                                        height: fullImageDynamicHeight
                                          ? fullImageDynamicHeight
                                          : 100,
                                      },
                                    ]}
                                  >
                                    {Boolean(downloadUrl) && (
                                      <Image
                                        style={[styles.imgContain]}
                                        source={{
                                          uri: downloadUrl,
                                        }}
                                        onLoad={() =>
                                          this.setState({ loading: false })
                                        }
                                      />
                                    )}
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                    {loading && (
                      <View style={[styles.centerSecion]}>
                        <ActivityIndicator color="#FF8300" size="small" />
                      </View>
                    )}
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(mapStateToProps)(attachmentImageBox);
