import React from 'react';
import {View, Text, Image, TouchableOpacity, Linking} from 'react-native';
import styles from '../../Css';

import { CONTACT_EMAIL } from '../../util/commonUiConfig';
import { pendoTrackEvent } from '../../util/pendoConfig';

const emailus = (props) => {
  return (
    <View style={[styles.detailItemRow, styles.borderComBot]}>
      <TouchableOpacity
        onPress={() => {
          Linking.openURL(`mailto:${CONTACT_EMAIL}`);
          pendoTrackEvent(props.eventName);
        }}>
        <View style={[styles.detailItemWrapper, styles.detailItemFirst]} nativeID={props.nativeId}>
          <View style={[styles.detailItemTitle]}>
            <View style={[styles.itemIconView]}>
              <Image
                source={require('../../assets/contact/email-us.png')}
                style={styles.itemContactIcon}
              />
            </View>
            <View style={[styles.itemTextView]}>
              <Text style={[styles.textPrimeBold, styles.textColorLight]} allowFontScaling={false}>
                Email Us
              </Text>
            </View>
          </View>
          <View style={[styles.detailItemData]}>
            <Text
              style={[
                styles.textPrimeBold,
                styles.textOrange,
                styles.textAlignRight,
              ]}
              allowFontScaling={false}
              >
              {CONTACT_EMAIL}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (emailus);
