// set up tabs
export const tabs = {
  profile: 1,
  medications: 2,
  symptom: 3,
  healthData: 4,
  routine: 5,
};

// peer to peer tabs
export const peerToPeerTabs = {
  chats: 0,
  myNetwork: 1,
  profile: 2,
};

// peer to peer statuses
export const peerToPeerStatuses = {
  INITIALIZING: "INITIALIZING",
  INITIALIZED: "INITIALIZED",
  STOPED: "STOPED"
};

//survey tabs
export const surveyTabs = {
  open: 0,
  completed: 1
}

// peer to peer tab path names
export const peerToPeerTabPath = {
  chats: 'chats',
  myNetwork: 'my-connections',
  profile: 'profile',
};

// clinical trial tab path names
export const clinicalTrialTabPath = {
  search: 'search',
  myList:'my-list',
  searchResults: 'search-results',
};

// community group tab path names
export const communityGroupTabPath = {
  myResources: 'my-resources',
  directory:'directory',
  searchResults: 'search-results',
};

//journal save options
export const journalSaveOptions = {
  save: 1,
  saveAndUpdateRoutine: 2,
  saveAndCreateRoutine: 3,
};

export const autoSuggestionListType = {
 activity : "ACTIVITY",
 medication : "MEDICATION",
 health_data : "HEALTH_DATA",
 symptom : "SYMPTOM",
 prescriber : "PRESCRIBER",
 health_data_uom: "HEALTH_DATA_UOM"
}

export const itemTypes = {
  treatment: 'TREATMENT',
  symptom: 'SYMPTOM',
  health_data: 'HEALTHDATA'
}

export const healthLibraryResourceStatus = {
  PUBLIC: 1,
  PRIVATE: 2,
}
// Globale add menu items
export const addMenuItems = (
  addJournalEntry,
  addTaskQuestion,
  addAttachment,
  addNewContact,
  addMedicationOrTherapy,
  addQuickNote,
) => [
  {
    onPress: addJournalEntry,
    title: 'New Entry',
    icon: require('../assets/nav-icons/note-menu-icon.png'),
  },
  {
    onPress: addTaskQuestion,
    title: 'To-do',
    // title: 'Task & Question',
    icon: require('../assets/nav-icons/conversation-menu-icon.png'),
  },
  {
    onPress: addAttachment,
    title: 'Attachment',
    icon: require('../assets/nav-icons/picture-menu-icon.png'),
  },
  {
    onPress: addNewContact,
    title: 'Contact',
    icon: require('../assets/nav-icons/contact-menu-icon.png'),
  },
  {
    onPress: addMedicationOrTherapy,
    title: 'Medication or Therapy',
    icon: require('../assets/nav-icons/med-menu-icon.png'),
  },
  {
    onPress: addQuickNote,
    title: 'Quick Note',
    icon: require('../assets/nav-icons/quick-note.png'),
  },
];

export const decimalRegex = /^\d*\.?\d*$/;
// subscription plans
export const MONTH = "day";
export const YEAR = "week";

export const freeStorage = "0.5GB";
export const premiumStorage = "1GB";
export const freeStorageByte = 536870912;
export const premiumStorageByte = 1073741824;

export const freeUserMaximumGraphsAllow = 2;
export const freeUserMaximumReportsAllow = 2;

export const InternalUserEmailRegex = "[^@]+@(?:hanalytics|zoeinsights).*\.com$"

// chart types
export const chartTypes = {
  bar: 'BAR', //Medication/Activity default chart type
  line:'LINE', //Symptom default chart type
  dash: 'DASH', //Health data default chart type
};

//current app version (web)
export const currentAppVersion = "5.26.0";
