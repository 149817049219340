import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import DateOfBirthFrame from './DateOfBirthFrame';
import {isLeapYear} from '../../util/formatDate';

// January - 31 days
// February - 28 days in a common year and 29 days in leap years
// March - 31 days
// April - 30 days
// May - 31 days
// June - 30 days
// July - 31 days
// August - 31 days
// September - 30 days
// October - 31 days
// November - 30 days
// December - 31 days

class DateOfBirth extends PureComponent {
  constructor(props) {
    super(props);
    let currentDay = '-1';
    let currentMonth = '-1';
    let currentYear = '-1';
    if (props.profile && props.profile.onlyYear) {
      currentDay = props.selectedDay ? props.selectedDay : '-1';
      currentMonth =  props.selectedMonth ? props.selectedMonth : '-1';
      currentYear = props.dob ? `${moment(props.dob).year()}` : props.selectedYear ? props.selectedYear : '-1';
    } else {
      currentDay = props.dob ? `${moment(props.dob).date()}` : props.selectedDay ? props.selectedDay : '-1';
      currentMonth = props.dob ? `${moment(props.dob).month()}` : props.selectedMonth ? props.selectedMonth : '-1';
      currentYear = props.dob ? `${moment(props.dob).year()}` : props.selectedYear ? props.selectedYear : '-1';
    }
    this.state = {
      currentDay,
      currentMonth,
      currentYear,
    };
    this.props.invalidDate(
      currentDay,
      currentMonth,
      currentYear,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState) this._handleDateChange();
  }

  // returns the DOB in ms
  _handleDateChange() {
    const {currentYear, currentMonth, currentDay} = this.state;

    if (currentYear !== '-1' && currentMonth !== '-1' && currentDay !== '-1') {
      const modDate = moment()
        .year(currentYear)
        .month(parseInt(currentMonth))
        .date(currentDay);
      this.props.onDayChange(modDate);
      this.props.invalidDate(currentDay, currentMonth, currentYear);
    } else {
      this.props.onDayChange(null);
      this.props.invalidDate(currentDay, currentMonth, currentYear);
    }
  }

  _renderDays() {
    const {currentMonth, currentYear} = this.state;
    let maxDate = 31;
    let pickerItems = [{label: '', value: '-1', key: 'dayInit'}];

    switch (currentMonth) {
      //Feb
      case '1':
        if (isLeapYear(currentYear)) maxDate = 29;
        else maxDate = 28;
        break;
      //Apr June Sep Nov
      case '3':
      case '5':
      case '8':
      case '10':
        maxDate = 30;
        break;

      default:
        break;
    }

    if (
      currentYear === `${moment().year()}` &&
      currentMonth === `${moment().month()}`
    ) {
      maxDate = moment().date();
    }

    for (let date = 1; date <= maxDate; date++) {
      pickerItems.push({
        label: `${date}`,
        value: `${date}`,
        key: `day${date}`,
      });
    }

    return pickerItems;
  }

  _renderMonths() {
    const {currentYear} = this.state;
    const months = [
      {label: '', value: '-1', key: 'monthInit'},
      {label: 'Jan', value: '0', key: 'jan'},
      {label: 'Feb', value: '1', key: 'feb'},
      {label: 'Mar', value: '2', key: 'mar'},
      {label: 'Apr', value: '3', key: 'apr'},
      {label: 'May', value: '4', key: 'may'},
      {label: 'Jun', value: '5', key: 'jun'},
      {label: 'Jul', value: '6', key: 'jul'},
      {label: 'Aug', value: '7', key: 'aug'},
      {label: 'Sep', value: '8', key: 'sep'},
      {label: 'Oct', value: '9', key: 'oct'},
      {label: 'Nov', value: '10', key: 'nov'},
      {label: 'Dec', value: '11', key: 'dec'},
    ];
    let pickerItems = [...months];

    if (currentYear === `${moment().year()}`) {
      pickerItems.length = moment().month() + 1;
    }

    return pickerItems;
  }

  _renderYears() {
    let pickerItems = [{label: '', value: '-1', key: 'yearInit'}];

    for (let year = 1920; year <= moment().year(); year++) {
      pickerItems.push({
        label: `${year}`,
        value: `${year}`,
        key: `year${year}`,
      });
    }
    return pickerItems;
  }

  _handleDaySelect(currentDay) {
    this.setState({currentDay});
  }

  _handleMonthSelect(currentMonth) {
    let reset = false;
    const {currentDay, currentYear} = this.state;

    switch (currentMonth) {
      //Feb
      case '1':
        if (
          (!isLeapYear(currentYear) && currentDay === '29') ||
          currentDay === '30' ||
          currentDay === '31'
        )
          reset = true;
        break;
      //Apr June Sep Nov
      case '3':
      case '5':
      case '8':
      case '10':
        if (currentDay === '31') reset = true;
        break;

      default:
        break;
    }

    if (reset) this.setState({currentMonth, currentDay: '-1'});
    else this.setState({currentMonth});
  }

  _handleYearSelect(currentYear) {
    const {currentDay, currentMonth} = this.state;
    if (
      currentMonth === '1' &&
      currentDay === '29' &&
      !isLeapYear(currentYear)
    )
      this.setState({currentYear, currentDay: '-1'});
    else {
      this.setState({currentYear});
    }
  }

  render() {
    const {invalidDay, invalidMonth, invalidYear, fromSetup, notRequiredField} = this.props;
    const {currentDay, currentMonth, currentYear} = this.state;
    return (
      <DateOfBirthFrame
        fromSetup={fromSetup}
        invalidDay={invalidDay}
        invalidMonth={invalidMonth}
        invalidYear={invalidYear}
        currentDay={currentDay}
        currentMonth={currentMonth}
        currentYear={currentYear}
        notRequiredField={notRequiredField}
        _renderDays={this._renderDays()}
        _handleDaySelect={value => this._handleDaySelect(value)}
        _renderMonths={this._renderMonths()}
        _handleMonthSelect={value => this._handleMonthSelect(value)}
        _renderYears={this._renderYears()}
        _handleYearSelect={value => this._handleYearSelect(value)}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.routines.peer2peer,
  };
};
export default connect(
  mapStateToProps,
  null,
)(DateOfBirth);
