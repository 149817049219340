import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity, TextInput} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Header from '../../components/Header/header';
import UIText from '../../components/Text/text';
import {connect} from 'react-redux';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import * as actions from '../../store/actions/index';
import * as validator from '../../validator/validator';
import SuccessBox from '../../components/ConfirmationBox/successBox';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import {COMMUNITY_GROUPS} from '../../navigation/path';
import SimpleConfirmBox from '../../components/ConfirmationBox/simpleConfirmBox';
import MenuOverlayContainer from '../../components/PageLayout/MenuOverlayContainer';
import { pendoTrackEvent } from '../../util/pendoConfig';
import CancelButton from '../../components/Button/cancelButton';

class ShareResource extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
      emailAddresses: [],
      email: '',
      editable: true,
      note: '',
      title: this.props.selectedResource
        ? this.props.selectedResource.title
        : '',
      count: this.props.selectedResource
        ? this.props.selectedResource.count
        : '',
      memberCount: null,
      newPostsToday: null,
      tags: this.props.selectedResource ? this.props.selectedResource.tags : '',
      successModalVisible: false,
      successMessage: '',
      resourceId: this.props.selectedResource
        ? this.props.selectedResource.resourceId
        : '',
      favIcon: this.props.selectedResource
        ? this.props.selectedResource?.favIcon
        : null,
      sendWait: false,
      validEmail: true,
      disabledSend: false,
      visibleConfirm: false
    };
  }

  componentDidMount = () => {
    if (this.props.selectedResource.type !== 'WebResources') {
      this.props.fetchSocialMediaData(
        this.state.resourceId.toString(),
        (sucess, data) => {
          if (sucess) {
            let memberCount = null;
            let newPostsToday = null;
            if (data.memberCount) {
              memberCount = data.memberCount;
            }

            if (data.newPostsToday) {
              newPostsToday = data.newPostsToday;
            }

            this.setState({
              memberCount: memberCount,
              newPostsToday: newPostsToday,
            });
          }
        },
      );
    }
    pendoTrackEvent('HealthLibrary/@ShareResource');
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const {windowHeight, windowWidth} = nextProps;
    this.setState({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      visibleModal: false,
    });
  };

  onChangeEmailText = value => {
    this.setState({email: value});
  };

  onBlur = e => {
    if (validator.validateEmail(this.state.email)) {
      const emailAddresses = [...this.state.emailAddresses];
      emailAddresses.push({email: this.state.email});
      if (emailAddresses.length === 50) {
        this.setState({editable: false});
      }
      this.setState(
        {email: '', emailAddresses: emailAddresses, validEmail: true},
        () => {
          if (this.textInput) {
            this.textInput.focus();
          }
        },
      );
    }
  };

  onKeyPress = e => {
    if (e.nativeEvent.key === 'Enter' || e.nativeEvent.key === ' ') {
      if (validator.validateEmail(this.state.email)) {
        const emailAddresses = [...this.state.emailAddresses];
        emailAddresses.push({
          email: this.state.email,
        });
        setTimeout(() => {
          this.setState(
            {email: '', emailAddresses: emailAddresses, validEmail: true},
            () => {
              if (this.textInput) {
                this.textInput.focus();
              }
            },
          );
        }, 500);
      }
    }
  };

  remove = email => {
    const emailAddresses = [...this.state.emailAddresses];
    this.setState({
      emailAddresses: emailAddresses.filter(res => res.email !== email),
    });
  };

  onChangeNoteText = note => {
    this.setState({
      note: note,
    });
  };

  handleOnSendEmail = () => {
    if (this.state.emailAddresses && this.state.emailAddresses.length > 0) {
      this.setState({validEmail: true});
    } else {
      this.setState({validEmail: false});
      return;
    }
    this.setState({sendWait: true, disabledSend: true});
    let emailBox = [];
    this.state.emailAddresses.forEach(res => {
      emailBox.push(res.email);
    });

    let emailObject = {
      emails: emailBox,
      note: this.state.note,
      resourceId: this.state.resourceId.toString(),
      replyToEmail: this.props.user ? this.props.user.email : '',
    };

    this.props.handleSendEmail(emailObject, data => {
      if (
        data &&
        data.SuccessfullEmails
        // && !data.FailedEmails
      ) {
        this.setState({
          emailAddresses: [],
          email: '',
          editable: true,
          sendWait: false,
          disabledSend: false,
          successModalVisible: true,
          successMessage: 'Successfully sent',
        });
        setTimeout(() => {
          this.setState({successModalVisible: false, successMessage: ''});
          this.props.navigation.push(
            COMMUNITY_GROUPS.path, {
              from: this.props.route.name,
            }
          );
        }, 1500);
      } else {
        this.setState({
          sendWait: false,
          disabledSend: false,
        });
      }
    });
  };

  handleOnCancel = () => {
    this.toggleCancelComfirmBox(false);
    this.props.navigation.push(
      COMMUNITY_GROUPS.path, {
        from: this.props.route.name,
      },
    );
  };

  toggleCancelComfirmBox = visibility => {
    this.setState({visibleConfirm: visibility});
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => this.toggleCancelComfirmBox(true)}
          index={COMMUNITY_GROUPS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        <MenuOverlayContainer
          onPressOpen={() => this.toggleCancelComfirmBox(true)}
        />
        {/* <ScrollView style={styles.scrollViewArea}> */}
        <KeyboardAwareScrollViewHoc>
          <View style={styles.gridSection}>
            <View 
              style={[stylesRes.contentSectionMax, stylesRes.layoutPdFixed]}
              dataSet={{media: ids.contentSectionMax}}>
              <View style={stylesRes.contBotPd} dataSet={{media: ids.contBotPd}}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.colPdLtRt}>
                      <View style={stylesRes.maxComSecText}>
                        <View style={styles.titleArea}>
                          <View
                            style={[
                              styles.titleAreaSub,
                              styles.flexRowJcCtAiCt,
                            ]}>
                            <Image
                              source={require('../../assets/community/community-group.png')}
                              style={styles.communityResourceIcon}
                            />
                            <Text
                              style={[
                                styles.textTitleBold,
                                styles.textAlignCenter,
                              ]}
                              allowFontScaling={false}
                              >
                              Share your resource {'\n'} to help others.
                            </Text>
                          </View>
                        </View>

                        <View
                          style={[styles.comTextSec, styles.infoPdTopBotSm]}>
                          <Text style={styles.textPrimeSm} allowFontScaling={false}>
                            Send an email to friends and family with a link to
                            the resource. None of your personal information is
                            shared.
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View style={styles.secArea}>
                      <View style={styles.colPdLtRt}>
                        <View style={styles.secSubArea}>
                          <View style={styles.fieldRow}>
                            <View
                              style={[
                                styles.fullEmailWrap,
                                styles.emailSendWrapperFull,
                                this.state.validEmail
                                  ? styles.validColor
                                  : styles.inValidColor,
                              ]}
                            >
                              <View
                                style={[
                                  styles.listEmailAll,
                                  styles.listEmailAllHeight,
                                  styles.flexRow,
                                ]}>
                                <View style={styles.listEmailTextSec}>
                                  <Text style={styles.textView} allowFontScaling={false}>
                                    <UIText
                                      style={[
                                        styles.textLabel,
                                        styles.bold,
                                        styles.textColorLight,
                                      ]}
                                      title="Email:"
                                    />
                                  </Text>
                                </View>
                                <View style={styles.listEmailScrollWrap}>
                                  {this.state.emailAddresses.map(
                                    (res, index) => (
                                      <View style={styles.listEmailSingle}>
                                        <View style={styles.listEmailName}>
                                          <Text style={styles.textPrimeSm} allowFontScaling={false}>
                                            {res.email}
                                          </Text>
                                        </View>
                                        <View
                                          style={[
                                            styles.smCloseIconView,
                                            styles.iconLtSp,
                                          ]}>
                                          <TouchableOpacity
                                            onPress={() =>
                                              this.remove(res.email)
                                            }>
                                            <Image
                                              source={require('../../assets/icons/color-select.png')}
                                              style={styles.colorSelectIcon}
                                              resizeMode={'contain'}
                                            />
                                          </TouchableOpacity>
                                        </View>
                                      </View>
                                    ),
                                  )}

                                  <View style={styles.textFieldEmailView}>
                                    <TextInput
                                      style={[
                                        styles.textFieldEmailSm,
                                        {zIndex: 999},
                                      ]}
                                      allowFontScaling={false}
                                      onChangeText={this.onChangeEmailText}
                                      value={this.state.email}
                                      onKeyPress={this.onKeyPress}
                                      onBlur={this.onBlur}
                                      editable={this.state.editable}
                                      autoFocus={true}
                                      underlineColorAndroid="transparent"
                                      ref={input => (this.textInput = input)}
                                    />
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                          <View style={styles.fieldRow}>
                            <TextInput
                              style={[
                                styles.textField,
                                styles.textFieldSm,
                                styles.textAreaMax,
                              ]}
                              allowFontScaling={false}
                              editable={true}
                              multiline={true}
                              numberOfLines={2}
                              onChangeText={this.onChangeNoteText}
                              value={this.state.note}
                              underlineColorAndroid="transparent"
                              placeholder="Type a note here..."
                            />
                          </View>

                          <View style={styles.fieldRow}>
                            <View style={styles.comDetailWrapper}>
                              <View
                                style={[styles.viewRowWrapper, styles.boxFull]}>
                                <View style={styles.ViewRow}>
                                  <View style={styles.viewDetailSec}>
                                    <View style={styles.viewMainData}>
                                      <View
                                        style={[
                                          styles.viewDataFullDesc,
                                          // styles.colPdTopBotRg,
                                          styles.colPdRtTopBotRg,
                                        ]}>
                                        {this.state.favIcon !== null && (
                                        <View style={[styles.communityBgSec, styles.communityView, styles.flexJcFs]}>
                                          <View style={[styles.communityProfileSec, styles.comMgTopSm]}>
                                              <Image
                                                style={
                                                  styles.communityProfileIcon
                                                }
                                                source={{
                                                  uri: this.state.favIcon,
                                                }}
                                              />
                                            </View>
                                          </View>
                                        )}

                                        {this.state.newPostsToday &&
                                          this.state.newPostsToday !==
                                            'UNAVAILABLE' && (
                                            <View
                                              style={[styles.viewItemSmTitle]}>
                                              <Text
                                                style={[
                                                  styles.textPrimeSm,
                                                  styles.textEm,
                                                  styles.textColorGreen,
                                                ]}
                                                allowFontScaling={false}
                                                >
                                                {`${
                                                  this.state.newPostsToday
                                                } new posts today`}
                                              </Text>
                                            </View>
                                          )}
                                        <View style={styles.viewItemData}>
                                          <Text style={styles.textPrimeSmBold} allowFontScaling={false}>
                                            {this.state.title &&
                                              `${this.state.title}`}
                                          </Text>
                                        </View>
                                        <View
                                          style={styles.viewItemDataCmmunity}>
                                          <Text style={styles.textMicSm} allowFontScaling={false}>
                                            {this.state.count &&
                                              `${
                                                this.state.count
                                              } people using this resource.`}
                                          </Text>
                                          {this.state.memberCount &&
                                            this.state.memberCount !==
                                              'UNAVAILABLE' && (
                                              <Text style={styles.textMicSm} allowFontScaling={false}>
                                                {`${
                                                  this.state.memberCount
                                                } total members`}
                                              </Text>
                                            )}
                                        </View>
                                        <View
                                          style={[
                                            styles.viewItemDataTag,
                                            styles.flexRow,
                                          ]}>
                                          <Text style={styles.textExMicSm} allowFontScaling={false}>
                                            Tags:
                                          </Text>
                                          <Text style={styles.textExMicSm} allowFontScaling={false}>
                                            {this.state.tags &&
                                              `${this.state.tags}`}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.btnArea}>
                      <View style={stylesRes.btnViewSec}>
                        <View style={styles.fieldBtn}>
                          <View style={styles.btnBlockFull}>
                            <CancelButton
                               touchableOnpress={() => this.toggleCancelComfirmBox(true)} 
                            />
                          </View>
                        </View>
                        <View style={styles.fieldBtnSp} />
                        <View style={styles.fieldBtn}>
                          <View style={styles.btnBlockFull}>
                            <TouchableOpacity
                              style={styles.submitSm}
                              underlayColor="rgba(0, 153, 168, 0.8)"
                              onPress={this.handleOnSendEmail}
                              disabled={this.state.disabledSend}>
                              <View style={styles.btnSingleView}>
                                <View
                                  style={[
                                    styles.btnSingle,
                                    styles.btnSingleCent,
                                  ]}>
                                  {this.state.sendWait && (
                                    <View style={styles.actIndCenterView}>
                                      <ActivityIndicator size="small" />
                                    </View>
                                  )}
                                  <UIText
                                    style={[
                                      styles.submitBtnTextWhite,
                                      styles.bold]}
                                    title="Send"
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </KeyboardAwareScrollViewHoc>
        {this.state.successModalVisible &&
        <SuccessBox
          visibleModal={this.state.successModalVisible}
          message={this.state.successMessage}
        />
        }
        {this.state.visibleConfirm &&
        <SimpleConfirmBox
          visibleModal={this.state.visibleConfirm}
          onBackdropPress={() => this.toggleCancelComfirmBox(false)}
          onRequestClose={() =>  this.toggleCancelComfirmBox(false)}
          alertBody={'Are you sure?'}
          alertTitle={'Cancel sending an email'}
          handleCancel={() => this.toggleCancelComfirmBox(false)}
          handleConfirm={this.handleOnCancel}
        />
        }
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    showLearnHow: state.uistate.showLearnHow,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    selectedResource: state.communityGroups.selectedResource,
    user: state.routines.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleSendEmail: (emailBox, callBack) => {
      dispatch(actions.handleSendEmail(emailBox, callBack));
    },
    fetchSocialMediaData: (resourceId, callBack) =>
      dispatch(actions.fetchSocialMediaData(resourceId, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShareResource);
