import React, { Component } from "react";
import { Platform, View } from "react-native";
import { connect } from "react-redux";

import { DynamicTable } from "zamplo-common-js";

import { CellHook } from './SurveyTableCellHook';
import {
    setInputValues,
    updateValues,
    setInputValuesToTableData,
    setAnswers,
    extractAnswers,
    setAnswersToTableData,
    sortTableData,
    calculateColumnCount,
    getSubTotalRows,
    getScoreValues,
    restructureTableData
} from './SurveyTableHandler';
import {
    DEFAULT
} from "./SurveyTableConstants";
import { WEB } from "../../../../util/platformWindowConfig";
import QuestionWarning from '../../../../components/QuestionTitle/QuestionWarning';
import Orientation from "react-native-orientation-locker";

class SurveyTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columnCount: 3,
            hideQuestionColumn: false,
            tableData: this.props?.questionItem?.questionTable ? restructureTableData(this.props?.question, this.props.isReadOnly) : [],
            color: '#F8793C',
            values: [],
            answer: [],
            surveyScoringEnabled: false,
            subTotalRows: [],
            scoreValues: {},
            rotated: false,
            unMounted: false
        };

        this.onChangeCellValue = this.onChangeCellValue.bind(this);
        this.onChangeCellFocusOut = this.onChangeCellFocusOut.bind(this);
        this.onselectAnswer = this.onselectAnswer.bind(this);
        this.setTableProperties = this.setTableProperties.bind(this);
    }

    componentDidMount() {
        const { tableData } = this.state;
        const { questionItem } = this.props;

        const questionTableProperties = questionItem?.questionTableProperties ? JSON.parse(questionItem?.questionTableProperties) : {};
        const hideQuestionColumn = Boolean(questionTableProperties?.hideQuestionColumn);

        this.setState({
            values: setInputValues(tableData),
            columnCount: questionItem?.questionTable ? calculateColumnCount(tableData) : 3,
            hideQuestionColumn,
            surveyScoringEnabled: questionItem?.surveyScoringEnabled,
            subTotalRows: questionItem?.surveyScoringEnabled ? getSubTotalRows(questionItem?.questionTable) : [],
            scoreValues: questionItem?.surveyScoringEnabled ? getScoreValues(questionItem?.questionTableProperties) : {},
            rotated: false,
            unMounted: false
        });

        this.props?.navigation?.addListener('blur', () => {
            this.componentWillUnmount();
        });

    }

    componentDidUpdate() {
        const { answer } = this.props;

        if (answer && JSON.stringify(this.state.answer) !== JSON.stringify(answer)) {
            let newTableData = setAnswersToTableData(this.state.tableData, answer);
            this.setState({
                values: setInputValues(newTableData),
                tableData: sortTableData(newTableData),
                answer
            });
        }
    }

    componentWillUnmount() {
        Platform.OS !== WEB && Orientation.unlockAllOrientations();
        this.setState({ unMounted: true });
    }

    onChangeCellValue(event, rowIndex, cellIndex, rowId) {
        const { values } = this.state;
        const updatedValues = updateValues(values, rowId, cellIndex, event);
        this.setState({ values: Object.assign([], updatedValues) });
    }

    onChangeCellFocusOut(cellData = {}, rowIndex, cellIndex, rowId) {
        const { values, tableData } = this.state;
        const updatedValues = setInputValuesToTableData(values, tableData);
        const updatedDataset = setAnswers(updatedValues, rowId, cellIndex, { ...cellData, value: values[rowId][cellIndex] }, values?.[rowId]?.[cellIndex] && values?.[rowId]?.[cellIndex] !== '' ? true : false);
        const answers = extractAnswers(updatedDataset);

        this.setState({ tableData: Object.assign([], updatedDataset) }, () => {
            this.props.onValueChange({ questionTable: Object.assign([], updatedValues), answers });
        });
    }

    onselectAnswer(rowIndex, cellIndex, cellData, select = true, rowId, onselectAnswerType) {
        const { tableData, surveyScoringEnabled, subTotalRows, scoreValues } = this.state;
        const updatedDataset = setAnswers(tableData, rowId, cellIndex, cellData, select, onselectAnswerType, { surveyScoringEnabled, subTotalRows, scoreValues });
        const answers = extractAnswers(updatedDataset);
        this.setState({ tableData: Object.assign([], updatedDataset) });
        this.props.onValueChange({ questionTable: Object.assign([], updatedDataset), answers });
    }

    setTableProperties(values) {
        const { rotated, unMounted } = this.state;
        if (Platform.OS !== WEB && !rotated && !unMounted && values?.columnWidths?.rotate) {
            this.setState({ rotated: true });
            Platform.OS !== WEB && Orientation.lockToLandscapeLeft();
        }
    }

    render() {

        const {
            columnCount,
            hideQuestionColumn,
            tableData,
            values
        } = this.state;

        const {
            tabMenuIconView,
            tabDevice,
            isReadOnly
        } = this.props;

        return (

            <>
            <DynamicTable
                tableData={tableData}
                columnCount={columnCount}
                hideFirstColumn={hideQuestionColumn}
                defaultColumnType={DEFAULT}
                cellHook={CellHook}
                activeBorderColor={"#3461B9"}
                defaultBorderColor={'rgba(64, 64, 65, 0.5)'}
                borderWidth={1}
                contentEditable={false}
                values={values}
                onChangeCellValue={this.onChangeCellValue}
                onCellFocusOut={this.onChangeCellFocusOut}
                onselectAnswer={this.onselectAnswer}
                selectIconUrl={require("../../../../assets/icons/answerTick.png")}
                errorRows={this.props.errors}
                WidthToIgnoreFromScreen={tabDevice && tabMenuIconView ? 100 : tabDevice && !tabMenuIconView ? 300 : 50}
                scrollable={Platform.OS !== WEB ? true : false}
                isReadOnly={isReadOnly}
                    setTableProperties={this.setTableProperties}
                keyprefix={`stq_`}
            />
                {Boolean(this.props.errors && this.props.errors.length) &&
                    <QuestionWarning />
                }
            </>
        );
    }
};
const mapStateToProps = state => {
    return {
        tabMenuIconView: state.uistate.tabMenuIconView,
        tabDevice: state.uistate.tabDevice,
    };
};
export default connect(
    mapStateToProps,
    null
)(SurveyTable);

