import * as paths from './path';
import {DEEP_LINK_URL} from '../store/actions/urls';

const config = {
    screens: {
      // Deep link path start
      //Signup with ZD path
      'signup': {
        path: paths.OTHER.signupPath + '/:accessCode?/:email?/:firstName?/:lastName?',
        stringify: {
          accessCode: (accessCode) => accessCode?.toString().replace(`${accessCode}`, ''),
          email: (email) => email?.replace(`${email}`, ''),
          firstName: (firstName) => firstName?.replace(`${firstName}`, ''),
          lastName: (lastName) => lastName?.replace(`${lastName}`, ''),
        },
      },
      //Caregiver accept paths start
      'login': paths.OTHER.loginPath,
      'accept': {
        path: paths.CAREGIVERS.caregiverAcceptPath + '/:token?/:email?/:navigateWhenReady?',
        stringify: {
          email: (email) => email?.replace(`${email}`, ''),
          navigateWhenReady: (navigateWhenReady) => navigateWhenReady.toString().replace(`${navigateWhenReady}`, ''),
        },
      },
      'caregiver-verfication': {
        path: paths.OTHER.caregiverVerificationPath + '/:caregiverStatus?',
        stringify: {
          caregiverStatus: (caregiverStatus) => caregiverStatus?.replace(`${caregiverStatus}`, ''),
        },
      },
      //Caregiver accept paths end
      'update-routine': {
        path: paths.JOURNALS.updateRoutinePath + '/:token?/:navigateWhenReady?',
        stringify: {
          navigateWhenReady: (navigateWhenReady) => navigateWhenReady.toString().replace(`${navigateWhenReady}`, ''),
        },
      },
      'create-journal': {
        path: paths.JOURNALS.createJournalPath + '/:token?/:reminder?/:navigateWhenReady?',
        stringify: {
          reminder: (reminder) => reminder.toString().replace(`${reminder}`, ''),
          navigateWhenReady: (navigateWhenReady) => navigateWhenReady.toString().replace(`${navigateWhenReady}`, ''),
        },
      },
      'journal-from-email': {
        path: paths.JOURNALS.journalFromEmail + '/:token?/:navigateWhenReady?',
        stringify: {
          navigateWhenReady: (navigateWhenReady) => navigateWhenReady.toString().replace(`${navigateWhenReady}`, ''),
        },
      },
      'survey-questions': {
        path: paths.SURVEYS.surveyQuestionsPath + '/:token?/:navigateWhenReady?',
        stringify: {
          navigateWhenReady: (navigateWhenReady) => navigateWhenReady.toString().replace(`${navigateWhenReady}`, ''),
        },
      },
      'email-verification': paths.OTHER.emailVerificationPath + '/:code?',
      'recover': paths.OTHER.emailReset + '/:token?',
      'manage-devices' : paths.MANAGE_DEVICES.path + '/:deviceName?/:access_token?/:token_type?/:refresh_token?/:device?',
      'joinstudy': {
        path: paths.DASHBOARD.joinStudyPath + '/:accessCode?/:email?/:orgName?/:profile?/:navigateWhenReady?',
        stringify: {
          navigateWhenReady: (navigateWhenReady) => navigateWhenReady.toString().replace(`${navigateWhenReady}`, ''),
        },
      },
      // Deep link path end
      'dashboard': {
        path: paths.DASHBOARD.path + '/:errorDes?/:from?/:navigateWhenReady?',
        stringify: {
          errorDes: (errorDes) => errorDes.replace(`${errorDes}`, ''),
          from: (from) => from.replace(`${from}`, ''),
          navigateWhenReady: (navigateWhenReady) => navigateWhenReady.toString().replace(`${navigateWhenReady}`, ''),
        },
      },
      'profile': {
        path: paths.PROFILE.path + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'caregiver-settings': {
        path: paths.CAREGIVERS.caregiverPath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'profile-premium-account': {
        path: paths.PROFILE.profilePremiumAccountPath + '/:from?/:fromPopup?',
        stringify: {
          fromPopup: (fromPopup) => fromPopup?.replace(`${fromPopup}`, ''),
        }
      },
      'refer-a-friend': paths.REFER_FRIEND.path + '/:from?',
      //To-DO paths start
      'todos': {
        path: paths.TODOS.path + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'add-todo': {
        path: paths.TODOS.addToDoPath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      //To-DO paths end
      //Graph paths start
      'graphs': {
          path: paths.GRAPHS.path + '/:from?',
          stringify: {
            from: (from) => from.replace(`${from}`, ''),
          },
      },
      'add-graph': {
        path: paths.GRAPHS.addGraphPath + '/:entryTypeId?/:from?/:fromPopup?',
        stringify: {
          entryTypeId: (entryTypeId) => entryTypeId?.toString().replace(`${entryTypeId}`, ''),
          from: (from) => from.replace(`${from}`, ''),
          fromPopup: (fromPopup) => fromPopup?.replace(`${fromPopup}`, ''),
        },
      },
      'edit-graph': {
        path: paths.GRAPHS.editGraphPath + '/:entryTypeId?/:from?/:fromPopup?/:graphName?',
        stringify: {
          entryTypeId: (entryTypeId) => entryTypeId?.toString().replace(`${entryTypeId}`, ''),
          from: (from) => from.replace(`${from}`, ''),
          fromPopup: (fromPopup) => fromPopup?.replace(`${fromPopup}`, ''),
        },
      },
      //Graph paths end
      //Community paths start
      'community': {
        path: paths.PEER_TO_PEER.path + '/:from?/:communityTab?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'community-disclaimer': {
        path: paths.PEER_TO_PEER.disclaimerPath + '/:communityTab?',
        stringify: {
          communityTab: (communityTab) => communityTab.replace(`${communityTab}`, ''),
        },
      },
      'latest-connections': {
        path: paths.PEER_TO_PEER.peerResultsByConditionPath + '/:communityTab?',
        stringify: {
          communityTab: (communityTab) => communityTab.replace(`${communityTab}`, ''),
        },
      },
      //Community paths end
      //Routine paths start
      'routines': {
        path: paths.ROUTINES.path + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'add-new-routine': {
        path: paths.ROUTINES.addRoutineTemplatePath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'edit-routine': {
        path: paths.ROUTINES.editRoutineTemplatePath + '/:from?/:reminderId?/:routineName?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
          reminderId: (reminderId) => reminderId?.toString().replace(`${reminderId}`, ''),
        },
      },
      //Routine paths end
      //Symptom paths start
      'symptoms': {
        path: paths.SYMPTOMS.path + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'view-symptom': {
        path: paths.SYMPTOMS.viewSymptomPath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      //Symptom paths end
      //Health data paths start
      'health-data': {
        path: paths.METRICS.path + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'view-health-data': {
        path: paths.METRICS.viewMetricPath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      //Health data paths end
      //Attachment paths start
      'images-documents': {
        path: paths.IMAGES_DOCUMENTS.path + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'add-images-documents': {
        path: paths.IMAGES_DOCUMENTS.addImagesDocumentsPath + '/:state?/:fromSubPath?',
        stringify: {
          state: (state) => JSON.stringify(state).replace(`${JSON.stringify(state)}`, ''),
          fromSubPath: (fromSubPath) => fromSubPath?.toString().replace(`${fromSubPath}`, ''),
        },
      },
      //Attachment paths end
      //HealthJourney paths start
      'health_journey': {
        path: paths.HEALTH_JOURNEY.path + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'add_event': {
        path: paths.HEALTH_JOURNEY.addEventPath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      //HealthJourney paths end
      //Report paths start
      'reports': {
        path: paths.REPORTS.path + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'report-view': {
        path: paths.REPORTS.viewReportPath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'report-new': {
        path: paths.REPORTS.newReportPath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'report-edit': {
        path: paths.REPORTS.editReportPath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'report-add-symptom': {
        path: paths.REPORTS.reportAddSymptomPath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'report-add-issue': {
        path: paths.REPORTS.reportAddIssuePath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'report-add-medications': {
        path: paths.REPORTS.reportAddMedicationsPath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'report-add-image': {
        path: paths.REPORTS.reportAddImagePath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'report-add-graph': {
        path: paths.REPORTS.reportAddGraphPath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      //Report paths end
      'health-library': {
        path: paths.COMMUNITY_GROUPS.path + '/:from?/:healthLibraryTab?/:isFromNotif?/:resourceId?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
          isFromNotif: (isFromNotif) => isFromNotif?.toString().replace(`${isFromNotif}`, ''),
          resourceId: (resourceId) => resourceId?.toString().replace(`${resourceId}`, ''),
        },
      },
      'clinical-trials': paths.CLINICAL_TRIALS.path + '/:clinicalTrialTab?',
      //Survey paths start
      'surveys': {
        path: paths.SURVEYS.path + '/:getPath?',
        stringify: {
          getPath: (getPath) => getPath?.replace(`${getPath}`, ''),
        },
      },
      'main-survey': {
        path: paths.SURVEYS.mainSurveyPath + '/:status?/:from?',
        stringify: {
          status: (status) => status?.toString().replace(`${status}`, ''),
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'survey-question': {
        path: paths.SURVEYS.surveyQuestionPath + '/:from?/:notificationId?/:isReadOnly?/:questionOrder?/:surveyId?/:surveyStatus?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
          notificationId: (notificationId) => notificationId?.toString().replace(`${notificationId}`, ''),
          isReadOnly: (isReadOnly) => isReadOnly?.toString().replace(`${isReadOnly}`, ''),
          questionOrder: (questionOrder) => questionOrder?.toString().replace(`${questionOrder}`, ''),
          surveyId: (surveyId) => surveyId?.toString().replace(`${surveyId}`, ''),
          surveyStatus: (surveyStatus) => surveyStatus?.replace(`${surveyStatus}`, ''),
        },
      },
      //Survey paths end
      //Journal paths start
      'journals-list': {
        path: paths.JOURNALS.path + '/:from?/:fromPopup?/:journalPath?/:journalName?/:fromWindowResize?/:reminderDateTime?/:reminderId?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
          fromPopup: (fromPopup) => fromPopup?.replace(`${fromPopup}`, ''),
          fromWindowResize: (fromWindowResize) => fromWindowResize?.toString()?.replace(`${fromWindowResize}`, ''),
          reminderDateTime: (reminderDateTime) => reminderDateTime?.toString().replace(`${reminderDateTime}`, ''),
          reminderId: (reminderId) => reminderId?.toString().replace(`${reminderId}`, ''),
        },
      },
      'journal-entry/view': {
        path: paths.JOURNALS.viewJournalPath + '/:from?/:fromPopup?/:journalName?/:journalPath?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
          fromPopup: (fromPopup) => fromPopup?.replace(`${fromPopup}`, ''),
          journalPath: (journalPath) => journalPath?.replace(`${journalPath}`, ''),
        },
      },
      'journal-entry/edit' : {
        path: paths.JOURNALS.addJournalPath + '/:from?/:fromPopup?/:journalName?/:fromWindowResize?/:reminderDateTime?/:reminderId?/:journalPath?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
          fromPopup: (fromPopup) => fromPopup?.replace(`${fromPopup}`, ''),
          fromWindowResize: (fromWindowResize) => fromWindowResize?.toString()?.replace(`${fromWindowResize}`, ''),
          reminderDateTime: (reminderDateTime) => reminderDateTime?.toString().replace(`${reminderDateTime}`, ''),
          reminderId: (reminderId) => reminderId?.toString().replace(`${reminderId}`, ''),
          journalPath: (journalPath) => journalPath?.replace(`${journalPath}`, ''),
        },
      },
      //Journal paths end
      //Contact paths start
      'contacts': {
        path: paths.CONTACTS.path + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'view-contact': {
        path: paths.CONTACTS.viewContactPath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        }
      },
      'add-contact': {
        path: paths.CONTACTS.addContactPath + '/:from?/:fromPopup?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
          fromPopup: (fromPopup) => fromPopup?.replace(`${fromPopup}`, ''),
        }
      },
      'edit-contact':  {
        path: paths.CONTACTS.editContactPath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        }
      },
      'contact-takephoto': {
        path: paths.CONTACTS.contactTakePhotoPath + '/:fromPopup?/:type?',
        stringify: {
          fromPopup: (fromPopup) => fromPopup?.replace(`${fromPopup}`, ''),
          type: (type) => type.replace(`${type}`, ''),
        }
      },
      //Contact paths end
      //Medication paths start
      'medications-therapies': {
        path: paths.MEDICATIONS.path + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        },
      },
      'view-medications-therapies': {
        path: paths.MEDICATIONS.viewTherapiesPath + '/:from?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
        }
      },
      'add-therapy': {
        path: paths.MEDICATIONS.addTherapiePath + '/:from?/:fromManualEntry?/:fromPopup?/:globalPath?/:activeTherapyTab?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
          fromManualEntry: (fromManualEntry) => fromManualEntry?.toString().replace(`${fromManualEntry}`, ''),
          fromPopup: (fromPopup) => fromPopup?.replace(`${fromPopup}`, ''),
          globalPath: (globalPath) => globalPath?.replace(`${globalPath}`, ''),
          activeTherapyTab: (activeTherapyTab) => activeTherapyTab?.toString().replace(`${activeTherapyTab}`, ''),
        },
      },
      'edit-therapy': {
        path: paths.MEDICATIONS.editTherapiePath + '/:from?/:fromPopup?',
        stringify: {
          from: (from) => from.replace(`${from}`, ''),
          fromPopup: (fromPopup) => fromPopup?.replace(`${fromPopup}`, ''),
        },
      },
      'scan-prescription': {
        path: paths.MEDICATIONS.scanPrescriptionPath + '/:fromPopup?/:from?/:globalPath?/:activeTherapyTab?',
        stringify: {
          fromPopup: (fromPopup) => fromPopup?.replace(`${fromPopup}`, ''),
          from: (from) => from?.replace(`${from}`, ''),
          globalPath: (globalPath) => globalPath?.replace(`${globalPath}`, ''),
          activeTherapyTab: (activeTherapyTab) => activeTherapyTab?.toString().replace(`${activeTherapyTab}`, '')
        },
      },
      //Medication paths end
    },
};
const linking = {
    prefixes: [DEEP_LINK_URL],
    config: config,
};

export default linking;