import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  deleteWait: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.DELETE_ACCOUNT_START:
      return {
        ...state,
        deleteWait: true
      };
    case actionTypes.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteWait: false
      };
    case actionTypes.DELETE_ACCOUNT_FAIL:
      return {
        ...state,
        deleteWait: false
      };
    default:
      return state;
  }
};
export default reducer;
