import React from 'react';
import {Image, View, Text, TouchableOpacity} from 'react-native';
import styles from '../../../Css';
import Dates from '../../DateTimePicker/Dates';
import InputField from '../../TextInput/inputField';
import * as FieldDetails from '../../TextInput/fieldDetails';
import TextInputPlusMinus from '../../TextInput/TextInputPlusMinus';
import TextPickerFrame from '../../TextInput/textPickerFrame';
import TherapieFrequency from './TherapieFrequency';
import TherapieInstruction from './TherapieInstruction';
import TherapiePrescriber from './TherapiePrescriber';
import Collapsible from '../../Collapsible/Collapsible';
import PrimaryButton from '../../Button/primaryButton';
import PrimaryButtonWhite from '../../Button/primaryButtonWhite';
import Warning from '../../Warning/warning';
import WarningInline from '../../Warning/warningInline';

const therapieRow = props => {
  let detailView = null;
  const {
    name,
    validateName,
    onChangeName,
    identificationNumber,
    unit,
    onUnitValueChange,
    quantity,
    updateQuantity,
    frequency,
    frequencyType,
    updateFrequency,
    updateFrequencyType,
    contentVisibility,
    handleOnPressCollapsible,
    instructions,
    updateInstructions,
    prescribedBy,
    healthProviderName,
    updatePrescribedBy,
    startDate,
    endDate,
    updateStartDate,
    updateEndDate,
    unitsList,
    update,
    saveWait,
    duplicatePopup,
    errorDescription,
    saveDisable,
    onNameValueChange,
    type,
  } = props;
  if (props.visibleDetailView) {
    let isMedicationNameInvalid = type === 'custom' && (name === '' || name === null || name === undefined);
    detailView = (
      <React.Fragment>
        <View
          style={[styles.subInSection, styles.boxBorderLtRt, styles.pdTopSm]}>
          <View style={[styles.fieldRow, styles.zIndexAutoComplete]}>
            <View style={[styles.fieldRowWrapper]}>
              <View style={styles.fieldColStretch}>
                <InputField
                  title={'Name'}
                  value={name}
                  onChangeText={onNameValueChange}
                  fieldLt={true}
                  invalid={isMedicationNameInvalid ? true : false}
                  editable={type === 'custom' ? true : false}
                />
                {isMedicationNameInvalid && (
                  <WarningInline
                    errorDescription={'Name can not be empty.'}
                    textPosition={'right'}
                  />
                )}
              </View>
              <View style={styles.fieldColSpMd} />
              <View style={styles.fieldColStretch}>
                <InputField
                  title={'DIN / NPN'}
                  value={identificationNumber}
                  invalid={!validateName}
                  onChangeText={onChangeName}
                  fieldLt={true}
                  editable={false}
                />
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.subInSection, styles.boxBorderLtRt]}>
          <View style={[styles.fieldRow]}>
            <View style={[styles.fieldRowWrapper]}>
              <View style={styles.fieldColStretch}>
                <TextInputPlusMinus
                  title={'Quantity'}
                  fieldBg={FieldDetails.BGWHITE}
                  fieldAlign={FieldDetails.ALIGNCENTER}
                  fieldLt={true}
                  value={quantity}
                  onChange={updateQuantity}
                />
              </View>
              <View style={styles.fieldColSpMd} />

              <View style={styles.fieldColStretch}>
                <TextPickerFrame
                  title={'Unit'}
                  fieldBg={FieldDetails.BGWHITE}
                  items={unitsList}
                  fieldAlign={FieldDetails.ALIGNLEFT}
                  fieldLt={true}
                  selectedValue={unit}
                  onValueChange={onUnitValueChange}
                />
              </View>
            </View>
            {Boolean(duplicatePopup) && (
              <View style={[styles.comMgTopSm]}>
                <Warning
                  errorDescription={errorDescription}
                  textPosition={'left'}
                />
              </View>
            )}
          </View>
          <TherapieFrequency
            times={frequency}
            periodic={frequencyType}
            updateTime={updateFrequency}
            updatePeriodic={updateFrequencyType}
          />
        </View>
        <Collapsible
          title="More Information"
          contentVisibility={contentVisibility}
          handleOnPressCollapsible={res => handleOnPressCollapsible(res)}
          titleSm={true}
          topBorder={true}
          ltRtBorder={true}>
          <View style={[styles.collapseContent, styles.zIndexAutoComplete]}>
            <View style={styles.boxBorderLtRt}>
              <TherapieInstruction
                instructions={instructions}
                updateInstructions={updateInstructions}
              />
            </View>
            <View style={[styles.subSection, styles.zIndexMinSpec]}>
              <TherapiePrescriber
                prescribedBy={prescribedBy}
                healthProviderName={healthProviderName}
                updatePrescribedBy={updatePrescribedBy}
              />
              <View style={styles.boxBorderLtRt}>
                <Dates
                  startDate={startDate}
                  endDate={endDate}
                  updateStartDate={updateStartDate}
                  updateEndDate={updateEndDate}
                />
              </View>
            </View>
          </View>
        </Collapsible>
        <View style={[styles.boxBorderLtRt, styles.zIndexMin]}>
          <View style={styles.secBtnWrapper}>
            <View style={styles.fieldBtn}>
              <PrimaryButtonWhite
                btnText={'Delete'}
                btnPosition={'full'}
                touchableOnPress={() => {
                  props.handleOnDeleteTreatment(props.index);
                  if (props.showDetail) props.showDetail();
                }}
              />
            </View>
            <View style={styles.fieldBtnSpSm} />
            <View style={styles.fieldBtn}>
              <PrimaryButton
                btnText={'Save'}
                btnPosition={'full'}
                btnStyle={styles.submitBgBlue}
                touchableOnPress={update}
                activityInd={saveWait}
                disabled={saveDisable}
              />
            </View>
          </View>
        </View>
        <View style={[styles.itemViewSpLg, styles.boxBorderBot]} />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {props.visibleDetailView ? (
        <View style={[styles.itemBoxSingle]}>
          <TouchableOpacity onPress={props.showDetail}>
            <View
              style={[
                styles.itemBox,
                styles.flexRow,
                styles.itemBoxTitle,
                styles.openBg,
                styles.flexCom,
              ]}>
              <View
                style={[
                  styles.itemDescBlock,
                  styles.flexRow,
                  styles.flexCom,
                  styles.flexJcSb,
                ]}>
                <View style={[styles.itemDataBlock, styles.flexCom]}>
                  <View style={[styles.itemInSec, styles.flexCom]}>
                    <View style={[styles.listItemText]}>
                      <Text style={[styles.textPrimeExSm]} allowFontScaling={false}>{props.name}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      ) : (
        <View style={[styles.itemBoxSingle, styles.itemViewSpLg]}>
          <View style={[styles.addedItemView, styles.addedItemBox]}>
            <View style={[styles.flexCom, styles.flexRow, styles.flexAiCt]}>
              <View style={[styles.addedItemCont, styles.flexCom]}>
                <TouchableOpacity onPress={props.showDetail}>
                  <View
                    style={[
                      styles.secItemWrapper,
                      styles.flexRow,
                      styles.flexAiCt,
                    ]}>
                    <View style={[styles.listIconView]}>
                      <View style={styles.listIconMed}>
                        <Image
                          style={styles.closeDarkIcon}
                          source={require('../../../assets/icons/lock.png')}
                        />
                      </View>
                    </View>
                    <View style={styles.flexCom}>
                      <Text style={[styles.textPrime]} allowFontScaling={false}>{props.name}</Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
              <View style={[styles.listItemRemove]}>
                <TouchableOpacity
                  onPress={() => {
                    props.handleOnDeleteTreatment(props.index);
                    if (props.showDetail) props.showDetail();
                  }}
                  style={styles.closeTouchPd}>
                  <Image
                    style={styles.closeDarkIcon}
                    source={require('../../../assets/icons/close-dark.png')}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      )}
      {detailView}
    </React.Fragment>
  );
};

export default (therapieRow);
