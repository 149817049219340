import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Condition from './Condition';
import { pendoTrackEvent } from '../../util/pendoConfig';

class Conditions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dob: props.dob,
      userConditions: props.userConditions,
      yearFilter: this.getYearFilters(props.dob),
      conditionWait: false,
    };
    this.props.profileInitConditions(props.userConditions);
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(props) {
    const {dob} = props;
    if (this.state.dob !== dob) {
      this.setState({yearFilter: this.getYearFilters(dob), dob: dob});
    }
  }

  getYearFilters = dob => {
    let filter = [];
    let index = 0;
    filter.push({
      label: '',
      value: null,
      key: index,
    });
    for (
      let i = new Date(dob).getFullYear();
      i <= new Date().getFullYear();
      i++
    ) {
      filter.push({
        label: i.toString(),
        value: i,
        key: index + 1,
      });
      index++;
    }
    return filter;
  };

  addCondition = () => {
    pendoTrackEvent('Profile@ > AddCondition');
    const obj = {
      id: -1,
      name: '',
      conditionId: 0,
      stage: '',
      status: 'DIAGNOSIS',
      year: null,
    };
    const conditions = [...this.props.conditions];
    conditions.push(obj);
    this.setState({userConditions: conditions});
    this.props.profileInitConditions(conditions);
  };

  deleteUseCondition = index => {
    this.setState({conditionWait: true}, () => {
      const userConditions = this.props.conditions;
      userConditions.splice(index, 1);
      this.setState({userConditions: userConditions, conditionWait: false});
      this.props.profileInitConditions(userConditions);
    });
  };

  renderConditions = () => {
    const {conditionValidation} = this.props;
    return this.state.userConditions.map((res, index) =>
      res ? (
        <Condition
          data={res}
          yearFilter={this.state.yearFilter}
          index={index}
          validateName={
            conditionValidation[index] || conditionValidation[index] === 0
              ? true
              : false
          }
          key={'con_' + index}
          deleteUseCondition={this.deleteUseCondition}
          setConditionsToDelete={this.props.setConditionsToDelete}
          initConditions={this.props.initConditions}
          conditionsCount={this.state.userConditions.length}
        />
      ) : null,
    );
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {conditionWait} = this.state;
    return (
      <React.Fragment>
        {!conditionWait && this.renderConditions()}
        <View style={styles.fieldRow}>
          <View style={stylesRes.btnSubCom} dataSet={{media: ids.btnSubCom}}>
            <View
              style={[styles.btnSubView, styles.comMgLtRtSp, styles.pdBotExSm]}>
              <View style={[styles.btnCont, styles.btnWidthCom]}>
                <TouchableOpacity onPress={this.addCondition}>
                  <View
                    style={[
                      styles.submitSub,
                      styles.submitBgOrange,
                      styles.flexRow,
                      styles.flexJcCtAiCt,
                    ]}
                    nativeID={'profileAddCondition'}>
                    <View style={[styles.moewIconSec, styles.rightSpSm]}>
                      <Image
                        style={styles.moreIcon}
                        source={require('../../assets/icons/more-white.png')}
                      />
                    </View>

                    <Text style={[styles.submitText, styles.submitTextSm]} allowFontScaling={false}>
                      Add a condition
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            <View style={stylesRes.descView} dataSet={{media: ids.descView}}>
              <View style={[styles.btnSubView, styles.flexRow]}>
                <View style={stylesRes.btnTextView} dataSet={{media: ids.btnTextView}}>
                  <View style={stylesRes.btnTextSec}>
                    <Text style={styles.textPrimeSm} allowFontScaling={false}>Why we ask</Text>
                  </View>
                  <TouchableOpacity
                    style={[styles.toolTipIconLinkSm, styles.toolTipMg]}
                    onPress={() => this.props.onShowTutorial(10)}>
                    <Image
                      source={require('../../assets/icons/question.png')}
                      style={styles.questionIconSm}
                      resizeMode={'contain'}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    userConditions: state.routines.userConditions,
    conditions: state.profile.conditions,
    conditionValidation: state.profile.conditionValidation,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    profileInitConditions: conditions =>
      dispatch(actions.profileInitConditions(conditions, {})),
    onShowTutorial: tutorialType =>
      dispatch(actions.showTutorial(tutorialType)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Conditions);
