import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  id: null,
  graphName: null,
  startDate: null,
  endDate: null,
  symptoms: {},
  metrics: {},
  therapies: {},
  dashboardGraph: true,
  previewGraph: false,
  symptomProps: {},
  metricProps: {},
  therapyProps: {},
  entryTypeId: null,
  initHashValue: '',
  temporyClors: {
    symptoms: {},
    metrics: {},
    therapies: {},
  }
};

const updateGraphName = (state, action) => {
  return updateObject(state, { graphName: action.graphName });
};

const updateStartDate = (state, action) => {
  return updateObject(state, { startDate: action.startDate });
};

const updateEndDate = (state, action) => {
  return updateObject(state, { endDate: action.endDate });
};

const addSymptom = (state, action) => {
  const symptoms = { ...state.symptoms };
  symptoms[action.symptom] = action.symptom;
  return updateObject(state, { symptoms: symptoms });
};

const removeSymptom = (state, action) => {
  const symptoms = { ...state.symptoms };
  const symptomProps = { ...state.symptomProps };
  delete symptoms[action.symptom];
  delete symptomProps[action.symptom];
  return updateObject(state, { symptoms: symptoms, symptomProps: symptomProps});
};

const addMetric = (state, action) => {
  const metrics = { ...state.metrics };
  metrics[action.metric] = action.metric;
  return updateObject(state, { metrics: metrics });
};

const removeMetric = (state, action) => {
  const metrics = { ...state.metrics };
  const metricProps = { ...state.metricProps };
  delete metrics[action.metric];
  delete metricProps[action.metric];
  return updateObject(state, { metrics: metrics, metricProps: metricProps });
};

const addTherapy = (state, action) => {
  const therapies = { ...state.therapies };
  therapies[action.therapy] = action.therapy;
  return updateObject(state, { therapies: therapies });
};

const removeTherapy = (state, action) => {
  const therapies = { ...state.therapies };
  const therapyProps = { ...state.therapyProps };
  delete therapies[action.therapy];
  delete therapyProps[action.therapy];
  return updateObject(state, { therapies: therapies, therapyProps: therapyProps });
};

const selectRollingGraph = (state, action) => {
  return updateObject(state, {
    id: action.id,
    graphName: action.graphName,
    symptoms: action.symptoms,
    metrics: action.metrics,
    therapies: action.therapies,
    startDate: action.startDate,
    endDate: action.endDate,
    symptomProps: action.symptomProps,
    metricProps: action.metricProps,
    therapyProps: action.therapyProps,
    entryTypeId: action.entryTypeId
  });
};

const resetRollingGraph = (state, action) => {
  return updateObject(state, {
    id: null,
    graphName: null,
    symptoms: {},
    metrics: {},
    therapies: {},
    startDate: null,
    endDate: null,
    symptomProps: {},
    metricProps: {},
    therapyProps: {},
    entryTypeId: null,
    initHashValue: ''
  });
};

const previewGraph = state => {
  return updateObject(state, {
    previewGraph: !state.previewGraph
  });
};

const hideShowDashboardGraph = (state, action) => {
  return updateObject(state, {
    dashboardGraph: action.dashboardGraph
  });
};

const updateSymptomProps = (state, action) => {
  const symptomProps = { ...state.symptomProps };
  symptomProps[action.symptomId] = action.symptomProps;
  return updateObject(state, { symptomProps: symptomProps });
};

const updateMetricProps = (state, action) => {
  const metricProps = { ...state.metricProps };
  metricProps[action.metricId] = action.metricProps;
  return updateObject(state, { metricProps: metricProps });
};

const updateTherapyProps = (state, action) => {
  const therapyProps = { ...state.therapyProps };
  therapyProps[action.therapyId] = action.therapyProps;
  return updateObject(state, { therapyProps: therapyProps });
};

const setGraphInitHashValue = (state, action) => {
  return updateObject(state, { 
    graph: state.savedGraph,
    initHashValue: action.initHashValue 
  });
};

const updateTempClrs = (state, action) => {
  const temporyClors = { ...state.temporyClors };

  if (action && action.values && action.values.type === "therapies") {
    temporyClors.therapies[action.values.id] = action.values?.displayProperties && action.values?.displayProperties?.color ? action.values.displayProperties.color : null;

  } else if (action && action.values && action.values.type === "symptoms") {
    temporyClors.symptoms[action.values.id] = action.values?.displayProperties && action.values?.displayProperties?.color ? action.values.displayProperties.color : null;
  } else if (action && action.values && action.values.type === "metric") {
    temporyClors.metrics[action.values.id] = action.values?.displayProperties && action.values?.displayProperties?.color ? action.values.displayProperties.color : null;
  }
  return updateObject(state, {
    temporyClors,
  });
}
const clearTempClrs = (state, action) => {
  return updateObject(state, {
    temporyClors: {
      symptoms: {},
      metrics: {},
      therapies: {},
    },
  });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_GRAPH_NAME:
      return updateGraphName(state, action);
    case actionTypes.UPDATE_GRAPH_START_DATE:
      return updateStartDate(state, action);
    case actionTypes.UPDATE_GRAPH_END_DATE:
      return updateEndDate(state, action);
    case actionTypes.ROLLING_GRAPH_ADD_SYMPTOM:
      return addSymptom(state, action);
    case actionTypes.ROLLING_GRAPH_REMOVE_SYMPTOM:
      return removeSymptom(state, action);
    case actionTypes.ROLLING_GRAPH_ADD_METRIC:
      return addMetric(state, action);
    case actionTypes.ROLLING_GRAPH_REMOVE_METRIC:
      return removeMetric(state, action);
    case actionTypes.ROLLING_GRAPH_ADD_THERAPY:
      return addTherapy(state, action);
    case actionTypes.ROLLING_GRAPH_REMOVE_THERAPY:
      return removeTherapy(state, action);
    case actionTypes.SELECT_ROLLING_GRAPH:
      return selectRollingGraph(state, action);
    case actionTypes.RESET_ROLLING_GRAPH:
      return resetRollingGraph(state, action);
    case actionTypes.HIDESHOWGRAPH:
      return hideShowDashboardGraph(state, action);
    case actionTypes.PREVIEW_GRAPH:
      return previewGraph(state, action);
    case actionTypes.UPDATE_SYMPTOM_PROPS:
      return updateSymptomProps(state, action);
    case actionTypes.UPDATE_METRIC_PROPS:
      return updateMetricProps(state, action);
    case actionTypes.UPDATE_THERAPY_PROPS:
      return updateTherapyProps(state, action);
    case actionTypes.SET_GRAPH_INIT_HASH_VALUE:
      return setGraphInitHashValue(state, action);
    case actionTypes.UPDATE_TEMP_CLRS:
      return updateTempClrs(state, action);
    case actionTypes.CLEAR_TEMP_CLRS:
      return clearTempClrs(state, action)
    default:
      return state;
  }
};
export default reducer;
