import React, {Component} from 'react';
import {View, ScrollView, Text, Image, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import {connect} from 'react-redux';
import stylesResponsive from '../../CssResponsive';
import Header from '../../components/Header/header';
import UIText from '../../components/Text/text';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import * as axiosCommon from '../../axios/axios-common';
import {
  SUBSCRIPTION_CANCEL,
  SUBSCRIPTION_PLANS,
} from '../../store/actions/urls';
import * as actions from '../../store/actions/index';
import SubscriptionCancelConfirmBox from '../../components/ConfirmationBox/subscriptionCancelConfirmBox';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import {DASHBOARD, PROFILE} from '../../navigation/path';
import {MONTH, YEAR} from '../../Constant';
import PageContainer from '../../components/PageLayout/PageContainer';
import PremiumContent from '../../components/Premium/premiumContent';
import {
  getCurrentSubscription,
  getSubscriptionMadeBy,
  getSubscriptionType,
  getNextBillingDate,
} from '../../util/subscriptionInformation';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { TAB_BREAk_POINT_START } from '../../util/platformWindowConfig';

class CancelSubscriptionScreen extends Component {
  constructor(props) {
    super(props);
    if (props.journalUserId) {
      props.navigation.navigate(DASHBOARD.path);
    }
    this.getSubscriptionPlans();
    this.state = {
      firstName: (props.user || {}).firstName,
      lastName: (props.user || {}).lastName,
      subscriptionPlans: {},
      selectedPlane: this.getCurrentPlane(),
      visibleModal: false,
      wait: false,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      currentSubscription: getCurrentSubscription(props.userSubscription),
    };
  }

  componentDidMount() {
    this.getSubscriptionPlans();
    this.setState({
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
    });
    pendoTrackEvent('Premium/@BillingCancel');
  }

  getSubscriptionPlans = () => {
    axiosCommon.axiosGET(SUBSCRIPTION_PLANS, res => {
      if (res.type === 'SUCCESS') {
        const obj = {};
        res.responseDto.data.plans.forEach(item => {
          obj[item.interval] = item;
        });
        this.setState({subscriptionPlans: obj});
      } else if (res.type === 'ERROR' && res.errorDto === 'SESSION_TIME_OUT') {
        console.log("SESSION_TIME_OUT, logging out; CancelSubscriptionScreen::getSubscriptionPlans");
        this.props.logout();
      } else {
        this.props.commonErrorHandler(res.errorDto);
      }
    });
  };

  getCurrentPlane = () => {
    const userSubscription = this.props.userSubscription.filter(
      item => !item.cancel,
    );

    if (!userSubscription[0]) {
      this.props.navigation.replace(PROFILE.profilePremiumAccountPath);
    } else {
      return userSubscription[0].subscriptionType === 'MONTH' ? MONTH : YEAR;
    }
  };

  handleCancelSubscription = () => {
    this.setState({visibleModal: true});
    pendoTrackEvent('Premium@ > Cancel');
  };

  handleCancel = () => {
    this.setState({visibleModal: false});
  };

  handleYes = () => {
    this.setState({wait: true});

    let requestUrl = SUBSCRIPTION_CANCEL;

    if (this.props.journalUserId) {
      requestUrl += '?journalUserId=' + this.props.journalUserId;
    }

    axiosCommon.axiosPUT(requestUrl, data => {
      console.log(data);
      if (data.type === 'SUCCESS') {
        this.setState({wait: false, visibleModal: false});
        this.props.resetDataLogout();
        this.props.navigation.navigate(PROFILE.path);
      } else if (
        data.type === 'ERROR' &&
        data.errorDto === 'SESSION_TIME_OUT'
      ) {
        console.log("SESSION_TIME_OUT, logging out; CancelSubscriptionScreen::handleYes");
        this.props.logout();
      } else {
        this.props.commonErrorHandler(data.errorDto.data.error);
        this.setState({wait: false, visibleModal: false});
      }
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {windowHeight, windowWidth} = nextProps;
    this.setState({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      loading: true,
    });
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    let fullWidth = this.state.windowWidth;
    let btnStyle = styles.btnRowMgRg;
    if (fullWidth > TAB_BREAk_POINT_START) {
      btnStyle = styles.btnRowMgLg;
    }
    const subscriptionMadeByDes = getSubscriptionMadeBy(
      this.state.currentSubscription,
    );

    const subscriptionType = getSubscriptionType(
      this.state.currentSubscription,
    );
    const nextBillingDate = getNextBillingDate(this.state.currentSubscription);

    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          filterClickHandler={this.handleOnPressFilter}
          clearFiltersHandler={this.handleOnPressClearFilters}
          handleOnPressBackButton={() => {
            if(this.props.navigation.canGoBack()) {
              this.props.navigation.goBack();
            } else {
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: PROFILE.path }],
              });
            }
          }}
          index={PROFILE.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        {Object.values(this.state.subscriptionPlans).length > 0 ? (
          <ScrollView style={styles.scrollViewArea}>
            <View style={styles.gridSection}>
              <View
                style={[
                  stylesRes.contentSection,
                  stylesRes.botExPd,
                  stylesRes.layoutTopPdFixed,
                ]}
                dataSet={{media: ids.botExPd}}>
                <PageContainer>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={styles.secArea}>
                        <View style={stylesRes.maxComSec} dataSet={{media: ids.maxComSec}}>
                          <View
                            style={[
                              styles.comSecRow,
                              styles.flexRow,
                              styles.flexAiCt,
                            ]}>
                            <View
                              style={[styles.comColHalf, styles.flexJcFeAiFe]}>
                              <View style={stylesRes.comImgSecPrem}>
                                <Image
                                  style={[styles.imgCover]}
                                  source={require('../../assets/premium/premium_smilingstars.png')}
                                />
                              </View>
                            </View>
                            <View style={styles.comColHalf}>
                              <View
                                style={[
                                  styles.comTextSec,
                                  styles.comPdLt,
                                  styles.spSmTopBot,
                                ]}>
                                <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                                  {this.state.firstName} {this.state.lastName}!
                                </Text>
                                <Text style={[styles.textPrime]} allowFontScaling={false}>
                                  You can do so much more with the premium
                                  version.
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </PageContainer>

                <PageContainer smScreenFull={true}>
                  <View style={[styles.gridRow, styles.postRelative]}>
                    <View style={styles.gridColFull}>
                      <View style={styles.secArea}>
                        <View
                          style={[styles.viewBannerSec, styles.postRelative]}>
                          <View
                            style={[
                              styles.premBannerBg,
                              styles.bgPremHeight,
                              styles.bgPlanHolderHeight,
                            ]}>
                            <Image
                              style={[styles.premBg]}
                              source={require('../../assets/premium/prem-bg.png')}
                            />
                          </View>
                          <View
                            style={[
                              styles.premBannerContent,
                              styles.bgPremHeight,
                              // styles.flexRowJcCtAiCt
                            ]}>
                            <View style={[styles.premTitleBlock]}>
                              <Text
                                style={[
                                  styles.textPrime,
                                  styles.textAlignCenter,
                                ]}
                                allowFontScaling={false}
                                >
                                {/* Do more with premium */}
                              </Text>
                            </View>

                            <View style={styles.premTextArea}>
                              <View style={stylesRes.premBoxFullView}>
                                <View style={stylesRes.premBoxView}>
                                  <View style={stylesRes.premBoxSingle}>
                                    <View
                                      style={
                                        this.state.selectedPlane === MONTH
                                          ? [
                                              styles.premBoxData,
                                              styles.premShadow,
                                              styles.premSelected,
                                            ]
                                          : [
                                              styles.premBoxData,
                                              styles.premShadow,
                                            ]
                                      }>
                                      <View style={[styles.preDataNo]}>
                                        <View style={[styles.preSignView]}>
                                          <View style={[styles.preDataSign]}>
                                            {/* if selected add textDark else add textLight*/}
                                            <Text
                                              style={
                                                this.state.selectedPlane ===
                                                MONTH
                                                  ? [
                                                      styles.textNoExSm,
                                                      styles.textDark,
                                                    ]
                                                  : [
                                                      styles.textNoExSm,
                                                      styles.textLight,
                                                    ]
                                              }
                                              allowFontScaling={false}
                                              >
                                              C$
                                            </Text>
                                          </View>
                                        </View>
                                        <View style={[styles.preDataNoLg]}>
                                          {/* if selected add textDark else add textLight*/}
                                          <Text
                                            style={
                                              this.state.selectedPlane === MONTH
                                                ? [
                                                    styles.textNoLg,
                                                    styles.textAlignCenter,
                                                  ]
                                                : [
                                                    styles.textNoLg,
                                                    styles.textAlignCenter,
                                                    styles.textLight,
                                                  ]
                                            }
                                            allowFontScaling={false}
                                            >
                                            {this.state.subscriptionPlans[MONTH]
                                              .amount / 100}
                                          </Text>
                                        </View>
                                      </View>
                                      <View
                                        style={[
                                          styles.preDataLine,
                                          styles.borderThin,
                                        ]}
                                      />
                                      <View style={[styles.preDataText]}>
                                        <Text
                                          style={[
                                            styles.textPrimeSm,
                                            styles.textAlignCenter,
                                          ]}
                                          allowFontScaling={false}
                                          >
                                          Monthly subscription
                                        </Text>
                                        <Text
                                          style={[
                                            styles.textPrimeSm,
                                            styles.textAlignCenter,
                                          ]}
                                          allowFontScaling={false}
                                          >
                                          (C$
                                          {(this.state.subscriptionPlans[MONTH]
                                            .amount /
                                            100) *
                                            12}{' '}
                                          annually)
                                        </Text>
                                      </View>
                                    </View>
                                    {/* </TouchableOpacity> */}
                                  </View>
                                </View>
                                <View style={stylesRes.premBoxOrViewWrapper}>
                                  <View style={stylesRes.premBoxOrView}>
                                    <View style={[styles.orIconSec]}>
                                      <Image
                                        style={[styles.orIcon]}
                                        source={require('../../assets/icons/or-bg.png')}
                                      />
                                    </View>

                                    <View style={[styles.premOrText]}>
                                      <Text
                                        style={[
                                          styles.textPrime,
                                          styles.textWhite,
                                          styles.textAlignCenter,
                                          styles.textEm,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        or
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                                <View style={stylesRes.premBoxView}>
                                  <View style={stylesRes.premBoxSingle}>
                                    {/* <TouchableOpacity style={[styles.maxView]}> */}
                                    {/* //Add styles.premSelected for selection */}
                                    <View
                                      style={
                                        this.state.selectedPlane === YEAR
                                          ? [
                                              styles.premBoxData,
                                              styles.premShadow,
                                              styles.premSelected,
                                            ]
                                          : [
                                              styles.premBoxData,
                                              styles.premShadow,
                                            ]
                                      }>
                                      <View style={[styles.preDataNo]}>
                                        <View style={[styles.preSignView]}>
                                          <View style={[styles.preDataSign]}>
                                            <Text
                                              style={
                                                this.state.selectedPlane ===
                                                YEAR
                                                  ? [
                                                      styles.textNoExSm,
                                                      styles.textDark,
                                                    ]
                                                  : [
                                                      styles.textNoExSm,
                                                      styles.textLight,
                                                    ]
                                              }
                                              allowFontScaling={false}
                                              >
                                              C$
                                            </Text>
                                          </View>
                                        </View>

                                        <View style={[styles.preDataNoLg]}>
                                          <Text
                                            style={
                                              this.state.selectedPlane === YEAR
                                                ? [
                                                    styles.textNoLg,
                                                    styles.textAlignCenter,
                                                    styles.textDark,
                                                  ]
                                                : [
                                                    styles.textNoLg,
                                                    styles.textAlignCenter,
                                                    styles.textLight,
                                                  ]
                                            }
                                            allowFontScaling={false}
                                            >
                                            {this.state.subscriptionPlans[YEAR]
                                              .amount / 100}
                                          </Text>
                                        </View>
                                      </View>
                                      <View
                                        style={[
                                          styles.preDataLine,
                                          styles.borderThin,
                                        ]}
                                      />
                                      <View style={[styles.preDataText]}>
                                        <Text
                                          style={[
                                            styles.textPrimeSm,
                                            styles.textAlignCenter,
                                          ]}
                                          allowFontScaling={false}
                                          >
                                          Annual subscription
                                        </Text>
                                      </View>
                                    </View>
                                    {/* </TouchableOpacity> */}
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </PageContainer>

                <PageContainer>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={[styles.secArea, styles.contPdTop]}>
                        <View style={styles.flexJcCtAiCt}>
                          <View style={styles.subscriptionInfoView}>
                            <Text style={styles.textPrimeSm} allowFontScaling={false}>
                              {subscriptionMadeByDes} | {subscriptionType}
                            </Text>
                          </View>
                          <View style={styles.subscriptionDateView}>
                            <Text style={[styles.textPrimeSm, styles.textGrey]} allowFontScaling={false}>
                              Next billing date: {nextBillingDate}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </PageContainer>

                <PageContainer>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={[styles.btnArea, btnStyle]}>
                        <View style={[styles.btnBlock, styles.noMgTop]}>
                          <TouchableOpacity
                            style={[styles.submit, styles.submitBgOrange]}
                            underlayColor="rgba(0, 153, 168, 0.8)"
                            onPress={this.handleCancelSubscription}
                            nativeID={'premiumCancel'}>
                            <View style={styles.btnSingleView}>
                              <View
                                style={[
                                  styles.btnSingle,
                                  styles.btnSingleCent,
                                ]}>
                                {/* <View style={styles.actIndLg}>
                                    <ActivityIndicator size="small" />
                                  </View> */}
                                <UIText
                                  style={styles.submitText}
                                  title="Cancel Subscription"
                                />
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </PageContainer>

                <PageContainer>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={styles.secArea}>
                        <View style={stylesRes.premiumDetailSec}>
                          <PremiumContent fullWidth={fullWidth} />
                        </View>
                      </View>
                    </View>
                  </View>
                </PageContainer>

                <PageContainer>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={[styles.btnArea, btnStyle]}>
                        <View style={[styles.btnBlock, styles.noMgTop]}>
                          <TouchableOpacity
                            style={[styles.submit, styles.submitBgOrange]}
                            underlayColor="rgba(0, 153, 168, 0.8)"
                            onPress={this.handleCancelSubscription}
                            nativeID={'premiumCancel'}>
                            <View style={styles.btnSingleView}>
                              <View
                                style={[
                                  styles.btnSingle,
                                  styles.btnSingleCent,
                                ]}>
                                {/* <View style={styles.actIndLg}>
                                    <ActivityIndicator size="small" />
                                  </View> */}
                                <UIText
                                  style={styles.submitText}
                                  title="Cancel Subscription"
                                />
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </PageContainer>
              </View>
            </View>
          </ScrollView>
        ) : (
          <ActivityIndicator />
        )}
        <SubscriptionCancelConfirmBox
          visibleModal={this.state.visibleModal}
          handleCancel={this.handleCancel}
          handleYes={this.handleYes}
          wait={this.state.wait}
        />
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.routines.user,
    userSubscription: state.routines.userSubscription,
    journalUserId: state.uistate.journalUserId,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    resetDataLogout: () => dispatch(actions.resetDataLogout()),
    logout: () => dispatch(actions.logout()),
    commonErrorHandler: errorDescription =>
      dispatch(actions.commonErrorHandler(errorDescription)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CancelSubscriptionScreen);
