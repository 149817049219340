export const postedUserName = (userName) => {
  let generatedName = '';
  if (userName) {
    const nameSplitted = userName.split(" ");
    if (nameSplitted[0]) {
      generatedName = nameSplitted[0];
    }
    if (nameSplitted.length > 1 && nameSplitted[nameSplitted.length - 1]) {
      generatedName = `${generatedName} ${nameSplitted[nameSplitted.length - 1].charAt(0)}.`;
    }
  }

  return generatedName;
};