import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import styles from "../../../../Css";
import UIText from "../../../Text/text";
import UITextInput from "../../../TextInput/textInput";
import stylesResponsive from "../../../../CssResponsive";
import AutoComplete from "../../../AutoComplete/AutoComplete";
import ActivityIndicator from "../../../ActivityIndicator/activityIndicator";
import Picker from "../../../Picker/picker";
import KeyboardAwareScrollViewHoc from "../../../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc";
import PageContainer from "../../../../components/PageLayout/PageContainer";
import { getScrollHeight } from '../../../../util/commonUiLogic';

const studyStatus = [
  { label: "Not Yet Recruiting", value: "0" },
  { label: "Recruiting ", value: "1" },
  { label: "Enrolling by invitation", value: "2" },
  { label: "Active, not recruiting", value: "3" },
  { label: "Suspended", value: "4" },
  { label: "Terminated", value: "5" },
  { label: "Completed", value: "6" },
  { label: "Withdrawn", value: "7" },
  { label: "Unknown status", value: "8" },
];

const phase = [
  { label: "1", value: "4" },
  { label: "2", value: "1" },
  { label: "3", value: "2" },
  { label: "4", value: "3" },
  { label: "Not applicable", value: "5" },
];

const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
const clinicalTrialSearch = (props) => {
  const {hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth} = props;
  let saveSearchfiltterd = [];
  let saveSearchList = [{ label: "", value: "", key: 0 }];
  if (props.saveSearchList) {
    saveSearchfiltterd = props.saveSearchList.filter((item) => item.title);
    saveSearchfiltterd.map((item, index) =>
      saveSearchList.push({ label: item.title, value: item.id, key: index + 1 })
    );
  }
  
  let tabHeight = 44;
  let scrollHeight = getScrollHeight(hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth, tabHeight);
  return (
    <View style={styles.gridRow}>
      <View style={styles.gridColFull}>
        <View
          style={[stylesRes.contentSearchForm, { maxHeight: scrollHeight }]}
        >
          <KeyboardAwareScrollViewHoc extraScrollHeight={90}>
            <PageContainer>
              <View 
                style={[stylesRes.tabContentFullArea, stylesRes.botPd]}
                dataSet={{media: ids.botPd}}>
                <View style={styles.titleArea}>
                  <View style={stylesRes.descMaxComLg}>
                    <View
                      style={[
                        styles.mainSmTitleBlockMd,
                        styles.flexRowJcCtAiCt,
                      ]}
                    >
                      <UIText
                        title="Get matched with Clinical Trials in your area"
                        style={[styles.textPrimeBold, styles.textAlignCenter]}
                      />
                    </View>
                  </View>
                </View>

                <View style={styles.secArea}>
                  <View
                    style={[
                      styles.subSection,
                      //styles.colPdTopLtRt
                      styles.colPdTopCom,
                    ]}
                  >
                    <View style={[styles.fieldRow]}>
                      <View style={[styles.pickerSelectionWrapper]}>
                        <Text style={[styles.textView, styles.textViewPicker]} allowFontScaling={false}>
                          <UIText
                            style={[
                              styles.textLabel,
                              styles.bold,
                              styles.textColorLight,
                            ]}
                            title="Saved Search"
                          />
                        </Text>
                        <View
                          style={[
                            stylesRes.dropArrowView,
                            stylesRes.dropArrowTopLg,
                          ]}
                          pointerEvents={"none"}
                        >
                          <View style={stylesRes.dropArrowIcon}>
                            <Image
                              style={[styles.imgContain]}
                              source={require("../../../../assets/icons/dropdown-arrow.png")}
                            />
                          </View>
                        </View>
                        <Picker
                          items={saveSearchList}
                          selectedValue={
                            props.savedSearchValue ? props.savedSearchValue : ""
                          }
                          style={[
                            styles.pickerAreaField,
                            styles.selectionPicker,
                          ]}
                          onValueChange={props.onSelectedValueChangeSavedSearch}
                        />
                      </View>
                    </View>
                    <View style={[styles.fieldRow, styles.zIndexAutoComplete]}>
                      {/* <Text style={[styles.textView]}>
                            <UIText
                              style={[
                                styles.textLabel,
                                styles.bold,
                                styles.textColorLight
                              ]}
                              title="Medical Condition"
                            />
                          </Text> */}
                      {/* 
                          <AutoSuggest
                            otherTextInputProps={{ editable: true }}
                            onChangeTextDebounce={0}
                            onChangeText={props.onChangeTextCondition}
                            onItemPress={props.onItemPress}
                            terms={props.termsArray}
                            currentInput={props.condition}
                            placeholder=""
                            textInputStyles={{
                              paddingTop: 30
                            }}
                            autoFocus={
                              !props.condition || props.condition === ""
                            }
                            remortSearch={true}
                            url="/clinicaltrial/conditions?cond="
                            processData={props.processData}
                          /> */}

                      <AutoComplete
                        textFieldWithTitle={true}
                        textFieldTitle="Medical Condition"
                        remortSearch={true}
                        url="/clinicaltrial/conditions?cond="
                        processData={props.processData}
                        triggerLength={1}
                        onChangeText={props.onChangeTextCondition}
                        onItemPress={props.onItemPress}
                        currentInput={props.condition}
                        // autoFocus={
                        //   !props.condition || props.condition === ""
                        // }
                        onPressCustom={props.onItemPressCustom}
                        customSectionTitle={"Search Custom Medical Condition"}
                        showCustomSection={true}
                      />
                    </View>

                    <View
                      style={[styles.fieldRow, styles.zIndexMin]}
                      nativeID="clinicalTrails"
                    >
                      <View style={[styles.pickerSelectionWrapper]}>
                        <Text style={[styles.textView, styles.textViewPicker]} allowFontScaling={false}>
                          <UIText
                            style={[
                              styles.textLabel,
                              styles.bold,
                              styles.textColorLight,
                            ]}
                            title="Country "
                          />
                        </Text>
                        <View
                          style={[
                            stylesRes.dropArrowView,
                            stylesRes.dropArrowTopLg,
                          ]}
                          pointerEvents={"none"}
                        >
                          <View style={stylesRes.dropArrowIcon}>
                            <Image
                              style={[styles.imgContain]}
                              source={require("../../../../assets/icons/dropdown-arrow.png")}
                            />
                          </View>
                        </View>
                        <Picker
                          items={[
                            { label: "", value: "" },
                            { label: "Afghanistan", value: "AF" },
                            { label: "Albania", value: "AL" },
                            { label: "Algeria", value: "DZ" },
                            { label: "American Samoa", value: "AS" },
                            { label: "Andorra", value: "AD" },
                            { label: "Angola", value: "AO" },
                            { label: "Argentina", value: "AR" },
                            { label: "Armenia", value: "AM" },
                            { label: "Aruba", value: "AW" },
                            { label: "Australia", value: "AU" },
                            { label: "Austria", value: "AT" },
                            { label: "Azerbaijan", value: "AZ" },
                            { label: "Bahamas", value: "BS" },
                            { label: "Bahrain", value: "BH" },
                            { label: "Bangladesh", value: "BD" },
                            { label: "Barbados", value: "BB" },
                            { label: "Belarus", value: "BY" },
                            { label: "Belgium", value: "BE" },
                            { label: "Belize", value: "BZ" },
                            { label: "Benin", value: "BJ" },
                            { label: "Bermuda", value: "BM" },
                            { label: "Bhutan", value: "BT" },
                            { label: "Bolivia", value: "BO" },
                            {
                              label: "Bosnia and Herzegovina",
                              value: "BA",
                            },
                            { label: "Botswana", value: "BW" },
                            { label: "Brazil", value: "BR" },
                            { label: "Brunei Darussalam", value: "BN" },
                            { label: "Bulgaria", value: "BG" },
                            { label: "Burkina Faso", value: "BF" },
                            { label: "Burundi", value: "BI" },
                            { label: "Cambodia", value: "KH" },
                            { label: "Cameroon", value: "CM" },
                            { label: "Canada", value: "CA" },
                            { label: "Cayman Islands", value: "KY" },
                            {
                              label: "Central African Republic",
                              value: "CF",
                            },
                            { label: "Chad", value: "TD" },
                            { label: "Chile", value: "CL" },
                            { label: "China", value: "CN" },
                            { label: "Colombia", value: "CO" },
                            { label: "Comoros", value: "KM" },
                            { label: "Congo", value: "CG" },
                            {
                              label: "Congo, The Democratic Republic of the",
                              value: "CD",
                            },
                            { label: "Costa Rica", value: "CR" },
                            { label: "Croatia", value: "HR" },
                            { label: "Cuba", value: "CU" },
                            { label: "Cyprus", value: "CY" },
                            { label: "Czech Republic", value: "CZ" },
                            { label: "C&#244;te D'Ivoire", value: "CI" },
                            { label: "Denmark", value: "DK" },
                            { label: "Djibouti", value: "DJ" },
                            { label: "Dominican Republic", value: "DO" },
                            { label: "Ecuador", value: "EC" },
                            { label: "Egypt", value: "EG" },
                            { label: "El Salvador", value: "SV" },
                            { label: "Equatorial Guinea", value: "GQ" },
                            { label: "Estonia", value: "EE" },
                            { label: "Ethiopia", value: "ET" },
                            { label: "Faroe Islands", value: "FO" },
                            { label: "Fiji", value: "FJ" },
                            { label: "Finland", value: "FI" },
                            {
                              label: "Former Serbia and Montenegro",
                              value: "CS",
                            },
                            { label: "Former Yugoslavia", value: "YU" },
                            { label: "France", value: "FR" },
                            { label: "French Guiana", value: "GF" },
                            { label: "French Polynesia", value: "PF" },
                            { label: "Gabon", value: "GA" },
                            { label: "Gambia", value: "GM" },
                            { label: "Georgia", value: "GE" },
                            { label: "Germany", value: "DE" },
                            { label: "Ghana", value: "GH" },
                            { label: "Gibraltar", value: "GI" },
                            { label: "Greece", value: "GR" },
                            { label: "Greenland", value: "GL" },
                            { label: "Grenada", value: "GD" },
                            { label: "Guadeloupe", value: "GP" },
                            { label: "Guam", value: "GU" },
                            { label: "Guatemala", value: "GT" },
                            { label: "Guinea", value: "GN" },
                            { label: "Guinea-Bissau", value: "GW" },
                            { label: "Guyana", value: "GY" },
                            { label: "Haiti", value: "HT" },
                            {
                              label: "Holy See (Vatican City State)",
                              value: "VA",
                            },
                            { label: "Honduras", value: "HN" },
                            { label: "Hong Kong", value: "HK" },
                            { label: "Hungary", value: "HU" },
                            { label: "Iceland", value: "IS" },
                            { label: "India", value: "IN" },
                            { label: "Indonesia", value: "ID" },
                            {
                              label: "Iran, Islamic Republic of",
                              value: "IR",
                            },
                            { label: "Iraq", value: "IQ" },
                            { label: "Ireland", value: "IE" },
                            { label: "Israel", value: "IL" },
                            { label: "Italy", value: "IT" },
                            { label: "Jamaica", value: "JM" },
                            { label: "Japan", value: "JP" },
                            { label: "Jersey", value: "JE" },
                            { label: "Jordan", value: "JO" },
                            { label: "Kazakhstan", value: "KZ" },
                            { label: "Kenya", value: "KE" },
                            {
                              label: "Korea, Democratic People's Republic of",
                              value: "KP",
                            },
                            { label: "Korea, Republic of", value: "KR" },
                            { label: "Kuwait", value: "KW" },
                            { label: "Kyrgyzstan", value: "KG" },
                            {
                              label: "Lao People's Democratic Republic",
                              value: "LA",
                            },
                            { label: "Latvia", value: "LV" },
                            { label: "Lebanon", value: "LB" },
                            { label: "Lesotho", value: "LS" },
                            { label: "Liberia", value: "LR" },
                            {
                              label: "Libyan Arab Jamahiriya",
                              value: "LY",
                            },
                            { label: "Liechtenstein", value: "LI" },
                            { label: "Lithuania", value: "LT" },
                            { label: "Luxembourg", value: "LU" },
                            {
                              label:
                                "Macedonia, The Former Yugoslav Republic of",
                              value: "MK",
                            },
                            { label: "Madagascar", value: "MG" },
                            { label: "Malawi", value: "MW" },
                            { label: "Malaysia", value: "MY" },
                            { label: "Maldives", value: "MV" },
                            { label: "Mali", value: "ML" },
                            { label: "Malta", value: "MT" },
                            { label: "Martinique", value: "MQ" },
                            { label: "Mauritania", value: "MR" },
                            { label: "Mauritius", value: "MU" },
                            { label: "Mayotte", value: "YT" },
                            { label: "Mexico", value: "MX" },
                            {
                              label: "Moldova, Republic of",
                              value: "MD",
                            },
                            { label: "Monaco", value: "MC" },
                            { label: "Mongolia", value: "MN" },
                            { label: "Montenegro", value: "ME" },
                            { label: "Montserrat", value: "MS" },
                            { label: "Morocco", value: "MA" },
                            { label: "Mozambique", value: "MZ" },
                            { label: "Myanmar", value: "MM" },
                            { label: "Namibia", value: "NA" },
                            { label: "Nepal", value: "NP" },
                            { label: "Netherlands", value: "NL" },
                            {
                              label: "Netherlands Antilles",
                              value: "AN",
                            },
                            { label: "New Caledonia", value: "NC" },
                            { label: "New Zealand", value: "NZ" },
                            { label: "Nicaragua", value: "NI" },
                            { label: "Niger", value: "NE" },
                            { label: "Nigeria", value: "NG" },
                            {
                              label: "Northern Mariana Islands",
                              value: "MP",
                            },
                            { label: "Norway", value: "NO" },
                            { label: "Oman", value: "OM" },
                            { label: "Pakistan", value: "PK" },
                            { label: "Panama", value: "PA" },
                            { label: "Papua New Guinea", value: "PG" },
                            { label: "Paraguay", value: "PY" },
                            { label: "Peru", value: "PE" },
                            { label: "Philippines", value: "PH" },
                            { label: "Poland", value: "PL" },
                            { label: "Portugal", value: "PT" },
                            { label: "Puerto Rico", value: "PR" },
                            { label: "Qatar", value: "QA" },
                            { label: "Romania", value: "RO" },
                            { label: "Russian Federation", value: "RU" },
                            { label: "Rwanda", value: "RW" },
                            { label: "R&#233;union", value: "RE" },
                            {
                              label: "Saint Kitts and Nevis",
                              value: "KN",
                            },
                            { label: "Saint Lucia", value: "LC" },
                            { label: "Samoa", value: "WS" },
                            { label: "Saudi Arabia", value: "SA" },
                            { label: "Senegal", value: "SN" },
                            { label: "Serbia", value: "RS" },
                            { label: "Seychelles", value: "SC" },
                            { label: "Sierra Leone", value: "SL" },
                            { label: "Singapore", value: "SG" },
                            { label: "Slovakia", value: "SK" },
                            { label: "Slovenia", value: "SI" },
                            { label: "Solomon Islands", value: "SB" },
                            { label: "Somalia", value: "SO" },
                            { label: "South Africa", value: "ZA" },
                            { label: "Spain", value: "ES" },
                            { label: "Sri Lanka", value: "LK" },
                            { label: "Sudan", value: "SD" },
                            { label: "Suriname", value: "SR" },
                            { label: "Swaziland", value: "SZ" },
                            { label: "Sweden", value: "SE" },
                            { label: "Switzerland", value: "CH" },
                            {
                              label: "Syrian Arab Republic",
                              value: "SY",
                            },
                            { label: "Taiwan", value: "TW" },
                            { label: "Tajikistan", value: "TJ" },
                            { label: "Tanzania", value: "TZ" },
                            { label: "Thailand", value: "TH" },
                            { label: "Togo", value: "TG" },
                            { label: "Trinidad and Tobago", value: "TT" },
                            { label: "Tunisia", value: "TN" },
                            { label: "Turkey", value: "TR" },
                            { label: "Uganda", value: "UG" },
                            { label: "Ukraine", value: "UA" },
                            {
                              label: "United Arab Emirates",
                              value: "AE",
                            },
                            { label: "United Kingdom", value: "GB" },
                            { label: "United States", value: "US" },
                            { label: "Uruguay", value: "UY" },
                            { label: "Uzbekistan", value: "UZ" },
                            { label: "Vanuatu", value: "VU" },
                            { label: "Venezuela", value: "VE" },
                            { label: "Vietnam", value: "VN" },
                            {
                              label: "Virgin Islands (U.S.)",
                              value: "VI",
                            },
                            { label: "Yemen", value: "YE" },
                            { label: "Zambia", value: "ZM" },
                            { label: "Zimbabwe", value: "ZW" },
                          ]}
                          selectedValue={props.country}
                          style={[
                            styles.pickerAreaField,
                            styles.selectionPicker,
                          ]}
                          onValueChange={(itemValue) =>
                            props.onValueChangeCountry(itemValue)
                          }
                        />
                      </View>
                    </View>

                    <View style={[styles.fieldRow, styles.zIndexMin]}>
                      <Text style={[styles.textView]} allowFontScaling={false}>
                        <UIText
                          style={[
                            styles.textLabel,
                            styles.bold,
                            styles.textColorLight,
                          ]}
                          title="City "
                        />
                      </Text>

                      <UITextInput
                        style={styles.textField}
                        onChangeText={props.onChangeTextCity}
                        value={props.city}
                      />
                    </View>

                    <View style={[styles.fieldRow, styles.fieldRowNoMg]}>
                      <View style={[styles.pickerSelectionWrapper]}>
                        <Text style={[styles.textView, styles.textViewPicker]} allowFontScaling={false}>
                          <UIText
                            style={[
                              styles.textLabel,
                              styles.bold,
                              styles.textColorLight,
                            ]}
                            title="Distance"
                          />
                        </Text>
                        <View
                          style={[
                            stylesRes.dropArrowView,
                            stylesRes.dropArrowTopLg,
                          ]}
                          pointerEvents={"none"}
                        >
                          <View style={stylesRes.dropArrowIcon}>
                            <Image
                              style={[styles.imgContain]}
                              source={require("../../../../assets/icons/dropdown-arrow.png")}
                            />
                          </View>
                        </View>
                        <Picker
                          items={[
                            { label: "", value: "" },
                            { label: "50miles", value: "50" },
                            { label: "100miles", value: "100" },
                            { label: "200miles", value: "200" },
                            { label: "300miles", value: "300" },
                          ]}
                          selectedValue={props.distance}
                          style={[
                            styles.pickerAreaField,
                            styles.selectionPicker,
                          ]}
                          onValueChange={(itemValue) =>
                            props.onValueChangeDistance(itemValue)
                          }
                        />
                      </View>
                    </View>
                    <View style={[styles.fieldRow, styles.fieldRowNoMg]}>
                      <View style={[styles.radioSecFul]}>
                        <View
                          style={[styles.radioRowView, styles.radioRowTopSp]}
                        >
                          <View style={stylesRes.radioBtnContent} dataSet={{media: ids.radioBtnContent}}>
                            <Text style={[styles.textPrime]} allowFontScaling={false}>Study Status</Text>
                          </View>
                          <View style={stylesRes.radioBtnList}>
                            {studyStatus.map((res) => (
                              <View
                                style={[
                                  styles.answerSingle,
                                  styles.flexWrapView,
                                ]}
                              >
                                <TouchableOpacity
                                  style={[
                                    styles.answerOptMg,
                                    styles.answerViewWrapper,
                                  ]}
                                  onPress={() =>
                                    props.onPressStudyStatus(res.value)
                                  }
                                >
                                  <View style={[styles.answerView]}>
                                    <Image
                                      style={[
                                        styles.radioIcon,
                                        styles.radioOptSp,
                                      ]}
                                      source={
                                        props.studyStatus.find(
                                          (element) => res.value === element
                                        )
                                          ? require("../../../../assets/icons/option-selected.png")
                                          : require("../../../../assets/icons/radio-default.png")
                                      }
                                    />
                                    <Text style={[styles.textPrime]} allowFontScaling={false}>
                                      {res.label}
                                    </Text>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            ))}
                          </View>
                        </View>
                        <View style={styles.hrLineLg} />

                        <View
                          style={[styles.radioBtnWrapper, styles.radioRowBotSp]}
                        >
                          <View style={stylesRes.radioBtnContent} dataSet={{media: ids.radioBtnContent}}>
                            <Text style={[styles.textPrime]} allowFontScaling={false}>
                              Phase of study
                            </Text>
                          </View>
                          <View style={stylesRes.radioBtnListSingle}>
                            {phase.map((res) => (
                              <View style={[styles.answerSingleSm]}>
                                <TouchableOpacity
                                  style={[
                                    styles.answerOptMgBot,
                                    styles.answerViewWrapper,
                                  ]}
                                  onPress={() => props.onPressPhase(res.value)}
                                >
                                  <View style={[styles.answerView]}>
                                    <Image
                                      style={[
                                        styles.radioIcon,
                                        styles.radioOptSp,
                                      ]}
                                      source={
                                        props.phase.find(
                                          (element) =>
                                            res.value ===
                                            (element === "0" ? "4" : element)
                                        )
                                          ? require("../../../../assets/icons/option-selected.png")
                                          : require("../../../../assets/icons/radio-default.png")
                                      }
                                    />
                                    <Text style={[styles.textPrime]} allowFontScaling={false}>
                                      {res.label}
                                    </Text>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            ))}
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={[styles.fieldRow]}>
                      <View style={[styles.pickerSelectionWrapper]}>
                        <Text style={[styles.textView, styles.textViewPicker]} allowFontScaling={false}>
                          <UIText
                            style={[
                              styles.textLabel,
                              styles.bold,
                              styles.textColorLight,
                            ]}
                            title="Study Type"
                          />
                        </Text>
                        <View
                          style={[
                            stylesRes.dropArrowView,
                            stylesRes.dropArrowTopLg,
                          ]}
                          pointerEvents={"none"}
                        >
                          <View style={stylesRes.dropArrowIcon}>
                            <Image
                              style={[styles.imgContain]}
                              source={require("../../../../assets/icons/dropdown-arrow.png")}
                            />
                          </View>
                        </View>
                        <Picker
                          items={[
                            { label: "All Studies", value: "" },
                            {
                              label: "Interventional Studies (Clinical Trials)",
                              value: "Intr",
                            },
                            {
                              label: "Observational Studies",
                              value: "Obsr",
                            },
                            {
                              label: "Expanded Access Studies",
                              value: "Expn",
                            },
                            {
                              label: "-- Patient Registries",
                              value: "PReg",
                            },
                          ]}
                          selectedValue={props.studyType}
                          style={[
                            styles.pickerAreaField,
                            styles.selectionPicker,
                          ]}
                          onValueChange={(itemValue) =>
                            props.onValueChangeStudyType(itemValue)
                          }
                        />
                      </View>
                    </View>
                    <View style={[styles.fieldRow]}>
                      <Text style={[styles.textView]} allowFontScaling={false}>
                        <UIText
                          style={[
                            styles.textLabel,
                            styles.bold,
                            styles.textColorLight,
                          ]}
                          title="Age Group"
                        />
                      </Text>

                      <UITextInput
                        style={styles.textField}
                        onChangeText={props.onChangeTextAge}
                        value={props.age}
                      />
                    </View>

                    <View style={[styles.fieldRow]}>
                      <View style={[styles.pickerSelectionWrapper]}>
                        <Text style={[styles.textView, styles.textViewPicker]} allowFontScaling={false}>
                          <UIText
                            style={[
                              styles.textLabel,
                              styles.bold,
                              styles.textColorLight,
                            ]}
                            title="Sex"
                          />
                        </Text>
                        <View
                          style={[
                            stylesRes.dropArrowView,
                            stylesRes.dropArrowTopLg,
                          ]}
                          pointerEvents={"none"}
                        >
                          <View style={stylesRes.dropArrowIcon}>
                            <Image
                              style={[styles.imgContain]}
                              source={require("../../../../assets/icons/dropdown-arrow.png")}
                            />
                          </View>
                        </View>
                        <Picker
                          items={[
                            { label: "All", value: "" },
                            {
                              label: "Studies with Female Participants  ",
                              value: "Female",
                            },
                            {
                              label: "Studies with Male Participants  ",
                              value: "Male",
                            },
                          ]}
                          selectedValue={props.gender}
                          style={[
                            styles.pickerAreaField,
                            styles.selectionPicker,
                          ]}
                          onValueChange={(itemValue) =>
                            props.onValueChangeGender(itemValue)
                          }
                        />
                      </View>
                    </View>
                    <View style={styles.btnBlock}>
                      <TouchableOpacity
                        onPress={props.onSearch}
                        disabled={props.searchWait}
                      >
                        <View 
                          style={[styles.submit, styles.btnSingleCent]} 
                          nativeID={'clinicalTrialsSearch'}>
                          {props.searchWait && (
                            <View style={styles.actIndCenterLg}>
                              <ActivityIndicator size="small" />
                            </View>
                          )}
                          <Text style={styles.submitText} allowFontScaling={false}>Search</Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View style={stylesRes.botHeightSm} dataSet={{media: ids.botHeightSm}} />
                </View>
              </View>
            </PageContainer>
          </KeyboardAwareScrollViewHoc>
        </View>
      </View>
    </View>
  );
};

export default (clinicalTrialSearch);
