import React, {useState, useRef} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  TextInput,
  Animated,
} from 'react-native';
import {findThumbnail} from '../../../util/commonUiLogic';
import styles from '../../../Css';

const AttachmentRenameBox = props => {
  const width = useRef(new Animated.Value(42)).current;
  const opacity = useRef(new Animated.Value(1)).current;
  const {name, index, onChangeFileName, onRemoveFile, extension, onSetFocusValue, focus, activeBubbleId, setActiveBubble} = props;
  const [fileName, setFileName] = useState(name);
  const [focused, setFocused] = useState(false);

  const onBlur = () => {
    if (!fileName) return;
    setTimeout(() => {
      Animated.timing(width, {
        toValue: 42,
        duration: 300,
        useNativeDriver: false,
      }).start(() => {
        onChangeFileName(fileName, index);
        setFocused(false);
        onSetFocusValue(false);
      })
    }, 100);
  };

  const removeFile = () => {
    Animated.sequence([
      Animated.timing(opacity, {
        toValue: 0,
        duration: 100,
        useNativeDriver: false,
      }),
      Animated.timing(width, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      })
    ]).start(() => {
      onRemoveFile(index);
      onSetFocusValue(false);
    });
  };

  const onFocusText = () => {
    if (activeBubbleId) setActiveBubble(null);
    Animated.timing(width, {
      toValue: 150,
      duration: 300,
      useNativeDriver: false,
    }).start(() => {
      setFocused(true);
      onSetFocusValue(true);      
    });
  }

  return (
    <View style={[styles.flexRow, styles.flexAiCt, styles.lblSp, styles.comSpExSm]}>
      <TouchableOpacity onPress={onFocusText} disabled={focus}>
      {/* Text Input Box */}
      <Animated.View
        key={index}
        style={[
          styles.flexRow,
          styles.flexJcCt,
          styles.flexAiCt,
          styles.mgLtFive,
          // styles.oneSixtyWd,
          styles.addAttachmentWrapper,
          styles.noMgRtLtTpBt,
          styles.pdRtTwelve,
          styles.pdLtTwelve,
          styles.iconLtSp,
          focused && styles.brdEditColorLuminous,
          focused && styles.wdEditAttachContentRes
        ]}>
        <Image
          style={styles.listViewIcon}
          source={findThumbnail(`a.${extension}`)}
          resizeMode={'contain'}
        />
        <View style={styles.tenGap} />
        <Animated.View style={{width, opacity, overflow: "hidden"}}>
          {focused ? (
            <TextInput
              // style={[styles.editableBoder]}
              style={[styles.wdHundredFifty]}
              value={fileName}
              onBlur={onBlur}
              allowFontScaling={false}
              onChangeText={setFileName}
              autoFocus
            />
          ) : (
            <Text
              numberOfLines={1}
              style={[styles.textPrime, styles.wdFourtyTwo, styles.noMgImg]}>
              {fileName}
            </Text>
          )}
        </Animated.View>
        <View style={[styles.mgLtThree, {width: 34}]}>
        <Text style={[styles.textPrime, styles.textGreyDark]}>.{extension}</Text>
        </View>
      </Animated.View>
      </TouchableOpacity>

      {/* close Icon */}
      {Boolean(focused) && (
        <TouchableOpacity onPress={() => {
          activeBubbleId && setActiveBubble(null);
          removeFile();
        }}>
          <View style={{padding: 10}}>
            <Image
              style={{width:15, height:15}}
              source={require('../../../assets/health-journey-icons/health-journey-close.png')}
            />
          </View>
        </TouchableOpacity>
      )}
    </View>

  );
};

export default AttachmentRenameBox;
