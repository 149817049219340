import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import Modal from "../Modal/modal";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import ModalLayout from "../../components/ModalLayout/modalLayout";

const JournalEmptyPopup = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const bulletPointItem = (item) => {
    return (
      <View style={[styles.flexRow, styles.upIconSec]}>
        {/* <Text style={[styles.textPrime, styles.bulletOrangeText]} allowFontScaling={false}>
          <Text style={[styles.textQuest]} allowFontScaling={false}>{'\u2022'}</Text>
            {item}
        </Text> */}
        <Text style={[styles.textPrimeMd, styles.bulletOrangeText, styles.textQuest]} allowFontScaling={false}>{'\u2022'}</Text>
        <Text style={[styles.textPrimeMd, styles.bulletOrangeText]} allowFontScaling={false}>{item}</Text>
      </View> 
    );
  }
  return (
    <Modal
      visible={props.visibleModal}
      onRequestClose={props.onRequestClose}
      onBackdropPress={props.onBackdropPress}
    >
      <ModalLayout
        contPosition={"center"}
        popupAuto={true}
        showClose={true}
        modalWidthMin={true}
        hidePopup={props.onRequestClose}
        closeIcnType={"SPECIAL"}
      >
        <View style={styles.popupContentWrapper}>
          {/* main title */}
          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              <View style={[styles.errorMessage, styles.colorBoxLeft, styles.mgTopLg]}>
                <Text style={[styles.textTitleSmBold, styles.textAlignLeft, {position:'absolute', bottom:15}]} allowFontScaling={false}>
                  Fields Missing Data Entry
                </Text>
              </View>
            </View>
          </View>

          {/* items section */}
          <View style={styles.gridRow}>
            <View style={[styles.gridColFull, styles.wrapperPdLt]}>
              {/* sub topic */}
              <View style={styles.secBgPdTop}>
                <Text style={[styles.textPrimeMdBold]} allowFontScaling={false}>
                  You have left the following field(s) blank:
                </Text>
              </View>
              {/* bullet points */}
              <View style={styles.pdTopSm}>
                {Boolean(props.dosagesNames) && props.dosagesNames.length > 0 && (
                  props.dosagesNames.map((item) => bulletPointItem(item))
                )}  
                {Boolean(props.symptomNames) && props.symptomNames.length > 0 && (
                  props.symptomNames.map((item) => bulletPointItem(item))
                )} 
                {Boolean(props.metricsNames) && props.metricsNames.length > 0 && (
                  props.metricsNames.map((item) => bulletPointItem(item))
                )}       
              </View>

              {/* inform sentence */}
              <View style={[styles.flexJcCt, styles.flexAiCt, styles.centrTxtAlgn, styles.contPdTop, styles.informSentWidth]}>
                <Text style={[styles.textPrime, styles.centrTxtAlgn]} allowFontScaling={false}>Do you wish to continue and save without this data?</Text>
              </View>
            </View>
          </View>
          
          {/* button */}
          <View style={[styles.gridRow, styles.itemViewSp]}>
            <View style={styles.gridColFull}>
              <View style={styles.btnArea}>
                <View style={[styles.btnArea, styles.comMgTopMd, styles.comMgTopLg]}>
                  <View
                    style={[stylesRes.btnViewSec, stylesRes.btnViewSecComNoPd]}
                    dataSet={{media: ids.btnViewSec}}
                  >
                    <View style={styles.fieldBtn}>
                      <View style={styles.btnBlockFull}>
                        <TouchableOpacity
                          style={[
                            styles.deleteResourceDeleteBtn,
                            styles.journalBtns,
                          ]}
                          underlayColor="rgba(0, 153, 168, 0.8)"
                          onPress={props.handleCancel}
                        >
                          <View style={styles.btnSingleView}>
                            <View
                              style={[styles.btnSingle, styles.btnSingleCent]}
                            >
                              <Text style={[styles.textTitleBold, styles.textColorGreyLight]} allowFontScaling={false}>
                                Cancel
                              </Text>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                    
                    <View style={styles.fieldBtnSpSm} />

                    <View style={styles.fieldBtn}>
                      <View style={styles.btnBlockFull}>
                        <TouchableOpacity
                          style={[
                            styles.saveSubmitBtnClr,
                            styles.journalBtns
                          ]}
                          underlayColor="rgba(0, 153, 168, 0.8)"
                          onPress={props.handleConfirm}
                        >
                          <View style={styles.btnSingleView}>
                            <View
                              style={[styles.btnSingle, styles.btnSingleCent]}
                            >
                              {props.confirmWait && (
                                <View
                                  style={[
                                    styles.indicatorIconView,
                                    styles.indicatorLtMiniLg,
                                  ]}
                                >
                                  <ActivityIndicator
                                    pdZero={true}
                                    size="small"
                                  />
                                </View>
                              )}
                              <Text style={[styles.textTitleBold, styles.textWhite]} allowFontScaling={false}>
                                Save
                              </Text>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ModalLayout>
    </Modal>
  );
};
export default (JournalEmptyPopup);
