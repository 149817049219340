import _ from 'lodash';
import {
    HEADER,
    SELECT_TYPE_RADIO,
    SELECT_TYPE_DEFAULT,
    TOTAL_ROW,
    SUB_TOTAL_ROW,
    QUESTION_ROW,
    SINGLE_SELECTION,
    COLUMN_TEMPLATE
} from "./SurveyTableConstants";

const findTotalValues = (refid , summaryData) => {
    
    const totalSection = summaryData.find(r => r && r.refId === refid);
    if(!totalSection) return 0;

    if(totalSection.score ) return totalSection.score;

    //This was the previous implemantation 

    // const selectedAnswerId = totalSection.selectedAnswerId;
    // const tableScores = totalSection.tableScores;

    // const score = tableScores.find(s => s && s.answerId ===  selectedAnswerId)?.score || 0;
   
    return 0;
}

export const sortTableData = (tableData) => {
    const sortedData = tableData.sort((a, b) => a.rowId - b.rowId);

    let i = 0;
    for (let rowData of sortedData) {
        sortedData[i].tableColumns = rowData.tableColumns.sort((a, b) => a.columnId - b.columnId);
        i++;
    }

    return sortedData;
}

export const setInputValues = (dataset) => {
    const values = [];
    for (let rowData of dataset) {
        values[rowData.rowId] = rowData.tableColumns.map(i => i && i.value);
    }
    return values;
}

export const updateValues = (values = [], rowId, columnIndex, value) => {
    if (values?.[rowId]?.[columnIndex]) {
        values[rowId][columnIndex] = value;
        return values;
    }

    for (let i = 1; i < rowId + 1; i++) {
        if (rowId === i) {

            if (!values[i]) {
                values[i] = [];
            }

            if (!values[i][columnIndex]) {
                for (let j = 0; j < columnIndex + 1; j++) {

                    if (j === columnIndex) {
                        values[i][j] = value;
                    } else if (!values[i][j]) {
                        values[i][j] = ""
                    }
                }
            } else if (values[i][columnIndex]) {
                values[i][columnIndex] = value;
            }



        } else if (!values[i]) {
            values[i] = [];
        }
    }

    return values;
}

export const setInputValuesToTableData = (values, dataset) => {

    for (let rowData of dataset) {
        const columnValues = values[rowData.rowId] || [];
        rowData.values = columnValues;

        let i = 0;
        for (let val of columnValues) {
            if (rowData.tableColumns?.[i]) rowData.tableColumns[i].value = val;
            i++;
        }



    }
    return dataset;
}

export const setScoresToAnswers = (rowId, cellIndex, scoringProperties) => {

    if (!scoringProperties?.surveyScoringEnabled || !scoringProperties?.subTotalRows?.length || !scoringProperties?.scoreValues?.length) return {};

    const subTotalRows = scoringProperties.subTotalRows;
    const scoreValues = scoringProperties.scoreValues;

    const selectedScoreValue = scoreValues.find(c => c && c.columnId === cellIndex + 1)?.score;
    const selectedSubTotalRefId = subTotalRows.find(r => r && r.rowId > rowId)?.subTotalRefId;


    return { scoreValue: selectedScoreValue, subTotalRefId: selectedSubTotalRefId }

}

export const setAnswers = (tableData, rowId, cellIndex, cellData, select, onselectAnswer = SELECT_TYPE_DEFAULT, scoringProperties = {}) => {

    const rowIndex = tableData.findIndex(a => a && a.rowId === rowId);
    const headerValue = [true];

    if (onselectAnswer === SELECT_TYPE_RADIO && select) {
        const headerRowIds = tableData.filter(a => a && a.rowType === HEADER).map(i => i && i.rowId);
        if (headerRowIds.length) {
            const nearestHeaderRowId = headerRowIds.sort((a, b) => b - a).find(i => i < rowId);
            const headerObj = tableData.find(i => i && i.rowId === nearestHeaderRowId);
            headerValue[0] = headerObj?.values?.[cellIndex] || true;
        }
    }


    if (select) {
        const rowData = tableData[rowIndex];
        const { createdOn, modifiedOn, answerSelectionType, ...rest } = { ...cellData };

        tableData[rowIndex].answers = [{ ...rest, selected: true, idOfRow: rowData.id, rowId: rowData.rowId, rowIndex, cellIndex, answerSelectionType: onselectAnswer, ...setScoresToAnswers(rowId, cellIndex, scoringProperties), ...(onselectAnswer === SELECT_TYPE_RADIO ? { value: headerValue[0] } : []) }];

        const previousSelectedIndex = tableData[rowIndex].tableColumns.findIndex(a => a && a.selected);

        if (previousSelectedIndex > 0) tableData[rowIndex].tableColumns[previousSelectedIndex].selected = false;
        if (tableData?.[rowIndex]?.tableColumns?.[cellIndex]) {
            tableData[rowIndex].tableColumns[cellIndex].selected = true;

            if (answerSelectionType !== SELECT_TYPE_RADIO) tableData[rowIndex].tableColumns[cellIndex].value = rest.value;

        } 
    } else {
        tableData[rowIndex].answers = [];
        if (tableData?.[rowIndex]?.tableColumns?.[cellIndex]) tableData[rowIndex].tableColumns[cellIndex].selected = false;
    }

    return tableData;
}

export const extractAnswers = (dataset) => {
    let answerArray = [];

    for (let rowData of dataset) {

        if (rowData.answers) {
            answerArray = answerArray.concat(rowData.answers);
        }
    }

    return answerArray;
}

export const setAnswersToTableData = (tableData, answers) => {

    let tableDataCopy = tableData;
    for (let answer of answers) {
        tableDataCopy = setAnswers(tableDataCopy, answer.rowId, answer.cellIndex, answer, true, answer.answerSelectionType)
    }

    return tableDataCopy;

}



export const sortAnswers = (answers) => {
    const sortedData = answers.sort((a, b) => a.rowId - b.rowId);
    return sortedData;
}

export const calculateColumnCount = (tableData) => {
    const maxColumnCount = Math.max(...tableData.map(r => r.tableColumns.length));
    return maxColumnCount;
}

export const getSubTotalRows = (questionTable = []) => {

    if (!questionTable.length) return [];

    //for calculation purposes total Row also get as separate subtotal section
    
    const sortedSubTotalRows = questionTable.filter(r => r && [TOTAL_ROW,SUB_TOTAL_ROW].includes(r.rowType) ).map(i => { return { rowId: i.rowId, subTotalRefId: i.refId } }).sort((a, b) => a.rowId - b.rowId);
    return sortedSubTotalRows;

}

export const getScoreValues = (questionTableProperties = '{}') => {

    if (!questionTableProperties) return [];

    const jsonPassedValues = JSON.parse(questionTableProperties);

    if (!jsonPassedValues?.scoreValues?.advanceOption) {
        return jsonPassedValues?.scoreValues?.rangeScores;
    } else if (jsonPassedValues?.scoreValues?.advanceScores) {
        return jsonPassedValues?.scoreValues?.advanceScores;
    }

    return [];

}

export const restructureTableData = (questionData, isReadOnly) => {
    
    const { healthSurveyQuestion ,  healthSurveyTableSummaries } = questionData;

    if(isReadOnly && healthSurveyTableSummaries && healthSurveyTableSummaries.length > 0){
        const sortedData =  sortTableData(healthSurveyQuestion?.questionTable);
        const newColumnCount = calculateColumnCount(sortedData) + 1;
        const newTableDataArray = [];
        
        for(let rowData of sortedData){
            const copiedObj = _.cloneDeep(rowData);
    
            if (
                rowData.rowType === HEADER
            ) {
                copiedObj.tableColumns.push({...(_.cloneDeep(COLUMN_TEMPLATE)) , value : "Totals", bold: true, columnId: newColumnCount, allowNull : true});
            } else if(rowData.rowType === QUESTION_ROW && rowData.questionSelectionType === SINGLE_SELECTION){
                copiedObj.tableColumns.push({...(_.cloneDeep(COLUMN_TEMPLATE)) , value : null, columnId: newColumnCount, allowNull : true})
            }else if([TOTAL_ROW,SUB_TOTAL_ROW].includes(rowData.rowType)){
                copiedObj.bold = true;
                copiedObj.tableColumns = _.cloneDeep(copiedObj.tableColumns).map(c => {
                    return { ...c, allowNull : true }
                });
                
                for(let i = 3; i <= newColumnCount ; i++){


                    if(i === newColumnCount){
                        const value = findTotalValues( rowData.refId ,healthSurveyTableSummaries);
                        copiedObj.tableColumns.push({...(_.cloneDeep(COLUMN_TEMPLATE)) , value: value?.toFixed(1), columnId: i, allowNull : true})
                    }else{
                        copiedObj.tableColumns.push({...(_.cloneDeep(COLUMN_TEMPLATE)) , value : null, columnId: i, allowNull : true})
                    }
                    
                }

            }
            newTableDataArray.push(copiedObj);
        }
        return newTableDataArray;
    }else {
       const sortedData =  sortTableData(healthSurveyQuestion?.questionTable?.filter(r => r && !r.hide));
       return sortedData;
    }
}