import * as actionTypes from "./actionTypes";
import * as axiosCommon from "../../axios/axios-common-ocr";
import * as url from "./urls";
import { logout } from "./index";

export const deleteAttachmentOcr = formData => {
  axiosCommon.axiosDELETE(
    url.OCR_DELETE_ATTACHMENT,
    res => {
      if (res.type === "SUCCESS") {
      } else {
      }
    },
    formData
  );
};


export const getPrescriptionWithRetry = (jobId, callback) => {
  return dispatch => {
    dispatch({ type: actionTypes.OCR_SCAN_PRESCRIPTION_START });
    axiosCommon.axiosGETPrescription(
      url.OCR_PRESCRIPTION_JOB_ID,
      res => {
        if (res.type === "SUCCESS") {
          dispatch({ type: actionTypes.OCR_SCAN_PRESCRIPTION_OK });
          callback(true, res.responseDto.data);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; getPrescriptionWithRetry");
            dispatch(logout());
          } else {
            // dispatch({
            //   type: actionTypes.OCR_SCAN_PRESCRIPTION_FAIL,
            //   errorDescription: res.errorDto.data
            // });
            callback(false, res.errorDto);
          }
        }
      },
      {
        jobId
      }
    );
  };
};

export const scanPrescription = (content, callback) => {
  return dispatch => {
    dispatch({ type: actionTypes.OCR_SCAN_PRESCRIPTION_START });
    axiosCommon.axiosFileUploadPOST(
      url.OCR_SCAN_PRESCRIPTION,
      res => {
        if (res.type === "SUCCESS") {
          dispatch({ type: actionTypes.OCR_SCAN_PRESCRIPTION_OK });
          callback(false, res.responseDto.data);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; scanPrescription");
            dispatch(logout());
          } else {
            callback(true, {});
          }
        }
      },
      {
        content
      }
    );
  };
};

export const uploadBusinessCard = (imageString, callBack) => {
  return dispatch => {
    axiosCommon.axiosFileUploadPOST(
      url.OCR_SCAN_BUSINESSCARD,
      res => {
        if (res.type === "SUCCESS") {
          callBack(true, res.responseDto.data);
        } else {
          if (res.errorDto.data) {
            callBack(false, res.errorDto.data);
          }
        }
      },
      { content: imageString }
    );
  };
};

export const businessCardJobResult = (jobId, callBack) => {
  return dispatch => {
    axiosCommon.axiosGET(
      url.OCR_BUSINESSCARD_JOB_RESULT,
      res => {
        if (res.type === "SUCCESS") {
          if (res.responseDto.data !== "IN_PROGRESS") {
            callBack(true, res.responseDto.data);
          } else {
            setTimeout(
              () => dispatch(businessCardJobResult(jobId, callBack)),
              2000
            );
          }
          //dispatch(callBack(true, res.responseDto.data));
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; businessCardJobResult");
            dispatch(logout());
          } else {
            if (res.errorDto.data) {
              callBack(false, res.errorDto.data);
            }
          }
        }
      },
      { jobId: jobId }
    );
  };
};

export const startAttachmentsScan = (formData, callBack) => {
  return dispatch => {
    axiosCommon.axiosFileUploadPOST(
      url.OCR_SCAN_ATTACHMENT,
      res => {
        if (res.type === "SUCCESS") {
        } else {
        }

        if (callBack) {
          callBack();
        }
      },
      formData
    );
  };
};
