import React, { Component } from "react";
import { View, TouchableOpacity, Text, Platform } from "react-native";
import styles from "../../../Css";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import CheckInItem from "./CheckInItem";
import { EmptyItem } from "../Common";
import { dashboardCategories, dashboardIssues, getDashboardData, sortAlphabeticalOrder } from '../../../util/commonUiLogic';
import { ROUTINES } from "../../../navigation/path";
import { ANDROID } from "../../../util/platformWindowConfig";

class CheckInSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      loading: false,
      disabledAll: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth } = props;

    if (
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  handleCreateNewJournalEntry = () => {
    const {
      onCreateNewRoutine,
      journalUserId,
      handleCreateNewRoutine,
      applicationSettingsDigest,
    } = this.props;
    const { newJournalEntryTypeId } = applicationSettingsDigest;

    this.setState({ disabledAll: true, loading: true, disabledOthers: true });
    onCreateNewRoutine(
      { journalEntryTypeId: newJournalEntryTypeId, draft: true },
      (res) => {
        this.setState({
          disabledAll: false,
          loading: false,
          disabledOthers: false,
        });
        if (res) {
          handleCreateNewRoutine();
        }
      },
      journalUserId
    );
  };

  setDisabledAll = (val) => {
    this.setState({ disabledAll: val });
  };

  render() {
    const { disabledAll } = this.state;
    const { journalEntryTypes, handleCreateNewRoutine, navigation } = this.props;
    let journalTypes = [...journalEntryTypes];
    journalTypes = journalTypes.filter(res =>
      res.userCanEdit &&
      !res.isAppointment &&
      res.isActive &&
      res.isHidden === false &&
      res.type !== "DEVICE" &&
      (res.showOnDashboard || res.journalPublishedBy === "PARTNER")
    );

    journalTypes = sortAlphabeticalOrder(journalTypes);
    let issue = '';
    if (journalTypes.length === 0) {
      issue = dashboardIssues.NOPINNED;
    }

    if (
      !journalEntryTypes.some(
        res =>
          res.userCanEdit &&
          res.isHidden === false
      )
    ) {
      issue = dashboardIssues.NODATA;
    }

    if (issue !== '') {
      return <EmptyItem navigation={navigation} type={dashboardCategories.ROUTINES} issue={issue} />;
    }

    return (
      <>
        <Text style={[styles.textPinned, Platform.OS !== ANDROID ? styles.bold : null]} allowFontScaling={false}>Pinned:</Text>
        {journalTypes.map((journalType, index) => {
          return (
            <View
              style={[styles.itemsContainerRow, styles.comSpSm]}
              key={"jt_" + index}
            >
              <CheckInItem
                title={journalType.name}
                icon={journalType.icon_c ? { uri: journalType.icon_c } : require("../../../assets/journal-icons/report.png")}
                hasLogoIcon={journalType.icon_c ? true : false}
                journalEntryTypeId={journalType.id}
                color={journalType.color}
                redirectJournal={handleCreateNewRoutine}
                disabledAll={disabledAll}
                setDisabledAll={this.setDisabledAll}
                isDeviceJournal={journalType.type === 'DEVICE' ? true : false}
                deviceName={journalType.deviceName}
              />
            </View>
          );
        })}
        <View style={[styles.flexJcCtAiCt, styles.comSpSm]}>
          <TouchableOpacity
            onPress={() =>
              navigation.reset({
                index: 0,
                routes: [{
                  name: ROUTINES.path,
                }],
              })
            }
          >
            <Text style={[styles.textPrimeBold, styles.resourceBlueTxt, styles.textUnderline]} allowFontScaling={false}>
              {getDashboardData(dashboardCategories.ROUTINES).viewButtonText}</Text>
          </TouchableOpacity>
        </View>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    journalEntryTypes: state.routines.journalEntryTypes,
    journalUserId: state.uistate.journalUserId,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateNewRoutine: (journalEntry, callBack, journalUserId) =>
      dispatch(actions.createNewRoutine(journalEntry, callBack, journalUserId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckInSection);
