import React from "react";
import { GOOGLE_PLACES_API_KEY } from "../../util/cognitoConfig";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef,props) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["geocode"]}
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery,props)
  );
}

async function handlePlaceSelect(updateQuery,props) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  props.handleAdressSelection(addressObject);
}

class GooglePlacesAutoComplete extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      query: props.defaultValue
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places`,
      () => handleScriptLoad((val) => this.setState({query: val}), this.inputRef, this.props)
    );
  }
  
  render() {
    return (
      <div className="search-location-input">
        <input
          ref={this.inputRef}
          onChange={event => this.setState({query: event.target.value})}
          placeholder=""
          value={this.state.query}
          style={{border: 'none', width: '100%'}}
          {...this.props.inputProps}
        />
      </div>
    );
  }
}

export default GooglePlacesAutoComplete;