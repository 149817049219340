import React, {Fragment, useState, useEffect} from 'react';
import UploadProgress from '../../../components/ImagesDocumentsForAttachment/uploadProgress';
import CancelUploadConfirmation from '../../../components/ConfirmationBox/savingConfirmation';
import SuccessBox from '../../../components/ConfirmationBox/successBox';
import {deleteFileByUri} from '../../../axios/common';
import {Platform} from 'react-native';
import { IOS } from '../../../util/platformWindowConfig';


const MobileProgress = props => {
  const {artifactId, attachments, onUpload, eventId, exit, journalUserId, cancelUploadAttachmentProcess} = props;
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [success, setSuccess] = useState(false);
  const [uniqueId, setUniqueId] = useState(null);

  useEffect(() => {
    if (!attachments || attachments.length < 0) return;
    setUploading(true);
    const formData = new FormData();
    let fileSize = 0;
    const uniqueIdGen = Date.now();
    setUniqueId(uniqueIdGen);
    attachments.forEach((res, index) => {
      const file = res.file;
      fileSize += file.size;
      formData.append('files[' + index + ']', file, file.name);
    });

    let uploadConfig = {
      onUploadProgress: progressEvent => {
        let progressUpload = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        setProgress(progressUpload);
      },
    };
    onUpload(
      artifactId,
      formData,
      (success) => {
        if (success) {
          setUploading(false);
          setCancelling(false);
          setProgress(0);
          setSuccess(true);

          setTimeout(() => {
            setSuccess(false);
            if (Platform.OS === IOS) {
              attachments.forEach(res => {
                deleteFileByUri(res.file.uri);
              });
            }
            exit();
          }, 2000);
        } else {
          setProgress(0);
          exit();
        }
      },
      false,
      journalUserId,
      fileSize,
      uploadConfig,
      undefined,
      uniqueIdGen,
      eventId,
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const restoreToUpload = () => {
    setCancelling(false);
    setTimeout(() => {
      setUploading(true);
    }, 500);
  }

  return (
    <Fragment>
      {uploading && (
        <UploadProgress
          visibleModal
          progress={progress}
          onPressCancel={() => {
            setUploading(false);
            setTimeout(() => {
              setCancelling(true);
            }, 500);
          }}
        />
      )}
      {cancelling && (
        <CancelUploadConfirmation
          visibleModal
          onBackdropPress={restoreToUpload}
          onRequestClose={restoreToUpload}
          alertTitle={'Cancel Upload?'}
          alertBody={
            'Your items are still uploading, are you sure you want to cancel?'
          }
          btnTextOne={'Cancel Upload'}
          btnTextTwo={'Continue Upload'}
          handleCancel={() =>
            cancelUploadAttachmentProcess(
              artifactId,
              journalUserId,
              () => {
                exit();
              },
              uniqueId
            )
          }
          handleConfirm={restoreToUpload}
        />
      )}
      {success && (
        <SuccessBox
          visibleModal
          messageTitle="Success!"
          message="Attachments uploaded"
        />
      )}
    </Fragment>
  );
};

export default MobileProgress;
