import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import { showJournalTitle } from '../../screens/Routines/Util/JournalEntryUtils'

const routineItem = props => {
  let journalHasAttachments = null;
  if (props.journalHasAttachments) {
    journalHasAttachments = (
      <Image
        style={styles.attachIcon}
        source={require('../../assets/icons/attach.png')}
      />
    );
  }
  let windowWidth = props.windowWidth;
  let smScreenMaxWidth = 1023;
  return (
    <View
      style={[
        styles.singleItemBorder,
        styles.flexCom,
      ]}>
      <TouchableOpacity
        style={[styles.flexCom]}
        onPress={() => props.handleOnPressAttachments(props.journalEntry)}>
        <View
          style={[
            styles.itemSingleRow,
            styles.flexCom,
            props.highlightColor && windowWidth > smScreenMaxWidth
              ? styles.selectedItemBg
              : styles.bgTransparent,
          ]}>
          <View style={[styles.itemContentCol]}>
            <View
              style={[
                styles.itemsContainerBox,
                windowWidth > smScreenMaxWidth
                  ? styles.itemsContainerBoxLg
                  : [
                      styles.itemsContainerBoxSm,
                      {backgroundColor: props.journalEntryType.color},
                    ],
              ]}>
              {windowWidth > smScreenMaxWidth && (
                <View
                  style={[
                    styles.itemIconBox,
                    styles.itemIconBoxSm,
                    {backgroundColor: props.journalEntryType.color},
                  ]}>
                  {props.journalEntryType?.icon_c || props.journalEntryType?.type === 'DEVICE'
                    ? <Image
                        style={[
                          styles.itemIconBox,
                          styles.itemIconBoxSm,
                        ]}
                        source={props.journalEntryType?.icon_c 
                          ? {uri: props.journalEntryType?.icon_c}
                          : require('../../assets/devices/hexoskin-journal-logo.png')
                        }
                      />
                    : <Image
                        style={styles.itemIcon}
                        source={require('../../assets/journal-icons/report.png')} 
                      />
                  }
                  {Boolean(props.journalHasAttachments) && (
                    <View style={styles.attachementAvailability}>
                      <View style={styles.activeAttachBorder}>
                        <View style={styles.activeAttachIcon} />
                      </View>
                    </View>
                  )}
                </View>
              )}
            </View>

            <View
              style={[
                styles.itemDetailWrapperPd,
                styles.itemContentView,
                windowWidth > smScreenMaxWidth
                  ? styles.itemDetailLtPdLg
                  : styles.itemDetailLtPdSm,
              ]}>
              <Text
                style={[
                  props.highlightColor
                    ? styles.textPrimeBold
                    : windowWidth > smScreenMaxWidth
                    ? styles.textPrime
                    : styles.textPrimeBold,
                ]}
                allowFontScaling={false}
                >
                {/* Props - Title */}
                {showJournalTitle(props.journalEntry, props.journalEntryType)}
              </Text>
              <View style={styles.dateItemWrapper}>
                <Text style={[styles.textPrimeSm, styles.textColorLight]}
                  allowFontScaling={false}
                   >
                  {/* Props - Date and Time */}
                  {props.routineDate + ' ' + props.routineTime}
                  {/* {props.routineTime} */}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.itemIconSetColSm]}>
            {windowWidth <= smScreenMaxWidth && (
              <View style={[styles.itemIconSingle]}>
                {journalHasAttachments}
              </View>
            )}
            {/* Props - Flagged */}
            <TouchableOpacity
              style={[styles.itemIconSingle]}
              onPress={() =>
                props.handleOnChangeflagged(props.journalEntry, props.flagged ? false : true)
              }>
              <Image
                style={[styles.starIconExSm,  !props.flagged && styles.starInactive]}
                source={require('../../assets/icons/star-status.png')}
              />
            </TouchableOpacity>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (routineItem);
