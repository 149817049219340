import React from "react";
import { Text, View } from "react-native";
import styles from "../../../Css";
import Modal from "../../Modal/modal";
import ModalLayoutSpec from "../../ModalLayout/modalLayoutSpec";
import PrimaryButton from '../../Button/primaryButton';

const disconnectPopup = props => {
    const {
        visibleModal,
        hidePopup,
        selectedUser,
        wait,
        onDisconnect
    } = props;
    return (
      <Modal
        visible={visibleModal}
        onBackdropPress={() => hidePopup('DISCONNECT_POPUP')}
      >
        <ModalLayoutSpec
          contPosition={"center"}
          showClose={true}
          hidePopup={() => hidePopup('DISCONNECT_POPUP')}
        >
          <View style={styles.popupContentWrapper}>
            <View style={[styles.popMessageContView, styles.comSp]}>
                <View style={[styles.popMessageTitleView]}>
                    <Text style={[styles.textPrime, styles.textAlignCenter]} allowFontScaling= {false}>
                      Would you like to disconnect
                    </Text>
                </View>
                <View style={[styles.popMessageTitleView]}>
                  <Text style={[styles.textPrimeBold, styles.textAlignCenter]} allowFontScaling= {false}>
                    {selectedUser && selectedUser?.userName} {"?"}
                  </Text>
                </View>
            </View>
            <View style={[styles.popBtnView, styles.comMgTopSm]}>
              <View style={[styles.btnArea, styles.flexRow, styles.flexJcCtAiCt]}>
                <View style={styles.fieldBtn}>
                  <PrimaryButton
                    btnText={"Confirm"}
                    btnPosition={"full"}
                    touchableOnPress={onDisconnect}
                    activityInd={wait}
                    disabled={wait}
                    btnStyle={[
                      styles.submitBgBlue,
                      styles.primaryButtonLgPd,
                      wait
                        ? styles.btnBlurState
                        : styles.btnActiveState,
                    ]}
                  />
                </View>
              </View>
            </View>
          </View>
        </ModalLayoutSpec>
      </Modal>
    );
};
export default (disconnectPopup);
