import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import styles from "../../../../Css";
import stylesResponsive from "../../../../CssResponsive";
import UIText from "../../../Text/text";
import ClinicalTrialResultsScrollView from "../../../../screens/Settings/ClinicalTrialsSettings/ClinicalTrialResultsScrollView";
import _ from "underscore";
import PageContainer from "../../../../components/PageLayout/PageContainer";

const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
const clinicalTrialResultList = (props) => {

  return (
    <View style={styles.gridRow}>
      <View style={styles.gridColFull}>
        <View style={stylesRes.tabContentFullArea}>
          <View style={styles.tabFullBlock}>
            <PageContainer smScreenFull={true}>
              <View style={styles.titleArea}>
                <View
                  style={
                    //styles.colPdTopLtRt
                    styles.colPdTopCom
                  }
                >
                  <View style={stylesRes.titleMaxComSm}>
                    <View style={[styles.titleFullBlock, styles.flexJcCtAiCt]}>
                      <View style={[styles.titleMainBlock]}>
                        <UIText
                          title="Search Results"
                          style={[styles.textPrimeBold, styles.textAlignCenter]}
                        />
                      </View>
                      <View style={[styles.titleSubBlock]}>
                        {props.searchResults.count ? (
                          <Text style={[styles.textPrime]}>
                            <Text style={[styles.bold]} allowFontScaling={false}>
                              {props.searchResults.count}
                            </Text>{" "}
                            Studies found for:
                          </Text>
                        ) : (
                          <Text style={[styles.textPrime]}>
                            <Text style={[styles.bold]} allowFontScaling={false}>No matching found</Text>
                          </Text>
                        )}
                      </View>
                    </View>
                  </View>
                </View>

                <View
                  style={
                    //styles.colPdTopLtRt
                    styles.colPdTopCom
                  }
                >
                  <View style={[styles.searchResultSec, styles.greyBorderThin]}>
                    <View style={[styles.resultWrapper]}>
                      <View style={[styles.ViewRow]}>
                        <View style={styles.viewDetailSec}>
                          <View style={styles.viewMainData}>
                            <View
                              style={[
                                styles.viewItemDesc,
                                styles.flexJcCtAiCt,
                                styles.colPdLtRtBot,
                              ]}
                            >
                              <View style={[styles.viewDataFull]}>
                                <View style={[styles.viewItemData]}>
                                  <View style={[styles.searchItemList]}>
                                    <View style={[styles.searchItem]}>
                                      <Text
                                        style={[
                                          styles.textExMicSmBold,
                                          styles.smLineHeight,
                                          styles.textAlignCenter,
                                        ]}
                                        allowFontScaling={false}
                                      >
                                        {props.searchResults.query}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </PageContainer>
            <View style={[styles.secSubArea]}>
              <View style={styles.secSubContentArea}>
                {props.searchResults && !_.isEmpty(props.searchResults) ? (
                    <ClinicalTrialResultsScrollView
                      data={props.searchResults}
                      searchTearmQuary={props.searchTearmQuary}
                  />
                ) : null}
              </View>
            </View>

            <View style={[styles.secSubArea, styles.bgWhite]}>
              <View style={[styles.btnArea, styles.btnMgSm]}>
                <View
                  style={[
                    stylesRes.btnViewSec,
                    stylesRes.btnExSp,
                    stylesRes.btnListPdTop,
                  ]}
                  dataSet={{media: ids.btnExSp}}
                >
                  <View style={styles.fieldBtn}>
                    <View style={styles.btnBlockFull}>
                      <TouchableOpacity
                        style={[styles.submitSm, styles.disableBtn]}
                        underlayColor="rgba(0, 153, 168, 0.8)"
                        onPress={props.onDirectSearchClinicalTrials}
                      >
                        <View style={styles.btnSingleView}>
                          <View
                            style={[styles.btnSingle, styles.btnSingleCent]}
                          >
                            <UIText
                              style={styles.submitText}
                              title="Edit Search"
                            />
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View style={styles.fieldBtnSp} />
                  <View style={styles.fieldBtn}>
                    <View style={styles.btnBlockFull}>
                      <TouchableOpacity
                        style={styles.submitSm}
                        underlayColor="rgba(0, 153, 168, 0.8)"
                        onPress={props.onPressSaveSearchClinicalTrials}
                      >
                        <View style={styles.btnSingleView}>
                          <View
                            style={[styles.btnSingle, styles.btnSingleCent]}
                          >
                            <UIText
                              style={styles.submitText}
                              title="Save Search"
                            />
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default (clinicalTrialResultList);
