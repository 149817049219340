import React, { Component } from "react";
import { View } from "react-native";
import styles from "../../Css";
import * as FieldDetails from "../TextInput/fieldDetails";
import TextInputFrame from "../TextInput/textInputFrame";

class Instructions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instructions: props.instructions,
    };
  }

  onChangeInstructions = (val) => {
    this.setState({ instructions: val }, () => {
      this.props.updateInstructions(val);
    });
  };
  render() {
    return (
      <View style={[styles.fieldRow, styles.zIndexMin]}>
        <View style={[styles.fieldRowWrapper]}>
          <View style={[styles.fieldColStretch]}>
            {/* <View style={[styles.textView]}>
          <UIText
            style={[styles.textLabel, styles.bold, styles.textColorLight]}
            title="Instructions"
          />
        </View> */}
            <TextInputFrame
              title={"Instructions"}
              fieldBg={FieldDetails.BGWHITE}
              value={this.state.instructions}
              fieldLt={true}
              onChangeText={this.onChangeInstructions}
              maxLength={250}
            />
            {/* <TextInput
          style={[
            styles.textField
            // this.state.dosagesArray[i].validFrequency
            //   ? styles.validColor
            //   : styles.inValidColor
          ]}
          onChangeText={this.onChangeInstructions}
          value={this.state.instructions}
          underlineColorAndroid="transparent"
        /> */}
          </View>
        </View>
      </View>
    );
  }
}

export default (Instructions);
