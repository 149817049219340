import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import {DASHBOARD} from '../../navigation/path';
import {Amplify, Auth} from 'aws-amplify';
import {
    IDENTITY_POOL_ID,
    REGION,
    IDENTITY_POOL_REGION,
    USER_POOL_ID,
    USER_POOL_WEB_CLIENT_ID,
} from '../../util/cognitoConfig';
import AccountVerify from '../AccountVerify';
import CheckYourEmail from '../../components/Signup/CheckYourEmail';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import { pendoTrackEvent } from '../../util/pendoConfig';

Amplify.configure({
    Auth: {
        identityPoolId: IDENTITY_POOL_ID,
        region: REGION,
        identityPoolRegion: IDENTITY_POOL_REGION,
        userPoolId: USER_POOL_ID,
        userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
        mandatorySignIn: false,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        clientMetadata: {myCustomKey: 'myCustomValue'},
    },
});

class EmailVerificationScreen extends Component {
    constructor(props) {
        super(props);
        this.textInput = null;
        //TO-DO remove previous navigation changes
        //const queryString = this.props.location.search ? this.props.location.search.split("&") : [];
        //let code = null;
        // queryString.forEach((value, index) => {
        //     const keyValue = value.replace('?', '').split('=');
        //     if (keyValue.length === 2 && keyValue[0] === 'code') {
        //         code = keyValue[1];
        //     }
        // });
        let code = this.props.route?.params?.code ? this.props.route?.params?.code : null;
        this.state = {
            //email: '',
            emailLoadWait: true,
            verificationCode: code,
            windowHeight: this.props.windowHeight,
            windowWidth: this.props.windowWidth,
            email: null,
            showLearnHowPrivacyPolicy: false,
            user: null,
            emailVerified: this.props.emailVerified,
        };
    }

    componentDidMount = () => {
        Auth.currentAuthenticatedUser({
            bypassCache: true,
        })
            .then(user => {
                this.setState({
                    email: user.attributes.email,
                    user: user,
                    emailLoadWait: false,
                });
            })
            .catch(err => {
                this.setState({emailLoadWait: false});
            });
        pendoTrackEvent('EmailVerification@');
    };

    static getDerivedStateFromProps(props, state) {
        const {windowWidth, windowHeight, emailVerified} = props;
        let code = props.route?.params?.code ? props.route?.params?.code : null;

        if (
            windowHeight !== state.windowHeight ||
            windowWidth !== state.windowWidth ||
            emailVerified !== state.emailVerified ||
            code !== state.verificationCode
        ) {
            return {
                windowHeight: windowHeight,
                windowWidth: windowWidth,
                emailVerified: emailVerified,
                verificationCode: code
            };
        }

        return null;
    }

    confirmUserSignUpWithCode = (verificationCode, callBack) => {
        Auth.verifyCurrentUserAttributeSubmit('email', verificationCode)
            .then(data => {
                this.props.setEmailVerified(true);
                callBack(true);
            })
            .catch(err => {
                if (
                    err.code === 'CodeMismatchException' ||
                    err.code === 'ExpiredCodeException'
                ) {
                    callBack(false, 'Error! Looks like you entered the wrong code');
                } else {
                    callBack(false, err.message);
                }
            });
    };

    goToDashboard = () => {
        this.props.navigation.push(DASHBOARD.path);
    };

    login = () => {
        this.props.signup(this.state.user, this.state.staySigned, false, success => {
            if (success) {
                this.props.navigation.push(DASHBOARD.path);
            }
        });
    };

    resendEmailVerification = callBack => {
        Auth.verifyCurrentUserAttribute('email')
            .then(() => {
                callBack(true);
            })
            .catch(err => {
                console.log(err);
                callBack(false, err.message);
            });
    };

    skipEmailVerification = () => {
        this.login();
    };

    render() {
        const {verificationCode, emailLoadWait} = this.state;
        let fullHeight = this.state.windowHeight;
        let minHeight = fullHeight;
        let fullWidth = this.state.windowWidth;
        return (
            <React.Fragment>
                {emailLoadWait && <ActivityIndicator/>}
                {Boolean(verificationCode) && !Boolean(emailLoadWait) && (
                    <AccountVerify
                        confirmUserSignUpWithCode={this.confirmUserSignUpWithCode}
                        verificationCode={this.state.verificationCode}
                        goToDashboard={this.goToDashboard}
                        emailVerified={this.state.emailVerified}
                    />
                )}
                {!Boolean(verificationCode) && Boolean(this.state.email) && (
                    <CheckYourEmail
                        windowHeightView={fullHeight}
                        windowHeightSmView={minHeight}
                        windowWidthView={fullWidth}
                        bgBlue={true}
                        contentCenter={false}
                        privacyLink={true}
                        email={this.state.email}
                        skipEmailVerification={this.skipEmailVerification}
                        resendEmailVerification={this.resendEmailVerification}
                        toggleLearnHowPrivacyPolicy={() => {
                            this.setState({showLearnHowPrivacyPolicy: true});
                        }}
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signup: (user, staySigned, shallow, callBack) =>
            dispatch(actions.signup(user, staySigned, shallow, callBack)),
        updateDeeplinkHandled: () => dispatch(actions.updateDeeplinkHandled()),
        setEmailVerified: (value) => dispatch(actions.setEmailVerified(value)),
    };
};

const mapStateToProps = state => {
    return {
        windowHeight: state.uistate.windowHeight,
        windowWidth: state.uistate.windowWidth,
        emailVerified: state.auth.emailVerified,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EmailVerificationScreen);
