import Moment from 'moment';

export const getSubscriptionExpiry = () => {
  let currentSubscription = this.getCurrentSubscription();
  if (currentSubscription !== null) {
    return currentSubscription.subscriptionExpiryDate;
  } else {
    return null;
  }
};

export const getSubscriptionPaymentType = () => {
  let currentSubscription = this.getCurrentSubscription();
  if (currentSubscription !== null) {
    return currentSubscription.paymentSource;
  } else {
    return null;
  }
};

export const isSubscriptionCancelled = userSubscription => {
  return !userSubscription || userSubscription.isCancel;
};

export const getCurrentSubscription = userSubscription => {
  // This is a really short list so I am okay for now calling this method multiple times rather
  // than caching the result
  let now = Date.now();

  let maxSubscriptionDate = now;
  let currentSubscription = null;
  if (userSubscription) {
    userSubscription.forEach(item => {
      if (
        item.subscriptionMadeBy === 'IOS' &&
        item.subscriptionStatus === 'PREMIUM'
      ) {
        currentSubscription = item;
      } else {
        if (
          item.subscriptionDate <= now &&
          maxSubscriptionDate < item.subscriptionExpiryDate
        ) {
          maxSubscriptionDate = item.subscriptionExpiryDate;
          currentSubscription = item;
        }
      }
    });
  }

  return currentSubscription;
};

export const isSubscriptionPremium = currentSubscription => {
  return (
    currentSubscription !== null &&
    currentSubscription !== undefined &&
    currentSubscription.subscriptionStatus === 'PREMIUM'
  );
};

export const isSubscriptionSponsored = currentSubscription => {
  return (
    isSubscriptionPremium(currentSubscription) &&
    currentSubscription.paymentSource === 'ORGANIZATION' &&
    currentSubscription.sponsoringToken !== null
  );
};

export const isSubscriptionSponsoredByHanalytics = currentSubscription => {
  return (
    isSubscriptionSponsored(currentSubscription) &&
    currentSubscription.sponsoringOrganization === 1
  );
};

export const expiryText = currentSubscription => {
  if (
    isSubscriptionSponsored(currentSubscription) &&
    currentSubscription.subscriptionExpiryDate !== null
  ) {
    let expiryDateString = Moment(
      new Date(currentSubscription.subscriptionExpiryDate),
    ).format('DD MMM YYYY');

    if (!isSubscriptionSponsoredByHanalytics(currentSubscription)) {
      return 'Premium plan expires on ' + expiryDateString;
    } else {
      return 'Your free trial expires on ' + expiryDateString;
    }
  }
  return '';
};

export const isPremiumSelfPayAccount = currentSubscription => {
  return (
    isSubscriptionPremium(currentSubscription) &&
    currentSubscription.paymentSource !== 'ORGANIZATION'
  );
};

export const getAccountType = userSubscriptions => {
  let userSubscription = null;
  if (userSubscriptions && userSubscriptions[0]) {
    userSubscription = userSubscriptions[0];
  }
  const today = new Date();

  if (
    userSubscription &&
    userSubscription.subscriptionMadeBy === 'IOS' &&
    userSubscription.subscriptionStatus === 'PREMIUM'
  ) {
    return 'premium';
  } else if (
    userSubscription &&
    userSubscription.subscriptionExpiryDate > today.getTime() &&
    userSubscription.subscriptionDate <= today.getTime()
  ) {
    return 'premium';
  } else {
    return 'regular';
  }
};

export const getISubscriptionExpired = userSubscriptions => {
  if (
    userSubscriptions &&
    userSubscriptions.expiredSubscription &&
    userSubscriptions.expiredSubscription.length > 0
  ) {
    return true;
  } else {
    return false;
  }
};

export const getSubscriptionMadeBy = currentSubscription => {
  if (currentSubscription) {
    if (currentSubscription.subscriptionStatus === 'TRIAL') {
      return null;
    } else if (
      currentSubscription.subscriptionMadeBy === null ||
      currentSubscription.subscriptionMadeBy === 'STRIPE'
    ) {
      return 'Stripe';
    } else {
      return 'Apple App Store';
    }
  }

  return null;
};

export const getSubscriptionPlanDescription = currentSubscription => {
  if (currentSubscription && currentSubscription.subscriptionType) {
    if (currentSubscription.subscriptionStatus === 'TRIAL') {
      return 'Your Free premium Trial';
    } else if (currentSubscription.subscriptionType === 'MONTH') {
      return 'Your Premium Monthly Plan';
    } else {
      return 'Your Premium Annual Plan';
    }
  }

  return null;
};

export const getSubscriptionType = currentSubscription => {
  if (currentSubscription && currentSubscription.subscriptionType) {
    if (currentSubscription.subscriptionStatus === 'TRIAL') {
      return 'Free Subscription ';
    } else if (currentSubscription.subscriptionType === 'MONTH') {
      return 'Monthly Subscription';
    } else {
      return 'Annual Subscription';
    }
  }

  return null;
};

export const getNextBillingDate = currentSubscription => {
  if (currentSubscription && currentSubscription.subscriptionExpiryDate) {
    return Moment(currentSubscription.subscriptionExpiryDate).format(
      'MMM DD, YYYY',
    );
  }

  return null;
};


// export const getSponsorOrganization = currentSubscription => {
//   return (
//       isSubscriptionSponsored(currentSubscription) ? currentSubscription.sponsoringOrganization : -1
//   );
// };
export const getSponsorOrganization = userSubscriptions => {
  let sponsorOrganization = '';
  if (userSubscriptions &&
      userSubscriptions.sponsorUserDigest &&
      userSubscriptions.sponsorUserDigest.organizationName
  ) {
    sponsorOrganization = userSubscriptions.sponsorUserDigest.organizationName;
  }
  return sponsorOrganization;
};

export const getSponsorProfile = userSubscriptions => {
  let sponsorProfile = '';
  if (userSubscriptions &&
      userSubscriptions.sponsorUserDigest &&
      userSubscriptions.sponsorUserDigest.profileName
  ) {
    sponsorProfile = userSubscriptions.sponsorUserDigest.profileName;
  }
  return sponsorProfile;
};