import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import styles from "../../Css";
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';

const arrowPrevButton = (props) => {
  return (
    <View style={[styles.btnSectionLink, styles.flexJcCtAiFs, styles.arrowSecPdLt]}>
      <TouchableOpacity
        style={[props.touchableStyle]}
        onPress={props.touchableOnPress}
        disabled={props.disabled}
      >
        <View style={[styles.flexRow, styles.flexAiCt]}>
          <View style={[styles.flexRow, styles.flexJcCtAiCt, props.disabled && {opacity: 0.5}]}>
          <View style={styles.iconViewExSm}>
            <Image
              style={styles.prevArrowThick}
              source={require("../../assets/icons/prev-arrow-thick.png")}
            />
          </View>
          <Text style={[styles.textPrimeBold, styles.textOrange]} allowFontScaling={false}>
            {props.btnText}
          </Text>
          </View>
          {Boolean(props.prevWait) && (
            <View
              style={[
                styles.indicatorIconView,
                styles.indLoaderLgRtSpec
              ]}>
              <ActivityIndicator pdZero={true} size="small" color={'#FF8300'}/>
            </View>
           )}
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (arrowPrevButton);
