import theme from "./base/theme";
import * as variable from "./base/variable";
import * as variableCommon from "./base/variableCommon";
import { Platform } from "react-native";
export const cssGrid = {
  fieldContainer: {
    marginBottom: 15,
  },
  fieldContainerLrg: {
    marginBottom: 40,
  },
  fieldRowWrapper: {
    paddingTop: 5,
    marginTop: 5,
    flexDirection: 'row',
    position: 'relative'
  },
  fieldRowWrapperFull: {
    paddingTop: 5,
    marginTop: 5,
    position: 'relative'
  },
  fieldRowWrapperSm: {
    flexDirection: 'row',
    position: 'relative',
  },
  fieldColSpMd: {
    width: 14,
  },
  fieldColSp: {
    width: 10,
  },
  fieldColSpSm: {
    width: 5,
  },
  fieldColStretch: {
    flex: 1,
  },
  fieldColIcon: {
    flexBasis: 36,
    maxWidth: 36
  },
  fieldColDelete: {
    flexBasis: 36,
    maxWidth: 36
  },
  fieldColEmpty: {
    flexBasis: 36,
    maxWidth: 36
  },
  fieldColMed: {
    flexBasis: 100,
    maxWidth: 100
  },
  fieldColBtn: {
    flexBasis: 160,
    maxWidth: 160
  },
  symptomFieldBtn: {
    flexBasis: 140,
    maxWidth: 140
  },
  fieldColPickerRgMd: {
    flexBasis: 60,
    maxWidth: 60
  },
  fieldColPlusMinus: {
    flexBasis: 100,
    maxWidth: 100
  },
  fieldColPlusMinusText: {
    flexBasis: 100,
    maxWidth: 100
  },
  fieldColId: {
    flexBasis: 30,
    maxWidth: 30,
    paddingLeft: 5
  },
  textInputWrapper: {
    height: 50,
  },
  textInputLgWrapper: {
    height: 70,
  },
  textInputSmWrapper: {
    height: 40,
  },
  textInputField: {
    ...variable.textInputPd,
    ...variable.textInputPrimary,
    height: 50,
    width: "100%"
  },
  textInputFieldSm: {
    ...variable.textInputPd,
    ...variable.textInputPrimary,
    height: 40,
    width: "100%"
  },
  textInputFieldPicker: {
    ...variable.textInputPdPicker,
    ...variable.textInputPrimary,
    height: 50,
    width: "100%"
  },
  textInputFieldPickerSm: {
    ...variable.textInputPdPicker,
    ...variable.textInputPrimary,
    height: 40,
    width: "100%"
  },
  textInputFieldLg: {
    ...variable.textInputPd,
    ...variable.textInputPrimary,
    height: 70,
    width: "100%"
  },
  dataWrapper: {
    height: 35,
    overflow: 'hidden',
  },
  dataWrapperSm: {
    height: 25,
    overflow: 'hidden',
  },
  fieldNameWrapper: {
    position: "absolute",
    top: -7,
    zIndex: 99999,
  },
  fieldNameCenter: {
    alignSelf: 'center'
  },
  fieldNameLeft: {
    left: 10,
  },
  fieldNameBorder: {
    position: "absolute",
    zIndex: 9,
    height: 7,
    bottom: 0,
    width: '100%',
    ...Platform.select({
      ios: {
        height: 7,
        bottom: 0,
      },
      android: {
        height: 6,
        bottom: 4,
      },
    }),
  },
  fieldName: {
    zIndex: 99,
  },
  framePlusMinus: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  frameInfoPd: {
    paddingRight: 30,
  },
  frameInfoPdCom: {
    paddingRight: 35,
  },
  txtInputRtSp: {
    paddingRight: 45,
  },
  frameInfoPdSm: {
    paddingRight: 30,
  },
  btnActionView: {
    position: "absolute",
    zIndex: 999,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  btnActionMinus: {
    left: 0,
    top: 0,
    height: 48,
    paddingLeft: 4,
  },
  btnActionPlus: {
    right: 0,
    top: 0,
    height: 48,
    paddingRight: 4,
  },
  btnActionInfo: {
    right: 10,
  },
  btnActionInfoSm: {
    right: 5,
  },
  btnActionRtSm: {
    right: 2,
  },
  btnActionInfoRtZero: {
    right: 0,
  },
  fieldRowCom: {
    marginBottom: 15
  },
  fieldRowComWrapper: {
    flexDirection: 'row',
    position: 'relative'
  },
  pickerFrameWrapper: {
    ...variable.textPickerFrame,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 1,
    paddingRight: 1
  },
  textPickerInFrame: {
    color: theme.PRIMARY_FONT_COLOR,
    borderWidth: 0,
    ...variableCommon.pickerBg,
    paddingTop: 0,
    paddingRight: 15,
    paddingBottom: 0,
    paddingLeft: 5,
    height: 30,
  },
  textPickerSelection: {
    color: theme.PRIMARY_FONT_COLOR,
    borderWidth: 0,
    ...variableCommon.pickerBg,
    paddingTop: 0,
    paddingRight: 15,
    paddingBottom: 0,
    paddingLeft: 0,
    height: 33,
  },
  textPickerSelectionRg: {
    height: 33,
    paddingLeft: 0,
  },
  textPickerSelectionSm: {
    height: 23,
    paddingLeft: 0,
  },
  dropArrowFrameView: {
    position: "absolute",
    display: "flex",
    right: 7,
    zIndex: 99999
  },
  dropArrowFrameCent: {
    top: 0,
    bottom: 0,
    justifyContent: "center"
  },
  dropArrowFrameIcon: {
    width: 6,
    height: 12
  },
  fieldDescription: {
    flex: 1,
    justifyContent: 'center',
  },
  fieldRowFrame: {
    flexDirection: "column",
    position: 'relative',
    marginBottom: 20,
  },
  textAreaInputWrapper: {
    minHeight: 50,
    ...variable.textAreaInputFrame,
  },
  textAreaInput: {
    ...variable.textAreaInputPd,
    ...variable.textAreaInputPrimary,
    minHeight: 46,
    width: "100%"
  },
  textSectionCenter: {
    flex: 1,
    justifyContent: "center",
  },
  weeklyAddWrapper: {
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 4,
  },
  textBoxField: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    ...variable.textInputPrimary,
  },
  sliderThumbView: {
    width: 25,
    height: 25,
    borderRadius: 25,
    backgroundColor: theme.COLOR_WHITE,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: theme.COLOR_BLACK,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.20,
    shadowRadius: 1.41,
    elevation: 2,
  },
  sliderThumbMg: {
    top: -5,
  },
  sliderThumbMgLg: {
    top: -30,
  },
  gradientView: {
    height: 17,
  },
  framePlusMinusTextBox: {
    width: '100%',
    height: 40,
    zIndex: 999,
    alignItems: 'center',
    justifyContent: 'center',
  },
  framePlusMinusTextBoxSm: {
    height: 38,
    borderRadius: 5,
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_12,
  },
  plusMinuTextWidthSm: {
    width: 50,
  },
  plusMinuTextWidthLg: {
    width: 52,
  },
  attachmentIndicator: {
    minHeight: 100,
  },
  indToolBox: {
    minHeight: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkTextComSp: {
    marginTop: 5,
  },
  linkTextCom: {
    bottom: 0, 
    right: 0, 
    backgroundColor: "white", 
  },
  linkTextComMore: {
    position: "absolute", 
    bottom: 0, 
    right: 0, 
    backgroundColor: "white", 
    paddingLeft: 10,
    alignItems: 'flex-end', 
    flex: 1
  },
  zIndexCircle: {
    zIndex: 99,
  },
  MenuTriggerTouch: {
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  mainNavDefault: {
    borderLeftWidth: 3,
    borderLeftColor: theme.COLOR_TRANSPARENT,
    backgroundColor: theme.COLOR_TRANSPARENT
  },
  mainNavSelected: {
    borderLeftWidth: 3,
    borderLeftColor: theme.COLOR_ORANGE,
    backgroundColor: theme.COLOR_BLUE_BG_LIGHT
  },
  navListsubItemPd: {
    paddingLeft: 15,
  },
  profileAvatar: {
    width: 40,
    height: 40,
    borderRadius: 40,
    overflow: "hidden",
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarBg: {
    backgroundColor: theme.COLOR_ORANGE_6,
  },
  profileAvatarSm: {
    width: 30,
    height: 30,
    borderRadius: 30,
    overflow: "hidden",
    alignItems: 'center',
    justifyContent: 'center',
  },
  profileAvatarDefault: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center'
  },
  profileAvatarDefaultSm: {
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center'
  },
  profileMenuSection: {
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  profileMenuSub: {
    flex: 1,
    paddingLeft: 10,
  },
  sideBarMainProfile: {
    flexBasis: 70,
    maxHeight: 70
  },
  profileNameBlock: {
    paddingBottom: 3,
  },
  setupMenuSection: {
    flexBasis: 70,
    maxHeight: 70
  },
  setupMenuWrapper: {
    marginTop: 10,
    marginLeft: 15,
    marginRight: 15,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  setupMenuDetails: {
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 5,
    marginRight: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  setupMenuIcon: {
    width: 21,
    height: 22,
    resizeMode: "contain",
  },
  setupMenuTitle: {
    paddingLeft: 10,
    flex: 1,
  },
  mainMenuSpace: {
    paddingBottom: 15,
  },
  mobileMenuSpace: {
    paddingBottom: 80,
  },
  switchMenu: {
    position: 'relative'
  },
  ovalBtnTextView: {
    position: 'absolute',
    left: 13,
    top: 13,
    justifyContent: 'center'
  },
  ovalBtnTextViewCom: {
    position: 'absolute',
    alignItems: 'center',
    top: 13,
    width: '100%',
    justifyContent: 'center'
  },
  smPlusIcon: {
    width: 10,
    height: 10,
    resizeMode: "contain",
    tintColor: theme.COLOR_BLUE_7
  },
  smPlusIconView: {
    paddingRight: 5,
  },
  switchMenuDetails: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 235,
    position: 'relative'
  },
  responsiveMenuRow: {
    width: '100%',
    minHeight: 70,
    position: 'relative',
    flexDirection: "row",
  },
  navResponsiveLeft: {
    flex: 1,
    justifyContent: 'center',
  },
  navResponsiveRight: {
    flex: 1,
    justifyContent: 'center',
  },
  navResponsiveCenter: {
    flexBasis: 75,
    maxWidth: 75,
  },
  navLeftItemRow: {
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  navRightItemRow: {
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  navLinkItemView: {
    alignItems: "center",
    minHeight: 39,
    justifyContent: "space-between",
  },
  addMenuCom: {
    position: "absolute",
    bottom: 26,
    zIndex: 999999,
    alignItems: "center",
    justifyContent: "center",
    width: 70,
    height: 70
  },
  addMenuComView: {
    width: 70,
    height: 70,
    zIndex: 9999999
  },
  sliderValueWrapper: {
    position: 'relative',
    width: '100%',
    height: 25,
  },
  sliderThumbInfoView: {
    width: 25,
    height: 25,
    borderRadius: 5,
    backgroundColor: theme.COLOR_GREY_LIGHT_RG,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: theme.COLOR_BLACK,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.20,
    shadowRadius: 1.41,
    elevation: 2,
  },
  fieldColDetails: {
    flexBasis: 80,
    maxWidth: 80,
  },
  sliderRangeArea: {
    flex: 1,
    paddingTop: 5,
  },
  sliderTextFixed: {
    flex: 1,
  },
  durationSliderPd: {
    paddingTop: 45,
    paddingLeft: 10,
    paddingRight: 10,
  },
  colMgLtRt: {
    marginLeft: 5,
    marginRight: 5,
  },
  collageColSp: {
    width: 15,
  },
  collageCol: {
    flex: 1
  },
  collageRow: {
    flexDirection: 'row'
  }, 
  collageBox: {
    borderRadius: 8,
    backgroundColor: theme.COLOR_COLLAGE,
    flex: 1,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 15,
    paddingRight: 15,
  },
  collageTitle: {
    paddingBottom: 5,
  },
  collageRowSp: {
    paddingBottom: 15,
    width: '100%'
  },
  collageImgView: {
    minHeight: 120,
  },
  collageImgSmView: {
    flexBasis: 100,
    maxWidth: 100,
  },
  collageDescWrapper: {
    flex: 1,
    justifyContent: 'center',
  },
  weekListView: {
    minWidth: 280,
  },
  tooltipZindexLow: {
    zIndex: 9,
  },
  tooltipZindexHigh: {
    zIndex: 99,
  },
  fieldColSearch: {
    flex: 1,
  },
  pageTitleSection: {
    paddingTop: 20,
    paddingBottom: 15,
  },
  touchZoom: {
    paddingLeft: 15,
    paddingRight: 15,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  questionItemLtRtPd: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  premiumIcon: {
    width: 23,
    height: 23,
    resizeMode: "contain",
  },
  premiumIconSm: {
    width: 20,
    height: 20,
    resizeMode: "contain",
  },
  smPlusIconViewLg: {
    paddingRight: 7,
  },
  tooltipComSec: {
    backgroundColor: "transparent",
    position: 'relative',
  },
  tooltipTextWrapperCom: {
    position: "absolute",
    bottom: 35,
    backgroundColor: "transparent",
  },
  itemSingleRow: {
    flexDirection: 'row',
  },
  itemContentCol: {
    flex: 1,
  },
  itemIconSetCol: {
    flexBasis: 150,
    alignItems: "center",
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  itemIconSingle: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: 45,
    alignItems: "center",
  },
  itemIconSingleSm: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    // minWidth: 45,
    alignItems: "center",
  },
  itemSettingMenu: {
    minHeight: 60,
  },
  itemSettingMenuSm: {
    minHeight: 50,
  },
  textAreaSm: {
    paddingTop: 15,
    paddingLeft: 10,
    paddingBottom: 15,
    paddingRight: 10,
    ...variable.textFieldPrimary,
    height: 51,
    width: "100%",
  },
  premiumIconExLg: {
    width: 38,
    height: 38,
    resizeMode: "contain",
  },
  premiumCircleView: {
    position: 'absolute',
    left: -20,
    top: -10,
  },
  fieldContainerCom: {
    marginBottom: 10,
  },
  zIndexAutoCompleteSpec: {
    ...Platform.select({
      ios: {
        zIndex: 99999999,
      },
      web: {
        zIndex: 99999999,
      },
    }),
  },
  frameQuanitityPd: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  frameQuanitityBox: {
    width: 76,
  },
  dataWrapperLg: {
    height: '100%',
    overflow: 'hidden',
  },
  fieldColMeasure: {
    flexBasis: 124,
    maxWidth: 124
  },
  fieldColPickerCom: {
    flexBasis: 124,
    maxWidth: 124
  },
  fieldColSpCom: {
    flexBasis: 46,
    maxWidth: 46
  },
  basisStyle:{
    flexBasis: 0,
  },
  listDateRangeGraph: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingLeft: 5,
    paddingRight: 8,
  },
  fieldNameBorderSpec: {
    position: "absolute",
    zIndex: 9,
    height: 7,
    bottom: 3,
    width: '100%',
    ...Platform.select({
      ios: {
        height: 7,
        bottom: 3,
      },
      android: {
        height: 6,
        bottom: 4,
      },
    }),
  },
  listDateRangeGraphLg: {
    flexBasis: 152,
    maxWidth: 152,
  },
  listDateRangeGraphSm: {
    flexBasis: 110,
    maxWidth: 110,
  },
  listDateRangeGraphExSm: {
    flexBasis: 90,
    maxWidth: 90,
  },
  cropIcon: {
    width: 24,
    height: 22,
    resizeMode: "contain",
  },
  cropTouch: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 15,
    paddingBottom: 15,
  },
  cropTouchWrapper: {
    marginLeft: 5,
  },
  cropperWrapperSection: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  cropperWrapperTitle: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  cropperBtnWrapper: {
    paddingBottom: 5
  },
  cropperWrapperMain: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  logoutMenuSection: {
    flexBasis: 55,
    maxHeight: 55
  },
  logOutMenuSectionLg: {
    flexBasis: 80,
  },

  logoutMenuDetails: {
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 5,
    marginRight: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  // logoutMenuTitle: {
  //   flex: 1,
  // },
  logoutMenuWrapper: {
    marginTop: 10,
    marginLeft: 15,
    marginRight: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  logoutMenuIcon: {
    width: 21,
    height: 22,
    resizeMode: "contain",
  },

  symptomDataWrapper: {
    height: 'auto',
    overflow: 'hidden',
  },
  reminderChooseOne:{
    position:'absolute', 
    left:272, 
    bottom: 2
  },
  ActivitySliderCardView: {
    ...variable.textFieldPrimary,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 8,
    width:'100%',
  },
  fieldColAutoFill: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
  },
  autoFillInnerRow: {
    flexDirection: 'row',
    width: '15%',
  },
  buttonContainerAutoFillInnerRow: {
    flexDirection: 'row',
    width: '100%',
  },
  customWrpperHealth:{
    paddingBottom:20,
    
  }
};
