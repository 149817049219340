import React from 'react';
import {View, Text, Image} from 'react-native';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import styles from '../../Css';
import { pendoTrackEvent } from '../../util/pendoConfig';

const {Popover} = renderers;

const buttonPopupToDoMenu = props => {
  return (
    <>
      {props.disabled ? (
        <View style={[styles.itemIconSingle, styles.itemIconBtn]}>
          <Image
            style={[styles.playSmIcon]}
            source={require('../../assets/icons/play.png')}
          />
        </View>
      ) : (
        <Menu
          renderer={Popover}
          rendererProps={{
            placement: 'bottom',
          }}>
          <MenuTrigger onPress={() => pendoTrackEvent('To-Do@ > SaveAccordion')}>
            <View 
              style={[styles.itemIconSingle, styles.itemIconBtn]} 
              nativeID={'to-doSaveAccordion'}>
              <Image
                style={[styles.playSmIcon]}
                source={require('../../assets/icons/play.png')}
              />
            </View>
          </MenuTrigger>
          <MenuOptions style={[styles.dropDownList, styles.menuOptionsLg]}>
            <View style={{padding: 1}} nativeID={'to-doAddtoContact'}>
            <MenuOption
              style={[styles.liItem, styles.viewItemPd]}
              onSelect={() => props.addQuestionToStore("contact")}
              >
              <Text style={styles.listTextCom} allowFontScaling= {false}>+ Add to Contact</Text>
            </MenuOption>
            </View>
            <View style={{padding: 1}} nativeID={'to-doAddtoJournal'}>
            <MenuOption
              style={[styles.liItem, styles.viewItemPd]}
              onSelect={() => props.addQuestionToStore("journal_entry")}
              >
              <Text style={styles.listTextCom} allowFontScaling= {false}>+ Add to Entry</Text>
            </MenuOption>
            </View>
            <View style={{padding: 1}} nativeID={'to-doAddtoMedAct'}>
            <MenuOption
              style={[styles.liItem, styles.viewItemPd]}
              onSelect={() => props.addQuestionToStore("med_activity")}
              >
              <Text style={styles.listTextCom} allowFontScaling= {false}>+ Add to Med/Activity</Text>
            </MenuOption>
            </View>
          </MenuOptions>
        </Menu>
      )}
    </>
  );
};
export default buttonPopupToDoMenu;
