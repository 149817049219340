import React, {Component} from 'react';
import {View, Platform, Keyboard} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import styles from '../../../Css';
import Header from '../../../components/Header/header';
import Tabs from '../../../components/Tab/Tab';
import ConfirmationBox from '../../../components/ConfirmationBox/confirmationBox';
import ClinicalTrialMyList from '../../../components/Settings/ClinicalTrials/ClinicalTrialMyList/ClinicalTrialMyList';
import ClinicalTrialSearch from '../../../components/Settings/ClinicalTrials/ClinicalTrialSearch/ClinicalTrialSearch';
import ClinicalTrialResultList from '../../../components/Settings/ClinicalTrials/ClinicalTrialSearch/ClinicalTrialResultList';
import Modal from '../../../components/Modal/modal';
import ClinicalTrialFreeModalContent from '../../../components/Settings/ClinicalTrials/ClinicalTrialSearch/ModalContent/ClinicalTrialFreeModalContent';
import ClinicalTrialPremiumModalContent from '../../../components/Settings/ClinicalTrials/ClinicalTrialSearch/ModalContent/ClinicalTrialPremiumModalContent';
import SpaceBar from '../../../components/SpaceBar/SpaceBar';
import _ from 'underscore';
import {CLINICAL_TRIALS, PROFILE} from '../../../navigation/path';
import {getAccountType} from '../../../util/subscriptionInformation';
import {hasValue} from '../../../util/hashValueGenerator';
import SavingConfirmation from '../../../components/ConfirmationBox/savingConfirmation';
import {alertBodySpec, clinicaltrialTitle} from "../../../GlobalFunctions/GlobalString"
import {handleCommonBackNavigation} from '../../../navigation/RootNavigation';
import MenuOverlayContainer from '../../../components/PageLayout/MenuOverlayContainer';
import {clinicalTrialTabPath} from '../../../Constant/index';
import { pendoTrackEvent } from '../../../util/pendoConfig';
import { WEB } from '../../../util/platformWindowConfig';


let searchResultSavedSearchCount = 0;
let searchTearmQuary = '';

class ClinicalTrialScreen extends Component {
  constructor(props) {
    super(props);
    const accountType = getAccountType(props.userSubscription);
    if (this.props.activeTab !== 0) {
      this.props.setActiveTab(0);
    }
    this.state = {
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
      id: -1,
      savedSearch: '',
      savedSearchValue: '',
      condition: '',
      country: '',
      city: '',
      distance: '',
      studyStatus: [],
      phase: [],
      studyType: '',
      age: '',
      gender: '',
      sex: 'UNSPECIFIED',
      isNotify: false,
      notifyRadioButtonValue: '',
      validTitle: true,
      clinicalTrialSavedSearch: this.props.clinicalTrialSavedSearch
        ? this.props.clinicalTrialSavedSearch
        : [],
      search: _.isEmpty(this.props.searchResults) ? true : false,
      searchWait: false,
      confirmBox: false,

      //Search Result list
      searchResults: this.props.searchResults ? this.props.searchResults : {},
      visibleModal: false,
      accountType: accountType,
      saveWait: false,

      //My list
      myClinicalTrials: this.props.clinicalTrials
        ? this.props.clinicalTrials
        : [],
      isDeleteText: false, //handle Delete text visibility
      isSelectedDelete: false, //handle onPress delete radio button
      isDeleteAll: false,
      clinicalTrialIds: this.props.clinicalTrialIds,
      deleteConfirm: false,
      deleteWait: false,
      duplicateTitle: false,
    };
    this.hasValue = this.getHashValue();
  }

  componentDidMount = () => {
    //this.hasValue = this.getHashValue();
    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
      this.props.navigation.setParams({clinicalTrialTab: clinicalTrialTabPath.search});
      pendoTrackEvent('ClinicalTrials/@Search');
    });
    this.props.navigation.addListener('blur', () => {
      this.props.showMobileMenu();
    });
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const {
      clinicalTrials,
      searchResults,
      clinicalTrialSavedSearch,
      clinicalTrialIds,
      windowHeight,
      windowWidth,
      error,
      errorDescription,
    } = nextProps;
    this.setState(
      {
        myClinicalTrials: clinicalTrials,
        searchResults: searchResults,
        clinicalTrialSavedSearch: clinicalTrialSavedSearch,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        error: error,
        errorDescription: errorDescription,
        //searchWait: false,
        clinicalTrialIds: clinicalTrialIds,
        //deleteConfirm: false,
        deleteWait: false,
      },
      () => {
        if (!_.isEmpty(this.state.clinicalTrialIds)) {
          this.setState({isDeleteText: true});
        } else {
          this.setState({isDeleteText: false});
        }
      },
    );
  };

  getHashValue = () => {
    const {
      id,
      savedSearch,
      savedSearchValue,
      condition,
      country,
      city,
      distance,
      studyStatus,
      phase,
      studyType,
      age,
      gender,
      sex,
      notifyRadioButtonValue,
      validTitle,
    } = this.state;
    return hasValue(
      id +
        ',' +
        savedSearch +
        ',' +
        savedSearchValue +
        ',' +
        condition +
        ',' +
        country +
        ',' +
        city +
        ',' +
        distance +
        ',' +
        studyStatus +
        ',' +
        phase +
        ',' +
        studyType +
        ',' +
        age +
        ',' +
        gender +
        ',' +
        sex +
        ',' +
        notifyRadioButtonValue +
        ',' +
        validTitle,
    );
  }

  //Search
  handleOnSelectedValueChangeSavedSearch = value => {
    let filteredItem = this.state.clinicalTrialSavedSearch.filter(res =>
      Platform.OS === WEB ? res.id.toString() === value : res.id === value,
    );
    if (filteredItem.length !== 0) {
      this.setState({
        id: value,
        savedSearch: filteredItem[0].title,
        savedSearchValue: value,
        condition: filteredItem[0].conditions,
        country: filteredItem[0].country,
        city: filteredItem[0].city,
        distance: filteredItem[0].distance,
        studyStatus: filteredItem[0].studyStatus,
        phase: filteredItem[0].phase,
        studyType: filteredItem[0].studyType,
        age: filteredItem[0].ageGroup,
        gender: this.onSetGenderValue(filteredItem[0].sex),
        sex: filteredItem[0].sex,
        notifyRadioButtonValue: this.handleOnNotifyRadioValues(
          filteredItem[0].notify,
        ),
        validTitle: true,
      });
    } else {
      this.setState({
        id: -1,
        savedSearch: '',
        savedSearchValue: '',
        condition: '',
        country: '',
        city: '',
        distance: '',
        studyStatus: [],
        phase: [],
        studyType: '',
        age: '',
        gender: '',
        sex: 'UNSPECIFIED',
        notifyRadioButtonValue: '',
        validTitle: true,
      });
    }
  };

  handleOnChangeTextCondition = currentInput => {
    this.setState({condition: currentInput});
  };

  onItemPress = selection => {
    this.setState({
      condition: selection.name,
    });
  };

  handleOnSelectedValueChangeCountry = value => {
    this.setState({
      country: value,
    });
  };

  handleOnChangeTextCity = value => {
    this.setState({
      city: value,
    });
  };
  handleOnSelectedValueChangeDistance = value => {
    this.setState({
      distance: value,
    });
  };

  handleOnPressStudyStatus = value => {
    const found = this.state.studyStatus.find(res => res === value);
    let filteredStudyStatus = [...this.state.studyStatus];
    if (found) {
      filteredStudyStatus = filteredStudyStatus.filter(res1 => value !== res1);
    } else {
      filteredStudyStatus.push(value);
    }
    this.setState({
      studyStatus: filteredStudyStatus,
    });
  };

  handleOnPressPhase = value => {
    const found = this.state.phase.find(res => res === value);
    let filteredPhase = [...this.state.phase];
    if (found) {
      filteredPhase = filteredPhase.filter(res1 => value !== res1);
    } else {
      filteredPhase.push(value);
    }
    this.setState({
      phase: filteredPhase,
    });
  };

  handleOnSelectedValueChangeStudyType = value => {
    this.setState({
      studyType: value,
    });
  };
  handleOnChangeTextAge = value => {
    this.setState({
      age: value,
    });
  };
  handleOnSelectedValueChangeGender = value => {
    this.setState({
      gender: value,
      sex: this.onSetSexValue(value),
    });
  };

  onSetGenderValue = value => {
    switch (value) {
      case 'UNSPECIFIED':
        return '';
      case 'MALE':
        return 'Male';
      case 'FEMALE':
        return 'Female';
      default:
        return '';
    }
  };
  onSetSexValue = value => {
    switch (value) {
      case '':
        return 'UNSPECIFIED';
      case 'Male':
        return 'MALE';
      case 'Female':
        return 'FEMALE';
      default:
        return 'UNSPECIFIED';
    }
  };
  getStudyStatusSearchQuery = () => {
    let searchStudyStatus = [];
    if (this.state.studyStatus.length !== 0) {
      this.state.studyStatus.forEach(element => {
        switch (element) {
          case '0':
            return searchStudyStatus.push('b');
          case '1':
            return searchStudyStatus.push('a');
          case '2':
            return searchStudyStatus.push('f');
          case '3':
            return searchStudyStatus.push('d');
          case '4':
            return searchStudyStatus.push('g');
          case '5':
            return searchStudyStatus.push('h');
          case '6':
            return searchStudyStatus.push('e');
          case '7':
            return searchStudyStatus.push('i');
          case '8':
            return searchStudyStatus.push('m');
          default:
            return searchStudyStatus.push('');
        }
      });
    }
    let studyStatusSearchQuery = '?recrs=';
    if (this.state.studyStatus.length === 1) {
      studyStatusSearchQuery = studyStatusSearchQuery + searchStudyStatus[0];
    } else if (this.state.studyStatus.length > 1) {
      studyStatusSearchQuery = studyStatusSearchQuery + searchStudyStatus[0];
      for (let i = 1; i < this.state.studyStatus.length; i++) {
        studyStatusSearchQuery =
          studyStatusSearchQuery + '&recrs=' + searchStudyStatus[i];
      }
    }
    return studyStatusSearchQuery;
  };

  getPhaseSearchQuery = () => {
    let phaseSearchQuery = '';
    if (
      this.state.phase.length === 0 ||
      (this.state.phase.length === 1 && this.state.phase[0] === '-1')
    ) {
      phaseSearchQuery = '&phase=';
    } else {
      this.state.phase.forEach(
        element =>
          (phaseSearchQuery =
            phaseSearchQuery + '&phase=' + (element === '4' ? '0' : element)),
      );
    }
    return phaseSearchQuery;
  };
  handleOnSearch = () => {
    this.setState({searchWait: true}, () => {
      this.props.onResetSearchResults();
      if (this.props.clinicalTrials) {
        this.props.clinicalTrials.forEach(res => {
          let locationListString = '';
          res.locationList.forEach(location => {
            locationListString = locationListString + location + ',';
          });
          let clinicalTrialId =
            res.briefTitle +
            locationListString.substring(0, locationListString.length - 1);
          this.props.onAddClinicalTrialToMyList(clinicalTrialId, res.id);
        });
      }
      searchTearmQuary =
        this.getStudyStatusSearchQuery() +
        '&cond=' +
        this.state.condition +
      '&cntry=' +
        this.state.country +
        '&city=' +
        this.state.city +
        '&dist=' +
        this.state.distance +
        this.getPhaseSearchQuery() +
        '&type=' +
        this.state.studyType +
        '&age_v=' +
        this.state.age +
        '&gndr=' +
        this.state.gender +
        '&down_count=1000';

      let searchTearms = {
        id: this.state.id,
        savedSearch: this.state.savedSearch,
        savedSearchValue: this.state.savedSearchValue,
        condition: this.state.condition,
        country: this.state.country,
        city: this.state.city,
        distance: this.state.distance,
        studyStatus: this.state.studyStatus,
        phase: this.state.phase,
        studyType: this.state.studyType,
        age: this.state.age,
        gender: this.state.gender,
        sex: this.state.sex,
      };

      pendoTrackEvent('ClinicalTrials@ > Search');
      this.props.onSearchTearms(searchTearms);
      this.props.onSearchClinicalTrials(searchTearmQuary, (success, res) => {
        if (success) {
          searchResultSavedSearchCount = res.count ? res.count : 0;
          this.props.navigation.setParams({clinicalTrialTab: clinicalTrialTabPath.searchResults});
          pendoTrackEvent('ClinicalTrials/@SearchResults');
          this.setState({search: false});
        }
        this.setState({searchWait: false});
      }, searchTearms);
    });
  };

  //Result list
  handleOnDirectSearchClinicalTrials = () => {
    this.props.navigation.setParams({clinicalTrialTab: clinicalTrialTabPath.search});
    pendoTrackEvent('ClinicalTrials/@Search');
    this.setState({
      search: true,
      id: this.props.searchTearms ? this.props.searchTearms.id : -1,
      savedSearch: this.props.searchTearms
        ? this.props.searchTearms.savedSearch
        : '',
      savedSearchValue: this.props.searchTearms
        ? this.props.searchTearms.savedSearchValue
        : '',
      condition: this.props.searchTearms
        ? this.props.searchTearms.condition
        : '',
      country: this.props.searchTearms ? this.props.searchTearms.country : '',
      city: this.props.searchTearms ? this.props.searchTearms.city : '',
      distance: this.props.searchTearms ? this.props.searchTearms.distance : '',
      studyStatus: this.props.searchTearms
        ? this.props.searchTearms.studyStatus
        : [],
      phase: this.props.searchTearms ? this.props.searchTearms.phase : [],
      studyType: this.props.searchTearms
        ? this.props.searchTearms.studyType
        : '',
      age: this.props.searchTearms ? this.props.searchTearms.age : '',
      gender: this.props.searchTearms ? this.props.searchTearms.gender : '',
      sex: this.props.searchTearms
        ? this.props.searchTearms.sex
        : 'UNSPECIFIED',
    });
    if (!_.isEmpty(this.props.newClinicalTrials)) {
      this.props.onResetNewClinicalTrials();
    }
  };

  handleOnPressSaveSearchClinicalTrials = () => {
    this.setState({visibleModal: true});
  };

  //Modal content
  handleOnTextValueChangeTitle = value => {
    const nameList = this.state.clinicalTrialSavedSearch.map(({title}) => title);

    if (value) {
      if (nameList.includes(value.trim())) {
        this.setState({
          duplicateTitle: true,
        });
      } else {
        this.setState({
          validTitle: true,
          duplicateTitle: false,
        });
      }
    } else {
      this.setState({
        validTitle: false,
      });
    }
    this.setState({
      savedSearch: value,
    });
  };
  handleOnSaveSearchOk = () => {
    Keyboard.dismiss();
    let updateSavedSearch = {
      savedSearch: {
        id: this.state.id,
        createdOn: null,
        modifiedOn: null,
        artifactId: null,
        conditions: this.state.condition,
        country: this.state.country,
        city: this.state.city,
        distance: this.state.distance,
        studyStatus: this.state.studyStatus,
        phase: this.state.phase,
        studyType: this.state.studyType,
        ageGroup: this.state.age,
        sex: this.state.sex,
        title: this.state.savedSearch,
        isNotify: this.state.isNotify,
        count: searchResultSavedSearchCount,
      },
    };
    this.setState({saveWait: true});
    this.props.onSaveSearchClinicalTrial(
      updateSavedSearch,
      () => {
        this.setState({
          saveWait: false,
          visibleModal: false,
          savedSearch: '',
          notifyRadioButtonValue: '',
          duplicateTitle: false,
        });
      },
      this.props.journalUserId,
    );
  };

  redirectPremiumAccount = () => {
    this.setState({visibleModal: false});
    this.props.navigation.push(
      PROFILE.profilePremiumAccountPath, {
        from: CLINICAL_TRIALS.path,
      }
    );
  };

  handleOnPressRadioFormNotify = value => {
    this.setState({
      notifyRadioButtonValue: value,
    });
    switch (value) {
      case 0:
        return false;
      case 1:
        return true;
      default:
        return false;
    }
  };

  handleOnNotifyRadioValues = value => {
    this.setState({
      isNotify: value,
    });
    switch (value) {
      case false:
        return 0;
      case true:
        return 1;
      default:
        return '';
    }
  };
  //My list
  handleDeleteClinicalTrials = () => {
    this.setState({deleteWait: true});
    this.props.onDeleteClinicalTrialList(
      Object.values(this.state.clinicalTrialIds),
      sucess => {
        if (sucess) {
          this.props.onResetClinicalTrials();
        }
        this.setState({
          deleteConfirm: false,
          deleteWait: false,
          isSelectedDelete: false,
          isDeleteText: false,
        });
      },
      this.props.journalUserId,
    );
  };

  handleDeleteAllClinicalTrials = () => {
    this.setState({deleteWait: true});
    this.props.onDeleteAllClinicalTrials(success => {
      if (success) {
        if (!_.isEmpty(this.state.clinicalTrialIds)) {
          this.props.onResetClinicalTrials();
          this.props.onResetNewClinicalTrials();
        }
      }
      this.setState({
        deleteConfirm: false,
        deleteWait: false,
        isSelectedDelete: false,
        isDeleteText: false,
      });
    }, this.props.journalUserId);
  };

  handleUIDeleteAllCancel = () => {
    this.props.onResetClinicalTrials();
    this.setState({
      deleteConfirm: false,
      isSelectedDelete: false,
      isDeleteAll: false,
      isDeleteText: false,
    });
  };

  handleDeleteClinicalTrialsConfirmation = () => {
    if (this.state.myClinicalTrials !== null) {
      if (this.state.myClinicalTrials.length !== 0) {
        this.setState(
          prevState => ({
            isSelectedDelete: !prevState.isSelectedDelete,
          }),
          () => {
            if (this.state.isSelectedDelete) {
              if (_.isEmpty(this.state.clinicalTrialIds)) {
                this.setState({isDeleteAll: true, isDeleteText: true});
                this.state.myClinicalTrials.forEach(myClinicalTrial => {
                  this.props.onAddClinicalTrialToDelete(myClinicalTrial.id);
                });
              } else {
                if (
                  this.state.myClinicalTrials.length ===
                  Object.keys(this.state.clinicalTrialIds).length
                ) {
                  this.setState({isDeleteAll: true, isDeleteText: true});
                } else {
                  this.setState({isDeleteAll: false, isDeleteText: true});
                }
              }
              this.setState({deleteConfirm: true});
            }
          },
        );
      }
    }
  };

  processData = res => {
    let termsArray = [];
    if (res) {
      const suggestionResults = [...res];
      termsArray = suggestionResults.map(value => {
        return {name: value, data: value};
      });
    }

    return termsArray;
  };

  onItemPressCustom = selection => {
    this.setState({
      condition: selection,
    });
  };

  handleCancelSearch = () => {
    if (this.hasValue === this.getHashValue()) {
      handleCommonBackNavigation(this.props.navigation);
    } else {
      this.setState({confirmBox: true});
    }
  };

  selectedTab = tabid => {
    this.props.setActiveTab(tabid);
    if (tabid === 1) {
      pendoTrackEvent('ClinicalTrials > @MyList');
      this.props.navigation.setParams({clinicalTrialTab: clinicalTrialTabPath.myList});
      pendoTrackEvent('ClinicalTrials/@MyList');
    } else if (tabid === 0 && this.state.search) {
      pendoTrackEvent('ClinicalTrials > @Search');
      this.props.navigation.setParams({clinicalTrialTab: clinicalTrialTabPath.search});
      pendoTrackEvent('ClinicalTrials/@Search');
    } else {
      pendoTrackEvent('ClinicalTrials > @Search');
      this.props.navigation.setParams({clinicalTrialTab: clinicalTrialTabPath.searchResults});
      pendoTrackEvent('ClinicalTrials/@SearchResults');
    }
  };

  render() {
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => {
            this.handleCancelSearch();
          }}
          index={CLINICAL_TRIALS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        {this.hasValue !== this.getHashValue() &&
          <MenuOverlayContainer
            onPressOpen={() => {
              this.setState({confirmBox: true});
            }}
          />
        }
        <View style={[styles.gridSectionView]}>
          <View style={[styles.containerTabView]}>
            <Tabs 
              pageContainer={true} 
              smScreenFull={true}
              activeTab={this.props.activeTab}
              tabIndex={this.selectedTab}
            >
              <View 
                title="Search" 
                style={[styles.tabContentSection]}
                nativeId={'clinicalTrialsSearch'}
              >
                {Boolean(this.state.search) ? (
                  <ClinicalTrialSearch
                    savedSearchValue={this.state.savedSearchValue}
                    saveSearchList={this.state.clinicalTrialSavedSearch}
                    onSelectedValueChangeSavedSearch={value =>
                      this.handleOnSelectedValueChangeSavedSearch(value)
                    }
                    condition={this.state.condition}
                    onChangeTextCondition={value =>
                      this.handleOnChangeTextCondition(value)
                    }
                    // termsArray={termsArray}
                    onItemPress={this.onItemPress}
                    country={this.state.country}
                    onValueChangeCountry={
                      this.handleOnSelectedValueChangeCountry
                    }
                    city={this.state.city}
                    onChangeTextCity={this.handleOnChangeTextCity}
                    distance={this.state.distance}
                    onValueChangeDistance={value =>
                      this.handleOnSelectedValueChangeDistance(value)
                    }
                    studyStatus={this.state.studyStatus}
                    onPressStudyStatus={value =>
                      this.handleOnPressStudyStatus(value)
                    }
                    phase={this.state.phase}
                    onPressPhase={value => this.handleOnPressPhase(value)}
                    studyType={this.state.studyType}
                    onValueChangeStudyType={value =>
                      this.handleOnSelectedValueChangeStudyType(value)
                    }
                    age={this.state.age}
                    onChangeTextAge={this.handleOnChangeTextAge}
                    gender={this.state.gender}
                    onValueChangeGender={value =>
                      this.handleOnSelectedValueChangeGender(value)
                    }
                    searchWait={this.state.searchWait}
                    onSearch={this.handleOnSearch}
                    windowHeight={this.state.windowHeight}
                    processData={this.processData}
                    journalUserId={this.props.journalUserId}
                    onItemPressCustom={this.onItemPressCustom}
                    hasNotch={this.props.hasNotch}
                    timeZone={this.props.timeZone}
                    timeZoneWarning={this.props.timeZoneWarning}
                  />
                ) : (
                  <ClinicalTrialResultList
                    searchResults={this.state.searchResults}
                    onPressSaveSearchClinicalTrials={
                      this.handleOnPressSaveSearchClinicalTrials
                    }
                    onDirectSearchClinicalTrials={
                      this.handleOnDirectSearchClinicalTrials
                    }
                    searchTearmQuary={searchTearmQuary}
                  />
                )}
              </View>
              <View 
                title="My List" 
                style={[styles.tabContentSection]}
                nativeId={'clinicalTrialsMyList'}
              >
                <ClinicalTrialMyList
                  myClinicalTrials={this.state.myClinicalTrials}
                  isSelectedDelete={this.state.isSelectedDelete}
                  isDeleteText={this.state.isDeleteText}
                  onDeleteClinicalTrialsConfirmation={
                    this.handleDeleteClinicalTrialsConfirmation
                  }
                />
              </View>
            </Tabs>
          </View>
        </View>
        {this.state.accountType === 'regular' ? (
          <Modal
            visible={this.state.visibleModal}
            onRequestClose={() => {
              this.setState({visibleModal: false, duplicateTitle: false, savedSearch: ''});
            }}
            onBackdropPress={() => {
              this.setState({visibleModal: false, duplicateTitle: false, savedSearch: ''});
            }}>
            <ClinicalTrialFreeModalContent
              handleOnSaveSearchOk={this.handleOnSaveSearchOk}
              title={this.state.savedSearch}
              savedSearchId={this.state.id}
              validTitle={this.state.validTitle}
              onTextValueChangeTitle={value =>
                this.handleOnTextValueChangeTitle(value)
              }
              saveSearchList={this.state.clinicalTrialSavedSearch}
              redirectPremiumAccount={this.redirectPremiumAccount}
              saveWait={this.state.saveWait}
              close={() => {
                this.setState({visibleModal: false, duplicateTitle: false, savedSearch: ''});
              }}
              journalUserId={this.props.journalUserId}
              duplicateTitle={this.state.duplicateTitle}
            />
          </Modal>
        ) : (
          <Modal
            visible={this.state.visibleModal}
            onRequestClose={() => {
              this.setState({visibleModal: false, duplicateTitle: false, savedSearch: ''});
            }}
            onBackdropPress={() => {
              this.setState({visibleModal: false, duplicateTitle: false, savedSearch: ''});
            }}>
            <ClinicalTrialPremiumModalContent
              handleOnSaveSearchOk={this.handleOnSaveSearchOk}
              title={this.state.savedSearch}
              validTitle={this.state.validTitle}
              onTextValueChangeTitle={value =>
                this.handleOnTextValueChangeTitle(value)
              }
              isNotify={this.state.notifyRadioButtonValue}
              onPressRadioFormNotify={value =>
                this.setState({
                  isNotify: this.handleOnPressRadioFormNotify(value),
                })
              }
              saveWait={this.state.saveWait}
              close={() => {
                this.setState({visibleModal: false, duplicateTitle: false, savedSearch: ''});
              }}
              duplicateTitle={this.state.duplicateTitle}
            />
          </Modal>
        )}
        {this.state.deleteConfirm &&
        <ConfirmationBox
          visibleModal={this.state.deleteConfirm}
          onBackdropPress={() => {
            if (this.state.isDeleteAll) {
              this.handleUIDeleteAllCancel();
            } else {
              this.setState({deleteConfirm: false, isSelectedDelete: false});
            }
          }}
          onRequestClose={() => {
            if (this.state.isDeleteAll) {
              this.handleUIDeleteAllCancel();
            } else {
              this.setState({deleteConfirm: false, isSelectedDelete: false});
            }
          }}
          alertTitle={
            !this.state.isDeleteAll
              ? 'Delete Clinical Trials from My List'
              : 'Delete All Clinical Trials from My List'
          }
          handleDelete={
            !this.state.isDeleteAll
              ? this.handleDeleteClinicalTrials
              : this.handleDeleteAllClinicalTrials
          }
          deleteWait={this.state.deleteWait}
        />
        }
        {this.state.confirmBox &&
        <SavingConfirmation
          visibleModal={this.state.confirmBox}
          onBackdropPress={() => {
            this.setState({confirmBox: false});
          }}
          onRequestClose={() => {
            this.setState({confirmBox: false});
          }}
          alertBody={alertBodySpec}
          alertTitle={clinicaltrialTitle}
          btnTextTwo={"Search"}
          btnTextOne={"Cancel"}
          handleCancel={()=> {
            this.setState({confirmBox: false});
            handleCommonBackNavigation(this.props.navigation)
          }}
          handleConfirm={() => {
            this.setState({confirmBox: false});
            this.handleOnSearch();
          }}
          confirmWait={this.state.searchWait}
          disabled={this.state.searchWait}
        />
        }
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    clinicalTrials: state.routines.clinicalTrials,
    clinicalTrialSavedSearch: state.routines.clinicalTrialSavedSearch,
    searchResults: state.clinicalTrials.searchResults,
    searchTearms: state.clinicalTrials.searchTearms,
    userSubscription: state.routines.userSubscription,
    clinicalTrialIds: state.clinicalTrials.clinicalTrials,
    newClinicalTrials: state.clinicalTrials.newClinicalTrials,
    journalUserId: state.uistate.journalUserId,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    errorDescription: state.routines.errorDescription,
    error: state.routines.error,
    activeTab: state.surveys.activeTab,
    hasNotch: state.uistate.hasNotch,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSearchClinicalTrials: (searchTearm, callBack, searchTearms) =>
      dispatch(actions.searchClinicalTrials(searchTearm, callBack, searchTearms)),
    onSaveSearchClinicalTrial: (
      savedSearchClinicalTrial,
      callBack,
      journalUserId,
    ) =>
      dispatch(
        actions.saveSearchClinicalTrial(
          savedSearchClinicalTrial,
          callBack,
          journalUserId,
        ),
      ),
    onDeleteAllClinicalTrials: (callBack, journalUserId) =>
      dispatch(actions.deleteAllClinicalTrials(callBack, journalUserId)),
    onAddClinicalTrialToDelete: id =>
      dispatch(actions.addClinicalTrialToDelete(id)),
    onResetNewClinicalTrials: () => dispatch(actions.resetNewClinicalTrials()),
    onResetClinicalTrials: () => dispatch(actions.resetClinicalTrials()),
    onDeleteClinicalTrialList: (clinicalTrialIdList, callBack, journalUserId) =>
      dispatch(
        actions.deleteClinicalTrialList(
          clinicalTrialIdList,
          callBack,
          journalUserId,
        ),
      ),
    onSearchTearms: searchTearms =>
      dispatch(actions.searchTearms(searchTearms)),
    onResetSearchResults: () => dispatch(actions.resetSearchResults()),
    onAddClinicalTrialToMyList: (id, newClinicalTrial) =>
      dispatch(actions.addClinicalTrialToMyList(id, newClinicalTrial)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    setActiveTab: activeTabIndex =>
      dispatch(actions.setActiveTab(activeTabIndex)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClinicalTrialScreen);
