import {pendoClearVisitor, pendoTrackEvent} from "../../util/pendoConfig";
import {DASHBOARD} from "../../navigation/path";
import {Platform} from "react-native-web";
import {setJournalUser, clearNotifInterval, setGraphChecked, } from "./uistate";
import {resetDataLogout, setSelectedJournalEntry, } from "./journal"
import {resetSetupState, } from "./setup"
import {resetNewJournal, } from "./routineTemplate"
import {resetPeerToPeer, clearTaskGetLatestRequestList} from "./peerToPeer"
import {resetHealthJourneyEvents, } from "./healthJourney"
import {resetFilteringData, } from "./healthJourneyFiltering"
import {resetFilteringObjectData, } from "./healthJourneyFilterObjects"

function resetMiscApplicationState(dispatch, graphChecked) {
  dispatch(resetSetupState(() => {}));
  dispatch(setSelectedJournalEntry(null));
  dispatch(resetNewJournal());
  dispatch(clearNotifInterval());
  dispatch(resetPeerToPeer());
  dispatch(clearTaskGetLatestRequestList());
  dispatch(resetHealthJourneyEvents());
  dispatch(resetFilteringData());
  dispatch(resetFilteringObjectData());
  if (graphChecked) {
    dispatch(setGraphChecked(false));
  }
}

function navigateToDashboardAfterProfileReset(navigation) {
  navigation.reset({
    index: 0,
    routes: [{name: DASHBOARD.path}],
  });
}

function trackProfileChangeEvent(trackEventTag) {
  pendoTrackEvent(trackEventTag);
  if (Platform.OS !== 'web') {
    pendoClearVisitor();
  }
}

export const changeJournalUser = (grantingUser, navigation, sharedAccessDigestProcessed, graphChecked, trackEventTag) => {
  return (dispatch, state) => {
    const {sharedAccessUserSummaries} = sharedAccessDigestProcessed;
    const name = sharedAccessUserSummaries?.[grantingUser]?.name ?? '';
    trackProfileChangeEvent(trackEventTag);
    dispatch(setJournalUser(grantingUser, name));
    dispatch(resetDataLogout());
    resetMiscApplicationState(dispatch, graphChecked);
    navigateToDashboardAfterProfileReset(navigation);
  };
};

export const setUpOwnJournal = (prevJournalUserId, navigation, sharedAccessDigestProcessed, graphChecked, trackEventTag) => {
  return (dispatch, state) => {
    trackProfileChangeEvent(trackEventTag);
    dispatch(setJournalUser(null, null));
    dispatch(resetDataLogout());
    if (prevJournalUserId) {
      resetMiscApplicationState(dispatch, graphChecked);
    }
    navigateToDashboardAfterProfileReset(navigation);
  };
};