import React, {Component} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

class phoneInput extends Component {
  componentDidMount() {
    var target = document.getElementsByClassName(' flag-dropdown');

    const config = {attributes: true, childList: false, subtree: false};

    // Callback function to execute when mutations are observed
    const callback = (mutationsList, observer) => {
      // Use traditional 'for loops' for IE 11
      for (let mutation of mutationsList) {
        const className = mutation.target.getAttribute('class');
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class' &&
          className === ' flag-dropdown'
        ) {
          this.props.setOpenCountyPopup(false);
        } else if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class' &&
          className === ' flag-dropdown open'
        ) {
          this.props.setOpenCountyPopup(true);
        } else {
          this.props.setOpenCountyPopup(false);
        }
      }
    };
    const observer = new MutationObserver(callback);

    observer.observe(target[0], config);
  }
  render() {
    return (
      <PhoneInput
        ref={ref => this.phone = ref}
        inputProps={{
          name: 'Phone Number',
          required: true,
          autoFocus: this.props.emailVerified && true,
          ...this.props.inputProps || {}
        }}
        country={this.props.country ? this.props.country : 'us'}
        onChange={(value, country) => {
          // let code;
          // if (country.countryCode === 'ca') {
          //   code = country.dialCode + ' (437)';
          // } else if (value.includes('437')) {
          //   code = country.dialCode;
          // } else {
          //   code = value;
          // }
          this.props.onChange(value);
        }}
        inputStyle={{
          width: '100%',
          borderWidth: 0,
          height: 49,
          fontSize: 16,
          color: '#404041',
          fontWeight: 'bold',
        }}
        dropdownStyle={{
          maxHeight: 160,
        }}
        buttonStyle={{borderWidth: 0, backgroundColor: '#ffffff'}}
        inputClass={'telCode'}
        value={this.props.value}
        disabled={this.props.disabled}
      />
    );
  }
}

export default phoneInput;
