import React from 'react';
import {View, Image, TouchableOpacity, Text} from 'react-native';
import styles from '../../../../Css';
import ProfileImageUploadPopup from '../../../Profile/profileImageUploadPopup';

const ProfileImage = props => {
  const {
    profileImage,
    imageLoading,
    uri,
    visibleEditProfilePhotoModal,
    imageUpload,
    getImageUrl,
    userAvatarName,
    onRequestClose,
    imageLoadingError,
    onUploadSucess,
    onPress,
    nativeId,
    onChangeProfileImage,
    tempProfileImage,
    profilePicRemoved,
    removeProfilePic,
    handleOnUploadProfileImage,
    fileUploadWait,
    uploadError,
    uploadErrorMesage,
    disableUploadProfile
  } = props;

  return (
    <>
      <TouchableOpacity onPress={onPress} nativeID={nativeId} disabled={disableUploadProfile}>
        <View style={[styles.peerProfileImg]}>
          {profileImage ? (
            <Image
              style={styles.profilePeerIcon}
              source={{uri: profileImage}}
            />
          ) : imageLoading && uri ? (
            <Image
              style={styles.profilePeerIcon}
              source={uri}
              onError={imageLoadingError}
            />
          ) : userAvatarName ? (
            <View style={styles.peerProfileCircle}>
              <View style={styles.peerProfileTextWrapper}>
                <Text style={[styles.textSpec, styles.textAlignCenter, styles.profileAvatarText]} allowFontScaling= {false}>
                  {userAvatarName.toUpperCase()}
                </Text>
              </View>
            </View>
          ) : (
            <Image
              style={styles.profilePeerIcon}
              source={require('../../../../assets/peer-to-peer/profile-default.png')}
            />
          )}
        </View>
        <View style={styles.plusSecView}>
          <Image
            style={styles.plusExSm}
            source={require('../../../../assets/icons/more-white.png')}
          />
        </View>
      </TouchableOpacity>
      <ProfileImageUploadPopup
        visibleEditProfilePhotoModal={visibleEditProfilePhotoModal}
        onRequestClose={onRequestClose}
        profileImage={profileImage}
        onUploadSucess={onUploadSucess}
        peer2peer={true}
        imageUpload={imageUpload}
        getImageUrl={getImageUrl}
        imageLoading={imageLoading}
        uri={uri}
        userAvatarName={userAvatarName}
        onChangeProfileImage={onChangeProfileImage}
        tempProfileImage={tempProfileImage}
        removeProfilePic={removeProfilePic}
        profilePicRemoved={profilePicRemoved}
        handleOnUploadProfileImage={handleOnUploadProfileImage}
        fileUploadWait={fileUploadWait}
        uploadError={uploadError}
        uploadErrorMesage={uploadErrorMesage}
        imageLoadingError={imageLoadingError}
        cancelCropping={props.cancelCropping}
      />
    </>
  );
};
export default (ProfileImage);
