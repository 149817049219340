import React from "react";
import { View, ScrollView } from "react-native";
import stylesResponsive from "../../../../CssResponsive";
import styles from "../../../../Css";
import AddButton from "../../../RoutineTemplate/ModalContent/addButton";
import TitleBlock from "../../../RoutineTemplate/ModalContent/titleBlock";
import ContentBlock from "../../../RoutineTemplate/ModalContent/contentBlock";
import ModelCloseButton from "../../../RoutineTemplate/ModalContent/modelCloseButton";
import ModalTemplate from "../../../RoutineTemplate/ModalContent/modalTemplate";
import NoResultBlock from "../../../RoutineTemplate/ModalContent/noResultBlock";
import { pendoTrackEvent } from "../../../../util/pendoConfig";
import {sortAlphabeticalOrder} from '../../../../util/commonUiLogic';

const graphJournalEntrytypeModal = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {
    journalEntryTypes,
    closeModal,
    onCreateNewGraph,
    onCreateGraphFromRoutine,
    onCreateNewRoutine,
  } = props;
  const filteredJournalEntryTypes = journalEntryTypes
    ? journalEntryTypes &&
      journalEntryTypes.filter(
        (entryType) =>
          entryType.userCanEdit &&
          entryType.isActive &&
          !entryType.isAppointment &&
          entryType.isHidden === false &&
          entryType.type !== "DEVICE"

      )
    : [];
  return (
    <React.Fragment>
      <View style={[stylesRes.modalScrollBlockSm]} dataSet={{media: ids.modalScrollBlockSm}}>
        <ModelCloseButton closeModal={closeModal} />
        <ModalTemplate>
          <AddButton
            onPressAddButton={() => {
              onCreateNewGraph();
              pendoTrackEvent('Graphs > CreateNew');
            }}
            btnText={"+ Create a New Graph"}
            topButton={true}
            nativeId={'graphsCreateNew'}
          />
          <TitleBlock titleText={"Create a new graph from a Routine"} />
          <View style={styles.resultSectionWrapper}>
          <ScrollView style={styles.modalScroller}>
              {filteredJournalEntryTypes &&
              filteredJournalEntryTypes.length === 0 ? (
                <NoResultBlock onPressRoutineWizard={onCreateNewRoutine} />
              ) : (
                sortAlphabeticalOrder(filteredJournalEntryTypes)
                  .map((res) => (
                    <ContentBlock
                      entryType={res}
                      onPressRoutine={() => {
                        onCreateGraphFromRoutine(res.id, res.graphId);
                        pendoTrackEvent('Graphs > Createfromroutine');
                      }}
                      nativeId={'graphsCreatefromroutine'}
                    />
                  ))
              )}
            </ScrollView>
          </View>
        </ModalTemplate>
      </View>
    </React.Fragment>
  );
};

export default (graphJournalEntrytypeModal);
