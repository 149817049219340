import React, { Component, Fragment } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import HealthJourneyEventCard from "./HealthJourneyEventCard";
import HealthJourneyYearCollapse from "./HealthJourneyYearCollapse";
import * as actions from '../../../store/actions/index';
import * as constants from '../HealthJourneyConstant';
class HealthJourneyListItem extends Component {
  state = {
    open: false,
    forceOpenYearData: {}
  };

  static getDerivedStateFromProps(props, state) {

    if (props.forceOpen !== state.open) {
      return true;
    }

    return null;
  }

  componentDidUpdate(prevProps) {

    const { forceOpen, openedYearAndId = {}, year, onYearHanlder } = this.props;
    const { open, forceOpenYearData } = this.state;

    if (forceOpen !== open) {
      this.setState({ open: forceOpen }, () => {
        if (!forceOpen) {
          this.setState({ forceOpenYearData: {} });
        }
      })
    }

    if (openedYearAndId && openedYearAndId.year === year && JSON.stringify(forceOpenYearData) !== JSON.stringify(openedYearAndId) && (!forceOpenYearData.year || openedYearAndId.id !== forceOpenYearData.id) && !open) {

      this.setState({ forceOpenYearData: openedYearAndId }, () => {
        onYearHanlder(year, false);
      })
    }
  }

  getUserData = type => {
    const {userSymptoms, userTreatments, userMetrics} = this.props;
    switch (type) {
      case constants.eventTypes.MEDICATIONS:
       return userTreatments;
      case constants.eventTypes.HEATHDATA:
        return userMetrics;
      case constants.eventTypes.SYMPTOMS:
        return userSymptoms;
      default:
        return {};
    }
  };

  getSingleCard = (card, idx) => {
    const {startDate, id} = card;
    const {editEvent, deleteEvent, setIdForExport, exportedList, exportOnGoing, updateLayoutDimentions, activeBubbleId, onPressViewItem, openedYearAndId, journalEntriesProcessed, disbleOperations, setActiveBubble, windowWidth, year, data} = this.props;
    const journalEntries = Object.values(journalEntriesProcessed);

    return (
      <View style={{zIndex: 9999 - idx + (parseInt(year) + data.length)}} key={id} onLayout={e => updateLayoutDimentions(startDate.split("-")[0], id, e.nativeEvent.layout)}>
        <HealthJourneyEventCard
          data={card}
          editEvent={editEvent}
          deleteEvent={deleteEvent}
          setIdForExport={setIdForExport}
          exportedList={exportedList}
          exportOnGoing={exportOnGoing}
          userData={this.getUserData(card.categoryType)}
          onPressViewItem={onPressViewItem}
          openedYearAndId={openedYearAndId}
          journalEntries={journalEntries}
          disbleOperations={disbleOperations}
          onPressCollapse={this.onPressCollapse}
          setActiveBubble={setActiveBubble}
          activeBubbleId={activeBubbleId}
          windowWidth={windowWidth}
          eventCountPerYear={data.length}
        />
      </View>
    )
  }

  onPressCollapse = () => {
    const { open } = this.state;
    const {openedYearAndId, setOpenedYearAndId, selectedDropdownItemNumber, isFiltersAvailable, 
      handleOpenHealthJourneyList, onYearHanlder, year } = this.props;

    if (open && openedYearAndId) {
      setOpenedYearAndId(null);
    } 

    if(open && isFiltersAvailable && selectedDropdownItemNumber.length ) {
      setOpenedYearAndId(null);
      // handleOpenHealthJourneyList();
    }


    onYearHanlder(year, open);
    // this.setState(prevState => ({ open: !prevState.open }));
  }

  render() {
    const { data, year } = this.props;
    const { open } = this.state;

    return (
      <Fragment>
        <HealthJourneyYearCollapse
          year={year}
          open={open}
          onPress={this.onPressCollapse}
        />
        {open && data.map((item, idx) => this.getSingleCard(item, idx))}
      </Fragment>
    );
  }
};

const mapStateToProps = state => {
  return {
    openedYearAndId: state.uistate.openedYearAndId,
    userSymptoms: state.routines.symptomsDigestProcessed.userSymptoms,
    userTreatments: state.routines.treatmentsProcessed,
    userMetrics: state.routines.metricsDigestProcessed.userDefinedMetrics,
    activeBubbleId: state.uistate.activeBubbleId,
    windowWidth: state.uistate.windowWidth,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    selectedDropdownItemNumber: state.healthJourneyFiltering.selectedDropdownItemNumber,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setOpenedYearAndId: data => dispatch(actions.setOpenedYearAndId(data)),
    setActiveBubble: id => dispatch(actions.setActiveBubble(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthJourneyListItem);