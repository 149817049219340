import React, { Component } from "react";
import { View, Text, Image, TextInput, TouchableOpacity, Platform } from "react-native";
import TickButton from "../../CheckBox/CheckBox";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";
import { formatDate } from "../../../util/formatDate";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import ActivityIndicator from "../../ActivityIndicator/activityIndicator";
import QuestionMainAnswers from "../QuestionMainAnswers";
import UIText from "../../../components/Text/text";
import PlusButtonCom from "../../../components/Button/plusButtonCom";
import { postedUserName } from '../Util/PostedUserNameUtils'

const DATE_FORMAT = "dd mm 'YY";
class Question extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      answers: this.props.question ? this.props.question.answers : null,
      ticked: this.props.question ? this.props.question.closed : null,
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      answer: "",
      addAnswer: false,
      wait: false,
      hideComments: true,
      saveWait: false,
      validComment: true,
      commentButtonEnable: true
    };
  }

  onPressAddComment = () => {
    if(this.state.commentButtonEnable) {
      this.setState({ 
        addAnswer: !this.state.addAnswer, 
        validComment: true, 
        commentButtonEnable: false 
      }, () => {
        if (this.textInput) {
          this.textInput.focus();
        }
      });
    } else {
      if (this.textInput) {
        this.textInput.focus();
      }
    }
  };

  onChangeTextComment = (val) => {
    this.setState({ answer: val, validComment: true });
  };

  saveAnswer = () => {
    let answer = {};
    if (this.state.answer && this.state.answer.trim() !== "") {
      this.setState({ saveWait: true });
      answer = {
        healthQuestionId: this.props.question.id,
        healthQuestionAnswer: {
          id: -1,
          content: this.state.answer,
        },
      };

      this.props.onUpdateHealthQuestionAnswer(
        answer,
        (success, answer) => {
          if (success) {
            let answers = this.state.answers;
            if (!this.state.answers.find((res) => res.id === answer.id)) {
              answers.push(answer);
            }
            this.setState({
              addAnswer: false,
              answer: "",
              answers: answers,
              wasaveWaitit: false,
              commentButtonEnable: true
            });
          }

          this.setState({
            addAnswer: false,
            saveWait: false,
          });
        },
        this.props.journalUserId
      );
    } else {
      this.setState({ validComment: false });
    }
  };

  handleCheckboxQuestion = (value) => {
    this.setState({ wait: true });
    const healthQuestion = this.props.question;
    healthQuestion.closed = value;
    healthQuestion.editting = false;
    let req = {
      artifactId: this.props.parentArtifactId,
      healthQuestion: healthQuestion,
    };

    this.props.onUpdateHealthQuestion(
      req,
      this.props.questionDigest,
      (success, message) => {
        if (success) {
          this.setState({ ticked: value });
        } else {
          // Alert.alert(message);
        }
        this.setState({ wait: false });
      },
      this.props.journalUserId
    );
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    let waitAnswer = null;
    if (this.state.saveWait) {
      waitAnswer = (
        <View style={[styles.actIndCom]}>
          <ActivityIndicator size="small" pdZero={true} />
        </View>
      );
    }
    
    let wait = null;
    if (this.state.wait) {
      wait = <ActivityIndicator size="small" pdZero={true} />;
    }
    return (
      <View
        key={this.props.key}
        style={[styles.questionRowComSpec, styles.questSectionSpecCom]}
      >
        <View style={[styles.questionDetailSec, styles.commentBoxViewCom]}>
          <View style={[styles.toDoContWrapper]}>
            <View style={[styles.questionItemLtRtPd]}>
              <View style={[styles.questionTitleViewSm]}>
                <View style={[styles.questionTitleBlockMain]}>
                  <View style={styles.questionTextBlock}>
                    <View style={[styles.questionTitleMain]}>
                      <View style={[styles.toDoSelection]}>
                        {this.state.ticked ? (
                          <TickButton
                            imagePath={"tickButton"}
                            styleType={"circle"}
                            tickBtnStyle={styles.circleSelection}
                            toggleTickCallback={() =>
                              this.handleCheckboxQuestion(false)
                            }
                          />
                        ) : (
                          <TickButton
                            imagePath={"untickButton"}
                            styleType={"circle"}
                            tickBtnStyle={styles.circleSelection}
                            toggleTickCallback={() =>
                              this.handleCheckboxQuestion(true)
                            }
                          />
                        )}
                      </View>
                      <View style={[styles.toDoTitle, styles.flexCom]}>
                        <Text style={[styles.textTitleBold, styles.textQuest]} allowFontScaling={false}>
                          {this.props.question
                            ? this.props.question.content
                            : null}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={[styles.questionCommentBlock]}>
                <View style={[styles.commentTextBlock, styles.flexCom]}>
                  <View style={[styles.btnArea]}>
                    <PlusButtonCom
                      btnPosition={"left"}
                      btnText={"Add Comment"}
                      touchableOnPress={this.onPressAddComment}
                    />
                  </View>
                </View>
                {wait}
                <View style={[styles.commentDetailsWrapper, styles.flexRow]}>
                  <View style={[styles.questionDateWrapper]}>
                    <Text style={[styles.textPrime, styles.dateTextQuest]} allowFontScaling={false}>
                      {this.props.question && formatDate( new Date(this.props.question.openedDate), DATE_FORMAT)}
                    </Text>
                  </View>
                </View>
              </View>

              {Boolean(this.state.answers.length) ? (
                <View style={[styles.commentFullBlock]}>
                  <View style={[styles.commentsListView]}>
                    {/* {this.state.answersSorted.length - 1 !== 0 ? ( */}
                    {Boolean(this.state.answers.length) ? (
                      <View
                        style={[
                          styles.commentsListItemMoreBtnSec,
                          styles.infoPdBotSm,
                        ]}
                      >
                        <View style={[styles.commentsListComHr]} />
                        <View style={[styles.moreCircleBubble]}>
                          <View style={styles.countBubbleWrapper}>
                            <Image
                              style={[styles.countBubble]}
                              source={require("../../../assets/icons/bubble-count.png")}
                            />

                            <View style={styles.countBubbleText}>
                              <TouchableOpacity
                                onPress={() => {
                                  this.setState((prevState) => ({
                                    hideComments: !prevState.hideComments,
                                  }));
                                }}
                                style={styles.countBubbleTouch}
                              >
                                {this.state.hideComments ? (
                                  <Text
                                    style={[
                                      styles.textPrimeSm,
                                      styles.textAlignCenter,
                                    ]}
                                    allowFontScaling={false}
                                  >
                                    {this.state.answers.length}
                                  </Text>
                                ) : (
                                  <Image
                                    style={[styles.countBubbleArrow]}
                                    source={require("../../../assets/icons/close-arrow.png")}
                                  />
                                )}
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    ) : null}

                    {Boolean(this.state.answers.length) ? (
                      this.state.hideComments ? null : (
                        <View
                          style={[
                            styles.commentsListItemMore,
                            styles.morePdTop,
                          ]}
                        >
                          {this.state.answers.map((answersSortedRes, index) => {
                            return (
                                <QuestionMainAnswers
                                  userImage="TO-DO"
                                  userName={postedUserName(answersSortedRes.postedByUser)}
                                  answer={answersSortedRes.content}
                                  postedDate={answersSortedRes.modifiedOn}
                                />
                            );
                          })}
                        </View>
                      )
                    ) : null}
                  </View>
                </View>
              ) : null}
            </View>
            <View style={[styles.hrLineSp]} />

            {/* Question Block */}
            <View
              style={[styles.questionCommentFull, styles.questionItemLtRtPd]}
            >
              {this.state.addAnswer ? (
                <View style={[styles.addCommentSection]}>
                  <View style={styles.fieldRowView}>
                    <View
                      style={[
                        styles.textAreaWrapper,
                        this.state.validComment
                          ? styles.validColor
                          : styles.inValidColor,
                      ]}
                    >
                      <Text style={styles.textView} allowFontScaling={false}>
                        <Text style={[styles.textLabel, styles.textGreyDark]} allowFontScaling={false}>
                          Type to enter a comment
                        </Text>
                      </Text>
                      <TextInput
                        ref={input => {
                          this.textInput = input;
                        }}
                        allowFontScaling={false}
                        onChangeText={this.onChangeTextComment}
                        style={[styles.textAreaField,
                          {
                            ...Platform.select({
                              android: {
                                textAlignVertical: "top"
                              },
                            })
                          }]}
                        multiline={true}
                        numberOfLines={3}
                        autoFocus={true}
                        underlineColorAndroid="transparent"
                        placeholderTextColor={"#98989A"}
                      />
                    </View>
                  </View>
                  <View style={styles.fieldRow}>
                    <View style={styles.btnArea}>
                      <View style={[stylesRes.btnViewSecSmRight, stylesRes.btnViewComment]}>
                        <View style={styles.fieldBtn}>
                          <View style={styles.btnBlockFull}>
                            <TouchableOpacity
                              style={[styles.submitSm, styles.btnPdMini, styles.bgWhite, styles.borderBtn]}
                              underlayColor="rgba(0, 153, 168, 0.8)"
                              onPress={ () => { 
                                this.setState({ addAnswer: !this.state.addAnswer, validComment: true, commentButtonEnable: true });
                              }}
                            >
                              <View style={styles.btnSingleView}>
                                <View
                                  style={[
                                    styles.btnSingle,
                                    styles.btnSingleCent,
                                  ]}
                                >
                                  <UIText
                                    style={[styles.submitText, styles.submitTextSm, styles.whiteBtnText]}
                                    title="Cancel"
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View style={styles.fieldBtnSp} />
                        <View style={styles.fieldBtn}>
                          <View style={styles.btnBlockFull}>
                            <TouchableOpacity
                              style={[styles.submitSm, styles.btnPdMini, styles.borderBtnTransparent]}
                              underlayColor="rgba(0, 153, 168, 0.8)"
                              onPress={this.saveAnswer}
                            >
                              <View style={styles.btnSingleView}>
                                <View
                                  style={[
                                    styles.btnSingle,
                                    styles.btnSingleCent,
                                  ]}
                                >
                                  {waitAnswer}
                                  <UIText
                                    style={[
                                      styles.submitText,
                                      styles.submitTextSm,
                                    ]}
                                    title="Save"
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              ) : null}
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    questionDigestProcessed: state.routines.questionDigestProcessed,
    user: state.routines.user,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateHealthQuestionAnswer: (
      healthQuestionAnswer,
      callBack,
      journalUserId
    ) =>
      dispatch(
        actions.updateHealthQuestionAnswerNew(
          healthQuestionAnswer,
          callBack,
          journalUserId
        )
      ),

    onUpdateHealthQuestion: (
      healthQuestion,
      questionDigest,
      callBack,
      journalUserId
    ) =>
      dispatch(
        actions.updateHealthQuestionNew(
          healthQuestion,
          questionDigest,
          callBack,
          journalUserId
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Question);
