import React, { Component } from "react";
import { Image, View, Text } from "react-native";
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import SearchMyEvent from "./SearchMyEvent";
class TopSectionBar extends Component {
    render(){
        const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
        return (
            <React.Fragment>
                <View style={[styles.comSpSm, stylesRes.pdMbTwentyDecZero]} dataSet={{media: ids.pdMbTwentyDecZero}}>
                    <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt]}>
                        <View>
                        <Text style={[stylesRes.healthJourneyTxt]} dataSet={{media: ids.healthJourneyTxt}}>
                            My Health Journey
                        </Text>
                        </View>
                        {/* Hide for now as this is not included in MVP */}
                        {/* <View style={[styles.exportPdfBtn]}>
                            <View style={[styles.flexRow, styles.flexAiFs, styles.flexJcCt]}>
                                <View style={styles.graphSmTitle}>
                                    <Image
                                        style={styles.exportPdfIcn}
                                        source={require('../../../assets/health-journey-icons/download-icon.png')}
                                    />
                                </View>
                                <View style={styles.premiumBtnSp}>
                                    <Text style={[styles.textPrimeBold, styles.textWhite]}>
                                        Export to PDF
                                    </Text>
                                </View>
                            </View>
                        </View> */}
                    </View>
                </View>
            {/* Hide for now as this is not included in MVP */}
            {/* <SearchMyEvent /> */}
        </React.Fragment>
        );
    }
};

export default TopSectionBar;
