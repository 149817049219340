import React, { useEffect, useState, useRef } from "react";
import { Image, View, Text, TouchableOpacity } from "react-native";
import moment from "moment";
import { connect } from 'react-redux';
import _ from 'lodash';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import AdvanceFilterDropDown from '../DropDown/AdvanceFilterDropDown';
import NewSelectCategoriesDropDown from "../DropDown/NewSelectCategoriesDropDown";
import SelectDateDropDown from '../DropDown/SelectDateDropDown'
import SortByDropDown from '../DropDown/SortByDropDown';

const OptionFilters = (props) => {
  const { selectedAllCategoriesItems, onPressFilter, setOpenFilters,toggleDate,
    saveHealthJourneyTagsObject, saveHealthJourneyCategoryObject, dob,isDateSelected, eventsDateRange, sortType, setSelectedAdvancedFilterItems, hasEvents, openFilters,selectedDateObject } = props;

  const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
  const [isAdvanceFilterVisible, setAdvanceFilterVisible] = useState(false);
  const [isSelectCategoriesVisible, setSelectCategoriesVisible] = useState(false);
  const [isSelectDateVisible, setSelectDateVisible] = useState(false);
  const [isSortByVisible, setSortByVisible] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [eventDaterange, setEventDateRange] = useState(undefined);
  const [itemCount, setItemCount] = useState(0);
  const opennedRef = useRef(openFilters)

  useEffect(() => {
    if (isAdvanceFilterVisible || isSelectCategoriesVisible || isSelectDateVisible || isSortByVisible) {
      if (!openFilters && !opennedRef.current) {
        setOpenFilters(true);
        opennedRef.current = true;
      } else if (!openFilters && opennedRef.current) {
        setOpenFilters(false);
        setAdvanceFilterVisible(false);
        setSelectCategoriesVisible(false);
        setSelectDateVisible(false);
        setSortByVisible(false);
        opennedRef.current = false;
      }
    } else {
      setOpenFilters(false);
      opennedRef.current = false;
    }
    //eslint-disable-next-line
  }, [isAdvanceFilterVisible, isSelectCategoriesVisible, isSelectDateVisible, isSortByVisible, openFilters])
  
  useEffect(() => {
    setEventDateRange(eventsDateRange);
  },[eventsDateRange]);

  useEffect(() => {
    const tagsObject = saveHealthJourneyTagsObject.data;
    const categoryObject = saveHealthJourneyCategoryObject.data;
    let subCatCount = 0;
    let count = 0;
    let added = false;
    let countAd = 0;
    if(saveHealthJourneyCategoryObject.data.length > 0){
      for(const item of saveHealthJourneyCategoryObject.data){
        if(item.subCategoryList.length > 0) subCatCount += item.subCategoryList.length
        else subCatCount += 1
      }
    }

    if(saveHealthJourneyCategoryObject.data.length > 0 && saveHealthJourneyTagsObject.data.length > 0) {
      count = tagsObject?.[0]?.data?.length + tagsObject?.[1]?.data?.length +
                categoryObject.length + subCatCount;
                setItemCount(count);
      added = true;
    }
    if (setSelectedAdvancedFilterItems && setSelectedAdvancedFilterItems.length > 0) {
      setSelectedAdvancedFilterItems.forEach(item => {
        countAd += item.data.length;
      })
    }
    setItemCount(count + countAd + (added ? 0 : subCatCount));
    
  },[saveHealthJourneyTagsObject, saveHealthJourneyCategoryObject, setSelectedAdvancedFilterItems]);

  const hasDropDownOpen = (isShowAdvanceFilter, isShowSelectCategories, isShowSelectDate, isShowSortBy) => {
    setAdvanceFilterVisible(isShowAdvanceFilter);
    setSelectCategoriesVisible(isShowSelectCategories);
    setSelectDateVisible(isShowSelectDate);
    setSortByVisible(isShowSortBy);
  }

  const allCategoriesFilterCount = () => {
    let subCatCount = 0;
    if(selectedAllCategoriesItems?.length > 0){
      for(const item of selectedAllCategoriesItems){
          subCatCount += 1;
      }
     }
     return subCatCount;
  }

  const advancedFilterCount = () => {
    let countAdvance = 0;
    if (setSelectedAdvancedFilterItems && setSelectedAdvancedFilterItems.length > 0) {
      setSelectedAdvancedFilterItems.forEach(item => {
        countAdvance += item.data.length;
      })
    }
    return countAdvance;
  }

  const tempAdvancedFilterCount = advancedFilterCount();
  const tempAllCategoriesFilterCount = allCategoriesFilterCount();

  return (
    <React.Fragment>
      {/* OPTIONS */}
      <View style={[styles.comMgTopExLg, styles.flexRow, styles.flexJcFs, styles.flexAiCt, styles.mgLtFifteen, stylesRes.listTab]} dataSet={{ media: ids.listTab }}>
        {/* filter icon */}
        <View style={[stylesRes.filtervisible, hasEvents ? {opacity: 0.4}: null]} dataSet={{ media: ids.filtervisible }} pointerEvents={openFilters ? "none" : "auto"}>
          <TouchableOpacity disabled={hasEvents} onPress={() => onPressFilter()} hitSlop={{top:15,bottom:15,left:15,right:15}}>
            <Image
              style={[styles.filterIcn]}
              source={require('../../../assets/health-journey-icons/filter-icon.png')}
            />
            {itemCount > 99 ? (
            <View style={styles.filterCount}>
              <Text style={[styles.textExMicSm, styles.fiveHudrredFtWgt, styles.textWhite]}>{`99+`}</Text>
            </View>
            ):(
              <View style={styles.filterCount}>
              <Text style={[styles.textExMicSm, styles.fiveHudrredFtWgt, styles.textWhite]}>{`${itemCount}`}</Text>
            </View>
            )
            }
          </TouchableOpacity>
        </View>

        {/* Advance Filter */}
        <View style={ stylesRes.advanceFilterCont } dataSet={{ media: ids.advanceFilterCont }}>
          <TouchableOpacity disabled={hasEvents} activeOpacity={0.9} onPress={() => [hasDropDownOpen(!isAdvanceFilterVisible, false, false, false)]}>
            <View style={[styles.sectionContainerHealthJourney, hasEvents ? {opacity: 0.4}: null, isAdvanceFilterVisible && styles.lightHighlightedBleBg]}>
              <View style={[styles.flexRow, styles.flexJcCt, styles.flexAiCt]}>
               {
               tempAdvancedFilterCount === 0 ?
               <View style={styles.secGap}>
                  <Text style={[styles.textPrime, styles.textColor]}>{tempAdvancedFilterCount === 0 && "+"}</Text>
                </View>:   
                <View style={[styles.secGap, styles.textIconView]}>
                  <Image
                    style={styles.categoryIcn}
                    source={require('../../../assets/health-journey-icons/filterDot.png')}
                  />
                </View>
                }
                <View style={styles.secGap}>
                  <Text style={[styles.textPrime, styles.textColor]}>{tempAdvancedFilterCount > 0 ?`--- ${tempAdvancedFilterCount} Filter ---` : "Advanced Filter"}</Text>
                </View>
              </View>
            </View>
            {isAdvanceFilterVisible ?
             <View>
              <TouchableOpacity activeOpacity={0.9} onPress={() => {}}>
                <AdvanceFilterDropDown
                  setOnOpenFilter={(value) => setAdvanceFilterVisible(value)}
                />
              </TouchableOpacity>
            </View> : null}
          </TouchableOpacity>
        </View>

        {/* All Categories */}
        <View style={ stylesRes.advanceFilterCont } dataSet={{ media: ids.advanceFilterCont }}>
          <TouchableOpacity disabled={hasEvents} activeOpacity={0.9} onPress={() => hasDropDownOpen(false, !isSelectCategoriesVisible, false, false)}>
            <View style={[styles.sectionContainerHealthJourney, hasEvents ? {opacity: 0.4}: null, isSelectCategoriesVisible && styles.lightHighlightedBleBg]}>
              <View style={[styles.flexRow, styles.flexJcCt, styles.flexAiCt]}>
                <View style={[styles.secGap, styles.textIconView]}>
                  <Image
                    style={styles.categoryIcn}
                    source={tempAllCategoriesFilterCount > 0 ? require('../../../assets/health-journey-icons/allCategoryNew.png') : require('../../../assets/health-journey-icons/category-icon.png')}
                  />
                </View>
                <View style={styles.secGap}>
                  <Text style={[styles.textPrime, styles.textColor]}>{tempAllCategoriesFilterCount > 0 ? `${tempAllCategoriesFilterCount} Category` : "All Categories"}</Text>
                </View>
              </View>
            </View>
            {/* <SelectCategoriesDropDown /> */}
            {isSelectCategoriesVisible ?
            <View>
              <TouchableOpacity activeOpacity={0.9} onPress={() => {}}>
                <NewSelectCategoriesDropDown
                  selectedAllCategoriesItems={selectedAllCategoriesItems}
                  setOnOpenFilter={(value) => setSelectCategoriesVisible(value)}
                />
            </TouchableOpacity>
            </View>
            : null}
          </TouchableOpacity>
        </View>

        {/* Date */}
        <View style={ stylesRes.dateSelectionCont } dataSet={{ media: ids.dateSelectionCont }}>
          <TouchableOpacity disabled={hasEvents} activeOpacity={0.9} onPress={() => hasDropDownOpen(false, false, !isSelectDateVisible, false)}>
            <View style={[styles.sectionContainerHealthJourney, hasEvents ? {opacity: 0.4}: null, isSelectDateVisible && styles.lightHighlightedBleBg]}>
              <View style={[styles.flexRow, styles.flexJcCt, styles.flexAiCt]}>
                <View style={[styles.secGap, styles.textIconView]}>
                  <Image
                    style={styles.healthJornCalendar}
                    source={Object.entries(selectedDateObject).length === 0 ? require('../../../assets/health-journey-icons/calendar-icon.png') : require('../../../assets/health-journey-icons/filtercalender.png')}
                  />
                </View>
                <View style={styles.secGap}>
                  <View style={styles.flexRow}>
                    {/* desc date section */}
                    <View style={stylesRes.largerScrnVisibiliyOp} dataSet={{ media: ids.largerScrnVisibiliyOp }}>
                      <View style={styles.flexRow}>
                        <Text style={[styles.textPrime, styles.textColor]}>
                          <Text style={styles.pdRtTwo}>{moment(eventDaterange?.firstDate).format('MMM')}</Text>
                          {/* <Text>{_.isNull(fromDate) ? '' : '`'} </Text> */}
                          <Text style={styles.pdLtTwo}>{moment(eventDaterange?.firstDate).format('YYYY')}</Text>
                        </Text>
                        <Text style={[styles.pdLtTwo, styles.pdRtFour, styles.textPrime, styles.textColor]}>{'-'}</Text>
                        <Text style={[styles.textPrime, styles.textColor]}>
                          <Text style={styles.pdRtTwo}>{moment(eventDaterange?.lastDate).format('MMM')}</Text>
                          {/* <Text>{_.isNull(toDate) ? '' : '`'} </Text> */}
                          <Text style={styles.pdLtTwo}>{moment(eventDaterange?.lastDate).format('YYYY')}</Text>
                        </Text>
                      </View>
                    </View>

                    {/* mb date section */}
                    <View style={stylesRes.smlerScrnVisibilityOp} dataSet={{ media: ids.smlerScrnVisibilityOp }}>
                      <View style={styles.flexRow}>
                        <Text style={[styles.textPrime, styles.textColor,stylesRes.largeTosmallTextRes ]} dataSet={{ media: ids.largeTosmallTextRes}} >
                          <Text style={styles.pdRtTwo}>{moment(eventDaterange?.firstDate).format('MMM')}</Text>
                          <Text>{_.isNull(fromDate) ? '' : '`'} </Text>
                          <Text>{moment(eventDaterange?.firstDate).format('YY')}</Text>
                        </Text>
                        <Text style={[styles.pdLtTwo, styles.pdRtFour, styles.textPrime, styles.textColor,stylesRes.largeTosmallTextRes ]} dataSet={{ media: ids.largeTosmallTextRes }}>{'-'}</Text>
                        <Text style={[styles.textPrime, styles.textColor,stylesRes.largeTosmallTextRes ]}  dataSet={{ media: ids.largeTosmallTextRes }}>
                          <Text style={styles.pdRtTwo}>{moment(eventDaterange?.lastDate).format('MMM')}</Text>
                          <Text>{_.isNull(toDate) ? '' : '`'} </Text>
                          <Text>{moment(eventDaterange?.lastDate).format('YY')}</Text>
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </TouchableOpacity>
          {isSelectDateVisible ?
            <View style={stylesRes.mgLtmbTenDescZero}dataSet={{ media: ids.mgLtmbTenDescZero }}>
              <SelectDateDropDown
                setOnOpenFilter={(value) => setSelectDateVisible(value)}
                dob={dob}
                isDateSelected={isDateSelected}
                toggleDate={toggleDate}
              />
            </View>: null}
          </View>

        {/* Sort Newwest */}
        <View style={ stylesRes.sortNewestCont } dataSet={{ media: ids.sortNewestCont }}>
          <TouchableOpacity disabled={hasEvents} activeOpacity={0.9} onPress={() => hasDropDownOpen(false, false, false, !isSortByVisible)}>
            <View>
              <View style={[styles.sectionContainerHealthJourney, styles.pdLtSix, hasEvents ? {opacity: 0.4}: null, stylesRes.sortToNewestLgAlign, isSortByVisible && styles.lightHighlightedBleBg]} dataSet={{ media: ids.sortToNewestLgAlign }}>
                <View style={[styles.flexRow, styles.flexJcCt, styles.flexAiCt]}>
                  {sortType === 1 ?
                    <View style={stylesRes.largerScrnVisibiliyOp} dataSet={{ media: ids.largerScrnVisibiliyOp }}>
                      <Text style={[styles.textPrime, styles.textColor]}>Sort Newest to Oldest</Text>
                    </View> :
                    <View style={stylesRes.largerScrnVisibiliyOp} dataSet={{ media: ids.largerScrnVisibiliyOp }}>
                      <Text style={[styles.textPrime, styles.textColor]}>Sort Oldest to Newest</Text>
                    </View>
                  }
                  {sortType === 0 ?
                    <View style={stylesRes.smlerScrnVisibilityOp} dataSet={{ media: ids.smlerScrnVisibilityOp }}>
                      <Text style={[styles.textPrime, styles.textColor, stylesRes.largeTosmallTextRes]} dataSet={{ media: ids.largeTosmallTextRes}}>Oldest to Newest</Text>
                    </View>
                    :
                    <View style={stylesRes.smlerScrnVisibilityOp} dataSet={{ media: ids.smlerScrnVisibilityOp }}>
                      <Text style={[styles.textPrime, styles.textColor,stylesRes.largeTosmallTextRes]}  dataSet={{ media: ids.largeTosmallTextRes}}>Newest to Oldest</Text>
                    </View>
                  }
                  <View style={[styles.secGap, styles.textIconView, { paddingLeft: 4 }]}>
                    <Image
                      style={{ width: 12, height: 12 }}
                      source={require('../../../assets/health-journey-icons/downward-arrow.png')}
                      resizeMode='contain'
                    />
                  </View>
                </View>
              </View>
            </View>
          </TouchableOpacity>
          {isSortByVisible ?
            <SortByDropDown
              setOnOpenFilter={(value) => setSortByVisible(value)} />
            : null}
        </View>
        </View>
        {/* <View style={[ stylesRes.smlerScrnVisibilityOp, styles.healthJourneyTpTb, styles.wdFullPer, styles.comMgTopLg, styles.brdBtnWdThree]} dataSet={{media: ids.smlerScrnVisibilityOp}}></View> */}
      
    </React.Fragment >
  );
};

const mapStateToProps = state => {
  return {
      saveHealthJourneyTagsObject: state.healthJourney.saveHealthJourneyTagsObject,
      saveHealthJourneyCategoryObject: state.healthJourney.saveHealthJourneyCategoryObject,
      eventsDateRange: state.healthJourneyFiltering.eventsDateRange,
      sortType: state.healthJourneyFiltering.sortType,
      setSelectedAdvancedFilterItems: state.healthJourneyFiltering.setSelectedAdvancedFilterItems,
      selectedDateObject: state.healthJourneyFiltering.setSelectedDateObject,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionFilters);
