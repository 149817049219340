import React from "react";
import { View, Image, Text } from "react-native";
import styles from "../../Css";

const warningInlineCommon = props => {
  return (
    <View style={[styles.warningBlock, styles.topPdExSm]}>
      <View
        style={[styles.notifyDetailsCom, styles.notifyNoPd, styles.notifyPdBot,
          props.textPosition === 'left'
          ? styles.flexJcFs
          : styles.flexJcCt]}
      >
        <View style={styles.notifyWarning}>
          <Image
            style={styles.warningCircle}
            source={require("../../assets/icons/warning-circle-light.png")}
          />
        </View>
        <Text style={[styles.warningComText]}  allowFontScaling={false}>{props.errorDescription}</Text>
      </View>
    </View>
  );
};

export default (warningInlineCommon);
