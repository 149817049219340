import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import styles from "../../../../Css";
import stylesResponsive from "../../../../CssResponsive";
import ClinicalTrialMyListScrollView from "../../../../screens/Settings/ClinicalTrialsSettings/ClinicalTrialMyListScrollView";
import _ from "underscore";
import PageContainer from "../../../../components/PageLayout/PageContainer";

const clinicalTrialMyList = props => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  return (
    <View style={styles.gridRow}>
      {props.myClinicalTrials ? (
        _.isEmpty(props.myClinicalTrials) ? null : (
          <View style={styles.gridColFull}>
            <View style={stylesRes.tabContentFullArea}>
              <View style={styles.tabFullBlock}>
              <PageContainer smScreenFull={true}>
                  <View style={[styles.secSubArea]}>
                    <View style={[styles.secSubTitleArea, styles.greyBorderThin]}>
                      <View style={[styles.colPd]}>
                        <View style={[styles.secSubTitleView, styles.flexJcFeAiFe]}>
                          <TouchableOpacity
                            onPress={props.onDeleteClinicalTrialsConfirmation}
                          >
                            <View style={[styles.clearBoxLg, styles.flexRow]}>
                              {props.isDeleteText &&
                                <Text
                                  style={[
                                    styles.textPrimeBold,
                                    styles.textGreyDark,
                                    styles.textAlignRight
                                  ]}
                                  allowFontScaling={false}
                                >
                                  Delete
                                </Text>
                              }
                              <View style={styles.iconListSec}>
                                {props.isSelectedDelete ?
                                  <Image
                                    style={styles.radioIcon}
                                    source={require("../../../../assets/icons/radio-selected.png")}
                                  />
                                  :
                                  <Image
                                    style={styles.radioIcon}
                                    source={require("../../../../assets/icons/radio-default.png")}
                                  />
                                }
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </PageContainer>
                <View style={[styles.secSubArea]}>
                  <View style={styles.secSubContentArea}>
                        <ClinicalTrialMyListScrollView
                          data={props.myClinicalTrials}
                        />
                  </View>
                </View>
              </View>
            </View>
          </View>
        )
      ) : null}
    </View>
  );
};

export default (clinicalTrialMyList);
