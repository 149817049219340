import React from "react";
import { Image, View, Text, Linking } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import PrimaryButton from "../../components/Button/primaryButton";
import PageLayout from "../../components/PageLayout/pageLayout";
import {CONTACT_EMAIL} from '../../util/commonUiConfig';

const stylesRes = stylesResponsive.getProcessedStyles().styles;
const ids = stylesResponsive.getProcessedStyles().ids;
const UserJournalDigestRequestErrorMessage = (props) => {
  return (
    <PageLayout
      windowHeightView={props.windowHeightView}
      windowHeightSmView={props.windowHeightSmView}
      windowWidthView={props.windowWidthView}
      bgBlue={props.bgBlue}
      contentCenter={false}
      bgStyle={true}
      contactHide={true}
    >
      <View style={[styles.boxWrapperArea, styles.colMgLtRt]}>
        <View style={[stylesRes.boxWrapperView, stylesRes.wrapperShadow]} dataSet={{media: ids.boxWrapperView}}>
          <View style={[styles.colPd]}>
            <View style={[styles.flexJcCtAiCt, styles.zoomPd]}>
              <Image
                style={[styles.zoomLgIcon]}
                source={require("../../assets/icons/zoom-lg.png")}
              />
            </View>

            <View style={styles.gridRow}>
              <View style={[styles.gridColFull]}>
                <View style={[styles.flexRowJcCtAiCt, styles.pdBotLg]}>
                  <Text style={[styles.textAlignCenter, styles.textTitle]} allowFontScaling={false}>
                    Oops, looks like something went wrong!
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <View style={[styles.titleBlock, styles.flexRowJcCtAiCt]}>
                  <Text
                    style={[
                      styles.textAlignCenter,
                      styles.textTitle,
                      styles.textTertiary,
                    ]}
                    allowFontScaling={false}
                  >
                    Please retry after logging in again
                  </Text>
                </View>
                <View
                  style={[
                    styles.flexRowJcCtAiCt,
                    styles.titleBlockPdBotMd,
                    styles.pdTopExSm,
                  ]}
                >
                  <Text
                    style={[
                      styles.textAlignCenter,
                      styles.textPrime,
                      styles.textTertiary,
                    ]}
                    allowFontScaling={false}
                  >
                    {`If problem persists, please contact `}
                    <Text
                      onPress={() =>
                        Linking.openURL(`mailto:${CONTACT_EMAIL}`)
                      }
                      title={CONTACT_EMAIL}
                      allowFontScaling={false}
                    >
                      {CONTACT_EMAIL}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <View
                  style={[styles.btnArea, styles.contPdTop, styles.comMgBotSm]}
                >
                  <View style={styles.fieldBtnCom}>
                    <PrimaryButton
                      btnText={"Back to Login"}
                      btnPosition={"center"}
                      btnShadow={false}
                      btnLg={true}
                      btnStyle={styles.primaryButtonFixed}
                      touchableOnPress={props.backToLoginPage}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </PageLayout>
  );
};

export default UserJournalDigestRequestErrorMessage;
