import React, { useState } from 'react';
import { View, ScrollView, Text, Image, TouchableOpacity } from 'react-native';
import _ from 'underscore';
import Header from '../Header/header';
import SpaceBar from '../SpaceBar/SpaceBar';
import SelectSaveButton from './selectSaveButton';
import PageContainer from "../PageLayout/PageContainer";
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import { treatmentTypes, sortAlphabeticalOrder } from '../../util/commonUiLogic';

const TreatmentListContent = (props) => {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const { index, navigation, route, saveWait, treatmentsProcessed, treatmentsList, handleOnPressBackButton, handleOnSave } = props;
    const [selectedTreatment, setSelectedTreatment] = useState(null);

    const handleOnPressSelectTreatment = id => {
        let val = null;
        if (selectedTreatment !== id) {
          val = id;
        }
        setSelectedTreatment(val);
        if (props.setSelectedTreatment && props.setSelectedTreatment(val));
    };

    const getTreatmentTypeData = (type) => {
        const data = {};
        switch (type) {
        case treatmentTypes.OTHER:
            data.title = 'Other';
            data.bg_image = require('../../assets/background-images/bg-purple.png');
            data.icon_image = require('../../assets/therapy-icons/other.png');
            break;
        case treatmentTypes.MEDICATION:
            data.title = 'Medication';
            data.bg_image = require('../../assets/background-images/bg-orange.png');
            data.icon_image = require('../../assets/therapy-icons/medication.png');
            break;
        case treatmentTypes.NATURAL_HEALTH_PRODUCT:
            data.title = 'Natural Health Product';
            data.bg_image = require('../../assets/background-images/bg-green.png');
            data.icon_image = require('../../assets/therapy-icons/natural.png');
            break;
        case treatmentTypes.SURGERY:
            data.title = 'Surgery';
            data.bg_image = require('../../assets/background-images/bg-pink.png');
            data.icon_image = require('../../assets/therapy-icons/surgery.png');
            break;
        case treatmentTypes.ACTIVITY:
            data.title = 'Activity';
            data.bg_image = require('../../assets/background-images/bg-blue.png');
            data.icon_image = require('../../assets/therapy-icons/activity.png');
            break;
        default:
            data.title = 'Other';
            data.bg_image = require('../../assets/background-images/bg-purple.png');
            data.icon_image = require('../../assets/therapy-icons/other.png');
            break;
        }
        return data;
    }

    const renderTreatmentTypeContent = (treatmentTypeList, treatmentType) => {
        const treatmentTypeData = getTreatmentTypeData(treatmentType);
        return (
        <>
            {_.isEmpty(treatmentTypeList) ? null : (
            <View style={stylesRes.contentSingleBlock}>
                <View style={stylesRes.colFull}>
                <View>
                    <View style={stylesRes.contentTopicSection}>
                    <View style={styles.medListFullSec}>
                        <View
                        style={[
                            styles.medListSingleBoxLg,
                            styles.bgOrange,
                            styles.boxHeightMd,
                        ]}>
                        <View style={styles.medSecImgFull}>
                            <Image
                                style={[
                                    styles.medSecImgBgView,
                                    styles.boxHeightMd,
                                ]}
                                resizeMode={'cover'}
                                source={treatmentTypeData?.bg_image}
                            />
                        </View>
                        <View style={styles.medDetails}>
                            <View style={styles.medContentLg}>
                            <View style={styles.medIconBlockLg}>
                                <Image
                                    style={[
                                        styles.medIcon,
                                        {width: 56, height: 62},
                                    ]}
                                    resizeMode={'contain'}
                                    source={treatmentTypeData?.icon_image}
                                />
                            </View>

                            <View style={styles.medTitleBlockLg}>
                                <Text style={styles.titleLgText} allowFontScaling={false}>
                                    {treatmentTypeData?.title}
                                </Text>
                            </View>
                            </View>
                        </View>
                        </View>
                    </View>
                    </View>
                    <View style={stylesRes.contentDetailSection}>
                    <View style={stylesRes.contList}>
                        {sortAlphabeticalOrder(Object.values(
                        treatmentTypeList
                        )).map(res => (
                        <View style={[styles.borderThin]} key={res.id}>
                            <TouchableOpacity
                                onPress={() =>
                                    handleOnPressSelectTreatment(res.id)
                                }
                                style={[styles.contListItemMd]}>
                            <View style={stylesRes.contListItemBlock}>
                                <View style={[stylesRes.contListItemDesc, stylesRes.contListCenter]}>
                                <Text style={styles.styleDes} allowFontScaling={false}>
                                    {res.name}
                                </Text>
                                </View>
                                <View style={stylesRes.selectionBlock}>
                                <View style={styles.iconListSec}>
                                    <View style={[styles.selectSec]}>
                                    <View
                                        style={[
                                        styles.selectSecIcon,
                                        styles.iconListMgSmLt,
                                        ]}>
                                        <Image
                                            style={styles.radioIcon}
                                            source={selectedTreatment === res.id
                                                ? require('../../assets/icons/radio-selected.png')
                                                : require('../../assets/icons/radio-default.png')}
                                        />
                                    </View>
                                    </View>
                                </View>
                                </View>
                            </View>
                            </TouchableOpacity>
                        </View>
                        ))}
                    </View>
                    </View>
                </View>
                </View>
            </View>
            )}
        </>
        );
    }

    return (
        <>
            <Header
                showFilers={false}
                showBack={true}
                showClearFilters={false}
                showNotify={false}
                handleOnPressBackButton={handleOnPressBackButton}
                index={index}
                navigation={navigation}
                route={route}
            />
            <SpaceBar />
            <ScrollView style={styles.scrollViewArea}>
                <View style={styles.gridSection}>
                    <View style={[stylesRes.contentSection, stylesRes.contBotPd]} dataSet={{media: ids.contBotPd}}>
                        <PageContainer smScreenFull={true}>
                            <View style={styles.gridRow}>
                                <View style={styles.gridColFull}>
                                    {/* Medication */}
                                    {renderTreatmentTypeContent(treatmentsList?.medication, treatmentTypes.MEDICATION)}
                                    
                                    {/* Natural Health Product */}
                                    {renderTreatmentTypeContent(treatmentsList?.naturalHealthProduct, treatmentTypes.NATURAL_HEALTH_PRODUCT)}

                                    {/* Activity */}
                                    {renderTreatmentTypeContent(treatmentsList?.activity, treatmentTypes.ACTIVITY)}

                                    {/* Surgery */}
                                    {renderTreatmentTypeContent(treatmentsList?.surgery, treatmentTypes.SURGERY)}

                                    {/* OTHER */}
                                    {renderTreatmentTypeContent(treatmentsList?.other, treatmentTypes.OTHER)}
                                </View>
                            </View>
                        </PageContainer>
                        <PageContainer>
                            {selectedTreatment ? (
                                <SelectSaveButton
                                    isFromSave={true}
                                    saveWait={saveWait}
                                    disabled={saveWait}
                                    onPress={() => handleOnSave(treatmentsProcessed, selectedTreatment)}
                                />
                            ) : (
                                <SelectSaveButton />
                            )}
                        </PageContainer>
                    </View>
                </View>
            </ScrollView>
        </>
    );
}
export default TreatmentListContent;

