import React from 'react';
import HsvColorPicker from '../../modules/react-native-hsv-color-picker';
import chroma from 'chroma-js';

export default class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    const {background} = props;
    let hue = 0;
    let sat = 0;
    let val = 1;
    if (background) {
      // const color = background.includes('rgba') ? chroma.rgb(background).hsv() : chroma.hex(background).hsv();
      let color = chroma(background).hsv();
      hue = color?.[0];
      sat = color?.[1];
      val = color?.[2];
    }
    this.state = {
      hue: hue,
      sat: sat,
      val: val,
      hex: background ? background : chroma.hsv(0, 0, 1).hex(),
    };
  }

  onSatValPickerChange = ({saturation, value}) => {
    const {hue} = this.state;
    const color = chroma.hsv(hue, saturation, value).hex();
    this.props.handleChangeComplete({
      hex: color,
    });
    this.setState({
      sat: saturation,
      val: value,
      hex: color,
    });
  };

  onHuePickerChange = ({hue}) => {
    const {sat, val} = this.state;
    const color = chroma.hsv(hue, sat, val).hex();
    this.props.handleChangeComplete({
      hex: color,
    });
    this.setState({
      hue: hue,
      hex: color,
    });
  };

  onTouchBox = col => {
    const color = chroma.hex(col).hsv();
    this.props.handleChangeComplete({
      hex: col,
    });
    this.setState({
      hue: color?.[0],
      sat: color?.[1],
      val: color?.[2],
      hex: col,
    });
  };

  render() {
    const {hue, sat, val, hex} = this.state;
    return (
      <HsvColorPicker
        hex={hex}
        huePickerHue={hue}
        onHuePickerDragMove={this.onHuePickerChange}
        onHuePickerPress={this.onHuePickerChange}
        satValPickerHue={hue}
        satValPickerSaturation={sat}
        satValPickerValue={val}
        onSatValPickerDragMove={this.onSatValPickerChange}
        onSatValPickerPress={this.onSatValPickerChange}
        onTouchBox={this.onTouchBox}
      />
    );
  }
}
