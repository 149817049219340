import React, { Fragment, useState, useEffect, useRef } from "react";
import {View, Text, Image, TextInput, TouchableOpacity, Platform} from 'react-native';
import styles from '../../../Css';
import ViewMoreText from '../../../components/ViewMoreText/index';
import * as constants from '../HealthJourneyConstant';

const AddEventDescription = (props) => {
    const { handleOnDescriptionDone, description: descProps, activeBubbleId, setActiveBubble, dismissTagList } = props;

    const [description, setDescription] = useState(null);
    const [showDescriptionBox, setShowDescriptionBox] = useState(false);
    const [letterCount, setLetterCount] = useState(0);
    const [focus, setFocus] = useState(false);
    const [height, setHeight] = useState(61);
    const inputRef = useRef(null);

    useEffect(() => {
    if (description !== descProps) {
        setDescription(descProps)
        setLetterCount(descProps?.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descProps])

    const handleOnPressDescription = () => {
    if (activeBubbleId) setActiveBubble(null);
    setShowDescriptionBox(true);
    setFocus(true);
    }

    useEffect(() => {
    onFocusHandler();
    }, []);

    const onFocusHandler = () => {
    inputRef.current && inputRef.current.focus();
    }

    return (
        <Fragment>
          {!showDescriptionBox ? (
              // Description text
              <TouchableOpacity onPress={handleOnPressDescription}>
                  <View style={[styles.flexRow, styles.mgTopLg, styles.flexAiCt, styles.mgBtFourty]}>

                      <View style={[styles.pdRtSeven, styles.mgLeftTwo]}>
                          <Image
                              style={styles.hamburgerMenuNwEvnt}
                              source={require("../../../assets/health-journey-icons/hamburger-menu.png")}
                              resizeMode={constants.IMAGE_RESIZE_MODE}
                          />
                      </View>

                      <View
                          style={[styles.pdRtSeven, styles.mgLtFive, styles.hudredPercentWd]}
                          pointerEvents={'none'}>
                          <ViewMoreText
                              longText={description ? description : constants.DESCRIPTION}
                              moreText={'Read more'}
                              lessText={'Read less'}
                              numberOfLines={2}
                              customStyle={[styles.seaBluTxtColor, styles.statusBarText]}
                          />
                      </View>
                  </View>
              </TouchableOpacity>
            ) :
                (
              // Description input box
                    <View style={styles.mgTopLg}>
                        <View style={[styles.flexRow, styles.flexAiCt, styles.pdBtEleven]}>
                            <View style={[styles.pdRtSeven, styles.mgLeftTwo]}>
                                <Image
                                    style={styles.hamburgerMenuNwEvnt}
                                    source={require("../../../assets/health-journey-icons/hamburger-menu-blue.png")}
                                    resizeMode={constants.IMAGE_RESIZE_MODE}
                                />
                            </View>
                            <View style={styles.pdLtFive}>
                                <Text style={[styles.textTitleBold, styles.seaBluTxtColor]}>Description</Text>
                            </View>
                        </View>
                        <TextInput
                            style={[styles.textTitle,
                                styles.addEvntTxtInput,
                                { height: height },
                                {
                                    ...Platform.select({
                                        android: {
                                            textAlignVertical: "top"
                                        },
                                    })
                                }]}
                            ref={inputRef}
                            multiline={true}
                            value={description || ""}
                            maxLength={constants.DESCRIPTION_MAX_LENGTH}
                            onChangeText={(value) => {
                                if (value) {
                                    setDescription(value);
                                    setLetterCount(value.length);
                                    handleOnDescriptionDone(value)
                                } else {
                                    setDescription(null);
                                    setLetterCount(0);
                                    handleOnDescriptionDone(null)
                                }
                            }}
                            onFocus={() => {
                                activeBubbleId && setActiveBubble(null);
                                dismissTagList();
                            }}
                            autoFocus={focus}
                            onContentSizeChange={(e) => {
                                setHeight(e.nativeEvent.contentSize.height);
                            }}
                            onBlur={() => {
                                setTimeout(() => {
                                    activeBubbleId && setActiveBubble(null);
                                    setShowDescriptionBox(false);
                                    setFocus(false);
                                }, 100);
                            }}
                        />
                        <View style={[
                            styles.flexRow,
                            styles.flexJcFs,
                            styles.flexAiCt,
                            styles.pdTpEleven
                        ]}>
                            <Text style={[styles.textTitle, styles.textGreyDark]}>
                                {letterCount}/1000 characters
                          </Text>
                      </View>
                  </View>
    )}
    </Fragment>
  );
};

export default AddEventDescription;