import { HEALTH_JOURNEY_ADVANCED_FILTERING_ATTACHMENTS, HEALTH_JOURNEY_CATEGORIES_FILTERING_DATA, RESET_FILTERING_OBJECT_DATA } from "./actionTypes";

const setAdvancedFilteringAttachmentsData = () => {
    return (dispatch) => {
        dispatch({
            type: HEALTH_JOURNEY_ADVANCED_FILTERING_ATTACHMENTS,
        });
    };
}

const setCategoriesFilteringInnitialArrayObject = () => {
    return (dispatch) => {
        dispatch({
            type: HEALTH_JOURNEY_CATEGORIES_FILTERING_DATA,
        });
    };
}

const resetFilteringObjectData = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_FILTERING_OBJECT_DATA,
        });
    }
}

export { setAdvancedFilteringAttachmentsData, setCategoriesFilteringInnitialArrayObject, resetFilteringObjectData }