import React, { Fragment, useState, useEffect } from "react";
import { Image, View, Text, TouchableOpacity } from "react-native";
import styles from '../../../Css';

const TagRowDetailViewComponent = () => {
    return (
        <View style={[styles.flexRow, styles.flexJcCtAiCt]}>
            <View style={styles.mgRtTwo}>
                <Image
                    style={styles.tgGreySmIcn}
                    source={require('../../../assets/health-journey-icons/tag-small-grey-icon.png')}
                />
            </View>
            <View>
                <Text style={styles.textPrime}> Tag </Text>
            </View>
        </View>
    );
};

const AttachmentDetailRowViewComponent = () => {
    return (
        <View style={[styles.flexRow, styles.flexJcCtAiCt]}>
            <View style={styles.mgRtTwo}>
                <Image
                    style={styles.tgGreySmIcn}
                    source={require('../../../assets/health-journey-icons/attachment-small-grey-icon.png')}
                />
            </View>
            <View>
                <Text style={styles.textPrime}> Attachments </Text>
            </View>
        </View>
    );
};
const TYPES = ['TAGS', 'ATTACHMENTS'];
const SelectCategoryTypeFilter = (props) => {
    const {type, selected, index, setOpenedId, openedId} = props;
    const [hasSelectFilterDropdownOpen, setSelectFilterDropdownOpen] = useState(false);

    const filterOptionsSelectionHandler = (selectedFilterType) => {
        props.selectedFilterType(selectedFilterType);
    }

    useEffect(() => {
      if (openedId === 9999 && hasSelectFilterDropdownOpen) {
        setSelectFilterDropdownOpen(false);
      }
      //eslint-disable-next-line
    }, [openedId])

    const handleOpen = () => {
      !hasSelectFilterDropdownOpen && setOpenedId(Date.now());
      setSelectFilterDropdownOpen(!hasSelectFilterDropdownOpen)
    }

    return (
        <React.Fragment>
            <View style={[styles.pstAbsLtFive, {zIndex: 9999 - index}]}>
                <View style={[styles.whOneThirtyTwo, styles.monthWdCustom, styles.fullHgHundredPerc, styles.minHgThirty, styles.flexJcFs, styles.bgWhite,styles.whOneThirtyTwo]}>
                    {/* top search text box */}
                    <TouchableOpacity onPress={handleOpen} disabled={!TYPES.some(item => !selected.includes(item))}>
                        <View style={[styles.flexRow, styles.flexAiCt, styles.flexJcSb, styles.lblSp]}>
                            <View style={[styles.navListLiContent, styles.textIconView]}>
                                {type === 'ATTACHMENTS' ? <AttachmentDetailRowViewComponent /> :
                                    type === 'TAGS' ? <TagRowDetailViewComponent /> :
                                        <Text style={[styles.textPrime, styles.textGrey]}> Select Filter: </Text>}
                            </View>
                            <View style={styles.infoSp}>
                                <Image
                                    style={[styles.customArrowWdHg]}
                                    source={require('../../../assets/health-journey-icons/dropdown-arrow-black.png')}
                                />
                            </View>
                        </View>
                    </TouchableOpacity>

                    {Boolean(hasSelectFilterDropdownOpen) ?
                        <View>
                            {/* seperate line */}
                            <View style={[styles.filterBrdBtm, styles.fieldColCenter, styles.mgBtmSix, styles.mgTpSix, styles.wdHundredSixteen]}></View>
                            {/* category options */}

                            <View>
                                {/* tag */}
                                {TYPES.filter(item => !selected.includes(item)).map(item => (
                                  <Fragment key={item}>
                                        <TouchableOpacity onPress={() => {
                                            filterOptionsSelectionHandler(item);
                                            setSelectFilterDropdownOpen(!hasSelectFilterDropdownOpen)
                                        }
                                        }>
                                        <View style={[styles.flexRow, styles.flexAiCt, styles.topAlgnSix, styles.pdBtmSix, styles.navListLiContent, styles.textIconView]}>
                                            {item === 'TAGS' ? <TagRowDetailViewComponent /> : <AttachmentDetailRowViewComponent />}
                                            
                                            {type === item ?
                                                <View style={[styles.btOne, styles.toAlignLeft]}>
                                                    <Image
                                                        style={[styles.healthJrnTickIcn]}
                                                        source={require('../../../assets/health-journey-icons/tick-icon.png')}
                                                    />
                                                </View>
                                                : null}
                                        </View>
                                    </TouchableOpacity>
                                  </Fragment>
                                ))}
                            </View>
                        </View>
                        : null}
                </View>
            </View>
        </React.Fragment>

    );
};

export default SelectCategoryTypeFilter;
