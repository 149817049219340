import React from "react";
import { View, Text } from "react-native";
import styles from "../../../../Css";
import CollapseDetailDescription from "../../../TabCollapse/collapseDetailDescription";

const healthDataTabContent = (props) => {
  const { windowWidth, onPressSetupNow } = props;

  return (
    <CollapseDetailDescription
      itemTitle={"Add Health Data to track"}
      imgSource={require("../../../../assets/setup/health.png")}
      windowWidth={windowWidth}
      onPressSetupNow={onPressSetupNow}
    >
      <View style={[styles.collapseContDec]}>
        <View style={[styles.collapseContPara]}>
          <Text style={[styles.textPrimeSm, styles.textGrey]} allowFontScaling={false}>
            To help you get started, health data common to your medical
            condition may be listed. This is not a complete list and can be
            deleted if not relevant to you. You can also add custom health data.
          </Text>
        </View>
      </View>
    </CollapseDetailDescription>
  );
};

export default (healthDataTabContent);
