import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
class CognitoNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifClick: props.notifClick
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {notifClick} = props;
    if (state.notifClick !== notifClick) {
      return {
        notifClick: notifClick,
      };
    }
    return null;
  }

  handleOnNotification = () => {
    if (this.props.data === "MFA") {
      this.props.setProfileSecurityShow(true);
      this.props.redirectProfile();
    } else {
      this.props.redirectEmailVerification();
    }
  };

  handleClearNotification = () => {
    this.props.clearNotificationById(this.props.id);
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.notifyMenuOption]}>
        <TouchableOpacity 
          onPress={this.handleOnNotification}
          disabled={this.state.notifClick}>
          <View style={[stylesRes.notifyMaxView]}>
            <View style={[styles.ViewRow]}>
              <View style={styles.viewDetailSec}>
                <View style={styles.viewMainData}>
                  <View
                    style={[
                      styles.viewItemDesc,
                      styles.colPdNotify,
                      styles.notifyTitleBg
                    ]}
                  >
                    <View style={[styles.viewAvailableIcon]}>
                      {/* if orange circle avaiable */}
                      <View style={[styles.notifyComSec, styles.rightSpSm]}>
                        <View
                          style={[
                            styles.notifyCircle,
                            styles.notifyCircleMgTop
                          ]}
                        ></View>
                      </View>
                      {/* end */}
                    </View>

                    <View style={[styles.viewDataFull]}>
                      <Text style={[styles.textPrimeMdBold, styles.textBlack]} allowFontScaling= {false}>
                        {this.props.data === "MFA"
                          ? "Set up 2-Step Verification"
                          : "Your email needs to be verified"}
                      </Text>
                    </View>
                  </View>

                  <View style={[styles.notifyDescFull, styles.colPdNotify]}>
                    <View style={[styles.notifyDesc, styles.notifyDescLtPd]}>
                      <Text style={[styles.textPrimeMd, styles.textBlack]} allowFontScaling= {false}>
                        {this.props.data === "MFA"
                          ? "To have greater account security, please go to view profile and turn on two step verification"
                          : "Verifying your email will ensure account security and is needed to enable all features "}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setProfileSecurityShow: show =>
      dispatch(actions.setProfileSecurityShow(show)),
  };
};

export default connect(null, mapDispatchToProps)(CognitoNotification);
