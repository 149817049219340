import React from 'react';
import {View, TextInput} from 'react-native';
import styles from '../../Css';
import FieldTitleCommon from './fieldTitleCommon';
import * as FontScaling from '../../components/Text/fontScaling';
const inputField = React.forwardRef((props, ref) => (
  <View style={[styles.textInputWrapper, styles.postRelative]}>
    <FieldTitleCommon
      fieldAlign={props.fieldAlign}
      fieldBg={props.fieldBg}
      title={props.title}
      requirdStar={props.requirdStar}
      fieldLt={props.fieldLt}
      fieldSm={props.fieldSm}
      fieldRg={props.fieldRg}
      errorText={props.errorText}
      warningDuplication={props.warningDuplication}
      errorTextCom={props.errorTextCom}
    />
    <TextInput
      style={[
        styles.textInputField,
        props.fieldInfo ? styles.frameInfoPd : styles.framePd,
        props.fieldBg && props.fieldBg === 'grey'
          ? styles.bgThinGrey
          : props.fieldBg && props.fieldBg === 'blue'
          ? styles.bgBlueExLight
          : props.fieldBg && props.fieldBg === 'transparent'
          ? styles.bgTransparent
          : styles.bgWhite,
        props.fieldAlign && props.fieldAlign === 'center'
          ? styles.textAlignCenter
          : styles.textAlignLeft,
        props.invalid 
        ? styles.inValidColor 
        : props.warningDuplication
        ? styles.duplicateWarningColor 
        : props.success
        ? styles.premSelected
        : styles.validColor,
        props.fieldStyle,
      ]}
      allowFontScaling={false}
      onBlur={props.onBlur}
      underlineColorAndroid="transparent"
      value={props.value ? props.value : ''}
      onChangeText={props.onChangeText}
      editable={props.editable}
      placeholder={props.placeholder}
      returnKeyType={props.returnKeyType}
      onSubmitEditing={props.onSubmitEditing}
      onFocus={props.onFocus}
      ref={ref}
      maxLength={props.maxLength}
      textContentType={props.textContentType}
      keyboardType={props.keyboardType}
      maxFontSizeMultiplier={FontScaling.fontScaleField}
      nativeID={props.nativeId}
      autoComplete={'off'}
    />
  </View>
));

export default inputField;
