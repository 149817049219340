//NOT USED ANYMORE SINCE CR TO IMPLEMENT WEBVIEW TO DISPLAY DESCRIPTION
import React, { Component } from "react";
import ShowMoreText from "react-show-more-text";
import { Text, View } from "react-native";
import styles from "../../Css";

class ViewMoreText extends Component {
  renderViewMoreLess = (isMoreText) => {
    const {moreText, lessText, displayInNewRow, customStyle} = this.props;
    return displayInNewRow ? (
      <View style={styles.linkTextComSp}>
        <Text style={[styles.textPrime, styles.textOrange, styles.textUnderline,]} allowFontScaling={false}>
          {isMoreText 
            ? 'See more'
            : 'See less'}
        </Text>
      </View>
    ) : (
        <Text style={
          [styles.textUnderline,
          customStyle ? 
            customStyle
          : 
          [
            styles.textPrimeBold, 
            styles.menuTextActive , 
            styles.mgRtZero
          ]
        ]
        } 
          allowFontScaling={false}>
          {isMoreText 
            ? moreText ? moreText : 'See more'
            : lessText ? lessText : 'See less'}
        </Text>
      // </View>
      
    )
    
  }

  onClick = (e) => {
    const { onPressMore } = this.props;
    if (onPressMore) onPressMore()
  }
  render() {
    const { longText, numberOfLines } = this.props;
    return (
      <ShowMoreText
        lines={numberOfLines ? numberOfLines : 1}
        // more="Show more"
        // less="Show less"
        more={this.renderViewMoreLess(true)}
        less={this.renderViewMoreLess(false)}
        onClick={this.onClick}
        anchorClass=""
        expanded={false}
        className="noSpaceBreak"
        //width={280}
      >
        {longText} 
      </ShowMoreText>
    );
  }
}

export default ViewMoreText;
