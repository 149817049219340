import React, {Component} from 'react';
import {connect} from 'react-redux';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import ActivityIndicator from '../ActivityIndicator/activityIndicator';
import * as actions from '../../store/actions/index';
import styles from '../../Css';

class ImageUpload extends Component {
  state = {
    fileUploadWait: false,
    uploadError: false,
    uploadErrorMesage: '',
  };

  inputRef = React.createRef();

  componentDidMount = () => {
    const {getRef} = this.props;
    getRef && getRef(this);
  }

  getInputRef = () => {
    return this.inputRef;
  }

  getImageLoad = (e, callBack) => {
    const files = e.target.files;
    const formData = new FormData();
    let fileSize = 0;
    formData.append('imageContents', files[0]);
    fileSize += files[0].size;

    var reader = new FileReader();
    let image = null;
    reader.onload = function(event) {
      image = event.target.result;
      callBack(fileSize, formData, image);
    };
    reader.readAsDataURL(files[0]);
  };

  onChange = e => {
    this.getImageLoad(e, (fileSize, formData, image) => {
      // e.target.value = null;
      if (fileSize > 5000000) {
        this.setState({
          uploadError: true,
          uploadErrorMesage:
            'Sorry. File exceeds the maximum upload size of 5MB.',
        });
      } else {
        this.setState({fileUploadWait: true});
        // if (this.props.peer2peer && !this.props.imageUpload) {
        //   this.props.onUploadp2p(formData, (success, message) => {
        //     if (success) {
        //       this.props.onUploadSucess(image);
        //     } else {
        //       this.setState({uploadError: true, uploadErrorMesage: message});
        //     }
        //     this.setState({fileUploadWait: false});
        //   });
        // } else if (this.props.imageUpload) {
          this.props.getImageUrl(formData);
          this.props.onChangeProfileImage(image);
          this.props.removeProfilePic(false);
        //   this.props.onUploadSucess(image);
          this.setState({fileUploadWait: false});
        // } else {
        //   this.props.onUpload(formData, (success, message) => {
        //     if (success) {
        //       this.props.onUploadSucess(image);
        //     } else {
        //       this.setState({uploadError: true, uploadErrorMesage: message});
        //     }
        //     this.setState({fileUploadWait: false});
        //   }, this.props.journalUserId);
        // }
      }
    });
  };

  render() {
    return (
      <View style={[styles.mgLtTwenty, styles.flexJcFsAiFs]}>
        {this.state.fileUploadWait && <ActivityIndicator size="small" />}
        {(this.state.uploadError || this.props.uploadError) && !this.state.fileUploadWait && (
          <View style={[styles.errorMessage, styles.errorSm, styles.fieldRtPdSpec]}>
            <Image
              style={[styles.iconWarning, styles.imgSp]}
              resizeMode={'cover'}
              source={require('../../assets/icons/warning.png')}
            />
            <Text style={styles.warningText} allowFontScaling={false} >
              {this.state.uploadErrorMesage || this.props.uploadErrorMesage}
            </Text>
          </View>
        )}

        <TouchableOpacity>
          <View>
            <View style={[styles.updatedProfileUploadImage]}>
              <Image
                style={[styles.updatedProfileUploadIcn]}
                source={require('../../assets/profile/profile-upload-icon.png')}
              />
              <Text style={[styles.updatedProfileUploadTxt]} allowFontScaling= {false}>
                Upload Photo
              </Text>
            </View>
            <View style={[styles.fileUploadBtn, styles.updatedProfileUploadPhoto]}>
              <input
                type="file"
                name="file"
                id="btn"
                onChange={e => {
                  this.onChange(e);
                }}
                style={{cursor: 'pointer', width: '100%', height: 15}}
                onClick={(e) => {
                  this.setState({uploadError: false});
                  e.target.value = '';
                }}
                accept="image/*"
                ref={this.inputRef}
              />
              <label
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  height: 15,
                  position: 'absolute',
                  top: 0,
                }}
                htmlFor="btn">
                Upload
              </label>
            </View>
          </View>
        </TouchableOpacity>

        <View style={{height: 20}} />

        <TouchableOpacity
          disabled={this.props.disableRemoveBtn}
          onPress={() => {
            this.props.removeProfilePic(true);
            this.setState({uploadError: false});
          }}
        >
          <View style={[styles.updatedProfileRemovePhoto]}>
            <Image
              style={[styles.updatedProfileRemoveIcn]}
              source={require('../../assets/profile/profile-remove-icon.png')}
            />
          
            <Text style={[styles.updatedProfileRemoveTxt]} allowFontScaling= {false}>
              Remove Photo
            </Text>
          </View>
        </TouchableOpacity>

      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpload: (formData, callBack, journalUserId) =>
      dispatch(actions.uploadProfileImage(formData, callBack, journalUserId)),
    onUploadp2p: (formData, callBack) =>
      dispatch(actions.uploadp2pProfileImage(formData, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImageUpload);
