import theme from "./base/theme";

export const csssurveyCommonStyles = {

  surveyLogoWidth: {
    alignItems: 'center',
    marginBottom: 20,
    flex: 1

  },

  orangeButton: {
    alignItems: 'center',
    borderRadius: 5,
    justifyContent: 'center',
    backgroundColor: theme.COLOR_CARROT,
    height: 40,
    width: 323,
  },

  blueText: {
    color: theme.COLOR_BLUE_11,
  },

  lightBlueText: {
    color: theme.COLOR_BLUE_6,
  },

  orangeText: {
    color: theme.COLOR_CARROT,
  },

  surveyListBorder: {
    height: 64,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 5,
  },

  surveyListWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  nameStatusWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

  surveyStatusIcon: {
    width: 20,
    height: 20,
  },

  surveyStatusInnerWrapper: {
    marginRight: 5,
  },

  fixedWidthDateWrapper: {
    flexBasis: 115,
    maxWidth: 115
  },

  attachDownloadLoaderWrapper: {
    paddingRight: 15,
    paddingLeft: 15
  },

  pdfDownloadIcon: {
    width: 26,
    height: 35,

  },

  emailIcon: {
    width: 28,
    height: 19,
  },

  emailPdfIconsHeight: {
    height: 64,
    justifyContent: 'center',
    paddingRight: 15,
    paddingLeft: 15
  },

  surveyNotfoundProfileImage: {
    width: 151,
    height: 144
  },

  suggestedTextHighlighter: {
    borderColor: theme.ORANGE_HIGHLIGH_COLOR, 
    backgroundColor: theme.ORANGE_HIGHLIGH_COLOR
  },

  customTextHighlighter: {
    borderColor: theme.ORANGE_HIGHLIGH_COLOR
  }

};
