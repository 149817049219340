import React from "react";
import { View } from "react-native";
import styles from "../../Css";
import { TAB_BREAk_POINT } from "../../util/platformWindowConfig";

const spaceBarBottom = (props) => {
  return (
    <React.Fragment>
      {props.windowWidth < TAB_BREAk_POINT ? (
        <View
          style={[
            props.spaceBarLg
            ? styles.spaceBarBotFixedLg
            : styles.spaceBarBotFixed,
            props.viewBg === "white" ? styles.bgWhite : styles.bgTransparent,
          ]}
        ></View>
      ) : null}
    </React.Fragment>
  );
};

export default spaceBarBottom;
