import * as actionTypes from "./actionTypes";
import {axiosGET, axiosPUT, axiosDELETE} from "../../axios/axios-common";
import * as urls from "./urls";
import { logout, commonErrorHandler } from "./index";

//Get All Health Jounry Events
export const fetchAllHealthJourneyEvents = (journalUserId, callBack) => {    
    let requestUrl = urls.GET_ALL_HEALTH_JOURNEY_EVENT_LIST;
    if(journalUserId){
      requestUrl += "?journalUserId=" + journalUserId;
    }
    return(dispatch) => {
        dispatch({
            type: actionTypes.FETCH_ALL_HEALTH_JOURNEY_EVENT,
          });
        axiosGET(
            requestUrl,
            (res) => {
            if (res.type === "SUCCESS") {
                dispatch({
                    type: actionTypes.FETCH_ALL_HEALTH_JOURNEY_EVENT_SUCCESS,
                    payload: res.responseDto.data,
                });            
                callBack(true);
            } else {
                if (res.errorDto === "SESSION_TIME_OUT") {
                  console.log("session time out; fetchAllHealthJourneyEvents");
                  dispatch(logout());
                } else {
                dispatch({
                    type: actionTypes.FETCH_ALL_HEALTH_JOURNEY_EVENT_FAILS,
                    payload: 'Failed to fetch all health journey events',
                });
                dispatch(commonErrorHandler(res.errorDto.data.message || 'Failed to fetch all health journey events'));
                callBack(false)
                }
            }
            }
        );
    };
  };

  //Save and Update a Health Journey Event
  export const saveAndUpdateHealthJourneyEvent = ( journalUserId, eventData, callback) => {
    let requestUrl = urls.SAVE_AND_UPDATE_HEALTH_JOURNEY;
    if(journalUserId){
        requestUrl += "?journalUserId=" + journalUserId;
      }
    return (dispatch) => {
      dispatch({
        type: actionTypes.SAVE_AND_UPDATE_HEALTH_JOURNEY_EVENT,
      });
      axiosPUT(
        requestUrl,
        (res) => {
          if (res.type === "SUCCESS") {
            dispatch(fetchAllHealthJourneyEvents( journalUserId, (flag) => callback(flag, res.responseDto.data)));
            dispatch({
              type: actionTypes.SAVE_AND_UPDATE_HEALTH_JOURNEY_EVENT_SUCCESS,
              payload: res.responseDto.data,
            });
          } else if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; saveAndUpdateHealthJourneyEvent");
            dispatch(logout());
          } else {
            dispatch({
              type: actionTypes.SAVE_AND_UPDATE_HEALTH_JOURNEY_EVENT_FAILS,
              payload: res.errorDto.data.error || 'Failed to save/update Health Journey Event',
            });
            callback(false)
          }
        },
        eventData
      );
    };
  };

  //Delete Health Journey Event
  export const deleteHealthJourneyEvent = (journalUserId, eventId, callback) => {
    let requestUrl = urls.DELETE_HEALTH_JOURNEY.replace('{eventId}', eventId);
    if(journalUserId){
        requestUrl += "?journalUserId=" + journalUserId;
      }
    return (dispatch) => {
      dispatch({
        type: actionTypes.DELETE_HEALTH_JOURNEY_EVENT,
      });

      axiosDELETE(
        requestUrl,
        (res) => {
          if (res.type === "SUCCESS") {
            dispatch(fetchAllHealthJourneyEvents(journalUserId, () => {}));
            dispatch({
              type: actionTypes.DELETE_HEALTH_JOURNEY_EVENT_SUCCESS,
              payload: res.responseDto.data,
            });
            callback(true)
          } else if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; deleteHealthJourneyEvent");
            dispatch(logout());
          } else {
            dispatch({
              type: actionTypes.DELETE_HEALTH_JOURNEY_EVENT_FAILS,
              payload: res.errorDto.data.error || 'Error deleting Health journey event',
            });
          }
        }
      );
    };
  };

  //Get Health Journey By Id
  export const fetchHealthJourneyById = (journalUserId, eventId, callBack) => {
    let requestUrl = urls.GET_HEALTH_JOURNEY_EVENT_BY_ID.replace('{eventId}', eventId);
    if(journalUserId){
        requestUrl += "?journalUserId=" + journalUserId;
      }
    return (dispatch) => {
      dispatch({
        type: actionTypes.FETCH_HEALTH_JOURNEY_EVENT,
      });
  
    axiosGET(
        requestUrl,
        (res) => {
          if (res.type === "SUCCESS") {
            dispatch({
              type: actionTypes.FETCH_HEALTH_JOURNEY_EVENT_SUCCESS,
              payload: res.responseDto.data,
            });
            callBack(true)
          } else if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; fetchHealthJourneyById");
            dispatch(logout());
          } else {
            dispatch({
              type: actionTypes.FETCH_HEALTH_JOURNEY_EVENT_FAILS,
              payload: res.errorDto.data.error || 'Error loading Health Journey Data',
            });
            callBack(false)
          }
        }
      );
    };
  };

  //delete Health Journey Attachment
  export const deleteHealthJourneyAttachment = (attachmentId, eventId, callBack) => {
    let attachment = {
      attachmentId: attachmentId,
    };
    let requestUrl = urls.HEALTH_JOURNEY_DELETE_ATTACHMENT.replace('{eventId}', eventId);

    return dispatch => {
      axiosDELETE(
        requestUrl,
        (res) => {
          if (res.type === 'SUCCESS') {
            dispatch({
                type: actionTypes.DELETE_HEALTH_JOURNEY_ATTACHMENT_SUCCESS,
                payload: res.responseDto.data,
              });
            callBack(true)
          }  else if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; deleteHealthJourneyAttachment");
            dispatch(logout());
          } else {
            dispatch({
              type: actionTypes.DELETE_HEALTH_JOURNEY_ATTACHMENT_FAILS,
              payload: res.errorDto.data.error || 'Error deleting Health journey attachment',
            });
            callBack(false)
          }
        },
        attachment,
      );
    };
  };

  //get tags from backend
  export const fetchHealthJourneyTagsList = (filterKey, serviceType, callBack) => {
    let requestUrl = urls.GET_HEALTH_JOURNEY_TAGS_LIST.replace('{filterKey}', filterKey).replace('{serviceType}', serviceType);
    return dispatch => {
      axiosGET(
        requestUrl,
        (res) => {
          if (res.type === 'SUCCESS') {
            console.log('LOG', 'fetchHealthJourneyTagsList : SUCCESS ');
            dispatch({
                type: actionTypes.FETCH_HEALTH_JOURNEY_TAGS_LIST_SUCCESS,
                payload: res.responseDto.data,
              });
            callBack(true, res.responseDto.data)
          }  else if (res.errorDto === "SESSION_TIME_OUT") {
            console.log('LOG', 'fetchHealthJourneyTagsList : TIME_OUT ');
            dispatch(logout());
          } else {
            console.log('LOG', 'fetchHealthJourneyTagsList : ERROR ');
            dispatch({
              type: actionTypes.FETCH_HEALTH_JOURNEY_TAGS_LIST_FAILS,
              payload: res.errorDto.data.error || 'Error loading Health Journey Tags',
            });
            callBack(false)
          }
        }
      );
    };
  };

  export const resetHealthJourneyEvents = () => {
    return dispatch => {
      dispatch({
        type: actionTypes.RESET_HEALTH_JOURNEY_EVENTS,
      });
    };
  };

  export const setHealthJourneyTagsAndAttachmentsObject = (tagsAndAttachments) => {
    return dispatch => {
      dispatch({
        type: actionTypes.FETCH_HEALTH_JOURNEY_FILTER_TAGS_OBJECT,
        payload: tagsAndAttachments,
      });
    };
  };

  export const setHealthJourneyCategoryObject = (categoryObj) => {
    return dispatch => {
      dispatch({
        type: actionTypes.FETCH_HEALTH_JOURNEY_FILTER_CATEGORY_OBJECT,
        payload: categoryObj,
      });
    };
  };