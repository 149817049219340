import React from "react";
import { View, Text, Image } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import Modal from "../../components/Modal/modal";
import AddMethodforEntry from "../../components/ConfirmationBox/addMethodforEntry";
import ImageTakePhotoPopup from "../../components/Attachment/AttachmentImageBoxUpdated/imageTakePhotoPopup";

const fileUpload = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <React.Fragment>
      {Boolean(props.uploadError) && (
        <View style={stylesRes.uploadErrorMessage} dataSet={{media: ids.uploadErrorMessage}}>
          <Image
            style={[styles.iconWarning, styles.imgSp]}
            resizeMode={"cover"}
            source={require("../../assets/icons/warning.png")}
          />
          <Text style={styles.warningText} allowFontScaling={false}>{props.uploadErrorMesage}</Text>
        </View>
      )}

      <Modal
        visible={props.visibleEntryModal}
        //onRequestClose={props.onRequestClose}
        onRequestClose={props.onPressClose}
        //onBackdropPress={props.onBackdropPress}
        onBackdropPress={props.onPressClose}
        // defaultStyle={[styles.customPopUpStyle, styles.mgTopUpload]}
      >
        <AddMethodforEntry
          onPressClose={props.onPressClose}
          // onPressUploadEntry={this.onPressUploadEntry}
          onPressTakePhoto={props.onPressTakePhoto}
          onChange={(e) => {
            props.onChangeFileUpload(e, null, 'FILE_UPLOAD');
          }}
          selected={props.selected}
          nativeIdTakePhoto={'attachmentsTakePhoto'}
          nativeIdUploadFile={'attachmentsUploadFile'}
          showSelectFromZamplo={props.showSelectFromZamplo}
          onSelectFromZamplo={props.onSelectFromZamplo}
        />
      </Modal>
      {/* <Modal
        visible={props.visibleImageTakePhoto}
        onRequestCloseImg={props.onRequestCloseImg}
        onBackdropPressImg={props.onBackdropPressImg}
      > */}
      {Boolean(props.visibleImageTakePhoto) && (
          <ImageTakePhotoPopup
            cropSuccess={props.cropSuccess}
            // onCropAddImage={(e, image64) => {
            //   props.onChangeFileUpload(e, image64);
            // }}
            onCropAddImage={(croppedImages, fileNamePrefix) => props.onChangeFileUpload(croppedImages, fileNamePrefix, 'CAMERA')}
            artifactId={false}
            close={props.close}
          />
        )}
      {/* </Modal> */}
    </React.Fragment>
  );
};

export default (fileUpload);
