import React, {Component} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import InfiniteScroll from 'react-infinite-scroll-component';
import RoutineItem from '../../components/RoutineTemplate/RoutineItem';
import styles from '../../Css';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import moment from 'moment';
import _ from 'underscore';
import {connect} from 'react-redux';
import Highlighter from '../../components/Highlighter/highlighter';
import PageContainer from '../../components/PageLayout/PageContainer';

class RutineItemScrollView extends Component {
  constructor(props) {
    super(props);
    const items = Array.from(props.data.slice(0, 19));
    this.state = {
      items: items,
      hasMore: items.length < props.data.length,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      fatchMoreData: false,
    };
    this.firstJournal = null;
  }

  // UNSAFE_componentWillReceiveProps(props) {
  //   const {windowHeight, windowWidth} = props;
  //   const items = Array.from(props.data.slice(0, 19));
  //   this.setState({
  //     items: items,
  //     hasMore: items.length > 0,
  //     windowWidth,
  //     windowHeight,
  //   });
  // }

  componentDidMount() {
    this.refUpdate();
  }

  refUpdate = () => {
    const {getRef} = this.props;
    getRef && getRef(this);
  };

  getFirstJournal = () => {
    return this.firstJournal;
  }

  refresh = () => {
    this.firstJournal = null;
    this.forceUpdate(() => {
      if (this.firstJournal) {
        this.props.setViewJournal(this.firstJournal);
      }
    });
  }


  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth} = props;
    const items = Array.from(props.data.slice(0, 19));
    const hasMore = props.data.length > items.length;

    if (state.fatchMoreData) {
      return {
        fatchMoreData: false,
      };
    }
    return {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      items: items,
      hasMore: hasMore,
    };
  }

  fetchMoreData = () => {
    if (this.state.items.length >= this.props.data.length) {
      this.setState({hasMore: false, fatchMoreData: true});
      return;
    } else {
      const items = this.state.items.concat(
        this.props.data.slice(
          this.state.items.length,
          this.state.items.length + 19,
        ),
      );

      if (items.length > this.state.items.length) {
        setTimeout(() => {
          this.setState({
            items: items,
            hasMore: this.props.data.length > items.length,
            fatchMoreData: true,
          });
        }, 1500);
      }
    }
  };

  renderHighlightTextContent = (textToHighlight, searchWords, style={}) => {
    return (
      (textToHighlight !== null && textToHighlight !== undefined) && 
        <Highlighter
          searchWords={searchWords}
          textToHighlight={textToHighlight}
          style={!_.isEmpty(style) && style}
        />
    );
  }

  render() {
    const questionsFilterResults = (contactArray, props) => {
      let questionList = [];
      let contList = [];
      const questionArtifact = props.questionDigest.userArtifactHealthQuestions.filter(
        re => {
          return (
            re.artifactId.toString() === contactArray.artifactId.toString()
          );
        },
      );
      if (questionArtifact && questionArtifact.length > 0) {
        questionArtifact.forEach(qid => {
          questionList.push(
            props.questionDigestProcessed.questions[qid.questionId],
          );
        });
      }

      if (
        questionList &&
        props.journalEntryFilter &&
        props.journalEntryFilter.searchKeyword
      ) {
        contList = questionList.filter(qcon => {
          return qcon.content
            .toLowerCase()
            .includes(props.journalEntryFilter.searchKeyword.toLowerCase());
        });
        if (contList && contList.length > 0) {
          return (
            <View style={[styles.viewMoreDataSingleSub, styles.viewMgSm]}>
              <View style={[styles.resultTitle]}>
                <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>Questions</Text>
              </View>
              {contList.map((res, x) => {
                return (
                  <View
                    style={styles.viewMorePdLg}
                    key={x}>
                    <View style={[styles.viewMoreDataItemSub]}>
                      {this.renderHighlightTextContent(res.content, props.journalEntryFilter.searchKeyword)}
                    </View>
                  </View>
                );
              })}
            </View>
          );
        }
      }
    };

    const attachemntFilterResults = (contactArray, props) => {
      let attList = [];
      if (
        props.obj &&
        props.obj[contactArray.artifactId] &&
        props.obj[contactArray.artifactId].length > 0
      ) {
        props.obj[contactArray.artifactId].forEach(rr => {
          attList.push(rr);
        });
      }
      attList = [...new Set(attList)];
      if (
        props.journalEntryFilter &&
        props.journalEntryFilter.searchKeyword &&
        attList &&
        attList.length > 0
      ) {
        return (
          <View style={[styles.viewMoreDataSingleSub, styles.viewMgSm]}>
            <View style={[styles.resultTitle]}>
              <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>Attachments</Text>
            </View>

            {attList.map((att, y) => {
              return (
                <View
                  style={styles.viewMorePdLg}
                  key={y}>
                  <View style={[styles.resultSubTitle]}>
                    <View style={[styles.resultTitleWrapper]}>
                      {attList.length !== 2 && (
                        this.renderHighlightTextContent(att.filename, props.journalEntryFilter.searchKeyword)
                      )}
                      {att.instanceId === contactArray.artifactId &&
                        att.searchWordContainingSentences &&
                        att.searchWordContainingSentences.length > 0 && (
                          this.renderHighlightTextContent(att.fileName, props.journalEntryFilter.searchKeyword)
                      )}
                      {(attList.length !== 2 || 
                        (att.instanceId === contactArray.artifactId &&
                          att.searchWordContainingSentences &&
                            att.searchWordContainingSentences.length > 0)) &&
                        <View style={styles.resultDateWrapper}>
                          <Text style={[styles.textPrimeSm, styles.textAlignLeft]} allowFontScaling={false}>
                            {att.uploadDate !== null &&
                              moment(att.uploadDate).format("DD MMM 'YY")}
                          </Text>
                        </View>
                      }
                    </View>
                  </View>
                  {att.instanceId === contactArray.artifactId
                    ? att.searchWordContainingSentences &&
                      att.searchWordContainingSentences.length > 0
                      ? att.searchWordContainingSentences.map((re, x) => {
                          return (
                            <View
                              style={[
                                styles.viewMoreDataItemSub,
                                styles.viewMoreDataTopMg,
                              ]}
                              key={x}>
                                {this.renderHighlightTextContent(re, props.journalEntryFilter.searchKeyword)}
                            </View>
                          );
                        })
                      : null
                    : null}
                </View>
              );
            })}
          </View>
        );
      }
    };

    const notesFilterResults = (res, props) => {
      return res.entry &&
        props.journalEntryFilter &&
        props.journalEntryFilter.searchKeyword ? (
        res.entry
          .toLowerCase()
          .includes(props.journalEntryFilter.searchKeyword.toLowerCase()) ? (
          <View style={[styles.viewMoreDataSingleSub, styles.viewMgSm]}>
            <View style={[styles.resultTitle]}>
              <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>Notes</Text>
            </View>
            <View style={styles.viewMorePdLg}>
              <View style={[styles.viewMoreDataItemSub]}>
                {this.renderHighlightTextContent(res.entry, props.journalEntryFilter.searchKeyword)}
              </View>
            </View>
          </View>
        ) : null
      ) : null;
    };
    const therapiesFilter = (res, props) => {
      let therapy = [];
      if (
        props.journalEntryFilter &&
        props.journalEntryFilter.searchKeyword &&
        res.dosages &&
        res.dosages.length > 0
      ) {
        res.dosages.filter(re => {
          return props.treatmentsProcessed[re.treatment] &&
            props.treatmentsProcessed[re.treatment].name
              .toLowerCase()
              .includes(props.journalEntryFilter.searchKeyword.toLowerCase())
            ? therapy.push(props.treatmentsProcessed[re.treatment].name)
            : null;
        });
      }

      return therapy && therapy.length > 0 ? (
        <View style={[styles.viewMoreDataSingleSub, styles.viewMgSm]}>
          <View style={[styles.resultTitle]}>
            <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>Therapies:</Text>
          </View>
          <View style={styles.viewMorePdLg}>
            {therapy.map((re, x) => {
              return (
                <View
                  style={[styles.viewMoreDataItemSub, styles.viewMoreDataTopMg]}
                  key={x}>
                    {this.renderHighlightTextContent(re, props.journalEntryFilter.searchKeyword)}
                </View>
              );
            })}
          </View>
        </View>
      ) : null;
    };

    const symptomsFilter = (res, props) => {
      let filter = [];
      if (
        props.journalEntryFilter &&
        props.journalEntryFilter.searchKeyword &&
        res.symptoms &&
        res.symptoms.length > 0
      ) {
        res.symptoms.filter(re => {
          return props.symptomsDigestProcessed.userSymptoms[re.symptom] &&
            props.symptomsDigestProcessed.userSymptoms[re.symptom].name
              .toLowerCase()
              .includes(props.journalEntryFilter.searchKeyword.toLowerCase())
            ? filter.push(
                props.symptomsDigestProcessed.userSymptoms[re.symptom].name,
              )
            : null;
        });
      }

      return filter && filter.length > 0 ? (
        <View style={[styles.viewMoreDataSingleSub, styles.viewMgSm]}>
          <View style={[styles.resultTitle]}>
            <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>Symptoms:</Text>
          </View>
          <View style={styles.viewMorePdLg}>
            {filter.map((re, x) => {
              return (
                <View
                  style={[styles.viewMoreDataItemSub, styles.viewMoreDataTopMg]}
                  key={x}>
                    {this.renderHighlightTextContent(re, props.journalEntryFilter.searchKeyword)}
                </View>
              );
            })}
          </View>
        </View>
      ) : null;
    };

    const metricsFilter = (res, props) => {
      let filter = [];
      if (
        props.journalEntryFilter &&
        props.journalEntryFilter.searchKeyword &&
        res.metrics &&
        res.metrics.length > 0
      ) {
        res.metrics.filter(re => {
          return props.metricsDigestProcessed.selectableMetrics[re.metric] &&
            props.metricsDigestProcessed.selectableMetrics[re.metric].name
              .toLowerCase()
              .includes(props.journalEntryFilter.searchKeyword.toLowerCase())
            ? filter.push(
                props.metricsDigestProcessed.selectableMetrics[re.metric].name,
              )
            : null;
        });
      }

      return filter && filter.length > 0 ? (
        <View style={[styles.viewMoreDataSingleSub, styles.viewMgSm]}>
          <View style={[styles.resultTitle]}>
            <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>Metrics:</Text>
          </View>
          <View style={styles.viewMorePdLg}>
            {filter.map((re, x) => {
              return (
                <View
                  style={[styles.viewMoreDataItemSub, styles.viewMoreDataTopMg]}
                  key={x}>
                    {this.renderHighlightTextContent(re, props.journalEntryFilter.searchKeyword)}
                </View>
              );
            })}
          </View>
        </View>
      ) : null;
    };

    const RoutineItemView = (item, index) => {
      return (
        <React.Fragment key={'rn_sv_' + index}>
          <RoutineItem
            journalEntry={item}
            highlightColor={
              item.id && this.props.journalEntrySelected
                ? item.id == this.props.journalEntrySelected.id
                  ? true
                  : false
                : false
            }
            journalEntrySelected={this.props.journalEntrySelected}
            handleOnPressAttachments={this.props.handleOnPressAttachments}
            handleOnChangeflagged={this.props.handleOnChangeflagged}
            key={item.id}
            routineTitle={item.title}
            routineDate={moment(item.entryDate).format("DD MMM 'YY")}
            routineTime={moment(item.entryDate).format('h:mm a')}
            journalEntryType={
              this.props.journalEntryTypesProcessed[item.entryType]
            }
            flagged={item.flagged}
            journalHasAttachments={this.props.journalHasAttachments(
              item.artifactId,
            )}
            index={index}
            windowWidth={this.props.windowWidth}
            selectedItem={this.props.selectedItem}
          />
          {notesFilterResults(item, this.props) ||
          questionsFilterResults(item, this.props) ||
          attachemntFilterResults(item, this.props) ||
          therapiesFilter(item, this.props) ||
          symptomsFilter(item, this.props) ||
          metricsFilter(item, this.props) ? (
            <TouchableOpacity
              onPress={() => this.props.handleOnPressAttachments(item)}>
              <View style={[styles.viewMoreSection]}>
                {notesFilterResults(item, this.props)}
                {questionsFilterResults(item, this.props)}
                {attachemntFilterResults(item, this.props)}
                {therapiesFilter(item, this.props)}
                {symptomsFilter(item, this.props)}
                {metricsFilter(item, this.props)}
              </View>
            </TouchableOpacity>
          ) : null}
        </React.Fragment>
      );
    };

    // const lastBegin = moment()
    //   .subtract(1, 'week')
    //   .startOf('week')
    //   .format('YYYY-MM-DD');
    // const lastEnd = moment()
    //   .subtract(1, 'week')
    //   .endOf('week')
    //   .format('YYYY-MM-DD');

    const thisBegin = moment()
      .startOf('week')
      .format('YYYY-MM-DD');
    const thisEnd = moment()
      .endOf('week')
      .format('YYYY-MM-DD');

    const startOfMonth = moment()
      .startOf('month')
      .format('YYYY-MM-DD');
    const endOfMonth = moment()
      .endOf('month')
      .format('YYYY-MM-DD');

    const todayData = filterJournalEntries => {
      const today = filterJournalEntries.filter(res => {
        return (
          new moment(res.entryDate).format('YYYY-MM-DD') ===
          moment().format('YYYY-MM-DD')
        );
      });
      if (today && today.length > 0) {
        this.firstJournal = today[0];
      }
      return today && today.length > 0 ? (
        <View style={styles.searchCategoryWrapper}>
          <View style={styles.categoryItemTitle}>
            <Text style={[styles.textPrimeBold]} allowFontScaling={false}>Today</Text>
          </View>
          {today.map((item, index) => RoutineItemView(item, index))}
        </View>
      ) : null;
    };

    const yesterdayData = filterJournalEntries => {
      const yesterday = filterJournalEntries.filter(res => {
        return (
          new moment(res.entryDate).format('YYYY-MM-DD') ===
          moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        );
      });
      if (yesterday && yesterday.length > 0 && !this.firstJournal) {
        this.firstJournal = yesterday[0];
      }
      return yesterday && yesterday.length > 0 ? (
        <View style={styles.searchCategoryWrapper}>
          <View style={styles.categoryItemTitle}>
            <Text style={[styles.textPrimeBold]} allowFontScaling={false}>Yesterday</Text>
          </View>
          {yesterday.map((item, index) => RoutineItemView(item, index))}
        </View>
      ) : null;
    };

    const thisWeekData = filterJournalEntries => {
      const thisWeek = filterJournalEntries.filter(res => {
        return (
          thisBegin <= new moment(res.entryDate).format('YYYY-MM-DD') &&
          new moment(res.entryDate).format('YYYY-MM-DD') <= thisEnd &&
          new moment(res.entryDate).format('YYYY-MM-DD') !==
            moment()
              .subtract(1, 'days')
              .format('YYYY-MM-DD') &&
          new moment(res.entryDate).format('YYYY-MM-DD') !==
            moment().format('YYYY-MM-DD')
        );
      });
      if (thisWeek && thisWeek.length > 0 && !this.firstJournal) {
        this.firstJournal = thisWeek[0];
      }
      return thisWeek && thisWeek.length > 0 ? (
        <View style={styles.searchCategoryWrapper}>
          <View style={styles.categoryItemTitle}>
            <Text style={[styles.textPrimeBold]} allowFontScaling={false}>This Week</Text>
          </View>
          {thisWeek.map((item, index) => RoutineItemView(item, index))}
        </View>
      ) : null;
    };

    // const lastWeekData = filterJournalEntries => {
    //   const lastWeek = filterJournalEntries.filter(res => {
    //     return (
    //       lastBegin <= new moment(res.entryDate).format('YYYY-MM-DD') &&
    //       new moment(res.entryDate).format('YYYY-MM-DD') <= lastEnd
    //     );
    //   });
    //   return lastWeek && lastWeek.length > 0 ? (
    //     <View style={styles.searchCategoryWrapper}>
    //       <View style={styles.categoryItemTitle}>
    //         <Text style={[styles.textPrimeBold]}>Last Week</Text>
    //       </View>
    //       {lastWeek.map((item, index) => RoutineItemView(item, index))}
    //     </View>
    //   ) : null;
    // };

    const thisMonthData = filterJournalEntries => {
      const thisMonth = filterJournalEntries.filter(res => {
        return (
          startOfMonth <= new moment(res.entryDate).format('YYYY-MM-DD') &&
          new moment(res.entryDate).format('YYYY-MM-DD') <= endOfMonth &&
          !(
            thisBegin <= new moment(res.entryDate).format('YYYY-MM-DD') &&
            new moment(res.entryDate).format('YYYY-MM-DD') <= thisEnd
          )
        );
      });
      if (thisMonth && thisMonth.length > 0 && !this.firstJournal) {
        this.firstJournal = thisMonth[0];
      }
      return thisMonth && thisMonth.length > 0 ? (
        <View style={styles.searchCategoryWrapper}>
          <View style={styles.categoryItemTitle}>
            <Text style={[styles.textPrimeBold]} allowFontScaling={false}>This Month</Text>
          </View>
          {thisMonth.map((item, index) => RoutineItemView(item, index))}
        </View>
      ) : null;
    };

    const olderData = filterJournalEntries => {
      const older = filterJournalEntries.filter(res => {
        return (
          new moment(res.entryDate).format('YYYY-MM-DD') < startOfMonth &&
          !(
            thisBegin <= new moment(res.entryDate).format('YYYY-MM-DD') &&
            new moment(res.entryDate).format('YYYY-MM-DD') <= thisEnd
          )
        );
      });
      if (older && older.length > 0 && !this.firstJournal) {
        this.firstJournal = older[0];
      }
      return older && older.length > 0 ? (
        <View style={styles.searchCategoryWrapper}>
          <View style={styles.categoryItemTitle}>
            <Text style={[styles.textPrimeBold]} allowFontScaling={false}>Older</Text>
          </View>
          {older.map((item, index) => RoutineItemView(item, index))}
        </View>
      ) : null;
    };

    return (
      // <div id="scrollableDiv" style={{height: scrollHeight, overflowY: 'auto'}}>
      // <div id="scrollableDiv">
      <PageContainer smScreenFull={true} outlookScreen={true} screenFull={true}>
        {this.props.filterWait ? (
          <ActivityIndicator />
        ) : (
          <InfiniteScroll
            dataLength={this.state.items.length}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv">
            {todayData(this.state.items)}
            {yesterdayData(this.state.items)}
            {thisWeekData(this.state.items)}
            {thisMonthData(this.state.items)}
            {olderData(this.state.items)}
            {/* {this.state.items.map((item, index) => (
                <React.Fragment key={'rn_sv_' + index}>
                  <RoutineItem
                    journalEntry={item}
                    handleOnPressAttachments={
                      this.props.handleOnPressAttachments
                    }
                    handleOnChangeflagged={this.props.handleOnChangeflagged}
                    key={item.id}
                    routineTitle={item.title}
                    routineDate={moment(item.entryDate).format('MM.DD.YYYY')}
                    routineTime={moment(item.entryDate).format('h:mm a')}
                    journalEntryType={
                      this.props.journalEntryTypesProcessed[item.entryType]
                    }
                    flagged={item.flagged}
                    onDeleteJournal={() => this.props.onDeleteJournal(item.id)}
                    journalHasAttachments={this.props.journalHasAttachments(
                      item.artifactId,
                    )}
                    index={index}
                    handleCopyToNew={() =>
                      this.props.handleCopyToNew &&
                      this.props.handleCopyToNew(item)
                    }
                    handleEditJournal={() =>
                      this.props.handleEditJournal &&
                      this.props.handleEditJournal(item)
                    }
                  />
                  {notesFilterResults(item, this.props) ||
                  questionsFilterResults(item, this.props) ||
                  attachemntFilterResults(item, this.props) ||
                  therapiesFilter(item, this.props) ||
                  symptomsFilter(item, this.props) ||
                  metricsFilter(item, this.props) ? (
                    <View style={[styles.viewMoreSection]}>
                      {notesFilterResults(item, this.props)}
                      {questionsFilterResults(item, this.props)}
                      {attachemntFilterResults(item, this.props)}
                      {therapiesFilter(item, this.props)}
                      {symptomsFilter(item, this.props)}
                      {metricsFilter(item, this.props)}
                    </View>
                  ) : null}
                </React.Fragment>
              ))} */}
          </InfiniteScroll>
        )}
        <View style={{height: 100}} />
      </PageContainer>
      // </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
    journalEntrySelected: state.routines.journalEntrySelected,
  };
};

export default connect(
  mapStateToProps,
  null,
)(RutineItemScrollView);
