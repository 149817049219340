import React, {Component} from "react";
import { Text, View, Platform, Switch } from "react-native";
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import styles from "../../../Css";
import Modal from "../../Modal/modal";
import ModalLayoutSpec from "../../ModalLayout/modalLayoutSpec";
import PrimaryButton from '../../Button/primaryButton';
import { IOS } from "../../../util/platformWindowConfig";


class makeProfilePublicPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendRequestWait: false,
      visibility: 'PRIVATE'
    };
  }

  sendConectionRequest = () => {
    const {
      selectedUser, 
      connectionRequestMessage, 
      onUpdatePeerProfileToPublic, 
      onSendConnectionRequest,
      onUpdateConnectionStatus
    } = this.props;
    this.setState({sendRequestWait: true});
    //update profile visibility to public
    onUpdatePeerProfileToPublic((success) => {
      if (success) {
        //send connection request
        const connectionRequest ={
          peerId: selectedUser.id,
          userName: selectedUser.userName,
          message: connectionRequestMessage,
        }
        onSendConnectionRequest(
            connectionRequest, 
            (success, res) => {
              if (success) {
                this.setState({sendRequestWait: false});
                this.props.hidePopup('MAKE_PROFILE_PUBLIC', true);
                setTimeout(() => {
                  this.props.hidePopup();
                }, 3000); 
                onUpdateConnectionStatus(res.requestTo, res.id, res.connectionStatus);
              } else {
                this.setState({sendRequestWait: false});
              }
            }
        );  
      } else {
        this.setState({sendRequestWait: false});
      }
    });
  }

  handleOnVisibilityChange = value => {
    const {updatePeer2PeerProfile, journalUserId, profile} = this.props;
    this.setState({
      visibility: value ? 'PRIVATE' : 'PUBLIC'
    }, () => {

      let p2pProfile = {
        userName:  profile?.username,
        story: profile?.story,
        visibility: value ? 'PRIVATE' : 'PUBLIC'
      };

      updatePeer2PeerProfile(p2pProfile, ()=>{}, journalUserId);

    });
  }
  render() {
    const {sendRequestWait, visibility} = this.state;
    return (
      <Modal
        visible={this.props.visibleModal}
        onBackdropPress={() => this.props.hidePopup('MAKE_PROFILE_PUBLIC')}
      >
        <ModalLayoutSpec
          contPosition={"center"}
          showClose={true}
          hidePopup={() => this.props.hidePopup('MAKE_PROFILE_PUBLIC')}
        >
          <View style={styles.popupContentWrapper}>
            <View style={[styles.popMessageContView, styles.comSp]}>
              <View style={[styles.popMessageTitleView]}>
                <Text style={[styles.textPrimeBold, styles.textAlignCenter]} allowFontScaling= {false}>
                  To connect, your profile must {"\n"}be set to public
                </Text>
              </View>
            </View>
            <View style={styles.modalSectionView}>
              <View
                style={[
                  styles.listItemContent,
                  styles.flexRow,
                  styles.comSp,
                  styles.comMgTopSm,
                ]}
              >
                <View style={[
                  Platform.OS === IOS
                  ? styles.tickIconViewWrapperLg
                  : styles.tickIconViewWrapper
                  ]}>
                  <View style={[styles.toggleButtonView, styles.flexAiFs]}>
                    <Switch
                      style={{
                        transform: [{scaleX: 1.0}, {scaleY: 1.0}],
                      }}
                      onValueChange={this.handleOnVisibilityChange}
                      value={visibility === 'PUBLIC' ? false : true}
                    />
                  </View>
                </View>
                <View style={[styles.flexCom]}>
                  <Text style={[styles.textPrimeBold]} allowFontScaling= {false}>Incognito Mode</Text>
                  <Text style={[styles.textPrimeExSm]} allowFontScaling= {false}>
                    Turn off incognito mode to connect with connections
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.popBtnView, styles.comMgTopSm]}>
              <View style={[styles.btnArea, styles.flexRow, styles.flexJcCtAiCt]}>
                <View style={styles.fieldBtn}>
                  <PrimaryButton
                    btnText={"Okay"}
                    btnPosition={"full"}
                    touchableOnPress={this.sendConectionRequest}
                    activityInd={sendRequestWait}
                    disabled={sendRequestWait || visibility === 'PRIVATE'}
                    btnStyle={[
                      styles.submitBgBlue,
                      styles.primaryButtonLgPd,
                      sendRequestWait || visibility === 'PRIVATE'
                        ? styles.btnBlurState
                        : styles.btnActiveState,
                    ]}
                  />
                </View>
              </View>
            </View>
          </View>
        </ModalLayoutSpec>
      </Modal>
    );
  };
};

const mapStateToProps = state => {
  return {
    connectionRequestMessage: state.peerToPeer.connectionRequestMessage,
    journalUserId: state.uistate.journalUserId,
    profile: state.routines.peer2peer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdatePeerProfileToPublic: (callBack) => 
      dispatch(actions.updatePeerProfileToPublic(callBack)),
    onSendConnectionRequest: (connectionRequest, callBack) => 
      dispatch(actions.sendConnectionRequest(connectionRequest, callBack)), 
    updatePeer2PeerProfile: (p2pProfile, callBack, journalUserId) =>
      dispatch(
        actions.updatePeer2PeerProfile(p2pProfile, callBack, journalUserId),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(makeProfilePublicPopup);
