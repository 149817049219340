import React, { Component } from 'react';
import { View, Text } from 'react-native';
import styles from '../../../../Css';
import { connect } from 'react-redux';
import TextPickerFrame from '../../../TextInput/textPickerFrame';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextInputPlusMinus from '../../../TextInput/TextInputPlusMinus';
import * as actions from '../../../../store/actions/index';
import MedticationTooltipContent from '../../../TooltipContent/Medtication';
import TextFrame from '../../../TextInput/textFrame';
import ItemDeleteButton from '../../../Button/itemDeleteButton';
import Warning from '../../../Warning/warning';
import { verifyDuplicateMedication, getMedicationUnits } from '../../../../util/commonUiLogic';

class SystemItem extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    this.state = {
      ...data,
      units: [],
      accept: true,
      wait: false,
      duplicatePopup: false,
      data
    };
  }


  componentDidMount() {
    const { data, treatmentsProcessed, drugAdministrationsDigits } = this.props;
    const { treatment } = data;
    const { identificationNumber, treatmentType, dosages } = treatmentsProcessed[
      treatment
    ];

    if (identificationNumber && treatmentType) {
      this.getTreatmentInfoValues(identificationNumber, treatmentType);
    } else {
      const units = getMedicationUnits(null, drugAdministrationsDigits);
      const unit = data?.dosages?.[0]?.units ?? data?.unit ?? dosages?.[0]?.units ?? 'Tablets';
      this.setState({
        units: units,
        unit: unit,
      });
      setTimeout(() => {
        this.verifyDuplicateMedication(data.name, null, unit, this.state.quantity);
      }, 0);
    }
  }

  componentDidUpdate(prevPros, prevStates) {
    const { data = {} } = this.props;

    if (!isNaN(data.quantity) && data.quantity !== this.state?.data?.quantity) {
      this.setState({
        quantity: data.quantity,
        data
      })
    }

  }

  getTreatmentInfoValues = (din_npn, treatmentType) => {
    const { name, unit, quantity } = this.state;
    const { data, treatmentsProcessed, drugAdministrationsDigits, getTreatmentInfo } = this.props;
    const { treatment } = data;
    const { dosages } = treatmentsProcessed[treatment];
    const units = getMedicationUnits(null, drugAdministrationsDigits);
    getTreatmentInfo(din_npn, treatmentType, (success, res) => {
      if (success) {
        const { unitValue, unitList } = res;
        this.setState({
          units: unitList,
          unit: data?.dosages?.[0]?.units ?? unitValue,
        }, () => {
          this.verifyDuplicateMedication(name, din_npn, (data?.dosages?.[0]?.units ?? unitValue), quantity);
        });
      } else {
        const unit =
        dosages && dosages.length > 0 && dosages[0].units
          ? dosages[0].units
          : null;
        this.setState({
          units: units,
          unit: unit,
        }, () => {
          this.verifyDuplicateMedication(name, din_npn, this.state.unit, quantity);
        });
      }
    },
    unit,
    units,
    drugAdministrationsDigits,
    false,
    data?.dosages?.[0]?.units);
  };

  onValueChange = val => {
    this.setState({ unit: val }, () => {
      const { data, updateMedication, index, treatmentsProcessed} = this.props;
      const { treatment } = data;
      const { name, identificationNumber, publishedBy } = treatmentsProcessed[treatment];
      const { quantity } = this.state;
      updateMedication(index, {
        ...data,
        unit: val,
        quantity: quantity,
        edited: publishedBy === 'PARTNER' ? false : true,
      });
      this.verifyDuplicateMedication(
        name,
        identificationNumber,
        val,
        quantity,
      );
    });
  };

  onChange = val => {
    this.setState({ quantity: val }, () => {
      const { data, index, updateMedication, treatmentsProcessed } = this.props;
      const { treatment } = data;
      const { name, identificationNumber, publishedBy } = treatmentsProcessed[treatment];
      const { unit } = this.state;
      updateMedication(index, { ...data, unit: unit, quantity: val, edited: publishedBy === 'PARTNER'  ? false : true });
      this.verifyDuplicateMedication(
        name,
        identificationNumber,
        unit,
        val,
      );
    });
  };

  verifyDuplicateMedication = (name, din_npn, unit, quantity) => {
    const { listTherapie, hasInvalidNamesInActivity, hasInvalidNamesInMedication, hasInvalidNamesInHealthData,
      hasInvalidNamesInSymtoms } = this.props;

    verifyDuplicateMedication(name, din_npn, unit, quantity, listTherapie, (exist, msg) => {
      if (exist) {
        this.setState(
          {
            duplicatePopup: true,
            errorDescription: msg,
          },
          () => {
            this.props.saveDisable(this.state.duplicatePopup);
          },
        );
      } else {
        this.setState(
          {
            duplicatePopup: false,
            errorDescription: null,
          },
          () => {
            if (!hasInvalidNamesInActivity && !hasInvalidNamesInMedication && !hasInvalidNamesInHealthData && !hasInvalidNamesInSymtoms) {
              this.props.saveDisable(false);
            } else {
              this.props.saveDisable(this.state.duplicatePopup);
            }
          },
        );
      }
    });
  };

  render() {
    const {
      data,
      handleRedirectMedications,
      treatmentsProcessed,
      deleteItem,
      index,
      windowWidth,
    } = this.props;
    const { treatment } = data;
    const { name, identificationNumber, treatmentType, dosages } = treatmentsProcessed[
      treatment
    ];
    const units = dosages && dosages[0] && dosages[0]?.units ? dosages[0]?.units : null;
    const {
      quantity,
      unit,
      duplicatePopup,
      errorDescription,
    } = this.state;
    let category = this.props.type;

    return (
      <>
        <View style={category !== 'RT' && [styles.ActivitySliderCardView, styles.bgDarkAsh]}>
          {category === 'RT' &&
            <View style={styles.fieldContainerCom}>
              <View style={[styles.fieldRowWrapper]}>
                <View style={styles.fieldColStretch}>
                  <TextFrame
                    title={'Medication'}
                    fieldBg={FieldDetails.BGGREY}
                    fieldInfo={true}
                    fieldValue={name}
                    editable={false}
                    // fieldInfo={type === 'SYSTEM' ? true : false}
                    renderComponent={() => (
                      <MedticationTooltipContent
                        identificationNumber={identificationNumber}
                        treatmentType={treatmentType}
                        treatment={treatmentsProcessed[treatment]}
                        handleRedirectMedications={handleRedirectMedications}
                        windowWidth={windowWidth}
                        showLink={true}
                      />
                    )}
                    noOfLines={1}
                  />
                </View>
                <View style={styles.fieldColSp} />
                <View style={styles.fieldColDelete}>
                  <ItemDeleteButton touchableOnPress={() => deleteItem(index)} />
                </View>
              </View>
            </View>
          }
          <View style={category !== 'RT' ? [styles.iconLtSp, styles.barBotSp] : [styles.fieldContainer]}>
            {category !== 'RT' &&
              <Text style={[styles.textPrimeBold, styles.textBlack, styles.infoSp, styles.comMgTopSm]}>
                {name}
              </Text>
            }
            <View style={category !== 'RT' ? [styles.flexRow, styles.mgTopLg, styles.rightSpSm] : [styles.flexRow, styles.mgTopLg]}>
              <View>
                <TextInputPlusMinus
                  title={'Quantity'}
                  fieldBg={FieldDetails.BGWHITE}
                  fieldAlign={FieldDetails.ALIGNCENTER}
                  // fieldLt={false}
                  // darkTxt={true}
                  value={quantity}
                  onChange={this.onChange}
                  units={this.state.units}
                  max={null}
                  activitySlider={true}
                />
              </View>
              <View style={styles.wdTen} />
              <View style={category !== 'RT' ? [styles.fieldColStretch, styles.rightSpSm] : [styles.fieldColPickerCom]}>
              {data?.units || units ?
                <TextFrame
                  title={category !== 'RT' ? 'Measure' : 'Unit'}
                  fieldBg={FieldDetails.BGGREY}
                  fieldValue={data?.units || units}
                  editable={false}
                  activitySlider={false}
                />
                :
                <TextPickerFrame
                  title={category !== 'RT' ? 'Measure' : 'Unit'}
                  fieldBg={FieldDetails.BGWHITE}
                  items={this.state.units}
                  onValueChange={this.onValueChange}
                  selectedValue={unit}
                  activitySlider={false}
                />
              }
              </View>
              {category !== 'RT' ?
                <View style={[styles.fieldColDelete, styles.flexJcCt]}>
                  <ItemDeleteButton touchableOnPress={() => deleteItem(index)} />
                </View>
                :
                null
              }
            </View>
          </View>
          {Boolean(duplicatePopup) && (
            <Warning
              errorDescription={errorDescription}
              textPosition={'left'}
              warningBlockSp={category !== 'RT' ? true : false}
              warningBlockMg={true}
              journalTreatmentWarning={true}
            />
          )}
        </View>
        <View style={[styles.secSepBorderBot, styles.comSp, styles.titleTopPd]} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    drugAdministrationsDigits: state.routines.drugAdministrationsDigits,
    journalUserId: state.uistate.journalUserId,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    listTherapie: state.routines.treatments,
    hasInvalidNamesInActivity: state.therapie.hasActivityInvalidName,
    hasInvalidNamesInMedication: state.therapie.hasMedicationInvalidName,
    hasInvalidNamesInHealthData: state.therapie.hasHealthDataInvalidName,
    hasInvalidNamesInSymtoms: state.therapie.hasSymptomsInvalidName,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.updateTherapie(addNewTherapie, callBack, journalUserId)),
    displayProperties: (displayPrope, journalUserId) =>
      dispatch(
        actions.updateGraphDisplayProperties(displayPrope, journalUserId),
      ),
    saveDisable: flag => dispatch(actions.updatesaveDisable(flag)),
    getTreatmentInfo: (din_npn, treatmentType, callBack, unit, units, drugAdministrationsDigits, displayFullName, selectedUnit) => 
      dispatch(actions.getTreatmentInfo(din_npn, treatmentType, callBack, unit, units, drugAdministrationsDigits, displayFullName, selectedUnit)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemItem);
