import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import {View, Keyboard} from 'react-native';
import styles from '../../../../Css';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import AutoCompleteSuggestion from '../../AutoCompleteSuggestion';
import {autoSuggestionListType} from '../../../../Constant/index';
import { getUserTerms, getSuggestedTeams } from '../../../../util/commonUiLogic';

class AddHealthData extends Component {
  constructor(props) {
    super(props);
    const {metricsName, userDefinedMetrics, systemDefinedMetrics} = props;
    this.state = {
      name: metricsName ? metricsName : '',
      terms: getSuggestedTeams(systemDefinedMetrics, userDefinedMetrics),
      userTerms: getUserTerms(userDefinedMetrics),
    };
  }

  // getTerms = () => {
  //   let termsArray = [];
  //   const {userDefinedMetrics, systemDefinedMetrics} = this.props;
  //   const userDefinedMetricsMap = {};
  //   userDefinedMetrics.forEach(res => {
  //     userDefinedMetricsMap[res.name] = res.name;
  //   });
  //   if (systemDefinedMetrics) {
  //     const suggestionResults = [];
  //     systemDefinedMetrics.forEach(res => {
  //       if (!userDefinedMetricsMap[res.name]) {
  //         suggestionResults.push(res);
  //       }
  //     });
  //     termsArray = suggestionResults.map(value => {
  //       return {name: value.name, data: value};
  //     });
  //   }

  //   return termsArray;
  // };

  // getUserTerms = () => {
  //   let termsArray = [];
  //   const {userDefinedMetrics} = this.props;
  //   if (userDefinedMetrics) {
  //     const suggestionResults = [...userDefinedMetrics];
  //     termsArray = suggestionResults.map(value => {
  //       return {name: value.name, data: value};
  //     });
  //   }
  //   return termsArray;
  // };

  onChangeTextName = currentInput => {
    this.setState({
      name: currentInput,
    });
  };

  onItemPress = (selection, type) => {
    let selectedValue = selection.data;
    const {onPressItem} = this.props;

    this.setState({
      name: '',
    });
    onPressItem(selectedValue, type);
    Keyboard.dismiss();
  };

  onAddNewPress = (selection, type) => {
    if (selection && selection.trim() !== '') {
      const name = selection;

      this.setState({
        name: '',
      });
      const {onPressItem} = this.props;
      onPressItem(name, type);
      Keyboard.dismiss();
    }
  };

  render() {
    const {userTerms, terms} = this.state;
    const {
      updateAutocompleteOpen,
      selectedValues
    } = this.props;
    return (
      <View
        style={[styles.fieldRow, styles.zIndexSmAutoComplete, styles.noMgBot]}>
          <AutoCompleteSuggestion
            textFieldWithTitle={true}
            textFieldTitle="Track Health Data"
            // toolTipButton={true}
            // toolTipId={'tool-tip-med'}
            // toolTipContent={
            //   'Keep track of any health data, e.g. blood glucose, weight, heart rate'
            // }
            fieldBg={FieldDetails.BGWHITE}
            remortSearch={false}
            //triggerLength={1}
            onChangeText={this.onChangeTextName}
            onItemPress={this.onItemPress}
            currentInput={this.state.name}
            onPressCustom={this.onAddNewPress}
            customSectionTitle={'Create custom'}
            showCustomSection={true}
            autoFocus={true}
            terms={terms}
            userTerms={userTerms}
            updateAutocompleteOpen={updateAutocompleteOpen}
            selectedValues={selectedValues}
            userListTitle={'Select from existing list of health data'}
            systemListTitel={'Select from suggested health data'}
            disabledScroll={this.props.disabledScroll}
            clearTextOnBlur={true}
            type={autoSuggestionListType.health_data}
            zoomIcon={true}
            removeIcon={true}
            autoDisplayUserList={true}
            maxLength={50}
          />
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    systemDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.systemDefinedMetrics
      : [],
    userDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.userDefinedMetrics
      : [],
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateMetric: (addNewMetric, callBack) =>
      dispatch(actions.updateMetric(addNewMetric, callBack)),
    onUpdateMetricsName: name => dispatch(actions.updateMetricsName(name)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddHealthData);
