import React from 'react';
import { View } from 'react-native';
import styles from '../../../../Css';
import ProfileCommonItems from './ProfileCommonItems';
import ProfileCommonBios from './ProfileCommonBios';
import { listTypes } from '../../../../screens/PeerToPeer/MainSection/Profile/Constraints';

const ProfileDetail = (props) => {

const {
    medicalConditionList, 
    symptomList, 
    treatmentList, 
    personalDetailList, 
    locationDetails, 
    onPressUserMedicationCondition, 
    onPressTreatment, 
    onPressSymptom, 
    onPressPersonalDetail, 
    onPressLocationDetail, 
    handleOnClickEditCancelItems, 
    showMedicalConditionList,
    showTreatmentList,
    showSymptomList,
    showPersonalDetailList, 
    showLocationDetailList, 
    handleOnClickSaveItems, 
    medicalConditionListSaveWait, 
    treatmentListSaveWait,
    symptomListSaveWait, 
    personalDetailListSaveWait, 
    locationDetailListSaveWait, 
    disableListItemSaveButtons, 
    disableMedicationListEdit, 
    disableTreatmentListEdit, 
    disableSymptomListEdit, 
    disablePersonalDetailListEdit, 
    disableLocationDetailListEdit,
    checkBoxTick } = props;

return (

        <View>
            <ProfileCommonItems 
                data={medicalConditionList}
                type={listTypes.MEDICAL_CONDITIONS}
                checkBoxTick={checkBoxTick}
                showList={showMedicalConditionList}
                disableListEdit={disableMedicationListEdit}
                onPressItem={onPressUserMedicationCondition}
                handleOnClickEditCancelItems={handleOnClickEditCancelItems}
                handleOnClickSaveItems={handleOnClickSaveItems}
                listItemSaveWait={medicalConditionListSaveWait}
                disableListItemSaveButtons={disableListItemSaveButtons}
                idKey = {'conditionId'}
                />

            <ProfileCommonItems 
                data={treatmentList}
                type={listTypes.TREATMENTS}
                onPressItem={onPressTreatment}
                handleOnClickEditCancelItems={handleOnClickEditCancelItems}
                showList={showTreatmentList}
                handleOnClickSaveItems={handleOnClickSaveItems}
                listItemSaveWait={treatmentListSaveWait}
                disableListItemSaveButtons={disableListItemSaveButtons}
                disableListEdit={disableTreatmentListEdit}
                checkBoxTick={checkBoxTick}
                idKey = {'treatmentId'}
                />

            <ProfileCommonItems 
                data={symptomList}
                type={listTypes.SYMPTOMS}
                onPressItem={onPressSymptom}
                handleOnClickEditCancelItems={handleOnClickEditCancelItems}
                showList={showSymptomList}
                handleOnClickSaveItems={handleOnClickSaveItems}
                listItemSaveWait={symptomListSaveWait}
                disableListItemSaveButtons={disableListItemSaveButtons}
                disableListEdit={disableSymptomListEdit}
                checkBoxTick={checkBoxTick}
                idKey = {'symptomId'}
                />
                
            <ProfileCommonBios
                data={personalDetailList}
                type={listTypes.PERSONAL_DETAILS}
                onPressItem={onPressPersonalDetail}
                handleOnClickEditCancelItems={handleOnClickEditCancelItems}
                showList={showPersonalDetailList}
                handleOnClickSaveItems={handleOnClickSaveItems}
                listItemSaveWait={personalDetailListSaveWait}
                disableListItemSaveButtons={disableListItemSaveButtons}
                disableListEdit={disablePersonalDetailListEdit}
                checkBoxTick={checkBoxTick}
                idKey = {'value'}
                valueTypeKey={'personalDetailType'}
                />
                

            <ProfileCommonBios
                data={locationDetails}
                type={listTypes.LOCATION_DETAILS}
                onPressItem={onPressLocationDetail}
                handleOnClickEditCancelItems={handleOnClickEditCancelItems}
                showList={showLocationDetailList}
                handleOnClickSaveItems={handleOnClickSaveItems}
                listItemSaveWait={locationDetailListSaveWait}
                disableListItemSaveButtons={disableListItemSaveButtons}
                disableListEdit={disableLocationDetailListEdit}
                checkBoxTick={checkBoxTick}
                idKey = {'value'}
                valueTypeKey={'locationType'}
                />

        </View>
    );
};


export default ProfileDetail;
