import React, {Component} from 'react';
import AddEditCustomSymptom from '../../../components/Settings/symptoms/AddEditCustomSymptom';
import {SYMPTOMS} from '../../../navigation/path';
import {handleCommonBackNavigation} from '../../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../../util/pendoConfig';

class AddCustomSymptomScreen extends Component {
  componentDidMount() {
    pendoTrackEvent(this.props.route.name === SYMPTOMS.addSymptomPath ? 'Symptoms/@AddSymptom' : 'Symptoms/@EditSymptom');
  }

  redirectBack = () => {
    handleCommonBackNavigation(this.props.navigation);
  };

  redirectSymptoms = () => {
    this.props.navigation.navigate(
      SYMPTOMS.path, {
        from: this.props.route.name,
      },
    );
  };

  redirectViewSymptom = () => {
    this.props.navigation.replace(SYMPTOMS.viewSymptomPath);
  };

  render() {
    return (
      <AddEditCustomSymptom
        redirectBack={this.redirectBack}
        redirectSymptoms={this.redirectSymptoms}
        redirectViewSymptom={this.redirectViewSymptom}
        navigation={this.props.navigation}
        route={this.props.route}
      />
    );
  }
}

export default (AddCustomSymptomScreen);
