import React from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";
import SectionFooter from "./sectionFooter";
import SectionHeader from "./sectionHeader";
import {ORGANIZATION_NAME} from '../../util/commonUiConfig';

const success = (props) => (
  <React.Fragment>
    <View style={[styles.staticPageContent, styles.staticPageMax]}>
      <SectionHeader />

      <View style={styles.gridRow}>
        <View style={styles.gridColFull}>
          <View style={[styles.staticImgWrapper, styles.flexJcCtAiCt]}>
          <Image
              style={styles.acceptBtn}
              source={require("../../assets/icons/accept-circle-dark.png")}
            />
          </View>
        </View>
      </View>

      <View style={styles.gridRow}>
        <View
          style={[
            styles.gridColFull,
            styles.staticSecText,
            styles.infoPdBotImg,
          ]}
        >
          <View style={styles.staticSecTitle}>
            <Text style={[styles.textTitleMainLgBold, styles.textAlignCenter]} allowFontScaling={false}>
            Success!
            </Text>
          </View>
          <View style={[styles.staticSecTitle, styles.pdTopSm]}>
            <Text style={[styles.textTitleMainLgBold, styles.textAlignCenter]} allowFontScaling={false}>
            Your email address has been restored to:
            </Text>
          </View>
          <View
            style={[
              styles.staticSecDesc,
              styles.pdTopSm,
            ]}
          >
            <Text
              style={[
                styles.textTitleMain,
                styles.textTertiary,
                styles.textAlignCenter,
              ]}
              allowFontScaling={false}
            >
              {props.recoveredEmail}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.gridRow}>
        <View style={styles.gridColFull}>
          <View style={[styles.staticBtnWrapper, styles.flexJcCtAiCt]}>
            <View style={styles.btnBlockFull}>
              <TouchableOpacity
                style={[styles.submitMd, styles.submitBgOrange, styles.smRad]}
                underlayColor="rgba(0, 153, 168, 0.8)"
                onPress={props.goToLogin}
                // onPress={props.onBackdropPress}
              >
                <View style={[styles.btnSingle, styles.btnSingleCent]}>
                  <Text
                    style={[
                      styles.submitText,
                      styles.submitTextSm,
                      styles.bold,
                    ]}
                    allowFontScaling={false}
                  >
                    Login to{" "}
                    <Text style={styles.textUnderline} allowFontScaling={false}>{ORGANIZATION_NAME}</Text>
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </View>
    <SectionFooter linkPress={this.toggleLearnHowPrivacyPolicy} />
  </React.Fragment>
);

export default (success);
