import React, { Component } from "react";
import { View, Keyboard } from "react-native";
import { connect } from "react-redux";
import styles from "../../../Css";
import * as FieldDetails from "../../TextInput/fieldDetails";
import AutoCompleteFrame from "../../AutoComplete/AutoCompleteFrame";

class TherapiePrescriber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      healthProviderName: props.healthProviderName,
      prescribedBy: props.prescribedBy,
      contactTerms: this.getContactTerms(),
    };
  }
  healthProviderNameChange = (val) => {
    this.setState({ healthProviderName: val });
    if (!val || val.trim() === '') {
      this.setState({prescribedBy: null}, () => {
        this.props.updatePrescribedBy(null, val);
      });
    }
  };

  onItemPress = (selection) => {
    Keyboard.dismiss();
    let selectedContact = selection.data;
    const name = selectedContact.givenName
      ? selectedContact.givenName
      : selectedContact.organization;
    this.setState(
      {
        prescribedBy: selectedContact.id,
        healthProviderName: name,
      },
      () => {
        this.props.updatePrescribedBy(selectedContact.id, name);
      }
    );
  };

  onAddNewPress = (selection) => {
    Keyboard.dismiss();
    this.setState(
      {
        prescribedBy: null,
        healthProviderName: selection,
      },
      () => {
        this.props.updatePrescribedBy(null, selection);
      }
    );
  };

  getContactTerms = () => {
    let termsArray = [];
    if (this.props.contacts) {
      const suggestionResults = [...this.props.contacts];
      termsArray = suggestionResults.map((value) => {
        return {
          name: value.givenName ? value.givenName : value.organization,
          data: value,
        };
      });
    }

    return termsArray.filter((res) => res.name !== null);
  };
  render() {
    const {
      updateAutocompleteOpen,
      textFieldTitle,
      customSectionTitle,
      systemListTitel,
    } = this.props;
    return (
      <View
        style={[
          styles.fieldRow,
          styles.zIndexAutoComplete,
          styles.fieldRowNoMg,
        ]}
      >
        <View
          style={[
            styles.fieldRowWrapperSm,
            styles.zIndexAutoComplete,
            styles.fieldColStretch,
          ]}
        >
        <View style={[
          this.props.noBorderLeft ? styles.noBorderLt : styles.fieldColSpBorderLt
        ]}>   
        </View>
          <View
            style={[
              styles.fieldColStretch,
              styles.zIndexAutoComplete,
              styles.fieldRowPdCom,
            ]}
          >
            <AutoCompleteFrame
              textFieldWithTitle={true}
              textFieldTitle={textFieldTitle ? textFieldTitle : "Prescriber"}
              remortSearch={false}
              triggerLength={1}
              onChangeText={(value) => {
                this.healthProviderNameChange(value);
              }}
              onItemPress={this.onItemPress}
              currentInput={this.state.healthProviderName}
              onPressCustom={this.onAddNewPress}
              customSectionTitle={
                customSectionTitle ? customSectionTitle : "Add new Prescriber"
              }
              systemListTitel={systemListTitel}
              showCustomSection={true}
              //   autoFocus={true}
              terms={this.state.contactTerms}
              updateAutocompleteOpen={updateAutocompleteOpen}
              fieldBg={FieldDetails.BGWHITE}
              fieldLt={true}
            />
          </View>
            <View style={[
              this.props.noBorderRight ? styles.noBorderRt : styles.fieldColSpBorderRt
            ]}>
            </View>
        </View>
      </View>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    contacts: state.routines.contacts,
    contactsProcessed: state.routines.contactsProcessed,
  };
};

export default connect(mapStateToProps, null)(TherapiePrescriber);
