import axios from 'axios';
import Cookies from 'js-cookie';
import {BASE_URL} from '../store/actions/urls';
import NavService from '../navigation/navigationService';
import { OTHER } from '../navigation/path';
export const cancelTokenSource = axios.CancelToken;

const axiosConfigCommon = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

axiosConfigCommon.interceptors.request.use(async resp => {
  const token = Cookies.get('authToken');
  if (token) {
    resp.headers.authorization = 'Bearer ' + token;
    resp.headers.Accept = 'application/json';
    resp.headers.AccessControlAllowOrigin = '*';
  }
  return resp;
});

axiosConfigCommon.interceptors.response.use(
  async resp => resp,
  (error) => {
    if (error.message === 'Network Error') NavService.navigate(OTHER.noNetwork);
    return Promise.reject(error);
  }
);


export default axiosConfigCommon;
