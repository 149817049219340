import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import ActivityIndicator from '../ActivityIndicator/activityIndicator';
import styles from '../../Css';
import * as FontScaling from '../../components/Text/fontScaling';
import {findThumbnail} from '../../util/commonUiLogic';

const attachment = props => {
  return (
    <TouchableOpacity
      onPress={() =>
        props.handlePopupAttachment(props.mimetype, props.id, props.name)
      }
      disabled={props.disabled}>
      <View style={[styles.attachmentRow, styles.borderThin]}>
        <View style={[styles.attachmentDetSec, styles.attachRowPd]}>
          <View
            style={[
              styles.attachmentDet,
              styles.attachmentDetMax,
              styles.attachSp,
            ]}>
            <View style={[styles.attachmentImgDet, styles.attachmentImgDetMax]}>
              <View style={[styles.attachImgAvailable, styles.borderNone]}>
              <Image
                  style={styles.attachmentPreviewIcon}
                  source={findThumbnail(props.name)}
                />
              </View>
            </View>
            <View style={[styles.attachmentCont, styles.attachmentContMax]}>
              <Text style={styles.attachmentCont} allowFontScaling={false} maxFontSizeMultiplier = {FontScaling.fontScaleMd}>{props.name}</Text>
            </View>
          </View>
          {props.downloadWait && props.id === props.attachmentId ? (
            <View style={[styles.attachmentBtn, styles.attachmentIconSec]}>
              <View style={[styles.attachmentIcon, styles.attachIconTouch]}>
                <View style={styles.attachDownloadLoader}>
                  <ActivityIndicator size="small" />
                </View>
              </View>
            </View>
          ) : (
            <View style={[styles.attachmentBtn, styles.attachmentIconSec]}>
              <TouchableOpacity
                onPress={() => {
                  props.downloadAttachment(props.id, props.name);
                }}
                style={styles.attachIconTouchCom}
                disabled={props.disabled}>
                <View style={styles.attachmentIcon}>
                  <Image
                    style={styles.attachDownloadIcon}
                    source={require('../../assets/icons/attach-download.png')}
                  />
                </View>
              </TouchableOpacity>
            </View>
          )}

          <View style={[styles.vertDivider]}>
            <Image
              style={styles.vBarIcon}
              source={require('../../assets/icons/v-bar.png')}
            />
          </View>

          <View style={[styles.attachmentBtn, styles.attachmentIconSec]}>
            <TouchableOpacity
              onPress={props.handleDeleteAttachment}
              style={styles.attachIconTouchCom}>
              <View style={styles.attachmentIcon}>
                <Image
                  style={styles.delLgIcon}
                  source={require('../../assets/icons/remove.png')}
                />
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default attachment;
