import React, { Component } from "react";
import { View } from "react-native";
import styles from "../../Css";
class DashboardHOC extends Component {
  render() {
    return (
      <React.Fragment>
        {Boolean(this.props.layoutGreyVisible) ? (
          <View style={[styles.flexCom, styles.secLightBlueBg]}>{this.props.children}</View>
        ) : (
          <View
            style={[
              styles.customScrollView,
              styles.flexCom,
              !this.props.isNotFromDashboard ? styles.secLightBlueBg : null,
            ]}
            nativeID={"dashboardScroll"}
          >
            {this.props.children}
          </View>
        )}
      </React.Fragment>
    );
  }
}

export default (DashboardHOC);
