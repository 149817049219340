import React, { Component } from "react";
import {
  View,
  ScrollView,
  Platform,
} from "react-native";
import styles from "../../Css";
import { connect } from "react-redux";
import LearnHowPrivacyPolicy from "../SignUp/LearnHowPrivacyPolicy";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import VerificationFailScreen from "./fail";
import VerificationSuccessScreen from "./success";
import { resetEmail, stopRefreshtoken, logout } from "../../store/actions/auth";
import { showMobileMenu, hideMobileMenu } from "../../store/actions/uistate";
import { OTHER } from "../../navigation/path";
import KeychainWrapper from '../../components/Biometric/keyChain';
import { WEB } from "../../util/platformWindowConfig";


class EmailRecovery extends Component {
  constructor(props) {
    super(props);
    const { windowWidth, windowHeight } = props;

    this.state = {
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      showLearnHowPrivacyPolicy: false,
      verifingWait: true,
      verifySuccess: false,
      recoveredEmail: ''
    };
  }

  componentDidMount() {
    //TO-DO remove previous navigation changes
    //const {match: {params: {token} = {}} = {}} = this.props;
    const token = this.props.route?.params?.token ? this.props.route?.params?.token : null;
    this.props.resetEmail(token, (status, data) => {
        this.setState({
          verifingWait: false,
          verifySuccess: status,
          recoveredEmail: status ? data.data.email : '',
        }, async () => {
          if (Platform.OS !== WEB && status) {
            this.props.stopRefreshtoken();
            await KeychainWrapper.resetGenericPassword();
          }
        });
    });
    this.props.hideMobileMenu();
  }

  static getDerivedStateFromProps(props, state) {
    const { windowWidth, windowHeight } = props;

    if (
      windowWidth !== state.windowWidth ||
      windowHeight !== state.windowHeight
    ) {
      return {
        windowWidth: windowWidth,
        windowHeight: windowHeight,
      };
    }
    props.hideMobileMenu();
    return null;
  }

  componentWillUnmount() {
    const {showMobileMenu} = this.props;
    showMobileMenu();
  }

  toggleLearnHowPrivacyPolicy = () => {
    this.setState({ showLearnHowPrivacyPolicy: true });
  };

  redirectToLogin = () => {
    // this.props.navigation.push(OTHER.loginPath);
    this.props.navigation.reset({
      index: 0,
      routes: [{name: OTHER.loginPath}],
    });
    this.props.onSignOutPress();
  };

  render() {
    const { verifingWait, verifySuccess, recoveredEmail, showLearnHowPrivacyPolicy } = this.state;
    return (
      <View style={[styles.pageBodyWrapper]}>
        <ScrollView
          style={[styles.scrollViewArea]}
          contentContainerStyle={[styles.scrollViewMax, styles.flexJcSb]}>
          {/* Loading Screen*/}
          {verifingWait && (
            <View style={[styles.flexCom, styles.flexJcCtAiCt]}>
              <ActivityIndicator pdZero={true} size={'large'} />
            </View>
          )}

          {/* Failure/link expired screen  */}
          {!verifingWait && !Boolean(verifySuccess) && (
            <VerificationFailScreen {...this.props} />
          )}

          {/* Recovery success screen */}
          {!verifingWait && Boolean(verifySuccess) && (
            <VerificationSuccessScreen
              {...this.props}
              goToLogin={this.redirectToLogin}
              recoveredEmail={recoveredEmail}
            />
          )}
        </ScrollView>
        {Boolean(showLearnHowPrivacyPolicy) &&
          <LearnHowPrivacyPolicy
            showLearnHowPrivacyPolicy={showLearnHowPrivacyPolicy}
            toggleLearnHowPrivacyPolicy={() => {
              this.setState({showLearnHowPrivacyPolicy: false});
            }}
          />
        }
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowWidth: state.uistate.windowWidth,
    windowHeight: state.uistate.windowHeight,
    journalUserId: state.uistate.journalUserId,
    hasNotch: state.uistate.hasNotch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      resetEmail: (token, callback) => dispatch(resetEmail(token, callback)),
      stopRefreshtoken: () => dispatch(stopRefreshtoken()),
      onSignOutPress: () => dispatch(logout()),
      hideMobileMenu: () => dispatch(hideMobileMenu()),
      showMobileMenu: () => dispatch(showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailRecovery);
