import * as actionTypes from './actionTypes';
import * as axiosCommon from '../../axios/axios-common';
import * as urls from './urls';
import {logout} from './index';

//delete account
export const deleteAccount = callBack => {
  return dispatch => {
    dispatch({
      type: actionTypes.DELETE_ACCOUNT_START,
    });
    axiosCommon.axiosDELETE(urls.DELETE_ACCOUNT, res => {
      if (res.type === 'SUCCESS') {
        callBack();
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; deleteAccount");
          dispatch(logout());
        } else if (res.errorDto !== 'undefined') {
          dispatch({type: actionTypes.DELETE_ACCOUNT_FAIL});
          dispatch({
            type: actionTypes.COMMON_ERROR_HANDLER,
            errorDescription: res.errorDto.data.error,
          });
        }
      }
    });
  };
};

export const deleteAccountSuccess = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.DELETE_ACCOUNT_SUCCESS,
    });
    console.log("success and logout; deleteAccountSuccess");
    dispatch(logout());
  };
};
