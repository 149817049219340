/* Nav Service for use in places where history prop is not avaliable.
ex: outside of a component, inside a action etc..*/

let historyStack = null;

const setNavService = (history) => {
    historyStack = history;
}

const navigate = path => {
    if(historyStack.getCurrentRoute().name !== path) historyStack.navigate(path);
}

export default {setNavService, navigate}