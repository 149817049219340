import theme from "./base/theme";
import * as variableCommon from "./base/variableCommon";
import * as variable from "../css/base/variable";
import * as variableShadow from './base/variableShadow';
export const cssLayout = {
  containerFull: {
    flex: 1,
    flexDirection: "column"
  },
  gridSection: {
    flex: 1,
    flexDirection: "column",
    width: "100%"
  },
  gridRow: {
    flexDirection: "column"
  },
  gridColFull: {
    flexDirection: "column"
  },
  fieldRow: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginBottom: 15
  },
  notifyDetails: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 15
  },
  notifyNoPd: {
    paddingTop: 0
  },
  passHintDetails: {
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
    height: "100%"
  },
  checkBoxSection: {
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "row"
  },
  headerContent: {
    justifyContent: "space-between",
    flexDirection: "row",
    paddingLeft: 15,
    paddingRight: 15,
    alignItems: "center",
    height: 57,
    paddingTop: 0,
    paddingBottom: 0
  },
  headerLeftDetails: {
    flexDirection: "row",
    alignItems: "center"
  },
  headerRightDetails: {
    flexDirection: "row",
    alignItems: "center"
  },
  headerCenterDetails: {
    flexDirection: "row",
    alignItems: "center"
  },
  messageRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  messageDetailSec: {
    flexDirection: "row",
    flex: 1
  },
  iconListSec: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 20
  },
  iconListNoPd: {
    paddingLeft: 0
  },
  iconListCol: {
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: 20
  },
  iconListSecCom: {
    flexDirection: "row",
    alignItems: "center"
  },
  topicIconListWeb: {
    flexDirection: "row",
    alignItems: "center",
    flexBasis: 92,
    justifyContent: "flex-end"
  },
  questionCommentBlock: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 15
  },
  commentTextBlock: {
    flexDirection: "row",
    alignItems: "center"
  },
  medSecImgFull: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center"
  },
  medDetails: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    width: "100%"
  },
  activityContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 15,
    marginBottom: 15
  },
  activityDetailBlock: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "40%"
  },
  attachmentDet: {
    flexDirection: "row"
  },
  attachmentBtn: {
    flexDirection: "row"
  },
  attachmentDetSec: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 24,
    paddingBottom: 24,
    alignItems: "center"
  },
  fieldRowFlexRow: {
    flexDirection: "row"
  },
  sortSecBlock: {
    flexDirection: "row"
  },
  slideContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  slideContainerLgScreen: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  navListLiDet: {
    flexDirection: "row",
    alignItems: "center"
  },
  navListLiImgCom: {
    marginLeft: 10,
    width: 30,
    alignItems: "center"
  },
  sideBarContentView: {
    flex: 1,
    height: "100%"
  },
  topicContentSecWeb: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1
  },
  medListSingleBoxLg: {
    position: "relative",
    backgroundColor: theme.COLOR_WHITE,
    justifyContent: "center"
  },
  medContentLg: {
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    flex: 1
  },
  medIconBlockLg: {
    flexBasis: 75,
    alignItems: "center"
  },
  medTitleBlockLg: {
    flex: 1,
    paddingLeft: 20
  },
  medTitleBlockMd: {
    flex: 1,
    paddingLeft: 10
  },
  titleContainerCollapsible: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    minHeight: 60,
  },
  titleCollapsible: {
    flex: 1
  },
  addItemSecBtnSecFull: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center"
  },
  fieldBtn: {
    flex: 1
  },
  btnBlockFull: {
    alignItems: "stretch"
  },
  cardContent: {
    flexDirection: "row"
  },
  medContentTop: {
    alignItems: "center",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  medContentBot: {
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  medTopicIconBlock: {
    flexBasis: "auto",
    alignItems: "center"
  },
  medTitleTop: {
    alignItems: "center",
    flexDirection: "row",
    flex: 1
  },
  listContentView: {
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-start"
  },
  fieldIconView: {
    position: "absolute",
    display: "flex",
    right: 10,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999999
  },
  listContentViewFull: {
    width: "100%",
    justifyContent: "flex-start"
  },
  listItemAddedView: {
    alignItems: "flex-start",
    width: "100%",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  addedItemInd: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%"
  },
  menuSingleBar: {
    flexDirection: "row"
  },
  menuSingleIconBlock: {
    flexBasis: 70,
    alignItems: "center",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  menuSingleContBlock: {
    flex: 1,
    alignSelf: "center"
  },
  menuSingleIcon: {
    flex: 1
  },
  containerCalFul: {
    flexDirection: "row",
    alignItems: "center"
  },
  uploadSection: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  navListLiImgLg: {
    flexBasis: 46,
    alignItems: "center"
  },
  medSettingTopLg: {
    flexBasis: 55,
    maxWidth: 55,
    paddingLeft: 10,
  },
  attachmentBtnMax: {
    flexBasis: 27,
    alignSelf: "flex-start"
  },
  attachmentDetMax: {
    flex: 1
  },
  attachmentImgDetMax: {
    flexBasis: 35
  },
  attachmentContMax: {
    flex: 1
  },
  viewCollapsible: {
    flexDirection: "row"
  },
  linkViewItemTextSec: {
    flex: 1,
    paddingRight: 10
  },
  selectionBlock: {
    flexBasis: 200,
    alignItems: "flex-end"
  },
  selectionBlockSm: {
    flexBasis: 80,
    alignItems: "flex-end"
  },
  contListItemTextBlock: {
    flex: 1
  },
  questionTitleMain: {
    alignItems: "center",
    flexDirection: "row"
  },
  questionTextBlock: {
    flex: 1
  },
  questionTitleBlockMain: {
    flexDirection: "row"
  },
  btnSingleCent: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    position: 'relative'
  },
  errorMessage: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center"
  },
  textSecSeverity: {
    paddingBottom: 10,
    minHeight: 40,
    flex: 1,
    position: "relative"
  },
  horzFieldValue: {
    flex: 1,
    paddingLeft: 10
  },
  horzFieldFull: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  colorBoxListView: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-end"
  },
  colorBoxLeft: {
    justifyContent: "flex-start"
  },
  customPopUpStyle: {
    marginRight: 0,
    marginLeft: 0,
    marginBottom: 0,
    justifyContent: "flex-start",
    alignItems: "center"
  },
  popMessageIconView: {
    alignItems: "center",
    justifyContent: "center"
  },
  tickButtonRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  tickButtonText: {
    flex: 1,
    paddingRight: 10
  },
  tickButtonTextRt: {
    flex: 1,
    paddingLeft: 5
  },
  tickButton: {
    flexBasis: 100,
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  tickButtonSm: {
    flexBasis: 25,
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  toolTipSm: {
    position: "absolute",
    top: 0,
    alignItems: "center",
    left: 0,
    right: 0
  },
  attachmentImgView: {
    alignSelf: "flex-start"
  },
  passHint: {
    position: "absolute",
    display: "flex",
    right: -18,
    top: 0,
    zIndex: 999,
    height: "100%"
  },
  fieldRowView: {
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  btnBlock: {
    marginTop: 15,
    alignItems: "center"
  },
  btnBlockRight: {
    alignItems: "flex-end"
  },
  btnBlockLeft: {
    alignItems: "flex-start"
  },
  textLabel: {
    position: "relative",
    display: "flex",
    fontFamily: theme.FONT_FAMILY,
    fontSize: 11
  },
  fileUploadBtnFixed: {
    flexDirection: "row",
    alignItems: "center"
  },
  upImg: {
    width: "100%",
    position: "absolute",
    top: 0,
    flexDirection: "row",
    ...variableCommon.cursorPointer,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 8,
    paddingTop: 15,
    paddingRight: 30,
    paddingBottom: 15,
    paddingLeft: 30,
    backgroundColor: theme.COLOR_BLUE_5,
    alignItems: "center",
    justifyContent: "center"
  },
  colorSection: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
    alignItems: "center",
    justifyContent: "center"
  },
  calSection: {
    backgroundColor: theme.COLOR_WHITE,
    paddingLeft: 15,
    paddingRight: 15,
    alignItems: "center"
  },
  commonTopImg: {
    alignSelf: "flex-end"
  },
  commonBotImg: {
    alignSelf: "flex-start"
  },
  commonContentBlock: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  commonFullBlock: {
    flex: 1
  },
  textAreaFull: {
    alignItems: "center"
  },
  textListBlock: {
    flexDirection: "row",
    alignItems: "flex-start"
  },
  textListView: {
    alignItems: "flex-start"
  },
  fieldColOneThirds: {
    flexDirection: "row"
  },
  fieldColDouble: {
    flexDirection: "row"
  },
  fieldColOneThird: {
    width: "33.33%"
  },
  fieldColOneFourth: {
    width: "25%"
  },
  fieldColThreeFourth: {
    width: "75%"
  },
  fieldColMain: {
    flex: 1
  },
  symptomLtPd: {
    marginRight: 10
  },
  fieldColSub: {
    flexBasis: 90
  },
  fieldColSubLg: {
    flexBasis: 120
  },
  gridWrapperFull: {
    flex: 1
  },
  radioSetRight: {
    justifyContent: "flex-end"
  },
  radioSetCom: {
    justifyContent: "space-between",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  radioSetCenter: {
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap"
  },
  radioSetCenterMax: {
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    maxWidth: 120
  },
  radioSetDouble: {
    maxWidth: 240,
    minWidth: 170
  },
  answerSingle: {
    flexDirection: "row",
    marginTop: 5
  },
  answerSingleSm: {
    flexDirection: "row",
    marginRight: 10,
    marginTop: 5
  },
  answerSingleCom: {
    marginTop: 5
  },
  answerOpt: {
    flexBasis: 20,
    maxHeight: 20,
    paddingRight: 10
  },
  answerOptMg: {
    marginTop: 2
  },
  answerOptMgBot: {
    marginBottom: 2
  },
  answerText: {
    flex: 1
  },
  answerNoPd: {
    paddingLeft: 0
  },
  questionAnswerBlock: {
    marginBottom: 20
  },
  questionWrapper: {
    marginBottom: 10
  },
  answerOptionRowSpec: {
    flexDirection: "row"
  },
  answerOptionInd: {
    flexDirection: "row"
  },
  btnWidthCom: {
    alignItems: "center"
  },
  profileBannerTextArea: {
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
    flexDirection: "row"
  },
  docDetailSec: {
    flexDirection: "row",
    flex: 1
  },
  contDescSubFull: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  contDescSub: {
    flexDirection: "row",
    alignItems: "center"
  },
  contComText: {
    flexWrap: "wrap"
  },
  btnViewArea: {
    alignItems: "center",
    justifyContent: "center"
  },
  preDataNo: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  ViewRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  viewDetailSec: {
    flexDirection: "row",
    flex: 1
  },
  viewItemDesc: {
    flexDirection: "row",
    alignItems: "center"
  },
  viewImg: {
    alignSelf: "flex-start"
  },
  viewImgCom: {
    alignSelf: "center"
  },
  viewDataFull: {
    flex: 1
  },
  dataIconSecExLg: {
    maxWidth: 130,
    flexBasis: 130,
    alignItems: "flex-end"
  },
  dataIconSecLg: {
    maxWidth: 110,
    flexBasis: 110,
    alignItems: "flex-end"
  },
  dataIconSecMd: {
    maxWidth: 100,
    flexBasis: 100,
    alignItems: "flex-end"
  },
  dataIconSecRg: {
    maxWidth: 80,
    flexBasis: 80,
    alignItems: "flex-end"
  },
  dataIconSecSm: {
    maxWidth: 50,
    flexBasis: 50,
    alignItems: "flex-end"
  },
  viewDate: {
    maxWidth: 100,
    flexBasis: 100,
    alignItems: "flex-end",
    paddingLeft: 10
  },
  secCenter: {
    justifyContent: "center"
  },
  secSubTitleRight: {
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  comToggleArea: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  topImgSignUp: {
    alignItems: "center",
    justifyContent: "center"
  },
  noBotMg: {
    marginBottom: 0
  },
  viewRowCont: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    height: "100%"
  },
  menuCont: {
    flexDirection: "row",
    alignItems: "center"
  },
  menuSingleMain: {
    flexDirection: "row",
    flex: 1
  },
  noBotRad: {
    borderBottomLeftRadius: 0
  },
  contentItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10
  },
  contentBoxTitle: {
    paddingBottom: 10,
  },
  errorSm: {
    maxWidth: 200,
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 10
  },
  premBannerContent: {
    justifyContent: "center",
    paddingTop: 25,
    paddingBottom: 25
  },
  preSignView: {
    width: 30,
    alignItems: "center",
    height: 80
  },
  sumDataRow: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },
  sumDataSign: {
    flexBasis: 10,
    maxWidth: 10,
    justifyContent: "flex-end"
  },
  sumDataValue: {
    flexBasis: 100,
    maxWidth: 100,
    flexDirection: "row"
  },
  sumDataField: {
    flex: 1
  },
  maxView: {
    flex: 1
  },
  textItemDet: {
    alignItems: "center",
    justifyContent: "center"
  },
  radioRowCenter: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row"
  },
  radioRowView: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  textView: {
    position: "absolute",
    display: "flex",
    left: 10,
    top: 10,
    zIndex: 99999
  },
  viewColor: {
    alignSelf: "flex-start"
  },
  headerViewComCont: {
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  listItemTextView: {
    flex: 1
  },
  listContentSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  },
  listItemEditCloseView: {
    flexDirection: "row",
    flexBasis: 80,
    maxWidth: 80,
    justifyContent: "flex-end"
  },
  addedListSection: {
    width: "100%"
  },
  listItemIconViewSm: {
    paddingLeft: 10
  },
  viewImgSec: {
    maxWidth: 80,
    flexBasis: 80,
    paddingRight: 10
  },
  viewRowTitleBlock: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 15,
    paddingTop: 15
  },
  searchItem: {
    flexDirection: "row"
  },
  searchItemInd: {
    maxWidth: "100%"
  },
  searchItemList: {
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "100%",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  itemFullRow: {
    flexDirection: "row",
    width: "100%"
  },
  itemTextDetails: {
    flex: 1,
    paddingRight: 10
  },
  itemTextSub: {
    flexDirection: "row"
  },
  titleFullView: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  },
  titleViewMain: {
    flex: 1,
    paddingRight: 10
  },
  itemTextFull: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center"
  },
  messageDataFull: {
    flexDirection: "row",
    width: "100%"
  },
  messageData: {
    flex: 1
  },
  answerPdTop: {
    paddingTop: 10
  },
  quoteDataView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  itemTextMainCom: {
    flexDirection: "row",
    alignItems: "center"
  },
  resultSubTitle: {
    flexDirection: "row",
    marginBottom: -6
  },
  headerViewText: {
    flex: 1
  },
  radioComTitle: {
    paddingRight: 8,
    alignSelf: "center",
    paddingLeft: 8,
  },
  radioStagesComTitles: {
    paddingRight: 8,
    alignSelf: "flex-start",
  },
  stagesTopTitle: {
    paddingTop: 8
  },
  radioSecRight: {
    alignSelf: "flex-end",
    flexDirection: "row"
  },
  radioSecStagesRight: {
    flexDirection: "column",
    flexWrap: "wrap",
  },
  radioSecStageswrapCr: {
    flexWrap: "wrap",
  },
  pageBodyWrapper: {
    flex: 1,
    backgroundColor: theme.COLOR_WHITE,
    maxWidth: "100%",
    position: "relative"
  },
  closeIconView: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  modalClose: {
    width: 45,
    height: 45
  },
  modalMessage: {
    flexDirection: "column",
    paddingTop: 20,
    paddingBottom: 20,
    minHeight: 220,
    alignItems: "center",
    justifyContent: "center"
  },
  modelContPd: {
    paddingTop: 30
  },
  iconListDefault: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-end"
  },
  textViewPicker: {
    left: 9,
    top: 9
  },
  boxFull: {
    flex: 1
  },
  textLabelSmText: {
    fontSize: 9
  },
  contentAreaView: {
    maxWidth: "100%",
    position: "relative"
  },
  contentAreaViewApp: {
    maxWidth: "100%",
    backgroundColor: theme.COLOR_WHITE,
    flexGrow: 1
  },
  contentAreaViewLoadApp: {
    maxWidth: "100%",
    backgroundColor: theme.COLOR_BLUE_4,
    flexGrow: 1
  },
  contentAreaViewBg: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_5
  },
  gridWrapperFullApp: {
    flex: 1
  },
  gridWrapperFullMax: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    marginLeft: "auto",
    marginRight: "auto"
  },
  answerSingleText: {
    flexDirection: "row",
    width: 180
  },
  answerSingleMinHeight: {
    minHeight: 45
  },
  answerPicker: {
    maxWidth: 100,
    flexBasis: 100
  },
  answerSingleViewAll: {
    paddingLeft: 10
  },
  answerSingleViewCom: {
    paddingRight: 10
  },
  questSectionWrapper: {
    paddingTop: 20
  },
  keyboardAwareView: {
    backgroundColor: theme.COLOR_WHITE
  },
  radioBtnWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1
  },
  smCloseIconView: {
    position: "absolute",
    right: 10,
    top: 10
  },
  smEditIconView: {
    position: "absolute",
    right: 10,
  },
  secCenterItems: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  toolTipSec: {
    position: "absolute",
    top: 0,
  },
  answerView: {
    flexDirection: 'row',
  },
  answerViewWrapper: {
    maxWidth: '100%',
  },
  radioOptSp: {
    marginRight: 10,
    marginTop: 2
  },
  optionWrapper: {
    position: 'absolute',
    top: 2,
    left: 0,
    zIndex: 9999,
  },
  answerTextWrapper: {
    maxWidth: '100%',
    paddingLeft: 25,
    minHeight: 15,
  },
  answerMg: {
    marginTop: 2,
  },
  questMgLg: {
    marginBottom: 5,
  },
  customPopUpBottomStyle: {
    marginRight: 0,
    marginLeft: 0,
    marginBottom: 0,
    marginTop: 0
  },
  titleContainerCollapsibleSm: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 40,
  },
  titleContainerCollapsibleMd: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 60,
  },
  dropDownTextTouch: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    maxWidth: '100%'
  },
  dropDownTextCom: {
    maxWidth: '90%'
  },
  notifyDetailsCom: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 15
  },
  customPopUpStyleLg: {
    marginRight: 0,
    marginLeft: 0,
    marginTop: 10,
    marginBottom: 10,
    justifyContent: "flex-start",
    alignItems: "center"
  },
  pageContainerViewMax: {
    ...variable.marginLtRtAuto,
    width: "100%",
  },
  pageContainerPd: {
    paddingLeft: 100,
    paddingRight: 100,
  },
  pageContainerPdZero: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  pageContainerPdSm: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  pageContainerPdMd: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  pageContainerPdLg: {
    paddingLeft: 80,
    paddingRight: 80,
  },
  searchSection: {
    paddingBottom: 10,
  },
  medIconBlockIconView: {
    flexBasis: 30,
    alignItems: "center"
  },
  medContentLgView: {
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1
  },
  premiumBgWrapper: {
    position: 'relative'
  },
  docIconSecWrapper: {
    flexDirection: 'row',
    maxWidth: 150,
    flexBasis: 150,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  attachmentDataSection: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: 'row',
    flex: 1,
  },
  mainTitleSectionView: {
    minWidth: 150,
    justifyContent: 'center',
  },
  premiumLinkView: {
    maxWidth: 80,
    flexBasis: 80,
  },
  docDetailView: {
    paddingLeft: 20,
    flex: 1
  },
  titlePdZero: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  titleWrapperFull: {
    paddingTop: 20,
    paddingBottom: 15,
    alignItems: "center",
    flexDirection: 'row',
    justifyContent: "space-between",
    maxWidth: '100%',
  },
  attachmentDataFull: {
    flex: 1,
    alignItems: 'flex-end',
    paddingRight: 5,
  },
  attachmentDataComView: {
    flexDirection: 'row',
    flexWrap: "wrap",
  },
  attachmentDataTitle: {
    alignSelf: 'flex-end'
  },
  attachmentDataCom: {
    alignSelf: 'flex-start'
  },
  attachmentDataSub: {
    maxWidth: '100%'
  },
  attachmentDataSmSection: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'row',
    flex: 1,
  },
  attachmentDataSmFull: {
    flex: 1,
    alignItems: 'center',
    paddingRight: 5,
  },
  attachmentDataSmTitle: {
    alignSelf: 'center'
  },
  addMenuOverlayView: {
    ...variableCommon.positionFixed,
    marginLeft: "auto",
    zIndex: 999,
    right: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: 'transparent',
    height: '100%',
  },
  tickButtonLg: {
    flexBasis: 40,
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  preDataNoSpec: {
    justifyContent: "center",
    alignItems: "center"
  },
  preDataMax: {
    maxWidth: '100%',
  },
  grapdhStyleLtPd: {
    paddingLeft: 10
  },
  resourceBoxStyleCr: {
    ...variableShadow.btnShadowSpec,
    backgroundColor: theme.COLOR_WHITE,
    borderRadius: 5,
    marginBottom: 16,
    paddingTop: 13,
    paddingBottom: 10
  },
  newResourceBorder: {
    borderWidth: 1,
    borderColor: theme.COLOR_BORDER_YELLOW,
  },

  newConnectionBtn: {
    backgroundColor: theme.COLOR_BLUE_DARK_2,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    borderBottomLeftRadius: 50,
    borderTopLeftRadius: 50,
    paddingLeft: 20,
    paddingRight: 20
  },
  clearAllWrapper: {
    zIndex: -9
  },
  clearAllTouchable: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 10,
    zIndex: -9,

  },
  clearAllTouchableText: {
    fontSize: 15,
    color: "#3271C6",
    textDecorationLine: "underline",
    fontWeight: '400',
    marginRight: 20
  }
};
