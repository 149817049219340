import React, {Component} from 'react';
import {View, ScrollView, Image, TouchableOpacity, TextInput, Keyboard} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import {logout} from '../../../store/actions/index';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import Header from '../../Header/header';
import * as axiosCommon from '../../../axios/axios-common';
import * as url from '../../../store/actions/urls';
import SpaceBar from '../../SpaceBar/SpaceBar';
import UIText from '../../Text/text';
import ActivityIndicator from '../../ActivityIndicator/activityIndicator';
import AutoCompleteSuggestion from '../../AutoComplete/AutoCompleteSuggestion';
import {METRICS} from '../../../navigation/path';
import PageContainer from '../../../components/PageLayout/PageContainer';
import * as ColorCode from '../../../components/ColorPicker/ColorCodes';
import SavingConfirmation from '../../ConfirmationBox/savingConfirmation';
import {
  alertBody,
  healthdataTitle,
} from '../../../GlobalFunctions/GlobalString';
import MenuOverlayContainer from '../../../components/PageLayout/MenuOverlayContainer';
import { pendoTrackEvent } from '../../../util/pendoConfig';
import {autoSuggestionListType, chartTypes} from '../../../Constant/index';
import * as FieldDetails from '../../TextInput/fieldDetails';
import FieldTitle from '../../TextInput/fieldTitle';
import GraphPropertySelector from '../GraphPropertySelector/GraphPropertySelector';
import { getSuggestedTeams, getUnits, getMeasurementUnits, getUserTerms } from '../../../util/commonUiLogic';
import {hasValue} from '../../../util/hashValueGenerator';

class AddEditMetric extends Component {
  constructor(props) {
    super(props);
    const {systemDefinedMetrics, selectMetricArray = {}} = props;
    const {displayProperties} = selectMetricArray ? selectMetricArray : {};
    const {color} = displayProperties ? displayProperties : {};
    this.state = {
      id: props.selectMetricArray ? props.selectMetricArray.id : -1,
      selectedUnit: (props.selectUOMArray || {}).id || "",
      selectedUnitName: (props.selectUOMArray || {}).symbol || "", //To set the unitName, when non of the unit selected from units list(ZI-2290)
      unitName: (props.selectUOMArray || {}).symbol || "",
      typeOfMeasurement: props.selectMetricArray
        ? props.selectMetricArray.typeOfMeasurement
        : null,
      metricGroup: props.selectMetricArray
        ? props.selectMetricArray.metricGroup
        : 1,
      metricName: props.selectMetricArray ? props.selectMetricArray.name : null,
      graphStyle:
        props.selectMetricArray && props.selectMetricArray.displayProperties
          ? props.selectMetricArray.displayProperties.style
              ? props.selectMetricArray.displayProperties.style
              : props.selectMetricArray.displayProperties.dashedLine === true
                  ? chartTypes.line
                  : chartTypes.dash
          : chartTypes.dash,
      color: color ? color : ColorCode.BGHEALTHDATA,
      saveWait: false,
      validMetric: true,
      validMetricUnit: props.selectUOMArray && props.selectUOMArray?.id ? true : false,
      validMetricUnitError: false,
      validMeasurementType: true,
      recordings: props.selectMetricArray
        ? props.selectMetricArray.recordings
        : null,
      error:
        props.selectMetricArray &&
        props.selectMetricArray.recordings &&
        props.selectMetricArray.recordings !== 0
          ? true
          : false,
      errorDescription:
        props.selectMetricArray &&
        props.selectMetricArray.recordings &&
        props.selectMetricArray.recordings !== 0
          ? 'Only graph properties and units can be edited for metrics labels with recordings or that are predefined.'
          : '',
      editable:
        props.selectMetricArray &&
        props.selectMetricArray.recordings &&
        props.selectMetricArray.recordings !== 0
          ? false
          : true,
      cancelConfirm: false,
      cancelWait: false,
      terms: getSuggestedTeams(systemDefinedMetrics, props.metricsDigest.userDefinedMetrics),
      userTerms: getSuggestedTeams(props.metricsDigest.userDefinedMetrics, systemDefinedMetrics, true),
      systemDefinedUnitOfMeasure: [],
      selectedUnits: [],
      lockUnit: (props.selectUOMArray || {}).id ? false : true,
      changedPickerColor: false
    };
    this.initMetricHash = this.getMetricHashValue();
    this.loadingFinished = false;
    this.uomRef = null;
  }

  componentDidMount() {
    this.loadSystemUnitsOfMeasure();
    this.setState(
      {
        metricsDigest: this.props.metricsDigest,
        metricsDigestProcessed: this.props.metricsDigestProcessed,
        unitsOfMeasureDigestProcessed: this.props.unitsOfMeasureDigestProcessed,
      });
    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
    });
    this.props.navigation.addListener('blur', () => {
      this.props.showMobileMenu();
    });
  }

  loadSystemUnitsOfMeasure = () => {
    this.setState({lockUnit: true});
    this.props.getSystemUnitOfMeasure((res, data) => {
      if (res) {
        this.setState(
          {
            systemDefinedUnitOfMeasure: data,
            //typeOfMeasurement: data[0].id,
            lockUnit: false
          });
        if (this.state.metricName && this.state.metricName !== '') {
          let tempId = getUserTerms(this.props.metricsDigest.userDefinedMetrics).filter(item => {
            if (item.data.name === this.state.metricName) {
              return item.data;
            }
          });
          this.setState(
            {
              systemDefinedUnitOfMeasure: getUnits(
                data, tempId[0].data.typeOfMeasurement,
              ),
            });
        }
        this.loadingFinished = true;
        this.forceUpdate();
      }
    });
  };

  // getTerms = () => {
  //   let termsArray = [];
  //   if (this.props.systemDefinedMetrics) {
  //     const suggestionResults = [...this.props.systemDefinedMetrics];
  //     termsArray = suggestionResults.map(value => {
  //       return {name: value.name, data: value};
  //     });
  //   }

  //   return termsArray;
  // };
// TODO: ZI-883 remove old codes
  // getUnitsOfList = unitsArray => {
  //   let unitList = [];
  //   if (this.props.data.typeOfMeasurement) {
  //     unitsArray.map(res =>
  //       res.typeOfMeasurement === this.props.data.typeOfMeasurement
  //         ? unitList.push({name: res.symbol, data: res})
  //         : null,
  //     );
  //   } else {
  //     unitsArray.map(res =>
  //       unitList.push({name: `${res.name} (${res.symbol})`, data: res}),
  //     );
  //   }
  //   return unitList;
  // };

  onItemPress = (selection, type) => {
    const { userDefinedMetricUnitOfMeasures } = this.props;
    Keyboard.dismiss();
    let selectedValue = selection.data;
    const units = getUnits(this.state.systemDefinedUnitOfMeasure, selectedValue.typeOfMeasurement);
    let unit = units?.[0];
    if (type === 'USER') {
      let uomId = userDefinedMetricUnitOfMeasures.filter(res => res.metricId === selectedValue.id)?.[0]?.uomId;
      if (units && uomId) {
        unit = units.filter(res => res.id === uomId)?.[0];
      }
    }
    this.setState({
      metricName: selectedValue.name,
      validMetric: true,
      // unitsArray: units,
      selectedUnit: unit.id,
      selectedUnitName: unit.symbol,
      typeOfMeasurement: selectedValue.typeOfMeasurement,
      selectedUnits: units,
      lockUnit: false,
      unitName: unit.symbol,
      validMetricUnit: true,
      metricGroup: selectedValue.metricGroup,
      changedPickerColor: true
    });
  };

  onAddNewPress = selection => {
    Keyboard.dismiss();
    const units = getUnits(this.state.systemDefinedUnitOfMeasure, null);
    this.setState({
      metricName: selection,
      validMetric: true,
      // unitsArray: units,
      selectedUnit: units[0].id,
      selectedUnitName: units[0].symbol,
      typeOfMeasurement: units[0].typeOfMeasurement,
      lockUnit: false,
      unitName: units[0].symbol,
      validMetricUnit: true,
      selectedUnits: units,
      metricGroup: 1,
      changedPickerColor: true
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.creatingRoutineEntry !== this.props.creatingRoutineEntry) {
      this.setState({saveWait: this.props.creatingRoutineEntry});
    }

    if (
      prevProps.createdRoutineEntry !== this.props.createdRoutineEntry &&
      this.props.createdRoutineEntry
    ) {
      this.props.redirectDashboard();
    }
  }

  // unitsOfArray = typeOfMeasurement => {
  //   if (typeOfMeasurement) {
  //     let unitsArray = this.state.systemDefinedUnitOfMeasure.filter(function(
  //       unit,
  //     ) {
  //       return unit.typeOfMeasurement === typeOfMeasurement;
  //     });
  //     return unitsArray;
  //   }
  //   let unitsArray = this.state.systemDefinedUnitOfMeasure.filter(function(
  //     unit,
  //   ) {
  //     return unit.active;
  //   });
  //   return unitsArray;
  // };

  handleOnSubmitSave = () => {
    this.setState({saveWait: true}, () => {
      if (!this.state.metricName) {
        this.setState({validMetric: false, saveWait: false});
        return;
      } else if (!this.state.validMetricUnit) {
        this.setState({validMetricUnitError: true, saveWait: false});
        return;
      } else {
        this.setState({validMetric: true});
      }
      if (this.uomRef) {
        this.uomRef.turnOffList();
      }
      let userMetrics = null;
      let metricUOM = null;

      let createMetric = {
        metric: {
          id: this.state.id,
          name: this.state.metricName,
          description: '',
          typeOfMeasurement: this.state.typeOfMeasurement,
          metricGroup: this.state.metricGroup || 1,
          displayProperties: {
            color: this.state.color,
            style: this.state.graphStyle,
            scaleMin: null,
            scaleMax: null,
          },
        },
      };
      pendoTrackEvent('AddHealthData@ > Save');
      let CREATE_METRICS = url.CREATE_METRICS;
      let CREATE_UOM = url.CREATE_UOM;
      if (this.props.journalUserId) {
        CREATE_METRICS += '?journalUserId=' + this.props.journalUserId;
        CREATE_UOM += '?journalUserId=' + this.props.journalUserId;
      }

      axiosCommon.axiosPUT(
        CREATE_METRICS,
        res => {
          if (res.type === 'SUCCESS') {
            if (res.responseDto.data.metric) {
              userMetrics = res.responseDto.data.metric;
              this.setState({id: userMetrics.id});

              if (
                userMetrics.id &&
                this.state.selectedUnit &&
                this.state.selectedUnit !== '0'
              ) {
                let createUnitsofMeasurement = {
                  metricDefaultUnitOfMeasure: {
                    metricId: userMetrics.id,
                    uomId: this.state.selectedUnit,
                  },
                };

                axiosCommon.axiosPUT(
                  CREATE_UOM,
                  res => {
                    if (res.type === 'SUCCESS') {
                      if (res.responseDto.data.metricDefaultUnitOfMeasure) {
                        metricUOM =
                          res.responseDto.data.metricDefaultUnitOfMeasure;
                        if (userMetrics && metricUOM) {
                          let getAllMetric = {};
                          if (this.state.systemDefinedUnitOfMeasure) {
                            let unit = this.state.systemDefinedUnitOfMeasure.filter(
                              res => {
                                return res.id == this.state.selectedUnit;
                              },
                            );
                            if (unit && unit.length > 0) {
                              getAllMetric.selectMetricArray = userMetrics;
                              getAllMetric.selectUOMArray = unit[0];
                            }
                          }
                          this.props.createUserMetric(
                            userMetrics,
                            metricUOM,
                            () => {
                              this.props.onSelectMetric(getAllMetric, () => {
                                this.setState({saveWait: false});
                                this.props.redirectViewMetric();
                              });
                            },
                          );
                        }
                      }
                    } else {
                      if (res.errorDto === 'SESSION_TIME_OUT') {
                        console.log("SESSION_TIME_OUT, logging out; Add Edit Metric 1");
                        logout();
                      } else if (res.errorDto === 'NETWORK_ERROR') {
                        this.props.commonErrorHandler('Network Error');
                      } else if (res.errorDto !== 'undefined') {
                        this.props.commonErrorHandler(res.errorDto.data.error);
                        this.setState({
                          saveWait: false,
                        });
                      }
                    }
                  },
                  createUnitsofMeasurement,
                );
              } else {
                this.setState({saveWait: false});
                this.props.redirectViewMetric();
              }
            }
          } else {
            if (res.errorDto === 'SESSION_TIME_OUT') {
              console.log("SESSION_TIME_OUT, logging out; Add Edit Metric 2");
              logout();
            } else if (res.errorDto !== 'undefined') {
              this.props.commonErrorHandler(res.errorDto.data.error);
              this.setState({
                saveWait: false,
              });
            }
          }
        },
        createMetric,
      );
    });
  };

  onChangeChartType = value => {
    this.setState({graphStyle: value});
  };

  handleOnSubmitCancel = () => {
    if (this.initMetricHash === this.getMetricHashValue()) {
      this.handlecancelMetric();
    } else {
      this.setState({
        cancelConfirm: true,
      });
    }
  };
  handlecancelMetric = () => {
    if (this.props.getAllMetric) {
      this.props.redirectBack();
    } else {
      this.setState({
        cancelConfirm: false
      }, () => {
        this.props.redirectMetric();
      });
    }
  };

  onChangeTextMetricName = currentInput => {
    let validMetric = true;
    if (currentInput.length <= 0) {
      this.loadSystemUnitsOfMeasure();
      this.setState({selectedUnits: []});
      validMetric = false;
    } else {
      validMetric = true;
    }
    this.setState({
      metricName: currentInput,
      validMetric: validMetric,
      lockUnit: true,
      selectedUnit: null,
      selectedUnitName: null,
      typeOfMeasurement: null,
      unitName: null,
      validMetricUnit: false,
      changedPickerColor: false
    });
  };

  onValueChangeUnit = value => {
    Keyboard.dismiss();
    const found = this.state.systemDefinedUnitOfMeasure.find(
      element => element.id.toString() === value.data.id.toString(),
    );
    this.setState({
      selectedUnit: value.data.id,
      selectedUnitName: value.data.symbol,
      typeOfMeasurement: found.typeOfMeasurement,
      unitName: value.data.symbol,
      validMetricUnit: true,
      validMetricUnitError: false
    });
  };
// TODO: ZI-883 remove old codes
  // getMeasurementUnits = () => {
  //   let measurementUnitList = [];
  //   if (this.state.selectedUnits.length > 0) {
  //     this.state.selectedUnits.map(res =>
  //       res.typeOfMeasurement && res.active
  //         ? measurementUnitList.push({
  //             label: res.symbol,
  //             value: res.id,
  //             key: res.id,
  //           })
  //         : null,
  //     );
  //   } else {
  //     this.state.systemDefinedUnitOfMeasure.map(res =>
  //       res.typeOfMeasurement && res.active
  //         ? measurementUnitList.push({
  //             label: res.symbol,
  //             value: res.id,
  //             key: res.id,
  //           })
  //         : null,
  //     );
  //   }

  //   return measurementUnitList;
  // };

  getMeasurementUnits = () => {
    const {selectedUnits, systemDefinedUnitOfMeasure, selectedUnit} = this.state;
    let measurementUnitList = [];
    if (selectedUnits.length > 0) {
      measurementUnitList = getMeasurementUnits(selectedUnits, selectedUnit);
    } else {
      measurementUnitList = getMeasurementUnits(systemDefinedUnitOfMeasure, selectedUnit);
    }

    return measurementUnitList;
  };

  getMetricChanges = () => {
    let flag = null;
    if (this.initMetricHash === this.getMetricHashValue()) {
      flag = false;
    } else {
      flag = true;
    }
    return flag;
  };

  onChangeTextName = currentInput => {
    this.setState(prevState => ({
      unitName: currentInput,
      validMetricUnitError: false,
      validMetricUnit: !Boolean(currentInput) ? false : prevState.validMetricUnit,
      //selectedUnit: !Boolean(currentInput) ? "" : prevState.validMetricUnit,
    }));
  }

  getMetricHashValue = () => {
    const {id, metricName, color, graphStyle, unitName, selectedUnit} = this.state;
    return hasValue(
      id +
        ',' +
        metricName +
        ',' +
        color +
        ',' +
        graphStyle +
        ',' +
        unitName +
        ',' +
        selectedUnit
    );
  }

  updateTermList = () => {
    this.setState({selectedUnit: null, selectedUnitName: null})
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {color, terms, userTerms, graphStyle, changedPickerColor} = this.state;
    return (
      <React.Fragment>
        <View 
          style={[styles.pageBodyWrapper]} 
          onTouchStart={() => {
            this.setState(prevState => ({
              unitName: prevState.selectedUnitName,
              validMetricUnitError: false,
              validMetricUnit: true,
              selectedUnit: prevState.selectedUnit,
            }));
          }}>
          <Header
            showFilers={false}
            showBack={true}
            showClearFilters={false}
            showNotify={true}
            handleOnPressBackButton={this.handleOnSubmitCancel}
            index={METRICS.index}
            navigation={this.props.navigation}
            route={this.props.route}
          />
          <SpaceBar />
          {Boolean(this.getMetricChanges()) && (
            <MenuOverlayContainer onPressOpen={this.handleOnSubmitCancel} />
          )}
          <ScrollView
            style={styles.scrollViewArea}
            keyboardShouldPersistTaps={'always'}>
            <View style={styles.gridSection}>
              <PageContainer smScreenFull={true}>
                <View style={stylesRes.contBotPd} dataSet={{media: ids.contBotPd}}>
                  <View style={[styles.gridRow, styles.zIndexAutoComplete]}>
                    <View style={styles.gridColFull}>
                      <View style={[styles.secArea]}>
                        <View style={[styles.formInnerArea]}>
                          <View style={[styles.colPdTopLtRt]}>
                            <View style={[styles.fieldRow]}>
                              <View style={[styles.fieldColDouble]}>
                                <View style={[styles.fieldColMain]}>
                                  <View
                                    style={[
                                      styles.fieldColMax,
                                      styles.fledSpRt,
                                    ]}>
                                    <AutoCompleteSuggestion
                                      textFieldWithTitle={true}
                                      textFieldTitle="Enter Health Data"
                                      remortSearch={false}
                                      triggerLength={1}
                                      onChangeText={this.onChangeTextMetricName}
                                      onItemPress={this.onItemPress}
                                      currentInput={this.state.metricName}
                                      onPressCustom={this.onAddNewPress}
                                      showCustomSection={true}
                                      autoFocus={true}
                                      terms={terms}
                                      userTerms={userTerms}
                                      editable={this.state.editable}
                                      validateName={!this.state.validMetric}
                                      maxLength={50}
                                      zoomIcon={true}
                                      removeIcon={true}
                                      type={autoSuggestionListType.health_data}
                                      placeholder="Search..."
                                    />
                                  </View>
                                </View>

                                {/* {this.state.systemDefinedUnitOfMeasure &&
                                this.state.systemDefinedUnitOfMeasure.length >
                                  0 ? ( */}
                                  <View style={stylesRes.heathUnitMeasure} dataSet={{media: ids.heathUnitMeasure}}>
                                    <View style={styles.fieldColMax}>
                                      {/* // TODO: ZI-883 remove old codes
                                      <View
                                        style={[styles.pickerSelectionWrapper]}>
                                        <Text
                                          style={[
                                            styles.textView,
                                            styles.textViewPicker,
                                          ]}
                                          allowFontScaling={false}
                                          >
                                          <UIText
                                            style={[
                                              styles.textLabel,
                                              styles.bold,
                                              styles.textColorLight,
                                            ]}
                                            title="Unit"
                                          />
                                        </Text>

                                        <View
                                          css={[
                                            stylesRes.dropArrowView,
                                            stylesRes.dropArrowTopLg,
                                          ]}
                                          pointerEvents={'none'}>
                                          <View css={[stylesRes.dropArrowIcon]}>
                                            <Image
                                              style={[styles.imgContain]}
                                              source={require('../../../assets/icons/dropdown-arrow.png')}
                                            />
                                          </View>
                                        </View>
                                        <Picker
                                          items={this.getMeasurementUnits()}
                                          selectedValue={
                                            this.state.selectedUnit
                                          }
                                          style={[
                                            styles.pickerAreaField,
                                            styles.selectionPicker,
                                          ]}
                                          onValueChange={value =>
                                            this.onValueChangeUnit(value)
                                          }
                                        /> */}
                                        {this.state.lockUnit ?
                                          <View style={{opacity:0.5}}>
                                            <FieldTitle
                                              fieldBg={FieldDetails.BGWHITE}
                                              title="Unit of Measure"
                                            />
                                            <View style={[styles.autoCompleteBtnView]}>
                                              <View style={[styles.btnActionTouch, styles.IconRtPadding]}>
                                                <View style={[
                                                  styles.autoCompleteIconView,
                                                  styles.mgRtZero
                                                ]}>
                                                  <Image
                                                    style={styles.searchZoomIcon}
                                                    source={require('../../../assets/icons/search-zoom.png')}
                                                  />
                                                </View>
                                              </View>
                                              </View>
                                            <TextInput 
                                              placeholder="Search..." 
                                              editable={false}
                                              allowFontScaling={false}
                                              underlineColorAndroid="transparent"
                                              style={[
                                                styles.textInputField,
                                                styles.bgWhite,
                                                styles.textAlignLeft,
                                              ]}
                                            />
                                          </View>
                                        :
                                          <AutoCompleteSuggestion
                                            textFieldWithTitle={true}
                                            textFieldTitle="Unit of Measure"
                                            fieldBg={FieldDetails.BGWHITE}
                                            remortSearch={false}
                                            //triggerLength={1}
                                            onChangeText={this.onChangeTextName}
                                            onItemPress={this.onValueChangeUnit}
                                            currentInput={this.state.unitName}
                                            autoFocus={false}
                                            userTerms={this.getMeasurementUnits()}
                                            zoomIcon={true}
                                            removeIcon={true}
                                            autoDisplayUserList={true}
                                            showCustomSection={false}
                                            showOtherResults
                                            type={autoSuggestionListType.health_data_uom}
                                            //showCloseAfterPressItem={Boolean(this.state.unitName)}
                                            removeSelectedIcon
                                            validateName={this.state.validMetricUnitError}
                                            placeholder="Search..."
                                            typeAhead={this.props.measurementTypes}
                                            getRef={ref => (this.uomRef = ref)}
                                            selectedValue={this.state.selectedUnit}
                                            onBlur={() => {
                                              this.setState(prevState => ({
                                                unitName: prevState.selectedUnitName,
                                                validMetricUnitError: false,
                                                validMetricUnit: true,
                                                selectedUnit: prevState.selectedUnit,
                                              }));
                                            }}
                                            updateTermList = {this.updateTermList}
                                          />
                                        }
                                      {/* </View> */}
                                    </View>
                                  </View>
                                {/* // ) : (
                                  // <View style={[styles.fieldColSub]}> */}
                                    {/* <View style={[styles.fieldColMax]}>
                                      <View
                                        style={[styles.pickerSelectionWrapper]}>
                                        <Text
                                          style={[
                                            styles.textView,
                                            styles.textViewPicker,
                                          ]}
                                          allowFontScaling={false}
                                          >
                                          <UIText
                                            style={[
                                              styles.textLabel,
                                              styles.bold,
                                              styles.textColorLight,
                                            ]}
                                            title="Unit"
                                          />
                                        </Text>

                                        <View
                                          css={[
                                            stylesRes.dropArrowView,
                                            stylesRes.dropArrowTopLg,
                                          ]}
                                          pointerEvents={'none'}>
                                          <View css={[stylesRes.dropArrowIcon]}>
                                            <Image
                                              style={[styles.imgContain]}
                                              source={require('../../../assets/icons/dropdown-arrow.png')}
                                            />
                                          </View>
                                        </View>
                                        <Picker
                                          items={[
                                            {
                                              label: '',
                                              value: '0',
                                              key: 'u_0',
                                            },
                                          ]}
                                          style={[
                                            styles.pickerAreaField,
                                            styles.selectionPicker,
                                          ]}
                                        />
                                        {/* <Picker.Item label="" value="0" />
                                        </Picker> 
                                      </View>
                                   </View> */}
                                  {/* </View>
                                )} */}
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  
                  <View style={[styles.gridRow, styles.zIndexMin]}>
                    <View style={styles.gridColFull}>
                      {Boolean(this.state.error) && (
                        <View style={styles.notifyArea}>
                          <View
                            style={[
                              styles.colPdLtRt,
                              styles.subViewPd,
                              styles.borderBotGrey,
                            ]}>
                            <View style={styles.notifyBlock}>
                              <View
                                style={[
                                  styles.notifyDetails,
                                  styles.notifyNoPd,
                                ]}>
                                <View style={styles.notifyWarning}>
                                  <Image
                                    style={styles.iconWarning}
                                    source={require('../../../assets/icons/warning.png')}
                                  />
                                </View>

                                <UIText
                                  style={[styles.notifyText, styles.bold]}
                                  title={this.state.errorDescription}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      )}
                    </View>
                  </View>

                  <GraphPropertySelector
                    onChangeChartType={this.onChangeChartType}
                    graphStyle={graphStyle}
                    onChangeColor={color => {
                      this.setState({color: color});
                    }}
                    color={color}
                    isEdit={true}
                    changedPickerColor={changedPickerColor}
                  />

                  <View style={[styles.gridRow, styles.zIndexMin]}>
                    <View style={styles.gridColFull}>
                      <View style={[styles.colPdLtRt]}>
                        <View style={styles.btnArea}>
                          <View style={stylesRes.btnViewSec}>
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={[styles.cancelButtonSm, styles.cancelBtnSmCr]}
                                  underlayColor="rgba(0, 153, 168, 0.AS8)"
                                  onPress={() => {
                                    this.handleOnSubmitCancel();
                                    pendoTrackEvent('AddHealthData@ > Cancel');
                                  }}
                                  disabled={this.state.saveWait}
                                  nativeID={'addHealthDataCancel'}>
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent,
                                      ]}>
                                      <UIText
                                        style={[styles.submitText, styles.cancelTxtCr]}
                                        title="Cancel"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                            <View style={styles.fieldBtnSp} />
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={styles.submitSm}
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={this.handleOnSubmitSave}
                                  disabled={
                                    this.state.saveWait ||
                                    !this.getMetricChanges
                                  }
                                  nativeID={'addHealthDataSave'}>
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent,
                                      ]}>
                                      {this.state.saveWait && (
                                        <View style={styles.actIndCom}>
                                          <ActivityIndicator size="small" />
                                        </View>
                                      )}
                                      <UIText
                                        style={styles.submitText}
                                        title="Save"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </PageContainer>
            </View>
          </ScrollView>
        </View>
        {this.state.cancelConfirm &&
        <SavingConfirmation
          visibleModal={this.state.cancelConfirm}
          onBackdropPress={() => {
            this.setState({cancelConfirm: false});
          }}
          onRequestClose={() => {
            this.setState({cancelConfirm: false});
          }}
          handleCancel={() => {
            this.setState({cancelConfirm: false});
            this.handlecancelMetric();
          }}
          alertTitle={healthdataTitle}
          alertBody={alertBody}
          handleConfirm={() => {
            this.setState({cancelConfirm: false});
            this.handleOnSubmitSave();
          }}
          confirmWait={this.state.saveWait}
          disabled={this.state.saveWait}
        />
      }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    metricsDigest: state.routines.metricsDigest,
    peer2peerUserProfile: state.routines.peer2peer,
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    createdRoutineEntry: state.routines.createdRoutineEntry,
    creatingRoutineEntry: state.routines.creatingRoutineEntry,
    measurementTypes: state.routines.unitsOfMeasureDigest.measurementTypes,
    systemDefinedMetrics: state.routines.metricsDigest.systemDefinedMetrics,
    userDefinedMetricUnitOfMeasures:
      state.routines.metricsDigest.userDefinedMetricUnitOfMeasures,
    getAllMetric: state.metrics.selectedMetric,
    selectMetricArray: state.metrics.selectedMetric
      ? state.metrics.selectedMetric.selectMetricArray
      : null,
    selectUOMArray: state.metrics.selectedMetric
      ? state.metrics.selectedMetric.selectUOMArray
      : null,
    errorDescription: state.metrics.errorDescription,
    error: state.metrics.error,
    journalUserId: state.uistate.journalUserId,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    createUserMetric: (userMetrics, metricUOM, callback) =>
      dispatch(actions.createUserMetric(userMetrics, metricUOM, callback)),
    onSelectMetric: (selectedContact, callBack) =>
      dispatch(actions.onSelectMetric(selectedContact, callBack)),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    getSystemUnitOfMeasure: callBack =>
      dispatch(actions.getSystemUnitOfMeasure(callBack)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEditMetric);
