import * as variable from "../css/base/variable";
export const cssScrollResponsive = {
  menuScroll: {
    "@media (max-height: 479px)": {
      maxHeight: 300,
      overflowY: "auto",
      direction: "rtl",
      paddingLeft: 10
    }
  },
  menuScrollSm: {
    "@media (max-height: 479px)": {
      maxHeight: 300,
      overflowY: "auto",
      paddingLeft: 10,
      paddingRight: 20,
      marginTop: 10
    }
  },
  backDropOpen: {
    overflow: "hidden"
  },
  userInfoTextMax: {
    width: "100%",
    maxHeight: 480,
    overflowY: "auto",
    overflowX: "hidden",
    "@media (max-width: 767px)": {
      maxHeight: 150
    },
    "@media (max-height: 820px)": {
      maxHeight: 200
    },
    "@media (max-height: 560px)": {
      maxHeight: 100
    }
  },
  moreMenuOpenFixed: {
    position: "fixed",
    top: 0
  },
  comImgSecPrem: {
    width: 220,
    height: 170,
    "@media (max-width: 574px)": {
      width: 180,
      height: 139
    },
    "@media (max-width: 374px)": {
      width: 150,
      height: 116
    }
  },
  boxWrapperViewMg: {
    ...variable.marginLtRtAuto,
    "@media (min-width: 992px)": {
      marginLeft: 'auto',
      marginRight: 15,
    },
  },
  userInfoTextCom: {
    width: "100%",
    maxHeight: 145,
    overflowY: "auto",
    overflowX: "hidden",
  },
  userInfoTextLg: {
    maxHeight: 170,
  },
  autoCompleteScrollCr: {
    maxHeight: 260,
    overflow: 'auto'
  },
};
