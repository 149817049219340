import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import {
  HealthDataContent,
  MedicationsContent,
  ProfileContent,
  RoutineContent,
  SymptomContent,
} from './SetupContent';
//import SuccessBox from '../../ConfirmationBox/successBox';
class SetupModal extends Component {
  constructor(props) {
    super(props);
    const {activeTab, windowWidth, windowHeight} = props;
    this.state = {
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      activeTab: activeTab,
      //visibleSetupSuccessModal: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {activeTab, windowHeight, windowWidth} = props;
    if (
      activeTab !== state.activeTab ||
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth
    ) {
      return {
        activeTab: activeTab,
        windowWidth: windowWidth,
        windowHeight: windowHeight,
      };
    }

    return null;
  }

  showSetupSuccessIfComplete = (graphId, activeTab) => {
    if (this.props.isSetupOn && this.isSetupComplete()) {
      //auto hide the setup
      //this.showSetupSuccessModal();
      this.props.showSuccessPopup(12, graphId);
      this.props.onSetShowMappedDataPopupNextTime(true);
      this.props.setActiveTab(activeTab);
      this.props.closeModal();
      //delay the setupwizard hide a bit to show the success box
      setTimeout(() => this.props.hideSetup(this.props.journalUserId, () => {}), 3000);
    }
  }

  isSetupComplete = () => {
    const {
      conditions = [],
      userConditions = [],
      treatments = [],
      userTreatments = [],
      symptoms = [],
      userSymptoms = [],
      metrics = [],
      userDefinedMetrics = [],
      journalEntryTypes = [],
    } = this.props;

    if (
      ((conditions && conditions.length > 0) ||
        (userConditions && userConditions.length > 0)) &&
      ((treatments && treatments.length > 0) ||
        (userTreatments && userTreatments.length > 0)) &&
      ((symptoms && symptoms.length > 0) ||
        (userSymptoms && userSymptoms.length > 0)) &&
      ((metrics && metrics.length > 0) ||
        (userDefinedMetrics && userDefinedMetrics.length > 0)) &&
      (journalEntryTypes && journalEntryTypes.length > 0)
    ) {
      //all steps complete
      return true;
    }
    //at least one step is pending
    return false;
  };

  //change the flow with ZI-1279
  // showSetupSuccessModal = () => {
  //   this.setState({
  //     visibleSetupSuccessModal: true,
  //   }, () => {
  //     setTimeout(() => {
  //       this.setState({visibleSetupSuccessModal: false})
  //     }, 3000);
  //   });
  // };

  closeModal = () => setTimeout(() => this.props.closeModal(), 3000);

  render() {
    const {tabs, setActiveTab, windowWidth} = this.props;
    const {activeTab} = this.state;

    return (
      <>
        {activeTab === tabs.profile && (
          <ProfileContent
            closeModal={this.closeModal}
            setActiveTab={setActiveTab}
            tabs={tabs}
            windowWidth={windowWidth}
            onSaveTriggered={this.showSetupSuccessIfComplete}
          />
        )}
        {activeTab === tabs.medications && (
          <MedicationsContent
            closeModal={this.closeModal}
            setActiveTab={setActiveTab}
            tabs={tabs}
            windowWidth={windowWidth}
            onSaveTriggered={this.showSetupSuccessIfComplete}
          />
        )}
        {activeTab === tabs.symptom && (
          <SymptomContent
            closeModal={this.closeModal}
            setActiveTab={setActiveTab}
            tabs={tabs}
            windowWidth={windowWidth}
            onSaveTriggered={this.showSetupSuccessIfComplete}
          />
        )}
        {activeTab === tabs.healthData && (
          <HealthDataContent
            closeModal={this.closeModal}
            setActiveTab={setActiveTab}
            tabs={tabs}
            windowWidth={windowWidth}
            onSaveTriggered={this.showSetupSuccessIfComplete}
          />
        )}
        {activeTab === tabs.routine && (
          <RoutineContent
            closeModal={this.closeModal}
            setActiveTab={setActiveTab}
            tabs={tabs}
            windowWidth={windowWidth}
            onSaveTriggered={this.showSetupSuccessIfComplete}
          />
        )}
        {/* <SuccessBox
          visibleModal={this.state.visibleSetupSuccessModal}
          message="Your setup is successful"
        /> */}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.routines.user,
    windowWidth: state.uistate.windowWidth,
    windowHeight: state.uistate.windowHeight,
    visibleGraph: state.rollinggraphs.dashboardGraph,
    dashboardProcessed: state.routines.dashboardProcessed,
    journalUserId: state.uistate.journalUserId,
    conditions: state.setup.conditions,
    userConditions: state.routines.userConditions,
    treatments: state.setup.treatments,
    userTreatments: state.routines.treatments,
    symptoms: state.setup.symptoms,
    userSymptoms: state.routines.symptomsDigest
      ? state.routines.symptomsDigest.userSymptoms
      : [],
    metrics: state.setup.metrics,
    userDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.userDefinedMetrics
      : [],
    journalEntryTypes: state.routines.journalEntryTypes,
    isSetupOn: state.routines.applicationSettingsDigest.isSetupOn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onHideShowGraph: value => dispatch(actions.onHideShowGraph(value)),
    selectGraph: (graph, callBack) =>
      dispatch(actions.selectGraph(graph, callBack)),
    isFromLogin: () => dispatch(actions.isFromLogin()),
    addTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.createTherapie(addNewTherapie, callBack, journalUserId)),
    onSaveQuestionToAttach: question =>
      dispatch(actions.saveQuestionToAttach(question)),
    resetRollingGraph: () => dispatch(dispatch(actions.resetRollingGraph())),
    hideSetup: (journalUserId, callBack) =>
      dispatch(actions.hideSetup(journalUserId, callBack)),
    showSuccessPopup: (id, graphId) => dispatch(actions.showSuccessPopup(id, graphId)),
    onSetShowMappedDataPopupNextTime: (isShow) => dispatch(actions.setShowMappedDataPopupNextTime(isShow))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetupModal);
