import React, { Component } from "react";
import {View, ScrollView} from 'react-native';
import styles from '../../Css';
import CloseButton from '../../components/Button/closeButton';
import { connect } from "react-redux";

class ModalLayout extends Component {
  constructor(props) {
    super(props);

    const { windowHeight, windowWidth } = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth } = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  render() {
    const { windowWidth } = this.props;
    return (
      <View style={[styles.modalLayoutCom,
        windowWidth > 767 && this.props.modalDynamic
        ? styles.modalLayoutWidthLg
        : windowWidth > 767 && this.props.modalVideo
        ? styles.modalLayoutWidthExLg
        : styles.modalLayoutWidth]}>
      <ScrollView 
        style={styles.modalScroller} 
        keyboardShouldPersistTaps={'always'}
      >
        <View style={[styles.popUpBlock, styles.bgWhite,
        this.props.boxStyle
        ? styles.popUpRadZero
        : styles.popUpRad,

        this.props.bordeRadSm
        && styles.successboxRoundCorners
        
        ]}>
          <View
            style={[
              this.props.pdCom
              ? styles.popUpContentPdLg
              : styles.popUpContentPdAll,
              this.props.popupAuto
              ? styles.popUpBoxAuto
              : styles.popUpBoxSm,
              this.props.contPosition === 'end'
                ? styles.flexJcFe
                : this.props.contPosition === 'start'
                ? styles.flexJcFs
                : styles.flexJcCt,
              this.props.modalSpecStyle
            ]}>
            {this.props.showClose && (
              <CloseButton
                nativeId={this.props.nativeId} 
                touchableOnPress={this.props.hidePopup}
                closeIcnType={this.props.closeIcnType}
                />
            )}
            {this.props.children}
          </View>
        </View>
      </ScrollView>
    </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(mapStateToProps, null)(ModalLayout);
