import React from "react";
import { View, Text } from "react-native";
import styles from "../../Css";

const emailAccountVerifiedSuccess = () => {
  return (
    <View style={[styles.popMessageContView, styles.comSpSm]}>
      <View style={styles.popMessageTitleView}>
        <Text style={[styles.textPrimeLg, styles.textAlignCenter]} allowFontScaling={false}>
          Thank you!
        </Text>
      </View>

      <View style={styles.popMessageDescView}>
        <Text style={[styles.textPrimeLgBold, styles.textAlignCenter]} allowFontScaling={false}>
          Your email account was successfully verified
        </Text>
      </View>
    </View>
  );
};

export default (emailAccountVerifiedSuccess);
