import React from "react";
import { View, Image, TouchableOpacity, Text } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";

const menuItem = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const { onPress, title, icon, type } = props;
  return (
    <View
      style={[
        title === "Quick Note" ? styles.liItemBorderNone : styles.liItemBorder,
        styles.liItemMenu,
      ]}
      nativeID={`orangeAddExpand${title.replace(/[&/ ]/g, '')}`}
    >
      <TouchableOpacity onPress={onPress}>
        <View
          nativeID={`orangeAdd${title.replace(/[&/ ]/g, '')}`}
          style={[
            stylesRes.navListLiDet,
            stylesRes.navPd,
            stylesRes.navPdPgTop,
            stylesRes.rowRev,
          ]}
          dataSet={{media: ids.rowRev}}
        >
          <View 
            style={[styles.navListLiImgLg]}>
            <Image style={[styles.navMenuIcon]} source={icon} />
          </View>
          <View
            style={[
              type === "W"
                ? stylesRes.navListLiContentLg
                : stylesRes.navListDescLg,
            ]}
            dataSet={{media: ids.navListLiContentLg}}
          >
            <Text style={[styles.text, styles.menuText]} allowFontScaling={false}>{title}</Text>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (menuItem);
