import React, { Component } from "react";
import { View, Text } from "react-native";
import styles from "../../Css";
import UIText from "../Text/text";
import UITextInput from "../TextInput/textInput";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

class ReportImagesAndDocumentsTitel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesTitle: this.props.imagesTitle
    };
  }

  onChangeImagesTitle = val => {
    this.setState({ imagesTitle: val });
  };

  render() {
    return (
      <View style={[styles.fieldRow, styles.fieldRowNoMg]}>
        <Text style={[styles.textView]} allowFontScaling={false}>
          <UIText
            style={[styles.textLabel, styles.bold, styles.textColorLight]}
            title="Title"
          />
        </Text>

        <UITextInput
          style={[styles.textField, styles.textFieldWhite]}
          value={this.state.imagesTitle}
          onChangeText={this.onChangeImagesTitle}
          onBlur={() => this.props.updateImagesTitle(this.state.imagesTitle)}
        />
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    imagesTitle: state.report.imagesTitle
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateImagesTitle: imagesTitle =>
      dispatch(actions.updateImagesTitle(imagesTitle))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportImagesAndDocumentsTitel);
