import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import ProfileImageComponent from '../../../../components/PeerToPeer/MainSection/Profile/ProfileImageComponent';
import {getUrl} from '../../../../GlobalFunctions/GetImage';

class ProfileImageScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleEditProfilePhotoModal: false,
      profileImage: null,
      tempProfileImage: null,
      imageLoading: true,
      url: null,
      profilePicRemoved: false,
      fileUploadWait: false,
      uploadError: false,
      uploadErrorMesage: "",
    };
  }

  componentDidMount() {
    const {journalUserId} = this.props;
    let url = '/api/peer/profileimage?';
    getUrl(url, journalUserId, url => {
      this.setState({
        url: url,
      });
    });
  }

  imageLoadingError = () => {
    this.setState({imageLoading: false});
  };

  onPeertoPeerImageUploadSucess = profileImage => {
    this.setState({profileImage, tempProfileImage: null, profilePicRemoved: false}, () => {
      this.props.uploadProfileImageSucess();
    });
  };

  handleOnUploadProfileImage = (formData, image) => {
    // const {formData} = this.props;
    // const image = this.state.tempProfileImage;
    this.setState({fileUploadWait: true});
    if (this.state.profilePicRemoved) {
      this.props.deleteProfileImage(
        'PEER',
        (success, message) => {
          if (success) {
            this.setState(
              {
                visibleEditProfilePhotoModal: false,
                // profilePicRemoved: false,
                imageLoading: false,
                // tempProfileImage: null,
              },
              () =>
                setTimeout(() => {
                  this.onPeertoPeerImageUploadSucess(null);
                }, 1000),
            );
          } else {
            this.setState({
              uploadError: true,
              uploadErrorMesage: message,
            });
          }
          this.setState({fileUploadWait: false});
        },
        this.props.journalUserId,
      );
    } else if (!this.props.imageUpload) {
      this.props.onUploadp2p(formData, (success, message) => {
        if (success) {
          this.setState(
            {
              visibleEditProfilePhotoModal: false,
              // profilePicRemoved: false,
              // tempProfileImage: null,
            },
            () =>
              setTimeout(() => {
                this.onPeertoPeerImageUploadSucess(image);
              }, 1000),
          );
          // this.onPeertoPeerImageUploadSucess(image);
        } else {
          this.setState({uploadError: true, uploadErrorMesage: message});
        }
          this.setState({fileUploadWait: false});
      });
    } else {
      this.onPeertoPeerImageUploadSucess(image);
      this.setState({
        visibleEditProfilePhotoModal: false, 
        profilePicRemoved: false,
        tempProfileImage: null,
        fileUploadWait: false
      });
    }
  }

  render() {
    const {imageUpload, getImageUrl, disableUploadProfile, commonErrorHandler} = this.props;

    const {
      profileImage,
      imageLoading,
      url,
      visibleEditProfilePhotoModal,
      tempProfileImage,
      profilePicRemoved,
      fileUploadWait,
      uploadError,
      uploadErrorMesage,
    } = this.state;

    const userAvatarName = this.props.userName
      ? this.props.userName.charAt(0) +
        (this.props.userName.length > 1 ? this.props.userName.charAt(1) : '')
      : null;
    return (
      <ProfileImageComponent
        profileImage={profileImage}
        imageLoading={imageLoading}
        uri={url}
        visibleEditProfilePhotoModal={visibleEditProfilePhotoModal}
        getImageUrl={getImageUrl}
        imageUpload={imageUpload}
        userAvatarName={userAvatarName}
        onRequestClose={() => {
          this.setState({
            visibleEditProfilePhotoModal: false, 
            profilePicRemoved: false,
            tempProfileImage: null,
            uploadError : false,
            uploadErrorMesage : ''
          });
        }}
        imageLoadingError={this.imageLoadingError}
        onUploadSucess={this.onPeertoPeerImageUploadSucess}
        onChangeProfileImage={image => this.setState({tempProfileImage: image, uploadError: false})}
        onPress={() => {
          if (Boolean(imageUpload)) {
            commonErrorHandler("Please enter username before upload profile photo");
          } else {
            this.setState({visibleEditProfilePhotoModal: true});
          }
        }}
        nativeId={this.props.nativeId}
        tempProfileImage={tempProfileImage}
        profilePicRemoved={profilePicRemoved}
        removeProfilePic={status => {
          if (status) {
            this.setState({
              tempProfileImage: null,
              profilePicRemoved: status,
              uploadError: false,
            })
          } else {
            this.setState({profilePicRemoved: status})
          }
        }}
        handleOnUploadProfileImage={this.handleOnUploadProfileImage}
        fileUploadWait={fileUploadWait}
        uploadError={uploadError}
        uploadErrorMesage={uploadErrorMesage}
        cancelCropping={() => {
          this.setState({
            tempProfileImage: null,
            uploadError : false,
            uploadErrorMesage : ''
          });
        }}
        disableUploadProfile={disableUploadProfile}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadProfileImageSucess: () =>
      dispatch(actions.uploadProfileImageSucess()),
    onUpload: (formData, callBack, journalUserId) =>
      dispatch(actions.uploadProfileImage(formData, callBack, journalUserId)),
    onUploadp2p: (formData, callBack) =>
      dispatch(actions.uploadp2pProfileImage(formData, callBack)),
    deleteProfileImage: (type, callBack, journalUserId) =>
      dispatch(actions.deleteProfileImage(type, callBack, journalUserId)),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileImageScreen);
