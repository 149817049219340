import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Switch,
} from 'react-native';
import PageContainer from '../../../PageLayout/PageContainer';
import styles from '../../../../Css';
import ProfileImage from '../../../../screens/PeerToPeer/MainSection/Profile/ProfileImageScreen';
import SavingConfirmation from '../../../../components/ConfirmationBox/savingConfirmation';
import KeyboardAwareScrollViewHoc from '../../../KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import EditableInput from './EditableInput';
import ProfileTextArea from './ProfileTextAreaInput';
import ProfileDetail from './ProfileDetail';
import stylesResponsive from "../../../../CssResponsive";
import { profileCancelTitle} from '../../../../GlobalFunctions/GlobalString';
import UnlockCommunityFeature from '../../../ConfirmationBox/unlockFeature';
import ActivityIndicator from "../../../../components/ActivityIndicator/activityIndicator";
import { visibleType } from '../../../../screens/PeerToPeer/MainSection/Profile/Constraints';

const CreateProfile = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {
    saveWait,
    username,
    invalidUsername,
    usernameExist,
    story,
    visibility,
    profile,
    getImageUrl,
    usernameEditable,
    editPenVisible,
    removeIcon,
    journalUserId,
    onUsernameChange,
    onBlurUsername,
    handleEditUsername,
    handleClearUsername,
    onVisibilityChange,
    onChangeStory,
    handleOnSaveProfile,
    saveChangeConfirmBox,
    onBackdropPress,
    handleConfirm,
    scrollViewRef,
    alertBody,
    handleCancel,
    handleCancelUserName,
    formData,
    enableUserNameSave,
    enableUserNameSaveCancel,
    medicalConditionList,
    symptomList,
    treatmentList,
    personalDetailList,
    locationDetails,
    onPressUserMedicationCondition,
    onPressTreatment,
    onPressSymptom,
    showUnlockComFeatureModal,
    onCloseUnlockComFeatureModal,
    onPressPersonalDetail,
    onPressLocationDetail,
    onCickEditMainProfile,
    handleOnClickEditCancelItems,
    disableStory,
    showMedicalConditionList,
    showTreatmentList,
    showSymptomList,
    showPersonalDetailList,
    showLocationDetailList,
    onBlurStory,
    onVisibilityChangeWait,
    handleOnClickSaveItems,
    editPenDisabled,
    medicalConditionListSaveWait,
    treatmentListSaveWait,
    symptomListSaveWait,
    personalDetailListSaveWait,
    locationDetailListSaveWait,
    disableListItemSaveButtons,
    disableMedicationListEdit,
    disableTreatmentListEdit,
    disableSymptomListEdit,
    disablePersonalDetailListEdit,
    disableLocationDetailListEdit,
    checkBoxTick,
    storyDisableBorder,
    emptyUserName,
    userNameTaken,
    lockFeature
  } = props;
  scrollViewRef && scrollViewRef(this);

const enableProfileLInk =  !lockFeature && (showMedicalConditionList ||  showTreatmentList || showSymptomList || showPersonalDetailList || showLocationDetailList || (username && username?.trim() !== profile?.username));
return (
    <KeyboardAwareScrollViewHoc ref={scrollViewRef}>
      <PageContainer smScreenFull={true}>
        <View
          style={[
            styles.secArea,
            styles.colPdTopCom,
            styles.colPdLtRt,
            styles.zIndexAutoComplete,
          ]}>
          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>


              <View style={[styles.flexRow, styles.flexJcFsAiCt, styles.infoPdBotImg]}>
                <Text style={[styles.profileText ,stylesRes.profileTextRes]} dataSet={{media: ids.profileTextRes}}>Profile Photo</Text>
                <View style={styles.profileImgWrapper}>
                  <ProfileImage
                    journalUserId={journalUserId}
                    userName={username && username.trim().length > 0 ? username.trim() : null}
                    imageUpload={profile && profile.username ? false : true}
                    getImageUrl={getImageUrl}
                    nativeId={'communityProfileImage'}
                    formData={formData}
                    handleOnSaveProfile={handleOnSaveProfile}
                    disableUploadProfile={lockFeature}
                  />
                </View>

              </View>
              <View style={[styles.comTopBorder]}>
                <View style={[styles.customPaddingProfile, styles.flexRowJcFsAiFs,]} >
                  <View style={styles.commonCont}>
                    <Text style={[styles.profileText, styles.marginZero, stylesRes.profileTextRes]} dataSet={{media: ids.profileTextRes}} >
                      Username<Text style={styles.redTextStar}>*</Text>
                    </Text>
                    <Text style={[styles.maxText, stylesRes.mobileText]} dataSet={{media: ids.mobileText}}>
                      Display Name
                    </Text>

                  </View>
                  <EditableInput 
                    userName={username}
                    onUsernameChange={onUsernameChange}
                    invalidUsername={invalidUsername}
                    onBlurUsername={onBlurUsername}
                    usernameEditable = {usernameEditable}
                    editPenVisible = {editPenVisible}
                    handleEditUsername={handleEditUsername}
                    removeIcon={removeIcon}
                    handleClearUsername={handleClearUsername}
                    profile={profile}
                    usernameExist={usernameExist}
                    enableUserNameSave={enableUserNameSave}
                    enableUserNameSaveCancel={enableUserNameSaveCancel}
                    handleCancelUserName={handleCancelUserName}
                    handleOnSaveProfile={handleOnSaveProfile}
                    saveWait={saveWait}
                    editPenDisabled={editPenDisabled}
                    emptyUserName={emptyUserName}
                    userNameTaken={userNameTaken}
                    lockFeature={lockFeature}
                  />
                </View>
              </View>


              <View style={[styles.borderProfileWidthOne]}>
                <View style={[styles.customPaddingProfile, styles.flexRowJcFsAiFs,]}>
                  <View style={styles.commonCont}>
                    <Text style={[styles.profileText, styles.marginZero, stylesRes.profileTextRes]} dataSet={{media: ids.profileTextRes}} >
                      Your Bio
                    </Text>
                    <Text style={[styles.maxText, stylesRes.mobileText]} dataSet={{media: ids.mobileText}}>
                      Share your story
                    </Text>

                  </View>
                  <ProfileTextArea 
                    story={story}
                    onChangeStory={onChangeStory}
                    disableStory={disableStory || lockFeature}
                    onBlurStory={disableStory ? () => {} : onBlurStory}
                    storyDisableBorder={storyDisableBorder}
                  />
                </View>
              </View>


              <View style={[styles.borderProfileWidthTwo, ]}>
                <View style={[styles.customPaddingProfile, styles.flexBetweenCenter,]}>
                  <View >
                    <Text style={[styles.profileHead, stylesRes.mobileTextTwo]} dataSet={{media: ids.mobileTextTwo}}>
                      Profile Details
                    </Text>
                    <Text style={[styles.maxText, styles.textEm, stylesRes.maxTextRes]} dataSet={{media: ids.maxTextRes}}>
                      Making details public will help connect you to similar users
                    </Text>

                  </View>
                  <View>
                    <TouchableOpacity disabled={enableProfileLInk} style={[styles.cancelProfile, styles.flexJcCtAiCt, styles.editMainBtn , enableProfileLInk &&  styles.btnBlurState ]} onPress={onCickEditMainProfile}><Text style={[styles.cancelText, stylesRes.mobileText]} dataSet={{media: ids.mobileText}}>Edit Main Profile</Text></TouchableOpacity>
                  </View>
                </View>
              </View>

              <ProfileDetail 
                medicalConditionList={medicalConditionList}
                symptomList={symptomList}
                treatmentList={treatmentList}
                personalDetailList={personalDetailList}
                locationDetails={locationDetails}
                onPressUserMedicationCondition={onPressUserMedicationCondition}
                onPressTreatment={onPressTreatment}
                onPressSymptom={onPressSymptom}
                onPressPersonalDetail={onPressPersonalDetail}
                onPressLocationDetail={onPressLocationDetail}
                handleOnClickEditCancelItems={handleOnClickEditCancelItems}
                showMedicalConditionList={showMedicalConditionList}
                showTreatmentList={showTreatmentList}
                showSymptomList={showSymptomList}
                showPersonalDetailList={showPersonalDetailList}
                showLocationDetailList={showLocationDetailList}
                handleOnClickSaveItems={handleOnClickSaveItems}
                medicalConditionListSaveWait={medicalConditionListSaveWait}
                treatmentListSaveWait={treatmentListSaveWait}
                symptomListSaveWait={symptomListSaveWait}
                personalDetailListSaveWait={personalDetailListSaveWait}
                locationDetailListSaveWait={locationDetailListSaveWait}
                disableListItemSaveButtons={disableListItemSaveButtons}
                disableMedicationListEdit={disableMedicationListEdit}
                disableTreatmentListEdit={disableTreatmentListEdit}
                disableSymptomListEdit={disableSymptomListEdit}
                disablePersonalDetailListEdit={disablePersonalDetailListEdit}
                disableLocationDetailListEdit={disableLocationDetailListEdit}
                checkBoxTick={checkBoxTick}
              />
   
              <View style={styles.borderProfileWidthOne}>
                <View style={[styles.bottomSection, styles.flexBestweenStart,]}>
                  <View style={styles.flexRowAliSt}>
                    <View >
                      <Text style={[styles.profileText, styles.marginZero, stylesRes.profileWidthTextPrivacy]} dataSet={{media: ids.profileWidthTextPrivacy}} >
                        Privacy Settings
                      </Text>
                      <Text style={[styles.maxText, styles.serchText,stylesRes.searchTextRes]} dataSet={{media: ids.searchTextRes}}>Do you wish to be visible in community search?</Text>
                    </View>

                    <View style={[styles.userNameContainerCustom, styles.flexRowAiCt, stylesRes.userNameContainerCustomRes]} dataSet={{media: ids.userNameContainerCustomRes}}>
                      <View>
                      {Boolean(onVisibilityChangeWait) && (
                        <View style={[styles.actIndCom, styles.incognitoLoader]}>
                            <ActivityIndicator size="small" />
                        </View>
                        )}
                        <Switch
                          testID={`commiunityProfileToggle`}
                          style={{
                            transform: [{ scaleX: 1.0 }, { scaleY: 1.0 }],
                            marginRight: 15,
                          }}
                          onValueChange={onVisibilityChange}
                          value={visibility === visibleType.PUBLIC ? false : true}
                          disabled={lockFeature || (!(profile && profile.username) ?? true)}
                        />

                      </View>
                      <View>
                        <Text style={[styles.profileText, styles.marginZero]} >
                          Incognito Mode
                        </Text>
                        <Text style={[styles.maxText, stylesRes.EnableText]} dataSet={{media: ids.EnableText}}>Enable incognito to prevent your profile from appearing in search results</Text>
                      </View>

                    </View>
                  </View>
                </View>
              </View>

            </View>
          </View>
        </View>
        {saveChangeConfirmBox &&
          <SavingConfirmation
            visibleModal={saveChangeConfirmBox}
            onBackdropPress={onBackdropPress}
            onRequestClose={onBackdropPress}
            alertBody={alertBody}
            alertTitle={profileCancelTitle}
            handleCancel={handleCancel}
            handleConfirm={handleConfirm}
            btnStyle={styles.btnBackgroundColor}
          />   
        }

        <UnlockCommunityFeature
          visibleModal={showUnlockComFeatureModal}
          onRequestClose={onCloseUnlockComFeatureModal}
        />
      </PageContainer>
    </KeyboardAwareScrollViewHoc>
  );
};

export default (CreateProfile);
