import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';
import {peerToPeerTabs, peerToPeerStatuses} from '../../Constant';
import moment from 'moment';

const initialState = {
  activeTab: peerToPeerTabs.myNetwork,
  peersResults: {},
  profile: null,
  p2pProfileHasDataToSave: null,
  searchTearms: null,
  searchedKeyword: null,
  connectionRequestMessage: null,
  connectionRequestCount: null,
  channels: [],
  selectedChannel: null,
  newMessageCount: 0,
  unSeenConversations: {},
  chatStatus: peerToPeerStatuses.INITIALIZING,
  resultsUpdateTimeStamp: null,
  showHideStatusDetails : {},
  commiunityDetails : {}


};

const setActiveTab = (state, action) => {
  return updateObject(state, {
    activeTab: action.activeTab,
  });
};

const updatePeer2PeerProfileSuccess = (state, action) => {
  return updateObject(state, {
    profile: action.userUpdated,
  });
};

const updatePeer2PeerProfileFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const OnSaveChangesP2PProfile = (state, action) => {
  return updateObject(state, {
    p2pProfileHasDataToSave: action.val,
  });
};

const setSearchTearms = (state, action) => {
  return updateObject(state, {
    searchTearms: action.searchTearms,
  });
};

const searchPeersSuccess = (state, action) => {
  return updateObject(state, {
    peersResults: action.peersResults,
  });
};

const updatePeerResultsSearchedList = (state, action) => {
  let peersResults = state.peersResults;
  peersResults.searchedList = action.searchedList;
  return updateObject(state, {
    peersResults: peersResults,
  });
};

const resetPeerResults = state => {
  return updateObject(state, {
    peersResults: {},
  });
};

const updateSearchedKeyword = (state, action) => {
  return updateObject(state, {
    searchedKeyword: action.searchedKeyword,
  });
};

const setConnectionRequestMessage = (state, action) => {
  return updateObject(state, {
    connectionRequestMessage: action.connectionRequestMessage,
  });
};

const updateConnectionRequestCount = (state, action) => {
  return updateObject(state, {
    connectionRequestCount: action.connectionRequestCount,
  });
};

const updateChannels = (state, action) => {
  return updateObject(state, {
    channels: [...action.channels],
  });
};

const updateSingleChannel = (state, action) => {
  const {channelSid, data = {}} = action;
  const {channels, selectedChannel: channelOnTheRun} = state;
  const indexOfTheChannel = channels.findIndex(channel => channel.id === channelSid);
  let selectedChannel = {};
  if (indexOfTheChannel === -1 && data && data?.id) {
    selectedChannel = {...data};
    channels.push({...data});
  } else if (indexOfTheChannel !== -1) {
    selectedChannel = Object.assign({}, channels[indexOfTheChannel]);
    selectedChannel = {
      ...selectedChannel,
      ...data
    };
    channels[indexOfTheChannel] = selectedChannel;
  }
  const sortedChannels = channels.sort((a, b) =>
    moment(b.lastMessageTime).diff(moment(a.lastMessageTime)),
  )
  return updateObject(state, {
    channels: [...sortedChannels],
    selectedChannel: channelOnTheRun?.id === channelSid && selectedChannel.id ? {...selectedChannel} : {...channelOnTheRun},
  });
};

const removeSingleChannel = (state, action) => {
  const {channelSid} = action;
  const {channels, selectedChannel} = state;
  const filteredChannels = channels
    .filter(channel => channel && channel.id !== channelSid)
    .sort((a, b) =>
      moment(b.lastMessageTime).diff(moment(a.lastMessageTime)),
    );
  return updateObject(state, {
    channels: [...filteredChannels],
    selectedChannel: selectedChannel && selectedChannel.id !== channelSid ? { ...selectedChannel } : null,
  });
};

const setSelectedChannel = (state, action) => {
  return updateObject(state, {
    selectedChannel: action.selectedChannel,
  });
};

const resetPeerToPeer = (state, action) => {
  return updateObject(state, {
    activeTab: peerToPeerTabs.myNetwork,
    peersResults: {},
    profile: null,
    p2pProfileHasDataToSave: null,
    searchTearms: null,
    searchedKeyword: null,
    connectionRequestMessage: null,
    connectionRequestCount: 0,
    channels: [],
    selectedChannelId: null,
    newMessageCount: 0,
    unSeenConversations: {},
  });
};

const setNewMessageCount = (state, action) => {
  return updateObject(state, {
    newMessageCount: action.newMessageCount,
  });
};

const setUnSeenConversations = (state, action) => {
  return updateObject(state, {
    unSeenConversations: action.unSeenConvos,
  });
};

const setChatStatus = (state, action) => {
  return updateObject(state, {
    chatStatus: action.chatStatus,
  });
};

const updateResults = (state, action) => {
  return updateObject(state, {
    resultsUpdateTimeStamp: action.timeStamp,
  });
};

const updateShowHideStatusDetails = (state, action) => {
  return updateObject(state, {
    showHideStatusDetails: {...state.showHideStatusDetails, ...action.showHideStatusDetails},
  });
};

const updateCommiunityProfileDetails = (state, action) => {
  return updateObject(state, {
    commiunityDetails: action.commiunityDetails,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PEER_TO_PEER_ACTIVE_TAB:
      return setActiveTab(state, action);
    case actionTypes.UPDATE_PEER_2_PEER_PROFILE_SUCCESS:
      return updatePeer2PeerProfileSuccess(state, action);
    case actionTypes.UPDATE_PEER_2_PEER_PROFILE_FAIL:
      return updatePeer2PeerProfileFail(state, action);
    case actionTypes.UPDATE_USER_CHANGE_P2P_PROFILE:
      return OnSaveChangesP2PProfile(state, action);
    case actionTypes.SET_SEARCH_TEARMS:
      return setSearchTearms(state, action);
    case actionTypes.SEARCH_PEERS_SUCCESS:
      return searchPeersSuccess(state, action);
    case actionTypes.UPDATE_PEER_RESULTS_SEARCHED_LIST:
      return updatePeerResultsSearchedList(state, action);
    case actionTypes.RESET_PEER_RESULTS:
      return resetPeerResults(state, action);
    case actionTypes.UPDATE_SEARCHED_KEYWORD:
      return updateSearchedKeyword(state, action);
    case actionTypes.SET_CONNECTION_REQUEST_MESSAGE:
      return setConnectionRequestMessage(state, action);
    case actionTypes.UPDATE_CONNECTION_REQUEST_COUNT:
      return updateConnectionRequestCount(state, action);
    case actionTypes.UPDATE_CHANNELS:
      return updateChannels(state, action);
    case actionTypes.SELECTED_CHANNEL:
      return setSelectedChannel(state, action);
    case actionTypes.RESET_PEER_TO_PEER:
      return resetPeerToPeer(state, action);
    case actionTypes.UPDATE_NEW_MESSAGE_COUNT:
      return setNewMessageCount(state, action);
    case actionTypes.UPDATE_SINGLE_CHANNEL:
      return updateSingleChannel(state, action);
    case actionTypes.REMOVE_SINGLE_CHANNEL:
      return removeSingleChannel(state, action);
    case actionTypes.SET_UNSEEN_CONVERSATIONS:
      return setUnSeenConversations(state, action);
    case actionTypes.SET_CHAT_STATUS:
      return setChatStatus(state, action);
    case actionTypes.UPDATE_RESULTS:
      return updateResults(state, action);
    case actionTypes.UPDATE_VIEW_MORE_SHOW_HIDE_DETAILS:
      return updateShowHideStatusDetails(state, action);
    case actionTypes.UPDATE_COMMIUNITY_PROFILE_DETAILS:
      return updateCommiunityProfileDetails(state, action);
    default:
      return state;
  }
};

export default reducer;
