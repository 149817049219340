import React, {useEffect, useRef} from 'react';
import {View, Text} from 'react-native';
import QuestionTitle from '../../../../components/QuestionTitle/QuestionTitle';
import QuestionImage from '../QuestionImage';
import ScaleSlider from '../../../../components/HASlider/scaleSlider';
import stylesCss from './QuestionSliderStyles';
import styles from '../../../../Css';
import QuestionWarning from '../../../../components/QuestionTitle/QuestionWarning';

const QuestionSlider = ({
                          title,
                          questionIndex,
                          questionDescription,
                          isReadOnly,
                          answer,
                          minValue,
                          maxValue,
                          minScaleDescription,
                          maxScaleDescription,
                          imageUrl,
                          onChange,
                          onDisableScroll,
                          required,
                          requiredWarning,
                          onSkip,
                        }) => {
  const sliderReference = useRef(null);

  useEffect(() => {
    if (sliderReference.current && sliderReference.current.focusInput) {
      setTimeout(() => {
        if (sliderReference.current && sliderReference.current.focusInput) {
          sliderReference.current.focusInput();
        }
      }, 200);
    }
  }, []);

  return (
    <>
      {Boolean(imageUrl) && <View style={[stylesCss.questionImageWrapper]}>
        <QuestionImage imageUrl={imageUrl}/>
      </View>}
      <View style={[stylesCss.questionSliderWrapper]}>
        <QuestionTitle questionIndex={questionIndex} title={title} required={required} onSkip={onSkip}
                       isReadOnly={isReadOnly}/>
        <View>
          {!Boolean(questionDescription) &&
            <Text styles={styles.textPrime} allowFontScaling={false}>
              Please indicate on this scale how good or bad your own health state is today.
              The best health state you can imagine is marked {`${maxValue}`} and the worst health state
              you can imagine is marked {`${minValue}`}.
            </Text>
          }
          {Boolean(questionDescription) &&
            <Text styles={styles.textPrime} allowFontScaling={false}>
              {questionDescription}
            </Text>
          }
        </View>
        <View style={[stylesCss.questionSliderWrapper]}>
          <ScaleSlider
            ref={sliderReference}
            minValue={minValue}
            maxValue={maxValue}
            minScaleDescription={minScaleDescription}
            maxScaleDescription={maxScaleDescription}
            enabled={!isReadOnly}
            onUpdate={onChange}
            value={answer}
            disabledScroll={onDisableScroll}
          />
        </View>
        {/* Requird Field Warning Start */}
        {Boolean(requiredWarning) &&
          <QuestionWarning/>
        }
        {/* Requird Field Warning End */}
      </View>
    </>
  )
};

export default QuestionSlider;
