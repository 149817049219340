import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import ActivityRow from './ActivityRow';
import moment from 'moment';
import { chartTypes } from '../../../Constant/index';
import { getTypeOfMeasurementByUomId, verifyDuplicateMedication } from '../../../util/commonUiLogic';
import {itemTypes} from '../../../Constant/index';
class StepTwoActivity extends Component {
  constructor(props) {
    super(props);
    const {treatment} = props;
    const {id, dosages, defaultMetrics, prescribedBy, type} = treatment;
    let healthProviderName = "";
    if (prescribedBy && props.contactsProcessed[prescribedBy]) {
      const {givenName, organization} = props.contactsProcessed[prescribedBy];
      healthProviderName = givenName ? givenName : organization;
    }
    this.state = {
      ...treatment,
      dosages: {...dosages},
      defaultMetrics: defaultMetrics,
      visibleDetailView: id === -1 ? true : false,
      startDateClick: false,
      endDateClick: false,
      saveWait: false,
      validateName: true,
      contentVisibility: false,
      healthProviderName,
      type: type,
    };
  }

  componentDidMount = () => {
    const {treatment} = this.props;
    const {dosages, name, id} = treatment;
    if (id === -1) {
    this.verifyDuplicateMedication(
      name,
      dosages && dosages.units,
      dosages && dosages.quantity,
    );
    }
  };

  static getDerivedStateFromProps(props, state) {
    const {treatment: {defaultMetrics}} = props;
    if (JSON.stringify(defaultMetrics) !== JSON.stringify(state.defaultMetrics)) {
      return {
        defaultMetrics: defaultMetrics ? defaultMetrics : [],
      }
    }
    return null;
  }

  handleShowDetailView = () => {
    this.setState(prevState => ({
      visibleDetailView: !prevState.visibleDetailView,
    }));
  };

  onChangeName = val => {
    const {treatments, index} = this.props;
    let tempTreatments = [];
    if (treatments) {
      tempTreatments = [...treatments];
      let item = tempTreatments[index];

      item.name = val;
      item.validateName = true;
      this.setState({...item});
      this.props.updateTreatment(tempTreatments, () => {});
    }
  };

  onUnitValueChange = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.dosages.units = val;
      this.setState({dosages: {...item.dosages}});
    }

    this.props.updateTreatment(tempTreatments, () => {
      const {name, dosages, id} = this.state;
      const {quantity} = dosages;
      if (id === -1) this.verifyDuplicateMedication(name, val, quantity);
    });
  };

  onNameValueChange = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.name = val;
      this.setState({name : val});
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {
      const {dosages, id} = this.state;
      const {quantity, units} = dosages;
      if (id === -1) this.verifyDuplicateMedication(val, units, quantity);
    });
  };

  updateQuantity = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.dosages.quantity = val;
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {
      const {name, dosages, id} = this.state;
      const {units} = dosages;
      if (id === -1) this.verifyDuplicateMedication(name, units, val);
    });
  };

  updateFrequency = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.dosages.frequency = val;
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {});
  };

  updateFrequencyType = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.dosages.frequencyType = val;
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {});
  };

  handleOnPressCollapsible = val => {
    this.setState({contentVisibility: !val});
  };

  updateInstructions = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.dosages.instructions = val;
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {});
  };

  updateHelethData = defaultMetrics => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.defaultMetrics = defaultMetrics;
    }
    this.props.updateTreatment(tempTreatments, () => {});
  };

  updatePrescribedBy = (id, name) => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.prescribedBy = id;
      item.healthProviderName = name;
      this.setState({...item});
    }
    this.props.updateTreatment(tempTreatments, () => {});
  };
  updateStartDate = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.dosages.start = val;
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {});
  };
  updateEndDate = val => {
    let tempTreatments = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      let item = tempTreatments[this.props.index];

      item.dosages.end = val;
      this.setState({dosages: {...item.dosages}});
    }
    this.props.updateTreatment(tempTreatments, () => {});
  };

  onPressUpdateTherapie = () => {
    const tempTreatments = [...this.props.treatments];
    let treatment = tempTreatments[this.props.index];
    const {dosages, defaultMetrics} = treatment;

    const {start, end} = dosages;
    const addNewTherapie = {
      treatment: {
        ...treatment,
        defaultMetrics: defaultMetrics,
        dosages: [
          {
            ...dosages,
            start: start ? moment.utc(start).valueOf() : null,
            end: end ? moment.utc(end).valueOf() : null,
          },
        ],
        displayProperties: {
          id: null,
          createdOn: null,
          modifiedOn: null,
          color: null,
          artifactId: null,
          dashedLine: true,
        },
      },
    };

    if (addNewTherapie.treatment.defaultMetrics.length > 0) {
      let haveErrors = false;
      let defMetrics = Object.assign([], addNewTherapie.treatment.defaultMetrics);
      addNewTherapie.treatment.defaultMetrics.forEach((item, index) => {
        if (!item.unit) {
          haveErrors = true;
          defMetrics[index].error = true;
        }
      });
      if (haveErrors) {
        this.updateHelethData(defMetrics);
        return;
      }
    }
    this.setState({saveWait: true});
    if (addNewTherapie.treatment.defaultMetrics.length > 0) {
      if (
        !addNewTherapie.treatment.prescribedBy &&
        addNewTherapie.treatment.healthProviderName
      ) {
        this.UpdateTherapieWithHelethData(
          addNewTherapie,
          addNewTherapie.treatment.healthProviderName,
        );
      } else {
        this.UpdateTherapieWithHelethData(addNewTherapie, null);
      }
    } else {
      if (
        !addNewTherapie.treatment.prescribedBy &&
        addNewTherapie.treatment.healthProviderName
      ) {
        this.addContactAndUpdateTherapie(
          addNewTherapie,
          addNewTherapie.treatment.healthProviderName,
        );
      } else {
        this.updateTherapie(addNewTherapie);
      }
    }
  };

  UpdateTherapieWithHelethData = (
    addNewTherapie,
    healthProviderName,
  ) => {
    const {unitsOfMeasureDigestProcessed} = this.props;
    const {unitsOfMeasure} = unitsOfMeasureDigestProcessed;

    const defaultMetrics = [];
    const newMetrix = [];
    addNewTherapie.treatment.defaultMetrics.forEach((res, index) => {
      if (res.metric) {
        defaultMetrics.push(res);
      } else if (res.data.type === 'USER') {
        defaultMetrics.push({
          unit: res.unit,
          metric: res.data.id,
          id: -(index + 1),
        });
      } else {
        let uomId = null;
        if (res.unit) {
          uomId = res.unit;
        }

        const typeOfMeasurement = getTypeOfMeasurementByUomId(unitsOfMeasure, uomId);
        newMetrix.push({
          metric: res.data,
          isActive: true,
          id: -(index + 1),
          name: res.data.name,
          createdOn: null,
          modifiedOn: null,
          artifactId: null,
          description: res.data.description ? res.data.description : null,
          typeOfMeasurement: typeOfMeasurement,
          metricGroup: res.data.metricGroup ? res.data.metricGroup : 1,
          displayProperties: {
            color: 'rgba(113, 168, 220, 1)',
            style: chartTypes.dash,
            scaleMin: null,
            scaleMax: null,
          },
          uomId: uomId,
        });
      }
    });

    if (newMetrix.length > 0) {
      let count = 0;
      newMetrix.forEach((obj, index) => {
        this.props.updateUserMetric(obj, (success, data) => {
          if (success) {
            defaultMetrics.push({
              id: obj.id,
              unit: obj.uomId,
              metric: data.id,
            });
            count = count + 1;
            if (obj.uomId) {
              const metricDefaultUnitOfMeasure = {
                id: -(index + 1),
                createdOn: null,
                modifiedOn: null,
                metricId: data.id,
                uomId: obj.uomId,
              };
              this.props.updateUserMetricDisplayUom(metricDefaultUnitOfMeasure);
            }
          }
          if (count === newMetrix.length) {
            addNewTherapie.treatment.defaultMetrics = defaultMetrics;
            if (healthProviderName) {
              this.addContactAndUpdateTherapie(
                addNewTherapie,
                healthProviderName,
              );
            } else {
              this.updateTherapie(addNewTherapie);
            }
          }
        });
      });
    } else {
      addNewTherapie.treatment.defaultMetrics = defaultMetrics;
      if (healthProviderName) {
        this.addContactAndUpdateTherapie(
          addNewTherapie,
          healthProviderName,
        );
      } else {
        this.updateTherapie(addNewTherapie);
      }
    }
  };

  // getTypeOfMeasurement = uomId => {
  //   const {unitsOfMeasureDigestProcessed} = this.props;
  //   const {unitsOfMeasure} = unitsOfMeasureDigestProcessed;
  //   if (unitsOfMeasure && unitsOfMeasure[uomId]) {
  //     return unitsOfMeasure[uomId].typeOfMeasurement;
  //   }

  //   return null;
  // };

  addContactAndUpdateTherapie = (
    addNewTherapie,
    healthProviderName,
  ) => {
    let addNewContact = {
      addNewContact: {
        contact: {
          id: -1,
          givenName: healthProviderName,
          isCareTeam: true,
        },
      },
    };
    this.props.updateContact(
      addNewContact,
      this.props.contacts,
      (success, contactData) => {
        if (success) {
          addNewTherapie.treatment.prescribedBy = contactData.id;
        }

        this.updateTherapie(addNewTherapie);
      },
      this.props.journalUserId,
      false, //stop calling fetch attachment api
    );
  };

  updateTherapie = (addNewTherapie) => {
    this.props.updateTherapie(
      addNewTherapie,
      (success, data) => {
        this.setState({saveWait: false, visibleDetailView: false});
        if (success) {
          const obj = {
            ...data,
            dosages: {
              ...data.dosages[0],
            },
            healthProviderName: this.state.healthProviderName,
            isConfirm: false,
          };
          const temp = [...this.props.treatments];
          temp[this.props.index] = obj;
          this.props.updateTreatment(temp, () => {});
        } else {
        }
      },
      this.props.journalUserId,
    );
  };

  verifyDuplicateMedication = (name, unit, quantity) => {
    const {listTherapie, routineWizardSaveDisable} = this.props;
    
    verifyDuplicateMedication(name, null, unit, quantity, listTherapie, (exist, msg) => {
      if (exist) {
        this.setState({
          duplicatePopup: true,
          saveDisable: true,
          errorDescription: msg,
        }, () => {
          this.updateDuplicateExistOrNot(true);
          routineWizardSaveDisable(true, itemTypes.treatment)
        });
      } else {
        this.setState({
          duplicatePopup: false,
          saveDisable: name?.trim() === '' ? true : false,
          errorDescription: null,
        }, () => {
          this.updateDuplicateExistOrNot(false);
        });
      }
    });
  };

  updateDuplicateExistOrNot = (val) => {
    const {routineWizardSaveDisable, updateTreatment, treatments, index} = this.props;

    let tempTreatments = [];
    if (treatments) {
      tempTreatments = [...treatments];
      let item = tempTreatments[index];

      item.duplicate = val;
    }
    updateTreatment(tempTreatments, () => {
      let invalidName = treatments.some(treatment => {
        return treatment.id == -1 && (treatment?.name?.trim() === '' || treatment?.duplicate)
      })
      routineWizardSaveDisable(invalidName, itemTypes.treatment);
    });
  }

  render() {
    const {
      name,
      validateName,
      visibleDetailView,
      dosages,
      contentVisibility,
      defaultMetrics,
      prescribedBy,
      healthProviderName,
      saveWait,
      duplicatePopup,
      errorDescription,
      saveDisable,
      type,
    } = this.state;
    const {
      units,
      quantity,
      frequency,
      frequencyType,
      instructions,
      start,
      end,
    } = dosages;

    return (
      <ActivityRow
        visibleDetailView={visibleDetailView}
        name={name}
        validateName={validateName}
        onChangeName={this.onChangeName}
        unit={units}
        onUnitValueChange={this.onUnitValueChange}
        quantity={quantity}
        updateQuantity={this.updateQuantity}
        frequency={frequency}
        frequencyType={frequencyType}
        updateFrequency={this.updateFrequency}
        updateFrequencyType={this.updateFrequencyType}
        contentVisibility={contentVisibility}
        instructions={instructions}
        updateInstructions={this.updateInstructions}
        defaultMetrics={defaultMetrics}
        updateHelethData={this.updateHelethData}
        prescribedBy={prescribedBy}
        healthProviderName={healthProviderName}
        updatePrescribedBy={this.updatePrescribedBy}
        startDate={start}
        endDate={end}
        updateStartDate={this.updateStartDate}
        updateEndDate={this.updateEndDate}
        handleOnPressCollapsible={this.handleOnPressCollapsible}
        index={this.props.index}
        handleOnDeleteTreatment={this.props.handleOnDeleteTreatment}
        key={this.props.key}
        showDetail={this.handleShowDetailView}
        update={this.onPressUpdateTherapie}
        saveWait={saveWait}
        duplicatePopup={duplicatePopup}
        errorDescription={errorDescription}
        saveDisable={saveDisable}
        clearErrors={this.props.clearErrors}
        error={this.props.error}
        onNameValueChange={this.onNameValueChange}
        type={type}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    treatments: state.setup.treatments,
    contacts: state.routines.contacts,
    contactsProcessed: state.routines.contactsProcessed,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    journalUserId: state.uistate.journalUserId,
    listTherapie: state.routines.treatments,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateTreatment: (addNewTreatment, callBack) =>
      dispatch(actions.updateTreatment(addNewTreatment, callBack)),
    updateTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.updateTherapie(addNewTherapie, callBack, journalUserId)),
    updateContact: (addNewContact, contacts, callBack, journalUserId, fetchAttachments) =>
      dispatch(actions.updateContact(addNewContact, contacts, callBack, journalUserId, fetchAttachments)),

    displayProperties: (displayPrope, journalUserId) =>
      dispatch(
        actions.updateGraphDisplayProperties(displayPrope, journalUserId),
      ),

    updateUserMetric: (metric, callBack) =>
      dispatch(actions.updateUserMetric(metric, callBack)),
    updateUserMetricDisplayUom: metricDefaultUnitOfMeasure =>
      dispatch(actions.updateUserMetricDisplayUom(metricDefaultUnitOfMeasure)),
    onDeleteTherapie: (id, callBack, journalUserId) =>
      dispatch(actions.deleteTherapie(id, callBack, journalUserId)),
    routineWizardSaveDisable: (flag, isFrom ) => dispatch(actions.updateRoutineWizardSaveDisable(flag, isFrom))
    };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepTwoActivity);
