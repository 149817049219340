import React, {Component} from 'react';
import {View, ScrollView, Text, Image} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Header from '../../components/Header/header';
import {connect} from 'react-redux';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from '../../components/Profile/CheckoutForm';
import * as axiosCommon from '../../axios/axios-common';
import {SUBSCRIPTION_PLANS} from '../../store/actions/urls';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import * as actions from '../../store/actions/index';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import {DASHBOARD, PROFILE} from '../../navigation/path';
import PageContainer from '../../components/PageLayout/PageContainer';
import { pendoTrackEvent } from '../../util/pendoConfig';
class UpgradePremiumScreen extends Component {
  constructor(props) {
    super(props);
    if (props.journalUserId) {
      props.navigation.navigate(DASHBOARD.path);
    }
    this.getSubscriptionPlans();
    this.state = {
      firstName: (props.user || {}).firstName,
      lastName: (props.user || {}).lastName,
      subscriptionPlans: {},
    };
    this.scrollViewRef = null;
  }

  componentDidMount() {
    this.getSubscriptionPlans();
    pendoTrackEvent('Premium/@BillingInfo');
  }

  getSubscriptionPlans = () => {
    axiosCommon.axiosGET(SUBSCRIPTION_PLANS, res => {
      if (res.type === 'SUCCESS') {
        const obj = {};
        res.responseDto.data.plans.forEach(item => {
          obj[item.interval] = item;
        });
        this.setState({subscriptionPlans: obj});
      } else if (res.type === 'ERROR' && res.errorDto === 'SESSION_TIME_OUT') {
        console.log("SESSION_TIME_OUT, logging out; UpgradePremiumScreen.web::sendSubscriptionDetails");
        this.props.logout();
      }
    });
  };

  subscriptionSuccess = () => {
    this.props.resetDataLogout();
    this.props.navigation.navigate(DASHBOARD.path);
  };

  handleScrolling = (scrollToTop = false) => {
    if (this.scrollViewRef) {
      setTimeout(() => {
        scrollToTop
          ? this.scrollViewRef?.scrollTo({ x: 0, y: 300, animated: true })
          : this.scrollViewRef?.scrollTo({ x: 0, y: 500, animated: true })
      }, 500);
    }
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <StripeProvider
        apiKey={this.props.applicationSettingsDigest.stripePublishableKey}>
        <View style={[styles.pageBodyWrapper]}>
          <Header
            showFilers={false}
            showBack={true}
            showClearFilters={false}
            showNotify={true}
            filterClickHandler={this.handleOnPressFilter}
            clearFiltersHandler={this.handleOnPressClearFilters}
            handleOnPressBackButton={() => {
              if(this.props.navigation.canGoBack()) {
                this.props.navigation.goBack();
              } else {
                this.props.navigation.reset({
                  index: 0,
                  routes: [{ name: PROFILE.profilePremiumAccountPath }],
                });
              }
            }}
            index={PROFILE.index}
            navigation={this.props.navigation}
            route={this.props.route}
          />
          <SpaceBar />
          <ScrollView 
          style={styles.scrollViewArea}
          ref={ref => (this.scrollViewRef = ref)}
          >
            <View style={styles.gridSection}>
              <View
                style={[
                  stylesRes.contentSection,
                  stylesRes.contBotPd,
                  stylesRes.layoutTopPdFixed,
                ]}
                dataSet={{media: ids.contBotPd}}>
                <PageContainer>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={styles.secArea}>
                        <View style={stylesRes.maxComSec} dataSet={{media: ids.maxComSec}}>
                          <View
                            style={[
                              styles.comSecRow,
                              styles.flexRow,
                              styles.flexAiCt,
                            ]}>
                            <View
                              style={[styles.comColHalf, styles.flexJcFeAiFe]}>
                              <View style={stylesRes.comImgSecPrem}>
                                <Image
                                  style={[styles.imgCover]}
                                  source={require('../../assets/premium/premium_smilingstars.png')}
                                />
                              </View>
                            </View>
                            <View style={styles.comColHalf}>
                              <View
                                style={[
                                  styles.comTextSec,
                                  styles.comPdLt,
                                  styles.spSmTopBot,
                                ]}>
                                <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                                  {this.state.firstName} {this.state.lastName}!
                                </Text>
                                <Text style={[styles.textPrime]} allowFontScaling={false}>
                                  You can do so much more with the premium
                                  version.
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </PageContainer>

                {Object.values(this.state.subscriptionPlans).length > 0 ? (
                  <Elements>
                    <CheckoutForm
                      subscriptionPlans={this.state.subscriptionPlans}
                      user={this.props.user}
                      subscriptionSuccess={this.subscriptionSuccess}
                      commonErrorHandler={this.props.commonErrorHandler}
                      handleScrolling={this.handleScrolling}
                    />
                  </Elements>
                ) : (
                  <ActivityIndicator />
                )}
              </View>
            </View>
          </ScrollView>
        </View>
      </StripeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.routines.user,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    journalUserId: state.uistate.journalUserId,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    resetDataLogout: () => dispatch(actions.resetDataLogout()),
    logout: () => dispatch(actions.logout()),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpgradePremiumScreen);
