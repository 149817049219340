export const cssDefault = {
  flexRow: {
    flexDirection: "row",
  },
  flexRowRev: {
    flexDirection: "row-reverse",
  },
  flexCol: {
    flexDirection: "column",
  },
  flecColRev: {
    flexDirection: "column-reverse",
  },
  flexJcSb: {
    justifyContent: "space-between",
  },
  flexJcFs: {
    justifyContent: "flex-start",
  },
  flexJcFe: {
    justifyContent: "flex-end",
  },
  flexJcCt: {
    justifyContent: "center",
  },
  flexAiFe: {
    alignItems: "flex-end",
  },
  flexAiCt: {
    alignItems: "center",
  },
  flexAiFs: {
    alignItems: "flex-start",
  },
  flexJcCtAiCt: {
    justifyContent: "center",
    alignItems: "center",
  },
  flexJcFsAiCt: {
    justifyContent: "flex-start",
    alignItems: "center",
  },
  flexJcFsAiFs: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  flexCom: {
    flex: 1,
  },
  flexRowJcCtAiCt: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  flexRowAiCt: {
    alignItems: "center",
    flexDirection: "row",
  },
  flexRowAliSt:{
    alignItems: "flex-start",
    flexDirection: "row",
  },
  flexRowJcFsAiFs: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "row",
  },
  flexJcFeAiFe: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  postRelative: {
    position: "relative",
  },
  centerSecion: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  imgFull: {
    position: "absolute",
    left: 0,
    right: 0,
    width: "100%",
  },
  imgCover: {
    width: "100%",
    resizeMode: "cover",
    flex: 1,
  },
  imgContain: {
    width: "100%",
    resizeMode: "contain",
    flex: 1,
  },
  flexAsFe: {
    alignSelf: "flex-end",
  },
  flexRowWrap: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  flexWrapView: {
    flexWrap: "wrap",
  },
  flexAsCt: {
    alignSelf: "center",
  },
  flexAsfs: {
    alignSelf: "flex-start",
  },
  flexJcCtAiFs: {
    justifyContent: "center",
    alignItems: "flex-start",
  },
  flexJcCtAiFe: {
    justifyContent: "center",
    alignItems: "flex-end",
  },
  centrTxtAlgn: {
    textAlign: "center"
  },
  hudredPercentWd: {
    width: "100%"
  },
  flexEndCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  flexBetweenCenter:{
  
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    
  },
  flexBestweenStart:{
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  }
};
