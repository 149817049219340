import React, {Component} from 'react';
import {Image, View, TouchableOpacity, Keyboard, TextInput} from 'react-native';
import styles from '../../../Css';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import * as FieldDetails from '../../TextInput/fieldDetails';
import AutoCompleteSuggestion from '../../AutoComplete/AutoCompleteSuggestion';
import {autoSuggestionListType} from '../../../Constant/index';
import WarningInline from '../../../components/Warning/warningInline';
import {zIndexWorkaround, getMeasurementUnits} from '../../../util/commonUiLogic';
class StepFourMetric extends Component {
  constructor(props) {
    super(props);
    const selectedOne = props.units.filter(item => item.id === props.metric?.uomId)?.[0];
    this.state = {
      ...props.metric,
      // uomId:
      //   this.props.units && this.props.units.length > 0
      //     ? props.metric.typeOfMeasurement
      //       ? this.props.units[0].id
      //       : 5
      //     : null,
      //unitList: this.getMeasurementUnits(),
      // uomId: this.getItem(props.metric)
      //   ? this.getItem(props.metric)
      //   : this.props.units && this.props.units.length > 0
      //   ? props.metric.typeOfMeasurement
      //     ? this.props.units[0].id
      //     : 5
      uomId: props.metric?.uomId ? props.metric.uomId : props.units?.length > 0 ? props.units[0].id : null,
      selectedUnitName: props.metric?.uomId && selectedOne ? `${selectedOne.symbol}` : props.units?.length > 0 ? props.units[0].symbol : "",
      unitName: props.metric?.uomId && selectedOne ? `${selectedOne.symbol}` : props.units?.length > 0 ? props.units[0].symbol : "",
      name: props.metric?.name,
      error: false,
    };
    if (!props.metric.uomId) {
      const metrics = [...props.metrics];
      metrics[props.index] = this.state;
      this.props.updateMetric(metrics, () => {});
    }
    this.uomRef = null;
  }

  getItem = (item, index) => {
    const {
      metricsDigest,
      unitsOfMeasureDigestProcessed,
    } = this.props;
    const {id, typeOfMeasurement} = item;

    let unitName;

    const {unitsOfMeasure} = unitsOfMeasureDigestProcessed;

    if (typeOfMeasurement && unitsOfMeasure[typeOfMeasurement]) {
      unitName = unitsOfMeasure[typeOfMeasurement].symbol;
    } else {
      const {
        userDefinedMetricUnitOfMeasures,
        systemDefinedMetricUnitOfMeasures,
      } = metricsDigest;
      let found = userDefinedMetricUnitOfMeasures.find(
        element => element.metricId === id,
      );
      if (!found) {
        found = systemDefinedMetricUnitOfMeasures.find(
          element => element.metricId === id,
        );
      }
      const {unitsOfMeasure} = unitsOfMeasureDigestProcessed;

      if (found && unitsOfMeasure[found.uomId]) {
        unitName = unitsOfMeasure[found.uomId].id;
      }
    }
    return unitName;
  };

  UNSAFE_componentWillReceiveProps(props) {
    const selectedOne = props.units.filter(item => item.id === props.metric?.uomId)?.[0];
    this.setState({
      ...props.metric,
      uomId: props.metric?.uomId ? props.metric.uomId : null,
      selectedUnitName: props.metric?.uomId && selectedOne ? `${selectedOne.symbol}` : this.state.unitName,
      unitName: props.metric?.uomId && selectedOne ? `${selectedOne.symbol}` : this.state.unitName,
    });
    if (
      // !props.metric.uomId ||
      (props.metric.uomId != this.state.uomId &&
        props.metric.id === this.state.id)
    ) {
      const metrics = [...this.props.metrics];
      const metric = metrics[this.props.index];
      metric.uomId = props.metric?.uomId
        ? props.metric.uomId
        : null;
      metrics[this.props.index] = metric;
      this.props.updateMetric(metrics, () => {});
    }
   
  }

  // static getDerivedStateFromProps(props, state) {
  //   const {
  //     onTouchRoutineFlag,
  //     index,
  //     onTouchRoutine,
  //     metricsCount,
  //   } = props;
  //   const {unitName, selectedUnitName, uomId} = state;
  //   if (metricsCount && metricsCount === 0) {
  //     onTouchRoutine(false);
  //   } else if(metricsCount && metricsCount > 0) {
  //     if (index === metricsCount - 1) {
  //       onTouchRoutine(false);
  //     }
  //   }
  //   if (onTouchRoutineFlag && !unitName) {
  //     return {
  //       unitName: selectedUnitName,
  //       error: false,
  //       uomId: uomId,
  //     };
  //   }

  //   return null;
  // }

  // TODO: ZI-883 remove old codes
  // getMeasurementUnits = () => {
  //   let units = [];
  //   this.props.units &&
  //     this.props.units.map(res =>
  //       units.push({
  //         label: res.symbol,
  //         value: res.id,
  //         key: 'mr_' + res.id,
  //       }),
  //     );
  //   return units;
  // };

// TODO: ZI-883 remove old codes
  // setUnit = (itemValue) => {
  //   this.setState({uomId: itemValue}, () => {
  //     this.props.handleOnSetUnit(
  //       this.props.index,
  //       this.state.uomId
  //     );
  //   });   
  // };

  setUnit = (itemValue) => {
    Keyboard.dismiss();
    this.setState({
      uomId: itemValue.data.id,
      selectedUnitName: itemValue.data.symbol,
      unitName: itemValue.data.symbol
    }, () => {
      this.props.handleOnSetUnit(
        this.props.index,
        this.state.uomId
      );
      this.props.resetErrors(this.props.index, itemValue.data.symbol);
    });   
  };

  onChangeTextName = currentInput => {
    this.setState(prevState => ({
      unitName: currentInput,
      //uomId: !Boolean(currentInput) ? null : prevState.uomId,
    }), () => {
      //this.props.handleOnSetUnit(this.props.index, null);
    });
  }

  onChangeName = currentInput => {
    this.setState(prevState => ({
      name: currentInput,
    }), () => {
      this.props.handleOnSetName(this.props.index, currentInput)
    });
  }

  updateTermList = () => {
    this.setState({uomId: null, selectedUnitName: null})
  }

  render() {
    const {index, metricsCount, units, type} = this.props;
    const {uomId} = this.state;
    let isMetricNameInvalid = type === 'custom' && (this.state.name === null || this.state.name === undefined || this.state.name === '');
    return (
      // <View
      //   style={[styles.addedItemRow, styles.itemViewSp,zIndexWorkaround(index, metricsCount)]}
      //   key={this.props.key}>
      //   <View style={[styles.addedItemView, styles.addedItemBox, zIndexWorkaround(index, metricsCount)]}>
      //     <View style={[styles.flexCom, styles.flexRow, styles.flexAiCt, zIndexWorkaround(index, metricsCount)]}>

      //NEWLY ADDED 
      <View style={[styles.fieldRow, zIndexWorkaround(index, metricsCount)]} key={this.props.key}>
        <View style={[styles.routineBorderTp, isMetricNameInvalid && styles.inValidColor]}/>
          <View style={[styles.stepFourMetricWrapper]}>
            <View style={[styles.flexCom, styles.flexRow, styles.flexAiCt, zIndexWorkaround(index, metricsCount)]}>

              <View style={[styles.addedItemCont, styles.flexCom, styles.routineBorderLt, isMetricNameInvalid && styles.inValidColor]}>
                <TextInput style={[styles.textPrime, styles.routingMetricNmMg]} allowFontScaling={false} onChangeText={this.onChangeName} editable ={type === 'custom' ? true : false} value={this.state.name}></TextInput>
              </View>

                <View style={[styles.stepfourmetricSz, styles.routineListPickerMg, zIndexWorkaround(index, metricsCount)]}>
                  {/* // TODO: ZI-883 remove old codes
                  <PickerTemplate invalid={false} pickerWithTitle={false}> */}
                    {/* <Picker
                      items={this.getMeasurementUnits()}
                      style={[styles.pickerAreaField, styles.textPickerPdExMiniCom]}
                      selectedValue={this.state.uomId}
                      onValueChange={itemValue => this.setUnit(itemValue)
                        // this.setState({uomId: itemValue}, () => {
                        //   const metrics = [...this.props.metrics];
                        //   const metric = metrics[this.props.index];
                        //   metric.uomId = this.state.uomId;
                        //   metrics[this.props.index] = metric;
                        //   this.props.updateMetric(metrics, () => {});
                        // })
                      }
                    /> */}
                    <AutoCompleteSuggestion
                      textFieldWithTitle={true}
                      textFieldTitle="Unit of Measure"
                      fieldBg={FieldDetails.BGWHITE}
                      remortSearch={false}
                      //triggerLength={1}
                      onChangeText={this.onChangeTextName}
                      onItemPress={this.setUnit}
                      currentInput={this.state.unitName}
                      autoFocus={false}
                      userTerms={getMeasurementUnits(units, uomId)}
                      zoomIcon={true}
                      removeIcon={true}
                      autoDisplayUserList={true}
                      showCustomSection={false}
                      showOtherResults
                      type={autoSuggestionListType.health_data_uom}
                      //showCloseAfterPressItem={Boolean(this.state.unitName)}
                      removeSelectedIcon
                      validateName={this.state.error}
                      placeholder="Search..."
                      typeAhead={this.props.measurementTypes}
                      getRef={ref => (this.uomRef = ref)}
                      selectedValue={uomId}
                      onBlur={() => {
                        this.setState(prevState => ({
                          unitName: prevState.selectedUnitName,
                          error: false,
                          uomId: prevState.uomId,
                        }));
                      }}
                      zIndex={99999 + metricsCount - index}
                      updateTermList = {this.updateTermList}
                    />
                  {/* </PickerTemplate> */}
                </View>

                  <View style={[styles.listItemRemove, styles.routineBorderRt, isMetricNameInvalid && styles.inValidColor]}>
                    <TouchableOpacity
                      onPress={() => {
                        this.setState({unitName: ""}, () => {
                          if (this.uomRef) {
                            this.uomRef.turnOffList();
                          }
                          this.props.handleOnDeleteMetric(index)
                        })
                      }}
                      style={styles.closeTouchPd}>
                      <Image
                        style={[styles.closeDarkIcon,styles.routingmgCloseIcn]}
                        source={require('../../../assets/icons/close-dark.png')}
                      />
                    </TouchableOpacity>
                  </View>
            </View>
          </View>
        <View style={[styles.routineBorderBtm, isMetricNameInvalid && styles.inValidColor]}/>
        {isMetricNameInvalid ?
          <WarningInline
            errorDescription={'Name can not be empty.'}
            textPosition={'right'} 
          />
          : 
          null 
        }
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    metrics: state.setup.metrics,
    unitsOfMeasureDigest: state.routines.unitsOfMeasureDigest,
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    metricsDigest: state.routines.metricsDigest,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    measurementTypes: state.routines.unitsOfMeasureDigest.measurementTypes,
    onTouchRoutineFlag: state.routines.onTouchRoutineFlag,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateMetric: (addNewMetric, callBack) =>
      dispatch(actions.updateMetric(addNewMetric, callBack)),
    onTouchRoutine: flag =>
      dispatch(actions.onTouchRoutine(flag)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepFourMetric);
