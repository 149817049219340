import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from '../../Css';

const biologicalSexitem = props => {
  const {isSelected, onSelect, label} = props;
  return (
    <View style={[styles.answerSingleSm]}>
      <TouchableOpacity
        style={[styles.answerOptMgBot, styles.answerViewWrapper]}
        onPress={onSelect}>
        <View style={[styles.answerView]}>
          <Image
            style={[styles.radioIcon, styles.radioOptSpSm]}
            source={
              isSelected
                ? require('../../assets/icons/option-selected.png')
                : require('../../assets/icons/radio-default.png')
            }
          />
          <Text style={[styles.textPrime]} allowFontScaling={false}>{label}</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (biologicalSexitem);
