import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import TickButton from "../CheckBox/CheckBoxNew";

import * as actions from "../../store/actions/index";

class ReportMetric extends Component {
  constructor(props) {
    super(props);
    const {metrics, metricProps, configChartType, savedMetricProps, dashboardProcessed, graphId} = this.props;  
    let defaultChartType = configChartType;
    if (dashboardProcessed.hasOwnProperty(graphId) && dashboardProcessed[graphId].metricsProperties) {
      const propsArray = JSON.parse(dashboardProcessed[graphId].metricsProperties);
      Object.values(propsArray).forEach(element => {
        if (element.id == props.item.id) {
          defaultChartType = element.style;
        }
      });
    }
    
    this.state = {
      ...props.item,
      displayProperties: { ...props.item.displayProperties },
      tick: metrics && metrics[props.item.id] ? true : false,
      chartType: metricProps && metricProps[props.item.id] && metricProps[props.item.id].style 
                  ? metricProps[props.item.id].style
                  : savedMetricProps && savedMetricProps[props.item.id] && savedMetricProps[props.item.id].style 
                      ? savedMetricProps[props.item.id].style
                          : dashboardProcessed.hasOwnProperty(graphId)
                              ? defaultChartType
                              : configChartType,
    };
  }
  
  UNSAFE_componentWillReceiveProps(props) {
    if (props.metrics && props.metrics[this.state.id]) {
      this.setState({ tick: true });
    } else {
      this.setState({ tick: false });
    }
  }

  handleOnPressTick = (tick, id) => {
    if (tick) {
      this.props.addMetric(id);
      this.props.updateMetricProps(id, this.state.chartType);
    } else {
      this.props.removeMetric(id);
    }
  };
  shouldComponentUpdate(nextProps, nextState) {
    return nextState.tick !== this.state.tick;
  }
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const colorItem = (
      <View
        key={this.props.key}
        style={stylesRes.colorBox}
        dataSet={{media: ids.colorBox}}
      >
        <View
          style={[
            stylesRes.colorItem,
            {
              backgroundColor: this.state.displayProperties?.color
                ? this.state.displayProperties?.color
                : "white"
            }
          ]}
          data-item={ids.colorItem}
        >
          <View style={[styles.colorSection]} />
        </View>
      </View>
    );

    return (
      <TouchableOpacity 
        onPress={() => 
          this.handleOnPressTick(!this.state.tick, this.state.id)
        }
      >
        <View style={[styles.ViewRow, styles.greyBorderThin]}>
          <View style={styles.viewDetailSec}>
            <View style={styles.viewMainData}>
              <View style={[styles.viewItemDesc, styles.colPdLtTopBotRg]}>
                <View style={[styles.viewColor, styles.infoSp]}>{colorItem}</View>

                <View style={[styles.viewDataFull]}>
                  <Text style={styles.textPrime} allowFontScaling={false}>{this.state.name}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.dataIconSecRg, styles.colPdRtTopBotRg]}>
            <View style={styles.iconListSec}>
              <View style={[styles.checkboxSec]}>
                <TickButton
                  ticked={this.state.tick}
                  toggleTickCallback={tick =>
                    this.handleOnPressTick(tick, this.state.id)
                  }
                />
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = state => {
  return {
    metrics: state.report.graph.metrics,
    metricProps: state.report.graph.metricProps,
    savedMetricProps: state.report.savedGraph.metricProps,
    dashboardProcessed: state.routines.dashboardProcessed,
    graphId: state.report.graph.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addMetric: metric => dispatch(actions.addReportGrapMetric(metric)),
    removeMetric: metric => dispatch(actions.removeReportGrapMetric(metric)),
    updateMetricProps: (metricId, chartType) => 
      dispatch(actions.updateReportGraphMetricProps(metricId, chartType))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportMetric);
