import React, { Component } from "react";
import { View, TouchableOpacity, Image } from "react-native";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";
import { connect } from "react-redux";
import ContactCamera from "../../../modules/webCam/webCam";
import ImageCropper from "../../../modules/imageCropper/attachmentImageCropper";
import * as actions from "../../../store/actions/index";
import { TAB_BREAk_POINT_START } from "../../../util/platformWindowConfig";

class ImageTakephoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      imageUrl: null,
      artifactId: this.props.artifactId,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { windowHeight, windowWidth } = nextProps;

    this.setState({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    });
  }

  onimageSrc = (imageUrl) => {
    this.setState({
      imageUrl: imageUrl,
      retake: false,
      message: "Adjust to crop image",
    });
  };

  onCropImage = (cropImageUrl, image64) => {
    if (!this.props.artifactId) {
      this.props.onCropAddImage(cropImageUrl, image64);
    } else {
      if (cropImageUrl) {
        this.setCreateSpinner();
        const formData = new FormData();

        let timestamp = `${new Date().getTime()}.png`;

        formData.append("files[0]", cropImageUrl, timestamp);

        this.props.onUpload(
          this.state.artifactId,
          formData,
          (success, attachments) => {
            if (success && attachments) {

            } else {
              this.props.uploadError();
            }
            this.setCreateSpinner();

            this.props.cropSuccess();
          },
          true,
          this.props.journalUserId,
          cropImageUrl.size
        );
      }
    }
  };

  setCreateSpinner = () => {
    this.setState({ createSpinner: !this.state.createSpinner });
  };

  createContact = () => {
    let addNewContact = {};
    this.setCreateSpinner();
    this.props.addContact(
      addNewContact,
      this.props.contacts,
      (res) => {
        if (res) {
          this.props.history.push("/add-contact");
        }
        this.setCreateSpinner();
      },
      this.props.journalUserId
    );
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    let fullHeight = this.state.windowHeight;
    let fullWidth = this.state.windowWidth;
    let scanWidth = "100%";
    let scanHeight = "auto";
    let maxContHeight = fullHeight;
    if (fullWidth > TAB_BREAk_POINT_START) {
      maxContHeight = fullHeight - 80;
    } else {
      maxContHeight = fullHeight;
    }
    return (
      <View style={[styles.modalMiddleView]}>
        {this.state.createSpinner ? (
          null
          // <ActivityIndicator color="#FF8300" size="small" />
        ) : (
          <View style={[styles.popUpBlockFull, styles.modalScan]}>
            <View>
              <View style={[styles.popUpBlock, styles.menuBg]}>
                <View style={styles.popUpContentArea}>
                  <View style={styles.gridSectionPopup}>
                    <View style={[styles.boxShadowModalNone]}>
                      <View
                        style={[stylesRes.modalContIn, stylesRes.colPdComScan]}
                        dataSet={{media: ids.colPdComScan}}
                      >
                        <View style={styles.gridRow}>
                          <View style={styles.gridColFull}>
                            <View style={[styles.infoBlock]}>
                              <View style={stylesRes.imgPreviewSecCom}>
                                <View
                                  style={[
                                    stylesRes.scanFullArea,
                                    { height: maxContHeight },
                                  ]}
                                >
                                  <View style={[stylesRes.modelCloseInner]} dataSet={{media: ids.modelCloseInner}}>
                                    <TouchableOpacity
                                      onPress={() => this.props.close()}
                                      style={[stylesRes.modalCloseScan]}
                                    >
                                      <View style={[stylesRes.closeIconView]}>
                                        <Image
                                          style={styles.closeModalIcon}
                                          source={require("../../../assets/icons/close.png")}
                                        />
                                      </View>
                                    </TouchableOpacity>
                                  </View>

                                    {!Boolean(this.state.imageUrl) ? (
                                    <ContactCamera
                                      onimageSrc={this.onimageSrc}
                                      scanHeight={scanHeight}
                                      scanWidth={scanWidth}
                                      maxContHeight={maxContHeight}
                                      windowWidth={this.state.windowWidth}
                                      windowHeight={this.state.windowHeight}
                                      paddingAll={true}
                                      modalView={true}
                                    />
                                  ) : (
                                    <ImageCropper
                                      imageUrl={this.state.imageUrl}
                                      onCropImage={this.onCropImage}
                                      contactCamera={() => {
                                        this.setState({ imageUrl: null });

                                        this.createContact();
                                      }}
                                      scanHeight={scanHeight}
                                      scanWidth={scanWidth}
                                      maxContHeight={maxContHeight}
                                      windowWidth={this.state.windowWidth}
                                      windowHeight={this.state.windowHeight}
                                      paddingAll={true}
                                    />
                                  )}
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    contacts: state.routines.contacts,
    journalUserId: state.uistate.journalUserId,
    user: state.routines.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onUpload: (artifactId, formData, callBack, reload, journalUserId, fileSize) =>
      dispatch(
        actions.uploadAttachmen(
          artifactId,
          formData,
          callBack,
          reload,
          journalUserId,
          fileSize
        )
      ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageTakephoto);
