import {
  ADD_COMMUNITY_GROUPS_RESOURCES_FAIL,
  VALIDATE_RESOURCE_SUCCESS,
  VALIDATE_RESOURCE_FAIL,
  RESET_ADD_RESOURCE_FETCHED_DATA,
  FETCH_ALL_RESOURCE_SUCCESS,
  FETCH_ALL_RESOURCE_FAIL,
  FETCH_ALL_SOCIAL_MEDIA_DATA_SUCCESS,
  FETCH_ALL_SOCIAL_MEDIA_DATA_FAIL,
  VALIDATE_DOCUMENT_SUCCESS,
  VALIDATE_DOCUMENT_FAIL,
  SELECTED_RESOURCE,
  HANDLE_SEARCH_SUCCESS,
  HANDLE_SEARCH_FAIL,
  TAG_SEARCH_SUCCESS,
  TAG_SEARCH_FAIL,
  UPDATE_SEARCHED_KEYWORDS, POP_PREVIOUS_TAG_SEARCH_RESULT, HANDLE_RESET_SEARCH, HANDLE_RESET_COMMUNITY_SEARCH
} from "../actions/actionTypes";
import {updateObject} from "../../shared/utility";

const initialState = {
  fetchAllResources: null,

  deleteResource: {
    isLoading: false,
    response: null,
    error: null
  },
  validateResource: null,
  validateDocumentResource: null,
  selectedResource: null,
  fetchAllSocialMediaData: {
    isLoading: false,
    response: null,
    error: null
  },

  searchResultStack: [],
  searchedKeyword: null
};

const addCommunityGroupsResourcesFailed = (state, error) => {
  return updateObject(state, {
    errorDescription: error,
    error: true
  });
};

const validateResourceSuccess = (state, data) => {
  return updateObject(state, {
    validateResource: data
  });
};

const validateResourceFail = (state, error) => {
  return updateObject(state, {
    errorDescription: error,
    error: true
  });
};

const validateDocumentSuccess = (state, data) => {
  return updateObject(state, {
    validateDocumentResource: data
  });
};

const validateDocumentFail = (state, error) => {
  return updateObject(state, {
    errorDescription: error,
    error: true
  });
};

const resetAddResourceFetchedData = state => {
  return updateObject(state, {
    validateResource: null,
    validateDocumentResource: null
  });
};

const fetchAllResourcesSuccess = (state, data) => {
  return updateObject(state, {
    fetchAllResources: data
  });
};

const fetchAllResourcesFail = (state, error) => {
  return updateObject(state, {
    errorDescription: error,
    error: true
  });
};

const fetchSocialMediaDataSuccess = (state, data) => {
  return updateObject(state, {
    fetchAllSocialMediaData: data
  });
};

const fetchSocialMediaDataFail = (state, error) => {
  return updateObject(state, {
    errorDescription: error,
    error: true
  });
};

const selectResource = (state, data) => {
  return updateObject(state, {
    selectedResource: data
  });
};

const resetSearch = (state) => {
  return updateObject(state, {
    searchResultStack: [],
  });
};

const onhandleSearchSuccess = (state, data, searchObject) => {
  return updateObject(state, {
    searchResultStack: [{searchResults: data, tag: null, searchObject: searchObject}],
  });
};

const onhandleSearchFail = (state, error) => {
  return updateObject(state, {
    errorDescription: error,
    error: true
  });
};

const handlePopPreviousTagSearchResult = (state) => {
  let searchResultStack = [];
  if (state.searchResultStack && state.searchResultStack.length > 0) {
    searchResultStack = state.searchResultStack.slice(0, state.searchResultStack.length - 1);
  } else {
    searchResultStack = [];
  }

  return updateObject(state, {
    searchResultStack: searchResultStack
  });
}

const handleSearchByTagSuccess = (state, tags, searchResults, searchSource) => {
  let searchResultStack;
  let searchStackElement = {searchResults: searchResults, tag: tags, searchObject: null, searchSource: searchSource};
  if (
    state.searchResultStack &&
    state.searchResultStack.length > 0
  ) {
    searchResultStack = [...state.searchResultStack];
    searchResultStack.push(searchStackElement);
  } else {
    searchResultStack = [searchStackElement];
  }

  return updateObject(state, {
    searchResultStack: searchResultStack
  });
};

const handleSearchByTagFail = (state, error) => {
  return updateObject(state, {
    errorDescription: error,
    error: true
  });
};

const updateSearchedKeywords = (state, searchObject) => {
  return updateObject(state, {
    searchedKeyword: searchObject.searchedKeyword
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_RESOURCE_SUCCESS:
      return fetchAllResourcesSuccess(state, action.allResources);
    case FETCH_ALL_RESOURCE_FAIL:
      return fetchAllResourcesFail(state, action.errorDescription);
    case FETCH_ALL_SOCIAL_MEDIA_DATA_SUCCESS:
      return fetchSocialMediaDataSuccess(state, action.getSocialMedia);
    case FETCH_ALL_SOCIAL_MEDIA_DATA_FAIL:
      return fetchSocialMediaDataFail(state, action.errorDescription);
    case ADD_COMMUNITY_GROUPS_RESOURCES_FAIL:
      return addCommunityGroupsResourcesFailed(state, action.errorDescription);
    case VALIDATE_RESOURCE_SUCCESS:
      return validateResourceSuccess(state, action.validResource);
    case VALIDATE_RESOURCE_FAIL:
      return validateResourceFail(state, action.errorDescription);
    case RESET_ADD_RESOURCE_FETCHED_DATA:
      return resetAddResourceFetchedData(state);
    case VALIDATE_DOCUMENT_SUCCESS:
      return validateDocumentSuccess(state, action.validDocument);
    case VALIDATE_DOCUMENT_FAIL:
      return validateDocumentFail(state, action.errorDescription);
    case SELECTED_RESOURCE:
      return selectResource(state, action.selectedResource);
    case HANDLE_SEARCH_SUCCESS:
      return onhandleSearchSuccess(state, action.searchResult, action.searchObject);
    case HANDLE_SEARCH_FAIL:
      return onhandleSearchFail(state, action.errorDescription);
    case HANDLE_RESET_COMMUNITY_SEARCH:
      return resetSearch(state);
    case TAG_SEARCH_SUCCESS:
      return handleSearchByTagSuccess(state, action.tagSearchResults.tag, action.tagSearchResults.tagResults, action.tagSearchResults.searchSource);
    case POP_PREVIOUS_TAG_SEARCH_RESULT:
      return handlePopPreviousTagSearchResult(state);
    case TAG_SEARCH_FAIL:
      return handleSearchByTagFail(state, action.errorDescription);
    case UPDATE_SEARCHED_KEYWORDS:
      return updateSearchedKeywords(state, action.searchObject);
    default:
      return state;
  }
};

export default reducer;
