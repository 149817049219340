import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import FindPeersResultsComponent from '../../../components/PeerToPeer/BeforeMainCreation/FindPeersResultsComponent';
import {PEER_TO_PEER, DASHBOARD} from '../../../navigation/path';
import {peerToPeerTabs, peerToPeerTabPath} from '../../../Constant';
import {handleCommonBackNavigation} from '../../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../../util/pendoConfig';

class FindPeersResultsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
      peersResults: [],
      loading: true,
    };
  }

  componentDidMount() {
    const {journalUserId, navigation, hideMobileMenu, onGetLatestPeers, peerProfile} = this.props;
    if (journalUserId) {
      navigation.push(DASHBOARD.path);
    }
    if (peerProfile && peerProfile?.username) {
      handleCommonBackNavigation(navigation);
    } else {
      onGetLatestPeers((success, res) => {
        if (success) {
          this.setState({loading: false, peersResults: res});
        } else {
          this.setState({loading: false});
        }
      });
    }
    hideMobileMenu();
    pendoTrackEvent('Community/@LatestConnections');
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth} = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  componentWillUnmount = () => {
    this.props.showMobileMenu();
  };

  handleOnPressCreateProfile = () => {
    pendoTrackEvent('Community > @LatestConnections > CreateProfile');
    this.props.OnSetPeerToPeerActiveTab(peerToPeerTabs.profile);
    this.props.navigation.push(
      PEER_TO_PEER.path, {
        from: this.props.route.name,
        communityTab: peerToPeerTabPath.profile
      }
    );
  };

  handleOnPressBackButton = () => {
    handleCommonBackNavigation(this.props.navigation);
  };

  render() {
    const {windowHeight, navigation, windowWidth, route, hasNotch} = this.props;
    const {peersResults, loading} = this.state;
    return (
      <FindPeersResultsComponent
        handleOnPressCreateProfile={this.handleOnPressCreateProfile}
        handleOnPressBackButton={this.handleOnPressBackButton}
        index={PEER_TO_PEER.index}
        peersResults={peersResults}
        navigation={navigation}
        route={route}
        windowHeight={windowHeight}
        windowWidth={windowWidth}
        loading={loading}
        hasNotch={hasNotch}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
    peerProfile: state.routines.peer2peer,
    hasNotch: state.uistate.hasNotch,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    OnSetPeerToPeerActiveTab: activeTabIndex =>
      dispatch(actions.setPeerToPeerActiveTab(activeTabIndex)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    onGetLatestPeers: (callBack) => dispatch(actions.getLatestPeers(callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FindPeersResultsScreen);
