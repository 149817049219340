import React, {Component} from 'react';
import {View, Text, TouchableOpacity, Keyboard} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import styles from '../../Css';
import * as comTitles from './titles';
import * as FieldDetails from '../../components/TextInput/fieldDetails';
import stylesResponsive from '../../CssResponsive';
import Collapsible from '../Collapsible/Collapsible';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import QuestionList from '../../components/Question/Add';
import Frequency from './Frequency';
import Dates from '../DateTimePicker/Dates';
import Prescriber from '../AutoComplete/CommonAutoComplete/Prescriber';
import Instructions from './Instructions';
import Notes from './Notes';
import TextPickerFrame from '../../components/TextInput/textPickerFrame';
import Quantity from './Quantity';
import HealthData from './HealthData';
import moment from 'moment';
import {hasValue} from '../../util/hashValueGenerator';
//import AutoCompleteFrame from '../../components/AutoComplete/AutoCompleteFrame';
import AutoCompleteSuggestion from '../../components/AutoComplete/AutoCompleteSuggestion';
import ColorItemField from '../../components/ColorPicker/ColorItemField';
import ColorPickerRow from '../../components/ColorPicker/ColorPickerRow';
import * as ColorCode from '../../components/ColorPicker/ColorCodes';
import Warning from '../../components/Warning/warning';
import { pendoTrackEvent } from '../../util/pendoConfig';
import {autoSuggestionListType, chartTypes} from '../../Constant/index';
import {getTypeOfMeasurementByUomId, verifyDuplicateMedication} from '../../util/commonUiLogic';
import CancelButton from '../Button/cancelButton';

class Activity extends Component {
  constructor(props) {
    super(props);

    let healthProviderName = '';
    const {contactsProcessed, newTherapie, isNew, treatments} = props;
    const {
      id,
      artifactId,
      name,
      prescribedBy,
      notes,
      defaultMetrics,
      dosages,
      isActive,
      isPaused,
      pause,
      pauseDate,
      pauseDuration,
      active,
      displayProperties = {},
      isPinned
    } = newTherapie;

    const {color} = displayProperties ? displayProperties : {};

    if (prescribedBy && contactsProcessed[prescribedBy]) {
      const {givenName, organization} = contactsProcessed[prescribedBy];
      healthProviderName = givenName ? givenName : organization;
    } else if (!prescribedBy && newTherapie && newTherapie.healthProviderName) {
      healthProviderName = newTherapie.healthProviderName;
    }

    this.id = id;
    this.artifactId = artifactId;
    this.active = active;
    this.isActive = isActive;
    this.isPaused = isPaused;
    this.isPinned = isPinned;
    this.color = color ? color : ColorCode.BGACTIVITY;
    this.pause = pause;
    this.pauseDate = pauseDate;
    this.pauseDuration = pauseDuration;
    this.prescribedBy = prescribedBy;
    this.healthProviderName = healthProviderName;
    this.notes = notes;
    const unit =
      dosages && dosages.length > 0 && dosages[0].units
        ? dosages[0].units
        : 'minutes';

    this.startDate =
      dosages && dosages.length > 0 ? dosages[0].start : new Date().getTime();
    this.endDate = dosages && dosages.length > 0 ? dosages[0].end : null;
    this.quantity =
      dosages && dosages.length > 0
        ? dosages[0].quantity
        : unit === 'minutes'
        ? 5
        : 1;
    this.frequency = dosages && dosages.length > 0 ? dosages[0].frequency : 1;
    this.periodic =
      dosages && dosages.length > 0 ? dosages[0].frequencyType : 'DAILY';
    this.instructions =
      dosages && dosages.length > 0 ? dosages[0].instructions : '';
    this.dosagesId = dosages && dosages.length > 0 ? dosages[0].id : '';
    this.helethData = defaultMetrics;

    this.state = {
      name: name,
      unit: unit,
      autocompleteOpen: false,
      validateName: false,
      saveWait: false,
      isNew: isNew,
      saveDisable: false,
      visibleAddNew: false,
      color: this.color,
      collapsible: {
        course:
          (this.instructions && this.instructions !== '') ||
          this.startDate ||
          this.endDate ||
          this.healthProviderName !== '' ||
          (this.helethData && this.helethData.length > 0)
            ? true
            : false,
        notes: this.notes ? true : false,
        questions: this.hasQuestions(
          this.props.questionDigest.userArtifactHealthQuestions,
          this.artifactId,
        ),
        medicalInfo: false,
      },
      terms: [],
      selectedValues: Activity.getSelectedValues(treatments, props),
      dosages: dosages,
      duplicatePopup: false,
      error: Math.random(),
      hasSelectedName: name ? true : false,
    };
  }

  componentDidMount() {
    this.getSystemActivity();
    this.updateNewTherapie();
    this.props.setHashChanges(this.getActivityHashValue(), "ACTIVITY");
    this.refUpdate();
    this.checkInitialDuplicates()
  }

  componentDidUpdate() {
    if (this.props.initActiveHash !== this.getActivityHashValue()) {
      this.props.setHaveChanges(true, 'ACTIVITY');
    } else {
      this.props.setHaveChanges(false, 'ACTIVITY');
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {treatments} = props;

    return {
      selectedValues: Activity.getSelectedValues(treatments, props),
    };
  }

  checkInitialDuplicates = () => {
    const {name, unit} = this.state;
    if (!this.props.forceEdit) {
      this.verifyDuplicateMedication(name, unit, this.quantity);
    }
  }

  refUpdate = () => {
    const {getRef} = this.props;
    getRef && getRef(this);
  };

  getActivityHashValue = () => {
    return hasValue(
      this.instructions +
        ',' +
        this.periodic +
        ',' +
        this.frequency +
        ',' +
        this.quantity +
        ',' +
        this.endDate +
        ',' +
        this.startDate +
        ',' +
        this.notes +
        ',' +
        this.healthProviderName +
        ',' +
        this.prescribedBy +
        ',' +
        JSON.stringify(this.helethData) +
        ',' +
        this.state.name +
        ',' +
        this.state.unit +
        ',' +
        this.color,
    );
  }

  getDisabledStatus = () => {
    return this.state.saveDisable;
  }

  hasQuestions = (questions, artifactId) => {
    let questionList = [];
    if (questions) {
      questionList = questions.filter(function(question) {
        return question.artifactId === artifactId;
      });
    }
    return questionList.length > 0 ? true : false;
  };

  static getSelectedValues = (dosages, props) => {
    const selectedValues = {};
    const {treatmentsProcessed} = props;

    if (dosages && dosages.length > 0) {
      dosages.forEach(element => {
        if (element.treatmentType === 5) {
          const obj = treatmentsProcessed[element.id];
          if (obj) {
            selectedValues[obj.name] = obj.name;
          }
        }
      });
    }
    return selectedValues;
  };

  getSystemActivity = () => {
    this.props.systemDefinedActivities((result, data) => {
      if (result) {
        this.setState({terms: data});
      }
    });
  };

  onPressUpdateTherapie = () => {
    Keyboard.dismiss();
    setTimeout(() => {
    const {name, unit, hasSelectedName} = this.state;
    let hasHealthDataError = false;
    if (Object.keys(this.helethData).length > 0) {
      this.helethData.forEach((item, index) => {
        if (!Boolean(item?.unit)) {
          hasHealthDataError = true;
          this.helethData[index].error = true;
        }
      });
    }
    if (!name || (name && name.trim() === '')) {
      this.setState({validateName: true});
      if (this.props.scrollToTop) {
        this.props.scrollToTop();
      }
      return;
    } else if (hasHealthDataError) {
      this.setState({error: Math.random()});
      return;
    } else if (!hasSelectedName) {
      return;
    } else {
      const addNewTherapie = {
        treatment: {
          id: this.id,
          artifactId: this.artifactId,
          name: name,
          notes: this.notes,
          prescribedBy: this.prescribedBy,
          identificationNumber: null,
          treatmentType: 5,
          active: this.active,
          isActive: this.isActive,
          isPaused: this.isPaused,
          isPinned: this.isPinned,
          pause: this.pause,
          pauseDate: this.pauseDate,
          pauseDuration: this.pauseDuration,
          dosages: [
            {
              id: this.dosagesId ? this.dosagesId : -1,
              start: moment.utc(this.startDate).valueOf(),
              end: moment.utc(this.endDate).valueOf(),
              quantity: this.quantity,
              frequency: this.frequency,
              frequencyType: this.periodic,
              instructions: this.instructions,
              units: unit,
            },
          ],
          displayProperties: {
            id: null,
            createdOn: null,
            modifiedOn: null,
            color: this.color,
            artifactId: null,
            dashedLine: false,
          },
          publishedBy: "SELF",
        },
      };

      this.setState({saveWait: true});
      this.props.setSaveWait(true);
      pendoTrackEvent('MedSupp+Act@ > Save');
      if (Object.keys(this.helethData).length > 0) {
        if (!this.prescribedBy && this.healthProviderName) {
          this.UpdateTherapieWithHelethData(
            addNewTherapie,
            this.healthProviderName,
          );
        } else {
          this.UpdateTherapieWithHelethData(addNewTherapie, null);
        }
      } else {
        if (!this.prescribedBy && this.healthProviderName) {
          this.addContactAndUpdateTherapie(
            addNewTherapie,
            this.healthProviderName,
          );
        } else {
          this.updateTherapie(addNewTherapie);
        }
      }
    }
    }, 300);
  };

  UpdateTherapieWithHelethData = (
    addNewTherapie,
    healthProviderName,
  ) => {
    const {unitsOfMeasureDigestProcessed} = this.props;
    const {unitsOfMeasure} = unitsOfMeasureDigestProcessed;

    const defaultMetrics = [];
    const newMetrix = [];
    this.helethData.forEach((res, index) => {
      if (res.metric) {
        defaultMetrics.push(res);
      } else if (res.data.type === 'USER') {
        defaultMetrics.push({
          unit: res.unit,
          metric: res.data.id,
          id: -(index + 1),
        });
      } else {
        let uomId = null;
        if (res.unit) {
          uomId = res.unit;
        }

        const typeOfMeasurement = getTypeOfMeasurementByUomId(unitsOfMeasure, uomId);
        newMetrix.push({
          metric: res.data,
          isActive: true,
          id: -(index + 1),
          name: res.data.name,
          createdOn: null,
          modifiedOn: null,
          artifactId: null,
          description: res.data.description ? res.data.description : null,
          typeOfMeasurement: typeOfMeasurement,
          metricGroup: res.data.metricGroup ? res.data.metricGroup : 1,
          displayProperties: {
            color: 'rgba(113, 168, 220, 1)',
            style: chartTypes.dash,
            scaleMin: null,
            scaleMax: null,
          },
          uomId: uomId,
        });
      }
    });

    if (newMetrix.length > 0) {
      let count = 0;
      newMetrix.forEach((obj, index) => {
        this.props.updateUserMetric(obj, (success, data) => {
          if (success) {
            defaultMetrics.push({
              id: obj.id,
              unit: obj.uomId,
              metric: data.id,
            });
            count = count + 1;
            if (obj.uomId) {
              const metricDefaultUnitOfMeasure = {
                id: -(index + 1),
                createdOn: null,
                modifiedOn: null,
                metricId: data.id,
                uomId: obj.uomId,
              };
              this.props.updateUserMetricDisplayUom(metricDefaultUnitOfMeasure);
            }
          }
          if (count === newMetrix.length) {
            addNewTherapie.treatment.defaultMetrics = defaultMetrics;
            if (healthProviderName) {
              this.addContactAndUpdateTherapie(
                addNewTherapie,
                this.healthProviderName,
              );
            } else {
              this.updateTherapie(addNewTherapie);
            }
          }
        });
      });
    } else {
      addNewTherapie.treatment.defaultMetrics = defaultMetrics;
      if (healthProviderName) {
        this.addContactAndUpdateTherapie(
          addNewTherapie,
          this.healthProviderName,
        );
      } else {
        this.updateTherapie(addNewTherapie);
      }
    }
  };

  // getTypeOfMeasurement = uomId => {
  //   const {unitsOfMeasureDigestProcessed} = this.props;
  //   const {unitsOfMeasure} = unitsOfMeasureDigestProcessed;
  //   if (unitsOfMeasure && unitsOfMeasure[uomId]) {
  //     return unitsOfMeasure[uomId].typeOfMeasurement;
  //   }

  //   return null;
  // };

  addContactAndUpdateTherapie = (
    addNewTherapie,
    healthProviderName,
  ) => {
    let addNewContact = {
      addNewContact: {
        contact: {
          id: -1,
          givenName: healthProviderName,
          isCareTeam: true,
        },
      },
    };
    this.props.updateContact(
      addNewContact,
      this.props.contacts,
      (success, contactData) => {
        if (success) {
          addNewTherapie.treatment.prescribedBy = contactData.id;
        }

        this.updateTherapie(addNewTherapie);
      },
      this.props.journalUserId,
      false,
    );
  };

  updateTherapie = (addNewTherapie) => {
    this.props.handleOnSaveQuestion(() => {
      this.props.updateTherapie(
        addNewTherapie,
        (success, data) => {
          this.setState({saveWait: false});
          this.props.setSaveWait(false);
          if (success) {
            this.props.handleOnPressSaveButton();
          } else {
          }
        },
        this.props.journalUserId,
      );
    });
  };

  handleOnSubmitCancel = callBack => {
    const changeHash = this.getActivityHashValue();
    if (this.props.initActiveHash === changeHash) {
      callBack(false);
    } else {
      callBack(true);
    }
  };

  getChangeAct = () => {
    let flag = null;
    const changeHash = this.getActivityHashValue();
    if (this.props.initActiveHash === changeHash) {
      flag = false;
    } else {
      flag = true;
    }
    return flag;
  };

  verifyDuplicateMedication = (name, unit, quantity) => {
    const {treatments} = this.props;
    verifyDuplicateMedication(name, null, unit, quantity, treatments, (exist, msg) => {
      if (exist) {
        this.setState({
          duplicatePopup: true,
          saveDisable: true,
          errorDescription: msg,
        });
      } else {
        this.setState({
          duplicatePopup: false,
          saveDisable: false,
          errorDescription: null,
        });
      }
    }, this.id);
  };

  handleOnPressCollapsible = (key, val) => {
    let collapsibleTemp = this.state.collapsible;
    collapsibleTemp[key] = !val;
    this.setState({collapsible: collapsibleTemp});
  };

  healthProviderNameChange = val => {
    this.healthProviderName = val;
    this.updateNewTherapie();
  };

  updateQuantity = val => {
    this.quantity = val;
    this.updateNewTherapie();
  };

  updateTime = val => {
    this.frequency = val;
    this.updateNewTherapie();
  };

  updatePeriodic = val => {
    this.periodic = val;
    this.updateNewTherapie();
  };

  updateInstructions = val => {
    this.instructions = val;
    this.updateNewTherapie();
  };

  updatePrescribedBy = (id, name) => {
    this.prescribedBy = id;
    this.healthProviderName = name;
    this.updateNewTherapie();
  };

  updateNotes = val => {
    this.notes = val ? val : null;
    this.updateNewTherapie();
  };

  updateStartDate = val => {
    this.startDate = val;
    this.updateNewTherapie();
  };

  updateEndDate = val => {
    this.endDate = val;
    this.updateNewTherapie();
  };

  updateDuration = val => {
    const { name, unit } = this.state;
    this.quantity = val;
    this.updateNewTherapie();
    this.verifyDuplicateMedication(name, unit, this.quantity);
  };

  updateHelethData = val => {
    this.helethData = val;
    this.updateNewTherapie();
    this.setState({error: Math.random()});
  };

  updateAutocompleteOpen = val => {
    this.setState({autocompleteOpen: val});
  };

  onUnitValueChange = val => {
    this.setState(
      {
        unit: val,
      },
      () => {
        let {name} = this.state;
        this.updateNewTherapie();
        this.verifyDuplicateMedication(name, val, this.quantity);
      },
    );
  };

  onChangeTextName = currentInput => {
    this.setState({
      name: currentInput ? currentInput : null,
      validateName: false,
      hasSelectedName: false,
      duplicatePopup: false,
      saveDisable: false,
      errorDescription: null,
    },
    () => {
      if (!currentInput) {
        this.updateNewTherapie();
      }
    });
  };

  onItemPressName = (selection, type) => {
    this.setState(
      {
        name: selection.name,
        hasSelectedName: true
      },
      () => {
        let {unit} = this.state;
        this.updateNewTherapie();
        Keyboard.dismiss();
        this.verifyDuplicateMedication(selection.name, unit, this.quantity);
      },
    );
  };

  onPressCustomName = (selection, type) => {
    if (selection && selection.trim() !== '') {
      this.setState(
        {
          name: selection,
          hasSelectedName: true
        },
        () => {
          let {unit, name} = this.state;
          this.updateNewTherapie();
          Keyboard.dismiss();
          this.verifyDuplicateMedication(name, unit, this.quantity);
        },
      );
    }
  };

  updateNewTherapie = () => {
    const {name, unit} = this.state;
    let treatment = {
      id: this.id,
      artifactId: this.artifactId,
      name: name,
      notes: this.notes,
      prescribedBy: this.prescribedBy,
      identificationNumber: null,
      treatmentType: 5,
      active: this.active,
      isActive: this.isActive,
      isPaused: this.isPaused,
      isPinned: this.isPinned,
      pause: this.pause,
      pauseDate: this.pauseDate,
      pauseDuration: this.pauseDuration,
      defaultMetrics: this.helethData,
      healthProviderName: this.healthProviderName,
      dosages: [
        {
          id: this.dosagesId ? this.dosagesId : -1,
          start: moment.utc(this.startDate).valueOf(),
          end: this.endDate ? moment.utc(this.endDate).valueOf() : null,
          quantity: this.quantity,
          frequency: this.frequency,
          frequencyType: this.periodic,
          instructions: this.instructions,
          units: unit,
        },
      ],
      treatmentId: this.id,
      displayProperties: {
        id: null,
        createdOn: null,
        modifiedOn: null,
        color: this.color,
        artifactId: null,
        dashedLine: false,
      },
    };
    this.props.updateNewTherapie(treatment);
  };
  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {
      validateName,
      unit,
      name,
      terms,
      selectedValues,
      color,
      duplicatePopup,
      errorDescription,
      saveDisable,
    } = this.state;
    return (
      <View style={[styles.therapyActivityTab]}>
        <View
          style={[
            styles.subInSection,
            styles.zIndexAutoComplete,
            styles.colPdTopLtRt,
          ]}>
          <View style={[styles.fieldRow, styles.zIndexAutoComplete]}>
            <View style={[styles.fieldRowWrapper]}>
              <View style={styles.fieldColStretch}>
                <AutoCompleteSuggestion
                  textFieldWithTitle={true}
                  textFieldTitle="Activity"
                  triggerLength={1}
                  validateName={validateName}
                  onChangeText={this.onChangeTextName}
                  onItemPress={this.onItemPressName}
                  currentInput={name}
                  onPressCustom={this.onPressCustomName}
                  customSectionTitle={'Add Custom Meds/Activity/Therapy'}
                  showCustomSection={true}
                  autoFocus={true}
                  fieldBg={FieldDetails.BGWHITE}
                  fieldLt={true}
                  terms={terms}
                  selectedValues={selectedValues}
                  updateTermList={() => this.getSystemActivity()}
                  zoomIcon={true}
                  removeIcon={true}
                  type={autoSuggestionListType.activity}
                />
              </View>
            </View>
          </View>
        </View>
        
        <View style={[styles.subInSection, styles.zIndexMin, styles.colPdLtRt]}>
          <View style={[styles.zIndexMin, styles.comSp]}>
            <View style={[styles.fieldRow, styles.noBotMg]}>
              <View style={[styles.fieldRowWrapper]}>
                <View style={styles.fieldColStretch}>
                  <Quantity
                    quantity={this.quantity}
                    updateDuration={this.updateDuration}
                    fieldLt={true}
                    units={unit}
                  />
                </View>
                <View style={styles.fieldColSp} />
                <View style={styles.fieldColStretch}>
                  <TextPickerFrame
                    title={'Measure'}
                    fieldBg={FieldDetails.BGWHITE}
                    items={[
                      {key: 'minutes', value: 'minutes', label: 'minutes'},
                      {key: 'hours', value: 'hours', label: 'hours'},
                      {
                        key: 'repetitions',
                        value: 'repetitions',
                        label: 'repetitions',
                      },
                      {key: 'sessions', value: 'sessions', label: 'sessions'},
                    ]}
                    fieldAlign={FieldDetails.ALIGNLEFT}
                    fieldLt={true}
                    selectedValue={unit}
                    onValueChange={this.onUnitValueChange}
                  />
                </View>
              </View>
            </View>
            {Boolean(duplicatePopup) && (
              <View style={[styles.zIndexMin, styles.comMgTopSm, styles.mgRtTwelve]}>
                <Warning
                  errorDescription={errorDescription}
                  textPosition={'left'}
                />
              </View>
            )}
          </View>

          <Frequency
            times={this.frequency}
            periodic={this.periodic}
            updateTime={this.updateTime}
            updatePeriodic={this.updatePeriodic}
          />
          <View style={[styles.zIndexMin]}>
            <ColorPickerRow colorPickerTitle={'Choose Colour For Graph'}>
              <ColorItemField
                handleChangeComplete={color => {
                  this.color = color;
                  this.setState({color: color});
                  this.updateNewTherapie();
                }}
                color={color}
              />
            </ColorPickerRow>
          </View>
          <View style={[stylesRes.secDividerCom, stylesRes.zIndexLower]}>
            <View style={stylesRes.secDividerSp} />
          </View>
        </View>

        <View style={[styles.subSectionSub, styles.zIndexMin]}>
          <Collapsible
            title="More Information"
            contentVisibility={this.state.collapsible.course}
            handleOnPressCollapsible={res =>
              this.handleOnPressCollapsible('course', res)
            }
            style={[styles.collapseTitle]}
            plusButton={true}
            toolTipButton={true}
            toolTipId={'tool-tip-more-info-activity'}
            toolTipContent={
              'Add details about your activity including instructions and health data for tracking'
            }
            topBorder={true}
            disabledScroll={this.props.disabledScroll}
            // titleMd={true}
          >
            <View
              style={[
                styles.collapseContent,
                styles.colPdLtRt,
                styles.zIndexAutoComplete,
              ]}>
              <HealthData
                helethData={this.helethData}
                updateHelethData={this.updateHelethData}
                updateAutocompleteOpen={this.updateAutocompleteOpen}
                error={this.state.error}
              />

              <Instructions
                instructions={this.instructions}
                updateInstructions={this.updateInstructions}
              />
              <View style={[styles.subSection, styles.zIndexMinSpec]}>
                <Prescriber
                  prescribedBy={this.prescribedBy}
                  healthProviderName={this.healthProviderName}
                  updatePrescribedBy={this.updatePrescribedBy}
                  updateAutocompleteOpen={this.updateAutocompleteOpen}
                />

                <Dates
                  startDate={this.startDate}
                  endDate={this.endDate}
                  updateStartDate={this.updateStartDate}
                  updateEndDate={this.updateEndDate}
                />
              </View>
            </View>
          </Collapsible>

          <View style={[styles.subSection, styles.zIndexMin]}>
            {/* Notes */}
            <Collapsible
              title="Notes"
              contentVisibility={this.state.collapsible.notes}
              handleOnPressCollapsible={res =>
                this.handleOnPressCollapsible('notes', res)
              }
              plusButton={true}
              topBorder={true}
              // titleMd={true}
            >
              <Notes updateNotes={this.updateNotes} notes={this.notes} />
            </Collapsible>

            <Collapsible
              title="To-dos"
              contentVisibility={this.state.collapsible.questions}
              handleOnPressCollapsible={res =>
                this.handleOnPressCollapsible('questions', res)
              }
              plusButton={true}
              topBorder={true}
              // titleMd={true}
            >
              <QuestionList type="therapy" scrollToEnd={this.props.scrollToEnd} />
            </Collapsible>
          </View>
        </View>

        {/* </View> */}
        <View style={[styles.subSection, styles.zIndexMin]}>
          <View style={styles.btnArea}>
            <View style={stylesRes.btnViewSec}>
              <View style={styles.fieldBtn}>
                <View style={styles.btnBlockFull}>
                  <CancelButton
                    touchableOnpress={() => this.props.handleOnPressBackButton()}
                    disabled={this.state.saveWait}
                  />
                </View>
              </View>
              <View style={styles.fieldBtnSpSm} />
              <View style={styles.fieldBtn}>
                <View style={styles.btnBlockFull}>
                  <TouchableOpacity
                    style={[
                      styles.submitSm,
                      styles.btnShadowCom,
                      saveDisable
                        ? [styles.submitBgOrange, styles.btnBlurState]
                        : styles.submitBgOrange,
                    ]}
                    underlayColor="rgba(0, 153, 168, 0.8)"
                    onPress={() => {
                      this.onPressUpdateTherapie();
                    }}
                    disabled={
                      this.state.saveWait || saveDisable || !this.getChangeAct
                    }
                    nativeID={'medSuppActSave'}>
                    <View ref={component => (this._root = component)}>
                      <View style={styles.btnSingleView}>
                        <View style={[styles.btnSingle, styles.btnSingleCent]}>
                          {this.state.saveWait && (
                            <View
                              style={[
                                styles.indicatorIconView,
                                styles.indicatorLtCom,
                              ]}>
                              <ActivityIndicator pdZero={true} size="small" />
                            </View>
                          )}
                          <Text
                            style={[styles.submitBtnTextWhite, styles.bold]}
                            allowFontScaling={false}
                            >
                            {comTitles.SAVE}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    contacts: state.routines.contacts,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    treatments: state.routines.treatments,
    journalUserId: state.uistate.journalUserId,
    contactsProcessed: state.routines.contactsProcessed,
    isNew: state.therapie.isNew,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    questionDigest: state.routines.questionDigest,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.updateTherapie(addNewTherapie, callBack, journalUserId)),
    displayProperties: (displayPrope, journalUserId) =>
      dispatch(
        actions.updateGraphDisplayProperties(displayPrope, journalUserId),
      ),
    updateContact: (addNewContact, contacts, callBack, journalUserId, fetchAttachments) =>
      dispatch(
        actions.updateContact(addNewContact, contacts, callBack, journalUserId, fetchAttachments),
      ),
    updateUserMetric: (metric, callBack) =>
      dispatch(actions.updateUserMetric(metric, callBack)),
    updateUserMetricDisplayUom: metricDefaultUnitOfMeasure =>
      dispatch(actions.updateUserMetricDisplayUom(metricDefaultUnitOfMeasure)),
    systemDefinedActivities: callBack =>
      dispatch(actions.systemDefinedActivities(callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Activity);
