import React, { useState, useEffect } from "react";
import {View, Text, Image, TouchableOpacity, Keyboard} from 'react-native';
import StartAndEndDate from "../../../components/HealthJourney/PopUp/StartAndEndDate";
import styles from '../../../Css';
import * as constants from '../HealthJourneyConstant';

const HealthJourneyDatePicker = (props) => {
  const {title, disabled, required, setIsStartDateSelected, type, setStartDate, setEndDate, startDate, endDate, isStartDaySelected, setIsStartDaySelected,  isEndDaySelected, setIsEndDaySelected, setActiveBubble, activeBubbleId,isStartDateSelected, isEndDateSelected, dob, dismissTagList} = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (activeBubbleId || open) {
      setOpen(activeBubbleId === type)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBubbleId])

  const handleOpenCloseModal = () => {
    setTimeout(() => {
      setOpen(!open);
    }, 0);
    setActiveBubble(open ? null : type);
    Keyboard.dismiss();
  }

  return (
    <View>
      <TouchableOpacity
        onPress={ () => {
          handleOpenCloseModal();
          dismissTagList();
        }}
        disabled={disabled}
      >
        <View
          style={[
            styles.flexAiCt,
            //styles.flexJcSb,
            styles.flexRow,
            styles.btnIconView,
            styles.textQuest,
            styles.NwEvtCalendarBox,
            styles.customWidthCalender,
            open && styles.lightHighlightedBleBg
          ]}>
          <Image
            style={styles.calendarIcnNwEvnt}
            source={require('../../../assets/health-journey-icons/calendar-icon.png')}
            resizeMode={constants.IMAGE_RESIZE_MODE}
          />

          <Text
            style={[
              styles.textPrimeSm,
              styles.calendarTxtNwEvnt,
              styles.btnIconView,
            ]}>
            <Text style={styles.pdRtTwo}>{title}</Text>
          </Text>
          {Boolean(required) &&
          <Text style={styles.dateRequireClr}>
            *
          </Text>
          }
        </View>
      </TouchableOpacity>
      {open && (
        <StartAndEndDate
          dob={dob}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          type={type}
          setIsStartDateSelected={setIsStartDateSelected}
          handleOpenCloseModal={() => handleOpenCloseModal()}
          startDate={startDate}
          endDate={endDate}
          isStartDaySelected={isStartDaySelected}
          setIsStartDaySelected={setIsStartDaySelected}
          isEndDaySelected={isEndDaySelected}
          setIsEndDaySelected={setIsEndDaySelected}
          isStartDateSelected={isStartDateSelected}
          isEndDateSelected={isEndDateSelected}
        />
      )}
    </View>
  ); 
};

export default HealthJourneyDatePicker;
