import React, {Component} from 'react';
import Symptoms from '../../../components/Settings/symptoms/symptoms';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import {SYMPTOMS, DASHBOARD} from '../../../navigation/path';
import {handleCommonBackNavigation} from '../../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../../util/pendoConfig';
import {sortAlphabeticalOrder} from '../../../util/commonUiLogic';
class SymptomsScreen extends Component {
  constructor(props) {
    super(props);
    //TO-DO remove previous navigation changes
    // let from = null;
    // if (this.props.route?.params && this.props.route?.params.from) {
    //   from = this.props.route?.params.from;
    // }
    this.state = {
      userDefinedSymptoms: this.props.userDefinedSymptoms,
      loadingWait: true,
      keywords: 
        //TO-DO remove previous navigation changes
        // from && //from && this.props.history.action === 'PUSH' &&
        // from.includes(SYMPTOMS.viewSymptomPath) &&
        // this.props.symptomFilter ? this.props.symptomFilter : '',
        '',
    };
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      let searchString = null;
      let from = null;
      if (this.props.route?.params && this.props.route?.params.from) {
        from = this.props.route?.params.from;
      }
      if (this.props.symptomFilter && from && (
        from.includes(SYMPTOMS.viewSymptomPath) || 
        from.includes(SYMPTOMS.addSymptomPath) ||
        from.includes(SYMPTOMS.editSymptomPath))
      ) {
        searchString = this.props.symptomFilter;
      }
      this.searchUserDefinedSymptoms(searchString);
      setTimeout(() => this.setState({loadingWait: false}), 1500);
      pendoTrackEvent('Symptoms@');
    });
    this.props.navigation.addListener('blur', () => {
      this.props.navigation.setParams({from: SYMPTOMS.path});
    });
  }

  // UNSAFE_componentWillReceiveProps(props) {
  //   const {userDefinedSymptoms, error, errorDescription} = props;
  //   this.setState({
  //     userDefinedSymptoms: userDefinedSymptoms,
  //     error,
  //     errorDescription,
  //   });
  // }

  onPressAddSymptom = () => {
    pendoTrackEvent('Symptoms@ > Add');
    this.props.onSelectSymptom(null, () => {
      this.props.navigation.replace(SYMPTOMS.addSymptomPath);
    });
  };

  onSelectSymptom = id => {
    let selectUserSymptom = this.props.userDefinedSymptoms.filter(function(
      symptoms,
    ) {
      return symptoms.id === id;
    });

    if (selectUserSymptom && selectUserSymptom.length > 0) {
      this.props.onSelectSymptom(selectUserSymptom[0], () => {
        pendoTrackEvent('Symptoms@ > ViewSymptom');
        this.props.navigation.push(SYMPTOMS.viewSymptomPath);
      });
    }
  };

  searchUserDefinedSymptoms = keywords => {
    if (keywords) {
      const filterSymptoms = this.props.userDefinedSymptoms.filter(res => {
        return res.name
          ? res.name.toLowerCase().includes(keywords.toLowerCase())
          : null;
      });

      this.setState({
        userDefinedSymptoms: filterSymptoms,
        keywords: keywords,
      });
    } else {
      this.setState({
        userDefinedSymptoms: this.props.userDefinedSymptoms,
        keywords: '',
      });
    }
    this.props.setSymptomFilter(keywords);
  };

  render() {
    let {userDefinedSymptoms} = this.state;
    userDefinedSymptoms = sortAlphabeticalOrder(userDefinedSymptoms);
    return (
      <Symptoms
        handleOnPressBackButton={() => {
          let from = null;
          if (this.props.route?.params && this.props.route?.params.from) {
            from = this.props.route?.params.from;
          }
          if (this.state.keywords !== '' && this.state.keywords !== null) {
            this.searchUserDefinedSymptoms(null);
          } else if (
            from &&
            (from.includes(SYMPTOMS.viewSymptomPath) ||
              from.includes(SYMPTOMS.addSymptomPath) ||
              from.includes(SYMPTOMS.editSymptomPath))
          ) {
            this.props.navigation.reset({
              index: 0,
              routes: [{ name: DASHBOARD.path }],
            });
          } else {
            handleCommonBackNavigation(this.props.navigation);
          }
        }}
        onPressAddSymptom={this.onPressAddSymptom}
        userSymptoms={userDefinedSymptoms}
        onPressSelectSymptom={this.onSelectSymptom}
        searchUserDefinedSymptoms={this.searchUserDefinedSymptoms.bind(this)}
        loadingWait={this.state.loadingWait}
        index={SYMPTOMS.index}
        navigation={this.props.navigation}
        route={this.props.route}
        keywords={this.state.keywords}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    userDefinedSymptoms: state.routines.symptomsDigest.userSymptoms,
    errorDescription: state.routines.errorDescription,
    error: state.routines.error,
    symptomFilter: state.filter.symptomFilter
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSelectSymptom: (selectedSymptom, callback) =>
      dispatch(actions.onSelectSymptom(selectedSymptom, callback)),
    setSymptomFilter: filter =>
      dispatch(actions.setSymptomFilter(filter)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SymptomsScreen);
