import React from 'react';
import {View, TextInput} from 'react-native';
import styles from '../../Css';
import FieldTitleCommon from './fieldTitleCommon';
import * as FontScaling from '../../components/Text/fontScaling';
const inputMinMaxField = React.forwardRef((props, ref) => (
  <View style={[styles.textInputRangeWrapper, styles.postRelative]}>
    <FieldTitleCommon
      fieldAlign={props.fieldAlign}
      fieldBg={props.fieldBg}
      title={props.title}
      requirdStar={props.requirdStar}
      fieldLt={props.fieldLt}
      fieldSm={props.fieldSm}
      fieldRg={props.fieldRg}
      fieldLabelThin={props.fieldLabelThin}
    />
    <TextInput
      style={[
        styles.textInputField,
        styles.textInputFieldMinMax,
        props.fieldInfo ? styles.frameInfoPd : styles.framePd,
        props.fieldBg && props.fieldBg === 'grey'
          ? styles.bgThinGrey
          : props.fieldBg && props.fieldBg === 'blue'
          ? styles.bgBlueExLight
          : props.fieldBg && props.fieldBg === 'transparent'
          ? styles.bgTransparent
          : styles.bgWhite,
        props.fieldAlign && props.fieldAlign === 'center'
          ? styles.textAlignCenter
          : styles.textAlignLeft,
        props.invalid ? styles.inValidColor : styles.validColor,
        props.fieldStyle,
      ]}
      allowFontScaling={false}
      underlineColorAndroid="transparent"
      value={props.value || props.value === 0 ? props.value.toString() : ''}
      onChangeText={props.onChangeText}
      editable={props.editable}
      placeholder={props.placeholder}
      returnKeyType={props.returnKeyType}
      onSubmitEditing={props.onSubmitEditing}
      onFocus={props.onFocus}
      ref={ref}
      maxLength={props.maxLength}
      textContentType={props.textContentType}
      keyboardType={props.keyboardType}
      maxFontSizeMultiplier = {FontScaling.fontScaleField}
    />
  </View>
));

export default inputMinMaxField;
