import theme from "./base/theme";
import * as variable from "./base/variable";
import {Platform} from 'react-native';

export const cssAutoComplete = {
  autoCompleteWrapper: {
    position: "relative",
  },
  autoCompleteItemRow: {
    flexDirection: "row",
    paddingTop: 3,
    paddingLeft:13,
    paddingBottom: 3,
    paddingRight: 13,
  },
  autoCompleteItemComRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 5,
    paddingLeft: 5,
    paddingBottom: 5,
    paddingRight: 5,
  },
  autoCompleteItemComRowCr: {
    flexDirection: "row",
    alignItems: "center",

  },
  selectedItemData: {
    flex: 1,
  },
  autoCompleteSecBorder: {
    borderBottomColor: theme.COLOR_GREY_LIGHT_EX_7,
    borderBottomWidth: 1,
    width: "100%",
    marginTop: 1,
    marginBottom: 1,
  },
  autoCompleteBtnView: {
    position: "absolute",
    right: 0,
    zIndex: 99,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  addCustomBtn: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 5,
    paddingLeft: 5,
    backgroundColor: theme.COLOR_ORANGE_DARK,
    minHeight: 30,
  },
  addCustomborderCr: {
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4
  },
  addCustomBtnText: {
    textAlign: "center",
    color: theme.COLOR_WHITE,
  },
  autoCompleteAdd: {
    marginLeft: 5,
  },
  autoCompleteClearIcon: {
    width: 16,
    height: 16,
    resizeMode: "contain",
  },
  autoCompletePlusIcon: {
    height: 16,
    resizeMode: "contain",
    width: 16,
  },
  autoCompletePlusIconPd: {
    paddingRight: 16
  },
  autoCompleteCustomText: {
    ...variable.fontPrimary,
    color: theme.COLOR_ORANGE,
    fontSize: 14,
    fontWeight: "bold",
    fontStyle: 'italic',
  },
  autoCompleteTextInput: {
    fontStyle: 'italic',
    fontWeight: 'bold'
  },
  autoCompleteTextRw: {
    flexDirection: 'row',
  },
  borderBottomColor: {
    borderColor:theme.COLOR_GREY_LIGHT_EX_7,
    borderBottomWidth: 1
  },
  autoCompleteTextLgWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: 3,
    paddingRight:19,
    flexBasis:'100%'
    // flexShrink: 1,
    // flexBasis:'0%',
  },
  textFieldAutoCompleteLg: {
    paddingRight: 100,
  },
  textFieldAutoCompleteSm: {
    paddingRight: 15,
  },
  IconRtPadding: {
    paddingRight: 10
  },
  autoSelectRoundIcon: {
    width: 22,
    height: 22,
    resizeMode: "contain",
    marginTop: 3,
    //marginRight:8,
  },
  autoSelectCheckText: {
    width: 18,
    height: 18,
    textAlign: 'center'
  },
  selectedItemRoundIcon: {
    flexBasis: 28,
    maxWidth: 28,
  },
  selectedItemCheckView: {
    flexBasis: 28,
    maxWidth: 28,
    alignItems: 'center',
    justifyContent: 'center',
  },

  selectedItemRoundIconCr: {
    // flexBasis: 100,
    maxWidth: 30,
  },
  autoCompleteTitleBlockCom: {
    paddingTop: 3,
    paddingLeft: 3,
    paddingBottom: 3,
    paddingRight: 3,
  },
  autoCompleteTitleBlockComCr: {
    height: 44,
    justifyContent: 'center',
    paddingLeft: 10,
    // paddingRight: 19,
    // paddingTop: 15,
    // paddingBottom:15,
    // width: '100%'
  },
  btnActionTouch: {
    height: '100%',
  },
  autoCompleteIconView: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },

  listSuggestion: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  customMyListText: {
    ...variable.fontPrimary, //(fontSize:12)
    fontSize: theme.FONT_SIZE_SM,
    fontStyle: 'italic',
    paddingRight: 20
  },

  systemListText: {
    ...variable.fontPrimary, //(fontSize:12)
    color: theme.COLOR_GREY_LIGHT_EX_12,
    fontSize: theme.FONT_SIZE_SM,   
  },

  listGap: {
    paddingTop:7
  },
  autosuggestlistitemhg: {
    ...Platform.select({
      ios: {
        maxHeight: 251
      },
      android: {
        maxHeight: 261
      },
    }),
  },
  autosuggestunititemhg: {
    maxHeight: 120 
  }
};
