import React, {Component} from 'react';
import {Text, View, Image, Platform, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import { connect } from 'react-redux';
import {autoSuggestionListType} from '../../Constant/index';
import Highlighter from '../../modules/textHighlighter';
import { WEB } from '../../util/platformWindowConfig';


class AutoSuggestionListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      select: props.item.data
        ? props.selectedValues[props.item.data.id] ||
          props.selectedValues[props.item.name] ||
          props.selectedValues[props.item.data?.treatmentId] ||
          props.selectedValues[props.item.name?.[0]?.trim()]
          ? true
          : false
        : props.selectedValues[props.item.name]
        ? true
        : false,
      inputText: this.props.item.currentInput,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      select: props.item.data
        ? props.selectedValues[props.item.data.id] ||
          props.selectedValues[props.item.name] ||
          props.selectedValues[props.item.data?.treatmentId] ||
          props.selectedValues[props.item.name?.[0]?.trim()]
          ? true
          : false
        : props.selectedValues[props.item.name],
    });
  }

  onSelect = () => {
    this.setState({select: true}, () => {
      if (Platform.OS === WEB) {
        setTimeout(() => this.props.onItemPress(this.props.item), 500);
      } else {
        this.props.onItemPress(this.props.item);
      }
    });
  };

  getUnits = uomId => {
    const {unitsOfMeasure} = this.props.unitsOfMeasureDigest;

    if (unitsOfMeasure && uomId) {
      return unitsOfMeasure.filter(res => res.id === uomId)[0]?.symbol;
    } else {
      return 'Select Measure';
    }
  };
  itemData = (data, type) => {
    switch(type) {
      case autoSuggestionListType.activity:
        return (data.dosages?.[0]?.quantity !== null ? data.dosages[0]?.quantity : '') + ' ' + (data.dosages?.[0]?.units ? data.dosages[0]?.units : '');
      case autoSuggestionListType.symptom:
        return data?.displayProperties?.scaleMax === 1 &&  data?.displayProperties?.scaleMin === 0 ? 'Yes or No' : '0-10';
      case autoSuggestionListType.health_data:
        return this.getUnits(this.props.userDefinedMetricUnitOfMeasures.filter(res => res.metricId === data?.id)?.[0]?.uomId);
      case autoSuggestionListType.medication:
        return (data.dosages?.[0]?.quantity !== null ? data.dosages[0]?.quantity : '') + ' ' + (data.dosages?.[0]?.units ? data.dosages[0]?.units : '');
      default:
        return '';
    }
  }

  itemMesurements = (data, type) => {
    switch(type) {
      case autoSuggestionListType.activity:
        return 'Set Duration';
      case autoSuggestionListType.symptom:
        return this.state.select
          ? data?.displayProperties?.scaleMax === 1 &&  data?.displayProperties?.scaleMin === 0 ? 'Yes or No' : '0-10'
          : 'Select Measure';
      case autoSuggestionListType.health_data:
        return this.state.select
          ? this.getUnits(
              this.props.userDefinedMetricUnitOfMeasures.filter(res => res.metricId === (this.props.userDefinedMetrics.filter(res=> res.name === this.props.systemDefinedMetricsProcessed[data?.id]?.name)[0]?.id))?.[0]?.uomId
          )
          : 'Select Measure';
      case autoSuggestionListType.medication:
        return data?.Ingredient  ? data?.Ingredient  : '' ;
      default:
        return '';
    }
  }
  
  render() {
    const {item, onUserItem , type, removeSelectedIcon, selectedValue, disbleSelectedValues} = this.props;
    return (
      <View style={[styles.autoCompleteItemCom, styles.secSepBorderBot]}>
        <TouchableOpacity onPress={this.onSelect} disabled={disbleSelectedValues && this.state.select}>
          <View style={[styles.autoCompleteItemComRow]}>
            {!Boolean(removeSelectedIcon) &&
              <View style={[styles.selectedItemRoundIcon]}>
                {this.state.select ? (
                  <Image
                    style={styles.autoSelectRoundIcon}
                    source={require('../../assets/icons/selection-round-tick.png')}
                  />
                ) : (
                  <Image
                    style={styles.autoSelectRoundIcon}
                    source={require('../../assets/icons/selection-round-default.png')}
                  />
                )}
              </View>
            }
            {type === autoSuggestionListType.health_data_uom &&
              <View style={styles.selectedItemCheckView}>
                {this.props.selectedValue === item.data.id &&
                  <Text 
                    style={[
                      styles.autoSelectCheckText,
                      styles.textPrime,
                      styles.textUOMListItem,
                    ]}
                    allowFontScaling={false}>{'\u2713'}</Text>
                }
              </View>
            }
            <View style={[styles.selectedItemData]}>
              {Boolean(this.props.keyword) || Boolean(this.props.item.bold) ? (
                <Highlighter
                  highlightStyle={[{
                    fontWeight: 'bold',
                    color: "black"},
                    type === autoSuggestionListType.health_data_uom && selectedValue === item.data.id &&
                    styles.textBlueUOM,
                  ]}
                  style={[
                    styles.textPrime,  
                    type === autoSuggestionListType.health_data_uom && selectedValue === item.data.id &&
                    styles.textUOMListItem
                  ]}
                  allowFontScaling={false}
                  numberOfLines={type === autoSuggestionListType.health_data_uom ? 2 : 3} 
                  nativeID={"textTruncate"} 
                  ellipsizeMode='tail'
                  autoEscape={true}
                  searchWords={[this.props.keyword, this.props.item.bold]}
                  textToHighlight={
                    type === autoSuggestionListType.medication ? 
                      item.name + 
                      (item?.data?.drugIngredients?.[0]?.strength ? ' (' + item?.data?.drugIngredients?.[0]?.strength : '') + 
                      (item?.data?.drugIngredients?.[0]?.strengthUnit ? item?.data?.drugIngredients?.[0]?.strengthUnit + ')': '') 
                      : item.name
                    }
                />
              ):(
                <Text 
                  style={[styles.textPrime,
                    type === autoSuggestionListType.health_data_uom && selectedValue === item.data.id &&
                    styles.textUOMListItem
                  ]} 
                  allowFontScaling={false} 
                  numberOfLines={type === autoSuggestionListType.health_data_uom ? 2 : 3} 
                  nativeID={"textTruncate"}  
                  ellipsizeMode='tail' 
                >
                {type === autoSuggestionListType.medication ? 
                    item.name + 
                    (item?.data?.drugIngredients?.[0]?.strength ? ' (' + item?.data?.drugIngredients?.[0]?.strength : '') + 
                    (item?.data?.drugIngredients?.[0]?.strengthUnit ? item?.data?.drugIngredients?.[0]?.strengthUnit + ')': '') 
                    : item.name}
                  </Text>
              )}

              {type !== autoSuggestionListType.health_data_uom           
                ? onUserItem === 'user' ? (
                  <View style={[styles.listSuggestion, styles.listGap]}>
                    <Text style={[styles.textPrimeExSm, styles.truncateMaxWidth]} allowFontScaling={false} numberOfLines={1} nativeID={"textTruncate"} ellipsizeMode='tail'>
                      {this.itemData(item?.data, type)}
                    </Text>
    
                    <Text style={[styles.customMyListText, styles.listTrucatePd]} allowFontScaling={false}>
                      in My List
                    </Text>
                  </View>
                ) : (
                  <View style={[styles.listSuggestion, styles.listGap, styles.selectedItemData ]}>    
                    <View style={[styles.truncateMaxName]}>
                      {this.props.keyword ? (
                        <Highlighter
                          highlightStyle={{
                            fontWeight: 'bold',
                            color: "black"
                          }}
                          style={styles.textPrimeExSm}
                          allowFontScaling={false}
                          numberOfLines={1} 
                          nativeID={"textTruncate"} 
                          ellipsizeMode='tail'
                          autoEscape={true}
                          searchWords={[this.props.keyword]}
                          textToHighlight={
                            type === autoSuggestionListType.medication ? this.itemMesurements(item, type) : this.itemMesurements(item?.data, type)
                          }
                        />
                      ) : (
                        <Text style={[styles.textPrimeExSm]} allowFontScaling={false} numberOfLines={1} nativeID={"textTruncate"} ellipsizeMode='tail' >
                          {type === autoSuggestionListType.medication ? this.itemMesurements(item, type) : this.itemMesurements(item?.data, type)}
                        </Text>
                      )}
                    </View>

                    {type === autoSuggestionListType.medication &&
                      <View style={[styles.truncateMaxWidth, styles.listTrucatePd]}>
                        {this.props.keyword ? (
                          <Highlighter
                            highlightStyle={{
                              fontWeight: 'bold',
                              color: "black"
                            }}
                            style={[styles.systemListText, styles.textColorGreyLight, styles.systemListTextRightPd]}
                            allowFontScaling={false}
                            autoEscape={true}
                            searchWords={[this.props.keyword]}
                            textToHighlight={item?.din ? item?.din  : ''}
                          />
                        ) : (
                          <Text style={[styles.systemListText, styles.textColorGreyLight, styles.systemListTextRightPd]} allowFontScaling={false}>
                            {item?.din ? item?.din  : ''}
                          </Text>  
                        )}
                      </View> 
                    }
                  </View>
                ) : null
              }
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    unitsOfMeasureDigest: state.routines.unitsOfMeasureDigest
      ? state.routines.unitsOfMeasureDigest
      : [],
    systemDefinedMetricsProcessed: state.routines.metricsDigestProcessed
      ? state.routines.metricsDigestProcessed.systemDefinedMetrics
      : [],
    userDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.userDefinedMetrics
      : [],
    userDefinedMetricUnitOfMeasures: state.routines.metricsDigest
      ? state.routines.metricsDigest.userDefinedMetricUnitOfMeasures
      : [],
  };
};


export default connect(mapStateToProps )(AutoSuggestionListItem);
