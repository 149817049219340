import theme from "./base/theme";
export const cssDatePickerLayout = {
    datePickerTitleView: {
        width: "100%",
        flexDirection: "row",
        flex: 1,
    },
    btnTabTitleView: {
        flex: 1,
    },
    pickBg: {
       backgroundColor: theme.COLOR_GREEN_5,
    },
    datePickerHeaderView: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        width: '100%'
    },
    datePickerBtnPd: {
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 3,
        paddingBottom: 3,
    },
    datePickerBtnText: {
        alignSelf: 'flex-start'
    },
    pickerRowMg: {
        marginBottom: 3
    },
    pickerHeaderBorder: {
        borderColor: theme.COLOR_GREY_DARK_5,
        borderTopWidth: 2,
        borderRightWidth: 2,
        borderLeftWidth: 2,
    },
    datePickerTitleApp: {
        minHeight: 55,
    },
    datePickerBtnWrapper: {
        borderLeftWidth: 2, 
        borderLeftColor: theme.COLOR_GREY_DARK_5,
        borderRightWidth: 2, 
        borderRightColor: theme.COLOR_GREY_DARK_5,
        borderBottomWidth: 2, 
        borderBottomColor: theme.COLOR_GREY_DARK_5,
        width: 300,
        paddingBottom: 5,
        backgroundColor: theme.COLOR_WHITE
    },
    datePickerBtn: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        minWidth: 64
    },
    datePickerBtnMgRt: {
        marginRight: 8,
    },
    datePickerBtnView: {
        justifyContent: 'flex-end',
        flexDirection: 'row'
    },
    pickterBtnText: {
        color: '#8bc34a'
    },
    datePickerHeaderWrapperApp: {
        marginTop: 15,
    },
    datePickerModalWrapper: {
    backgroundColor: theme.COLOR_WHITE,
    paddingBottom: 18,
    paddingTop: 18,
    paddingLeft: 18,
    paddingRight: 18,
    },
    datePickerHeaderWrapperWeb: {
        width: '100%',
    },
    datePickerTitleWrapperWeb: {
        paddingTop: 18,
        marginLeft: 18,
        marginRight: 18,
        width: 274,
    },
    textFieldDatePickerField: {
        minHeight: 29,
        justifyContent: 'center',
    },
    daySelectionBox: {
        maxWidth: 274,
        paddingBottom: 4,
        paddingTop: 4,
        paddingLeft: 4,
        paddingRight: 4,
    },
    dayColWrap: {
        width: 36,
        height: 36,
        marginLeft: 1,
        marginRight: 1,
        marginTop: 1,
        marginBottom: 1,
    },
    dayColTouch: {
        width: 36,
        height: 36,
        borderRadius: 36,
        alignItems: 'center',
        justifyContent: 'center'
    },
    dayActive: {
        backgroundColor: theme.COLOR_ORANGE_DARK
    },
    dayDefault: {
        backgroundColor: theme.COLOR_WHITE
    },
    pickerColDateSection: {
        width: '60%',
        paddingRight: 5,
    },
    pickerColTimeSection: {
        width: '40%',
        paddingLeft: 5,
        justifyContent: 'flex-end'
    },
    pickerDateTimeSection: {
        flexDirection: 'row'
    },
    datePickerBtnRow: {
        flexDirection: 'row'
    },
    datePickerTimeBtn: {
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 3,
        paddingBottom: 3,
    },
    timeFullRow: {
        alignItems: 'flex-end'
    },
    timeTypeFull: {
        marginBottom: 3,
    },
    datePickerTimeView: {
        marginBottom: -3
    }
};
  