import React from "react";
import { View, TouchableOpacity, ScrollView } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";

import MenuItem from "./menuItem";

const AddMenuMobile = (props) => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  const { menuItems, close } = props;
  return (
    <View style={[styles.mobileAddMenuItems]}>
      <TouchableOpacity
        style={[styles.addLinksOverlayCom]}
        onPress={close}
      ></TouchableOpacity>
      <View style={[stylesRes.addLinksArea]}>
        <View style={[stylesRes.menuScrollApp]}>
          <ScrollView>
            {menuItems.map((item, index) => (
              <MenuItem {...item} type={"MW"} key={"add_menu_web_" + index} />
            ))}
          </ScrollView>
        </View>
      </View>
    </View>
  );
};

export default (AddMenuMobile);
