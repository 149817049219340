import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import LogoSetUp from '../../components/LogoSetUp/LogoSetUp';
import {ORGANIZATION_NAME} from '../../util/commonUiConfig';

export const LogoSetup = () => {
  return (
    <View style={styles.gridRow}>
      <View style={[styles.gridColFull, styles.infoPdBotSm]}>
        <LogoSetUp />
      </View>
    </View>
  );
};
export const ViewStyle = () => {
  return (
    <View style={styles.gridRow}>
      <View style={styles.gridColFull}>
        <View style={styles.greyBorderThinTop} />
      </View>
    </View>
  );
};

export const ProceedTextConent = navigateToDashbaord => {
  return (
    <View style={styles.gridRow}>
      <View style={styles.gridColFull}>
        <View style={[styles.staticBtnWrapper, styles.flexJcCtAiCt]}>
          <View style={styles.btnBlockFull}>
            <TouchableOpacity
              style={[styles.submitMd, styles.submitBgOrange, styles.smRad]}
              underlayColor="rgba(0, 153, 168, 0.8)"
              onPress={navigateToDashbaord}>
              <View style={[styles.btnSingle, styles.btnSingleCent]}>
                <Text
                  style={[styles.submitText, styles.submitTextSm, styles.bold]} allowFontScaling={false}>
                  Proceed to {ORGANIZATION_NAME}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};
