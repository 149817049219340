import React from 'react';
import {Text, View, Image} from 'react-native';
import styles from '../../Css';
import PrimaryButton from '../Button/primaryButton';
import PrimaryButtonWhite from '../Button/primaryButtonWhite';
import Modal from '../Modal/modal';
import ModalLayout from '../ModalLayout/modalLayout';

const appUpdateModal = props => {
  return (
    <Modal visible={props.visibility} onBackdropPress={props.onBackdropPress}>
      <ModalLayout
        contPosition={'center'}
        showClose={props.showClose}
        pdCom={true}
        hidePopup={props.hidePopup}>
        <View style={styles.popupContentWrapper}>
          <View style={[styles.popMessageContView, styles.comSp]}>
            <View style={[styles.popMessageTitleView]}>
              <Text style={[styles.textTitleSmBold]} allowFontScaling={false}>New Update Available</Text>
            </View>
          </View>
          <View style={styles.modalSectionView}>
            <View style={[styles.mainDescWrapper, styles.postRelative]}>
              <View style={[styles.mainImgWrapper, styles.flexJcCtAiCt]}>
                <Image
                  style={styles.modalBg}
                  source={require('../../assets/modal/modal-bg.png')}
                />
              </View>
              <View style={[styles.mainContWrapper, styles.flexJcCtAiCt]}>
                <Text style={[styles.textPrimeLg, styles.textAlignCenter]} allowFontScaling={false}>
                  We have updated Zamplo with better features, improved tools
                  and tighter security. Just for you!
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.popBtnView, styles.comMgTopSm]}>
            <View style={[styles.btnArea, styles.flexRow, styles.flexJcCtAiCt]}>
              <View style={styles.fieldBtn}>
                {/* hide this section when button no need - start*/}
                {props.showNotNow && (
                  <PrimaryButtonWhite
                    btnText={'Not Now'}
                    btnPosition={'full'}
                    touchableOnPress={props.notNowUpdate}
                  />
                )}
                {/* hide this section when button no need - end*/}
              </View>
              <View style={styles.fieldBtnSpSm} />
              <View style={styles.fieldBtn}>
                <PrimaryButton
                  btnText={'Update Now'}
                  btnPosition={'full'}
                  touchableOnPress={props.updateNow}
                  activityIndStyle={styles.indicatorLtMiniLg}
                />
              </View>
            </View>
          </View>
        </View>
      </ModalLayout>
    </Modal>
  );
};

export default (appUpdateModal);
