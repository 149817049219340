import React, {Component} from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Header from '../../components/Header/header';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import UIText from '../../components/Text/text';
import {connect} from 'react-redux';
import Webcam from 'react-webcam';
import * as actions from '../../store/actions/index';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import {MEDICATIONS, DASHBOARD, HEALTH_JOURNEY} from '../../navigation/path';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { verifyDuplicateMedicationExistOrNot } from '../../util/commonUiLogic';
import { TAB_BREAk_POINT_END, TAB_BREAk_POINT_START } from '../../util/platformWindowConfig';

class ScanPrescription extends Component {
  constructor(props) {
    super(props);
    const inside = this;
    let fromPopup = null;
    if (props.route?.params && props.route?.params.fromPopup) {
      fromPopup = props.route?.params.fromPopup;
    }
    this.state = {
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      reqFailed: false,
      showSpinner: false,
      facingMode: inside.checkForMobile() ? {exact: 'environment'} : 'user',
      videoConstraints: {
        width: {
          min: 320,
          max: 1280,
        },
        height: {
          min: 240,
          max: 720,
        },
        facingMode: inside.checkForMobile() ? {exact: 'environment'} : 'user',
      },
      resetCameraView: false,
      screenshot: null,
      width: 0,
      height: 0,
      fromPopup: fromPopup,
      formHealthJourney: props.route?.params?.from === HEALTH_JOURNEY.path,
      eject: false,
    };
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      this.setState({showSpinner: false, screenshot: null});
      this.props.hideMobileMenu();
      pendoTrackEvent('MedSupp+Act/@ScanPrescription');
    });
  }

  componentWillUnmount() {
    this.props.showMobileMenu();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {windowHeight, windowWidth} = nextProps;
    this.setState({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    });
    // Image.getSize(
    //   nextProps.image,
    //   (width, height) => {
    //     this.setState({ width: width, height: height });
    //   },
    //   error => {
    //     //  this.setState({ loading: false });
    //   }
    // );
  }

  checkForMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      return true;
    }
    return false;
  }

  setRef = webcam => {
    this.webcam = webcam;
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.setState({reqFailed: false, screenshot: imageSrc});
    Image.getSize(
      imageSrc,
      (width, height) => {
        this.setState({
          width: width,
          height: height,
        });
      },
      error => {
        // this.setState({ loading: false });
      },
    );

    if (imageSrc) {
      this.processPhoto(imageSrc);
    }
  };

  resetView = () => {
    this.setState({reqFailed: false, screenshot: null});
  };

  showSpinner = () => {
    this.setState({showSpinner: !this.state.showSpinner});
  };

  processPhoto = image => {
    this.showSpinner();
    this.props.scanPrescription(image, (didScanFail, response) => {
      if (!didScanFail && !this.state.eject) {
        if (response.jobId) {
          this.retryPrescription(response.jobId);
        } else setTimeout(() => this.showSpinner(), 500);
      } else
        setTimeout(() => {
          this.setState({reqFailed: true});
          this.showSpinner();
        }, 500);
    });
  };

  retryPrescription = jobId => {
    const MAX_RETRY = 27;
    let currentRetry = 0;

    const getPrescription = () => this.props.getPrescription(jobId, callBack);

    const retryErrorHandler = () => {
      if (currentRetry < MAX_RETRY && !this.state.eject) {
        currentRetry++;
        setTimeout(() => {
          console.log('Retrying...');
          getPrescription();
        }, 2 * 1000);
      } else {
        console.log('Retried several times but still failed');
        this.setState({reqFailed: true});
      }
    };

    const callBack = (status, prescription) => {
      if (status && !this.state.eject) {
        const found = verifyDuplicateMedicationExistOrNot(prescription.BrandName, prescription.IdentificationNumber, this.props.treatments);
        if (found && this.state.formHealthJourney) {
          this.props.setEventData({
            ...this.props.eventData,
            categoryTypeItems: [...this.props.eventData?.categoryTypeItems.filter(item => item.id !== found.id), {id: found.id}],
          })
          this.handleOnPressBack();
          return;
        }
        let PrescriberId;
        if (prescription.Prescriber) {
          const prescriber = this.props.contacts.find(
            element => element.givenName === prescription.Prescriber,
          );
          if (prescriber) {
            PrescriberId = prescriber.id;
            this.commonCreateTherapy(prescription, PrescriberId);
          } else {
            let newContact = {
              addNewContact: {
                contact: {
                  id: -1,
                  givenName: prescription.Prescriber,
                },
              },
            };
            this.props.updateContact(newContact, '', (success, contact) => {
              if (success) {
                PrescriberId = contact.id;
                this.commonCreateTherapy(prescription, PrescriberId);
              } else {
                this.commonCreateTherapy(prescription, PrescriberId);
              }
            });
          }
        } else {
          this.commonCreateTherapy(prescription, PrescriberId);
        }
      } else if (prescription === 'IN_PROGRESS' && !this.state.eject) {
        retryErrorHandler();
      } else {
        this.setState({reqFailed: true, showSpinner: false});
      }
    };

    getPrescription();
  };

  commonCreateTherapy = (prescription, PrescriberId) => {
    const globalPath = this.props.route?.params?.globalPath;
    const fromDashbordMeds = this.props.route?.params?.fromDashbordMeds;
    const newTherapy = {
      treatment: {
        id: -1,
        name: prescription.BrandName ? prescription.BrandName : null,
        prescribedBy: PrescriberId ? PrescriberId : null,
        identificationNumber: prescription.IdentificationNumber
          ? prescription.IdentificationNumber
          : null,
        treatmentType: prescription.Type ? prescription.Type : null,
        displayProperties: {
          id: null,
          createdOn: null,
          modifiedOn: null,
          color: null,
          artifactId: null,
          dashedLine: false,
        },
        active: true,
        isActive: true,
        // ...(prescription.Units && {
        //   dosages: [{ units: prescription.Units }]
        // })
        dosages: [],
      },
    };
    this.props.updateTherapy(
      newTherapy,
      res => {
        if (res) {
          if (this.state.eject) {
            this.deleteDiscartedTherapy(res?.id);
            return;
          }
          // setTimeout(() => this.uploadImage(this.props.newTherapy), 500);
          // this.props.navigation.navigate(MEDICATIONS.addTherapiePath);
          this.uploadImage(this.props.newTherapy, () => {
            if (this.state.eject) {
              this.deleteDiscartedTherapy(res?.id);
            } else if (this.state.formHealthJourney) {
              this.handleOnPressBack();
            } else {
              this.props.navigation.push(
                MEDICATIONS.addTherapiePath, {
                  from: globalPath === DASHBOARD.path ? globalPath : this.props.route.name,
                  globalPath: globalPath,
                  fromPopup: this.state.fromPopup,
                  activeTherapyTab: fromDashbordMeds ? 0 : null
                }
              );
            }
          });
        } else this.showSpinner();
        // this.props.setCreateSpinner();
      },
      this.props.journalUserId,
    );
  };

  deleteDiscartedTherapy = id => {
    const {deleteTherapy, journalUserId, resetNewTherapie} = this.props;
    resetNewTherapie();
    deleteTherapy(id, () => {}, journalUserId);
  }

  uploadImage = (newTherapy, redirectCallBack) => {
    const formData = new FormData();
    let userId = this.props.user.id;
    let timestamp = new Date().getTime() + '.jpeg';
    formData.append(
      'files[0]',
      this.b64toBlob(this.state.screenshot),
      timestamp,
    );

    const callBack = (success, attachments) => {
      if (success && attachments) {
        this.props.uploadImageToOCR({
          treatmentTypeId: newTherapy.treatmentType.toString(),
          instanceId: newTherapy.artifactId.toString(),
          attachments: [
            {
              fileName: attachments[0].filename,
              attachmentId: attachments[0].id.toString(),
              content: this.state.screenshot,
            },
          ],
          attachmentCategory: 'Therapy',
          userId: userId.toString(),
        });
        redirectCallBack();
      }
    };

    this.props.uploadImageToBackend(
      newTherapy.artifactId,
      formData,
      callBack,
      false,
      this.props.journalUserId,
      this.b64toBlob(this.state.screenshot).size,
    );
  };

  b64toBlob = dataURI => {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: 'image/jpeg'});
  };

  changeMode = () => {
    this.setState({resetCameraView: true});

    setTimeout(() => {
      const {facingMode} = this.state;
      const newFacingMode =
        facingMode === 'user' ? {exact: 'environment'} : 'user';

      this.setState({
        facingMode: newFacingMode,
        resetCameraView: false,
      });
    }, 100);
  };

  redirectScreen = () => {
    const {formHealthJourney, fromPopup} = this.state;
    const globalPath = this.props.route?.params?.globalPath;
    const  fromDashbordMeds = this.props?.params?.fromDashbordMeds;
    if (formHealthJourney) {
      this.handleOnPressBack();
    } else {
      let addNewTherapy = {};
      this.props.addTherapy(
        addNewTherapy,
        res => {
          if (res) {
            this.props.navigation.push(
              MEDICATIONS.addTherapiePath, {
                from: globalPath === DASHBOARD.path ? globalPath : this.props.route.name,
                fromManualEntry: true,
                globalPath: globalPath,
                fromPopup: fromPopup,
                activeTherapyTab: fromDashbordMeds ? 0 : null
              },
            );
          }
        },
        this.props.journalUserId,
      );
    }
  };

  handleOnPressBack = (eject = false) => {
    if (eject) {
      this.setState({eject: true});
      this.props.resetNewTherapie();
    }
    setTimeout(() => {
      if (eject) {
        this.props.resetNewTherapie();
      }
      if (this.state.fromPopup) {
        this.props.setFromPopup(this.state.fromPopup);
      }
      if(this.props.route?.params &&
        this.props.route?.params.from === MEDICATIONS.path) {
          this.props.navigation.reset({
            index: 0,
            routes: [{ name: MEDICATIONS.path }],
          });
      } else if (this.props.route?.params?.globalPath) {
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: this.props.route?.params?.globalPath }],
        });
      } else if (this.state.formHealthJourney) {
        const littleScreen = this.state.windowWidth < TAB_BREAk_POINT_END;
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: littleScreen ? HEALTH_JOURNEY.addEventPath : HEALTH_JOURNEY.path }],
        });
      } else {
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: DASHBOARD.path }],
        });
      }
    }, eject ? 2000 : 0);
  }

  catchWebcamErrors = (error) => {
    const { facingMode } = this.state;
    if (error && error.constraint === "facingMode") {
      if (facingMode === "user") {
        this.setState({ facingMode: { exact: 'environment' } });
      } else {
        this.setState({ facingMode: 'user' });
      }

    }

  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {formHealthJourney, eject} = this.state;
    let fullWidth = this.state.windowWidth;
    let fullHeight = this.state.windowHeight;
    let scanWidth = '100%';
    let scanHeight = 'auto';
    let headerHight = 0;
    headerHight = 57;
    if (this.props.journalUserId) {
      headerHight = 88;
    }
    let maxContHeight = fullHeight - headerHight;
    let positionStatus = 'absolute';
    let marginTop = 0;
    const videoConstraints = {
      facingMode: this.state.facingMode,
      width: {
        max: 1280,
      },
      height: {
        max: 720,
      },
    };
    let imageWidth = this.state.width;
    let imageHeight = this.state.height;
    let imageHeightResult = imageHeight;
    let imageWidthResult = imageWidth;
    if (fullWidth > TAB_BREAk_POINT_START) {
      scanWidth = 640;
    } else {
      if (imageHeight > maxContHeight) {
        imageHeightResult = maxContHeight;
        imageWidthResult = (imageWidth / imageHeight) * imageHeightResult;
      } else if (imageWidth > fullWidth) {
        imageWidthResult = imageWidth;
        imageHeightResult = (imageHeight / imageWidth) * imageWidthResult;
      }
      if (imageHeightResult > maxContHeight) {
        imageHeightResult = maxContHeight;
      }
      if (imageWidthResult > fullWidth) {
        imageWidthResult = fullWidth;
      }
    }
    let topSpace = '30%';
    if (fullHeight < 575) {
      topSpace = '15%';
    } 
    return (
      <View style={stylesRes.contentAreaFull} pointerEvents={eject ? "none" : "auto"}>
        <Header
          showFilers={false}
          showBack={false}
          showCancel={true}
          showClearFilters={false}
          showNotify={false}
          hideCaregiverAccess={true}
          handleOnPressBackButton={() => this.handleOnPressBack(true)}
          handleOnPressCancelButton={() => this.handleOnPressBack(true)}
          index={formHealthJourney ? HEALTH_JOURNEY.index : MEDICATIONS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <View
          style={[
            stylesRes.sectionWrapper,
            stylesRes.fixedScreen,
            {height: fullHeight},
          ]}>
          <SpaceBar />

          {this.state.showSpinner ? (
            <ActivityIndicator />
          ) : (
            <React.Fragment>
              <View style={[styles.scrollFixed, {height: maxContHeight}]}>
                <View style={styles.gridSection}>
                  <View style={stylesRes.contentSection}>
                    <View style={stylesRes.contentSectionFull}>
                      <View style={styles.gridRow}>
                        <View style={styles.gridColFull}>
                          <View style={stylesRes.scanFullArea}>
                            <View
                              style={[
                                stylesRes.frameArea,
                                stylesRes.frameAreaImg,
                              ]}>
                              <View style={stylesRes.colPdTopLtRtCom} dataSet={{media: ids.colPdTopLtRtCom}}>
                                <View style={[styles.frameAreaTop]}>
                                  <View
                                    style={{
                                      // marginLeft: "auto",
                                      // marginRight: "auto",
                                      minHeight: 120,
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}>
                                    {!this.state.reqFailed ? (
                                      <React.Fragment>
                                        {!this.state.resetCameraView ? (
                                          <TouchableOpacity
                                            onPressIn={this.changeMode}>
                                            <Webcam
                                              audio={false}
                                              width={scanWidth}
                                              height={scanHeight}
                                              ref={this.setRef}
                                              screenshotFormat="image/jpeg"
                                              // videoConstraints={this.state.videoConstraints}
                                                onUserMediaError={(e) => this.catchWebcamErrors(e)}
                                              videoConstraints={
                                                videoConstraints
                                              }
                                            />
                                          </TouchableOpacity>
                                        ) : (
                                          <Text allowFontScaling={false}>Loading...</Text>
                                        )}
                                        <View
                                          style={[
                                            stylesRes.frameLine,
                                            {
                                              width: scanWidth,
                                              minHeight: 70,
                                              top: topSpace,
                                            },
                                          ]}
                                        />
                                      </React.Fragment>
                                    ) : (
                                      <View>
                                        <Image
                                          source={{
                                            uri: this.state.screenshot,
                                          }}
                                          style={{
                                            width: imageWidthResult,
                                            height: imageHeightResult,
                                          }}
                                        />
                                        <View
                                          style={[
                                            stylesRes.frameLine,
                                            {
                                              width: scanWidth,
                                              minHeight: 70,
                                              top: topSpace,
                                            },
                                          ]}
                                        />
                                      </View>
                                    )}
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View
                style={[
                  stylesRes.frameArea,
                  stylesRes.frameAreaContView,
                  {position: positionStatus, marginTop: marginTop},
                ]}>
                <View style={[styles.colPdLtRt]}>
                  <View style={[styles.frameAreaBot]}>
                    <View style={[stylesRes.frameViewBot]}>
                      <View
                        style={[
                          stylesRes.frameViewCom,
                          stylesRes.frameBtnMinHeight,
                        ]}>
                          {Boolean(this.state.reqFailed) && (
                          <View style={[styles.btnArea, styles.btnRowMgSm]}>
                            <View style={[styles.btnBlock, styles.noMgTop]}>
                              <TouchableOpacity
                                style={[styles.submit]}
                                underlayColor="rgba(0, 153, 168, 0.8)"
                                onPress={this.redirectScreen}>
                                <View style={styles.btnSingleView}>
                                  <View
                                    style={[
                                      styles.btnSingle,
                                      styles.btnSingleCent,
                                    ]}>
                                    <UIText
                                      style={styles.submitText}
                                      title="Enter Manually"
                                    />
                                  </View>
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        )}
                      </View>

                      <View style={stylesRes.frameViewBtn}>
                        <TouchableOpacity
                          onPress={
                            !this.state.reqFailed
                              ? this.capture
                              : this.resetView
                          }
                          disabled={this.state.showSpinner}>
                          <View style={[stylesRes.frameBtnFull]}>
                            <View style={[stylesRes.frameBtn]}>
                              <Text
                                style={[
                                  styles.textPrimeExSmBold,
                                  styles.textAlignCenter,
                                  styles.textBlack,
                                ]}
                                allowFontScaling={false}
                                >
                                {this.state.reqFailed
                                  ? 'Retake'
                                  : 'Take \n photo'}
                              </Text>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>
                      <View style={stylesRes.frameViewText}>
                        <View style={stylesRes.frameTextFull}>
                          <View style={stylesRes.frameTextBtn}>
                            <Text
                              style={[
                                styles.textPrimeBold,
                                styles.textAlignCenter,
                                styles.textWhite,
                              ]}
                              allowFontScaling={false}
                              >
                              {this.state.reqFailed
                                ? 'Sorry, there was an error. Retake photo.'
                                : 'Ensure the DIN or NPN is within the frame.'}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </React.Fragment>
          )}
        </View>
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.routines.user,
    treatments: state.routines.treatments,
    contacts: state.routines.contacts,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
    newTherapy: state.therapie.newTherapie,
    eventData: state.uistate.eventData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateContact: (addNewContact, contacts, callBack) =>
      dispatch(actions.updateContact(addNewContact, contacts, callBack)),
    scanPrescription: (base64, callback) =>
      dispatch(actions.scanPrescription(base64, callback)),
    updateTherapy: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.updateTherapie(addNewTherapie, callBack, journalUserId)),
    getPrescription: (jobId, callBack) =>
      dispatch(actions.getPrescriptionWithRetry(jobId, callBack)),
    addTherapy: (addNewTherapy, callBack, journalUserId) =>
      dispatch(actions.createTherapie(addNewTherapy, callBack, journalUserId)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    uploadImageToOCR: (formData, callBack) =>
      dispatch(actions.startAttachmentsScan(formData, callBack)),
    uploadImageToBackend: (
      artifactId,
      formData,
      callBack,
      reload,
      journalUserId,
      fileSize,
    ) =>
      dispatch(
        actions.uploadAttachmen(
          artifactId,
          formData,
          callBack,
          reload,
          journalUserId,
          fileSize,
        ),
      ),
    setFromPopup: fromPopup => dispatch(actions.setFromPopup(fromPopup)),
    resetNewTherapie: () => dispatch(actions.resetNewTherapie()),
    deleteTherapy: (id, callBack, journalUserId) =>
      dispatch(actions.deleteTherapie(id, callBack, journalUserId)),
    setEventData: data => dispatch(actions.setEventData(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScanPrescription);
