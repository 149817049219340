import React from "react";
import {
  View,
  TouchableOpacity,
  Text,
  TextInput,
  Image,
  Platform,
} from "react-native";
import Header from "../Header/header";
import SpaceBar from "../SpaceBar/SpaceBar";
import PlusButton from "../Button/plusButton";
import Modal from "../Modal/modal";
import AddMethodforEntry from "../ConfirmationBox/addMethodforEntry";
import AddDocumentTypeEntry from "../ConfirmationBox/addDocumentTypeEntry";
import PrimaryButton from "../Button/primaryButton";
import InputScrollViewHoc from "../InputScrollViewHoc/InputScrollViewHoc";
import StorageBanner from "../Banner/StorageBanner";
import PageContainer from "../PageLayout/PageContainer";
import InputField from "../TextInput/inputFieldSpec";
import DateTimePicker from "../DateTimePicker/DateTimePicker";
import CancelButton from "../Button/cancelButton";
import ImageTakePhotoPopup from "../Attachment/AttachmentImageBox/imageTakePhotoPopup";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import * as FieldDetails from "../TextInput/fieldDetails";
import { findThumbnail } from '../../util/commonUiLogic';
import { WEB } from "../../util/platformWindowConfig";

const addQuickNote = (props) => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  return (
    <View style={[styles.pageBodyWrapper]}>
      {/* HEADER VIEW + NAVIGATION */}
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={true}
        handleOnPressBackButton={props.handleOnPressBackButton}
        index={props.index}
        navigation={props.navigation}
        route={props.route}
      />

      <SpaceBar />
      <StorageBanner />
      <InputScrollViewHoc>
        <View style={styles.gridSection}>
          <PageContainer>
            <View style={stylesRes.layoutTopPdFixed}>
              {/* SCREEN TITLE + CLOSE BUTTON */}
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View
                    style={[
                      styles.titleWrapper,
                      styles.comMgBotMd,
                      styles.flexRow,
                      styles.flexJcSb,
                      styles.flexAiCt,
                    ]}
                  >
                    <View style={styles.titleView}>
                      <Text style={styles.textTitleBold} allowFontScaling={false}>
                        Write a Quick Note
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.calSectionHeader,
                        styles.flexRow,
                        styles.flexAiCt,
                        styles.flexComDateSmCom,
                      ]}
                    >
                      <View style={[styles.flexCom, styles.flexAiCt]}>
                        <View style={styles.textInputAreaView}>
                          <View
                            style={[styles.simpleBtnShadow, styles.bgWhite]}
                          >
                            <DateTimePicker
                              // date={date}
                              customTouchableComponent={
                                props.customTouchableComponent
                              }
                              onConfirm={props.onConfirm}
                              // clearCancelOff={true}
                              disabled={props.showSpinner}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              {/* TEXT INPUT */}

              <View style={styles.gridRow}>
                <View style={styles.fieldRow}>
                  <View style={styles.fieldRowWrapperFull}>
                    {/* TextInput */}
                    <InputField
                      title={"Entry Name"}
                      fieldLt={true}
                      fieldBg={FieldDetails.BGWHITE}
                      placeholder={"Quick note"}
                      value={props.quickNoteJournalName}
                      onChangeText={(text) => props.onChangeJournalName(text)}
                      removeIcon={Boolean(props.quickNoteJournalName.length) && !props.showSpinner}
                      touchablePress={props.clearJournalName}
                      editable={!props.showSpinner}
                    />
                  </View>
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={styles.fieldRow}>
                  <View
                    style={[styles.textEditorView, Platform.OS !== WEB && styles.textEditorHeightSm]}
                    nativeID={"resizeTextArea"}
                  >
                    <TextInput
                      style={[
                        styles.textAreaFieldAuto,
                        Platform.OS === WEB
                          ? styles.textEditorHeightLg
                          : styles.textEditorHeightSm,
                        styles.fieldShadowCom,
                        styles.bgWhite,
                        { height: props.height, 
                          ...Platform.select({
                            android: {
                              textAlignVertical: "top"
                            },
                          })
                        },
                      ]}
                      allowFontScaling={false}
                      multiline={true}
                      value={props.quickNote}
                      onChangeText={(text) => props.onChangeQuickNote(text)}
                      underlineColorAndroid="transparent"
                      onContentSizeChange={(e) => {
                        props.updateSize(e.nativeEvent.contentSize.height);
                      }}
                      placeholder={"Enter your note here"}
                      // numberOfLines={6}
                      editable={!props.showSpinner}
                    />
                  </View>
                </View>
              </View>

              {/* LIST OF ATTACHMENTS */}
              <View style={styles.detailViewWrap}>
                {Object.keys(props.files) &&
                  Object.keys(props.files).map((res) => (
                    <View
                      style={[styles.attachmentRow]}
                      key={"image_id_" + res}
                    >
                      <View
                        style={[
                          styles.attachmentDetSec,
                          styles.attachRowPdSm,
                          styles.borderThin,
                        ]}
                      >
                        <View
                          style={[
                            styles.attachmentDet,
                            styles.attachmentDetMax,
                            styles.attachSp,
                          ]}
                        >
                          <View
                            style={[
                              styles.attachmentImgDet,
                              styles.attachmentImgDetMax,
                            ]}
                          >
                            <View
                              style={[
                                styles.attachImgAvailable,
                                styles.borderNone,
                              ]}
                            >
                              <Image
                                style={styles.attachmentPreviewIcon}
                                source={
                                  findThumbnail(props.files[res].name)
                                }
                              />
                            </View>
                          </View>
                          <View
                            style={[
                              styles.attachmentCont,
                              styles.attachmentContMax,
                            ]}
                          >
                            <Text style={styles.attachmentCont} allowFontScaling={false}>
                              {props.files[res].name}
                            </Text>
                          </View>
                        </View>

                        {!props.showSpinner && 
                          <View
                            style={[
                              styles.attachmentBtn,
                              styles.attachmentIconSec,
                            ]}
                          >
                            <TouchableOpacity
                              onPress={() => props.handleDeleteAttachment(res)}
                              style={styles.attachIconTouch}
                            >
                              <View style={styles.attachmentIcon}>
                                <Image
                                  style={styles.delLgIcon}
                                  source={require("../../assets/icons/remove.png")}
                                />
                              </View>
                            </TouchableOpacity>
                          </View>
                        }
                      </View>
                    </View>
                  ))}
              </View>

              {/* ADD ATTACHMENT BUTTON */}
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={[styles.btnArea, styles.greyBorderThin]}>
                    <PlusButton
                      btnPosition={"left"}
                      btnText={"Attachment"}
                      touchableOnPress={props.handleOnPressAddAttachmentButton}
                      disabled={props.showSpinner}
                    />
                  </View>
                </View>
              </View>
              {/* SAVE BUTTON */}
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={[styles.secSubArea, styles.comMgTopExLg]}>
                    <View style={styles.btnArea}>
                      <View style={styles.flexRowJcCtAiCt}>
                        <View style={stylesRes.btnViewSec}>
                          <View style={styles.fieldBtn}>
                            <CancelButton 
                              touchableOnpress={props.handleOnPressBackButton}
                              disabled={props.showSpinner}
                            />
                          </View>
                          <View style={styles.fieldBtnSpSm} />
                          <View style={styles.fieldBtn}>
                            <PrimaryButton
                              btnText={"Save"}
                              disabled={
                                props.showSpinner || 
                                (props.quickNote.length === 0 &&
                                  Object.keys(props.files).length === 0)
                              }
                              activityInd={props.showSpinner}
                              touchableOnPress={props.handleOnPressSaveButton}
                              btnShadow={true}
                              btnStyle={[styles.primaryButtonLg]}
                              nativeId={'quickNoteSave'}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              {Platform.OS !== WEB && <View style={{height: 150}} />}
            </View>
          </PageContainer>
        </View>
      </InputScrollViewHoc>

      {/* Modal ==> Take photo or Attach Image/File - Mobile/Web */}
      {props.visibleEntryModal &&
      <Modal
        visible={props.visibleEntryModal}
        onRequestClose={props.onRequestClose}
        onBackdropPress={props.onBackdropPress}
        // defaultStyle={[styles.customPopUpStyle, styles.mgTopUpload]}
      >
        <AddMethodforEntry
          onPressClose={props.handleOnPressAttachmentRemoveButton}
          onPressUploadEntry={props.onPressUploadEntry} // SIRI::TODO // Clarify
          onPressTakePhoto={props.onPressTakePhoto}
          onChange={(e) => {
            props.onChangeFileUpload(e);
          }}
          selected={props.selected}
        />
      </Modal>
      }

      {/* Modal ==> Select upload attachment type (file/image) - Mobile */}
      {Platform.OS !== WEB && props.visibleUploadTypeModal &&
      <Modal
        visible={props.visibleUploadTypeModal}
        onRequestClose={props.onRequestCloseUploadTypeModal}
        onBackdropPress={props.onBackdropPressUploadTypeModal}
      >
        <AddDocumentTypeEntry
          onPressCancel={props.onPressCancel}
          onPressDocumentType={(type) => props.selectDocumentType(type)}
          defaultStyle={[styles.customPopUpBottomStyle]}
        />
      </Modal>
      }
      {/* Modal ==> Take photo - WEB */}
      {Platform.OS === WEB && props.visibleImageTakePhoto && (
      <Modal
        visible={props.visibleImageTakePhoto}
        onRequestCloseImg={props.onRequestCloseImg}
        onBackdropPressImg={props.onBackdropPressImg}
      >
          <ImageTakePhotoPopup
            cropSuccess={props.cropSuccess}
            onCropAddImage={(e, image64) => {
              props.onChangeFileUpload(e, image64);
            }}
            artifactId={false}
            close={props.close}
          />
      </Modal>
      )}
    </View>
  );
};

export default addQuickNote;