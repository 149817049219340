import React from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import styles from "../../../../Css";
import stylesResponsive from "../../../../CssResponsive";
import JournalEntryPopupMenu from "../../../PopupMenu/journalEntryPopupMenu";
import moment from "moment";
import PageContainer from "../../../../components/PageLayout/PageContainer";
import * as FontScaling from "../../../../components/Text/fontScaling";
import MouseHover from "../../../../components/MouseHover";
import Webview from "../../../Webview/webview";
import FieldTitle from "../../../TextInput/fieldTitle";
import * as FieldDetails from "../../../TextInput/fieldDetails";
import { showJournalTitle } from '../../../../screens/Routines/Util/JournalEntryUtils';
import { APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED } from '../../../../util/featureConfig';

const journalEntryHeader = (props) => {
  const { styles: stylesRes } = stylesResponsive.getProcessedStyles();
  const {
    journalEntry,
    journalEntryTypes,
    handleDeleteJournal,
    handleEditJournal,
    handleCopytoNew,
    changeFlagged,
    iconStatus,
    handleConvertToRoutine,
    deviceJournal,
  } = props;
  const { entryDate, flagged, description, reminderDateTime = null, createdByNotification = false } = journalEntry;
  let color = null;
  let icon_c = null;
  if (journalEntryTypes) {
    color = journalEntryTypes.color;
    icon_c = journalEntryTypes?.icon_c;
  }

  return (
    <React.Fragment>
      <View style={[styles.journalTopicSection, styles.topicBlock]}>
        <View style={styles.gridRow}>
          <View style={styles.gridColFull}>
            <View style={[styles.topicFullBlock]}>
              <PageContainer outlookScreen={true}>
                <View style={[styles.topicBarWrapper]}>
                  <View style={[styles.flexRowAiCt, styles.flexCom]}>
                    <View style={styles.flexEndCenter}>
                      <View style={[styles.flexRowJcCtAiCt, styles.rtPdMd]}>
                        <View
                          style={[
                            styles.itemIconBox,
                            styles.itemIconBoxSm,
                            { backgroundColor: color },
                          ]}
                        >
                          {icon_c || deviceJournal
                            ? <Image
                              style={[
                                styles.itemIconBox,
                                styles.itemIconBoxSm,
                              ]}
                              source={icon_c
                                ? { uri: icon_c }
                                : require('../../../../assets/devices/hexoskin-journal-logo.png')
                              }
                            />
                            : <Image
                              style={styles.itemIcon}
                              source={require("../../../../assets/journal-icons/report.png")}
                            />
                          }
                        </View>
                        <View style={styles.ltPdMd}>
                          <Text
                            style={styles.textTitleSmBold}
                            allowFontScaling={false}
                            maxFontSizeMultiplier={FontScaling.fontScaleRg}
                          >
                            {showJournalTitle(journalEntry, journalEntryTypes)}
                          </Text>
                          <Text
                            style={[styles.textPrimeMd, styles.textColorLight]}
                            allowFontScaling={false}
                            maxFontSizeMultiplier={FontScaling.fontScaleMd}
                          >
                            {moment(entryDate).format("DD MMM 'YY h:mmA")}
                          </Text>
                        </View>
                      </View>

                      {/* <View>
                        {Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED) && Boolean(reminderDateTime) &&
                          <Text style={styles.textPrime} allowFontScaling={false}>
                            Entry for reminder on {`${moment(reminderDateTime).format("DD MMM 'YY h:mm A")}`}
                          </Text>
                        }
                      </View> */}
                    </View>
                  </View>
                  <View
                    style={[
                      styles.iconSectionWrapper,
                      !iconStatus ? styles.iconSectionLg : styles.iconSectionSm,
                    ]}
                  >
                    <MouseHover id={"favourite"} text={"Add to Favourite"}>
                      <TouchableOpacity
                        style={[styles.itemIconSingle]}
                        onPress={changeFlagged}
                        nativeID={'journalsStar'}
                      >
                        {flagged ? (
                          <Image
                            style={styles.starIconMd}
                            source={require("../../../../assets/icons/star-status.png")}
                          />
                        ) : (
                          <Image
                            style={[styles.starIconMd, styles.starInactive]}
                            source={require("../../../../assets/icons/star-status.png")}
                          />
                        )}
                      </TouchableOpacity>
                    </MouseHover>
                    {!Boolean(iconStatus) && (
                      <>
                        <MouseHover id={"copy"} text={"Copy to New"}>
                          <TouchableOpacity
                            style={[styles.itemIconSingle]}
                            onPress={handleCopytoNew}
                            disabled={deviceJournal}
                            nativeID={'journalsCopyNew'}
                          >
                            <Image
                              style={styles.duplicateIcon}
                              source={require("../../../../assets/icons/duplicate.png")}
                            />
                          </TouchableOpacity>
                        </MouseHover>

                        <MouseHover id={"convert"} text={"Convert to Routine"}>
                          <TouchableOpacity
                            testID="convertToRoutineBtn"
                            style={[styles.itemIconSingle]}
                            onPress={handleConvertToRoutine}
                            disabled={deviceJournal}
                            nativeID={'journalsConvert'}
                          >
                            <Image
                              id="convertToRotine"
                              style={styles.convertIcon}
                              source={require("../../../../assets/icons/convert.png")}
                            />
                          </TouchableOpacity>
                        </MouseHover>
                      </>
                    )}

                    <JournalEntryPopupMenu
                      handleDeleteJournal={handleDeleteJournal}
                      handleEditJournal={handleEditJournal}
                      handleCopytoNew={handleCopytoNew}
                      iconStatus={iconStatus}
                      handleConvertToRoutine={handleConvertToRoutine}
                      deviceJournal={deviceJournal}
                    />
                  </View>

                </View>
                {Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED) && Boolean(createdByNotification) && Boolean(reminderDateTime) &&
                  <View style={styles.colPdBotExSm}>
                    <Text style={styles.textPrime} allowFontScaling={false}>
                      Entry for reminder on {`${moment(reminderDateTime).format("DD MMM 'YY h:mm A")}`}
                    </Text>
                  </View>
                }
              </PageContainer>
            </View>
          </View>
        </View>
      </View>

      {description !== "" && description != null && (
        <View style={[stylesRes.contentViewWrapperFull, stylesRes.tabBorder]}>
          <PageContainer outlookScreen={true}>
            <View style={[styles.journalContentWrapper, styles.colPdTop]}>
              <View style={styles.pdBotLg}>
                <View style={[styles.postRelative]}>
                  <FieldTitle
                    fieldAlign={props.fieldAlign}
                    fieldBg={FieldDetails.BGGREY}
                    title={"Routine Description"}
                    fieldLt={true}
                  />
                  <View
                    style={[
                      styles.textInputField,
                      styles.bgTransparent,
                      styles.bgThinGrey,
                      { height: "auto" },
                    ]}
                  >
                    <View
                      style={[
                        //!seeMore && { height: 50 },
                        //{ overflow: "hidden" },
                        styles.flexJcCt,
                        styles.bgThinGrey
                        //styles.bgTransparent
                      ]}
                    //nativeID={"richTextView"}
                    />
                    <Webview htmlContent={description} />
                  </View>
                </View>
                <View style={[styles.moreDescView]} />
              </View>
            </View>
          </PageContainer>
        </View>
      )}
    </React.Fragment>
  );
};

export default (journalEntryHeader);
