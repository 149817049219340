import theme from "./base/theme";
export const cssPage = {
  containerCollapsible: {
    backgroundColor: theme.COLOR_WHITE,
    overflow: "hidden",
    zIndex: 9
  },
  notifyMax: {
    maxHeight: 200,
    overflowY: "auto"
  },
  scrollableArea: {
    height: "auto",
    overflowY: "auto"
  },
  scrollFixed: {
    overflowY: "hidden"
  },
  dataIconSecAccess: {
    maxWidth: 170,
    flexBasis: 170,
    alignItems: "flex-end"
  },
  dataAccessMinWidth: {
    minWidth: 120
  },
  textReq: {
    position: "absolute",
    right: -7,
    top: -2,
    color: theme.COLOR_WARNING
  },
  textReqFrame: {
    right: 0,
  },
  customMgTopProfile: {
    marginTop: 194
  },
  mgTopUpload: {
    marginTop: 100
  },
  viewDataSearchFull: {
    flex: 1
  },
  viewSearchSec: {
    flexDirection: "row",
    flex: 1
  },
  resourceBorderApp: {
    display: "none"
  },
  deviceTextLg: {
    paddingBottom: 3
  },
  autoCompleteListHeight: {
    maxHeight: 150,
    overflow:'auto'
  },
  autoCompleteUnitHeight: {
    maxHeight: 115,
    overflow:'auto'
  },
  topSpForHeader: {
    marginTop: 57,
  },
  calTopSpLg: {
    marginTop: 130,
  },
  customScrollNested: {
    overflowY: 'auto',
    overflowX: "hidden"
  },
  customScrollView: {
    overflowY: 'auto',
    overflowX: "hidden"
  },
  graphSecScrollView: {
    overflowY: 'auto',
    overflowX: "hidden",
    maxHeight: 100,
  },
  overlayFixed: {
    position: "fixed"
  },
  truncateMaxName: {
    maxWidth: '56%',
  },
  listTrucatePd: {
    alignItems:'flex-end',
    minWidth: 95,
    textAlign: 'right'

  },
  systemListTextRightPd: {
    paddingRight: 5
  },
  scrollingOp: {
    overflow:'auto',
    maxHeight: 110, 
  },
  healthResourceMsgContainer: {
    width:'100%', 
    flex:1,
    paddingLeft: 14, 
    paddingRight: 0, 
    paddingTop:0, 
    paddingBottom: 0, 
    borderRadius: 5, 
    borderWidth:1, 
    borderColor:theme.COLOR_BORDER_MEDIUM_2, 
    marginTop:12, 
  },

  scrollSecContent: {
    paddingTop:10, 
    paddingBottom: 15, 
    paddingRight: 14
  },

  scrollInsideContent:{
    maxHeight:100, 
    overflow:'auto'
  },

  overflowHidden:{
    overflow:'hidden'
  },
  overflowAuto: {
    overflow: 'auto'
  },
};
