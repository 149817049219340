import React, {Component} from 'react';
import {View, Image, TouchableOpacity, Platform} from 'react-native';
import stylesResponsive from '../../CssResponsive';
import styles from '../../Css';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import HelpInviteCareGiver from '../../components/Tutorial/helpInviteCareGiver';
import HelpMeasurement from '../../components/Tutorial/helpMeasurement';
import HelpContact from '../../components/Tutorial/helpContact';
import HelpProfileCondition from '../../components/Tutorial/helpProfileCondition';
import HelpSurvey from '../../components/Tutorial/helpSurvey';
import { ANDROID, IOS, TAB_BREAk_POINT_START } from '../../util/platformWindowConfig';

class Tutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTutorial: false,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
    };
  }

  componentDidMount = () => {
    this.setState({
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
      showTutorial: this.props.showTutorial,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {windowHeight, windowWidth, showTutorial} = nextProps;
    this.setState({
      windowWidth,
      windowHeight,
      showTutorial,
    });

    if (showTutorial) {
      setTimeout(() => {
        if (this.state.showTutorial) {
          this.props.onShowTutorial(null);
        }
      }, 10000);
    }
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    let fullWidth = this.state.windowWidth;
    let toolTipWidth = 1185;
    let toolTipHeight = 135;
    let bgHeight = 0;
    // if (fullWidth > 1440) {
    //   toolTipWidth = 1440 - 255;
    // } else if (fullWidth > 991) {
    //   toolTipWidth = fullWidth - 255;
    // } else {
    //   toolTipWidth = fullWidth;
    // }
    if (fullWidth > TAB_BREAk_POINT_START) {
      toolTipWidth = fullWidth - 255;
    } else {
      toolTipWidth = fullWidth;
    }
    if (Platform.OS === IOS || Platform.OS === ANDROID) {
      toolTipWidth = fullWidth;
    }

    toolTipHeight = (135 / 1185) * toolTipWidth;
    if (toolTipHeight < 120) {
      bgHeight = 120 - toolTipHeight;
    }

    let tutorial = null;
    switch (this.props.tutorialType) {
      case 4:
        tutorial = <HelpInviteCareGiver />;
        break;
      case 5:
        tutorial = <HelpMeasurement />;
        break;
      case 7:
        tutorial = <HelpContact />;
        break;      
      case 10:
        tutorial = <HelpProfileCondition />;
        break;     
      case 17:
        tutorial = <HelpSurvey />;
        break;
      default:
        break;
    }
    return (
      <React.Fragment>
        {Boolean(this.state.showTutorial) && (
          <View style={[stylesRes.tutorialToolTip]} dataSet={{media: ids.tutorialToolTip}}>
            <View style={[stylesRes.tutorialBgContent]}>
              <View
                style={[
                  stylesRes.tutorialToolTipBg,
                  {width: toolTipWidth, height: toolTipHeight},
                ]}>
                <Image
                  style={styles.imgCover}
                  source={require('../../assets/background-images/tooltip-top.png')}
                />
              </View>
              <View style={[stylesRes.tutorialToolBgCom, {height: bgHeight}]} />
              <View style={[stylesRes.tutorialContentFull]} dataSet={{media: ids.tutorialContentFull}}>
                <View style={[stylesRes.tutorialContent]} dataSet={{media: ids.tutorialContent}}>
                  <View style={[stylesRes.tutorialClose]}>
                    <View
                      style={[styles.listItemCloseIcon, styles.closeIconSp]}>
                      <TouchableOpacity
                        onPress={() => this.props.onShowTutorial(null)}>
                        <View style={[stylesRes.toolTipClosePd]}>
                          <Image
                            style={styles.closeDarkIcon}
                            source={require('../../assets/icons/close-dark.png')}
                          />
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View style={[stylesRes.toolTipData]}>{tutorial}</View>
                </View>
              </View>
            </View>
          </View>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    showTutorial: state.uistate.showTutorial,
    tutorialType: state.uistate.tutorialType,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShowTutorial: tutorialType =>
      dispatch(actions.showTutorial(tutorialType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Tutorial);
