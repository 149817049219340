import React, {Component} from 'react';
import {
  StyleSheet, // CSS-like styles
  Text, // Renders text
  TouchableOpacity, // Pressable container
  View, // Container component
  Platform,
} from 'react-native';
import {connect} from 'react-redux';
import PageContainer from '../../components/PageLayout/PageContainer';
import * as actions from '../../store/actions/index';
import { ANDROID, IOS } from '../../util/platformWindowConfig';

class Tabs extends Component {
  // Initialize State
  state = {
    // First tab is active by default
    activeTab: this.props.activeTab ? this.props.activeTab : 0,
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (
      props.activeTab !== undefined &&
      this.state.activeTab !== props.activeTab
    ) {
      this.setState({activeTab: props.activeTab});
    }
  }

  // Pull children out of props passed from App component
  render({children, tabIndex, p2pProfileHasDataToSave} = this.props) {
    return (
      // <View style={styles.container}>
      // {/* Tabs row */}
      <React.Fragment>
        {this.props.pageContainer ? (
          <PageContainer
            smScreenFull={this.props.smScreenFull}
            outlookScreen={this.props.outlookScreen}
            screenFull={this.props.screenFull}>
            <View
              style={[
                styles.tabsContainer,
                this.props.outlookTabView
                  ? [styles.tabComView, styles.tabComBorderView]
                  : [],
              ]}>
              {/* Pull props out of children, and pull title out of props */}
              {children.map(
                (
                  {
                    props: {
                      title,
                      activeCircle,
                      displayNotifCount,
                      notifCount,
                      showOnTab,
                      nativeId,
                      isFromHealthJourney
                    },
                  },
                  index,
                ) => (
                  <TouchableOpacity
                    nativeID={nativeId}
                    style={[
                      // Default style for every tab
                      styles.tabContainer,
                      this.props.outlookTabViewSmall
                        ? styles.tabBorderCom
                        : null,
                      styles.tabContCenter,
                      // Merge default style with styles.tabContainerActive for active tab
                      index === this.state.activeTab &&
                      this.props.outlookTabView
                        ? styles.tabComBorderSelectedView
                        : this.props.outlookTabView
                        ? styles.tabComBorderDefaultView
                        : index === this.state.activeTab
                        ? styles.tabContainerActive
                        : [],
                    ]}
                    // Change active tab
                    onPress={() => {
                      if (p2pProfileHasDataToSave) {
                        if (tabIndex) {
                          tabIndex();
                        }
                      } else {
                        if (tabIndex) {
                          tabIndex(index);
                        }
                        this.setState({activeTab: index});
                        if (title === "Profile" || isFromHealthJourney) {
                          this.props.hideMobileMenu();
                        } else {
                          this.props.showMobileMenu();
                        }
                      }
                    }}
                    // Required key prop for components generated returned by map iterator
                    key={index}>

                    <View
                      style={[
                        styles.tabTextSection,
                        styles.tabTextMgZero,
                      ]}>
                      <Text
                        style={[
                          styles.tabText,
                          index === this.state.activeTab
                            ? styles.tabTextActive
                            : styles.tabTextDefault,
                        ]}
                        // maxFontSizeMultiplier={FontScaling.fontScaleRg}
                        allowFontScaling={false}
                        >
                        {title}
                      </Text>
                      {Boolean(displayNotifCount) && (index !== this.state.activeTab || showOnTab) &&
                        <View style={styles.countCircle}>
                          <View style={styles.countNoWrapper}>
                            <Text style={styles.countText} allowFontScaling={false}>{notifCount}</Text>
                          </View>
                        </View>
                      }
                      <View style={activeCircle ? styles.activeCircle : null} />
                    </View>
                  </TouchableOpacity>
                  // Add 'activeCircle' for the Attachments
                ),
              )}
            </View>
          </PageContainer>
        ) : (
          <View style={styles.tabsContainer}>
            {/* Pull props out of children, and pull title out of props */}
            {children.map(
              (
                {
                  props: {
                    title,
                    activeCircle,
                    displayNotifCount,
                    notifCount,
                    showOnTab,
                    nativeId,
                    isFromHealthJourney
                  },
                },
                index,
              ) => (
                <TouchableOpacity
                  nativeID={nativeId}
                  style={[
                    // Default style for every tab
                    styles.tabContainer,
                    styles.tabContCenter,
                    // Merge default style with styles.tabContainerActive for active tab
                    index === this.state.activeTab
                      ? styles.tabContainerActive
                      : [],
                  ]}
                  // Change active tab
                  onPress={() => {
                    if (p2pProfileHasDataToSave) {
                      if (tabIndex) {
                        tabIndex();
                      }
                    } else {
                      if (tabIndex) {
                        tabIndex(index);
                      }
                      this.setState({activeTab: index});
                      if (title === "Profile" || isFromHealthJourney) {
                        this.props.hideMobileMenu();
                      } else {
                        this.props.showMobileMenu();
                      }
                    }
                  }}
                  // Required key prop for components generated returned by map iterator
                  key={index}>

                  <View
                    style={[
                      styles.tabTextSection,
                      styles.tabTextMgZero,
                    ]}>
                    <Text
                      style={[
                        styles.tabText,
                        index === this.state.activeTab
                          ? styles.tabTextActive
                          : styles.tabTextDefault,
                      ]}
                      allowFontScaling={false}
                      >
                      {title}
                    </Text>
                    {Boolean(displayNotifCount) && (index !== this.state.activeTab || Boolean(showOnTab)) &&
                      <View style={styles.countCircle}>
                        <View style={styles.countNoWrapper}>
                          <Text style={styles.countText} allowFontScaling={false}>{notifCount}</Text>
                        </View>
                      </View>
                    }
                    <View style={activeCircle ? styles.activeCircle : null} />
                  </View>
                </TouchableOpacity>
                // Add 'activeCircle' for the Attachments
              ),
            )}
          </View>
        )}

        {/* Content */}
        {children[this.state.activeTab]}
      </React.Fragment>
      // {/* </View> */}
    );
  }
}

const mapStateToProps = state => {
  return {
    p2pProfileHasDataToSave: state.peerToPeer.p2pProfileHasDataToSave,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Tabs);

const styles = StyleSheet.create({
  // Component container
  containerTabView: {
    flex: 1, // Take up all available space
  },
  // Tabs row container
  tabsContainer: {
    flexDirection: 'row', // Arrange tabs in a row
  },
  // Individual tab container
  tabContainer: {
    flex: 1, // Take up equal amount of space for each tab
    paddingVertical: 12, // Vertical padding
    borderBottomWidth: 3, // Add thick border at the bottom
    borderBottomColor: '#F4ECD7', // Transparent border for inactive tabs
  },
  // Active tab container
  tabContainerActive: {
    borderBottomColor: '#ff8300', // White bottom border for active tabs
  },
  // Tab text
  tabText: {
    textAlign: 'center',
    position: 'relative',
    fontFamily:
      Platform.OS === IOS || Platform.OS === ANDROID
        ? 'Asap'
        : 'Asap, sans-serif',
  },
  tabTextDefault: {
    fontWeight: 'normal',
    color: '#98989a',
  },
  tabTextActive: {
    fontWeight: 'bold',
    color: '#404041',
  },
  // Content container
  contentContainer: {
    flex: 1, // Take up all available space
  },
  content: {
    flex: 1, // Take up all available space
    justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    backgroundColor: '#C2185B', // Darker background for content area
  },
  // Content header
  header: {
    margin: 10, // Add margin
    color: '#000000', // White color
    fontSize: 26, // Bigger font size
  },
  // Content text
  text: {
    marginHorizontal: 20, // Add horizontal margin
    color: '#000000', // Semi-transparent text
    textAlign: 'center', // Center
    fontSize: 18,
  },
  activeCircle: {
    width: 6,
    height: 6,
    borderRadius: 6,
    backgroundColor: '#ff8300',
    marginBottom: 6,
    // position: "absolute",
    // top: 4,
    // left: 0
  },
  tabTextSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabPlusAttach: {
    position: 'absolute',
    left: 0,
    width: 38,
    height: 38,
    marginRight: 10,
    // top: 0,
    // bottom: 0,
    // height: "100%",
    // alignItems: "center",
    // justifyContent: "center",
    // flex: 1,
  },
  attachPlusIcon: {
    width: 38,
    height: 38,
  },
  tabTextPd: {
    paddingLeft: 48,
  },
  tabTextPdZero: {
    paddingLeft: 0,
  },
  tabTextMgZero: {
    paddingLeft: 0,
  },
  tabContCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabComView: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  tabComBorderView: {
    borderBottomWidth: 1, // Add thick border at the bottom
    borderBottomColor: '#E8E8E8', // Transparent border for inactive tabs
  },
  tabComBorderSelectedView: {
    borderBottomWidth: 3, // Add thick border at the bottom
    borderBottomColor: '#ff8300', // Transparent border for inactive tabs
  },
  tabComBorderDefaultView: {
    borderBottomWidth: 3, // Add thick border at the bottom
    borderBottomColor: 'transparent', // Transparent border for inactive tabs
  },
  tabBorderCom: {
    borderBottomWidth: 3, // Add thick border at the bottom
    borderBottomColor: '#E8E8E8', // Transparent border for inactive tabs
  },
  countCircle: {
    width: 16,
    height: 16,
    borderRadius: 16,
    backgroundColor: '#FF9E38',
    marginBottom: 6,
    // marginTop: -2,
    marginLeft: 1
  },
  countNoWrapper: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    height: 16,
    width: 16,
    top: 0
  },
  countText: {
    fontSize: 10,
    color: "#ffffff",
    fontWeight: "bold"
  }
});
