import React, { Component } from 'react';
import { Platform, Text } from 'react-native';
import { connect } from 'react-redux';
import { EventRegister } from 'react-native-event-listeners';
import moment from 'moment';
import NotificationWrapper from './NotificationWrapper';
import NotificationContent from './NotificationContent';
import CognitoNotification from './CognitoNotification';
import styles from '../../Css';
import * as actions from '../../store/actions/index';
import { OTHER, PROFILE, JOURNALS, PEER_TO_PEER, MANAGE_DEVICES, COMMUNITY_GROUPS } from '../../navigation/path';
import { peerToPeerTabs, peerToPeerTabPath } from '../../Constant/index';
import { getEntryDateTime, handleRoutineNavigation, handleSurveysNavigation } from '../../util/commonUiLogic';
import { TAB_BREAk_POINT_END, WEB } from '../../util/platformWindowConfig';
class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      hasNotification: Object.values(props.notification).length > 0,
      seenNotification: props.seenNotification,
      showNotificationList: false,
      anchorEl: null,
      Openid: null,
      notifClick: false,
      notifCount: Object.values(props.notification).length
    };
    Platform.OS !== WEB && this.props.hideMobileMenu();
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      this.setState({ notifClick: false });
    });
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    });
  }
  componentWillUnmount() {
    Platform.OS !== WEB && this.props.showMobileMenu();
  }

  openMenu = id => {
    this.setState({ Openid: id });
  };

  handleClose = () => {
    this.setState({
      showNotificationList: !this.state.showNotificationList,
      anchorEl: null,
    });
  };

  static getDerivedStateFromProps(props, state) {
    const {
      windowHeight,
      windowWidth,
      notification,
      seenNotification,
      isShowMobileMenu,
    } = props;
    if (Platform.OS !== WEB && isShowMobileMenu) {
      props.hideMobileMenu();
    }
    let hasNotification = state.hasNotification;
    let notifCount = Object.values(notification).length;
    if (notifCount > 0) {
      hasNotification = true;
    } else {
      hasNotification = false;
    }
    if (
      hasNotification !== state.hasNotification ||
      notification !== state.notification ||
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        hasNotification,
        notification,
        seenNotification,
        notifCount
      };
    }
    return null;
  }

  getHealthResourceNotifName = (type) => {
    switch (type) {
      case 'PARTNER_DELETE_HEALTH_RESOURCE':
        return 'A Resource Has Been Removed';
      case 'PARTNER_PUBLISH_HEALTH_RESOURCE':
        return 'You Have a New Resource!';
      case 'PARTNER_REMINDER_HEALTH_RESOURCE':
        return 'Resource Reminder!';
      case 'PARTNER_UPDATED_HEALTH_RESOURCE':
        return 'Your resource has been updated!';
      default:
        return null;
    }
  }

  getDeviceNotificationName = type => {
    switch (type) {
      case 'JOURNAL_CREATED_FOR_DEVICE':
      case 'JOURNAL_UPDATED_FOR_DEVICE':
        return (
          <Text>
            Your{' '}
            <Text style={styles.statusBarText}>Hexoskin Entry</Text>{' '}
            has been {type === 'JOURNAL_CREATED_FOR_DEVICE' ? 'created. ' : 'updated. '}
            <Text style={styles.statusBarText}>Click to view.</Text>
          </Text>
        );
      case 'CONNECTED_DEVICE':
        return (
          <Text>
            Your <Text style={styles.statusBarText}>Hexoskin device</Text>{' '}
            is now connected.
          </Text>
        );
      //no figma for these ones
      case 'AUTO_DISCONNECTED':
      case 'MANUAL_DISCONNECTED':
      case 'CONNECTION_EXPIRED':
      case 'DISCONNECTED_DEVICE':
        return (
          <Text>
            Your <Text style={styles.statusBarText}>Hexoskin device</Text>{' '}
            is now disconnected!.
          </Text>
        );
      default:
        return <Text />;
    }
  }

  getNotificationView = () => {
    return Object.values(this.state.seenNotification)
      .sort((a, b) => moment(b.date).diff(moment(a.date)))
      .map(item => {
        switch (item.type) {
          case 'ROUTINE_REMINDERS':
            return (
              <NotificationContent
                key={item.id}
                data={item.data}
                date={
                  moment(item.date).format("D MMM 'YY") +
                  ' at ' +
                  moment(item.date).format('h:mm a')
                }
                id={item.id}
                onPressNotification={() => {
                  this.setState({ notifClick: true });
                  this.redirectNewJournal(item.id, item.data);
                }}
                notifClick={this.state.notifClick}
                Openid={this.state.Openid}
                openMenu={this.openMenu}
                notifName={'Complete Entry: ' + item.data.name}
                notificationIcon={item.data.icon_c ? { uri: item.data.icon_c } : require('../../assets/journal-icons/report.png')}
                type={item.type}
                onPressClearNotification={() =>
                  this.handleClearNotification(item.type, item.id)
                }
                onPressTurnOffNotification={() =>
                  this.handleTurnOffNotification(item.type, item.id, item.data.id)
                }
              />
            );
          case 'CLINICALTRIAL':
            return (
              <NotificationContent
                key={item.id}
                data={item.data}
                date={
                  moment(item.date).format("D MMM 'YY") +
                  ' at ' +
                  moment(item.date).format('h:mm a')
                }
                id={item.id}
                Openid={this.state.Openid}
                openMenu={this.openMenu}
                notifName={
                  'You have been matched for a clinical trial(s): ' +
                  item.data.clinicalTrialSavedSearch.title
                }
                notificationIcon={require('../../assets/notification/notify-2.png')}
                type={item.type}
                onPressClearNotification={() =>
                  this.handleClearNotification(item.type, item.id, item.data.id)
                }
                onPressTurnOffNotification={() =>
                  this.handleTurnOffNotification(item.type, item.id, item.data.notificationTypeId, item.data)
                }
              />
            );
          case 'SURVEY':
            return (
              <NotificationContent
                key={item.id}
                data={item.data}
                date={
                  moment(item.date).format("D MMM 'YY") +
                  ' at ' +
                  moment(item.date).format('h:mm a')
                }
                id={item.id}
                onPressNotification={() => {
                  this.setState({ notifClick: true, showNotificationList: false });
                  handleSurveysNavigation(item.data.id, this.props, true, item.id);
                }}
                notifClick={this.state.notifClick}
                Openid={this.state.Openid}
                openMenu={this.openMenu}
                notifName={
                  'You have a survey due: ' +
                  item.data.healthSurvey.title
                }
                notificationIcon={require('../../assets/notification/notify-5.png')}
                type={item.type}
                onPressClearNotification={() =>
                  this.handleClearNotification(item.type, item.id)
                }
              />
            );
          case 'COGNITO_REMINDER':
            return (
              <CognitoNotification
                key={item.id}
                data={item.data}
                date={
                  moment(item.date).format("D MMM 'YY") +
                  ' at ' +
                  moment(item.date).format('h:mm a')
                }
                id={item.id}
                notifClick={this.state.notifClick}
                redirectProfile={() => {
                  this.setState({ notifClick: true });
                  this.redirectProfile();
                }}
                redirectEmailVerification={() => {
                  this.setState({ notifClick: true });
                  this.redirectEmailVerification();
                }}
              />
            );
          case 'ROUTINE_UPDATE':
          case 'ROUTINE_PUBLISH':
            return (
              <NotificationContent
                key={item.id}
                data={item.data.entryTypeWithReminders}
                date={
                  moment(item.date).format("D MMM 'YY") +
                  ' at ' +
                  moment(item.date).format('h:mm a')
                }
                id={item.id}
                onPressNotification={() => {
                  this.setState({ notifClick: true });
                  this.redirectToRoutines(item.type, item.id, item.data.id, item.data.notificationTypeId);
                }}
                notifClick={this.state.notifClick}
                Openid={this.state.Openid}
                openMenu={this.openMenu}
                notifName={
                  item.type === 'ROUTINE_UPDATE'
                    ? 'Routine updated: ' + item.data.entryTypeWithReminders.name
                    : 'Routine published: ' + item.data.entryTypeWithReminders.name
                }
                notificationIcon={item.data.entryTypeWithReminders?.icon_c
                  ? { uri: item.data.entryTypeWithReminders?.icon_c }
                  : item.data.entryTypeWithReminders?.deviceName
                    ? require('../../assets/devices/hexoskin-journal-logo.png')
                    : require('../../assets/journal-icons/report.png')
                }
                type={item.type}
                onPressClearNotification={() =>
                  this.handleClearNotification(item.type, item.id, item.data.id)
                }
              />
            );
          case 'EXPIRED_REMINDER':
          case 'EXPIRING_REMINDER':
            return (
              <NotificationContent
                key={item.id}
                data={item.data}
                date={
                  item.type === 'EXPIRED_REMINDER'
                    ? 'Your routine reminder expired ' + moment(item.data.reminderEndDate).format("D MMM 'YY")
                    : 'Your routine reminder will expire in 3 days.'
                }
                id={item.id}
                onPressNotification={() => {
                  this.setState({ notifClick: true });
                  this.redirectToRoutines(item.type, item.id, item.data.notificationId, item.data.journalEntryTypeId, item.data.reminderId);
                }}
                notifClick={this.state.notifClick}
                Openid={this.state.Openid}
                openMenu={this.openMenu}
                notifName={item.data.journalEntryTypeName}
                notificationIcon={require('../../assets/notify-icons/notify-reminder.png')}
                type={item.type}
                onPressClearNotification={() =>
                  this.handleClearNotification(item.type, item.id, item.data.notificationId)
                }
              />
            );
          case 'AUTO_DISCONNECTED':
          case 'MANUAL_DISCONNECTED':
          case 'CONNECTION_EXPIRED':
          case 'CONNECTED_DEVICE':
          case 'DISCONNECTED_DEVICE':
            return (
              <NotificationContent
                key={item.id}
                // data={item.data.createdJournalEntries}
                date={
                  moment(item.date).format("D MMM 'YY") +
                  ' at ' +
                  moment(item.date).format('h:mm a')
                }
                id={item.id}
                onPressNotification={() => this.redirectToDevice(item.id, item.data.id)}
                Openid={this.state.Openid}
                openMenu={this.openMenu}
                notifName={this.getDeviceNotificationName(item.type)}
                notificationIcon={require("../../assets/devices/hexoskin.png")}
                type={item.type}
                onPressClearNotification={() =>
                  this.handleClearNotification(item.type, item.id, item.data.id)
                }
                deviceNotif
              />
            );
          case 'JOURNAL_CREATED_FOR_DEVICE':
          case 'JOURNAL_UPDATED_FOR_DEVICE':
            return (
              <NotificationContent
                key={item.id}
                data={item.data.createdJournalEntries}
                date={
                  moment(item.date).format("D MMM 'YY") +
                  ' at ' +
                  moment(item.date).format('h:mm a')
                }
                id={item.id}
                onPressNotification={() => {
                  this.setState({ notifClick: true });
                  this.redirectToJournalDevice(item.id, item.data);
                }}
                Openid={this.state.Openid}
                openMenu={this.openMenu}
                notifName={this.getDeviceNotificationName(item.type)}

                notificationIcon={require('../../assets/devices/hexoskin-journal-logo.png')}
                type={item.type}
                onPressClearNotification={() =>
                  this.handleClearNotification(item.type, item.id, item.data.id)
                }
                onPressTurnOffNotification={() =>
                  this.handleTurnOffNotification(item.type, item.id, item.data.id)
                }
              />
            );
          case 'PEER_NOTIFICATION':
            return (
              <NotificationContent
                key={item.id}
                data={item.data}
                date={
                  moment(item.date).format("D MMM 'YY") +
                  ' at ' +
                  moment(item.date).format('h:mm a')
                }
                id={item.id}
                onPressNotification={() => {
                  this.setState({ notifClick: true });
                  this.redirectToCommunity(
                    item.id,
                    item.data.type,
                    item.data.type === 'PEER_CONNECTION_REQUEST'
                      ? item.data.notificationTypeId
                      : item.data.channelId
                  );
                }}
                notifClick={this.state.notifClick}
                Openid={this.state.Openid}
                openMenu={this.openMenu}
                notifName={
                  item.data.type === 'PEER_CONNECTION_REQUEST'
                    ? 'Community Request: You received a connection request from ' + item.data.userName
                    : 'Community Accepted: Congratulations, you are connected to ' + item.data.userName
                }
                notificationIcon={require('../../assets/notification/notify-2.png')}
                type={item.type}
                onPressClearNotification={() =>
                  this.handleClearNotification(item.type, item.id)
                }
              />
            );
          case 'JOIN_CLINICAL_STUDY':
            return (
              <NotificationContent
                key={item.id}
                data={item.data}
                date={'Join the ' + item.data.profile + ' at ' + item.data.orgName}
                id={item.id}
                onPressNotification={() => {
                  this.setState({ notifClick: true });
                  if (this.props.emailVerified) {
                    this.showJoinStudyPopup(item.type, item.id, item.data);
                  }
                }}
                notifClick={this.state.notifClick}
                Openid={this.state.Openid}
                openMenu={this.openMenu}
                notifName={'Invitation From ' + item.data.profile}
                notificationIcon={require('../../assets/notify-icons/notif-join-study.png')}
                type={item.type}
                disabled={!this.props.emailVerified}
                hideOptions={true}
              />
            );
          case 'PARTNER_PUBLISH_HEALTH_RESOURCE':
          case 'PARTNER_DELETE_HEALTH_RESOURCE':
          case 'PARTNER_REMINDER_HEALTH_RESOURCE':
          case 'PARTNER_UPDATED_HEALTH_RESOURCE':
            return (
              <NotificationContent
                key={item.id}
                data={item.data}
                date={
                  moment(item.date).format("D MMM 'YY") +
                  ' at ' +
                  moment(item.date).format('h:mm a')
                }
                id={item.id}
                onPressNotification={() => {
                  this.setState({ notifClick: true });
                  if (this.props.emailVerified) {
                    this.showHealthLibraryResourcPopup(item.type, item.id, item.data);
                  }
                }}
                notifClick={this.state.notifClick}
                Openid={this.state.Openid}
                openMenu={this.openMenu}
                notifName={this.getHealthResourceNotifName(item.type)}
                notifDetails={true}
                notificationIcon={item.type === 'PARTNER_DELETE_HEALTH_RESOURCE'
                  ? require('../../assets/notify-icons/notif-delete-resource.png')
                  : { uri: item.data.iconUrl }
                }
                type={item.type}
                disabled={!this.props.emailVerified}
                hideOptions={true}
              />
            );
          default:
            return null;
        }
      });
  };

  handleClearNotification = (type, notifId, id) => {
    if (type === 'CLINICALTRIAL' ||
      type === 'ROUTINE_UPDATE' ||
      type === 'EXPIRED_REMINDER' ||
      type === 'EXPIRING_REMINDER' ||
      type === 'PARTNER_HEALTH_LIBRARY_RESOURCE' ||
      type === 'JOURNAL_FOR_DEVICE' ||
      type === 'DEVICE_STATUS'
    ) {
      const idArray = [];
      idArray.push(id);

      this.props.deleteNotifcation(this.props.journalUserId, idArray, res => {
        if (res) {
          this.props.clearNotificationById(notifId);
        }
      });
    } else {
      this.props.clearNotificationById(notifId);
    }
  };

  handleTurnOffNotification = (type, notifId, id, data) => {
    switch (type) {
      case 'ROUTINE_REMINDERS':
      case'JOURNAL_CREATED_FOR_DEVICE':
      case 'JOURNAL_UPDATED_FOR_DEVICE':
        const filteredJournalEntry = this.props.journalEntryTypes.filter(
          type => type.id === id,
        );
        filteredJournalEntry[0].reminders[0].reminder = false;

        const payload = {
          journalEntryType: filteredJournalEntry[0],
        };

        this.props.clearNotificationById(notifId);
        this.props.createRoutine(payload, this.props.journalUserId);
        break;
      case 'CLINICALTRIAL':
        const clinicalTrialSavedSearch = {
          ...data.clinicalTrialSavedSearch
        };

        clinicalTrialSavedSearch.isNotify = false;
        clinicalTrialSavedSearch.notify = false;
        this.props.saveSearchClinicalTrial(
          { savedSearch: clinicalTrialSavedSearch },
          res => {
            if (res) {
              this.handleClearNotification(type, notifId, data.id);
            }
          },
          this.props.journalUserId
        );
        break;
      default:
        return null;
    }
  };

  redirectNewJournal = (notifId, data) => {
    const journalEntry = { journalEntryTypeId: data.id, draft: true, entryDate: getEntryDateTime(new Date().getHours() + ':' + new Date().getMinutes()) };
    const reminderDateTime = Boolean(data && data?.reminders && data?.reminders?.reminderTime) ? getEntryDateTime(data?.reminders?.reminderTime) : null;
    this.setState({ showNotificationList: false });
    this.props.onCreateNewRoutine(
      journalEntry,
      res => {
        if (res) {
          this.props.navigation.reset({
            index: 0,
            routes: [{
              name: this.props.windowWidth < TAB_BREAk_POINT_END ? JOURNALS.addJournalPath : JOURNALS.path,
              params: {
                journalPath: JOURNALS.addJournalPathSuffix,
                journalName: data.name,
                reminderDateTime: reminderDateTime,
              }
            }],
          });
          this.props.clearNotificationById(notifId);
        }
      },
      this.props.journalUserId,
    );
  };

  redirectToJournalDevice = (notifId, data) => {
    this.setState({ showNotificationList: false });
    this.props.onSetSelectedJournalEntry(data.createdJournalEntries);
    this.props.navigation.reset({
      index: 0,
      routes: [{
        name: this.props.windowWidth < TAB_BREAk_POINT_END ? JOURNALS.viewJournalPath : JOURNALS.path,
        params: {
          journalPath: JOURNALS.viewJournalPathSuffix,
          journalName: data.createdJournalEntries?.title
        }
      }],
    });
    this.handleClearNotification('JOURNAL_FOR_DEVICE', notifId, data.id)
  }

  redirectEmailVerification = () => {
    this.setState({ showNotificationList: false });
    this.props.resetDataLogout();
    this.props.isFromLogin();
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: OTHER.emailVerificationPath }],
    });
  };
  redirectProfile = () => {
    this.setState({ showNotificationList: false });
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: PROFILE.path }],
    });
  };

  redirectToRoutines = (type, notifId, id, journalEntryTypeId, reminderId = null) => {
    this.setState({ showNotificationList: false });
    const journalEntryType = this.props.journalEntryTypesProcessed[journalEntryTypeId];
    if (journalEntryType) {
      handleRoutineNavigation(journalEntryType, {}, this.props, false, true, reminderId);
      this.handleClearNotification(type, notifId, id);
    }
  }

  redirectToDevice = (notifId, id) => {
    this.setState({ showNotificationList: false });
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: MANAGE_DEVICES.path }],
    });
    this.handleClearNotification('DEVICE_STATUS', notifId, id);
  }

  redirectToCommunity = (notifId, type, connectionId) => {
    const { navigation, clearNotificationById, onSetPeerToPeerActiveTab, onSetFromNotifId } = this.props;
    this.setState({ showNotificationList: false });
    if (type === "PEER_CONNECTION_REQUEST") {
      onSetPeerToPeerActiveTab(peerToPeerTabs.myNetwork);
      clearNotificationById(notifId);
      onSetFromNotifId(connectionId);
      navigation.reset({
        index: 0,
        routes: [{
          name: PEER_TO_PEER.path,
          params: { communityTab: peerToPeerTabPath.myNetwork }
        }],
      });
    } else {
      this.redirectToIndividualChat(connectionId, notifId);
    }
  }

  redirectToIndividualChat = (conversationId, notifId) => {
    const {
      clearNotificationById,
      onSetFromNotifId
    } = this.props;
    clearNotificationById(notifId);
    onSetFromNotifId(conversationId);
    EventRegister.emitEvent("redirectChat", { conversationSid: conversationId })
  };

  showJoinStudyPopup = (type, notifId, data) => {
    const joinStudyObj = {
      accessCode: data.accessCode,
      orgName: data.orgName,
      profile: data.profile,
      isFromNotif: true,
      notifId: notifId,
      id: data.notificationId
    };
    if (Platform.OS === WEB) {
      this.handleClose();
    }
    this.props.setJoinStudyData(joinStudyObj);
    this.props.showSuccessPopup(15, null, null);
  }

  showHealthLibraryResourcPopup = (type, notifId, data) => {
    if (Platform.OS === WEB) {
      this.handleClose();
    }
    this.props.updateSearchedKeywords(null);
    this.props.navigation.reset({
      index: 0,
      routes: [{
        name: COMMUNITY_GROUPS.path,
        params: {
          isFromNotif: true,
          resourceId: type !== 'PARTNER_DELETE_HEALTH_RESOURCE' ? data?.communityResourceId : null
        }
      }],
    });
    if (type !== 'PARTNER_DELETE_HEALTH_RESOURCE') {
      this.props.getResourceDetailsById(data.communityResourceId, () => {
        //Delete notification
        this.handleClearNotification('PARTNER_HEALTH_LIBRARY_RESOURCE', notifId, data.id);
      });
    } else {
      this.handleClearNotification(
        'PARTNER_HEALTH_LIBRARY_RESOURCE',
        notifId,
        data.id
      );
    }
  }

  render() {
    const {
      windowWidth,
      windowHeight,
      tabMenuIconView,
      journalUserId,
      clearAllNotification,
      setSeenNotification,
      hasNotch,
      timeZone,
      timeZoneWarning
    } = this.props;

    return (
      <NotificationWrapper
        windowWidth={windowWidth}
        windowHeight={windowHeight}
        journalUserId={journalUserId}
        clearAllNotification={clearAllNotification}
        getNotificationView={this.getNotificationView}
        showNotificationList={this.state.showNotificationList}
        hasNotification={this.state.hasNotification}
        handleClose={this.handleClose}
        anchorEl={this.state.anchorEl}
        onPressBellIconWeb={(e) => {
          setSeenNotification();
          e.preventDefault();
          this.setState({
            anchorEl: !this.state.showNotificationList
              ? e.currentTarget
              : null,
            showNotificationList: !this.state.showNotificationList,
          });
        }}
        notifCount={this.state.notifCount}
        hasNotch={hasNotch}
        timeZone={timeZone}
        timeZoneWarning={timeZoneWarning}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    notification: state.uistate.notification,
    seenNotification: state.uistate.seenNotification,
    journalUserId: state.uistate.journalUserId,
    isShowMobileMenu: state.uistate.showMobileMenu,
    journalEntryTypes: state.routines.journalEntryTypes,
    myNetwork: state.routines.myNetwork,
    peerProfile: state.routines.peer2peer,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    symptomsDigestProcessed: state.routines.symptomsDigestProcessed,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    emailVerified: state.auth.emailVerified,
    hasNotch: state.uistate.hasNotch,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setSeenNotification: () => dispatch(actions.setSeenNotification()),
    clearAllNotification: () => dispatch(actions.clearAllNotification()),
    isFromLogin: () => dispatch(actions.isFromLogin()),
    resetDataLogout: () => dispatch(actions.resetDataLogout()),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    onCreateNewRoutine: (journalEntry, callBack, journalUserId) =>
      dispatch(actions.createNewRoutine(journalEntry, callBack, journalUserId)),
    getAllUserHealthSurvey: (journalUserId, callBack) =>
      dispatch(actions.getAllUserHealthSurvey(journalUserId, callBack)),
    getUserHealthSurveyById: (id, journalUserId, callBack) =>
      dispatch(actions.getUserHealthSurveyById(id, journalUserId, callBack)),
    selectSurvey: (id, surveyId, name, isClosed,
      modifiedOn = null, description = null, duration = null,
      s3ImageUrl = null, surveyStatus = null, questionOrder = 0
    ) =>
      dispatch(actions.selectSurvey(id, surveyId, name, isClosed,
        modifiedOn, description, duration,
        s3ImageUrl, surveyStatus, questionOrder
      )),
    resetSurvey: () => dispatch(actions.resetSurvey()),
    setActiveTab: activeTabIndex =>
      dispatch(actions.setActiveTab(activeTabIndex)),
    createRoutine: (payload, journalUserId) =>
      dispatch(actions.updateJournalEntryType(payload, journalUserId)),
    clearNotificationById: id => dispatch(actions.clearNotificationById(id)),
    deleteNotifcation: (journalUserId, idList, callBack) =>
      dispatch(actions.deleteNotifcation(journalUserId, idList, callBack)),
    saveSearchClinicalTrial: (
      savedSearchClinicalTrial,
      callBack,
      journalUserId,
    ) =>
      dispatch(
        actions.saveSearchClinicalTrial(
          savedSearchClinicalTrial,
          callBack,
          journalUserId,
        ),
      ),
    onSetPeerToPeerActiveTab: (activeTabIndex) =>
      dispatch(actions.setPeerToPeerActiveTab(activeTabIndex)),
    onSetFromNotifId: (id) =>
      dispatch(actions.setFromNotifId(id)),
    onGetToken: (callBack, userId) =>
      dispatch(actions.getToken(callBack, userId)),
    commonErrorHandler: (error) => dispatch(actions.commonErrorHandler(error)),
    onSetSelectedConversation: (conversation) =>
      dispatch(actions.setSelectedChannel(conversation)),
    setEntryType: (journalEntryType) =>
      dispatch(actions.setSelectedJournalEntryType(journalEntryType)),
    setJoinStudyData: (data) => dispatch(actions.setJoinStudyData(data)),
    showSuccessPopup: (id, graph, data) => dispatch(actions.showSuccessPopup(id, graph, data)),
    getResourceDetailsById: (communityResourceId, callBack) =>
      dispatch(actions.getResourceDetailsById(communityResourceId, callBack)),
    updateSearchedKeywords: (keywords) =>
      dispatch(actions.updateSearchedKeywords(keywords)),
    onSetSelectedJournalEntry: selectedId =>
      dispatch(actions.setSelectedJournalEntry(selectedId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notification);
