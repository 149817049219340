import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import * as actions from '../../../../store/actions/index';
import ChatHistoryComponent from '../../../../components/PeerToPeer/MainSection/Chats/ChatHistoryComponent';
import {PEER_TO_PEER} from '../../../../navigation/path';
import { pendoTrackEvent } from '../../../../util/pendoConfig';
import { peerToPeerStatuses } from '../../../../Constant';
import _ from 'lodash';

class ChatHistoryScreen extends Component {
  constructor(props) {
    super(props);
    const {conversations, myNetwork} = props;
    this.state = {
      conversations: conversations ? conversations : [],
      loading: false,
      myConnections: myNetwork?.connected ? myNetwork.connected : [],
      totalUnreadMessages: 0,
      searchedKeyword: null,
      chatStatus: peerToPeerStatuses.STOPED
    };
    this.conversationPaginator = React.createRef();
  }

  componentDidMount() {
    const { getRef } = this.props;
    getRef && getRef(this);
    pendoTrackEvent('Community/@Chats');
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    });
  }

  componentWillUnmount = () => {
    const {
      onUpdateSearchedKeyword,
      onSetFromNotifId,
      fromNotifId,
    } = this.props;
    if (this.state.searchedKeyword) {
      onUpdateSearchedKeyword('');
    }
    if(fromNotifId) {
      onSetFromNotifId(null);
    }
  };

  updateConnectionStatus = (myConnections, client) => {
    let connectionStatus = [];
    myConnections &&
      myConnections.length > 0 &&
      myConnections.forEach(res => {
        res.id &&
          client.getUser(res?.id?.toString()).then(status => {
            connectionStatus.push({id: res.id, status: status.state.online});
          });
      });
    return connectionStatus;
  };


  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth, myNetwork, newMessageCount, conversations, chatStatus } = props;
    const {connected} = myNetwork;

    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth ||
      !_.isEqual(connected, state.myConnections) ||
      !_.isEqual(conversations, state.conversations) ||
      chatStatus !== state.chatStatus
    ) {

      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        myConnections: connected ? connected : [],
        newMessageCount,
        conversations: state.searchedKeyword && state.searchedKeyword !== '' ? state.conversations : conversations,
        chatStatus: chatStatus
      };
    }

    return null;
  }

  filterChatHistory = keyword => {
    const {conversations} = this.props;

    if (keyword) {
      let conversationsFilter = [];
      if (conversations && conversations.length > 0) {
        conversations.forEach(res => {
          if (
            res.name &&
            res.name.toLowerCase().includes(keyword.toLowerCase())
          ) {

            conversationsFilter.push({
              ...res,
              sid: res.id,
              name: res.name,
              lastMessage: res.lastMessage,
              lastMessageTime: res.lastMessageTime,
              newText: res.newText,
              author: res.author,
              profileImageS3SignedUrl: res.profileImageS3SignedUrl,
              messageIndex: res.messageCount - 1
            });
          }
          if (res.allMessages && res.allMessages.length > 0) {
            res.allMessages.find(msg => {
              if (msg?.text && msg.text.toLowerCase().includes(keyword.toLowerCase())) {
                conversationsFilter.push({
                  ...res,
                  sid: res.id,
                  name: res.name,
                  lastMessage: msg.text,
                  lastMessageTime: msg.createdAt,
                  newText: res.newText,
                  author: res.author,
                  profileImageS3SignedUrl: res.profileImageS3SignedUrl,
                  messageIndex: msg.index
                });
              }
            });
          }
        });
        conversationsFilter.sort((a, b) =>
          moment(b.lastMessageTime).diff(moment(a.lastMessageTime)),
        );
      }

      this.setState({
        searchedKeyword: keyword,
        conversations: conversationsFilter,
      });
    } else {
      conversations.sort((a, b) =>
        moment(b.lastMessageTime).diff(moment(a.lastMessageTime)),
      );
      this.setState({
        searchedKeyword: null,
        conversations: conversations ? conversations : [],
      });
    }
    this.props.onUpdateSearchedKeyword(keyword);
    return keyword;
  };

  handleOnRedirect = () => {
    pendoTrackEvent('Community > @Chats > NewMessage');
    this.props.navigation.push(PEER_TO_PEER.newChatListPath);
  };

  handleOnPressChatItem = item => {
    const {searchedKeyword} = this.state;
    const {conversations} = this.props;

    if (!searchedKeyword) {
      this.props.onSetSelectedConversation(item);
    } else {
      const findItem = conversations.filter(res => item.id === res.id);
      this.props.onSetSelectedConversation(findItem[0]);
    }

    this.props.navigation.push(
      PEER_TO_PEER.individualChatPath, {
        isFromSearch: Boolean(searchedKeyword),
        messageIndex: Boolean(searchedKeyword) ? item.messageIndex : -1
      });
  };

  render() {
    const {peerProfile, getRef} = this.props;
    const {conversations, loading, searchedKeyword} = this.state;
    getRef && getRef(this);

    return (
      <ChatHistoryComponent
        hasPeerProfile={peerProfile && peerProfile?.username ? true : false}
        handleOnRedirect={this.handleOnRedirect}
        loading={loading}
        onPressChatItem={this.handleOnPressChatItem}
        myConversationList={conversations}
        id={peerProfile?.id?.toString()}
        searchedKeyword={searchedKeyword}
        onSearchInputValueChange={this.filterChatHistory}
        chatInitialized={this.state.chatStatus === peerToPeerStatuses.INITIALIZED ? true : false}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    peerProfile: state.routines.peer2peer,
    conversations: state.peerToPeer.channels?.filter(channel => channel.id) ?? [],
    searchedKeyword: state.peerToPeer.searchedKeyword,
    fromNotifId: state.uistate.fromNotifId,
    myNetwork: state.routines.myNetwork,
    newMessageCount: state.peerToPeer.newMessageCount,
    chatStatus: state.peerToPeer.chatStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetToken: (callBack, userId) =>
      dispatch(actions.getToken(callBack, userId)),
    onUpdateConversations: conversations =>
      dispatch(actions.updateChannels(conversations)),
    onSetSelectedConversation: conversation =>
      dispatch(actions.setSelectedChannel(conversation)),
    setNewMessageCount: (count) => dispatch(actions.setNewMessageCount(count)),
    onUpdateSearchedKeyword: keyword =>
      dispatch(actions.updateSearchedKeyword(keyword)),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    onSetFromNotifId: (id) =>
      dispatch(actions.setFromNotifId(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChatHistoryScreen);
