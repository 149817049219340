import {StyleSheet} from 'react-native';

export default StyleSheet.create({

  questionImageWrapper: {
    marginTop: 30,
    marginBottom: 30,
    height: 164
  },

  symptomSlidereWrapper: {
    marginTop: 20
  },

  symptomValueDescriptionRow: {
    flexDirection: 'row',
    marginTop: 10,
    width: '100%',
  },

  symptomValueDescriptionTableHeader: {
    textAlign: 'left',
  },

  symptomValueDescriptionIndexHeader: {
    textAlign: 'left',
    width: 60,
    marginRight: 10,
    flexShrink: 0,
    flex: 0
  },

  symptomValueDescriptionIndex: {
    textAlign: 'center',
    width: 60,
    marginRight: 10,
    flexShrink: 0,
  },

  symptomValueDescriptionText: {
    textAlign: 'left',
    maxWidth: 400
  },

});