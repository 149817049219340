import React from "react";
import Tooltip from 'react-tooltip-lite';
import { Image } from "react-native";
import styles from "../../Css";

const toolTip = (props) => {
  return (

    <React.Fragment>
{props.menuOptions ? (
  <Tooltip isOpen={props.isOpen} direction={"left"} arrow={false} content={props.children} zIndex={9999999} background={'#ffffff'} className={"tooltip-wrapper"}
  tipContentClassName={"tooltip-content-menu"}>

  </Tooltip>
): (
  <Tooltip content={props.text} background={'#00BDBD'} className={"tooltip-wrapper"} color={"#ffffff"}
  tipContentClassName={"tooltip-content"}>
  <Image
            source={require("../../assets/icons/question-circle-fill.png")}
            style={styles.helpCircleComIcon}
            resizeMode={"contain"}
            useDefaultStyles={true}
          />
  </Tooltip>

)}
</React.Fragment>

  );
};

export default toolTip;

// Old Code Commented

// import React from "react";
// import ReactTooltip from "react-tooltip";
// import { Image, View } from "react-native";
// import styles from "../../Css";

// const toolTip = (props) => {
//   return (
//     <React.Fragment>
//       <a data-tip data-for={props.id} data-event='click'>
//         <Image
//           source={require("../../assets/icons/question-circle-fill.png")}
//           style={styles.helpCircleComIcon}
//           resizeMode={"contain"}
//         />
//       </a>
//       <ReactTooltip
//         id={props.id}
//         type="info"
//         className={"tool-tip-section"}
//         backgroundColor={"#00BDBD"}
//         arrowColor={"#00BDBD"}
//         place='top' 
//         clickable={true}
//         data-iscapture="true"
//       >
//         <span>{props.text}</span>
//       </ReactTooltip>
//     </React.Fragment>
//   );
// };

// export default toolTip;
