import React from "react";
import { View, Text, Image } from "react-native";
import styles from "../../Css";
import FieldTitle from "./fieldTitle";
import * as FontScaling from '../../components/Text/fontScaling';

const textDatePickerFrame = (props) => {
  return (
    <View
      style={[
        props.fieldSm ? styles.textInputSmWrapper : styles.textInputWrapper,
        styles.postRelative,
      ]}
    >
      <FieldTitle
        fieldAlign={props.fieldAlign}
        fieldBg={props.fieldBg}
        title={props.title}
        fieldSm={props.fieldSm}
        fieldLt={props.fieldLt}
        windowWidth={props.windowWidth}
        reminderSection={props.reminderSection}
      />
      <View
        style={[
          props.fieldSm ? styles.textInputFieldSm : styles.textInputField,
          props.fieldInfo ? styles.frameInfoPd : styles.framePd,
          props.fieldBg === "grey"
            ? styles.bgThinGrey
            : props.fieldBg === "blue"
              ? styles.bgBlueExLight
              : props.fieldBg === "transparent"
                ? styles.bgTransparent
                : styles.bgWhite,
        ]}
      >
        <View style={[
          props.fieldSm ? styles.dataWrapperSm : styles.dataWrapper,
          styles.flexJcCt]}>     
          <Text style={[
              props.reminderSection
              ? styles.textPrimeSm
              : styles.textPrimeMd
            ]}
            allowFontScaling = {FontScaling.allowFontScaleFalse}
            >{props.fieldValue}</Text>
        </View>
      </View>
      <View
        style={[styles.btnActionView, 
        props.dateSm
        ?  styles.btnActionInfoSm
        : styles.btnActionInfo
      ]}
      pointerEvents={"none"}
      >
        <Image
          style={[
            props.dateSm
            ? styles.calIconExSm
            : styles.calIcon
          ]}
          source={require("../../assets/icons/cal.png")}
        />
      </View>
    </View>
  );
};

export default textDatePickerFrame;
