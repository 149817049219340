import React, { Component } from 'react';
import { View } from 'react-native';
import { IMAGES_DOCUMENTS } from '../../navigation/path';

export default class AttachToOptionCommon extends Component {

  handleOnSave = (listItems, selectedItem) => {
    this.setState({saveWait: true});
    const artifactId = listItems[selectedItem].artifactId;
    this.props.setAttachmentUploadStatus(true);
    this.props.setAttachmentArtifactId(artifactId);
    let fileSize = 0;
    Object.values(this.props.files).forEach((res, index) => {
      fileSize += res.size;
    });
    this.props.setAttachmentFullSize(fileSize);
      
    this.props.showUploadProgress();
    this.setState({saveWait: false});
    this.props.navigation.push(IMAGES_DOCUMENTS.path);
  };

  render() {
    return (<View />);
  }
}
