import React, { Component } from 'react';
import { View } from 'react-native';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../store/actions/urls';
import { getAccountType } from '../../util/subscriptionInformation';
import { CAREGIVERS, PROFILE } from '../../navigation/path';


export default class HeaderCommon extends Component {
    constructor(props) {
        super(props);
        const {
          showFilers,
          showBack,
          showNotify,
          showClearFilters,
          isHome,
          showCancel,
          sharedAccessDigest,
          index,
          windowHeight,
          windowWidth,
          hideCaregiverAccess,
          route,
          customHeading,
        } = props;
        const pathname = route.name;
        const {sharedAccessUserSummaries} = sharedAccessDigest
          ? sharedAccessDigest
          : {};
        const myDetail = sharedAccessUserSummaries && sharedAccessUserSummaries.length > 0 ? sharedAccessUserSummaries.find(res => res.ownerOfJournal) : {};
    
        const url = this.getProfileImageUrl(props);
    
        this.state = {
          index,
          showFilers,
          showBack,
          showNotify,
          showClearFilters,
          isHome,
          showCancel,
          customHeading,
          imagePath: showClearFilters
            ? require('../../assets/icons/header-filter-on-active.png')
            : require('../../assets/icons/header-filter-on.png'),
          visibleFilter: false,
          filterType: null,
          showQuestionFilter: false,
          showCaregiverList: false,
          sharedAccessGivenToMe: props.sharedAccessDigest.sharedAccessGivenToMe.filter(
            item => this.props.sharedAccessDigestProcessed && this.props.sharedAccessDigestProcessed.sharedAccessUserSummaries &&
              this.props.sharedAccessDigestProcessed.sharedAccessUserSummaries.hasOwnProperty(item.grantingUser) &&
                    item.permissionType !== 'NONE' &&
                    (item.shareAccessStatus === null ||
                      item.shareAccessStatus === 'CONNECTED')
          ),
    
          uri: {
            uri: url + '&random_number=' + new Date().getTime(),
            cache: 'reload',
          },
          uername:
            (this.props.user.firstName ? this.props.user.firstName : '') +
            ' ' +
            (this.props.user.lastName ? `${this.props.user.lastName}` : ''),
          imageLoading: true,
          myDetail: myDetail,
          showAddCareiver: !pathname.includes(CAREGIVERS.inviteCaregiverPath),
          accountType: getAccountType(props.userSubscription),
          windowHeight,
          windowWidth,
          disableAddCaregiverNavigation: pathname.includes(PROFILE.profilePremiumAccountPath) ||
                                pathname.includes(CAREGIVERS.inviteCaregiverPath)
                                  ? true
                                  : false,
          hideCaregiverAccess,
          timeZoneWarning: false,
          timeZoneUpdating: false,
        };
    
        this.props.onMenuItemPress(index);
    }

    static getDerivedStateFromProps(props, state) {
        const {
          showFilers,
          showBack,
          showNotify,
          showClearFilters,
          index,
          windowHeight,
          windowWidth,
          user,
          hideCaregiverAccess,
          sharedAccessDigest,
          sharedAccessDigestProcessed,
          customHeading,
        } = props;
        if (index !== state.index) {
          props.onMenuItemPress(index);
        }
        const {firstName, lastName} = user;
        const uername =
          (firstName ? firstName : '') + ' ' + (lastName ? lastName : '');
        return {
          showFilers,
          showBack,
          showNotify,
          showClearFilters,
          index,
          windowHeight,
          windowWidth,
          customHeading,
          imagePath: showClearFilters
            ? require('../../assets/icons/header-filter-on-active.png')
            : require('../../assets/icons/header-filter-on.png'),
          uername,
          hideCaregiverAccess,
          sharedAccessGivenToMe: sharedAccessDigest.sharedAccessGivenToMe.filter(
            item => sharedAccessDigestProcessed && sharedAccessDigestProcessed.sharedAccessUserSummaries &&
              sharedAccessDigestProcessed.sharedAccessUserSummaries.hasOwnProperty(item.grantingUser) &&
                    item.permissionType !== 'NONE' &&
                    (item.shareAccessStatus === null ||
                      item.shareAccessStatus === 'CONNECTED')
          ),
        };
    }

  getProfileImageUrl = props => {
    const {journalUserId} = props;
    let url = BASE_URL + '/api/profile/image/byId?';
    if (journalUserId) {
      url += 'journalUserId=' + journalUserId + '&auth=' + Cookies.get('authToken');
    } else {
      url += 'auth=' + Cookies.get('authToken');
    }

    return url;
  };

  render() {
    return <View />
  }
}

