import React, {Component} from 'react';
import stylesResponsive from '../../CssResponsive';
import styles from '../../Css';
import {View, Image, Text, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Cookies from 'js-cookie';
import {BASE_URL} from '../../store/actions/urls';
import UserAvatar from 'react-native-user-avatar';
import {PROFILE} from '../../navigation/path';
import { pendoTrackEvent } from '../../util/pendoConfig';
import {getUserAvatarName} from '../../util/commonUiLogic';

class ProfileSection extends Component {
  constructor(props) {
    super(props);

    const url = ProfileSection.getProfileImageUrl(props);
    const {journalUserId, user} = props;
    this.state = {
      imageLoading: true,
      journalUserId: journalUserId,
      uri: {
        uri: url,
        cache: 'reload',
      },
      userName: ProfileSection.getUserName(user),
    };
  }

  static getProfileImageUrl = props => {
    const {journalUserId} = props;
    let url = BASE_URL + '/api/profile/image/byId?';
    if (journalUserId) {
      url += 'journalUserId=' + journalUserId + '&auth=' + Cookies.get('authToken');
    } else {
      url += 'auth=' + Cookies.get('authToken');
    }

    return url;
  };

  static getDerivedStateFromProps(props, state) {
    const {uploadProfileImage, uploadProfileImageSucess, user} = props;
    const userName = ProfileSection.getUserName(user);
    if (uploadProfileImage) {
      const url = ProfileSection.getProfileImageUrl(props);
      uploadProfileImageSucess();
      return {
        uri: {
          uri: url + '&random_number=' + new Date().getTime(),
          cache: 'reload',
        },
        userName: userName,
        imageLoading: true,
      };
    } else if (userName !== state.userName) {
      const url = ProfileSection.getProfileImageUrl(props);
      return {
        userName: userName,
        uri: {
          uri: url + '&random_number=' + new Date().getTime(),
          cache: 'reload',
        },
        imageLoading: true,
      };
    }

    return null;
  }

  static getUserName = user => {
    if (user) {
      const firstName = user.firstName;
      const lastName = user.lastName;
      if (firstName && lastName) {
        return firstName + ' ' + lastName;
      } else if (firstName && !lastName) {
        return firstName;
      } else if (!firstName && lastName) {
        return lastName;
      }
    }

    return '';
  };

  handelOnProfilePress = () => {
    pendoTrackEvent('Menu > Profile');
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: PROFILE.path }],
    });
  };

  imageLoadingError() {
    this.setState({imageLoading: false});
  }

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {imageLoading, uri, userName} = this.state;
    const {user} = this.props;
    const {firstName, lastName} = user;
    const userAvatarName = getUserAvatarName(firstName, lastName);
    const { tabMenuIconView } = this.props;
    return (
      <View style={styles.sideBarMainProfile}>
        <View 
          nativeID={'menuProfile'}
          style={[
            styles.profileMenuSection, 
            styles.flexAiFs
          ]}
        >
          <TouchableOpacity onPress={this.handelOnProfilePress}>
            <View 
              nativeID={'menuProfileImage'}
              style={[styles.profileAvatar, styles.avatarBg]}>
              {Boolean(imageLoading) && (
                <Image
                  style={styles.profileAvatarDefault}
                  resizeMode={'cover'}
                  source={uri}
                  onError={this.imageLoadingError.bind(this)}
                />
              )}

              {!Boolean(imageLoading) && (
                <UserAvatar
                  size={50}
                  name={userAvatarName.toUpperCase()}
                  textColor={'#FF9E38'}
                  bgColor={'rgb(255, 228, 200)'}
                />
              )}
            </View>
          </TouchableOpacity>
          {!Boolean(tabMenuIconView) && (
            <View style={[styles.profileMenuSub]}>
              <View style={[styles.profileNameBlock]}>
                <Text  
                  nativeID={'menuProfileName'} 
                  style={[styles.textPrime]}
                  allowFontScaling={false}>{userName}</Text>
              </View>
              <TouchableOpacity onPress={this.handelOnProfilePress}>
                <View style={stylesRes.profileDetailBlock}>
                  <Text
                    nativeID={'menuViewProfile'}
                    style={[
                      styles.textPrimeSm,
                      styles.textColorLight,
                      styles.textUnderline,
                    ]}
                    allowFontScaling={false}
                    >
                    View Profile
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.routines.user,
    uploadProfileImage: state.uistate.uploadProfileImage,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadProfileImageSucess: () =>
      dispatch(actions.uploadProfileImageSucess()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileSection);
