import React, { useState, useEffect } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
} from 'react-native';
import _ from 'lodash'; 
import styles from '../../../../Css';
import stylesResponsive from '../../../../CssResponsive';
import TickButton from '../../../../components/CheckBox/CheckBox';

const CategoriesFilterSubExpandableComponent = ({ item, key, setSubItemSelection, selectedSubCategories, isUncheckedAll }) => {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    //Custom Component for the Expandable List
    const [isItemSelected, setItemSelection] = useState(false);

    useEffect(() => {
        if (selectedSubCategories) {
            const isSubCategorySelected = selectedSubCategories.find(x => x.subCategoryList.includes(item.name));
            setItemSelection(Boolean(isSubCategorySelected));
        }
        //eslint-disable-next-line
    }, [selectedSubCategories]);

    useEffect(() => {
        if(_.isEqual(isUncheckedAll, true)){
            setItemSelection(false);
        }
    }, [isUncheckedAll]);

    return (
        <View style={[styles.pdLtZerpfRtEight]}>
            <TouchableOpacity
                key={key}
                onPress={() => [setItemSelection(!isItemSelected), setSubItemSelection(item.name, !isItemSelected)]}>
                <View style={[styles.checkboxContainer, styles.ctgryListRtLtTpBt, {paddingRight:5}]}>
                    {Boolean(isItemSelected) ? (
                        <TickButton
                            imagePath={'tickButton'}
                            toggleTickCallback={() => [setItemSelection(!isItemSelected), setSubItemSelection(item.name, !isItemSelected)]}
                            tickStyle={{ marginRight: 0 }}
                        />
                        ) : (
                        <TickButton
                            imagePath={'untickButton'}
                            toggleTickCallback={() => [setItemSelection(!isItemSelected), setSubItemSelection(item.name, !isItemSelected)]}
                            tickStyle={{ marginRight: 0 }}
                        />
                    )}
                    <Text style={[styles.textPaddingSubCategory, styles.textPrime]}>
                        {`${item.name}`}
                    </Text>
                </View>
            </TouchableOpacity>
        </View>
    );
};

export default CategoriesFilterSubExpandableComponent;