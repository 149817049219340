import {BASE_URL} from '../store/actions/urls';
import Cookies from 'js-cookie';

export const getUrl = (url, journalUserId, callBack) => {
  let tempUrl = BASE_URL + url;
  if (journalUserId) {
    tempUrl +=
      'journalUserId=' + journalUserId + '&auth=' + Cookies.get('authToken');
  } else {
    tempUrl += 'auth=' + Cookies.get('authToken');
  }
  callBack(tempUrl);
};
