import React from "react";
import { View, Text, TouchableOpacity, Linking } from "react-native";
import styles from "../../Css";
import {ORGANIZATION_URL_TOU, ORGANIZATION_NAME, CURRENT_YEAR} from '../../util/commonUiConfig';

const sectionFooter = (props) => (
  <React.Fragment>
    <View style={[styles.staticPageFooter, styles.staticPageMax]}>
      <View style={styles.gridRow}>
        <View style={styles.gridColFull}>
          <View style={styles.greyBorderThinTop} />
        </View>
      </View>
      <View style={styles.gridRow}>
        <View style={styles.gridColFull}>
          <View
            style={[
              styles.staticPageFooterText,
              styles.flexRowWrap,
              styles.flexJcCtAiCt,
            ]}
          >
            <View style={[styles.staticPageCopyRight, styles.staticPagePd]}>
              <Text style={[styles.textPrimeSm, styles.textGreyDark]} allowFontScaling={false}>
                Copyright © {ORGANIZATION_NAME} {CURRENT_YEAR}
              </Text>
            </View>
            <View
              style={[
                styles.staticPageLink,
                styles.flexRow,
                styles.staticPagePd,
              ]}
            >
              <TouchableOpacity onPress={props.linkPress}>
                <Text
                  style={[
                    styles.textPrimeSm,
                    styles.textGreyDark,
                    styles.textUnderline,
                  ]}
                  allowFontScaling={false}
                >
                  Privacy Policy
                </Text>
              </TouchableOpacity>
              <Text style={[styles.textPrimeSm, styles.textGreyDark]} allowFontScaling={false}> | </Text>
              <TouchableOpacity
                onPress={() => Linking.openURL(ORGANIZATION_URL_TOU)}
                title="Terms of Use"
              >
                <Text
                  style={[
                    styles.textPrimeSm,
                    styles.textGreyDark,
                    styles.textUnderline,
                  ]}
                  allowFontScaling={false}
                >
                  Terms of Use
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </View>
  </React.Fragment>
);

export default (sectionFooter);
