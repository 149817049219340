import React from "react";
import { View, Text } from "react-native";
import styles from "../../Css";

// Date
import moment from "moment";

const DATE_FORMAT = "DD MMM 'YY";

const questionMainAnswers = (props) => {
  const { userName, answer, postedDate } = props;

  return (
    <View style={[styles.commentsListItemWrapper, styles.secBgPdBot]}>
      <View style={[styles.commentContSecLg]}>
        <Text
          style={[
            styles.textPrime,
            styles.textLightComment,
          ]}
          allowFontScaling= {false}
        >
          {userName} | {moment(postedDate).format(DATE_FORMAT)}
        </Text>
      </View>
      <View style={[styles.commentContSecLg, styles.pdTopExSm]}>
        <Text style={[styles.textPrime]} allowFontScaling= {false}>
          {answer}
        </Text>
      </View>
    </View>
  );
};

export default (questionMainAnswers);
