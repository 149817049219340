import theme from "./base/theme";
import { Platform } from "react-native";
export const cssSub = {
  activeTabOrangeColor: {
    borderBottomColor: theme.COLOR_ORANGE_DARK,
  },
  btnSubSectionFixed: {
    maxWidth: 170,
    flexBasis: 170,
  },
  textInputMdWrapper: {
    height: 55,
  },
  textInputMdWrapperCr: {
    height: 50,
  },
  btnTabSmWrapper: {
    minHeight: 30,
  },
  listItemRemoveLg: {
    maxWidth: 35,
    flexBasis: 35,
    alignItems: "flex-end",
  },
  btnTabSingleBoxSec: {
    maxWidth: 160,
    flexBasis: 160,
    flexDirection: "row",
  },
  btnTabSingleSpLg: {
    maxWidth: 10,
    flexBasis: 10,
  },
  popMessageContMax: {
    width: "100%",
    maxWidth: 200,
    marginLeft: "auto",
    marginRight: "auto",
  },
  closeDarkIconLg: {
    width: 18,
    height: 18,
    tintColor: theme.SECONDARY_FONT_COLOR,
  },
  modalCloserWrapper: {
    position: "absolute",
    right: 6,
    top: 6,
    zIndex: 9999,
  },
  secRtPd: {
    ...Platform.select({
      web: {
        paddingRight: 25,
      },
    }),
  },
  smAddBtn: {
    minWidth: 100,
  },
  contactBoxMax: {
    width: '100%',
    maxWidth: 360,
    marginLeft: "auto",
    marginRight: "auto",
  },
  colorPickerRowPd: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },
  colorPickerButtonSec: {
    maxWidth: 60,
    flexBasis: 60,
    alignSelf: "flex-end"
  },
  colorPickerSelection: {
    width: 46,
    height: 32,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  colorPickerDetails: {
    height: 22,
    width: 22,
    borderRadius: 5,
    marginLeft: 4,
  },
  colorPickerTouch: {
    height: 30,
    width: 44,
  },
  colorPickerArrowIcon: {
    width: 8,
    height: 5
  },
  colorPickerArrow: {
    position: "absolute",
    right: 4,
  },
  fixedBtnPd: {
    paddingLeft: 16, 
    paddingRight: 16,
    paddingTop: 5,
    paddingBottom: 15,
  },
  fieldColSmGraph: {
    flexBasis: 80,
    maxWidth: 80
  },
  fieldColExSmGraph: {
    flexBasis: 68,
    maxWidth: 68
  },
  colorPickerTextCom: {
    flexBasis: 100,
    maxWidth: 100,
    paddingRight: 5,
  },
  colorPickerTextComLg: {
    flexBasis: 160,
    maxWidth: 160,
    paddingRight: 5,
  },
  touchSmPd: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  fieldPdRtSp: {
    paddingRight: 50,
  },
  passHintTouchLg: {
    width: 50,
    paddingLeft: 5,
    paddingRight: 5,
  },
  touchSmPdRtTopBot: {
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  topSmPd: {
    paddingTop: 5,
  }
};
