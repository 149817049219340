import React from 'react';
import { View, Text, TouchableOpacity, } from 'react-native';
import styles from '../../../../Css';
import stylesResponsive from "../../../../CssResponsive";

const ProfileCommonItemsEdit = (props) => {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {
        type,  
        showList,
        data,
        checkBoxTick,
        onPressItem,
        idKey
    } = props;

    const listItem = (dataList, type, key) => {
        if (dataList && dataList.length > 0) {
            return dataList.map(res => {
                return <TouchableOpacity style={[styles.publicBtn, styles.flexRowAiCt, stylesRes.publicBtnMobile]} onPress={() => onPressItem(res)} dataSet={{ media: ids.publicBtnMobile }}>
                    <>
                        {res[key] ? checkBoxTick(res[key], type, key) : null}
                        <Text style={[showList ? styles.marginLeftText :styles.publicText, stylesRes.MobileTextPublic]} dataSet={{ media: ids.MobileTextPublic }}>{res.name ? res.name : ''}</Text>
                    </>
                </TouchableOpacity>
            })
        }
    }


    return (
        <View>
            <View style={[styles.userNameContainer, styles.publicBtnSec, stylesRes.publicBtnSecRes, stylesRes.userNameRes, styles.fieldColThreeFourth]} dataSet={{media:ids.mergestyle}}>
                <Text style={[styles.publicText, stylesRes.mobileText, styles.italicText, styles.publicTextProfile]} dataSet={{ media: ids.mobileText }}><Text style={styles.redTextStar}>*</Text>Selected items are public</Text>
                <View style={[styles.flexRow, styles.flexWrapView]}>
                    {listItem(data, type, idKey)}
                </View>
            </View>
        </View>
    );
};
export default ProfileCommonItemsEdit;
