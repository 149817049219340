import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import _ from 'underscore';
import Header from '../../components/Header/header';
import Collapsible from '../../components/Collapsible/Collapsible';
import UIText from '../../components/Text/text';
import ReportSelectGraph from '../../components/Report/ReportSelectGraph';
import ReportMetrics from '../../components/Report/ReportGraphMetrics';
import ReportGraphSymptoms from '../../components/Report/ReportGraphSymptoms';
import ReportGraphTherapies from '../../components/Report/ReportGraphTherapies';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import Graph from '../../components/Report/Graph';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import * as axiosCommon from '../../axios/axios-common';
import * as url from '../../store/actions/urls';
import * as actions from '../../store/actions/index';
import { REPORTS } from '../../navigation/path';
import { pendoTrackEvent } from '../../util/pendoConfig';
import ReportAttachGraphCommon from './ReportAttachGraphCommon';

class ReportAttachGraphScreen extends ReportAttachGraphCommon {
  constructor(props) {
    super(props);
    this.state = {
      selectedGraph: props.selectedGraph,
      visibleActivity: false,
      visibleTherapy: false,
      visibleSymptom: false,
      visibleMetric: false,
      graphid: null,
      saveWait: false,
    };
  }

  componentDidMount () {
    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
      pendoTrackEvent('Reports/@AddGraph');
    });
    this.props.navigation.addListener('blur', () => {
      this.props.showMobileMenu();
    });
  }

  onSelect = val => {
    this.setState({selectedGraph: val});
  };

  onSave = () => {
    if (this.props.graph.name === null) {
      this.props.commonErrorHandler('Select a Graph name');
    } else {
      this.setState({saveWait: true});
      this.saveGraphImage((res) => {
        axiosCommon.axiosPUT(
          url.UPLOAD_GRAPH_IMAGE_REPORT,
          () => {
            this.setState({saveWait: false});
            this.props.saveReportGraph();
            this.props.navigation.reset({
              index: 0,
              routes: [{ name: REPORTS.newReportPath }],
            });
          },
          {
            name: this.state.graphid,
            content: res,
          },
        );
      });
    }
  };

  cancelReport = () => {
    this.props.cancelGraph();
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: REPORTS.newReportPath }],
    });
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => {
            this.cancelReport();
          }}
          index={REPORTS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        {/* <View style={[stylesRes.sectionWrapper, stylesRes.fixedScreen]}> */}
        <SpaceBar />
        <KeyboardAwareScrollViewHoc>
          {/* <ScrollView style={styles.scrollViewArea}> */}
          <View style={styles.gridSection}>
            <View style={[stylesRes.contentSectionMax, stylesRes.layoutPdFixed]} dataSet={{media: ids.contentSectionMax}}>
              <View style={stylesRes.contBotPd} dataSet={{media: ids.contBotPd}}>
                <ReportSelectGraph />
                <View
                  style={[
                    styles.borderTopGrey,
                    styles.zIndexMin,
                    styles.pdBotLg,
                  ]}
                />
                <View style={[styles.gridRow, styles.zIndexMin]}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.secArea, styles.colPdLtRt]}>
                      <View>
                        <View style={stylesRes.detailGraph}>
                          <Graph isEdit={true} updateGrph={this.updateGrph} graphDivId={"chartdiv_attached_report"} />
                        </View>
                      </View>
                    </View>
                    <View style={[styles.bgSep, styles.comMgTopRg]} />
                  </View>
                </View>

                <View style={[styles.gridRow, styles.zIndexMin]}>
                  <View style={styles.gridColFull}>
                    <View style={styles.secArea}>
                      <View
                        style={[styles.SecCollapsible, styles.borderThinTop]}>
                        <Collapsible
                          title="Activities"
                          contentVisibility={this.state.visibleActivity}
                          handleOnPressCollapsible={() =>
                            this.setState({
                              visibleActivity: !this.state.visibleActivity,
                            })
                          }>
                          <ReportGraphTherapies type="activity" />
                        </Collapsible>

                        <Collapsible
                          title="Meds/Supplements"
                          contentVisibility={this.state.visibleTherapy}
                          handleOnPressCollapsible={() =>
                            this.setState({
                              visibleTherapy: !this.state.visibleTherapy,
                            })
                          }>
                          <ReportGraphTherapies />
                        </Collapsible>

                        <Collapsible
                          title="Symptoms"
                          contentVisibility={this.state.visibleSymptom}
                          handleOnPressCollapsible={() =>
                            this.setState({
                              visibleSymptom: !this.state.visibleSymptom,
                            })
                          }>
                          <ReportGraphSymptoms />
                        </Collapsible>

                        <Collapsible
                          title="Health Data"
                          contentVisibility={this.state.visibleMetric}
                          handleOnPressCollapsible={() =>
                            this.setState({
                              visibleMetric: !this.state.visibleMetric,
                            })
                          }>
                          <ReportMetrics />
                        </Collapsible>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.colPdLtRt]}>
                      <View style={styles.btnArea}>
                        <View style={stylesRes.btnViewSec}>
                          <View style={styles.fieldBtn}>
                            <View style={styles.btnBlockFull}>
                              <TouchableOpacity
                                style={[styles.submitSm, styles.disableBtn]}
                                underlayColor="rgba(0, 153, 168, 0.8)"
                                onPress={this.cancelReport}>
                                <View style={styles.btnSingleView}>
                                  <View
                                    style={[
                                      styles.btnSingle,
                                      styles.btnSingleCent,
                                    ]}>
                                    <UIText
                                      style={styles.submitText}
                                      title="Cancel"
                                    />
                                  </View>
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                          <View style={styles.fieldBtnSp} />
                          <View style={styles.fieldBtn}>
                            <View style={styles.btnBlockFull}>
                              <TouchableOpacity
                                style={[
                                  styles.submitSm,
                                  !_.isEmpty(this.props.graph) && this.props.graph.name !== null
                                    ? styles.btnActiveState
                                    : styles.btnBlurState
                                ]}
                                underlayColor="rgba(0, 153, 168, 0.8)"
                                onPress={this.onSave}
                                disabled={_.isEmpty(this.props.graph) || this.props.graph.name === null}>
                                <View style={styles.btnSingleView}>
                                  <View
                                    style={[
                                      styles.btnSingle,
                                      styles.btnSingleCent,
                                    ]}>
                                    {this.state.saveWait && (
                                      <View style={styles.actIndCom}>
                                        <ActivityIndicator size="small" />
                                      </View>
                                    )}
                                    <UIText
                                      style={styles.submitText}
                                      title="Save"
                                    />
                                  </View>
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* </ScrollView> */}
        </KeyboardAwareScrollViewHoc>
        {/* </View> */}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    graph: state.report.graph,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveReportGraph: () => dispatch(actions.saveReportGraph()),
    commonErrorHandler: errorDescription =>
      dispatch(actions.commonErrorHandler(errorDescription)),
    cancelGraph: () => dispatch(actions.cancelGraph()),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportAttachGraphScreen);
