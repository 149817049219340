import React from "react";
import { TouchableOpacity, Image } from "react-native";
import styles from "../../../Css";

const modelCloseButton = (props) => {
  const { closeModal } = props;
  return (
    <>
      <TouchableOpacity
        style={[styles.touchSpaceHeader]}
        onPress={closeModal}
      />
      <TouchableOpacity
        onPress={closeModal}
        style={[styles.closeViewTouchWrap, styles.closeViewTopLg]}
      >
        <Image
          style={[styles.closeIcon, styles.closeViewTouchBtnSm]}
          source={require("../../../assets/icons/close.png")}
        />
      </TouchableOpacity>
    </>
  );
};

export default (modelCloseButton);
