import React, { Component } from 'react';
import { View } from 'react-native';

export default class AbatractQuestionScrollView extends Component {
  constructor(props) {
    super(props);
    const items = Array.from(props.data.slice(0, 19));
    this.state = {
      items: items,
      hasMore: items.length < props.data.length,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      fatchMoreData: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth} = props;
    const items = Array.from(props.data.slice(0, 19));
    const hasMore = props.data.length > items.length;

    if (state.fatchMoreData) {
      return {
        fatchMoreData: false,
      };
    }
    return {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      items: items,
      hasMore: hasMore,
    };
  }

  render() {
    return <View />
  }
}

export const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
  };
};
