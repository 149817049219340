import React from "react";
import { Text, Image, View } from "react-native";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers
} from "react-native-popup-menu";
import {healthLibraryResourceStatus} from '../../Constant/index';
import styles from "../../Css";

const { Popover } = renderers;

const communityGroupResourcesPopupMenu = props => {
  const {
    publishedBy,
    resourceStatus, 
    isHidden, 
    type,
    onDownloadResource,
    onDeleteResource,
    onShowHideResource,
    onShareResource,
  } = props;
  return (
    <Menu renderer={Popover} rendererProps={{ preferredPlacement: "left" }}>
      <MenuTrigger>
      <View style={[styles.itemIconSingle, styles.itemSettingMenu]}>
        <Image
          style={[styles.settingIcon]}
          source={require("../../assets/icons/settings.png")}
        />
        </View>
      </MenuTrigger>
      <MenuOptions style={styles.dropDownList}>
        {publishedBy !== "PARTNER" && type && type === "Document" ? (
          <MenuOption style={styles.liItem} onSelect={onDownloadResource}>
            <Text style={styles.listText} allowFontScaling= {false}>Download</Text>
          </MenuOption>
        ) : null}
        {publishedBy !== "PARTNER" ? (
          <MenuOption style={styles.liItem} onSelect={onDeleteResource}>
            <Text style={styles.listText} allowFontScaling= {false}>Delete</Text>
          </MenuOption>
        ) : (
          <MenuOption style={styles.liItem} onSelect={onShowHideResource}>
            <Text style={styles.listText} allowFontScaling= {false}>{isHidden ? "Show" : "Hide"}</Text>
          </MenuOption>
        )}
        {resourceStatus !== healthLibraryResourceStatus.PRIVATE && (
          <MenuOption style={styles.liItem} onSelect={onShareResource}>
            <Text style={styles.listText} allowFontScaling= {false}>Share</Text>
          </MenuOption>
        )}
      </MenuOptions>
    </Menu>
  );
};
export default communityGroupResourcesPopupMenu;
