import React from "react";
import { View, Text, Image, Linking, Platform } from "react-native";
import Header from "../Header/header";
import Tabs from "../Tab/Tab";
import Collapsible from "../Collapsible/Collapsible";
import Question from "../../components/Question/View";
import MedicationsOrTherapiesPopupMenu from "../PopupMenu/medicationsOrTherapiesPopupMenu";
import Attachments from "../Attachment/Attachments";
import SpaceBar from "../SpaceBar/SpaceBar";
import KeyboardAwareScrollViewHoc from "../KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc";
import StorageBanner from "../../components/Banner/StorageBanner";
import PageContainer from "../../components/PageLayout/PageContainer";
import AddAttachment from '../../components/Button/addAttachment';
import stylesResponsive from "../../CssResponsive";
import styles from "../../Css";
import { ANDROID, WEB } from "../../util/platformWindowConfig";

const contentItems = (itemType, value, imagePath, stylesRes) => {
  return (
    <View style={[styles.borderThin, styles.indBlock, styles.colPd]}>
      <View style={[stylesRes.contListItemDesc, stylesRes.contListItemFull]}>
        <View style={styles.contListItemIconBlock}>
          <Image
            style={[styles.contTypeIcon]}
            resizeMode={"contain"}
            source={imagePath}
          />
        </View>
        <View style={styles.contListItemTextBlock}>
          {itemType === "email" || itemType === "phoneNumber" ? (
            <Text
              style={[styles.textPrime, styles.textOrange, styles.textUnderline]}
              allowFontScaling={false}
              onPress={() => {
                if (Linking.canOpenURL(value)) {
                  if (itemType === "phoneNumber") {
                    Linking.openURL("tel:" + value);
                  } else if (itemType === "email") {
                    Linking.openURL("mailto:" + value);
                  }
                }
              }}
            >
              {value}
            </Text>
          ) : (
            <Text style={[styles.textPrime]} allowFontScaling={false}>
              {value}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};

const contentGoogleMapLink = (props, imagePath, stylesRes) => {
  return (
    <View style={[styles.borderThin, styles.indBlock, styles.colPd]}>
      <View style={[stylesRes.contListItemDesc, stylesRes.contListItemFull]}>
        <View style={styles.contListItemIconBlock}>
          <Image
            style={[styles.contTypeIcon]}
            resizeMode={"contain"}
            source={imagePath}
          />
        </View>
        <View style={styles.contListItemTextBlock}>
          <Text
            style={[styles.textPrime, styles.textOrange, styles.textUnderline]}
            allowFontScaling={false}
            onPress={() => {
              Platform.OS === WEB
              ? window.open(
                  "https://www.google.com/maps/search/?api=1&query=" + props
                )
              : Linking.openURL(
                  Platform.OS === ANDROID
                    ? "geo:0,0?q=" + props
                    : "maps:0,0?q=" + props
                );
            }}
          >
            {Platform.OS === WEB ? 'Look Up on Google Maps' : props.replace(/[+]/g, '')}
          </Text>
        </View>
      </View>
    </View>
  );
};

const viewContact = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={true}
        handleOnPressBackButton={props.handleOnPressBackButton}
        index={props.index}
        navigation={props.navigation}
        route={props.route}
      />
      <SpaceBar />
      <StorageBanner />
      <KeyboardAwareScrollViewHoc hasNotch={props.hasNotch} extraScrollHeight={Platform.OS === ANDROID ? 200 : 70}>
        <View style={styles.gridSection}>
          <PageContainer smScreenFull={true}>
            <View style={[styles.contactTopicSection, styles.topicBlock]}>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={[styles.topicFullBlock, styles.borderThin]}>
                    <View style={[styles.flexRow]}>
                      <View
                        style={[
                          styles.topicContentSecWeb,
                          styles.colPdLtTopBot,
                        ]}
                      >
                        <Text style={styles.topicTitle} allowFontScaling={false}>
                          {props.contactName}
                        </Text>
                      </View>

                      <View style={styles.topicIconListWeb}>
                        <MedicationsOrTherapiesPopupMenu
                          handleDeleteTherapieConfirmation={
                            props.handleDeleteContactConfirmation
                          }
                          handleEditTherapie={props.handleEditContact}
                          nativeIdMenuTrigger={'viewContact3Dots'}
                          eventNameMenuTrigger={'ViewContact@ > 3Dots'}
                          nativeIdEdit={'viewContact3DotsEdit'}
                          eventNameEdit={'ViewContact@ > 3Dots > Edit'}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </PageContainer>
          <Tabs 
            pageContainer={true} 
            smScreenFull={true} 
            activeTab={props.activeTab}
            tabIndex={(res) => props.selectedTab(res)}
          >
            {/* Details tab */}
            <View
              title="Details"
              style={styles.tabContentSection}
            >
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={[stylesRes.tabContentFullArea, stylesRes.botPd]} dataSet={{media: ids.tabContentFullArea}}>
                    <PageContainer smScreenFull={true}>
                      <View style={[styles.contactRowSingle]}>
                        <View
                          style={[
                            styles.contactDetailSec,
                            //styles.colPdLtRt,
                          ]}
                        >
                          {props.organization
                            ? contentItems(
                                "organization",
                                props.organization,
                                require("../../assets/icons/house.png"),
                                stylesRes
                              )
                            : null}
                          {props.specialty
                            ? contentItems(
                                "consult",
                                props.specialty,
                                require("../../assets/icons/consult.png"),
                                stylesRes
                              )
                            : null}
                          {props.phoneNumber
                            ? contentItems(
                                "phoneNumber",
                                props.phoneNumber,
                                require("../../assets/icons/tel.png"),
                                stylesRes
                              )
                            : null}
                          {props.faxNumber
                            ? contentItems(
                                "faxNumber",
                                props.faxNumber,
                                require("../../assets/icons/fax.png"),
                                stylesRes
                              )
                            : null}
                          {props.emailaddress
                            ? contentItems(
                                "email",
                                props.emailaddress,
                                require("../../assets/icons/message.png"),
                                stylesRes
                              )
                            : null}

                          {Platform.OS === WEB && (props.address1 ||
                          props.city ||
                          props.stateProvince ||
                          props.country)
                            ? contentItems(
                                "address",
                                (props.address1 ? props.address1 + ", " : "") +
                                  (props.city ? props.city + ", " : "") +
                                  (props.stateProvince
                                    ? props.stateProvince + ", "
                                    : "") +
                                  (props.country ? props.country + ", " : "") +
                                  (props.zipCode ? props.zipCode : ""),
                                require("../../assets/icons/address.png"),
                                stylesRes
                              )
                            : null}

                          {props.address1 ||
                          props.city ||
                          props.stateProvince ||
                          props.country
                            ? contentGoogleMapLink(
                                (props.address1 ? props.address1 + ", +" : "") +
                                  (props.city ? props.city + ", +" : "") +
                                  (props.stateProvince
                                    ? props.stateProvince + ", +"
                                    : "") +
                                  (props.country ? props.country + ", +" : "") +
                                  (props.zipCode ? props.zipCode : ""),
                                Platform.OS === WEB ? require("../../assets/icons/map.png") : require("../../assets/icons/address.png"),
                                stylesRes
                              )
                            : null}
                        </View>
                      </View>
                    </PageContainer>

                    <PageContainer smScreenFull={true}>
                      {/* Notes*/}
                      <Collapsible
                        title="Notes"
                        contentVisibility={props.contentVisibilityNotes}
                        handleOnPressCollapsible={
                          props.handleOnPressCollapsibleNotes
                        }
                      >
                        {/* ZI-271 fix */}
                        {Boolean(props.notes) && (
                          <View style={[styles.collapseContent]}>
                            <View style={styles.gridRow}>
                              <View style={styles.gridColFull}>
                                <View style={[styles.fieldRowView]}>
                                  <View
                                    style={[
                                      styles.collapseDescription,
                                      styles.borderThin,
                                    ]}
                                  >
                                    <View
                                      style={[
                                        styles.collapseInContent,
                                        styles.colPdLtRt,
                                        styles.listTopBotPd,
                                      ]}
                                    >
                                      <View style={[styles.detailViewFull]}>
                                        <Text style={styles.smDescText} allowFontScaling={false}>
                                          {props.notes}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        )}
                      </Collapsible>
                      {/* Questions*/}
                      <Collapsible
                        title="To-dos"
                        contentVisibility={props.contentVisibilityQuestions}
                        handleOnPressCollapsible={
                          props.handleOnPressCollapsibleQuestions
                        }
                      >
                        {props.questions && props.questions.length > 0 && (
                          <View style={[styles.colPdTopLtRt, styles.questItemViewWrap, styles.collapseContentBg]}>
                            {props.questions.map((res) => (
                              <Question
                                key={res.id}
                                question={
                                  props.questionDigestProcessed.questions[
                                    res.questionId
                                  ]
                                }
                                questionDigest={props.questionDigest}
                                parentArtifactId={props.artifactId}
                              />
                            ))}
                          </View>
                        )}
                      </Collapsible>
                    </PageContainer>
                  </View>
                </View>
              </View>
            </View>
            {/* Attachments tab */}
            <View
              title="Attachments"
              activeCircle={props.hasAttachments}
              style={styles.tabContentSection}
              attachmentCategory="Contact-view"
            >
              <PageContainer smScreenFull={true}>
              <View style={[styles.attachWrapper, styles.attachWrapperPd]}>
              {props.userAttachmentsSelected &&
                props.userAttachmentsSelected.length !== 0 && (
                  <Attachments attachmentCategory="Contact-view" attachmentSpaceZero={true}/>
                )}
                  <AddAttachment 
                    attachmentCategory="Contact-view" 
                    btnText={"Add"} 
                    btnPosition={"left"} 
                    openCroper={props.openCroper}
                    tempCapturedImage={props.tempCapturedImage} 
                    onUploadCompleted={props.onUploadCompleted} 
                  />
                </View>
                </PageContainer>
            </View>
          </Tabs>
        </View>
      </KeyboardAwareScrollViewHoc>
    </View>
  );
};
export default (viewContact);
