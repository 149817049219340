import React from 'react';
import { View, TouchableOpacity, Linking, Platform } from 'react-native';
import TextOrCallus from './Common/textOrCallus';
import styles from '../../Css';
import { ANDROID, WEB } from '../../util/platformWindowConfig';

const callus = props => {
  return (
    <View style={[styles.detailItemRow]}>
      <TouchableOpacity
        disabled={Platform.OS === WEB}
        onPress={() => Linking.openURL((Platform.OS === ANDROID ? 'tel:' : 'tel://') + props.phoneNumber)}>
        <TextOrCallus phoneNumber={props.phoneNumber} />
      </TouchableOpacity>
    </View>
  );
};

export default (callus);
