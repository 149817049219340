import React from "react";

import styles from "../../../../Css";
import stylesResponsive from "../../../../CssResponsive";

import {View, Text, TouchableOpacity, Image} from "react-native";
import * as FontScaling from '../../../../components/Text/fontScaling';

const moreMenuItem = (props) => {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {moreMenuMobile, onPressMoreMenu, addMenu, selectedMenu, tabMenuIconView, hasNotifications} = props;

    return (
        <View style={[stylesRes.navLinkSingleItem]}>
            <TouchableOpacity
                onPress={onPressMoreMenu}
                style={[!addMenu ? styles.menuOverlayCom : styles.menuOverlay]}
                disabled={addMenu}
            >
                <View style={[styles.navLinkItemView]} nativeID={'menuMore'}>
                    <View style={[styles.navMenuCom]}>
                        {selectedMenu > 19 || moreMenuMobile ? (
                            <Image
                                style={[styles.navMainMenuIcon]}
                                source={require("../../../../assets/main-nav/more-active.png")}
                            />
                        ) : (
                            <Image
                                style={[styles.navMainMenuIcon]}
                                source={require("../../../../assets/main-nav/more.png")}
                            />
                        )}
                        {/* for ipad and tab */}
                        {Boolean(tabMenuIconView) && Boolean(hasNotifications) && (
                            <View style={[styles.notifyAvailableMenu, styles.notifyAvailableMenuSm]}/>
                        )}
                    </View>
                    <View style={[styles.navMenuText]}>
                        <Text
                            style={[
                                styles.textExMicSmBold,
                                styles.textAlignCenter,
                                selectedMenu > 19 || moreMenuMobile
                                    ? styles.menuTextSelected
                                    : styles.menuTextDefault,
                            ]}
                            allowFontScaling={false}
                            maxFontSizeMultiplier={FontScaling.fontScaleMd}
                        >
                            More
                        </Text>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    );
};

export default (moreMenuItem);
