import * as variableShadow from "./base/variableShadow";
import theme from "./base/theme";
import {Platform} from 'react-native';
export const cssQuickNote = {
  titleView: {
    flex: 1,
  },
  deleteIconWrapper: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  smBtnPd: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 2,
    paddingRight: 2,
    minHeight: 42,
    alignItems: "center",
    justifyContent: "center",
  },
  btnStretch: {
    alignItems: "stretch",
  },
  attachRowPdSm: {
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 0,
    paddingRight: 0,
  },
  borderNone: {
    borderWidth: 0,
  },
  iconViewSm: {
    marginRight: 10,
  },
  actionBtnPd: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  addPlusIcon: {
    width: 30,
    height: 30,
  },
  fieldShadowCom: {
    ...variableShadow.fieldShadow,
  },
  iconViewExSm: {
    marginRight: 5,
  },
  iconViewExSmLt: {
    marginLeft: 5,
  },
  playSmIcon: {
    width: 10,
    height: 6,
  },
  itemIconBtn: {
    minHeight: 47,
    alignItems: 'center',
    borderLeftWidth: 1,
    borderLeftColor: theme.COLOR_WHITE,
    minWidth: 50,
    zIndex: 9999999,
  },
  toDoSelection: {
    maxWidth: 40,
    flexBasis: 40,
    paddingRight: 10,
  },
  circleSelection: {
    width: 30,
    height: 30,
  },
  toDoContWrapper: {
    justifyContent: "flex-start",
  },
  commentsListComHr: {
    paddingTop: 3,
    borderBottomColor: '#d1d1d1',
    borderBottomWidth: 1,
    borderTopColor: '#d1d1d1',
    borderTopWidth: 1,
    position: 'relative',
  },
  countBubble: {
    width: 35,
    height: 39,
  },
  countBubbleText: {
    width: 35,
    height: 25,
    position: "absolute",
    justifyContent: "center",
    alignItems: 'center',
    left: 0,
  },
  moreCircleBubble: {
    width: 40,
    height: 40,
    position: "absolute",
    left: 20,
    top: -10,
    justifyContent: "center"
  },
  commentDetailsWrapper:{
    maxWidth: 150,
    flexBasis: 150,
    paddingLeft: 10,
    justifyContent: "flex-end",
    alignItems: 'center'
  },
  attachMainIcon: {
    width: 20,
    height: 20,
    tintColor: "#4A4B57"
  },
  questionDateWrapper: {
    paddingLeft: 10,
  },
  countBubbleArrow: {
    width: 13,
    height: 8,
  },
  countBubbleTouch: {
    width: 35,
    height: 25,
    justifyContent: "center",
    alignItems: 'center'
  },
  hrLineSp: {
    paddingBottom: 10,
  },
  commentContSecLg: {
    width: '100%'
  },
  textLightComment: {
    color: '#91929D'
  },
  commentBoxView: {
    ...variableShadow.commentBoxShadow,
    marginTop: 5,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    ...Platform.select({
      ios: {
        borderColor: 'transparent',
        borderWidth: 1,
      },
      android: {
        borderColor: 'transparent',
        borderWidth: 1,
      },
    }),
  },
  fieldShadowSpec: {
    ...variableShadow.fieldShadowSpec,
  },
  fixedBoxWrapper: {
    borderWidth: 1,
    borderColor: theme.COLOR_GREY_LIGHT_RG,
    borderTopWidth: 0,
  },
  btnSubmitWithIcon: {
    paddingLeft: 18,
  },
  btnSingleViewCom: {
    maxWidth: 90,
  },
  submitLoaderSm: {
    left: -7
  },
  btnMoreViewCom: {
    position: 'absolute',
    width: 60,
    right: 0,
    top: 0,
    zIndex: 999,
  },
};
