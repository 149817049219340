import React, {Component} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import moment from 'moment';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import Header from '../../components/Header/header';
import SuccessBox from '../../components/ConfirmationBox/successBox';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import RutineItemScrollView from './RutineItemScrollView';
import {TODOS} from '../../navigation/path';
import PageContainer from "../../components/PageLayout/PageContainer";
import { pendoTrackEvent } from '../../util/pendoConfig';

class AttachToJournal extends Component {
  state = {
    journalEntries: null,
    journalEntryTypes: null,
    filterJournalEntries: null,
    filterOn: false,
    saveWait: false,
    journalEntriesAdd: null,
    successModalVisible: false,
  };

  handleOnSave = () => {
    this.setState({saveWait: true});
    const artifactId = this.props.journalEntriesProcessed[
      this.props.journalEntriesAdd
    ].artifactId;
    const healthQuestion = {
      artifactId: artifactId,
      healthQuestion: {
        id: -1,
        content: this.props.saveQuestionToAttach,
        openedDate: Date.now(),
      },
    };

    this.props.onSaveHealthQuestion(
      healthQuestion,
      artifactId,
      (success) => {
        if (success) {
          this.props.removeAllJournal();
          this.props.clearJournalEntryFilter();
          this.setState({successModalVisible: true});
        }
        this.setState({saveWait: false});

        setTimeout(() => {
          this.setState({successModalVisible: false});
          this.props.navigation.push(
            TODOS.path, {
              from: this.props.route.name,
            },
          );
        }, 1500);
      },
      this.props.journalUserId,
    );
  };

  componentDidMount = () => {
    this.props.navigation.addListener('focus', () => {
      this.handleOnPressClearFilters();

      const allJournal = this.getJournal(
        this.props.journalEntriesProcessed,
        this.props.journalEntryTypesProcessed,
      );
      allJournal.sort((a, b) => {
        return b.entryDate - a.entryDate;
      });
      this.setState({
        journalEntries: allJournal,
        journalEntryTypes: this.props.journalEntryTypes,

        filterJournalEntries: allJournal,

        journalEntriesAdd: this.props.journalEntriesAdd,
      });
      this.props.hideMobileMenu();
      pendoTrackEvent('To-Dos/@AttachToJournal')
    });
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    });
  };

  componentWillUnmount = () => {
    // Clear Filter
    this.props.removeAllJournal();
    this.props.clearJournalEntryFilter();
    this.handleOnPressClearFilters();
    this.props.showMobileMenu();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      journalEntriesProcessed,
      journalEntryTypes,
      journalEntryFilter,
      journalEntriesAdd,
    } = nextProps;

    const allJournal = this.getJournal(
      journalEntriesProcessed,
      this.props.journalEntryTypesProcessed,
    );
    allJournal.sort((a, b) => {
      return b.entryDate - a.entryDate;
    });
    if (journalEntryFilter &&
      Object.keys(journalEntryFilter).length > 2
    ) {
      this.setState(
        {
          journalEntries: allJournal,
          journalEntryTypes,
          filterOn: this.props.journalEntryFilter ? true : this.state.filterOn,
          journalEntriesAdd,
        },
        () => {
          this.handleFilter(journalEntryFilter);
        },
      );
    } else {
      this.setState({
        journalEntries: allJournal,
        journalEntryTypes,
        filterJournalEntries: allJournal,
        journalEntriesAdd,
      });
    }
  }

  getJournal = (journalEntriesProcessed, journalEntryTypesProcessed) => {
    const journalEntries = Object.values(journalEntriesProcessed);
    return journalEntries.filter(function(journalEntrie) {
      return (
        journalEntryTypesProcessed[journalEntrie.entryType] &&
        !journalEntryTypesProcessed[journalEntrie.entryType].isAppointment
      );
    });
  };

  filterJournalEntriesByDate = (date, journalEntries) => {
    if (journalEntries) {
      return journalEntries.filter(function(journalEntries) {
        return (
          new moment(journalEntries.entryDate).format('YYYY-MM-DD') ===
          moment(date).format('YYYY-MM-DD')
        );
      });
    }
  };

  handleOnPressClearFilters = () => {
    this.setState({
      filterOn: false,
      filterJournalEntries: this.state.journalEntries,
    });
    this.props.clearJournalEntryFilter();
  };

  filterJournalEntriesBySearchKeyword = (journalEntries, searchKeyword) => {
    if (journalEntries) {
      return journalEntries.filter(function(journalEntrie) {
        return journalEntrie.title.toLowerCase().includes(searchKeyword.toLowerCase());
      });
    }
  };

  filterJournalEntriesByRoutineType = (journalEntries, routineType) => {
    if (journalEntries) {
      return journalEntries.filter(function(journalEntrie) {
        return journalEntrie.entryType === parseInt(routineType);
      });
    }
  };

  filterJournalEntriesByViewBy = (journalEntries, startDate, endDate) => {
    if (journalEntries) {
      return journalEntries.filter(function(journalEntrie) {
        return (
          journalEntrie.entryDate < startDate.getTime() &&
          journalEntrie.entryDate > endDate.getTime()
        );
      });
    }
  };

  filterJournalEntriesByStartDate = (journalEntries, startDate) => {
    const toDay = new Date();
    toDay.setHours(23, 59, 59, 999);
    if (journalEntries) {
      if (startDate.getTime() < toDay.getTime()) {
        return journalEntries.filter(function(journalEntrie) {
          return (
            journalEntrie.entryDate >= startDate.getTime() &&
            journalEntrie.entryDate <= toDay.getTime()
          );
        });
      } else {
        return journalEntries.filter(function(journalEntrie) {
          return journalEntrie.entryDate >= startDate.getTime();
        });
      }
    }
  };

  filterJournalEntriesByEndDate = (journalEntries, endDate) => {
    const toDay = new Date();
    toDay.setHours(0, 0, 0, 0);
    if (journalEntries) {
      if (endDate.getTime() < toDay.getTime()) {
        return journalEntries.filter(function(journalEntrie) {
          return journalEntrie.entryDate <= endDate.getTime();
        });
      } else {
        return journalEntries.filter(function(journalEntrie) {
          return (
            journalEntrie.entryDate <= endDate.getTime() &&
            journalEntrie.entryDate >= toDay.getTime()
          );
        });
      }
    }
  };

  handleFilter = filter => {
    let filterJournalEntries = [];

    if (
      filter.searchKeyword &&
      filter.searchKeyword !== '' &&
      this.state.journalEntries
    ) {
      filterJournalEntries = this.filterJournalEntriesBySearchKeyword(
        this.state.journalEntries,
        filter.searchKeyword,
      );
    } else if (this.state.journalEntries) {
      filterJournalEntries = this.state.journalEntries;
    }

    if (filter.hasOwnProperty('routineType') && filter.routineType !== 0) {
      filterJournalEntries = this.filterJournalEntriesByRoutineType(
        filterJournalEntries,
        filter.routineType,
      );
    }
    if (filter.viewBy === 1) {
      const startDate = new Date();
      const endDate = new Date();
      startDate.setHours(23, 59, 59, 59);
      endDate.setDate(endDate.getDate() - 30);
      endDate.setHours(0, 0, 0, 0);
      filterJournalEntries = this.filterJournalEntriesByViewBy(
        filterJournalEntries,
        startDate,
        endDate,
      );
    } else if (filter.viewBy === 2) {
      const startDate = new Date();
      const endDate = new Date();
      startDate.setHours(23, 59, 59, 59);
      endDate.setDate(endDate.getDate() - 90);
      endDate.setHours(0, 0, 0, 0);
      filterJournalEntries = this.filterJournalEntriesByViewBy(
        filterJournalEntries,
        startDate,
        endDate,
      );
    } else if (filter.viewBy === 3 && filter.startDate && filter.endDate) {
      const startDate = new Date(filter.startDate.getTime());
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(filter.endDate.getTime());
      endDate.setHours(23, 59, 59, 59);

      filterJournalEntries = this.filterJournalEntriesByViewBy(
        filterJournalEntries,
        endDate,
        startDate,
      );
    } else if (filter.viewBy === 3 && filter.startDate) {
      const startDate = new Date(filter.startDate.getTime());
      startDate.setHours(0, 0, 0, 0);

      filterJournalEntries = this.filterJournalEntriesByStartDate(
        filterJournalEntries,
        startDate,
      );
    } else if (filter.viewBy === 3 && filter.endDate) {
      const endDate = new Date(filter.endDate.getTime());
      endDate.setHours(23, 59, 59, 59);
      filterJournalEntries = this.filterJournalEntriesByEndDate(
        filterJournalEntries,
        endDate,
      );
    }
    if (filter.sortBy && filter.sortBy === 1) {
      filterJournalEntries.sort(function(a, b) {
        const aval = a.flagged ? 1 : 0;
        const bval = b.flagged ? 1 : 0;
        return bval - aval;
      });
    } else if (filter.sortBy && filter.sortBy === 2) {
      filterJournalEntries.sort(function(a, b) {
        return b.entryDate - a.entryDate;
      });
    } else if (filter.sortBy && filter.sortBy === 3) {
      filterJournalEntries.sort(function(a, b) {
        return a.entryDate - b.entryDate;
      });
    }
    this.setState({
      filterJournalEntries: filterJournalEntries,
    });
  };

  journalHasAttachments = artifactId => {
    let attachments = [];
    if (this.props.attachmentsCompleteDigest) {
      attachments = this.props.attachmentsCompleteDigest.userArtifactAttachments.filter(
        function(attachment) {
          return attachment.userArtifact === artifactId;
        },
      );
    }
    return attachments.length > 0;
  };

  handleOnPressBackButton = () => {
    this.props.navigation.push(
      TODOS.addToDoPath, {
        from: this.props.route.name,
      },
    );
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={true}
          showBack={true}
          backDetailed=""
          showClearFilters={this.state.filterOn}
          showNotify={false}
          clearFiltersHandler={this.handleOnPressClearFilters}
          handleOnPressBackButton={this.handleOnPressBackButton}
          handleFilter={this.handleFilter}
          type="journal"
          index={TODOS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        <View style={[styles.gridSection]}>

            <View style={[styles.contentSection]}>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  {/* Routines List Start Here */}
                  {this.state.journalEntries &&
                    this.state.filterJournalEntries &&
                    this.state.filterJournalEntries.length !== 0 && (
                      <RutineItemScrollView
                        data={this.state.filterJournalEntries}
                        journalEntryTypesProcessed={
                          this.props.journalEntryTypesProcessed
                        }
                        filterOn={true}
                      />
                    )}

                  {/* Routines List End Here */}
                </View>
              </View>
            </View>

            {/* Question */}
            <PageContainer>
            {this.state.journalEntriesAdd ? (
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={stylesRes.btnExSp} dataSet={{media: ids.btnExSp}}>
                    <View style={[styles.btnBlock, styles.btnMgLg]}>
                      <TouchableOpacity
                        onPress={this.handleOnSave}
                        disabled={this.state.saveWait}>
                        <View
                          style={[styles.submit, styles.submitMiniBtn]}
                          underlayColor="rgba(0, 153, 168, 0.8)">
                          {this.state.saveWait && (
                            <View style={styles.actIndCenter}>
                              <ActivityIndicator size="small" />
                            </View>
                          )}
                          <Text style={styles.submitText} allowFontScaling={false}>Save</Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            ) : (
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={stylesRes.btnExSp} dataSet={{media: ids.btnExSp}}>
                    <View style={[styles.btnBlock, styles.btnMgLg]}>
                      <TouchableOpacity disabled={!this.state.saveWait}>
                        <View
                          style={[
                            styles.submit,
                            styles.submitMiniBtn,
                            styles.disableBtn,
                          ]}
                          underlayColor="rgba(0, 153, 168, 0.8)">
                          <Text style={styles.submitText} allowFontScaling={false}>Select</Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            )}
        </PageContainer>
        </View>

        <SuccessBox
          visibleModal={this.state.successModalVisible}
          message="Success! Question has been added."
        />
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    journalEntryTypes: state.routines.journalEntryTypes,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,

    journalEntryFilter: state.routines.journalEntryFilter,
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,

    // Question
    saveQuestionToAttach: state.question.saveQuestionToAttach,
    journalEntriesAdd: state.question.journalEntriesAdd,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // Question
    onSaveHealthQuestion: (question, artifactId, callBack, journalUserId) =>
      dispatch(
        actions.saveHealthQuestion(
          question,
          artifactId,
          callBack,
          journalUserId,
        ),
      ),
    removeAllJournal: () => dispatch(actions.removeAllJournal()),
    clearJournalEntryFilter: () => dispatch(actions.clearJournalEntryFilter()),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttachToJournal);
