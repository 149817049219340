import React, {Component} from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import styles from '../../../../Css';
import Collapsible from '../../../Collapsible/Collapsible';
import ItemDeleteButton from '../../../Button/itemDeleteButton';
import PlusButtonCom from '../../../Button/plusButtonCom';
import MedicationAutoComplete from '../../../AutoComplete/CommonAutoComplete/Medication';
import UserItem from './UserItem';
import SystemItem from './SystemItem';
import UserItemView from './UserItemView';
import UserItemEdit from './UserItemEdit';
import { verifyDuplicateMedication } from '../../../../util/commonUiLogic';
import {getSortedMedicationDosagesWithTreatmentObject} from "../../../../store/actions/index";

class Medications extends Component {
  constructor(props) {
    super(props);
    const {dosages} = this.props;
    const sortedMedicationDosagesWithTreatmentObject = getSortedMedicationDosagesWithTreatmentObject(dosages, false, props.treatmentsProcessed);
    this.state = {
      collapsible: {
        medications: sortedMedicationDosagesWithTreatmentObject && sortedMedicationDosagesWithTreatmentObject.length > 0,
      },
      autocompleteOpen: false,
      dosages: sortedMedicationDosagesWithTreatmentObject,
      add: false,
      selectedValues: Medications.getSelectedValues(sortedMedicationDosagesWithTreatmentObject),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {newJournal, isCopyPrevious} = props;
    if (newJournal && isCopyPrevious && isCopyPrevious()) {
      const {dosages} = newJournal;
      const sortedMedicationDosagesWithTreatmentObject = getSortedMedicationDosagesWithTreatmentObject(dosages, false, props.treatmentsProcessed);
      return {
        collapsible: {
          medications: sortedMedicationDosagesWithTreatmentObject && sortedMedicationDosagesWithTreatmentObject.length > 0,
        },
        dosages: [...sortedMedicationDosagesWithTreatmentObject],
        selectedValues: Medications.getSelectedValues(sortedMedicationDosagesWithTreatmentObject),
      };
    }

    return null;
  }

  static getSelectedValues = (dosages) => {
    const selectedValues = {};

    if (dosages) {
      dosages.forEach(element => {
        const obj = element.treatmentObj;
        if (obj) {
          selectedValues[obj.id] = obj.id;
        }
      });
    }
    return selectedValues;
  };

  handleOnPressCollapsible = key => {
    const {collapsible} = this.state;
    let val = collapsible[key];
    collapsible[key] = !val;
    let add = false;
    // if (!dosages || (dosages && dosages.length === 0)) {
    //   add = true;
    // }

    this.setState({collapsible: collapsible, add: add});
  };

  updateAutocompleteOpen = val => {
    this.setState({autocompleteOpen: val});
  };

  getItem = (item, index) => {
    const {
      handleRedirectMedications,
      type,
      treatmentsProcessed,
      windowWidth,
      isNew
    } = this.props;
    if (item.type === 'SYSTEM' || item.type === 'CUSTOM') {
      return (
        <SystemItem
          data={item}
          index={index}
          key={'med_' + index}
          updateMedication={this.updateMedication}
          handleRedirectMedications={handleRedirectMedications}
          windowWidth={windowWidth}
          type={type}
          deleteItem={this.deleteItem}
          isNew={isNew}
        />
      );
    } else if (
      treatmentsProcessed[item.treatment] &&
      treatmentsProcessed[item.treatment].dosages &&
      treatmentsProcessed[item.treatment].dosages.length > 0 &&
      (treatmentsProcessed[item.treatment].dosages[0].quantity === null ||
        treatmentsProcessed[item.treatment].dosages[0].quantity === undefined)
    ) {
      return (
        <UserItemEdit
          data={item}
          index={index}
          key={'med_' + index}
          deleteItem={this.deleteItem}
          updateMedication={this.updateMedication}
          handleRedirectMedications={handleRedirectMedications}
          windowWidth={windowWidth}
          type={type}
          isNew={isNew}
        />
      );
    } else if (type === 'RT') {
      return (
        <UserItemView
          data={item}
          index={index}
          key={'med_' + index}
          deleteItem={this.deleteItem}
          updateMedication={this.updateMedication}
          handleRedirectMedications={handleRedirectMedications}
          windowWidth={windowWidth}
        />
      );
    } else if (treatmentsProcessed[item.treatment]) {
      return (
        <UserItem
          data={item}
          index={index}
          key={'med_' + index}
          type={type}
          deleteItem={this.deleteItem}
          updateMedication={this.updateMedication}
          handleRedirectMedications={handleRedirectMedications}
          windowWidth={windowWidth}
          isNew={isNew}
        />
      );
    }

    return null;
  };

  onPressItem = (item, type) => {
    const {dosages, selectedValues} = this.state;
    const { updateMedication, treatmentsProcessed, type: componentType } = this.props;
    let existItem = true;
    this.setAdd();

    if (dosages && dosages.length > 0) {
      if (type === 'USER' || type === 'SYSTEM') {
        dosages.forEach(i => {
          if (i.treatment === item.data.id) {
            existItem = false;
          }
        });
      } else {
        dosages
          .filter(d => d.id === -1)
          .forEach(i => {
            if (
              i.type === 'USER'
                ? treatmentsProcessed[i.treatment].name === item
                : i?.treatmentObj?.name === item
            ) {
              existItem = false;
            }
          });
      }
    }

    if (existItem) {
      if (type === 'USER') {
        dosages.push({
          id: -1,
          new: true,
          quantity: componentType === 'R'
            ? null
            : item.data.dosages && item.data.dosages[0]
              ? item.data.dosages[0].quantity
              : null,
          units:
            item.data.dosages && item.data.dosages[0]
              ? item.data.dosages[0].units
              : null,
          treatment: item.data.id,
          type: type,
          accept: true,
        });

        const obj = treatmentsProcessed[item.data.id];
        if (obj) {
          selectedValues[obj.id] = obj.id;
        }
      } else if (type === 'SYSTEM') {
        let currSummaryName = null;

        if (item.data.summary.match('.+?(?=\\(DIN:)')) {
          currSummaryName = item.data.summary.match('.+?(?=\\(DIN:)');
        } else if (item.data.summary.match('.+?(?=\\(NHP:)')) {
          currSummaryName = item.data.summary.match('.+?(?=\\(NHP:)');
        }

        selectedValues[item.data.summary] = item.data.summary;
        dosages.push({
          name: currSummaryName[0],
          notes: null,
          new: true,
          prescribedBy: null,
          identificationNumber: item.data.treatmentId,
          treatmentType: item.data.treatmentType,
          active: true,
          isActive: true,
          dosages: [
            {
              quantity: componentType === 'R' ? null : 1,
              frequency: 1,
              frequencyType: 'DAILY',
              units: null,
            },
          ],
          displayProperties: {
            color: null,
            dashedLine: true,
          },
          type: type,
          accept: true,
          userDosingLog: {
            quantity: 1,
            units: null,
          },
        });
      } else {
        selectedValues[item] = item;
        dosages.push({
          name: item,
          notes: null,
          new: true,
          prescribedBy: null,
          identificationNumber: null,
          treatmentType: 2,
          active: true,
          isActive: true,
          dosages: [
            {
              quantity: componentType === 'R' ? null : 1,
              frequency: 1,
              frequencyType: 'DAILY',
              units: null,
            },
          ],
          displayProperties: {
            color: null,
            dashedLine: true,
          },
          type: type,
          accept: true,
          userDosingLog: {
            quantity: 1,
            units: null,
          },
        });
      }

      this.setState({ dosages: dosages, selectedValues: selectedValues }, () => {
        updateMedication(dosages)
      }

      );
    }
  };

  setAdd = () => {
    const {add} = this.state;
    this.setState({add: !add});
  };

  executeDuplicateChecker = (name, din_npn, unit, quantity) => {
    const { listTherapie } = this.props;
    let res = false;

    verifyDuplicateMedication(name, din_npn, unit, quantity, listTherapie, (exist, msg) => {
      if (exist) {
        res = true;
      }
    });

    return res;
  }

  checkDuplicates = () => {

    const { dosages = [] } = this.state;
    let res = false;

    try {
      for (const property of dosages) {
        if (property.type && property.type !== "USER") {
          const checkResult = this.executeDuplicateChecker(property.name, property.identificationNumber, property.dosages[0].units, property.dosages[0].quantity);
          if (checkResult) {
            res = true;
            break;
          }
        }
      }
    } catch (error) {
      console.log('error', error);
    }

    return res;
  }

  updateMedication = (index, data) => {
    const {dosages} = this.state;
    const { updateMedication, isInvalidName, isMedDuplicate } = this.props;
    dosages[index] = data;
    this.setState(
      {
        dosages: dosages,
      },
      () => updateMedication(dosages),
    );
    let hasInvalidNames = this.checkInvalidName();
    let hasDuplicates = this.checkDuplicates();
    isInvalidName(hasInvalidNames);
    isMedDuplicate(hasDuplicates);
  };

  checkInvalidName = () => {
    let isInvalidName = this.state.dosages.some(dosage => {
      return dosage?.name?.trim() === '';
    });
    return isInvalidName;
  }

  deleteItem = index => {
    const {dosages} = this.state;
    const { updateMedication, isInvalidName, isMedDuplicate, treatmentsProcessed } = this.props;
    dosages.splice(index, 1);
    const sortedMedicationDosagesWithTreatmentObject = getSortedMedicationDosagesWithTreatmentObject(dosages, false, treatmentsProcessed);
    this.setState(
      {
        dosages: dosages,
        selectedValues: Medications.getSelectedValues(sortedMedicationDosagesWithTreatmentObject),
      },
      () => updateMedication(dosages),

    );

    let hasInvalidNames = this.checkInvalidName();
    let hasDuplicates = this.checkDuplicates();
    isInvalidName(hasInvalidNames);
    isMedDuplicate(hasDuplicates);
  };

  render() {
    const {dosages, add, selectedValues, collapsible} = this.state;
    const {plusButton, disabledScroll, type} = this.props;
    return (
      <Collapsible
        title={'Medications/Supplements'}
        contentVisibility={collapsible.medications}
        handleOnPressCollapsible={() =>
          this.handleOnPressCollapsible('medications')
        }
        style={[styles.collapseTitle]}
        customTitleStyle={true}
        plusButton={plusButton}
        borderNone={true}
        nativeId={type === 'R' ? 'journalMedication' : 'routineMedication'}
      >
        <View style={[styles.collapseContent, styles.colPdTopLtRt]}>
          {dosages.map((item, index) => this.getItem(item, index))}

          {!Boolean(add) && (
            <View style={[styles.btnArea, styles.btnAreaPd, styles.zIndexMin]}>
              <PlusButtonCom
                btnPosition={'left'}
                btnText={'Add'}
                touchableOnPress={this.setAdd}
                nativeId={type === 'R' ? 'journalAddMedication' : 'routineAddMedication'}
              />
            </View>
          )}
        </View>

        {Boolean(add) && (
          <View
            style={[
              styles.fieldRowCom,
              styles.fieldRowNoMg,
              styles.zIndexAutoComplete,
              styles.colPdLtRtBot,
            ]}>
            <View style={[styles.fieldRowComWrapper]}>
              <View style={[styles.fieldColStretch]}>
                <MedicationAutoComplete
                  updateAutocompleteOpen={this.updateAutocompleteOpen}
                  onPressItem={this.onPressItem}
                  selectedValues={selectedValues}
                  disabledScroll={disabledScroll}
                />
              </View>
              <View style={[styles.fieldColSp]} />
              <View style={[styles.fieldColDelete]}>
                <ItemDeleteButton touchableOnPress={this.setAdd} />
              </View>
            </View>
          </View>
        )}
      </Collapsible>
    );
  }
}

const mapStateToProps = state => {
  return {
    newJournal: state.routineTemplate.newJournal,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    listTherapie: state.routines.treatments,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    isInvalidName: flag => dispatch(actions.hasEntryMedicationsInvalidNames(flag)),
    isMedDuplicate: flag => dispatch(actions.hasEntryMedicationsDuplicate(flag)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Medications);
