const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const days = {
  monday: "Mon",
  tuesday: "Tue",
  wednessday: "Wed",
  thursday: "Thu",
  friday: "Fri",
  saturday: "Sat",
  sunday: "Sun"
}

export const formatDate = (date, format) => {
  if (format === "dd mm 'YY") {
    var year = date.getFullYear().toString();
    var day = date.getDate().toString();
    return (
      (day.length === 1 ? "0" + day : day) +
      " " +
      month[date.getMonth()] +
      " '" +
      year.slice(-2)
    );
  }
};

export const timeMap = {
  '00': "12 AM",
  '01': "1 AM",
  '02': "2 AM",
  '03': "3 AM",
  '04': "4 AM",
  '05': "5 AM",
  '06': "6 AM",
  '07': "7 AM",
  '08': "8 AM",
  '09': "9 AM",
  1: "1 AM",
  2: "2 AM",
  3: "3 AM",
  4: "4 AM",
  5: "5 AM",
  6: "6 AM",
  7: "7 AM",
  8: "8 AM",
  9: "9 AM",
  10: "10 AM",
  11: "11 AM",
  12: "12 PM",
  13: "1 PM",
  14: "2 PM",
  15: "3 PM",
  16: "4 PM",
  17: "5 PM",
  18: "6 PM",
  19: "7 PM",
  20: "8 PM",
  21: "9 PM",
  22: "10 PM",
  23: "11 PM",
  0: "12 AM",
};


export const getFormattedTime = time => {
  const [h = 0, m = 0] = time && Array.isArray(time) ? time : time.split(":");
  const [hr, tv] = timeMap[h].split(" ");
  return `${parseInt(hr)}:${m} ${tv}`;
} 

export const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
} 

export const pickerMonthAccordingToBirth = (month) => {

  const monthList = [
    { label: 'Jan', value: '0', key: 'jan' },
    { label: 'Feb', value: '1', key: 'feb' },
    { label: 'Mar', value: '2', key: 'mar' },
    { label: 'Apr', value: '3', key: 'apr' },
    { label: 'May', value: '4', key: 'may' },
    { label: 'Jun', value: '5', key: 'jun' },
    { label: 'Jul', value: '6', key: 'jul' },
    { label: 'Aug', value: '7', key: 'aug' },
    { label: 'Sep', value: '8', key: 'sep' },
    { label: 'Oct', value: '9', key: 'oct' },
    { label: 'Nov', value: '10', key: 'nov' },
    { label: 'Dec', value: '11', key: 'dec' },
  ];

  const newArrayWithInit = [{ label: 'Month', value: '-1', key: 'monthInit' }]


  switch(month){
    case '01':
      return newArrayWithInit.concat(monthList);
    case '02' :
      return newArrayWithInit.concat(monthList.splice(1));
    case '03' :
      return newArrayWithInit.concat(monthList.splice(2));
    case '04' :
      return newArrayWithInit.concat(monthList.splice(3));
    case '05' :
      return newArrayWithInit.concat(monthList.splice(4));
    case '06' :
      return newArrayWithInit.concat(monthList.splice(5));
    case '07' :
      return newArrayWithInit.concat(monthList.splice(6));
    case '08' :
      return newArrayWithInit.concat(monthList.splice(7));
    case '09' :
      return newArrayWithInit.concat(monthList.splice(8));
    case '10' :
      return newArrayWithInit.concat(monthList.splice(9));
    case '11' :
      return newArrayWithInit.concat(monthList.splice(10));
    case '12' :
      return newArrayWithInit.concat(monthList.splice(11));
    default:
      break;

  }
}
