import axios from './axios-config-common';
import {fileDownload, reportDownload, reportPdfDownload} from './common';
import {Platform, Linking} from 'react-native';
import NavService from '../navigation/navigationService';
import { OTHER } from '../navigation/path';
import { WEB } from '../util/platformWindowConfig';


axios.interceptors.response.use(
  async resp => resp,
  (error) => {
    if (error.message === 'Network Error') NavService.navigate(OTHER.noNetwork);
    return Promise.reject(error);
  }
);


export const axiosGET = (url, calbackFunc, data) => {
  let body = {};
  if (data) {
    body = data;
  }

  axios
    .get(url, {
      headers: {
        'Content-Typ': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
      },
      mode: 'no-cors',
      params: body,
    })
    .then(response => {
      calbackFunc({type: 'SUCCESS', responseDto: response});
    })
    .catch(error => {
      if (error === undefined || error.response === undefined) {
        calbackFunc({
          type: 'ERROR',
          errorDto: {data: {error: 'NETWORK_ERROR'}},
        });
      } else if (error.response) {
        if (error.response.status === 403) {
          calbackFunc({type: 'ERROR', errorDto: 'SESSION_TIME_OUT'});
        } else {
          calbackFunc({type: 'ERROR', errorDto: error.response});
        }
      }
    });
};

export const axiosPOST = (url, calbackFunc, data) => {
  axios
    .post(url, data)
    .then(response => {
      calbackFunc({type: 'SUCCESS', responseDto: response});
    })
    .catch(error => {
      if (error === undefined || error.response === undefined) {
        calbackFunc({
          type: 'ERROR',
          errorDto: {data: {error: 'NETWORK_ERROR'}},
        });
      } else {
        if (error.response.status === 403) {
          calbackFunc({type: 'ERROR', errorDto: 'SESSION_TIME_OUT'});
        } else {
          calbackFunc({type: 'ERROR', errorDto: error.response});
        }
      }
    });
};

export const axiosPOSTPdfDownload = (url, data, fileName, callBack) => {
  const header = {
    url,
    method: 'POST',
    responseType: 'blob',
    data: data,
  };
  axios(header)
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      callBack(true);
    })
    .catch(error => {
      if (error === undefined || error.response === undefined) {
        callBack(false, 'NETWORK_ERROR');
      } else {
        if (error.response.status === 403) {
          callBack(false, 'SESSION_TIME_OUT');
        } else {
          callBack(false, error.response);
        }
      }
    });
};

export const axiosPdfDownload = async (
  data,
  fileName,
  journalUserId,
  callBack,
) => {
  reportDownload(data, fileName, journalUserId, callBack);
};

export const axiosPdfDownloadMobile = async (
  url,
  fileName,
  callBack,
  journalUserId,
  data,
  isPostRequest
) => {
  reportPdfDownload(url, fileName, callBack, journalUserId, data, isPostRequest);
};

export const axiosFileUploadPOST = (url, calbackFunc, data, onUploadProgress, cancelToken) => {
  axios
    .post(url, data, {
      cancelToken : cancelToken && cancelToken.token,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onUploadProgress
    })
    .then(response => {
      calbackFunc({type: 'SUCCESS', responseDto: response});
    })
    .catch(error => {
      if (error && error.message === 'UPLOADCANCELLED') {
        calbackFunc({type: 'UPLOADCANCELLED', errorDto: 'UPLOADCANCELLED'});
      } else if (error === undefined || error.response === undefined) {
        calbackFunc({type: 'ERROR', errorDto: {data: 'NETWORK_ERROR'}});
      } else {
        if (error.response.status === 403) {
          calbackFunc({type: 'ERROR', errorDto: 'SESSION_TIME_OUT'});
        } else {
          calbackFunc({type: 'ERROR', errorDto: error.response});
        }
      }
    });
};

export const axiosImageUploadPUT = (url, calbackFunc, data) => {
  axios
    .put(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      calbackFunc({type: 'SUCCESS', responseDto: response});
    })
    .catch(error => {
      if (error === undefined || error.response === undefined) {
        calbackFunc({
          type: 'ERROR',
          errorDto: {data: {error: 'NETWORK_ERROR'}},
        });
      } else {
        if (error.response.status === 403) {
          calbackFunc({type: 'ERROR', errorDto: 'SESSION_TIME_OUT'});
        } else {
          calbackFunc({type: 'ERROR', errorDto: error.response});
        }
      }
    });
};

export const axiosPUT = (url, calbackFunc, data) => {
  axios
    .put(url, data)
    .then(response => {
      calbackFunc({type: 'SUCCESS', responseDto: response});
    })
    .catch(error => {
      
      if (error === undefined || error.response === undefined) {
        calbackFunc({
          type: 'ERROR',
          errorDto: {data: {error: 'NETWORK_ERROR'}},
        });
      } else {
        if (error.response.status === 403) {
          calbackFunc({type: 'ERROR', errorDto: 'SESSION_TIME_OUT'});
        } else {
          calbackFunc({type: 'ERROR', errorDto: error.response});
        }
      }
    });
};

export const axiosDELETE = (url, calbackFunc, payload) => {
  axios
    .delete(url, {data: payload})
    .then(response => {
      calbackFunc({type: 'SUCCESS', responseDto: response});
    })
    .catch(error => {
      if (error === undefined || error.response === undefined) {
        calbackFunc({
          type: 'ERROR',
          errorDto: {data: {error: 'NETWORK_ERROR'}},
        });
      } else {
        if (error.response.status === 403) {
          calbackFunc({type: 'ERROR', errorDto: 'SESSION_TIME_OUT'});
        } else {
          calbackFunc({type: 'ERROR', errorDto: error.response});
        }
      }
    });
};

export const axiosDownload = (header, fileName, callBack) => {
  axios(header).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
    if (callBack) {
      callBack();
    }
  });
};

export const actualDownload = async (filename, url, callBack, isFromViewFile = false) => {
  fileDownload(filename, url, callBack, false, isFromViewFile);
};

export const axiosViewFile = url => {
  if (Platform.OS === WEB) {
    let win = window.open(url, '_blank');
    if (win) {
      win.focus();
    }
  } else {
    Linking.openURL(url);
  }
  // axios(header).then(response => {
  //   const url = window.URL.createObjectURL(response.data);

  // });
};

export const axiosGETImage = (url, calbackFunc) => {
  axios
    .get(url, {
      headers: {
        responseType: 'arraybuffer',
      },
    })
    .then(response => {
      calbackFunc({type: 'SUCCESS', responseDto: response});
    })
    .catch(error => {
      if (error === undefined || error.response === undefined) {
        calbackFunc({
          type: 'ERROR',
          errorDto: {data: {error: 'NETWORK_ERROR'}},
        });
      } else {
        if (error.response.status === 403) {
          calbackFunc({type: 'ERROR', errorDto: 'SESSION_TIME_OUT'});
        } else {
          calbackFunc({type: 'ERROR', errorDto: error.response});
        }
      }
    });
};

export const axiosPOSTSendEmailReport = (url, data, callBack) => {
  const header = {
    url,
    method: 'POST',
    data: data,
  };
  axios(header)
    .then(response => {
      callBack(true, response);
    })
    .catch(error => {
      if (error === undefined || error.response === undefined) {
        callBack(false, 'NETWORK_ERROR');
      } else {
        if (error.response.status === 403) {
          callBack(false, 'SESSION_TIME_OUT');
        } else {
          callBack(false, error.response);
        }
      }
    });
};

export const axiosGraphPdfDownload = (url, fileName, callBack) => {
  if (Platform.OS === WEB) {
    axiosPdfDownloadWeb(url, fileName, callBack);
  } else {
    axiosPdfDownloadMobile(url, fileName, callBack);
  }
}

export const axiosPdfDownloadWeb = (url, fileName, callBack) => {
  const header = {
    url,
    method: 'GET',
    responseType: 'blob',
  };
  axios(header)
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      callBack(true);
    })
    .catch(error => {
      if (error === undefined || error.response === undefined) {
        callBack(false, 'NETWORK_ERROR');
      } else {
        if (error.response.status === 403) {
          callBack(false, 'SESSION_TIME_OUT');
        } else {
          callBack(false, error.response);
        }
      }
    });
};
