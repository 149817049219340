import React, {Component} from 'react';
import TextInputPlusMinus from '../../TextInput/TextInputPlusMinus';
import * as FieldDetails from '../../TextInput/fieldDetails';

class Quantity extends Component {
  constructor(props) {
    super(props);
    const {quantity, units} = this.props;
    this.state = {
      quantity: quantity ? quantity : 0,
      units: units ? units : 'minutes',
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const {units} = props;
    this.setState({units: units});
  }

  onChange = val => {
    const { updateDuration } = this.props;

    this.setState({ quantity: val }, () => updateDuration(val));
  };

  render() {
    const {quantity, units} = this.state;
    return (
      <TextInputPlusMinus
        title={'Quantity'}
        fieldBg={FieldDetails.BGWHITE}
        fieldAlign={FieldDetails.ALIGNCENTER}
        value={quantity}
        onChange={this.onChange}
        type={'ACTIVITY'}
        units={units}
        fieldLt={this.props.fieldLt}
        max={null}

      />
    );
  }
}

export default (Quantity);
