import React, { Component } from "react";
import {
  View,
  ScrollView,
  Platform
} from "react-native";
import { connect } from "react-redux";
import _ from "underscore";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";
import Header from "../../../components/Header/header";
import SpaceBar from "../../../components/SpaceBar/SpaceBar";
import moment from "moment";
import { GRAPHS, DASHBOARD, PROFILE } from "../../../navigation/path";
import * as actions from "../../../store/actions/index";
import PageContainer from "../../../components/PageLayout/PageContainer";
import SectionTitle from "../../../components/Text/sectionTitle";
import SearchField from "../../../components/Search/searchField";
import PlusButtonDark from "../../../components/Button/plusButtonDark";
import PremiumOvalButton from "../../../components/Button/premiumOvalButton";
import SimpleConfirmBox from "../../../components/ConfirmationBox/simpleConfirmBox";
import { getAccountType } from "../../../util/subscriptionInformation";
import { freeUserMaximumGraphsAllow } from "../../../Constant";
import NoResultFound from "../../../components/Search/noResultFound";
import GraphBanner from "../../../components/Banner/GraphBanner";
import NoGraphFound from "../../../components/Search/noGraphFound";
import ActivityIndicator from "../../../components/ActivityIndicator/activityIndicator";
import * as axiosCommon from "../../../axios/axios-common";
import * as url from "../../../store/actions/urls";
import GraphItem from "../../../components/Settings/RollingGraphs/CardItem/graphItem";
import { handleCommonBackNavigation } from "../../../navigation/RootNavigation";
import { pendoTrackEvent } from "../../../util/pendoConfig";
import { WEB } from "../../../util/platformWindowConfig";

class RollingGraphsScreen extends Component {
  constructor(props) {
    super(props);
    const accountType = getAccountType(props.userSubscriptions);
   // let from = null;
    //TO-DO remove previous navigation changes
    // if (this.props.location.state && this.props.location.state.from) {
    //   from = this.props.location.state.from;
    // }
    // if (this.props.route?.params && this.props.route?.params.from) {
    //   from = this.props.route?.params.from;
    // }
    this.state = {
      filteredGraphs: [],
      accountType: accountType,
      visibleModal: false,
      selectedId: false,
      searchStr:
        //TO-DO remove previous navigation changes
        // from &&
        // //this.props.history.action === "PUSH" &&
        // from.includes(GRAPHS.addGraphPath) &&
        // this.props.graphFilter
        //   ? this.props.graphFilter
        //   : "",
        "",
      enableGraphCount: 0,
      downloadwait: false,
      graphId: null,
      loading: props.dashboard && props.dashboard.length > 0
    };
    this.tapCount = 0;
  }

  componentDidMount() {
    //TO-DO remove previous navigation changes
    // if (this.state.filteredGraphs) {
    //   let enableGraphCount = this.state.enableGraphCount;
    //   const enableGraphFilter = this.state.filteredGraphs.filter(graph => graph.status === 'ENABLED');
    //   const dashboardSort = this.groupedAndFilterGraphs(this.state.filteredGraphs);

    //   if (enableGraphFilter && enableGraphFilter.length > 0) {
    //     enableGraphCount = enableGraphFilter.length;
    //   }
    //   this.setState({
    //     filteredGraphs: dashboardSort,
    //     enableGraphCount: enableGraphCount,
    //   });
    //   this.filterGraphs(this.state.searchStr);
    // }
    const {dashboard} = this.props;
    this.props.navigation.addListener('focus', () => {
      let searchString = null;
      let from = null;
      if (this.props.route?.params && this.props.route?.params.from) {
        from = this.props.route?.params.from;
      }
      if (this.props.graphFilter && from && (from.includes(GRAPHS.addGraphPath) || from.includes(GRAPHS.editGraphPath))) {
        searchString = this.props.graphFilter;
      }
      if (dashboard && dashboard.length > 0) {
        let enableGraphCount = this.state.enableGraphCount;
        const enableGraphFilter = dashboard.filter(graph => graph.status === 'ENABLED');
        const dashboardSort = this.groupedAndFilterGraphs(dashboard);

        if (enableGraphFilter && enableGraphFilter.length > 0) {
          enableGraphCount = enableGraphFilter.length;
        }
        this.setState({
          filteredGraphs: dashboardSort,
          enableGraphCount: enableGraphCount,
          loading: false,
        });
        this.filterGraphs(searchString);
      }
      pendoTrackEvent('Graphs@');
    });
    this.props.navigation.addListener('blur', () => {
      this.tapCount = 0;
      this.props.navigation.setParams({from: GRAPHS.path});
    });
  }

  redirectToEdit = (id, name) => {
    this.tapCount = this.tapCount + 1;
    if (this.tapCount === 1) {
      this.props.resetRollingGraph();
      this.props.selectGraph(this.props.dashboardProcessed[id], () => {
        this.props.navigation.navigate(
          GRAPHS.editGraphPath, {
            graphName: name,
            from: GRAPHS.path
          });
      });
    }
  };

  groupedAndFilterGraphs = (graphs) => {
    let dashboardSort = [];
    const enableGraphFilter = graphs.filter(graph => graph.status === 'ENABLED')
      .sort((a, b) => b.createdOn - a.createdOn);
    const disableGraphFilter = graphs.filter(graph => graph.status !== 'ENABLED')
      .sort((a, b) => b.createdOn - a.createdOn);
    dashboardSort = enableGraphFilter.concat(disableGraphFilter);
    
    return dashboardSort;
  }

  filterGraphs = (searchStr) => {
    let graphs = this.groupedAndFilterGraphs(this.props.dashboard);
    if (searchStr) { 
      const filteredGraphs = graphs.filter(
        (type) =>
          type.name && type.name.toLowerCase().includes(searchStr.toLowerCase())
      );

      this.setState({
        searchStr: searchStr,
        filteredGraphs: filteredGraphs,
      });
    } else {
      this.setState({
        searchStr: searchStr,
        filteredGraphs: graphs,
      });
    }
    this.props.setGraphFilter(searchStr);
  };

  deleteGraph = () => {
    let enableGraphCount = this.state.enableGraphCount;
    this.setState({ wait: true });
    this.props.deleteDashboardGraph(
      this.state.selectedId,
      (res) => {
        let graphs = [...this.state.filteredGraphs];
        if (res) {
          //this.filterGraphs(this.state.searchStr);
          graphs = graphs.filter((item) => item.id !== this.state.selectedId);

          let enableGraphFilter = graphs.filter(
            (graph) => graph.status === "ENABLED"
          );
          if (enableGraphFilter && enableGraphFilter.length > 0) {
            enableGraphCount = enableGraphFilter.length;
          }
        }
        this.setState({
          wait: false,
          visibleModal: false,
          selectedId: null,
          filteredGraphs: graphs,
          enableGraphCount: enableGraphCount,
        });
      },
      this.props.journalUserId
    );
  };

  downloadGraph = (id , name, startDate, endDate) => {
    this.setState({ downloadwait: true, graphId: id });
    if (Platform.OS === WEB) {
      let downloadUrl = url.DOWNLOAD_DASHBOARD_GRAPH + id;
      if (this.props.journalUserId) {
        downloadUrl += "?journalUserId=" + this.props.journalUserId;
      }
      axiosCommon.axiosPOSTPdfDownload(
        downloadUrl,
        {
          startDate: startDate
            ? moment(startDate, "x").format("DD MMM 'YY")
            : "",
          endDate: endDate
            ? moment(endDate, "x").format("DD MMM 'YY")
            : ""
        },
        name + ".pdf",
        (success, res) => {
          if (!success && res.status === 400) {
            this.props.commonErrorHandler(
              "Edit and Save again to download the graph."
            );
          }
          this.setState({ downloadwait: false, graphId: null });
        }
      );
    } else {
      axiosCommon.axiosPdfDownloadMobile(
        url.DOWNLOAD_DASHBOARD_GRAPH + id,
        name + ".pdf",
        (res) => {
          if (res.status === 400) {
            this.props.commonErrorHandler(
              "Edit and Save again to download the graph."
            );
          }
          this.setState({ downloadwait: false, graphId: null });
        },
        this.props.journalUserId,
        {
          startDate: startDate
            ? moment(startDate, "x").format("DD MMM 'YY")
            : "",
          endDate: endDate
            ? moment(endDate, "x").format("DD MMM 'YY")
            : ""
        },
        true
      );
    }
  };

  redirectToPremium = () => {
    this.props.navigation.push(PROFILE.profilePremiumAccountPath);
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {
      accountType,
      filteredGraphs,
      enableGraphCount,
      downloadwait,
      graphId,
      loading,
    } = this.state;
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          filterClickHandler={this.handleOnPressFilter}
          clearFiltersHandler={this.handleOnPressClearFilters}
          handleOnPressBackButton={() => {
            let from = null;
            if (this.props.route?.params && this.props.route?.params.from) {
              from = this.props.route?.params.from;
            }
            if (this.state.searchStr !== "" && this.state.searchStr !== null) {
              this.filterGraphs(null);
            } else if (from && (from.includes(GRAPHS.addGraphPath) || from.includes(GRAPHS.editGraphPath))) {
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: DASHBOARD.path }],
              });
            } else {
              handleCommonBackNavigation(this.props.navigation);
            }
          }}
          index={GRAPHS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />

        {accountType === "regular" &&
          enableGraphCount >= freeUserMaximumGraphsAllow && <GraphBanner />}

        <ScrollView style={[styles.scrollViewArea]}>
          <View style={styles.gridSection}>
            <PageContainer>
              <View style={[styles.gridRow, styles.colPdBotSm]}>
                <View style={styles.gridColFull}>
                <View style={[styles.searchSection]}>
                    {/* button */}
                    <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt]}>
                      <View>
                        <SectionTitle 
                          smallTextTilte={true}
                          title={"Graphs"}
                        />
                      </View>
                      <View>
                      {accountType === "regular" &&
                        enableGraphCount >= freeUserMaximumGraphsAllow && (
                          <View style={[styles.premiumTopBtn, {top:3}]}>
                            <PremiumOvalButton
                              navigation={this.props.navigation}
                              btnFull={true}
                            />
                          </View>
                        )}
                      <View style={[stylesRes.newConnectionBtnCr, styles.btnMgMd]} dataSet={{media: ids.newConnectionBtnCr}}>
                        <PlusButtonDark
                          touchableOnPress={() => {
                            pendoTrackEvent('Graphs@ > Add');
                            const { accountType } = this.state;
                            if (
                              accountType === "regular" &&
                              enableGraphCount >= freeUserMaximumGraphsAllow
                            ) {
                              return;
                            }
                            this.props.setFromPopup('GRAPH_ROUTINE');
                          }}
                          btnPosition={"full"}
                          btnText={"New Graph"}
                          plusIconSm={true}
                          btnShadowNone={true}
                          nativeId={'graphsAdd'}
                          newConnection={true}
                        />
                      </View>
                      
                      </View>
                    </View>
                    {/* search bar */}
                    <View style={styles.fieldColSearch}>
                      <SearchField
                        searchText={
                          this.state.searchStr ? this.state.searchStr : ""
                        }
                        placeholder="Search existing graphs"
                        allowFontScaling={false}
                        onChangeText={this.filterGraphs}
                        showClearBtton={
                          this.state.searchStr && this.state.searchStr !== ""
                            ? true
                            : false
                        }
                        clearFilter={() => this.filterGraphs(null)}
                      />
                    </View>
                </View>     
                  {loading ? (
                    <ActivityIndicator />
                  ) : _.isEmpty(filteredGraphs) &&
                  this.state.searchStr !== "" &&
                  this.state.searchStr !== null ? (
                    <NoResultFound />
                  ) : (
                    _.isEmpty(filteredGraphs) && (
                      <NoGraphFound/>
                    )
                  )}
                </View>
              </View>
            </PageContainer>
            {this.state.filteredGraphs.length > 0 ? (
              <PageContainer>
                <View style={stylesRes.botPd} dataSet={{media: ids.botPd}}>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={styles.secArea}>
                        <View style={[styles.itemSection]}>
                          <View style={styles.subViewArea}>
                            <View
                              style={[styles.secSubContentArea, styles.pdTopSm]}
                            >
                              {/* Loop this section  - start*/}
                              {this.state.filteredGraphs &&
                                filteredGraphs.map((res, index) => (
                                  <GraphItem 
                                    item={res}
                                    graphId={graphId}
                                    downloadwait={downloadwait}
                                    onPressDownload={this.downloadGraph}
                                    onPressDelete={() => {
                                      this.setState({
                                        visibleModal: true,
                                        selectedId: res.id,
                                      });
                                    }}
                                    redirectToEdit={() => this.redirectToEdit(res.id, res.name)}
                                    onPressPermium={this.redirectToPremium}
                                    windowWidth={this.props.windowWidth}
                                    allowfontScaling = {false}
                                  />
                              ))}
                              {/* Loop this section  - end*/}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </PageContainer>
            ) : null}
          </View>
        </ScrollView>
        <SimpleConfirmBox
          visibleModal={this.state.visibleModal}
          onRequestClose={() =>
            this.setState({ visibleModal: false, selectedId: null })
          }
          onBackdropPress={() =>
            this.setState({ visibleModal: false, selectedId: null })
          }
          alertTitle={"Delete Rolling Graph"}
          alertBody={"Are you sure ?"}
          handleCancel={() =>
            this.setState({ visibleModal: false, selectedId: null })
          }
          handleConfirm={this.deleteGraph}
          confirmWait={this.state.wait}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.routines.dashboard,
    dashboardProcessed: state.routines.dashboardProcessed,
    userSubscriptions: state.routines.userSubscription,
    journalUserId: state.uistate.journalUserId,
    graphFilter: state.filter.graphFilter,
    windowWidth: state.uistate.windowWidth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectGraph: (graph, callBack) =>
      dispatch(actions.selectGraph(graph, callBack)),
    resetRollingGraph: () => dispatch(actions.resetRollingGraph()),
    deleteDashboardGraph: (id, callBack, journalUserId) =>
      dispatch(actions.deleteDashboardGraph(id, callBack, journalUserId)),
    setGraphFilter: (filter) => dispatch(actions.setGraphFilter(filter)),
    commonErrorHandler: (errorDescription) =>
      dispatch(actions.commonErrorHandler(errorDescription)),
    setFromPopup: (fromPopup) => dispatch(actions.setFromPopup(fromPopup)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RollingGraphsScreen);
