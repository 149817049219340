import React, { Component } from "react";
import { View, TouchableOpacity, Platform } from "react-native";
import styles from "../../Css";
import DatePicker from "../DateTimePicker/DatePicker";
import * as FieldDetails from "../TextInput/fieldDetails";
import TextDatePickerFrame from "../TextInput/textDatePickerFrame";
import moment from "moment";
import { WEB } from "../../util/platformWindowConfig";

class Dates extends Component {
  constructor(props) {
    super(props);
    const { startDate, endDate } = props;
    this.state = {
      startDate: startDate,
      endDate: endDate,
    };
  }

  onConfirmStartDate = (date) => {
    const { updateStartDate } = this.props;
    this.setState(
      {
        startDate: date,
      },
      () => {
        if (updateStartDate) {
          updateStartDate(date);
        }
      }
    );
  };

  onConfirmEndDate = (date) => {
    const { updateEndDate } = this.props;
    this.setState(
      {
        endDate: date,
      },
      () => {
        if (updateEndDate) {
          updateEndDate(date);
        }
      }
    );
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { startDate, endDate } = nextProps;
    //if (startDate || startDate) {
    this.setState({ startDate: startDate, endDate: endDate });
    // }
  };

  customTouchableComponentStartDate = (onPress) => {
    return (
      Platform.OS === WEB
        ? <TouchableOpacity onPress={onPress}>
            {this.renderStartDateContent()}
          </TouchableOpacity>
        : this.renderStartDateContent()
    );
  };

  renderStartDateContent = () => {
    const { startDate, endDate } = this.state;
    return (
      <TextDatePickerFrame
        title={"Start Date"}
        fieldBg={FieldDetails.BGWHITE}
        fieldSm={false}
        fieldLt={true}
        fieldValue={
          startDate ? moment(startDate).format("D MMM 'YY") : ""
        }
        onDateConfirm={this.onConfirmStartDate}
        date={startDate}
        shouldDisableDate={(day) => {
          return endDate && day > moment(endDate);
        }}
        maxDate={endDate}
      />
    )
  }

  customTouchableComponentEndDate = (onPress) => {
    return (
      Platform.OS === WEB
      ? <TouchableOpacity onPress={onPress}>
          {this.renderEndDateContent()}
        </TouchableOpacity>
      : this.renderEndDateContent()
    );
  };

  renderEndDateContent = () => {
    const { endDate, startDate } = this.state;
    return (
      <TextDatePickerFrame
        title={"End Date"}
        fieldBg={FieldDetails.BGWHITE}
        fieldSm={false}
        fieldLt={true}
        fieldValue={
          endDate ? moment(endDate).format("D MMM 'YY") : ""
        }
        onDateConfirm={this.onConfirmEndDate}
        date={endDate}
        shouldDisableDate={(day) => {
          return startDate && day < moment(startDate).add(-1, "days");
        }}
        minDate={startDate}
      />
    )
  }

  render() {
    const { startDate, endDate } = this.state;
    return (
      <React.Fragment>
        <View style={[styles.fieldRow, styles.zIndexMin]}>
          <View style={[styles.fieldRowWrapper]}>
            <View style={styles.fieldColStretch}>
            <DatePicker
              date={startDate}
              onConfirm={this.onConfirmStartDate}
              shouldDisableDate={(day) => {
                return endDate && day > moment(endDate);
              }}
              maxDate={endDate}
              customTouchableComponent={
                this.customTouchableComponentStartDate
              }
            />
            </View>
            <View style={styles.fieldColSp}></View>
            <View style={styles.fieldColStretch}>
              <DatePicker
                date={endDate}
                onConfirm={this.onConfirmEndDate}
                shouldDisableDate={(day) => {
                  return startDate && day < moment(startDate).add(-1, "days");
                }}
                minDate={startDate}
                customTouchableComponent={
                  this.customTouchableComponentEndDate
                }
              />

            </View>
          </View>
        </View>

      </React.Fragment>
    );
  }
}

export default (Dates);
