import React from "react";
import { View, TextInput } from "react-native";
import styles from "../../Css";
import FieldTitle from "./fieldTitle";
import * as FontScaling from '../../components/Text/fontScaling';
import WarningInline from '../../components/Warning/warningInline';
const groupFrame = (props) => {
  return (
    <View style={[styles.textInputLgWrapper, styles.postRelative, props.groupStyle]}>
      <FieldTitle
        fieldAlign={props.fieldAlign}
        fieldBg={props.fieldBg}
        title={props.title}
      />
      <View
        style={[
          styles.flexRow,
          styles.textInputFieldLg,
          props.groupStyle,
          props.fieldInfo ? styles.frameInfoPd : styles.framePd,
          props.fieldBg === "grey"
            ? styles.bgThinGrey
            : props.fieldBg === "blue"
            ? styles.bgBlueExLight
            : props.fieldBg === "transparent"
            ? styles.bgTransparent
            : props.fieldBg === "red"
            ? [styles.inValidColor, styles.bgThinGrey]
            : styles.bgWhite,
        ]}
      >
        {Boolean(props.groupDescription) && (
          <React.Fragment>
            <View style={styles.fieldColStretch}>
              <View style={[styles.fieldDescription]}>
                <View style={[styles.symptomDataWrapper, styles.flexJcCt]}>
                  <TextInput style={[styles.textPrimeSm]} allowFontScaling = {FontScaling.allowFontScaleFalse} onChangeText={props.onChangeText} editable={props.fieldType === 'custom' ? true : false} value={props.fieldValue}/>
                </View>
              </View>
            </View>
            <View style={styles.fieldColSp}></View>
          </React.Fragment>
        )}
        {props.children}
      </View>
      {props.fieldBg === "red" && (
      <WarningInline
        errorDescription={'Name can not be empty.'}
        textPosition={'right'}
      />
    )}
    </View>
  );
};

export default groupFrame;
