import React, {Component} from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
  Linking,
} from "react-native";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";
import {connect} from "react-redux";
import * as actions from "../../../store/actions/index";
import ResourceDeletePopupMenu from "../../../components/PopupMenu/communityGroupResourcesPopupMenu";
import SuccessBox from "../../../components/ConfirmationBox/successBox";
import DeleteResourceConfirmationBox from "../../../components/ConfirmationBox/deleteResourceConfirmationBox";
import {COMMUNITY_GROUPS} from "../../../navigation/path";
import { WEB } from "../../../util/platformWindowConfig";


export const TAG_SEARCH_SOURCE_MY_RESOURCES = "my-resources";

class ResourcesListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.user.id.toString(),
      resourcesList: [],
      fetchWait: false,
      socialMediaList: null,
      successModalVisible: false,
      deleteConfirm: false,
      deleteWait: false,
      selectedId: null,
      memberCount: null,
      newPostsToday: null
    };
  }

  componentDidMount = () => {
    if (this.props.resource.type !== "WebResources") {
      this.props.fetchSocialMediaData(
        this.props.resource.resourceId
          ? this.props.resource.resourceId.toString()
          : "",
        (sucess, data) => {
          if (sucess) {
            let memberCount = null;
            let newPostsToday = null;
            if (data.memberCount) {
              memberCount = data.memberCount;
            }

            if (data.newPostsToday) {
              newPostsToday = data.newPostsToday;
            }

            this.setState({
              memberCount: memberCount,
              newPostsToday: newPostsToday
            });
          }
        }
      );
    }
  };

  handleDeleteResource = () => {
    this.setState({deleteWait: true});
    let deleteObject = {
      resourceId: this.state.selectedId,
      userId: this.props.journalUserId
        ? this.props.journalUserId.toString()
        : this.props.user.id.toString()
    };
    this.props.handleDeleteResource(deleteObject, res => {
      if (res === "Caregiver has permission type: READ_ONLY") {
        this.setState({
          deleteWait: false,
          deleteConfirm: false,
          alertMessageTitle: null
        });
      } else {
        this.props.fetchAllResouces(
          this.props.journalUserId,
          // ? this.props.journalUserId.toString()
          // : this.props.user.id.toString(),
          async () => {
            this.setState({deleteConfirm: false, alertMessageTitle: null});
            await new Promise(r => setTimeout(() => this.setState({
              successModalVisible: true,
              deleteWait: false
            }, r), 500));
            await new Promise(r => setTimeout(() => this.setState({successModalVisible: false}, r), 2500));
            this.props.fetchFilteredResourceList();
          }
        );
      }
    });
  };

  handleShareResource = resource => {
    this.props.onSelectResources(resource, () => {
      this.props.navigation.push(COMMUNITY_GROUPS.shareResourcePath);
    });
  };

  handleShowHideResource = (id, isHidden, mapId) => {
    const {handleOnPressShowHide} = this.props;
    const showHideObj = {
      communityResourceId: id,
      isHidden: !isHidden,
      mapId: mapId
    }
    handleOnPressShowHide(showHideObj);
  }

  onPressTitle = url => {
    if (url && url !== "None") {
      if (Platform.OS === WEB) {
        window.open(url);
      } else {
        Linking.openURL(url);
      }
    }
  };

  openPartnerResource = (seen, mapId) => {
    const {getResourceDetailsById} = this.props;
    if (!seen) {
      this.props.handleOnTapNewResource(mapId)
    }
    getResourceDetailsById(mapId);
  }

  onDownloadResource = resourceId => {
    this.setState({downloadWait: true});
    this.props.onDownloadResource(
      {resourceId: resourceId.toString()},
      () => {
        this.setState({downloadWait: false});
      }
    );
  };

  handleDeleteContactConfirmation = id => {
    this.setState({
      deleteConfirm: true,
      alertMessageTitle: "Delete Resource",
      selectedId: id
    });
  };

  onPressTag = tag => {
    let tagSearch = {
      tag: tag,
      userId: this.state.userId
    };

    this.props.resetSearch();
    this.props.handleSearchByTag(tagSearch, TAG_SEARCH_SOURCE_MY_RESOURCES, tagSearchResults => {
      if (tagSearchResults) {
        this.props.activeTab(tagSearch, tagSearchResults);
      }
    });
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {resource} = this.props;
    const {communityResponse, publishedBy, profileName, resourceStatus, hidden, mapId, seen} = resource;
    const {resourceId, favIcon, type, url, title, count, description, tags} = communityResponse;
    const {successModalVisible} = this.state;
    return (
      <React.Fragment>
        {resourceId ? (
          <View style={stylesRes.resourceBoxMgCr} dataSet={{media: ids.resourceBoxMgCr}}>
            <View
              style={[
                styles.viewRowWrapper,
                styles.boxFull,
                styles.resourceBoxStyleCr,
                !seen && styles.newResourceBorder
              ]}
              key={resourceId}
            >
              <View style={[styles.ViewRow, styles.boxFull,]}>
                <View style={stylesRes.healthJourneyContentTruncWd} dataSet={{media: ids.healthJourneyContentTruncWd}}>
                  <TouchableOpacity onPress={() => this.openPartnerResource(seen, mapId)}
                                    disabled={publishedBy !== "PARTNER"}>
                    <View style={[styles.viewDetailSec]}>

                      <View style={[styles.viewDataFullDesc]}>
                        {/* image */}
                        <View style={[styles.communityBgSec, styles.communityView, styles.flexJcFs]}>
                          <View style={[styles.communityProfileSec, styles.comMgTopSm]}>
                            <Image
                              style={styles.communityProfileIcon}
                              source={{
                                uri: favIcon
                              }}
                            />
                          </View>
                        </View>

                        {Boolean(this.state.newPostsToday) &&
                          this.state.newPostsToday !== "UNAVAILABLE" && (
                            <View style={[styles.viewItemSmTitle]}>
                              <Text
                                style={[
                                  styles.textPrimeSm,
                                  styles.textEm,
                                  styles.textColorGreen
                                ]}
                                allowFontScaling={false}
                              >
                                {`${this.state.newPostsToday} new posts today`}
                              </Text>
                            </View>
                          )}

                        <View>

                          {/* name */}
                          <View style={[styles.viewItemData]}>
                            {type !== "Document" && publishedBy !== "PARTNER" ? (
                              <TouchableOpacity
                                onPress={() =>
                                  this.onPressTitle(url)
                                }
                              >
                                <Text
                                  style={styles.textPrimeBold}
                                  allowFontScaling={false}
                                  numberOfLines={Platform.OS === WEB ? 1 : 2}
                                  nativeID={"textTruncate"}
                                  ellipsizeMode='tail'>
                                  {title}
                                </Text>
                              </TouchableOpacity>
                            ) : (
                              <Text
                                style={styles.textPrimeBold}
                                allowFontScaling={false}
                                numberOfLines={Platform.OS === WEB ? 1 : 2}
                                nativeID={"textTruncate"}
                                ellipsizeMode='tail'
                              >
                                {title}
                              </Text>
                            )}
                          </View>

                          {/* second line */}
                          <View style={[styles.viewItemDataCmmunity]}>
                              <>
                                {/* GREYED TEXT */}
                                {count !== null && (
                                  <Text style={[styles.textPrimeSm, styles.greyTxtCr]} allowFontScaling={false}>
                                    {`${count} people using this resource.`}
                                  </Text>
                                )}

                                {this.state.memberCount !== null &&
                                  this.state.memberCount !== "UNAVAILABLE" && (
                                    <Text style={[styles.textPrimeSm, styles.greyTxtCr]} allowFontScaling={false}>
                                      {`${this.state.memberCount} total members`}
                                    </Text>
                                  )}
                                {Boolean(description !== null && description && description.trim() !== '') &&
                                  <Text style={[styles.textPrimeSm, {paddingTop: 8}]}
                                        allowFontScaling={false}>{description}</Text>
                                }
                              </>
                          </View>

                          {/* third line */}
                          <View style={[styles.viewItemDataTag]}>
                            {publishedBy === "PARTNER" && (
                              <View style={[styles.flexRow, styles.flexAiFe, styles.mgtBtmFive]}>
                                <Text
                                  style={[styles.textPrimeSm, styles.greyTxtLtCr, styles.pdLgtZero, styles.txtGreyHighlightCr, styles.pdRtTwo]}
                                  allowFontScaling={false}>
                                  Shared by:
                                </Text>
                                <Image
                                  style={styles.startCheckIcon}
                                  source={require("../../../assets/icons/star-check-icon.png")}
                                />
                                <Text style={[styles.textPrimeSm, styles.greyTxtLtCr, styles.premiumBtnSp]}
                                      allowFontScaling={false}>{profileName}</Text>
                              </View>
                            )}
                            {/* TAG */}
                            <View style={[styles.flexRow, styles.flexAiFs]}>
                              <View>
                                <Text
                                  style={[styles.textPrimeSm, styles.greyTxtCr]}
                                  allowFontScaling={false}>{"Tags: "}
                                </Text>
                              </View>
                              <View style={stylesRes.resourceListItemTag} dataSet={{media: ids.resourceListItemTag}}>
                                {tags &&
                                tags.length > 0
                                  ? tags.map((tag, tagsIndex) => (
                                    <TouchableOpacity
                                      onPress={() => this.onPressTag(tag)}
                                      key={tagsIndex}
                                    >
                                      <Text
                                        style={[styles.textPrimeSm, styles.greyTxtCr]}
                                        key={tag}
                                        allowFontScaling={false}>
                                        {tag}
                                        {tagsIndex !== tags.length - 1
                                          ? ", "
                                          : ""}
                                      </Text>
                                    </TouchableOpacity>
                                  ))
                                  : null}
                              </View>
                            </View>
                            {/* )} */}
                          </View>
                        </View>

                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
                <View>
                  <View style={[styles.iconListSec, styles.iconListNoPd]}>
                    <View style={[styles.settingSec]}>
                      <ResourceDeletePopupMenu
                        onDeleteResource={() => {
                          this.handleDeleteContactConfirmation(
                            resourceId
                          );
                        }}
                        onDownloadResource={() => {
                          this.onDownloadResource(resourceId);
                        }}
                        onShareResource={() => {
                          this.handleShareResource(communityResponse);
                        }}
                        onShowHideResource={() => {
                          this.handleShowHideResource(
                            resourceId,
                            hidden,
                            mapId
                          );
                        }}
                        type={type}
                        publishedBy={publishedBy}
                        isHidden={hidden}
                        resourceStatus={resourceStatus}
                      />
                    </View>
                  </View>
                </View>
              </View>

              {successModalVisible && (
                <SuccessBox
                  visibleModal={successModalVisible}
                  messageTitle={'Resource Deleted'}
                  messageTitleStyle={styles.bold}
                  onRequestClose={() => this.setState({successModalVisible: false})}
                  roundedCorners
                  showClose
                />
              )}
            </View>
          </View>
        ) : null}
        <DeleteResourceConfirmationBox
          visibleModal={this.state.deleteConfirm}
          onBackdropPress={() => {
            this.setState({deleteConfirm: false});
          }}
          onRequestClose={() => {
            this.setState({deleteConfirm: false});
          }}
          alertTitle={this.state.alertMessageTitle}
          handleDelete={this.handleDeleteResource}
          deleteWait={this.state.deleteWait}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    showLearnHow: state.uistate.showLearnHow,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    resourcesList: state.communityGroups.fetchAllResources,
    user: state.routines.user,
    socialMediaDataList: state.communityGroups.fetchAllSocialMediaData,
    journalUserId: state.uistate.journalUserId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAllResouces: (userId, callBack) =>
      dispatch(actions.fetchAllResouces(userId, callBack)),
    fetchSocialMediaData: (resourceId, callBack) =>
      dispatch(actions.fetchSocialMediaData(resourceId, callBack)),
    handleDeleteResource: (id, callBack) => {
      dispatch(actions.deleteResource(id, callBack));
    },
    onSelectResources: (resource, callBack) => {
      dispatch(actions.onSelectResources(resource, callBack));
    },
    onDownloadResource: (resourceId, callBack) => {
      dispatch(actions.onDownloadResource(resourceId, callBack));
    },
    handleSearchByTag: (tagSearch, searchSource, callBack) => {
      dispatch(actions.handleSearchByTag(tagSearch, searchSource, callBack));
    },
    resetSearch: () => {
      dispatch(actions.resetCommunitySearch());
    },
    getResourceDetailsById: (communityResourceId) => dispatch(actions.getResourceDetailsById(communityResourceId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcesListItem);
