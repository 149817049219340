export const cssOcr = {
  uploadAreaRow: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  uploadBtnText: {
    paddingTop: 8,
    justifyContent: "center",
    flex: 1,
  },
  uploadTextArea: {
    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
    paddingBottom: 10,
  },
};
