import React from 'react';
import { View, Text } from "react-native";
import styles from '../../Css';

const QuestionWarning = (props) => (
    <View style={[styles.notifyBlock, styles.pdTopSm]}>
      <View
        style={[
          styles.warningInline,
          props.textPosition === 'center' 
          ? styles.flexJcCt
          : props.textPosition === 'right' 
          ? styles.flexJcFe
          : styles.flexJcFs
        ]}>
        <Text
          style={[styles.textPrimeBold, styles.textRed]}
          allowFontScaling={false}
        >* This is a required question.</Text>
        {/* <Text
          style={[styles.textPrimeExSmBold, styles.textRed]}
        >* {props.errorDescription}</Text> */}
      </View>
    </View>
);

export default (QuestionWarning);



