import React, { useState } from "react";
import { Text, View, TouchableOpacity, ScrollView, Image } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import Modal from "../Modal/modal";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import { getFrequencyType } from "../../util/commonUiLogic";

const ConfirmationBox = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {
    visibleModal,
    onRequestClose,
    selectedTherapie,
    onDeleteTherapie,
    onFinish = () => {},
    journalUserId,
    handleWhenHaveData = () => {},
    hasRecordings
  } = props;
  const { treatmentType, name, dosages } = selectedTherapie;
  const { quantity, frequency, frequencyType } =
    dosages && dosages.length > 0 ? dosages[0] : {};

  const [deleteWait, setDeleteWait] = useState(false);

  const handleDeleteTherapie = () => {
    setDeleteWait(true)
    if (hasRecordings) {
      setDeleteWait(false)
      handleWhenHaveData()
    } else {
      onDeleteTherapie(
        selectedTherapie.id,
        res => {
          setDeleteWait(false);
          if (res) {
            onFinish();
            onRequestClose();
          }
        },
        journalUserId,
      );
    }
  };

  return (
    <Modal
      visible={visibleModal}
      onRequestClose={onRequestClose}
      onBackdropPress={deleteWait ? undefined : onRequestClose}
    >
      <View style={stylesRes.modalScrollMessageView} dataSet={{media: ids.modalScrollMessageView}}>
        <ScrollView style={styles.modalScroller}>
          <View style={[styles.popUpBlock, styles.bgWhite]}>
            <View style={styles.popUpContentArea}>
              <View style={styles.gridSectionPopup}>
                <View
                  style={[
                    styles.popUpContentPd,
                    styles.popUpBoxHeightMin,
                    //content end
                    styles.flexJcFe,
                    //content center
                    // styles.flexJcCt
                  ]}
                >
                  <View style={stylesRes.modalContIn}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.popMessageIconView, styles.comSp]}>
                          <Image
                            style={styles.deleteBasket}
                            source={require("../../assets/icons/delete-basket.png")}
                          />
                        </View>

                        <View
                          style={[styles.popMessageContView, styles.comSpSm]}
                        >
                          {treatmentType === 5 ? (
                            <React.Fragment>
                              <View
                                style={[
                                  styles.popMessageTitleView,
                                  styles.popupTitleMg,
                                ]}
                              >
                                <Text
                                  style={[
                                    styles.textTitle,
                                    styles.textAlignCenter,
                                  ]}
                                  allowFontScaling={false}
                                >
                                  <Text style={styles.bold}>Delete </Text>
                                  Activities
                                </Text>
                              </View>

                              <View style={styles.popMessageDescView}>
                                <Text
                                  style={[
                                    styles.textPrimeLg,
                                    styles.textAlignCenter,
                                  ]}
                                  allowFontScaling={false}
                                >
                                  Are you sure?
                                </Text>
                              </View>
                            </React.Fragment>
                          ) : (
                            <View
                              style={[
                                styles.popMessageTitleView,
                                styles.popupTitleMg,
                              ]}
                            >
                              <Text
                                style={[
                                  //styles.textPrimeLg,
                                  styles.textTitle,
                                  styles.textAlignCenter,
                                ]}
                                allowFontScaling={false}
                              >
                                Do you want to{" "}
                                <Text style={styles.bold} allowFontScaling={false}>Delete</Text> this
                                medication/supplements?
                              </Text>
                            </View>
                          )}
                        </View>
                        {treatmentType !== 5 && (
                          <View
                            style={[
                              styles.popMessageDetailsView,
                              styles.comMgTopSm,
                            ]}
                          >
                            <View style={[styles.tableBlock, styles.comSpSm]}>
                              <View style={[styles.tableView]}>
                                <View style={[styles.trTitleView]}>
                                  <View
                                    style={[
                                      styles.tdColView,
                                      styles.tdColRtBorder,
                                      styles.tdColEqLgView,
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.textPrimeSmBold,
                                        styles.textAlignCenter,
                                      ]}
                                      allowFontScaling={false}
                                    >
                                      Name of the medication
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.tdColView,
                                      styles.tdColRtBorder,
                                      styles.tdColEqView,
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.textPrimeSmBold,
                                        styles.textAlignCenter,
                                      ]}
                                      allowFontScaling={false}
                                    >
                                      Quantity
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.tdColView,
                                      styles.tdColEqView,
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.textPrimeSmBold,
                                        styles.textAlignCenter,
                                      ]}
                                      allowFontScaling={false}
                                    >
                                      Frequency{" "}
                                    </Text>
                                  </View>
                                </View>
                                {/* Loop this section */}

                                <View style={[styles.trDataView]}>
                                  <View
                                    style={[
                                      styles.tdColView,
                                      styles.tdColRtBorder,
                                      styles.tdColEqLgView,
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.textPrimeSmBold,
                                        styles.textColorLight,
                                        styles.textAlignCenter,
                                      ]}
                                      allowFontScaling={false}
                                    >
                                      {name}
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.tdColView,
                                      styles.tdColRtBorder,
                                      styles.tdColEqView,
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.textPrimeSmBold,
                                        styles.textColorLight,
                                        styles.textAlignCenter,
                                      ]}
                                      allowFontScaling={false}
                                    >
                                      {quantity}
                                    </Text>
                                  </View>
                                  {frequency !== null &&
                                    <View
                                      style={[
                                        styles.tdColView,
                                        styles.tdColEqView,
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.textPrimeSmBold,
                                          styles.textColorLight,
                                          styles.textAlignCenter,
                                        ]}
                                        allowFontScaling={false}
                                      >
                                        {frequency +
                                          " times " +
                                          getFrequencyType(frequencyType)}
                                      </Text>
                                    </View>
                                  }
                                </View>

                                {/*  Loop this section  End*/}
                              </View>
                            </View>
                          </View>
                        )}

                        <View
                          style={[
                            styles.popBtnView,
                            styles.comMgTopMd,
                            styles.comSp,
                          ]}
                        >
                          <View style={styles.btnArea}>
                            <View
                              style={[
                                stylesRes.btnViewSec,
                                stylesRes.btnViewSecComNoPd,
                              ]}
                            >
                              <View style={styles.fieldBtn}>
                                <View style={styles.btnBlockFull}>
                                  <TouchableOpacity
                                    style={[
                                      styles.submitSm,
                                      styles.btnShadowCom,
                                      styles.submitBgAsh,
                                    ]}
                                    underlayColor="rgba(0, 153, 168, 0.8)"
                                    onPress={onRequestClose}
                                    disabled={deleteWait}
                                  >
                                    <View style={styles.btnSingleView}>
                                      <View
                                        style={[
                                          styles.btnSingle,
                                          styles.btnSingleCent,
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            styles.submitBtnTextWhite,
                                            styles.bold,
                                          ]}
                                          allowFontScaling={false}
                                        >
                                          Cancel
                                        </Text>
                                      </View>
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              </View>
                              <View style={styles.fieldBtnSpSm} />
                              <View style={styles.fieldBtn}>
                                <View style={styles.btnBlockFull}>
                                  <TouchableOpacity
                                    style={[
                                      styles.submitSm,
                                      styles.btnShadowCom,
                                      styles.submitBgRed,
                                    ]}
                                    underlayColor="rgba(0, 153, 168, 0.8)"
                                    onPress={handleDeleteTherapie}
                                    disabled={deleteWait}
                                  >
                                    <View style={styles.btnSingleView}>
                                      <View
                                        style={[
                                          styles.btnSingle,
                                          styles.btnSingleCent,
                                        ]}
                                      >
                                        {Boolean(deleteWait) && (
                                          <View
                                            style={[
                                              styles.indicatorIconView,
                                              styles.indicatorLtMini,
                                            ]}
                                          >
                                            <ActivityIndicator
                                              pdZero={true}
                                              size="small"
                                            />
                                          </View>
                                        )}
                                        <Text
                                          style={[
                                            styles.submitBtnTextWhite,
                                            styles.bold,
                                          ]}
                                          allowFontScaling={false}
                                        >
                                          Delete
                                        </Text>
                                      </View>
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};
export default ConfirmationBox;
