import React, {useEffect,useState} from 'react';
import {
  View,
  ScrollView,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  Platform,
} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import UIText from '../../components/Text/text';
import Modal from '../../components/Modal/modal';
import Calendar from '../../modules/datePicker/calendar/index';
import Allergies from '../../components/Allergies/Allergies';
import Conditions from '../../components/Conditions/Conditions';
import DateOfBirth from '../../components/DateOfBirth/DateOfBirth';
import EditProfileHeader from './EditProfileHeader';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import Picker from '../Picker/picker';
import KeyboardAwareScrollViewHoc from '../KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import PageContainer from '../../components/PageLayout/PageContainer';
import Header from '../../components/Header/header';
import MenuOverlayContainer from '../../components/PageLayout/MenuOverlayContainer';
import { pendoTrackEvent } from '../../util/pendoConfig';
import CancelButton from '../Button/cancelButton';
import { WEB } from '../../util/platformWindowConfig';


const EditProfile = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const [saveInprogress, changeSaveInprogress] = useState(false);
  const {
    handleOnPressBackButton,
    index,
    navigation,
    loading,
    hasNotch,
    userId,
    journalUserId,
    firstName,
    lastName,
    dob,
    validFirstName,
    onChangeTextFirstName,
    validLastName,
    onChangeTextLastName,
    emailValidate,
    email,
    onChangeTextEmail,
    handleOnDatePress,
    invalidDay,
    invalidMonth,
    invalidYear,
    checkInvalidDate,
    gender,
    onChangeGender,
    height,
    onChangeTextHeight,
    heightUomSymbol,
    onChangeHeightUomSymbol,
    weight,
    onChangeTextWeight,
    weightUomSymbol,
    onChangeWeightUomSymbol,
    ethnicity,
    onChangeTextEthnicity,
    onChangeTextCity,
    city,
    country,
    onChangeState,
    state,
    onChangeCountry,
    visibleCalModal,
    onRequestClose,
    onBackdropPress,
    maxDate,
    onPressCancel,
    onPressSave,
    saveWait,
    getHeightUnits,
    getWeightUnits,
    scrollViewRef,
    route,
    setConditionsToDelete,
    initConditions,
    newUserAllergies,
    addUserAllergy,
    deleteUserAllergy,
  } = props;
  scrollViewRef && scrollViewRef(this);

  useEffect(()=> {
    if(!saveWait && saveInprogress){
      changeSaveInprogress(false);
    }
  },[saveWait, saveInprogress])

  const handleSaveClick = () => {
    changeSaveInprogress(true);
    onPressSave()
  }

  return (
    <View style={styles.pageBodyWrapper}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={true}
        handleOnPressBackButton={handleOnPressBackButton}
        index={index}
        navigation={navigation}
        route={route}
      />
      <SpaceBar />
      {Boolean(props.getProfileChange) && (
        <MenuOverlayContainer onPressOpen={handleOnPressBackButton} />
      )}
      {loading ? (
        <ActivityIndicator />
      ) : (
        <KeyboardAwareScrollViewHoc hasNotch={hasNotch} ref={scrollViewRef}>
          <View style={styles.gridSection}>
            <View style={[stylesRes.contentSection, stylesRes.contBotSmPdFixed]}>
              <PageContainer smScreenFull={true}>
                <EditProfileHeader
                  userId={userId}
                  journalUserId={journalUserId}
                  firstName={firstName}
                  lastName={lastName}
                />
              </PageContainer>
              <View style={styles.gridRow}>
                <View style={styles.gridColFull}>
                  <View style={styles.secArea}>
                    <PageContainer>
                      <View style={styles.colPdTopCom}>
                        <View
                          style={[
                            styles.secSubBlock,
                            styles.comMgTopSm,
                            styles.zIndexAutoComplete,
                          ]}>
                          <View style={styles.contRow}>
                            <View style={styles.cardContent}>
                              <View style={styles.contColFul}>
                                <View
                                  style={[styles.contTitle, styles.fiedBotPd]}>
                                  <Text
                                    style={styles.textPrimeBold}
                                    allowFontScaling= {false}
                                    >
                                    Medical Condition(s):
                                  </Text>
                                </View>
                                <View style={styles.contDesc}>
                                  <View
                                    style={[
                                      styles.addedSecFull,
                                      styles.pdBotExSm,
                                      styles.pdTopExSm,
                                    ]}>
                                    <Conditions setConditionsToDelete={setConditionsToDelete} initConditions={initConditions} dob={dob} />
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                        <View
                          style={[styles.comSpMd, styles.greyBorderThick]}
                        />

                        <View style={styles.zIndexMin}>
                          <View
                            style={[
                              styles.secSubBlock,
                              styles.zIndexAutoComplete,
                            ]}>
                            <View style={styles.contRow}>
                              <View style={styles.cardContent}>
                                <View style={styles.contColFul}>
                                  <View
                                    style={[
                                      styles.contTitle,
                                      styles.fiedBotPd,
                                    ]}>
                                    <Text
                                      style={styles.textPrimeBold}
                                      allowFontScaling= {false}
                                      >
                                      Allergie(s):
                                    </Text>
                                  </View>
                                  <View style={styles.contDesc}>
                                    <View
                                      style={[
                                        styles.addedSecFull,
                                        styles.pdBotExSm,
                                        styles.pdTopExSm,
                                      ]}>
                                      <Allergies 
                                        newUserAllergies={newUserAllergies} 
                                        addUserAllergy={addUserAllergy} 
                                        deleteUserAllergy={deleteUserAllergy} 
                                      />
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.comSpMd,
                              styles.greyBorderThick,
                              styles.zIndexMin,
                            ]}
                          />
                          <View
                            style={[
                              styles.secSubBlock,
                              styles.comSpLg,
                              styles.zIndexMin,
                            ]}>
                            <View
                              style={[styles.formInnerArea, styles.comSpLg]}>
                              <View style={styles.gridRow}>
                                <View style={styles.gridColFull}>
                                  <View
                                    style={[
                                      styles.fieldRow,
                                      styles.fieldRowFlexRow,
                                    ]}>
                                    <View style={styles.fieldColHalf}>
                                      <View
                                        style={[
                                          styles.fieldColCont,
                                          styles.mgRtSm,
                                        ]}>
                                        <Text 
                                          style={[styles.textView, styles.textInputTopPd]}
                                          allowFontScaling={false}>
                                          <Text
                                            style={[
                                              styles.textLabel,
                                              styles.bold,
                                              styles.textColorLight,
                                            ]}
                                            allowFontScaling= {false}
                                            >
                                            First Name
                                          </Text>
                                          <UIText
                                            style={styles.textReq}
                                            title={'*'}
                                          />
                                        </Text>
                                        <TextInput
                                          style={[
                                            styles.textField,
                                            validFirstName
                                              ? styles.validColor
                                              : styles.inValidColor,
                                          ]}
                                          allowFontScaling= {false}
                                          value={firstName ? firstName : ''}
                                          onChangeText={onChangeTextFirstName}
                                        />
                                      </View>
                                    </View>
                                    <View style={styles.fieldColHalf}>
                                      <View
                                        style={[
                                          styles.fieldColCont,
                                          styles.mgLtSm,
                                        ]}>
                                        <Text 
                                          style={[styles.textView, styles.textInputTopPd]}
                                          allowFontScaling={false}>
                                          <Text
                                            style={[
                                              styles.textLabel,
                                              styles.bold,
                                              styles.textColorLight,
                                            ]}
                                            allowFontScaling= {false}
                                            >
                                            Last Name
                                          </Text>
                                          <UIText
                                            style={styles.textReq}
                                            title={'*'}
                                          />
                                        </Text>
                                        <TextInput
                                          style={[
                                            styles.textField,
                                            validLastName
                                              ? styles.validColor
                                              : styles.inValidColor,
                                          ]}
                                          allowFontScaling= {false}
                                          value={lastName ? lastName : ''}
                                          onChangeText={onChangeTextLastName}
                                        />
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>

                                {!Boolean(journalUserId) && (
                                <View style={styles.fieldRow}>
                                  <Text 
                                    style={[styles.textView, styles.textInputTopPd]} 
                                    allowFontScaling={false}>
                                    <Text
                                      style={[
                                        styles.textLabel,
                                        styles.bold,
                                        styles.textColorLight,
                                      ]}
                                      allowFontScaling={false}
                                      >
                                      Email
                                    </Text>
                                    <UIText
                                      style={styles.textReq}
                                      title={'*'}
                                    />
                                  </Text>

                                  <TextInput
                                    style={[
                                      styles.textField,
                                      emailValidate
                                        ? styles.validColor
                                        : styles.inValidColor,
                                    ]}
                                    allowFontScaling= {false}
                                    value={email ? email : ''}
                                    onChangeText={onChangeTextEmail}
                                  />
                                </View>
                              )}

                              <View style={styles.fieldRow}>
                                <DateOfBirth
                                  onDayChange={handleOnDatePress}
                                  dob={dob}
                                  invalidDay={invalidDay}
                                  invalidMonth={invalidMonth}
                                  invalidYear={invalidYear}
                                  invalidDate={checkInvalidDate}
                                />
                              </View>

                              <View style={styles.fieldRow}>
                                <View
                                  style={[
                                    styles.pickerSelectionWrapper,
                                    styles.validColor,
                                  ]}>
                                  <Text
                                    style={[
                                      styles.textView,
                                      styles.textViewPicker,
                                    ]}
                                    allowFontScaling={false}>
                                    <UIText
                                      style={[
                                        styles.textLabel,
                                        styles.bold,
                                        styles.textColorLight,
                                      ]}
                                      title="Sex"
                                    />
                                    <UIText
                                      style={styles.textReq}
                                    />
                                  </Text>
                                  <View
                                    style={[
                                      stylesRes.dropArrowView,
                                      stylesRes.dropArrowCent,
                                    ]}
                                    pointerEvents={'none'}>
                                    <View style={stylesRes.dropArrowIcon}>
                                      <Image
                                        style={styles.imgContain}
                                        source={require('../../assets/icons/dropdown-arrow.png')}
                                      />
                                    </View>
                                  </View>
                                  <Picker
                                    items={[
                                      {label: '', value: '', key: 's_0'},
                                      {
                                        label: 'Male',
                                        value: 'MALE',
                                        key: 's_2',
                                      },
                                      {
                                        label: 'Female',
                                        value: 'FEMALE',
                                        key: 's_3',
                                      },
                                      {
                                        label: 'Intersex',
                                        value: 'INTERSEX',
                                        key: 's_4',
                                      },
                                      {
                                        label: 'Prefer not to answer',
                                        value: 'PREFER_NOT_TO_ANSWER',
                                        key: 's_5',
                                      },
                                    ]}
                                    style={[
                                      styles.pickerAreaField,
                                      styles.selectionPicker,
                                    ]}
                                    selectedValue={gender}
                                    onValueChange={onChangeGender}
                                  />
                                </View>
                              </View>
                              <View style={styles.fieldRow}>
                                <View style={styles.fieldColDouble}>
                                  <View style={styles.fieldColMain}>
                                    <View
                                      style={[
                                        styles.fieldColMax,
                                        styles.fledSpRt,
                                      ]}>
                                      <Text style={styles.textView} allowFontScaling= {false}>
                                        <UIText
                                          style={[
                                            styles.textLabel,
                                            styles.bold,
                                            styles.textColorLight,
                                          ]}
                                          title="Height"
                                        />
                                      </Text>

                                      <TextInput
                                        style={[
                                          styles.textField,
                                          styles.textFieldWhite,
                                          styles.textFieldMax,
                                        ]}
                                        allowFontScaling= {false}
                                        value={height ? height.toString() : ''}
                                        onChangeText={onChangeTextHeight}
                                        autoCapitalize="none"
                                        underlineColorAndroid="transparent"
                                        keyboardType={
                                          Platform.OS === WEB
                                            ? 'default'
                                            : 'numeric'
                                        }
                                      />
                                    </View>
                                  </View>
                                  <View style={styles.fieldColSub}>
                                    <View style={styles.fieldColMax}>
                                      <View style={styles.pickerAreaWrapper}>
                                        <View
                                          style={[
                                            stylesRes.dropArrowView,
                                            stylesRes.dropArrowCent,
                                          ]}
                                          pointerEvents={'none'}>
                                          <View style={stylesRes.dropArrowIcon}>
                                            <Image
                                              style={styles.imgContain}
                                              source={require('../../assets/icons/dropdown-arrow.png')}
                                            />
                                          </View>
                                        </View>
                                        <Picker
                                          items={getHeightUnits}
                                          style={[
                                            styles.pickerAreaField,
                                            styles.textPickerPdMedCom,
                                          ]}
                                          selectedValue={
                                            heightUomSymbol
                                              ? heightUomSymbol
                                              : ''
                                          }
                                          onValueChange={
                                            onChangeHeightUomSymbol
                                          }
                                        />
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>

                              <View style={styles.fieldRow}>
                                <View style={styles.fieldColDouble}>
                                  <View style={styles.fieldColMain}>
                                    <View
                                      style={[
                                        styles.fieldColMax,
                                        styles.fledSpRt,
                                      ]}>
                                      <Text style={styles.textView} allowFontScaling= {false}>
                                        <UIText
                                          style={[
                                            styles.textLabel,
                                            styles.bold,
                                            styles.textColorLight,
                                          ]}
                                          title="Weight"
                                        />
                                      </Text>

                                      <TextInput
                                        style={[
                                          styles.textField,
                                          styles.textFieldWhite,
                                          styles.textFieldMax,
                                        ]}
                                        allowFontScaling= {false}
                                        value={weight ? weight.toString() : ''}
                                        onChangeText={onChangeTextWeight}
                                        autoCapitalize="none"
                                        underlineColorAndroid="transparent"
                                        keyboardType={
                                          Platform.OS === WEB
                                            ? 'default'
                                            : 'numeric'
                                        }
                                      />
                                    </View>
                                  </View>
                                  <View style={styles.fieldColSub}>
                                    <View style={styles.fieldColMax}>
                                      <View style={styles.pickerAreaWrapper}>
                                        <View
                                          style={[
                                            stylesRes.dropArrowView,
                                            stylesRes.dropArrowCent,
                                          ]}
                                          pointerEvents={'none'}>
                                          <View style={stylesRes.dropArrowIcon}>
                                            <Image
                                              style={styles.imgContain}
                                              source={require('../../assets/icons/dropdown-arrow.png')}
                                            />
                                          </View>
                                        </View>
                                        <Picker
                                          items={getWeightUnits}
                                          style={[
                                            styles.pickerAreaField,
                                            styles.textPickerPdMedCom,
                                          ]}
                                          selectedValue={
                                            weightUomSymbol
                                              ? weightUomSymbol
                                              : ''
                                          }
                                          onValueChange={
                                            onChangeWeightUomSymbol
                                          }
                                        />
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>

                              <View style={styles.fieldRow}>
                                <Text style={styles.textView} allowFontScaling= {false}>
                                  <UIText
                                    style={[
                                      styles.textLabel,
                                      styles.bold,
                                      styles.textColorLight,
                                    ]}
                                    title="Ethnicity"
                                  />
                                </Text>

                                <TextInput
                                  style={[
                                    styles.textField,
                                    styles.textFieldWhite,
                                  ]}
                                  allowFontScaling= {false}
                                  value={ethnicity ? ethnicity : ''}
                                  onChangeText={onChangeTextEthnicity}
                                  underlineColorAndroid="transparent"
                                />
                              </View>

                              <View
                                style={[
                                  styles.fieldRow,
                                  styles.fieldRowFlexRow,
                                ]}>
                                <View style={styles.fieldColLgView}>
                                  <View
                                    style={[
                                      styles.fieldColCont,
                                      styles.mgRtSm,
                                    ]}>
                                    <Text style={styles.textView} allowFontScaling= {false}>
                                      <Text
                                        style={[
                                          styles.textLabel,
                                          styles.bold,
                                          styles.textColorLight,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        City
                                      </Text>
                                    </Text>
                                    <TextInput
                                      style={styles.textField}
                                      allowFontScaling= {false}
                                      onChangeText={onChangeTextCity}
                                      value={city}
                                      underlineColorAndroid="transparent"
                                    />
                                  </View>
                                </View>
                                <View style={styles.fieldColSmView}>
                                  <View
                                    style={[
                                      styles.fieldColCont,
                                      styles.mgLtSm,
                                    ]}>
                                    <View
                                      style={styles.pickerSelectionWrapper}>
                                      <Text
                                        style={[
                                          styles.textView,
                                          styles.textViewPicker,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        <UIText
                                          style={[
                                            styles.textLabel,
                                            styles.bold,
                                            styles.textColorLight,
                                          ]}
                                          title={
                                            country === 'Canada'
                                              ? 'Province'
                                              : country ===
                                                'United States of America (USA)'
                                              ? 'State'
                                              : 'Province/State'
                                          }
                                        />
                                      </Text>
                                      <View
                                        style={[
                                          stylesRes.dropArrowView,
                                          stylesRes.dropArrowCent,
                                        ]}
                                        pointerEvents={'none'}>
                                        <View style={stylesRes.dropArrowIcon}>
                                          <Image
                                            style={styles.imgContain}
                                            source={require('../../assets/icons/dropdown-arrow.png')}
                                          />
                                        </View>
                                      </View>
                                      {country === 'Canada' ? (
                                        <Picker
                                          items={[
                                            {
                                              label: '',
                                              value: '',
                                              key: 'ps_NONE',
                                            },
                                            {
                                              label: 'AB',
                                              value: 'AB',
                                              key: 'ps_AB',
                                            },
                                            {
                                              label: 'BC',
                                              value: 'BC',
                                              key: 'ps_BC',
                                            },
                                            {
                                              label: 'MB',
                                              value: 'MB',
                                              key: 'ps_MB',
                                            },
                                            {
                                              label: 'NB',
                                              value: 'NB',
                                              key: 'ps_NB',
                                            },
                                            {
                                              label: 'NL',
                                              value: 'NL',
                                              key: 'ps_NL',
                                            },
                                            {
                                              label: 'NS',
                                              value: 'NS',
                                              key: 'ps_NS',
                                            },
                                            {
                                              label: 'NT',
                                              value: 'NT',
                                              key: 'ps_NT',
                                            },
                                            {
                                              label: 'NU',
                                              value: 'NU',
                                              key: 'ps_NU',
                                            },
                                            {
                                              label: 'ON',
                                              value: 'ON',
                                              key: 'ps_ON',
                                            },
                                            {
                                              label: 'PE',
                                              value: 'PE',
                                              key: 'ps_PE',
                                            },
                                            {
                                              label: 'QC',
                                              value: 'QC',
                                              key: 'ps_QC',
                                            },
                                            {
                                              label: 'SK',
                                              value: 'SK',
                                              key: 'ps_SK',
                                            },
                                            {
                                              label: 'YT',
                                              value: 'YT',
                                              key: 'ps_YT',
                                            },
                                            {
                                              label: 'None',
                                              value: 'None',
                                              key: 'ps_NONE0',
                                            },
                                          ]}
                                          style={[
                                            styles.pickerAreaField,
                                            styles.selectionPicker,
                                          ]}
                                          onValueChange={onChangeState}
                                          selectedValue={state}
                                        />
                                      ) : country ===
                                        'United States of America (USA)' ? (
                                        <Picker
                                          items={[
                                            {
                                              label: '',
                                              value: '',
                                              key: 'ps_NONE',
                                            },
                                            {
                                              label: 'AL',
                                              value: 'AL',
                                              key: 'ps_AL',
                                            },
                                            {
                                              label: 'AK',
                                              value: 'AK',
                                              key: 'ps_AK',
                                            },
                                            {
                                              label: 'AZ',
                                              value: 'AZ',
                                              key: 'ps_AZ',
                                            },
                                            {
                                              label: 'AR',
                                              value: 'AR',
                                              key: 'ps_AR',
                                            },
                                            {
                                              label: 'CA',
                                              value: 'CA',
                                              key: 'ps_CA',
                                            },
                                            {
                                              label: 'CO',
                                              value: 'CO',
                                              key: 'ps_CO',
                                            },
                                            {
                                              label: 'CT',
                                              value: 'CT',
                                              key: 'ps_CT',
                                            },
                                            {
                                              label: 'DE',
                                              value: 'DE',
                                              key: 'ps_DE',
                                            },
                                            {
                                              label: 'DC',
                                              value: 'DC',
                                              key: 'ps_DC',
                                            },
                                            {
                                              label: 'FL',
                                              value: 'FL',
                                              key: 'ps_FL',
                                            },
                                            {
                                              label: 'GA',
                                              value: 'GA',
                                              key: 'ps_GA',
                                            },
                                            {
                                              label: 'HI',
                                              value: 'HI',
                                              key: 'ps_HI',
                                            },
                                            {
                                              label: 'ID',
                                              value: 'ID',
                                              key: 'ps_ID',
                                            },
                                            {
                                              label: 'IL',
                                              value: 'IL',
                                              key: 'ps_IL',
                                            },
                                            {
                                              label: 'IN',
                                              value: 'IN',
                                              key: 'ps_IN',
                                            },
                                            {
                                              label: 'IA',
                                              value: 'IA',
                                              key: 'ps_IA',
                                            },
                                            {
                                              label: 'KS',
                                              value: 'KS',
                                              key: 'ps_KS',
                                            },
                                            {
                                              label: 'KY',
                                              value: 'KY',
                                              key: 'ps_KY',
                                            },
                                            {
                                              label: 'LA',
                                              value: 'LA',
                                              key: 'ps_LA',
                                            },
                                            {
                                              label: 'ME',
                                              value: 'ME',
                                              key: 'ps_ME',
                                            },
                                            {
                                              label: 'MD',
                                              value: 'MD',
                                              key: 'ps_MD',
                                            },
                                            {
                                              label: 'MA',
                                              value: 'MA',
                                              key: 'ps_MA',
                                            },
                                            {
                                              label: 'MI',
                                              value: 'MI',
                                              key: 'ps_MI',
                                            },
                                            {
                                              label: 'MN',
                                              value: 'MN',
                                              key: 'ps_MN',
                                            },
                                            {
                                              label: 'MS',
                                              value: 'MS',
                                              key: 'ps_MS',
                                            },
                                            {
                                              label: 'MO',
                                              value: 'MO',
                                              key: 'ps_MO',
                                            },
                                            {
                                              label: 'MT',
                                              value: 'MT',
                                              key: 'ps_MT',
                                            },
                                            {
                                              label: 'NE',
                                              value: 'NE',
                                              key: 'ps_NE',
                                            },
                                            {
                                              label: 'NV',
                                              value: 'NV',
                                              key: 'ps_NV',
                                            },
                                            {
                                              label: 'NH',
                                              value: 'NH',
                                              key: 'ps_NH',
                                            },
                                            {
                                              label: 'NJ',
                                              value: 'NJ',
                                              key: 'ps_NJ',
                                            },
                                            {
                                              label: 'NM',
                                              value: 'NM',
                                              key: 'ps_NM',
                                            },
                                            {
                                              label: 'NY',
                                              value: 'NY',
                                              key: 'ps_NY',
                                            },
                                            {
                                              label: 'NC',
                                              value: 'NC',
                                              key: 'ps_NC',
                                            },
                                            {
                                              label: 'ND',
                                              value: 'ND',
                                              key: 'ps_ND',
                                            },
                                            {
                                              label: 'OH',
                                              value: 'OH',
                                              key: 'ps_OH',
                                            },
                                            {
                                              label: 'OK',
                                              value: 'OK',
                                              key: 'ps_OK',
                                            },
                                            {
                                              label: 'OR',
                                              value: 'OR',
                                              key: 'ps_OR',
                                            },
                                            {
                                              label: 'PA',
                                              value: 'PA',
                                              key: 'ps_PA',
                                            },
                                            {
                                              label: 'RI',
                                              value: 'RI',
                                              key: 'ps_RI',
                                            },
                                            {
                                              label: 'SC',
                                              value: 'SC',
                                              key: 'ps_SC',
                                            },
                                            {
                                              label: 'SD',
                                              value: 'SD',
                                              key: 'ps_SD',
                                            },
                                            {
                                              label: 'TN',
                                              value: 'TN',
                                              key: 'ps_TN',
                                            },
                                            {
                                              label: 'TX',
                                              value: 'TX',
                                              key: 'ps_TX',
                                            },
                                            {
                                              label: 'UT',
                                              value: 'UT',
                                              key: 'ps_UT',
                                            },
                                            {
                                              label: 'VT',
                                              value: 'VT',
                                              key: 'ps_VT',
                                            },
                                            {
                                              label: 'VA',
                                              value: 'VA',
                                              key: 'ps_VA',
                                            },
                                            {
                                              label: 'WA',
                                              value: 'WA',
                                              key: 'ps_WA',
                                            },
                                            {
                                              label: 'WV',
                                              value: 'WV',
                                              key: 'ps_WV',
                                            },
                                            {
                                              label: 'WI',
                                              value: 'WI',
                                              key: 'ps_WI',
                                            },
                                            {
                                              label: 'WY',
                                              value: 'WY',
                                              key: 'ps_WY',
                                            },
                                            {
                                              label: 'None',
                                              value: 'None',
                                              key: 'ps_NONE0',
                                            },
                                          ]}
                                          style={[
                                            styles.pickerAreaField,
                                            styles.selectionPicker,
                                          ]}
                                          onValueChange={onChangeState}
                                          selectedValue={state}
                                        />
                                      ) : (
                                        <Picker
                                          items={[
                                            {
                                              label: '',
                                              value: '',
                                              key: 'empty',
                                            },
                                          ]}
                                          style={[
                                            styles.pickerAreaField,
                                            styles.selectionPicker,
                                          ]}
                                          onValueChange={onChangeState}
                                          selectedValue={state}
                                        />
                                      )}
                                    </View>
                                  </View>
                                </View>
                              </View>

                              <View
                                style={[styles.fieldRow, styles.fieldRowNoMg]}>
                                <View style={styles.pickerSelectionWrapper}>
                                  <Text
                                    style={[
                                      styles.textView,
                                      styles.textViewPicker,
                                    ]}
                                    allowFontScaling= {false}
                                    >
                                    <UIText
                                      style={[
                                        styles.textLabel,
                                        styles.bold,
                                        styles.textColorLight,
                                      ]}
                                      title="Country"
                                    />
                                  </Text>
                                  <View
                                    style={[
                                      stylesRes.dropArrowView,
                                      stylesRes.dropArrowCent,
                                    ]}
                                    pointerEvents={'none'}>
                                    <View style={stylesRes.dropArrowIcon}>
                                      <Image
                                        style={styles.imgContain}
                                        source={require('../../assets/icons/dropdown-arrow.png')}
                                      />
                                    </View>
                                  </View>
                                  <Picker
                                    items={[
                                      {
                                        label: '',
                                        value: '',
                                        key: 'c_0',
                                      },
                                      {
                                        label: 'Canada',
                                        value: 'Canada',
                                        key: 'c_1',
                                      },
                                      {
                                        label: 'United States of America (USA)',
                                        value: 'United States of America (USA)',
                                        key: 'c_2',
                                      },
                                      {
                                        label: 'Afghanistan',
                                        value: 'Afghanistan',
                                        key: 'c_3',
                                      },
                                      {
                                        label: 'Albania',
                                        value: 'Albania',
                                        key: 'c_4',
                                      },
                                      {
                                        label: 'Algeria',
                                        value: 'Algeria',
                                        key: 'c_5',
                                      },
                                      {
                                        label: 'Andorra',
                                        value: 'Andorra',
                                        key: 'c_6',
                                      },
                                      {
                                        label: 'Angola',
                                        value: 'Angola',
                                        key: 'c_7',
                                      },
                                      {
                                        label: 'Antigua and Barbuda',
                                        value: 'Antigua and Barbuda',
                                        key: 'c_8',
                                      },
                                      {
                                        label: 'Argentina',
                                        value: 'Argentina',
                                        key: 'c_9',
                                      },
                                      {
                                        label: 'Armenia',
                                        value: 'Armenia',
                                        key: 'c_10',
                                      },
                                      {
                                        label: 'Australia',
                                        value: 'Australia',
                                        key: 'c_11',
                                      },
                                      {
                                        label: 'Austria',
                                        value: 'Austria',
                                        key: 'c_12',
                                      },
                                      {
                                        label: 'Azerbaijan',
                                        value: 'Azerbaijan',
                                        key: 'c_13',
                                      },
                                      {
                                        label: 'Bahamas',
                                        value: 'Bahamas',
                                        key: 'c_15',
                                      },
                                      {
                                        label: 'Bahrain',
                                        value: 'Bahrain',
                                        key: 'c_16',
                                      },
                                      {
                                        label: 'Bangladesh',
                                        value: 'Bangladesh',
                                        key: 'c_17',
                                      },
                                      {
                                        label: 'Barbados',
                                        value: 'Barbados',
                                        key: 'c_18',
                                      },
                                      {
                                        label: 'Belarus',
                                        value: 'Belarus',
                                        key: 'c_19',
                                      },
                                      {
                                        label: 'Belgium',
                                        value: 'Belgium',
                                        key: 'c_20',
                                      },
                                      {
                                        label: 'Belize',
                                        value: 'Belize',
                                        key: 'c_21',
                                      },
                                      {
                                        label: 'Benin',
                                        value: 'Benin',
                                        key: 'c_22',
                                      },
                                      {
                                        label: 'Bhutan',
                                        value: 'Bhutan',
                                        key: 'c_23',
                                      },
                                      {
                                        label: 'Bolivia',
                                        value: 'Bolivia',
                                        key: 'c_24',
                                      },
                                      {
                                        label: 'Bosnia and Herzegovina',
                                        value: 'Bosnia and Herzegovina',
                                        key: 'c_25',
                                      },
                                      {
                                        label: 'Botswana',
                                        value: 'Botswana',
                                        key: 'c_26',
                                      },
                                      {
                                        label: 'Brazil',
                                        value: 'Brazil',
                                        key: 'c_27',
                                      },
                                      {
                                        label: 'Brunei',
                                        value: 'Brunei',
                                        key: 'c_28',
                                      },
                                      {
                                        label: 'Bulgaria',
                                        value: 'Bulgaria',
                                        key: 'c_29',
                                      },
                                      {
                                        label: 'Burkina Faso',
                                        value: 'Burkina Faso',
                                        key: 'c_30',
                                      },
                                      {
                                        label: 'Burundi',
                                        value: 'Burundi',
                                        key: 'c_31',
                                      },
                                      {
                                        label: 'Cabo Verde',
                                        value: 'Cabo Verde',
                                        key: 'c_32',
                                      },
                                      {
                                        label: 'Cambodia',
                                        value: 'Cambodia',
                                        key: 'c_33',
                                      },
                                      {
                                        label: 'Cameroon',
                                        value: 'Cameroon',
                                        key: 'c_34',
                                      },
                                      {
                                        label: 'Central African Republic (CAR)',
                                        value: 'Central African Republic (CAR)',
                                        key: 'c_35',
                                      },
                                      {
                                        label: 'Chad',
                                        value: 'Chad',
                                        key: 'c_36',
                                      },
                                      {
                                        label: 'Chile',
                                        value: 'Chile',
                                        key: 'c_37',
                                      },
                                      {
                                        label: 'China',
                                        value: 'China',
                                        key: 'c_199',
                                      },
                                      {
                                        label: 'Colombia',
                                        value: 'Colombia',
                                        key: 'c_38',
                                      },
                                      {
                                        label: 'Comoros',
                                        value: 'Comoros',
                                        key: 'c_39',
                                      },
                                      {
                                        label:
                                          'Congo, Democratic Republic of the',
                                        value:
                                          'Congo, Democratic Republic of the',
                                        key: 'c_40',
                                      },
                                      {
                                        label: 'Congo, Republic of the',
                                        value: 'Congo, Republic of the',
                                        key: 'c_41',
                                      },
                                      {
                                        label: 'Costa Rica',
                                        value: 'Costa Rica',
                                        key: 'c_42',
                                      },
                                      {
                                        label: "Cote d'Ivoire",
                                        value: "Cote d'Ivoire",
                                        key: 'c_43',
                                      },
                                      {
                                        label: 'Croatia',
                                        value: 'Croatia',
                                        key: 'c_44',
                                      },
                                      {
                                        label: 'Cuba',
                                        value: 'Cuba',
                                        key: 'c_45',
                                      },
                                      {
                                        label: 'Cyprus',
                                        value: 'Cyprus',
                                        key: 'c_46',
                                      },
                                      {
                                        label: 'Czechia',
                                        value: 'Czechia',
                                        key: 'c_47',
                                      },
                                      {
                                        label: 'Denmark',
                                        value: 'Denmark',
                                        key: 'c_48',
                                      },
                                      {
                                        label: 'Djibouti',
                                        value: 'Djibouti',
                                        key: 'c_49',
                                      },
                                      {
                                        label: 'Dominica',
                                        value: 'Dominica',
                                        key: 'c_50',
                                      },
                                      {
                                        label: 'Dominican Republic',
                                        value: 'Dominican Republic',
                                        key: 'c_51',
                                      },
                                      {
                                        label: 'Ecuador',
                                        value: 'Ecuador',
                                        key: 'c_52',
                                      },
                                      {
                                        label: 'Egypt',
                                        value: 'Egypt',
                                        key: 'c_53',
                                      },
                                      {
                                        label: 'El Salvador',
                                        value: 'El Salvador',
                                        key: 'c_54',
                                      },
                                      {
                                        label: 'Equatorial Guinea',
                                        value: 'Equatorial Guinea',
                                        key: 'c_55',
                                      },
                                      {
                                        label: 'Eritrea',
                                        value: 'Eritrea',
                                        key: 'c_56',
                                      },
                                      {
                                        label: 'Estonia',
                                        value: 'Estonia',
                                        key: 'c_57',
                                      },
                                      {
                                        label: 'Eswatini (formerly Swaziland)',
                                        value: 'Eswatini (formerly Swaziland)',
                                        key: 'c_58',
                                      },
                                      {
                                        label: 'Ethiopia',
                                        value: 'Ethiopia',
                                        key: 'c_59',
                                      },
                                      {
                                        label: 'Fiji',
                                        value: 'Fiji',
                                        key: 'c_60',
                                      },
                                      {
                                        label: 'Finland',
                                        value: 'Finland',
                                        key: 'c_61',
                                      },
                                      {
                                        label: 'France',
                                        value: 'France',
                                        key: 'c_62',
                                      },
                                      {
                                        label: 'Gabon',
                                        value: 'Gabon',
                                        key: 'c_63',
                                      },
                                      {
                                        label: 'Gambia',
                                        value: 'Gambia',
                                        key: 'c_64',
                                      },
                                      {
                                        label: 'Georgia',
                                        value: 'Georgia',
                                        key: 'c_65',
                                      },
                                      {
                                        label: 'Germany',
                                        value: 'Germany',
                                        key: 'c_66',
                                      },
                                      {
                                        label: 'Ghana',
                                        value: 'Ghana',
                                        key: 'c_67',
                                      },
                                      {
                                        label: 'Greece',
                                        value: 'Greece',
                                        key: 'c_68',
                                      },
                                      {
                                        label: 'Grenada',
                                        value: 'Grenada',
                                        key: 'c_69',
                                      },
                                      {
                                        label: 'Guatemala',
                                        value: 'Guatemala',
                                        key: 'c_70',
                                      },
                                      {
                                        label: 'Guinea',
                                        value: 'Guinea',
                                        key: 'c_71',
                                      },
                                      {
                                        label: 'Guinea-Bissau',
                                        value: 'Guinea-Bissau',
                                        key: 'c_72',
                                      },
                                      {
                                        label: 'Guyana',
                                        value: 'Guyana',
                                        key: 'c_73',
                                      },
                                      {
                                        label: 'Haiti',
                                        value: 'Haiti',
                                        key: 'c_74',
                                      },
                                      {
                                        label: 'Honduras',
                                        value: 'Honduras',
                                        key: 'c_75',
                                      },
                                      {
                                        label: 'Hungary',
                                        value: 'Hungary',
                                        key: 'c_76',
                                      },
                                      {
                                        label: 'Iceland',
                                        value: 'Iceland',
                                        key: 'c_77',
                                      },
                                      {
                                        label: 'India',
                                        value: 'India',
                                        key: 'c_78',
                                      },
                                      {
                                        label: 'Indonesia',
                                        value: 'Indonesia',
                                        key: 'c_201',
                                      },
                                      {
                                        label: 'Iran',
                                        value: 'Iran',
                                        key: 'c_80',
                                      },
                                      {
                                        label: 'Iraq',
                                        value: 'Iraq',
                                        key: 'c_81',
                                      },
                                      {
                                        label: 'Ireland',
                                        value: 'Ireland',
                                        key: 'c_82',
                                      },
                                      {
                                        label: 'Israel',
                                        value: 'Israel',
                                        key: 'c_83',
                                      },
                                      {
                                        label: 'Italy',
                                        value: 'Italy',
                                        key: 'c_84',
                                      },
                                      {
                                        label: 'Jamaica',
                                        value: 'Jamaica',
                                        key: 'c_85',
                                      },
                                      {
                                        label: 'Japan',
                                        value: 'Japan',
                                        key: 'c_86',
                                      },
                                      {
                                        label: 'Jordan',
                                        value: 'Jordan',
                                        key: 'c_87',
                                      },
                                      {
                                        label: 'Kazakhstan',
                                        value: 'Kazakhstan',
                                        key: 'c_88',
                                      },
                                      {
                                        label: 'Kenya',
                                        value: 'Kenya',
                                        key: 'c_89',
                                      },
                                      {
                                        label: 'Kiribati',
                                        value: 'Kiribati',
                                        key: 'c_90',
                                      },
                                      {
                                        label: 'Kosovo',
                                        value: 'Kosovo',
                                        key: 'c_91',
                                      },
                                      {
                                        label: 'Kuwait',
                                        value: 'Kuwait',
                                        key: 'c_92',
                                      },
                                      {
                                        label: 'Kyrgyzstan',
                                        value: 'Kyrgyzstan',
                                        key: 'c_93',
                                      },
                                      {
                                        label: 'Laos',
                                        value: 'Laos',
                                        key: 'c_94',
                                      },
                                      {
                                        label: 'Latvia',
                                        value: 'Latvia',
                                        key: 'c_95',
                                      },
                                      {
                                        label: 'Lebanon',
                                        value: 'Lebanon',
                                        key: 'c_96',
                                      },
                                      {
                                        label: 'Lesotho',
                                        value: 'Lesotho',
                                        key: 'c_97',
                                      },
                                      {
                                        label: 'Liberia',
                                        value: 'Liberia',
                                        key: 'c_98',
                                      },
                                      {
                                        label: 'Libya',
                                        value: 'Libya',
                                        key: 'c_99',
                                      },
                                      {
                                        label: 'Liechtenstein',
                                        value: 'Liechtenstein',
                                        key: 'c_100',
                                      },
                                      {
                                        label: 'Lithuania',
                                        value: 'Lithuania',
                                        key: 'c_101',
                                      },
                                      {
                                        label: 'Luxembourg',
                                        value: 'Luxembourg',
                                        key: 'c_102',
                                      },
                                      {
                                        label: 'Madagascar',
                                        value: 'Madagascar',
                                        key: 'c_103',
                                      },
                                      {
                                        label: 'Malawi',
                                        value: 'Malawi',
                                        key: 'c_104',
                                      },
                                      {
                                        label: 'Malaysia',
                                        value: 'Malaysia',
                                        key: 'c_105',
                                      },
                                      {
                                        label: 'Maldives',
                                        value: 'Maldives',
                                        key: 'c_106',
                                      },
                                      {
                                        label: 'Mali',
                                        value: 'Mali',
                                        key: 'c_107',
                                      },
                                      {
                                        label: 'Malta',
                                        value: 'Malta',
                                        key: 'c_108',
                                      },
                                      {
                                        label: 'Marshall Islands',
                                        value: 'Marshall Islands',
                                        key: 'c_109',
                                      },
                                      {
                                        label: 'Mauritania',
                                        value: 'Mauritania',
                                        key: 'c_110',
                                      },
                                      {
                                        label: 'Mauritius',
                                        value: 'Mauritius',
                                        key: 'c_111',
                                      },
                                      {
                                        label: 'Mexico',
                                        value: 'Mexico',
                                        key: 'c_112',
                                      },
                                      {
                                        label: 'Micronesia',
                                        value: 'Micronesia',
                                        key: 'c_113',
                                      },
                                      {
                                        label: 'Moldova',
                                        value: 'Moldova',
                                        key: 'c_114',
                                      },
                                      {
                                        label: 'Monaco',
                                        value: 'Monaco',
                                        key: 'c_115',
                                      },
                                      {
                                        label: 'Mongolia',
                                        value: 'Mongolia',
                                        key: 'c_116',
                                      },
                                      {
                                        label: 'Montenegro',
                                        value: 'Montenegro',
                                        key: 'c_117',
                                      },
                                      {
                                        label: 'Morocco',
                                        value: 'Morocco',
                                        key: 'c_118',
                                      },
                                      {
                                        label: 'Mozambique',
                                        value: 'Mozambique',
                                        key: 'c_119',
                                      },
                                      {
                                        label: 'Myanmar',
                                        value: 'Myanmar',
                                        key: 'c_120',
                                      },
                                      {
                                        label: 'Namibia',
                                        value: 'Namibia',
                                        key: 'c_121',
                                      },
                                      {
                                        label: 'Nauru',
                                        value: 'Nauru',
                                        key: 'c_122',
                                      },
                                      {
                                        label: 'Nepal',
                                        value: 'Nepal',
                                        key: 'c_123',
                                      },
                                      {
                                        label: 'Netherlands',
                                        value: 'Netherlands',
                                        key: 'c_124',
                                      },
                                      {
                                        label: 'New Zealand',
                                        value: 'New Zealand',
                                        key: 'c_125',
                                      },
                                      {
                                        label: 'Nicaragua',
                                        value: 'Nicaragua',
                                        key: 'c_126',
                                      },
                                      {
                                        label: 'Niger',
                                        value: 'Niger',
                                        key: 'c_127',
                                      },
                                      {
                                        label: 'Nigeria',
                                        value: 'Nigeria',
                                        key: 'c_128',
                                      },
                                      {
                                        label: 'North Korea',
                                        value: 'North Korea',
                                        key: 'c_129',
                                      },
                                      {
                                        label: 'North Macedonia',
                                        value: 'North Macedonia',
                                        key: 'c_130',
                                      },
                                      {
                                        label: 'Norway',
                                        value: 'Norway',
                                        key: 'c_131',
                                      },
                                      {
                                        label: 'Oman',
                                        value: 'Oman',
                                        key: 'c_132',
                                      },
                                      {
                                        label: 'Pakistan',
                                        value: 'Pakistan',
                                        key: 'c_133',
                                      },
                                      {
                                        label: 'Palau',
                                        value: 'Palau',
                                        key: 'c_134',
                                      },
                                      {
                                        label: 'Palestine',
                                        value: 'Palestine',
                                        key: 'c_135',
                                      },
                                      {
                                        label: 'Panama',
                                        value: 'Panama',
                                        key: 'c_136',
                                      },
                                      {
                                        label: 'Papua New Guinea',
                                        value: 'Papua New Guinea',
                                        key: 'c_137',
                                      },
                                      {
                                        label: 'Paraguay',
                                        value: 'Paraguay',
                                        key: 'c_138',
                                      },
                                      {
                                        label: 'Peru',
                                        value: 'Peru',
                                        key: 'c_139',
                                      },
                                      {
                                        label: 'Philippines',
                                        value: 'Philippines',
                                        key: 'c_140',
                                      },
                                      {
                                        label: 'Poland',
                                        value: 'Poland',
                                        key: 'c_141',
                                      },
                                      {
                                        label: 'Portugal',
                                        value: 'Portugal',
                                        key: 'c_142',
                                      },
                                      {
                                        label: 'Qatar',
                                        value: 'Qatar',
                                        key: 'c_143',
                                      },
                                      {
                                        label: 'Romania',
                                        value: 'Romania',
                                        key: 'c_144',
                                      },
                                      {
                                        label: 'Russia',
                                        value: 'Russia',
                                        key: 'c_145',
                                      },
                                      {
                                        label: 'Rwanda',
                                        value: 'Rwanda',
                                        key: 'c_146',
                                      },
                                      {
                                        label: 'Saint Kitts and Nevis',
                                        value: 'Saint Kitts and Nevis',
                                        key: 'c_147',
                                      },
                                      {
                                        label: 'Saint Lucia',
                                        value: 'Saint Lucia',
                                        key: 'c_148',
                                      },
                                      {
                                        label:
                                          'Saint Vincent and the Grenadines',
                                        value:
                                          'Saint Vincent and the Grenadines',
                                        key: 'c_149',
                                      },
                                      {
                                        label: 'Samoa',
                                        value: 'Samoa',
                                        key: 'c_150',
                                      },
                                      {
                                        label: 'San Marino',
                                        value: 'San Marino',
                                        key: 'c_151',
                                      },
                                      {
                                        label: 'Sao Tome and Principe',
                                        value: 'Sao Tome and Principe',
                                        key: 'c_152',
                                      },
                                      {
                                        label: 'Saudi Arabia',
                                        value: 'Saudi Arabia',
                                        key: 'c_153',
                                      },
                                      {
                                        label: 'Senegal',
                                        value: 'Senegal',
                                        key: 'c_154',
                                      },
                                      {
                                        label: 'Serbia',
                                        value: 'Serbia',
                                        key: 'c_155',
                                      },
                                      {
                                        label: 'Seychelles',
                                        value: 'Seychelles',
                                        key: 'c_156',
                                      },
                                      {
                                        label: 'Sierra Leone',
                                        value: 'Sierra Leone',
                                        key: 'c_157',
                                      },
                                      {
                                        label: 'Singapore',
                                        value: 'Singapore',
                                        key: 'c_158',
                                      },
                                      {
                                        label: 'Slovakia',
                                        value: 'Slovakia',
                                        key: 'c_159',
                                      },
                                      {
                                        label: 'Slovenia',
                                        value: 'Slovenia',
                                        key: 'c_160',
                                      },
                                      {
                                        label: 'Solomon Islands',
                                        value: 'Solomon Islands',
                                        key: 'c_161',
                                      },
                                      {
                                        label: 'Somalia',
                                        value: 'Somalia',
                                        key: 'c_162',
                                      },
                                      {
                                        label: 'South Africa',
                                        value: 'South Africa',
                                        key: 'c_163',
                                      },
                                      {
                                        label: 'South Korea',
                                        value: 'South Korea',
                                        key: 'c_164',
                                      },
                                      {
                                        label: 'South Sudan',
                                        value: 'South Sudan',
                                        key: 'c_165',
                                      },
                                      {
                                        label: 'Spain',
                                        value: 'Spain',
                                        key: 'c_166',
                                      },
                                      {
                                        label: 'Sri Lanka',
                                        value: 'Sri Lanka',
                                        key: 'c_167',
                                      },
                                      {
                                        label: 'Sudan',
                                        value: 'Sudan',
                                        key: 'c_168',
                                      },
                                      {
                                        label: 'Suriname',
                                        value: 'Suriname',
                                        key: 'c_169',
                                      },
                                      {
                                        label: 'Sweden',
                                        value: 'Sweden',
                                        key: 'c_170',
                                      },
                                      {
                                        label: 'Switzerland',
                                        value: 'Switzerland',
                                        key: 'c_171',
                                      },
                                      {
                                        label: 'Syria',
                                        value: 'Syria',
                                        key: 'c_172',
                                      },
                                      {
                                        label: 'Taiwan',
                                        value: 'Taiwan',
                                        key: 'c_173',
                                      },
                                      {
                                        label: 'Tajikistan',
                                        value: 'Tajikistan',
                                        key: 'c_174',
                                      },
                                      {
                                        label: 'Tanzania',
                                        value: 'Tanzania',
                                        key: 'c_200',
                                      },
                                      {
                                        label: 'Thailand',
                                        value: 'Thailand',
                                        key: 'c_175',
                                      },
                                      {
                                        label: 'Timor-Leste',
                                        value: 'Timor-Leste',
                                        key: 'c_176',
                                      },
                                      {
                                        label: 'Togo',
                                        value: 'Togo',
                                        key: 'c_177',
                                      },
                                      {
                                        label: 'Tonga',
                                        value: 'Tonga',
                                        key: 'c_178',
                                      },
                                      {
                                        label: 'Trinidad and Tobago',
                                        value: 'Trinidad and Tobago',
                                        key: 'c_179',
                                      },
                                      {
                                        label: 'Tunisia',
                                        value: 'Tunisia',
                                        key: 'c_180',
                                      },
                                      {
                                        label: 'Turkey',
                                        value: 'Turkey',
                                        key: 'c_181',
                                      },
                                      {
                                        label: 'Turkmenistan',
                                        value: 'Turkmenistan',
                                        key: 'c_182',
                                      },
                                      {
                                        label: 'Tuvalu',
                                        value: 'Tuvalu',
                                        key: 'c_183',
                                      },
                                      {
                                        label: 'Uganda',
                                        value: 'Uganda',
                                        key: 'c_184',
                                      },
                                      {
                                        label: 'Ukraine',
                                        value: 'Ukraine',
                                        key: 'c_185',
                                      },
                                      {
                                        label: 'United Arab Emirates (UAE)',
                                        value: 'United Arab Emirates (UAE)',
                                        key: 'c_186',
                                      },
                                      {
                                        label: 'United Kingdom (UK)',
                                        value: 'United Kingdom (UK)',
                                        key: 'c_187',
                                      },
                                      {
                                        label: 'Uruguay',
                                        value: 'Uruguay',
                                        key: 'c_188',
                                      },
                                      {
                                        label: 'Uzbekistan',
                                        value: 'Uzbekistan',
                                        key: 'c_189',
                                      },
                                      {
                                        label: 'Vanuatu',
                                        value: 'Vanuatu',
                                        key: 'c_190',
                                      },
                                      {
                                        label: 'Vatican City (Holy See)',
                                        value: 'Vatican City (Holy See)',
                                        key: 'c_191',
                                      },
                                      {
                                        label: 'Venezuela',
                                        value: 'Venezuela',
                                        key: 'c_192',
                                      },
                                      {
                                        label: 'Vietnam',
                                        value: 'Vietnam',
                                        key: 'c_193',
                                      },
                                      {
                                        label: 'Yemen',
                                        value: 'Yemen',
                                        key: 'c_194',
                                      },
                                      {
                                        label: 'Zambia',
                                        value: 'Zambia',
                                        key: 'c_195',
                                      },
                                      {
                                        label: 'Zimbabwe',
                                        value: 'Zimbabwe',
                                        key: 'c_196',
                                      },
                                      {
                                        label: 'Others',
                                        value: 'Others',
                                        key: 'c_197',
                                      },
                                      {
                                        label: 'Europe',
                                        value: 'Europe',
                                        key: 'c_198',
                                      },
                                    ]}
                                    style={[
                                      styles.pickerAreaField,
                                      styles.selectionPicker,
                                    ]}
                                    onValueChange={onChangeCountry}
                                    selectedValue={country}
                                  />
                                </View>
                              </View>
                              <Modal
                                visible={visibleCalModal}
                                onRequestClose={onRequestClose}
                                onBackdropPress={onBackdropPress}
                                defaultStyle={[
                                  styles.customPopUpStyle,
                                  styles.calTopSpLg,
                                ]}>
                                <View style={stylesRes.modalScrollCal} dataSet={{media: ids.modalScrollCal}}>
                                  <ScrollView style={styles.modalScroller}>
                                    <View style={stylesRes.calViewModalCom} dataSet={{media: ids.calViewModalCom}}>
                                      <Calendar
                                        onDayPress={handleOnDatePress}
                                        maxDate={maxDate}
                                        current={dob}
                                      />
                                    </View>
                                  </ScrollView>
                                </View>
                              </Modal>

                              <View style={[styles.btnArea, styles.comMgTop]}>
                                <View
                                  style={[
                                    stylesRes.btnViewSec,
                                    stylesRes.btnViewSecComNoPd,
                                  ]}>
                                  <View style={styles.fieldBtn}>
                                    <View style={styles.btnBlockFull}>
                                      <CancelButton
                                        touchableOnpress={() => {
                                          onPressCancel();
                                          pendoTrackEvent('Profile@ > Cancel');
                                        }}
                                        disabled={saveWait}
                                        nativeId={'profileCancel'}
                                      />
                                    </View>
                                  </View>
                                  <View style={styles.fieldBtnSp} />
                                  <View style={styles.fieldBtn}>
                                    <View style={styles.btnBlockFull}>
                                      <TouchableOpacity
                                        style={[
                                          styles.submitSm,
                                          saveWait || !props.getProfileChange
                                            ? styles.btnBlurState
                                            : styles.btnActiveState,
                                        ]}
                                        underlayColor="rgba(0, 153, 168, 0.8)"
                                        onPress={handleSaveClick}
                                        disabled={
                                          saveInprogress || saveWait || !props.getProfileChange
                                        }
                                        nativeID={'profileSave'}>
                                        <View style={styles.btnSingleView}>
                                          <View
                                            style={[
                                              styles.btnSingle,
                                              styles.btnSingleCent,
                                            ]}>
                                            {saveWait && (
                                              <View style={styles.actIndCom}>
                                                <ActivityIndicator
                                                  pdZero={true}
                                                  size="small"
                                                />
                                              </View>
                                            )}
                                            <UIText
                                              style={[
                                                styles.submitBtnTextWhite,
                                                styles.bold
                                              ]}
                                              title="Save"
                                            />
                                          </View>
                                        </View>
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </PageContainer>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollViewHoc>
      )}
    </View>
  );
};

export default (EditProfile);
