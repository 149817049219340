import React, { Component } from "react";
import { Text, View, Image } from "react-native";
import styles from "../../Css";
import InputField from "../TextInput/inputField";
import SingleButton from "../Button/singleButton";
import { connect } from "react-redux";
import ModalLayout from "../ModalLayout/modalLayout";
import ActivityIndicator from "../ActivityIndicator/activityIndicator";
import { DASHBOARD } from "../../navigation/path";
import * as actions from "../../store/actions/index";

class JoinStudyPopup extends Component {
  constructor(props) {
      super(props);
      this.state = {
          accessCode: props.joinStudyData?.accessCode,
          success: false,
          validating: false,
          joining: false,
      }
      this.setState({validating: true});
      this.validateAccessCode(props.joinStudyData?.accessCode);
      this.joinStudyStatusCheck = null;
  }

  componentDidMount() {
    this.setState({validating: true});
    this.validateAccessCode(this.props.joinStudyData?.accessCode);
  }

  onChangeText = (text) => {
    this.setState({accessCode: text, validating: !text ? false : true});
    if (text && text.length === this.props.joinStudyData.accessCode.length) {
        this.validateAccessCode(text);
    } else {
        this.setState({success: false, validating: false});
    } 
  }

  validateAccessCode = (accessCode) => {
    this.props.handleJoinStudyRequests("VALIDATE", accessCode, res => {
        if (res) {
            this.setState({success: true, validating: false});
        } else {
            this.setState({success: false, validating: false});
        }
    }, this.props.journalUserId);
  }

  onPressJoin = () => {
    this.setState({joining: true}); 
    this.props.handleJoinStudyRequests("JOIN", this.state.accessCode, (res, data) => {
        if (res) {
            this.joinStudyStatusCheck = setInterval(() => {
                this.props.getJoinStudyStatus(data?.id, (sucess) => {
                    if (sucess) {
                        this.stopJoinStudyStatusCheck();
                        this.setState({joining: false});
                        this.props.setJoinStudyData({});
                        this.props.hidePopup();
                        this.props.navigation.reset({
                            index: 0,
                            routes: [{ 
                                name: DASHBOARD.path,
                            }],
                        });
                    }
                }, this.props.journalUserId);
            }, 5000);
            /**
             * Delete notification handle in BE side
             * */
            //Clear and Delete joinStudy notification
            // if (this.props.joinStudyData?.isFromNotif) {
            //     const idArray = [];
            //     idArray.push(this.props.joinStudyData.id);
            //     this.props.deleteNotifcation(this.props.journalUserId, idArray, res => {
            //         if (res) {
            //         this.props.clearNotificationById(this.props.joinStudyData.notifId);
            //         }
            //     });
            // }
        } else {
            this.setState({joining: false});
        }
    }, 
    this.props.journalUserId);  
  }

  hidePopup = () => {
    this.stopJoinStudyStatusCheck();
    this.props.navigation.reset({
        index: 0,
        routes: [{ 
            name: DASHBOARD.path,
        }],
    });
    this.props.setJoinStudyData({});
    this.props.hidePopup();
  }

  //clear interval
  stopJoinStudyStatusCheck = () => {
    if (this.joinStudyStatusCheck) {
        clearInterval(this.joinStudyStatusCheck);
        this.joinStudyStatusCheck = null;
    }
  }

  render() {
    return (
        <ModalLayout
            contPosition={"start"}
            showClose={true}
            popupAuto={false}
            boxStyle={false}
            hidePopup={this.hidePopup}
        >
            <View styles={[styles.dashboardmodalWrapper]}>
                {/* text */}
                <View style={[styles.textAlignCenter,styles.contSmPdTopBotCr, styles.contentWrapperPd]}>
                    <Text style={[styles.textPrimeLgBold, styles.dashboardPopUpTxt, styles.textAlignCenter]} allowFontScaling={false}> 
                        Join the<Text style={styles.dashboardPopUpHighlight} allowFontScaling={false}> {this.props.joinStudyData.profile} </Text>  
                        at 
                        <Text style={styles.dashboardPopUpHighlight} allowFontScaling={false}> {this.props.joinStudyData.orgName} </Text>
                    </Text>
                </View>

                {/* textInput */}
                <View style={styles.fieldContainer}>
                    <View style={styles.fieldRowWrapper}>
                        <View style={[styles.fieldColActitySliderPreview, styles.deviceArea]}>
                            <InputField
                                title={'Enter Access Code'}
                                requirdStar={true}
                                fieldLt={false}
                                success={this.state.validating ? false : this.state.success}
                                invalid={this.state.validating ? false : !this.state.success}
                                value={this.state.accessCode}
                                onChangeText={this.onChangeText}
                                editable={!this.state.joining}
                                maxLength={18}
                            />

                            <View style={styles.popUpGreenRightIcon}>
                                {/* tick Icon */}
                                {this.state.validating 
                                    ?   <View style={styles.joinStudyIndicator}>
                                            <ActivityIndicator size/>
                                        </View>
                                        
                                    :   this.state.success 
                                        ?    <Image
                                                style={styles.statusCorrectIcon}
                                                source={require('../../assets/icons/right-green-icon.png')}
                                                resizeMode={'contain'}
                                            />
                                        :   <Image
                                                style={styles.statusWrongIcon}
                                                source={require('../../assets/icons/warning-exclamation.png')}
                                                resizeMode={'contain'}
                                            />
                                }
                            </View>
                        </View>
                    </View>
                </View>
                
                {/* text */}
                <View style={[styles.textAlignCenter,styles.dashboardPopUpPd]}>
                    <Text style={[styles.textPrime, styles.dashboardPopUpTxt, styles.textAlignCenter]}allowFontScaling={false}>
                        Click <Text style={[styles.textPrimeBold]}allowFontScaling={false}>join</Text> to begin as a participant.
                    </Text>
                </View>

                {/* text */}
                <View style={[styles.textAlignCenter, styles.dashboardPopUpBt, styles.deviceArea]}>
                    <Text style={[styles.textPrime, styles.dashboardPopUpTxt, styles.textAlignCenter]} allowFontScaling={false}>
                    Once you click join you will be introduced to a customized version of Zamplo for the
                    <Text style={styles.textPrimeBold} allowFontScaling={false}> {this.props.joinStudyData.profile} </Text>
                    </Text>
                </View>
                
                {/* join button */}
                <View style={[styles.gridRow,styles.dashboardPopUpBtn]}>
                    <View style={[styles.gridColFull, styles.joinBtnAlignment]}>
                        <View style={[styles.btnArea, styles.btnPdTopMini]}>
                            <View style={[styles.fieldBtn]}>
                                <SingleButton
                                    btnText={'Join'}
                                    noBtnShadow={true}
                                    btnLg={true}
                                    touchableOnPress={this.onPressJoin}
                                    disabled={!this.state.success || this.state.joining}
                                    activityInd={this.state.joining}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </ModalLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    joinStudyData: state.uistate.joinStudyData,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleJoinStudyRequests: (type, accessCode, callback, journalUserId) => 
        dispatch(actions.handleJoinStudyRequests(type, accessCode, callback, journalUserId)),
    getJoinStudyStatus: (id, callBack, journalUserId) =>
        dispatch(actions.getJoinStudyStatus(id, callBack, journalUserId)),
    setJoinStudyData: (data) => dispatch(actions.setJoinStudyData(data)),
    // clearNotificationById: id => dispatch(actions.clearNotificationById(id)),
    // deleteNotifcation: (journalUserId, idList, callBack) =>
    //   dispatch(actions.deleteNotifcation(journalUserId, idList, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JoinStudyPopup);
