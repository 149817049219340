import React from 'react';
import {View, Text, TouchableOpacity, Platform} from 'react-native';
import styles from '../../../../Css';
import stylesResponsive from '../../../../CssResponsive';
import {
  CANCEL_EDIT_LIST,
  EDIT_LIST,
  listTypes,
} from '../../../../screens/PeerToPeer/MainSection/Profile/Constraints';
import ProfileCommonItemsEdit from './ProfileCommonItemsEdit';
import ProfileCommonItemView from './ProfileCommonItemView';
import ActivityIndicator from '../../../../components/ActivityIndicator/activityIndicator';
import {sortAlphabeticalOrder} from '../../../../util/commonUiLogic';
import { WEB } from '../../../../util/platformWindowConfig';

const MEDICAL_CONDITION_TITLE = 'Medical Conditions';
const SYMPTOMS_TITLE = 'Symptoms';
const TREATMENT_TITLE = 'Treatments';

const ProfileCommonItems = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {
    type,
    handleOnClickEditCancelItems,
    showList,
    handleOnClickSaveItems,
    listItemSaveWait,
    checkBoxTick,
    data,
    onPressItem,
    idKey,
  } = props;

  const sortedData =
    Boolean(data && data.length > 0) &&
    sortAlphabeticalOrder(
      data.filter(res => res.name !== null && res.name !== ''),
    );

    return (
    <View style={styles.comTopBorder}>
      <View
        style={[
          styles.customPaddingProfile,
          styles.flexBestweenStart,
          stylesRes.flexWrapCustom,
        ]}
        dataSet={{media: ids.flexWrapCustom}}>
        <View style={[styles.flexRowAliSt, Platform.OS !== WEB && styles.wdNinetyPerc]}>
          <View>
            <Text
              style={[
                styles.profileText,
                styles.marginZero,
                stylesRes.profileWidthText,
              ]}
              dataSet={{media: ids.profileWidthText}}>
              {type === listTypes.MEDICAL_CONDITIONS
                ? MEDICAL_CONDITION_TITLE
                : type === listTypes.SYMPTOMS
                ? SYMPTOMS_TITLE
                : TREATMENT_TITLE}
            </Text>
          </View>
          {Boolean(showList) ? (
            <ProfileCommonItemsEdit
              checkBoxTick={checkBoxTick}
              data={sortedData}
              type={type}
              showList={showList}
              onPressItem={onPressItem}
              idKey={idKey}
            />
          ) : (
            <ProfileCommonItemView
              commonItemList={sortedData}
              showList={showList}
              idKey={idKey}
            />
          )}
        </View>
         
        {Boolean(showList) ? (
          <View
            style={[styles.flexRowAiCt, styles.flexJcFe, stylesRes.bottomRes]}
            dataSet={{media: ids.bottomRes}}>
            <TouchableOpacity
              onPress={() =>
                handleOnClickEditCancelItems(type, CANCEL_EDIT_LIST)
              }
              style={[styles.cancelProfile, styles.flexJcCtAiCt]}>
              <Text style={styles.cancelText}>Cancel</Text>
            </TouchableOpacity>
            <TouchableOpacity
              testID={`saveListItems`}
              onPress={() => handleOnClickSaveItems(type)}
              style={[styles.saveProfile, styles.submitBgBlueDark]}>
              <View>
                {Boolean(listItemSaveWait) && (
                  <ActivityIndicator size="small" style={styles.loader} />
                )}
              </View>
              <View>
                <Text style={styles.saveTextProfile}>Save</Text>
              </View>
            </TouchableOpacity>
          </View>
        ) : (
          <View>
            <TouchableOpacity
                disabled={!data ? true : data?.length === 0}
                onPress={() => handleOnClickEditCancelItems(type, EDIT_LIST)}>
              <Text style={[styles.visiblityText, ...(!data || data?.length === 0 ? [{ opacity: 0.5}]: []) ]}>Edit</Text>
            </TouchableOpacity>
          </View>
        )}
</View>
    </View>
  );
};
export default ProfileCommonItems;
