import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import styles from "../../../Css";
import Symptom from "./Symptom";
import {chartTypes} from '../../../Constant/index';
import {sortAlphabeticalOrder} from '../../../util/commonUiLogic';

class Symptoms extends Component {
  render() {
    return (
      <View style={styles.collapseContent}>
        <View style={styles.collapseDescription}>
          <View style={styles.collapseInContent}>
            <View style={styles.secSubContentArea}>
              {sortAlphabeticalOrder(this.props.symptomsDigest.userSymptoms)
                .map(item => (
                  <Symptom
                    item={item}
                    key={"sy_" + item.id}
                    configChartType={item.displayProperties?.style || (item.displayProperties?.dashedLine === false ? chartTypes.dash : chartTypes.line)}
                    defaultMin={item.displayProperties?.scaleMin ? item.displayProperties.scaleMin : 0}
                    defaultMax={item.displayProperties?.scaleMax ? item.displayProperties.scaleMax : 10}
                    fullWidth={this.props.fullWidth}
                    publishedByPartner={this.props.publishedByPartner}
                    routineSymptoms={this.props.routineSymptoms}
                    colorPicked={this.props.colorPicked}
                    addNewUserSymptom={this.props.addNewUserSymptom}
                  />
              ))}
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    symptomsDigestProcessed: state.routines.symptomsDigestProcessed,
    symptomsDigest: state.routines.symptomsDigest
  };
};

export default connect(
  mapStateToProps,
  null
)(Symptoms);
