import React, { Component } from "react";
import { Image } from "react-native";
import styles from "../../Css";

import * as axiosCommon from "../../axios/axios-common";
import * as urls from "../../store/actions/urls";

class ImageFile extends Component {
  state = {
    uri: null,
    wait: true
  };

  componentDidMount() {
    const callBack = response => {
      if (response.type === "SUCCESS") {
        const token = response.responseDto.data.authToken;
        this.setState({
          uri: {
            uri:
              urls.BASE_URL +
              "/api/attachments/byid?attachmentId=" +
              this.props.id +
              "&auth=" +
              token,
            cache: "reload"
          }
        });
      }
    };

    axiosCommon.axiosGET(
      "/api/attachments/downloadtoken/byid?attachmentId=" + this.props.id,
      callBack
    );
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { id } = props;
    if (this.props.id !== id) {
      const callBack = response => {
        if (response.type === "SUCCESS") {
          const token = response.responseDto.data.authToken;
          this.setState({
            uri: {
              uri:
                urls.BASE_URL +
                "/api/attachments/byid?attachmentId=" +
                id +
                "&auth=" +
                token,
              cache: "reload"
            }
          });
        }
      };

      axiosCommon.axiosGET(
        "/api/attachments/downloadtoken/byid?attachmentId=" + id,
        callBack
      );
    }
  }

  imageLoadingError() {
    this.setState({ uri: require("../../assets/icons/report-default.png") });
  }

  render() {
    return (
      <Image
        style={styles.imgContain}
        source={this.state.uri}
        onError={this.imageLoadingError.bind(this)}
      />
    );
  }
}

export default (ImageFile);
