import React from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  ActivityIndicator,
} from 'react-native';
import styles from '../../Css';
import Header from '../Header/header';
import stylesResponsive from '../../CssResponsive';
import FileUpload from './fileUpload';
import SpaceBar from '../SpaceBar/SpaceBar';
import StorageBanner from '../../components/Banner/StorageBanner';
import PageContainer from "../../components/PageLayout/PageContainer";
import CancelAndSaveButtons from './CancelAndSaveButtons';
import FileRename from './fileRename';
import MenuOverlayContainer from '../../components/PageLayout/MenuOverlayContainer';
import DropZone from '../../screens/ImagesDocuments/Dropzone/DropZone';

const addImagesDocuments = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={!props.visibleImageTakePhoto}
        handleOnPressBackButton={props.handleOnPressBackButton}
        index={props.index}
        navigation={props.navigation}
        route={props.route}
        hideCaregiverAccess={props.visibleImageTakePhoto}
        showCropper={props.visibleImageTakePhoto}
        handleOnPressCropButton={props.handleOnPressCropButton}
      />
      <SpaceBar />
      {!Boolean(props.visibleImageTakePhoto) &&
        <MenuOverlayContainer
          onPressOpen={() => {
            props.addImagesDocumentsRef && props.addImagesDocumentsRef.handleCancelAddImageDocument();
          }}
        />
      }
      <StorageBanner />
      <ScrollView
        style={styles.scrollViewArea}
        keyboardShouldPersistTaps={'always'}
      >
        <View style={styles.gridSection}>
          <PageContainer smScreenFull={true}>
              <View style={stylesRes.contentSection}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.sortingFullCont]}>
                      <View
                          nativeID="noborder"
                          style={[styles.sortingListBlock, styles.borderThin, styles.uploadAttachmentThinBorder]}>
                        <View style={[styles.uploadSection, styles.sortingPd, styles.uploadAttachmentPadding]}>
                          <FileUpload
                            onChangeFileUpload={props.onChangeFileUpload}
                            uploadErrorMesage={props.uploadErrorMesage}
                            uploadError={props.uploadError}
                            visibleEntryModal={props.visibleEntryModal}
                            onRequestClose={props.onRequestClose}
                            onBackdropPress={props.onBackdropPress}
                            onPressClose={props.onPressClose}
                            selected={props.selected}
                            visibleImageTakePhoto={props.visibleImageTakePhoto}
                            onPressTakePhoto={props.onPressTakePhoto}
                            onRequestCloseImg={props.onRequestCloseImg}
                            onBackdropPressImg={props.onBackdropPressImg}
                            close={props.close}
                            cropSuccess={props.cropSuccess}
                            visibleUploadTypeModal={
                              props.visibleUploadTypeModal
                            }
                            onRequestCloseUploadTypeModal={
                              props.onRequestCloseUploadTypeModal
                            }
                            onBackdropPressUploadTypeModal={
                              props.onBackdropPressUploadTypeModal
                            }
                            onPressCancel={props.onPressCancel}
                            selectDocumentType={props.selectDocumentType}
                            onTakePhotoCancel={() => {
                              const { onTakePhotoCancel } = props;
                              if (onTakePhotoCancel) {
                                onTakePhotoCancel();
                              }
                            }}
                            setImageToCrop={props.setImageToCrop}
                            croppedUri={props.croppedUri}
                            tempCapturedImages={props.tempCapturedImages}
                            cancelCropping={props.cancelCropping}
                          />
                        </View>
                      </View>
                      {/* <ActivityIndicator /> */}

                      {/* <View style={styles.detailViewBlock}>
                        {Object.keys(props.files) &&
                          Object.keys(props.files).map(res => ( */}
                          {!Boolean(props.visibleImageTakePhoto) &&
                          // <View style={[styles.mainHeightContainer, {height: props.windowWidth > 992 ?  props.windowHeight - 200 : 'auto'}]}>
                          <View style={[styles.mainHeightContainer]}>

                            <View style={[styles.fileListHeightContainer, props.uploadError ? {maxHeight: 190} : {maxHeight: 240}]}>
                            {/* <View style={[styles.fileListHeightContainer, {maxHeight: props.windowHeight - 590}]}> */}
                             <ScrollView>
                              <FileRename
                                    images={props.images}
                                    files={props.files}
                                    handleDeleteAttachment={props.handleDeleteAttachment}
                                    onFileRename={props.onFileRename}
                                    disabled={props.showSpinner}
                                  />
                             </ScrollView>
                            </View>
                            <View style={stylesRes.buttonAndDragSection} dataSet={{media: ids.buttonAndDragSection}}>
                              <View style={[styles.btnSingle, styles.flexAiFs, styles.colPdLtRt]}>

                                {!Boolean(props.isFileReading) && <TouchableOpacity
                                  onPress={props.onAddPressedForUpload}
                                  style={[
                                    styles.btnActionCom,
                                    styles.bgWhite,
                                    styles.btnBorderThin,
                                    styles.btnBorderLight,
                                  ]}
                                  disabled={props.showSpinner}
                                >
                                    <View style={[styles.flexRow, styles.flexJcCtAiCt]}>
                                      <View style={styles.fileUploadBtnFixed}>
                                        <View style={styles.fileUploadBtnIcon}>
                                          <Image
                                            style={styles.plusExSm}
                                            source={require("../../assets/icons/plus-sm.png")}
                                            resizeMode={"contain"}
                                          />
                                        </View>
                                        <Text style={[styles.textPrimeBold, styles.textOrange]} allowFontScaling={false}>Add</Text>
                                      </View>
                                    </View>
                                </TouchableOpacity>}

                                {Boolean(props.isFileReading) && <View>
                                  <ActivityIndicator size="large" color="#FF8300" />
                                </View>}

                              </View>
                              <View style={[styles.dropZoneWrapper]}>
                                {props.onDragAndDropFileSelect &&
                                <View style={stylesRes.dropZoneInnerWrapper} dataSet={{media: ids.dropZoneInnerWrapper}}>
                                  <DropZone onGetFiles={props.onDragAndDropFileSelect}/>
                                </View>}
                              </View>
                            </View>
                          </View>
                        }
                        {!Boolean(props.visibleImageTakePhoto) &&
                          <View style={stylesRes.saveCancelButtonBlockWrapper} dataSet={{media: ids.saveCancelButtonBlockWrapper}}>
                            <View style={[stylesRes.btnViewSec, stylesRes.btnViewSecNew]}>
                              <CancelAndSaveButtons
                                handleCreateNewRoutine={props.handleCreateNewRoutine}
                                redirectAttachImagesDocumentsToJournal={props.redirectAttachImagesDocumentsToJournal}
                                onSaveFilesToAttach={props.onSaveFilesToAttach}
                                redirectAttachImagesDocumentsToContact={props.redirectAttachImagesDocumentsToContact}
                                redirectAttachImagesDocumentsToTreatment={props.redirectAttachImagesDocumentsToTreatment}
                                onAddImageDocumentCancelPress={props.onAddImageDocumentCancelPress}
                                files={props.files}
                                disabled={props.showSpinner}
                              />
                            </View>
                          </View>
                          }
                    </View>
                  </View>
                </View>
              </View>
            </PageContainer>
          </View>
        </ScrollView>
      </View>
  );
};

export default (addImagesDocuments);
