import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import styles from "../../../../Css";
import stylesResponsive from "../../../../CssResponsive";
import { openLink } from '../../../../services/LinkingService'
class ClinicalTrialMyListElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myClinicalTrial: props.myClinicalTrial,
      isSelected:
        props.clinicalTrials && props.clinicalTrials[props.myClinicalTrial.id]
          ? true
          : false
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      myClinicalTrial: props.myClinicalTrial,
      isSelected:
        props.clinicalTrials && props.clinicalTrials[props.myClinicalTrial.id]
          ? true
          : false
    });
  }

  handleOnPressRadioButton = id => {
    this.setState(
      prevState => ({
        isSelected: !prevState.isSelected
      }),
      () => {
        if (this.state.isSelected) {
          this.props.onAddClinicalTrialToDelete(id);
        } else {
          this.props.onRemoveClinicalTrialFromDelete(id);
        }
      }
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.isSelected !== this.state.isSelected;
  }
  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    return (
      <View
        key={this.state.myClinicalTrial.id}
        style={[styles.viewRowWrapper, styles.greyBorderThin]}
      >
        <View style={styles.viewRowTitleBlock}>
          <View style={stylesRes.viewRowTitle}>
            <Text
              style={[styles.textPrimeSm, styles.textEm, styles.textColorGreen]}
              allowFontScaling={false}
            >
              {this.state.myClinicalTrial.overallStatus}
            </Text>
          </View>
        </View>
        <View style={[styles.ViewRow]}>
          <View style={styles.viewDetailSec}>
            <View style={styles.viewMainData}>
              <View
                style={[
                  styles.viewItemDesc,
                  styles.flexJcFsAiFs,
                  styles.colPdLtBotRgTopSm
                ]}
              >
                <View style={[styles.viewImgSec, styles.flexAiCt]}>
                  <View style={[styles.mapIconSec]}>
                    <Image
                      source={require("../../../../assets/icons/map-mark.png")}
                      style={styles.mapIconSm}
                      resizeMode={"contain"}
                    />
                  </View>
                </View>

                <View style={[styles.viewDataFull]}>
                  <View style={[styles.viewItemData]}>
                    <TouchableOpacity
                      onPress={() => openLink(this.state.myClinicalTrial.url)}
                      disabled={!this.state.myClinicalTrial.url}
                    >
                      <Text style={[styles.textPrimeSmBold, styles.textUnderline]} allowFontScaling={false}>
                        {this.state.myClinicalTrial.briefTitle}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View style={[styles.viewItemDataSub, styles.pdTopExSm]}>
                    <Text style={styles.textPrimeSm} allowFontScaling={false}>
                      {this.state.myClinicalTrial.conditionList
                        ? this.state.myClinicalTrial.conditionList.map(
                            (condition, index) => condition + "\n"
                          )
                        : ""}
                    </Text>
                  </View>
                  <View style={[styles.locDetails, styles.pdTopExSm]}>
                    <Text style={[styles.textExMicSm]} allowFontScaling={false}>
                    {this.state.myClinicalTrial.locationList &&
                      this.state.myClinicalTrial.locationList.length !== 0
                        ? this.state.myClinicalTrial.locationList.map(
                            (location, index) => location + ","
                          )
                        : ""}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.dataIconSecMd, styles.colPdRtBotRgTopSm]}>
            <View style={styles.iconListSec}>
              <TouchableOpacity
                style={[styles.selectSecIcon, styles.iconListMgSmLt]}
                onPress={() =>
                  this.handleOnPressRadioButton(this.state.myClinicalTrial.id)
                }
              >
                {this.state.isSelected ? (
                  <Image
                    style={styles.radioIcon}
                    source={require("../../../../assets/icons/radio-selected.png")}
                  />
                ) : (
                  <Image
                    style={styles.radioIcon}
                    source={require("../../../../assets/icons/radio-default.png")}
                  />
                )}
              </TouchableOpacity>

              {/* <TouchableOpacity
                        style={[styles.selectSecIcon, styles.iconListMgSmLt]}
                        >
                            <Image
                                style={styles.radioIcon}
                                source={require("../../../../assets/icons/radio-default.png")}
                            />
                        </TouchableOpacity> */}
            </View>
          </View>
        </View>
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    clinicalTrials: state.clinicalTrials.clinicalTrials
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddClinicalTrialToDelete: id =>
      dispatch(actions.addClinicalTrialToDelete(id)),
    onRemoveClinicalTrialFromDelete: id =>
      dispatch(actions.removeClinicalTrialFromDelete(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClinicalTrialMyListElement);
