export const vhMinHeight = {
  minHeight: "100vh"
};
export const vhHeight = {
  height: "100vh"
};
export const vhMaxHeightNotify = {
  maxHeight: "85vh"
};
export const vhMinHeightLoader = {
  minHeight: "80vh"
};
export const vhMinHeightScan = {
  minHeight: "75vh"
};
export const vhMaxHeightScroll = {
  maxHeight: "55vh"
};
