import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
import _ from "underscore";

const initialState = {
  searchResults: {},
  searchTearms: null,
  clinicalTrials: {}, //handle delete clinical trials from mylist
  newClinicalTrials: {} //handle add clinical trials to mylist
};

const searchClinicalTrialSuccess = (state, action) => {
  const searchResults = { ...state.searchResults };
  if (_.isEmpty(searchResults)) {
    return updateObject(state, {
      searchResults: action.searchResults
    });
  } else {

    if (action.searchResults && action.searchResults.study) {
      action.searchResults.study.forEach(item => {
        searchResults.study.push(item);
      });
      return updateObject(state, {
        searchResults: searchResults
      });
    }

  }
};
const searchTearms = (state, action) => {
  return updateObject(state, {
    searchTearms: action.searchTearms
  });
};
const addClinicalTrialToDelete = (state, action) => {
  const clinicalTrials = { ...state.clinicalTrials };
  clinicalTrials[action.clinicalTrialId] = action.clinicalTrialId;
  return updateObject(state, { clinicalTrials: clinicalTrials });
};

const removeClinicalTrialFromDelete = (state, action) => {
  const clinicalTrials = { ...state.clinicalTrials };
  delete clinicalTrials[action.clinicalTrialId];
  return updateObject(state, { clinicalTrials: clinicalTrials });
};

const resetClinicalTrials = (state, action) => {
  return updateObject(state, {
    clinicalTrials: null
  });
};

const resetSearchResults = (state, action) => {
  return updateObject(state, {
    searchResults: {}
  });
};
const addClinicalTrialToMyList = (state, action) => {
  const newClinicalTrials = { ...state.newClinicalTrials };
  newClinicalTrials[action.newClinicalTrialId] = action.newClinicalTrial;
  return updateObject(state, { newClinicalTrials: newClinicalTrials });
};

const removeClinicalTrialFromMyList = (state, action) => {
  const newClinicalTrials = { ...state.newClinicalTrials };
  delete newClinicalTrials[action.newClinicalTrialId];
  return updateObject(state, { newClinicalTrials: newClinicalTrials });
};

const resetNewClinicalTrials = (state, action) => {
  return updateObject(state, {
    newClinicalTrials: null
  });
};

const deleteFromNewClinicalTrialList = (state, action) => {
  const newClinicalTrials = { ...state.newClinicalTrials };
  action.clinicalTrialIdList.forEach(id => {
    delete newClinicalTrials[
      Object.keys(newClinicalTrials).find(key => 
        newClinicalTrials[key] === id
      )
    ];
  });
  return updateObject(state, { newClinicalTrials: newClinicalTrials });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_CLINICAL_TRIAL_SUCCESS:
      return searchClinicalTrialSuccess(state, action);
    case actionTypes.SEARCH_TEARMS:
      return searchTearms(state, action);
    case actionTypes.ADD_CLINICAL_TRIAL_TO_DELETE:
      return addClinicalTrialToDelete(state, action);
    case actionTypes.REMOVE_CLINICAL_TRIAL_FROM_DELETE:
      return removeClinicalTrialFromDelete(state, action);
    case actionTypes.RESET_CLINICAL_TRIALS:
      return resetClinicalTrials(state, action);

    case actionTypes.ADD_CLINICAL_TRIAL_TO_MY_LIST:
      return addClinicalTrialToMyList(state, action);
    case actionTypes.REMOVE_CLINICAL_TRIAL_FROM_MY_LIST:
      return removeClinicalTrialFromMyList(state, action);
    case actionTypes.RESET_NEW_CLINICAL_TRIALS:
      return resetNewClinicalTrials(state, action);
    case actionTypes.RESET_CLINICAL_TRIAL_STATE:
      return initialState;

    case actionTypes.RESET_CLINICAL_TRIAL_SEARCH_RESULTS:
      return resetSearchResults(state, action);
    case actionTypes.DELETE_FROM_NEW_CLINICAL_TRIAL_LIST:
      return deleteFromNewClinicalTrialList(state, action)
    default:
      return state;
  }
};
export default reducer;
