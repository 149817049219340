import { DEEP_LINK_URL } from "../store/actions/urls";

export const AUTH_CONFIGS_WEB = {
    "clientId": "4xvGoHRnyMjjYPPQroaCrNmHGaz20K5f",
}

export const AUTH_CONFIGS = {
    "domain": "hexoskin.us.auth0.com",
    "audience": "hms",
    "appOrigin": `${DEEP_LINK_URL}/manage-devices/HEXOSKIN`
}
