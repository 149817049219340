import theme from "./base/theme";
import * as variableShadow from "./base/variableShadow";

export const cssuploadAttachment = {

  fileUploadInputWrapper: {
    width: '100%',
  },

  newUploadBtnText: {
    paddingTop: 8,
    justifyContent: "center",
    marginTop: 2,
  },

  newPopUpBlock: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,

  },

  newUploadAreaRow: {
    justifyContent: 'center',
  },

  closeIconWrapper: {
    alignItems: 'flex-end',
    width: '100%',
    marginRight: 15,
  },

  newTouchSpaceHeader: {
    alignItems: 'flex-end',
    justifyContent: 'center',

  },

  uploadAttachmentpopUpContentPadding: {
    paddingTop: 0,
    paddingBottom: 50,
  },

  dragNdropInnerWrapper: {
    alignItems:'center',
    //maxWidth: 808,
    width: '100%',
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 8,
    borderStyle:  'dashed',
    borderColor: '#98989A',
    paddingTop: 25,
    paddingBottom: 20
  },

  dragNdropIcon: {
    width: 72,
    height: 68,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 35,
  },

  newUploadPopUpBlock: {
    backgroundColor: '#fff',
    marginBottom: 'unset',
    marginLeft:'unset',
    marginRight:'unset',
    marginTop:'unset',
    width:'100%',
  },

  camModalWrapper: {
    width: '100%',
  },

  uploadAttachmentPadding: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },

  uploadAttachmentCamIcon: {
    width: 56,
    height: 56,

  },

  uploadAttachmentButtonWrapper: {
    position: 'absolute',
  },

  uploadAttachmentWhiteBlock: {
    backgroundColor: '#fff',
  },

  uploadAttachmentThinBorder: {
    borderBottomWidth: 0,
  },

  cropperGreenBtn: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#33ADB9",
    width: 113,
    height: 40,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },

  prefixPopupBtn: {
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    width: 140,
    height: 37,
  },

  prefixPopupBtnRight: {
    backgroundColor: theme.COLOR_BLUE,
  },
  
  prefixPopupInput: {
    borderColor: 'rgba(152,152,154,0.6)',
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
    paddingBottom: 12,
    height: 48,
    width: 232,

  },

  prefixPopupBtnWrapper: {
    flexDirection: 'row',
  },

  prefixPopupInputWrapper: {
    marginTop: 37,
    marginBottom: 37,
  },

  attachmentCancelBtn: {
    marginRight: 8,
  },

  commonBtnStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    width: 140,
    height: 41,
  },

  uploadAttachmentcrossIcon: {
    width: 18,
    height: 16,
  },

  uploadAttachmentBtnMax: {
    flexBasis: 18,
    height: '100%',
    alignItems: 'center',
  },

  editableBoder: {
    borderColor: '#0099A8',
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 8,
    paddingBottom: 8,
    width: 250,
  },

  uploadattachmentThumbImg: {
    width: 40,
    height: 30,
  },

  attachmentThumbTextWrapper: {
    alignItems: 'center',
  },

  fileListHeightContainer: {
    marginTop: 20,
    marginBottom: 20,
    //overflow: 'scroll',
  },

  countWrapper: {
    alignSelf: 'center',
  },

  attachmentPreviewIcon: {
      width : 20,
      height: 24,
      resizeMode: 'contain',
  },

  dropZoneWrapper: {
    flex: 1,
    width: '100%',
  },

  dragNdropSectionOuterWrapper: {
    alignItems: 'center',
    justifyContent: 'center',

  },

  attachmentImgViewNew: {
    alignSelf: "center"
  },

  uploadAttachmentImgDetMax: {
    flexBasis: 25,
  },

  dashboardPopupUpload: {
    maxWidth: 280,
  },

  uploadHeaderContent: {
    alignItems:'center', 
    height: 47,
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    paddingLeft: 16, 
    paddingRight: 24
  },

  uploadHeaderRightDetails: {
    alignItems:'center',
    flexDirection:'row', 
  },

  uploadBottomContent: {
    alignItems:'center', 
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    paddingLeft: 16,
    paddingRight: 24,
    paddingTop: 15,
    paddingBottom: 15
  },

  uploadingBottomIcon: {
    paddingRight: 10
  },

  imagenameSm: {
    maxWidth:78,
    flexWrap: 'nowrap'
  },

  dashboardPgContainer: {
    backgroundColor:theme.COLOR_WHITE, 
    borderRadius: 5,
    ...variableShadow.shadowStyleEleven
  },

  uploadingOpacity: {
    color:theme.COLOR_GREY_3
  }
};
