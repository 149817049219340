import React from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import styles from "../../../../Css";

const premiumBtnBlock = (props) => {
  return (
    <View
      style={[
        styles.listPremiumBtnSec,
        styles.colPdTopBotRg,
        styles.flexJcCtAiCt,
      ]}
    >
      <TouchableOpacity
        onPressIn={props.onPressPermium}
        style={[styles.premiumBtnView]}
      >
        <View style={[styles.btnSingle, styles.btnSingleCent]}>
          <View style={styles.smRtMg}>
            <Image
              style={[styles.premiumIconSm]}
              source={require("../../../../assets/icons/premium-main.png")}
            />
          </View>
          <Text style={[styles.textPrimeSmBold, styles.textOrange]} allowFontScaling={false}>Premium</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default premiumBtnBlock;
