import React, { Component } from "react";
import { View, ScrollView, Text, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import UIText from "../Text/text";
import UITextInput from "../TextInput/textInput";
import ActivityIndicator from "../ActivityIndicator/activityIndicator";

class LoginUserConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationCode: "",
      verificationCodeValid: true,
      wait: false
    };
  }

  onChangeverificationCode = value => {
    if (value) {
      this.setState({ verificationCodeValid: true });
    } else {
      this.setState({ verificationCodeValid: false });
    }
    this.setState({ verificationCode: value });
  };

  onPressLogin = () => {
    if (this.state.verificationCodeValid) {
      this.setState({ wait: true }, () => {
        this.props.conformationVerification(
          this.state.verificationCode,
          message => {
            this.setState({
              wait: false,
              error: true,
              errorDescription: message
            });
          }
        );
      });
    }
  };

  render() {
    const stylesRes = stylesResponsive.getProcessedStyles().styles;
    const ids = stylesResponsive.getProcessedStyles().ids;
    return (
      <ScrollView 
        style={[stylesRes.containerFull, stylesRes.midSec]}
        dataSet={{media: ids.containerFull}}>
        <View 
          style={stylesRes.containerCommon} 
          dataSet={{media: ids.containerCommon}}>
          <View 
            style={stylesRes.containerCommonMax}
            dataSet={{media: ids.containerCommonMax}}>
            <View 
              style={stylesRes.gridContent}
              dataSet={{media: ids.gridContent}}>
              <View 
                style={stylesRes.comContentSection}
                dataSet={{media: ids.comContentSection}}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.titleExPd}>
                      <Text
                        style={[styles.textPrimeBold, styles.textAlignCenter]}
                        allowFontScaling={false}
                      >
                        Login User Confirmation
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.topImgSecCom}>
                      <View style={stylesRes.topImgLgCom}>
                        <Image
                          style={styles.passIconLgCom}
                          source={require("../../assets/reset-password/forgot-pass.png")}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View 
                style={stylesRes.comContentSection}
                dataSet={{media: ids.comContentSection}}>
                <View style={styles.detailBlock}>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={[styles.fieldRow]}>
                        <Text style={[styles.textView]} allowFontScaling={false}>
                          <UIText
                            style={[
                              styles.textLabel,
                              styles.bold,
                              styles.textColorLight
                            ]}
                            title="Enter Verification sent to email"
                          />
                          <UIText 
                          style={styles.textReq} 
                          title="*" 
                          />
                        </Text>

                        <UITextInput
                          style={[
                            styles.textField,
                            this.state.verificationCodeValid
                              ? styles.validColor
                              : styles.inValidColor
                          ]}
                          onChangeText={this.onChangeverificationCode}
                          value={
                            this.state.verificationCode
                              ? this.state.verificationCode
                              : undefined
                          }
                        />
                      </View>
                    </View>
                  </View>

                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      {Boolean(this.state.error) && (
                        <View
                          style={[styles.notifyBlock, styles.subTextBlockMg]}
                        >
                          <View
                            style={[styles.notifyDetails, styles.notifyNoPd]}
                          >
                            <View style={styles.notifyWarning}>
                              <Image
                                style={styles.iconWarning}
                                source={require("../../assets/icons/warning.png")}
                              />
                            </View>

                            <UIText
                              style={[styles.notifyText]}
                              title={this.state.errorDescription}
                            />
                          </View>
                        </View>
                      )}
                    </View>
                  </View>

                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <TouchableOpacity
                        onPress={() => {
                          this.setState({ verificationCode: "" }, () => {
                            this.props.resendCode(message => {
                              this.setState({
                                error: true,
                                errorDescription: message
                              });
                            });
                          })

                        }}
                      >
                        <View
                          style={[styles.validateMessage, styles.validatePd]}
                        >
                          <Text
                            style={[
                              styles.textPrimeMd,
                              styles.textErr,
                              styles.textAlignCenter
                            ]}
                            allowFontScaling={false}
                          >
                            Resend Code
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>

                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={styles.btnBlock}>
                        <TouchableOpacity
                          style={[styles.submitMd]}
                          underlayColor="rgba(0, 153, 168, 0.8)"
                          onPress={this.onPressLogin}
                        >
                          <View style={styles.btnSingleView}>
                            <View
                              style={[styles.btnSingle, styles.btnSingleCent]}
                            >
                              {this.state.wait && (
                                <View style={styles.actIndLogin}>
                                  <ActivityIndicator size="small" />
                                </View>
                              )}
                              <UIText 
                              style={styles.submitText} 
                              title="Login" 
                              />
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }
}

export default (LoginUserConfirmation);
