import React from "react";
import { View, TouchableOpacity, Image } from "react-native";
import styles from "../../../Css";

const croperSection = (props) => {
  const { handleOnPressCropButton } = props;
  return (
    <View style={styles.cropTouchWrapper}>
      <TouchableOpacity onPress={handleOnPressCropButton}>
        <View style={[styles.cropTouch]} nativeID={'headerCroper'}>
          <Image
            style={styles.cropIcon}
            source={require("../../../assets/ocr-icons/crop.png")}
          />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (croperSection);
