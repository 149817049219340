import React, {Component} from 'react';
import {View, Text} from 'react-native';
import styles from '../../../../Css';
import {connect} from 'react-redux';
import moment from 'moment';
import DateOfBirthFrame from '../../../DateOfBirth/DateOfBirth';
import CountryFrame from '../../../Country/CountryFrame';
import {AddCondition, ConditionList} from '../../../Setup/Profile';
import * as actions from '../../../../store/actions/index';
import SmallTitle from '../../../Text/smallTitle';
import MessageBox from '../../../Text/messageBox';
import {ProfileTabFooter} from '../SetupFooter';
import BiologicalSex from '../../../BiologicalSex';
import SetupCancelConfirmationBox from '../setupCancelConfirmationBox';
import SuccessBox from '../../../ConfirmationBox/successBox';
class ProfileTabContent extends Component {
  constructor(props) {
    super(props);
    const {user, conditions, selectedUser} = props;
    const {country = 'Canada', birthday, sex = 'MALE'} = user;
    this.country = country ? country : 'Canada';
    this.birthday = birthday
      ? moment.utc(new Date(birthday)).format('YYYY-MM-DD')
      : '';
    this.sex = sex ? sex : 'MALE';
    this.state = {
      //validateBirthday: true,
      country: selectedUser?.country ? selectedUser?.country : country ? country : '',
      //validateCountry: false,
      birthday: selectedUser?.birthday ? selectedUser?.birthday : birthday
        ? moment.utc(new Date(birthday)).format('YYYY-MM-DD')
        : '',
      sex: selectedUser?.sex ? selectedUser?.sex : sex ? sex : 'MALE',
      saveWait: false,
      nextWait: false,
      invalidDay: false,
      invalidMonth: false,
      invalidYear: false,
      autocompleteOpen: false,
      visibleCloseConfirmationModal: false,
      inActive:
        !birthday ||
        !country ||
        !conditions ||
        (conditions && conditions.length === 0),
      conditionCount: 0,
      showSuccessPopupCondition: false,
      selectedDay: selectedUser?.selectedDay || '-1',
      selectedMonth: selectedUser?.selectedMonth || '-1',
      selectedYear: selectedUser?.selectedYear || '-1',
    };
  }

  handleOnDatePress = birthday => {
    if (birthday) {
      // this.setState({validateBirthday: true});
    } else {
      // this.setState({validateBirthday: false});
    }
    this.setState({
      birthday,
      inActive:
        !birthday ||
        !this.state.country ||
        !this.props.conditions ||
        (this.props.conditions && this.props.conditions.length === 0),
    }, () => {
      this.updateUserDetails();
    });
  };
  onChangeCountry = value => {
    this.setState({
      country: value,
      validateCountry: true,
      inActive:
        !this.state.birthday ||
        !value ||
        !this.props.conditions ||
        (this.props.conditions && this.props.conditions.length === 0),
    }, () => {
      this.updateUserDetails();
    });
  };

  onSelectBiologicalSex = val => {
    this.setState({sex: val}, () => {
      this.updateUserDetails();
    });
  };

  updateUserDetails = () => {
    const addNewUser = {
      user: {
        country: this.state.country,
        birthday: this.state.birthday,
        sex: this.state.sex,
        selectedDay: this.state.selectedDay,
        selectedMonth: this.state.selectedMonth,
        selectedYear: this.state.selectedYear,
      },
    };
    this.props.updateUser(addNewUser, () => {});
  }

  update = (callBack, from) => {
    if (this.validateMedicalInfo()) {
      const {
        user,
        updateProfile,
        conditions,
        updateUserCondition,
        updateCondition,
      } = this.props;
      const {country, birthday, sex} = this.state;
      let editProfile = {
        ...user,
        country: country,
        birthday: new Date(birthday).getTime(),
        sex: sex,
      };
      if (from === 'SAC') {
        this.setState({saveWait: true});
      } else {
        this.setState({nextWait: true});
      }
      updateProfile(
        editProfile,
        () => {
          if (conditions && conditions.length > 0) {
            const updateConditionList = [];
            conditions.forEach((res, index) => {
              updateUserCondition(
                res,
                (success, data) => {
                  if (success) {
                    data.condition = res.condition;
                    data.stages = res.stages;
                    updateConditionList.push(data);
                  } else {
                    updateConditionList.push(res);
                  }
                  if (index === conditions.length - 1) {
                    updateCondition(updateConditionList, () => {
                      if (from === 'SAC') {
                        this.setState({saveWait: false});
                      } else {
                        this.setState({nextWait: false});
                      }
                      //this.showConditionSuccessModal(() => callBack());
                      callBack();
                    });
                  }
                },
                this.props.journalUserId,
              );
            });
          } else {
            if (from === 'SAC') {
              this.setState({saveWait: false});
            } else {
              this.setState({nextWait: false});
            }
            callBack();
          }
        },
        this.props.journalUserId,
      );
    }
  };

  validateMedicalInfo = () => {
    const {day, month, year} = this.state;
    let valid = true;

    if (day) {
      valid = false;
      this.setState({invalidDay: true});
    }
    if (month) {
      valid = false;
      this.setState({invalidMonth: true});
    }
    if (year) {
      valid = false;
      this.setState({invalidYear: true});
    }

    if (!this.state.sex) {
      this.setState({validateSex: false});
      valid = false;
    } else {
      this.setState({validateSex: true});
    }
    // if (!this.state.country || this.state.country === '') {
    //   this.setState({validateCountry: false});
    //   valid = false;
    // } else {
    //   this.setState({validateCountry: true});
    // }
    return valid;
  };

  cancel = () => {
    const {updateCondition, closeModal, conditions = []} = this.props;
    const conditionsfiltered =
      conditions && conditions.filter(res => res.id !== -1);
    updateCondition(conditionsfiltered, () => {
      closeModal();
      this.hideCloseConfirmationModal();
    });
  };

  updateAutocompleteOpen = val => {
    this.setState({autocompleteOpen: val});
  };

  hideCloseConfirmationModal = () => {
    this.setState({visibleCloseConfirmationModal: false});
  };
  updateConditions = conditions => {
    this.setState({
      inActive:
        !this.state.birthday ||
        !this.state.country ||
        !conditions ||
        (conditions && conditions.length === 0),
      conditionCount: conditions && conditions.length ? conditions.length : 0,
    });
  };
  showConditionSuccessModal = () => {
    if (this.props.showMappedDataPopup) {
      this.setState({
        showSuccessPopupCondition: true,
      }, () => {
        this.props.onSetShowMappedDataPopupNextTime(false);
        setTimeout(() => {
          this.setState({showSuccessPopupCondition: false});
        }, 2500);
      });
    }
  };

  checkInvalidDate = (day, month, year) => {
    if (
      (day !== '-1' && month !== '-1' && year !== '-1') ||
      (day === '-1' && month === '-1' && year === '-1')
    ) {
      this.setState({onlyYear: false});
    }
    if (day === '-1' && month === '-1' && year === '-1') {
      this.setState({
        day: false,
        month: false,
        year: false,
        invalidDay: false,
        invalidMonth: false,
        invalidYear: false,
        selectedDay: '-1',
        selectedMonth: '-1',
        selectedYear: '-1',
      });
    } else {
      this.setState({
        day: day === '-1' ? true : false,
        month: month === '-1' ? true : false,
        year: year === '-1' ? true : false,
        selectedDay: day,
        selectedMonth: month,
        selectedYear: year,
      });
    }
  };

  render() {
    const {
      birthday,
      //validateBirthday,
      country,
      sex,
      saveWait,
      nextWait,
      autocompleteOpen,
      visibleCloseConfirmationModal,
      invalidDay,
      invalidMonth,
      invalidYear,
      // validateCountry,
      selectedDay,
      selectedMonth,
      selectedYear,
    } = this.state;
    const {tabs, setActiveTab} = this.props;

    return (
      <>
        <View style={[styles.sectionContainer, styles.sectionContainerzIndex]}>
          <View style={[styles.secMainTitle, styles.comMgBot, styles.secRtPd]}>
            <Text style={[styles.textTitleExLgBold, styles.textThickGrey]} allowFontScaling={false}>
              Setup your profile and add your medical condition(s)
            </Text>
          </View>

          <MessageBox
            message={
              <Text allowFontScaling={false}>
                You can always add more information through your{' '}
                <Text style={styles.bold} allowFontScaling={false}>Profile</Text> after the setup process
              </Text>
            }
          />

          <View style={styles.sectionView}>
            <SmallTitle title={"What's your birthday?"} />
            <DateOfBirthFrame
              onDayChange={this.handleOnDatePress}
              dob={birthday}
              //invalidBirthday={!validateBirthday}
              invalidDay={invalidDay}
              invalidMonth={invalidMonth}
              invalidYear={invalidYear}
              invalidDate={this.checkInvalidDate}
              notRequiredField={true}
              fromSetup={true}
              selectedDay={selectedDay}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
            />
          </View>

          <View style={styles.sectionView}>
            <BiologicalSex sex={sex} onSelect={this.onSelectBiologicalSex} />
          </View>

          <View style={styles.sectionView}>
            <CountryFrame
              country={country}
              onChangeCountry={this.onChangeCountry}
              //invalid={!validateCountry}
            />
          </View>

          <View
            style={[
              styles.secMainTitle,
              styles.comMgBotSm,
              styles.comMgTopExSm,
            ]}>
            <Text style={[styles.textTitleBold]} allowFontScaling={false}>
              Which medical condition(s) apply to you?
            </Text>
          </View>

          <AddCondition
            updateAutocompleteOpen={this.updateAutocompleteOpen}
            updateInActive={val => {
              this.setState({
                inActive: val || !this.state.birthday || !this.state.country,
              });
            }}
            conditionCount={this.state.conditionCount}
            addedMappedData={this.showConditionSuccessModal}
            autoFocus={false}
          />
          
          <ConditionList updateConditions={this.updateConditions} />
          {Boolean(autocompleteOpen) && (
            <View style={[styles.zIndexMin, styles.autoSuggestSp]} />
          )}
        </View>
        <ProfileTabFooter
          next={() => {
            this.update(() => setActiveTab(tabs.medications), 'NEXT');
          }}
          saveWait={saveWait}
          nextWait={nextWait}
        />
        <SetupCancelConfirmationBox
          visibleModal={visibleCloseConfirmationModal}
          cancel={this.hideCloseConfirmationModal}
          confirm={this.cancel}
          message="Cancel setting up your profile?"
        />
        <SuccessBox
          visibleModal={this.state.showSuccessPopupCondition}
          messageTitle="Success"
          messageTitleStyle={styles.bold}
          message="Health data and symptoms related to your medical condition have been added to your routine"
          backdropStyle={true}
          animationNone={true}
          roundedCorners={true}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.routines.user,
    conditions: state.setup.conditions,
    journalUserId: state.uistate.journalUserId,
    showMappedDataPopup: state.uistate.showMappedDataPopup,
    selectedUser: state.setup.selectedUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProfile: (editProfile, callBack, journalUserId) =>
      dispatch(actions.updateProfile(editProfile, callBack, journalUserId)),
    updateCondition: (addNewCondition, callBack) =>
      dispatch(actions.updateCondition(addNewCondition, callBack)),
    updateUserCondition: (condition, callBack, journalUserId) =>
      dispatch(actions.updateUserCondition(condition, callBack, journalUserId)),
    onSetShowMappedDataPopupNextTime: (isShow) => dispatch(actions.setShowMappedDataPopupNextTime(isShow)),
    updateUser: (addNewUser, callBack) =>
      dispatch(actions.updateUser(addNewUser, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileTabContent);