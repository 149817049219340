import {
    DISABLE_FILTER_COMPONENTS,
    SET_ADVANCED_FILTER_ITEMS,
    CLEAR_ADVANCED_FILTER_ITEMS,
    SET_SORT_TYPE,
    SET_INNITIAL_DATE_RANGE,
    SET_SELECTED_BY_DATE_OPTION,
    SET_SELECTED_BY_DATE_OBJECT,
    CLEAR_SELECTED_BY_DATE_OBJECT,
    SET_SELECTED_BY_CATEGORY_OBJECT,
    CLEAR_SELECTED_BY_CATEGORY_OBJECT,
    SET_SELECTED_TAGS,
    CLEAR_SELECTED_TAGS,
    CLEAR_SELECTED_TAGS_COUNT,
    ADD_SET_SELECTED_TAGS_COUNT,
    REDUCE_SET_SELECTED_TAGS_COUNT,
    ENABLE_APPLY_BUTTON,
    DISABLE_APPLY_BUTTON,
    ENABLE_REMOVE_BUTTON,
    DISABLE_REMOVE_BUTTON,
    HEALTH_JOURNEY_SELECTED_CATEGORY_LIST,
    ENABLE_CATEGORY_APPLY_BUTTON,
    ENABLE_CATEGORY_REMOVE_BUTTON,
    DISABLE_CATEGORY_APPLY_BUTTON,
    DISABLE_CATEGORY_REMOVE_BUTTON,
    SET_SELECTED_DROPDOWN_NUMBER,
    REMOVE_SELECTED_DROPDOWN_NUMBER,
    RESET_FILTERING_DATA
} from "../actions/actionTypes";

const initialState = {
    sortType: 1,
    resetComponents: false,
    eventsDateRange: {},
    setSelectedAdvancedFilterItems: [],
    selectedTags: [],
    setSelectDateOption: null,
    setSelectedDateObject: {},
    setSelectedCategoryObject: {},
    tagsCount: 0,
    isApplyButtonDisabled: true,
    isRemoveButtonDisabled: true,
    isCategoryApplyButtonDisabled: true,
    isCategoryRemoveButtonDisabled: true,
    healthJourneyCategoryList: [],
    selectedDropdownItemNumber : [],
};

const healthJourneyFilteringReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SORT_TYPE:
            return {
                ...state,
                sortType: action.payload,
            }
        case DISABLE_FILTER_COMPONENTS:
            return {
                ...state,
                resetComponents: action.payload,
            }
        case SET_INNITIAL_DATE_RANGE:
            return {
                ...state,
                eventsDateRange: action.payload,
            }
        case SET_SELECTED_BY_DATE_OPTION: 
            return {
                ...state,
                setSelectDateOption: action.payload,
            }
        case SET_SELECTED_BY_DATE_OBJECT: 
            return {
                ...state,
                setSelectedDateObject: action.payload,
            }
        case CLEAR_SELECTED_BY_DATE_OBJECT: 
            return {
                ...state,
                setSelectedDateObject: {},
            }
        case SET_SELECTED_BY_CATEGORY_OBJECT: 
            return {
                ...state,
                setSelectedCategoryObject: action.payload,
            }
        case CLEAR_SELECTED_BY_CATEGORY_OBJECT: 
            return {
                ...state,
                setSelectedCategoryObject: {},
            }
        case SET_ADVANCED_FILTER_ITEMS:
            return {
                ...state,
                setSelectedAdvancedFilterItems: action.payload,
            }
        case CLEAR_ADVANCED_FILTER_ITEMS: 
            return {
                ...state,
                setSelectedAdvancedFilterItems: [],
            }
        case SET_SELECTED_TAGS: 
            return {
                ...state,
                selectedTags: [...(new Set([...state.selectedTags, action.payload]))]
            }
        case CLEAR_SELECTED_TAGS: 
            return {
                ...state,
                selectedTags: [],
            }
        case ADD_SET_SELECTED_TAGS_COUNT: 
            return {
                ...state,
                tagsCount: state.tagsCount + 1
            }
        case REDUCE_SET_SELECTED_TAGS_COUNT: 
            return {
                ...state,
                tagsCount: state.tagsCount - 1
            }
        case CLEAR_SELECTED_TAGS_COUNT: 
            return {
                ...state,
                tagsCount: 0
            }
        case ENABLE_APPLY_BUTTON: 
            return {
                ...state,
                isApplyButtonDisabled: false
            }
        case DISABLE_APPLY_BUTTON: 
            return {
                ...state,
                isApplyButtonDisabled: true
            }
        case ENABLE_REMOVE_BUTTON: 
            return {
                ...state,
                isRemoveButtonDisabled: false
            }
        case DISABLE_REMOVE_BUTTON: 
            return {
                ...state,
                isRemoveButtonDisabled: true
            }
        case HEALTH_JOURNEY_SELECTED_CATEGORY_LIST: 
            return {
                ...state,
                healthJourneyCategoryList: action.payload
            }
            case ENABLE_CATEGORY_APPLY_BUTTON: 
            return {
                ...state,
                isCategoryApplyButtonDisabled: false
            }
        case DISABLE_CATEGORY_APPLY_BUTTON: 
            return {
                ...state,
                isCategoryApplyButtonDisabled: true
            }
        case ENABLE_CATEGORY_REMOVE_BUTTON: 
            return {
                ...state,
                isCategoryRemoveButtonDisabled: false
            }
        case DISABLE_CATEGORY_REMOVE_BUTTON: 
            return {
                ...state,
                isCategoryRemoveButtonDisabled: true
            }
        case SET_SELECTED_DROPDOWN_NUMBER: 
            return {
                ...state,
                selectedDropdownItemNumber: [...(new Set([...state.selectedDropdownItemNumber, action.payload]))]
            }
        case REMOVE_SELECTED_DROPDOWN_NUMBER: 
            return {
                ...state,
                selectedDropdownItemNumber: state.selectedDropdownItemNumber.filter(item => item !== action.payload)
            }
        case RESET_FILTERING_DATA:
            return {
                sortType: 1,
                resetComponents: false,
                eventsDateRange: {},
                setSelectedAdvancedFilterItems: [],
                selectedTags: [],
                setSelectDateOption: null,
                setSelectedDateObject: {},
                setSelectedCategoryObject: {},
                tagsCount: 0,
                isApplyButtonDisabled: true,
                isRemoveButtonDisabled: true,
                isCategoryApplyButtonDisabled: true,
                isCategoryRemoveButtonDisabled: true,
                healthJourneyCategoryList: [],
                selectedDropdownItemNumber : [],
            }
        default:
            return state;
    }
};

export default healthJourneyFilteringReducer;