import React, { Component } from 'react';
import { View } from 'react-native';

export default class ReportAttachGraphCommon extends Component {

  saveGraphImage = (callBack) => {
    this.chart.exporting.getImage('png').then(imgData => {
        callBack(imgData.replace(/^data:image\/[a-z]+;base64,/, ''));
    });
  }

  updateGrph = (graphid, chart) => {
    this.chart = chart;
    this.setState({
      graphid: graphid,
    });
  };

  render() {
    return <View />;
  }
}

