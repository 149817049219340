import React, {Component} from "react";
import {View, FlatList} from "react-native";
import * as actions from "../../../store/actions/index";
import ResultsListItem from "./ResultsListItem";
import DirectoryHeader from "./DirectoryHeader";
import PageContainer from "../../../components/PageLayout/PageContainer";
import _ from "lodash";
import { getScrollHeight } from '../../../util/commonUiLogic';

export const ITEM_LIST_WINDOW_SIZE = 19;

export default class AbstractResultsList extends Component {
  constructor(props) {
    super(props);
    const {searchResultStack} = props;
    const searchResult = searchResultStack.length > 0 ? searchResultStack[searchResultStack.length - 1].searchResults : [];
    let items = Array.from(searchResult.slice(0, ITEM_LIST_WINDOW_SIZE));
    let hasMore = items.length >= ITEM_LIST_WINDOW_SIZE;
    this.state = {
      searchResult: searchResult,
      items: items,
      itemEndIndex: items.length,
      hasMore: hasMore,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {searchResultStack} = nextProps;
    let {itemEndIndex} = prevState;
    const topOfSearchStack = searchResultStack.length > 0 ? searchResultStack[searchResultStack.length - 1] : {};
    const searchResult = topOfSearchStack.searchResults ? topOfSearchStack.searchResults : [];
    const searchCount = searchResult.length;
    const searchString = topOfSearchStack.searchObject ? topOfSearchStack.searchObject.searchString : '';
    const resourceType = topOfSearchStack.searchObject ? topOfSearchStack.searchObject.resourceType : '';
    const tagSearch = topOfSearchStack?.tag?.tag ? topOfSearchStack.tag.tag : null;
    if (!_.isEqual(searchResult, prevState.searchResult)) {
      itemEndIndex = ITEM_LIST_WINDOW_SIZE;
    }
    let items = Array.from(searchResult.slice(0, itemEndIndex));
    let hasMore = items.length < searchResult.length;

    if (nextProps.windowHeight !== prevState.windowHeight ||
      nextProps.windowWidth !== prevState.windowWidth ||
      !_.isEqual(searchResult, prevState.searchResult) ||
      !_.isEqual(items, prevState.items) ||
      hasMore !== prevState.hasMore ||
      tagSearch !== prevState.tagSearch ||
      searchCount !== prevState.searchCount ||
      searchString !== prevState.searchString ||
      resourceType !== prevState.resourceType
    ) {
      return {
        searchResult: searchResult,
        searchCount: searchCount,
        searchString: searchString,
        resourceType: resourceType,
        tagSearch: tagSearch,
        items: items,
        hasMore: hasMore,
        windowHeight: nextProps.windowHeight,
        windowWidth: nextProps.windowWidth
      };
    } else {
      return null;
    }
  }

  footer = () => {
    return <View style={{height: 100}}></View>;
  };
  header = () => {
    return (
      <DirectoryHeader
        searchCount={this.state.searchCount}
        searchString={this.state.searchString}
        tagSearch={this.state.tagSearch}
        resourceType={this.state.resourceType}
        onPressResetSearch={this.props.onPressResetSearch}
        onPressPreviousSearch={this.props.onPressPreviousSearch}
        onPressSaveCriteriaVisible={this.props.onPressSaveCriteriaVisible}
      />
    );
  };

  render() {
    const {hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth} = this.props;
    let btnHeight = 200;
    let tabHeight = 44;
    
    let scrollHeight = getScrollHeight(hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth, btnHeight + tabHeight);
    if (scrollHeight < 50) {
      scrollHeight = 50;
    }
    return (
      <PageContainer smScreenFull={true}>
        <View style={{maxHeight: scrollHeight}}>
          <View>
            {/* {this.state.items.length > 0 && ( */}
            <FlatList
              initialNumToRender={20}
              onEndReachedThreshold={0.5}
              data={this.state.searchResult}
              keyExtractor={item => item.resourceId ? item.resourceId.toString() : ""}
              renderItem={({item}) => (
                <ResultsListItem
                  result={item}
                />
              )}
              ListFooterComponent={this.footer}
              ListHeaderComponent={this.header}
            />
            {/* )} */}
          </View>
        </View>
      </PageContainer>
    );
  }
}

export const mapStateToProps = state => {
  return {
    showLearnHow: state.uistate.showLearnHow,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    socialMediaDataList: state.communityGroups.fetchAllSocialMediaData,
    searchResultStack: state.communityGroups.searchResultStack,
    user: state.routines.user,
    hasNotch: state.uistate.hasNotch,
    journalUserId: state.uistate.journalUserId,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    fetchSocialMediaData: resourceId =>
      dispatch(actions.fetchSocialMediaData(resourceId)),
    clearAddedResource: () => dispatch(actions.resetAddResourceFetchedData())
  };
};
