import React from "react";
import { View, Text } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import QuestionSkip from "./QuestionSkip";

const QuestionTitle = ({
  questionIndex,
  title,
  required,
  onSkip,
  isReadOnly,
}) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <View style={stylesRes.titleWrapperSection}>
      <View
        style={[
          stylesRes.titleWrapper,
          stylesRes.titleWrapperMain,
          stylesRes.surveyTitlePd,
        ]}
        dataSet={{media: ids.titleWrapper}}
      >
        <Text style={[styles.textTitleBold]} allowFontScaling={false}>
          {`Q${questionIndex}. `}
          {title}
          {Boolean(required) &&
            <Text
              style={[
                styles.textTitleExLgBold,
                styles.textRed,
                styles.questReqArea,
              ]}
              allowFontScaling={false}
            >
              {" "}
              *
            </Text>
          }
        </Text>
      </View>
      {/* Skip Now Start */}
      {!required && !isReadOnly && <QuestionSkip onSkip={onSkip} />}
      {/* Skip Now End */}
    </View>
  );
};

export default (QuestionTitle);
