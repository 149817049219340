import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  Platform,
} from "react-native";
import { connect } from "react-redux";
import moment from "moment";
import Orientation from "react-native-orientation-locker";
import Graph from "../../components/Settings/RollingGraphs/Graph";
import PageContainer from "../../components/PageLayout/PageContainer";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import * as actions from "../../store/actions/index";
import { WEB } from "../../util/platformWindowConfig";

class GraphPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
    };
  }
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { windowHeight, windowWidth } = nextProps;
    this.setState({
      windowWidth,
      windowHeight,
    });
  };
  componentDidMount = () => {
    Platform.OS !== WEB && Orientation.lockToLandscapeLeft();
  };
  componentWillUnmount = () => {
    Platform.OS !== WEB && Orientation.unlockAllOrientations();
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    let fullHeight = this.state.windowHeight;
    let graphPageHeight = 0;
    let graphHeight = 0;
    let headerHeight = 57;
    let graphPd = 20;
    let careGiverAlertHeight = 0;
    if (this.props.journalUserId) {
      careGiverAlertHeight = 31;
    }
    let titleHeight = 100;
    let totHeight = headerHeight + careGiverAlertHeight;
    graphPageHeight = fullHeight - totHeight;
    graphHeight = graphPageHeight - (titleHeight + graphPd);
    let windowWidth = this.state.windowWidth;
    return (
      <React.Fragment>
        <View
          style={[
            styles.graphZoomArea,
            this.props.journalUserId
              ? styles.graphZoomMgLg
              : styles.graphZoomMg,
            { height: graphPageHeight },
          ]}
        >
          <PageContainer>
          <View style={{paddingTop:  graphPd}}>
            <View style={[styles.graphPreviewArea, { height: graphHeight }]}>
              <Graph
                metrics={this.props.metrics}
                therapies={this.props.therapies}
                symptoms={this.props.symptoms}
                startDate={this.props.startDate ? new Date(this.props.startDate) : null}
                endDate={this.props.endDate ? new Date(this.props.endDate) : null}
                graphHeight={graphHeight}
                symptomProps={this.props.symptomProps}
                metricProps={this.props.metricProps}
                therapyProps={this.props.therapyProps}
                isFromAddEditGraph={true}
                graphDivId={"chartdiv_graph"}
                enableZoom={true}
              />
            </View>
          </View>
          </PageContainer>
          <View style={[styles.graphContentArea, { height: titleHeight }]}>
            <ScrollView>
              <PageContainer>
                <View style={[stylesRes.graphContentZoom, stylesRes.graphMgZoom]}>
                  <View
                    style={[
                      styles.graphContentPd,
                      styles.menuBgWhite,
                      styles.previewGraphView,
                    ]}
                  >
                    <View
                      style={[
                        styles.graphText,
                        styles.flexRowWrap,
                        styles.flexAiCt,
                      ]}
                    >
                      <View style={[styles.flexCom]}>
                        <Text
                          style={[styles.textPrimeSmBold, styles.textBlack]}
                          allowFontScaling={false}
                        >
                          {this.props.graphName}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.flexCom,
                          styles.graphTextLg,
                          windowWidth > 767
                            ? styles.graphTextPreviewLg
                            : styles.graphTextPreviewMd,
                        ]}
                      >
                        <Text
                          style={[
                            styles.textPrimSmBold,
                            styles.textBlack,
                            styles.textAlignCenter,
                          ]}
                          allowFontScaling={false}
                        >
                          {this.props.startDate
                            ? moment(this.props.startDate).format("DD MMM 'YY")
                            : ""}{" "}
                          -{" "}
                          {this.props.endDate
                            ? moment(this.props.endDate).format("DD MMM 'YY")
                            : ""}
                        </Text>
                      </View>

                      <View
                        style={[
                          styles.flexCom,
                          styles.flexRow,
                          styles.flexJcFe,
                        ]}
                      >
                        <View style={[styles.graphIconInd]}>
                          <TouchableOpacity
                            onPress={() => {
                              this.props.previewGraph();
                            }}
                          >
                            <Image
                              style={[styles.zoomOutIcon]}
                              source={require("../../assets/icons/zoom-out.png")}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </PageContainer>
            </ScrollView>
          </View>
        </View>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: state.rollinggraphs.id,
    graphName: state.rollinggraphs.graphName,
    journalUserId: state.uistate.journalUserId,
    metrics: state.rollinggraphs.metrics,
    symptoms: state.rollinggraphs.symptoms,
    therapies: state.rollinggraphs.therapies,
    startDate: state.rollinggraphs.startDate,
    endDate: state.rollinggraphs.endDate,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    symptomProps: state.rollinggraphs.symptomProps,
    metricProps: state.rollinggraphs.metricProps,
    therapyProps: state.rollinggraphs.therapyProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    previewGraph: () => dispatch(actions.previewGraph()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GraphPreview);
