import React from "react";
import { View, Text, TouchableWithoutFeedback } from "react-native";
import styles from "../../../Css";
const subMenuTitel = (props) => {
  const { titel, handleTabMenuClick } = props;
  return (
    <TouchableWithoutFeedback 
      onPress={() => {
        if (handleTabMenuClick) {
          handleTabMenuClick()
          return;
        }
      }}>
    <View style={styles.mainNavSubItemTitle}>
      <View style={[styles.navListLiDet, styles.navListLiDetPdSm]}>
        <View style={[styles.navListLiTitle]}>
          <Text style={[styles.textPrimeExSm, styles.textGrey]} allowFontScaling={false}>{titel}</Text>
        </View>
      </View>
    </View>
    </TouchableWithoutFeedback>
  );
};

export default (subMenuTitel);
