import React from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import QuestionMainItem from '../../components/Question/QuestionMainItem';
import PageContainer from '../../components/PageLayout/PageContainer';
import stylesResponsive from '../../CssResponsive';
import AbatractQuestionScrollView, { mapStateToProps } from './AbstractQuestionScrollView';

class QuestionScrollView extends AbatractQuestionScrollView {
  fetchMoreData = () => {
    if (this.state.items.length >= this.props.data.length) {
      this.setState({hasMore: false, fatchMoreData: true});
      return;
    } else {
      const items = this.state.items.concat(
        this.props.data.slice(
          this.state.items.length,
          this.state.items.length + 19,
        ),
      );

      if (items.length > this.state.items.length) {
        setTimeout(() => {
          this.setState({
            items: items,
            hasMore: this.props.data.length > items.length,
            fatchMoreData: true,
          });
        }, 1500);
      }
    }
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    return (
      // <div id="scrollableDiv" style={{height: scrollHeight, overflowY: 'auto'}}>
        <PageContainer smScreenFull={true}>
          <View style={stylesRes.contAreaAll}>
            <InfiniteScroll
              dataLength={this.state.items.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMore}
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv">
              {this.state.items.map((item, index) => (
                <QuestionMainItem
                  key={item.id}
                  user={this.props.user}
                  tabIndex={this.props.tabIndex}
                  healthQuestion={item}
                  redirectJournal={this.props.redirectJournal}
                  redirectMedicationsTherapies={
                    this.props.redirectMedicationsTherapies
                  }
                  redirectContact={this.props.redirectContact}
                  answerdQuestion={this.props.answerdQuestion}
                  getQuestionDigest={this.props.getQuestionDigest}
                />
              ))}
            </InfiniteScroll>
            <View style={{height: 100}} />
          </View>
        </PageContainer>
      // </div>
    );
  }
}

export default connect(
  mapStateToProps,
  null,
)(QuestionScrollView);
