import React, { useState } from "react";
import { Text, View } from "react-native";
import styles from "../../Css";
import Modal from "../Modal/modal";
import ModalLayout from "../../components/ModalLayout/modalLayout";
import PrimaryButton from "../../components/Button/primaryButton";
import PrimaryButtonWhite from "../../components/Button/primaryButtonWhite";

const SavingConfirmation = (props) => {
  const [conSaveInprogress, changeConSaveInprogress] = useState(false);

  const handlePress = () => {
    changeConSaveInprogress(true);
    props.handleConfirm()
  }

  return (
    <Modal
      visible={props.visibleModal}
      onRequestClose={props.onRequestClose}
      onBackdropPress={props.onBackdropPress}
    >
      <ModalLayout
        contPosition={"start"}
        showClose={true}
        pdCom={true}
        hidePopup={props.onBackdropPress}
        modalSpecStyle={styles.comfirmModalMin}
        modalDynamic={props.dynamic}
      >
        <View style={[styles.popupContentWrapper, styles.flexCom]}>
          <View
            style={[styles.popMessageMainView, styles.flexCom, styles.flexJcCt]}
          >
            <View style={[styles.popMessageComView]}>
              <View style={[styles.popMessageTitleView]}>
                <Text
                  style={[
                    styles.textTitleBold,
                    styles.textThickGrey,
                    styles.textAlignCenter,
                  ]}
                  allowFontScaling={false}
                >
                  {props.alertTitle}
                </Text>
              </View>
            </View>
            {Boolean(props.alertBody) && (
              <View style={[styles.popMessageSmView, styles.secBgPdTop]}>
                <Text
                  style={[
                    styles.textPrime,
                    styles.textThickGrey,
                    styles.textAlignCenter,
                  ]}
                  allowFontScaling={false}
                >
                  {props.alertBody}
                </Text>
              </View>
            )}
          </View>
          <View style={[styles.popBtnView, styles.comMgTopSm]}>
            <View style={[styles.btnArea, styles.flexRow]}>
              <View style={styles.fieldBtn}>
                <PrimaryButtonWhite
                  btnText={props.btnTextOne ? props.btnTextOne : "Don’t Save"}
                  btnPosition={"full"}
                  btnStyle={[styles.darkBtnBorder]}
                  touchableOnPress={props.handleCancel}
                  btnTextStyle={styles.textColorGreyLight}
                  activityIndStyle={styles.indicatorLtDefaultSm}
                  activityInd={props.deleteWait}
                  disabled={conSaveInprogress}
                />
              </View>
              <View style={styles.fieldBtnSpSm} />
              <View style={styles.fieldBtn}>
                <PrimaryButton
                  btnText={props.btnTextTwo ? props.btnTextTwo : "Save"}
                  btnPosition={"full"}
                  touchableOnPress={() => { handlePress() }}
                  activityInd={props.confirmWait}
                  btnShadow={true}
                  disabled={props.disabled || props.saveDisabled}
                  idFromHealthJourney={props.idFromHealthJourney}
                  btnStyle={props.btnStyle}
                />
              </View>
            </View>
          </View>
        </View>
      </ModalLayout>
    </Modal>
  );
};
export default SavingConfirmation;
