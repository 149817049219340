import React, { Component } from 'react'

class DraggableComponent extends Component {
  constructor (props) {
    super(props);
    const {defaultPosition} = props;
    this.state = {
      pos: defaultPosition || {x: 0, y: 0},
      dragging: false,
      rel: null // position relative to the cursor
    };
    this.divRef = null;
  }

  componentDidUpdate(props, state) {
    if (this.state.dragging && !state.dragging) {
      document.addEventListener('mousemove', this.onMouseMove)
      document.addEventListener('mouseup', this.onMouseUp)
    } else if (!this.state.dragging && state.dragging) {
      document.removeEventListener('mousemove', this.onMouseMove)
      document.removeEventListener('mouseup', this.onMouseUp)
    }
  }

  onMouseDown = (e) => {
    // only left mouse button
    if (e.button !== 0) return
    var pos = this.divRef.getBoundingClientRect();
    this.setState({
      dragging: true,
      rel: {
        x: e.pageX - pos.left,
        y: e.pageY - pos.top
      }
    })
    e.stopPropagation()
    e.preventDefault()
  }

  onMouseUp = (e) => {
    this.setState({dragging: false})
    e.stopPropagation()
    e.preventDefault()
  }

  onMouseMove = (e) => {
    if (!this.state.dragging) return
    this.setState({
      pos: {
        x: e.pageX - this.state.rel.x,
        y: e.pageY - this.state.rel.y - this.props.windowHeight
      }
    })
    e.stopPropagation()
    e.preventDefault()
  }

  render() {
    return (
      <div
        onMouseDown={this.onMouseDown}
        style={{
          position: 'absolute',
          left: this.state.pos.x,
          top: this.state.pos.y,
        }}
        ref={ref => (this.divRef = ref)} 
      >
        {this.props.children}
      </div>
    )
  }
}

export default DraggableComponent;

