import * as axiosCommon from "../../axios/axios-common";
import * as url from "./urls";
import * as actionTypes from "./actionTypes";

export const saveFilesToAttach = files => {
  return {
    type: actionTypes.SAVE_FILES_TO_ATTACH,
    files: files
  };
};

export const saveUploadedAttachments = files => {
  return {
    type: actionTypes.SAVE_UPLOADED_ATTACHMENTS,
    files: files
  };
};

export const clearFilesToAttach = () => {
  return {
    type: actionTypes.CLEAR_FILES_TO_ATTACH
  };
};

export const clearUploadedAttachments = () => {
  return {
    type: actionTypes.CLEAR_UPLOADED_ATTACHMENTS
  };
};

export const searchAttachment = (formData, callBack) => {
  return dispatch => {
    axiosCommon.axiosGET(
      url.OCR_SEARCH_ATTACHMENTS,
      res => {
        if (res.type === "SUCCESS") {
          callBack(true, res.responseDto.data);
        } else {
          callBack(false, res.errorDto);
        }
      },
      formData
    );
  };
};

