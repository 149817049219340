import React from "react";
import { View, Text } from "react-native";
import styles from "../../Css";
const ColorPickerRow = (props) => {
  return (
    <View
      style={[
        styles.colorPickerRow,
        styles.flexRow,
        styles.colorPickerRowPd,
        styles.bgThinGrey,
        styles.flexAiCt,
        styles.itemViewSp
      ]}
    >
      <View style={[
        props.ColorPickerCom
        ? styles.colorPickerTextCom
        : styles.colorPickerTextComLg
        ]}>
        <Text style={styles.textPrimeSmBold} allowFontScaling={false}>{props.colorPickerTitle}</Text>
      </View>
      <View style={[styles.colorPickerButtonSec]}>
          {props.children}
      </View>
    </View>
  );
};

export default (ColorPickerRow);
