import React from "react";
import { View, Text } from "react-native";
import styles from "../../../../Css";
import moment from "moment";

const dateRangeBlock = (props) => {
  const {
    startDate,
    endDate,
    createdOn,
    status,
    windowWidth
  } = props;
  return (
    <View style={[styles.colPdTopBotRg, styles.listDateRangeGraph,
    status === 'ENABLED'    
    ? styles.listDateRangeGraphLg
    : status !== 'ENABLED' && windowWidth > 374
    ? styles.listDateRangeGraphSm
    : styles.listDateRangeGraphExSm
    ]}>
    {status === 'ENABLED' &&
      <View style={styles.graphTitleDateRange}>
        <Text
          style={[
            styles.textPrimeSm,
            styles.textAlignLeft,
          ]}
          allowFontScaling={false}
        >
          {startDate ? moment(startDate).format("D MMM 'YY") : ""}{" "}
          - {endDate ? moment(endDate).format("D MMM 'YY") : ""}
        </Text>
      </View>
    }
    <View style={styles.graphDateWrapper}>
      <Text
        style={[
          styles.textPrimeSm,
          styles.textGreyDark,
          styles.graphLefPd
        ]}
        allowFontScaling={false}
      >
        Saved: {moment(createdOn, "x").format("D MMM 'YY")}
      </Text>
    </View>
  </View>
  );
};

export default dateRangeBlock;
