import React from "react";
import {
  Image,
  View,
  Text,
} from "react-native";
import PageContainer from "../../../PageLayout/PageContainer";
import styles from "../../../../Css";
import stylesResponsive from "../../../../CssResponsive";
import Collapsible from "../../../Collapsible/Collapsible";
import SearchField from "../../../Search/searchField";
import PrimaryButtonDark from "../../../Button/primaryButtonDark";
import KeyboardAwareScrollViewHoc from '../../../KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import CardItem from '../../Card/CardItem';
import ActivityIndicator from '../../../ActivityIndicator/activityIndicator';
import DisconnectPopup from '../../ConfirmationBox/disconnectPopup';
import BlockPopup from '../../ConfirmationBox/blockPopup';
import ReportPopup from '../../ConfirmationBox/reportPopup';
import SuccessMessagePopup from '../../ConfirmationBox/successMessagePopup';

const sectionFilter = (section, keyword, displayMessage) => {
  const filterArray = section.reduce((accArr, res) => {
    let age = '';
    if (res.birthday) {
      let ageDifMs = Date.now() - res.birthday;
      let ageDate = new Date(ageDifMs);
      age = Math.abs(ageDate.getUTCFullYear() - 1970).toString();
    }
    let conditionList = [];
    if (res?.medicalConditionsList?.length && keyword) {
      conditionList = res.medicalConditionsList.filter(condition => {
        return condition.toLowerCase().includes(keyword.toLowerCase());
      });
    }

    if (keyword && (
      (res.userName
        ? res.userName.toLowerCase().includes(keyword.toLowerCase())
        : null) ||
      (res.sex
        ? res.sex.toLowerCase().includes(keyword.toLowerCase())
        : null) ||
      (res.birthday ? age.includes(keyword) : null) ||
      (res.story
        ? res.story.toLowerCase().includes(keyword.toLowerCase())
        : null) ||
      (conditionList && conditionList.length > 0 ? true : null) ||
      (displayMessage && res.message
        ? res.message.toLowerCase().includes(keyword.toLowerCase())
        : null))
    ) {
      accArr.push({
        ...res,
        isExpand:
          (conditionList?.length) ||
              (res?.story && res.story.toLowerCase().includes(keyword.toLowerCase()))
            ? true
            : false,
      });
    }

    return accArr;
  }, []);

  return filterArray;
};

const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
const MyNetwork = props => {
  const {
    hasPeerProfile,
    visibleReceived,
    visibleSent,
    visibleConnected,
    onPressCollapsibleRecieved,
    onPressCollapsibleSent,
    onPressCollapsibleConnected,
    handleRedirect,
    myNetworkList,
    loading,
    onSelectActionStatus,
    searchedKeyword,
    onSearchInputValueChange,
    connecting,
    connectionID,
    wait,
    visibleDisconnectPopup,
    visibleBlockPopup,
    visibleReportPopup,
    visibleSuccessMessage,
    hidePopup,
    selectedUser,
    onDisconnectPeer,
    disconnectWait,
    onBlockPeer,
    blockWait,
    onReportPeer,
    reportWait,
    successMessage,
    successMessageTitle,
    onPressSend,
    conversations,
    profileLocked
  } = props;

  const searchedReceivedArr = Boolean(searchedKeyword)
    ? sectionFilter([...myNetworkList?.received || []], searchedKeyword, true)
    : [...myNetworkList?.received || []];
  const searchedSentArr = Boolean(searchedKeyword)
    ? sectionFilter([...myNetworkList?.sent || []], searchedKeyword, false)
    : [...myNetworkList?.sent || []];
  const searchedConnectedArr = Boolean(searchedKeyword)
    ? sectionFilter([...myNetworkList?.connected || []], searchedKeyword, false)
    : [...myNetworkList?.connected || []];

  return (
    <KeyboardAwareScrollViewHoc>
      <PageContainer smScreenFull={true}>
        <View style={[styles.secArea, styles.colPdTopCom, styles.pageBotPd]}>
          <View style={[styles.gridRow, styles.colPdLtRt]}>
            <View style={styles.gridColFull}>
              <View style={[styles.searchSection]}>
                <View style={[styles.flecColRev]}>

                  <View style={stylesRes.newConnectionSearchBar} dataSet={{media: ids.newConnectionSearchBar}}>
                    <SearchField
                      searchText={
                        searchedKeyword ? searchedKeyword : ""
                      }
                      placeholder="Search my connections"
                      onChangeText={onSearchInputValueChange}
                      showClearBtton={
                        searchedKeyword && searchedKeyword !== ""
                          ? true
                          : false
                      }
                      clearFilter={() => onSearchInputValueChange(null)}
                      nativeId={'communityMyConnectionsSearch'}
                      eventName={'Community > @MyConnections  > Search'}
                    />
                  </View>
                  
                  <View style={stylesRes.newConnectionBtnPost} dataSet={{media: ids.newConnectionBtnPost}}>
                    <View style={{bottom:10}}>
                      <Text style={styles.textTitleBold}>
                        Community
                      </Text>
                    </View>
                    <View style={stylesRes.newConnectionBtnCr} dataSet={{media: ids.newConnectionBtnCr}}>
                    <PrimaryButtonDark
                      touchableOnPress={handleRedirect}
                      btnPosition={"full"}
                      btnText={"+ New Connections"}
                      btnShadowNone={true}
                      disabled={!Boolean(hasPeerProfile) || profileLocked}
                      disabledColorChange={true}
                      newConnection={true}
                      nativeId={'communityMyConnectionsNewConnections'}
                    />
                    </View>
                  </View>

                </View>
              </View>
              {(Boolean(myNetworkList) &&
                Boolean(searchedReceivedArr?.length === 0) &&
                Boolean(searchedSentArr?.length === 0) &&
                Boolean(searchedConnectedArr?.length === 0)) &&
                <>
                  <View
                    style={[
                      styles.pageTitleWrapper,
                      styles.contPdTop,
                      styles.comSpLg,
                    ]}
                  >
                    <Text style={[styles.textTitleMdBold, styles.textAlignCenter]} allowFontScaling= {false}>
                      Search for connections and connect with them!
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.flexJcCtAiCt,
                      styles.comSpLg,
                    ]}
                  >
                    <Image
                      style={[styles.networkIcon]}
                      source={require("../../../../assets/peer-to-peer/network.png")}
                    />
                  </View>
                </>
              }
            </View>
          </View>

          {loading ? (
            <ActivityIndicator pdLarge={true} />
          ) : (
            <>
              <Collapsible
                title={`Received (${searchedReceivedArr.length})`}
                contentVisibility={visibleReceived}
                handleOnPressCollapsible={() => onPressCollapsibleRecieved()}
                borderNone={true}
              >
                <View style={[styles.collapseContent, styles.colPdLtRt]}>
                  <View
                    style={[
                      styles.collapseInContent,
                      //styles.colPdLtRt,
                      // styles.listTopBotPd,
                    ]}
                  >
                    {searchedReceivedArr.sort((a,b) => (a?.userName !== null && b?.userName !== null) && 
                      a?.userName.toLowerCase().localeCompare(b?.userName.toLowerCase())).map((element, index) => (
                        <CardItem
                          key={index}
                          peerProfile={element}
                          displayInfo={true}
                          displayConnectDeclineButtons={true}
                          displayReceivedMessage={true}
                          onSelectActionStatus={onSelectActionStatus}
                          isExpand={element.isExpand ? element.isExpand : false}
                          searchedKeyword={searchedKeyword}
                          connecting={connecting}
                          connectionID={connectionID}
                          wait={wait}
                        />
                      ))}
                  </View>
                </View>
              </Collapsible>

              <Collapsible
                title={`Sent (${searchedSentArr.length})`}
                contentVisibility={visibleSent}
                handleOnPressCollapsible={() => onPressCollapsibleSent()}
                topBorder={true}
              >
                <View style={[styles.collapseContent, styles.colPdLtRt]}>
                  <View
                    style={[
                      styles.collapseInContent,
                      //styles.colPdLtRt,
                      // styles.listTopBotPd,
                    ]}
                  >
                    {searchedSentArr.sort((a,b) => (a?.userName !== null && b?.userName !== null) && 
                      a?.userName.toLowerCase().localeCompare(b?.userName.toLowerCase())).map((element, index) => (
                        <CardItem
                          key={index}
                          peerProfile={element}
                          displayInfo={true}
                          displayStatusButton={true}
                          displaySettingsButton={true}
                          onSelectActionStatus={onSelectActionStatus}
                          isExpand={element.isExpand ? element.isExpand : false}
                          searchedKeyword={searchedKeyword}
                        />
                      ))}
                  </View>
                </View>
              </Collapsible>
              <Collapsible
                title={`Connected (${searchedConnectedArr.length})`}
                contentVisibility={visibleConnected}
                handleOnPressCollapsible={() => onPressCollapsibleConnected()}
                topBorder={true}
              >
                <View style={[styles.collapseContent, styles.colPdLtRt]}>
                  <View
                    style={[
                      styles.collapseInContent,
                      //styles.colPdLtRt,
                      // styles.listTopBotPd,
                    ]}
                  >
                    {searchedConnectedArr.sort((a,b) => (a?.userName !== null && b?.userName !== null) && 
                      a?.userName.toLowerCase().localeCompare(b?.userName.toLowerCase())).map((element, index) => (
                        <CardItem
                          key={index}
                          peerProfile={element}
                          displayInfo={true}
                          displayStatusButton={true}
                          displaySettingsButton={true}
                          onSelectActionStatus={onSelectActionStatus}
                          isExpand={element.isExpand ? element.isExpand : false}
                          searchedKeyword={searchedKeyword}
                          displaySendIcon={true}
                          onPressSend={onPressSend}
                          foundChatOnList={conversations.find(conversation => conversation.userId === element.id)}
                        />
                      ))}
                  </View>
                </View>
              </Collapsible>
              <View style={styles.greyBorderThin} />
            </>
          )}
        </View>
      </PageContainer>
      {visibleDisconnectPopup &&
        <DisconnectPopup
          visibleModal={visibleDisconnectPopup}
          hidePopup={hidePopup}
          selectedUser={selectedUser}
          onDisconnect={onDisconnectPeer}
          wait={disconnectWait}
        />
      }
      {visibleBlockPopup &&
        <BlockPopup
          visibleModal={visibleBlockPopup}
          hidePopup={hidePopup}
          selectedUser={selectedUser}
          onBlock={onBlockPeer}
          wait={blockWait}
        />
      }
      {visibleReportPopup &&
        <ReportPopup
          visibleModal={visibleReportPopup}
          hidePopup={hidePopup}
          selectedUser={selectedUser}
          onReport={onReportPeer}
          wait={reportWait}
        />
      }
      {visibleSuccessMessage &&
        <SuccessMessagePopup
          visibleModal={visibleSuccessMessage}
          hidePopup={hidePopup}
          message={successMessage}
          messageTitle={successMessageTitle}
          selectedUser={selectedUser}
        />
      }
    </KeyboardAwareScrollViewHoc>
  );
}

export default (MyNetwork);
