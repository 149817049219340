import React, { Component } from "react";
import { View, Text, Image } from "react-native";
import PropTypes from "prop-types";
import styles from "../../../Css";
import {isMobile} from 'react-device-detect';
class DropZone extends Component {
  state = {
    dropzoneBackgroundColor: "#ffff",
  };

  handleOnDrop = (e) => {
    const { onGetFiles } = this.props;

    e.stopPropagation();
    e.preventDefault();

    onGetFiles([ ...e.dataTransfer.files ]);
  };

  setBackgroundColor = (color) => {
    this.setState({ dropzoneBackgroundColor: color });
  };

  render() {
    return (
      !isMobile && (
      <View style={[styles.dragNdropInnerWrapper]}>
        <label
          for="dropzone"
          onDragOver={(e) => this.setBackgroundColor("#aaa")}
          onDragLeave={(e) => this.setBackgroundColor("#ffff")}
        >
          {/* <View> */}
            {/* <Image
              style={[styles.paperIcon]}
              source={require("../../assets/icons-portal/file-icon.png")}
            /> */}
            {/* <Text>
              Drag and drop your file here
            </Text> */}
              <Image
                style={[styles.dragNdropIcon]}
                source={require('../../../assets/ocr-icons/add-document-icon-sml.png')}
              />
              <View>
                <Text style={[styles.textTitleExLgBold]} allowFontScaling={false}>Drag and drop your document here</Text>
              </View>
          {/* </View> */}
          <input
            type="file"
            multiple="multiple"
            style={{
              position: "absolute",
              left: "0",
              opacity: "0",
              top: "0",
              bottom: "0",
              width: "100%",
            }}
            id="dropzone"
            onDrop={(e) => this.handleOnDrop(e)}
            onClick={(e) => e.preventDefault()}
            disabled={this.props.disabled}
          />
        </label>
      </View>
      )
    );
  }
}

DropZone.propTypes = {
  readFormat: PropTypes.string,
  boxWidth: PropTypes.string,
  boxHeight: PropTypes.string,
  callBack: PropTypes.func,
};

export default DropZone;
