import React, {Component} from "react";
import {View, Text, TouchableOpacity} from "react-native";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";

class DirectoryHeader extends Component {

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <React.Fragment>
        <View>
          <View>
            <View style={styles.colPdTopLtRt}>
              <View style={[styles.titleArea, styles.comSp]}>
                <View style={stylesRes.maxContArea} dataSet={{media: ids.maxContArea}}>
                  <View style={[styles.titleFullBlock, styles.flexJcCtAiCt]}>
                    <View style={[styles.titleSubBlock]}>
                      <Text style={[styles.titleText, styles.bold, styles.textGrey]} allowFontScaling={false}>
                        Zamplo does not control or actively monitor User Content and, as such, does not guarantee the accuracy, integrity or quality of such content. Information from a verified source can be identified by a blue checkmark.
                      </Text>
                    </View>
                    <View style={[styles.titleSubBlock]}>
                      <Text style={[styles.textPrime]} allowFontScaling={false}>
                        <Text style={[styles.bold]} allowFontScaling={false}>
                          {this.props.searchCount ? this.props.searchCount : 0}
                        </Text>{" "}
                        results found for:
                      </Text>
                    </View>

                    <View style={[styles.searchResultSec]}>
                      <View style={[styles.resultWrapper]}>
                        <View style={[styles.ViewRow]}>
                          <View style={styles.viewSearchSec}>
                            <View style={styles.viewMainData}>
                              <View
                                style={[
                                  styles.viewItemDesc,
                                  styles.flexJcCtAiCt
                                ]}
                              >
                                <View style={[styles.viewDataSearchFull]}>
                                  <View style={[styles.viewItemData]}>
                                    <View style={[styles.searchItemList]}>
                                      {this.props.searchString ? (
                                        <View
                                          style={[
                                            styles.searchItem,
                                            styles.searchItemInd
                                          ]}
                                        >
                                          <Text
                                            style={[
                                              styles.textPrimeBold,
                                              styles.textAlignCenter
                                            ]}
                                            allowFontScaling={false}
                                          >
                                            {this.props.searchString}
                                          </Text>
                                          <Text
                                            style={[
                                              styles.textPrimeBold,
                                              styles.textAlignCenter
                                            ]}
                                            allowFontScaling={false}
                                          >
                                            {" "}
                                            |{" "}
                                          </Text>
                                        </View>
                                      ) : null}
                                      <View
                                        style={[
                                          styles.searchItem,
                                          styles.searchItemInd
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            styles.textPrimeBold,
                                            styles.textAlignCenter
                                          ]}
                                          allowFontScaling={false}
                                        >
                                          {this.props.resourceType
                                            ? this.props.resourceType
                                            : ""}
                                        </Text>
                                      </View>
                                      {this.props.tagSearch &&
                                        <View
                                          style={[
                                            styles.searchItem,
                                            styles.searchItemInd
                                          ]}
                                        >
                                          <Text
                                            style={[
                                              styles.textPrimeBold,
                                              styles.textAlignCenter
                                            ]}
                                            allowFontScaling={false}
                                          >
                                            Tag: {this.props.tagSearch}
                                          </Text>
                                        </View>
                                      }
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={styles.flexRow}>
                      <View style={styles.smPdLtRt}>
                        <View style={[styles.btnBlockView]}>
                          <View style={[styles.btnBlock]}>
                            <TouchableOpacity
                              onPress={this.props.onPressResetSearch}
                            >
                              <View
                                style={[
                                  styles.newCancelLbBtn
                                ]}
                                underlayColor="rgba(0, 153, 168, 0.8)"
                              >
                                <Text style={styles.cancelTxtCr} allowFontScaling={false}>
                                  Reset Search
                                </Text>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                      {this.props.tagSearch &&
                        <View style={[styles.btnBlockView]}>
                          <View style={[styles.btnBlock]}>
                            <TouchableOpacity
                              onPress={this.props.onPressPreviousSearch}
                            >
                              <View
                                style={[
                                  styles.newCancelLbBtn
                                ]}
                                underlayColor="rgba(0, 153, 168, 0.8)"
                              >
                                <Text style={styles.cancelTxtCr} allowFontScaling={false}>
                                  Previous Search
                                </Text>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      }
                      {!this.props.tagSearch ? (
                          <View style={styles.smPdLtRt}>
                            <View style={[styles.btnBlockView]}>
                              <View style={[styles.btnBlock]}>
                                <TouchableOpacity
                                  onPress={this.props.onPressSaveCriteriaVisible}>
                                  <View
                                    style={[styles.newResourceHealthLbBtn]}>
                                    <Text style={[styles.textPrimeBold, styles.textWhite]} allowFontScaling={false}>
                                      Save Criteria
                                    </Text>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        ) :
                        null}
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.resourceBorderApp}>
            <View style={stylesRes.maxContArea} dataSet={{media: ids.maxContArea}}>
              <View style={[styles.comMgTop, styles.greyBorderThin]}></View>
            </View>
          </View>
        </View>
      </React.Fragment>
    );
  }
}

export default (DirectoryHeader);
