import React, {Component} from 'react';
import Modal from '../../Modal/modal';
import ModalLayout from '../../ModalLayout/modalLayout';
import ActivityQuickEntry from './ActivityQuickEntry';
import MedicationQuickEntry from './MedicationQuickEntry';
import SuccessModal from './success';
import {pendoTrackEvent} from '../../../util/pendoConfig';
class QuickEntryPopup extends Component {
  constructor(props) {
    super(props);
    const {visibleModal} = props;

    this.state = {
      visibleModal: visibleModal,
      success: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {visibleModal} = props;
    if (visibleModal !== state.visibleModal) {
      return {
        visibleModal: visibleModal,
      };
    }
    return null;
  }

  getRenderItem = () => {
    const {hidePopup, treatment} = this.props;
    const {treatmentType} = treatment;
  
    if (treatmentType === 5) {
      return (
        <ActivityQuickEntry
          hidePopup={hidePopup}
          treatment={treatment}
          showSuccess={this.showSuccess}
        />
      );
    } else {
      return (
        <MedicationQuickEntry
          hidePopup={hidePopup}
          treatment={treatment}
          showSuccess={this.showSuccess}
        />
      );
    }
  };

  showSuccess = () => {
    const {hidePopup} = this.props;
    //set the visibleModal false
    hidePopup();
    //sets the success popup to true
    //in ios you cant have 2 modals at the same time
    //so we have to wait a bit to show the other modal
    setTimeout(() => {
      this.setState({success: true}, () => {
        setTimeout(() => {
          this.setState({success: false});
        }, 3000);
      });
    }, 500);
  };

  render() {
    const {visibleModal, success} = this.state;
    const {treatment, hidePopup} = this.props;
    const {treatmentType, name} = treatment || {};
    return (
      <>
        <Modal
          visible={visibleModal}
          onBackdropPress={hidePopup}>
          <ModalLayout
            contPosition={'center'}
            showClose={true}
            nativeId={treatmentType === 5 
                ? 'dashboardRecordActExit' 
                : 'dashboardRecordMedExit'
            }
            hidePopup={() => {
              pendoTrackEvent(
                treatmentType === 5 
                  ? 'Dashboard@ > RecordActivity > Exit' 
                  : 'Dashboard@ > RecordMed > Exit'
              );
              hidePopup();
            }}>
            {treatment && this.getRenderItem()}
          </ModalLayout>
        </Modal>
        <SuccessModal
          treatmentType={treatmentType}
          name={name}
          visibility={success}
          hidePopup={() => this.setState({success: false})}
        />
      </>
    );
  }
}
export default (QuickEntryPopup);
