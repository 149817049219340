import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import ConfirmationBox from "../ConfirmationBox/confirmationBox";

class AllergyList extends Component {
  state = {
    userAllergies: [],
    confirmBox: false,
    deleteWait: false,
    selectedId: null,
  };
  constructor(props) {
    super(props);
    const { editMode } = props;
    this.state = {
      userAllergies: props.userAllergies,
      editMode,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { userAllergies, editMode, newUserAllergies = [], deleteUserAllergy } = props;
    if (Boolean(deleteUserAllergy)) {
      this.setState({ userAllergies: [...newUserAllergies], editMode });
    } else {
      this.setState({ userAllergies, editMode });
    }
  }

  onDeleteAllergy = () => {
    if (this.state.selectedId) {
      if (Boolean(this.props.deleteUserAllergy)) {
        this.props.deleteUserAllergy(this.state.selectedId);
        this.setState({
          confirmBox: false,
          selectedId: null,
        });
      } else {
        this.setState({ deleteWait: true });
        this.props.onDeleteAllergy(this.state.selectedId, (res) => {
          this.setState({
            confirmBox: false,
            selectedId: null,
            deleteWait: false,
          });
        });
      }
    }
  };

  render() {
    return (
      <View style={[styles.addedSecFull]}>
        {this.state.userAllergies.map((res, index) => (
          <View
            style={[
              styles.addedItemRow,
              // styles.itemViewSpLg,
              this.state.editMode && styles.itemViewSpLg,
              // !this.state.editMode && styles.greyBorderThin
            ]}
            key={"allergy_" + index}
          >
            <View
              style={[
                styles.addedItemView,
                !this.state.editMode && styles.cardRowInSp,
                this.state.editMode && styles.itemMgCom,
              ]}
            >
              {this.state.editMode ? (
                <View style={[styles.listItemCloseSecSm]}>
                  <View style={[styles.listItemCloseIcon, styles.closeIconSp]}>
                    <TouchableOpacity
                      onPress={() => {
                        this.setState({ confirmBox: true, selectedId: res.id });
                      }}
                      style={styles.closeTouchPd}
                    >
                      <Image
                        style={styles.closeDarkIcon}
                        source={require("../../assets/icons/close-dark.png")}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              ) : null}

              <View style={[styles.addedItemCont, styles.smPdItem]}>
                <Text style={[styles.textPrimeBold, styles.textGrey]} allowFontScaling={false}>
                  {res.allergy}
                </Text>
                {/* <Text
                  style={[styles.textPrimeExSm, styles.textGrey]}
                /> */}
              </View>
            </View>
          </View>
        ))}

        <ConfirmationBox
          visibleModal={this.state.confirmBox}
          onRequestClose={() => {
            this.setState({ confirmBox: false });
          }}
          onBackdropPress={() => {
            this.setState({ confirmBox: false });
          }}
          alertTitle="Delete Allergy"
          handleDelete={this.onDeleteAllergy}
          deleteWait={this.state.deleteWait}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAllergies: state.routines.userAllergies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAllergy: (id, callBack) =>
      dispatch(actions.deleteUserAllergy(id, callBack)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllergyList);
