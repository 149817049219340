export const DELETE = 'Delete';
export const EDIT = 'Edit';

export const DESCRIPTION = 'Description';
export const DESCRIPTION_MAX_LENGTH = 1000;

export const UTILITY_MED_AND_SUPPLIMENT = 'Meds/Supplements';
export const UTILITY_SYMPTOM = 'Symptoms';
export const UTILITY_HEALTH_DATA = 'Health Data';
export const UTILITY_DOCUMENT_AND_IMAGES = 'Documents/Images';
export const UTILITY_CUSTOM_NOTE = 'Custom Note';

export const EVENT_NAME = 'Event Name';
export const SEARCH_OR_CREATE_NEW_TAGS = 'Search or create new tag';
export const SEARCH_MY_EVENTS = 'Search My Events';

export const ADD_LISTENER_FOCUS = 'focus';
export const IMAGE_RESIZE_MODE = 'contain';
export const YES_OR_NO = 'Y/N';
export const ONE_TO_TEN = '1-10';
export const WINDOW_MOBILE_WIDTH_MARGIN = 1024;

export const eventTypes = {
    MEDICATIONS: "MEDICATIONS",
    SYMPTOMS: "SYMPTOMS",
    HEATHDATA: "HEATHDATA",
    ATTACHMENTS: "ATTACHMENTS",
    CUSTOM_NOTES: "CUSTOM_NOTES",
  };
  
  export const eventMonths = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  };
  
  export const eventPublishedBy = {
    PARTNER: 'PARTNER', 
    CAREGIVER: 'CAREGIVER',
    SELF: 'SELF',
  };

  export const READ_MORE = 'Read more';
  export const READ_LESS = 'Read less';

  export const START_DATE = 'Start Date';
  export const END_DATE = 'End Date'

  export const DELETE_CONFIRM_TITLE = "Are you sure you want to delete this event?";
  export const DELETE_CONFIRM_BODY = "Once an event is deleted it cannot be recovered.";
  export const DELETE_CONFIRM_BTN_CANCEL = "Cancel";
  export const DELETE_CONFIRM_BTN_DELETE = "Yes, Delete";


  export const MAX_FILE_SIZE_M = 157286400;
  export const MAX_FILE_SIZE_W = 262144000;

  export const setAttachmentIconToDisplay = (value, isFromAddAttachmentCategory = false) => {
    if (isFromAddAttachmentCategory) {
      if (value) {
        return require('../../assets/health-journey-icons/category-icon-blue.png');
      } else {
        return require('../../assets/health-journey-icons/category-icon.png');
      }
    } else {
      if (value) {
        return require('../../assets/health-journey-icons/attachment-icon-blue.png');
      } else {
        return require('../../assets/health-journey-icons/attachment-icon.png');
      }
    }
  }

export const months = [
  { label: 'Month', value: '-1', key: 'monthInit' },
  { label: 'Jan', value: '0', key: 'jan' },
  { label: 'Feb', value: '1', key: 'feb' },
  { label: 'Mar', value: '2', key: 'mar' },
  { label: 'Apr', value: '3', key: 'apr' },
  { label: 'May', value: '4', key: 'may' },
  { label: 'Jun', value: '5', key: 'jun' },
  { label: 'Jul', value: '6', key: 'jul' },
  { label: 'Aug', value: '7', key: 'aug' },
  { label: 'Sep', value: '8', key: 'sep' },
  { label: 'Oct', value: '9', key: 'oct' },
  { label: 'Nov', value: '10', key: 'nov' },
  { label: 'Dec', value: '11', key: 'dec' },
];