import React, {Component} from 'react';
import {Keyboard} from 'react-native';
import Signup from './SignUp';
import {DASHBOARD, OTHER} from '../../navigation/path';
import { pendoTrackEvent } from '../../util/pendoConfig';

class SignUpScreen extends Component {
  constructor(props) {
    super(props);
    let params = props.route.params;
    this.state = {
      accessCode: params?.accessCode,
      email: params?.email,
      firstName: params?.firstName,
      lastName: params?.lastName,
    };
  }

  componentDidMount() {
    pendoTrackEvent("SignUp@");
  }

  redrictDashboard = () => {
    let path = DASHBOARD.path;
    this.props.navigation.push(path);
  };

  redrictLogin = () => {
    Keyboard.dismiss();
    let path = OTHER.loginPath;
    this.props.navigation.push(path);
  };

  render() {
    return (
      <Signup
        redrictDashboard={this.redrictDashboard}
        redrictLogin={this.redrictLogin}
        accessCode={this.state.accessCode}
        email={this.state.email}
        firstName={this.state.firstName}
        lastName={this.state.lastName}
      />
    );
  }
}

export default (SignUpScreen);
