import React, { Component } from "react";
import { View, Text, Platform, Linking } from "react-native";
import {extractInformation} from "../../util/medications_and_supplements/healthDataParsingUtil";

import styles from "../../Css";
import { WEB } from "../../util/platformWindowConfig";


class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: null,
      ingredients: [],
    };
  }
  componentDidMount() {
    this.extractInformation(this.props.treatmentInfoValues);
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { treatmentInfoValues } = props;
    this.extractInformation(treatmentInfoValues);
  }
  extractInformation = (treatmentInfoValues) => {
    let extractedInformation = extractInformation(treatmentInfoValues);
    if (extractedInformation.route || extractedInformation.ingredients) {
      this.setState({
        route: extractedInformation.route,
        ingredients: extractedInformation.ingredients,
      });
    }
  };
  onPressTitle = (url) => {
    if (url && url !== "None") {
      if (Platform.OS === WEB) {
        window.open(url);
      } else {
        Linking.openURL(url);
      }
    }
  };

  render() {
    return (
      <View style={[styles.collapseContent, 
       styles.colPdLtRt
       ]}>
        <View style={styles.medicalInfoWrapper}>
          <View style={styles.medicalInfoContent}>
            <View style={[styles.medicalInfoDesc, styles.comSpSm]}>
              <Text style={[styles.textPrimeExSm, styles.textColorLight]} allowFontScaling={false}>
                Information provided using the Government of Canada{" "}
                {/* <TouchableOpacity
                  onPress={() =>
                    this.onPressTitle(
                      "https://health-products.canada.ca/dpd-bdpp/index-eng.jsp"
                    )
                  }
                > */}
                <Text
                  style={styles.textInkBlue}
                  allowFontScaling={false}
                  onPress={() =>
                    this.onPressTitle(
                      "https://health-products.canada.ca/dpd-bdpp/index-eng.jsp"
                    )
                  }
                >
                  drug product database
                </Text>
                {/* </TouchableOpacity> */}
                <Text allowFontScaling={false}>
                  . You should always consult your doctor or pharmacist for drug
                  information
                </Text>
              </Text>
            </View>

            <View style={[styles.medicalInfoTitle, styles.comSpMd]}>
              <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>
                Route(s) of administration:{" "}
                <Text style={styles.textColorLight} allowFontScaling={false}>{this.state.route}</Text>
              </Text>
            </View>

            <View style={[styles.tableBlock, styles.comSpSm]}>
              <View style={[styles.tableView]}>
                <View style={[styles.trTitleView]}>
                  <View
                    style={[
                      styles.tdColView,
                      styles.tdColRtBorder,
                      styles.tdColLgView,
                    ]}
                  >
                    <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>
                      Active Ingredients
                    </Text>
                  </View>
                  <View style={[styles.tdColView, styles.tdColSmView]}>
                    <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>Strength</Text>
                  </View>
                </View>
                {this.state.ingredients.map((item, index) => (
                  <View
                    style={[styles.trDataView]}
                    key={"information_item" + index}
                  >
                    <View
                      style={[
                        styles.tdColView,
                        styles.tdColRtBorder,
                        styles.tdColLgView,
                      ]}
                    >
                      <Text
                        style={[styles.textPrimeSmBold, styles.textColorLight]}
                        allowFontScaling={false}
                      >
                        {item.ingredient}
                      </Text>
                    </View>
                    <View style={[styles.tdColView, styles.tdColSmView]}>
                      <Text
                        style={[styles.textPrimeSmBold, styles.textColorLight]}
                        allowFontScaling={false}
                      >
                        {item.strength + " "} {item.strengthUnit}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default (Information);
