import * as variable from "./base/variable";
import * as variableShadow from './base/variableShadow';
import theme from "./base/theme";
export const cssModal = {
  popUpContentPd: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  popUpContentPdSm: {
    paddingTop: 20,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 20,
  },
  popUpBlock: {
    width: "100%",
    ...variable.marginLtRtAuto,
  },
  popUpContentArea: {
    position: "relative",
  },
  mgTopPopUpForClose: {
    marginTop: -20,
    zIndex: 9,
  },
  popUpRad: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
  },
  notifyCircleMgTop: {
    marginTop: 5,
  },
  colPdNotify: {
    paddingTop: 10,
    paddingLeft: 20,
    paddingBottom: 10,
    paddingRight: 20,
  },
  notifyDescLtPd: {
    paddingLeft: 20,
    paddingBottom: 5,
  },
  modalLayoutCom: {
    ...variable.marginAllAuto,
    maxHeight: "100%",
    width: 310,
  },
  popUpContentPdAll: {
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  popUpContentPdLg: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  popUpContentPdRgSm: {
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5,
  },
  modalBg: {
    resizeMode: "contain",
    width: 125,
    height: 125,
  },
  mainImgWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  mainContWrapper: {
    minHeight: 125,
    paddingTop: 10,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  }, 
  popMessageDecView: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 15,
    paddingBottom: 15,
  },
  popMessageDecViewFull: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  textLightBlue: {
    color: '#008FA1'
  },
  darkBtnBorder: {
    borderColor: theme.COLOR_GREY_DARK_1,
    borderWidth: 1,
  },
  attachmentBtnBorder: {
    borderColor: theme.COLOR_GREY_DARK_1,
    borderWidth: 2,
  },
  popMessageComView: {
    ...variable.marginLtRtAuto,
    width: "100%",
    maxWidth: 225,
  },
  popMessageSmView: {
    ...variable.marginLtRtAuto,
    width: "100%",
    maxWidth: 220,
  },
  popMessageLgView: {
    ...variable.marginLtRtAuto,
    width: "100%",
    maxWidth: 260,
  },
  popMessageMainView: {
    paddingBottom: 15,
    paddingTop: 15,
  },
  comfirmModalMin: {
    minHeight: 250,
  },
  popMessageSmComView: {
    ...variable.marginLtRtAuto,
    width: "100%",
  },
  dashboardPopUpTxt: {
    color: theme.COLOR_BLACK,
    lineHeight: 22
  },
  dashboardPopUpHighlight: {
    color:theme.COLOR_BLUE
  },
  dashboardPopUpBt: {
    maxWidth: 245
  },
  dashboardPopUpPd: {
    paddingTop: 10,
    paddingBottom:25
  },
  dashboardPopUpBtn: {
    paddingTop:32,
    paddingBottom:20
  },
  popUpGreenRightIcon: {
    position:'absolute',
    right:15, 
    top:14
  },
  joinBtnAlignment: {
    justifyContent:'center', 
    alignItems:'center'
  },
  joinStudyIndicator: {
    top:-7,
    right:-10
  },
  successboxRoundCorners: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 8,
  },
  noRoundCorners: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
  healthResourceModalWrapper: {
    position:'relative', 
    bottom:10
  },
  
  healthResourceContent: {
    alignItems:'center', 
    lineHeight:11, 
    marginTop:14, 
    maxWidth:157, 
    marginRight:'auto', 
    marginLeft:'auto', 
    textAlign:'center',
    justifyContent:'center'
  },

  twoThirtyNineWidth:{
    width:239
  },

  eigthBrdRad:{
    borderTopLeftRadius:8,
    borderBottomRightRadius:8,
    borderTopRightRadius:8,
    borderBottomLeftRadius:8
  },

  mdBtnHexo:{
    width:134, 
    height:41,
  },

  mdBtnHexoTxt:{
    position:'relative', 
    bottom:1
  },

  cancelBtnHexo:{
    ...variableShadow.noteBoxShadow,
    backgroundColor:theme.COLOR_CARROT
  },

  crossIcnBtnSm:{
    position:'absolute', 
    right:-5, 
    top:-7
  },

  twoThirtyWidth: {
    width:230
  },

  closeBtnResDel: {
    width: "100%", 
    alignItems: "flex-end", 
    top: 10, 
    right: 10
  }
};
