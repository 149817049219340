import * as axiosCommon from '../../axios/axios-common';
import * as url from './urls';
import * as actionTypes from './actionTypes';
import {logout, addHealthQuestion, commonErrorHandler} from './index';
import { getMedicationUnits } from '../../util/commonUiLogic';

//Create an empty user treatment

export const createTherapie = (addNewTherapie, callBack, journalUserId) => {
  let requestUrl = url.ADD_THERAPIE;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(addTherapieSuccess(res.responseDto.data.treatment, true));
          // dispatch(addTherapie(res.responseDto.data));

          callBack(true);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; createTherapie");
            dispatch(logout());
          } else {
            // dispatch(addTherapieFailed(res.errorDto.data.error));
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false);
          }
        }
      },
      addNewTherapie,
    );
  };
};

export const addTherapie = data => {
  return {
    type: actionTypes.ADD_THERAPIE,
    treatment: data.treatment,
  };
};

export const updateTreatments = data => {
  return {
    type: actionTypes.UPDATE_THERAPIE,
    treatment: data.treatment ? data.treatment : data,
  };
};

export const addTherapieSuccess = (data, isNew) => {
  return {
    type: actionTypes.ADD_THERAPIE_SUCCESS,
    newTherapie: data,
    isNew: isNew,
  };
};

export const addTherapieFailed = errorMsg => {
  return {
    type: actionTypes.ADD_THERAPIE_FAIL,
    errMessage: errorMsg,
  };
};

export const resetNewTherapie = () => {
  return dispatch => {
    dispatch(addTherapieSuccess(null, false));
  };
};
//Delete an existing user treatment

export const deleteTherapie = (therapieId, callBack, journalUserId) => {
  let requestUrl = url.DELETE_THERAPIE + '?treatmentId=' + therapieId;
  if (journalUserId) {
    requestUrl += '&journalUserId=' + journalUserId;
  }
  return dispatch => {
    dispatch(setDeleteTherapieInProgress(true));
    axiosCommon.axiosDELETE(requestUrl, res => {
      if (res.type === 'SUCCESS') {
        dispatch(deleteTherapieSuccess(therapieId));
        dispatch(setDeleteTherapieInProgress(false));
        callBack(true);
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; deleteTherapie");
          dispatch(logout());
        } else {
          dispatch(deleteTherapieFailed(res.errorDto.data.error));
          dispatch(setDeleteTherapieInProgress(false));
          callBack(false);
        }
      }
    });
  };
};

export const deleteTherapieSuccess = therapieId => {
  return {
    type: actionTypes.DELETE_THERAPIE_SUCCESS,
    therapieId: therapieId,
  };
};

export const deleteTherapieFailed = errorMsg => {
  return {
    type: actionTypes.DELETE_THERAPIE_FAIL,
    errorDescription: errorMsg,
  };
};
export const setDeleteTherapieInProgress = inProgress => {
  return {
    type: actionTypes.DELETE_THERAPIE_IN_PROGRESS,
    deleteTherapieInProgress: inProgress,
  };
};
//Save an existing or new user treatment

export const updateTherapie = (updateTherapie, callBack, journalUserId) => {
  let requestUrl = url.UPDATE_THERAPIE;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          if (updateTherapie.treatment.id === -1) {
            dispatch(addTherapieSuccess(res.responseDto.data.treatment, true));
            dispatch(addTherapie(res.responseDto.data));
            callBack(true, res.responseDto.data.treatment);
          } else {
            dispatch(updateTherapieSuccess(res.responseDto.data));
            dispatch(setPopupTreatment(res.responseDto.data.treatment));
            // dispatch(
            //   fetchUserAttachments(res.responseDto.data.treatment.artifactId)
            // );
            dispatch(updateTreatments(res.responseDto.data));
            callBack(true, res.responseDto.data.treatment);
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateTherapie");
            dispatch(logout());
          } else {
            //     dispatch(updateTherapieFailed(res.errorDto.data.error));
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data);
          }
        }
      },
      updateTherapie,
    );
  };
};

export const updateTherapieSuccess = data => {
  return {
    type: actionTypes.UPDATE_THERAPIE_SUCCESS,
    selectedTherapie: data.treatment,
  };
};

export const updateTherapieFailed = errorMsg => {
  return {
    type: actionTypes.UPDATE_THERAPIE_FAIL,
    errMessage: errorMsg,
  };
};

export const setPopupTreatment = data => {
  return {
    type: actionTypes.SET_POPUP_TREATMENT,
    popupTreatment: data,
  };
};

//Update the graph display properties for a user treatment

export const updateGraphDisplayProperties = (
  updateGraphDisplay,
  journalUserId,
  callBack,
) => {
  let requestUrl = url.UPDATE_GRAPH_DISPLAY;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(updateGraphDisplaySuccess(res.responseDto.data));
          if (callBack) {
            callBack(res.responseDto.data);
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateGraphDisplayProperties");
            dispatch(logout());
          } else {
            dispatch(updateGraphDisplayFailed(res.errorDto.data.message));
          }
        }
      },
      updateGraphDisplay,
    );
  };
};

export const updatesaveDisable = flag => {
  return {
    type: actionTypes.UPDATE_SAVE_DISABLE,
    saveDisable: flag,
  };
};

export const updateGraphDisplaySuccess = data => {
  return {
    type: actionTypes.UPDATE_GRAPH_DISPLAY_SUCCESS,
    successMsg: data.message,
  };
};

export const updateGraphDisplayFailed = errorMsg => {
  return {
    type: actionTypes.UPDATE_GRAPH_DISPLAY_FAIL,
    errMessage: errorMsg,
  };
};

//Add Health Questions

export const createHealthQuestion = (
  addQuestions,
  questionDigest,
  artifactId,
  questionDigestProcessed,
  callBack,
  journalUserId,
) => {
  let requestUrl = url.UPDATE_HEALTH_QUESTION;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(createQuestionSuccess(res.responseDto.data));
          dispatch(
            addHealthQuestion(
              res.responseDto.data.healthQuestion,
              questionDigest,
              questionDigestProcessed,
              artifactId,
            ),
          );
          callBack(res.responseDto.data);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; createHealthQuestion");
            dispatch(logout());
          } else {
            dispatch(createQuestionFailed(res.errorDto.data.message));
          }
        }
      },
      addQuestions,
    );
  };
};

export const createQuestionSuccess = data => {
  return {
    type: actionTypes.UPDATE_HEALTH_QUESTION_SUCCESS,
    successMsg: data,
  };
};

export const createQuestionFailed = errorMsg => {
  return {
    type: actionTypes.UPDATE_HEALTH_QUESTION_FAIL,
    errMessage: errorMsg,
  };
};

export const selectTherapieSuccess = selectedTherapie => {
  return {
    type: actionTypes.SELECT_THERAPIE,
    selectedTherapie: selectedTherapie,
  };
};

export const selectTherapie = (selectedTherapie, callBack) => {
  return dispatch => {
    dispatch(selectTherapieSuccess(selectedTherapie));
    callBack();
  };
};

export const onPauseMedication = (
  selectedTherapie,
  timeStamp,
  days,
  callBack,
) => {
  const newTherapie = selectedTherapie;
  newTherapie.pauseDate = timeStamp;
  newTherapie.pauseDuration = days;
  newTherapie.paused = true;
  newTherapie.isPaused = true;
  newTherapie.active = true;
  newTherapie.isActive = true;

  const re = {
    treatment: newTherapie,
  };
  return (dispatch, state) => {
    let journalUserId = state().uistate?.journalUserId;
    dispatch(updateTherapie(re, callBack, journalUserId));
  };
};

export const onResumeMedication = (selectedTherapie, active, callBack) => {
  const newTherapie = selectedTherapie;
  newTherapie.paused = false;
  newTherapie.isPaused = false;
  newTherapie.pauseDate = null;
  newTherapie.pauseDuration = null;
  newTherapie.active = active;
  newTherapie.isActive = active;
  const re = {
    treatment: newTherapie,
  };
  return (dispatch, state) => {
    let journalUserId = state().uistate?.journalUserId;
    dispatch(updateTherapie(re, callBack, journalUserId));
  };
};

export const onActive = (selectedTherapie, val, callBack) => {
  const newTherapie = selectedTherapie;

  newTherapie.active = val;
  newTherapie.isActive = val;

  const re = {
    treatment: newTherapie,
  };
  return (dispatch, state) => {
    let journalUserId = state().uistate?.journalUserId;
    dispatch(updateTherapie(re, callBack, journalUserId));
  };
};

export const editTherapie = (treatment, callBack) => {
  return dispatch => {
    dispatch(addTherapieSuccess(treatment));
    callBack();
  };
};

export const systemDefinedActivities = callBack => {
  return dispatch => {
    axiosCommon.axiosGET(url.GET_SYSTEM_DEFINED_ACTIVITIES, res => {
      if (res.type === 'SUCCESS') {
        callBack(true, res.responseDto.data);
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; systemDefinedActivities");
          dispatch(logout());
        } else if (res.errorDto !== 'undefined') {
          dispatch(commonErrorHandler(res.errorDto.data.error));
          callBack(false, res.errorDto.data.error);
        }
      }
    });
  };
};

export const hasEntryActivityInvalidNames = (hasActivityInvalidNames) => {
  return {
    type: actionTypes.HAS_ENTRY_CUSTOM_ACTIVITY_INVALID_NAMES,
    hasInvalidNames: hasActivityInvalidNames,
  };
};

export const hasEntryMedicationsInvalidNames = (hasMedicationsInvalidNames) => {
  return {
    type: actionTypes.HAS_ENTRY_CUSTOM_MEDICATION_INVALID_NAMES,
    hasInvalidNames: hasMedicationsInvalidNames,
  };
};

export const hasEntryHealthDataInvalidNames = (hasHealthDataInvalidNames) => {
  return {
    type: actionTypes.HAS_ENTRY_CUSTOM_HEALTH_DATA_INVALID_NAMES,
    hasInvalidNames: hasHealthDataInvalidNames,
  };
};

export const hasEntrySymptomInvalidNames = (hasSymtomInvalidNames) => {
  return {
    type: actionTypes.HAS_ENTRY_CUSTOM_SYMPTOMS_INVALID_NAMES,
    hasInvalidNames: hasSymtomInvalidNames,
  };
};


export const hasEntryActivityDuplicates = (hasActivityDuplicates) => {
  return {
    type: actionTypes.HAS_ENTRY_CUSTOM_ACTIVITY_DUPLICATE,
    hasDuplicates: hasActivityDuplicates,
  };
};

export const hasEntryMedicationsDuplicate = (hasMedicationsDuplicate) => {
  return {
    type: actionTypes.HAS_ENTRY_CUSTOM_MEDICATION_DUPLICATE,
    hasDuplicates: hasMedicationsDuplicate,
  };
};

export const resetDuplicates = () => {
  return {
    type: actionTypes.RESET_DUPLICATES,
    hasDuplicates: false,
  };
};

export const getTreatmentInfo = (din_npn, treatmentType, callBack, unit = null, units = [], drugAdministrationsDigits, displayFullName = false, selectedUnit = null) => {
  let requestUrl = url.GET_TREATMENT_INFO.replace('{din_npn}', din_npn).replace('{treatmentType}', treatmentType);
  return dispatch => {
    axiosCommon.axiosGET(requestUrl, res => {
      if (res.type === 'SUCCESS') {
        const {data} = res.responseDto;
        const {value} = data;
        const {drugFormat} = value || {};

        let drugStates = null;
        if (value && drugFormat && drugFormat.length > 0) {
          drugStates = drugFormat[0].drugStates;
        }

        let unitList = [];

        const {drugAdministrations} = drugStates || {};
        if (drugStates && drugAdministrations && drugAdministrations.length > 0) {
          unitList = getMedicationUnits(drugAdministrations, drugAdministrationsDigits, displayFullName);
        } else {
          unitList = units;
        }
        if (!unit) {
          const foundTablet = unitList.find(res => res.value === 'Tablets');
          unit = selectedUnit ? selectedUnit : foundTablet ? 'Tablets' : unitList && unitList[0] && unitList[0].value ? unitList[0].value : null;
        }
        callBack(true, {unitValue: unit, unitList: unitList, value: value});
      } else {
        callBack(false);
      }
    });
  };
}

