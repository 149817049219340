import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import RoutinePopupMenu from '../../../components/PopupMenu/routinePopupMenu';
import ReminderHeader from '../../../components/RoutineTemplate/Reminder/ReminderHeader';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import _ from 'lodash';
import { pendoTrackEvent } from '../../../util/pendoConfig';
import {
  filterMetrics,
  filterSymptoms,
  filterTreatments,
} from '../../LoadingScreen/DashboardLoadingScreenUtil';

class RoutineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      defaultMetrics: filterMetrics(props.entry, props.metricsDigestProcessed),
      defaultTreatments: filterTreatments(props.entry, props.treatmentsProcessed),
      defaultSymptoms: filterSymptoms(props.entry, props.symptomsDigestProcessed),
      showOnDashboard: props.entry ? props.entry.showOnDashboard : true,
    };
    this.tapCount = 0;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) !== JSON.stringify(this.state)) return true;
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) return true;
    return false;
  }

  countOccurrences = () => {
    const {journalEntriesProcessed, entry} = this.props;
    let count = 0;

    Object.values(journalEntriesProcessed).forEach(element => {
      if (element.entryType === entry.id) count += 1;
    });

    return count;
  };

  handleDeleteRoutine = () => {
    const {entry, handleDeleteRoutine} = this.props;
    handleDeleteRoutine(entry.id);
  };

  handleEditRoutine = () => {
    const {handleEdit, entry} = this.props;
    const {defaultMetrics, defaultSymptoms, defaultTreatments} = this.state;
    const newEntry = {
      ...entry,
      defaultMetrics,
      defaultSymptoms,
      defaultTreatments,
    };
    this.tapCount = this.tapCount + 1;
    if (this.tapCount === 1) {
      pendoTrackEvent('Routines@ > 3Dots > Edit');
      handleEdit(newEntry);
    }
  };

  handleCopyToNew = () => {
    const {handleEdit, entry} = this.props;
    const {defaultMetrics, defaultSymptoms, defaultTreatments} = this.state;
    const convertToSelf = (array, containerKey) => (array || []).map(item => { let clonedItem = _.cloneDeep(item); clonedItem[containerKey].publishedBy = 'SELF'; return clonedItem;});
    
    const newEntry = {
      ...entry,
      name: "Copy of " + entry.name,
      id: -1,
      defaultMetrics: convertToSelf(defaultMetrics, 'metricRef'),
      defaultSymptoms: convertToSelf(defaultSymptoms, 'symptomObj'),
      defaultTreatments: convertToSelf(defaultTreatments, 'treatmentObj'),
      reminders: entry?.reminders && entry?.reminders.length > 0 && entry.reminders.map(r => { let clonedItem = _.cloneDeep(r); clonedItem.publishedBy = 'SELF'; return clonedItem; }),
      copied: true,
      journalPublishedBy: 'SELF', //copying even a partner portal published rutine should be copied as user created
      isCopyToNew: true,
    };
    this.tapCount = this.tapCount + 1;
    if (this.tapCount === 1) {
      pendoTrackEvent('Routines@ > 3Dots > CopytoNew');
      handleEdit(newEntry);
    }
  };

  handleHideShowonDashboard = () => {
    this.tapCount = this.tapCount + 1;
    const {entry, journalUserId, createRoutine} = this.props;
    const {showOnDashboard} = this.state;
    const newEntry = {
      journalEntryType: {...entry, showOnDashboard: !showOnDashboard},
    };

    if (this.tapCount === 1) {
      if (showOnDashboard) {
        pendoTrackEvent('Routines@ > 3Dots > UnpinFromDashboard');
      }
      createRoutine(newEntry, journalUserId, success => {
        if (success) {
          this.setState(prevState => ({
            showOnDashboard: !prevState.showOnDashboard,
          }));
        }
        this.tapCount = 0;
      });
    }
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {
      defaultMetrics,
      defaultSymptoms,
      defaultTreatments,
      isOpen,
      showOnDashboard
    } = this.state;
    const {isActive, entry} = this.props;
    const {name, color, journalPublishedBy, icon_c, type, reminders} = entry;
    const defaultMedications = defaultTreatments.filter(res => res?.treatmentObj?.treatmentType !== 5);
    const defaultActivities = defaultTreatments.filter(res => res?.treatmentObj?.treatmentType === 5);

    const itemView = (title, itemCount, content) => {
      return (
        <View style={styles.contentBoxSingle}>
          <View style={styles.contentBoxView}>
            <View
              style={[styles.contentBoxTitle, styles.greyBorderThin]}>
              <Text style={styles.textPrimeBold} allowFontScaling={false}>
                {title + ' (' + itemCount + ')'}
              </Text>
            </View>
            <View style={styles.contentBoxDataAll}>
              {content}
            </View>
          </View>
        </View>
      );
    }

    const singleItem = (element, index) => {
      return (
        <View
          style={[styles.contentItem, styles.flexJcSb, styles.greyBorderThin]}
          key={index}>
          <View style={[styles.flexCom, styles.flexRow, styles.wrapperPdLt]}>
            <Text style={[styles.textPrime, styles.textQuest]} allowFontScaling={false}>{'\u2022'}</Text>
            <Text style={[styles.textPrime]} allowFontScaling={false}>{element.name}</Text>
          </View>
        </View>
      );
    }

    const metrics = defaultMetrics.map((element, index) => (
      singleItem(element, index)
    ));

    const symptoms = defaultSymptoms.map((element, index) => (
      singleItem(element, index)
    ));

    const medications = defaultMedications.map((element, index) => (
      singleItem(element, index)
    ));

    const activities = defaultActivities.map((element, index) => (
      singleItem(element, index)
    ));

    return (
      <View style={[styles.menuSingleCom, styles.barBotSp]}>
        <View
          style={[
            styles.menuSingleBar,
            styles.menuBgWhite,
            styles.menuItemView,
          ]}>
          <TouchableOpacity
            disabled={!isActive}
            style={[styles.menuSingleMain]}
            onPress={() => {
              if(!this.state.isOpen) {
                pendoTrackEvent('Routines@ > ViewRoutine');
              }
              this.setState({isOpen: !this.state.isOpen});
            }}
            nativeID={'routinesViewRoutine'}>

            {/* If Default - start*/}
            {entry.deviceName === 'HEXOSKIN' ? 
              <View
                style={[
                  styles.menuSingleIconSpecBlock,
                  styles.barPd,
                  this.state.isOpen && styles.noBotRad,
                  !isActive && styles.inActiveBg,
                ]}>
              <View style={[styles.menuSingleIcon]}>
                <Image
                  style={styles.menuSpecIcon}
                  source={require('../../../assets/devices/hexoskin.png')}
                />
              </View>
            </View>
            :
              <View
                style={[
                styles.menuSingleIconBlock,
                styles.barPd,
                ]}>
                <View style={[
                  styles.journalEntryNwBx,
                  styles.flexJcCt,
                  styles.flexAiCt,
                  {backgroundColor: color}
                ]}>
                  <Image
                    style={icon_c ? styles.journalEntryNwBx : styles.routineIcon}
                    source={icon_c ? {uri: icon_c} : require('../../../assets/journal-icons/report.png')}
                  />
                </View>
              </View>
            }
            <View style={styles.menuSingleContBlock}>
              <View style={[styles.flexCom, styles.menuCont]}>
                <View style={styles.flexCom}>
                  <Text style={styles.textPrime} allowFontScaling={false}>{name}</Text>
                </View>
                {type !== "DEVICE" && isActive && showOnDashboard &&
                  <Image
                    style={[{width:12, height: 18}]}
                    source={require("../../../assets/icons/pin-grey.png")}
                  />
                }
                {isActive && (
                  <View style={[styles.arrowSelect, styles.flexJcCtAiCt]}>
                    <Image
                      style={[
                        this.state.isOpen
                          ? styles.arrowOpen
                          : styles.arrowClose,
                      ]}
                      source={
                        this.state.isOpen
                          ? require('../../../assets/icons/arrow-down-orange.png')
                          : require('../../../assets/icons/arrow-next.png')
                      }
                    />
                  </View>
                )}
              </View>
            </View>
          </TouchableOpacity>
          <View style={[styles.dataIconSecSm, styles.secCenter]}>
            {Boolean(type !== "DEVICE") ?
              <View style={styles.iconListDefault}>
                <RoutinePopupMenu
                  handleDeleteJournal={this.handleDeleteRoutine}
                  handleEditJournal={this.handleEditRoutine}
                  handleCopyToNew={this.handleCopyToNew}
                  canEdit={type !== "DEVICE"}
                  canDelete={journalPublishedBy !== 'PARTNER'}
                  canShowHide={type !== "DEVICE" && isActive && journalPublishedBy !== 'PARTNER'}
                  handleHideShowonDashboard={this.handleHideShowonDashboard}
                  hideShow={showOnDashboard}
                  nativeIdMenuTrigger={'routines3Dots'}
                  eventNameMenuTrigger={'Routines@ > 3Dots'}
                  nativeIdEdit={'routines3DotsEdit'}
                  nativeIdDelete={'routines3DotsDelete'}
                  nativeIdCopytoNew={'routines3DotsCopytoNew'}
                  nativeIdPinToUnpinFromDashboard={'routines3DotsPinToUnpinFromDashboard'}
                />
              </View>
            : <View style={styles.itemSettingMenu} />
            }
          </View>
        </View>

        {isOpen && (
          <View style={[styles.menuOpenBlock]}>
            <View
              style={[
                styles.menuOpenDetails,
                styles.bgWhite,
                styles.menuBlockOpenView,
                styles.contPdTop,
              ]}>
                <View
                  style={[
                    styles.colPdLtRt,
                    styles.borderSpSm,
                    styles.greyBorderThin,
                  ]}>
                  <View style={stylesRes.contentBoxFullView}>
                    <View style={[styles.titleArea]}>
                      <Text style={[styles.textPrime, styles.defaultText]} allowFontScaling={false}>
                        This routine has{' '}
                        <Text
                          style={[styles.textPrimeBold, styles.darkLinktext]} allowFontScaling={false}>
                          {this.countOccurrences()}
                        </Text>{' '}
                      {this.countOccurrences() > 1 ? 'recordings' : 'recording'}
                      </Text>
                    </View>
                  </View>
                </View>
              <ReminderHeader reminders={reminders}/>
              <View style={[styles.colPdLtRt, styles.contPdTopSmall]}>
                <View style={stylesRes.contentBoxFullView}>
                  {itemView('Medications/Supplements', defaultMedications.length, medications)}
                  {itemView('Activities', defaultActivities.length, activities)}
                  {itemView('Symptoms', defaultSymptoms.length, symptoms)}
                  {itemView('Health Data', defaultMetrics.length, metrics)}
                </View>
              </View>
            </View>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    symptomsDigestProcessed: state.routines.symptomsDigestProcessed,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createRoutine: (payload, journalUserId, callBack) =>
      dispatch(
        actions.updateJournalEntryType(payload, journalUserId, callBack),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoutineItem);
