import axios from "../../axios/axios-config-auth";
import qs from "qs";
import * as url from "./urls";
import { logout, authStart, authFail, authSuccess } from "./auth";
import Cookies from "js-cookie";
import {
  isMobile,
  isTablet,
  osName,
  osVersion,
  deviceType,
  mobileVendor,
  browserName,
  browserVersion
} from "react-device-detect";
import { v1 as uuid } from 'uuid';

import {moveToEmailVerificationPhase} from "./auth.web";
export const signup = (user, staySigned, shallow, callBack) => {
  return dispatch => {
    dispatch(authStart());
    let devId = localStorage.getItem("x-uuid-id-ssn");
    if (!devId) {
      devId = uuid();
      localStorage.setItem("x-uuid-id-ssn", devId);
    }

    let deviceInfo = {
      deviceId: devId,
      isBrowser: true,
      isMobile: isMobile,
      isTablet: isTablet,
      osName: osName,
      browserName: browserName,
      browserVersion: browserVersion,
      brand: mobileVendor,
      device: osVersion,
      deviceType: deviceType
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Auth-Info": JSON.stringify(deviceInfo)
      }
    };

    axios
      .post(
        url.LOGIN_COGNITO,
        qs.stringify({
          token: user.signInUserSession.idToken.jwtToken,
          stay: staySigned
        }),
        axiosConfig
      )
      .then(response => {
        Cookies.set("authToken", response.data.authToken);
        Cookies.set("jwtToken", user.signInUserSession.idToken.jwtToken);
        if (!shallow) {
          dispatch(authSuccess());
        } else {
          dispatch(moveToEmailVerificationPhase(false, user))
        }
        // dispatch(keepAlive());
        callBack(true);
      })
      .catch(error => {
        if (error.response === undefined) {
          console.log("error undefined; signup");
          dispatch(logout());
        } else {
          dispatch(authFail(error));
          callBack(false);
        }
      });
  };
};
