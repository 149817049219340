import * as axiosCommon from "../../axios/axios-common";
import * as url from "./urls";
import * as actionTypes from "./actionTypes";
import { logout, commonErrorHandler, setFinshedSchedules, pullSchedulars } from "./index";
import { APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED } from '../../util/featureConfig';

export const getAllUserHealthSurvey = (journalUserId, callBack) => {
  let requestUrl = url.USER_HEALTH_SURVEY;
  return (dispatch, getState) => {
    let requestData = {};
    if (getState().uistate?.journalUserId) {
      requestData = { ...requestData, journalUserId: getState().uistate?.journalUserId };
    }

    axiosCommon.axiosGET(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          callBack(true, res.responseDto.data);
          
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; getAllUserHealthSurvey");
            dispatch(logout());
          } else {
            callBack(false);
            dispatch(commonErrorHandler(res.errorDto.data.error));
          }
        }
      },
      requestData
    );
  };
};

export const getUserHealthSurveyById = (id, journalUserId, callBack) => {
  let requestUrl = url.HEALTH_SURVEY + "/" + id;
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosGET(requestUrl, res => {
      if (res.type === "SUCCESS") {
        dispatch(setSurveyQuestions(res.responseDto.data));
        if(callBack){
          callBack(true, res.responseDto.data);
        }
      } else {
        if (res.errorDto === "SESSION_TIME_OUT") {
          console.log("session time out; getUserHealthSurveyById");
          dispatch(logout());
        } else {
          if(callBack){
            callBack(false);
          }
          dispatch(commonErrorHandler(res.errorDto.data.error));
        }
      }
    });
  };
};

export const selectSurvey = (id, surveyId, name, isClosed,
  modifiedOn, description, duration = null,
  s3ImageUrl = null, surveyStatus = null, questionOrder = 0
) => {
  return {
    type: actionTypes.SELECT_SURVEY,
    id: id,
    surveyId: surveyId,
    name: name,
    isClosed: isClosed,
    modifiedOn: modifiedOn,
    description: description,
    duration,
    s3ImageUrl,
    surveyStatus,
    questionOrder
  };
};

export const setSurveyQuestions = data => {
  return {
    type: actionTypes.SET_SURVEY_QUESTIONS,
    data: data
  };
};

export const addRemoveSurveyChoiceQuestionsAnswer = (
  questionId,
  answersId,
  add
) => {
  return {
    type: actionTypes.ADD_REMOVE_SURVEY_CHOICE_QUESTIONS_ANSWER,
    answersId: answersId,
    questionId: questionId,
    add: add
  };
};

export const addRemoveSurveySingleQuestionsAnswer = (questionId, value) => {
  return {
    type: actionTypes.ADD_REMOVE_SURVEY_SINGALE_QUESTIONS_ANSWER,
    value: value,
    questionId: questionId
  };
};

export const saveSurveyAnswer = (data, journalUserId, callBack) => {
  let requestUrl = url.HEALTH_SURVEY_ANSWER;
  // if (journalUserId) {
  //   requestUrl += "?journalUserId=" + journalUserId;
  // }
  return (dispatch, getState) => {
    const _journalUserId = getState().uistate?.journalUserId || null;
    if (_journalUserId) {
      requestUrl = `${requestUrl}?journalUserId=${_journalUserId}`
    }

    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          //   dispatch(createJournalEntryByTypeSuccess(res.responseDto.data));
          callBack(true);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; saveSurveyAnswer");
            dispatch(logout());
          } else {
            callBack(false);
            dispatch(commonErrorHandler(res.errorDto.data.message));
          }
        }
      },
      data
    );
  };
};
export const getUserHealthSurveyAnswersById = (id, journalUserId, callBack) => {
  let requestUrl = url.GET_HEALTH_SURVEY_ANSWERS + "/" + id;
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosGET(requestUrl, res => {
      if (res.type === "SUCCESS") {
        dispatch(setSurveyAnswers(res.responseDto.data));
        callBack(true, res.responseDto.data);
      } else {
        if (res.errorDto === "SESSION_TIME_OUT") {
          console.log("session time out; getUserHealthSurveyAnswersById");
          dispatch(logout());
        } else {
          callBack(false);
          dispatch(commonErrorHandler(res.errorDto.data.error));
        }
      }
    });
  };
};

export const setSurveyAnswers = data => {
  return {
    type: actionTypes.SET_SURVEY_ANSWERS,
    data: data
  };
};

export const resetSurvey = () => {
  return {
    type: actionTypes.RESET_SURVEY
  };
};

export const setActiveTab = activeTabIndex => {
  return {
    type: actionTypes.SET_ACTIVE_TAB,
    activeTab: activeTabIndex
  };
};

export const fetchSurveyQuestion = (surveyId, surveyQuestionId = null, notificationId, surveyStatus, questionOrder, questionDirection) => {
  return (dispatch, state) => {
    let requestUrl = url.GET_HEALTH_SURVEY_QUESTION
      .replace('{surveyID}', surveyId)
      .replace('{notificationID}', notificationId);

    let reqData = {};
    if (surveyQuestionId) {
      reqData = { ...reqData, qid: surveyQuestionId };
    } else if (surveyStatus && surveyStatus === 'PROCESSING') {
      reqData = { ...reqData, status: surveyStatus.toLowerCase(), questionOrder: questionOrder };
    } else if (questionOrder && questionDirection) {
      reqData = { ...reqData, currentQuestionOrderNumber: questionOrder,  transitionDirection: questionDirection};
    }

    let journalUserId = state().uistate?.journalUserId || null;
    if (journalUserId) {
      reqData = { ...reqData, journalUserId };
    }

    dispatch(saveHealthSurveyAnswerReset());
    dispatch({
      type: actionTypes.FETCH_SURVEY_QUESTION,
    });

    axiosCommon.axiosGET(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          dispatch({
            type: actionTypes.FETCH_SURVEY_QUESTION_SUCCESS,
            payload: res.responseDto.data,
          });
          dispatch(setAnsweredRatio(true, res.responseDto.data.answeredRatio));
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; fetchSurveyQuestion");
            dispatch(logout());
          } else {
            dispatch({
              type: actionTypes.FETCH_SURVEY_QUESTION_FAIL,
              payload: 'Failed to fetch survey question',
            });
            dispatch(commonErrorHandler(res.errorDto.data.message || 'Failed to fetch question'));
          }
        }
      },
      reqData
    );
  }
};

export const saveHealthSurveyAnswer = (answer, journalUserId) => {
  let requestUrl = url.HEALTH_SURVEY_ANSWER;
  // if (journalUserId) {
  //   requestUrl += "?journalUserId=" + journalUserId;
  // }

  return (dispatch, getState) => {
    const _journalUserId = getState().uistate?.journalUserId || null;
    if (_journalUserId) {
      requestUrl = `${requestUrl}?journalUserId=${_journalUserId}`
    }

    dispatch({
      type: actionTypes.SAVE_HEALTH_SURVEY_ANSWER,
    });
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          if(Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED) && Boolean(answer?.completedQuestions)) {
            let dashboardScheduleData = getState().uistate?.dashboardScheduleData;
            const filteredSchedules = Boolean(dashboardScheduleData) && 
              Boolean(dashboardScheduleData?.surveys && dashboardScheduleData?.surveys?.length > 0) && 
              dashboardScheduleData?.surveys.filter(schedule => schedule.id ===  answer?.notificationId && schedule?.overDueDays && schedule?.overDueDays > 0);
            const finishedScheduleList = Boolean(filteredSchedules && filteredSchedules?.length > 0)
              ? [...(getState().uistate?.finishedSchedules ?? []), ...filteredSchedules]
              : getState().uistate?.finishedSchedules ?? [];
            dispatch(setFinshedSchedules(finishedScheduleList));
            dispatch(pullSchedulars(_journalUserId, () => {
              dispatch({
                type: actionTypes.SAVE_HEALTH_SURVEY_ANSWER_SUCCESS,
                payload: `SAVED_${res.responseDto.data}`,
              }); 
            }));
          }
          if (!Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED) || !Boolean(answer?.completedQuestions)) {
            dispatch({
              type: actionTypes.SAVE_HEALTH_SURVEY_ANSWER_SUCCESS,
              payload: `SAVED_${res.responseDto.data}`,
            });
          } 
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; saveHealthSurveyAnswer");
            dispatch(logout());
          } else {
            dispatch({
              type: actionTypes.SAVE_HEALTH_SURVEY_ANSWER_FAIL,
              payload: `FAILED_${res.errorDto.data.message}`,
            });
            dispatch(commonErrorHandler(res.errorDto.data.message || 'Failed to save answer'));
          }
        }
      },
      answer
    );
  };
};

export const saveHealthSurveyAnswerReset = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.SAVE_HEALTH_SURVEY_ANSWER_RESET,
    });
  };
};

export const setSurveyListFromUrl = (fromUrl) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SURVEY_LIST_FROM_URL,
      payload: fromUrl,
    });
  };
};

export const setAnsweredRatio = (isNew, ratio) => {
  return {
    type: actionTypes.SET_ANSWERD_RATIO,
    data: ratio,
    isNew: isNew,
  };
};