import React from 'react';
import {View, Image, Text} from 'react-native';
import styles from '../../../Css';
import Modal from '../../Modal/modal';
import ModalLayout from '../../ModalLayout/modalLayout';

const success = props => {
  const {treatmentType, name, visibility, hidePopup} = props;
  return (
    <Modal visible={visibility} onBackdropPress={hidePopup}>
      <ModalLayout
        contPosition={'center'}
        showClose={true}
        hidePopup={hidePopup}>
        <View style={styles.popupContentWrapper}>
          <View style={[styles.popIconView, styles.comMgBot]}>
            <View style={styles.popMessageIconView}>
              <Image
                style={styles.popMessageIcon}
                source={require('../../../assets/icons/right.png')}
              />
            </View>
          </View>
          <View style={[styles.popMessageContView, styles.comSp]}>
            <View style={[styles.popMessageTitleView, styles.comMgBotSm]}>
              <Text
                style={[
                  styles.textTitleMdBold,
                  styles.textAlignCenter,
                ]}
                allowFontScaling={false}
                >
                Success!
              </Text>
            </View>

            {treatmentType === 5 && (
              <View style={[styles.popMessageDescView]}>
                <Text style={[styles.textTitle, styles.textAlignCenter]} allowFontScaling={false}>
                  One session of <Text style={styles.bold} allowFontScaling={false}>{name} </Text>
                  was recorded as an entry
                </Text>
              </View>
            )}
            {treatmentType !== 5 && (
              <View style={[styles.popMessageDescView]}>
                <Text style={[styles.textTitle, styles.textAlignCenter]} allowFontScaling={false}>
                  One dose of <Text style={styles.bold} allowFontScaling={false}>{name} </Text>
                  was recorded as an entry
                </Text>
              </View>
            )}
          </View>
        </View>
      </ModalLayout>
    </Modal>
  );
};

export default (success);
