import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import styles from "../../../Css";
import InputField from "../../TextInput/inputField";
import * as FieldDetails from "../../TextInput/fieldDetails";
import Webview from "../../Webview/webview";
import FieldTitle from "../../TextInput/fieldTitle";
import ColorItemField from '../../../components/ColorPicker/ColorItemField';
import ColorPickerRow from '../../../components/ColorPicker/ColorPickerRow';
import WarningDuplication from '../../../components/Warning/warningDuplication';
class JournalEntryTypeHeader extends Component {
  constructor(props) {
    super(props);
    const { name, description, color, haveDuplicatedName } = this.props;
    this.state = {
      name: name,
      invalidName: false,
      description: description,
      validation: true,
      color:color,
      duplicatedName: haveDuplicatedName ? true : false,
      initialName: name,
    };
  }

  componentDidMount() {
    const { name, journalEntryTypes = [], isFromCopyToNew } = this.props;
    if (isFromCopyToNew) {
      if (journalEntryTypes.some(element => element && element.name && element.name.trim().toLowerCase() === name.trim().toLowerCase())) {
        this.setState({ duplicatedName: true });
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { validation } = props;
    if (validation !== state.validation) {
      let invalidName = false;
      if ((state.name && state.name.trim() === "") || !state.name) {
        invalidName = true;
      }
      return {
        invalidName: invalidName,
        validation: validation,
      };
    }

    return null;
  }

  setColor = (selectedColor) => {
    const { updateColor } = this.props;
    this.setState({ color: selectedColor }, () => updateColor(selectedColor));
  };

  onNameChange = (val) => {
    const { updateName, journalEntryTypes = [], isFromCopyToNew } = this.props;
    const filteredJournalEntryTypes = journalEntryTypes.filter(element => element && element.name && element.name.trim().toLowerCase() === val.trim().toLowerCase());
    if (filteredJournalEntryTypes && filteredJournalEntryTypes.length > 0 && 
      (this.state.initialName.trim().toLowerCase() !== val.trim().toLowerCase() ||
        (filteredJournalEntryTypes.length > 1 && this.state.initialName.trim().toLowerCase() === val.trim().toLowerCase()) || 
          isFromCopyToNew)
    ) {
      this.setState({ name: val, invalidName: false, duplicatedName: true }, () => updateName(val));
    } else {
      this.setState({ name: val, invalidName: false, duplicatedName: false }, () => updateName(val));
    }
  };

  onDescriptionChange = (val) => {
    const { updateDescription } = this.props;
    this.setState({ description: val }, () => updateDescription(val));
  };

  render() {
    const { name, description, invalidName, color, duplicatedName } = this.state;
    const { publishedBy } = this.props;
    return (
      <React.Fragment>
        <View style={[styles.routineFormWrapper]}>
          <View style={[styles.routineForm, styles.comMgTopSm]}>
            <View style={styles.fieldRow}>
              <View style={styles.fieldRowWrapperFull}>
                <InputField
                  title={"Routine Name"}
                  value={name}
                  onChangeText={this.onNameChange}
                  invalid={invalidName || duplicatedName}
                  fieldLt={true}
                  editable={publishedBy !== "PARTNER"}
                  requirdStar={true}
                  // warningDuplication={duplicatedName}
                />
              </View>
              {Boolean(duplicatedName) &&
                <WarningDuplication 
                  errorMessage={"A routine with this name already exists. Please choose a new name or suffix."}
                  error
                />
              }
            </View>
            {Boolean(description) && description.length > 0 ? (
              <View style={styles.fieldRow}>
                <View style={styles.fieldRowWrapperFull}>
                  <View style={[styles.postRelative]}>
                    <FieldTitle
                      fieldAlign={this.props.fieldAlign}
                      fieldBg={FieldDetails.BGGREY}
                      title={"Routine Description"}
                      fieldLt={true}
                    />
                    <View
                      style={[
                        styles.textInputField,
                        styles.bgTransparent,
                        styles.bgThinGrey,
                        { height: "auto" },
                      ]}
                    >
                      <View
                        style={[
                          styles.flexJcCt,
                          styles.bgThinGrey,
                        ]}
                      />
                      <Webview htmlContent={description} />
                    </View>
                  </View>
                </View>
              </View>
            ) : null}
          </View>
        </View>

        <View style={styles.secArea}>
          <View style={[styles.fieldRow]}>
            <View
              style={[
                styles.horzFieldFull,
                styles.bgThinGrey,
                styles.bgContentPd,
              ]}
            >
              <ColorPickerRow colorPickerTitle={'Choose Color'} ColorPickerCom={true}>
                <ColorItemField
                  handleChangeComplete={color => {
                    this.setColor(color);
                  }}
                  color={color}
                  disabled={publishedBy === "PARTNER"}
                />
              </ColorPickerRow>
            </View>
          </View>
        </View>

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    journalEntryTypes: state.routines.journalEntryTypes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalEntryTypeHeader);
