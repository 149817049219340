import React from 'react';
import {TextInput} from 'react-native';

const textInput = props => {
  return (
    <TextInput
      style={props.style}
      allowFontScaling={false}
      secureTextEntry={props.secureTextEntry}
      onChangeText={props.onChangeText}
      value={props.value ? props.value : ''}
      onBlur={props.onBlur}
      autoFocus={props.autoFocus}
      underlineColorAndroid="transparent"
      placeholder={props.placeholder}
      onKeyPress={props.onKeyPress}
      onSubmitEditing={props.onSubmitEditing}
      ref={props.ref}
      editable={props.editable}
      caretHidden={props.caretHidden}
      keyboardType={props.keyboardType}
      autoCapitalize={props.autoCapitalize}
      onFocus={props.onFocus}
      selection={props.selection}
      returnKeyType={props.returnKeyType}
    />
  );
};

export default textInput;
