import React, { Component } from "react";
import { View, TouchableWithoutFeedback } from "react-native";
import styles from "../../Css";
import { connect } from "react-redux";
import { TAB_BREAk_POINT_START } from "../../util/platformWindowConfig";

class MenuOverlayContainer extends Component {
  constructor(props) {
    super(props);

    const { windowHeight, windowWidth, openPopup } = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      openPopup: openPopup,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth } = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  render() {
    const { windowWidth, windowHeight, hideHeaderOverlay } = this.props;
    return (
      <React.Fragment>
        {windowWidth > TAB_BREAk_POINT_START && (
          <TouchableWithoutFeedback
            onPress={this.props.onPressOpen}
          >
            <View
              style={[
                { minHeight: windowHeight},
                styles.menuOverlayView,
                styles.overlayFixed,
                this.props.tabDevice && this.props.tabMenuIconView
                  ? styles.menuOverlaySmall
                  : styles.menuOverlayDefault,
              ]}
            />
          </TouchableWithoutFeedback>
        )}
        {!Boolean(hideHeaderOverlay) &&
          <TouchableWithoutFeedback
            onPress={this.props.onPressOpen}
          > 
            <View style={[
              styles.headerOverlayView,
              this.props.journalUserId
              ? styles.menuOverlayHeaderLg
              : styles.menuOverlayHeaderSm
              ]} 
            />
          </TouchableWithoutFeedback> 
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    tabMenuIconView: state.uistate.tabMenuIconView,
    tabDevice: state.uistate.tabDevice,
    journalUserId: state.uistate.journalUserId,
  };
};

export default connect(mapStateToProps, null)(MenuOverlayContainer);
