import {Platform} from "react-native";
import { ANDROID, IOS } from "../../util/platformWindowConfig";

export default {
  // FONT_FAMILY: "Asap, sans-serif",
  FONT_FAMILY: (Platform.OS === IOS || Platform.OS === ANDROID ) ? "Asap" : "Asap, sans-serif",
  SECONDARY_FONT_FAMILY: Platform.OS === ANDROID ? "Nunito-Bold" : "Nunito",
  FONT_SIZE_XXXX_SM: 8,
  FONT_SIZE_XXX_SM: 9,
  FONT_SIZE_XX_SM: 10,
  FONT_SIZE_X_SM: 11,
  FONT_SIZE_SM: 12,
  FONT_SIZE_MD: 13,
  FONT_SIZE_RG: 14,
  FONT_SIZE_LG: 15,
  FONT_SIZE_X_LG: 16,
  FONT_SIZE_XX_LG: 17,
  FONT_SIZE_XXX_LG: 18,
  FONT_SIZE_XXXX_LG: 19,
  FONT_SIZE_XXXXX_LG: 20,
  FONT_SIZE_Title_XX_SM: 21,
  FONT_SIZE_Title_X_SM: 22,
  FONT_SIZE_Title_X_RG: 23,
  FONT_SIZE_Title_LG: 24,
  FONT_SIZE_Title_X_LG: 25,
  // Font Weights
  FONT_WEIGHT_BOLD: "bold",
  FONT_WEIGHT_LIGHT: "400",
  FONT_WEIGHT_MEDIUM: "600",
  FONT_WEIGHT_REGULAR: "500",
  FONT_WEIGHT_EX_BOLD: "700",
  // Primary Colors
  COLOR_BLUE: "#0099A8",
  COLOR_ORANGE: "#FF8300",
  COLOR_ORANGE_DARK_2: "#F15723",
  COLOR_GREY_DARK_EX: "#707071",
  COLOR_GREY_DARK: "#404041",
  COLOR_GREY_DARK_1: "#787885",
  COLOR_GREY_DARK_2: "#696975",
  COLOR_GREY_DARK_3: "#212B36",
  COLOR_GREY_DARK_4: "rgba(64, 64, 65, 0.3)",
  COLOR_GREY_DARK_5: "#636363",
  COLOR_GREY_MEDIUM: "#98989A",
  COLOR_GREY_MEDIUM_EX: "#b1afaa",
  COLOR_GREY_LIGHT: "#F4ECD7",
  COLOR_GREY_LIGHT_MD: "#ece9e1",
  COLOR_GREY_LIGHT_RG: "#e5e5e5",
  COLOR_GREY_LIGHT_EX: "#F1F1F1",
  COLOR_GREY_LIGHT_EX_1: "#f2f0ea",
  COLOR_GREY_LIGHT_EX_2: "#fdfaf3",
  COLOR_GREY_LIGHT_EX_3: "#f4f2ed",
  COLOR_GREY_LIGHT_EX_4: "#f5f5f5",
  COLOR_GREY_LIGHT_EX_5: "#f7f6f3",
  COLOR_GREY_LIGHT_EX_6: "rgba(244, 236, 215, 0.3)",
  COLOR_GREY_LIGHT_EX_7: "rgba(196, 196, 196, 0.48)",
  COLOR_GREY_LIGHT_EX_8: "rgba(138,138,138,0.6)",
  COLOR_GREY_LIGHT_EX_9: "#f3f0eb",
  COLOR_GREY_LIGHT_EX_10: "#f9f9fa",
  COLOR_GREY_LIGHT_EX_11: "#EEEEEE",
  COLOR_GREY_LIGHT_EX_12: "#F2F2F2",
  COLOR_GREY_LIGHT_EX_13: "#fcfbfb",
  COLOR_GREY_LIGHT_EX_14: "#C7C7C7",
  COLOR_GREY_LIGHT_EX_15: "#ebe8e8",
  COLOR_GREY_LIGHT_EX_16: "#cacaca",
  COLOR_GREY_LIGHT_EX_17: "#EDEDED",
  COLOR_GREY_LIGHT_EX_18: "#e0dfdf",
  COLOR_GREY_LIGHT_EX_19:"#EFEFEF",
  COLOR_GREY_LIGHT_EX_20: "#DDDDDD",
  COLOR_GREY_LIGHT_EX_21:"rgba(136, 136, 136, 0.5)",
  COLOR_GREY_LIGHT_EX_22:"#E8E8E8",
  COLOR_GRAY_LIGHT_EX_23:'#D6D6D7',
  COLOR_GRAY_LIGHT_EX_24:'#F3F3F3',
  // COLOR_GREY_LIGHT_EX_12: "#787885",
  COLOR_GREY_LG_5: "#DCDCDC",
  COLOR_GREY_1: "#C5C5C5",
  COLOR_GREY_2: "#B8B8B8",
  COLOR_GREY_3: "#C4C4C4",
  COLOR_GREY_4: "#BDBDBD",
  COLOR_GREY: "#888888",
  COLOR_SAFRON: "#EFC33B",
  COLOR_BLACK: "#000000",
  COLOR_BLACK_1: "(rgba(0, 0, 0, 0.05)",
  COLOR_BLACK_2: "(rgba(0, 0, 0, 0.5)",
  COLOR_WHITE: "#ffffff",
  COLOR_LIGHT_WHITE: "#D6D6D6",
  COLOR_LIGHT_GREY_WHITE:"#D9D9D9",
  COLOR_LIGHT_WHITE_1: "rgba(255,255,255,0.7)",
  COLOR_LIGHT_WHITE_2: "#F7F8FB",
  COLOR_DARK_LIGHT_GREY: '#FBFBFB',
  COLOR_GREEN: "#6bb59e",
  COLOR_INK: "#474de0",
  // Secondary Colors
  COLOR_YELLOW: "#EEE846",
  COLOR_YELLOW_1: "#FFF2D0",
  COLOR_YELLOW_2: "#f5e387",
  COLOR_CARROT: "#FF9E38",
  COLOR_ORANGE_DARK: "#FF9E38",
  COLOR_LIGHT_PINK: "#fcf9f4",
  COLOR_PURPLE: "#A36DB6",
  COLOR_OCEAN_GREEN: "#53b97c",
  COLOR_OCEAN_BLUE: "#7dc3c0",
  COLOR_OCEAN_BLUE_2: "#E6EEF8",
  // Tertiary Colors
  COLOR_WARNING: "#E48868",
  COLOR_SUCCESS: "#7FC5A3",
  COLOR_CREAM: "#ffe4a1",
  COLOR_EMERALD: "#5ec778",
  COLOR_BLUE_1: "#86cfd6",
  COLOR_BLUE_2: "#E4F5F4",
  COLOR_BLUE_3: "#354CB7",
  COLOR_BLUE_4: "#57bcc6",
  COLOR_BLUE_5: "rgba(0, 153, 168, 0.8)",
  COLOR_BLUE_6: "#00BDBD",
  COLOR_BLUE_7: "#2264D1",
  COLOR_BLUE_8: "#2bc8c8",
  COLOR_BLUE_9: "#86aef0",
  COLOR_BLUE_10: "#9DC2FF",
  COLOR_BLUE_11: "#43ADB9",
  COLOR_BLUE_12: "#F6FAFF",
  COLOR_BLUE_13: "#4CADB8",
  COLOR_BLUE_14: "#1F8BFF",
  COLOR_BLUE_15: "#3088D9",
  COLOR_BLUE_16: "#3271C6",
  COLOR_BLUE_17:"#0059B8",
  COLOR_BLUE_LUMINOUS: "#38ADA9",
  COLOR_WHITE_BLUE_LIGHT: "#EBF3FF",
  COLOR_BLUE_TEXT_UNDELINE: "#3271CB",
  COLOR_UNDERLINE_BLUE: "#4C89DC",
  COLOR_UNDERLINE_BLUE_DISABLED: "#4C89DC80",
  COLOR_BORDER: "#C4C4C4",
  COLOR_BORDER_LIGHT: "rgba(196, 196, 196, 0.3)",
  COLOR_BORDER_MEDIUM: "rgba(152, 152, 154, 0.4)",
  COLOR_BORDER_MEDIUM_2: "rgba(152, 152, 154, 0.3)",
  COLOR_BORDER_COM: "rgba(193, 193, 193, 0.4)",
  COLOR_ERROR_BG: "#FBEDE8",
  COLOR_ERROR_BG_1: "#FF6363",
  COLOR_ERROR_BORDER: "#F2D9D2",
  COLOR_DISABLE_BUTTON: "#d6d6d6",
  COLOR_HEADER: "#fcf9f3",
  COLOR_BLUE_BG: "rgba(134, 207, 214, 0.6)",
  COLOR_BLUE_BG_LIGHT: "#f7f9fa",
  COLOR_GREEN_DARK: "#4B8A67",
  COLOR_ORANGE_1: "#ffad57",
  COLOR_ORANGE_2: "#faf6ec",
  COLOR_ORANGE_3: "#ffcb44",
  COLOR_ORANGE_4: "#FFCA44",
  COLOR_ORANGE_5: "#BA8D16",
  COLOR_ORANGE_6: "#ffe4c8",
  COLOR_GREEN_1: "#5fc778",
  COLOR_GREEN_2: "#e3f4ea",
  COLOR_GREEN_3: "#8acfa6",
  COLOR_GREEN_4: "#01d801",
  COLOR_GREEN_5: "#43a047",
  COLOR_GREEN_6: "#0099A8",
  COLOR_GREEN_7: "#217648",
  COLOR_GREEN_8: "#E7F7EB",
  COLOR_BORDER_GREEN: "rgba(0, 153, 168, 0.5)",
  COLOR_BORDER_YELLOW: "#F9CE5D",
  COLOR_RED: "#ff0000",
  COLOR_RED_1: "#F44444",
  COLOR_RED_2: "#E00000",
  COLOR_RED_3: "#AF0C00",
  COLOR_RED_4: "#F7E7E6",
  COLOR_BLOOD_RED_LIGHT: "#DE6879",
  COLOR_BUTTON_BG: "rgba(0, 153, 168, 0.8)",
  COLOR_PURPLE_LIGHT: "#f1e9f4",
  COLOR_PURPLE_DARK: "#bf9dcc",
  COLOR_PURPLE_1: "rgba(163, 109, 182, 0.7)",
  COLOR_PURPLE_2: "#8067D7",
  COLOR_PURPLE_3: "#8c98cd",
  COLOR_BLUE_DARK: "#3C5D78",
  COLOR_BLUE_DARK_2: "#3461B9",
  COLOR_BROWN: "#9d7e58",
  COLOR_RED_DARK: '#FF3838',
  COLOR_FB: '#4A47C9',
  COLOR_TWIT: '#479AC9',
  COLOR_WEB: '#7FC5A3',
  COLOR_SHADOW: 'rgba(0, 0, 0, 0.15)',
  COLOR_GREY_TEXT: '#4A4B57',
  COLOR_GREY_TEXT_DARK: '#A1A1A1',
  LIGHT_BG: '#F7F7F7',
  LIGHT_MD_BG: '#EBEBEB',
  COLOR_TRANSPARENT: 'transparent',
  COLOR_ASH: 'rgba(149, 149, 149, 0.7)',
  COLOR_ASH_2:'#627487',
  COLOR_ASH_3: '#E3E5E5',
  COLOR_DARK_ASH:'#E4E4E4',
  COLOR_ORANGE_LIGHT: 'rgba(255, 158, 56, 0.5)',
  COLOR_THIN_BLUE: '#f6ffff',
  COLOR_THIN_PINK: '#fffcf5',
  COLOR_THIN_PINK_2: '#FFFAF2',
  COLOR_THIN_PINK_3: '#FFFCF4',
  COLOR_BOX_BLUE: '#7DC3C0',
  COLOR_BOX_ORANGE: '#EDC356',
  COLOR_BOX_GREEN: '#7DC38C',
  COLOR_COLLAGE: 'rgba(219, 241, 243, 0.87)',
  // Used Colors
  PRIMARY_FONT_COLOR: "#404041",
  SECONDARY_FONT_COLOR: "#98989A",
  TERTIARY_FONT_COLOR: "#627487",
  COLLAPSIBLE_TITLE_FONT_COLOR: "#5A5A5A",

  ORANGE_HIGHLIGH_COLOR: '#FFB160',
  BTN_BACKGROUND_COLOR : '#3461B9',

  // Primary Text
  PRIMARY_TEXT_SIZE: 14,
  PRIMARY_TITLE_TEXT_SIZE: 16
};
