import React, {Component} from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import styles from '../../../../Css';
import Question from '../../../Question/View';
import Collapsible from '../../../Collapsible/Collapsible';
import PlusButtonCom from '../../../Button/plusButtonCom';
import QuestionText from '../../../Question/Add/questionText';

class Questions extends Component {
  constructor(props) {
    super(props);
    const {journalEntry, questionDigest, type} = this.props;
    const {artifactId} = journalEntry;
    const {userArtifactHealthQuestions} = questionDigest;
    const questions = this.getQuestions(
      userArtifactHealthQuestions,
      artifactId,
    );
    this.state = {
      visibleQuestions: type === 'selectedJournal' ? true : questions && questions.length > 0,
      questions: questions,
      showQuestionTextBox: false,
      remoteSubmit: false
    };
  }

  getQuestions = (questions, artifactId) => {
    let questionsArray = [];
    if (questions) {
      questionsArray = questions.filter(function(question) {
        return question.artifactId === artifactId;
      });
    }

    const unique = questionsArray
      .map(e => e['questionId'])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => questionsArray[e])
      .map(e => questionsArray[e]);
    return unique;
  };

  handleShowQuestionTextBox = () => {
    this.setState({showQuestionTextBox: true, remoteSubmit: true});
  };

  handleHideQuestionTextBox = () => {
    this.setState({showQuestionTextBox: false});
  };

  UNSAFE_componentWillReceiveProps(props) {
    const {journalEntry, questionDigest, questionList} = props;
    const {artifactId} = journalEntry;

    if (questionList) {
      this.setState({
        questions: this.getQuestions(
          questionDigest.userArtifactHealthQuestions,
          artifactId,
        ),
      });
    }
  }

  changeRemoteSubmit = (remoteSubmit, showQuestionTextBox) => {
    this.setState({ remoteSubmit , ...(showQuestionTextBox ? { showQuestionTextBox } : {})});
  };

  render() {
    const {
      journalEntry,
      questionDigest,
      questionDigestProcessed,
      type,
    } = this.props;
    const {artifactId} = journalEntry;
    const {questions} = this.state;
    return (
      <Collapsible
        title="To-dos"
        contentVisibility={this.state.visibleQuestions}
        handleOnPressCollapsible={() => {
          this.setState({
            visibleQuestions: !this.state.visibleQuestions,
          });
        }}
        disabled={(questions && questions.length > 0) || type === 'selectedJournal' ? false : true}
        style={[styles.collapseTitle]}
        customTitleStyle={true}
        topBorder={true}>
        {Boolean(questions) && questions.length > 0 && (
          <View
            style={[styles.collapseContent, styles.colPd, styles.bgThinGrey,
              type === 'selectedJournal'
              ? styles.noBotPd
              : null
              ]}>
            <View style={styles.questSectionJournal}>
              {questions.map(res => (
                <Question
                  question={questionDigestProcessed.questions[res.questionId]}
                  questionDigest={questionDigest}
                  colorLine={true}
                  parentArtifactId={artifactId}
                  key={'question' + res.id}
                  journalSection={true}
                  changeRemoteSubmit={this.changeRemoteSubmit}
                />
              ))}
            </View>
          </View>
        )}
        {type === 'selectedJournal' && (
          <View
            style={[
              styles.collapseContent,
              styles.colPd,
              styles.bgThinGrey,
              questions && questions.length > 0
              ? styles.noPdTop
              : null
            ]}>
            <View
              style={[
                styles.btnArea,
                styles.zIndexMin,
                this.props.bgLight ? styles.bgThinGrey : styles.colBg,
              ]}>
              {Boolean(this.state.showQuestionTextBox) && (
                <QuestionText
                  questionList={this.state.questions}
                  showQuestionTextBox={this.state.showQuestionTextBox}
                  handleHideQuestionTextBox={this.handleHideQuestionTextBox}
                  type={this.props.type}
                  changeRemoteSubmit={this.changeRemoteSubmit}
                  remoteSubmit={this.state.remoteSubmit}
                />
              )}

              <PlusButtonCom
                btnPosition={'left'}
                btnText={'Add'}
                touchableOnPress={this.handleShowQuestionTextBox} 
              />
            </View>
          </View>
        )}
      </Collapsible>
    );
  }
}

const mapStateToProps = state => {
  return {
    questionDigestProcessed: state.routines.questionDigestProcessed,
    questionDigest: state.routines.questionDigest,
    questionList: state.routineTemplate.questionList,
  };
};

export default connect(
  mapStateToProps,
  null,
)(Questions);
