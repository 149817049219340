import React from 'react';
import {View} from 'react-native';
import PageContainer from '../../../PageLayout/PageContainer';
import styles from '../../../../Css';
import Header from '../../../Header/header';
import SpaceBar from '../../../SpaceBar/SpaceBar';
import KeyboardAwareScrollViewHoc from '../../../KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import SearchName from './searchName';
import ChartCardItem from '../../ChatCard/CardItem';
import ActivityIndicator from '../../../ActivityIndicator/activityIndicator';

const NewChatListComponent = props => {
  const {
    index,
    navigation,
    handleOnPressBackButton,
    myConversationList,
    searchedKeyword,
    onSearchInputValueChange,
    loading,
    onPressUserNameItem,
    route,
  } = props;

  return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showHome={false}
        showNotify={true}
        hideCaregiverAccess={true}
        handleOnPressBackButton={handleOnPressBackButton}
        index={index}
        navigation={navigation}
        route={route}
      />
      <SpaceBar />
      <KeyboardAwareScrollViewHoc>
        <PageContainer smScreenFull={true}>
          <View style={[styles.secArea]}>
            <View style={[styles.gridRow, styles.colPd, styles.fieldWrapperBg]}>
              <View style={styles.gridColFull}>
                <View style={[styles.searchSectionName]}>
                  <View style={styles.fieldRowWrapperSm}>
                    <View style={styles.fieldColSearch}>
                      <SearchName
                        searchText={searchedKeyword ? searchedKeyword : ''}
                        placeholder="Search by username"
                        onChangeText={onSearchInputValueChange}
                        showClearBtton={
                          searchedKeyword && searchedKeyword !== ''
                            ? true
                            : false
                        }
                        clearFilter={() => onSearchInputValueChange(null)}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </PageContainer>
        <PageContainer smScreenFull={true}>
          {loading ? (
            <ActivityIndicator pdLarge={true} />
          ) : (
            <View
              style={[styles.secArea, styles.colPdTopCom, styles.pageBotPd]}>
              <View style={[styles.gridRow, styles.colPdLtRt]}>
                <View style={styles.gridColFull}>
                  <View style={[styles.UserListSection]}>
                    {myConversationList &&
                      myConversationList.length > 0 &&
                      myConversationList
                        .sort(
                          (a, b) =>
                            a?.userName !== null &&
                            b?.userName !== null &&
                            a?.userName.toLowerCase().localeCompare(b?.userName.toLowerCase()),
                        )
                        .map((element, index) => (
                          <ChartCardItem
                            key={index}
                            item={element}
                            onPressItem={onPressUserNameItem}
                            displayUserNameOnly={true}
                          />
                        ))}
                  </View>
                </View>
              </View>
            </View>
          )}
        </PageContainer>
      </KeyboardAwareScrollViewHoc>
    </View>
  );
};

export default (NewChatListComponent);
