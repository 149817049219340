import React, { useEffect, useState, useRef, Fragment } from 'react';
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    TextInput,
    Animated,
    Easing,
    ScrollView
} from 'react-native';
import styles from '../../../../Css';
import stylesResponsive from "../../../../CssResponsive";
import TickButton from '../../../../components/CheckBox/CheckBox';
import MobileSubCategoriesExpandableView from './MobileSubCategoriesExpandableView';
import _ from 'lodash';

const MobileCategoriesFilterComponent = ({ item, onClickFunction, isUncheckedAll, setClearAllCheckboxes, getSelectedCategoriesObj, selectedCategories }) => {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const [layoutHeight, setLayoutHeight] = useState(0);
    const [isItemSelected, setItemSelection] = useState(false);
    const [subCategoriesItems, setSubCategoriesItems] = useState(item.subcategory);
    const [selectedSubCategoriesCount, setSelectedSubCategoriesCount] = useState(0);
    const [selectedCategoryName, setSelectedCategoryName] = useState(null);
    const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);
    const [selectedSubCategoryNames, setSelectedSubCategoryNames] = useState([]);
    const [searchString, setSearchString] = useState("");
    const spinValue = useRef(undefined);
    const mounted = useRef(null);

    const spin = spinValue?.current?.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '-90deg'],
    });

    useEffect(() => {
        if(_.isEqual(isUncheckedAll, true)){
            setItemSelection(false);
            setSelectedCategoryName(null);
            setSelectedCategoryValue(null);
        }
        // setClearAllCheckboxes(false);
    }, [isUncheckedAll]);

    useEffect(() => {
        if (selectedCategories) {
            const isCategorySelected = selectedCategories.find(x => x.categoryName === item.category_name);
            if (isCategorySelected) {
                setItemSelection(true);
                setSelectedCategoryName(isCategorySelected.categoryName);
                setSelectedSubCategoriesCount(isCategorySelected?.subCategoryList.length)
                setSelectedSubCategoryNames(isCategorySelected?.subCategoryList || [])
            } else if (selectedSubCategoryNames.length > 0 && selectedCategoryName) {
              setSelectedSubCategoryNames([])
              setSelectedCategoryName(null);
            }
        }
        //eslint-disable-next-line
    }, [selectedCategories]);

    useEffect(() => {
        if (spinValue.current) {
            Animated.timing(spinValue.current, {
                toValue: item.isExpanded ? 0 : 1,
                duration: 160,
                easing: Easing.linear,
                useNativeDriver: true,
            }).start();
        } else {
            spinValue.current = new Animated.Value(1);;
        }
    }, [item.isExpanded])

    useEffect(() => {
        if (item.isExpanded) {
            setLayoutHeight(null);
        } else {
            setLayoutHeight(0);
        }
    }, [item.isExpanded]);

    const categorySelectHandler = (categoryName, categoryValue, itemSelected) => {
        if (itemSelected) {
            setSelectedCategoryName(categoryName);
            setSelectedCategoryValue(categoryValue);
        } else {
            setSelectedCategoryName(null);
            setSelectedCategoryValue(null);
        }
    }

    const subCategotySelectionHandler = (subCategoryName, isItemChecked) => {
        if (isItemChecked && !selectedSubCategoryNames.includes(subCategoryName)) {
            setSelectedSubCategoryNames([...selectedSubCategoryNames, subCategoryName]);
            setSelectedSubCategoriesCount(selectedSubCategoryNames.length + 1);
            setItemSelection(true);
            categorySelectHandler(item.category_name, item.value, true)
        }
        if (!isItemChecked && selectedSubCategoryNames.includes(subCategoryName)) {
            setSelectedSubCategoryNames(selectedSubCategoryNames.filter(item => item !== subCategoryName));
            setSelectedSubCategoriesCount(selectedSubCategoryNames.length - 1);
            if (selectedSubCategoryNames.length - 1 === 0) {
                setItemSelection(false);
                categorySelectHandler(null, null, false)
            }
        }
    }

    const selectAll = (val, flag) => {
      if (flag) {
        setSelectedSubCategoryNames(item.subcategory.map(item => item.name));
        setSelectedSubCategoriesCount(item.subcategory.length);
        setItemSelection(true);
        categorySelectHandler(item.category_name, item.value, true)
      } else {
        setSelectedSubCategoryNames([]);
        setSelectedSubCategoriesCount(0);
        setItemSelection(false);
        categorySelectHandler(null, null, false)
      }
    }

    const onSearchSubCategory = (pSubcategoryItems, searchKey) => {
        let searchedItem = pSubcategoryItems.filter(function (item) {
            if (item.name.toLowerCase().includes(searchKey.toLowerCase())) {
                return item;
            }
        });
        setSubCategoriesItems(searchedItem);
        setSearchString(searchKey);
    }

    useEffect(() => {
        if (mounted.current) {
          let obj = {
            categoryName: selectedCategoryName,
            value: selectedCategoryValue,
            subCategoryList: selectedSubCategoryNames
          };
          getSelectedCategoriesObj(obj);
        } else {
          mounted.current = true;
        }
        //eslint-disable-next-line
    }, [selectedCategoryName, selectedSubCategoryNames]);

    return (
        <React.Fragment>
            <View style={[styles.flexRow, styles.flexJcFs, styles.flexAiCt, styles.menuSingleTextViewSm, styles.ctgryListRtLtTpBt, styles.pdBtTwo, styles.noPdRt, styles.pdLtZero, styles.noPdTop]}>
                {/*Header of the Expandable List Item*/}
                <View style={{width:'100%'}}>
                    <View style={[styles.flexRow]}>
                        <TouchableOpacity
                            activeOpacity={0.8}
                            onPress={() => item.subcategory.length > 0
                                ?   onClickFunction()
                                :   [setItemSelection(!isItemSelected), categorySelectHandler(item.category_name, item.value, !isItemSelected)]}
                            style={[styles.header, styles.upIconSec, styles.pdBtEight, styles.wdHundredPer]}>
                            <View style={styles.checkboxContainer}>
                                {isItemSelected ? (
                                    <TickButton
                                        imagePath={'tickButton'}
                                        toggleTickCallback={() => item.subcategory.length > 0
                                            ?   onClickFunction()
                                            :   [setItemSelection(!isItemSelected),
                                                categorySelectHandler(item.category_name, item.value, !isItemSelected)]}
                                        tickStyle={{ marginRight: 0 }}
                                        disabled={selectedSubCategoryNames.length > 0}
                                    />
                                ) : (
                                    <TickButton
                                        imagePath={'untickButton'}
                                        toggleTickCallback={() => item.subcategory.length > 0
                                            ?   onClickFunction()
                                            :   [setItemSelection(!isItemSelected),
                                                categorySelectHandler(item.category_name, item.value, !isItemSelected)]}
                                        tickStyle={{ marginRight: 0 }}
                                    />
                                )}
                                <Text style={[styles.textPadding, styles.textPrimeBold, !isItemSelected && {opacity: 0.5}]}>
                                    {`${item.category_name}`}
                                </Text>
                                {`${item.subcategory.length}` > 0 ?
                                    <View style={[styles.flexRow, styles.flexJcFe, styles.flexAiCt, styles.mgLtFive, {position: 'absolute', right: 0, bottom: -8, top: -8}]}>
                                        <Text style={[styles.textCompStyling, styles.textPrime, styles.lightSelectedAmntTxtClr]}>
                                            {selectedSubCategoryNames.length > 0 ? item.subcategory.length !== selectedSubCategoryNames.length ? `${selectedSubCategoryNames.length}` : `ALL` : ``}
                                        </Text>
                                    <View style={styles.iconLtSp}>
                                    <Animated.View style={[styles.customArrowWdHg, { transform: [{ rotate: spin ? spin : '-90deg' }] },
                                            item.isExpanded && { bottom: 0 }]}>
                                                <Image
                                                    style={[styles.customArrowWdHg]}
                                                    source={require('../../../../assets/health-journey-icons/dropdown-arrow-black.png')}
                                                />
                                            </Animated.View>
                                        </View>
                                    </View>
                                : null}
                            </View>
                        </TouchableOpacity>
                    </View>
                    <View
                        style={{
                            height: layoutHeight,
                            overflow: 'hidden',
                        }}>

                        {`${item.subcategory.length}` > 0 ?
                            <View>

                                <View style={[styles.dateFilterLineWithMargin, {marginTop:0}]}></View>
                                <View style={[styles.flexRow, styles.flexJcFs, styles.flexAiCt, styles.infoPdTopBotExSm, styles.cardRowPdLtRt, styles.fourtyHg]}>
                                    <View style={[styles.tpOne]}>
                                        <Image
                                            style={{ width: 15, height: 15 }}
                                            source={require('../../../../assets/health-journey-icons/search-icon.png')}
                                        />
                                    </View>
                                    <TextInput
                                        style={[styles.textPrimeMd, styles.wdFullPer, styles.mgLtFive, styles.fourtyHg]}
                                        placeholder={"Search My " + `${item.category_name}`}
                                        placeholderTextColor={"#C4C4C4"}
                                        underlineColorAndroid="transparent"
                                        onChangeText={(value) => onSearchSubCategory(item.subcategory, value)}
                                    />
                                </View>
                            </View> : null}

                            <ScrollView nestedScrollEnabled={true} showsVerticalScrollIndicator={true} style={[styles.colapseViewMobileMaxHeight]}>
                                {/*Content under the header of the Expandable List Item*/}
                                {!Boolean(searchString) && subCategoriesItems?.length > 0 && (
                                  <MobileSubCategoriesExpandableView
                                    item={{name: "Select All"}}
                                    setSubItemSelection={(val, isChecked) => selectAll(val, isChecked)}
                                    selectedSubCategories={selectedSubCategoryNames.length === item.subcategory.length && !isUncheckedAll ? [{subCategoryList: ["Select All"]}] : [{subCategoryList: ["Test"]}]}
                                    isUncheckedAll={isUncheckedAll}
                                  />
                                )}
                                {subCategoriesItems.map((item, key) => (
                                  <Fragment key={key + item.name}>
                                    <MobileSubCategoriesExpandableView
                                        item={item}
                                        key={key}
                                        setSubItemSelection={(val, isChecked) => subCategotySelectionHandler(val, isChecked)}
                                        selectedSubCategories={selectedCategories}
                                        isUncheckedAll={isUncheckedAll}
                                    />
                                  </Fragment>
                                ))}
                            </ScrollView>
                    </View>
                </View >
            </View >
            <View style={[styles.dateFilterLineWithMargin, {marginTop:0}]}></View>
        </React.Fragment>
    );
};

export default MobileCategoriesFilterComponent;
