export const cssMenu = {
  sidebarLgMenu: {
    flexBasis: 255,
    maxWidth: 255,
  },
  sidebarSmMenu: {
    flexBasis: 60,
    maxWidth: 60,
  },
  moreTabMenuIcon: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  moreTabMenuIconView: {
    paddingTop: 5,
    paddingLeft: 8,
  },
  btnMaxAppZindex: {
    zIndex: 999999
  },
  addMenuOverlayAppView: {
    position: "absolute",
    right: -15
  },
  addMainMenuBtnTab: {
    position: "absolute",
    width: 80,
    height: 95,
    bottom: 0,
    right: 15,
  },
  addMenuMaxWrapper: {
    maxHeight: 300,
  },
};
