import React, {Component} from 'react';
import {View} from 'react-native';
import styles from '../../Css';
import {connect} from 'react-redux';
import MainSurvey from './MainSurvey';
import {DASHBOARD, SURVEYS} from '../../navigation/path';
import * as actions from '../../store/actions/index';
import Header from '../../components/Header/header';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import {pendoTrackEvent} from '../../util/pendoConfig';
import SurveyConsentForm from "./SurveyConsentForms/SurveyConsentForm";

class MainSurveyScreen extends Component {
  constructor(props) {
    super(props);
    const from = this.props.route.params?.from;
    this.state = {
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
      isFromDashboard: Boolean(from === DASHBOARD.path),
    };
  }

  componentDidMount = () => {
    const {hideMobileMenu} = this.props;
    this.props.navigation.addListener('focus', () => {
      hideMobileMenu();
      pendoTrackEvent('Surveys/@MainSurvey');
    });
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    });
  }

  componentWillUnmount = () => {
    const {showMobileMenu} = this.props;
    showMobileMenu();
  };

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth, surveyId, notificationId, surveyStatus, questionOrder, navigation} = props;

    if (
      props.windowHeight !== state.windowHeight ||
      props.windowWidth !== state.windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }
    if (surveyId && notificationId) {
      if (surveyStatus === 'PROCESSING') {
        navigation.push(SURVEYS.surveyQuestionPath, {
          from: state.isFromDashboard ? DASHBOARD.path : SURVEYS.mainSurveyPath,
          surveyId,
          notificationId,
          surveyStatus,
          questionOrder
        });
      }

      if (surveyStatus === 'COMPLETED') {
        navigation.push(SURVEYS.surveyQuestionPath, {
          from: SURVEYS.mainSurveyPath,
          surveyId,
          notificationId,
          isReadOnly: true
        });
      }
    }

    return null;
  }

  redirectSurveyQuestions = () => {
    const {surveyId, navigation, notificationId} = this.props;

    navigation.push(SURVEYS.surveyQuestionPath, {
      from: this.state.isFromDashboard ? DASHBOARD.path : SURVEYS.mainSurveyPath,
      surveyId: surveyId,
      notificationId
    });
  };

  render() {
    const {consentForms} = this.props;

    const pendingConsents = consentForms.filter((form) => {
      return (form.status === 'PENDING' || form.status === 'DATA_ENTERED_AND_CONSENT_PENDING');
    });

    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={false}
          navigation={this.props.navigation}
          route={this.props.route}
          index={SURVEYS.index}
          handleOnPressBackButton={() => {
            handleCommonBackNavigation(this.props.navigation);
          }}
        />
        <SpaceBar/>
        {pendingConsents.length > 0 && (
          <SurveyConsentForm
            windowHeight={this.props.windowHeight}
            windowWidth={this.props.windowWidth}
            tabDevice={this.props.tabDevice}
            tabMenuIconView={this.props.tabMenuIconView}
            hasNotch={this.props.hasNotch}
            navigation={this.props.navigation}>
          </SurveyConsentForm>
        )}
        {pendingConsents.length === 0 && (
          <MainSurvey
            navigation={this.props.navigation}
            redirectSurveyQuestions={this.redirectSurveyQuestions}
            route={this.props.route}
          />)}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    showLearnHow: state.uistate.showLearnHow,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    hasNotch: state.uistate.hasNotch,
    tabDevice: state.uistate.tabDevice,
    tabMenuIconView: state.uistate.tabMenuIconView,
    name: state.surveys.name,
    journalUserId: state.uistate.journalUserId,
    journalUserName: state.uistate.journalUserName,
    surveyId: state.surveys.surveyId,
    notificationId: state.surveys.notificationId,
    isClosed: state.surveys.isClosed,
    surveyStatus: state.surveys.surveyStatus,
    questionOrder: state.surveys.questionOrder,
    consentForms: state.userConsentForms.consentForms || [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainSurveyScreen);
