import React, {Component} from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import stylesResponsive from '../../CssResponsive';
import Header from '../../components/Header/header';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import ContactCamera from '../../modules/webCam/webCam';
import ImageCropper from '../../modules/imageCropper/imageCropper';
import * as actions from '../../store/actions/index';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import {CONTACTS} from '../../navigation/path';
import ProgressBarContent from '../../components/Contacts/progressBar';
import { pendoTrackEvent } from '../../util/pendoConfig';
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import { TAB_BREAk_POINT_START } from '../../util/platformWindowConfig';

class ContactTakePhoto extends Component {
  constructor(props) {
    super(props);
    let fromPopup = null;
    if (props.route?.params && props.route?.params.fromPopup) {
      fromPopup = props.route?.params.fromPopup;
    }
    this.state = {
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      //TO-DO remove previous navigation changes
      // imageUrl: this.props.location.state
      //   ? this.props.location.state.inputResult
      //   : null,
      imageUrl: this.props.route?.params
        ? this.props.route?.params.inputResult
        : null,
      retake: false,
      response: '',
      camImage: false,
      fromPopup: fromPopup,
      processing: false
    };
  }

  componentDidMount() {
    this.props.hideMobileMenu();
    pendoTrackEvent('Contacts/@ScanBusinesscard');
  }

  componentWillUnmount() {
    this.props.showMobileMenu();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {windowHeight, windowWidth, route} = nextProps;
    if (route?.params) {
      this.setState({
        imageUrl: route?.params.inputResult,
      });
    }

    this.setState({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    });
  }

  onimageSrc = imageUrl => {
    this.setState({
      imageUrl: imageUrl,
      retake: false,
      message: 'Adjust to crop image',
      camImage: true,
    });
  };

  onCropImage = (cropImageUrl, image64) => {
    if (cropImageUrl) {
      this.setState({processing: true});
      this.props.uploadBusinessCard(cropImageUrl, (success, res) => {
        if (success && res && res.jobId) {
          this.props.businessCardJobResult(res.jobId, (suc, response) => {
            if (suc && response && response.length > 0) {
              let addNewContact = {
                addNewContact: {
                  contact: {
                    id: -1,
                    physicalAddressLine1: response[0].Address.address
                      ? response[0].Address.address
                      : null,
                    municipality: response[0].Address.city
                      ? response[0].Address.city
                      : null,
                    stateProvince: response[0].Address.province
                      ? response[0].Address.province
                      : null,
                    postalCode: response[0].Address.zipCode
                      ? response[0].Address.zipCode
                      : null,
                    country: response[0].Address.country
                      ? response[0].Address.country
                      : null,
                    organization: response[0].Organization
                      ? response[0].Organization
                      : null,
                    primaryPhoneNumber: response[0].Phone
                      ? response[0].Phone
                      : null,
                    faxPhoneNumber: response[0].Fax ? response[0].Fax : null,
                    emailaddress: response[0].Email ? response[0].Email : null,
                    givenName: response[0].FullName
                      ? response[0].FullName
                      : null,
                  },
                },
                ...(cropImageUrl && {imagePath: cropImageUrl}),
              };
              if (this.state.processing) {
              this.props.updateContact(
                addNewContact,
                '',
                (success, contact) => {
                  if (success) {
                    const formData = new FormData();
                    let timestamp = new Date().getTime() + '.png';

                    formData.append('files[0]', image64, timestamp);
                    if (this.state.processing) {
                    this.props.onUpload(
                      contact.artifactId,
                      formData,
                      (success, attachments) => {
                        if (!this.state.processing) {
                          this.deleteContact(contact.id);
                        }
                        if (success && attachments) {
                          if (this.state.processing) {
                            this.props.navigation.push(
                              CONTACTS.addContactPath, {
                                from: this.props.route.name,
                              },
                            );
                            this.setState({processing: false});
                          }
                        } else {
                          this.setState({uploadError: true, processing: false});
                        }
                      },
                      false,
                      this.props.journalUserId,
                      image64.size,
                    );
                    } else {
                      this.deleteContact(contact.id);
                    }
                  } else {
                    this.setState({
                      retake: true,
                      message: 'Sorry, there was an error. Retake photo.',
                      processing: false,
                    });
                  }
                },
                this.props.journalUserId,
              );
              }
            } else if (!suc) {
              this.setState({
                retake: true,
                message: 'Sorry, there was an error. Retake photo.',
                processing: false,
              });
            }
          });
        } else {
          this.setState({processing: false});
        }
      });
    }
  };

  deleteContact = (contactId) => {
    this.props.onDeleteContact(
      contactId,
      this.props.contacts,
      () => {},
      this.props.journalUserId,
    );
  }

  handleOnCancel = () => {
    this.setState({processing: false}, () => {
      if (this.state.fromPopup) {
        this.props.setFromPopup(this.state.fromPopup);
      }
      handleCommonBackNavigation(this.props.navigation);
    });
  }

  setCreateSpinner = () => {
    this.setState({createSpinner: !this.state.createSpinner});
  };

  createContact = () => {
    let addNewContact = {};
    this.setCreateSpinner();
    this.props.addContact(
      addNewContact,
      this.props.contacts,
      res => {
        if (res) {
          this.props.navigation.push(
            CONTACTS.addContactPath, {
              from: this.props.route.name,
            },
          );
        }
        this.setCreateSpinner();
      },
      this.props.journalUserId,
    );
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    let fullHeight = this.state.windowHeight;
    let fullWidth = this.state.windowWidth;
    // let screen = "smScreen";
    // if (fullWidth > 991) {
    //   screen = "lgScreen";
    // }
    let scanWidth = '100%';
    let scanHeight = 'auto';
    let headerHight = 0;
    headerHight = 57;
    if (this.props.journalUserId) {
      headerHight = 88;
    }
    let maxContHeight = fullHeight - headerHight;

    let cropperImgHeight = '100%';
    let cropperImgWidth = 'auto';

    if (!this.state.camImage) {
      if (fullWidth > TAB_BREAk_POINT_START) {
        cropperImgWidth = 600;
        cropperImgHeight = 400;
        if (maxContHeight < 400) {
          cropperImgHeight = maxContHeight;
        }
      } else {
        cropperImgWidth = fullWidth;
        cropperImgHeight = maxContHeight;
      }
    }
    return (
      <View style={[stylesRes.contentAreaFull]}>
        <Header
          showFilers={false}
          showBack={false}
          showClearFilters={false}
          showNotify={true}
          showCancel={true}
          handleOnPressBackButton={this.handleOnCancel}
          handleOnPressCancelButton={this.handleOnCancel}
          index={CONTACTS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <View
          style={[
            stylesRes.sectionWrapper,
            stylesRes.fixedScreen,
            this.state.processing
            ? stylesRes.secProcessing
            : stylesRes.secStatic,
            stylesRes.secBlueBgCom,
            {height: fullHeight},
          ]}>
          <SpaceBar />
          {this.state.createSpinner ? (
            <ActivityIndicator />
          ) : this.state.processing ? (   
            <ProgressBarContent onCancel={this.handleOnCancel} />
          ) : !this.state.imageUrl ? (
            <ContactCamera
              onimageSrc={this.onimageSrc}
              message={this.state.message}
              scanHeight={scanHeight}
              scanWidth={scanWidth}
              windowWidth={this.state.windowWidth}
              windowHeight={this.state.windowHeight}
              maxContHeight={maxContHeight}
            />
          ) : (
            <ImageCropper
              imageUrl={this.state.imageUrl}
              onCropImage={this.onCropImage}
              retake={this.state.retake}
              contactCamera={() => {
                this.setState({imageUrl: null});

                this.createContact();
              }}
              onRetakePhoto={() => {
                this.setState({
                  imageUrl: null,
                  message: null,
                });
              }}
              message={this.state.message}
              scanHeight={cropperImgHeight}
              scanWidth={cropperImgWidth}
              windowWidth={this.state.windowWidth}
              windowHeight={this.state.windowHeight}
              maxContHeight={maxContHeight}
            />
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.routines.user,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    contacts: state.routines.contacts,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadBusinessCard: (imageString, callBack) =>
      dispatch(actions.uploadBusinessCard(imageString, callBack)),
    businessCardJobResult: (jobId, callBack) =>
      dispatch(actions.businessCardJobResult(jobId, callBack)),
    updateContact: (addNewContact, contacts, callBack, journalUserId) =>
      dispatch(
        actions.updateContact(addNewContact, contacts, callBack, journalUserId),
      ),
    addContact: (addNewContact, contacts, callBack, journalUserId) =>
      dispatch(
        actions.createContact(addNewContact, contacts, callBack, journalUserId),
      ),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    onUpload: (
      artifactId,
      formData,
      callBack,
      reload,
      journalUserId,
      fileSize,
    ) =>
      dispatch(
        actions.uploadAttachmen(
          artifactId,
          formData,
          callBack,
          reload,
          journalUserId,
          fileSize,
        ),
      ),
    setFromPopup: fromPopup => dispatch(actions.setFromPopup(fromPopup)),
    onDeleteContact: (id, contacts, callBack, journalUserId) =>
      dispatch(actions.deleteContact(id, contacts, callBack, journalUserId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactTakePhoto);
