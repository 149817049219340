import React from 'react';
import {View, Text} from 'react-native';
import styles from '../../../Css';

const titleBlock = props => {
  return (
    <View
    style={[styles.menuSingleContent, styles.barBotSp, styles.barTopSpRg]}>
    <View style={[styles.menuSingleNoteBlock]}>
      <Text style={[styles.textPrime, styles.textEm]} allowFontScaling={false}>
        {props.titleText}
      </Text>
    </View>
  </View>
  );
};

export default (titleBlock);
