import * as React from 'react';
import {DASHBOARD} from './path';
import { Platform } from 'react-native';
import { createNavigationContainerRef } from '@react-navigation/native';
import { WEB } from '../util/platformWindowConfig';


export const isReadyRef = React.createRef();
export const navigationRef = createNavigationContainerRef();

console.log('LOG', 'isReadyRef ::::: => ' + JSON.stringify(isReadyRef));
console.log('LOG', 'navigationRef isReadyRef ::::: => ' + JSON.stringify(navigationRef.isReady()));
console.log('LOG', 'navigationRef current ::::: => ' + JSON.stringify(navigationRef.current));

export const getNavigationProps = () => {
  if (navigationRef.isReady() && navigationRef.current) {
    return navigationRef.current;
  }
}

export const getLocationProps = () => {
  if (navigationRef.isReady() && navigationRef.current) {
    return navigationRef.current?.getCurrentRoute();
  }
}

export const handleCommonBackNavigation = (navigation) => {
  console.log('LOG', 'isReadyRef ::::: => ' + JSON.stringify(isReadyRef));
  console.log('LOG', 'navigationRef isReadyRef ::::: => ' + JSON.stringify(navigationRef.isReady()));
  if (navigationRef.isReady() && navigationRef.current) {
    if(navigation.canGoBack()) {
      navigation.goBack();
    } else {
      Platform.OS === WEB
        ? navigation.navigate(DASHBOARD.path)
        : navigation.reset({
          index: 0,
          routes: [{ name: DASHBOARD.path }],
      });
    }
  }
}
