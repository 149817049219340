import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Collapsible from '../Collapsible/Collapsible';
import moment from 'moment';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import ImageFile from './ImageFile';
import ReportImagesAndDocumentsTitel from './ReportImagesAndDocumentsTitel';
import PlusButtonCom from "../../components/Button/plusButtonCom";

class ReportImagesAndDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleReportImages: props.imageOpen,
      selectedImages: props.selectedImages ? props.selectedImages : [],
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const {selectedImages} = props;
    this.setState({selectedImages});
  }
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <Collapsible
        title="Images/Documents"
        contentVisibility={this.state.visibleReportImages}
        handleOnPressCollapsible={() => {
          this.setState({
            visibleReportImages: !this.state.visibleReportImages,
          });
          this.props.setTabOpen(5, !this.state.visibleReportImages);
        }}>
        <View style={[styles.collapseContent]}>
          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              <View
                style={[
                  styles.formSection,
                  styles.colBg,
                  styles.colPdTopLtRt,
                  styles.colBg,
                ]}>
                <ReportImagesAndDocumentsTitel />
              </View>

              {/* If Images/Documents added only, Please add styles.addedItemsImages  */}
              <View style={[styles.addedItemsImages]}>
                {/* Loop Item Start */}

                {this.state.selectedImages &&
                  this.state.selectedImages.map(item => {
                    const attachment = this.props.attachmentsProcessed[item.id];
                    return (
                      Boolean(attachment) && (
                        <View
                          style={[
                            styles.listItemView,
                            styles.listItemAddedView,
                            styles.colPdLtRt,
                            styles.colBg,
                          ]}
                          key={'rat_' + item.id}>
                          <View style={[styles.listItemCloseCenter]}>
                            <View style={styles.listItemCloseIcon}>
                              <TouchableOpacity
                                onPress={() =>
                                  this.props.removeReportImage(attachment.id)
                                }>
                                <Image
                                  style={styles.closeDarkIcon}
                                  source={require('../../assets/icons/close-dark.png')}
                                />
                              </TouchableOpacity>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.listContentViewFull,
                              styles.colPdTopBot,
                            ]}>
                            <View style={[styles.listContentView]}>
                              <View
                                style={[
                                  styles.addedItemInd,
                                  styles.addedItemRtPd,
                                ]}>
                                <View style={[styles.itemFullRow]}>
                                  <View style={stylesRes.itemImgAreaMainLg} dataSet={{media: ids.itemImgAreaMainLg}}>
                                    <View style={stylesRes.itemDocIcon} dataSet={{media: ids.itemDocIcon}}>
                                      {/* <Image
                                        style={styles.imgContain}
                                        source={require("../../assets/icons/report-img-doc.png")}
                                      /> */}
                                      <ImageFile id={attachment.id} />
                                    </View>
                                  </View>

                                  <View style={stylesRes.itemTextAreaMain}>
                                    <View style={[styles.itemTextDetails]}>
                                      <View style={[styles.itemTextMain]}>
                                        <Text style={[styles.textPrimeBold]} allowFontScaling={false}>
                                          {attachment.filename}
                                        </Text>
                                      </View>
                                    </View>

                                    <View style={stylesRes.itemTextDate} dataSet={{media: ids.itemTextDate}}>
                                      <Text
                                        style={[
                                          styles.textPrimeSm,
                                          styles.textAlignRight,
                                        ]} 
                                        allowFontScaling={false}
                                        >
                                        {moment(
                                          attachment.modifiedOn,
                                          'x',
                                        ).format("D MMM 'YY")}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>

                          <View style={stylesRes.itemBorderReportFull} />
                        </View>
                      )
                    );
                  })}
                {/* Loop Item End */}
              </View>

              <View style={[styles.addItemSecFull, styles.colBg, styles.colPd]}>
              <View style={[styles.btnArea]}>
                  <PlusButtonCom
                    btnPosition={"left"}
                    btnText={"Add"}
                    touchableOnPress={this.props.redirectReportAddImage}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Collapsible>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedImages: state.report.selectedImages,
    attachmentsProcessed:
      state.routines.attachmentsCompleteDigestProcessed.attachments,
    imageOpen: state.report.imageOpen,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    removeReportImage: id => dispatch(actions.removeReportImage(id)),
    setTabOpen: (tab, open) => dispatch(actions.setTabOpen(tab, open)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportImagesAndDocuments);
