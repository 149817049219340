import React, {Component} from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import styles from '../../../../Css';
import Collapsible from '../../../Collapsible/Collapsible';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextFrame from '../../../TextInput/textFrame';
import * as actions from '../../../../store/actions/index';
import { TAB_BREAk_MAX_WIDTH } from '../../../../util/platformWindowConfig';

class HealthData extends Component {
  constructor(props) {
    super(props);
    const {metrics} = this.props;
    const filterdMetrics = metrics.filter(metric => metric.value !== null);
    const sortedMetricsWithMetricObj = actions.getSortedMetricsWithSymptomObject(filterdMetrics, false, props.metricsDigestProcessed);
    this.state = {
      metrics: sortedMetricsWithMetricObj,
      visibleHealthData: filterdMetrics && filterdMetrics.length > 0,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {metrics, windowWidth} = props;
    const filterdMetrics = metrics.filter(metric => metric.value !== null);
    const sortedMetricsWithMetricObj = actions.getSortedMetricsWithSymptomObject(filterdMetrics, false, props.metricsDigestProcessed);
    if (windowWidth > TAB_BREAk_MAX_WIDTH && JSON.stringify(state.metrics) !== JSON.stringify(sortedMetricsWithMetricObj)) {
      return {
        metrics: sortedMetricsWithMetricObj,
        visibleHealthData: filterdMetrics && filterdMetrics.length > 0,
      }
    }
    return null;
  }

  findOption = (array, optionId) => {
    const find = (element, index) => {
      if (!element || (element && !element.options) || (element && element.options && element.options.length === 0)) {
        return find(array[index + 1], index + 1);
      }
      let match = null;
      element.options.forEach(item  => {
        if (item.id === optionId) {
          match = item
        }
      });
      if (index === array.length - 1 && !match) {
        return false;
      }
      if (match) {
        return match;
      } else {
        return find(array[index + 1], index + 1);
      }
    }
    return find(array[0], 0);
  }

  getItem = (item, index) => {
    const {
      metricsDigest,
      unitsOfMeasureDigestProcessed,
    } = this.props;
    const {metric, value, unitOfMeasure, uomOption} = item;
    let matchedOption = null;
    if (uomOption) {
      matchedOption = this.findOption(this.props.measurementUnits, uomOption);
    }

    const {name} = item.metricRef;

    let unitName;

    const {unitsOfMeasure} = unitsOfMeasureDigestProcessed;

    if (unitOfMeasure && unitsOfMeasure[unitOfMeasure]) {
      unitName = unitsOfMeasure[unitOfMeasure].symbol;
    } else {
      const {
        userDefinedMetricUnitOfMeasures,
        systemDefinedMetricUnitOfMeasures,
      } = metricsDigest;
      let found = userDefinedMetricUnitOfMeasures.find(
        element => element.metricId === metric,
      );
      if (!found) {
        found = systemDefinedMetricUnitOfMeasures.find(
          element => element.metricId === metric,
        );
      }
      const {unitsOfMeasure} = unitsOfMeasureDigestProcessed;

      if (found && unitsOfMeasure[found.uomId]) {
        unitName = unitsOfMeasure[found.uomId].symbol;
      }
    }

    return (
      <View style={styles.fieldContainer} key={'je_hea_' + index}>
        <View style={[styles.fieldRowWrapper]}>
          <View style={styles.fieldColPlusMinus}>
            <TextFrame
              title={matchedOption ?  'Measure' : 'Quantity'}
              fieldBg={FieldDetails.BGGREY}
              fieldAlign={FieldDetails.ALIGNCENTER}
              fieldValue={matchedOption ? matchedOption.name : value}
              editable={false}
            />
          </View>
          <View style={styles.fieldColSp} />

          <View style={styles.fieldColMed}>
            <TextFrame
              title={'Unit of Measure'}
              fieldBg={FieldDetails.BGGREY}
              fieldInfo={false}
              fieldValue={unitName}
              editable={false}
            />
          </View>

          <View style={styles.fieldColSp} />
          <View style={styles.fieldColStretch}>
            <TextFrame
              title={'Health Data'}
              fieldBg={FieldDetails.BGGREY}
              fieldInfo={false}
              fieldValue={name}
              noOfLines={1}
              editable={false}
            />
          </View>
        </View>
      </View>
    );
  };

  render() {
    const {metrics, visibleHealthData} = this.state;
    const {metricsDigestProcessed} = this.props;
    const {selectableMetrics} = metricsDigestProcessed;

    return (
      <>
      {
        metrics && metrics.length > 0 ?
        (

          <Collapsible
            title="Health Data"
            contentVisibility={visibleHealthData}
            handleOnPressCollapsible={() => {
              this.setState({
                visibleHealthData: !visibleHealthData,
              });
            }}
            disabled={metrics && metrics.length > 0 ? false : true}
            style={[styles.collapseTitle]}
            customTitleStyle={true}
            topBorder={true}
            // toolTipButton={true}
            // toolTipId={'tool-tip-health-data'}
            // toolTipContent={''}
            >
            {metrics.length > 0 && (
              <View
                style={[
                  styles.collapseContent,
                  styles.colPd,
                  styles.colPdBotSm,
                  styles.bgThinGrey,
                ]}>
                {/* Loop Item for Health Data - Start */}
                {metrics.map(
                  (item, index) =>
                    selectableMetrics[item.metric] && this.getItem(item, index),
                )}

                {/* Loop Item for Health Data - End */}
              </View>
            )}
          </Collapsible>
        ) : null
      }
      </>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    metricsDigest: state.routines.metricsDigest,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    measurementUnits: state.routines.unitsOfMeasureDigest.unitsOfMeasure,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(
  mapStateToProps,
  null,
)(HealthData);
