import React, {Component} from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import styles from '../../../Css';
import Therapy from './Therapy';
import { hasValue } from "../../../util/hashValueGenerator";
import {sortAlphabeticalOrder} from '../../../util/commonUiLogic';
import {chartTypes} from '../../../Constant/index';

let tMax = 1;
let tMin = 0;
class Therapies extends Component {
  constructor(props) {
    super(props);
    const {startDate, endDate} = props;
    const hash = hasValue(
        startDate +
          "," +
          endDate
    );
    this.state = {
      startDate: startDate,
      endDate: endDate,
      hash: hash,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {startDate, endDate} = props;
    const changeHasValue = hasValue(
        startDate +
          "," +
          endDate
    );
    if (state.hash !== changeHasValue) {
      return {
        startDate: startDate,
        endDate: endDate,
        hash: changeHasValue
      };
    }
    return null;
  }

  getTherapiesData = (startDate, endDate) => {
    const therapiesData = {};
    const journalEntries = Object.values(this.props.journalEntriesProcessed);
    if (journalEntries.length > 0) {
      if (startDate) {
        startDate.setHours(0, 0, 0, 0);
      }

      if (endDate) {
        endDate.setHours(23, 59, 59, 59);
      }

      let firstDate = new Date();
      firstDate.setDate(firstDate.getDate() - 14);
      firstDate.setHours(0, 0, 0, 0);

      let journalEntriesFilterd = [];
      if (startDate && endDate) {
        journalEntriesFilterd = journalEntries.filter(
          item =>
            item.dosages &&
            item.dosages.length > 0 &&
            item.entryDate > startDate.getTime() &&
            item.entryDate < endDate.getTime(),
        );
      } else {
        journalEntriesFilterd = journalEntries.filter(
          item =>
            item.dosages &&
            item.dosages.length > 0 &&
            item.entryDate > firstDate.getTime(),
        );
      }

      journalEntriesFilterd.forEach(item => {
        let dosages = item.dosages;
        dosages = dosages.filter(item => item.quantity);

        dosages.forEach(dosagesItem => {
          if (dosagesItem.quantity) {
            let data = [];
            if (therapiesData[dosagesItem.treatment]) {
              data = [...therapiesData[dosagesItem.treatment]];
            }
            data.push({...dosagesItem, quantity: dosagesItem.quantity ? dosagesItem.quantity : 0});
            therapiesData[dosagesItem.treatment] = data
          }
        });
      });
    }

    return therapiesData;
  }

  getMinMax = (therapiesData, treatment) => {
    tMax = 1;
    tMin = 0;
    Object.keys(therapiesData).forEach((item, index) => { 
      if(item == treatment) {
        let therapySeriesData = therapiesData[item];
        therapySeriesData.forEach(data => {
          const val = data.quantity ? Math.ceil(data.quantity) : 0;
          if (tMin > val) {
            tMin = val;
          }
          if (tMax < val) {
            tMax = val;
          }
        });
        return;
      }
    });
  }
  renderTreatments = () => {
    const {startDate, endDate} = this.state;
    const therapiesData = this.getTherapiesData(
      startDate ? new Date(startDate) : null,
      endDate ? new Date(endDate) : null)

      let treatments = null;

      if (this.props.type && this.props.type !== "") {
          if (this.props.type === "activity") {
            treatments = this.props.treatments.filter(
              item => item.name && item.displayProperties && item.treatmentType === 5
            );
            sortAlphabeticalOrder(treatments);
          } else {
            treatments = this.props.treatments.filter(
              item => item.name && item.displayProperties && item.treatmentType !== 5
            );
            sortAlphabeticalOrder(treatments);
          }

      } else {
        treatments = this.props.treatments.filter(
          item => item.name && item.displayProperties
        );
        sortAlphabeticalOrder(treatments);
      }

    return treatments.map(item => (
        this.getMinMax(therapiesData, item.id),
        <Therapy
          item={item}
          key={'tr_' + item.id}
          configChartType={chartTypes.bar}
          defaultMin={tMin}
          defaultMax={tMax}
          fullWidth={this.props.fullWidth}
          publishedByPartner={this.props.publishedByPartner}
          routineTreatments={this.props.routineTreatments}
          colorPicked={this.props.colorPicked}
          addNewTherapies={this.props.addNewTherapies}
        />
    ));
  };
  render() {
    return (
      <View style={styles.collapseContent}>
        <View style={styles.collapseDescription}>
          <View style={styles.collapseInContent}>
            <View style={styles.secSubContentArea}>
              {this.renderTreatments()}
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    treatments: state.routines.treatments,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    startDate: state.rollinggraphs.startDate,
    endDate: state.rollinggraphs.endDate,
  };
};
export default connect(
  mapStateToProps,
  null,
)(Therapies);
