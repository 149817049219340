import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import SuccessBox from '../../components/ConfirmationBox/successBox';
import ContactListContent from '../../components/AttachToOption/contactListContent';
import styles from '../../Css';
import * as actions from '../../store/actions/index';
import { TODOS } from '../../navigation/path';
import { filterContacts } from '../../util/commonUiLogic';
import { pendoTrackEvent } from '../../util/pendoConfig';
class AttachToContact extends Component {
  state = {
    contacts: {},
    contactsList: {
      careTeam: {},
      family: {},
    },
    selectedContact: null,
    saveWait: false,
    successModalVisible: false,
  };

  componentDidMount = () => {
    this.props.navigation.addListener('focus', () => {
      this.setState({contacts: this.props.contacts}, () => {
        this.setState({contactsList: filterContacts(this.state.contacts)});
      });
      this.props.hideMobileMenu();
      pendoTrackEvent('To-Dos/@AttachToContact');
    });
    this.props.navigation.addListener('blur', () => {
      this.props.showMobileMenu();
    });
  };

  setSelectedContact = id => {
    this.setState({selectedContact: id});
  };

  handleOnSave = () => {
    this.setState({saveWait: true});
    const artifactId = this.props.contactsProcessed[this.state.selectedContact]
      .artifactId;
    const healthQuestion = {
      artifactId: artifactId,
      healthQuestion: {
        id: -1,
        content: this.props.saveQuestionToAttach,
        openedDate: Date.now(),
      },
    };

    this.props.onSaveHealthQuestion(
      healthQuestion,
      artifactId,
      (success) => {
        if(success) {
          this.setState({successModalVisible: true});
        }
        this.setState({saveWait: false});
        setTimeout(() => {
          this.setState({successModalVisible: false});
          this.props.navigation.push(
            TODOS.path, {
              from: this.props.route.name,
            },
          );
        }, 1500);
      },
      this.props.journalUserId,
    );
  };

  handleOnPressBackButton = () => {
    this.props.navigation.push(
      TODOS.addToDoPath, {
        from: this.props.route.name,
      },
    );
  };

  render() {
    const { navigation, route, contactsProcessed } = this.props;
    const { contactsList, saveWait, successModalVisible } = this.state;
    return (
      <View style={[styles.pageBodyWrapper]}>
        <ContactListContent
          index={TODOS.index}
          navigation={navigation}
          route={route}
          handleOnPressBackButton={this.handleOnPressBackButton}
          contactsList={contactsList}
          contactsProcessed={contactsProcessed}
          saveWait={saveWait}
          handleOnSave={this.handleOnSave}
          setSelectedContact={this.setSelectedContact}
        />

        <SuccessBox
          visibleModal={successModalVisible}
          message="Success! Question has been added."
        />
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.routines.contacts,
    contactsProcessed: state.routines.contactsProcessed,
    saveQuestionToAttach: state.question.saveQuestionToAttach,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveHealthQuestion: (question, artifactId, callBack, journalUserId) =>
      dispatch(
        actions.saveHealthQuestion(
          question,
          artifactId,
          callBack,
          journalUserId,
        ),
      ),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttachToContact);
