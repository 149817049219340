import React, { Component } from "react";
import {Platform} from "react-native";
import { connect } from "react-redux";

import { DASHBOARD, GRAPHS, OTHER } from "../../../../navigation/path";
import * as actions from "../../../../store/actions/index";
import { tabs } from "../../../../Constant";
import GraphRoutineTypeModal from "../../../../components/Settings/RollingGraphs/ModalContent/graphJournalEntrytypeModal";
import Modal  from "../../../../components/Modal/modal";
import {toggleSetupModal} from "../../../../store/actions/uistate";
import * as RootNavigation from '../../../../navigation/RootNavigation';
import { WEB } from "../../../../util/platformWindowConfig";


class GraphRoutineTypeModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleRoutineTypeModal: props.fromPopup === 'GRAPH_ROUTINE' ? true : false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.fromPopup !== props.fromPopup) {
        return {
            visibleRoutineTypeModal: props.fromPopup === 'GRAPH_ROUTINE' ? true : false,
            fromPopup: props.fromPopup
        };
    }
    return null;
  }

  redirectAddEditRollingGraphs = (entryTypeId, graphName) => {
    if (graphName) {
      this.props.navigation.navigate(
        GRAPHS.editGraphPath, {
          from: RootNavigation.getLocationProps().name,
          fromPopup: 'GRAPH_ROUTINE',
          entryTypeId: entryTypeId,
          graphName: graphName,
        },
      );
    } else {
      this.props.navigation.navigate(
        GRAPHS.addGraphPath, {
          from: RootNavigation.getLocationProps().name,
          fromPopup: 'GRAPH_ROUTINE',
          entryTypeId: entryTypeId,
        },
      );
    }
  };

  handleCreateGraphFromRoutine = (entryTypeId, graphId) => {
    const {
      dashboardProcessed, 
      resetRollingGraph, 
      selectGraph, 
      setFromPopup,
      journalEntryTypesProcessed
    } = this.props;
    //graph exist for selected routine
    if (dashboardProcessed[graphId]) {
      resetRollingGraph();
      setFromPopup(null);
      selectGraph(dashboardProcessed[graphId], () => {
        this.redirectAddEditRollingGraphs(entryTypeId, dashboardProcessed[graphId]?.name);
      });
    } else {
      const selectedRoutine = journalEntryTypesProcessed[entryTypeId];
      let metrics = null;
      let symptoms = null;
      let therapies = null;

      selectedRoutine.defaultMetrics.forEach((item, index) => {
        if (index === 0) {
          metrics = item.metric + '';
        } else {
          metrics += "," + item.metric;
        }
      });

      selectedRoutine.defaultSymptoms.forEach((item, index) => {
        if (index === 0) {
          symptoms = item.userSymptom + '';
        } else {
          symptoms += "," + item.userSymptom;
        }
      });

      selectedRoutine.defaultTreatments.forEach((item, index) => {
        if (index === 0) {
          therapies = item.treatment + '';
        } else {
          therapies += "," + item.treatment;
        }
      });
      let endDate = new Date();
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 14);
      const tempGraph = {
        id: null,
        treatments: therapies,
        metrics: metrics,
        symptoms: symptoms,
        name: selectedRoutine.name + ' Graph',
        startDate: startDate.getTime(),
        endDate: endDate.getTime(),
        treatmentsProperties: null,
        symptomsProperties: null,
        metricsProperties: null,
        entryTypeId: entryTypeId,
      };
      resetRollingGraph();
      setFromPopup(null);
      selectGraph(tempGraph, () => {
        this.redirectAddEditRollingGraphs(entryTypeId);
      });
    }    
  }

  openRoutineWizard = () => {
    this.props.setSetupActiveTab(tabs.routine);
    this.props.setFromPopup(null);
    if(!this.state.isSetupOn) {
      this.props.showSetup(this.props.journalUserId, false, () => {
        this.handlePlatformNavigation();
      });
    } else {
      this.handlePlatformNavigation();
    }
  }

  handlePlatformNavigation = () => {
    if(Platform.OS === WEB) {
      this.props.toggleSetupModal(true);
      this.props.navigation.navigate(DASHBOARD.path);
    } else {
      this.props.navigation.navigate(OTHER.setupPath);
    }    
  }

  render() {
    const {visibleRoutineTypeModal} = this.state;
    const {journalEntryTypes, resetRollingGraph, setFromPopup}  = this.props;
    return (
      visibleRoutineTypeModal && (
      <Modal
        visible={visibleRoutineTypeModal}
        onRequestClose={() => {
          this.setState({visibleRoutineTypeModal: false});
          setFromPopup(null);
        }}
        onBackdropPress={() => {
          this.setState({visibleRoutineTypeModal: false});
          setFromPopup(null);
        }}
      >
        <GraphRoutineTypeModal 
          closeModal={() => {
            this.setState({visibleRoutineTypeModal: false});
            setFromPopup(null);
          }}
          journalEntryTypes={journalEntryTypes}
          onCreateNewGraph={() => {
            resetRollingGraph();
            setFromPopup(null);
            this.redirectAddEditRollingGraphs(null);
          }}
          onCreateGraphFromRoutine={this.handleCreateGraphFromRoutine}
          onCreateNewRoutine={this.openRoutineWizard}
        />
      </Modal> 
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.routines.dashboard,
    dashboardProcessed: state.routines.dashboardProcessed,
    journalUserId: state.uistate.journalUserId,
    fromPopup: state.uistate.fromPopup,
    journalEntryTypes: state.routines.journalEntryTypes,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectGraph: (graph, callBack) =>
      dispatch(actions.selectGraph(graph, callBack)),
    resetRollingGraph: () => dispatch(actions.resetRollingGraph()),
    setFromPopup: (fromPopup) => dispatch(actions.setFromPopup(fromPopup)),
    setSetupActiveTab: value => dispatch(actions.setSetupActiveTab(value)),
    showSetup: (journalUserId, updateGlobally, callBack) =>
      dispatch(actions.showSetup(journalUserId, callBack, updateGlobally)),
    toggleSetupModal: (val) => dispatch(toggleSetupModal(val)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GraphRoutineTypeModalContent);
