import React, {Component} from 'react';
import StartUp from '../../components/StartUp/StartUp';
import {connect} from 'react-redux';
import {OTHER} from '../../navigation/path';
class StartUpScreen extends Component {
  state = {
    layoutchanges: false,
    windowHeight: undefined,
    windowWidth: undefined,
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {windowHeight, windowWidth} = nextProps;
    this.setState({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    });
  }

  componentDidMount = () => {
    this.setState({
      windowHeight: this.props.windowHeight,
      windowWidth: this.props.windowWidth,
    });
  };
  handleTouchableOnPress = () => {
    let path = OTHER.loginPath;
    this.props.navigation.push(path);
  };

  onSignup = () => {
    this.props.navigation.push(OTHER.signupPath);
  };

  render() {
    return (
      <StartUp
        handleTouchableOnPress={this.handleTouchableOnPress}
        handleOnSignup={this.onSignup}
        windowHeight={this.state.windowHeight}
        windowWidth={this.state.windowWidth}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(mapStateToProps)(StartUpScreen);
