import React from 'react';
import {View} from 'react-native';
import styles from '../../../Css';
import Header from '../../Header/header';
import SpaceBar from '../../SpaceBar/SpaceBar';
import Tabs from '../../Tab/Tab';
import CreateProfile from '../../../screens/PeerToPeer/MainSection/Profile/CreateProfileScreen';
import MyNetwork from '../../../screens/PeerToPeer/MainSection/MyNetwork/MyNetworkScreen';
import ChatHistory from '../../../screens/PeerToPeer/MainSection/Chats/ChatHistoryScreen';
import MenuOverlayContainer from '../../PageLayout/MenuOverlayContainer';
import {peerToPeerTabs} from '../../../Constant/index';
import { pendoTrackEvent } from '../../../util/pendoConfig';
import { getScrollHeight } from '../../../util/commonUiLogic';

const PeerToPeerMain = props => {
  const {
    tabIndex,
    activeTab,
    navigation,
    index,
    handleOnPressBackButton,
    hasNotch,
    windowHeight,
    journalUserId,
    searchedKeyword,
    unSeenRequestCount,
    editedProfile,
    unSeenConversations,
    windowWidth,
    p2pProfileHasDataToSave,
    route,
    timeZoneWarning,
    timeZone
  } = props;

  let {profileChangesRef, myNetworkRef, chatsRef} = props;

  let tabHeight = 44;
  let scrollHeight = getScrollHeight(hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth, tabHeight);
  if (scrollHeight < 50) {
    scrollHeight = 50;
  }
  return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={true}
        hideCaregiverAccess={true}
        handleOnPressBackButton={() =>
          activeTab === peerToPeerTabs.profile
            ? profileChangesRef && profileChangesRef.handleOnSubmitCancel()
            : activeTab === peerToPeerTabs.myNetwork && searchedKeyword
            ? myNetworkRef && myNetworkRef.filterMyNetwork(null)
            : activeTab === peerToPeerTabs.chats && searchedKeyword
            ? chatsRef && chatsRef.filterChatHistory(null)
            : handleOnPressBackButton()
        }
        index={index}
        navigation={navigation}
        route={route}
      />
      <SpaceBar />
      {activeTab === peerToPeerTabs.profile && p2pProfileHasDataToSave && (
        <MenuOverlayContainer
          onPressOpen={() => {
            profileChangesRef && profileChangesRef.handleOnSubmitCancel();
          }}
        />
      )}

      <View style={styles.gridSection}>
        <Tabs
          activeTab={activeTab}
          pageContainer={true}
          smScreenFull={true}
          tabIndex={index => {
            index || index === peerToPeerTabs.chats
              ? tabIndex(index)
              : profileChangesRef && profileChangesRef.handleOnSubmitCancel()
            if (index === peerToPeerTabs.chats) {
              pendoTrackEvent('Community > @Chats');
            } else if (index === peerToPeerTabs.myNetwork) {
              pendoTrackEvent('Community > @MyConnections');
            } else {
              pendoTrackEvent('Community > @Profile');
            }
          }}>
          {/* Chats tab */}
          <View
            title="Chats"
            displayNotifCount={
              Object.keys(unSeenConversations).length > 0 ? true : false
            }
            showOnTab={true}
            notifCount={Object.keys(unSeenConversations).length}
            nativeId={'communityChats'}>
            <View style={[styles.scrollableArea, {height: scrollHeight}]}>
              {activeTab === peerToPeerTabs.chats && (
                <ChatHistory
                  navigation={navigation}
                  getRef={ref => (chatsRef = ref)}
                />
              )}
            </View>
          </View>
          {/* My Connections tab */}
          <View
            title="My Connections"
            displayNotifCount={
              unSeenRequestCount && unSeenRequestCount > 0 ? true : false
            }
            notifCount={unSeenRequestCount}
            nativeId={'communityMyConnections'}>
            <View style={[styles.scrollableArea, {height: scrollHeight}]}>
              {activeTab === peerToPeerTabs.myNetwork && (
                <MyNetwork
                  navigation={navigation}
                  getRef={ref => (myNetworkRef = ref)}
                  windowWidth={windowWidth}
                />
              )}
            </View>
          </View>
          {/* Profile tab */}
          <View 
            title="Profile" 
            activeCircle={editedProfile}
            nativeId={'communityProfile'}>
            <View style={[styles.scrollableArea, {height: scrollHeight}]}>
              {activeTab === peerToPeerTabs.profile && (
                <CreateProfile
                  navigation={navigation}
                  route={route}
                  getRef={ref => (profileChangesRef = ref)}
                />
              )}
            </View>
          </View>
        </Tabs>
      </View>
    </View>
  );
};

export default (PeerToPeerMain);
