import * as actionTypes from './actionTypes';
import * as axiosCommon from "../../axios/axios-common";
import * as url from "./urls";
import {logout} from './index';

// update user
export const updateUser = (updateUser, callBack) => {
  return dispatch => {
    dispatch(updateUserSuccess(updateUser));
    callBack();
  };
};

export const updateUserSuccess = updateUser => {
  return {
    type: actionTypes.UPDATE_USER,
    selectedUser: updateUser.user,
  };
};

//update condition
export const updateCondition = (updateCondition, callBack) => {
  return dispatch => {
    dispatch(updateConditionSuccess(updateCondition));
    callBack();
  };
};
export const updateConditionSuccess = updateCondition => {
  return {
    type: actionTypes.UPDATE_CONDITION,
    conditions: updateCondition,
  };
};

//update treatment
export const updateTreatment = (updateTreatment, callBack) => {
  return dispatch => {
    dispatch(updateTreatmentSuccess(updateTreatment));
    callBack();
  };
};
export const updateTreatmentSuccess = updateTreatment => {
  return {
    type: actionTypes.UPDATE_TREATMENT,
    treatments: updateTreatment,
  };
};

//update symptom
export const updateSymptom = (updateSymptom, callBack) => {
  return dispatch => {
    dispatch(updateSymptomSuccess(updateSymptom));
    callBack();
  };
};
export const updateSymptomSuccess = updateSymptom => {
  return {
    type: actionTypes.UPDATE_SYMPTOM,
    symptoms: updateSymptom,
  };
};

//update metric
export const updateMetric = (updateMetric, callBack) => {
  return dispatch => {
    dispatch(updateMetricSuccess(updateMetric));
    callBack();
  };
};
export const updateMetricSuccess = updateMetric => {
  return {
    type: actionTypes.UPDATE_METRIC,
    metrics: updateMetric,
  };
};

//update routine
export const updateRoutine = (updateRoutine, callBack) => {
  return dispatch => {
    dispatch(updateRoutineSuccess(updateRoutine));
    callBack();
  };
};
export const updateRoutineSuccess = updateRoutine => {
  return {
    type: actionTypes.UPDATE_ROUTINE,
    newRoutine: updateRoutine,
  };
};

//To handle Text name changes
export const updateConditionName = name => {
  return {
    type: actionTypes.UPDATE_CONDITION_NAME,
    conditionName: name,
  };
};
export const updateTherapieName = (name, activeTab) => {
  return {
    type: actionTypes.UPDATE_THERAPIE_NAME,
    therapieName: name,
    activeTherapieTab: activeTab,
  };
};
export const updateSymptomName = name => {
  return {
    type: actionTypes.UPDATE_SYMPTOM_NAME,
    symptomName: name,
  };
};
export const updateMetricsName = name => {
  return {
    type: actionTypes.UPDATE_METRICS_NAME,
    metricsName: name,
  };
};
export const resetSetupState = callBack => {
  return dispatch => {
    dispatch(resetSetupStateSuccess());
    callBack();
  };
};
export const resetSetupStateSuccess = () => {
  return {
    type: actionTypes.RESET_SETUP_STATE,
  };
};
export const updateAddNewCustomSymptom = isAddNew => {
  return {
    type: actionTypes.UPDATE_ADD_NEW_CUSTOM_SYMPTOM,
    isAddNewCustomSymptom: isAddNew,
  };
};

export const setSetupActiveTab = activeTab => {
  return {
    type: actionTypes.SET_SETUP_ACTIVE_TAB,
    activeTab: activeTab,
  };
};

export const getConditionSymptomsList = (conditionIdList, callBack, journalUserId) => {
  let requestUrl = url.SYMPTOM_CONDITION;
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosGET(requestUrl, res => {
      if (res.type === "SUCCESS") {
        callBack(true, res.responseDto.data.conditionSymptomsList);
      } else {
        if (res.errorDto === "SESSION_TIME_OUT") {
          console.log("session time out; getConditionSymptomsList");
          dispatch(logout());
        } else {
          callBack(false);
        }
      }
    },
    conditionIdList);
  };
};

export const getConditionMetricsList = (conditionIdList, callBack, journalUserId) => {
  let requestUrl = url.METRICS_CONDITION;
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosGET(requestUrl, res => {
      if (res.type === "SUCCESS") {
        callBack(true, res.responseDto.data.conditionMetricsList);
      } else {
        if (res.errorDto === "SESSION_TIME_OUT") {
          console.log("session time out; getConditionMetricsList");
          dispatch(logout());
        } else {
          callBack(false);
        }
      }
    },
    conditionIdList);
  };
};

export const updateDataAddedFromRW = itemList => {
  return {
    type: actionTypes.UPDATE_DATA_ADDED_FROM_RW,
    itemList: itemList,
  };
};

export const updateRoutineWizardSaveDisable = (flag, isFrom) => {
  return {
    type: actionTypes.UPDATE_ROUTINE_WIZARD_SAVE_DISABLE,
    routineWizardSaveDisable: flag,
    isFrom: isFrom
  };
};