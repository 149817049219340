import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import styles from "../../Css";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";

const singleButton = (props) => {
  return (
    <TouchableOpacity
      style={[props.touchableStyle]}
      onPress={props.touchableOnPress}
      disabled={props.disabled}
    >
      <View
        style={[
          styles.btnSingleView,
          styles.submit,
          props.noBtnShadow
          ? styles.noBtnShadow
          : styles.btnShadowCom,
          props.btnStyle,
          props.disabled
          ? styles.disableBtn
          : styles.submitBgOrange
        ]}
      >
        <View style={[styles.btnSingle, styles.btnSingleCent]}>
          {Boolean(props.activityInd) && (
            <View
              style={[
                styles.indicatorIconView,
                styles.indicatorLtSm,
                props.activityIndStyle,
              ]}
            >
              <ActivityIndicator
                pdZero={true}
                size="small"
              />
            </View>
          )}
          <Text
            style={[styles.submitBtnTextWhite, styles.bold, props.textStyle]}
            allowFontScaling={false}
          >
            {props.btnText}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default (singleButton);
