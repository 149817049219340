import React, { useEffect, useState } from "react";
import { Image, View, Text, TouchableOpacity } from "react-native";
import styles from '../../../Css';
import moment from "moment";
import stylesResponsive from '../../../CssResponsive';
import CustomDatePicker from './CustomDatePicker';
import _ from "lodash";
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import {isLeapYear} from '../../../util/formatDate';

const SelectDateDropDown = (props) => {
    const { 
        setSortByDateOptions, 
        setSelectedDateObject, 
        selectedDateObject, 
        clearSelectedDateObject, 
        setSelectDateOption,
        onApplyButtonEnabled,
        onApplyButtonDisable,
        onRemoveButtonEnabled,
        onRemoveButtonDisable,
        isApplyButtonDisabled,
        isRemoveButtonDisabled,
        dob,
        setDropDownItemNumber,
        removeDropDownItemNumber,
        isDateSelected,
        toggleDate,
        fetchAllHealthJourneyEvents
    } = props;

    const FILTER_TAG = 2; 
    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    const [customOptionSelected, setCustomOptionSelected] = useState(false);
    const [lastThirtyDaysSelected, setLastThirtyDaysSelected] = useState(false);
    const [threeMonthsSelected, setThreeMonthsSelected] = useState(false);
    const [sixMonthsSelected, setSixMonthsSelected] = useState(false);
    const [selectedFromMonth, setSelectedFromMonth] = useState(null);
    const [selectedFromYear, setSelectedFromYear] = useState(null);
    const [selectedToMonth, setSelectedToMonth] = useState(null);
    const [selectedToYear, setSelectedToYear] = useState(null);
    const [oldEventYear, setOldEventYear] = useState(moment().year());


    useEffect(() => {
        if (setSelectDateOption === 'custom') {
            if(!isDateSelected){
                setCustomOptionSelected(false);
                toggleDate()
            }else{
                setCustomOptionSelected(true);
                setSelectedFromMonth(moment(selectedDateObject.fromDate).format('MM'));
                setSelectedFromYear(moment(selectedDateObject.fromDate).format('YYYY'));
                setSelectedToMonth(moment(selectedDateObject.toDate).format('MM'));
                setSelectedToYear(moment(selectedDateObject.toDate).format('YYYY'));
            }
        } else if (setSelectDateOption === 'lastThirtyDays') {
            if(!isDateSelected){
                setLastThirtyDaysSelected(false);
                toggleDate()
            }else{
                setLastThirtyDaysSelected(true);
            }
        } else if (setSelectDateOption === 'threeMonths') {
            if(!isDateSelected){
                setThreeMonthsSelected(false);
                toggleDate()
            }else{
                setThreeMonthsSelected(true);
            }
        } else if (setSelectDateOption === 'sixMonths') {
            if(!isDateSelected){
                setSixMonthsSelected(false);
                toggleDate()
            }else{
                setSixMonthsSelected(true);
            }
        } else {
            handleRadioButtonOptions(false, false, false, false);
        }
        const {data} = fetchAllHealthJourneyEvents;
        if (data && Object.keys(data) && Object.keys(data).length > 0) {
            setOldEventYear(Object.keys(data)[0]);
        } 
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!_.isEmpty(setSelectedDateObject) && !_.isUndefined(setSelectedDateObject) && !_.isNaN((setSelectedDateObject))) {
            onApplyButtonDisable();
            onRemoveButtonEnabled();
        } 
        //eslint-disable-next-line
    }, [setSelectedDateObject]);

    useEffect(() => {
        if(!_.isUndefined(setSelectedDateObject) && 
            (customOptionSelected || lastThirtyDaysSelected || threeMonthsSelected || sixMonthsSelected)){
                onRemoveButtonEnabled();
                onApplyButtonEnabled();
            }
            //eslint-disable-next-line
    }, [ customOptionSelected, lastThirtyDaysSelected, threeMonthsSelected, sixMonthsSelected]);

    const handleClearAllData = () => {
        clearSelectedDateObject();
        removeDropDownItemNumber(FILTER_TAG);
        setSortByDateOptions();
        onApplyButtonDisable();
        onRemoveButtonDisable();
        setSelectedFromMonth(null);
        setSelectedFromYear(null);
        setSelectedToMonth(null);
        setSelectedToYear(null);
        setCustomOptionSelected(false);
        setLastThirtyDaysSelected(false);
        setThreeMonthsSelected(false);
        setSixMonthsSelected(false);
        props.setOnOpenFilter(false);
    }

    const handleRadioButtonOptions = (isCustomOption, isThirtyDays, isThreeMonths, isSixMonths) => {
        setCustomOptionSelected(isCustomOption);
        setLastThirtyDaysSelected(isThirtyDays);
        setThreeMonthsSelected(isThreeMonths);
        setSixMonthsSelected(isSixMonths);
    }

    const validateCustomFilter = () => {
      return Boolean(
        !_.isNull(selectedFromYear) &&
          !_.isNull(selectedFromMonth) &&
          !_.isNull(selectedToYear) &&
          !_.isNull(selectedToMonth) &&
          selectedFromYear !== "-1" &&
          selectedFromMonth !== "-1" &&
          selectedToYear !== "-1" &&
          selectedToMonth !== "-1"
      );
    }

    const getDayNumberText = (selectedMonth, selectedYear) => {
        switch (selectedMonth) {
            //Feb
            case '02':
              if (isLeapYear(selectedYear)) return '-29';
              else return '-28';
            //Apr June Sep Nov
            case '04':
            case '06':
            case '09':
            case '11':
                return '-30';
            default:
                return '-31';
        }
    }

    const handleApplyOption = () => {
        if (customOptionSelected) {
            if (validateCustomFilter()) {
                setSortByDateOptions('custom');
                setSelectedDateObject({
                    type: 'custom',
                    fromDate: moment(selectedFromYear + '-' + selectedFromMonth + '-01', 'YYYY-MM-DD').format('YYYY-MM-DD'),
                    toDate: moment(selectedToYear + '-' + selectedToMonth + getDayNumberText(selectedToMonth, selectedToYear), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                });
            } 
        } else if (lastThirtyDaysSelected) {
            setSortByDateOptions('lastThirtyDays');
            setSelectedDateObject({
                type: 'lastThirtyDays',
                fromDate: moment().subtract(30, "days").format('YYYY-MM-DD'),
                toDate: moment().add(1, 'day').format('YYYY-MM-DD')
            });
        } else if (threeMonthsSelected) {
            setSortByDateOptions('threeMonths');
            setSelectedDateObject({
                type: 'threeMonths',
                fromDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
                toDate: moment().add(1, 'day').format('YYYY-MM-DD')
            });
        } else if (sixMonthsSelected) {
            setSortByDateOptions('sixMonths');
            setSelectedDateObject({
                type: 'sixMonths',
                fromDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
                toDate: moment().add(1, 'day').format('YYYY-MM-DD')
            });
        } else {
            // handleDateFilterOption({});
        }
        setDropDownItemNumber(FILTER_TAG);
        props.setOnOpenFilter(false);
    }

    return (
        <React.Fragment>
            {/* Date Pop Up */}
            <View style={[styles.postAb, stylesRes.dateFilterPos]} dataSet={{ media: ids.dateFilterPos }} onStartShouldSetResponder={(e) => e.stopPropagation()}>
                <View style={[stylesRes.dateFilterWd, styles.dateFilterWrapper]} dataSet={{ media: ids.dateFilterWd }}>

                    <View style={[styles.flexRow, styles.ItemRowSp, styles.smPdLtRt]}>
                        <View style={[styles.secGap, styles.textIconView]}>
                            <Image
                                style={styles.healthJornCalendar}
                                source={require('../../../assets/health-journey-icons/calendar-icon.png')}
                            />
                        </View>
                        <View>
                            <Text style={styles.textPrimeExSm}> Select Dates </Text>
                        </View>
                    </View>

                    <View style={styles.dateFilterLine}></View>

                    <View style={[stylesRes.dividingClms, styles.flexJcCt, styles.flexAiCt, styles.contPdTop, styles.infoPd]} dataSet={{ media: ids.dividingClms }}>
                        <View style={[styles.flexRow, stylesRes.datePickerMbResFir]} dataSet={{ media: ids.datePickerMbResFir }}>
                            <TouchableOpacity onPress={() => handleRadioButtonOptions(true, false, false, false)}>
                                <View style={[styles.flexRow, styles.flexAiCt, styles.btnIconView, styles.pdRtFive]}>
                                    {Boolean(customOptionSelected) ? (
                                        <Image
                                            style={styles.radioIcon}
                                            source={require('../../../assets/icons/radio-selected.png')}
                                        />
                                    ) : (
                                        <Image
                                            style={styles.radioIcon}
                                            source={require('../../../assets/icons/radio-default.png')}
                                        />
                                    )}
                                    <Text style={[styles.textPrimeSm, styles.pdLtFive]}>
                                        Custom
                                    </Text>
                                </View>
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => handleRadioButtonOptions(false, true, false, false)}>
                                <View style={[styles.flexRow, styles.flexAiCt, styles.btnIconView, styles.pdRtFive]}>
                                    {Boolean(lastThirtyDaysSelected) ? (
                                        <Image
                                            style={styles.radioIcon}
                                            source={require('../../../assets/icons/radio-selected.png')}
                                        />
                                    ) : (
                                        <Image
                                            style={styles.radioIcon}
                                            source={require('../../../assets/icons/radio-default.png')}
                                        />
                                    )}
                                    <Text style={[styles.textPrimeSm, styles.pdLtFive]}>
                                        Last 30 days
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        </View>

                        <View style={[styles.flexRow, stylesRes.datePickerMbResSec]} dataSet={{ media: ids.datePickerMbResSec }}>
                            <TouchableOpacity onPress={() => handleRadioButtonOptions(false, false, true, false)}>
                                <View style={[styles.flexRow, styles.flexAiCt, styles.btnIconView, styles.pdRtFive]}>
                                    {Boolean(threeMonthsSelected) ? (
                                        <Image
                                            style={styles.radioIcon}
                                            source={require('../../../assets/icons/radio-selected.png')}
                                        />
                                    ) : (
                                        <Image
                                            style={styles.radioIcon}
                                            source={require('../../../assets/icons/radio-default.png')}
                                        />
                                    )}
                                    <Text style={[styles.textPrimeSm, styles.pdLtFive]}>
                                        Last 3 months
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        
                            <TouchableOpacity onPress={() => handleRadioButtonOptions(false, false, false, true)}>
                                <View style={[styles.flexRow, styles.flexAiCt, styles.btnIconView]}>
                                    {sixMonthsSelected ? (
                                        <Image
                                            style={styles.radioIcon}
                                            source={require('../../../assets/icons/radio-selected.png')}
                                        />
                                    ) : (
                                        <Image
                                            style={styles.radioIcon}
                                            source={require('../../../assets/icons/radio-default.png')}
                                        />
                                    )}
                                    <Text style={[styles.textPrimeSm, styles.pdLtFive]}>
                                        Last 6 months
                                    </Text>
                                </View>
                            </TouchableOpacity>
                            
                        </View>
                    </View>

                    {/* Custom Options */}
                    {Boolean(customOptionSelected) ?
                        <CustomDatePicker
                            selectedFromMonth={selectedFromMonth}
                            selectedToMonth={selectedToMonth}
                            selectedFromYear={selectedFromYear}
                            selectedToYear={selectedToYear}
                            handleFromMonthSelected={(fromMonth) => setSelectedFromMonth(fromMonth)}
                            handleToMonthSelected={(toMonth) => setSelectedToMonth(toMonth)}
                            handleFromYearSelected={(fromYear) => setSelectedFromYear(fromYear)}
                            handleToYearSelect={(toYear) => setSelectedToYear(toYear)} 
                            dob={dob}
                            oldEventYear={oldEventYear}/>
                        : null}

                    <View style={[styles.flexRow, stylesRes.dateFilterCnterPos, { zIndex: -9 }]} dataSet={{ media: ids.dateFilterCnterPos }}>
                        <TouchableOpacity disabled={isRemoveButtonDisabled} onPress={() => handleClearAllData()}>
                            <View style={[styles.btnWdRemandApply, isRemoveButtonDisabled ? styles.removeButtonDisabled : styles.removeButton]}>
                                <Text style={[styles.textPrimeBold, isRemoveButtonDisabled ? styles.headerTextCom : styles.textBlack]}> X Remove</Text>
                            </View>
                        </TouchableOpacity> 
                    
                        <TouchableOpacity disabled={isApplyButtonDisabled || (customOptionSelected && !validateCustomFilter())} onPress={() => handleApplyOption()}>
                            <View style={[styles.btnWdRemandApply, isApplyButtonDisabled || (customOptionSelected && !validateCustomFilter()) ? styles.applyButtonDisabled : styles.applyButton]}>
                                <Text style={[styles.textPrimeBold, styles.textWhite, styles.postTwo]}> Apply </Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        setSelectDateOption: state.healthJourneyFiltering.setSelectDateOption,
        selectedDateObject: state.healthJourneyFiltering.setSelectedDateObject,
        isApplyButtonDisabled: state.healthJourneyFiltering.isApplyButtonDisabled,
        isRemoveButtonDisabled: state.healthJourneyFiltering.isRemoveButtonDisabled,
        fetchAllHealthJourneyEvents: state.healthJourney.fetchAllHealthJourneyEvents,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        setSortByDateOptions: (selectedOption) =>
            dispatch(actions.setSortByDateOptions(selectedOption)),
        setSelectedDateObject: (dateObject) =>
            dispatch(actions.setSelectedDateObject(dateObject)),
        clearSelectedDateObject: () => 
            dispatch(actions.clearSelectedDateObject()),
        onApplyButtonEnabled: () => 
            dispatch(actions.onApplyButtonEnabled()),
        onApplyButtonDisable: () => 
            dispatch(actions.onApplyButtonDisable()),
        onRemoveButtonEnabled: () => 
            dispatch(actions.onRemoveButtonEnabled()),
        onRemoveButtonDisable: () => 
            dispatch(actions.onRemoveButtonDisable()),
        setDropDownItemNumber: (itemNumber) => 
            dispatch(actions.setDropDownItemNumber(itemNumber)),
        removeDropDownItemNumber: (itemNumber) => 
            dispatch(actions.removeDropDownItemNumber(itemNumber)),
     };
  };

export default connect(mapStateToProps, mapDispatchToProps) (SelectDateDropDown);
