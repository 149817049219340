import React from "react";
import { View } from "react-native";
import styles from "../../Css";
import WatchOverview from "./watchOverview";
import PageContainer from "../../components/PageLayout/PageContainer";

const ribbonWrapper = (props) => {
  return (
    <View style={[styles.ribbonZindexHigh, styles.secLightBlueBg]}>
      <View style={[styles.secSpPdTop, styles.lightOverlay]}>
        <PageContainer>
          <View
            style={[
              styles.premiumUpgradeConteArea,
              styles.premiumBoxWrapper,
              styles.ribbonWrapper,
            ]}
          >
            <View
              style={[styles.ribbonSpec, styles.flexRowJcCtAiCt]}
            >
              <WatchOverview windowWidth={props.windowWidth} />
              <View style={[styles.flexCom, styles.flexRowJcCtAiCt]}>
                <View style={[styles.premiumTextAreaSm, styles.flexCom, styles.ribbonPdRtTopBot]}>
                  <View
                    style={[
                      styles.premiumTextTitle,
                      styles.flexRowWrap,
                      styles.messageRibbonText,
                    ]}
                  >
                    {props.children}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </PageContainer>
      </View>
    </View>
  );
};

export default ribbonWrapper;
