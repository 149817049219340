import React, { Component } from "react";
import { View, Text, Image } from "react-native";
import styles from "../../Css";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { getAccountType } from "../../util/subscriptionInformation";

class StorageBanner extends Component {
  constructor(props) {
    super(props);
    const accountType = getAccountType(props.userSubscriptions);
    this.state = {
      showStorageBanner: props.showStorageBanner,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      accountType: accountType,
    };
  }
  static getDerivedStateFromProps(props, state) {
    const {
      showStorageBanner,
      showHideStorageBanner,
      windowHeight,
      windowWidth,
    } = props;
    if (showStorageBanner) {
      setTimeout(() => {
        showHideStorageBanner(false);
      }, 10000);
    }
    if (
      showStorageBanner !== state.showStorageBanner ||
      windowWidth !== state.windowWidth ||
      windowHeight !== state.windowHeight
    ) {
      return {
        showStorageBanner: showStorageBanner,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }
  render() {
    const { showStorageBanner, windowWidth } = this.state;
    return (
      <>
        {Boolean(showStorageBanner) && (
          <View style={[styles.stoargeBannerCont]}>
            <View
              style={[
                styles.stoargeBannerView,
                styles.flexRowJcCtAiCt,
                styles.stoargeBannerCom,
              ]}
            >
              <View style={[styles.bannerBlock]}>
                <View style={styles.warningIconView}>
                  <Image
                    style={styles.warningMessageIcon}
                    source={require("../../assets/icons/warning-message.png")}
                  />
                </View>
                <View style={[styles.warningDescView, styles.warningDescPd]}>
                  <Text
                    style={[
                      styles.textPrime,
                      styles.textColorGreyLight,
                      styles.textAlignCenter,
                      windowWidth < 767 ? styles.textPrimeSm : styles.textPrime,
                    ]}
                    allowFontScaling={false}
                  >
                    {this.state.accountType === "regular"
                      ? "You have reached your 1 GB storage limit, delete existing attachments or upgrade to premium"
                      : "You have reached your 25 GB storage limit"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showStorageBanner: state.uistate.showStorageBanner,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showHideStorageBanner: (val) =>
      dispatch(actions.showHideStorageBanner(val)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StorageBanner);
