import React from "react";
import { Text } from "react-native";
import styles from "../../Css";

const helpInviteCareGiver = () => {
  return (
    <Text style={styles.textPrime}>
        <Text style={styles.bold}  allowFontScaling={false}>Full access</Text> allows caregivers to read entries and create entries for you.  <Text style={styles.bold}>Read only</Text> will only allow reading entries.
    </Text>
  );
};

export default helpInviteCareGiver;
