import React, {Component} from 'react';
import {View, Keyboard, Text, TouchableOpacity} from 'react-native';
import PageContainer from "../../../components/PageLayout/PageContainer";
import styles from '../../../Css';
import stylesCss from './SurveyConsentFormStyle';
import stylesResponsive from '../../../CssResponsive';
import ArrowPrevButton from '../../../components/Button/arrowPrevButton';
import ArrowNextButton from '../../../components/Button/arrowNextButton';
import KeyboardAwareScrollViewHoc from '../../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import {connect} from "react-redux";
import {getScrollHeight} from '../../../util/commonUiLogic';
import {
  submitConsentFormConfirmation,
  submitConsentFormInitialDataForSurvey,
  incrementNextConsentFormIndex,
} from "../../../store/actions/userConsentForms";
import Webview from "../../../components/Webview/webview";
import QuestionSingleSelection from "../SurveyQuestions/QuestionSingleSelection/QuestionSingleSelection";
import InputField from "../../../components/TextInput/inputField";
import {WINDOW_MOBILE_WIDTH_MARGIN} from "../../../components/Tags/constants";
import TickButton from '../../../components/CheckBox/CheckBoxNew';
import {PDFViewer} from "../../../components/PDFViewer/PDFViewer";
import {SURVEYS} from "../../../navigation/path";
import theme from "../../../css/base/theme";


const COLLECTION_FIELD_DELIM = "^";

const consentChoices = [
  {
    id: 1,
    questionChoiceText: "Yes",
    choiceOrder: 1,
  },
  {
    id: 2,
    questionChoiceText: "No",
    choiceOrder: 2,
  },
];

const consentingPersonIdentificationChoices = [
  {
    id: 1,
    questionChoiceText: "Consenting for myself",
    choiceOrder: 1,
  },
  {
    id: 2,
    questionChoiceText: "Consenting for someone else",
    choiceOrder: 2,
  },
];

const EDITABLE_STATUS = 'PENDING';
const VERIFICATION_STATUS = "DATA_ENTERED_AND_CONSENT_PENDING";
const NON_CONSENT_BEHAVIOR_CURRENT_SURVEY_CONTINUE = "CONTINUE_SURVEY";
const NON_CONSENT_BEHAVIOR_CURRENT_SURVEY_STOP = "STOP_SURVEY";
const NON_CONSENT_BEHAVIOR_FUTURE_SURVEY_STOP_ALL = "STOP_ALL_SURVEYS";
const NON_CONSENT_BEHAVIOR_FUTURE_SURVEY_STOP_ALL_LIKE_THIS_ONE = "STOP_CURRENT_SURVEY_TYPE";
const NON_CONSENT_BEHAVIOR_PARTICIPANT_ACCOUNT_WITHDRAW = "WITHDRAW_PARTICIPANT";
const NON_CONSENT_BEHAVIOR_PARTICIPANT_ACCOUNT_NO_EFFECT = "NO_EFFECT";
const USER_CONSENT_FORM_DID_NOT_CONSENT = "DID_NOT_CONSENT";

class SurveyConsentForm extends Component {

  state = {
    consentData: {},
    isScrollEnabled: true,
    requiredWarning: false,
    confirmBox: false,
    showSpinner: false,
    consentFormIndex: 0,
    consentDocumentFullyViewed: false,
  };

  componentDidMount() {
  };

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth, consentForms, consentFormIndex} = props;
    let {consentForm} = state;
    if (props.consentFormIndex !== state.consentFormIndex || !Boolean(consentForm)) {
      consentForm = Boolean(consentForms[consentFormIndex]) ? {
        ...consentForms[consentFormIndex],
        selfConsentFieldValuesArray: consentForms[consentFormIndex].selfConsentFieldValues ? consentForms[consentFormIndex].selfConsentFieldValues.split(COLLECTION_FIELD_DELIM) : [],
        otherConsentingPersonFieldValuesArray: consentForms[consentFormIndex].otherConsentingPersonFieldValues ? consentForms[consentFormIndex].otherConsentingPersonFieldValues.split(COLLECTION_FIELD_DELIM) : [],
      } : {};
    }
    if (
      props.windowHeight !== state.windowHeight ||
      props.windowWidth !== state.windowWidth ||
      props.consentForms !== state.consentForms ||
      props.consentFormIndex !== state.consentFormIndex
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        consentForms: consentForms,
        consentFormIndex: consentFormIndex,
        consentForm: consentForm,
        currentPdfPageNumber: 1,
        consentPdfVerified: false,
        consentPdfUrlLoaded: false,
      };
    }

    return null;
  }

  consentOptionChanged = (value) => {
    const {consentForm} = this.state;
    const {selectedItem} = value;
    const consented = selectedItem === null ? null : selectedItem === 1;
    this.setState({
      consentForm: {
        ...consentForm,
        isConsented: consented,
      }
    });
  };

  consentIdentificationOptionChanged = (value) => {
    const {consentForm} = this.state;
    const {selectedItem} = value;
    const isSelfConsent = selectedItem === null ? null : selectedItem === 1;
    this.setState({
      consentForm: {
        ...consentForm,
        isSelfConsent: isSelfConsent,
      }
    });
  };

  handleSaveAndBackClick = () => {
    Keyboard.dismiss();
    this.setState({
        consentForm: {
          ...this.state.consentForm,
          status: EDITABLE_STATUS
        },
        currentPdfPageNumber: 1,
      }
    )
  }

  handleSaveAndNextClick = () => {
    Keyboard.dismiss();
    const {surveyId, notificationId, consentFormIndex, submitConsentFormInitialDataForSurvey} = this.props;
    const {consentForm} = this.state;

    const isValid = this.validConsentFormSubmission()

    const consentFormToSubmit = {
      ...consentForm,
      selfConsentFieldValues: consentForm.selfConsentFieldValuesArray.join(COLLECTION_FIELD_DELIM),
      otherConsentingPersonFieldValues: consentForm.otherConsentingPersonFieldValuesArray.join(COLLECTION_FIELD_DELIM),
    }

    if (isValid) {
      submitConsentFormInitialDataForSurvey(surveyId, notificationId, consentFormIndex, consentFormToSubmit, (success, data) => this.handleConsentFormSubmitResponse(success, data));
    }
  };

  handleSubmit = () => {
    Keyboard.dismiss();
    const {
      surveyId,
      notificationId,
      consentFormIndex,
      submitConsentFormConfirmation,
      incrementNextConsentFormIndex
    } = this.props;
    const {consentForm} = this.state;

    const isValid = true; //this.validConsentFormConfirmation()

    const consentFormToSubmit = {
      ...consentForm,
    }

    if (isValid) {
      submitConsentFormConfirmation(surveyId, notificationId, consentFormIndex, consentFormToSubmit,
        (success, data) => {
          this.handleConsentFormSubmitResponse(success, data, () => {
            incrementNextConsentFormIndex();
          });
        });
    }
  };

  handleStopSurveyConsents = () => {
    this.props.navigation.replace(SURVEYS.path);
  }

  handleConsentFormSubmitResponse(success, data, setStateCallback) {
    if (success) {
      const updatedUserConsent = data.userConsent;
      const {consentFormDefinition, status} = updatedUserConsent;

      if (status === USER_CONSENT_FORM_DID_NOT_CONSENT && consentFormDefinition.nonConsentBehaviorForCurrentSurvey === NON_CONSENT_BEHAVIOR_CURRENT_SURVEY_STOP) {
        this.handleStopSurveyConsents();
      } else {
        this.setState({
          consentForm: {
            ...updatedUserConsent,
            selfConsentFieldValuesArray: updatedUserConsent.selfConsentFieldValues ? updatedUserConsent.selfConsentFieldValues.split(COLLECTION_FIELD_DELIM) : [],
            otherConsentingPersonFieldValuesArray: updatedUserConsent.otherConsentingPersonFieldValues ? updatedUserConsent.otherConsentingPersonFieldValues.split(COLLECTION_FIELD_DELIM) : [],
          }
        }, setStateCallback);
      }
    } else {
      this.setState({
        requiredWarning: true,
        errorMessage: data,
      });
    }
  }

  consentFormFieldChanged = (index, text, formFieldDataElementName) => {
    const {consentForm} = this.state;
    const consentFormFieldData = consentForm[formFieldDataElementName] || [];
    consentFormFieldData[index] = text;

    this.setState({
      consentForm: {
        ...consentForm,
        [formFieldDataElementName]: [...consentFormFieldData],
      }
    });
  };

  consentFormFieldRow(field, index, consentFormFieldData, formFieldDataElementName) {
    const {consentForm} = this.state;
    const {status} = consentForm;
    return <View key={formFieldDataElementName + "-" + index}
                 style={[styles.flex, (this.state.windowWidth > WINDOW_MOBILE_WIDTH_MARGIN) ? styles.flexRow : styles.flexCol, styles.fullWidth, stylesCss.attestationMargin]}>
      <Text style={[
        styles.textPrimeBold,
        (this.state.windowWidth > WINDOW_MOBILE_WIDTH_MARGIN) && {width: '30%'},
        {marginTop: 'auto', marginBottom: 'auto'}
      ]}>
        {field}
      </Text>
      <View style={[
        (this.state.windowWidth > WINDOW_MOBILE_WIDTH_MARGIN) && {width: '70%'},
      ]}>
        <InputField
          value={consentFormFieldData[index]}
          onChangeText={text => this.consentFormFieldChanged(index, text, formFieldDataElementName)}
          editable={status === EDITABLE_STATUS}
        />
      </View>
    </View>
  }

  validConsentFormSubmission = () => {
    const {consentForm} = this.state;
    const {consentFormDefinition} = consentForm;

    const collectionFields = Boolean(consentFormDefinition?.collectionFields) ? consentFormDefinition?.collectionFields.split(COLLECTION_FIELD_DELIM) : [];
    const otherConsentingPersonFields = Boolean(consentFormDefinition?.otherConsentingPersonFields) ? consentFormDefinition?.otherConsentingPersonFields.split(COLLECTION_FIELD_DELIM) : [];

    const isConsented = consentForm?.isConsented;
    const isSelfConsent = consentForm?.isSelfConsent;

    let error = "";

    if (isSelfConsent === null || isSelfConsent === undefined) {
      error = "You must declare whether you are consenting for yourself or someone else.";
    }

    if (!Boolean(error) && (isConsented === null || isSelfConsent === undefined)) {
      error = "You must declare whether you consent to participate in this survey.";
    }

    if (!Boolean(error) && isConsented && isSelfConsent && collectionFields.length > 0) {
      collectionFields.forEach((field, index) => {
        if (!Boolean(error) && !Boolean(consentForm?.selfConsentFieldValuesArray[index])) {
          error = `You must provide a value for: ${field}.`;
        }
      });
    }

    if (!Boolean(error) && isConsented && !isSelfConsent && otherConsentingPersonFields.length > 0) {
      otherConsentingPersonFields.forEach((field, index) => {
        if (!Boolean(error) && !Boolean(consentForm?.otherConsentingPersonFieldValuesArray[index])) {
          error = `You must provide a value for: ${field}.`;
        }
      });
    }

    this.setState({
      requiredWarning: Boolean(error),
      errorMessage: error,
    });

    return !Boolean(error);
  }

  onPdfLoadSuccess = (numPagesLoaded) => {
    this.setState({
      consentPdfVerified: false,
      consentPdfUrlLoaded: true,
      allPagesReviewed: numPagesLoaded === 1,
    });
  };

  getVerificationView(consentForm) {
    const PDF_VIEWER_WIDTH_FOR_WEB = 800;
    return <View style={[styles.fullWidth]}>
      <PDFViewer
        width={PDF_VIEWER_WIDTH_FOR_WEB}
        url={consentForm.consentPdfUrl}
        onLoadSuccess={this.onPdfLoadSuccess}
        onPageChanged={({pageNumber, numPages}) => {
          this.setState({
            currentPdfPageNumber: pageNumber,
            allPagesReviewed: pageNumber === numPages,
          });
        }}/>
      {!Boolean(this.state.allPagesReviewed) &&
        <View style={[styles.flexRow, {
          marginTop: 15,
          marginLeft: "auto",
          marginRight: "auto",
          justifyContent: "flex-start"
        }]}>
          <Text style={[styles.textPrimeMd]}>Read all pages of the above document to confirm the information is
            correct.</Text>
        </View>}
      {Boolean(this.state.consentPdfUrlLoaded) && <View style={[styles.flexRow, {
        marginTop: 15,
        marginLeft: "auto",
        marginRight: "auto",
        justifyContent: "flex-start",
        width: "90%"
      }]}>
        <TickButton
          ticked={this.state.consentPdfVerified}
          toggleTickCallback={(ticked) => this.setState({consentPdfVerified: ticked})}
          tickStyle={{marginRight: 0}}
          disabled={!Boolean(this.state.allPagesReviewed)}
        />
        <Text
          style={[styles.textPrimeMd, {color: !this.state.allPagesReviewed ? theme.COLOR_GRAY_LIGHT_EX_23 : theme.PRIMARY_FONT_COLOR}]}>
          I certify that all information in this document above is correct. I understand that clicking
          'Save Consent' will electronically sign the form and that signing is the equivalent of signing
          a physical document.
        </Text>
      </View>}
    </View>;
  }

  getImpactTextIfNotConsenting = () => {
    const {consentForm} = this.state;
    const {consentFormDefinition} = consentForm;
    const {
      nonConsentBehaviorForCurrentSurvey,
      nonConsentBehaviorForFutureSurveys,
      nonConsentBehaviorForParticipantAccount
    } = consentFormDefinition;

    let impactText = "Note: By not consenting, the following will occur: ";

    if (nonConsentBehaviorForCurrentSurvey === NON_CONSENT_BEHAVIOR_CURRENT_SURVEY_CONTINUE) {
      impactText += " the current survey will continue;";
    } else if (nonConsentBehaviorForCurrentSurvey === NON_CONSENT_BEHAVIOR_CURRENT_SURVEY_STOP) {
      impactText += " the current survey will be ended;";
    }

    if (nonConsentBehaviorForFutureSurveys === NON_CONSENT_BEHAVIOR_FUTURE_SURVEY_STOP_ALL) {
      impactText += " all future surveys will be stopped;";
    } else if (nonConsentBehaviorForFutureSurveys === NON_CONSENT_BEHAVIOR_FUTURE_SURVEY_STOP_ALL_LIKE_THIS_ONE) {
      impactText += " all future surveys like this one will be stopped;";
    }

    if (nonConsentBehaviorForParticipantAccount === NON_CONSENT_BEHAVIOR_PARTICIPANT_ACCOUNT_WITHDRAW) {
      impactText += " and you will be withdrawn from the study and your data will no longer be collected as part of the study.";
    } else if (nonConsentBehaviorForParticipantAccount === NON_CONSENT_BEHAVIOR_PARTICIPANT_ACCOUNT_NO_EFFECT) {
      impactText += " and you will continue to be included in the study to participate in other surveys and routines.";
    }

    return impactText;
  }

  getConsentInputView(stylesRes, name, description, isSelfConsent, status, isConsented, consentPrompt, collectionFields, consentForm, otherConsentingPersonFields) {
    return <View style={stylesRes.questionsOuterWrapper}>
      <Text
        style={[
          styles.textTitleMainSmBold,
          styles.textAlignCenter,
        ]}
        allowFontScaling={false}
      >
        {name}
      </Text>

      {description !== "" && description != null && (
        <View style={[stylesRes.contentViewWrapperFull, stylesRes.tabBorder]}>
          <View style={[styles.colPdTop, styles.pdBotLg]}>
            <View style={[styles.postRelative]}>
              <View
                style={[
                  styles.textInputField,
                  styles.bgTransparent,
                  styles.bgThinGrey,
                  {height: "auto"},
                ]}
              >
                <Webview
                  htmlContent={description}
                  alwaysFullContent={true}
                  onContentScrollCompleteChange={(value) => {
                    this.setState({consentDocumentFullyViewed: this.state.consentDocumentFullyViewed || value})
                  }}
                />
              </View>
            </View>
            <View style={[styles.moreDescView]}/>
          </View>
        </View>
      )}

      {Boolean(this.state.consentDocumentFullyViewed) &&
        <View>
          <QuestionSingleSelection
            questionContainerStyles={[{marginBottom: 15}]}
            showQuestionTitleAsPrompt={true}
            allowOtherText={false}
            answer={{selectedItem: isSelfConsent !== null ? (isSelfConsent ? 1 : 2) : null}}
            onSelect={this.consentIdentificationOptionChanged}
            isReadOnly={status !== EDITABLE_STATUS}
            onChangeText={() => {
            }}
            questionIndex={1}
            title={"I am consenting for myself or I am consent on behalf of someone else"}
            imageUrl={null}
            required={true}
            requiredWarning={false}
            onSkip={() => {
            }}
            choices={consentingPersonIdentificationChoices}
          />

          <QuestionSingleSelection
            questionContainerStyles={{marginBottom: 15, marginTop: 0}}
            showQuestionTitleAsPrompt={true}
            allowOtherText={false}
            answer={{selectedItem: isConsented !== null ? (isConsented ? 1 : 2) : null}}
            onSelect={this.consentOptionChanged}
            isReadOnly={status !== EDITABLE_STATUS}
            onChangeText={() => {
            }}
            questionIndex={0}
            title={consentPrompt}
            imageUrl={null}
            required={true}
            requiredWarning={false}
            onSkip={() => {
            }}
            choices={consentChoices}
          />

          {isSelfConsent !== null && isConsented !== null && !Boolean(isConsented) &&
            <Text style={[styles.textPrimeMdBold]}>
              {this.getImpactTextIfNotConsenting()}
            </Text>
          }
          {isSelfConsent !== null && Boolean(isConsented) &&
            <View>
              <View style={[stylesCss.questionTitleAsPromptWrapper]}>
                <Text style={[styles.textTitleBold, stylesCss.attestationMargin]} allowFontScaling={false}>
                  Attestation
                </Text>
              </View>
              {Boolean(isSelfConsent) && collectionFields.map((field, index) => {
                return this.consentFormFieldRow(field, index, consentForm?.selfConsentFieldValuesArray, "selfConsentFieldValuesArray")
              })}
              {!Boolean(isSelfConsent) && otherConsentingPersonFields.map((field, index) => {
                return this.consentFormFieldRow(field, index, consentForm?.otherConsentingPersonFieldValuesArray, "otherConsentingPersonFieldValuesArray")
              })}
            </View>
          }
        </View>
      }

      {!Boolean(this.state.consentDocumentFullyViewed) &&
        <Text style={[styles.textAlignCenter, styles.textPrimeMd]}>Read all pages of the above document before consenting.</Text>
      }
    </View>;
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {
      consentFormSaving,
    } = this.props;

    const {consentForm} = this.state;

    const consentFormDefinition = consentForm?.consentFormDefinition || {};
    const description = consentFormDefinition?.description;
    const name = consentFormDefinition?.name;
    const consentPrompt = consentFormDefinition?.consentPrompt;
    const collectionFields = Boolean(consentFormDefinition?.collectionFields) ? consentFormDefinition?.collectionFields.split(COLLECTION_FIELD_DELIM) : [];
    const otherConsentingPersonFields = Boolean(consentFormDefinition?.otherConsentingPersonFields) ? consentFormDefinition?.otherConsentingPersonFields.split(COLLECTION_FIELD_DELIM) : [];

    const isConsented = consentForm?.isConsented;
    const isSelfConsent = consentForm?.isSelfConsent;
    const status = consentForm?.status;

    return (
      <>
        <KeyboardAwareScrollViewHoc extraScrollHeight={50} enableResetScrollToCoords
                                    scrollEnabled={true}>
          <View style={[styles.pageBodyWrapper]}>
            <View style={styles.gridSection}>
              <View>
                <View
                  style={[stylesRes.contentMainWrapper]}
                  dataSet={{media: ids.contentMainWrapper}}
                />
                <PageContainer>
                  {consentForm?.status === EDITABLE_STATUS &&
                    this.getConsentInputView(stylesRes, name, description, isSelfConsent, status, isConsented, consentPrompt, collectionFields, consentForm, otherConsentingPersonFields)}
                  {consentForm?.status === VERIFICATION_STATUS &&
                    this.getVerificationView(consentForm)
                  }
                  {this.state.requiredWarning && (
                    <View>
                      <Text
                        style={[styles.textAlignCenter, styles.textPrimeMdBold, styles.textRed]}>{this.state.errorMessage}</Text>
                    </View>
                  )}
                  <View style={stylesRes.prevNextBtnSurveyWrapper}>
                    {consentForm?.status === VERIFICATION_STATUS &&
                      <View style={[stylesRes.responsiveBackBtnMargin, stylesRes.btnPdBot]}
                            dataSet={{media: ids.responsiveBackBtnMargin}}>
                        <ArrowPrevButton
                          touchableStyle={[stylesCss.btnMaxWidthBorder]}
                          btnText={'Back'}
                          touchableOnPress={this.handleSaveAndBackClick}
                          disabled={consentFormSaving}
                          prevWait={false}
                        />
                      </View>}
                    {consentForm?.status === EDITABLE_STATUS && this.state.consentDocumentFullyViewed &&
                      <View style={[stylesRes.responsiveNextBtnMargin, stylesRes.btnPdBot]}
                            dataSet={{media: ids.responsiveNextBtnMargin}}>
                        <ArrowNextButton
                          touchableStyle={[stylesCss.btnMaxWidthBorder]}
                          btnText={consentForm.isConsented === null || Boolean(consentForm.isConsented) ? 'Preview Consent' : 'Decline'}
                          touchableOnPress={this.handleSaveAndNextClick}
                          disabled={consentFormSaving}
                        />
                      </View>}
                    {consentForm?.status === VERIFICATION_STATUS &&
                      <View style={[stylesRes.responsiveNextBtnMargin, stylesRes.btnPdBot]}
                            dataSet={{media: ids.responsiveNextBtnMargin}}>
                        <ArrowNextButton
                          touchableStyle={[stylesCss.btnMaxWidthBorder]}
                          btnText={'Save Consent'}
                          touchableOnPress={this.handleSubmit}
                          disabled={consentFormSaving || !this.state.consentPdfVerified || !Boolean(this.state.allPagesReviewed)}
                        />
                      </View>}
                  </View>
                </PageContainer>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollViewHoc>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
    surveyId: state.surveys.surveyId,
    notificationId: state.surveys.notificationId,
    consentForms: state.userConsentForms.consentForms,
    consentFormIndex: state.userConsentForms.consentFormIndex,
    consentFormSaving: state.userConsentForms.saving,
    consentFormError: state.userConsentForms.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    submitConsentFormInitialDataForSurvey: (surveyId, notificationId, consentFormIndex, consentFormData, callBack) =>
      dispatch(submitConsentFormInitialDataForSurvey(surveyId, notificationId, consentFormIndex, consentFormData, callBack)),
    submitConsentFormConfirmation: (surveyId, notificationId, consentFormIndex, consentFormData, callBack) =>
      dispatch(submitConsentFormConfirmation(surveyId, notificationId, consentFormIndex, consentFormData, callBack)),
    incrementNextConsentFormIndex: () => dispatch(incrementNextConsentFormIndex()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyConsentForm);
