import React from "react";
import { View, Image } from "react-native";
import styles from "../../Css";
import UIText from "../../components/Text/text";

const warning = props => {
  return (
    <View style={styles.warningBlock}>
      <View
        style={[styles.notifyDetailsCom, styles.notifyNoPd, styles.notifyPdBot,
          props.textPosition === 'left'
          ? styles.flexJcFs
          : styles.flexJcCt,
          props.warningBlockMg
          ? styles.topMgWarning
          : styles.topMgWarningZero,
          props.journalWarningBanner
          ? styles.flexAiFs
          : styles.flexAiCt
        ]}
      >
        <View style={[styles.notifyWarning,
              props.warningBlockSp
              ? styles.ltLgPd
              : styles.ltLgPdZero]}>
          <Image
            style={styles.warningTraingleCom}
            source={require("../../assets/icons/warning-exclamation.png")}
          />
        </View>
        <UIText
          style={[
            props.journalWarningBanner 
              ? styles.journalWarningText
              : props.journalTreatmentWarning
              ? [styles.warningComText, styles.journalTreatmentWarning]
              : styles.warningComText,
          ]}
          title={props.errorDescription}
        />
      </View>
    </View>
  );
};

export default (warning);
