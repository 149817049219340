import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import {Image, View, Text, TouchableOpacity} from 'react-native';
import RadioForm from '../../../modules/radioButton/RadioButton';
import styles from '../../../Css';

class StepOneCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.condition,
      index : 1,
    };

  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      ...props.condition,
      index : this.state.index + 1,
    });
  }


  getStage = value => {
    const conditionsStages = this.state.condition?.conditionsStages || this.state.condition?.conditionStages
    if (conditionsStages && conditionsStages.length > 0) {
      return conditionsStages.findIndex(
        x => x.stage === value,
      );
    }
  };

  handleOnPressRadioFormConditionsStage = value => {
    const conditionsStages = this.state.condition?.conditionsStages || this.state.condition?.conditionStages
    const obj = conditionsStages[value];
    this.setState({stage: obj.stage}, () => {
      let conditions = [];
      if (this.props.conditions) {
        conditions = [...this.props.conditions];
      }

      conditions[this.props.index] = this.state;
      this.props.updateCondition(conditions, () => {});
    });
  };

  render() {
    return (
      <View
        style={[
          styles.addedItemRow,
          styles.itemRowPd,
          styles.addedItemSingle,
          styles.secSepBorderBot,
          styles.itemViewSp,
        ]}
        key={this.props.index}>
        <View style={[styles.addedItemView]}>
          <View style={[styles.addedItemCont, styles.flexCol]}>

          <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt]}>
              <View style={[styles.flexCom]}>
                <Text style={[styles.textPrimeBold, styles.activeText]} allowFontScaling={false}>
                  {this.props.index + 1}.  {this.state.condition.name}
                </Text>
              </View>
              <View style={[styles.listItemRemove]}>
                <TouchableOpacity
                  onPress={() =>
                    this.props.handleOnDeleteCondition(this.props.index)
                  }
                  style={styles.closeTouchPd}>
                  <Image
                    style={styles.closeDarkIconSm}
                    source={require('../../../assets/icons/stages-close.png')}
                  />
                </TouchableOpacity>
              </View>
          </View>

            {this.state.stages && this.state.stages.length > 0 ? (
              <View style={[styles.addedItemMore]}>
                <View style={[styles.radioSecStagesRight, styles.flexAiFs, styles.radioStagePd]}>
                  <View style={styles.radioStagesComTitles}>
                    <View style={styles.stagesTopTitle}>
                    <Text style={[styles.textPrime, styles.darkLinktext]} allowFontScaling={false}>
                      Stage:
                    </Text>
                    </View>
                  </View>
                  <RadioForm
                    style={[styles.textListView, styles.pdTopSm, styles.radioSecStageswrapCr]}
                    radio_props={this.state.stages}
                    formHorizontal={true}
                    buttonColor={'rgba(152, 152, 154, 0.6)'}
                    selectedButtonColor={'#FF8300'}
                    onPress={this.handleOnPressRadioFormConditionsStage}
                    initial={this.getStage(this.state.stage)}
                    labelWrapStyle={{paddingLeft: 5, marginRight: 10}}
                    labelStyle={{fontSize: 12, color: '#98989A'}}
                    allowFontScaling= {false}
                  />
                </View>
              </View>
            ) : null}

            

            {/* <Text style={[styles.textPrimeSm, styles.textEm]}>
                52,000 active patients
              </Text> */}
          </View>
          {/* </View> */}
        </View>

        {/* {this.state.stages && this.state.stages.length > 0 ? (
            <View style={[styles.addedItemMore, styles.addedItemMorePdSm]}>
              <View style={[styles.radioSecRight]}>
                <View style={styles.radioComTitle}>
                  <Text style={[styles.textPrimeSm, styles.textColorLight]}>Stage</Text>
                </View>
                <RadioForm
                  style={styles.radioSetRight}
                  radio_props={this.state.stages}
                  formHorizontal={true}
                  buttonColor={"rgba(152, 152, 154, 0.6)"}
                  selectedButtonColor={"#FF8300"}
                  onPress={this.handleOnPressRadioFormConditionsStage}
                  initial={this.getStage(this.state.stage)}
                  labelWrapStyle={{paddingLeft: 8, marginRight: 15}}
                  labelStyle={{fontSize: 12, color: "#98989A"}}
                />
              </View>
          </View>
        ) : null} */}
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    conditions: state.setup.conditions,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateCondition: (addNewCondition, callBack) =>
      dispatch(actions.updateCondition(addNewCondition, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepOneCondition);
