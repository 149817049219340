import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity, Keyboard} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import UIText from '../../components/Text/text';
import RadioForm from '../../modules/radioButton/RadioButton';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import Picker from '../../components/Picker/picker';
import DeletingConfirmation from '../ConfirmationBox/deletingConfirmation';
import {zIndexWorkaround} from '../../util/commonUiLogic';

const radioPropsSecond = [
  // { label: "None", value: 0 },
  {label: 'Diagnosed', value: 0},
  {label: 'Remission', value: 1},
  {label: 'Reoccurence', value: 2},
];

class Condition extends Component {
  state = {};
  constructor(props) {
    super(props);
    const array = [];

    if (this.props.data && this.props.data.conditionStages) {
      this.props.data.conditionStages.forEach(function(res, index) {
        array.push({label: res.stage, value: index});
      });
    }
    this.state = {
      ...props.data,
      year: props.data.year ? props.data.year : '',
      validName: true,
      yearFilter: props.yearFilter ? props.yearFilter : [],
      onPressOutsideState: false,
      selectedConditions: this.getSelectedConditions(),
      radioProps: array,
      conditionsStages: this.props.data.conditionStages,
      initialStage: this.getStage(
        this.props.data.conditionStages,
        props.data.stage,
      ),
      confirmBox: false,
      deleteWait: false,
    };
  }

  getSelectedConditions = () => {
    const obj = {};
    if (this.props.conditions.length > 0) {
      this.props.conditions.forEach(
        item => item && (obj[item.name?.toLowerCase()] = item.name?.toLowerCase()),
      );
    }

    return obj;
  };

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(props) {
    const {conditionValidation, yearFilter} = props;

    if (conditionValidation[props.index] === props.index) {
      this.setState({...props.conditions[props.index]});
    }
    this.setState({
      yearFilter: yearFilter,
    });
  }

  onSelectedValueChangeYear = value => {
    this.setState({year: value}, () => this.updateCondition());
  };

  handleOnPressRadioFormStatus = value => {
    switch (value) {
      case 0:
        this.setState({status: 'DIAGNOSIS'}, () => this.updateCondition());
        break;
      case 1:
        this.setState({status: 'REMISSION'}, () => this.updateCondition());
        break;
      case 2:
        this.setState({status: 'REOCCURENCE'}, () => this.updateCondition());
        break;
      default:
        this.setState({status: 'DIAGNOSIS'}, () => this.updateCondition());
        break;
    }
  };

  getStatus = value => {
    switch (value) {
      case 'DIAGNOSIS':
        return 0;
      case 'REMISSION':
        return 1;
      case 'REOCCURENCE':
        return 2;
      default:
        return 0;
    }
  };

  onPressOutside = () => {
    this.setState({onPressOutsideState: true});
  };

  onChangeTextName = selection => {
    this.setState(
      {
        name: selection,
        radioProps: [],
        conditionsStages: [],
        conditionId: 0,
        stage: '',
      }
    );
  };

  onItemPress = selection => {
    Keyboard.dismiss();
    const selectObj = selection.data || selection;
    const array = [];
    
    if (!Boolean(this.isConditionExist(selection))) {
    const conditionsStages = selectObj?.conditionsStages || selectObj?.conditionStages;
    if (conditionsStages && conditionsStages.length > 0) {
      conditionsStages.forEach(function(res, index) {
        array.push({label: res.stage, value: index});
      });
    }
    this.setState(
      {
        name: selection.name,
        radioProps: array,
        conditionsStages: conditionsStages,
        conditionId: selectObj.id,
        stage:
        conditionsStages && conditionsStages.length !== 0
            ? conditionsStages[0]?.stage
            : '',
      },
      () => this.updateCondition(),
    );
    } else {
      this.onChangeTextName(null);
    }
  };

  isConditionExist = (selection) => {
    let conditions = [];
    let filteredItems = [];
    let selectedName = selection?.name || selection;
    if (this.props.conditions) {
      conditions = [...this.props.conditions];
      filteredItems = conditions.filter(
        (res, index) => res.name?.toLowerCase() === selectedName?.toLowerCase() && index !== this.props.index,
      );
    }
    return filteredItems.length > 0;
  }

  handleOnPressRadioFormConditionsStage = value => {
    const obj = this.state.conditionsStages[value];
    this.setState({stage: obj.stage, initialStage: value}, () =>
      this.updateCondition(),
    );
  };

  getStage = (conditionsStages, value) => {
    if (conditionsStages) {
      return conditionsStages.findIndex(x => x.stage === value);
    }
  };

  updateCondition = () => {
    const obj = {...this.props.conditions[this.props.index]};
    obj.name = this.state.name;
    obj.conditionId = this.state.conditionId;
    obj.stage = this.state.stage;
    obj.status = this.state.status;
    obj.year = parseInt(this.state.year);
    this.props.profileUpdateCondition(obj, this.props.index);
  };

  processData = res => {
    let termsArray = [];
    if (res) {
      const suggestionResults = [...res.summaries];
      termsArray = suggestionResults.map(value => {
        return {name: value.name, data: value};
      });
    }

    return termsArray;
  };

  onDeleteUseCondition = () => {
    if (this.state.id && this.state.id !== -1) {
        // this.setState({deleteWait: true});
      // this.props.onDeleteUseCondition(this.state.id, false, res => {
        this.setState({
          confirmBox: false,
          deleteWait: false,
        });
      // });
      this.props.setConditionsToDelete(this.state.id);
      this.props.deleteUseCondition(this.props.index);
    } else {
      this.props.deleteUseCondition(this.props.index);
      this.props.setConditionsToDelete(false);
      this.setState({
        confirmBox: false,
        deleteWait: false,
      });
    }
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    
    return (
      <React.Fragment>
        <View style={[styles.fieldRow, zIndexWorkaround(this.props.index, this.props.conditionsCount)]}>
          <View style={[styles.fieldColDouble]}>
            <View style={[styles.fieldColMain]}>
              <View style={[styles.fieldColMax, styles.fledSpRt]}>
                <AutoComplete
                  textFieldWithTitle={true}
                  textFieldTitle="Condition"
                  remortSearch={true}
                  url="/api/conditions/byname?substring="
                  processData={this.processData}
                  triggerLength={1}
                  onChangeText={this.onChangeTextName}
                  onItemPress={this.onItemPress}
                  currentInput={this.state.name}
                  validateName={
                    this.props.validateName &&
                    (this.state.name === null || this.state.name === '')
                  }
                  onPressCustom={selection => {
                    Keyboard.dismiss();
                    if(!Boolean(this.isConditionExist(selection))) {
                      this.setState(
                        {
                          name: selection,
                          radioProps: [],
                          conditionsStages: [],
                          conditionId: 0,
                          stage: '',
                        },
                        () => this.updateCondition(),
                      );
                    } else {
                      this.onChangeTextName(null);
                    }
                  }}
                  customSectionTitle={'Add Custom Medical Condition'}
                  showCustomSection={true}
                  autoFocus={!this.state.name || this.state.name === ''}
                  selectedValues={this.state.selectedConditions}
                  editable={this.props.data.id === -1 ? true : false}
                  userTerms={this.props.conditions}
                />
                {/* </View> */}
              </View>
            </View>

            <View style={[styles.fieldColSubLg, styles.smPdItem]}>
              <View style={[styles.fieldColMax]}>
                <View style={[styles.pickerSelectionWrapper]}>
                  <Text style={[styles.textView, styles.textViewPicker]} allowFontScaling={false}>
                    <UIText
                      style={[
                        styles.textLabel,
                        styles.bold,
                        styles.textColorLight,
                      ]}
                      title="Year"
                    />
                  </Text>
                  <View
                    style={[stylesRes.dropArrowView, stylesRes.dropArrowCent]}
                    pointerEvents={'none'}>
                    <View style={stylesRes.dropArrowIcon}>
                      <Image
                        style={[styles.imgContain]}
                        source={require('../../assets/icons/dropdown-arrow.png')}
                      />
                    </View>
                  </View>
                  <Picker
                    items={this.state.yearFilter}
                    selectedValue={this.state.year ? this.state.year : ''}
                    style={[styles.pickerAreaField, styles.selectionPicker]}
                    onValueChange={this.onSelectedValueChangeYear}
                  />
                </View>
              </View>
            </View>
            <View style={[styles.listItemCloseSecSm, styles.listItemCom]}>
              <View style={[styles.listItemCloseIcon]}>
                <TouchableOpacity
                  onPress={() => {
                    this.state.name === '' || this.state.name === null
                      ? this.onDeleteUseCondition() 
                      : this.setState({confirmBox: true});
                  }}
                  style={styles.closeTouchPd}>
                  <Image
                    style={styles.closeDarkIcon}
                    source={require('../../assets/icons/close-dark.png')}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        {this.state.radioProps.length !== 0 && (
          <View style={[styles.fieldRow, styles.flexAiCt]}>
            <View style={[styles.radioSecFul, styles.comMgTopSm]}>
              <View style={[styles.radioSecStagesRight, styles.flexAiFs,]}>
                <View style={[styles.radioColTextView, styles.infoSp]}>
                  <Text style={[styles.textPrime]} allowFontScaling={false}>
                    Stage:
                  </Text>
                </View>
                <View style={[styles.radioColBtnView, styles.textListView,styles.radioSecStageswrapCr]}>
                  <RadioForm
                    style={[styles.textListView, styles.pdTopSm, styles.radioSecStageswrapCr]}
                    radio_props={this.state.radioProps}
                    formHorizontal={true}
                    buttonColor={'rgba(152, 152, 154, 0.6)'}
                    selectedButtonColor={'#FF8300'}
                    onPress={this.handleOnPressRadioFormConditionsStage}
                    initial={this.state.initialStage}
                  />
                </View>
              </View>
            </View>
          </View>
        )}
        {this.state.year !== '' && this.state.year !== null &&
          <View style={[styles.fieldRow]}>
            <View style={[styles.radioSecFul, styles.comMgTopSm, styles.comSpSm]}>
              <RadioForm
                style={[styles.radioSetCom, styles.radioSetCenter]}
                radio_props={radioPropsSecond}
                formHorizontal={true}
                initial={this.getStatus(this.state.status)}
                onPress={this.handleOnPressRadioFormStatus}
                buttonColor={'rgba(152, 152, 154, 0.6)'}
                selectedButtonColor={'#FF8300'}
              />
            </View>
          </View>
        }
        <DeletingConfirmation
          visibleModal={this.state.confirmBox}
          onRequestClose={() => {
            this.setState({confirmBox: false});
          }}
          onBackdropPress={() => {
            this.setState({confirmBox: false});
          }}
          alertTitle="Are you sure you’d like to delete this health condition?"
          handleConfirm={this.onDeleteUseCondition}
          handleCancel={() => {
            this.setState({confirmBox: false});
          }}
          deleteWait={this.state.deleteWait}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    conditions: state.profile.conditions,
    conditionValidation: state.profile.conditionValidation,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    profileUpdateCondition: (condition, index) =>
      dispatch(actions.profileUpdateCondition(condition, index)),
    onDeleteUseCondition: (id, peer, callBack) =>
      dispatch(actions.deleteUseCondition(id, peer, callBack)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Condition);
