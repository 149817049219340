import React, { Component } from 'react';
import { View } from 'react-native';
import { connect }  from 'react-redux';
import TreatmentListContent from '../../components/AttachToOption/treatmentListContent';
import SuccessBox from '../../components/ConfirmationBox/successBox';
import styles from '../../Css';
import * as actions from '../../store/actions/index';
import { TODOS } from '../../navigation/path';
import { filterTherapy } from '../../util/commonUiLogic';
import { pendoTrackEvent } from '../../util/pendoConfig';
class AttachToTreatment extends Component {
  state = {
    treatments: [],

    // Filter Therapy
    treatmentsList: {
      activity: {},
      surgery: {},
      naturalHealthProduct: {},
      medication: {},
      other: {},
    },

    selectedTreatment: null,
    saveWait: false,
    successModalVisible: false,
  };

  componentDidMount = () => {
    this.props.navigation.addListener('focus', () => {
      this.setState({treatments: this.props.treatments}, () => {
        this.setState({treatmentsList: filterTherapy(this.state.treatments)});
      });
      this.props.hideMobileMenu();
      pendoTrackEvent('To-Dos/@AttachToMedSupp+Act');
    });
    this.props.navigation.addListener('blur', () => {
      this.props.showMobileMenu();
    });
  };

  setSelectedTreatment = id => {
    this.setState({selectedTreatment: id});
  };

  handleOnSave = () => {
    this.setState({saveWait: true});
    const artifactId = this.props.treatmentsProcessed[
      this.state.selectedTreatment
    ].artifactId;
    const healthQuestion = {
      artifactId: artifactId,
      healthQuestion: {
        id: -1,
        content: this.props.saveQuestionToAttach,
        openedDate: Date.now(),
      },
    };

    this.props.onSaveHealthQuestion(
      healthQuestion,
      artifactId,
      (success) => {
        if (success) {
          this.setState({successModalVisible: true});
        }
        this.setState({saveWait: false});
        setTimeout(() => {
          this.setState({successModalVisible: false});
          this.props.navigation.push(
            TODOS.path, {
              from: this.props.route.name,
            },
          );
        }, 1500);
      },
      this.props.journalUserId,
    );
  };

  handleOnPressBackButton = () => {
    this.props.navigation.push(
      TODOS.addToDoPath, {
        from: this.props.route.name,
      },
    );
  };

  render() {
    const { navigation, route, treatmentsProcessed } = this.props;
    const { treatmentsList, saveWait, successModalVisible } = this.state;
    return (
      <View style={[styles.pageBodyWrapper]}>
        <TreatmentListContent
          index={TODOS.index}
          navigation={navigation}
          route={route}
          handleOnPressBackButton={this.handleOnPressBackButton}
          treatmentsList={treatmentsList}
          treatmentsProcessed={treatmentsProcessed}
          saveWait={saveWait}
          handleOnSave={this.handleOnSave}
          setSelectedTreatment={this.setSelectedTreatment}
        />

        <SuccessBox
          visibleModal={successModalVisible}
          message="Success! Question has been added."
        />
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    saveQuestionToAttach: state.question.saveQuestionToAttach,
    treatments: state.routines.treatments,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveHealthQuestion: (question, artifactId, callBack, journalUserId) =>
      dispatch(
        actions.saveHealthQuestion(
          question,
          artifactId,
          callBack,
          journalUserId,
        ),
      ),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttachToTreatment);
