import React from "react";
import { View, Text, Image } from "react-native";
import styles from "../../Css";
import { Emailus, Textus, Callus } from "../../components/Contactus";
import { CONTACT_PHONE_NUMBER, ORGANIZATION_NAME } from "../../util/commonUiConfig";
import SectionFooter from "./sectionFooter";
import SectionHeader from "./sectionHeader";

const fail = (props) => (
  <React.Fragment>
    <View style={[styles.staticPageContent, styles.staticPageMax]}>
      <SectionHeader />

      <View style={styles.gridRow}>
        <View style={styles.gridColFull}>
          <View style={[styles.staticImgWrapper, styles.flexJcCtAiCt]}>
            <Image
              style={styles.warningTraingleLg}
              source={require("../../assets/icons/warning-exclamation.png")}
            />
          </View>
        </View>
      </View>

      <View style={styles.gridRow}>
        <View
          style={[
            styles.gridColFull,
            styles.staticSecText,
            styles.infoPdBotImg,
          ]}
        >
          <View style={styles.staticSecTitle}>
            <Text style={[styles.textTitleMainLgBold, styles.textAlignCenter]} allowFontScaling={false}>
              This link has expired!
            </Text>
          </View>
          <View
            style={[
              styles.staticSecDesc,
              styles.pdTopSm,
            ]}
          >
            <Text
              style={[
                styles.textPrime,
                styles.textTertiary,
                styles.textAlignCenter,
              ]}
              allowFontScaling={false}
            >
              Your request to recover your email has expired or the link has
              already been used. Please contact {ORGANIZATION_NAME} customer support to
              request a new recovery link.
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.gridRow}>
        <View style={[styles.gridColFull, styles.pdBotLg]}>
          <View style={[styles.flexJcCtAiCt]}>
            <View style={[styles.contactBoxMax]}>
              <View
                style={[
                  styles.contactBlock,
                  styles.detailBoxWrapper,
                  styles.greyBoxBorder,
                ]}
              >
                <View style={[styles.detailTitleBlock]}>
                  <Text style={[styles.textTitleBold]} allowFontScaling={false}>Contact Us</Text>
                </View>
                <View style={[styles.detailInfoBlockSm]}>
                  <Text style={[styles.textPrime, styles.textColorLight]} allowFontScaling={false}>
                    We will get back to you in 48 hours
                  </Text>
                </View>

                <View style={[styles.detailItemBlock]}>
                  <Emailus />

                  <Textus phoneNumber={` ${CONTACT_PHONE_NUMBER}`} />
                  <Callus phoneNumber={` ${CONTACT_PHONE_NUMBER}`} />
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
    <SectionFooter linkPress={this.toggleLearnHowPrivacyPolicy} />
  </React.Fragment>
);

export default (fail);
