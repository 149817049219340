import React from "react";
import { Text, View, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";
import Modal from "../Modal/modal";
import ModalLayout from "../../components/ModalLayout/modalLayout";

const UnlockCommunityFeature = (props) => {
    const {visibleModal, onRequestClose} = props

    return (
        <Modal
            visible={visibleModal}
            onRequestClose={()=> onRequestClose()}
            onBackdropPress={()=> onRequestClose()}
        >
            <ModalLayout
                contPosition={"start"}
                showClose={true}
                pdCom={true}
                hidePopup={()=>onRequestClose()}
                modalSpecStyle={styles.modalContainer}
                modalDynamic={props.dynamic}
            >

                <View style={styles.modalContainer}>
                    <Image
                        style={styles.passIconSmCom}
                        source={require("../../assets/reset-password/forgot-password.png")}
                    />
                    <Text style={styles.ModalHeadProfile}>To Unlock this Community Feature:</Text>
                    <Text style={[styles.conditionText]}>Add a condition, treatment or symptom to your Zamplo profile</Text>

                    <TouchableOpacity style={[styles.cancelProfile, styles.flexJcCtAiCt, styles.okayBtn]} onPress={()=>onRequestClose()}><Text style={styles.cancelText}>Okay!</Text></TouchableOpacity>
                </View>
            </ModalLayout>
        </Modal>
    );
};
export default UnlockCommunityFeature;
