import * as variable from "../css/base/variable";

export const cssMedicationResponsive = {
  btnTabViewWrap: {
    width: "100%",
    flexDirection: "row",
    maxWidth: 375,
    ...variable.marginLtRtAuto,
  },
  btnTabViewSmWrap: {
    width: "100%",
    flexDirection: "row",
    flex: 1,
  },
  zIndexLower: {
    zIndex: 9,
  },
  secDividerSp: {
    marginBottom: 5,
  },
  pauseDetailWrapper: {
    width: "100%",
    // maxWidth: 300,
    ...variable.marginLtRtAuto,
  },
  itemStatusSlider: {
    flexBasis: 180,
    maxWidth: 180,
    alignItems: 'flex-end'
  },
  itemCenter: {
    alignItems: 'center'
  },
  healthDataView: {
    width: "100%",
    // maxWidth: 280,
  },
  btnActivity:{
    "@media (max-width: 360px)": {
      minwidth: 50,
     width:"100%"
    }
  },
  
  itemVertCenter: {
    justifyContent: 'center'
  },
  btnTabViewWrapCom: {
    width: "100%",
    flexDirection: "row",
    marginRight: 'auto',
  },
  btnTabViewLg: {
    maxWidth: 375
  },
  btnTabViewSm: {
    maxWidth: '100%'
  },
};
