import React from 'react';
import {View, Image, TouchableOpacity, Text} from 'react-native';
import styles from '../../Css';
import {PROFILE} from '../../navigation/path';
import * as FontScaling from '../../components/Text/fontScaling';
const premiumButton = props => {
  return (
    <TouchableOpacity
      onPress={() => {
        props.navigation.push(PROFILE.profilePremiumAccountPath);
      }}>
      <View style={[styles.premiumBtnWrapper,
      props.btnTop
      ? styles.premiumBtnTop
      : props.btnBot
      ? styles.premiumBtnBot
      : styles.premiumBtnCom
      
      ]}>
        <View style={styles.premiumIconView}>
          <Image
            style={styles.premiumIcon}
            source={require('../../assets/icons/premium.png')}
          />
        </View>
        <Text style={[styles.textPrimeSmBold, styles.textOrange]}  maxFontSizeMultiplier = {FontScaling.fontScaleRg}>Premium</Text>
      </View>
    </TouchableOpacity>
  );
};

export default (premiumButton);
