import React from "react";

import SucessMessage from "../../components/SucessMessage";
const setUpHOC = props => {
  return (
    <React.Fragment>
      {props.children}
      <SucessMessage />
    </React.Fragment>
  );
};

export default (setUpHOC);
