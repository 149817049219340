import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import ProgressBar from '../../modules/react-native-animated-progress';
import styles from '../../Css';

const uploadHeader = props => {
  return (
    <View>
      <View style={[styles.uploadHeaderContent]}>
      <View>
        <Text style={[styles.textPrime]}>
        {props.title}
        </Text>
      </View>

        <View style={styles.uploadHeaderRightDetails}>
          <View style={styles.uploadHeaderRightIconPd}>
            <TouchableOpacity
            onPress={props.onShowHide}
            >
            {/* {props.showContent 
              ? <Text>hide</Text>
              : <Text>show</Text>
            } */}
            {props.showContent 
              ? <Image
                    source={require("../../assets/icons/down-arrow.png")}
                    style={styles.arrowOpenLg}
                    resizeMode={"contain"}
                  />
              : <Image
                    source={require("../../assets/icons/up-arrow.png")}
                    style={styles.arrowOpenLg}
                    resizeMode={"contain"}
                  />
            }

            </TouchableOpacity>
          </View>
        
          <View>
            <TouchableOpacity
                onPress={props.onCancel}
            >
              <Image
                source={require("../../assets/icons/close-dark.png")}
                style={styles.closeDarkIconSm}
                resizeMode={"contain"}
              />
              </TouchableOpacity>
          </View>  
            
        </View>

      </View>
      <View>
        <ProgressBar
          progress={props.progress}
          height={8}
          backgroundColor="#FF8300"
        />
      </View>
    </View>
  )
}

export default (uploadHeader);