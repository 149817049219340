export function extractInformation(treatmentInfoValues) {
  if (treatmentInfoValues) {
    let medicinalIngredientsValues = [];
    let ingredientsValues = [];
    let routeValues = [];
    let ingredients = [];
    let nonmedicinalIngredients = [];

    if (treatmentInfoValues.route && treatmentInfoValues.route.length > 0) {
      routeValues = treatmentInfoValues.route[0];
    }

    if (
      treatmentInfoValues.ingredients &&
      treatmentInfoValues.ingredients.length > 0
    ) {
      ingredientsValues = treatmentInfoValues.ingredients;
    }

    if (
      treatmentInfoValues.medicinalIngredients &&
      treatmentInfoValues.medicinalIngredients.length > 0
    ) {
      medicinalIngredientsValues = treatmentInfoValues.medicinalIngredients;
    }

    if (
      treatmentInfoValues.nonmedicinalIngredients &&
      treatmentInfoValues.nonmedicinalIngredients.length > 0
    ) {
      nonmedicinalIngredients = treatmentInfoValues.nonmedicinalIngredients;
    }

    ingredientsValues.forEach((element) => {
      ingredients.push({
        ingredient: element.ingredient,
        strength: element.strength,
        strengthUnit: element.strengthUnit,
      });
    });

    medicinalIngredientsValues.forEach(element => {
      let strength = "";
      let strengthUnit = "";
      if (element.potencyAmount !== null && element.potencyAmount > 0) {
        strength = element.potencyAmount;
        strengthUnit = element.potencyAmountConstituent;
        if (element.potencyAmountUnitOfMeasure) {
          strengthUnit += " " + element.potencyAmountUnitOfMeasure;
        }
      }

      if (element.quantity !== null && (element.quantity > 0 || element.quantityUnitOfMeasure)) {
        strength = element.quantity;
        strengthUnit = element.quantityUnitOfMeasure;
      }

      ingredients.push({
        ingredient: element.properName ? element.properName : element.properNameFrench,
        strength: strength,
        strengthUnit: strengthUnit,
      });
    });

    nonmedicinalIngredients.forEach(element => {
      ingredients.push({
        ingredient: element.properName ? element.properName : element.properNameFrench,
        strength: '',
        strengthUnit: '',
      });
    });

    return {
      route: routeValues.routeOfAdministration,
      ingredients: ingredients,
    };
  } else {
    return {
      route: null,
      ingredients: null,
    }
  }
};