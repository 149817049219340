import React from 'react';
import {View, Image, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import { TAB_BREAk_POINT_START } from '../../util/platformWindowConfig';
import CollapsibleTitle from './CollapsibleTitle';


const collapsible = props => {
  return (
    <React.Fragment>
      <View
        style={[
          styles.containerCollapsible,
          styles.collapseBotBorder,
          props.topBorder
            ? styles.collapseTopBorder
            : styles.collapseTopBorderNone,
          props.borderNone ? styles.collapseBorderNone : styles.collapseBorder,
          props.botBorder
            ? styles.collapseBotBorder
            : styles.collapseBotBorderNone,
          props.ltRtBorder
            ? styles.collapseBorderLtRt
            : styles.collapseBorderCom,
        ]}>
        <TouchableOpacity
          style={styles.button}
          onPress={() =>
            props.handleOnPressCollapsible(props.contentVisibility)
          }
          // underlayColor="#f1f1f1"
          disabled={props.disabled ? true : false}>
          <View
            nativeID={props.nativeId}
            style={[
              props.titleSm
                ? styles.titleContainerCollapsibleSm
                : props.titleMd && props.windowWidth > TAB_BREAk_POINT_START
                ? styles.titleContainerCollapsibleMd
                : styles.titleContainerCollapsible,
            ]}>
            <View
              style={[
                styles.titleCollapsible,
                styles.viewCollapsible,
                styles.flexAiCt,
              ]}>
              {/* <View style={styles.collapsibleIconView}>
            <TouchableOpacity>
            <Image
                  source={require("../../assets/icons/square-plus-orange.png")}
                  style={styles.collapsiblePlusIcon}
                  resizeMode={"contain"}
                />
            </TouchableOpacity>
            </View> */}

              <CollapsibleTitle
                title={props.title}
                plusButton={props.plusButton}
                toolTipButton={props.toolTipButton}
                toolTipId={props.toolTipId}
                toolTipContent={props.toolTipContent}
                handleOnPressCollapsible={() =>
                  props.handleOnPressCollapsible(props.contentVisibility)
                }
                contentVisibility={props.contentVisibility}
                titleLg={props.titleLg}
                titleLt={props.titleLt}
                disabledScroll={props.disabledScroll}
                regularUser={props.regularUser}
                navigation={props.navigation}
                titleMd={props.titleMd}
                windowWidth={props.windowWidth}
                customTitle={props.customTitle}
                nativeId={props.nativeId}
                customTitleStyle={props.customTitleStyle}
              />
              {/* <Text style={styles.textPrimeBold}>{props.title}</Text> */}
            </View>

            {/* {props.contentVisibility ? <Text>UP</Text> : <Text>DOWN</Text>} */}
            <View 
              nativeID={props.nativeId && props.nativeId + (props.contentVisibility ? 'CollapsArrow' : 'ExpandArrow')} 
              style={[props.contentVisibility ? styles.arrowOpenView : styles.arrowClose]}>
              {Boolean(props.contentVisibility) ? (
              <Image
                style={[styles.arrowOpen]}
                source={require('../../assets/icons/arrow-down-orange.png')}
              />
            ) : (
              <Image
                style={[styles.arrowClose]}
                source={require('../../assets/icons/arrow-next.png')}
              />
            )}
            </View>
          </View>
        </TouchableOpacity>
      </View>
      {Boolean(props.contentVisibility) && (
        <React.Fragment>{props.children}</React.Fragment>
      )}
    </React.Fragment>
  );
};

export default collapsible;
