import React from "react";
import { View, Image } from "react-native";
import styles from "../../Css";

const loadinIndicator = () => {
  return (
    <View>
      <Image
        style={styles.spinLoader}
        source={require("../../assets/icons/loader.gif")}
      />
    </View>
  );
};

export default loadinIndicator;
