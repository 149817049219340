import React, { useEffect, useState, Fragment } from "react";
import {
    View,
    Text,
    TouchableOpacity,
    UIManager,
    Platform,
} from "react-native";
import { connect } from 'react-redux';
import _ from 'lodash';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import AdvancedFilterSingleItem from "./AdvancedFilterSingleItem";
import * as actions from '../../../store/actions';
import { ANDROID } from "../../../util/platformWindowConfig";

const AVAILABLE_OPTIONS = 2;

const AdvanceFilterDropDown = (props) => {
    const { advancedFilterAttachments, setOnOpenFilter,
        fetchHealthJourneyTagsList, setAdvancedFilteringAttachmentsData } = props;

    const { setAdvancedFilterCheckedItems,
        setSelectedAdvancedFilterItems,
        clearAllAdvancedFilteres,
        resetFilterComponents,
        clearSelectedTags,
        setSelectedTags,
        setDropDownItemNumber,
        removeDropDownItemNumber,
    } = props;

    const FILTER_TAG = 0; 
    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    const [tags, setTags] = useState([]);
    const [advancedFilterList, setAdvancedFilterList] = useState([{}]);
    const [openedId, setOpenedId] = useState("");

    const checkAll = (index, flag) => {
      const clone = Object.assign([], advancedFilterList);
      const data = Object.assign([], clone[index].data);
      let selectedOne = {
        ...clone[index],
        data: data.map(e => ({...e, isChecked: flag})),
      };
      clone[index] = selectedOne;
      setAdvancedFilterList(clone);
    };

    const checkOne = (flag, subIdx, mainIdx, name) => {
      const clone = Object.assign([], advancedFilterList);
      const data = Object.assign([], clone[mainIdx].data);
      const foundHit = data.findIndex(d => d.category_name === name && d.id === subIdx);
      if (foundHit !== -1) 
        data[foundHit] = {...data[foundHit], isChecked: flag};
      let selectedOne = {
        ...clone[mainIdx],
        data,
      };
      clone[mainIdx] = selectedOne;
      setAdvancedFilterList(clone);
    };


    if (Platform.OS === ANDROID) {
        UIManager.setLayoutAnimationEnabledExperimental(true);
    }

    useEffect(() => {
        setAdvancedFilteringAttachmentsData();
        // setting all the available tags items in redux to useState.

        if (setSelectedAdvancedFilterItems && setSelectedAdvancedFilterItems.length > 0) {
          const filterListArray = [];
          const extra = [];
          setSelectedAdvancedFilterItems.forEach(item => {
            const {filterType, data} = item;
            if (filterType === 'Attachments')
              advancedFilterAttachments.forEach(item => {
                if (!data.some(e => e.category_name === item.category_name))
                  extra.push({...item, isChecked: false})
              })
            let obj = {
              type: filterType === "Tag" ? "TAGS" : "ATTACHMENTS",
              data: [...data, ...extra],
              placeholder: filterType === "Tag" ? "Select Tags" : "Select Types",
            }
            filterListArray.push(obj);
          });
          setAdvancedFilterList(filterListArray)
          fetchTags(filterListArray);
        } else {
          fetchTags([]);
        }

        let tagArray = [];
        async function fetchTags(preData) {
            const fetchData = await new Promise((resolve, reject) =>
                fetchHealthJourneyTagsList('tags', 'HJ', (isFetched, data) => {
                    if (isFetched) {
                        const healthJourneyTags = data?.healthJourney ? data?.healthJourney : [];
                        resolve(healthJourneyTags)
                    } else {
                        reject(data)
                    }
                }));

            fetchData.sort();
            if (fetchData !== null && fetchData !== undefined) {
              let count = 0;
              for (let item of fetchData) {
                ++count;
                const id = Date.now() + count;
                tagArray.push({ category_name: item, isChecked: false, id });
              }
            }
            setTags(tagArray);
            const tagIndex = preData.findIndex(item => item.type === "TAGS");
            if (tagIndex !== -1) {
              const data = Object.assign([], preData[tagIndex].data)
              let tagsItem = Object.assign({}, preData[tagIndex])
              let fullList = Object.assign([], preData)
              const rowTags = data.map(item => item.category_name);
              fetchData.forEach((item, i) => {
                if (!rowTags.includes(item)) {
                  const id = Date.now() + i;
                  data.push({ category_name: item, isChecked: false, id });
                }
              }) 
              tagsItem = {
                ...tagsItem,
                data: data.sort((a, b) => a?.category_name.localeCompare(b?.category_name))
              }
              fullList[tagIndex] = tagsItem
              setAdvancedFilterList([...fullList])
            }
        }
        //eslint-disable-next-line
    }, []);

    const getDataList = type => {
      switch (type) {
        case "TAGS":
          return {data: tags, placeholder: "Select Tags"};
        case "ATTACHMENTS":
          return {data: advancedFilterAttachments, placeholder: "Select Types"};
        default:
          return {data: [], placeholder: "Unknown"};
      }
    }
    
    const filterTypesSelectionHandler = (selectedFilterType, index) => {
        // setFilterType(selectedFilterType);
        setSelectedTags(selectedFilterType);
        const clone = Object.assign([], advancedFilterList);
        let selectedOne = {
          type: selectedFilterType,
          ...getDataList(selectedFilterType),
        };
        clone[index] = selectedOne;
        setAdvancedFilterList(clone);
    }

    const onApplyHandler = () => {
      const list = Object.assign([], advancedFilterList);
      const filter = [];
      list.forEach(item => {
        const {type, data} = item;
        let obj = {
          filterType: type === "TAGS" ? "Tag" : 'Attachments',
          data: data.filter(e => e.isChecked)
        };
        filter.push(obj);
      })
      setAdvancedFilterCheckedItems(filter);
      setDropDownItemNumber(FILTER_TAG);
      setOnOpenFilter(false);
    }

    const clearFilters = () => {
        clearAllAdvancedFilteres();
        clearSelectedTags();
        resetFilterComponents(true);
        removeDropDownItemNumber(FILTER_TAG);
        setAdvancedFilterList([{}]);

      setOnOpenFilter(false);
    }

    const handleAddFilter = () => {
      const array = Object.assign([], advancedFilterList);
      array.push({});
      setAdvancedFilterList(array);
      setOpenedId(9999);
    }

    const maximumFilterCountChecker = () => {
      if (advancedFilterList.length === AVAILABLE_OPTIONS)
        return true;
      return Boolean(enableApplyButton());
    }

    const removeFilter = idx => {
      const array = Object.assign([], advancedFilterList);
      array.splice(idx, 1);
      if (array.length === 0)
        array.push({});
      setAdvancedFilterList(array);
    }

    const enableApplyButton = () => {
      return advancedFilterList.find(item => _.isEmpty(item) || !item.data.find(e => e.isChecked))
    }
    
    const setOpenFilters = () => {
      if (openedId === 9999) return;
      setOpenedId(9999);
    }

    return (
        <React.Fragment>
            <View style={[styles.postAb, styles.advanceFilterPos]} onStartShouldSetResponder={() => setOpenFilters()}>
                {/* content box for advance filter popup */}
                    <View style={[stylesRes.advanceFilterOpWd, styles.dateFilterWrapper, styles.infoPd]} dataSet={{ media: ids.advanceFilterOpWd }}>

                        {advancedFilterList.map((item, index) => (
                          <Fragment key={index}>
                            <AdvancedFilterSingleItem
                              onSeleceFilter={filterTypesSelectionHandler}
                              index={index}
                              selectedMain={advancedFilterList.map(e => e.type)}
                              item={item}
                              removeFilter={removeFilter}
                              checkAll={checkAll}
                              checkOne={checkOne}
                              openedId={openedId}
                              setOpenedId={setOpenedId}
                            />
                          </Fragment>
                        ))}
                        {/* {row()} */}

                        <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt, styles.contPdTop, styles.mgLtEighteen, styles.mgRtFifteen, { zIndex: -9 }]}>
                            <TouchableOpacity onPress={handleAddFilter} disabled={maximumFilterCountChecker()}>
                                <View style={[maximumFilterCountChecker() && { opacity: 0.5 }]}>
                                    <Text style={[styles.textPrime, styles.seaBluTxtColor, styles.textUnderline]}>
                                        + Add Filter
                                    </Text>
                                </View>
                            </TouchableOpacity>
                            <View style={[styles.flexRow, styles.flexJcCtAiCt]}>
                                <TouchableOpacity disabled={_.isEmpty([advancedFilterList[0]])} onPress={() => clearFilters()}>
                                    <View style={[styles.btnWdRemandApply, _.isEmpty(advancedFilterList[0]) ? styles.removeButtonDisabled : styles.removeButton]}>
                                        <Text style={[styles.textPrimeBold, _.isEmpty(advancedFilterList[0]) ? styles.headerTextCom : styles.textBlack]}> X Clear All</Text>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity disabled={Boolean(enableApplyButton())} onPress={() => onApplyHandler()}>
                                    <View style={[styles.btnWdRemandApply, Boolean(enableApplyButton()) ? styles.applyButtonDisabled : styles.applyButton]}>
                                        <Text style={[styles.textPrimeBold, styles.textWhite, styles.postTwo]}> Apply </Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
            </View>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        fetchHealthJourneyTagsList: state.healthJourney.fetchHealthJourneyTagsList,
        setSelectedAdvancedFilterItems: state.healthJourneyFiltering.setSelectedAdvancedFilterItems,
        advancedFilterAttachments: state.healthJourneyFilterObjects.advancedFilterAttachments,
        shouldComponentsDisabled: state.healthJourneyFiltering.shouldComponentsDisabled,
        selectedTags: state.healthJourneyFiltering.selectedTags,
        tagsCount: state.healthJourneyFiltering.tagsCount,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchHealthJourneyTagsList: (filterKey, serviceType, callback) =>
            dispatch(actions.fetchHealthJourneyTagsList(filterKey, serviceType, callback)),
        setAdvancedFilteringAttachmentsData: () =>
            dispatch(actions.setAdvancedFilteringAttachmentsData()),
        setAdvancedFilterCheckedItems: (advancedFilterList) =>
            dispatch(actions.setAdvancedFilterCheckedItems(advancedFilterList)),
        clearAllAdvancedFilteres: () =>
            dispatch(actions.clearAllAdvancedFilteres()),
        resetFilterComponents: (isDisabled) =>
            dispatch(actions.resetFilterComponents(isDisabled)),
        setSelectedTags: (tagName) =>
            dispatch(actions.setSelectedTags(tagName)),
        clearSelectedTags: () =>
            dispatch(actions.clearSelectedTags()),
        increaseSelectedTagCount: () =>
            dispatch(actions.increaseSelectedTagCount()),
        decreaseSelectedTagCount: () =>
            dispatch(actions.decreaseSelectedTagCount()),
        clearSelectedTagCount: () =>
            dispatch(actions.clearSelectedTagCount()),
        setDropDownItemNumber: (itemNumber) => 
            dispatch(actions.setDropDownItemNumber(itemNumber)),
        removeDropDownItemNumber: (itemNumber) => 
            dispatch(actions.removeDropDownItemNumber(itemNumber)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceFilterDropDown);
