import React, {Component} from 'react';
import {View, Text, Linking} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import { CONTACT_PHONE_NUMBER, CONTACT_EMAIL, ORGANIZATION_NAME } from '../../util/commonUiConfig';

class Contact extends Component {
  onPressEmail = () => {
    const email = `mailto:${CONTACT_EMAIL}?subject=Contact Us: ${ORGANIZATION_NAME}`;
    Linking.openURL(email);
  };

  onPressCallAction = () => {
    const tel = `tel://${CONTACT_PHONE_NUMBER}`;
    Linking.openURL(tel);
  };

  render() {
    const stylesRes = stylesResponsive.getProcessedStyles().styles;
    const ids = stylesResponsive.getProcessedStyles().ids;
    const { css } = this.props;
    return (
        <View style={[styles.gridWrapperFullApp]}>
          <View style={[stylesRes.contactWrapperView, css]}
           dataSet={{media: css ? ids.boxWrapperViewMediaQuery : ids.contactWrapperView}}>
            <View style={[styles.flexCol]}>
              <View style={[styles.flexAsCt, styles.contactListItem]}>
                <Text
                  style={[
                    styles.textTitleBold,
                    styles.textWhite,
                    styles.textAlignCenter,
                  ]}
                  allowFontScaling={false}
                >
                  Need Help? Contact Us
                </Text>
              </View>
              <View style={[styles.flexAsCt, styles.contactListItem]}>
                <Text
                  style={[styles.textPrime, styles.textWhite, styles.textAlignCenter, styles.textUnderline]}
                  allowFontScaling={false}
                  onPress={this.onPressEmail}
                >
                  {CONTACT_EMAIL}
                </Text>
              </View>
              <View style={[styles.flexAsCt, styles.contactListItem]}>
                <Text
                  style={[styles.textPrime, styles.textWhite, styles.textAlignCenter]}
                  allowFontScaling={false}
                  onPress={this.onPressCallAction}
                >
                  {CONTACT_PHONE_NUMBER}
                </Text>
              </View>
            </View>
          </View>
        </View>
    );
  }
}

export default (Contact);
