import React, {Component} from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import ConfirmationBox from '../../ConfirmationBox/deleteMedicationconfirmationBox';
import CannotDeleteBox from '../../ConfirmationBox/cannotDeleteBox';
import ActivityIndicator from '../../ActivityIndicator/activityIndicator';
import _ from 'lodash';
import MenuOptionPopover from '../../../screens/HealthJourney/HealthJourneyList/MenuOptionPopover';
import { ViewMedicationsOrTherapiesScreen } from '../../../screens/MedicationsOrTherapies';
import { getEntryCountUsingTreatmentId } from './getEntryCountFromDosages';
import { TAB_BREAk_POINT_START } from '../../../util/platformWindowConfig';

const FREQUENCYS = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  AS_NEEDED: "AS_NEEDED"
}
class MedicationsOrTherapiesItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeWait: false,
      deleteConform: false,
      cannotDeleteBox: false,
      hasRecordings: ViewMedicationsOrTherapiesScreen.getHasRecordings(props.treatment.id, props.journalEntriesProcessed)
    };
  }

  getFrequencyName = (type, frequency) => {
    switch (type) {
      case FREQUENCYS.DAILY:
        return `${frequency} times daily`;
      case FREQUENCYS.WEEKLY:
        return `${frequency} times weekly`
      case FREQUENCYS.AS_NEEDED:
        return `${frequency} times as needed`;
      default:
        return `${frequency} times`;
    }
  }

  ishaveAttachments = artifactId => {
    const {attachmentsCompleteDigest} = this.props;
    if (
      !artifactId ||
      !attachmentsCompleteDigest ||
      !attachmentsCompleteDigest?.userArtifactAttachments ||
      attachmentsCompleteDigest?.userArtifactAttachments?.length === 0
    )
      return false;
    return attachmentsCompleteDigest.userArtifactAttachments.some(attachment => attachment.userArtifact === artifactId);
  };

  static filterOperations = (operation, treatment) => {
    const {isActive, isPaused, isPinned, publishedBy} = treatment;
    if (isActive && !isPaused) {
      if (
        (operation.id === "RESUME") || 
        (operation.id === "EDIT" && publishedBy === "PARTNER") || 
        (operation.id === "PIN" && isPinned) ||
        (operation.id === "UNPIN" && !isPinned)
      )
        return false;
      return true;
    }

    if (isActive && isPaused) {
      if (
        (operation.id === "PAUSE") || 
        (operation.id === "EDIT" && publishedBy === "PARTNER") || 
        (operation.id === "PIN") ||
        (operation.id === "UNPIN")
      )
        return false;
      return true;
    }

    if (!isActive && !isPaused) {
      if (
        (operation.id === "STOP") || 
        (operation.id === "EDIT" && publishedBy === "PARTNER") || 
        (operation.id === "PIN") ||
        (operation.id === "UNPIN")
      )
        return false;
      return true;
    }
    return false;
  }

  getMenuOptions = () => {
    const {treatment, handleEditMedicationActivity, showSuccessPopup} = this.props;
    const operations = [
      {id: "RESUME", title: "Resume", onSelect: () => showSuccessPopup(21, null, {treatment})},
      {id: "PAUSE", title: "Pause", onSelect: () => showSuccessPopup(20, null, {treatment})},
      {id: "STOP", title: "Stop", onSelect: () => showSuccessPopup(19, null, {treatment})},
      {id: "UNPIN", title: "Unpin from Dashboard", onSelect: () => this.handlePinUnpinFromDashboard(false, treatment)},
      {id: "PIN", title: "Pin to Dashboard", onSelect: () => this.handlePinUnpinFromDashboard(true, treatment)},
      {id: "EDIT", title: "Edit", onSelect: () => handleEditMedicationActivity(treatment)},
      {id: "EDIT", title: "Delete", onSelect: () => this.setState({deleteConform: true})} //kept the same id as EDIT
    ];
    return operations.filter(operation => MedicationsOrTherapiesItem.filterOperations(operation, treatment));
  }

  handlePinUnpinFromDashboard = (flag, treatment) => {
    const {onPinToDashboard, journalUserId} = this.props;
    const treatmentToBeUpdated = Object.assign({}, treatment);
    treatmentToBeUpdated.isPinned = flag;
    this.setState({activeWait: true}, () => {
      const updatedTreatment = {
        treatment: treatmentToBeUpdated,
      };
      onPinToDashboard(updatedTreatment, () => {
        this.setState({activeWait: false});
      },
      journalUserId)
    })
  }

  handleWhenHaveData = () => {
    this.setState({deleteConform: false}, () => {
      setTimeout(() => this.setState({cannotDeleteBox: true}), 500);
    })
  }

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {activeWait, deleteConform, hasRecordings, cannotDeleteBox} = this.state;
    const { treatment, medicationsOrTherapiesSelectHandler, onDeleteTherapie, journalUserId, journalEntriesProcessed } = this.props;
    const {
      drugIngredients = null,
      medicinalIngredients = null,
      isPinned = false,
      artifactId = null,
    } = treatment;

    let styleDes = [styles.textPrimeBold];
    let styleDesSub = [styles.textPrimeMd, styles.textGreyDark];

    return (
      <View style={[styles.contListItemSingle, {backgroundColor:'#F9F9F9'} ,
      this.props.borderNone
      ? styles.borderNone
      : styles.greyBorderThin
      ]}>
        <View style={[styles.contListItemWrapper]}>
          <View style={[stylesRes.contListItemBlock, stylesRes.itemCenter, {justifyContent:'space-between'}]}>
            <TouchableOpacity
              onPress={() => {
                medicationsOrTherapiesSelectHandler(treatment.id);
              }}
              style={[
                styles.flexCom,
                styles.colPdTopBotRg,
                this.props.windowWidth > TAB_BREAk_POINT_START
                  ? styles.colPdLtZero
                  : styles.ltPdLg,
              ]}>
              <View
                style={[stylesRes.contListItemDesc, stylesRes.itemVertCenter, {maxWidth:'90%',}]}>
                <Text style={styleDes} allowFontScaling={false}>
                  <Text style={styleDes} allowFontScaling={false}>{treatment.name ? treatment.name.trim() : ''}</Text>
                  {Boolean(drugIngredients) && Boolean(drugIngredients[0]) && (
                    <Text style={styleDes} allowFontScaling={false}>
                      {' '}{drugIngredients[0].strength}
                      {drugIngredients[0].strengthUnit}
                    </Text>
                  )}
                  {Boolean(medicinalIngredients) && Boolean(medicinalIngredients[0]) && (
                    <Text style={styleDes} allowFontScaling={false}>
                      {' '}{medicinalIngredients[0].quantityUnitOfMeasureFrench}
                    </Text>
                  )}
                </Text>
                {(treatment?.dosages[0]?.quantity !== null || treatment?.dosages[0]?.units !== null) && (
                <View style={styles.specDetWrapper}>
                  <Text style={styleDesSub} allowFontScaling={false}>
                    {treatment?.dosages[0]?.quantity !== null && (
                      <Text style={styleDesSub} allowFontScaling={false}>{treatment?.dosages[0]?.quantity}{' '}</Text>
                    )}
                    {treatment?.dosages[0]?.units !== null && (
                      <Text style={styleDesSub} allowFontScaling={false}>{treatment?.dosages[0]?.units}</Text>
                    )}
                    {!_.isNil(treatment?.dosages[0]?.frequency) && (
                      <>
                        {" | "}
                        <Text style={styleDesSub} allowFontScaling={false}>{this.getFrequencyName(treatment?.dosages[0]?.frequencyType, treatment?.dosages[0]?.frequency)}</Text>
                      </>
                    )}
                  </Text>
                </View>
                )}
              </View>
            </TouchableOpacity>

            <View style={[styles.flexRow, styles.colPdRtNotify]}>
              {activeWait && (
                <View style={[styles.indicatorFixed, styles.sliderIndicator]}>
                  <ActivityIndicator pdZero={true} size="small" />
                </View>
                )}
              
              {treatment?.isActive && !treatment?.isPaused && isPinned && (
               <View style={[styles.flexJcCtAiCt, styles.contListItemIconBlock]}>
                  <Image
                    style={{width:12, height: 18}}
                    source={require("../../../assets/icons/pin-grey.png")}
                  />
               </View>
              )}
              
              {this.ishaveAttachments(artifactId) && (
              <View style={[styles.flexJcCtAiCt, styles.smPdLtRt]}>
                <TouchableOpacity onPress={() => medicationsOrTherapiesSelectHandler(treatment.id, true)}>
                  <Image
                    style={{width:18, height: 17}}
                    source={require("../../../assets/icons/attachment.png")}
                  />
                </TouchableOpacity>
                </View>
              )}
              <MenuOptionPopover
                placement="bottom"
                triggerIcon={require('../../../assets/icons/settings.png')}
                menuOptions={this.getMenuOptions()}
                itemMenuTrigger={true}
              />
            </View>
          </View>
        </View>
        {Boolean(deleteConform) && (
          <ConfirmationBox
            visibleModal={deleteConform}
            onRequestClose={() => {
              this.setState({deleteConform: false});
            }}
            selectedTherapie={treatment}
            onDeleteTherapie={onDeleteTherapie}
            journalUserId={journalUserId}
            handleWhenHaveData={this.handleWhenHaveData}
            hasRecordings={hasRecordings}
          />
        )}
        
        {/* cannot delete message box */}
        {Boolean(cannotDeleteBox) && (
          <CannotDeleteBox
            visibleModal={cannotDeleteBox}
            onBackdropPress={() => {
              this.setState({cannotDeleteBox: false});
            }}
            onRequestClose={() => {
              this.setState({cannotDeleteBox: false});
            }}
            name={treatment?.name}
            medications={true}
            count={getEntryCountUsingTreatmentId(journalEntriesProcessed, treatment.id)}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDeleteTherapie: (id, callBack, journalUserId) =>
      dispatch(actions.deleteTherapie(id, callBack, journalUserId)),
    onPauseMedication: (selectedTherapie, timeStamp, days, callBack) =>
      dispatch(
        actions.onPauseMedication(selectedTherapie, timeStamp, days, callBack),
      ),
    onActive: (selectedTherapie, val, callBack) =>
      dispatch(actions.onActive(selectedTherapie, val, callBack)),
    editTherapie: (therapie, callBack) =>
      dispatch(actions.editTherapie(therapie, callBack)),
    fetchUserAttachments: artifactId =>
      dispatch(actions.fetchUserAttachments(artifactId)),
    onResumeMedication: (selectedTherapie, active, callBack) =>
      dispatch(actions.onResumeMedication(selectedTherapie, active, callBack)),
    onSelectContact: (selectedContact, callBack) =>
      dispatch(actions.selectedContact(selectedContact, callBack)),
    onPinToDashboard: (updatedTheropy, callBack, journalUserId) =>
      dispatch(actions.updateTherapie(updatedTheropy, callBack, journalUserId)),
    showSuccessPopup: (id, graphId, deviceData) => dispatch(actions.showSuccessPopup(id, graphId, deviceData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicationsOrTherapiesItem);
