import React from 'react';
import {View, TouchableOpacity, Image} from 'react-native';
import styles from '../../Css';

const closeButton = props => {
  return (
    <View style={styles.closeBtnView} nativeID={'popupMsgClose'}>
      <TouchableOpacity onPress={props.touchableOnPress}>
        <View style={styles.closeBtnTouch} nativeID={props.nativeId}>
          {props.closeIcnType=== "SPECIAL"?(
            <Image
            style={styles.popUpCloseLgIcon}
            source={require('../../assets/icons/menu-res-active.png')}
          /> 

          ):(
          <Image
            style={styles.closeDarkIconSm}
            source={require('../../assets/icons/close-dark.png')}
          /> 
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (closeButton);
