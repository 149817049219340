import React from "react";
import { View } from "react-native";
import styles from "../../Css";
import FileUploadJournalAttachment from "../FileUpload/FileUploadJournalAttachment";

const addAttachment = (props) => {
  return (
    <View
      style={[
        styles.actionBtnWrapper,
        props.btnPosition === "left"
          ? styles.flexAiFs
          : props.btnPosition === "right"
          ? styles.flexAiFe
          : styles.flexAiCt,
      ]}
    >
      <View style={[styles.actionBtn, styles.contSmPdTopBot]}>
        <FileUploadJournalAttachment
          attachmentCategory={props.attachmentCategory}
          btnText={props.btnText}
          openCroper={props.openCroper}
          tempCapturedImage={props.tempCapturedImage}
          onUploadCompleted={props.onUploadCompleted}
        />
      </View>
    </View>
  );
};

export default (addAttachment);
