import React, { Component } from "react";
import { TouchableOpacity, Text } from "react-native";
import styles from "../../Css";
import { connect } from "react-redux";
import RibbonWrapper from "./ribbonWrapper";

class EmailSentRibbon extends Component {
  constructor(props) {
    super(props);

    const { windowHeight, windowWidth } = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth } = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  render() {
    const {
      windowWidth,
      resendEmail,
      emailSentTo,
      contactSupport,
    } = this.props;
    return (
      <RibbonWrapper windowWidth={windowWidth}>
        <React.Fragment>
          <Text style={styles.textPrime} allowFontScaling= {false}>Email sent to </Text>
          <Text style={styles.textPrimeSm} allowFontScaling= {false}>{emailSentTo} </Text>
          <TouchableOpacity onPress={resendEmail}>
            <Text style={[styles.textUnderline, styles.textPrime]} allowFontScaling= {false}>
              Resend again{" "}
            </Text>
          </TouchableOpacity>
          <Text style={styles.textPrime} allowFontScaling= {false}> or </Text>
          <TouchableOpacity onPress={contactSupport}>
            <Text style={[styles.textUnderline, styles.textPrime]} allowFontScaling= {false}>
              Contact Support
            </Text>
          </TouchableOpacity>
        </React.Fragment>
      </RibbonWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(mapStateToProps, null)(EmailSentRibbon);
