import React, {Component} from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
  Linking, Dimensions, FlatList
} from "react-native";
import {connect} from "react-redux";
import styles from "../../../Css";
import * as actions from "../../../store/actions/index";
import ActivityIndicator from "../../../components/ActivityIndicator/activityIndicator";
import SuccessBox from "../../../components/ConfirmationBox/successBox";
import ViewMoreText from "../../../components/ViewMoreText"
import { WEB } from "../../../util/platformWindowConfig";


const MINIMUM_SCREEN_WIDTH_FOR_RESOURCE_ICONS = 450;

const TAG_SEARCH_SOURCE_DIRECTORY = "search-directory";

class ResultsListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResult: this.props.searchResult ? this.props.searchResult : [],
      socialMediaList: null,
      addMyListwait: false,
      memberCount: null,
      newPostsToday: null,
      added: false,
      seeMore: false
    };
  }

  componentDidMount = () => {
    const {resourcesList} = this.props;
    const { added} = this.state;
    const result = this.props.result;

    if (this.props.result.type !== "WebResources") {
      this.props.fetchSocialMediaData(
        this.props.result.resourceId.toString(),
        (sucess, data) => {
          if (sucess) {
            let memberCount = null;
            let newPostsToday = null;
            if (data.memberCount) {
              memberCount = data.memberCount;
            }

            if (data.newPostsToday) {
              newPostsToday = data.newPostsToday;
            }

            this.setState({
              memberCount: memberCount,
              newPostsToday: newPostsToday
            });
          }
        }
      );
    }

    if(result?.resourceId && resourcesList?.length && !added){

      const foundInMyResource = resourcesList.find(r => r?.communityResponse?.resourceId === result?.resourceId);
      if(foundInMyResource){
        this.setState({
          added: true
        });
      }else{
        this.setState({
          added: false
        });
      }
    }else{
      this.setState({
        added: false
      });
    }
  };

  onPressAddToMyList = resource => {
    let replaceResource = {
      resourceId: resource.resourceId ? resource.resourceId.toString() : null,
      description: resource.description,
      title: resource.title,
      userId: this.props.journalUserId
        ? this.props.journalUserId.toString()
        : this.props.user.id.toString(),
      tags: resource.tags,
      match: resource?.match,
    };
    this.setState({addMyListwait: true});
    this.props.addReplaceMyList(replaceResource, res => {
      if (
        res === "Resource is already added to the list" ||
        res === "Successfully saved resource" ||
        /people added this resource/i.test(res)
      ) {
        this.setState({
          successModalVisible: true,
          successMessage: res,
          addMyListwait: false,
          added: true
        });
        setTimeout(() => {
          this.setState({successModalVisible: false, successMessage: ""});
        }, 1500);
        this.props.clearAddedResource();
      } else if (res === "Caregiver has permission type: READ_ONLY") {
        this.setState({
          addMyListwait: false
        });
      } else {
        this.setState({
          addMyListwait: false
        });
      }
    });
  };

  onPressTitle = url => {
    if (url && url !== "None") {
      if (Platform.OS === WEB) {
        window.open(url);
      } else {
        Linking.openURL(url);
      }
    }
  };

  onPressTag = tag => {
    let tagSearch = {
      tag: tag,
      userId: this.props.user.id.toString()
    };

    this.props.handleSearchByTag(tagSearch, TAG_SEARCH_SOURCE_DIRECTORY);
  };

  render() {
    const result = this.props.result;
    const screenWidth = Math.round(Dimensions.get('window').width);
    return (
      <View
        style={[styles.viewRowWrapper, styles.greyBorderThin, styles.boxFull]}>
        <View style={[styles.ViewRow, styles.boxFull]}>
          <View style={[styles.viewDetailSec, styles.colPdTopBotRg]}>
            <View style={[styles.viewDataFullDesc, screenWidth <= MINIMUM_SCREEN_WIDTH_FOR_RESOURCE_ICONS ? styles.viewDataFullDescSmallScreen : null]}>
              {
                (screenWidth > MINIMUM_SCREEN_WIDTH_FOR_RESOURCE_ICONS) &&
                this.getIconView(result)
              }
              <View style={[styles.viewDataView, styles.flexAiFs]}>
                {this.state.newPostsToday &&
                  this.state.newPostsToday !== "UNAVAILABLE" && (
                    <View style={[styles.viewItemSmTitle]}>
                      <Text
                        style={[
                          styles.textPrimeSm,
                          styles.textEm,
                          styles.textColorGreen
                        ]}
                        allowFontScaling={false}
                      >
                        {`${this.state.newPostsToday} new posts today`}
                      </Text>
                    </View>
                  )}
                <View style={[styles.viewItemData]}>
                  {result.type !== "Document" ? (
                    <TouchableOpacity
                      onPress={() => this.onPressTitle(result.url)}>
                      <Text style={[styles.textPrimeBold, styles.txtGreyHighlightCr]} allowFontScaling={false}>
                        {result.title}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <Text style={styles.textPrimeSmBold} allowFontScaling={false}>
                      {result.title}
                    </Text>
                  )}
                </View>
                <View style={[styles.viewItemDataCmmunity]}>
                  <Text style={[styles.textPrimeExSmBold, styles.textGrey, styles.textWeightFiveHundred]}
                        allowFontScaling={false}>
                    {result.count} person saved this resource
                  </Text>
                  {this.state.memberCount &&
                    this.state.memberCount !== "UNAVAILABLE" && (
                      <Text style={styles.textMicSm} allowFontScaling={false}>
                        {`${this.state.memberCount} total members`}
                      </Text>
                    )}
                </View>
                <View style={[styles.viewItemDataCmmunity, styles.hdOverflow]}>
                  <ViewMoreText
                    longText={result?.description}
                    moreText={'Read more'}
                    lessText={'Hide'}
                    customStyle={[styles.textPrimeExSm, styles.textBlueDark, styles.textUnderLineBlue]}
                    numberOfLines={Platform.OS === WEB ? 1 : 2}
                  />
                </View>
                {result.match && result.match.length > 0 &&
                  <View style={[styles.viewItemDataTag, styles.flexRowWrap]}>
                    <Text style={styles.textMicSm} allowFontScaling={false}>{"Match: "}</Text>
                    {result.match?.map((mtch, matchsIndex) => (
                      <TouchableOpacity
                        onPress={() => this.onPressTag(mtch)}
                        key={matchsIndex}
                      >
                        <Text style={[styles.textMicSm, styles.cancelTxtCr]} allowFontScaling={false}>
                          {mtch}
                          {matchsIndex !== result.match?.length - 1
                            ? ", "
                            : ""}
                        </Text>
                      </TouchableOpacity>
                    ))}
                  </View>
                }
                {result.tags && result.tags.length > 0 &&
                  <View style={[styles.viewItemDataTag, styles.flexRowWrap]}>
                    <Text style={styles.textMicSm} allowFontScaling={false}>{"Tags: "}</Text>
                    {result.tags.map((tag, tagsIndex) => (
                      <TouchableOpacity
                        onPress={() => this.onPressTag(tag)}
                        key={tagsIndex}
                      >
                        <Text style={styles.textMicSm} allowFontScaling={false}>
                          {tag}
                          {tagsIndex !== result.tags.length - 1
                            ? ", "
                            : ""}
                        </Text>
                      </TouchableOpacity>
                    ))}
                  </View>
                }
                {/* The placement at the end of resource is inconsistent with the My Resources tab but done this way for now
                    in order to */}
                {result.publishedBy === "PARTNER" && (
                  <View style={[styles.viewItemDataCmmunity]}>
                    <View style={[{minWidth: 75, flexDirection: "row", marginBottom: "auto", marginTop: 5}]}>
                      <Text
                        style={[styles.textMicSm, styles.greyTxtLtCr, styles.pdLgtZero, styles.txtGreyHighlightCr, styles.pdRtTwo]}
                        allowFontScaling={false}>
                        Shared by the following partners:
                      </Text>
                      <Image
                        style={styles.startCheckIcon}
                        source={require("../../../assets/icons/star-check-icon.png")}
                      />
                    </View>
                    <View>
                      <FlatList
                        data={result.partnerOrgs}
                        keyExtractor={(item, index) => 'partner-shared-name-' + index}
                        renderItem={({item}) => (
                          <Text style={styles.textMicSm}>{item}</Text>)}
                        />
                    </View>
                  </View>
                )}
              </View>
            </View>
          </View>
          <View style={[styles.dataIconSecLg, styles.colPdRtTopBotRg]}>
            <View style={styles.iconListNoPd}>
              <View style={[styles.listViewSec]}>
                {this.state.addMyListwait ? (
                  <ActivityIndicator size="small"/>
                ) : (
                  <TouchableOpacity
                    onPress={() => this.onPressAddToMyList(result)}
                    disabled={this.state.added}
                  >
                    <View
                      style={[
                        styles.submitSubSm,
                        styles.flexRow,
                        styles.flexJcCtAiCt,
                        this.state.added
                          ? styles.disableBtn
                          : styles.submitBgOrange
                      ]}
                    >
                      <View style={[styles.moreIconSec, styles.rightSpExSm]}>
                        <Image
                          style={styles.moreIcon}
                          source={require("../../../assets/icons/more-white.png")}
                        />
                      </View>
                      <Text style={[styles.btnSmTextBold]} allowFontScaling={false}>My List</Text>
                    </View>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>
        </View>
        <SuccessBox
          visibleModal={this.state.successModalVisible}
          message={this.state.successMessage}
          messageTitle={"Success!"}
        />
      </View>
    );
  }

  getIconView(result) {
    return <View style={[styles.communityBgSec, styles.communityView, styles.flexJcCt]}>
      <View style={[styles.communityProfileSec, styles.comMgTopSm]}>
        <Image
          style={styles.communityProfileIcon}
          source={{
            uri: result?.favIcon
          }}
        />
      </View>
    </View>;
  }
}

const mapStateToProps = state => {
  return {
    showLearnHow: state.uistate.showLearnHow,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    socialMediaDataList: state.communityGroups.fetchAllSocialMediaData,
    searchResult: state.communityGroups.searchResult,
    tagSearchResults: state.communityGroups.tagSearchResults,
    user: state.routines.user,
    journalUserId: state.uistate.journalUserId,
    resourcesList: state.communityGroups.fetchAllResources,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSocialMediaData: (resourceId, callBack) =>
      dispatch(actions.fetchSocialMediaData(resourceId, callBack)),
    addReplaceMyList: (replaceResource, callBack) =>
      dispatch(actions.addReplaceMyList(replaceResource, callBack)),
    clearAddedResource: () => dispatch(actions.resetAddResourceFetchedData()),
    handleSearchByTag: (tagSearch, searchSource, callBack) => {
      dispatch(actions.handleSearchByTag(tagSearch, searchSource, callBack));
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultsListItem);
