import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL_OCR } from "../store/actions/urls";

const axiosConfigCommonOcr = axios.create({
  baseURL: BASE_URL_OCR
  // withCredentials: true
});

axiosConfigCommonOcr.interceptors.request.use(
  async resp => {
    const token = Cookies.get("authToken");
    if (token) {
      resp.headers["jwt_token"] = token;
      //resp.headers["Content-Type"] = "application/json";
      // resp.headers["Access-Control-Allow-Credentials"] = "false";
      //resp.headers.authorization = "Bearer " + token;
      // resp.headers.Accept = "application/json";
      // resp.headers.AccessControlAllowOrigin = "*";

      // axiosConfigCommon.defaults.headers.post["Content-Type"] =
      //   "application/json";

      // axiosConfigCommon.defaults.headers.post["Access-Control-Allow-Origin"] =
      //   "*";

      // axiosConfigCommonOcr.defaults.headers.post[
      //   "Access-Control-Allow-Headers"
      // ] = "Content-Type, jwt_token";

      // axiosConfigCommon.defaults.headers.post[
      //   "Access-Control-Allow-Credentials"
      // ] = "false";

      // axiosConfigCommon.defaults.headers.post["withCredentials"] = "true";

      return resp;
    }
  },
  error => Promise.reject(error)
);

export default axiosConfigCommonOcr;
