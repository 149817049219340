import React from 'react';
import { View, TouchableOpacity, Linking, Platform } from 'react-native';
import TextOrCallus from './Common/textOrCallus';
import styles from '../../Css';
import { ANDROID, WEB } from '../../util/platformWindowConfig';

const contactus = props => {
  return (
    <View style={[styles.detailItemRow, styles.borderComBot]}>
      <TouchableOpacity
          disabled={Platform.OS === WEB}
          onPress={() => 
            Platform.OS === ANDROID
              ? Linking.openURL('smsto:' + props.phoneNumber)
              : Linking.openURL('sms:' + props.phoneNumber)
          }>
          <TextOrCallus phoneNumber={props.phoneNumber} isFromTextUs={true} />
        </TouchableOpacity>
    </View>
  );
};

export default (contactus);
