import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import MouseHover from '../MouseHover';
import styles from '../../Css';

const singleItem = props => {
  return (
    <View style={[styles.uploadBottomContent, styles.bgWhite]}>

      <View style={styles.uploadHeaderRightDetails}>
        <View style={[styles.uploadingBottomIcon, props.status && {opacity: .5}]}>
          <Image
            source={require("../../assets/icons/upload-button.png")}
            style={styles.uploadIconSm}
            resizeMode={"contain"}
          />
        </View>
        
        <View style={styles.imagenameSm}>
        <MouseHover id={"name"} text={props.itemName}>
          <View>
          <Text style={[styles.textPrime, props.status && styles.uploadingOpacity]} numberOfLines={1}>
            {props.itemName}
          </Text>
          </View>
          </MouseHover>
        </View>
      </View>
      
      <View style={styles.uploadHeaderRightDetails}>
        {Boolean(props.status)
          ? <View style={styles.progessuploadingSm}>
              <Text style={[styles.textPrime, styles.uploadingOpacity]}>
               uploading...
              </Text>
            </View>
            
          : <View style={styles.progresscompleteSm}>
              <Text style={[styles.textPrime, styles.textCompleteGreen]}>
               Complete
              </Text>
          </View>
        }
        {Boolean(props.status) &&
          <TouchableOpacity disabled={props.isUploading && props.progress > 99} onPress={() => props.onCancel(props.id, props.item.cancelToken)}>
            {/* <Text>x</Text> */}
            <Image
                source={require("../../assets/icons/Close-Light.png")}
                style={styles.closeLightIconExSm}
                resizeMode={"contain"}
              />

          </TouchableOpacity>
        }
      </View>
      
    </View>
  )
}

export default (singleItem);