import * as actionTypes from "./actionTypes";

export const saveQuestionToAttach = question => {
  return {
    type: actionTypes.SAVE_QUESTION_TO_ATTACH,
    saveQuestionToAttach: question
  };
};

export const addRemoveJournal = id => {
  return {
    type: actionTypes.ADD_REMOVE_JOURNAL,
    id: id
  };
};

export const removeAllJournal = () => {
  return {
    type: actionTypes.REMOVE_ALL_JOURNAL
  };
};

export const filterQuestions = filter => {
  return {
    type: actionTypes.QUESTION_FILTER,
    filter: filter
  };
};

export const clearQuestionFilter = () => {
  return {
    type: actionTypes.QUESTION_CLEAR_FILTER,
    filter: null
  };
};
