import React, { Component } from "react";
import { View } from "react-native";
import styles from "../../Css";
import { connect } from "react-redux";
import { TAB_BREAk_POINT_START } from "../../util/platformWindowConfig";

class PageContainerInline extends Component {
  constructor(props) {
    super(props);

    const { windowHeight, windowWidth } = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth } = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  render() {
    const { windowWidth } = this.props;
    return (
      <View style={[styles.pageContainerView]}>
        <View
          style={[
            styles.pageContainerViewMax,
            windowWidth > TAB_BREAk_POINT_START
            ? styles.pageContainerPd
            : styles.pageContainerPdSm
          ]}
        >
          {this.props.children}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(mapStateToProps, null)(PageContainerInline);
