import React, { Component } from "react";
import { View, TouchableWithoutFeedback } from "react-native";
import styles from "../../Css";
import { connect } from "react-redux";
import { TAB_BREAk_MAX_WIDTH } from "../../util/platformWindowConfig";

class OutlookListContainer extends Component {
  constructor(props) {
    super(props);

    const { windowHeight, windowWidth, openPopup } = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      openPopup: openPopup,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth } = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  render() {
    const {
      windowWidth,
      windowHeight,
      disabled,
    } = this.props;
    return (
      <React.Fragment>
        <View
          style={
            windowWidth > TAB_BREAk_MAX_WIDTH
              ? [styles.outlookListContainer, styles.outlookBorder]
              : styles.outlookListContainerLg
          }
        >
          {this.props.children}

          {windowWidth > TAB_BREAk_MAX_WIDTH && !disabled && (
            <TouchableWithoutFeedback
              onPress={this.props.onPressOpen}
              disabled={disabled}
            >
              <View
                style={[styles.outlookOverlayView, { minHeight: windowHeight }]}
              />
            </TouchableWithoutFeedback>
          )}
        </View>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(mapStateToProps, null)(OutlookListContainer);
