import React from 'react';
import {View, Text, Image, TouchableOpacity, ScrollView} from 'react-native';
import stylesResponsive from '../../CssResponsive';
import styles from '../../Css';
import PremiumOvalButton from '../../components/Button/premiumOvalButton';
const AddNewTherapyModalContent = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <React.Fragment>
      <View style={[stylesRes.modalScrollBlock]} dataSet={{media: ids.modalScrollBlock}}>
        <TouchableOpacity
          style={[styles.touchSpaceHeader]}
          onPress={props.closeModal}
        />
        <TouchableOpacity
          onPress={props.closeModal}
          style={[styles.closeViewTouchWrap, styles.closeViewTopLg]}>
          <Image
            style={[styles.closeIcon, styles.closeViewTouchBtnSm]}
            source={require('../../assets/icons/close.png')}
          />
        </TouchableOpacity>
        <ScrollView style={styles.modalScroller}>
          <View style={[styles.popUpBlock, styles.bgWhite]}>
            <View style={styles.popUpContentArea}>
              <View style={styles.gridSectionPopup}>
                <View style={[styles.popUpContentPdSm, styles.menuBg]}>
                  <View style={stylesRes.modalContIn}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.uploadArea}>
                          <View style={styles.uploadAreaRow}>
                            <TouchableOpacity
                              onPress={
                                props.accountType === 'regular'
                                  ? props.redirectPremiumAccount
                                  : props.scanLabel
                              }
                              disabled={props.buttonStatus}
                              style={styles.touchableComView}>
                              <View style={[stylesRes.uploadBtnSingle, stylesRes.uploadBtnLink]} dataSet={{media: ids.uploadBtnSingle}} nativeID={'medSuppActScanLabel'}>
                                <View style={stylesRes.uploadBtnLink}>
                                  <View style={stylesRes.uploadBtnImgFull}>
                                    <View style={stylesRes.uploadBtnCamImg}>
                                      <Image
                                        style={styles.imgContain}
                                        source={require('../../assets/ocr-icons/camera.png')}
                                      />
                                    </View>

                                    <View
                                      style={[
                                        stylesRes.uploadPlusImg,
                                        stylesRes.uploadCamSec,
                                      ]}>
                                      {props.accountType === 'regular' ? (
                                        <Image
                                          style={styles.imgContain}
                                          source={require('../../assets/icons/plus-grey-large.png')}
                                        />
                                      ) : (
                                        <Image
                                          style={styles.imgContain}
                                          source={require('../../assets/icons/plus-orange-large.png')}
                                        />
                                      )}
                                    </View>
                                    <View
                                      style={[
                                        styles.topBtnView,
                                        styles.topBtnViewPremium,
                                      ]}>
                                      {props.accountType === 'regular' && (
                                          <PremiumOvalButton
                                            navigation={props.navigation}
                                            closeModal={props.closeModal}
                                            fromPopup={props.fromPopup}
                                          />
                                        )}
                                    </View>
                                  </View>
                                  <View style={styles.uploadBtnText}>
                                    <Text
                                      style={[
                                        styles.textPrimeSm,
                                        styles.textAlignCenter,
                                        styles.btnMinText,
                                      ]}
                                      allowFontScaling={false}
                                      >
                                      Scan Label
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={props.manualEntry}
                              disabled={props.buttonStatus}
                              style={styles.touchableComView}>
                              <View style={stylesRes.uploadBtnSingle} dataSet={{media: ids.uploadBtnSingle}} nativeID={'medSuppActEnterManually'}>
                                <View style={stylesRes.uploadBtnLink}>
                                  <View style={stylesRes.uploadBtnImgFull}>
                                    <View style={stylesRes.uploadBtnJournalImg}>
                                      <Image
                                        style={styles.imgContain}
                                        source={require('../../assets/ocr-icons/journal.png')}
                                      />
                                    </View>
                                    <View
                                      style={[
                                        stylesRes.uploadPlusImg,
                                        stylesRes.uploadJournalSec,
                                      ]}>
                                      <Image
                                        style={styles.imgContain}
                                        source={require('../../assets/ocr-icons/plus-orange.png')}
                                      />
                                    </View>
                                  </View>
                                  <View style={styles.uploadBtnText}>
                                    <Text
                                      style={[
                                        styles.textPrimeSm,
                                        styles.textAlignCenter,
                                        styles.btnMinText,
                                      ]}
                                      allowFontScaling={false}
                                      >
                                      Enter {'\n'}
                                      Manually
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View style={styles.uploadContentArea}>
                          <View style={styles.uploadTextArea}>
                            <Text
                              style={[
                                styles.textMicSmBold,
                                styles.textGrey,
                                styles.textAlignCenter,
                              ]}
                              allowFontScaling={false}
                              >
                              The system can search by Drug or Natural Product
                              Identification Numbers. They are located on
                              Canadian product packaging or prescription labels.
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </React.Fragment>
  );
};
export default (AddNewTherapyModalContent);
