import React from "react";
import { View, Text } from "react-native";
import styles from "../../Css";
import * as FontScaling from '../../components/Text/fontScaling';
const textBox = (props) => {
  return (
    <View style={[styles.textBoxWrapper, styles.postRelative]}>
      <View
        style={[
          styles.textBoxField,
          props.fieldBg === "grey"
            ? styles.bgThinGrey
            : props.fieldBg === "blue"
              ? styles.bgBlueExLight
              : props.fieldBg === "transparent"
                ? styles.bgTransparent
                : styles.bgWhite
        ]}
      >
        <View style={[styles.dataWrapperFull, styles.flexJcCt]}>
          <Text style={[styles.textPrimeMd]}
          maxFontSizeMultiplier = {FontScaling.fontScaleField}>{props.fieldValue}</Text>
        </View>
      </View>
    </View>
  );
};

export default textBox;
