export const cssSpacing = {
  contPdTop: {
    paddingTop: 20,
  },
  contPdTopSmall: {
    paddingTop: 15,
  },
  contSmPdTopBot: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  contSmPdTopBotCr: {
    paddingTop: 11,
    paddingBottom: 15,
  },
  colPd: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  colPdSm: {
    paddingTop: 20,
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
  },
  colPdTopLtRt: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  colPdLtRtBot: {
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  colPdLtRt: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  colPdInSec: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  iconLtSp: {
    marginLeft: 10,
  },
  infoPd: {
    paddingBottom: 15,
  },
  smPdItem: {
    paddingRight: 30,
  },
  ItemRowSp: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  infoSp: {
    marginRight: 10,
  },
  infoPdLg: {
    paddingBottom: 25,
  },
  titlePdBotXSm: {
    paddingBottom: 5,
  },
  medListFullSec: {
    marginBottom: 0,
  },
  sortingPd: {
    paddingBottom: 20,
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  sortingPdSm: {
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 30,
    paddingRight: 30,
  },
  sortingFullBlock: {
    marginBottom: 30,
  },
  attachmentCont: {
    marginLeft: 10,
    marginRight: 10,
  },
  smPdLtRt: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  privacyTextBlock: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  infoPdTopBotExSm: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  infoPdTopBotSm: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  noMgImg: {
    marginLeft: 0,
  },
  pdTopSm: {
    paddingTop: 10,
  },
  listFullPdLg: {
    paddingTop: 12,
    paddingBottom: 15,
  },
  progressBarTopSm: {
    paddingTop: 10
  },
  progressBarTopLg: {
    paddingTop: 16
  },
  titleBlock: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  mgTopLg: {
    marginTop: 20,
  },
  noPdTop: {
    paddingTop: 0,
  },
  messageDetail: {
    paddingLeft: 40,
  },
  messageDetailSm: {
    paddingLeft: 30,
  },
  messageTopBotPdSm: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  textQuest: {
    paddingRight: 10,
  },
  addCommentSection: {
    paddingBottom: 15,
    paddingTop: 0,
  },
  smRtMg: {
    marginRight: 3,
  },
  mgRtSm: {
    marginRight: 7,
  },
  mgLtSm: {
    marginLeft: 7,
  },
  pdBotXs: {
    paddingBottom: 10,
  },
  breadCrumbMg: {
    marginRight: 10,
  },
  btnBottomSp: {
    paddingBottom: 120,
  },
  fiedBotPd: {
    paddingBottom: 7,
  },
  menuSingleTextView: {
    paddingRight: 30,
    paddingLeft: 15,
  },
  menuSingleTextViewSm: {
    paddingLeft: 15,
  },
  barBotSp: {
    marginBottom: 15,
  },
  barTopSp: {
    marginTop: 15,
  },
  barPd: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  lblSp: {
    marginTop: 5,
  },
  infoPdTopBot: {
    paddingBottom: 25,
    paddingTop: 20,
  },
  infoPdBotImg: {
    paddingBottom: 30,
  },
  infoPdBotLg: {
    paddingBottom: 40,
  },
  infoPdBotMd: {
    paddingBottom: 20,
  },
  infoPdBotSm: {
    paddingBottom: 10,
  },
  gridPdAllTopBotSm: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 15,
  },
  titleTopBotPd: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  listTopBotPd: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  morePdTop: {
    paddingTop: 10,
  },
  pdBotExSm: {
    paddingBottom: 5,
  },
  pdTopExSm: {
    paddingTop: 5,
  },
  colPdTopBot: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  colPdTopBotCom: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  colPdTop: {
    paddingTop: 10,
  },
  colPdTopCom: {
    paddingTop: 20,
  },
  colPdBot: {
    paddingBottom: 10,
  },
  colPdTopBotLg: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  pdBotLg: {
    paddingBottom: 20,
  },
  mgTopAddJournal: {
    marginTop: 100,
  },
  colPdLtTopBot: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingBottom: 20,
  },
  colPdLtTopBotLg: {
    paddingTop: 20,
    paddingLeft: 30,
    paddingBottom: 20,
  },
  colPdRtTopBotLg: {
    paddingTop: 20,
    paddingRight: 30,
    paddingBottom: 20,
  },
  colPdLtRtTopBotLg: {
    paddingTop: 20,
    paddingRight: 30,
    paddingBottom: 20,
    paddingLeft: 30,
  },
  colPdLtRtLg: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  colPdLtTopBotRg: {
    paddingTop: 15,
    paddingLeft: 20,
    paddingBottom: 15,
  },
  colPdRtTopBotRg: {
    paddingTop: 15,
    paddingRight: 20,
    paddingBottom: 15,
  },
  colPdLtBotRgTopSm: {
    paddingTop: 5,
    paddingLeft: 20,
    paddingBottom: 15,
  },
  colPdRtBotRgTopSm: {
    paddingTop: 5,
    paddingRight: 20,
    paddingBottom: 15,
  },
  gridItemPd: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    paddingRight: 15,
  },
  itemPd: {
    padding: 20,
  },
  imgSp: {
    marginRight: 5,
  },
  cardRowPd: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    paddingRight: 20,
  },
  cardRowPdLtRtTop: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  cardRowPdLtRt: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  cardRowInSp: {
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 15,
  },
  fileUploadBtnIcon: {
    paddingRight: 10,
  },
  addedItemRtPd: {
    paddingRight: 20,
  },
  navListLiContent: {
    paddingLeft: 8,
  },
  iconListMgSmLt: {
    marginLeft: 28,
  },
  iconSettingMgLt: {
    marginLeft: 12,
  },
  iconSettingSp: {
    marginLeft: 6,
  },
  settingSp: {
    marginLeft: 10,
    marginRight: 10,
  },
  settingPd: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingTop: 10,
  },
  selectionPdRt: {
    paddingRight: 20,
  },
  selectionPdRtLg: {
    paddingRight: 30,
  },
  contListItemIconBlock: {
    paddingRight: 15,
  },
  iconPosition: {
    top:3
},
  fieldRowNoMg: {
    marginBottom: 0,
  },
  contPdLtRtFilter: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  contListItem: {
    paddingTop: 30,
    paddingRight: 20,
    paddingLeft: 30,
    paddingBottom: 30,
  },
  contListItemMd: {
    paddingTop: 15,
    paddingRight: 30,
    paddingLeft: 30,
    paddingBottom: 15,
  },
  contListItemRg: {
    paddingTop: 10,
    paddingRight: 20,
    paddingLeft: 30,
    paddingBottom: 10,
  },
  contListItemSm: {
    paddingTop: 5,
    paddingRight: 20,
    paddingLeft: 30,
    paddingBottom: 5,
  },
  ltPdLg: {
    paddingLeft: 20,
  },
  rtPdLg: {
    paddingRight: 20,
  },
  ltPdMd: {
    paddingLeft: 10,
  },
  rtPdMd: {
    paddingRight: 10,
  },
  topPdSm: {
    paddingTop: 10,
  },
  topPdExSm: {
    paddingTop: 5,
  },
  titleTopPd: {
    paddingTop: 15,
  },
  topPdLg: {
    paddingTop: 20,
  },
  topPdXLg: {
    paddingTop: 50,
  },
  detailViewBlock: {
    paddingBottom: 20,
  },
  textSecTop: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  navListLiDetPdSm: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  navListsubPd: {
    paddingLeft: 12,
  },
  listText: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingTop: 20,
  },
  textSecPd: {
    paddingBottom: 20,
  },
  pdSmList: {
    paddingBottom: 5,
  },
  titleBlockPdTop: {
    paddingTop: 40,
  },
  titleBlockPdBotMd: {
    paddingBottom: 30,
  },
  secSpPdBot: {
    paddingBottom: 30,
  },
  secSpPdTop: {
    paddingTop: 30,
  },
  secSpPdTopSm: {
    paddingTop: 20,
  },
  secSpPdTopMd: {
    paddingTop: 35,
  },
  rightSpSm: {
    marginRight: 10,
  },
  rightSpExSm: {
    marginRight: 5,
  },
  addedItemMorePdSm: {
    paddingTop: 10,
    paddingBottom: 5,
  },
  itemMg: {
    marginLeft: 10,
    marginRight: 10,
  },
  itemMgCom: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 5,
  },
  itemViewSp: {
    marginBottom: 10,
  },
  itemViewSpLg: {
    marginBottom: 15,
  },
  closeIconSp: {
    marginTop: 5,
  },
  comSpSm: {
    marginBottom: 10,
  },
  comSp: {
    marginBottom: 15,
  },
  comSpMd: {
    marginBottom: 20,
  },
  comSpLg: {
    marginBottom: 30,
  },
  rightSpLg: {
    marginRight: 20,
  },
  rightSpExLg: {
    marginRight: 30,
  },
  comSecRowPd: {
    paddingBottom: 40,
  },
  comPd: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  comPdLt: {
    paddingLeft: 20,
  },
  spSmTopBot: {
    marginTop: 10,
    marginBottom: 10,
  },
  spComTopBot: {
    marginTop: 15,
    marginBottom: 15,
  },
  btnMgLg: {
    marginBottom: 30,
    marginTop: 30,
  },
  btnMgSm: {
    marginBottom: 10,
    marginTop: 10,
  },
  btnMgMd: {
    marginBottom: 20,
    marginTop: 20,
  },
  comMgLtRtSp: {
    marginLeft: 7,
    marginRight: 7,
  },
  contDescSubNo: {
    marginLeft: 36,
  },
  viewMgTop: {
    marginTop: 5,
  },
  viewMgTopMd: {
    marginTop: 15,
  },
  viewMgTopLg: {
    marginTop: 20,
  },
  btnRowMgLg: {
    marginBottom: 30,
    marginTop: 30,
  },
  btnRowMgRg: {
    marginBottom: 20,
    marginTop: 20,
  },
  btnRowMgSm: {
    marginBottom: 10,
    marginTop: 20,
  },
  btnRowMgTop: {
    marginTop: 15,
  },
  btnRowMgBot: {
    marginBottom: 15,
  },
  appListMg: {
    marginTop: 10,
  },
  preDataLine: {
    paddingTop: 10,
    marginBottom: 10,
  },
  noMgTop: {
    marginTop: 0,
  },
  noMgBot: {
    marginBottom: 0,
  },
  premTitleBlock: {
    paddingBottom: 25,
  },
  spaceBarLg: {
    paddingBottom: 80,
    marginBottom: 30,
  },
  toggleTextPdSm: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  subTextBlockMg: {
    marginBottom: 15,
  },
  titleExPd: {
    paddingTop: 40,
    paddingBottom: 20,
  },
  comMgTopExSm: {
    marginTop: 5,
  },
  comMgTopSm: {
    marginTop: 10,
  },
  comMgTop: {
    marginTop: 15,
  },
  comMgTopRg: {
    marginTop: 20,
  },
  comMgTopMd: {
    marginTop: 20,
  },
  comMgTopLg: {
    marginTop: 25,
  },
  comMgTopExLg: {
    marginTop: 30,
  },
  comMgBotSm: {
    marginBottom: 10,
  },
  comMgBotExSm: {
    marginBottom: 8,
  },
  comMgBot: {
    marginBottom: 15,
  },
  comMgBotRg: {
    marginBottom: 20,
  },
  comMgBotMd: {
    marginBottom: 20,
  },
  comMgBotLg: {
    marginBottom: 25,
  },
  comMgBotExLg: {
    marginBottom: 30,
  },
  colPdLtTopBotExSm: {
    paddingTop: 20,
    paddingLeft: 10,
    paddingBottom: 20,
  },
  colPdRtTopBotExSm: {
    paddingTop: 20,
    paddingRight: 10,
    paddingBottom: 20,
  },
  borderSpSm: {
    paddingBottom: 10,
    marginBottom: 10,
  },
  borderSpMd: {
    paddingBottom: 25,
    marginBottom: 25,
  },
  viewItemPd: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 15,
  },
  viewItemRad: {
    borderRadius: 5,
  },
  viewSingleMg: {
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
  },
  secBgPdTop: {
    paddingTop: 15,
  },
  secBgPdBot: {
    paddingBottom: 15,
  },
  contentBoxSingle: {
    marginBottom: 20,
  },
  borderMgLtRt: {
    marginLeft: 30,
    marginRight: 30,
  },
  radioRowSp: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  radioRowBotSp: {
    paddingBottom: 20,
  },
  radioRowTopSp: {
    paddingTop: 20,
  },
  btnRowSp: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  mapIconSec: {
    paddingBottom: 5,
  },
  viewItemDataSub: {
    paddingTop: 10,
  },
  titleSubBlock: {
    paddingTop: 5,
  },
  upIconSec: {
    paddingTop: 8,
  },
  titlePd: {
    paddingBottom: 15,
    paddingTop: 15,
    marginLeft: 20,
    marginRight: 20,
  },
  quotePdTopBot: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  navMenuText: {
    paddingTop: 4,
  },
  addedImgTitleText: {
    paddingTop: 15,
  },
  addedItemSingle: {
    paddingBottom: 15,
  },
  menuOptionsLg: {
    minWidth: 120,
  },
  colPdTopBotRg: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  setupBtnArea: {
    marginLeft: 20,
    marginRight: 20,
  },
  closeTouchPd: {
    paddingTop: 4,
    paddingRight: 4,
    paddingLeft: 4,
    paddingBottom: 4,
  },
  fieldDescCom: {
    paddingTop: 5,
    paddingBottom: 10,
  },
  infoTitle: {
    paddingBottom: 15,
  },
  colPdLtTopBotNotify: {
    paddingTop: 10,
    paddingLeft: 20,
    paddingBottom: 10,
  },
  colPdRtTopBotNotify: {
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
  },
  colPdRtNotify: {
    paddingRight: 10,
  },
  touchPd: {
    paddingTop: 5,
    paddingRight: 5,
    paddingLeft: 5,
    paddingBottom: 5,
  },
  radioStagePd: {
    paddingLeft: 18
  },
  validatePd: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  textSingle: {
    marginLeft: 4,
    marginRight: 4,
  },
  rowTitleSec: {
    paddingBottom: 10,
  },
  rowTitleTopSp: {
    marginTop: 5,
  },
  textIconView: {
    paddingRight: 8,
  },
  btnIconView: {
    paddingLeft: 10,
  },
  noPdBot: {
    paddingBottom: 0,
  },
  btnPdTopMini: {
    paddingTop: 5,
  },
  fieldRtPdSpec: {
    paddingRight: 50,
  },
  notifyPdBot: {
    paddingBottom: 10,
  },
  comSpExSm: {
    marginBottom: 5,
  },
  fieldPdRtExLg: {
    paddingRight: 45,
  },
  helpCircleMg: {
    marginTop: -5,
  },
  btnPdLgFixed: {
    paddingBottom: 100,
  },
  colPdBotLg: {
    paddingBottom: 20,
  },
  colPdBotSm: {
    paddingBottom: 10,
  },
  colPdBotExSm: {
    paddingBottom: 5,
  },
  btnAreaPd: {
    paddingBottom: 20,
  },
  headerIconPdSm: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 15,
    paddingBottom: 15,
  },
  comSpTopBot: {
    marginBottom: 5,
  },
  infoTitlePdCom: {
    paddingTop: 20,
    paddingBottom: 10,
  },
  btnPdSmFixed: {
    paddingBottom: 40,
  },
  pdTopBotZero: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  sumDataPdSm: {
    paddingTop: 15,
    paddingBottom: 20,
  },
  warningMessage: {
    paddingRight: 5,
  },
  wrapperPdLt: {
    paddingLeft: 15,
  },
  contactListItem: {
    paddingBottom: 5,
  },
  contactListItemSm: {
    paddingBottom: 3,
  },
  
  uploadHeaderRightIconPd: {
    paddingRight: 20
  },

  progessuploadingSm: {
    paddingRight: 28
  },

  progresscompleteSm: {
    paddingRight: 42
  },
  resourcePopTagTp:{
    marginTop:16
  },
  topAlgnSix: {
    paddingTop: 6
  },
  flexEnd: {
    alignItems: 'flex-end', 
    flex: 1
  },
  paddingLeft25: {
    paddingLeft: 25
  },

  pdLtTwo:{
    paddingLeft: 2
  },
  pdRtTwo:{
    paddingRight: 2
  },
  pdRtFour: {
    paddingRight:4
  },
  mgTpSeventy: {
    marginTop:70
  },
  mgRtTwelve: {
    marginRight: 12
  },
  mgRtFifty: {
    marginRight: 50
  },
  pdLtFifty: {
    paddingLeft: 50
  },
  pdRtThirtyFive: {
    paddingRight: 35
  },
  mgTpEighteen: {
    marginTop: 18
  },
  pdRtSeven: {
    paddingRight: 7
  },
  mgTpFifty: {
    marginTop: 50
  },
  mgRtFifteen: {
    marginRight: 15
  },
  mgRtEight: {
    marginRight: 8
  },
  mgLtEight: {
    marginLeft:8
  },
  mgLtTwelve: {
    marginLeft:12
  },

  mgLtFifteen: {
    marginLeft: 15
  },
  mgLtTwenty: {
    marginLeft: 20
  },
  pdLeftFour: {
    paddingLeft: 4
  },
  mgLtFive:{
    marginLeft: 5
  },
  mgLtThree:{
    marginLeft: 3
  },
  tpThirty: {
    top: 30
  },
  pdTpEleven: {
    paddingTop: 11
  },
  pdBtEight: {
    paddingBottom: 8
  },
  pdTpEight: {
    paddingTop: 8
  },
  pdTpOne: {
    paddingTop: 1
  },
  pdTpTwo: {
    paddingTop: 2
  },
  pdBtTwo:{
    paddingBottom: 2
  },
  pdBtOne: {
    paddingBottom:1
  },
  mgBtFourty: {
    marginBottom: 40
  },
  mgRtTwentyFive: {
    marginRight: 25
  },
  mgLtTwenyFive: {
    marginLeft: 25
  },
  mgBtFifty: {
    marginBottom: 50
  },
  mgRtSix:{
    marginRight: 6
  },
  mgLtSix: {
    marginLeft: 6
  },
  pdBtSixty: {
    paddingBottom: 60
  },
  pdBtEleven: {
    paddingBottom: 11
  },
  pdLtTwentyFive: {
    paddingLeft: 25
  },

  pdLtFourteen: {
    paddingLeft: 14
  },

  pdRtFourteen: {
    paddingRight: 14
  },
  noMgRtLtTpBt: {
    marginLeft: 0,
    marginBottom: 0,
    marginRight: 0,
    marginTop: 0
  },

  noPdRtLtTpBt: {
    paddingTop: 0 ,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0
  },

  noPdRt: {
    paddingRight: 0
  },

  mgRtTwo: {
    marginRight: 2
  },

  mgLtEighteen: {
    marginLeft: 18
  },

  pdBtmSix: {
    paddingBottom: 6
  },

  mgBtmSix: {
    marginBottom: 6
  },

  mgTpSix: {
    marginTop: 6
  },

  pdBtTwelve: {
    paddingBottom: 12
  },

  pdLtTwentyOne: {
    paddingLeft: 21
  },

  pdRtTwentyFive: {
    paddingRight: 25
  },
  
  mgLtThirty: {
    marginLeft: 30
  },

  pdLtZero: {
    paddingLeft: 0
  },
  
  pdRtTwelve: {
    paddingRight: 12
  },

  pdLtTwelve:{
    paddingLeft: 12
  },
  pdTpTwelve:{
    paddingTop: 12
  },
  mgRtZero: {
    marginRight: 0
  },
  mgLeftTwo: {
    marginLeft: 2
  },

  ctgryListRtLtTpBt: {
    paddingTop: 10 ,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20
  },

  pdLtSix: {
    paddingLeft: 6
  },

  mgRtEighteen:{
    marginRight: 18
  },

  boxLtRtMrginSm: {
    marginLeft: 9,
    marginRight: 9,
  },

  pdLtNineteen: {
    paddingLeft: 19
  },
  wdTen: {
    width: 10
  },
  mgTpTwelve: {
    marginTop: 12
  },
  mgRtHundred: {
    marginRight: 100
  },
  pdAllFive: {
    padding: 5
  }
};
