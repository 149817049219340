import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity, Keyboard} from 'react-native';
import styles from '../../Css';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import AllergyList from './AllergyList';
import AutoComplete from '../../components/AutoComplete/AutoComplete';

class Allergies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allergy: '',
      systemDefinedAllergy: [],
    };
  }

  componentDidMount() {
    this.getSystemDefinedAllergies();
  }

  getSystemDefinedAllergies = () => {
    this.props.updateSystemDefinedAllergies((result, data) => {
      if (result) {
        this.updateTermList(data);
      }
    });
  };

  updateTermList = data => {
    let termsArray = [];
    termsArray = data.map(value => {
      return {name: value.name, data: value};
    });
    this.setState({systemDefinedAllergy: [...termsArray]});
  };

  onChangeTextAllergie = value => {
    this.setState({allergy: value});
  };

  onUpdateAllergy = (selection, type) => {
    Keyboard.dismiss();
    const allergyName = type === 'USER' ? selection.name : selection;
    if (
      (!Boolean(this.props.addUserAllergy) && this.props.userAllergies.find(item => item.allergy?.toLowerCase() === allergyName?.toLowerCase())) || 
      (Boolean(this.props.addUserAllergy) && this.props.newUserAllergies.find(item => item.allergy?.toLowerCase() === allergyName?.toLowerCase()))
    ) {
      this.setState({allergy: ''});
      return console.warn('Allergy already exists.');
    }
    if (Boolean(this.props.addUserAllergy)) {
      if (type && type !== '') {
        this.props.addUserAllergy(
          {
            id: -(this.props.newUserAllergies.length + 1),
            allergy: allergyName,
          }
        );
        this.setState({allergy: ''});
      }
    } else {
      if (type && type !== '') {
        this.props.onUpdateAllergy(
          {
            id: -1,
            allergy: allergyName,
          },
          res => {
            this.setState({allergy: ''});
          },
          this.props.journalUserId,
        );
      }
    }
  };

  renderUserAllergies() {
    return (
      <View style={[styles.addedSecFull, styles.pdBotExSm]}>
        {this.state.userAllergies.map(res => (
          <View style={[styles.addedItemRow, styles.ItemRowPd]}>
            <View
              style={[
                styles.addedItemView,
                styles.itemMg,
                styles.itemViewSpLg,
              ]}>
              <View style={[styles.listItemCloseSecSm]}>
                <View style={[styles.listItemCloseIcon, styles.closeIconSp]}>
                  <TouchableOpacity>
                    <Image
                      style={styles.closeDarkIcon}
                      source={require('../../assets/icons/close-dark.png')}
                    />
                  </TouchableOpacity>
                </View>
              </View>
              <View style={[styles.addedItemCont, styles.smPdItem]}>
                <Text style={[styles.textPrime]} allowFontScaling={false}>{res.allergy}</Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    );
  }

  render() {
    const {allergy, systemDefinedAllergy} = this.state;
    let selectedAllergies = {};
    if (Boolean(this.props.addUserAllergy)) {
      selectedAllergies = this.props.newUserAllergies.reduce(
        (obj, item) => ({...obj, [item.allergy?.toLowerCase()]: item.allergy?.toLowerCase()}),
        {},
      );
    } else {
      selectedAllergies = this.props.userAllergies.reduce(
        (obj, item) => ({...obj, [item.allergy?.toLowerCase()]: item.allergy?.toLowerCase()}),
        {},
      );
    }
    const {newUserAllergies, addUserAllergy, deleteUserAllergy} = this.props;
    return (
      <React.Fragment>
        <View style={[styles.fieldRow, styles.zIndexAutoComplete]}>
          <AutoComplete
            textFieldWithTitle={true}
            textFieldTitle="Allergy"
            onChangeText={this.onChangeTextAllergie}
            userTerms={systemDefinedAllergy}
            onItemPress={this.onUpdateAllergy}
            currentInput={allergy}
            onPressCustom={this.onUpdateAllergy}
            customSectionTitle={'Create Custom'}
            showCustomSection={true}
            userListTitle={'Select from list of suggested Allergie(s)'}
            selectedValues={selectedAllergies}
            updateTermList={() => this.getSystemDefinedAllergies()}
          />
        </View>

        <AllergyList editMode={true} newUserAllergies={newUserAllergies} addUserAllergy={addUserAllergy} deleteUserAllergy={deleteUserAllergy} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    userAllergies: state.routines.userAllergies,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateAllergy: (allergy, callBack, journalUserId) =>
      dispatch(actions.updateUserAllergy(allergy, callBack, journalUserId)),
    onDeleteAllergy: (id, callBack) =>
      dispatch(actions.deleteUserAllergy(id, callBack)),
    updateSystemDefinedAllergies: callback =>
      dispatch(actions.updateSystemDefinedAllergies(callback)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Allergies);
