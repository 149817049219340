import theme from "../css/base/theme";
import * as variableCommon from "../css/base/variableCommon";
export const cssMenuResponsive = {
  sideBarIconContainer: {
      flexBasis: 60,
      ...variableCommon.positionFixed,
      top: 0,
      maxWidth: 60,
      backgroundColor: theme.COLOR_WHITE
    },
    sidebarMarginSm: {
      marginLeft: 0,
      "@media (min-width: 992px)": {
        marginLeft: 60
      }
    },
    breadCrumbAppBlock: {
      flexDirection: "row",
      alignItems: "center",
      marginRight: 20
    },
    addMenuTabScreen: {
      right: 0,
      position: 'absolute',
    },
};
