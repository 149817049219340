import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers
} from "react-native-popup-menu";
import styles from '../../Css';
import { pendoTrackEvent } from '../../util/pendoConfig';
import ActivityIndicatorCustom from '../ActivityIndicator/activityIndicator';
const { Popover } = renderers;

const optionArray = [
  { key: 0, text: '+ Add to Contact', nativeId: 'attachmentAddtoContact' },
  { key: 1, text: '+ Add to Entry' , nativeId: 'attachmentAddtoJournal' },
  { key: 2, text: '+ Add to Med/Activity', nativeId: 'attachmentAddtoMedAct' },
]

class CancelAndSaveButtons extends Component {
  handleOptionSelect = (key = null) => {
    const {
      redirectAttachImagesDocumentsToContact,
      redirectAttachImagesDocumentsToTreatment,
      onSaveFilesToAttach,
      redirectAttachImagesDocumentsToJournal,
      handleCreateNewRoutine,
    } = this.props;

    if (key === null) {
      handleCreateNewRoutine();
      pendoTrackEvent('Attachment@ > Save');
    }
    if (key === optionArray[0].key) {
      redirectAttachImagesDocumentsToContact();
      onSaveFilesToAttach();
      pendoTrackEvent('Attachment@ > AddtoContact');
    }
    if (key === optionArray[1].key) {
      redirectAttachImagesDocumentsToJournal();
      onSaveFilesToAttach();
      pendoTrackEvent('Attachment@ > AddtoJournal');
    }
    if (key === optionArray[2].key) {
      redirectAttachImagesDocumentsToTreatment();
      onSaveFilesToAttach();
      pendoTrackEvent('Attachment@ > AddtoMed+Act');
    }
  };

  render() {
    const { onAddImageDocumentCancelPress, files } = this.props;

    return (
      <>
        <View style={styles.fieldBtn}>
          <View style={[styles.btnBlockFull]}>
            <TouchableOpacity
              style={[
                styles.cancelButtonSm,
                styles.attachmentBtnBorder,
                styles.bgWhite
              ]}
              underlayColor="rgba(0, 153, 168, 0.8)"
              onPress={onAddImageDocumentCancelPress}
              disabled={this.props.showSpinner || this.props.disabled}
            >
              <View style={styles.btnSingleView}>
                <View style={[styles.btnSingle, styles.btnSingleCent]}>
                  <Text style={[
                   styles.textPrime,
                   styles.textAlignCenter,
                   styles.textColorGreyLight,
                   styles.bold,
                    ]}
                    allowFontScaling={false}
                    >
                    Cancel
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>
        </View>

        <View style={styles.collageColSp} />

        <View style={styles.fieldBtn}>
          <View style={styles.btnBlockFull}>
            <TouchableOpacity
              style={[
                styles.submitSm,
                styles.btnShadowCom,
                this.props.showSpinner || !Boolean(Object.keys(files).length) || this.props.disabled
                  ? styles.submitBgOrangeLight
                  : styles.submitBgOrange,
              ]}
              underlayColor="rgba(0, 153, 168, 0.8)"
              onPress={() => this.handleOptionSelect(null)}
              nativeID={'attachmentSave'}
              disabled={this.props.showSpinner || !Boolean(Object.keys(files).length) || this.props.disabled}
            >
                <View style={[styles.btnSingleView, styles.btnSingleViewCom]}>
                <View
                  style={[
                    styles.btnSingle,
                    styles.btnSingleCent,
                    styles.flexAsfs,
                    styles.btnSubmitWithIcon,
                  ]}
                >
                    {this.props.showSpinner && (
                    <View
                      style={[styles.indicatorIconView, styles.submitLoaderSm]}
                    >
                        <ActivityIndicatorCustom pdZero={true} size="small" />
                      </View>
                    )}
                    <View style={styles.btnSubmitSec}>
                      <Text style={[styles.submitBtnTextWhite, styles.bold]} allowFontScaling={false}>
                        Save
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.btnMoreViewCom}>
                      <View>
                        <Menu
                          renderer={Popover}
                          rendererProps={{
                            placement: "bottom",
                          }}
                          disabled={this.props.showSpinner || !Boolean(Object.keys(files).length) || this.props.disabled}
                        >
                          <MenuTrigger onPress={() => pendoTrackEvent('Attachment@ > SaveAccordion')}>
                            <View
                              nativeID={'attachmentSaveAccordion'}
                              style={[
                                styles.itemIconSingle,
                                styles.itemIconBtn,
                              ]}
                            >
                              <Image
                                style={[styles.playSmIcon]}
                                source={require("../../assets/icons/play.png")}
                              />
                            </View>
                          </MenuTrigger>
                          <MenuOptions
                            style={[
                              styles.dropDownList,
                              styles.menuOptionsLg,
                            ]}
                          >
                            {optionArray.map((item, itemIndex) => (
                              <View style={{padding: 1}} nativeID={item.nativeId} key={itemIndex}>
                              <MenuOption
                                style={[styles.liItem, styles.viewItemPd]}
                                onSelect={() =>
                                  this.handleOptionSelect(item.key)
                                }
                                key={`attachmentSaveOption_${itemIndex}`}
                                disabled={!Boolean(Object.keys(files).length)}
                              >
                                <Text style={styles.listTextCom} allowFontScaling={false}>
                                  {item.text}
                                </Text>
                              </MenuOption>
                              </View>
                            ))}
                          </MenuOptions>
                        </Menu>
                      </View>
                    </View>
            </TouchableOpacity>
          </View>
        </View>
      </>
    );
  }
};

export default CancelAndSaveButtons;
