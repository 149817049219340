import React from "react";
import { Image, View, Text } from "react-native";
import styles from "../../Css";

const deviceItem = props => {
  return (
    <View style={[styles.deviceSingle]}>
      <View style={styles.deviceContent}>
        <View style={styles.deviceIconView}>
          <View style={[styles.deviceIconSm, styles.flexAiCt]}>
            {props.deviceId === localStorage.getItem("x-uuid-id-ssn") && (
              <View style={styles.availableView}>
                <View style={styles.availableIcon}></View>
              </View>
            )}

            <Image
              style={[
                styles.deviceIcon,
                props.deviceType === "MOBILE"
                  ? styles.phoneIcon
                  : props.deviceType === "TABLET"
                  ? styles.tabIcon
                  : styles.laptopIcon
              ]}
              source={
                props.deviceType === "MOBILE"
                  ? require("../../assets/icons/phone.png")
                  : props.deviceType === "TABLET"
                  ? require("../../assets/icons/tablet.png")
                  : require("../../assets/icons/laptop.png")
              }
            />
          </View>
        </View>
        <View style={styles.deviceText}>
          <View style={styles.deviceTextLg}>
            <Text style={[styles.textPrimeSmBold]} allowFontScaling= {false}>{props.name}</Text>
          </View>
          <View style={styles.deviceTextSm}>
            <Text style={[styles.textPrimeSm]} allowFontScaling= {false}>{props.type}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default deviceItem;
