import React, {Component} from 'react';
import {View, TouchableOpacity, Text, Image} from 'react-native';
import Cropper from "react-cropper";
import {Slider, Rail, Handles} from 'react-compound-slider';
import ActivityIndicator from '../ActivityIndicator/activityIndicator';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';

const railStyle = {
  position: 'absolute',
  width: '100%',
  height: 3,
  borderRadius: 5,
  cursor: 'pointer',
  backgroundColor: '#C4C4C4'
};

class ProfileImageCropper extends Component {
  state = {
    zoom: [1],
    // key: 0,
  };
  scale = 1;

  componentDidMount() {
    Image.getSize(this.props.imageSrc, (width, height) => {
      if (width < 1000 || height < 1000) {
        let number = width;
        if (height < width) {
          number = height;
        }
        this.scale = 1000 / number;
      }
    });
  }

  zoom = value => {
    if (this.cropper) {
      this.cropper.zoomTo(value * this.scale);
    }
  }

  onFinishCrop = () => {
    if (!this.cropper) return;
    if (this.cropper) {
      const image = this.cropper.getCroppedCanvas().toDataURL();
      this.cropper.getCroppedCanvas({
        maxWidth: 1920,
        maxHeight: 1920,
      }).toBlob((blob) => {
        const formData = new FormData();
        const timestamp = `${new Date().getTime()}.png`;
        formData.append("imageContents", blob, timestamp);
        
        this.props.handleOnUploadProfileImag(formData, image, blob.size);
      },'png');
    }
  }

  resetCropper = () => {
    if (this.cropper) {
      this.cropper.reset();
    }
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.uploadinPicSection]}>

        <View style={[styles.uploadPicAlign]}>
          <Cropper
            src={this.props.imageSrc}
            style={{height: '100%', width: '100%'}}
            // zoomTo={this.state.zoom[0] / 100 < 0.24 ? 0.24 : this.state.zoom[0] / 100}
            background={false}
            ref={(instance) => {
              this.cropper = instance;
            }}
            movable={true}
            zoomOnWheel={false}
            initialAspectRatio={1}
            aspectRatio={1}
            guides={true}
            dragMode="move"
            viewMode={2}
            className={'Crop-circle'}
          />
        </View>
        
        <View style={stylesRes.updateScale} dataSet={{media: ids.updateScale}}>
          <View style={[styles.uploadScaleSec]}>
          
              <Slider
                mode={1}
                step={1}
                domain={[1, 100]}
                rootStyle={{
                  position: 'relative',
                  width: '90%',
                  touchAction: 'none',
                }}
                onUpdate={zoom => this.setState({ zoom }, () => this.zoom(zoom[0] / 100))}
                // onChange={zoom => this.setState({ zoom })}
                values={this.state.zoom}
              >
                <Rail>
                  {({ getRailProps }) => (
                    <div style={railStyle} {...getRailProps()} />
                  )}
                </Rail>
                <Handles>
                  {({ handles, getHandleProps }) => (
                    <div className="slider-handles">
                      {handles.map(handle => (
                        <div
                          key={handle.id}
                          role="slider"
                          aria-valuemin={1}
                          aria-valuemax={100}
                          aria-valuenow={handle.value}
                          style={{
                            left: `${handle.percent}%`,
                            position: 'absolute',
                            marginLeft: '-11px',
                            marginTop: '-4px',
                            zIndex: 2,
                            width: 12,
                            height: 12,
                            cursor: 'pointer',
                            borderRadius: '50%',
                            boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
                            backgroundColor: '#4CADB8'
                          }}
                          {...getHandleProps(handle.id)}
                        />
                      ))}
                    </div>
                  )}
                </Handles>

                <View style={[styles.uploadScaleSecTxt]}>
                  <Text style={[styles.textPrimeBold]}>Scale</Text>
                </View>

              </Slider>
              <TouchableOpacity 
                style={[styles.helpCircleComView, styles.reverseCircleAlign]} 
                onPress={() => this.setState({zoom: [1]}, () => this.resetCropper())}
              >
                <Image
                  style={[styles.uploadReverseIcn]}
                  source={require('../../assets/profile/reverse-icon.png')}
                />
              </TouchableOpacity>
            </View>
            <View>
              {this.props.uploadError &&
              !this.props.fileUploadWait && (
                <View style={[styles.errorMessage, styles.errorSm]}>
                  <Image
                    style={[styles.iconWarning, styles.imgSp]}
                    resizeMode={'cover'}
                    source={require('../../assets/icons/warning.png')}
                  />
                  <Text style={styles.warningText} allowFontScaling={false}>
                    {this.props.uploadErrorMesage}
                  </Text>
                </View>
              )}
          </View>
        </View>

        <View style={[styles.uploadSecBtns]}>
          <TouchableOpacity 
            style={[styles.uploadSectTwoBtn, styles.uploadSecCancelBt]} 
            //onPress={() => this.setState({zoom: [50], key: this.state.key + 1})}
            onPress={() => this.props.cancelCropping()}
          >
            <Text style={[styles.updatedProfileSaveBtnTxt]}>Cancel</Text>
          </TouchableOpacity>

          <TouchableOpacity 
            style={[styles.uploadSectTwoBtn, styles.uploadSecSveBt]} 
            onPress={this.onFinishCrop}
          >
            <View style={[styles.uploadActivityInd]}>
              {this.props.fileUploadWait && <ActivityIndicator size="small" />}
            </View>
            <Text style={[styles.updatedProfileSaveBtnTxt]}>Save</Text>
          </TouchableOpacity>
        </View>

      </View>
    );
  }
}

export default (ProfileImageCropper);
