import React from 'react';
import {View, Text} from 'react-native';
import PageContainer from '../../../PageLayout/PageContainer';
import styles from '../../../../Css';
import stylesResponsive from '../../../../CssResponsive';
import SearchField from '../../../Search/searchField';
import PrimaryButtonDark from '../../../Button/primaryButtonDark';
import KeyboardAwareScrollViewHoc from '../../../KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import ChartCardItem from '../../ChatCard/CardItem';
import ActivityIndicator from '../../../ActivityIndicator/activityIndicator';

const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
const ChatHistoryComponent = props => {
  const {
    hasPeerProfile,
    handleOnRedirect,
    loading,
    onPressChatItem,
    myConversationList,
    id,
    searchedKeyword,
    onSearchInputValueChange,
    chatInitialized
  } = props;

  return (
    <KeyboardAwareScrollViewHoc>
      <PageContainer smScreenFull={true}>
        <View style={[styles.secArea, styles.colPdTopCom, styles.pageBotPd]}>
          <View style={[styles.gridRow, styles.colPdLtRt]}>
            {loading || !chatInitialized ? (
              <Text style={styles.textTitleBold}>
                Connecting to chat server… <ActivityIndicator pdLarge={true} />
              </Text>

            ) : (
              <View style={styles.gridColFull}>
                <View style={[styles.searchSection]}>
                  <View style={styles.flexCol}>
                    {/* button */}
                    <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt]} >
                      <View style={{bottom:10}}>
                        <Text style={styles.textTitleBold}>
                          Message
                        </Text>
                      </View>
                      <View style={stylesRes.newConnectionBtnCr} dataSet={{media: ids.newConnectionBtnCr}}>
                        <PrimaryButtonDark
                          touchableOnPress={handleOnRedirect}
                          btnPosition={'full'}
                          btnText={'+ New Message'}
                          btnShadowNone={true}
                          disabledColorChange={true}
                          disabled={!Boolean(hasPeerProfile && Boolean(myConversationList?.length))}
                          btnStyleSpec={styles.btnSpecPd}
                          newConnection={true}
                          nativeId={'communityNewMessage'}
                        />
                      </View>
                    </View>
                      {/* search bar */}
                    <View style={styles.fieldColSearch}>
                      <SearchField
                        searchText={searchedKeyword ? searchedKeyword : ''}
                        placeholder="Search messages"
                        onChangeText={onSearchInputValueChange}
                        showClearBtton={
                          searchedKeyword && searchedKeyword !== ''
                            ? true
                            : false
                        }
                        clearFilter={() => onSearchInputValueChange(null)}
                        nativeId={'communityChatsSearch'}
                        eventName={'Community > @Chats > Search'}
                      />
                    </View>
    
                  </View>
                </View>

                <View style={[styles.chatSection, styles.contPdTop]}>
                  {myConversationList &&
                    myConversationList.length > 0 &&
                    myConversationList.map((res, index) => {
                      return (
                        <ChartCardItem
                          key={index}
                          item={res}
                          onPressItem={onPressChatItem}
                          sid={id}
                          searchedKeyword={searchedKeyword}
                        />
                      );
                    })}
                </View>
              </View>
            )}
          </View>
        </View>
      </PageContainer>
    </KeyboardAwareScrollViewHoc>
  );
};

export default (ChatHistoryComponent);
