import React, { Component } from "react";
import { View, ScrollView, Image, TouchableOpacity } from "react-native";
import styles from "../../../Css";
import Modal from "../../Modal/modal";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";

import SetupLayout from "./SetupLayout";

class SetupModal extends Component {
  constructor(props) {
    super(props);
    const { visibleModal, activeTab, windowWidth, windowHeight } = props;
    this.state = {
      visibleModal: visibleModal,
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      activeTab: activeTab,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { visibleModal, activeTab, windowHeight, windowWidth } = props;
    if (
      visibleModal !== state.visibleModal ||
      activeTab !== state.activeTab ||
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth
    ) {
      return {
        visibleModal: visibleModal,
        activeTab: activeTab,
        windowWidth: windowWidth,
        windowHeight: windowHeight,
      };
    }

    return null;
  }

  render() {
    const { visibleModal, activeTab } = this.state;
    const { closeModal, tabs, setActiveTab, windowHeight } = this.props;
    let modalMinHeight = windowHeight;
    if (windowHeight > 600) {
      modalMinHeight = 550;
    } else {
      modalMinHeight = windowHeight - 60;
    }
    return (
      <Modal visible={visibleModal}>
        <ScrollView
          style={styles.scrollViewModal}
          contentContainerStyle={styles.scrollViewMax}
        >
          <View style={[styles.modalLargeWrapper]}>
            <View
              style={[styles.popUpBlock, styles.bgWhite, styles.popUpRad]}
              pointerEvents="auto"
            >
              <View style={[styles.popUpContentArea]}>
                <View style={[styles.contentModalWrapper]}>
                  <View
                    style={[
                      styles.modalMainSection,
                      styles.flexJcSb,
                      { minHeight: modalMinHeight },
                    ]}
                  >
                    <View style={styles.modalCloserWrapper}>
                      <TouchableOpacity style={styles.closeTouchPd} onPress={closeModal}>
                        <Image
                          style={styles.closeDarkIconLg}
                          source={require("../../../assets/icons/close-dark.png")}
                        />
                      </TouchableOpacity>
                    </View>
                    <SetupLayout
                      activeTab={activeTab}
                      closeModal={closeModal}
                      tabs={tabs}
                      setActiveTab={setActiveTab}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.routines.user,
    windowWidth: state.uistate.windowWidth,
    windowHeight: state.uistate.windowHeight,
    visibleGraph: state.rollinggraphs.dashboardGraph,
    dashboardProcessed: state.routines.dashboardProcessed,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHideShowGraph: (value) => dispatch(actions.onHideShowGraph(value)),
    selectGraph: (graph, callBack) =>
      dispatch(actions.selectGraph(graph, callBack)),
    isFromLogin: () => dispatch(actions.isFromLogin()),
    addTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.createTherapie(addNewTherapie, callBack, journalUserId)),
    onSaveQuestionToAttach: (question) =>
      dispatch(actions.saveQuestionToAttach(question)),
    resetRollingGraph: () => dispatch(dispatch(actions.resetRollingGraph())),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupModal);
