import React, {useState, useMemo, useEffect, Fragment, useCallback} from 'react';
import {
  Image,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  TextInput
} from 'react-native';
import _ from "lodash";
import styles from '../../../Css';
import stylesResponsive from "../../../CssResponsive";
import SelectCategoryTypeFilter from '../Picker/SelectCategoryTypeFilter';
import AdvanceFilterExpandableComponent from './ExpandableComponent/AdvanceFilterExpandableComponent';
import TickButton from '../../../components/CheckBox/CheckBox';
import {zIndexWorkaround} from '../../../util/commonUiLogic';

const AdvanceFilterSingleItem = props => {
  const {
    onSeleceFilter,
    index,
    selectedMain,
    item = {},
    removeFilter,
    checkAll,
    checkOne,
    openedId,
    setOpenedId,
    fromMobileFilter
  } = props;
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {type, placeholder, data = []} = item;
  const [open, setOpen] = useState(false);
  const [dropdownData, setDropdownData] = useState(data);
  const [searchKeyword, setSearchKeyword] = useState('');
  const match = (item, key) => item?.category_name && item.category_name?.toLowerCase().includes(key?.toLowerCase());

  useEffect(() => {
    if (!_.isEqual(dropdownData, data)) {
      setDropdownData(data);
    }
    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (openedId !== index && open) {
      setOpen(false);
      setSearchKeyword('');
    }else if(!open){
      setSearchKeyword('');
    }
    //eslint-disable-next-line
  }, [openedId, open])


  const handleOpen = () => {
    !open && setOpenedId(index);
    setOpen(e => !e);
  };

  const onSearchSubCategory = useCallback(
    _.debounce(text => setSearchKeyword(text), 500),
    [],
  );

  const checkedCount = () => data.reduce((acc, val) => (val?.isChecked ? ++acc : acc), 0);

  const isAllChecked = () => {
    const checked = checkedCount();
    return checked === data.length;
  };

  const allChecked = useMemo(isAllChecked, [data]);
  const selectedCountSub = useMemo(checkedCount, [data]);
  return (
    <View style={zIndexWorkaround(index, 2)} onStartShouldSetResponder={e => e.stopPropagation()}>
      {/* adding filter option */}
      <View
        style={[
          styles.flexRow,
          styles.flexJcSb,
          styles.flexAiCt,
          styles.mgRtTwelve,
          styles.comMgTopSm,
          stylesRes.singleFilter,

        ]}dataSet={{media: ids.singleFilter}}>
        {/* two filters */}
        <View style={[styles.flexRow,]}>
          {/* first filter */}
          <View style={[styles.mgLtFive, styles.rightSpExSm, styles.whOneThirtyTwo]}>
            {/* select Filter picker */}
            <SelectCategoryTypeFilter
              selectedFilterType={value => onSeleceFilter(value, index)}
              selected={selectedMain}
              type={type}
              index={index}
              setOpenedId={setOpenedId}
              openedId={openedId}
            />
          </View>
          {/* second filter */}
          <View style={[styles.flexCol]}>
            <View style={styles.flexRow}>
              <TouchableOpacity onPress={handleOpen} disabled={!type} style={{zIndex: -9999}}>
                <View
                  style={[
                    styles.monthWdCustom,
                    styles.flexRow,
                    styles.flexAiCt,
                    styles.flexJcSb,
                    styles.mgLtFive,
                    styles.rightSpExSm,
                    stylesRes.descTwomgHundredSixty,
                    // styles.wdOneSixty,
                  ]} dataSet={{media: ids.descTwomgHundredSixty}}>
                  <Text
                    style={[
                      styles.btTwo,
                      styles.postRelative,
                      styles.iconLtSp,
                      styles.textPrime,
                      styles.textGrey,
                      selectedCountSub > 0 ? styles.fntDark :{ opacity: 0.5, top:1,},
                    ]}>
                    {`${
                      selectedCountSub > 0
                        ? selectedCountSub + ' Selected'
                        : placeholder || "Select Tags"
                    }`}{' '}
                  </Text>
                  <View style={styles.infoSp}>
                    <Image
                      style={[styles.customArrowWdHg]}
                      source={require('../../../assets/health-journey-icons/dropdown-arrow-black.png')}
                    />
                  </View>
                </View>
              </TouchableOpacity>
              {/* close icons */}
              {!_.isEmpty(item) &&
                <TouchableOpacity onPress={() => removeFilter(index)} style={{alignSelf: 'center'}}>
                  {/* <View style={[styles.centerSecion, styles.iconLtSp]}> */}
                    <Image
                      style={styles.closeIcnGrey}
                      source={require('../../../assets/health-journey-icons/Close-circle-bg.png')}
                    />
                  {/* </View> */}
                </TouchableOpacity>
              }
            </View>
            {open && data.length > 0 ? (
              <View>
                <View style={[styles.pstAbTpZeroRgtZero, styles.mgLtFive]}>
                  <View
                    style={[
                      styles.monthWdCustom,
                      styles.fullHgHundredPerc,
                      styles.minHgFifty,
                      styles.mxHgTwoFifty,
                      styles.borderTpLtRadiusZero,
                      styles.borderTpRtRadiusZero,
                      // styles.wdTwoHundred,
                      stylesRes.descTwomgHundredSixty,
                      styles.bgWhite,
                    ]} dataSet={{media: ids.descTwomgHundredSixty}}>
                    {/* --- for the attachments instead of wdTwoFiftySeven add wdTwoHundred--- */}
                    {type === 'TAGS' ? (
                      <View
                        style={[
                          styles.flexRow,
                          styles.flexJcFs,
                          styles.flexAiCt,
                          styles.infoPdTopBotExSm,
                          styles.cardRowPdLtRt,
                          styles.fourtyHg,
                        ]}>
                        <View style={[styles.tpOne]}>
                          <Image
                            style={{
                              width: 15,
                              height: 15,
                            }}
                            source={require('../../../assets/health-journey-icons/search-icon.png')}
                          />
                        </View>
                        <TextInput
                          style={[
                            styles.textPrimeSm,
                            styles.wdFullPer,
                            styles.mgLtFive,
                            styles.fourtyHg,
                          ]}
                          placeholder={'Search My Tags'}
                          placeholderTextColor={'#C4C4C4'}
                          underlineColorAndroid="transparent"
                          onChangeText={value => onSearchSubCategory(value)}
                        />
                      </View>
                    ) : null}
                    <View style={[styles.dateFilterLine]} />
                    <ScrollView nestedScrollEnabled = {true} >
                      {!Boolean(searchKeyword) && (
                        <TouchableOpacity onPress={() => checkAll(index, !allChecked)}>
                        <View style={styles.colBgLight}>
                          <View
                            style={[
                              styles.flexRow,
                              styles.flexJcFs,
                              styles.flexAiCt,
                              styles.menuSingleTextViewSm,
                              styles.ctgryListRtLtTpBt,
                              styles.pdBtTwo,
                            ]}>
                            <View style={[styles.tickButtonSm]}>
                                {allChecked ? (
                                    <TickButton
                                        imagePath={'tickButton'}
                                        toggleTickCallback={() => checkAll(index, !allChecked)}
                                        tickStyle={{ marginRight: 0 }}
                                    />
                                ) : (
                                    <TickButton
                                        imagePath={'untickButton'}
                                        toggleTickCallback={() => checkAll(index, !allChecked)}
                                        tickStyle={{ marginRight: 0 }}
                                    />
                                )}
                            </View>
                            <View style={[styles.textPrime, styles.flexAsCt]}>
                              <Text> Select All </Text>
                            </View>
                          </View>
                          <View style={[styles.dateFilterLineWithMargin]} />
                        </View>
                        </TouchableOpacity>
                      )}
                      {dropdownData.filter(item => match(item, searchKeyword)).map((item, idx) => (
                        <Fragment key={idx}>
                          <AdvanceFilterExpandableComponent
                            item={item}
                            index={idx}
                            checkedItem={(isChecked, idx, name) =>
                              checkOne(isChecked, idx, index, name)
                            }
                            fromMobileFilter={fromMobileFilter}
                          />
                        </Fragment>
                      ))}
                    </ScrollView>
                  </View>
                </View>
              </View>
            ) : null}
          </View>
        </View>
      </View>
    </View>
  );
};
export default AdvanceFilterSingleItem;
