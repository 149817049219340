import React, { Component } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import styles from "../../../Css";
import * as paths from "../../../navigation/path";
import {peerToPeerTabs, peerToPeerTabPath} from '../../../Constant/index';
import { pendoTrackEvent } from "../../../util/pendoConfig";

class CommunityMenuItem extends Component {
  constructor(props) {
    super(props);
    const { isSelected } = props;
    this.state = {
      isSelected: isSelected,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { isSelected } = props;
    if (isSelected !== state.isSelected) {
      return {
        isSelected: isSelected,
      };
    }

    return null;
  }
  render() {
    const { navigation, onPressMenuItem, tabMenuIconView, hasNotifications, OnSetPeerToPeerActiveTab, route, connectedUserCount, handleTabMenuClick } = this.props;
    const { isSelected } = this.state;
    const { title } = paths.PEER_TO_PEER;
    return (
      <View style={styles.mainNavItem}>
        <TouchableOpacity
          onPress={() => {
            if (handleTabMenuClick) {
              handleTabMenuClick()
            } else {
              if (onPressMenuItem) {
                onPressMenuItem();
              }
              let params = '';
              if (connectedUserCount > 0) {
                OnSetPeerToPeerActiveTab(peerToPeerTabs.chats);
                params = peerToPeerTabPath.chats;
              } else {
                OnSetPeerToPeerActiveTab(peerToPeerTabs.myNetwork);
                params = peerToPeerTabPath.myNetwork;
              }
              pendoTrackEvent('Menu > Community');
              navigation.reset({
                index: 0,
                routes: [{
                  name: route,
                  params: { communityTab: params }
                }],
              });
            }
          }}
        >
          <View
            nativeID={'menuCommunity'}
            style={[
              styles.navListLiDet,
              styles.navListLiDetPdSm,
              Boolean(isSelected) ? styles.mainNavSelected : styles.mainNavDefault,
            ]}
          >
            <View style={[styles.navListLiImgCom]}>
              <Image
                style={styles.navMainMenuIcon}
                source={Boolean(isSelected)
                  ? require("../../../assets/main-nav/community-active.png")
                  : require("../../../assets/main-nav/community.png")
                }
              />
            {/* for ipad and tab */}
              {Boolean(tabMenuIconView) && Boolean(hasNotifications) && (
                <View style={[styles.notifyAvailableMenu, styles.notifyAvailableMenuSm]} />
            )} 
            </View>
            {!Boolean(tabMenuIconView) && (
            <View style={[styles.navListLiContent]}>
              <Text
                style={[
                  Boolean(isSelected)
                    ? [styles.textPrimeBold, styles.textOrange]
                    : styles.textPrime,
                ]}
                allowFontScaling={false}
              >
                {title}
              </Text>
              {/* for desktop */}
                {!Boolean(tabMenuIconView) && Boolean(hasNotifications) && (
                <View style={[styles.notifyAvailableMenu]} />
              )}
            </View>
            )}
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default (CommunityMenuItem);
