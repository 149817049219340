import React, {Component} from 'react';
import {View} from 'react-native';
import styles from '../../Css';
import SmallTitle from '../Text/smallTitle';
import stylesResponsive from '../../CssResponsive';
import BiologicalSexitem from './ biologicalSexitem';

class BiologicalSex extends Component {
  constructor(props) {
    super(props);
    this.items = [
      {
        label: 'Male',
        value: 'MALE',
      },
      {
        label: 'Female',
        value: 'FEMALE',
      },
      {
        label: 'Intersex',
        value: 'INTERSEX',
      },
      {
        label: 'Prefer not to answer',
        value: 'PREFER_NOT_TO_ANSWER',
      },
    ];
    const {sex} = props;
    this.state = {
      sex: sex,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {sex} = props;
    if (sex !== state.sex) {
      return {
        sex: sex,
      };
    }
    return null;
  }

  onSelect = value => {
    const {onSelect} = this.props;
    onSelect(value);
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {sex} = this.state;
    return (
      <>
        <SmallTitle title={"What's your sex?"} />

        <View style={styles.fieldRow}>
          <View style={[styles.radioBtnWrapper]}>
            <View style={[stylesRes.radioBtnListSingle]}>
              {this.items.map((res, index) => (
                <BiologicalSexitem
                  key={'bs_item' + index}
                  {...res}
                  isSelected={sex === res.value}
                  onSelect={() => this.onSelect(res.value)}
                />
              ))}
            </View>
          </View>
        </View>
      </>
    );
  }
}

export default (BiologicalSex);
