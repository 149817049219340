import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    selectedId:null
};

const selectCaregiver = (state, action) => {
  return updateObject(state, { selectedId: action.id });
};



const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_CAREGIVER:
      return selectCaregiver(state, action);
    default:
      return state;
  }
};

export default reducer;
