import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "../../../../Css";
import Collapsible from "../../../Collapsible/Collapsible";
import * as FieldDetails from "../../../TextInput/fieldDetails";
import EditNotes from "./notes";

class Notes extends Component {
  constructor(props) {
    super(props);
    let { entry } = props;
    if (entry !== null) {
      entry = entry.trim();
    }
    this.state = {
      collapsible: {
        notes: entry === null || entry === "" ? false : true,
      },
      entry: entry,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { newJournal, isCopyPrevious } = props;
    if (newJournal && isCopyPrevious && isCopyPrevious()) {
      const { entry } = newJournal;
      return {
        entry: entry,
        collapsible: {
          notes: entry === null || entry === "" ? false : true,
        },
      };
    }
    return null;
  }

  handleOnPressCollapsible = (key) => {
    let collapsibleTemp = this.state.collapsible;
    let val = collapsibleTemp[key];
    collapsibleTemp[key] = !val;
    this.setState({ collapsible: collapsibleTemp });
  };

  onChangeTextNote = (val) => {
    const { updateEntry } = this.props;
    this.setState(
      {
        entry: val,
      },
      () => updateEntry(val)
    );
  };

  render() {
    const { entry } = this.state;
    return (
      <Collapsible
        title="Notes"
        contentVisibility={this.state.collapsible.notes}
        handleOnPressCollapsible={() => this.handleOnPressCollapsible("notes")}
        style={[styles.collapseTitle]}
        customTitleStyle={true}
        plusButton={true}
        topBorder={true}
        nativeId={'journalNotes'}
      >
        <EditNotes
          onChangeTextNote={this.onChangeTextNote}
          noteValue={entry ? entry : ""}
          fieldBg={FieldDetails.BGGREY}
          mgZero={true}
        />
      </Collapsible>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    newJournal: state.routineTemplate.newJournal,
  };
};

export default connect(mapStateToProps, null)(Notes);
