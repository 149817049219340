import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  userDeviceMap: null,
};

const setUserDevices = (state, action) => {
  return updateObject(state, {
    userDeviceMap: action.userDeviceMap
  });
};

const updateUserDevices = (state, action) => {
  let deviceMapToBeUpdated = [...state.userDeviceMap];
  state.userDeviceMap.forEach((item, index) => {
    if (item.deviceName === action.device) {
      let newItem = {...item, connectionStatus: action.status ? 'CONNECTED' : 'DISCONNECTED'}
      deviceMapToBeUpdated[index] = newItem
    }
  });
  return updateObject(state, {
    userDeviceMap: deviceMapToBeUpdated
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DEVICE_MAP:
      return setUserDevices(state, action);
    case actionTypes.UPDATE_DEVICE_MAP:
      return updateUserDevices(state, action);
    default:
      return state;
  }
};
export default reducer;
