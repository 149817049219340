import axios from 'axios';
import Cookies from 'js-cookie';
import {BASE_URL} from '../store/actions/urls';

const axiosConfigCommonNotif = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

axiosConfigCommonNotif.interceptors.request.use(async resp => {
  const token = Cookies.get('authToken');
  if (token) {
    resp.headers.authorization = 'Bearer ' + token;
    resp.headers.Accept = 'application/json';
    resp.headers.AccessControlAllowOrigin = '*';
  }
  return resp;
});

export default axiosConfigCommonNotif;
