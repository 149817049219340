import theme from "./base/theme";
import { Platform } from "react-native";
import * as variable from "./base/variable";
import * as variableShadow from "./base/variableShadow";
export const cssProfile = {
  passHintIn: {
    position: "absolute",
    display: "flex",
    right: 10,
    top: 0,
    zIndex: 999,
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  passHintView: {
    width: 35,
    height: 35,
    justifyContent: "center",
    alignItems: "center",
  },
  verifyCodeField: {
    height: 51,
    width: "100%",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
    borderColor: theme.COLOR_BORDER,
  },
  fieldIconView: {
    position: "absolute",
    display: "flex",
    right: 10,
    top: 0,
    zIndex: 999,
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  fieldIconTouchView: {
    justifyContent: "center",
    flex: 1,
    height: "100%",
  },
  helpCircleComView: {
    marginLeft: 8,
  },
  reverseCircleAlign: {
    position:'relative', 
    bottom: 2
  },
  submitBtnShadow: {
    ...variableShadow.submitBtnShadow,
  },
  fieldHeight: {
    minHeight: 76,
  },
  btnShadowCom: {
    ...variableShadow.btnShadowCom,
  },
  indicatorIconView: {
    position: "absolute",
  },
  indicatorLtSm: {
    left: -30,
  },
  indicatorLtMd: {
    left: -25,
  },
  indicatorRtSwtich: {
    right: 60,
  },
  indicatorLtExSm: {
    left: -15,
  },
  checkBoxView: {
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  linkSecView: {
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  contCenterView: {
    justifyContent: "center",
    flexDirection: "row",
  },
  textDescWrapper: {
    maxWidth: "100%",
    paddingLeft: 30,
    minHeight: 15,
  },
  textSubDetail: {
    maxWidth: "100%",
  },
  settingSmSec: {
    width: 15,
    paddingLeft: 10,
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
  },
  settingsGrey: {
    tintColor: theme.COLOR_BORDER,
  },
  textIconCenter: {
    position: "absolute",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
    zIndex: 9999,
  },
  textDescViewWrapper: {
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  textDescView: {
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  switchView: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end",
    minWidth: 100,
  },
  fieldBtnSpSm: {
    width: 10,
  },
  telCodeField: {
    height: 51,
    width: "100%",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
    borderColor: theme.COLOR_BORDER,
  },
  fieldIconTouchSmView: {
    justifyContent: "center",
    height: "100%",
  },
  notifyCircle: {
    width: 10,
    height: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
    backgroundColor: theme.COLOR_ORANGE,
  },
  viewAvailableIcon: {
    alignSelf: "flex-start",
    minWidth: 20,
    flexBasis: 20,
  },
  linkViewItemIconSecLg: {
    flexBasis: 20,
    alignItems: "center",
  },
  toolTipRow: {
    flexDirection: "row",
  },
  fieldBtnMini: {
    flex: 0.7,
  },
  pdBotPage: {
    paddingBottom: 45,
  },
  textIconSmWrapper: {
    position: "absolute",
    top: 3,
    left: 0,
    zIndex: 9999,
  },
  forgotPassView: {
    paddingLeft: 5,
    alignItems: 'flex-end'
  },
  rightZero: {
    right: 0,
  },
  passHintViewTouch: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: 45,
    paddingRight: 10,
  },
  contactContainer: {
    justifyContent: 'space-evenly',
    width: 270,
    alignSelf: 'center',
    marginTop: 15
  },

  switchBgLight: {
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_13,
  },
  
  popoverBgBorder: {
    borderColor: theme.COLOR_GREY_LIGHT_EX_18,
    borderWidth: 1,
  },
  topArrowMg: {
    marginLeft: 155,
  },
  topArrowWrapper: {
    backgroundColor: 'transparent', 
    marginBottom: -1, 
    zIndex: 9, 
    width: 235,
  },
  telCodeFieldLg: {
    height: 61,
    width: "100%",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
    borderColor: theme.COLOR_BORDER,
    paddingTop: 10,
  },
  helpCircleComViewMg: {
    marginLeft: 2,
  },
  btnSkip: {
    paddingTop: 14,
    paddingBottom: 14,
  },
  envelopeLgIcon: {
    width: 55,
    height: 44,
    tintColor: theme.COLOR_ORANGE,
    resizeMode: "contain",
  },
  emailAvailableView: {
    position: 'absolute',
    right: -10,
    top: -10,
  },
  emailAvailableIcon: {
    width: 25,
    height: 25,
    borderWidth: 4,
    borderColor: theme.COLOR_WHITE,
    backgroundColor: '#008FA1',
    borderRadius: 25,
  },
  lineSp: {
    paddingBottom: 3,
  },
  staticPageMax: {
    width: '100%',
    ...variable.marginLtRtAuto,
    maxWidth: 700,
    paddingRight: 20,
    paddingLeft: 20,
  },
  caregiverDefaultIcon: {
    width: 191,
    height: 138,
    resizeMode: "contain",
  },
  staticImgWrapper: {
    paddingTop: 20,
    paddingRight: 10,
    paddingBottom: 20,
    paddingLeft: 10,
  },
  staticSecText: {
    ...variable.marginLtRtAuto,
    maxWidth: 480,
  },
  staticPagePd: {
    paddingRight: 7,
    paddingLeft: 7,
  },
  staticPageFooterText: {
    paddingTop: 25,
    paddingBottom: 25,
  },
  staticBtnWrapper: {
    paddingBottom: 20,
  },
  staticPageContent: {
    paddingTop: 30
  },
  layoutOverlay : {
    backgroundColor: 'rgba(0,0,0,0.3)',
    width: '100%',
    left: 0,
    height: '100%',
    zIndex: 99,
    position: 'absolute',
  },
  ribbonZindexHigh: {
    zIndex: 99
  },
  playAreaView: {
    maxWidth: 190,
    flexBasis: 190,
    paddingRight: 10,
  },
  playAreaViewSm: {
    maxWidth: 135,
    flexBasis: 135,
    paddingRight: 10,
  },
  premiumIconMd: {
    width: 30,
    height: 30,
  },
  premiumIconAreaSm: {
    flexBasis: 35,
    maxWidth: 35
  },
  mediaPlay: {
    width: 8,
    height: 11,
    marginRight: 7,
    marginLeft: 10,
    marginTop: 3,
  },
  premiumTextAreaSm: {
    paddingLeft: 7,
  },
  ribbonClose: {
    tintColor: theme.COLOR_GREY_MEDIUM,
    width: 12,
    height: 12,
  },
  ribbonWrapper: {
    minHeight: 50,
    justifyContent: 'center'
  },
  messageRibbonText: {
    alignItems: 'baseline'
  },
  lightOverlay : {
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  primaryButtonMax: {
    minWidth: 210,
  },
  modalLayoutWidth: {
    width: 310,
  },
  modalLayoutWidthLg: {
    width: 420,
  },
  modalLayoutWidthExLg : {
    width: 520,
  },
  videoLoader: {
    height: 160, 
    width: '100%', 
    position: 'absolute', 
    alignItems: 'center', 
    justifyContent: 'center'
  },
  videoOverlayWrapper: {
    position: 'absolute', 
    width: '100%', 
    height: '100%', 
    backgroundColor: 'rgba(0, 0, 0, 0.7)', 
    zIndex: 99999999
  },
  warningTraingleLg: {
    width: 55,
    height: 50,
  },
  verifyboxBlock: {
    minHeight: 375,
    paddingBottom: 20,
  },
  skipBtnView: {
    flexBasis: 140,
    maxWidth: 140,
  },
  colorItemPreview: {
    borderColor: theme.COLOR_BORDER,
    borderWidth: 1,
    width: 24,
    height: 24,
    borderRadius: 5,
  },
  refreshIcon: {
    width: 16,
    height: 16,
  },
  caregiverMessageIcon: {
    width: 60,
    height: 60,
    resizeMode: "contain",
  },
  flexSpec: {
    flex: 0.7
  },
  ribbonPdLtTopBot: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
  },
  ribbonPdRtTopBot: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
  },
  touchFullWrapper: {
    alignSelf: 'stretch',
    justifyContent: 'center'
  },
  storeImgWrapper: {
    flexBasis: 230,
    maxWidth: 230,
  },
  storeIcon: {
    width: 200,
    height: 60,
    resizeMode: "contain",
  },
  storeImgView: {
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: 'center',
  },
  qrImg: {
    width: 165,
    height: 165,
    resizeMode: "contain",
  },
  qrContWrapper: {
    paddingLeft: 15,
    paddingRight: 15,
    alignSelf: "flex-end"
  },
  storeWrapper: {
    paddingLeft: 2,
    paddingRight: 2,
  },
  storeMainWrapper: {
    width: "100%",
    maxWidth: 360,
    ...variable.marginLtRtAuto,
    paddingTop: 15,
    marginBottom: -15
  },
  storeIconSm: {
    width: 150,
    height: 45,
    resizeMode: "contain",
  },
  storeIconRg: {
    width: 174,
    height: 52,
    resizeMode: "contain",
  },
  communityTileIcon: {
    width: 109,
    height: 115,
    resizeMode: "contain",
  },

  // updated profile Styles

  updatedProfileClsIcn: {
    width: 15,
    height: 15
  },

  updatedProfileHeading: {
    ...variable.fontPrimaryText,
    fontWeight: 'bold', 
    textAlign:'center'
  },

  updatedProfileContentSec: {
    flexDirection:'row', 
    alignItems:'center', 
    justifyContent:'space-between'
  },

  updatedProfileImageStyle: {
    borderRadius: 100
  },

  updatedProfileImage: {
    width: 85, 
    height: 85,
    borderRadius: 85
  },

  updatedProfileOptions: {
    flexDirection:'column'
  },

  updatedProfileUploadImage: {
    flexDirection:'row', 
    //marginBottom:12
  },

  updatedProfileUploadIcn: {
    width: 22, 
    height:15
  },

  updatedProfileUploadTxt: {
    ...variable.fontPrimaryText,
    color:theme.COLOR_ORANGE,
    textDecorationLine:'underline',
    paddingLeft:8
  },

  updatedProfileRemovePhoto: {
    flexDirection:'row', 
    //marginTop:8, 
    paddingLeft:3,
    alignItems:'center',
    justifyContent:'center'
  },

  updatedProfileRemoveIcn: {
    width: 17, 
    height:20
  },

  updatedProfileRemoveTxt: {
    ...variable.fontPrimaryText,
    color:theme.COLOR_GREY_LIGHT_EX_21,
    textDecorationLine:'underline', 
    paddingLeft:10
  },

  updatedProfileSaveBtn: {
    ...variableShadow.shadowStyleEleven,
    backgroundColor:'#4CADB8', 
    borderRadius:5, 
    alignItems:'center',
    justifyContent:'center',
    height: 41
  },

  updatedProfileSaveBtnTxt: {
    ...variable.fontPrimaryText,
    color:theme.COLOR_WHITE,
    fontWeight:'bold'
  },
  updatedProfileUploadPhoto: {
    top: 0, 
    width: '100%', 
    height: 15, 
    position: 'absolute'
  },
  uploadinPicSection:{
    marginTop: 10
  },
  uploadPicAlign:{
    height: 268, 
    width: '100%', 
    borderRadius: 8, 
    overflow: "hidden"
  },

  uploadScaleSec: {
    marginTop: 40, 
    flexDirection: "row", 
    alignItems:'center'
  },
  uploadScaleSecTxt: {
    position:'absolute', 
    bottom:3
  },
  uploadReverseIcn: {
    width: 20, 
    height: 23
  },
  uploadSecBtns: {
    marginTop: 30, 
    flexDirection: "row",
    justifyContent: 'center'
  },
  uploadSecBtnsMobile: {
    alignItems: 'center',
    marginTop: 20, 
    flexDirection: 'row', 
    zIndex: 9999
  },
  uploadSectTwoBtn:{
    ...variableShadow.shadowStyleEleven,
    width: 132, 
    height: 41, 
    borderRadius:5, 
    alignItems:'center',
    justifyContent:'center'
  },
  uploadSectTwoBtnMbl: {
    width: 112, 
    height: 35,
  },
  uploadSecCancelBt:{
    backgroundColor:theme.COLOR_GREY_TEXT_DARK, 
  },
  uploadSecSveBt:{
    backgroundColor:theme.COLOR_BLUE_13,
    marginLeft:16
  },
  uploadActivityInd: {
    position:'absolute', 
    left: 0
  },
  uploadmgLT: {
    marginLeft: 13
  },

  uploadImageEditOption:{
    position: 'absolute',
    bottom: 15,
    left: 5,
    ...Platform.select({
      android: {
        bottom: 18,
        left: 6
      },
    }),
  },

  uploadImageEditHalfCircle: {
    width: 76, 
    height: 23,
    ...Platform.select({
      android: {
        width: 73, 
        height: 21,
      },
    }),
  },
  
  uploadImageEditText:{
    position:'relative', 
    bottom: 20,
    alignItems:'center',
    justifyContent:'center' 
  },
};
