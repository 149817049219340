import React, {useRef} from "react";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";
import { View, Image, TouchableOpacity, Text, ScrollView } from "react-native";
import Popover from "../../Popover";
import GraphListItem from "./graphListItem";
import { TAB_BREAk_POINT_START } from "../../../util/platformWindowConfig";

const GraphNameDropDown = (props) => {
  let touchable = useRef();
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {
    name,
    onPressDropDown,
    showGraphList,
    dashboard,
    graphId,
    viewGraph,
    anchorEl,
    windowWidth,
    onPressCreateNewGraph,
    displayPremiumCrown,
    handleClose,
  } = props;

  // const handleClick = (event) => {
  //   onPressDropDown(event);
  // };

  // const close = () => {
  //   onPressDropDown(null);
  // };

  return (
    <View style={[stylesRes.graphDrownParentCr]} dataSet={{media: ids.graphDrownParentCr}}>
      <TouchableOpacity
        onPress={onPressDropDown}
        ref={touchable}
        style={[styles.dropDownTextTouch]}
        nativeID={'dashboardGraphDrop'}>
          
        <View style={[styles.dropDownTextCom, styles.graphWrapperRt]}>
          <Text
            style={[
              windowWidth > TAB_BREAk_POINT_START
                ? styles.textTitleMd
                : styles.textPrime,
            ]}
            allowFontScaling={false}
            numberOfLines={1}
          >
            {name}
          </Text>
        </View>
        <View style={styles.statusIconSelectCom}>
          <View style={[stylesRes.graphDropDownPolygonIcon]} dataSet={{media: ids.graphDropDownPolygonIcon}}>
            {/* {showGraphList ? (
            <Image
              style={[styles.graphPolygonDrpDnIcn]}
              source={require("../../../assets/icons/dropdown-polygon.png")}
            />
          ) : ( */}
            <Image
              style={[styles.graphPolygonDrpDnIcn]}
              source={require("../../../assets/icons/dropdown-polygon.png")}
            />
            {/* )} */}
          </View>
        </View>
      </TouchableOpacity>

      {/* {showGraphList && ( */}
        <Popover
          open={showGraphList}
          handleClose={handleClose}
          anchorEl={anchorEl}
          touchable={touchable}
        >
          <View style={[styles.dropDownOptionsView, styles.overflowHidden]}>
            <View
              style={[styles.graphSelectionHeight, styles.overflowAuto]}
            >
              <View style={[stylesRes.graphSelectionAreaCr]} dataSet={{media: ids.graphSelectionAreaCr}}>
              <ScrollView nestedScrollEnabled={true}>
                {dashboard &&
                  dashboard.map((item, index) => (
                      <GraphListItem
                        key={"graph_list_item_" + index}
                        item={item}
                        viewGraph={viewGraph}
                        graphId={graphId}
                      />
                  ))}
              </ScrollView>
              </View>
            </View>
            <View style={[styles.fixedBoxWrapper]}>
              <TouchableOpacity onPress={onPressCreateNewGraph}>
                <View
                  style={[
                    styles.dropDownOption,
                    styles.dropDownOptionPdLt,
                    styles.topBorderNone,
                  ]}
                  nativeID={displayPremiumCrown ? 'dashboardGraphDropPremium' : 'dashboardGraphDropCreateNew'}
                >
                  <View style={[stylesRes.dropDownOptionText]}>
                    <Text
                      style={[
                        styles.textPrimeSmBold,
                        !displayPremiumCrown
                          ? styles.textOrange
                          : styles.textGrey,
                      ]}
                      allowFontScaling={false}
                    >
                      <Text
                        style={[
                          styles.textPrimeBold,
                          !displayPremiumCrown
                            ? styles.textOrange
                            : styles.textGrey,
                        ]}
                        allowFontScaling={false}
                      >
                        +
                      </Text>{" "}
                      Create a New Graph
                    </Text>
                  </View>
                {Boolean(displayPremiumCrown) && (
                    <View style={[stylesRes.dropDownOptionBtn]}>
                      <Image
                        style={styles.premiumIconRg}
                        source={require("../../../assets/icons/premium-main.png")}
                      />
                    </View>
                  )}
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </Popover>
      {/* )} */}
    </View>
  );
};

export default (GraphNameDropDown);
