import React from "react";
import { View, Text, Image} from "react-native";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";
import {getSortedReminderList} from '../../../util/commonUiLogic';

const timeMap = {
  '00': "12",
  '01': "1",
  '02': "2",
  '03': "3",
  '04': "4",
  '05': "5",
  '06': "6",
  '07': "7",
  '08': "8",
  '09': "9",
  1: "01",
  2: "02",
  3: "03",
  4: "04",
  5: "05",
  6: "06",
  7: "07",
  8: "08",
  9: "09",
  10: "10",
  11: "11",
  12: "12",
  13: "1",
  14: "2",
  15: "3",
  16: "4",
  17: "5",
  18: "6",
  19: "7",
  20: "8",
  21: "9",
  22: "10",
  23: "11",
  0: "12",
};

const ReminderType = {
  DAILY : 'Daily',
  MONTHLY : 'Monthly',
  WEEKLY : 'Weekly'
}

const enums = Object.freeze({
    at: ' at ',
    on: ' on ',
    emptyString: '',
    comma: ",",
    space: ' ',
    and: " and ",
    am: "am",
    pm: "pm",
    day: 'Day-',
});

const ReminderHeader = (props) => {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {reminders, isFromJournal = false} = props;
    
    let text = "Reminders set ";
    const getReminder = (type, reminders, index) => {
        if (isFromJournal) {
            text = getDailyMonthlyWeeklyText(reminders, type);
        } else {
            text = text + getDailyMonthlyWeeklyText(reminders, type) + (index !== Object.keys(remindersObject).length - 1 ? ", " : "");
        }
        return (
            <>
            {(isFromJournal || index === Object.keys(remindersObject).length - 1) &&
                <View
                    style={[styles.textInfoView, styles.bgThinGrey]}
                    key={"rm_" + index}
                >
                    <View style={styles.textIconView}>
                        <Image
                            style={styles.clockIcon}
                            source={require("../../../assets/icons/clock.png")}
                            resizeMode={"contain"}
                        />
                    </View>
                    <Text
                        style={[styles.textPrimeBold, styles.textColorGreyLight]}
                        allowFontScaling={false}
                    >
                        {text}
                    </Text>
                </View>
            }
            </>
   
        );
    };

    const getDailyMonthlyWeeklyText = (reminders, type) => {
        let timeString = "";
        reminders.forEach((item, index) => {
        let splitedTime = item?.reminderTime && Array.isArray(item?.reminderTime) ? item?.reminderTime : item?.reminderTime?.split(':');
        let date = enums.on + enums.day + item?.reminderMonthlyDay
        let day = getDay(item);
        let time =
        item.reminderTime !== null
            ? timeMap[splitedTime[0]] +
            ":" +
            (splitedTime[1] === 0 ? "00" : splitedTime[1]) +
            (splitedTime[0] < 12 ? enums.am : enums.pm)
            : "";
        if (index === 0) {
            if(type === ReminderType.DAILY.toLowerCase())
            timeString = enums.at + time;
            else
            timeString = (type === ReminderType.WEEKLY.toLowerCase() ? day : date)+ enums.at + time;   
        } else if (index === reminders.length - 1) {
            if(type === ReminderType.DAILY.toLowerCase())
            timeString += enums.and + time;
            else
            timeString += enums.and + (type === ReminderType.WEEKLY.toLowerCase() ? day : date) + enums.at + time;
        } else {
            if(type === ReminderType.DAILY.toLowerCase())
            timeString += enums.comma + enums.space + enums.emptyString + time;
            else
            timeString += enums.comma + enums.space + (type === ReminderType.WEEKLY.toLowerCase() ? day : date) + enums.at + time;
        }
        });
        let initText = isFromJournal ? "Reminders set " : enums.emptyString;

        return type === ReminderType.DAILY.toLowerCase() ? initText + ReminderType.DAILY + timeString : 
            type === ReminderType.MONTHLY.toLowerCase() ? initText + ReminderType.MONTHLY +  timeString : 
            initText + ReminderType.WEEKLY + enums.on + timeString;
    }

    const getDay = (reminder) => {
        const {
        reminderMonday,
        reminderTuesday,
        reminderWednesday,
        reminderThursday,
        reminderFriday,
        reminderSaturday,
        reminderSunday,
        } = reminder;

        let dates = reminderMonday ? "Mon" : "";
        dates += reminderTuesday ? ", Tue" : "";
        dates += reminderWednesday ? ", Wed" : "";
        dates += reminderThursday ? ", Thu" : "";
        dates += reminderFriday ? ", Fri" : "";
        dates += reminderSaturday ? ", Sat" : "";
        dates += reminderSunday ? ", Sun" : "";
        if (dates.charAt(0) === ",") {
        dates = dates.slice(1, dates.length);
        }
        return dates;
    };

    const remindersGroups = (reminders) => {
        const remindersObject = {};
        if(reminders && reminders.length > 0) {
        reminders.forEach((element) => {
            const { reminderEveryday, reminderMonthly, reminder } = element;
            if (reminderEveryday && reminder) {
            let array = [];
            if (remindersObject["daily"]) {
                array = remindersObject["daily"];
            }
            array.push(element);
            remindersObject["daily"] = array;
            } else if (reminderMonthly && reminder) {
            let array = [];
            if (remindersObject["monthly"]) {
                array = remindersObject["monthly"];
            }
            array.push(element);
            remindersObject["monthly"] = array;
            } else if (reminder) {
            let array = [];
            if (remindersObject["weekly"]) {
                array = remindersObject["weekly"];
            }
            array.push(element);
            remindersObject["weekly"] = array;
            }
        });
        }

        return remindersObject;
    };
    const remindersObject = remindersGroups(getSortedReminderList(reminders));

    return (
    <React.Fragment>
        {Object.keys(remindersObject).length > 0 && (
            <View style={stylesRes.textInfoWrapper}>
                {Object.keys(remindersObject).map((key, index) =>
                    getReminder(key, remindersObject[key], index)
                )}
            </View>
        )}
    </React.Fragment>
    );
}

export default ReminderHeader;
