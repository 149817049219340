import React, {Component} from 'react';
import {View, ScrollView, Text, Image, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Header from '../../components/Header/header';
import UIText from '../../components/Text/text';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import * as actions from '../../store/actions/index';
import SimpleConfirmBox from '../../components/ConfirmationBox/simpleConfirmBox';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import Picker from '../../components/Picker/picker';
import {CAREGIVERS, DASHBOARD} from '../../navigation/path';
import {pendoTrackEvent} from '../../util/pendoConfig';

class CaregiverScreen extends Component {
  constructor(props) {
    super(props);
    if (props.journalUserId) {
      props.navigation.push('../' + DASHBOARD.path);
    }
    // const {from} = this.props.match.params;
    this.state = {
      selectedId: props.selectedId,
      permissionType:
        props.sharedAccessDigestProcessed.sharedAccessGivenToOthers[
          props.selectedId
        ].permissionType,
      grantedTo:
        props.sharedAccessDigestProcessed.sharedAccessGivenToOthers[
          props.selectedId
        ].grantedTo,
      saveWait: false,
      visibleModal: false,
      removeWait: false,
    };
  }

  componentDidMount= () => {
    pendoTrackEvent('Caregivers/@CaregiverSettings');
  }

  changeAcess = val => {
    this.setState({permissionType: val});
  };

  onUpdateSharedAccessPermission = () => {
    this.setState({saveWait: true}, () => {
      this.props.updateSharedAccessPermission(
        this.state.grantedTo,
        this.state.permissionType,
        res => {
          this.setState({saveWait: false});
          if (res) {
            this.props.navigation.push(CAREGIVERS.path);
          }
        },
      );
    });
  };

  onHandleConfirm = () => {
    this.setState({removeWait: true});
    this.props.deleteSharedAccessPermission(this.state.grantedTo, res => {
      this.setState({removeWait: false, visibleModal: false});
      if (res) {
        this.props.navigation.push(CAREGIVERS.path);
      }
    });
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => {
            this.props.navigation.goBack();
          }}
          index={CAREGIVERS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />

        {Boolean(this.state.selectedId) && (
          <React.Fragment>
            <SpaceBar />
            <ScrollView style={styles.scrollViewArea}>
              <View style={styles.gridSection}>
                <View
                  style={[
                    stylesRes.contentSectionMax,
                    stylesRes.layoutBotPdFixed,
                  ]}
                  dataSet={{media: ids.contentSectionMax}}>
                  <View
                    style={stylesRes.contBotSmPdFixed}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.secArea}>
                          <View style={[styles.careBannerSec, styles.bgHeight]}>
                            <View
                              style={[
                                styles.careBannerBg,
                                styles.bgHeight,
                                styles.bgHeightCareBanner,
                              ]}>
                              <Image
                                style={[styles.careBannerBgImg]}
                                source={require('../../assets/icons/care-bg.png')}
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.secArea}>
                          <View style={[styles.secSubArea, styles.greyBorder]}>
                            <View
                              style={[
                                styles.secSubContentArea,
                                styles.colPdLtRt,
                              ]}>
                              <View style={[styles.ViewRow]}>
                                <View style={[styles.viewDetailSec]}>
                                  <View style={styles.viewMainData}>
                                    <View
                                      style={[
                                        styles.viewItemDesc,
                                        styles.colPdLtTopBotExSm,
                                      ]}>
                                      <View style={[styles.viewDataFull]}>
                                        <Text style={styles.textPrimeBold} allowFontScaling={false}>
                                          {
                                            this.props
                                              .sharedAccessDigestProcessed
                                              ?.sharedAccessUserSummaries?.[
                                              this.props
                                                .sharedAccessDigestProcessed
                                              ?.sharedAccessGivenToOthers?.[
                                                this.state.selectedId
                                              ]?.grantedTo
                                            ]?.name
                                          }
                                        </Text>
                                        <Text style={styles.textPrimeSm} allowFontScaling={false}>
                                          {
                                            this.props
                                              .sharedAccessDigestProcessed
                                              ?.sharedAccessUserSummaries?.[
                                              this.props
                                                .sharedAccessDigestProcessed
                                              ?.sharedAccessGivenToOthers?.[
                                                this.state.selectedId
                                              ]?.grantedTo
                                            ]?.emailAddress
                                          }
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                                <View
                                  style={[
                                    styles.dataIconSecExLg,
                                    styles.colPdRtTopBotExSm,
                                  ]}>
                                  <View style={styles.iconListSec}>
                                    <View style={[styles.btnSubSec]}>
                                      <View style={[styles.btnArea]}>
                                        <View
                                          style={[
                                            styles.btnBlock,
                                            styles.noMgTop,
                                          ]}>
                                          <TouchableOpacity
                                            style={[styles.submitSm]}
                                            underlayColor="rgba(0, 153, 168, 0.8)"
                                            onPress={() =>
                                              this.setState({
                                                visibleModal: true,
                                              })
                                            }>
                                            <View style={styles.btnSingleView}>
                                              <View
                                                style={[
                                                  styles.btnSingle,
                                                  styles.btnSingleCent,
                                                ]}>
                                                {/* <View style={styles.actIndLg}>
                                                  <ActivityIndicator size="small" />
                                                </View> */}
                                                <UIText
                                                  style={styles.submitText}
                                                  title="Remove"
                                                />
                                              </View>
                                            </View>
                                          </TouchableOpacity>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>
                              {/* Loop this section  - end*/}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.secArea, styles.greyBorder]}>
                          <View
                            style={[styles.secSubAreaFull, styles.colPdLtRt]}>
                            <View
                              style={[styles.secSubArea, styles.listFullPdLg]}>
                              <View style={[styles.secSubTitleArea]}>
                                <View style={[styles.secSubTitleView]}>
                                  <Text style={styles.textPrimeSmBold} allowFontScaling={false}>
                                    Access level
                                  </Text>
                                </View>
                              </View>

                              <View style={[styles.secSubContentArea]}>
                                <View
                                  style={[styles.formInnerArea, styles.lblSp]}>
                                  <View
                                    style={[
                                      styles.fieldRow,
                                      styles.fieldRowNoMg,
                                    ]}>
                                    <View style={[styles.pickerAreaWrapper]}>
                                      <View
                                        style={[
                                          stylesRes.dropArrowView,
                                          stylesRes.dropArrowCent,
                                        ]}
                                        pointerEvents={'none'}>
                                        <View style={stylesRes.dropArrowIcon}>
                                          <Image
                                            style={[styles.imgContain]}
                                            source={require('../../assets/icons/dropdown-arrow.png')}
                                          />
                                        </View>
                                      </View>
                                      <Picker
                                        items={[
                                          {
                                            label: 'Full Access',
                                            value: 'READ_WRITE',
                                            key: 'READ_WRITE',
                                          },
                                          {
                                            label: 'Read Only',
                                            value: 'READ_ONLY',
                                            key: 'READ_ONLY',
                                          },
                                        ]}
                                        style={[
                                          styles.pickerAreaField,
                                          styles.textPickerPdMiniCom,
                                        ]}
                                        selectedValue={
                                          this.state.permissionType
                                        }
                                        onValueChange={this.changeAcess}
                                      />
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.btnArea, styles.btnRowMgLg]}>
                          <View style={[styles.btnBlock, styles.noMgTop]}>
                            <TouchableOpacity
                              style={[styles.submit]}
                              underlayColor="rgba(0, 153, 168, 0.8)"
                              onPress={this.onUpdateSharedAccessPermission}
                              disabled={this.state.saveWait}>
                              <View style={styles.btnSingleView}>
                                <View
                                  style={[
                                    styles.btnSingle,
                                    styles.btnSingleCent,
                                  ]}>
                                  {this.state.saveWait && (
                                    <View style={styles.actIndLg}>
                                      <ActivityIndicator size="small" />
                                    </View>
                                  )}

                                  <UIText
                                    style={styles.submitText}
                                    title="Save "
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
            <SimpleConfirmBox
              visibleModal={this.state.visibleModal}
              alertTitle={'Remove Caregiver'}
              alertBody={
                'Are you sure you want to remove ' +
                this.props.sharedAccessDigestProcessed
                  ?.sharedAccessUserSummaries?.[
                  this.props.sharedAccessDigestProcessed
                    ?.sharedAccessGivenToOthers?.[this.state.selectedId]?.grantedTo
                ]?.name ?? '' + '\'s' +
                ' access to your account? You will need to resend the invite to reconnect.'
              }
              onRequestClose={() => this.setState({visibleModal: false})}
              onBackdropPress={() => this.setState({visibleModal: false})}
              handleCancel={() => this.setState({visibleModal: false})}
              handleConfirm={this.onHandleConfirm}
              confirmWait={this.state.removeWait}
              alertBodyBoldText={true}
            />
          </React.Fragment>
        )}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedId: state.caregiver.selectedId,
    sharedAccessDigestProcessed: state.routines.sharedAccessDigestProcessed,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteSharedAccessPermission: (grantedTo, callBack) =>
      dispatch(actions.deleteSharedAccessPermission(grantedTo, callBack)),
    updateSharedAccessPermission: (grantedTo, permission, callBack) =>
      dispatch(
        actions.updateSharedAccessPermission(grantedTo, permission, callBack),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CaregiverScreen);
