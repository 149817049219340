import React, {Component} from 'react';
import {View, Text, TouchableOpacity, Keyboard} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import styles from '../../Css';
import * as comTitles from './titles';
import * as FieldDetails from '../../components/TextInput/fieldDetails';
import stylesResponsive from '../../CssResponsive';
import Collapsible from '../Collapsible/Collapsible';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import QuestionList from '../../components/Question/Add';
import Frequency from './Frequency';
import Dates from '../DateTimePicker/Dates';
import Prescriber from '../AutoComplete/CommonAutoComplete/Prescriber';
import Information from './Information';
import Instructions from './Instructions';
import Notes from './Notes';
import moment from 'moment';
import {hasValue} from '../../util/hashValueGenerator';
import TextInputPlusMinus from '../../components/TextInput/TextInputPlusMinus';
import TextPickerFrame from '../../components/TextInput/textPickerFrame';
//import AutoCompleteFrame from '../../components/AutoComplete/AutoCompleteFrame';
import AutoCompleteSuggestion from '../../components/AutoComplete/AutoCompleteSuggestion';
import ColorItemField from '../../components/ColorPicker/ColorItemField';
import ColorPickerRow from '../../components/ColorPicker/ColorPickerRow';
import * as ColorCode from '../../components/ColorPicker/ColorCodes';
import Warning from '../../components/Warning/warning';
import { pendoTrackEvent } from '../../util/pendoConfig';
import {autoSuggestionListType} from '../../Constant/index';
import CancelButton from '../Button/cancelButton';
import {verifyDuplicateMedication, getMedicationUnits} from '../../util/commonUiLogic';

class AddTherapie extends Component {
  constructor(props) {
    super(props);

    let healthProviderName = '';
    const {contactsProcessed, newTherapie, isNew, drugAdministrationsDigits} = props;
    const {
      id,
      artifactId,
      name,
      prescribedBy,
      notes,
      identificationNumber,
      dosages,
      treatmentType,
      isPaused,
      pause,
      pauseDate,
      pauseDuration,
      active,
      isActive,
      displayProperties = {},
      isPinned
    } = newTherapie;

    const {color} = displayProperties ? displayProperties : {};
    if (prescribedBy && contactsProcessed[prescribedBy]) {
      const {givenName, organization} = contactsProcessed[prescribedBy];

      healthProviderName = givenName ? givenName : organization;
    } else if (!prescribedBy && newTherapie && newTherapie.healthProviderName) {
      healthProviderName = newTherapie.healthProviderName;
    }

    this.id = id;
    this.artifactId = artifactId;
    this.active = active;
    this.isActive = isActive;
    this.isPaused = isPaused;
    this.isPinned = isPinned;
    this.color = color
      ? color
      : treatmentType === 3
      ? ColorCode.BGSUPPLEMENT
      : ColorCode.BGMEDICATION;
    this.pause = pause;
    this.pauseDate = pauseDate;
    this.pauseDuration = pauseDuration;
    this.prescribedBy = prescribedBy;
    this.healthProviderName = healthProviderName;
    this.notes = notes;
    this.startDate =
      dosages && dosages.length > 0 ? dosages[0].start : new Date().getTime();
    this.endDate = dosages && dosages.length > 0 ? dosages[0].end : null;
    this.quantity = dosages && dosages.length > 0 ? dosages[0].quantity : 1;
    this.frequency = dosages && dosages.length > 0 ? dosages[0].frequency : 1;
    this.periodic =
      dosages && dosages.length > 0 ? dosages[0].frequencyType : 'DAILY';
    this.instructions =
      dosages && dosages.length > 0 ? dosages[0].instructions : '';
    const unit = dosages && dosages.length > 0 ? dosages[0].units : '';

    this.state = {
      din_npn: identificationNumber,
      name: name,
      unit: unit,
      units: getMedicationUnits(null, drugAdministrationsDigits, true),
      quantity: this.quantity,
      treatmentType: treatmentType,
      validateName: false,
      saveWait: false,
      saveDisable: false,
      isNew: isNew,
      color: this.color,
      collapsible: {
        course:
          (this.instructions && this.instructions !== '') ||
          this.startDate ||
          this.endDate ||
          this.healthProviderName !== ''
            ? true
            : false,
        notes: this.notes ? true : false,
        questions: this.hasQuestions(
          this.props.questionDigest.userArtifactHealthQuestions,
          this.artifactId,
        ),
        medicalInfo: false,
      },
      hasDin_npn:
        (props.newTherapie || {}).identificationNumber !== '' ? true : false,
      treatmentInfoValues: null,
      autocompleteOpen: false,
      currentInput: null,
      duplicatePopup: false,
      selectedValues: this.getSelectedMedications(props.listTherapie),
    };
    //this.quantityTextInput = null;
  }

  componentDidMount() {
    let {din_npn, unit, units} = this.state;
    if (din_npn) {
      this.getTreatmentInfoValues(this.checkInitialDuplicates);
      this.props.setHashChanges(this.getMedicationHashValue(), "MEDICATION");
    } else {
      if (!unit) {
        const foundTablet = units.find(res => res.value === 'Tablets');
        this.setState({
          unit: foundTablet ? 'Tablets' : units && units[0] && units[0].value ? units[0].value : null
        }, () => {
          this.props.setHashChanges(this.getMedicationHashValue(), "MEDICATION");
        })
      } else {
        this.props.setHashChanges(this.getMedicationHashValue(), "MEDICATION");
      }
    }
    this.updateNewTherapie();
    this.refUpdate();
  }

  checkInitialDuplicates = (unit) => {
    const {name, din_npn, quantity} = this.state;
    if (!this.props.forceEdit) {
      this.verifyDuplicateMedication(name, din_npn, unit, quantity);
    }
  }

  componentDidUpdate() {
    if (this.props.initMedicationHash !== this.getMedicationHashValue()) {
      this.props.setHaveChanges(true, 'MEDICATION');
    } else {
      this.props.setHaveChanges(false, 'MEDICATION');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {isNew, listTherapie} = nextProps;

    this.setState({
      isNew,
      selectedValues: this.getSelectedMedications(listTherapie),
    });
  }

  getSelectedMedications = (treatments) => {
    const selectedValues = {};
    const {treatmentsProcessed} = this.props;

    if (treatments && treatments.length > 0) {
      treatments.forEach(res => {
        if (res.treatmentType !== 5) {
          const obj = treatmentsProcessed[res.id];
          if (obj) {
            if (obj.identificationNumber) {
              selectedValues[obj.identificationNumber] = obj.identificationNumber
            } else {
              selectedValues[obj.name] = obj.name
            }
          }
        }
      });
    }
    return selectedValues;
  };

  getDisabledStatus = () => {
    return this.state.saveDisable;
  }

  refUpdate = () => {
    const {getRef} = this.props;
    getRef && getRef(this);
  };

  getMedicationHashValue = () => {
    return hasValue(
      this.instructions +
        ',' +
        this.periodic +
        ',' +
        this.frequency +
        ',' +
        this.quantity +
        ',' +
        this.endDate +
        ',' +
        this.startDate +
        ',' +
        this.notes +
        ',' +
        this.healthProviderName +
        ',' +
        this.prescribedBy +
        ',' +
        this.state.unit +
        ',' +
        this.state.name +
        ',' +
        this.state.din_npn +
        ',' +
        this.color,
    );
  }

  hasQuestions = (questions, artifactId) => {
    let questionList = [];
    if (questions) {
      questionList = questions.filter(function(question) {
        return question.artifactId === artifactId;
      });
    }
    return questionList.length > 0 ? true : false;
  };

  verifyDuplicateMedication = (name, din_npn, unit, quantity) => {
    const {listTherapie} = this.props;
    verifyDuplicateMedication(name, din_npn, unit, quantity, listTherapie, (exist, msg) => {
      if (exist) {
        this.setState({
          duplicatePopup: true,
          saveDisable: true,
          errorDescription: msg,
        });
      } else {
        this.setState({
          duplicatePopup: false,
          saveDisable: false,
          errorDescription: null,
        });
      }
    }, this.id);
  };

  onPressUpdateTherapie = () => {
    Keyboard.dismiss();
    setTimeout(() => {
    const {name, din_npn, treatmentType, unit} = this.state;
    if (
      !this.state.name ||
      (this.state.name && this.state.name.trim() === '')
    ) {
      this.setState({validateName: true});
      if (this.props.scrollToTop) {
        this.props.scrollToTop();
      }
      return;
    } else {
      this.setState({validateName: false});
    }

    if (unit !== '') {
      let addNewTherapie = {
        treatment: {
          id: this.id,
          artifactId: this.artifactId,
          name: name,
          notes: this.notes,
          prescribedBy: this.prescribedBy,
          identificationNumber: din_npn,
          treatmentType: treatmentType,
          active: this.active,
          isActive: this.isActive,
          isPaused: this.isPaused,
          isPinned: this.isPinned,
          pause: this.pause,
          pauseDate: this.pauseDate,
          pauseDuration: this.pauseDuration,
          dosages: [
            {
              id: -1,
              start: moment.utc(this.startDate).valueOf(),
              end: moment.utc(this.endDate).valueOf(),
              quantity: this.quantity,
              frequency: this.frequency ? this.frequency : 1,
              frequencyType: this.periodic,
              instructions: this.instructions,
              units: unit,
            },
          ],
          displayProperties: {
            id: null,
            createdOn: null,
            modifiedOn: null,
            color: this.color,
            artifactId: null,
            dashedLine: true,
          },
          publishedBy: "SELF",
        },
      };

      this.setState({saveWait: true});
      this.props.setSaveWait(true);
      pendoTrackEvent('MedSupp+Act@ > Save');
      if (!this.prescribedBy && this.healthProviderName) {
        this.addContactAndUpdateTherapie(
          addNewTherapie,
          this.healthProviderName,
        );
      } else {
        this.updateTherapie(addNewTherapie);
      }
    }
    }, 300);
  };

  addContactAndUpdateTherapie = (
    addNewTherapie,
    healthProviderName,
  ) => {
    let addNewContact = {
      addNewContact: {
        contact: {
          id: -1,
          givenName: healthProviderName,
          isCareTeam: true,
        },
      },
    };
    this.props.updateContact(
      addNewContact,
      this.props.contacts,
      (success, contactData) => {
        if (success) {
          addNewTherapie.treatment.prescribedBy = contactData.id;
        }

        this.updateTherapie(addNewTherapie);
      },
      this.props.journalUserId,
      false,
    );
  };

  updateTherapie = (addNewTherapie) => {
    this.props.handleOnSaveQuestion(() => {
      this.props.updateTherapie(
        addNewTherapie,
        (success, data) => {
          this.setState({saveWait: false});
          this.props.setSaveWait(false);
          if (success) {
            this.props.handleOnPressSaveButton();
          }
        },
        this.props.journalUserId,
      );
    });
  };

  handleOnSubmitCancel = callBack => {
    const changeHash = this.getMedicationHashValue();
    if (this.props.initMedicationHash === changeHash) {
      callBack(false);
    } else {
      callBack(true);
    }
  };

  getChangeMedi = () => {
    let flag = null;
    const changeHash = this.getMedicationHashValue();
    if (this.props.initMedicationHash === changeHash) {
      flag = false;
    } else {
      flag = true;
    }
    return flag;
  };

  handleOnPressCollapsible = (key, val) => {
    let collapsibleTemp = this.state.collapsible;
    collapsibleTemp[key] = !val;
    this.setState({collapsible: collapsibleTemp});
  };

  processData = res => {
    let termsArray = [];
    if (res) {
      const suggestionResults = [...res.summaries];
      if (res?.substring === this.state.name || res?.substring === this.state.din_npn || !res?.substring) {
      termsArray = suggestionResults.map(value => {
        return {
          currentInput: this.state.currentInput,
          name: value.summary.match('.+?(?=\\(DIN:)') ? value.summary.match('.+?(?=\\(DIN:)') : value.summary.match('.+?(?=\\(NHP:)'),
          //name: value.summary,
          Ingredient: value.ingredient,
          din: value.summary.includes("(DIN:") ? "DIN: "+value.treatmentId : "NHP: "+value.treatmentId ,
          data: value,
        };
      });
      this.setState({termsArray: termsArray});
      } else {
        termsArray = this.state.termsArray
      }
    }

    return termsArray;
  };

  getTreatmentInfoValues = callBack => {
    const {getTreatmentInfo, drugAdministrationsDigits} = this.props;
    const {treatmentType, din_npn, unit, units} = this.state;
    if (din_npn) {
      getTreatmentInfo(din_npn, treatmentType, (success, res) => {
        if (success) {
          const {unitValue, unitList, value} = res;
          this.setState({
            treatmentInfoValues: value,
            units: unitList,
            unit: unitValue,
            collapsible: {
              course:
                (this.instructions && this.instructions !== '') ||
                this.startDate ||
                this.endDate ||
                this.healthProviderName !== ''
                  ? true
                  : false,
              notes: this.notes ? true : false,
              questions: this.hasQuestions(
                this.props.questionDigest.userArtifactHealthQuestions,
                this.artifactId,
              ),
              medicalInfo: value ? true : false,
            },
          });
          callBack(unitValue);
        } else {
          const foundTablet = units && units.length > 0 && units.find(res => res.value === 'Tablets');
          const unitVal = foundTablet ? 'Tablets' : units && units[0] && units[0].value ? units[0].value : null;
          this.setState({
            unit: unitVal,
            din_npn: din_npn
          });
          callBack(unitVal);
        }
      },
      unit,
      units,
      drugAdministrationsDigits,
      true);
    }
  };

  healthProviderNameChange = val => {
    this.healthProviderName = val;
    this.updateNewTherapie();
  };

  updateQuantity = val => {
    let {unit, name, din_npn} = this.state;
    this.setState({quantity: val});
    this.quantity = val;
    this.updateNewTherapie();
    this.verifyDuplicateMedication(name, din_npn, unit, this.quantity);
  };

  updateTime = val => {
    this.frequency = val;
    this.updateNewTherapie();
  };

  updatePeriodic = val => {
    this.periodic = val;
    this.updateNewTherapie();
  };

  updateInstructions = val => {
    this.instructions = val;
    this.updateNewTherapie();
  };

  updatePrescribedBy = (id, name) => {
    this.prescribedBy = id;
    this.healthProviderName = name;
    this.updateNewTherapie();
  };

  updateNotes = val => {
    this.notes = val ? val : null;
    this.updateNewTherapie();
  };

  updateStartDate = val => {
    this.startDate = val;
    this.updateNewTherapie();
  };

  updateEndDate = val => {
    this.endDate = val;
    this.updateNewTherapie();
  };

  updateAutocompleteOpen = val => {
    this.setState({autocompleteOpen: val});
  };

  onItemPressIdentificationNumber = selection => {
    let currSummaryName = null;

    if (selection.data.summary.match('.+?(?=\\(DIN:)')) {
      currSummaryName = selection.data.summary.match('.+?(?=\\(DIN:)');
    } else if (selection.data.summary.match('.+?(?=\\(NHP:)')) {
      currSummaryName = selection.data.summary.match('.+?(?=\\(NHP:)');
    }
    let selectedValue = selection.data;

    this.setState(
      {
        name: currSummaryName[0],
        din_npn: selectedValue.treatmentId,
        treatmentType: selectedValue.treatmentType.toString(),
        hasDin_npn: true,
        unit: '',
      },
      () => {
        let {name, din_npn} = this.state;
        this.getTreatmentInfoValues(unit =>
          this.verifyDuplicateMedication(name, din_npn, unit, this.quantity),
        );
        this.updateNewTherapie();
        Keyboard.dismiss();
      },
    );
  };

  onChangeTextIdentificationNumber = currentInput => {
    let {unit, name} = this.state;
    this.setState(
      {
        din_npn: currentInput ? currentInput : null,
        currentInput: currentInput ? currentInput : null,
      },
      () => {
        if (!currentInput) {
          this.updateNewTherapie();
        }
        this.verifyDuplicateMedication(name, currentInput, unit, this.quantity);
      }
    );
  };

  onItemPressName = selection => {
    let currSummaryName = null;

    if (selection.data.summary.match('.+?(?=\\(DIN:)')) {
      currSummaryName = selection.data.summary.match('.+?(?=\\(DIN:)');
    } else if (selection.data.summary.match('.+?(?=\\(NHP:)')) {
      currSummaryName = selection.data.summary.match('.+?(?=\\(NHP:)');
    }
    let selectedValue = selection.data;
    this.setState(
      {
        name: currSummaryName[0],
        din_npn: selectedValue.treatmentId,
        treatmentType: selectedValue.treatmentType.toString(),
        hasDin_npn: true,
        unit: '',
      },
      () => {
        let {name, din_npn} = this.state;
        this.getTreatmentInfoValues(unit =>
          this.verifyDuplicateMedication(name, din_npn, unit, this.quantity),
        );
        this.updateNewTherapie();
        Keyboard.dismiss();
        // if (this.quantityTextInput) {
        //   this.quantityTextInput.focus();
        // }
      },
    );
  };

  onChangeTextName = currentInput => {
    this.setState({
      name: currentInput ? currentInput : null,
      validateName: false,
      currentInput: currentInput,
      unit: currentInput ? this.state.unit : ''
    },
    () => {
      if (!currentInput) {
        this.updateNewTherapie()
      }
    });
  };

  onPressCustomName = selection => {
    const {drugAdministrationsDigits} = this.props;
    let units = [];
    let {unit} = this.state;

    units = getMedicationUnits(null, drugAdministrationsDigits, true);
    if (!unit) {
      unit = 'Tablets'; //units[0].value;
    }
    this.setState(
      {
        name: selection,
        din_npn: null,
        unit: unit,
        units: units,
      },
      () => {
        let {unit, name, din_npn} = this.state;
        this.updateNewTherapie();
        // if (this.quantityTextInput) {
        //   this.quantityTextInput.focus();
        // }
        Keyboard.dismiss();
        this.verifyDuplicateMedication(name, din_npn, unit, this.quantity);
      },
    );
  };

  updateNewTherapie = () => {
    const {name, din_npn, treatmentType, unit} = this.state;
    let treatment = {
      id: this.id,
      artifactId: this.artifactId,
      name: name,
      notes: this.notes,
      prescribedBy: this.prescribedBy,
      identificationNumber: din_npn,
      treatmentType: treatmentType,
      active: this.active,
      isActive: this.isActive,
      isPaused: this.isPaused,
      isPinned: this.isPinned,
      pause: this.pause,
      pauseDate: this.pauseDate,
      pauseDuration: this.pauseDuration,
      healthProviderName: this.healthProviderName,
      dosages: [
        {
          id: -1,
          start: moment.utc(this.startDate).valueOf(),
          end: this.endDate ? moment.utc(this.endDate).valueOf() : null,
          quantity: this.quantity,
          frequency: this.frequency,
          frequencyType: this.periodic,
          instructions: this.instructions,
          units: unit,
        },
      ],
      treatmentId: this.id,
      displayProperties: {
        id: null,
        createdOn: null,
        modifiedOn: null,
        color: this.color,
        artifactId: null,
        dashedLine: true,
      },
    };
    this.props.updateNewTherapie(treatment);
  };

  onValueChangeUnit = itemValue => {
    this.setState(
      {
        unit: itemValue,
      },
      () => {
        let {unit, name, din_npn} = this.state;
        this.updateNewTherapie();
        this.verifyDuplicateMedication(name, din_npn, unit, this.quantity);
      },
    );
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const {
      validateName,
      name,
      din_npn,
      units,
      unit,
      quantity,
      color,
      duplicatePopup,
      saveDisable,
      errorDescription,
      selectedValues,
    } = this.state;
    const url = '/api/treatments/treatment/summarycontains?substring=';
    return (
      <View style={styles.therapyMedicationTab}>
        <View
          style={[
            styles.subSection,
            styles.zIndexAutoComplete,
            styles.colPdTopLtRt,
          ]}>
          <View style={[styles.fieldRow, styles.zIndexAutoComplete]}>
            <View style={styles.fieldRowWrapper}>
              <View style={styles.fieldColStretch}>
                <AutoCompleteSuggestion
                  textFieldWithTitle={true}
                  textFieldTitle="Name"
                  remortSearch={true}
                  url={url}
                  processData={this.processData}
                  triggerLength={3}
                  validateName={validateName}
                  onChangeText={this.onChangeTextName}
                  onItemPress={this.onItemPressName}
                  currentInput={name}
                  onPressCustom={this.onPressCustomName}
                  customSectionTitle={'Add Custom Meds/Activity/Therapy'}
                  showCustomSection={true}
                  autoFocus={
                    this.props.fromManualEntry && this.props.emailVerified
                      ? true
                      : false
                  }
                  fieldBg={FieldDetails.BGWHITE}
                  fieldLt={true}
                  placeholder={'Type three characters'}
                  allowFontScaling={false}
                  zoomIcon={true}
                  removeIcon={true}
                  type={autoSuggestionListType.medication} 
                  selectedValues={selectedValues}  
                  customWidth={true}
                />
              </View>
              <View style={styles.fieldColSpMd} />
              <View style={styles.fieldColStretch}>
                <AutoCompleteSuggestion
                  textFieldWithTitle={true}
                  textFieldTitle="DIN / NPN"
                  remortSearch={true}
                  url={url}
                  processData={this.processData}
                  triggerLength={3}
                  onChangeText={this.onChangeTextIdentificationNumber}
                  onItemPress={this.onItemPressIdentificationNumber}
                  currentInput={din_npn}
                  showCustomSection={false}
                  autoFocus={false}
                  fieldBg={FieldDetails.BGWHITE}
                  fieldLt={true}
                  toolTipButton={true}
                  toolTipId={'tool-tip-din-info'}
                  toolTipContent={'DIN stands for Drug Identification Number'}
                  placement={'left'}
                  disabledScroll={this.props.disabledScroll}
                  placeholder={'ex: 01234567'}
                  allowFontScaling= {false}
                  zoomIcon={true}
                  removeIcon={true}
                  type={autoSuggestionListType.medication}
                  selectedValues={selectedValues}
                  customWidth={true}
                  contentRightAlign={true}
                />
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.subInSection, styles.zIndexMin, styles.colPdLtRt]}>
          <View style={[styles.zIndexMin, styles.comSp]}>
            <View style={[styles.fieldRow, styles.noBotMg]}>
              <View style={styles.fieldRowWrapper}>
                <View style={styles.fieldColStretch}>
                  <TextInputPlusMinus
                    title={'Quantity'}
                    fieldBg={FieldDetails.BGWHITE}
                    fieldAlign={FieldDetails.ALIGNCENTER}
                    fieldLt={true}
                    value={quantity}
                    onChange={this.updateQuantity}
                    autoFocus={this.props.fromManualEntry ? false : true}
                    //getTextInputRef={val => (this.quantityTextInput = val)}
                  />
                </View>
                <View style={styles.fieldColSpMd} />

                <View style={styles.fieldColStretch}>
                  <TextPickerFrame
                    title={'Unit'}
                    fieldBg={FieldDetails.BGWHITE}
                    items={units}
                    fieldAlign={FieldDetails.ALIGNLEFT}
                    fieldLt={true}
                    selectedValue={unit}
                    onValueChange={this.onValueChangeUnit}
                    allowFontScaling= {false}
                  />
                </View>
              </View>
            </View>
            {Boolean(duplicatePopup) && (
              <View style={[styles.zIndexMin, styles.comMgTopSm, styles.warningMessage]}>
                <Warning
                  errorDescription={errorDescription}
                  textPosition={'left'}
                />
              </View>
            )}
          </View>
          <Frequency
            times={this.frequency}
            periodic={this.periodic}
            updateTime={this.updateTime}
            updatePeriodic={this.updatePeriodic}
          />
          <View style={[styles.zIndexMin]}>
            <ColorPickerRow colorPickerTitle={'Choose Colour For Graph'}>
              <ColorItemField
                handleChangeComplete={c => {
                  this.color = c;
                  this.setState({color: c});
                  this.updateNewTherapie();
                }}
                color={color}
              />
            </ColorPickerRow>
          </View>
          <View style={[stylesRes.secDividerCom, stylesRes.zIndexLower]}>
            <View style={stylesRes.secDividerSp} />
          </View>
        </View>

        <View style={[styles.subSectionSub, styles.zIndexMin]}>
          <Collapsible
            title="More Information"
            contentVisibility={this.state.collapsible.course}
            handleOnPressCollapsible={res =>
              this.handleOnPressCollapsible('course', res)
            }
            style={[styles.collapseTitle]}
            topBorder={true}
            plusButton={true}
            toolTipButton={true}
            toolTipId={'tool-tip-more-info-medication'}
            toolTipContent={
              'Add details about your medication/supplement including prescription-related information'
            }
            disabledScroll={this.props.disabledScroll}
            //titleMd={true}
          >
            <View
              style={[
                styles.collapseContent,
                styles.colPdLtRt,
                styles.zIndexAutoComplete,
              ]}>
              <Instructions
                instructions={this.instructions}
                updateInstructions={this.updateInstructions}
              />
              <View style={[styles.subSection, styles.zIndexMinSpec]}>
                <Prescriber
                  prescribedBy={this.prescribedBy}
                  healthProviderName={this.healthProviderName}
                  updatePrescribedBy={this.updatePrescribedBy}
                  updateAutocompleteOpen={this.updateAutocompleteOpen}
                />

                <Dates
                  startDate={this.startDate}
                  endDate={this.endDate}
                  updateStartDate={this.updateStartDate}
                  updateEndDate={this.updateEndDate}
                />
              </View>
            </View>
          </Collapsible>

          {/* Notes */}
          <View style={[styles.subSection, styles.zindexMin]}>
            <Collapsible
              title="Notes"
              contentVisibility={this.state.collapsible.notes}
              handleOnPressCollapsible={res =>
                this.handleOnPressCollapsible('notes', res)
              }
              style={[styles.collapseTitle, styles.zIndexMin]}
              plusButton={true}
              topBorder={true}
              //titleMd={true}
            >
              <Notes updateNotes={this.updateNotes} notes={this.notes} />
            </Collapsible>
            <Collapsible
              title="To-dos"
              contentVisibility={this.state.collapsible.questions}
              handleOnPressCollapsible={res =>
                this.handleOnPressCollapsible('questions', res)
              }
              plusButton={true}
              topBorder={true}
              //titleMd={true}
            >
              <QuestionList 
                type="therapy" 
                scrollToEnd={this.props.scrollToEnd}
              />
            </Collapsible>
            {Boolean(this.state.treatmentInfoValues) && (
              <Collapsible
                title="Medication/Supplement Information"
                contentVisibility={this.state.collapsible.medicalInfo}
                handleOnPressCollapsible={res =>
                  this.handleOnPressCollapsible('medicalInfo', res)
                }
                plusButton={true}
                toolTipButton={true}
                toolTipId={'tool-tip-suppliment-info'}
                toolTipContent={
                  'Find details about your medication/supplement including active ingredients and strength'
                }
                topBorder={true}
                placement={'left'}
                disabledScroll={this.props.disabledScroll}
                allowFontScaling= {false}
                //titleMd={true}
              >
                <Information
                  treatmentInfoValues={this.state.treatmentInfoValues}
                />
              </Collapsible>
            )}
          </View>
        </View>
        {/* </View> */}
        <View style={[styles.subSection, styles.zIndexMin]}>
          <View style={styles.btnArea}>
            <View style={stylesRes.btnViewSec}>
              <View style={styles.fieldBtn}>
                <View style={styles.btnBlockFull}>
                  <CancelButton 
                    touchableOnpress={() => this.props.handleOnPressBackButton()}
                    disabled={this.state.saveWait}
                    cancelBtnWidthDiff={true}
                  />
                </View>
              </View>
              <View style={styles.fieldBtnSpSm} />
              <View style={styles.fieldBtn}>
                <View style={styles.btnBlockFull}>
                  <TouchableOpacity
                    style={[
                      styles.submitSm,
                      styles.btnShadowCom,
                      saveDisable
                        ? [styles.submitBgOrange, styles.btnBlurState]
                        : styles.submitBgOrange,
                    ]}
                    underlayColor="rgba(0, 153, 168, 0.8)"
                    onPress={() => {
                      this.onPressUpdateTherapie(); 
                    }}
                    disabled={
                      !this.getChangeMedi || this.state.saveWait || saveDisable
                    }
                    nativeID={'medSuppActSave'}>
                    <View ref={component => (this._root = component)}>
                      <View style={styles.btnSingleView}>
                        <View style={[styles.btnSingle, styles.btnSingleCent]}>
                          {this.state.saveWait && (
                            <View
                              style={[
                                styles.indicatorIconView,
                                styles.indicatorLtCom,
                              ]}>
                              <ActivityIndicator pdZero={true} size="small" />
                            </View>
                          )}
                          <Text
                            style={[styles.submitBtnTextWhite, styles.bold]}
                            allowFontScaling={false}
                            >
                            {comTitles.SAVE}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    contacts: state.routines.contacts,
    journalUserId: state.uistate.journalUserId,
    isNew: state.therapie.isNew,
    newContact: state.contacts.newContact,
    contactsProcessed: state.routines.contactsProcessed,
    drugAdministrationsDigits: state.routines.drugAdministrationsDigits,
    emailVerified: state.auth.emailVerified,
    questionDigest: state.routines.questionDigest,
    listTherapie: state.routines.treatments,
    treatmentsProcessed: state.routines.treatmentsProcessed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.updateTherapie(addNewTherapie, callBack, journalUserId)),
    displayProperties: (displayPrope, journalUserId) =>
      dispatch(
        actions.updateGraphDisplayProperties(displayPrope, journalUserId),
      ),
    updateContact: (addNewContact, contacts, callBack, journalUserId, fetchAttachments) =>
      dispatch(
        actions.updateContact(addNewContact, contacts, callBack, journalUserId, fetchAttachments),
      ),
    getTreatmentInfo: (din_npn, treatmentType, callBack, unit, units, drugAdministrationsDigits, displayFullName) => 
      dispatch(actions.getTreatmentInfo(din_npn, treatmentType, callBack, unit, units, drugAdministrationsDigits, displayFullName)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddTherapie);
