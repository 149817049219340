import React from 'react';
import {View, Text, TouchableOpacity, Platform} from 'react-native';
import styles from '../../../../Css';
import stylesResponsive from '../../../../CssResponsive';
import {
  CANCEL_EDIT_LIST,
  EDIT_LIST,
  listTypes,
} from '../../../../screens/PeerToPeer/MainSection/Profile/Constraints';
import ProfileCommonBiosEdit from './ProfileCommonBiosEdit';
import ProfileCommonBiosView from './ProfileCommonBiosView';
import ActivityIndicator from '../../../../components/ActivityIndicator/activityIndicator';
import { WEB } from '../../../../util/platformWindowConfig';

const ProfileCommonBios = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {
    type,
    handleOnClickEditCancelItems,
    showList,
    handleOnClickSaveItems,
    listItemSaveWait,
    disableListItemSaveButtons,
    checkBoxTick,
    data,
    onPressItem,
    idKey,
    valueTypeKey,
  } = props;

  const sortedData =
    Boolean(data && data.length > 0) &&
    data.sort(
      (a, b) =>
        a[valueTypeKey] !== null &&
        b[valueTypeKey] !== null &&
        a[valueTypeKey]
          .toLowerCase()
          .localeCompare(b[valueTypeKey].toLowerCase()),
    );

  return (
    <View style={styles.comTopBorder}>
      <View
        style={[
          styles.customPaddingProfile,
          styles.flexBestweenStart,
          stylesRes.flexWrapCustom,
        ]}
        dataSet={{media: ids.flexWrapCustom}}>
        <View style={[styles.flexRowAliSt, Platform.OS !== WEB && styles.wdNinetyPerc]}>
          <View>
            <Text
              style={[
                styles.profileText,
                styles.marginZero,
                stylesRes.profileWidthText,
              ]}
              dataSet={{media: ids.profileWidthText}}>
              {type === listTypes.PERSONAL_DETAILS
                ? 'Personal Details'
                : 'Location Details'}
            </Text>
          </View>
          {Boolean(showList) ? (
            <ProfileCommonBiosEdit
              type={type}
              showList={showList}
              checkBoxTick={checkBoxTick}
              onPressItem={onPressItem}
              data={sortedData}
              idKey={idKey}
              valueTypeKey={valueTypeKey}
            />
          ) : (
            <ProfileCommonBiosView
              commonItemList={sortedData}
              idKey={idKey}
              valueTypeKey={valueTypeKey}
            />
          )}
        </View>

        {Boolean(showList) ? (
          <View
            style={[styles.flexRowAiCt, styles.flexJcFe, stylesRes.bottomRes]}
            dataSet={{media: ids.bottomRes}}>
            <TouchableOpacity
              onPress={() =>
                handleOnClickEditCancelItems(type, CANCEL_EDIT_LIST)
              }
              style={[styles.cancelProfile, styles.flexJcCtAiCt]}>
              <Text style={styles.cancelText}>Cancel</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => handleOnClickSaveItems(type)}
              disabled={disableListItemSaveButtons}
              style={[styles.saveProfile, styles.submitBgBlueDark]}>
              <View>
                {Boolean(listItemSaveWait) && (
                  <ActivityIndicator size="small" style={styles.loader} />
                )}
              </View>
              <View>
                <Text style={styles.saveTextProfile}>Save</Text>
              </View>
            </TouchableOpacity>
          </View>
        ) : (
          <View>
            <TouchableOpacity
              disabled={!data ||data?.filter(a => a.value && a.value !== "")?.length === 0}
              onPress={() => handleOnClickEditCancelItems(type, EDIT_LIST)}>
              <Text
                style={[
                  styles.visiblityText,
                  ...(!data || data?.filter(a => a.value && a.value !== "")?.length === 0
                    ? [{opacity: 0.5}]
                    : []),
                ]}>
                Edit
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
};

export default ProfileCommonBios;
