import React from 'react';
import {View, TextInput, Platform, Text} from 'react-native';
import QuestionTitle from '../../../../components/QuestionTitle/QuestionTitle';
import AnswerCheckBox from '../../../../components/AnswerCheckBox/AnswerCheckBox';
import QuestionImage from '../QuestionImage';
import stylesCss from './QuestionSingleSelectionStyle';
import styles from '../../../../Css';
import QuestionWarning from '../../../../components/QuestionTitle/QuestionWarning';

const QuestionSingleSelection = ({
                                   choices = [],
                                   allowOtherText,
                                   answer,
                                   onSelect,
                                   isReadOnly,
                                   onChangeText,
                                   questionIndex,
                                   title,
                                   imageUrl,
                                   required,
                                   requiredWarning,
                                   onSkip,
                                   showQuestionTitleAsPrompt = false,
                                   questionContainerStyles = {}
                                 }) => {
  return (
    <>
      {Boolean(imageUrl) && <View style={[stylesCss.questionImageWrapper]}>
        <QuestionImage imageUrl={imageUrl}/>
      </View>}
      <View style={[stylesCss.surveySingleQuestionWrapper, questionContainerStyles]}>
        {!showQuestionTitleAsPrompt &&
          <QuestionTitle questionIndex={questionIndex} title={title} required={required} onSkip={onSkip}
                         isReadOnly={isReadOnly}/>
        }
        {
          showQuestionTitleAsPrompt &&
          <View style={[stylesCss.questionTitleAsPromptWrapper]}>
            <Text style={[styles.textTitleBold]} allowFontScaling={false}>
              {title}
            </Text>
          </View>
        }
        <View>
          {choices
            .sort((firstEle, secondEl) => {
              const firstChoiceOrder = firstEle?.choiceOrder || 0;
              const secondChoiceOrder = secondEl?.choiceOrder || 0;
              return (firstChoiceOrder > secondChoiceOrder) ? 1 : -1;
            })
            .map(({questionChoiceText, id}) => (
              Boolean(questionChoiceText) &&
              <AnswerCheckBox
                key={`qo-${questionIndex}-${id}`}
                text={questionChoiceText}
                selected={Boolean(`${answer?.selectedItem}` === `${id}`)}
                enabled={!isReadOnly}
                onSelect={(selectedStatus) => {
                  onSelect({
                    selectedItem: selectedStatus ? id : null,
                    otherText: selectedStatus ? null : answer.otherText,
                  });
                }}
                requiredWarning={requiredWarning}
              />
            ))}
        </View>
        {Boolean(allowOtherText) && <View style={
          
        [
          (!isReadOnly || (isReadOnly && answer?.otherText)) && styles.greyBoxBorder, stylesCss.singleQuestionTextboxWrapper,
        isReadOnly && answer?.otherText  && styles.customTextHighlighter,
          // {if required}
          requiredWarning && styles.inValidColor
        ]
        
        }>
          {!isReadOnly && <TextInput
            style={[
              stylesCss.singleQuestionInput,
              {
                ...Platform.select({
                  android: {
                    textAlignVertical: "top",
                    height: 95,
                  },
                  ios: {
                    height: 95,
                  },
                }),
              },
            ]}
            allowFontScaling={false}
            editable={!isReadOnly}
            multiline
            placeholderTextColor="#C4C4C4"
            placeholder="Other"
            value={answer?.otherText || ''}
            onChangeText={(text) => onChangeText({
              otherText: text,
              selectedItem: Boolean(text) ? null : answer.selectedItem,
            })}
          />}
          {isReadOnly && answer?.otherText && <Text style={[stylesCss.singleSelectionReadOnlyText, styles.textPrime, styles.fontBold]} allowFontScaling={false}>
            {answer?.otherText || ''}
          </Text>}
        </View>}
        {/* Requird Field Warning Start */}
        {Boolean(requiredWarning) &&
          <QuestionWarning/>
        }
        {/* Requird Field Warning End */}
      </View>
    </>
  );
};

export default (QuestionSingleSelection);
