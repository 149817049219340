import React, {Component} from 'react';
import {Text, View, Image, TouchableOpacity, Platform} from 'react-native';
import styles from '../../Css';
import { WEB } from '../../util/platformWindowConfig';


class AutoCompleteListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      select: props.item.data
        ? props.selectedValues[props.item.data.id] ||
          props.selectedValues[props.item.name] ||
          props.selectedValues[props.item.name?.toLowerCase()]
          ? true
          : false
        : props.selectedValues[props.item.name] ||
          props.selectedValues[props.item.name?.toLowerCase()]
        ? true
        : false,
      inputText: this.props.item.currentInput,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      select: props.item.data
        ? props.selectedValues[props.item.data.id] ||
          props.selectedValues[props.item.name] ||
          props.selectedValues[props.item.name?.toLowerCase()]
          ? true
          : false
        : props.selectedValues[props.item.name] ||
          props.selectedValues[props.item.name?.toLowerCase()],
    });
  }

  onSelect = () => {
    this.setState({select: true}, () => {
      if (Platform.OS === WEB) {
        setTimeout(() => this.props.onItemPress(this.props.item), 500);
      } else {
        this.props.onItemPress(this.props.item);
      }
    });
  };

  render() {
    const {item} = this.props;
    return (
      <View style={[styles.autoCompleteItemCom, styles.secSepBorderBot]}>
        <TouchableOpacity onPress={this.onSelect}>
          <View style={[styles.autoCompleteItemComRow]}>
            <View style={[styles.selectedItemRoundIcon]}>
              {this.state.select ? (
                <Image
                  style={styles.autoSelectRoundIcon}
                  source={require('../../assets/icons/selection-round-tick.png')}
                />
              ) : (
                <Image
                  style={styles.autoSelectRoundIcon}
                  source={require('../../assets/icons/selection-round-default.png')}
                />
              )}
            </View>
            <View style={[styles.selectedItemData]}>
              <Text style={styles.textPrime} allowFontScaling={false}>
                {item.name.trim()}
                {/* {item.data && item.data.dosages && item.data.dosages[0] && '; '}
                {item.data &&
                  item.data.dosages &&
                  item.data.dosages[0] &&
                  item.data.dosages[0].quantity}{' '}
                {item.data &&
                  item.data.dosages &&
                  item.data.dosages[0] &&
                  item.data.dosages[0].units} */}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default (AutoCompleteListItem);
