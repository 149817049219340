import React, {useState} from "react";
import { View, Text, TouchableOpacity, Image, Platform } from "react-native";
import ActivityIndicator from '../../../components/ActivityIndicator/activityIndicator';
import { findThumbnail, isViewableAttachment } from "../../../util/commonUiLogic";
import stylesCss from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import {fileDownload} from '../../../axios/common'
import * as constants from '../HealthJourneyConstant'
import * as axiosCommon from "../../../axios/axios-common";
import * as urls from "../../../store/actions/urls";
import theme from "../../../css/base/theme";
import { WEB } from "../../../util/platformWindowConfig";


const EventAttachmentItem = props =>  {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {item, index} = props;
  const {filename, id} = item;
  const [downloadWait, setDownloadWait] = useState(false);
  const [viewWait, setViewWait] = useState(false);
  const [attachmentId, setAttachmentId] = useState(null);

  let tapCount = 0;

  const downloadAttachment = (id, fileName) => {
    const callBack = (response) => {
      if (response.type === "SUCCESS") {
        const token = response.responseDto.data.authToken;
        const url = urls.IMG_DOC_BY_ID + id + "&auth=" + token;
        if (Platform.OS === WEB) {
          const header = {
            url,
            method: "GET",
            responseType: "blob", // important
          };
          axiosCommon.axiosDownload(header, fileName, () => {
            downloadOrViewCompleted(true);
          });
        } else {
          axiosCommon.actualDownload(fileName, url, () => {
            downloadOrViewCompleted(true);
          });
        }
      } else {
        downloadOrViewCompleted(true);
      }
    };
    
    if (id) {
      tapCount = tapCount + 1;
      setDownloadWait(true);
      setAttachmentId(id);
      if (tapCount === 1) {
        axiosCommon.axiosGET(urls.IMG_GET_DOWNLOAD_TOKEN + id, callBack);
      }
    }
  };
  
  const downloadOrViewCompleted = (isFromDownload) => {
    tapCount = 0;
    isFromDownload? setDownloadWait(false) : setViewWait(false);;
    setAttachmentId(null);
  }

  const viewAttachmentHealthJounrney=(id, fileName)=>{
    setViewWait(true);
    setAttachmentId(id);
    tapCount = tapCount + 1;
    if (tapCount === 1) {
    axiosCommon.axiosGET(urls.S3_ATTACHMENTS + id, (res) => {
      if (res.type === "SUCCESS") {
        let s3Url = res.responseDto.data.downloadUrl;
        if (s3Url) {
          if (Platform.OS === WEB) {
            let win = window.open(s3Url, '_blank');
            downloadOrViewCompleted(false);
            if (win) {
              win.focus();
            }
          } else {
            fileDownload(fileName, s3Url, ()=>{
              downloadOrViewCompleted(false);
            }, true, true )
          }
        }
      } else {
        downloadOrViewCompleted(false);
      }
    });
    }
  }
  
  const viewable = isViewableAttachment(filename);
  return (
    <View style={[stylesCss.secLightBlueBg, stylesCss.flexRow, stylesCss.flexAiCt, stylesCss.comSpSm, stylesRes.pdRtLgBlue]} key={index} dataSet={{media: ids.pdRtLgBlue}}>
        {/* bullet point- number */}
        {/* <View style={[stylesCss.contListItemIconBlock, stylesCss.wdThirtyFive]}>
          <Text style={stylesCss.textPrime}>
            {index}.
          </Text>
        </View> */}
        {/* attachment info box */}
        <View style={[
          stylesRes.wdAttachment,
          stylesCss.boxBorder,
          stylesCss.bgWhite,
          stylesCss.attachmentWrapperHg,
          stylesCss.flexRow,
          stylesCss.flexJcSb,
          stylesCss.flexAiCt,
          stylesCss.pdLtFourteen,
          stylesCss.pdRtFourteen
          ]}
          dataSet={{media: ids.wdAttachment}}> 
            <View style={[stylesCss.flexRow, stylesCss.flexAiCt]}>
              <View>
                <Image
                    style={stylesCss.listViewIcon}
                    source={findThumbnail(filename)}
                    resizeMode={constants.IMAGE_RESIZE_MODE}
                  />
                </View>
              <View style={[stylesCss.btnIconView, stylesRes.mxWidthAttachmentNm]} dataSet={{media: ids.mxWidthAttachmentNm}}>
                <Text style={stylesCss.textPrime} numberOfLines={1}>{filename}</Text>
              </View>
            </View>
          
            <View style={[stylesCss.flexRow, stylesCss.flexJcCtAiCt]}>
                {(downloadWait || viewWait) && id === attachmentId && (
                  <View style={stylesCss.pdfWaitSm}>
                    <View style={stylesCss.attachDownloadLoader}>
                      <ActivityIndicator size="small" color={theme.COLOR_BLUE_16} />
                    </View>
                  </View>
                )}
                <TouchableOpacity
                  onPress={viewable ? ()=>viewAttachmentHealthJounrney(id, filename) : ()=>downloadAttachment(id, filename)}>
                    {viewable
                    ?  <Text style={[ stylesCss.readMoreTxt, stylesCss.textTitleBold, stylesCss.seaBluTxtColor]}>
                        view
                      </Text>

                    :  <Image
                      style={{height: 18, width: 16}}
                      source={require('../../../assets/health-journey-icons/download-icon-blue.png')}
                      resizeMode={constants.IMAGE_RESIZE_MODE}
                      />
                    }
                </TouchableOpacity>
            </View>
        </View>
    </View>
  );
};

export default EventAttachmentItem;