import React, {Component} from 'react';
import {View, Text, TouchableOpacity, Platform} from 'react-native';
import {connect} from 'react-redux';
import styles from '../../../Css';
import TickButton from '../../../components/CheckBox/CheckBoxNew';
import * as actions from '../../../store/actions/index';
import InputMinMaxField from '../../../components/TextInput/inputMinMaxField';
import * as FieldDetails from '../../../components/TextInput/fieldDetails';
import PickerTemplate from '../../../components/Picker/pickerTemplate';
import Picker from '../../../components/Picker/picker';
import WarningInline from '../../../components/Warning/warningInline';
import ColorItemField from '../../../components/ColorPicker/ColorItemField';
import * as ColorCode from '../../../components/ColorPicker/ColorCodes';
import {chartTypes} from '../../../Constant/index';
import { WEB } from '../../../util/platformWindowConfig';


class Symptom extends Component {
  constructor(props) {
    super(props);
    const {
      symptoms,
      symptomProps,
      configChartType,
      defaultMin,
      defaultMax,
      item,
      routineSymptoms,
    } = props;
    let canRemoveSymptom = true;
    if (routineSymptoms.filter(symptom => 
      symptom.userSymptom == item.id && item.publishedBy === 'PARTNER'
    ).length > 0) {
      canRemoveSymptom = false;
    }
    this.state = {
      ...props.item,
      displayProperties: {...props.item.displayProperties},
      color:
        item.displayProperties && item.displayProperties.color
          ? item.displayProperties.color
          : ColorCode.BGSYMPTOM,
      tick: symptoms && symptoms[props.item.id] ? true : false,
      chartType:
        symptomProps &&
        symptomProps[props.item.id] &&
        symptomProps[props.item.id].style
          ? symptomProps[props.item.id].style
          : configChartType,
      minVal:
        symptomProps &&
        symptomProps[props.item.id] &&
        symptomProps[props.item.id].min
          ? symptomProps[props.item.id].min
          : defaultMin,
      maxVal:
        symptomProps &&
        symptomProps[props.item.id] &&
        symptomProps[props.item.id].max
          ? symptomProps[props.item.id].max
          : defaultMax,
      showDefaultMin:
        symptomProps &&
        symptomProps[props.item.id] &&
        symptomProps[props.item.id].min
          ? parseInt(symptomProps[props.item.id].min) === defaultMin
            ? true
            : false
          : true,
      showDefaultMax:
        symptomProps &&
        symptomProps[props.item.id] &&
        symptomProps[props.item.id].max
          ? parseInt(symptomProps[props.item.id].max) === defaultMax
            ? true
            : false
          : true,
      error:
        symptomProps &&
        symptomProps[props.item.id] &&
        symptomProps[props.item.id].max &&
        symptomProps[props.item.id].min &&
        parseInt(symptomProps[props.item.id].max) <=
          parseInt(symptomProps[props.item.id].min)
          ? true
          : false,
      errorDescription:
        symptomProps &&
        symptomProps[props.item.id] &&
        symptomProps[props.item.id].max &&
        symptomProps[props.item.id].min &&
        parseInt(symptomProps[props.item.id].max) <=
          parseInt(symptomProps[props.item.id].min)
          ? 'Min should be less than Max.'
          : null,
      hasData: false,
      canRemoveSymptom: canRemoveSymptom,
      colorPicked : false,
    };
  }

  componentDidMount() {
    this.checkHasData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate ||
      prevState.minVal !== this.state.minVal ||
      prevState.maxVal !== this.state.maxVal 
    ) {
      this.checkHasData();
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.symptoms && props.symptoms[this.state.id]) {
      this.setState({tick: true});
    } else {
      this.setState({tick: false});
    }
  }

  handleOnPressTick = (tick, id) => {
    const {chartType, minVal, maxVal, canRemoveSymptom} = this.state;
    const {configChartType, defaultMin, defaultMax, publishedByPartner} = this.props;
  
    if (tick) {
      this.props.addSymptom(id);
      this.props.updateSymptomProps(id, chartType, minVal, maxVal);
    } else {
      if(publishedByPartner && !canRemoveSymptom) {
        return;
      }
      this.props.removeSymptom(id);
      this.setState({
        chartType: configChartType,
        minVal: defaultMin,
        maxVal: defaultMax,
        showDefaultMin: true,
        showDefaultMax: true,
        error: false,
        errorDescription: '',
      });
    }
    this.checkHasData();
  };
  // shouldComponentUpdate(nextProps, nextState) {
  //   return (
  //     nextState.tick !== this.state.tick ||
  //     nextState.chartType !== this.state.chartType ||
  //     nextState.minVal !== this.state.minVal ||
  //     nextState.maxVal !== this.state.maxVal
  //   );
  // }

  onChangeChartType = selectedType => {
    const {id, minVal, maxVal} = this.state;
    const {configChartType} = this.props;
    this.setState({
      chartType: selectedType,
    });
    if (selectedType !== configChartType) this.props.addSymptom(id);
    this.props.updateSymptomProps(id, selectedType, minVal, maxVal);
  };
  changeMinValue = value => {
    const {id, chartType, maxVal} = this.state;
    //removes the decimal character
    if (value !== '') value = String(parseInt(value));
    var valid = value.match(/^-?\d*(\.\d+)?$/);

    if (isNaN(value) || !valid) {
      if (this.state.minVal === undefined) {
        this.setState({minVal: ''});
      }
    } else {
      if (value === '') {
        this.setState({
          minVal: this.props.defaultMin,
          showDefaultMin: true,
        });
        this.getMinMaxError(this.props.defaultMin, this.state.maxVal);
      } else {
        this.setState({
          minVal: value,
          showDefaultMin: false,
        });
        this.getMinMaxError(value, this.state.maxVal);
      }
      this.props.addSymptom(id);
      this.props.updateSymptomProps(
        id,
        chartType,
        value === '' ? this.props.defaultMin : value,
        maxVal,
      );
    }
    this.checkHasData();
  };
  changeMaxValue = value => {
    const {id, chartType, minVal} = this.state;
    //removes the decimal character
    if (value !== '') value = String(parseInt(value));
    var valid = value.match(/^-?\d*(\.\d+)?$/);

    if (isNaN(value) || !valid) {
      if (this.state.maxVal === undefined) {
        this.setState({maxVal: ''});
      }
    } else {
      if (value === '') {
        this.setState({
          maxVal: this.props.defaultMax,
          showDefaultMax: true,
        });
        this.getMinMaxError(this.state.minVal, this.props.defaultMax);
      } else {
        this.setState({
          maxVal: value,
          showDefaultMax: false,
        });
        this.getMinMaxError(this.state.minVal, value);
      }
      this.props.addSymptom(id);
      this.props.updateSymptomProps(
        id,
        chartType,
        minVal,
        value === '' ? this.props.defaultMax : value,
      );
    }
    this.checkHasData();
  };

  getMinMaxError = (min, max) => {
    if (parseInt(max) <= parseInt(min)) {
      this.setState({
        error: true,
        errorDescription: 'Min should be less than Max.',
      });
    } else {
      this.setState({
        error: false,
        errorDescription: '',
      });
    }
  };
  checkHasData = () => {
    //user selected dates
    let {
      startDate,
      endDate,
      item: {id},
    } = this.props;
    startDate = startDate ? new Date(startDate) : null;
    endDate = endDate ? new Date(endDate) : new Date();
    startDate && startDate.setHours(0, 0, 0, 0);
    endDate && endDate.setHours(23, 59, 59, 59);
    //default date if user has not selected dates
    if (!startDate) {
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 14);
      startDate.setHours(0, 0, 0, 0);
    }
    const journalEntries = Object.values(this.props.journalEntriesProcessed);
    let journsWithSelectedData = journalEntries.filter(journal => {
      //with the selected date range
      if (
        journal.entryDate > startDate.getTime() &&
        journal.entryDate < endDate.getTime()
      ) {
        if (
          journal.symptoms.find(
            symptom =>
              symptom.symptom === id &&
              (symptom.severity || symptom.severity === 0)
          )
        )
          return true;
        return false;
      }

      return false;
    });
    this.setState({hasData: journsWithSelectedData.length ? true : false});
  };
  render() {
    const {color, canRemoveSymptom} = this.state;
    const {item, publishedByPartner} = this.props;
    return (
      <View style={[styles.viewRowCom, styles.greyBorderThin]}>
        <View style={styles.viewDetailSec}>
          <View style={styles.viewMainData}>
            <View style={[styles.viewItemDesc, styles.colPdInSec]}>
              <View style={[styles.checkboxCircle, styles.flexAsfs]}>
                <TouchableOpacity
                  style={{height: 50}}
                  onPress={() =>
                    this.handleOnPressTick(!this.state.tick, this.state.id)
                  }
                  disabled={publishedByPartner && !canRemoveSymptom}
                  >
                  <TickButton
                    ticked={this.state.tick}
                    toggleTickCallback={tick =>
                      this.handleOnPressTick(tick, this.state.id)
                    }
                    disabled={publishedByPartner && !canRemoveSymptom}
                  />
                </TouchableOpacity>
              </View>
              <View style={[styles.viewDataFull]}>
                <View style={styles.itemTextWrapper}>
                  <Text style={styles.textPrime} allowFontScaling={false}>{this.state.name}</Text>
                </View>

                <View style={[styles.fieldRangeContainer, styles.flexCom]}>
                  <View style={[styles.fieldRowWrapper]}>
                  <View style={
                      this.props.fullWidth < 340 && Platform.OS === WEB
                      ? styles.fieldColExSmGraph
                      : this.props.fullWidth < 375 && Platform.OS === WEB
                      ? styles.fieldColSmGraph
                      : styles.fieldColGraph
                    }>
                      <PickerTemplate>
                        <Picker
                          items={[
                            {label: '▐ ▖Bar', value: chartTypes.bar, key: '1'},
                            {label: '╱ Line', value: chartTypes.line, key: '2'},
                            {label: '╌ Dash', value: chartTypes.dash, key: '3'},
                          ]}
                          style={[
                            styles.pickerAreaField,
                            styles.textPickerGraph,
                          ]}
                          pickerFrame={'PickerFrameMinMax'}
                          selectedValue={this.state.chartType}
                          onValueChange={this.onChangeChartType}
                        />
                      </PickerTemplate>
                    </View>
                    <View style={styles.fieldColSpRg} />
                    <View style={styles.fieldColMinMax}>
                      {!this.state.showDefaultMin ? (
                        <InputMinMaxField
                          title={'Min'}
                          // fieldLt={true}
                          fieldLabelThin={true}
                          fieldBg={FieldDetails.BGWHITE}
                          onChangeText={this.changeMinValue}
                          value={this.state.minVal}
                          maxLength={5}
                          keyboardType={
                            Platform.OS === WEB ? 'default' : 'numeric'
                          }
                          invalid={
                            parseInt(this.state.minVal) >=
                            parseInt(this.state.maxVal)
                          }
                        />
                      ) : (
                        <InputMinMaxField
                          title={'Min'}
                          // fieldLt={true}
                          fieldLabelThin={true}
                          fieldBg={FieldDetails.BGWHITE}
                          onChangeText={this.changeMinValue}
                          placeholder={'Auto'}
                          maxLength={5}
                          keyboardType={
                            Platform.OS === WEB ? 'default' : 'numeric'
                          }
                        />
                      )}
                    </View>
                    <View style={styles.fieldColSpRg} />
                    <View style={styles.fieldColMinMax}>
                      {!this.state.showDefaultMax ? (
                        <InputMinMaxField
                          title={'Max'}
                          // fieldLt={true}
                          fieldLabelThin={true}
                          fieldBg={FieldDetails.BGWHITE}
                          onChangeText={this.changeMaxValue}
                          value={this.state.maxVal}
                          maxLength={5}
                          keyboardType={
                            Platform.OS === WEB ? 'default' : 'numeric'
                          }
                          invalid={
                            parseInt(this.state.maxVal) <=
                            parseInt(this.state.minVal)
                          }
                        />
                      ) : (
                        <InputMinMaxField
                          title={'Max'}
                          // fieldLt={true}
                          fieldLabelThin={true}
                          fieldBg={FieldDetails.BGWHITE}
                          onChangeText={this.changeMaxValue}
                          placeholder={'Auto'}
                          maxLength={5}
                          keyboardType={
                            Platform.OS === WEB ? 'default' : 'numeric'
                          }
                        />
                      )}
                    </View>
                    <View style={styles.fieldColSpRg} />
                    <View style={[styles.flexAiFs, styles.flexCom]}>
                      <ColorItemField
                        color={color}
                        disabled={item.publishedBy === 'PARTNER'}
                        handleChangeComplete={color => {
                          if (color) {
                            this.setState({color: color});
                            let userSymptom = {
                              userSymptom: {
                                ...item,
                                displayProperties: {
                                  ...item.displayProperties,
                                  color: color,
                                },
                              },
                            };
                            this.props.addNewUserSymptom(userSymptom)
                            this.props.colorPicked(true)
                            // this.props.createUserSymptom(
                            //   userSymptom,
                            //   (success, addedSyptom) => {this.props.refreshGraph()},
                            //   this.props.journalUserId,
                            // );
                            this.props.refreshGraph()
                          }
                        }}
                      />
                    </View>
                  </View>
                  {Boolean(this.state.error) && (
                    <View style={styles.inlineError}>
                      <WarningInline
                        errorDescription={this.state.errorDescription}
                        textPosition={'right'}
                      />
                    </View>
                  )}
                  {Boolean(this.state.tick) && !Boolean(this.state.hasData) && (
                    <View style={styles.inlineError}>
                      <WarningInline
                        errorDescription={'No data in entries to plot'}
                        textPosition={'right'}
                      />
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    symptoms: state.rollinggraphs.symptoms,
    symptomProps: state.rollinggraphs.symptomProps,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    startDate: state.rollinggraphs.startDate,
    endDate: state.rollinggraphs.endDate,
    journalUserId: state.uistate.journalUserId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addSymptom: symptom => dispatch(actions.addSymptom(symptom)),
    removeSymptom: symptom => dispatch(actions.removeSymptom(symptom)),
    updateSymptomProps: (symptomId, chartType, min, max) =>
      dispatch(actions.updateSymptomProps(symptomId, chartType, min, max)),
    createUserSymptom: (userSymptom, callback, journalUserId) =>
      dispatch(actions.createUserSymptom(userSymptom, callback, journalUserId)),
    refreshGraph: () => dispatch(actions.refreshGraph()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Symptom);
