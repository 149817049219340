import theme from "./base/theme";
import * as variable from "./base/variable";
export const cssText = {
  text: {
    fontFamily: theme.FONT_FAMILY
  },
  bold: {
    fontWeight: theme.FONT_WEIGHT_BOLD
  },
  textThin: {
    fontWeight: "normal"
  },
  textColor: {
    color: theme.PRIMARY_FONT_COLOR
  },
  textEm: {
    fontStyle: "italic"
  },
  textUnderline: {
    textDecorationLine: "underline"
  },

  textUnderLineBlue: {
    textDecorationColor: theme.COLOR_UNDERLINE_BLUE
  },

  textFont: {
    fontFamily: theme.FONT_FAMILY
  },
  textLine: {
    lineHeight: 1
  },
  textExDbMicSm: {
    ...variable.fontPrimary, //(fontSize:8)
    fontSize: theme.FONT_SIZE_XXXX_SM
  },
  textExDbMicSmBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_XXXX_SM,
    fontWeight: "bold"
  },
  textExMicSm: {
    ...variable.fontPrimary, //(fontSize:9)
    fontSize: theme.FONT_SIZE_XX_SM
  },
  textExMicSmBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_XX_SM,
    fontWeight: "bold"
  },
  textMicSm: {
    ...variable.fontPrimary, //(fontSize:10)
    fontSize: theme.FONT_SIZE_XX_SM
  },
  textMicSmBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_XX_SM,
    fontWeight: "bold"
  },
  textPrimeExSm: {
    ...variable.fontPrimary, //(fontSize:11)
    fontSize: theme.FONT_SIZE_X_SM
  },
  textPrimeExSmBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_X_SM,
    fontWeight: "bold"
  },
  textPrimeSm: {
    ...variable.fontPrimary, //(fontSize:12)
    fontSize: theme.FONT_SIZE_SM
  },
  textPrimeSmBlue: {
    ...variable.fontFamilyPrimary, //(fontSize:12)
    fontSize: theme.FONT_SIZE_SM,
    color: '#3461B9',
    fontWeight: "bold"
  },
  textPrimeSmBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_SM,
    fontWeight: "bold"
  },
  textPrimeMd: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_MD //(fontSize:13)
  },
  textPrimeMdBlue: {
    ...variable.fontFamilyPrimary,
    fontSize: theme.FONT_SIZE_MD, //(fontSize:13)
    color: '#3461B9',
    fontWeight: "bold"
  },
  textPrimeMdBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_MD,
    fontWeight: "bold"
  },
  textPrime: {
    ...variable.fontPrimary,
    fontSize: theme.PRIMARY_TEXT_SIZE //(fontSize:14)
  },
  textPrimeBold: {
    ...variable.fontPrimary,
    fontSize: theme.PRIMARY_TEXT_SIZE,
    fontWeight: "bold"
  },
  textPrimeLg: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_LG //(fontSize:15)
  },
  textPrimeLgBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_LG,
    fontWeight: "bold"
  },
  textTitle: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_X_LG //(fontSize:16)
  },
  textTitleBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_X_LG,
    fontWeight: "bold"
  },
  clearAllTitleBold: {
    ...variable.fontDarkBlue,
    fontSize:14,
  },
  textTitleSm: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_XX_LG //(fontSize:17)
  },
  textTitleSmBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_XX_LG,
    fontWeight: "bold"
  },
  textTitleMd: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_XXX_LG //(fontSize:18)
  },
  textTitleMdBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_XXX_LG,
    fontWeight: "bold"
  },
  textTitleLg: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_XXXX_LG //(fontSize:19)
  },
  textTitleLgBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_XXXX_LG,
    fontWeight: "bold"
  },
  textTitleExLg: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_XXXXX_LG //(fontSize:20)
  },
  textTitleExLgBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_XXXXX_LG,
    fontWeight: "bold"
  },
  textTitleMain: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_Title_XX_SM //(fontSize:21)
  },
  textTitleMainBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_Title_XX_SM,
    fontWeight: "bold"
  },
  textTitleMainSm: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_Title_X_SM //(fontSize:22)
  },
  textTitleMainSmBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_Title_X_SM,
    fontWeight: "bold"
  },
  textTitleMainMd: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_Title_X_RG //(fontSize:23)
  },
  textTitleMainMdBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_Title_X_RG,
    fontWeight: "bold"
  },
  textTitleMainLg: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_Title_LG //(fontSize:24)
  },
  textTitleMainLgBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_Title_LG,
    fontWeight: "bold"
  },
  textTitleMainExLg: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_Title_X_LG //(fontSize:25)
  },
  textTitleMainExLgBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_Title_X_LG,
    fontWeight: "bold"
  },
  textCollapsibleTitle: {
    ...variable.fontCollapsibleTitle,
    fontSize: theme.PRIMARY_TEXT_SIZE //(fontSize:14)
  },
  textPinned: {
    ...variable.fontCollapsibleTitle,
    color: theme.PRIMARY_FONT_COLOR,
    fontSize: theme.FONT_SIZE_X_LG //(fontSize:16)
  },
  textNoSm: {
    ...variable.fontPrimary, //(fontSize:30)
    fontSize: 30
  },
  textNoSmBold: {
    ...variable.fontPrimary,
    fontSize: 30,
    fontWeight: "bold"
  },
  textSpecMd: {
    fontSize: 33, //(fontSize:33)
    ...variable.fontPrimary
  },
  textSpecMdBold: {
    fontSize: 33,
    ...variable.fontPrimary,
    fontWeight: "bold"
  },
  textSpec: {
    fontSize: 40, //(fontSize:40)
    ...variable.fontPrimary
  },
  textSpecBold: {
    fontSize: 40,
    ...variable.fontPrimary,
    fontWeight: "bold"
  },
  textNoMd: {
    ...variable.fontPrimary, //(fontSize:70)
    fontSize: 70
  },
  textNoMdBold: {
    ...variable.fontPrimary,
    fontSize: 70,
    fontWeight: "bold"
  },
  textNoLg: {
    ...variable.fontPrimary, //(fontSize:80)
    fontSize: 80
  },
  textNoLgBold: {
    ...variable.fontPrimary,
    fontSize: 80,
    fontWeight: "bold"
  },
  btnActiveText: {
    ...variable.fontPrimary, //(fontSize:12)
    fontSize: theme.FONT_SIZE_SM,
    color: theme.COLOR_WHITE,
    fontWeight: "bold"
  },
  btnInActiveText: {
    ...variable.fontPrimary, //(fontSize:12)
    fontSize: theme.FONT_SIZE_SM,
    color: theme.COLOR_GREY_MEDIUM,
    fontWeight: "normal"
  },
  linkText: {
    ...variable.fontPrimaryTextBold,
    color: theme.COLOR_ORANGE,
    textAlign: "center"
  },
  questDescText: {
    fontFamily: theme.FONT_FAMILY
  },
  smDescText: {
    fontFamily: theme.FONT_FAMILY
  },
  titleText: {
    ...variable.fontPrimaryText,
    textAlign: "center"
  },
  descText: {
    ...variable.fontPrimaryText,
    textAlign: "center"
  },
  progressTextSm: {
    paddingLeft: 20,
    paddingRight: 20
  },
  topicTitle: {
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 20,
    fontFamily: theme.FONT_FAMILY
  },
  topicDateText: {
    fontSize: 13,
    fontFamily: theme.FONT_FAMILY
  },
  smTitleText: {
    ...variable.fontPrimaryTextBold
  },
  smTitleTextCheckBox: {
    ...variable.fontPrimaryText
  },
  textCom: {
    color: theme.PRIMARY_FONT_COLOR,
    fontFamily: theme.FONT_FAMILY
  },
  textSizeXLg: {
    fontSize: theme.FONT_SIZE_X_LG
  },
  textPostBy: {
    fontSize: theme.FONT_SIZE_XXX_SM,
    color: theme.COLOR_GREY_MEDIUM
  },
  textSizeSm: {
    fontSize: theme.FONT_SIZE_SM
  },
  medDesc: {
    textAlign: "right",
    fontFamily: theme.FONT_FAMILY
  },
  textSm: {
    fontSize: 11,
    fontFamily: theme.FONT_FAMILY
  },
  textLg: {
    fontSize: 16,
    fontFamily: theme.FONT_FAMILY
  },
  sortItem: {
    fontSize: 11,
    fontFamily: theme.FONT_FAMILY
  },
  descLgText: {
    ...variable.fontPrimaryTextBold
  },
  menuSingleText: {
    ...variable.fontPrimaryTextBold
  },
  descSmTitleText: {
    ...variable.fontPrimaryText,
    fontSize: 10,
    lineHeight: 11,
    color: theme.SECONDARY_FONT_COLOR,
    fontWeight: "bold"
  },
  menuSingleNoteText: {
    ...variable.fontPrimaryText,
    fontStyle: "italic",
    fontSize: 12
  },
  menuTextLg: {
    ...variable.fontPrimaryText,
    fontSize: 16
  },
  smExContText: {
    color: theme.SECONDARY_FONT_COLOR,
    fontSize: 9
  },
  messageTitle: {
    ...variable.fontPrimaryTextBold
  },
  messageDateText: {
    fontSize: 13,
    letterSpacing: 0.05,
    paddingTop: 3,
    fontFamily: theme.FONT_FAMILY
  },
  textSlider: {
    paddingBottom: 15,
    fontFamily: theme.FONT_FAMILY
  },
  dateTextComment: {
    color: theme.COLOR_WHITE,
    fontFamily: theme.FONT_FAMILY
  },
  nameText: {
    ...variable.fontPrimaryTextBold,
    fontSize: 18
  },
  smLinkText: {
    ...variable.fontSecondaryText,
    fontSize: 12,
    textDecorationLine: "underline"
  },
  smContText: {
    ...variable.fontSecondaryText,
    fontSize: 12
  },
  detailTextBold: {
    ...variable.fontSecondaryTextBold,
    fontSize: 16,
    textAlign: 'center'
  },
  titleLgText: {
    ...variable.fontPrimaryTextBold,
    fontSize: 25
  },
  detailText: {
    ...variable.fontPrimaryTextBold
  },
  collapseTitle: {
    ...variable.fontPrimaryTextBold,
    fontSize: 30
  },
  warningText: {
    ...variable.fontPrimaryText,
    color: theme.COLOR_WARNING,
    fontSize: 12
  },
  warningTextLg: {
    ...variable.fontPrimaryText,
    color: theme.COLOR_WARNING,
    fontSize: 13
  },
  infoText: {
    ...variable.fontPrimaryText
  },
  dateFullText: {
    color: theme.COLOR_GREY,
    fontSize: theme.FONT_SIZE_SM
  },
  titleTextLg: {
    color: theme.PRIMARY_FONT_COLOR,
    fontSize: theme.FONT_SIZE_Title_X_SM
  },
  notifyText: {
    color: theme.COLOR_WARNING,
    textAlign: "left",
    fontSize: 11,
    fontFamily: theme.FONT_FAMILY,
    paddingLeft: 5
  },
  submitText: {
    fontSize: 16,
    textAlign: "center",
    color: theme.COLOR_WHITE,
    fontFamily: theme.FONT_FAMILY
  },

  cancelTxtCr: {
    fontWeight:'bold',
    color: theme.COLOR_GREY_DARK_1,
  },
  submitTextSm: {
    fontSize: 14
  },
  submitTextExSm: {
    fontSize: 12
  },
  headerText: {
    fontSize: 13,
    fontFamily: theme.FONT_FAMILY
  },
  textComSm: {
    fontSize: 9,
    color: theme.SECONDARY_FONT_COLOR,
    fontStyle: "italic",
    fontFamily: theme.FONT_FAMILY
  },
  titleTextMain: {
    ...variable.fontPrimaryTextBold,
    fontSize: 20
  },
  menuText: {
    ...variable.fontPrimaryText
  },
  menuLinkText: {
    textDecorationLine: "none"
  },
  courseTitle: {
    color: theme.COLOR_ORANGE_DARK,
    fontSize: 16,
    fontWeight: "bold"
  },
  statusTextIn: {
    fontSize: 15,
    fontWeight: "bold"
  },
  btnText: {
    color: theme.COLOR_ORANGE_DARK,
    fontSize: 14,
    fontWeight: "bold"
  },
  dateTextQuest: {
    textAlign: "right"
  },
  ltTextWeb: {
    textAlign: "right"
  },
  rtTextWeb: {
    textAlign: "left"
  },
  textAlignLeft: {
    textAlign: "left"
  },
  textAlignRight: {
    textAlign: "right"
  },
  textAlignCenter: {
    textAlign: "center"
  },
  centerText: {
    textAlign: "center"
  },
  statusBarText: {
    fontWeight: "bold"
  },
  rtText: {
    textAlign: "right"
  },
  ltText: {
    textAlign: "left"
  },
  textCompleteGreen: {
    color: theme.COLOR_GREEN_6
  },
  textBlack: {
    color: theme.COLOR_BLACK
  },
  textGrey: {
    color: theme.COLOR_GREY_MEDIUM
  },
  textGreyDark: {
    color: theme.COLOR_GREY
  },
  textGreyDarkMd: {
    color: theme.COLOR_GREY_2
  },
  darkLinktext: {
    color: theme.PRIMARY_FONT_COLOR
  },
  textColorLight: {
    color: theme.SECONDARY_FONT_COLOR
  },
  textColorGreen: {
    color: theme.COLOR_SUCCESS
  },
  textOrange: {
    color: theme.COLOR_ORANGE_DARK
  },
  textLightBlueGraph: {
    color: theme.COLOR_BLUE_14
  },
  headerTextActive: {
    color: theme.COLOR_ORANGE,
    fontWeight: "bold"
  },
  headerTextCom: {
    color: theme.COLOR_GREY
  },
  menuTextActive: {
    color: theme.COLOR_ORANGE
  },
  smText: {
    color: theme.COLOR_ORANGE
  },
  textInActive: {
    color: theme.COLOR_BORDER
  },
  textOrangeLight: {
    color: theme.COLOR_ORANGE
  },
  textGreyLight: {
    color: theme.COLOR_GREY_LIGHT_EX_8
  },
  textWhite: {
    color: theme.COLOR_WHITE
  },
  userPendingText: {
    color: theme.SECONDARY_FONT_COLOR
  },
  userDeclinedText: {
    color: theme.COLOR_WARNING
  },
  userConnectedText: {
    color: theme.COLOR_GREEN
  },
  textLight: {
    color: theme.COLOR_BORDER
  },
  textDark: {
    color: theme.PRIMARY_FONT_COLOR
  },
  btnWhiteText: {
    color: theme.COLOR_WHITE
  },
  btnBlackText: {
    color: theme.PRIMARY_FONT_COLOR
  },
  textOrangeThin: {
    color: theme.COLOR_ORANGE_4
  },
  bulletOrangeText: {
    color: theme.COLOR_ORANGE_DARK_2
  },
  seaBluTxtColor:{
    color: theme.COLOR_BLUE_16
  },
  resourceBlueTxt: {
    color: theme.COLOR_BLUE_DARK_2
  },

  textHelpMenu: {
    color: theme.COLOR_WHITE,
    fontWeight: "bold",
    fontStyle: "italic"
  },
  btnMinText: {
    lineHeight: 16
  },
  txtGreyHighlightCr: {
    color:theme.COLOR_GREY_DARK
  },
  textHelpTitle: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_X_LG,
    fontWeight: "bold",
    color: theme.COLOR_WHITE
  },
  smLineHeight: {
    lineHeight: 15
  },
  rgLineHeight: {
    lineHeight: 16
  },
  lgLineHeight: {
    lineHeight: 18
  },
  textHelpLgTitle: {
    color: theme.COLOR_BORDER
  },
  btnSmTextBold: {
    fontSize: 12,
    textAlign: "center",
    color: theme.COLOR_WHITE,
    fontFamily: theme.FONT_FAMILY,
    fontWeight: "bold"
  },
  menuTextDefault: {
    color: theme.PRIMARY_FONT_COLOR,
    textDecorationLine: "none"
  },
  menuTextSelected: {
    color: theme.COLOR_ORANGE,
    textDecorationLine: "none"
  },
  itemPicker: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_RG,
    color: "red"
  },
  btnTextSm: {
    textAlign: "center",
    fontSize: theme.FONT_SIZE_SM,
    color: theme.COLOR_WHITE,
    fontFamily: theme.FONT_FAMILY,
    fontWeight: "bold"
  },
  textBlue: {
    color: theme.COLOR_BLUE_1
  },
  textRed: {
    color: theme.COLOR_RED_DARK
  },
  textLightWhite: {
    color: theme.COLOR_LIGHT_WHITE
  },
  textLightBlack: {
    color: theme.COLOR_GREY_DARK_EX
  },
  textMedBlack: {
    color: theme.COLOR_GREY_TEXT
  },
  warningComText: {
    textAlign: "left",
    fontFamily: theme.FONT_FAMILY,
    fontSize: theme.FONT_SIZE_X_SM,
    paddingLeft: 5,
    color: theme.COLOR_RED
  },
  warningComTextInd: {
    textAlign: "center",
    fontFamily: theme.FONT_FAMILY,
    fontSize: theme.FONT_SIZE_X_SM,
    color: theme.COLOR_RED
  },
  textColorGreyLight: {
    color: theme.COLOR_GREY_DARK_1
  },
  submitBtnText: {
    fontSize: 14,
    textAlign: "center",
    color: theme.COLOR_GREY_DARK_1,
    fontFamily: theme.FONT_FAMILY
  },
  submitBtnTextWhite: {
    fontSize: 14,
    textAlign: "center",
    color: theme.COLOR_WHITE,
    fontFamily: theme.FONT_FAMILY
  },
  commonCancelBtnTxt:{
    fontSize: 14,
    textAlign: "center",
    color: theme.COLOR_GREY_DARK_1,
    fontFamily: theme.FONT_FAMILY,
    fontWeight: "bold"
  },
  textErr: {
    color: theme.COLOR_RED
  },
  lineHeightLgNo: {
    lineHeight: 40
  },
  lineHeightSmNo: {
    lineHeight: 30
  },
  textExLightWhite: {
    color: theme.COLOR_LIGHT_WHITE_1
  },
  activeText: {
    color: theme.PRIMARY_FONT_COLOR,
  },
  defaultText: {
    color: theme.COLOR_GREY,
  },
  textPrimeCom: {
    color: theme.PRIMARY_FONT_COLOR,
  },
  textThickGrey: {
    color: theme.COLOR_GREY_DARK_3,
  },
  messageText: {
    color: theme.COLOR_ORANGE_5,
  },
  textBlueDark: {
    color: theme.COLOR_BLUE_7
  },
  textTertiary: {
    color: theme.TERTIARY_FONT_COLOR
  },
  textNoExSm: {
    ...variable.fontPrimary, //(fontSize:30)
    fontSize: 20
  },
  textNoLgSpec: {
    ...variable.fontPrimary, //(fontSize:80)
    fontSize: 35
  },
  progressBarText: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_X_LG,
    lineHeight:22,
    textAlign:'center'
  }, 
  textNotificationCount: {
    fontFamily: theme.FONT_FAMILY,
    fontSize: theme.FONT_SIZE_XXX_SM,
    fontWeight: "bold",
    lineHeight: 15.15,
    textAlign: 'center',
    bottom: 1.25,
  },
  textUOMListItem: {
    color: theme.COLOR_BLUE_15, 
    fontWeight: 'bold'
  },
  textBlueUOM: {
    color: theme.COLOR_BLUE_15
  },
  textWeightFiveHundred: {
    fontWeight:'500'
  },
  txtCapitalizaTransfor:{
    textTransform:'capitalize'
  },
  healthJourneySeaBlue:{
    color:theme.COLOR_BLUE_TEXT_UNDELINE
  },
  lineHgTwenty: {
    lineHeight:20
  },
  tagText:{
    ...variable.fontFamilyPrimary,
    fontSize: theme.FONT_SIZE_MD,
    color: theme.COLOR_BLUE_16,
    fontStyle: 'italic',

  },
  fontBold: {
    fontWeight: 'bold'
  },
};