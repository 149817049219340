import React from "react";
import { Image, View, Text, TouchableOpacity, Platform } from "react-native";
import styles from "../../../../Css";
import Header from "../../../Header/header";
import SpaceBar from "../../../SpaceBar/SpaceBar";
import PageContainer from "../../../../components/PageLayout/PageContainer";
import PageContainerInline from "../../../../components/PageLayout/PageContainerInline";
import KeyboardAwareScrollViewHoc from "../../../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc";
import Collapsible from "../../../Collapsible/Collapsible";
import QuestionBlock from "./questionBlock";
import InputField from "../../../../components/TextInput/inputField";
import ActivityIndicator from "../../../ActivityIndicator/activityIndicator";
import CancelButton from "../../../Button/cancelButton";
import { WEB } from "../../../../util/platformWindowConfig";


const FindPeersComponent = (props) => {
  const {
    index,
    navigation,
    handleOnPressBackButton,
    windowWidth,
    peopleLikeMe,
    onPressOtherUser,
    mySex,
    onPressMySex,
    disabledMySex,
    displayMySexError,
    minAge,
    maxAge,
    invalidAge,
    onChangeMinAge,
    onChangeMaxAge,
    showMoreFilters,
    onPressCollapsibleMoreFilters,
    userConditions,
    selectedConditionList,
    onPressUserCondition,
    treatments,
    selectedTreatmentList,
    onPressTreatment,
    symptoms,
    selectedSymptomList,
    onPressSymptom,
    treatmentAll,
    onPressTreatmentTimeFilter,
    symptomAll,
    onPressSymptomTimeFilter,
    searchWait,
    handleOnPressSearchPeers,
    handleOnSubmitCancel,
    scrollViewRef,
    route,
  } = props;
  scrollViewRef && scrollViewRef(this);
  return (
    <View style={styles.pageBodyWrapper}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showHome={false}
        showNotify={true}
        hideCaregiverAccess={true}
        handleOnPressBackButton={handleOnPressBackButton}
        index={index}
        navigation={navigation}
        route={route}
      />
      <SpaceBar />
      <KeyboardAwareScrollViewHoc ref={scrollViewRef}>
          <View style={styles.gridSection}>
            <View style={[styles.gridRow, styles.pageBotPd]}>
              <View style={[styles.gridColFull, styles.pdBotLg]}>
              <PageContainer>
                <View style={[styles.secArea, styles.colPdTopBotLg]}>
                  <View
                    style={[
                      styles.pageTitleWrapper,
                      // styles.contPdTop,
                      styles.comSpLg,
                    ]}
                  >
                    <Text style={[
                      // styles.textTitleMdBold
                      windowWidth > 1366
                      ? styles.textTitleLgBold
                      : styles.textTitleMdBold,
                      ]}
                      allowFontScaling= {false}
                      >
                      Select your search criteria
                    </Text>
                  </View>

                  <View style={styles.questionAnswerBlock}>
                    <View style={[styles.questionWrapper, styles.noBotMg]}>
                      <Text style={
                        // styles.textPrimeBold
                        windowWidth > 1366
                        ? styles.textTitleBold
                        : styles.textPrimeBold
                        }
                        allowFontScaling= {false}
                        >
                        Show me connections that are:
                      </Text>
                    </View>

                    <View style={styles.answerWrapper}>
                      <View style={[styles.answerSingle, styles.answerSinglePd]}>
                        <TouchableOpacity
                          style={[styles.answerOptMg, styles.answerViewWrapper]}
                          onPress={onPressOtherUser}
                        >
                          <View style={styles.optionWrapper}>
                            {peopleLikeMe ? (
                              <Image
                                style={styles.radioIcon}
                                source={require("../../../../assets/icons/radio-selected.png")}
                              />
                            ) : (
                              <Image
                                style={styles.radioIcon}
                                source={require("../../../../assets/icons/radio-default.png")}
                              />
                            )}
                          </View>
                          <View style={styles.answerTextWrapper}>
                            <Text style={[
                              // styles.textPrime
                              windowWidth > 1366
                              ? styles.textTitle
                              : styles.textPrime,
                              ]}
                              allowFontScaling= {false}
                              >
                              People like me
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                      <View style={styles.answerSingle}>
                        <TouchableOpacity
                          style={[styles.answerOptMg, styles.answerViewWrapper]}
                          onPress={onPressOtherUser}
                        >
                          <View style={styles.optionWrapper}>
                            {!peopleLikeMe ? (
                              <Image
                                style={styles.radioIcon}
                                source={require("../../../../assets/icons/radio-selected.png")}
                              />
                            ) : (
                              <Image
                                style={styles.radioIcon}
                                source={require("../../../../assets/icons/radio-default.png")}
                              />
                            )}
                          </View>

                          <View style={styles.answerTextWrapper}>
                            <Text style={[
                              // styles.textPrime
                              windowWidth > 1366
                              ? styles.textTitle
                              : styles.textPrime,
                              ]}
                              allowFontScaling= {false}
                              >
                              Caring for people like me
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>

                  <View style={styles.questionAnswerBlock}>
                    <View style={[styles.questionWrapper, styles.noBotMg]}>
                      <Text style={
                        // styles.textPrimeBold
                        windowWidth > 1366
                        ? styles.textTitleBold
                        : styles.textPrimeBold
                        }
                        allowFontScaling= {false}
                        >
                        I want to refine the search with the following criteria:
                      </Text>
                    </View>

                    <View style={styles.answerWrapper}>
                      <View style={styles.answerSingle}>
                        <TouchableOpacity
                          style={[styles.answerOptMg, styles.answerViewWrapper]}
                          onPress={onPressMySex}
                        >
                          <View style={styles.optionWrapper}>
                            {mySex ? (
                              <Image
                                style={styles.radioIcon}
                                source={require("../../../../assets/toggleTick/check-tick.png")}
                              />
                            ) : (
                              <Image
                                style={styles.radioIcon}
                                source={require("../../../../assets/toggleTick/check-untick.png")}
                              />
                            )} 
                          </View>
                          <View style={styles.answerTextWrapper}>
                            <Text style={[
                              windowWidth > 1366
                              ? styles.textTitle
                              : styles.textPrime,
                              disabledMySex
                              ? styles.textGreyDarkMd
                              : styles.activeText
                            ]}
                            allowFontScaling= {false}
                            >
                              My Sex
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                      {Boolean(displayMySexError) &&
                        <View style={[styles.fieldInfo, styles.topPdExSm]}>
                          <Text style={[styles.textMicSm, styles.warningComText]} allowFontScaling= {false}>
                            Please select your sex in the community or main profile
                          </Text>
                        </View>
                      }
                    </View>

                    <View style={styles.sectionView}>
                      <View
                        style={[
                          styles.fieldRow,
                          styles.noMgBot,
                          styles.colPdTop,
                        ]}
                      >
                        <View style={styles.fieldRowWrapper}>
                          <View style={styles.fieldColInput}>
                            <InputField
                              title={"Min Age"}
                              value={minAge}
                              invalid={invalidAge}
                              onChangeText={onChangeMinAge}
                              fieldLt={true}
                              placeholder={"Min age"} 
                              keyboardType={
                                Platform.OS === WEB ? 'default' : 'numeric'
                              }
                              maxLength={3}
                            />
                          </View>
                          <View
                            style={[styles.fieldColText, styles.flexJcCtAiCt]}
                          >
                            <Text style={styles.textPrimeSm} allowFontScaling= {false}>to</Text>
                          </View>
                          <View style={styles.fieldColInput}>
                            <InputField
                              title={"Max Age"}
                              value={maxAge}
                              invalid={invalidAge}
                              onChangeText={onChangeMaxAge}
                              fieldLt={true}
                              placeholder={"Max age"}
                              keyboardType={
                                Platform.OS === WEB ? 'default' : 'numeric'
                              }
                              maxLength={3}
                            />
                          </View>
                        </View>
                        {Boolean(invalidAge) &&
                          <View style={[styles.fieldInfo, styles.topPdExSm]}>
                            <Text
                              style={[
                                styles.textMicSm,
                                styles.warningComText
                              ]}
                              allowFontScaling= {false}
                              >
                                {parseInt(minAge) <= 13
                                  ? 'Min Age should be greater than 13'
                                  : 'Max Age should be greater than Min Age'
                                }
                            </Text>
                          </View>
                        }
                      </View>
                    </View>
                  </View>

                  <View style={styles.questionAnswerBlock}>
                    <View style={[styles.questionWrapper, styles.noBotMg]}>
                      <Text
                        style={[
                          // styles.textPrimeBold,
                          windowWidth > 1366
                          ? styles.textTitleBold
                          : styles.textPrimeBold,
                          // props.valid
                          // ? styles.textColor
                          // : styles.textRed
                        ]}
                        allowFontScaling= {false}
                      >
                        {Boolean(peopleLikeMe) ? "Who also have any of these condition(s):" : "Who are caring for others with any of these condition(s):"}
                      </Text>
                    </View>

                    <View style={styles.answerWrapper}>
                      {userConditions && userConditions.length > 0 &&
                        userConditions.map(res => {
                          return (
                            <View style={[styles.answerSingle, styles.answerSinglePd]} key={res.id}>
                              <TouchableOpacity
                                style={[styles.answerOptMg, styles.answerViewWrapper]}
                                onPress={() => onPressUserCondition(res)}
                              >
                                <View style={[styles.optionWrapper]}>
                                  {selectedConditionList.find(element => element.id === res.id) ? (
                                    <Image
                                      style={styles.radioIcon}
                                      source={require("../../../../assets/toggleTick/check-tick.png")}
                                    />
                                  ) : (
                                    <Image
                                      style={styles.radioIcon}
                                      source={require("../../../../assets/toggleTick/check-untick.png")}
                                    />
                                  )}
                                </View>
                                <View style={styles.answerTextWrapper}>
                                  <Text style={[
                                    // styles.textPrime
                                    windowWidth > 1366
                                    ? styles.textTitle
                                    : styles.textPrime,
                                    ]}
                                    allowFontScaling= {false}
                                    >{res.name}</Text>
                                </View>
                              </TouchableOpacity>
                            </View>
                          );
                        })
                      }
                    </View>
                  </View>
                </View>
                </PageContainer>
                <PageContainerInline>
                <Collapsible
                  title="More Filters"
                  contentVisibility={showMoreFilters}
                  handleOnPressCollapsible={() => onPressCollapsibleMoreFilters()}
                  topBorder={true}
                  titleSm={true}
                >
                  <View style={[styles.collapseContent]}>
                    <View
                      style={[
                        styles.collapseInContent,
                      ]}
                    >
                      <QuestionBlock 
                        title={'One or more of these treatments:'}
                        items={treatments} 
                        selectedList={selectedTreatmentList}
                        onPressItem={onPressTreatment}
                        selectAll={treatmentAll}
                        onPressTimeFilter={onPressTreatmentTimeFilter}
                        windowWidth={windowWidth}

                      />
                      <QuestionBlock 
                        title={'One or more of these symptoms:'}
                        items={symptoms} 
                        selectedList={selectedSymptomList}
                        onPressItem={onPressSymptom}
                        selectAll={symptomAll}
                        onPressTimeFilter={onPressSymptomTimeFilter}
                        windowWidth={windowWidth}
                      />
                    </View>
                  </View>
                </Collapsible>

                <View style={[styles.greyBorderThin, styles.colPdTopBotLg]} />
                <View style={[styles.subSection, styles.zIndexMin]}>
                  <View style={styles.btnArea}>
                    <View style={styles.btnMaxWrapperLg}>
                      <View style={styles.fieldBtn}>
                        <View style={styles.btnBlockFull}>
                          <CancelButton 
                            touchableOnpress={handleOnSubmitCancel}
                          />
                        </View>
                      </View>
                      <View style={styles.fieldBtnSpSm} />
                      <View style={styles.fieldBtn}>
                        <View style={styles.btnBlockFull}>
                          <TouchableOpacity
                            style={[
                              styles.submitSm,
                              styles.btnShadowCom,
                              styles.submitBgBlue,
                              searchWait
                                ? styles.btnBlurState
                                : styles.btnActiveState,
                            ]}
                            underlayColor="rgba(0, 153, 168, 0.8)"
                            disabled={searchWait}
                            onPress={handleOnPressSearchPeers}
                          >
                            <View style={styles.btnSingleView}>
                              <View
                                style={[styles.btnSingle, styles.btnSingleCent]}
                              >
                                {searchWait && (
                                  <View
                                    style={[
                                      styles.indicatorIconView,
                                      styles.indicatorLtPeers,
                                    ]}>
                                    <ActivityIndicator pdZero={true} size="small" />
                                  </View>
                                )}
                                <Text
                                  style={[
                                    styles.submitBtnTextWhite,
                                    styles.bold,
                                  ]}
                                  allowFontScaling= {false}
                                >
                                  Search Connections
                                </Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </View> 
                </PageContainerInline>
              </View>
            </View>
          </View>
      </KeyboardAwareScrollViewHoc>
    </View>
  );
};
export default (FindPeersComponent);
