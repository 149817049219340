import * as actionTypes from "../actions/actionTypes";
// import { updateObject } from "../../shared/utility";

const INITIAL_STATE = {
  prescription: {
    BrandName: null,
    Prescriber: null,
    Type: null,
    IdentificationNumber: null,
    Units: null
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.OCR_SCAN_PRESCRIPTION_OK:
      return state;
    default:
      return state;
  }
};

export default reducer;
