import * as axiosCommon from '../../axios/axios-common';
import * as url from './urls';
import * as actionTypes from './actionTypes';
import {logout, commonErrorHandler} from './index';

// Create New Routine
export const createNewRoutine = (journalEntry, callBack, journalUserId) => {
  let requestUrl = url.CREATE_NEW_ROUTINE;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          const entry = {...res.responseDto.data.journalEntry}
          dispatch(createNewRoutineSuccess({...entry, entryDate: journalEntry?.entryDate ?? entry?.entryDate}));
          // dispatch(
          //   saveJournalEntrySuccess(res.responseDto.data.journalEntry, true),
          // );
          callBack(true, {...entry, entryDate: journalEntry?.entryDate ?? entry?.entryDate});
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; createNewRoutine");
            dispatch(logout());
          } else {
            //  dispatch(createNewRoutineFailed(res.errorDto.data.message));
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false);
          }
        }
      },
      journalEntry,
    );
  };
};

export const createNewRoutineSuccess = data => {
  return {
    type: actionTypes.CREATE_NEW_ROUTINE_SUCCESS,
    newJournal: data,
  };
};

export const createNewRoutineFailed = errorMsg => {
  return {
    type: actionTypes.CREATE_NEW_ROUTINE_FAIL,
    errMessage: errorMsg,
  };
};

export const resetNewJournal = () => {
  return dispatch => {
    dispatch(createNewRoutineSuccess(null));
  };
};

//Save a journal entry
export const saveJournalEntry = (journalEntry, callBack, journalUserId, deletedSymptomIds, isFromCopyToNew = false, isFromOverdueAddhocEntry = false) => {
  let requestUrl = url.SAVE_JOURNAL_ENTRY;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            saveJournalEntrySuccess(res.responseDto.data.journalEntry, false, deletedSymptomIds, isFromCopyToNew),
          );
          if (!isFromOverdueAddhocEntry) dispatch(createNewRoutineSuccess(null));
          callBack(true, res.responseDto.data);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; saveJournalEntry");
            dispatch(logout());
          } else {
            // dispatch(saveJournalEntryFailed(res.errorDto.data.message));
            if (res.errorDto.data.error !== 'NETWORK_ERROR') {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
            }
          }
        }
      },
      journalEntry,
    );

    try {
      dispatch(deleteRoutineDraftData(journalUserId));
    } catch (error) {
      console.log('delet error object');
    }
  };
};

export const saveJournalEntrySuccess = (data, create, deletedSymptomIds, isFromCopyToNew) => {
  data.isNew = create;
  return {
    type: actionTypes.SAVE_JOURNAL_ENTRY_SUCCESS,
    newJournal: data,
    create: create,
    deletedSymptomIds: deletedSymptomIds,
    isFromCopyToNew: isFromCopyToNew,
  };
};

export const saveJournalEntryFailed = errorMsg => {
  return {
    type: actionTypes.SAVE_JOURNAL_ENTRY__FAIL,
    errMessage: errorMsg,
  };
};

// Save a new or existing user Health Question answer
export const saveHealthQuestion = (
  healthQuestion,
  artifactId,
  callBack,
  journalUserId,
) => {
  let requestUrl = url.UPDATE_HEALTH_QUESTION;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(saveHealthQuestionSuccess(res.responseDto.data, artifactId));
          callBack(true, res.responseDto.data);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; saveHealthQuestion");
            dispatch(logout());
          } else {
            dispatch(saveHealthQuestionFail(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      healthQuestion,
    );
  };
};

// Save Health Question
export const saveHealthQuestionSuccess = (response, artifactId) => {
  return {
    type: actionTypes.SAVE_HEALTH_QUESTION_SUCCESS,
    response: response,
    artifactId: artifactId,
  };
};

export const saveHealthQuestionFail = errorMsg => {
  return {
    type: actionTypes.SAVE_HEALTH_QUESTION_FAIL,
    errorDescription: errorMsg,
  };
};

export const updateQestionList = questionList => {
  return {
    type: actionTypes.UPDATE_QUESTION_LIST,
    questionList: questionList,
  };
};

export const updateTemporyQuestionObject = questionObject => {
  return {
    type: actionTypes.UPDATE_TEMPORY_QUESTION_OBJECT,
    questionObject: questionObject,
  };
};

export const editRoutineTemplate = journalEntry => {
  return {
    type: actionTypes.EDIT_ROUTINE_TEMPLATE,
    journalEntry: journalEntry,
  };
};

export const copyRoutineTemplate = journalEntry => {
  return {
    type: actionTypes.COPY_ROUTINE_TEMPLATE,
    journalEntry: journalEntry,
  };
};

//update journalEntryState
export const updateJournalEntryState = (data, callBack) => {
  return dispatch => {
    dispatch(updateJournalEntryStateSuccess(data));
    callBack();
  };
};
export const updateJournalEntryStateSuccess = data => {
  return {
    type: actionTypes.UPDATE_JOURNAL_ENTRY_STATE,
    newJournal: data,
  };
};

export const deleteRoutineDraftData = (journalUserId) => {
  try {
    let urls = url.DELETE_DRAFT_JOURNAL_ENTRY;
    if (journalUserId) {
      urls += '?journalUserId=' + journalUserId;
    }
    axiosCommon.axiosDELETE(
      urls,
      (res) => {
      },
      { journalUserId }
    );
  } catch (error) {
    console.log("draft deletion failed");
  }
}

export const updateTemporyEntryObject = obj => {
  return {
    type: actionTypes.UPDATE_TEMPORY_ENTRY_OBJECT,
    entryObject: obj,
  };
};
