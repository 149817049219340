import React, {Component} from 'react';
import {
  View,
  ScrollView,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  Keyboard,
} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Header from '../../components/Header/header';
import UIText from '../../components/Text/text';
import {connect} from 'react-redux';
import * as validator from '../../validator/validator';
import * as actions from '../../store/actions/index';
import SavingConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import Picker from '../../components/Picker/picker';
import Warning from '../../components/Warning/warning';
import {CAREGIVERS, DASHBOARD} from '../../navigation/path';
import LearnHowPrivacyPolicy from '../SignUp/LearnHowPrivacyPolicy';
import {
  alertBodySpec,
  caregiverTitle,
} from '../../GlobalFunctions/GlobalString';
import MenuOverlayContainer from '../../components/PageLayout/MenuOverlayContainer';
import {pendoTrackEvent} from '../../util/pendoConfig';
import CancelButton from '../../components/Button/cancelButton';

let initialValue = {};
class InviteCaregiverScreen extends Component {
  constructor(props) {
    super(props);
    if (props.journalUserId) {
      props.navigation.push(DASHBOARD.path);
    }
    this.textInput = null;
    this.state = {
      emailAddresses: [],
      email: '',
      note:
        'Hi! Would you accept my invitation to be a part of my care network?',
      confirmBox: false,
      wait: false,
      disabledInvite: true,
      error: false,
      errorDescription: '',
      showLearnHowPrivacyPolicy: false,
    };
    initialValue = this.state;
  }

  componentDidMount() {
    this.props.navigation.addListener('focus', () => {
      if (this.props.emailVerified && this.textInput) {
        this.textInput.focus();
      }
      this.props.hideMobileMenu();
      pendoTrackEvent('Caregivers/@InviteCaregiver');
    });
    this.props.navigation.addListener('blur', () => {
      this.props.showMobileMenu();
    });
  }

  onChangeEmailText = value => {
    if (value.trim().indexOf(' ') >= 0) {
      this.setState({
        email: value,
        error: true,
        errorDescription: 'Please enter valid email address.',
      });
    } else {
      this.setState({
        email: value,
        error: false,
        errorDescription: '',
      });
    }
  };

  onChangeNoteText = value => {
    this.setState({note: value});
  };

  onKeyPress = e => {
    if (e.nativeEvent.key === 'Enter' || e.nativeEvent.key === ' ') {
      this.addEmailToList();
    }
    if (e.nativeEvent.key === 'Delete' || e.nativeEvent.key === 'Backspace') {
      if (this.state.email === '' && this.state.emailAddresses.length > 0) {
        for (let i = 0; i < this.state.emailAddresses.length; i++) {
          this.remove(this.state.emailAddresses[i].email);
        }
      }
    }
  };

  onBlur = e => {
    this.addEmailToList();
  };

  addEmailToList = () => {
    if (this.state.email.trim() === '') {
      setTimeout(() => {
        this.setState({
          error: false,
          errorDescription: '',
        });
      }, 500);
    } else if (validator.validateEmail(this.state.email.trim())) {
      if (this.checkEmailAreadyInList(this.state.email.trim())) {
        setTimeout(() => {
          this.setState(
            {
              email: '',
              error: true,
              errorDescription: 'Email address already exist.',
            },
            () => {
              if (this.textInput) {
                this.textInput.focus();
              }
            },
          );
        }, 500);
      } else {
        const emailAddresses = [...this.state.emailAddresses];
        emailAddresses.push({
          email: this.state.email.trim(),
          permission: 'READ_WRITE',
        });
        setTimeout(() => {
          this.setState(
            {
              email: '',
              emailAddresses: emailAddresses,
              error: false,
              errorDescription: '',
            },
            () => {
              // if (this.textInput) {
              //   this.textInput.focus();
              // }
              Keyboard.dismiss();
              this.setState({disabledInvite: false});
            },
          );
        }, 100);
      }
    } else {
      setTimeout(() => {
        this.setState({
          error: true,
          errorDescription: 'Please enter valid email address.',
        });
      }, 500);
    }
  };
  checkEmailAreadyInList = email => {
    const {emailAddresses} = this.state;
    const found = emailAddresses.find(
      res => res.email && email && res.email.toLowerCase() === email.toLowerCase(),
    );
    if (found) {
      return true;
    }
    return false;
  };

  changePermission = (value, index) => {
    const obj = this.state.emailAddresses[index];
    obj.permission = value;
    const emailAddresses = [...this.state.emailAddresses];
    emailAddresses[index] = obj;
    this.setState({emailAddresses: emailAddresses});
  };
  remove = email => {
    const emailAddresses = [...this.state.emailAddresses];
    this.setState(
      {
        emailAddresses: emailAddresses.filter(res => res.email !== email),
      },
      () => {
        if (this.textInput) {
          this.textInput.focus();
        }
      },
    );
  };

  onSharedAccessInvitation = () => {
    if (this.state.email && this.state.email.trim() !== '') {
      this.addEmailToList();
    } else if (this.state.emailAddresses.length > 0) {
      this.setState({wait: true, disabledInvite: true});
      this.props.sharedAccessInvitation(
        this.state,
        (success, res) => {
          if (success) {
            let existingEmails = [];
            res.filter(res => {
              if (res.existingEmail) {
                existingEmails.push(res.emailAddress);
              }
            });
            if (existingEmails && existingEmails.length > 0) {
              this.props.commonErrorHandler(
                existingEmails.length === 1
                  ? 'Already in caregivers list: ' + existingEmails.toString()
                  : 'Some emails already in caregivers list',
              );
            }
            setTimeout(() => {
              this.setState({wait: false, disabledInvite: false});
              this.props.navigation.push(CAREGIVERS.path);
            }, 1500);
          } else {
            this.setState({wait: false, disabledInvite: false});
          }
        },
        this.props.journalUserId,
      );
    } else {
      setTimeout(() => {
        this.setState(
          {
            email: '',
            error: true,
            errorDescription: 'Please enter Email address.',
          },
          () => {
            if (this.textInput) {
              this.textInput.focus();
            }
          },
        );
      }, 500);
    }
  };
  handleCancel = () => {
    this.setState({confirmBox: false});
    setTimeout(() => {
      this.props.navigation.push(CAREGIVERS.path);
    }, 300);
  };

  redirectCaregiverAccess = () => {
    if (JSON.stringify(this.state) === JSON.stringify(initialValue)) {
      this.props.navigation.push(CAREGIVERS.path);
    } else {
      this.setState({confirmBox: true});
    }
  };

  getSaveChanges = () => {
    let flag = null;
    if (JSON.stringify(this.state) === JSON.stringify(initialValue)) {
      flag = false;
    } else {
      flag = true;
    }
    return flag;
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <View style={styles.pageBodyWrapper}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => {
            this.redirectCaregiverAccess();
          }}
          index={CAREGIVERS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        {/* <View style={[stylesRes.sectionWrapper, stylesRes.fixedScreen]}> */}
        <SpaceBar />
        {this.getSaveChanges() ? (
          <MenuOverlayContainer
            onPressOpen={() => {
              this.redirectCaregiverAccess();
            }}
          />
        ) : null}
        <ScrollView
          style={styles.scrollViewArea}
          keyboardShouldPersistTaps={'handled'}>
          <View style={styles.gridSection}>
            <View
              style={[stylesRes.contentSectionMax, stylesRes.layoutBotPdFixed]}
              dataSet={{media: ids.contentSectionMax}}>
              <View style={stylesRes.contBotPd} dataSet={{media: ids.contBotPd}}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.secArea}>
                      <View style={styles.colPdTopLtRt}>
                        <View style={stylesRes.maxComSec} dataSet={{media: ids.maxComSec}}>
                          <View style={[styles.comTextSec, styles.comSpLg]}>
                            <Text style={styles.textPrimeSm}>
                                With caregivers, you remain in full control of your data. A caregiver can access your entries, but your data always remains with you.
                              <Text style={styles.bold} allowFontScaling={false}>
                                {' '}
                                Full access
                              </Text>{' '}
                              allows a caregiver to read entries and create entries for you.
                              <Text style={styles.bold} allowFontScaling={false}>
                                {' '}
                                Read Only
                              </Text>{' '}
                              will only allow a caregiver to read entries.
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.secArea}>
                      <View style={styles.colPdLtRt}>
                        <View style={styles.secSubArea}>
                          <View style={styles.fieldRow}>
                            <TouchableOpacity
                              style={[
                                styles.fullEmailWrap,
                                styles.emailSendWrapperFull,
                              ]}
                              onPress={() => {
                                if (this.textInput) {
                                  this.textInput.focus();
                                }
                              }}>
                              <View
                                style={[
                                  styles.listEmailAll,
                                  styles.listEmailAllHeight,
                                  styles.flexRow,
                                ]}>
                                <View style={styles.listEmailTextSec}>
                                  <Text style={styles.textView} allowFontScaling={false}>
                                    <UIText
                                      style={[
                                        styles.textLabel,
                                        styles.bold,
                                        styles.textColorLight,
                                      ]}
                                      title="Email:"
                                    />
                                  </Text>
                                </View>
                                <View style={styles.listEmailScrollWrap}>
                                  {this.state.emailAddresses.map(
                                    (res, index) => (
                                      <View
                                        style={styles.listEmailSingle}
                                        key={'emai_invite+' + index}>
                                        <View style={styles.listEmailName}>
                                          <Text style={styles.textPrimeSm} allowFontScaling={false}>
                                            {res.email}
                                          </Text>
                                        </View>
                                        <View
                                          style={[
                                            styles.smCloseIconView,
                                            styles.iconLtSp,
                                          ]}>
                                          <TouchableOpacity
                                            onPress={() =>
                                              this.remove(res.email)
                                            }>
                                            <Image
                                              source={require('../../assets/icons/color-select.png')}
                                              style={styles.colorSelectIcon}
                                              resizeMode={'contain'}
                                            />
                                          </TouchableOpacity>
                                        </View>
                                      </View>
                                    ),
                                  )}

                                  <View style={styles.textFieldEmailView}>
                                    <TextInput
                                      style={[
                                        styles.textFieldEmailSm,
                                        {zIndex: 999},
                                      ]}
                                      allowFontScaling={false}
                                      onChangeText={this.onChangeEmailText}
                                      value={this.state.email}
                                      onKeyPress={this.onKeyPress}
                                      onBlur={this.onBlur}
                                      underlineColorAndroid="transparent"
                                      ref={input => {
                                        this.textInput = input;
                                      }}
                                      keyboardType="email-address"
                                      textContentType="emailAddress"
                                    />
                                  </View>
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>

                          <View style={styles.fieldRow}>
                            <TextInput
                              style={[
                                styles.textField,
                                styles.textFieldSm,
                                styles.textAreaMax,
                              ]}
                              allowFontScaling={false}
                              editable={true}
                              multiline={true}
                              numberOfLines={3}
                              onChangeText={this.onChangeNoteText}
                              value={this.state.note}
                              underlineColorAndroid="transparent"
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={[styles.gridColFull, styles.errBoxView]}>
                    {Boolean(this.state.error) && (
                      <Warning errorDescription={this.state.errorDescription} />
                    )}
                  </View>
                </View>
                {this.state.emailAddresses.length > 0 && (
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={styles.secArea}>
                        <View
                          style={[
                            styles.secSubArea,
                            styles.comSp,
                            styles.comMgTopExLg,
                          ]}>
                          <View style={styles.secSubTitleArea}>
                            <View style={styles.colPdLtRtLg}>
                              <View
                                style={[
                                  styles.secSubTitleView,
                                  styles.secSubTitleRight,
                                ]}>
                                <TouchableOpacity
                                  style={[
                                    styles.toolTipIconLink,
                                    styles.toolTipMgMd,
                                  ]}
                                  onPress={() => this.props.onShowTutorial(4)}>
                                  <Image
                                    source={require('../../assets/icons/question.png')}
                                    style={styles.questionIcon}
                                    resizeMode={'contain'}
                                  />
                                </TouchableOpacity>
                                <Text style={[styles.textPrimeSmBold]} allowFontScaling={false}>
                                  Permissions
                                </Text>
                              </View>
                            </View>
                          </View>

                          <View style={styles.secSubContentArea}>
                            {this.state.emailAddresses.map((res, index) => (
                              <View
                                style={[styles.ViewRow, styles.greyBorderThin]}
                                key={'email_list_' + index}>
                                <View style={styles.viewDetailSec}>
                                  <View style={styles.viewMainData}>
                                    <View
                                      style={[
                                        styles.viewItemDesc,
                                        styles.colPdLtTopBotLg,
                                      ]}>
                                      <View style={styles.viewDataFull}>
                                        <Text
                                          style={styles.textPrime}
                                          allowFontScaling={false}
                                          numberOfLines={1}>
                                          {res.email}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                                <View
                                  style={[
                                    styles.dataIconSecAccess,
                                    styles.colPdRtTopBotLg,
                                  ]}>
                                  <View style={styles.iconListSecCom}>
                                    <View
                                      style={[
                                        styles.fieldInSec,
                                        styles.iconSettingMgLt,
                                      ]}>
                                      <View style={styles.pickerAreaWrapper}>
                                        <View
                                          style={[
                                            stylesRes.dropArrowView,
                                            stylesRes.dropArrowCent,
                                          ]}
                                          pointerEvents={'none'}>
                                          <View style={stylesRes.dropArrowIcon}>
                                            <Image
                                              style={styles.imgContain}
                                              source={require('../../assets/icons/dropdown-arrow.png')}
                                            />
                                          </View>
                                        </View>
                                        <Picker
                                          items={[
                                            {
                                              label: 'Read Only',
                                              value: 'READ_ONLY',
                                              key: 'READ_ONLY',
                                            },
                                            {
                                              label: 'Full Access',
                                              value: 'READ_WRITE',
                                              key: 'READ_WRITE',
                                            },
                                          ]}
                                          style={[
                                            styles.pickerAreaField,
                                            styles.textPickerPdMiniCom,
                                            styles.dataAccessMinWidth,
                                          ]}
                                          selectedValue={res.permission}
                                          onValueChange={value => {
                                            this.changePermission(value, index);
                                          }}
                                        />
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            ))}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                )}

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.btnWrapper, styles.btnRowMgLg]}>
                      <View style={[styles.btnRow, styles.btnRowMgBot]}>
                        <View style={styles.btnViewArea}>
                          <View style={stylesRes.btnSingleSecFull}>
                            <TouchableOpacity
                              style={styles.submitMd}
                              underlayColor="rgba(0, 153, 168, 0.8)"
                              onPress={this.onSharedAccessInvitation}
                              disabled={this.state.wait || !this.getSaveChanges}>
                              <View style={styles.btnSingleView}>
                                <View
                                  style={[
                                    styles.btnSingle,
                                    styles.btnSingleCent,
                                  ]}>
                                  {this.state.wait && (
                                    <View
                                      style={[
                                        styles.actIndCom,
                                        styles.indLeftMd,
                                      ]}>
                                      <ActivityIndicator size="small" />
                                    </View>
                                  )}
                                  <UIText
                                    style={[
                                      styles.submitBtnTextWhite,
                                      styles.bold]}
                                    title="Invite caregiver(s)"
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>

                      <View style={styles.btnRow}>
                        <View style={styles.btnViewArea}>
                          <View style={stylesRes.btnSingleSecFull}>
                            <CancelButton 
                              touchableOnpress={this.redirectCaregiverAccess}
                              disabled={this.state.wait}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.secArea}>
                      <View style={styles.colPdLtRt}>
                        <View style={stylesRes.maxComSec} dataSet={{media: ids.maxComSec}}>
                          <View style={styles.contColFul}>
                            <View
                              style={[
                                styles.contComText,
                                styles.flexRowJcCtAiCt,
                              ]}>
                              <Text
                                style={[
                                  styles.textPrime,
                                  styles.textAlignCenter,
                                ]}
                                allowFontScaling={false}
                                >
                                Your privacy is our priority.
                              </Text>
                              <TouchableOpacity
                                style={styles.mgLtExSm}
                                onPress={() =>
                                  this.setState({
                                    showLearnHowPrivacyPolicy: true,
                                  })
                                }>
                                <Text
                                  style={[
                                    styles.textPrimeBold,
                                    styles.textOrangeLight,
                                    styles.textAlignCenter,
                                  ]}
                                  allowFontScaling={false}
                                  >
                                  Learn How
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        {this.state.confirmBox &&
        <SavingConfirmation
          visibleModal={this.state.confirmBox}
          onBackdropPress={() => {
            this.setState({confirmBox: false});
          }}
          onRequestClose={() => {
            this.setState({confirmBox: false});
          }}
          alertBody={alertBodySpec}
          alertTitle={caregiverTitle}
          handleCancel={() => this.handleCancel()}
          handleConfirm={() => {
            this.setState({confirmBox: false});
            this.onSharedAccessInvitation();
          }}
          confirmWait={this.state.wait}
          disabled={this.state.wait}
        />
        }
        {this.state.showLearnHowPrivacyPolicy &&
          <LearnHowPrivacyPolicy
            showLearnHowPrivacyPolicy={this.state.showLearnHowPrivacyPolicy}
            toggleLearnHowPrivacyPolicy={() => {
              this.setState({showLearnHowPrivacyPolicy: false});
            }}
          />
        }
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    sharedAccessDigest: state.routines.sharedAccessDigest,
    sharedAccessDigestProcessed: state.routines.sharedAccessDigestProcessed,
    journalUserId: state.uistate.journalUserId,
    emailVerified: state.auth.emailVerified,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    sharedAccessInvitation: (data, callBack, journalUserId) =>
      dispatch(actions.sharedAccessInvitation(data, callBack, journalUserId)),
    onShowTutorial: tutorialType =>
      dispatch(actions.showTutorial(tutorialType)),
    toggleLearnHow: () => dispatch(actions.showLearnHow()),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteCaregiverScreen);
