import React from "react";
import { View, Text, Platform } from "react-native";
import styles from "../../Css";
import UIText from "../../components/Text/text";
import * as FontScaling from '../../components/Text/fontScaling';
import { WEB } from "../../util/platformWindowConfig";

const fieldTitle = (props) => {
  return (
    <View
      style={[
        styles.fieldNameWrapper,
        props.fieldAlign === "center"
          ? styles.fieldNameCenter
          : styles.fieldNameLeft,
      ]}
    >
      <View style={[styles.fieldNameContent, styles.postRelative]}>
        <View
          style={[
            styles.fieldNameBorder,
            props.fieldBg === "grey"
              ? styles.bgThinGrey
              : props.fieldBg === "blue"
              ? styles.bgBlueExLight
              : props.fieldBg === "transparent"
              ? styles.bgTransparent
              : styles.bgWhite,
          ]}
        ></View>
        <View style={styles.fieldName}>
          <Text
            style={[
              props.windowWidth < 360 && props.reminderSection
                ? styles.textExMicSmBold
                : styles.textPrimeExSmBold,
              props.fieldSm || props.fieldLt
                ? styles.textColorLight
                : props.fieldRg
                ? styles.textColorGreyLight
                : styles.textMedBlack,
              props.conditionTitle
                ? styles.conditionTitleTxt
                : styles.textColorGreyLight,
              props.darkTxt && styles.txtGreyHighlightCr,
                
            ]}
            allowFontScaling = {FontScaling.allowFontScaleFalse}
          >
            {props.title}
            {props.requirdStar && Platform.OS === WEB && <>{"   "}</>}
            {Boolean(props.requirdStar) && (
              <UIText
                style={[styles.textReq, styles.textReqFrame]}
                title={" * "}
              />
            )}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default fieldTitle;
