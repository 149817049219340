import { StyleSheet } from "react-native";
import { cssCommon } from "./css/common";
import { cssImage } from "./css/image";
import { cssText } from "./css/text";
import { cssForm } from "./css/form";
import { cssLayout } from "./css/layout";
import { cssSpacing } from "./css/spacing";
import { cssBackground } from "./css/background";
import { cssBorder } from "./css/border";
import { cssModal } from "./css/modal";
import { cssDefault } from "./css/default";
import { cssOcr } from "./css/ocr";
import { cssPage } from "./css/page";
import { cssMobApp } from "./css/mobApp";
import { cssCommunity } from "./css/community";
import { cssAutoComplete } from "./css/autoComplete";
import { cssGrid } from "./css/grid";
import { cssProfile } from "./css/profile";
import { cssMedication } from "./css/medication";
import { cssDatePickerLayout } from "./css/datePickerLayout";
import { cssJournal } from "./css/journal";
import { cssQuickNote } from "./css/quickNote";
import { cssRoutine } from "./css/routine";
import { cssSub } from "./css/sub";
import { cssOutlook } from "./css/outlook";
import { cssMenu } from "./css/menu";
import { cssPeerToPeer } from "./css/peertopeer";
import { cssSlider } from "./css/slider";
import { cssuploadAttachment } from "./css/uploadAttachment";
import { csssurveyCommonStyles } from "./css/surveyCommonStyles";
import {csshealthjourney} from "./css/healthjourney";
import { csstimeZoneField } from "./css/timeZoneFiels";
import { cssDashboard } from "./css/dashboardStyles";
import { cssPeerProfile } from "./css/peerProfile";

export default StyleSheet.create({
  ...cssCommon,
  ...cssImage,
  ...cssText,
  ...cssForm,
  ...cssLayout,
  ...cssSpacing,
  ...cssBackground,
  ...cssBorder,
  ...cssModal,
  ...cssDefault,
  ...cssOcr,
  ...cssPage,
  ...cssMobApp,
  ...cssAutoComplete,
  ...cssCommunity,
  ...cssGrid,
  ...cssProfile,
  ...cssMedication,
  ...cssDatePickerLayout,
  ...cssJournal,
  ...cssQuickNote,
  ...cssRoutine,
  ...cssSub,
  ...cssOutlook,
  ...cssMenu,
  ...cssPeerToPeer,
  ...cssSlider,
  ...cssuploadAttachment,
  ...csssurveyCommonStyles,
  ...csshealthjourney,
  ...csstimeZoneField,
  ...cssDashboard,
  ...cssPeerProfile,
});