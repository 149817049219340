import React from 'react';
import {View, Text} from 'react-native';
import styles from '../../Css';

const warningInline = props => {
  return (
    <View style={styles.notifyBlock}>
      <View
        style={[
          styles.warningInline,
          props.textPosition === 'left' 
          ? styles.flexJcFs
          : props.textPosition === 'right' 
          ? styles.flexJcFe
          : styles.flexJcCt
        ]}>
        <Text
          style={[styles.textPrimeExSmBold, styles.textRed]}
          allowFontScaling={false}
        >* {props.errorDescription}</Text>
      </View>
    </View>
  );
};

export default (warningInline);
