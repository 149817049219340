import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import styles from "../../Css";
import * as actions from "../../store/actions/index";
import Picker from "../Picker/picker";
import PickerTemplate from "../Picker/pickerTemplate";
import _ from 'underscore';

class ReportSelectGraph extends Component {
  constructor(props) {
    super(props);
    const filteredDashboardProcesseds = Object.values(props.dashboardProcessed).filter(graph => graph.status === 'ENABLED');
    this.state = {
      dashboard: props.dashboard.filter(graph => graph.status === 'ENABLED'),
      graphId: props.graph && props.graph.id
        ? parseInt(props.graph.id)
        : filteredDashboardProcesseds[0]
        ? filteredDashboardProcesseds[0].id
        : null,
      // graphName: this.props.graph
      //   ? this.props.graph.name
      //   : props.dashboardProcessed[0]
      //   ? props.dashboardProcessed[0].name
      //   : "",
      //terms: this.getTerms(),
    };
  }

  componentDidMount() {
    const filteredDashboards = this.props.dashboard.filter(graph => graph.status === 'ENABLED');
    if((_.isEmpty(this.props.graph) || this.props.graph.name === null) && filteredDashboards[0]) {
      this.onChangeGraph(filteredDashboards[0].id);
    }
  }

  // getTerms = () => {
  //   let termsArray = [];
  //   if (this.props.dashboard) {
  //     const suggestionResults = [...this.props.dashboard];
  //     termsArray = suggestionResults.map((value) => {
  //       return { name: value.name, data: value };
  //     });
  //   }

  //   return termsArray;
  // };

  onChangeGraph = (id) => {
    if (id !== "" && id != this.props.savedGraph.id) {
      const selectedGraph = this.props.dashboardProcessed[id];
      const graph = {
        id: id,
        name: selectedGraph.name,
        symptoms: this.getObject(selectedGraph.symptoms),
        metrics: this.getObject(selectedGraph.metrics),
        therapies: this.getObject(selectedGraph.treatments),
        symptomProps: this.getPropsObject(selectedGraph.symptomsProperties),
        metricProps: this.getPropsObject(selectedGraph.metricsProperties),
        therapyProps: this.getPropsObject(selectedGraph.treatmentsProperties)
      };
      this.setState({ 
        graphId: id, 
        //graphName: selectedGraph.name 
      }, () =>
        this.props.selectReportGraph(graph)
      );
    } else {
      const selectedGraph = this.props.savedGraph;
      const graph = {
        id: id !== "" ? id : null,
        name: selectedGraph.name,
        symptoms: selectedGraph.symptoms,
        metrics: selectedGraph.metrics,
        therapies: selectedGraph.therapies,
        symptomProps: selectedGraph.symptomProps,
        metricProps: selectedGraph.metricProps,
        therapyProps: selectedGraph.therapyProps
      };
      this.setState({ 
        graphId: id !== "" ? id : null, 
        //graphName: selectedGraph.name 
      }, () =>
        this.props.selectReportGraph(graph)
      );
    }
  };

  getObject = (data) => {
    const obj = {};
    if (data) {
      const array = data.split(",");
      array.forEach((element) => {
        obj[element] = element;
      });
    }
    return obj;
  };

  getPropsObject = data => {
    const propsObj = {};
    if (data) {
      const propsArray = JSON.parse(data);
      Object.values(propsArray).forEach(element => {
        propsObj[element.id] = {id: element.id, style: element.style}
      });
    }
    return propsObj
  }
  
  // onItemPress = (selection) => {
  //   let selectedGraph = selection.data;
  //   const graph = {
  //     id: selectedGraph.id,
  //     name: selectedGraph.name,
  //     symptoms: this.getObject(selectedGraph.symptoms),
  //     metrics: this.getObject(selectedGraph.metrics),
  //     therapies: this.getObject(selectedGraph.treatments),
  //   };
  //   this.setState(
  //     { graphId: selectedGraph.id, graphName: selectedGraph.name },
  //     () => this.props.selectReportGraph(graph)
  //   );
  // };

  // onAddNewPress = (selection) => {
  //   const graph = {
  //     id: null,
  //     name: selection,
  //     symptoms: this.getObject(null),
  //     metrics: this.getObject(null),
  //     therapies: this.getObject(null),
  //   };
  //   this.setState({ graphId: null, graphName: selection }, () =>
  //     this.props.selectReportGraph(graph)
  //   );
  // };

  getGraphNames = () => {
    let graphNameList = [];
    //add previously added custom graph name
    if (this.props.savedGraph.id === null && this.props.savedGraph.name) {
      graphNameList.push({
        label: this.props.savedGraph.name,
        value: "",
        key: 0,
      })
    } else if (!_.isEmpty(this.props.savedGraph) && !this.props.dashboardProcessed.hasOwnProperty(this.props.savedGraph.id)) {
      graphNameList.push({
        label: this.props.savedGraph.name,
        value: parseInt(this.props.savedGraph.id),
        key: 0,
      });
    }
    this.state.dashboard &&
      this.state.dashboard.map((res) =>
        graphNameList.push({
          label: res.name,
          value: res.id,
          key: res.id,
        })
      );
    return graphNameList;
  };

  render() {
    return (
      <View style={[styles.gridRow, styles.zIndexAutoComplete]}>
        <View style={styles.gridColFull}>
          <View style={[styles.secArea, styles.colPdLtRtBot]}>
            <PickerTemplate
              invalid={false}
              pickerWithTitle={true}
              pickerTitle={"Select a Graph"}
            >
              <Picker
                items={this.getGraphNames()}
                style={[styles.pickerAreaField, styles.selectionPicker]}
                selectedValue={this.state.graphId}
                onValueChange={this.onChangeGraph}
                keepExistingPickerBg={true}
              />
            </PickerTemplate>
            {/* <AutoComplete
              textFieldWithTitle={true}
              textFieldTitle="Select a Preset"
              remortSearch={false}
              triggerLength={1}
              onChangeText={value => {
                this.setState({ graphName: value });
              }}
              onItemPress={this.onItemPress}
              currentInput={this.state.graphName}
              onPressCustom={this.onAddNewPress}
              customSectionTitle={"Add new Preset"}
              showCustomSection={true}
              autoFocus={true}
              terms={this.state.terms}
              zoomIcon={true}
            />  */}
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.routines.dashboard,
    dashboardProcessed: state.routines.dashboardProcessed,
    graph: state.report.graph,
    savedGraph: state.report.savedGraph,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectReportGraph: (graph) => dispatch(actions.selectReportGraph(graph)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportSelectGraph);
