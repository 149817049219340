import React, {Component} from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import styles from '../../Css';
import {connect} from 'react-redux';
import ColorPicker from './ColorPicker';
import ColorPickerPopover from './ColorPickerPopover';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import ColorPickerScroll from './ColorPickerScroll';

class ColorItemField extends Component {
  constructor(props) {
    super(props);
    const {windowHeight, windowWidth} = props;
    this.state = {
      background: props.color ? props.color : '#fff',
      showColorPicker: false,
      anchorEl: null,
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      wait: false,
      changedPickerColor: false
    };
  }

  handleChangeComplete = color => {
    this.setState({background: color.hex});
  };

  handleClose = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker,
      anchorEl: null,
    });
  };

  onPressSaveColor = () => {
    this.setState({wait: true}, () => {
      this.props.handleChangeComplete(this.state.background);
      this.setState({
        showColorPicker: !this.state.showColorPicker,
        anchorEl: null,
        wait: false,
      });
    });
  };

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth, changedPickerColor, color} = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth ||
      state.changedPickerColor !== changedPickerColor
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        background: color,
        changedPickerColor: changedPickerColor
      };
    }

    return null;
  }

  render() {
    let fullHeight = this.state.windowHeight;
    const {background, wait, changedPickerColor} = this.state;
    const {color} = this.props;
    return (
      <>
        <View style={[styles.flexAiFe]}>
          <View
            style={[
              this.props.fieldBg === 'grey'
                ? styles.bgThinGrey
                : this.props.fieldBg === 'blue'
                ? styles.bgBlueExLight
                : this.props.fieldBg === 'transparent'
                ? styles.bgTransparent
                : styles.bgWhite,
              styles.textInputField,
              styles.colorPickerSelection,
              styles.flexAiFs,
              styles.flexJcCt,
              this.props.disabled
              ? styles.btnBlurState
              : styles.btnActiveState
            ]}>
            <TouchableOpacity
              onPress={e => {
                e.preventDefault();
                this.setState({
                  anchorEl: !this.state.showColorPicker
                    ? e.currentTarget
                    : null,
                  // showColorPicker: !this.state.showColorPicker,
                  showColorPicker: true,
                });
              }}
              style={[
                styles.colorPickerTouch,
                styles.flexJcCt,
                styles.postRelative,
              ]}
              disabled={this.props.disabled}>
              <View
                style={[styles.colorPickerDetails, {backgroundColor : color}]}
              />
              <View style={[styles.colorPickerArrow]}>
                <Image
                  style={styles.colorPickerArrowIcon}
                  source={require('../../assets/icons/picker-arrow.png')}
                />
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <ColorPickerPopover
          open={this.state.showColorPicker}
          handleClose={this.handleClose}
          anchorEl={this.state.anchorEl}
          popoverId={"colorPicker"}>
            <ColorPickerScroll fullHeight={fullHeight}>
              <View style={[styles.popoverWrapper, styles.popoverWrapperPd]}>
                <ColorPicker
                  background={background}
                  handleChangeComplete={this.handleChangeComplete}
                />
                <View style={[styles.btnArea, styles.fixedBtnPd]}>
                  <View style={[styles.flexRow]}>
                    <View style={styles.fieldBtn}>
                      <View style={styles.btnBlockFull}>
                        <TouchableOpacity
                          style={[
                            styles.submitSubSm,
                            styles.btnShadowCom,
                            styles.submitBgAsh,
                          ]}
                          underlayColor="rgba(0, 153, 168, 0.8)"
                          onPress={() => {
                            this.setState({
                              showColorPicker: false,
                              changedPickerColor: !changedPickerColor
                            });
                          }}>
                          <View style={styles.btnSingleView}>
                            <View
                              style={[styles.btnSingle, styles.btnSingleCent]}>
                              <Text
                                style={[
                                  styles.submitBtnTextWhite,
                                  styles.bold,
                                ]}
                                allowFontScaling={false}
                                >
                                Cancel
                              </Text>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View style={styles.fieldBtnSpSm} />
                    <View style={styles.fieldBtn}>
                      <View style={styles.btnBlockFull}>
                        <TouchableOpacity
                          style={[
                            styles.submitSubSm,
                            styles.btnShadowCom,
                            styles.submitBgOrange,
                          ]}
                          disabled={wait}
                          underlayColor="rgba(0, 153, 168, 0.8)"
                          onPress={this.onPressSaveColor}>
                          <View style={styles.btnSingleView}>
                            <View
                              style={[styles.btnSingle, styles.btnSingleCent]}>
                              {wait && (
                                <View
                                  style={[
                                    styles.indicatorIconView,
                                    styles.indicatorLtMini,
                                  ]}>
                                  <ActivityIndicator
                                    pdZero={true}
                                    size="small"
                                  />
                                </View>
                              )}
                              <Text
                                style={[
                                  styles.submitBtnTextWhite,
                                  styles.bold,
                                ]}
                                allowFontScaling={false}
                                >
                                Save
                              </Text>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
             </ColorPickerScroll>
        </ColorPickerPopover>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(
  mapStateToProps,
  null,
)(ColorItemField);
