import React, {Component} from 'react';
import {connect} from 'react-redux';
import Report from '../../components/Report/Report';
import {
  REPORTS,
  JOURNALS,
  CONTACTS,
  MEDICATIONS,
} from '../../navigation/path';
import * as actions from '../../store/actions/index';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { TAB_BREAk_POINT_END } from '../../util/platformWindowConfig';

class NewReportsScreen extends Component {

  componentDidMount= () => {
    pendoTrackEvent(this.props.route.name === REPORTS.newReportPath ? 'Reports/@AddReport' : 'Reports/@EditReport');
  }

  redirectReportAddIssue = () => {
    this.props.navigation.push(
      REPORTS.reportAddIssuePath, {
        from: this.props.route.name
      }
    );
  };

  redirectReportAddImage = () => {
    this.props.navigation.push(
      REPORTS.reportAddImagePath, {
        from: this.props.route.name
      }
    );
  };

  redirectReportAttachGraph = () => {
    this.props.navigation.push(
      REPORTS.reportAddGraphPath, {
        from: this.props.route.name
      }
    );
  };

  redirectReportAttachSymptom = () => {
    this.props.navigation.push(
      REPORTS.reportAddSymptomPath, {
        from: this.props.route.name
      }
    );
  };

  redirectReportAttachMedications = () => {
    this.props.navigation.push(
      REPORTS.reportAddMedicationsPath, {
        from: this.props.route.name
      }
    );
  };

  redirectPage = userArtifact => {
    let item;
    const {journalEntriesProcessed, contactsList, therapyList} = this.props;
    const journalList = Object.values(journalEntriesProcessed);
    if ((item = journalList.find(entry => entry.artifactId === userArtifact))) {
      if (!this.props.journalEntryTypesProcessed[item.entryType].isAppointment) {
        this.props.setJournalEntry(item);
        if (this.props.windowWidth < TAB_BREAk_POINT_END) {
          this.props.navigation.push(
            JOURNALS.viewJournalPath, {
              from: this.props.route.name,
              params: {journalName: item?.title}
            },
          );
        } else {
          this.props.navigation.push(JOURNALS.path, {
            from: this.props.route.name,
            params: {
              journalPath: JOURNALS.viewJournalPathSuffix,
              journalName: item?.title
            }
          });
        }
      }
    } else if (
      (item = contactsList.find(contact => contact.artifactId === userArtifact))
    ) {
      this.props.setContact(item, () =>
        this.props.navigation.push(
          CONTACTS.viewContactPath, {
            from: this.props.route.name
          }
        ),
      );
    } else if (
      (item = therapyList.find(therapy => therapy.artifactId === userArtifact))
    ) {
      this.props.setTherapy(item, () =>
        this.props.navigation.push(
          MEDICATIONS.viewTherapiesPath, {
            from: this.props.route.name
          },
        ),
      );
    }
  };

  redirectReportList = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ 
        name: REPORTS.path,
        params: {from: this.props.route.name}
      }],
    });
  };

  redirectViewReport = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: REPORTS.viewReportPath }],
    });
  };

  render() {
    return (
      <Report
        redirectReportAddIssue={this.redirectReportAddIssue}
        redirectReportAddImage={this.redirectReportAddImage}
        redirectReportAttachGraph={this.redirectReportAttachGraph}
        redirectPage={this.redirectPage}
        redirectReportAttachMedications={
          this.redirectReportAttachMedications
        }
        redirectReportAttachSymptom={this.redirectReportAttachSymptom}
        redirectReportList={this.redirectReportList}
        redirectViewReport={this.redirectViewReport}
        index={REPORTS.index}
        navigation={this.props.navigation}
        route={this.props.route}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    therapyList: state.routines.treatments,
    contactsList: state.routines.contacts,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    windowWidth: state.uistate.windowWidth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setJournalEntry: selectedId =>
      dispatch(actions.setSelectedJournalEntry(selectedId)),
    setTherapy: (SelectedTherapie, callBack) =>
      dispatch(actions.selectTherapie(SelectedTherapie, callBack)),
    setContact: (selectedContact, callBack) =>
      dispatch(actions.selectedContact(selectedContact, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewReportsScreen);
