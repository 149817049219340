import React, { Component } from "react";
import { TextInput, Image, View } from "react-native";
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
class SearchMyEvent extends Component {
  render(){
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <React.Fragment>
        <View style={[styles.barTopSp, stylesRes.pdMbTwentyDecZero]} dataSet={{media: ids.pdMbTwentyDecZero}}>
          <View style={styles.searchEventsIcnPst}>
            <Image
            style={styles.searchEventIcn}
            source={require('../../../assets/health-journey-icons/search-icon.png')}
            />
          </View>
          <View>
            <TextInput
            style={[
                styles.textField,
                styles.textFieldLg,
                styles.searchEvenBr
            ]}
            
            placeholder="Search My Events"
            placeholderTextColor={"#C4C4C4"}
            />
          </View>
        </View>
      </React.Fragment>
    );
  }
};

export default SearchMyEvent;
