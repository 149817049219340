import React from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import NoGraphData from "../../Dashboard/GraphSection/noGraphData";
import GraphCommon from "../../GraphCommon/GraphCommon";
import styles from "../../../Css";
import { getGraphHashValue } from "../../../util/commonUiLogic";

class Graph extends GraphCommon {
  constructor(props) {
    super(props);
    const {
      therapies,
      metrics,
      symptoms,
      startDate,
      endDate,
      symptomProps,
      metricProps,
      therapyProps,
      refreshGraph,
    } = props;
    const hash = getGraphHashValue(therapies, metrics, symptoms, startDate, endDate, therapyProps, metricProps, symptomProps);
    this.state = {
      hash: hash,
      refreshGraph,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      symptoms,
      metrics,
      therapies,
      startDate,
      endDate,
      symptomProps,
      metricProps,
      therapyProps,
      refreshGraph,
      isFromAddEditGraph
    } = props;
    const changedHashValue = getGraphHashValue(therapies, metrics, symptoms, startDate, endDate, therapyProps, metricProps, symptomProps);

    if ((state.hash !== changedHashValue || state.refreshGraph !== refreshGraph) && state.didMount) {
      const chartData = Graph.drawGraph(
        symptoms,
        metrics,
        therapies,
        startDate,
        endDate,
        props,
        isFromAddEditGraph
      );
      return {
        therapies: therapies,
        metrics: metrics,
        symptoms: symptoms,
        hash: changedHashValue,
        startDate: startDate,
        endDate: endDate,
        symptomProps: symptomProps,
        metricProps: metricProps,
        therapyProps: therapyProps,
        refreshGraph,
        chartData: chartData
      };
    }

    return null;
  }

  render() {
    const { chartData } = this.state;
    const { graphHeight } = this.props;
    return (
      <View
        style={[
          styles.graphAreaWrapper,
          {
            height: graphHeight ? graphHeight : "330px",
            borderColor: "rgba(152, 152, 154, 0.4)",
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 4,
            padding: 1,
          },
        ]}
      >
        <div
          id={this.props.graphDivId}
          style={{
            width: "100%",
            height: graphHeight ? graphHeight - 2 : "328px",
          }}
        />
        {(!Boolean(chartData) || Boolean(chartData && chartData.length === 0)) &&
          <View
            style={[
              styles.noGraphWrapper,
              styles.flexJcCtAiCt,
              {
                height: graphHeight ? graphHeight - 2 : "351px",
                borderRadius: 4,
              },
            ]}
          >
            <NoGraphData initGraph={!this.hasSelectedData()} />
          </View>
        }
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    symptomsDigestProcessed: state.routines.symptomsDigestProcessed,
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    userDefinedMetricUnitOfMeasures:
      state.routines.metricsDigest.userDefinedMetricUnitOfMeasures,
    systemDefinedMetricUnitOfMeasures:
      state.routines.metricsDigest.systemDefinedMetricUnitOfMeasures,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    refreshGraph: state.uistate.refreshGraph,
    temporyClors: state.rollinggraphs.temporyClors
  };
};

export default connect(mapStateToProps, null)(Graph);
