import React from 'react';
import {View, Text} from 'react-native';
import styles from '../../Css';
import TickButton from '../../components/CheckBox/CheckBox';
import PremiumButton from '../../components/Button/premiumButton';

const imageDocSearch = props => {
  return (
    <React.Fragment>
      <View style={styles.docSearch}>
        <View style={[styles.flexRow, styles.flexAiFs]}>
          <View style={[styles.tickButtonLg]}>
            <TickButton
              imagePath={!props.ticked ? 'untickButton' : 'tickButton'}
              toggleTickCallback={toggleVal => {
                props.onTicked(toggleVal);
              }}
              tickStyle={styles.tickStyleWrapperLg}
              disabled={
                (props.accountType && props.accountType === 'premium')
                  ? false
                  : true
              }
              tickBtnStyle={styles.tickIconTouch}
              isTicked={props.ticked}
              nativeId={props.nativeId}
            />
          </View>
          <View style={[styles.flexCom, 
            props.outlookView || props.windowWidth < 768
            ? styles.flexCol
            : styles.flexRowWrap
            ]}>
            <View style={[styles.tickBoxWrapper]}>
              <View
                style={[
                  styles.tickBoxContent,
                  (props.outlookView || props.windowWidth < 768) 
                  // &&
                  // props.journalUserId === null &&
                  // (!props.accountType ||
                  //   (props.accountType && props.accountType === 'regular'))
                    ? styles.tickBoxContentPdNone
                    : styles.tickBoxContentPd,
                ]}>
                <Text style={[
                  props.outlookView
                  ? styles.textPrimeExSm
                  : styles.textPrime,
                  (props.accountType && props.accountType === 'premium')
                      ? styles.textDark
                      : styles.textColorLight
                  ]}
                  // maxFontSizeMultiplier = {FontScaling.fontScaleRg}
                  allowFontScaling={false}
                  >
                  Search text inside images and documents
                </Text>
              </View>
              {(!Boolean(props.accountType) ||
                  (props.accountType && props.accountType === 'regular')) && (
                  <View style={
                    props.outlookView || props.windowWidth < 768
                    ? styles.premiumSecBtnAreaCom
                    : styles.premiumSecBtnArea
                    }>
                    <PremiumButton btnSpace={true} navigation={props.navigation} />
                  </View>
                )}
            </View>
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

export default imageDocSearch;
