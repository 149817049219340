import React from "react";
import { View, Image } from "react-native";
import Popover from "@material-ui/core/Popover";
import styles from "../../Css";
import { makeStyles } from "@material-ui/core/styles";
const popover = (props) => {
  let { open, handleClose, anchorEl } = props;

  return (
    <Popover
      classes={{
        root: useStyles.root,
        paper: useStyles.paper,
      }}
      id={props.popoverId}
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {props.arrowAvailable && (
        <View style={[styles.topArrowWrapper]}>
          <Image
            style={[styles.topArrow, styles.topArrowMg]}
            source={require("../../assets/icons/top-arrow.png")}
          />
        </View>
      )}
      {props.children}
    </Popover>
  );
};

export default (popover);

const useStyles = makeStyles({
  root: {},
  paper: {},
});
