import * as axiosCommon from "../../axios/axios-common";
import * as url from "./urls";
import * as actionTypes from "./actionTypes";
import { logout, commonErrorHandler } from "./index";

export const updateReportDetail = data => {
  return {
    type: actionTypes.UPDATE_REPORT_DETAIL,
    name: data.name,
    startDate: data.startDate,
    endDate: data.endDate,
    filteredRecordedSymptom: data.filteredRecordedSymptom,
    filteredRecordedDosing: data.filteredRecordedDosing,
    selectedIssues: data.selectedIssues,
    selectedIssuesObj: data.selectedIssuesObj,
    selectedImages: data.selectedImages,
    selectedImagesObj: data.selectedImagesObj,
    viewBy: data.viewBy,
    graphid: data.graphid
    // validName: data.validName
  };
};

export const removeReportSymptom = id => {
  return {
    type: actionTypes.REMOVE_REPORT_SYMPTOM,
    id: id
  };
};

export const removeReportDosing = id => {
  return {
    type: actionTypes.REMOVE_REPORT_DOSING,
    id: id
  };
};

export const addRemoveReportIssue = question => {
  return {
    type: actionTypes.ADD_REMOVE_REPORT_ISSUE,
    question: question
  };
};

export const addRemoveReportImage = attachment => {
  return {
    type: actionTypes.ADD_REMOVE_REPORT_IMAGE,
    attachment: attachment
  };
};

export const saveReportIssue = () => {
  return {
    type: actionTypes.SAVE_REPORT_ISSUE
  };
};

export const saveReportImage = () => {
  return {
    type: actionTypes.SAVE_REPORT_IMAGE
  };
};

export const removeReportIssue = id => {
  return {
    type: actionTypes.REMOVE_REPORT_ISSUE,
    id: id
  };
};

export const removeReportImage = id => {
  return {
    type: actionTypes.REMOVE_REPORT_IMAGE,
    id: id
  };
};

export const invalidReportName = () => {
  return {
    type: actionTypes.INVALID_REPORT_NAME
  };
};

export const updateImagesTitle = title => {
  return {
    type: actionTypes.UPDATE_REPORT_IMAGES_TITLE,
    title: title
  };
};

export const selectReportGraph = graph => {
  return {
    type: actionTypes.SELECT_REPORT_GRAPH,
    graph: graph
  };
};

export const removeReportGraph = () => {
  return {
    type: actionTypes.REMOVR_REPORT_GRAPH
  };
};

export const updateReportNote = note => {
  return {
    type: actionTypes.UPDATE_REPORT_NOTE,
    note: note
  };
};

export const resetReport = () => {
  return {
    type: actionTypes.RESET_REPORT
  };
};

export const selectReport = (id, data) => {
  return {
    type: actionTypes.SELECT_REPORT,
    id: id,
    data: data
  };
};

export const updateUserReport = (report, journalUserId, callBack) => {
  let requestUrl = url.UPDATE_USER_REPORT;
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          callBack(true, res.responseDto.data.report);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; updateUserReport");
            dispatch(logout());
          } else {
            callBack(false);
            dispatch(commonErrorHandler(res.errorDto.data.error));
          }
        }
      },
      report
    );
  };
};

export const getAllUserReport = (journalUserId, callBack) => {
  let requestUrl = url.GET_ALL_USER_REPORT;
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosGET(requestUrl, res => {
      if (res.type === "SUCCESS") {
        callBack(true, res.responseDto.data);
      } else {
        if (res.errorDto === "SESSION_TIME_OUT") {
          console.log("session time out; getAllUserReport");
          dispatch(logout());
        } else {
          callBack(false);
          dispatch(commonErrorHandler(res.errorDto.data.error));
        }
      }
    });
  };
};

export const deleteReport = (id, callBack, journalUserId) => {
  const entryId = {
    reportId: id
  };
  let requestUrl = url.DELETE_USER_REPORT;
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosDELETE(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          callBack(true);
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; deleteReport");
            dispatch(logout());
          } else {
            callBack(false);
            dispatch(commonErrorHandler(res.errorDto.data.error));
          }
        }
      },
      entryId
    );
  };
};

export const addReportGrapSymptom = symptom => {
  return {
    type: actionTypes.REPORT_GRAPH_ADD_SYMPTOM,
    symptom: symptom
  };
};

export const removeReportGrapSymptom = symptom => {
  return {
    type: actionTypes.REPORT_GRAPH_REMOVE_SYMPTOM,
    symptom: symptom
  };
};

export const addReportGrapMetric = metric => {
  return {
    type: actionTypes.REPORT_GRAPH_ADD_METRIC,
    metric: metric
  };
};

export const removeReportGrapMetric = metric => {
  return {
    type: actionTypes.REPORT_GRAPH_REMOVE_METRIC,
    metric: metric
  };
};

export const addReportGrapTherapy = therapy => {
  return {
    type: actionTypes.REPORT_GRAPH_ADD_THERAPY,
    therapy: therapy
  };
};

export const removeReportGrapTherapy = therapy => {
  return {
    type: actionTypes.REPORT_GRAPH_REMOVE_THERAPY,
    therapy: therapy
  };
};

export const saveReportGraph = () => {
  return {
    type: actionTypes.SAVE_REPORT_GRAPH
  };
};

export const setTabOpen = (type, open) => {
  return {
    type: actionTypes.SET_OPEN_TAB,
    tab: type,
    open: open
  };
};

export const setGraphImage = graphid => {
  return {
    type: actionTypes.SET_GRAPH_IMAGE,
    graphid: graphid
  };
};

export const saveReportSymptom = (addList, removeList) => {
  return {
    type: actionTypes.SAVE_REPORT_SYMPTOM,
    addList: addList,
    removeList: removeList
  };
};

export const saveReportMedicationsTherapie = (addList, removeList) => {
  return {
    type: actionTypes.SAVE_REPORT_MEDICATION_THERAPIE,
    addList: addList,
    removeList: removeList
  };
};

export const cancelGraph = () => {
  return {
    type: actionTypes.CANCEL_GRAPH
  };
}
export const setInitHashValue = (initHashVal) => {
  return {
    type: actionTypes.SET_INIT_HASH_VALUE,
    initHashValue: initHashVal
  };
}

export const updateReportGraphSymptomProps = (symptomId, chartType) => {
  return {
    type: actionTypes.REPORT_GRAPH_UPDATE_SYMPTOM_PROPS,
    symptomId: symptomId,
    symptomProps: {
      id: symptomId, 
      style: chartType
    },
  };
};

export const updateReportGraphMetricProps = (metricId, chartType) => {
  return {
    type: actionTypes.REPORT_GRAPH_UPDATE_METRIC_PROPS,
    metricId: metricId,
    metricProps: {
      id: metricId, 
      style: chartType
    },
  };
};

export const updateReportGraphTherapyProps = (therapyId, chartType) => {
  return {
    type: actionTypes.REPORT_GRAPH_UPDATE_THERAPY_PROPS,
    therapyId: therapyId,
    therapyProps: {
      id: therapyId, 
      style: chartType
    },
  };
};