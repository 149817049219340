import * as axiosCommon from '../../axios/axios-common';
import * as url from './urls';
import * as actionTypes from './actionTypes';
import {logout, updateUserMetrics, updateUserMetricUOM, setDeviceMap, updateContacts, updateTreatments, updateNewSymptom} from './index';
import {Auth} from 'aws-amplify';
import {setEmailVerified} from './auth';
import {nullableStringCompare} from "../../util/stringManipulations";

// Create a journal entry based on the given journal entry type.
export const createJournalEntryByType = (journalEntry, journalUserId) => {
  let requestUrl = url.CREATE_JOURNAL_ENTRY_BY_TYPE;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(createJournalEntryByTypeSuccess(res.responseDto.data));
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; createJournalEntryByType");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            dispatch(createJournalEntryByTypeFailed(res.errorDto.data.error));
          }
        }
      },
      journalEntry,
    );
  };
};

export const createJournalEntryByTypeSuccess = data => {
  return {
    type: actionTypes.CREATE_JOURNAL_ENTRY_BY_TYPE_SUCCESS,
    successMsg: data.message,
  };
};

export const createJournalEntryByTypeFailed = errorMsg => {
  return {
    type: actionTypes.CREATE_JOURNAL_ENTRY_BY_TYPE_FAIL,
    errMessage: errorMsg,
  };
};

// Delete a journal entry
export const deleteJournal = (
  journalEntryId,
  journalEntriesProcessed,
  callBack,
  journalUserId,
) => {
  let del = {
    journalEntryId: journalEntryId,
  };
  let requestUrl = url.DELETE_JOURNAL_ENTRY;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    dispatch(deleteJournalStart());
    axiosCommon.axiosDELETE(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          let deletedJournal = journalEntriesProcessed.hasOwnProperty(journalEntryId) ? journalEntriesProcessed[journalEntryId] : null;
          if (deletedJournal) {
            let artifactId = deletedJournal.artifactId
              ? deletedJournal.artifactId
              : null;
            let symptoms = deletedJournal.symptoms
              ? deletedJournal.symptoms
              : [];
            dispatch(
              deleteJournalSuccess(journalEntryId, artifactId, symptoms),
            );
          }

          if (callBack) {
            callBack(true);
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; deleteJournal");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            dispatch(deleteJournalFail(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      del,
    );
  };
};

export const deleteJournalSuccess = (
  journalEntryId,
  artifactId,
  symptoms
) => {
  return {
    type: actionTypes.DELETE_JOURNAL_ENTRY_SUCCESS,
    journalEntryId: journalEntryId,
    artifactId: artifactId,
    symptoms: symptoms
  };
};

export const deleteJournalFail = errorMsg => {
  return {
    type: actionTypes.DELETE_JOURNAL_ENTRY_FAIL,
    errorDescription: errorMsg,
  };
};

export const deleteJournalStart = () => {
  return {
    type: actionTypes.DELETE_JOURNAL_ENTRY_START,
  };
};

// Save a journal entry
export const updateJournalEntry = (journalEntry, journalUserId) => {
  let requestUrl = url.UPDATE_JOURNAL_ENTRY;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(updateJournalEntrySuccess(res.responseDto.data));
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateJournalEntry");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            dispatch(updateJournalEntryFailed(res.errorDto.data.error));
          }
        }
      },
      journalEntry,
    );
  };
};

export const updateJournalEntrySuccess = data => {
  return {
    type: actionTypes.UPDATE_JOURNAL_ENTRY_SUCCESS,
    successMsg: data.message,
  };
};

export const updateJournalEntryFailed = errorMsg => {
  return {
    type: actionTypes.CREATE_JOURNAL_ENTRY_BY_TYPE_FAIL,
    errMessage: errorMsg,
  };
};

// Clone a journal entry. Returns a journal entry with the metric, symptoms, and treatment recordings copied.
export const cloneJournalEntry = (journalEntry, journalUserId) => {
  let requestUrl = url.CLONE_JOURNAL_ENTRY;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(cloneJournalEntrySuccess(res.responseDto.data));
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; cloneJournalEntry");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            dispatch(cloneJournalEntryFailed(res.errorDto.data.error));
          }
        }
      },
      journalEntry,
    );
  };
};

export const cloneJournalEntrySuccess = data => {
  return {
    type: actionTypes.CLONE_JOURNAL_ENTRY_SUCCESS,
    successMsg: data.message,
  };
};

export const cloneJournalEntryFailed = errorMsg => {
  return {
    type: actionTypes.CLONE_JOURNAL_ENTRY_FAIL,
    errMessage: errorMsg,
  };
};

// Fetch a list of journal entries whose entry date falls within a given time range
export const fetchJournalEntryByTimeRange = (startTime, EndTime) => {
  return dispatch => {
    axiosCommon.axiosGET(url.FETCH_JOURNAL_ENTRY_BY_TIME_RANGE, res => {
      if (res.type === 'SUCCESS') {
        dispatch(fetchJournalEntryByTimeRangeSuccess(res.responseDto.data));
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; fetchJournalEntryByTimeRange");
          dispatch(logout());
        } else {
          dispatch(fetchJournalEntryByTimeRangeFailed(res.errorDto.data.error));
        }
      }
    });
  };
};

export const fetchJournalEntryByTimeRangeSuccess = data => {
  return {
    type: actionTypes.FETCH_JOURNAL_ENTRY_BY_TIME_RANGE_SUCCESS,
    successMsg: data.message,
    journalEntries: data.journalEntries,
  };
};

export const fetchJournalEntryByTimeRangeFailed = errorMsg => {
  return {
    type: actionTypes.FETCH_JOURNAL_ENTRY_BY_TIME_RANGE_FAIL,
    errMessage: errorMsg,
  };
};

function invokeEmailVerificationCheck(dispatch) {
  Auth.currentUserInfo({bypassCache: true,}).then(user => {
    dispatch(setEmailVerified(user.attributes.email_verified));
  }).catch((error) => {
    console.error("Error resolving email verification status (will default to true): " + JSON.stringify(error));
    // We will default to true in a coginito failure. We must fail open - patient records
    // are too important to block over an email verification check
    dispatch(setEmailVerified(true));
  });
}

// Download entire user journal. Intended to be downloaded immediately after login.
export const fetchAllJournalEntries = callBack => {
  return dispatch => {
    dispatch(fetchAllJournalEntriesStart());
    invokeEmailVerificationCheck(dispatch);
    axiosCommon.axiosGET(url.FETCH_ALL_JOURNAL_ENTRIES, res => {
      if (res.type === 'SUCCESS') {
        dispatch(
            fetchAllJournalEntriesSuccess(res.responseDto.data, callBack, true),
        );
        dispatch(
            setDeviceMap(res.responseDto.data?.userDeviceMap),
        );
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; fetchAllJournalEntries");
          dispatch(userJournalDigestError(true));
          dispatch(logout());
        } else {
          console.log("non session timeout error; fetchAllJournalEntries");
          dispatch(commonErrorHandler(res.errorDto.data.error));
          dispatch(fetchAllJournalEntriesFail(res.errorDto.data.error));
          dispatch(logout());
        }
      }
    });
  };
};

//userJournalDigest error function
export const userJournalDigestError = userJournalDigestErrorFlag => {
  return {
    type: actionTypes.USER_DIGEST_ERROR,
    userJournalDigestErrorFlag,
  };
};

export const fetchAllJournalEntriesForCaregiverAccept = callBack => {
  return dispatch => {
    dispatch(fetchAllJournalEntriesStart());
    invokeEmailVerificationCheck(dispatch);
    axiosCommon.axiosGET(url.FETCH_ALL_JOURNAL_ENTRIES, res => {
      if (res.type === 'SUCCESS') {
          dispatch(
            fetchAllJournalEntriesSuccess(
              res.responseDto.data,
              callBack,
              false,
            ),
          );
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; fetchAllJournalEntriesForCaregiverAccept");
          dispatch(logout());
        } else {
          console.log("non session time out; fetchAllJournalEntriesForCaregiverAccept");
          dispatch(commonErrorHandler(res.errorDto.data.error));
          dispatch(fetchAllJournalEntriesFail(res.errorDto.data.error));
          dispatch(logout());
        }
      }
    });
  };
};

export const fetachAllUserJournals = (journalUserId, callBack) => {
  return dispatch => {
    dispatch(fetchAllJournalEntriesStart());
    axiosCommon.axiosGET(
      url.FETCH_ALL_JOURNAL_ENTRIES + '?journalUserId=' + journalUserId,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            fetachAllUserJournalsSuccess(res.responseDto.data, callBack, true),
          );
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; fetachAllUserJournals");
            dispatch(logout());
          } else {
            console.log("non session time out; fetachAllUserJournals");
            dispatch(commonErrorHandler(res.errorDto.data.error));
            dispatch(fetchAllJournalEntriesFail(res.errorDto.data.error));
            dispatch(logout());
          }
        }
      },
    );
  };
};

export const fetchAllJournalEntriesSuccess = (
  data,
  callBack,
  fetchAllJournalsSpinnerStart,
) => {
  const processedData = processAllJournalEntries(data);
  const journalRecordedData = getJournalRecordedData(
    data.journalEntries,
    data.treatments,
    processedData.journalEntryTypes,
    data.contacts,
  );

  if (callBack) {
    callBack(processedData, data);
  }

  return {
    type: actionTypes.FETCH_ALL_JOURNAL_ENTRIES_SUCCESS,
    journalData: data,
    processedData: processedData,
    journalRecordedData: journalRecordedData,
    fetchAllJournalsSpinnerStart: fetchAllJournalsSpinnerStart,
  };
};

export const fetachAllUserJournalsSuccess = (data, callBack, fetchAllJournalsSpinnerStart) => {
  const processedData = processAllUserJournalEntries(data);
  const journalRecordedData = getJournalRecordedData(
    data.journalEntries,
    data.treatments,
    processedData.journalEntryTypes,
    data.contacts,
  );

  if (callBack) {
    callBack(processedData);
  }

  return {
    type: actionTypes.FETCH_ALL_USER_JOURNAL_ENTRIES_SUCCESS,
    journalData: data,
    processedData: processedData,
    journalRecordedData: journalRecordedData,
    fetchAllJournalsSpinnerStart: fetchAllJournalsSpinnerStart,
  };
};

const getJournalRecordedData = (
  journalEntries,
  treatments,
  journalEntryTypes,
  contacts,
) => {
  let recordedSymptom = [];
  let recordedDosing = [];
  let journalArtifactMap = {};
  let treatmentsArtifactMap = {};
  let contactsArtifactMap = {};

  journalEntries.forEach(item => {
    journalArtifactMap[item.artifactId] = item.id;
    if (
      item.symptoms &&
      recordedSymptom.length === 0 &&
      item.symptoms.length > 0
    ) {
      recordedSymptom = [...item.symptoms];
    } else if (item.symptoms && item.symptoms.length > 0) {
      recordedSymptom = recordedSymptom.concat(item.symptoms);
    }
  });

  treatments.forEach(item => {
    treatmentsArtifactMap[item.artifactId] = item;
    if (item.dosages.length > 0) {
      item.dosages.forEach(dosage =>
        recordedDosing.push({...dosage, userTreatment: item.id}),
      );
    }
  });

  contacts.forEach(item => {
    contactsArtifactMap[item.artifactId] = item;
  });

  return {
    recordedSymptom: recordedSymptom,
    recordedDosing: recordedDosing,
    journalArtifactMap: journalArtifactMap,
    treatmentsArtifactMap: treatmentsArtifactMap,
    contactsArtifactMap: contactsArtifactMap,
  };
};

export const fetchAllJournalEntriesFail = errorMsg => {
  return {
    type: actionTypes.FETCH_ALL_JOURNAL_ENTRIES_FAIL,
    errMessage: errorMsg,
  };
};

export const fetchAllJournalEntriesStart = () => {
  return {
    type: actionTypes.FETCH_ALL_JOURNAL_ENTRIES_START,
  };
};

//  Delete an existing journal entry type (routine)
export const deleteJournalEntryType = (
  journalEntryTypeId,
  callBack,
  journalUserId,
) => {
  const entryId = {
    journalEntryTypeId,
  };
  let requestUrl = url.DELETE_JOURNAL_ENTRY_TYPE;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return (dispatch, state) => {
    axiosCommon.axiosDELETE(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          const journalEntryType = state().routines.journalEntryTypesProcessed[journalEntryTypeId];
          if (journalEntryType && journalEntryType.graphId) {
            dispatch(deleteDashboardGraphSuccess(journalEntryType.graphId));
          }
          dispatch(deleteJournalEntryTypeSuccess(journalEntryTypeId));
          callBack();
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; deleteJournalEntryType");
            dispatch(logout());
          } else {
            callBack(res.errorDto.data.error);
          }
        }
      },
      entryId,
    );
  };
};

export const deleteJournalEntryTypeSuccess = journalEntryTypeId => {
  return {
    type: actionTypes.DELETE_JOURNAL_ENTRY_TYPE_SUCCESS,
    journalEntryTypeId,
  };
};

export const clearSelectedEntryType = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.CLEAR_JOURNAL_ENTRY_TYPE_SELECTED,
    });
  };
};

// Save an existing or new journal entry type (routine)
export const updateJournalEntryType = (
  journalEntry,
  journalUserId,
  callBack,
) => {
  let requestUrl = url.UPDATE_JOURNAL_ENTRY_TYPE;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    dispatch({type: actionTypes.UPDATING_JOURNAL_ENTRY_TYPE});
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(updateJournalEntryTypeSuccess(res.responseDto.data.journalEntryType));
          if (callBack) {
            callBack(true, res.responseDto.data);
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateJournalEntryType");
            dispatch(logout());
          } else {
            dispatch(updateJournalEntryTypeFailed(res.errorDto.data.error));
          }
          if (callBack) {
            callBack(false);
          }
        }
      },
      journalEntry,
    );
  };
};

export const updateJournalEntryTypeSuccess = (journalEntryType) => {
  return {
    type: actionTypes.UPDATE_JOURNAL_ENTRY_TYPE_SUCCESS,
    journalEntryType,
  };
};

export const updateJournalEntryTypeFailed = errorMsg => {
  return {
    type: actionTypes.UPDATE_JOURNAL_ENTRY_TYPE_FAIL,
    errorDescription: errorMsg,
  };
};

//For PP published and updated routines
//To fetch Routine by id
export const fetchJournalEntryTypeById = (journalEnrtyTypeId, journalUserId, callBack) => {
  let requestUrl = url.FETCH_JOURNAL_ENTRY_TYPE_BY_ID + journalEnrtyTypeId;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosGET(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(updateJournalEntryTypeDetails(res.responseDto.data, () => {
            callBack(true, res.responseDto.data);
          }));
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; fetchJournalEntryTypeById");
            dispatch(logout());
          } else {
            console.log("non session time out; fetchJournalEntryTypeById");
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false);
            dispatch(logout());
          }
        }
      },
    );
  };
};

//For PP published and updated routines
//To update treatments, symptoms, health data
export const updateJournalEntryTypeDetails = (journalEntryTypeDetails, callBack) => {
  return (dispatch) => {
    dispatch(updateJournalEntryTypeSuccess(journalEntryTypeDetails));
    let treatments = journalEntryTypeDetails?.userTreatmentWithDisplayProperties || [];
    let symptoms = journalEntryTypeDetails?.userSymptomWithDisplayProperties || [];
    let metrics = journalEntryTypeDetails?.metricWithDisplayProperties || [];
    let metricDisplayUnitOfMeasure = journalEntryTypeDetails?.metricDisplayUnitOfMeasures || [];
    if (treatments && treatments.length > 0) {
      treatments.forEach(treatment => {
        // const treatmentItem = state().routines.treatmentsProcessed[treatment.id];
        // if (!treatmentItem) 
        dispatch(updateTreatments(treatment));
      });
    }
    if (symptoms && symptoms.length > 0) {
      symptoms.forEach(symptom => {
        // const symptomItem = state().routines.symptomsDigestProcessed.userSymptoms[symptom.id];
        // if (!symptomItem) 
        dispatch(updateNewSymptom(symptom));
      });
    }
    if (metrics && metrics.length > 0) {
      metrics.forEach(metric => {
        // const metricItem = state().routines.metricsDigestProcessed.selectableMetrics[metric.id];
        // if (!metricItem) 
        dispatch(updateUserMetrics(metric));
        if (metricDisplayUnitOfMeasure && metricDisplayUnitOfMeasure.length > 0) {
          metricDisplayUnitOfMeasure.forEach(metricUom => {
            if (metricUom.metricId === metric.id) {
              dispatch(updateUserMetricUOM(metricUom));
            }
          });
        }
      });
    }
    if (callBack) {
      callBack();
    }
  };
};

// ------ User Health Question Start Here ------

// Save a new or existing user Health Question answer

// ------------------------------------ TO-DO Remove This Start ------------------------------------
export const updateHealthQuestionAnswer = (
  healthQuestionAnswer,
  healthQuestionId,
  questionDigest,
  journalEntrySelected,
  journalEntrySelectedStatus,
  questionDigestStatus,
  journalUserId,
) => {
  let requestUrl = url.UPDATE_HEALTH_QUESTION_ANSWER;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            updateHealthQuestionAnswerSuccess(
              res.responseDto.data,
              healthQuestionId,
              questionDigest,
              journalEntrySelected,
              journalEntrySelectedStatus,
              questionDigestStatus,
            ),
          );
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateHealthQuestionAnswer");
            dispatch(logout());
          } else {
            dispatch(updateHealthQuestionAnswerFail(res.errorDto.data.error));
          }
        }
      },
      healthQuestionAnswer,
    );
  };
};

export const updateHealthQuestionAnswerFail = errorMsg => {
  return {
    type: actionTypes.UPDATE_HEALTH_QUESTION_ANSWER_FAIL,
    errorDescription: errorMsg,
  };
};

export const updateHealthQuestionAnswerSuccess = (
  data,
  healthQuestionId,
  questionDigest,
  journalEntrySelected,
  journalEntrySelectedStatus,
  questionDigestStatus,
) => {
  // Update global state
  // questionDigest.userArtifactHealthQuestions.forEach(function (res)  {
  //   if (res.id === healthQuestionId) {
  //     res.questionIdObj.answers.push(data.healthQuestionAnswer);
  //     return ;
  //   }
  // });

  for (let i = 0; i < questionDigest.userArtifactHealthQuestions.length; i++) {
    if (questionDigest.userArtifactHealthQuestions[i].id === healthQuestionId) {
      questionDigest.userArtifactHealthQuestions[i].questionIdObj.answers.push(
        data.healthQuestionAnswer,
      );
      break;
    }
  }

  //Update Selected Journal
  // journalEntrySelected.questionsFiltered.forEach(function(res) {
  //   if (res.questionId === healthQuestionId) {
  //     res.questionIdObj.answers.push(data.healthQuestionAnswer);
  //     return;
  //   }
  // });

  for (let i = 0; i < journalEntrySelected.questionsFiltered.length; i++) {
    if (
      journalEntrySelected.questionsFiltered[i].questionId === healthQuestionId
    ) {
      journalEntrySelected.questionsFiltered[i].questionIdObj.answers.push(
        data.healthQuestionAnswer,
      );
      break;
    }
  }

  return {
    type: actionTypes.UPDATE_HEALTH_QUESTION_ANSWER_SUCCESS,
    questionDigest: questionDigest,
    journalEntrySelected: journalEntrySelected,
    journalEntrySelectedStatus: !journalEntrySelectedStatus,
    questionDigestStatus: !questionDigestStatus,
  };
};

// ------------------------------------ TO-DO Remove This End ------------------------------------

export const updateHealthQuestionAnswerNew = (
  healthQuestionAnswer,
  callBack,
  journalUserId,
) => {
  let requestUrl = url.UPDATE_HEALTH_QUESTION_ANSWER;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            updateHealthQuestionAnswerSuccessNew(
              res.responseDto.data,
              healthQuestionAnswer.healthQuestionId,
            ),
          );
          callBack(true, res.responseDto.data.healthQuestionAnswer);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateHealthQuestionAnswerNew");
            dispatch(logout());
          } else {
            dispatch(updateHealthQuestionAnswerFail(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      healthQuestionAnswer,
    );
  };
};

export const updateHealthQuestionAnswerSuccessNew = (
  data,
  healthQuestionId,
) => {
  return {
    type: actionTypes.UPDATE_HEALTH_QUESTION_ANSWER_SUCCESS_NEW,
    healthQuestionId: healthQuestionId,
    healthQuestionAnswer: data.healthQuestionAnswer,
  };
};

//Save a new or existing user health question.
export const updateHealthQuestion = (
  healthQuestion,
  questionDigest,
  journalEntrySelectedStatus,
  journalUserId,
) => {
  let requestUrl = url.UPDATE_HEALTH_QUESTION;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            updateHealthQuestionSuccess(
              res.responseDto.data,
              questionDigest,
              journalEntrySelectedStatus,
            ),
          );
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateHealthQuestion");
            dispatch(logout());
          } else {
            dispatch(updateHealthQuestionFail(res.errorDto.data.error));
          }
        }
      },
      healthQuestion,
    );
  };
};

export const updateHealthQuestionNew = (
  healthQuestion,
  questionDigest,
  callBack,
  journalUserId,
) => {
  let requestUrl = url.UPDATE_HEALTH_QUESTION;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            updateHealthQuestionSuccessNew(
              res.responseDto.data,
              questionDigest,
            ),
          );
          callBack(true);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateHealthQuestionNew");
            dispatch(logout());
          } else {
            dispatch(updateHealthQuestionFail(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      healthQuestion,
    );
  };
};

export const updateHealthQuestionSuccessNew = (data, questionDigest) => {
  for (let i = 0; i < questionDigest.questions.length; i++) {
    if (questionDigest.questions[i].id === data.healthQuestion.id) {
      questionDigest.questions[i] = data.healthQuestion;
      break;
    }
  }

  return {
    type: actionTypes.UPDATE_HEALTH_QUESTION_SUCCESS_NEW,
    questionDigest: questionDigest,
  };
};

export const updateHealthQuestionSuccess = (
  data,
  questionDigest,
  journalEntrySelectedStatus,
) => {
  for (let i = 0; i < questionDigest.questions.length; i++) {
    if (questionDigest.questions[i].id === data.healthQuestion.id) {
      questionDigest.questions[i] = data.healthQuestion;
      break;
    }
  }
  return {
    type: actionTypes.UPDATE_HEALTH_QUESTION_SUCCESS,
    questionDigest: questionDigest,
    journalEntrySelectedStatus: !journalEntrySelectedStatus,
  };
};

export const updateHealthQuestionFail = errorMsg => {
  return {
    type: actionTypes.UPDATE_HEALTH_QUESTION_FAIL,
    errMessage: errorMsg,
  };
};

// ------ User Health Question End Here ------

// Get a list of user artifacts associated with a user artifact.
export const fetchUserAttachments = artifactId => {
  return dispatch => {
    dispatch(fetchUserAttachmentsStart());
    axiosCommon.axiosGET(url.FETCH_USER_ATTACHMENTS + artifactId, res => {
      if (res.type === 'SUCCESS') {
        let userAttachmentsSelected = [];
        if (res.responseDto.data.attachments.length !== 0) {
          userAttachmentsSelected = res.responseDto.data.attachments;

          dispatch(fetchUserAttachmentsSuccess(userAttachmentsSelected));
        } else {
          dispatch(fetchUserAttachmentsSuccess(userAttachmentsSelected));
        }
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; fetchUserAttachments");
          dispatch(logout());
        } else {
          dispatch(fetchUserAttachmentsFail(res.errorDto.data.error));
        }
      }
    });
  };
};

export const fetchUserAttachmentsSuccess = userAttachmentsSelected => {
  return {
    type: actionTypes.FETCH_USER_ATTACHMENTS_SUCCESS,
    userAttachmentsSelected: userAttachmentsSelected,
  };
};

export const fetchUserAttachmentsFail = errorMsg => {
  return {
    type: actionTypes.FETCH_USER_ATTACHMENTS_FAIL,
    errMessage: errorMsg,
  };
};

export const fetchUserAttachmentsStart = () => {
  return {
    type: actionTypes.FETCH_USER_ATTACHMENTS_START,
  };
};

// Returns attachment file contents for download
export const deleteAttachment = (
  attachmentId,
  artifactId,
  attachmentsCompleteDigest,
  callBack,
  fileSize,
) => {
  let attachment = {
    attachmentId: attachmentId,
  };
  return dispatch => {
    axiosCommon.axiosDELETE(
      url.DELETE_ATTACHMENT,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            deleteAttachmentSuccess(
              attachmentsCompleteDigest,
              attachmentId,
              fileSize,
            ),
          );
          setTimeout(() => {
            dispatch(fetchUserAttachments(artifactId));
            if (callBack) {
              callBack(true);
            }
          }, 1000);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; deleteAttachment");
            dispatch(logout());
          } else {
            if (callBack) {
              callBack(false);
            }
            dispatch(deleteAttachmentFail(res.errorDto.data.error));
          }
        }
      },
      attachment,
    );
  };
};

export const deleteAttachmentSuccess = (
  attachmentsCompleteDigest,
  attachmentId,
  fileSize,
) => {
  const filteredItems = attachmentsCompleteDigest.userArtifactAttachments.filter(
    res => res.attachment !== attachmentId,
  );
  attachmentsCompleteDigest.userArtifactAttachments = filteredItems;
  return {
    type: actionTypes.DELETE_ATTACHMENT_SUCCESS,
    attachmentsCompleteDigest: attachmentsCompleteDigest,
    fileSize: fileSize,
  };
};

export const deleteAttachmentFail = errorMsg => {
  return {
    type: actionTypes.DELETE_ATTACHMENT_FAIL,
    errorDescription: errorMsg,
  };
};

// Normal Actions
export const setSelectedJournalEntry = selectedJournalEntry => {
  return dispatch => {
    dispatch(setSelectedJournalEntryStart());
    //   dispatch(fetchUserAttachments(selectedJournalEntry.artifactId));
    dispatch(setSelectedJournalEntrySuccess(selectedJournalEntry));
  };
};

export const setSelectedJournalEntryType = journalEntryTypeSelected => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_JOURNAL_ENTRY_TYPE_SELECTED,
      journalEntryTypeSelected,
    });
  };
};

export const setSelectedJournalEntrySuccess = selectedJournalEntry => {
  return {
    type: actionTypes.SET_JOURNAL_ENTRY_SELECTED_SUCCESS,
    journalEntrySelected: selectedJournalEntry,
  };
};

export const setSelectedJournalEntryStart = () => {
  return {
    type: actionTypes.SET_JOURNAL_ENTRY_SELECTED_START,
  };
};

export const setSelectDate = selectedDate => {
  return {
    type: actionTypes.SELECT_DATE,
    selectedDate: selectedDate,
  };
};

export const resetDataLogout = () => {
  return {
    type: actionTypes.RESET_DATA_LOGOUT,
  };
};

// Add Orginal Data Arrays into Objects
const mapDataToKeyVal = arr => {
  let obj = {};

  arr.forEach(res => {
    let key = res.id;
    let value = res;
    obj[key] = value;
  });

  return obj;
};

const mapSharedAccessUserSummaries = arr => {
  let obj = {};

  arr.forEach(res => {
    let key = res.userId;
    let value = res;
    obj[key] = value;
  });

  return obj;
};

// Process Fetch All Journal Entries Data
const processAllJournalEntries = data => {
  const journalEntries = mapDataToKeyVal(data.journalEntries); // Journal Entries
  const journalEntryTypes = mapDataToKeyVal(data.journalEntryTypes); // Journal Entry Types
  const treatments = mapDataToKeyVal(data.treatments); // Treatments
  const contacts = mapDataToKeyVal(data.contacts); // Contacts
  const userAllergies = mapDataToKeyVal(data.allergiesDigest.userAllergies); // User Allergy
  const userConditions = mapDataToKeyVal(data.userConditions); // User Conditions
  const dashboard = mapDataToKeyVal(data.dashboard); //  dashboard
  const clinicalTrials = mapDataToKeyVal(data.clinicalTrial); // Clinical Trials
  const clinicalTrialSavedSearch = mapDataToKeyVal(
    data.clinicalTrialSavedSearch,
  ); //clinical Trial Saved Search

  // Units of Measure Digest
  const unitsOfMeasure = mapDataToKeyVal(
    data.unitsOfMeasureDigest.unitsOfMeasure,
  );
  const systemsOfMeasure = mapDataToKeyVal(
    data.unitsOfMeasureDigest.systemsOfMeasure,
  );
  const baseUnits = mapDataToKeyVal(data.unitsOfMeasureDigest.baseUnits);
  const measurementTypes = mapDataToKeyVal(
    data.unitsOfMeasureDigest.measurementTypes,
  );
  const defaultUnitsOfMeasure = mapDataToKeyVal(
    data.unitsOfMeasureDigest.defaultUnitsOfMeasure,
  );

  // Metrics Digest
  const selectableMetrics = mapDataToKeyVal(
    data.metricsDigest.selectableMetrics,
  );
  const userDefinedMetrics = mapDataToKeyVal(
    data.metricsDigest.userDefinedMetrics,
  );
  const systemDefinedMetrics = mapDataToKeyVal(
    data.metricsDigest.systemDefinedMetrics,
  );
  const userDefinedMetricGroups = mapDataToKeyVal(
    data.metricsDigest.userDefinedMetricGroups,
  );
  const systemDefinedMetricGroups = mapDataToKeyVal(
    data.metricsDigest.systemDefinedMetricGroups,
  );
  const userDefinedMetricUnitOfMeasures = mapDataToKeyVal(
    data.metricsDigest.userDefinedMetricUnitOfMeasures,
  );
  const systemDefinedMetricUnitOfMeasures = mapDataToKeyVal(
    data.metricsDigest.systemDefinedMetricUnitOfMeasures,
  );

  // Symptoms Digest
  const systemDefinedSymptomLocation = mapDataToKeyVal(
    data.symptomsDigest.systemDefinedSymptomLocation,
  );
  const userSymptoms = mapDataToKeyVal(data.symptomsDigest.userSymptoms);

  // Question Digest
  const questions = mapDataToKeyVal(data.questionDigest.questions);
  const userArtifactHealthQuestions = mapDataToKeyVal(
    data.questionDigest.userArtifactHealthQuestions,
  );

  //attachmentsComplete Digest
  const attachments = mapDataToKeyVal(
    data.attachmentsCompleteDigest.attachments,
  );
  const userArtifactAttachments = mapDataToKeyVal(
    data.attachmentsCompleteDigest.userArtifactAttachments,
  );

  // sharedAccess Digest

  const sharedAccessUserSummaries = mapSharedAccessUserSummaries(
    data?.sharedAccessDigest?.sharedAccessUserSummaries || [],
  );

  const sharedAccessGivenToOthers = mapDataToKeyVal(
    data.sharedAccessDigest.sharedAccessGivenToOthers,
  );

  const pendingInvitationsReceived = mapDataToKeyVal(
    data.sharedAccessDigest.pendingInvitationsReceived,
  );

  //drugAdministrationsDigits

  const drugAdministrationsDigits = mapDataToKeyVal(
    data.drugAdministrationsDigits,
  ); // Contacts

  const processedData = {
    journalEntries: journalEntries,
    unitsOfMeasureDigest: {
      unitsOfMeasure: unitsOfMeasure,
      systemsOfMeasure: systemsOfMeasure,
      baseUnits: baseUnits,
      measurementTypes: measurementTypes,
      defaultUnitsOfMeasure: defaultUnitsOfMeasure,
    },
    metricsDigest: {
      selectableMetrics: selectableMetrics,
      userDefinedMetrics: userDefinedMetrics,
      systemDefinedMetrics: systemDefinedMetrics,
      userDefinedMetricGroups: userDefinedMetricGroups,
      systemDefinedMetricGroups: systemDefinedMetricGroups,
      userDefinedMetricUnitOfMeasures: userDefinedMetricUnitOfMeasures,
      systemDefinedMetricUnitOfMeasures: systemDefinedMetricUnitOfMeasures,
    },
    symptomsDigest: {
      systemDefinedSymptomLocation: systemDefinedSymptomLocation,
      userSymptoms: userSymptoms,
    },
    journalEntryTypes: journalEntryTypes,
    treatments: treatments,
    contacts: contacts,
    questionDigest: {
      questions: questions,
      userArtifactHealthQuestions: userArtifactHealthQuestions,
    },
    attachmentsCompleteDigest: {
      attachments: attachments,
      userArtifactAttachments: userArtifactAttachments,
    },
    userAllergies: userAllergies,
    userConditions: userConditions,
    dashboard: dashboard,
    sharedAccessDigest: {
      sharedAccessUserSummaries: sharedAccessUserSummaries,
      sharedAccessGivenToOthers: sharedAccessGivenToOthers,
      pendingInvitationsReceived: pendingInvitationsReceived,
    },
    clinicalTrials: clinicalTrials,
    clinicalTrialSavedSearch: clinicalTrialSavedSearch,
    drugAdministrationsDigits: drugAdministrationsDigits,
  };

  return processedData;
};

// Process Fetch All Journal Entries Data
const processAllUserJournalEntries = data => {
  const journalEntries = mapDataToKeyVal(data.journalEntries); // Journal Entries
  const journalEntryTypes = mapDataToKeyVal(data.journalEntryTypes); // Journal Entry Types
  const treatments = mapDataToKeyVal(data.treatments); // Treatments
  const contacts = mapDataToKeyVal(data.contacts); // Contacts
  const userAllergies = mapDataToKeyVal(data.allergiesDigest.userAllergies); // User Allergy
  const userConditions = mapDataToKeyVal(data.userConditions); // User Conditions
  const dashboard = mapDataToKeyVal(data.dashboard); //  dashboard
  const clinicalTrials = mapDataToKeyVal(data.clinicalTrial); // Clinical Trials
  const clinicalTrialSavedSearch = mapDataToKeyVal(
    data.clinicalTrialSavedSearch,
  ); //clinical Trial Saved Search

  // Units of Measure Digest
  const unitsOfMeasure = mapDataToKeyVal(
    data.unitsOfMeasureDigest.unitsOfMeasure,
  );
  const systemsOfMeasure = mapDataToKeyVal(
    data.unitsOfMeasureDigest.systemsOfMeasure,
  );
  const baseUnits = mapDataToKeyVal(data.unitsOfMeasureDigest.baseUnits);
  const measurementTypes = mapDataToKeyVal(
    data.unitsOfMeasureDigest.measurementTypes,
  );
  const defaultUnitsOfMeasure = mapDataToKeyVal(
    data.unitsOfMeasureDigest.defaultUnitsOfMeasure,
  );

  // Metrics Digest
  const selectableMetrics = mapDataToKeyVal(
    data.metricsDigest.selectableMetrics,
  );
  const userDefinedMetrics = mapDataToKeyVal(
    data.metricsDigest.userDefinedMetrics,
  );
  const systemDefinedMetrics = mapDataToKeyVal(
    data.metricsDigest.systemDefinedMetrics,
  );
  const userDefinedMetricGroups = mapDataToKeyVal(
    data.metricsDigest.userDefinedMetricGroups,
  );
  const systemDefinedMetricGroups = mapDataToKeyVal(
    data.metricsDigest.systemDefinedMetricGroups,
  );
  const userDefinedMetricUnitOfMeasures = mapDataToKeyVal(
    data.metricsDigest.userDefinedMetricUnitOfMeasures,
  );
  const systemDefinedMetricUnitOfMeasures = mapDataToKeyVal(
    data.metricsDigest.systemDefinedMetricUnitOfMeasures,
  );

  // Symptoms Digest
  const systemDefinedSymptomLocation = mapDataToKeyVal(
    data.symptomsDigest.systemDefinedSymptomLocation,
  );
  const userSymptoms = mapDataToKeyVal(data.symptomsDigest.userSymptoms);

  // Question Digest
  const questions = mapDataToKeyVal(data.questionDigest.questions);
  const userArtifactHealthQuestions = mapDataToKeyVal(
    data.questionDigest.userArtifactHealthQuestions,
  );

  //attachmentsComplete Digest
  const attachments = mapDataToKeyVal(
    data.attachmentsCompleteDigest.attachments,
  );
  const userArtifactAttachments = mapDataToKeyVal(
    data.attachmentsCompleteDigest.userArtifactAttachments,
  );

  // sharedAccess Digest

  // const sharedAccessUserSummaries = mapSharedAccessUserSummaries(
  //   data.sharedAccessDigest.sharedAccessUserSummaries
  // );

  //  const sharedAccessGivenToOthers = mapDataToKeyVal(
  //    data.sharedAccessDigest.sharedAccessGivenToOthers
  //  );

  const processedData = {
    journalEntries: journalEntries,
    unitsOfMeasureDigest: {
      unitsOfMeasure: unitsOfMeasure,
      systemsOfMeasure: systemsOfMeasure,
      baseUnits: baseUnits,
      measurementTypes: measurementTypes,
      defaultUnitsOfMeasure: defaultUnitsOfMeasure,
    },
    metricsDigest: {
      selectableMetrics: selectableMetrics,
      userDefinedMetrics: userDefinedMetrics,
      systemDefinedMetrics: systemDefinedMetrics,
      userDefinedMetricGroups: userDefinedMetricGroups,
      systemDefinedMetricGroups: systemDefinedMetricGroups,
      userDefinedMetricUnitOfMeasures: userDefinedMetricUnitOfMeasures,
      systemDefinedMetricUnitOfMeasures: systemDefinedMetricUnitOfMeasures,
    },
    symptomsDigest: {
      systemDefinedSymptomLocation: systemDefinedSymptomLocation,
      userSymptoms: userSymptoms,
    },
    journalEntryTypes: journalEntryTypes,
    treatments: treatments,
    contacts: contacts,
    questionDigest: {
      questions: questions,
      userArtifactHealthQuestions: userArtifactHealthQuestions,
    },
    attachmentsCompleteDigest: {
      attachments: attachments,
      userArtifactAttachments: userArtifactAttachments,
    },
    userAllergies: userAllergies,
    userConditions: userConditions,
    dashboard: dashboard,
    //  sharedAccessDigest: {
    //  sharedAccessUserSummaries: sharedAccessUserSummaries
    //   sharedAccessGivenToOthers: sharedAccessGivenToOthers
    // },
    clinicalTrials: clinicalTrials,
    clinicalTrialSavedSearch: clinicalTrialSavedSearch,
  };

  return processedData;
};

export const filterJournalEntry = filter => {
  return {
    type: actionTypes.JOURNAL_ENTRY_FILTER,
    filter: filter,
  };
};

export const clearJournalEntryFilter = () => {
  return {
    type: actionTypes.JOURNAL_ENTRY_CLEAR_FILTER,
  };
};

export const setAttachmentUploadProgress = progress => {
  return {
    type: actionTypes.SET_ATTACHEMENT_UPLOAD_PROGRESS,
    progress,
  };
};

export const setAttachmentUploadProgressTemp = progress => {
  return {
    type: actionTypes.SET_ATTACHEMENT_UPLOAD_PROGRESS_TEMP,
    progress,
  };
};

export const setAttachmentUploadStatus = status => {
  return {
    type: actionTypes.SET_ATTACHEMENT_UPLOAD_STATUS,
    status,
  };
};

export const setAttachmentArtifactId = id => {
  return {
    type: actionTypes.SET_ATTACHEMENT_ARTIFACT_ID,
    id,
  };
};

export const setAttachmentFullSize = size => {
  return {
    type: actionTypes.SET_ATTACHEMENT_FULL_SIZE,
    size,
  };
};

export const setAttachmentCurrentKey = key => {
  return {
    type: actionTypes.SET_ATTACHEMENT_UPLOAD_CURRENT_KEY,
    key,
  };
};

export const cancelUploadAttachmentProcess = (
  artifactId,
  journalUserId,
  callback,
  uniqueId,
) => {
  return dispatch => {
    let requestUrl = url.CANCEL_UPLOAD_USER_ATTACHMENT + '?artifactId=' + artifactId;

    if (journalUserId) {
      requestUrl += '&journalUserId=' + journalUserId;
    }
    if (uniqueId) {
      requestUrl += '&uniqueId=' + uniqueId;
    }
    axiosCommon.axiosDELETE(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          callback(true);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; cancelUploadAttachmentProcess");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callback(false);
          }
        }
      }
    );
  };
};

export const cancelUploadAttachmentProcessAxios = (cancelToken) => {
  return () => {
    cancelToken.cancel('UPLOADCANCELLED');
  };
};

export const uploadAttachmen = (
  artifactId,
  formData,
  callBack,
  reload,
  journalUserId,
  fileSize,
  uploadConfig,
  cancelToken,
  uniqueId,
  currentEventId,
) => {
  return dispatch => {
    let requestUrl = url.UPLOAD_USER_ATTACHMENT + '?artifactId=' + artifactId;

    if (journalUserId) {
      requestUrl += '&journalUserId=' + journalUserId;
    }
    if (uniqueId) {
      requestUrl += '&uniqueId=' + uniqueId;
    }
    if (currentEventId) {
      requestUrl += '&eventId=' + currentEventId;
    }
    axiosCommon.axiosFileUploadPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            uploadAttachmentsSuccess(
              res.responseDto.data.attachments,
              artifactId,
              fileSize,
            ),
          );

          callBack(true, res.responseDto.data.attachments);
          if (reload || reload === undefined) {
            dispatch(fetchUserAttachments(artifactId));
          }
        } else if (res.type === 'UPLOADCANCELLED') {
          callBack('UPLOADCANCELLED');
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; uploadAttachmen");
            dispatch(logout());
          } else {
            if (!res.errorDto.data.error === 'Uploading Attachment has been cancelled.') {
              dispatch(commonErrorHandler(res.errorDto.data.error));
            }
            callBack(false);
          }
        }
      },
      formData,
      uploadConfig?.onUploadProgress,
      cancelToken,
    );
  };
};

export const uploadAttachmentsSuccess = (attachments, artifactId, fileSize) => {
  return {
    type: actionTypes.UPLOAD_ATTACHMENTS_SUCCESS,
    attachments: attachments,
    artifactId: artifactId,
    fileSize: fileSize,
  };
};

export const setTotalAttachmentSize = fileSize => {
  return {
    type: actionTypes.SET_TOTAL_ATTACHMENT_SIZE,
    fileSize: fileSize,
  };
};

export const addHealthQuestion = (
  questions,
  questionDigest,
  questionDigestProcessed,
  artifactId,
) => {
  questionDigest.questions.push(questions);
  questionDigestProcessed.questions[questions.id] = questions;
  const userArtifactHealthQuestions = {
    questionId: questions.id,
    artifactId: artifactId,
  };
  questionDigest.userArtifactHealthQuestions.push(userArtifactHealthQuestions);
  questionDigestProcessed.userArtifactHealthQuestions[
    questions.id + '-' + artifactId
  ] = userArtifactHealthQuestions;
  return {
    type: actionTypes.ADD_QUESTION,
    questionDigest: questionDigest,
    questionDigestProcessed: questionDigestProcessed,
  };
};

export const clearError = () => {
  return {
    type: actionTypes.CLEAR_ERROR,
  };
};

// Start User Allergy

export const updateUserAllergy = (userAllergy, callBack, journalUserId) => {
  let requestUrl = url.UPDATE_USER_ALLERGY;

  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(updateUserAllergySuccess(res.responseDto.data.userAllergy));
          callBack(true);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateUserAllergy");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      {userAllergy: userAllergy},
    );
  };
};

export const updateUserAllergySuccess = userAllergy => {
  return {
    type: actionTypes.UPDATE_USER_ALLERGY,
    userAllergy: userAllergy,
  };
};

export const deleteUserAllergy = (id, callBack) => {
  return dispatch => {
    axiosCommon.axiosDELETE(
      url.DELETE_USER_ALLERGY,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(deleteUserAllergySuccess(id));
          if (callBack) {
            callBack(true);
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; deleteUserAllergy");
            dispatch(logout());
          } else {
            if (callBack) {
              dispatch(commonErrorHandler(res.errorDto.data.error));
              callBack(false);
            }
          }
        }
      },
      {userAllergyId: id},
    );
  };
};

export const deleteUserAllergySuccess = id => {
  return {
    type: actionTypes.DELETE_USER_ALLERGY,
    id: id,
  };
};

// End User Allergy

// Start User Condition

export const updateUserConditions = (conditions, callBack, journalUserId) => {
  let requestUrl = url.UPDATE_USER_CONDITION;

  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }

  return dispatch => {
    conditions.forEach(res => {
      axiosCommon.axiosPUT(
        requestUrl,
        res => {
          if (res.type === 'SUCCESS') {
            dispatch(
              updateUserConditionsSuccess(res.responseDto.data),
            );
          } else {
            if (res.errorDto === 'SESSION_TIME_OUT') {
              console.log("session time out; updateUserConditions");
              dispatch(logout());
            } else {
              dispatch(commonErrorHandler(res.errorDto.data.error));
              // callBack(false, res.errorDto.data.message);
            }
          }
        },
        {userConditions: res},
      );
    });
    callBack(true);
  };
};

export const updateUserCondition = (condition, callBack, journalUserId) => {
  let requestUrl = url.UPDATE_USER_CONDITION;

  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }

  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            updateUserConditionsSuccess(res.responseDto.data),
          );
          callBack(true, res.responseDto.data);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateUserCondition");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      {userConditions: condition},
    );
  };
};

export const updateUserConditionsSuccess = condition => {
  return {
    type: actionTypes.UPDATE_USER_CONDITION,
    userCondition: condition,
  };
};

export const deleteUseCondition = (id, peer, callBack) => {
  return dispatch => {
    axiosCommon.axiosDELETE(
      url.DELETE_USER_CONDITION,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(deleteUseConditionSuccess(id));
          if (callBack) {
            callBack(true);
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; deleteUseCondition");
            dispatch(logout());
          } else {
            if (callBack) {
              dispatch(commonErrorHandler(res.errorDto.data.error));
              callBack(false);
            }
          }
        }
      },
      {userConditionsId: id, ...(peer === true && {peer: true})},
    );
  };
};

export const deleteUseConditionSuccess = id => {
  return {
    type: actionTypes.DELETE_USER_CONDITION,
    id: id,
  };
};

// Start User Symptom
export const updateUserSymptom = (symptom, callBack, journalUserId) => {
  let requestUrl = url.UPDATE_USER_SYMPTOM;

  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }

  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(updateUserSymptomSuccess(res.responseDto.data));
          callBack(true, res.responseDto.data.userSymptom);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateUserSymptom");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      {userSymptom: symptom},
    );
  };
};
export const updateUserSymptomSuccess = data => {
  return {
    type: actionTypes.UPDATE_USER_SYMPTOM,
    userSymptom: data.userSymptom,
  };
};

// Start User Metric
export const updateUserMetric = (metric, callBack, journalUserId) => {
  let requestUrl = url.UPDATE_USER_METRIC;

  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(updateUserMetrics(res.responseDto.data.metric));
          callBack(true, res.responseDto.data.metric);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateUserMetric");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      {metric: metric},
    );
  };
};

export const updateUserMetricDisplayUom = (
  metricDefaultUnitOfMeasure,
  journalUserId,
) => {
  let requestUrl = url.UPDATE_USER_METRIC_DISPLAY_UOM;

  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            updateUserMetricUOM(
              res.responseDto.data.metricDefaultUnitOfMeasure,
            ),
          );
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateUserMetricDisplayUom");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            //     callBack(false, res.errorDto.data.message);
          }
        }
      },
      {metricDefaultUnitOfMeasure: metricDefaultUnitOfMeasure},
    );
  };
};

//Selectable Metric
export const updateSelectableMetrics = metric => {
  return {
    type: actionTypes.UPDATE_SELECTABLE_METRIC,
    metric: metric,
  };
};

export const uploadProfileImage = (formData, callBack, journalUserId) => {
  let requestUrl = url.UPLOAD_PROFILE_IMAGE;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosImageUploadPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(true);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; uploadProfileImage");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      formData,
    );
  };
};

export const deleteProfileImage = (type, callBack, journalUserId) => {
  let requestUrl = url.DELETE_PROFILE_IMAGE;

  if (type === "PEER") {
    requestUrl = url.DELETE_PROFILE_IMAGE_PEER;
  }

  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosDELETE(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(true);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; deleteProfileImage");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
    );
  };
};

// start Care giver access

export const sharedAccessInvitation = (data, callBack, journalUserId) => {
  let requestUrl = url.SHARED_ACCESS_INVITATION;

  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }

  return dispatch => {
    const list = [];
    data.emailAddresses.forEach((res, index) => {
      list.push({
        emailAddress: res.email,
        permission: res.permission,
        note: data.note,
      });
    });

    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            sharedAccessInvitationSuccess(
              res.responseDto.data.shareAccessInvitations,
            ),
          );
          callBack(true, res.responseDto.data.shareAccessInvitations);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; sharedAccessInvitation");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.message);
          }
        }
      },
      {shareAccess: list},
    );
  };
};

export const sharedAccessInvitationResend = (token, callBack) => {
  return dispatch => {
    axiosCommon.axiosPUT(
      url.SHARED_ACCESS_INVITATION_RESEND,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            sharedAccessInvitationResendSuccess(
              token,
              res.responseDto.data.shareAccessInvitations?.[0],
            ),
          );
          callBack(true, res.responseDto.data.shareAccessInvitations?.[0]);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; sharedAccessInvitationResend");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      {
        token: token,
      },
    );
  };
};

export const sharedAccessInvitationResendSuccess = (token, data) => {
  return {
    type: actionTypes.SHARED_ACCESS_INVITATION_RESEND_SUCCESS,
    data: data,
    token: token,
  };
};

export const sharedAccessInvitationAccept = (token, callBack) => {
  return dispatch => {
    axiosCommon.axiosPUT(
      url.SHARED_ACCESS_INVITATION_ACCEPT,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(true, res.responseDto.data);
          dispatch(sharedAccessInvitationAcceptSuccess(token, res.responseDto.data));
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; sharedAccessInvitationAccept");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data);
          }
        }
      },
      {
        token: token,
      },
    );
  };
};

export const sharedAccessInvitationAcceptSuccess = (token, data) => {
  return {
    type: actionTypes.SHARED_ACCESS_INVITATION_ACCEPT_SUCCESS,
    data: data,
    token: token,
  };
};

export const sharedAccessInvitationDecline = (token, callBack) => {
  return dispatch => {
    axiosCommon.axiosPUT(
      url.SHARED_ACCESS_INVITATION_DECLINE,
      res => {
        if (res.type === 'SUCCESS') {
          sharedAccessInvitationDeclineSuccess(token);
          callBack(true);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; sharedAccessInvitationDecline");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      {
        token: token,
      },
    );
  };
};

export const sharedAccessInvitationDeclineSuccess = token => {
  return {
    type: actionTypes.SHARED_ACCESS_INVITATION_DECLINE_SUCCESS,
    token: token,
  };
};

export const sharedAccessInvitationSuccess = data => {
  return {
    type: actionTypes.SHARED_ACCESS_INVITATION_SUCCESS,
    shareAccessInvitations: data,
  };
};

export const deleteSharedAccessInvitation = (
  token,
  callBack,
  journalUserId,
) => {
  let requestUrl = url.SHARED_ACCESS_INVITATION;

  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosDELETE(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(deleteSharedAccessInvitationSuccess(token));
          if (callBack) {
            callBack(true);
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; deleteSharedAccessInvitation");
            dispatch(logout());
          } else {
            if (callBack) {
              dispatch(commonErrorHandler(res.errorDto.data.error));
              callBack(false);
            }
          }
        }
      },
      {
        token: token,
      },
    );
  };
};

export const deleteSharedAccessInvitationSuccess = token => {
  return {
    type: actionTypes.DELETE_SHARED_ACCESS_INVITATION_SUCCESS,
    token: token,
  };
};

export const deleteSharedAccessPermission = (
  grantedTo,
  callBack,
  journalUserId,
) => {
  let requestUrl = url.SHARED_ACCESS_PERMISSION;

  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosDELETE(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(deleteSharedAccessPermissionSuccess(grantedTo));
          if (callBack) {
            callBack(true);
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; deleteSharedAccessPermission");
            dispatch(logout());
          } else {
            if (callBack) {
              dispatch(commonErrorHandler(res.errorDto.data.error));
              callBack(false);
            }
          }
        }
      },
      {
        grantedTo: grantedTo,
      },
    );
  };
};

export const deleteSharedAccessPermissionGivenToMe = (
  grantingUser,
  callBack,
) => {
  let requestUrl = url.SHARED_ACCESS_PERMISSION_CAREGIVER;

  return dispatch => {
    axiosCommon.axiosDELETE(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(deleteSharedAccessPermissionGivenTomeSuccess(grantingUser));
          if (callBack) {
            callBack(true);
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; deleteSharedAccessPermissionGivenToMe");
            dispatch(logout());
          } else {
            if (callBack) {
              dispatch(commonErrorHandler(res.errorDto.data.error));
              callBack(false);
            }
          }
        }
      },
      {
        grantedFrom: grantingUser,
      },
    );
  };
};

export const sharedAccessDisconnect = (data, callBack) => {
  let requestUrl = url.SHARED_ACCESS_DISCONNECT;

  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(sharedAccessDisconnectSuccess(res.responseDto.data));
          if (callBack) {
            callBack(true, res.responseDto.data);
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; sharedAccessDisconnect");
            dispatch(logout());
          } else {
            if (callBack) {
              dispatch(commonErrorHandler(res.errorDto.data.error));
              callBack(false);
            }
          }
        }
      },
      data,
    );
  };
};

export const sharedAccessDisconnectSuccess = data => {
  return {
    type: actionTypes.SHARED_ACCESS_DISCONNECT_SUCCESS,
    data: data,
  };
};

export const deleteSharedAccessPermissionSuccess = grantedTo => {
  return {
    type: actionTypes.DELETE_SHARED_ACCESS_PERMISSION_SUCCESS,
    grantedTo: grantedTo,
  };
};

export const deleteSharedAccessPermissionGivenTomeSuccess = grantingUser => {
  return {
    type: actionTypes.DELETE_SHARED_ACCESS_PERMISSION_GIVEN_TO_ME_SUCCESS,
    grantingUser: grantingUser,
  };
};

export const updateSharedAccessPermission = (
  grantedTo,
  permission,
  callBack,
) => {
  return dispatch => {
    axiosCommon.axiosPUT(
      url.SHARED_ACCESS_PERMISSION,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(updateSharedAccessPermissionSuccess(grantedTo, permission));
          if (callBack) {
            callBack(true);
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateSharedAccessPermission");
            dispatch(logout());
          } else {
            if (callBack) {
              dispatch(commonErrorHandler(res.errorDto.data.error));
              callBack(false);
            }
          }
        }
      },
      {
        grantedTo: grantedTo,
        permission: permission,
      },
    );
  };
};

export const updateSharedAccessPermissionSuccess = (grantedTo, permission) => {
  return {
    type: actionTypes.UPDATE_SHARED_ACCESS_PERMISSION_SUCCESS,
    grantedTo: grantedTo,
    permission: permission,
  };
};

// end Care giver access

//start dashboard graph

export const updateDashboardGraph = (dashboardData, callBack, journalUserId) => {
  let requestUrl = url.UPDATE_DASHBOARD_GRAPH;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            updateDashboardGraphSuccess(res.responseDto.data.dashboard, true),
          );
          callBack(true, res.responseDto.data.dashboard);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateDashboardGraph");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      {
        dashboard: dashboardData,
        // updatedUserTreatments: updatedUserTreatments,
        // updatedUserSymptoms: updatedUserSymptoms,
        // updatedUserMetrics: updatedUserMetrics

      },
    );
  };
};

export const updateDashboardGraphSuccess = (data, isFromGraphSave) => {
  return {
    type: actionTypes.UPDATE_DASHBOARD_GRAPH_SUCCESS,
    dashboard: data,
    isFromGraphSave: isFromGraphSave,
  };
};

export const deleteDashboardGraph = (dashboardId, callBack, journalUserId) => {
  let requestUrl = url.DELETE_DASHBOARD_GRAPH;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosDELETE(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(deleteDashboardGraphSuccess(dashboardId));
          if (callBack) {
            callBack(true);
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; deleteDashboardGraph");
            dispatch(logout());
          } else {
            if (callBack) {
              dispatch(commonErrorHandler(res.errorDto.data.error));
              callBack(false);
            }
          }
        }
      },
      {
        dashboardId: dashboardId,
      },
    );
  };
};

export const deleteDashboardGraphSuccess = dashboardId => {
  return {
    type: actionTypes.DELETE_DASHBOARD_GRAPH_SUCCESS,
    dashboardId: dashboardId,
  };
};

//end dashboard graph

export const commonErrorHandler = errorDescription => {
  return {
    type: actionTypes.COMMON_ERROR_HANDLER,
    errorDescription,
  };
};

// Delete Health Question
export const deleteHealthQuestion = (
  healthQuestionId,
  artifactId,
  questionDigest,
  questionDigestProcessed,
  callBack,
  journalUserId,
) => {
  let requestUrl = url.DELETE_HEALTH_QUESTION;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosDELETE(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            deleteHealthQuestionSuccess(
              questionDigest,
              healthQuestionId,
              questionDigestProcessed,
            ),
          );
          callBack();
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; deleteHealthQuestion");
            dispatch(logout());
          } else {
            dispatch(deleteHealthQuestionFail(res.errorDto.data.error));
          }
        }
      },
      {
        healthQuestionId,
        artifactId,
      },
    );
  };
};

// Delete Health Question
export const deleteHealthQuestionSuccess = (
  questionDigest,
  healthQuestionId,
  questionDigestProcessed,
) => {
  const found = questionDigest.userArtifactHealthQuestions.find(
    res => res.questionId === healthQuestionId,
  );
  let userArtifactHealthQuestionsTemp = questionDigest.userArtifactHealthQuestions.filter(
    res => res.questionId !== healthQuestionId,
  );
  const id = healthQuestionId;

  let questions = questionDigest.questions.filter(res => res.id !== id);

  questionDigest.userArtifactHealthQuestions = userArtifactHealthQuestionsTemp;
  questionDigest.questions = questions;

  const questionsProcessed = questionDigestProcessed.questions;
  const userArtifactHealthQuestionsProcessed =
    questionDigestProcessed.userArtifactHealthQuestions;
  delete questionsProcessed[healthQuestionId];

  if (found && userArtifactHealthQuestionsProcessed[found.id]) {
    delete userArtifactHealthQuestionsProcessed[found.id];
  }

  return {
    type: actionTypes.DELETE_HEALTH_QUESTION_SUCCESS,
    questionDigest: questionDigest,
    questionDigestProcessed: {
      questions: questionsProcessed,
      userArtifactHealthQuestions: userArtifactHealthQuestionsProcessed,
    },
  };
};
export const deleteHealthQuestionFail = errorMsg => {
  return {
    type: actionTypes.DELETE_HEALTH_QUESTION_FAIL,
    errorDescription: errorMsg,
  };
};

export const pushNotification = (pushNotification, callBack, journalUserId) => {
  let requestUrl = url.PUSH_NOTIFICATIONS;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          dispatch(
            pushNotificationOverdueRoutineReminderAndMatchedClinicalTrialSuccess(
              res.responseDto.data.pushNotification,
            ),
          );
          callBack(true);
        } else if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; pushNotification");
          dispatch(logout());
        } else
          dispatch(
            pushNotificationOverdueRoutineReminderAndMatchedClinicalTrialFail(
              res.errorDto.data.error,
            ),
          );
        callBack(false);
      },
      pushNotification,
    );
  };
};

export const pushNotificationOverdueRoutineReminderAndMatchedClinicalTrialSuccess = pushNotification => {
  return {
    type: actionTypes.OVERDUE_ROUTINE_REMINDER_AND_MATCHED_CLINICAL_TRAIL,
    matchedClinicalTrial: pushNotification.matchedClinicalTrial,
    overdueRoutineReminder: pushNotification.overdueRoutineReminder,
  };
};

export const pushNotificationOverdueRoutineReminderAndMatchedClinicalTrialFail = errorMsg => {
  return {
    type: actionTypes.OVERDUE_ROUTINE_REMINDER_AND_MATCHED_CLINICAL_TRAIL_FAIL,
    errMessage: errorMsg,
  };
};

export const fetchIosSubscriptionDetails = (successCallback, journalUserId) => {
  let requestUrl = url.IOS_SUBSCRIPTION_STATUS;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosGET(requestUrl, response => {
      console.log(
        'Actions Journal - fetchIosSubscriptionDetails | response: ',
        response,
      );
      if (response.type === 'SUCCESS') {
        dispatch(fetchIosSubscriptionDetailsSuccess(response.responseDto.data));
        successCallback(true);
      } else if (response.errorDto === 'SESSION_TIME_OUT') {
        console.log("session time out; fetchIosSubscriptionDetails");
        successCallback(false);
        dispatch(logout());
      } else {
        dispatch(
          fetchIosSubscriptionDetailsFailure(response.errorDto.data.error),
        );
        dispatch(commonErrorHandler(response.errorDto.data.error));
        successCallback(false);
      }
    });
  };
};

export const fetchIosSubscriptionDetailsSuccess = iosSubscriptionDetails => {
  return {
    type: actionTypes.FETCH_IOS_SUBSCRIPTION_DETAILS_SUCCESS,
    userSubscription: iosSubscriptionDetails.userSubscription,
    userSubscriptionDigest: iosSubscriptionDetails.userSubscriptionDigest,
  };
};

export const fetchIosSubscriptionDetailsFailure = errorDescription => {
  return {
    type: actionTypes.FETCH_IOS_SUBSCRIPTION_DETAILS_FAILURE,
    errorDescription: errorDescription,
  };
};

export const onTouchRoutine = flag => {
  return {
    type: actionTypes.ON_TOUCH_ROUTINE,
    flag,
  };
};

// refer friend inviation
export const referFriendInvitation = (data, callBack, journalUserId) => {
  let requestUrl = url.REFER_FRIEND_INVITATION;

  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }

  return dispatch => {
    let list = {
      emailAddress: [],
    };
    data.emailAddresses.forEach((res, index) => {
      data.loggedUserEmail !== res.email && list.emailAddress.push(res.email);
    });

    list.note = data.note;

    let errorMessage = `Email same as logged in user: ${data.loggedUserEmail}`;

    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          if (list.emailAddress.length !== data.emailAddresses.length) {
            res.responseDto.message = errorMessage;
            dispatch(commonErrorHandler(errorMessage));
          } else {
            res.responseDto.message = 'Your mail was successfully sent.';
          }

          callBack(true, res.responseDto);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; referFriendInvitation");
            dispatch(logout());
          } else {
            if (list.emailAddress.length !== data.emailAddresses.length) {
              res.errorDto.data.message = errorMessage;
              dispatch(commonErrorHandler(errorMessage));
            } else {
              res.errorDto.data.message = "Refer Friend Email wasn't sent!!!";
              dispatch(commonErrorHandler(res.errorDto.data.error));
            }

            callBack(false, res.errorDto.data);
          }
        }
      },
      list,
    );
  };
};

/**
 * Join study requests
 * @param {string} type "JOIN" or "VALIDATE" 
 * @param {string} accessCode 
 * @param {(status: boolean) => any} callback 
 */
 export const handleJoinStudyRequests = (type, accessCode, callback, journalUserId) => {
  let requestUrl = url.JOIN_STUDY;
  if (type === "JOIN") {
    requestUrl += 'join-study';
  } else if (type === "VALIDATE") {
    requestUrl += 'verify-accesscode';
  } else {
    throw new Error(`${type} is not specified!`);
  }
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId
  }
  
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          if (type === "JOIN") {
            callback(true, res.responseDto.data);
          } else {
            callback(true);
          }
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; handleJoinStudyRequests");
            dispatch(logout());
          } else {
            callback(false);
            dispatch(commonErrorHandler(res.errorDto.data.error));
          }
        }
      },
      {accessCode},
    );
  };
};

export const getJoinStudyStatus = (id, callback, journalUserId) => {
  let requestUrl = url.JOIN_STUDY_STATUS + id;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId
  }
  return dispatch => {
    axiosCommon.axiosGET(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          callback(true);
          dispatch(resetDataLogout());
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; getJoinStudyStatus");
            dispatch(logout());
          } else {
            callback(false);
          }
        }
      },
    );
  };
};
//save list of treatments once in routine wizard (Save & Next/ Save & Back)
export const updateRoutineWizardTreatments = (routineWizards, callback, journalUserId) => {
  let requestUrl = url.ROUTINE_WIZARD_TREATMENTS;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          callback(true, res.responseDto.data.routineWizards);
          if (res.responseDto.data.routineWizards && res.responseDto.data.routineWizards.length > 0) {
            res.responseDto.data.routineWizards.forEach(treatment => {
              if (treatment.contact) {
                dispatch(updateContacts(treatment))
              }
              if (treatment.metrics && treatment.metrics.length > 0) {
                treatment.metrics.forEach(res => {
                  dispatch(updateUserMetrics(res));
                })
              }
              //uom
              if (treatment.metricDefaultUnitOfMeasures && treatment.metricDefaultUnitOfMeasures.length > 0) {
                treatment.metricDefaultUnitOfMeasures.forEach(res => {
                  dispatch(updateUserMetricUOM(res));
                })
              }
              if (treatment.treatment) {
                let newMetrics = [];
                if (treatment.metricDefaultUnitOfMeasures && treatment.metricDefaultUnitOfMeasures.length > 0) {
                  treatment.metricDefaultUnitOfMeasures.forEach(element => {
                    newMetrics.push({
                      ...element,
                      metric: element.metricId,
                      unit: element.uomId,
                    });
                  });
                  treatment.treatment.defaultMetrics = [...treatment.treatment.defaultMetrics, ...newMetrics];
                }
                dispatch(updateTreatments(treatment))
              }
            })
          }  
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; updateRoutineWizardTreatments");
            dispatch(logout());
          } else {
            dispatch(commonErrorHandler(res.errorDto.data.error));
          }
        }
      },
      {routineWizards: routineWizards}
    );
  };
};

export const editJournalEntry = (id, journalUserId, callback, isFromCopyToNew = false) => {
  let requestUrl = (isFromCopyToNew ? url.COPY_TO_NEW_JOURNAL_ENTRY : url.EDIT_JOURNAL_ENTRY) + id;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId
  }
  return dispatch => {
    axiosCommon.axiosGET(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          callback(true, res.responseDto.data);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; editJournalEntry");
            dispatch(logout());
          } else {
            callback(false);
            dispatch(commonErrorHandler(res.errorDto.data.error));
          }
        }
      },
    );
  };
};

export const getSortedSymptomsWithSymptomObject = (symptoms, isCopy, symptomsDigestProcessed) => {
  let symptomsMap = symptoms.map(item => {
    let { id } = item;
    if (isCopy) {
      id = -1;
    }
    const symptomObj = symptomsDigestProcessed.userSymptoms[item.symptom];
    return {...item, id: id, symptomObj: symptomObj};
  });

  symptomsMap.sort((x, y) => nullableStringCompare(x?.symptomObj?.name, y?.symptomObj?.name));
  return symptomsMap;
};

export const getSortedMetricsWithSymptomObject = (metrics, isCopy, metricsDigestProcessed) => {
  let metricsMap = metrics.map(item => {
    let { id } = item;
    if (isCopy) {
      id = -1;
    }
    const metricRef = metricsDigestProcessed.selectableMetrics[item.metric];
    return { ...item, id: id, metricRef: metricRef };
  });

  metricsMap.sort((x, y) => nullableStringCompare(x?.metricRef?.name, y?.metricRef?.name))
  return metricsMap;
};

export const getSortedMedicationDosagesWithTreatmentObject = (dosages, isCopy, treatmentsProcessed) => {
  const filter = dosages.filter(res =>
    treatmentsProcessed[res.treatment]
      ? treatmentsProcessed[res.treatment].treatmentType !== 5
      : res?.treatmentType !== 5
        ? res
        : null,
  );

  return getSortedMedicationOrActivityDosagesWithTreatmentObject(filter, isCopy, treatmentsProcessed);
}

export const getSortedActivityDosagesWithTreatmentObject = (dosages, isCopy, treatmentsProcessed) => {
  const filter = dosages.filter(res =>
    treatmentsProcessed[res.treatment]
      ? treatmentsProcessed[res.treatment].treatmentType === 5
      : res?.treatmentType === 5
        ? res
        : null,
  );

  return getSortedMedicationOrActivityDosagesWithTreatmentObject(filter, isCopy, treatmentsProcessed);
}

export const getSortedMedicationOrActivityDosagesWithTreatmentObject = (dosages, isCopy, treatmentsProcessed) => {

  let treatmentMap = dosages.map((item, index) => {
    let { id, quantity } = item;
    if (isCopy) {
      id = -1;
    }
    const treatmentObj = treatmentsProcessed[item.treatment];
    return {
      ...item,
      accept: true,
      id: id,
      quantity: quantity,
      treatmentObj: treatmentObj,
    };
  });

  treatmentMap.sort((x, y) => nullableStringCompare(x?.treatmentObj?.name ? x?.treatmentObj?.name : x?.name, y?.treatmentObj?.name ? y?.treatmentObj?.name : y?.name));
  return treatmentMap;
};