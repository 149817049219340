import React from "react";
import { Text, View, TouchableOpacity, ScrollView, Image } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import Modal from "../Modal/modal";

const confirmationBox = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {
    visibleModal,
    onRequestClose,
    onBackdropPress,
    name,
    medications,
    count
  } = props;

  return (
    <Modal
      visible={visibleModal}
      onRequestClose={onRequestClose}
      onBackdropPress={onBackdropPress}
    >
      <View style={stylesRes.modalScrollMessageView} dataSet={{media: ids.modalScrollMessageView}}>
        <ScrollView style={styles.modalScroller}>
          <View style={[styles.popUpBlock, styles.bgWhite]}>
            <View style={styles.popUpContentArea}>
              <View style={styles.gridSectionPopup}>
                <View
                  style={[
                    styles.popUpContentPd,
                    styles.popUpBoxHeightMin,
                    //content end
                    styles.flexJcFe,
                    //content center
                    // styles.flexJcCt
                  ]}
                >
                  <View style={stylesRes.modalContIn}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.popMessageIconView, styles.comSp]}>
                          <Image
                            style={styles.deleteBasket}
                            source={require("../../assets/icons/delete-basket.png")}
                          />
                        </View>

                        <View
                          style={[styles.popMessageContView, styles.comSpSm]}
                        >
                          <React.Fragment>
                            <View
                              style={[
                                styles.popMessageTitleView,
                                styles.popupTitleMg,
                              ]}
                            >
                              <Text
                                style={[
                                  styles.textTitle,
                                  styles.textAlignCenter,
                                ]}
                                allowFontScaling={false}
                              >
                                <Text style={styles.bold} allowFontScaling={false}> Sorry, {name}{!medications ? ' with data' : ''} cannot be deleted</Text>
                                {Boolean(medications) ? (<Text style={styles.bold} allowFontScaling={false}> because it appears in {count} {count > 1 ? 'entries' : 'entry'} </Text>) : null}
                              </Text>
                            </View>
                          </React.Fragment>
                        </View>
                          <View
                            style={[
                              styles.popMessageDetailsView,
                              styles.comMgTopSm,
                            ]}
                          >
                          </View>
                        <View
                          style={[
                            styles.popBtnView,
                            styles.comMgTopMd,
                            styles.comSp,
                          ]}
                        >
                          <View style={styles.btnArea}>
                            <View
                              style={[
                                stylesRes.btnViewSec,
                                stylesRes.btnViewSecComNoPd,
                              ]}
                            >
                              <View style={styles.fieldBtn}>
                                <View style={styles.btnBlockFull}>
                                  <TouchableOpacity
                                    style={[
                                      styles.submitSm,
                                      styles.btnShadowCom,
                                      styles.bgBlue,
                                    ]}
                                    underlayColor="rgba(0, 153, 168, 0.8)"
                                    onPress={onBackdropPress}
                                  >
                                    <View style={styles.btnSingleView}>
                                      <View
                                        style={[
                                          styles.btnSingle,
                                          styles.btnSingleCent,
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            styles.submitBtnTextWhite,
                                            styles.bold,
                                          ]}
                                          allowFontScaling={false}
                                        >
                                          OK
                                        </Text>
                                      </View>
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              </View>
                              <View style={styles.fieldBtnSpSm} />
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};
export default (confirmationBox);
