import React from "react";
import { View } from "react-native";
import styles from "../../../../Css";
import CommonPopupMenu from "../../../../components/PopupMenu/commonPopupMenu";

const menuBlock = (props) => {
  const {
    onPressEdit,
    onPressDelete,
    nativeIdEdit,
  } = props;
  return (
    <View style={[styles.dataIconSecSm, styles.secCenter]}>
      <CommonPopupMenu
        handleDelete={onPressDelete}
        handleEdit={onPressEdit}
        nativeIdEdit={nativeIdEdit}
      />
    </View>
  );
};

export default menuBlock;
