import {StyleSheet} from 'react-native';
import theme from '../../css/base/theme';

export const THUMB_SLIDER_WIDTH = 26;
export const THUMB_SLIDER_CENTER_OFFSET = THUMB_SLIDER_WIDTH/2;

export const styles = StyleSheet.create({

  sliderContainerStyle: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent'
  },

  sliderScaleEndMarker: {
    paddingLeft: 10,
    paddingRight: 10
  },

  sliderTrackStyling: {
    borderRadius: 11,
    borderWidth: 0,
    height: 17
  },

  sliderThumbStylingWhenEnabled: {
    width: THUMB_SLIDER_WIDTH,
    height: THUMB_SLIDER_WIDTH,
    borderRadius: THUMB_SLIDER_CENTER_OFFSET,
    backgroundColor: '#0099A8'
  },

  sliderThumbCenterElementWhenEnabled: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: '#fff'
  },

  sliderThumbStylingWhenNotEnabled: {
    width: THUMB_SLIDER_WIDTH,
    height: THUMB_SLIDER_WIDTH,
    borderRadius: THUMB_SLIDER_CENTER_OFFSET,
    borderWidth: 1,
    borderColor: 'black',
    backgroundColor: '#fff'
  },

  sliderTitle: {
    fontWeight: '700',
    marginBottom: 12,
  },

  sliderBlockWrapper: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 10,
    height: 26
  },

  sliderBlockGradient: {
    position: 'absolute',
    top: 5,
    left: 0,
    width: '100%',
    borderRadius: 11,
    height: 16,
  },

  sliderIndexWrapper: {
    alignItems: 'center',
    //height: 'inherit',
    justifyContent: 'center',
    zIndex: 999,
  },

  sliderBlockButtonWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent',
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderWidth: 0.25,
    height: 17,
    flexGrow: 1
  },

  sliderBlockButtonWrapperGray: {
    backgroundColor: '#E2E2E2',
  },

  leftmostSliderSegment: {
    borderTopLeftRadius: 11,
    borderBottomLeftRadius: 11,
    borderLeftColor: 'transparent'
  },

  rightmostSliderSegment: {
    borderTopRightRadius: 11,
    borderBottomRightRadius: 11,
    borderRightColor: 'transparent'
  },

  arrowHead: {
    bottom: -22,
    height: 19,
    width: 21,
  },

  indexText: {
    fontWeight: '700',
    fontSize: theme.FONT_SIZE_X_SM
  },

  scaleEndsWrapper: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    position: 'absolute',
    paddingRight: 10,
    paddingLeft: 10,
  },

  tooltipBubblePosition: {
    position: 'absolute',
    bottom: 16,
    justifyContent: 'center',
    alignItems: 'center'
  },

  tooltipWrapper: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 35,
    zIndex: 9,
  },

  tooltipTextWrapper: {
    backgroundColor: '#fff',
    bottom: -22,
    width: 120,
    minWidth: 80,
    padding: 5,
    shadowColor: "#A3C5FE",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    textAlign: 'center',
    elevation: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },

  sliderOurtWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 5,
    paddingTop: 5,
  },

  textBox: {
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
    flexBasis: 42,
    height: 42,
    textAlign: 'center',
  },

  textBoxtext: {
    fontSize: theme.FONT_SIZE_X_LG,
    fontWeight: '700',
  },

});
