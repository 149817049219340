import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';

const SelectCategoryPopUp = (props) => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  const {menuItems, menuItemPress} = props;
  return (
    <React.Fragment>
      <View
        style={[
          stylesRes.addEventSidePopup,
          stylesRes.btnMaxZindex,
          styles.twentyRgt,
          styles.overlayFixed
        ]}>
        <View>
          <TouchableOpacity disabled>
            <View style={styles.sidePopUpSltCtg}>
              <Text style={[styles.textTitleBold, styles.textWhite]}>
                Select a Category:
              </Text>
            </View>
          </TouchableOpacity>
          <View style={styles.addEventSidePopUpWd}>
          {menuItems &&
            menuItems.length > 0 &&
            menuItems.map(item => (
              <TouchableOpacity 
                onPress={() => {
                  item.onPress();
                  menuItemPress();
                }} 
                key={item.title}>
                <View style={styles.sidePopUpCtg}>
                  <Text style={[styles.textTitle, styles.textColor]}>
                    {item.title}
                  </Text>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};
export default SelectCategoryPopUp;
