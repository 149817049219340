import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import Collapsible from '../../Collapsible/Collapsible';
import Add from './Add';
import Daily from './Edit/Daily';
import Monthly from './Edit/Monthly';
import Weekly from './Edit/Weekly';
import {getSortedReminderList} from '../../../util/commonUiLogic';
class Reminder extends Component {
  constructor(props) {
    super(props);
    const { reminders } = this.props;
    this.state = {
      visibleReminder: reminders && reminders.length > 0,
      reminders: getSortedReminderList(reminders),
      hasActiveReminders: this.getHasActiveReminders(reminders),
      remWait: false,
      pushNotification: reminders && reminders.length > 0 ? reminders[0].pushNotification : true,
      emailNotification: reminders && reminders.length > 0 ? reminders[0].emailNotification : false,
      showRadioForm: false,
      showNotificationCheckBox: false
    };
    this.expiredReminderPosition = [];
    this.foundExpiredOne = reminders && reminders.length > 0 ? reminders.length + 2 - 1 : 2;
  }

  componentDidMount() {
    this.refUpdate();
  }

  refUpdate = () => {
    const {getRef} = this.props;
    getRef && getRef(this);
  };

  getHasActiveReminders = reminders => {
    let has = false;
    reminders.forEach(element => {
      if (element.reminder) {
        has = true;
      }
    });

    return has;
  };

  setExpiredReminderPosition = (position) => {
    this.expiredReminderPosition.push(position);
  }

  getExpiredReminderPosition = () => {
    return getSortedReminderList(this.expiredReminderPosition).filter((element, index) => index <= this.foundExpiredOne);
  }

  getItem = (item, index) => {
    if (this.state.pushNotification || this.state.emailNotification) {
      if (item.id == this.props.reminderId) {
        this.foundExpiredOne = index;
      }
      if (item.reminderEveryday) {
        return (
          <Daily
            data={item}
            index={index}
            key={'rem_' + index}
            updateReminders={this.updateReminders}
            deleteItem={this.deleteItem}
            windowWidth={this.props.windowWidth}
            reminderSection={this.props.reminderSection}
            isExpiredOne={item.id == this.props.reminderId}
            setExpiredReminderPosition={this.setExpiredReminderPosition}
          />
        );
      } else if (item.reminderMonthly) {
        return (
          <Monthly
            data={item}
            index={index}
            key={'rem_' + index}
            updateReminders={this.updateReminders}
            deleteItem={this.deleteItem}
            windowWidth={this.props.windowWidth}
            reminderSection={this.props.reminderSection}
            isExpiredOne={item.id == this.props.reminderId}
            setExpiredReminderPosition={this.setExpiredReminderPosition}
            routineWizardReminderCont={false}
          />
        );
      } else {
        return (
          <Weekly
            data={item}
            index={index}
            key={'rem_' + index}
            updateReminders={this.updateReminders}
            deleteItem={this.deleteItem}
            windowWidth={this.props.windowWidth}
            reminderSection={this.props.reminderSection}
            isExpiredOne={item.id == this.props.reminderId}
            setExpiredReminderPosition={this.setExpiredReminderPosition}
            routineWizardReminderCont={false}
          />
        );
      }

    }

  };

  updateReminders = (index, reminder) => {
    const { updateReminders } = this.props;
    const { reminders } = this.state;
    reminders[index] = reminder;
    this.setState({ reminders: reminders }, () => updateReminders(reminders));
  };

  deleteItem = index => {
    this.setState({ remWait: true }, () => {
      const { updateReminders } = this.props;
      let { reminders } = this.state;
      delete reminders[index];
      reminders = reminders.filter(item => item);
      this.setState({ reminders: reminders, remWait: false }, () =>
        updateReminders(reminders),
      );
    });
  };

  pushNotificationSelection = () => {
    this.setState({
      pushNotification: !this.state.pushNotification
    }, () => {
      this.props.handleNotificationStatus(this.state.pushNotification, this.state.emailNotification);

    });
  }

  emailNotificationSelection = () => {
    this.setState({
      emailNotification: !this.state.emailNotification
    }, () => {
      this.props.handleNotificationStatus(this.state.pushNotification, this.state.emailNotification);
    });
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const { plusButton, updateNewReminders, newReminders } = this.props;

    const { hasActiveReminders, reminders, remWait, showRadioForm, showNotificationCheckBox } = this.state;
    return (
      <Collapsible
        title="Reminder"
        contentVisibility={this.state.visibleReminder}
        handleOnPressCollapsible={() =>
          this.setState({
            visibleReminder: !this.state.visibleReminder,
          })
        }
        style={styles.collapseTitle}
        customTitleStyle={true}
        plusButton={plusButton}
        topBorder={true}>

        {showNotificationCheckBox || hasActiveReminders ? (
          <View style={[styles.colPdLtRtBot, styles.bgWhite]}>
            <View style={styles.flexRow}>
              <View style={styles.answerSingleSm}>
                <TouchableOpacity
                  style={[
                    styles.answerOptMgBot,
                    styles.answerViewWrapper,
                  ]}
                  onPress={this.pushNotificationSelection}
                >
                  <View style={styles.answerView}>
                    <Image
                      style={[
                        styles.radioIcon,
                        styles.radioOptSp,
                      ]}
                      source={
                        this.state.pushNotification ? require('../../../assets/toggleTick/check-tick.png') : require('../../../assets/toggleTick/check-untick.png')
                      }
                    />
                    <Text style={styles.textPrime} allowFontScaling={false}>
                      Push notification
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
              <View style={styles.answerSingleSpace}></View>
              <View style={styles.answerSingleSm}>
                <TouchableOpacity
                  style={[
                    styles.answerOptMgBot,
                    styles.answerViewWrapper,
                  ]}
                  onPress={this.emailNotificationSelection}
                >
                  <View style={styles.answerView}>
                    <Image
                      style={[
                        styles.radioIcon,
                        styles.radioOptSp,
                      ]}
                      source={
                        this.state.emailNotification ? require('../../../assets/toggleTick/check-tick.png') : require('../../../assets/toggleTick/check-untick.png')
                      }
                    />
                    <Text style={styles.textPrime} allowFontScaling={false}>
                      Email notification
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ): null}
        

       
        <View style={[styles.collapseContent, styles.colPd, styles.bgThinGrey]}>
          {/* {hasActiveReminders && (
            <View css={[styles.contentMaxWidth, stylesRes.reminderContentSection]}>
              <View style={[styles.titleSection, styles.comMgBotSm]}>
                {(this.state.pushNotification || this.state.emailNotification) ? (
                  <Text style={[styles.textPrime, styles.textColorGreyLight]} allowFontScaling={false}>
                    Notifications are sent based on your timezone
                  </Text>
                ) : null}
              </View>
            </View>
          )} */}
          {!remWait
            ? reminders.map((element, index) => this.getItem(element, index))
            : null}

          {(this.state.pushNotification || this.state.emailNotification) ?
            <Add
              updateNewReminders={updateNewReminders}
              newReminders={newReminders}
              windowWidth={this.props.windowWidth}
              reminderSection={this.props.reminderSection}
              remindersCount={reminders.length}
              scroll={this.props.scroll}
              hasActiveReminders={hasActiveReminders}
              setShowRadioForm={(showRadioForm) => {
                this.setState({
                  showRadioForm: showRadioForm
                })
              }}
              showRadioForm={showRadioForm}
            /> : null}
            
        {!showRadioForm && (
            <View style={stylesRes.routineReminderContent} dataSet={{media: ids.routineReminderContent}}> 
              <TouchableOpacity
                style={styles.addReminderButton}
                onPress={() => this.setState({ showRadioForm: true, showNotificationCheckBox: true })}
              >
                <Image
                  style={styles.plusIcon}
                  source={require("../../../assets/icons/plus-icon-gray.png")}
                />
                <Text style={[styles.primarySmBold, styles.addAReminderTxtColor]}>
                  Add a Reminder
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </Collapsible>
    );
  }
}

export default (Reminder);
