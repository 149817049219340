import React from 'react';
import {UserImage} from '.';
import CaregiverDetailCommon from "./CaregiverDetailCommon";
class CaregiverDetail extends CaregiverDetailCommon {
  imageLoadingError() {
    this.setState({imageLoading: false});
  }

  render() {
    const {imageLoading, uri, name} = this.state;
    const {myName} = this.props;

    return (
      <UserImage
        imageLoading={imageLoading}
        uri={uri}
        name={name}
        onError={this.imageLoadingError.bind(this)}
        changeJournalUser={this.props.changeJournalUser}
        myName={myName}
      />
    );
  }
}

export default (CaregiverDetail);
