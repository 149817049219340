import React from "react";
import { View, Text } from "react-native";
import styles from "../../../Css";

const dateItem = (props) => {
  const { 
    windowWidth,
    isNewText,
    date,
  } = props;
  return (
    <>
      <View style={styles.itemColBtnSp} />
      <View style={[styles.itemColBtn]}>
        <View style={[styles.flexJcCt, styles.flexAiFe]}>
          <Text
            style={[
              windowWidth > 1366 ? styles.textPrime : styles.textPrimeSm,
              styles.textAlignRight,
              isNewText 
                ? styles.statusBarText
                : styles.defaultText
            ]}
            allowFontScaling= {false}
          >
            {date}
          </Text>
        </View>
      </View>
    </>
  );
};

export default (dateItem);
