import React from "react";
import { Text, Image, View } from "react-native";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from "react-native-popup-menu";
import styles from "../../Css";
import { pendoTrackEvent } from "../../util/pendoConfig";

const { Popover } = renderers;
const medicationsOrTherapiesPopupMenu = (props) => {
  
  return (
    <Menu renderer={Popover} rendererProps={{ preferredPlacement: "left" }}>
      <MenuTrigger onPress={() => pendoTrackEvent(props.eventNameMenuTrigger)}>
        <View 
          style={[styles.itemIconSingle, styles.itemSettingMenu]}
          nativeID={props.nativeIdMenuTrigger}>
          <Image
            style={[styles.settingIcon, styles.settingIconDark]}
            source={require("../../assets/icons/settings.png")}
          />
        </View>
      </MenuTrigger>
      <MenuOptions style={styles.dropDownList}>
        <View style={{padding: 1}} nativeID={props.nativeIdEdit}>
        <MenuOption style={styles.liItem} onSelect={props.handleEditTherapie}>
          <Text style={styles.listText} allowFontScaling= {false}>Edit</Text>
        </MenuOption>
        </View>
        <MenuOption
          style={[
            styles.liItem,
            props.hasRecordings &&
            styles.submitBgGrey
          ]}
          onSelect={props.handleDeleteTherapieConfirmation}
        >
          <Text style={styles.listText} allowFontScaling= {false}>Delete</Text>
        </MenuOption>
      </MenuOptions>
    </Menu>
  );
};
export default medicationsOrTherapiesPopupMenu;
