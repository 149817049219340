import React from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import NoGraphData from "../Dashboard/GraphSection/noGraphData";
import GraphCommon from "../GraphCommon/GraphCommon";
import styles from "../../Css";
import * as actions from "../../store/actions/index";
import { getGraphHashValue } from '../../util/commonUiLogic';

class Graph extends GraphCommon {
  constructor(props) {
    super(props);
    const {symptoms, metrics, therapies, startDate, endDate, symptomProps, metricProps, therapyProps, windowHeight, windowWidth} = props;
    const hash = getGraphHashValue(therapies, metrics, symptoms, startDate, endDate, therapyProps, metricProps, symptomProps);;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      hash: hash,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      symptoms,
      metrics,
      therapies,
      startDate,
      endDate,
      symptomProps,
      metricProps,
      therapyProps,
      windowHeight,
      windowWidth,
    } = props;
    const changeDasValue = getGraphHashValue(therapies, metrics, symptoms, startDate, endDate, therapyProps, metricProps, symptomProps);;
    if (state.hash !== changeDasValue && state.didMount) {
      const chartData = Graph.drawGraph(symptoms, metrics, therapies, startDate, endDate, props);
      return {
        therapies: therapies,
        metrics: metrics,
        symptoms: symptoms,
        therapyProps: therapyProps,
        metricProps: metricProps,
        symptomProps: symptomProps,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        hash: changeDasValue,
        chartData: chartData
      };
    }

    return null;
  }

  render() {
    const { chartData } = this.state;
    return (
      <View
        style={[
          styles.graphAreaWrapper,
          {
            height: "353px",
            borderColor: "rgba(152, 152, 154, 0.4)",
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 4,
            padding: 1,
          },
        ]}
      >
        <div
          id={this.props.graphDivId}
          style={{
            width: "100%",
            height: "351px",
          }}
        />
        {(!Boolean(chartData) || Boolean(chartData && chartData.length === 0)) &&
          <View
            style={[
              styles.noGraphWrapper,
              styles.flexJcCtAiCt,
              {
                height: "351px",
                borderRadius: 4,
              },
            ]}
          >
            <NoGraphData initGraph={!this.hasSelectedData()} />
          </View>
        }
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    symptomsDigestProcessed: state.routines.symptomsDigestProcessed,
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    userDefinedMetricUnitOfMeasures:
      state.routines.metricsDigest.userDefinedMetricUnitOfMeasures,
    systemDefinedMetricUnitOfMeasures:
      state.routines.metricsDigest.systemDefinedMetricUnitOfMeasures,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    symptoms: state.report.graph.symptoms,
    metrics: state.report.graph.metrics,
    therapies: state.report.graph.therapies,
    startDate: state.report.startDate,
    endDate: state.report.endDate,
    graphImageS3: state.report.graphImageS3,
    userId: state.routines.user.id,
    symptomProps: state.report.graph.symptomProps,
    metricProps: state.report.graph.metricProps,
    therapyProps: state.report.graph.therapyProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGraphImage: (graphImageS3Id, image) =>
      dispatch(actions.setGraphImage(graphImageS3Id, image)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Graph);
