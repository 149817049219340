import React, {Component} from 'react';
import {View} from 'react-native';
import styles from '../../../../Css';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextFrame from '../../../TextInput/textFrame';
import ItemDeleteButton from '../../../Button/itemDeleteButton';

class UserItemView extends Component {
  constructor(props) {
    super(props);

    let {data, userSymptomsProcessed} = props;
    let {symptom} = data;
    const userSymptoms = userSymptomsProcessed[symptom];
    const {displayProperties} = userSymptoms;
    const {scaleMin, scaleMax} = displayProperties;
    let sliderYesNo = scaleMin === 0 && scaleMax === 1 ? true : false;

    this.state = {
      sliderYesNo: sliderYesNo,
    };
  }


  render() {
    const {sliderYesNo} = this.state;
    let {data, userSymptomsProcessed, deleteItem, index} = this.props;
    let {symptom, symptomObj: {publishedBy} = {}} = data;
    const userSymptom = userSymptomsProcessed[symptom];
    const {name} = userSymptom;
    return (
      <>
      <View style={[styles.fieldContainer]}>
        <View style={[styles.fieldRowWrapper]}>
          <View style={styles.fieldColStretch}>
            <TextFrame
              title={'Symptom'}
              fieldBg={FieldDetails.BGGREY}
              fieldValue={name}
              fleldUnique={true}
              editable={false}
              noOfLines={1}
            />
          </View>
          <View style={styles.fieldColSp} />
          <View style={styles.fieldColMeasure}>
            <TextFrame
              title={'Measure'}
              fieldBg={FieldDetails.BGGREY}
              fieldValue={sliderYesNo ? 'Yes/No' : '0 to 10'}
              editable={false}
            />
          </View>
          <View style={styles.fieldColSp} />
            <View style={styles.fieldColDelete}>
            {publishedBy !== 'PARTNER' && (
              <ItemDeleteButton touchableOnPress={() => deleteItem(index)} />
            )}  
            </View>
        </View>
      </View>
      <View style={[styles.secSepBorderBot, styles.comSp]}></View>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    hasInvalidNamesInActivity: state.therapie.hasActivityInvalidName,
    hasInvalidNamesInMedication: state.therapie.hasMedicationInvalidName,
    hasInvalidNamesInHealthData: state.therapie.hasHealthDataInvalidName,
    hasInvalidNamesInSymtoms: state.therapie.hasSymptomsInvalidName,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createUserSymptom: (userSymptom, callback, journalUserId) =>
      dispatch(actions.createUserSymptom(userSymptom, callback, journalUserId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserItemView);
