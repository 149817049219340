import React from 'react';
import { View, Text, TouchableOpacity, Image, } from 'react-native';
import styles from '../../../../Css';
import stylesResponsive from "../../../../CssResponsive";
import { visibleType } from '../../../../screens/PeerToPeer/MainSection/Profile/Constraints';

const ProfileCommonBiosView = (props) => {
    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    const { commonItemList, showList, valueTypeKey } = props;

    const renderPublicOrPrivateItem = (isPublic = false, list = []) => {
        return (
            <View style={[styles.userNameContainer, styles.publicBtnSec, stylesRes.publicBtnSecRes, stylesRes.userNameRes, styles.fieldColThreeFourth]} dataSet={{media:ids.mergestyle}}>
                <View style={[styles.flexRowAiCt, !Boolean(isPublic) && styles.marginTopText, styles.publicTextProfile]}>
                    <Image
                        style={Boolean(isPublic) ? styles.showIcon : styles.hideIcon}
                        source={Boolean(isPublic)
                            ?   require('../../../../assets/icons/pw-show-blue.png')
                            :   require('../../../../assets/icons/pw-hide-blue.png')}
                    />
                    <Text style={[styles.textPrime, styles.textWeightFiveHundred, stylesRes.mobileText]} dataSet={{media: ids.mobileText}}>
                        {Boolean(isPublic) ? 'Shown to Public' : 'Private to You'}
                    </Text>
                </View>
                {list && list.length > 0 ? (
                    <View style={[styles.flexRow, styles.flexWrapView]}>
                        {list.map((e) => {
                            let valueType = e[valueTypeKey];
                            return (
                                <TouchableOpacity disabled={true} style={[styles.publicBtn, stylesRes.publicBtnMobile]} dataSet={{media: ids.publicBtnMobile}}>
                                    <Text style={[showList ? styles.marginLeftText : styles.publicText, stylesRes.MobileTextPublic, !Boolean(isPublic) && styles.privateBtn]} dataSet={{media: ids.MobileTextPublic}}>{`${valueType?.charAt(0) + valueType?.slice(1)?.toLowerCase()} : ${e.value  ? e.value : 'None'}`}</Text>
                                </TouchableOpacity>
                            )
                        })}
                    </View>
                ) : (
                    <View style={[styles.flexRow, styles.flexWrapView]}>
                        <TouchableOpacity disabled={true} style={[styles.publicBtn , stylesRes.publicBtnMobile]} dataSet={{media: ids.publicBtnMobile}}>
                            <Text style={[showList ? styles.marginLeftText : styles.publicText, stylesRes.mobileText, styles.privateBtn]} dataSet={{media: ids.mobileText}}>None</Text>
                        </TouchableOpacity>   
                    </View>
                )}
            </View>
        )
    }
    return (
        <View>
            {/* PUBLIC details */}
            {renderPublicOrPrivateItem(true, commonItemList && commonItemList.length > 0 && commonItemList.filter(e => e.visibility === visibleType.PUBLIC))}
            {/* PRIVATE details */}
            {renderPublicOrPrivateItem(false, commonItemList && commonItemList.length > 0 && commonItemList.filter(e => e.visibility !== visibleType.PUBLIC))}
        </View>
    );
};

export default ProfileCommonBiosView;
