import React, {Component} from "react";
import {View, TouchableOpacity, Text} from "react-native";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive"
import {connect} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import ActivityIndicator from "../../../components/ActivityIndicator/activityIndicator";
import MyResourcesHeader from "./MyResourcesHeader";
import PageContainer from "../../../components/PageLayout/PageContainer";
import ResourcesListItem from "./ResourcesListItem";
import { getScrollHeight } from '../../../util/commonUiLogic';
class ResourcesList extends Component {
  constructor(props) {
    super(props);
    const items =
      this.props.resourcesList && this.props.resourcesList.length > 0
        ? Array.from(this.props.resourcesList.filter(item => !item.hidden).slice(0, 19))
        : [];
    const hiddenItems =
      this.props.resourcesList && this.props.resourcesList.length > 0
        ? Array.from(this.props.resourcesList.filter(item => item.hidden).slice(0, 19))
        : [];
    let hasMore = true;
    if (items.length < 19) {
      hasMore = false;
    }
    let hasMoreHiddenData = true;
    if (hiddenItems.length < 19) {
      hasMoreHiddenData = false;
    }
    this.state = {
      finalResources:
        this.props.resourcesList && this.props.resourcesList.length > 0
          ? this.props.resourcesList.filter(item => !item.hidden)
          : [],
      finalResourcesHidden:
        this.props.resourcesList && this.props.resourcesList.length > 0
          ? this.props.resourcesList.filter(item => item.hidden)
          : [],
      userId: this.props.user.id.toString(),
      items: items,
      hiddenItems: hiddenItems,
      hasMore: hasMore,
      hasMoreHiddenData: hasMoreHiddenData,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      resourcesCount: props.resourcesCount ? props.resourcesCount : 0,
      show: false,
    };
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    const {windowHeight, windowWidth, resourcesList} = nextProps;
    const items =
      resourcesList && resourcesList.length > 0
        ? Array.from(resourcesList.filter(item => !item.hidden).slice(0, 19))
        : [];
    const hiddenItems =
      resourcesList && resourcesList.length > 0
        ? Array.from(resourcesList.filter(item => item.hidden).slice(0, 19))
        : [];
    let hasMore = true;
    if (items.length < 19) {
      hasMore = false;
    }
    let hasMoreHiddenData = true;
    if (hiddenItems.length < 19) {
      hasMoreHiddenData = false;
    }
    this.setState({
      finalResources:
        resourcesList && resourcesList.length > 0 ? resourcesList.filter(item => !item.hidden) : [],
      finalResourcesHidden:
        resourcesList && resourcesList.length > 0 ? resourcesList.filter(item => item.hidden) : [],
      items: items,
      hiddenItems: hiddenItems,
      hasMore: hasMore,
      hasMoreHiddenData: hasMoreHiddenData,
      resourcesCount: resourcesList && resourcesList.length ? resourcesList.length : 0,
      windowWidth,
      windowHeight
    });
  };

  fetchMoreData = () => {
    if (this.state.items.length >= this.state.finalResources.length) {
      this.setState({hasMore: false});
      return;
    } else {
      const items = this.state.items.concat(
        this.state.finalResources.slice(
          this.state.items.length,
          this.state.items.length + 19
        )
      );

      if (items.length > this.state.items.length) {
        setTimeout(() => {
          this.setState({
            items: items
          });
        }, 1500);
      }
    }
  };

  fetchMoreHiddenData = () => {
    if (this.state.hiddenItems.length >= this.state.finalResourcesHidden.length) {
      this.setState({hasMoreHiddenData: false});
      return;
    } else {
      const items = this.state.hiddenItems.concat(
        this.state.finalResourcesHidden.slice(
          this.state.hiddenItems.length,
          this.state.hiddenItems.length + 19
        )
      );

      if (items.length > this.state.hiddenItems.length) {
        setTimeout(() => {
          this.setState({
            hiddenItems: items
          });
        }, 1500);
      }
    }
  };

  setResourcesVisibility = () => {
    this.setState(prevState => ({show: !prevState.show}))
  }

  render() {
    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    const { journalUserId, timeZoneWarning, timeZone } = this.props;
    const { windowHeight, windowWidth} = this.state;
    let tabHeight = 44;
    let scrollHeight =  getScrollHeight(false, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth, tabHeight);
    
    return (
      <View style={styles.scrollableWrapper}>
        <div
          id="scrollableDiv"
          style={{height: scrollHeight, overflowY: "auto"}}
        >
          <MyResourcesHeader
            resourcesCount={this.state.resourcesCount}
            handleSearchMyResourceInputValueChange={
              this.props.handleSearchMyResourceInputValueChange
            }
            handleResourceModalShow={this.props.handleResourceModalShow}
            keywords={this.props.keywords}
            emailVerified={this.props.emailVerified}
            newResourcesCount={this.props.newResourcesCount}
          />
          <PageContainer smScreenFull={true}>
            {!this.state.finalResources ? (
              <ActivityIndicator/>
            ) : (
              <>
                <InfiniteScroll
                  dataLength={this.state.items.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.hasMore}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                >
                  <View
                    style={[
                      styles.communityList,
                      // styles.greyBorderThinTop,
                      styles.boxFull
                    ]}
                  >
                    {this.state.items.map((resource, index) => (
                      <ResourcesListItem
                        resource={resource}
                        navigation={this.props.navigation}
                        activeTab={tagSearchResultsArr => {
                          this.props.activeTab(tagSearchResultsArr);
                        }}
                        fetchFilteredResourceList={this.props.fetchFilteredResourceList}
                        handleOnPressShowHide={this.props.handleOnPressShowHide}
                        handleOnTapNewResource={this.props.handleOnTapNewResource}
                        key={index}
                      />
                    ))}
                  </View>
                </InfiniteScroll>
                {this.state.hiddenItems.length > 0 && (
                  <View>
                    <View style={stylesRes.resourceBoxMgCr} dataSet={{media: ids.resourceBoxMgCr}}>
                      <View style={[styles.flexJcSb, styles.flexRow, styles.viewMoreDataTopMg]}>
                        <Text
                          style={[styles.textPrimeBold]}>{`${this.state.hiddenItems.length} Resources Hidden`}</Text>
                        <TouchableOpacity onPress={this.setResourcesVisibility}>
                          <Text style={[styles.linkText]}>{this.state.show ? "Hide" : "Show"}</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                    {this.state.show && (
                      <InfiniteScroll
                        dataLength={this.state.hiddenItems.length}
                        next={this.fetchMoreHiddenData}
                        hasMore={this.state.hasMoreHiddenData}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget="scrollableDiv"
                      >
                        <View
                          style={[
                            styles.communityList,
                            // styles.greyBorderThinTop,
                            styles.boxFull
                          ]}
                        >
                          {this.state.hiddenItems.map((resource, index) => (
                            <ResourcesListItem
                              resource={resource}
                              navigation={this.props.navigation}
                              activeTab={tagSearchResultsArr => {
                                this.props.activeTab(tagSearchResultsArr);
                              }}
                              key={index}
                              fetchFilteredResourceList={this.props.fetchFilteredResourceList}
                              handleOnPressShowHide={this.props.handleOnPressShowHide}
                              handleOnTapNewResource={this.props.handleOnTapNewResource}
                            />
                          ))}
                        </View>
                      </InfiniteScroll>
                    )}
                  </View>
                )}
              </>
            )}
            <View style={{height: 80}}></View>
          </PageContainer>
        </div>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    user: state.routines.user,
    journalUserId: state.uistate.journalUserId,
    emailVerified: state.auth.emailVerified,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};

export default connect(mapStateToProps, null)(ResourcesList);
