import React, {Component} from 'react';
import {View, Text} from 'react-native';
import styles from '../../../../../Css';
import Item from './item';

class RoutineContent extends Component {
  constructor(props) {
    super(props);
    const {itemList, selectedItem} = props;
    this.state = {
      itemList: itemList.filter(res => res.id !== -1),
      selectedItem: selectedItem,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {itemList, selectedItem} = props;
    return {itemList: itemList.filter(res => res.id !== -1), selectedItem: selectedItem};
  }

  onSelect = id => {
    const {selectedItem} = this.state;
    if (selectedItem[id]) {
      delete selectedItem[id];
    } else {
      selectedItem[id] = id;
    }

    this.setState({selectedItem: selectedItem});
    const {updateDefaultValue} = this.props;
    updateDefaultValue(selectedItem);
  };

  render() {
    const {title} = this.props;
    const {itemList, selectedItem} = this.state;
    return (
      <View style={styles.sectionView}>
      {itemList && itemList.length > 0 &&
        <View style={[styles.questionAnswerBlock]}>
          <View style={[styles.questionWrapper]}>
            <Text style={[styles.textPrimeBold]} allowFontScaling={false}>{title}</Text>
          </View>

          <View style={[styles.answerWrapper]}>
            {/* {itemList.map(
              res =>
                res.id !== -1 && ( */}
            {itemList.map(res =>
              <Item
                data={res}
                isSlected={selectedItem[res.id] ? true : false}
                onSelect={this.onSelect}
              />
            )}
          </View>
        </View>
      }
      </View>
    );
  }
}

export default (RoutineContent);
