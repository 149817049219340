import theme from './base/theme';
import {Platform} from 'react-native';
import * as variable from './base/variable';
import * as variableShadow from './base/variableShadow';
import * as variableHeight from './base/variableHeight';
export const cssCommon = {
  topImgSecCom: {
    position: 'relative',
  },
  customBorderSec: {
    width: 20,
    height: '100%',
    position: 'absolute',
    top: 0,
    borderTopRightRadius: 10,
  },
  topicFullBlock: {
    position: 'relative',
  },
  activityNameBlock: {
    width: '60%',
  },
  circleSmScreen: {
    width: 22,
    height: 22,
    top: -1,
  },
  
  maxWidthContent: {
    minWidth: 50,
    width: '100%',
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 20,
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_3,
  },
  contCol: {
    width: '50%',
  },
  contColFul: {
    width: '100%',
  },
  fieldColHalf: {
    width: '50%',
  },
  fieldColOneThird: {
    width: '33%',
  },
  fieldColLgView: {
    width: '60%',
  },
  fieldColSmView: {
    width: '40%',
  },

  sortItemSingle: {
    borderColor: theme.COLOR_BORDER,
    borderWidth: 1,
    borderRadius: 30,
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 10,
    marginRight: 8,
  },
  imgView: {
    position: 'absolute',
    display: 'flex',
    right: 10,
    zIndex: 99,
  },
  imgViewcal: {
    bottom: 10,
  },
  imgViewSearch: {
    top: 15,
    zIndex: 999,
  },
  zIndexHigh: {
    zIndex: 99,
  },
  messageDetailCont: {
    width: '100%',
  },
  statusTextWebText: {
    maxWidth: 250,
  },
  medSecImgBgView: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  boxHeightMd: {
    height: 80,
  },
  mainSmTitleBlock: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  mainSmTitleBlockMd: {
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
  },
  fieldBtnSp: {
    width: 20,
  },
  listItemCloseSecSm: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 999,
  },
  listItemCom: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  smRad: {
    borderRadius: 5,
  },
  fileUploadBtn: {
    opacity: 0,
  },
  upImgSec: {
    flex: 1,
  },
  fieldColCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  fullHeight: {
    height: '100%',
  },
  questionTitleViewSm: {
    paddingBottom: 15,
    paddingTop: 15,
    maxWidth: '100%',
  },
  commentsListItemMoreBtnSec: {
    marginTop: 15,
    marginBottom: 15,
    position: 'relative',
  },
  actIndRight: {
    position: 'absolute',
    left: 40,
  },
  actIndTop: {
    position: 'absolute',
    top: -40,
  },
  actIndSm: {
    position: 'absolute',
    left: -10,
    margin: -10,
  },
  actIndView: {
    position: 'absolute',
    left: -20,
    margin: -10,
  },
  zIndexMax: {
    zIndex: 99999,
  },
  zIndexDbMax: {
    zIndex: 9999999,
  },
  zIndexMin: {
    zIndex: 9,
  },
  actIndCom: {
    position: 'absolute',
    left: 0,
    margin: 0,
  },
  indLeftMd: {
    left: -20,
  },
  indLeftSm: {
    left: -5,
  },
  actIndCenter: {
    position: 'absolute',
    left: 5,
    top: 0,
    bottom: 0,
  },
  actIndCenterLg: {
    position: 'absolute',
    left: 10,
    top: 0,
    bottom: 0,
  },
  actIndCenterView: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
  },
  actIndLeftView: {
    position: 'absolute',
    left: -4,
    top: 0,
    bottom: 0,
    zIndex: 9,
  },
  fieldInRow: {
    position: 'relative',
  },
  imgViewcalLg: {
    bottom: 15,
  },
  imgViewcalSm: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  listItemCloseCenter: {
    position: 'absolute',
    right: 20,
    top: 0,
    zIndex: 999,
    height: '100%',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actIndLogin: {
    position: 'absolute',
    left: -40,
  },
  actIndLg: {
    position: 'absolute',
    left: -35,
  },
  linearGradient: {
    borderRadius: 50,
    marginBottom: 10,
  },
  toolTipData: {
    zIndex: 99,
    marginTop: 6,
  },
  errorBar: {
    position: 'absolute',
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
    paddingRight: 10,
    backgroundColor: theme.COLOR_ERROR_BG,
    width: '100%',
    zIndex: 99,
    borderColor: theme.COLOR_ERROR_BORDER,
    borderWidth: 1,
  },
  highZindex: {
    zIndex: 999999,
  },
  textListIcon: {
    width: 5,
    height: 5,
    borderRadius: 5,
    marginRight: 5,
    backgroundColor: theme.COLOR_ORANGE,
    marginTop: 7,
  },
  textListIconCom: {
    backgroundColor: theme.PRIMARY_FONT_COLOR,
  },
  fledSpRt: {
    marginRight: 10,
  },
  fledSpLt: {
    marginLeft: 10,
  },
  fledSpLtRt: {
    marginRight: 5,
    marginLeft: 5,
  },
  comColHalf: {
    width: '50%',
  },
  profileBannerBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
  },
  profileBannerSec: {
    position: 'relative',
  },
  oneThird: {
    width: '34%',
  },
  twoThirds: {
    width: '66%',
  },
  premBannerBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
  },
  bgPremHeight: {
    minHeight: 275,
  },
  premTextArea: {
    width: '100%',
  },
  premBoxData: {
    width: '100%',
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 3,
    paddingRight: 3,
    backgroundColor: theme.COLOR_WHITE,
    flex: 1,
    borderWidth: 7,
    borderColor: theme.COLOR_WHITE,
  },
  premSelected: {
    borderColor: theme.COLOR_GREEN_DARK,
  },
  twoDigit: {
    left: 0,
  },
  premOrText: {
    width: 64,
    position: 'absolute',
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewMainData: {
    width: '100%',
  },
  listEmailSingle: {
    borderColor: theme.COLOR_GREY_LIGHT_MD,
    borderWidth: 1,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 10,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
    marginBottom: 5,
    flexDirection: 'row',
    alignItems: 'flex-start',
    maxWidth: '98%',
  },
  borderRadSm: {
    borderRadius: 5,
  },
  clearBox: {
    maxWidth: 80,
  },
  clearBoxLg: {
    maxWidth: 120,
  },
  careBannerBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
  },
  bgHeight: {
    minHeight: 135,
  },
  careBannerSec: {
    position: 'relative',
  },
  upBtnHeight: {
    height: 51,
  },
  upBtnHeightSm: {
    height: 45,
  },
  uploadViewFull: {
    marginBottom: 15,
    width: '100%',
  },
  preDataSign: {
    position: 'absolute',
    top: 0,
  },
  dropDownList: {
    ...variableShadow.shadowStyleOne,
  },
  barShadow: {
    ...variableShadow.shadowStyleFour,
  },
  premShadow: {
    ...variableShadow.shadowStyleTwo,
  },
  menuItemView: {
    borderRadius: 5,
    // ...variableShadow.shadowStyleFour,
    ...variableShadow.itemBoxShadow,
  },
  menuBlockOpenView: {
    borderRadius: 5,
    ...variableShadow.shadowStyleFour,
  },
  weeklyBtnShadowRoutine: {
    ...variableShadow.noteBoxShadow,
  },
  menuOpenBlock: {
    marginTop: 2,
  },
  toolTipIconLink: {
    width: 46,
    height: 28,
    marginLeft: 10,
  },
  toolTipIconLinkSm: {
    width: 26,
    height: 20,
    marginLeft: 10,
  },
  toolTipMg: {
    marginTop: -10,
  },
  toolTipMgMd: {
    marginTop: -15,
  },
  bgSep: {
    width: '100%',
    paddingTop: 10,
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_3,
  },
  bgSepSm: {
    width: '100%',
    paddingTop: 5,
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_3,
  },
  subViewPd: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  notifyMaxHeight: {
    ...variableHeight.vhMaxHeightNotify,
  },
  viewMoreSection: {
    borderBottomColor: theme.COLOR_GREY_LIGHT_RG,
    borderBottomWidth: 2,
  },
  viewMoreSectionCom: {
    borderBottomColor: theme.COLOR_GREY_LIGHT,
    borderBottomWidth: 1,
  },
  viewMoreDateRow: {
    borderLeftColor: theme.COLOR_GREY_LIGHT,
    borderLeftWidth: 1,
    borderRightColor: theme.COLOR_GREY_LIGHT,
    borderRightWidth: 1,
    borderBottomColor: theme.COLOR_GREY_LIGHT,
    borderBottomWidth: 1,
  },
  viewMedSection: {
    flex: 1,
    borderBottomColor: theme.COLOR_GREY_LIGHT,
    borderBottomWidth: 3,
  },
  viewMorePd: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  viewMorePdLg: {
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  viewMoreDataItem: {
    marginBottom: 10,
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 6,
    backgroundColor: theme.COLOR_GREY_LIGHT_RG,
  },
  viewMoreDataItemSub: {
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 6,
    backgroundColor: theme.COLOR_GREY_LIGHT_RG,
  },
  viewMoreDataTopMg: {
    marginTop: 10,
  },
  viewMoreDataSingle: {
    marginLeft: 30,
    borderTopColor: theme.COLOR_GREY_LIGHT,
    borderTopWidth: 1,
  },
  viewMoreDataSingleSub: {
    marginLeft: 30,
    borderLeftColor: theme.COLOR_GREY_LIGHT,
    borderLeftWidth: 1,
    borderRightColor: theme.COLOR_GREY_LIGHT,
    borderRightWidth: 1,
    borderBottomColor: theme.COLOR_GREY_LIGHT,
    borderBottomWidth: 1,
    paddingBottom: 10,
  },
  viewMgSm: {
    marginLeft: 20,
  },
  resultTitle: {
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
  },
  addedItemsSymptoms: {
    paddingBottom: 5,
    paddingTop: 5,
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_3,
  },
  addedItemsIssues: {
    paddingTop: 5,
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_3,
  },
  addedItemsImages: {
    paddingTop: 5,
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_3,
  },
  addedItemsThrapies: {
    paddingTop: 5,
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_3,
  },
  listItemsAll: {
    borderTopColor: theme.COLOR_GREY_LIGHT_RG,
    borderTopWidth: 2,
  },
  listItemsReportAllAdded: {
    borderTopColor: theme.COLOR_GREY_LIGHT_RG,
    borderTopWidth: 2,
  },
  messageDataDetailSm: {
    paddingTop: 5,
    maxWidth: '100%',
  },
  fileUploadPost: {
    position: 'absolute',
    top: 0,
    width: 100,
    left: 0,
  },
  dropDownOption: {
    paddingLeft: 25,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderTopColor: theme.COLOR_GREY_LIGHT_RG,
    borderTopWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  checkBoxItemText: {
    marginTop: 3,
  },
  highZIndex: {
    zIndex: 999,
  },
  settingSecGraph: {
    position: 'absolute',
    right: 20,
    top: 0,
    zIndex: 999,
    height: '100%',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  notifyAvailable: {
    borderRadius: 9,
    backgroundColor: theme.COLOR_RED_2,
    position: 'absolute',
    top: 18,
    alignItems: 'center',
    justifyContent: 'center',
  },
  notifyAvailableSmall: {
    width: 14,
    height: 14,
    borderRadius: 14,
    left: 13,
  },
  notifyAvailableMedium: {
    width: 17,
    height: 14,
    left: 14,
    top: 17,
  },
  notifyAvailableLarge: {
    width: 23,
    height: 14,
    left: 11,
  },
  backTextSec: {
    paddingLeft: 7,
  },
  modalMiddleView: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loaderCom: {
    ...variableHeight.vhMinHeightLoader,
    alignItems: 'center',
    justifyContent: 'center',
  },
  notiyfyListViewApp: {
    position: 'absolute',
    right: 0,
    top: 57,
  },
  notifyMenuOptionListCom: {
    backgroundColor: theme.COLOR_WHITE,
    ...variableShadow.shadowStyleTen,
  },
  notifyMenuOptionListApp: {
    backgroundColor: theme.COLOR_WHITE,
    ...variableShadow.shadowStyleTen,
  },
  modalScan: {
    maxHeight: '100%',
  },
  topBtnView: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    width: '100%',
    zIndex: 9,
  },
  topBtnCont: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 4,
    paddingBottom: 4,
    ...variableShadow.shadowStyleTwentyOne,
    borderColor: theme.COLOR_GREY_MEDIUM,
    borderWidth: 4,
    backgroundColor: theme.COLOR_BLUE_2,
    alignItems: 'center',
  },
  listEmailAll: {
    flex: 1,
  },
  bgHeightProfile: {
    height: 135,
  },
  plusSecView: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 6,
    bottom: 0,
    width: 26,
    height: 26,
    borderRadius: 26,
    backgroundColor: theme.COLOR_ORANGE_DARK,
  },
  fullEmailWrap: {
    position: 'relative',
  },
  bgHeightCareBanner: {
    height: 135,
  },
  menuOverlay: {
    opacity: 0.2,
  },
  bgPlanHolderHeight: {
    height: 275,
  },
  capturedImg: {
    width: '100%',
    alignItems: 'center',
  },
  notifyAction: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  zIndexAutoComplete: {
    ...Platform.select({
      ios: {
        zIndex: 9999999,
      },
      web: {
        zIndex: 9999999,
      },
    }),
  },
  zIndexSmAutoComplete: {
    ...Platform.select({
      ios: {
        zIndex: 999999,
      },
      web: {
        zIndex: 999999,
      },
    }),
  },
  tagsExtraView: {
    minHeight: 280,
    ...Platform.select({
      ios: {
        zIndex: -9,
      },
      web: {
        zIndex: -9,
      },
    }),
  },
  menuFull: {
    width: '100%',
  },
  listEmailScrollWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    position: 'relative',
  },
  listEmailTextSec: {
    flexBasis: 50,
    maxWidth: 50,
  },
  listEmailAllHeight: {
    minHeight: 47,
  },
  textFieldEmailView: {
    minWidth: 70,
    ...Platform.select({
      web: {
        flex: 1,
      },
    }),
  },
  listEmailName: {
    paddingRight: 20,
    maxWidth: '100%',
  },
  deviceText: {
    paddingLeft: 70,
  },
  deviceSingle: {
    paddingBottom: 15,
    minHeight: 40,
  },
  deviceArea: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  graphIconInd: {
    marginLeft: 10,
  },
  graphText: {
    flex: 1,
  },
  graphContentWrap: {
    marginTop: 10,
  },
  graphZoomArea: {
    position: 'absolute',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    backgroundColor: theme.COLOR_WHITE,
  },
  graphContentArea: {
    backgroundColor: theme.COLOR_WHITE,
  },
  graphZoomMg: {
    top: 57,
  },
  graphZoomMgLg: {
    top: 88,
  },
  availableIcon: {
    width: 10,
    height: 10,
    backgroundColor: theme.COLOR_GREEN_4,
    borderRadius: 10,
  },
  availableView: {
    position: 'absolute',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
  },
  toolTipView: {
    alignItems: 'flex-start',
    ...Platform.select({
      web: {
        marginLeft: -14,
      },
    }),
  },
  toolTipNo: {
    marginTop: -32,
    width: 32,
  },
  pdfWait: {
    marginLeft: -10,
    marginRight: -10,
    marginTop: -7,
    marginBottom: -7,
  },
  loaderWaitCom: {
    marginTop: -10,
    marginLeft: -10,
    marginRight: -10,
    marginBottom: -10,
  },
  loaderWait: {
    marginTop: -10,
    marginLeft: -15,
    marginRight: -5,
    marginBottom: -10,
  },
  listRowBorder: {
    borderBottomColor: theme.COLOR_GREY_LIGHT_EX_7,
    borderBottomWidth: 1,
  },
  listItemPd: {
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  listTypeDesc: {
    flexDirection: 'row',
    flex: 1,
  },
  listItemDetailView: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  listItemImglView: {
    maxWidth: 50,
    flexBasis: 50,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  modalContentView: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 10,
  },
  modalContentMg: {
    marginBottom: 10,
  },
  btnFullOval: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 10,
  },
  lightGreyTint: {
    tintColor: '#c4c4c4',
  },
  zIndexMinSpec: {
    ...Platform.select({
      ios: {
        zIndex: 9,
      },
      web: {
        zIndex: 9,
      },
    }),
  },
  zIndexZero: {
    zIndex: 0,
  },
  closeBar: {
    width: '100%',
    height: 57,
  },
  tagName: {
    paddingRight: 20,
    maxWidth: '100%',
  },
  closeViewTouchWrap: {
    width: 45,
    height: 45,
    zIndex: 9,
    right: -15,
    top: -20,
    position: 'absolute',
  },
  closeViewTouchBtnSm: {
    width: 45,
    height: 45,
  },
  addLinksOverlayCom: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    zIndex: 99,
    backgroundColor: 'rgba(64, 64, 65, 0.3)',
  },
  touchSpaceHeader: {
    width: '100%',
    height: 57,
  },
  closeViewTopLg: {
    top: 25,
  },
  spaceBarBotFixed: {
    minHeight: 130,
    width: '100%',
  },
  spaceBarBotFixedLg: {
    minHeight: 180,
    width: '100%',
  },
  spaceBarBotCom: {
    minHeight: 100,
    width: '100%',
  },
  sectionContainerzIndex: {
    ...Platform.select({
      ios: {
        zIndex: 99,
      },
      web: {
        zIndex: 99,
      },
    }),
  },
  checkBoxItemDesc: {
    marginTop: 3,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  errorTriangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 6,
    borderRightWidth: 6,
    borderBottomWidth: 12,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: theme.COLOR_ERROR_BG_1,
  },
  triangleLeft: {
    transform: [{rotate: '-90deg'}],
  },
  fieldErrorView: {
    flexDirection: 'row',
    position: 'relative',
  },
  errorTriangleView: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999999,
  },
  fieldErrorBox: {
    marginLeft: 10,
    zIndex: 99999,
  },
  fieldErrorDetails: {
    backgroundColor: theme.COLOR_ERROR_BG_1,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 2,
    justifyContent: 'center',
    width: 130,
    ...variableShadow.errShadow,
    zIndex: 9,
    minHeight: 35,
  },
  fieldErrorWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    right: -10,
  },
  fieldErrorContent: {
    paddingTop: 10,
  },
  privacyIconView: {
    maxWidth: 80,
    flexBasis: 80,
  },
  privacyTitleView: {
    paddingBottom: 15,
  },
  listViewData: {
    flex: 1,
  },
  listViewIconWrap: {
    maxWidth: 25,
    flexBasis: 25,
    paddingTop: 0,
  },
  listViewIcon: {
    width: 16,
    height: 16,
  },
  privacyListRow: {
    flexDirection: 'row',
    paddingBottom: 5,
    alignItems: 'flex-start',
  },
  detailContact: {
    paddingTop: 15,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
  },
  textEmailField: {
    position: 'absolute',
    left: 10,
    top: 6,
    bottom: 0,
    justifyContent: 'center',
    right: 45,
    height: 36,
    overflow: 'hidden',
  },
  textEmailValue: {
    flex: 1,
    justifyContent: 'center',
  },
  detailBoxWrapper: {
    ...variableShadow.itemShadow,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 8,
    marginBottom: 20,
  },
  detailTitleBlock: {
    paddingBottom: 5,
  },
  detailInfoBlock: {
    paddingBottom: 15,
  },
  detailInfoBlockSm: {
    paddingBottom: 10,
  },
  detailImgBlock: {
    paddingBottom: 10,
  },
  detailItemWrapper: {
    flexDirection: 'row',
    paddingTop: 12,
    paddingBottom: 12,
  },
  detailItemTitle: {
    flexBasis: 100,
    maxWidth: 100,
    flexDirection: 'row',
  },
  detailItemWideTitle: {
    flexBasis: 200,
    maxWidth: 200,
    flexDirection: 'row',
  },
  detailItemData: {
    flex: 1,
    paddingLeft: 10,
  },
  itemIconView: {
    flexBasis: 30,
    maxWidth: 30,
  },
  itemTextView: {
    flex: 1,
  },
  linkWrapper: {
    paddingTop: 20,
  },
  swipeIconView: {
    flexBasis: 30,
    maxWidth: 30,
  },
  swipeTextView: {
    flex: 1,
  },
  swipeDetailBlock: {
    flexDirection: 'row',
    paddingTop: 10,
  },
  swipeBoxWrapper: {
    ...variableShadow.itemShadow,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 8,
    backgroundColor: theme.COLOR_THIN_PINK_2,
  },
  detailItemLast: {
    paddingBottom: 6,
  },
  detailItemFirst: {
    paddingTop: 6,
  },
  fieldColLgViewFull: {
    width: '100%',
  },
  mgRtZero: {
    marginRight: 0,
  },
  menuListItem: {
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 8,
    paddingRight: 8,
  },
  menuTriggerFrame: {
    paddingTop: 10,
    paddingLeft: 14,
    paddingRight: 14,
    paddingBottom: 10,
    minHeight: 36,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme.COLOR_TRANSPARENT,
    alignItems: 'center',
    justifyContent: 'center',
  },
  graphDefault: {
    borderRadius: 4,
    borderColor: theme.COLOR_BORDER_MEDIUM,
    borderWidth: 1,
    minHeight: 245,
  },
  graphDefaultDashBoard: {
    minHeight: 245,
  },
  tooltipTriangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 6,
    borderRightWidth: 6,
    borderBottomWidth: 12,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#00BDBD',
  },
  tooltipBottom: {
    transform: [{rotate: '180deg'}],
  },
  tooltipTextWrapper: {
    position: 'absolute',
    bottom: 35,
    backgroundColor: 'transparent',
    marginLeft: 'auto',
    marginRight: 'auto',
    // zIndex: 999999,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltipTextBlockSub: {
    backgroundColor: '#00BDBD',
    paddingTop: 8,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 8,
    borderRadius: 4,
  },
  tooltipTriangleWrapper: {
    position: 'absolute',
    bottom: -10,
  },
  btnActionFullLeft: {
    paddingRight: 3,
  },
  btnActionFullRight: {
    paddingLeft: 3,
  },
  otpWrapper: {
    width: '100%',
    paddingLeft: 42,
    paddingRight: 42,
  },
  premiumBtnSection: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -15,
    paddingRight: 5,
  },
  searchEmptyResult: {
    minHeight: 150,
  },
  resultContentTitle: {
    paddingBottom: 5,
  },
  resultIconView: {
    paddingBottom: 15,
  },
  premiumBtnWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -2,
  },
  premiumBtnCom: {
    marginTop: -2,
  },
  premiumBtnBot: {
    marginTop: 2,
  },
  docSearch: {
    paddingBottom: 2,
  },
  stoargeBannerCont: {
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: theme.COLOR_GREY_LIGHT_EX_17,
  },
  stoargeBannerView: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
  },
  boxHeightBg: {
    height: 35,
  },
  bgImgMax: {
    maxWidth: 926,
  },
  premiumBgContent: {
    position: 'absolute',
    alignItems: 'center',
    top: 5,
    width: '100%',
    justifyContent: 'center',
  },
  topBtnViewPremium: {
    top: -25,
  },
  premiumIconViewSm: {
    marginLeft: -5,
  },
  premiumIconView: {
    marginTop: 1,
  },
  premiumBtnSp: {
    paddingLeft: 5,
  },
  tickBoxContentPd: {
    paddingRight: 80,
    position: 'relative',
  },
  premiumSecBtnArea: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 999,
  },
  tickBoxWrapper: {
    maxWidth: '100%',
  },
  caregiverBannerWrapper: {
    position: 'relative',
    marginBottom: 15,
  },
  caregiverBannerFull: {
    width: '100%',
  },
  caregiverBannerBg: {
    backgroundColor: theme.COLOR_YELLOW_2,
  },
  caregiverContentView: {
    flex: 1,
    paddingLeft: 10,
  },
  caregiverIconView: {
    maxWidth: 120,
    flexBasis: 120,
  },
  bannerTitleView: {
    paddingBottom: 2,
  },
  caregiverContent: {
    maxWidth: 740,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    alignItems: 'center',
  },
  caregiverBannerDetail: {
    paddingBottom: 10,
  },
  iconInactiveColor: {
    tintColor: theme.COLOR_GREY_2,
  },
  premiumBtnOval: {
    marginTop: -1,
  },
  premiumBtnImg: {
    marginTop: 1,
  },
  premiumTopBtn: {
    position: 'absolute',
    top: -15,
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9,
  },
  singleSectionHalf: {
    width: '50%',
  },
  singleSecSpace: {
    flexBasis: 30,
    maxWidth: 30,
  },
  flexAuto: {
    width: '100%',
  },
  contactIconCom: {
    tintColor: theme.COLOR_WHITE,
    width: 25,
    height: 25,
    resizeMode: 'contain',
  },
  btnBlurState: {
    opacity: 0.4,
  },
  btnActiveState: {
    opacity: 1,
  },
  btnLightBlurState: {
    opacity: 0.9,
  },
  premiumListIcon: {
    width: 100,
    height: 100,
    resizeMode: 'contain',
  },
  singleComSpace: {
    flexBasis: 40,
    maxWidth: 40,
  },
  spaceNone: {
    display: 'none',
  },
  surveyBannerWrapper: {
    position: 'relative',
    marginBottom: 15,
    width: '100%',
  },
  surveyBannerFull: {
    width: '100%',
  },
  surveyBannerDetail: {
    minHeight: 50,
  },
  surveyBannerBg: {
    backgroundColor: '#f2dd4a',
  },
  surveyBannerCurve: {
    position: 'absolute',
    left: 0,
    right: 30,
    width: '100%',
  },
  grayBox: {
    backgroundColor: theme.COLOR_GREY_LIGHT_MD,
  },
  grayboxTitle: {
    color: theme.COLOR_GREY_MEDIUM,
  },
  toolTipInfoBoxRg: {
    width: 270,
  },
  toolTipInfoBoxMd: {
    width: 200,
  },
  toolTipInfoBoxSm: {
    width: 180,
  },
  toolTipInfoBox: {
    width: 180,
  },
  premiumBoxWrapper: {
    borderWidth: 1,
    borderColor: theme.COLOR_GREY_LIGHT_RG,
    borderRadius: 8,
    ...variableShadow.itemShadow,
    backgroundColor: theme.COLOR_THIN_PINK_2,
    marginBottom: 20,
  },
  premiumBoxContWrapper: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  premiumArrowArea: {
    flexBasis: 30,
    maxWidth: 30,
  },
  arrowActionIcon: {
    tintColor: theme.COLOR_GREY_MEDIUM,
    width: 7,
    height: 12,
  },
  btnTouchFull: {
    width: '100%',
    minHeight: 66,
  },
  warningIconView: {
    position: 'absolute',
    width: 18,
    zIndex: 9999,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  warningDescPd: {
    paddingLeft: 25,
  },
  bannerBlock: {
    position: 'relative',
    maxWidth: '100%',
  },
  stoargeBannerCom: {
    maxWidth: '100%',
    paddingLeft: 20,
    paddingRight: 20,
  },
  premiumBoxSmWrapper: {
    borderRadius: 6,
    backgroundColor: theme.COLOR_THIN_PINK_2,
  },
  titleWrapperSm: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: 'center',
    maxWidth: '100%',
  },
  mainTitlePd: {
    paddingBottom: 3,
  },
  subscriptionInfoView: {
    paddingBottom: 3,
  },
  btnTouchSmFull: {
    width: '100%',
    minHeight: 46,
  },
  tickIconTouch: {
    width: 18,
    height: 18,
  },
  tickStyleWrapperLg: {
    paddingRight: 12,
    paddingBottom: 8,
    paddingTop: 8,
    paddingLeft: 10,
    marginTop: -8,
    marginRight: 0,
  },
  moreInfoWrapper: {
    maxWidth: 40,
    flexBasis: 40,
  },
  moreInfoComPd: {
    paddingRight: 40,
  },
  listDownloadSec: {
    flexBasis: 30,
    maxWidth: 30,
    alignItems: 'flex-start',
  },
  downloadIconTouch: {
    paddingRight: 5,
    paddingLeft: 5,
  },
  previewGraphView: {
    borderRadius: 5,
    ...variableShadow.previewBoxShadow,
  },
  previewIcon: {
    width: 28,
    height: 29,
  },
  pdfLgIcon: {
    width: 26,
    height: 32,
  },
  pdfSmIcon: {
    width: 20,
    height: 25,
  },
  graphTextLg: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  graphIconDownload: {
    marginLeft: 10,
  },
  graphContentPd: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    paddingTop: 10,
  },
  viewRowCom: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textInputFieldMinMax: {
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    height: 32,
  },
  fieldColMinMax: {
    flexBasis: 55,
    maxWidth: 55,
  },
  fieldColGraph: {
    flexBasis: 92,
    maxWidth: 92,
  },
  textPickerGraph: {
    paddingTop: 0,
    paddingRight: 20,
    paddingBottom: 0,
    paddingLeft: 7,
    height: 28,
  },
  fieldColSpRg: {
    width: 8,
  },
  textInputRangeWrapper: {
    height: 32,
  },
  zoomOutIcon: {
    width: 32,
    height: 32,
  },
  graphTextPreviewLg: {
    flexBasis: 300,
    maxWidth: 300,
  },
  graphDateRangeLg: {
    flexBasis: 300,
    maxWidth: 300,
  },
  graphDateSpec: {
    flexBasis: 185,
    maxWidth: 185,
  },
  graphDateRangeSm: {
    flexBasis: 140,
    maxWidth: 140,
  },
  greyBorderSep: {
    borderLeftColor: theme.COLOR_GREY_LIGHT_RG,
    borderLeftWidth: 2,
    height: '100%',
  },
  scrollTop: {
    width: 45,
    height: 45,
    position: 'absolute',
    right: 20,
    bottom: 90,
    opacity: 0.8,
  },
  scrollTopIcon: {
    width: 45,
    height: 45,
    resizeMode: 'contain',
  },
  graphTextPreviewMd: {
    flexBasis: 200,
    maxWidth: 200,
  },
  checkboxCircle: {
    flexBasis: 32,
    maxWidth: 32,
  },
  pdfWaitSm: {
    marginTop: -7,
    marginBottom: -7,
  },
  graphSmTitle: {
    paddingRight: 5,
  },
  graphTitleDateRange: {
    paddingBottom: 3,
  },
  inlineError: {
    maxWidth: 216,
  },
  warningInline: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 5,
  },
  downloadSecMg: {
    marginLeft: 5,
  },
  noGraphWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: 353,
    backgroundColor: theme.COLOR_WHITE,
    //backgroundColor: "pink"
  },
  graphAreaWrapper: {
    width: '100%',
    height: 353,
  },
  graphDefaultIcon: {
    width: 133,
    height: 94,
  },
  graphDefaultWrapper: {
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 20,
  },
  graphNoItemWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    minHeight: 250,
  },
  graphNoItemWrapperMax: {
    maxWidth: 350,
    paddingTop: 20,
  },
  graphNoItemWrapperFull: {
    maxWidth: '100%',
    paddingBottom: 100,
  },
  graphNoItemContent: {
    paddingTop: 40,
    paddingRight: 20,
    paddingBottom: 40,
    paddingLeft: 20,
  },
  graphNoItemContentLg: {
    paddingRight: 20,
    paddingLeft: 20,
  },
  graphNoItemContentSm: {
    paddingRight: 15,
    paddingLeft: 15,
  },
  menuOverlayView: {
    top: 0,
    left: 0,
    zIndex: 9999,
  },
  menuOverlayDefault: {
    width: 255,
  },
  menuOverlaySmall: {
    width: 60,
  },
  noInfoIcon: {
    width: 40,
    height: 40,
  },
  noInfoSection: {
    position: 'relative',
    flexDirection: 'row',
  },
  noInfoTextSection: {
    paddingLeft: 50,
    maxWidth: '100%',
  },
  noInfoImgSection: {
    position: 'absolute',
    width: 50,
    left: 0,
  },
  graphZoomSet: {
    backgroundColor: '#bdbbbb',
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 5,
    paddingLeft: 10,
    borderRadius: 5,
    alignSelf: 'flex-end',
    maxWidth: 100,
  },
  borderBtn: {
    borderWidth: 1,
    borderColor: '#787885',
  },
  borderSurveyGraphHeader: {
    borderWidth: 2,
    borderColor: '#787885',
  },
  borderSurveyGraphHeaderRight:{
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0
  },
  borderBtnTransparent: {
    borderWidth: 1,
    borderColor: 'transparent',
  },
  whiteBtnText: {
    color: '#787885',
  },
  questItemViewWrap: {
    marginBottom: 15,
  },
  questSectionSpecCom: {
    marginBottom: 15,
    backgroundColor: '#ffffff',
  },
  btnPdMini: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
  },
  payCardIcon: {
    width: 65,
    height: 49,
  },
  notifyAvailableMenu: {
    width: 7,
    height: 7,
    borderRadius: 7,
    backgroundColor: theme.COLOR_ORANGE,
    position: 'absolute',
    right: -9,
    top: 0,
  },
  graphBoxWrapper: {
    borderWidth: 1,
    borderColor: theme.COLOR_GREY_LIGHT_RG,
    borderRadius: 8,
    ...variableShadow.itemShadow,
    backgroundColor: theme.COLOR_WHITE,
  },
  premiumIconRg: {
    width: 23,
    height: 23,
    resizeMode: 'contain',
  },
  plusBtnWrapper: {
    maxWidth: 45,
    flexBasis: 45,
  },
  barBotSpSm: {
    marginBottom: 10,
  },
  resultSectionWrapper: {
    maxHeight: 308,
  },
  barBotSpRg: {
    marginBottom: 10,
  },
  barTopSpRg: {
    marginTop: 5,
  },
  barTopSpMd: {
    marginTop: 10,
  },
  topBorderNone: {
    borderTopWidth: 0,
  },
  bottomBorderNone: {
    borderBottomWidth: 0,
  },
  submitSubMin: {
    minWidth: 145,
  },
  contentWrapperPd: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  notifyAvailableMenuSm: {
    right: 0,
    top: 5,
  },
  comMgLtRtCom: {
    marginLeft: 10,
    marginRight: 10,
  },
  spSmTopBotCom: {
    marginTop: 7,
    marginBottom: 7,
  },
  headerOverlayView: {
    zIndex: 99999,
    top: 0,
    left: 0,
    height: 57,
    position: 'absolute',
    width: '100%',
  },
  menuOverlayHeaderSm: {
    height: 57,
  },
  menuOverlayHeaderLg: {
    height: 88,
  },
  progressSm: {
    alignItems: 'center',
    backgroundColor: theme.COLOR_WHITE,
    height: 8,
    justifyContent: 'center',
    marginLeft: 20,
    marginRight: 20,
    width: 87,
  },
  notificationDisable: {
    backgroundColor: theme.COLOR_LIGHT_WHITE_1,
    opacity: 0.3,
  },
  viewResourceBtn: {
    backgroundColor: theme.COLOR_BLUE_11,
    borderRadius: 8,
    width: 186,
    height: 39,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  resourceLinkTxt: {
    color: theme.COLOR_GREY_DARK,
    textDecorationLine: 'underline',
    paddingLeft: 5,
  },
  greyTxtCr: {
    color: theme.COLOR_GREY_MEDIUM,
  },
  greyTxtLtCr: {
    color: theme.COLOR_GREY,
  },
  deleteResourceConWidth: {
    width: 256,
  },
  deleteResourceCancelBtn: {
    ...variableShadow.shadowStyleEleven,
    backgroundColor: theme.COLOR_BLUE_13,
    marginRight: 4,
  },
  deleteResourceDeleteBtn: {
    backgroundColor: theme.COLOR_WHITE,
    borderColor: theme.COLOR_GREY_DARK_1,
    borderWidth: 2,
    marginLeft: 4,
  },
  saveSubmitBtnClr: {
    ...variableShadow.shadowStyleEleven,
    backgroundColor: theme.COLOR_BLUE,
    marginLeft: 4,
  },
  informSentWidth: {
    maxWidth: 268,
  },
  journalWarningText: {
    color: theme.COLOR_GREY,
    paddingLeft: 10,
    maxWidth: 500,
    textAlign: 'center',
  },
  journalWarningborder: {
    borderBottomWidth: 1,
    borderBottomColor: theme.COLOR_BORDER_MEDIUM,
  },

  journalTreatmentWarning: {
    paddingRight: 30,
    maxWidth: '80%',
    flexWrap: 'wrap',
  },

  journalWarningContent: {
    marginTop: 13,
    paddingBottom: 6,
  },

  sixMgRght: {
    marginRight: 6,
  },

  sixMgLt: {
    marginLeft: 6,
  },

  oneTp: {
    top: 5,
  },

  pdLeftZero: {
    paddingLeft: 0,
  },

  addReminderButton: {
    width: 138,
    backgroundColor: theme.COLOR_WHITE,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: theme.COLOR_ASH_2,
    flexDirection: 'row',
    // width: '100%',
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },

  addAReminderTxtColor: {
    color: theme.COLOR_ASH_2,
  },

  primarySmBold: {
    ...variable.fontPrimary,
    fontSize: theme.FONT_SIZE_RG, //(fontSize:14, bold)
    fontWeight: theme.FONT_WEIGHT_BOLD,
  },

  choosingTxt: {
    color: theme.COLOR_GREY_DARK,
    fontSize: theme.FONT_SIZE_RG, //(fontSize:14)
    fontWeight: theme.FONT_WEIGHT_BOLD,
    fontFamily: theme.FONT_FAMILY,
  },

  pdTopSm: {
    paddingTop: 10,
  },

  radioFormWrap: {
    paddingTop: 14,
  },

  daysButtonActive: {
    // boxShadow: 'none',
    backgroundColor: theme.COLOR_ASH_2,
  },

  weekDaySelection: {
    minWidth: 35,
  },

  reminderNum: {
    position: 'absolute',
    top: 9,
    left: 6,
  },

  indexSm: {
    alignItems: 'center',
    backgroundColor: theme.COLOR_ASH_2,
    borderRadius: 100,
    bottom: -10,
    height: 20,
    justifyContent: 'center',
    position: 'relative',
    width: 20,
  },

  tpNine: {
    top: 9,
  },

  indexTxtSm: {
    color: theme.COLOR_WHITE,
  },

  scheduleCancelIcon: {
    width: 15,
    height: 15,
  },

  hdOverflow: {
    overflow: 'hidden',
  },

  newResourceHealthLbBtn: {
    backgroundColor: theme.COLOR_BLUE_DARK_2,
    width: 150,
    height: 35,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
  },

  newCancelLbBtn: {
    backgroundColor: theme.COLOR_WHITE,
    borderColor: theme.COLOR_GREY_DARK_1,
    borderWidth: 1,
    width: 150,
    height: 35,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
  },

  pdBtnBtNine: {
    paddingBottom: 8,
  },
  attachmentTpSection: {
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightTwenty: {
    right: 20,
  },
  healthLibrarySearchIcnPlc: {
    position: 'absolute',
    right: 20,
    top: 13,
  },

  pdLgtZero: {
    paddingLeft: 0,
  },

  mgtBtmFive: {
    marginBottom: 5,
  },

  journalEntryNwBx: {
    width: 45,
    height: 45,
    borderRadius: 5,
  },

  transformRotate: {
    transform: [{rotate: '-90deg'}],
  },

  wdBgCircle: {
    width: 20,
    height: 20,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },

  darkGreen: {
    backgroundColor: '#00BDBD',
  },

  darkPurple: {
    backgroundColor: '#86AEF0',
  },

  darkGreyBg: {
    backgroundColor: '#C4C4C4',
  },

  copyFromPrev: {
    width: 200, 
    height:40, 
    backgroundColor:theme.COLOR_BLUE_DARK_2, 
    borderRadius:50, 
  },
  tagView:{
    borderColor: theme.COLOR_GREY_LIGHT_MD,
    borderWidth: 1,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 22,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
    marginBottom: 5,
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '98%',
  },
 
  
};
