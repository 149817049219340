import React from 'react';
import {View} from 'react-native';
import {
  MoreMenuItem,
  AddButton,
  CommonBottomMenuItem
} from './MenuItem';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import * as paths from "../../../navigation/path";
import {APP_FEATURE_P2P_ENABLED} from '../../../util/featureConfig';

const mobileWebMenu = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {
    handelOnMenuItemPress,
    selectedMenu,
    onAdd,
    addMenu,
    onPressMoreMenu,
    moreMenuMobile,
    navigation,
    onClearQuestionFilter,
    onClearJournalEntryFilte,
    hasCommunityNotifications,
    hasSurveyNotifications,
    journalUserId,
    route,
    tabMenuIconView
  } = props;
  return (
    <React.Fragment>
      <View style={stylesRes.responsiveMenuWrapper} dataSet={{media: ids.responsiveMenuWrapper}}>
        <View style={styles.responsiveMenuRow}>
          <View style={[styles.navResponsiveLeft]}>
            <View style={[styles.navLeftItemRow]}>
              <CommonBottomMenuItem
                navigation={navigation}
                onPressMenuItem={handelOnMenuItemPress}
                addMenu={addMenu}
                selectedMenu={selectedMenu}
                route={paths.DASHBOARD.path}
                pathDetails={paths.DASHBOARD}
                moreMenuMobile={moreMenuMobile}
              />
              {APP_FEATURE_P2P_ENABLED && !Boolean(journalUserId) ?
                (<CommonBottomMenuItem
                  navigation={navigation}
                  onPressMenuItem={handelOnMenuItemPress}
                  addMenu={addMenu}
                  selectedMenu={selectedMenu}
                  hasNotifications={hasCommunityNotifications}
                  route={route}
                  pathDetails={paths.PEER_TO_PEER}
                  moreMenuMobile={moreMenuMobile}
                />) :
                (<CommonBottomMenuItem
                  navigation={navigation}
                  onPressMenuItem={handelOnMenuItemPress}
                  addMenu={addMenu}
                  selectedMenu={selectedMenu}
                  route={paths.TODOS.path}
                  pathDetails={paths.TODOS}
                  onClearFilter={onClearQuestionFilter}
                  moreMenuMobile={moreMenuMobile}
                />)
              }
              
            </View>
          </View>
          <View style={[styles.navResponsiveCenter]}>
            <View style={[styles.addMenuCom]}>
              <View style={styles.addMenuComView}>
                <AddButton openAddMenu={onAdd} addMenu={addMenu} />
              </View>
            </View>
          </View>
          <View style={[styles.navResponsiveRight]}>
            <View style={[styles.navRightItemRow]}>
              <CommonBottomMenuItem
                navigation={navigation}
                onPressMenuItem={handelOnMenuItemPress}
                addMenu={addMenu}
                selectedMenu={selectedMenu}
                route={paths.JOURNALS.path}
                pathDetails={paths.JOURNALS}
                onClearFilter={onClearJournalEntryFilte}
                moreMenuMobile={moreMenuMobile}
              />
              <MoreMenuItem
                moreMenuMobile={moreMenuMobile}
                onPressMoreMenu={onPressMoreMenu}
                addMenu={addMenu}
                selectedMenu={selectedMenu}
                tabMenuIconView={tabMenuIconView}
                hasNotifications={hasSurveyNotifications}
              />
            </View>
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

export default (mobileWebMenu);
