import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
  treatment: {
    id: null,
    createdOn: null,
    modifiedOn: null,
    artifactId: null,
    name: null,
    notes: null,
    instructions: null,
    identificationNumber: null,
    treatmentType: null,
    displayProperties: {
      id: null,
      createdOn: null,
      modifiedOn: null,
      color: null,
      artifactId: null,
      dashedLine: false,
    },
    dosages: [
      {
        id: null,
        createdOn: null,
        modifiedOn: null,
        start: null,
        end: null,
        frequency: null,
        prescribedBy: null,
      },
    ],
  },
  newTherapie: null,
  selectedTherapie: null,
  isNew: false,
  popupTreatment: null,
  saveDisable: false,
  hasActivityInvalidName: false,
  hasMedicationInvalidName: false,
  hasHealthDataInvalidName: false,
  hasSymptomsInvalidName: false,
  hasActivityDuplicate: false,
  hasMedicationDuplicate: false,
};

const createTherapieSuccess = (state, action) => {
  return updateObject(state, {
    newTherapie: action.newTherapie,
    isNew: action.isNew,
  });
};

const createTherapieFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const updateTherapieSuccess = (state, action) => {
  return updateObject(state, {
    selectedTherapie: action.selectedTherapie,
    newTherapie: null,
    isNew: false,
  });
};

const updateTherapieFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const updateGraphDisplaySuccess = (state, action) => {
  return updateObject(state, {});
};

const updateSaveDisable = (state, action) => {


  const {
    hasActivityInvalidName,
    hasMedicationInvalidName,
    hasHealthDataInvalidName,
    hasSymptomsInvalidName,
    hasActivityDuplicate,
    hasMedicationDuplicate
  } = state;

  let overWrite = action.saveDisable;

  if (!action.saveDisable &&
    (
      hasActivityInvalidName ||
      hasMedicationInvalidName ||
      hasHealthDataInvalidName ||
      hasSymptomsInvalidName ||
      hasActivityDuplicate ||
      hasMedicationDuplicate

    )) {
    overWrite = true;
  }


  return updateObject(state, {
    saveDisable: overWrite,
  });
};

const updateGraphDisplayFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const createQuestionSuccess = (state, action) => {
  return updateObject(state, {});
};

const createQuestionFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true,
  });
};

const selectTherapie = (state, action) => {
  return updateObject(state, {
    selectedTherapie: action.selectedTherapie,
  });
};

const setPopupTreatment = (state, action) => {
  return updateObject(state, {
    popupTreatment: action.popupTreatment,
  });
};

const setInvalidNamesInActivity = (state, action) => {

  const {
    hasMedicationInvalidName,
    hasHealthDataInvalidName,
    hasSymptomsInvalidName,
    hasActivityDuplicate,
    hasMedicationDuplicate
  } = state;

  let overWrite = action.hasInvalidNames;

  if (!action.hasInvalidNames &&
    (
      hasMedicationInvalidName ||
      hasHealthDataInvalidName ||
      hasSymptomsInvalidName ||
      hasActivityDuplicate ||
      hasMedicationDuplicate

    )) {
    overWrite = true;
  }

  return updateObject(state, {
    hasActivityInvalidName: action.hasInvalidNames,
    saveDisable: overWrite,
  });
};

const setInvalidNamesInMedication = (state, action) => {

  const {
    hasActivityInvalidName,
    hasHealthDataInvalidName,
    hasSymptomsInvalidName,
    hasActivityDuplicate,
    hasMedicationDuplicate
  } = state;

  let overWrite = action.hasInvalidNames;

  if (!action.hasInvalidNames &&
    (
      hasActivityInvalidName ||
      hasHealthDataInvalidName ||
      hasSymptomsInvalidName ||
      hasActivityDuplicate ||
      hasMedicationDuplicate
    )) {
    overWrite = true;
  }

  return updateObject(state, {
    hasMedicationInvalidName: action.hasInvalidNames,
    saveDisable: overWrite
  });
};

const setInvalidNamesInHealthData = (state, action) => {

  const {
    hasActivityInvalidName,
    hasMedicationInvalidName,
    hasSymptomsInvalidName,
    hasActivityDuplicate,
    hasMedicationDuplicate
  } = state;

  let overWrite = action.hasInvalidNames;

  if (!action.hasInvalidNames &&
    (
      hasActivityInvalidName ||
      hasMedicationInvalidName ||
      hasSymptomsInvalidName ||
      hasActivityDuplicate ||
      hasMedicationDuplicate

    )) {
    overWrite = true;
  }

  return updateObject(state, {
    hasHealthDataInvalidName: action.hasInvalidNames,
    saveDisable: overWrite
  });
};

const setInvalidNamesInSymptoms = (state, action) => {

  const {
    hasActivityInvalidName,
    hasMedicationInvalidName,
    hasHealthDataInvalidName,
    hasActivityDuplicate,
    hasMedicationDuplicate
  } = state;

  let overWrite = action.hasInvalidNames;

  if (!action.hasInvalidNames &&
    (
      hasActivityInvalidName ||
      hasMedicationInvalidName ||
      hasHealthDataInvalidName ||
      hasActivityDuplicate ||
      hasMedicationDuplicate
    )) {
    overWrite = true;
  }


  return updateObject(state, {
    hasSymptomsInvalidName: action.hasInvalidNames,
    saveDisable: overWrite
  });
};

const setDuplicatesInActivity = (state, action) => {

  const {
    hasMedicationInvalidName,
    hasHealthDataInvalidName,
    hasSymptomsInvalidName,
    hasActivityInvalidName,
    hasMedicationDuplicate
  } = state;

  let overWrite = action.hasDuplicates;

  if (!action.hasDuplicates &&
    (
      hasMedicationInvalidName ||
      hasHealthDataInvalidName ||
      hasSymptomsInvalidName ||
      hasActivityInvalidName ||
      hasMedicationDuplicate

    )) {
    overWrite = true;
  }

  return updateObject(state, {
    hasActivityDuplicate: action.hasDuplicates,
    saveDisable: overWrite,
  });
};

const setDuplicatesInMedication = (state, action) => {

  const {
    hasActivityInvalidName,
    hasHealthDataInvalidName,
    hasSymptomsInvalidName,
    hasMedicationInvalidName,
    hasActivityDuplicate
  } = state;

  let overWrite = action.hasDuplicates;

  if (!action.hasDuplicates &&
    (
      hasActivityInvalidName ||
      hasHealthDataInvalidName ||
      hasSymptomsInvalidName ||
      hasMedicationInvalidName ||
      hasActivityDuplicate
    )) {
    overWrite = true;
  }

  return updateObject(state, {
    hasMedicationDuplicate: action.hasDuplicates,
    saveDisable: overWrite
  });
};

const resetDuplicates = (state, action) => {
  return updateObject(state, {
    saveDisable: false,
    hasActivityInvalidName: false,
    hasMedicationInvalidName: false,
    hasHealthDataInvalidName: false,
    hasSymptomsInvalidName: false,
    hasActivityDuplicate: false,
    hasMedicationDuplicate: false,
  });

}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_THERAPIE_SUCCESS:
      return createTherapieSuccess(state, action);
    case actionTypes.ADD_THERAPIE_FAIL:
      return createTherapieFail(state, action);

    case actionTypes.UPDATE_THERAPIE_SUCCESS:
      return updateTherapieSuccess(state, action);
    case actionTypes.UPDATE_THERAPIE_FAIL:
      return updateTherapieFail(state, action);

    case actionTypes.UPDATE_SAVE_DISABLE:
      return updateSaveDisable(state, action);

    case actionTypes.UPDATE_GRAPH_DISPLAY_SUCCESS:
      return updateGraphDisplaySuccess(state, action);
    case actionTypes.UPDATE_GRAPH_DISPLAY_FAIL:
      return updateGraphDisplayFail(state, action);

    case actionTypes.UPDATE_HEALTH_QUESTION_SUCCESS:
      return createQuestionSuccess(state, action);
    case actionTypes.UPDATE_HEALTH_QUESTION_FAIL:
      return createQuestionFail(state, action);
    case actionTypes.SELECT_THERAPIE:
      return selectTherapie(state, action);
    case actionTypes.SET_POPUP_TREATMENT:
      return setPopupTreatment(state, action);

    case actionTypes.HAS_ENTRY_CUSTOM_ACTIVITY_INVALID_NAMES:
      return setInvalidNamesInActivity(state, action);
    case actionTypes.HAS_ENTRY_CUSTOM_MEDICATION_INVALID_NAMES:
      return setInvalidNamesInMedication(state, action);
    case actionTypes.HAS_ENTRY_CUSTOM_HEALTH_DATA_INVALID_NAMES:
      return setInvalidNamesInHealthData(state, action);
    case actionTypes.HAS_ENTRY_CUSTOM_SYMPTOMS_INVALID_NAMES:
      return setInvalidNamesInSymptoms(state, action);

    case actionTypes.HAS_ENTRY_CUSTOM_ACTIVITY_DUPLICATE:
      return setDuplicatesInActivity(state, action);
    case actionTypes.HAS_ENTRY_CUSTOM_MEDICATION_DUPLICATE:
      return setDuplicatesInMedication(state, action);
    case actionTypes.RESET_DUPLICATES:
      return resetDuplicates(state, action);
    default:
      return state;
  }
};

export default reducer;
