import React from "react";
import { View, ScrollView, Text, TouchableOpacity } from "react-native";
import _ from "underscore";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";
import Header from "../../Header/header";
import SpaceBar from "../../SpaceBar/SpaceBar";
import ActivityIndicator from "../../ActivityIndicator/activityIndicator";
import PageContainer from "../../../components/PageLayout/PageContainer";
import SearchField from "../../../components/Search/searchField";
import NoResultFound from "../../../components/Search/noResultFound";
import SectionTitle from "../../../components/Text/sectionTitle";
import PlusButtonDark from "../../../components/Button/plusButtonDark";

const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
const itemList = (res, props) => (
  <View style={[styles.singleItem, styles.greyBorder]} key={res.id}>
    <TouchableOpacity
      onPress={() => {
        props.onSelectMetricList(res.id);
      }}
    >
      <View style={[styles.messageRow]}>
        <View style={[styles.messageDetailSec, styles.fullHeight]}>
          <View style={[styles.messageDetailCont, styles.fullHeight]}>
            <View
              style={[
                stylesRes.customBorderCom,
                {
                  backgroundColor:
                    res.displayProperties && res.displayProperties.color
                      ? res.displayProperties.color
                      : "white",
                },
              ]}
            />
            <View
              style={[
                stylesRes.messageDetailCom,
                stylesRes.messageTopBotPdWeb,
                stylesRes.textMinHeight,
              ]}
            >
              <Text style={[styles.textTitle, styles.breakWord]} allowFontScaling={false}>
                {res.name}
              </Text>
            </View>
          </View>
        </View>
        <View 
          style={[stylesRes.messageLinkSecCom, stylesRes.messageTopBotPdWeb]}
          dataSet={{media: ids.messageLinkSecCom}}>
          <View style={styles.iconListSec}>
            <View style={[styles.linkDescSec, styles.iconListMgSmLt]}>
              <Text
                style={[
                  styles.textPrime,
                  styles.breakWord,
                  styles.textAlignRight,
                ]}
                allowFontScaling={false}
              >
                {uom(res.id, props)}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  </View>
);

const uom = (id, props) => {
  let uoMeasure = [];
  let udmuom = props.userDefinedMetricUnitOfMeasures.filter(function (
    userDefinedMetricUnitOfMeasures
  ) {
    return userDefinedMetricUnitOfMeasures.metricId === id;
  });

  if (udmuom && udmuom.length > 0) {
    uoMeasure = props.unitsOfMeasure.filter(function (unit) {
      return unit.id === udmuom[0].uomId;
    });
  }
  return uoMeasure && uoMeasure.length > 0 ? uoMeasure[0].symbol : "";
};
const metrics = (props) => {
  return (
    <View style={[styles.pageBodyWrapper]}>
      <Header
        showFilers={false}
        showBack={true}
        showClearFilters={false}
        showNotify={true}
        filterClickHandler={this.handleOnPressFilter}
        clearFiltersHandler={this.handleOnPressClearFilters}
        handleOnPressBackButton={props.handleOnPressBackButton}
        index={props.index}
        navigation={props.navigation}
        route={props.route}
      />
      <SpaceBar />
      <ScrollView style={styles.scrollViewArea}>
        <View style={styles.gridSection}>
          <PageContainer>
            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
              <View style={[styles.searchSection]}>
                    {/* button */}
                    <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt]}>
                      <View>
                        <SectionTitle 
                          smallTextTilte={true}
                          title={'Health Data'} 
                        />
                      </View>
                      <View style={[stylesRes.newConnectionBtnCr, styles.btnMgMd]} dataSet={{media: ids.newConnectionBtnCr}}>
                        <PlusButtonDark
                          touchableOnPress={props.onPressCreateNewMetric}
                          btnPosition={"full"}
                          btnText={"New Health Data"}
                          plusIconSm={true}
                          btnShadowNone={true}
                          nativeId={'healthDataAdd'}
                          newConnection={true}

                        />
                      </View>
                    </View>
                    {/* search bar */}
                    <View style={styles.fieldColSearch}>
                      <SearchField
                        searchText={
                          props.searchKeyword ? props.searchKeyword : ""
                        }
                        placeholder="Search existing health data"
                        onChangeText={props.searchUserDefinedMetric}
                        showClearBtton={
                          props.searchKeyword && props.searchKeyword !== ""
                            ? true
                            : false
                        }
                        clearFilter={() => props.searchUserDefinedMetric(null)}
                        nativeId={'healthDataSearch'}
                        eventName={'HealthData@ > Search'}
                      />
                    </View>
                </View>
                {_.isEmpty(props.userDefinedMetrics) &&
                  !props.loadingWait &&
                  props.searchKeyword !== "" &&
                  props.searchKeyword !== null && <NoResultFound />}
              </View>
            </View>
          </PageContainer>
          {props.loadingWait ? (
            <ActivityIndicator pdLarge={true}/>
          ) : (
            <PageContainer smScreenFull={true}>
              <View style={stylesRes.botExPd} dataSet={{media: ids.botExPd}}>
                <View style={[styles.gridRow, styles.contPdTop]}>
                  <View style={styles.gridColFull}>
                    <View style={styles.secArea}>
                      {/* Single Item Start */}
                        {Boolean(props.userDefinedMetrics) &&
                        props.userDefinedMetrics.length > 0 && (
                          <View
                            style={[styles.ItemWrapper, styles.greyBorderTop]}
                          >
                            <View style={styles.ItemWrapper}>
                              {props.userDefinedMetrics.map((res, i) => {
                                return itemList(res, props);
                              })}
                            </View>
                          </View>
                        )}
                    </View>
                  </View>
                </View>
              </View>
            </PageContainer>
          )}
        </View>
      </ScrollView>
    </View>
  );
};
export default (metrics);
