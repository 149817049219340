import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import ImageFile from "./ImageFile";

class ReportImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelect: this.props.selectedImagesObj[this.props.id] ? true : false
    };
  }

  onSelect = () => {
    this.setState({ isSelect: !this.state.isSelect });
    this.props.addRemoveReportImage(this.props.attachment);
    this.props.setImagesSelected(
      Object.values(this.props.selectedImagesObj).length > 0
    );
  };
  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.messageSecRow, styles.greyBorder]}>
        <TouchableOpacity style={[styles.messageRow]} onPress={this.onSelect}>
          <View style={styles.messageDetailSec}>
            <View style={styles.messageDetailCont}>
              <View
                style={[
                  styles.customBorderSec,
                  { backgroundColor: this.props.color }
                ]}
              />
              <View style={[styles.messageDetail, styles.messageTopBotPdSm]}>
                <View style={[styles.messageDataFull]}>
                  <View style={stylesRes.messageImg}>
                    <View style={stylesRes.messageImgIcon}>
                      <ImageFile id={this.props.id} />
                    </View>
                  </View>
                  <View style={[styles.messageData]}>
                    <Text style={styles.messageTitle} allowFontScaling={false}>{this.props.title}</Text>
                    <Text style={styles.messageDateText} allowFontScaling={false}>
                      {this.props.filename}
                      {" - "}
                      {this.props.date}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View
            style={[
              styles.selectionBlockSm,
              styles.messageTopBotPdSm,
              styles.selectionPdRt
            ]}
          >
            <View style={styles.iconListSec}>
              <View
                style={[
                  styles.selectSecIcon,
                  styles.iconSettingSp,
                  styles.settingPd
                ]}
              >
                <Image
                  style={styles.radioIcon}
                  source={
                    this.state.isSelect
                      ? require("../../assets/icons/radio-selected.png")
                      : require("../../assets/icons/radio-default.png")
                  }
                />
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedImagesObj: state.report.selectedImagesObj
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addRemoveReportImage: attachment =>
      dispatch(actions.addRemoveReportImage(attachment))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportImage);
