import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import stylesResponsive from '../../../CssResponsive';
import styles from '../../../Css';
import UIText from '../../Text/text';

const addButton = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <View
      style={[
        styles.menuSingleBarAddBlock,
        props.topButton
        ? styles.barBotSpRg
        : styles.barTopSpMd
      ]}
    >
      <View style={styles.menItemAddSec}>
        <TouchableOpacity onPress={props.onPressAddButton} >
          <View style={[stylesRes.addItemSecFull]}>
            <View style={[styles.addItemSecBtnSecFull]}>
              <View
                style={[
                  stylesRes.addItemWidth,
                  stylesRes.boxShadowBtn,
                  stylesRes.flexBtnStart,
                  stylesRes.flexBtnAlignCenter,
                  stylesRes.addItemNewConn
                ]}
                dataSet={{media: ids.addItemSecBtnSec}}
                nativeID={props.nativeId}>
                  <UIText
                    title={props.btnText}
                    style={[styles.btnText, styles.textWhite, styles.sliderCom]}
                  />
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default (addButton);
