export const WEB = 'web';
export const ANDROID = 'android';
export const IOS = 'ios';
export const TAB_BREAk_POINT_START = 991;
export const TAB_BREAk_POINT = 992;
export const TAB_BREAk_MAX_WIDTH = 1023;
export const TAB_BREAk_POINT_END = 1024;
export const UI_BREAK_ONE_COUNT = 1366;
export const TIME_ZONE_BANNER_HEIGHT_BREAK_POINT = 500;
export const UI_BREAK_FOUR_COUNT = 400;

