import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import styles from '../../Css';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';


const arrowNextButton = props => {
  return (
    <View style={[styles.btnSectionLink, styles.flexJcCtAiFe, styles.arrowSecPdRt]}>
      <TouchableOpacity
        style={[props.touchableStyle]}
        onPress={props.touchableOnPress}
        disabled={props.disabled}>
        <View style={[styles.flexRow, styles.flexAiCt]}>
          {Boolean(props.nextWait) && (
            <View
              style={[
                styles.indicatorIconView,
                styles.indLoaderLgLtSpec
              ]}>
              <ActivityIndicator pdZero={true} size="small" color={'#FF8300'}/>
            </View>
          )}
          <View style={[styles.flexRow, styles.flexJcCtAiCt, props.disabled && {opacity: 0.5}]}>
          <Text style={[styles.textPrimeBold, styles.textOrange, styles.textAlignRight]} allowFontScaling={false}>
            {props.btnText}
          </Text>
          <View style={styles.iconViewExSmLt}>
            <Image
              style={styles.nextArrowThick}
              source={require('../../assets/icons/next-arrow-thick.png')}
            />
          </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default (arrowNextButton);
