import { QUESTION_TYPES } from './questionUtilConstants';

const answerModel = {
  notificationId: null,
  surveyId: null,
  completedQuestions: false,
  questionOrder: 0, // This should be non-zero integer value
  answerResponse: [
    {
        id: '1',  // Question id
        type: "CHOICE", // Values are 'MULTIPLE' for multiple, 'CHOICE' for single selection question
        text: null,
        range: null,
        choiceId: [],
        skipped: false,
    }
  ]
 };

 const getQuestionType = (type) => {
  switch (type) {
    case QUESTION_TYPES.choiceQuestion:
      return 'CHOICE';
    case QUESTION_TYPES.multipleAnswerQuestion:
      return 'MULTIPLE';
    case QUESTION_TYPES.longAnswerQuestion:
      return 'LONG';
    case QUESTION_TYPES.slider:
      return 'RANK';
    case QUESTION_TYPES.symptomSliderQuestion:
      return 'SYMPTOM';
    case QUESTION_TYPES.descriptiveQuestion:
      return 'DESCRIPTIVE';
      case QUESTION_TYPES.surveyTable:
        return 'TABLE';
    default:
      return null;
  }
 }

const answerGenerator = (function() {
  function generateSavingAnswer(
    userAnswer, questionType, surveyId, questionId, notificationId, isLastQuestion = false, questionOrder, symptomSliderMeasureType, skipped
  ) {
    let updatedAnswer = {
      ...answerModel,
      notificationId: notificationId,
      surveyId: surveyId,
      completedQuestions: isLastQuestion,
      questionOrder,
      answerResponse: []
    };

    if (skipped) {
      let answerRes = {
        ...answerModel.answerResponse[0],
        id: `${questionId}`,
        type: getQuestionType(questionType),
        text: null,
        choiceId: [],
        skipped: true,
        tableValues: [],
      };

      updatedAnswer.answerResponse.push(answerRes);
    } else {
      if (questionType === QUESTION_TYPES.choiceQuestion) {
        const { selectedItem, otherText = '' } = userAnswer;
        let answerRes = {
          ...answerModel.answerResponse[0],
          id: `${questionId}`,
          type: 'CHOICE',
          text: !selectedItem && otherText.trim() ? otherText.trim() : null,
          choiceId: [],
        };
        if (selectedItem) {
          answerRes.choiceId.push(`${selectedItem}`);
        }
  
        updatedAnswer.answerResponse.push(answerRes);
      }
  
      if (questionType === QUESTION_TYPES.multipleAnswerQuestion) {
        const { selectedItems = [], otherText = '' } = userAnswer;
        let answerRes = {
          ...answerModel.answerResponse[0],
          id: `${questionId}`,
          type: 'MULTIPLE',
          text: otherText !== null && otherText.trim() ? otherText.trim() : null,
          choiceId: selectedItems ? selectedItems.map((id) => `${id}`) : [],
        };
        updatedAnswer.answerResponse.push(answerRes);
      }
  
      if (questionType === QUESTION_TYPES.longAnswerQuestion) {
        let answerRes = {
          ...answerModel.answerResponse[0],
          id: `${questionId}`,
          type: 'LONG',
          text: userAnswer,
        };
        updatedAnswer.answerResponse.push(answerRes);
      }
  
      if (questionType === QUESTION_TYPES.slider) {
        let answerRes = {
          ...answerModel.answerResponse[0],
          id: `${questionId}`,
          type: 'RANK',
          range: userAnswer,
        };
        updatedAnswer.answerResponse.push(answerRes);
      }
  
      if (questionType === QUESTION_TYPES.symptomSliderQuestion) {
        let answerRes = {
          ...answerModel.answerResponse[0],
          id: `${questionId}`,
          type: 'SYMPTOM',
          range: userAnswer,
          mesureType: symptomSliderMeasureType,
        };
        updatedAnswer.answerResponse.push(answerRes);
      }
  
      if (questionType === QUESTION_TYPES.descriptiveQuestion) {
        let answerRes = {
          ...answerModel.answerResponse[0],
          id: `${questionId}`,
          type: 'DESCRIPTIVE',
          mesureType: false,
        };
        updatedAnswer.answerResponse.push(answerRes);
      }

      if (questionType === QUESTION_TYPES.surveyTable) {
        let answerRes = {
          ...answerModel.answerResponse[0],
          id: `${questionId}`,
          type: 'TABLE',
          mesureType: null,
          tableValues: userAnswer,
        };
        updatedAnswer.answerResponse.push(answerRes);
      }
    }
    return updatedAnswer;
  };

  return {
    generateSavingAnswer,
  };
})();

export default answerGenerator;
