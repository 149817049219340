import React from 'react';
import {View, Text, Image, TouchableOpacity, ScrollView, Platform} from 'react-native';
import stylesResponsive from '../../CssResponsive';
import styles from '../../Css';
import ImageUpload from '../FileUpload/ImageUpload';
import Modal from '../Modal/modal';
import ActivityIndicator from '../ActivityIndicator/activityIndicator';
import ProfileImageCropper from './profileImageCropper';
import { WEB } from '../../util/platformWindowConfig';


let imageUploadRef = null;
let inputRef = null;
const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
const profileImageUploadPopup = props => {
  const {uri} = props;
  return (
    <Modal
      visible={props.visibleEditProfilePhotoModal}
      onRequestClose={props.onRequestClose}
      onBackdropPress={props.onRequestClose}
    >
      <View
        style={[
          //!props.tempProfileImage
          stylesRes.modalScrollBlockLg,
          !props.tempProfileImage && stylesRes.uploadImageScrollModal,
          //: {width: 500, height: 900},
        ]}
        dataSet={{media: !props.tempProfileImage ? ids.uploadImageScrollModal : ids.modalScrollBlockLg}}>
        {!Boolean(props.tempProfileImage) && (
          <>
            <TouchableOpacity
              style={[styles.touchSpaceHeader]}
              onPress={props.onRequestClose}
            />
            <TouchableOpacity
              onPress={props.onRequestClose}
              style={stylesRes.updatedProfileClsIcnPost}
              dataSet={{media: ids.updatedProfileClsIcnPost}}>
              <Image
                style={styles.updatedProfileClsIcn}
                source={require('../../assets/profile/profile-close.png')}
              />
            </TouchableOpacity>
          </>
        )}
        <ScrollView style={styles.modalScroller}>
          <View
            style={[
              stylesRes.updatedProfilePopUpStyle,
              props.tempProfileImage && stylesRes.updatedPopUpCrop,
              !props.tempProfileImage && stylesRes.updatedPopUpUpload,
            ]}
            dataSet={{media: props.tempProfileImage ? ids.updatedPopUpCrop : ids.updatedPopUpUpload}}>
            <Text
              style={[styles.updatedProfileHeading]}
              allowFontScaling={false}>
              {!props.tempProfileImage
                ? 'Change Your Profile Photo'
                : 'Adjust & Crop Photo'}
            </Text>

            {/* ZI-483 CR UPDATED CODE */}
            {!props.tempProfileImage ? (
              <>
                <View style={[styles.updatedProfileContentSec]}>
                  <View style={[styles.updatedProfileImageStyle]}>
                    {props.tempProfileImage && !props.profilePicRemoved ? (
                      <Image
                        style={styles.updatedProfileImage}
                        source={{uri: props.tempProfileImage}}
                      />
                    ) : props.profileImage && !props.profilePicRemoved ? (
                      <Image
                        style={styles.updatedProfileImage}
                        source={{uri: props.profileImage}}
                      />
                    ) : props.imageLoading &&
                      props.uri &&
                      !props.profilePicRemoved ? (
                      <Image
                        style={styles.updatedProfileImage}
                        source={Platform.OS === WEB ? {uri: uri} : uri}
                        onError={props.imageLoadingError} // should maintain a different one
                      />
                    ) : props.userAvatarName ? (
                      <View
                        style={[
                          styles.peerProfileCircle,
                          styles.updatedProfileImage,
                        ]}>
                        <View
                          style={[
                            styles.peerProfileTextWrapper,
                            styles.updatedProfileImage,
                          ]}>
                          <Text
                            style={[
                              styles.textSpec,
                              styles.textAlignCenter,
                              styles.profileAvatarText,
                            ]}
                            allowFontScaling={false}>
                            {props.userAvatarName.toUpperCase()}
                          </Text>
                        </View>
                      </View>
                    // </View>
                  ) : (
                    <Image
                      style={[styles.updatedProfileImage]}
                      source={require('../../assets/profile/profile-default.png')}
                    />
                  )}
                 
                  <View>
                    <View style={[styles.uploadImageEditOption]}>
                      <Image
                          style={[styles.uploadImageEditHalfCircle]}
                          source={require('../../assets/profile/Subtract.png')}
                      />
                    </View>

                    <TouchableOpacity 
                      onPress={() => {
                        if (Platform.OS !== WEB && imageUploadRef) {
                          imageUploadRef.onChange();
                        } else if (Platform.OS === WEB && imageUploadRef) {
                          inputRef = imageUploadRef.getInputRef();
                          if (inputRef) {
                            inputRef.current.click();
                          }
                        }
                      }} 
                      style={styles.uploadImageEditText}>
                        <Text style={[styles.textPrimeMdBold, styles.textWhite]} allowFontScaling={false}>
                          Edit
                        </Text>
                    </TouchableOpacity>
                  </View>
                </View>

                <View style={[styles.updatedProfileOptions]}>
                  <ImageUpload
                    onUploadSucess={props.onUploadSucess}
                    peer2peer={props.peer2peer}
                    imageUpload={props.imageUpload}
                    getImageUrl={props.getImageUrl}
                    disableRemoveBtn={
                      !(
                        props.profileImage ||
                        (props.imageLoading && props.uri) ||
                        props.tempProfileImage
                      )
                    }
                    onChangeProfileImage={props.onChangeProfileImage}
                    removeProfilePic={props.removeProfilePic}
                    uploadError={props.uploadError}
                    uploadErrorMesage={props.uploadErrorMesage}
                    getRef={ref => (imageUploadRef = ref)}
                  />
                </View>
              </View>

              <TouchableOpacity
                onPress={() => {
                  props.handleOnUploadProfileImage();
                }}
                disabled={
                  !(props.tempProfileImage || props.profilePicRemoved) ||
                  props.fileUploadWait ||
                  props.uploadError
                }>
                <View style={[styles.updatedProfileSaveBtn]}>
                  <View style={[styles.actIndRight]}>
                  {props.fileUploadWait && <ActivityIndicator size="small" />}
                  </View>
                  <Text
                    style={[styles.updatedProfileSaveBtnTxt]}
                    allowFontScaling={false}>
                    Save
                  </Text>
                </View>
              </TouchableOpacity>
            </>
          ) : (
            <ProfileImageCropper
              imageSrc={props.tempProfileImage}
              handleOnUploadProfileImag={props.handleOnUploadProfileImage}
              uploadError={props.uploadError}
              uploadErrorMesage={props.uploadErrorMesage}
              fileUploadWait={props.fileUploadWait}
              cancelCropping={props.cancelCropping}
            />
          )}
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};

export default (profileImageUploadPopup);
