import React, {Component} from 'react';
import {View, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import UIText from '../../components/Text/text';
import UITextInput from '../../components/TextInput/textInput';
import Header from '../../components/Header/header';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import SymptomItem from '../../components/Report/SymptomItem';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import {REPORTS} from '../../navigation/path';
import PageContainer from "../../components/PageLayout/PageContainer";
import { pendoTrackEvent } from '../../util/pendoConfig';

class ReportAddSymptomsScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recordedSymptom: props.recordedSymptom,
      filterRecordedSymptoms: this.filterRecordedSymptom(
        props.recordedSymptom,
        '',
      ),
      addList: [],
      removeList: [],
      searchKeyWord: '',
    };
  }

  componentDidMount () {
    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
      pendoTrackEvent('Reports/@AddSymptom');
    });
    this.props.navigation.addListener('blur', () => {
      this.props.showMobileMenu();
    });
  }

  removeDuplicateSymptom = filteredRecordedSymptom => {
    const unique = filteredRecordedSymptom
      .map(e => e['symptom'])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => filteredRecordedSymptom[e])
      .map(e => filteredRecordedSymptom[e]);

    return unique;
  };

  filterRecordedSymptom = (recordedSymptoms, searchKeyWord) => {
    let filterRecordedSymptoms = [];

    let startDate;
    if (this.props.startDate) {
      startDate = new Date(this.props.startDate.getTime());
      startDate.setHours(0, 0, 0, 0);
    }

    let endDate;

    if (this.props.endDate) {
      endDate = new Date(this.props.endDate.getTime());
      endDate.setHours(23, 59, 59, 59);
    }

    if (searchKeyWord) {
      filterRecordedSymptoms = recordedSymptoms.filter(item => {
        let symptomName;

        if (this.props.userSymptomsProcessed[item.symptom]) {
          symptomName = this.props.userSymptomsProcessed[item.symptom].name;
        }

        return (
          symptomName &&
          symptomName.toLowerCase().includes(searchKeyWord.toLowerCase())
        );
      });
    } else {
      filterRecordedSymptoms = recordedSymptoms;
    }

    if (startDate && endDate) {
      filterRecordedSymptoms = filterRecordedSymptoms.filter(
        item =>
          item.createdOn > startDate.getTime() &&
          item.createdOn < endDate.getTime(),
      );
    }
    const userSymptomsProcessed = this.props.userSymptomsProcessed;
    filterRecordedSymptoms.sort(function(a, b) {
      var textA = userSymptomsProcessed[a.symptom]
        ? userSymptomsProcessed[a.symptom].name
        : '';
      var textB = userSymptomsProcessed[b.symptom]
        ? userSymptomsProcessed[b.symptom].name
        : '';
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return this.removeDuplicateSymptom(filterRecordedSymptoms);
  };

  onSearchByKeyWord = val => {
    this.setState({
      searchKeyWord: val,
      filterRecordedSymptoms: this.filterRecordedSymptom(
        this.state.recordedSymptom,
        val,
      ),
    });
  };

  onSave = () => {
    this.props.saveReportSymptom(this.state.addList, this.state.removeList);
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: REPORTS.newReportPath }],
    });
  };

  cancelReport = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: REPORTS.newReportPath }],
    });
  };

  addRemoveSymptom = (isAdd, value) => {
    if (isAdd) {
      const addList = [...this.state.addList];
      addList.push(value);
      this.setState({addList: addList});
    } else {
      const removeList = [...this.state.removeList];
      removeList.push(value);
      this.setState({removeList: removeList});
    }
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => {
            this.props.navigation.goBack();
          }}
          index={REPORTS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        <KeyboardAwareScrollViewHoc>
          <View style={[styles.gridSection]}>
          <PageContainer>
            <View style={stylesRes.layoutPdFixed}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.secArea}>
                        <View style={[styles.formInnerArea]}>
                          <View style={[styles.fieldRow]}>
                            <UITextInput
                              style={[
                                styles.textField,
                                styles.textFieldWhite,
                                styles.textFieldSm,
                              ]}
                              onChangeText={this.onSearchByKeyWord}
                              placeholder="Search entries"
                              value={this.state.searchKeyWord}
                            />
                          </View>
                        </View>
                      </View>
                  </View>
                </View>
              </View>
            </PageContainer>
            <PageContainer smScreenFull={true}>
              <View style={stylesRes.contBotPd}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.secArea]}>
                      {this.state.filterRecordedSymptoms.length > 0 && (
                        <View style={[styles.listItemsAll]}>
                          {this.state.filterRecordedSymptoms.map(
                            (res, index) =>
                              Boolean(this.props.userSymptomsProcessed[res.symptom]) && (
                                <SymptomItem
                                  id={res.id}
                                  key={'ri_' + index}
                                  symptom={
                                    this.props.userSymptomsProcessed[
                                      res.symptom
                                    ]
                                  }
                                  data={res}
                                  addRemoveSymptom={this.addRemoveSymptom}
                                />
                              ),
                          )}
                        </View>
                      )}
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.btnArea, styles.btnMgSm]}>
                      <View style={stylesRes.btnViewSec}>
                        <View style={styles.fieldBtn}>
                          <View style={styles.btnBlockFull}>
                            <TouchableOpacity
                              style={[styles.submitSm, styles.disableBtn]}
                              underlayColor="rgba(0, 153, 168, 0.8)"
                              onPress={this.cancelReport}>
                              <View style={styles.btnSingleView}>
                                <View
                                  style={[
                                    styles.btnSingle,
                                    styles.btnSingleCent,
                                  ]}>
                                  <UIText
                                    style={styles.submitText}
                                    title="Cancel"
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View style={styles.fieldBtnSp} />
                        <View style={styles.fieldBtn}>
                          <View style={styles.btnBlockFull}>
                            <TouchableOpacity
                              onPress={this.onSave}
                              style={[styles.submitSm, this.state.filterRecordedSymptoms.length ? styles.btnActiveState : styles.btnBlurState]}
                              disabled={!this.state.filterRecordedSymptoms.length}>
                              <View style={styles.btnSingleView}>
                                <View
                                  style={[
                                    styles.btnSingle,
                                    styles.btnSingleCent,
                                  ]}>
                                  <UIText
                                    style={styles.submitText}
                                    title="Save"
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              </PageContainer>
          </View>
        </KeyboardAwareScrollViewHoc>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    recordedSymptom: state.routines.recordedSymptom,
    startDate: state.report.startDate,
    endDate: state.report.endDate,
    userSymptomsProcessed: state.routines.symptomsDigestProcessed.userSymptoms,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    saveReportSymptom: (addList, removeList) =>
      dispatch(actions.saveReportSymptom(addList, removeList)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportAddSymptomsScreen);
