import React, {useRef} from "react";
import { View, TouchableOpacity, Text, Image } from "react-native";
import styles from "../../../Css";
import Popover from "../../Popover";

import CaregiverList from "./caregiverList";
const SwitchProfileSection = (props) => {
  let touchable = useRef();

  const {
    onSwitchProffile,
    showCaregiverList,
    handleClose,
    changeJournalUser,
    sharedAccessGivenToMe,
    sharedAccessUserSummaries,
    sharedAccessDigestProcessed,
    setUpOwnJournal,
    myDetail,
    anchorEl,
    accountType,
    navigation,
    showAdd,
    disableAddCaregiverNavigation,
    journalUserId,
    showAddCareiver
  } = props;

  return (
    <>
      <View style={styles.headerCenterBlock}>
        <View style={[styles.switchBlock, styles.postRelative]}>
          <TouchableOpacity
            onPress={onSwitchProffile}
            ref={touchable}
            nativeID={'headerSwitchProfile'}
          >
            <View style={styles.switchMenu}>
              <Image
                style={styles.switchMenuBgCom}
                source={require("../../../assets/icons/switch-button.png")}
              />

              <View style={styles.ovalBtnTextView}>
                <Text style={[styles.textPrimeSmBold, styles.textBlueDark]} allowFontScaling={false}>
                  Switch Profile
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      <Popover
        open={showCaregiverList}
        handleClose={handleClose}
        touchable={touchable}
        anchorEl={anchorEl}
        arrowAvailable={true}
        popoverId={"switchProfile"}
      >
        <CaregiverList
          changeJournalUser={changeJournalUser}
          sharedAccessGivenToMe={sharedAccessGivenToMe}
          sharedAccessUserSummaries={sharedAccessUserSummaries}
          sharedAccessDigestProcessed={sharedAccessDigestProcessed}
          setUpOwnJournal={setUpOwnJournal}
          myDetail={myDetail}
          accountType={accountType}
          navigation={navigation}
          showAdd={showAdd}
          disableAddCaregiverNavigation={disableAddCaregiverNavigation}
          journalUserId={journalUserId}
          showAddCareiver={showAddCareiver}
          handleClose={handleClose}
        />
      </Popover>
    </>
  );
};

export default (SwitchProfileSection);
