import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  saveQuestionToAttach: "",
  journalEntriesAdd: null
};

const saveQuestionToAttach = (state, action) => {
  return updateObject(state, {
    saveQuestionToAttach: action.saveQuestionToAttach
  });
};

const addRemoveJournal = (state, action) => {
  let id = null;
  if (
    state.journalEntriesAdd == null ||
    state.journalEntriesAdd !== action.id
  ) {
    id = action.id;
  }

  return updateObject(state, {
    journalEntriesAdd: id
  });
};

const removeAllJournal = (state, action) => {
  return updateObject(state, {
    journalEntriesAdd: null
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_QUESTION_TO_ATTACH:
      return saveQuestionToAttach(state, action);
    case actionTypes.ADD_REMOVE_JOURNAL:
      return addRemoveJournal(state, action);
    case actionTypes.REMOVE_ALL_JOURNAL:
      return removeAllJournal(state, action);
    default:
      return state;
  }
};

export default reducer;
