import React, {Component} from 'react';
import {View, SafeAreaView, Platform} from 'react-native';
import {connect} from 'react-redux';
import styles from '../../Css';
import Header from '../../components/Header/header';
import * as actions from '../../store/actions/index';
import {SetupLayout} from '../../components/Dashboard';
import {DASHBOARD} from '../../navigation/path';
import {tabs} from '../../Constant';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import { IOS } from '../../util/platformWindowConfig';

class Setup extends Component {
  constructor(props) {
    super(props);
    const {activeTab} = props;
    this.state = {
      activeTab: activeTab,
    };
  }

  componentDidMount() {
    this.props.hideMobileMenu();
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    });
  }

  componentWillUnmount() {
    this.props.showMobileMenu();
  }

  static getDerivedStateFromProps(props, state) {
    const {activeTab} = props;
    if (activeTab !== state.activeTab) {
      return {
        activeTab: activeTab,
      };
    }

    return null;
  }

  redirectDashboard = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: DASHBOARD.path }],
    });
  };

  render() {
    const {setActiveTab} = this.props;
    const {activeTab} = this.state;
    return (
      <View style={[styles.pageBodyWrapper]} onTouchStart={() => this.props.onTouchRoutine(true)}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => {
            handleCommonBackNavigation(this.props.navigation);
          }}
          index={DASHBOARD.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />

        <SafeAreaView style={[styles.bgWhite, styles.flexCom]}>
          <KeyboardAwareScrollViewHoc
            setupScrollStyle={styles.contentAreaViewApp}
            extraScrollHeight={200}>
            <View
              style={[
                styles.flexJcSb,
                Platform.OS !== IOS && styles.gridWrapperFullApp,
                styles.colPd,
              ]}>
              <SetupLayout
                tabs={tabs}
                visibleModal={false}
                closeModal={this.redirectDashboard}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </View>
          </KeyboardAwareScrollViewHoc>
        </SafeAreaView>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    activeTab: state.setup.activeTab,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setActiveTab: value => dispatch(actions.setSetupActiveTab(value)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    onTouchRoutine: flag =>
      dispatch(actions.onTouchRoutine(flag)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Setup);
