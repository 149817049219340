import React from "react";
import { Image, View, TouchableOpacity, Text } from "react-native";
import styles from '../../../../Css';

const CopyFromPrev = (props) => {

  const { onPress, nativeID, disabled } = props;

  return (
    <React.Fragment>
        <View style={[styles.flexRow, styles.flexJcCt, styles.flexAiCt, styles.comSpMd]}>
        <TouchableOpacity
          nativeID={nativeID}
          onPress={onPress}
          disabled={disabled}
        >
            <View style={[styles.copyFromPrev, styles.flexRow, styles.flexJcCt, styles.flexAiCt]}>
                <Image
                    style={styles.copyNewIcon}
                    source={require("../../../../assets/icons/copy-from-previous-white.png")}
                    resizeMode={"contain"}
                />
                <Text style={[styles.textPrimeBold, styles.textWhite]} allowFontScaling={false}> Copy from previous</Text>
            </View>
            </TouchableOpacity>
        </View>
    </React.Fragment>
  );
};
export default CopyFromPrev;
