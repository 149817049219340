import theme from "./base/theme";
import * as variable from "./base/variable";
import * as variableShadow from "../css/base/variableShadow";

export const csstimeZoneField = {
    timeZoneField: {
        ...variable.textFieldPrimary,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderTopLeftRadius: 8,
        paddingTop:10,
        paddingBottom:10,
        paddingLeft:10,
        paddingRight:50,
        height:50,
        width:'100%',
    },

    textSelectBordeRad:{
        borderTopLeftRadius:8, 
        borderTopRightRadius:8, 
        borderBottomLeftRadius:8, 
        borderBottomRightRadius:8
    },

    textDeSelectBordeRad: {
        borderTopLeftRadius:8, 
        borderTopRightRadius:8, 
        borderBottomLeftRadius:0, 
        borderBottomRightRadius:0
    },

    heightFourtyFive: {
        height: 45
    },

    mxHeightHudredEightyFive: {
        maxHeight: 185
    },

    dropDwnSelectFd: {
        borderTopWidth:1,
        borderTopColor:theme.COLOR_GREY_LG_5
    },

    timeZoneRadioBtn : {
        width:24,
        height:24,
        marginTop:1
    },

    timeZoneDropDown: {
        position:'absolute', 
        right:15, 
        top:20
    },

    timeZoneDropDownIcn: {
        height:11, 
        width:21
    },

    zIndexMinusNine: {
        zIndex: -9
    },

    zIndexMinusTen: {
      zIndex: -10
    },

    ltTwenty: {
        left: 20
    },

    timeZoneNotifiTxt: {
        color: theme.COLOR_ASH_2
    },

    sixtyPerWd: {
        width: '60%'
    },

    timeZoneUpdateBtn: {
        width: 70, 
        height: 35, 
        alignItems:'center',
        backgroundColor:theme.COLOR_BLUE_DARK_2, 
        borderRadius: 24, 
        justifyContent:'center', 
        marginRight:25
    },

    crossNotifyIcn: {
        width: 15,
        height: 15
    },

    minHeightFiftySeven: {
        minHeight: 57
    },

    successPopUpAlgn: {
        width:220, 
        marginLeft:'auto', 
        marginRight:'auto'
    },

    borderBtmWdZero: {
        borderBottomWidth:0
    },

    timeZoneAutoCompleteShadow:{
        ...variableShadow.shadowStyleEleven
    },

    timeZoneZctivityInd: {
        position: 'absolute', 
        left: -40
    }
    
};
