import React, {useState} from 'react';
import {View, Text, Image, TouchableOpacity, Platform} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Header from '../../components/Header/header';
import AllergyList from '../../components/Allergies/AllergyList';
import ConditionsList from '../../components/Conditions/ConditionsList';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import ProfileHeader from './ProfileHeader';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import Security from './Security';
import ChangePassword from './ChangePassword';
import ToolTip from '../../components/ToolTip';
import KeyboardAwareScrollViewHoc from '../KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import PageContainer from '../../components/PageLayout/PageContainer';
import {
  isSubscriptionSponsored,
  isSubscriptionCancelled,
} from '../../util/subscriptionInformation';
import {
  freeStorage,
  premiumStorage,
  freeStorageByte,
  premiumStorageByte,
  currentAppVersion
} from '../../Constant';
import DeviceInfo from 'react-native-device-info';
import TimeZone from './TimeZone';
import { WEB } from '../../util/platformWindowConfig';

let scrollViewRef;

let isOpenChangePassword = false;
const ViewProfile = props => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  const scrollToSecurity = isOpen => {
    if (scrollViewRef && !isOpenChangePassword && scrollViewRef.scrollToEnd) {
      setTimeout(() => scrollViewRef.scrollToEnd(0, 160), 500);
    }
  };
  const scrollToChangePassword = isOpen => {
    isOpenChangePassword = isOpen;

    if (scrollViewRef && scrollViewRef.scrollToEnd) {
      setTimeout(() => scrollViewRef.scrollToEnd(0, 320), 500);
    }
  };

  const scrollToTop = (isOpen, callback, data) => {
    isOpenChangePassword = isOpen;
    if (scrollViewRef) {
      setTimeout(() => {
        Platform.OS === WEB
          ? scrollViewRef?.scrollTo({ x: 0, y: 0, animated: true })
          : scrollViewRef?.scrollToPosition(0, 0, true);

        }, 500);
        callback(2, null, data);
    }
  };


  return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={props.handleOnPressBackButton}
          index={props.index}
          navigation={props.navigation}
          route={props.route}
        />
        <SpaceBar />
        {props.loading ? (
          <ActivityIndicator />
        ) : (
          <KeyboardAwareScrollViewHoc
            hasNotch={props.hasNotch}
            extraScrollHeight={100}
            ref={val => (scrollViewRef = val)}
            scrollEnabled={props.scrollEnabled}>
            <View style={styles.gridSection}>
              <View style={[stylesRes.contentSection, stylesRes.contBotSmPdFixed]}>
                <PageContainer smScreenFull={true}>
                  <ProfileHeader
                    firstName={props.firstName}
                    lastName={props.lastName}
                    userId={props.userId}
                    redirectEditProfile={props.redirectEditProfile}
                    journalUserId={props.journalUserId}
                  />
                </PageContainer>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.cardArea}>
                      <View style={styles.cardDetailSec}>
                        <PageContainer smScreenFull={true}>
                          <View
                            style={[
                              styles.secSubBlock,
                              styles.greyBorderThin,
                              styles.comMgBotRg,
                              styles.viewMgTopMd,
                            ]}>
                            <View style={[styles.contFull]}>
                              <View style={[styles.contRow]}>
                                <View
                                  style={[
                                    styles.premiumUpgradeConteArea,
                                    styles.premiumBoxSmWrapper,
                                  ]}>
                                  <View style={[styles.titleWrapperSm]}>
                                    <View
                                      style={[
                                        styles.mainTitleSection,
                                        styles.mainTitlePd,
                                      ]}>
                                      <View>
                                        <TouchableOpacity
                                          onPress={
                                            props.accountType === 'regular' ||
                                            (props.currentSubscription &&
                                              props.currentSubscription
                                                .subscriptionStatus === 'TRIAL')
                                              ? props.redirectPremiumAccount
                                              : props.redirectCancelSubscription
                                          }
                                          disabled={
                                            props.journalUserId ||
                                            (props.subscriptionMadeBy &&
                                              props.subscriptionMadeBy ===
                                                'IOS') ||
                                            props.subscriptionIsCancel ||
                                            (props.currentSubscription &&
                                              isSubscriptionSponsored(
                                                props.currentSubscription,
                                              ))
                                          }>
                                          <Text
                                            style={[
                                              styles.textPrime,
                                              styles.textOrange,
                                            ]}
                                            allowFontScaling= {false}
                                            >
                                            {props.subscriptionPlanDescription}
                                          </Text>
                                        </TouchableOpacity>
                                      </View>
                                    </View>
                                    <View
                                      style={[styles.attachmentDataSmSection]}>
                                      <View style={styles.attachmentDataSmFull}>
                                        <View
                                          style={styles.attachmentDataComView}>
                                          <View
                                            style={styles.attachmentDataSmTitle}>
                                            <Text
                                              style={[styles.textPrimeSmBold]}>
                                              Data usage:{' '}
                                            </Text>
                                          </View>
                                          <View style={styles.attachmentDataSub}>
                                            <Text
                                              style={[
                                                styles.textPrimeSm,
                                                styles.textColorLight,
                                              ]}
                                              allowFontScaling= {false}
                                              >
                                              {props.accountType === 'regular'
                                                ? 'Used ' +
                                                  (
                                                    Number.parseInt(
                                                      (props.totalUsageByte /
                                                        freeStorageByte) *
                                                        1000,
                                                    ) / 10
                                                  ).toFixed(1) +
                                                  '% of ' +
                                                  freeStorage
                                                : 'Used ' +
                                                  (
                                                    Number.parseInt(
                                                      (props.totalUsageByte /
                                                        premiumStorageByte) *
                                                        1000,
                                                    ) / 10
                                                  ).toFixed(1) +
                                                  '% of ' +
                                                  premiumStorage}
                                            </Text>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>
                              {Boolean(props.subscriptionMadeByDes) &&
                                Boolean(props.subscriptionType) && (
                                  <View
                                    style={[
                                      styles.contRow,
                                      styles.colPdTopBotRg,
                                    ]}>
                                    <View style={styles.flexJcCtAiCt}>
                                      <View style={styles.subscriptionInfoView}>
                                        <Text style={styles.textPrimeSm} allowFontScaling= {false}>
                                          {props.subscriptionMadeByDes} |{' '}
                                          {props.subscriptionType}
                                        </Text>
                                      </View>
                                      <View style={styles.subscriptionDateView}>
                                        <Text
                                          style={[
                                            styles.textPrimeSm,
                                            styles.textGrey,
                                          ]}
                                          allowFontScaling= {false}
                                          >
                                          {props.currentSubscription &&
                                          isSubscriptionCancelled(
                                            props.currentSubscription,
                                          )
                                            ? 'Subscription expires on: '
                                            : 'Next billing date: '}
                                          {props.nextBillingDate}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                )}

                              <View style={[styles.contRow]}>
                                <View style={[styles.cardContent]}>
                                  <View style={styles.contColFul}>
                                    <View
                                      style={[
                                        styles.greyBorderThinTop,
                                        styles.contPdTop,
                                      ]}>
                                      <View
                                        style={[
                                          styles.contTitle,
                                          styles.fiedBotPd,
                                          styles.cardRowPdLtRt,
                                        ]}>
                                        <Text style={[styles.textPrimeBold]} allowFontScaling= {false}>
                                          Medical Condition(s):
                                        </Text>
                                      </View>
                                    </View>
                                    <View style={styles.contDesc}>
                                      <View
                                        style={[
                                          styles.addedSecFull,
                                          styles.pdBotExSm,
                                          styles.pdTopExSm,
                                        ]}>
                                        {/* Loop this View (styles.addedItemRow) - Start */}
                                        <ConditionsList />
                                        {/* Loop this View (styles.addedItemRow) - End */}
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>

                              <View style={[styles.contRow]}>
                                <View
                                  style={[
                                    styles.cardContent,
                                    // styles.cardRowPdLtRtTop
                                  ]}>
                                  <View style={styles.contColFul}>
                                    <View
                                      style={[
                                        styles.contTitle,
                                        styles.fiedBotPd,
                                        styles.cardRowPdLtRt,
                                        styles.topPdSm,
                                      ]}>
                                      <Text style={[styles.textPrimeBold]} allowFontScaling= {false}>
                                        Allergie(s):
                                      </Text>
                                    </View>
                                    <View style={styles.contDesc}>
                                      <View
                                        style={[
                                          styles.addedSecFull,
                                          styles.pdBotExSm,
                                          styles.pdTopExSm,
                                        ]}>
                                        {/* Loop this View (styles.addedItemRow) - Start */}
                                        <AllergyList editMode={false} />
                                        {/* Loop this View (styles.addedItemRow) - End */}
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                            {/* </View> */}
                          </View>
                        </PageContainer>

                        <PageContainer smScreenFull={true}>
                          <View style={[styles.secSubBlock]}>
                            <View style={[styles.contFull]}>
                              <View style={[styles.contRow]}>
                                <View
                                  style={[
                                    styles.cardContent,
                                    styles.cardRowPd,
                                    styles.noPdTop,
                                  ]}>
                                  <View style={styles.contCol}>
                                    <View
                                      style={[
                                        styles.contTitle,
                                        styles.fiedBotPd,
                                      ]}>
                                      <Text
                                        style={[
                                          styles.textPrimeBold,
                                          // styles.textColorLight,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        Date of Birth:
                                      </Text>
                                    </View>
                                    <View style={styles.contDesc}>
                                      <Text
                                        style={[
                                          styles.textPrimeBold,
                                          styles.textGrey,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        {props.dob}
                                      </Text>
                                    </View>
                                  </View>
                                  <View style={styles.contCol}>
                                    <View
                                      style={[
                                        styles.contTitle,
                                        styles.fiedBotPd,
                                      ]}>
                                      <Text
                                        style={[
                                          styles.textPrimeBold,
                                          // styles.textColorLight,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        Sex:
                                      </Text>
                                    </View>
                                    <View style={styles.contDesc}>
                                      <Text
                                        style={[
                                          styles.textPrimeBold,
                                          styles.textGrey,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        {props.gender}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </View>

                              <View style={[styles.contRow]}>
                                <View
                                  style={[
                                    styles.cardContent,
                                    styles.cardRowPd,
                                    styles.noPdTop,
                                  ]}>
                                  <View style={styles.contCol}>
                                    <View
                                      style={[
                                        styles.contTitle,
                                        styles.fiedBotPd,
                                      ]}>
                                      <Text
                                        style={[
                                          styles.textPrimeBold,
                                          // styles.textColorLight,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        Height:
                                      </Text>
                                    </View>
                                    <View style={styles.contDesc}>
                                      <Text
                                        style={[
                                          styles.textPrimeBold,
                                          styles.textGrey,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        {props.height} {props.heightUomSymbol}
                                      </Text>
                                    </View>
                                  </View>
                                  <View style={styles.contCol}>
                                    <View
                                      style={[
                                        styles.contTitle,
                                        styles.fiedBotPd,
                                      ]}>
                                      <Text
                                        style={[
                                          styles.textPrimeBold,
                                          // styles.textColorLight,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        Weight:
                                      </Text>
                                    </View>
                                    <View style={styles.contDesc}>
                                      <Text
                                        style={[
                                          styles.textPrimeBold,
                                          styles.textGrey,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        {props.weight} {props.weightUomSymbol}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </View>

                              <View style={[styles.contRow]}>
                                <View
                                  style={[
                                    styles.cardContent,
                                    styles.cardRowPd,
                                    styles.noPdTop,
                                  ]}>
                                  <View style={styles.contCol}>
                                    <View
                                      style={[
                                        styles.contTitle,
                                        styles.fiedBotPd,
                                        styles.toolTipRow,
                                      ]}>
                                      <Text
                                        style={[
                                          styles.textPrimeBold,
                                          // styles.textColorLight,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        Ethnicity:
                                      </Text>
                                      <View
                                        style={[
                                          styles.helpCircleComView,
                                          styles.helpCircleMg,
                                        ]}>
                                        <ToolTip
                                          id={'ethnicity-data'}
                                          text={
                                            'Precision medicine focuses on genetics. Ethnicity can help researchers learn about symptoms or outcomes in subpopulations. This information is optional and not displayed on your reports.'
                                          }
                                          disabledScroll={props.disabledScroll}
                                        />
                                      </View>
                                    </View>
                                    <View style={styles.contDesc}>
                                      <Text
                                        style={[
                                          styles.textPrimeBold,
                                          styles.textGrey,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        {props.ethnicity}
                                      </Text>
                                    </View>
                                  </View>
                                  <View style={styles.contCol}>
                                    <View
                                      style={[
                                        styles.contTitle,
                                        styles.fiedBotPd,
                                      ]}>
                                      <Text
                                        style={[
                                          styles.textPrimeBold,
                                          // styles.textColorLight,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        Location:
                                      </Text>
                                    </View>
                                    <View style={styles.contDesc}>
                                      <Text
                                        style={[
                                          styles.textPrimeBold,
                                          styles.textGrey,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        {(props.city
                                          ? props.city.trim() + ','
                                          : '') +
                                          (props.state ? props.state + ',' : '') +
                                          (props.country ? props.country : '')}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </View>

                              <View style={[styles.contRow]}>
                                <View
                                  style={[styles.cardContent, styles.cardRowPd]}>
                                  <View style={styles.contColFul}>
                                    <View
                                      style={[
                                        styles.contComText,
                                        styles.flexRowJcCtAiCt,
                                      ]}>
                                      <Text
                                        style={[
                                          styles.textPrime,
                                          styles.textAlignCenter,
                                          styles.textGrey,
                                        ]}
                                        allowFontScaling= {false}
                                        >
                                        Your privacy is our priority.
                                      </Text>
                                      <TouchableOpacity
                                        style={[styles.mgLtExSm]}
                                        onPress={props.toggleLearnHow}>
                                        <Text
                                          style={[
                                            styles.textPrime,
                                            styles.textOrange,
                                            styles.textAlignCenter,
                                          ]}
                                          allowFontScaling= {false}
                                          >
                                          Learn How
                                        </Text>
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                            {/* </View> */}
                          </View>
                        </PageContainer>
                      </View>
                    </View>
                  </View>
                </View>

                <PageContainer smScreenFull={true}>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={[styles.linkViewList]}>
                        <View>
                          <TimeZone
                            showSuccessPopup={props.showSuccessPopup}
                            timeZoneList={props.timeZoneList}
                            selectedTimeZone={props.selectedTimeZone}
                            processing={props.processing}
                            updateTimeZone={props.updateTimeZone}
                            journalUserId={props.journalUserId}
                            updateTimeZoneDropDownOpen={(value) => {
                              setOpenDropdown(value);
                              if (scrollViewRef && value && scrollViewRef.scrollToEnd) {
                                setTimeout(() => scrollViewRef.scrollToEnd(), 500);
                              }
                            }}
                          />
                        </View>
                        
                        {/* Measurements */}
                        <View
                          style={[
                            styles.linkViewItemSingle,
                            styles.borderTopMenu,
                            styles.zIndexMinusNine
                          ]}>
                          <TouchableOpacity
                            onPress={props.redirectMeasurements}
                            style={[
                              styles.linkViewItem,
                              styles.flexRowJcCtAiCt,
                              styles.itemPd,
                            ]}
                            nativeID={'profileMeasurements'}>
                            <View style={styles.linkViewItemTextSec}>
                              <Text style={[styles.textTitle]} allowFontScaling= {false}>Measurements</Text>
                            </View>
                            <View style={styles.linkViewItemIconSecLg}>
                              <Image
                                style={[styles.arrowCloseLg]}
                                source={require('../../assets/icons/arrow-next-dark.png')}
                              />
                            </View>
                          </TouchableOpacity>
                        </View>
                        
                        {/* Security */}
                        {!Boolean(props.journalUserId) && (
                          <View style={styles.zIndexMinusNine}>
                          <Security
                            showSuccessPopup={props.showSuccessPopup}
                            scrollTo={scrollToSecurity}
                            disabledScroll={props.disabledScroll}
                            biometricToggle={props.biometricToggle}
                          />
                          </View>
                        )}

                        {/* Change Password */}
                        {!Boolean(props.journalUserId) && (
                          <View style={styles.zIndexMinusTen}>
                          <ChangePassword
                            showSuccessPopup={props.showSuccessPopup}
                            scrollTo={scrollToChangePassword}
                            scrollToTop={scrollToTop}
                            biometricToggle={props.changeBiometricToggle}
                          />
                          </View>
                        )}

                        {/* Sign Out from Everywhere */}
                        {!Boolean(props.journalUserId) && (
                          <View
                            style={[
                              styles.linkViewItemSingle,
                              styles.borderTopMenu,
                              styles.zIndexMinusTen
                            ]}>
                            <TouchableOpacity
                              onPress={props.redirectMyDevices}
                              style={[
                                styles.linkViewItem,
                                styles.flexRowJcCtAiCt,
                                styles.itemPd,
                              ]}>
                              <View style={styles.linkViewItemTextSec}>
                                <Text style={[styles.textTitle]} allowFontScaling= {false}>
                                  Sign out Everywhere
                                </Text>
                              </View>
                              <View style={styles.linkViewItemIconSecLg}>
                                <Image
                                  style={[styles.arrowCloseLg]}
                                  source={require('../../assets/icons/arrow-next-dark.png')}
                                />
                              </View>
                            </TouchableOpacity>
                          </View>
                        )}

                        {/* Delete this account */}
                        {!Boolean(props.journalUserId) && (
                          <View style={styles.zIndexMinusNine}>
                          <TouchableOpacity onPress={props.onPressDelete}>
                            <View style={ styles.secDivider }>
                              <View
                                style={ styles.cardRowPdLtRtTop }>
                                <Text
                                  style={ [
                                  styles.textPrime,
                                  styles.textAlignCenter,
                                  styles.textRed,
                                  ] }
                                  allowFontScaling={ false }
                                >
                                Delete this Account
                                </Text>
                              </View>
                            </View>
                          </TouchableOpacity>
                          </View>
                        )}
                        <View style={[styles.secDivider, styles.zIndexMinusNine]}>
                          <View
                            style={ styles.cardRowPd }>
                            <Text
                              style={ [
                                styles.textPrime,
                                styles.textAlignCenter,
                                styles.textGrey,
                              ] }
                              allowFontScaling={ false }
                            >
                              App Version { Platform.OS !== WEB ? DeviceInfo.getVersion() : currentAppVersion }
                            </Text>
                          </View>
                        </View>
                        {Boolean(props.journalUserId) && Boolean(openDropdown) &&
                          <View style={[styles.zIndexMinusNine, {height: 60}]}/>
                        }
                      </View>
                    </View>
                  </View>
                </PageContainer>
                {/* </View> */}
              </View>
            </View>
          </KeyboardAwareScrollViewHoc>
        )}
      </View>
  );
};

export default (ViewProfile);
