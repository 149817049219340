export const newLineCharReplacement = text => {
  let temp = "";
  [...text].forEach( (t,i) => {
    if ( text.charCodeAt(i) === 10 ) {
      if( text.charCodeAt(i-1) !== 32 ) {
        temp = temp + " ";
      }
    } else {
      temp = temp + t
    }
  });
  return temp;
}

export const nullableStringCompare = (value1, value2) => {

  const x = value1 === undefined ? null : value1;
  const y = value2 === undefined ? null : value2;

  if (x === null && y !== null) {
    return -1;
  }

  if (x === null && y === null) {
    return 0;
  }

  if (x !== null && y === null) {
    return 1;
  }

  return x.localeCompare(y);
}