import React, {Component} from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import styles from '../../../../Css';
import Collapsible from '../../../Collapsible/Collapsible';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextFrame from '../../../TextInput/textFrame';
import ActivityTooltipContent from '../../../TooltipContent/Activity';
import {getSortedActivityDosagesWithTreatmentObject} from "../../../../store/actions";
import { TAB_BREAk_MAX_WIDTH } from '../../../../util/platformWindowConfig';

class Activity extends Component {
  constructor(props) {
    super(props);
    const {dosages} = props;
    const filteredActivities = dosages.filter(dosage => dosage.quantity !== null);
    const sortedActivityDosagesWithTreatmentObject = getSortedActivityDosagesWithTreatmentObject(filteredActivities, false, props.treatmentsProcessed);
    this.state = {
      dosages: sortedActivityDosagesWithTreatmentObject,
      visibleActivity: filteredActivities && filteredActivities.length > 0,
    };
  }


  static getDerivedStateFromProps(props, state) {
    const {dosages, windowWidth} = props;
    const filteredActivities = dosages.filter(dosage => dosage.quantity !== null);
    const sortedActivityDosagesWithTreatmentObject = getSortedActivityDosagesWithTreatmentObject(filteredActivities, false, props.treatmentsProcessed);
    if (windowWidth > TAB_BREAk_MAX_WIDTH && JSON.stringify(state.dosages) !== JSON.stringify(filteredActivities)) {
      return {
        dosages: sortedActivityDosagesWithTreatmentObject,
        visibleActivity: filteredActivities && filteredActivities.length > 0,
      }
    }
    return null;
  }

  getItem = (item, index) => {
    const {quantity, treatment} = item;
    const {
      treatmentsProcessed,
      windowWidth,
      handleRedirectActivity,
    } = this.props;

    const {name} = item.treatmentObj;
    let units;
    let instruction = null;
    //let treatmentObj = {};

    if (
      treatmentsProcessed[item.treatment] &&
      treatmentsProcessed[item.treatment].dosages &&
      treatmentsProcessed[item.treatment].dosages.length > 0
    ) {
      //treatmentObj = treatmentsProcessed[item.treatment];
      units = treatmentsProcessed[item.treatment].dosages[0].units ?  treatmentsProcessed[item.treatment].dosages[0].units : 'minutes';
      instruction = treatmentsProcessed[item.treatment].dosages[0].instructions
        ? treatmentsProcessed[item.treatment].dosages[0].instructions
        : null;
    }

    // if (!units || units === 'minutes') {
    //   return (
    //     <ActivitySliderView
    //       quantity={quantity}
    //       name={name}
    //       key={'je_act_' + index}
    //       data={item}
    //       windowWidth={windowWidth}
    //       treatmentsProcessed={treatmentsProcessed}
    //       handleRedirectActivity={handleRedirectActivity}
    //     />
    //   );
    // }
    return (
      <View style={styles.fieldContainer} key={'je_act_' + index}>
        <View style={[styles.fieldRowWrapper]}>
          <View style={styles.fieldColPlusMinus}>
            <TextFrame
              title={'Quantity'}
              fieldBg={FieldDetails.BGGREY}
              fieldAlign={FieldDetails.ALIGNCENTER}
              fieldValue={quantity}
              editable={false}
            />
          </View>
          <View style={styles.fieldColSp} />

          <View style={styles.fieldColMed}>
            <TextFrame
              title={'Measure'}
              fieldBg={FieldDetails.BGGREY}
              fieldInfo={false}
              fieldValue={units}
              editable={false}
            />
          </View>

          <View style={styles.fieldColSp} />
          <View style={styles.fieldColStretch}>
            <TextFrame
              title={'Activity'}
              fieldBg={FieldDetails.BGGREY}
              fieldValue={name}
              fieldInfo={instruction ? true : false}
              editable={false}
              renderComponent={() => (
                <ActivityTooltipContent
                  treatment={treatmentsProcessed[item.treatment]}
                  handleRedirectActivity={handleRedirectActivity}
                  // windowWidth={this.props.windowWidth}
                />
              )}
              noOfLines={1}
            />
          </View>
        </View>
      </View>
    );
  };

  render() {
    const {visibleActivity, dosages} = this.state;
    const {treatmentsProcessed} = this.props;

    return (
      <>
      {dosages && dosages.length > 0 ? ( 
        <Collapsible
        title="Activity"
        contentVisibility={visibleActivity}
        handleOnPressCollapsible={() => {
          this.setState({
            visibleActivity: !this.state.visibleActivity,
          });
        }}
        disabled={dosages && dosages.length > 0 ? false : true}
        style={[styles.collapseTitle]}
        customTitleStyle={true}
        topBorder={true}>
        {dosages.length > 0 && (
          <View
            style={[
              styles.collapseContent,
              styles.colPd,
              styles.colPdBotSm,
              styles.bgThinGrey,
            ]}>
            <View style={[styles.fieldContainer, styles.fieldRowNoMg]}>
              <View style={[styles.fieldRowSliderWrapper]}>
                {/* Loop Item for Activity Slider - Start */}
                {dosages.map(
                  (item, index) =>
                    treatmentsProcessed[item.treatment] &&
                    this.getItem(item, index),
                )}

                {/* Loop Item for Activity Slider - End */}
              </View>
            </View>
          </View>
        )}
      </Collapsible> 
      )
      : null }
      
      </> 
    );
  }
}

const mapStateToProps = state => {
  return {
    treatmentsProcessed: state.routines.treatmentsProcessed,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(
  mapStateToProps,
  null,
)(Activity);
