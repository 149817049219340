import { DASHBOARD } from "../../navigation/path";
import {updateObject} from "../../shared/utility";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
    redirectPath: DASHBOARD.path,
    deeplinkNotYetHandled: false
};

const updatePathByIntent = (state, action) => {
    return updateObject(state, {
        redirectPath: action.path,
        deeplinkNotYetHandled: true
    });
};

const updateDeeplinkHandled = (state, action) => {
    return updateObject(state, {
        // redirectPath: '/',
        deeplinkNotYetHandled: false
    });
};

const resetRedirectToInitialState = (state, action) => {
    return updateObject(state, {
        redirectPath: '/',
        deeplinkNotYetHandled: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PATH_BY_INTENT:
            return updatePathByIntent(state, action);
        case actionTypes.UPDATE_DEEPLINK_HANDLED:
            return updateDeeplinkHandled(state, action);
        case actionTypes.RESET_REDIRECT_TO_INITIAL_STATE:
            return resetRedirectToInitialState(state, action);
        default:
            return state;
    }
};

export default reducer;
