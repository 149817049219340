import React, {Component} from 'react';
import {View, Text, Image} from 'react-native';
import styles from '../../../../Css';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import {hasValue} from '../../../../util/hashValueGenerator';
import {AddHealthData, HealthDataList} from '../../../Setup/HelathData';
import {HealthDataFooter} from '../SetupFooter';
import MessageBox from '../../../Text/messageBox';
import SetupCancelConfirmationBox from '../setupCancelConfirmationBox';

class HealthDataContent extends Component {
  constructor(props) {
    super(props);
    const {windowWidth, windowHeight, metrics = [], saveDisable} = props;
    const metricsfiltered = metrics && metrics.filter(res => res.id !== -1);
    this.state = {
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      metrics: metrics ? metrics : [],
      saveWait: false,
      nextWait: false,
      visibleCloseConfirmationModal: false,
      inActive: true,
      hasValue: hasValue(JSON.stringify(metricsfiltered)),
      saveDisable: saveDisable
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth, metrics = [], saveDisable} = props;
    if (
      windowHeight !== state.windowHeight ||
      windowWidth !== state.windowWidth ||
      (metrics && metrics.length !== state.metrics.length) ||
      saveDisable !== state.saveDisable
    ) {
      return {
        windowWidth: windowWidth,
        windowHeight: windowHeight,
        metrics: metrics,
        inActive: !metrics || (metrics && metrics.length === 0),
        saveDisable: saveDisable
      };
    }

    return null;
  }

  update = (callBlack, from) => {
    if (this.props.metrics && this.props.metrics.length > 0) {
      let haveError = false;
      let newMetrics = Object.assign([], this.props.metrics);
      this.props.metrics.forEach((item, index) => {
        if (!item.uomId) {
          haveError = true;
          newMetrics[index].error = true;
        }
      });
      if (haveError) {
        this.props.updateMetric(newMetrics, () => 5);
        return;
      }
      const updateMetric = [];
      if (from === 'SAC') {
        this.setState({saveWait: true});
      } else {
        this.setState({nextWait: true});
      }
      this.props.metrics.forEach((res, index) => {
        let uomId = null;
        if (res.uomId) {
          uomId = res.uomId;
        }
        if (!res.typeOfMeasurement) {
          res.typeOfMeasurement = this.getTypeOfMeasurement(uomId);
        }
        this.props.updateUserMetric(res, (success, data) => {
          if (success) {
            data.metric = res.metric;
            data.uomId = uomId;
            updateMetric.push(data);
            if (uomId) {
              const metricDefaultUnitOfMeasure = {
                id: -1,
                createdOn: null,
                modifiedOn: null,
                metricId: data.id,
                uomId: uomId,
              };
              this.props.updateUserMetricDisplayUom(metricDefaultUnitOfMeasure);
            }
          } else {
            updateMetric.push(res);
          }
          if (index === this.props.metrics.length - 1) {
            this.props.updateMetric(updateMetric, () => {
              setTimeout(() => {
                const changeHasValue = hasValue(JSON.stringify(updateMetric));
                if (from === 'SAC') {
                  this.setState({saveWait: false, hasValue: changeHasValue});
                } else {
                  this.setState({nextWait: false, hasValue: changeHasValue});
                }
                callBlack();
              }, 1000);
            });
          }
        });
      });
    } else if (from === 'NEXT') {
      callBlack();
    }
  };

  getTypeOfMeasurement = uomId => {
    const unitsOfMeasureDigestProcessed = this.props
      .unitsOfMeasureDigestProcessed;
    if (
      unitsOfMeasureDigestProcessed &&
      unitsOfMeasureDigestProcessed.unitsOfMeasure &&
      unitsOfMeasureDigestProcessed.unitsOfMeasure[uomId]
    ) {
      return unitsOfMeasureDigestProcessed.unitsOfMeasure[uomId]
        .typeOfMeasurement;
    }

    return null;
  };
  cancel = () => {
    const {updateMetric, closeModal, metrics = []} = this.props;
    const metricsfiltered = metrics && metrics.filter(res => res.id !== -1);
    updateMetric(metricsfiltered, () => {
      closeModal();
      this.hideCloseConfirmationModal();
    });
  };

  hideCloseConfirmationModal = () => {
    this.setState({visibleCloseConfirmationModal: false});
  };
  render() {
    const {
      metrics,
      saveWait,
      nextWait,
      visibleCloseConfirmationModal,
      saveDisable
    } = this.state;
    const {tabs, setActiveTab} = this.props;

    return (
      <>
        <View style={[styles.sectionContainer, styles.sectionContainerzIndex]}>
          <View style={[styles.secMainTitle, styles.comMgBot, styles.secRtPd]}>
            <Text style={[styles.textTitleExLgBold, styles.textThickGrey]} allowFontScaling={false}>
              Which health data would you like to track?
            </Text>
          </View>
          <MessageBox
            message={
              <Text allowFontScaling={false}>
                You may add any health data you want to track. Custom health
                data can be added through{' '}
                <Text style={styles.bold} allowFontScaling={false}>My Health Tracking</Text> after setup.
              </Text>
            }
          />

          <AddHealthData
            updateInActive={val => this.setState({inActive: val})}
          />

          {(!Boolean(metrics) || (metrics && metrics.length === 0)) && (
            <View
              style={[
                styles.addDefaultBox,
                styles.flexJcCtAiCt,
                styles.zIndexMin,
              ]}>
              <View style={[styles.addDefaultIcon]}>
                <Image
                  style={styles.addIcon}
                  source={require('../../../../assets/icons/add-health-data.png')}
                />
              </View>
              <View style={styles.addDefaultData}>
                <Text style={[styles.textPrimeSm, styles.textColorLight]} allowFontScaling={false}>
                  Add Health Data
                </Text>
              </View>
            </View>
          )}
          <HealthDataList />
          <View style={styles.autoSuggestSpace} />
        </View>

        <HealthDataFooter
          back={() => {
            this.state.metrics && this.state.metrics.length > 0
              ? this.update(() => setActiveTab(tabs.symptom), 'SAC')
              : setActiveTab(tabs.symptom);
          }}
          next={() => {
            this.update(() => setActiveTab(tabs.routine), 'NEXT');
          }}
          saveWait={saveWait}
          nextWait={nextWait}
          saveDisable={saveDisable}
        />
        <SetupCancelConfirmationBox
          visibleModal={visibleCloseConfirmationModal}
          cancel={this.hideCloseConfirmationModal}
          confirm={this.cancel}
          message="Cancel setting up your health data?"
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowWidth: state.uistate.windowWidth,
    windowHeight: state.uistate.windowHeight,
    metrics: state.setup.metrics,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    saveDisable: state.setup.routineWizardSaveDisable?.healthData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMetric: (addNewMetric, callBack) =>
      dispatch(actions.updateMetric(addNewMetric, callBack)),
    updateUserMetric: (metric, callBack, journalUserId) =>
      dispatch(actions.updateUserMetric(metric, callBack, journalUserId)),
    updateUserMetricDisplayUom: (metricDefaultUnitOfMeasure, journalUserId) =>
      dispatch(
        actions.updateUserMetricDisplayUom(
          metricDefaultUnitOfMeasure,
          journalUserId,
        ),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthDataContent);
