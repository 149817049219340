import React, { Component } from "react";
import { View, Text, TextInput } from "react-native";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import styles from "../../Css";
import Collapsible from "../Collapsible/Collapsible";

class ReportAddEditNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleReportNote: props.noteOpen,
      note: this.props.note,
      validNote: this.props.validNote,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const {note} = props;
    this.setState({note});
  }

  onChangeNote = val => {
    if (val.length > 100) {
      this.setState({note: val, validNote: false});
    } else {
      this.setState({note: val, validNote: true});
    }
    this.props.updateReportNote(val)
  };
  render() {
    return (
      <Collapsible
        title="Notes"
        contentVisibility={this.state.visibleReportNote}
        handleOnPressCollapsible={() => {
          this.setState({
            visibleReportNote: !this.state.visibleReportNote,
          });
          this.props.setTabOpen(7, !this.state.visibleReportNote);
        }}
      >
        <View style={[styles.collapseContent]}>
          <View style={styles.gridRow}>
            <View style={styles.gridColFull}>
              <View style={[styles.colPd, styles.colBg]}>
                <View style={[styles.noteSection]}>
                  <View style={[styles.fieldRow, styles.fieldRowNoMg]}>
                    <TextInput
                      style={[
                        styles.textField,
                        styles.textFieldSm,
                        styles.textAreaMax,
                        styles.textFieldWhite,
                        this.state.validNote
                          ? styles.validColor
                          : styles.inValidColor,
                      ]}
                      allowFontScaling={false}
                      multiline={true}
                      numberOfLines={3}
                      onChangeText={this.onChangeNote}
                      value={this.state.note}
                      onBlur={() => this.props.updateReportNote(this.state.note)}
                      maxLength={100}
                    />
                  </View>
                  <View style={[styles.fieldInfo, styles.topPdExSm]}>
                    <Text style={[styles.textMicSm, styles.textAlignRight]} allowFontScaling={false}>
                      * 100 Characters Max
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Collapsible>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    note: state.report.note,
    noteOpen: state.report.noteOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateReportNote: note => dispatch(actions.updateReportNote(note)),
    setTabOpen: (tab, open) => dispatch(actions.setTabOpen(tab, open)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportAddEditNote);
