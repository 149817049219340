import React, {Component} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import DateFnsUtils from '@date-io/date-fns';
import {DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import lightGreen from '@material-ui/core/colors/lightGreen';
import styles from '../../Css';
import moment from 'moment';

const theme = createMuiTheme({
  palette: {
    primary: lightGreen,
  },
});

class dateTimePicker extends Component {
  constructor(props) {
    super(props);
    const {format, date} = props;
    this.format = format ? format : "D MMM 'YY | h:mm A";
    this.changeClick = false;

    this.state = {
      date: date ? date : null,
      openTo: 'date',
    };
  }

  onChange = date => {
    const {onConfirm} = this.props;
    this.changeClick = true;

    this.setState(
      {
        date: moment(date).valueOf(),
      },
      () => {
        if (onConfirm) {
          onConfirm(moment(date).valueOf());
        }
      },
    );
  };

  onClose = () => {
    if (this.props.format !== 'dd-MM-yyyy') {
      const {onConfirm, clearCancelOff} = this.props;
      if (!this.changeClick && !clearCancelOff) {
        this.setState({date: null}, () => {
          if (onConfirm) {
            onConfirm(null);
          }
        });
      }
    }
    this.changeClick = false;
  };

  textFieldComponent = props => {
    const {customTouchableComponent} = this.props;
    const {onClick} = props;
    if (customTouchableComponent) {
      return customTouchableComponent(onClick);
    }
    const {date} = this.state;

    return (
      <TouchableOpacity onPress={onClick}>
        <View style={[styles.textFieldDatePickerField]}>
          <Text style={styles.textPrime} allowFontScaling={false}>
            {date
              ? moment(date).format(
                  this.format === 'dd-MM-yyyy' ? 'DD-MM-YYYY' : this.format,
                )
              : ''}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const {date} = nextProps;
    this.setState({date: date});
  };

  onMonthChange = date => {
    this.setState({
      date: moment(date).valueOf(),
    });
  };

  onChangeAM = props => {
    let {date} = props;
    let time = moment(moment(date), 'HH:mm');

    if (moment(date).format('A') === 'PM') {
      const momentDate = moment(date);
      momentDate.set({
        hour: this.getPMToAM(time.get('hour')),
        minute: time.get('minute'),
        second: time.get('second'),
      });

      props.onChange(new Date(momentDate.valueOf()));
    }
  };

  getAMToPM = val => {
    switch (val) {
      case 1:
        return 13;
      case 2:
        return 14;
      case 3:
        return 15;
      case 4:
        return 16;
      case 5:
        return 17;
      case 6:
        return 18;
      case 7:
        return 19;
      case 8:
        return 20;
      case 9:
        return 21;
      case 10:
        return 22;
      case 11:
        return 23;
      default:
        return 0;
    }
  };

  onChangePM = props => {
    let {date} = props;

    let time = moment(moment(date), 'HH:mm');

    if (moment(date).format('A') === 'AM') {
      const momentDate = moment(date);
      momentDate.set({
        hour: this.getAMToPM(time.get('hour')),
        minute: time.get('minute'),
        second: time.get('second'),
      });

      props.onChange(new Date(momentDate.valueOf()));
    }
  };

  getPMToAM = val => {
    switch (val) {
      case 13:
        return 1;
      case 14:
        return 2;
      case 15:
        return 3;
      case 16:
        return 4;
      case 17:
        return 5;
      case 18:
        return 6;
      case 19:
        return 7;
      case 20:
        return 8;
      case 21:
        return 9;
      case 22:
        return 10;
      case 23:
        return 11;
      default:
        return 12;
    }
  };

  toolbarComponent = props => {
    // console.log(props);
    return (
      <View style={[styles.datePickerHeaderWrapperWeb]}>
        <View style={[styles.datePickerHeaderView, styles.pickBg]}>
          <View style={[styles.pickerDateTimeSection]}>
            <View style={[styles.pickerColDateSection]}>
              <View style={[styles.datePickerHeaderRow, styles.pickerRowMg]}>
                <View style={[styles.datePickerHeaderCont]}>
                  <View style={[styles.datePickerBtnText]}>
                    <TouchableOpacity
                      style={[styles.datePickerBtn, styles.datePickerBtnPd]}
                      onPress={() => props.setOpenView('year')}>
                      <Text
                        style={[
                          styles.textPrimeLg,
                          styles.textExLightWhite,
                          // if Active
                          // ? styles.textExLightWhite
                          // : styles.textWhite,
                        ]}
                        allowFontScaling={false}
                        >
                        {moment(props.date).format('YYYY')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <View style={[styles.datePickerHeaderRow]}>
                <View style={[styles.datePickerHeaderCont]}>
                  <View style={[styles.datePickerBtnText]}>
                    <TouchableOpacity
                      style={[styles.datePickerBtn, styles.datePickerBtnPd]}
                      onPress={() => props.setOpenView('month')}>
                      <Text
                        style={[
                          styles.textNoSm,
                          styles.lineHeightSmNo,
                          styles.textExLightWhite,
                          // if Active
                          // ? styles.textExLightWhite
                          // : styles.textWhite
                        ]}
                        allowFontScaling={false}
                        >
                        {moment(props.date).format("D MMM 'YY")}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>

            <View style={[styles.pickerColTimeSection]}>
              <View style={[styles.datePickerHeaderRow]}>
                <View style={[styles.datePickerHeaderCont]}>
                  <View
                    style={[
                      styles.datePickerBtnRow,
                      styles.flexJcFe,
                      styles.datePickerTimeView,
                    ]}>
                    <View style={[styles.timeFullRow, styles.flexRow]}>
                      <TouchableOpacity
                        style={[styles.datePickerTimeBtn]}
                        onPress={() => props.setOpenView('hours')}>
                        <Text
                          style={[
                            styles.textSpec,
                            styles.lineHeightLgNo,
                            styles.textExLightWhite,
                            // if Active
                            // ? styles.textExLightWhite
                            // : styles.textWhite
                          ]}
                          allowFontScaling={false}
                          >
                          {moment(props.date).format('h')}
                        </Text>
                      </TouchableOpacity>

                      <View style={styles.datePickerTimeBtn}>
                        <Text
                          style={[
                            styles.textSpec,
                            styles.lineHeightLgNo,
                            styles.textExLightWhite,
                          ]}
                          allowFontScaling={false}
                          >
                          :
                        </Text>
                      </View>

                      <TouchableOpacity
                        style={[styles.datePickerTimeBtn]}
                        onPress={() => props.setOpenView('minutes')}>
                        <Text
                          style={[
                            styles.textSpec,
                            styles.lineHeightLgNo,
                            styles.textExLightWhite,
                            // if Active
                            // ? styles.textExLightWhite
                            // : styles.textWhite
                          ]}
                          allowFontScaling={false}
                          >
                          {moment(props.date).format('mm')}
                        </Text>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={[styles.datePickerBtnText, styles.timeTypeFull]}>
                      <View style={styles.timeType}>
                        <TouchableOpacity
                          style={[styles.datePickerTimeBtn]}
                          onPress={() => this.onChangeAM(props)}>
                          <Text
                            style={[
                              styles.textPrimeLg,
                              moment(props.date).format('A') === 'AM'
                                ? styles.textWhite
                                : styles.textExLightWhite,
                            ]}
                            allowFontScaling={false}
                            >
                            AM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={styles.timeType}>
                        <TouchableOpacity
                          style={[styles.datePickerTimeBtn]}
                          onPress={() => this.onChangePM(props)}>
                          <Text
                            style={[
                              styles.textPrimeLg,
                              moment(props.date).format('A') === 'PM'
                                ? styles.textWhite
                                : styles.textExLightWhite,
                            ]}
                            allowFontScaling={false}
                            >
                            PM
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  render() {
    const {date} = this.state;
    const {shouldDisableDate, format, disablePast, disabled} = this.props;
    return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            value={moment(date)}
            onChange={this.onChange}
            format={format}
            InputProps={{
              disableUnderline: true,
            }}
            openTo={'date'}
            TextFieldComponent={this.textFieldComponent}
            onClose={this.onClose}
            shouldDisableDate={day => {
              return shouldDisableDate && shouldDisableDate(day);
            }}
            views={['year', 'month', 'date', 'hours', 'minutes']}
            showTodayButton={true}
            onMonthChange={this.onMonthChange}
            ToolbarComponent={this.toolbarComponent}
            ampm={false}
            disablePast={disablePast}
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}
export default (dateTimePicker);
