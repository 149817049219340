import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import StepFourMetric from './StepFourMetric';
import { getUnits, getSuggestedTeams } from '../../../util/commonUiLogic';
import {itemTypes} from '../../../Constant/index';
class StepFourMetricList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metricArray: props.metrics ? props.metrics : [],
      untisArray: [],
      terms: getSuggestedTeams(this.props.systemDefinedMetrics, this.props.userDefinedMetrics).concat(getSuggestedTeams(this.props.userDefinedMetrics, this.props.systemDefinedMetrics, true)),
    };
  }

  componentDidMount(){
    this.props.getSystemUnitOfMeasure(
      (res, data) => {
        if(res){
          this.setState({
            untisArray: data
          })
        }
      }
    );
  }

  static getDerivedStateFromProps(props, state) {
    const {metrics} = props;
    if (metrics && JSON.stringify(metrics) !== JSON.stringify(state.metricArray)) {
      return {
        metricArray: metrics,
        terms: getSuggestedTeams(props.systemDefinedMetrics, props.userDefinedMetrics).concat(getSuggestedTeams(props.userDefinedMetrics, props.systemDefinedMetrics, true)),
      };
    }

    return null;
  }

  hasMetricRecordings = (id) => {
    let metrics = [];
    let records = null;
    const journalEntries = Object.values(this.props.journalEntriesProcessed);
    if (journalEntries && journalEntries.length > 0) {
      journalEntries.forEach(res => {
        if (res.metrics && res.metrics.length > 0) {
          res.metrics.forEach(re => {
            metrics.push(re);
          });
        }
      });
    }

    if (metrics && metrics.length > 0) {
      records = metrics.filter(res => {
        return res.metric === id;
      });
    }
    return records ? records.length : null;
  };

  handleOnDeleteMetric = id => {
    //Delete Metric from server
    const tempData = this.state.metricArray;
    const {userDefinedMetrics, journalUserId} = this.props;
    if (tempData[id].id !== -1 && tempData[id]?.metric?.addedFromRW && !this.hasMetricRecordings(tempData[id].id)) {
      this.props.onDeleteMetric(
        tempData[id].id, 
        userDefinedMetrics, 
        () => {}, 
        journalUserId, 
        true //hide error message banner
      );
    }

    tempData.splice(id, 1);
    this.setState({
      metricArray: tempData,
    });

    this.props.updateMetric(tempData, () => {
      this.checkMetricsAbleToSave();
    });
  };

  handleOnSetUnit = (id, uomId) => {
    const tempData = this.state.metricArray;
    const newTemp = tempData[id];
    newTemp.uomId = uomId;

    tempData.splice(id, 1, newTemp);

    this.setState({
      metricArray: tempData,
    });
    this.props.updateMetric(tempData, () => {});

  }

  handleOnSetName = (id, name) => {
    const tempData = this.state.metricArray;
    const newTemp = tempData[id];
    newTemp.name = name;
    newTemp.metric.name = name;

    tempData.splice(id, 1, newTemp);

    this.setState({
      metricArray: tempData,
    });
    this.props.updateMetric(tempData, () => {
      this.checkMetricsAbleToSave();
    });

  }

  checkMetricsAbleToSave = () => {
    const {metrics, routineWizardSaveDisable} = this.props;
    let invalidName = metrics.some(metric => {
      return metric.id == -1 && metric?.name?.trim() === ''
    })
    routineWizardSaveDisable(invalidName, itemTypes.health_data);
  }

  resetErrors = (id, value) => {
    const tempData = this.state.metricArray;
    const newTemp = tempData[id];
    newTemp.error = false;
    if (!Boolean(value)) {
      newTemp.uomId = null;
    }
    tempData.splice(id, 1, newTemp);

    this.setState({
      metricArray: tempData,
    });
    this.props.updateMetric(tempData, () => {});

  }

  render() {
    let rows = this.state.metricArray.map((res, i) => {
      let tempMetric = this.state.terms.filter((item) => {
        if (item.data.name === res.name) {
          return item.data;
        }
        return [];
      });
      return (
        <StepFourMetric
          key={i}
          metric={res}
          index={i}
          units={tempMetric && tempMetric.length > 0 
            ? getUnits(this.state.untisArray, res.typeOfMeasurement)
            : getUnits(this.state.untisArray, null)
          }
          handleOnDeleteMetric={this.handleOnDeleteMetric}
          handleOnSetUnit={this.handleOnSetUnit}
          handleOnSetName={this.handleOnSetName}
          resetErrors={this.resetErrors}
          metricsCount={this.state.metricArray.length}
          type={res.type}
        />
      );
    });
    return <React.Fragment>{Boolean(this.state.untisArray.length) && rows}</React.Fragment>;
  }
}
const mapStateToProps = state => {
  return {
    metrics: state.setup.metrics,
    userDefinedMetrics: state.routines.metricsDigest.userDefinedMetrics,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    journalUserId: state.uistate.journalUserId,
    systemDefinedMetrics: state.routines.metricsDigest.systemDefinedMetrics,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateMetric: (addNewMetric, callBack) =>
      dispatch(actions.updateMetric(addNewMetric, callBack)),
    onDeleteMetric: (id, userDefinedMetrics, callBack, journalUserId, hideErrorMsg) =>
      dispatch(actions.deleteMetric(id, userDefinedMetrics, callBack, journalUserId, hideErrorMsg)),
    getSystemUnitOfMeasure: (callBack) =>
      dispatch(actions.getSystemUnitOfMeasure(callBack)),
    routineWizardSaveDisable: (flag, isFrom ) => 
      dispatch(actions.updateRoutineWizardSaveDisable(flag, isFrom))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepFourMetricList);
