import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity, BackHandler, Platform, Keyboard} from 'react-native';
import {connect} from 'react-redux';
import moment from "moment";
import _ from 'lodash';
import MenuOverlayContainer from '../../components/PageLayout/MenuOverlayContainer';
import AddEventNameDatePicker from "./HealthJourneyEvents/AddEventNameDatePicker";
import AddEventCategoryType from './HealthJourneyEvents/AddEventCategoryType';
import AddEventDescription from './HealthJourneyEvents/AddEventDescription';
import AddEventAttachments from './HealthJourneyEvents/AddEventAttachments';
import AddEventTags from '../../components/Tags/EventTags';
import SavingConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import styles from '../../Css';
import * as actions from '../../store/actions/index';
import { HEALTH_JOURNEY, IMAGES_DOCUMENTS } from '../../navigation/path';
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import {hasValue} from '../../util/hashValueGenerator';
import {alertBody, healthJourneyTitle} from '../../GlobalFunctions/GlobalString';
import * as constants from './HealthJourneyConstant'
import MobileProgress from './HealthJourneyAttachments/MobileProgress';
import { cancelTokenSource } from '../../axios/axios-config-common.web';
import {checkCategoryItemsSavedOrNot} from '../../util/commonUiLogic';
import { WEB, IOS } from '../../util/platformWindowConfig';
import { unFocus } from 'zamplo-common-js';


class AddEventScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dob: props.user.birthday
        ? moment.utc(props.user.birthday, 'x').format('YYYY-MM-DD')
        : null,
      type: null,
      eventName: null,
      startDate : null,
      endDate: null,
      isStartDaySelected: false,
      isEndDaySelected: false,
      description: null,
      tagsArray: [],
      showTagSection: false,
      attachments: [],
      categoryTypeItems: [],
      saveWait: false,
      saveDisabled: true,
      confirmBox: false,
      attachUploading: false,
      launch: false,
      isStartDateSelected:true,
      isEndDateSelected:true,
      newCategoryTypeItems: [],
      deleting: false,
      mounted: false,
      isTagFocused: false,
      showList : true,
    };
    this.initHashValue = null;
  }

  componentDidMount() {
    const {eventData, hideMobileMenu} = this.props;
  
    const {type = null, eventName = null, startDate = null, endDate = null, isStartDaySelected = true, isEndDaySelected = true, description = null, tags, tagsArray = [], attachments = [], categoryTypeItems = [], isEndDateSelected = true , isStartDateSelected =true, newCategoryTypeItems = []} = eventData;
    if (!_.isEmpty(eventData)) {
      this.setState({
        type,
        eventName,
        startDate,
        endDate,
        isStartDaySelected,
        isEndDaySelected,
        description,
        tagsArray: tagsArray ? tagsArray : tags ? tags.includes(",") ? tags.split(",").map(t => ({tags: t})) : [{tags}] : [],
        showTagSection: Boolean(tags || (tagsArray && tagsArray.length > 0)),
        attachments,
        categoryTypeItems,
        isStartDateSelected,
        isEndDateSelected,
        newCategoryTypeItems,
        mounted: true,
      }, () => {
        this.initHashValue = eventData?.initHashValue ? eventData?.initHashValue : this.getHashValue();
      });
    }
    hideMobileMenu();
    BackHandler.addEventListener('hardwareBackPress', this.onPressCancel);
    this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this.keyboardDidShow);
    this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this.keyboardDidHide);
  }

  componentWillUnmount() {
    const {showMobileMenu, setActiveBubble} = this.props;
    showMobileMenu();
    setActiveBubble(null);
    BackHandler.removeEventListener('hardwareBackPress', this.onPressCancel);
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
  }
  
  static getDerivedStateFromProps(props, state) {
    const {windowWidth, eventData, setEventData, navigation} = props;
    const {eventName, description, tagsArray, attachments, categoryTypeItems, newCategoryTypeItems, type, startDate, endDate} = state;
    if (windowWidth >= constants.WINDOW_MOBILE_WIDTH_MARGIN) {
      setEventData({
        ...eventData,
        eventName,
        description,
        tagsArray,
        attachments,
        categoryTypeItems,
        newCategoryTypeItems,
        type,
        startDate,
        endDate,
      });
      navigation.reset({
        index: 0,
        routes: [{ name: HEALTH_JOURNEY.path }],
      });
    }
    return null;
  }

  keyboardDidShow = () => {
    this.setState({
      isKeyboardVisible: true
    });
  }

  keyboardDidHide = () => {
    this.setState({
      isKeyboardVisible: false
    });
  }

  handleOnPressBackButton = () => {
    const {navigation} = this.props;
    handleCommonBackNavigation(navigation);
  };
  setStartDate =(date)=>{
    this.setState({startDate:date})
  }
  setEndDate =(date)=>{
    this.setState({endDate:date})
  }

  enableSaveButton = () => {
    const {type, eventName, startDate, endDate, attachments, categoryTypeItems} = this.state;
    let commonRequired = Boolean(eventName && startDate && (startDate && endDate ? !moment(startDate).isAfter(endDate) : true) && this.initHashValue !== this.getHashValue());
    switch (type) {
      case constants.eventTypes.MEDICATIONS:
      case constants.eventTypes.SYMPTOMS:
      case constants.eventTypes.HEATHDATA:
        return Boolean(commonRequired && checkCategoryItemsSavedOrNot(categoryTypeItems));
      case constants.eventTypes.ATTACHMENTS:
        return Boolean(commonRequired && attachments && attachments.length > 0);
      case constants.eventTypes.CUSTOM_NOTES:
        return commonRequired;
      default:
        return false;  
    }  
  }

  onSaveAttachWeb = (attachments, artifactId, eventId) => {
    const {onSaveFilesToAttach, setAttachmentUploadStatus, setAttachmentArtifactId, setCurrentEventId, setAttachmentFullSize, showUploadProgress} = this.props;
    const files = {};
    let fileSize = 0;
    attachments.forEach((item, idx) => {
      const file = item.file;
      file.cancelToken = cancelTokenSource.source();
      files[idx] = file;
      fileSize += file.size;
    });
    onSaveFilesToAttach(files);
    setAttachmentUploadStatus(true);
    setAttachmentArtifactId(artifactId);
    setCurrentEventId(eventId);
    setAttachmentFullSize(fileSize);
    showUploadProgress();
  };

  onSaveAttachMobile = (attachments, artifactId, eventId) => {
    this.setState({
      attachUploading: true,
      attachments,
      artifactId,
      eventId,
    });
  };
  setIsStartDaySelectedHandler = (props)=>{
    this.setState({isStartDaySelected: props})
  }
  setIsEndDaySelectedHandler = (props)=>{
    this.setState({isEndDaySelected: props})
  }

  

  handleOnSaveEvent = () => {
    this.setState({saveWait: true, confirmBox: false});
    Keyboard.dismiss();
    setTimeout(() => {
    const {eventData, journalUserId, saveAndUpdateHealthJourneyEvent, showSuccessPopup, setOpenedYearAndId, activeBubbleId, setActiveBubble} = this.props;
    const {eventName, startDate, endDate, description, tagsArray, categoryTypeItems, attachments} = this.state;
    if (activeBubbleId) setActiveBubble(null);
    const newAttachments = attachments.filter(item => !item.id);
    const data = {
      id: eventData && eventData.id ? eventData.id : "-1",
      eventName,
      description,
      tags: tagsArray.filter(t => t.tags).map(t => t.tags).join(","),
      startDate: moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
      categoryType: eventData.type,
      endDate: endDate ? moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD") : null,
      medications: eventData.type === constants.eventTypes.MEDICATIONS ? categoryTypeItems.map(item => item.id) : [],
      symptoms: eventData.type === constants.eventTypes.SYMPTOMS ? categoryTypeItems.map(item => item.id) : [],
      healthData: eventData.type === constants.eventTypes.HEATHDATA ? categoryTypeItems.map(item => item.id) : [],
      publishedBy: eventData && eventData.publishedBy ? eventData.publishedBy : journalUserId ? constants.eventPublishedBy.CAREGIVER : constants.eventPublishedBy.SELF,
      userAttachments: attachments.filter(item => item.id).map(item => ({attachmentId: item.id, attachmentName: `${item.fileName}.${item.fileExtension}`})),
      isStartDateSelected:startDate && startDate.length == 10? true : false,
      isEndDateSelected: endDate && endDate.length == 10? true : false,
    };
    saveAndUpdateHealthJourneyEvent(journalUserId, data, (success, data) => {
      this.setState({saveWait: false});
      if (success) {
        const {id, startDate, artifactId} = data;
        const year = startDate?.split("-")[0];
        setOpenedYearAndId({
          year: year,
          id,
        });
        if (newAttachments && newAttachments.length > 0) {
          if (Platform.OS === WEB) {
            this.onSaveAttachWeb(newAttachments, artifactId, id);
            this.handleEventListNavigation();
            showSuccessPopup(18, null, null);
          } else {
            this.onSaveAttachMobile(newAttachments, artifactId, id);
          }
        } else {
          this.handleEventListNavigation();
          showSuccessPopup(18, null, null);
        }
      }
    });
  }, 500);
  }

  removeCategoryItems = async () => {
    const {newCategoryTypeItems, type} = this.state;
    const {onDeleteTherapie, journalUserId, commonErrorHandler, onDeleteSymptoms, userSymptoms} = this.props;
    if (newCategoryTypeItems.length === 0) {
      this.handleEventListNavigation();
      return;
    }
    this.setState({deleting: true});
    let promises = [];
    if (type === constants.eventTypes.MEDICATIONS) {
      promises = newCategoryTypeItems.map(
        ({id}) =>
          new Promise((resolve, reject) =>
            onDeleteTherapie(
              id,
              res => {
                if (res) {
                  resolve("Passed");
                } else {
                  reject(id);
                }
              },
              journalUserId,
            ),
          ),
      );
    } else if (type === constants.eventTypes.SYMPTOMS) {
      promises = newCategoryTypeItems.map(
        ({id}) =>
          new Promise((resolve, reject) =>
            onDeleteSymptoms(
              id,
              userSymptoms,
              res => {
                if (res) {
                  resolve("Passed");
                } else {
                  reject(id);
                }
              },
              journalUserId
            ),
          ),
      );
    }

    const preProcess = promises.map(async p => {
      try {
        return {
          status: "fulfilled",
          value: await p
        }
      } catch (error) {
        return {
          status: "rejected",
          reason: error
        }
      }
    })

    const results = await Promise.all(preProcess);
    const failed = results.filter(item => item.status === "rejected").map(item => item.reason);
    if (failed.length > 0)
      commonErrorHandler(`Failed to delete ${failed.join(",")}`);
    this.setState({deleting: false});
    this.handleEventListNavigation();
  }

  handleEventListNavigation = () => {
    const {navigation, setEventData, activeBubbleId, setActiveBubble} = this.props;
    this.setState({confirmBox: false});
    setEventData({});
    if (activeBubbleId) setActiveBubble(null);
    navigation.reset({
      index: 0,
      routes: [{ name: HEALTH_JOURNEY.path}],
    });
    return true;
  }

  onPressCancel = () => {
    if (this.initHashValue === this.getHashValue()) {
      this.handleEventListNavigation();
    } else {
      this.setState({confirmBox: true});
    }
    return true;
  }

  getHashValue = () => {
    const { eventName = null, description = null, startDate = null, endDate = null, tagsArray = [], attachments = [], categoryTypeItems = [] } = this.state;
    const currentHash = hasValue(
      eventName +
      ',' +
      description +
        ',' +
      startDate +
        ',' +
      endDate +
        ',' +
      JSON.stringify(tagsArray) +
        ',' +
      JSON.stringify(attachments) + 
        ',' +
      JSON.stringify(categoryTypeItems)
    );
    return currentHash;
  }


  addAttachmentsTakePhoto = () => {
    const {navigation} = this.props;
    this.syncEventData();
    navigation.navigate(HEALTH_JOURNEY.addAttachments)
  }

  addAttachmentsFromZamplo = () => {
    const {navigation} = this.props;
    this.syncEventData();
    navigation.navigate(IMAGES_DOCUMENTS.path, {
      from: HEALTH_JOURNEY.path
    })
  }

  syncEventData = () => {
    const {setEventData, eventData} = this.props;
    const {type, eventName, description, tagsArray, startDate, endDate, attachments, categoryTypeItems, newCategoryTypeItems} = this.state;
    setEventData({
      ...eventData,
      type,
      eventName,
      description,
      tagsArray,
      startDate,
      endDate,
      attachments,
      categoryTypeItems,
      newCategoryTypeItems,
      initHashValue: this.initHashValue
    });
  }

  exit = () => {
    const {showSuccessPopup, fetchAllHealthJourneyEvents, journalUserId} = this.props;
    fetchAllHealthJourneyEvents(journalUserId, this.handleEventListNavigation)
    showSuccessPopup(18, null, null);
  }

  dismissTagList = () => {
    this.setState({showList : false, isTagFocused : true})
    setTimeout(()=>{
      this.setState({showList: true, isTagFocused : false})
    },100)
  }

  render() {
    const {eventData, windowWidth, activeBubbleId, setActiveBubble, commonErrorHandler, onUpload, journalUserId, cancelUploadAttachmentProcess, route, treatments, userDefinedMetrics, userSymptoms, systemDefinedMetrics, navigation, userSubscriptions, onDeleteTherapie, editTherapie, newTherapie, isNew, drugAdministrationsDigits, updateTherapie, resetNewTherapie, updateSystemDefinedSymptoms, journalEntries, createUserSymptom, onDeleteSymptoms, onSelectSymptom, onSelectTherapie, hasNotch, fetchHealthJourneyTagsList} = this.props;
    const {type, eventName, startDate, endDate, isStartDaySelected, isEndDaySelected, description, tagsArray, showTagSection, attachments, showAttachmentSection, categoryTypeItems, saveWait, confirmBox, attachUploading, artifactId, eventId, launch, isStartDateSelected , isEndDateSelected, newCategoryTypeItems, deleting, dob, mounted, saveDisabled, isTagFocused, isKeyboardVisible, showList} = this.state;
    let saveDisabledNewValue = !this.enableSaveButton();
    if (saveDisabled !== saveDisabledNewValue) {
      this.setState({saveDisabled: saveDisabledNewValue});
    }
    if (Boolean(Platform.OS === IOS) &&
      Boolean(route?.params?.attachments) &&
      Boolean(route?.params?.attachments.length !== attachments.length) &&
      Boolean(route?.params?.attachments.length > attachments.length)
    ) {
      this.setState({
        attachments: route?.params?.attachments
      }, () => {
        this.props.navigation.setParams({attachments: null});
      });
    }
    return (
      <React.Fragment>
        {Boolean(this.initHashValue) && this.initHashValue !== this.getHashValue() &&
          <MenuOverlayContainer onPressOpen={this.onPressCancel} hideHeaderOverlay />
        }
        <KeyboardAwareScrollViewHoc contentContainerStyle={{flexGrow: !isKeyboardVisible ? 1 : undefined, minHeight: 600}} hasNotch={hasNotch}>
        <View style={[styles.hgHundredPer, styles.bgWhite, styles.flexJcSb]} onStartShouldSetResponder={unFocus}>
          <View style={[styles.secSpPdTopSm, styles.pdBtSixty, styles.messageDetailSm, styles.smPdItem, styles.noPdBot]}>
            <View style={styles.flexJcFeAiFe}> 
              <TouchableOpacity onPress={() => this.onPressCancel()}> 
                <View style={{padding: 10}}>
                <Image 
                    style={{width:15, height:15}} 
                    source={require("../../assets/health-journey-icons/health-journey-close.png")} 
                /> 
                </View>
              </TouchableOpacity> 
            </View>
            <View style={{
              ...Platform.select({
                ios: {
                  zIndex: 999
                },
                web: {
                  zIndex:999
                }
              })
            }}>
              <AddEventNameDatePicker
                eventName={eventName}
                onChangeEventName={(value) => {
                  this.setState({eventName: value})
                }}
                dob={dob}
                startDate={startDate}
                setStartDate={this.setStartDate}
                endDate={endDate}
                setEndDate={this.setEndDate}
                isStartDateSelected = { this.state.isStartDateSelected}
                isEndDateSelected = { this.state.isEndDateSelected}
                isStartDaySelected={isStartDaySelected}
                isEndDaySelected={isEndDaySelected}
                setIsStartDaySelected={(value) => this.setIsStartDaySelectedHandler(value)}
                setIsEndDaySelected={(value) => this.setIsEndDaySelectedHandler(value) }
                activeBubbleId={activeBubbleId}
                setActiveBubble={setActiveBubble}
                autoFocus={Boolean(
                  route?.params?.from === HEALTH_JOURNEY.addAttachments || 
                  route?.params?.from === IMAGES_DOCUMENTS.path
                )}
                dismissTagList={this.dismissTagList}
              />
            </View> 
            {/* Meds or Symptoms or Health data */}
            {!_.isEmpty(eventData) && 
                type !== constants.eventTypes.CUSTOM_NOTES && 
                type !== constants.eventTypes.ATTACHMENTS &&
                  <View style={styles.sectionContainerzIndex}>
                    <AddEventCategoryType 
                      categoryType={type}
                      items={categoryTypeItems}
                      newItems={newCategoryTypeItems}
                      userListItems={type === constants.eventTypes.MEDICATIONS 
                        ? treatments 
                        : type === constants.eventTypes.SYMPTOMS
                          ? userSymptoms
                          : type === constants.eventTypes.HEATHDATA
                            ? userDefinedMetrics
                            : []
                      }
                      systemDefinedMetrics={systemDefinedMetrics}
                      updateCagoryList={(list, newList) => this.setState({categoryTypeItems: list, newCategoryTypeItems: newList})}
                      activeBubbleId={activeBubbleId}
                      setActiveBubble={setActiveBubble}
                      navigation={navigation}
                      journalUserId={journalUserId}
                      userSubscriptions={userSubscriptions}
                      onDeleteTherapie={onDeleteTherapie}
                      editTherapie={editTherapie}
                      newTherapie={newTherapie}
                      isNew={isNew}
                      drugAdministrationsDigits={drugAdministrationsDigits}
                      updateTherapie={updateTherapie}
                      resetNewTherapie={resetNewTherapie}
                      commonErrorHandler={commonErrorHandler}
                      syncEventData={this.syncEventData}
                      createUserSymptom={createUserSymptom}
                      onDeleteSymptoms={onDeleteSymptoms}
                      journalEntries={journalEntries}
                      onSelectSymptom={onSelectSymptom}
                      onSelectTherapie={onSelectTherapie}
                      parentMounted={mounted}
                      updateSystemDefinedSymptoms={updateSystemDefinedSymptoms}
                      dismissTagList={this.dismissTagList}
                    />
                  </View>
            }
            <View>
              {type === constants.eventTypes.ATTACHMENTS &&
                <AddEventAttachments 
                  attachments={attachments} 
                  windowWidth={windowWidth} 
                  updateAttachments={attach => this.setState({attachments: attach})}
                  addAttachmentsTakePhoto={this.addAttachmentsTakePhoto}
                  commonErrorHandler={commonErrorHandler}
                  addAttachmentsFromZamplo={this.addAttachmentsFromZamplo}
                  isFromAddAttachmentCategory
                  activeBubbleId={activeBubbleId}
                  setActiveBubble={setActiveBubble}
                />
              }
              <AddEventDescription
                handleOnDescriptionDone={(value) => 
                  this.setState({description: value})
                }
                windowWidth={windowWidth}
                description={description}
                activeBubbleId={activeBubbleId}
                setActiveBubble={setActiveBubble}
                dismissTagList={this.dismissTagList}
              />
              {/* Attachments */} 
              {type !== constants.eventTypes.ATTACHMENTS &&
                <AddEventAttachments 
                  attachments={attachments} 
                  windowWidth={windowWidth} 
                  updateAttachments={attach => this.setState({attachments: attach}, () => {
                    const {attachments} = this.state;
                    if (!attachments || attachments?.length === 0) {
                      this.setState({launch: false});
                    }
                  })}
                  addAttachmentsTakePhoto={this.addAttachmentsTakePhoto}
                  commonErrorHandler={commonErrorHandler}
                  addAttachmentsFromZamplo={this.addAttachmentsFromZamplo}
                  launch={launch}
                  activeBubbleId={activeBubbleId}
                  setActiveBubble={setActiveBubble}
                />
              }
              {/* Tags */}
              {Boolean(showTagSection) && Boolean(showList) &&
                <AddEventTags 
                  windowWidth={windowWidth}
                  updateTagArray={(tagsArray) => this.setState({tagsArray: tagsArray})}
                  tagsArray={tagsArray}
                  hideTagSection={() => this.setState({showTagSection: false})}
                  activeBubbleId={activeBubbleId}
                  setActiveBubble={setActiveBubble}
                  customWidth={260}
                  setIsFocused={flag => this.setState({isTagFocused: flag})}
                  fetchHealthJourneyTagsList={fetchHealthJourneyTagsList}  />
              }
            </View>
          </View>
            {Boolean(isTagFocused) && <View style={styles.tagsExtraView} />}
          <View style={[styles.flexRow, styles.flexJcSb, styles.flexAiCt, styles.mgTpFifty, styles.noPdBot, styles.messageDetailSm, styles.noMgTop, {zIndex: -996}]}>
            {/* attachment options */}
            <View style={[styles.flexRow, styles.flexAiCt, styles.btTen]}>
              {type !== constants.eventTypes.ATTACHMENTS && attachments.length === 0 && (
                <TouchableOpacity onPress={() => {
                  if (activeBubbleId) setActiveBubble(null);
                  this.setState({launch: true})
                }}>
                  <View style={styles.mgRtFifteen}>
                    <Image
                      style={styles.attachmentIcnGreyEvent}
                      source={require("../../assets/health-journey-icons/attachment-icon-grey.png")}
                      resizeMode={constants.IMAGE_RESIZE_MODE}
                    />
                  </View>
                </TouchableOpacity>
              )}
              
              {/* tag */}
                {!Boolean(showTagSection) &&
              <TouchableOpacity onPress={() => {
                if (activeBubbleId) setActiveBubble(null);
                this.setState({showTagSection: true});
              }}>
                <View style={type !== constants.eventTypes.ATTACHMENTS && attachments.length === 0 && styles.mgLtFifteen}>
                  <Image
                      style={styles.tagIcnDarkEvent}
                      source={require("../../assets/health-journey-icons/add-tag-icon-dark.png")}
                      resizeMode={constants.IMAGE_RESIZE_MODE}
                  />
                </View>
              </TouchableOpacity>
              }
            </View>

            <View style={[styles.flexRow, styles.flexJcFe, styles.flexAiCt, styles.comMgTopLg, styles.mgBtFifty, styles.rightSpExLg]}>
              <TouchableOpacity
                onPress={this.handleOnSaveEvent}
                disabled={saveWait || saveDisabled}>
                  <View style={styles.btnSingleCent}>
                    {saveWait && (
                      <View style={[
                          styles.indicatorIconView,
                          styles.btnSubmitloaderCom,
                        ]} >
                        <ActivityIndicator
                          pdZero={true}
                          size="small"
                          color={"#4C89DC"}
                        />
                      </View>
                    )}
                    <View style={[
                      styles.newEventBtn,
                      saveWait || saveDisabled ? styles.newEventSveBtnDisbaled : styles.newEventSveBtn,
                      styles.hgThirtyFive,
                      styles.wdHundred,
                      styles.flexAiCt,
                      styles.flexJcCt,
                      styles.mgLtSix
                    ]}>
                      
                      <Text style={[styles.textTitleBold, styles.textWhite, styles.btOne]}>Save</Text>
                    </View>
                  </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        </KeyboardAwareScrollViewHoc>
        {confirmBox && (
          <SavingConfirmation
            visibleModal={confirmBox}
            alertBody={alertBody}
            alertTitle={healthJourneyTitle}
            handleCancel={this.removeCategoryItems}
            handleConfirm={() => {
              this.handleOnSaveEvent();
            }}
            confirmWait={saveWait}
            deleteWait={deleting}
            saveDisabled={saveWait || saveDisabled || deleting}
            cancelDisabled={saveWait}
            onBackdropPress={() => {
              this.setState({confirmBox: false});
            }}
          />
        )} 
        {attachUploading && (
          <MobileProgress
            artifactId={artifactId}
            attachments={attachments}
            onUpload={onUpload}
            eventId={eventId}
            exit={this.exit}
            journalUserId={journalUserId}
            cancelUploadAttachmentProcess={cancelUploadAttachmentProcess}
          />
        )} 
      </React.Fragment>
      );
  }
}

const mapStateToProps = state => {
  return {
    windowWidth: state.uistate.windowWidth,
    startDate : state,
    journalUserId: state.uistate.journalUserId,
    eventData: state.uistate.eventData,
    activeBubbleId: state.uistate.activeBubbleId,
    treatments: state.routines.treatments,
    userSymptoms: state.routines.symptomsDigest
      ? state.routines.symptomsDigest.userSymptoms
      : [],
    userDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.userDefinedMetrics
      : [],
    systemDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.systemDefinedMetrics
      : [],
    userSubscriptions: state.routines.userSubscription,
    newTherapie: state.therapie.newTherapie,
    isNew: state.therapie.isNew,
    drugAdministrationsDigits: state.routines.drugAdministrationsDigits,
    journalEntries: state.routines.journalEntries,
    user: state.routines.user,
    hasNotch: state.uistate.hasNotch,
  }   
};

const mapDispatchToProps = dispatch => {
  return {
    saveAndUpdateHealthJourneyEvent: (journalUserId, data, callback) =>
      dispatch(actions.saveAndUpdateHealthJourneyEvent(journalUserId, data, callback)),
    setEventData: data => dispatch(actions.setEventData(data)),
    showSuccessPopup: (id, graph, data) =>
      dispatch(actions.showSuccessPopup(id, graph, data)),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    setOpenedYearAndId: data => dispatch(actions.setOpenedYearAndId(data)),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    setActiveBubble: id => dispatch(actions.setActiveBubble(id)),
    setCurrentEventId: id => dispatch(actions.setCurrentEventId(id)),
    onSaveFilesToAttach: files => dispatch(actions.saveFilesToAttach(files)),
    setAttachmentUploadStatus: status => dispatch(actions.setAttachmentUploadStatus(status)),
    setAttachmentArtifactId: id => dispatch(actions.setAttachmentArtifactId(id)),
    setAttachmentFullSize: size => dispatch(actions.setAttachmentFullSize(size)),
    showUploadProgress: () => dispatch(actions.showUploadProgress()),
    onUpload: (
      artifactId,
      formData,
      callBack,
      reload,
      journalUserId,
      fileSize,
      uploadConfig,
      cancelToken,
      uniqueId,
      currentEventId,
    ) =>
      dispatch(
        actions.uploadAttachmen(
          artifactId,
          formData,
          callBack,
          reload,
          journalUserId,
          fileSize,
          uploadConfig,
          cancelToken,
          uniqueId,
          currentEventId,
        ),
      ),
    cancelUploadAttachmentProcess: (artifactId, journalUserId, callback, uniqueId) => 
      dispatch(actions.cancelUploadAttachmentProcess(artifactId, journalUserId, callback, uniqueId)),
    fetchAllHealthJourneyEvents: (journalUserId, callback) => 
      dispatch(actions.fetchAllHealthJourneyEvents(journalUserId, callback)),
    onDeleteTherapie: (id, callBack, journalUserId) =>
      dispatch(actions.deleteTherapie(id, callBack, journalUserId)),
    editTherapie: (treatment, callback) =>
      dispatch(actions.editTherapie(treatment, callback)),
    updateTherapie: (addNewTherapie, callBack, journalUserId) =>
      dispatch(actions.updateTherapie(addNewTherapie, callBack, journalUserId)),
    resetNewTherapie: () => dispatch(actions.resetNewTherapie()),
    updateSystemDefinedSymptoms: (callback) => dispatch(actions.updateSystemDefinedSymptoms(callback)),
    createUserSymptom: (userSymptom, callback, journalUserId) =>
      dispatch(actions.createUserSymptom(userSymptom, callback, journalUserId)),
    onDeleteSymptoms: (id, userSymptoms, callback, journalUserId) =>
      dispatch(actions.deleteUserSymptom(id, userSymptoms, callback, journalUserId)),
    onSelectTherapie: (SelectedTherapie, callBack) =>
      dispatch(actions.selectTherapie(SelectedTherapie, callBack)),
    onSelectSymptom: (selectedSymptom, callback) =>
      dispatch(actions.onSelectSymptom(selectedSymptom, callback)),
    fetchHealthJourneyTagsList: (filterKey, serviceType, callBack)=>
      dispatch(actions.fetchHealthJourneyTagsList(filterKey, serviceType, callBack))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEventScreen);