import React, { Component } from 'react';
import { View } from 'react-native';
import * as axiosCommon from '../../axios/axios-common';
import * as url from '../../store/actions/urls';

export default class ReportCommon extends Component {

  uploadGraphImage = () => {
    this.chart.exporting.getImage('png').then(imgData => {
        axiosCommon.axiosPUT(url.UPLOAD_GRAPH_IMAGE_REPORT, () => {}, {
          name: this.props.graphImageS3,
          content: imgData.replace(/^data:image\/[a-z]+;base64,/, ''),
        });
    });
  }

  updateGrph = chart => {
    this.chart = chart;
  };


  render() {
    return (<View />)}
}
