import React from 'react';
import {View, TextInput} from 'react-native';
import styles from '../../Css';
import FieldTitle from './fieldTitle';
import TextFieldToolTip from './textFieldToolTip';
import * as FontScaling from '../../components/Text/fontScaling';

const textInputFrame = props => {
  const {value, keyboardType, onChangeText, editable} = props;
  return (
    <View
      style={[
        props.fieldSm ? styles.textInputSmWrapper : styles.textInputWrapper,
        styles.postRelative,
      ]}>
      <FieldTitle
        fieldAlign={props.fieldAlign}
        fieldBg={props.fieldBg}
        title={props.title}
        fieldLt={props.fieldLt}
        fieldSm={props.fieldSm}
        fieldRg={props.fieldRg}
      />
      <TextInput
        style={[
          props.fieldSm ? styles.textInputFieldSm : styles.textInputField,
          props.fieldInfo ? styles.frameInfoPd : styles.framePd,
          props.fieldBg === 'grey'
            ? styles.bgThinGrey
            : props.fieldBg === 'blue'
            ? styles.bgBlueExLight
            : props.fieldBg === 'transparent'
            ? styles.bgTransparent
            : styles.bgWhite,
          props.fieldAlign === 'center'
            ? styles.textAlignCenter
            : styles.textAlignLeft,
        ]}
        allowFontScaling={false}
        underlineColorAndroid="transparent"
        value={value}
        keyboardType={keyboardType}
        onChangeText={onChangeText}
        editable={editable}
        maxLength={props.maxLength}
        maxFontSizeMultiplier = {FontScaling.fontScaleField}
      />

      {Boolean(props.fieldInfo) && (
        <TextFieldToolTip renderComponent={props.renderComponent} />
      )}
    </View>
  );
};

export default textInputFrame;
