import React, { Fragment , useState, useEffect} from "react";
import {View, TextInput} from 'react-native';
import moment from 'moment';
import HealthJourneyDatePicker from "./HealthJourneyDatePicker";
import WarningInlineCommon from "../../../components/Warning/warningInlineCommon";
import styles from '../../../Css';
import * as constants from '../HealthJourneyConstant'
import theme from "../../../css/base/theme";

const AddEventNameDatePicker = (props) => {
    const { eventName, onChangeEventName, setEndDate, setStartDate, startDate, endDate, isStartDaySelected, setIsStartDaySelected, isEndDaySelected, setIsEndDaySelected, setActiveBubble, activeBubbleId, isStartDateSelected,isEndDateSelected, autoFocus, dob, dismissTagList} = props;
    
    const [_isStartDateSelected, setIsStartDateSelected] = useState(false);
    useEffect(() => {
       if (startDate !== null) {
          setIsStartDateSelected(true);
       } else {
          setIsStartDateSelected(false);
       }
    }, [startDate])

    return (
        <Fragment>
            {/* Event Name */}
            <View>
                <TextInput style={[styles.textTitleExLgBold, styles.txtGreyHighlightCr, styles.addEvntTxtInput, styles.textFieldMax]}
                    //multiline={true}
                    placeholder={constants.EVENT_NAME}
                    placeholderTextColor={theme.COLOR_GREY}
                    onChangeText={(value) => {
                        if (value) {
                            onChangeEventName(value)
                        } else {
                            onChangeEventName(null)
                        }
                    }}
                    value={eventName || ""}
                    autoFocus={!autoFocus}
                    onFocus={() => {
                        activeBubbleId && setActiveBubble(null);
                        dismissTagList();
                    }}
                />
            </View>

            {/* Calendar */}
            <View style={[styles.flexRow, styles.comSpMd, styles.mgTpEighteen, styles.startDateEndPopZIndex]}>
                <HealthJourneyDatePicker
                    dob={dob}
                    setStartDate={setStartDate}
                    setIsStartDateSelected={setIsStartDateSelected}
                    type="start"
                    title={(startDate && startDate.length == 10) ? startDate ? moment(startDate, 'YYYY-MM-DD').format("DD'MMM'YY") : constants.START_DATE :
                    startDate ?moment(startDate, 'YYYY-MM').format("MMM'YY") : constants.START_DATE}
                    required={startDate ? false : true}
                    endDate={endDate}
                    startDate={startDate}
                    isEndDaySelected={isEndDaySelected}
                    isStartDaySelected={isStartDaySelected}
                    setIsStartDaySelected={setIsStartDaySelected}
                    setIsEndDaySelected={setIsEndDaySelected}
                    setActiveBubble={setActiveBubble}
                    activeBubbleId={activeBubbleId}
                    isStartDateSelected = {isStartDateSelected}
                    dismissTagList={dismissTagList}
                />
                <View style={styles.wdTwelve} />
                <HealthJourneyDatePicker
                    dob={dob}
                    setEndDate={setEndDate}
                    disabled={!_isStartDateSelected}
                    type="end"
                    title={(endDate && endDate.length == 10) ? endDate ? moment(endDate, 'YYYY-MM-DD').format("DD'MMM'YY") : constants.END_DATE :
                    endDate ? moment(endDate, 'YYYY-MM').format("MMM'YY") : constants.END_DATE}
                    endDate={endDate}
                    startDate={startDate}
                    isStartDaySelected={isStartDaySelected}
                    isEndDaySelected={isEndDaySelected}
                    setIsStartDaySelected={setIsStartDaySelected}
                    setIsEndDaySelected={setIsEndDaySelected}
                    setActiveBubble={setActiveBubble}
                    activeBubbleId={activeBubbleId}
                    isEndDateSelected={isEndDateSelected}
                    dismissTagList={dismissTagList}
                />
            </View>
            {endDate == 'Invalid date' ? null : endDate !== null ? !moment(startDate).isAfter(endDate) ? null :
                <View style={styles.flexJcFsAiFs}>
                    <WarningInlineCommon errorDescription={'Invalid date, start date must be anterior to end date'} />
                </View> : null}
        </Fragment>
    );
};

export default AddEventNameDatePicker;
