import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import StepThreeSymptom from './StepThreeSymptom';
import {itemTypes} from '../../../Constant/index';

class StepThreeSymptomList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      symptomArray: props.symptoms ? props.symptoms : [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {symptoms} = props;
    if (symptoms && symptoms.length !== state.symptomArray.length) {
      return {
        symptomArray: symptoms,
      };
    }

    return null;
  }

  hasSymptomRecordings = (id) => {
    let symptoms = [];
    let records = false;
    const journalEntries = Object.values(this.props.journalEntriesProcessed);
    if (journalEntries && journalEntries.length > 0) {
      journalEntries.forEach(res => {
        if (res.symptoms && res.symptoms.length > 0) {
          res.symptoms.forEach(re => {
            symptoms.push(re);
          });
        }
      });
    }

    if (symptoms && symptoms.length > 0) {
      records = symptoms.filter(res => {
        return res.symptom === id;
      });
    }
    return records && records.length > 0 ? true : false;
  };

  handleOnDeleteSymptom = id => {
    //Delete symptom from server
    const tempData = this.state.symptomArray;
    const {journalUserId, userDefinedSymptoms} = this.props;
    if (tempData[id].id !== -1 && tempData[id]?.symptom?.addedFromRW && !this.hasSymptomRecordings(tempData[id].id)) {
      this.props.onDeleteSymptoms(
        tempData[id].id,
        userDefinedSymptoms,
        () => {},
        journalUserId,
        true //hide error message banner
      );
    }

    tempData.splice(id, 1);
    this.setState({
      symptomArray: tempData,
    });

    this.props.updateSymptom(tempData, () => {
      if (this.props.updateSymptoms) {
        this.props.updateSymptoms(tempData);
      }
      this.checkSymptomsAbleToSave();
    });
  };

  handleOnScale = (id, sMin, sMax) => {
    const tempData = this.state.symptomArray;
    let newTemp = tempData[id];
    newTemp.displayProperties.scaleMax = sMax;
    newTemp.displayProperties.scaleMin = sMin;

    tempData.splice(id, 1, newTemp);

    this.setState({
      symptomArray: tempData,
    });

    this.props.updateSymptom(tempData, () => {
      if (this.props.updateSymptoms) {
        this.props.updateSymptoms(tempData);
      }
    });
  };

  handleOnSymptomName = (id, name) => {
    const tempData = this.state.symptomArray;
    let newTemp = tempData[id];
    newTemp.name = name;
    newTemp.symptom.name = name;

    this.setState({
      symptomArray: tempData,
    });

    this.props.updateSymptom(tempData, () => {
      if (this.props.updateSymptoms) {
        this.props.updateSymptoms(tempData);
      }
      this.checkSymptomsAbleToSave();
    });
  };

  checkSymptomsAbleToSave = () => {
    const {symptoms, routineWizardSaveDisable} = this.props;
    let invalidName = symptoms.some(symptom => {
      return symptom.id == -1 && symptom?.name?.trim() === ''
    })
    routineWizardSaveDisable(invalidName, itemTypes.symptom);
  }

  render() {
    let rows = this.state.symptomArray.map((res, i) => {
      return (
        <StepThreeSymptom
          key={i}
          symptom={res}
          index={i}
          handleOnDeleteSymptom={this.handleOnDeleteSymptom}
          handleOnScale={this.handleOnScale}
          handleOnSymptomName={this.handleOnSymptomName}
          editableSymptom={!this.hasSymptomRecordings(res?.id !== -1 ? res?.id : res?.symptom?.id)}
          type={res.type}
        />
      );
    });
    return <React.Fragment>{rows}</React.Fragment>;
  }
}
const mapStateToProps = state => {
  return {
    symptoms: state.setup.symptoms,
    journalUserId: state.uistate.journalUserId,
    userDefinedSymptoms: state.routines.symptomsDigest.userSymptoms,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateSymptom: (addNewSymptom, callBack) =>
      dispatch(actions.updateSymptom(addNewSymptom, callBack)),
    onDeleteSymptoms: (id, userSymptoms, callback, journalUserId, hideErrorMsg) =>
      dispatch(
        actions.deleteUserSymptom(id, userSymptoms, callback, journalUserId, hideErrorMsg),
      ),
    routineWizardSaveDisable: (flag, isFrom ) => 
      dispatch(actions.updateRoutineWizardSaveDisable(flag, isFrom))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepThreeSymptomList);
