import React, {Component} from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import TickButton from "../../components/CheckBox/CheckBox";
import {connect} from 'react-redux';
import * as axiosCommon from '../../axios/axios-common';
import * as urls from '../../store/actions/urls';
import AttachmentImageBox from '../Attachment/AttachmentImageBox/classImageBox';
import {formatDate} from '../../util/formatDate';
import OptionsPopup from '../../components/PopupMenu/ImagesDocsPopup';
import ConfirmationBox from '../../components/ConfirmationBox/confirmationBox';
import * as actions from '../../store/actions/index';
import Highlighter from '../../modules/textHighlighter';
import {findThumbnail} from '../../util/commonUiLogic';
class ImagePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteConfirm: false,
      viewImage: false,
      loading: true,
    };
  }

  showDeleteConfirm = () => {
    this.setState({deleteConfirm: true});
  };

  showDoc = id => {
    if (this.props.mimetype.split('/')[1] === 'pdf') {
      this.props.viewAttachment(id, this.props.fileName);
    } else {
      this.setState({
        viewImage: true
      });
      axiosCommon.axiosGET(urls.S3_ATTACHMENTS + id, res => {
        if (res.type === 'SUCCESS') {
          this.setState({
            // viewImage: true,
            attachmentId: id,
            downloadUrl: res.responseDto.data.downloadUrl,
          });
        } else {
          this.setState({
            viewImage: false
          });
        }
      });
    }
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {selectedItems = [], preSelectedItems = [], updateSelectedItems, attachmentId, fileName, fromHealthJourney} = this.props;
    const ticked = selectedItems.concat(preSelectedItems)?.includes(attachmentId);
    return (
      <View style={[styles.viewWrapper]}>
        <View style={[styles.greyBorderThin]}>
          <View style={[styles.itemSingleRow, styles.boxFull, {alignItems:'center'}]}>
            <TouchableOpacity
              onPress={() => {
                this.showDoc(this.props.attachmentId);
              }}
              style={[styles.itemSingleRow, styles.boxFull]}
              disabled={this.props.disableText}
            >
              <View style={styles.docDetailSec}>
                <View style={stylesRes.attachmentItemDetailView} dataSet={{media: ids.attachmentItemDetailView}}>
                  <View
                      style={[styles.attachmentImgDet, styles.attachmentImgView, styles.attachmentImgViewNew]}>
                    {/* <Image
                      style={styles.attachmentImgLg}
                      source={
                        // this.props.mimetype !== 'application/pdf'
                        //   ? {
                        //       uri: this.props.iconUrl,
                        //     }
                          // :
                          // require('../../assets/icons/img-default.png')
                          require(this.findThumbnail(this.props.fileName))
                      }
                    /> */}
                    
                    {/* {this.findThumbnail(this.props.fileName, this.props.iconUrl)} */}
                    <Image 
                        style={styles.attachmentPreviewIcon}
                        source={findThumbnail(this.props.fileName)}/>
                    </View>
                  <View style={[styles.docDetailView]}>
                    <Text
                      style={[
                        styles.textPrimeBold,
                        this.props.disableText
                          ? styles.textGreyDarkMd
                          : styles.activeText,
                      ]}
                      allowFontScaling={false}
                      >
                      {this.props.fileName}
                    </Text>
                    <Text
                      style={[
                        styles.textPrimeSm,
                        styles.textColorLight,
                        this.props.disableText
                          ? styles.textGreyDarkMd
                          : styles.activeText,
                      ]}
                      allowFontScaling={false}
                      >
                      {formatDate(new Date(this.props.uploadDate), "dd mm 'YY")}
                    </Text>
                  </View>
                </View>
              </View>
            </TouchableOpacity>
            
            {Boolean(fromHealthJourney) ? (
              <View style={[styles.docIconSecWrapper, styles.rightSpExLg]}>
                <View style={[styles.itemIconSingle, styles.rightSpExLg]}>
                  <Image
                    style={[
                      styles.starIcon,
                      this.props.disableText
                        ? styles.iconInactiveColor
                        : styles.iconActiveTintColor,
                    ]}
                    source={
                      this.props.flagged
                        ? require('../../assets/icons/star-yellow.png')
                        : require('../../assets/icons/star-grey.png')
                    }
                  />
                </View>
                <TickButton
                  imagePath={ticked ? "tickButton" : "untickButton"}
                  toggleTickCallback={() => updateSelectedItems({id: attachmentId, name: fileName})}
                  tickStyle={{ marginRight: 0 }}
                  isTicked={ticked}
                />
              </View>
            ) : (
              <View style={[styles.docIconSecWrapper]}>
                <TouchableOpacity
                  onPress={() => this.props.redirectPage(this.props.artifactId)}
                  style={[styles.itemIconSingle]}>
                  <Image
                    style={[
                      styles.attachLinkIcon,
                      this.props.disableText
                        ? styles.iconInactiveColor
                        : styles.iconActiveTintColor,
                    ]}
                    source={require('../../assets/icons/attachment-link.png')}
                  />
                </TouchableOpacity>
                <View style={[styles.itemIconSingle]}>
                  <Image
                    style={[
                      styles.starIcon,
                      this.props.disableText
                        ? styles.iconInactiveColor
                        : styles.iconActiveTintColor,
                    ]}
                    source={
                      this.props.flagged
                        ? require('../../assets/icons/star-yellow.png')
                        : require('../../assets/icons/star-grey.png')
                    }
                  />
                </View>
                <OptionsPopup
                  attachmentId={this.props.attachmentId}
                  fileName={this.props.fileName}
                  showDeleteConfirm={this.showDeleteConfirm}
                  downloadAttachment={this.props.downloadAttachment}
                  disableText={this.props.disableText}
                  nativeIdMenuTrigger={'attachments3Dots'}
                  eventNameMenuTrigger={'Attachments@ > 3Dots'}
                />
              </View>
            )}

            {this.props.mimetype.split('/')[0] === 'image' && (
              <AttachmentImageBox
                visibleModal={this.state.viewImage}
                onBackdropPress={() => {
                  this.setState({viewImage: false});
                }}
                close={() => {
                  this.setState({viewImage: false});
                }}
                attachmentId={this.props.attachmentId}
                downloadUrl={this.state.downloadUrl}
                logout={this.props.logout}
              />
            )}

            <ConfirmationBox
              visibleModal={this.state.deleteConfirm}
              onBackdropPress={() => {
                this.setState({deleteConfirm: false});
              }}
              onRequestClose={() => {
                this.setState({deleteConfirm: false});
              }}
              alertTitle={'Delete attachment'}
              handleDelete={() => {
                this.props.deleteAttachment(
                  this.props.attachmentId,
                  this.props.fileName,
                  this.props.fileSize,
                  this.props.artifactId
                );
                this.setState({deleteConfirm: false});
              }}
            />
          </View>
        </View>

        {/* Section for seach result start*/}
        {this.props.searchWordContainingSentences &&
          this.props.searchWordContainingSentences.length > 0 && (
            <View style={[styles.viewMoreSection]}>
              <View style={[styles.viewMoreDataSingle]}>
                <View style={[styles.viewMoreDateRow, styles.viewMorePd]}>
                  {this.props.searchWordContainingSentences.map(
                    (res, index) => {
                      return (
                        <View
                          style={[styles.viewMoreDataItem]}
                          key={'hi_' + index}>
                          <Text style={[styles.textPrimeSm]}>
                            <Highlighter
                              highlightStyle={{
                                backgroundColor: '#e5e5e5',
                                fontWeight: 'bold',
                              }}
                              autoEscape={true}
                              searchWords={[this.props.filter]}
                              textToHighlight={res}
                              allowFontScaling={false}
                            />
                          </Text>
                        </View>
                      );
                    },
                  )}
                </View>
              </View>
            </View>
          )}

        {/* Section for seach result end*/}
      </View>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(ImagePreview);
