import React, { useState, useRef, useEffect } from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  Animated,
  UIManager,
  Platform,
  LayoutAnimation,
} from 'react-native';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import styles from '../../Css';
import { getDashboardData, dashboardCategories } from '../../util/commonUiLogic';
import { pendoTrackEvent } from '../../util/pendoConfig';
import { ANDROID, WEB } from '../../util/platformWindowConfig';

if (
  Platform.OS === ANDROID &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

const DashboardWrapper = props => {
  const { children, style, nativeId, nativeIdInner, type, visibleGraph, onHideShowGraph, nativeIdShowHide, scheduleCount, updateDashboardItemShowHide, dashBoardWrapperShowHide = {} } = props;
  const [open, setOpen] = useState(true);
  const opacity = useRef(new Animated.Value(1)).current;
  let occupied = useRef(false).current;
  const {
    title,
  } = getDashboardData(type);

  useEffect(() => {
    if (type !== dashboardCategories.INSIGHTS && dashBoardWrapperShowHide.hasOwnProperty(nativeIdShowHide)) {
      setOpen(dashBoardWrapperShowHide[nativeIdShowHide]);
    }
    //eslint-disable-next-line
  }, [0])

  useEffect(() => {
    if (type === dashboardCategories.INSIGHTS && visibleGraph !== open) {
      onPressOpen()
    }
    //eslint-disable-next-line
  }, [visibleGraph])

  useEffect(() => {
    if (type !== dashboardCategories.INSIGHTS) updateDashboardItemShowHide({ [nativeIdShowHide]: open });
   //eslint-disable-next-line
  }, [open])



  const onPressOpen = () => {
    type === dashboardCategories.INSIGHTS && pendoTrackEvent('Dashboard@ > GraphGraphShowHide');
    if (Platform.OS === WEB) {
      if (occupied) return;
      occupied = true;
      if (!open) {
        setOpen(e => !e);
        Animated.timing(opacity, {
          toValue: 1,
          duration: 400,
        }).start(() => {
          occupied = false;
          type === dashboardCategories.INSIGHTS && onHideShowGraph(true)
        });
      } else {
        Animated.timing(opacity, {
          toValue: 0,
          duration: 400,
        }).start(() => {
          setOpen(e => !e)
          occupied = false;
          type === dashboardCategories.INSIGHTS && onHideShowGraph(false)
        });
      }
    } else {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      type === dashboardCategories.INSIGHTS && onHideShowGraph(!open)
      setOpen(e => !e);
    }

  };

  return (
    <View
      style={[styles.dashboardWrapper, style]}
      nativeID={nativeId}>
      <TouchableOpacity onPress={onPressOpen} testID={'SHOW_HIDE_' + type}>
        <View
          style={[
            styles.flexRow, styles.flexJcSb,
            styles.infoPdTopBotExSm,
            open && [
              type !== dashboardCategories.SCHEDULES && type !== dashboardCategories.INSIGHTS && styles.titleBottomBorder,
              styles.flexCom,
            ],
          ]}>
          <View style={[styles.flexRow, styles.flexJcCtAiCt]}>
            <Text style={styles.textTitleMdBold} allowFontScaling={false}>
              {title}
            </Text>
            {type === dashboardCategories.SCHEDULES &&
              <Text style={styles.textTitle} allowFontScaling={false}>{scheduleCount + ' items'}</Text>
            }
          </View>
          <View nativeID={nativeIdShowHide}>
            <Text style={[styles.textPrimeBold, styles.resourceBlueTxt, styles.textUnderline]} allowFontScaling={false}>{open ? 'Hide' : 'Show'}</Text>
          </View>
        </View>
      </TouchableOpacity>
      {open && (
        <Animated.View
          nativeID={nativeIdInner}
          style={{
            opacity,
            maxHeight: opacity.interpolate({
              inputRange: [0, 1],
              outputRange: ['0%', '100%'],
            }),
            overflow: 'hidden',
            paddingTop: open ? 5 : 0,
          }}>
          {children}
        </Animated.View>
      )}
    </View>
  );
};
const mapStateToProps = state => {
  return {
    dashBoardWrapperShowHide: state.uistate.dashBoardWrapperShowHide,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDashboardItemShowHide: (item) => dispatch(actions.updateDashboardItemShowHide(item)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardWrapper);

