import React, {Component} from "react";
import {Animated, ScrollView, TouchableWithoutFeedback, View} from 'react-native';
import theme from "../../css/base/theme";
import styles from '../../Css';

class ScrollViewWithScrollBarIndicator extends Component {
  constructor(props) {
    super(props);
    this.scrollviewRef = React.createRef();
    this.scrollPosition = 0;
    this.state = {
      scrollIndicator: new Animated.Value(0)
    };
  }

  setCompleteScrollBarHeight(height) {
    this.setState({completeScrollBarHeight: height});
  }

  setVisibleScrollBarHeight(height) {
    this.setState({visibleScrollBarHeight: height});
  }

  onScrollbarWheel(scrollEvent) {
    let {deltaY} = scrollEvent;
    if (deltaY !== undefined) {
      this.scrollviewRef.current.scrollTo({
        x: 0,
        y: Math.max(0, this.scrollPosition + deltaY),
        animated: true
      })
    }
  }

  scrollbarPressed(pressEvent) {
    let {locationY} = pressEvent;
    if (locationY === undefined) {
      if (pressEvent.target && pressEvent.target.getBoundingRect) {
        const rect = pressEvent.target.getBoundingClientRect();
        locationY = pressEvent.clientY - rect.top;
      }

      if (pressEvent.target && pressEvent.target.measure) {
        pressEvent.target.measure((x, y, width, height, pageX, pageY) => {
          console.log(x, y, width, height, pageX, pageY);
          locationY = pressEvent.clientY - y;
          this.scrollbarPressed({...pressEvent, locationY});
        });
        return;
      }

      if (locationY === undefined) {
        return;
      }
    }

    let normalizedClickPosition = Math.min(Math.max(0, locationY), this.state.visibleScrollBarHeight);
    const relativePosition = normalizedClickPosition / this.state.visibleScrollBarHeight;

    const scrollIndicatorSize = this.getScrollIndicatorSize(this.state.completeScrollBarHeight, this.state.visibleScrollBarHeight);
    const relativeDistanceFromCenter = (normalizedClickPosition - this.state.visibleScrollBarHeight/2)/(this.state.visibleScrollBarHeight/2);

    const nonAdjustedScrollPosition = Math.max(0, (this.state.completeScrollBarHeight - this.state.visibleScrollBarHeight) * relativePosition);
    const adjustedScrollPositionToPushIndicatorToEndsOfBar = (relativeDistanceFromCenter * scrollIndicatorSize) + nonAdjustedScrollPosition;

    this.scrollviewRef.current.scrollTo({
      x: 0,
      y: adjustedScrollPositionToPushIndicatorToEndsOfBar,
      animated: true
    })
  }

  getScrollIndicatorSize(completeScrollBarHeight: number, visibleScrollBarHeight: number) {
    const scrollIndicatorSize =
      completeScrollBarHeight > visibleScrollBarHeight
        ? (1 - ((completeScrollBarHeight - visibleScrollBarHeight) / completeScrollBarHeight)) * visibleScrollBarHeight
        : visibleScrollBarHeight;
    return scrollIndicatorSize;
  }

  render() {
    const {completeScrollBarHeight = 1} = this.state;
    const {visibleScrollBarHeight = 1} = this.state;
    const {persistentScrollbar = false} = this.props;
    const scrollIndicatorSize = this.getScrollIndicatorSize(completeScrollBarHeight, visibleScrollBarHeight);

    const showScrollbar = completeScrollBarHeight > visibleScrollBarHeight || persistentScrollbar;

    const difference =
      visibleScrollBarHeight > scrollIndicatorSize
        ? visibleScrollBarHeight - scrollIndicatorSize
        : 1;

    let rangeOfMenuListValues = Math.max(1, completeScrollBarHeight - visibleScrollBarHeight);
    const scrollIndicatorPosition = this.state.scrollIndicator.interpolate({
      inputRange: [0, rangeOfMenuListValues],
      outputRange: [0, difference],
      extrapolate: 'clamp'
    });

    return <View style={[styles.flexRow, {flex: 1}]}>
      <ScrollView
        ref={this.scrollviewRef}
        scrollID={this.props.scrollID}
        fadingEdgeLength={175}
        showsVerticalScrollIndicator={false}
        onContentSizeChange={(contentWidth, contentHeight) => {
          this.setCompleteScrollBarHeight(contentHeight);
        }}
        onLayout={({
                     nativeEvent: {
                       layout: {height}
                     }
                   }) => {
          this.setVisibleScrollBarHeight(height);
        }}
        onScroll={Animated.event(
          [{nativeEvent: {contentOffset: {y: this.state.scrollIndicator}}}],
          {
            useNativeDriver: false,
            listener: (event) => {this.scrollPosition = event.nativeEvent.contentOffset.y}
          }
        )}
        scrollEventThrottle={16}>
        {this.props.children}
      </ScrollView>
      {Boolean(showScrollbar) &&
        <TouchableWithoutFeedback
          onPress={(event) => this.scrollbarPressed(event)}
        >
          <View
            style={{
              height: '100%',
              width: 6,
              backgroundColor: theme.COLOR_GREY_MEDIUM_EX,
              borderRadius: 8
            }}
            onWheel={(event) => this.onScrollbarWheel(event)}
          >
            <Animated.View
              style={{
                width: 6,
                borderRadius: 8,
                backgroundColor: theme.COLOR_ORANGE_DARK,
                height: scrollIndicatorSize,
                transform: [{translateY: scrollIndicatorPosition}]
              }}
              pointerEvents="none"
            />
          </View>
        </TouchableWithoutFeedback>
      }
    </View>
  }
}

export default (ScrollViewWithScrollBarIndicator);
