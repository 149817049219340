
export const generateQuickNoteTitle = (quickNoteJournalName, quickNote, files) => {

    if (quickNoteJournalName.length < 1 && quickNote.length > 0) {
        return quickNote.substring(0, 34);
    } else if (Object.keys(files).length > 0 && quickNoteJournalName.length < 1) {
        return Object.keys(files).length < 2 ? `${Object.keys(files).length} File` : `${Object.keys(files).length} Files`
    } else {
        return quickNoteJournalName;
    }

}