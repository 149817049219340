import * as axiosCommon from '../../axios/axios-common';
import * as axiosCommonNotif from '../../axios/axios-common-notif';
import * as url from './urls';
import * as actionTypes from './actionTypes';
import {Platform} from 'react-native';
import moment from 'moment';
import {logout, commonErrorHandler, updateDeviceMap, updateJournalEntryTypeDetails, resetNewJournal} from './index';
import { preProcessScheduleData, scheduleStatus } from '../../util/commonUiLogic';
import { WEB } from '../../util/platformWindowConfig';
import { APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED } from '../../util/featureConfig';
//import {clearBackgroundTask, startBackgroundTask} from './backgroundTask';


export const selectMenuItem = index => {
  return {
    type: actionTypes.SELECT_MENU_ITEM,
    selectedMenu: index,
  };
};

export const deselectMenuItem = () => {
  return {
    type: actionTypes.DESELECT_MENU_ITEM,
  };
};

export const sideDrawerToggle = () => {
  return {
    type: actionTypes.SIDE_DAWER_TOGGLE,
  };
};

export const slectMoreMenu = () => {
  return {
    type: actionTypes.MORE_MENU,
  };
};

export const uploadProfileImageSucess = () => {
  return {
    type: actionTypes.UPLOAD_PROFILE_IMAGE_SUCCESS,
  };
};

export const windowResize = (windowHeight, windowWidth) => {
  return {
    type: actionTypes.WINDOW_RESIZE,
    windowHeight: windowHeight,
    windowWidth: windowWidth,
  };
};

export const showTutorial = tutorialType => {
  return {
    type: actionTypes.SHOW_TUTORIAL,
    tutorialType: tutorialType,
  };
};

export const setJournalUser = (journalUserId, journalUserName) => {
  return {
    type: actionTypes.SET_JOURNAL_USER,
    journalUserId: journalUserId,
    journalUserName: journalUserName,
  };
};

export const showLearnHow = () => {
  return {
    type: actionTypes.SHOW_LEARN_HOW,
  };
};
export const showUserLicenseAgreement = () => {
  return {
    type: actionTypes.SHOW_USER_LICENSE_AGREEMENT,
  };
};
export const showPrivacyStatment = () => {
  return {
    type: actionTypes.SHOW_PRIVACY_STATMENT,
  };
};

export const setToken = (token, type, reminderDateTime = null) => {
  return {
    type: actionTypes.SET_TOKEN,
    token: token,
    tokenType: type,
    reminderDateTime: reminderDateTime
  };
};

export const reset = () => {
  //clearBackgroundTask();
  return {
    type: actionTypes.UI_STATE_RESET,
  };
};

export const routineReminders = (journalEntryTypes, journalEntries, notif, routineSchedules = []) => {
  const todayReminders = getTodayReminders(journalEntryTypes);
  const startDate = new Date();
  const endDate = new Date();
  endDate.setHours(23, 59, 59, 59);
  startDate.setHours(0, 0, 0, 0);

  const todayJournalEntries = journalEntries.filter(function(journalEntrie) {
    return (
      journalEntrie.entryDate > startDate.getTime() &&
      journalEntrie.entryDate < endDate.getTime()
    );
  });

  const todayCompletedRoutineSchedules = Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED) && Boolean(routineSchedules && routineSchedules?.length > 0) &&
  routineSchedules.filter(schedule => {
    return schedule.scheduleTimeStatus === scheduleStatus.UP_COMING && schedule.completed
  });
  
  const today = new Date();
  const notifyReminders = [];

  todayReminders.forEach(item => {
    const reminder = item.reminders;
    let splitedTime = reminder?.reminderTime && Array.isArray(reminder?.reminderTime) ? reminder?.reminderTime : reminder?.reminderTime?.split(':');
    const reminderTime = new Date();
    const reminderEnd = Boolean(reminder.reminderEnd) ? new Date(reminder.reminderEnd) : null;
    if (splitedTime && splitedTime.length > 0) {
      reminderTime.setHours(
        splitedTime[0],
        splitedTime[1],
        0,
        0,
      );
      Boolean(reminderEnd) && reminderEnd.setHours(
        splitedTime[0],
        splitedTime[1],
        0,
        0,
      );
    }

    var diff = (reminderTime.getTime() - today.getTime()) / 1000;
    diff /= 60 * 60;

    let found = true;
    if (Boolean(APP_FEATURE_DASHBOARD_SCHEDULE_ENABLED)) {
      found = Boolean(todayCompletedRoutineSchedules && todayCompletedRoutineSchedules.length> 0) &&
      todayCompletedRoutineSchedules.find(
        schedule => 
          schedule.entryTypeId === item.id && schedule.reminderDateTime === reminderTime.getTime()
      );
    } else {
      found = Boolean(todayJournalEntries && todayJournalEntries.length > 0) &&
      todayJournalEntries.find(
        journal =>
          journal.entryType === item.id && journal.entryDate < today.getTime(),
      );
    }
    
    let isEnd = true;
    if ((reminderEnd && today.getTime() <= reminderEnd.getTime()) || reminderEnd === null) {
      isEnd = false;
    }
    if (!isEnd && diff <= 2 && !found) {
      const data = {
        type: 'ROUTINE_REMINDERS',
        data: item,
        date: reminderTime,
        id: 'RR_' + item.id + '_' + reminder.id + '_' + moment(new Date()).format('YYYY-MM-DD'),
      };
      notifyReminders.push(data);
    }
  });

  return dispatch => {
    dispatch(setRoutineReminders(notifyReminders, notif));
  };
};

export const setRoutineReminders = (notifyReminders, notif) => {
  return {
    type: actionTypes.ROUTINE_REMINDERS,
    notification: notifyReminders,
    notif: notif,
  };
};

const getTodayReminders = journalEntryTypes => {
  const toDayReminders = [];
  const today = new Date();
  journalEntryTypes.forEach(element => {
    if (element.active && element.reminders && element.reminders.length > 0 && element.type !== "DEVICE") {
      element.reminders.forEach(reminder => {
        if (reminder.reminder) {
          if (reminder.reminderEveryday) {
            toDayReminders.push({...element, reminders: reminder});
          } else if (today.getDay() === 0 && reminder.reminderSunday) {
            toDayReminders.push({...element, reminders: reminder});
          } else if (today.getDay() === 1 && reminder.reminderMonday) {
            toDayReminders.push({...element, reminders: reminder});
          } else if (today.getDay() === 2 && reminder.reminderTuesday) {
            toDayReminders.push({...element, reminders: reminder});
          } else if (today.getDay() === 3 && reminder.reminderWednesday) {
            toDayReminders.push({...element, reminders: reminder});
          } else if (today.getDay() === 4 && reminder.reminderThursday) {
            toDayReminders.push({...element, reminders: reminder});
          } else if (today.getDay() === 5 && reminder.reminderFriday) {
            toDayReminders.push({...element, reminders: reminder});
          } else if (today.getDay() === 6 && reminder.reminderSaturday) {
            toDayReminders.push({...element, reminders: reminder});
          } else if (
            reminder.reminderMonthly &&
            today.getDate === reminder.reminderMonthlyDay
          ) {
            toDayReminders.push({...element, reminders: reminder});
          }
        }
      });
    }
  });
  return toDayReminders;
};

export const setSeenNotification = () => {
  return {
    type: actionTypes.SEEN_NOTIFICATION,
  };
};

export const resetNotification = () => {
  return {
    type: actionTypes.RESET_NOTIFICATION,
  };
};

export const clearAllNotification = () => {
  return (dispatch, state) => {
    let requestUrl = url.CLEAR_ALL_NOTIFICATION;
    let journalUserId = state().uistate?.journalUserId;
    if (journalUserId) {
      requestUrl += '?journalUserId=' + journalUserId;
    }
    axiosCommon.axiosDELETE(requestUrl, res => {
      if (res.type === 'SUCCESS') {
        dispatch({
          type: actionTypes.CLEAR_ALL_NOTIFICATION,
          from: "MANUAL",
        });
      } else {
        if (res.errorDto === 'SESSION_TIME_OUT') {
          console.log("session time out; clearAllNotification");
          dispatch(logout());
        } else if (res.errorDto !== 'undefined') {
          dispatch({
            type: actionTypes.COMMON_ERROR_HANDLER,
            errorDescription: res.errorDto.data.error,
          });
        }
      }
    });
  };
};

export const clearNotificationById = id => {
  return {
    type: actionTypes.CLEAR_NOTIFICATION_BY_ID,
    id: id,
  };
};

export const clearMenuSectionNotifications = (notificationArray) => {
  return {
    type: actionTypes.CLEAR_MENU_SECTION_NOTIFICATIONS,
    sectionNotifications: notificationArray,
  };
}

export const showMobileMenu = () => {
  return {
    type: actionTypes.SHOW_MOBILE_MENU,
  };
};

export const hideMobileMenu = () => {
  return {
    type: actionTypes.HIDE_MOBILE_MENU,
  };
};

export const showUploadProgress = () => {
  return {
    type: actionTypes.SHOW_UPLOAD_PROGRESS,
  };
};

export const hideUploadProgress = () => {
  return {
    type: actionTypes.HIDE_UPLOAD_PROGRESS,
  };
};

export const onShowDeleteUploadConfirmation = (status, data) => {
  return {
    type: actionTypes.DELETE_UPLOAD_CONFIRMATION,
    status,
    data,
  };
};

export const pullNotification = (journalUserId, notif) => {
  let requestUrl = url.USER_NOTIFICATION;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  //Move timestamp calculation to BE
  // const utcMoment = moment.utc();
  // const utcDate = new Date(utcMoment.format());
  const notifications = [];
  return (dispatch, state) => {
    axiosCommonNotif.axiosGET(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          const flag = state().uistate?.clearAllSynced;
          const clearNotifFlag = res.responseDto.data?.clearAll;
          if (clearNotifFlag) {
            dispatch({
              type: actionTypes.CLEAR_ALL_NOTIFICATION,
              from: "AUTO",
            });
          }
          if (!clearNotifFlag && !flag) return; //we do not need apis done between clear and 1st notif api
          res.responseDto.data.clinicalTrialsNotifications.forEach(item => {
            notifications.push({
              type: 'CLINICALTRIAL',
              data: item,
              date: new Date(item.modifiedOn),
              id:
                'CLINICALTRIAL' +
                item.id +
                '_' +
                moment(item.modifiedOn).format('YYYY-MM-DD'),
            });
          });
          res.responseDto.data.surveyNotifications.forEach(item => {
            notifications.push({
              type: 'SURVEY',
              data: item,
              date: new Date(item.modifiedOn),
              id:
                'SURVEY' +
                item.id +
                '_' +
                moment(item.modifiedOn).format('YYYY-MM-DD'),
            });
          });

          res.responseDto.data.updatedJournalEntryTypes.forEach(item => {
            notifications.push({
              type: 'ROUTINE_UPDATE',
              data: item,
              date: new Date(item.modifiedOn),
              id:
                'ROUTINE_UPDATE' +
                item.id +
                '_' +
                moment(item.modifiedOn).format('YYYY-MM-DD'),
            });
            dispatch(updateJournalEntryTypeDetails(item.entryTypeWithReminders));
          });

          res.responseDto.data.publishedJournalEntryTypes.forEach(item => {
            notifications.push({
              type: 'ROUTINE_PUBLISH',
              data: item,
              date: new Date(item.modifiedOn),
              id:
                'ROUTINE_PUBLISH' +
                item.id +
                '_' +
                moment(item.modifiedOn).format('YYYY-MM-DD'),
            });
            dispatch(updateJournalEntryTypeDetails(item.entryTypeWithReminders));
          });

          //Device journal notification types: JOURNAL_CREATED_FOR_DEVICE, JOURNAL_UPDATED_FOR_DEVICE
          res.responseDto.data.deviceJournalNotifications.forEach(item => {
            if(item){
              notifications.push({
                type: item.type,
                data: item,
                date: new Date(item.modifiedOn),
                id:
                  item.type +
                  item.id +
                  '_' +
                  moment(item.modifiedOn).format('YYYY-MM-DD'),
              });
            }
          });

          //Device connection notification types: AUTO_DISCONNECTED, MANUAL_DISCONNECTED, CONNECTION_EXPIRED, DISCONNECTED_DEVICE, CONNECTED_DEVICE
          res.responseDto.data.deviceConnectedStatus.forEach(item => {
            if(item){
              notifications.push({
                type: item.status,
                data: item,
                date: new Date(item.modifiedOn),
                id:
                  item.status +
                  item.id +
                  '_' +
                  moment(item.modifiedOn).format('YYYY-MM-DD'),
              });
            }
          });

          res.responseDto.data.expiredReminders.forEach(item => {
            notifications.push({
              type: 'EXPIRED_REMINDER',
              data: item,
              date: new Date(item.modifiedOn),
              id:
                'EXPIRED_REMINDER' +
                item.notificationId +
                '_' +
                moment(item.modifiedOn).format('YYYY-MM-DD'),
            });
          });
          res.responseDto.data.expiringReminders.forEach(item => {
            notifications.push({
              type: 'EXPIRING_REMINDER',
              data: item,
              date: new Date(item.modifiedOn),
              id:
                'EXPIRING_REMINDER' +
                item.notificationId +
                '_' +
                moment(item.modifiedOn).format('YYYY-MM-DD'),
            });
          });
          res.responseDto.data.studyNotifications.forEach(item => {
            notifications.push({
              type: 'JOIN_CLINICAL_STUDY',
              data: item,
              date: new Date(item.modifiedOn),
              id:
                'JOIN_CLINICAL_STUDY' +
                item.notificationId +
                '_' +
                moment(item.modifiedOn).format('YYYY-MM-DD'),
            });
          });
          res.responseDto.data.partnerHealthLibraryNotifications.publishedResource.forEach(item => {
            notifications.push({
              type: 'PARTNER_PUBLISH_HEALTH_RESOURCE',
              data: item,
              date: new Date(item.modifiedOn),
              id: 'PARTNER_HEALTH_RESOURCE' + item.resourceId,
            });
          });
          res.responseDto.data.partnerHealthLibraryNotifications.deletedResource.forEach(item => {
            notifications.push({
              type: 'PARTNER_DELETE_HEALTH_RESOURCE',
              data: item,
              date: new Date(item.modifiedOn),
              id: 'PARTNER_HEALTH_RESOURCE' + item.resourceId,
            });
          });
          res.responseDto.data.partnerHealthLibraryNotifications.resourceReminder.forEach(item => {
            notifications.push({
              type: 'PARTNER_REMINDER_HEALTH_RESOURCE',
              data: item,
              date: new Date(item.modifiedOn),
              id: 'PARTNER_HEALTH_RESOURCE' + item.resourceId,
            });
          });
          res.responseDto.data.partnerHealthLibraryNotifications.updatedResource.forEach(item => {
            notifications.push({
              type: 'PARTNER_UPDATED_HEALTH_RESOURCE',
              data: item,
              date: new Date(item.modifiedOn),
              id: 'PARTNER_HEALTH_RESOURCE' + item.resourceId,
            });
          });
          dispatch(addNotifcation(res.responseDto.data.lastNotificationOn, notifications, notif));
          dispatch(handleDevicePopups(res.responseDto.data.deviceStatus));
          //dispatch(addNotifcation(utcDate.getTime(), notifications, notif));
        } else {
          // if (res.errorDto === 'SESSION_TIME_OUT') {
          //   dispatch(logout());
          // }
        }
      },
      {
        timeStamp: state().uistate.notificationTimeStamp,
        mobile: Platform.OS === WEB ? false : true,
      },
    );
  };
};

export const handleDevicePopups = (data) => {
 
  //  data.push({type: 'AUTO_DISCONNECTED'}); //for testing uncomment this
  let popupNotifications = [];
  let nonPopupNotifications = [];
  let deviceDataFail = {
    title: '',
    success: false,
    fail: true,
    bodyTitle: 'Your Hexoskin connection has expired!',
    body: 'Re-connect your Hexoskin to continue creating entries.',
    btnText: 'Reconnect',
    action: 'REDIRECT',
    device: 'HEXOSKIN',
  }

  let deviceDataSuccess = {
    title: 'Hexoskin is now connected!',
    success: true,
    fail: false,
    bodyTitle: false,
    body: 'Zamplo will recieve a daily average of your health data and create an automatic entry.',
    btnText: 'Got it!',
    action: 'GOTIT',
    device: 'HEXOSKIN',
  }
  data.forEach(item => {
    switch (item.status) {
      case 'AUTO_DISCONNECTED':
        case 'DISCONNECTED_DEVICE':
          case 'CONNECTED_DEVICE':
        popupNotifications.push(item);
        break;
      case 'MANUAL_DISCONNECTED':
        nonPopupNotifications.push(item);
        break;
      default:
        break;
    }
  });

  return (dispatch, state) => {

    if (popupNotifications.length > 0) {
      popupNotifications.forEach(item => {
        const filteredDevice = state().device.userDeviceMap.length && state().device.userDeviceMap.filter(value => value.deviceName === item.deviceName) ;
        if (filteredDevice[0].connectionStatus !== 'CONNECTED' && item.status === 'CONNECTED_DEVICE') {
          dispatch(showSuccessPopup(13, null, deviceDataSuccess));
          dispatch(updateDeviceMap(item.deviceName, true));
        } else if (filteredDevice[0].connectionStatus !== 'DISCONNECTED' && (item.status === 'AUTO_DISCONNECTED' || item.status === 'MANUAL_DISCONNECTED')) {
          dispatch(showSuccessPopup(13, null, deviceDataFail));
          dispatch(updateDeviceMap(item.deviceName, false));
        }
      })
    }
  
    if (nonPopupNotifications.length > 0) {
      nonPopupNotifications.forEach(item => {
        dispatch(updateDeviceMap(item.deviceName, item.status === 'MANUAL_DISCONNECTED' ? false : true));
      })
    }
  }
}

export const getAppUpdate = (data, callBack) => {
  let requestUrl = url.APP_VERSION;
  //check for sending app data to use this log
  return (dispatch,state) => {
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(res.responseDto.data);
        }
      },
      data
    );
  };
}

export const appNotNowUpdate = (data, callBack) => {
  let requestUrl = url.IGNORE_APP_UPDATE
  return () =>{
    axiosCommon.axiosPOST(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(res.responseDto.data);
        }
      },
      data
    )
  }
}

export const addNotifcation = (timeStamp, notifications, notif) => {
  return {
    type: actionTypes.ADD_NOTIFCATION,
    notifications: notifications,
    timeStamp: timeStamp,
    notif: notif,
  };
};

export const deleteNotifcation = (journalUserId, idList, callBack) => {
  let requestUrl = url.USER_NOTIFICATION;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosDELETE(
      requestUrl,
      res => {
        if (res.type === 'SUCCESS') {
          callBack(true);
        } else {
          if (res.errorDto === 'SESSION_TIME_OUT') {
            console.log("session time out; deleteNotifcation");
            dispatch(logout());
          } else {
            callBack(false);
          }
        }
      },
      {
        userNotificationIdList: idList,
      },
    );
  };
};
let interval = null;
export const reminder = (journalUserId, notif) => {
  return (dispatch, state) => {
    // startBackgroundTask(() => {
    //   console.log('running backgrund task');
    //   const journalEntryTypes = state().routines.journalEntryTypes;
    //   const journalEntries = state().routines.journalEntries;
    //   dispatch(routineReminders(journalEntryTypes, journalEntries, notif));
    //   dispatch(pullNotification(journalUserId, notif));
    // });
    //no need to run background task as we have implemented push notifications
    //just setinterval woud be enough
    interval = setInterval(() => {
      const journalEntryTypes = state().routines.journalEntryTypes;
      const journalEntries = Object.values(state().routines.journalEntriesProcessed);
      dispatch(routineReminders(journalEntryTypes, journalEntries, notif, state().uistate?.dashboardScheduleData?.routines || []));

      let tempJournalUserId = state().uistate?.journalUserId || null;
      dispatch(pullNotification(tempJournalUserId, notif));
    }, 30000);
  };
};
export const clearNotifInterval = () => {
  return () => {
    if (interval) {
      clearInterval(interval);
    }
  };
};

export const showSuccessPopup = (messageId, graphId, deviceData = null) => {
  return {
    type: actionTypes.SHOW_SUCCESS_POPUP,
    messageId: messageId,
    graphId: graphId,
    deviceData: deviceData,
  };
};

export const setDeviceMainInfo = (hasNotch, uniqueId) => {
  return {
    type: actionTypes.SET_DEVICE_MAIN_INFO,
    hasNotch: hasNotch,
    uniqueId: uniqueId
  };
};

export const hideSuccessPopup = () => {
  return {
    type: actionTypes.HIDE_SUCCESS_POPUP,
  };
};

export const setProfileSecurityShow = show => {
  return {
    type: actionTypes.SET_PROFILE_SECURITY_SHOW,
    show: show,
  };
};

export const updateSignupEmail = email => {
  return {
    type: actionTypes.SIGNUP_EMAIL,
    email: email,
  };
};

export const cognitoReminder = (emailVerified, mfaEnable, notif) => {
  return dispatch => {
    const notifications = [];
    if (!emailVerified) {
      notifications.push({
        type: 'COGNITO_REMINDER',
        data: 'EMAIL',
        date: new Date(),
        id: 'EMAIL_VRIFICATION_REMINDER',
      });
    }
    // Disabling MFA notification for now -- users are struggling with it
    if (false && !mfaEnable) {
      notifications.push({
        type: 'COGNITO_REMINDER',
        data: 'MFA',
        date: new Date(),
        id: 'MFA_ENABLE_REMINDER',
      });
    }
    dispatch(addNotifcation(null, notifications, notif));
  };
};

export const showSetup = (journalUserId, callBack, updateGlobally = true) => {
  return dispatch => {
    dispatch(updateIsSetupOn(true, journalUserId, callBack, updateGlobally));
  };
};

export const hideSetup = (journalUserId, callBack) => {
  return dispatch => {
    dispatch(updateIsSetupOn(false, journalUserId, callBack));
  };
};

export const updateIsSetupOn = (val, journalUserId, callBack, updateGlobally = true) => {
  let requestUrl = url.SETUP_STATUS;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return dispatch => {
    if(updateGlobally) {
      axiosCommon.axiosPOST(
        requestUrl,
        res => {
          if (res.type === 'SUCCESS') {
            callBack(true);
            dispatch(updateIsSetupOnSuccess(val));
          } else {
            if (res.errorDto === 'SESSION_TIME_OUT') {
              console.log("session time out; updateIsSetupOn");
              dispatch(logout());
            } else if (res.errorDto !== 'undefined') {
              dispatch(commonErrorHandler(res.errorDto.data.error));
              callBack(false, res.errorDto.data.error);
            }
          }
        },
        {isSetupCompleted: val},
      );
    }
    else {
      callBack(true);
      dispatch(updateIsSetupOnSuccess(val));
    }
    // dispatch(updateIsSetupOnSuccess(val));
  };
};

export const updateIsSetupOnSuccess = isSetupOn => {
  return {
    type: actionTypes.SETUP_ON_SUCCESS,
    isSetupOn: isSetupOn,
  };
};

export const showHideStorageBanner = val => {
  return {
    type: actionTypes.SHOW_HIDE_STORAGE_BANNER,
    val: val,
  };
};

export const setFromPopup = (val, fromDashbordMeds = false) => {
  return {
    type: actionTypes.SET_FROM_POPUP,
    fromPopup: val,
    fromDashbordMeds: fromDashbordMeds
  };
};

export const setFromNotifId = (id) => {
  return {
    type: actionTypes.SET_FROM_NOTIF_ID,
    fromNotifId: id,
  };
}

export const selectGraphItem = graphId => {
  return {
    type: actionTypes.SELECT_GRAPH_ITEM,
    selectedGraphId: graphId,
  };
};

export const setPreviousGraphId = graphId => {
  return {
    type: actionTypes.SET_PREVIOUS_GRAPH_ID,
    previousGraphId: graphId,
  };
};

export const setGraphChecked = (graphChecked) => {
  return {
    type: actionTypes.SET_GRAPH_CHECKED,
    graphChecked: graphChecked
  };
};

export const toggleWatchOverview = value => {
  return {
    type: actionTypes.TOGGLE_WATCH_OVERVIEW,
    payload: value
  }
}
export const setTabMenuIconView = () => {
  return {
    type: actionTypes.SET_TAB_MENU_ICON_VIEW,
  };
}
export const setIsTabDevice = isTabDevice => {
  return {
    type: actionTypes.SET_IS_TAB_DEVICE,
    tabDevice: isTabDevice
  };
}

export const toggleSetupModal = val => {
  return {
    type: actionTypes.TOGGLE_SETUP_MODAL,
    payload: val
  }
}

export const updatePremiumBannerHide = (
  showPremiumBanner,
  journalUserId,
  callBack
) => {
  let requestUrl = url.DASHBOARD_PREMIUM_BANNER_CLOSE;
  if (journalUserId) {
    requestUrl += "?journalUserId=" + journalUserId;
  }
  return dispatch => {
    axiosCommon.axiosPUT(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          callBack(true);
          dispatch(
            updatePremiumBannerHideSuccess(showPremiumBanner)
          );
        } else {
          if (res.errorDto === "SESSION_TIME_OUT") {
            console.log("session time out; updatePremiumBannerHide");
            dispatch(logout());
          } else if (res.errorDto !== "undefined") {
            dispatch(commonErrorHandler(res.errorDto.data.error));
            callBack(false, res.errorDto.data.error);
          }
        }
      },
      { isHidePremiumBanner: showPremiumBanner }
    );
  };
};

const updatePremiumBannerHideSuccess = showPremiumBanner => {
  return {
    type: actionTypes.UPDATE_PREMIUM_BANNER_HIDE_SUCCESS,
    showPremiumBanner: showPremiumBanner
  };
};

export const toggleVerificationEmailSent = val => {
  return {
    type: actionTypes.TOGGLE_VERIFICATION_MAIL_SENT,
    payload: val
  }
}

export const setShowMappedDataPopupNextTime = (isShow) => {
  return {
    type: actionTypes.SET_SHOW_MAPPED_DATA_POPUP_NEXT_TIME,
    showMappedDataPopup: isShow
  }
}

export const setJoinStudyData = (data) => {
  return {
    type: actionTypes.SET_JOIN_STUDY_DATA,
    data: data,
  }
}

export const setHealthResourcePopupData = (data) => {
  return {
    type: actionTypes.SET_HEALTH_RESOURCE_POPUP_DATA,
    data: data,
  }
}

export const refreshGraph = () => {
  return {
    type: actionTypes.REFRESH_GRAPH,
  }
}

export const setSeenTimeZoneWarning = (seenTimeZoneWarning) => {
  return {
    type: actionTypes.SET_SEEN_TIME_ZONE_WARNING,
    seenTimeZoneWarning
  }
}

export const setEventData = data => {
  return {
    type: actionTypes.SET_EVENT_DATA,
    data,
  }
}

export const setOpenedYearAndId = data => {
  return {
    type: actionTypes.SET_EVENT_YEAR_AND_ID,
    data,
  }
}

export const setActiveBubble = id => {
  return {
    type: actionTypes.SET_ACTIVE_BUBBLE,
    id,
  }
}

export const setCurrentEventId = id => {
  return {
    type: actionTypes.SET_CURRENT_EVENT_ID,
    id,
  }
}

export const setSetTabMenuEnable = value => {
  return {
    type: actionTypes.SET_TAB_MENU_DISABLE,
    value,
  }
}

export const setAlartModalVisible = status => {
  return {
    type: actionTypes.SET_ALERT_MODAL_VISIBLE,
    status,
  }
}

export const pullSchedulars = (journalUserId, callBack) => {
  let requestUrl = url.GET_PENDING_SCHEDULE_LIST;
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return (dispatch, state) => {
    axiosCommonNotif.axiosGET(
      requestUrl,
      res => {
        if (res.type === "SUCCESS") {
          let finishedSchedules = state().uistate?.finishedSchedules || [];
          const {routines = [], surveys = [], extra} = preProcessScheduleData(res.responseDto.data, finishedSchedules);
          dispatch(updateDashboardData({
            routines,
            surveys,
            extra
          }));
        }
        if (callBack) callBack();
      }
    );
  };
};

export const updateDashboardData = data => {
  return {
    type: actionTypes.SYNC_DASHBOARD_SCHEDULE_DATA,
    data,
  }
}

export const setFinshedSchedules = data => {
  return {
    type: actionTypes.SET_FINISH_SCHEDULES,
    data,
  }
}

export const updateDashboardItemShowHide = item => {
  return {
    type: actionTypes.UPDATE_DASHBOARD_ITEM_SHOW_HIDE,
    item,
  }
}
export const setDropdownUniqueId = id => {
  return {
    type: actionTypes.UPDATE_DROPDOWN_UNIQUE_ID,
    id,
  }
}

export const getOverdueAddhocEntry = (entryId, entryTypeId, dayNumber, journalUserId, callBack) => {
  let requestUrl = url.GET_OVERDUE_ADHOC_ENTRY.replace('{entryId}', entryId).replace('{entryTypeId}', entryTypeId).replace('{dayNumber}', dayNumber);
  if (journalUserId) {
    requestUrl += '?journalUserId=' + journalUserId;
  }
  return (dispatch) => {
    axiosCommon.axiosGET(
      requestUrl,
      res => {
        dispatch(resetNewJournal());
        if (res.type === "SUCCESS") {
          callBack(true, res.responseDto.data);
        } else {
          callBack(false);
        }
      }
    );
  };
};
