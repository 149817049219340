import React, { Component } from "react";
import { View, Image, Text } from "react-native";
import styles from "../../Css";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

class GlobalMessageHandling extends Component {
  state = {
    errorDescription: null,
    error: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { errorDescription, error } = nextProps;
    if (this.state.errorDescription !== errorDescription) {
      this.setState({ errorDescription, error }, () => {
        if (errorDescription != null) {
          setTimeout(() => {
            this.setState({ errorDescription: null, error: false });
            this.props.clearError();
          }, 3000);
        }
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        {Boolean(this.state.error) && Boolean(this.state.errorDescription) && (
          <View style={[styles.errorBar]}>
            <View style={styles.errorMessage}>
              <Image
                style={[styles.iconWarning, styles.imgSp]}
                resizeMode={"cover"}
                source={require("../../assets/icons/warning.png")}
              />
              <Text style={[styles.warningTextLg, styles.bold]} allowFontScaling={false}>
                {this.state.errorDescription}
              </Text>
            </View>
          </View>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorDescription: state.routines.errorDescription,
    error: state.routines.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearError: () => dispatch(actions.clearError())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalMessageHandling);
