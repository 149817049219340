import React from "react";
import { Image, View, Text, TextInput, ScrollView } from "react-native";
import styles from '../../../Css';
import TickButton from '../../../components/CheckBox/CheckBox';

const SelectedOptionPicker = (props) => {
    const { data, tickedList, toggleTickCallback, filterType, onSearchSubCategory } = props;

    return (
        <React.Fragment>
            <View style={[styles.pstAbTpThirtyRgtZero, styles.tpZero, styles.boxLtRtMrginSm, styles.ltOneThirty, {left:138}]}>
                <View style={[
                    styles.monthWdCustom, 
                    styles.fullHgHundredPerc, 
                    styles.minHgFifty,
                    styles.mxHgTwoFifty, 
                    styles.borderTpLtRadiusZero, 
                    styles.borderTpRtRadiusZero, 
                    styles.wdTwoHundred, 
                    styles.bgWhite, 
                    styles.wdOneSixty
                ]}>

                    {/* need a fix */}
                    {filterType === 'TAG' ?
                    <View style={[styles.flexRow, styles.flexJcFs, styles.flexAiCt, styles.infoPdTopBotExSm, styles.cardRowPdLtRt, styles.fourtyHg]}>
                        <View style={[styles.imgSp, styles.tpOne]}>
                            <Image
                                style={styles.searchIcnSmallGrey}
                                source={require('../../../assets/health-journey-icons/search-icon.png')}
                            />

                        </View>
                        <TextInput
                            style={[styles.textPrimeSm, styles.wdFullPer, styles.mgLtFive, styles.fourtyHg]}
                            placeholder="Search My Tags"
                            underlineColorAndroid="transparent"
                            onChangeText={(value) => onSearchSubCategory(value)}
                        />
                    </View> : null}
                    <View style={styles.brdLgWdThree}></View> 
                    {/* selection Content */}
                    <View>
                        {/* need a fix */}
                        {/* select all option */}
                        <View style={styles.colBgLight}>
                            <View style={[styles.flexRow, styles.flexJcFs, styles.flexAiCt, styles.menuSingleTextViewSm, styles.ctgryListRtLtTpBt, styles.pdBtTwo]}>
                                <View style={styles.tickButtonSm}>
                                    {tickedList.includes('Select All') ? (
                                        <TickButton
                                            imagePath={'tickButton'}
                                            toggleTickCallback={() =>
                                                toggleTickCallback(false)
                                            }
                                            tickStyle={{ marginRight: 0 }}
                                        />
                                    ) : (
                                        <TickButton
                                            imagePath={'untickButton'}
                                            toggleTickCallback={() =>
                                                props.toggleTickCallback(true)
                                            }
                                            tickStyle={{ marginRight: 0 }}
                                        />
                                    )}
                                </View>
                                <View style={styles.textPrime}>
                                    <Text> Select All </Text>
                                </View>
                            </View>
                            <View style={[styles.dateFilterLineWithMargin]}></View>
                        </View>
                        {/* Rest of the selectors */}
                        <View style={[styles.mxHgHudredSixtyThree, styles.overflowHid]}>
                            <ScrollView nestedScrollEnabled={true}>
                                {/* selector 01 */}
                                {data.map((item, key) => (
                                    <View>
                                        <View style={[styles.flexRow, styles.flexJcFs, styles.flexAiCt, styles.menuSingleTextViewSm, styles.ctgryListRtLtTpBt, styles.pdBtTwo]}>
                                            <View style={styles.tickButtonSm}>
                                                {tickedList.includes(item.category_name) ? (
                                                    <TickButton
                                                        imagePath={'tickButton'}
                                                        toggleTickCallback={() =>
                                                            toggleTickCallback(false, item.category_name)
                                                        }
                                                        tickStyle={{ marginRight: 0 }}
                                                    />
                                                ) : (
                                                    <TickButton
                                                        imagePath={'untickButton'}
                                                        toggleTickCallback={() =>
                                                            props.toggleTickCallback(true, item.category_name)
                                                        }
                                                        tickStyle={{ marginRight: 0 }}
                                                    />
                                                )}
                                            </View>
                                            <View>
                                                <Text style={styles.textPrime}> {item.category_name} </Text>
                                            </View>
                                        </View>
                                        <View style={[styles.dateFilterLineWithMargin]}></View>
                                    </View>
                                ))}
                            </ScrollView>
                        </View>

                    </View>
                </View>
            </View>

        </React.Fragment>
    );
};

export default SelectedOptionPicker;
