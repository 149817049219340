import React from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native";
import stylesResponsive from "../../../../../CssResponsive";
import styles from "../../../../../Css";
import UIText from "../../../../Text/text";
import UITextInput from "../../../../../components/TextInput/textInput";
import RadioForm from "../../../../../modules/radioButton/RadioButton";
import ActivityIndicator from "../../../../../components/ActivityIndicator/activityIndicator";
import WarningDuplication from '../../../../../components/Warning/warningDuplication';

const radioPropsYesNo = [
  { label: "No", value: 0 },
  { label: "Yes", value: 1 }
];

const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
const clinicalTrialPremiumModalContent = props => {
  return (
    <View style={stylesRes.modalScrollWrapperCom} dataSet={{media: ids.modalScrollWrapperCom}}>
      <ScrollView 
        style={styles.modalScroller}
        keyboardShouldPersistTaps={'always'}
      >
          <View style={[styles.popUpBlock, styles.bgWhite]}>
            <View style={styles.popUpContentArea}>
              <View style={styles.gridSectionPopup}>
                <View style={[styles.popUpContentPd]}>
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={styles.fieldRow}>
                        <View style={[styles.fieldCol, styles.topPdLg]}>
                          <UITextInput
                            style={[
                              styles.textField,
                              styles.textFieldSm,
                              !props.validTitle
                                ? styles.inValidColor
                                : props.duplicateTitle
                                  ? styles.duplicateWarningColor
                                  : styles.validColor
                            ]}
                            onChangeText={props.onTextValueChangeTitle}
                            value={props.title}
                            placeholder={"Enter title"}
                          />
                        {Boolean(props.duplicateTitle) &&
                            <WarningDuplication errorMessage={"Duplicate name found. Please consider a different name"}/>
                          }
                        </View>
                      </View>
                    </View>
                  </View>

                  <View>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.infoBlock, styles.infoPd]}>
                          <View style={stylesRes.infoBlockMax}>
                            <Text
                              style={[styles.textPrime, styles.textAlignCenter]}
                              allowFontScaling={false}
                            >
                              Notify me when a new clinical trial matches my
                              search. Searches are completed weekly
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.fieldRow, styles.fieldRowNoMg]}>
                          <View style={[styles.radioSecFul]}>
                            <View
                              style={[styles.radioRowCenter, styles.radioRowSp]}
                            >
                              <View style={[styles.radioColBtnView]}>
                                <RadioForm
                                  style={[
                                    styles.radioSetCom,
                                    styles.radioSetCenterMax
                                  ]}
                                  radio_props={radioPropsYesNo}
                                  formHorizontal={true}
                                  buttonColor={"rgba(152, 152, 154, 0.6)"}
                                  selectedButtonColor={"#FF8300"}
                                  onPress={props.onPressRadioFormNotify}
                                  initial={props.isNotify}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={styles.hrLineLg} />

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.infoBlock, styles.infoPd]}>
                          <View style={stylesRes.infoBlockMax}>
                            <Text
                              style={[
                                styles.textPrimeSm,
                                styles.textAlignCenter
                              ]}
                            >
                              <Text style={[styles.bold]} allowFontScaling={false}>Remember</Text> none
                              of your personal data is sent out.
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.btnArea}>
                          <View style={stylesRes.btnViewSecSm} dataSet={{media: ids.btnViewSecSm}}>
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={[styles.submitSm, styles.disableBtn]}
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={props.close}
                                  disabled={props.saveWait}
                                >
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent
                                      ]}
                                    >
                                      <UIText
                                        style={styles.submitText}
                                        title="Cancel"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                            <View style={styles.fieldBtnSp} />
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={[
                                    styles.submitSm,
                                    !props.title
                                      ? styles.disableBtn
                                      : styles.submitBgBlue
                                  ]}
                                  disabled={!props.title || props.saveWait}
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={props.handleOnSaveSearchOk}
                                >
                                  <View style={styles.btnSingleView}>
                                    {props.saveWait && (
                                      <View style={styles.actIndCenterView}>
                                        <ActivityIndicator size="small" />
                                      </View>
                                    )}
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent
                                      ]}
                                    >
                                      <UIText
                                        style={[
                                          styles.submitText,
                                          styles.submitTextSm
                                        ]}
                                        title="Ok"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
      </ScrollView>
    </View>
  );
};

export default (clinicalTrialPremiumModalContent);
