import React, {Component} from 'react';
import Measurements from '../../components/Profile/measurements';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import {PROFILE} from '../../navigation/path';
import { pendoTrackEvent } from '../../util/pendoConfig';

class MeasurementsScreen extends Component {
  constructor(props) {
    super(props);
    const journalWeightHeight = this.getLetastJournal();
    const weight = this.getWeight(journalWeightHeight);
    const height = this.getHight(journalWeightHeight);
    this.state = {
      toggleSwitch: (props.user || {}).units,
      switchStatus: this.getMesurement((props.user || {}).units),
      activeWait: false,
      height: height.value,
      weight: weight.value,
      weightUomSymbol: weight.unit,
      heightUomSymbol: height.unit,
    };
  }

  componentDidMount= () => {
    pendoTrackEvent('Profile/@Measurements');
  }

  getHight = journal => {
    const hightMetrix = this.props.metricsDigest.systemDefinedMetrics.filter(
      function(res) {
        return res.name === 'Height';
      },
    );
    if (journal && journal.metrics && hightMetrix) {
      const metrics = journal.metrics.filter(function(res) {
        return res.metric === hightMetrix[0].id;
      });

      if (metrics && metrics[0]) {
        return {
          value: metrics[0].value ? metrics[0].value : null,
          unit: this.props.unitsOfMeasureDigestProcessed.unitsOfMeasure[
            metrics[0].unitOfMeasure
          ].symbol,
        };
      }
    }

    return {value: null, unit: null};
  };

  getWeight = journal => {
    const weightMetrix = this.props.metricsDigest.systemDefinedMetrics.filter(
      function(res) {
        return res.name === 'Weight';
      },
    );
    if (journal && journal.metrics && weightMetrix) {
      const metrics = journal.metrics.filter(function(res) {
        return res.metric === weightMetrix[0].id;
      });
      if (metrics && metrics[0]) {
        return {
          value: metrics[0].value,
          unit: this.props.unitsOfMeasureDigestProcessed.unitsOfMeasure[
            metrics[0].unitOfMeasure
          ].symbol,
        };
      }
    }

    return {value: null, unit: null};
  };

  getLetastJournal = () => {
    const journal = Object.values(this.props.journalEntriesProcessed).filter(function(res) {
      return res.title === 'User profile update';
    });

    journal.sort(function(a, b) {
      return b.createdOn - a.createdOn;
    });
    if (journal) {
      return journal?.[0];
    }

    return null;
  };

  getMesurement = units => {
    if (units === 'US') {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <Measurements
        toggleSwitch={value => {
          let toggleSwitch = null;
          if (value === true) {
            toggleSwitch = 'US';
          } else {
            toggleSwitch = 'SI';
          }
          this.setState({toggleSwitch: toggleSwitch, switchStatus: value});
          if (this.props.user) {
            let profile = {
              id: this.props.user.id,
              firstName: this.props.user.firstName,
              lastName: this.props.user.lastName,
              email: this.props.user.email,
              ethnicity: this.props.user.ethnicity,
              city: this.props.user.city,
              state: this.props.user.state,
              country: this.props.user.country,
              birthday: this.props.user.birthday,
              sex: this.props.user.sex,
              weight: this.state.weight,
              weightUomSymbol: this.state.weightUomSymbol,
              height: this.state.height,
              heightUomSymbol: this.state.heightUomSymbol,
              lastEulaAgreement: this.props.user.lastEulaAgreement,
              units: toggleSwitch,
              timezone: this.props.user.timezone,
            };

            this.props.updateProfile(profile, () => {
            });
          }
        }}
        active={this.state.switchStatus}
        handleOnPressBackButton={() => {
          this.props.navigation.reset({
            index: 0,
            routes: [{ name: PROFILE.path }],
          });
        }}
        onShowTutorial={this.props.onShowTutorial}
        index={PROFILE.index}
        navigation={this.props.navigation}
        route={this.props.route}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.routines.user,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    metricsDigest: state.routines.metricsDigest,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateProfile: (editProfile, callBack) =>
      dispatch(actions.updateProfile(editProfile, callBack)),
    onShowTutorial: tutorialType =>
      dispatch(actions.showTutorial(tutorialType)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeasurementsScreen);
