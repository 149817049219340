import React from 'react';
import {Text, View, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import Modal from '../Modal/modal';
import ModalLayout from '../ModalLayout/modalLayout';

const biometricEnableModal = props => {
  return (
    <Modal visible={props.showSetting} onPressSkip={props.onPressSkip} backdropStyle={true}>
      <ModalLayout
        contPosition={'center'}
        showClose={false}
        pdCom={true}
        boxStyle={true}
        // hidePopup={props.hidePopup}
      >
        <View style={styles.popupContentWrapper}>
          <View style={[styles.popMessageContView]}>
            <View style={[styles.popMessageTitleView]}>
              <Text style={[styles.textTitleBold, styles.textThickGrey]} allowFontScaling={false}>
                Enable Biometrics on device
              </Text>
            </View>
            <View style={[styles.popMessageDecViewFull]}>
              <Text style={[styles.textPrimeLg, styles.textThickGrey]} allowFontScaling={false}>
                You need to enable Biometrics on your device to setup Biometrics
                Login
              </Text>
            </View>
          </View>

          <View style={[styles.popBtnView, styles.comMgTopSm]}>
            <View style={[styles.btnArea]}>
              <View style={[styles.fieldBtn, styles.flexAsFe]}>
                <TouchableOpacity onPress={props.onPressGoToSetting}>
                  <Text style={[styles.textPrimeMdBold, styles.textLightBlue]} allowFontScaling={false}>
                    GO TO DEVICE PREFERENCES
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={styles.comSp} />
              <View style={[styles.fieldBtn, styles.flexAsFe]}>
                <TouchableOpacity onPress={props.onPressSkip}>
                  <Text style={[styles.textPrimeMdBold, styles.textLightBlue]} allowFontScaling={false}>
                    SKIP SETUP
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </ModalLayout>
    </Modal>
  );
};

export default (biometricEnableModal);
