import React from 'react';
import {Text, View, TouchableOpacity, ScrollView, Image} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Modal from '../Modal/modal';
import UIText from '../Text/text';
import ActivityIndicator from '../ActivityIndicator/activityIndicator';

const commonConfirmBox = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <Modal
      visible={props.visibleModal}
      onRequestClose={props.onRequestClose}
      onBackdropPress={props.onBackdropPress}>
      <View style={stylesRes.modalScrollWrapperCom} dataSet={{media: ids.modalScrollWrapperCom}}>
        <ScrollView style={styles.modalScroller}>
          <View style={[styles.popUpBlock, styles.bgWhite]}>
            <View style={styles.popUpContentArea}>
              <View style={styles.gridSectionPopup}>
                <View style={[styles.popUpContentPd]}>
                  <View style={stylesRes.modalContIn}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View
                          style={[
                            styles.infoTitleBlock,
                            styles.infoTitlePdCom,
                          ]}>
                          <View
                            style={[
                              styles.exclamationWrapper,
                              styles.pdBotLg,
                              styles.flexJcCtAiCt,
                            ]}>
                            <Image
                              style={[styles.exclamationIcon]}
                              source={require('../../assets/icons/exclamination.png')}
                            />
                          </View>
                          <Text
                            style={[
                              props.titleLarge
                                ? styles.textTitleBold
                                : styles.textPrimeBold,
                              props.alignLeft
                                ? styles.textAlignLeft
                                : styles.textAlignCenter,
                            ]}
                            allowFontScaling={false}
                            >
                            {props.alertTitle}
                          </Text>
                        </View>
                        {Boolean(props.alertBodyList) ? (
                          <View
                            style={[
                              styles.infoBlock,
                              styles.titleTopPd,
                              styles.infoPdBotMd,
                            ]}>
                            <Text
                              style={[
                                styles.textAlignLeft,
                                styles.textPrimeBold,
                              ]}
                              allowFontScaling={false}
                              >
                              {props.alertBodyTitle}
                            </Text>
                            {/* {props.alertBodyList} */}
                            <Text
                              style={[
                                props.alignLeft
                                  ? styles.textAlignLeft
                                  : styles.textAlignCenter,
                                props.alertBodyBoldText
                                  ? styles.textPrimeBold
                                  : styles.textPrime,
                              ]}
                              allowFontScaling={false}
                              >
                              {props.alertBodyList}
                            </Text>
                          </View>
                        ) : (
                          <View style={[styles.infoBlock, styles.infoPdBotMd]}>
                            <Text
                              style={[
                                props.alignLeft
                                  ? styles.textAlignLeft
                                  : styles.textAlignCenter,
                                props.alertBodyBoldText
                                  ? styles.textPrimeBold
                                  : styles.textPrime,
                              ]}
                              allowFontScaling={false}
                              >
                              {props.alertBody}
                            </Text>
                          </View>
                        )}
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.btnArea}>
                          <View style={stylesRes.btnViewSecSm} dataSet={{media: ids.btnViewSecSm}}>
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={[
                                    styles.submitSm,
                                    styles.btnShadowCom,
                                    styles.btnBorderNone,
                                    styles.submitBgAsh,
                                  ]}
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={props.handleCancel}>
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent,
                                      ]}>
                                      <UIText
                                        style={[
                                          styles.textPrimeBold,
                                          styles.textWhite,
                                        ]}
                                        title="Cancel"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                            <View style={styles.fieldBtnSp} />
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={[
                                    styles.submitSm,
                                    styles.btnShadowCom,
                                    styles.btnBorderNone,
                                    props.btnOrange
                                      ? styles.submitBgOrange
                                      : styles.submitBgBlue,
                                  ]}
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={props.handleConfirm}>
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent,
                                      ]}>
                                      {props.confirmWait && (
                                        <View style={styles.actIndSm}>
                                          <ActivityIndicator size="small" />
                                        </View>
                                      )}
                                      <UIText
                                        style={[
                                          styles.textPrimeBold,
                                          styles.textWhite,
                                        ]}
                                        title="Okay"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};
export default (commonConfirmBox);
