import React, {Component} from 'react';
import {Platform} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import {DASHBOARD, PEER_TO_PEER, REFER_FRIEND} from '../../../../navigation/path';
import SearchResultsComponent from '../../../../components/PeerToPeer/MainSection/MyNetwork/SearchResultsComponent';
import _ from 'underscore';
import {handleReferFriend} from '../../../../util/shareOption';
import { pendoTrackEvent } from '../../../../util/pendoConfig';
import { WEB } from '../../../../util/platformWindowConfig';

class SearchResultsScreen extends Component {
  constructor(props) {
    super(props);

    const {windowHeight, windowWidth, peersResults} = props;
    const {searchedList, criteria} = peersResults;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      searchedKeyword: null,
      matchedResultCount: searchedList && searchedList.length,
      searchFilterResults: searchedList && searchedList.length > 0 ? searchedList : [],
      criteriaText: criteria,
      visibleConnectionRequestPopup: false,
      visibleMakeprofilePublicPopup: false,
      visibleSuccessMessage: false,
      selectedUser: null
    };
    this.props.hideMobileMenu();
  }

  componentDidMount() {
    if (this.props.journalUserId) {
      this.props.navigation.push(DASHBOARD.path);
    }
    if (_.isEmpty(this.props.peersResults)) {
      this.props.navigation.goBack();
    }
    this.filterSerachResults(this.props.searchedKeyword);
    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
      pendoTrackEvent('Chats/@SearchConnections');
    });
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    });
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth} = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  componentWillUnmount = () => {
    this.props.showMobileMenu();
    if(this.state.searchedKeyword) {
      this.props.onUpdateSearchedKeyword("");
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.resultsUpdateTimeStamp !== this.props.resultsUpdateTimeStamp) {
      this.filterSerachResults(this.props.searchedKeyword)
    }
  }

  handleOnPressBackButton = () => {
    this.props.onSetSearchTearms(null);
    if (this.state.searchedKeyword) {
      this.filterSerachResults(null);
    } else {
      //this.props.navigation.goBack();
      this.handleOnPressEditSearch();
    }
  };

  handleOnPressEditSearch = () => {
    //this.props.navigation.goBack();
    this.props.navigation.reset({
      index: 0,
      routes: [{ 
        name: PEER_TO_PEER.findPeersPath,
      }],
    });
  }

  handleOnPressCancelSearch = () => {
    if(this.state.searchedKeyword) {
      this.props.onUpdateSearchedKeyword("");
    }
    this.props.onSetSearchTearms(null);
    // this.props.navigation.reset({
    //   index: 0,
    //   routes: [{ 
    //     name: PEER_TO_PEER.path,
    //     params: {
    //       from: this.props.route.name,
    //       communityTab: peerToPeerTabPath.myNetwork
    //     }
    //   }],
    // });
    this.handleOnPressEditSearch();
  }

  filterSerachResults = (keyword) => {
    const { peersResults, onUpdateSearchedKeyword } = this.props;
    const { searchedList } = peersResults;
    if (keyword) {
      let filterArray = [];
      let tempFilter = [];

      if (searchedList && searchedList.length > 0) {
        searchedList.filter((res) => {
          let age = "";
          if (res.birthday) {
            let ageDifMs = Date.now() - res.birthday;
            let ageDate = new Date(ageDifMs);
            age = Math.abs(ageDate.getUTCFullYear() - 1970).toString();
          }
          let conditionList = [];
          if (res.medicalConditionsList && res.medicalConditionsList.length > 0) {
            conditionList = res.medicalConditionsList.filter(condition => {
              return condition.toLowerCase().includes(keyword.toLowerCase())
            });
          }
             
          if (
            (res.userName
              ? res.userName.toLowerCase().includes(keyword.toLowerCase())
              : null) ||
            (res.sex
              ? res.sex.toLowerCase().includes(keyword.toLowerCase())
              : null) ||
            (res.birthday
              ? age.includes(keyword)
              : null) ||
            (res.story
              ? res.story.toLowerCase().includes(keyword.toLowerCase())
              : null) ||
            (conditionList && conditionList.length > 0 ? true : null)
          ) {
            return filterArray.push({
              ...res, 
              isExpand: ((conditionList && conditionList.length > 0) || 
                (res.story && res.story.toLowerCase().includes(keyword.toLowerCase()))
              ) ? true : false
            });
          }
        });
      }
      if (filterArray && filterArray.length) {
        tempFilter = [...new Set(filterArray)];
      }

      this.setState(
        {
          searchFilterResults: tempFilter,
          matchedResultCount: tempFilter.length,
          searchedKeyword: keyword,
        },
        () => onUpdateSearchedKeyword(keyword)
      );
    } else {
      this.setState(
        {
          searchFilterResults: searchedList
            ? searchedList
            : [],
            matchedResultCount: searchedList
            ? searchedList.length
            : 0,
            searchedKeyword: keyword,
        },
        () => onUpdateSearchedKeyword("")
      );
    }
  }

  handleOnPressConnect = (user) => {
    this.setState({
      visibleConnectionRequestPopup: true,
      selectedUser: user
    });
  }
  
  hidePopup = (popupMessageName, showNextPopup) => {
    switch (popupMessageName) {
      case 'CONNECTION_REQUEST':
        this.setState({
          visibleConnectionRequestPopup: false,
          visibleMakeprofilePublicPopup: showNextPopup && this.props.profile.profileVisibility !== 'PUBLIC' ? true : false,
          visibleSuccessMessage: showNextPopup && this.props.profile.profileVisibility === 'PUBLIC' ? true : false
        });
        break;
      case 'MAKE_PROFILE_PUBLIC':
        this.setState({
          visibleMakeprofilePublicPopup: false,
          visibleSuccessMessage: showNextPopup ? true : false
        });
        break;
      default:
        this.setState({
          visibleSuccessMessage: false
        });
        break;
    } 
  }
  
  handleUpdateConnectionStatus = (peerId, connectionId, connectionStatus) => {
    const {peersResults, onUpdatePeerResultsSearchedList} = this.props;
    const {searchedList} = peersResults;
    const {searchFilterResults} = this.state;

    let searchFilterResultsNew = searchFilterResults;
    let searchFilterResultsAll = searchedList;

    let filteredPeer = searchFilterResults.filter(peer => {
      return peer.id === peerId
    });
    if (filteredPeer && filteredPeer.length > 0) {
      filteredPeer = filteredPeer[0];
      let temp = {
        ...filteredPeer,
        connectionId: connectionId,
        connectionStatus: connectionStatus
      }
      searchFilterResultsNew = searchFilterResultsNew.map(result => result.id !== filteredPeer.id ? result : temp);
      searchFilterResultsAll = searchFilterResultsAll.map(result => result.id !== filteredPeer.id ? result : temp);
      this.setState({
        searchFilterResults: searchFilterResultsNew
      }, () => {
        onUpdatePeerResultsSearchedList(searchFilterResultsAll);
      });
    }
  }

  handleOnPressReferFriend = () => {
    if (Platform.OS !== WEB) {
      handleReferFriend();
    } else {
      this.props.navigation.push(
        REFER_FRIEND.path, {
          from: this.props.route.name,
        }
      );
    }
  }
  
  render() {
    const {
      journalUserId, 
      windowHeight, 
      navigation, 
      hasNotch, 
      searchTearms,
      windowWidth,
      route,
      hideMobileMenu,
      timeZone,
      timeZoneWarning
    } = this.props;
    const {
      matchedResultCount, 
      criteriaText, 
      searchFilterResults, 
      searchedKeyword,
      visibleConnectionRequestPopup,
      visibleMakeprofilePublicPopup,
      visibleSuccessMessage,
      selectedUser
    } = this.state;
    hideMobileMenu();
    return (
      <SearchResultsComponent
        navigation={navigation}
        route={route}
        index={PEER_TO_PEER.index}
        handleOnPressBackButton={this.handleOnPressBackButton}
        searchedList={searchFilterResults}
        totalMatchedResults={matchedResultCount}
        criteriaText={criteriaText}
        onPressEditSearch={this.handleOnPressEditSearch}
        onPressCancelSearch={this.handleOnPressCancelSearch}
        onPressReferFriend={this.handleOnPressReferFriend}
        onSearchInputValueChange={this.filterSerachResults}
        searchedKeyword={searchedKeyword}
        windowHeight={windowHeight}
        windowWidth={windowWidth}
        journalUserId={journalUserId}
        hasNotch={hasNotch}
        selectedConditionList={searchTearms && searchTearms.selectedConditionList}
        visibleConnectionRequestPopup={visibleConnectionRequestPopup}
        visibleMakeprofilePublicPopup={visibleMakeprofilePublicPopup}
        visibleSuccessMessage={visibleSuccessMessage}
        hidePopup={this.hidePopup}
        onPressConnect={this.handleOnPressConnect}
        selectedUser={selectedUser}
        onUpdateConnectionStatus={this.handleUpdateConnectionStatus}
        timeZone={timeZone}
        timeZoneWarning={timeZoneWarning}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    journalUserId: state.uistate.journalUserId,
    peersResults: state.peerToPeer.peersResults,
    searchedKeyword: state.peerToPeer.searchedKeyword,
    hasNotch: state.uistate.hasNotch,
    searchTearms: state.peerToPeer.searchTearms,
    resultsUpdateTimeStamp: state.peerToPeer.resultsUpdateTimeStamp,
    profile: state.routines.peer2peer,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    onUpdateSearchedKeyword: (keyword) => 
      dispatch(actions.updateSearchedKeyword(keyword)),
    onSetSearchTearms: (searchTearms) => 
      dispatch(actions.setSearchTearms(searchTearms)),
    onUpdatePeerResultsSearchedList: (searchedList) =>
      dispatch(actions.updatePeerResultsSearchedList(searchedList)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchResultsScreen);
