import React from 'react';
import {View, Text, Image} from 'react-native';
import styles from '../../Css';

const messageBox = props => {
  return (
    <View style={[styles.messageBoxWrapper, styles.containerPinkMd]}>
      <View style={[styles.flexRow]}>
        <View style={styles.iconMessage}>
          <Image
            style={[styles.iconWarningSm, styles.imgSp]}
            resizeMode={'cover'}
            source={require('../../assets/icons/warning-light.png')}
          />
        </View>
        <View style={styles.flexCom}>
          {Boolean(props.messageTitle) && (
            <View style={styles.messageTitleSm}>
              <Text style={[styles.textPrimeSm, styles.messageText, styles.rgLineHeight]}  allowFontScaling={false}>
                {props.messageTitle}
              </Text>
            </View>
          )}
          <Text style={[styles.textPrimeSm, styles.messageText, styles.rgLineHeight]}  allowFontScaling={false}>
            {props.message}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default messageBox;
