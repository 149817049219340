import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  user: {
    firstName: null,
    lastName: null,
    ethnicity: null,
    city: null,
    state: null,
    country: null,
    birthday: null,
    weightUomSymbol: null,
    heightUomSymbol: null,
    sex: null
  },
  conditions: [],
  userUpdated: null,
  conditionValidation: {}
};

const updateProfileSuccess = (state, action) => {
  return updateObject(state, {
    userUpdated: action.userUpdated
  });
};

const updateProfileFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true
  });
};

export const initConditions = (state, action) => {
  return updateObject(state, {
    conditions: action.conditions,
    conditionValidation: action.conditionValidation
  });
};

export const updateCondition = (state, action) => {
  const conditions = [...state.conditions];
  conditions[action.index] = action.condition;
  return updateObject(state, {
    conditions: conditions
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return updateProfileSuccess(state, action);

    case actionTypes.UPDATE_PROFILE_FAIL:
      return updateProfileFail(state, action);
    case actionTypes.PROFILE_INIT_CONDITIONS:
      return initConditions(state, action);
    case actionTypes.PROFILE_UPDATE_CONDITION:
      return updateCondition(state, action);
    default:
      return state;
  }
};
export default reducer;
