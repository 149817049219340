import React, { Component } from "react";
import { View } from "react-native";

export default class AddEditRollingGraphsCommon extends Component {

  updateGrph = (chart) => {
    this.chart = chart;
  };

  getGraphImage = (callBack) => {
    this.chart.exporting.getImage("png").then((imgData) => {
        callBack(imgData.replace(/^data:image\/[a-z]+;base64,/, ""));
    });
  }

  render() {
    return (<View />);
  }
}

