import React, { Component } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import { connect } from "react-redux";
import styles from "../../../Css";
import * as FieldDetails from "../../../components/TextInput/fieldDetails";
//import TextPickerFrame from "../../../components/TextInput/textPickerFrame";
import ItemDeleteButton from "../../../components/Button/itemDeleteButton";
import AutoCompleteSuggestion from "../../AutoComplete/AutoCompleteSuggestion";
import {autoSuggestionListType} from '../../../Constant/index';
import {zIndexWorkaround, getMeasurementUnits} from '../../../util/commonUiLogic';
import * as actions from '../../../store/actions/index';
import EditHealthDataPopup from "../../../components/Modal/editHealthDataPopup";
class Item extends Component {
  constructor(props) {
    super(props);
    const { metric, units, updateMetricValues, index, userDefinedMetricsProcessed } = props;
    const { data } = metric;
    let { unit } = metric;
    unit = unit ? unit : units[0]?.id;
    let unitName = unit ? units.filter(item => item.id === unit)?.[0]?.symbol : units[0]?.symbol;

    let name = data && data.name
      ? data.name
      : userDefinedMetricsProcessed && userDefinedMetricsProcessed[metric?.metric]
      ? userDefinedMetricsProcessed[metric?.metric].name
      : "";

    this.state = {
      ...metric,
      unit,
      unitName,
      selectedUnitName: unitName,
      error: false,
      editHealthModal: false,
      name: name,
    };
    this.uomRef = null;
    updateMetricValues(index, parseInt(unit));

  }

  UNSAFE_componentWillReceiveProps(props) {
    const { metric, units, userDefinedMetricsProcessed } = props;
    let { unit, data } = metric;
    let name = data && data.name
      ? data.name
      : userDefinedMetricsProcessed && userDefinedMetricsProcessed[metric?.metric]
      ? userDefinedMetricsProcessed[metric?.metric].name
      : "";
    let unitName = unit ? units.filter(item => item.id === unit)?.[0]?.symbol : this.state.unitName;
    this.setState({ ...metric, unitName, name });
  }

  // componentDidUpdate(props, state) {
  //   const {
  //     onTouchRoutineFlag,
  //     index,
  //     onTouchRoutine,
  //     metricsCount,
  //   } = props;
  //   const {unitName, selectedUnitName, unit} = state;
  //   if (metricsCount && metricsCount === 0) {
  //     onTouchRoutine(false);
  //   } else if(metricsCount && metricsCount > 0) {
  //     if (index === metricsCount - 1) {
  //       onTouchRoutine(false);
  //     }
  //   }
  //   if (onTouchRoutineFlag && !unitName && selectedUnitName) {
  //     this.setState({
  //       unitName: selectedUnitName,
  //       error: false,
  //       unit: unit,
  //     });
  //   }
  // }
  // TODO: ZI-883 remove old codes
  // getMeasurementUnits = () => {
  //   let units = [];
  //   this.props.units &&
  //     this.props.units.map((res) =>
  //       units.push({
  //         label: res.symbol,
  //         value: res.id,
  //         key: res.id + " " + this.props.index,
  //       })
  //     );
  //   return units;
  // };

  // TODO: ZI-883 remove old codes
  // onUnitChange = (val) => {
  //   const { updateMetricValues, index } = this.props;
  //   this.setState({ unit: val });
  //   updateMetricValues(index, parseInt(val));
  // };

  onUnitChange = (val) => {
    const { updateMetricValues, index } = this.props;
    this.setState({ 
      unit: val.data.id, 
      unitName: val.data.symbol,
      selectedUnitName: val.data.symbol
    }, () => {
      updateMetricValues(index, parseInt(val.data.id));
      this.props.deleteMetricErrors(index);
    });
  };

  onChangeTextName = currentInput => {
    this.setState(prevState => ({
      unitName: currentInput,
      //unit: !Boolean(currentInput) ? null : prevState.unit,
    }), () => {
      // if (!Boolean(currentInput)) {
      //   const { updateMetricValues, index } = this.props;
      //   updateMetricValues(index, null);
      // }
    });
  }

  editHealthData = () => {
    this.setState({
      editHealthModal: true,
    });
  };

  handleEditHealthDataResource = (currentInput) => {
    const { index,  updateHelthDataName} = this.props;
        this.setState({ 
          name: currentInput,
          editHealthModal: false,
    }, () => {
      updateHelthDataName(index, currentInput);
      this.props.deleteMetricErrors(index);
    });
 }

 updateTermList = () => {
  this.setState({unit: null, selectedUnitName: null})
}
  render() {
    const { unit } = this.state;
    const { deleteItem, index, metricsCount } = this.props;

    let isCustomHealthData = this.props.metric?.type === 'CUSTOM';


    return (
      <React.Fragment>
        {/* <View style={[styles.fieldRow, styles.zIndexMin, zIndexWorkaround(index, metricsCount)]}> */}

        <View style={[styles.fieldRow,zIndexWorkaround(index, metricsCount)]}>
          <View style={[styles.horzFieldTitle, styles.comSpExSm]}>
            <View style={styles.listEmailScrollWrap}>
              <TouchableOpacity onPress={() => isCustomHealthData && this.editHealthData()}>
                <View style={[styles.listEmailSingle, styles.bgSep, styles.flexJcCtAiCt]}>
                  <View style={[styles.listEmailName, !isCustomHealthData && styles.noPdRt]}>
                    <Text style={styles.textPrimeBold} allowFontScaling={false}>
                      {this.state.name}
                    </Text>
                  </View>
                  {Boolean(isCustomHealthData) &&
                    <View
                      style={[
                        styles.smEditIconView,
                        styles.iconLtSp,
                        styles.flexJcCtAiCt,
                      ]}
                    >
                      <Image
                        source={require("../../../assets/peer-to-peer/edit-pen.png")}
                        style={styles.colorSelectIcon}
                        resizeMode={"contain"}
                      />
                    </View>
                  }
                </View>
              </TouchableOpacity>
            </View>
          </View>
          <View style={[styles.fieldRowWrapper, zIndexWorkaround(index, metricsCount)]}>
            <View style={[styles.fieldColStretch, zIndexWorkaround(index, metricsCount)]}>   
              {/* TODO: ZI-883 remove old codes
              <TextPickerFrame
                title={"Units"}
                fieldBg={FieldDetails.BGWHITE}
                fieldLt={true}
                items={this.getMeasurementUnits()}
                onValueChange={this.onUnitChange}
                selectedValue={unit}
              /> */}
              <AutoCompleteSuggestion
                textFieldWithTitle={true}
                textFieldTitle="Unit of Measure"
                fieldBg={FieldDetails.BGWHITE}
                remortSearch={false}
                //triggerLength={1}
                onChangeText={this.onChangeTextName}
                onItemPress={this.onUnitChange}
                currentInput={this.state.unitName}
                autoFocus={false}
                userTerms={getMeasurementUnits(this.props.units, unit)}
                zoomIcon={true}
                removeIcon={true}
                autoDisplayUserList={true}
                showCustomSection={false}
                showOtherResults
                type={autoSuggestionListType.health_data_uom}
                //showCloseAfterPressItem={Boolean(this.state.unitName)}
                removeSelectedIcon
                validateName={this.state.error}
                placeholder="Search..."
                typeAhead={this.props.measurementTypes}
                getRef={ref => (this.uomRef = ref)}
                selectedValue={unit}
                onBlur={() => {
                  this.setState(prevState => ({
                    unitName: prevState.selectedUnitName,
                    error: false,
                    unit: prevState.unit,
                  }));
                }}
                zIndex={99999 + metricsCount - index}
                updateTermList = {this.updateTermList}
              />
            </View>
            <View style={styles.fieldColSp}></View>
            <View style={styles.fieldColDelete}>
              <ItemDeleteButton 
                touchableOnPress={() => {
                  this.setState({unitName: ""}, () => {
                    if (this.uomRef) {
                      this.uomRef.turnOffList();
                    }
                    deleteItem(index);
                  })
                }} />
            </View>
          </View>
        </View>
        {/* Old Design */}
        {/* <View style={[styles.fieldRow, styles.zIndexMin]}>
          <View style={[styles.horzFieldFull]}>
            <View style={[styles.horzFieldTitle, styles.horzLgTitle]}>
              <Text style={[styles.textPrimeMdBold]}>{name}</Text>
            </View>

            <View style={[styles.horzFieldValue]}>
              <View style={[styles.fieldInRow]}>
                <View style={[styles.pickerSelectionWrapper]}>
                  <Text style={[styles.textView, styles.textViewPicker]}>
                    <UIText
                      style={[
                        styles.textLabel,
                        styles.bold,
                        styles.textColorLight,
                      ]}
                      title="Units"
                    />
                  </Text>
                  <View
                    css={[stylesRes.dropArrowView, stylesRes.dropArrowCent]}
                    pointerEvents={"none"}
                  >
                    <View css={[stylesRes.dropArrowIcon]}>
                      <Image
                        style={[styles.imgContain]}
                        source={require("../../../assets/icons/dropdown-arrow.png")}
                      />
                    </View>
                  </View>
                  <Picker
                    items={this.getMeasurementUnits()}
                    style={[styles.pickerAreaField, styles.selectionPicker]}
                    onValueChange={this.onUnitChange}
                    selectedValue={unit}
                  />
                </View>
              </View>
            </View>

            <View style={[styles.horzFieldValueRemove]}>
              <View style={[styles.deleteFullView, styles.flexJcCtAiCt]}>
                <TouchableOpacity
                  style={[styles.delTouchBlock]}
                  onPress={() => deleteItem(index)}
                >
                  <Image
                    style={[styles.delLgIcon]}
                    source={require("../../../assets/icons/remove.png")}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View> */}
        <EditHealthDataPopup
          visibleModal={this.state.editHealthModal}
          onBackdropPress={() => {
            this.setState({ editHealthModal: false });
          }}
          onRequestClose={() => {
            this.setState({ editHealthModal: false });
          }}
          handleEditHealthData={(val) => this.handleEditHealthDataResource(val)}
          healthDatainputValue= {this.state.name}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userDefinedMetricsProcessed: state.routines.metricsDigestProcessed
      ? state.routines.metricsDigestProcessed.userDefinedMetrics
      : [],
    measurementTypes: state.routines.unitsOfMeasureDigest.measurementTypes,
    onTouchRoutineFlag: state.routines.onTouchRoutineFlag,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTouchRoutine: flag =>
      dispatch(actions.onTouchRoutine(flag)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
