import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import {View, Keyboard} from 'react-native';
import styles from '../../../Css';
import * as axiosCommon from '../../../axios/axios-common';
import * as FieldDetails from '../../TextInput/fieldDetails';
import AutoCompleteSuggestion from '../../AutoComplete/AutoCompleteSuggestion';
import { autoSuggestionListType } from '../../../Constant/index';
import { getMedicationUnits } from '../../../util/commonUiLogic';

class StepTwoAddTreatment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.activeTherapieTab === 0 && props.therapieName ? props.therapieName : '',
      treatmentArray: this.getTreatmentsArray(
        (props.treatments || {}).treatments,
      ),
      visibleCalModal: null,
      startDateClick: false,
      currentIndex: 0,
      endDateClick: false,
      index: 0,
      isItemSelected: false,
      din_npn: null,
      treatmentType: null,
      unit: null,
      selectedValues: StepTwoAddTreatment.getSelectedTreatments(props.treatments, props),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {treatments} = props;

    return {
      selectedValues: StepTwoAddTreatment.getSelectedTreatments(treatments, props),
    };
  }

  static getSelectedTreatments = (treatments, props) => {
    const selectedValues = {};
    const {treatmentsProcessed, userTreatments} = props;

    if (treatments && treatments.length > 0) {
      treatments.forEach(element => {
        if (element.treatmentType !== 5) {
          selectedValues[element.name] = element.name;
        }
      });
    }
    if (userTreatments && userTreatments.length > 0) {
      userTreatments.forEach(item => {
        if (item.treatmentType !== 5) {
          const obj = treatmentsProcessed[item.id];
          if (obj) {
            selectedValues[obj.name] = obj.name;
          }
        }
      });
    }
    return selectedValues;
  };

  getTreatmentsArray = treatments => {
    const treatmentsArray = [];
    if (treatments) {
      treatments.map(res => {
        treatmentsArray.push({
          ...res,
          name: res.name,
          din_npn: res.din_npn,
          prescriber: res.prescriber,
          treatmentType: res.treatmentType,

          dosage: res.dosage,
        });
        return null;
      });
    }
    return treatmentsArray;
  };

  onChangeTextName = currentInput => {
    this.props.onUpdateTherapieName(currentInput, 0);
    this.setState({
      name: currentInput,
    });
  };

  onItemPress = selection => {
    const {drugAdministrationsDigits} = this.props;
    Keyboard.dismiss();
    let currSummaryName = null;

    if (selection.data.summary.match('.+?(?=\\(DIN:)')) {
      currSummaryName = selection.data.summary.match('.+?(?=\\(DIN:)');
    } else if (selection.data.summary.match('.+?(?=\\(NHP:)')) {
      currSummaryName = selection.data.summary.match('.+?(?=\\(NHP:)');
    }
    let selectedValue = selection.data;

    let tempTreatments = [];
    let filteredItems = [];
    if (this.props.treatments) {
      tempTreatments = [...this.props.treatments];
      filteredItems = tempTreatments.filter(
        res => res.name === currSummaryName[0],
      );
    }

    if (filteredItems.length === 0) {
      const name = currSummaryName[0];
      const din_npn = selectedValue.treatmentId;
      const treatmentType = selectedValue.treatmentType;

      axiosCommon.axiosGET(
        '/api/treatments/treatment?treatmentId=' +
          din_npn +
          '&treatmentType=' +
          treatmentType,
        res => {
          let unitsList = [];
          let {unit} = '';
          if (res.type === 'SUCCESS') {
            const {data} = res.responseDto;
            const {value} = data;
            const {drugFormat} = value;

            let drugStates = null;
            if (drugFormat && drugFormat.length > 0) {
              drugStates = drugFormat[0].drugStates;
            }

            if (drugStates) {
              const {drugAdministrations} = drugStates;
              unitsList = getMedicationUnits(drugAdministrations, drugAdministrationsDigits, true);
            } else {
              unitsList = getMedicationUnits(null, drugAdministrationsDigits, true);
            }
          } else {
            unitsList = getMedicationUnits(null, drugAdministrationsDigits, true);
          }
          const foundTablet = unitsList.find(res => res.value === 'Tablets');
          unit = foundTablet ? 'Tablets' : unitsList && unitsList[0] && unitsList[0].value ? unitsList[0].value : null;

          let treatmentObject = {
            id: -1,
            name: name,
            notes: null,
            prescribedBy: null,
            healthProviderName: '',
            identificationNumber: din_npn,
            treatmentType: treatmentType,
            active: true,
            isActive: true,
            dosages: {
              id: -1,
              start: new Date().getTime(),
              end: null,
              quantity: 0,
              frequency: 1,
              frequencyType: 'DAILY',
              instructions: null,
              units: unit,
            },
            unitsList: unitsList,
            type: null,
          };

          let temp = [...tempTreatments];
          temp.push(treatmentObject);
          this.props.updateTreatment(temp, () => {
            this.setState({
              name: '',
            });
            this.props.updateInActive(false);
          });
        },
      );
    }
    this.setState({
      name: '',
    });
    this.props.onUpdateTherapieName('', 0);
  };

  onPressCustom = () => {
    Keyboard.dismiss();
    if (this.state.name && this.validateTreatment()) {
      let tempTreatments = [];
      let filteredItems = [];
      if (this.props.treatments) {
        tempTreatments = [...this.props.treatments];
        filteredItems = tempTreatments.filter(
          res => res.name === this.state.name,
        );
      }
      if (filteredItems.length === 0) {
        const name = this.state.name;
        const unitsList = getMedicationUnits(null, this.props.drugAdministrationsDigits, true);
        const unit = 'Tablets'; //unitsList[0].value;
        let treatmentObject = {
          id: -1,
          name: name,
          notes: null,
          prescribedBy: null,
          healthProviderName: '',
          identificationNumber: null,
          treatmentType: 2,
          active: true,
          isActive: true,
          dosages: {
            id: -1,
            start: new Date().getTime(),
            end: null,
            quantity: 0,
            frequency: 1,
            frequencyType: 'DAILY',
            instructions: null,
            units: unit,
          },
          unitsList: unitsList,
          isConfirm: false,
          type: 'custom'
        };

        tempTreatments.push(treatmentObject);
        this.props.updateTreatment(tempTreatments, () => {
          this.setState({
            name: '',
          });
          this.props.updateInActive(false);
        });
      }
      this.setState({
        name: '',
      });
      this.props.onUpdateTherapieName('', 0);
    }
  };

  validateTreatment = () => {
    let valid = true;
    if (this.props.treatments && this.props.treatments.length !== 0) {
      valid = this.props.treatments.some(
        res => !(res.name === this.state.name),
      );
    }
    return valid;
  };

  clearInputAndTerms = () => {
    this.setState({
      name: '',
    });
  };

  processData = res => {
    let termsArray = [];
    if (res) {
      const suggestionResults = [...res.summaries];
      if (res?.substring === this.state.name || !res?.substring) {
      termsArray = suggestionResults.map(value => {
        return {
          currentInput: this.state.currentInput ,
          name: value.summary.match('.+?(?=\\(DIN:)') ? value.summary.match('.+?(?=\\(DIN:)') : value.summary.match('.+?(?=\\(NHP:)'),
          //name: value.summary,
          Ingredient: value.ingredient,
          din: value.summary.includes("(DIN:") ? "DIN: "+value.treatmentId : "NHP: "+value.treatmentId ,
          data: value
        };
      });
      this.setState({termsArray: termsArray});
      } else {
        termsArray = this.state.termsArray
      }
    }

    return termsArray;
  };



  render() {
    return (
      <View style={[styles.fieldRow, styles.zIndexAutoComplete]}>
        <View style={[styles.fieldRowWrapper]}>
          <View style={styles.fieldColStretch}>
            <AutoCompleteSuggestion
              textFieldWithTitle={true}
              textFieldTitle="Enter brand name or DIN/NPN"
              remortSearch={true}
              url="/api/treatments/treatment/summarycontains?substring="
              processData={this.processData}
              triggerLength={3}
              onChangeText={this.onChangeTextName}
              onItemPress={this.onItemPress}
              currentInput={this.state.name}
              onPressCustom={this.onPressCustom}
              customSectionTitle={'Create Custom'}
              showCustomSection={true}
              autoFocus={true}
              fieldBg={FieldDetails.BGWHITE}
              fieldLt={true}
              zoomIcon={true}
              removeIcon={true}
              //requirdStar={'*'}
              placeholder="ex: Tylenol, Advil"
              type={autoSuggestionListType.medication}
              updateAutocompleteOpen={this.props.updateAutocompleteOpen}
              selectedValues={this.state.selectedValues}
            />
          </View>
        </View>
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    treatments: state.setup.treatments,
    therapieName: state.setup.therapieName,
    activeTherapieTab: state.setup.activeTherapieTab,
    drugAdministrationsDigits: state.routines.drugAdministrationsDigits,
    treatmentsProcessed: state.routines.treatmentsProcessed,
    userTreatments: state.routines.treatments,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateTreatment: (addNewTreatment, callBack) =>
      dispatch(actions.updateTreatment(addNewTreatment, callBack)),
    onUpdateTherapieName: (name, activeTab) => dispatch(actions.updateTherapieName(name, activeTab)),
    updateTherapie: (addNewTherapie, callBack) =>
      dispatch(actions.updateTherapie(addNewTherapie, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepTwoAddTreatment);
