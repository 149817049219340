import React, {Component} from 'react';
import AddEditMetric from '../../../components/Settings/metrics/AddEditMetric';
import {METRICS, DASHBOARD} from '../../../navigation/path';
import {handleCommonBackNavigation} from '../../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../../util/pendoConfig';

class AddEditMetricScreen extends Component {

  componentDidMount= () => {
    pendoTrackEvent(this.props.route.name === METRICS.addMetricPath ? 'HealthData/@AddHealthdata' : 'HealthData/@EditHealthData');
  }

  redirectMetric = () => {
    this.props.navigation.navigate(
      METRICS.path, {
        from: this.props.route.name,
      }
    );
  };
  redirectViewMetric = () => {
    this.props.navigation.replace(METRICS.viewMetricPath);
  };

  redirectDashboard = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: DASHBOARD.path }],
    });
  };
  redirectBack = () => {
    handleCommonBackNavigation(this.props.navigation);
  };
  render() {
    return (
      <AddEditMetric
        redirectDashboard={this.redirectDashboard}
        redirectBack={this.redirectBack}
        redirectMetric={this.redirectMetric}
        redirectViewMetric={this.redirectViewMetric}
        navigation={this.props.navigation}
        route={this.props.route}
      />
    );
  }
}

export default (AddEditMetricScreen);
