export const cssSlider = {
  editSymptomSliderOuterWrapper: {
    paddingBottom: 10,
    marginBottom: 10,
    marginTop: 10,
  },

  sliderViewWrapper: {
    marginBottom: 10,
    marginTop: 10,
    paddingBottom: 10,
  }

};
