import React from "react";
import { ScrollView } from "react-native";
import AutoCompleteListItem from "./AutoCompleteListItem";
import AutoSuggestionListItem from "./AutoSuggestionListItem";

const AutoCompleteList = props => {
  return (
    <ScrollView>
      {props.type && props.dataListFilter && props.dataListFilter.length
        ? props.dataListFilter.map((item, i) => (
        <AutoSuggestionListItem
          key={item + "_" + i}
          item={item}
          onItemPress={props.onItemPress}
          selectedValues={props.selectedValues ? props.selectedValues : {}}
          onUserItem={props.onUserItems}
          type={props.type}
          keyword={props.keyword}
          removeSelectedIcon={props.removeSelectedIcon}
          selectedValue={props.selectedValue}
          disbleSelectedValues={props.disbleSelectedValues}
        />
      ))
        : props.dataListFilter && props.dataListFilter.length
          ? props.dataListFilter.map((item, i) => (
        <AutoCompleteListItem
          key={item + "_" + i}
          item={item}
          onItemPress={props.onItemPress}
          selectedValues={props.selectedValues ? props.selectedValues : {}}
        />
      )) : null}
    </ScrollView>
  );
};

export default (AutoCompleteList);
