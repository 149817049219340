import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  contact: {
    id: null,
    createdOn: null,
    modifiedOn: null,
    artifactId: null,
    prefix: null,
    suffix: null,
    physicalAddressLine1: null,
    physicalAddressLine2: null,
    municipality: null,
    stateProvince: null,
    postalCode: null,
    country: null,
    organization: null,
    specialty: null,
    primaryPhoneNumber: null,
    secondaryPhoneNumber: null,
    faxPhoneNumber: null,
    emailaddress: null,
    notes: null,
    givenName: null,
    familyName: null,
    middleName: null
  },
  newContact: null,
  selectedContact: null,
  isNew: false
};

const createContactSuccess = (state, action) => {
  return updateObject(state, {
    newContact: action.newContact,
    isNew: action.isNew
  });
};

const createContactFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true
  });
};

const updateContactSuccess = (state, action) => {
  return updateObject(state, {
    selectedContact: action.selectedContact,
    newContact: null,
    isNew: false
  });
};

const updateContactFail = (state, action) => {
  return updateObject(state, {
    errorDescription: action.errorDescription,
    error: true
  });
};

const selectContact = (state, action) => {
  return updateObject(state, {
    selectedContact: action.selectedContact
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_CONTACT_SUCCESS:
      return createContactSuccess(state, action);
    case actionTypes.ADD_CONTACT_FAIL:
      return createContactFail(state, action);

    case actionTypes.UPDATE_CONTACT_SUCCESS:
      return updateContactSuccess(state, action);
    case actionTypes.UPDATE_CONTACT_FAIL:
      return updateContactFail(state, action);

    case actionTypes.SELECT_CONTACT:
      return selectContact(state, action);

    default:
      return state;
  }
};
export default reducer;
