import React, {Component} from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import UIText from '../../components/Text/text';
import UITextInput from '../../components/TextInput/textInput';
import Header from '../../components/Header/header';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import ReportImage from '../../components/Report/ReportImage';
import moment from 'moment';
import {
  getAppointmentsColourCode,
  getTherapiesColourCode,
} from '../../util/commonUiLogic';
import Picker from '../../components/Picker/picker';
import KeyboardAwareScrollViewHoc from '../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import {REPORTS} from '../../navigation/path';
import PageContainer from "../../components/PageLayout/PageContainer";
import { pendoTrackEvent } from '../../util/pendoConfig';

class ReportAddImageScreen extends Component {
  constructor(props) {
    super(props);
    const attachments = this.getAttachments();
    this.state = {
      attachments: attachments,
      issueDestination: '1',
      searchKeyWord: '',
      filterAttachments: this.filterAttachments(attachments, '1', ''),
      isImagesSelected:
        Object.values(props.selectedImagesObj).length > 0 ? true : false,
    };
    this.props.hideMobileMenu();
  }

  componentDidMount () {
    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
      pendoTrackEvent('Reports/@AddAttachment');
    });
    this.props.navigation.addListener('blur', () => {
      this.props.showMobileMenu();
    });
  }
  getAttachments = () => {
    const journalAttachments = [];
    const contactAttachments = [];
    const treatmentAttachments = [];
    if (
      this.props.attachmentsCompleteDigest &&
      this.props.attachmentsCompleteDigest.userArtifactAttachments
    ) {
      this.props.attachmentsCompleteDigest.userArtifactAttachments.forEach(
        element => {
          if (this.props.journalArtifactMap[element.userArtifact] && this.props.journalEntriesProcessed[this.props.journalArtifactMap[element.userArtifact]]) {
            const journal = this.props.journalEntriesProcessed[this.props.journalArtifactMap[element.userArtifact]];
            element.title = journal.title;
            if (
              this.props.journalEntryTypesProcessed[journal.entryType] &&
              this.props.journalEntryTypesProcessed[journal.entryType]
                .isAppointment
            ) {
              element.color = getAppointmentsColourCode(
                journal.appointmentType,
              );
            } else if (
              this.props.journalEntryTypesProcessed[journal.entryType]
            ) {
              element.color = this.props.journalEntryTypesProcessed[
                journal.entryType
              ].color;
            }
            journalAttachments.push(element);
          }

          if (this.props.contactsArtifactMap[element.userArtifact]) {
            element.title = this.props.contactsArtifactMap[element.userArtifact]
              .givenName
              ? this.props.contactsArtifactMap[element.userArtifact].givenName
              : this.props.contactsArtifactMap[element.userArtifact]
                  .organization;
            contactAttachments.push(element);
          }

          if (this.props.treatmentsArtifactMap[element.userArtifact]) {
            const treatment = this.props.treatmentsArtifactMap[
              element.userArtifact
            ];
            element.title = treatment.name;

            element.color = getTherapiesColourCode(treatment.treatmentType);
            treatmentAttachments.push(element);
          }
        },
      );
    }

    return {
      journalAttachments: journalAttachments,
      contactAttachments: contactAttachments,
      treatmentAttachments: treatmentAttachments,
    };
  };

  filterAttachments = (allAttachments, issueDestination, searchKeyWord) => {
    let attachments;
    let filterAttachments = [];
    switch (issueDestination) {
      case '1':
        attachments = allAttachments.journalAttachments;
        break;
      case '2':
        attachments = allAttachments.contactAttachments;
        break;
      default:
        attachments = allAttachments.treatmentAttachments;
        break;
    }

    let startDate;
    if (this.props.startDate) {
      startDate = new Date(this.props.startDate);
      startDate.setHours(0, 0, 0, 0);
    }

    let endDate;

    if (this.props.endDate) {
      endDate = new Date(this.props.endDate);
      endDate.setHours(23, 59, 59, 59);
    }

    if (attachments) {
      attachments.forEach(element => {
        const attachment = this.props.attachmentsCompleteDigestProcessed
          .attachments[element.attachment];
        if (attachment && attachment.mimetype.includes('image')) {
          attachment.title = element.title;
          attachment.color = element.color;
          filterAttachments.push(attachment);
        }
      });
    }

    if (searchKeyWord) {
      filterAttachments = filterAttachments.filter(res =>
        res.filename.toLowerCase().includes(searchKeyWord.toLowerCase()),
      );
    }

    if (startDate && endDate) {
      filterAttachments = filterAttachments.filter(
        item =>
          item.modifiedOn > startDate.getTime() &&
          item.modifiedOn < endDate.getTime(),
      );
    }

    return filterAttachments;
  };

  changeIssueDestination = val => {
    this.setState({
      issueDestination: val,
      filterAttachments: this.filterAttachments(
        this.state.attachments,
        val,
        this.state.searchKeyWord,
      ),
    });
  };

  onSearchByKeyWord = val => {
    this.setState({
      searchKeyWord: val,
      filterAttachments: this.filterAttachments(
        this.state.attachments,
        this.state.issueDestination,
        val,
      ),
    });
  };

  onSave = () => {
    this.props.saveReportImage();
    const from = this.props.route.params?.from;
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: from ? from : REPORTS.newReportPath }],
    });
  };

  cancelReport = () => {
    const from = this.props.route.params?.from;
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: from ? from : REPORTS.newReportPath }],
    });
  };

  setImagesSelected = val => {
    this.setState({isImagesSelected: val});
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={() => {
            this.props.navigation.goBack();
          }}
          index={REPORTS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        <KeyboardAwareScrollViewHoc>
          <View style={[styles.gridSection]}>
            <View style={stylesRes.layoutPdFixed}>
              <PageContainer>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={styles.secArea}>
                        <View style={[styles.formInnerArea]}>
                          <View style={[styles.fieldRow]}>
                            <View style={[styles.pickerSelectionWrapper]}>
                              <Text
                                style={[
                                  styles.textView,
                                  styles.textViewPicker,
                                ]} allowFontScaling={false}>
                                <UIText
                                  style={[
                                    styles.textLabel,
                                    styles.bold,
                                    styles.textColorLight,
                                  ]}
                                  title="Image Location"
                                />
                              </Text>
                              <View
                                style={[
                                  stylesRes.dropArrowView,
                                  stylesRes.dropArrowTopLg,
                                ]}
                                pointerEvents={'none'}>
                                <View style={stylesRes.dropArrowIcon}>
                                  <Image
                                    style={[styles.imgContain]}
                                    source={require('../../assets/icons/dropdown-arrow.png')}
                                  />
                                </View>
                              </View>
                              {/* Select styles.inValidColor for invalid option*/}
                              <Picker
                                items={[
                                  {
                                    label: 'Entries',
                                    value: '1',
                                    key: 1,
                                  },
                                  {label: 'Contacts', value: '2', key: 2},
                                  {label: 'Treatment', value: '3', key: 3},
                                ]}
                                style={[
                                  styles.pickerAreaField,
                                  styles.selectionPicker,
                                ]}
                                selectedValue={this.state.issueDestination}
                                onValueChange={this.changeIssueDestination}
                              />
                            </View>
                          </View>
                          <View style={[styles.fieldRow]}>
                            <UITextInput
                              style={[
                                styles.textField,
                                styles.textFieldWhite,
                                styles.textFieldSm,
                              ]}
                              placeholder="Search entries"
                              onChangeText={this.onSearchByKeyWord}
                              value={this.state.searchKeyWord}
                            />
                          </View>
                      </View>
                    </View>
                  </View>
                </View>
                </PageContainer>
              </View>
            <PageContainer smScreenFull={true}>
              <View style={stylesRes.contBotPd} dataSet={{media: ids.contBotPd}}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.secArea]}>
                      {/* if Items available, Please add styles.listItemsAll*/}
                      <View
                        style={[
                          this.state.filterAttachments.length > 0
                            ? styles.listItemsReportAllAdded
                            : styles.listItemsNone,
                        ]}>
                        {this.state.filterAttachments.map((res, index) => (
                          <ReportImage
                            id={res.id}
                            key={this.state.issueDestination + 'ri_' + index}
                            title={res.title}
                            filename={res.filename}
                            attachment={res}
                            date={moment(res.modifiedOn, 'x').format(
                              "D MMM 'YY",
                            )}
                            color={res.color}
                            setImagesSelected={this.setImagesSelected}
                          />
                        ))}
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.btnArea, styles.btnMgSm]}>
                      <View style={stylesRes.btnViewSec}>
                        <View style={styles.fieldBtn}>
                          <View style={styles.btnBlockFull}>
                            <TouchableOpacity
                              style={[styles.submitSm, styles.disableBtn]}
                              underlayColor="rgba(0, 153, 168, 0.8)"
                              onPress={this.cancelReport}>
                              <View style={styles.btnSingleView}>
                                <View
                                  style={[
                                    styles.btnSingle,
                                    styles.btnSingleCent,
                                  ]}>
                                  <UIText
                                    style={styles.submitText}
                                    title="Cancel"
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View style={styles.fieldBtnSp} />
                        <View style={styles.fieldBtn}>
                          <View style={styles.btnBlockFull}>
                            {this.state.isImagesSelected ? (
                              <TouchableOpacity
                                onPress={this.onSave}
                                style={[styles.submitSm]}>
                                <View style={styles.btnSingleView}>
                                  <View
                                    style={[
                                      styles.btnSingle,
                                      styles.btnSingleCent,
                                    ]}>
                                    <UIText
                                      style={styles.submitText}
                                      title="Save"
                                    />
                                  </View>
                                </View>
                              </TouchableOpacity>
                            ) : (
                              <TouchableOpacity
                                style={[styles.submitSm, styles.disableBtn]}
                                underlayColor="rgba(0, 153, 168, 0.8)">
                                <View style={styles.btnSingleView}>
                                  <View
                                    style={[
                                      styles.btnSingle,
                                      styles.btnSingleCent,
                                    ]}>
                                    <UIText
                                      style={styles.submitText}
                                      title="Select"
                                    />
                                  </View>
                                </View>
                              </TouchableOpacity>
                            )}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                {/*
                  <View style={styles.gridRow}>
                    <View style={styles.gridColFull}>
                      <View style={[styles.btnBlock, styles.btnMgLg]}>
                        <TouchableOpacity onPress={this.onSave}>
                          <View
                            style={[styles.submit]}
                            underlayColor="rgba(0, 153, 168, 0.8)"
                          >
                            {this.state.saveWait && (
                            <View style={styles.actIndCenter}>
                              <ActivityIndicator size="small" />
                            </View>
                          )}
                            <Text style={styles.submitText}>Save</Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View> */}

                {/* <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.btnBlock, styles.btnMgLg]}>
                      <TouchableOpacity>
                        <View
                          style={[styles.submit, styles.disableBtn]}
                          underlayColor="rgba(0, 153, 168, 0.8)"
                        >
                          <Text style={styles.submitText}>Select</Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View> */}
            </View>
            </PageContainer>
          </View>
        </KeyboardAwareScrollViewHoc>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    attachmentsCompleteDigest: state.routines.attachmentsCompleteDigest,
    attachmentsCompleteDigestProcessed:
      state.routines.attachmentsCompleteDigestProcessed,
    journalEntryTypesProcessed: state.routines.journalEntryTypesProcessed,
    journalArtifactMap: state.routines.journalArtifactMap,
    journalEntriesProcessed: state.routines.journalEntriesProcessed,
    treatmentsArtifactMap: state.routines.treatmentsArtifactMap,
    contactsArtifactMap: state.routines.contactsArtifactMap,
    startDate: state.report.startDate,
    endDate: state.report.endDate,
    selectedImagesObj: state.report.selectedImagesObj,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    saveReportImage: () => dispatch(actions.saveReportImage()),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportAddImageScreen);
