import React from "react";
import { View, Text, Image } from "react-native";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";
import { getCoverColor, getCoverIcon, getCoverImage } from "../../../util/commonUiLogic";

const medicationsOrTherapiesHeader = (props) => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  return (
    <View style={stylesRes.contentTopicSection}>
      <View style={styles.medListFullSec}>
        <View
          style={[
            styles.medListSingleBoxLg,
            styles.boxHeightBg,
            {backgroundColor: getCoverColor(props.treatmentType)}
          ]}
        >
          <View style={styles.medSecImgFull}>
            <Image
              style={[styles.medSecImgBgView, styles.boxHeightBg, styles.bgImgMax]}
              resizeMode={"cover"}
              source={getCoverImage(props.treatmentType)}
            />
          </View>
          <View style={styles.medDetails}>
            <View style={styles.medContentLgView}>
              <View style={styles.medIconBlockIconView}>
                <Image
                  style={[styles.medIconSm]}
                  resizeMode={"contain"}
                  source={getCoverIcon(props.treatmentType)}
                />
              </View>

              <View style={styles.medTitleBlockMd}>
                <Text style={styles.textPrimeBold} allowFontScaling={false}>{props.title}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default medicationsOrTherapiesHeader;
