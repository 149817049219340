
export const SCREEN_SIZES = {
  MOBILE: 768,
  TABLET: 1024,
  DESKTOP: 1200,
  MOBILE_TO_TABLET: `(min-width: 768px) and (max-width: 1023px)`,
  TABLET_TO_DESKTOP: `(min-width: 1024px) and (max-width: 1199px)`,
  MAX_760:  `(max-width: 760px)`,
  MIN_760:  `(min-width: 760px)`,
  MIN_768:  `(min-width: 768px)`,
  MAX_768:  `(max-width: 768px)`,
  MIN_761:  `(min-width: 761px)`,
  MIN_360:  `(min-width: 360px)`,
  MAX_1363: `(max-width: 1363px)`,
  MAX_480:  `(max-width: 480px)`,
  MIN_480:  `(min-width: 480px)`,
  MAX_1112: `(max-width: 1112px)`,
  MIN_1112: `(min-width: 1112px)`,
  MAX_1360: `(max-width: 1360px)`,
  MIN_1360: `(min-width: 1360px)`,
}
