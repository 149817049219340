import React from "react";
import { View, Text } from "react-native";
import styles from "../../../../Css";
import CollapseDetailDescription from "../../../TabCollapse/collapseDetailDescription";

const symptomTabContent = (props) => {
  const { windowWidth, onPressSetupNow } = props;

  return (
    <CollapseDetailDescription
      itemTitle={"Add Symptom(s) to track severity"}
      imgSource={require("../../../../assets/setup/symptom.png")}
      windowWidth={windowWidth}
      onPressSetupNow={onPressSetupNow}
    >
      <View style={[styles.collapseContDec]}>
        <View style={[styles.collapseContPara, styles.comMgBotSm]}>
          <Text style={[styles.textPrimeSm, styles.textGrey]} allowFontScaling={false}>
            To help you get started, symptoms common to your medical conditions
            may be listed.
          </Text>
        </View>

        <View style={[styles.collapseContPara]}>
          <Text style={[styles.textPrimeSm, styles.textGrey]} allowFontScaling={false}>
            This is not a complete list and you can delete any symptom not
            relevant to you. You can also add any custom symptoms.
          </Text>
        </View>
      </View>
    </CollapseDetailDescription>
  );
};

export default (symptomTabContent);
