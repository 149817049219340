import React, {Component} from 'react';
import {View, Text} from 'react-native';
import styles from '../../Css';
import {connect} from 'react-redux';
import {getISubscriptionExpired} from '../../util/subscriptionInformation';

class GraphBanner extends Component {
  constructor(props) {
    super(props);
    const isSubscriptionExpired = getISubscriptionExpired(props.userSubscriptionDigest);
    this.state = {
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      isSubscriptionExpired: isSubscriptionExpired
    };
  }
  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth} = props;

    if (
      windowWidth !== state.windowWidth ||
      windowHeight !== state.windowHeight
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }
  render() {
    const {windowWidth, isSubscriptionExpired} = this.state;
    return (
      <>
        <View style={[styles.stoargeBannerCont]}>
          <View
            style={[
              styles.stoargeBannerView,
              styles.flexRowJcCtAiCt,
              styles.stoargeBannerCom,
            ]}>
            <View style={[styles.bannerBlock]}>
              {/* <View style={styles.warningIconView}>
                <Image
                  style={styles.warningMessageIcon}
                  source={require('../../assets/icons/warning-message.png')}
                />
              </View> */}
              <View style={[
                styles.warningDescView, 
                // styles.warningDescPd
                ]}>
                <Text
                  style={[
                    styles.textPrime,
                    styles.textColorGreyLight,
                    styles.textAlignCenter,
                    windowWidth < 767 ? styles.textPrimeSm : styles.textPrime,
                  ]}
                  allowFontScaling={false}
                  >
                  {isSubscriptionExpired
                    ? 'Free access gives you 2 graphs! Upgrade to premium to view all your graphs or make more.'
                    : 'Congratulations on creating your 2 free graphs! Upgrade to premium to make more'
                  }
                </Text>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    userSubscriptionDigest: state.routines.userSubscriptionDigest,
  };
};

export default connect(mapStateToProps, null)(GraphBanner);
