import React from "react";
import { Image, View, TouchableOpacity } from "react-native";
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
const AddButton = (props) => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  const {openCloseEventMenu, open, isDisabled} = props;
  const imageSource = !open
    ? require('../../../assets/icons/more-white.png')
    : require('../../../assets/icons/less-white.png')
  return (
    <React.Fragment>
      {/* Add More Button */}
      <View style={styles.addEventButton}>
        <View style={[stylesRes.addMainMenuIn, stylesRes.btnMaxZindex]}>
          <TouchableOpacity onPress={openCloseEventMenu} disabled={isDisabled}>
            <View style={isDisabled ? styles.plusBtnDisabledBlue : styles.plusBtnBlue}>
              <Image
                style={styles.plusBtnBlueIcn}
                source={imageSource}
              />
            </View>
          </TouchableOpacity>
        </View> 
      </View>
    </React.Fragment>
  );
};
export default AddButton;
