import React, { useEffect, useState } from 'react';
import { View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import { connect } from 'react-redux';
import ActivityIndicator from '../../ActivityIndicator/activityIndicator'
import { EmptyItem } from '../Common';
import styles from '../../../Css';
import { DASHBOARD, JOURNALS, SURVEYS } from '../../../navigation/path';
import * as actions from '../../../store/actions/index';
import { scheduleTypes, scheduleStatus, dashboardCategories, getEntryDateTime, handleSurveyNavigation, handleNavigationCommon } from '../../../util/commonUiLogic';
import { getFormattedTime } from '../../../util/formatDate';
import { getCurrentSubscription, isSubscriptionSponsored } from "../../../util/subscriptionInformation";
import { surveyTabs } from '../../../Constant/index';
import { TAB_BREAk_POINT_END, WEB } from '../../../util/platformWindowConfig';

// schedule header icons
const surveyScheduleIcon = require('../../../assets/dashbord/survey-scheduler.png');
const routineScheduleIcon = require('../../../assets/dashbord/routine-schedule.png');

// schedule icons
const greenClock = require('../../../assets/dashbord/green-clock.png');
const clock = require('../../../assets/dashbord/clock.png');
const greenFlag = require('../../../assets/dashbord/green-flag.png');
const flag = require('../../../assets/dashbord/flag.png');
const greenBell = require('../../../assets/dashbord/green-bell.png');
const bell = require('../../../assets/dashbord/bell.png');

//schedule complete/ uncomplete icons
const completedIcon = require('../../../assets/dashbord/complete-tick.png');
const unCompletedIcon = require('../../../assets/dashbord/next.png');



const TodaysScheduleSection = props => {
  const { dashboardScheduleData, navigation, journalEntryTypes, windowWidth, onCreateNewRoutine, journalUserId, finishedSchedules, updateDashboardData, setFinshedSchedules, getAllUserHealthSurvey, resetSurvey, getUserHealthSurveyById, setActiveTab, selectSurvey, userSubscription } = props;
  const [processing, setProcessing] = useState(null);

  const currentSubscription = getCurrentSubscription(userSubscription);
  const isSponsoredSubscription = isSubscriptionSponsored(currentSubscription);

  useEffect(() => {
    setTimeout(() => {
      finishedSchedules && finishedSchedules.length > 0 && removeFinishedSchedulers()
    }, 10000);
    //eslint-disable-next-line
  }, [])

  const removeFinishedSchedulers = () => {
    const routines = [...dashboardScheduleData?.routines];
    const surveys = [...dashboardScheduleData?.surveys];
    finishedSchedules.forEach(schedule => {
      if (schedule.type === scheduleTypes.ROUTINE) {
        const found = routines.findIndex(routine => routine?.uniqueId === schedule?.uniqueId);
        if (found !== -1)
          routines.splice(found, 1)
      } else {
        const found = surveys.findIndex(survey => survey?.uniqueId === schedule?.uniqueId);
        if (found !== -1)
          surveys.splice(found, 1)
      }
    })
    let updatedDashboardScheduleData = {
      ...dashboardScheduleData,
      routines: [...routines],
      surveys: [...surveys]
    }
    setFinshedSchedules([])
    updateDashboardData(updatedDashboardScheduleData);
  }

  const onPressAction = (type, schedule) => {
    if (type === scheduleTypes.SURVEY) {
      handleRedirectSurveys(schedule)
    } else {
      handleCreateEntry(schedule)
    }
  }

  const handleRedirectSurveys = (schedule) => {
    const { uniqueId, id } = schedule;
    setProcessing(uniqueId);
    getAllUserHealthSurvey(journalUserId, (success, response) => {
      if (success) {
        let found = response.open.find(function (element) {
          return element.id.toString() === `${id}`;
        });
        if (!Boolean(found?.id)) {
          found = response.closed.find(function (element) {
            return element.id.toString() === `${id}`;
          });
        }

        resetSurvey();
        getUserHealthSurveyById(found ? found?.id : id, journalUserId, (success, res) => {
          if (success) {
            handleSurveyNavigation(found ? found : res.newSchedule, props, false, null, true);
          } else {
            setActiveTab(surveyTabs.open);
            handleNavigationCommon(navigation, SURVEYS.mainSurveyPath, { status: true });
          }
          setProcessing(null);
        });
      } else {
        setProcessing(null);
      }
    });
  }

  const handleCreateEntry = schedule => {
    const { entryTypeId, uniqueId, scheduleTimeStatus, reminderDateTime } = schedule;
    setProcessing(uniqueId);
    const entryType = journalEntryTypes.find(({ id }) => id === entryTypeId);

    const entryDate = Boolean(scheduleTimeStatus === scheduleStatus.UP_COMING)
      ? getEntryDateTime(new Date().getHours() + ':' + new Date().getMinutes())
      : reminderDateTime;
    const entry = { journalEntryTypeId: entryTypeId, draft: true, entryDate: entryDate };
    onCreateNewRoutine(
      entry,
      res => {
        if (res) {
          navigation.reset({
            index: 0,
            routes: [{
              name: windowWidth < TAB_BREAk_POINT_END ? JOURNALS.addJournalPath : JOURNALS.path,
              params: {
                from: DASHBOARD.path,
                journalPath: JOURNALS.addJournalPathSuffix,
                journalName: entryType?.name,
                reminderDateTime: reminderDateTime,
              }
            }],
          });
        }
        setProcessing(null);
      },
      journalUserId,
    );
  }

  const scheduleHeader = (scheduleType, completed, total) => {
    let isSurvey = Boolean(scheduleType === scheduleTypes.SURVEY);
    return (
      <View style={styles.headerDashboardContent}>
        <View style={styles.titleDashCont}>
          <View>
            <Image
              style={styles.editIconDash}
              source={isSurvey ? surveyScheduleIcon : routineScheduleIcon}
            />
          </View>
          <View>
            <Text style={styles.surveyText} allowFontScaling={false}>{isSurvey ? 'Surveys:' : 'Routines:'}</Text>
          </View>
        </View>
        <View>
          <Text style={styles.completeText} allowFontScaling={false}>{`${completed}`}/{`${total}`} Completed</Text>
        </View>
      </View>
    );
  }

  const scheduleItem = (scheduleType, schedule) => {
    const { name, completed, scheduleTimeStatus, scheduleTime, uniqueId } = schedule;
    let isSurveyAndDue = Boolean(scheduleType === scheduleTypes.SURVEY) && Boolean(scheduleTimeStatus === scheduleStatus.DUE);
    let isOverDue = Boolean(scheduleTimeStatus === scheduleStatus.OVER_DUE);
    return (
      <View style={styles.taskContainer} key={uniqueId || Date.now() * Math.random()}>
        <View style={styles.studyContainer}>
          <View style={[styles.dueContainer, isOverDue && styles.overDueBGColor, completed && styles.dueCompletedBGColor]}>
            <Image
              style={styles.clockDash}
              source={getScheduleIcon(scheduleTimeStatus, completed)}
            />
            <Text
              style={[styles.dueText, isOverDue && styles.overDueTextColor, completed && styles.dueCompletedTextColor]}
              allowFontScaling={false}>
              {isSurveyAndDue ? 'Due' : isOverDue ? scheduleTime + ' days' : getFormattedTime(scheduleTime)}
            </Text>
          </View>

          <Text
            style={[styles.studyText, Platform.OS !== WEB && styles.mgRtHundred]}
            numberOfLines={1}
            allowFontScaling={false}>
            {name}
          </Text>
        </View >
        {processing === uniqueId &&
          <View style={styles.mgRtZero}>
            <ActivityIndicator size pdZero />
          </View>
        }
        <View>
          <TouchableOpacity testID={'PRESS_SCHEDULE_' + scheduleType + "_" + uniqueId} disabled={completed || processing === uniqueId} onPress={() => onPressAction(scheduleType, schedule)}>
            <Image
              style={styles.editIconDash}
              source={completed ? completedIcon : unCompletedIcon}
            />
          </TouchableOpacity>
        </View>
      </View >
    )
  }

  const getScheduleIcon = (schedule, isCompleted) => {
    if (isCompleted) {
      switch (schedule) {
        case scheduleStatus.DUE:
          return greenClock;
        case scheduleStatus.OVER_DUE:
          return greenFlag;
        default:
          return greenBell;
      }
    } else {
      switch (schedule) {
        case scheduleStatus.DUE:
          return clock;
        case scheduleStatus.OVER_DUE:
          return flag;
        default:
          return bell;
      }
    }
  }

  const completedNoSchedulesAvailableView = (scheduleType, isCompletedAll) => {
    return (
      <View style={[styles.flexJcCtAiCt, styles.infoPd]}>
        <Text style={[styles.textPrimeSm, styles.textColorLight]} allowFontScaling={false}>
          {getCompletedNoSchedulesAvailableText(scheduleType, isCompletedAll)}
        </Text>
      </View>
    )
  }

  const getCompletedNoSchedulesAvailableText = (scheduleType, isCompletedAll) => {
    switch (scheduleType) {
      case scheduleTypes.SURVEY:
        if (isCompletedAll) {
          return 'All Surveys Completed';
        } else {
          return 'No Surveys to Schedule';
        }
      case scheduleTypes.ROUTINE:
        if (isCompletedAll) {
          return 'All Routines Completed';
        } else {
          return 'No Routines to Schedule';
        }
      default:
        return '';
    }
  }

  return (
    <>
      {!Boolean(dashboardScheduleData) || (Boolean(dashboardScheduleData?.routines.length === 0) && Boolean(dashboardScheduleData?.surveys.length === 0)) ?
        <EmptyItem navigation={navigation} type={dashboardCategories.SCHEDULES} />
        : <View>
          {/* Survey Schedulers */}
          {Boolean(isSponsoredSubscription) &&
            <View style={styles.dashBoardContentMain}>
              {scheduleHeader(scheduleTypes.SURVEY, dashboardScheduleData?.extra?.completedSurveys, dashboardScheduleData?.surveys.length)}
              <View style={styles.surveyContent}>
                {Boolean(dashboardScheduleData?.surveys.length === 0)
                  ? completedNoSchedulesAvailableView(scheduleTypes.SURVEY, false)
                  : dashboardScheduleData?.surveys.map(survey => scheduleItem(scheduleTypes.SURVEY, { ...survey, name: survey?.surveyTitle }))
                }
              </View>
            </View>
          }

          {/* Routine Schedulers */}
          <View style={styles.dashBoardContentMain}>
            {scheduleHeader(scheduleTypes.ROUTINE, dashboardScheduleData?.extra?.completedRoutines, dashboardScheduleData?.routines.length)}
            <View style={styles.surveyContent}>
              {Boolean(dashboardScheduleData?.routines.length === 0)
                ? completedNoSchedulesAvailableView(scheduleTypes.ROUTINE, false)
                : dashboardScheduleData?.routines.map(routine => scheduleItem(scheduleTypes.ROUTINE, { ...routine, name: routine?.entryTypeName }))
              }
            </View>
          </View>
        </View>
      }
    </>
  );
};

const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
    dashboardScheduleData: state.uistate.dashboardScheduleData,
    journalEntryTypes: state.routines.journalEntryTypes,
    windowWidth: state.uistate.windowWidth,
    finishedSchedules: state.uistate.finishedSchedules,
    userSubscription: state.routines.userSubscription,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateNewRoutine: (journalEntry, callBack, journalUserId) =>
      dispatch(actions.createNewRoutine(journalEntry, callBack, journalUserId)),
    updateDashboardData: data =>
      dispatch(actions.updateDashboardData(data)),
    setFinshedSchedules: data =>
      dispatch(actions.setFinshedSchedules(data)),
    getAllUserHealthSurvey: (journalUserId, callBack) =>
      dispatch(actions.getAllUserHealthSurvey(journalUserId, callBack)),
    resetSurvey: () => dispatch(actions.resetSurvey()),
    getUserHealthSurveyById: (id, journalUserId, callBack) =>
      dispatch(actions.getUserHealthSurveyById(id, journalUserId, callBack)),
    setActiveTab: (activeTabIndex) =>
      dispatch(actions.setActiveTab(activeTabIndex)),
    selectSurvey: (id, surveyId, name, isClosed,
      modifiedOn = null, description = null, duration = null,
      s3ImageUrl = null, surveyStatus = null, questionOrder = 0
    ) =>
      dispatch(actions.selectSurvey(id, surveyId, name, isClosed,
        modifiedOn, description, duration,
        s3ImageUrl, surveyStatus, questionOrder
      )),
    setToken: (token, type, reminderDateTime) => dispatch(actions.setToken(token, type, reminderDateTime)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TodaysScheduleSection);