import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity, TextInput} from 'react-native';
import moment from 'moment';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import {connect} from 'react-redux';
import ActivityIndicator from '../../../components/ActivityIndicator/activityIndicator';
import UIText from '../../../components/Text/text';
import ResultsList from './ResultsList';
import * as actions from '../../../store/actions/index';
import * as axiosCommon from '../../../axios/axios-common-community-groups';
import {BASE_URL_COMMUNITY_GROUPS, BASE_URL, SEARCH_CRITERIA} from '../../../store/actions/urls';
import Modal from '../../../components/Modal/modal';
import ResoureSearchModal from '../../../components/ResourceModal/SearchModal';
import Picker from '../../../components/Picker/picker';
import KeyboardAwareScrollViewHoc from '../../../components/KeyboardAwareScrollViewHoc/keyboardAwareScrollViewHoc';
import PageContainer from '../../../components/PageLayout/PageContainer';
import {communityGroupTabPath} from '../../../Constant/index';
import {pendoTrackEvent} from '../../../util/pendoConfig';
import { sortAlphabeticalOrder, getScrollHeight, getMedicalConditionStatus } from '../../../util/commonUiLogic';
import _ from 'lodash';
import {TAG_SEARCH_SOURCE_MY_RESOURCES} from "../MyResources/ResourcesListItem";
import Collapsible from "../../../components/Collapsible/Collapsible";
import { UI_BREAK_FOUR_COUNT } from '../../../util/platformWindowConfig';

let initialValue = {};

class Directory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      peopleLike: true,
      caringPeopleLike: false,
      similarAge: false,
      mySex: false,
      symptomsList: props.userDefinedSymptoms ? props.userDefinedSymptoms : [],
      treatmentList: props.listTherapie ? props.listTherapie : [],
      conditionList: props.userConditions ? props.userConditions : [],
      selectedUserCondition: [],
      selectedTreatment: [],
      selectedSymptom: [],
      sharedAccessGivenToMe: props.sharedAccessDigest.sharedAccessGivenToMe
        ? props.sharedAccessDigest.sharedAccessGivenToMe
        : [],
      sharedAccessSummery: props.sharedAccessDigest?.sharedAccessUserSummaries
        ? props.sharedAccessDigest.sharedAccessUserSummaries
        : [],
      treatmentLastSix: true,
      treatmentAll: false,
      symptomLastSix: true,
      symptomAll: false,
      searchString: '',
      resourceType: 'All',
      dateRange: 'Anytime',
      searchWait: false,
      savedSearchList: [],
      searchResult: [],
      searchCount: null,
      visibleSearchModal: false,
      selectedUser: 'Me',
      selectedId: props.user ? props.user.id : '',
      userId: props.user ? props.user.id : '',
      birthday: props.user ? props.user.birthday : '',
      sex: props.user ? props.user.sex : '',
      nameList:   props?.sharedAccessDigest?.sharedAccessGivenToMe?.length && props?.sharedAccessDigest?.sharedAccessUserSummaries?.length
      ?  this.getCareGiverNameList(props.sharedAccessDigest.sharedAccessGivenToMe, props.sharedAccessDigest.sharedAccessUserSummaries, props.user.id)
      :[],
      userDetailWait: false,
      saveDSelectedCriteria: '',
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      name: '',
      validCondition: true,
      validSymptom: true,
      validTreatment: true,
      confirmBox: false,
      showMoreFilters: false,
      loading: true
    };
    initialValue = this.state;
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth} = props;
    if (_.isEqual(state, initialValue)) {
      props.handleChanges(false);
    } else {
      props.handleChanges(true);
    }

    if (
      props.windowHeight !== state.windowHeight ||
      props.windowWidth !== state.windowWidth
    ) {
      initialValue = {
        ...initialValue,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      }
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }
    return null;
  }

  componentDidMount = () => {
    this.refUpdate();
    if (this.props.activeTab === 1 && this.props.searchResultStack.length > 0) {
      let searchResultStackElement = this.props.searchResultStack[this.props.searchResultStack.length - 1];

      initialValue = {
        ...initialValue,
        searchResult: searchResultStackElement,
        searchCount: searchResultStackElement.searchResults.length,
      };
      this.setState({
        searchResult: searchResultStackElement,
        searchCount: searchResultStackElement.searchResults.length,
        caringPeopleLike: searchResultStackElement?.searchObject?.caringPeopleLike,
        similarAge: searchResultStackElement?.searchObject?.similarAge,
        mySex: searchResultStackElement?.searchObject?.mySex,
        selectedTreatment: searchResultStackElement?.searchObject?.selectedTreatment,
        selectedUserCondition: searchResultStackElement?.searchObject?.selectedUserCondition ,
        selectedSymptom: searchResultStackElement?.searchObject?.selectedSymptom,
        selectedId: this.props.user ? this.props.user.id : '',
        selectedUser: searchResultStackElement?.searchObject?.selectedUser,
        name: searchResultStackElement?.searchObject?.name,
        dateRange: searchResultStackElement?.searchObject?.dateRange,
        peopleLike: searchResultStackElement?.searchObject?.peopleLikeMe,
        resourceType: searchResultStackElement?.searchObject?.resourceType,
      });
    } else {
      if (this.state.userId) {
        this.setSavedSearch();
      }
      this.setCaregiverNameList();
    }
  };

  setSavedSearch = () => {
    this.setState({loading: true});
    axiosCommon.axiosGET(
      BASE_URL_COMMUNITY_GROUPS + 'searchcriteria',
      res => {
        if (res.type === 'SUCCESS') {
          initialValue = {
            ...initialValue,
            savedSearchList: res.responseDto.data,
          };
          this.setState({savedSearchList: res.responseDto.data});
        }
        this.setState({loading: false});
      },
      {userId: this.state.userId},
    );
  }

  refUpdate = () => {
    const {getRef} = this.props;
    getRef && getRef(this);
  };

  isSearchOn = () => {
    return this.props.searchResultStack.length > 0;
  };

  isSearchFromMyResources = () => {
    return this.isSearchOn() && this.props.searchResultStack[this.props.searchResultStack.length - 1].searchSource === TAG_SEARCH_SOURCE_MY_RESOURCES;
  };

  onPressOtherUser = () => {
    this.setState(prevState => ({
      peopleLike: !prevState.peopleLike,
      caringPeopleLike: !prevState.caringPeopleLike,
    }));
    this.setCurrentUserDetails(this.state.userId);
  };

  onPressSimilarAge = () => {
    this.setState(prevState => ({
      similarAge: !prevState.similarAge,
    }));
  };

  onPressMySex = () => {
    this.setState(prevState => ({
      mySex: !prevState.mySex,
    }));
  };

  handleCancelSearch = callBack => {
    if (_.isEqual(this.state, initialValue)) {
      callBack(true);
    } else {
      callBack(false);
    }
  };

  Diagnosed = () => {
    let sortedList = [];
    if (this.state.conditionList && this.state.conditionList.length > 0) {
      sortedList = this.state.conditionList.sort(function (a, b) {
        if (a.name && b.name) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        }
        return 0;
      });
      return sortedList.map(res => (
        <View key={res.id} style={[styles.answerWrapper]}>
          <View style={[styles.answerSingle]}>
            <TouchableOpacity
              style={[styles.answerOptMg, styles.answerViewWrapper]}
              onPress={() => this.onPressUserCondition(res)}>
              <View style={[styles.optionWrapper]}>
                {res.id ? this.checkBoxtickCondition(res.id) : null}
              </View>

              <View style={[styles.answerTextWrapper]}>
                <Text style={[styles.textPrime]} allowFontScaling={false}>{res.name}</Text>
                <Text
                  style={[styles.textPrimeSm, styles.textGrey, styles.textEm]} allowFontScaling={false}>
                  {(res.year
                      ? getMedicalConditionStatus(res.status) +
                      ' in ' +
                      res.year +
                      (res.stage ? ', ' : '')
                      : '') +
                    (res.stage
                      ? !res.stage.includes('Stage')
                        ? 'Stage ' + res.stage
                        : res.stage
                      : '')}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      ));
    }
  };

  treatmentItems = () => {
    if (this.state.treatmentList && this.state.treatmentList.length > 0) {
      let treatmentList = [];
      let listTherapie = this.state.treatmentList;
      if (this.state.treatmentLastSix === true) {
        const startDate = new Date();
        const endDate = new Date();
        startDate.setHours(23, 59, 59, 59);
        endDate.setDate(endDate.getDate() - 180);
        endDate.setHours(0, 0, 0, 0);
        treatmentList = listTherapie.filter(listTherapie => {
          return (
            listTherapie.name &&
            listTherapie.createdOn < startDate.getTime() &&
            listTherapie.createdOn > endDate.getTime()
          );
        });
      } else {
        treatmentList = listTherapie.filter(listTherapie => {
          return listTherapie.name;
        });
      }
      let sortedList = [];
      if (treatmentList && treatmentList.length > 0) {
        sortedList = sortAlphabeticalOrder(treatmentList);
        return sortedList.map(res =>
          res.isActive === true || res.isActive === 'True' ? (
            <View key={res.id} style={[styles.answerWrapper]}>
              <View style={[styles.answerSingle]}>
                <TouchableOpacity
                  style={[styles.answerOptMg, styles.answerViewWrapper]}
                  onPress={() => this.onPressTreatment(res)}>
                  <View style={[styles.optionWrapper]}>
                    {res.id ? this.checkBoxtickTreatment(res.id) : null}
                  </View>

                  <View style={[styles.answerTextWrapper]}>
                    <Text style={[styles.textPrime]} allowFontScaling={false}>{res.name}</Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          ) : null,
        );
      }
    }
  };

  symptomItems = () => {
    if (this.state.symptomsList && this.state.symptomsList.length > 0) {
      let symptomsList = [];
      if (this.state.symptomLastSix === true) {
        let userDefinedSymptoms = [...this.state.symptomsList];

        const startDate = new Date();
        const endDate = new Date();
        startDate.setHours(23, 59, 59, 59);
        endDate.setDate(endDate.getDate() - 180);
        endDate.setHours(0, 0, 0, 0);
        symptomsList = userDefinedSymptoms.filter(userDefinedSymptoms => {
          return (
            userDefinedSymptoms.name &&
            userDefinedSymptoms.createdOn < startDate.getTime() &&
            userDefinedSymptoms.createdOn > endDate.getTime()
          );
        });
      } else {
        symptomsList = [...this.state.symptomsList];
      }
      let sortedList = [];
      sortedList = sortAlphabeticalOrder(symptomsList);

      return sortedList.map(res =>
        res.isActive === true || res.isActive === 'True' ? (
          <View style={[styles.answerWrapper]} key={res.id}>
            <View style={[styles.answerSingle]}>
              <TouchableOpacity
                style={[styles.answerOptMg, styles.answerViewWrapper]}
                onPress={() => this.onPressSymptom(res)}>
                <View style={[styles.optionWrapper]}>
                  {res.id ? this.checkBoxtickSymptom(res.id) : null}
                </View>

                <View style={[styles.answerTextWrapper]}>
                  <Text style={[styles.textPrime]} allowFontScaling={false}>{res.name}</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        ) : null,
      );
    }
  };

  onPressUserCondition = condition => {
    let temp = [];
    let selectedUserCondition = {};
    let flag = 0;
    if (
      this.state.selectedUserCondition &&
      this.state.selectedUserCondition.length > 0
    ) {
      this.state.selectedUserCondition.forEach(res => {
        if (res && res.id === condition.id) {
          temp = this.state.selectedUserCondition.filter(
            re => re.id !== condition.id,
          );
          flag = 1;
        }
      });
      if (flag !== 1) {
        temp = [...this.state.selectedUserCondition];
        selectedUserCondition = {
          id: condition.id,
          name: condition.name,
          status: condition.status,
          year: condition.year,
          stage: condition.stage,
        };
        temp.push(selectedUserCondition);
      }
    } else {
      selectedUserCondition = {
        id: condition.id,
        name: condition.name,
        status: condition.status,
        year: condition.year,
        stage: condition.stage,
      };
      temp.push(selectedUserCondition);
    }

    this.setState({
      selectedUserCondition: temp,
    });
  };

  checkBoxtickCondition = res => {
    if (
      this.state.selectedUserCondition &&
      this.state.selectedUserCondition.length > 0
    ) {
      let flag = 0;
      this.state.selectedUserCondition.forEach(re => {
        return re.id === res ? (flag = 1) : null;
      });
      if (flag === 1) {
        return (
          <Image
            style={styles.radioIcon}
            source={require('../../../assets/toggleTick/check-tick.png')}
          />
        );
      } else {
        return (
          <Image
            style={styles.radioIcon}
            source={require('../../../assets/toggleTick/check-untick.png')}
          />
        );
      }
    } else {
      return (
        <Image
          style={styles.radioIcon}
          source={require('../../../assets/toggleTick/check-untick.png')}
        />
      );
    }
  };

  onPressTreatment = treatment => {
    let temp = [];
    let selectedTreatment = {};
    let flag = 0;
    if (
      this.state.selectedTreatment &&
      this.state.selectedTreatment.length > 0
    ) {
      this.state.selectedTreatment.forEach(res => {
        if (res && res.id === treatment.id) {
          temp = this.state.selectedTreatment.filter(
            re => re.id !== treatment.id,
          );
          flag = 1;
        }
      });
      if (flag !== 1) {
        temp = [...this.state.selectedTreatment];
        selectedTreatment = {
          id: treatment.id,
          name: treatment.name,
        };
        temp.push(selectedTreatment);
      }
    } else {
      selectedTreatment = {
        id: treatment.id,
        name: treatment.name,
      };
      temp.push(selectedTreatment);
    }

    this.setState({
      selectedTreatment: temp,
    });
  };

  checkBoxtickTreatment = res => {
    if (
      this.state.selectedTreatment &&
      this.state.selectedTreatment.length > 0
    ) {
      let flag = 0;
      this.state.selectedTreatment.forEach(re => {
        return re.id === res ? (flag = 1) : null;
      });
      if (flag === 1) {
        return (
          <Image
            style={styles.radioIcon}
            source={require('../../../assets/toggleTick/check-tick.png')}
          />
        );
      } else {
        return (
          <Image
            style={styles.radioIcon}
            source={require('../../../assets/toggleTick/check-untick.png')}
          />
        );
      }
    } else {
      return (
        <Image
          style={styles.radioIcon}
          source={require('../../../assets/toggleTick/check-untick.png')}
        />
      );
    }
  };

  onPressSymptom = symptom => {
    let temp = [];
    let selectedSymptom = {};
    let flag = 0;
    if (this.state.selectedSymptom && this.state.selectedSymptom.length > 0) {
      this.state.selectedSymptom.forEach(res => {
        if (res && res.id === symptom.id) {
          temp = this.state.selectedSymptom.filter(re => re.id !== symptom.id);
          flag = 1;
        }
      });
      if (flag !== 1) {
        temp = [...this.state.selectedSymptom];
        selectedSymptom = {
          id: symptom.id,
          name: symptom.name,
        };
        temp.push(selectedSymptom);
      }
    } else {
      selectedSymptom = {
        id: symptom.id,
        name: symptom.name,
      };
      temp.push(selectedSymptom);
    }

    this.setState({
      selectedSymptom: temp,
    });
  };

  checkBoxtickSymptom = res => {
    if (this.state.selectedSymptom && this.state.selectedSymptom.length > 0) {
      let flag = 0;
      this.state.selectedSymptom.forEach(re => {
        return re.id === res ? (flag = 1) : null;
      });
      if (flag === 1) {
        return (
          <Image
            style={styles.radioIcon}
            source={require('../../../assets/toggleTick/check-tick.png')}
          />
        );
      } else {
        return (
          <Image
            style={styles.radioIcon}
            source={require('../../../assets/toggleTick/check-untick.png')}
          />
        );
      }
    } else {
      return (
        <Image
          style={styles.radioIcon}
          source={require('../../../assets/toggleTick/check-untick.png')}
        />
      );
    }
  };

  getCareGiverNameList = (sharedAccessGivenToMe, sharedAccessSummery, userId) => {
  if (
    sharedAccessGivenToMe &&
    sharedAccessGivenToMe.length > 0
  ) {
    let nameList = [];
    sharedAccessGivenToMe.forEach(res => {
      sharedAccessSummery.filter(re => {
        return re.userId === res.grantingUser &&
        res.permissionType !== 'NONE' &&
        re.userId !== userId
          ? nameList.push({userId: re.userId, name: re.name})
          : null;
      });
    });
    return nameList;
  }else{
    return [];
  }
  }

  setCaregiverNameList = () => {
    if (
      this.state.sharedAccessGivenToMe &&
      this.state.sharedAccessGivenToMe.length > 0
    ) {
      let nameList = this.getCareGiverNameList(this.state.sharedAccessGivenToMe, this.state.sharedAccessSummery, this.props.user.id)
      initialValue = {
        ...initialValue,
        nameList
      };
      this.setState({nameList});
    }
  }

  getNameList = () => {
    let names = [{key: '0', label: 'Me', value: this.state.userId}];

    this.state.nameList &&
    this.state.nameList.length > 0 &&
    this.state.nameList.map(res =>
      names.push({
        key: res.userId,
        label: res.name,
        value: res.userId,
      }),
    );
    return names;
  };

  setCurrentUserDetails = (value) => {
    this.setState({
      symptomsList: this.props.userDefinedSymptoms
        ? this.props.userDefinedSymptoms
        : [],
      treatmentList: this.props.listTherapie
        ? this.props.listTherapie
        : [],
      conditionList: this.props.userConditions
        ? this.props.userConditions
        : [],
      selectedSymptom: [],
      selectedTreatment: [],
      selectedUserCondition: [],
      treatmentLastSix: true,
      treatmentAll: false,
      symptomLastSix: true,
      symptomAll: false,
      birthday: this.props.user ? this.props.user.birthday : '',
      sex: this.props.user ? this.props.user.sex : '',
      selectedId: value,
      selectedUser: 'Me',
    });
  }

  peopleLikeDropDown = () => {
    return (
      <Picker
        items={this.getNameList()}
        style={[styles.pickerAreaField, styles.textPickerPdMiniCom, styles.btnFixed]}
        onValueChange={value => {
          if (value) {
            if (value.toString() === this.state.userId.toString()) {
              this.setCurrentUserDetails(value);
            } else {
              if (this.state.nameList && this.state.nameList.length > 0) {
                this.state.nameList.forEach(res => {
                  return res.userId.toString() === value.toString()
                    ? this.setState({
                      selectedId: res.userId,
                      selectedUser: res.name,
                    })
                    : null;
                });
              }

              this.getUserDetails(value, [], [], [], 0, 1, 0, 1);
            }
          }
        }}
        selectedValue={this.state.selectedId}
        disabled={this.state.userDetailWait}
      />
    );
  };

  onPressTreatmentTimeFilter = () => {
    this.setState(prevState => ({
      treatmentLastSix: !prevState.treatmentLastSix,
      treatmentAll: !prevState.treatmentAll,
    }));
  };

  onPressSymptomTimeFilter = () => {
    this.setState(prevState => ({
      symptomLastSix: !prevState.symptomLastSix,
      symptomAll: !prevState.symptomAll,
    }));
  };

  getSavedSearchList = () => {
    let savedSearchList = [{key: '0', label: '', value: ''}];
    this.state.savedSearchList &&
    this.state.savedSearchList.length > 0 &&
    this.state.savedSearchList.map(res =>
      savedSearchList.push({
        key: res.searchCriteriaId,
        label: res.name,
        value: JSON.stringify(res),
      }),
    );
    return savedSearchList;
  };

  saveSearchDropDown = () => {
    return (
      <Picker
        items={this.getSavedSearchList()}
        style={[styles.pickerAreaField, styles.selectionPicker]}
        onValueChange={this.onValueChangeSavedCriteria}
        selectedValue={this.state.saveDSelectedCriteria}
      />
    );
  };

  onPressCollapsibleMoreFilters = () => {
    this.setState(prevState => ({
      showMoreFilters: !prevState.showMoreFilters,
    }));
  };

  onValueChangeSavedCriteria = value => {
    pendoTrackEvent('HealthLibrary > @Directory > SavedSearch');
    if (value) {
      let searchValue = JSON.parse(value);

      if (searchValue.compareCaregiver) {
        if (
          this.state.sharedAccessGivenToMe &&
          this.state.sharedAccessGivenToMe.length > 0
        ) {
          this.state.sharedAccessSummery.forEach(re => {
            return re.userId === searchValue.compareCaregiver
              ? this.setState({selectedUser: re.name, selectedId: re.userId})
              : null;
          });
        }
      }
      this.setState({name: searchValue.name});
      if (searchValue.compareCaregiver === this.state.userId) {
        this.setState(prevState => ({
          symptomsList: this.props.userDefinedSymptoms
            ? this.props.userDefinedSymptoms
            : [],
          treatmentList: this.props.listTherapie ? this.props.listTherapie : [],
          conditionList: this.props.userConditions
            ? this.props.userConditions
            : [],
          selectedTreatment:
            searchValue.treatment && searchValue.treatment.length > 0
              ? searchValue.treatment
              : [],
          selectedSymptom:
            searchValue.symptom && searchValue.symptom.length > 0
              ? searchValue.symptom
              : [],
          selectedUserCondition:
            searchValue.diagnosis && searchValue.diagnosis.length > 0
              ? searchValue.diagnosis
              : [],
          searchString: searchValue.searchString
            ? searchValue.searchString
            : '',
          dateRange: searchValue.dateRange
            ? searchValue.dateRange
            : prevState.dateRange,
          resourceType: searchValue.resourceType
            ? searchValue.resourceType
            : prevState.resourceType,
          peopleLike: searchValue.compareUser === 1 ? true : false,
          caringPeopleLike: searchValue.compareUser === 1 ? false : true,
          similarAge:
            searchValue.similarAge === 1,
          mySex: searchValue.mySex === 1,
          treatmentAll: searchValue.treatmentAll === 1 ? true : false,
          treatmentLastSix: searchValue.treatment6Months === 1 ? true : false,
          symptomAll: searchValue.symptomAll === 1 ? true : false,
          symptomLastSix: searchValue.symptom6Months === 1 ? true : false,
          userId: searchValue.userId ? searchValue.userId : '',
          birthday: this.props.user ? this.props.user.birthday : '',
          sex: this.props.user ? this.props.user.sex : '',
        }));
      } else {
        this.getUserDetails(
          searchValue.compareCaregiver,
          searchValue.treatment,
          searchValue.symptom,
          searchValue.diagnosis,
          searchValue.treatmentAll,
          searchValue.treatment6Months,
          searchValue.symptomAll,
          searchValue.symptom6Months,
        );
        this.setState(prevState => ({
          searchString: searchValue.searchString
            ? searchValue.searchString
            : '',
          dateRange: searchValue.dateRange
            ? searchValue.dateRange
            : prevState.dateRange,
          resourceType: searchValue.resourceType
            ? searchValue.resourceType
            : prevState.resourceType,
          peopleLike: searchValue.compareUser === 1 ? true : false,
          caringPeopleLike: searchValue.compareUser === 1 ? false : true,
          similarAge: searchValue.similarAge === 1,
          mySex: searchValue.mySex === 1,
        }));
      }
    } else {
      this.setState({
        peopleLike: true,
        caringPeopleLike: false,
        similarAge: false,
        mySex: false,
        symptomsList: this.props.userDefinedSymptoms
          ? this.props.userDefinedSymptoms
          : [],
        treatmentList: this.props.listTherapie ? this.props.listTherapie : [],
        conditionList: this.props.userConditions
          ? this.props.userConditions
          : [],
        selectedUserCondition: [],
        selectedTreatment: [],
        selectedSymptom: [],
        sharedAccessGivenToMe: this.props.sharedAccessDigest
          .sharedAccessGivenToMe
          ? this.props.sharedAccessDigest.sharedAccessGivenToMe
          : [],
        sharedAccessSummery: this.props.sharedAccessDigest?.sharedAccessUserSummaries
          ? this.props.sharedAccessDigest.sharedAccessUserSummaries
          : [],
        treatmentLastSix: true,
        treatmentAll: false,
        symptomLastSix: true,
        symptomAll: false,
        searchString: '',
        resourceType: 'All',
        dateRange: 'Anytime',
        searchWait: false,
        searchResult: [],
        searchCount: null,
        visibleSearchModal: false,
        selectedUser: 'Me',
        selectedId: this.props.user ? this.props.user.id : '',
        birthday: this.props.user ? this.props.user.birthday : '',
        sex: this.props.user ? this.props.user.sex : '',
        name: '',
      });
    }
    this.setState({
      saveDSelectedCriteria: value,
      error: false,
      errorDescription: '',
    });
  };

  onPressResetSearch = () => {
    this.props.resetSearch();
    this.props.navigation.setParams({healthLibraryTab: communityGroupTabPath.directory});
    pendoTrackEvent('HealthLibrary/@Directory');
    this.setState({
      search: false,
      searchResult: [],
      searchCount: null,
      peopleLike: true,
      caringPeopleLike: false,
      similarAge: false,
      mySex: false,
      selectedUserCondition: [],
      selectedTreatment: [],
      selectedSymptom: [],
      treatmentLastSix: true,
      treatmentAll: false,
      symptomLastSix: true,
      symptomAll: false,
      searchString: '',
      resourceType: 'All',
      dateRange: 'Anytime',
      symptomsList: this.props.userDefinedSymptoms
        ? this.props.userDefinedSymptoms
        : [],
      treatmentList: this.props.listTherapie ? this.props.listTherapie : [],
      conditionList: this.props.userConditions ? this.props.userConditions : [],
      userId: this.props.user ? this.props.user.id : '',
      birthday: this.props.user ? this.props.user.birthday : '',
      sex: this.props.user ? this.props.user.sex : '',
      selectedId: this.state.userId,
      selectedUser: 'Me',
      saveDSelectedCriteria: '',
      name: '',
      savedSearchList: this.setSavedSearch()
    });
  };

  onPressSaveCriteriaVisible = () => {
    this.setState({visibleSearchModal: true});
  };

  onPressSearch = () => {
    this.setState({searchWait: true});
    this.props.setSearchWait(true);
    let treatment = "";
    let symptom = "";
    let condition = "";
    let age = '';
    if (
      this.state.selectedTreatment &&
      this.state.selectedTreatment.length > 0
    ) {
      this.state.selectedTreatment.filter(res => {

        treatment = treatment + `${treatment !== "" ? '^^'+res.name : res.name}`;
        return treatment;

      });
    }
    if (this.state.selectedSymptom && this.state.selectedSymptom.length > 0) {
      this.state.selectedSymptom.filter(res => {
        symptom = symptom + `${symptom !== "" ? '^^'+res.name : res.name}`;
        return symptom;

      });
    }

    if (
      this.state.selectedUserCondition &&
      this.state.selectedUserCondition.length > 0
    ) {
      this.state.selectedUserCondition.filter(res => {
        condition = condition + `${condition !== "" ? '^^'+res.name : res.name}`;
        return condition;

      });
    }
    if (this.state.birthday) {
      let ageDifMs = Date.now() - this.state.birthday;
      let ageDate = new Date(ageDifMs);
      age = Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    let searchObject = {
      treatment: treatment,
      symptom: symptom,
      searchString: this.state.searchString,
      gender: this.state.mySex ? this.state.sex : null,
      dateRange: this.state.dateRange,
      peopleLikeMe: this.state.peopleLike,
      diagnosis: condition,
      age: this.state.similarAge && age ? age : null,
      resourceType: this.state.resourceType,
      // userId: this.props.user ? this.props.user.id : '',
    };

    const searchObjectExtraValues = {
      caringPeopleLike: this.state.caringPeopleLike,
      similarAge: this.state.similarAge,
      mySex: this.state.mySex,
      selectedTreatment: this.state.selectedTreatment,
      selectedUserCondition: this.state.selectedUserCondition ,
      selectedSymptom: this.state.selectedSymptom,
      selectedId: this.state.userId,
      selectedUser: this.state.selectedUser,
      name: this.state.name,
    }

    pendoTrackEvent('HealthLibrary > @Directory > Search');
    this.props.onhandleSearch(searchObject, (sucess, data) => {
      if (sucess) {
        this.props.navigation.setParams({healthLibraryTab: communityGroupTabPath.searchResults});
        pendoTrackEvent('HealthLibrary/@SearchResults');
        this.setState({
          searchResult: data,
          searchCount: data.length,
          searchWait: false,
          search: true,
        });
      } else {
        this.setState({
          searchWait: false,
        });
      }
      this.props.setSearchWait(false);
    }, searchObjectExtraValues );
  };

  getUserDetails = (
    userId,
    treatment,
    symptom,
    diagnosis,
    treatmentAll,
    treatment6Months,
    symptomAll,
    symptom6Months,
  ) => {
    this.setState({userDetailWait: true});
    if (userId) {
      axiosCommon.axiosGET(
        BASE_URL_COMMUNITY_GROUPS + 'getuserdetails',
        res => {
          if (res.type === 'SUCCESS') {
            if (res.responseDto.data) {
              let userDetails = {...res.responseDto.data};

              this.setState(
                {
                  symptomsList: userDetails.userSymptoms
                    ? userDetails.userSymptoms
                    : [],
                  treatmentList: userDetails.userTreatments
                    ? userDetails.userTreatments
                    : [],
                  conditionList: userDetails.userDiagnosis
                    ? userDetails.userDiagnosis
                    : [],
                  sex: userDetails.gender ? userDetails.gender : '',
                  selectedTreatment:
                    treatment && treatment.length > 0 ? treatment : [],
                  selectedSymptom: symptom && symptom.length > 0 ? symptom : [],
                  selectedUserCondition:
                    diagnosis && diagnosis.length > 0 ? diagnosis : [],
                  treatmentAll: treatmentAll === 1 ? true : false,
                  treatmentLastSix: treatment6Months === 1 ? true : false,
                  symptomAll: symptomAll === 1 ? true : false,
                  symptomLastSix: symptom6Months === 1 ? true : false,
                },
                () => {
                  if (userDetails.dateOfBirth) {
                    this.setState({
                      birthday: moment(
                        userDetails.dateOfBirth,
                        'YYYY-MM-DD HH:mm:ss',
                      ).format('x'),
                    });
                  }
                  this.setState({userDetailWait: false});
                },
              );
            }
          }
        },
        {userId: userId},
      );
    }
  };

  onPressPreviousSearch = () => {
    this.props.popPreviousSearchResult();
  };

  renderPeopleLikeDropDown = (title, isSelected) => {
    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    return (
      <View
        style={[
          styles.answerSingle,
          styles.answerMg,
          styles.flexAiCt,
        ]}>
        <View
          style={[
            styles.answerSingleText,
            styles.answerSingleMinHeight,
            styles.flexAiCt,
          ]}>
          <TouchableOpacity
            style={[
              styles.answerOptMg,
              styles.answerViewWrapper,
            ]}
            onPress={this.onPressOtherUser}
            disabled={this.state.userDetailWait}>
            <View style={[styles.optionWrapper]}>
              <Image
                style={styles.radioIcon}
                source={isSelected
                  ? require('../../../assets/icons/radio-selected.png')
                  : require('../../../assets/icons/radio-default.png')
                }
              />
            </View>

            <View
              style={[styles.answerTextWrapper]}>
              <Text style={[styles.textPrime]} allowFontScaling={false}>
                {title}
              </Text>
            </View>
          </TouchableOpacity>
        </View>

        {isSelected ? (
          <View
            style={stylesRes.answerPickerRes}
            dataSet={{media: ids.answerPickerRes}}>
            <View
              style={[styles.pickerAreaWrapper]}>
              <View
                style={[
                  stylesRes.dropArrowView,
                  stylesRes.dropArrowCent,
                ]}
                pointerEvents={'none'}>
                <View
                  style={stylesRes.dropArrowIcon}>
                  <Image
                    style={[styles.imgContain]}
                    source={require('../../../assets/icons/dropdown-arrow.png')}
                  />
                </View>
              </View>
              {this.peopleLikeDropDown()}
            </View>
          </View>
        ) : null}
      </View>
    )
  }

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const {hasNotch, journalUserId, timeZoneWarning, timeZone} = this.props;
    const {windowHeight, windowWidth, loading} = this.state;
    let tabHeight = 44;
    let scrollHeight = getScrollHeight(hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth, tabHeight);
    if (scrollHeight < 50) {
      scrollHeight = 50;
    }

    console.log('searchObject',this.props.searchObject);

    return (
      <React.Fragment>
        <View title="Community Search" style={[styles.tabContentSection]}>
          {this.isSearchOn() ? (
            <View style={[styles.scrollableArea, {height: scrollHeight}]}>
              <ResultsList
                onPressResetSearch={this.onPressResetSearch}
                onPressPreviousSearch={this.onPressPreviousSearch}
                onPressSaveCriteriaVisible={this.onPressSaveCriteriaVisible}
              />
            </View>
          ) : (
            loading ? (
              <View
                style={[
                  { minHeight: scrollHeight },
                  styles.flexJcCtAiCt
                ]}
              >
                <ActivityIndicator />
              </View>
            ) : (
            <View style={[styles.scrollableArea, {height: scrollHeight}]}>
              <KeyboardAwareScrollViewHoc>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <PageContainer smScreenFull={true}>
                      <View
                        style={[
                          stylesRes.tabContentFullArea,
                          stylesRes.contBotPdLg,
                        ]}
                        dataSet={{media: ids.contBotPdLg}}>
                        <View style={styles.secArea}>
                          <View
                            style={[styles.subSection, styles.colPdTopLtRt]}>
                            <View style={[styles.fieldRow]}>
                              <View style={[styles.pickerSelectionWrapper]}
                                    nativeID={'healthLibraryDirectorySavedSearch'}>
                                <Text
                                  style={[
                                    styles.textView,
                                    styles.textViewPicker,
                                  ]}
                                  allowFontScaling={false}
                                >
                                  <UIText
                                    style={[
                                      styles.textLabel,
                                      styles.bold,
                                      styles.textColorLight,
                                    ]}
                                    title="Saved Searches"
                                  />
                                </Text>
                                <View
                                  style={[
                                    stylesRes.dropArrowView,
                                    stylesRes.dropArrowTopLg,
                                  ]}
                                  pointerEvents={'none'}>
                                  <View style={stylesRes.dropArrowIcon}>
                                    <Image
                                      style={[styles.imgContain]}
                                      source={require('../../../assets/icons/dropdown-arrow.png')}
                                    />
                                  </View>
                                </View>
                                {this.saveSearchDropDown()}
                              </View>
                            </View>
                            <View style={[styles.fieldRow]}>
                              <TextInput
                                style={[
                                  styles.textField,
                                  styles.textFieldWhite,
                                  styles.textFieldSm,
                                ]}
                                allowFontScaling={false}
                                onChangeText={value => {
                                  this.setState({searchString: value});
                                }}
                                value={this.state.searchString}
                                underlineColorAndroid="transparent"
                                placeholder="Enter keywords or tags. Leave blank to view all resources."
                              />
                            </View>

                            <View style={[styles.fieldRow]}>
                              <View style={[styles.fieldDescCom]}>
                                <Text style={[styles.textPrime]} allowFontScaling={false}>
                                  Added or updated since:
                                </Text>
                              </View>
                              <View style={[styles.pickerSelectionWrapper]}>
                                <Text
                                  style={[
                                    styles.textView,
                                    styles.textViewPicker,
                                  ]}
                                  allowFontScaling={false}
                                >
                                  <UIText
                                    style={[
                                      styles.textLabel,
                                      styles.bold,
                                      styles.textColorLight,
                                    ]}
                                    title="Select Date Range:"
                                  />
                                </Text>
                                <View
                                  style={[
                                    stylesRes.dropArrowView,
                                    stylesRes.dropArrowTopLg,
                                  ]}
                                  pointerEvents={'none'}>
                                  <View style={stylesRes.dropArrowIcon}>
                                    <Image
                                      style={[styles.imgContain]}
                                      source={require('../../../assets/icons/dropdown-arrow.png')}
                                    />
                                  </View>
                                </View>
                                <Picker
                                  items={[
                                    {
                                      key: '0',
                                      label: 'Anytime',
                                      value: 'Anytime',
                                    },
                                    {
                                      key: '1',
                                      label: 'Last week',
                                      value: 'LastWeek',
                                    },
                                    {
                                      key: '2',
                                      label: 'Last month',
                                      value: 'LastMonth',
                                    },
                                    {
                                      key: '3',
                                      label: 'Last 6 months',
                                      value: 'Last6Month',
                                    },
                                    {
                                      key: '4',
                                      label: 'Last year',
                                      value: 'LastYear',
                                    },
                                  ]}
                                  style={[
                                    styles.pickerAreaField,
                                    styles.selectionPicker,
                                  ]}
                                  selectedValue={this.state.dateRange}
                                  onValueChange={value => {
                                    this.setState({dateRange: value});
                                  }}
                                />
                              </View>
                            </View>

                            <View style={[styles.fieldRow]}>
                              <View style={[styles.pickerSelectionWrapper]}>
                                <Text
                                  style={[
                                    styles.textView,
                                    styles.textViewPicker,
                                  ]}
                                  allowFontScaling={false}
                                >
                                  <UIText
                                    style={[
                                      styles.textLabel,
                                      styles.bold,
                                      styles.textColorLight,
                                    ]}
                                    title="Resource type"
                                  />
                                </Text>
                                <View
                                  style={[
                                    stylesRes.dropArrowView,
                                    stylesRes.dropArrowTopLg,
                                  ]}
                                  pointerEvents={'none'}>
                                  <View style={stylesRes.dropArrowIcon}>
                                    <Image
                                      style={[styles.imgContain]}
                                      source={require('../../../assets/icons/dropdown-arrow.png')}
                                    />
                                  </View>
                                </View>
                                <Picker
                                  items={[
                                    {
                                      key: '0',
                                      label: 'All',
                                      value: 'All',
                                    },
                                    {
                                      key: '1',
                                      label: 'Document',
                                      value: 'Document',
                                    },
                                    {
                                      key: '2',
                                      label: 'WebResources',
                                      value: 'WebResources',
                                    },
                                    {
                                      key: '3',
                                      label: 'Twitter',
                                      value: 'Twitter',
                                    },
                                    {
                                      key: '4',
                                      label: 'Facebook',
                                      value: 'Facebook',
                                    },
                                  ]}
                                  style={[
                                    styles.pickerAreaField,
                                    styles.textPickerPdSmCom,
                                  ]}
                                  selectedValue={this.state.resourceType}
                                  onValueChange={value => {
                                    this.setState({resourceType: value});
                                  }}
                                />
                              </View>
                            </View>

                            <Collapsible
                              title="Filter resources by what others have added to their own library"
                              contentVisibility={this.state.showMoreFilters}
                              handleOnPressCollapsible={() => this.onPressCollapsibleMoreFilters()}
                              topBorder={false}
                              botBorder={false}
                              titleSm={true}
                            >
                              <View style={[styles.questSectionWrapper]}>
                                <View style={[styles.questionAnswerBlock]}>
                                  <View
                                    style={[
                                      styles.questionWrapper,
                                      styles.questMgLg,
                                    ]}>
                                    <Text
                                      style={[
                                        styles.textPrime, styles.textEm,
                                      ]}
                                      allowFontScaling={false}
                                    >
                                       I am looking for resources added by others who are:
                                    </Text>
                                  </View>
                                  <View style={[styles.answerWrapper]}>
                                  {this.renderPeopleLikeDropDown('People like:', this.state.peopleLike)}
                                  {this.renderPeopleLikeDropDown('Caring for people like:', this.state.caringPeopleLike)}
                                  </View>
                                </View>

                                <View style={[styles.questionAnswerBlock]}>
                                  <View
                                    style={[
                                      styles.questionWrapper,
                                      styles.noBotMg,
                                    ]}>
                                    <Text
                                      style={[
                                        styles.textPrime, styles.textEm,
                                      ]}
                                      allowFontScaling={false}
                                    >
                                      I want to refine the search with the following criteria:
                                    </Text>
                                  </View>

                                  <View style={[styles.answerWrapper]}>
                                    <View style={[styles.answerSingle]}>
                                      <TouchableOpacity
                                        style={[
                                          styles.answerOptMg,
                                          styles.answerViewWrapper,
                                        ]}
                                        onPress={this.onPressSimilarAge}>
                                        <View style={[styles.optionWrapper]}>
                                          {this.state.similarAge ? (
                                            <Image
                                              style={styles.radioIcon}
                                              source={require('../../../assets/toggleTick/check-tick.png')}
                                            />
                                          ) : (
                                            <Image
                                              style={styles.radioIcon}
                                              source={require('../../../assets/toggleTick/check-untick.png')}
                                            />
                                          )}
                                        </View>
                                        <View style={[styles.answerTextWrapper]}>
                                          <Text style={[styles.textPrime]} allowFontScaling={false}>
                                            Similar age
                                            to {this.state.selectedUser === 'Me' ? 'me' : this.state.selectedUser}
                                          </Text>
                                        </View>
                                      </TouchableOpacity>
                                    </View>
                                    <View style={[styles.answerSingle]}>
                                      <TouchableOpacity
                                        style={[
                                          styles.answerOptMg,
                                          styles.answerViewWrapper,
                                        ]}
                                        onPress={this.onPressMySex}>
                                        <View style={[styles.optionWrapper]}>
                                          {this.state.mySex ? (
                                            <Image
                                              style={styles.radioIcon}
                                              source={require('../../../assets/toggleTick/check-tick.png')}
                                            />
                                          ) : (
                                            <Image
                                              style={styles.radioIcon}
                                              source={require('../../../assets/toggleTick/check-untick.png')}
                                            />
                                          )}
                                        </View>

                                        <View style={[styles.answerTextWrapper]}>
                                          <Text style={[styles.textPrime]} allowFontScaling={false}>
                                            Same sex
                                            as {this.state.selectedUser === 'Me' ? 'me' : this.state.selectedUser}
                                          </Text>
                                        </View>
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                </View>
                              </View>
                              {this.state.userDetailWait ? (
                                <ActivityIndicator size="small" color="#FF8300"/>
                              ) : (
                                <View>
                                  <View style={[styles.questionAnswerBlock]}>
                                    <View
                                      style={[
                                        styles.questionWrapper,
                                        styles.flexRow,
                                        styles.noBotMg,
                                      ]}>
                                      <Text
                                        style={
                                          this.state.validCondition
                                            ? [styles.textPrime, styles.textEm]
                                            : [styles.textPrime, styles.textEm, styles.textRed]
                                        }
                                        allowFontScaling={false}
                                      >
                                        {!Boolean(this.state.caringPeopleLike) ? "Who also have one or more of these conditions:" : "Who are caring for others with one or more of these conditions:"}
                                      </Text>
                                      <View
                                        style={[
                                          styles.answerSingleViewAll,
                                          styles.flexRow,
                                        ]}
                                      />
                                    </View>

                                    {this.Diagnosed()}
                                  </View>

                                  <View style={[styles.questionAnswerBlock]}>
                                    <View
                                      style={[
                                        styles.questionWrapper,
                                        styles.flexRow,
                                        styles.noBotMg,
                                        styles.contColFul
                                      ]}>
                                      <Text
                                        style={[
                                          styles.textPrime,
                                          styles.textEm,
                                          windowWidth < UI_BREAK_FOUR_COUNT && styles.contCol,
                                          !this.state.validTreatment && styles.textRed
                                        ]}
                                        allowFontScaling={false}
                                      >
                                        One or more of these treatments:
                                      </Text>
                                      <View
                                        style={[
                                          styles.answerSingleViewAll,
                                          styles.flexRow,
                                          windowWidth < UI_BREAK_FOUR_COUNT && styles.contCol
                                        ]}>
                                        <View
                                          style={[
                                            styles.answerSingleViewCom,
                                            styles.flexRow,
                                          ]}>
                                          <TouchableOpacity
                                            style={[styles.answerViewWrapper]}
                                            onPress={
                                              this.onPressTreatmentTimeFilter
                                            }>
                                            <View style={[styles.optionWrapper]}>
                                              {this.state.treatmentLastSix ? (
                                                <Image
                                                  style={styles.radioIcon}
                                                  source={require('../../../assets/icons/radio-selected.png')}
                                                />
                                              ) : (
                                                <Image
                                                  style={styles.radioIcon}
                                                  source={require('../../../assets/icons/radio-default.png')}
                                                />
                                              )}
                                            </View>

                                            <View
                                              style={[styles.answerTextWrapper]}>
                                              <Text style={[styles.textPrime]} allowFontScaling={false}>
                                                Last 6 months
                                              </Text>
                                            </View>
                                          </TouchableOpacity>
                                        </View>
                                        <View
                                          style={[
                                            styles.answerSingleViewCom,
                                            styles.flexRow,
                                          ]}>
                                          <TouchableOpacity
                                            style={[styles.answerViewWrapper]}
                                            onPress={
                                              this.onPressTreatmentTimeFilter
                                            }>
                                            <View style={[styles.optionWrapper]}>
                                              {this.state.treatmentAll ? (
                                                <Image
                                                  style={styles.radioIcon}
                                                  source={require('../../../assets/icons/radio-selected.png')}
                                                />
                                              ) : (
                                                <Image
                                                  style={styles.radioIcon}
                                                  source={require('../../../assets/icons/radio-default.png')}
                                                />
                                              )}
                                            </View>

                                            <View
                                              style={[styles.answerTextWrapper]}>
                                              <Text style={[styles.textPrime]} allowFontScaling={false}>
                                                All
                                              </Text>
                                            </View>
                                          </TouchableOpacity>
                                        </View>
                                      </View>
                                    </View>
                                    {this.treatmentItems()}
                                  </View>

                                  <View style={[styles.questionAnswerBlock]}>
                                    <View
                                      style={[
                                        styles.questionWrapper,
                                        styles.flexRow,
                                        styles.noBotMg,
                                        styles.contColFul
                                      ]}>
                                      <Text
                                        style={[
                                          styles.textPrime,
                                          styles.textEm,
                                          windowWidth < 400 && styles.contCol,
                                          !this.state.validSymptom && styles.textRed
                                        ]}
                                        allowFontScaling={false}
                                      >
                                        One or more of these symptoms:
                                      </Text>
                                      <View
                                        style={[
                                          styles.answerSingleViewAll,
                                          styles.flexRow,
                                          windowWidth < 400 && styles.contCol
                                        ]}>
                                        <View
                                          style={[
                                            styles.answerSingleViewCom,
                                            styles.flexRow,
                                          ]}>
                                          <TouchableOpacity
                                            style={[styles.answerViewWrapper]}
                                            onPress={
                                              this.onPressSymptomTimeFilter
                                            }>
                                            <View style={[styles.optionWrapper]}>
                                              {this.state.symptomLastSix ? (
                                                <Image
                                                  style={styles.radioIcon}
                                                  source={require('../../../assets/icons/radio-selected.png')}
                                                />
                                              ) : (
                                                <Image
                                                  style={styles.radioIcon}
                                                  source={require('../../../assets/icons/radio-default.png')}
                                                />
                                              )}
                                            </View>
                                            <View
                                              style={[styles.answerTextWrapper]}>
                                              <Text style={[styles.textPrime]} allowFontScaling={false}>
                                                Last 6 months
                                              </Text>
                                            </View>
                                          </TouchableOpacity>
                                        </View>
                                        <View
                                          style={[
                                            styles.answerSingleViewCom,
                                            styles.flexRow,
                                          ]}>
                                          <TouchableOpacity
                                            style={[styles.answerViewWrapper]}
                                            onPress={
                                              this.onPressSymptomTimeFilter
                                            }>
                                            <View style={[styles.optionWrapper]}>
                                              {this.state.symptomAll ? (
                                                <Image
                                                  style={styles.radioIcon}
                                                  source={require('../../../assets/icons/radio-selected.png')}
                                                />
                                              ) : (
                                                <Image
                                                  style={styles.radioIcon}
                                                  source={require('../../../assets/icons/radio-default.png')}
                                                />
                                              )}
                                            </View>

                                            <View
                                              style={[styles.answerTextWrapper]}>
                                              <Text style={[styles.textPrime]} allowFontScaling={false}>
                                                All
                                              </Text>
                                            </View>
                                          </TouchableOpacity>
                                        </View>
                                      </View>
                                    </View>

                                    {this.symptomItems()}
                                  </View>
                                </View>
                              )}
                            </Collapsible>
                            <View style={styles.gridRow}>
                              <View style={styles.gridColFull}>
                                {Boolean(this.state.error) && (
                                  <View style={styles.notifyBlock}>
                                    <View style={styles.notifyDetails}>
                                      <View style={styles.notifyWarning}>
                                        <Image
                                          style={styles.iconWarning}
                                          source={require('../../../assets/icons/warning.png')}
                                        />
                                      </View>
                                      <UIText
                                        style={styles.notifyText}
                                        title={this.state.errorDescription}
                                      />
                                    </View>
                                  </View>
                                )}
                              </View>
                            </View>

                            <View style={[styles.btnBlock, styles.btnMgSm]}>
                              <TouchableOpacity onPress={this.onPressSearch} disabled={this.state.searchWait}>
                                <View
                                  style={[styles.newResourceHealthLbBtn, styles.btnSingleCent]}
                                  nativeID={'healthLibraryDirectorySearch'}>
                                  {this.state.searchWait && (
                                    <View style={styles.actIndCenterLg}>
                                      <ActivityIndicator size="small"/>
                                    </View>
                                  )}
                                  <Text style={[styles.textPrimeBold, styles.textWhite]}
                                        allowFontScaling={false}>Search</Text>
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </PageContainer>
                  </View>
                </View>
              </KeyboardAwareScrollViewHoc>
            </View>
            )
          )}
        </View>
        <Modal
          visible={this.state.visibleSearchModal}
          onRequestClose={() => {
            this.setState({visibleSearchModal: false});
          }}
          onBackdropPress={() => {
            this.setState({visibleSearchModal: false});
          }}>
          <ResoureSearchModal
            peopleLike={this.state.peopleLike}
            caringPeopleLike={this.state.caringPeopleLike}
            similarAge={this.state.similarAge}
            mySex={this.state.mySex}
            symptomsList={this.state.symptomsList}
            treatmentList={this.state.treatmentList}
            conditionList={this.state.conditionList}
            selectedUserCondition={this.state.selectedUserCondition}
            selectedTreatment={this.state.selectedTreatment}
            selectedSymptom={this.state.selectedSymptom}
            sharedAccessGivenToMe={this.state.sharedAccessGivenToMe}
            sharedAccessSummery={this.state.sharedAccessSummery}
            treatmentLastSix={this.state.treatmentLastSix}
            treatmentAll={this.state.treatmentAll}
            symptomLastSix={this.state.symptomLastSix}
            symptomAll={this.state.symptomAll}
            searchString={this.state.searchString}
            resourceType={this.state.resourceType}
            dateRange={this.state.dateRange}
            searchWait={this.state.searchWait}
            savedSearchList={this.state.savedSearchList}
            searchResult={this.state.searchResult}
            searchCount={this.state.searchCount}
            search={this.isSearchOn()}
            visibleSearchModal={this.state.peopleLike}
            selectedUser={this.state.selectedUser}
            selectedId={this.state.selectedId}
            user={this.state.userId}
            name={this.state.name}
            onPressClose={() => {
              this.setState({visibleSearchModal: false});
            }}
            close={value => {
              if (value === true) {
                this.props.resetSearch();
                this.setState(
                  {
                    search: false,
                    searchResult: [],
                    searchCount: null,
                    peopleLike: true,
                    caringPeopleLike: false,
                    similarAge: false,
                    mySex: false,
                    selectedUserCondition: [],
                    selectedTreatment: [],
                    selectedSymptom: [],
                    treatmentLastSix: true,
                    treatmentAll: false,
                    symptomLastSix: true,
                    symptomAll: false,
                    searchString: '',
                    resourceType: 'All',
                    dateRange: 'Anytime',
                    name: '',
                    visibleSearchModal: false,
                    userId: this.props.user ? this.props.user.id : '',
                    birthday: this.props.user ? this.props.user.birthday : '',
                    sex: this.props.user ? this.props.user.sex : '',
                    selectedUser: 'Me',
                    selectedId: this.props.user ? this.props.user.id : '',
                    symptomsList: this.props.userDefinedSymptoms
                      ? this.props.userDefinedSymptoms
                      : [],
                    treatmentList: this.props.listTherapie
                      ? this.props.listTherapie
                      : [],
                    conditionList: this.props.userConditions
                      ? this.props.userConditions
                      : [],
                    saveDSelectedCriteria: '',
                  },
                  () => {
                    if (this.state.userId) {
                      axiosCommon.axiosGET(
                        BASE_URL_COMMUNITY_GROUPS + 'searchcriteria',
                        res => {
                          if (res.type === 'SUCCESS') {
                            this.setState({
                                savedSearchList: res.responseDto.data,
                              },
                              () => {
                                initialValue = this.state;
                                this.forceUpdate();
                              });
                          }
                        },
                        {userId: this.state.userId},
                      );
                    } else {
                      initialValue = this.state;
                      this.forceUpdate();
                    }
                  },
                );
              }
              this.setState({visibleSearchModal: false});
            }}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    showLearnHow: state.uistate.showLearnHow,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    userConditions: state.routines.userConditions,
    userDefinedSymptoms: state.routines.symptomsDigest.userSymptoms,
    listTherapie: state.routines.treatments,
    user: state.routines.user,
    sharedAccessDigest: state.routines.sharedAccessDigest,
    journalUserId: state.uistate.journalUserId,
    searchResultStack: state.communityGroups.searchResultStack,
    hasNotch: state.uistate.hasNotch,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onhandleSearch: (searchObject, callBack, searchObjectExtraData) =>
      dispatch(actions.onhandleSearch(searchObject, callBack, searchObjectExtraData)),
    popPreviousSearchResult: () => {
      dispatch(actions.popPreviousSearchResult());
    },
    resetSearch: () => {
      dispatch(actions.resetCommunitySearch());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Directory);
