import axios from './axios-config-common-community-groups';
import {fileDownload} from './common';

export const axiosGET = (url, callbackFunc, data) => {
  let body = {};
  if (data) {
    body = data;
  }
  axios
    .get(url, {params: body})
    .then(response => {
      callbackFunc({type: 'SUCCESS', responseDto: response});
    })
    .catch(error => {
      if (error.response === undefined) {
        callbackFunc({type: 'ERROR', errorDto: {data: 'NETWORK_ERROR'}});
      } else if (error.response.status === 404) {
        callbackFunc({type: 'NOT FOUND', notFound: error.response});
      } else {
        callbackFunc({type: 'ERROR', errorDto: error.response});
      }
    });
};

export const axiosPOST = (url, callbackFunc, data) => {
  axios
    .post(url, data)
    .then(response => {
      callbackFunc({type: 'SUCCESS', responseDto: response});
    })
    .catch(error => {
      if (error.response === undefined) {
        callbackFunc({type: 'ERROR', errorDto: {data: 'NETWORK_ERROR'}});
      } else if (error.response.status === 409) {
        callbackFunc({type: 'CONFLICT', conflictDto: error.response});
      } else {
        callbackFunc({type: 'ERROR', errorDto: error.response});
      }
    });
};

export const axiosPUT = (url, callbackFunc, data) => {
  axios
    .put(url, data)
    .then(response => {
      callbackFunc({type: 'SUCCESS', responseDto: response});
    })
    .catch(error => {
      if (error.response === undefined) {
        callbackFunc({type: 'ERROR', errorDto: {data: 'NETWORK_ERROR'}});
      } else if (error.response.status === 409) {
        callbackFunc({type: 'CONFLICT', conflictDto: error.response});
      } else {
        callbackFunc({type: 'ERROR', errorDto: error.response});
      }
    });
};

export const axiosDELETE = (url, calbackFunc, payload) => {
  axios
    .delete(url, {data: payload})
    .then(response => {
      calbackFunc({type: 'SUCCESS', responseDto: response});
    })
    .catch(error => {
      if (error.response === undefined) {
        calbackFunc({type: 'ERROR', errorDto: {data: 'NETWORK_ERROR'}});
      } else {
        if (error.response.status === 403) {
          calbackFunc({type: 'ERROR', errorDto: 'SESSION_TIME_OUT'});
        } else {
          calbackFunc({type: 'ERROR', errorDto: error.response});
        }
      }
    });
};

export const axiosDownload = (header, callBack) => {
  setTimeout(() => {
    const response = {
      file: header,
    };
    window.location.href = response.file;
  }, 100);
  callBack();
};

export const actualDownload = async (fileName, url, callBack) => {
  fileDownload(fileName, url, callBack, true);
};
