import React, { Component } from "react";
import "./cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import UIText from "../../components/Text/text";
import { View, Text, TouchableOpacity, ScrollView } from "react-native";

class ImageCropper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      cropResult: null
    };
  }

  base64ImageToBlob = str => {
    // extract content type and base64 payload from original string
    let pos = str.indexOf(";base64,");
    let type = str.substring(5, pos);
    let b64 = str.substr(pos + 8);

    // decode base64
    let imageContent = atob(b64);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    let buffer = new ArrayBuffer(imageContent.length);
    let view = new Uint8Array(buffer);

    // fill the view, using the decoded base64
    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }

    // convert ArrayBuffer to Blob
    let blob = new Blob([buffer], { type: type });
    return blob;
  };

  cropImage = () => {
    if (typeof this.cropper.getCroppedCanvas() === "undefined") {
      return;
    }

    let cropResult = this.cropper
      .getCroppedCanvas()
      .toDataURL("image/png", 0.8);
    let cropResult2 = this.cropper
      .getCroppedCanvas()
      .toDataURL("image/png", 0.8);
    let base64ImageToBlob = this.base64ImageToBlob(cropResult2);

    if (cropResult) {
      this.props.onCropImage(cropResult, base64ImageToBlob);
    } else {
    }
  };

  retakePhoto = () => {
    this.props.onRetakePhoto(true);
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    let positionStatus = "absolute";
    return (
      <React.Fragment>
        <ScrollView style={styles.scrollViewArea}>
          <View style={[styles.gridSection]}>
            <View style={[stylesRes.contentSectionMax]} dataSet={{media: ids.contentSectionMax}}>
              <View style={[stylesRes.contentSectionFull]}>
                <View style={[styles.gridRow]}>
                  <View style={[styles.gridColFull]}>
                    <View
                      style={[
                        stylesRes.scanFullArea,
                        { height: this.props.maxContHeight }
                      ]}
                    >
                      <View style={[stylesRes.frameArea, stylesRes.frameAreaImg]}>
                        <View style={[stylesRes.colPdTopLtRtCom]} dataSet={{media: ids.colPdTopLtRtCom}}>
                          <View style={[styles.frameAreaTop]}>
                            <View
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto"
                              }}
                            >
                              <Cropper
                                style={{
                                  width: this.props.scanWidth,
                                  height: this.props.scanHeight
                                }}
                                guides={false}
                                src={this.props.imageUrl}
                                ref={cropper => {
                                  this.cropper = cropper;
                                }}
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        <View
          style={[
            stylesRes.frameArea,
            stylesRes.frameAreaContView,
            { position: positionStatus }
          ]}
        >
          <View style={[styles.colPdLtRt]}>
            <View style={[styles.frameAreaBot]}>
              <View style={[stylesRes.frameViewBot]}>
                {this.props.retake ? (
                  <React.Fragment>
                    <View style={[stylesRes.frameViewCom]}>
                      <View style={[styles.btnArea, styles.btnRowMgSm]}>
                        <View style={[styles.btnBlock, styles.noMgTop]}>
                          <TouchableOpacity
                            onPress={() => {
                              this.props.contactCamera(true);
                            }}
                            style={[styles.submit]}
                            underlayColor="rgba(0, 153, 168, 0.8)"
                          >
                            <View style={styles.btnSingleView}>
                              <View
                                style={[styles.btnSingle, styles.btnSingleCent]}
                              >
                                <UIText
                                  style={styles.submitText}
                                  title="Enter Manually"
                                />
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>

                    <View style={[stylesRes.frameViewBtn]}>
                      <TouchableOpacity>
                        <button
                          onClick={this.retakePhoto}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            cursor: "poniter"
                          }}
                        >
                          <View style={[stylesRes.frameBtnFull]}>
                            <View style={[stylesRes.frameBtn]}>
                              <Text
                                style={[
                                  styles.textPrimeExSmBold,
                                  styles.textAlignCenter,
                                  styles.textBlack
                                ]}
                                allowFontScaling={false}
                              >
                                Retake
                              </Text>
                            </View>
                          </View>
                        </button>
                      </TouchableOpacity>
                    </View>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <View style={[stylesRes.frameViewBtn]}>
                      <TouchableOpacity>
                        <button
                          onClick={this.cropImage}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            cursor: "poniter"
                          }}
                        >
                          <View style={[stylesRes.frameBtnFull]}>
                            <View style={[stylesRes.frameBtn]}>
                              <Text
                                style={[
                                  styles.textPrimeExSmBold,
                                  styles.textAlignCenter,
                                  styles.textBlack
                                ]}
                                allowFontScaling={false}
                              >
                                {/* Scan */}
                                Save
                              </Text>
                            </View>
                          </View>
                        </button>
                      </TouchableOpacity>
                    </View>
                  </React.Fragment>
                )}
                <View
                  style={[stylesRes.frameViewText, stylesRes.frameTextMinHeight]}
                >
                  <View style={[stylesRes.frameTextFull]}>
                    {this.props.message ? (
                      <View style={[stylesRes.frameTextBtn]}>
                        <Text
                          style={[
                            styles.textPrimeBold,
                            styles.textAlignCenter,
                            styles.textWhite
                          ]}
                          allowFontScaling={false}
                        >
                          {this.props.message}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </React.Fragment>
    );
  }
}
export default (ImageCropper);
