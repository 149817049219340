import React, { Component } from 'react';
import _ from "lodash";
import { View, Text, Dimensions } from 'react-native';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import moment from 'moment';
import styles from '../../../../Css';

class SurveyTableGraph extends Component {
    createChart = (dataSet) => {
        const { tableScores, id, yaxisMin, yaxisMax } = dataSet;
        am4core.useTheme(am4themes_animated);
        let chart = am4core.create("chartdiv" + id, am4charts.XYChart);
        chart.logo.disabled = true;
        chart.maskBullets = false;

        // Add data converting x value from milisconds to date.
        chart.data = tableScores.map(value => ({
            x: new Date(value.completedDate),
            y: _.round(value.score, 1)
        }));
        const hasSameDateValues = this.checkSameDateValuesExistOrNot(tableScores);

        // Create axes
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        //make sure to only display days, not minutes or other scales
        dateAxis.dateFormats.setKey("day", "dd/MM/YY");
        if (!Boolean(hasSameDateValues)) {
            dateAxis.dateFormats.setKey("minute", " ");
            dateAxis.dateFormats.setKey("hour", " ");
            dateAxis.periodChangeDateFormats.setKey("day", "dd/MM/YY"); 
       }
        dateAxis.renderer.grid.template.location = 0;

        dateAxis.renderer.minGridDistance = 50;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.labels.template.fill = am4core.color("#000");
        dateAxis.renderer.line.strokeOpacity = 1;
        dateAxis.renderer.labels.template.rotation = 45; // rotate labels by 45 degrees
        //date Axis tooltip
        dateAxis.fullWidthTooltip = true;
        if (!Boolean(hasSameDateValues)) {
            dateAxis.tooltipDateFormat = "MMM dd";
        } else {
            dateAxis.tooltipDateFormat = "MMM dd, h:mm a";
        }
        dateAxis.tooltip.background.fill = am4core.color("#3b5998");
        dateAxis.tooltip.background.strokeWidth = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.labels.template.fill = am4core.color("#000");
        valueAxis.renderer.line.strokeOpacity = 1;
        // Change grid line to a dotted line
        valueAxis.renderer.grid.template.strokeDasharray = "10,10";
        valueAxis.cursorTooltipEnabled = false;
        //Set value(y) Axis min/max values
        valueAxis.min = yaxisMin;
        valueAxis.max = yaxisMax;

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.stroke = am4core.color("#3b5998");
        chart.cursor.lineX.strokeWidth = 1;
        chart.cursor.lineX.strokeOpacity = 1;
        chart.cursor.lineX.strokeDasharray = "";
        chart.cursor.lineY.disabled = true;

        // Create series
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "y";
        series.dataFields.dateX = "x";
        series.strokeWidth = 2;
        series.stroke = am4core.color('#000');

        //Tooltips
        series.tooltipText = '{valueY}';
        series.tooltip.autoTextColor = false;
        series.tooltip.label.fill = am4core.color("#ffffff");
        series.showOnInit = true;
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#3b5998");

        // Make bullets grow on hover
        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 2;
        bullet.circle.radius = 4;
        bullet.circle.fill = am4core.color("#000");
    }

    checkSameDateValuesExistOrNot = (values) => {
        let arr = [];
        values.map(val =>
            arr.push(moment(val.completedDate).format('DD/MM/YY'))
        )
        return arr.filter((item, index) => arr.indexOf(item) !== index).length > 0;
    }

    componentDidMount() {
        const { dataSet } = this.props;
        const { id } = dataSet;
        this.createChart(dataSet);
        const { width } = Dimensions.get('window');
        const chartContainer = document.getElementById(`chartdiv${id}`);
        chartContainer.style.height = `${Math.min(width, 300)}px`; // Set max height to 500 pixels
    }

    componentDidUpdate(prevProps) {
        const { dataSet } = this.props;
        const { tableScores, id } = dataSet;
        if (prevProps.dataSet.id !== id || prevProps.dataSet.tableScores !== tableScores) {
            this.createChart(dataSet);
        }
    }

    render() {
        const { index, total, dataSet } = this.props;
        const { subTitle, score, id } = dataSet;
        return (
            <>
                <View style={[styles.flexRow, styles.flexJcCtAiCt, styles.singleItemWrapperFull, styles.borderSurveyGraphHeader]}>
                    <View style={[styles.flexAiFs, styles.fieldColThreeFourth, styles.colPdTopBot, styles.ltPdMd, styles.borderSurveyGraphHeader, styles.borderSurveyGraphHeaderRight]}>
                        <Text style={styles.textPrime} numberOfLines={1}>{`${subTitle}`}</Text>
                    </View>
                    <View style={[styles.flexAiCt, styles.fieldColOneFourth, styles.colPdTopBot]}>
                        <Text style={styles.textPrime}>{`${score?.toFixed(1)}`}</Text>
                    </View>
                </View>
                <View style={[styles.flexCom, styles.sortingPdSm, styles.borderBtn, styles.topBorderNone, index < total - 1 && styles.bottomBorderNone, { height: 250 }]}>
                    <div id={"chartdiv" + id} style={{ width: "100%" }} />
                </View>
            </>
        );
    }
}

export default SurveyTableGraph;
