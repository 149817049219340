import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import styles from "../../Css";

const routineModalCommonContent = (props) => {
  const {
    messageTitle,
    displayCrown,
    touchableOnPress
  } = props;
  return (
    <View
      style={[
        styles.popMessageContView,
        styles.comSpSm,
        styles.contentWrapperPd,
      ]}
    >
      <View style={[styles.popMessageTitleView, styles.comSp]}>
        <Text style={[styles.textTitleMdBold, styles.textAlignCenter]} allowFontScaling={false}>
          {messageTitle}
        </Text>
      </View>
      {Boolean(displayCrown) &&
        <>
        <View style={styles.popMessageDescView}>
          <Text style={[styles.textPrime]} allowFontScaling={false}>
            You have reached your limit of 2 free graphs! Upgrade to Premium to create a graph.
          </Text>
        </View>
        {/* Button start */}
        <View style={styles.popMessageBtnView} nativeID={'upgradeBtn'}>
          <View style={[styles.btnViewSection, styles.btnBlock]}>
            <TouchableOpacity 
              onPressIn={touchableOnPress}
              style={[
                styles.submitSub,
                styles.submitBgOrange,
                styles.submitSubMin,
              ]}
            >
              <View style={[styles.btnSingle, styles.btnSingleCent]}>
                <Text style={[styles.textPrimeBold, styles.textWhite]} allowFontScaling={false}>
                  Upgrade
                </Text>
                <View style={styles.mgLtSm}>
                  <Image
                    style={[styles.premiumIconSm]}
                    source={require("../../assets/icons/premium-main.png")}
                  />
                </View>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        {/* Button end */}
        </>
      }
    </View>
  );
};

export default (routineModalCommonContent);
