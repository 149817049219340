import React, {Component} from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Notification from '../../components/Notification/Notification';
import Header from '../../components/Header/header';
import styles from '../../Css';
import {
  DASHBOARD,
  CONTACTS,
  JOURNALS,
  ROUTINES,
  MEDICATIONS,
} from '../../navigation/path';
import { pendoTrackEvent } from '../../util/pendoConfig';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
class NotificationScreen extends Component {
  state = {
    visibleModal: null,
  };

  componentDidMount() {
    pendoTrackEvent('Notifications@');
  }

  render() {
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          //  disableNotification={true}
          handleOnPressBackButton={() => {
            let from;
            if (
              this.props.route?.params &&
              this.props.route?.params.from
            ) {
              from = this.props.route?.params.from;
            }
            if (from === CONTACTS.addContactPath || from === CONTACTS.editContactPath) {
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: CONTACTS.path }],
              });
            } else if (from === JOURNALS.addJournalPath) {
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: JOURNALS.path }],
              });
            } else if (from === ROUTINES.addRoutineTemplatePath || from === ROUTINES.editRoutineTemplatePath) {
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: ROUTINES.path }],
              });
            } else if (from === MEDICATIONS.addTherapiePath || from === MEDICATIONS.editTherapiePath) {
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: MEDICATIONS.path }],
              });
            } else {
              this.props.navigation.pop();
            }
          }}
          index={DASHBOARD.index}
          navigation={this.props.navigation}
          route={this.props.route}
          isFromNotification={true}
        />
        <SpaceBar />
        <Notification navigation={this.props.navigation} />
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorDescription: state.auth.errorDescription,
    error: state.auth.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (emailAddress, password, callBack) =>
      dispatch(actions.auth(emailAddress, password, callBack)),
    onFetachAllJournals: callBack =>
      dispatch(actions.fetchAllJournalEntries(callBack)),
    onSaveQuestionToAttach: question =>
      dispatch(actions.saveQuestionToAttach(question)),
    onLoginButtonPress: () => dispatch(actions.loginButtonPress()),
    onWindowResize: (windowHeight, windowWidth) =>
      dispatch(actions.windowResize(windowHeight, windowWidth)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationScreen);
