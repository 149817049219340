import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import * as comTitles from './titles';
import PageLayoutSignup from '../../components/PageLayout/pageLayoutSignup';
import LogoSetUp from '../../components/LogoSetUp/LogoSetUp';
import Warning from '../../components/Warning/warning';
import PrimaryButton from '../../components/Button/primaryButton';
import InputField from '../../components/TextInput/inputField';
import InputPasswordField from '../../components/TextInput/inputPasswordField';
import * as FieldDetails from '../../components/TextInput/fieldDetails';
import ValidationMessage from '../../components/Warning/validationMessage';
import ToolTip from '../../components/ToolTip';
import InputCodeField from '../../components/TextInput/inputCodeField';
import { TAB_BREAk_POINT_START } from '../../util/platformWindowConfig';

let secondTextInput = null;
let thirdTextInput = null;
let fourthTextInput = null;
let fivethTextInput = null;
const stylesRes = stylesResponsive.getProcessedStyles().styles;
const ids = stylesResponsive.getProcessedStyles().ids;
const signup = props => {
  const {
    onChangeText,
    onFocus,
    redrictLogin,
    iHaveAccessCode,
    setIHaveAccessCode,
    accessCodeValidate,
    accessCode,
  } = props;
  return (
    <PageLayoutSignup
      windowHeightView={props.windowHeightView}
      windowHeightSmView={props.windowHeightSmView}
      windowWidthView={props.windowWidthView}
      bgBlue={props.bgBlue}
      ttoggleLearnHowPrivacyPolicy={props.toggleLearnHowPrivacyPolicy}
    >
      <View style={[styles.boxWrapperArea, styles.colMgLtRt]}>
        <View
          style={[
            stylesRes.boxWrapperView,
            stylesRes.wrapperShadow,
            stylesRes.boxWrapperViewMg,
          ]}
          dataSet={{media: ids.boxWrapperViewMediaQuery}}>
          <View style={[styles.colPd]}>
            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <LogoSetUp />
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <View
                  style={[
                    styles.titleBlock,
                    styles.flexRowJcCtAiCt,
                    styles.noPdTop,
                  ]}>
                  <Text
                    style={[
                      styles.textAlignCenter,
                      styles.textPrime,
                      styles.textTertiary,
                    ]}
                    allowFontScaling={false}
                    >
                    Already signed up?{' '}
                  </Text>
                  <TouchableOpacity onPress={redrictLogin} style={styles.touchSmPd} disabled={props.authSuccessSpinner}>
                    <Text
                      style={[
                        styles.resourceBlueTxt,
                        styles.bold,
                        styles.textUnderline,
                      ]}
                      allowFontScaling={false}
                      >
                      Login
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <View style={styles.fieldContainer}>
                  <View style={[styles.fieldRowWrapper]}>
                    <View style={styles.fieldColStretch}>
                      <InputField
                        title={comTitles.FIRST_NAME}
                        fieldBg={FieldDetails.BGWHITE}
                        fieldRg={true}
                        requirdStar={true}
                        invalid={!props.validateFN}
                        onChangeText={val => onChangeText('FIRST_NAME', val)}
                        onFocus={() => onFocus('FIRST_NAME')}
                        value={props.firstName}
                        autoFocus={true}
                        underlineColorAndroid="transparent"
                        onSubmitEditing={() => {
                          if (secondTextInput) {
                            secondTextInput.focus();
                          }
                        }}
                        returnKeyType={'next'}
                      />
                    </View>
                    <View style={styles.fieldColSp} />
                    <View style={styles.fieldColStretch}>
                      <InputField
                        title={comTitles.LAST_NAME}
                        fieldBg={FieldDetails.BGWHITE}
                        fieldRg={true}
                        requirdStar={true}
                        invalid={!props.validateLN}
                        onChangeText={val => onChangeText('LAST_NAME', val)}
                        onFocus={() => onFocus('LAST_NAME')}
                        value={props.lastName}
                        ref={input => {
                          secondTextInput = input;
                        }}
                        underlineColorAndroid="transparent"
                        onSubmitEditing={() => {
                          thirdTextInput.focus();
                        }}
                        returnKeyType={'next'}
                      />
                    </View>
                  </View>
                  {(!props.validateFN || !props.validateLN) && (
                    <ValidationMessage
                      errorMessage={
                        !props.validateFN && !props.validateLN
                          ? 'Enter First Name and Last Name'
                          : !props.validateFN
                          ? 'Enter First Name'
                          : 'Enter Last Name'
                      }
                    />
                  )}
                </View>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <View style={styles.fieldContainer}>
                  <View style={[styles.fieldRowWrapper]}>
                    <View style={styles.fieldColStretch}>
                      <InputField
                        title={comTitles.EMAIL_ADDRESS}
                        fieldBg={FieldDetails.BGWHITE}
                        fieldRg={true}
                        requirdStar={true}
                        invalid={!props.emailValidate}
                        onChangeText={val => onChangeText('EMAIL', val)}
                        onFocus={() => onFocus('EMAIL')}
                        value={props.email}
                        onBlur={props.emailChecker}
                        ref={input => {
                          thirdTextInput = input;
                        }}
                        underlineColorAndroid="transparent"
                        onSubmitEditing={() => {
                          fourthTextInput.focus();
                        }}
                        returnKeyType={'next'}
                        textContentType={'username'}
                        keyboardType={'email-address'}
                      />
                    </View>
                  </View>
                  {!props.emailValidate && (
                    <ValidationMessage
                      errorMessage={'Please enter a valid email address'}
                    />
                  )}
                </View>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <View style={styles.fieldContainer}>
                  <View style={[styles.fieldRowWrapper]}>
                    <View style={styles.fieldColStretch}>
                      <InputPasswordField
                        title={comTitles.CONFIRM_PASSWORD_COMMON}
                        fieldBg={FieldDetails.BGWHITE}
                        fieldRg={true}
                        requirdStar={true}
                        handlePasswordVisibility={
                          props.handlePasswordVisibility
                        }
                        passwordVisible={props.passwordVisible}
                        secureTextEntry={props.passwordVisible}
                        invalid={!props.passwordValidate}
                        onChangeText={val => onChangeText('PASSWORD', val)}
                        onFocus={() => onFocus('PASSWORD')}
                        value={props.password}
                        autoFocus={false}
                        underlineColorAndroid="transparent"
                        ref={input => {
                          fourthTextInput = input;
                        }}
                        onSubmitEditing={() => {
                          if (props.passwordVisible) {
                            fivethTextInput.focus();
                          }
                        }}
                        returnKeyType={'next'}
                        passwordShowHide={true}
                        textContentType={'password'}
                      />
                    </View>
                  </View>
                  {!Boolean(props.passwordValidate) && (
                    <ValidationMessage errorMessage={props.passwordError} />
                  )}
                </View>
              </View>
            </View>

            {Boolean(props.passwordVisible) && (
              <View style={styles.gridRow}>
                <View style={[styles.gridColFull, styles.fieldHeight]}>
                  <View style={styles.fieldContainer}>
                    <View style={[styles.fieldRowWrapper]}>
                      <View style={styles.fieldColStretch}>
                        <InputPasswordField
                          title={comTitles.CONFIRM_PASSWORD}
                          fieldBg={FieldDetails.BGWHITE}
                          fieldRg={true}
                          requirdStar={true}
                          handlePasswordVisibility={
                            props.handlePasswordVisibility
                          }
                          invalid={!props.cpasswordValidate}
                          //   passwordValidate={true}
                          secureTextEntry={true}
                          onChangeText={val =>
                            onChangeText('CONFIRM_PASSWORD', val)
                          }
                          onFocus={() => onFocus('CONFIRM_PASSWORD')}
                          value={props.confirmPassword}
                          autoFocus={false}
                          underlineColorAndroid="transparent"
                          ref={input => {
                            fivethTextInput = input;
                          }}
                          passwordShowHide={false}
                          textContentType={'password'}
                        />
                      </View>
                    </View>
                    {!Boolean(props.cpasswordValidate) && (
                      <ValidationMessage errorMessage={props.cpasswordError} />
                    )}
                  </View>
                </View>
              </View>
            )}

            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                {Boolean(iHaveAccessCode) && (
                  <View style={styles.fieldContainer}>
                    <View style={[styles.fieldRowWrapper]}>
                      <View style={styles.fieldColStretch}>
                        <InputCodeField
                          title={comTitles.ACCESS_CODE}
                          fieldBg={FieldDetails.BGWHITE}
                          fieldRg={true}
                          requirdStar={true}
                          // fieldSm={true}
                          onChangeText={val => onChangeText('ACCESSCODE', val)}
                          value={accessCode}
                          // placeholder={symbol}
                          onFocus={() => onFocus('ACCESSCODE')}
                          invalidCode={!accessCodeValidate}
                        />
                      </View>
                    </View>
                    {!accessCodeValidate && (
                      <ValidationMessage
                        errorMessage={'Please enter a valid Access Code'}
                      />
                    )}
                  </View>
                )}
              </View>
            </View>

            {Boolean(props.error) && (
              <View style={styles.gridRow}>
                <View style={[styles.gridColFull, styles.errBoxView]}>
                  <Warning errorDescription={props.errorDescription} />
                </View>
              </View>
            )}

            <View style={styles.gridRow}>
              <View style={[styles.gridColFull]}>
                <View
                  style={[
                    styles.flexRow,
                    styles.flexJcCtAiCt,
                    styles.comMgBotSm,
                  ]}>
                  <View style={styles.textDetails}>
                    <TouchableOpacity onPress={setIHaveAccessCode} style={styles.comPd}>
                      <Text
                        style={[
                          styles.textPrimeExSmBold,
                          styles.resourceBlueTxt,
                          styles.textUnderline,
                        ]}
                        allowFontScaling={false}
                        >
                          {!iHaveAccessCode
                            ? 'I have an Access Code'
                            : 'I don’t have an Access Code'
                          } 
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View style={[styles.helpCircleComViewMg, { zIndex: 9999}]}>
                    <ToolTip
                      id={'access-code'}
                      text={
                        'This is the 9 character code for your custom account.'
                      }
                    />
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <View style={[styles.fieldDetails, styles.flexAiCt]}>
                  <View style={[styles.checkBoxSection]}>
                    <View>
                      {props.iAgreeButton}
                    </View>
                    <View style={[styles.checkBoxItemDesc]}>
                      <View style={[styles.textDetails, styles.topSmPd]}>
                        <Text
                          style={[styles.textPrimeExSm, styles.textTertiary]} allowFontScaling={false}>
                          I accept and acknowledge the{' '}
                        </Text>
                      </View>
                      <TouchableOpacity
                        style={[styles.mgLtExSm, styles.touchSmPdRtTopBot]}
                        onPress={props.toggleUserLicenseAgreement}>
                        <Text
                          style={[
                            styles.textPrimeExSmBold,
                            styles.textTertiary,
                            styles.textUnderline,
                          ]}
                          allowFontScaling={false}
                          >
                          User License Agreement
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridColFull}>
                <View style={[styles.btnArea, styles.comMgTop]}>
                  <View style={styles.fieldBtn}>
                    <PrimaryButton
                      btnText={comTitles.SIGNUP}
                      btnPosition={'full'}
                      activityInd={props.authSuccessSpinner}
                      btnShadow={true}
                      btnLg={true}
                      touchableOnPress={props.handleOnSubmitSignup}
                      disabled={props.authSuccessSpinner}
                      btnStyle={styles.submitBgBlueDark}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={[
            stylesRes.boxWrapperBottomView,
            stylesRes.pdBotZero,
            props.windowWidthView > TAB_BREAk_POINT_START
            ? stylesRes.boxWrapperSpec
            : stylesRes.boxWrapperMain,
          ]}
          dataSet={{media: ids.boxWrapperBottomView}}>
          <View style={[styles.flexRow, styles.flexJcCt]}>
            <Text style={[styles.textPrime, styles.textWhite]} allowFontScaling={false}>
              Your privacy is our priority.{' '}
            </Text>
            <TouchableOpacity onPress={props.toggleLearnHowPrivacyPolicy}>
              <Text
                style={[
                  styles.textPrime,
                  styles.textWhite,
                  styles.textUnderline,
                ]}
                allowFontScaling={false}
                >
                Learn How
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </PageLayoutSignup>
  );
};

export default (signup);
