import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
  showSideDrawer: false,
  selectedMenu: 0,
  moreMenu: false,
  uploadProfileImage: false,
  windowHeight: undefined,
  windowWidth: undefined,
  showTutorial: false,
  tutorialType: null,
  journalUserId: null,
  journalUserName: null,
  showLearnHow: false,
  token: null,
  tokenType: null,
  tokenHistory: null,
  tokenTypeHistory: null,
  reminderDateTime: null,
  notification: {},
  seenNotification: {},
  clearNotification: {},
  showUserLicenseAgreement: false,
  showPrivacyStatment: false,
  showMobileMenu: true,
  notificationTimeStamp: 0,
  hasNotch: false,
  uniqueId: null,
  showSuccessMessage: false,
  messageId: null,
  graphId: null,
  profileSecurityShow: false,
  showStorageBanner: false,
  fromPopup: null,
  fromDashbordMeds: false,
  fromNotifId: null,
  selectedGraphId: null,
  previousGraphId: null,
  fontScale: null,
  visibleWatchOverview: false,
  tabMenuIconView: true,
  tabDevice: false,
  deleteTherapieInProgress: false,
  deleteContactInProgress: false,
  setupModalOpen: false,
  sentVerificationMail: false,
  //userJournalDiegest error flag default
  userJournalDigestErrorFlag: false,
  email: null,
  deviceData: null,
  showUploadProgress: false,
  deleteUploadConfirm: false,
  deleteUploadConfirmData: null,
  showMappedDataPopup: true,
  joinStudyData: {},
  healthResourcePopupData: {},
  clearAllSynced: true,
  refreshGraph: Date.now(),
  timeZoneWarning: false,
  graphChecked: false,
  eventData: {},
  openedYearAndId: null,
  activeBubbleId: null,
  currentEventId: null,
  tabManuEnable: true,
  isVisibleAlartModal: false,
  dashboardScheduleData: {
    routines: [],
    surveys: [],
    extra: {}
  },
  finishedSchedules: [],
  dashBoardWrapperShowHide: {},
  dropdownUniqueId: null,
};

const selectMenuItem = (state, action) => {
  return updateObject(state, {selectedMenu: action.selectedMenu});
};

const deselectMenuItem = (state, action) => {
  return updateObject(state, {selectedMenu: 0});
};

const sideDrawerToggle = (state, action) => {
  return updateObject(state, {showSideDrawer: !state.showSideDrawer});
};

const slectMoreMenu = (state, action) => {
  return updateObject(state, {moreMenu: !state.moreMenu});
};

const uploadProfileImageSucess = (state, action) => {
  return updateObject(state, {uploadProfileImage: !state.uploadProfileImage});
};

const windowResize = (state, action) => {
  return updateObject(state, {
    windowHeight: action.windowHeight,
    windowWidth: action.windowWidth,
  });
};

const setJournalUser = (state, action) => {
  return updateObject(state, {
    journalUserId: action.journalUserId,
    journalUserName: action.journalUserName,
  });
};

const showTutorial = (state, action) => {
  return updateObject(state, {
    showTutorial: !state.showTutorial,
    tutorialType: action.tutorialType,
  });
};

const showLearnHow = state => {
  return updateObject(state, {
    showLearnHow: !state.showLearnHow,
  });
};
const showUserLicenseAgreement = state => {
  return updateObject(state, {
    showUserLicenseAgreement: !state.showUserLicenseAgreement,
  });
};
const showPrivacyStatment = state => {
  return updateObject(state, {
    showPrivacyStatment: !state.showPrivacyStatment,
  });
};
const setToken = (state, action) => {
  if (action.token === null && action.tokenType === null) {
    return updateObject(state, {
      token: action.token,
      tokenType: action.tokenType,
      reminderDateTime: action.reminderDateTime
    });
  }
  return updateObject(state, {
    token: action.token,
    tokenType: action.tokenType,
    tokenHistory: action.token,
    tokenTypeHistory: action.tokenType,
    reminderDateTime: action.reminderDateTime
  });
};

const showMobileMenu = state => {
  return updateObject(state, {
    showMobileMenu: true,
  });
};

const hideMobileMenu = state => {
  return updateObject(state, {
    showMobileMenu: false,
  });
};

const showUploadProgress = state => {
  return updateObject(state, {
    showUploadProgress: true,
  });
};

const hideUploadProgress = state => {
  return updateObject(state, {
    showUploadProgress: false,
  });
};

const onShowDeleteUploadConfirmation = (state, action) => {
  return updateObject(state, {
    deleteUploadConfirm: action.status,
    deleteUploadConfirmData: action.data,
  });
};

const reset = state => {
  return updateObject(state, {
    showSideDrawer: false,
    selectedMenu: 0,
    moreMenu: false,
    uploadProfileImage: false,
    showTutorial: false,
    tutorialType: null,
    journalUserId: null,
    journalUserName: null,
    showLearnHow: false,
    token: null,
    tokenType: null,
    reminderDateTime: null,
    notification: {},
    seenNotification: {},
    clearNotification: {},
    notificationTimeStamp: 0,
    showSuccessMessage: false,
    messageId: null,
    graphId: null,
    profileSecurityShow: false,
    showStorageBanner: false,
    fontScale: null,
    visibleWatchOverview: false,
    tabMenuIconView: true,
    tabDevice: false,
    deleteTherapieInProgress: false,
    deleteContactInProgress: false,
    setupModalOpen: false,
    sentVerificationMail: false,
    selectedGraphId: null,
    userJournalDigestErrorFlag: false,
    fromNotifId: null,
    showMappedDataPopup: true,
    joinStudyData: {},
    healthResourcePopupData: {},
    clearAllSynced: true,
    timeZoneWarning: false,
    graphChecked: false,
    eventData: {},
    openedYearAndId: null,
    activeBubbleId: null,
    currentEventId: null,
    fromDashbordMeds: false,
    dashboardScheduleData: {
      routines: [],
      surveys: [],
      extra: {}
    },
    finishedSchedules: [],
    dashBoardWrapperShowHide: {},
  });
};

const setNotification = (state, action) => {
  const notification = {...state.notification};
  const seenNotification = {...state.seenNotification};
  const clearNotification = {...state.clearNotification};
  action.notification.forEach(element => {
    if (!seenNotification[element.id] && !clearNotification[element.id]) {
      notification[element.id] = element;
      seenNotification[element.id] = element;
      if (action.notif) {
        //setTimeout(() => action.notif.localNotif(element), 10000);
      }
    }
  });
  return updateObject(state, {
    notification: notification,
    seenNotification: seenNotification,
  });
};

const setSeenNotification = state => {
  return updateObject(state, {
    notification: {},
  });
};

export const resetNotification = state => {
  return updateObject(state, {
    notification: {},
    seenNotification: {},
    clearNotification: {},
    notificationTimeStamp: 0,
  });
};

export const clearAllNotification = (state, action) => {
  const clearNotification = {...state.clearNotification};
  const seenNotification = {...state.seenNotification};
  Object.keys(seenNotification).forEach(
    id => (clearNotification[id] = seenNotification[id]),
  );
  return updateObject(state, {
    notification: {},
    seenNotification: {},
    clearNotification: clearNotification,
    clearAllSynced: action?.from ===  "MANUAL" ? false : true,
  });
};

export const clearNotificationById = (state, action) => {
  const clearNotification = {...state.clearNotification};
  const seenNotification = {...state.seenNotification};
  clearNotification[action.id] = seenNotification[action.id];
  delete seenNotification[action.id];
  return updateObject(state, {
    notification: {},
    seenNotification: seenNotification,
    clearNotification: clearNotification,
  });
};

export const clearMenuSectionNotifications = (state, action) => {
  const notification = {...state.notification};
  const seenNotification = {...state.seenNotification};
  const clearNotification = {...state.clearNotification};
  action.sectionNotifications.forEach(element => {
    clearNotification[element.id] = seenNotification[element.id];
    delete notification[element.id];
    delete seenNotification[element.id];
  });
  return updateObject(state, {
    notification: notification,
    seenNotification: seenNotification,
    clearNotification: clearNotification,
  });
};

export const clearNotificationForEmailVerified = (state, action) => {
  if (action.emailVerified === true) {
    return clearNotificationById(state, {id: 'EMAIL_VRIFICATION_REMINDER'});
  } else {
    return state;
  }
};

export const addNotifcation = (state, action) => {
  const notification = {...state.notification};
  const seenNotification = {...state.seenNotification};
  const clearNotification = {...state.clearNotification};
  if (clearNotification['EMAIL_VRIFICATION_REMINDER']) {
    delete clearNotification['EMAIL_VRIFICATION_REMINDER'];
  }

  if (clearNotification['MFA_ENABLE_REMINDER']) {
    delete clearNotification['MFA_ENABLE_REMINDER'];
  }
  action.notifications.forEach(element => {
    if (!seenNotification[element.id] && !clearNotification[element.id]) {
      notification[element.id] = element;
      seenNotification[element.id] = element;
      let seen = false;
      if (element.data && element.data.seen) {
        seen = element.data.seen;
      }
      if (action.notif && !seen) {
        //stop notification popup display, when app is in foreground(local notifications)
        //action.notif.localNotif(element);
      }
    } else if (element.type === 'PEER_NOTIFICATION') {
      delete seenNotification[element.id];
      notification[element.id] = element;
      seenNotification[element.id] = element;
    } else if (element.type === 'SURVEY') {
      delete clearNotification[element.id];
      notification[element.id] = element;
      seenNotification[element.id] = element;
    } else if (element.id.includes("PARTNER_HEALTH_RESOURCE")) {
      delete seenNotification[element.id];
      delete notification[element.id]
      notification[element.id] = element;
      seenNotification[element.id] = element;
    }
  });

  return updateObject(state, {
    notification: notification,
    seenNotification: seenNotification,
    notificationTimeStamp: action.timeStamp
      ? action.timeStamp
      : state.notificationTimeStamp,
    clearNotification: clearNotification,
  });
};

const setDeviceMainInfo = (state, action) => {
  return updateObject(state, {
    hasNotch: action.hasNotch,
    uniqueId: action.uniqueId
  });
};

export const showSuccessPopup = (state, action) => {
  return updateObject(state, {
    showSuccessMessage: true,
    messageId: action.messageId,
    graphId: action.graphId,
    deviceData: action.deviceData,
  });
};

export const hideSuccessPopup = state => {
  return updateObject(state, {
    showSuccessMessage: false,
    messageId: null,
    graphId: null,
  });
};

export const setProfileSecurityShow = (state, action) => {
  return updateObject(state, {
    profileSecurityShow: action.show,
  });
};

export const showHideStorageBanner = (state, action) => {
  return updateObject(state, {
    showStorageBanner: action.val,
  });
};

export const setFromPopup = (state, action) => {
  return updateObject(state, {
    fromPopup: action.fromPopup,
    fromDashbordMeds: action.fromDashbordMeds
  });
};

export const setFromNotifId = (state, action) => {
  return updateObject(state, {
    fromNotifId: action.fromNotifId,
  });
};

const selectGraphItem = (state, action) => {
  return updateObject(state, {
    selectedGraphId: action.selectedGraphId,
  });
};

const setPreviousGraphId = (state, action) => {
  return updateObject(state, {
    previousGraphId: action.previousGraphId,
  });
};

const setDeviceFontScale = (state, action) => {
  return updateObject(state, {
    fontScale: action.fontScale,
  });
};

const toggleWatchOverview = (state, action) => {
  return updateObject(state, {
    visibleWatchOverview: action.payload,
  });
}

const setTabMenuIconView = state => {
  return updateObject(state, {
    tabMenuIconView: !state.tabMenuIconView,
  });
};

const setIsTabDevice = (state, action) => {
  return updateObject(state, {
    tabDevice: action.tabDevice,
  });
};
const setDeleteTherapieInProgress = (state, action) => {
  return updateObject(state, {
    deleteTherapieInProgress : action.deleteTherapieInProgress,
  });
};

const setDeleteContactInProgress = (state, action) => {
  return updateObject(state, {
    deleteContactInProgress : action.deleteContactInProgress,
  });
};

const setSetupModalVisible = (state, action) => {
  return updateObject(state, {
    setupModalOpen : action.payload,
  });
};

const setVerificationEmailSent = (state, action) => {
  return updateObject(state, {
    sentVerificationMail: action.payload
  });
}

//403 user digest error
const userDigestError = (state, action) => {
  return updateObject(state, {
    userJournalDigestErrorFlag: action.userJournalDigestErrorFlag,
  });
};

const updateSignupEmail = (state, action) => {
  return updateObject(state, {
    email: action.email,
  });
};

const setShowMappedDataPopupNextTime = (state, action) => {
  return updateObject(state, {
    showMappedDataPopup: action.showMappedDataPopup
  });
};

const setJoinStudyData = (state, action) => {
  return updateObject(state, {
    joinStudyData: action.data
  });
};

const setHealthResourcePopupData = (state, action) => {
  return updateObject(state, {
    healthResourcePopupData: action.data
  });
};

const refreshGraph = state => {
  return updateObject(state, {
    refreshGraph: Date.now(),
  });
};

const setSeenTimeZoneWarning = (state, action) => {
  return updateObject(state, {
    timeZoneWarning: action.seenTimeZoneWarning,
  });
};

const setGraphChecked = (state, action) => {
  return updateObject(state, {
    graphChecked: action.graphChecked,
  });
};

const setEventData = (state, action) => {
  return updateObject(state, {
    eventData: action.data,
  });
};

const setOpenedYearAndId = (state, action) => {
  return updateObject(state, {
    openedYearAndId: action.data,
  });
};

const setActiveBubble = (state, action) => {
  return updateObject(state, {
    activeBubbleId: action.id,
  });
};

const setCurrentEventId = (state, action) => {
  return updateObject(state, {
    currentEventId: action.id,
  });
};

const setTabMenuStatus = (state, action) => {
  return updateObject(state, {
    tabManuEnable: action.value,
  });
};

const setAlertModalVisible = (state, action) => {
  return updateObject(state, {
    isVisibleAlartModal: action.status,
  });
};

const setDashboardScheduleData = (state, action) => {
  return updateObject(state, {
    dashboardScheduleData: {
      ...state.dashboardScheduleData,
      ...action.data
    }
  });
};

const setFinishedSchedules = (state, action) => {
  return updateObject(state, {
    finishedSchedules: [...action.data]
  })
};

const setDashBoardItemShowHide = (state, action) => {
  return updateObject(state, {
    dashBoardWrapperShowHide: { ...state.dashBoardWrapperShowHide, ...action.item },
  });
};

const setDropdownUniqueId = (state, action) => {
  return updateObject(state, {
    dropdownUniqueId: action.id,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_MENU_ITEM:
      return selectMenuItem(state, action);
    case actionTypes.DESELECT_MENU_ITEM:
      return deselectMenuItem(state, action);
    case actionTypes.SIDE_DAWER_TOGGLE:
      return sideDrawerToggle(state, action);
    case actionTypes.MORE_MENU:
      return slectMoreMenu(state, action);
    case actionTypes.UPLOAD_PROFILE_IMAGE_SUCCESS:
      return uploadProfileImageSucess(state, action);
    case actionTypes.WINDOW_RESIZE:
      return windowResize(state, action);
    case actionTypes.SHOW_TUTORIAL:
      return showTutorial(state, action);
    case actionTypes.SET_JOURNAL_USER:
      return setJournalUser(state, action);
    case actionTypes.SHOW_LEARN_HOW:
      return showLearnHow(state);
    case actionTypes.SET_TOKEN:
      return setToken(state, action);
    case actionTypes.UI_STATE_RESET:
      return reset(state, action);
    case actionTypes.ROUTINE_REMINDERS:
      return setNotification(state, action);
    case actionTypes.SEEN_NOTIFICATION:
      return setSeenNotification(state, action);
    case actionTypes.RESET_NOTIFICATION:
      return resetNotification(state, action);
    case actionTypes.CLEAR_ALL_NOTIFICATION:
      return clearAllNotification(state, action);
    case actionTypes.CLEAR_NOTIFICATION_BY_ID:
      return clearNotificationById(state, action);
    case actionTypes.SET_EMAIL_VERIFIED:
      return clearNotificationForEmailVerified(state, action);
    case actionTypes.CLEAR_MENU_SECTION_NOTIFICATIONS:
      return clearMenuSectionNotifications(state, action)
    case actionTypes.SHOW_USER_LICENSE_AGREEMENT:
      return showUserLicenseAgreement(state, action);
    case actionTypes.SHOW_PRIVACY_STATMENT:
      return showPrivacyStatment(state, action);
    case actionTypes.SHOW_MOBILE_MENU:
      return showMobileMenu(state, action); 
    case actionTypes.HIDE_MOBILE_MENU:
      return hideMobileMenu(state, action);
    case actionTypes.ADD_NOTIFCATION:
      return addNotifcation(state, action);
    case actionTypes.SHOW_SUCCESS_POPUP:
      return showSuccessPopup(state, action);
    case actionTypes.HIDE_SUCCESS_POPUP:
      return hideSuccessPopup(state, action);
    case actionTypes.SET_PROFILE_SECURITY_SHOW:
      return setProfileSecurityShow(state, action);
    case actionTypes.SHOW_HIDE_STORAGE_BANNER:
      return showHideStorageBanner(state, action);
    case actionTypes.SET_DEVICE_MAIN_INFO:
      return setDeviceMainInfo(state, action);
    case actionTypes.SET_FROM_POPUP:
      return setFromPopup(state, action);
    case actionTypes.SET_FROM_NOTIF_ID:
      return setFromNotifId(state, action);
    case actionTypes.SELECT_GRAPH_ITEM:
      return selectGraphItem(state, action);
    case actionTypes.SET_PREVIOUS_GRAPH_ID:
      return setPreviousGraphId(state, action);
    case actionTypes.SET_DEVICE_FONT_SCALE:
      return setDeviceFontScale(state, action);
    case actionTypes.TOGGLE_WATCH_OVERVIEW:
      return toggleWatchOverview(state, action);
    case actionTypes.SET_TAB_MENU_ICON_VIEW:
      return setTabMenuIconView(state, action);
    case actionTypes.SET_IS_TAB_DEVICE:
      return setIsTabDevice(state, action);
    case actionTypes.DELETE_THERAPIE_IN_PROGRESS:
      return setDeleteTherapieInProgress(state, action);
    case actionTypes.DELETE_CONTACT_IN_PROGRESS:
      return setDeleteContactInProgress(state, action);
    case actionTypes.TOGGLE_SETUP_MODAL:
      return setSetupModalVisible(state, action);
    case actionTypes.TOGGLE_VERIFICATION_MAIL_SENT:
      return setVerificationEmailSent(state, action);
    case actionTypes.USER_DIGEST_ERROR:
      return userDigestError(state, action);
      case actionTypes.SIGNUP_EMAIL:
        return updateSignupEmail(state, action);
    case actionTypes.SHOW_UPLOAD_PROGRESS:
      return showUploadProgress(state, action);
    case actionTypes.HIDE_UPLOAD_PROGRESS:
      return hideUploadProgress(state, action);
    case actionTypes.DELETE_UPLOAD_CONFIRMATION:
      return onShowDeleteUploadConfirmation(state, action);
    case actionTypes.SET_SHOW_MAPPED_DATA_POPUP_NEXT_TIME:
      return setShowMappedDataPopupNextTime(state, action)
    case actionTypes.SET_JOIN_STUDY_DATA:
      return setJoinStudyData(state, action)
    case actionTypes.SET_HEALTH_RESOURCE_POPUP_DATA:
      return setHealthResourcePopupData(state, action)
    case actionTypes.REFRESH_GRAPH:
        return refreshGraph(state, action)
    case actionTypes.SET_SEEN_TIME_ZONE_WARNING:
      return setSeenTimeZoneWarning(state, action)
    case actionTypes.SET_GRAPH_CHECKED:
        return setGraphChecked(state, action)
    case actionTypes.SET_EVENT_DATA:
      return setEventData(state, action) 
    case actionTypes.SET_EVENT_YEAR_AND_ID:
      return setOpenedYearAndId(state, action)  
    case actionTypes.SET_ACTIVE_BUBBLE:
      return setActiveBubble(state, action)  
    case actionTypes.SET_CURRENT_EVENT_ID:
      return setCurrentEventId(state, action)
    case actionTypes.SET_TAB_MENU_DISABLE:
      return setTabMenuStatus(state, action)
    case actionTypes.SET_ALERT_MODAL_VISIBLE:
      return setAlertModalVisible(state, action)
    case actionTypes.SYNC_DASHBOARD_SCHEDULE_DATA:
      return setDashboardScheduleData(state, action)
    case actionTypes.SET_FINISH_SCHEDULES:
      return setFinishedSchedules(state, action)
    case actionTypes.UPDATE_DASHBOARD_ITEM_SHOW_HIDE:
      return setDashBoardItemShowHide(state, action)
    case actionTypes.UPDATE_DROPDOWN_UNIQUE_ID:
      return setDropdownUniqueId(state, action)
    default:
      return state;
  }
};

export default reducer;
