import React, {useState, useEffect, Fragment, useRef} from "react";
import {View, Text, Image, TouchableOpacity, Keyboard, Animated} from 'react-native';
import _ from "lodash";
import AutoCompleteSuggestion from '../../../components/AutoComplete/AutoCompleteSuggestion';
import * as FieldDetails from '../../../components/TextInput/fieldDetails';
import EventBubbleItem from "../HealthJourneyList/EventBubbleItem";
import Modal from '../../../components/Modal/modal';
import AddNewTherapyModalContent from '../../../components/ConfirmationBox/addPrescription';
import HealthJourneyAddEvent from "./HealthJourneyAddEvent";
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import { getUserTerms, getSuggestedTeams, verifyDuplicateMedicationExistOrNot } from '../../../util/commonUiLogic';
import { getAccountType } from "../../../util/subscriptionInformation";
import * as constants from '../HealthJourneyConstant';
import * as ColorCode from '../../../components/ColorPicker/ColorCodes';
import { autoSuggestionListType, chartTypes } from "../../../Constant";
import { HEALTH_JOURNEY, MEDICATIONS, PROFILE, SYMPTOMS } from "../../../navigation/path";

let autoCompleteRef = null
const AddEventCategoryType = (props) => {
  const scale = useRef(new Animated.Value(0)).current;
  const opacity = useRef(new Animated.Value(1)).current;
  const mounted = useRef(false);
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {categoryType, items = [], userListItems, systemDefinedMetrics, updateSystemDefinedSymptoms, activeBubbleId, setActiveBubble, userSubscriptions, navigation, journalUserId, onDeleteTherapie, newItems = [], updateCagoryList, editTherapie, drugAdministrationsDigits, updateTherapie, resetNewTherapie, commonErrorHandler, newTherapie, isNew, syncEventData, createUserSymptom, onDeleteSymptoms, journalEntries, onSelectSymptom, onSelectTherapie, parentMounted} = props;
  const [openEnterCategoryType, setOpenEnterCategoryType] = useState(false);
  const [name, setName] = useState('');
  const [userTerms, setUserTearms] = useState([]);
  const [terms, setTerms] = useState([]);
  const [visiblePrescription, setVisiblePrescription] = useState(false);
  const [accountType, setAccountType] = useState(null);
  const [delId, setDelId] = useState(null);
  const [proceeding, setProceeding] = useState(false);
  const [selectedVals, setSelectedVals] = useState({});
  const url = '/api/treatments/treatment/summarycontains?substring=';

  useEffect(() => {
    switch(categoryType) {
      case constants.eventTypes.MEDICATIONS:
        setUserTearms(getMedicationUserTerms());
        setAccountType(getAccountType(userSubscriptions));
        break;
      case constants.eventTypes.SYMPTOMS:
        setUserTearms(getUserTerms(userListItems));
        updateSystemDefinedSymptoms((result, data) => {
          if (result) {
            let termsArray = getSuggestedTeams(data, userListItems);
            setTerms([...termsArray]);
          }
        });
        break;
      case constants.eventTypes.HEATHDATA:
        setUserTearms(getUserTerms(userListItems));
        let termsArray = getSuggestedTeams(systemDefinedMetrics, userListItems);
        setTerms([...termsArray]);
        break;
      default:
        break;
    }

    if (!mounted.current && parentMounted) {
      let newOne = null;
      if (!_.isEmpty(newTherapie) && isNew) {
        newOne = {id: newTherapie.id, name: newTherapie.name, initializing: true};
        updateCagoryList(
          [...items, newOne],
          [...newItems, newOne],
        );
        //No need to update this is already created!
        proceedNewItem(newTherapie, constants.eventTypes.MEDICATIONS);
      }
      // setTimeout(() => {
      // }, 500); 
      mounted.current = true;
    }
    initSelectedValues();
    //eslint-disable-next-line
  }, [categoryType, userListItems, items]);

  const initSelectedValues = () => {
    const selectedValues = {};
    if (items && items.length > 0) {
      items.forEach(res => {
        if (res) {
          const obj = userListItems?.find(item => res.id === item.id);
          if (obj) {
            selectedValues[obj.name] = obj.name;
          }
        }
      });
    }
    setSelectedVals(selectedValues);
  };

  const getSelectedCategoryTypeText = (categoryType, isFromAdd = false) => {
    switch(categoryType) {
        case constants.eventTypes.MEDICATIONS:
            return isFromAdd ? '+ Add Med/Supplement' : 'Enter Medication/Supplement';
        case constants.eventTypes.SYMPTOMS:
            return isFromAdd ? '+ Add Symptom' : 'Enter Symptom';
        case constants.eventTypes.HEATHDATA:
            return isFromAdd ? '+ Add Health Data' : 'Enter Health Data';
        default:
            return '+ Add more';
    }
  }

  const proceedNewItem = async (item, type) => {
    setProceeding(true);
    try {
      if (type === constants.eventTypes.MEDICATIONS) {
        const proccessedObj = await HealthJourneyAddEvent.getCategoryItem(item, type, drugAdministrationsDigits);
        updateTherapie(
          proccessedObj,
          (suc, data) => {
            if (suc) {
              success(data);
            } else {
              abort(item, "Failed to save");
            }
          },
          journalUserId,
        );
      } else if (type === constants.eventTypes.SYMPTOMS) {
        let proccessedObj = {
          userSymptom: {
            ...item,
          },
        };
        createUserSymptom(
          proccessedObj,
          (suc, data) => {
            if (suc) {
              success(data);
            } else {
              abort(item, "Failed to save");
            }
          },
          journalUserId,
        );
      }
    } catch (error) {
      abort(item, error);
    } finally {
      resetNewTherapie();
    }
  }


  const abort = (item, error) => {
    updateCagoryList(
      items.filter(res => res.id !== item.id && res.id !== "-1"),
      newItems.filter(res => res.id !== item.id && res.id !== "-1"),
    );
    setProceeding(false);
    commonErrorHandler(error);
  }

  const success = item => {
    updateCagoryList(
      [...items.filter(res => res.id !== item.id && res.id !== "-1"), {id: item.id}],
      [...newItems.filter(res => res.id !== item.id && res.id !== "-1"), {id: item.id}],
    );
    setProceeding(false);
  }

  const hadleAddMedications = async (item, type) => {
    const copiedItems = Object.assign([], items);
    const copiedNewItems = Object.assign([], newItems);
    if (type === "USER") {
      copiedItems.push({id: item?.data?.id});
      updateCagoryList(
        copiedItems,
        copiedNewItems
      );
    } else if (type === "SYSTEM") {
      let currSummaryName = null;
      if (item.data.summary.match('.+?(?=\\(DIN:)')) {
        currSummaryName = item.data.summary.match('.+?(?=\\(DIN:)');
      } else if (item.data.summary.match('.+?(?=\\(NHP:)')) {
        currSummaryName = item.data.summary.match('.+?(?=\\(NHP:)');
      }
      let selectedValue = item.data;
      const payload = {
        id: "-1",
        name: currSummaryName[0],
        identificationNumber: selectedValue.treatmentId,
        treatmentType: selectedValue.treatmentType.toString(),
        unit: '',
      };
      copiedItems.push({id: payload.id, name: payload.name, initializing: true});
      copiedNewItems.push({id: payload.id, name: payload.name, initializing: true});
      updateCagoryList(
        copiedItems,
        copiedNewItems,
      );
      proceedNewItem(payload, constants.eventTypes.MEDICATIONS);
    } else {
      const payload = {
        id: "-1",
        name: item
      };
      copiedItems.push({id: payload.id, name: payload.name, initializing: true});
      copiedNewItems.push({id: payload.id, name: payload.name, initializing: true});
      updateCagoryList(
        copiedItems,
        copiedNewItems,
      );
      proceedNewItem(payload, constants.eventTypes.MEDICATIONS);
    }
  }

  const handleAddSymptoms = (item, type) => {
    const copiedItems = Object.assign([], items);
    const copiedNewItems = Object.assign([], newItems);
    if (type === "USER") {
      copiedItems.push({id: item?.data?.id});
      updateCagoryList(
        copiedItems,
        copiedNewItems
      );
    } else if (type === "SYSTEM") {
      let displayProperties = item?.data?.displayProperties;
      let color = displayProperties?.color
        ? displayProperties?.color
        : ColorCode.BGSYMPTOM;
      let graphStyle = displayProperties?.style || (displayProperties?.dashedLine === false ? chartTypes.dash : chartTypes.line);
      let payload = {
        id: "-1",
        name: item.name,
        displayProperties: {
          color,
          style: graphStyle,
          description: displayProperties?.description,
          scaleMax: displayProperties?.scaleMax,
          scaleMin: displayProperties?.scaleMin,
        },
      };
      copiedItems.push({id: payload.id, name: payload.name, initializing: true});
      copiedNewItems.push({id: payload.id, name: payload.name, initializing: true});
      updateCagoryList(
        copiedItems,
        copiedNewItems,
      );
      proceedNewItem(payload, constants.eventTypes.SYMPTOMS);
    } else {
      let payload = {
        id: "-1",
        name: item,
        displayProperties: {
          color: ColorCode.BGSYMPTOM,
          style: chartTypes.line,
          scaleMax: null,
          scaleMin: null,
        },
      };
      copiedItems.push({id: payload.id, name: payload.name, initializing: true});
      copiedNewItems.push({id: payload.id, name: payload.name, initializing: true});
      updateCagoryList(
        copiedItems,
        copiedNewItems,
      );
      proceedNewItem(payload, constants.eventTypes.SYMPTOMS);
    }
  }

  const getMedicationUserTerms = () => {
    let termsArray = [];
    if (userListItems) {
      const suggestionResults = [...userListItems];
      termsArray = suggestionResults.map(value => {
        return (
          value &&
          value.treatmentType !== 5 && {
            name: value.name,
            data: value,
          }
        );
      });
    }

    return termsArray;
  };

  const onPressItem = (cateType, item, type) => {
    handleAddSelectedValues(item, type, cateType);
    if (cateType === constants.eventTypes.MEDICATIONS) {
      hadleAddMedications(item, type);
    } else if (cateType === constants.eventTypes.SYMPTOMS) {
      handleAddSymptoms(item, type);
    }
  }

  const handleAddSelectedValues = (item, type, cateType) => {
    const selected = Object.assign({}, selectedVals);
    switch (type) {
      case "CUSTOM":
        selected[item] = item;
        break;
      case "USER":
        selected[item.name] = item.name;
        break;
      case "SYSTEM":
        const name = cateType === constants.eventTypes.MEDICATIONS ? item?.name?.[0].trim() : item?.name;
        selected[name] = name;
        break;
      default:
        break;
    }
    setSelectedVals(selected);
  }

  const onPressViewItem = (id, recordings) => {
    if (categoryType === constants.eventTypes.MEDICATIONS) {
      const therapieTobeUpdated = {
        ...userListItems.find(item => item.id === id),
        forceEdit: true,
      }
      if (therapieTobeUpdated.publishedBy !== 'PARTNER') {
        editTherapie(therapieTobeUpdated, () =>
          redirect(
            MEDICATIONS.editTherapiePath,
            true,
          ),
        );
      } else {
        onSelectTherapie(therapieTobeUpdated, () =>
          redirect(
            MEDICATIONS.viewTherapiesPath,
            true,
          ),
        )
      }
    } else if (categoryType === constants.eventTypes.SYMPTOMS) {
      const symptomTobeUpdated = {
        ...userListItems.find(item => item.id === id),
        recordings: recordings ? recordings : undefined,
      }
      onSelectSymptom(symptomTobeUpdated, () =>
        redirect(
          symptomTobeUpdated.publishedBy !== 'PARTNER'
            ? SYMPTOMS.editSymptomPath
            : SYMPTOMS.viewSymptomPath,
          true,
        ),
      );
    }
  }

  const onItemPress = (selection, type) => {
    setName('');
    onPressItem(categoryType, selection, type);
    onCloseManualPrescription();
    Keyboard.dismiss(); 
  };

  const onChangeTextName = (currentInput) => {
    setName(currentInput);
  };

  const onPressCustomName = (selection, type) => {
    if (selection && selection.trim() !== '') {
        onItemPress(selection, type);
    }
  };


  const processData = res => {
    let termsArray = [];
    if (res) {
      const suggestionResults = [...res.summaries];
      if (res?.substring === name || !res?.substring) {
        let filteredResults = [];
        suggestionResults.forEach(val => {
          if(!verifyDuplicateMedicationExistOrNot(
            val.summary.match('.+?(?=\\(DIN:)') ? val.summary.match('.+?(?=\\(DIN:)')?.[0] : val.summary.match('.+?(?=\\(NHP:)')?.[0],
            val.treatmentId,
            userListItems)
          ) {
            return filteredResults.push(val);
          }
        });

        termsArray = filteredResults.map(value => {
        return {
          currentInput: name ,
          name: value.summary.match('.+?(?=\\(DIN:)') ? value.summary.match('.+?(?=\\(DIN:)') : value.summary.match('.+?(?=\\(NHP:)'),
          // name: value.summary,
          Ingredient: value.ingredient,
          din: value.summary.includes("(DIN:") ? "DIN: "+value.treatmentId : "NHP: "+value.treatmentId ,
          data: value
        };
      });
      setTerms(termsArray);
      } else {
        termsArray = terms
      }
    }
    return termsArray;
  };

  const renderAddButton = () => {
    return (
      <View style={styles.pdRtSeven}>
        <TouchableOpacity onPress={() => {
          Keyboard.dismiss();
          activeBubbleId && setActiveBubble(null);
          handleVisiblePrescription();
        }}>
            <Text style={[styles.textPrimeBold, styles.seaBluTxtColor, styles.textUnderline]}>
                {getSelectedCategoryTypeText(
                    items && items.length === 0 ? categoryType : null,
                    true
                )}
            </Text>
        </TouchableOpacity>
      </View>
    );
  }

  const handleAddOperation = () => {
    Animated.timing(opacity, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true,
    }).start(() => {
      !openEnterCategoryType && setOpenEnterCategoryType(op => !op);
      Animated.timing(scale, {
        toValue: !openEnterCategoryType ? 1 : 0,
        duration: 200,
        useNativeDriver: true,
      }).start(() => {
        openEnterCategoryType && setOpenEnterCategoryType(op => !op);
        Animated.timing(opacity, {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
        }).start();
      });
    });
  }

  const handleVisiblePrescription = () => {
    if (categoryType === constants.eventTypes.MEDICATIONS)
      setVisiblePrescription(e => !e);
    else
      handleAddOperation();
  }

  const handleRedirecPremium = () => {
    handleVisiblePrescription();
    redirect(PROFILE.profilePremiumAccountPath, true);
  }

  const redirect = (place, params) => {
    syncEventData();
    navigation.navigate(place, params && {
      from: HEALTH_JOURNEY.path
    });
  }

  const onManualPrescription = () => {
    handleVisiblePrescription();
    setTimeout(() => {
      handleAddOperation();
    }, 100);
  }

  const onScanPrescription = () => {
    handleVisiblePrescription();
    redirect(MEDICATIONS.scanPrescriptionPath, true);
  }

  const onCloseManualPrescription = () => {
    if (autoCompleteRef && autoCompleteRef.turnOffList) {
      autoCompleteRef.turnOffList();
    }
    onChangeTextName("");
    setTimeout(() => {
      handleAddOperation();
    }, 100);
  }

  const handleDeleteCategory = () => {
    return (id, isNew, type) => {
      if (isNew) {
        setDelId(id);
        if (type === constants.eventTypes.MEDICATIONS) {
          onDeleteTherapie(
            id,
            (res) => {
              if (res) {
                activeBubbleId && setActiveBubble(null);
                updateCagoryList(
                  items.filter(res => res.id !== id),
                  newItems.filter(res => res.id !== id),
                );
              }
              setDelId(null);
            },
            journalUserId
          );
        } else if (type === constants.eventTypes.SYMPTOMS) {
          onDeleteSymptoms(
            id,
            userListItems,
            (res) => {
              if (res) {
                activeBubbleId && setActiveBubble(null);
                updateCagoryList(
                  items.filter(res => res.id !== id),
                  newItems.filter(res => res.id !== id),
                );
              }
              setDelId(null);
            },
            journalUserId
          );
        }
      } else {
        activeBubbleId && setActiveBubble(null);
        updateCagoryList(
          items.filter(res => res.id !== id),
          newItems
        );
      }
      const selected = Object.assign({}, selectedVals);
      const found = userListItems.find(item => item.id === id);
      delete selected[found?.name];
      setSelectedVals(selected);
    }
  }

  return (
    <View style={[!openEnterCategoryType && styles.flexRow, styles.mgTopLg, styles.flexAiFs]}>
      <View 
        style={[
          styles.flexRow,
          styles.flexAiCt,
          styles.mgtBtmFive,
          styles.sectionContainerzIndex
        ]}>
        <View style={[styles.pdRtSeven, styles.mgLeftTwo]}>
          <Image
            style={[
              styles.categoryIcn,
              items && items.length > 0 && {top:7}
            ]}
            source={
              items && items.length === 0 && !openEnterCategoryType
                ? require('../../../assets/health-journey-icons/category-icon-blue.png')
                : require('../../../assets/health-journey-icons/category-icon.png')
            }
            resizeMode={'contain'}
          />
        </View>
        {Boolean(openEnterCategoryType) && (
          <View style={[styles.fieldRowComWrapper, styles.zIndexAutoComplete]}>
            <View style={[styles.fieldColStretch]}>
              <AutoCompleteSuggestion
                selectedInput={true}
                textFieldWithTitle={true}
                textFieldTitle={getSelectedCategoryTypeText(categoryType)}
                remortSearch={
                  categoryType === constants.eventTypes.MEDICATIONS
                    ? true
                    : false
                }
                url={categoryType === constants.eventTypes.MEDICATIONS && url}
                processData={
                  categoryType === constants.eventTypes.MEDICATIONS &&
                  processData
                }
                triggerLength={
                  categoryType === constants.eventTypes.MEDICATIONS && 3
                }
                showCustomSection={true}
                userTerms={userTerms}
                terms={
                  categoryType !== constants.eventTypes.MEDICATIONS && terms
                }
                currentInput={name}
                onChangeText={onChangeTextName}
                onItemPress={onItemPress}
                onPressCustom={onPressCustomName}
                autoFocus={true}
                fieldBg={FieldDetails.BGWHITE}
                fieldLt={false}
                darkTxt={true}
                toolTipButton={true}
                allowFontScaling={false}
                zoomIcon={true}
                customWidth={true}
                contentRightAlign={true}
                type={
                  categoryType === constants.eventTypes.MEDICATIONS
                    ? autoSuggestionListType.medication
                    : categoryType === constants.eventTypes.SYMPTOMS
                    ? autoSuggestionListType.symptom
                    : autoSuggestionListType.health_data
                }
                autoDisplayUserList
                getRef={ref => (autoCompleteRef = ref)}
                selectedValues={selectedVals}
                disbleSelectedValues
              />
            </View>
            <View style={[styles.flexJcCtAiCt, styles.iconLtSp]}>
              <TouchableOpacity onPress={onCloseManualPrescription} style={styles.rightSpExLg}>
                <Image
                  style={{width: 15, height: 15}}
                  source={require('../../../assets/health-journey-icons/health-journey-close.png')}
                />
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
      {items.length > 0 && (
        <Animated.View
          style={[
            styles.flexRow,
            styles.mgLtFive,
            stylesRes.mbZeroDescEightyFivePersRes,
            {
              flexWrap: 'wrap',
              flex: 1,
              alignItems: 'center',
              opacity,
              zIndex: 9,
            },
          ]}
          dataSet={{media: ids.mbZeroDescEightyFivePersRes}}>
          {items.map((item, idx) => (
            <Fragment key={idx}>
              <EventBubbleItem
                type={categoryType}
                index={idx}
                operation="ADD"
                item={
                  item.initializing
                    ? item
                    : userListItems?.filter(res => res.id === item.id)?.[0] ||
                      {}
                }
                activeBubbleId={activeBubbleId}
                setActiveBubble={setActiveBubble}
                onDeleteTherapie={handleDeleteCategory()}
                isNew={newItems.some(element => element.id === item.id)}
                deleting={delId === item.id}
                onPressViewItem={onPressViewItem}
                journalEntries={journalEntries}
              />
            </Fragment>
          ))}
          {items.length > 0 && !openEnterCategoryType && renderAddButton()}
        </Animated.View>
      )}
      {!Boolean(openEnterCategoryType) && items.length === 0 && renderAddButton()}
      {Boolean(visiblePrescription) && (
        <Modal
          visible={visiblePrescription}
          onRequestClose={handleVisiblePrescription}
          onBackdropPress={handleVisiblePrescription}>
          <AddNewTherapyModalContent
            closeModal={handleVisiblePrescription}
            scanLabel={onScanPrescription}
            redirectPremiumAccount={handleRedirecPremium}
            manualEntry={onManualPrescription}
            accountType={accountType}
            navigation={navigation}
            fromPopup={autoSuggestionListType.medication}
            journalUserId={journalUserId}
          />
        </Modal>
      )}
    </View>
  );
};

export default AddEventCategoryType;
