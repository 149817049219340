import React from 'react';
import { View, Text } from 'react-native';
import QuestionImage from '../QuestionImage';
import stylesCss from './DescriptionStyle';
import styles from '../../../../Css';
import QuestionTitle from '../../../../components/QuestionTitle/QuestionTitle';

const Description = ({ title, content, imageUrl, questionIndex, required, onSkip, isReadOnly, showTitleSection = false, customDescriptionStyle }) => (
  <>
    {

      Boolean(showTitleSection) && (
        <View style={[{
          marginTop: 20
        }]}>
          <QuestionTitle questionIndex={questionIndex} title={title} required={required} onSkip={onSkip} isReadOnly={isReadOnly} />
        </View>
      )
    }

    {Boolean(imageUrl) && <View style={[stylesCss.questionImageWrapper]}>
      <QuestionImage imageUrl={imageUrl} />
    </View>}
    <View style={[stylesCss.contentWrapper]}>
      {!Boolean(showTitleSection) && (<Text style={[styles.textTitleMainBold]} allowFontScaling={false}>{title}</Text>)}
      <View style={[stylesCss.descriptionWrappertWrapper, customDescriptionStyle  ? stylesCss.leftAlignContent : stylesCss.contentMaxWidth]}>
        <Text style={[styles.textPrime, customDescriptionStyle ? stylesCss.leftAlignText : stylesCss.centeredText]} allowFontScaling={false}>
          {content}
        </Text>
      </View>
    </View>
  </>
);

export default Description;
