import React from 'react';
import {View, Image, Text} from 'react-native';
import styles from '../../Css';
import TextPickerFrame from '../../components/TextInput/textPickerFrame';
import * as FieldDetails from '../../components/TextInput/fieldDetails';
import UIText from '../../components/Text/text';
import stylesResponsive from '../../CssResponsive';
import Picker from '../../components/Picker/picker';

const DateOfBirth = props => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  const {
    _renderDays,
    notRequiredField,
    currentDay,
    invalidDay,
    _renderMonths,
    _handleMonthSelect,
    currentMonth,
    invalidMonth,
    _renderYears,
    currentYear,
    _handleDaySelect,
    invalidYear,
    _handleYearSelect,
    fromSetup,
  } = props;
  return Boolean(fromSetup) ? (
    <View style={[styles.dateOfBirthWrapper, styles.fieldContainer]}>
      <View style={[styles.fieldColOneThirds, styles.fieldRowWrapper]}>
        <View style={[styles.fieldColOneThird]}>
          <View style={[styles.fieldColMax, styles.fledSpRt]}>
            <TextPickerFrame
              title={'Date of Birth'}
              fieldBg={FieldDetails.BGWHITE}
              items={_renderDays}
              fieldAlign={FieldDetails.ALIGNLEFT}
              fieldLt={true}
              requirdStar={notRequiredField ? null : '*'}
              selectedValue={currentDay}
              onValueChange={_handleDaySelect}
              invalid={invalidDay && currentDay === '-1'}
            />
          </View>
        </View>
        <View style={[styles.fieldColOneThird]}>
          <View style={[styles.fieldColMax, styles.fledSpLtRt]}>
            <TextPickerFrame
              title={'Month of Birth'}
              fieldBg={FieldDetails.BGWHITE}
              items={_renderMonths}
              fieldAlign={FieldDetails.ALIGNLEFT}
              fieldLt={true}
              requirdStar={notRequiredField ? null : '*'}
              selectedValue={currentMonth}
              onValueChange={_handleMonthSelect}
              invalid={invalidMonth && currentMonth === '-1'}
            />
          </View>
        </View>
        <View style={[styles.fieldColOneThird]}>
          <View style={[styles.fieldColMax, styles.fledSpLt]}>
            <TextPickerFrame
              title={'Year of Birth'}
              fieldBg={FieldDetails.BGWHITE}
              items={_renderYears}
              fieldAlign={FieldDetails.ALIGNLEFT}
              fieldLt={true}
              requirdStar={notRequiredField ? null : '*'}
              selectedValue={currentYear}
              onValueChange={_handleYearSelect}
              invalid={invalidYear && currentYear === '-1'}
            />
          </View>
        </View>
      </View>
    </View>
  ) : (
    <View style={[styles.dateOfBirthWrapper]}>
      <View style={[styles.fieldColOneThirds]}>
        <View style={[styles.fieldColOneThird]}>
          <View style={[styles.fieldColMax, styles.fledSpRt]}>
            <View
              style={[
                styles.pickerSelectionWrapper,
                invalidDay && currentDay === '-1'
                  ? styles.inValidColor
                  : styles.validColor,
              ]}>
              <Text style={[styles.textView, styles.textViewPicker]} allowFontScaling={false}>
                <UIText
                  style={[
                    styles.textLabel,
                    styles.bold,
                    styles.textColorLight,
                    styles.textLabelSmText,
                  ]}
                  title="Day of Birth"
                  allowFontScaling= {false}
                />
              </Text>
              <View
                style={[stylesRes.dropArrowView, stylesRes.dropArrowTopLg]}
                pointerEvents={'none'}>
                <View style={[stylesRes.dropArrowIcon]}>
                  <Image
                    style={[styles.imgContain]}
                    source={require('../../assets/icons/dropdown-arrow.png')}
                  />
                </View>
              </View>
              <Picker
                items={_renderDays}
                style={[styles.pickerAreaField, styles.selectionPicker]}
                selectedValue={currentDay}
                onValueChange={_handleDaySelect}
              />
            </View>
          </View>
        </View>
        <View style={[styles.fieldColOneThird]}>
          <View style={[styles.fieldColMax, styles.fledSpLtRt]}>
            <View
              style={[
                styles.pickerSelectionWrapper,
                invalidMonth && currentMonth === '-1'
                  ? styles.inValidColor
                  : styles.validColor,
              ]}>
              <Text style={[styles.textView, styles.textViewPicker]} allowFontScaling={false}>
                <UIText
                  style={[
                    styles.textLabel,
                    styles.bold,
                    styles.textColorLight,
                    styles.textLabelSmText,
                  ]}
                  title="Month of Birth"
                  allowFontScaling= {false}
                />
              </Text>
              <View
                style={[stylesRes.dropArrowView, stylesRes.dropArrowTopLg]}
                pointerEvents={'none'}>
                <View style={[stylesRes.dropArrowIcon]}>
                  <Image
                    style={[styles.imgContain]}
                    source={require('../../assets/icons/dropdown-arrow.png')}
                  />
                </View>
              </View>
              <Picker
                items={_renderMonths}
                style={[styles.pickerAreaField, styles.selectionPicker]}
                selectedValue={currentMonth}
                onValueChange={_handleMonthSelect}
              />
            </View>
          </View>
        </View>
        <View style={[styles.fieldColOneThird]}>
          <View style={[styles.fieldColMax, styles.fledSpLt]}>
            <View
              style={[
                styles.pickerSelectionWrapper,
                invalidYear && currentYear === '-1'
                  ? styles.inValidColor
                  : styles.validColor,
              ]}>
              <Text style={[styles.textView, styles.textViewPicker]} allowFontScaling={false}>
                <UIText
                  style={[
                    styles.textLabel,
                    styles.bold,
                    styles.textColorLight,
                    styles.textLabelSmText,
                  ]}
                  title="Year of Birth"
                  allowFontScaling= {false}
                />
              </Text>
              <View
                style={[stylesRes.dropArrowView, stylesRes.dropArrowTopLg]}
                pointerEvents={'none'}>
                <View style={[stylesRes.dropArrowIcon]}>
                  <Image
                    style={[styles.imgContain]}
                    source={require('../../assets/icons/dropdown-arrow.png')}
                  />
                </View>
              </View>
              <Picker
                items={_renderYears}
                style={[styles.pickerAreaField, styles.selectionPicker]}
                selectedValue={currentYear}
                onValueChange={_handleYearSelect}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default (DateOfBirth);
