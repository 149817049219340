import React, { Component } from "react";
import { View, TouchableOpacity, Text, Image, Platform } from "react-native";
import { connect } from "react-redux";
import styles from "../../../Css";
import stylesResponsive from "../../../CssResponsive";
import ActivityIndicator from "../../ActivityIndicator/activityIndicator";
import Graph from "./Graph";
import * as actions from "../../../store/actions/index";
import moment from "moment";
import { hasValue } from "../../../util/hashValueGenerator";
import MouseHover from "../../MouseHover";
import { WEB } from "../../../util/platformWindowConfig";

class GraphSection extends Component {
  constructor(props) {
    super(props);
    const {
      therapies,
      metrics,
      symptoms,
      startDate,
      endDate,
      graphName,
      symptomProps,
      metricProps,
      therapyProps,
    } = props;

    const hash = hasValue(
      JSON.stringify(therapies) +
        "," +
        JSON.stringify(metrics) +
        "," +
        JSON.stringify(symptoms) +
        "," +
        startDate +
        "," +
        endDate +
        "," +
        JSON.stringify(symptomProps) +
        "," +
        JSON.stringify(metricProps) +
        "," +
        JSON.stringify(therapyProps)
    );
    this.state = {
      therapies: therapies,
      metrics: metrics,
      symptoms: symptoms,
      startDate: startDate,
      endDate: endDate,
      graphName: graphName,
      hash: hash,
      symptomProps: symptomProps,
      metricProps: metricProps,
      therapyProps: therapyProps,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      symptoms,
      metrics,
      therapies,
      startDate,
      endDate,
      graphName,
      symptomProps,
      metricProps,
      therapyProps,
    } = props;
    const changeDasValue = hasValue(
      JSON.stringify(therapies) +
        "," +
        JSON.stringify(metrics) +
        "," +
        JSON.stringify(symptoms) +
        "," +
        startDate +
        "," +
        endDate +
        "," +
        JSON.stringify(symptomProps) +
        "," +
        JSON.stringify(metricProps) +
        "," +
        JSON.stringify(therapyProps)
    );
    if (state.hash !== changeDasValue) {
      return {
        therapies: therapies,
        metrics: metrics,
        symptoms: symptoms,
        startDate: startDate,
        endDate: endDate,
        hash: changeDasValue,
        symptomProps: symptomProps,
        metricProps: metricProps,
        therapyProps: therapyProps,
      };
    } else if (graphName !== state.graphName) {
      return {
        graphName: graphName,
      };
    }

    return null;
  }

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();

    const {
      therapies,
      metrics,
      symptoms,
      startDate,
      endDate,
      graphName,
      symptomProps,
      metricProps,
      therapyProps,
    } = this.state;
    const { updateGrph, downloadwait, download, fullWidth } = this.props;
    return (
      <View style={styles.gridRow}>
        <View style={styles.gridColFull}>
          <View
            style={[
              styles.secArea,
              fullWidth > 1279 && Platform.OS === WEB
                ? styles.colPdLtRtZero
                : styles.colPdLtRt,
            ]}
          >
            <View>
              <View syle={stylesRes.detailGraph}>
                {!Boolean(this.props.previewGraph) && (
                  <Graph
                    metrics={metrics}
                    therapies={therapies}
                    symptoms={symptoms}
                    startDate={startDate ? new Date(startDate) : null}
                    endDate={endDate ? new Date(endDate) : null}
                    updateGrph={updateGrph}
                    updateDataFound={this.props.updateDataFound}
                    symptomProps={symptomProps}
                    metricProps={metricProps}
                    therapyProps={therapyProps}
                    graphHeight={Platform.OS === WEB ? 500 : 330}
                    isFromAddEditGraph={true}
                    graphDivId={"chartdiv_graph"}
                    enableZoom={true}
                  />
                )}
              </View>
            </View>
          </View>

          <View
            style={[
              styles.graphContentWrap,
              fullWidth > 1279 && Platform.OS === WEB
                ? styles.colPdLtRtZero
                : styles.colPdLtRt,
            ]}
          >
            <View
              style={[
                styles.graphContentPd,
                styles.menuBgWhite,
                styles.previewGraphView,
              ]}
            >
              <View
                style={[styles.graphText, styles.flexRowWrap, styles.flexAiCt]}
              >
                <View style={[styles.flexCom]}>
                  <Text style={[styles.textPrimeSmBold, styles.textBlack]} allowFontScaling={false}>
                    {graphName}
                  </Text>
                </View>
                <View
                  style={[
                    styles.flexCom,
                    styles.graphTextLg,
                    fullWidth > 1279
                      ? styles.graphDateSpec
                      : fullWidth > 767
                      ? styles.graphDateRangeLg
                      : styles.graphDateRangeSm,
                  ]}
                >
                  <Text
                    style={[
                      styles.textPrimSmBold,
                      styles.textBlack,
                      styles.textAlignCenter,
                    ]}
                    allowFontScaling={false}
                  >
                    {startDate ? "  " + moment(startDate).format("DD MMM 'YY") : ""} -
                    {fullWidth > 767 
                    ? " "
                    : "\n"
                    }
                    {endDate ? moment(endDate).format("DD MMM 'YY") : ""}
                  </Text>
                </View>
                <View style={[styles.flexCom, styles.flexRow, styles.flexJcFe, styles.flexAiCt]}>
                  <View style={[styles.graphIconDownload]}>
                    {downloadwait ? (
                      <View style={styles.pdfWait}>
                        <ActivityIndicator size="small" />
                      </View>
                    ) : (
                      <MouseHover id={"downloadPdf"} text={"Download PDF"} light={true}>
                        <TouchableOpacity
                          onPress={download}
                          disabled={!this.props.dataFound}
                        >
                          <Image
                            style={[
                              styles.pdfLgIcon,
                              !this.props.dataFound
                                ? styles.btnBlurState
                                : styles.btnActiveState
                            ]}
                            source={require("../../../assets/icons/pdf-download.png")}
                          />
                        </TouchableOpacity>
                      </MouseHover>
                    )}
                  </View>
                  <View style={[styles.graphIconInd]}>
                    <MouseHover
                      id={"enterFullscreen"}
                      text={"Enter Fullscreen"}
                      light={true}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          this.props.onPreviewGraph();
                          this.props.hideMobileMenu();
                        }}
                        disabled={!this.props.dataFound}
                      >
                        <Image
                          style={[
                            styles.previewIcon,
                            !this.props.dataFound
                              ? styles.btnBlurState
                              : styles.btnActiveState
                          ]}
                          source={require("../../../assets/icons/preview.png")}
                        />
                      </TouchableOpacity>
                    </MouseHover>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View
            style={[
              fullWidth < 1280 
              ? [styles.bgSepSm, styles.comMgTopRg]
              : styles.comMgTopSm
            ]}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    graphName: state.rollinggraphs.graphName,
    startDate: state.rollinggraphs.startDate,
    endDate: state.rollinggraphs.endDate,
    metrics: state.rollinggraphs.metrics,
    symptoms: state.rollinggraphs.symptoms,
    therapies: state.rollinggraphs.therapies,
    previewGraph: state.rollinggraphs.previewGraph,
    symptomProps: state.rollinggraphs.symptomProps,
    metricProps: state.rollinggraphs.metricProps,
    therapyProps: state.rollinggraphs.therapyProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPreviewGraph: () => dispatch(actions.previewGraph()),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GraphSection);
