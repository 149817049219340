import React from "react";
import { Text, View, TouchableOpacity, ScrollView, Image } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import UIText from "../Text/text";

const addDocumentTypEntry = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <View style={stylesRes.modalScrollSpecCom}>
      <ScrollView style={styles.modalScroller}>
        <View style={styles.popUpBlock}>
          <View style={styles.popUpContentArea}>
            <View style={styles.gridSectionPopup}>
              <View style={styles.popUpContentPdCom}>
                <View style={stylesRes.modalContIn}>
                  <View
                    style={[
                      styles.modalContentView,
                      styles.bgWhite,
                      styles.modalContentMg,
                    ]}
                  >
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.listTypeView}>
                          <View
                            style={[
                              styles.listTypeViewRow,
                              styles.listRowBorder,
                            ]}
                          >
                            <TouchableOpacity
                              onPress={() => props.onPressDocumentType("photo")}
                            >
                              <View
                                style={[styles.listTypeDesc, styles.listItemPd]}
                              >
                                <View style={styles.listItemDetailView}>
                                  <Text style={[styles.texPrimeBold]} allowFontScaling={false}>
                                    Add Photo
                                  </Text>
                                </View>
                                <View style={styles.listItemImglView}>
                                  <View style={styles.listItemImg}>
                                    <Image
                                      style={styles.addAttachIcon}
                                      source={require("../../assets/icons/add-photo-orange.png")}
                                    />
                                  </View>
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                          <View style={styles.listTypeViewRow}>
                            <TouchableOpacity
                              onPress={() =>
                                props.onPressDocumentType("document")
                              }
                            >
                              <View
                                style={[styles.listTypeDesc, styles.listItemPd]}
                              >
                                <View style={styles.listItemDetailView}>
                                  <Text style={[styles.texPrimeBold]} allowFontScaling={false}>
                                    Add Document
                                  </Text>
                                </View>

                                <View style={styles.listItemImglView}>
                                  <View style={styles.listItemImg}>
                                    <Image
                                      style={styles.addAttachIcon}
                                      source={require("../../assets/icons/add-doc-orange.png")}
                                    />
                                  </View>
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={[styles.modalBtnView]}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <TouchableOpacity
                          style={[styles.modalBtn]}
                          underlayColor="rgba(0, 153, 168, 0.8)"
                          onPress={props.onPressCancel}
                        >
                          <View style={[styles.btnFullOval, styles.bgWhite]}>
                            <View style={styles.btnSingleView}>
                              <View
                                style={[styles.btnSingle, styles.btnSingleCent]}
                              >
                                <UIText
                                  style={[
                                    styles.textTitleBold,
                                    styles.textOrange,
                                  ]}
                                  title="Cancel"
                                />
                              </View>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};
export default (addDocumentTypEntry);
