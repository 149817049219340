import React from 'react';
import {Text, View} from 'react-native';
import styles from '../../Css';
import Modal from '../Modal/modal';
import TextInput from '../TextInput/textInput';
import ModalLayout from '../ModalLayout/modalLayout';
import PrimaryButton from '../../components/Button/primaryButton';
import PrimaryButtonWhite from '../../components/Button/primaryButtonWhite';

const deleteAccount = props => {
  return (
    <Modal
      visible={props.visibleModal}
      onRequestClose={props.onRequestClose}
      onBackdropPress={props.onBackdropPress}>
      <ModalLayout
        contPosition={'start'}
        showClose={true}
        pdCom={true}
        hidePopup={props.onBackdropPress}>
        <View style={[styles.popupContentWrapper, styles.flexCom]}>
          <View
            style={[
              styles.popMessageMainView,
              styles.flexCom,
              styles.flexJcCt,
            ]}>
            <View style={[styles.popMessageComView]}>
              <View style={[styles.popMessageTitleView]}>
                <Text
                  style={[
                    styles.textTitleBold,
                    styles.textThickGrey,
                    styles.textAlignCenter,
                  ]}
                  allowFontScaling={false}
                  >
                  We are sorry to see you go!
                </Text>
              </View>
            </View>

            <View style={[styles.popMessageLgView, styles.secBgPdTop]}>
              <View style={[styles.infoPd]}>
                <Text
                  style={[
                    styles.textPrime,
                    styles.textThickGrey,
                    styles.textAlignCenter,
                  ]}
                  allowFontScaling={false}
                  >
                  By deleting your account, <Text style={styles.bold} allowFontScaling={false}>all of your personal data will be <Text style={styles.textEm}>erased</Text> and <Text style={styles.textEm}>unrecoverable</Text>.
                  </Text> Premium plan renewals will stop at the next renewal date.
                </Text>
              </View>

              <View style={[styles.infoPd]}>
                <Text
                    style={[
                      styles.textPrime,
                      styles.textThickGrey,
                      styles.textAlignCenter,
                    ]}
                    allowFontScaling={false}
                    > 
                    Are you sure you want{'\n'}to delete your account?
                </Text>
              </View>

              <View style={[styles.fieldRow]}>
                <View style={[styles.flexJcCtAiCt]}>
                  <View style={[styles.horzComTitle, styles.infoPd]}>
                    <Text style={[styles.textPrime]} allowFontScaling={false}>
                      Please type <Text style={[styles.bold]} allowFontScaling={false}>YES</Text> to
                      confirm.
                    </Text>
                  </View>
                  <TextInput
                    style={[
                      styles.textField,
                      styles.centerText,
                      styles.textFieldDeleteAccount,
                    ]}
                    allowFontScaling={false}
                    onChangeText={props.handleInputConf}
                    value={props.confText}
                    autoFocus={true}
                  />
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.popBtnView, styles.comMgTopSm]}>
            <View style={[styles.btnArea, styles.flexRow]}>
              <View style={styles.fieldBtn}>
                <PrimaryButtonWhite
                  btnText={'Delete Account'}
                  btnPosition={'full'}
                  btnStyle={[styles.darkBtnBorder]}
                  touchableOnPress={props.onPressDelete}
                  btnTextStyle={styles.textColorGreyLight}
                  activityIndStyle={styles.indicatorLtDefaultSm}
                  activityInd={props.pushWait}
                  disabled={!props.didUserConfirm}
                />
              </View>
              <View style={styles.fieldBtnSpSm} />
              <View style={styles.fieldBtnMiniSpec}>
                <PrimaryButton
                  btnText={'Cancel'}
                  btnPosition={'full'}
                  touchableOnPress={props.onBackdropPress}
                  activityInd={props.confirmWait}
                  btnShadow={true}
                  disabled={props.disabled}
                />
              </View>
            </View>
          </View>
        </View>
      </ModalLayout>
    </Modal>
  );
};

export default (deleteAccount);
