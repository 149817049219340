import React from 'react';
import {View, Image} from 'react-native';
import styles from '../../Css';
import ContentDetails from './contentDetails';
import {ORGANIZATION_NAME} from '../../util/commonUiConfig';
import { TAB_BREAk_POINT_START } from '../../util/platformWindowConfig';

const DisplayContent = (
  title,
  description,
  imageUrl,
  title1,
  description1,
  imageUrl1,
  props,
) => {
  let fullWidth = props.fullWidth;
  let rowStyle = styles.flexCol;
  let colStyle = [styles.flexAuto, styles.colPdTopBot];
  let spaceStyle = styles.spaceNone;

  if (fullWidth > TAB_BREAk_POINT_START) {
    rowStyle = [styles.flexRow, styles.comSecRowPd];
    colStyle = styles.flexCom;
    spaceStyle = styles.singleComSpace;
  }
  return (
    <View style={[rowStyle]}>
      {DisplayTextContent(title, description, imageUrl, colStyle)}
      <View style={[spaceStyle]} />
      {DisplayTextContent(title1, description1, imageUrl1, colStyle)}
    </View>
  );
};

const DisplayTextContent = (title, description, imageUrl, colStyle) => {
  return (
    <View style={[colStyle]}>
      <ContentDetails
        imageElement={
          <Image style={[styles.premiumListIcon]} source={imageUrl} />
        }
        title={title}
        description={description}
      />
    </View>
  );
};

const PremiumContent = props => {
  let fullWidth = props.fullWidth;
  let rowStyle = styles.flexCol;
  let colStyleSpec = [styles.flexAuto, styles.colPdTopBot];

  if (fullWidth > TAB_BREAk_POINT_START) {
    rowStyle = [styles.flexRow, styles.comSecRowPd];
    colStyleSpec = [styles.singleSectionHalf];
  }

  return (
    <View style={styles.premiumConteArea}>
      {DisplayContent(
        `Let ${ORGANIZATION_NAME} store everything`,
        'Create a single source of truth. Record unlimited text journal entries, 5,000 images and diagnostic images with 25GB of space.',
        require('../../assets/premium/storage.png'),
        'Find information fast',
        'Search the text in your paper reports and images.',
        require('../../assets/premium/text-convertion.png'),
        props,
      )}

      {DisplayContent(
        'Understand your personalized health data',
        'Gain life-changing insights with unlimited graphing',
        require('../../assets/premium/graphs.png'),
        'Be organized when telling your story',
        'Create unlimited number of reports',
        require('../../assets/premium/reports.png'),
        props,
      )}

      <View style={[rowStyle, styles.noPdBot, styles.flexJcCtAiCt]}>
        {DisplayTextContent(
          'Automated Clinical Trial search',
          'Let us take the burden of finding clinical trials that are best suited for you!',
          require('../../assets/premium/clinical-trial-search.png'),
          colStyleSpec,
        )}
      </View>
    </View>
  );
};

export default (PremiumContent);
