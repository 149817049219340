import theme from "../css/base/theme";
import * as variable from "../css/base/variable";
import { Platform } from "react-native";
import { SCREEN_SIZES } from "../css/base/mediaQueries";
export const cssProfileResponsive = {
  descTextCom: {
    maxWidth: 320,
    ...variable.marginLtRtAuto,
    width: "100%",
    "@media (min-width: 992px)": {
      maxWidth: 340,
    },
  },
  wrapperShadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    ...Platform.select({
      web: {
        boxShadow: "0px 4px 4px #9DC2FF",
      },
    }),
  },
  descTextComSm: {
    maxWidth: 255,
    ...variable.marginLtRtAuto,
    width: "100%",
  },
  boxWrapperIn: {
    maxWidth: 360,
    ...variable.marginLtRtAuto,
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
  },
  boxWrapperInWrapper: {
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
  },
  modalScrollMessageView: {
    ...variable.marginAllAuto,
    maxHeight: "100%",
    width: 310,
    "@media (min-width: 992px)": {
      width: 325,
    },
  },
  contBgTransparent: {
    backgroundColor: 'transparent'
  },
  boxWrapperView: {
    maxWidth: 360,
    ...variable.marginLtRtAuto,
    width: "100%",
    backgroundColor: theme.COLOR_WHITE,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
    marginTop: 20,
    marginBottom: 20,
    "@media (min-width: 992px)": {
      maxWidth: 460,
    },
  },
  contactWrapperView: {
    maxWidth: 360,
    ...variable.marginLtRtAuto,
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
    "@media (min-width: 992px)": {
      maxWidth: 460,
    },
  },
  boxWrapperBottomView: {
    maxWidth: 360,
    ...variable.marginLtRtAuto,
    width: "100%",
    paddingTop: 0,
    paddingBottom: 15,
    "@media (min-width: 992px)": {
      maxWidth: 460,
    },
  },
  boxWrapperViewMediaQuery: {
    "@media (min-width: 992px)": {
      maxWidth: 460,
      marginLeft: 'auto',
      marginRight: 15,
    }
  },
  boxWrapperBottomViewMediaQuery: {
    "@media (min-width: 992px)": {
      maxWidth: 460,
      marginLeft: 'auto',
      marginRight: 15,
      display: 'none',
    },
  },
  botLinkView: {
    "@media (min-width: 992px)": {
      display: 'none',
    },
  },
  containerFullRow: {
    flexDirection: 'row',
    width: '100%'
  },
  containerSingleCol: {
    width: '50%'
  },
  singleColSignUpLeft: {
    alignItems: 'flex-end'
  },
  singleColSignUpRight: {
    alignItems: 'flex-start',
    minHeight: 1,
    "@media (max-width: 991px)": {
      display: 'none',
    },
  },
  boxWrapperViewManagement: {
    maxWidth: 460,
    marginRight: 'auto',
    marginLeft: 15,
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
  descTextComLg: {
    maxWidth: 300,
    ...variable.marginLtRtAuto,
    width: "100%",
  },
  modalRdSm: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
  },
  topImgSpecCom: {
    ...variable.marginLtRtAuto,
    marginTop: 5
  },
  switchProfileFixed: {
    position: 'relative'
  },
  secProcessing: {
    backgroundColor: theme.COLOR_BLUE_BG_LIGHT,
    justifyContent: "center",
    alignItems: "center",
  },
  secStatic: {
    backgroundColor: theme.COLOR_WHITE,
  },
  pdBotZero: {
    paddingBottom: 0
  },
  boxWrapperMain: {
    ...variable.marginLtRtAuto,
  },
  boxWrapperSpec: {
    marginLeft: 'auto',
    marginRight: 15,
  },

  publicBtnSecRes:{
    [`@media ${SCREEN_SIZES.MAX_760}`]: {
      width:220,
      
    },
  },
  userNameRes:{
    marginLeft: 120,
    [`@media ${SCREEN_SIZES.MAX_760}`]: {
     marginLeft:0,
    },
  },

  profileTextRes:{
    marginRight: 126,
    [`@media ${SCREEN_SIZES.MAX_760}`]: {
      marginRight: -22,
      fontSize:14,
     },
  },
  


profileWidthText:{
  [`@media ${SCREEN_SIZES.MAX_760}`]: {
  width:90,
  fontSize:14,
  marginRight:15,
 },

},
maxTextRes:{
  [`@media ${SCREEN_SIZES.MAX_760}`]: {
    maxWidth:180,
    fontSize: 12,
   },
},
searchTextRes:{
  [`@media ${SCREEN_SIZES.MAX_760}`]: {
   width:97,
   fontSize:12,
   },
},
profileWidthTextPrivacy:{
  [`@media ${SCREEN_SIZES.MAX_760}`]: {
    maxWidth:99,
    fontSize:14,

    },
},


mobileText:{
  [`@media ${SCREEN_SIZES.MAX_760}`]: {
    fontSize: 12,
    
   
   },
},
MobileTextPublic:{
  
    fontSize: 12,
    minWidth:20,
    maxWidth:180,
    [`@media ${SCREEN_SIZES.MIN_761}`]: {
    fontSize: 14,
    minWidth:20,
    maxWidth:400,
   
  },
},

mobileTextTwo:{
  [`@media ${SCREEN_SIZES.MAX_760}`]: {
    fontSize: 16,
   },
},
mobileTextThree:{
  [`@media ${SCREEN_SIZES.MAX_760}`]: {
    fontSize: 10,
   },
},
userNameContainerCustomRes:{
  [`@media ${SCREEN_SIZES.MAX_760}`]: {
    marginLeft:0,
    width:160,
    alignItems:'flex-start'
   },
  
},
EnableText:{
  [`@media ${SCREEN_SIZES.MAX_760}`]: {
    width:160,

   },
},

bottomRes:{
 
  [`@media ${SCREEN_SIZES.MAX_480}`]: {
    width:'100%',
    marginTop:10,
  },
  [`@media ${SCREEN_SIZES.MIN_480} and ${SCREEN_SIZES.MAX_768}`]: {
    width:'100%',
    marginTop:10,
  },
  [`@media ${SCREEN_SIZES.MIN_768} and ${SCREEN_SIZES.MAX_1360}`]: {
    width:'100%',
    marginTop:10,
  },
},

mergestyle:{

  [`@media ${SCREEN_SIZES.MAX_480}`]: {
    width: 175,
    marginLeft:8,
  },
  [`@media ${SCREEN_SIZES.MIN_480} and ${SCREEN_SIZES.MAX_760}`]: {
   
    width: 303,
    marginLeft:0,
  
  },
  [`@media ${SCREEN_SIZES.MIN_760} and ${SCREEN_SIZES.MAX_1112}`]: {
    
    width: 265,
    marginLeft:95,
  
  },
  [`@media ${SCREEN_SIZES.MIN_1112} and ${SCREEN_SIZES.MAX_1360}`] :{
     
    width: 356,
    marginLeft:95,
  },
  [`@media ${SCREEN_SIZES.MIN_1360}`]:{
    width: 450,
    marginLeft:95,
    
  },
  },

flexendProfile:{
  [`@media ${SCREEN_SIZES.MAX_760}`]: {  
    paddingRight:0,
  
   },
},

flexWrapCustom:{
  [`@media ${SCREEN_SIZES.MIN_360} and ${SCREEN_SIZES.MAX_1363} `]:{
    flexWrap:"wrap",
    
  }
  
},
paddingCustomnRight:{
  [`@media ${SCREEN_SIZES.MAX_760}`]: {  
  paddingRight:30,
 },
},

closeCustom:{
  [`@media ${SCREEN_SIZES.MAX_760}`]: {  
    marginRight: 10,
    position:'absolute',
    right:5,
 },
},


publicBtnMobile:{
  [`@media ${SCREEN_SIZES.MAX_760}`]: {
    padding: 8,
    marginBottom:5,
    marginRight: 5,
    alignSelf: 'flex-start',
  },
},




}