import React, {Component} from 'react';
import {
  View,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
} from 'react-native';
import moment from 'moment';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import Header from '../../components/Header/header';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import SpaceBar from '../../components/SpaceBar/SpaceBar';
import SavingConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import {TODOS, DASHBOARD} from '../../navigation/path';
import PageContainer from '../../components/PageLayout/PageContainer';
import ButtonPopupToDoMenu from '../../components/PopupMenu/buttonPopupToDoMenu';
import ActivityIndicatorCustom from '../../components/ActivityIndicator/activityIndicator';
import {newLineCharReplacement} from '../../util/stringManipulations';
import {alertBody, todoTitle} from '../../GlobalFunctions/GlobalString';
import MenuOverlayContainer from '../../components/PageLayout/MenuOverlayContainer';
import {handleCommonBackNavigation} from '../../navigation/RootNavigation';
import { pendoTrackEvent } from '../../util/pendoConfig';

class Questions extends Component {
  state = {
    status: false,
    question: '',
    confirmBox: false,
    date: moment(Date.now()).format('MM/DD/YYYY'),
    time: moment(Date.now()).format('h:mm a'),
    showSpinner: false,
  };

  constructor(props) {
    super(props);
    setInterval(() => {
      this.setState({
        date: moment(Date.now()).format('MM/DD/YYYY'),
        time: moment(Date.now()).format('h:mm a'),
      });
    }, 60000);
  }

  componentDidMount = () => {
    this.props.navigation.addListener('focus', () => {
      this.setState({ question: this.props.saveQuestionToAttach });
      this.props.hideMobileMenu();
      pendoTrackEvent('To-Dos/@AddTo-Do');
    });
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    });
  };

  ShowHideTextComponentView = () => {
    if (this.state.status === true) {
      this.setState({status: false});
    } else {
      this.setState({status: true});
    }
  };

  handleOnChange = question => {
    this.setState({question});
  };

  addQuestionToStore = (val) => {
    if ( val === "journal_entry" ) {
      pendoTrackEvent('To-Do@ > AddtoJournal');
      this.props.navigation.push(
        TODOS.todoAttachToJournalPath
      );
    }
    if ( val === "contact" ) {
      pendoTrackEvent('To-Do@ > AddtoContact');
      this.props.navigation.push(
        TODOS.todoAttachToContactPath
      );
    }

    if ( val === "med_activity" ) {
      pendoTrackEvent('To-Do@ > AddtoMed+Act');
      this.props.navigation.push(
        TODOS.todoAttachToTreatmentPath
      );
    }
    this.props.onSaveQuestionToAttach(this.state.question);
  };

  handleCancelButton = () => {
    if (this.state.question.trim() !== '') {
      this.setState({confirmBox: true});
    } else {
      this.handleModalOnClickCancel();
    }
  };

  handleModalOnClickCancel = () => {
    this.setState({question: ''});
    this.props.onSaveQuestionToAttach('');
    if (
      this.props.route?.params &&
      this.props.route?.params.from
    ) {
      this.props.route?.params.from ===
        TODOS.todoAttachToContactPath ||
      this.props.route?.params.from ===
        TODOS.todoAttachToJournalPath ||
      this.props.route?.params.from ===
        TODOS.todoAttachToTreatmentPath
        ? this.props.navigation.reset({
          index: 0,
          routes: [{ name: DASHBOARD.path }],
        })
        : this.props.route?.params.from === TODOS.path 
            ? this.props.navigation.reset({
                index: 0,
                routes: [{ 
                  name: TODOS.path,
                  params: {from: this.props.route.name}
                }],
              }) 
            :  handleCommonBackNavigation(this.props.navigation);
    } else {
      handleCommonBackNavigation(this.props.navigation);

    }
  };

  // New Quick Note
  handleCreateNewRoutine = () => {
    this.setState({showSpinner: true});
    let titleText = `To-do: ${newLineCharReplacement(this.state.question)}`;

    pendoTrackEvent('To-Do@ > Save');
    let newTemplate = {
      id: -1,
      title:
        titleText.length > 34
          ? `${titleText.substring(0, 34).slice(0, -3)}...`
          : titleText,
      entryDate: moment.utc().valueOf(),
      entryType: this.props.applicationSettingsDigest.quickNoteEntryTypeId,
    };
    this.props.onSaveJournalEntry(
      {journalEntry: newTemplate},
      (res, data) => {
        if (res) {
          const currDate = moment.utc().format('x');
          this._createQuestion(data.journalEntry.artifactId, currDate);
        } else {
          this.setState({ showSpinner: false, confirmBox: false, question: '' });
        }
      },
      this.props.journalUserId,
    );
  };

  _createQuestion = (artifactId, openedDate) => {
    const payload = {
      artifactId,
      healthQuestion: {id: -1, content: this.state.question, openedDate},
    };
    const {onSaveHealthQuestion, journalUserId} = this.props;

    onSaveHealthQuestion(
      payload,
      artifactId,
      () => {
        setTimeout(() => {
          this.setState({ showSpinner: false, confirmBox: false, question: '' });
          this.props.navigation.push(
            TODOS.path, {
              from: this.props.route.name,
            },
          );
        }, 1500);
      },
      journalUserId,
    );
  };

  componentWillUnmount() {
    this.props.showMobileMenu();
  }

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    return (
      <View style={[styles.pageBodyWrapper]}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          filterClickHandler={this.handleOnPressFilter}
          clearFiltersHandler={this.handleOnPressClearFilters}
          handleOnPressBackButton={() => {
            this.handleCancelButton();
          }}
          index={TODOS.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        {this.state.question.trim() !== '' && !this.state.showSpinner &&
          <MenuOverlayContainer
            onPressOpen={() => {
              this.setState({confirmBox: true});
            }}
          />
        }
        <ScrollView style={styles.scrollViewArea}>
          <View style={styles.gridSection}>
            <PageContainer>
              <View style={[stylesRes.contentSection, stylesRes.layoutPdFixed]}>
                <View style={styles.gridRow}>
                  <View style={styles.gridColFull}>
                    <View style={[styles.titleBlockCom, styles.colPdBotLg]}>
                      <View style={styles.titleTextBlock}>
                        <Text style={[styles.textTitleBold]} allowFontScaling={false}>
                          Write a To-do
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={[styles.fieldRow]}>
                    <View
                      style={[styles.textEditorView]}
                      nativeID={'resizeTextArea'}>
                      <TextInput
                        style={[
                          styles.textAreaFieldAuto,
                          styles.textEditorHeightLg,
                          styles.fieldShadowSpec,
                          styles.bgWhite,
                          {
                            ...Platform.select({
                              android: {
                                textAlignVertical: 'top',
                              },
                            }),
                          },
                        ]}
                        allowFontScaling={false}
                        multiline={true}
                        numberOfLines={6}
                        value={this.state.question}
                        onChangeText={this.handleOnChange}
                        autoFocus={!this.props.emailVerified ? false : true}
                        placeholder={'Enter your to-do here'}
                      />
                    </View>
                  </View>
                </View>

                <View
                  style={[
                    styles.btnArea,
                    styles.bgWhite,
                    styles.secSpPdTop,
                    styles.btnBottomSp,
                  ]}>
                  <View style={stylesRes.btnViewSec}>
                    <View style={styles.fieldBtn}>
                      <View style={styles.btnBlockFull}>
                        <TouchableOpacity
                          style={[
                            styles.submitSm,
                            styles.borderBtn,
                            styles.bgWhite,
                            this.state.showSpinner
                            ? styles.btnBlurState
                            : styles.btnActiveState,
                          ]}
                          underlayColor="rgba(0, 153, 168, 0.8)"
                          onPress={this.handleCancelButton}
                          disabled={this.state.showSpinner}
                        >
                          <View style={styles.btnSingleView}>
                            <View
                              style={[styles.btnSingle, styles.btnSingleCent]}>
                              <Text
                                style={[
                                  styles.submitBtnTextWhite,
                                  styles.whiteBtnText,
                                ]} allowFontScaling={false}>
                                Cancel
                              </Text>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View style={styles.fieldBtnSpSm} />
                    <View style={styles.fieldBtn}>
                      <View style={styles.btnBlockFull}>
                        <TouchableOpacity
                          style={[
                            styles.submitSm,
                            styles.btnShadowCom,
                            styles.borderBtnTransparent,
                            styles.submitBgOrange,
                            !this.state.question || this.state.question.trim() === '' || this.state.showSpinner
                            ? styles.btnBlurState
                            : styles.btnActiveState,
                          ]}
                          underlayColor="rgba(0, 153, 168, 0.8)"
                          onPress={this.handleCreateNewRoutine}
                          disabled={
                            !this.state.question || this.state.question.trim() === '' || this.state.showSpinner
                          }
                          nativeID={'to-doSave'}>
                          <View
                            style={[
                              styles.btnSingleView,
                              styles.btnSingleViewCom,
                            ]}
                          >
                            <View
                              style={[
                                styles.btnSingle,
                                styles.btnSingleCent,
                                styles.flexAsfs,
                                styles.btnSubmitWithIcon,
                              ]}
                            >
                                {this.state.showSpinner && (
                                    <View
                                    style={[
                                      styles.indicatorIconView,
                                      styles.submitLoaderSm,
                                    ]}
                                  >
                                    <ActivityIndicatorCustom
                                      pdZero={true}
                                      size="small"
                                    />
                                  </View>
                                 )}
                                <View style={styles.btnSubmitSec}>
                                  <Text
                                    style={[
                                      styles.submitBtnTextWhite,
                                      styles.bold,
                                    ]} allowFontScaling={false}>
                                    Save
                                  </Text>
                                </View>
                              </View>
                            </View>
                            <View style={[styles.btnMoreViewCom]}>
                                  <View>
                                    <ButtonPopupToDoMenu
                                      disabled={
                                        !this.state.question || this.state.question.trim() === '' ||
                                        this.state.showSpinner
                                      }
                                      addQuestionToStore={
                                        this.addQuestionToStore
                                      }
                                    />
                                  </View>
                            </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </PageContainer>
          </View>
        </ScrollView>
        {this.state.confirmBox &&
          <SavingConfirmation
            visibleModal={this.state.confirmBox}
            onBackdropPress={() => {
              this.setState({confirmBox: false});
            }}
            onRequestClose={() => {
              this.setState({confirmBox: false});
            }}
            alertBody={alertBody}
            alertTitle={todoTitle}
            handleCancel={() => {
              this.setState({confirmBox: false});
              this.handleModalOnClickCancel();
            }}
            handleConfirm={() => {
              this.setState({confirmBox: false});
              this.handleCreateNewRoutine();
            }}
            confirmWait={this.state.showSpinner}
            disabled={this.state.showSpinner}
          />
        }
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    saveQuestionToAttach: state.question.saveQuestionToAttach,
    journalEntryTypes: state.routines.journalEntryTypes,
    newJournal: state.routineTemplate.newJournal,
    journalUserId: state.uistate.journalUserId,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    emailVerified: state.auth.emailVerified,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveQuestionToAttach: question =>
      dispatch(actions.saveQuestionToAttach(question)),
    onSaveJournalEntry: (journalEntry, callBack, journalUserId) =>
      dispatch(actions.saveJournalEntry(journalEntry, callBack, journalUserId)),
    onSaveHealthQuestion: (question, artifactId, callBack, journalUserId) =>
      dispatch(
        actions.saveHealthQuestion(
          question,
          artifactId,
          callBack,
          journalUserId,
        ),
      ),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Questions);
