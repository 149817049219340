import React, {Component} from 'react';
import { Image, Keyboard } from 'react-native';
import {connect} from 'react-redux';
import _ from "lodash";
import * as actions from '../../../../store/actions/index';
import * as axiosCommon from '../../../../axios/axios-common';
import {
  PEER_2_PEER_USERNAME_VALIDATION,
  CHANGE_PROFILE_EDIT_STATUS,
} from '../../../../store/actions/urls';
import {peerToPeerTabs} from '../../../../Constant/index';
import ProfileComponent from '../../../../components/PeerToPeer/MainSection/Profile/CreateProfileComponent';
import {Platform} from 'react-native';
import {alertBody, alertTitle} from '../../../../GlobalFunctions/GlobalString';
import {pendoTrackEvent} from '../../../../util/pendoConfig';
import {WEB} from '../../../../util/platformWindowConfig';
import styles from '../../../../Css';
import {PEER_TO_PEER, PROFILE} from '../../../../navigation/path';
import {
  EDIT_LIST,
  INCOGNITO_MODE,
  listTypes,
  SAVE_HASH,
  STORY_MODE,
  visibleType,
  USERNAME_SAVE,
  COMMUNITY_CONDITION_KEY,
  COMMUNITY_SYMPTOMS_KEY,
  PERSONAL_DETAILS_KEY,
  LOCATION_DETAILS_KEY,
  COMMUNITY_TREATMENT_KEY
} from './Constraints';
import {sortAlphabeticalOrder} from '../../../../util/commonUiLogic';


const VALUE = 'value';
const SYMPTOM_ID = 'symptomId';
const TREATMENT_ID = 'treatmentId';
const CONDITION_ID = 'conditionId';
const PERSONAL_DETAIL_TYPE = 'personalDetailType';
const LOCATION_DETAIL_TYPE = 'locationType';
const AGE = 'AGE';
const SEX = 'SEX';
const CITY = 'CITY';
const COUNTRY = 'COUNTRY';
class createProfileScreen extends Component {
  constructor(props) {
    super(props);

    const { profile, commiunityDetails } = props;
    const commonStates = {
      saveWait: false,
      invalidUsername: false,
      usernameExist: false,
      confirmBox: false,
      deleteWait: false,
      newUserConditions: [],
      showMoreInfo: false,
      usernameEditable: (profile || {}).username ? false : true,
      editPenVisible: true,
      saveChangeConfirmBox: false,
      enableUserNameSaveCancel: false,
      enableUserNameSave: false,
      emptyUserName: false,
      showUnlockComFeatureModal: false,
      fromProfileEdit: props.route?.params?.from === PROFILE.profileEditPath,
      disableStory: false,
      showMedicalConditionList: false,
      showTreatmentList: false,
      showSymptomList: false,
      showPersonalDetailList: false,
      showLocationDetailList: false,
      onVisibilityChangeWait: false,
      editPenDisabled: false,
      medicalConditionListSaveWait: false,
      treatmentListSaveWait: false,
      symptomListSaveWait: false,
      personalDetailListSaveWait: false,
      locationDetailListSaveWait: false,
      disableListItemSaveButtons: false,
      disableMedicationListEdit: false,
      disableTreatmentListEdit: false,
      disableSymptomListEdit: false,
      disablePersonalDetailListEdit: false,
      disableLocationDetailListEdit: false,
      storyDisableBorder: false,
    };
    if (profile && profile.username) {
      this.state = {
        username: (profile || {}).username,
        id: profile.id ? profile.id : {},
        story: profile.story ? profile.story : '',
        visibility: profile.profileVisibility ? profile.profileVisibility : visibleType.PRIVATE,

        selectedMedicalCondition: commiunityDetails.selectedMedicalCondition
          ? commiunityDetails.selectedMedicalCondition
          : profile?.communityProfileCondition
          ? profile?.communityProfileCondition.filter(item => item.visibility === visibleType.PUBLIC)
          : [],
        selectedTreatments: commiunityDetails.selectedTreatments
          ? commiunityDetails.selectedTreatments
          : profile?.communityProfileTreatments
          ? profile?.communityProfileTreatments.filter(item => item.visibility === visibleType.PUBLIC)
          : [],
        selectedSymptoms: commiunityDetails.selectedSymptoms
          ? commiunityDetails.selectedSymptoms
          : profile?.communityProfileSymptoms
          ? profile?.communityProfileSymptoms.filter(item => item.visibility === visibleType.PUBLIC)
          : [],
        selectedPersonalDetails: commiunityDetails.selectedPersonalDetails
          ? commiunityDetails.selectedPersonalDetails
          : profile?.personalDetails
          ? profile?.personalDetails.filter(item => item.visibility === visibleType.PUBLIC)
          : [],
        selectedLocationDetails: commiunityDetails.selectedLocationDetails
          ? commiunityDetails.selectedLocationDetails
          : profile?.locationDetails
          ? profile?.locationDetails.filter(item => item.visibility === visibleType.PUBLIC)
          : [],

        medicalConditionList: profile?.communityProfileCondition || [],
        treatmentList: profile?.communityProfileTreatments || [],
        symptomList: profile?.communityProfileSymptoms || [],
        personalDetailList: profile?.personalDetails || [],
        locationDetails: profile?.locationDetails || [],
        ...commonStates
      };
    } else {
      this.state = {
        username: '',
        id:'',
        story: '',
        visibility: visibleType.PRIVATE,
        selectedMedicalCondition: [],
        selectedTreatments: [],
        selectedSymptoms: [],
        selectedPersonalDetails: [],
        selectedLocationDetails: [],
        medicalConditionList: [],
        treatmentList: [],
        symptomList: [],
        personalDetailList: [],
        locationDetails: [],
        ...commonStates
      };
    }
    this.unchangeProfile();
    this.scrollViewRef = null;
  }

  componentDidUpdate(prevProps){
    const { commiunityDetails, profile } = this.props;
    if(JSON.stringify(prevProps.commiunityDetails) !== JSON.stringify(commiunityDetails)){
      this.setState({
        selectedMedicalCondition: commiunityDetails.selectedMedicalCondition,
        selectedTreatments:  commiunityDetails.selectedTreatments,
        selectedSymptoms: commiunityDetails.selectedSymptoms,
        selectedPersonalDetails: commiunityDetails.selectedPersonalDetails,
        selectedLocationDetails: commiunityDetails.selectedLocationDetails,
        medicalConditionList: commiunityDetails.medicalConditionList,
        treatmentList: commiunityDetails.treatmentList,
        symptomList: commiunityDetails.symptomList,
        personalDetailList: commiunityDetails.personalDetailList,
        locationDetails: commiunityDetails.locationDetails
      });
    }

    if(prevProps?.profile?.profileVisibility !== profile?.profileVisibility && profile?.profileVisibility !== this.state.visibility){
      this.setState({
        visibility: profile?.profileVisibility,
      });
    }
  }

  componentDidMount = () => {
    const {fromProfileEdit} = this.state;
    const {setCommiunityProfileDetails, commiunityDetails} = this.props;

    this.getPeer2PeerProfileData();    

    this.refUpdate();
    pendoTrackEvent('Community/@Profile');

    setCommiunityProfileDetails({
      username: fromProfileEdit
        ? commiunityDetails.username
        : this.state.username,
      story: fromProfileEdit ? commiunityDetails.story : this.state.story,
      medicalConditionList: fromProfileEdit
        ? commiunityDetails.medicalConditionList
        : this.state.medicalConditionList,
      treatmentList: fromProfileEdit
        ? commiunityDetails.treatmentList
        : this.state.treatmentList,
      symptomList: fromProfileEdit
        ? commiunityDetails.symptomList
        : this.state.symptomList,
      personalDetailList: fromProfileEdit
        ? commiunityDetails.personalDetailList
        : this.state.personalDetailList,
      locationDetails: fromProfileEdit
        ? commiunityDetails.locationDetails
        : this.state.locationDetails,
      selectedMedicalCondition: fromProfileEdit
        ? this.state.selectedMedicalCondition
        : this.state.medicalConditionList.filter(item => item.visibility === visibleType.PUBLIC),
      selectedTreatments: fromProfileEdit
        ? this.state.selectedTreatments
        : this.state.selectedTreatments.filter(item => item.visibility === visibleType.PUBLIC),
      selectedSymptoms: fromProfileEdit
        ? this.state.selectedSymptoms
        : this.state.selectedSymptoms.filter(item => item.visibility === visibleType.PUBLIC),
      selectedPersonalDetails: fromProfileEdit
        ? this.state.selectedPersonalDetails
        : this.state.selectedPersonalDetails.filter(item => item.visibility === visibleType.PUBLIC),
      selectedLocationDetails: fromProfileEdit
        ? this.state.selectedLocationDetails
        : this.state.selectedLocationDetails.filter(item => item.visibility === visibleType.PUBLIC),
      showMedicalConditionList: fromProfileEdit
        ? commiunityDetails.showMedicalConditionList
        : this.state.showMedicalConditionList,
      showTreatmentList: fromProfileEdit
        ? commiunityDetails.showTreatmentList
        : this.state.showTreatmentList,
      showSymptomList: fromProfileEdit
        ? commiunityDetails.showSymptomList
        : this.state.showSymptomList,
      showPersonalDetailList: fromProfileEdit
        ? commiunityDetails.showPersonalDetailList
        : this.state.showPersonalDetailList,
      showLocationDetailList: fromProfileEdit
        ? commiunityDetails.showLocationDetailList
        : this.state.showLocationDetailList,
    });

    if (fromProfileEdit) {
      this.setState({
        username: commiunityDetails.username,
        story: commiunityDetails.story,
        medicalConditionList: commiunityDetails.medicalConditionList,
        treatmentList: commiunityDetails.treatmentList,
        symptomList: commiunityDetails.symptomList,
        personalDetailList: commiunityDetails.personalDetailList,
        locationDetails: commiunityDetails.locationDetails,
        showMedicalConditionList: commiunityDetails.showMedicalConditionList,
        showTreatmentList: commiunityDetails.showTreatmentList,
        showSymptomList: commiunityDetails.showSymptomList,
        showPersonalDetailList: commiunityDetails.showPersonalDetailList,
        showLocationDetailList: commiunityDetails.showLocationDetailList,
      });
    }

    this.disableListEdit();
  };


  getPeer2PeerProfileData = () => {
    this.props.getPeer2PeerProfileData((success, res) => {
      if (success) {
        if (Boolean(res?.isEnabledPopup)) {
          this.setState({
            showUnlockComFeatureModal: true,
          });
        }
      }
    });   
  }

  componentWillUnmount = () => {
    this.props.navigation.setParams({from: ''});
  }
  
  refUpdate = () => {
    const {getRef} = this.props;
    getRef && getRef(this);
  };

  unchangeProfile = () => {
    axiosCommon.axiosGET(CHANGE_PROFILE_EDIT_STATUS, res => {});
  };

  onCickEditMainProfile = () => {
    this.props.navigation.push(PROFILE.profileEditPath, {
      from: PEER_TO_PEER.path,
    });
  };

  onCloseUnlockComFeatureModal = () => {
    this.setState({showUnlockComFeatureModal: false});
  };

  onUploadP2PImage = () => {
    const {onUpdateShowPeerProfileScreen, journalUserId, onUploadp2p} = this.props;
    const {formData} = this.state;
    onUpdateShowPeerProfileScreen(true, journalUserId, res => {
      if (formData) {
        onUploadp2p(formData, (success, message) => {
        });
      }
    });
  };

  scrollScreen(x, y, animated) {
    setTimeout(() => {
      if (Platform.OS === WEB) {
        if (
          this.scrollViewRef?.scrollTo &&
          typeof this.scrollViewRef?.scrollTo === 'function'
        ) {
          this.scrollViewRef?.scrollTo({x, y, animated});
        }
      } else {
        if (
          this.scrollViewRef?.scrollToPosition &&
          typeof this.scrollViewRef?.scrollToPosition === 'function'
        ) {
          this.scrollViewRef?.scrollToPosition(x, y, animated);
        }
      }
    }, 500);
  }

  checkInvalidateUserNameValue = () => {
    const {username} = this.state;

    if (!username || (username && username?.trim() === '')) {
      return 'Username can not be empty.';
    } else if (username && username.length > 20) {
      return 'Username exceeds the character limit.';
    }else{
      return false;
    }
  }

  handleOnSaveProfile = type => {
    const {commonErrorHandler, profile , updatePeer2PeerProfile, journalUserId,} = this.props;
    const {username, story, visibility, } = this.state;


    let p2pProfile = {
      userName:  [USERNAME_SAVE , SAVE_HASH].includes(type) ? username?.trim() : (profile || {}).username,
      story: [STORY_MODE , SAVE_HASH].includes(type) ? story :  profile.story,
      visibility: visibility ? visibility : visibleType.PRIVATE,
    };

    this.setState({saveWait: Boolean(type !== STORY_MODE)});
    

    if (type === INCOGNITO_MODE) {
      this.setState({
        onVisibilityChangeWait: true,
      });
    }else if([USERNAME_SAVE , SAVE_HASH].includes(type)){
      const invalidated = this.checkInvalidateUserNameValue();
      if(invalidated){
        this.setState({invalidUsername: true, saveWait: false , usernameExist: false, userNameTaken: false});
        commonErrorHandler(invalidated);
        if (this.scrollViewRef) {
          this.scrollScreen(0, 0, true);
        }
        return;
      }
    }else if([STORY_MODE , SAVE_HASH].includes(type) ){
      if (story && story.length > 500) {
        this.setState({usernameExist: false , userNameTaken: false});
        commonErrorHandler('Your story exceeds the character limit.');
        if (this.scrollViewRef && this.scrollViewRef.scrollToEnd) {
          setTimeout(
            () => this.scrollViewRef.scrollToEnd({animated: true}),
            500,
          );
        }
        return;
      }
    }
    Keyboard.dismiss();
    pendoTrackEvent('Community > @Profile > Save');
    updatePeer2PeerProfile(
      p2pProfile,
      res => {
        if (res) {
          this.onUploadP2PImage();
          this.setState(prevState => {
            return {
              saveWait: false,
              enableUserNameSaveCancel: type === USERNAME_SAVE ? false : prevState.enableUserNameSaveCancel,
              onVisibilityChangeWait: false,
              disableStory: false,
              removeIcon: type === USERNAME_SAVE ? false : prevState.removeIcon,
              editPenVisible: type === USERNAME_SAVE ? true : prevState.editPenVisible,
              invalidUsername:false,
              usernameEditable: type === USERNAME_SAVE ? false : prevState.usernameEditable,
              usernameExist: false,
              userNameTaken: false,
              emptyUserName: false,
              saveChangeConfirmBox: false
            };
          }, ()=> {
            if((USERNAME_SAVE).includes(type) && (profile || {}).username === null){
              this.getPeer2PeerProfileData();    
            }
          });
        } else {
          this.setState({saveWait: false, onVisibilityChangeWait: false});
        }
      },
      journalUserId,
    );
    
  };

  onUsernameChange = value => {
    const {profile, OnSaveChangesP2PProfile} = this.props;
    let invalidUsername = false;
    let enableUserNameSave= true;
    let emptyUserName = false;
    let enableUserNameSaveCancel = true;
    let p2pProfileHasDataToSave = true;

    const profileUserName = !(profile || {}).username ? null : (profile || {}).username;
    const userNameCopy = !value?.trim() || value?.trim() === '' ? null : value?.trim();

    if (userNameCopy && userNameCopy.trim() !== '' && userNameCopy.trim().length > 20) {
        invalidUsername = true;
        enableUserNameSave = false;
    } else if (!userNameCopy || (userNameCopy && userNameCopy.trim() === '')){
        invalidUsername= true;
        emptyUserName= true;
        enableUserNameSave= false;
        enableUserNameSaveCancel = false;
    } else if (profileUserName === userNameCopy){
        p2pProfileHasDataToSave = false;
        enableUserNameSave= false;
        enableUserNameSaveCancel = false;
    }else {
        invalidUsername= false;
        enableUserNameSaveCancel = true;
        enableUserNameSave= true;
    }
    this.setState({
      username: value,
      usernameExist: false,
      userNameTaken: false,
      removeIcon: true,
      enableUserNameSaveCancel,
      invalidUsername,
      enableUserNameSave,
      emptyUserName
    }, () => {
      if(value && value.trim() !== '') this.validateUsername();
    });
    OnSaveChangesP2PProfile(p2pProfileHasDataToSave);
  };

  handleSaveProfileDetailsInPopup=()=>{

    const saveOtherDetails = new Promise((resolve, reject) => {
      this.handleOnSaveHasList(SAVE_HASH);
      setTimeout(() => {
        resolve();
      }, 500);
    });
    saveOtherDetails.then(() => {
      if(this.state.username !== this.props.profile?.username){
        this.handleOnSaveProfile(SAVE_HASH);
      }
      this.setState({
        saveChangeConfirmBox: false,
      });
    })    
  }

  onBlurUsername = () => {
    setTimeout(() => {
      const {profile} = this.props;
      const { username} =this.state;
      
      const profileUserName = !(profile || {}).username ? null : (profile || {}).username;
      const userNameCopy = !username?.trim() || username?.trim() === '' ? null : username?.trim();

      const userNameHasChanged = profileUserName === userNameCopy ? false : true;
      this.setState({
        removeIcon: userNameHasChanged ? true :false,
        editPenVisible: userNameHasChanged ? false : true,
        enableUserNameSaveCancel : userNameHasChanged ? true : false,
        usernameEditable: false,
        disableStory: false
      });
    }, 500);
    
  }

  validateUsername = (fromValueChange) => {
    const {username, invalidUsername} = this.state;
    const {profile, setCommiunityProfileDetails, commiunityDetails} =
      this.props;
    if (
      username &&
      username.trim() !== '' &&
      (username.trim() !== profile?.username || !profile) &&
      !invalidUsername
    ) {
      axiosCommon.axiosPOST(
        PEER_2_PEER_USERNAME_VALIDATION,
        res => {
          console.log(res);
          if(!this.state.username || (this.state.username && this.state.username.trim() === '')) return;
          if (res && res.type === "SUCCESS") {
            this.setState({
              usernameExist: true,
              enableUserNameSave: true,
              removeIcon: true,
              editPenVisible: false,
              userNameTaken : false
            });
          } else {
            this.setState({
              usernameExist: false,
              enableUserNameSave: false,
              removeIcon: true,
              editPenVisible: false,
              userNameTaken: true
            });
          }
        },
        {username: username?.trim()},
        setCommiunityProfileDetails({
          ...commiunityDetails,
          username: username?.trim(),
        }),
      );
    }
  };

  onBlurStory = () => {
    this.handleOnSaveProfile(STORY_MODE);
  };

  onChangeStory = value => {
    const {setCommiunityProfileDetails, commiunityDetails } = this.props;
    let storyDisableBorder = false;

    if(value.length > 500) storyDisableBorder = true;
    this.setState({story: value ? value : '', storyDisableBorder});
    setCommiunityProfileDetails({...commiunityDetails, story: value});
  };

  onChangeTextName = selection => {
    this.setState({
      name: selection,
      inValidName: false,
    });
  };

  disableListEdit = () => {
    const {
      medicalConditionList,
      symptomList,
      treatmentList,
      personalDetailList,
    } = this.state;

    if (medicalConditionList.length === 0) {
      this.setState({
        disableMedicationListEdit: true,
      });
    } else if (symptomList.length === 0) {
      this.setState({
        disableSymptomListEdit: true,
      });
    } else if (treatmentList.length === 0) {
      this.setState({
        disableTreatmentListEdit: true,
      });
    } else if (personalDetailList.length === 0) {
      this.setState({
        disablePersonalDetailListEdit: true,
      });
    } else {
      this.setState({
        disableLocationDetailListEdit: true,
      });
    }
  };

  onVisibilityChange = value => {
    this.setState({
      visibility: value ? visibleType.PRIVATE : visibleType.PUBLIC
    }, () => {
      this.handleOnSaveProfile(INCOGNITO_MODE);
    });  
  };

  handleEditUsername = () => {
    this.setState(prevState => {
      return {
      removeIcon: true,
      usernameEditable: true,
      disableStory: true,
      editPenVisible: false,
    }});
  };
  
  handleClearUsername = () => {
    this.setState({
      username: '',
      removeIcon: false,
      enableUserNameSaveCancel: true,
      usernameExist: false,
      userNameTaken: false,
      invalidUsername: true,
      emptyUserName: true,
      enableUserNameSave: false,
    });    
  };

  handleCancelUserName = () => {
    const {profile} = this.props;
    const {setCommiunityProfileDetails, commiunityDetails} = this.props;

    this.setState({
      username: (profile || {}).username,
      removeIcon: false,
      editPenVisible: true,
      invalidUsername:false,
      usernameEditable:false,
      usernameExist: false,
      userNameTaken: false,
      emptyUserName: false,
      enableUserNameSaveCancel: false,
      disableStory: false,
    });
    setCommiunityProfileDetails({
      ...commiunityDetails,
      username: (profile || {}).username,
    });
  };

  handleOnSubmitCancel = () => {
    if (this.getP2pProfileHasDataToSave()) {
      this.setState({
        saveChangeConfirmBox: true,
      });
    } else {
      this.handleCancelConfirm();
    }
  };

  handleOnClickEditCancelItems = (listType, type) => {
    const {setCommiunityProfileDetails, commiunityDetails, profile} = this.props;

    const medicalConditionList = profile.communityProfileCondition;
    const treatmentList = profile?.communityProfileTreatments;
    const symptomList = profile?.communityProfileSymptoms;
    const personalDetailList = profile?.personalDetails;
    const locationDetails = profile?.locationDetails;

    

    this.setState({
      disableStory: type === EDIT_LIST ?? true,
      editPenDisabled: type === EDIT_LIST ?? true,
    });

    if (listType === listTypes.MEDICAL_CONDITIONS) {
      if (type === EDIT_LIST) {
        setCommiunityProfileDetails({
          ...commiunityDetails,
          showMedicalConditionList: true,
        });
      } else {
        setCommiunityProfileDetails({
          ...commiunityDetails,
          medicalConditionList: medicalConditionList,
          selectedMedicalCondition: medicalConditionList?.filter(item => item.visibility === visibleType.PUBLIC),
          showMedicalConditionList: false,
        });
      }
    }
    if (listType === listTypes.TREATMENTS) {
      if (type === EDIT_LIST) {
        setCommiunityProfileDetails({
          ...commiunityDetails,
          showTreatmentList: true,
        });
      } else {
        setCommiunityProfileDetails({
          ...commiunityDetails,
          treatmentList: treatmentList,
          selectedTreatments: treatmentList?.filter(item => item.visibility === visibleType.PUBLIC),
          showTreatmentList: false,
        });
      }
    }
    if (listType === listTypes.SYMPTOMS) {
      if (type === EDIT_LIST) {
        setCommiunityProfileDetails({
          ...commiunityDetails,
          showSymptomList: true,
        });
      } else {
        setCommiunityProfileDetails({
          ...commiunityDetails,
          symptomList: symptomList,
          selectedSymptoms: symptomList?.filter(item => item.visibility === visibleType.PUBLIC),
          showSymptomList: false,
        });
      }
    }
    if (listType === listTypes.PERSONAL_DETAILS) {
      if (type === EDIT_LIST) {
        setCommiunityProfileDetails({
          ...commiunityDetails,
          showPersonalDetailList: true,
        });
      } else {
        setCommiunityProfileDetails({
          ...commiunityDetails,
          personalDetailList: personalDetailList,
          selectedPersonalDetails: personalDetailList?.filter(
            item => item.visibility === visibleType.PUBLIC,
          ),
          showPersonalDetailList: false,
        });
      }
    }
    if (listType === listTypes.LOCATION_DETAILS) {
      if (type === EDIT_LIST) {
        setCommiunityProfileDetails({
          ...commiunityDetails,
          showLocationDetailList: true,
        });
      } else {
        setCommiunityProfileDetails({
          ...commiunityDetails,
          locationDetails: locationDetails,
          selectedLocationDetails: locationDetails?.filter(
            item => item.visibility === visibleType.PUBLIC,
          ),
          showLocationDetailList: false,
        });
      }
    }

    switch (listType) {
      case listTypes.MEDICAL_CONDITIONS:
        this.setState({showMedicalConditionList: type === EDIT_LIST ?? true});
        break;
      case listTypes.TREATMENTS:
        this.setState({showTreatmentList: type === EDIT_LIST ?? true});
        break;
      case listTypes.SYMPTOMS:
        this.setState({showSymptomList: type === EDIT_LIST ?? true});
        break;
      case listTypes.PERSONAL_DETAILS:
        this.setState({showPersonalDetailList: type === EDIT_LIST ?? true});
        break;
      case listTypes.LOCATION_DETAILS:
        this.setState({showLocationDetailList: type === EDIT_LIST ?? true});
        break;
      default:
        return false;
    }
  };

  getVisibilityForBios = (dataList, keyType ,type) => {
    if(dataList.filter(item => item[keyType] === type)[0].visibility === visibleType.PUBLIC){
      return 'PUBLIC'
    }else {
      return 'PRIVATE'
    }
  }

  handleOnSaveHasList = (type) => {
    let {profile, OnSaveChangesP2PProfile, OnSetPeerToPeerActiveTab} = this.props;

    const {
      medicalConditionList,
      treatmentList,
      symptomList,
      personalDetailList,
      locationDetails,
    } = this.state;

    const sortedStateMedicalCondtionList = sortAlphabeticalOrder(medicalConditionList);
    const sortedStateTreatmentList = sortAlphabeticalOrder(treatmentList);
    const sortedStateStymptomList = sortAlphabeticalOrder(symptomList);

    const sortedReduxMedicalCondtionList = sortAlphabeticalOrder(profile?.communityProfileCondition || []);
    const sortedReduxTreatmentList = sortAlphabeticalOrder(profile?.communityProfileTreatments || []);
    const sortedReduxStymptomList = sortAlphabeticalOrder(profile?.communityProfileSymptoms || []);
    
    if(JSON.stringify(sortedStateTreatmentList) !== JSON.stringify(sortedReduxTreatmentList)){
      this.handleOnClickSaveItems(listTypes.TREATMENTS);
    }
    if(JSON.stringify(sortedStateMedicalCondtionList) !== JSON.stringify(sortedReduxMedicalCondtionList)){
      this.handleOnClickSaveItems(listTypes.MEDICAL_CONDITIONS);
    }
    if(JSON.stringify(sortedStateStymptomList) !== JSON.stringify(sortedReduxStymptomList)){
      this.handleOnClickSaveItems(listTypes.SYMPTOMS);
    }
    if(JSON.stringify(personalDetailList) !== JSON.stringify(profile?.personalDetails || [])){
      this.handleOnClickSaveItems(listTypes.PERSONAL_DETAILS);
    }
    if(JSON.stringify(locationDetails) !== JSON.stringify(profile?.locationDetails || [])){
      this.handleOnClickSaveItems(listTypes.LOCATION_DETAILS);
    }

    setTimeout(() => {
      OnSaveChangesP2PProfile(false);
      OnSetPeerToPeerActiveTab(peerToPeerTabs.myNetwork);
    }, 500);
    
   
  }

  handleOnClickSaveItems = type => {
    const {
      id,
      locationDetails,
      personalDetailList,
      medicalConditionList,
      symptomList,
      treatmentList,
    } = this.state;

    const { setCommiunityProfileDetails, commiunityDetails} = this.props;

    let p2pProflie = {}
    switch (type) {
      case listTypes.MEDICAL_CONDITIONS:
        this.setState({
          medicalConditionListSaveWait: true,
          disableListItemSaveButtons: true,
        });
        p2pProflie = {
          peerProfileId: id,
          conditionsList: medicalConditionList,
        }
        break;
      case listTypes.TREATMENTS:
        this.setState({
          treatmentListSaveWait: true,
          disableListItemSaveButtons: true,
        });
        p2pProflie = {
          peerProfileId: id,
          treatmentsList: treatmentList,
        }
        break;
      case listTypes.SYMPTOMS:
        this.setState({
          symptomListSaveWait: true,
          disableListItemSaveButtons: true,
        });
        p2pProflie = {
          peerProfileId: id,
          symptomsList: symptomList,
        }
        break;
      case listTypes.PERSONAL_DETAILS:
        this.setState({
          personalDetailListSaveWait: true,
          disableListItemSaveButtons: true,
        });
        p2pProflie = {
          peerProfileId: id,
          ageVisibility: this.getVisibilityForBios(personalDetailList, PERSONAL_DETAIL_TYPE, AGE),
          sexVisibility: this.getVisibilityForBios(personalDetailList, PERSONAL_DETAIL_TYPE, SEX),
        }
        break;
      case listTypes.LOCATION_DETAILS:
        this.setState({
          locationDetailListSaveWait: true,
          disableListItemSaveButtons: true,
        });
        p2pProflie = {
          peerProfileId: id,
          cityVisibility: this.getVisibilityForBios(locationDetails, LOCATION_DETAIL_TYPE, CITY),
          countryVisibility: this.getVisibilityForBios(locationDetails, LOCATION_DETAIL_TYPE, COUNTRY),
        }
        break;
      default:
        return false;
    }

    const {updatePeer2PeerProfileListItems, journalUserId} = this.props;

    updatePeer2PeerProfileListItems(
      type,
      p2pProflie,
      res => {
        if (res) {

          let stateKey = null;
          if (type === listTypes.MEDICAL_CONDITIONS) { 
            stateKey = 'showMedicalConditionList';
          }else if(type === listTypes.TREATMENTS){
            stateKey = 'showTreatmentList';
          }else if(type === listTypes.SYMPTOMS){
            stateKey = 'showSymptomList';
          }else if(type === listTypes.PERSONAL_DETAILS){
            stateKey = 'showPersonalDetailList';
          }else if(type === listTypes.LOCATION_DETAILS){
            stateKey = 'showLocationDetailList';
          }

          if(stateKey){
            this.setState({[stateKey]: false}, () => {
              setCommiunityProfileDetails({
                ...commiunityDetails,
                [stateKey]: false,
              });
            });
          }

        }
        this.setState({
          medicalConditionListSaveWait: false,
          treatmentListSaveWait: false,
          symptomListSaveWait: false,
          personalDetailListSaveWait: false,
          locationDetailListSaveWait: false,
          disableListItemSaveButtons: false,
        });
      },
      journalUserId,
    );

    
  }; 

  handleCancelConfirm = () => {
    const {
      OnSetPeerToPeerActiveTab,
      OnSaveChangesP2PProfile,
    } = this.props;

    OnSaveChangesP2PProfile(false);
    OnSetPeerToPeerActiveTab(peerToPeerTabs.myNetwork);
  };
 
  removeUnwantedKeys = (obj) => {
    if(Boolean(obj['createdOn'])) delete obj['createdOn']; 
    if(Boolean(obj['modifiedOn'])) delete obj['modifiedOn'];
  }

  getP2pProfileHasDataToSave = () => {
    const {OnSaveChangesP2PProfile} = this.props;
    let {profile} = this.props;

    if (!profile) profile = {};
    const {
      username,
      medicalConditionList,
      treatmentList,
      symptomList,
      personalDetailList,
      locationDetails,
    } = this.state;
    let p2pProfileHasDataToSave = null;
    const sortedMedicalCondtionList = sortAlphabeticalOrder(medicalConditionList);
    const sortedTreatmentList = sortAlphabeticalOrder(treatmentList);
    const sortedStymptomList = sortAlphabeticalOrder(symptomList);

    const sortedCommunityProfileCondition = sortAlphabeticalOrder(profile?.communityProfileCondition || []);
    sortedCommunityProfileCondition.forEach(obj => {
      this.removeUnwantedKeys(obj)
    })
    const sortedCommunityProfileTreatments = sortAlphabeticalOrder(profile?.communityProfileTreatments || []);
    sortedCommunityProfileTreatments.forEach(obj => {
      this.removeUnwantedKeys(obj)
    })
    const sortedCommunityProfileSymptoms = sortAlphabeticalOrder(profile?.communityProfileSymptoms || []);
    sortedCommunityProfileSymptoms.forEach(obj => {
      this.removeUnwantedKeys(obj)
    })
    const profileUserName = !(profile || {}).username ? null : (profile || {}).username;
    const userNameCopy = !username?.trim() || username?.trim() === '' ? null : username?.trim();

    if (
      profileUserName === userNameCopy &&
      JSON.stringify(sortedMedicalCondtionList) ===
        JSON.stringify(sortedCommunityProfileCondition) &&
      JSON.stringify(sortedTreatmentList) ===
        JSON.stringify(sortedCommunityProfileTreatments) &&
      JSON.stringify(sortedStymptomList) ===
        JSON.stringify(sortedCommunityProfileSymptoms) &&
      JSON.stringify(personalDetailList) ===
        JSON.stringify(profile?.personalDetails || []) &&
      JSON.stringify(locationDetails) ===
        JSON.stringify(profile?.locationDetails || [])
    ) {
      p2pProfileHasDataToSave = false;
    } else {
      p2pProfileHasDataToSave = true;
    }

    OnSaveChangesP2PProfile(p2pProfileHasDataToSave);
    return p2pProfileHasDataToSave;
  };

  onPressItem = (item, selectedItem, itemList, idKey) => {
    let temp = [];
    let selectedItemList = {};
    let flag = 0;
    let allTemp = [];

    if (selectedItem && selectedItem.length > 0) {
      selectedItem.forEach(res => {
        if (res && res[idKey] === item[idKey]) {
          temp = selectedItem.filter(re => re[idKey] !== item[idKey]);
          flag = 1;
        }
      });
      if (flag !== 1) {
        temp = [...selectedItem];
        selectedItemList = {
          [idKey]: item[idKey],
          name: item.name,
          visibility: visibleType.PUBLIC,
        };
        temp.push(selectedItemList);
        allTemp = this.updateItemsListVisibility(item, itemList, visibleType.PUBLIC, idKey);
      } else {
        allTemp = this.updateItemsListVisibility(item, itemList, visibleType.PRIVATE, idKey);
      }
    } else {
      selectedItemList = {
        [idKey]: item[idKey],
        name: item.name,
        visibility: item.visibility,
      };
      temp.push(selectedItemList);
      allTemp = this.updateItemsListVisibility(item, itemList, visibleType.PUBLIC , idKey);
    }
    return [temp, allTemp];
  };

  checkBoxTick = (itemId, type, idKey) => {
    const {
      selectedMedicalCondition,
      selectedTreatments,
      selectedSymptoms,
      selectedPersonalDetails,
      selectedLocationDetails,
    } = this.state;

    const selectedItems =
      type === listTypes.MEDICAL_CONDITIONS
        ? selectedMedicalCondition
        : type === listTypes.TREATMENTS
        ? selectedTreatments
        : type === listTypes.SYMPTOMS
        ? selectedSymptoms
        : type === listTypes.PERSONAL_DETAILS
        ? selectedPersonalDetails
        : type === listTypes.LOCATION_DETAILS
        ? selectedLocationDetails
        : [];

    if (selectedItems && selectedItems.length > 0) {
      let flag = 0;
      selectedItems.forEach(re => {
        return re[idKey] === itemId ? (flag = 1) : null;
      });

      return (
        <Image
          style={styles.radioIcon}
          source={
            flag === 1
              ? require('../../../../assets/toggleTick/right.png')
              : require('../../../../assets/toggleTick/check-untick.png')
          }
        />
      );
    } else {
      return (
        <Image
          style={styles.radioIcon}
          source={require('../../../../assets/toggleTick/check-untick.png')}
        />
      );
    }
  };

  updateItemsListVisibility = (item, itemListHard, visibility, idKey) => {
    const itemList = _.cloneDeep(itemListHard);
    let selectedIndex = itemList.findIndex(res => res[idKey] === item[idKey]);
    if(selectedIndex > -1) itemList[selectedIndex].visibility = visibility;

    return itemList;
  };

  hasP2pProfileChangedValue = (list, idKey, type) =>{
    const {profile, OnSaveChangesP2PProfile} = this.props;
    let p2pProfileHasDataToSave = null;
    
    const sortedStateList = type ? list : sortAlphabeticalOrder(list);
    const sortedReduxList = type ? profile[idKey] : sortAlphabeticalOrder(profile[idKey] || []);
    if(JSON.stringify(sortedStateList) === JSON.stringify( sortedReduxList)){
      p2pProfileHasDataToSave = false;
    }else {
      p2pProfileHasDataToSave = true;
    }
    OnSaveChangesP2PProfile(p2pProfileHasDataToSave);
  }

  onPressUserMedicationCondition = item => {
    const {setCommiunityProfileDetails, commiunityDetails} = this.props;
    const {selectedMedicalCondition, medicalConditionList} = this.state;

    const [selectedList, allList] = this.onPressItem(
      item,
      selectedMedicalCondition,
      medicalConditionList,
      CONDITION_ID
    );
    this.setState({
      selectedMedicalCondition: selectedList,
      medicalConditionList: allList,
    });
    setCommiunityProfileDetails({
      ...(_.cloneDeep(commiunityDetails)),
      selectedMedicalCondition: selectedList,
      medicalConditionList: allList,
    });
    this.hasP2pProfileChangedValue(allList, COMMUNITY_CONDITION_KEY)
  };

  onPressTreatment = item => {
    const {setCommiunityProfileDetails, commiunityDetails} = this.props;
    const {selectedTreatments, treatmentList} = this.state;

    const [selectedList, allList] = this.onPressItem(
      item,
      selectedTreatments,
      treatmentList,
      TREATMENT_ID
    );
    this.setState({
      selectedTreatments: selectedList,
      treatmentList: allList,
    });

    setCommiunityProfileDetails({
      ...commiunityDetails,
      selectedTreatments: selectedList,
      treatmentList: allList,
    });
    this.hasP2pProfileChangedValue(allList, COMMUNITY_TREATMENT_KEY)
  };

  onPressSymptom = item => {
    const {setCommiunityProfileDetails, commiunityDetails} = this.props;
    const {selectedSymptoms, symptomList} = this.state;

    const [selectedList, allList] = this.onPressItem(
      item,
      selectedSymptoms,
      symptomList,
      SYMPTOM_ID
    );
    this.setState({
      selectedSymptoms: selectedList,
      symptomList: allList,
    });

    setCommiunityProfileDetails({
      ...commiunityDetails,
      selectedSymptoms: selectedList,
      symptomList: allList,
    });
    this.hasP2pProfileChangedValue(allList, COMMUNITY_SYMPTOMS_KEY)
  };

  onPressPersonalDetail = item => {
    const {setCommiunityProfileDetails, commiunityDetails} = this.props;
    const {selectedPersonalDetails, personalDetailList} = this.state;

    const [selectedList, allList] = this.onPressItem(
      item,
      selectedPersonalDetails,
      personalDetailList,
      VALUE
    );
    this.setState({
      selectedPersonalDetails: selectedList,
      personalDetailList: allList,
    });
    setCommiunityProfileDetails({
      ...commiunityDetails,
      selectedPersonalDetails: selectedList,
      personalDetailList: allList,
    });
    this.hasP2pProfileChangedValue(allList, PERSONAL_DETAILS_KEY, true)
  };

  onPressLocationDetail = item => {
    const {setCommiunityProfileDetails, commiunityDetails} = this.props;
    const {selectedLocationDetails, locationDetails} = this.state;

    const [selectedList, allList] = this.onPressItem(
      item,
      selectedLocationDetails,
      locationDetails,
      VALUE
    );
    this.setState({
      selectedLocationDetails: selectedList,
      locationDetails: allList,
    });
    setCommiunityProfileDetails({
      ...commiunityDetails,
      selectedLocationDetails: selectedList,
      locationDetails: allList,
    });
    this.hasP2pProfileChangedValue(allList, LOCATION_DETAILS_KEY, true)
  };

  render() {
    const {profile, journalUserId} = this.props;
    const {
      saveWait,
      username,
      invalidUsername,
      usernameExist,
      name,
      newUserConditions,
      story,
      confirmBox,
      deleteWait,
      visibility,
      showMoreInfo,
      usernameEditable,
      editPenVisible,
      removeIcon,
      saveChangeConfirmBox,
      inValidName,
      formData,
      enableUserNameSave,
      enableUserNameSaveCancel,
      medicalConditionList,
      treatmentList,
      symptomList,
      personalDetailList,
      locationDetails,
      showUnlockComFeatureModal,
      disableStory,
      showList,
      showMedicalConditionList,
      showTreatmentList,
      showSymptomList,
      showPersonalDetailList,
      showLocationDetailList,
      onVisibilityChangeWait,
      editPenDisabled,
      medicalConditionListSaveWait,
      symptomListSaveWait,
      treatmentListSaveWait,
      locationDetailListSaveWait,
      personalDetailListSaveWait,
      disableListItemSaveButtons,
      disableMedicationListEdit,
      disableTreatmentListEdit,
      disableSymptomListEdit,
      disablePersonalDetailListEdit,
      disableLocationDetailListEdit,
      storyDisableBorder,
      emptyUserName,
      userNameTaken
    } = this.state;
    this.refUpdate();
    return (
      <ProfileComponent
        saveWait={saveWait}
        username={username}
        medicalConditionList={medicalConditionList}
        symptomList={symptomList}
        treatmentList={treatmentList}
        personalDetailList={personalDetailList}
        locationDetails={locationDetails}
        invalidUsername={invalidUsername}
        usernameExist={usernameExist}
        userNameTaken={userNameTaken}
        name={name}
        inValidName={inValidName}
        newUserConditions={newUserConditions}
        story={story}
        confirmBox={confirmBox}
        deleteWait={deleteWait}
        visibility={visibility}
        showMoreInfo={showMoreInfo}
        profile={profile}
        saveChangeConfirmBox={saveChangeConfirmBox}
        getImageUrl={value => {
          this.setState({formData: value});
        }}
        handleOnPressCollapsible={() => {
          this.setState({
            showMoreInfo: !showMoreInfo,
          });
        }}
        onRequestClose={() => {
          this.setState({confirmBox: false});
        }}
        usernameEditable={usernameEditable}
        editPenVisible={editPenVisible}
        removeIcon={removeIcon}
        journalUserId={journalUserId}
        onUsernameChange={this.onUsernameChange}
        onBlurUsername={this.onBlurUsername}
        handleEditUsername={this.handleEditUsername}
        handleClearUsername={this.handleClearUsername}
        enableUserNameSave={enableUserNameSave}
        enableUserNameSaveCancel={enableUserNameSaveCancel}
        handleOnDatePress={this.handleOnDatePress}
        onGenderValueChange={this.onGenderValueChange}
        onVisibilityChange={this.onVisibilityChange}
        onChangeTextName={this.onChangeTextName}
        onChangeStory={this.onChangeStory}
        onCityChange={this.onCityChange}
        onCountryValueChange={this.onCountryValueChange}
        handleOnSubmitCancel={this.handleOnSubmitCancel}
        onBackdropPress={() => {
          this.setState({
            saveChangeConfirmBox: false,
          });
        }}
        alertBody={alertBody}
        alertTitle={alertTitle}
        handleConfirm={() => {
          this.handleSaveProfileDetailsInPopup();
        }}
        handleOnSaveProfile={this.handleOnSaveProfile}
        handleCancel={this.handleCancelConfirm}
        scrollViewRef={val => (this.scrollViewRef = val)}
        formData={formData}
        checkBoxTick={this.checkBoxTick}
        onPressUserMedicationCondition={this.onPressUserMedicationCondition}
        onPressTreatment={this.onPressTreatment}
        onPressSymptom={this.onPressSymptom}
        onPressPersonalDetail={this.onPressPersonalDetail}
        onPressLocationDetail={this.onPressLocationDetail}
        showUnlockComFeatureModal={showUnlockComFeatureModal}
        onCloseUnlockComFeatureModal={this.onCloseUnlockComFeatureModal}
        handleCancelUserName={this.handleCancelUserName}
        onCickEditMainProfile={this.onCickEditMainProfile}
        handleOnClickEditCancelItems={this.handleOnClickEditCancelItems}
        disableStory={disableStory}
        showList={showList}
        showMedicalConditionList={showMedicalConditionList}
        showTreatmentList={showTreatmentList}
        showSymptomList={showSymptomList}
        showPersonalDetailList={showPersonalDetailList}
        showLocationDetailList={showLocationDetailList}
        onBlurStory={this.onBlurStory}
        onVisibilityChangeWait={onVisibilityChangeWait}
        handleOnClickSaveItems={this.handleOnClickSaveItems}
        editPenDisabled={editPenDisabled}
        medicalConditionListSaveWait={medicalConditionListSaveWait}
        treatmentListSaveWait={treatmentListSaveWait}
        symptomListSaveWait={symptomListSaveWait}
        personalDetailListSaveWait={personalDetailListSaveWait}
        locationDetailListSaveWait={locationDetailListSaveWait}
        disableListItemSaveButtons={disableListItemSaveButtons}
        disableMedicationListEdit={disableMedicationListEdit}
        disableTreatmentListEdit={disableTreatmentListEdit}
        disableSymptomListEdit={disableSymptomListEdit}
        disablePersonalDetailListEdit={disablePersonalDetailListEdit}
        disableLocationDetailListEdit={disableLocationDetailListEdit}
        storyDisableBorder={storyDisableBorder}
        emptyUserName={emptyUserName}
        lockFeature={profile?.isEnabledPopup ? true : false}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.routines.peer2peer,
    journalUserId: state.uistate.journalUserId,
    commiunityDetails: state.peerToPeer.commiunityDetails,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePeer2PeerProfile: (p2pProfile, callBack, journalUserId) =>
      dispatch(
        actions.updatePeer2PeerProfile(p2pProfile, callBack, journalUserId, journalUserId),
      ),
    updatePeer2PeerProfileListItems: (type, p2pProfile, callBack,journalUserId) =>
      dispatch(
        actions.updatePeer2PeerProfileListItems(
          type,
          p2pProfile,
          callBack,
          journalUserId,
        ),
      ),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    onUpdateShowPeerProfileScreen: (
      showPeerProfileScreen,
      journalUserId,
      callBack,
    ) =>
      dispatch(
        actions.updateShowPeerProfileScreen(
          showPeerProfileScreen,
          journalUserId,
          callBack,
        ),
      ),
    onUploadp2p: (formData, callBack) =>
      dispatch(actions.uploadp2pProfileImage(formData, callBack)),
    OnSetPeerToPeerActiveTab: activeTabIndex =>
      dispatch(actions.setPeerToPeerActiveTab(activeTabIndex)),
    OnSaveChangesP2PProfile: p2pProfileHasDataToSave =>
      dispatch(actions.OnSaveChangesP2PProfile(p2pProfileHasDataToSave)),
    setCommiunityProfileDetails: item =>
      dispatch(actions.setCommiunityProfileDetails(item)),
    getPeer2PeerProfileData : (callBack, journalUserId) =>
      dispatch(actions.getPeer2PeerProfileData(callBack, journalUserId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(createProfileScreen);
