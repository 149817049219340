import React, {Component} from 'react';
import {Image, View, TouchableOpacity} from 'react-native';
import styles from '../../../Css';
import GroupFrame from '../../../components/TextInput/groupFrame';
import * as FieldDetails from '../../../components/TextInput/fieldDetails';
import TextButton from '../../../components/TextInput/textButton';

class StepThreeSymptom extends Component {
  constructor(props) {
    super(props);
    const {displayProperties} = props.symptom;
    const {scaleMax, scaleMin} = displayProperties;
    this.state = {
      ...props.symptom,
      yesNoBtn: scaleMax === 1 && scaleMin === 0 ? true : false,
      zeroToTenBtn: (scaleMax === null && scaleMin === null) || (scaleMin === 0 && scaleMax === 10) ? true : false,
      name: props.symptom?.name
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const {displayProperties} = props.symptom;
    const {scaleMax, scaleMin} = displayProperties;
    this.setState({
      ...props.symptom,
      yesNoBtn: scaleMax === 1 && scaleMin === 0 ? true : false,
      zeroToTenBtn: (scaleMax === null && scaleMin === null) || (scaleMin === 0 && scaleMax === 10) ? true : false,
    });
  }

  setYesNo = () => {
    this.setState(
      prevState => ({
        yesNoBtn: !prevState.yesNoBtn,
        zeroToTenBtn: !prevState.zeroToTenBtn,
      }),
      () => {
        this.props.handleOnScale(
          this.props.index,
          this.state.yesNoBtn ? 0 : 0,
          this.state.yesNoBtn ? 1 : 10,
        );
      },
    );
  };

  onChangeName = currentInput => {
    this.setState(prevState => ({
      name: currentInput,
    }), () => {
      this.props.handleOnSymptomName(
        this.props.index,
        this.state.name
      );
    });
  }


  render() {
    const {editableSymptom, type} = this.props;
    const {yesNoBtn, zeroToTenBtn, symptom, name} = this.state;
    // const {name} = symptom;
    return (
      <>
        {/* Selecting a record */}

        <View style={[styles.addedItemRow]} key={this.props.key}>
          <View style={[styles.fieldContainer, styles.flexRow]}>
            <View style={styles.flexCom}>
              <View style={[styles.fieldRowWrapper]}>
                <View style={styles.fieldColStretch}>
                  <GroupFrame
                    title={'Symptom'}
                    // fieldBg={FieldDetails.BGGREY}
                    fieldBg={type === 'custom' && (name === '' || name === null || name === undefined) ? FieldDetails.BGRED : FieldDetails.BGGREY}
                    fieldValue={name}
                    fieldType={type}
                    onChangeText={this.onChangeName}
                    groupDescription={true}>
                    <View style={[styles.symptomFieldBtn, !editableSymptom && {opacity:0.5}]}>
                      <GroupFrame
                        title={'Select Measure'}
                        fieldBg={FieldDetails.BGGREY}
                        groupStyle={styles.textInputMdWrapper}>
                        <View style={styles.btnTabSingleDynamic}>
                          <TextButton
                            btnTabStyle={styles.btnTabSmWrapper}
                            active={yesNoBtn}
                            btnText={'Yes/No'}
                            touchableOnPress={() => this.setYesNo()}
                            disabled={!editableSymptom}
                            weeklybtnShadow={false}
                          />
                        </View>
                        <View style={styles.btnTabSingleSp} />
                        <View style={styles.btnTabSingleDynamic}>
                          <TextButton
                            btnTabStyle={styles.btnTabSmWrapper}
                            active={zeroToTenBtn}
                            btnText={'0 to 10'}
                            touchableOnPress={() => this.setYesNo()}
                            disabled={!editableSymptom}
                            weeklybtnShadow={false}
                          />
                        </View>
                      </GroupFrame>
                    </View>
                  </GroupFrame>
                </View>
              </View>
            </View>
            <View style={[styles.listItemRemoveLg, styles.flexJcCt]}>
              <TouchableOpacity
                onPress={() =>
                  this.props.handleOnDeleteSymptom(this.props.index)
                }
                style={styles.closeTouchPd}>
                <Image
                  style={styles.closeDarkIcon}
                  source={require('../../../assets/icons/close-dark.png')}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>

        {/* After saving a record */}
        {/* <View
          style={[styles.addedItemRow, styles.itemViewSp]}
          key={this.props.key}
        >
          <View style={[styles.addedItemView, styles.addedItemBox]}>
            <View style={(styles.flexCom, styles.flexRow)}>
              <View
                style={[styles.addedItemCont, styles.flexCom, styles.flexJcCt]}
              >
                <Text style={[styles.textPrime]}>
                  {this.state.symptom.name}
                </Text>
              </View>

              <View style={[styles.btnTabSingleBoxSec]}>
                <View style={styles.btnTabSingleDynamic}>
                  <TextButton
                    btnTabStyle={styles.btnTabSmWrapper}
                    // active={yesNo === true}
                    btnText={"Yes/No"}
                    // touchableOnPress={() => this.setYesNo(true)}
                  />
                </View>
                <View style={styles.btnTabSingleSpLg}></View>
                <View style={styles.btnTabSingleDynamic}>
                  <TextButton
                    btnTabStyle={styles.btnTabSmWrapper}
                    // active={yesNo === false}
                    btnText={"0 to 10"}
                    // touchableOnPress={() => this.setYesNo(false)}
                  />
                </View>
              </View>

              <View style={[styles.listItemRemove, styles.flexJcCt]}>
                <TouchableOpacity
                  onPress={() =>
                    this.props.handleOnDeleteSymptom(this.props.index)
                  }
                  style={styles.closeTouchPd}
                >
                  <Image
                    style={styles.closeDarkIcon}
                    source={require("../../../assets/icons/close-dark.png")}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View> */}
      </>
    );
  }
}

export default (StepThreeSymptom);
