import React from 'react';
import styles from '../../../Css';
import stylesResponsive from '../../../CssResponsive';
import {View, Image, TouchableOpacity, Text} from 'react-native';
const graphListItem = props => {
  const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
  const {key, item, viewGraph, graphId} = props;

  return (
    <TouchableOpacity
      key={key}
      onPress={() =>
        viewGraph(item.id, item.name ? item.name : 'Defult Graph', true)
      }>
      <View style={[styles.dropDownOption, styles.dropDownOptionPdLt]}>
        <View style={[stylesRes.dropDownOptionText]}>
          <Text
            style={[
              styles.textPrimeSm,
              item.id === graphId ? styles.textOrange : styles.textPrimeCom,
            ]}
            allowFontScaling={false}
            numberOfLines={1}>
            {item.name ? item.name : 'Defult Graph'}
          </Text>
        </View>
        <View style={[stylesRes.dropDownOptionBtn]}>
          <View style={[stylesRes.dropDownOptionIcon]}>
            {item.id === graphId ? (
              <Image
                style={[styles.imgContain]}
                source={require('../../../assets/icons/option-selected.png')}
              />
            ) : (
              <Image
                style={[styles.imgContain]}
                source={require('../../../assets/icons/option-default.png')}
              />
            )}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default (graphListItem);
