import React from "react";
import { View, Text } from "react-native";
import styles from "../../Css";

const medicalConditionSuccess = () => {
  return (
    <View
      style={[
        styles.popMessageContView,
        styles.comSpSm,
        styles.popMessageContMax,
      ]}
    >
      <View
        style={[styles.popMessageDescView, styles.comMgTop, styles.comMgBotRg]}
      >
        <Text style={[styles.textPrimeLgBold, styles.textAlignCenter]} allowFontScaling={false}>
          Common symptoms and health data related to your medical condition have
          been added
        </Text>
      </View>
    </View>
  );
};

export default (medicalConditionSuccess);
