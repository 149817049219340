import React, {Component} from 'react';
import {Platform} from 'react-native';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import {PEER_TO_PEER, DASHBOARD} from '../../../../navigation/path';
import FindPeersComponent from '../../../../components/PeerToPeer/MainSection/MyNetwork/FindPeersComponent';
import { pendoTrackEvent } from '../../../../util/pendoConfig';
import {sortAlphabeticalOrder} from '../../../../util/commonUiLogic';
import { peerToPeerTabPath } from '../../../../Constant';
import { WEB } from '../../../../util/platformWindowConfig';


class FindPeersScreen extends Component {
  constructor(props) {
    super(props);

    const {windowHeight, windowWidth, user, profile, searchTearms} = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      peopleLikeMe: searchTearms ? 
        searchTearms.peopleLikeMe 
        : true,
      mySex: searchTearms 
        ? searchTearms.mySex 
        : false,
      sex: profile && profile.userName ? profile.sex : user ? user.sex : null,
      displayMySexError: false,
      minAge: searchTearms
        ? searchTearms.minAge 
        : null,
      maxAge: searchTearms 
        ? searchTearms.maxAge 
        : null,
      showMoreFilters: searchTearms ? true : false,
      selectedConditionList: searchTearms 
        ? searchTearms.selectedConditionList 
        : [],
      selectedTreatmentList: searchTearms 
        ? searchTearms.selectedTreatmentList 
        : [],
      selectedSymptomList: searchTearms 
        ? searchTearms.selectedSymptomList 
        : [],
      treatmentAll: searchTearms 
        ? searchTearms.treatmentAll 
        : false,
      symptomAll: searchTearms 
        ? searchTearms.symptomAll 
        : false,
      searchWait: false
    };
    this.scrollViewRef = null;
    this.props.hideMobileMenu();
  }

  componentDidMount() {
    if (this.props.journalUserId) {
      this.props.navigation.push(DASHBOARD.path);
    }
    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
      pendoTrackEvent('Community/@FindConnections');
    });
    this.props.navigation.addListener('blur', () => {
      this.componentWillUnmount();
    });
  }

  // static getDerivedStateFromProps(props, state) {
  //   const {windowHeight, windowWidth} = props;
  //   if (
  //     state.windowHeight !== windowHeight ||
  //     state.windowWidth !== windowWidth
  //   ) {
  //     return {
  //       windowHeight: windowHeight,
  //       windowWidth: windowWidth,
  //     };
  //   }

  //   return null;
  // }

  componentWillUnmount = () => {
    this.props.showMobileMenu();
  };

  handleOnPressSearchPeers = () => {
    this.setState({ searchWait: true });
    if (parseInt(this.state.minAge) >= parseInt(this.state.maxAge) ||
      parseInt(this.state.minAge) <= 13 ||
      (!this.state.minAge && parseInt(this.state.maxAge) <= 18)
    ) {
      this.setState({searchWait: false});
      if (this.scrollViewRef) {
        setTimeout(() => {
          Platform.OS === WEB
            ? this.scrollViewRef?.scrollTo({ x: 0, y: 0, animated: true })
            : this.scrollViewRef?.scrollToPosition(0, 0, true)
        }, 500);
      }
      return;
    }
    this.props.onResetPeerResults();

    let treatments = [];
    let symptoms = [];
    let conditions = [];
    if (
      this.state.selectedTreatmentList &&
      this.state.selectedTreatmentList.length > 0
    ) {
      this.state.selectedTreatmentList.filter((res) => {
        treatments.push(res.name);
        return treatments;
      });
    }
    if (this.state.selectedSymptomList && 
      this.state.selectedSymptomList.length > 0
    ) {
      this.state.selectedSymptomList.filter((res) => {
        symptoms.push(res.name);
        return symptoms;
      });
    }

    if (
      this.state.selectedConditionList &&
      this.state.selectedConditionList.length > 0
    ) {
      this.state.selectedConditionList.filter((res) => {
        conditions.push(res.name);
        return conditions;
      });
    }

    let searchObject = {
      peopleLikeMe: this.state.peopleLikeMe,
      mySex: this.state.mySex,
      minAge: this.state.minAge ? parseInt(this.state.minAge) : null,
      maxAge: this.state.maxAge ? parseInt(this.state.maxAge) : null,
      medicalConditionsList: conditions,
      isTreatmentAll: this.state.treatmentAll,
      treatmentList: treatments,
      isSymptomsAll:this.state.symptomAll,
      symptomsList: symptoms,
    };

    let searchTearms = {
      peopleLikeMe: this.state.peopleLikeMe,
      mySex: this.state.mySex,
      minAge: this.state.minAge,
      maxAge: this.state.maxAge,
      selectedConditionList: this.state.selectedConditionList,
      selectedTreatmentList: this.state.selectedTreatmentList,
      selectedSymptomList: this.state.selectedSymptomList,
      treatmentAll: this.state.treatmentAll,
      symptomAll: this.state.symptomAll,
    };

    this.props.onSetSearchTearms(searchTearms);
    this.props.onHandleSearchPeers(searchObject, (success, data) => {
      if (success) {
        if (this.state.searchWait) {
          this.setState({searchWait: false});
          this.props.navigation.push(PEER_TO_PEER.searchResultsPath);
        }
      } else {
        this.setState({searchWait: false});
      }
    });
  };

  handleOnPressBackButton = () => {
    this.setState({searchWait: false}, () => {
      //this.props.navigation.goBack();
      this.props.navigation.reset({
        index: 0,
        routes: [{ 
          name: PEER_TO_PEER.path,
          params: {
              from: this.props.route.name,
              communityTab: peerToPeerTabPath.myNetwork
            }
        }],
      });
    });
  };

  handleOnPressOtherUser = () => {
    this.setState((prevState) => ({
      peopleLikeMe: !prevState.peopleLikeMe,
    }));
  };

  handleOnPressMySex = () => {
    if (this.state.sex) {
      this.setState((prevState) => ({
        mySex: !prevState.mySex,
      }));
    } else {
      this.setState({
        displayMySexError: true,
      });
    }
  };

  handleOnChangeMinAge = value => {
    if (value !== '') value = String(parseInt(value));
    var valid = value.match(/^-?\d*(\.\d+)?$/);

    if (isNaN(value) || !valid) {
      if (this.state.minAge === undefined) {
        this.setState({minAge: ''});
      }
    } else {
      this.setState({minAge: value});
    }
  }

  handleOnChangeMaxAge = value => {
    if (value !== '') value = String(parseInt(value));
    var valid = value.match(/^-?\d*(\.\d+)?$/);

    if (isNaN(value) || !valid) {
      if (this.state.maxAge === undefined) {
        this.setState({maxAge: ''});
      }
    } else {
      this.setState({maxAge: value});
    }
  }

  onPressCollapsibleMoreFilters = () => {
    this.setState({
      showMoreFilters: !this.state.showMoreFilters
    });
  }
  
  getMedicalConditions = () => {
    let sortedList = [];
    if (this.props.userConditions && this.props.userConditions.length > 0) {
      sortedList = sortAlphabeticalOrder(this.props.userConditions);
      return sortedList;
    }
  };

  handleOnPressMedicalCondition = condition => {
    const found = this.state.selectedConditionList.find(res => res.id === condition.id);
    let filteredConditionList = [...this.state.selectedConditionList];
    if (found) {
      filteredConditionList = filteredConditionList.filter(res1 => condition.id !== res1.id);
    } else {
      filteredConditionList.push(condition);
    }
    this.setState({
      selectedConditionList: filteredConditionList,
    });
  };

  getTreatmentItems = () => {
    if (this.props.listTherapie && this.props.listTherapie.length > 0) {
      let treatmentList = [];
      let listTherapie = this.props.listTherapie;
      if (!this.state.treatmentAll) {
        treatmentList = this.getLastSixMonthTreatments();
      } else {
        treatmentList = listTherapie.filter(listTherapie => {
          return listTherapie.name && listTherapie.isActive;
        });
      }
      let sortedList = [];
      if (treatmentList && treatmentList.length > 0) {
        sortedList = sortAlphabeticalOrder(treatmentList);
      }
      return sortedList;
    }
  };

  getLastSixMonthTreatments = () => {
    let lastSixMonthTreatmentList = [];
    let listTherapie = this.props.listTherapie;
    const startDate = new Date();
    const endDate = new Date();
    startDate.setHours(23, 59, 59, 59);
    endDate.setDate(endDate.getDate() - 180);
    endDate.setHours(0, 0, 0, 0);
    lastSixMonthTreatmentList = listTherapie.filter((listTherapie) => {
      return (
        listTherapie.name &&
        listTherapie.createdOn < startDate.getTime() &&
        listTherapie.createdOn > endDate.getTime()
      );
    });
    return lastSixMonthTreatmentList;
  }

  handleOnPressTreatment = treatment => {
    const found = this.state.selectedTreatmentList.find(res => res.id === treatment.id);
    let filteredTreatmentList = [...this.state.selectedTreatmentList];
    if (found) {
      filteredTreatmentList = filteredTreatmentList.filter(res1 => treatment.id !== res1.id);
    } else {
      filteredTreatmentList.push(treatment);
    }
    this.setState({
      selectedTreatmentList: filteredTreatmentList,
    });
  }

  getSymptomItems = () => {
    if (this.props.userDefinedSymptoms && this.props.userDefinedSymptoms.length > 0) {
      let symptomsList = [];
      let userDefinedSymptoms = [...this.props.userDefinedSymptoms];
      if (!this.state.symptomAll) {
        symptomsList = this.getLastSixMonthSymptoms();
      } else {
        symptomsList = userDefinedSymptoms.filter(symptom => {
          return symptom.name && symptom.isActive;
        });;
      }
      let sortedList = [];
      if (symptomsList && symptomsList.length > 0) {
        sortedList = sortAlphabeticalOrder(symptomsList);
      }
      return sortedList;
    }
  };

  getLastSixMonthSymptoms = () => {
    let lastSixMonthSymptomList = [];
    let userDefinedSymptoms = [...this.props.userDefinedSymptoms];
    const startDate = new Date();
    const endDate = new Date();
    startDate.setHours(23, 59, 59, 59);
    endDate.setDate(endDate.getDate() - 180);
    endDate.setHours(0, 0, 0, 0);
    lastSixMonthSymptomList = userDefinedSymptoms.filter((userDefinedSymptoms) => {
      return (
        userDefinedSymptoms.name &&
        userDefinedSymptoms.createdOn < startDate.getTime() &&
        userDefinedSymptoms.createdOn > endDate.getTime()
      );
    });
    return lastSixMonthSymptomList;
  }

  handleOnPressSymptom = symptom => {
    const found = this.state.selectedSymptomList.find(res => res.id === symptom.id);
    let filteredSymptomList = [...this.state.selectedSymptomList];
    if (found) {
      filteredSymptomList = filteredSymptomList.filter(res1 => symptom.id !== res1.id);
    } else {
      filteredSymptomList.push(symptom);
    }
    this.setState({
      selectedSymptomList: filteredSymptomList,
    });
  }

  handleOnPressTreatmentTimeFilter = () => {
    if(this.state.treatmentAll) {
      if(this.props.listTherapie && this.props.listTherapie.length > 0) {
        let treatmentList = this.getLastSixMonthTreatments();
        let filteredList = [];
        this.state.selectedTreatmentList.find(res => {
          if(treatmentList.includes(res)) {
            filteredList.push(res);
          } 
        });
        this.setState({
          selectedTreatmentList: filteredList
        }); 
      }
    }
    this.setState((prevState) => ({
      treatmentAll: !prevState.treatmentAll,
    }));
  };

  handleOnPressSymptomTimeFilter = () => {
    if(this.state.symptomAll) {
      if (this.props.userDefinedSymptoms && this.props.userDefinedSymptoms.length > 0) {
        let symptomList = this.getLastSixMonthSymptoms();
        let filteredList = [];
        this.state.selectedSymptomList.find(res => {
          if(symptomList.includes(res)) {
            filteredList.push(res);
          } 
        });
        this.setState({
          selectedSymptomList: filteredList
        }); 
      }
    }
    this.setState((prevState) => ({
      symptomAll: !prevState.symptomAll,
    }));
  };

  render() {
    const {
      peopleLikeMe, 
      mySex,
      sex, 
      displayMySexError,
      minAge,
      maxAge,
      selectedConditionList,
      selectedTreatmentList,
      selectedSymptomList,
      treatmentAll, 
      symptomAll,
      showMoreFilters,
      searchWait
    } = this.state;
    const {navigation, windowWidth, route, hideMobileMenu} = this.props;
    hideMobileMenu();
    return (
      <FindPeersComponent
        navigation={navigation}
        route={route}
        index={PEER_TO_PEER.index}
        handleOnPressBackButton={this.handleOnPressBackButton}
        peopleLikeMe={peopleLikeMe}
        onPressOtherUser={this.handleOnPressOtherUser}
        mySex={mySex}
        onPressMySex={this.handleOnPressMySex}
        disabledMySex={!sex}
        displayMySexError={displayMySexError}
        minAge={minAge}
        maxAge={maxAge}
        invalidAge={parseInt(minAge) >= parseInt(maxAge) || parseInt(minAge) <= 13 || (!minAge && parseInt(maxAge) <= 18)}
        onChangeMinAge={this.handleOnChangeMinAge}
        onChangeMaxAge={this.handleOnChangeMaxAge}
        showMoreFilters={showMoreFilters}
        onPressCollapsibleMoreFilters={this.onPressCollapsibleMoreFilters}
        userConditions={this.getMedicalConditions()}
        selectedConditionList={selectedConditionList}
        onPressUserCondition={this.handleOnPressMedicalCondition}
        treatments={this.getTreatmentItems()}
        selectedTreatmentList={selectedTreatmentList}
        onPressTreatment={this.handleOnPressTreatment}
        symptoms={this.getSymptomItems()}
        selectedSymptomList={selectedSymptomList}
        onPressSymptom={this.handleOnPressSymptom}
        treatmentAll={treatmentAll}
        onPressTreatmentTimeFilter={this.handleOnPressTreatmentTimeFilter}
        symptomAll={symptomAll}
        onPressSymptomTimeFilter={this.handleOnPressSymptomTimeFilter}
        searchWait={searchWait}
        handleOnPressSearchPeers={this.handleOnPressSearchPeers}
        handleOnSubmitCancel={this.handleOnPressBackButton}
        scrollViewRef={val => (this.scrollViewRef = val)}
        windowWidth={windowWidth}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    user: state.routines.user,
    profile: state.routines.peer2peer,
    userConditions: state.routines.userConditions,
    userDefinedSymptoms: state.routines.symptomsDigest.userSymptoms,
    listTherapie: state.routines.treatments,
    searchTearms: state.peerToPeer.searchTearms
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    onResetPeerResults: () => dispatch(actions.resetPeerResults()),
    onSetSearchTearms: (searchTearms) => 
      dispatch(actions.setSearchTearms(searchTearms)),
    onHandleSearchPeers: (searchObject, callBack) => 
      dispatch(actions.handleSearchPeers(searchObject, callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FindPeersScreen);
