import React , { Component }from "react";
import { View, Text, TouchableOpacity, Image, Platform } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import SendEmailBox from "../ConfirmationBox/sendEmailPopupBox";
import SuccessBox from "../../components/ConfirmationBox/successBox";
import * as url from "../../store/actions/urls";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import {
  axiosPOSTSendEmailReport,
  axiosPOSTPdfDownload,
  axiosPdfDownloadMobile
 } from "../../axios/axios-common";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { WEB } from "../../util/platformWindowConfig";


const SURVEY_STATUS = {
  open: 'OPEN',
  processing: 'PROCESSING',
  completed: 'COMPLETED',
}

class SurveyItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      downloadWait: false,
      selectedId: null,
      visibleModalSendEmail: false,
      sendWait: false,
    };
  }

  surveyStatusCommand = (surveyStatus) => {
    switch (surveyStatus) {
      case SURVEY_STATUS.open:
        return 'Start';
      case SURVEY_STATUS.processing:
        return 'Continue';
      // case SURVEY_STATUS.completed:
      //   return 'Start';
      default:
        return '';
    }
  };
  onSendEmail = (id) => {
    this.setState({
      visibleModalSendEmail: true,
      selectedId: id,
    });
  };

  handleSendEmailSurvey = (emails, callback) => {
    this.setState({ sendWait: true });
    let sendEmail = {
      emailRecipients: emails,
      surveyId: this.props.surveyId,
      notificationId: this.props.notificationId,
    };
    let requestUrl = url.SEND_EMAIL_SURVEY_REPORT;

    if (this.props.journalUserId) {
      requestUrl += '?journalUserId=' + this.props.journalUserId;
    }

    axiosPOSTSendEmailReport(requestUrl, sendEmail, () => {
      this.setState({
        selectedId: null,
        visibleModalSendEmail: false,
        sendWait: false,
        successModalVisible: true,
      });
      callback(true);
      setTimeout(() => {
        this.setState({ successModalVisible: false });
      }, 1500);
    });
  };

  onDownloadReport = () => {
    this.setState({ downloadWait: true });
    const data = {};
    let requestUrl = url.GENERATE_SURVEY_REPORT
        .replace('{surveyID}', this.props.surveyId)
          .replace('{notificationID}', this.props.notificationId)
    if (Platform.OS === WEB) {
      if (this.props.journalUserId) {
        requestUrl += '?journalUserId=' + this.props.journalUserId;
      }
      axiosPOSTPdfDownload(
        requestUrl,
        data,
        this.props.surveyName + ".pdf",
        (res, message) => {
          this.setState({ downloadWait: false });
          if (!res) {
            this.props.triggerCommonErrorHandler("Unable to download PDF. Please try again.");
          }
        }
      );
    } else {
      axiosPdfDownloadMobile(
        requestUrl,
        this.props.surveyName + ".pdf",
        (res) => {
          this.setState({ downloadWait: false });
          if (res.status !== 200) {
            this.props.triggerCommonErrorHandler("Unable to download PDF. Please try again.");
          }
        },
        this.props.journalUserId,
        data,
        true
      );
    }
  };

  render(){
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const { id } = this.props;

  return (
    <View style={[styles.viewSingleRow, styles.viewSingleMg]}>
      <TouchableOpacity onPress={this.props.onPress}>
        <View style={[styles.ViewRow]}>
          <View
            style={[
              styles.viewRowCont,
              styles.bgWhite,
              styles.viewItemPd,
              styles.viewItemRad,
              styles.pdTopBotZero,
              //styles.menuItemView,
              styles.surveyListBorder,
            ]}
          >
            <View style={styles.viewDetailSec}>
              <View style={styles.viewMainData}>
                <View style={styles.surveyListWrapper}>
                  <View style={styles.nameStatusWrapper}>
                    {(this.props.surveyStatus === SURVEY_STATUS.open || this.props.surveyStatus === SURVEY_STATUS.processing) &&
                    <View style={stylesRes.surveyStatusWrapper} dataSet={{media: ids.surveyStatusWrapper}}>
                      <View style={styles.surveyStatusInnerWrapper}>
                        <Text
                          style={[
                            styles.textPrimeBold,
                            this.props.surveyStatus === SURVEY_STATUS.open && styles.orangeText,
                            this.props.surveyStatus === SURVEY_STATUS.processing && styles.lightBlueText,
                          ]}
                          allowFontScaling={false}
                        >
                          {this.surveyStatusCommand(this.props.surveyStatus)}
                        </Text>
                      </View>
                      <View >
                        <Image
                          style={[styles.surveyStatusIcon]}
                          source={
                            this.props.surveyStatus === SURVEY_STATUS.open
                              ? require("../../assets/icons/next-icon-orange.png")
                              : require("../../assets/icons/next-icon-blue.png")
                          }
                        />
                      </View>
                    </View>}
                    {(this.props.surveyStatus === 'COMPLETED'? (
                    <View style={stylesRes.resViewDataFull} dataSet={{media: ids.resViewDataFull}}>
                      <Text numberOfLines={1} style={styles.textPrimeBold} allowFontScaling={false}>{this.props.title}</Text>
                    </View>
                    ):(
                    <View style={[styles.viewDataFull]}>
                      <Text numberOfLines={1} style={styles.textPrimeBold} allowFontScaling={false}>{this.props.title}</Text>
                    </View>
                    ))}
                  </View>
                  {(this.props.surveyStatus === 'COMPLETED') &&
                  <View style={stylesRes.fixedWidthIconWrapper} dataSet={{media: ids.fixedWidthIconWrapper}}>
                    <View>
                      {this.state.downloadWait ? (
                        <View style={[styles.attachDownloadLoaderWrapper]}>
                          <View style={styles.pdfWaitSm}>
                            <View style={[styles.attachDownloadLoader]}>
                              <ActivityIndicator size="small" />
                            </View>
                          </View>
                        </View>
                      ):(
                      <TouchableOpacity
                          onPress={this.onDownloadReport}
                          disabled={this.state.downloadWait}
                          style={[styles.emailPdfIconsHeight]}
                      >
                          <Image
                            style={[styles.pdfDownloadIcon]}
                            source={require("../../assets/icons/pdf-sml-icon.png")}
                          />
                        </TouchableOpacity>
                      )}

                    </View>
                    <View style={[styles.emailIconWrapper]}>
                      <TouchableOpacity
                        onPress={()=> this.onSendEmail(id)}
                        disabled={this.state.visibleModalSendEmail}
                        style={[styles.emailPdfIconsHeight]}
                      >
                        <Image
                          style={[styles.emailIcon]}
                          source={require("../../assets/icons/email-icon-black.png")}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>}
                  {(this.props.surveyStatus === 'COMPLETED'? (
                    <View style={stylesRes.responsiveFixedWidthDateWrapper} dataSet={{media: ids.responsiveFixedWidthDateWrapper}}>
                    <Text
                      style={[
                        styles.textPrimeSm,
                        styles.textGrey,
                        styles.breakWord,
                        styles.textAlignRight
                      ]}
                      allowFontScaling={false}
                    >
                      {this.props.date}
                    </Text>
                  </View>
                  ):(
                    <View style={[styles.fixedWidthDateWrapper]}>
                      <Text
                        style={[
                          styles.textPrimeSm,
                          styles.textGrey,
                          styles.breakWord,
                          styles.textAlignLeft
                        ]}
                        allowFontScaling={false}
                      >
                        {this.props.surveyStatus === 'OPEN' && this.props.surveyDueDate && `Due on: ${this.props.surveyDueDate}`}
                        {this.props.surveyStatus === 'PROCESSING' && this.props.surveyCompletedTime && `Saved: ${this.props.surveyCompletedTime}`}
                      </Text>
                  </View>
                  ))}
                </View>
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
      {this.state.visibleModalSendEmail && (
          <SendEmailBox
            visibleModalSendEmail={this.state.visibleModalSendEmail}
            onBackdropPress={() => {
              this.setState({
                visibleModalSendEmail: false,
                selectedId: null,
              });
            }}
            onRequestClose={() => {
              this.setState({
                visibleModalSendEmail: false,
                selectedId: null,
              });
            }}
            handleSendEmail={(emails, callback) =>
              this.handleSendEmailSurvey(emails, callback)
            }
            sendWait={this.state.sendWait}
          />
          )}
          <SuccessBox
            visibleModal={this.state.successModalVisible}
            message="Success! Email has been sent."
          />
    </View>
  );
}
};

const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateUserReport: (data, journalUserId, callBack) =>
      dispatch(actions.updateUserReport(data, journalUserId, callBack)),
    triggerCommonErrorHandler: (errorDesc) =>
      dispatch(actions.commonErrorHandler(errorDesc)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyItem);