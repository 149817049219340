import React, { Component } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import TakenImagesCount from './takenImagesCount';

class AttachmentImagePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      cropResult: null
    };
  }

  handleDonePressed = () => {
    const { onDonePressed } = this.props;
    onDonePressed();
  };

  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    //let positionStatus = "absolute";
    const { imageCount } = this.props;

    return (
      <React.Fragment>
        <View style={[stylesRes.frameArea, stylesRes.frameAreaImg]}>
          <View
            style={[
              this.props.paddingAll
                ? stylesRes.colPdComScanNone
                : stylesRes.colPdTopLtRtCom
            ]}
            dataSet={{media: ids.colPdTopLtRtCom}}
          >
            <View style={[styles.frameAreaTop]}>
              <View
                style={{
                  width:this.props.scanWidth,
                  height:this.props.maxContHeight,
                }}
              >
                 {/* Please put the dummy image here. Will update it later */}
              <Image
                source={{uri: this.props.imageUrl}}
                style={{
                  resizeMode: "contain",
                  flex: 1,
                }}
              />
              </View>
            </View>
          </View>
        </View>

        <View
          style={[
            stylesRes.frameArea,
            stylesRes.uploadAttachmentframeAreaContView,
            //stylesRes.frameAreaContView,
            //{ position: positionStatus }
          ]}
        >
          <View style={[styles.colPdLtRt]}>
            <View style={[styles.frameAreaBot]}>
              <View style={[stylesRes.frameViewBot]}>
                <React.Fragment>
                  <View style={[stylesRes.frameViewBtn]}>
                    <TouchableOpacity
                      style ={[styles.uploadAttachmentButtonWrapper]}
                      onPress={this.props.onTakePhotoAgain}
                    >
                      <View style={[stylesRes.frameBtnFull, stylesRes.uploadAttachmentCamIconWrapper]}>
                        <Image
                          style={styles.uploadAttachmentCamIcon}
                          source={require("../../assets/ocr-icons/camera-circle-icon.png")}
                        />
                      </View>
                    </TouchableOpacity>
                  </View>
                </React.Fragment>
              </View>
            </View>
          </View>
        </View>
        <View style={stylesRes.cropperTextBlock} dataSet={{media: ids.cropperTextBlock}}>
          <View style={[styles.countWrapper]}>
            <TakenImagesCount count={imageCount} />
          </View>
          <View>
            <TouchableOpacity
              style={[styles.cropperGreenBtn]}
              onPress={this.handleDonePressed}
            >
              <Text style={[styles.textPrime, styles.dateTextComment]} allowFontScaling={false}>Done</Text>
            </TouchableOpacity>
          </View>
        </View>
      </React.Fragment>
    );
  }
}
export default (AttachmentImagePreview);
