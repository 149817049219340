import {Text, TouchableOpacity, View} from "react-native";
import styles from "../../Css";
import React from "react";

export const NAVIGATION_HEADER_HEIGHT = 30;

export function changePage(offset, pageNumber, numPages, setPageNumber, onPageChanged) {
  const newPageNumber = pageNumber + offset;
  if (newPageNumber >= 1 && newPageNumber <= numPages) {
    setPageNumber(newPageNumber);
    if (onPageChanged) {
      onPageChanged({pageNumber: newPageNumber, numPages});
    }
  }
}

export function previousPage(pageNumber, numPages, setPageNumber, onPageChanged) {
  changePage(-1, pageNumber, numPages, setPageNumber, onPageChanged);
}

export function nextPage(pageNumber, numPages, setPageNumber, onPageChanged) {
  changePage(1, pageNumber, numPages, setPageNumber, onPageChanged);
}

export function getNavigationPane(pageNumber, numPages, previousPage, nextPage) {
  return <View
    style={[styles.flexRow, {
      justifyContent: "space-between",
      height: NAVIGATION_HEADER_HEIGHT,
      marginTop: 10
    }]}>
    <TouchableOpacity style={[]}>
      <Text
        style={[styles.textPrimeMdBlue, styles.textAlignCenter, styles.textUnderline]}
        onPress={previousPage}>
        Prev Page
      </Text>
    </TouchableOpacity>
    <Text>Page {pageNumber} of {numPages}</Text>
    <TouchableOpacity>
      <Text
        style={[styles.textPrimeMdBlue, styles.textAlignCenter, styles.textUnderline]}
        onPress={nextPage}>
        Next Page
      </Text>
    </TouchableOpacity>
  </View>;
}