import React, { Component } from "react";
import { View } from "react-native";
import styles from "../../Css";
import { connect } from "react-redux";
import { TAB_BREAk_MAX_WIDTH } from "../../util/platformWindowConfig";

class OutlookContentContainer extends Component {
  constructor(props) {
    super(props);

    const { windowHeight, windowWidth } = props;
    this.state = {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { windowHeight, windowWidth } = props;
    if (
      state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
      };
    }

    return null;
  }

  render() {
    const { windowWidth } = this.props;
    return (
        <React.Fragment>
        <View style={[
          windowWidth > TAB_BREAk_MAX_WIDTH
            ? styles.outlookContentContainer
            : styles.outlookContentContainerLg
          ]}>
          {this.props.children}
        </View>
        </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
  };
};

export default connect(mapStateToProps, null)(OutlookContentContainer);
