import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import Webcam from "react-webcam";
import stylesResponsive from "../../CssResponsive";
import styles from "../../Css";

class WebcamCapture extends Component {
  constructor(props) {
    super(props);
    const inside = this;
    this.state = {
      facingMode: inside.checkForMobile() ? { exact: "environment" } : "user",
      resetCameraView: false
      // videoConstraints: {
      //   width: {
      //     min: 320,
      //     max: 1280
      //   },
      //   height: {
      //     min: 240,
      //     max: 720
      //   },
      //   facingMode: inside.checkForMobile() ? { exact: "environment" } : "user"
      // }
      // height: null,
      // width: null
    };
    //this.setRef();
  }

  // componentDidMount = () => {
  //   if (this.webcam.getCanvas()) {
  //     this.setState({
  //       height: this.webcam.getCanvas().height,
  //       width: this.webcam.getCanvas().width
  //     });
  //   }
  // };

  // UNSAFE_componentWillReceiveProps = nextprops => {
  //   if (this.webcam.getCanvas()) {
  //     this.setState({
  //       height: this.webcam.getCanvas().height,
  //       width: this.webcam.getCanvas().width
  //     });
  //   }
  // };

  checkForMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    }
    return false;
  }

  setRef = webcam => {
    this.webcam = webcam;
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.props.onimageSrc(imageSrc);
  };

  changeMode = () => {
    this.setState({ resetCameraView: true });

    setTimeout(() => {
      const { facingMode } = this.state;
      const newFacingMode =
        facingMode === "user" ? { exact: "environment" } : "user";

      this.setState({
        facingMode: newFacingMode,
        resetCameraView: false
      });
    }, 100);
  };

  catchWebcamErrors = (error) => {
    const { facingMode } = this.state;
    if (error && error.constraint === "facingMode") {
      if (facingMode === "user") {
        this.setState({ facingMode: { exact: 'environment' } });
      } else {
        this.setState({ facingMode: 'user' });
      }
    }
  }
  
  render() {
    const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
    const videoConstraints = {
      facingMode: this.state.facingMode,
      width: {
        max: 1280
      },
      height: {
        max: 720
      }
    };
    let positionStatus = "absolute";
    return (
      <React.Fragment>
        <View style={[styles.scrollFixed, {height: this.props.maxContHeight}]}>
          <View style={[styles.gridSection]}>
            <View style={[stylesRes.contentSectionMax]} dataSet={{media: ids.contentSectionMax}}>
              <View style={[stylesRes.contentSectionFull]}>
                <View style={[styles.gridRow]}>
                  <View style={[styles.gridColFull]}>
                    <View
                      style={[
                        stylesRes.scanFullArea
                      ]}
                    >
                      <View style={[stylesRes.frameArea, stylesRes.frameAreaImg]}>
                        <View
                          style={[
                            this.props.paddingAll
                              ? stylesRes.colPdComScanNone
                              : stylesRes.colPdTopLtRtCom
                          ]}
                          dataSet={{media: ids.colPdTopLtRtCom}}
                        >
                          <View style={[styles.frameAreaTop]}>
                            <View
                              style={[
                                stylesRes.camMg,
                                this.props.windowWidth > 767 && this.props.modalView
                                ? {width: '640'}
                                : {width: 'auto'}
                              ]}
                            >
                              {!this.state.resetCameraView ? (
                                <TouchableOpacity onPressIn={this.changeMode}>
                                  <Webcam
                                    audio={false}
                                    ref={this.setRef}
                                    width={this.props.scanWidth}
                                    height={this.props.scanHeight}
                                    screenshotFormat="image/jpeg"
                                    onUserMediaError={(e) => this.catchWebcamErrors(e)}
                                    videoConstraints={videoConstraints}
                                  />
                                </TouchableOpacity>
                              ) : (
                                <Text allowFontScaling={false}>Loading...</Text>
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={[
            stylesRes.frameArea,
            stylesRes.frameAreaContView,
            { position: positionStatus }
          ]}
        >
          <View style={[styles.colPdLtRt]}>
            <View style={[styles.frameAreaBot]}>
              <View style={[stylesRes.frameViewBot]}>
                <View style={[stylesRes.frameViewBtn]}>
                  <TouchableOpacity>
                    <button
                      onClick={this.capture}
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        cursor: "poniter"
                      }}
                    >
                      <View style={[stylesRes.frameBtnFull]}>
                        <View style={[stylesRes.frameBtn]}>
                          <Text
                            style={[
                              styles.textPrimeExSmBold,
                              styles.textAlignCenter,
                              styles.textBlack
                            ]}
                            allowFontScaling={false}
                          >
                            Take {"\n"}photo
                          </Text>
                        </View>
                      </View>
                    </button>
                  </TouchableOpacity>
                </View>

                <View
                  style={[stylesRes.frameViewText, stylesRes.frameTextMinHeight]}
                >
                  <View style={[stylesRes.frameTextFull]}>
                    {this.props.message ? (
                      <View style={[stylesRes.frameTextBtn]}>
                        <Text
                          style={[
                            styles.textPrimeBold,
                            styles.textAlignCenter,
                            styles.textWhite
                          ]}
                          allowFontScaling={false}
                        >
                          {this.props.message}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </React.Fragment>
    );
  }
}
export default (WebcamCapture);
