import React from "react";
import { Text, View, TouchableOpacity, ScrollView } from "react-native";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import Modal from "../Modal/modal";
import UIText from "../Text/text";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";

const confirmationBox = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <Modal
      visible={props.visibleModal}
      onRequestClose={props.onRequestClose}
      onBackdropPress={props.onBackdropPress}
    >
      <View style={stylesRes.modalScrollWrapperCom} dataSet={{media: ids.modalScrollWrapperCom}}>
        <ScrollView style={styles.modalScroller}>
          <View style={[styles.popUpBlock, styles.bgWhite]}>
            <View style={styles.popUpContentArea}>
              <View style={styles.gridSectionPopup}>
                <View style={[styles.popUpContentPd]}>
                  <View>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View
                          style={[styles.infoTitleBlock, styles.infoPdTopBot]}
                        >
                          <Text
                            style={[
                              styles.infoText,
                              styles.text,
                              styles.textAlignCenter,
                              styles.bold
                            ]}
                            allowFontScaling={false}
                          >
                            {props.alertTitle}
                          </Text>
                        </View>
                        <View style={[styles.infoBlock, styles.infoPdBotLg]}>
                          <Text
                            style={[
                              styles.infoText,
                              styles.text,
                              styles.textAlignCenter
                            ]}
                            allowFontScaling={false}
                          >
                            Are you sure?
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.btnArea}>
                          <View style={stylesRes.btnViewSecSm} dataSet={{media: ids.btnViewSecSm}}>
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={[styles.submitSm]}
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={props.onBackdropPress}
                                  disabled={props.deleteWait}
                                >
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent
                                      ]}
                                    >
                                      <UIText
                                        style={[
                                          styles.submitText,
                                          styles.submitTextSm
                                        ]}
                                        title="Cancel"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                            <View style={styles.fieldBtnSp} />
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={[styles.submitSm, styles.disableBtn]}
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={() => {
                                    props.handleDelete();
                                  }}
                                  disabled={props.deleteWait}
                                >
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent
                                      ]}
                                    >
                                      {props.deleteWait && (
                                        <View style={styles.loaderWait}>
                                          <ActivityIndicator size="small" />
                                        </View>
                                      )}
                                      <UIText
                                        style={[
                                          styles.submitText,
                                          styles.submitTextSm
                                        ]}
                                        title="Yes, Delete"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};
export default (confirmationBox);
