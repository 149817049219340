import React, { Component } from "react";
import {
  View,
  ScrollView,
  Text,
  Image
} from "react-native";
import styles from "../../Css";
import { connect } from "react-redux";
import LearnHowPrivacyPolicy from "../SignUp/LearnHowPrivacyPolicy";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import Footer from "../../components/StaticCommon/footer";
import Content from "./content";
import {ORGANIZATION_NAME} from '../../util/commonUiConfig';
class AccountVerify extends Component {
  constructor(props) {
    super(props);
    const { windowWidth, windowHeight, emailVerified } = props;

    this.state = {
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      showLearnHowPrivacyPolicy: false,
      verifingWait: false,
      verifySuccess: false,
      emailVerified: emailVerified,
      failMsg: "",
    };
  }

  componentDidMount() {
    this.setState({ verifingWait: true });
    // if (this.props.emailVerified !== true) {
      this.props.confirmUserSignUpWithCode(
          this.props.verificationCode,
          (status, errMsg) => {
            this.setState({verifingWait: false});
            if (status) {
              this.setState({verifySuccess: true});
            } else {
              this.setState({failMsg: errMsg});
            }
          }
      );
    // } else {
    //   this.setState({verifingWait: false});
    //   this.setState({verifySuccess: true});
    // }
  }

  static getDerivedStateFromProps(props, state) {
    const { windowWidth, windowHeight, emailVerified } = props;

    if (
        windowWidth !== state.windowWidth ||
        windowHeight !== state.windowHeight ||
        emailVerified !== state.emailVerified
    ) {
      return {
        windowWidth: windowWidth,
        windowHeight: windowHeight,
        emailVerified: emailVerified
      };
    }

    return null;
  }

  toggleLearnHowPrivacyPolicy = () => {
    this.setState({ showLearnHowPrivacyPolicy: true });
  };

  render() {
    const { verifingWait, verifySuccess, emailVerified, showLearnHowPrivacyPolicy } = this.state;
    return (
      <View style={[styles.pageBodyWrapper]}>
        <ScrollView
          style={[styles.scrollViewArea]}
          contentContainerStyle={[styles.scrollViewMax, styles.flexJcSb]}
        >
          {verifingWait ? (
            <View style={[styles.flexCom, styles.flexJcCtAiCt]}>
              <ActivityIndicator pdZero={true} size={"large"} />
            </View>
          ) : (
            <React.Fragment>
            <Content
              verifySuccess={verifySuccess || emailVerified}
              messageTitle={
                <Text
                  style={[styles.textTitleMainLgBold, styles.textAlignCenter]}
                  allowFontScaling={false}
                >
                  {verifySuccess && "Thank you"}
                  {!verifySuccess && "This invitation link has expired"}
                </Text>
              }
              messageSubTitle={
                <Text style={[styles.textTitleMainLgBold]} allowFontScaling={false}>
                  {verifySuccess &&  "Success! You have verified your email"}
                </Text>
              }
              message={
                <Text style={[styles.textPrime, styles.textTertiary]} allowFontScaling={false}>
                  {verifySuccess &&  `Thank you for verifying your email address. You can now start using ${ORGANIZATION_NAME} to manage your health journey.`}
                  {!verifySuccess && `Your request to verify your email has expired or the link has already been used. You can resend a verification link through your ${ORGANIZATION_NAME} account.`}
                </Text>
              }
              messageIcon={
                <Image
                  style={[
                    verifySuccess
                    ? styles.caregiverDefaultIcon
                    : !verifySuccess
                    ? styles.warningTraingleLg
                    : null
                  ]}
                  source={
                    verifySuccess ?  require("../../assets/premium/caregiver-default.png") :
                    !verifySuccess ?  require("../../assets/icons/warning-exclamation.png") : null
                  }
                />
              }
              navigateToDashbaord={this.props.goToDashboard}
            />
            <Footer toggleLearnHowPrivacyPolicy={this.toggleLearnHowPrivacyPolicy} />
            </React.Fragment>
            )}
        </ScrollView>
        {Boolean(showLearnHowPrivacyPolicy) &&
          <LearnHowPrivacyPolicy
            showLearnHowPrivacyPolicy={showLearnHowPrivacyPolicy}
            toggleLearnHowPrivacyPolicy={() => {
              this.setState({ showLearnHowPrivacyPolicy: false });
            }}
          />
        }
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    windowWidth: state.uistate.windowWidth,
    windowHeight: state.uistate.windowHeight,
    journalUserId: state.uistate.journalUserId,
    hasNotch: state.uistate.hasNotch,
    emailVerified: state.auth.emailVerified,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountVerify);
