import React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  horizontal: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10,
  },
  pdSm: {
    padding: 10,
  },
  pdLg: {
    padding: 20,
  },
  pdZero: {
    padding: 0,
  },
});

const activityIndicator = (props) => {
  let size = "large";
  if (props.size) {
    size = "small";
  }
  return (
    <View
      style={[
        styles.container,
        styles.horizontal,
        props.pdZero 
        ? styles.pdZero 
        : props.pdLarge
        ? styles.pdLg
        : styles.pdSm
      ]}
    >
      <ActivityIndicator
        size={size}
        color={props.color ? props.color : "#FF8300"}
      />
    </View>
  );
};

export default activityIndicator;
