import React from "react";
import { View } from "react-native";
import styles from "../../../Css";
import FieldTitle from "../../../components/TextInput/fieldTitle";
import * as FieldDetails from "../../../components/TextInput/fieldDetails";
import ViewMoreText from "../../../components/ViewMoreText";

const contentBlock = (props) => {
  const {
    title,
    message,
  } = props;
  return (
    <View style={[styles.flexRow, styles.colPdTopCom]}>
    {/* {((windowWidth > 991) || (windowWidth < 992)) && <View style={styles.itemColImgSp} />} */}
    <View style={styles.itemColImgSp} />
    <View style={styles.flexCom}>
      <View style={[styles.postRelative]}>
        <FieldTitle
          fieldBg={FieldDetails.BGWHITE}
          title={title}
          fieldLt={true}
        />
        <View
          style={[
            styles.textInputField,
            styles.bgTransparent,
            styles.bgWhite,
            styles.peerBtnArea,
            { height: "auto" },
          ]}
        >
          <ViewMoreText
            longText={message}
            numberOfLines={2}
            displayInNewRow={true}
          />
        </View>
      </View>
    </View>
  </View>
  );
};

export default (contentBlock);
