import React, { Component } from "react";
import { Image, View, ScrollView, Text, TouchableOpacity } from "react-native";
import ActivityIndicator from "../../components/ActivityIndicator/activityIndicator";
import styles from "../../Css";
import stylesResponsive from "../../CssResponsive";
import UIText from "../../components/Text/text";
import Header from "../../components/Header/header";
import { connect } from "react-redux";
import * as axiosCommon from "../../axios/axios-common";
import * as actions from "../../store/actions/index";
import { SIGNED_DEVICES } from "../../store/actions/urls";
import DeviceItem from "../../components/Profile/deviceItem";
import SpaceBar from "../../components/SpaceBar/SpaceBar";
import { PROFILE } from "../../navigation/path";
import PageContainer from "../../components/PageLayout/PageContainer";
import { pendoTrackEvent } from "../../util/pendoConfig";
import CancelButton from "../../components/Button/cancelButton";
class MyDevicesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      saveWait: false,
    };
  }

  handleOnPressBackButton = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: PROFILE.path }],
    });
  };

  componentDidMount() {
    this.getSignedDevices();
    pendoTrackEvent('Profile/@MyDevices');
  }

  devicesLogout = () => {
    this.setState({ saveWait: true });
    this.props.logoutEveryWhere();
  };

  getSignedDevices = () => {
    axiosCommon.axiosGET(SIGNED_DEVICES, (res) => {
      if (res.type === "SUCCESS") {
        this.setState({ devices: res.responseDto.data });
      } else if (res.type === "ERROR" && res.errorDto === "SESSION_TIME_OUT") {
      }
    });
  };

  render() {
    const {styles: stylesRes} = stylesResponsive.getProcessedStyles();
    const { uniqueId } = this.props;
    return (
      <View style={styles.pageBodyWrapper}>
        <Header
          showFilers={false}
          showBack={true}
          showClearFilters={false}
          showNotify={true}
          handleOnPressBackButton={this.handleOnPressBackButton}
          index={PROFILE.index}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        <SpaceBar />
        <ScrollView style={styles.scrollViewArea}>
          <View style={styles.gridSection}>
            <PageContainer>
              <View
                style={[stylesRes.contentSection, stylesRes.contBotSmPdFixed]}
              >
                <View style={stylesRes.secArea}>
                  <View style={styles.colPdTopCom}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.topImgSecCom}>
                          <View
                            style={[stylesRes.topImgLgCom, stylesRes.topImgNoMg]}
                          >
                            <Image
                              style={styles.passIconLgCom}
                              source={require("../../assets/reset-password/forgot-pass.png")}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.textSecTop}>
                          <Text
                            style={[
                              styles.textTitleMdBold,
                              styles.textAlignCenter,
                            ]}
                            allowFontScaling={false}
                          >
                            My Devices
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={[styles.comMgBotLg, styles.subTextBlock]}>
                          <Text
                            style={[styles.textPrime, styles.textAlignCenter]}
                            allowFontScaling={false}
                          >
                            You are signed in to the following devices
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.deviceArea}>
                          {this.state.devices.map((device) => (
                            <DeviceItem
                              key={device.id + "_" + device.deviceId}
                              name={device.name}
                              type={device.type}
                              deviceType={device.deviceType}
                              deviceId={device.deviceId}
                              uniqueId={uniqueId}
                            />
                          ))}
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={stylesRes.secArea}>
                    <View style={styles.gridRow}>
                      <View style={[styles.gridColFull, styles.barPd]}>
                        <View style={styles.sectionSep} />
                      </View>
                    </View>
                  </View>
                  <View style={stylesRes.secArea}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View
                          style={[styles.subTextBlockMg, styles.subTextBlock]}
                        >
                          <Text
                            style={[styles.textPrime, styles.textAlignCenter]}
                            allowFontScaling={false}
                          >
                            Are you sure you want to sign out of all devices?
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.btnArea}>
                          <View style={stylesRes.btnViewSec}>
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <CancelButton 
                                  touchableOnpress={this.handleOnPressBackButton}
                                  cancelBtnWidthDiff={true}
                                />
                              </View>
                            </View>
                            <View style={styles.fieldBtnSp} />
                            <View style={styles.fieldBtn}>
                              <View style={styles.btnBlockFull}>
                                <TouchableOpacity
                                  style={styles.submitSm}
                                  underlayColor="rgba(0, 153, 168, 0.8)"
                                  onPress={this.devicesLogout}
                                >
                                  <View style={styles.btnSingleView}>
                                    <View
                                      style={[
                                        styles.btnSingle,
                                        styles.btnSingleCent,
                                      ]}
                                    >
                                      {this.state.saveWait && (
                                        <View style={styles.actIndCom}>
                                          <ActivityIndicator size="small" />
                                        </View>
                                      )}
                                      <UIText
                                        style={[
                                          styles.submitBtnTextWhite,
                                          styles.bold
                                        ]}
                                        title="Yes"
                                      />
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </PageContainer>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    uniqueId: state.uistate.uniqueId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutEveryWhere: () => dispatch(actions.logoutEveryWhere()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyDevicesScreen);
