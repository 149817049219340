import React from 'react';
import {View, Text, Image} from 'react-native';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import styles from '../../Css';
import {journalSaveOptions} from '../../Constant/index';

const {Popover} = renderers;

const buttonPopupMenu = props => {
  return (
    <>
      {props.disabled ? (
        <View style={[styles.itemIconSingle, styles.itemIconBtn]}>
          <Image
            style={[styles.playSmIcon]}
            source={require('../../assets/icons/play.png')}
          />
        </View>
      ) : (
        <Menu
          renderer={Popover}
          rendererProps={{
            placement: 'bottom',
          }}>
          <MenuTrigger>
            <View style={[styles.itemIconSingle, styles.itemIconBtn]}>
              <Image
                style={[styles.playSmIcon]}
                source={require('../../assets/icons/play.png')}
              />
            </View>
          </MenuTrigger>
          <MenuOptions style={[styles.dropDownList, styles.menuOptionsLg]}>
              {Boolean(props.showCreateRoutine) && (
              <MenuOption
                style={[styles.liItem, styles.viewItemPd]}
                onSelect={() => props.onPressCreateRoutine(journalSaveOptions.saveAndUpdateRoutine)}>
                <Text style={styles.listTextCom} allowFontScaling= {false}>+ Update this Routine</Text>
              </MenuOption>
            )}
            <MenuOption
              style={[styles.liItem, styles.viewItemPd]}
              onSelect={() => props.onPressCreateRoutine(journalSaveOptions.saveAndCreateRoutine)}>
              <Text style={styles.listTextCom} allowFontScaling= {false}>+ Create a New Routine</Text>
            </MenuOption>
          </MenuOptions>
        </Menu>
      )}
    </>
  );
};
export default buttonPopupMenu;
