import React from "react";
import { View, Image, TouchableOpacity } from "react-native";
import styles from "../../Css";

const circleButtonCom = (props) => {
  return (
    <View 
      style={[styles.itemButton]} 
      nativeID={props.nativeId}>
        <TouchableOpacity  
          style={[styles.acceptBtnTouchView, styles.flexCom, styles.flexJcCtAiCt]} 
          onPress={props.touchableOnPress}>
          <View style={[styles.acceptIconSection]}>           
          {Boolean(props.btnCreate) ? (
              <Image
                style={styles.recordBtn}
                source={require("../../assets/icons/accept-circle-dark.png")}
              />
            ) : (
              <Image
                style={styles.recordBtn}
                source={require("../../assets/icons/accept-circle-light.png")}
              />
            )}
          </View>
        </TouchableOpacity>
    </View>
  );
};

export default (circleButtonCom);
