import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ScaleSlider from "./scaleSlider";

const SYMPTOM_SLIDER_START = '#F5BF44';
const SYMPTOM_SLIDER_END = '#D24935';

const SYMPTOM_MIN_VALUE = 0;
const SYMPTOM_MAX_VALUE = 10;

const COLOR_MODE_TYPES = {
  flat: 'FLAT',
  gradient: 'GRADIENT',
};
Object.freeze(COLOR_MODE_TYPES);

class SymptomSlider extends Component {
  render() {
    const {
      ref,
      enabled,
      onUpdate,
      value,
      disabledScroll,
      valueDescriptions
    } = this.props;

    return (
      <ScaleSlider
        ref={ref}
        minValue={SYMPTOM_MIN_VALUE}
        maxValue={SYMPTOM_MAX_VALUE}
        minScaleDescription={Boolean(valueDescriptions[SYMPTOM_MIN_VALUE]) ? valueDescriptions[SYMPTOM_MIN_VALUE] : ''}
        maxScaleDescription={Boolean(valueDescriptions[SYMPTOM_MAX_VALUE]) ? valueDescriptions[SYMPTOM_MAX_VALUE] : ''}
        enabled={enabled}
        onUpdate={onUpdate}
        value={value}
        disabledScroll={disabledScroll}
        scaleStartColor={SYMPTOM_SLIDER_START}
        scaleEndColor={SYMPTOM_SLIDER_END}
        valueDescriptions={valueDescriptions}
      />
    )
  };
};

SymptomSlider.defaultProps = {
  value: null,
  enabled: true,
  colorMode: COLOR_MODE_TYPES.gradient,
  valueDescriptions: []
};

SymptomSlider.propTypes = {
  value: PropTypes.number,
  enabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  minScaleDescription: PropTypes.string,
  maxScaleDescription: PropTypes.string,
  /**
   * If this value is set, the min/max scale won't be displayed and
   * the slider will display a tooltip with the description of that value
   */
  valueDescriptions: PropTypes.arrayOf(PropTypes.string),
  /**
   * This is important for iOS device to avoid the bounce effect.
   * In iOS devices, scroll event should disabled when user starts interacting.
   */
  disabledScroll: PropTypes.func,
};

export default SymptomSlider;
