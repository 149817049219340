import React from 'react';
import {View, Text, TextInput, Platform} from 'react-native';
import QuestionTitle from '../../../../components/QuestionTitle/QuestionTitle';
import AnswerCheckBox from '../../../../components/AnswerCheckBox/AnswerCheckBox';
import QuestionImage from '../QuestionImage';
import stylesCss from './QuestionMultipleSelectionStyle';
import QuestionWarning from '../../../../components/QuestionTitle/QuestionWarning';
import styles from "../../../../Css";

const QuestionMultipleSelection = ({
  title,
  questionIndex,
  allowOtherText,
  answer = {},
  onChangeText,
  choices = [],
  isReadOnly,
  imageUrl,
  onChange,
  required,
  requiredWarning,
  onSkip,
}) => {
  const selectedItems = answer && answer.selectedItems ? answer.selectedItems : [];
  const isSelected = (id) => {
    const found = selectedItems.find((item) => `${item}` === `${id}`);
    return Boolean(found);
  };

  const handleSelect = (id, status) => {
    const multiAnswerUpdated = selectedItems.filter((item) => {
      if (`${item}` === `${id}`) {
        return (status)
      }
      return true;
    });
    if (status) {
      multiAnswerUpdated.push(id);
    }

    onChange({selectedItems: multiAnswerUpdated , otherText: answer?.otherText});
  };

  return (
    <>
      {Boolean(imageUrl) && <View style={[stylesCss.questionImageWrapper]}>
        <QuestionImage imageUrl={imageUrl} />
      </View>}
      <View style={[stylesCss.surveyMultipleQuestionWrapper]}>
        <QuestionTitle questionIndex={questionIndex} title={title} required={required} onSkip={onSkip} isReadOnly={isReadOnly}/>
        <View>
          {choices
            .sort((firstEle, secondEl) => {
              const firstChoiceOrder = firstEle?.choiceOrder || 0;
              const secondChoiceOrder = secondEl?.choiceOrder || 0;
              return (firstChoiceOrder > secondChoiceOrder) ? 1 : -1;
            })
            .map(({ questionChoiceText, id }) => (
              <AnswerCheckBox
                  text={questionChoiceText}
                  selected={isSelected(id)}
                  enabled={!isReadOnly}
                  onSelect={(selectedStatus) => handleSelect(id, selectedStatus)}
                  requiredWarning={requiredWarning}
              />
          ))}
        </View>
        {Boolean(allowOtherText) && <View style={
          [
            (!isReadOnly || (isReadOnly && answer?.otherText)) && styles.greyBoxBorder, stylesCss.singleQuestionTextboxWrapper,
            isReadOnly && answer?.otherText && styles.customTextHighlighter,
          // {if required}
          requiredWarning && styles.inValidColor
        ]}>
          {!isReadOnly && <TextInput
              style={[
                stylesCss.singleQuestionInput,
                {
                  ...Platform.select({
                    android: {
                      textAlignVertical: "top",
                      height: 95,
                    },
                    ios: {
                      height: 95,
                    },
                  }),
                },
              ]}
              allowFontScaling={false}
              editable={!isReadOnly}
              multiline
              placeholderTextColor="#C4C4C4"
              placeholder="Other"
              value={answer?.otherText || ''}
              onChangeText={(text) => onChangeText({
                otherText: text,
                selectedItems: selectedItems
              })}
          />}
          {isReadOnly && answer?.otherText && <Text 
          style={[stylesCss.singleSelectionReadOnlyText, styles.textPrime,  styles.fontBold]} 
          allowFontScaling={false}>
            {answer?.otherText}
          </Text>}
        </View>}
    {/* Requird Field Warning Start */}
      {requiredWarning &&
        <QuestionWarning/>
      }
      {/* Requird Field Warning End */}
      </View>
    </>
  );
};

export default QuestionMultipleSelection;
