import React from 'react';
import {View, Image, Text, TouchableOpacity, ScrollView} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import {isMobile, browserName} from 'react-device-detect';
import PremiumOvalButton from '../../components/Button/premiumOvalButton';

const addMethodforEntry = props => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  return (
    <>
    {props.selected === 3 ? (
    <React.Fragment>
      <View style={[stylesRes.modalScrollBlock, stylesRes.newModalScrollBlock]} dataSet={{media: ids.newModalScrollBlock}}>
        <ScrollView style={[styles.modalScroller]}>
          <View style={[styles.popUpBlock, styles.menuBg, styles.newPopUpBlock]}>
            <View
            style={[styles.touchSpaceHeader, styles.newTouchSpaceHeader]}
            onPress={props.onPressClose}
          >
              <TouchableOpacity
              onPress={props.onPressClose}
              style={[styles.closeIconWrapper]}
              >
                <Image
                  style={[styles.closeIcon, styles.closeViewTouchBtnSm]}
                  source={require('../../assets/icons/close.png')}
                />
              </TouchableOpacity>
            </View>
            <View style={styles.popUpContentArea}>
              <View style={styles.gridSectionPopup}>
                <View style={[styles.popUpContentPdSm, styles.uploadAttachmentpopUpContentPadding]}>
                  <View style={stylesRes.modalContIn}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.uploadArea}>
                          <View style={[styles.uploadAreaRow, styles.newUploadAreaRow]}>
                            <TouchableOpacity
                              onPress={
                                props.selected !== 3 &&
                                props.accountType === 'regular'
                                  ? props.redirectPremiumAccount
                                  : props.onPressTakePhoto
                              }
                              style={styles.touchableComView}>
                              <View 
                                style={[stylesRes.uploadBtnSingle, stylesRes.newUploadBtnSingle, stylesRes.newUploadBtnSingleLeftBtn]}
                                dataSet={{media: ids.newUploadBtnSingle}}
                                nativeID={props.nativeIdTakePhoto}>
                                <View style={[stylesRes.uploadBtnLink, stylesRes.newUploadBtnLink]}>
                                  <View style={[stylesRes.uploadBtnImgFull, stylesRes.uploadBtnImgFullAlign]}>
                                    <View style={stylesRes.uploadBtnCamImgOrange}>
                                      <Image
                                        style={styles.imgContain}
                                        source={require('../../assets/ocr-icons/camera-icon-orange.png')}
                                      />
                                    </View>
                                    <View
                                      style={[
                                        styles.topBtnView,
                                        styles.topBtnViewPremium,
                                      ]}>
                                      {props.selected !== 3 &&
                                        props.accountType === 'regular' && (
                                          <PremiumOvalButton
                                            navigation={props.navigation}
                                            closeModal={props.onPressClose}
                                            fromPopup={props.fromPopup}
                                          />
                                        )}
                                    </View>
                                  </View>
                                  <View style={[styles.newUploadBtnText]}>
                                    {props.selected !== 3 ? (
                                      <Text
                                        style={[
                                          styles.textPrimeSm,
                                          styles.textAlignCenter,
                                          styles.btnMinText,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        Scan {'\n'}
                                        Business Card
                                      </Text>
                                    ) : (
                                      <Text
                                        style={[
                                          styles.textPrimeSm,
                                          styles.textAlignCenter,
                                          styles.btnMinText,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        Take {'\n'}
                                        Photo
                                      </Text>
                                    )}
                                  </View>
                                  {/* {props.selected !== 3 &&
                                    props.accountType === "regular" && (
                                      // <View style={styles.topBtnView}>
                                      //   <View style={styles.topBtnCont}>
                                      //     <Text
                                      //       style={[
                                      //         styles.textPrime,
                                      //         styles.textAlignCenter,
                                      //         styles.textBlack,
                                      //       ]}>
                                      //       Premium
                                      //     </Text>
                                      //   </View>
                                      // </View>

                                    )} */}
                                </View>
                              </View>
                            </TouchableOpacity>
                            {props.selected !== 3 ? (
                              <TouchableOpacity
                                onPress={props.onPressManuallyEntry}
                                style={styles.touchableComView}>
                                <View style={stylesRes.uploadBtnSingle} dataSet={{media: ids.uploadBtnSingle}}>
                                  <View style={stylesRes.uploadBtnLink}>
                                    <View style={stylesRes.uploadBtnImgFull}>
                                      <View style={stylesRes.uploadBtnJournalImg}>
                                        <Image
                                          style={styles.imgContain}
                                          source={require('../../assets/ocr-icons/journal.png')}
                                        />
                                      </View>
                                      <View
                                        style={[
                                          stylesRes.uploadPlusImg,
                                          stylesRes.uploadJournalSec,
                                        ]}>
                                        <Image
                                          style={styles.imgContain}
                                          source={require('../../assets/ocr-icons/plus-orange.png')}
                                        />
                                      </View>
                                    </View>
                                    <View style={styles.uploadBtnText}>
                                      <Text
                                        style={[
                                          styles.textPrimeSm,
                                          styles.textAlignCenter,
                                          styles.btnMinText,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        Enter {'\n'}
                                        Manually
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </TouchableOpacity>
                            ) : null}
                            {props.selected !== 3 ?
                            null : (
                              <TouchableOpacity
                                onPress={props.onPressUploadEntry}
                                style={styles.touchableComView}>
                                <View style={[stylesRes.uploadBtnSingle, stylesRes.newUploadBtnSingle, stylesRes.newUploadBtnSingleRightBtn]} dataSet={{media: ids.newUploadBtnSingle}}>
                                  <View style={[stylesRes.uploadBtnLink, stylesRes.newUploadBtnLink]}>
                                    <View style={[stylesRes.uploadBtnImgFull, stylesRes.uploadBtnImgFullAlign]}>
                                      <View style={stylesRes.uploadIconOrange}>
                                        <Image
                                          style={styles.imgContain}
                                          source={require('../../assets/ocr-icons/upload-Icon-orange.png')}
                                        />
                                      </View>
                                    </View>
                                    <View style={[styles.newUploadBtnText]}>
                                      <Text
                                        style={[
                                          styles.textPrimeSm,
                                          styles.textAlignCenter,
                                          styles.btnMinText,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        Upload {'\n'}
                                        File
                                      </Text>
                                    </View>
                                  </View>
                                  <View
                                  nativeID={'test-inpt'}
                                    style={[
                                      styles.fileUploadBtn,
                                      styles.fileUploadPost,
                                      styles.fileUploadInputWrapper,
                                    ]}>
                                    {isMobile  ? ( //validated this as only mobile. pls check
                                      <input
                                        type="file"
                                        name="file"
                                        id={props.nativeIdUploadFile}
                                        onChange={props.onChange}
                                        // onClick={props.onClick}
                                        style={{
                                          cursor: 'pointer',
                                          width: 100,
                                          height: 140,
                                          marginTop: 0,
                                        }}
                                      />
                                    ) : (
                                      <input
                                        type="file"
                                        name="file"
                                        id={props.nativeIdUploadFile}
                                        onChange={props.onChange}
                                        multiple
                                        // onClick={props.onClick}
                                        style={{
                                          cursor: 'pointer',
                                          width: 200,
                                          height: 140,
                                          marginTop: 0,
                                        }}
                                      />
                                    )}
                                  </View>
                                </View>
                              </TouchableOpacity>
                            )}
                          </View>
                              {Boolean(props.showSelectFromZamplo) && (
                            <View style={[styles.flexAiCt, styles.barTopSp]}>
                              <TouchableOpacity onPress={props.onSelectFromZamplo}>
                                <Text style={[styles.textPrimeLgBold, styles.seaBluTxtColor, styles.readMoreTxt]}> 
                                  Select from Zamplo Attachments 
                                </Text>
                              </TouchableOpacity>
                            </View>
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </React.Fragment>
    ) : (
      <React.Fragment>
      <View style={[stylesRes.modalScrollBlock]} dataSet={{media: ids.modalScrollBlock}}>
        <TouchableOpacity
          style={[styles.touchSpaceHeader]}
          onPress={props.onPressClose}
        />
        <TouchableOpacity
          onPress={props.onPressClose}
          style={[styles.closeViewTouchWrap, styles.closeViewTopLg]}>
          <Image
            style={[styles.closeIcon, styles.closeViewTouchBtnSm]}
            source={require('../../assets/icons/close.png')}
          />
        </TouchableOpacity>
        <ScrollView style={styles.modalScroller}>
          <View style={[styles.popUpBlock, styles.bgWhite]}>
            <View style={styles.popUpContentArea}>
              <View style={styles.gridSectionPopup}>
                <View style={[styles.popUpContentPdSm, styles.menuBg]}>
                  <View style={stylesRes.modalContIn}>
                    <View style={styles.gridRow}>
                      <View style={styles.gridColFull}>
                        <View style={styles.uploadArea}>
                          <View style={styles.uploadAreaRow}>
                            <TouchableOpacity
                              onPress={
                                props.selected !== 3 &&
                                props.accountType === 'regular'
                                  ? props.redirectPremiumAccount
                                  : props.onPressTakePhoto
                              }
                              style={styles.touchableComView}>
                              <View style={stylesRes.uploadBtnSingle} dataSet={{media: ids.uploadBtnSingle}} nativeID={'contactScanCard'}>
                                <View style={stylesRes.uploadBtnLink}>
                                  <View style={stylesRes.uploadBtnImgFull}>
                                    <View style={stylesRes.uploadBtnCamImg}>
                                      <Image
                                        style={styles.imgContain}
                                        source={require('../../assets/ocr-icons/camera.png')}
                                      />
                                    </View>
                                    <View
                                      style={[
                                        styles.topBtnView,
                                        styles.topBtnViewPremium,
                                      ]}>
                                      {props.selected !== 3 &&
                                        props.accountType === 'regular' && (
                                          <PremiumOvalButton
                                            navigation={props.navigation}
                                            closeModal={props.onPressClose}
                                            fromPopup={props.fromPopup}
                                          />
                                        )}
                                    </View>

                                    <View
                                      style={[
                                        stylesRes.uploadPlusImg,
                                        stylesRes.uploadJournalSec,
                                      ]}>
                                      {props.selected !== 3 &&
                                      props.accountType === 'regular' ? (
                                        <Image
                                          style={styles.imgContain}
                                          source={require('../../assets/icons/plus-grey-large.png')}
                                        />
                                      ) : (
                                        <Image
                                          style={styles.imgContain}
                                          source={require('../../assets/icons/plus-orange-large.png')}
                                        />
                                      )}
                                    </View>
                                  </View>
                                  <View style={styles.uploadBtnText}>
                                    {props.selected !== 3 ? (
                                      <Text
                                        style={[
                                          styles.textPrimeSm,
                                          styles.textAlignCenter,
                                          styles.btnMinText,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        Scan {'\n'}
                                        Business Card
                                      </Text>
                                    ) : (
                                      <Text
                                        style={[
                                          styles.textPrimeSm,
                                          styles.textAlignCenter,
                                          styles.btnMinText,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        Take {'\n'}
                                        Photo
                                      </Text>
                                    )}
                                  </View>
                                </View>
                              </View>
                            </TouchableOpacity>
                            {props.selected !== 3 ? (
                              <TouchableOpacity
                                onPress={props.onPressManuallyEntry}
                                style={styles.touchableComView}>
                                <View style={stylesRes.uploadBtnSingle} dataSet={{media: ids.uploadBtnSingle}} nativeID={'contactEnterManually'}>
                                  <View style={stylesRes.uploadBtnLink}>
                                    <View style={stylesRes.uploadBtnImgFull}>
                                      <View style={stylesRes.uploadBtnJournalImg}>
                                        <Image
                                          style={styles.imgContain}
                                          source={require('../../assets/ocr-icons/journal.png')}
                                        />
                                      </View>
                                      <View
                                        style={[
                                          stylesRes.uploadPlusImg,
                                          stylesRes.uploadJournalSec,
                                        ]}>
                                        <Image
                                          style={styles.imgContain}
                                          source={require('../../assets/ocr-icons/plus-orange.png')}
                                        />
                                      </View>
                                    </View>
                                    <View style={styles.uploadBtnText}>
                                      <Text
                                        style={[
                                          styles.textPrimeSm,
                                          styles.textAlignCenter,
                                          styles.btnMinText,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        Enter {'\n'}
                                        Manually
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </TouchableOpacity>
                            ) : null}
                            {props.selected !== 3 ?
                            null : (
                              <TouchableOpacity
                                onPress={props.onPressUploadEntry}
                                style={styles.touchableComView}>
                                <View style={stylesRes.uploadBtnSingle} dataSet={{media: ids.uploadBtnSingle}}>
                                  <View style={stylesRes.uploadBtnLink}>
                                    <View style={stylesRes.uploadBtnImgFull}>
                                      <View style={stylesRes.uploadBtnJournalImg}>
                                        <Image
                                          style={styles.imgContain}
                                          source={require('../../assets/ocr-icons/upload.png')}
                                        />
                                      </View>
                                      <View
                                        style={[
                                          stylesRes.uploadPlusImg,
                                          stylesRes.uploadJournalSec,
                                        ]}>
                                        <Image
                                          style={styles.imgContain}
                                          source={require('../../assets/ocr-icons/plus-orange.png')}
                                        />
                                      </View>
                                    </View>
                                    <View style={styles.uploadBtnText}>
                                      <Text
                                        style={[
                                          styles.textPrimeSm,
                                          styles.textAlignCenter,
                                          styles.btnMinText,
                                        ]}
                                        allowFontScaling={false}
                                        >
                                        Upload
                                      </Text>
                                    </View>
                                  </View>
                                  <View
                                    style={[
                                      styles.fileUploadBtn,
                                      styles.fileUploadPost,
                                    ]}>
                                    {isMobile &&
                                    browserName.includes('Firefox') ? (
                                      <input
                                        type="file"
                                        name="file"
                                        id="btns"
                                        onChange={props.onChange}
                                        // onClick={props.onClick}
                                        style={{
                                          cursor: 'pointer',
                                          width: 100,
                                          height: 125,
                                          marginTop: 0,
                                        }}
                                      />
                                    ) : (
                                      <input
                                        type="file"
                                        name="file"
                                        id="btns"
                                        onChange={props.onChange}
                                        multiple
                                        // onClick={props.onClick}
                                        style={{
                                          cursor: 'pointer',
                                          width: 100,
                                          height: 125,
                                          marginTop: 0,
                                        }}
                                      />
                                    )}
                                  </View>
                                </View>
                              </TouchableOpacity>
                            )}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </React.Fragment>
    )}
    </>
  );
};
export default (addMethodforEntry);
