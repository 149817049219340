import React from "react";
import { View, ScrollView } from "react-native";
import stylesResponsive from "../../../CssResponsive";
import styles from "../../../Css";
import AddButton from "./addButton";
import TitleBlock from "./titleBlock";
import ContentBlock from "./contentBlock";
import ModelCloseButton from "./modelCloseButton";
import ModalTemplate from "./modalTemplate";
import {pendoTrackEvent} from '../../../util/pendoConfig';

const journalEntryTypeModalContent = (props) => {
  const {styles: stylesRes, ids} = stylesResponsive.getProcessedStyles();
  const {
    journalEntryTypes,
    labsEntryType,
    newJournalEntryType,
    closeModal,
    handleCreateNewRoutine,
    addNewRoutine,
  } = props;
  return (
    <React.Fragment>
      <View style={[stylesRes.modalScrollBlockSm]} dataSet={{media: ids.modalScrollBlockSm}}>
        <ModelCloseButton closeModal={closeModal} />
        <ScrollView style={styles.modalScroller}>
        <ModalTemplate>
            {Boolean(newJournalEntryType) && (
            <ContentBlock
              entryType={newJournalEntryType}
              onPressRoutine={() => {
                handleCreateNewRoutine(newJournalEntryType.id);
                pendoTrackEvent('Journals > NewJournal');
              }}
              nativeId={'journalsNewJournal'}
            />
          )}

            {Boolean(labsEntryType) && (
            <ContentBlock
              entryType={labsEntryType}
              onPressRoutine={() => {
                handleCreateNewRoutine(labsEntryType.id);
                pendoTrackEvent('Journals > Labs&Reports');
              }}
              nativeId={'journalsLabReports'}
            />
          )}
          <TitleBlock titleText={"My routines"} />
            {Boolean(journalEntryTypes) &&
            journalEntryTypes
              .filter(
                (entryType) =>
                  entryType.userCanEdit &&
                  entryType.isActive &&
                  !entryType.isAppointment &&
                  entryType.isHidden === false &&
                  entryType.type !== "DEVICE"
              )
              .sort((a, b) => (a.name !== null && b.name !== null) && a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
              .map((res) => (
                <ContentBlock
                  entryType={res}
                  onPressRoutine={() => {
                    handleCreateNewRoutine(res.id);
                    pendoTrackEvent('Journals > RoutineEntry');
                  }}
                  nativeId={'journalsRoutineEntry'}
                />
              ))}
          <AddButton
            onPressAddButton={() => {
              addNewRoutine();
              pendoTrackEvent('Journals > AddRoutine');
            }}
            btnText={"+ New Routine"}
            nativeId={'journalsAddRoutine'}
          />
        </ModalTemplate>
        </ScrollView>
      </View>
    </React.Fragment>
  );
};

export default (journalEntryTypeModalContent);
