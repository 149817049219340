import React, {Component} from 'react';
import {View, Platform} from 'react-native';
import styles from '../../../../Css';
import {connect} from 'react-redux';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextFrame from '../../../TextInput/textFrame';
import ItemDeleteButton from '../../../Button/itemDeleteButton';
import {decimalRegex} from '../../../../Constant';
import TextInputQuantityFrame from '../../../TextInput/textInputQuantityFrame';
import TextPickerFrame from '../../../TextInput/textPickerFrame';
import { backgroundColor } from '../../../../modules/datePicker/style';
import { WEB } from '../../../../util/platformWindowConfig';


class UserItem extends Component {
  constructor(props) {
    super(props);
    const {metricsDigestProcessed, data, updateHealthData, index} = props;
    const {selectableMetrics} = metricsDigestProcessed;
    const {metric, metricRef: {publishedBy} = {}, value, uomOption} = data;
    const {name} = selectableMetrics[metric];

    this.state = {
      ...data,
      name: name,
      unitName: UserItem.getUnitName(props).unitName,
      unitOfMeasure: UserItem.getUnitName(props).uomId,
      publishedBy: publishedBy,
      unitValueChanging: false,
    };
    const options = props.measurementUnits.filter(item => item.id === this.state.unitOfMeasure)?.[0]?.options;
    let id = null;
    if (Boolean(data.uomOption)) {
      options.forEach(item => {
        if (item.id === data.uomOption) {
          id = item.id;
        }
      })
    }
    updateHealthData(index, {
      ...data,
      unitOfMeasure: this.state.unitOfMeasure,
      value: value ? value : (options && options.length > 0 ? id ? id : options[0].id : null),
      uomOption: uomOption ? uomOption : (options && options.length > 0 ? id ? id : options[0].id : null),
    })
  }


  static getUnitName = props => {
    const {data, unitsOfMeasureDigestProcessed, metricsDigest} = props;
    const {metric, unitOfMeasure} = data;
    const {unitsOfMeasure} = unitsOfMeasureDigestProcessed;

    let unitName;
    let uomId = unitOfMeasure;

    if (unitOfMeasure && unitsOfMeasure[unitOfMeasure]) {
      unitName = unitsOfMeasure[unitOfMeasure].symbol;
    } else {
      const {
        userDefinedMetricUnitOfMeasures,
        systemDefinedMetricUnitOfMeasures,
      } = metricsDigest;
      let found = userDefinedMetricUnitOfMeasures.find(
        element => element.metricId === metric,
      );
      if (!found) {
        found = systemDefinedMetricUnitOfMeasures.find(
          element => element.metricId === metric,
        );
      }
      const {unitsOfMeasure} = unitsOfMeasureDigestProcessed;

      if (found && unitsOfMeasure[found.uomId]) {
        unitName = unitsOfMeasure[found.uomId].symbol;
        uomId = found.uomId;
      }
    }
    return {unitName: unitName, uomId: uomId};
  };

  static getDerivedStateFromProps(props, state) {
    const {data, metricsDigestProcessed} = props;
    const {selectableMetrics} = metricsDigestProcessed;
    const {metric, metricRef: {publishedBy} = {}} = data;
    const {name} = selectableMetrics[metric];

    if (!state.unitValueChanging && ((data && data.value !== state.value) || data.name !== state.name)) {
      return {
        value: data.value,
        name: name,
        unitName: UserItem.getUnitName(props).unitName,
        unitOfMeasure: UserItem.getUnitName(props).uomId,
        publishedBy: publishedBy,
      };
    }

    return null;
  }

  onChangeTextQuantity = val => {
    const {value, unitOfMeasure, unitName} = this.state;
    const {updateHealthData, index, data} = this.props;
    let pass = true;
    if (!isNaN(val) && val.match(decimalRegex) && ((unitName === '10pt' && val > 10) || (unitName === '5pt' && val > 5))) {
      pass = false;
    } else if (isNaN(val) || !val.match(decimalRegex)) {
      pass = false;
    }
    if (pass) {
      this.setState({value: val ? val: null}, () =>
        updateHealthData(index, {
          ...data,
          unitOfMeasure: unitOfMeasure,
          value: val ? val : null,
        }),
      );
    } else {
      this.setState({value: value}, () =>
        updateHealthData(index, {
          ...data,
          unitOfMeasure: unitOfMeasure,
          value: parseFloat(value),
        }),
      );
    }
  };

  onSetQuantity = val => {
    const {updateHealthData, index, data} = this.props;
    this.setState({value: val, unitValueChanging: true}, () => {
      updateHealthData(index, {
        ...data,
        unitOfMeasure: this.state.unitOfMeasure,
        value: val,
        uomOption: val,
      });
      this.setState({unitValueChanging: false});
    });
  };

  renderInputfield = () => {
    const {deviceJournal, data, measurementUnits} = this.props;
    const {value} = this.state;
    const uomId = data?.unitOfMeasure;
    const deviceType = data?.metricRef?.deviceType;
    const measurement = measurementUnits.filter(item => item.id === uomId)?.[0]
    let component = null;
    component = (
      <TextInputQuantityFrame
        title={'Quantity'}
        fieldBg={FieldDetails.BGWHITE}
        fieldAlign={FieldDetails.ALIGNCENTER}
        value={value || value === 0 ? value.toString() : ''}
        // value={this.state.value || ""}
        keyboardType={Platform.OS === WEB ? 'default' : 'numeric'}
        onChangeText={this.onChangeTextQuantity}
        maxLength={9}
        editable={!(deviceJournal && Boolean(deviceType))}
      />
    );
    if (measurement && measurement.options && measurement?.options.length > 0) {
      const items = [];
      measurement.options.forEach(element => {
        items.push({
          label: element.name, 
          value: element.id, 
          key: element.id,
        })
      });
      component = (
        <TextPickerFrame
          title={'Measure'}
          fieldBg={FieldDetails.BGWHITE}
          items={items}
          selectedValue={this.state.value}
          onValueChange={this.onSetQuantity}
        />
      );
    }
    return component;
  }

  render() {
    const {name, unitName, publishedBy} = this.state;
    const {deleteItem, index} = this.props;
    return (
      <>
        <View style={[ styles.fieldContainerCom]}>
          <View style={[styles.fieldRowWrapper]}>
            <View style={styles.fieldColStretch}>
              <TextFrame
                title={'Health Data'}
                fieldBg={FieldDetails.BGGREY}
                fieldValue={name}
                noOfLines={1}
                editable={false}
              />
            </View>
            <View style={styles.fieldColSp} />
            <View style={styles.fieldColDelete}>
              {publishedBy !== 'PARTNER' && (
                <ItemDeleteButton touchableOnPress={() => deleteItem(index)} />
              )}
            </View>
          </View>
        </View>
        <View style={[styles.fieldContainer]}>
          <View style={[styles.fieldRowWrapper]}>
            <View style={styles.fieldColPlusMinus}>
              {this.renderInputfield()}
              {/* // TODO: ZI-883 remove old codes
              <TextInputQuantityFrame
                title={'Quantity'}
                fieldBg={FieldDetails.BGWHITE}
                fieldAlign={FieldDetails.ALIGNCENTER}
                value={value || value === 0 ? value.toString() : ''}
                keyboardType={Platform.OS === 'web' ? 'default' : 'numeric'}
                onChangeText={this.onChangeTextQuantity}
              /> */}
            </View>
            <View style={styles.fieldColSp} />
            <View style={styles.fieldColMeasure}>
              <TextFrame
                title={'Unit of Measure'}
                fieldBg={FieldDetails.BGGREY}
                fieldValue={unitName}
                editable={false}
              />
            </View>
          </View>
        </View>
        <View style={[styles.secSepBorderBot, styles.comSp]} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    metricsDigestProcessed: state.routines.metricsDigestProcessed,
    metricsDigest: state.routines.metricsDigest,
    unitsOfMeasureDigestProcessed: state.routines.unitsOfMeasureDigestProcessed,
    measurementUnits: state.routines.unitsOfMeasureDigest.unitsOfMeasure,
    hasInvalidNamesInActivity: state.therapie.hasActivityInvalidName,
    hasInvalidNamesInMedication: state.therapie.hasMedicationInvalidName,
    hasInvalidNamesInHealthData: state.therapie.hasHealthDataInvalidName,
    hasInvalidNamesInSymtoms: state.therapie.hasSymptomsInvalidName,
  };
};

export default connect(
  mapStateToProps,
  null,
)(UserItem);
