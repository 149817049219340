import React, { Component } from "react";
import { ActivityIndicator } from "react-native";
import { View, Text, TouchableOpacity, Image } from "react-native";
import XDate from "xdate";
import PropTypes from "prop-types";
import styleConstructor from "./style";
import { weekDayNames } from "../../dateutils";
import {
  CHANGE_MONTH_LEFT_ARROW,
  CHANGE_MONTH_RIGHT_ARROW
} from "../../testIDs";

export default class CalendarHeader extends Component {
  constructor(props) {
    super(props);
    this.style = styleConstructor(props.theme);
    this.addMonth = this.addMonth.bind(this);
    this.substractMonth = this.substractMonth.bind(this);
    this.onPressLeft = this.onPressLeft.bind(this);
    this.onPressRight = this.onPressRight.bind(this);
  }

  addMonth() {
    this.props.addMonth(1);
  }

  substractMonth() {
    this.props.addMonth(-1);
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.month.toString("yyyy MM") !==
      this.props.month.toString("yyyy MM")
    ) {
      return true;
    }
    if (nextProps.showIndicator !== this.props.showIndicator) {
      return true;
    }
    if (nextProps.hideDayNames !== this.props.hideDayNames) {
      return true;
    }
    if (nextProps.mode !== this.props.mode) {
      return true;
    }
    if (nextProps.yearRange !== this.props.yearRange) {
      return true;
    }
    return false;
  }

  onPressLeft() {
    const { onPressArrowLeft } = this.props;
    if (typeof onPressArrowLeft === "function") {
      return onPressArrowLeft(this.substractMonth);
    }
    return this.substractMonth();
  }

  onPressRight() {
    const { onPressArrowRight } = this.props;
    if (typeof onPressArrowRight === "function") {
      return onPressArrowRight(this.addMonth);
    }
    return this.addMonth();
  }

  _renderHeader() {
    let leftArrow = <View />;
    let rightArrow = <View />;
    let weekDaysNames = weekDayNames(this.props.firstDay);
    let indicator;
    if (this.props.showIndicator) {
      indicator = <ActivityIndicator />;
    }
    switch (this.props.mode) {
      case "month":
        return (
          <View>
            {/* <View style={this.style.arrow} /> */}
            {!this.props.hideMonth && (
              <View style={this.style.header}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                  }}
                >
                  <TouchableOpacity onPress={this.props.onYearSelect}>
                    <Text
                      allowFontScaling={false}
                      style={this.style.monthText}
                      accessibilityTraits="header"
                    >
                      {this.props.month.toString(this.props.yearFormat)}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </View>
        );
      case "year":
        //const year = new XDate(this.props.yearRange);
        const minYear = this.props.yearRange.getFullYear() - 8;
        const maxYear = this.props.yearRange.getFullYear() + 7;

        if (!this.props.hideArrows) {
          leftArrow = (
            <TouchableOpacity
              onPress={() => this.props.onPressYearSelectL(minYear - 8)}
              style={this.style.arrow}
              hitSlop={{ left: 20, right: 20, top: 20, bottom: 20 }}
              testID={CHANGE_MONTH_LEFT_ARROW}
            >
              {this.props.renderArrow ? (
                this.props.renderArrow("left")
              ) : (
                <Image
                  source={require("../img/c-prev.png")}
                  style={[this.style.arrowImage, this.style.arrowImageIcon]}
                />
              )}
            </TouchableOpacity>
          );
          rightArrow = (
            <TouchableOpacity
              onPress={() => this.props.onPressYearSelectR(maxYear + 9)}
              style={this.style.arrow}
              hitSlop={{ left: 20, right: 20, top: 20, bottom: 20 }}
              testID={CHANGE_MONTH_RIGHT_ARROW}
            >
              {this.props.renderArrow ? (
                this.props.renderArrow("right")
              ) : (
                <Image
                  source={require("../img/c-next.png")}
                  style={[this.style.arrowImage, this.style.arrowImageIcon]}
                />
              )}
            </TouchableOpacity>
          );
        }

        return (
          <View>
            {!this.props.hideMonth && (
              <View style={this.style.header}>
                {leftArrow}
                <View style={{ flexDirection: "row" }}>
                  {/* <Text
                      allowFontScaling={false}
                      style={this.style.monthText}
                      accessibilityTraits="header"
                    >
                      {this.props.month.toString(this.props.monthFormat)}
                    </Text> */}
                  {/* <Text
                      allowFontScaling={false}
                      style={this.style.monthText}
                      accessibilityTraits="header"
                    >
                      {this.props.month.toString(this.props.yearFormat)}
                    </Text> */}

                  <Text
                    allowFontScaling={false}
                    style={this.style.monthText}
                    accessibilityTraits="header"
                  >
                    {`${minYear} - ${maxYear}`}
                  </Text>
                </View>
                {rightArrow}
              </View>
            )}
          </View>
        );
      default:
        if (!this.props.hideArrows) {
          leftArrow = (
            <TouchableOpacity
              onPress={this.onPressLeft}
              style={this.style.arrow}
              hitSlop={{ left: 20, right: 20, top: 20, bottom: 20 }}
              testID={CHANGE_MONTH_LEFT_ARROW}
            >
              {this.props.renderArrow ? (
                this.props.renderArrow("left")
              ) : (
                <Image
                  source={require("../img/c-prev.png")}
                  style={[this.style.arrowImage, this.style.arrowImageIcon]}
                />
              )}
            </TouchableOpacity>
          );
          rightArrow = (
            <TouchableOpacity
              onPress={this.onPressRight}
              style={this.style.arrow}
              hitSlop={{ left: 20, right: 20, top: 20, bottom: 20 }}
              testID={CHANGE_MONTH_RIGHT_ARROW}
            >
              {this.props.renderArrow ? (
                this.props.renderArrow("right")
              ) : (
                <Image
                  source={require("../img/c-next.png")}
                  style={[this.style.arrowImage, this.style.arrowImageIcon]}
                />
              )}
            </TouchableOpacity>
          );
        }
        return (
          <View>
            {!Boolean(this.props.hideMonth) && (
              <React.Fragment>
                <View style={this.style.header}>
                  {leftArrow}
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={this.props.onMonthSelect}>
                      <Text
                        allowFontScaling={false}
                        style={this.style.monthText}
                        accessibilityTraits="header"
                      >
                        {this.props.month.toString(this.props.monthFormat)}
                      </Text>
                    </TouchableOpacity>
                    <Text />
                    <TouchableOpacity onPress={this.props.onYearSelect}>
                      <Text
                        allowFontScaling={false}
                        style={this.style.monthText}
                        accessibilityTraits="header"
                      >
                        {this.props.month.toString(this.props.yearFormat)}
                      </Text>
                    </TouchableOpacity>
                    {indicator}
                  </View>
                  {rightArrow}
                </View>
                {!Boolean(this.props.hideDayNames) && (
                  <View style={this.style.week}>
                    {Boolean(this.props.weekNumbers) && (
                      <Text
                        allowFontScaling={false}
                        style={this.style.dayHeader}
                      />
                    )}
                    {weekDaysNames.map((day, idx) => (
                      <Text
                        allowFontScaling={false}
                        key={idx}
                        accessible={false}
                        style={this.style.dayHeader}
                        numberOfLines={1}
                        importantForAccessibility="no"
                      >
                        {day}
                      </Text>
                    ))}
                  </View>
                )}
              </React.Fragment>
            )}
          </View>
        );
    }
  }

  render() {
    // return (
    //   <View>
    //     <View style={this.style.header}>
    //       {leftArrow}
    //       <View style={{ flexDirection: "row" }}>
    //         <Text
    //           allowFontScaling={false}
    //           style={this.style.monthText}
    //           accessibilityTraits="header"
    //         >
    //           { !this.props.hideMonth && this.props.month.toString(this.props.monthFormat)}
    //         </Text>
    //         {indicator}
    //       </View>
    //       {rightArrow}
    //     </View>
    //     {!this.props.hideDayNames && (
    //       <View style={this.style.week}>
    //         {this.props.weekNumbers && (
    //           <Text allowFontScaling={false} style={this.style.dayHeader} />
    //         )}
    //         {weekDaysNames.map((day, idx) => (
    //           <Text
    //             allowFontScaling={false}
    //             key={idx}
    //             accessible={false}
    //             style={this.style.dayHeader}
    //             numberOfLines={1}
    //             importantForAccessibility="no"
    //           >
    //             {day}
    //           </Text>
    //         ))}
    //       </View>
    //     )}
    //   </View>
    // );
    return <React.Fragment>{this._renderHeader()}</React.Fragment>;
  }
}

CalendarHeader.propTypes = {
  theme: PropTypes.object,
  hideArrows: PropTypes.bool,
  month: PropTypes.instanceOf(XDate),
  addMonth: PropTypes.func,
  showIndicator: PropTypes.bool,
  firstDay: PropTypes.number,
  renderArrow: PropTypes.func,
  hideDayNames: PropTypes.bool,
  weekNumbers: PropTypes.bool,
  onPressArrowLeft: PropTypes.func,
  onPressArrowRight: PropTypes.func
};

// CalendarHeader.defaultProps = {
//   monthFormat: "MMMM yyyy"
// };

CalendarHeader.defaultProps = {
  monthFormat: "MMMM",
  yearFormat: "yyyy"
};
