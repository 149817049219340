import React from 'react';
import { View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import { connect } from 'react-redux';
import moment from 'moment';
import AddQuickNote from '../../components/AddQuickNote/AddQuickNote';
import SuccessBox from '../../components/ConfirmationBox/successBox';
import SavingConfirmation from '../../components/ConfirmationBox/savingConfirmation';
import Cropper from '../../components/ImageCropper/AmazingCropperPage';
import MenuOverlayContainer from '../../components/PageLayout/MenuOverlayContainer';
import Camera from '../../components/Attachment/AttachmentImageBoxUpdated/cameraModal';
import AddQuickNoteCommon from './AddQuickNoteCommon';
import styles from '../../Css';
import { generateQuickNoteTitle } from './Util/QuickNoteUtils';
import { newLineCharReplacement } from '../../util/stringManipulations';
import { alertBody, qnoteTitle } from '../../GlobalFunctions/GlobalString';
import { getAccountType } from '../../util/subscriptionInformation';
import { deleteFileByUri } from '../../axios/common';
import { pendoTrackEvent } from '../../util/pendoConfig';
import * as actions from '../../store/actions/index';
import { getScrollHeight } from '../../util/commonUiLogic';
import { JOURNALS, DASHBOARD } from '../../navigation/path';
import { IOS, TAB_BREAk_POINT_END, WEB } from '../../util/platformWindowConfig';

let initialDate = null;

class AddQuickNoteScreen extends AddQuickNoteCommon {
  constructor(props) {
    super(props);
    const accountType = getAccountType(props.userSubscriptions);
    this.state = {
      date: moment().valueOf(),
      files: {},
      images: {},
      fileSize: 0,
      index: 0,
      uploadError: false,
      uploadErrorMesage: null,
      confirmBox: false,
      visibleEntryModal: false,
      visibleImageTakePhoto: false,
      windowHeight: props.windowHeight,
      windowWidth: props.windowWidth,
      visibleUploadTypeModal: false,
      quickNote: '',
      quickNoteJournalName: '',
      height: 100,
      accountType: accountType,
      journalName: true,
      cameraOpened: false,
    };
  }

  componentDidMount() {
    initialDate = this.state.date;
    this.getFileAndImage(
      Object.values(this.props.files),
      (files, images, index, fileSize) =>
        this.setState({files, images, index, fileSize}),
    );

    this.props.navigation.addListener('focus', () => {
      this.props.hideMobileMenu();
      pendoTrackEvent('AddQuickNote@');
    });
    this.props.navigation.addListener('blur', () => {
      if (Platform.OS === IOS) {
        Object.values(this.state.files).forEach((res, index) => {
          deleteFileByUri(res.uri);
        });
      }
      this.props.showMobileMenu();
    });
  }

  componentWillReceiveProps(props) {
    const {windowHeight, windowWidth} = props;
    this.setState({
      windowWidth,
      windowHeight,
    });
  }

  onChangeQuickNote = text => {
    this.setState({quickNote: text});
    if (this.state.journalName) {
      if (text.length > 34) {
        this.setState({
          quickNoteJournalName: ` ${newLineCharReplacement(
            text.substring(0, 34).slice(0, -3),
          )}...`,
        });
      } else {
        this.setState({quickNoteJournalName: newLineCharReplacement(text)});
      }
    }
  };

  onChangeJournalName = text => {
    this.state.journalName && this.setState({journalName: false});
    this.setState({quickNoteJournalName: text});
  };

  onChangeDate = date => {
    if (date) {
      this.setState({date: date});
    }
  };

  handleDeleteAttachment = id => {
    const filterData = this.state.files;
    const filterimages = this.state.images;
    delete filterData[id];
    delete filterimages[id];
    this.setState({
      files: filterData,
      images: filterimages,
    });
  };

  handleCreateNewRoutine = () => {
    this.setState({showSpinner: true});

    if (
      Boolean(Object.keys(this.state.files).length) ||
      Boolean(this.state.quickNoteJournalName.trim().length) ||
      Boolean(this.state.quickNote.trim().length)
    ) {
      let newTemplate = {
        id: -1,
        title: generateQuickNoteTitle(
          this.state.quickNoteJournalName.trim(),
          this.state.quickNote.trim(),
          this.state.files,
        ),
        entryDate: this.state.date,
        entryType: this.props.applicationSettingsDigest.quickNoteEntryTypeId,
        entry: this.state.quickNote.trim(),
      };

      pendoTrackEvent('QuickNote@ > Save');
      this.props.onSaveJournalEntry(
        {journalEntry: newTemplate},
        (res, data) => {
          if (res) {
            // const currDate = moment.utc().format("x");
            // this._createQuestion(data.artifactId, currDate);

            if (Boolean(Object.keys(this.state.files).length)) {
            const artifactId = data.journalEntry.artifactId;
            const formData = new FormData();
            const ocrData = {};
            let fileSize = 0;
            Object.values(this.state.files).forEach((res, index) => {
              fileSize += res.size;
              formData.append('files[' + index + ']', res, res.name);
              ocrData[res.name] = res;
            });
            Platform.OS !== WEB && this.setKeepAwake();

            this.props.onUpload(
              artifactId,
              formData,
              (success, attachments) => {
                if (success && attachments) {
                  this.getFileContentData(attachments, ocrData, artifactId);
                }
                this.handleQuickNoteNavigation(data);
              },
              false,
              this.props.journalUserId,
              fileSize,
            );
            } else {
              this.handleQuickNoteNavigation(data);
            }
          } else {
            this.setState({showSpinner: false});
          }
        },
        this.props.journalUserId,
      );
    } else {
      this.setState({showSpinner: false});
    }
  };

  handleQuickNoteNavigation = (data) => {
    this.setState({
      showSpinner: false, 
      confirmBox: false, 
      successModalVisible: true
    });

    setTimeout(() => {
      this.setState({ successModalVisible: false });
      this.props.clearFilesToAttach();
      this.props.onSetSelectedJournalEntry(data.journalEntry);
      if (this.props.windowWidth < TAB_BREAk_POINT_END) {
        this.props.navigation.reset({
          index: 0,
          routes: [{ 
            name: JOURNALS.viewJournalPath,
            params: {
              from: this.props.route.name,
              journalName: data.journalEntry?.title
            } 
          }],
        });
      } else {
        this.props.navigation.reset({
          index: 0,
          routes: [{ 
            name: JOURNALS.path,
            params: {
              journalPath: JOURNALS.viewJournalPathSuffix,
              journalName: data.journalEntry?.title
            }
          }],
        });
      }
    }, 1500);
  }

  handleScreenDismiss = () => {
    this.setState({confirmBox: false});
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: DASHBOARD.path }],
    });
  };

  handleIncompleteScreenDismissValidation = () => {
    if (
      this.state.quickNote.length > 0 ||
      Object.keys(this.state.files).length > 0 ||
      this.state.quickNoteJournalName.length > 0 ||
      this.state.date !== initialDate
    ) {
      // this.setState({confirmBox: true});
      return true;
    } else {
      return false;
    }
  };

  checkOnlyChangeDateTimeOrNot = () => {
    if(
      (this.state.quickNote.length > 0 ||
      Object.keys(this.state.files).length > 0
      )
      ){
        return false;
      }else if(this.state.date !== initialDate || this.state.quickNoteJournalName.length > 0) {
        return true;
      }
  }

  handleOnPressBackButton = () => {
    if (!this.handleIncompleteScreenDismissValidation()) {
      this.handleScreenDismiss();
    } else {
      this.setState({confirmBox: true});
    }
  };

  handleOnPressAddAttachmentButton = () => {
    this.setState({
      visibleEntryModal: true,
      selected: 3,
    });
  };

  handleOnPressAttachmentRemoveButton = () => {
    this.setState({
      visibleEntryModal: false,
    });
  };

  handleOnPressSaveButton = () => {
    if (
      this.state.quickNote.length > 0 ||
      Object.keys(this.state.files).length > 0
    ) {
      this.handleCreateNewRoutine();
    } else {
      console.log(
        'WEB_AddQuickNoteScreen handleOnPressSaveButton | minimum validation failure',
      );
      // SIRI::TODO // Show validation warning alert
    }
  };

  customTouchableComponentWeb = (onPress) => {
    return (
      <TouchableOpacity onPress={onPress}>
        {this.customTouchableComponent()}
      </TouchableOpacity>
    );

  }

  customTouchableComponent = () => {
    const {date} = this.state;
    return (
      <View style={[styles.bgWhite]}>
        <View
          style={[
            styles.textField,
            styles.textFieldDateSm,
            styles.noRad,
            styles.dateSmPd,
          ]}>
          <View style={styles.dateTimeValue}>
            <Text
              style={[styles.textPrimeSm]}
              allowFontScaling={false}>
              {date ? moment(date).format("D MMM 'YY h:mm A") : ''}
            </Text>
          </View>
        </View>
        <View
          style={[styles.imgViewSm, styles.imgViewcalSm, styles.imgViewLgRt]}
          pointerEvents={'none'}>
          <Image
            style={[styles.calIconExSm]}
            source={require('../../assets/icons/cal-dark.png')}
          />
        </View>
      </View>
    );
  };

  clearJournalName = () => {
    this.setState({quickNoteJournalName: '', journalName: true});
  };

  handleOnSubmitCancel = () => {
    this.setState({confirmBox: true});
  }

  render() {
    const { hasNotch, journalUserId, timeZoneWarning, timeZone } = this.props;
    const { windowHeight, windowWidth } = this.state;
    let btnHeight = Platform.OS === WEB ? 80 : 100;
    let scrollHeight = getScrollHeight(hasNotch, journalUserId, timeZoneWarning, timeZone, windowHeight, windowWidth, btnHeight);
    const {cameraOpened} = this.state;
    return (
      Platform.OS !== WEB && this.state.showCropper 
      ? <Cropper 
          image={this.state.image}
          close={this.closeCroper}
        />
      : <React.Fragment>
          {this.handleIncompleteScreenDismissValidation() ?
            (<MenuOverlayContainer onPressOpen={this.handleOnSubmitCancel} />) : null
        }
        <AddQuickNote 
          files={this.state.files}
          images={this.state.images}
          quickNote={this.state.quickNote}
          quickNoteJournalName={this.state.quickNoteJournalName}
          date={this.state.date}
          onConfirm={this.onChangeDate}
          onChangeQuickNote={this.onChangeQuickNote}
          onChangeJournalName={this.onChangeJournalName}
          onChangeFileUpload={this.onChangeFileUpload}
          handleDeleteAttachment={this.handleDeleteAttachment}
          uploadError={this.state.uploadError}
          uploadErrorMesage={this.state.uploadErrorMesage}
          visibleEntryModal={this.state.visibleEntryModal}
          handleOnPressBackButton={this.handleOnPressBackButton}
          handleOnPressAddAttachmentButton={
            this.handleOnPressAddAttachmentButton
          }
          handleOnPressAttachmentRemoveButton={
            this.handleOnPressAttachmentRemoveButton
          }
          handleOnPressSaveButton={this.handleOnPressSaveButton}
          onRequestClose={() => {
            this.setState({visibleEntryModal: false});
          }}
          onBackdropPress={() => this.setState({visibleEntryModal: false})}
          onPressClose={() => {
            this.setState({visibleEntryModal: false});
          }}
          onPressTakePhoto={() => {
            Platform.OS === WEB
              ? this.setState({
                  visibleImageTakePhoto: true,
                  visibleEntryModal: false,
                })
              : this.takePhoto()
          }}
          selected={this.state.selected}
          visibleImageTakePhoto={this.state.visibleImageTakePhoto}
          onRequestCloseImg={() =>
            this.setState({
              visibleImageTakePhoto: false,
            })
          }
          onBackdropPressImg={() =>
            this.setState({
              visibleImageTakePhoto: false,
            })
          }
          close={() => {
            this.setState({visibleImageTakePhoto: false});
          }}
          cropSuccess={() => {
            this.setState({visibleImageTakePhoto: false});
          }}
          showSpinner={this.state.showSpinner}
          scrollHeight={scrollHeight}
          visibleUploadTypeModal={this.state.visibleUploadTypeModal}
          onRequestCloseUploadTypeModal={() => {
            this.setState({visibleUploadTypeModal: false});
          }}
          onBackdropPressUploadTypeModal={() =>
            this.setState({visibleUploadTypeModal: false})
          }
          onPressCancel={() => {
            this.setState({visibleUploadTypeModal: false});
          }}
          selectDocumentType={this.selectDocumentType}
          index={JOURNALS.index}
          navigation={this.props.navigation}
          route={this.props.route}
          btnHeight={btnHeight}
          updateSize={height => this.setState({height: height})}
          height={this.state.height}
          customTouchableComponent={Platform.OS === WEB
            ? this.customTouchableComponentWeb
            : this.customTouchableComponent
          }
          clearJournalName={this.clearJournalName}
        />
        {this.state.confirmBox &&
        <SavingConfirmation
          visibleModal={this.state.confirmBox}
          alertTitle={qnoteTitle}
          alertBody={alertBody}
          handleCancel={() => this.handleScreenDismiss()}
          handleConfirm={() => {
            this.setState({confirmBox: false}, () => {
              this.handleOnPressSaveButton();
            });
          }}
          confirmWait={this.state.showSpinner}
          disabled={this.state.showSpinner || this.checkOnlyChangeDateTimeOrNot()}
          onBackdropPress={() => this.setState({confirmBox: false})}
        />
        }
        {this.state.successModalVisible &&
        <SuccessBox
          visibleModal={this.state.successModalVisible}
          messageTitle="Success!"
          messageTitleStyle={[styles.courseTitle, styles.textPrimeCom]}
          message="A quick note entry was added."
        />
        }
        {Platform.OS !== WEB && cameraOpened && (
          <Camera
            visible={cameraOpened}
            onClose={() => this.setState({cameraOpened: false})}
            windowHeight={this.props.windowHeight}
            windowWidth={this.props.windowWidth}
            getImage={this.onCaptureImage}
            notchHeight={hasNotch ? 50 : 0}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    files: state.imagesDocuments.files,
    journalUserId: state.uistate.journalUserId,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    applicationSettingsDigest: state.routines.applicationSettingsDigest,
    user: state.routines.user,
    userSubscriptions: state.routines.userSubscription,
    hasNotch: state.uistate.hasNotch,
    timeZone: state.routines?.user?.timezone,
    timeZoneWarning: state.uistate.timeZoneWarning,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpload: (
      artifactId,
      formData,
      callBack,
      reload,
      journalUserId,
      fileSize,
    ) =>
      dispatch(
        actions.uploadAttachmen(
          artifactId,
          formData,
          callBack,
          reload,
          journalUserId,
          fileSize,
        ),
      ),
    onSaveJournalEntry: (journalEntry, callBack, journalUserId) =>
      dispatch(actions.saveJournalEntry(journalEntry, callBack, journalUserId)),
    clearFilesToAttach: () => dispatch(actions.clearFilesToAttach()),
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    onSetSelectedJournalEntry: selectedId =>
      dispatch(actions.setSelectedJournalEntry(selectedId)),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    showHideStorageBanner: val => dispatch(actions.showHideStorageBanner(val)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddQuickNoteScreen);
