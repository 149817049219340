import React, {Component} from 'react';
import {View, Text} from 'react-native';
import styles from '../../../../Css';
import Collapsible from '../../../Collapsible/Collapsible';
import * as FieldDetails from '../../../TextInput/fieldDetails';
import TextBox from '../../../TextInput/textBox';

class Notes extends Component {
  constructor(props) {
    super(props);
    let {entry} = this.props;
    if (entry !== null) {
      entry = entry.trim();
    }
    this.state = {
      visibleNotes: entry === null || entry === '' ? false : true,
    };
  }

  render() {
    const {entry, isQuickNote} = this.props;
    return (
      <Collapsible
        title="Notes"
        contentVisibility={this.state.visibleNotes}
        handleOnPressCollapsible={() => {
          this.setState({
            visibleNotes: !this.state.visibleNotes,
          });
        }}
        disabled={entry !== '' && entry !== null ? false : true}
        style={[styles.collapseTitle]}
        customTitleStyle={true}
        topBorder={true}>
        {entry !== '' && entry !== null && !isQuickNote && (
          <View
            style={[styles.collapseContent, styles.colPd, styles.bgThinGrey]}>
            <View style={styles.fieldColStretch}>
              <TextBox fieldBg={FieldDetails.BGGREY} fieldValue={entry} />
            </View>
          </View>
        )}

        {entry !== '' && entry !== null && isQuickNote && (
          <View style={[styles.colPd, styles.bgThinGrey, styles.comMgBotRg]}>
            <View style={styles.noteDisplayArea}>
              <View style={styles.noteTextArea}>
                <Text style={styles.textPrime} allowFontScaling={false}>
                  {entry}
                </Text>
              </View>
            </View>
          </View>
        )}
      </Collapsible>
    );
  }
}

export default (Notes);
