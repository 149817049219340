import React, { Component } from 'react';
import {
  Text,
  View,
  Image,
  TextInput,
  TouchableOpacity,
  Platform,
} from 'react-native';
import styles from '../../Css';
import stylesResponsive from '../../CssResponsive';
import AutoCompleteList from './AutoCompleteList';
import ActivityIndicator from '../../components/ActivityIndicator/activityIndicator';
import * as axiosCommon from '../../axios/axios-common';
import debounce from './vendor/throttle-debounce/debounce';
// import ToolTip from '../../components/ToolTip';
import FieldTitle from '../../components/TextInput/fieldTitle';
import { autoSuggestionListType } from '../../Constant/index';
import NestedScroll from '../NestedScroll/nestedScroll';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { ANDROID, WEB } from '../../util/platformWindowConfig';
class AutoCompleteSuggestion extends Component {
  constructor(props) {
    super(props);
    const { currentInput, remortSearch } = props;
    this.state = {
      textFieldWithTitle: props.textFieldWithTitle,
      textFieldTitle: props.textFieldTitle,
      selectedInput: props.selectedInput,
      textFieldAddCustomBtn: false,
      dataLoading: false,
      showList:
        props.autoDisplayUserList && props.type && props.type !== autoSuggestionListType.health_data_uom
          ? this.autoFocusDropdown(props.type).length > 0
            ? true
            : false
          : false,
      inputText: currentInput ? currentInput : null,
      dataListFilter: [],
      dataUserListFilter:
        // props.medication && props.medication === 'med'
        //   ? this.autoFocusDropdown()
        //   : [],
        props.autoDisplayUserList && props.type
          ? this.autoFocusDropdown(props.type)
          : [],
      remortSearch: remortSearch ? remortSearch : false,
      focused: props.autoFocus,
      uniqueId: Date.now()
    };
    this.layout = null;
    this.textRef = React.createRef()
  }

  componentDidMount() {
    this.refUpdate();
  }

  autoFocusDropdown = (type) => {
    let listFilter = [];

    switch (type) {
      case autoSuggestionListType.medication:
        this.props.userTerms.filter(eachTerm => {
          if (eachTerm && eachTerm.data.treatmentType !== 5 && eachTerm.data.name !== null) {
            listFilter.push(eachTerm);
          }
        })
        return listFilter;
      case autoSuggestionListType.activity:
        this.props.userTerms.filter(eachTerm => {
          if (eachTerm && eachTerm.data.treatmentType === 5 && eachTerm.data.name !== null) {
            listFilter.push(eachTerm);
          }
        })
        return listFilter;
      case autoSuggestionListType.symptom:
      case autoSuggestionListType.health_data:
      case autoSuggestionListType.health_data_uom:
        this.props.userTerms.filter(eachTerm => {
          if (eachTerm && eachTerm.data.name !== null) {
            listFilter.push(eachTerm);
          }
        })
        return listFilter;
      default:
        return listFilter;
    }
  };

  static getDerivedStateFromProps(props, state) {
    const { type, currentInput, handleFocus, listFocus, dropdownUniqueId } = props;
    let object = {};
    if (type === autoSuggestionListType?.tag && handleFocus && listFocus !== state.showList)
      handleFocus(state.showList)

    if (currentInput !== state.inputText) {
      object = {
        ...object,
        inputText: currentInput,
      };
    }
    if (dropdownUniqueId !== state.uniqueId) {
      object = {
        ...object,
        showList: false,
      };
    }
    if (Object.keys(object).length > 0) {
      return object
    }
    return null;
  }

  refUpdate = () => {
    const { getRef } = this.props;
    getRef && getRef(this);
  };

  turnOffList = () => {
    this.setState({
      showList: false,
    });
  }

  onChangeText = currentInput => {
    const { updateAutocompleteOpen, autoDisplayUserList, type } = this.props;
    if (updateAutocompleteOpen) {
      this.updateDropdownUniqueId();
      updateAutocompleteOpen(currentInput !== '' ? true : false);
    }
    if (currentInput !== '') {
      this.setState({
        inputText: currentInput,
        showList: true,
        dataLoading: true,
      });
    } else {
      this.setState({
        inputText: null,
        showList:
          // medication && medication === 'med' && this.autoFocusDropdown().length > 0
          //   ? true
          //   : false,
          autoDisplayUserList && type
            ? this.autoFocusDropdown(type).length > 0
              ? true
              : false
            : false,
        dataLoading: false,
        dataListFilter: []
      });
    }

    this.props.onChangeText(currentInput);
    const findMatch = (term1, term2) =>
      // term1.data?.drugIngredients?.[0]?.ingredient && term1.data?.drugIngredients?.[0]?.ingredient.toLowerCase().indexOf(term2.toLowerCase()) > -1;
      (term1.name && term1.name.toLowerCase().indexOf(term2.toLowerCase()) > -1) ||
      (term1.din && term1.din.toLowerCase().indexOf(term2.toLowerCase()) > -1) ||
      (term1.data?.drugIngredients?.[0]?.ingredient && term1.data?.drugIngredients?.[0]?.ingredient.toLowerCase().indexOf(term2.toLowerCase()) > -1);

    const inputIsEmpty = !!(currentInput.length <= 0);
    if (this.state.remortSearch) {
      //debounce(500, () => {
      let trigger = true;
      if (
        this.props.triggerLength &&
        currentInput.length < this.props.triggerLength
      ) {
        trigger = false;
      }

      const suggestionUserResults =
        this.props.userTerms && this.props.userTerms !== null
          ? this.props.userTerms.filter(eachTerm => {
            return findMatch(eachTerm, currentInput);
          })
          : [];

      // medication &&
      //   medication === 'med' &&
      autoDisplayUserList &&
        type &&
        suggestionUserResults &&
        suggestionUserResults.length > 0 &&
        suggestionUserResults.forEach(item => {
          item.currentInput = currentInput;
        });

      if (this.props.remortSearch && trigger) {
        axiosCommon.axiosGET(this.props.url + currentInput, res => {
          let suggestionResults = [];
          if (res.type === 'SUCCESS') {
            suggestionResults = this.state.inputText ? this.props.processData(res.responseDto?.data) : this.props.processData(null);
          } else {
            suggestionResults = this.props.processData(null);
          }

          let showSuggestionResults = true;
          suggestionResults.forEach(item => {
            if (
              this.props.triggerLength &&
              item.currentInput &&
              item.currentInput.length < this.props.triggerLength
            ) {
              showSuggestionResults = false;
            }
          });
          const suggestionResultsN =
            suggestionResults && suggestionResults !== null
              ? suggestionResults.filter(eachTerm => {
                if (eachTerm.din?.toLowerCase().includes(currentInput.toLowerCase()) ||
                  eachTerm.Ingredient?.toLowerCase().includes(currentInput.toLowerCase()) ||
                  eachTerm.name?.[0]?.toLowerCase().includes(currentInput.toLowerCase())
                ) {
                  return true
                } else {
                  return false;
                }
              })
              : [];
          this.setState({
            dataListFilter:
              inputIsEmpty || !showSuggestionResults ? [] : suggestionResultsN,
            // dataUserListFilter: inputIsEmpty ? [] : suggestionUserResults,
            dataUserListFilter: suggestionUserResults.length !== 0 ? suggestionUserResults : !this.state.inputText && this.props.autoDisplayUserList && this.props.type
              ? this.autoFocusDropdown(this.props.type)
              : [],
            dataLoading: false,
          });
        });
      } else if (this.props.remortSearch && !trigger) {
        this.setState({
          dataListFilter: [],
          dataUserListFilter: suggestionUserResults
            ? suggestionUserResults
            : [],
          dataLoading: false,
        });
      } else {
        this.setState({
          dataListFilter: [],
          dataUserListFilter: [],
          dataLoading: false,
        });
      }
      // })();
    } else {
      debounce(500, () => {
        let trigger = true;
        if (
          this.props.triggerLength &&
          currentInput.length < this.props.triggerLength
        ) {
          trigger = false;
        }
        if (trigger) {
          const suggestionResults =
            this.props.terms && this.props.terms !== null
              ? this.props.terms.filter(eachTerm => {
                return findMatch(eachTerm, currentInput);
              })
              : '';

          const suggestionOtherResults =
            this.props.terms && this.props.terms !== null
              ? this.props.terms.filter(eachTerm => {
                return !findMatch(eachTerm, currentInput);
              })
              : '';

          let suggestionUserResults =
            this.props.userTerms && this.props.userTerms !== null
              ? this.props.userTerms.filter(eachTerm => {
                return findMatch(eachTerm, currentInput);
              })
              : '';


          if (this.props.typeAhead && this.props.typeAhead.length > 0) {
            const terms = [];
            const missedTerms = [];
            const hits = this.props.typeAhead.filter(eachTerm => {
              return findMatch(eachTerm, currentInput);
            });

            if (suggestionUserResults.length === 0) {
              hits.forEach(item => {
                this.props.userTerms.forEach(term => {
                  if (item.id === term.data.typeOfMeasurement) {
                    if (terms.filter(t => t.data.id === term.data.id).length === 0) {
                      terms.push({ ...term, bold: `(${term.data.symbol})` });
                    }
                  } else {
                    if (missedTerms.filter(t => t.data.id === term.data.id).length === 0) {
                      missedTerms.push(term);
                    }
                  }
                });
              });
              suggestionUserResults = [...terms, ...missedTerms];
            }
          }

          this.setState({
            dataListFilter: inputIsEmpty ? [] : this.props.showOtherResults ? [...suggestionResults, ...suggestionOtherResults] : suggestionResults,
            dataUserListFilter: suggestionUserResults ? suggestionUserResults : [], //inputIsEmpty ? [] : suggestionUserResults,
            dataLoading: false,
          });
        } else {
          this.setState({
            dataListFilter: [],
            dataUserListFilter: [],
            dataLoading: false,
          });
        }
      })();
    }
  };

  onItemPress = (selectedItemData, type) => {
    this.props.onItemPress(selectedItemData, type);
    this.clear();
  };

  clear = () => {
    const { updateAutocompleteOpen, type } = this.props;
    if (updateAutocompleteOpen) {
      updateAutocompleteOpen(false);
    }
    if (Platform.OS !== WEB && type === autoSuggestionListType.health_data_uom && this.textRef) {
      this.textRef.current.blur();
    }

    this.setState({
      showList: false,
      dataListFilter: [],
      dataUserListFilter: [],
    });
  };

  onPressCustom = () => {
    this.props.onPressCustom(this.state.inputText, 'CUSTOM');
    this.clear();
  };

  hideList = () => {
    if (this.props.clearTextOnBlur) {
      this.props.onChangeText('');
    }
    this.setState({ showList: false });
  };

  onFocus = () => {
    if (this.state.showList) return;
    const { autoDisplayUserList, type } = this.props;
    this.setState({
      showList:
        // medication && medication === 'med' && this.autoFocusDropdown().length > 0
        //   ? true
        //   : false,
        autoDisplayUserList && type
          ? this.autoFocusDropdown(type).length > 0
            ? true
            : false
          : false,
      dataListFilter: [],
      dataUserListFilter:
        //medication && medication === 'med' ? this.autoFocusDropdown() : [],
        autoDisplayUserList && type
          ? this.autoFocusDropdown(type)
          : [],
    });
  };

  layoutChange = (e) => {
    this.layout = e.nativeEvent.layout;
    this.forceUpdate()
  }

  handleOnFocus = () => {
    const { autoDisplayUserList, type } = this.props;
    this.updateDropdownUniqueId();
    this.setState({ focused: true });
    if (autoDisplayUserList && type) {
      this.onFocus()
    }
  }

  updateDropdownUniqueId = () => {
    const { dropdownUniqueId, setDropdownUniqueId } = this.props;
    if (dropdownUniqueId !== this.state.uniqueId) {
      const uId = Date.now();
      setDropdownUniqueId(uId);
      this.setState({ uniqueId: uId });
    }
  }

  render() {
    const { styles: stylesRes, ids } = stylesResponsive.getProcessedStyles();
    const {
      updateTermList,
      type,
      currentInput,
      disbleSelectedValues,
    } = this.props;
    return (
      <View style={[styles.autoCompleteWrapper]} onTouchStart={e => e.stopPropagation()}>
        {/* NEW CR STARTS */}
        <View style={[styles.autoCompleteField]} onLayout={this.layoutChange}>
          <View
            style={[
              this.props.fieldSm
                ? styles.textInputSmWrapper
                : styles.textInputWrapper,
              styles.postRelative,
            ]}>
            <FieldTitle
              fieldAlign={this.props.fieldAlign}
              fieldBg={this.props.fieldBg}
              title={this.props.textFieldTitle}
              fieldSm={this.props.fieldSm}
              fieldLt={this.props.fieldLt}
              requirdStar={this.props.requirdStar}
              darkTxt={this.props.darkTxt}
            />
            {/* 
            {this.props.toolTipButton && (
              <View
                style={[
                  styles.questComCircleView,
                  styles.questComCircleMg,
                  styles.zIndexCircle,
                ]}>
                <ToolTip
                  id={this.props.toolTipId}
                  text={this.props.toolTipContent}
                  placement={this.props.placement}
                  disabledScroll={this.props.disabledScroll}
                />
              </View>
            )} */}

            <View style={[styles.autoCompleteBtnView]}>
              {/* {this.props.zoomIcon && (!this.state.showList || (autoDisplayUserList && currentInput === '')) && ( */}
              {this.props.type !== autoSuggestionListType.health_data_uom && !Boolean(currentInput) && (
                <View style={[styles.btnActionTouch, styles.IconRtPadding]}>
                  <View style={[
                    styles.autoCompleteIconView,
                    type === autoSuggestionListType.health_data_uom && styles.mgRtZero
                  ]}>
                    <Image
                      style={styles.searchZoomIcon}
                      source={require('../../assets/icons/search-zoom.png')}
                    />
                  </View>
                </View>
              )}

              {/* Clear button section Start */}
              {/* {this.props.removeIcon && ((this.state.showList && !autoDisplayUserList) || (this.state.showList && autoDisplayUserList && currentInput !== ''))  && ( */}
              {Boolean(this.props.removeIcon) && this.state.showList && currentInput !== '' && currentInput !== null && (
                <TouchableOpacity
                  style={[styles.btnActionTouch, styles.IconRtPadding]}
                  onPress={() => {
                    if (updateTermList) {
                      updateTermList();
                    }
                    if (this.props.processData) {
                      this.props.processData(null);
                    }

                    this.props.onChangeText('');
                    this.clear()
                  }}>
                  <View style={[
                    styles.autoCompleteIconView,
                    type === autoSuggestionListType.health_data_uom && styles.mgRtZero
                  ]}>
                    <Image
                      style={styles.removeIcon}
                      source={require('../../assets/icons/remove.png')}
                    />
                  </View>
                </TouchableOpacity>
              )}

              {/* Clear button section Start */}
              {!Boolean(this.props.removeIcon) && Boolean(this.state.showList) && (
                <TouchableOpacity
                  style={[
                    styles.btnActionTouch,
                    styles.IconRtPadding,
                    this.props.selectedInput && styles.noDis
                  ]}
                  onPress={() => {
                    if (updateTermList) {
                      updateTermList();
                    }
                    this.clear();
                    if (this.props.processData) {
                      this.props.processData(null);
                    }

                    this.props.onChangeText('');
                  }}>
                  <View style={[styles.autoCompleteIconView]}>
                    <Image
                      style={styles.autoCompleteClearIcon}
                      source={require('../../assets/icons/clear.png')}
                    />
                  </View>
                </TouchableOpacity>
              )}
              {/* Add custon button section Start */}
              {/* {this.state.textFieldAddCustomBtn && ( */}
              {/* <View style={[styles.autoCompleteAdd]}>
              <TouchableOpacity>
                <View style={[styles.addCustomBtn]}>
                  <Text style={[styles.textMicSmBold, styles.addCustomBtnText]}>
                    Confirm
                  </Text>
                </View>
              </TouchableOpacity>
            </View> */}
              {/* )} */}
              {/* Add custon button section End */}
            </View>
            <TextInput
              // style={[
              //   styles.textField,
              //   this.props.fieldBg === "white"
              //     ? styles.bgWhite
              //     : this.props.fieldBg === "grey"
              //     ? styles.bgThinGrey
              //     : styles.bgTransparent,
              //   this.state.textFieldWithTitle
              //     ? styles.textFieldRg
              //     : styles.textFieldSm,
              //   this.state.textFieldAddCustomBtn
              //     ? styles.textFieldAutoCompleteLg
              //     : styles.textFieldAutoCompleteSm,
              //   !this.props.validateName
              //     ? styles.validColor
              //     : styles.inValidColor,
              // ]}
              style={[
                this.props.fieldSm
                  ? styles.textInputFieldSm
                  : styles.textInputField,

                // this.props.fieldInfo
                //   ? styles.frameInfoPd
                //   : styles.frameInfoPdCom,
                this.props.fieldBg === 'grey'
                  ? styles.bgThinGrey
                  : this.props.fieldBg === 'blue'
                    ? styles.bgBlueExLight
                    : this.props.fieldBg === 'transparent'
                      ? styles.bgTransparent
                      : styles.bgWhite,
                this.props.fieldAlign === 'center'
                  ? styles.textAlignCenter
                  : styles.textAlignLeft,
                !this.props.validateName
                  ? styles.validColor
                  : styles.inValidColor,
                this.state.showList ||
                  this.props.zoomIcon ||
                  // this.props.toolTipButton ||
                  this.props.fieldInfo
                  ? styles.txtInputRtSp
                  : null,
                this.props.customAutoSuggestStyle,
                this.props.selectedInput && styles.selectedBorderClr,
              ]}
              underlineColorAndroid="transparent"
              value={this.state.inputText ? this.state.inputText : ''}
              onChangeText={this.onChangeText}
              // onBlur={() => {
              //   if (Platform.OS === "web") {
              //     setTimeout(() => {
              //       this.clear();
              //       if (this.props.processData) {
              //         this.props.processData(null);
              //       }
              //       /// this.props.onChangeText("");
              //     }, 500);
              //   }
              // }}
              autoFocus={this.props.autoFocus}
              editable={this.props.editable}
              placeholder={this.props.placeholder}
              //onBlur={() => setTimeout(() => this.hideList(), 500)}
              onBlur={() => {
                if (Platform.OS === WEB && this.props.onBlur) {
                  this.props.onBlur();
                }
                this.setState({ focused: false })
              }}
              onFocus={this.handleOnFocus}
              allowFontScaling={false}
              maxLength={this.props.maxLength}
              ref={this.textRef}
              selection={Platform.OS !== ANDROID || this.state.focused || type === autoSuggestionListType.health_data_uom ? undefined : { start: 0, end: 0 }}
            />
          </View>
        </View>

        {/* Start of AutoSuggestionList */}
        {Boolean(this.state.showList) && (
          <View
            onLayout={this.layoutChange}
            style={[
              stylesRes.autoCompleteSuggestionDataCom,
              this.props.zIndex && { zIndex: this.props.zIndex },
              this.props.contentRightAlign
                ? stylesRes.rightAlignStyle
                : stylesRes.leftAlignStyle,
              stylesRes.autoCompleteBgShadowCr,
              stylesRes.autoCompleteBgWhite,
              stylesRes.autoCompleteBorder,
              this.props.customWidth
                ? stylesRes.medicationsDrpDownStyle
                : stylesRes.heathUnitMeasureSm,
              this.state.textFieldWithTitle
                ? stylesRes.autoCompleteDataMgLgCr
                : stylesRes.autoCompleteDataMgSmCr,
            ]}
            dataSet={{ media: this.props.customWidth ? ids.medicationsDrpDownStyle : ids.heathUnitMeasureSm }}>
            {/* Custom */}
            {Boolean(this.props.showCustomSection) && (
              <View style={[styles.autoCompleteSection]}>
                <View
                  style={[
                    styles.autoCompleteTitleBlockComCr,
                    styles.navListLiDefault,
                    styles.borderBottomColor
                  ]}>
                  <TouchableOpacity
                    onPress={this.onPressCustom}
                    disabled={!this.state.inputText || (this.state.inputText && this.state.inputText.trim() === '')}>
                    <View style={[styles.autoCompleteItemComRowCr]}>
                      {/* IMAGE */}
                      <View style={[styles.autoCompletePlusIconPd]}>
                        <Image
                          style={styles.autoCompletePlusIcon}
                          source={require('../../assets/icons/plus-sm.png')}
                        />
                      </View>

                      {/* TEXT */}
                      <View style={[styles.autoCompleteTextRw]} >
                        <View>
                          <Text style={[styles.autoCompleteCustomText]} allowFontScaling={false}>
                            Create Custom
                          </Text>
                        </View>

                        <View style={styles.autoCompleteTextLgWrap}>
                          <View style={styles.selectedItemRoundIconCr} />
                          {/* <View style={[styles.selectedItemData]}> */}
                          <Text style={[styles.autoCompleteCustomText, styles.autoCompleteTextInput, this.layout && { width: parseInt(this.layout.width) - 150 }]} numberOfLines={1} allowFontScaling={false} >
                            {this.state.inputText ? `"` + this.state.inputText + `"` : ''}
                          </Text>
                          {/* </View> */}
                        </View>
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            )}

            {/* AUTOSUGGEST */}
            {Platform.OS === WEB ? (
              <React.Fragment>
                {this.state.dataLoading ? (
                  <View style={[styles.autoCompleteSection]}>
                    <ActivityIndicator size="small" />
                  </View>
                ) : (
                  <React.Fragment>
                    {((this.state.dataUserListFilter &&
                      this.state.dataUserListFilter.length > 0) || (this.state.dataListFilter &&
                        this.state.dataListFilter.length > 0)) && (
                        <View
                          style={[
                            type === autoSuggestionListType.health_data_uom
                              ? stylesRes.autoCompleteUnitScroll
                              : stylesRes.autoCompleteScrollCr,
                            styles.autoCompleteSection,
                            styles.addCustomborderCr,
                            styles.autoCompleteListHeight
                          ]}
                        >

                          <View style={
                            type === autoSuggestionListType.health_data_uom
                              ? styles.autoCompleteUnitHeight
                              : styles.autoCompleteListHeight
                          }>
                            {/* FROM USER LIST */}
                            {this.props.userTerms && this.props.userTerms !== null && (
                              <View>
                                <View style={[styles.autoCompleteContList]}>
                                  <View
                                    style={[
                                      // styles.autoCompleteListHeight,
                                      styles.autoCompleteScrollHiddenCr,
                                      // styles.autoCompleteScrollWrap,
                                      this.props.type !== autoSuggestionListType.health_data_uom && styles.bgBlueAutoSuggest,
                                    ]}>
                                    <AutoCompleteList
                                      dataListFilter={this.state.dataUserListFilter}
                                      onItemPress={val =>
                                        this.onItemPress(val, 'USER')
                                      }
                                      selectedValues={this.props.selectedValues}
                                      onUserItems={'user'}
                                      autoSuggestionList={true}
                                      type={this.props.type}
                                      keyword={this.props.currentInput || ""}
                                      removeSelectedIcon={this.props.removeSelectedIcon}
                                      selectedValue={this.props.selectedValue}
                                      disbleSelectedValues={disbleSelectedValues}
                                    />
                                  </View>
                                </View>
                              </View>
                            )}
                            {/* FROM SYSTEM LIST */}
                            <View>
                              <View style={[styles.autoCompleteContList]}>
                                <View
                                  style={[
                                    // styles.autoCompleteListHeight,
                                    styles.autoCompleteScrollHiddenCr,
                                  ]}>
                                  <AutoCompleteList
                                    dataListFilter={this.state.dataListFilter}
                                    onItemPress={val => this.onItemPress(val, 'SYSTEM')}
                                    selectedValues={this.props.selectedValues}
                                    autoSuggestionList={true}
                                    type={this.props.type}
                                    keyword={this.props.currentInput || ""}
                                    disbleSelectedValues={disbleSelectedValues}
                                  />
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {this.state.dataLoading ? (
                  <View style={[styles.autoCompleteSection]}>
                    <ActivityIndicator size="small" />
                  </View>
                ) : (
                  <React.Fragment>
                    {((this.state.dataUserListFilter &&
                      this.state.dataUserListFilter.length > 0) || (this.state.dataListFilter &&
                        this.state.dataListFilter.length > 0)) && (

                        <View
                          style={
                            type === autoSuggestionListType.health_data_uom
                              ? styles.autosuggestunititemhg
                              : styles.autosuggestlistitemhg
                          }>

                          <NestedScroll>

                            <View style={this.props.type !== autoSuggestionListType.health_data_uom && styles.bgBlueAutoSuggest}>
                              {Boolean(this.props.userTerms) && this.props.userTerms !== null && (
                                <AutoCompleteList
                                  dataListFilter={this.state.dataUserListFilter}
                                  onItemPress={val =>
                                    this.onItemPress(val, 'USER')
                                  }
                                  selectedValues={this.props.selectedValues}
                                  onUserItems={'user'}
                                  autoSuggestionList={true}
                                  type={this.props.type}
                                  keyword={this.props.currentInput || ""}
                                  removeSelectedIcon={this.props.removeSelectedIcon}
                                  selectedValue={this.props.selectedValue}
                                  disbleSelectedValues={disbleSelectedValues}
                                />
                              )}
                            </View>

                            <AutoCompleteList
                              dataListFilter={this.state.dataListFilter}
                              onItemPress={val => this.onItemPress(val, 'SYSTEM')}
                              selectedValues={this.props.selectedValues}
                              autoSuggestionList={true}
                              type={this.props.type}
                              keyword={this.props.currentInput || ""}
                              disbleSelectedValues={disbleSelectedValues}
                            />

                          </NestedScroll>

                        </View>

                      )}
                  </React.Fragment>
                )}
              </React.Fragment>

            )
            }

          </View>
        )}
        {/* NEW CR ENDS */}

        {/* Suggestion Starting View Start */}
      </View>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dropdownUniqueId: state.uistate.dropdownUniqueId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDropdownUniqueId: (id) => dispatch(actions.setDropdownUniqueId(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoCompleteSuggestion);
