import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';
import {tabs, itemTypes} from '../../Constant';

const initialState = {
  selectedUser: null,
  conditions: [],
  treatments: [],
  symptoms: [],
  metrics: [],
  newRoutine: {},
  conditionName: null,
  therapieName: null,
  symptomName: null,
  metricsName: null,
  isAddNewCustomSymptom: null,
  activeTab: tabs.profile,
  activeTherapieTab: 0,
  itemList: {},
  type: null,
  routineWizardSaveDisable: {
    treatment: false,
    symptom: false,
    healthData: false
  }
};

const updateUser = (state, action) => {
  return updateObject(state, {selectedUser: action.selectedUser});
};

const updateCondition = (state, action) => {
  return updateObject(state, {conditions: action.conditions});
};

const updateTreatment = (state, action) => {
  return updateObject(state, {treatments: action.treatments});
};

const updateSymptom = (state, action) => {
  return updateObject(state, {symptoms: action.symptoms});
};

const updateMetric = (state, action) => {
  return updateObject(state, {metrics: action.metrics});
};

const updateRoutine = (state, action) => {
  return updateObject(state, {newRoutine: action.newRoutine});
};

const updateConditionName = (state, action) => {
  return updateObject(state, {conditionName: action.conditionName});
};
const updateTherapieName = (state, action) => {
  return updateObject(state, {therapieName: action.therapieName, activeTherapieTab: action.activeTherapieTab});
};
const updateSymptomName = (state, action) => {
  return updateObject(state, {symptomName: action.symptomName});
};
const updateMetricsName = (state, action) => {
  return updateObject(state, {metricsName: action.metricsName});
};
const updateIsAddNewCustomSymptom = (state, action) => {
  return updateObject(state, {
    isAddNewCustomSymptom: action.isAddNewCustomSymptom,
  });
};

const setActiveTab = (state, action) => {
  return updateObject(state, {
    activeTab: action.activeTab,
  });
};

const updateDataAddedFromRW = (state, action) => {
  return updateObject(state, {
    itemList: action.itemList,
  });
};

const updateRoutineWizardSaveDisable = (state, action) => {
  const routineWizardSaveDisable = {...state.routineWizardSaveDisable};
  switch (action.isFrom) {
    case itemTypes.treatment:
      routineWizardSaveDisable.treatment = action.routineWizardSaveDisable;
      break;
    case itemTypes.symptom:
      routineWizardSaveDisable.symptom = action.routineWizardSaveDisable;
      break;
    case itemTypes.health_data:
      routineWizardSaveDisable.healthData = action.routineWizardSaveDisable;
      break;
    default:
      break;
  }
  return updateObject(state, {
    routineWizardSaveDisable: routineWizardSaveDisable,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER:
      return updateUser(state, action);
    case actionTypes.UPDATE_CONDITION:
      return updateCondition(state, action);
    case actionTypes.UPDATE_TREATMENT:
      return updateTreatment(state, action);
    case actionTypes.UPDATE_SYMPTOM:
      return updateSymptom(state, action);
    case actionTypes.UPDATE_METRIC:
      return updateMetric(state, action);
    case actionTypes.UPDATE_ROUTINE:
      return updateRoutine(state, action);
    case actionTypes.UPDATE_CONDITION_NAME:
      return updateConditionName(state, action);
    case actionTypes.UPDATE_THERAPIE_NAME:
      return updateTherapieName(state, action);
    case actionTypes.UPDATE_SYMPTOM_NAME:
      return updateSymptomName(state, action);
    case actionTypes.UPDATE_METRICS_NAME:
      return updateMetricsName(state, action);
    case actionTypes.RESET_SETUP_STATE:
      return initialState;
    case actionTypes.UPDATE_ADD_NEW_CUSTOM_SYMPTOM:
      return updateIsAddNewCustomSymptom(state, action);
    case actionTypes.SET_SETUP_ACTIVE_TAB:
      return setActiveTab(state, action);
    case actionTypes.UPDATE_DATA_ADDED_FROM_RW:
      return updateDataAddedFromRW(state, action);
    case actionTypes.UPDATE_ROUTINE_WIZARD_SAVE_DISABLE:
      return updateRoutineWizardSaveDisable(state, action);
    default:
      return state;
  }
};
export default reducer;
