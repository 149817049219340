import React from "react";
import { View, Text } from "react-native";
import styles from "../../Css";

const contentDetails = ({ imageElement, title, description }) => (
  <>
    <View style={[styles.comSecRow, styles.flexRow, styles.flexAiFs]}>
      <View style={[styles.oneThird, styles.flexAiCt]}>
        <View style={[styles.comImgSec]}>
          {imageElement}
        </View>
      </View>
      <View style={[styles.twoThirds, styles.pdTopSm]}>
        <View style={[styles.comTextSec, styles.comPdLt]}>
          <Text style={[styles.textPrimeBold]} allowFontScaling= {false}>
            {title}
          </Text>
          <Text style={[styles.textPrimeSm]} allowFontScaling= {false}>
            {description}
          </Text>
        </View>
      </View>
    </View>
  </>
);

export default (contentDetails);
