import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import {View, Keyboard} from 'react-native';
import styles from '../../../Css';
import * as FieldDetails from '../../TextInput/fieldDetails';
import AutoCompleteSuggestion from '../../AutoComplete/AutoCompleteSuggestion';
import {autoSuggestionListType, chartTypes} from '../../../Constant/index';
import { getSelectedItems, getSuggestedTeams, getUnits } from '../../../util/commonUiLogic';
class StepFourAddMetric extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.metricsName ? props.metricsName : '',
      terms: getSuggestedTeams(this.props.systemDefinedMetrics, this.props.userDefinedMetrics),
      userTerms: getSuggestedTeams(this.props.userDefinedMetrics, this.props.systemDefinedMetrics, true),
      selectedMetrics: getSelectedItems(props.metrics),
      untisArray: []
    };
  }

  componentDidMount() {
    this.props.getSystemUnitOfMeasure(
      (res, data) => {
        if (res) {
          this.setState({untisArray: data});
        }
      }
    );
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({selectedMetrics: getSelectedItems(props.metrics)});
  }

  onChangeTextName = currentInput => {
    this.setState({
      name: currentInput,
    });
    this.props.onUpdateMetricsName(currentInput);
  };

  onItemPress = (selection, type) => {
    Keyboard.dismiss();
    let selectedValue = selection.data;

    let metrics = [];
    let filteredItems = [];
    if (this.props.metrics) {
      metrics = [...this.props.metrics];
      filteredItems = metrics.filter(
        res => res.metric.name === selectedValue.name,
      );
    }
    if (filteredItems.length === 0) {
      const name = selection.name;
      const units = getUnits(this.state.untisArray, selectedValue.typeOfMeasurement);
      let unit = units?.[0];
      if (type === 'USER') {
        let uomId = this.props.userDefinedMetricUnitOfMeasures.filter(res => res.metricId === selectedValue.id)?.[0]?.uomId;
        if (units && uomId) {
          unit = units.filter(res => res.id === uomId)?.[0];
        }
      }
      metrics.push({
        metric: {...selectedValue, addedFromRW: type === 'SYSTEM' ? true : false},
        isActive: true,
        id: -1,
        name: name,
        createdOn: null,
        modifiedOn: null,
        artifactId: null,
        description: selectedValue.description,
        typeOfMeasurement: selectedValue.typeOfMeasurement,
        metricGroup: selectedValue.metricGroup,
        displayProperties: {
          color: 'rgba(113, 168, 220, 1)',
          style: chartTypes.dash,
          scaleMin: null,
          scaleMax: null,
        },
        uomId: unit?.id,
        unitName: unit?.symbol,
        type: null
      });
      this.props.updateMetric(metrics, () => {
        this.setState({
          name: '',
        });
        this.props.updateInActive(false);
      });
    }
    this.setState({
      name: '',
    });
    this.props.onUpdateMetricsName('');
  };

  validateMetric = () => {
    let valid = true;
    if (this.props.metrics && this.props.metrics.length !== 0) {
      valid = this.props.metrics.some(
        res => !(res.metric.name === this.state.name),
      );
    }
    return valid;
  };
  clearInputAndTerms = () => {
    this.setState({
      name: '',
    });
  };

  onAddNewPress = selection => {
    Keyboard.dismiss();
    let metrics = [];
    let filteredItems = [];
    if (this.props.metrics) {
      metrics = [...this.props.metrics];
      filteredItems = metrics.filter(res => res.metric.name === selection);
    }
    if (filteredItems.length === 0) {
      const name = selection;
      const units = getUnits(this.state.untisArray, null);
      metrics.push({
        metric: {
          name: name,
          addedFromRW: true
        },
        isActive: true,
        id: -1,
        name: name,
        createdOn: null,
        modifiedOn: null,
        artifactId: null,
        description: '',
        typeOfMeasurement: null,
        metricGroup: 1,
        displayProperties: {
          color: 'rgba(113, 168, 220, 1)',
          style: chartTypes.dash,
          scaleMin: null,
          scaleMax: null,
        },
        uomId: units[0]?.id,
        unitName: units[0]?.symbol,
        type: 'custom'
      });
      this.props.updateMetric(metrics, () => {
        this.setState({
          name: '',
        });
        this.props.updateInActive(false);
      });
    }
    this.setState({
      name: '',
    });
    this.props.onUpdateMetricsName('');
  };

  render() {
    return (
      <View style={[styles.fieldRow, styles.zIndexAutoCompleteSpec]}>
        <View style={[styles.fieldRowWrapper]}>
          <View style={styles.fieldColStretch}>
            <AutoCompleteSuggestion
              textFieldWithTitle={true}
              textFieldTitle="Enter Health Data"
              remortSearch={false}
              triggerLength={1}
              onChangeText={this.onChangeTextName}
              onItemPress={this.onItemPress}
              currentInput={this.state.name}
              onPressCustom={this.onAddNewPress}
              showCustomSection={true}
              autoFocus={true}
              terms={this.state.terms}
              userTerms={this.state.userTerms}
              selectedValues={this.state.selectedMetrics}
              fieldBg={FieldDetails.BGWHITE}
              fieldLt={true}
              zoomIcon={true}
              removeIcon={true}
              //requirdStar={'*'}
              placeholder="ex: Alertness, Happiness"
              type={autoSuggestionListType.health_data}
              maxLength={50}
            />
          </View>
        </View>
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    metrics: state.setup.metrics,
    systemDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.systemDefinedMetrics
      : [],
    userDefinedMetrics: state.routines.metricsDigest
      ? state.routines.metricsDigest.userDefinedMetrics
      : [],
    metricsName: state.setup.metricsName,
    userDefinedMetricUnitOfMeasures:
      state.routines.metricsDigest.userDefinedMetricUnitOfMeasures,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateMetric: (addNewMetric, callBack) =>
      dispatch(actions.updateMetric(addNewMetric, callBack)),
    onUpdateMetricsName: name => dispatch(actions.updateMetricsName(name)),
    getSystemUnitOfMeasure: (callBack) =>
      dispatch(actions.getSystemUnitOfMeasure(callBack)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepFourAddMetric);
