import React, {Component, Fragment} from 'react';
import {View, Text} from 'react-native';
import MedicationsOrTherapiesItem from '../../components/MedicationsOrTherapies/MedicationsOrTherapiesItem/MedicationsOrTherapiesItem';
import styles from '../../Css';
import MedicationActivityItemCollapse from './MedicationActivityItemCollapse';

export const title = ['Active', 'Paused', 'Inactive'];
export const value = ['active', 'pause', 'inActive'];

class MedicationActivityCategoryItem extends Component {
  constructor(props) {
    super(props);
    const {medication} = props;
    this.state = {
      categorizedMedication: MedicationActivityCategoryItem.getAllItem(
        medication,
      ),
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    const {medication} = props;

    if (
      MedicationActivityCategoryItem.getAllItem(medication) !==
      state.categorizedMedication
    ) {
      return {
        categorizedMedication: MedicationActivityCategoryItem.getAllItem(
          medication,
        ),
      };
    }
    return null;
  };

  static getAllItem = medication => {
    let active = [];
    let pause = [];
    let inActive = [];

    medication &&
      medication.length > 0 &&
      medication.forEach(res => {
        if (res.isActive && !res.isPaused) {
          active.push(res);
        }
      });

    medication &&
      medication.length > 0 &&
      medication.forEach(res => {
        if (res.isActive && res.isPaused) {
          pause.push(res);
        }
      });

    medication &&
      medication.length > 0 &&
      medication.forEach(res => {
        if (!res.isActive && !res.isPaused) {
          inActive.push(res);
        }
      });
    return [{active: active}, {pause: pause}, {inActive: inActive}];
  };

  render() {
    const {categorizedMedication} = this.state;
    const {windowWidth, medicationsOrTherapiesSelectHandler, renderSearchContents, handleEditMedicationActivity} = this.props;
    return (
      <>
        {/* {categorizedMedication &&
          categorizedMedication.length > 0 &&
          categorizedMedication.map((data, index) => {
            countItem = 0;
            return (
              data[value[index]] &&
              data[value[index]].length > 0 && (
                <>
                  <View
                    style={[
                      styles.flexRow,
                      styles.flexAiCt,
                      styles.itemDividerFull,
                      this.props.windowWidth > 991
                        ? styles.itemDividerPdLtZero
                        : styles.itemDividerPdLt,
                    ]}>
                    <Text style={[styles.textPrimeMd, styles.textColorLight]} allowFontScaling={false}>
                      {title[index]}
                    </Text>
                    <View style={[styles.flexCom, styles.itemDividerWrap]}>
                      <View
                        style={[styles.itemDivider, styles.greyBorderThin]}
                      />
                    </View>
                  </View>
                  {data[value[index]].map(item => {
                    countItem++;
                    return (
                      <React.Fragment key={'MM' + item.id}>
                        <MedicationsOrTherapiesItem
                          treatment={item}
                          medicationsOrTherapiesSelectHandler={
                            this.props.medicationsOrTherapiesSelectHandler
                          }
                          key={'M' + item.id}
                          windowWidth={this.props.windowWidth}
                          borderNone={
                            countItem === data[value[index]].length
                              ? true
                              : false
                          }
                        />
                        {item.containKeyword && this.props.renderSearchContents(item)}
                      </React.Fragment>
                    );
                  })}
                </>
              )
            );
          })} */}
          {categorizedMedication &&
          categorizedMedication.length > 0 &&
          categorizedMedication.map((data, index) => (
            <Fragment key={"Treat_" + index}>
              <MedicationActivityItemCollapse
                data={data[value[index]]}
                windowWidth={windowWidth}
                medicationsOrTherapiesSelectHandler={medicationsOrTherapiesSelectHandler}
                renderSearchContents={renderSearchContents}
                collapseTitle={title[index]}
                handleEditMedicationActivity={handleEditMedicationActivity}
                searchKey={this.props.searchKey}
              />
            </Fragment>
          ))}
      </>
    );
  }
}

export default (MedicationActivityCategoryItem);
