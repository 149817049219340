import React from "react";
import { View, Text, Platform } from "react-native";
import styles from "../../Css";
import FieldTitle from "./fieldTitle";
import TimePicker from "../DateTimePicker/TimePicker";
import * as FontScaling from '../../components/Text/fontScaling';

const textTimePickerFrame = (props) => {
  return (
    <View
      style={[
        props.fieldSm ? styles.textInputSmWrapper : styles.textInputWrapper,
        styles.postRelative,
      ]}
    >
      <FieldTitle
        fieldAlign={props.fieldAlign}
        fieldBg={props.fieldBg}
        title={props.title}
        fieldSm={props.fieldSm}
        fieldLt={props.fieldLt}
        windowWidth={props.windowWidth}
        reminderSection={props.reminderSection}
      />
      <View
        style={[
          props.fieldSm ? styles.textInputFieldSm : styles.textInputField,
          props.fieldInfo ? styles.frameInfoPd : styles.framePd,
          props.fieldBg === "grey"
            ? styles.bgThinGrey
            : props.fieldBg === "blue"
              ? styles.bgBlueExLight
              : props.fieldBg === "transparent"
                ? styles.bgTransparent
                : styles.bgWhite,
        ]}
      >
        <View style={[
          props.fieldSm ? styles.dataWrapperSm : styles.dataWrapper,
          styles.flexJcCt]}>
          <Text style={[              
              props.reminderSection
              ? styles.textPrimeSm
              : styles.textPrimeMd]}
              allowFontScaling = {FontScaling.allowFontScaleFalse}>{props.fieldValue}</Text>
          
        </View>
      </View>
      {/* <View style={[styles.btnActionView, styles.btnActionInfo]} pointerEvents={"none"}>
        <Image
          style={[styles.calIcon]}
          source={require("../../assets/icons/cal.png")}
        />
      </View> */}
    </View>
  );
};

export default textTimePickerFrame;
