import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import IndividualChatComponent from '../../../../components/PeerToPeer/MainSection/Chats/IndividualChatComponent';
import {DASHBOARD, PEER_TO_PEER} from '../../../../navigation/path';
import {GiftedChat} from '../../../../modules/react-native-gifted-chat';
import {actionStatus} from '../../../../util/commonUiLogic';
import { peerToPeerTabPath } from '../../../../Constant';
import { pendoTrackEvent } from '../../../../util/pendoConfig';
import { EventRegister } from 'react-native-event-listeners';
import _ from 'lodash';

class IndividualChatScreen extends Component {
  constructor(props) {
    super(props);
    const {windowHeight, windowWidth, route} = props;
    this.state = {
      messages: [],
      windowHeight,
      windowWidth,
      visibleDisconnectPopup: false,
      visibleBlockPopup: false,
      visibleReportPopup: false,
      visibleSuccessMessage: false,
      selectedUser: null,
      connectionId: null,
      status: null,
      reportType: null,
      disconnectWait: false,
      blockWait: false,
      reportWait: false,
      successMessage: null,
      successMessageTitle: null,
      scrollHeight: 0,
      messageText: '',
      isKeyboardShow: false,
      isConversationDeleted: false,
      loading: false,
      selectedConversation: props.selectedConversation,
      isFromSearch: route.params && route.params?.isFromSearch,
      messageIndex: route.params && route.params?.messageIndex
    };
    this.chatMessagesPaginator = React.createRef();
    this.chatClientConversation = React.createRef();
    this.props.hideMobileMenu();
  }

  componentDidMount() {
    const {
      journalUserId,
      selectedConversation,
      hideMobileMenu,
      setUnSeenConversations,
      unSeenConversations
    } = this.props;
    if (journalUserId) {
      this.props.navigation.push(DASHBOARD.path);
    }
    this.props.navigation.addListener('focus', () => {
      hideMobileMenu();
      delete unSeenConversations[selectedConversation?.id];
      setUnSeenConversations(unSeenConversations);
      EventRegister.emitEvent("setAllMessagesToRead", selectedConversation?.id);
      pendoTrackEvent('Community/@Conversation');
    });
    this.props.navigation.addListener('blur', () => {
      //this.componentWillUnmount();
      this.props.showMobileMenu();
    });
  }

  static getDerivedStateFromProps(props, state) {
    const {windowHeight, windowWidth, selectedConversation} = props;
    const {allMessages = []} = selectedConversation ?? {};
    if (
      (state.windowHeight !== windowHeight ||
      state.windowWidth !== windowWidth ||
      !_.isEqual(state.messages, allMessages)) && selectedConversation
    ) {
      return {
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        messages: allMessages,
        selectedConversation
      };
    }

    return null;
  }

  componentWillUnmount = () => {
    this.props.showMobileMenu();
    this.props.setSelectedChannel(null);
  };

  handleOnPressBackButton = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ 
        name: PEER_TO_PEER.path,
        params: {communityTab: peerToPeerTabPath.chats}
      }],
    });
  };


  handleOnSend = (newMessages = []) => {
    const {selectedConversation} = this.props;
    if (!selectedConversation) return;
    const attributes = {giftedId: newMessages[0]._id};
    this.setState(prevState => ({
      messages: GiftedChat.append(prevState.messages, newMessages),
    }));
    EventRegister.emitEvent("addMessage", {newMessages, attributes, conversationSid: selectedConversation?.id})
  };

  handleOnSelectActionStatus = (connectionId, status, selectedUser) => {
    this.setState(
      {
        connectionId: connectionId,
        status: status,
        selectedUser: selectedUser,
      },
      () => {
        switch (status) {
          case actionStatus.DISCONNECT:
            this.setState({
              visibleDisconnectPopup: true,
            });
            break;
          case actionStatus.BLOCK:
            this.setState({
              visibleBlockPopup: true,
            });
            break;
          case actionStatus.REPORT:
            this.setState({
              visibleReportPopup: true,
            });
            break;
          default:
            break;
        }
      },
    );
  };

  hidePopup = (popupName, showNextPopup) => {
    switch (popupName) {
      case 'DISCONNECT_POPUP':
        this.setState({
          visibleDisconnectPopup: false,
          visibleSuccessMessage: showNextPopup ? true : false,
        });
        break;
      case 'REPORT_POPUP':
        this.setState({
          visibleReportPopup: false,
          visibleSuccessMessage: showNextPopup ? true : false,
          selectedUser: showNextPopup && null,
        });
        break;
      case 'BLOCK_POPUP':
        this.setState({
          visibleBlockPopup: false,
          visibleSuccessMessage: showNextPopup ? true : false,
          selectedUser: showNextPopup && null,
        });
        break;
      default:
        this.setState({
          visibleSuccessMessage: false,
        });
        break;
    }
  };

  handleOnDisconnectConversation = () => {
    this.setState({disconnectWait: true});
    this.handleChatConversationAction(success => {
      if (success) {
        this.setState(
          {
            successMessage: 'You will be disconnected with',
            successMessageTitle: 'Disconnected',
          },
          () => {
            this.hidePopup('DISCONNECT_POPUP', true);
            setTimeout(() => {
              this.hidePopup();
            }, 3000);
          },
        );
      } else {
        this.hidePopup('DISCONNECT_POPUP');
      }
      this.setState({disconnectWait: false});
    });
  };

  handleOnBlockConversation = () => {
    const {selectedUser} = this.state;
    this.setState({blockWait: true});
    this.handleChatConversationAction(success => {
      if (success) {
        this.setState(
          {
            successMessage:
              'You will be disconnected and you will not show up in ' +
              (selectedUser && selectedUser?.userName) +
              '’s search.',
            successMessageTitle: 'Blocked',
          },
          () => {
            this.hidePopup('BLOCK_POPUP', true);
            setTimeout(() => {
              this.hidePopup();
            }, 3000);
          },
        );
      } else {
        this.hidePopup('BLOCK_POPUP');
      }
      this.setState({blockWait: false});
    });
  };

  handleOnReportConversation = reportType => {
    const {selectedUser} = this.state;
    this.setState(
      {
        reportWait: true,
        reportType: reportType,
      },
      () => {
        this.handleChatConversationAction(success => {
          if (success) {
            this.setState(
              {
                successMessage:
                  'You will be disconnected and you will not show up in ' +
                  (selectedUser && selectedUser?.userName) +
                  '’s search.',
                successMessageTitle: 'Report Sent',
              },
              () => {
                this.hidePopup('REPORT_POPUP', true);
                setTimeout(() => {
                  this.hidePopup();
                }, 3000);
              },
            );
          } else {
            this.hidePopup('REPORT_POPUP');
          }
          this.setState({
            reportWait: false,
            reportType: null,
          });
        });
      },
    );
  };

  handleChatConversationAction = callBack => {
    const {onHandleChatConversationAction} = this.props;
    const {connectionId, status, reportType} = this.state;
    const chatConversationActionObj = {
      connectionId: connectionId,
      status: status,
      reportType: reportType,
    };
    onHandleChatConversationAction(
      chatConversationActionObj,
      (success, res) => {
        if (callBack) {
          callBack(success);
        }
      },
    );
  };

  handleOnScroll = scrollHeight => {
    this.setState({
      scrollHeight: scrollHeight,
    });
  };

  handleOnInputTextChanged = text => {
    const {selectedConversation} = this.props;
    this.setState({
      messageText: text,
    });
    if (!selectedConversation) return;
    EventRegister.emitEvent("typingStarted", selectedConversation?.id)
  };
  handleOnKeyboardWillShow = isShow => {
    this.setState({
      isKeyboardShow: isShow,
    });
  };
  render() {
    const {
      navigation,
      peerProfile,
      windowWidth,
      route,
      hideMobileMenu,
    } = this.props;
    const {id} = peerProfile;
    const {
      messages,
      visibleDisconnectPopup,
      visibleBlockPopup,
      visibleReportPopup,
      visibleSuccessMessage,
      selectedUser,
      disconnectWait,
      blockWait,
      reportWait,
      successMessage,
      successMessageTitle,
      scrollHeight,
      messageText,
      isKeyboardShow,
      loading,
      selectedConversation,
      isFromSearch,
      messageIndex
    } = this.state;
    hideMobileMenu();
    return (
      <IndividualChatComponent
        navigation={navigation}
        route={route}
        index={PEER_TO_PEER.index}
        handleOnPressBackButton={this.handleOnPressBackButton}
        userId={id}
        messages={messages}
        onSend={this.handleOnSend}
        peerProfile={selectedConversation ?? {}}
        windowWidth={windowWidth}
        onSelectActionStatus={this.handleOnSelectActionStatus}
        visibleDisconnectPopup={visibleDisconnectPopup}
        visibleBlockPopup={visibleBlockPopup}
        visibleReportPopup={visibleReportPopup}
        visibleSuccessMessage={visibleSuccessMessage}
        hidePopup={this.hidePopup}
        selectedUser={selectedUser}
        onDisconnectConversation={this.handleOnDisconnectConversation}
        disconnectWait={disconnectWait}
        onBlockConversation={this.handleOnBlockConversation}
        blockWait={blockWait}
        onReportConversation={this.handleOnReportConversation}
        reportWait={reportWait}
        successMessage={successMessage}
        successMessageTitle={successMessageTitle}
        onScroll={this.handleOnScroll}
        scrollHeight={scrollHeight}
        onInputTextChanged={this.handleOnInputTextChanged}
        messageText={messageText}
        onKeyboardWillShow={this.handleOnKeyboardWillShow}
        isKeyboardShow={isKeyboardShow}
        loading={loading}
        disableHeader={_.isEmpty(this.props.selectedConversation)}
        isFromSearch={isFromSearch}
        messageIndex={messageIndex}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    journalUserId: state.uistate.journalUserId,
    windowHeight: state.uistate.windowHeight,
    windowWidth: state.uistate.windowWidth,
    peerProfile: state.routines.peer2peer,
    selectedConversation: state.peerToPeer.selectedChannel,
    unSeenConversations: state.peerToPeer.unSeenConversations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideMobileMenu: () => dispatch(actions.hideMobileMenu()),
    showMobileMenu: () => dispatch(actions.showMobileMenu()),
    commonErrorHandler: error => dispatch(actions.commonErrorHandler(error)),
    onHandleChatConversationAction: (channelActionObj, callBack) =>
      dispatch(actions.handleMyNetworkAction(channelActionObj, callBack)),
    setSelectedChannel: (selectedChannel) =>
      dispatch(actions.setSelectedChannel(selectedChannel)),
    setUnSeenConversations: unSeenConvos =>
      dispatch(actions.setUnSeenConversations(unSeenConvos)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndividualChatScreen);
